import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { PulseLoader } from 'react-spinners';
import { formatDate } from '../../utils/formatDate';
import { getTimeDifference } from '../../utils/getTimeDifference';
import i18n from '../../translation/i18n';
import { InputTable } from '../../assets/styles/InputTable';
import { apiGet } from '../../services/_common';

// 출입 장비  연결 정보
export const EquipInfo = ({ name, userInfo }: any) => {
  const { t } = useTranslation();
  const now = dayjs();
  const currentTime = now.format('YYYY-MM-DD HH:mm:ss');
  const [columns, setColumns] = useState<any[]>([]);
  const [tableState, setTableState] = useState([]);

  const equipQuery = useQuery(['equipInfoGet', userInfo], () => apiGet({ path: '/setting/faceSet', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }), { enabled: userInfo.sCd !== '00000' });

  useEffect(() => {
    if (equipQuery.isSuccess && equipQuery.data && equipQuery.data.data.statusCode === 200) {
      const { faceSet } = equipQuery.data.data.data;
      if (faceSet) {
        setTableState(
          faceSet.map((v: any) => {
            return {
              fName: v.fName,
              fLastdate: formatDate(v.fLastdate),
              isFresh: !!getTimeDifference(currentTime, v.fLastdate),
            };
          })
        );
      }
    }
  }, [equipQuery.isSuccess, equipQuery.isRefetching]);

  useEffect(() => {
    setColumns([
      {
        header: t('출입 장비명'),
        name: 'fName',
        align: 'center',
        sortable: true,
      },
      {
        header: t('최종 연결'),
        name: 'fLastdate',
        align: 'center',
        sortable: true,
      },
      {
        header: t('상태'),
        name: 'isFresh',
        align: 'center',
        sortable: true,
      },
    ]);
  }, [i18n.language]);

  const onClickRefresh = () => {
    equipQuery.refetch();
  };

  if (!equipQuery.isSuccess || equipQuery.data.status !== 200 || equipQuery.isRefetching) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }
  return (
    <div className='equipInfo'>
      <div className='widget-header'>
        <div className='widget-title flex-between'>
          <span>
            {t(name)}
            <span className='badge'>{tableState.length}</span>
          </span>

          <div className='refresh-btn' onClick={onClickRefresh} role='presentation'>
            <span className='material-symbols-rounded'>refresh</span>
          </div>
        </div>
      </div>
      <div className='widget-body table'>
        <InputTable>
          <div className='thead'>
            <div className='tr'>
              <div className='trCol2p5 flex-center tableStickyNo content-overflow padding-left text_tertiary'>{t('출입 장비명')}</div>
              <div className='trCol10 flex-center text_tertiary'>{t('최종 연결')}</div>
              <div className='trCol4 flex-center padding-right text_tertiary'>{t('상태')}</div>
            </div>
          </div>
          <div className='table'>
            <div className='tbody'>
              {tableState?.length > 0 ? (
                tableState.map((el: any, i: number) => (
                  <div className='tr' key={`equipInfo_${i}`}>
                    <div className='trCol2p5 flex-basic tableStickyNo content-overflow padding-left text_primary'>{el.fName}</div>
                    <div className='trCol10 flex-end shrink0 text_disabled'>
                      <span className=''>{el.fLastdate}</span>
                    </div>
                    <div className='trCol4 flex-center shrink0 padding-right'>
                      <span className={el.isFresh ? 'greenLight' : 'redLight'}>{el.isFresh ? 'ON' : 'OFF'}</span>
                    </div>
                  </div>
                ))
              ) : (
                <div className='noData'>No data.</div>
              )}
            </div>
          </div>
          {/* <tfoot>
              <div className='tr'>
                <div className='trCol2p5 flex-basic tableStickyNo content-overflow padding-left'>
                  {t('합계')}
                  <span className='subNumber'>{tableState.length}</span>
                </div>
              </div>
            </tfoot> */}
        </InputTable>
      </div>
    </div>
  );
};
