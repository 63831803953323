/**
 * 작성자 : 홍선영
 * 날짜 : 2023.05.02
 * 경로 : 일반관리-현장관리-현장정보탭
 */

import { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

import { IComCdList, IModal } from 'customTypes';
import { msiteUseYnState, userState } from '../../../atoms';
import { COMCD_INFRA, COMCD_S_STATUS, COMCD_S_VERSION, COMCD_USE_YN, COMCD_USE_YN1, INIT_USE_YN_A, SELECT_SNAME_OR_HNAME } from '../../../_constants';

import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { BtnGhost } from '../../../components/Button';
import Input from '../../../components/Input';
import Portal from '../../../components/Portal';
import SelectBox from '../../../components/SelectBox';
import ViewTable from '../../../components/Table/ViewTable';
import BackButton from '../../../components/BackButton';
import DeleteModal from '../../../components/Modal/DeleteModal2';
import SiteInfoInputForm from '../../../components/Form/SiteInfoInputForm';
import { ynFilter } from '../../../utils/ynFilter';
import { trimObject } from '../../../utils/trimObject';
import { setComCdListState } from '../../../utils/setComCdListState';
import { useDetectScrolledToBottom } from '../../../utils/useDetectScrolledToBottom';
import { logPost } from '../../../services/log';
import { useFetchCommonCodeList } from '../../../services/useSetCodeListInSelectBoxForm';
import { apiDelete, apiGet, apiPost } from '../../../services/_common';
import MSiteList from './MSiteList';
import { getHcdList } from '../../../hooks/useMSite';

const Root = styled.div`
  &.hideRoot {
    visibility: hidden;
    position: absolute !important;
  }
  .inputForm-body {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  > .inputFormsWrapper {
    height: 3.5rem !important;
    flex-shrink: 0;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    padding: 0 0.5rem;
    border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    &.smallTab {
      flex-direction: row;
      align-items: center;
      padding: 0 0.5rem;
      font-weight: 500;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        height: 0rem !important;
      }
      .tab {
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
        display: flex;
        align-items: center;
        padding: 0 0.5rem;
        cursor: pointer;
        height: 3.5rem;
        border-bottom: 2px solid transparent;
      }
      .activeTab {
        font-weight: 700;
        border-bottom: 2px solid ${({ theme }: { theme: any }) => theme.selected_primary};
        color: ${({ theme }: { theme: any }) => theme.selected_primary};
      }
    }
    @media (min-width: 1024px) {
      padding: 0 1rem;
    }
  }
`;

const SiteInfo = () => {
  const { t } = useTranslation();
  const size = useOutletContext<any>();
  const userInfo = useRecoilValue(userState);
  const node = useRef<any>(null);
  const userInfoInputFormRef = useRef<any>(null); // 인풋 폼 ref (테이블 로우를 클릭했을 때 바로 inputForm 으로 스크롤 이동시키기 위한 ref)
  const [searchOptionUseYn, setSearchOptionUseYn] = useState(INIT_USE_YN_A);
  const [searchOption, setSearchOption] = useState({ type: 'nameType', nameType: 'sName', cdName: t('현장명') });
  const [searchName, setSearchName] = useState({ sName: '', hName: '' });
  const [isNewAdd, setIsNewAdd] = useState(true); // 신규등록 여부
  const [rowState, setRowState] = useState({
    address1: '',
    address2: '',
    bigo: '',
    eDate: '',
    editor: '',
    fDate: '',
    hCd: '',
    hName: '',
    hTranscd: '',
    hTransyn: 'N', // 기본값
    postNum: '',
    sCd: '',
    sDate: '',
    sInfra: '',
    sName: '',
    sStatus: 'Y', // 기본값
    sTel: '',
    sVersion: '',
    smsYn: 'N', // 기본값
    useYn: 'Y', // 기본값
    delYn: 'N', // 기본값
    wDate: '',
    writer: '',
    latitude: '',
    longitude: '',
    eExcelYn: 'Y',
    eListYn: 'Y',
    ePrintYn: 'Y',
    msiteYn: 'N',
    eduexamYn: 'N',
  }); // 서버에서 받아 온 raw data 어레이 중 1줄의 데이터 state
  const [viewData, setViewData] = useState([{}]); // 서버에서 받아 온 raw data 중 viewTable에 뿌려줄 데이터만 담는 state
  const [columnCount, setColumnCount] = useState(0);
  const { isBottom } = useDetectScrolledToBottom(node); // 스크롤이 해당노드의 하단에 근접했는지 여부 (플로팅버튼 띄우기 위함)
  const [masterSiteList, setMasterSiteList] = useState<any>([]);
  const [orgMasterSiteList, setOrgMasterSiteList] = useState<any>([]); // 필터링되지않은 본사리스트
  const [hCd, setHCd] = useState<any>({ type: 'hCd', hCd: '', cdName: '' });
  const [sStatus, setSStatus] = useState<any>({ type: COMCD_S_STATUS, [COMCD_S_STATUS]: 'Y', cdName: '운영중', cdSort: 0 }); // 현장상태 운영여부 공통코드
  const [sVersion, setSVersion] = useState<any>({ type: COMCD_S_VERSION, [COMCD_S_VERSION]: '', cdName: '', cdSort: 0 }); // 프로그램버전 공통코드
  const [sInfra, setSInfra] = useState<any>({ type: COMCD_INFRA, [COMCD_INFRA]: '', cdName: '', cdSort: 0 }); // 인프라 공통코드
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const [sTelNumState, setSTelNumState] = useState({ num1: '', num2: '', num3: '' });
  const [headList, setHeadList] = useState<{ type: String; hCd: string; cdName: string }[]>([]);
  const [sStatusComCdList, setSStatusComCdList] = useState<IComCdList[]>([]); // 현장상태 운영여부 공통코드
  const [sVersionComCdList, setSVersionComCdList] = useState<IComCdList[]>([]); // 프로그램버전 공통코드
  const [sInfraComCdList, setSInfraComCdList] = useState<IComCdList[]>([]); // 인프라 공통코드
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [viewMainPage, setViewMainPage] = useState(true);
  const { data: useYnComCdListWithAll } = useFetchCommonCodeList(COMCD_USE_YN, true); // 사용여부 공통코드 목록 (검색조건에서 사용, 전체포함)
  const { data: useYnComCdList } = useFetchCommonCodeList(COMCD_USE_YN, false); // 사용유무 공통코드 목록 (사용자 정보에서 사용)
  const { data: reverseUseYnComCdList } = useFetchCommonCodeList(COMCD_USE_YN1, false); // 사용유무(역순) 공통코드 목록 (사용자 정보에서 사용)
  const [selectedTab, setSelectedTab] = useState<'siteDetail' | 'siteList'>('siteDetail');
  const setMsiteUseYn = useSetRecoilState(msiteUseYnState);

  useEffect(() => {
    getSiteListAPI().then((res: any) => {
      if (res.status === 200) {
        setSearchOptionUseYn(INIT_USE_YN_A);
        setComCdListState(COMCD_S_STATUS, setSStatusComCdList, false);
        setComCdListState(COMCD_S_VERSION, setSVersionComCdList, false);
        setComCdListState(COMCD_INFRA, setSInfraComCdList, false);
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '현장 관리 > 현장 정보',
          action: '조회',
          etc: ``,
        });
      }
    });
  }, []);

  useEffect(() => {
    // 도메인에 따라서 뷰테이블에 전달해야하는 키값만 viewTableProperties 배열안에 작성하면 됨.
    if (masterSiteList && masterSiteList.length > 0) {
      const viewTableProperties = ['hCd', 'hName', 'sCd', 'sName', 'useYn'];
      setDataIntoViewTable(viewTableProperties);
    }
  }, [masterSiteList]);

  // 본사 리스트 조회 API
  getHcdList().then((res) => {
    if (headList.length === 0) {
      setHeadList(res);
    }
  });

  // 로우데이터에서 뷰테이블에 보여줄 데이터만 뽑아서 배열에 담아 리턴
  const setDataIntoViewTable = (properties: any) => {
    setColumnCount(properties.length);
    const viewDataArray: any = [];
    const allKeys = Object.keys(masterSiteList[0]);

    masterSiteList?.map((el: any, i: number) => {
      const result = allKeys.reduce((next, key: any) => {
        if (properties.includes(key)) {
          return { ...next, [key]: masterSiteList[i][key] };
        }
        return next;
      }, {});
      return viewDataArray.push(result);
    });
    setViewData(viewDataArray);
  };

  // 삭제버튼 클릭으로 삭제 모달창 띄우기
  const onClickDelete = () => {
    setOpenModal((prev) => ({ ...prev, status: true, type: 'delete', api: deleteHeadAPI }));
  };

  // 현장정보 조회 API
  const getSiteListAPI = async () => {
    const res = await apiGet({ path: '/site' });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      // 삭제되지 않은 현장만 필터링해서 setState
      const activeSiteInfos = data.siteInfoList.filter((el: any) => el.delYn === 'N');
      setMasterSiteList(activeSiteInfos);
      setOrgMasterSiteList(activeSiteInfos);
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  // 현장정보 저장&수정 API
  const saveSiteAPI = async () => {
    const sTelMergedNumber = `${sTelNumState.num1}-${sTelNumState.num2}-${sTelNumState.num3}`;
    const reqData = {
      hCd: hCd.hCd,
      sName: rowState.sName,
      sCd: rowState.sCd,
      sStatus: rowState.sStatus,
      sInfra: rowState.sInfra,
      sDate: rowState.sDate?.replaceAll('-', ''),
      fDate: rowState.fDate?.replaceAll('-', ''),
      sTel: sTelMergedNumber,
      sVersion: rowState.sVersion,
      postNum: rowState.postNum,
      address1: rowState.address1,
      address2: rowState.address2,
      smsYn: rowState.smsYn,
      hTransyn: rowState.hTransyn,
      hTranscd: rowState.hTranscd,
      bigo: rowState.bigo,
      useYn: rowState.useYn,
      delYn: rowState.delYn,
      writer: userInfo.userId,
      editor: userInfo.userId,
      latitude: rowState.latitude,
      longitude: rowState.longitude,
      eExcelYn: rowState.eExcelYn,
      eListYn: rowState.eListYn,
      ePrintYn: rowState.ePrintYn,
      msiteYn: rowState.msiteYn,
      eduexamYn: rowState.eduexamYn,
    };

    const req = trimObject(reqData);
    const res = await apiPost({ path: '/site', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      applyFilter(data[1].siteInfoList);
      setOrgMasterSiteList(data[1].siteInfoList);
      setRowState(data[0].siteInfo);
      setMsiteUseYn(rowState.msiteYn as 'N' | 'Y');

      toast.success(t('현장정보 저장&수정 성공'));
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '마스터일반관리-현장관리',
        action: '현장 정보 저장&수정',
        etc: `본사명 : ${rowState.hName} / 현장명 : ${rowState.sName}(${rowState.sCd || '신규'})`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 현장정보 삭제 API
  const deleteHeadAPI = async () => {
    const req = { hCd: rowState.hCd, sCd: rowState.sCd, editor: userInfo.userId };
    const res = await apiDelete({ path: '/site', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      applyFilter(data[1].siteInfoList);
      setOrgMasterSiteList(data[1].siteInfoList);
      setIsNewAdd(true);
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '마스터일반관리-현장관리',
        action: '현장 정보 삭제',
        etc: `본사명 : ${rowState.hName} / 현장명 : ${rowState.sName}(${rowState.sCd})`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 저장버튼 클릭해서 save API 호출하여 데이터전송
  const onClickSave = () => {
    setIsSaveClicked(true);
    if (hCd.hCd?.trim() === '') return toast.warning(t('본사명을 선택하세요'));
    if (rowState.sName?.trim() === '') return toast.warning(t('현장명을 입력하세요'));
    // if (isNewAdd) return saveSiteAPI();
    return saveSiteAPI();
  };

  const resetSelectAllOptions = () => {
    setHCd({ type: 'hCd', hCd: '', cdName: '' });
    setSStatus({ type: COMCD_S_STATUS, [COMCD_S_STATUS]: 'Y', cdName: '운영중', cdSort: 0 });
    setSInfra({ type: COMCD_INFRA, [COMCD_INFRA]: '', cdName: '미선택' });
    setSVersion({ type: COMCD_S_VERSION, [COMCD_S_VERSION]: '', cdName: '', cdSort: 0 });
  };

  // 신규등록 버튼 클릭해서 새 입력창(iputForm) 노출
  const onClickNewRegistration = () => {
    initiateState();
    setIsNewAdd(true);
    resetSelectAllOptions();

    if (size.innerSize.W < 1024) {
      setViewMainPage(false);
    }
  };

  const backToMain = () => {
    setViewMainPage(true);
  };

  // 스테이트 객체의 밸류를 ''로 초기화
  const initiateState = () => {
    Object.keys(rowState).map((el: any) => {
      return setRowState((prev: any) => ({
        ...prev,
        [el]: '',
        useYn: 'Y',
        delYn: 'N',
        hTransyn: 'N',
        smsYn: 'N',
        sStatus: 'Y',
        sVersion: '',
        sInfra: '',
        eExcelYn: 'Y',
        eListYn: 'Y',
        ePrintYn: 'Y',
        msiteYn: 'N',
        eduexamYn: 'N',
      }));
    });
    setIsNewAdd(true);
    setIsSaveClicked(false);
  };

  const applyFilter = (array: any[]) => {
    // 필터링 기준
    const filterOptions = {
      nameType: searchOption.nameType,
      hName: searchName.hName,
      sName: searchName.sName,
      useYn: searchOptionUseYn[COMCD_USE_YN],
    };
    // 필터링된 어레이 리턴, 대소문자구분X
    const filteredArray = array.filter((item: any) => {
      if (filterOptions.nameType === 'hName') {
        return item[searchOption.nameType]?.toLowerCase()?.includes(filterOptions.hName?.toLowerCase());
      }
      return item[searchOption.nameType]?.toLowerCase()?.includes(filterOptions.sName?.toLowerCase());
    });

    if ((searchOption.nameType === 'hName' && searchName.hName === '') || (searchOption.nameType === 'sName' && searchName.sName === '')) {
      const result = ynFilter(array, 'useYn', searchOptionUseYn[COMCD_USE_YN]);
      if (result.length > 0) {
        setMasterSiteList(result);
      } else {
        setMasterSiteList([]);
      }
    } else {
      const result = ynFilter(filteredArray, 'useYn', searchOptionUseYn[COMCD_USE_YN]);
      if (result.length > 0) {
        setMasterSiteList(result);
      } else {
        setMasterSiteList([]);
      }
    }
  };

  useEffect(() => {
    applyFilter(orgMasterSiteList);
  }, [searchOption.nameType, searchName.sName, searchName.hName, searchOptionUseYn[COMCD_USE_YN]]);

  const onClickInitiateSearchOption = () => {
    setSearchOptionUseYn(INIT_USE_YN_A);
    setSearchOption({ type: 'nameType', nameType: 'sName', cdName: t('현장명') });
    setSearchName({ sName: '', hName: '' });

    if (size.innerSize.W >= 1024) {
      onClickNewRegistration(); // 우측 인풋창도 초기화
    }
  };

  useEffect(() => {
    setSelectedTab('siteDetail');
  }, [rowState, setSelectedTab]);

  return (
    <div className={`content-container ${size.innerSize.W >= 1024 ? 'twoColumn column-55 max800' : 'oneColumn'}`}>
      <Root className={size.innerSize.W >= 1024 || viewMainPage ? 'showRoot' : 'hideRoot'}>
        <SearchOptions>
          <div className='inputsWrapper'>
            <div className='inputForm-row'>
              <div className='inputForm-col withLabelComCf'>
                <label htmlFor='useYn'>{t('사용유무')}</label>
                <SelectBox
                  options={useYnComCdListWithAll}
                  defaultOption={t('전체')}
                  state={searchOptionUseYn}
                  setState={setSearchOptionUseYn}
                  stateKey={COMCD_USE_YN}
                  filterbar='filter-1-left'
                  initiateKey={searchOptionUseYn[COMCD_USE_YN]}
                />
              </div>
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col'>
                <SelectBox
                  options={SELECT_SNAME_OR_HNAME}
                  defaultOption={searchOption.nameType === 'sName' ? t('현장명') : t('본사명')}
                  state={searchOption}
                  setState={setSearchOption}
                  stateKey='nameType'
                  initiateKey={searchOption.nameType}
                  filterbar='filter-1-left'
                />
              </div>
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col'>
                <Input
                  label=''
                  placeholder={searchOption.nameType === 'sName' ? t('현장명') : t('본사명')}
                  type='text'
                  id={searchOption.nameType === 'sName' ? 'sName' : 'hName'}
                  name={searchOption.nameType === 'sName' ? 'sName' : 'hName'}
                  state={searchName}
                  setState={setSearchName}
                />
              </div>
            </div>
          </div>
          <div className='inputsWrapper'>
            <div className='secondSearchOption'>
              <div className='flex-basic textBtnGroup'>
                <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
              </div>
              <div className='flex-basic iconBtnGroup'>
                <BtnGhost onClick={onClickNewRegistration}>
                  <span className='material-symbols-rounded'>add</span>
                  {t('등록')}
                </BtnGhost>
              </div>
            </div>
          </div>
        </SearchOptions>
        <div className='searchContent inputForm-body' ref={node}>
          <ViewTable
            rawData={masterSiteList}
            state={viewData}
            setRowState={setRowState}
            setIsNewAdd={setIsNewAdd}
            userInfoInputFormRef={userInfoInputFormRef}
            setHCd={setHCd}
            setSStatus={setSStatus}
            setSVersion={setSVersion}
            setSInfra={setSInfra}
            setViewMainPage={() => setViewMainPage(false)}
          />
        </div>
      </Root>
      <Root className={size.innerSize.W >= 1024 || !viewMainPage ? 'showRoot' : 'hideRoot'}>
        <div className={`inputFormsWrapper ${isNewAdd ? 'flexRowEm' : 'smallTab'}`}>
          {size.innerSize.W < 1024 && <BackButton func={() => backToMain()} />}
          {isNewAdd ? (
            <div className='formTitle'>{t('새로운 현장정보')}</div>
          ) : (
            <>
              <div
                className={`tab ${selectedTab === 'siteDetail' ? 'activeTab' : ''}`} //
                role='button'
                tabIndex={0}
                onClick={() => setSelectedTab('siteDetail')}
              >
                {t('현장정보 상세')}
              </div>
              {masterSiteList.find((el: any) => el.sCd === rowState.sCd && el.hCd === rowState.hCd).msiteYn === 'Y' && (
                <div
                  className={`tab ${selectedTab === 'siteList' ? 'activeTab' : ''}`} //
                  role='button'
                  tabIndex={0}
                  onClick={() => setSelectedTab('siteList')}
                >
                  {t('다중 현장 관리 목록')}
                </div>
              )}
            </>
          )}
        </div>
        {selectedTab === 'siteDetail' && (
          <SiteInfoInputForm
            state={rowState}
            setState={setRowState}
            isNewAdd={isNewAdd}
            userInfoInputFormRef={userInfoInputFormRef}
            onClickNewAdd={onClickNewRegistration}
            onClickSave={onClickSave}
            onClickDelete={onClickDelete}
            sStatus={sStatus}
            setSStatus={setSStatus}
            sVersion={sVersion}
            setSVersion={setSVersion}
            sTelNumState={sTelNumState}
            setSTelNumState={setSTelNumState}
            sInfra={sInfra}
            setSInfra={setSInfra}
            hList={headList}
            useYnComCdList={useYnComCdList}
            sStatusComCdList={sStatusComCdList}
            sVersionComCdList={sVersionComCdList}
            sInfraComCdList={sInfraComCdList}
            isSaveClicked={isSaveClicked}
            hCd={hCd}
            setHCd={setHCd}
          />
        )}
        {selectedTab === 'siteList' && (
          <MSiteList
            headList={headList} //
            rowState={rowState}
          />
        )}
      </Root>
      <Portal openModal={openModal?.status}>{openModal && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
    </div>
  );
};

export default SiteInfo;
