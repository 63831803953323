import React, { useLayoutEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Aside from './Aside';
import styled from 'styled-components';
import { ISizeObj } from 'customTypes';
import { useRecoilState } from 'recoil';
import { asideState } from '../atoms';

interface IProps {
  size: ISizeObj;
}

interface ISizeStyle {
  W: number;
  H: number;
}

const Root = styled.div<ISizeStyle>`
  display: flex;
  width: 100%;

  .wrapper {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    height: -webkit-fill-available;
    height: fill-available;
    transition: width 0.1s ease-in;
    overflow-y: hidden;
  }

  .noWrap {
    position: absolute;
    top: 0;
    right: 0;
    transition: width 0.1s ease-in;
    width: ${(props) => (props.W < 1024 ? '100%' : 'calc(100% - 23rem)')};
    padding: ${(props) => (props.W < 1024 ? '2rem' : '2rem 2rem 2rem 1rem')};
    > :last-child {
      min-height: calc(100vh - 11rem);
      background-color: transparent;
      border-radius: 0.8rem;
      position: relative;
      box-shadow: none;
    }
  }
`;
const Layout = ({ size }: IProps) => {
  const innerWidth = size.innerSize.W;
  const innerHeight = size.innerSize.H;
  const [openAside, setOpenAside] = useRecoilState(asideState);
  const location = useLocation();

  useLayoutEffect(() => {
    if (size.innerSize.W >= 1024) {
      setOpenAside(true);
    }
    if (size.innerSize.W < 1024) {
      setOpenAside(false);
    }
  }, [location?.pathname, size.innerSize.W]);

  const validatePath = () => {
    const isBoard = location.pathname === '/board';
    const isRealtimeCCTV = location.pathname === '/realtime_cctv';
    const isPlayCCTV = location.pathname === '/play_cctv';
    const isdownloadCctv = location.pathname === '/download_cctv';
    const isSafeEduVideo = location.pathname === '/safeedu_video';
    return !(isBoard || isRealtimeCCTV || isPlayCCTV || isdownloadCctv || isSafeEduVideo);
  };

  return (
    <Root W={innerWidth} H={innerHeight}>
      {validatePath() && <Aside W={innerWidth} openMenu={openAside} />}
      <div className='wrapper'>
        <Outlet context={size} />
      </div>
    </Root>
  );
};

export default React.memo(Layout);
