/**
 * 작성자 : 한영광
 * 날짜 : 2023.05.24
 * 기능 : 본사 사용자관리-사용자정보
 */

import { useState, useRef, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PulseLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { INumbers, ITabObject } from 'customTypes';
import { pageInfoState, userState } from '../../atoms';
import { COMCD_APPROVAL_YN, COMCD_LOCK_YN, COMCD_USE_YN, INIT_APPROVAL_YN_A, INIT_LOCK_YN_A, INIT_LOCK_YN_N, INIT_USE_YN_A, INIT_USE_YN_Y, USER_GRANT } from '../../_constants';
import i18n from '../../translation/i18n';
import UserInfo from './user1/userInfo';
import UserMenu from './user1/userMenu';
import { SearchOptions } from '../../assets/styles/SearchOptions';
import { TuiGridTwoColumnStyle } from '../../assets/styles/TuiGridTwoColumnStyle';
import { LoadingModalBackground } from '../../assets/styles/Modal';
import illustrator from '../../assets/images/illustration/61.svg';
import { BtnGhost } from '../../components/Button';
import Tab from '../../components/Tab';
import Input from '../../components/Input';
import TuiGrid from '../../components/Table/TuiGrid';
import SelectBox from '../../components/SelectBox';
import BackButton from '../../components/BackButton';
import IssueGuide from '../../components/IssueGuide';
import TuiGridWrapper from '../../components/Table/TuiGridWrapper';
import { apiGet } from '../../services/_common';
import { logPost } from '../../services/log';
import { useFetchCommonCodeList } from '../../services/useSetCodeListInSelectBoxForm';
import { ynFilter } from '../../utils/ynFilter';
import { useDebounce } from '../../utils/useDebounce';
import { useGetFrozenCount } from '../../utils/getFrozenCount';

const INIT_ROW_STATE = {
  userId: '',
  userName: '',
  hpNum: '',
  telNum: '',
  eMail: '',
  ipInfo: '',
  bigo: '',
  eDate: '',
  editor: '',
  lockYn: 'N',
  useYn: 'Y',
  approvalYn: '',
  userPwd: '',
  wDate: '',
  writer: '',
  loginerrorCount: '',
  isDuplicateChecked: false,
  gCd: '',
};

const Suser1 = () => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const { userMenuList } = userInfo;
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const INIT_GCD = { type: USER_GRANT, [USER_GRANT]: '', cdName: t('권한 선택') };
  const INIT_SJCD = { type: 'sjCd', sjCd: '', cdName: t('협력업체 선택') };
  const [columns, setColumns] = useState<any[]>([]);
  const [activeTabClick, setActiveTabClick] = useState(true);
  const [viewTable, setViewTable] = useState<boolean>(true);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  /**
   * 수정자 : 한영광
   * 수정일자 : 2023.08.31
   * 수정내용 : 검색조건에 사용하는 잠금(사용)유무 리스트와 사용자 정보에 사용하는 잠금(사용)유무 리스트를 따로만듬
   *           -> 검색조건에는 전체를 포함하지만 사용자 정보에서는 전체를 포함하지 않음
   */
  const { data: lockYnComCdListWithAll } = useFetchCommonCodeList(COMCD_LOCK_YN, true); // 잠금여부 공통코드 목록 (검색조건에서 사용, 전체포함)
  const { data: approvalYnComCdListWithAll } = useFetchCommonCodeList(COMCD_APPROVAL_YN, true); // 승인여부 공통코드 목록 (검색조건에서 사용, 전체포함)
  const { data: useYnComCdListWithAll } = useFetchCommonCodeList(COMCD_USE_YN, true); // 사용여부 공통코드 목록 (검색조건에서 사용, 전체포함)
  const { data: lockYnComCdList } = useFetchCommonCodeList(COMCD_LOCK_YN, false); // 잠금유무 공통코드 목록 (사용자 정보에서 사용)
  const { data: useYnComCdList } = useFetchCommonCodeList(COMCD_USE_YN, false); // 사용유무 공통코드 목록 (사용자 정보에서 사용)
  const { data: userGrantComCdList } = useFetchCommonCodeList(USER_GRANT, false, 'etc1', 'S'); // 사용자 권한 공통코드 목록 (사용자 정보에서 사용)
  const [rowState, setRowState] = useState<any>(INIT_ROW_STATE);
  const [telNumState, setTelNumState] = useState<INumbers>({ num1: '', num2: '', num3: '' });
  const [hpNumState, setHpNumState] = useState<INumbers>({ num1: '010', num2: '', num3: '' });
  const [ipInfoState, setIpInfoState] = useState<INumbers>({ num1: '', num2: '', num3: '', num4: '' });
  const [searchOption, setSearchOption] = useState({ userName: '' });
  const [searchOptionUseYn, setSearchOptionUseYn] = useState(INIT_USE_YN_A);
  const [searchOptionLockYn, setSearchOptionLockYn] = useState(INIT_LOCK_YN_A);
  const [searchOptionApprovalYn, setSearchOptionApprovalYn] = useState(INIT_APPROVAL_YN_A);
  const [siteJoinInfoList, setSiteJoinInfoList] = useState<any[]>([]); // 현장 협력업체 정보 리스트
  const [newTabList, setNewTabList] = useState<ITabObject[]>();
  const [selectedRowKey, setSelectedRowKey] = useState<any>(null);
  const [lockYn, setLockYn] = useState(INIT_LOCK_YN_N); // 잠금유무 공통코드
  const [useYn, setUseYn] = useState(INIT_USE_YN_Y); // 사용유무 공통코드
  const [isNewAdd, setIsNewAdd] = useState(true); // 신규등록 여부
  const [gCd, setGCd] = useState(INIT_GCD);
  const [siteJoinInfo, setSiteJoinInfo] = useState(INIT_SJCD);
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const debouncedUserName = useDebounce(searchOption.userName);
  const {
    data: siteUserList,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useQuery(['siteJoin', userInfo.hCd, userInfo.sCd], () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && userInfo.sCd !== '00000',
  });
  const [tableState, setTableState] = useState<ISiteUser[]>(siteUserList || []);
  const [initTableState, setInitTableState] = useState<ISiteUser[]>(siteUserList || []);

  const fetchData = async () => {
    try {
      const res = await apiGet({ path: '/user/site', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } });
      const result = res.data.data?.userList;
      const newArray = result?.map((el: ISiteUser) => ({
        ...el,
        useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용'),
        lockYnCdName: el.lockYn === 'Y' ? t('잠금') : t('미잠금'),
        approvalYnCdName: el.approvalYn === 'Y' ? t('승인') : t('미승인'),
      }));
      setTableState(newArray);
      setInitTableState(newArray);
      return result;
    } catch (error) {
      console.error('error', error);
      throw new Error('error');
    }
  };

  useEffect(() => {
    const arr: any[] = [];
    userMenuList.map((v: any) => {
      const result = tabInfo?.tabList?.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  useEffect(() => {
    setSearchOptionLockYn(INIT_LOCK_YN_A);
    setSearchOptionUseYn(INIT_USE_YN_A);
    setSearchOptionApprovalYn(INIT_APPROVAL_YN_A);

    setColumns([
      {
        header: 'ID',
        name: 'userId',
        sortable: true,
        width: 140,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('사용자명'),
        name: 'userName',
        sortable: true,
        width: 140,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('권한'),
        name: 'gName',
        sortable: true,
        filter: 'select',
        minWidth: 100,
      },
      {
        header: t('잠금유무'),
        name: 'lockYnCdName',
        align: 'center',
        sortable: true,
        minWidth: 100,
      },
      {
        header: t('승인여부'),
        name: 'approvalYnCdName',
        align: 'center',
        sortable: true,
        minWidth: 100,
      },
      {
        header: t('사용유무'),
        name: 'useYnCdName',
        align: 'center',
        sortable: true,
        minWidth: 100,
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    getSiteJoinInfoAPI();
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '현장 사용자 정보',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    applyFilter(initTableState);
  }, [debouncedUserName, searchOptionUseYn[COMCD_USE_YN], searchOptionLockYn[COMCD_LOCK_YN], searchOptionApprovalYn[COMCD_APPROVAL_YN]]);

  // 현장 협력업체 정보 조회 API
  const getSiteJoinInfoAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/site/joininfo', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const newData = data.map((v: any) => ({ type: 'sjCd', sjCd: v.sjCd, cdName: v.sjName }));
      setSiteJoinInfoList(newData);
    } else {
      // toast.error(t(ERROR));
    }
  };

  const applyFilter = (array: any[]) => {
    // 검색옵션 변경됐을 때 필터링 처리
    // 필터링 기준
    const filterOptions = {
      userName: searchOption.userName,
    };
    // 필터링된 어레이 리턴, 대소문자구분X
    const filteredArray = array.filter((item: any) => {
      return item.userName?.toLowerCase()?.includes(filterOptions.userName?.toLowerCase());
    });
    const lockFilter = ynFilter(filteredArray, 'lockYn', searchOptionLockYn[COMCD_LOCK_YN]);
    const approvalFilter = ynFilter(lockFilter, 'approvalYn', searchOptionApprovalYn[COMCD_APPROVAL_YN]);
    const result = ynFilter(approvalFilter, 'useYn', searchOptionUseYn[COMCD_USE_YN]);

    if (result.length > 0) setTableState(result.map((el: any, i) => ({ ...el, rowKey: i, sortKey: i })));
    else setTableState([]);
  };

  const onClickInitiateSearchOption = () => {
    setSearchOption({ userName: '' });
    setSearchOptionLockYn(INIT_LOCK_YN_A);
    setSearchOptionUseYn(INIT_USE_YN_A);
    setSearchOptionApprovalYn(INIT_APPROVAL_YN_A);
    clearRowData();
  };

  const clearRowData = () => {
    Object.keys(rowState).map((el: any) => {
      return setRowState((prev: any) => ({ ...prev, [el]: '', isDuplicateChecked: false, useYn: 'Y', lockYn: 'N' }));
    });
    setGCd(INIT_GCD);
    setSiteJoinInfo(INIT_SJCD);
    setUseYn(INIT_USE_YN_Y);
    setLockYn(INIT_LOCK_YN_N);
    setHpNumState({ num1: '010', num2: '', num3: '' });
    setTelNumState({ num1: '', num2: '', num3: '' });
    setIpInfoState({ num1: '', num2: '', num3: '', num4: '' });
    setIsSaveClicked(false);
    setIsNewAdd(true);
    setSelectedRowKey(null);
  };

  // 사용자 Row 클릭
  const onClickRow = (rowData: any) => {
    setSelectedRowKey(rowData.rowKey);
    const user = tableState.find((v: ISiteUser) => v.userId === rowData.userId);
    if (user) {
      const splitHpNumber = user.hpNum.split('-');
      setHpNumState({ num1: splitHpNumber[0] || '', num2: splitHpNumber[1] || '', num3: splitHpNumber[2] || '' });

      const splitTelNumber = user.telNum.split('-');
      setTelNumState({ num1: splitTelNumber[0] || '', num2: splitTelNumber[1] || '', num3: splitTelNumber[2] || '' });

      const splitIpNumber = user.ipInfo.split('.');
      setIpInfoState({ num1: splitIpNumber[0] || '', num2: splitIpNumber[1] || '', num3: splitIpNumber[2] || '', num4: splitIpNumber[3] || '' });

      setRowState({ ...user, isDuplicateChecked: true });
      setUseYn({ type: COMCD_USE_YN, [COMCD_USE_YN]: rowData.useYn, cdName: rowData.useYnCdName });
      setLockYn({ type: COMCD_LOCK_YN, [COMCD_LOCK_YN]: rowData.lockYn, cdName: rowData.lockYnCdName });
      setGCd({ type: USER_GRANT, [USER_GRANT]: rowData.gCd, cdName: rowData.gName });
      setSiteJoinInfo({ type: 'sjCd', sjCd: rowData.sjCd, cdName: rowData.sjName });
      setIsNewAdd(false);
    }
    if (size.innerSize.W < 1024) {
      setViewTable(false);
    }
  };

  const onClickTab = (mrCd: string) => {
    setCurrentTabMrCd(mrCd);
  };

  const onClickSubTab = (tabName: string) => {
    if (tabName === 'info') {
      setActiveTabClick(true);
    } else {
      setActiveTabClick(false);
    }
  };

  const renderPage = () => {
    if (activeTabClick || isNewAdd) {
      return (
        <UserInfo
          orgUserInfo={rowState}
          setOrgUserInfo={setRowState}
          applyFilter={applyFilter}
          initTableState={initTableState}
          setInitTableState={setInitTableState}
          getUserSiteAPI={refetch}
          lockYnComCdList={lockYnComCdList}
          useYnComCdList={useYnComCdList}
          userGrantComCdList={userGrantComCdList}
          siteJoinInfoList={siteJoinInfoList}
          setSiteJoinInfoList={setSiteJoinInfoList}
          setSelectedRowKey={setSelectedRowKey}
          hpNumState={hpNumState}
          telNumState={telNumState}
          ipInfoState={ipInfoState}
          setHpNumState={setHpNumState}
          setTelNumState={setTelNumState}
          setIpInfoState={setIpInfoState}
          useYn={useYn}
          setUseYn={setUseYn}
          lockYn={lockYn}
          setLockYn={setLockYn}
          isNewAdd={isNewAdd}
          setIsNewAdd={setIsNewAdd}
          gCd={gCd}
          setGCd={setGCd}
          siteJoinInfo={siteJoinInfo}
          setSiteJoinInfo={setSiteJoinInfo}
          isSaveClicked={isSaveClicked}
          setIsSaveClicked={setIsSaveClicked}
          clearRowData={clearRowData}
        />
      );
    }
    return <UserMenu orgUserInfo={rowState} setOrgUserInfo={setRowState} viewTableState={tableState} />;
  };
  const componentRef = useRef<HTMLDivElement>(null);
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight, componentRef.current?.offsetWidth]);
  const frozenCount = useGetFrozenCount();

  const backToMain = () => {
    setViewTable(true);
    setSelectedRowKey(null);
  };

  const addBtnAction = () => {
    clearRowData();
    if (size.innerSize.W < 1024) setViewTable(false);
  };

  if (userInfo.gCd === 'SU' || userInfo.gCd === 'ST') {
    return (
      <div className='contents'>
        <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
        <div className={`content-container ${size.innerSize.W >= 1024 ? 'twoColumn column-55 max800' : 'oneColumn'}`}>
          <TuiGridTwoColumnStyle className={size.innerSize.W >= 1024 || viewTable ? 'showRoot' : 'hideRoot'}>
            <div className='emptyData'>
              <img src={illustrator} alt='' />
              <p className='main_text text-center'>{t('해당 페이지의 권한이 없습니다 관리자에게 문의해 주시기 바랍니다')}</p>
            </div>
          </TuiGridTwoColumnStyle>
        </div>
      </div>
    );
  }

  if (isError) return <IssueGuide />;

  if (isLoading || isFetching)
    return (
      <LoadingModalBackground>
        <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
      </LoadingModalBackground>
    );

  return (
    <div className='contents'>
      <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      <div className={`content-container ${size.innerSize.W >= 1024 ? 'twoColumn column-55 max800' : 'oneColumn'}`}>
        <TuiGridTwoColumnStyle className={size.innerSize.W >= 1024 || viewTable ? 'showRoot' : 'hideRoot'}>
          <SearchOptions align='left'>
            <div className='inputsWrapper'>
              <div className='inputForm-row'>
                <div className='inputForm-col withLabelComCf'>
                  <label htmlFor='lockYn'>{t('잠금유무')}</label>
                  <SelectBox
                    options={lockYnComCdListWithAll}
                    defaultOption={searchOptionLockYn.cdName}
                    state={searchOptionLockYn}
                    setState={setSearchOptionLockYn}
                    stateKey={COMCD_LOCK_YN}
                    initiateKey={searchOptionLockYn[COMCD_LOCK_YN]}
                    filterbar='filter-1-left'
                  />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col withLabelComCf'>
                  <label htmlFor='approvalYn'>{t('승인여부')}</label>
                  <SelectBox
                    options={approvalYnComCdListWithAll}
                    defaultOption={searchOptionApprovalYn.cdName}
                    state={searchOptionApprovalYn}
                    setState={setSearchOptionApprovalYn}
                    stateKey={COMCD_APPROVAL_YN}
                    initiateKey={searchOptionApprovalYn[COMCD_APPROVAL_YN]}
                    filterbar='filter-1-left'
                  />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col withLabelComCf'>
                  <label htmlFor='useYn'>{t('사용유무')}</label>
                  <SelectBox
                    options={useYnComCdListWithAll}
                    defaultOption={searchOptionUseYn.cdName}
                    state={searchOptionUseYn}
                    setState={setSearchOptionUseYn}
                    stateKey={COMCD_USE_YN}
                    initiateKey={searchOptionUseYn[COMCD_USE_YN]}
                    filterbar='filter-1-center'
                  />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <Input placeholder={t('사용자명')} label='' type='text' id='userName' name='userName' state={searchOption} setState={setSearchOption} />
                </div>
              </div>
            </div>
            <div className='inputsWrapper'>
              <div className='secondSearchOption'>
                <div className='flex-basic textBtnGroup'>
                  <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
                </div>
                <div className='flex-basic iconBtnGroup'>
                  <BtnGhost onClick={() => addBtnAction()}>
                    <span className='material-symbols-rounded'>add</span>
                    {t('등록')}
                  </BtnGhost>
                </div>
              </div>
            </div>
          </SearchOptions>
          <TuiGridWrapper componentRef={componentRef}>
            <TuiGrid data={tableState} columns={columns} perPage={15} onClickRow={onClickRow} rowKey={selectedRowKey} height={tuiHeight} frozenCount={frozenCount} />
          </TuiGridWrapper>
        </TuiGridTwoColumnStyle>
        {(size.innerSize.W >= 1024 || !viewTable) && (
          <TuiGridTwoColumnStyle>
            {isNewAdd ? (
              <div className='inputFormsWrapper flexRowEm'>
                {size.innerSize.W < 1024 && <BackButton func={() => backToMain()} />}
                <div className='formTitle'>{t('새로운 사용자')}</div>
              </div>
            ) : (
              <div className='inputFormsWrapper smallTab'>
                {size.innerSize.W < 1024 && <BackButton func={() => backToMain()} />}
                <div className={`tab ${activeTabClick && 'activeTab'}`} role='button' tabIndex={0} onClick={() => onClickSubTab('info')}>
                  {t('사용자 정보')}
                </div>
                <div className={`tab ${!activeTabClick && 'activeTab'} userMenu`} role='button' tabIndex={0} onClick={() => onClickSubTab('menu')}>
                  {t('사용자 메뉴 관리')}
                </div>
              </div>
            )}
            {renderPage()}
          </TuiGridTwoColumnStyle>
        )}
      </div>
    </div>
  );
};

export default Suser1;
