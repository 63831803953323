/**
 * 작성자 : 한영광, 홍선영
 * 날짜 : 2023.05.16
 * 기능 : API 요청 함수
 */

import axios from 'axios';
import Cookies from 'js-cookie';

export const registerId = async (id: string) => {
  const config = {
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
    },
  };

  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/register?userId=${id}`, config)
    .then((res: any) => {
      if (res.status === 210) {
        alert('관리자가 설정을 변경하여 리로드됩니다.');
        window.location.replace('/?oeditYn=Y');
      }
      return res;
    })
    .catch((e: any) => {
      return e.response;
    });
};

export const registerSiteJoinInfo = async (hCd: string, sCd: string) => {
  const config = {
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
    },
  };

  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/register/siteJoinInfo?hCd=${hCd}&sCd=${sCd}`, config)
    .then((res: any) => {
      if (res.status === 210) {
        alert('관리자가 설정을 변경하여 리로드됩니다.');
        window.location.replace('/?oeditYn=Y');
      }
      return res;
    })
    .catch((e: any) => {
      return e.response;
    });
};

export const registerTempPwd = async (userId: string, eMail: string) => {
  const params = new URLSearchParams();
  params.append('userId', userId);
  params.append('eMail', eMail);

  const config = {
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
    },
  };

  return axios
    .patch(`${process.env.REACT_APP_BASE_URL}/register/tempPwd`, params, config)
    .then((res: any) => {
      if (res.status === 210) {
        alert('관리자가 설정을 변경하여 리로드됩니다.');
        window.location.replace('/?oeditYn=Y');
      }
      return res;
    })
    .catch((e: any) => {
      return e.response;
    });
};

export const registerSiteInfo = async (hCd: string) => {
  const config = {
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
    },
  };

  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/register/siteInfo?hCd=${hCd}`, config)
    .then((res: any) => {
      if (res.status === 210) {
        alert('관리자가 설정을 변경하여 리로드됩니다.');
        window.location.replace('/?oeditYn=Y');
      }
      return res;
    })
    .catch((e: any) => {
      return e.response;
    });
};

export const registerSignup = async (signupInfo: any, gCd: string, siteName: any) => {
  const params = new URLSearchParams();

  params.append('userId', signupInfo?.userId);
  params.append('userName', signupInfo?.userName);
  params.append('gCd', gCd);
  params.append('hCd', siteName?.hCd);
  params.append('sCd', siteName?.sCd);
  params.append('sjCd', siteName?.sjCd);
  params.append('hpNum', signupInfo?.hpNum);
  params.append('telNum', signupInfo?.telNum);
  params.append('eMail', signupInfo?.eMail);
  params.append('ipInfo', signupInfo?.ipInfo);
  // params.append('approvalYn', signupInfo?.approvalYn);
  // params.append('pwdCheckyn', signupInfo?.pwdCheckyn);

  const config = {
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
    },
  };

  return axios
    .post(`${process.env.REACT_APP_BASE_URL}/register/signup`, params, config)
    .then((res: any) => {
      if (res.status === 210) {
        alert('관리자가 설정을 변경하여 리로드됩니다.');
        window.location.replace('/?oeditYn=Y');
      }
      return res;
    })
    .catch((e: any) => {
      return e.response;
    });
};

export const registerSignin = async (loginInfo: any) => {
  const params = new URLSearchParams();
  params.append('userId', loginInfo?.id);
  params.append('userPwd', loginInfo?.password);
  const config = {
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
    },
  };

  return axios
    .post(`${process.env.REACT_APP_BASE_URL}/register/signin`, params, config)
    .then((res: any) => {
      if (res.status === 210) {
        alert('관리자가 설정을 변경하여 리로드됩니다.');
        window.location.replace('/?oeditYn=Y');
      }
      return res;
    })
    .catch((e: any) => {
      return e.response;
    });
};

export const registerPwdchangeDate = async (userId: string, accessToken: string) => {
  const params = new URLSearchParams();
  params.append('userId', userId);

  const config = {
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
      authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .patch(`${process.env.REACT_APP_BASE_URL}/register/pwdchangeDate`, params, config)
    .then((res: any) => {
      if (res.status === 210) {
        alert('관리자가 설정을 변경하여 리로드됩니다.');
        window.location.replace('/?oeditYn=Y');
      }
      return res;
    })
    .catch(async (e: any) => {
      if (e.response.status === 401) {
        if (e.response.data.statusCode === 4018) {
          Cookies.remove('prosafeToken');
          Cookies.remove('refreshToken');
          window.location.replace('/?auth=fail');
          return e.response;
        }
        const res2 = await registerRefresh();
        if (res2.status === 200) {
          const res3: any = await registerPwdchangeDate(userId, accessToken);
          return res3;
        }
      }
      return e.response;
    });
};

export const registerPwd = async (userId: string, newPassword: string, accessToken: string) => {
  const params = new URLSearchParams();
  params.append('userId', userId);
  params.append('userPwd', newPassword);

  const config = {
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
      authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .patch(`${process.env.REACT_APP_BASE_URL}/register/pwd`, params, config)
    .then((res: any) => {
      if (res.status === 210) {
        alert('관리자가 설정을 변경하여 리로드됩니다.');
        window.location.replace('/?oeditYn=Y');
      }
      return res;
    })
    .catch(async (e: any) => {
      if (e.response.data.statusCode === 401) {
        const res2 = await registerRefresh();
        if (res2.status === 200) {
          const res3: any = await registerPwd(userId, newPassword, accessToken);
          return res3;
        }
      }
      return e.response;
    });
};

export const registerIp = async () => {
  const config = {
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
    },
  };

  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/register/ip`, config)
    .then((res: any) => {
      if (res.status === 210) {
        alert('관리자가 설정을 변경하여 리로드됩니다.');
        window.location.replace('/?oeditYn=Y');
      }
      return res;
    })
    .catch((e: any) => {
      return e.response;
    });
};

export const registerHeadInfo = async (subDomain: string) => {
  const config = {
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
    },
  };

  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/register/headInfo?subDomain=${subDomain}`, config)
    .then((res: any) => {
      if (res.status === 210) {
        alert('관리자가 설정을 변경하여 리로드됩니다.');
        window.location.replace('/?oeditYn=Y');
      }
      return res;
    })
    .catch((e: any) => {
      return e.response;
    });
};

export const registerEmail = async (userId: string, eMail: string) => {
  const config = {
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
    },
  };

  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/register/email?userId=${userId}&eMail=${eMail}`, config)
    .then((res: any) => {
      if (res.status === 210) {
        alert('관리자가 설정을 변경하여 리로드됩니다.');
        window.location.replace('/?oeditYn=Y');
      }
      return res;
    })
    .catch(async (e: any) => {
      return e.response;
    });
};

export const registerRefresh = async () => {
  const params = new URLSearchParams();
  const refreshToken: any = Cookies.get('refreshToken');
  params.append('refreshToken', refreshToken);
  const config = {
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
    },
  };

  return axios
    .post(`${process.env.REACT_APP_BASE_URL}/register/refresh`, params, config)
    .then((res: any) => {
      const { data } = res.data;
      data?.accessToken && Cookies.set('prosafeToken', data.accessToken);
      data?.refreshToken && Cookies.set('refreshToken', data.refreshToken);
      return res;
    })
    .catch((e: any) => {
      if (window.location.pathname === '/board') {
        window.location.reload();
      } else {
        window.location.replace('/?auth=fail');
        Cookies.remove('prosafeToken');
        Cookies.remove('refreshToken');
      }
      return e.response;
    });
};
