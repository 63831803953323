import styled from 'styled-components';

const TunnelBigoViewContainer = styled.div`
  background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
  overflow: hidden;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: calc(50% - 0.25rem);
  &.alpha25 {
    background-color: ${({ theme }: { theme: any }) => theme.alpha25};
  }
  &.alpha50 {
    background-color: ${({ theme }: { theme: any }) => theme.alpha50};
  }
  &.alpha75 {
    background-color: ${({ theme }: { theme: any }) => theme.alpha75};
  }
  &.alpha100 {
    background-color: ${({ theme }: { theme: any }) => theme.board};
  }
  span {
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    color: ${({ theme }: { theme: any }) => theme.text_disabled};
    border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    padding: 0 0.75rem;
    font-size: 0.75rem;
  }
  div {
    height: 2.25rem;
    padding: 0.5rem 0.75rem;
    font-size: 0.75rem;
    font-weight: 600;
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &.tight {
      padding: 0.375rem 0.5rem;
    }
  }
  &.siteDashboard {
    > div {
      font-size: 0.875rem;
      height: 2.75rem;
      padding: 0 0.75rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 2.75rem;
    }
  }
`;

const TunnelBigoView = ({
  textType,
  text,
  label = true,
  opacity,
  siteDashboard = false,
}: {
  textType: string;
  text: string;
  label?: boolean;
  opacity?: 'alpha25' | 'alpha50' | 'alpha75' | 'alpha100';
  siteDashboard?: boolean;
}) => {
  return (
    <TunnelBigoViewContainer className={`${opacity || ''} ${siteDashboard ? 'siteDashboard' : ''}`}>
      {!label && <span>{textType === 'tjc' ? '작업 내용' : '장비 상태'}</span>}
      <div className={`${!label ? '' : 'tight'}`}>{text}</div>
    </TunnelBigoViewContainer>
  );
};

export default TunnelBigoView;
