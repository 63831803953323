/**
 * 작성자 : 홍선영
 * 날짜 : 2023.04.24
 * 기능 : string 형태의 파라미터값을 받아서 length를 3자리로 제한하는 함수.
 * (e.g 정렬값(string)을 최대 3자리까지만 입력 가능하도록 할 때)
 */

export const limitDigits = (inputElement: string) => {
  const regex = /^\d{1,3}$/;

  if (!regex.test(inputElement)) {
    const validValue = inputElement.match(/\d{1,3}/);

    if (validValue) return validValue[0];
    return '';
  }
  return inputElement;
};
