/**
 * 작성자 : 한영광
 * 날짜 : 2023.05.30
 * 기능 : 본사 사용자 정보
 * 경로 : 사용자 관리 > 사용자 정보 > 사용자 정보 (src/pages/h_user/user1/userInfo)
 */

import { useEffect, Dispatch, SetStateAction, useState } from 'react';
import { toast } from 'react-toastify';
import { userState } from '../../atoms';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { COMCD_LOCK_YN, COMCD_USE_YN, ERROR, USER_GRANT } from '../../_constants';
import { BtnBlue, BtnGray, BtnGreen, BtnLightBlue, BtnRed } from '../Button';
import Input from '../Input';
import IpInput from '../IpInput';
import NumbersInput from '../NumbersInput';
import SelectBox from '../SelectBox';
import { useSetAuth } from '../../utils/useSetAuth';
import { applyBorderStyle } from '../../utils/applyBorderStyle';
import { checkIdDuplicate } from '../../utils/checkIdDuplicate';
import { registerIp } from '../../services/register';
import { useTranslation } from 'react-i18next';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 0.5rem;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;

const Root = styled.div<IUserInputFormStyle>`
  padding: 0.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* width: ${(props) => props.width}; */
  /* height: fit-content; */
  height: calc(100% - 57px);
  overflow: auto;
  justify-content: flex-start;
  .inputForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem;
    flex-grow: 1;
    button {
      height: 2.5rem;
      font-size: 0.75rem;
      flex-shrink: 0;
      padding: 0 0.75rem;
      border-radius: 0.25rem;
    }
    button.gray {
      font-weight: 500;
      color: ${({ theme }: { theme: any }) => theme.color.zinc_200};
    }
    label {
      width: 4.5rem;
      font-weight: 500;
      flex-shrink: 0;
      font-size: 0.875rem;
    }
    .inputForm-group-1280 {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (min-width: 1280px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem 2rem;
      }
    }
    .inputForm-group-1536 {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (min-width: 1536px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem 2rem;
      }
    }
    .inputForm-group-1536.withBtn {
      flex-direction: row;
      gap: 0.5rem;
      button {
        transition: none;
      }
      @media (min-width: 1536px) {
        gap: 1rem 2rem;
        button {
          margin-left: 0;
          width: fit-content;
        }
      }
    }
    .inputForm-group-1536.withBtn.inputColumnFour {
      input {
        padding: 0;
      }
    }
    .inputForm-row.labelInInput {
      display: flex;
      gap: 0.5rem;
      flex-grow: 1;
      > div {
        flex-grow: 1;
      }
    }
    .inputForm-row.labelOutInput,
    .inputForm-row.labelInInput > div > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      input {
        flex-shrink: 1;
        height: 2.5rem;
        font-size: 0.875rem;
        /* padding: 0; */
        padding: 0 0.5rem;
        &:disabled {
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          color: ${({ theme }: { theme: any }) => theme.text_disabled};
        }
      }
      > div.viewOnly {
        height: 2.5rem;
        font-size: 0.875rem;
        background-color: rgba(0, 0, 0, 0.05);
        color: ${({ theme }: { theme: any }) => theme.text_primary};
        border-radius: 0.25rem;
        padding: 0 0.75rem;
        display: flex;
        align-items: center;
      }
      > div {
        flex-grow: 1;
        > div > div > ul {
          height: 2.5rem;
          li {
            display: flex;
            width: 100%;
            max-width: 100%;
            span {
              flex-grow: 1;
              width: 4rem;
            }
          }
        }
      }
    }
    .detailInfo-group {
      margin: 1rem 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      @media (min-width: 1536px) {
        grid-template-columns: repeat(3, 1fr);
      }
      .flex-col.detailInfo {
        flex-grow: 1;
        width: 100%;
        div {
          height: 2.5rem;
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          border-radius: 0.25rem;
          display: flex;
          align-items: center;
          padding: 0 0.5rem;
          font-size: 0.875rem;
        }
      }
    }
  }
  input {
    :disabled {
      background-color: #eeeeee;
    }
  }
  .required {
    &::after {
      content: '*';
      padding: 0 0.2rem;
      color: red;
    }
  }
  @media screen and (max-width: 1023px) {
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    height: fit-content;
    overflow: auto;
  }
`;
interface IUserInputFormStyle {
  width: string;
  flexDirection?: string;
}

interface IUserInputForm {
  style: IUserInputFormStyle;
  state: any;
  setState: Dispatch<SetStateAction<any>>;
  isNewAdd?: boolean;
  userInfoInputFormRef: React.RefObject<any>;
  onClickNewAdd: () => void;
  onClickSave: () => void;
  onClickApproval: () => void;
  onClickDelete: () => void;
  onClickInitPwd: () => void;
  typeName?: string;
  telNumState: any;
  setTelNumState: Dispatch<SetStateAction<any>>;
  hpNumState: any;
  setHpNumState: Dispatch<SetStateAction<any>>;
  ipInfoState: any;
  setIpInfoState: Dispatch<SetStateAction<any>>;
  gCd: any;
  setGCd: Dispatch<SetStateAction<any>>;
  lockYn: any;
  useYn: any;
  setUseYn: Dispatch<SetStateAction<any>>;
  setLockYn: Dispatch<SetStateAction<any>>;
  lockYnComCdList: any;
  useYnComCdList: any;
  userGrantComCdList: any;
  userIdRef: React.RefObject<any>;
  userNameRef: React.RefObject<any>;
  eMailRef: React.RefObject<any>;
  isSaveClicked: boolean;
}

const UserInfoInputFormHead = ({
  style,
  state,
  setState,
  isNewAdd,
  userInfoInputFormRef,
  onClickNewAdd,
  onClickSave,
  onClickApproval,
  onClickDelete,
  onClickInitPwd,
  typeName,
  telNumState,
  setTelNumState,
  hpNumState,
  setHpNumState,
  ipInfoState,
  setIpInfoState,
  gCd,
  setGCd,
  lockYn,
  useYn,
  setUseYn,
  setLockYn,
  lockYnComCdList,
  useYnComCdList,
  userGrantComCdList,
  userIdRef,
  userNameRef,
  eMailRef,
  isSaveClicked,
}: IUserInputForm) => {
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const userInfo = useRecoilValue(userState);
  const telLengthState = { num1: 3, num2: 4, num3: 4 }; // 전화번호 입력 길이
  const [hpNumLastInput, setHpNumLastInput] = useState(false); // 휴대번호 마지막인풋 길이충족 여부
  const [telNumLastInput, setTelNumLastInput] = useState(false); // 전화번호 마지막인풋 길이충족 여부

  useEffect(() => {
    if (telNumLastInput) {
      eMailRef.current?.focus();
    }
  }, [telNumLastInput]);

  useEffect(() => {
    setState((prev: any) => ({ ...prev, gCd: gCd[USER_GRANT], gName: gCd.cdName }));
  }, [gCd[USER_GRANT]]);

  useEffect(() => {
    if (useYn[COMCD_USE_YN] !== '') {
      setState((prev: any) => ({ ...prev, useYn: useYn[COMCD_USE_YN] }));
    }
  }, [useYn[COMCD_USE_YN]]);

  useEffect(() => {
    if (lockYn[COMCD_LOCK_YN] !== '') {
      setState((prev: any) => ({ ...prev, lockYn: lockYn[COMCD_LOCK_YN] }));
    }
  }, [lockYn[COMCD_LOCK_YN]]);

  // 현재IP버튼 API호출 후 ip setState
  const onClickGetCurrentIp = async () => {
    const res = await registerIp();
    const { data, statusCode } = res.data;

    if (statusCode === 200) {
      const splitNumber = data.split('.');
      setIpInfoState((prev: any) => ({ ...prev, num1: splitNumber[0], num2: splitNumber[1], num3: splitNumber[2], num4: splitNumber[3] }));
      // setState((prev: any) => ({ ...prev, ipInfo: data }));
    } else {
      // toast.error(t(ERROR));
    }
  };

  const onClickCheckDuplicateId = async () => {
    const { isIdDuplicate, idDuplicateAlertMsg, resStatusCode } = await checkIdDuplicate(state.userId);

    if (isIdDuplicate) return toast.warning(t(idDuplicateAlertMsg));
    if (resStatusCode === 200) setState((prev: any) => ({ ...prev, isDuplicateChecked: true }));
    if (resStatusCode === 409) setState((prev: any) => ({ ...prev, userId: '' }));

    return toast.warning(t(idDuplicateAlertMsg));
  };

  // 사용자 권한에 따라 버튼 렌더링
  const renderApprovalBtn = () => {
    if (auth.createAuth) {
      return (
        !isNewAdd &&
        state.approvalYn === 'N' && (
          <BtnGreen className='approvalBtn' onClick={onClickApproval}>
            {t('승인')}
          </BtnGreen>
        )
      );
    }
    return null;
  };
  const renderCreateBtn = () => {
    if (auth.createAuth) {
      return <BtnBlue onClick={onClickNewAdd}>{t('신규등록')}</BtnBlue>;
    }
    return null;
  };
  const renderSaveBtn = () => {
    if (auth.createAuth || auth.updateAuth) {
      return <BtnBlue onClick={onClickSave}>{t('저장')}</BtnBlue>;
    }
    return null;
  };
  const renderDeleteBtn = () => {
    if (auth.deleteAuth) {
      return <BtnRed onClick={onClickDelete}>{t('삭제')}</BtnRed>;
    }
    return null;
  };
  const renderInitPwdBtn = () => {
    if (auth.createAuth || auth.updateAuth) {
      return <BtnLightBlue onClick={onClickInitPwd}>{t('비밀번호 초기화')}</BtnLightBlue>;
    }
    return null;
  };

  return (
    <Root width={style.width}>
      <div className='inputForm' ref={userInfoInputFormRef}>
        <div className='inputForm-group-1536'>
          <div className='inputForm-row labelOutInput'>
            <label className='required' htmlFor='auth'>
              {t('권한')}
            </label>
            <div>
              <SelectBox
                options={userGrantComCdList}
                defaultOption={isNewAdd ? t('미선택') : t(state.gName)}
                state={gCd}
                setState={setGCd}
                stateKey={USER_GRANT}
                initiateKey={gCd[USER_GRANT]}
                getBorderStyle={isSaveClicked ? applyBorderStyle(gCd[USER_GRANT], 'red', 'subCd') : undefined}
              />
            </div>
          </div>
          <div className='inputForm-row labelInInput'>
            <Input
              className='required'
              label={t('아이디')}
              type='text'
              id='userId'
              name='userId'
              state={state}
              setState={setState}
              disabled={!isNewAdd}
              trim
              inputRef={userIdRef}
              getBorderStyle={isSaveClicked ? applyBorderStyle(state.userId, 'red', 'userId') : undefined}
            />
            {isNewAdd && (
              <BtnGray className='btn-small' onClick={onClickCheckDuplicateId}>
                {t('중복검사')}
              </BtnGray>
            )}
          </div>
        </div>
        {/* <div className='inputForm-group-1536 withBtn' /> */}
        <div className='inputForm-group-1536'>
          <div className='inputForm-row labelInInput'>
            <Input
              className='required'
              label={t('이름')}
              type='text'
              id='userName'
              name='userName'
              state={state}
              setState={setState}
              inputRef={userNameRef}
              getBorderStyle={isSaveClicked ? applyBorderStyle(state.userName, 'red', 'userName') : undefined}
            />
          </div>
          {!isNewAdd && (
            <div className='inputForm-row labelOutInput'>
              <div className='viewOnly'>{userInfo.gCd === 'MM' && `(${state.userPwd})`}</div>
            </div>
          )}
        </div>
        <div className='inputForm-group-1536'>
          <div className='inputForm-row labelOutInput'>
            <label htmlFor='password'>{t('비밀번호')}</label>
            {renderInitPwdBtn()}
          </div>
          <div className='inputForm-row labelInInput'>
            <Input
              className='required'
              label={t('이메일')}
              type='text'
              id='eMail'
              name='eMail'
              state={state}
              setState={setState}
              trim
              inputRef={eMailRef}
              getBorderStyle={isSaveClicked ? applyBorderStyle(state.eMail, 'red', 'eMail') : undefined}
            />
          </div>
        </div>
        <div className='inputForm-group-1536'>
          <div className='inputForm-row labelOutInput'>
            <label htmlFor='hpNum' className='required'>
              {t('휴대폰번호')}
            </label>
            <NumbersInput state={hpNumState} setState={setHpNumState} lengthState={telLengthState} setIsLastInputCompleted={setHpNumLastInput} isSaveClicked={isSaveClicked} />
          </div>
          <div className='inputForm-row labelOutInput'>
            <label htmlFor='telNum'>{t('전화번호')}</label>
            <NumbersInput state={telNumState} setState={setTelNumState} lengthState={telLengthState} isFirstInputFocused={hpNumLastInput} setIsLastInputCompleted={setTelNumLastInput} />
          </div>
        </div>

        <div className='inputForm-group-1536 withBtn inputColumnFour'>
          <div className='inputForm-row labelOutInput'>
            <label htmlFor='hpNum'>{t('접속 IP')}</label>
            <IpInput stateName='ipInfo' separator='.' state={ipInfoState} setState={setIpInfoState} setInfoState={setState} />
          </div>
          <div className='inputForm-row labelOutInput'>
            <BtnGray className='btn-small' onClick={onClickGetCurrentIp}>
              {t('현재 PC IP 적용')}
            </BtnGray>
          </div>
        </div>
        <div className='inputForm-group-1280'>
          <div className='inputForm-row labelOutInput'>
            <label htmlFor='lockYn'>{t('잠금유무')}</label>
            <SelectBox
              options={lockYnComCdList}
              defaultOption={isNewAdd ? t('미잠금') : t(state.lockYn)}
              state={lockYn}
              setState={setLockYn}
              stateKey={COMCD_LOCK_YN}
              initiateKey={lockYn[COMCD_LOCK_YN]}
              filterbar='filter-1-left'
            />
          </div>
          <div className='inputForm-row labelOutInput'>
            <label htmlFor='useYn'>{t('사용유무')}</label>
            <SelectBox
              options={useYnComCdList}
              defaultOption={isNewAdd ? t('사용') : t(state.useYn)}
              state={useYn}
              setState={setUseYn}
              stateKey={COMCD_USE_YN}
              initiateKey={useYn[COMCD_USE_YN]}
              filterbar='filter-1-left'
            />
          </div>
          {!isNewAdd && (
            <div className='inputForm-row labelOutInput'>
              <label htmlFor='useYn'>{t('승인여부')}</label>
              <div className='viewOnly'>{state.approvalYn === 'Y' ? t('승인') : t('미승인')}</div>
            </div>
          )}
          <div className='inputForm-row labelInInput'>
            <Input label={t('비고')} type='text' id='bigo' name='bigo' state={state} setState={setState} maxLength={200} />
          </div>
        </div>
        {!isNewAdd && (
          <div className='detailInfo-group'>
            <div className='flex-col detailInfo'>
              <label htmlFor='loginDate'>{t('로그인 일시')}</label>
              <div>{state.loginDate}</div>
            </div>
            <div className='flex-col detailInfo'>
              <label htmlFor='loginerrorCount'>{t('로그인 에러 횟수')}</label>
              <div>{state.loginerrorCount}</div>
            </div>

            <div className='flex-col detailInfo'>
              <label htmlFor='pwdChangeDate'>{t('비밀번호 변경일')}</label>
              <div>{state.pwdChangeDate}</div>
            </div>
            <div className='flex-col detailInfo'>
              <label htmlFor='pwdCheckYn'>{t('재입력 유무')}</label>
              <div>{state.pwdCheckYn === 'Y' ? t('적용') : t('미적용')}</div>
            </div>

            <div className='flex-col detailInfo'>
              <label htmlFor='ip'>{t('최종접속 IP')}</label>
              <div>{state.ip}</div>
            </div>

            <div className='flex-col detailInfo'>
              <label htmlFor='createDate'>{t('등록일자')}</label>
              <div>{state?.wDate}</div>
            </div>
            <div className='flex-col detailInfo'>
              <label htmlFor='createUser'>{t('등록자')}</label>
              <div>{state.writer}</div>
            </div>

            <div className='flex-col detailInfo'>
              <label htmlFor='updateDate'>{t('수정일자')}</label>
              <div>{state.eDate}</div>
            </div>
            <div className='flex-col detailInfo'>
              <label htmlFor='updateUser'>{t('수정자')}</label>
              <div>{state.editor}</div>
            </div>
          </div>
        )}
      </div>
      <ButtonsWrapper>
        {renderDeleteBtn()}
        {renderApprovalBtn()}
        {/* {renderCreateBtn()} */}
        {renderSaveBtn()}
      </ButtonsWrapper>
    </Root>
  );
};

export default UserInfoInputFormHead;
