import axios from 'axios';
/**
 * 작성자 : 한영광
 * 날짜 : 2023.11.30
 * 기능 : API 요청 함수에서 공통으로 사용되는 함수 모음
 */

import { registerRefresh } from './register';
import Cookies from 'js-cookie';

type ApiParam = {
  path: string;
  contentType?: string;
  req?: any;
};
export const apiGet = async ({ path, contentType, req }: ApiParam): Promise<any> => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}${path}`, config({ method: 'get', contentType, req }))
    .then(response)
    .catch((error) => catchResult({ error, func: () => apiGet({ path, contentType, req }) }));
};
export const apiPost = async ({ path, contentType, req }: ApiParam): Promise<any> => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}${path}`, req, config({ method: 'post', contentType }))
    .then(response)
    .catch((error) => catchResult({ error, func: () => apiPost({ path, contentType, req }) }));
};
export const apiPatch = async ({ path, contentType, req }: ApiParam): Promise<any> => {
  return axios
    .patch(`${process.env.REACT_APP_BASE_URL}${path}`, req, config({ method: 'patch', contentType }))
    .then(response)
    .catch((error) => catchResult({ error, func: () => apiPatch({ path, contentType, req }) }));
};
export const apiDelete = async ({ path, contentType, req }: ApiParam): Promise<any> => {
  return axios
    .delete(`${process.env.REACT_APP_BASE_URL}${path}`, config({ method: 'delete', contentType, req }))
    .then(response)
    .catch((error) => catchResult({ error, func: () => apiDelete({ path, contentType, req }) }));
};

export const externalApiPost = async (path: string, body: any): Promise<any> => {
  return axios
    .post(`${path}`, body)
    .then(response)
    .catch((error) => catchResult({ error, func: () => externalApiPost(path, body) }));
};

type Config = {
  method: string;
  contentType?: string;
  req?: any;
};
export const config = ({ method, contentType = 'application/x-www-form-urlencoded', req }: Config) => {
  const accessToken = Cookies.get('prosafeToken');
  const headers = {
    'Content-type': `${contentType}`,
    authorization: `Bearer ${accessToken}`,
  };
  switch (method) {
    case 'get':
      return {
        headers,
        params: req,
      };
    case 'post':
      return {
        headers,
      };
    case 'patch':
      return {
        headers,
      };
    case 'delete':
      return {
        headers,
        data: req,
      };
    default:
      return {};
  }
};

export const response = (res: any) => {
  if (res.status === 210) {
    alert('관리자가 설정을 변경하여 리로드됩니다.');
    window.location.replace('/?oeditYn=Y');
  }
  return res;
};

export const catchResult = async ({ error: error, func }: { error: any; func: () => Promise<any> }) => {
  // if (error?.response?.status === 504) {
  //   const res2: any = func();
  //   return res2;
  // }
  if (error?.response?.status === 401) {
    if (error.response.data.statusCode === 4018) {
      Cookies.remove('prosafeToken');
      Cookies.remove('refreshToken');
      window.location.replace('/?auth=fail');
      return error.response;
    }
    const res2 = await registerRefresh();
    if (res2.status === 200) {
      const res3: any = func();
      return res3;
    }
  }
  return error.response;
};
