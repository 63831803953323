/**
 * 작성자 : 홍선영
 * 날짜 : 2024.01.15
 * 기능 : 안전관리-긴급상황정보 정보 (현장용) 모달
 */

import { useState, useEffect, Dispatch, SetStateAction, useRef } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';

import { userState } from '../../atoms';
import { IAuth, IModal } from 'customTypes';
import { ModalBackground, Modal } from '../../assets/styles/Modal';
import { BtnBlue } from '../Button';
import Input from '../Input';

import { ACTION_STATUS2, hourList2, minList2 } from '../../_constants';
import ReactSelect from '../ReactSelect';
import { logPost } from '../../services/log';
import useOnKeydownF9 from '../../utils/useOnKeydownF9';
import { todayYYYYMMDD } from '../../utils/formatDate';
import { useTranslation } from 'react-i18next';
import useOnKeydownPgUp from '../../utils/useOnKeydownPgUp';
import useOnKeydownPgDn from '../../utils/useOnKeydownPgDn';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiPatch } from '../../services/_common';
import SelectBox from '../SelectBox';

interface IProps {
  refetch: () => void;
  reportArray: any[]; // 보고서목록데이터
  setReportArray: Dispatch<SetStateAction<any[]>>;
  setOpenModal: Dispatch<SetStateAction<IModal>>;
  auth: IAuth;
  code?: number;
  index: number;
  setRowKey: Dispatch<SetStateAction<any>>;
  listCount: number;
}

const EmergencyStatusModal = ({ refetch, setOpenModal, auth, code, index, reportArray, setReportArray, setRowKey, listCount }: IProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const userInfo = useRecoilValue(userState);
  const userNameRef = useRef<HTMLInputElement>(null);
  const INIT_ACTION_STATUS = { type: 'actionStatus', actionStatus: '0', cdName: t('미조치') };
  const [openSubModal, setOpenSubModal] = useState<IModal>({ status: false, type: '', isOverlappingModal: true });
  const [isViewMode, setIsViewMode] = useState(!auth.createAuth && !auth.updateAuth); // 권한에 따른 뷰 or 수정모드 여부
  const [hour, setHour] = useState({ label: '00', value: '00' });
  const [min, setMin] = useState({ label: '00', value: '00' });
  const [sec, setSec] = useState({ label: '00', value: '00' });
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const { isPgUpPressed, setIsPgUpPressed } = useOnKeydownPgUp();
  const { isPgDnPressed, setIsPgDnPressed } = useOnKeydownPgDn();
  const [currentIndex, setCurrentIndex] = useState<number>(index);
  const [rowState, setRowState] = useState<any>({});
  const [status, setStatus] = useState(INIT_ACTION_STATUS);
  const newHourList = [{ label: '선택', value: '' }, ...hourList2];
  const newMinList = [{ label: '선택', value: '' }, ...minList2];

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearchWorker();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    if (isPgUpPressed) {
      onClickPrev();
      setIsPgUpPressed(false);
    }
  }, [isPgUpPressed]);

  useEffect(() => {
    if (isPgDnPressed) {
      onClickNext();
      setIsPgDnPressed(false);
    }
  }, [isPgDnPressed]);

  // 근로자 검색
  const onClickSearchWorker = async () => {
    if (!openSubModal.status) setOpenSubModal((prev) => ({ ...prev, status: true, type: 'workerList' }));
  };

  useEffect(() => {
    if (reportArray?.length > 0 && currentIndex > -1 && ACTION_STATUS2) {
      setIsSaveClicked(false);
      setRowState(reportArray[currentIndex]);
      setHour({ label: reportArray[currentIndex].actionDate.substring(8, 10) || '선택', value: reportArray[currentIndex].actionDate.substring(8, 10) || '' });
      setMin({ label: reportArray[currentIndex].actionDate.substring(10, 12) || '선택', value: reportArray[currentIndex].actionDate.substring(10, 12) || '' });
      setSec({ label: reportArray[currentIndex].actionDate.substring(12, 14) || '00', value: reportArray[currentIndex].actionDate.substring(12, 14) || '00' });
    }
  }, [currentIndex]);

  useEffect(() => {
    setStatus({
      type: 'actionStatus',
      actionStatus: rowState.actionStatus,
      cdName: ACTION_STATUS2?.find((el: any) => el.actionStatus === rowState.actionStatus)?.cdName || t('미조치'),
    });
  }, [rowState.actionStatus, currentIndex]);

  const onClickClose = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };

  const onClickPrev = () => {
    if (reportArray) {
      if (currentIndex !== undefined && currentIndex > 0) {
        const newIndex = currentIndex - 1;
        if (listCount) {
          navigate({
            pathname: location.pathname,
            search: `?pageNum=${Math.floor(newIndex / listCount) + 1}`,
          });
        }
        if (setRowKey) setRowKey(newIndex);
        setCurrentIndex(newIndex);
      } else toast.info(t('이전 내역이 없습니다'));
    }
  };

  const onClickNext = () => {
    if (reportArray) {
      if (currentIndex !== undefined && reportArray.length - 1 > currentIndex) {
        const newIndex = currentIndex + 1;
        if (listCount) {
          navigate({
            pathname: location.pathname,
            search: `?pageNum=${Math.floor(newIndex / listCount) + 1}`,
          });
        }
        if (setReportArray) setReportArray(reportArray);
        if (setRowKey) setRowKey(newIndex);
        setCurrentIndex(newIndex);
      } else toast.info(t('다음 내역이 없습니다'));
    }
  };

  const onClickSave = async () => {
    setIsSaveClicked(true);
    const { eaIdx, userName } = rowState;
    const timeValue = hour.value !== '' && min.value !== '' && sec.value !== '' ? `${hour.value}${min.value}${sec.value}` : '';
    const req = { eaIdx, actionStatus: status.actionStatus, userName: userName?.trim(), actionDate: `${todayYYYYMMDD()}${timeValue}` };
    const res = await apiPatch({ path: '/safe/ea', req });
    const { statusCode, message } = res.data;
    if (statusCode === 200) {
      refetch();
      toast.success(t(message));
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '긴급 상황 정보(팝업)',
        action: '저장',
        etc: rowState.eaIdx,
      });
    }
  };

  return (
    <ModalBackground onClick={onClickClose} role='presentation'>
      <Modal>
        <div
          className='modal height-fit signup'
          role='presentation'
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className='inputForm-head'>
            <div className='modalHeaderTitle'>{t('긴급 상황 정보')}</div>
            <div className='closeBtn' onClick={onClickClose} role='presentation'>
              <span className='material-symbols-rounded'>close</span>
            </div>
          </div>
          {/* <div className={`inputForm-body ${isViewMode ? 'view' : undefined}`}> */}
          <div className='black flexColumn flex-between col-w100'>
            <div className='filter-option-grid grid1'>
              <div className='viewOnly'>
                <label htmlFor='dGubun' className='w7rem'>
                  {t('구분')}
                </label>
                <div>{rowState.dGubun}</div>
              </div>
              <div className='viewOnly'>
                <label htmlFor='dLevel' className='w7rem'>
                  {t('단계')}
                </label>
                <div>{rowState.dLevel}</div>
              </div>
              <div className='viewOnly'>
                <label htmlFor='time' className='w7rem'>
                  {t('발생 시간')}
                </label>
                <div>{rowState.time}</div>
              </div>
              <div className='viewOnly'>
                <label htmlFor='elapsedTime' className='w7rem'>
                  {t('경과 시간')}
                </label>
                <div>{rowState.elapsedTime}</div>
              </div>
              <div className='viewOnly'>
                <label htmlFor='dType' className='w7rem'>
                  {t('유형')}
                </label>
                <div>{rowState.dType}</div>
              </div>
              <div className='viewOnly'>
                <label htmlFor='dContents' className='w7rem'>
                  {t('구역 / 관련내용')}
                </label>
                <div>{rowState.dContents}</div>
              </div>
              <div className='selectWrapper'>
                <label htmlFor='actionStatus'>{t('조치상태')}</label>
                <SelectBox
                  options={ACTION_STATUS2}
                  defaultOption={t(status.cdName) || t('미조치')}
                  state={status}
                  setState={setStatus}
                  stateKey='actionStatus'
                  initiateKey={status.actionStatus}
                  filterbar='filter-1-left'
                  optionHeight='height-md'
                />
              </div>
              <Input className='w7rem' label={t('조치자')} type='text' name='userName' inputRef={userNameRef} state={rowState} setState={setRowState} />
              <div className='flex-basic time'>
                <label className='w7rem' htmlFor='pwdLimitCount'>
                  {t('조치시간')}
                </label>
                <div className='flex-basic'>
                  <div className='flex-basic'>
                    <ReactSelect
                      defaultValue={{ label: '선택', value: '' }}
                      options={newHourList}
                      value={hour}
                      state={hour}
                      setState={setHour}
                      isClearable={false}
                      isSearchable={false}
                      optionsPlacement='top'
                    />
                    <span>{t('시')}</span>
                  </div>
                  <div className='flex-basic'>
                    <ReactSelect
                      defaultValue={{ label: '선택', value: '' }}
                      options={newMinList}
                      value={min}
                      state={min}
                      setState={setMin}
                      isClearable={false}
                      isSearchable={false}
                      optionsPlacement='top'
                    />
                    <span>{t('분')}</span>
                  </div>
                  <div className='flex-basic'>
                    <ReactSelect
                      defaultValue={{ label: '선택', value: '' }}
                      options={newMinList}
                      value={sec}
                      state={sec}
                      setState={setSec}
                      isClearable={false}
                      isSearchable={false}
                      optionsPlacement='top'
                    />
                    <span>{t('초')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer flex-between'>
            <div className='flex-basic arrows'>
              <BtnBlue onClick={onClickPrev}>
                <span className='material-symbols-rounded'>arrow_back_ios_new</span>
                <p>{t('이전')}</p>
                <div>
                  <p>Page</p>
                  <p>Up</p>
                </div>
              </BtnBlue>
              <BtnBlue onClick={onClickNext}>
                <div>
                  <p>Page</p>
                  <p>Down</p>
                </div>
                <p>{t('다음')}</p>
                <span className='material-symbols-rounded'>arrow_forward_ios</span>
              </BtnBlue>
            </div>
            <div className='flex-basic'>{(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={onClickSave}>{t('저장')}</BtnBlue>}</div>
          </div>
        </div>
      </Modal>
    </ModalBackground>
  );
};

export default EmergencyStatusModal;
