import { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import Cookies from 'js-cookie';

import { ERROR } from '../../_constants';
import { IModal, ILogin } from 'customTypes';
import { IUser, userState } from '../../atoms';
import Input from '../Input';
import { BtnBlue, BtnWhite } from '../Button';
import { ModalBackground, Modal } from '../../assets/styles/Modal';
import { registerEmail } from '../../services/register';

const IpCheckModal = ({ openModal, setOpenModal, state }: ILogin) => {
  const { t } = useTranslation();
  const setUserInfo = useSetRecoilState<IUser>(userState);
  const [signupInfo, setSignupInfo] = useState({
    userId: '',
    isDuplicateChecked: false,
    userName: '',
    hpNum: '',
    telNum: '',
    eMail: '',
    ipInfo: '',
  });

  // 닫기버튼 클릭
  const onClickClose = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };

  // 사용자 IP검증팝업에서 이메일주소 입력완료 버튼 클릭 시
  // email로 사용자 검증하고 검증 완료되면 대시보드로 이동
  const onClickCheckUserEmail = async () => {
    const res = await registerEmail(state.id, signupInfo.eMail);
    const { statusCode, message } = res.data;
    if (statusCode === 200) {
      Cookies.set('prosafeToken', state.accessToken);
      Cookies.set('refreshToken', state.refreshToken);
      setUserInfo((prev) => ({
        ...prev,
        ...state.userInfo,
        userMenuList: state.userMenuList,
      }));
    } else {
      // toast.error(t(ERROR));
    }

    onClickClose();
  };

  // 사용자 IP검증 팝업에서 이메일 검증완료 하지 않고 취소버튼을 누른경우,
  // accessToken쿠키제거하고 팝업닫기
  const onClickRemoveAccessToken = () => {
    Cookies.remove('prosafeToken');
    onClickClose();
  };

  return (
    <ModalBackground>
      <Modal>
        <div className='modal fitHeight'>
          <div className='inputForm-head'>
            <h5 className='bold'>{openModal?.title}</h5>
          </div>
          <div className='inputForm-body'>
            <div className='modal-contents'>
              <div className='inputForm-row'>
                <div className='inputForm-col col-w100'>
                  <div className='inputFormText'>
                    <span className='red'>{t('접속 IP 정보와 실제 접속한 IP 정보가 틀립니다.')}</span> <br />
                    {t('본인 확인 절차로 시스템 등록 시 입력한')} <span className='red'>{t('이메일 정보가 필요합니다.')} </span>
                    <br />
                    {t('입력한 이메일 주소가 맞지 않을 경우 시스템 사용을 할 수 없습니다.')}
                  </div>
                </div>
              </div>
              <div className='inputForm-col'>
                <div className='inputForm-col'>
                  <Input id='eMail' name='eMail' label={t('이메일')} type='text' state={signupInfo} setState={setSignupInfo} placeholder={t('이메일 주소')} />
                </div>
              </div>
            </div>
            <div className='modal-footer flex-end'>
              <BtnBlue onClick={onClickCheckUserEmail}>{t('입력 완료')}</BtnBlue>
              <BtnWhite onClick={onClickRemoveAccessToken}>{t('취소')}</BtnWhite>
            </div>
          </div>
        </div>
      </Modal>
    </ModalBackground>
  );
};

export default IpCheckModal;
