/**
 * 작성자 : 홍선영
 * 날짜 : 2024.02.29
 * 경로 : 안전관리-AI카메라 상단 검색필터 (현장용)
 */

import { SearchOptions } from '../../../assets/styles/SearchOptions';
import SelectBox from '../../../components/SelectBox';
import RangePicker, { onChangeRangeInput } from '../../../components/RangePicker';
import { BtnGhost } from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { SetStateAction, useEffect, useState } from 'react';
import { setComCdListState } from '../../../utils/setComCdListState';
import { IComCdList } from 'customTypes';
import { LIST_COUNT } from '../../../_constants';
import { toast } from 'react-toastify';
import { todayYYYYMMDD } from '../../../utils/formatDate';
import useOnKeydownF9 from '../../../utils/useOnKeydownF9';
import dayjs from 'dayjs';
import ShortcutButton from '../../../components/button/ShortcutButton';
import { logPost } from '../../../services/log';
import { useRecoilValue } from 'recoil';
import { IUser, userState } from '../../../atoms';

interface NvrSelect {
  type: string;
  nCd: string;
  cdName: string;
}

interface CameraSelect {
  type: string;
  cCd: string;
  cdName: string;
}

interface ListCountSelect {
  type: string;
  LIST_COUNT: string;
  cdName: string;
}

interface DateRange {
  start: string;
  end: string;
}

interface IProps {
  nvrList: NvrSelect[];
  cameraList: CameraSelect[];
  nCd: NvrSelect;
  setnCd: React.Dispatch<SetStateAction<NvrSelect>>;
  cCd: CameraSelect;
  setcCd: React.Dispatch<SetStateAction<CameraSelect>>;
  userInputDateRange: DateRange;
  setUserInputDateRange: React.Dispatch<SetStateAction<DateRange>>;
  listCount: ListCountSelect;
  setListCount: React.Dispatch<SetStateAction<ListCountSelect>>;
  patchUserMenuAPI: (el: any) => Promise<void>;
  dataCount: number;
  refetch: () => void;
}

const SearchFilterForSafetyManagement = ({
  nvrList,
  nCd,
  setnCd,
  cameraList,
  cCd,
  setcCd,
  userInputDateRange,
  listCount,
  setListCount,
  patchUserMenuAPI,
  setUserInputDateRange,
  dataCount,
  refetch,
}: IProps) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue<IUser>(userState);
  const toDay = todayYYYYMMDD();
  const MESSAGE = t('NVR을 선택하세요');
  const [visibleRangePicker, setVisibleRangePicker] = useState(false);
  const [pickerDateRange, setPickerDateRange] = useState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
  const [listCountComCdList, setListCountComCdList] = useState<IComCdList[]>([]); // 검색줄수 공통코드
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅

  useEffect(() => {
    setComCdListState(LIST_COUNT, setListCountComCdList, false);
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: 'AI 카메라 안전관리 사진',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    if (pickerDateRange[0].startDate) {
      setUserInputDateRange((prev) => ({ ...prev, start: dayjs(pickerDateRange[0].startDate).format('YYYY-MM-DD') }));
    }
    if (pickerDateRange[0].endDate) {
      setUserInputDateRange((prev) => ({ ...prev, end: dayjs(pickerDateRange[0].endDate).format('YYYY-MM-DD') }));
    }
  }, [pickerDateRange[0].startDate, pickerDateRange[0].endDate]);

  const onClickRangeInput = () => {
    if (!visibleRangePicker) setVisibleRangePicker(true);
  };

  const initiateSearchOptions = () => {
    setnCd({ type: 'nCd', nCd: 'A', cdName: t('전체') });
    setcCd({ type: 'cCd', cCd: 'A', cdName: t('전체') });
  };

  // 검색 버튼 클릭
  const onClickSearch = () => {
    if (userInputDateRange.start === '' || userInputDateRange.end === '') {
      toast.warning(t('날짜를 선택하세요'));
    } else if (userInputDateRange.end && userInputDateRange.start > userInputDateRange.end) {
      toast.warning(t('날짜를 다시 입력하세요'));
      setUserInputDateRange((prev) => ({ ...prev, end: '' }));
    } else {
      refetch();
      setVisibleRangePicker(false);
    }
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    Object.keys(userInputDateRange).map((el) => {
      return setUserInputDateRange((prev) => ({ ...prev, [el]: '', start: toDay, end: toDay }));
    });
    setPickerDateRange([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
    initiateSearchOptions();
  };

  return (
    <SearchOptions>
      <div className='inputsWrapper'>
        <div className='inputForm-col withLabelComCf'>
          <label htmlFor='nCd'>{t('NVR 명')}</label>
          <SelectBox options={nvrList} defaultOption={t('전체')} state={nCd} setState={setnCd} stateKey='nCd' initiateKey={nCd.nCd} filterbar='filter-1-left' optionHeight='height-md' />
        </div>
        <div className='inputForm-col withLabelComCf'>
          <label htmlFor='cCd'>{t('카메라 명')}</label>
          <SelectBox
            options={cameraList}
            defaultOption={t('전체')}
            state={cCd}
            setState={setcCd}
            stateKey='cCd'
            initiateKey={cCd.cCd}
            filterbar='filter-1-left'
            optionHeight='height-md'
            clickAble={nCd.nCd === 'A'}
            toastContent={MESSAGE}
          />
        </div>
        <div className='inputForm-row-fit'>
          <span className='calendarLabel'>{t('촬영일자')}</span>
          <div className='inputForm-col'>
            <div className='flex-basic'>
              <input
                id='startInput'
                type='text'
                value={userInputDateRange.start}
                onMouseDown={onClickRangeInput}
                onChange={(e) => onChangeRangeInput(e, 'start', setPickerDateRange, setUserInputDateRange)}
              />
              <span className='inputDash'> ~ </span>
              <input
                id='endInput'
                type='text'
                value={userInputDateRange.end}
                onMouseDown={onClickRangeInput}
                onChange={(e) => onChangeRangeInput(e, 'end', setPickerDateRange, setUserInputDateRange)}
              />
            </div>
            {visibleRangePicker && (
              <div className='rangePickerWrapper'>
                <RangePicker state={pickerDateRange} setState={setPickerDateRange} setVisible={setVisibleRangePicker} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='inputsWrapper'>
        <div className='secondSearchOption'>
          <div className='flex-basic textBtnGroup'>
            <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
            {/* <BtnGhost onClick={onClickSearch} className='searchBtn'>
              {t('검색')}
              <span className='shortcut-f9'>F9</span>
            </BtnGhost> */}
            <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
            <div className='searchResult'>
              {t('총')}
              <span>{dataCount}</span>
              {t('개')}
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col withLabelComCf'>
                <label htmlFor='listCount'>{t('보기 설정')}</label>
                <SelectBox
                  options={listCountComCdList}
                  defaultOption={listCount.cdName}
                  state={listCount}
                  setState={setListCount}
                  stateKey={LIST_COUNT}
                  initiateKey={listCount[LIST_COUNT]}
                  setTableLinesAPI={() => patchUserMenuAPI}
                  optionHeight='height-sm'
                  rsearch
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SearchOptions>
  );
};

export default SearchFilterForSafetyManagement;
