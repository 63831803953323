/**
 * 2023.04.25 한영광
 * 마스터일반관리 -> 사용자관리 -> 본사 정보 탭 페이지 인풋폼
 * pages/m_common/common2/headInfo.tsx 에서 사용
 *
 * 2023.05.10 홍선영
 * 수정사항: 띄어쓰기 방지 해제
 *          우편번호 숫자만 입력 가능하게 수정
 *          사업자번호 마지막칸 입력 후 대표자명으로 포커스 이동 추가
 *          등록일자/수정일자 표시 오류 수정
 *
 * 2024.03.11 김광민
 * 수정사항 1. : 라이트모드/다크모드 상태 추가
 * 수정사항 2. : 동작 로직은 유지한채 가독성 향상을 위한 리팩토링
 */

import { useEffect, Dispatch, SetStateAction, useState, useRef } from 'react';
import styled from 'styled-components';
import { BtnBlue, BtnGray as BtnRed } from '../Button';
import { AddressSearch } from '../AddressSearch';
import Input from '../Input';
import NumbersInput from '../NumbersInput';
import { applyBorderStyle } from '../../utils/applyBorderStyle';
import SelectBox from '../SelectBox';
import { useTranslation } from 'react-i18next';
import { COMCD_JONGMOK, COMCD_UPTAE, COMCD_USE_YN } from '../../_constants';
import LogoFileInput from '../LogoFileInput';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 0.5rem;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;

const Root = styled.div<IUserInputFormStyle>`
  padding: 0.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  overflow: auto;
  justify-content: flex-start;
  .seperator {
    margin: 0.5rem 0;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    div {
      height: 1px;
      flex-grow: 1;
      background-color: rgba(0, 0, 0, 0.1);
    }
    span {
      border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    }
  }
  .inputForm {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem;
    button {
      height: 2.5rem;
      font-size: 0.75rem;
      flex-shrink: 0;
      padding: 0 0.75rem;
      border-radius: 0.25rem;
    }
    button.gray {
      font-weight: 500;
      color: ${({ theme }: { theme: any }) => theme.color.zinc_200};
    }
    label {
      width: 5.5rem;
      font-weight: 500;
      flex-shrink: 0;
      font-size: 0.875rem;
    }
    .col-span-img {
      grid-row: span 6;
      display: flex;
      align-items: center;
      max-height: 17.5rem;
      border-radius: 0.25rem;
      gap: 0.5rem;
      overflow: hidden;
      user-select: none;
      .titleName {
        width: 5.5rem;
        font-weight: 500;
        flex-shrink: 0;
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
      }
      .logo-input-container {
        flex-grow: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 0.5rem;
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        .description {
          font-size: 0.875rem;
          color: ${({ theme }: { theme: any }) => theme.filled_amber_deep};
          display: flex;
          align-items: center;
          gap: 0.25rem;
          .material-symbols-rounded {
            color: ${({ theme }: { theme: any }) => theme.filled_orange};
            font-size: 1.25rem;
          }
        }
      }
      > div {
        display: flex;
        gap: 0.25rem;
      }
    }
    .inputForm-group-1280 {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (min-width: 1280px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem 2rem;
      }
    }
    .inputForm-group-1536 {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      /* gap: 1rem; */
      @media (min-width: 1536px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem 2rem;
      }
    }
    .inputForm-group-1536.withBtn {
      flex-direction: row;
      gap: 0.5rem;
      button {
        transition: none;
      }
      @media (min-width: 1536px) {
        gap: 2rem;
        button {
          margin-left: 0;
          width: fit-content;
        }
      }
    }
    .inputForm-group-1536.withBtn.inputColumnFour {
      input {
        padding: 0;
      }
    }
    .inputForm-row.labelInInput {
      display: flex;
      gap: 0.5rem;
      flex-grow: 1;
      > div {
        flex-grow: 1;
      }
    }
    .inputForm-row.labelOutInput.searchSelector,
    .inputForm-row.labelInInput.searchSelector {
      li.defaultOption {
        width: 100%;
        max-width: none;
        display: flex;
        justify-content: space-between;
        font-size: 0.875rem;
      }
    }
    .inputForm-row.labelOutInput,
    .inputForm-row.labelInInput > div > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      flex-grow: 1;
      input {
        flex-shrink: 1;
        height: 2.5rem;
        font-size: 0.875rem;
        padding: 0 0.5rem;
        &:disabled {
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          color: ${({ theme }: { theme: any }) => theme.text_disabled};
        }
      }
      > div.viewOnly {
        height: 2.5rem;
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_primary};
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        border-radius: 0.25rem;
        padding: 0 0.75rem;
        display: flex;
        align-items: center;
      }
      > div {
        flex-grow: 1;
        > ul {
          height: 2.5rem;
          li {
            display: flex;
            width: 100%;
            max-width: 100%;
            span {
              flex-grow: 1;
              width: 4rem;
            }
          }
        }
      }
    }
    .detailInfo-group {
      margin: 1rem 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      /* gap: 1rem; */
      gap: 0.5rem 1rem;
      @media (min-width: 1536px) {
        grid-template-columns: repeat(3, 1fr);
      }
      .flex-col.detailInfo {
        flex-grow: 1;
        width: 100%;
        div {
          height: 2.5rem;
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          border-radius: 0.25rem;
          display: flex;
          align-items: center;
          padding: 0 0.5rem;
          font-size: 0.875rem;
        }
      }
    }
  }
  input {
    :disabled {
      background-color: #eeeeee;
    }
  }
  .required {
    &::after {
      content: '*';
      padding: 0 0.2rem;
      color: red;
    }
  }
  @media screen and (max-width: 1023px) {
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: fit-content;
    overflow: none;
  }
`;

interface IUserInputFormStyle {
  width?: string;
  flexDirection?: string;
}

type Logo = { light: string; dark: string };
type UpdatedLogoState = 'N' | 'D' | 'U';
type IsUpdatedLogoImage = { light: UpdatedLogoState; dark: UpdatedLogoState };

interface IUserInputForm {
  style?: IUserInputFormStyle;
  state: any;
  setState: Dispatch<SetStateAction<any>>;
  uptaeList: any;
  jongmokList: any;
  useYnList: any;
  uptae: any;
  setUptae: Dispatch<SetStateAction<any>>;
  jongmok: any;
  setJongmok: Dispatch<SetStateAction<any>>;
  useYn: any;
  setUseYn: Dispatch<SetStateAction<any>>;
  hTelState: any;
  setHTelState: Dispatch<SetStateAction<any>>;
  hNumState: any;
  setHNumState: Dispatch<SetStateAction<any>>;
  isNewAdd?: boolean;
  onClickSave: () => void;
  onClickDelete: () => void;
  logoImage: Logo;
  setLogoImage: Dispatch<SetStateAction<Logo>>;
  setTypeOfLogoImage: Dispatch<SetStateAction<Logo>>;
  setIsUpdatedLogoImage: Dispatch<SetStateAction<IsUpdatedLogoImage>>;
  isSaveClicked: boolean;
  isUpdatedLogoImage: IsUpdatedLogoImage;
}

const HeadInfoInputForm = (props: IUserInputForm) => {
  const {
    style,
    state,
    setState,
    uptaeList,
    jongmokList,
    useYnList,
    uptae,
    setUptae,
    jongmok,
    setJongmok,
    useYn,
    setUseYn,
    hTelState,
    setHTelState,
    hNumState,
    setHNumState,
    isNewAdd,
    onClickSave,
    onClickDelete,
    logoImage,
    setLogoImage,
    setTypeOfLogoImage,
    setIsUpdatedLogoImage,
    isSaveClicked,
    isUpdatedLogoImage,
  } = props;

  const { t } = useTranslation();

  // useRef를 사용하여 대표자명 입력란에 대한 참조를 만듭니다.
  const hCeonameRef = useRef<HTMLInputElement>(null);
  // 사업자번호 입력 완료 여부를 추적하기 위한 state를 설정합니다.
  const [hNumLastInput, setHNumLastInput] = useState(false);

  // 사업자번호 변경 시 state를 업데이트합니다.
  useEffect(() => {
    if (isNewAdd) {
      setHTelState({ num1: '', num2: '', num3: '' });
      setHNumState({ num1: '', num2: '', num3: '' });
    } else {
      const splitHTel = state.hTel.split('-');
      const splitHNum = state.hNum.split('-');
      setHTelState({
        num1: splitHTel[0],
        num2: splitHTel[1],
        num3: splitHTel[2],
      });
      setHNumState({
        num1: splitHNum[0],
        num2: splitHNum[1],
        num3: splitHNum[2],
      });
    }
  }, [state.hTel, state.hNum, isNewAdd]);

  // 사업자번호 입력 완료 시 대표자명 입력란으로 포커스를 이동합니다.
  useEffect(() => {
    if (hNumLastInput) hCeonameRef.current?.focus();
  }, [hNumLastInput]);

  // 로고 테마에 따른 상태값을 설정합니다.
  const [lightThemeLogo, setLightThemeLogo] = useState<string>('');
  const [darkThemeLogo, setDarkThemeLogo] = useState<string>('');
  const [typeOfLightLogoImage, setTypeOfLightLogoImage] = useState<any | null>();
  const [typeOfDarkLogoImage, setTypeOfDarkLogoImage] = useState<any | null>();
  const [isUpdatedLightThemeLogo, setIsUpdatedLightThemeLogo] = useState<UpdatedLogoState>(isUpdatedLogoImage.light);
  const [isUpdatedDarkThemeLogo, setIsUpdatedDarkThemeLogo] = useState<UpdatedLogoState>(isUpdatedLogoImage.dark);

  // // useEffect를 사용하여 로고 상태를 업데이트합니다.
  useEffect(() => {
    setLightThemeLogo(logoImage.light);
    setDarkThemeLogo(logoImage.dark);
  }, [logoImage.light, logoImage.dark]);

  // useEffect를 사용하여 로고 상태를 업데이트합니다.
  useEffect(() => {
    setLogoImage({ light: lightThemeLogo, dark: darkThemeLogo });
    setTypeOfLogoImage({ light: typeOfLightLogoImage, dark: typeOfDarkLogoImage });
    setIsUpdatedLogoImage({ light: isUpdatedLightThemeLogo, dark: isUpdatedDarkThemeLogo });
  }, [lightThemeLogo, darkThemeLogo, typeOfLightLogoImage, typeOfDarkLogoImage, isUpdatedDarkThemeLogo, isUpdatedLightThemeLogo]);

  // useEffect를 사용하여 업태 변경 시 종목값 초기화를 처리합니다.
  useEffect(() => {
    if (uptae[COMCD_UPTAE] !== '') {
      /**
       * 수정자 : 홍선영
       * 날짜 : 2024.02.27
       * 수정내용 : (본사)업태 값이 변경되었을 때 종목값 미선택으로 초기화
       */
      // setState((prev: any) => ({ ...prev, uptae: uptae[COMCD_UPTAE] }));
      if (uptae[COMCD_UPTAE] !== state.uptae) {
        setJongmok({ type: COMCD_JONGMOK, [COMCD_JONGMOK]: '', cdName: t('미선택') });
      }
    }
  }, [uptae[COMCD_UPTAE]]);

  // 전화번호 및 사업자등록번호 입력 길이를 정의합니다.
  const hTelLengthState = { num1: 3, num2: 4, num3: 4 }; // 전화번호 입력 길이
  const hNumLengthState = { num1: 3, num2: 2, num3: 5 }; // 사업자 등록번호 입력 길이

  return (
    <>
      <Root>
        <div className='inputForm'>
          <div className='inputForm-group-1280'>
            <div className='image-wrap col-span-img'>
              <span className='titleName'>{t('회사 로고')}</span>
              <div className='logo-input-container'>
                <LogoFileInput theme='light' logo={lightThemeLogo} setLogo={setLightThemeLogo} setLogoType={setTypeOfLightLogoImage} isUpdatedLogo={setIsUpdatedLightThemeLogo} />
                <LogoFileInput theme='dark' logo={darkThemeLogo} setLogo={setDarkThemeLogo} setLogoType={setTypeOfDarkLogoImage} isUpdatedLogo={setIsUpdatedDarkThemeLogo} />
                <span className='description'>
                  <span className='material-symbols-rounded'>info</span>
                  {t('svg 파일 사용을 권장합니다')}
                </span>
              </div>
            </div>
            <div className='inputForm-row labelOutInput'>
              <label htmlFor='auth'>{t('코드')}</label>
              <div className='viewOnly'>{state.hCd}</div>
            </div>
            <div className='inputForm-row labelInInput'>
              <Input
                className='required'
                label={t('본사명')}
                type='text'
                id='hName'
                name='hName'
                state={state}
                setState={setState}
                getBorderStyle={isSaveClicked ? applyBorderStyle(state.hName, 'red', 'hName') : undefined}
              />
            </div>
            <div className='inputForm-row labelOutInput'>
              <label htmlFor='hNum'>{t('사업자 번호')}</label>
              <NumbersInput state={hNumState} setState={setHNumState} lengthState={hNumLengthState} setIsLastInputCompleted={setHNumLastInput} />
            </div>
            <div className='inputForm-row labelInInput'>
              <Input inputRef={hCeonameRef} label={t('대표자명')} type='text' id='hCeoname' name='hCeoname' state={state} setState={setState} />
            </div>

            <div className='inputForm-row labelOutInput'>
              <label htmlFor='hTel'>{t('대표 전화번호')}</label>
              <NumbersInput state={hTelState} setState={setHTelState} lengthState={hTelLengthState} />
            </div>
            <div className='inputForm-row labelOutInput'>
              <label className='required' htmlFor='uptae'>
                {t('업태')}
              </label>
              <SelectBox
                options={uptaeList}
                defaultOption={isNewAdd ? t('미선택') : t(state.uptae)}
                state={uptae}
                setState={setUptae}
                stateKey={COMCD_UPTAE}
                initiateKey={uptae[COMCD_UPTAE]}
                getBorderStyle={isSaveClicked ? applyBorderStyle(uptae[COMCD_UPTAE], 'red', 'subCd') : undefined}
                optionHeight='height-sm'
              />
            </div>
            <div className='inputForm-row labelOutInput'>
              <label htmlFor='jongmok'>{t('종목')}</label>
              <SelectBox
                options={jongmokList}
                defaultOption={jongmok.cdName}
                state={jongmok}
                setState={setJongmok}
                stateKey={COMCD_JONGMOK}
                initiateKey={jongmok[COMCD_JONGMOK]}
                optionHeight='height-sm'
              />
            </div>
            <div className='inputForm-row labelInInput'>
              <Input label={t('서브도메인')} type='text' id='subDomain' name='subDomain' state={state} setState={setState} />
            </div>
          </div>
          <div className='inputForm-group-1536 withBtn'>
            <div className='inputForm-row labelOutInput content-overflow'>
              <label htmlFor={t('주소')}>{t('우편번호')}</label>
              <Input type='number' id='postNum' name='postNum' state={state} setState={setState} placeholder={t('우편번호')} disabled />
            </div>
            <div className='inputForm-row labelOutInput'>
              <AddressSearch setState={setState} />
            </div>
          </div>
          <div className='inputForm-group-1536'>
            <div className='inputForm-row labelOutInput'>
              <label htmlFor={t('주소')}>{t('주소')}</label>
              <Input type='address' id='address1' name='address1' state={state} setState={setState} placeholder={t('주소')} disabled />
            </div>
            <div className='inputForm-row labelOutInput'>
              <label htmlFor={t('상세주소')}>{t('상세주소')}</label>
              <Input type='text' id='address2' name='address2' state={state} setState={setState} placeholder={t('상세주소')} maxLength={200} />
            </div>
          </div>
          <div className='inputForm-group-1536'>
            <div className='inputForm-row labelInInput'>
              <Input label={t('비고')} type='text' id='bigo' name='bigo' state={state} setState={setState} maxLength={200} />
            </div>
            <div className='inputForm-row labelOutInput'>
              <label htmlFor='useYn'>{t('사용유무')}</label>
              <SelectBox options={useYnList} defaultOption={isNewAdd ? t('사용') : t(state.useYn)} state={useYn} setState={setUseYn} stateKey={COMCD_USE_YN} initiateKey={state.useYn} />
            </div>
          </div>
          {!isNewAdd ? (
            <div className='detailInfo-group'>
              <div className='flex-col detailInfo'>
                <label htmlFor='createDate'>{t('등록일자')}</label>
                <div>{state?.wDate}</div>
              </div>
              <div className='flex-col detailInfo'>
                <label htmlFor='createUser'>{t('등록자')}</label>
                <div>{state.writer}</div>
              </div>
              <div className='flex-col detailInfo'>
                <label htmlFor='updateDate'>{t('수정일자')}</label>
                <div>{state.eDate}</div>
              </div>
              <div className='flex-col detailInfo'>
                <label htmlFor='updateUser'>{t('수정자')}</label>
                <div>{state.editor}</div>
              </div>
            </div>
          ) : undefined}
        </div>
      </Root>
      <ButtonsWrapper>
        {!isNewAdd ? <BtnRed onClick={onClickDelete}>{t('삭제')}</BtnRed> : undefined}
        <BtnBlue onClick={onClickSave}>{t('저장')}</BtnBlue>
      </ButtonsWrapper>
    </>
  );
};

export default HeadInfoInputForm;
