/**
 * 작성자 : 한영광
 * 날짜 : 2023.09.01
 * 기능 : 안전관리 - 무사고 관리
 */

import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { IModal } from 'customTypes';
import { userState } from '../../../atoms';
import { ERROR } from '../../../_constants';
import Input from '../../../components/Input';
import { InputTable } from '../../../assets/styles/InputTable';
import { BtnBlue, BtnGhost, BtnLightBlue, BtnRed } from '../../../components/Button';
import Portal from '../../../components/Portal';
import DeleteModal from '../../../components/Modal/DeleteModal';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { logPost } from '../../../services/log';
import DatePickerInTable from '../../../components/DatePickerInTable';
import { diffDay, formatDateYMD, todayYYYYMMDD } from '../../../utils/formatDate';
import { useSetAuth } from '../../../utils/useSetAuth';
import RangePicker, { onChangeRangeInput } from '../../../components/RangePicker';
import useOnKeydownF9 from '../../../utils/useOnKeydownF9';
import { scrollToNodeTop } from '../../../utils/scrollToNodeTop';
import { apiDelete, apiGet, apiPost } from '../../../services/_common';
import ShortcutButton from '../../../components/button/ShortcutButton';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 0.5rem;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;
const Root = styled.div`
  display: flex;
  flex-direction: column;
  > div:nth-child(3) {
    overflow: auto;
    flex-shrink: 0;
  }
  .tbody > .tr > div.selector {
    > div {
      width: 100%;
      > div > ul > li {
        width: 100%;
        > span {
          width: 100%;
        }
      }
    }
  }
  .textBtnGroup button {
    padding: 0 0.75rem;
    width: 4.5rem;
    height: 2.5rem;
    font-size: 0.875rem;
    font-weight: 600;
  }
`;

interface ITableObject {
  index: number;
  aSeq: string;
  accidentDate: string;
  accidentContent: string;
  accidentSdate: string;
  accidentEdate: string;
  accidentTday: string;
  hCd: string;
  sCd: string;
  wDate: string;
  writer: string;
  eDate: string;
  editor: string;
  flag: string;
}

const Accident = () => {
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const userInfo = useRecoilValue(userState);
  const scrollContainerRef = useRef<HTMLInputElement>(null);
  const contentRef = useRef<HTMLInputElement>(null);
  const [tableState, setTableState] = useState<ITableObject[]>([]);
  const [orgTableState, setOrgTableState] = useState<ITableObject[]>([]);
  const [searchOption, setSearchOption] = useState({
    content: '',
    start: '',
    end: '',
  });
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const [isSaveClicked, setIsSaveClicked] = useState(true); // 저장1버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [visibleRangePicker, setVisibleRangePicker] = useState(false);
  const [rangeState, setRangeState] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection',
    },
  ]);
  const [addRowStatus, setAddRowStatus] = useState(false); // 신규추가 상태값
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  useEffect(() => {
    getSafeAccidentMlist();
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '안전 관리/게시판 > 무사고 관리',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    if (!rangeState[0].startDate) {
      return;
    }
    setSearchOption((prev) => ({ ...prev, start: dayjs(rangeState[0].startDate).format('YYYY-MM-DD') }));
  }, [rangeState[0].startDate]);

  useEffect(() => {
    if (!rangeState[0].endDate) {
      return;
    }
    setSearchOption((prev) => ({ ...prev, end: dayjs(rangeState[0].endDate).format('YYYY-MM-DD') }));
  }, [rangeState[0].endDate]);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  // 신규추가시 스크롤 마지막으로 이동
  useEffect(() => {
    if (addRowStatus) {
      if (scrollContainerRef.current) scrollContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      setAddRowStatus(false);
    }
  }, [addRowStatus]);

  // 뷰테이블 값이 수정될때
  const onChangeTableState = (e: React.ChangeEvent<HTMLInputElement>, i: number, el: ITableObject) => {
    const { name, value } = e.currentTarget;
    const newTableState = [...tableState]; // 뷰테이블 로우 어레이

    newTableState[i].accidentContent = value;
    newTableState[i].flag = 'Y';
    setTableState([...newTableState]);
  };

  // 로우 추가 클릭
  const onClickAddTableRow = () => {
    if (!userInfo.accidentSdate) {
      alert(t('무사고 기간 설정이 필요합니다 (설정관리 -> 환경설정 -> 일반관리)'));
      return;
    }

    const data = {
      index: orgTableState.length,
      aSeq: '',
      accidentDate: todayYYYYMMDD(),
      accidentContent: '',
      accidentSdate: orgTableState.length === 0 ? userInfo.accidentSdate : '',
      accidentEdate: userInfo.accidentEdate,
      accidentTday: '',
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      wDate: '',
      writer: '',
      eDate: '',
      editor: '',
      flag: 'Y',
    };
    const newTableState = [...tableState, data];
    const newOrgTableState = [...orgTableState, data];
    setTableState([...newTableState]);
    setOrgTableState([...newOrgTableState]);
    setAddRowStatus(true);
  };

  const getSafeAccidentMlist = async () => {
    const req = {
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
    };
    const res = await apiGet({ path: '/safe/accidentm', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      if (!userInfo.accidentSdate) {
        return alert(t('무사고 기간 설정이 필요합니다 (설정관리 -> 환경설정 -> 일반관리)'));
      }
      const accidentList = data.accidentList.map((v: any, i: number) => {
        return {
          ...v,
          index: i,
          accidentSdate: i === 0 ? userInfo.accidentSdate : data.accidentList[i - 1].accidentDate,
          accidentEdate: userInfo.accidentEdate,
          accidentTday:
            i === 0 ? diffDay(formatDateYMD(v.accidentDate), formatDateYMD(userInfo.accidentSdate)) : diffDay(formatDateYMD(v.accidentDate), formatDateYMD(data.accidentList[i - 1].accidentDate)),
          flag: 'N',
        };
      });

      setTableState([...searchFilter(accidentList)]);
      // setTableState(accidentList.filter((v: any) => v.accidentContent.includes(searchOption.content.trim())));
      setOrgTableState(accidentList);
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  const deleteCodeAPI = async (el: any) => {
    const req = { hCd: el.hCd, sCd: el.sCd, aSeq: el.aSeq, editor: el.editor };
    const res = await apiDelete({ path: '/safe/accidentm', req });
    const { data, message, statusCode } = res.data;
    if (statusCode === 200) {
      setOpenModal((prev) => ({ ...prev, status: false }));
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '안전 관리/게시판 > 무사고 관리',
        action: '삭제',
        etc: `${el.accidentContent}(${el.aSeq})`,
      });
      setTableState([]);
      getSafeAccidentMlist();
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  // 로우 삭제 클릭
  const onClickDelete = (el: ITableObject, i: number) => {
    // 새로 추가한 로우 제거 클릭 시
    if (el.aSeq === '') {
      // 뷰테이블 로우 삭제
      const updatedArray = [...tableState];
      const updatedOrgArray = [...orgTableState];
      updatedArray.splice(i, 1);
      setTableState(updatedArray);
      setOrgTableState(updatedOrgArray.filter((v: any) => v.index !== el.index));
    } else {
      // 삭제 시 에디터아이디 추가
      const data = {
        ...el,
        editor: userInfo.userId,
      };
      setOpenModal((prev) => ({ ...prev, status: true, state: tableState, setState: setTableState, api: deleteCodeAPI, el: data, index: i }));
    }
  };

  const onClickSaveTableState = async () => {
    if (!userInfo.accidentSdate) {
      alert(t('무사고 기간 설정이 필요합니다 (설정관리 -> 환경설정 -> 일반관리)'));
    } else {
      setIsSaveClicked(true);

      let editCheck = false;
      let rangeCheck = false;
      const emptyCheck = orgTableState.filter((v: any) => !v.accidentDate);
      if (emptyCheck.length > 0) {
        toast.warning(t('사고일자를 입력하세요'));
      } else {
        const reqData = orgTableState.map((v: any) => {
          if (v.flag === 'Y') {
            if (Number(v.accidentDate) > Number(userInfo.accidentEdate) || Number(v.accidentDate) < Number(userInfo.accidentSdate)) {
              rangeCheck = true;
            } else {
              v.editor = userInfo.userId;
            }
            editCheck = true;
          }
          const { index, wDate, eDate, accidentSdate, accidentEdate, accidentTday, ...rest } = v;
          return rest;
        });
        if (!editCheck) {
          toast.warning(t('변경된 내용이 없습니다'));
          return;
        }
        if (rangeCheck) {
          toast.warning(t('사고일자는 시작, 종료일자 범위 안에 날짜를 입력해야 합니다'));
          return;
        }

        const req = { accidentMReqDto: reqData };
        const res = await apiPost({ path: '/safe/accidentm', req });
        const { message, statusCode } = res.data;
        if (statusCode === 200) {
          toast.success(t(message));
          setOpenModal((prev) => ({ ...prev, status: false }));
          await logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: '안전 관리/게시판 > 무사고 관리',
            action: '저장',
            etc: ``,
          });
          setTableState([]);
          getSafeAccidentMlist();
        } else {
          // toast.error(t(ERROR));
        }
      }
    }
  };

  const onClickSearch = () => {
    if (!openModal.status) {
      setTableState([...searchFilter(orgTableState).filter((v: any) => v.aSeq !== '')]);
      setOrgTableState(orgTableState.filter((v: any) => v.aSeq !== ''));
    }
  };

  const searchFilter = (state: any) => {
    let newTableState = state;
    if (searchOption.start) {
      newTableState = newTableState.filter((v: any) => Number(v.accidentDate) >= Number(searchOption.start.replaceAll('-', '')));
    }
    if (searchOption.end) {
      newTableState = newTableState.filter((v: any) => Number(v.accidentDate) <= Number(searchOption.end.replaceAll('-', '')));
    }
    newTableState = newTableState.filter((v: any) => v.accidentContent.includes(searchOption.content.trim()));
    return newTableState;
  };

  const renderNewBtn = () => {
    if (auth.createAuth) {
      return (
        <div className='flex-basic iconBtnGroup'>
          <BtnGhost onClick={onClickAddTableRow}>
            <span className='material-symbols-rounded'>add</span>
            {t('등록')}
          </BtnGhost>
          {/* <BtnBlue onClick={onClickAddTableRow}>신규항목 추가</BtnBlue>; */}
        </div>
      );
    }
    return null;
  };

  const renderSaveBtn = () => {
    if (auth.createAuth || auth.updateAuth) {
      return <BtnBlue onClick={onClickSaveTableState}>{t('저장')}</BtnBlue>;
    }
    return null;
  };

  // 사고내용 입력창에서 엔터입력시 검색
  const onEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (contentRef.current) onClickSearch();
    }
  };
  const onChangeState = (index: number, value: string, next: boolean) => {
    if ((Number(value) > Number(userInfo.accidentEdate) || Number(value) < Number(userInfo.accidentSdate)) && !next) {
      const newTableState = tableState;
      // toast.warning('사고일자는 시작, 종료일자 범위 안에 날짜를 입력해야 합니다.');
      // newTableState[index].accidentDate = '';
      // setTableState([...newTableState]);
    } else {
      const newOrgTableState = orgTableState;
      newOrgTableState[index].accidentDate = value;
      newOrgTableState[index].flag = 'Y';
      setOrgTableState([...newOrgTableState]);
      setTableState([...newOrgTableState.filter((v: any) => v.accidentContent.includes(searchOption.content.trim()))]);
      if (index < orgTableState.length - 1) {
        if (newOrgTableState[index + 1].aSeq !== '') {
          newOrgTableState[index + 1].accidentSdate = value;
        }
        onChangeState(index + 1, orgTableState[index + 1].accidentDate, true);
      }
    }
  };

  const onClickRangeInput = () => {
    if (!visibleRangePicker) setVisibleRangePicker(true);
  };

  const onClickInitiateSearchOption = () => {
    setSearchOption((prev) => ({ ...prev, dName: '', huseYn: '', suseYn: '', buseYn: '', useYn: '', bigo: '' }));
    scrollToNodeTop(scrollContainerRef);
    onClickSearch();
  };

  return (
    <div className='content-container oneColumn'>
      <Root>
        <SearchOptions>
          <div className='inputsWrapper'>
            <div className='inputForm-row'>
              <div className='inputForm-col'>
                <Input label='' placeholder={t('사고내용')} type='text' id='content' name='content' state={searchOption} setState={setSearchOption} inputRef={contentRef} onKeyDown={onEnterKeyDown} />
              </div>
            </div>
            <div className='inputForm-row-fit'>
              <span className='calendarLabel'>{t('사고일자')}</span>
              <div className='inputForm-col'>
                <div className='flex-basic'>
                  <input id='startInput' type='text' value={searchOption.start} onMouseDown={onClickRangeInput} onChange={(e) => onChangeRangeInput(e, 'start', setRangeState, setSearchOption)} />
                  <span className='wave'> ~ </span>
                  <input id='endInput' type='text' value={searchOption.end} onMouseDown={onClickRangeInput} onChange={(e) => onChangeRangeInput(e, 'end', setRangeState, setSearchOption)} />
                </div>
                {visibleRangePicker && (
                  <div className='rangePickerWrapper'>
                    <RangePicker state={rangeState} setState={setRangeState} setVisible={setVisibleRangePicker} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='inputsWrapper'>
            <div className='secondSearchOption'>
              <div className='flex-basic textBtnGroup'>
                <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
                <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
                {/* <BtnGhost onClick={onClickSearch} className='searchBtn'>
                  {t('검색')}
                  <span className='shortcut-f9'>F9</span>
                </BtnGhost> */}
              </div>
              {renderNewBtn()}
            </div>
          </div>
        </SearchOptions>
        <InputTable className='margin-left-05'>
          <div ref={scrollContainerRef}>
            <div className='thead'>
              <div className='tr'>
                <div className='trCol2p5 flex-center tableStickyNo text_disabled'>No</div>
                <div className='trCol8 required flex-center text_disabled'>{t('사고일자')}</div>
                <div className='trCol12 flex-center tableStickyTitle content-overflow text_disabled'>{t('사고내용')}</div>
                <div className='trCol8 flex-center text_disabled'>{t('시작일자')}</div>
                <div className='trCol8 flex-center text_disabled'>{t('종료일자')}</div>
                <div className='trCol4 flex-center text_disabled'>{t('무사고')}</div>
                <div className='trCol12 flex-center text_disabled'>{t('등록일자')}</div>
                <div className='trCol6 flex-center text_disabled'>{t('등록자')}</div>
                <div className='trCol12 flex-center text_disabled'>{t('수정일자')}</div>
                <div className='trCol6 flex-center text_disabled'>{t('수정자')}</div>
                <div className='trCol4 flex-center'> </div>
              </div>
            </div>
            <div className='table'>
              <div className='tbody'>
                {tableState?.map((el: any, i: number) => (
                  <div className='tr' role='button' tabIndex={0} key={i}>
                    <div className='trCol2p5 flex-center tableStickyNo  text_disabled'>{i + 1}</div>
                    <div className='trCol8 flex-center'>
                      <div className='datePickerWrapper'>
                        <DatePickerInTable
                          state={tableState}
                          setState={setTableState}
                          stateKey='accidentDate'
                          onChangeState={onChangeState}
                          index={i}
                          popperPlacement='bottom'
                          isSaveClicked={isSaveClicked}
                          disabled={!auth.createAuth && !auth.updateAuth}
                        />
                      </div>
                    </div>
                    <div className='trCol12 flex-center tableStickyTitle content-overflow'>
                      <input
                        type='text'
                        id='accidentContent'
                        name='accidentContent'
                        value={el.accidentContent}
                        onChange={(e) => onChangeTableState(e, i, el)}
                        disabled={!auth.createAuth && !auth.updateAuth}
                        maxLength={50}
                      />
                    </div>
                    <div className='trCol8 flex-center text_primary'>{formatDateYMD(el.accidentSdate)}</div>
                    <div className='trCol8 flex-center text_primary'>{formatDateYMD(el.accidentEdate)}</div>
                    <div className='trCol4 flex-center text_primary'>{el.accidentTday}</div>
                    <div className='trCol12 flex-center text_tertiary'>{el.wDate}</div>
                    <div className='trCol6 flex-center text_tertiary'>{el.writer}</div>
                    <div className='trCol12 flex-center text_primary'>{el.eDate}</div>
                    <div className='trCol6 flex-center text_primary'>{el.editor}</div>
                    <div className='trCol4 flex-center'>{auth.deleteAuth && <BtnRed onClick={() => onClickDelete(el, i)}>{el.aSeq !== '' ? t('삭제') : t('제거')}</BtnRed>}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </InputTable>
        <ButtonsWrapper>{renderSaveBtn()}</ButtonsWrapper>
      </Root>
      <Portal openModal={openModal?.status}>{openModal && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
    </div>
  );
};

export default Accident;
