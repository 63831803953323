/**
 * 작성자 : 홍선영
 * 날짜 : 2023.06.27
 * 기능 : 현장출입금지자 뷰 모달
 */

import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';

import { userState } from '../../atoms';
import { IAuth, IModal } from 'customTypes';
import { BLACK_YN_INPUT } from '../../_constants';
import { Modal, ModalBackground } from '../../assets/styles/Modal';
import { BtnBlue, BtnRed } from '../Button';
import Input from '../Input';
import Portal from '../Portal';
import DeleteModal from './DeleteModal2';
import DatePickerComponent from '../DatePicker';
import { trimObject } from '../../utils/trimObject';
import { applyBorderStyle } from '../../utils/applyBorderStyle';
import { logPost } from '../../services/log';
import { isValidYYYYMMDD } from '../../utils/formatDate';
import { useTranslation } from 'react-i18next';
import SelectBox from '../SelectBox';
import { apiDelete, apiGet, apiPost } from '../../services/_common';

interface ICode {
  hCd: string;
  sCd: string;
  wCd: string;
  bSeq: string;
  writer: string;
}

interface IProps {
  getReportListAPI: () => void;
  setOpenModal: Dispatch<SetStateAction<IModal>>;
  auth: IAuth;
  code: ICode;
}

interface IBlackWorker {
  bSeq: number;
  bDate: string;
  bChargename: string;
  bLocation: string;
  wName: string;
  wJobdate: string;
  sjCd: string;
  sjName: string;
  blackYn: string;
  bMemo: string;
  type?: string;
}

const InfoBlackWorkerModal = ({ getReportListAPI, setOpenModal, auth, code }: IProps) => {
  const { t } = useTranslation();
  const INIT_BLACK_YN = BLACK_YN_INPUT[0];
  const userInfo = useRecoilValue(userState);
  const initialBlackWorker = {
    bSeq: 0,
    bDate: '',
    bChargename: '',
    bLocation: '',
    wName: '',
    wJobdate: '',
    sjCd: '',
    sjName: '',
    blackYn: '',
    bMemo: '',
  };
  const [workerInputSet, setWorkerInputSet] = useState<IBlackWorker>(initialBlackWorker);
  const [bDate, setBDate] = useState('');
  const [openModal2, setOpenModal2] = useState<IModal>({ status: false, type: '' });
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [blackYn, setBlackYn] = useState(INIT_BLACK_YN);

  useEffect(() => {
    getBlackWorkerInfoAPI();
  }, []);

  useEffect(() => {
    if (bDate !== '') setWorkerInputSet((prev: IBlackWorker) => ({ ...prev, bDate: bDate.replace('-', '') }));
  }, [bDate]);

  // 근로자 정보 조회 API
  const getBlackWorkerInfoAPI = async () => {
    const req = { hCd: code.hCd, sCd: code.sCd, bSeq: code.bSeq };
    const res = await apiGet({ path: '/worker/blackinfo', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      setWorkerInputSet(data);
      setBDate(data.bDate?.replaceAll('-', ''));
      setBlackYn({ type: 'blackYn', blackYn: data.blackYn, cdName: data.blackYn === 'Y' ? t('적용') : t('해제') });
    } else {
      // toast.error(t(ERROR));
    }
  };

  const saveBlackWorkerInfoAPI = async (dataParam: IBlackWorker) => {
    const { sjName, sjCd, wJobdate, wName, type, ...rest } = dataParam;
    const reqdata = { ...rest, hCd: code.hCd, sCd: code.sCd, wCd: code.wCd, blackYn: blackYn.blackYn, bDate: bDate.replaceAll('-', ''), writer: code.writer, editor: userInfo.userId };

    const req = { workerBlackReqDto: [reqdata] };
    const res = await apiPost({ path: '/worker/blackinfo', req });
    const { statusCode, data, message } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      getReportListAPI();
      setOpenModal((prev) => ({ ...prev, status: false }));
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '현장 출입금지자 세부사항(팝업)',
        action: '저장',
        etc: `${workerInputSet.wName}(${code.wCd})`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 닫기버튼 클릭
  const onClickClose = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };

  const deleteAPI = async () => {
    const req = { hCd: code.hCd, sCd: code.sCd, wCd: code.wCd, bSeq: code.bSeq, editor: userInfo.userId };
    const res = await apiDelete({ path: '/worker/blackinfo', req });
    const { statusCode, message } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      getReportListAPI();
      onClickClose();
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '현장 출입금지자 세부사항(팝업)',
        action: '삭제',
        etc: `${workerInputSet.wName}(${code.wCd})`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  const onClickSaveBlackWorkerInput = () => {
    setIsSaveClicked(true);

    if (workerInputSet.bMemo?.trim() === '') return toast.warning(t('필수입력값을 모두 입력하세요'));
    if (!bDate) return toast.warning(t('적발일자를 선택하세요'));
    if (bDate && (bDate.length < 8 || !isValidYYYYMMDD(bDate))) return toast.warning(t('적발일자를 확인하세요'));

    const trimData = trimObject(workerInputSet);
    return saveBlackWorkerInfoAPI(trimData);
  };

  const onClickDeleteBlackWorker = () => {
    setOpenModal2((prev) => ({ ...prev, status: true, type: 'delete', api: deleteAPI }));
  };

  return (
    <ModalBackground onClick={onClickClose} role='presentation'>
      <Modal>
        <div
          className='modal height-fit signup'
          role='presentation'
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className='inputForm-head'>
            <div className='modalHeaderTitle'>{t('출입금지자 세부사항')}</div>
            <div className='closeBtn' onClick={onClickClose} role='presentation'>
              <span className='material-symbols-rounded'>close</span>
            </div>
          </div>
          <div className='black flexColumn flex-between col-w100'>
            <div className='filter-option-grid'>
              <div className='datePickerWrapper noPosition'>
                <label htmlFor='bDate' className='w7rem required'>
                  {t('적발일자')}
                </label>
                <DatePickerComponent startDate={workerInputSet.bDate} setDate={setBDate} popperPlacement='bottom' isSaveClicked={isSaveClicked} />
              </div>
              <Input className='w7rem' label={t('적발담당자')} type='text' name='bChargename' state={workerInputSet} setState={setWorkerInputSet} disabled={!auth.updateAuth && !auth.createAuth} />
              <Input className='w7rem' label={t('적발장소')} type='text' name='bLocation' state={workerInputSet} setState={setWorkerInputSet} disabled={!auth.updateAuth && !auth.createAuth} />
              <div className='viewOnly'>
                <label htmlFor='blackDate' className='w7rem'>
                  {t('근로자명')}
                </label>
                <div className='namespaceEllipsis'>{workerInputSet.wName}</div>
              </div>
              <div className='viewOnly'>
                <label htmlFor='wJobdate' className='w7rem'>
                  {t('취업일자')}
                </label>
                <div>{workerInputSet.wJobdate}</div>
              </div>
              <div className='viewOnly'>
                <label htmlFor='sjName' className='w7rem'>
                  {t('협력업체')}
                </label>
                <div className='namespaceEllipsis'>{workerInputSet.sjName}</div>
              </div>
              <div className='selectWrapper'>
                <label htmlFor='blackYn'>{t('출입금지 적용')}</label>
                {!auth.updateAuth && !auth.createAuth ? (
                  <div>{workerInputSet.blackYn !== '' ? (workerInputSet.blackYn === 'Y' ? t('적용') : t('해제')) : t('선택')}</div>
                ) : (
                  <SelectBox options={BLACK_YN_INPUT} defaultOption={blackYn.cdName} state={blackYn} setState={setBlackYn} stateKey='blackYn' optionHeight='height-md' initiateKey={blackYn.blackYn} />
                )}
              </div>
              <div className='viewOnly'>
                <label className='w7rem' htmlFor='clear'>
                  {t('해제')}
                </label>
                <div>{t('출입금지 적용 삭제')}</div>
              </div>
              <Input
                className='required w7rem'
                label={t('출입금지 사유')}
                type='text'
                name='bMemo'
                state={workerInputSet}
                setState={setWorkerInputSet}
                disabled={!auth.updateAuth && !auth.createAuth}
                getBorderStyle={isSaveClicked ? applyBorderStyle(workerInputSet.bMemo, 'red', 'bMemo') : undefined}
              />
            </div>
            <div className='modal-footer flex-end col-w100'>
              {auth.deleteAuth && <BtnRed onClick={onClickDeleteBlackWorker}>{t('삭제')}</BtnRed>}
              {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={onClickSaveBlackWorkerInput}>{t('저장')}</BtnBlue>}
            </div>
          </div>
        </div>
      </Modal>
      <Portal openModal={openModal2?.status}>
        {openModal2.status === true ? openModal2.type === 'delete' ? <DeleteModal openModal={openModal2} setOpenModal={setOpenModal2} /> : undefined : undefined}
      </Portal>
    </ModalBackground>
  );
};

export default InfoBlackWorkerModal;
