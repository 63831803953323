import { color } from './theme';
import styled from 'styled-components';

export const WorkerGrid = styled.table`
  // display: grid;
  width: 100%;
  gap: 0.75rem 1rem;
  // grid-template-columns: repeat(3, 1fr);
  border-spacing: 0.5rem;

  @media (min-width: 640px) {
    // grid-template-columns: repeat(2, 1fr);
    .grid-row-4 {
      // grid-row-start: 1;
      // grid-row-end: 5;
    }
  }
  @media (min-width: 1024px) {
    // grid-template-columns: repeat(3, 1fr);
    .col-start-1 {
      grid-column-start: 1;
    }
    .col-start-2 {
      grid-column-start: 2;
    }
    .col-start-3 {
      grid-column-start: 3;
    }
    .row-start-1.col-start-2 {
      grid-row-start: 1;
      grid-column-start: 2;
    }
    .row-start-1.col-start-3 {
      grid-row-start: 1;
      grid-column-start: 3;
    }
    .row-start-2.col-start-1 {
      grid-row-start: 2;
      grid-column-start: 2;
    }
    .row-start-2.col-start-2 {
      grid-row-start: 2;
      grid-column-start: 3;
    }
    .row-start-3.col-start-1 {
      grid-row-start: 3;
      grid-column-start: 2;
    }
    .row-start-3.col-start-2 {
      grid-row-start: 3;
      grid-column-start: 3;
    }
    .row-start-4.col-start-1 {
      grid-row-start: 4;
      grid-column-start: 2;
    }
    .row-start-4.col-start-2 {
      grid-row-start: 4;
      grid-column-start: 3;
    }
    .row-start-5 {
      grid-row-start: 5;
    }
    .row-start-6 {
      grid-row-start: 6;
    }
    .row-start-7 {
      grid-row-start: 7;
    }
    .row-start-8 {
      grid-row-start: 8;
    }
    .row-start-9 {
      grid-row-start: 9;
    }
    .row-start-10 {
      grid-row-start: 10;
    }
    .row-start-11 {
      grid-row-start: 11;
    }
    .row-start-12 {
      grid-row-start: 12;
    }
    .row-start-13 {
      grid-row-start: 13;
    }
    .row-start-14 {
      grid-row-start: 14;
    }
    .row-start-15 {
      grid-row-start: 15;
    }
    .row-start-16 {
      grid-row-start: 16;
    }
    .row-start-17 {
      grid-row-start: 17;
    }
    .row-start-18 {
      grid-row-start: 18;
    }
    .row-start-19 {
      grid-row-start: 19;
    }
    .row-start-20 {
      grid-row-start: 20;
    }
  }
  .control.input > div > div label,
  .control.input > div label {
    padding: 0;
  }

  .control.jobtypeCreateSelect {
    flex-grow: 1;
    gap: 1rem;
    flex-direction: column;
    align-items: flex-start;
    @media (min-width: 640px) {
      flex-direction: row;
      align-items: center;
    }
    > .flex-basic {
      flex-grow: 1;
      > div.reactSelect {
        flex-grow: 1;
        > div {
          width: 100%;
          color: ${({ theme }: { theme: any }) => theme.text_primary};
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          border: none;
          > div:first-child {
            width: 100%;
            padding-right: 0;
            padding-left: 0.5rem;
            > div {
              cursor: pointer;
              display: flex;
              align-items: center;
              padding: 0 0.5rem !important;
              height: 2rem;
              font-size: 0.875rem;
              padding: 0;
              input {
              }
            }
          }
          > div:last-child {
            padding: 0;
          }
          > div:nth-child(2) {
            span {
              background-color: ${({ theme }: { theme: any }) => theme.outline};
            }
          }
        }
        > div:nth-child(4) {
          > div {
            > div {
              color: ${({ theme }: { theme: any }) => theme.text_primary};
              &:hover {
                color: ${({ theme }: { theme: any }) => theme.text_primary};
                /* background-color: ${({ theme }: { theme: any }) => theme.tonal_deep}; */
              }
              &:active {
                /* background-color: blue; */
              }
            }
          }
        }
      }
      @media (max-width: 640px) {
        width: 100%;
      }
    }
  }

  .searchSelect {
    > div {
      flex-grow: 1;
      > ul > li {
        width: 100% !important;
        max-width: 100% !important;
        span {
          width: 100%;
        }
      }
    }
  }

  .datePicker {
    .custom-header {
      display: flex;
      align-items: center;
      padding: 0 0.25rem;
      gap: 0.25rem;
      select {
        font-size: 0.875rem;
        height: 2rem;
        width: 34%;
        padding: 0 0.125rem;
      }
      div.arrow {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        outline: 1px solid rgba(0, 0, 0, 0.2);
        background-color: rgba(243, 246, 249, 0.5);
        /* background-color: ${({ theme }: { theme: any }) => theme.tonal}; */
        height: 2rem;
        user-select: none;
        border-radius: 0.125rem;
      }
      div.arrow:first-child {
        padding-right: 0.25rem;
      }
      div.arrow:last-child {
        padding-left: 0.125rem;
      }
    }
  }
  .nameContainer {
    flex-grow: 1;
    display: flex;
    gap: 0.5rem;
    button {
      height: 2.5rem;
      padding: 0 0.5rem;
      font-size: 0.875rem;
    }
  }

  .grid-column-1 {
    /* grid-column: span 1; */
    @media (min-width: 640px) {
      // grid-column: span 1;
      .selector {
        flex-direction: row;
      }
    }
    /* .selector {
      flex-direction: column;
    } */
  }

  .control.insuranceCheck {
    width: 100%;
    .flex-basic {
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 1rem;
      padding: 0.5rem 0.75rem;
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      min-height: 2.5rem;
      border-radius: 0.25rem;
      label {
        user-select: none;
        width: fit-content;
        padding: 0;
        cursor: pointer;
      }
      > div {
        input[type='checkbox'] {
          width: 1rem;
          height: 1rem;
          outline: none;
          flex-grow: 0;
        }
      }
    }
  }

  .control.preJobtypeCreateSelect {
    flex-grow: 1;
    gap: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
    @media (min-width: 640px) {
      flex-direction: row;
      align-items: center;
    }
    > .flex-basic {
      width: calc(50% - 4rem);
      flex-grow: 1;
      flex-shrink: 0;
      > div.reactSelect {
        flex-grow: 1;
        > div {
          width: 100%;
          color: ${({ theme }: { theme: any }) => theme.text_primary};
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          border: none;
          > div:first-child {
            width: 100%;
            padding-right: 0;
            padding-left: 0.5rem;
            > div {
              cursor: pointer;
              display: flex;
              align-items: center;
              padding: 0 0.5rem !important;
              height: 2rem;
              font-size: 0.875rem;
              padding: 0;
              input {
              }
            }
          }
          > div:last-child {
            padding: 0;
          }
          > div:nth-child(2) {
            span {
              background-color: ${({ theme }: { theme: any }) => theme.outline};
            }
          }
        }
        > div:nth-child(4) {
          > div {
            > div {
              color: ${({ theme }: { theme: any }) => theme.text_primary};
              &:hover {
                color: ${({ theme }: { theme: any }) => theme.text_primary};
                /* background-color: ${({ theme }: { theme: any }) => theme.tonal_deep}; */
              }
              &:active {
                /* background-color: blue; */
              }
            }
          }
        }
      }
      @media (max-width: 640px) {
        width: 100%;
      }
    }
  }

  .control.bloodPressureWrapper {
    display: flex;
    > div.flex-basic {
      gap: 0.5rem;
      flex-wrap: wrap;
      > div {
        border-radius: 0.25rem;
        label {
          user-select: none;
          cursor: pointer;
          height: 2.5rem;
          width: fit-content;
          border-right: 1px solid ${({ theme }: { theme: any }) => theme.outline};
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          padding: 0 0.75rem;
          border-radius: 0.25rem 0 0 0.25rem;
          span {
            user-select: none;
            border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
            /* border-left: 1px solid rgba(0, 0, 0, 0.1); */
            width: 100%;
            align-items: center;
            padding: 0 0.5rem;
            margin-left: 0.5rem;
            font-size: 0.875rem;
            color: ${({ theme }: { theme: any }) => theme.text_tertiary};
          }
        }
        input {
          /* outline: none; */
          /* border-radius: 0; */
          /* width: 100%; */
          border-radius: 0 0.25rem 0.25rem 0;
        }
      }
    }
  }

  .grid-column-2 {
    /* grid-column: span 2; */
    display: flex;
    @media (min-width: 640px) {
      // grid-column: span 2;
    }
    .control.input {
      flex-grow: 1;
    }
  }

  .equipments,
  .healthCheck {
    > label {
      padding: 0;
    }
    .flex-basic {
      gap: 0.5rem;
      flex-grow: 1;

      .result > div > ul > li {
        border-radius: 0 0.25rem 0.25rem 0 !important;
      }

      > div:nth-child(1),
      > div:nth-child(3) > div {
        display: flex;
        flex-direction: row;
        gap: 0;
        border-radius: 0.25rem;
        /* outline: 1px solid rgba(0, 0, 0, 0.15); */
        label {
          user-select: none;
          cursor: pointer;
          height: 2.5rem;
          width: fit-content;
          padding: 0 0.75rem;
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          border-right: 1px solid ${({ theme }: { theme: any }) => theme.outline};
          border-radius: 0.25rem 0 0 0.25rem;
        }
        input {
          padding: 0;
          text-align: center;
          width: 6rem;
          color: ${({ theme }: { theme: any }) => theme.text_primary};
          border-radius: 0 0.25rem 0.25rem 0;
        }

        > div:nth-child(2) {
          width: 6rem;
        }
        > div:nth-child(3) {
          position: absolute;
        }
      }
      > div.result {
        display: flex;
        border-radius: 0.25rem;
        // overflow: hidden;
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        /* outline: 1px solid rgba(0, 0, 0, 0.1); */
        label {
          border-right: 1px solid ${({ theme }: { theme: any }) => theme.outline};
          width: auto;
          padding: 0 0.75rem;
        }
        > div > ul > li {
          border-radius: 0.125rem;
          width: 6rem;
          span {
            width: 100%;
          }
        }
      }
      > div:nth-child(3) {
        flex-grow: 1;
        > div {
          flex-grow: 1;
          input {
            text-align: left;
            padding: 0 0.5rem;
          }
        }
      }
    }
    .noGrow {
      flex-grow: 0;
      width: fit-content;
      > div {
        width: fit-content !important;
      }
    }
    .serialCheckbox {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 1rem;
      padding: 0.5rem 0.75rem;
      /* outline: 1px solid rgba(0, 0, 0, 0.1); */
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      border-radius: 0.25rem;
      min-height: 2.5rem;

      label {
        user-select: none;
        width: fit-content;
        padding: 0;
        cursor: pointer;
      }
      > div {
        input[type='checkbox'] {
          width: 1rem;
          height: 1rem;
          outline: none;
          flex-grow: 0;
        }
      }
    }
  }

  .healthCheck .flex-basic,
  .equipments .flex-basic,
  .address .flex-basic {
    flex-wrap: wrap;
    @media (min-width: 1024px) {
      flex-wrap: nowrap;
    }
  }

  .address {
    .flex-basic {
      gap: 0.5rem;
      // gap: 1rem;
      flex-grow: 1;
      button {
        height: 2.5rem;
        width: 6.9rem;
        font-size: 0.875rem;
      }
      > div:nth-child(2) {
        width: 6.5rem;
        input {
          width: 6.5rem;
        }
      }
      > div:nth-child(n + 3) {
        flex-grow: 1;
        width: 100%;
        div {
          width: 100%;
          input {
            width: 100%;
          }
        }
      }
    }
  }

  .healthCheck .flex-basic {
    width: 100%;
  }

  .grid-column-3 {
    /* grid-column: span 2; */
    width: 100%;
    @media (min-width: 640px) {
      // grid-column: span 2;
    }
    @media (min-width: 1024px) {
      // grid-column: span 3;
    }
  }
  .grid-row-4 {
    /* grid-row: span 4; */
    > div > div {
      width: 100%;
    }
  }
  .control {
    display: flex;
    align-items: center;
    label {
      width: 6rem;
      font-size: 0.875rem;
      font-weight: 500;
      /* padding: 0 0.5rem; */
      flex-shrink: 0;
    }
    input {
      width: auto;
      flex-grow: 1;
      font-size: 0.875rem;
    }
    input[type='number'] {
      padding: 0;
      width: 3rem;
      text-align: center;
    }
    input[type='text'] {
      flex-grow: 1;
      /* width: auto; */
      width: 50%;
      font-size: 0.875rem;
    }
  }
  .control.selector {
    > div:nth-child(2) {
      flex-grow: 1;
      li {
        width: 100%;
        max-width: 100%;
        span {
          /* width: 100%; */
          width: 8rem;
          flex-grow: 1;
        }
      }
    }
  }
  .control.input {
    > div {
      width: 100%;
      > div {
        flex-direction: row;
        gap: 0;
      }
    }
  }
  .control.viewOnly {
    height: 100%;
    div {
      font-size: 0.875rem;
      width: 100%;
      height: 2.5rem;
      /* outline: 1px solid rgba(0, 0, 0, 0.075); */
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      border-radius: 0.25rem;
      padding: 0 0.75rem;
      display: flex;
      align-items: center;
      color: ${({ theme }: { theme: any }) => theme.text_disabled};
      user-select: none;
    }
  }

  .reactSelectWrapper {
    > div > div {
      min-width: 6rem;
    }
  }
`;
