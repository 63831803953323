/**
 * 작성자 : 홍선영
 * 날짜 : 2024.02.28
 * 경로 : 안전관리-AI 카메라-안전관리 사진 (현장용)
 */

import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PulseLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { useOutletContext } from 'react-router-dom';
import { IUser, pageInfoState, userState } from '../../atoms';
import styled from 'styled-components';

import { ITabObject } from 'customTypes';
import { ContentsContainerRoot } from '../../assets/styles/ContentsContainerRoot';
import { INIT_CCD_A, INIT_NCD_A } from '../../_constants';
import { LoadingModalBackground } from '../../assets/styles/Modal';
import Tab from '../../components/Tab';
import IssueGuide from '../../components/IssueGuide';
import TuiGridImage from '../../components/TuiGridImage';
import SearchFilterForSafetyManagement from './safe7/SearchFilterForSafetyManagement';
import { apiGet } from '../../services/_common';
import { logPost } from '../../services/log';
import { formatDate, todayYYYYMMDD } from '../../utils/formatDate';
import useSetListCount from '../../utils/useSetListCount';
import { useFetchNvrCodeList, useFetchCameraCodeList } from '../../services/useSetCodeListInSelectBoxForm';
import { useTranslation } from 'react-i18next';

const Root = styled(ContentsContainerRoot)`
  display: flex;
  flex-direction: column;
`;

const Ssafe7 = () => {
  const { t } = useTranslation();
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  const userInfo = useRecoilValue<IUser>(userState);
  const { userMenuList } = userInfo;
  const [newTabList, setNewTabList] = useState<ITabObject[]>();
  const [nCd, setnCd] = useState(INIT_NCD_A);
  const [cCd, setcCd] = useState(INIT_CCD_A);
  const toDay = todayYYYYMMDD();
  const [userInputDateRange, setUserInputDateRange] = useState({ start: toDay, end: toDay });
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const { listCount, setListCount, patchUserMenuAPI } = useSetListCount(); // 검색줄수설정 훅
  const { data: nvrList, isError: isErrorNvrList, isLoading: isLoadingNvrList } = useFetchNvrCodeList(); // NVR목록 조회 useQuery
  const { data: cameraList } = useFetchCameraCodeList(nCd.nCd); // 카메라목록 조회 useQuery

  useEffect(() => {
    if (isSearchClicked) {
      setIsSearchClicked(false);
    }
  }, [isSearchClicked]);

  useEffect(() => {
    const arr: any[] = [];
    userMenuList.map((v: any) => {
      const result = tabInfo?.tabList?.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  const onClickTab = (mrCd: string) => {
    setCurrentTabMrCd(mrCd);
  };

  const getCameraName = async (nvrCode: string, cameraCode: string) => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, nCd: nvrCode };
    const res = await apiGet({ path: '/cam/camera', req });
    const result = res.data.data.cameraList;
    if (result?.length > 0) {
      const findCam = result.find((el: any) => el.cCd === cameraCode);
      if (findCam) return findCam.cName;
      return '';
    }
    return '';
  };

  // AI 카메라 데이터를 TUI Grid 형식으로 가져오는 함수
  const fetchAicamDataInTuiGridForm = async (hCd: string, sCd: string, date1: string, date2: string, nCdParam: string, cCdParam: string) => {
    let filterNvr = nCdParam !== 'A';
    let filterCamera = cCdParam !== 'A';

    try {
      const req = { hCd, sCd, date1: date1.replaceAll('-', ''), date2: date2.replaceAll('-', '') };
      const res = await apiGet({ path: '/cam/aicams', req });

      const promises = res.data.data?.map(async (item: any, index: number) => {
        const [nvrCode, camCode, timestamp] = extractInfoFromUrl(item);
        const camName = await getCameraName(nvrCode, camCode); // await promise
        return {
          index,
          url: item,
          date: formatDate(timestamp) || '',
          nvrName: nvrList?.find((el) => el.nCd === nvrCode)?.cdName || '',
          camName: camName || '',
        };
      });
      if (promises) {
        let result = await Promise.all(promises);

        // 필터링 조건 적용
        result = result.filter(({ url }: { url: string }) => {
          const [nvrCode, camCode] = extractInfoFromUrl(url);
          return (!filterNvr || nvrCode === nCdParam) && (!filterCamera || camCode === cCdParam);
        });

        // 결과 데이터 포맷팅
        const formattedTableData = result.map((el, index) => ({
          cellData: generateCellDataHtml(el, index.toString()),
          url: el.url,
        }));
        return formattedTableData;
      }
      return [];
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  // URL에서 NVR 코드, 카메라 코드, 타임스탬프 추출하는 함수
  function extractInfoFromUrl(url: any) {
    const parts = url.split('/').pop().split('_');
    return [parts[0], parts[1], parts[2]];
  }

  // TUI Grid 셀 데이터 HTML 생성 함수
  function generateCellDataHtml(el: any, index: string) {
    return `<div key=${index}>
      <div className='flex-flex flex-col'>
        <img src=${el.url} alt='' />
      </div>
      <div>
      <ul>
        <li>${t('NVR 명')}: ${el.nvrName}</li>
        <li>${t('카메라 명')}: ${el.camName}</li>
        <li>${el.date}</li>
      </ul>
    </div>
    </div>`;
  }

  // aicams 조회 useQuery
  const {
    data: aiCamList,
    isLoading: isLoadingAiCamList,
    isRefetching: isRefetchingAiCamList,
    isError: isErrorAiCamList,
    isInitialLoading: InitLoadingAiCamList,
    refetch,
  } = useQuery(['aicamsGet', userInfo.hCd, userInfo.sCd], () => fetchAicamDataInTuiGridForm(userInfo.hCd, userInfo.sCd, userInputDateRange.start, userInputDateRange.end, nCd.nCd, cCd.cCd), {
    enabled: false,
    cacheTime: 0,
  });

  const renderPage = () => {
    const activeTab = newTabList?.find((el: ITabObject) => el.mrCd === currentTabMrCd);
    if (activeTab !== undefined) {
      const { mrCd } = activeTab;
      switch (mrCd) {
        case '001':
          return (
            <>
              <SearchFilterForSafetyManagement
                nvrList={nvrList || []}
                cameraList={cameraList || []}
                nCd={nCd}
                setnCd={setnCd}
                cCd={cCd}
                setcCd={setcCd}
                userInputDateRange={userInputDateRange}
                setUserInputDateRange={setUserInputDateRange}
                listCount={listCount}
                setListCount={setListCount}
                patchUserMenuAPI={patchUserMenuAPI}
                dataCount={aiCamList?.length || 0}
                refetch={refetch}
              />
              <TuiGridImage data={aiCamList} listCount={listCount} loading={InitLoadingAiCamList || isRefetchingAiCamList} error={isErrorAiCamList} />
            </>
          );
      }
    }
    return undefined;
  };

  if (isErrorNvrList) return <IssueGuide />;
  if (isLoadingNvrList)
    return (
      <LoadingModalBackground>
        <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
      </LoadingModalBackground>
    );

  return (
    <div className='contents'>
      <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      <div className='content-container oneColumn'>
        <Root loading={isLoadingNvrList}>{renderPage()}</Root>
      </div>
    </div>
  );
};

export default Ssafe7;
