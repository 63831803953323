/**
 * 작성자 : 김광민
 * 날짜 : 2024.02.13
 * 기능 : 터널 관리 > 방송 장비 정보
 */

import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  .material-symbols-rounded {
    color: ${({ theme }: { theme: any }) => theme.outline};
    color: ${({ theme }: { theme: any }) => theme.outline_em};
    font-size: 1.5rem;
    font-size: 1.75rem;
    font-variation-settings: 'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 24;
  }
  &.filled {
    .material-symbols-rounded {
      color: ${({ theme }: { theme: any }) => theme.filled_blue};
    }
  }
  &.scale:hover {
    transform: scale(1.2);
  }
`;

type Props = {
  onClick: () => void;
  checked: boolean;
  scale?: boolean;
};

const CheckBoxCircle = ({ onClick, checked, scale = false }: Props) => {
  const filled = checked ? 'filled' : '';
  const transform = scale ? 'scale' : '';
  return (
    <Root className={`${filled} ${transform}`}>
      <span className='material-symbols-rounded'>check_circle</span>
    </Root>
  );
};

export default CheckBoxCircle;
