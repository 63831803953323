import styled from 'styled-components';

const Root = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  &:hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
  }
  &:active {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
  }
  &:hover,
  &:active {
    .material-symbols-rounded {
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
    }
  }
  .material-symbols-rounded {
    font-size: 1.25rem;
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
  }
`;

const PlayButton = ({ autoPlay, onClick }: { autoPlay: boolean; onClick: () => void }) => {
  return (
    <Root role='button' tabIndex={0} className='playButton' onClick={onClick}>
      <span className='material-symbols-rounded'>{autoPlay ? 'pause' : 'play_arrow'}</span>
    </Root>
  );
};

export default PlayButton;
