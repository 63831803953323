import styled from 'styled-components';
import ButtonContainer from './ButtonContainer';
import ClickableContainer from './ClickableContainer';
import ClickExpandableContainer from './ClickExpandableContainer';
import { captureButton, closeButton, offRecordButton, onRecordButton, refreshButton, removeQueueButton } from '../../../../components/button/DarkButtons';
import { WebSDKcctv } from '../../../../utils/webSDKcctvClass';
import { IsInfraType } from './IsInfraType';
import { pageInfoState, userState } from '../../../../atoms';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import * as cctv from '../../../../utils/cctv';
import { logPost } from '../../../../services/log';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import LeftedToast from '../../../../components/cctv/LeftedToast';

const Root = styled.div`
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  display: flex;
  align-items: center;
  height: 100%;
  color: ${({ theme }: { theme: any }) => theme.color.white};
  user-select: none;
  gap: 0.25rem;
  > div:last-child {
    margin-left: 0.75rem;
  }
`;

type Props = {
  cctvOBJ: WebSDKcctv; //
  onClickRemoveScreen: (value: 'selected' | 'onScreen' | 'all') => void;
  isRecording: boolean;
  onClickRefresh: () => void;
  onClickRecord: (value: boolean) => void;
  selectedCameraInfo: SelectedCctvCameraInfo | null;
  playingCamStatus: any[];
  disabled?: boolean;
};

const BottomRightOptions = ({
  cctvOBJ, //
  onClickRemoveScreen,
  isRecording,
  onClickRefresh,
  onClickRecord: onClickRecordProps,
  selectedCameraInfo,
  playingCamStatus,
  disabled = selectedCameraInfo === null,
}: Props) => {
  const userInfo = useRecoilValue(userState);
  // const disabled = selectedCameraInfo === null;
  const isInfraType = IsInfraType(); // 인프라용의 경우 녹화/캡쳐버튼 미노출
  const navigate = useNavigate();
  const tabInfo = useRecoilValue(pageInfoState);

  const backToPage = () => {
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: 'CCTV 실시간 보기-인프라용',
      action: `실시간 보기 종료`,
      etc: ``,
    });
    if (tabInfo.tabList.length === 1) {
      cctv.getPluginOBJECT()?.oPlugin && cctv.destroy();
      navigate('/');
    } else {
      window.history.back();
    }
  };

  // 화면 제거 상태
  const removeScreenOptions = [
    { key: 'selected', button: <ButtonContainer name='선택한 채널만' darkModeOnly />, value: 'selected' },
    { key: 'onScreen', button: <ButtonContainer name='현재 모니터링 중인 채널' darkModeOnly />, value: 'onScreen' },
    { key: 'all', button: <ButtonContainer name='채널 전체' darkModeOnly />, value: 'all' },
  ];
  const [removeScreenDisabled, setRemoveScreenDisabled] = useState(playingCamStatus.filter((cam) => cam.isPlaying).length === 0);

  useEffect(() => {
    setRemoveScreenDisabled(playingCamStatus.filter((cam) => cam.isPlaying).length === 0);
  }, [playingCamStatus]);

  return (
    <Root className={disabled ? 'disabled' : ''}>
      {!isInfraType && (
        <ClickableContainer // 녹화
          button={isRecording ? offRecordButton : onRecordButton}
          onClick={() => onClickRecordProps(!isRecording)}
        />
      )}
      {!isInfraType && (
        <ClickableContainer // 캡쳐
          button={captureButton}
          onClick={() => cctvOBJ.capture()}
          disabled={disabled}
        />
      )}
      <ClickableContainer // 새로고침
        button={refreshButton}
        onClick={onClickRefresh}
        disabled={disabled}
      />
      <ClickExpandableContainer // 화면 재생 빼기
        defaultOption={removeQueueButton}
        options={
          disabled
            ? removeScreenOptions //
                .filter((option) => option.value !== 'selected')
            : removeScreenOptions
        }
        direction='toLeft'
        onClick={(removeOption) => onClickRemoveScreen(removeOption)}
        disabled={removeScreenDisabled}
      />
      <ClickableContainer // 종료
        button={closeButton}
        onClick={backToPage}
      />
    </Root>
  );
};

export default BottomRightOptions;
