import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { BtnRed } from '../../../components/Button';
import ClickableContainer from '../../s_cctv/S_cctv1/RealtimeCCTV/ClickableContainer';
import TableLamp from '../../sm_setting/setting12/TableLamp';
import { formatDate } from '../../../utils/formatDate';
import { useTranslation } from 'react-i18next';
import { useSigncode } from '../../../hooks/useTablet';
import { IModal } from 'customTypes';
import Portal from '../../../components/Portal';
import YesOrNoConfirm from '../../../components/Modal/YesOrNo';
import { applyBorderStyle } from '../../../utils/applyBorderStyle';

interface Props {
  rowData: any;
  setTableState: Dispatch<SetStateAction<any>>;
  index: number;
  emptyCheck: boolean;
}

const TableRow = ({ rowData, setTableState, index, emptyCheck }: Props) => {
  const { t } = useTranslation();
  const { deleteSigncode } = useSigncode();
  const [inputs, setInputs] = useState({ name: rowData.signName, tag: rowData.signTag });
  const [useYn, setUseYn] = useState<'Y' | 'N'>(rowData.useYn);
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: 'confirm' });

  // 입력 필드의 값을 업데이트하는 함수
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  // 부모로부터 props로 내려받은 rowData가 변경되면
  // 입력 필드의 값을 업데이트하는 함수
  useEffect(() => {
    setInputs({ name: rowData.signName, tag: rowData.signTag });
    setUseYn(rowData.useYn);
  }, [rowData]);

  // 서명항목, 서명 TAG, 서용유무가 변경되면 부모로 내려받은
  // tableState 상태를 업데이트하는 함수
  useEffect(() => {
    const handler = setTimeout(() => {
      setTableState((prev: any) => {
        const newState = [...prev];
        newState[index] = { ...newState[index], signName: inputs.name, signTag: inputs.tag, useYn };
        return newState;
      });
    }, 500); // 지연시간

    return () => {
      clearTimeout(handler);
    };
  }, [setTableState, inputs.name, inputs.tag, useYn]);

  // 신규 항목 제거 함수
  const deleteNewItem = () => {
    setTableState((prev: any) => {
      return prev.filter((_: any, i: number) => i !== index);
    });
  };

  // 서명 항목 제거 알고리즘 함수
  const handleDelete = () => {
    // 기존 항목 삭제시
    if (rowData.signIndex) {
      setOpenModal({ ...openModal, status: true });
      return;
    }
    // 신규 항목 제거시 입력 필드에 내용이 있는 경우
    if (inputs.name && inputs.tag) {
      setOpenModal({ ...openModal, status: true });
      return;
    }
    // 신규 항목 제거시 입력 필드가 비어있는 경우
    deleteNewItem();
  };

  // 사용유무 업데이트 함수
  const handleUpdateUseYn = () => {
    const newUseYn = useYn === 'Y' ? 'N' : 'Y';
    setUseYn(newUseYn);
  };

  return (
    <div className='tr'>
      <div className='trCol2p5 flex-center tableStickyNo'>{index + 1}</div>
      <div className='trCol15 flex-center tableStickyTitle content-overflow2x'>
        <input
          type='text' //
          id='name'
          name='name'
          value={inputs.name}
          onChange={handleInputChange}
          style={emptyCheck ? applyBorderStyle(inputs.name, 'red', 'name') : undefined}
          maxLength={50}
        />
      </div>
      <div className='trCol10 flex-center content-overflow2x'>
        <input
          type='text' //
          id='tag'
          name='tag'
          value={inputs.tag}
          onChange={handleInputChange}
          style={emptyCheck ? applyBorderStyle(inputs.tag, 'red', 'tag') : undefined}
          maxLength={50}
        />
      </div>
      <div className='trCol5 flex-center selector content-overflow'>
        <ClickableContainer
          onClick={handleUpdateUseYn} //
          button={<TableLamp use={useYn === 'Y'} />}
        />
      </div>
      <div
        className='trCol10 flex-center content-overflow' //
      >
        {formatDate(rowData.wDate)}
      </div>
      <div
        className='trCol5 flex-center content-overflow' //
      >
        {rowData.writer ?? ''}
      </div>
      <div
        className='trCol10 flex-center content-overflow' //
      >
        {formatDate(rowData.eDate)}
      </div>
      <div
        className='trCol5 flex-center content-overflow' //
      >
        {rowData.editor ?? ''}
      </div>
      <div
        className='trCol5 flex-center' //
      >
        <BtnRed onClick={handleDelete}>
          {rowData.signIndex //
            ? t('삭제')
            : t('제거')}
        </BtnRed>
      </div>
      <Portal openModal={openModal?.status}>
        <YesOrNoConfirm
          mainText={t('서명항목을 정말 삭제하시겠습니까?')}
          subText={t('저장하지 않은 변경 사항은 유실됩니다.')}
          onClickClose={() => setOpenModal({ status: false, type: 'confirm' })} //
          onClickConfirm={() => {
            if (rowData.signIndex) {
              deleteSigncode(rowData);
            } else {
              deleteNewItem();
            }
            setOpenModal({ status: false, type: 'confirm' });
          }}
        />
      </Portal>
    </div>
  );
};

export default TableRow;
