/**
 * 작성자 : 홍선영
 * 날짜 : 2023.04.20
 * 기능 : 목록에서 신규항목 추가를 클릭했을 때 No를 증가시키고 그 객체를 setState하는 함수
 */

import { Dispatch, SetStateAction } from 'react';
import { FLAG_CREATE_OR_UPDATE } from '../_constants';

export const increaseArrayNo = (data: any, state: any[], setState: Dispatch<SetStateAction<any[]>>, newIndex: number, cdTitle: string, cdName: string, userId: string) => {
  const copyArray = [...state, data];
  copyArray.map((el, i) => {
    //  새로 추가하는 로우일때
    //  (데이터 패치 후 프론트에서 배열에 인덱스를 따로 넣어주므로, 인덱스값이 없으면 새로운 로우임)
    if (el.index !== undefined) {
      const updateArray = [...copyArray];
      updateArray[i] = {
        ...updateArray[i],
        index: newIndex,
        [cdTitle]: '',
        [cdName]: '',
        flag: FLAG_CREATE_OR_UPDATE,
        writer: userId,
        no: copyArray.length,
      };
      setState(updateArray);
    }
  });

  return null;
};
