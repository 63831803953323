import styled from 'styled-components';
import useMSite, { mergeRes, postMSite } from '../../../hooks/useMSite';
import { InputTable } from '../../../assets/styles/InputTable';
import { useEffect, useRef, useState } from 'react';
import { BtnBlue } from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import TableRow from './TableRow';
import { getDate } from 'date-fns';
import { userState } from '../../../atoms';
import { useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  padding: 0.5rem;
  > div {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
  .countWrapper {
    padding: 0 0.25rem;
  }
  .countName {
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
  }
  .countNumber {
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.filled_blue};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    margin-left: 0.5rem;
  }
  .seperator {
    height: 1rem;
    border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    margin: 0 0.25rem;
  }
`;

const Root = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  overflow: hidden;
  justify-content: flex-start;
`;

interface Props {
  headList: { type: String; hCd: string; cdName: string }[];
  rowState: any;
}

const MSiteList = ({ headList, rowState }: Props) => {
  const { t } = useTranslation();
  const scrollContainerRef = useRef<HTMLInputElement>(null);

  const userInfo = useRecoilValue(userState);
  const [emptyCheck, setEmptyCheck] = useState<boolean>(false);

  // 1. 선택된 row에서 hCd, sCd 추출
  const { hCd, sCd } = rowState;
  // 2. 추출한 hCd, sCd를 기반으로 커스텀 hook 작동
  const { mSiteQuery, deleteMSite } = useMSite(hCd, sCd);
  // 3. useQuery에서 데이터 가져오기
  const { data, isLoading, isError, refetch } = mSiteQuery;
  // 4. 가져온 데이터를 tableState 상태에 저장 및 가공
  const [tableState, setTableState] = useState<any>([]);
  // 5. data가 변경될 때 tableState 업데이트
  useEffect(() => {
    if (data) {
      data.length > 0 ? setTableState(data) : setTableState([]);
    }
  }, [data]);
  // 6. 건설사 리스트 상태에 저장 및 가공
  const [headOptions, setHeadOptions] = useState<any>([]);
  // 7. headList가 변경 될 때 headOptions 업데이트
  useEffect(() => {
    const newArray = headList //
      .map((list: any) => ({
        type: 'mhCd', //
        mhCd: list.hCd,
        cdName: list.cdName,
      }));
    setHeadOptions(newArray);
  }, [headList]);
  // 8. 사용유무 카운트
  const useYnLength = tableState.filter((el: any) => el.useYn === 'Y').length;

  const onClickAdd = () => {
    setTableState([...tableState, { mhCd: '', msCd: '', wregYn: 'N', useYn: 'Y' }]);
    // 테이블 스크롤 위치 조정
    if (scrollContainerRef.current) {
      scrollContainerRef.current //
        .scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  // 저장 버튼 클릭 함수
  const onClickSave = async () => {
    let emptyCount = 0;
    let response: any = [];

    const promises = tableState.map((tableRow: any, index: number) => {
      const { mhCd, msCd, useYn, wregYn } = tableRow;
      // 1. 서명항목, 서명TAG 공백인 경우 리턴
      if (mhCd === '' || msCd === '') {
        emptyCount += 1;
        setEmptyCheck(true);
        return;
      }
      // 2. 신규 항목 POST API 요청
      const dataIndex = data[index]; // 기존 항목
      if (!dataIndex) {
        const req = {
          hCd,
          sCd,
          mhCd,
          msCd,
          wregYn,
          useYn,
          delYn: 'N',
          writer: userInfo.userId,
          editor: userInfo.userId,
        };
        setTimeout(() => {
          postMSite({ postMsiteSetDto: [req] }).then((res) => {
            response.push(res);
          });
        }, index * 100);
        return;
      }
      // 3. 기존 항목에서 수정된 내용이 없는 경우 리턴
      const comparisonName = dataIndex.mhCd === mhCd;
      const comparisonTag = dataIndex.msCd === msCd;
      const comparisonUse = dataIndex.useYn === useYn;
      const comparisonWreg = dataIndex.wregYn === wregYn;
      if (dataIndex && comparisonName && comparisonTag && comparisonUse && comparisonWreg) {
        return;
      }
      // 4. 기존 항목 POST API 요청
      const req = {
        hCd,
        sCd,
        sSeq: dataIndex.sSeq,
        mhCd,
        msCd,
        wregYn,
        useYn,
        delYn: 'N',
        writer: tableRow.writer,
        editor: userInfo.userId,
      };
      postMSite({ postMsiteSetDto: [req] }).then((res) => {
        response.push(res);
      });
    });

    await Promise.all(promises).then(() => {
      // 비어 있는 셀렉트 박스 유효성 검사 해제
      if (emptyCount === 0) setEmptyCheck(false);
      // 비어 있는 입력필드 유효성 검사 유지
      if (emptyCount > 0) emptyCount = 0;
      // Query Refetch
      setTimeout(async () => {
        const result = mergeRes(response);
        result.forEach((el) => {
          if (el.statusCode === 200) {
            toast.success(t(el.message));
          }
        });
        await refetch();
      }, 100 * (tableState?.length || 1));
    });
  };

  if (isLoading) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  if (isError) return null;

  return (
    <Root>
      <InputTable className='margin-left-05 overflow-y-scroll'>
        <div ref={scrollContainerRef}>
          <div className='thead'>
            <div className='tr'>
              <div className='trCol2p5 flex-center tableStickyNo'>{t('No')}</div>
              <div className='trCol22 flex-center tableStickyTitle'>{t('건설사 현장명')}</div>
              <div className='trCol5 flex-center content-overflow'>{t('근로자')}</div>
              <div className='trCol5 flex-center content-overflow'>{t('사용유무')}</div>
              <div className='trCol5 flex-center'> </div>
            </div>
          </div>
          <div className='table'>
            <div className='tbody'>
              {tableState?.length > 0 ? (
                tableState.map((el: any, i: number) => (
                  <TableRow //
                    rowData={el}
                    setTableState={setTableState}
                    index={i}
                    key={`table_${i}_${el.signTag}_${getDate}`}
                    emptyCheck={emptyCheck}
                    headOptions={headOptions}
                    deleteMSite={deleteMSite}
                  />
                ))
              ) : (
                <div className='noData'>No data.</div>
              )}
            </div>
          </div>
        </div>
      </InputTable>
      <ButtonsWrapper>
        <div className='countWrapper'>
          {/* <span className='countName'>
            {t('전체')}
            <span className='countNumber'>{tableState.length}</span>
          </span>
          <span className='seperator' /> */}
          <span className='countName'>
            {t('사용 중')}
            <span className='countNumber'>{useYnLength}</span>
          </span>
        </div>
        <div>
          <BtnBlue onClick={onClickAdd}>{t('신규항목 추가')}</BtnBlue>
          <BtnBlue onClick={onClickSave}>{t('저장')}</BtnBlue>
        </div>
      </ButtonsWrapper>
    </Root>
  );
};

export default MSiteList;
