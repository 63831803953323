/*
 * 작성자 : 홍선영
 * 날짜 : 2023.12.06
 * 기능 : 검색조건 Y/N 선택값에 따라 그에 해당하는 리스트를 필터링해서 리턴하는 함수
 */

export const ynFilter = (initialArray: any[], key: string, option: string) => {
  if (option === 'Y') return initialArray.filter((el: any) => el[key] === 'Y');
  if (option === 'N') return initialArray.filter((el: any) => el[key] === 'N');
  return initialArray;
};
