/**
 * 작성자 : 홍선영
 * 날짜 : 2023.12.18
 * 기능 : 방송장비테스트 모달
 */
/*
 * 수정자 : 김광민
 * 수정 날짜 : 2024.03.05
 * 수정 이유 1 : global style 걷어내고 컴포넌트 내 스타일시트 새로 작성
 * 수정 이유 2 : html 구조 재구성 및 전체 스타일시트 수정
 * 수정 이유 3 : map 메서드로 감싸는 부분 별도 컴포넌트로 추출
 */

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Root = styled.div`
  height: 100%;
  max-height: inherit;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 0 0 40%;
  background-color: ${({ theme }: { theme: any }) => theme.board};
  position: relative;
  .header,
  .body > .list {
    background-color: ${({ theme }: { theme: any }) => theme.board};
    border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    padding: 0 1rem;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    .seperator {
      /* height: 1rem;
      border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline_em}; */
      &::before {
        content: '•';
      }
    }
  }
  .header {
    position: sticky;
    top: 0;
    height: 3rem;
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    justify-content: space-between;
    .title {
      display: flex;
      gap: 0 0.25rem;
      align-items: center;
    }
  }
  .body {
    overflow: auto;
    .list {
      background-color: ${({ theme }: { theme: any }) => theme.board};
      border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.875rem;
      padding: 0.5rem;
      gap: 1.25rem;
      .content {
        display: flex;
        gap: 0.25rem;
        flex-shrink: 0;
        .index {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 1.5rem;
          height: 1.5rem;
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
          border-radius: 0.25rem;
          margin: 0 0.5rem;
        }
        .text {
          display: flex;
          align-items: center;
          gap: 0.25rem;
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        }
      }
      .badge {
        flex-shrink: 0;
        width: fit-content;
        padding: 0 0.75rem;
        display: flex;
        min-height: 2rem;
        align-items: center;
        gap: 0.375rem;
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        border-radius: 0.25rem;
        .bullet {
          display: flex;
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 1rem;
        }
        &.black {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_slate};
          color: ${({ theme }: { theme: any }) => theme.filled_slate_deep};
          .bullet {
            background-color: ${({ theme }: { theme: any }) => theme.filled_slate};
          }
        }
        &.green {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
          color: ${({ theme }: { theme: any }) => theme.filled_green_deep};
          .bullet {
            background-color: ${({ theme }: { theme: any }) => theme.filled_green};
          }
        }
        &.blue {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
          color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
          .bullet {
            background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
          }
        }
        &.red {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
          color: ${({ theme }: { theme: any }) => theme.filled_red_deep};
          .bullet {
            background-color: ${({ theme }: { theme: any }) => theme.filled_red};
          }
        }
      }
    }
  }
`;

type BroadcastList = {
  bsCd: string;
  bsId: string;
  bsIp: string;
  bsName: string;
  bsPwd: string;
  cName: null | string;
  checked: boolean;
  pPort: string;
  tadCd: string;
  tadName: string;
  tatCd: string;
  tatGubun: string;
};

const BroadcastEquipList = ({ data, state }: { data: BroadcastList[]; state: any }) => {
  const { t } = useTranslation();

  const getColor = (params: any) => {
    if (params === 0 || !params) return 'black';
    if (params === 1) return 'green';
    if (params === 2) return 'blue';
    return 'red';
  };

  const getText = (params: any) => {
    if (params === 0 || !params) return t('연결중');
    if (params === 1) return t('방송가능');
    if (params === 2) return t('방송중');
    return t('방송불가');
  };

  return (
    <Root>
      <div className='header'>
        <div className='title'>
          {t('구역')}
          <span className='seperator' />
          {t('방송장비명')}
        </div>
        {t('상태')}
      </div>
      <div className='body'>
        {data.map((el: BroadcastList, index: number) => (
          <div className='list' key={index}>
            <div className='content'>
              <span className='index'>{index + 1}</span>
              <div className='text'>
                {el.tadName}
                <span className='seperator' />
                {el.bsName}
              </div>
            </div>
            <div className={`badge ${getColor(state[index])}`}>
              <span className='bullet' />
              <span className='bullet-status'>{getText(state[index])}</span>
            </div>
          </div>
        ))}
      </div>
    </Root>
  );
};

export default BroadcastEquipList;
