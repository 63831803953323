/**
 * 작성자 : 홍선영
 * 날짜 : 2023.06.22
 * 기능 : 검색어를 입력하여 옵션리스트를 검색하고 값을 선택해서 setState(배열) 하는 셀렉트박스 컴포넌트
 */

import React, { SetStateAction, useEffect, useState, Dispatch, useRef, useCallback, useLayoutEffect } from 'react';
import { IComCd } from 'customTypes';
import { FLAG_CREATE_OR_UPDATE } from '../_constants';
import { arraySortByAscdOrder } from '../utils/arraySortByAscdOrder';
import { SelectBoxSmDropStyle } from '../assets/styles/SelectBoxSmDrop';
import { useTranslation } from 'react-i18next';
import { v1 } from 'uuid';
import { apiGet } from '../services/_common';

interface ISearchSelectBox {
  options: any; // 셀렉트박스 배열 [{ 키네임1: 코드값1 }, { 키네임2: 코드값2 }, { 키네임3: 코드값3 }]
  defaultOption: string; // 셀렉트 디폴트옵션
  state: any; //  state값
  setState: Dispatch<SetStateAction<any>>; //  useState set액션
  setBeforeState?: Dispatch<SetStateAction<any>>; //  useState set액션
  stateKey: string; //  state 키값
  codeKey: string;
  initiateKey?: any;
  index: number; // 객체의 인덱스값
  useFlag: boolean; // 스테이트값이 변경될때 flag값을 업데이트해야하는 지 여부
  getBorderStyle?: any; // 선택값이 없을 때 보더 스타일
  disabled: boolean;
  hasSubList?: { codeKey: string; nameKey: string }; // 부모옵션값이 변경됐을 때 자식옵션상태 미선택으로 변경하기 위한 상태값
  align?: 'left' | 'right';
  dropDownWidth?: 'fit-content' | 'expand-content' | 'expand-content-md' | 'expand-content-sm' | 'expand-content-xs';
  asideMain?: boolean;
  comboWidth?: 'expand-box' | 'expand-box-md' | 'expand-box-sm';
  optionHeight?: 'height-lg' | 'height-md' | 'height-base' | 'height-sm';
  searchPlaceholder?: string; // 검색란 플레이스홀더
}

const SearchSelectBoxs = ({
  options,
  defaultOption,
  state,
  setState,
  setBeforeState,
  stateKey,
  codeKey,
  initiateKey,
  index,
  useFlag,
  getBorderStyle,
  disabled,
  dropDownWidth = 'fit-content',
  align = 'left',
  asideMain = false,
  comboWidth,
  optionHeight,
  hasSubList,
  searchPlaceholder,
}: ISearchSelectBox) => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>({});
  const [userInput, setUserInput] = useState({ value: '' });
  const [searchResultMenu, setSearchResultMenu] = useState([]);
  const [optionIndex, setOptionIndex] = useState(0);
  const { value } = userInput;
  const [dropdownPosition, setDropdownPosition] = useState<'bottomToTop' | 'topToBottom' | null>(null);

  useEffect(() => {
    //  initiate key가 변경됐을 때 셀렉트옵션 초기화
    if (initiateKey) setSelectedOption({});
  }, [initiateKey]);

  const search = useCallback(() => {
    if (value !== '') {
      //  dropdown메뉴를 순회하면서 검색문자를 포함한 옵션배열 리턴(대소문자 구분없이 검색)
      const result: any = options?.filter((word: any) => {
        return word[codeKey].toLowerCase().includes(value.toLowerCase());
      });
      setSearchResultMenu(result);
    } else {
      //  검색입력값이 없거나 모두지워졌을때 검색메뉴배열 비우기
      setSearchResultMenu([]);
    }
  }, [value, codeKey, options]);

  useEffect(() => {
    search();
  }, [search]);

  useEffect(() => {
    // 선택한 옵션객체에 코드키가 있을때 state값 업데이트
    if (Object.keys(selectedOption).includes(codeKey)) {
      if (stateKey === 'wPrejobtype') {
        getJobtypeAPI(state[index].hCd, state[index].sCd, selectedOption[stateKey], stateKey);
      } else if (hasSubList) {
        // 부모 목록을 변경했을 때 자식목록값 초기화
        setState((prev: any) => prev.map((el: any, i: number) => (i === index ? { ...el, [stateKey]: selectedOption[stateKey], [hasSubList.codeKey]: '', [hasSubList.nameKey]: '' } : el)));
      } else if (useFlag) {
        // 스테이트 업데이트 시 플래그값 업데이트하는 경우
        setState((prev: any) => prev.map((el: any, i: number) => (i === index ? { ...el, [stateKey]: selectedOption[stateKey], flag: FLAG_CREATE_OR_UPDATE } : el)));
        if (setBeforeState) {
          setBeforeState((prev: any) => prev.map((el: any, i: number) => (i === index ? { ...el, [stateKey]: selectedOption[stateKey], flag: FLAG_CREATE_OR_UPDATE } : el)));
        }
      } else {
        setState((prev: any) => prev.map((el: any, i: number) => (i === index ? { ...el, [stateKey]: selectedOption[stateKey] } : el)));
        if (setBeforeState) {
          setBeforeState((prev: any) => prev.map((el: any, i: number) => (i === index ? { ...el, [stateKey]: selectedOption[stateKey] } : el)));
        }
      }
    }
  }, [selectedOption, codeKey, stateKey]);

  // 직종 코드리스트 호출
  const getJobtypeAPI = async (hCd: string, sCd: string, grCd: string, key: string) => {
    const req = {
      hCd,
      sCd,
      grCd,
    };
    const res = await apiGet({ path: '/code/normal/prejobtype', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const formatSelectBoxFormList = data.prejobtypeList.map((el: IComCd, i: number) => {
        return { type: 'wJobtype', wJobtype: el.subCd, name: el.cdName, cdSort: i + 1 };
      });
      const newObj = { type: 'wJobtype', wJobtype: '', name: t('선택'), cdSort: 0 };
      const addAllSelect: any = [...formatSelectBoxFormList, newObj];
      const sortedArray: any = arraySortByAscdOrder(addAllSelect, 'cdSort');

      const newState = state.map((el: any, i: number) => (i === index ? { ...el, options: sortedArray, [key]: grCd } : { ...el }));
      setState(newState);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 콤보박스 바깥클릭 감지
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  // 콤보박스 열린상태에서 바깥 클릭했을 때 창닫기
  const handleClickOutside = (e: any) => {
    if (wrapperRef && !wrapperRef.current?.contains(e.target)) setIsDropdownOpen(false);
    else setIsDropdownOpen(true);
  };

  // 콤보박스 클릭시 옵션리스트 열기
  const onClickDropdown = () => {
    if (!disabled) setIsDropdownOpen(!isDropdownOpen);
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput({ ...userInput, value: e.currentTarget.value });
  };

  // 콤보박스에서 방향키 위아래로 움직였을 때 옵션이동
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLDivElement>, option: any) => {
    const key = e.key || e.keyCode;
    if ((key === 'ArrowUp' || key === 38) && optionIndex > 0) {
      //  방향키 위
      setOptionIndex((prev) => prev - 1);
    }
    if ((key === 'ArrowDown' || key === 40) && optionIndex < option.length - 1) {
      //  방향키 아래
      setOptionIndex((prev) => prev + 1);
    }
    if (key === 'Enter' || key === 13) {
      //  엔터
      setSelectedOption(option[optionIndex]);
      setIsDropdownOpen(!isDropdownOpen);
      setUserInput({ value: '' });
    }
  };

  //  검색입력값이 없을 때 기본메뉴배열 노출
  const renderAllDropdownMenu = () => {
    if (value === '') {
      return options?.map((el: any) => (
        <li
          className={state[index][stateKey] === el[stateKey] ? 'selectedOption' : undefined}
          key={`${v1()}`}
          role='presentation'
          onClick={() => {
            setSelectedOption(el);
            setIsDropdownOpen(!isDropdownOpen);
          }}
        >
          {el[codeKey]}
        </li>
      ));
    }
    return null;
  };

  //  검색문자를 포함하는 메뉴배열 노출
  const renderSearchDropdownMenu = () => {
    return searchResultMenu.map((el, i) => (
      <li
        className={optionIndex === i ? 'selectedOption' : undefined}
        key={`${el[stateKey]}`}
        role='presentation'
        onClick={() => {
          setSelectedOption(el);
          setIsDropdownOpen(!isDropdownOpen);
          setUserInput({ value: '' });
        }}
      >
        {el[codeKey]}
      </li>
    ));
  };

  useLayoutEffect(() => {
    const updatePosition = () => {
      if (wrapperRef.current) {
        const rect = wrapperRef.current.getBoundingClientRect();
        if (window.innerHeight - 72 < rect.bottom && rect.top - 72 > rect.height) {
          setDropdownPosition('bottomToTop');
        } else {
          setDropdownPosition('topToBottom');
        }
      }
    };
    updatePosition();
  }, [isDropdownOpen]);

  return (
    <SelectBoxSmDropStyle isDropdownOpen={isDropdownOpen}>
      <ul style={getBorderStyle}>
        <li className={`defaultOption ${isDropdownOpen ? 'opened' : ''} `} role='presentation' onClick={onClickDropdown}>
          <span>{selectedOption[codeKey] === undefined || state[stateKey] === '' ? defaultOption : selectedOption[codeKey]}</span>
          <div>
            <span className='material-symbols-rounded'>keyboard_arrow_down</span>
          </div>
        </li>
        <div className={`optionBox ${isDropdownOpen ? 'on' : 'off'} ${optionHeight ?? ''} ${dropDownWidth} ${dropdownPosition === 'bottomToTop' && 'bottomToTop'}`} ref={wrapperRef}>
          <li className='searchBox'>
            <input
              type='text'
              onKeyDown={searchResultMenu.length !== 0 ? (e) => handleKeyDown(e, searchResultMenu) : (e) => handleKeyDown(e, options)}
              onChange={(e) => onChangeInput(e)}
              placeholder={searchPlaceholder !== undefined ? searchPlaceholder : t('검색...')}
              value={value}
            />
          </li>
          {searchResultMenu.length !== 0 ? renderSearchDropdownMenu() : renderAllDropdownMenu()}
        </div>
      </ul>
    </SelectBoxSmDropStyle>
  );
};

export default React.memo(SearchSelectBoxs);
