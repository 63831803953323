import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 2rem;
  color: ${({ theme }: { theme: any }) => theme.color.zinc_200};
  background-color: ${({ theme }: { theme: any }) => theme.color.zinc_800};
  border-radius: 0.25rem;
  padding-left: 0.5rem;
  gap: 0.5rem;
  > .material-symbols-rounded {
    font-size: 1.375rem;
    font-variation-settings: 'FILL' 1;
  }
  > .name {
    color: ${({ theme }: { theme: any }) => theme.color.zinc_400};
    letter-spacing: normal;
    font-size: 0.875rem;
    font-weight: 400;
    width: fit-content;
    word-break: keep-all;
    white-space: nowrap;
  }
  .options {
    display: flex;
    width: fit-content;
    flex-shrink: 0;
    .disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

type Option = { key: string; selected: boolean; button: React.ReactNode; disabled: boolean };

type Props = {
  name?: string;
  icon?: string;
  tabs: Option[];
  onClick: (divisionNum: number) => void;
  disabled?: boolean;
};

const DarkSmallTabContainer = ({ name, icon, tabs, onClick, disabled = false }: Props) => {
  return (
    <Root className={disabled ? 'disabled' : ''}>
      {icon && <span className='material-symbols-rounded'>{icon}</span>}
      {name && <span className='name'>{name}</span>}
      <div className={`options `}>
        {tabs.map((option) => {
          return (
            <div
              className={option.disabled ? 'disabled' : ''}
              key={option.key} //
              role='button'
              tabIndex={0}
              onClick={() => onClick(Number(option.key))}
            >
              {option.button}
            </div>
          );
        })}
      </div>
    </Root>
  );
};

export default DarkSmallTabContainer;
