import { Dispatch, SetStateAction, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { v1 } from 'uuid';

import { IComCd } from 'customTypes';
import { scrollToNode } from '../../utils/scrollToNode';
import { InputTable } from '../../assets/styles/InputTable';
import { useTranslation } from 'react-i18next';
import { COMCD_INFRA, COMCD_JONGMOK, COMCD_LOCK_YN, COMCD_S_STATUS, COMCD_S_VERSION, COMCD_UPTAE, COMCD_USE_YN, COMCD_USE_YN1 } from '../../_constants';

interface ITable {
  style?: ITableStyle;
  rawData: any;
  state: any;
  setRowState: any;
  setIsNewAdd: Dispatch<SetStateAction<boolean>>;
  setIsSettingPassword?: Dispatch<SetStateAction<boolean>>;
  userInfoInputFormRef: any;
  typeName?: string;
  setHCd?: Dispatch<SetStateAction<any>>;
  setSCd?: Dispatch<SetStateAction<any>>;
  setSStatus?: Dispatch<SetStateAction<IComCd>>;
  setHTransyn?: Dispatch<SetStateAction<IComCd>>;
  setSVersion?: Dispatch<SetStateAction<IComCd>>;
  setLockYn?: Dispatch<SetStateAction<any>>;
  setUseYn?: Dispatch<SetStateAction<any>>;
  setSmsYn?: Dispatch<SetStateAction<IComCd>>;
  setSInfra?: Dispatch<SetStateAction<IComCd>>;
  setJongmok?: Dispatch<SetStateAction<any>>;
  setUptae?: Dispatch<SetStateAction<any>>;
  setTelNumState?: Dispatch<SetStateAction<any>>;
  setHpNumState?: Dispatch<SetStateAction<any>>;
  setIpInfoState?: Dispatch<SetStateAction<any>>;
  setElistYn?: Dispatch<SetStateAction<any>>;
  setEexcelYn?: Dispatch<SetStateAction<any>>;
  setEprintYn?: Dispatch<SetStateAction<any>>;
  setViewMainPage?: () => void;
}

interface ITableStyle {
  colCount: number;
  width: string;
}

const ViewTable = ({
  style,
  rawData,
  state,
  setRowState,
  setIsNewAdd,
  setIsSettingPassword,
  userInfoInputFormRef,
  typeName,
  setHCd,
  setSCd,
  setSStatus,
  setHTransyn,
  setSVersion,
  setLockYn,
  setUseYn,
  setSmsYn,
  setSInfra,
  setJongmok,
  setUptae,
  setTelNumState,
  setHpNumState,
  setIpInfoState,
  setViewMainPage,
  setElistYn,
  setEexcelYn,
  setEprintYn,
}: ITable) => {
  const { t } = useTranslation();
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const { pathname } = useLocation();
  const tableHeader = Object.keys(state[0]);
  const onClickRow = (rowObject: any, index: number) => {
    const {
      bigo,
      delYn,
      eDate,
      eMail,
      editor,
      gCd,
      hpNum,
      ipInfo,
      lockYn,
      hCd,
      sCd,
      telNum,
      useYn,
      userId,
      userName,
      userPwd,
      wDate,
      writer,
      loginerrorCount,
      displayPassword,
      gName,
      hName,
      sName,
      address1,
      address2,
      fDate,
      hTranscd,
      hTransyn,
      postNum,
      sDate,
      sInfra,
      sStatus,
      sTel,
      sVersion,
      smsYn,
      hCeoname,
      hLogoimage,
      hDLogoimage,
      hNum,
      hTel,
      jongmok,
      uptae,
      subDomain,
      eExcelYn,
      eListYn,
      ePrintYn,
      latitude,
      longitude,
      msiteYn,
      eduexamYn,
    } = rowObject;
    setRowState((prev: any) => ({
      ...prev,
      bigo,
      delYn,
      eDate,
      eMail,
      editor,
      gCd,
      hCd,
      sCd,
      hpNum,
      ipInfo,
      lockYn,
      telNum,
      useYn,
      userId,
      userName,
      userPwd,
      wDate,
      writer,
      loginerrorCount,
      isDuplicateChecked: true,
      newPassword: '',
      newPasswordConfirm: '',
      displayPassword: displayPassword || userPwd,
      gName,
      hName,
      sName,
      address1,
      address2,
      fDate,
      hTranscd,
      hTransyn,
      postNum,
      sDate,
      sInfra,
      sStatus,
      sTel,
      sVersion,
      smsYn,
      hCeoname,
      hLogoimage,
      hDLogoimage,
      hNum,
      hTel,
      jongmok,
      uptae,
      subDomain,
      eExcelYn,
      eListYn,
      ePrintYn,
      msiteYn,
      eduexamYn,
      ...(latitude && { latitude }),
      ...(longitude && { longitude }),
    }));

    scrollToNode(userInfoInputFormRef); // 반응형에서 세로로 확장되는 경우때문에 scrollToNodeTop 쓰지 않고 해당노드(인풋폼)로 이동하는 함수 사용함
    setIsNewAdd(false);
    setIsSettingPassword && setIsSettingPassword(false);
    setHCd && setHCd((prev: any) => ({ ...prev, hCd: rowObject.hCd, cdName: rowObject.hName }));
    setSCd && setSCd((prev: any) => ({ ...prev, sCd: rowObject.sCd, sName: rowObject.sName }));

    setSStatus && setSStatus((prev: IComCd) => ({ ...prev, [COMCD_S_STATUS]: rowObject.sStatus }));
    setHTransyn && setHTransyn((prev: IComCd) => ({ ...prev, [COMCD_USE_YN1]: rowObject.hTransyn }));
    setSVersion && setSVersion((prev: IComCd) => ({ ...prev, [COMCD_S_VERSION]: rowObject.sVersion }));
    setLockYn && setLockYn((prev: any) => ({ ...prev, [COMCD_LOCK_YN]: rowObject.lockYn }));
    setUseYn && setUseYn((prev: any) => ({ ...prev, [COMCD_USE_YN]: rowObject.useYn }));
    setSmsYn && setSmsYn((prev: IComCd) => ({ ...prev, smsYn: rowObject.smsYn }));
    setSInfra && setSInfra((prev: IComCd) => ({ ...prev, [COMCD_INFRA]: rowObject.sInfra }));
    setJongmok && setJongmok((prev: IComCd) => ({ ...prev, [COMCD_JONGMOK]: rowObject.jongmok, cdName: '' }));
    setUptae && setUptae((prev: IComCd) => ({ ...prev, [COMCD_UPTAE]: rowObject.uptae }));
    setElistYn && setElistYn((prev: any) => ({ ...prev, [COMCD_USE_YN]: rowObject.eListYn }));
    setEexcelYn && setEexcelYn((prev: any) => ({ ...prev, [COMCD_USE_YN]: rowObject.eExcelYn }));
    setEprintYn && setEprintYn((prev: any) => ({ ...prev, [COMCD_USE_YN]: rowObject.ePrintYn }));

    if (setHpNumState) {
      const splitHpNumber = rowObject.hpNum.split('-');
      setHpNumState({ num1: splitHpNumber[0] || '', num2: splitHpNumber[1] || '', num3: splitHpNumber[2] || '' });
    }
    if (setTelNumState) {
      const splitTelNumber = rowObject.telNum.split('-');
      setTelNumState({ num1: splitTelNumber[0] || '', num2: splitTelNumber[1] || '', num3: splitTelNumber[2] || '' });
    }
    if (setIpInfoState) {
      const splitIpNumber = rowObject.ipInfo.split('.');
      setIpInfoState({ num1: splitIpNumber[0] || '', num2: splitIpNumber[1] || '', num3: splitIpNumber[2] || '', num4: splitIpNumber[3] || '' });
    }

    setSelectedRowIndex(index);
    if (setViewMainPage) {
      setViewMainPage();
    }
  };

  const setKoreanTitle = (colTitleEl: string) => {
    if (colTitleEl === 'userId') return 'ID';
    if (colTitleEl === 'userName') {
      if (typeName === '본사 관리자' || typeName === '영업 담당자') return `${typeName}명`;
      return '관리자명';
    }
    if (colTitleEl === 'useYn') return '사용유무';
    if (colTitleEl === 'lockYn') return '잠금유무';
    if (colTitleEl === 'hCd' || colTitleEl === 'sCd') return '코드';
    if (colTitleEl === 'gName') return '권한';
    if (colTitleEl === 'hName') return '본사명';
    if (colTitleEl === 'sName') return '현장명';
    return colTitleEl;
  };

  const valueKorean = (colTitleEl: string, dataEl: any) => {
    if (colTitleEl === 'useYn') {
      if (dataEl[colTitleEl] === 'Y') return '사용';
      return '미사용';
    }
    if (colTitleEl === 'lockYn') {
      if (dataEl[colTitleEl] === 'Y') return '잠금';
      return '미잠금';
    }
    return dataEl[colTitleEl];
  };

  return (
    <InputTable className='margin-left-05'>
      {/* <Table id='tableId'> */}
      <div className='thead'>
        <div className='tr'>
          {pathname === '/m_common3' || pathname === '/m_common4' ? (
            <div className='trCol2p5 flex-center tableStickyNo' key={v1()}>
              No
            </div>
          ) : null}

          {pathname !== '/master1' &&
            tableHeader.map((colTitleEl) => (
              <div className={`th trCol10 flex-center content-overflow ${colTitleEl}`} key={v1()}>
                {t(setKoreanTitle(colTitleEl))}
              </div>
            ))}
          {pathname === '/master1' &&
            tableHeader.map((colTitleEl) => (
              <div
                className={`td content-overflow flex-center
                  ${(colTitleEl === 'userId' || colTitleEl === 'userName') && 'trCol10'} 
                  ${colTitleEl !== 'userId' && colTitleEl !== 'userName' && 'trCol6'} 
                  ${colTitleEl}`}
                key={v1()}
              >
                {t(setKoreanTitle(colTitleEl))}
              </div>
            ))}
        </div>
      </div>
      <div className='tbody'>
        {rawData?.length > 0 ? (
          rawData?.map((dataEl: any, i: number) => (
            <div
              className={`tr ${i === selectedRowIndex ? 'selected' : ''}`}
              role='presentation'
              key={v1()}
              onClick={() => onClickRow(dataEl, i)}
              // style={getBackgroundStyle(i)}
            >
              {pathname === '/m_common3' || pathname === '/m_common4' ? (
                <div className='td trCol2p5 flex-center tableStickyNo' key={v1()}>
                  {i + 1}
                </div>
              ) : null}
              {pathname !== '/master1' &&
                tableHeader.map((colTitleEl: any) => (
                  <div className={`td trCol10 flex-center content-overflow ${colTitleEl}`} key={v1()}>
                    {t(valueKorean(colTitleEl, dataEl))}
                  </div>
                ))}
              {pathname === '/master1' &&
                tableHeader.map((colTitleEl: any) => (
                  <div
                    className={`td content-overflow 
                  ${colTitleEl === 'userId' && 'flex-basic trCol10'} 
                  ${colTitleEl === 'userName' && 'flex-basic trCol10'} 
                  ${colTitleEl !== 'userId' && colTitleEl !== 'userName' && 'flex-center  trCol6'} 
                  ${colTitleEl}`}
                    key={v1()}
                  >
                    {t(valueKorean(colTitleEl, dataEl))}
                  </div>
                ))}
            </div>
          ))
        ) : (
          <div className='noData'>No data.</div>
        )}
      </div>
    </InputTable>
  );
};

export default ViewTable;
