/**
 * 작성자 : 한영광
 * 날짜 : 2023.06.26
 * 경로 : 출역 관리 - 일 출역현황
 */

/**
 * 작성자 : 홍선영
 * 날짜 : 2023.07.05
 * 수정사항 : 리스트 내 수정버튼 삭제하고, 각 로우 클릭 시 근로자정보 팝업에서 출역 수정할수 있도록 함
 */

import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { IoChevronUpSharp } from 'react-icons/io5';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useOutletContext, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { LIST_COUNT } from '../../_constants';
import { IUser, pageInfoState, userState } from '../../atoms';
import { IComCdList, ITabObject } from 'customTypes';
import { BtnGhost } from '../../components/Button';
import { SearchOptions } from '../../assets/styles/SearchOptions';
import { FloatingButtonToTop } from '../../assets/styles/FloatingButtonToTop';
import { LoadingModalBackground } from '../../assets/styles/Modal';
import { useSetAuth } from '../../utils/useSetAuth';
import { trimObject } from '../../utils/trimObject';
import { todayYYYYMMDD } from '../../utils/formatDate';
import { scrollToNodeTop } from '../../utils/scrollToNodeTop';
import { setComCdListState } from '../../utils/setComCdListState';
import { useDetectScrolledToBottom } from '../../utils/useDetectScrolledToBottom';
import useSetJobtype from '../../utils/useSetJobtype';
import useOnKeydownF9 from '../../utils/useOnKeydownF9';
import Tab from '../../components/Tab';
import Input from '../../components/Input';
import Portal from '../../components/Portal';
import TuiGrid from '../../components/Table/TuiGrid';
import SelectBox from '../../components/SelectBox';
import TuiGridWrapper from '../../components/Table/TuiGridWrapper';
import InfoAttendModal from '../../components/Modal/InfoAttendModal';
import InfoWorkerModal from '../../components/Modal/InfoWorkerModal';
import SearchSelectBoxSm from '../../components/SearchSelectBoxSm';
import EnrollmentAttendModal from '../../components/Modal/EnrollmentAttendModal';
import RangePicker, { onChangeRangeInput } from '../../components/RangePicker';
import { logPost } from '../../services/log';
import { useSetSjcd } from '../../utils/useSetSjcd';
import { useGetFrozenCount } from '../../utils/getFrozenCount';
import { ContentsContainerRoot } from '../../assets/styles/ContentsContainerRoot';
import i18n from '../../translation/i18n';
import useSetListCount from '../../utils/useSetListCount';
import { apiGet } from '../../services/_common';
import ShortcutButton from '../../components/button/ShortcutButton';
import { useQuery } from '@tanstack/react-query';

const Root = styled(ContentsContainerRoot)`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0.5rem;
  padding: 2rem 0;

  > :nth-child(1) {
    align-self: flex-start;
  }
`;

const Sattend1 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const INIT_F_NUM = { type: 'fNum', fNum: 'A', cdName: t('전체') };
  const INIT_F_LOCATION = { type: 'fLocation', fLocation: '', cdName: t('전체') };
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const { listCount, setListCount, patchUserMenuAPI } = useSetListCount(); // 검색줄수설정 훅
  const { siteJoinInfoList } = useSetSjcd();
  const location = useLocation();
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  const toDay = todayYYYYMMDD();
  const [userInfo, setUserInfo] = useRecoilState<IUser>(userState);
  const { userMenuList, eExcelYn, eListYn, ePrintYn } = userInfo;
  const [tableState, setTableState] = useState<any[]>([]);
  const [orgTableState, setOrgTableState] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const wNameRef = useRef<HTMLInputElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const [visibleRangePicker, setVisibleRangePicker] = useState(false);
  const [rangeState, setRangeState] = useState([
    {
      startDate: location.state?.startDate ? new Date(location.state?.startDate) : new Date(),
      endDate: location.state?.endDate ? new Date(location.state?.endDate) : new Date(),
      key: 'selection',
    },
  ]);
  const [listCountComCdList, setListCountComCdList] = useState<IComCdList[]>([]); // 검색줄수 공통코드
  const [jikJongList, setJikJongList] = useState<any[]>([]);
  const [fNameList, setFnameList] = useState<any[]>([]);
  const [searchOption, setSearchOption] = useState({
    wName: location.state?.wName || '',
    start: location.state?.startDate || toDay,
    end: location.state?.endDate || toDay,
  });
  const [fNum, setFNum] = useState(INIT_F_NUM);
  const [sjCd, setSjCd] = useState({ type: 'sjCd', sjCd: '', cdName: '' });
  const [fLocation, setFLocation] = useState({ type: 'fLocation', fLocation: '', cdName: '' });
  const [fLocationList, setFLocationList] = useState<any[]>([]);
  const [wPrejobtype, setWPrejobtype] = useState({ type: 'wPrejobtype', wPrejobtype: '', cdName: '' });
  const [wJobtype, setWJobtype] = useState({ type: 'wJobtype', wJobtype: '', cdName: '' });
  const { prejobtypeList, jobtypeList } = useSetJobtype(wPrejobtype.wPrejobtype);
  const [excelBtn, setExcelBtn] = useState(false);
  const { isBottom } = useDetectScrolledToBottom(tableRef);
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '', code: { wCd: '' }, index: 0 });
  const [newTabList, setNewTabList] = useState<ITabObject[]>();
  const [rowKey, setRowKey] = useState('');
  const [filterTableState, setFilterTableState] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [initiate, setinitiate] = useState('');

  useEffect(() => {
    const arr: any[] = [];
    userMenuList.map((v: any) => {
      const result = tabInfo?.tabList?.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  useEffect(() => {
    setLoading(true);
    getAttendDayAPI().then((res: any) => {
      if (res.status === 200) {
        getNormalCd();
        getFLocation();
        setComCdListState(LIST_COUNT, setListCountComCdList, false);
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '일 출역현황',
          action: '조회',
          etc: ``,
        });
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    setColumns(
      userInfo.prejobtypeYn === 'Y'
        ? [
            {
              header: t('근로자명'),
              name: 'wName',
              align: 'left',
              sortable: true,
              width: 150,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('협력업체'),
              name: 'sjName',
              align: 'left',
              sortable: true,
              filter: 'select',
              width: 200,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('공종'),
              name: 'wPrejobtypeName',
              align: 'left',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('직종'),
              name: 'wJobtypeName',
              align: 'left',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('취업일자'),
              name: 'wJobdate',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('생년월일'),
              name: 'wBdate',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('출역일자'),
              name: 'aDate',
              align: 'center',
              sortable: true,
              filter: 'select',
              minWidth: 120,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('최초시간'),
              name: 'aTimeMin',
              align: 'center',
              sortable: true,
              minWidth: 120,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('최종시간'),
              name: 'aTimeMax',
              align: 'center',
              sortable: true,
              minWidth: 120,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('최초체온'),
              name: 'tempMin',
              align: 'center',
              sortable: true,
              minWidth: 100,
            },
            {
              header: t('최종체온'),
              name: 'tempMax',
              align: 'center',
              sortable: true,
              minWidth: 100,
            },
          ]
        : [
            {
              header: t('근로자명'),
              name: 'wName',
              align: 'left',
              sortable: true,
              width: 150,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('협력업체'),
              name: 'sjName',
              align: 'left',
              sortable: true,
              filter: 'select',
              width: 200,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('직종'),
              name: 'wJobtypeName',
              align: 'left',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('취업일자'),
              name: 'wJobdate',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('생년월일'),
              name: 'wBdate',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('출역일자'),
              name: 'aDate',
              align: 'center',
              sortable: true,
              filter: 'select',
              minWidth: 120,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('최초시간'),
              name: 'aTimeMin',
              align: 'center',
              sortable: true,
              minWidth: 120,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('최종시간'),
              name: 'aTimeMax',
              align: 'center',
              sortable: true,
              minWidth: 120,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('최초체온'),
              name: 'tempMin',
              align: 'center',
              sortable: true,
              minWidth: 100,
            },
            {
              header: t('최종체온'),
              name: 'tempMax',
              align: 'center',
              sortable: true,
              minWidth: 100,
            },
            // {
            //   header: '수정',
            //   name: 'changeStatus',
            //   align: 'center',
            //   renderer: {
            //     type: UpdateBtnRenderer,
            //     options: {
            //       onUpdateButtonClicked,
            //     },
            //   },
            // },
          ]
    );
  }, [userInfo.prejobtypeYn, i18n.language]);

  useEffect(() => {
    setWJobtype({ type: 'wJobtype', wJobtype: '', cdName: '' });
    if (wPrejobtype.wPrejobtype === '') setJikJongList([]);
  }, [wPrejobtype.wPrejobtype]);

  useEffect(() => {
    setJikJongList(jobtypeList);
  }, [jobtypeList]);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    if (!rangeState[0].startDate) {
      return;
    }
    setSearchOption((prev: any) => ({ ...prev, start: dayjs(rangeState[0].startDate).format('YYYY-MM-DD') }));
  }, [rangeState[0].startDate]);

  useEffect(() => {
    if (!rangeState[0].endDate) {
      return;
    }
    setSearchOption((prev: any) => ({ ...prev, end: dayjs(rangeState[0].endDate).format('YYYY-MM-DD') }));
  }, [rangeState[0].endDate]);

  // 일 출역현황 조회 API
  const getAttendDayAPI = async () => {
    const newSearchOption = {
      ...searchOption,
      fNum: fNum.fNum === 'A' ? '' : fNum.fNum,
      sjCd: sjCd.sjCd,
      aDate1: searchOption.start.replaceAll('-', ''),
      aDate2: searchOption.end.replaceAll('-', ''),
      wPrejobtype: wPrejobtype.wPrejobtype,
      wJobtype: wJobtype.wJobtype,
      fLocation: fLocation.fLocation,
    };
    const { start, end, ...finalSearchObj } = newSearchOption; // searchOption에서 start, end값 삭제
    const trimData = trimObject(finalSearchObj);
    const req = { ...trimData, hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/attend/day', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      setLoading(false);
      setOrgTableState(data.attendList);
      setTableState(data.attendList);
    } else {
      setLoading(false);
      // toast.error(t(ERROR));
    }
    return res;
  };

  // 장비 리스트 조회 API
  const getNormalCd = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, subCd: 'I' };
    const res = await apiGet({ path: '/code/normal/site', req });

    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const newList = [];
      newList.push(INIT_F_NUM);
      data.normalList.map((v: any) => {
        newList.push({ type: 'fNum', fNum: v.subCd, cdName: v.cdName });
      });
      setFnameList(newList);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 출입위치 리스트 조회 API
  const getFLocation = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, subCd: 'J' };
    const res = await apiGet({ path: '/code/normal/site', req });

    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const newList = [];
      newList.push(INIT_F_LOCATION);
      data.normalList.map((v: any) => {
        newList.push({ type: 'fLocation', fLocation: v.subCd, cdName: v.cdName });
      });
      setFLocationList(newList);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 검색 버튼 클릭
  const onClickSearch = () => {
    setRowKey('');
    if (!openModal.status) {
      if (!searchOption.end && !searchOption.start) {
        toast.warning(t('출역일자를 입력하세요'));
      } else if (searchOption.end && searchOption.start > searchOption.end) {
        toast.warning(t('출역일자를 다시 입력하세요'));
        setSearchOption((prev: any) => ({ ...prev, end: '' }));
      } else {
        setLoading(true);
        setVisibleRangePicker(false);
        navigate({
          pathname: location.pathname,
          search: '',
        });
        getAttendDayAPI();
      }
    }
  };

  const initiateSearchOptions = () => {
    setSjCd({ type: 'sjCd', sjCd: '', cdName: '' });
    setFNum(INIT_F_NUM);
    setWPrejobtype({ type: 'wPrejobtype', wPrejobtype: '', cdName: '' });
    setWJobtype({ type: 'wJobtype', wJobtype: '', cdName: '' });
    setFLocation(INIT_F_LOCATION);
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    if (location.state?.sjName) {
      location.state.sjName = '';
    }
    Object.keys(searchOption).map((el: any) => {
      return setSearchOption((prev) => ({ ...prev, [el]: '', start: toDay, end: toDay }));
    });
    setinitiate(`${Math.random()}`);
    initiateSearchOptions();
    setRangeState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
  };

  // 신규 등록 버튼 클릭
  const onClickNew = () => {
    setOpenModal({ status: true, type: 'enrollmentAttend' });
  };

  // 엑셀 저장 버튼 클릭
  const onClickExcelSave = () => {
    if (tableState.length <= 0) return;
    setExcelBtn(true);
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '일 출역현황',
      action: '엑셀 저장',
      etc: ``,
    });
  };

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  // 인쇄 버튼 클릭
  const onClickPrint = () => {
    handlePrint();
  };

  // 근로자 Row 클릭
  const onClickRow = (rowData: any, columnName: any, filteredIndex: number) => {
    setRowKey(`${filteredIndex}`);
    setOpenModal({ status: true, type: 'infoWorker', code: rowData.wCd, aDate: rowData.aDate, index: filteredIndex });
  };

  const renderNewBtn = () => {
    if (auth.createAuth) {
      return (
        <BtnGhost onClick={onClickNew}>
          <span className='material-symbols-rounded'>add</span> {t('추가')}
        </BtnGhost>
      );
    }
    return null;
  };

  const renderExcelBtn = () => {
    if (auth.excelAuth) {
      return (
        <BtnGhost onClick={onClickExcelSave}>
          <span className='icon-ms-xlsx'>X</span> {t('엑셀')}
        </BtnGhost>
      );
      // return <BtnLightBlue onClick={onClickExcelSave}>엑셀 저장</BtnLightBlue>;
    }
    return null;
  };

  const renderPrintBtn = () => {
    if (auth.printAuth) {
      return (
        <BtnGhost onClick={onClickPrint}>
          <span className='material-symbols-rounded'>print</span>
          {t('인쇄')}
        </BtnGhost>
      );
    }
    return null;
  };

  const onClickRangeInput = () => {
    if (!visibleRangePicker) setVisibleRangePicker(true);
  };

  const onClickTab = (tab: string) => {
    setCurrentTabMrCd(tab);
  };

  // 근로자명 입력창에서 엔터입력시 검색
  const loginOnEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (wNameRef.current) onClickSearch();
    }
  };
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight, componentRef.current?.offsetWidth]);

  const frozenCount = useGetFrozenCount();

  return (
    <div className='contents'>
      <div className='content-container oneColumn'>
        <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
        <Root loading={loading}>
          <SearchOptions>
            {isBottom && (
              <FloatingButtonToTop>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <button type='button' onClick={() => scrollToNodeTop(tableRef)}>
                  <IoChevronUpSharp size={20} style={{ stroke: 'white' }} />
                </button>
              </FloatingButtonToTop>
            )}
            <div className='inputsWrapper'>
              <div className='inputForm-row withLabel'>
                <label htmlFor='sjCd'>{t('협력업체')}</label>
                <div className='inputForm-col'>
                  <SearchSelectBoxSm
                    options={siteJoinInfoList}
                    defaultOption={location.state?.sjName || t('전체')}
                    state={sjCd}
                    setState={setSjCd}
                    stateKey='sjCd'
                    codeKey='cdName'
                    initiateKey={sjCd.sjCd}
                    filterbar='filter-1-left'
                    optionHeight='height-md'
                  />
                </div>
              </div>
              <div className='inputForm-col withLabelComCf'>
                <label htmlFor='wForeignyn'>{t('장비명')}</label>
                <SelectBox
                  options={fNameList} //
                  defaultOption={t('전체')}
                  state={fNum}
                  setState={setFNum}
                  stateKey='fNum'
                  initiateKey={fNum.fNum}
                  filterbar='filter-1-left'
                  optionHeight='height-md'
                />
              </div>
              <div className='inputForm-col withLabelComCf'>
                <label htmlFor='fLocation'>{t('출입위치')}</label>
                <SelectBox
                  options={fLocationList} //
                  defaultOption={t('전체')}
                  state={fLocation}
                  setState={setFLocation}
                  stateKey='fLocation'
                  initiateKey={fLocation.fLocation}
                  filterbar='filter-1-left'
                  optionHeight='height-md'
                />
              </div>
              <div className='inputForm-row-fit'>
                <span className='calendarLabel'>{t('출역일자')}</span>
                <div className='inputForm-col'>
                  <div className='flex-basic'>
                    <input id='startInput' type='text' value={searchOption.start} onMouseDown={onClickRangeInput} onChange={(e) => onChangeRangeInput(e, 'start', setRangeState, setSearchOption)} />
                    <span className='inputDash'> ~ </span>
                    <input id='endInput' type='text' value={searchOption.end} onMouseDown={onClickRangeInput} onChange={(e) => onChangeRangeInput(e, 'end', setRangeState, setSearchOption)} />
                  </div>
                  {visibleRangePicker && (
                    <div className='rangePickerWrapper'>
                      <RangePicker state={rangeState} setState={setRangeState} setVisible={setVisibleRangePicker} />
                    </div>
                  )}
                </div>
              </div>
              {userInfo.prejobtypeYn === 'Y' && (
                <div className='inputForm-row'>
                  <div className='inputForm-col'>
                    <SearchSelectBoxSm
                      options={prejobtypeList}
                      defaultOption={t('공종 전체')}
                      state={wPrejobtype}
                      setState={setWPrejobtype}
                      stateKey='wPrejobtype'
                      codeKey='cdName'
                      initiateKey={initiate}
                      filterbar='filter-1-center'
                      comboWidth='expand-box-sm'
                      optionHeight='height-md'
                    />
                  </div>
                </div>
              )}
              <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <SearchSelectBoxSm
                    options={jikJongList}
                    defaultOption={t('직종 전체')}
                    state={wJobtype}
                    setState={setWJobtype}
                    stateKey='wJobtype'
                    codeKey='cdName'
                    initiateKey={wJobtype.wJobtype}
                    filterbar='filter-1-right'
                    comboWidth='expand-box-sm'
                    optionHeight='height-md'
                  />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <Input
                    placeholder={t('근로자명')}
                    label=''
                    type='text'
                    id='wName'
                    name='wName'
                    state={searchOption}
                    setState={setSearchOption}
                    inputRef={wNameRef}
                    onKeyDown={loginOnEnterKeyDown}
                    className='textInput'
                  />
                </div>
              </div>
            </div>
            <div className='inputsWrapper'>
              <div className='secondSearchOption'>
                <div className='flex-basic textBtnGroup'>
                  <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
                  {/* <BtnGhost onClick={onClickSearch} className='searchBtn'>
                    {t('검색')}
                    <span className='shortcut-f9'>F9</span>
                  </BtnGhost> */}
                  <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
                  <div className='searchResult'>
                    {t('총')}
                    <span>{tableState.length}</span>
                    {t('개')}
                  </div>
                  <div className='inputForm-row'>
                    <div className='inputForm-col withLabelComCf'>
                      <label htmlFor='useYn'>{t('보기 설정')}</label>
                      <SelectBox
                        options={listCountComCdList}
                        defaultOption={listCount[LIST_COUNT]}
                        state={listCount}
                        setState={setListCount}
                        stateKey={LIST_COUNT}
                        initiateKey={listCount[LIST_COUNT]}
                        setTableLinesAPI={patchUserMenuAPI}
                        optionHeight='height-sm'
                        rsearch
                      />
                    </div>
                  </div>
                </div>
                <div className='flex-basic iconBtnGroup'>
                  {renderNewBtn()}
                  {renderExcelBtn()}
                </div>
              </div>
            </div>
          </SearchOptions>
          <TuiGridWrapper componentRef={componentRef}>
            <TuiGrid
              data={tableState}
              filterTableState={filterTableState}
              setFilterTableState={setFilterTableState}
              columns={columns}
              perPage={Number(listCount[LIST_COUNT])}
              excelBtn={excelBtn}
              setExcelBtn={setExcelBtn}
              usePagenation
              onClickRow={onClickRow}
              // height={560}
              // visibleTotalCount
              scrollX
              height={tuiHeight}
              eListYn={eListYn}
              eExcelYn={eExcelYn}
              rowKey={rowKey}
              frozenCount={frozenCount}
              setRowKey={setRowKey}
            />
          </TuiGridWrapper>
        </Root>
      </div>
      {loading && (
        <LoadingModalBackground>
          <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
        </LoadingModalBackground>
      )}
      <Portal openModal={openModal?.status}>
        {openModal.status && openModal.type === 'infoAttend' && openModal.code && (
          <InfoAttendModal setOpenModal={setOpenModal} auth={auth} code={{ hCd: userInfo.hCd, sCd: userInfo.sCd, wCd: openModal.code.wCd, bSeq: openModal.code.bSeq, writer: openModal.code.writer }} />
        )}
        {openModal.status && openModal.type === 'infoWorker' && openModal.code && (
          <InfoWorkerModal
            getReportListAPI={getAttendDayAPI}
            reportArray={filterTableState}
            setReportArray={setFilterTableState}
            innerTabIndex={1}
            setOpenModal={setOpenModal}
            auth={auth}
            code={{ hCd: userInfo.hCd, sCd: userInfo.sCd, wCd: openModal.code, aDate: openModal.aDate }}
            index={openModal.index}
            setRowKey={setRowKey}
            listCount={Number(listCount[LIST_COUNT])}
          />
        )}
        {openModal.status && openModal.type === 'enrollmentAttend' && (
          <EnrollmentAttendModal getReportListAPI={getAttendDayAPI} setOpenModal={setOpenModal} auth={auth} code={{ hCd: userInfo.hCd, sCd: userInfo.sCd }} />
        )}
      </Portal>
    </div>
  );
};

export default Sattend1;
