/**
 * 작성자 : 홍선영
 * 날짜 : 2023.11.03
 * 기능 : 보고서 상단 검색조건의 국적 목록 useState hook
 */

import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { IUser, userState } from '../atoms';
import { apiGet } from '../services/_common';

interface INationality {
  type: string;
  wNationality: string;
  name: string;
}

const useSetNationality = () => {
  const { t } = useTranslation();
  const [nationalityList, setNationalityList] = useState<INationality[]>([]);
  const userInfo = useRecoilValue<IUser>(userState);

  // 국적 리스트 조회
  const nationalityListQuery = useQuery(['nationalityListGet', userInfo], () => apiGet({ path: '/code/normal/site', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, subCd: 'E' } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
  });

  useEffect(() => {
    if (nationalityListQuery.isSuccess) {
      const { status, data } = nationalityListQuery.data;
      if (status === 200) {
        if (data.data.normalList) {
          const newList = data.data.normalList.map((el: any) => {
            return {
              type: 'wNationality',
              wNationality: el.cdName,
              cdName: el.cdName,
            };
          });
          setNationalityList([{ type: 'wNationality', wNationality: 'A', cdName: t('국적 전체') }, ...newList]);
        }
      }
    }
  }, [nationalityListQuery.isSuccess]);

  return { nationalityList };
};

export default useSetNationality;
