import React, { useEffect, useState, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';

import { IModal } from 'customTypes';
import { ERROR, UNAUTHORIZED } from '../_constants';
import { IPageInfo, IUser, pageInfoState, userState } from '../atoms';
import { BtnGreen, BtnLightGreen } from '../components/Button';
import Input from '../components/Input';
import Portal from '../components/Portal';
import SignupModal from '../components/Modal/SignupModal';
import IpCheckModal from '../components/Modal/IpCheckModal';
import PasswordCheckYnModal from '../components/Modal/PasswordCheckYnModal';
import PasswordErrorCountModal from '../components/Modal/PasswordErrorCountModal';
import PasswordChangeTermModal from '../components/Modal/PasswordChangeTermModal';
import { ReactComponent as Top } from '../assets/images/login/top.svg';
import { ReactComponent as Bottom } from '../assets/images/login/bottom.svg';
import { trimObject } from '../utils/trimObject';
import { logPost } from '../services/log';
import { registerSignin } from '../services/register';
import Language from '../components/language';

const Root = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  > .language {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
  }
  > .login-background {
    width: 100%;
    height: 100%;
    position: fixed;
    pointer-events: none;
    > svg {
      width: initial;
      height: initial;
    }
    > :first-child {
      right: 0;
      position: absolute;
    }
    > :last-child {
      bottom: 0;
      position: absolute;
    }
  }
  > .login-container {
    width: 100%;
    padding: 0 5%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    > div {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
    }
    > .login-title {
      text-align: center;
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      > h2 {
        /* font-weight: 600; */
        > span {
          font-weight: 400;
        }
      }
      > span {
        letter-spacing: 0.0875rem;
      }
      > h1 {
        letter-spacing: 0.5rem;
        letter-spacing: 0.375rem;
        font-weight: 800;
        font-size: 3.5rem;
        text-indent: 0.5rem;
      }
    }
    > .login-form {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      input {
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.03);
      }
    }
    > .login-footer {
      display: flex;
      gap: 0.5rem;
      > button {
        width: 100%;
      }
    }
  }
`;

const Login = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const auth = queryParams.get('auth');
  const savedId = Cookies.get('prosafeUId');
  const idRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [rememberCheck, setRememberCheck] = useState(false);
  const [loginInfo, setLoginInfo] = useState({ id: '', password: '', passwordErrorCount: 0 });
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const [userInfo, setUserInfo] = useRecoilState<IUser>(userState);
  const resetUserInfo = useResetRecoilState(userState);
  const setPageInfo = useSetRecoilState<IPageInfo>(pageInfoState);
  // 쿠키에 저장된 prosafeUId 값이 있는경우 id input창과 checkbox에 값 세팅
  const setRememberId = useCallback(() => {
    if (savedId !== undefined) {
      setLoginInfo((prev) => ({ ...prev, id: savedId }));
      setRememberCheck(true);
    }
  }, [savedId]);

  useEffect(() => {
    if (savedId === undefined) {
      idRef.current?.focus();
    } else {
      passwordRef.current?.focus();
    }
    if (auth === 'fail') {
      resetUserInfo();
      window.history.replaceState({}, '', location.pathname);
      alert(UNAUTHORIZED);
    }
    // if (auth === 'forcelogout') {
    //   resetUserInfo();
    //   window.history.replaceState({}, '', location.pathname);
    //   alert(t('다른 기기에서 로그인'));
    // }
  }, []);

  useEffect(() => {
    setRememberId();
  }, [setRememberId]);

  const loginAPI = async () => {
    const reqData = trimObject(loginInfo);
    const res = await registerSignin(reqData);
    const { data, message, statusCode, errorCount } = res.data;
    if (statusCode === 200) {
      const { userId } = data.userInfo;
      const { userMenuList } = data;
      if (data.pwdCheckyn === 'Y') {
        toast.info(t('임시비밀번호를 사용하여 로그인 하셨습니다. 새로운 비밀번호를 입력해 주세요.'));
        setLoginInfo((prev) => ({ ...prev, passwordErrorCount: 0, accessToken: data.accessToken, refreshToken: data.refreshToken, userId, userInfo: data.userInfo }));
        setOpenModal((prev: IModal) => ({
          ...prev,
          status: true,
          type: 'passwordCheckYn',
          title: t('비밀번호 변경'),
        }));
        await logPost({
          hCd: data.userInfo.hCd,
          sCd: data.userInfo.sCd,
          userId: data.userInfo.userId,
          menu: '로그인',
          action: '임시비밀번호 사용 로그인 시도',
          etc: '',
        });
      } else if (data.pwdTermCheckyn === 'Y') {
        setLoginInfo((prev) => ({
          ...prev,
          userId,
          userInfo: data.userInfo,
          pwdLimitTerm: data.pwdLimitTerm,
          accessToken: data.accessToken,
        }));
        setOpenModal((prev: IModal) => ({
          ...prev,
          status: true,
          type: 'passwordChangeTerm',
          title: t('비밀번호 주기적 변경 안내'),
        }));
        await logPost({
          hCd: data.userInfo.hCd,
          sCd: data.userInfo.sCd,
          userId: data.userInfo.userId,
          menu: '로그인',
          action: '비밀번호 주기적 변경 기간내에 로그인 시도',
          etc: '',
        });
      } else if (data.ipCheckyn === 'Y') {
        setLoginInfo((prev) => ({ ...prev, accessToken: data.accessToken, refreshToken: data.refreshToken, userId, userInfo: data.userInfo, userMenuList }));
        setOpenModal((prev: IModal) => ({
          ...prev,
          status: true,
          type: 'ipCheck',
          title: t('접속 IP 불일치'),
        }));
        await logPost({
          hCd: data.userInfo.hCd,
          sCd: data.userInfo.sCd,
          userId: data.userInfo.userId,
          menu: '로그인',
          action: '접속 IP 불일치',
          etc: '',
        });
      } else {
        // 로그인 성공시 accessToken 쿠키저장
        data?.accessToken && Cookies.set('prosafeToken', data.accessToken);
        data?.refreshToken && Cookies.set('refreshToken', data.refreshToken);
        setUserInfo((prev) => ({
          ...prev,
          ...data.userInfo,
          userId,
          userMenuList,
        }));
        setPageInfo((prev) => ({ ...prev, header: { mtName: '대시보드', mdName: '' }, mtCd: '', mdCd: '', mrCd: '' }));
        await logPost({
          hCd: data.userInfo.hCd,
          sCd: data.userInfo.sCd,
          userId: data.userInfo.userId,
          menu: '로그인',
          action: '정상 로그인',
          etc: '',
        });
        // .then((result: any) => {
        //   if (result.status === 200) {
        //     window.location.replace('/'); // 정상적 로그인 성공
        //   }
        // });
      }
    } else if (statusCode === 4012) {
      // 비밀번호 불일치시 비밀번호 불일치카운트 증가
      toast.info(t(message));
      setLoginInfo((prev) => ({ ...prev, passwordErrorCount: errorCount }));
      await logPost({
        hCd: 'M000',
        sCd: '00000',
        userId: '',
        menu: '로그인',
        action: '패스워드 불일치',
        etc: `로그인 시도 ID: ${loginInfo.id}`,
      });
    } else if (statusCode === 403) {
      // 비밀번호 불일치 횟수 초과시 팝업
      setOpenModal((prev: IModal) => ({
        ...prev,
        status: true,
        type: 'passwordErrorCount',
        title: t('패스워드 불일치 횟수 초과'),
      }));
      await logPost({
        hCd: 'M000',
        sCd: '00000',
        userId: '',
        menu: '로그인',
        action: '패스워드 불일치 횟수 초과',
        etc: `로그인 시도 ID: ${loginInfo.id}`,
      });
    } else if (statusCode === 4018) {
      toast.info(t(message));
      await logPost({
        hCd: 'M000',
        sCd: '00000',
        userId: '',
        menu: '로그인',
        action: '삭제 처리된 계정 로그인 시도',
        etc: `로그인 시도 ID: ${loginInfo.id}`,
      });
    } else {
      toast.error(t(message));
    }
  };

  const resetLoginInfo = () => {
    setLoginInfo((prev) => ({ ...prev, id: '', password: '' }));
  };

  // 사용자등록버튼 클릭 시 해당모달 띄우기
  const onClickSignup = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: !prev.status, type: 'signup', title: t('사용자 등록') }));
  };

  const onClickLogin = () => {
    if (loginInfo?.id?.trim() !== '' && loginInfo?.password?.trim() !== '') {
      loginAPI();
      if (rememberCheck) Cookies.set('prosafeUId', loginInfo.id);
      else Cookies.remove('prosafeUId');
    } else toast.warning(t('아이디와 비밀번호를 입력하세요'));
  };

  const renderModal = () => {
    switch (openModal?.type) {
      case 'passwordCheckYn':
        return <PasswordCheckYnModal openModal={openModal} setOpenModal={setOpenModal} state={loginInfo} setState={setLoginInfo} resetState={resetLoginInfo} />;
      case 'passwordErrorCount':
        return <PasswordErrorCountModal openModal={openModal} setOpenModal={setOpenModal} setState={setLoginInfo} resetState={resetLoginInfo} />;
      case 'signup':
        return <SignupModal openModal={openModal} setOpenModal={setOpenModal} setState={setLoginInfo} resetState={resetLoginInfo} />;
      case 'passwordChangeTerm':
        return <PasswordChangeTermModal openModal={openModal} setOpenModal={setOpenModal} state={loginInfo} setState={setLoginInfo} resetState={resetLoginInfo} />;
      case 'ipCheck':
        return <IpCheckModal openModal={openModal} setOpenModal={setOpenModal} state={loginInfo} resetState={resetLoginInfo} />;
      default:
        return undefined;
    }
  };

  // 아이디 입력창에서 엔터입력시 패스워드 인풋으로 포커스이동
  const moveFocusToPasswordOnEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (passwordRef.current) passwordRef.current.focus();
    }
  };

  // 패스워드 입력창에서 엔터입력시 로그인
  const loginOnEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (passwordRef.current) onClickLogin();
    }
  };

  return (
    <Root>
      <div className='login-background'>
        <Top />
        <Bottom />
      </div>
      <div className='language'>
        <Language />
      </div>
      <div className='login-container'>
        <div className='login-title'>
          <h2>
            {t('현장 통합관리')} <span>{t('솔루션')}</span>
          </h2>
          <span>PROJECT MANAGEMENT SYSTEM</span>
          <h1>PROSAFE</h1>
        </div>
        <div className='login-form'>
          <Input id='id' name='id' label='ID' type='text' state={loginInfo} setState={setLoginInfo} placeholder='' onKeyDown={moveFocusToPasswordOnEnterKeyDown} inputRef={idRef} trim />
          <Input id='password' name='password' label='PASSWORD' type='password' state={loginInfo} setState={setLoginInfo} placeholder='' inputRef={passwordRef} onKeyDown={loginOnEnterKeyDown} trim />
        </div>
        <div className={loginInfo?.passwordErrorCount >= 1 ? 'flex-between' : 'flex-end'}>
          {loginInfo?.passwordErrorCount >= 1 ? (
            <div className='red'>
              {t('비밀번호 불일치 횟수')} : ({loginInfo?.passwordErrorCount}/5)
            </div>
          ) : undefined}
          <Input id='loginRememberCheck' name='loginRememberCheck' label={t('아이디 저장하기')} type='checkbox' state={rememberCheck} setState={setRememberCheck} placeholder='' />
        </div>
        <div className='login-footer'>
          <BtnGreen onClick={onClickLogin}>{t('로그인')}</BtnGreen>
          <BtnLightGreen onClick={onClickSignup}>{t('사용자 등록')}</BtnLightGreen>
        </div>
      </div>
      <Portal openModal={openModal?.status}>{openModal && renderModal()}</Portal>
    </Root>
  );
};

export default React.memo(Login);
