/**
 * 작성자 : 홍선영
 * 날짜 : 2023.06.07
 * 경로 : 설정관리-환경설정-일반관리탭 (현장관리자)
 */

import { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { userState } from '../../../atoms';
import { preJobTypeList, hourList, minList, elderlyAgeList, elderlyAgeListWithDefault, CCTV_MODE, SCREEN_COLOR_MODE, BOARD_LANG, INIT_USE_YN_Y, COMCD_USE_YN } from '../../../_constants';
import { BtnBlue } from '../../../components/Button';
import Input from '../../../components/Input';
import SelectBox from '../../../components/SelectBox';
import { trimObject } from '../../../utils/trimObject';
import { logPost } from '../../../services/log';
import { applyBorderStyle } from '../../../utils/applyBorderStyle';
import { useSetAuth } from '../../../utils/useSetAuth';
import DatePickerComponent from '../../../components/DatePicker';
import { formatDateToStr, formatDateYMD, isValidYYYYMMDD } from '../../../utils/formatDate';
import { useTranslation } from 'react-i18next';
import { ContentsContainerRoot } from '../../../assets/styles/ContentsContainerRoot';
import { useQuery } from '@tanstack/react-query';
import { apiGet, apiPost } from '../../../services/_common';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import { PulseLoader } from 'react-spinners';
import { setComCdListState } from '../../../utils/setComCdListState';
import { IComCdList } from 'customTypes';
import IssueGuide from '../../../components/IssueGuide';
import illustrator from '../../../assets/images/illustration/304.svg';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  width: 100%;
  justify-content: flex-end;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;

const CheckBox = styled.div`
  display: flex;
  align-items: center;

  > label {
    font-size: 1.25rem;
    font-weight: initial;
    display: flex;
    align-items: center;
    user-select: none;
  }
`;

const Root = styled(ContentsContainerRoot)`
  align-items: center;
  .inputForm {
    overflow: auto;
    flex-grow: 1;
    position: relative;
    width: calc(100% - 0.5rem);
    margin-left: 0.5rem;
    > div {
      width: 100%;
    }
    .setting-container-group {
      width: 100%;
      max-width: 40rem;
      margin: 0 auto;
      gap: 2rem;
      display: flex;
      flex-direction: column;
      padding-bottom: 4rem;
    }
    /* @media (min-width: 1280px) {
      padding: 3rem 25%;
      width: 100%;
    } */
    .title {
      border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      height: 3rem;
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
    }
    .setting-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      column-gap: 2rem;
      row-gap: 1rem;
      padding: 1rem 0.5rem;
      @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    .setting-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 2.5rem;
      input {
        font-size: 0.875rem;
      }
      &.accident {
        @media (max-width: 70000px) {
          flex-direction: column;
          gap: 0.5rem;
        }
      }
      @media (min-width: 768px) {
      }
      label {
        font-weight: 500;
        font-size: 0.875rem;
        width: 6rem;
        flex-shrink: 0;
      }
      > div {
        flex-grow: 1;
        ul li {
          max-width: 100%;
          width: 100%;
          span {
            width: 100%;
          }
        }
      }
      input[type='text'] {
        height: 2.5rem;
        border-radius: 0.25rem;
        font-size: 0.875rem;
      }
      .setting-row-content {
        display: flex;
        align-items: center;
        > span {
          padding: 0 0.5rem;
          font-size: 0.875rem;
          color: ${({ theme }: { theme: any }) => theme.text_secondary};
        }
      }
      input[type='number'] {
        height: 2.5rem;
        border-radius: 0.25rem;
        font-size: 0.875rem;
        text-align: center;
        width: 4rem;
      }
      .setting-row-element {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 0.875rem;
        span {
          color: ${({ theme }: { theme: any }) => theme.text_secondary};
        }
      }
    }
    .setting-row-content.time {
      gap: 1rem;
    }
    .setting-row-content-group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      @media (min-width: 640px) {
        display: flex;
        align-items: center;
      }
    }
  }
  .setting-row.no-grid {
    margin: 1rem 0.5rem;
    height: fit-content;
    &.accident {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 1rem 2rem;
      @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  .buttonsWrapper {
    display: flex;
    justify-content: flex-start;
    @media (min-width: 768px) {
      justify-content: flex-end;
    }
  }
`;

interface IGeneralSet {
  hCd: string;
  sCd: string;
  prejobtypeYn: string;
  eduPlace: string;
  eduCharge: string;
  fromLowpress: string;
  toLowpress: string;
  fromHighpress: string;
  toHighpress: string;
  elderlyAgeSet: string;
  elderlySearch1: string;
  elderlySearch2: string;
  elderlySearch3: string;
  elderlySearch4: string;
  elderlySearch5: string;
  writer: string;
  eduStartHour: string;
  eduStartMin: string;
  eduEndHour: string;
  eduEndMin: string;
  eduStartTime: string;
  eduEndTime: string;
  accidentSdate: string;
  accidentTday: string;
  accidentEdate: string;
  cctvProtocol: string;
  bScreenMode: string;
  bLangMode: string;
  tSensorAdmin: string;
  tSensorWorker: string;
  tBccYn: string;
}
const INIT_ELDERLY_AGE = { type: 'elderlyAge', elderlyAge: '', cdName: '' };
const INIT_HOUR = { type: 'hour', hour: '', cdName: '' };
const INIT_MIN = { type: 'min', min: '', cdName: '' };
const INIT_JOBTYPE_YN = { type: 'jobtype', jobtype: '', cdName: '' };
const INIT_GENERAL_SETTING = {
  hCd: '',
  sCd: '',
  prejobtypeYn: '',
  eduPlace: '',
  eduCharge: '',
  fromLowpress: '',
  toLowpress: '',
  fromHighpress: '',
  toHighpress: '',
  elderlyAgeSet: '',
  elderlySearch1: '',
  elderlySearch2: '',
  elderlySearch3: '',
  elderlySearch4: '',
  elderlySearch5: '',
  writer: '',
  eduStartHour: '',
  eduStartMin: '',
  eduEndHour: '',
  eduEndMin: '',
  eduStartTime: '',
  eduEndTime: '',
  accidentSdate: '',
  accidentTday: '',
  accidentEdate: '',
  cctvProtocol: '',
  bScreenMode: '',
  bLangMode: '',
  tSensorAdmin: '',
  tSensorWorker: '',
  tBccYn: '',
};

const UserLoginSetting = ({ mqtt }: any) => {
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const [userInput, setUserInput] = useState<IGeneralSet>(INIT_GENERAL_SETTING);
  const [userInfo, setUserInfo] = useRecoilState(userState);
  const [prejobtypeYn, setPrejobtypeYn] = useState(INIT_JOBTYPE_YN);
  const [startHour, setStartHour] = useState(INIT_HOUR);
  const [startMin, setStartMin] = useState(INIT_MIN);
  const [endHour, setEndHour] = useState(INIT_HOUR);
  const [endMin, setEndMin] = useState(INIT_MIN);
  const [elderlyAge, setElderlyAge] = useState({ type: 'elderlyAge', elderlyAge: '', cdName: '' });
  const [elderlyAge1, setElderlyAge1] = useState(INIT_ELDERLY_AGE);
  const [elderlyAge2, setElderlyAge2] = useState(INIT_ELDERLY_AGE);
  const [elderlyAge3, setElderlyAge3] = useState(INIT_ELDERLY_AGE);
  const [elderlyAge4, setElderlyAge4] = useState(INIT_ELDERLY_AGE);
  const [elderlyAge5, setElderlyAge5] = useState(INIT_ELDERLY_AGE);
  const [startDate, setStartDate] = useState('');
  const [targetDate, setTargetDate] = useState({ accidentTday: '' });
  const [cctvProtocol, setCctvProtocol] = useState({ type: 'cctvProtocol', cctvProtocol: '', cdName: '' });
  const [bScreenMode, setBScreenMode] = useState({ type: 'bScreenMode', bScreenMode: '', cdName: '' });
  const [bLangMode, setBLangMode] = useState({ type: 'bLangMode', bLangMode: '1', cdName: 'ko-KR' });
  const [bccYn, setBccYn] = useState(INIT_USE_YN_Y);
  const [tabletYn, setTabletYn] = useState(INIT_USE_YN_Y);
  const [tInoutYn, setTIntoutYn] = useState(INIT_USE_YN_Y);
  const [tTsYn, setTTsYn] = useState(INIT_USE_YN_Y);
  const [tSafeeduYn, setTSafeeduYn] = useState(INIT_USE_YN_Y);
  const [tVisitYn, setTVisitYn] = useState(INIT_USE_YN_Y);
  const [adminAlert, setAdminAlert] = useState(false);
  const [workerAlert, setWorkerAlert] = useState(false);
  const [useYnComCdListWithoutAll, setUseYnComCdListWithoutAll] = useState<IComCdList[]>([]); // 사용유무 공통코드 (전체값 없는)

  const eduPlaceRef = useRef<HTMLInputElement>(null);
  const eduChargeRef = useRef<HTMLInputElement>(null);
  const fromLowpressRef = useRef<HTMLInputElement>(null);
  const toLowpressRef = useRef<HTMLInputElement>(null);
  const fromHighpressRef = useRef<HTMLInputElement>(null);
  const toHighpressRef = useRef<HTMLInputElement>(null);
  const targetDateRef = useRef<HTMLInputElement>(null);
  const startDateRef = useRef<HTMLInputElement>(null);
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값

  const {
    data: userSettingData,
    isLoading,
    isFetching,
    isRefetching,
    isError,
  } = useQuery(['generalSettingGet', userInfo.hCd, userInfo.sCd], () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
    cacheTime: 0,
  });

  const fetchData = async () => {
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
      const res = await apiGet({ path: '/setting/general', req });
      const { generalSet } = res.data.data;
      setUserInput(generalSet);
      setStartDate(generalSet.accidentSdate);
      setTargetDate({ accidentTday: generalSet.accidentTday });
      setPrejobtypeYn({ type: 'jobtype', jobtype: generalSet.prejobtypeYn, cdName: '' });
      setStartHour({ type: 'hour', hour: generalSet.eduStartTime.substring(0, 2), cdName: generalSet.eduStartTime.substring(0, 2) });
      setStartMin({ type: 'min', min: generalSet.eduStartTime.substring(2, 4), cdName: generalSet.eduStartTime.substring(2, 4) });
      setEndHour({ type: 'hour', hour: generalSet.eduEndTime.substring(0, 2), cdName: generalSet.eduEndTime.substring(0, 2) });
      setEndMin({ type: 'min', min: generalSet.eduEndTime.substring(2, 4), cdName: generalSet.eduEndTime.substring(2, 4) });
      setElderlyAge({ type: 'elderlyAge', elderlyAge: generalSet.elderlyAgeSet || '70', cdName: generalSet.elderlyAgeSet || '70' });
      setElderlyAge1({ type: 'elderlyAge', elderlyAge: generalSet.elderlySearch1, cdName: generalSet.elderlySearch1 });
      setElderlyAge2({ type: 'elderlyAge', elderlyAge: generalSet.elderlySearch2, cdName: generalSet.elderlySearch2 });
      setElderlyAge3({ type: 'elderlyAge', elderlyAge: generalSet.elderlySearch3, cdName: generalSet.elderlySearch3 });
      setElderlyAge4({ type: 'elderlyAge', elderlyAge: generalSet.elderlySearch4, cdName: generalSet.elderlySearch4 });
      setElderlyAge5({ type: 'elderlyAge', elderlyAge: generalSet.elderlySearch5, cdName: generalSet.elderlySearch5 });
      setCctvProtocol({ type: 'cctvProtocol', cctvProtocol: generalSet.cctvProtocol || '0', cdName: '' });
      setBScreenMode({ type: 'bScreenMode', bScreenMode: generalSet.bScreenMode || '1', cdName: '' });
      setBLangMode({ type: 'bLangMode', bLangMode: generalSet.bLangMode, cdName: '' });
      setAdminAlert(generalSet.tSensorAdmin === 'Y');
      setWorkerAlert(generalSet.tSensorWorker === 'Y');
      setBccYn({ type: COMCD_USE_YN, [COMCD_USE_YN]: generalSet.tBccYn, cdName: generalSet.tBccYn === 'Y' ? '사용' : '미사용' });
      setTabletYn({ type: COMCD_USE_YN, [COMCD_USE_YN]: generalSet.tabletYn, cdName: generalSet.tabletYn === 'Y' ? '사용' : '미사용' });
      setTIntoutYn({ type: COMCD_USE_YN, [COMCD_USE_YN]: generalSet.tInoutYn, cdName: generalSet.tInoutYn === 'Y' ? '사용' : '미사용' });
      setTTsYn({ type: COMCD_USE_YN, [COMCD_USE_YN]: generalSet.tTsYn, cdName: generalSet.tTsYn === 'Y' ? '사용' : '미사용' });
      setTSafeeduYn({ type: COMCD_USE_YN, [COMCD_USE_YN]: generalSet.tSafeeduYn, cdName: generalSet.tSafeeduYn === 'Y' ? '사용' : '미사용' });
      setTVisitYn({ type: COMCD_USE_YN, [COMCD_USE_YN]: generalSet.tVisitYn, cdName: generalSet.tVisitYn === 'Y' ? '사용' : '미사용' });
      return generalSet;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  useEffect(() => {
    setComCdListState(COMCD_USE_YN, setUseYnComCdListWithoutAll, false);
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '환경 설정 > 일반 관리',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    setUserInput((prev) => ({ ...prev, accidentSdate: startDate }));
    if (startDate && targetDate.accidentTday) {
      const accidentEdate = new Date(`${formatDateYMD(startDate)}`);
      accidentEdate.setDate(accidentEdate.getDate() + Number(targetDate.accidentTday));
      setUserInput((prev) => ({ ...prev, accidentEdate: formatDateToStr(accidentEdate) }));
    }
  }, [startDate]);

  useEffect(() => {
    setUserInput((prev) => ({ ...prev, accidentTday: targetDate.accidentTday }));
    if (startDate && targetDate.accidentTday) {
      const accidentEdate = new Date(`${formatDateYMD(startDate)}`);
      accidentEdate.setDate(accidentEdate.getDate() + Number(targetDate.accidentTday));
      setUserInput((prev) => ({ ...prev, accidentEdate: formatDateToStr(accidentEdate) }));
    }
  }, [targetDate]);

  const saveGeneralSettingAPI = async () => {
    let sHour;
    let sMin;
    let eHour;
    let eMin;

    if (startHour.hour !== '') sHour = startHour.hour;
    else sHour = userInput.eduStartTime.substring(0, 2);

    if (startMin.min !== '') sMin = startMin.min;
    else sMin = userInput.eduStartTime.substring(2, 4);

    if (endHour.hour !== '') eHour = endHour.hour;
    else eHour = userInput.eduEndTime.substring(0, 2);

    if (endMin.min !== '') eMin = endMin.min;
    else eMin = userInput.eduEndTime.substring(2, 4);

    const reqData = {
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      prejobtypeYn: prejobtypeYn.jobtype,
      eduPlace: userInput.eduPlace,
      eduCharge: userInput.eduCharge,
      eduStartTime: `${sHour}${sMin}`,
      eduEndTime: `${eHour}${eMin}`,
      fromLowpress: userInput.fromLowpress,
      toLowpress: userInput.toLowpress,
      fromHighpress: userInput.fromHighpress,
      toHighpress: userInput.toHighpress,
      elderlyAgeSet: elderlyAge.elderlyAge || '70',
      elderlySearch1: elderlyAge1.elderlyAge,
      elderlySearch2: elderlyAge2.elderlyAge,
      elderlySearch3: elderlyAge3.elderlyAge,
      elderlySearch4: elderlyAge4.elderlyAge,
      elderlySearch5: elderlyAge5.elderlyAge,
      accidentSdate: startDate,
      accidentTday: userInput.accidentTday,
      accidentEdate: userInput.accidentEdate,
      cctvProtocol: cctvProtocol.cctvProtocol,
      bScreenMode: bScreenMode.bScreenMode,
      bLangMode: bLangMode.bLangMode,
      tSensorAdmin: adminAlert ? 'Y' : 'N',
      tSensorWorker: workerAlert ? 'Y' : 'N',
      tBccYn: bccYn[COMCD_USE_YN],
      tabletYn: tabletYn[COMCD_USE_YN],
      tInoutYn: tInoutYn[COMCD_USE_YN],
      tTsYn: tTsYn[COMCD_USE_YN],
      tSafeeduYn: tSafeeduYn[COMCD_USE_YN],
      tVisitYn: tVisitYn[COMCD_USE_YN],
      editor: userInfo.userId,
      writer: userInfo.userId,
    };
    const res = await apiPost({ path: '/setting/general', req: trimObject(reqData) });
    const { statusCode, message, data } = res.data;
    if (statusCode === 200) {
      setUserInfo((prev) => ({ ...prev, ...data.generalSet }));
      setUserInput({ ...data.generalSet, eduStartHour: '', eduStartMin: '', eduEndHour: '', eduEndMin: '' });
      setStartDate(data.generalSet?.accidentSdate);
      toast.success(t(message));
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '환경 설정 > 일반 관리',
        action: '저장',
        etc: ``,
      });
      mqtt.mqtt.publish(`${userInfo.hCd}${userInfo.sCd}/board`, 'test');
    } else {
      // toast.error(t(ERROR));
    }
  };

  const onClickSave = () => {
    setIsSaveClicked(true);

    if (userInput.eduPlace?.trim() === '') {
      toast.warning(t('빈칸을 입력하세요'));
      return eduPlaceRef.current?.focus();
    }
    if (userInput.eduCharge?.trim() === '') {
      toast.warning(t('빈칸을 입력하세요'));
      return eduChargeRef.current?.focus();
    }
    if (userInput.fromLowpress?.trim() === '') {
      toast.warning(t('빈칸을 입력하세요'));
      return fromLowpressRef.current?.focus();
    }
    if (userInput.toLowpress?.trim() === '') {
      toast.warning(t('빈칸을 입력하세요'));
      return toLowpressRef.current?.focus();
    }
    if (Number(userInput.fromLowpress?.trim()) >= Number(userInput.toLowpress?.trim())) {
      toast.warning(t('최저혈압 범위를 확인하세요.'));
      return fromLowpressRef.current?.focus();
    }
    if (userInput.fromHighpress?.trim() === '') {
      toast.warning(t('빈칸을 입력하세요'));
      return fromHighpressRef.current?.focus();
    }
    if (userInput.toHighpress?.trim() === '') {
      toast.warning(t('빈칸을 입력하세요'));
      return toHighpressRef.current?.focus();
    }
    if (Number(userInput.fromHighpress?.trim()) >= Number(userInput.toHighpress?.trim())) {
      toast.warning(t('최고혈압 범위를 확인하세요.'));
      return fromHighpressRef.current?.focus();
    }
    if (userInput.accidentTday?.trim() === '') {
      toast.warning(t('빈칸을 입력하세요'));
      return targetDateRef.current?.focus();
    }
    if (userInput.accidentSdate?.trim() === '') {
      toast.warning(t('빈칸을 입력하세요'));
      return targetDateRef.current?.focus();
    }

    if (!startDate) return toast.warning(t('시작일자를 선택하세요'));
    if (startDate && (startDate.length < 8 || !isValidYYYYMMDD(startDate))) return toast.warning(t('시작일자를 확인하세요'));

    return saveGeneralSettingAPI();
  };

  const handleCheckboxChange = (val: string) => {
    if (val === 'admin') setAdminAlert(!adminAlert);
    if (val === 'worker') setWorkerAlert(!workerAlert);
  };

  if (isError) return <IssueGuide text='요청하신 작업을 완수하지 못했습니다 잠시 후 다시 시도하세요' illustrator={{ visible: true, src: illustrator }} />;
  if (isLoading || isFetching || isRefetching)
    return (
      <LoadingModalBackground>
        <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' style={{ width: 'inherit', height: 'inherit' }} />
      </LoadingModalBackground>
    );
  if (!userSettingData) return <IssueGuide />;

  return (
    <Root loading={isFetching}>
      <div className='inputForm'>
        <div className='setting-container-group'>
          <div className='setting-container'>
            <div className='title'>{t('공종 사용유무')}</div>
            <div className='setting-grid'>
              <div className='setting-row'>
                <label htmlFor='pwdReuseTerm'>{t('선택')}</label>
                <SelectBox
                  options={preJobTypeList}
                  defaultOption={prejobtypeYn.cdName}
                  state={prejobtypeYn}
                  setState={setPrejobtypeYn}
                  stateKey='jobtype'
                  initiateKey={userSettingData.prejobtypeYn}
                  grantChk={userInfo.gCd !== 'MM'}
                />
              </div>
            </div>
          </div>
          <div className='setting-container'>
            <div className='title'>{t('안전교육 자동 설정')}</div>
            <div className='setting-grid'>
              <div className='setting-row'>
                <label htmlFor='pwdLimitCount'>{t('교육 장소')}</label>
                <Input
                  name='eduPlace'
                  id='eduPlace'
                  type='text'
                  state={userInput}
                  setState={setUserInput}
                  disabled={!auth.createAuth && !auth.updateAuth}
                  inputRef={eduPlaceRef}
                  getBorderStyle={isSaveClicked ? applyBorderStyle(userInput.eduPlace, 'red', 'eduPlace') : undefined}
                />
              </div>
              <div className='setting-row'>
                <label htmlFor='pwdLimitCount'>{t('교육 담당자')}</label>
                <Input
                  name='eduCharge'
                  id='eduCharge'
                  type='text'
                  state={userInput}
                  setState={setUserInput}
                  disabled={!auth.createAuth && !auth.updateAuth}
                  inputRef={eduChargeRef}
                  getBorderStyle={isSaveClicked ? applyBorderStyle(userInput.eduCharge, 'red', 'eduCharge') : undefined}
                />
              </div>
              <div className='setting-row'>
                <label htmlFor='pwdLimitCount'>{t('교육 시작시간')}</label>
                <div className='setting-row-content time'>
                  <div className='setting-row-element'>
                    <SelectBox
                      options={hourList}
                      defaultOption={startHour.cdName ? startHour.cdName.substring(0, 2) : '08'}
                      state={startHour}
                      setState={setStartHour}
                      stateKey='hour'
                      initiateKey={userSettingData.eduStartTime.substring(0, 2)}
                      grantChk={!auth.createAuth && !auth.updateAuth}
                      optionHeight='height-sm'
                    />
                    <span>시</span>
                  </div>
                  <div className='setting-row-element'>
                    <SelectBox
                      options={minList}
                      defaultOption={startMin.cdName ? startMin.cdName.substring(2, 4) : '00'}
                      state={startMin}
                      setState={setStartMin}
                      stateKey='min'
                      initiateKey={userSettingData.eduStartTime.substring(2, 4)}
                      grantChk={!auth.createAuth && !auth.updateAuth}
                      optionHeight='height-sm'
                    />
                    <span>분</span>
                  </div>
                </div>
              </div>
              <div className='setting-row'>
                <label htmlFor='pwdLimitCount'>{t('교육 종료시간')}</label>
                <div className='setting-row-content time'>
                  <div className='setting-row-element'>
                    <SelectBox
                      options={hourList}
                      defaultOption={endHour.cdName ? endHour.cdName.substring(0, 2) : '08'}
                      state={endHour}
                      setState={setEndHour}
                      stateKey='hour'
                      initiateKey={userSettingData.eduEndTime.substring(0, 2)}
                      grantChk={!auth.createAuth && !auth.updateAuth}
                      optionHeight='height-sm'
                    />
                    <span>시</span>
                  </div>
                  <div className='setting-row-element'>
                    <SelectBox
                      options={minList}
                      defaultOption={endMin.cdName ? endMin.cdName.substring(2, 4) : '30'}
                      state={endMin}
                      setState={setEndMin}
                      stateKey='min'
                      initiateKey={userSettingData.eduEndTime.substring(2, 4)}
                      grantChk={!auth.createAuth && !auth.updateAuth}
                      optionHeight='height-sm'
                    />
                    <span>분</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='setting-container'>
            <div className='title'>{t('혈압 정보 설정')}</div>
            <div className='setting-grid'>
              <div className='setting-row'>
                <label htmlFor='pwdLimitTerm'>{t('최저혈압')}</label>
                <div className='setting-row-content'>
                  <Input
                    type='number'
                    name='fromLowpress'
                    state={userInput}
                    setState={setUserInput}
                    disabled={!auth.createAuth && !auth.updateAuth}
                    maxLength={3}
                    inputRef={fromLowpressRef}
                    getBorderStyle={isSaveClicked ? applyBorderStyle(userInput.fromLowpress, 'red', 'fromLowpress') : undefined}
                  />
                  <span>-</span>
                  <Input
                    type='number'
                    name='toLowpress'
                    state={userInput}
                    setState={setUserInput}
                    disabled={!auth.createAuth && !auth.updateAuth}
                    maxLength={3}
                    inputRef={toLowpressRef}
                    getBorderStyle={isSaveClicked ? applyBorderStyle(userInput.toLowpress, 'red', 'toLowpress') : undefined}
                  />
                </div>
              </div>
              <div className='setting-row'>
                <label htmlFor='pwdLimitTerm'>{t('최고혈압')}</label>
                <div className='setting-row-content'>
                  <Input
                    type='number'
                    name='fromHighpress'
                    state={userInput}
                    setState={setUserInput}
                    disabled={!auth.createAuth && !auth.updateAuth}
                    maxLength={3}
                    inputRef={fromHighpressRef}
                    getBorderStyle={isSaveClicked ? applyBorderStyle(userInput.fromHighpress, 'red', 'fromHighpress') : undefined}
                  />
                  <span>-</span>
                  <Input
                    type='number'
                    name='toHighpress'
                    state={userInput}
                    setState={setUserInput}
                    disabled={!auth.createAuth && !auth.updateAuth}
                    maxLength={3}
                    inputRef={toHighpressRef}
                    getBorderStyle={isSaveClicked ? applyBorderStyle(userInput.toHighpress, 'red', 'toHighpress') : undefined}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='setting-container'>
            <div className='title'>{t('고령 기준 연령 설정')}</div>
            <div className='setting-grid'>
              <div className='setting-row'>
                <label htmlFor='pwdLimitCount' className='minWidthFit'>
                  {t('연령')}
                </label>
                <div className='setting-row-content'>
                  <SelectBox
                    options={elderlyAgeList}
                    defaultOption={elderlyAge.cdName || '70'}
                    state={elderlyAge}
                    setState={setElderlyAge}
                    stateKey='elderlyAge'
                    initiateKey={userSettingData.elderlyAgeSet}
                    grantChk={!auth.createAuth && !auth.updateAuth}
                    optionHeight='height-sm'
                  />
                  <span>{t('세')}</span>
                </div>
              </div>
            </div>
          </div>

          <div className='setting-container'>
            <div className='title'>{t('고령 검색 조건 연령 설정 (최대 5개)')}</div>
            <div className='setting-row no-grid'>
              <label htmlFor='pwdLimitCount' className='minWidthFit'>
                {t('연령')}
              </label>
              <div className='setting-row-content-group'>
                <div className='setting-row-content'>
                  <SelectBox
                    options={elderlyAgeListWithDefault}
                    defaultOption={elderlyAge1.cdName || t('선택')}
                    state={elderlyAge1}
                    setState={setElderlyAge1}
                    stateKey='elderlyAge'
                    initiateKey={userSettingData.elderlySearch1}
                    grantChk={!auth.createAuth && !auth.updateAuth}
                    optionHeight='height-sm'
                  />
                  <span>{t('세')}</span>
                </div>
                <div className='setting-row-content'>
                  <SelectBox
                    options={elderlyAgeListWithDefault}
                    defaultOption={elderlyAge2.cdName || t('선택')}
                    state={elderlyAge2}
                    setState={setElderlyAge2}
                    stateKey='elderlyAge'
                    initiateKey={userSettingData.elderlySearch2}
                    grantChk={!auth.createAuth && !auth.updateAuth}
                    optionHeight='height-sm'
                  />
                  <span>{t('세')}</span>
                </div>
                <div className='setting-row-content'>
                  <SelectBox
                    options={elderlyAgeListWithDefault}
                    defaultOption={elderlyAge3.cdName || t('선택')}
                    state={elderlyAge3}
                    setState={setElderlyAge3}
                    stateKey='elderlyAge'
                    initiateKey={userSettingData.elderlySearch3}
                    grantChk={!auth.createAuth && !auth.updateAuth}
                    optionHeight='height-sm'
                  />
                  <span>{t('세')}</span>
                </div>
                <div className='setting-row-content'>
                  <SelectBox
                    options={elderlyAgeListWithDefault}
                    defaultOption={elderlyAge4.cdName || t('선택')}
                    state={elderlyAge4}
                    setState={setElderlyAge4}
                    stateKey='elderlyAge'
                    initiateKey={userSettingData.elderlySearch4}
                    grantChk={!auth.createAuth && !auth.updateAuth}
                    optionHeight='height-sm'
                  />
                  <span>{t('세')}</span>
                </div>
                <div className='setting-row-content'>
                  <SelectBox
                    options={elderlyAgeListWithDefault}
                    defaultOption={elderlyAge5.cdName || t('선택')}
                    state={elderlyAge5}
                    setState={setElderlyAge5}
                    stateKey='elderlyAge'
                    initiateKey={userSettingData.elderlySearch5}
                    grantChk={!auth.createAuth && !auth.updateAuth}
                    optionHeight='height-sm'
                  />
                  <span>{t('세')}</span>
                </div>
              </div>
            </div>
          </div>
          <div className='setting-container'>
            <div className='title'>{t('무사고 기간 설정')}</div>
            <div className='setting-row no-grid accident'>
              <div className='setting-row-content'>
                <label htmlFor='pwdLimitCount' className='minWidthFit'>
                  {t('시작일자')}
                </label>
                <div className='setting-row-content accident'>
                  <div className='flex-basic'>
                    <div className='datePickerWrapper'>
                      {!auth.createAuth && !auth.updateAuth ? (
                        <input type='text' value={userSettingData.accidentSdate} disabled />
                      ) : (
                        <DatePickerComponent startDate={userSettingData.accidentSdate} setDate={setStartDate} popperPlacement='bottom' ref={startDateRef} isSaveClicked={isSaveClicked} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='setting-row-content'>
                <label htmlFor='pwdLimitCount' className='minWidthFit'>
                  {t('목표일')}
                </label>
                <div className='setting-row-content'>
                  <div className='flex-basic'>
                    <Input
                      type='number'
                      name='accidentTday'
                      state={targetDate}
                      setState={setTargetDate}
                      disabled={!auth.createAuth && !auth.updateAuth}
                      maxLength={4}
                      inputRef={targetDateRef}
                      getBorderStyle={isSaveClicked ? applyBorderStyle(userInput.accidentTday, 'red', 'accidentTday') : undefined}
                    />
                  </div>
                  <span>{t('일')}</span>
                </div>
              </div>
              <div className='setting-row-content'>
                <label htmlFor='pwdLimitCount' className='minWidthFit'>
                  {t('종료일자')}
                </label>
                <div className='setting-row-content'>
                  <div className='flex-basic'>
                    <div className='datePickerWrapper'>
                      <input type='text' value={formatDateYMD(userInput.accidentEdate) || ''} disabled />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='setting-container'>
            <div className='title'>{t('CCTV 연결 방식 설정')}</div>
            <div className='setting-grid'>
              <div className='setting-row'>
                <label htmlFor='pwdReuseTerm'>{t('연결 방식')}</label>
                <SelectBox
                  options={CCTV_MODE}
                  defaultOption={cctvProtocol.cdName || t('미선택')}
                  state={cctvProtocol}
                  setState={setCctvProtocol}
                  stateKey='cctvProtocol'
                  initiateKey={userSettingData.cctvProtocol}
                  grantChk={!auth.createAuth && !auth.updateAuth}
                />
              </div>
            </div>
          </div>
          <div className='setting-container'>
            <div className='title'>{t('상황판 설정')}</div>
            <div className='setting-grid'>
              <div className='setting-row'>
                <label htmlFor='pwdReuseTerm'>{t('화면 모드')}</label>
                <SelectBox
                  options={SCREEN_COLOR_MODE}
                  defaultOption={bScreenMode.cdName || 'LIGHT'}
                  state={bScreenMode}
                  setState={setBScreenMode}
                  stateKey='bScreenMode'
                  initiateKey={userSettingData.bScreenMode}
                  grantChk={!auth.createAuth && !auth.updateAuth}
                />
              </div>
              <div className='setting-row'>
                <label htmlFor='pwdReuseTerm'>{t('언어 설정')}</label>
                <SelectBox
                  options={BOARD_LANG}
                  defaultOption={bLangMode.cdName}
                  state={bLangMode}
                  setState={setBLangMode}
                  stateKey='bLangMode'
                  initiateKey={userSettingData.bLangMode}
                  grantChk={!auth.createAuth && !auth.updateAuth}
                />
              </div>
            </div>
          </div>
          <div className='setting-container'>
            <div className='title'>{t('알림톡 설정')}</div>
            <div className='setting-grid'>
              <div className='setting-row'>
                <label htmlFor='userType'>{t('센서 긴급 상황')}</label>
                <CheckBox>
                  <label htmlFor='admin'>
                    <input id='admin' type='checkbox' checked={adminAlert} onChange={() => handleCheckboxChange('admin')} disabled={userInfo.gCd !== 'MM'} />
                    {t('관리자')}
                  </label>
                </CheckBox>
                <CheckBox>
                  <label htmlFor='worker'>
                    <input id='worker' type='checkbox' checked={workerAlert} onChange={() => handleCheckboxChange('worker')} disabled={userInfo.gCd !== 'MM'} />
                    {t('근로자')}
                  </label>
                </CheckBox>
              </div>
              <div className='setting-row'>
                <label htmlFor='beacon'>{t('비콘 호출')}</label>
                <SelectBox
                  options={useYnComCdListWithoutAll}
                  defaultOption={bccYn.cdName}
                  state={bccYn}
                  setState={setBccYn}
                  stateKey={COMCD_USE_YN}
                  initiateKey={userSettingData.tBccYn}
                  grantChk={!auth.createAuth && !auth.updateAuth}
                />
              </div>
            </div>
            {userInfo.gCd === 'MM' && (
              <>
                <div className='setting-grid'>
                  <div className='setting-row'>
                    <label htmlFor='beacon'>{t('출역내역')}</label>
                    <SelectBox
                      options={useYnComCdListWithoutAll}
                      defaultOption={tInoutYn.cdName}
                      state={tInoutYn}
                      setState={setTIntoutYn}
                      stateKey={COMCD_USE_YN}
                      initiateKey={userSettingData.tInoutYn}
                      grantChk={!auth.createAuth && !auth.updateAuth}
                    />
                  </div>
                  <div className='setting-row'>
                    <label htmlFor='beacon'>{t('유해물질 반입')}</label>
                    <SelectBox
                      options={useYnComCdListWithoutAll}
                      defaultOption={tTsYn.cdName}
                      state={tTsYn}
                      setState={setTTsYn}
                      stateKey={COMCD_USE_YN}
                      initiateKey={userSettingData.tTsYn}
                      grantChk={!auth.createAuth && !auth.updateAuth}
                    />
                  </div>
                </div>
                <div className='setting-grid'>
                  <div className='setting-row'>
                    <label htmlFor='beacon'>{t('안전교육')}</label>
                    <SelectBox
                      options={useYnComCdListWithoutAll}
                      defaultOption={tSafeeduYn.cdName}
                      state={tSafeeduYn}
                      setState={setTSafeeduYn}
                      stateKey={COMCD_USE_YN}
                      initiateKey={userSettingData.tSafeeduYn}
                      grantChk={!auth.createAuth && !auth.updateAuth}
                    />
                  </div>
                  <div className='setting-row'>
                    <label htmlFor='beacon'>{t('방문자')}</label>
                    <SelectBox
                      options={useYnComCdListWithoutAll}
                      defaultOption={tVisitYn.cdName}
                      state={tVisitYn}
                      setState={setTVisitYn}
                      stateKey={COMCD_USE_YN}
                      initiateKey={userSettingData.tVisitYn}
                      grantChk={!auth.createAuth && !auth.updateAuth}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          {userInfo.gCd === 'MM' && (
            <div className='setting-container'>
              <div className='title'>{t('태블릿 설정')}</div>
              <div className='setting-grid'>
                <div className='setting-row'>
                  <label htmlFor='pwdReuseTerm'>{t('근로자 / 안전교육 등록')}</label>
                  <SelectBox
                    options={useYnComCdListWithoutAll}
                    defaultOption={tabletYn.cdName}
                    state={tabletYn}
                    setState={setTabletYn}
                    stateKey={COMCD_USE_YN}
                    initiateKey={userSettingData.tabletYn}
                    grantChk={!auth.createAuth && !auth.updateAuth}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ButtonsWrapper>{(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={onClickSave}>{t('저장')}</BtnBlue>}</ButtonsWrapper>
    </Root>
  );
};

export default UserLoginSetting;
