import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import i18n from '../../translation/i18n';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PulseLoader } from 'react-spinners';
import { apiGet } from '../../services/_common';

interface ISafetyViolation {
  blackCount: string;
  pointCount: string;
}

// 안전 위반 현황
export const SafetyViolationInfo = ({ name, userInfo }: any) => {
  const { t } = useTranslation();
  const yesterday = dayjs().subtract(1, 'day');
  let format = yesterday.format(`MM월 DD일 dddd`);
  if (i18n.language === 'en-US') {
    format = yesterday.locale('en').format(`MMMM D dddd`);
  } else {
    format = yesterday.locale('ko').format(`MM월 DD일 dddd`);
  }
  const [safetyViolatWorkerCount, setSafetyViolatWorkerCount] = useState<ISafetyViolation | null>(null);
  const safetyViolatWorkerQuery = useQuery(['safetyViolationGet', userInfo], () => apiGet({ path: '/safe/pointblackcontents', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }), {
    enabled: userInfo.sCd !== '00000',
  });

  useEffect(() => {
    if (safetyViolatWorkerQuery.isSuccess && safetyViolatWorkerQuery.data && safetyViolatWorkerQuery.data.data.statusCode === 200) {
      const { blackCount, pointCount } = safetyViolatWorkerQuery.data.data.data[0];
      setSafetyViolatWorkerCount({ blackCount, pointCount });
    }
  }, [safetyViolatWorkerQuery.isSuccess, safetyViolatWorkerQuery.isRefetching]);

  const onClickRefresh = () => {
    safetyViolatWorkerQuery.refetch();
  };

  if (!safetyViolatWorkerQuery.isSuccess || safetyViolatWorkerQuery.data.status !== 200 || safetyViolatWorkerQuery.isRefetching) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <div className='safetyViolationInfo'>
      <div className='widget-header'>
        <div className='widget-title flex-between'>
          <span>{t(name)}</span>
          <div className='refresh-btn' onClick={onClickRefresh} role='presentation'>
            <span className='material-symbols-rounded'>refresh</span>
          </div>
        </div>
        <div className='widget-subTitle'>
          {format} ({t('전일')})
        </div>
      </div>
      <div className='widget-body safety'>
        <div className='box flex-basic'>
          <div className='orange'>
            <span className='material-symbols-rounded'>warning</span>
            {/* <IoWarning size={30} /> */}
          </div>
          <div>
            <div>
              <span>{safetyViolatWorkerCount?.pointCount} </span>
              {t('명')}
            </div>
            {t('안전 지적')}
          </div>
        </div>
        <div className='box flex-basic'>
          <div className='red'>
            <span className='material-symbols-rounded'>block</span>
            {/* <IoBan size={30} /> */}
          </div>
          <div>
            <div>
              <span>{safetyViolatWorkerCount?.blackCount} </span>
              {t('명')}
            </div>
            {t('출입 금지')}
          </div>
        </div>
      </div>
    </div>
  );
};
