import styled from 'styled-components';

export const TareaModalStyle = styled.div`
  position: relative;
  transition: all ease 0.8s;
  width: 100%;
  height: inherit;
  height: 100vh;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  .centered-content {
    width: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  > .modal {
    z-index: 6000;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 2rem);
    height: calc(100dvh - 2rem);
    width: 68rem;
    background-color: ${({ theme }: { theme: any }) => theme.board};
    border-radius: 0.5rem;
    overflow: hidden;
    @media screen and (max-width: 1023px) {
      /* width: 80%; */
      width: calc(100vw - 2rem);
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      height: 100vh;
      height: inherit;
      overflow-y: auto;
      border-radius: 0;
    }
    > .inputForm-head {
      position: relative;
      font-weight: 500;
      flex-shrink: 0;
      padding: 0.5rem 0.5rem;
      border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      .modalHeaderCenter {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: ${({ theme }: { theme: any }) => theme.text_primary};
      }
      > svg {
        width: 24px;
        height: 24px;
      }
      .closeBtn {
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5rem;
        cursor: pointer;
        margin: 0 0.5rem;
        color: ${({ theme }: { theme: any }) => theme.text_primary};
      }
      .closeBtn:hover {
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
      }
      .material-symbols-rounded {
        color: ${({ theme }: { theme: any }) => theme.text_primary};
        font-variation-settings: 'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 20;
      }
      .arrow-icon span {
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .icon-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        gap: 0.25rem;
        cursor: pointer;
        border-radius: 5rem;
        padding-right: 1rem;
        padding-left: 0.5rem;
        font-size: 0.875rem;
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        color: ${({ theme }: { theme: any }) => theme.text_primary};
      }
      .icon-btn:hover {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
      }
    }
    > .inputForm-body {
      flex-shrink: 1;
      background-color: ${({ theme }: { theme: any }) => theme.board};
      color: #121212;
      overflow-y: initial;
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: auto;

      .modal-contents-container {
        background-color: ${({ theme }: { theme: any }) => theme.board};
        flex-grow: 1;
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        .subHead {
          padding: 2rem 0;
        }
      }
      .modal-contents {
        background-color: ${({ theme }: { theme: any }) => theme.board};
        padding: 1rem;
        flex-grow: 1;
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &.tareaPoint {
          flex-direction: row;
        }

        .subHead {
          padding: 2rem 0;
        }

        @media screen and (max-width: 1024px) {
          &.tareaPoint {
            flex-direction: column;
          }
        }
      }

      .content {
        padding-top: 1rem;
        background-color: transparent;
      }

      .flexStart {
        justify-content: flex-start;
        > label {
          min-width: 8rem;
        }
      }

      .paddingBottom {
        padding-bottom: 2rem;
      }

      label {
        display: flex;
        align-items: center;
      }
    }
    > .inputForm-body.attendList {
      overflow: hidden;
      > div:first-child {
        overflow: auto;
        flex-grow: 1;
        width: 100%;
        > .modalRibbon {
          width: 100%;
          padding: 0.5rem 0.75rem;
          position: sticky;
          left: 0;
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
          gap: 2rem;
          touch-action: none;
          @media (max-width: 767px) {
            flex-direction: column;
            gap: 0.5rem;
            align-items: flex-start;
          }
          > div:first-child {
            padding: 0.25rem 0.75rem;
            border-radius: 0.25rem;
            flex-grow: 1;
            height: 2.5rem;
            display: flex;
            align-items: center;
            background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
            /* outline: 1px solid ${({ theme }: { theme: any }) => theme.outline}; */
            > span {
              font-size: 0.875rem;
              color: ${({ theme }: { theme: any }) => theme.text_secondary};
              > span {
                color: ${({ theme }: { theme: any }) => theme.text_primary};
                font-weight: 500;
                margin-left: 0.5rem;
              }
            }
            > span:nth-child(2)::before {
              content: '•';
              margin: 0 0.25rem;
            }
          }
          .inputCalendar {
            display: flex;
            gap: 0.25rem;
            align-items: center;
            flex-wrap: wrap;
            color: ${({ theme }: { theme: any }) => theme.text_secondary};
            > span:first-child {
              font-size: 0.875rem;
              margin: 0 0.5rem;
            }
            input {
              height: 2.5rem;
              width: 6rem;
              padding: 0;
              text-align: center;
            }
            button {
              height: 2.5rem;
              /* width: 3rem; */
              font-size: 0.875rem;
            }
            /* button.searchBtn {
              font-size: 0.875rem;
              font-weight: 600;
              width: fit-content;
              padding: 0.75rem;
              padding-right: 0.25rem;
              gap: 0.5rem;
              box-shadow: none;
              border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
              .shortcut-f9 {
                background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
                color: ${({ theme }: { theme: any }) => theme.text_secondary};
                border-radius: 0.25rem;
                font-size: 0.75rem;
                letter-spacing: 0;
                width: 1.75rem;
                height: 1.75rem;
                letter-spacing: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid ${({ theme }: { theme: any }) => theme.outline_em};
                font-weight: 600;
              }
            } */
          }
        }
        .thead {
          position: sticky;
          top: 0;
          /* width: fit-content; */
        }
        .tbody {
          /* width: fit-content; */
        }
        .thead.eduList,
        .tbody.eduList {
          width: 100%;
        }
      }
      .tr {
        button {
          width: 3rem;
          padding: 0 0.5rem;

          font-size: 0.875rem;
        }
        /* span {
          font-size: 0.875rem;
        } */
        > div {
          flex-shrink: 0;
          > div {
            width: 100%;
            padding: 0 0.125rem;
            li {
              width: 100%;
              max-width: 100%;
              padding-left: 0.5rem;
            }
            span {
              width: 100%;
            }
          }
        }
      }
    }
    .modal-footer {
      border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      padding: 0.5rem;
      > div {
        gap: 0.25rem;
        > button {
          height: 2.5rem;
          font-size: 0.875rem;
          width: 6rem;
          padding: 0;
          @media screen and (max-width: 767px) {
            width: fit-content;
            padding: 0 1.5rem;
          }
        }
      }
      > .arrows {
        button {
          gap: 0.25rem;
          width: fit-content;
          gap: 0.5rem;
          background-color: #e5f1ff;
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          &:hover {
            background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
          }
          &:nth-child(1) {
            padding-left: 0.25rem;
            padding-right: 0.5rem;
            @media screen and (max-width: 767px) {
              padding-right: 1rem;
            }
            > div:nth-child(3) {
              padding-left: 0.5rem;
              border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
              @media screen and (max-width: 767px) {
                display: none;
              }
              p {
                text-align: left;
              }
            }
          }
          &:nth-child(2) {
            padding-left: 0.5rem;
            padding-right: 0.25rem;
            @media screen and (max-width: 767px) {
              padding-left: 1rem;
            }
            > div:nth-child(1) {
              padding-right: 0.5rem;
              border-right: 1px solid ${({ theme }: { theme: any }) => theme.outline};
              @media screen and (max-width: 767px) {
                display: none;
              }
              p {
                text-align: right;
              }
            }
          }
          > p,
          > span {
            color: ${({ theme }: { theme: any }) => theme.selected_primary};
          }
          > div {
            p {
              color: ${({ theme }: { theme: any }) => theme.text_tertiary};
              font-size: 0.625rem;
              line-height: 1.125;
              letter-spacing: 0.025rem;
              font-weight: 600;
            }
          }
        }
      }
    }
    .control {
      .selectWrapper {
        width: 100%;
        > div {
          width: inherit;
        }
      }
      .reactSelectWrapper {
        width: 100%;
        > div {
          width: inherit;
        }
      }

      > div > div {
        display: flex;

        > label {
          margin: 0;
          padding: 0 0.75rem;
          /* min-width: 7rem; */
          /* font-weight: bold; */
        }
      }
    }
  }
  .modalHeaderTitle {
    font-weight: 500;
    height: 2.5rem;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    gap: 1rem;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .innerTabChip {
    overflow: overlay;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    border-radius: 5rem;
    &::-webkit-scrollbar {
      height: 0rem;
    }
    .tab {
      flex-shrink: 0;
      padding: 0 1.5rem;
      height: 2.5rem;
      display: flex;
      border-radius: 5rem;
      align-items: center;
      cursor: pointer;
      user-select: none;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
    }
    .tab:hover {
      /* background-color: rgba(0, 0, 0, 0.05); */
    }
    .activeTab {
      font-weight: ${({ theme }: { theme: any }) => theme.font_bold};
      color: ${({ theme }: { theme: any }) => theme.selected_primary};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
      pointer-events: none;
    }
    /* .activeTab: */
  }
  .innerTab {
    /* padding-bottom: 2rem; */
    .tab {
      padding: 0 1rem;
      cursor: pointer;
      height: 3rem;
    }

    .activeTab {
      font-weight: bold;
      border-bottom: 2px solid rgb(63, 66, 84);
    }
  }

  > .modal-scrollStyle {
    padding: 30px 12px 30px 30px;

    > .inputForm-body {
      margin-right: 12px;

      > .modal-contents {
        padding-right: 12px;
      }
    }
  }
  .flexColumn {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0 1rem 0;
    > small {
      line-height: 1.5;
    }
  }

  .dpNone {
    display: none;
  }

  .subModal {
    max-width: none;

    .inputForm-body {
      > :first-child {
        width: 100%;
        > :first-child {
          width: 100%;

          > div {
            flex: 0.5;
            > div {
              width: 100% !important;
            }
          }

          > :last-child > div {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }

  .w100 {
    width: 100%;
    > div {
      padding-left: 1rem;
    }
  }
  .w30 {
    width: 30%;
    > div {
      width: 100%;
      padding-left: 1rem;
    }
  }
  .w25 {
    width: 25%;
    > div {
      width: 100%;
      padding-left: 0.5rem;
    }
  }
  .w10 {
    width: 10%;
  }

  .emptyData {
    -webkit-box-flex: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    user-select: none;
    padding-bottom: 4rem;
    gap: 1rem;

    img {
      width: 14rem;
      /* user-drag: none; */
      -webkit-user-drag: none;
    }
    span {
      color: ${({ theme }: { theme: any }) => theme.filled_violet};
    }
  }
`;

export const TareaContainer = styled.div`
  gap: 0.5rem;
  height: calc(100% - 3.55rem);
  padding: 1rem;
  display: flex;
  flex-direction: row;
  color: ${({ theme }: { theme: any }) => theme.text_tertiary};

  .startBg {
    flex-grow: 1;
    height: fit-content;
    padding: 1rem;
    border-radius: 10px;
    background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
  }

  .endBg {
    flex-grow: 1;
    height: fit-content;
    padding: 1rem;
    border-radius: 10px;
    background-color: ${({ theme }: { theme: any }) => theme.tonal_amber};
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
