/**
 * 작성자 : 한영광
 * 날짜 : 2023.04.18
 * 기능 : 마스터일반관리-메뉴관리 페이지
 *
 * 작성자 : 홍선영
 * 날짜 : 2023.05.17
 * 수정사항 : 탭메뉴 동적출력되도록 수정
 */

import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ITabObject } from 'customTypes';
import { IUser, pageInfoState, userState } from '../../atoms';
import Head from './common1/head';
import Site from './common1/site';
import Tab from '../../components/Tab';
import { logPost } from '../../services/log';

const Mcommon1 = () => {
  const [userInfo, setUserInfo] = useRecoilState<IUser>(userState);
  const { userMenuList } = userInfo;
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);

  const onClickTab = (mrCd: string) => {
    setCurrentTabMrCd(mrCd);
  };

  const renderPage = () => {
    const activeTab = tabInfo.tabList?.find((el: ITabObject) => el.mrCd === currentTabMrCd);
    if (activeTab !== undefined) {
      const { mrCd } = activeTab;
      switch (mrCd) {
        case '001':
          return <Head />;
        case '002':
          return <Site />;
      }
    }
    return undefined;
  };

  return (
    <div className='contents'>
      <Tab tabList={tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      <div className='content-container oneColumn'>{renderPage()}</div>
    </div>
  );
};

export default Mcommon1;
