import ReactDOM from 'react-dom/client';
import { IoPersonAdd } from 'react-icons/io5';

class ImageBtnRenderer {
  element;

  constructor(props: any) {
    this.element = document.createElement('div');
    const root = ReactDOM.createRoot(this.element);
    const { rowKey } = props;
    const { onImgButtonClicked } = props.columnInfo.renderer.options;

    if (props.value) {
      root.render(<img src={props.value} width='40px' height='40px' style={{ borderRadius: '5px' }} role='presentation' onClick={() => onImgButtonClicked(rowKey)} />);
    } else {
      root.render(<IoPersonAdd size={25} onClick={() => onImgButtonClicked(rowKey)} />);
    }
  }

  getElement() {
    return this.element;
  }
}
export default ImageBtnRenderer;
