/**
 * 작성자 : 홍선영
 * 날짜 : 2023.12.22
 * 기능 : 터널 관리 > 굴진/일반 정보 > 굴진/일반 정보 팝업 > 유해 가스 정보 (현장용)
 */

import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { userState } from '../atoms';
import { BtnBlue } from './Button';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { apiGet } from '../services/_common';
import { arraySortByAscdOrder } from '../utils/arraySortByAscdOrder';
import { PulseLoader } from 'react-spinners';
import useOnKeydownF9 from '../utils/useOnKeydownF9';
import TareaMGasInfoContent from './TareaMGasInfoContent';

interface ITareaMGasInfo {
  data: TunnelType;
  onClickPrev: () => void;
  onClickNext: () => void;
  showPrevNextBtn: boolean;
}

// 유해 가스 정보 탭
export const TareaMGasInfo = ({ data, onClickPrev, onClickNext, showPrevNextBtn }: ITareaMGasInfo) => {
  const userInfo = useRecoilValue(userState);
  const [sensorList, setSensorList] = useState<SensorList[]>([]);
  const [sensorData, setSensorData] = useState<SensorData[]>([]);

  const { t } = useTranslation();
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9();

  // API 호출을 위한 공통 함수
  const useSensorQuery = (queryKey: string[], url: string, params: Record<string, string | undefined>, dependencies: any[] = []) => {
    return useQuery(queryKey, () => apiGet({ path: url, req: params }), {
      enabled: dependencies.every(Boolean),
      // 모든 의존성이 true일 때만 쿼리 활성화
    });
  };

  const queryParams = {
    sensorList: {
      queryKey: ['sensorListGet', userInfo.hCd, userInfo.sCd],
      url: '/sensor/ulist',
      params: { hCd: userInfo.hCd, sCd: userInfo.sCd },
      dependencies: [userInfo.hCd, userInfo.sCd],
    },
    sensorData: {
      queryKey: ['sensorDataGet', userInfo.hCd, userInfo.sCd, data.tatCd],
      url: '/tarea/t/sensor',
      params: { hCd: userInfo.hCd, sCd: userInfo.sCd, tatCd: data.tatCd },
      dependencies: [userInfo.hCd, userInfo.sCd, data.tatCd],
    },
  };

  const sensorListQuery = useSensorQuery(queryParams.sensorList.queryKey, queryParams.sensorList.url, queryParams.sensorList.params, queryParams.sensorList.dependencies);

  const sensorDataQuery = useSensorQuery(queryParams.sensorData.queryKey, queryParams.sensorData.url, queryParams.sensorData.params, queryParams.sensorData.dependencies);

  useEffect(() => {
    if (!sensorListQuery || !sensorListQuery.isSuccess || !sensorListQuery.data || !sensorListQuery.data.data || !sensorListQuery.data.data.data) {
      console.error('API 호출 결과가 없습니다.');
      return;
    }
    const { sensorUList } = sensorListQuery.data.data.data;
    if (sensorUList) {
      const useYList = sensorUList.filter((el: any) => el.useYn === 'Y');
      setSensorList(arraySortByAscdOrder(useYList, 'etc1'));
    }
  }, [sensorListQuery.data]);

  useEffect(() => {
    if (!sensorDataQuery || !sensorDataQuery.isSuccess || !sensorDataQuery.data || !sensorDataQuery.data.data || !sensorDataQuery.data.data.data) {
      console.error('API 호출 결과가 없습니다.');
      return;
    }
    const { tareaList } = sensorDataQuery.data.data.data;
    if (tareaList) {
      setSensorData(tareaList);
    }
  }, [sensorDataQuery]);

  // f9키 프레스 훅
  useEffect(() => {
    if (isF9Pressed) {
      onClickRefresh();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  const onClickRefresh = () => {
    sensorDataQuery.refetch();
  };

  const isLoading = sensorListQuery.isFetching || sensorDataQuery.isFetching;
  const isError = sensorListQuery.isError || sensorDataQuery.isError;

  if (isLoading) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  if (isError) {
    const errorData = sensorListQuery.isError ? sensorListQuery.data : sensorDataQuery.data;
    console.error('API 호출 중 오류 발생:', errorData);
  }

  return (
    <>
      <TareaMGasInfoContent data={data} sensorList={sensorList} sensorData={sensorData} onClickRefresh={onClickRefresh} />
      {showPrevNextBtn ? (
        <div className='modal-footer flex-between'>
          <div className='flex-basic arrows'>
            <BtnBlue onClick={onClickPrev}>
              <span className='material-symbols-rounded'>arrow_back_ios_new</span>
              <p>{t('이전')}</p>
              <div>
                <p>Page</p>
                <p>Up</p>
              </div>
            </BtnBlue>
            <BtnBlue onClick={onClickNext}>
              <div>
                <p>Page</p>
                <p>Down</p>
              </div>
              <p>{t('다음')}</p>
              <span className='material-symbols-rounded'>arrow_forward_ios</span>
            </BtnBlue>
          </div>
        </div>
      ) : undefined}
    </>
  );
};
