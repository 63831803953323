/**
 * 작성자 : 홍선영
 * 날짜 : 2024.01.22
 * 기능 : 대시보드 타이머 (초)
 */

import { useEffect, useState } from 'react';

const useDashboardTimer = (time: number) => {
  const [timer, setTimer] = useState(time);

  useEffect(() => {
    let intervalId: number;
    if (timer > 0) {
      intervalId = window.setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [timer]);

  return { timer, setTimer };
};
export default useDashboardTimer;
