import styled from 'styled-components';
import { useEffect, useState } from 'react';
import AllimtalkList from './AllimtalkList';
import { useSetAuth } from '../../../utils/useSetAuth';
import AllimtalkEditor from './AllimtalkEditor';
import { useGetTabletTalk } from '../../../hooks/useTablet';
import { PulseLoader } from 'react-spinners';
import { logPost } from '../../../services/log';
import { useRecoilValue } from 'recoil';
import { IUser, userState } from '../../../atoms';

const Root = styled.div`
  flex-shrink: 0;
`;

const TabletTalk = () => {
  const userInfo = useRecoilValue<IUser>(userState);
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const { data: originData, isLoading, isError, refetch } = useGetTabletTalk('1');
  const [data, setData] = useState<TabletTalk[]>(originData || []);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [warning, setWarning] = useState(false);

  useEffect(() => {
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '알림톡 관리 > 신규채용자 알림톡 필독 관리',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    originData?.length > 0 //
      ? setData(originData)
      : setData([]);
  }, [originData]);

  if (isLoading) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  if (isError) return null;

  return (
    <div className='content-container setting-medical'>
      <AllimtalkList
        auth={auth}
        originData={originData} //
        data={data}
        setData={setData}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        refetch={refetch}
        warning={warning}
        tGubun={1}
      />
      <Root>
        <AllimtalkEditor
          auth={auth} //
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          tGubun={1}
          data={data}
          refetch={refetch}
          setWarning={setWarning}
        />
      </Root>
    </div>
  );
};

export default TabletTalk;
