/**
 * 작성자 : 홍선영
 * 날짜 : 2023.09.07
 * 경로 : 안전 관리/게시판 - 안전 중점사항
 */

import { Dispatch, SetStateAction, useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';

import { IUser, userState } from '../../../atoms';
import { IModal } from 'customTypes';
import { COMCD_USE_YN, FLAG_CREATE_OR_UPDATE, FLAG_NONE, INIT_USE_YN_A, SAFE_DEFAULT_COLOR, USE_YN } from '../../../_constants';
import { InputTable } from '../../../assets/styles/InputTable';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { ynFilter } from '../../../utils/ynFilter';
import { trimArray } from '../../../utils/trimArray';
import { useSetAuth } from '../../../utils/useSetAuth';
import { applyBorderStyle } from '../../../utils/applyBorderStyle';
import { arraySortByAscdOrder } from '../../../utils/arraySortByAscdOrder';
import { BtnBlue, BtnGreen, BtnRed } from '../../../components/Button';
import Input from '../../../components/Input';
import Portal from '../../../components/Portal';
import SelectBoxs from '../../../components/SelectBoxs';
import DeleteModal from '../../../components/Modal/DeleteModal';
import ColorPickerModal from '../../../components/Modal/ColorPickerModal';
import SelectBox from '../../../components/SelectBox';
import illustrator from '../../../assets/images/illustration/31.svg';
import { apiDelete, apiGet, apiPost } from '../../../services/_common';
import { useFetchCommonCodeList } from '../../../services/useSetCodeListInSelectBoxForm';
import IssueGuide from '../../../components/IssueGuide';
import { logPost } from '../../../services/log';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  justify-content: flex-end;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
  .inputNumber {
    display: flex;
    position: relative;
    width: 4.5rem;
    > span {
      user-select: none;
      position: absolute;
      right: 0;
      font-size: 0.875rem;
      top: 50%;
      margin-right: 0.5rem;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      transform: translate(0, -50%);
    }
  }

  .colorPicker {
    width: 3rem;
    height: auto;
    border-radius: 5px;
    cursor: pointer;
  }
`;
const Root = styled.div`
  &.hideRoot {
    visibility: hidden;
    position: absolute;
  }
  .icon-btn {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    gap: 0.25rem;
    cursor: pointer;
    border-radius: 5rem;
    padding-right: 1rem;
    padding-left: 0.5rem;
    font-size: 0.875rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    color: ${({ theme }: { theme: any }) => theme.text_primary};
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
    }
    > span {
      color: ${({ theme }: { theme: any }) => theme.text_primary};
    }
    &.arrow-icon span {
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }
  }
  .emptyData {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    padding-bottom: 4rem;
    img {
      width: 16rem;
      /* user-drag: none; */
      -webkit-user-drag: none;
    }
    span {
      color: ${({ theme }: { theme: any }) => theme.filled_violet};
    }
  }
  .inputFormsWrapper {
    padding: 0.5rem;
  }
  .inputFormsWrapper.report {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .closeBtn {
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5rem;
      cursor: pointer;
      margin: 0 0.5rem;
      color: ${({ theme }: { theme: any }) => theme.text_primary};
    }
    .closeBtn:hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
    }
  }
  .formTitle {
    padding: 0 0.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }

  .checkedApply {
    user-select: none;
    width: 100%;
    display: flex;
    flex-shrink: 0;
    /* justify-content: center; */
    justify-content: space-between;
    padding: 0 0.5rem;
    gap: 0.5rem;
    align-items: center;
    height: 3rem;
    font-size: 0.875rem;
    background-color: #f7fbff;
    .material-symbols-rounded {
      /* color: white; */
      color: #e4f1ff;
      background-color: #0071e9;
      font-variation-settings: 'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 28;
      box-shadow: 0 0 0 0.25rem inset #f7fbff;
    }
    span {
      display: flex;
      gap: 0.5rem;
      span > span {
        color: #0071e9;
        /* margin-right: 0.25rem; */
      }
    }
    button {
      /* margin: 0 0.25rem; */
      width: fit-content;
      height: 2rem;
      padding: 0 0.5rem;
      font-size: 0.875rem;
      background-color: #dbe9fa;
      color: #0071e9;
      border: none;
      /* outline: 1px solid #73aae6; */
    }
  }

  .content-container {
    gap: 2rem;
    > div {
      flex: 0.5;
    }

    .table {
      height: 56vh;
      overflow-y: scroll;

      .colorButton {
        border: none;
        height: 2rem;
        width: calc(100% - 1rem);
        border-radius: 5px;
        margin: 0 auto;
      }
    }
  }

  .preview {
    height: calc(14vh - 2rem);
    margin-top: 2rem;
    gap: 2rem;

    button {
      width: 9rem;
    }
  }

  .previewWrapper {
    /* border: 1px solid lightgray; */
    padding: 1rem;
    width: 100%;
    height: 8rem;
    display: flex;
    align-items: center;
    /* border-radius: 5px; */

    .marquee {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      font-size: 4.5em;
      line-height: 1;
      margin-top: 0.5rem;
    }

    .marquee-content {
      display: inline-block;
      padding-left: 100%;
      // animation: marquee 20s linear infinite;
    }

    @keyframes marquee {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-100%);
      }
    }

    .blink {
      animation: blinker 1s linear infinite;
    }

    @keyframes blinker {
      50% {
        opacity: 0;
      }
    }

    .sbdContent {
      height: calc(14vh - 4rem);
      font-size: 5em;
      display: inline-block;

      > div {
        height: inherit;
        text-wrap: nowrap;
        overflow: hidden;
        text-align: center;
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }

    .sbdActionY {
      animation: blinkText 2s linear infinite;
    }

    @keyframes blinkText {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .content-container {
      flex-direction: column;
      gap: 0;
      > div {
        flex: 1;
      }

      .table {
        min-height: fit-content;
      }
    }
  }
`;

interface ISafeBoardT {
  hCd?: string;
  sCd?: string;
  no?: number;
  index: number;
  sbtCd?: string;
  sbtName: string;
  sbtDefault: string;
  useYn: string;
  writer: string;
  wDate?: string;
  editor: string;
  eDate?: string;
  flag?: string;
}
interface ISafeBoardD {
  hCd?: string;
  sCd?: string;
  index: number;
  sbtCd?: string;
  sbdCd: string;
  sbdName: string;
  sbdFontcolor: string;
  sbdAction: string;
  cdSort: number;
  useYn: string;
  wDate?: string;
  editor: string;
  eDate?: string;
  flag?: string;
  checkedSbd: boolean;
}

type DragItem = {
  type: string;
  index: number;
};

const DRAGGABLE_ITEM_TYPE = 'ROW';

const DraggableRow = ({
  el,
  index,
  moveRow,
  onClickOpenColorPicker,
  boardDtableState,
  setBoardDtableState,
  boardDorgTableState,
  setBoardDOrgTableState,
  onClickDeleteBoardD,
  onChangeCheckbox,
  onChangeBoardDtableState,
  userInfo,
  isSave2Clicked,
  auth,
}: any) => {
  const { t } = useTranslation();
  const trRef = useRef(null);
  const newCdArray = boardDtableState.map((elParam: ISafeBoardD) => elParam.cdSort);
  const cdSortArray = newCdArray.sort((a: number, b: number) => a - b);

  const [, drop] = useDrop({
    accept: DRAGGABLE_ITEM_TYPE,
    hover(item: DragItem, monitor) {
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop: (item, monitor) => {
      const copyArray = [...boardDtableState];
      const newArray = copyArray.map((cpItem, cpIndex) => {
        return {
          ...cpItem,
          cdSort: cdSortArray[cpIndex],
          flag: FLAG_CREATE_OR_UPDATE,
          editor: userInfo.userId,
        };
      });

      setBoardDtableState(newArray);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: DRAGGABLE_ITEM_TYPE,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(trRef));

  return (
    <div ref={trRef} style={{ opacity: isDragging ? 0 : 1 }}>
      <div className='tr' role='button' tabIndex={0}>
        <div className='trCol2p5 flex-center tableStickyNo'>
          <input type='checkbox' id='checkedSbd' name='checkedSbd' checked={el.checkedSbd} onChange={(e) => onChangeCheckbox(e, index, el)} />
        </div>
        <div className='trCol2p5 flex-center'>{index + 1}</div>
        <div className='trCol4 flex-center'>{el.sbdCd}</div>
        <div className='trCol22 flex-center'>
          <input
            type='text'
            id='sbdName'
            name='sbdName'
            value={el.sbdName}
            onChange={(e) => onChangeBoardDtableState(e, index, el)}
            style={isSave2Clicked ? applyBorderStyle(el.sbdName, 'red', 'sbdName') : undefined}
            maxLength={50}
          />
        </div>
        <div className='trCol4 flex-center'>
          <button
            type='button'
            onClick={() => onClickOpenColorPicker(el, index)}
            className='colorButton'
            style={el.sbdFontcolor !== '' ? { backgroundColor: el.sbdFontcolor } : { backgroundColor: SAFE_DEFAULT_COLOR }}
          >
            {' '}
          </button>
        </div>
        <div className='trCol4 flex-center'>
          <input type='checkbox' id='sbdAction' name='sbdAction' checked={el.sbdAction === 'Y'} onChange={(e) => onChangeCheckbox(e, index, el)} />
        </div>
        <div className='trCol6 flex-center selector'>
          <SelectBoxs
            options={USE_YN}
            defaultOption={el.useYn === 'Y' ? t('사용') : t('미사용')}
            state={boardDtableState}
            setState={setBoardDtableState}
            rawData={boardDorgTableState}
            setRawData={setBoardDOrgTableState}
            stateKey='useYn'
            codeKey='cdName'
            index={index}
            object={el}
            primaryKey='sbdCd'
          />
        </div>
        {auth.deleteAuth && (
          <div className='trCol4 flex-center'>
            <BtnRed onClick={() => onClickDeleteBoardD(el, index)}>{el.index === undefined ? t('삭제') : t('제거')}</BtnRed>
          </div>
        )}
      </div>
    </div>
  );
};

const Mainpoint = () => {
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const userInfo = useRecoilValue<IUser>(userState);
  const size = useOutletContext<any>();
  const { data: useYnComCdListWithAll } = useFetchCommonCodeList(COMCD_USE_YN, true); // 사용여부 공통코드 목록 (전체포함)
  const [searchOption, setSearchOption] = useState({ name: '' });
  const [useYn, setUseYn] = useState(INIT_USE_YN_A);
  const [boardTtableState, setBoardTtableState] = useState<ISafeBoardT[]>([]);
  const [boardDtableState, setBoardDtableState] = useState<ISafeBoardD[]>([]);
  const [boardTorgTableState, setBoardTOrgTableState] = useState<ISafeBoardT[]>([]);
  const [boardDorgTableState, setBoardDOrgTableState] = useState<ISafeBoardD[]>([]);
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '', title: '' });
  const [newBoardTRowIndex, setNewBoardTRowIndex] = useState<number>(0); // 신규항목 추가 클릭했을 때 새 로우객체에 인덱스추가
  const [newBoardDRowIndex, setNewBoardDRowIndex] = useState<number>(0); // 신규항목 추가 클릭했을 때 새 로우객체에 인덱스추가
  const [initBoardTableLength, setInitBoardTableLength] = useState<number>(0); // 테이블에 표시할 로우 넘버
  const [initBoardDTableLength, setInitBoardDTableLength] = useState<number>(0); // 테이블에 표시할 로우 넘버
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장1버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [isSave2Clicked, setIsSave2Clicked] = useState(false); // 저장2버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [showSubTable, setShowSubTable] = useState({ status: false, sbtCd: '', sbtName: '' });
  const [allChecked, setAllChecked] = useState(false);
  const [newSbdCount, setNewSbdCount] = useState({ count: 1 });
  const [color, setColor] = useState('#000');
  const [combinedText, setCombinedText] = useState<any>();
  const [clickPreview, setClickPreview] = useState(false); // 미리보기 클릭여부
  const [flowTime, setFlowTime] = useState<number>(0);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const scrollContainerRef = useRef<HTMLInputElement>(null);
  const [addRowStatus, setAddRowStatus] = useState(false); // 신규추가 상태값

  useEffect(() => {
    getSafeBoardtAPI();
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '안전관리/게시판 > 안전 중점사항',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    applyFilter(boardTorgTableState);
  }, [searchOption, useYn[COMCD_USE_YN]]);

  useEffect(() => {
    const newArray = boardDtableState.map((el: ISafeBoardD) => ({ ...el, checkedSbd: allChecked }));
    setBoardDtableState(newArray);
  }, [allChecked]);

  // 신규추가시 스크롤 마지막으로 이동
  useEffect(() => {
    if (addRowStatus) {
      if (scrollContainerRef.current) scrollContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      setAddRowStatus(false);
    }
  }, [addRowStatus]);

  const getSafeBoardtAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/safe/boardt', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      const { safeBoardTList } = data;
      const addFlag = safeBoardTList.map((el: ISafeBoardT) => ({ ...el, flag: FLAG_NONE, hCd: userInfo.hCd, sCd: userInfo.sCd }));
      setBoardTtableState(addFlag);
      setBoardTOrgTableState(addFlag);
      setNewBoardTRowIndex(safeBoardTList.length);
      setInitBoardTableLength(safeBoardTList.length);
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  const getSafeBoardDAPI = async (sbtCdParam: string) => {
    const res = await apiGet({ path: '/safe/boardd', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, sbtCd: sbtCdParam } });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      const { safeBoardDList } = data;
      const addFlag = safeBoardDList.map((el: ISafeBoardD) => ({ ...el, flag: FLAG_NONE, hCd: userInfo.hCd, sCd: userInfo.sCd, sbtCd: sbtCdParam }));

      setBoardDtableState(arraySortByAscdOrder(addFlag, 'cdSort'));
      setBoardDOrgTableState(arraySortByAscdOrder(addFlag, 'cdSort'));
      setNewBoardDRowIndex(safeBoardDList.length);
      setInitBoardDTableLength(safeBoardDList.length);
    } else {
      // toast.error(t(ERROR));
    }
  };

  const deleteBoardtAPI = async (el: ISafeBoardT) => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, sbtCd: el.sbtCd, editor: userInfo.userId };
    const res = await apiDelete({ path: '/safe/boardt', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      setOpenModal((prev: IModal) => ({ ...prev, status: false }));
      // setNewBoardTRowIndex(data.cameraList.length);
    } else {
      // toast.error(t(ERROR));
    }
  };

  const deleteBoarddAPI = async (el: ISafeBoardD) => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, sbtCd: showSubTable.sbtCd, sbdCd: el.sbdCd, editor: userInfo.userId };
    const res = await apiDelete({ path: '/safe/boardd', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      setOpenModal((prev: IModal) => ({ ...prev, status: false }));
      // setNewBoardDRowIndex(data.cameraList.length);
    } else {
      // toast.error(t(ERROR));
    }
  };

  const saveBoardTListAPI = async (reqData: ISafeBoardT[]) => {
    const req = { safeBoardTReqDto: trimArray(reqData) };
    const res = await apiPost({ path: '/safe/boardt', contentType: 'application/json', req });
    const { statusCode, data, message } = res.data;
    if (statusCode === 200) {
      const { safeBoardTList } = data;
      const addFlag = safeBoardTList.map((el: ISafeBoardT) => ({ ...el, flag: FLAG_NONE, hCd: userInfo.hCd, sCd: userInfo.sCd }));
      setBoardTtableState(addFlag);
      setBoardTOrgTableState(addFlag);
      setNewBoardTRowIndex(safeBoardTList.length);
      setInitBoardTableLength(safeBoardTList.length);
      setIsSaveClicked(false);
      toast.success(t(message));
    } else {
      // toast.error(t(ERROR));
    }
  };

  const saveBoardDListAPI = async (reqData: ISafeBoardD[]) => {
    const req = { safeBoardDReqDto: trimArray(reqData) };
    const res = await apiPost({ path: '/safe/boardd', contentType: 'application/json', req });
    const { statusCode, data, message } = res.data;
    if (statusCode === 200) {
      const { safeBoardDList } = data;
      const addFlag = safeBoardDList.map((el: ISafeBoardT) => ({ ...el, flag: FLAG_NONE, hCd: userInfo.hCd, sCd: userInfo.sCd, sbtCd: showSubTable.sbtCd }));
      setBoardDtableState(addFlag);
      setBoardDOrgTableState(addFlag);
      setNewBoardDRowIndex(safeBoardDList.length);
      setInitBoardDTableLength(safeBoardDList.length);
      setIsSaveClicked(false);
      setClickPreview(false);
      toast.success(t(message));
    } else {
      // toast.error(t(ERROR));
    }
  };

  const onChangeBoardTtableState = (e: React.ChangeEvent<HTMLInputElement>, i: number, el: ISafeBoardT) => {
    const { name, value } = e.currentTarget;
    const viewDataArray = [...boardTtableState];
    const rawDataArray = [...boardTorgTableState];

    let findIndex;
    if (el.index === undefined) {
      // 새로운 로우가 아닌 기존 로우를 수정했을 때
      findIndex = boardTtableState.findIndex((boardTEl: ISafeBoardT) => boardTEl.sbtCd === el.sbtCd);
    } else {
      findIndex = i;
    }

    viewDataArray[i] = { ...viewDataArray[i], [name]: value, flag: FLAG_CREATE_OR_UPDATE }; // 뷰테이블 로우 업데이트
    if (findIndex !== -1) {
      // 데이터테이블 로우 업데이트
      rawDataArray[findIndex] = { ...rawDataArray[findIndex], [name]: value, editor: userInfo.userId, flag: FLAG_CREATE_OR_UPDATE };
    }
    setBoardTtableState(viewDataArray);
    setBoardTOrgTableState(rawDataArray);
  };

  const onChangeBoardDtableState = (e: React.ChangeEvent<HTMLInputElement>, i: number, el: ISafeBoardD) => {
    const { name, value } = e.currentTarget;
    const viewDataArray = [...boardDtableState];
    const rawDataArray = [...boardDorgTableState];

    let findIndex;
    if (el.index === undefined) {
      // 새로운 로우가 아닌 기존 로우를 수정했을 때
      findIndex = boardDtableState.findIndex((boardDEl: ISafeBoardD) => boardDEl.sbdCd === el.sbdCd);
    } else {
      findIndex = i;
    }

    viewDataArray[i] = { ...viewDataArray[i], [name]: value, flag: FLAG_CREATE_OR_UPDATE }; // 뷰테이블 로우 업데이트
    if (findIndex !== -1) {
      // 데이터테이블 로우 업데이트
      rawDataArray[findIndex] = { ...rawDataArray[findIndex], [name]: value, editor: userInfo.userId, flag: FLAG_CREATE_OR_UPDATE };
    }
    setBoardDtableState(viewDataArray);
    setBoardDOrgTableState(rawDataArray);
  };

  const onChangeRadioOption = (e: React.ChangeEvent<HTMLInputElement>, i: number, el: ISafeBoardT) => {
    const viewDataArray = [...boardTtableState]; // 뷰테이블 로우 어레이

    // 수정한 로우 & 원래 디폴트값이었던 로우의 적용여부,플래그 값 업데이트
    const findPrevDefaultIndex = viewDataArray.findIndex((viewEl: ISafeBoardT) => viewEl.sbtDefault === 'Y');
    if (findPrevDefaultIndex !== -1) {
      viewDataArray[findPrevDefaultIndex] = {
        ...viewDataArray[findPrevDefaultIndex],
        sbtDefault: 'N',
        flag: FLAG_CREATE_OR_UPDATE,
      };
    }

    const findNewDefaultIndex = viewDataArray.findIndex((_, viewIndex) => viewIndex === i);
    if (findNewDefaultIndex !== -1) {
      viewDataArray[findNewDefaultIndex] = {
        ...viewDataArray[findNewDefaultIndex],
        sbtDefault: 'Y',
        flag: FLAG_CREATE_OR_UPDATE,
      };
    }

    setBoardTtableState(viewDataArray);
  };

  const onChangeAllChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setAllChecked(checked);
  };

  const onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>, i: number, el: ISafeBoardD) => {
    const viewDataArray = [...boardDtableState]; // Clone the current state
    const { name, checked } = e.target;

    if (name === 'sbdAction') {
      if (i < viewDataArray.length) {
        viewDataArray[i] = {
          ...viewDataArray[i],
          sbdAction: checked ? 'Y' : 'N',
          flag: FLAG_CREATE_OR_UPDATE,
        };
      }
    }

    if (name === 'checkedSbd') {
      if (i < viewDataArray.length) {
        viewDataArray[i].checkedSbd = checked;
      }
    }

    setBoardDtableState(viewDataArray);

    const newArray2 = viewDataArray.every((elParam) => elParam.checkedSbd);
    setAllChecked(newArray2);
  };

  // 안전중점사항 항목 추가 클릭
  const onClickAddNewBoardT = () => {
    setNewBoardTRowIndex((prev) => prev + 1);
    const newAdd = {
      index: newBoardTRowIndex,
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      sbtCd: '',
      sbtName: '',
      sbtDefault: 'N',
      useYn: 'Y',
      writer: '',
      editor: '',
      flag: FLAG_CREATE_OR_UPDATE,
    };

    setBoardTtableState((prev: ISafeBoardT[]) => [...prev, newAdd]);
    setAddRowStatus(true);
  };

  // 안전중점사항 세부항목 추가 클릭
  const onClickAddNewBoardD = () => {
    setNewBoardDRowIndex((prev) => prev + 1);

    const newRows = Array.from({ length: newSbdCount.count > 1 ? newSbdCount.count : 1 }, (_, i) => ({
      index: newBoardDRowIndex + (i + 1),
      cdSort: boardDtableState.length + i + 1,
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      sbtCd: showSubTable.sbtCd,
      sbdCd: '',
      sbdName: '',
      sbdFontcolor: color || SAFE_DEFAULT_COLOR,
      sbdAction: 'N',
      useYn: 'Y',
      writer: '',
      editor: '',
      flag: FLAG_CREATE_OR_UPDATE,
      checkedSbd: false,
    }));
    setBoardDtableState((prev: ISafeBoardD[]) => [...prev, ...newRows]);
    setNewSbdCount({ count: 1 });
  };

  const onClickSaveBoardT = async () => {
    setIsSaveClicked(true);
    const findDefault = boardTtableState.find((el) => el.sbtDefault === 'Y');
    if (findDefault !== undefined) {
      // 기본값이 Y이면서 동시에 사용유무가 N 인 경우
      const defaultYn = boardTtableState.find((el) => el.sbtDefault === 'Y' && el.useYn === 'N');
      if (defaultYn !== undefined) {
        return toast.warning(t('적용 항목의 경우 미사용할 수 없습니다'));
      }
      const requiredFieldArray = ['sbtName'];
      const emptyCheck = boardTtableState.filter((el: any) => {
        const check = requiredFieldArray.find((el2: string) => el[el2] === '');
        return check;
      });
      if (emptyCheck.length > 0) {
        toast.warning(t('필수입력값을 모두 입력하세요'));
      } else {
        const addWriterIntoNewArray = boardTtableState.map((el) => (el.index !== undefined ? { ...el, writer: userInfo.userId } : { ...el }));
        const newArray = addWriterIntoNewArray.map(({ index, no, wDate, eDate, ...rest }: any) => rest);
        const filteredArray = newArray.filter((el: ISafeBoardT) => el.flag === FLAG_CREATE_OR_UPDATE);
        const addUserInfoArray = filteredArray.map((el: ISafeBoardT) => ({ ...el, editor: userInfo.userId }));
        saveBoardTListAPI(addUserInfoArray);
      }
    } else toast.warning(t('기본 적용항목을 선택하세요'));
    return undefined;
  };

  const onClickSaveBoardD = async () => {
    setIsSave2Clicked(true);

    const requiredFieldArray = ['sbdName'];
    const emptyCheck = boardDtableState.filter((el: any) => {
      const check = requiredFieldArray.find((el2: string) => el[el2] === '');
      return check;
    });
    if (emptyCheck.length > 0) {
      toast.warning(t('필수입력값을 모두 입력하세요'));
    } else {
      const addWriterIntoNewArray = boardDtableState.map((el) => (el.index !== undefined ? { ...el, writer: userInfo.userId } : { ...el }));
      const newArray = addWriterIntoNewArray.map(({ index, no, wDate, eDate, checkedSbd, ...rest }: any) => rest);
      const filteredArray = newArray.filter((el: ISafeBoardD) => el.flag === FLAG_CREATE_OR_UPDATE);
      const addUserInfoArray = filteredArray.map((el: ISafeBoardD) => {
        return { ...el, editor: userInfo.userId };
      });
      saveBoardDListAPI(addUserInfoArray);
    }
    return undefined;
  };

  const decreaseArrayNo = (array: any[], dataLength: number, setState: Dispatch<SetStateAction<any[]>>) => {
    let increase = 0;

    const newArray = array.map((el, i) => {
      if (el.index !== undefined) {
        increase += 1;
        const copyArray = [...array];
        copyArray[i] = {
          ...copyArray[i],
          no: dataLength + increase,
        };
        return copyArray[i];
      }
      return el;
    });
    setState(newArray);
  };

  const onClickDeleteBoardT = (el: ISafeBoardT, i: number) => {
    if (el.sbtDefault === 'N') {
      if (el.index !== undefined) {
        // 새로 추가한 로우 제거 클릭 시
        const updatedArray = [...boardTtableState];
        updatedArray.splice(i, 1);
        decreaseArrayNo(updatedArray, initBoardTableLength, setBoardTtableState);
      } else {
        // 삭제 시 에디터아이디 추가
        const data = {
          ...el,
          editor: userInfo.userId,
        };
        setOpenModal((prev: any) => ({ ...prev, status: true, type: 'array', title: 'delete', state: boardTtableState, setState: setBoardTtableState, api: deleteBoardtAPI, el: data, index: i }));
      }
    } else toast.warning(t('적용중인 항목은 삭제하실 수 없습니다'));
  };

  const onClickDeleteBoardD = (el: ISafeBoardD, i: number) => {
    if (el.index !== undefined) {
      // 새로 추가한 로우 제거 클릭 시
      const updatedArray = [...boardDtableState];
      updatedArray.splice(i, 1);
      decreaseArrayNo(updatedArray, initBoardDTableLength, setBoardDtableState);
    } else {
      // 삭제 시 에디터아이디 추가
      const data = {
        ...el,
        editor: userInfo.userId,
      };
      setOpenModal((prev: any) => ({ ...prev, status: true, type: 'array', title: 'delete', state: boardDtableState, setState: setBoardDtableState, api: deleteBoarddAPI, el: data, index: i }));
    }
  };

  const onClickTableState = (i: number, el: any) => {
    setSelectedRowIndex(i);
    if (el.index === undefined) {
      // 기존 로우 클릭시 상세항목 목록 노출
      getSafeBoardDAPI(el.sbtCd);
      setShowSubTable({ status: true, sbtCd: el.sbtCd, sbtName: el.sbtName });
      setCombinedText('');
    } else {
      setShowSubTable({ status: false, sbtCd: '', sbtName: '' });
    }
  };

  const onClickOpenColorPicker = (el?: ISafeBoardD, index?: number) => {
    if (index !== undefined) {
      setOpenModal((prev: any) => ({ ...prev, status: true, type: 'object', title: 'colorPicker', state: boardDtableState, setState: setBoardDtableState, color, setColorState: setColor, el, index }));
    } else {
      setOpenModal((prev: any) => ({ ...prev, status: true, type: 'array', title: 'colorPicker', state: boardDtableState, setState: setBoardDtableState, color, setColorState: setColor, index }));
    }
  };

  // const onClickOpenMovingText = (el?: ISafeBoardD, index?: number) => {
  //   setOpenSubModal(true);
  // };

  const onClickInitiateSearchOption = () => {
    setSearchOption((prev: any) => ({ ...prev, name: '' }));
    setUseYn(INIT_USE_YN_A);
    setShowSubTable({ status: false, sbtCd: '', sbtName: '' });
  };

  const applyFilter = (array: any[]) => {
    // 검색옵션 변경됐을 때 필터링 처리
    // 필터링 기준
    const filterOptions = {
      name: searchOption.name,
    };
    // 필터링된 어레이 리턴, 대소문자구분X
    const titleFilteredArray = array.filter((item: any) => {
      return item.sbtName?.toLowerCase()?.includes(filterOptions.name?.toLowerCase());
    });
    const newFilter = titleFilteredArray;
    const result = ynFilter(newFilter, 'useYn', useYn[COMCD_USE_YN]);

    if (result.length > 0) setBoardTtableState(result);
    else setBoardTtableState([]);
  };

  const moveRow = (fromIndex: number, toIndex: number) => {
    const updatedData = [...boardDtableState];
    const [movedItem] = updatedData.splice(fromIndex, 1);
    updatedData.splice(toIndex, 0, movedItem);
    setBoardDtableState(updatedData);
  };

  useEffect(() => {
    setClickPreview(false);
  }, [showSubTable]);

  const onClickPreview = () => {
    if (showSubTable.status) {
      if (boardDtableState && boardDtableState.length > 0) {
        const totalLengthWithoutSpaces = boardDtableState
          .filter((el: ISafeBoardD) => el.useYn === 'Y')
          .map((el: ISafeBoardD) => el.sbdName.replace(/\s+/g, ''))
          .reduce((sum, sbdNameWithoutSpaces) => sum + sbdNameWithoutSpaces.length, 0);

        const getFlowTime = () => {
          if (totalLengthWithoutSpaces < 50) return totalLengthWithoutSpaces;
          if (totalLengthWithoutSpaces < 100) return totalLengthWithoutSpaces / 3;
          return totalLengthWithoutSpaces / 3;
        };

        setFlowTime(getFlowTime());

        const marqueeContent = boardDtableState
          .filter((item2: ISafeBoardD) => item2.useYn === 'Y')
          .map((item: ISafeBoardD) => (
            <span key={item.sbdCd} className={item.sbdAction === 'Y' ? 'blink' : ''} style={{ color: item.sbdFontcolor }}>
              {item.sbdName}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            </span>
          ));

        setCombinedText(marqueeContent);

        setClickPreview((prev) => !prev);
      } else toast.warning(t('안전 중점사항 내용이 없습니다'));
    } else toast.warning(t('안전 중점사항 항목을 선택하세요'));
  };

  const getBackgroundStyle = (i: number) => {
    if (i === selectedRowIndex) {
      return { backgroundColor: 'rgb(243, 246, 249)' };
    }
    return undefined;
  };

  const getChecked = () => boardDtableState.filter((el: any) => el.checkedSbd === true).length;

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={`content-container ${size.innerSize.W >= 1024 ? 'twoColumn column-55 max800' : 'oneColumn'}`}>
        <Root className={size.innerSize.W >= 1024 || (size.innerSize.W < 1024 && !showSubTable.sbtName) ? 'showRoot' : 'hideRoot'}>
          <SearchOptions align='left'>
            <div className='inputsWrapper'>
              <div className='inputForm-row'>
                <div className='inputForm-col withLabelComCf'>
                  <label htmlFor='useYn'>{t('사용유무')}</label>
                  <SelectBox
                    options={useYnComCdListWithAll}
                    defaultOption={useYn.cdName}
                    state={useYn}
                    setState={setUseYn}
                    stateKey={COMCD_USE_YN}
                    initiateKey={useYn[COMCD_USE_YN]}
                    filterbar='filter-1-left'
                  />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <Input label='' placeholder={t('항목 명')} type='text' id='name' name='name' state={searchOption} setState={setSearchOption} />
                </div>
              </div>
            </div>
            {/* <BtnGreen onClick={onClickInitiateSearchOption}>초기화</BtnGreen> */}
          </SearchOptions>
          <InputTable className='margin-left-05'>
            <div className='thead'>
              <div className='tr'>
                <div className='trCol2p5 flex-center tableStickyNo'>No</div>
                <div className='trCol4 flex-center'>{t('코드')}</div>
                <div className='trCol10 flex-center required tableStickyTitle'>{t('항목 명')}</div>
                <div className='trCol3 flex-center'>{t('적용')}</div>
                <div className='trCol6 flex-center'>{t('사용유무')}</div>
                <div className='trCol10 flex-center'>{t('등록일자')}</div>
                <div className='trCol6 flex-center'>{t('등록자')}</div>
                <div className='trCol10 flex-center'>{t('수정일자')}</div>
                <div className='trCol6 flex-center'>{t('수정자')}</div>
                {auth.deleteAuth && <div className='trCol4 flex-center'> </div>}
              </div>
            </div>
            <div className='table' ref={scrollContainerRef}>
              <div className='tbody'>
                {boardTtableState.map((el, i) => (
                  <div
                    className='tr'
                    role='button'
                    tabIndex={0}
                    key={i}
                    onClick={() => onClickTableState(i, el)}
                    // onClick={(e) => test(e)}
                    style={getBackgroundStyle(i)}
                  >
                    <div className='trCol2p5 flex-center tableStickyNo text_disabled'>{i + 1}</div>
                    <div className='trCol4 flex-center'>{el.sbtCd}</div>
                    <div className='trCol10 flex-center tableStickyTitle'>
                      <input
                        type='text'
                        id='sbtName'
                        name='sbtName'
                        value={el.sbtName}
                        onChange={(e) => onChangeBoardTtableState(e, i, el)}
                        style={isSaveClicked ? applyBorderStyle(el.sbtName, 'red', 'sbtName') : undefined}
                        onClick={(event) => {
                          if (size.innerSize.W < 1024) {
                            event.stopPropagation();
                          }
                        }}
                        maxLength={50}
                      />
                    </div>
                    <div className='trCol3 flex-center'>
                      <input
                        type='radio'
                        id='sbtDefault'
                        name='sbtDefault'
                        checked={el.sbtDefault === 'Y'}
                        onChange={(e) => onChangeRadioOption(e, i, el)}
                        disabled={el.useYn === 'N'}
                        onClick={(event) => {
                          if (size.innerSize.W < 1024) {
                            event.stopPropagation();
                          }
                        }}
                      />
                    </div>
                    <div className='trCol6 flex-center selector'>
                      <SelectBoxs
                        options={USE_YN}
                        defaultOption={el.useYn === 'Y' ? t('사용') : t('미사용')}
                        state={boardTtableState}
                        setState={setBoardTtableState}
                        rawData={boardTorgTableState}
                        setRawData={setBoardTOrgTableState}
                        stateKey='useYn'
                        codeKey='cdName'
                        index={i}
                        object={el}
                        primaryKey='sbtCd'
                      />
                    </div>
                    <div className='trCol10 flex-center text_tertiary'>{el.wDate}</div>
                    <div className='trCol6 flex-center text_tertiary'>{el.writer}</div>
                    <div className='trCol10 flex-center text_primary'>{el.eDate}</div>
                    <div className='trCol6 flex-center text_primary'>{el.editor}</div>
                    {auth.deleteAuth && (
                      <div className='trCol4 flex-center'>
                        <BtnRed
                          onClick={(event) => {
                            event.stopPropagation();
                            onClickDeleteBoardT(el, i);
                          }}
                        >
                          {el.index === undefined ? t('삭제') : t('제거')}
                        </BtnRed>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </InputTable>
          <ButtonsWrapper>
            {auth.createAuth && <BtnBlue onClick={onClickAddNewBoardT}>{t('신규항목 추가')}</BtnBlue>}
            {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={onClickSaveBoardT}>{t('저장')}</BtnBlue>}
          </ButtonsWrapper>
        </Root>

        {showSubTable.sbtName ? (
          <Root>
            <div className='inputFormsWrapper report'>
              {!(size.innerSize.W >= 1024) && (
                <div
                  className='icon-btn arrow-icon'
                  role='presentation'
                  onMouseDown={() => {
                    setShowSubTable({ status: false, sbtCd: '', sbtName: '' });
                  }}
                >
                  <span className='material-symbols-rounded'>arrow_back</span>
                  {t('뒤로가기')}
                </div>
              )}
              <div className='formTitle'>{showSubTable.status && `${showSubTable.sbtName}(${showSubTable.sbtCd})`}</div>
            </div>

            <InputTable className='margin-left-05'>
              {getChecked() !== 0 ? (
                <div className='checkedApply'>
                  <span>
                    <span className='material-symbols-rounded'>check_box</span>
                    <span>
                      <span className='colorText'>{getChecked()}</span>
                      {t('개 항목이 선택되었습니다')}
                    </span>
                  </span>
                  <BtnGreen onClick={() => onClickOpenColorPicker()}>{t('글자 색상 일괄변경')}</BtnGreen>
                </div>
              ) : (
                <div className='tableTip'>
                  <span className='material-symbols-rounded'>lightbulb </span>
                  <span>{t('드래그 앤 드롭을 지원합니다. 행 순서를 위,아래로 변경하세요')}</span>
                </div>
              )}
              <div className='thead'>
                <div className='tr'>
                  <div className='trCol2p5 flex-center tableStickyNo'>
                    <input type='checkbox' id='checkAll' name='checkAll' checked={allChecked} onChange={onChangeAllChecked} />
                  </div>
                  <div className='trCol2p5 flex-center'>No</div>
                  <div className='trCol4 flex-center'>{t('코드')}</div>
                  <div className='trCol22 required flex-center'>{t('중점사항 내용')}</div>
                  <div className='trCol4 flex-center'>{t('글자 색상')}</div>
                  <div className='trCol4 flex-center'>{t('깜빡임')}</div>
                  <div className='trCol6 flex-center'>{t('사용유무')}</div>
                  {auth.deleteAuth && <div className='trCol4 flex-center'> </div>}
                </div>
              </div>
              {/* <div className='red'>※ Drag & Drop 순서변경</div> */}
              <div className={`${boardDtableState?.length > 0 ? 'table' : 'table img'}`}>
                <div className='tbody'>
                  {showSubTable.status && boardDtableState?.length > 0 ? (
                    boardDtableState.map((el, index) => (
                      <DraggableRow
                        key={`${el.sbdCd}_${index}`}
                        index={index}
                        el={el}
                        moveRow={moveRow}
                        onClickOpenColorPicker={onClickOpenColorPicker}
                        boardDtableState={boardDtableState}
                        setBoardDtableState={setBoardDtableState}
                        boardDorgTableState={boardDorgTableState}
                        setBoardDOrgTableState={setBoardDOrgTableState}
                        onClickDeleteBoardD={onClickDeleteBoardD}
                        onChangeCheckbox={onChangeCheckbox}
                        onChangeBoardDtableState={onChangeBoardDtableState}
                        userInfo={userInfo}
                        isSave2Clicked={isSave2Clicked}
                        auth={auth}
                      />
                    ))
                  ) : (
                    <IssueGuide />
                  )}
                </div>
              </div>
            </InputTable>
            {clickPreview && (
              <div className='previewWrapper'>
                <div className='marquee'>
                  <div className='marquee-content' style={clickPreview ? { animation: `marquee ${flowTime}s linear infinite` } : undefined}>
                    {combinedText}
                  </div>
                </div>
              </div>
            )}
            <ButtonsWrapper>
              {boardDtableState?.length > 0 && <BtnGreen onClick={onClickPreview}>{clickPreview ? t('정지') : t('미리보기')}</BtnGreen>}
              <div className='colorPicker' role='presentation' onClick={() => onClickOpenColorPicker()} style={{ backgroundColor: color }} />
              <div className='inputNumber'>
                <Input type='number' id='count' name='count' maxLength={2} state={newSbdCount} setState={setNewSbdCount} />
                <span className='flex-center'>
                  {t('개')} {t('행')}
                </span>
              </div>
              {auth.createAuth && <BtnBlue onClick={onClickAddNewBoardD}>{t('신규항목 추가')}</BtnBlue>}
              {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={onClickSaveBoardD}>{t('저장')}</BtnBlue>}
            </ButtonsWrapper>
          </Root>
        ) : (
          size.innerSize.W >= 1024 && (
            <Root>
              <div className='emptyData'>
                <img src={illustrator} alt='noData' />
                <span>{t('왼쪽 목록에서 메뉴 타이틀을 선택해주세요')}</span>
              </div>
            </Root>
          )
        )}
        {/* {openSubModal && <MovingTextModal openModal={openSubModal} setOpenModal={setOpenSubModal} clickPreview={clickPreview} flowTime={flowTime} combinedText={combinedText} />} */}
        <Portal openModal={openModal?.status}>
          {openModal && openModal.title === 'delete' && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}
          {openModal && openModal.title === 'colorPicker' && <ColorPickerModal openModal={openModal} setOpenModal={setOpenModal} />}
        </Portal>
      </div>
    </DndProvider>
  );
};

export default Mainpoint;
