import React, { ReactElement } from 'react';
import styled from 'styled-components';

const Root = styled.div`
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &:hover {
    cursor: pointer;
    box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.15);
  }
`;

type Props = {
  onToggle: ReactElement;
  offToggle: ReactElement;
  state: boolean;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
};

const ToggleButton = ({ onToggle, offToggle, state, setState, disabled = false }: Props) => {
  return (
    <Root onClick={() => setState(!state)} role='button' tabIndex={0} className={disabled ? 'disabled' : ''}>
      {state ? onToggle : offToggle}
    </Root>
  );
};

export default ToggleButton;
