/**
 * 작성자 : 홍선영
 * 날짜 : 2024.03.22
 * 기능 : 목록 펼치기/접기 기능이 있는 토글버튼
 */

import React from 'react';
import styled from 'styled-components';

const ExpandButtonStyle = styled.button`
  all: unset;
  cursor: pointer;
  user-select: none;
  background-color: ${({ theme }: { theme: any }) => theme.board};
  border-radius: 0.5rem;
  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }
  &.margin-05 {
    margin: 0 0.5rem;
  }
  .expandIcon {
    font-size: 24px;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }: { theme: any }) => theme.text_disabled};
  }
  &:hover {
    background-color: ${({ theme }: { theme: any }) => theme.board};
    outline: 3px solid ${({ theme }: { theme: any }) => theme.filled_blue};
    .material-symbols-rounded {
      font-variation-settings: 'wght' 600;
      color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
    }
    &:active {
      outline: 2px solid ${({ theme }: { theme: any }) => theme.filled_blue};
      transform: scale(0.95);
    }
  }
`;

const ExpandButton = ({
  isExpanded,
  onClickExpandButton,
  disabled,
  className,
}: {
  isExpanded: boolean;
  onClickExpandButton: (e: React.MouseEvent<any>) => void;
  disabled?: boolean;
  className?: string;
}) => {
  const style = `${disabled ? 'disabled' : ''} ${className ?? ''}`;
  return (
    <ExpandButtonStyle type='button' onClick={onClickExpandButton} className={style}>
      <span className='material-symbols-rounded expandIcon'>expand_{`${isExpanded ? 'less' : 'more'}`}</span>
    </ExpandButtonStyle>
  );
};
export default ExpandButton;
