/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.09
 * 경로 : 터널관리 - 구역 현황 관리 > 종합진행현황
 */

import { TunnelPointContainer } from '../../assets/styles/Tunnels';
import { tunnelReverseState } from '../../atoms';
import { useRecoilValue } from 'recoil';
import { TAREA_INNERTAB_CCTV, TAREA_INNERTAB_EQUIP, TAREA_INNERTAB_LOCATION, TAREA_INNERTAB_MGAS } from '../../_constants';
import TunnelChipView from './TunnelChipView';
import TunnelBigoView from './TunnelBigoView';
import TotalPointLength from './TotalLength';
import TunnelChartProgressBar from '../TunnelChartProgressBar';

type MultiTunnelPointProps = {
  data: MutateDataType;
  filter: TunnelFilterData[];
  grid?: boolean;
  siteDashboard?: boolean;
  onClick?: any;
};

const MultiTunnelPoint = ({ data, filter, grid = false, siteDashboard = false, onClick }: MultiTunnelPointProps) => {
  const isTunnelReversed = useRecoilValue(tunnelReverseState);
  const { tatGubun } = data;
  const isStart = tatGubun === 'S';
  const isReversed = !isTunnelReversed ? isStart : !isStart;
  const filterResult = ({ text, filterData }: { text: string; filterData: TunnelFilterData[] }) => {
    return filterData.find((obj) => obj.title === text)?.status;
  };

  return (
    <TunnelPointContainer className={siteDashboard ? 'siteDashboard' : ''}>
      <TunnelChartProgressBar rlength={data?.rLength} length={data?.length} pointType={isStart ? 'start' : 'end'} showOption={data?.useYn} timeOut={10} pointView isTunnelReversed={isTunnelReversed} />
      <TotalPointLength length={data?.length} rLength={data?.rLength} reverse={isReversed} nameOnly />
      <div className='detailPoint-group'>
        <div className={`detailPoint ${grid ? 'grid' : ''}`}>
          {/* 유해가스 */}
          {data?.dvNo !== undefined && filterResult({ text: 'mGas', filterData: filter }) && (
            <TunnelChipView
              value={data?.dvNo !== 0 ? '○' : '-'}
              icon='sensors'
              disabled={data?.dvNo === 0}
              size={grid ? 'small' : ''}
              onClick={() => data?.dvNo > 0 && onClick(TAREA_INNERTAB_MGAS, tatGubun)}
            />
          )}
          {/* 근로자 */}
          {data?.workerCount !== undefined && filterResult({ text: 'location', filterData: filter }) && (
            <TunnelChipView
              value={data?.workerCount}
              icon='group'
              disabled={data?.workerCount === '0'}
              size={grid ? 'small' : ''}
              onClick={() => data?.workerCount !== '0' && onClick(TAREA_INNERTAB_LOCATION, tatGubun)}
            />
          )}
          {/* 장비 */}
          {data?.deviceCount !== undefined && filterResult({ text: 'device', filterData: filter }) && (
            <TunnelChipView
              value={data?.deviceCount}
              icon='front_loader'
              disabled={data?.deviceCount === '0'}
              size={grid ? 'small' : ''}
              onClick={() => data?.deviceCount !== '0' && onClick(TAREA_INNERTAB_LOCATION, tatGubun)}
            />
          )}
          {/* CCTV */}
          {data?.cameraCount !== undefined && filterResult({ text: 'cctv', filterData: filter }) && (
            <TunnelChipView
              value={data?.cameraCount}
              icon='nest_cam_iq_outdoor'
              disabled={data?.cameraCount === '0'}
              size={grid ? 'small' : ''}
              onClick={() => data?.cameraCount !== '0' && onClick(TAREA_INNERTAB_CCTV, tatGubun)}
            />
          )}
          {/* 방송장비 */}
          {data?.bsCount !== undefined && filterResult({ text: 'broadCast', filterData: filter }) && (
            <TunnelChipView
              value={data?.bsCount}
              icon='volume_up'
              disabled={data?.bsCount === '0'}
              size={grid ? 'small' : ''}
              onClick={() => data?.bsCount !== '0' && onClick(TAREA_INNERTAB_EQUIP, tatGubun)}
            />
          )}
        </div>
      </div>
      {/* 작업내용 */}
      {data?.tjcName !== undefined && <TunnelBigoView text={data.tjcName} textType='tjc' label={grid} siteDashboard={siteDashboard} />}
      {/* 장비상태 */}
      {data?.tesName !== undefined && <TunnelBigoView text={data.tesName} textType='tes' label={grid} siteDashboard={siteDashboard} />}
    </TunnelPointContainer>
  );
};

export default MultiTunnelPoint;
