import { Dispatch, SetStateAction } from 'react';
/**
 * 작성자 : 한영광
 * 날짜 : 2024.01.19
 * 기능 : WebSDK CCTV 클래스
 */
import * as cctv from './cctv';

interface Nvr {
  ip: string;
  pPort: string;
  id: string;
  password: string;
}

interface Camera {
  ip: string;
  stream: string;
  channelNum: string;
  port: string;
  pPort: string;
  cName: string;
  nCd: string;
  cCd: string;
}

interface Setting {
  capturePath: string | null;
  videoPath: string | null;
}

export interface CameraStatus extends Camera {
  isPlaying: boolean;
  isRecording: boolean;
  isPausing: boolean;
  isFail: boolean;
  windowIndex: number | null;
  is3DZoomOn: boolean;
  isDigitalZoomOn: boolean;
  playbackSpeed: number;
}

export const emptyCameraStatus: CameraStatus = {
  ip: '',
  stream: '',
  channelNum: '',
  port: '',
  pPort: '',
  cName: '',
  isPlaying: false,
  isRecording: false,
  isPausing: false,
  isFail: false,
  windowIndex: null,
  is3DZoomOn: false,
  isDigitalZoomOn: false,
  nCd: '',
  cCd: '',
  playbackSpeed: 1,
};

interface Record {
  szPlaybackURI: string;
  szFileName: string;
  szStartTime: string;
  szEndTime: string;
  size: number;
}

type WebSDKConstructor = {
  setOpenModal: any;
  flowChangeListener?: any;
  divPlugin: string;
  ptzSpeed?: number;
  setSelectWndIndex?: Dispatch<SetStateAction<any>>;
  cType?: number; // cctvdivisions
  onMousePtzEventListener?: any;
};

export class WebSDKcctv {
  webVideoCtrl: any;
  setOpenModal: any;
  flowChangeListener: any;
  pluginOBJ: any;
  divPlugin: string;
  cctv: any;
  ptzSpeed: number;
  cctvDivisions: number;
  cameraStatus: CameraStatus[];
  setSelectWndIndex: Dispatch<SetStateAction<any>> | undefined;
  progressInterval: NodeJS.Timer | undefined;
  camCLocation: string;
  camFColor: string;
  currentPage: number;
  onMousePtzEventListener: any;

  constructor({ setOpenModal, flowChangeListener, divPlugin, ptzSpeed, setSelectWndIndex, cType, onMousePtzEventListener }: WebSDKConstructor) {
    this.setOpenModal = setOpenModal;
    this.flowChangeListener = flowChangeListener;
    this.divPlugin = divPlugin;
    this.cctv = cctv;
    this.ptzSpeed = ptzSpeed || 4;
    this.cctvDivisions = cType || 4;
    this.cameraStatus = new Array<CameraStatus>(128).fill(emptyCameraStatus);
    this.setSelectWndIndex = setSelectWndIndex;
    this.camCLocation = '1';
    this.camFColor = '255/255/13';
    this.currentPage = 1;
    this.onMousePtzEventListener = onMousePtzEventListener;
  }

  clickWnd(wndNum: number) {
    this.cctv.selectWnd(wndNum);
  }

  async init(width?: number) {
    this.webVideoCtrl = window;
    await this.cctv.init(
      this.setOpenModal,
      this.flowChangeListener ? (e: any) => this.flowChangeListener(e) : null,
      this.divPlugin,
      (e: any) => this.setPluginOBJ(e),
      this.setSelectWndIndex,
      this.cctvDivisions,
      width,
      this.onMousePtzEventListener
    );
    // this.cctv.changeWndNum(this.cctvDivisions, this.pluginOBJ);
  }

  async login(nvrs: Nvr[]) {
    let failNvrs: Nvr[] = [];
    await Promise.all(
      nvrs.map(async (nvr: any) => {
        await this.cctv
          .login(nvr.ip, nvr.pPort, nvr.id, nvr.password, this.flowChangeListener, this.pluginOBJ)
          .then((e: any) => {
            // console.log('success');
            if (e === 'fail') {
              failNvrs.push(nvr);
            }
          })
          .catch((e: any) => {
            failNvrs.push(nvr);
          });
      })
    );
    this.cctv.clickGetLocalCfg();
    return failNvrs;
  }

  setCurrentPage(currentPage: number) {
    this.currentPage = currentPage;
  }

  changeWndNum(cctvDivisions: number) {
    this.cctv.changeWndNum(cctvDivisions, this.pluginOBJ);
    this.cctvDivisions = cctvDivisions;
  }

  // CCTV 객체의 status를 초기화하지 않는 화면분할함수
  changeWndNumWithoutInitialize(cctvDivisions: number) {
    this.cctv.changeWndNum(cctvDivisions, this.pluginOBJ);
    this.cctvDivisions = cctvDivisions;
  }

  setSnapPolygon({ cctvDivisions, camCLocation, camFColor }: { cctvDivisions?: string; camCLocation?: string; camFColor?: string }) {
    this.cameraStatus
      .filter((camera: Camera) => camera.cName)
      .forEach((camera: CameraStatus, wndIndex: number) => {
        const pageWndIndex: number = (camera.windowIndex || 0) - (this.currentPage - 1) * this.cctvDivisions * this.cctvDivisions;
        this.cctv.clickSetSnapPolygon(pageWndIndex, camera.cName, cctvDivisions || this.cctvDivisions, camCLocation || this.camCLocation, camFColor || this.camFColor);
      });
  }

  async start(cameras: Camera[], startWndNum?: number, cctvDivisions?: number, camCLocation?: string, camFColor?: string) {
    const nextPageCams: any[] = [];
    this.camCLocation = camCLocation || '1';
    this.camFColor = camFColor || '255/255/13';
    // this.changeWndNum(cctvDivisions || this.cctvDivisions);
    await Promise.all(
      cameras.map(async (camera: Camera, i: number) => {
        const pageWndIndex: number = (startWndNum || 0) + i - (this.currentPage - 1) * this.cctvDivisions * this.cctvDivisions;
        const wndIndex: number = (startWndNum || 0) + i;
        if (pageWndIndex >= 0 && pageWndIndex < this.cctvDivisions * this.cctvDivisions) {
          // console.log({ pageWndIndex, name: camera.cName }, '재생 시작');
          await this.cctv
            .startRealPlay(camera.ip, camera.stream, camera.channelNum, pageWndIndex, camera.port, this.pluginOBJ, camCLocation)
            .then(async (v: any) => {
              await this.cctv.clickSetSnapPolygon(pageWndIndex, camera.cName, cctvDivisions, camCLocation, camFColor);
              this.cameraStatus[wndIndex] = {
                ...camera,
                isPlaying: true,
                isRecording: false,
                isPausing: false,
                windowIndex: wndIndex,
                is3DZoomOn: false,
                isDigitalZoomOn: false,
                isFail: false,
                playbackSpeed: 1,
              };
              return this.cameraStatus;
            })
            .catch((e: any) => {
              const isNotCurrentPage = wndIndex >= this.currentPage * this.cctvDivisions * this.cctvDivisions || wndIndex < (this.currentPage - 1) * this.cctvDivisions * this.cctvDivisions;
              if (isNotCurrentPage) {
                this.cameraStatus[wndIndex] = {
                  ...camera,
                  isPlaying: true,
                  isRecording: false,
                  isPausing: false,
                  windowIndex: wndIndex,
                  is3DZoomOn: false,
                  isDigitalZoomOn: false,
                  isFail: false,
                  playbackSpeed: 1,
                };
                return this.cameraStatus;
              }
              this.cameraStatus[wndIndex] = {
                ...camera,
                isPlaying: false,
                isRecording: false,
                isPausing: false,
                windowIndex: wndIndex,
                is3DZoomOn: false,
                isDigitalZoomOn: false,
                isFail: true,
                playbackSpeed: 1,
              };
              return this.cameraStatus;
            });
        } else {
          nextPageCams.push({ camera, wndIndex });
        }
      })
    );
    nextPageCams.forEach(({ camera, wndIndex }) => {
      this.cameraStatus[wndIndex] = {
        ...camera,
        isPlaying: true,
        isRecording: false,
        isPausing: false,
        windowIndex: wndIndex,
        is3DZoomOn: false,
        isDigitalZoomOn: false,
        isFail: false,
        playbackSpeed: 1,
      };
    });
    return this.cameraStatus;
  }

  async stop() {
    await this.cctv
      .stopAllRealPlay(this.pluginOBJ)
      .then(() => {
        this.cameraStatus = new Array<CameraStatus>(128).fill(emptyCameraStatus);
      })
      .catch((e: any) => {
        console.log(e);
      });
    return this.cameraStatus;
  }

  async stopWithoutInitialize() {
    await this.cctv.stopAllRealPlay(this.pluginOBJ);
    return this.cameraStatus;
  }

  async stopWithIndex(wndIndex: number) {
    const pageWndIndex: number = wndIndex - (this.currentPage - 1) * this.cctvDivisions * this.cctvDivisions;
    await this.cctv.clickStopRealPlay(pageWndIndex).then(() => {
      this.cameraStatus[wndIndex] = emptyCameraStatus;
    });
    return this.cameraStatus;
  }

  destroy() {
    this.cctv.destroy();
  }

  destoryPlugin() {
    this.cctv.destoryPlugin(this.pluginOBJ);
  }

  hideWnd() {
    this.cctv.hideWnd(this.pluginOBJ);
  }

  showWnd() {
    this.cctv.showWnd(this.pluginOBJ);
  }

  setPluginOBJ(obj: any) {
    this.pluginOBJ = obj;
  }

  getPluginOBJ() {
    return this.pluginOBJ;
  }

  getCameraStatus(wndIndex: number) {
    return this.cameraStatus[wndIndex];
  }

  getCameraStatusList() {
    return this.cameraStatus;
  }

  dropCamrera(cameras: Camera[], wndIndex: number) {
    this.cctv.selectWnd(wndIndex);
    this.start(cameras, wndIndex);
  }

  async mousePTZ(wndIndex: number, value: boolean) {
    if (value) {
      await this.pointZoomDisable(wndIndex);
      await this.zoomDisable(wndIndex);
    }
    this.cctv.setMousePTZ(value);
  }

  // PTZ(카메라 방향) 시작
  ptzOn(derection: number) {
    this.cctv.mouseDownPTZControl(derection, this.ptzSpeed);
  }

  // PTZ(카메라 방향) 중지
  ptzStop() {
    this.cctv.mouseUpPTZControl();
  }

  // 3D 줌 시작
  async pointZoomEnable(wndIndex: number) {
    await this.cctv.clickEnable3DZoom(wndIndex).then(() => {
      this.cameraStatus[wndIndex] = { ...this.cameraStatus[wndIndex], is3DZoomOn: true };
    });
    return this.cameraStatus;
  }

  // 3D 줌 중지
  async pointZoomDisable(wndIndex: number) {
    await this.cctv.clickDisable3DZoom(wndIndex).then(() => {
      this.cameraStatus[wndIndex] = { ...this.cameraStatus[wndIndex], is3DZoomOn: false };
    });
    return this.cameraStatus;
  }

  // PTZ 줌 in
  digitalZoomIn() {
    this.cctv.ptzZoomIn();
  }

  // PTZ 줌 out
  digitalZoomOut() {
    this.cctv.ptzZoomOut();
  }

  // PTZ 줌 stop
  digitalZoomStop() {
    this.cctv.ptzZoomStop();
  }

  // 디지털 줌 시작
  async zoomEnable(wndIndex: number) {
    const pageWndIndex: number = wndIndex - (this.currentPage - 1) * this.cctvDivisions * this.cctvDivisions;
    await this.cctv.clickEnableEZoom(pageWndIndex).then(() => {
      this.cameraStatus[wndIndex] = { ...this.cameraStatus[wndIndex], isDigitalZoomOn: true };
    });
    return this.cameraStatus;
  }

  // 디지털 줌 중지
  async zoomDisable(wndIndex: number) {
    await this.cctv.clickDisableEZoom(wndIndex).then(() => {
      this.cameraStatus[wndIndex] = { ...this.cameraStatus[wndIndex], isDigitalZoomOn: false };
    });
    return this.cameraStatus;
  }

  // 조리개 in
  irisIn() {
    this.cctv.ptzIrisIn();
  }

  // 조리개 out
  irisOut() {
    this.cctv.ptzIrisOut();
  }

  // 조리개 stop
  irisStop() {
    this.cctv.ptzIrisStop();
  }

  // 포커스 in
  focusIn() {
    this.cctv.ptzFocusIn();
  }

  // 포커스 out
  focusOut() {
    this.cctv.ptzFocusOut();
  }

  // 포커스 stop
  focusStop() {
    this.cctv.ptzFocusStop();
  }

  // 새로고침(다시 재생)
  async reconnectOne(nvr: Nvr) {
    const szDeviceIdentify = `${nvr.ip}_${nvr.pPort}`;
    const res = await this.cctv.reconnect(szDeviceIdentify);
    return res;
  }

  // 녹화시작
  async recordWithIndex(wndIndex: number) {
    await this.cctv.clickStartRecord(wndIndex).then(() => {
      this.cameraStatus[wndIndex] = { ...this.cameraStatus[wndIndex], isRecording: true };
    });
    return this.cameraStatus;
  }

  // 녹화정지
  async recordStopWithIndex(wndIndex: number) {
    await this.cctv.clickStopRecord(wndIndex).then(() => {
      this.cameraStatus[wndIndex] = { ...this.cameraStatus[wndIndex], isRecording: false };
    });
    return this.cameraStatus;
  }

  // 전체 녹화 시작
  async recordAll() {
    await Promise.all(
      this.cameraStatus.map(async (camera: CameraStatus, wndIndex: number) => {
        if (camera.ip && wndIndex < this.cameraStatus.length) {
          await this.cctv
            .clickStartRecordAll(wndIndex)
            .then((v: any) => {
              this.cameraStatus[wndIndex] = { ...camera, isRecording: true };
            })
            .catch((e: any) => {
              console.log(e);
              this.cameraStatus[wndIndex] = { ...camera, isRecording: false };
            });
        }
      })
    );
    return this.cameraStatus;
  }

  // 전체 녹화 정지
  async recordStopAll() {
    await Promise.all(
      this.cameraStatus.map(async (camera: CameraStatus, wndIndex: number) => {
        if (camera.ip && wndIndex < this.cameraStatus.length) {
          await this.cctv
            .clickStopRecordAll(wndIndex)
            .then((v: any) => {
              this.cameraStatus[wndIndex] = { ...camera, isRecording: false };
            })
            .catch((e: any) => {
              console.log(e);
              // this.cameraStatus[wndIndex] = { ...camera, isRecording: false };
            });
        }
      })
    );
    return this.cameraStatus;
  }

  // 캡쳐 하기
  async capture() {
    const result = await this.cctv.clickCapturePic();
    return result;
  }

  // 스트림 변경
  changeStream(camera: Camera, wndIndex: number, stream: string) {
    this.cctv.startRealPlay(camera.ip, stream, camera.channelNum, wndIndex, camera.port, this.pluginOBJ);
  }

  // 전체 스트림 변경
  async changeStreamAll(cameras: any[], stream: number) {
    await Promise.all(
      cameras.map(async (camera: any) => {
        const pageWndIndex: number = camera.windowIndex - (this.currentPage - 1) * this.cctvDivisions * this.cctvDivisions;
        await this.cctv.clickStopRealPlay(pageWndIndex);
        await this.cctv.startRealPlay(camera.ip, stream, camera.channelNum, pageWndIndex, camera.port, this.pluginOBJ, 'realtimeCCTV');
      })
    );
  }

  // 전체화면
  fullScreen() {
    this.cctv.clickFullScreen();
  }

  // 다운로드 경로 불러오기
  downloadPathSetting(setDownloadPath: Dispatch<SetStateAction<any>>) {
    return this.cctv.clickOpenFileDlg(setDownloadPath);
  }

  // 다운로드 경로 저장
  setLocalCfg({ capturePath, videoPath }: Setting) {
    this.cctv.clickSetLocalCfg(capturePath, videoPath);
  }

  // 해당 경로 폴더 열기
  openDirectory(openPath?: string) {
    this.cctv.clickOpenDirectory(openPath);
  }

  // 윈도우 리사이즈
  async resizeWnd(width: number, height: number) {
    await this.cctv.resizeWindow(width, height);
  }

  // -----------------------------------------------------------------------------------------
  // ------------------------------------ CCTV 재생 메서드 ------------------------------------
  // -----------------------------------------------------------------------------------------

  // 녹화 리스트 조회
  async recordSearch(camera: Camera, stream: number, startTime: string, endTime: string): Promise<Record[]> {
    const { ip, channelNum, pPort } = camera;
    const szDeviceIdentify = `${ip}_${pPort}`;
    const result = await this.cctv.clickRecordSearch(szDeviceIdentify, stream, channelNum, startTime, endTime);
    return result;
  }

  // 녹화된 영상 재생
  async startPlayback(camera: Camera, stream: number, startTime: string, endTime: string, wndIndex: number) {
    const { ip, channelNum, port } = camera;

    await this.cctv
      .clickStartPlayback(ip, port, stream, channelNum, startTime, endTime, wndIndex)
      .then(() => {
        this.cameraStatus[wndIndex] = {
          ...camera,
          isPlaying: true,
          isRecording: false,
          isPausing: false,
          windowIndex: wndIndex,
          is3DZoomOn: false,
          isDigitalZoomOn: false,
          isFail: false,
          playbackSpeed: 1,
        };
      })
      .catch(() => {
        this.cameraStatus[wndIndex] = {
          ...camera,
          isPlaying: true,
          isRecording: false,
          isPausing: false,
          windowIndex: wndIndex,
          is3DZoomOn: false,
          isDigitalZoomOn: false,
          isFail: true,
          playbackSpeed: 1,
        };
      });
    return this.cameraStatus;
  }

  // 녹화된 영상 리버스 재생
  async startReversePlayback(camera: Camera, stream: number, startTime: string, endTime: string, wndIndex: number) {
    const { ip, channelNum, port } = camera;

    await this.cctv
      .clickReversePlayback(ip, port, stream, channelNum, startTime, endTime, wndIndex)
      .then(() => {
        this.cameraStatus[wndIndex] = {
          ...camera,
          isPlaying: true,
          isRecording: false,
          isPausing: false,
          windowIndex: wndIndex,
          is3DZoomOn: false,
          isDigitalZoomOn: false,
          isFail: false,
          playbackSpeed: 1,
        };
      })
      .catch(() => {
        this.cameraStatus[wndIndex] = {
          ...camera,
          isPlaying: true,
          isRecording: false,
          isPausing: false,
          windowIndex: wndIndex,
          is3DZoomOn: false,
          isDigitalZoomOn: false,
          isFail: true,
          playbackSpeed: 1,
        };
      });
    return this.cameraStatus;
  }

  // 일시 정지
  async pause(wndIndex: number) {
    await this.cctv
      .clickPause(wndIndex)
      .then(() => {
        this.cameraStatus[wndIndex] = { ...this.cameraStatus[wndIndex], isPlaying: false, isPausing: true };
      })
      .catch(() => {});
    return this.cameraStatus;
  }

  // 다시 시작
  async resume(wndIndex: number) {
    await this.cctv
      .clickResume(wndIndex)
      .then(() => {
        this.cameraStatus[wndIndex] = { ...this.cameraStatus[wndIndex], isPlaying: true, isPausing: false };
      })
      .catch(() => {});
    return this.cameraStatus;
  }

  // 재생 속도 빠르게
  async playFast(wndIndex: number) {
    let result = true;
    await this.cctv
      .clickPlayFast(wndIndex)
      .then(() => {
        result = true;
        this.cameraStatus[wndIndex] = { ...this.cameraStatus[wndIndex], playbackSpeed: this.cameraStatus[wndIndex].playbackSpeed * 2 };
      })
      .catch(() => {
        result = false;
      });
    return { success: result, data: this.cameraStatus[wndIndex].playbackSpeed };
  }

  // 재생 속도 느리게
  async playSlow(wndIndex: number) {
    let result = true;
    await this.cctv
      .clickPlaySlow(wndIndex)
      .then(() => {
        result = true;
        this.cameraStatus[wndIndex] = { ...this.cameraStatus[wndIndex], playbackSpeed: this.cameraStatus[wndIndex].playbackSpeed / 2 };
      })
      .catch(() => {
        result = false;
      });
    return { success: result, data: this.cameraStatus[wndIndex].playbackSpeed };
  }

  // 녹화된 영상 다운로드
  async startDownloadRecord(camera: Camera, szPlaybackURI: string, setProgress: Dispatch<SetStateAction<number>>) {
    await this.stopDownloadRecord();

    const szDeviceIdentify = `${camera.ip}_${camera.pPort}`;
    const szFileName = `${szDeviceIdentify}_${camera.channelNum}_${new Date().getTime()}`;
    await this.webVideoCtrl.I_StartDownloadRecord(szDeviceIdentify, szPlaybackURI, szFileName);

    this.getDownloadProgress(setProgress);
  }

  // 녹화된 영상 다운로드 정지
  async stopDownloadRecord() {
    try {
      clearInterval(this.progressInterval);
      await this.webVideoCtrl.I_StopDownloadRecord();
    } catch (error) {
      console.log('다운로드 중이 아님');
    }
  }

  // 녹화된 영상 다운로드 진행도
  async getDownloadProgress(setProgress: Dispatch<SetStateAction<number>>) {
    this.progressInterval = setInterval(async () => {
      const progress = Number(await this.webVideoCtrl.I_GetDownloadProgress());
      setProgress(progress);
      if (progress === 100) {
        clearInterval(this.progressInterval);
        this.webVideoCtrl.I_StopDownloadRecord();
      }
    }, 1000);
  }
}
