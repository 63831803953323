/**
 * 작성자 : 홍선영
 * 날짜 : 2023.12.21
 * 기능 : 터널 관리 > 굴진/일반 정보 > 굴진/일반 정보 팝업 > 근로자 출역 정보 & 실시간 잔류 현황 (현장용)
 */

import { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PulseLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { IUser } from '../atoms';
import { IComCdList } from 'customTypes';
import { LIST_COUNT, TAREA_INNERTAB_ATTEND, TAREA_INNERTAB_REALTIME_ATTEND } from '../_constants';
import i18n from '../translation/i18n';
import { useGetFrozenCount } from '../utils/getFrozenCount';
import { setComCdListState } from '../utils/setComCdListState';
import { formatDateToStrBar, todayYYYYMMDD } from '../utils/formatDate';
import useOnKeydownF9 from '../utils/useOnKeydownF9';
import useSetListCount from '../utils/useSetListCount';
import { BtnBlue, BtnGhost } from './Button';
import TuiGrid from './Table/TuiGrid';
import SelectBox from './SelectBox';
import TuiGridWrapper from './Table/TuiGridWrapper';
import RangePicker, { onChangeRangeInput } from './RangePicker';
import { apiGet } from '../services/_common';
import { InputTable } from '../assets/styles/InputTable';
import { SearchOptions } from '../assets/styles/SearchOptions';
import { LoadingModalBackground } from '../assets/styles/Modal';
import IssueGuide from './IssueGuide';

interface ITareaAttendInfo {
  innerTabIndex: number;
  selectedIndex: number;
  data: TareaData;
  userInfo: IUser;
  onClickPrev: () => void;
  onClickNext: () => void;
  showPrevNextBtn: boolean;
}

// 근로자 출역 정보 & 실시간 잔류 현황 탭
export const TareaAttendInfo = ({ innerTabIndex, selectedIndex, data, userInfo, onClickPrev, onClickNext, showPrevNextBtn }: ITareaAttendInfo) => {
  const { t } = useTranslation();
  const toDay = todayYYYYMMDD();
  const frozenCount = useGetFrozenCount();
  const componentRef = useRef<HTMLDivElement>(null);
  const { listCount, setListCount } = useSetListCount('15'); // 검색줄수설정 훅
  const [visibleRangePicker, setVisibleRangePicker] = useState(false);
  const [pickerDateRange, setPickerDateRange] = useState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
  const [userInputDateRange, setUserInputDateRange] = useState({ start: toDay, end: toDay });
  const [columns, setColumns] = useState<any[] | null>(null);
  const [filteredAttendList, setFilteredAttendList] = useState<any[]>([]);
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  const [tatFidCd, setTatFidCd] = useState<string | null>(('tatFidCd' in data && data.tatFidCd) || null);
  const [listCountComCdList, setListCountComCdList] = useState<IComCdList[]>([]); // 검색줄수 공통코드
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const currentDate = new Date(); // 실시간 잔류현황의 경우 조회일자 어제~오늘로 고정, 날짜수정불가
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);
  const isTunnelAttendTab = innerTabIndex === TAREA_INNERTAB_ATTEND;
  const isRealtimeAttendTab = innerTabIndex === TAREA_INNERTAB_REALTIME_ATTEND;

  const {
    data: tunnelAttendList,
    isFetching: isFetchingTunnelAttend,
    isRefetching: isRefetchingTunnelAttend,
    refetch: refetchTunnelAttend,
  } = useQuery(['tAreaAttendDayGet', userInfo.hCd, userInfo.sCd, tatFidCd], () => fetchAttendData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && !!tatFidCd && isTunnelAttendTab,
  });

  const fetchAttendData = async () => {
    try {
      const req = {
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        aDate1: userInputDateRange.start.replaceAll('-', ''),
        aDate2: userInputDateRange.end.replaceAll('-', ''),
        fLocation: tatFidCd,
        tatCd: data.tatCd,
      };
      const res = await apiGet({ path: '/attend/day', req });
      const attendList = res.data.data?.attendList || [];
      return attendList;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  const {
    data: realtimeAttendList,
    isFetching: isFetchingRealtime,
    isRefetching: isRefetchingRealtime,
    refetch: refetchRealtime,
  } = useQuery(['tAreaRealtimeAttendGet', userInfo.hCd, userInfo.sCd, tatFidCd], () => fetchRealtimeAttendData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && !!tatFidCd && isRealtimeAttendTab,
  });

  const fetchRealtimeAttendData = async () => {
    try {
      const req = {
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        tatCd: data.tatCd,
      };
      const res = await apiGet({ path: '/attend/realtime', req });
      const attendList = res.data.data?.attendList || [];
      return attendList;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  useEffect(() => {
    if ('tatFidCd' in data) setTatFidCd(data.tatFidCd);
    else setTatFidCd(null);
  }, [data]);

  const onClickSearch = () => {
    if (tatFidCd && isTunnelAttendTab) refetchTunnelAttend();
    if (isRealtimeAttendTab) refetchRealtime();
  };

  useEffect(() => {
    setComCdListState(LIST_COUNT, setListCountComCdList, false);
  }, []);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    const prejobtypeColumn = {
      header: t('공종'),
      name: 'wPrejobtypeName',
      align: 'left',
      sortable: true,
      minWidth: 120,
    };

    if (isTunnelAttendTab) {
      setColumns([
        {
          header: t('근로자명'),
          name: 'wName',
          align: 'left',
          sortable: true,
          width: 150,
          renderer: { classNames: ['text_secondary'] },
        },
        {
          header: t('협력업체'),
          name: 'sjName',
          align: 'left',
          sortable: true,
          filter: 'select',
          width: 200,
          renderer: { classNames: ['text_secondary'] },
        },
        userInfo.prejobtypeYn === 'Y' && prejobtypeColumn,
        {
          header: t('직종'),
          name: 'wJobtypeName',
          align: 'left',
          sortable: true,
          minWidth: 120,
        },
        {
          header: t('취업일자'),
          name: 'wJobdate',
          align: 'center',
          sortable: true,
          minWidth: 120,
        },
        {
          header: t('출역일자'),
          name: 'aDate',
          align: 'center',
          sortable: true,
          filter: 'select',
          minWidth: 120,
          renderer: { classNames: ['text_secondary'] },
        },
        {
          header: t('위치명'),
          name: 'fLocationName',
          align: 'center',
          sortable: true,
          minWidth: 120,
        },
        {
          header: t('최초시간'),
          name: 'aTimeMin',
          align: 'center',
          sortable: true,
          minWidth: 120,
          renderer: { classNames: ['text_secondary'] },
        },
        {
          header: t('최종시간'),
          name: 'aTimeMax',
          align: 'center',
          sortable: true,
          minWidth: 120,
          renderer: { classNames: ['text_secondary'] },
        },
        {
          header: t('최초체온'),
          name: 'tempMin',
          align: 'center',
          sortable: true,
          minWidth: 100,
        },
        {
          header: t('최종체온'),
          name: 'tempMax',
          align: 'center',
          sortable: true,
          minWidth: 100,
        },
      ]);
    }

    if (isRealtimeAttendTab) {
      setColumns([
        {
          header: t('근로자명'),
          name: 'wName',
          align: 'left',
          sortable: true,
          width: 120,
          renderer: { classNames: ['text_secondary'] },
        },
        {
          header: t('협력업체'),
          name: 'sjName',
          align: 'left',
          sortable: true,
          filter: 'select',
          width: 140,
          renderer: { classNames: ['text_secondary'] },
        },
        userInfo.prejobtypeYn === 'Y' && prejobtypeColumn,
        {
          header: t('직종'),
          name: 'wJobtypeName',
          align: 'left',
          sortable: true,
          minWidth: 120,
        },
        {
          header: t('취업일자'),
          name: 'wJobdate',
          align: 'center',
          sortable: true,
          minWidth: 120,
        },
        {
          header: t('위치명'),
          name: 'fLocationName',
          align: 'center',
          sortable: true,
          minWidth: 120,
        },
        {
          header: t('출입시간'),
          name: 'aTimeMax',
          align: 'center',
          sortable: true,
          minWidth: 120,
          renderer: { classNames: ['text_secondary'] },
        },
      ]);
    }
  }, [userInfo.prejobtypeYn, i18n.language, innerTabIndex]);

  const onClickRangeInput = () => {
    if (!visibleRangePicker) setVisibleRangePicker(true);
  };

  useEffect(() => {
    if (pickerDateRange[0].startDate) {
      setUserInputDateRange((prev: any) => ({ ...prev, start: dayjs(pickerDateRange[0].startDate).format('YYYY-MM-DD') }));
    }
    if (pickerDateRange[0].endDate) {
      setUserInputDateRange((prev: any) => ({ ...prev, end: dayjs(pickerDateRange[0].endDate).format('YYYY-MM-DD') }));
    }
  }, [pickerDateRange[0].startDate, pickerDateRange[0].endDate]);

  useEffect(() => {
    setPickerDateRange([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
  }, [selectedIndex]);

  const dataToRender = isTunnelAttendTab ? tunnelAttendList : realtimeAttendList;
  const hasDataForTab = isTunnelAttendTab ? !!tunnelAttendList : !!realtimeAttendList;
  const isLoading = !columns || isFetchingTunnelAttend || isRefetchingTunnelAttend || isFetchingRealtime || isRefetchingRealtime;
  const shouldRenderData = !isLoading && hasDataForTab;

  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight, componentRef.current?.offsetWidth, shouldRenderData]);

  return (
    <>
      <div className='inputForm-body attendList'>
        <InputTable>
          <div className='modalRibbon' style={{ zIndex: '1' }}>
            <div>
              <span>
                {t('구역 명')}
                <span>
                  {data.tatName} ({data.tatCd})
                </span>
              </span>
            </div>
            <SearchOptions style={{ width: 'fit-content', padding: '0' }}>
              <div className='inputsWrapper'>
                <div className='secondSearchOption'>
                  <div className='flex-basic textBtnGroup'>
                    <div className='searchResult'>
                      {t('총')}
                      <span>{dataToRender?.length || 0}</span>
                      {t('개')}
                    </div>
                    <div className='inputForm-row'>
                      <div className='inputForm-col withLabelComCf flexRow'>
                        <label htmlFor='useYn'>{t('보기 설정')}</label>
                        <SelectBox
                          options={listCountComCdList}
                          defaultOption={listCount[LIST_COUNT]}
                          state={listCount}
                          setState={setListCount}
                          stateKey={LIST_COUNT}
                          initiateKey={listCount[LIST_COUNT]}
                          // setTableLinesAPI={patchUserMenuAPI}
                          optionHeight='height-sm'
                          rsearch
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SearchOptions>
            <div className='inputCalendar'>
              <span>{t('조회 일자')}</span>
              <input
                id='startInput'
                type='text'
                value={isTunnelAttendTab ? userInputDateRange.start : formatDateToStrBar(yesterday)}
                onMouseDown={onClickRangeInput}
                onChange={(e) => onChangeRangeInput(e, 'start', setPickerDateRange, setUserInputDateRange)}
                disabled={isRealtimeAttendTab}
              />
              <span>-</span>
              <input
                id='endInput'
                type='text'
                value={isTunnelAttendTab ? userInputDateRange.end : formatDateToStrBar(currentDate)}
                onMouseDown={onClickRangeInput}
                onChange={(e) => onChangeRangeInput(e, 'end', setPickerDateRange, setUserInputDateRange)}
                disabled={isRealtimeAttendTab}
              />
              <BtnGhost className='searchBtn' onClick={onClickSearch}>
                {t('검색')}
                <span className='shortcut-f9'>F9</span>
              </BtnGhost>
            </div>
          </div>
          {visibleRangePicker && (
            <div className='rangePickerWrapper'>
              <RangePicker state={pickerDateRange} setState={setPickerDateRange} setVisible={setVisibleRangePicker} />
            </div>
          )}
          {isLoading ? (
            <LoadingModalBackground>
              <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' style={{ width: 'inherit', height: 'inherit' }} />
            </LoadingModalBackground>
          ) : shouldRenderData ? (
            <TuiGridWrapper componentRef={componentRef}>
              <TuiGrid
                data={dataToRender}
                filterTableState={filteredAttendList}
                setFilterTableState={setFilteredAttendList}
                columns={columns}
                perPage={Number(listCount[LIST_COUNT])}
                usePagenation
                scrollX
                height={tuiHeight}
                frozenCount={frozenCount}
                eListYn='Y'
                modal
              />
            </TuiGridWrapper>
          ) : (
            <IssueGuide />
          )}
        </InputTable>
      </div>
      {showPrevNextBtn ? (
        <div className='modal-footer flex-between'>
          <div className='flex-basic arrows'>
            <BtnBlue onClick={onClickPrev}>
              <span className='material-symbols-rounded'>arrow_back_ios_new</span>
              <p>{t('이전')}</p>
              <div>
                <p>Page</p>
                <p>Up</p>
              </div>
            </BtnBlue>
            <BtnBlue onClick={onClickNext}>
              <div>
                <p>Page</p>
                <p>Down</p>
              </div>
              <p>{t('다음')}</p>
              <span className='material-symbols-rounded'>arrow_forward_ios</span>
            </BtnBlue>
          </div>
        </div>
      ) : undefined}
    </>
  );
};
