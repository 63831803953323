/**
 * 작성자 : 한영광
 * 날짜 : 2023.05.22
 * 기능 : 주소검색 버튼 클릭 시 daum 주소검색 팝업 띄워주는 컴포넌트
 */
import { Dispatch, SetStateAction } from 'react';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import { useTranslation } from 'react-i18next';
import getAddressInfo from '../utils/getAddressInfo';
import { BtnGray } from './Button';

interface IAddressSearch {
  setState: Dispatch<SetStateAction<any>>;
  disabled?: boolean;
}

export const AddressSearch = ({ setState, disabled }: IAddressSearch) => {
  const { t } = useTranslation();
  const open = useDaumPostcodePopup();
  const options = {
    width: '500px',
    height: '420px',
    top: 200,
    left: 700,
  };

  const handleComplete = async (data: any) => {
    const { address, zonecode } = data;
    const { latitude, longitude } = await getAddressInfo(address);
    setState((prev: any) => ({ ...prev, address1: address, postNum: zonecode, latitude, longitude }));
  };

  const handleClick = () => {
    open({ onComplete: handleComplete, ...options });
  };

  return (
    <BtnGray type='button' onClick={handleClick} disabled={disabled}>
      {t('주소검색')}
    </BtnGray>
  );
};
