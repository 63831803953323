/**
 * 작성자 : 홍선영
 * 날짜 : 2024.01.17
 * 경로 : 설정관리-알림톡 관리 페이지 (현장관리자)
 */

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useOutletContext } from 'react-router-dom';
import { IUser, pageInfoState, userState } from '../../atoms';

import { ITabObject } from 'customTypes';
import Tab from '../../components/Tab';
import EmergencyList from './setting11/EmergencyList';
import Allimtalk from './setting11/Allimtalk';
import { logPost } from '../../services/log';
import TabletTalk from './setting11/TabletTalk';

const SMSetting11 = () => {
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const userInfo = useRecoilValue<IUser>(userState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  const { userMenuList } = userInfo;
  const [newTabList, setNewTabList] = useState<ITabObject[]>();
  const TU_REQ_PARAM = { tuGubun: '01' }; // 긴급 상황 목록 요청 파라미터
  const ES_REQ_PARAM = { tuGubun: '02' }; // 비콘 호출 요청 라미터
  const TU_ALLIMTALK_REQ_PARAM = { tsGubun: '01' }; // 긴급 상황 알림톡 요청 파라미터
  const ES_ALLIMTALK_REQ_PARAM = { tsGubun: '02' }; // 비콘 호출 알림톡 요청 파라미터

  useEffect(() => {
    const arr: any[] = [];
    userMenuList.map((v: any) => {
      const result = tabInfo?.tabList?.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  const onClickTab = (mrCd: string) => {
    setCurrentTabMrCd(mrCd);
  };

  const renderPage = () => {
    const activeTab = userMenuList.find((el: ITabObject) => el.mrCd === currentTabMrCd);
    if (activeTab !== undefined) {
      const { mrCd } = activeTab;
      switch (mrCd) {
        case '001':
          return <EmergencyList prop={TU_REQ_PARAM} />;
        case '002':
          return <EmergencyList prop={ES_REQ_PARAM} />;
        case '003':
          return <Allimtalk prop={TU_ALLIMTALK_REQ_PARAM} />;
        case '004':
          return <Allimtalk prop={ES_ALLIMTALK_REQ_PARAM} />;
        case '005':
          return <TabletTalk />;
      }
    }
    return undefined;
  };

  return (
    <div className='contents'>
      <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      {renderPage()}
    </div>
  );
};

export default SMSetting11;
