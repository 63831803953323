import { t } from 'i18next';
import { useEffect } from 'react';
import notFound from '../assets/images/illustration/notFound.svg';
import itlog from '../assets/images/logo/itlog.svg';
import styled from 'styled-components';
import { BtnBlue } from '../components/Button';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 5%;
  overflow: auto;

  .logoWrapper {
    align-self: end;
  }

  > div > img {
    width: 8rem;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    > * {
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    }

    h2 {
      padding: 1rem 0;
    }

    h5 {
      letter-spacing: -0.75px;
    }

    .title {
      font-size: 5rem;
      padding: 2rem 0;
    }

    .btnWrapper {
      padding: 2rem 0;
    }
  }

  .illust {
    > img {
      width: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    > div > img {
      width: 5rem;
    }

    .content {
      height: fit-content;
      h2 {
        font-size: 1.2rem;
      }
      h5 {
        font-size: 1rem;
        line-height: 1.5;
      }
      .text {
        order: 1;
      }

      .btnWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

const NotFound = () => {
  // useEffect(() => {
  //   alert(t('존재하지 않는 페이지입니다'));
  //   window.location.replace('/');
  // }, []);

  return (
    <Root>
      <div className='logoWrapper'>
        <img src={itlog} alt='itlog logo' />
      </div>
      <div className='content'>
        <div className='text'>
          <h2>{t('요청하신 페이지를 찾을 수 없습니다.')}</h2>
          <h5>{t('찾으려는 페이지의 주소가 잘못 입력되었거나,')}</h5>
          <h5>{t('주소의 변경 혹은 삭제로 인해 사용하실 수 없습니다.')}</h5>
          <h5>{t('입력하신 페이지의 주소가 정확한 지 다시 한번 확인해 주세요.')}</h5>
          <div className='btnWrapper'>
            <BtnBlue onClick={() => window.location.replace('/')}>{t('메인으로')}</BtnBlue>
          </div>
        </div>
        <div className='illust'>
          <img src={notFound} alt='not found' />
        </div>
      </div>
    </Root>
  );
};

export default NotFound;
