import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSetAuth } from '../../../../utils/useSetAuth';
import { useEffect, useState } from 'react';
import { findJobTypeName, flattenSubLists, useGetJobTypeLists } from '../../../../hooks/useJobType';
import { applyBorderStyle } from '../../../../utils/applyBorderStyle';
import ClickableContainer from '../../../s_cctv/S_cctv1/RealtimeCCTV/ClickableContainer';
import TableLamp from '../../../sm_setting/setting12/TableLamp';
import JobtypeChip from '../../../sm_setting/setting12/JobtypeChip';
import Portal from '../../../../components/Portal';
import JobTypeModal from '../../../sm_setting/setting12/JobTypeModal';
import { formatDateYMD, todayYYYYMMDD } from '../../../../utils/formatDate';
import SearchSelectBoxSm from '../../../../components/SearchSelectBoxSm';
import { useSetSjcd } from '../../../../utils/useSetSjcd';
import DatePickerComponent from '../../../../components/DatePicker';
import { PulseLoader } from 'react-spinners';
import { apiGet } from '../../../../services/_common';
import { userState } from '../../../../atoms';
import { useRecoilValue } from 'recoil';

const Root = styled.div`
  .button {
    flex-shrink: 0;
    user-select: none;
    cursor: pointer;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    padding: 0 0.5rem;
    margin: auto 0;
    .material-symbols-rounded {
      font-variation-settings: 'FILL' 1, 'wght' 400;
      font-size: 1.5rem;
    }
  }
  .info-button {
    cursor: default !important;
    height: 2rem !important;
    width: 2.5rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
    color: ${({ theme }: { theme: any }) => theme.filled_green} !important;
    .material-symbols-rounded {
      font-variation-settings: 'FILL' 0;
    }
    &:hover {
      outline: 0.25rem solid ${({ theme }: { theme: any }) => theme.filled_green};
    }
  }
  .jobtype-chip-container {
    height: 100%;
    height: 3.5rem !important;
    display: flex;
    align-items: stretch;
    gap: 0.5rem;
    .default-button {
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      color: ${({ theme }: { theme: any }) => theme.filled_slate_deep};
      &:hover {
        outline: 0.25rem solid ${({ theme }: { theme: any }) => theme.filled_slate};
        &:active {
          transform: scale(0.95);
        }
      }
    }
    .copy-button {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_violet};
      color: ${({ theme }: { theme: any }) => theme.filled_violet_deep};
      .material-symbols-rounded {
        font-size: 1.375rem;
      }
      &:hover {
        outline: 0.25rem solid ${({ theme }: { theme: any }) => theme.filled_violet};
        &:active {
          transform: scale(0.95);
        }
      }
    }
    .add-button {
      background-color: ${({ theme }: { theme: any }) => theme.filled_slate_deep};
      color: ${({ theme }: { theme: any }) => theme.board};
      &:hover {
        outline: 0.25rem solid ${({ theme }: { theme: any }) => theme.tonal_deep};
        &:active {
          transform: scale(0.95);
        }
      }
    }
    .delete-button {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
      color: ${({ theme }: { theme: any }) => theme.filled_red};
      &:hover {
        outline: 0.25rem solid ${({ theme }: { theme: any }) => theme.filled_red};
        &:active {
          transform: scale(0.95);
        }
      }
    }

    .scroll-container {
      padding-top: 0.75rem;
      padding-right: 0.5rem;
      flex-grow: 1;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
    }
    .checklist-container {
      flex-grow: 1;
      display: flex;
      align-items: center;
    }
    .checklist {
      margin: 0.5rem 0;
      height: 2rem;
      border-radius: 0.25rem;
      display: flex;
      align-items: center;
      gap: 0.25rem;
      background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
      padding-left: 0.375rem;
      padding-right: 1rem;
      .material-symbols-rounded {
        font-size: 1.375rem;
        color: ${({ theme }: { theme: any }) => theme.filled_green};
        display: flex;
        align-items: center;
        justify-content: center;
        height: 1.75rem;
        width: 1.75rem;
        border-radius: 0.25rem;
      }
      .description {
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.filled_green_deep};
      }
    }
  }
  .detail-info {
    display: flex;
    gap: 1rem;
    .material-symbols-rounded {
      margin: 0 0.25rem;
      font-variation-settings: 'FILL' 1, 'wght' 400;
      font-size: 1.5rem;
      padding: 0.25rem;
      padding-left: 0.375rem;
      padding-right: 0.125rem;
      border-radius: 2rem;
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      color: ${({ theme }: { theme: any }) => theme.filled_slate};
    }
    .separator {
      height: 1.25rem;
      border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    }
    .container {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .label {
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.filled_slate_deep};
        background-color: ${({ theme }: { theme: any }) => theme.board};
        border-radius: 0.25rem;
        padding: 0.125rem 0.5rem;
      }
      .writer,
      .editor {
        font-size: 0.875rem;
        padding: 0 0.75rem;
        border-radius: 1rem;
        color: ${({ theme }: { theme: any }) => theme.filled_green_deep};
        background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
      }
      .wDate,
      .eDate {
        color: ${({ theme }: { theme: any }) => theme.filled_green_deep};
      }
    }
  }
  .overflow-scroll {
    overflow-y: hidden;
    overflow-x: scroll;
    white-space: nowrap;
    padding-top: 0.75rem !important;
  }
`;

interface Props {
  rowData: any;
  rowIndex: number;
  setTableState: any;
  onClickDeleteRow: any;
  isSaveClicked: boolean;
  originalData?: any;
  prejobtypeYn: 'Y' | 'N';
  setNewTableState?: any;
}

const ScheduleSettingTableRow = ({ rowData, rowIndex, setTableState, onClickDeleteRow, isSaveClicked, originalData, prejobtypeYn, setNewTableState }: Props) => {
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const userInfo = useRecoilValue(userState);
  const { hCd, sCd } = userInfo;

  const isNew = rowData.sjsIdx === null;
  const initJobtypeIdx =
    !isNew || rowData.jobtypeIdx !== '' //
      ? rowData.jobtypeIdx
      : '';
  const [jobtypeIdx, setJobtypeIdx] = useState<string>(initJobtypeIdx);
  const [openModal, setOpenModal] = useState<{ status: boolean; type: string }>({
    status: false,
    type: 'jobtype',
  });

  const { data: jobTypeLists, isLoading, isError } = useGetJobTypeLists(prejobtypeYn as 'Y' | 'N');
  const [flattenSubList, setFlattenSubList] = useState<JobtypeList[]>([]);
  const flattenList = prejobtypeYn === 'Y' ? flattenSubList : jobTypeLists;

  const toDay = todayYYYYMMDD();
  const [date, setDate] = useState(
    rowData.sjsIdx !== null //
      ? rowData.sjsDate
      : toDay
  );
  const [sjCd, setSjCd] = useState({ type: 'sjCd', sjCd: rowData.sjCd, cdName: rowData.sjName });
  const [useYn, setUseYn] = useState(rowData.useYn === 'Y');
  const { siteJoinInfoList } = useSetSjcd();

  const [visibleInfo, setVisibleInfo] = useState(false);
  const [loading, setLoading] = useState(true);
  const [copy, setCopy] = useState(rowData.copy);

  useEffect(() => {
    if (jobTypeLists && prejobtypeYn === 'Y') {
      setFlattenSubList(flattenSubLists(jobTypeLists));
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } else {
      setLoading(false);
    }
  }, [jobTypeLists, prejobtypeYn]);

  useEffect(() => {
    setTableState((prev: any[]) => {
      // 배열의 특정 요소를 불변성을 유지하며 업데이트
      const updatedArray = prev.map((item, index) => {
        if (index === rowIndex) {
          return { ...item, jobtypeIdx };
        }
        return item;
      });
      return updatedArray;
    });
  }, [jobtypeIdx, rowIndex]); // rowIndex도 의존성 배열에 추가

  useEffect(() => {
    setTableState((prev: any[]) => {
      const updatedArray = prev.map((item, index) => {
        if (index === rowIndex) {
          return { ...item, sjCd: sjCd.sjCd, sjName: sjCd.cdName };
        }
        return item;
      });
      return updatedArray;
    });
    if (rowData.sjsIdx === null && sjCd.sjCd !== '') {
      getSiteJoinInfo(sjCd.sjCd).then((responseData) => {
        if (!copy) {
          setJobtypeIdx(responseData);
        } else {
          setCopy(false);
        }
      });
    }
    if (rowData.sjsIdx === null && sjCd.sjCd === '') {
      setJobtypeIdx('');
    }
  }, [sjCd, rowIndex]);

  useEffect(() => {
    setTableState((prev: any[]) => {
      const updatedArray = prev.map((item, index) => {
        if (index === rowIndex) {
          return { ...item, useYn: useYn ? 'Y' : 'N' };
        }
        return item;
      });
      return updatedArray;
    });
  }, [useYn, rowIndex, setTableState]);

  useEffect(() => {
    if (date !== null) {
      setTableState((prev: any[]) => {
        const updatedArray = prev.map((item, index) => {
          if (index === rowIndex) {
            return { ...item, sjsDate: date };
          }
          return item;
        });
        return updatedArray;
      });
    }
  }, [date, rowIndex]);

  const removeJobtype = (item: string) => {
    // 문자열을 배열로 변환
    const array = jobtypeIdx.split(',');
    // 배열에서 특정 요소 제거
    const filteredArray = array.filter((element) => element !== item);
    // 배열을 다시 문자열로 변환
    const resultString = filteredArray.join(',');

    setJobtypeIdx(resultString);
  };

  // 협력업체 값을 변경했을 때 변경한 협력업체의 직종값을 화면에 노출
  const getSiteJoinInfo = async (sjCdParam: string): Promise<string> => {
    const path = '/sitejoin/info';
    const req = { hCd, sCd, sjCd: sjCdParam };
    const res = await apiGet({ path, req });
    try {
      if (res.data.statusCode === 200) {
        const { jobtypeIdx: responseData } = res.data.data.siteJoinInfo;
        if (responseData) {
          return responseData;
        }
        return '';
      }
    } catch (error) {
      throw new Error('error');
    }
    return '';
  };

  if (siteJoinInfoList.length === 0) {
    return null;
  }

  return (
    <Root
      className='tr' //
      role='button'
      tabIndex={0}
    >
      <div className='trCol2p5 flex-center tableStickyNo'>{rowData.sjsIdx !== null ? rowIndex + 1 : '-'}</div>
      <div className={`trCol12p5 flex-basic ${rowData.sjsIdx === null ? 'overflow-visible' : 'overflow-scroll'}`}>
        {rowData.sjsIdx === null ? (
          <SearchSelectBoxSm
            options={siteJoinInfoList.map((item, i) => {
              if (i === 0) {
                return { ...item, cdName: t('미선택') };
              }
              return item;
            })}
            defaultOption={rowData.sjName}
            state={sjCd}
            setState={setSjCd}
            stateKey='sjCd'
            codeKey='cdName'
            optionHeight='height-base'
            comboWidth='expand-box-md'
            dropDownWidth='expand-content'
            getBorderStyle={isSaveClicked ? applyBorderStyle(sjCd.sjCd, 'red', 'sjCd') : undefined}
          />
        ) : (
          rowData.sjName
        )}
      </div>
      <div className='trCol7 flex-center datePickerWrapper'>
        {rowData.sjsIdx === null ? (
          <DatePickerComponent //
            startDate={date}
            setDate={setDate}
            popperPlacement='bottom'
            getBorderStyle={isSaveClicked ? applyBorderStyle(date, 'red', 'date') : undefined}
          />
        ) : (
          formatDateYMD(rowData.sjsDate)
        )}
      </div>
      <div className='trCol6 flex-center'>
        {loading ? (
          <PulseLoader color='rgb(0, 122, 255)' size='6px' />
        ) : (
          <ClickableContainer
            onClick={() => setUseYn((prevUseYn: boolean) => !prevUseYn)} //
            button={<TableLamp use={rowData.useYn === 'Y'} />}
          />
        )}
      </div>
      {visibleInfo ? (
        <div className='trCol12 flex-start content-overflow detail-info'>
          <span className='material-symbols-rounded'>how_to_reg</span>
          <div className='container'>
            <span className='label'>{t('등록')}</span>
            <span className='writer'>{rowData.writer}</span>
            <span className='wDate'>{rowData.wDate}</span>
          </div>
          <span className='separator' />
          <div className='container'>
            <span className='label'>{t('수정')}</span>
            <span className='editor'>{rowData.editor}</span>
            <span className='eDate'>{rowData.eDate}</span>
          </div>
        </div>
      ) : (
        <div className='trCol4 flex-start content-overflow scroll jobtype-chip-container'>
          <div //
            className='button add-button'
            onClick={() => setOpenModal({ status: true, type: 'jobtype' })}
            role='button'
            tabIndex={0}
          >
            <span className='material-symbols-rounded'>add</span>
          </div>
          {rowData.jobtypeIdx !== null && rowData.jobtypeIdx !== '' ? (
            <div className='scroll-container'>
              {loading ? (
                <PulseLoader color='rgb(0, 122, 255)' size='6px' />
              ) : (
                rowData.jobtypeIdx.split(',').map((selectedItem: string) => (
                  <JobtypeChip //
                    key={selectedItem}
                    selectedItem={selectedItem}
                    removeJobtype={removeJobtype}
                    name={findJobTypeName(selectedItem, flattenList) ?? selectedItem}
                  />
                ))
              )}
            </div>
          ) : (
            <div className='checklist-container'>
              <div className='checklist'>
                <span className='material-symbols-rounded'>check</span>
                <span className='description'>{t('다중선택 가능')}</span>
              </div>
            </div>
          )}
          {
            // jobtypeIdx가 ''(빈 문자열)이 아닌 경우 지우기 버튼 표시
            rowData.jobtypeIdx !== '' && (
              <div
                className='button default-button' //
                onClick={() => setJobtypeIdx('')}
                role='button'
                tabIndex={0}
              >
                <span className='material-symbols-rounded'>backspace</span>
              </div>
            )
          }
          {
            // jobtypeIdx가 변경된 경우에만 복원 버튼 표시
            // 원본 데이터의 jobtypeIdx가 ''(빈 문자열) 또는 null이 아니어야 함
            rowData.sjsIdx !== null && //
              originalData[rowIndex] && //
              rowData.jobtypeIdx !== originalData[rowIndex].jobtypeIdx && (
                <div
                  className='button default-button' //
                  onClick={() => setJobtypeIdx(originalData[rowIndex].jobtypeIdx || '')}
                  role='button'
                  tabIndex={0}
                >
                  <span className='material-symbols-rounded'>undo</span>
                </div>
              )
          }
          {rowData.sjsIdx !== null && ( //
            <div
              className='button copy-button' //
              onClick={() => {
                setNewTableState((prev: any[]) => {
                  return [
                    ...prev,
                    {
                      sjsIdx: null,
                      sjsDate: date,
                      sjCd: sjCd.sjCd,
                      sjName: sjCd.cdName,
                      jobtypeIdx,
                      useYn: useYn ? 'Y' : 'N',
                      copy: true,
                    },
                  ];
                });
              }}
              role='button'
              tabIndex={0}
            >
              <span className='material-symbols-rounded'>content_copy</span>
            </div>
          )}
          {auth.deleteAuth && (
            <div
              className='button delete-button' //
              onClick={onClickDeleteRow}
              role='button'
              tabIndex={0}
            >
              <span className='material-symbols-rounded'>delete</span>
            </div>
          )}
        </div>
      )}
      {rowData.sjsIdx !== null ? (
        <div
          className='button' //
          onMouseEnter={() => setVisibleInfo(true)}
          onMouseLeave={() => setVisibleInfo(false)}
          role='button'
          tabIndex={0}
        >
          <div
            className='button info-button' //
          >
            <span className='material-symbols-rounded'>info</span>
          </div>
        </div>
      ) : null}
      <Portal openModal={openModal?.status}>
        {openModal && openModal.type === 'jobtype' && (
          <JobTypeModal
            setSelectedProps={setJobtypeIdx} //
            setOpenModal={setOpenModal}
            selectedProps={jobtypeIdx}
          />
        )}
      </Portal>
    </Root>
  );
};

export default ScheduleSettingTableRow;
