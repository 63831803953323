/**
 * 작성자 : 홍선영
 * 날짜 : 2023.07.19
 * 기능 : 근로자 1명의 일별 출역리스트 모달
 */

import { Dispatch, SetStateAction, useState, useRef, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { userState } from '../../atoms';
import { Modal, ModalBackground } from '../../assets/styles/Modal';
import { setComCdListState } from '../../utils/setComCdListState';
import { LIST_COUNT } from '../../_constants';
import { IComCdList, IModal } from 'customTypes';
import { useReactToPrint } from 'react-to-print';
import TuiGrid from '../Table/TuiGrid';
import { arraySortByAscdOrder } from '../../utils/arraySortByAscdOrder';
import SelectBox from '../SelectBox';
import { useTranslation } from 'react-i18next';
import i18n from '../../translation/i18n';
import TuiGridWrapper from '../Table/TuiGridWrapper';
import { apiGet } from '../../services/_common';

interface ISearchOption {
  sjName: string;
  sjCd: string;
  startDate: string;
  endDate: string;
  wName?: string;
}

interface IProps {
  setOpenModal: Dispatch<SetStateAction<IModal>>;
  filter: ISearchOption;
}

const AttendDailyPersonalListModal = ({ setOpenModal, filter }: IProps) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const [searchOption, setSearchOption] = useState({ sjCd: '', wName: '' });
  const [dailyPersonalAttendState, setDailyPersonalAttendState] = useState([]);
  const { eListYn } = userInfo;
  const [siteJoinInfoList, setSiteJoinInfoList] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [listCountComCdList, setListCountComCdList] = useState<IComCdList[]>([]); // 검색줄수 공통코드
  const [listCount, setListCount] = useState({
    type: LIST_COUNT,
    [LIST_COUNT]: '15',
    cdName: '15',
  });
  const [firstApiReq, setFirstApiReq] = useState(false);
  const [faceSetList, setFaceSetList] = useState<any[]>([]);

  useEffect(() => {
    getFaceSetAPI();
    getSjCdAPI().then((res: any) => {
      if (res.status === 200) {
        setComCdListState(LIST_COUNT, setListCountComCdList, false);
        setListCount({ LIST_COUNT: '15', cdName: '15', type: 'LIST_COUNT' });
        setFirstApiReq(true);
      }
    });
  }, []);

  useEffect(() => {
    setColumns(
      userInfo.prejobtypeYn === 'Y'
        ? [
            {
              header: t('근로자명'),
              name: 'wName',
              sortable: true,
              minWidth: 130,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('협력업체'),
              name: 'sjName',
              sortable: true,
              filter: 'select',
              width: 200,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('공종'),
              name: 'wPrejobtypeName',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('직종'),
              name: 'wJobtypeName',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('취업일자'),
              name: 'wJobdate',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('생년월일'),
              name: 'wBdate',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('출역일자'),
              name: 'aDate',
              align: 'center',
              sortable: true,
              filter: 'select',
              minWidth: 120,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('출역시간'),
              name: 'aTime',
              align: 'center',
              sortable: true,
              minWidth: 120,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('장비명'),
              name: 'fName',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('출입위치'),
              name: 'fLocationName',
              sortable: true,
              filter: 'select',
              minWidth: 160,
            },
            {
              header: t('체온'),
              name: 'temp',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
          ]
        : [
            {
              header: t('근로자명'),
              name: 'wName',
              align: 'center',
              sortable: true,
            },
            {
              header: t('협력업체'),
              name: 'sjName',
              align: 'center',
              sortable: true,
            },

            {
              header: t('직종'),
              name: 'wJobtypeName',
              sortable: true,
              width: 200,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('취업일자'),
              name: 'wJobdate',
              sortable: true,
              minWidth: 150,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('생년월일'),
              name: 'wBdate',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('출역일자'),
              name: 'aDate',
              align: 'center',
              sortable: true,
              filter: 'select',
              minWidth: 120,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('출역시간'),
              name: 'aTime',
              align: 'center',
              sortable: true,
              minWidth: 120,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('장비명'),
              name: 'fName',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('출입위치'),
              name: 'fLocation',
              align: 'center',
              sortable: true,
              filter: 'select',
              minWidth: 120,
            },
            {
              header: t('체온'),
              name: 'temp',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
          ]
    );
  }, [userInfo.prejobtypeYn, i18n.language]);

  useEffect(() => {
    if (firstApiReq) {
      getAttendDetailAPI();
      setFirstApiReq(false);
    }
  }, [firstApiReq]);

  const onClickClose = () => {
    setOpenModal((prev) => ({ ...prev, status: false }));
  };

  // 협력업체 리스트 조회 API
  const getSjCdAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/siteJoin', req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      const newList = data.siteJoinList
        .filter((v1: any) => v1.useYn === 'Y')
        .map((v2: any) => {
          if (filter?.sjName === v2.sjName) {
            setSearchOption((prev: any) => ({ ...prev, sjCd: v2.sjCd }));
          }
          return {
            type: 'sjCd',
            sjCd: v2.sjCd,
            name: v2.sjName,
          };
        });
      setSiteJoinInfoList(
        data.siteJoinList.map((v2: any) => {
          if (filter?.sjName === v2.sjName) {
            setSearchOption((prev: any) => ({ ...prev, sjCd: v2.sjCd }));
          }
          return {
            type: 'sjCd',
            sjCd: v2.sjCd,
            name: v2.sjName,
          };
        })
      );
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  // 개인별 일 출역현황 조회 API
  const getAttendDetailAPI = async () => {
    const newSearchOption = { ...filter, aDate1: filter.startDate.replaceAll('-', ''), aDate2: filter.endDate.replaceAll('-', '') };
    const { sjName, startDate, endDate, ...finalSearchObj } = newSearchOption; // searchOption에서 start, end값 삭제
    const req = { ...finalSearchObj, hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: `/attend/dayDetail`, req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      if (eListYn === 'Y') {
        const arr = data.attendList.map((v: any) => {
          const item = faceSetList.find((v2: any) => v2.fNum === v.fNum);
          return {
            ...v,
            fName: item?.name,
            fLocationName: item?.fLocationName,
          };
        });
        setDailyPersonalAttendState(arr);
      } else {
        setDailyPersonalAttendState(data.attendList);
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 장비 리스트 조회 API (리스트)
  const getFaceSetAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/setting/faceSet', req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      const newArray: any = data.faceSet.map((el: any, i: number) => {
        return { type: 'fNum', fNum: el.fNum, name: el.fName, cdSort: i + 1, fLocation: el.fLocation, fLocationName: el.fLocationName, fInout: el.fInout };
      });
      const sortedArray: any = arraySortByAscdOrder(newArray, 'cdSort');
      setFaceSetList(sortedArray);
    } else {
      // toast.error(t(ERROR));
    }
  };

  const componentRef2 = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef2.current,
  });
  // 인쇄 버튼 클릭
  const onClickPrint = () => {
    handlePrint();
  };
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  useEffect(() => {
    if (componentRef2.current !== null) {
      setTuiHeight(componentRef2.current.offsetHeight);
    }
  }, [componentRef2.current?.offsetHeight, componentRef2.current?.offsetWidth]);
  return (
    <ModalBackground>
      <Modal>
        <div className='modal minHeight-609 dailyPersonalAttend'>
          <div className='inputForm-head'>
            <div className='modalHeaderTitle'>{t('개인별 일 출역현황')}</div>
            <div className='closeBtn' onClick={onClickClose} role='presentation'>
              <span className='material-symbols-rounded'>close</span>
            </div>
          </div>
          <div className='inputForm-body dailyPersonalAttend'>
            <div className='flex-between searchWrapper'>
              <div className='flex-basic view-option'>
                <label htmlFor='listCount'>{t('보기 설정')}</label>
                <SelectBox options={listCountComCdList} defaultOption={listCount.cdName} state={listCount} setState={setListCount} initiateKey={listCount.cdName} rsearch />
              </div>
              <div className='detail-info'>
                <div className='flex-basic'>
                  <label htmlFor='sjCd'>{t('협력업체')}</label>
                  <span className='seperator' />
                  <span className='data'>{filter.sjName}</span>
                </div>
                <div className='flex-basic'>
                  <label htmlFor='date'>{t('출역일자')}</label>
                  <span className='seperator' />
                  <div className='flex-basic'>
                    <span className='data'>{filter.startDate}</span>
                    <span className='wave'> ~ </span>
                    <span className='data'>{filter.endDate}</span>
                  </div>
                </div>
                {filter.wName && (
                  <div className='flex-basic'>
                    <label htmlFor='wName'>{t('근로자명')}</label>
                    <input type='text' value={filter.wName} maxLength={50} />
                  </div>
                )}
                {/* <BtnGray onClick={onClickPrint}>인쇄</BtnGray> */}
              </div>
            </div>
            <TuiGridWrapper componentRef={componentRef2} scrollYHidden>
              {/* <div className='tuiGridWrapper' ref={componentRef2}> */}
              <TuiGrid data={dailyPersonalAttendState} columns={columns} perPage={Number(listCount[LIST_COUNT])} usePagenation frozenCount={1} height={tuiHeight} eListYn={eListYn} />
            </TuiGridWrapper>
            {/* </div> */}
          </div>
        </div>
      </Modal>
    </ModalBackground>
  );
};

export default AttendDailyPersonalListModal;
