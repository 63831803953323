import styled from 'styled-components';
import illustrator from '../../../assets/images/illustration/74.svg';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import MultiVideoPlayer from '../../../components/MultipleVideoPlayer3';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isMobile } from '../../../utils/checkMobile';
import { IUser, asideFoldState, userState } from '../../../atoms';
import { logPost } from '../../../services/log';
import { apiGet } from '../../../services/_common';
import WebSdk from '../WebSdk';
import * as cctv from '../../../utils/cctv';

const Root = styled.div<any>`
  flex-direction: row;
  gap: 0.5rem;
  padding: 0 0.5rem;
  height: 100%;
  overflow: hidden;

  > div:nth-child(2) {
    flex-grow: 1;
  }

  .play-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    .material-symbols-rounded {
      font-size: 6rem;
      font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      opacity: 0.6;
    }
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
      .material-symbols-rounded {
        opacity: 1;
      }
    }
    &:active {
      .material-symbols-rounded {
        transform: scale(0.9);
      }
    }
    &.white {
      .material-symbols-rounded {
        color: white;
        opacity: 0.6;
      }
      &:hover {
        .material-symbols-rounded {
          opacity: 1;
        }
        background-color: rgba(255, 255, 255, 0.15);
      }
    }
  }

  .emptyData {
    width: 100%;
    height: calc(100% - 4rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: inherit;
      height: inherit;
      max-width: 20rem;
      max-height: 20rem;
      object-fit: contain;
      -webkit-user-drag: none;
    }
    .main_text {
      padding: 1rem 1.5rem;
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      color: ${({ theme }: { theme: any }) => theme.inverse_alpha_75};
      border-radius: 0.5rem;
      margin: 0 1rem;
      .font_semibold {
        margin-left: 0.25rem;
      }
      .seperator {
        margin: 0 0.5rem;
        border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline_em};
      }
    }
  }
`;

interface ICameraObject {
  wsNum: string;
  nName: string;
  cName: string;
}

type Props = {};

const SiteCCTV = ({}: Props) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const [foldMenu] = useRecoilState<boolean>(asideFoldState);
  const [currentPage, setCurrentPage] = useState(1);
  const mobile = isMobile();
  const [cctvDivisions, setCctvDivisions] = useState(16);
  const [cameraInfo, setCameraInfo] = useState<ICameraObject[]>([]);
  const [currentCameraInfo, setCurrentCameraInfo] = useState<ICameraObject[]>([]);
  const [webSdkState, setWebSdkState] = useState<boolean>(false);

  useEffect(() => {
    if (userInfo.cctvProtocol === '2') {
      getCameraListAPI().then((res: any) => {
        if (res.status === 200) {
          logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: '현장 CCTV 보기',
            action: '조회',
            etc: ``,
          });
        }
      });
    }
  }, [userInfo.cctvProtocol]);

  useEffect(() => {
    if (userInfo.cctvProtocol === '2') {
      // 현재 페이지 숫자, 화면 분할 값, 카메라정보 변화 시 currentCameraInfo setState
      const startIndex = (currentPage - 1) * cctvDivisions; // Calculate the starting index.
      const endIndex = startIndex + cctvDivisions; // Calculate the ending index.
      const currentPosts = cameraInfo.slice(startIndex, endIndex); // Get the posts for the current page.
      setCurrentCameraInfo(currentPosts);
    }
  }, [userInfo.cctvProtocol, currentPage, cctvDivisions, cameraInfo]);

  useEffect(() => {
    setCurrentPage(1);
  }, [cctvDivisions]);

  const getCameraListAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/cam/site', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      setCameraInfo(data.cameraList);
    } else {
      throw new Error('error');
    }
    return res;
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [cctvDivisions]);

  const onClickPlay = () => {
    setWebSdkState(true);
  };

  useEffect(() => {
    if (cctv.getPluginOBJECT()?.oPlugin) {
      cctv.destroy();
      setWebSdkState(false);
    }
  }, [foldMenu]);

  return (
    <div className='content-container'>
      <Root>
        {userInfo.cctvProtocol === '0' ? (
          <div className='emptyData'>
            <img src={illustrator} alt='' />
            <p className='main_text text-center'>{t('설정관리-일반관리 메뉴에서 CCTV 연결방식을 설정하세요')}</p>
          </div>
        ) : userInfo.cctvProtocol === '1' ? (
          mobile ? (
            <div className='emptyData'>
              <img src={illustrator} alt='' />
              <p className='main_text text-center'>{t('모바일 환경에서 지원되지 않는 화면입니다')}</p>
            </div>
          ) : webSdkState ? (
            <WebSdk currentPageNumber={currentPage} setCurrentPageNumber={setCurrentPage} usePulseloader />
          ) : (
            <div className='play-btn' onClick={onClickPlay} role='presentation'>
              <span className='material-symbols-rounded'>play_arrow</span>
              {/* <span className='material-symbols-rounded'>play_circle</span> */}
            </div>
          )
        ) : (
          <MultiVideoPlayer
            cctvDivisions={cctvDivisions}
            setCctvDivisions={setCctvDivisions}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            currentCameraInfo={currentCameraInfo as ICameraObject[]}
            totalCameraInfo={cameraInfo as ICameraObject[]}
            dashboard={false}
          />
        )}
      </Root>
    </div>
  );
};

export default SiteCCTV;
