/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.08
 * 경로 : 터널관리 - 구역 현황 관리 > 종합진행현황
 */

import { TunnelPointContainer } from '../../assets/styles/Tunnels';
import { useMemo } from 'react';
import { tunnelReverseState } from '../../atoms';
import { useRecoilValue } from 'recoil';
import TunnelChipView from './TunnelChipView';
import TunnelBigoView from './TunnelBigoView';
import TunnelChartProgressBar from '../TunnelChartProgressBar';
import TotalPointLength from './TotalLength';
import SingleTunnelPointDetail from './SingleTunnelPointDetail';

type Props = {
  detailData: any;
  masterData: MutateDataType;
  filter: TunnelFilterData[];
  dashboard?: boolean;
  transition?: boolean;
  autoPlay: boolean;
  selectedTatCd?: string;
  updatePlayNow?: () => void;
  onClick?: any;
  siteDashboard?: boolean;
};

const SingleTunnelPoint = ({ detailData, masterData, filter, dashboard = false, transition, autoPlay, selectedTatCd, updatePlayNow, onClick, siteDashboard = false }: Props) => {
  const isTunnelReversed = useRecoilValue(tunnelReverseState);

  // 터널 구분에 따라 데이터 필터링
  const filteredData = detailData.filter((element: any) => element.tatGubun === masterData.tatGubun);

  // 터널 구분에 따라 클래스 및 위치 지정
  const isStart = masterData?.tatGubun === 'S';
  const isReversed = !isTunnelReversed ? isStart : !isStart;

  // 필터 결과 가져오기 함수
  const filterResult = useMemo(() => {
    return ({ text, filterData }: { text: string; filterData: TunnelFilterData[] }) => {
      return filterData.find((obj) => obj.title === text)?.status;
    };
  }, [filteredData]);

  return (
    <TunnelPointContainer className={`single ${dashboard ? 'dashboard' : ''} `}>
      <TunnelChartProgressBar
        rlength={masterData?.rLength}
        length={masterData?.length}
        pointType={isStart ? 'start' : 'end'}
        showOption={masterData?.useYn}
        timeOut={10}
        pointView
        isTunnelReversed={isTunnelReversed}
      />
      <TotalPointLength length={masterData?.length} rLength={masterData?.rLength} reverse={isReversed} />
      {(filterResult({ text: 'mGas', filterData: filter }) || filterResult({ text: 'cctv', filterData: filter })) && (
        <div className={`capsule-group ${isStart ? 'reverse' : ''}`}>
          {masterData?.dvNo !== undefined && filterResult({ text: 'mGas', filterData: filter }) && (
            <TunnelChipView value={masterData?.dvNo !== 0 ? '○' : '-'} icon='sensors' size='x-small' disabled={masterData?.dvNo === 0} />
          )}
          {masterData?.cameraCount !== undefined && filterResult({ text: 'cctv', filterData: filter }) && (
            <TunnelChipView value={masterData?.cameraCount} icon='nest_cam_iq_outdoor' size='x-small' disabled={masterData?.cameraCount === '0'} />
          )}
        </div>
      )}
      <SingleTunnelPointDetail
        isStart={isReversed}
        transition={transition}
        filteredData={isStart ? filteredData.reverse() : filteredData}
        dashboard={dashboard}
        masterData={masterData}
        filter={filter}
        autoPlay={autoPlay}
        selectedTatCd={selectedTatCd}
        updatePlayNow={updatePlayNow}
        onClick={onClick}
        siteDashboard={siteDashboard}
      />
      <div className='bigo'>
        {/* 작업내용 */}
        {masterData?.tjcName !== undefined && <TunnelBigoView text={masterData.tjcName} textType='tjc' siteDashboard={siteDashboard} />}
        {/* 장비 상태 */}
        {masterData?.tesName !== undefined && <TunnelBigoView text={masterData.tesName} textType='tes' siteDashboard={siteDashboard} />}
      </div>
    </TunnelPointContainer>
  );
};

export default SingleTunnelPoint;
