import { useTranslation } from 'react-i18next';
import { todayYYYYMMDD } from '../../utils/formatDate';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PulseLoader } from 'react-spinners';
import LineChart from '../LineChart';
import { apiGet } from '../../services/_common';
import SelectBox from '../SelectBox';

// 측정기 차트
export const PmInfo = ({ name, userInfo, sensorCd }: any) => {
  const { t } = useTranslation();
  const toDay = todayYYYYMMDD();
  const [fNameList, setFnameList] = useState<any[]>([]);
  const [device, setDevice] = useState({ cdName: '', subCd: '' });
  const [datasets, setDatasets] = useState<any>({ labels: [], datasets: [] });
  const [defaultSeneor, setDefaultSeneor] = useState('');
  const [sensor, setSensor] = useState<string>(sensorCd);

  const sensorQuery = useQuery(['sensorCdGet', userInfo, sensor], () => apiGet({ path: '/sensor/detail', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, grCd: sensor } }), {});

  useEffect(() => {
    if (sensorQuery.isSuccess && sensorQuery.data.data.statusCode === 200) {
      const { sensorList } = sensorQuery.data.data.data;
      const newArray = sensorList.map((v: any) => {
        return {
          sDefault: v.sDefault,
          type: 'subCd',
          subCd: v.subCd,
          cdName: v.sName,
        };
      });
      setFnameList(newArray);
      const defaultSensorObj = newArray.find((el: any) => el.sDefault === 'Y');
      if (defaultSensorObj) {
        setDefaultSeneor(defaultSensorObj.cdName);
        setDevice({ subCd: defaultSensorObj.subCd, cdName: defaultSensorObj.cdName });
      }
    }
  }, [sensorQuery.isSuccess, sensorQuery.isRefetching]);

  // 미세먼지 센서 일별 조회 API
  const getPmSensorDayAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, dvNo: device.subCd, date: toDay, time: '1' };
    const res = await apiGet({ path: '/sensor/pmDay', req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      const sortedData = data.sort((a: any, b: any) => {
        return a.checkTime.localeCompare(b.checkTime);
      });

      const newDataset = {
        labels: sortedData.map((el: any) => el.checkTime.slice(-3)),
        datasets: [
          {
            label: 'PM2.5',
            data: sortedData.map((item: any) => Number(item.pm25)),
            borderColor: 'rgba(0, 119, 255, 0.5)',
            backgroundColor: (context: any) => {
              const { ctx } = context.chart;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, 'rgba(0, 119, 255, 0.2)');
              gradient.addColorStop(1, 'rgba(0, 119, 255, 0)');
              return gradient;
            },
            fill: true,
          },
          {
            label: 'PM10',
            data: sortedData.map((item: any) => Number(item.pm10)),
            borderColor: 'rgba(0, 221, 255, 0.5)',
            backgroundColor: (context: any) => {
              const { ctx } = context.chart;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, 'rgba(0, 221, 255, 0.2)');
              gradient.addColorStop(1, 'rgba(0, 221, 255, 0)');
              return gradient;
            },
            fill: true,
          },
        ],
      };
      setDatasets(newDataset);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 소음 센서 일별 조회 API
  const getSoundSensorDayAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, dvNo: device.subCd, date: toDay, time: '1' };
    const res = await apiGet({ path: '/sensor/soundDay', req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      const sortedData = data.sort((a: any, b: any) => {
        return a.checkTime.localeCompare(b.checkTime);
      });
      const newDataset = {
        labels: sortedData.map((el: any) => el.checkTime.slice(-3)),
        datasets: [
          {
            label: device.cdName,
            data: sortedData.map((item: any) => Number(item.sound)),
            borderColor: 'rgba(0, 119, 255, 0.5)',
            backgroundColor: (context: any) => {
              const { ctx } = context.chart;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, 'rgba(0, 119, 255, 0.2)');
              gradient.addColorStop(1, 'rgba(0, 119, 255, 0)');
              return gradient;
            },
            fill: true,
          },
        ],
      };
      setDatasets(newDataset);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 질소 센서 일별 조회 API
  const getNoxSensorDayAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, dvNo: device.subCd, date: toDay, time: '1' };
    const res = await apiGet({ path: '/sensor/noxDay', req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      const sortedData = data.sort((a: any, b: any) => {
        return a.checkTime.localeCompare(b.checkTime);
      });
      const newDataset = {
        labels: sortedData.map((el: any) => el.checkTime.slice(-3)),
        datasets: [
          {
            label: device.cdName,
            data: sortedData.map((item: any) => Number(item.nox)),
            borderColor: 'rgba(0, 0, 255, 0.5)',
            backgroundColor: (context: any) => {
              const { ctx } = context.chart;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, 'rgba(0, 0, 255, 0.2)');
              gradient.addColorStop(1, 'rgba(0, 0, 255, 0)');
              return gradient;
            },
            fill: true,
          },
        ],
      };
      setDatasets(newDataset);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 풍속 센서 일별 조회 API
  const getWindSensorDayAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, dvNo: device.subCd, date: toDay, time: '1' };
    const res = await apiGet({ path: '/sensor/windDay', req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      const sortedData = data.sort((a: any, b: any) => {
        return a.checkTime.localeCompare(b.checkTime);
      });
      const newDataset = {
        labels: sortedData.map((el: any) => el.checkTime.slice(-3)),
        datasets: [
          {
            label: device.cdName,
            data: sortedData.map((item: any) => Number(item.wind)),
            borderColor: 'rgba(150, 0, 255, 0.5)',
            backgroundColor: (context: any) => {
              const { ctx } = context.chart;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, 'rgba(150, 0, 255, 0.2)');
              gradient.addColorStop(1, 'rgba(150, 0, 255, 0)');
              return gradient;
            },
            fill: true,
          },
        ],
      };
      setDatasets(newDataset);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 산소 일별 조회 API
  const getO2SensorDayAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, dvNo: device.subCd, date: toDay, time: '1' };
    const res = await apiGet({ path: '/sensor/o2Day', req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      const sortedData = data.sort((a: any, b: any) => {
        return a.checkTime.localeCompare(b.checkTime);
      });
      const newDataset = {
        labels: sortedData.map((el: any) => el.checkTime.slice(-3)),
        datasets: [
          {
            label: device.cdName,
            data: sortedData.map((item: any) => Number(item.o2)),
            borderColor: 'rgba(255, 0, 255, 0.5)',
            backgroundColor: (context: any) => {
              const { ctx } = context.chart;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, 'rgba(255, 0, 255, 0.2)');
              gradient.addColorStop(1, 'rgba(255, 0, 255, 0)');
              return gradient;
            },
            fill: true,
          },
        ],
      };
      setDatasets(newDataset);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 가스 센서 일별 조회 API
  const getMqSensorDayAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, dvNo: device.subCd, date: toDay, time: '1' };
    const res = await apiGet({ path: '/sensor/mqDay', req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      const sortedData = data.sort((a: any, b: any) => {
        return a.checkTime.localeCompare(b.checkTime);
      });
      const newDataset = {
        labels: sortedData.map((el: any) => el.checkTime.slice(-3)),
        datasets: [
          {
            label: device.cdName,
            data: sortedData.map((item: any) => Number(item.mq)),
            borderColor: 'rgba(255, 0, 110, 0.5)',
            backgroundColor: (context: any) => {
              const { ctx } = context.chart;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, 'rgba(255, 0, 110, 0.2)');
              gradient.addColorStop(1, 'rgba(255, 0, 110, 0)');
              return gradient;
            },
            fill: true,
          },
        ],
      };
      setDatasets(newDataset);
    } else {
      // toast.error(t(ERROR));
    }
  };

  useEffect(() => {
    if (device.subCd) {
      if (sensor === '01') getPmSensorDayAPI();
      if (sensor === '02') getSoundSensorDayAPI();
      if (sensor === '03') getNoxSensorDayAPI();
      if (sensor === '04') getWindSensorDayAPI();
      if (sensor === '05') getO2SensorDayAPI();
      if (sensor === '06') getMqSensorDayAPI();
    }
  }, [device]);

  const onClickRefresh = () => {
    sensorQuery.refetch();
    if (sensor === '01') getPmSensorDayAPI();
    if (sensor === '02') getSoundSensorDayAPI();
    if (sensor === '03') getNoxSensorDayAPI();
    if (sensor === '04') getWindSensorDayAPI();
    if (sensor === '05') getO2SensorDayAPI();
    if (sensor === '06') getMqSensorDayAPI();
  };

  if (!sensorQuery.isSuccess || sensorQuery.data.status !== 200 || sensorQuery.isRefetching) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <div className='pmInfo'>
      <div className='widget-header'>
        <div className='widget-title flex-between'>
          <span>{t(name)}</span>
          <div className='refresh-btn' onClick={onClickRefresh} role='presentation'>
            <span className='material-symbols-rounded'>refresh</span>
          </div>
        </div>
      </div>
      <div className='widget-body graph'>
        <SelectBox options={fNameList} defaultOption={defaultSeneor} state={device} setState={setDevice} stateKey='subCd' />
        <div className='lineChartWrapper'>
          <LineChart labels={datasets.labels} datasets={datasets.datasets} title='' showLegend={sensor === '01'} />
        </div>
      </div>
    </div>
  );
};
