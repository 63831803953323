/**
 * 작성자 : 한영광
 * 날짜 : 2023.06.01
 * 기능 : 본사 현장정보/CCTV - 현장CCTV 탭
 *
 * 작성자 : 홍선영
 * 날짜 : 2023.06.02
 * 경로 : 멀티플레이어 컴포넌트 추가
 */

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as cctv from '../../../utils/cctv';

import MultiVideoPlayer from '../../../components/MultipleVideoPlayer3';
import { apiGet } from '../../../services/_common';
import WebSdk from '../../s_cctv/WebSdk';
import PlayVideoButton from '../../../components/button/PlayVideoButton';

const Root = styled.div`
  -webkit-box-flex: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  overflow: auto;
  -webkit-box-pack: start;
  justify-content: flex-start;

  .caption {
    padding: 0.5rem 1rem;
    font-weight: 600;
  }

  > div > .cctvWrapper {
    height: calc(100vh - 28rem);
  }
`;

interface ICameraObject {
  wsNum: string;
  nName: string;
  cName: string;
}

interface IProps {
  siteInfo: any;
}

const SiteCCTV = ({ siteInfo }: IProps) => {
  const { t } = useTranslation();
  const [cctvDivisions, setCctvDivisions] = useState(9);
  const [currentPage, setCurrentPage] = useState(1);
  const [cameraInfo, setCameraInfo] = useState<ICameraObject[]>([]);
  const [currentCameraInfo, setCurrentCameraInfo] = useState<ICameraObject[]>([]);
  const [webSdkState, setWebSdkState] = useState<boolean>(false);

  const getCameraListAPI = async (hCd: string, sCd: string) => {
    const req = { hCd, sCd };
    const res = await apiGet({ path: '/cam/site', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      setCameraInfo(data.cameraList);
    } else {
      // toast.error(t(ERROR));
    }
  };

  useEffect(() => {
    getCameraListAPI(siteInfo.hCd, siteInfo.sCd);
    if (cctv.getPluginOBJECT()?.oPlugin) {
      cctv.destroy();
      setWebSdkState(false);
    }
  }, [siteInfo]);

  useEffect(() => {
    // Calculate the starting index.
    const startIndex = (currentPage - 1) * cctvDivisions;

    // Calculate the ending index.
    const endIndex = startIndex + cctvDivisions;

    // Get the posts for the current page.
    const currentPosts = cameraInfo.slice(startIndex, endIndex);
    setCurrentCameraInfo(currentPosts);

    // console.log(currentPosts); // Output: [1, 2, 3]
  }, [currentPage, cctvDivisions, cameraInfo]);

  useEffect(() => {
    setCurrentPage(1);
  }, [cctvDivisions]);

  const onClickPlay = () => {
    setWebSdkState(true);
  };

  const renderCCTV = () => {
    if (siteInfo.cctvProtocol === '1') {
      // cctv protocol이 WEB SDK인 경우
      return (
        <>
          <div className='caption'>
            {siteInfo.sName} {t('CCTV')}
          </div>
          {webSdkState ? (
            <WebSdk state={cameraInfo} currentPageNumber={currentPage} setCurrentPageNumber={setCurrentPage} height='45rem' />
          ) : (
            <PlayVideoButton onClickPlay={onClickPlay} height='45rem' />
          )}
        </>
      );
    }
    if (siteInfo.cctvProtocol === '2') {
      // cctv protocol이 RTSP인 경우
      return (
        <>
          <div className='caption'>
            {siteInfo.sName} {t('CCTV')}
          </div>
          <MultiVideoPlayer
            cctvDivisions={cctvDivisions}
            setCctvDivisions={setCctvDivisions}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            currentCameraInfo={currentCameraInfo}
            totalCameraInfo={cameraInfo}
            dashboard={false}
          />
        </>
      );
    }
    return null;
  };

  return <Root>{!siteInfo?.sCd ? <div className='dataEmpty'>{t('현장을 선택하세요')}</div> : renderCCTV()}</Root>;
};

export default SiteCCTV;
