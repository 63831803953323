/**
 * 작성자 : 홍선영
 * 날짜 : 2024.06.03
 * 경로 : 안전관리 - 근로자 안전교육 보고서 인쇄 양식
 */

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatDateStringToStrBar } from '../../../utils/formatDate';
import { userState } from '../../../atoms';
import { useRecoilValue } from 'recoil';

interface Person {
  sCode: string;
  sDate: string;
  sSeq: number;
  sEduseq: number;
  wCd: string;
  wName: string;
  sjCd: string;
  sjName: string;
  wPrejobtype: string;
  wPrejobtypeName: string | null;
  wJobtype: string;
  wJobtypeName: string | null;
  wBdate: string | null;
  wRetiredate: string | null;
  wWorkstatus: string;
  signFile: string;
  wBlood: string | null;
  hasSignFile: boolean;
  rowKey: number;
}

interface TableProps {
  item: Person[];
  showSign: boolean;
  showSjName: { status: boolean; name: string };
  eduDate: string;
}

const Root = styled.div`
  h3 {
    text-align: center;
    font-weight: bold;
  }

  .flex-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem 0;
  }

  table {
    width: 100vw;
    border-collapse: collapse;

    tr {
      width: inherit;
      height: 3rem;
    }

    th {
      font-weight: bold;
      font-size: 1rem;
    }

    td {
      font-size: 0.8rem;
    }

    th:nth-child(6),
    td:nth-child(6) {
      border-right: 2px solid black;
      box-shadow: 2px 0 0 black, 4px 0 0 black; // Simulating two additional lines
      padding: 0 !important;
    }

    th,
    td {
      padding: 0.2rem;
      border: 1px solid black;
      text-align: center;
      // white-space: nowrap;
      overflow: hidden;
      // text-overflow: ellipsis;
      max-width: 9vw !important;

      &.w-3 {
        width: 3vw;
      }
      &.w-4 {
        width: 4vw;
      }
      &.w-5 {
        width: 5vw;
      }
      &.w-6 {
        width: 6vw;
      }
      &.w-8 {
        width: 8vw;
      }

      img {
        object-fit: contain;
        max-height: 3rem;
        max-width: 8vw;
      }

      &.font-small {
        font-size: 0.8rem;
      }
    }
  }

  @media print {
    .personTable {
      width: 100%; /* Adjust table width to fit page */
      table-layout: fixed; /* Helps in maintaining uniform column sizes */
      border-collapse: collapse; /* Ensures borders are neat */

      tr {
        page-break-inside: avoid !important; /* Avoid breaking rows across pages */
      }
    }
  }
`;

const SafeEduSignForm = ({ item, showSign, showSjName, eduDate }: TableProps) => {
  const { t } = useTranslation();
  const { sName } = useRecoilValue(userState);

  const renderRows = () => {
    const rows = [];
    for (let i = 0; i < item.length; i += 2) {
      const person1 = item[i];
      const person2 = item[i + 1];

      rows.push(
        <tr key={i}>
          <PersonData person={person1} index={i + 1} showSign={showSign} />
          {person2 ? <PersonData person={person2} index={i + 2} showSign={showSign} /> : <EmptyPersonData />}
        </tr>
      );
    }
    return rows;
  };

  return (
    <Root>
      <div>
        <h3>{t('교육자 명단')}</h3>
        <div className='flex-between'>
          <div>
            {t('현장명')} : {sName} {showSjName.status ? `(${t('협력업체')} : ${showSjName.name})` : null}
          </div>
          <div>{formatDateStringToStrBar(eduDate)}</div>
        </div>
      </div>
      <table className='personTable'>
        <thead>
          <tr>
            <th className='w-3'>{t('구분')}</th>
            <th className='w-6'>{t('소속')}</th>
            <th className='w-6'>{t('직종')}</th>
            <th className='w-4 font-small'>{t('혈액형')}</th>
            <th className='w-5'>{t('성명')}</th>
            <th className='w-6'>{t('서명')}</th>
            <th className='w-3'>{t('구분')}</th>
            <th className='w-6'>{t('소속')}</th>
            <th className='w-6'>{t('직종')}</th>
            <th className='w-4 font-small'>{t('혈액형')}</th>
            <th className='w-5'>{t('성명')}</th>
            <th className='w-6'>{t('서명')}</th>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
    </Root>
  );
};

const PersonData: React.FC<{ person: Person; index: number; showSign: boolean }> = ({ person, index, showSign }) => {
  const randomString = Math.random().toString(36).substring(7); // 랜덤 문자열을 쿼리스트링으로 추가해서 이미지 캐싱 방지

  return (
    <>
      <td className='w-3'>{index}</td>
      <td className='w-6'>{person.sjName}</td>
      <td className='w-6'>{person.wJobtypeName || ''}</td>
      <td className='w-4 font-small'>{person.wBlood || ''}</td>
      <td className='w-5'>{person.wName}</td>
      <td className='w-6'>{showSign && person.signFile ? <img src={`${person.signFile}?v=${randomString}`} alt='서명' style={{ width: '100px' }} /> : null}</td>
    </>
  );
};

const EmptyPersonData: React.FC = () => <td colSpan={6}> </td>;

export default SafeEduSignForm;
