/**
 * 작성자 : 김광민
 * 날짜 : 2023.12.19
 * 경로 : 터널 관리 - 구역 현황 관리 - 차트 모달
 */

import styled from 'styled-components';

type Props = {
  reverse?: boolean;
  pointOption: boolean;
};

const PercentageTd = styled.td<{ reverse: boolean }>`
  &.footerPercentage {
    display: flex;
    padding: 0 0.25rem;
    position: relative;
    &.reverse > div {
      align-items: end;
    }
    > div.percentageTd {
      width: calc((100% - 5rem) / 5);
      display: flex;
      flex-direction: column;
      &.reverse {
        width: 5rem;
        flex-grow: 0;
      }
      > .level {
        height: 0.75rem;
        background-color: aliceblue;
      }
    }
    &.footerReverse {
      flex-direction: row-reverse;
      > div {
        justify-content: flex-end;
        &.space-between {
          justify-content: space-between;
        }
      }
    }
  }
  * {
    font-size: 0.75rem;
    color: ${({ theme }: { theme: any }) => theme.text_disabled};
  }
  .verticalBar {
    width: 100%;
    border-style: ${(props) => (props.reverse ? 'none solid none none' : 'none none none solid')};
    border-width: 2px;
    border-color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    height: 0.75rem;
    &.level1 {
      border-color: ${({ theme }: { theme: any }) => theme.filled_slate};
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
    }
    &.level2 {
      border-color: ${({ theme }: { theme: any }) => theme.filled_amber};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
    }
    &.level3 {
      border-color: ${({ theme }: { theme: any }) => theme.filled_indigo};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_violet};
    }
    &.level4 {
      border-color: ${({ theme }: { theme: any }) => theme.filled_green};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
    }
    &.level5 {
      border-color: ${({ theme }: { theme: any }) => theme.filled_blue};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
    }
  }
`;

const PercentageBar = ({ reverse = false, pointOption }: Props) => {
  const getPosition = () => {
    if (reverse) {
      return 'order1 reverse';
    }
    return 'order3 footerReverse';
  };

  return (
    <PercentageTd className={`td12 footerPercentage tdGrow ${pointOption ? getPosition() : ''}`} reverse={reverse}>
      <div className='percentageTd reverse'>
        <div className='verticalBar' />
        100%
      </div>
      <div className='percentageTd'>
        <div className='verticalBar level5' />
        80%
      </div>
      <div className='percentageTd'>
        <div className='verticalBar level4' />
        60%
      </div>
      <div className='percentageTd'>
        <div className='verticalBar level3' />
        40%
      </div>
      <div className='percentageTd'>
        <div className='verticalBar level2' />
        20%
      </div>
      <div className='percentageTd'>
        <div className='verticalBar level1' />
        0%
      </div>
    </PercentageTd>
  );
};

export default PercentageBar;
