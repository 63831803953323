import styled from 'styled-components';
import CountBoxInModal from './CountBoxInModal';

const Root = styled.div`
  user-select: none;
  cursor: pointer;
  flex-grow: 1;
  height: 2.75rem;
  padding: 0 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }: { theme: any }) => theme.board};
  outline: 0.125rem solid ${({ theme }: { theme: any }) => theme.tonal};
  .title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-grow: 1;
    .material-symbols-rounded {
      font-variation-settings: 'FILL' 1, 'wght' 600, 'GRAD' 0, 'opsz' 48;
      font-size: 1.5rem;
      &.blue {
        color: ${({ theme }: { theme: any }) => theme.filled_blue};
      }
      &.green {
        color: ${({ theme }: { theme: any }) => theme.filled_green};
      }
      &.gray {
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      }
    }
    .name {
      font-size: 0.875rem;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
    }
  }
  &:hover {
    /* outline: 0.25rem solid ${({ theme }: { theme: any }) => theme.tonal_deep}; */
    outline: 0.25rem solid ${({ theme }: { theme: any }) => theme.tonal_deep_blue};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
    .title {
      .name {
        color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
      }
      .material-symbols-rounded {
        &.blank {
          color: ${({ theme }: { theme: any }) => theme.filled_blue};
        }
      }
    }
    &:active {
      outline: 0.25rem solid ${({ theme }: { theme: any }) => theme.filled_blue};
      .selected-counts {
        background-color: ${({ theme }: { theme: any }) => theme.board};
      }
    }
  }
`;

interface Props {
  data: JobtypeFoldableList;
  count: number;
}

const checkBox = {
  blank: { icon: 'check_box_outline_blank', color: 'gray' },
  checked: { icon: 'indeterminate_check_box', color: 'green' },
  checkedAll: { icon: 'check_box', color: 'blue' },
};

const FoldableListBoxInModal = ({ data, count }: Props) => {
  const blank = count === 0;
  const checkedAll = count === data.subList.length;

  const getColor = () => {
    if (blank) return checkBox.blank.color;
    if (checkedAll) return checkBox.checkedAll.color;
    return checkBox.checked.color;
  };

  const getIcon = () => {
    if (blank) return checkBox.blank.icon;
    if (checkedAll) return checkBox.checkedAll.icon;
    return checkBox.checked.icon;
  };

  const iconStyle = `material-symbols-rounded ${getColor() ?? 'gray'} ${blank ? 'blank' : ''}`;

  return (
    <Root>
      <div className='title'>
        <span className={iconStyle}>{getIcon()}</span>
        <span className='name'>{data.grName}</span>
      </div>
      <CountBoxInModal
        count={count} //
        totalCount={data.subList.length}
      />
    </Root>
  );
};

export default FoldableListBoxInModal;
