/**
 * 작성자 : 홍선영
 * 날짜 : 2023.12.18
 * 기능 : 방송장비테스트 모달
 */
/*
 * 수정자 : 김광민
 * 수정 날짜 : 2024.03.05
 * 수정 이유 1 : global style 걷어내고 컴포넌트 내 스타일시트 새로 작성
 * 수정 이유 2 : html 구조 재구성 및 전체 스타일시트 수정
 * 수정 이유 3 : map 메서드로 감싸는 부분 별도 컴포넌트로 추출
 */

import { useState, Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { IModal } from 'customTypes';
import Portal from '../Portal';
import DeleteModal from './DeleteModal2';
import { useTranslation } from 'react-i18next';
import { BtnGhost } from '../Button';
import WebSdkModal from './WebSdkModal';
import { WebSDKspeaker } from '../../utils/webSDKspeakerClass';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import BroadcastEquipList from '../BroadcastEquipList';
import { endAudio, getConnectedSpeakers, hasAvailableSpeaker, hasConnectingSpeaker, isInputEmpty, playTTS, startAudio, stopAudio, stopSpeakers } from '../../services/Tunnel';
import InfoTextWithIcon from './InfoTextWithIcon';
import AccordionTextArea from '../AccordionTextArea';
import i18n from '../../translation/i18n';
import BroadcastLanguage from '../BroadcastLanguage';
import ModalBottomButton from '../button/ModalBottomButton';
import CheckChipButton from '../button/CheckChipButton';

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5010;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 60%);
  backdrop-filter: blur(0.5rem);
  user-select: none;
  > .modal {
    width: 40rem;
    width: 64rem;
    max-width: calc(100vw - 2rem);
    max-width: calc(100dvw - 2rem);
    border-radius: 0.5rem;
    overflow: hidden;
    max-height: calc(100vh - 2rem);
    max-height: calc(100dvh - 2rem);
    > .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
      color: ${({ theme }: { theme: any }) => theme.bw_inverse};
      padding: 0.5rem;
      > .title {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0 0.5rem;
      }
      > .closeButton {
        border-radius: 0.5rem;
        margin: 0.375rem;
        padding: 0.25rem;
        display: flex;
        cursor: pointer;
        > .material-symbols-rounded {
          font-variation-settings: 'FILL' 0, 'wght' 600, 'GRAD' 0, 'opsz' 24;
          font-size: 1.375rem;
          color: ${({ theme }: { theme: any }) => theme.alpha_50};
        }
        &:hover {
          background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
          box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.1);
          .material-symbols-rounded {
            color: ${({ theme }: { theme: any }) => theme.tonal_deep_blue};
          }
        }
      }
    }
    > .body {
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      display: flex;
      align-items: flex-start;
      max-height: calc(100vh - 9.75rem);
      max-height: calc(100dvh - 9.75rem);
      overflow: hidden;
      position: relative;
      > .content-container {
        max-height: inherit;
        overflow: auto;
        -webkit-overflow-scrolling: touch; /* iOS 기기에서 스크롤 성능 개선 */
        flex: 1 0 60%;
        display: flex;
        flex-direction: column;
        border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
        background-color: ${({ theme }: { theme: any }) => theme.board};
        > .informations {
          padding: 0 0.75rem;
          padding-top: 0.75rem;
          display: flex;
          flex-direction: column;
        }
        > .textarea-container {
          overflow: auto;
          width: 100%;
          padding: 0 0.75rem;
          padding-bottom: 0.75rem;
          display: flex;
          flex-direction: column;
          gap: 0.25rem;
          > div {
            position: sticky;
            top: 0;
          }
        }
      }
    }
    > .footer {
      display: flex;
      justify-content: space-between;
      background-color: ${({ theme }: { theme: any }) => theme.board};
      border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      padding: 0.5rem;
      > .toggles,
      > .buttons {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        > .label {
          font-size: 0.875rem;
          letter-spacing: normal;
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
          padding: 0 0.5rem;
        }
      }
    }
  }
  > .cctvWrapperHidden {
    visibility: hidden;
    height: 0.1rem;
    width: 0.1rem;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

interface IProps {
  openModal: any;
  setOpenModal: Dispatch<SetStateAction<IModal>>;
}

const BroadcastEquipModal = ({ openModal, setOpenModal }: IProps) => {
  const { t } = useTranslation();
  const isKorean = i18n.language === 'ko-KR';

  const INIT_OPEN_SUB_MODAL = { status: false, type: '', isOverlappingModal: true };
  const [openSubModal, setOpenSubModal] = useState<any>(INIT_OPEN_SUB_MODAL);

  const [speakerList, setSpeakerList] = useState<WebSDKspeaker[]>([]);

  const [isMicEnabled, setIsMicEnabled] = useState(false);
  const [broadcastState, setBroadcastState] = useState<any[]>([]);
  const [inputState, setInputState] = useState({ ko: '', ch: '', vi: '', en: '' });

  const [isStartAudioEnabled, setIsStartAudioEnabled] = useState(true);
  const [isEndAudioEnabled, setIsEndAudioEnabled] = useState(true);
  const [audioList, setAudioList] = useState<any[]>([]);
  const AUDIO_DELAY = 500;

  const [isLoading, setIsLoading] = useState(true);

  let checkSpeaker = 0; // 모든 장비의 방송가능여부 체크가 완료됐는지 확인하는 용도

  useEffect(() => {
    setIsLoading(true);
    // 스피커 정보 및 연결 상태 초기화
    initSpeaker().then(async (res) => {
      if (res.length > 0) {
        setSpeakerList(res);
        /* eslint-disable no-await-in-loop */
        for (let i = 0; i < res.length; i += 1) {
          res[i].login();
        }
        const broadcastStateList = openModal.data.map(() => 0);
        setBroadcastState(broadcastStateList);
      } else {
        const broadcastStateList = openModal.data.map(() => 3);
        setBroadcastState(broadcastStateList);
      }
    });
  }, [openModal.data]);

  const stateChangeListener = (params: any) => {
    checkSpeaker += 1;
    const newBsState = broadcastState;
    newBsState[params.index] = params.status;
    setBroadcastState([...newBsState]);
    if (checkSpeaker === openModal.data.length) {
      setIsLoading(false);
    }
  };

  const initSpeaker = async () => {
    let newWebsdkSpeakers: WebSDKspeaker[] = [];

    const speakers = openModal.data;
    let initFailChk = false;
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < speakers.length; i += 1) {
      const { bsIp: ip, pPort: port, bsId: id, bsPwd: pwd, bsName, tadName } = speakers[i];
      const equipmentInfo = { ip, port, id, pwd, bsName, tadName };
      const websdkSpeaker = new WebSDKspeaker(setOpenSubModal, i, stateChangeListener, equipmentInfo);
      await websdkSpeaker
        .init()
        .then(() => {
          newWebsdkSpeakers.push(websdkSpeaker);
        })
        .catch((e) => {
          setIsLoading(false);
          initFailChk = true;
        });
      if (initFailChk) {
        break;
      }
    }
    return newWebsdkSpeakers;
  };

  // 방송 시작 핸들러
  const startBroadcast = async () => {
    // 마이크가 켜져 있는 경우 경고
    if (isMicEnabled) return;
    // 연결 중인 스피커가 있는 경우 경고
    if (hasConnectingSpeaker(speakerList)) {
      toast.warning(t('연결중입니다'));
      return;
    }
    // 사용 가능한 스피커가 없는 경우 경고
    if (hasAvailableSpeaker(speakerList)) {
      toast.warning(t('방송을 실행할 수 있는 장비가 없습니다'));
      return;
    }
    // 1. 실행 가능한 스피커 시작
    const connectedSpeakers = getConnectedSpeakers(speakerList);
    connectedSpeakers.forEach(async (speaker: any) => {
      if (speaker.isIdle()) {
        await speaker.start();
      }
    });
    // 2. 텍스트 입력이 없는 경우 알림 및 시작 효과음 재생
    const isEmptyInput = isInputEmpty(inputState);
    if (isEmptyInput) {
      isStartAudioEnabled && setTimeout(() => startAudio.play(), AUDIO_DELAY);
    } else {
      // 3. 텍스트 입력이 있는 경우 TTS 실행
      playTTS({
        inputState,
        toggleStartAudio: isStartAudioEnabled,
        setAudioList,
        onClickStop: stopBroadcast,
      });
    }
    // 마이크 사용 여부 설정
    setIsMicEnabled(true);
  };

  // 방송 정지 핸들러
  const stopBroadcast = async () => {
    // 마이크 사용 상태 활성화 시
    if (!isMicEnabled) return;
    // 1. 재생 중인 모든 오디오 정지
    startAudio.pause();
    startAudio.currentTime = 0;
    audioList.length > 0 &&
      audioList.forEach((audio) => {
        audio.pause();
        audio.currentTime = 0;
      });
    // 2. 종료음 재생 (사용자 설정 활성화 시)
    if (isEndAudioEnabled) {
      const pause = () => {
        speakerList.map(async (list: WebSDKspeaker) => {
          await list.stop();
        });
      };
      // 3. 모든 스피커 정지
      endAudio.play();
      endAudio.addEventListener('ended', pause, { once: true });
    } else {
      // 3. 종료음 없이 모든 스피커 정지
      speakerList.map(async (list: WebSDKspeaker) => {
        await list.stop();
      });
    }
    // 4. 마이크 사용 상태 비활성화
    setIsMicEnabled(false);
  };

  // 모달창 닫기 핸들러
  const onClickClose = () => {
    const checkMic = broadcastState.filter((state: any) => state === 2);
    // 1. 방송 중인지 확인 후 경고 메시지 출력
    if (isLoading) {
      toast.warning(t('연결 중입니다.'));
      return;
    }
    if (isMicEnabled || checkMic.length > 0) {
      toast.warning(t('방송 중입니다. 먼저 방송을 중지해주세요.'));
      return;
    }
    // 1. 모든 오디오 요소 중지
    stopAudio(audioList);
    // 필요 시 모달 닫기
    !openSubModal.status && setOpenModal((prev) => ({ ...prev, status: false }));
    // 2. 스피커 중지 및 로그아웃
    stopSpeakers(speakerList);
  };

  // openModal이 존재하고 data가 배열인 경우
  const isOpenModalData = openModal?.data && Array.isArray(openModal.data);
  // openModal이 null 또는 undefined인 경우
  if (!isOpenModalData) return null;

  return (
    <Root onClick={onClickClose}>
      {openSubModal.status !== true && (
        <div
          className='modal'
          role='presentation'
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className='header'>
            <span className='title'>{`TTS ${t('실시간 방송')}`}</span>
            <div className='closeButton' onClick={onClickClose} role='presentation'>
              <span className='material-symbols-rounded'>close</span>
            </div>
          </div>
          <div className='body'>
            <BroadcastEquipList data={openModal?.data} state={broadcastState} />
            <div className='content-container'>
              <div className='informations'>
                <InfoTextWithIcon icon='language' text={t('TTS 방송을 원하시는 언어에 해당 언어로 내용을 입력하세요.')} />
                <BroadcastLanguage data={inputState} />
              </div>
              <div className='textarea-container'>
                <AccordionTextArea name='ko' label={t('한국어')} state={inputState} setState={setInputState} maxLength={300} isExpandedState />
                <AccordionTextArea name='en' label={isKorean ? `English - ${t('영어')}` : 'English'} state={inputState} setState={setInputState} maxLength={300} isExpandedState />
                <AccordionTextArea name='ch' label={isKorean ? `中文 - ${t('중국어 간체')}` : `中文`} state={inputState} setState={setInputState} maxLength={300} />
                <AccordionTextArea name='vi' label={isKorean ? `Tiếng Việt - ${t('베트남어')}` : `Tiếng Việt`} state={inputState} setState={setInputState} maxLength={300} />
              </div>
            </div>
          </div>
          <div className='footer'>
            <div className='toggles'>
              <CheckChipButton //
                onClick={() => setIsStartAudioEnabled(!isStartAudioEnabled)}
                status={isStartAudioEnabled}
                text={t('방송 시작음')}
              />
              <CheckChipButton //
                onClick={() => setIsEndAudioEnabled(!isEndAudioEnabled)}
                status={isEndAudioEnabled}
                text={t('방송 종료음')}
              />
            </div>
            <div className='buttons'>
              {!isLoading ? (
                <>
                  <ModalBottomButton //
                    onClick={onClickClose}
                    text='방송 종료'
                    negative
                  />
                  {!isMicEnabled ? (
                    <ModalBottomButton //
                      onClick={startBroadcast}
                      text='방송 시작'
                    />
                  ) : (
                    <ModalBottomButton //
                      onClick={stopBroadcast}
                      text='방송 중지'
                    />
                  )}
                </>
              ) : (
                <BtnGhost>{t('연결중..')}</BtnGhost>
              )}
            </div>
          </div>
        </div>
      )}
      <Portal openModal={openSubModal?.status}>
        {openSubModal.status === true && openSubModal.type === 'delete' && <DeleteModal openModal={openSubModal} setOpenModal={setOpenSubModal} />}
        {openSubModal.status === true && openSubModal.type === 'websdk' && <WebSdkModal openModal={openSubModal} setOpenModal={setOpenSubModal} />}
      </Portal>
      <div id='divPlugin' className='cctvWrapperHidden' />
    </Root>
  );
};

export default BroadcastEquipModal;
