import { useRecoilState } from 'recoil';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import enUS from '../locales/en-US/translation.json';
import koKR from '../locales/ko-KR/translation.json';
import viVN from '../locales/vi-VN/translation.json';

import localeDefaultPack from './default-pack.json';

// 국가 코드와 json 파일 매핑
const resources = {
  'ko-KR': { translation: koKR },
  'en-US': { translation: enUS },
  // 'vi-VN': { translation: viVN },
};
const language = window.localStorage.getItem('language');
i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: language || localeDefaultPack['default-language'],

    returnNull: false,
    returnEmptyString: false,
    keySeparator: false,
    nsSeparator: false,

    // template literal 방식이라고 생각하면 편하다.
    // ex: t('안녕 %{하세요}')
    interpolation: {
      prefix: '%{',
      suffix: '}',
    },
    parseMissingKeyHandler(key: any, defaultValue: any) {
      // console.warn('Missing i18n key:', key, defaultValue);
      const keySeparator = '~~';
      const value = key.includes(keySeparator) ? key.split(keySeparator)[1] : key;

      return value;
    },
  });

export default i18n;
