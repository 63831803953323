import React, { useState, useEffect, Dispatch, SetStateAction, useRef } from 'react';
import { DateRangePicker } from 'react-date-range';
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth, subWeeks, subMonths, subDays } from 'date-fns';
import ko from 'date-fns/locale/ko';
import enUS from 'date-fns/locale/en-US';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import i18n from '../translation/i18n';
import { dateRegex } from '../utils/checkRegex';

interface IProps {
  state: any;
  setState: Dispatch<SetStateAction<any>>;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setVisible2?: Dispatch<SetStateAction<boolean>>;
  filterbar?: boolean;
}

interface DateFormatArg {
  date: Date;
  width: string;
}

const Root = styled.div`
  position: absolute;
  top: 0.25rem;
  left: 50%;
  transform: translate(-50%, 0);
  outline: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  border-radius: 0.25rem;
  z-index: 5100;
  background-color: ${({ theme }: { theme: any }) => theme.board};
  z-index: 4999;
  margin-top: 0.25rem;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  &.filterbar {
    @media (max-width: 1024px) {
      position: fixed;
      top: 7rem;
      left: 50%;
      transform: translate(-50%, 0);
      margin-top: 0;
      @media (max-width: 767px) {
        .rdrDateRangePickerWrapper {
          display: flex;
          flex-direction: column;
          .rdrDefinedRangesWrapper {
            order: 9999;
            width: 100%;
            .rdrStaticRanges {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
            }
          }
        }
      }
    }
  }
  .closeBtn {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }
  .rdrCalendarWrapper {
    background-color: ${({ theme }: { theme: any }) => theme.board};
  }
  .rdrDefinedRangesWrapper {
    width: 6rem;
    background-color: transparent;
    border-right: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  }
  .rdrMonthAndYearWrapper {
    button {
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      /* i {
        border-color: transparent ${({ theme }: { theme: any }) => theme.text_secondary} transparent transparent;
      } */
    }
    button:nth-child(1) i {
      border-color: transparent ${({ theme }: { theme: any }) => theme.text_secondary} transparent transparent;
    }
    button:nth-child(3) i {
      border-color: transparent transparent transparent ${({ theme }: { theme: any }) => theme.text_secondary};
    }
  }
  .rdrMonth {
    padding-bottom: 0;
  }
  .rdrDateDisplayWrapper,
  .rdrInputRanges,
  .rdrMonthName {
    display: none;
  }
  .rdrWeekDays .rdrWeekDay {
    font-weight: 400;
  }
  .rdrYearPicker {
    order: 1;
    select {
      font-weight: 500;
    }
  }
  .rdrMonthPicker {
    order: 3;
  }
  .rdrDayHovered {
    background-color: ${({ theme }: { theme: any }) => theme.selected_primary};
    .rdrStartEdge,
    .rdrInRange,
    .rdrEndEdge,
    .rdrDayStartPreview,
    .rdrDayInPreview,
    .rdrDayEndPreview {
      display: none;
    }
    span span {
      color: ${({ theme }: { theme: any }) => theme.color.zinc_100};
    }
  }
  .rdrStartEdge,
  .rdrInRange,
  .rdrEndEdge {
    color: ${({ theme }: { theme: any }) => theme.color.zinc_100};
  }
  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    border: none;
    background: ${({ theme }: { theme: any }) => theme.tonal} !important;
    mix-blend-mode: ${({ theme }: { theme: any }) => theme.rdrDayEndPreview} !important;
  }
  button.rdrDay {
    span.rdrDayNumber {
      span {
        font-weight: 400;
        color: ${({ theme }: { theme: any }) => theme.text_primary};
      }
    }
    &:hover span span {
      color: ${({ theme }: { theme: any }) => theme.color.zinc_100};
    }
  }
  button.rdrDayPassive {
    span.rdrDayNumber {
      span {
        color: ${({ theme }: { theme: any }) => theme.text_disabled};
        display: none;
      }
    }
  }
  .rdrDayToday .rdrDayNumber span::after {
    height: 0.25rem;
    width: 1rem;
    bottom: 0;
    /* border-radius: 0; */
  }
  button.rdrStaticRange {
    border: none;
    background-color: transparent;
  }
  button.rdrStaticRange span.rdrStaticRangeLabel {
    font-size: 0.875rem;
    height: 100%;
    display: flex;
    align-items: center;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
    background-color: ${({ theme }: { theme: any }) => theme.board};
    font-weight: 500;
  }
  .rdrMonthAndYearPickers {
    gap: 0.5rem;
    /* > span:first-child,
    > span:last-child {
      position: relative;
      &::after {
        content: '▼';
        background-image: url('/src/assets/images/keyboard_arrow_down_FILL0_wght400_GRAD0_opsz24.svg');
        background-repeat: no-repeat;
        background-position: center left;
        background-size: contain;
        position: absolute;
        right: 0.5rem;
        font-size: 0.875rem;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        padding-top: 0.25rem;
        pointer-events: none;
        font-size: 0.625rem;
        color: ${({ theme }: { theme: any }) => theme.text_disabled};
      }
    } */
    select {
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      background-color: ${({ theme }: { theme: any }) => theme.background};

      /* background: none; */
      position: relative;
      /* &:hover {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
        option {
          background-color: ${({ theme }: { theme: any }) => theme.board};
        }
      } */
      /* option {
        background-color: ${({ theme }: { theme: any }) => theme.text_primary};
      } */
    }
  }
  button.rdrStaticRange span.rdrStaticRangeLabel {
  }
  button.rdrStaticRange span.rdrStaticRangeLabel:hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
  }
  button.rdrStaticRange {
    border-radius: 0.5rem;
    overflow: none;
  }

  .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,
  .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after {
    display: none;
  }
`;

export const onChangeRangeInput = (e: React.ChangeEvent<HTMLInputElement>, type: string, setRangeState: Dispatch<SetStateAction<any>>, setSearchOption: Dispatch<SetStateAction<any>>) => {
  const { value } = e.currentTarget;
  // 입력 필드를 지운 경우 날짜를 null로 설정
  const checkedValue = dateRegex(value);
  if (!checkedValue) {
    setRangeState((prev: any) => {
      if (type === 'start') return [{ ...prev[0], startDate: '' }];
      if (type === 'end') return [{ ...prev[0], endDate: '' }];
      if (type === 'aDateStart') return [{ ...prev[0], startDate: '' }];
      if (type === 'aDateEnd') return [{ ...prev[0], endDate: '' }];
      if (type === 'oldDateStart') return [{ ...prev[0], startDate: '' }];
      if (type === 'oldDateEnd') return [{ ...prev[0], endDate: '' }];
      if (type === 'sDateStart') return [{ ...prev[0], startDate: '' }];
      if (type === 'sDateEnd') return [{ ...prev[0], endDate: '' }];
      if (type === 'srDateStart') return [{ ...prev[0], startDate: '' }];
      if (type === 'srDateEnd') return [{ ...prev[0], endDate: '' }];
      return prev;
    });
    setSearchOption((prev: any) => ({ ...prev, [type]: '' }));
    return;
  }

  // 빈값이 아니면 setState
  const date = dayjs(checkedValue, 'YYYY-MM-DD').toDate();
  if (isNaN(date.getTime())) {
    let finalValue = checkedValue;

    if (checkedValue.length === 6 && checkedValue[4] !== '-') {
      finalValue = `${checkedValue.slice(0, 4)}-${checkedValue.slice(4)}`;
    } else if (checkedValue.length === 8 && checkedValue[7] !== '-') {
      finalValue = `${checkedValue.slice(0, 7)}-${checkedValue.slice(7)}`;
    }
    setSearchOption((prev: any) => ({ ...prev, [type]: finalValue }));
    return;
  }
  setRangeState((prev: any) => {
    if (type === 'start') return [{ ...prev[0], startDate: date }];
    if (type === 'end') return [{ ...prev[0], endDate: date }];
    if (type === 'aDateStart') return [{ ...prev[0], startDate: date }];
    if (type === 'aDateEnd') return [{ ...prev[0], endDate: date }];
    if (type === 'oldDateStart') return [{ ...prev[0], startDate: date }];
    if (type === 'oldDateEnd') return [{ ...prev[0], endDate: date }];
    if (type === 'sDateStart') return [{ ...prev[0], startDate: date }];
    if (type === 'sDateEnd') return [{ ...prev[0], endDate: date }];
    if (type === 'srDateStart') return [{ ...prev[0], startDate: date }];
    if (type === 'srDateEnd') return [{ ...prev[0], endDate: date }];
    return prev;
  });
};

const RangePicker = ({ state, setState, setVisible, setVisible2, filterbar = true }: IProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);
  const [selectionCount, setSelectionCount] = useState(0); // 캘린더 endDate 클릭했을 때 캘린더 닫히도록 하기위한 카운트

  const handleClickOutside = (e: MouseEvent) => {
    if (ref.current && e.target instanceof Node && !ref.current.contains(e.target)) {
      const startInput = document.getElementById('startInput');
      const startEndInput = document.getElementById('startEndInput');
      const endInput = document.getElementById('endInput');
      const startInput2 = document.getElementById('startInput2');
      const startEndInput2 = document.getElementById('startEndInput2');
      const endInput2 = document.getElementById('endInput2');
      const modalStartInput = document.getElementById('modalStartInput');
      const modalEndInput = document.getElementById('modalEndInput');

      if (e.target !== startInput && e.target !== startEndInput && e.target !== endInput && e.target !== modalStartInput && e.target !== modalEndInput) {
        setVisible(false);
      }
      if (setVisible2 && e.target !== startInput2 && e.target !== endInput2 && e.target !== startEndInput2) {
        setVisible2(false);
      }
    }
  };

  const handleClickCustomLabel = (e: MouseEvent) => {
    const element = e.target as Element;

    if (element && element.className) {
      const { className, innerHTML } = element;
      if (innerHTML === t('오늘')) {
        setState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
      }
      if (innerHTML === t('어제')) {
        setState([{ startDate: subDays(new Date(), 1), endDate: subDays(new Date(), 1), key: 'selection' }]);
      }
      if (innerHTML === t('이번 주')) {
        setState([{ startDate: startOfWeek(new Date()), endDate: endOfWeek(new Date()), key: 'selection' }]);
      }
      if (innerHTML === t('지난 주')) {
        setState([{ startDate: startOfWeek(subWeeks(new Date(), 1)), endDate: endOfWeek(subWeeks(new Date(), 1)), key: 'selection' }]);
      }
      if (innerHTML === t('이번 달')) {
        setState([{ startDate: startOfMonth(new Date()), endDate: endOfMonth(new Date()), key: 'selection' }]);
      }
      if (innerHTML === t('지난 달')) {
        setState([{ startDate: startOfMonth(subMonths(new Date(), 1)), endDate: endOfMonth(subMonths(new Date(), 1)), key: 'selection' }]);
      }
      if (className === 'rdrStaticRangeLabel') {
        setVisible(false);
        if (setVisible2 !== undefined) setVisible2(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  useEffect(() => {
    document.addEventListener('mousedown', handleClickCustomLabel);
    return () => {
      document.removeEventListener('mousedown', handleClickCustomLabel);
    };
  });

  const customKoreanLocale = {
    ...(i18n.language === 'ko-KR' ? ko : enUS),
    formatLong: {
      date: ({ date, width }: DateFormatArg) => {
        switch (width) {
          case 'abbreviated':
          case 'short':
            return 'yyyy.MM';
          default:
            return `yyyy${t('년')} MM${t('월')}`;
        }
      },
      time: (date: Date) => 'HH:mm',
      dateTime: (date: Date) => `yyyy${t('년')} MM${t('월')} dd${t('일')} HH:mm`,
    },
  };

  const staticRanges = [
    {
      label: t('오늘'),
      range: () => ({
        startDate: new Date(),
        endDate: new Date(),
      }),
      isSelected: () => false,
    },
    {
      label: t('어제'),
      range: () => ({
        startDate: subDays(new Date(), 1),
        endDate: subDays(new Date(), 1),
      }),
      isSelected: () => false,
    },
    {
      label: t('이번 주'),
      range: () => ({
        startDate: startOfWeek(new Date()),
        endDate: endOfWeek(new Date()),
      }),
      isSelected: () => false,
    },
    {
      label: t('지난 주'),
      range: () => ({
        startDate: startOfWeek(subWeeks(new Date(), 1)),
        endDate: endOfWeek(subWeeks(new Date(), 1)),
      }),
      isSelected: () => false,
    },
    {
      label: t('이번 달'),
      range: () => ({
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date()),
      }),
      isSelected: () => false,
    },
    {
      label: t('지난 달'),
      range: () => ({
        startDate: startOfMonth(subMonths(new Date(), 1)),
        endDate: endOfMonth(subMonths(new Date(), 1)),
      }),
      isSelected: () => false,
    },
  ];

  /**
   * 수정자 : 홍선영
   * 날짜 : 2024.02.19
   * 수정 내용 : 레인지피커를 드래그하여 날짜선택 한 경우, 데이트피커가 자동으로 닫히지 않는 부분에 대한 요청사항 있어 수정함.
   *            시작날짜 선택하는 동시에, 종료날짜도 동일날짜로 세팅되기 때문에,
   *            시작일자와 종료일자가 같지 않은경우 데이트피커 닫히도록 처리
   */
  const handleSelect = (ranges: any) => {
    // 선택한 시작일과 종료일이 다른지 확인
    if (ranges.selection.startDate !== ranges.selection.endDate) {
      setSelectionCount(selectionCount + 1); // 날짜가 다른 경우 선택 횟수 변수 증가
    }
    setState([ranges.selection]);
  };

  useEffect(() => {
    if (state[0].startDate && state[0].endDate && selectionCount === 1) {
      setVisible(false);
      if (setVisible2 !== undefined) setVisible2(false);
    }
  }, [selectionCount]);

  return (
    <Root ref={ref} className={`${filterbar && 'filterbar'}`}>
      {/* <BtnRed className='closeBtn' onClick={() => setVisible(false)}>
        닫기
      </BtnRed> */}
      <DateRangePicker
        onChange={handleSelect}
        // showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={1}
        // ranges={state.startDate === '' && state.endDate === '' ? { startDate: new Date(), endDate: new Date() } : state}
        ranges={state}
        direction='horizontal'
        // preventSnapRefocus
        // calendarFocus='backwards'
        locale={customKoreanLocale}
        renderStaticRangeLabel={(range) => {
          switch (range.label) {
            case 'Today':
              return t('오늘');
            case 'Yesterday':
              return t('어제');
            case 'This Week':
              return t('이번 주');
            case 'Last Week':
              return t('지난 주');
            case 'This Month':
              return t('이번 달');
            case 'Last Month':
              return t('지난 달');
            default:
              return range.label;
          }
        }}
        staticRanges={staticRanges}
      />
    </Root>
  );
};

export default RangePicker;
