import styled from 'styled-components';

export const BtnWhite = styled.button`
  color: #3f4254;
  background-color: #fff;
  /* box-shadow: 1px 1px 3px rgb(0 0 0 / 7%); */
  /* border: 1px solid #fff; */
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.05);
    /* background-color: #fff; */
  }
`;

export const BtnGray = styled.button`
  color: #eaeaea;
  background-color: #626777;
  border: 1px solid #626777;
  &:hover {
    color: #eaeaea;
    background-color: #3f4254;
    border: 1px solid #3f4254;
  }
`;

export const BtnGraySmall = styled.button`
  font-size: 0.875rem;
  width: fit-content;
  height: 2rem;
  padding: 0 0.5rem;
  color: #eaeaea;
  background-color: #626777;
  border: 1px solid #626777;
  &:hover {
    color: #eaeaea;
    background-color: #3f4254;
    border: 1px solid #3f4254;
  }
`;

export const BtnBlue = styled.button`
  color: #eaeaea;
  background-color: ${({ theme }: { theme: any }) => theme.selected_primary};
  &:hover {
    color: #eaeaea;
    background-color: ${({ theme }: { theme: any }) => theme.blue_60};
  }
  &:disabled {
    background-color: ${({ theme }: { theme: any }) => theme.filled_blue_light};
    cursor: default;
  }
`;
export const BtnDeep = styled.button`
  color: ${({ theme }: { theme: any }) => theme.text_primary};
  background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
  &:hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
  }
`;

export const BtnLightBlue = styled.button`
  color: #1261ff;
  background-color: rgba(18, 97, 255, 0.2);
  border: 1px solid rgba(18, 97, 255, 0.2);
  &:hover {
    color: #1261ff;
    background-color: rgba(18, 97, 255, 0.2);
    border: 1px solid #1261ff;
  }
`;

export const BtnSkyBlue = styled.button`
  color: ${({ theme }: { theme: any }) => theme.btn_text};
  background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
  &:hover {
    background-color: rgba(54, 153, 255, 0.6);
  }
`;

export const BtnLightSkyBlue = styled.button`
  color: #3699ff;
  background-color: rgba(54, 153, 255, 0.2);
  border: 1px solid rgba(54, 153, 255, 0.2);
  &:hover {
    color: #3699ff;
    background-color: rgba(54, 153, 255, 0.2);
    border: 1px solid #3699ff;
  }
`;

export const BtnGreen = styled.button`
  color: #fff;
  background-color: #0f766e;
  border: none;
  &:hover {
    color: #fff;
    background-color: #115e59;
  }
`;

export const BtnLightGreen = styled.button`
  color: #0f766e;
  background-color: #f0fdfa;
  &:hover {
    background-color: #ccfbf1;
    /* background-color: #99f6e4; */
  }
`;

export const BtnViolet = styled.button`
  color: #eaeaea;
  background-color: #8950fc;
  border: 1px solid #8950fc;
  &:hover {
    color: #eaeaea;
    background-color: rgba(137, 80, 252, 0.6);
  }
`;

export const BtnLightViolet = styled.button`
  color: #8950fc;
  background-color: rgba(137, 80, 252, 0.2);
  /* border: 1px solid rgba(137, 80, 252, 0.2); */
  &:hover {
    color: #8950fc;
    background-color: rgba(137, 80, 252, 0.2);
    /* border: 1px solid #8950fc; */
  }
`;

export const BtnRed = styled.button`
  color: ${({ theme }: { theme: any }) => theme.btn_text};
  background-color: ${({ theme }: { theme: any }) => theme.filled_red};
  &:hover {
    background-color: rgba(246, 78, 96, 0.6);
  }
  &:disabled {
    background-color: rgba(246, 78, 96, 0.6);
    cursor: default;
  }
`;

export const BtnLightRed = styled.button`
  color: #f42a3f;
  background-color: rgba(246, 78, 96, 0.2);
  border: 1px solid rgba(246, 78, 96, 0.2);
  &:hover {
    color: #f42a3f;
    background-color: rgba(246, 78, 96, 0.2);
    border: 1px solid #f64e60;
  }
`;

export const BtnYellow = styled.button`
  color: #fff;
  background-color: #ffa800;
  border: 1px solid #ffa800;
  &:hover {
    color: #eaeaea;
    color: #fff;
    background-color: rgba(255, 168, 0, 0.6);
  }
`;

export const BtnLightYellow = styled.button`
  color: #ffa800;
  background-color: rgba(255, 168, 0, 0.2);
  border: 1px solid rgba(255, 168, 0, 0.2);
  &:hover {
    color: #ffa800;
    background-color: rgba(255, 168, 0, 0.2);
    border: 1px solid #ffa800;
  }
`;

export const BtnGhost = styled.button`
  color: ${({ theme }: { theme: any }) => theme.text_primary};
  background-color: ${({ theme }: { theme: any }) => theme.board};
  gap: 0.25rem;
  > span {
  }
  &:hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
    box-shadow: none;
  }

  &.active {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
  }
`;

export const InitBtn = styled.button`
  padding: 0 0.75rem;
  width: 4.5rem;
  border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
  color: ${({ theme }: { theme: any }) => theme.text_primary};
  gap: 0.25rem;
  font-size: 0.875rem;
  font-weight: 600;
  height: 2.5rem;
  user-select: none;
  &:hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
    box-shadow: none;
  }
  &.active {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
  }
`;
