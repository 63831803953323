/**
 * 작성자 : 한영광
 * 날짜 : 2024.01.05
 * 기능 : 긴급 상황 알림 정보 모달
 */
/*
 * 수정자 : 김광민
 * 수정 날짜 : 2024.02.29
 * 수정 이유 1 : global style 걷어내고 컴포넌트 내 스타일시트 추가
 * 수정 이유 2 :html 구조 재구성 및 전체 스타일시트 수정
 * 수정 이유 3 :map 메서드로 감싸는 부분 별도 컴포넌트로 추출
 * 수정 이유 4 :브라우저 너비에 따른 반응형 대응
 */

import styled from 'styled-components';
import { IModal } from 'customTypes';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import AlertModalCard from '../AlertModalCard';

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5010;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 60%);
  backdrop-filter: blur(0.5rem);
  user-select: none;
  .modal {
    width: 40rem;
    max-width: calc(100vw - 2rem);
    background-color: ${({ theme }: { theme: any }) => theme.board};
    border-radius: 0.5rem;
    overflow: hidden;
    max-height: calc(100vh - 2rem);
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: ${({ theme }: { theme: any }) => theme.filled_red_deep};
      color: ${({ theme }: { theme: any }) => theme.bw_inverse};
      padding: 0.5rem;
      .title {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0 0.5rem;
        .material-symbols-rounded {
          font-variation-settings: 'FILL' 0, 'wght' 300, 'GRAD' 0, 'opsz' 24;
          font-size: 2.5rem;
        }
        .titleName {
          font-size: 1.125rem;
          font-weight: 400;
        }
        .titleCount {
          font-weight: 400;
          &::before {
            content: '•';
            color: ${({ theme }: { theme: any }) => theme.alpha_75};
            margin-right: 0.5rem;
          }
        }
      }
      .closeButton {
        border-radius: 0.5rem;
        margin: 0.375rem;
        padding: 0.25rem;
        display: flex;
        cursor: pointer;
        .material-symbols-rounded {
          font-variation-settings: 'FILL' 0, 'wght' 600, 'GRAD' 0, 'opsz' 24;
          font-size: 1.375rem;
          color: ${({ theme }: { theme: any }) => theme.alpha_50};
        }
        &:hover {
          background-color: ${({ theme }: { theme: any }) => theme.filled_red};
          box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.1);
          .material-symbols-rounded {
            color: ${({ theme }: { theme: any }) => theme.tonal_deep_red};
          }
        }
      }
    }
    .body {
      background-color: ${({ theme }: { theme: any }) => theme.board};
      padding: 0 0.75rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      overflow: auto;
      max-height: 19.75rem;
      @media (min-width: 768px) {
        max-height: 28.75rem;
      }
      @media (min-width: 1024px) {
        max-height: 42.75rem;
      }
      .cardContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
    }
    .footer {
      display: flex;
      justify-content: center;
      border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      .closeButton {
        cursor: pointer;
        color: ${({ theme }: { theme: any }) => theme.filled_red_deep};
        padding-left: 0.5rem;
        padding-right: 1.5rem;
        height: 2.25rem;
        border-radius: 2rem;
        font-size: 0.938rem;
        letter-spacing: normal;
        margin: 0.75rem 0;
        display: flex;
        align-items: center;
        gap: 0.75rem;
        transition: all 0.05s ease-in-out;
        background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
        outline: 1px solid ${({ theme }: { theme: any }) => theme.tonal_deep_red};
        .material-symbols-rounded {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_deep_red};
          border-radius: 1rem;
          padding: 0.125rem;
          font-size: 1.375rem;
          transition: all 0.25s ease-in-out;
        }
        &:hover {
          .material-symbols-rounded {
            font-variation-settings: 'FILL' 0, 'wght' 600, 'GRAD' 0, 'opsz' 24;
            background-color: ${({ theme }: { theme: any }) => theme.filled_red};
            color: ${({ theme }: { theme: any }) => theme.tonal_deep_red};
          }
        }
      }
    }
  }
`;

const AlertModal = ({ openModal, setOpenModal }: any) => {
  const { t } = useTranslation();
  // const ALERT_DURATION = 60; // 알럿창 유지 시간(초)
  // const { timer, setTimer } = useTimer(ALERT_DURATION);

  // useEffect(() => {
  //   // mqtt 알럿창 떴을 때 타이머 시작
  //   if (openModal.status) setTimer(ALERT_DURATION);
  // }, [openModal.status]);

  // useEffect(() => {
  //   // 시간 초과시 알럿창 닫기
  //   if (openModal.status && timer <= 0) setOpenModal((prev: any) => ({ ...prev, status: false }));
  // }, [timer]);

  // 닫기버튼 클릭
  const onClickClose = () => {
    openModal.initAlertData();
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };

  // AlertModal 컴포넌트 내에서 openModal이 null 또는 undefined인 경우.
  // openModal이 존재하고 data가 배열인 경우에만 map 함수를 호출
  const isOpenModalData = openModal?.data && Array.isArray(openModal.data);

  return (
    <Root onClick={onClickClose}>
      <div className='modal'>
        <div className='header'>
          <div className='title'>
            <span className='material-symbols-rounded'>warning</span>
            <span className='titleName'>{t('긴급 상황 알림 정보')}</span>
            <span className='titleCount'>{`${t('총')} ${openModal?.data.length}`}</span>
          </div>
          <div className='closeButton' onClick={onClickClose} role='presentation'>
            <span className='material-symbols-rounded'>close</span>
          </div>
        </div>
        <div className='body'>
          <div className='cardContainer'>
            {isOpenModalData &&
              openModal.data.map((card: any, index: number) => (
                <AlertModalCard //
                  key={v4()}
                  data={card}
                  index={index}
                />
              ))}
          </div>
        </div>
        <div className='footer'>
          <div className='closeButton' onClick={onClickClose} tabIndex={0} role='button'>
            <span className='material-symbols-rounded'>check</span>
            {t('위 내용을 모두 확인했습니다')}
          </div>
        </div>
      </div>
    </Root>
  );
};

export default AlertModal;
