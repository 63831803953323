/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.30
 * 경로 : 터널 모달 > 굴진/일반정보 모듈
 */

import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { applyBorderStyle } from '../utils/applyBorderStyle';
import styled from 'styled-components';
import Input from './Input';
import TunnelTdProgressBar from './TunnelTdProgressbar';
import DistanceInfo from './tunnel/DistanceInfo';
import SelectBoxInfo from './tunnel/SelectBoxInfo';
import PercentageColorBar from './PercentageColorBar';
import { tunnelReverseState } from '../atoms';
import { useRecoilValue } from 'recoil';

const TAreaPoint = styled.div`
  display: flex;
  flex-direction: column;
  display: block;
  color: ${({ theme }: { theme: any }) => theme.text_tertiary};
  gap: 0.5rem;
  flex-grow: 1;
  @media (max-width: 1023px) {
    min-width: calc(100dvw - 1rem);
  }
  @media (min-width: 1024px) {
    width: calc(50% - 0.25rem);
  }
  > div:nth-child(1),
  > div:nth-child(2) {
    margin-bottom: 0.75rem;
  }
  > .normalPointInfo {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem;
    @media (min-width: 1024px) {
      padding: 1rem;
    }
    .distanceGroup,
    .selectGroup {
      display: flex;
      gap: 0.5rem;
    }
    .bigo {
      display: flex;
      flex-direction: column;
      height: 8rem;
      justify-content: space-between;
      font-weight: 400;
      background-color: ${({ theme }: { theme: any }) => theme.board};
      padding: 0.75rem;
      border-radius: 0.5rem;
      outline: 1px solid ${({ theme }: { theme: any }) => theme.tonal_deep};
      @media (min-width: 1024px) {
        padding: 0.875rem 1rem;
      }
      &.focus {
        input {
          outline: 1px solid ${({ theme }: { theme: any }) => theme.tonal_deep};
        }
      }
      .title {
        display: flex;
        align-items: center;
        gap: 0.375rem;
        .material-symbols-outlined {
          font-size: 1.125rem;
          border-radius: 2rem;
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
          font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
        }
      }
      input,
      label {
        font-size: 0.875rem !important;
      }
      &:hover {
        outline: 1px solid ${({ theme }: { theme: any }) => theme.outline_em};
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }
    }
  }
`;

type Props = {
  position: 's' | 'e';
  tjcList: any;
  tesList: any;
  data: any;
  setData: Dispatch<SetStateAction<any>>;
  isSaveClicked: boolean;
};

const TareaNormalInfoPoint = ({ position, tjcList, tesList, data, setData, isSaveClicked }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [focusOut, setFocusOut] = useState(false);
  const { t } = useTranslation();
  const isStart = position === 's';
  const isTunnelReversed = useRecoilValue(tunnelReverseState);
  const isReversed = !isTunnelReversed ? isStart : !isStart;

  const tjcInitial = {
    type: 'tjcCd',
    tjcCd: isStart ? data.sTjcCd : data.eTjcCd,
    cdName: isStart ? data.sTjcName : data.eTjcName,
  };

  const tesInitial = {
    type: 'tesCd',
    tesCd: isStart ? data.sTesCd : data.eTesCd,
    cdName: isStart ? data.sTesName : data.eTesName,
  };

  const [tjc, setTjc] = useState(tjcInitial);
  const [tes, setTes] = useState(tesInitial);

  useEffect(() => {
    setTjc(tjcInitial);
    setTes(tesInitial);
  }, [data.tatCd]);

  // 작업내용 업데이트시 변경내용 setData에 반영
  useEffect(() => {
    if (isStart) {
      setData((prev: any) => ({ ...prev, sTjcName: tjc.cdName, sTjcCd: tjc.tjcCd }));
    }
    if (!isStart) {
      setData((prev: any) => ({ ...prev, eTjcName: tjc.cdName, eTjcCd: tjc.tjcCd }));
    }
  }, [tjc]);

  // 장비상태 업데이트시 변경내용 setData에 반영
  useEffect(() => {
    if (isStart) {
      setData((prev: any) => ({ ...prev, sTesName: tes.cdName, sTesCd: tes.tesCd }));
    }
    if (!isStart) {
      setData((prev: any) => ({ ...prev, eTesName: tes.cdName, eTesCd: tes.tesCd }));
    }
  }, [tes]);

  const rlength = isStart ? data.sRLength : data.eRLength;
  const length = isStart ? data.sLength : data.eLength;

  return (
    <TAreaPoint>
      <TunnelTdProgressBar rlength={rlength} length={length} meter={false} reverse={isReversed} point={position} />
      <PercentageColorBar reverse={isReversed} />
      <div className='normalPointInfo'>
        <div className='distanceGroup'>
          <DistanceInfo length={length} rlength={rlength} viewType='length' position={position} isSaveClicked={isSaveClicked} data={data} setData={setData} />
          <DistanceInfo length={length} rlength={rlength} viewType='rlength' position={position} isSaveClicked={isSaveClicked} data={data} setData={setData} />
        </div>
        <div className='selectGroup'>
          <SelectBoxInfo text='작업내용' options={tjcList} defaultOption={tjc.cdName} state={tjc} setState={setTjc} stateKey={tjc.type} initiateKey={tjc.tjcCd} />
          <SelectBoxInfo text='장비 상태' options={tesList} defaultOption={tes.cdName} state={tes} setState={setTes} stateKey={tes.type} initiateKey={tes.tesCd} />
        </div>
        <div className={`bigo ${focusOut ? 'focus' : ''}`} role='button' tabIndex={0} onClick={() => inputRef.current && inputRef.current?.focus()}>
          <div className='title'>
            <span className='material-symbols-outlined'>description</span>
            <label htmlFor=''>{t('비고')}</label>
          </div>
          <Input type='text' blur={() => setFocusOut(false)} onFocus={() => setFocusOut(true)} name={isStart ? 'sBigo' : 'eBigo'} state={data} setState={setData} inputRef={inputRef} />
        </div>
      </div>
    </TAreaPoint>
  );
};

export default TareaNormalInfoPoint;
