/**
 * 작성자 : 홍선영
 * 날짜 : 2023.05.16
 * 경로 : 근로자 관리 - 근로자 직무스트레스관리
 */

import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import i18n from '../../../translation/i18n';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { ContentsContainerRoot } from '../../../assets/styles/ContentsContainerRoot';
import IssueGuide from '../../../components/IssueGuide';
import { IUser, userState } from '../../../atoms';
import WorkerStressSearchOption from './workerStressSearchOption';
import useSetListCount from '../../../utils/useSetListCount';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import { PulseLoader } from 'react-spinners';
import TuiGridWrapper from '../../../components/Table/TuiGridWrapper';
import TuiGrid from '../../../components/Table/TuiGrid';
import { LIST_COUNT } from '../../../_constants';
import { useGetFrozenCount } from '../../../utils/getFrozenCount';
import Portal from '../../../components/Portal';
import InfoWorkerModal from '../../../components/Modal/InfoWorkerModal';
import { useSetAuth } from '../../../utils/useSetAuth';
import { logPost } from '../../../services/log';

const Root = styled(ContentsContainerRoot)``;

const WorekrStressManagement = () => {
  const userInfo = useRecoilValue<IUser>(userState);
  const { eExcelYn, eListYn } = userInfo;
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const { t } = useTranslation();
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [tableState, setTableState] = useState<any[]>([]);
  const [filterTableState, setFilterTableState] = useState<any[]>([]);
  const componentRef = useRef<HTMLDivElement>(null);
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  const [tuiWidth, setTuiWidth] = useState<null | number>(null);
  const [columns, setColumns] = useState<any[]>([]);
  const [excelBtn, setExcelBtn] = useState(false);
  const [rowKey, setRowKey] = useState('');
  const { listCount, setListCount } = useSetListCount('15'); // 검색줄수설정 훅
  const frozenCount = useGetFrozenCount();
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '', code: '', index: 0 });
  const [refetchFlag, setRefetchFlag] = useState(false);

  useEffect(() => {
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '근로자 관리 > 근로자 직무스트레스 관리',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    if (componentRef.current !== null) setTuiHeight(componentRef.current.offsetHeight);
  }, [componentRef.current?.offsetHeight]);

  useEffect(() => {
    if (componentRef.current !== null && componentRef.current !== undefined) setTuiWidth(componentRef.current?.offsetWidth);
  }, [componentRef.current?.offsetWidth]);

  useEffect(() => {
    setColumns(
      userInfo.prejobtypeYn === 'Y'
        ? [
            {
              header: t('근로자명'),
              name: 'wName',
              sortable: true,
              minWidth: 130,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('협력업체'),
              name: 'sjName',
              sortable: true,
              filter: 'select',
              width: 160,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('공종'),
              name: 'wPrejobtypeName',
              sortable: true,
              filter: 'select',
              minWidth: 120,
            },
            {
              header: t('직종'),
              name: 'wJobtypeName',
              sortable: true,
              filter: 'select',
              minWidth: 120,
            },
            {
              header: t('휴대폰번호'),
              name: 'wHnum',
              align: 'center',
              minWidth: 140,
            },
            {
              header: t('총 점수'),
              name: 'stressTotal',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('결과'),
              name: 'stressResult',
              align: 'center',
              filter: 'select',
              minWidth: 120,
            },
            {
              header: t('생년월일'),
              name: 'wBdate',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('취업일자'),
              name: 'wJobdate',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('퇴직일자'),
              name: 'wRetiredate',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('근로 상태'),
              name: 'wWorkstatusName',
              align: 'center',
              sortable: true,
              filter: 'select',
              minWidth: 120,
            },
          ]
        : [
            {
              header: t('근로자명'),
              name: 'wName',
              sortable: true,
              minWidth: 130,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('협력업체'),
              name: 'sjName',
              sortable: true,
              filter: 'select',
              width: 200,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('직종'),
              name: 'wJobtypeName',
              sortable: true,
              filter: 'select',
              minWidth: 120,
            },
            {
              header: t('휴대폰번호'),
              name: 'wHnum',
              align: 'center',
              minWidth: 140,
            },
            {
              header: t('총 점수'),
              name: 'stressTotal',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('결과'),
              name: 'stressResult',
              align: 'center',
              filter: 'select',
              minWidth: 120,
            },
            {
              header: t('생년월일'),
              name: 'wBdate',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('취업일자'),
              name: 'wJobdate',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('퇴직일자'),
              name: 'wRetiredate',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('근로 상태'),
              name: 'wWorkstatusName',
              align: 'center',
              sortable: true,
              filter: 'select',
              minWidth: 120,
            },
          ]
    );
  }, [userInfo.prejobtypeYn, i18n.language]);

  // 근로자 Row 클릭
  const onClickRow = (rowData: any, columnName: any, filteredIndex: number) => {
    setRowKey(`${filteredIndex}`);
    setOpenModal({ status: true, type: 'infoWorker', code: rowData?.wCd, aDate: dayjs().format('YYYY-MM-DD'), index: filteredIndex });
  };

  // 근로자정보 모달에서 정보 수정 시 flag를 true로 변경하고 refetch하여 tuigrid 목록 재조회
  const onClickRefresh = () => {
    setRefetchFlag(true);
  };

  const loading = isFetching;

  if (isError) return <IssueGuide />;

  return (
    <>
      <div className='content-container oneColumn'>
        <Root loading={loading}>
          <WorkerStressSearchOption
            setIsFetching={setIsFetching}
            setIsError={setIsError}
            tableState={tableState}
            setTableState={setTableState}
            setRowKey={setRowKey}
            listCount={listCount}
            setListCount={setListCount}
            setExcelBtn={setExcelBtn}
            refetchFlag={refetchFlag}
            setRefetchFlag={setRefetchFlag}
          />
          <TuiGridWrapper componentRef={componentRef}>
            {tuiWidth !== null && (
              <TuiGrid
                data={tableState}
                filterTableState={filterTableState}
                setFilterTableState={setFilterTableState}
                columns={columns}
                perPage={Number(listCount[LIST_COUNT])}
                excelBtn={excelBtn}
                setExcelBtn={setExcelBtn}
                usePagenation
                onClickRow={onClickRow}
                customCheck
                scrollX
                height={tuiHeight}
                rowKey={rowKey}
                eListYn={eListYn}
                eExcelYn={eExcelYn}
                rowHeight={48}
                frozenCount={frozenCount}
                setRowKey={setRowKey}
              />
            )}
          </TuiGridWrapper>
        </Root>
      </div>
      {loading && (
        <LoadingModalBackground>
          <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
        </LoadingModalBackground>
      )}
      <Portal openModal={openModal?.status}>
        {openModal.status && openModal.type === 'infoWorker' && openModal.code !== undefined && (
          <InfoWorkerModal
            innerTabIndex={0}
            getReportListAPI={onClickRefresh}
            reportArray={filterTableState}
            setReportArray={setFilterTableState}
            setOpenModal={setOpenModal}
            auth={auth}
            code={{ hCd: userInfo.hCd, sCd: userInfo.sCd, wCd: openModal.code, aDate: openModal.aDate }}
            index={openModal.index}
            setRowKey={setRowKey}
            listCount={Number(listCount[LIST_COUNT])}
          />
        )}
      </Portal>
    </>
  );
};

export default WorekrStressManagement;
