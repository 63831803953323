import React, { useEffect, useState, Dispatch, SetStateAction, useMemo } from 'react';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';

import { userState } from '../../../../atoms';
import { CctvLeftSideMenuStyle } from '../../../../assets/styles/CctvLeftSideMenuStyle';
import { LoadingModalBackground } from '../../../../assets/styles/Modal';
import PTZController from './PTZController';
import Portal from '../../../../components/Portal';
import SelectBox from '../../../../components/SelectBox';
import IssueGuide from '../../../../components/IssueGuide';
import LeftedToast from '../../../../components/cctv/LeftedToast';
import LeftedModal from '../../../../components/Modal/LeftedModal';
import ExpandButton from '../../../../components/button/ExpandButton';
import LeftedModalBookmark from '../../../../components/Modal/LeftedModalBookmark';
import { WebSDKcctv } from '../../../../utils/webSDKcctvClass';
import { bookmarkSelectBoxOptions } from '../../../../_constants';
import { initNvrList } from '../../../../services/webSDK';
import { getCctvSettings, patchCctvSettings } from '../../../../hooks/useCctvSettings';
import { nvrListOverwrite } from '../../../../utils/nvrListOverwrite';
import { IsInfraType } from './IsInfraType';
import ExpandableMenu, { InfraMenuStyle } from '../../../../components/cctv/ExpandableMenu';
import { apiGet, apiPatch, apiPost } from '../../../../services/_common';
import { logPost } from '../../../../services/log';

interface ILeftSideProps {
  setDraggingCamInfo: Dispatch<SetStateAction<ISubMenu[] | null>>;
  visiblePanel: boolean;
  nvrList: IMainMenu[];
  setNvrList: Dispatch<SetStateAction<IMainMenu[]>>;
  sInfraList: { sInfra: string; sInfraName: string; cdsortKey: number; expand: boolean }[] | null;
  setSInfraList: Dispatch<SetStateAction<{ sInfra: string; sInfraName: string; cdsortKey: number; expand: boolean }[] | null>>;
  isLoading: boolean;
  isError: boolean;
  setIsDragging: Dispatch<SetStateAction<boolean>>;
  cctvOBJ: WebSDKcctv | undefined;
  cctvSettings: CctvSettings;
  bookmarkedCams: IMainMenu[] | string;
  setBookmarkedCams: Dispatch<SetStateAction<IMainMenu[] | string>>;
  initSelectedCamList: { type: string; camList: string; cdName: string };
  setInitSelectedCamList: Dispatch<SetStateAction<{ type: string; camList: string; cdName: string }>>;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  selectedCctvCameraInfo: SelectedCctvCameraInfo | null;
  cctvDivisions: number;
  setCctvDivisions: Dispatch<SetStateAction<number>>;
  isRecording: boolean;
  setIsRecording: Dispatch<SetStateAction<boolean>>;
  playingCamStatus: any[];
  setPlayingCamStatus: Dispatch<SetStateAction<any[]>>;
  sdkInitLoading: boolean;
  setSdkInitLoading: Dispatch<SetStateAction<boolean>>;
  camPlayLoading: boolean;
  setCamPlayLoading: Dispatch<SetStateAction<boolean>>;
  setSelectedCctvCameraInfo: Dispatch<SetStateAction<SelectedCctvCameraInfo | null>>;
  integrationCamList: any;
}

const LeftSideMenu: React.FC<ILeftSideProps> = ({
  visiblePanel,
  setDraggingCamInfo,
  nvrList,
  setNvrList,
  sInfraList,
  setSInfraList,
  isLoading,
  isError,
  setIsDragging,
  cctvOBJ,
  cctvSettings,
  bookmarkedCams,
  setBookmarkedCams,
  initSelectedCamList,
  setInitSelectedCamList,
  currentPage,
  setCurrentPage,
  selectedCctvCameraInfo,
  cctvDivisions,
  setCctvDivisions,
  isRecording,
  setIsRecording,
  playingCamStatus,
  setPlayingCamStatus,
  sdkInitLoading,
  setSdkInitLoading,
  camPlayLoading,
  setCamPlayLoading,
  setSelectedCctvCameraInfo,
  integrationCamList,
}) => {
  const userInfo = useRecoilValue(userState);
  const isInfraType = IsInfraType(); // 인프라용 여부
  const [expandAll, setExpandAll] = useState<boolean>(isInfraType);
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '', data: null, setDataFunction: null });
  const [selectedCamList, setSelectedCamList] = useState<{ type: string; camList: string | null; cdName: string }>({ type: '', camList: null, cdName: '' });
  const [initLoading, setInitLoading] = useState(true);
  const [zoomType, setZoomType] = useState(1);

  useEffect(() => {
    if (selectedCamList.camList !== null && !sdkInitLoading) {
      if (initLoading) {
        setInitLoading(false);
      } else {
        const camListName = selectedCamList.camList as keyof CctvSettings;
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: 'CCTV 실시간 보기-인프라용',
          action: `즐겨찾기`,
          etc: `${selectedCamList.cdName} 불러오기`,
        });
        if (camListName === 'camList') {
          apiGet({ path: '/cam/camlist', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }).then((res: any) => {
            if (res.data.data.camList) {
              setCctvDivisions(res.data.data.camCType);
              setBookmarkedCams(JSON?.parse(res.data.data.camList));
            } else {
              initiate();
            }
          });
        } else {
          apiGet({ path: '/user/cctv', req: { userId: userInfo.userId } }).then((res) => {
            // 선택한 즐겨찾기에 저장된 값이 있을때
            if (res.data.data[camListName]) {
              setCctvDivisions(Number(cctvSettings.camCType));
              setBookmarkedCams(JSON?.parse(res.data.data[camListName]));
            }
            // 빈 즐겨찾기일때
            else initiate();
          });
        }
        // '즐겨찾기 선택'을 선택했을 때
        if (selectedCamList.camList === '' && cctvOBJ?.getPluginOBJ()?.oPlugin) {
          initiate();
        }
      }
    }
  }, [sdkInitLoading, selectedCamList.camList]);

  // CCTV객체 stop, NVR list 기본값으로 초기화, 북마크목록 빈배열로 초기화
  const initiate = () => {
    const initiatedNvrList = initNvrList(nvrList);
    setNvrList(initiatedNvrList);
    setBookmarkedCams([]);
    cctvOBJ?.stop();
    if (currentPage > 1) setCurrentPage(1);
  };

  // 사용자가 선택한 북마크 옵션에 따라 CCTV 설정의 북마크 업데이트
  const setBookmarkOptionData = (optionValue: '0' | '1' | '2' | '3' | '4' | '5') => {
    let selectedBookmark = 'camList1';
    let camList1 = '';
    let camList2 = '';
    let camList3 = '';
    let camList4 = '';
    let camList5 = '';
    const newNvrList = nvrList.map((nvr) => {
      return {
        nCd: nvr.nCd,
        subList: nvr.subList
          .filter((sub) => sub.isPlaying)
          .map((sub) => {
            return {
              cCd: sub.cCd,
              isPlaying: sub.isPlaying,
              windowIndex: sub.windowIndex,
            };
          }),
      };
    });

    if (optionValue === '0') {
      selectedBookmark = 'camList';
      const isPlayingCamera = cctvOBJ?.getCameraStatusList().find((cam: any) => cam.isPlaying);

      apiPost({
        path: '/cam/camlist',
        req: { hCd: userInfo.hCd, sCd: userInfo.sCd, camList: isPlayingCamera ? JSON.stringify(newNvrList) : '', editor: userInfo.userId, camCType: cctvDivisions },
      }).then((res) => {
        toast(<LeftedToast message='즐겨찾기 저장 완료' />, { position: 'top-left', autoClose: 3000 });
        setSelectedCamList({ type: 'camList', camList: selectedBookmark, cdName: `통합 즐겨찾기` });
        setInitSelectedCamList({ type: 'camList', camList: selectedBookmark, cdName: `통합 즐겨찾기` });
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: 'CCTV 실시간 보기-인프라용',
          action: `즐겨찾기`,
          etc: `통합 즐겨찾기 저장`,
        });
      });
    } else {
      if (optionValue === '1') {
        selectedBookmark = 'camList1';
        // camList2 = cctvSettings.camList2 ? JSON.stringify(cctvSettings.camList2) : '';
        // camList3 = cctvSettings.camList3 ? JSON.stringify(cctvSettings.camList3) : '';
        // camList4 = cctvSettings.camList4 ? JSON.stringify(cctvSettings.camList4) : '';
        // camList5 = cctvSettings.camList5 ? JSON.stringify(cctvSettings.camList5) : '';
      }
      if (optionValue === '2') {
        selectedBookmark = 'camList2';
        // camList1 = cctvSettings.camList1 ? JSON.stringify(cctvSettings.camList1) : '';
        // camList3 = cctvSettings.camList3 ? JSON.stringify(cctvSettings.camList3) : '';
        // camList4 = cctvSettings.camList4 ? JSON.stringify(cctvSettings.camList4) : '';
        // camList5 = cctvSettings.camList5 ? JSON.stringify(cctvSettings.camList5) : '';
      }
      if (optionValue === '3') {
        selectedBookmark = 'camList3';
        // camList1 = cctvSettings.camList1 ? JSON.stringify(cctvSettings.camList1) : '';
        // camList2 = cctvSettings.camList2 ? JSON.stringify(cctvSettings.camList2) : '';
        // camList4 = cctvSettings.camList4 ? JSON.stringify(cctvSettings.camList4) : '';
        // camList5 = cctvSettings.camList5 ? JSON.stringify(cctvSettings.camList5) : '';
      }
      if (optionValue === '4') {
        selectedBookmark = 'camList4';
        // camList1 = cctvSettings.camList1 ? JSON.stringify(cctvSettings.camList1) : '';
        // camList2 = cctvSettings.camList2 ? JSON.stringify(cctvSettings.camList2) : '';
        // camList3 = cctvSettings.camList3 ? JSON.stringify(cctvSettings.camList3) : '';
        // camList5 = cctvSettings.camList5 ? JSON.stringify(cctvSettings.camList5) : '';
      }
      if (optionValue === '5') {
        selectedBookmark = 'camList5';
        // camList1 = cctvSettings.camList1 ? JSON.stringify(cctvSettings.camList1) : '';
        // camList2 = cctvSettings.camList2 ? JSON.stringify(cctvSettings.camList2) : '';
        // camList3 = cctvSettings.camList3 ? JSON.stringify(cctvSettings.camList3) : '';
        // camList4 = cctvSettings.camList4 ? JSON.stringify(cctvSettings.camList4) : '';
      }

      const updatedUserSetting = {
        camList1,
        camList2,
        camList3,
        camList4,
        camList5,
        [selectedBookmark]: JSON.stringify(newNvrList),
        userId: userInfo.userId,
        camSpeed: cctvSettings.camSpeed || '',
        camCPath: cctvSettings.camCPath || '',
        camMPath: cctvSettings.camMPath || '',
        camCType: cctvSettings.camCType || '',
        camCLocation: cctvSettings.camCLocation || '',
        camFColor: cctvSettings.camFColor || '',
      };

      // CCTV 설정 저장 함수 호출
      saveBookmarkOption(updatedUserSetting, selectedBookmark);
    }
  };

  // CCTV 설정 저장 API 요청
  const saveBookmarkOption = async (userSetting: any, targetCamList: string) => {
    try {
      const req = { ...userSetting, userId: userInfo.userId };
      await apiPatch({ path: '/user/cctv', req });
      toast(<LeftedToast message='즐겨찾기 저장 완료' />, { position: 'top-left', autoClose: 3000 });
      setSelectedCamList({ type: 'camList', camList: targetCamList, cdName: `즐겨찾기 ${targetCamList[targetCamList.length - 1]}` });
      setInitSelectedCamList({ type: 'camList', camList: targetCamList, cdName: `즐겨찾기 ${targetCamList[targetCamList.length - 1]}` });
      logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: 'CCTV 실시간 보기-인프라용',
        action: `즐겨찾기`,
        etc: `즐겨찾기 ${targetCamList[targetCamList.length - 1]} 저장`,
      });
    } catch (error) {
      console.error(error);
    }
  };
  // 즐겨찾기 저장 버튼 클릭 이벤트 핸들러

  const onClickBookmark = () => {
    setOpenModal({ status: true, type: 'bookmark', setDataFunction: (bookmarkType: '0' | '1' | '2' | '3' | '4' | '5') => setBookmarkOptionData(bookmarkType), gCd: userInfo.gCd });
  };

  // 전체 확장/축소 기능을 토글하는 함수
  const expandAllMenu = async () => {
    // setExpandAll((prev) => !prev);
    cctvOBJ?.pause(0);
  };
  const expandAllMenu2 = async () => {
    // setExpandAll((prev) => !prev);
    cctvOBJ?.resume(0);
  };

  // 전체 확장 상태가 변경될 때 NVR 리스트 업데이트
  useEffect(() => {
    if (!isInfraType) setNvrList(nvrList.map((el: IMainMenu) => ({ ...el, expand: expandAll })));
    else sInfraList && setSInfraList((prev) => prev && prev?.map((el) => ({ ...el, expand: false })));
  }, [expandAll]);

  const infraDetails = useMemo(() => {
    return sInfraList?.reduce((acc: any, infra: any) => {
      acc[infra.sInfra] = { ...infra, expand: !!infra.expand };
      return acc;
    }, {});
  }, [sInfraList]);

  const expandInfraMenu = (sInfraCode: string) => {
    if (sInfraList) {
      const newArray = sInfraList.map((el) => (el.sInfra === sInfraCode ? { ...el, expand: !el.expand } : el));
      setSInfraList(newArray);
    }
  };

  if (isError) return <IssueGuide />;

  if (isLoading) {
    return (
      <LoadingModalBackground>
        <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
      </LoadingModalBackground>
    );
  }

  return (
    <CctvLeftSideMenuStyle className={visiblePanel ? 'visible' : 'hidden'} onMouseUp={() => setIsDragging(false)}>
      <div className='list'>
        <div className='bookmarkWrapper'>
          <ExpandButton isExpanded={expandAll} onClickExpandButton={expandAllMenu} />
          <SelectBox
            options={bookmarkSelectBoxOptions} //
            defaultOption='즐겨찾기 선택'
            state={selectedCamList}
            setState={setSelectedCamList}
            stateKey='camList'
            clickAble={isRecording}
            toastContent='녹화를 종료한 후에 시도하세요'
            toastLocation='left'
            initiateKey={initSelectedCamList.camList}
            disabled={camPlayLoading}
          />
          <button className='bookmark' type='button' onClick={onClickBookmark}>
            <span className='material-symbols-rounded'>bookmark_add</span>
          </button>
        </div>
        <div className='menuWrapper'>
          {nvrList.map((el: IMainMenu) => {
            const infra = sInfraList && infraDetails[el.sInfra];
            const displayInfraMenu = infra && el.cdSort === infra.cdsortKey;

            return (
              <React.Fragment key={el.nCd}>
                {displayInfraMenu && (
                  <InfraMenuStyle className={infra.expand ? 'expanded' : ''}>
                    <ExpandButton isExpanded={infra.expand} onClickExpandButton={() => !camPlayLoading && expandInfraMenu(el.sInfra)} />
                    <span role='button' tabIndex={0} onClick={() => !camPlayLoading && expandInfraMenu(el.sInfra)}>
                      {infra.sInfraName}
                    </span>
                  </InfraMenuStyle>
                )}

                {((isInfraType && infra?.expand) || !isInfraType) && (
                  <ExpandableMenu
                    key={el.nCd}
                    mainMenu={el}
                    nvrList={nvrList}
                    setNvrList={setNvrList}
                    setDraggingCamInfo={setDraggingCamInfo}
                    cctvOBJ={cctvOBJ}
                    setOpenModal={setOpenModal}
                    setIsDragging={setIsDragging}
                    cctvDivisions={cctvDivisions}
                    currentPage={currentPage}
                    selectedCctvCameraInfo={selectedCctvCameraInfo}
                    setIsRecording={setIsRecording}
                    type='realtime'
                    cctvSettings={cctvSettings}
                    playingCamStatus={playingCamStatus}
                    setPlayingCamStatus={setPlayingCamStatus}
                    sdkLoading={sdkInitLoading}
                    setSdkLoading={setSdkInitLoading}
                    setSelectedCctvCameraInfo={setSelectedCctvCameraInfo}
                  />
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
      {cctvOBJ !== undefined && !camPlayLoading && (
        <PTZController //
          cctvOBJ={cctvOBJ}
          cctvSettings={cctvSettings}
          selectedCctvCameraInfo={selectedCctvCameraInfo}
          nvrList={nvrList}
          zoomType={zoomType}
          setZoomType={setZoomType}
        />
      )}
      <Portal openModal={openModal?.status}>
        {openModal.type === 'stream' && <LeftedModal openModal={openModal} setOpenModal={setOpenModal} />}
        {openModal.type === 'bookmark' && <LeftedModalBookmark openModal={openModal} setOpenModal={setOpenModal} />}
      </Portal>
    </CctvLeftSideMenuStyle>
  );
};

export default LeftSideMenu;
