/**
 * 작성자 : 홍선영
 * 날짜 : 2024.01.12
 * 경로 : 안전 관리 > 긴급 상황 정보 탭
 */
import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { PulseLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { IoChevronUpSharp } from 'react-icons/io5';
import dayjs from 'dayjs';
import styled from 'styled-components';

import i18n from '../../../translation/i18n';
import { IComCdList } from 'customTypes';
import { IUser, asideFoldState, userState } from '../../../atoms';
import { ACTION_STATUS, COMCD_EMERGENCY_GUBUN, COMCD_EMERGENCY_LEVEL, COMCD_EMERGENCY_TYPE, LIST_COUNT } from '../../../_constants';
import { BtnGhost } from '../../../components/Button';
import SearchSelectBoxSm from '../../../components/SearchSelectBoxSm';
import Portal from '../../../components/Portal';
import TuiGrid from '../../../components/Table/TuiGrid';
import SelectBox from '../../../components/SelectBox';
import TuiGridWrapper from '../../../components/Table/TuiGridWrapper';
import EmergencyStatusModal from '../../../components/Modal/emergencyStatusModal';
import RangePicker, { onChangeRangeInput } from '../../../components/RangePicker';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { FloatingButtonToTop } from '../../../assets/styles/FloatingButtonToTop';
import { ContentsContainerRoot } from '../../../assets/styles/ContentsContainerRoot';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import { useSetAuth } from '../../../utils/useSetAuth';
import { scrollToNodeTop } from '../../../utils/scrollToNodeTop';
import { useGetFrozenCount } from '../../../utils/getFrozenCount';
import { setComCdListState } from '../../../utils/setComCdListState';
import { arraySortByAscdOrder } from '../../../utils/arraySortByAscdOrder';
import { formatDate, todayYYYYMMDD } from '../../../utils/formatDate';
import { useDetectScrolledToBottom } from '../../../utils/useDetectScrolledToBottom';
import { getTimeDifferenceFromCurrentTime } from '../../../utils/getTimeDifference';
import useOnKeydownF9 from '../../../utils/useOnKeydownF9';
import useSetListCount from '../../../utils/useSetListCount';
import { apiGet } from '../../../services/_common';
import { logPost } from '../../../services/log';
import ShortcutButton from '../../../components/button/ShortcutButton';

const Root = styled(ContentsContainerRoot)`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .btn-in-cell {
    height: 2rem;
    font-size: 0.875rem;
    padding-left: 0.5rem;
    padding-right: 0.75rem;
    margin: 0 auto;
    font-weight: 600;
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.board};
    }
    .material-symbols-rounded {
      margin-right: 0.25rem;
    }
  }
  .btn-blue {
    background-color: ${({ theme }: { theme: any }) => theme.filled_blue} !important;
    border: none !important;
    /* color: ${({ theme }: { theme: any }) => theme.tonal_blue} !important; */
    color: ${({ theme }: { theme: any }) => theme.color.blue_100} !important;
  }
  .btn-red {
    background-color: ${({ theme }: { theme: any }) => theme.filled_red} !important;
    border: none !important;
    /* color: ${({ theme }: { theme: any }) => theme.tonal_red} !important; */
    color: ${({ theme }: { theme: any }) => theme.color.red_100} !important;
  }
  .btn-blue,
  .btn-red {
    .material-symbols-rounded {
      font-size: 1.375rem;
    }
  }

  .badge {
    font-weight: 500;
    font-size: 0.875rem;
    padding: 0.125rem 0.5rem;
    border-radius: 0.25rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
    margin: 0 0.5rem;
    color: rgb(62, 151, 255);
    display: inline-block;
    min-width: 1.75rem;
    text-align: center;
  }

  .blue {
    color: ${({ theme }: { theme: any }) => theme.filled_blue};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
  }

  .red {
    color: ${({ theme }: { theme: any }) => theme.filled_red};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
  }

  .violet {
    color: ${({ theme }: { theme: any }) => theme.filled_violet};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_violet};
  }

  .orange {
    color: ${({ theme }: { theme: any }) => theme.filled_orange};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
  }

  .green {
    color: ${({ theme }: { theme: any }) => theme.filled_green};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
  }

  .amber {
    color: ${({ theme }: { theme: any }) => theme.filled_amber};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_amber};
  }

  .slate {
    color: ${({ theme }: { theme: any }) => theme.filled_slate};
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
  }
`;

const EmergencyStatus = () => {
  const { t } = useTranslation();
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const { listCount, setListCount, patchUserMenuAPI } = useSetListCount(); // 검색줄수설정 훅
  const { auth } = useSetAuth();
  const userInfo = useRecoilValue<IUser>(userState);
  const initTatCd = { type: 'tatCd', tatCd: 'A', cdName: t('전체') };
  const initTatGubunCd = { type: 'tatGubunCd', tatGubunCd: 'A', cdName: t('전체') };
  const initTadCd = { type: 'tadCd', tadCd: 'A', cdName: t('전체') };
  const initEGubun = { type: COMCD_EMERGENCY_GUBUN, [COMCD_EMERGENCY_GUBUN]: 'A', cdName: t('전체') };
  const initELevel = { type: COMCD_EMERGENCY_LEVEL, [COMCD_EMERGENCY_LEVEL]: 'A', cdName: t('전체') };
  const initEType = { type: COMCD_EMERGENCY_TYPE, [COMCD_EMERGENCY_TYPE]: 'A', cdName: t('전체') };
  const initActionStatus = { type: 'actionStatus', actionStatus: 'A', cdName: t('전체') };
  const { eExcelYn, eListYn, ePrintYn } = userInfo;
  const [columns, setColumns] = useState<any[]>([]);
  const tableRef = useRef<HTMLDivElement>(null);
  const [listCountComCdList, setListCountComCdList] = useState<IComCdList[]>([]); // 검색줄수 공통코드
  const [eGubunComCdList, setEGubunComCdList] = useState<IComCdList[]>([]); // 긴급상황 구분 공통코드
  const [eLevelComCdList, setELevelComCdList] = useState<IComCdList[]>([]); // 긴급상황 레벨 공통코드
  const [eTypeComCdList, setETypeComCdList] = useState<IComCdList[]>([]); // 긴급상황 유형 공통코드
  const [excelBtn, setExcelBtn] = useState(false);
  const [tableState, setTableState] = useState<any[] | null>([]);
  const [filterTableState, setFilterTableState] = useState<any[]>([]);
  const { isBottom } = useDetectScrolledToBottom(tableRef);
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '', code: '', index: 0 });
  const [rowKey, setRowKey] = useState('');
  const foldMenu = useRecoilValue(asideFoldState);
  const [tatCdList, setTatCdList] = useState<any[]>([]);
  const [tatGubunCdList, setTatGubunCdList] = useState<any[]>([]);
  const [tadCdList, setTadCdList] = useState<any[]>([]);
  const [tatCd, setTatCd] = useState(initTatCd);
  const [tatGubunCd, setTatGubunCd] = useState(initTatGubunCd);
  const [tadCd, setTadCd] = useState(initTadCd);
  const [eGubun, setEGubun] = useState(initEGubun);
  const [eLevel, setELevel] = useState(initELevel);
  const [eType, setEType] = useState(initEType);
  const [actionStatus, setActionStatus] = useState(initActionStatus);
  const componentRef = useRef<HTMLDivElement>(null);
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  const frozenCount = useGetFrozenCount();
  const toDay = todayYYYYMMDD();
  const [rangeState, setRangeState] = useState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
  const [dates, setDates] = useState({ start: toDay, end: toDay });
  const [visibleRangePicker, setVisibleRangePicker] = useState(false);
  const [search, setSearch] = useState(true); // 검색버튼 클릭여부
  const MESSAGE = t('구역명과 시/종점을 먼저 선택하세요');

  useEffect(() => {
    const getDLevelList = async () => {
      const req = { grCd: COMCD_EMERGENCY_LEVEL };
      const res = await apiGet({ path: '/code/detail', req });
      const { statusCode, data } = res.data;
      if (statusCode === 200) {
        let newArr = data.comCdList;
        const useYList = newArr.filter((el: any) => el.useYn === 'Y' && el.subCd !== '0'); // '좋음' 제외
        const newArray = useYList.map((el: any) => ({ type: el.grCd, [el.grCd]: el.subCd, cdName: el.cdName }));
        newArr = newArray;
        const defaultObject = { type: COMCD_EMERGENCY_LEVEL, [COMCD_EMERGENCY_LEVEL]: 'A', cdName: t('전체'), cdSort: 0 };
        newArr = [defaultObject, ...newArray];
        setELevelComCdList(arraySortByAscdOrder(newArr, 'cdSort'));
      }
    };

    setComCdListState(COMCD_EMERGENCY_GUBUN, setEGubunComCdList, true);
    setComCdListState(COMCD_EMERGENCY_TYPE, setETypeComCdList, true);
    setComCdListState(LIST_COUNT, setListCountComCdList, false);
    getDLevelList();
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '긴급 상황 정보',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight, foldMenu]);

  const tAreaQuery = useQuery(['tareaGet', userInfo.hCd, userInfo.sCd], () => fetchTareaData(), { enabled: !!userInfo.hCd && !!userInfo.sCd });

  const fetchTareaData = async () => {
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
      const res = await apiGet({ path: '/tarea', req });
      const { tareaList } = res.data.data || {};
      if (!tareaList) return [];
      return tareaList || [];
    } catch (error) {
      console.error('error', error);
      throw new Error('error');
    }
  };

  useEffect(() => {
    if (tAreaQuery.data) {
      const newArray = tAreaQuery.data.filter((el: any) => el.useYn === 'Y').map((el: any) => ({ type: 'tatCd', tatCd: el.tatCd, cdName: el.tatName }));
      setTatCdList([initTatCd, ...newArray]);
    }
  }, [tAreaQuery.data]);

  const tAreaDQuery = useQuery(
    ['tAreaDGet', userInfo.hCd, userInfo.sCd, tatCd.tatCd, tatGubunCd.tatGubunCd],
    () => apiGet({ path: '/tarea/d', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, tatCd: tatCd.tatCd, tatGubun: tatGubunCd.tatGubunCd } }),
    {
      enabled: !!userInfo.hCd && !!userInfo.sCd && !!tatCd.tatCd && tatCd.tatCd !== '' && !!tatGubunCd.tatGubunCd && tatGubunCd.tatGubunCd !== '',
    }
  );

  // 긴급 상황 정보 목록조회 useQuery
  const emergencyListQuery = useQuery(['safeListGet', userInfo.hCd, userInfo.sCd, search], () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && userInfo.sCd !== '00000',
  });

  // 긴급 상황 정보 목록조회 데이터패치 함수
  const fetchData = async () => {
    try {
      const req = {
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        time1: dates.start.replaceAll('-', ''),
        time2: dates.end.replaceAll('-', ''),
        ...(tatCd.tatCd !== 'A' && { tatCd: tatCd.tatCd }),
        ...(tatGubunCd.tatGubunCd !== 'A' && { tatGubun: tatGubunCd.tatGubunCd }),
        ...(tadCd.tadCd !== 'A' && { tadCd: tadCd.tadCd }),
        ...(eGubun[COMCD_EMERGENCY_GUBUN] !== 'A' && { dGubun: eGubun.cdName }),
        ...(eLevel[COMCD_EMERGENCY_LEVEL] !== 'A' && { dLevel: eLevel.cdName }),
        ...(eType[COMCD_EMERGENCY_TYPE] !== 'A' && { dType: eType.cdName }),
        ...(actionStatus.actionStatus !== 'A' && { actionStatus: actionStatus.actionStatus }),
      };
      const res = await apiGet({ path: '/safe/ea', req });
      const data = res.data?.data || [];
      const newArray = data.map((el: any) => ({
        ...el,
        dGubunHtml: el.dGubun ? `<span class='badge ${el.dGubun === '센서' ? 'amber' : el.dGubun === '비콘' ? 'green' : el.dGubun === '출입통제' ? 'blue' : ''}'>${el.dGubun}</span>` : '',
        dLevel: el.dLevel,
        actionStatusHtml: el.actionStatus
          ? `<span class='badge ${el.actionStatus === '0' ? 'red' : el.actionStatus === '1' ? 'green' : el.actionStatus === '2' ? 'blue' : ''}'>${
              ACTION_STATUS.find((action: any) => el.actionStatus === action.actionStatus)?.cdName || ''
            }</span>`
          : '',
        time: formatDate(el.time),
        elapsedTime: getTimeDifferenceFromCurrentTime(formatDate(el.time)),
        actionDateInFormat: formatDate(el.actionDate),
      }));
      setTableState(newArray);
      return data;
    } catch (error) {
      console.error(error);
      throw new Error('error /safe/ea');
    } finally {
      setSearch(false);
    }
  };

  useEffect(() => {
    if (tAreaDQuery.isSuccess && tAreaDQuery.data.status === 200) {
      const { tareaDList } = tAreaDQuery.data.data.data;
      const newArray = tareaDList.filter((el: any) => el.useYn === 'Y').map((el: any) => ({ type: 'tadCd', tadCd: el.tadCd, cdName: el.tadName }));
      setTadCdList([initTadCd, ...newArray]);
    }
  }, [tAreaDQuery.isSuccess, tAreaDQuery.isRefetching, tatCd.tatCd]);

  useEffect(() => {
    setColumns([
      {
        header: t('구분'),
        name: 'dGubunHtml',
        align: 'center',
        sortable: true,
        width: 100,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('단계'),
        name: 'dLevel',
        align: 'center',
        sortable: true,
        minWidth: 80,
      },
      {
        header: t('발생시간'),
        name: 'time',
        align: 'center',
        sortable: true,
        minWidth: 180,
      },
      {
        header: t('경과시간'),
        name: 'elapsedTime',
        sortable: true,
        minWidth: 160,
      },
      {
        header: t('유형'),
        name: 'dType',
        sortable: true,
        minWidth: 120,
      },
      {
        header: t('구역 / 관련 내용'),
        name: 'dContents',
        sortable: true,
        minWidth: 180,
      },
      {
        header: t('조치상태'),
        name: 'actionStatusHtml',
        sortable: true,
        minWidth: 120,
        align: 'center',
      },
      {
        header: t('조치자'),
        name: 'userName',
        sortable: true,
        minWidth: 120,
      },
      {
        header: t('조치시간'),
        name: 'actionDateInFormat',
        align: 'center',
        sortable: true,
        minWidth: 180,
      },
    ]);
  }, [userInfo.prejobtypeYn, tableState, i18n.language]);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    if (!rangeState[0].startDate) {
      return;
    }
    setDates((prev: any) => ({ ...prev, start: dayjs(rangeState[0].startDate).format('YYYY-MM-DD') }));
  }, [rangeState[0].startDate]);

  useEffect(() => {
    if (!rangeState[0].endDate) {
      return;
    }
    setDates((prev: any) => ({ ...prev, end: dayjs(rangeState[0].endDate).format('YYYY-MM-DD') }));
  }, [rangeState[0].endDate]);

  // 검색버튼 클릭 이벤트
  const onClickSearch = () => {
    setRowKey('');
    if (!openModal.status) {
      if (!dates.end && !dates.start) {
        toast.warning(t('발생일자를 입력하세요'));
      } else if (dates.end && dates.start > dates.end) {
        toast.warning(t('발생일자를 다시 입력하세요'));
        setDates((prev: any) => ({ ...prev, end: '' }));
      } else {
        emergencyListQuery.refetch();
        setVisibleRangePicker(false);
      }
    }
  };

  const onClickRangeInput = () => {
    if (!visibleRangePicker) setVisibleRangePicker(true);
  };

  const initiateSearchOptions = () => {
    setEGubun(initEGubun);
    setELevel(initELevel);
    setEType(initEType);
    setActionStatus(initActionStatus);
    setTatCd(initTatCd);
    setTadCd(initTadCd);
    setTatGubunCd(initTatGubunCd);
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    initiateSearchOptions();
    setRangeState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
  };

  // 긴급상황 Row 클릭
  const onClickRow = (rowData: any, columnName: any, filteredIndex: number) => {
    if (filteredIndex > -1) {
      setRowKey(`${filteredIndex}`);
      setOpenModal({ status: true, type: 'emergencyStatusModal', code: rowData.eaIdx, index: filteredIndex });
    }
  };

  // 선택한 구역의 시/종점 사용 유무에 따라 시/종점 선택셀렉트 변경
  useEffect(() => {
    if (tatCd.tatCd && tAreaQuery.data) {
      const findTarea = tAreaQuery.data.find((el: any) => el.tatCd === tatCd.tatCd);
      const d = initTatGubunCd;
      const s = { type: 'tatGubunCd', tatGubunCd: 'S', cdName: t('시점') };
      const e = { type: 'tatGubunCd', tatGubunCd: 'E', cdName: t('종점') };
      if (findTarea) {
        if (findTarea.sUseYn === 'Y' && findTarea.eUseYn === 'Y') setTatGubunCdList([d, s, e]);
        else if (findTarea.sUseYn === 'Y' && findTarea.eUseYn === 'N') setTatGubunCdList([d, s]);
        else if (findTarea.sUseYn === 'N' && findTarea.eUseYn === 'Y') setTatGubunCdList([d, e]);
        else setTatGubunCdList([d]);
      } else setTatGubunCdList([d]);
    }
    setTatGubunCd(initTatGubunCd);

    // 구역명이 변경된 경우, 상세구역명 셀렉트값, 목록 초기화
    setTadCd(initTadCd);
    setTadCdList([initTadCd]);
  }, [tatCd.tatCd, tAreaQuery.data]);

  useEffect(() => {
    // 시/종점이 변경된 경우, 상세구역명 셀렉트값, 목록 초기화
    setTadCd(initTadCd);
    if (tatGubunCd.tatGubunCd === 'A') setTadCdList([initTadCd]);
  }, [tatGubunCd.tatGubunCd]);

  return (
    <Root loading={emergencyListQuery.isLoading || emergencyListQuery.isRefetching || !emergencyListQuery.data}>
      <SearchOptions>
        {isBottom && (
          <FloatingButtonToTop>
            <button type='button' onClick={() => scrollToNodeTop(tableRef)}>
              <IoChevronUpSharp size={20} style={{ stroke: 'white' }} />
            </button>
          </FloatingButtonToTop>
        )}
        <div className='inputsWrapper'>
          <div className='inputForm-row withLabel'>
            <label htmlFor='eGubun'>{t('구분')}</label>
            <div className='inputForm-col'>
              <SearchSelectBoxSm
                options={eGubunComCdList}
                defaultOption={t('전체')}
                state={eGubun}
                setState={setEGubun}
                stateKey={COMCD_EMERGENCY_GUBUN}
                codeKey='cdName'
                initiateKey={eGubun[COMCD_EMERGENCY_GUBUN]}
                filterbar='filter-1-left'
                dropDownWidth='fit-content'
                optionHeight='height-md'
              />
            </div>
          </div>
          <div className='inputForm-row-fit'>
            <span className='calendarLabel'>{t('발생일자')}</span>
            <div className='inputForm-col'>
              <div className='flex-basic'>
                <input id='startInput' type='text' value={dates.start} onMouseDown={onClickRangeInput} onChange={(e) => onChangeRangeInput(e, 'start', setRangeState, setDates)} />
                <span className='inputDash'> ~ </span>
                <input id='endInput' type='text' value={dates.end} onMouseDown={onClickRangeInput} onChange={(e) => onChangeRangeInput(e, 'end', setRangeState, setDates)} />
              </div>
              {visibleRangePicker && (
                <div className='rangePickerWrapper'>
                  <RangePicker state={rangeState} setState={setRangeState} setVisible={setVisibleRangePicker} />
                </div>
              )}
            </div>
          </div>
          <div className='inputForm-row withLabel'>
            <label htmlFor='wWorkstatus'>{t('단계')}</label>
            <div className='inputForm-col'>
              <SearchSelectBoxSm
                options={eLevelComCdList}
                defaultOption={t('전체')}
                state={eLevel}
                setState={setELevel}
                stateKey={COMCD_EMERGENCY_LEVEL}
                codeKey='cdName'
                initiateKey={eLevel[COMCD_EMERGENCY_LEVEL]}
                filterbar='filter-1-left'
                optionHeight='height-md'
              />
            </div>
          </div>
          <div className='inputForm-row withLabel'>
            <label htmlFor='wWorkstatus'>{t('유형')}</label>
            <div className='inputForm-col'>
              <SearchSelectBoxSm
                options={eTypeComCdList}
                defaultOption={t('전체')}
                state={eType}
                setState={setEType}
                stateKey={COMCD_EMERGENCY_TYPE}
                codeKey='cdName'
                initiateKey={eType[COMCD_EMERGENCY_TYPE]}
                filterbar='filter-1-left'
                optionHeight='height-md'
              />
            </div>
          </div>
          <div className='inputForm-row withLabel'>
            <label htmlFor='actionStatus'>{t('조치상태')}</label>
            <div className='inputForm-col'>
              <SearchSelectBoxSm
                options={ACTION_STATUS}
                defaultOption={t('전체')}
                state={actionStatus}
                setState={setActionStatus}
                stateKey='actionStatus'
                codeKey='cdName'
                initiateKey={actionStatus.actionStatus}
                filterbar='filter-1-left'
                optionHeight='height-md'
              />
            </div>
          </div>
          <div className='inputForm-row withLabel'>
            <label htmlFor='tatCd'>{t('구역 명')}</label>
            <div className='inputForm-col'>
              <SearchSelectBoxSm
                options={tatCdList}
                defaultOption={t('전체')}
                state={tatCd}
                setState={setTatCd}
                stateKey='tatCd'
                codeKey='cdName'
                initiateKey={tatCd.tatCd}
                filterbar='filter-1-right'
                comboWidth='expand-box-sm'
                optionHeight='height-md'
              />
            </div>
          </div>
          <div className='inputForm-row withLabel'>
            <label htmlFor='tatGubunCd'>{t('시/종점')}</label>
            <div className='inputForm-col'>
              <SelectBox
                options={tatGubunCdList}
                defaultOption={t('전체')}
                state={tatGubunCd}
                setState={setTatGubunCd}
                stateKey='tatGubunCd'
                initiateKey={tatGubunCd.tatGubunCd}
                optionHeight='height-md'
              />
            </div>
          </div>
          <div className='inputForm-row withLabel'>
            <label htmlFor='tadCd'>{t('상세구역 명')}</label>
            <div className='inputForm-col'>
              <SearchSelectBoxSm
                options={tadCdList}
                defaultOption={t('전체')}
                state={tadCd}
                setState={setTadCd}
                stateKey='tadCd'
                codeKey='cdName'
                initiateKey={tadCd.tadCd}
                filterbar='filter-1-right'
                comboWidth='expand-box-sm'
                optionHeight='height-md'
                clickAble={tatGubunCd.tatGubunCd === 'A' || tatCd.tatCd === 'A'}
                toastContent={MESSAGE}
              />
            </div>
          </div>
        </div>
        <div className='inputsWrapper'>
          <div className='secondSearchOption'>
            <div className='flex-basic textBtnGroup'>
              <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
              {/* <BtnGhost onClick={() => onClickSearch()} className='searchBtn'>
                {t('검색')}
                <span className='shortcut-f9'>F9</span>
              </BtnGhost> */}
              <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
              <div className='searchResult'>
                {t('총')}
                <span>{tableState?.length}</span>
                {t('개')}
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col withLabelComCf'>
                  <label htmlFor='useYn'>{t('보기 설정')}</label>
                  <SelectBox
                    options={listCountComCdList}
                    defaultOption={listCount[LIST_COUNT]}
                    state={listCount}
                    setState={setListCount}
                    stateKey={LIST_COUNT}
                    initiateKey={listCount[LIST_COUNT]}
                    setTableLinesAPI={patchUserMenuAPI}
                    optionHeight='height-sm'
                    rsearch
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SearchOptions>
      {tableState && (
        <TuiGridWrapper componentRef={componentRef}>
          <TuiGrid
            data={tableState}
            filterTableState={filterTableState}
            setFilterTableState={setFilterTableState}
            columns={columns}
            perPage={Number(listCount[LIST_COUNT])}
            excelBtn={excelBtn}
            setExcelBtn={setExcelBtn}
            usePagenation
            onClickRow={onClickRow}
            // height={componentRef.current?.offsetHeight}
            // visibleTotalCount
            scrollX
            height={tuiHeight}
            rowKey={rowKey}
            eListYn={eListYn}
            eExcelYn={eExcelYn}
            frozenCount={frozenCount}
            setRowKey={setRowKey}
          />
        </TuiGridWrapper>
      )}

      {(emergencyListQuery.isLoading || emergencyListQuery.isRefetching || !emergencyListQuery.data) && (
        <LoadingModalBackground>
          <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
        </LoadingModalBackground>
      )}
      <Portal openModal={openModal?.status}>
        {openModal.type === 'emergencyStatusModal' && (
          <EmergencyStatusModal
            refetch={() => emergencyListQuery.refetch()}
            reportArray={filterTableState}
            setReportArray={setFilterTableState}
            setOpenModal={setOpenModal}
            auth={auth}
            index={openModal.index}
            setRowKey={setRowKey}
            listCount={Number(listCount[LIST_COUNT])}
          />
        )}
      </Portal>
    </Root>
  );
};

export default EmergencyStatus;
