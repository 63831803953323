/**
 * 작성자 : 홍선영
 * 날짜 : 2023.07.05
 * 경로 : 출역 관리 - 개인별 월 출역현황
 */

import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { IoChevronUpSharp } from 'react-icons/io5';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { IModal, ITabObject } from 'customTypes';
import { pageInfoState, themeState, userState } from '../../atoms';
import { MONTH_LIST, YEAR_LIST } from '../../_constants';
import { dark, light } from '../../assets/styles/theme';
import { SearchOptions } from '../../assets/styles/SearchOptions';
import { FloatingButtonToTop } from '../../assets/styles/FloatingButtonToTop';
import { ContentsContainerRoot } from '../../assets/styles/ContentsContainerRoot';
import { LoadingModalBackground, ModalBackground } from '../../assets/styles/Modal';
import { BtnGhost, BtnLightViolet } from '../../components/Button';
import Tab from '../../components/Tab';
import Portal from '../../components/Portal';
import Toggle from '../../components/Toggle';
import WorkerListModal from '../../components/Modal/WorkerListModal';
import SearchSelectBoxSm from '../../components/SearchSelectBoxSm';
import AttendDailyPersonalListModal from '../../components/Modal/AttendDailyPersonalListModal';
import { useSetAuth } from '../../utils/useSetAuth';
import { trimObject } from '../../utils/trimObject';
import { useSetSjcd } from '../../utils/useSetSjcd';
import { todayYYYYMMDD } from '../../utils/formatDate';
import { scrollToNodeTop } from '../../utils/scrollToNodeTop';
import { useDetectScrolledToBottom } from '../../utils/useDetectScrolledToBottom';
import useSetJobtype from '../../utils/useSetJobtype';
import useOnKeydownF9 from '../../utils/useOnKeydownF9';
import { logPost } from '../../services/log';
import i18n from '../../translation/i18n';
import { apiGet } from '../../services/_common';
import SelectBox from '../../components/SelectBox';
import ShortcutButton from '../../components/button/ShortcutButton';

const Root = styled(ContentsContainerRoot)`
  display: flex;
  flex-direction: column;
  .btn {
    button {
      height: 2.5rem;
      padding: 0 0.75rem;
      font-size: 0.875rem;
    }
  }
  > div:nth-child(2) {
    overflow: auto;
  }
  .calendarMonthTitle {
    text-align: center;
    margin-top: 1rem;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }

  .calendar {
    display: grid;
    overflow: hidden;
    padding: 0.5rem;
    border-radius: 10px;
    overflow: auto;
    .days {
      min-width: 12.5rem;
      text-align: center;
      padding: 0.5rem;
      font-weight: 700;
      border: 0.0625rem solid ${({ theme }: { theme: any }) => theme.outline};
      outline: 0.0625rem solid ${({ theme }: { theme: any }) => theme.outline};
      background-color: ${({ theme }: { theme: any }) => theme.background};
      /* background-color: rgb(243, 246, 249); */
    }

    .emptyCells {
      border: 0.0625rem solid ${({ theme }: { theme: any }) => theme.outline};
    }
    .date {
      height: 12rem;
      font-weight: 600;
      outline: 0.0625rem solid ${({ theme }: { theme: any }) => theme.outline};
      padding: 1rem;
      cursor: pointer;
      img {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        height: calc(100% - 2rem);
      }
      > div {
        height: 100%;
        > div:first-child {
          height: calc(100% - 0.6rem);
        }
        > div:nth-child(1) > div:nth-child(2) {
          color: ${({ theme }: { theme: any }) => theme.text_secondary};
        }
        > div:nth-child(2) {
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        }
      }
    }
  }
`;

interface ISearchOption {
  wName: string;
  wCd: string;
}

const Sattend6 = () => {
  dayjs.locale(i18n.language === 'en-US' ? 'en' : 'ko');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const { siteJoinInfoList } = useSetSjcd();
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  const toDay = todayYYYYMMDD();
  const userInfo = useRecoilValue(userState);
  const { userMenuList } = userInfo;
  const wNameRef = useRef<HTMLInputElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const [jikJongList, setJikJongList] = useState<any[]>([]);
  const [searchOption, setSearchOption] = useState<ISearchOption>({ wName: '', wCd: '' });
  const [sjCd, setSjCd] = useState({ type: 'sjCd', sjCd: '', cdName: '' });
  const [aYear, setAYear] = useState({ type: 'aYear', aYear: toDay.substring(0, 4), cdName: toDay.substring(0, 4) });
  const [month, setMonth] = useState({ type: 'month', month: toDay.substring(4, 6), cdName: toDay.substring(4, 6) });
  const [wPrejobtype, setWPrejobtype] = useState({ type: 'wPrejobtype', wPrejobtype: '', cdName: '' });
  const [wJobtype, setWJobtype] = useState({ type: 'wJobtype', wJobtype: '', cdName: '' });
  const { prejobtypeList, jobtypeList } = useSetJobtype(wPrejobtype.wPrejobtype);
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', code: { wCd: '' }, isOverlappingModal: false });
  const [selectedUser, setSelectedUser] = useState<any>(); // 근로자 검색팝업에서 선택한 유저
  const [searchCheck, setSearchCheck] = useState<boolean>(false); // 근로자 검색절차 완료 여부
  const [photoViewYn, setPhotoViewYn] = useState(true);
  const { isBottom } = useDetectScrolledToBottom(tableRef);
  const [newTabList, setNewTabList] = useState<ITabObject[]>();
  const theme = useRecoilValue(themeState);
  const [loading, setLoading] = useState(false);
  const todayjs = dayjs();

  useEffect(() => {
    const arr: any[] = [];
    userMenuList.map((v: any) => {
      const result = tabInfo?.tabList?.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const getDateArray = () => {
    let startDay = dayjs(`${aYear.aYear}-${month.month}-01`);
    const endDay = startDay.endOf('month');
    const dateArray: any = [];

    while (startDay.isBefore(endDay)) {
      // dateArray.push(startDay.format('DD ddd'));
      dateArray.push({ date: startDay.format('DD'), dayOfWeek: startDay.day(), aImg: '', aTimeMin: '', aTimeMax: '' });
      startDay = startDay.add(1, 'day');
    }
    return dateArray;
  };
  const [dateArray, setDateArray] = useState([]);
  const [firstDayOfMonth, setFirstDayOfMonth] = useState(dayjs(`${aYear.aYear}-${month.month}-01`).day());
  const [totalDays, setTotalDays] = useState(firstDayOfMonth + dateArray.length);
  const [remainingCells, setRemainingCells] = useState(totalDays < 35 ? 35 - totalDays : 42 - totalDays);
  const [calendarMonth, setCalendarMonth] = useState(toDay.substring(4, 6));
  const [monthTitle, setMonthTitle] = useState<string>('');
  const [initiate, setinitiate] = useState('');

  useEffect(() => {
    setDateArray(getDateArray);
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '개인별 월 출역현황',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    const result = dayjs(`${aYear.aYear}-${month.month}-01`);
    setMonthTitle(result.format(i18n.language === 'en-US' ? `MMMM` : `MMMM`));
  }, [calendarMonth, i18n.language]);

  useEffect(() => {
    if (isF9Pressed) {
      // 근로자검색 모달에서 F9를 눌렀을 때 월출역보고서의 F9가 트리거되지 않도록 처리함
      if (!openModal.status) onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    setTotalDays(firstDayOfMonth + dateArray.length);
  }, [firstDayOfMonth]);

  useEffect(() => {
    setRemainingCells(totalDays < 35 ? 35 - totalDays : 42 - totalDays);
  }, [totalDays]);

  useEffect(() => {
    setWJobtype({ type: 'wJobtype', wJobtype: '', cdName: '' });
    if (wPrejobtype.wPrejobtype === '') setJikJongList([]);
  }, [wPrejobtype.wPrejobtype]);

  useEffect(() => {
    setJikJongList(jobtypeList);
  }, [jobtypeList]);

  useEffect(() => {
    // 근로자 검색 팝업에서 선택버튼까지 클릭했을 때 setState
    if (selectedUser && (selectedUser.sjCd !== '' || selectedUser.wName !== '')) {
      if (!searchCheck) {
        getMonthlyAttendListAPI(selectedUser.wCd);
      }
      setFirstDayOfMonth(dayjs(`${aYear.aYear}-${month.month}-01`).day());
      setCalendarMonth(month.month);
      setSearchOption((prev: ISearchOption) => ({ ...prev, wName: selectedUser.wName, wCd: selectedUser.wCd }));
      setSearchCheck(true);
    }
  }, [selectedUser]);

  useEffect(() => {
    // 근로자 검색을 마친 상태에서 협력업체나 근로자명을 변경했을 때 검색여부 false로 변경
    if (selectedUser) {
      if (selectedUser.wName !== searchOption.wName || selectedUser.sjCd !== sjCd.sjCd || selectedUser.wCd !== searchOption.wCd) {
        setSearchCheck(false);
      }
    }
  }, [searchOption.wName, sjCd.sjCd]);

  // 월출역현황 조회
  const getMonthlyAttendListAPI = async (wCdParam: string) => {
    const obj = { wCd: wCdParam, aMonth: `${aYear.aYear}${month.month}` };
    const req = { ...obj, hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/attend/monthDetail', req });

    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      setLoading(false);
      const newDateArray = getDateArray();

      if (data.attendList.length > 0) {
        const updatedDates = newDateArray.map((dateObj: any) => {
          const attendItem = data.attendList.find((attend: any) => attend.aDate.substring(6, 8) === dateObj.date);
          if (attendItem) {
            return {
              ...dateObj,
              aTimeMin: attendItem.aTimeMin,
              aTimeMax: attendItem.aTimeMax,
              aImg: attendItem.aImg,
            };
          }
          return dateObj;
        });
        setDateArray(updatedDates);
      } else {
        toast.info(t('출역데이터가 없습니다.'));
        setDateArray(newDateArray);
      }
    } else {
      setLoading(false);
      // toast.error(t(ERROR));
    }
  };

  const getWorkerListAPI = async (wCdParam: string) => {
    const newObj = { sjCd: sjCd.sjCd, wName: searchOption.wName, wPrejobtype: wPrejobtype.wPrejobtype, wJobtype: wJobtype.wJobtype };
    const trimData = trimObject(newObj);
    const req = { ...trimData, hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/worker', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      setFirstDayOfMonth(dayjs(`${aYear.aYear}-${month.month}-01`).day());
      setCalendarMonth(month.month);
      setSelectedUser(data.workerList[0]);
      setSearchCheck(true);
      getMonthlyAttendListAPI(wCdParam);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 검색 버튼 클릭
  const onClickSearch = () => {
    if (searchOption.wCd) {
      if (!openModal.status) {
        setLoading(true);
        navigate({ pathname: location.pathname, search: '' });
        // 근로자 조회 API
        getWorkerListAPI(searchOption.wCd);
      }
    } else toast.warning(t('근로자를 검색하세요'));
  };

  const initiateSearchOptions = () => {
    setSjCd({ type: 'sjCd', sjCd: '', cdName: '' });
    setWPrejobtype({ type: 'wPrejobtype', wPrejobtype: '', cdName: '' });
    setWJobtype({ type: 'wJobtype', wJobtype: '', cdName: '' });
    setAYear({ type: 'aYear', aYear: toDay.substring(0, 4), cdName: toDay.substring(0, 4) });
    setMonth({ type: 'month', month: toDay.substring(4, 6), cdName: toDay.substring(4, 6) });
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    Object.keys(searchOption).map((el: any) => {
      return setSearchOption((prev: ISearchOption) => ({ ...prev, [el]: '', aYear: toDay.substring(0, 4), month: toDay.substring(4, 6) }));
    });
    setinitiate(`${Math.random()}`);
    initiateSearchOptions();
  };

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  // 인쇄 버튼 클릭
  const onClickPrint = () => {
    handlePrint();
  };

  const renderPrintBtn = () => {
    if (auth.printAuth) {
      return (
        <BtnGhost onClick={onClickPrint}>
          <span className='material-symbols-rounded'>print</span>
          {t('인쇄')}
        </BtnGhost>
      );
    }
    return null;
  };

  // 사진보기 체크박스 클릭
  const handleCheckboxChange = () => {
    setPhotoViewYn(!photoViewYn);
  };

  const onClickTab = (tab: string) => {
    setCurrentTabMrCd(tab);
  };

  // 근로자명 입력창에서 엔터입력시 검색
  const loginOnEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (wNameRef.current) onClickSearch();
    }
  };

  const onClickDate = (dateParam: string) => {
    const newArrayObj: any = dateArray.find((el: any) => el.date === dateParam);
    if (newArrayObj) {
      // 해당날짜의 출근시간 또는 퇴근시간 데이터가 있을 때만 모달띄우기
      if (newArrayObj.aTimeMax || newArrayObj.aTimeMin) {
        const searchYmd = `${aYear.aYear}-${month.month}-${dateParam}`;
        const data = {
          sjName: selectedUser.sjName,
          startDate: searchYmd,
          endDate: searchYmd,
          wCd: selectedUser.wCd,
        };
        setOpenModal((prev) => ({ ...prev, status: true, type: 'attendDailyPersonalList', searchOption: data }));
      }
    }
  };

  const onClickClose = () => {
    setOpenModal((prev: any) => ({ ...prev, status: false }));
  };

  const onClickSearchWorkerBtn = () => {
    setOpenModal((prev: any) => ({ ...prev, status: true, type: 'workerList' }));
  };

  return (
    <div className='contents'>
      <div className='content-container oneColumn'>
        <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
        <Root loading={loading}>
          <SearchOptions className='border-bottom'>
            {isBottom && (
              <FloatingButtonToTop>
                <button type='button' onClick={() => scrollToNodeTop(tableRef)}>
                  <IoChevronUpSharp size={20} style={{ stroke: 'white' }} />
                </button>
              </FloatingButtonToTop>
            )}
            <div className='inputsWrapper'>
              <div className='inputForm-row withLabel'>
                <label htmlFor='sjCd'>{t('협력업체')}</label>
                <div className='inputForm-col'>
                  <SearchSelectBoxSm
                    options={siteJoinInfoList}
                    defaultOption={t('전체')}
                    state={sjCd}
                    setState={setSjCd}
                    stateKey='sjCd'
                    codeKey='cdName'
                    filterbar='filter-1-left'
                    optionHeight='height-md'
                  />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <SelectBox
                    options={YEAR_LIST}
                    defaultOption={aYear.aYear}
                    state={aYear}
                    setState={setAYear}
                    stateKey='aYear'
                    initiateKey={initiate}
                    filterbar='filter-1-left'
                    optionHeight='height-md'
                  />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <SelectBox
                    options={MONTH_LIST}
                    defaultOption={month.month}
                    state={month}
                    setState={setMonth}
                    stateKey='month'
                    initiateKey={initiate}
                    filterbar='filter-1-left'
                    optionHeight='height-sm'
                  />
                </div>
              </div>
              {userInfo.prejobtypeYn === 'Y' && (
                <div className='inputForm-row'>
                  <div className='inputForm-col'>
                    <SearchSelectBoxSm
                      options={prejobtypeList}
                      defaultOption={t('공종 전체')}
                      state={wPrejobtype}
                      setState={setWPrejobtype}
                      stateKey='wPrejobtype'
                      codeKey='cdName'
                      initiateKey={initiate}
                      filterbar='filter-1-leftToCenter'
                      comboWidth='expand-box-sm'
                      optionHeight='height-md'
                    />
                  </div>
                </div>
              )}
              <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <SearchSelectBoxSm
                    options={jikJongList}
                    defaultOption={t('직종 전체')}
                    state={wJobtype}
                    setState={setWJobtype}
                    stateKey='wJobtype'
                    codeKey='cdName'
                    initiateKey={wJobtype.wJobtype}
                    filterbar='filter-1-center'
                    comboWidth='expand-box-sm'
                    optionHeight='height-md'
                  />
                </div>
              </div>
              {/* <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <Input
                    disabled
                    placeholder={t('근로자를 검색하세요')}
                    label=''
                    type='text'
                    id='wName'
                    name='wName'
                    state={searchOption}
                    set={setSearchOption}
                    inputRef={wNameRef}
                    onKeyDown={loginOnEnterKeyDown}
                  />
                </div>
              </div> */}
              {searchOption.wName.length ? (
                <div className='searchData'>
                  <span>{t('근로자명')}</span>
                  {/* <span className='seperator' /> */}
                  {searchOption.wName}
                </div>
              ) : (
                ''
              )}
              <div className='btn'>
                <div className='inputForm-row'>
                  <div className='inputForm-col'>
                    <BtnLightViolet onClick={onClickSearchWorkerBtn}>{searchOption.wName.length === 0 ? t('근로자 검색') : t('근로자 재검색')}</BtnLightViolet>
                  </div>
                </div>
              </div>
            </div>
            <div className='inputsWrapper'>
              <div className='secondSearchOption'>
                <div className='flex-basic textBtnGroup'>
                  <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
                  {/* <BtnGhost onClick={onClickSearch} className='searchBtn'>
                    {t('검색')}
                    <span className='shortcut-f9'>F9</span>
                  </BtnGhost> */}
                  <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
                </div>
                <div className='flex-basic iconBtnGroup'>
                  <Toggle onChange={handleCheckboxChange} status={photoViewYn} text={t('사진보기')} />
                  {/* {renderPrintBtn()} */}
                </div>
              </div>
            </div>
          </SearchOptions>
          <div ref={componentRef}>
            <h2 className='calendarMonthTitle'>{monthTitle}</h2>
            <div className='calendar' style={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)' }}>
              {days.map((day) => (
                <div
                  className='days'
                  key={day}
                  style={{
                    color:
                      day === 'Sun'
                        ? theme === 'light'
                          ? light.color.rose_500
                          : dark.color.rose_400
                        : day === 'Sat'
                        ? theme === 'light'
                          ? light.color.blue_500
                          : dark.color.blue_400
                        : theme === 'light'
                        ? light.text_primary
                        : dark.text_primary,
                  }}
                >
                  {day}
                </div>
              ))}
              {[...new Array(firstDayOfMonth)].map((e, i) => (
                <div className='emptyCells' key={`empty-${i}`} />
              ))}
              {dateArray.map(({ date, dayOfWeek, aTimeMin, aTimeMax, aImg }: any, index: number) => (
                <div key={index} className='date' onClick={() => onClickDate(date)} role='presentation'>
                  <div>
                    <div>
                      <div
                        style={{
                          color:
                            dayOfWeek === 0
                              ? theme === 'light'
                                ? light.color.rose_500
                                : dark.color.rose_400
                              : dayOfWeek === 6
                              ? theme === 'light'
                                ? light.color.blue_500
                                : dark.color.blue_400
                              : theme === 'light'
                              ? light.text_primary
                              : dark.text_primary,
                        }}
                      >
                        {date}
                      </div>
                      {photoViewYn ? (
                        aTimeMax !== '' ? (
                          aImg !== '' && aImg !== null ? (
                            <img style={{ display: !photoViewYn ? 'none' : 'block' }} src={aImg} alt='' width='100%' />
                          ) : (
                            <div className='flex-center' style={{ paddingTop: '1rem' }}>
                              No Image
                            </div>
                          )
                        ) : null
                      ) : null}
                    </div>
                    <div className='flex-between'>
                      <div>{aTimeMin}</div>
                      <div>{aTimeMax}</div>
                    </div>
                  </div>
                </div>
              ))}
              {remainingCells < 7 && [...new Array(remainingCells)].map((e, i) => <div className='emptyCells' key={`empty-end-${i}`} />)}
            </div>
          </div>
        </Root>
      </div>
      {loading && (
        <LoadingModalBackground>
          <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
        </LoadingModalBackground>
      )}
      <Portal openModal={openModal?.status}>
        {openModal.status && openModal.type === 'workerList' && (
          <ModalBackground>
            <WorkerListModal
              openModal={openModal}
              setOpenModal={setOpenModal}
              state={selectedUser}
              setState={setSelectedUser}
              isIncludeBlackUser
              useCheckbox={false}
              filter={{ sjCd: sjCd.sjCd, wName: searchOption.wName, wPrejobtype: wPrejobtype.wPrejobtype, wJobtype: wJobtype.wJobtype }}
              onClickClose={onClickClose}
            />
          </ModalBackground>
        )}
        {openModal.status && openModal.type === 'attendDailyPersonalList' && <AttendDailyPersonalListModal setOpenModal={setOpenModal} filter={openModal.searchOption} />}
      </Portal>
    </div>
  );
};

export default Sattend6;
