/**
 * 작성자 : 홍선영
 * 날짜 : 2023.06.14
 * 기능 : 근로자정보 입력/뷰 모달
 */

import React, { useState, useEffect, Dispatch, SetStateAction, useRef } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { PulseLoader } from 'react-spinners';
import { IoPersonAdd } from 'react-icons/io5';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { readAndCompressImage } from 'browser-image-resizer';
import dayjs from 'dayjs';
import CreatableSelect from 'react-select/creatable';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-toastify/dist/ReactToastify.css'; // toastify css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-datepicker/dist/react-datepicker.css';

import { msiteListState, msiteUseYnState, userState } from '../../atoms';
import { IAuth, IComCd, IComCdList, IModal, IWorkerInputSet } from 'customTypes';
import { COMCD_WORKERINPUT_SET, COMCD_WORKER_GUBUN, FLAG_CREATE_OR_UPDATE, FLAG_NONE, FOREIGN_YN_INPUT, SEX, WORK_STATUS_INPUT, hourList2, minList2 } from '../../_constants';
import { LoadingModalBackground, ModalBackground } from '../../assets/styles/Modal';
import { BtnBlue, BtnGhost, BtnRed } from '../Button';
import { useLocation, useNavigate } from 'react-router-dom';

import Input from '../Input';
import Portal from '../Portal';
import TuiGrid from '../Table/TuiGrid';
import DatePicker from '../DatePicker';
import DeleteModal from './DeleteModal2';
import ReactSelect from '../ReactSelect';
import NumbersInput from '../NumbersInput';
import SearchSelectBoxs from '../SearchSelectBoxs';
import DatePickerInTable from '../DatePickerInTable';
import SearchSelectBoxSm from '../SearchSelectBoxSm';
import { AddressSearch } from '../AddressSearch';
import RangePicker, { onChangeRangeInput } from '../RangePicker';
import { InfoWorkerModalStyle } from '../../assets/styles/InfoWorkerModal';
import { SubModal } from '../../assets/styles/subModal';
import { InputTable } from '../../assets/styles/InputTable';
import { WorkerGrid } from '../../assets/styles/WorkerGrid';
import { trimObject } from '../../utils/trimObject';
import { fileToBase64 } from '../../utils/fileToBase64';
import { applyBorderStyle } from '../../utils/applyBorderStyle';
import { arraySortByAscdOrder } from '../../utils/arraySortByAscdOrder';
import useOnKeydownF9 from '../../utils/useOnKeydownF9';
import useOnKeydownPgUp from '../../utils/useOnKeydownPgUp';
import useOnKeydownPgDn from '../../utils/useOnKeydownPgDn';
import { formatDate, formatDateToStrBar, formatDateYMD, isValidYYYYMMDD, todayDiffDay, todayYYYYMMDD } from '../../utils/formatDate';
import { logPost } from '../../services/log';
import i18n from '../../translation/i18n';
import { quoteRegex } from '../../utils/checkRegex';
import BackButton from '../BackButton';
import { setComCdListState } from '../../utils/setComCdListState';
import SelectBox from '../SelectBox';
import { apiDelete, apiGet, apiPost } from '../../services/_common';
import TakeMedicine from './InfoWorkerModal/TakeMedicine';
import ThreeDigitNumberInput from './InfoWorkerModal/ThreeDigitNumberInput';
import StressTotal from './InfoWorkerModal/StressTotal';
import Sex from './InfoWorkerModal/Sex';
import SafeEdu from './InfoWorkerModal/SafeEdu';
import MsiteCd from './InfoWorkerModal/MsiteCd';

interface ICode {
  hCd: string;
  sCd: string;
  wCd: string;
  aDate?: string;
}

interface IWorker {
  sjName: string;
  wName: string;
  wJobdate: string;
  wNationality: string;
  wVisa: string;
  wHnum: string;
  wEnum: string;
  wAddress1: string;
  wAddress2: string;
  wBlood: string;
  wForeignyn: string;
}

interface IProps {
  getReportListAPI: () => void; // 근로자 보고서 조회 api
  innerTabIndex: number; // 내부탭 인덱스 (0: 근로자 정보, 1: 출역내역, 2: 교육내역)
  reportArray?: any[]; // 보고서목록데이터
  setReportArray?: Dispatch<SetStateAction<any[]>>;
  setOpenModal: Dispatch<SetStateAction<IModal>>;
  auth: IAuth;
  code: ICode;
  index?: number;
  setRowKey?: Dispatch<SetStateAction<any>>;
  listCount?: number;
}

// Compression configuration
const config = {
  quality: 0.7,
  maxWidth: 800,
  maxHeight: 600,
  autoRotate: true,
  debug: true,
};

const initiateWorkerInputSet = {
  wCd: '',
  wName: '',
  sjCd: '',
  sjName: '',
  wEname: '',
  wImg: '',
  wGubun: '',
  wBcid: '',
  wWorkstatus: '',
  wBdate: '',
  wHnum: '',
  wEnum: '',
  wZipcode: '',
  wAddress1: '',
  wAddress2: '',
  wJobdate: '',
  wRetiredate: '',
  wPrejobtype: '',
  wPrejobtypeName: '',
  wJobtype: '',
  wJobtypeName: '',
  wForeignyn: '',
  wNationality: '',
  wPassport: '',
  wVisa: '',
  wBlood: '',
  wLowpress: 0,
  wHighpress: 0,
  wPinnum: '',
  wInsurance: '',
  wSafetydate: todayYYYYMMDD(),
  wSafetydevice: '',
  wSafetydeviceetc: '',
  wEdudate: '',
  wPenaltycount: 0,
  wBlackyn: 'N',
  wSmemo: '',
  wBigo: '',
  wHndate: '',
  wHnresult: '',
  wHnbigo: '',
  wHsdate: '',
  wHsresult: '',
  wHsbigo: '',
  inoutDate: '',
  writer: '',
  wDate: '',
  editor: '',
  eDate: '',
  pulseRate: '',
  bloodSugar: '',
  msiteCd: '',
  takeMedicine: '',
  safeeduReg: '',
  safeeduDate: '',
  stressTotal: '',
  stressResult: '',
  sex: '',
};

const InfoWorkerModal = ({ getReportListAPI, innerTabIndex, reportArray, setReportArray, setOpenModal, auth, code, index, setRowKey, listCount }: IProps) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const location = useLocation();
  const navigate = useNavigate();
  const [isNewAdd, setIsNewAdd] = useState(code.wCd === '');
  const [changeModal, setChangeModal] = useState(false);
  const [originalWorkerInputSet, setOriginalWorkerInputSet] = useState<any>({}); // 수정모드에서 취소 눌렀을 때 worekrInputSet데이터 원본데이터로 복구하기 위함
  const [innerTab, setInnerTab] = useState(innerTabIndex);
  const [workerInputSet, setWorkerInputSet] = useState<IWorkerInputSet>(initiateWorkerInputSet);
  const [wHnumState, setWHNumState] = useState({ num1: '', num2: '', num3: '' }); // 근로자 휴대폰번호
  const [wEnumState, setWENumState] = useState({ num1: '', num2: '', num3: '' }); // 근로자 비상연락처 번호
  const [wJobdate, setWJobdate] = useState(dayjs().format('YYYYMMDD'));
  const [wRetiredate, setWRetiredate] = useState('');
  const [wBdate, setWBdate] = useState('');
  const [wHndate, setWHndate] = useState('');
  const [wHsdate, setWHsdate] = useState('');
  const [wSafetydate, setWSafetydate] = useState('');
  const [openSubModal, setOpenSubModal] = useState<any>({ status: false, type: '', wName: '' });
  const [bloodPressureRange, setBloodPressureRange] = useState({ fromLowpress: 0, toLowpress: 0, fromHighpress: 0, toHighpress: 0 }); // TODO 현장별 혈압정보 받아오기
  const highestBloodPressureRef = useRef<HTMLInputElement>(null);
  const lowestBloodPressureRef = useRef<HTMLInputElement>(null);
  const [isViewMode, setIsViewMode] = useState(!auth.createAuth && !auth.updateAuth); // 권한에 따른 뷰 or 수정모드 여부
  const [currentWorkerIndex, setCurrentWorkerIndex] = useState<number | undefined>(index || undefined);
  const [currentWorkerWCd, setCurrentWorkerWCd] = useState(code.wCd || '');
  const [bloodPressure, setBloodPressure] = useState({ high: 0, low: 0 });
  const [address, setAddress] = useState({ address1: '', address2: '', postNum: '' });
  const [userImage, setUserImage] = useState({ isUpdate: false, img: '' });
  const [preJobtypeNormalCdList, setPreJobtypeNormalCdList] = useState([]); // 공종 코드리스트
  const [jobtypeNormalCdList, setjobtypeNormalCdList] = useState([]); // 직종 코드리스트
  const [jobtypeOnlyNormalCdList, setJobtypeOnlyNormalCdList] = useState([]); // 직종만일 때 코드리스트
  const [prejobtypeState, setPrejobtypeState] = useState({ value: '', label: t('선택'), isNewValue: false }); // 공종 코드
  const [jobtypeState, setJobtypeState] = useState({ value: '', label: t('선택'), isNewValue: false }); // 직종 코드
  const [wGubun, setWGubun] = useState({ type: COMCD_WORKER_GUBUN, [COMCD_WORKER_GUBUN]: '01', cdName: '' });
  const { isPgUpPressed, setIsPgUpPressed } = useOnKeydownPgUp();
  const { isPgDnPressed, setIsPgDnPressed } = useOnKeydownPgDn();
  const [insuranceCheckValue, setInsuranceCheckValue] = useState([
    { check: false, value: '1', name: '고용보험' },
    { check: false, value: '2', name: '건강보험' },
    { check: false, value: '3', name: '퇴직공제 대상자' },
  ]);
  const [safetyDeviceCheckValue, setSafetyDeviceCheckValue] = useState([
    { check: true, value: '1', name: '안전모' },
    { check: true, value: '2', name: '안전화' },
    { check: true, value: '3', name: '안전벨트' },
    { check: true, value: '4', name: '각반' },
  ]);
  const [takeMedicineValue, setTakeMedicineValue] = useState([
    { check: false, value: '1', name: '고혈압' },
    { check: false, value: '2', name: '고지혈증' },
    { check: false, value: '3', name: '당뇨' },
  ]);
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [grid, setGrid] = useState<any[]>([]);
  const [sjCd, setSjCd] = useState({ type: 'sjCd', sjCd: '', cdName: '' }); // 협력업체
  const [wForeignyn, setWForeignyn] = useState({ type: 'wForeignyn', wForeignyn: '', cdName: t('선택') }); // 외국인여부
  const [wHnresult, setWHnresult] = useState({ type: 'wHnresult', wHnresult: '', cdName: t('선택') }); // 일반검진 결과
  const [wHsresult, setWHsresult] = useState({ type: 'wHsresult', wHsresult: '', cdName: t('선택') }); // 특수검진 결과
  const [isLoading, setIsLoading] = useState(true);
  const listData = useRecoilValue(msiteListState);
  const [msCdValue, setMsCdValue] = useState('');

  useEffect(() => {
    const escKeyEscClose = (e: KeyboardEvent) => {
      if (e.keyCode === 27) onClickClose();
    };
    window.addEventListener('keydown', escKeyEscClose);
  }, []);

  useEffect(() => {
    if (userInfo.prejobtypeYn === 'Y') {
      getPrejobtypeAPI('000').then((res: any) => {
        if (res.status === 200) getComCdWorkerInputSetAPI();
      });
    } else {
      getJobtypeAPI('000').then((res: any) => {
        if (res.status === 200) getComCdWorkerInputSetAPI();
      });
    }
  }, [innerTab]);

  // workerinput_set 공통코드에서 조회
  const getComCdWorkerInputSetAPI = async () => {
    const req = { grCd: COMCD_WORKERINPUT_SET };
    const res = await apiGet({ path: '/code/detail', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const sortedArray = arraySortByAscdOrder(data.comCdList, 'cdSort');
      const newArray = sortedArray.map((el: IComCdList, i: number) => {
        return { index: i + 1, etc1: Number(el.etc1), cdName: el.cdName, cdSort: el.cdSort, wCd: el.subCd, propertyName: el.etc2, normalCd: el.etc3 };
      });
      getWorkerInfoFormOrderAPI(arraySortByAscdOrder(newArray, 'cdSort'));
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 누락된 wCol 및 wRow를 추가하는 함수
  const fillMissingColsAndRows = (data: any) => {
    // 데이터에서 최대 행 수를 계산
    const maxRow = data.reduce((acc: any, item: any) => Math.max(acc, item.wRow), 0);
    const newData = [...data];
    const colCounts = { 1: true, 2: true, 3: true }; // 존재해야 하는 컬럼

    // 모든 행에 모든 열이 있는지 확인
    for (let row = 1; row <= maxRow; row += 1) {
      // 각 행에서 최대 행까지 반복
      const existingCols = new Set(); // 이 행의 기존 열 추적
      data.forEach((item: any) => {
        if (item.wRow === row) {
          existingCols.add(item.wCol);
        }
      });

      // 누락된 컬럼 추가
      Object.keys(colCounts).forEach((col) => {
        if (!existingCols.has(parseInt(col, 10))) {
          newData.push({
            wCd: null,
            wRow: row,
            wCol: parseInt(col, 10),
          });
        }
      });
    }

    return newData;
  };

  // 해당 현장의 근로자정보 폼 순서 가져오는 API
  const getWorkerInfoFormOrderAPI = async (comCdWorkerInputSet: any[]) => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/setting/worker', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      // 컨트롤이 배치되지 않은 공간을 공백으로 남기기 위해 빈객체 추가
      // const addEmptyCell = fillMissingColsAndRows(data.workerinputSetList);
      // const sortedData = addEmptyCell.sort((a, b) => a.wRow - b.wRow || a.wCol - b.wCol);

      const newArray = data.workerinputSetList.map((el: any, i: number) => {
        const object = comCdWorkerInputSet.find((el2: any) => el2.wCd === el.wCd);
        // 근로자입력 공통코드목록에서 cdName, etc1(크기값), wCol, wRow값 추가
        if (object !== undefined) {
          const { wCd, etc1, cdName, cdSort, propertyName, normalCd } = object;
          return { etc1, wCol: el.wCol, wRow: el.wRow, propertyName, normalCd, content: { index: object.index, cdSort, cdName, wCd, etc1, wCol: el.wCol, wRow: el.wRow, isInGrid: true } };
        }
        // return {
        //   hidden: true,
        //   etc1: 1,
        //   wCol: el.wCol,
        //   wRow: el.wRow,
        //   propertyName: '',
        //   normalCd: '',
        //   content: { index: i, cdSort: i, cdName: '', wCd: '', etc1: 1, wCol: el.wCol, wRow: el.wRow, isInGrid: true },
        // };
        return undefined;
      });

      const newArray2: any = newArray.filter((el: any) => el);

      // etc1(사이즈)만큼 객체의 수를 추가하기
      const newArray3 = Array.from(newArray2, ({ etc1, wRow, wCol, ...rest }) => {
        const copyArray: any = [];
        for (let i = 0; i < etc1; i += 1) {
          if (i > 0) {
            const row = 3 * (wRow - 1);
            const col = wCol + i; //  컬럼숫자 증가
            const id = row + col;

            copyArray.push({ ...rest, id, hidden: true, wCol: col, wRow, etc1 });
          } else {
            const row = 3 * (wRow - 1);
            const col = wCol - 1;
            const id = row + col;

            copyArray.push({ ...rest, id, wCol, wRow, etc1 });
          }
        }
        return copyArray;
      }).flat();

      // 근로자정보 폼 배열 중 마지막객체의 인덱스를 찾아서
      const lastWorkerInputSetObjectIndex = newArray.length - 1;
      if (lastWorkerInputSetObjectIndex > -1) {
        // const lastObject = newArray[lastWorkerInputSetObjectIndex];

        // 마지막 wRow값으로 그리드의 크기를 정하고, wCol, wRow 값 부여
        // const initialGrid: any[] = Array.from({ length: lastObject.wRow * 3 }, (_, i) => ({ id: i, wRow: Math.floor(i / 3) + 1, wCol: (i % 3) + 1 }));
        // const copyInitialGrid = [...initialGrid];

        // newArray3.map((newArrayEl: any) => {
        //   const findIndex = copyInitialGrid.findIndex((el) => el.id === newArrayEl.id);
        //   if (findIndex >= 0) {
        //     copyInitialGrid[findIndex] = newArrayEl;
        //   }
        // });

        // console.log('newArray', newArray);

        const addEmptyCell = fillMissingColsAndRows(newArray3);
        const sortedData = addEmptyCell.sort((a, b) => a.wRow - b.wRow || a.wCol - b.wCol);

        // console.log('sortedData', sortedData);
        setGrid(sortedData);
        // 공종/직종 선택여부에 따라 grid에서 컨트롤 제외 (e.g. 설정값이 공종/직종인데 직종단일컨트롤 배치했을 경우 안보이도록 처리)
        if (userInfo.prejobtypeYn === 'Y') setGrid(sortedData.filter((el: any) => el.content?.wCd !== '13'));
        else setGrid(sortedData.filter((el: any) => el.content?.wCd !== '12'));
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  // (공종+직종일 때) 공종 코드리스트 호출
  const getPrejobtypeAPI = async (grCd: string) => {
    const req = {
      hCd: code.hCd,
      sCd: code.sCd,
      grCd,
    };
    const res = await apiGet({ path: '/code/normal/prejobtype', req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      const prejobTypeList = data.prejobtypeList.filter((el: any) => el.subCd.charAt(0) === 'A');
      const formatSelectBoxFormList = prejobTypeList.map((el: IComCd) => {
        return { value: el.subCd, label: el.cdName };
      });
      const addDefaultObject = { value: '', label: t('선택') };
      const newArray: any = [addDefaultObject, ...formatSelectBoxFormList];
      setPreJobtypeNormalCdList(newArray);
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  // 직종 코드리스트 호출
  const getJobtypeAPI = async (grCd: string) => {
    const req = {
      hCd: code.hCd,
      sCd: code.sCd,
      grCd,
    };
    const res = await apiGet({ path: '/code/normal/prejobtype', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      // 공종+직종일 때 직종리스트
      if (userInfo.prejobtypeYn === 'Y') {
        const formatSelectBoxFormList = data.prejobtypeList.map((el: IComCd) => {
          return { value: el.subCd, label: el.cdName };
        });
        const addDefaultObject = { value: '', label: t('선택') };
        const newArray: any = [addDefaultObject, ...formatSelectBoxFormList];
        setjobtypeNormalCdList(newArray);
      } else {
        // 직종만일 때 직종리스트
        const jobTypeList = data.prejobtypeList.filter((el: any) => el.subCd.charAt(0) === 'B');
        const formatSelectBoxFormList = jobTypeList.map((el: IComCd) => {
          return { value: el.subCd, label: el.cdName };
        });
        const addDefaultObject = { value: '', label: t('선택') };
        const newArray: any = [addDefaultObject, ...formatSelectBoxFormList];
        setJobtypeOnlyNormalCdList(newArray);
      }
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  // 근로자 정보조회 API
  const workerInfoQuery = useQuery(
    ['workerInfo', userInfo.hCd, userInfo.sCd, currentWorkerWCd],
    () => apiGet({ path: '/worker/info', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, wCd: currentWorkerWCd } }),
    {
      enabled: !isNewAdd && !!currentWorkerWCd && !!userInfo.hCd && !!userInfo.sCd,
    }
  );

  useEffect(() => {
    if (workerInfoQuery.isSuccess && workerInfoQuery.data) {
      const { worker } = workerInfoQuery.data.data.data;
      getPrejobtypeAPI('000'); // 공종목록 호출
      /**
       * 수정자 : 한영광
       * 수정일자 : 2023.11.27
       * 수정내용 : 공종, 직종이 선택되지 않은 근로자인 경우 직종 리스트 조회 API를 요청하게 되면 Bad Request 에러를 응답받기 때문에 공종, 직종 선택 여부를 체크하여 API 요청하도록 처리함
       */
      if (userInfo.prejobtypeYn === 'Y') {
        if (worker[0].wPrejobtype) {
          getJobtypeAPI(worker[0].wPrejobtype); // 직종목록 호출
        } else {
          // 직종을 초기화 해주는 이유는 이전, 다음 버튼으로 넘어 온 경우 이전 근로자의 공종 코드로 직종리스트를 불러오기 때문
          setjobtypeNormalCdList([]);
        }
      } else {
        getJobtypeAPI('000'); // 직종목록 호출
      }
      setPrejobtypeState({ value: worker[0].wPrejobtype, label: worker[0].wPrejobtypeName || t('선택'), isNewValue: false });
      setJobtypeState({ value: worker[0].wJobtype, label: worker[0].wJobtypeName || t('선택'), isNewValue: false });
      setOriginalWorkerInputSet(worker[0]);
      setMsCdValue(worker[0].msiteCd);
      setWorkerInputSet(worker[0]);
      setWJobdate(worker[0].wJobdate);
      setWRetiredate(worker[0].wRetiredate);
      setWBdate(worker[0].wBdate);
      setWHndate(worker[0].wHndate);
      setWHsdate(worker[0].wHsdate);
      setWSafetydate(worker[0].wSafetydate);
      setWGubun({ type: COMCD_WORKER_GUBUN, [COMCD_WORKER_GUBUN]: worker[0].wGubun, cdName: '' });
      setSjCd({ type: 'sjCd', sjCd: worker[0].sjCd || '', cdName: worker[0].sjName });
      setWForeignyn({ type: 'wForeignyn', wForeignyn: worker[0].wForeignyn, cdName: '' });
      setWHnresult({ type: 'wHnresult', wHnresult: worker[0].wHnresult, cdName: '' });
      setWHsresult({ type: 'wHsresult', wHsresult: worker[0].wHsresult, cdName: '' });
      setIsLoading(false);
      if (worker[0].wHnum) {
        const splitNumber = worker[0].wHnum.split('-');
        setWHNumState((prev: any) => ({ ...prev, num1: splitNumber[0], num2: splitNumber[1], num3: splitNumber[2] }));
      } else {
        setWHNumState((prev: any) => ({ ...prev, num1: '', num2: '', num3: '' }));
      }

      if (worker[0].wEnum) {
        const splitNumber = worker[0].wEnum.split('-');
        setWENumState((prev: any) => ({ ...prev, num1: splitNumber[0], num2: splitNumber[1], num3: splitNumber[2] }));
      } else {
        setWENumState((prev: any) => ({ ...prev, num1: '', num2: '', num3: '' }));
      }

      const wInsuranceValues = worker[0].wInsurance?.split('/');
      setInsuranceCheckValue(
        insuranceCheckValue.map((item: any) => ({
          ...item,
          check: wInsuranceValues?.includes(item.value),
        }))
      );

      const wSafetydeviceValues = worker[0].wSafetydevice?.split('/');
      setSafetyDeviceCheckValue(
        safetyDeviceCheckValue.map((item: any) => ({
          ...item,
          check: wSafetydeviceValues?.includes(item.value),
        }))
      );

      setBloodPressure({ low: worker[0].wLowpress, high: worker[0].wHighpress });
      setAddress({ address1: worker[0].wAddress1, address2: worker[0].wAddress2, postNum: worker[0].wZipcode });
      setUserImage({ img: worker[0].wImg, isUpdate: false });
    }
  }, [workerInfoQuery.isSuccess, workerInfoQuery.isRefetching]);

  useEffect(() => {
    if (isPgUpPressed) {
      onClickPrev();
      setIsPgUpPressed(false);
    }
  }, [isPgUpPressed]);

  useEffect(() => {
    if (isPgDnPressed) {
      onClickNext();
      setIsPgDnPressed(false);
    }
  }, [isPgDnPressed]);

  useEffect(() => {
    if (code.wCd === '') {
      setIsNewAdd(true);
    }
  }, [code]);

  useEffect(() => {
    if (currentWorkerIndex !== undefined && currentWorkerIndex >= 0 && reportArray && reportArray?.length > 0) {
      setCurrentWorkerWCd(reportArray[currentWorkerIndex]?.wCd);
    }
  }, [currentWorkerIndex, reportArray]);

  useEffect(() => {
    if (index !== undefined) setCurrentWorkerIndex(index);
  }, [index]);

  // 닫기버튼 클릭
  const onClickClose = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };

  // 밸류가 null인 경우 모두 ''으로 바꾸어서 전송
  const updateNullPropertiesToEmptyString = (obj: any) => {
    return Object.keys(obj).reduce(
      (acc, key) => ({
        ...acc,
        [key]: obj[key] === null ? '' : obj[key],
      }),
      {}
    );
  };

  // 보험여부 선택한 객체배열을 "1/2/3" 형태로 변환
  const getWInsuranceValue = () => {
    const values = insuranceCheckValue
      .filter((item: any) => item.check === true)
      .map((item: any) => item.value)
      .join('/');

    return values;
  };

  // 보호구 선택한 객체배열을 "1/2/3" 형태로 변환
  const getWSafetyDeviceValue = () => {
    // setSelectAll(safetyDeviceCheckValue.every((item: any) => item.check));
    const values = safetyDeviceCheckValue
      .filter((item: any) => item.check === true)
      .map((item: any) => item.value)
      .join('/');

    return values;
  };

  // 근로자 신규등록
  const saveNewWorkerInputAPI = async () => {
    const newWorkerInputSet = updateNullPropertiesToEmptyString(workerInputSet);

    const wInsurance = getWInsuranceValue();
    const wSafetydevice = getWSafetyDeviceValue();
    const newPrejobtype =
      userInfo.prejobtypeYn === 'Y'
        ? prejobtypeState.isNewValue
          ? { wPrejobtypeName: prejobtypeState.value, wPrejobtype: '' }
          : prejobtypeState.value !== ''
          ? { wPrejobtype: prejobtypeState.value }
          : { wPrejobtypeName: '', wPrejobtype: '' }
        : { wPrejobtypeName: '', wPrejobtype: '000' };
    const newJobtype = jobtypeState.isNewValue ? { wJobtypeName: jobtypeState.value, wJobtype: '' } : jobtypeState.value !== '' ? { wJobtype: jobtypeState.value } : { wJobtypeName: '', wJobtype: '' };

    const reqData: any = {
      ...newWorkerInputSet,
      hCd: code.hCd,
      sCd: code.sCd,
      wCd: '',
      writer: userInfo.userId,
      editor: userInfo.userId,
      hSendyn: 'N',
      hSdate: '',
      wInsurance,
      wSafetydevice,
      wLowpress: bloodPressure.low,
      wHighpress: bloodPressure.high,
      wAddress1: address.address1,
      wAddress2: address.address2,
      wZipcode: address.postNum,
      wBdate: wBdate || '',
      wSafetydate: wSafetydate || '',
      wHndate: wHndate || '',
      wHsdate: wHsdate || '',
      wJobdate: wJobdate || '',
      wRetiredate: wRetiredate || '',
      wWorkstatus: todayDiffDay(wRetiredate) ? 'I' : 'R',
      wGubun: wGubun[COMCD_WORKER_GUBUN],
      sjCd: sjCd.sjCd,
      wForeignyn: wForeignyn.wForeignyn,
      wHnresult: wHnresult.wHnresult,
      wHsresult: wHsresult.wHsresult,
      wNationality: wForeignyn.wForeignyn === 'N' ? '' : workerInputSet.wNationality,
      msiteCd: msCdValue,
      pulseRate: workerInputSet.pulseRate || '',
      bloodSugar: workerInputSet.bloodSugar || '',
      takeMedicine: workerInputSet.takeMedicine || '',
      safeeduReg: workerInputSet.safeeduReg || '',
      safeeduDate: workerInputSet.safeeduDate || '',
      stressTotal: workerInputSet.stressTotal || '',
      stressResult: workerInputSet.stressResult || '',
      sex: workerInputSet.sex || '',
      ...newPrejobtype,
      ...newJobtype,
    };
    const { wDate, eDate, sjName, cdName, type, wSafetydeviceText, takeMedicineText, wFregnum, wFregdate, nedusignImg, redusignImg, ...newObj } = reqData;
    const req = trimObject(newObj);
    const res = await apiPost({ path: '/worker/info', req });

    const { statusCode, data, message } = res.data;
    if (statusCode === 200 && reportArray) {
      toast.success(t(message));
      // const newUser = { ...data.worker[0], wJobdate: formatDateYMD(data.worker[0].wJobdate), wRetiredate: formatDateYMD(data.worker[0].wRetiredate) };
      // setReportArray && setReportArray((prev) => [...prev, newUser]);
      getReportListAPI();
      setOpenModal((prev) => ({ ...prev, status: false }));
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '근로자 정보(팝업)',
        action: '신규등록',
        etc: `${workerInputSet.wName}(${data.worker[0].wCd})`,
      });
    } // else toast.error(t(ERROR));
  };

  const updateMsiteCd = () => {
    let value = '';
    listData.forEach((el: any) => {
      const msiteCd = `${el.mhCd}${el.msCd}`;
      if (el.wRegYn === 'Y') {
        value = value !== '' ? `${value},${msiteCd}` : `${msiteCd}`;
      }
    });
    return value.length === 0 ? '' : value;
  };

  const updateMsiteCdValueD = (msite: string, orgMsite: string) => {
    const splittedOrgMsiteCdArray = orgMsite ? orgMsite.split(',') : [];
    const splittedMsiteCdArray = msite ? msite.split(',') : [];

    if (splittedMsiteCdArray.length === 0) {
      // If the new array is empty, append 'delete' to all items in the original array
      const updatedMsiteCd = splittedOrgMsiteCdArray.map((el: string) => `${el}D`);
      return updatedMsiteCd?.join(',');
    }
    {
      // Create a Set from newArray for faster lookup
      const newSet = new Set(splittedMsiteCdArray);

      // Create the newData array
      const newData: any = [];

      // Process existing items: update with 'D' if not in new array and not already marked
      splittedOrgMsiteCdArray.forEach((item) => {
        const cleanItem = item.replace('D', '');
        if (!newSet.has(cleanItem)) {
          newData.push(item.endsWith('D') ? item : `${item}D`);
        } else {
          newData.push(cleanItem);
        }
      });

      // Add new items that are not in the original
      splittedMsiteCdArray.forEach((item: string) => {
        if (!newData.includes(item) && !newData.includes(`${item}D`)) {
          newData.push(item);
        }
      });
      const toString = newData?.join(',');

      return toString;
    }
  };

  const saveWorkerInputAPI = async () => {
    const newWorkerInputSet = updateNullPropertiesToEmptyString(workerInputSet);
    const wInsurance = getWInsuranceValue();
    const wSafetydevice = getWSafetyDeviceValue();
    const updatedMsiteCd = updateMsiteCdValueD(msCdValue, workerInputSet?.msiteCd || '');

    const newPrejobtype =
      userInfo.prejobtypeYn === 'Y'
        ? prejobtypeState.isNewValue
          ? { wPrejobtypeName: prejobtypeState.value, wPrejobtype: '' }
          : prejobtypeState.value !== ''
          ? { wPrejobtype: prejobtypeState.value }
          : { wPrejobtypeName: '', wPrejobtype: '' }
        : { wPrejobtypeName: '', wPrejobtype: '000' };
    const newJobtype = jobtypeState.isNewValue ? { wJobtypeName: jobtypeState.value, wJobtype: '' } : jobtypeState.value !== '' ? { wJobtype: jobtypeState.value } : { wJobtypeName: '', wJobtype: '' };

    const reqData: any = {
      ...newWorkerInputSet,
      hCd: code.hCd,
      sCd: code.sCd,
      wCd: currentWorkerWCd,
      editor: userInfo.userId,
      hSendyn: 'N',
      hSdate: '',
      wInsurance,
      wSafetydevice,
      wLowpress: bloodPressure.low,
      wHighpress: bloodPressure.high,
      wAddress1: address.address1 || '',
      wAddress2: address.address2 || '',
      wZipcode: address.postNum || '',
      wBdate: wBdate || '',
      wSafetydate: wSafetydate || '',
      wHndate: wHndate || '',
      wHsdate: wHsdate || '',
      wJobdate: wJobdate || '',
      wRetiredate: wRetiredate || '',
      wWorkstatus: todayDiffDay(wRetiredate) ? 'I' : 'R',
      wGubun: wGubun[COMCD_WORKER_GUBUN],
      sjCd: sjCd.sjCd,
      wForeignyn: wForeignyn.wForeignyn,
      wHnresult: wHnresult.wHnresult,
      wHsresult: wHsresult.wHsresult,
      wNationality: wForeignyn.wForeignyn === 'N' ? '' : workerInputSet.wNationality,
      msiteCd: updatedMsiteCd,
      pulseRate: workerInputSet.pulseRate || '',
      bloodSugar: workerInputSet.bloodSugar || '',
      takeMedicine: workerInputSet.takeMedicine || '',
      safeeduReg: workerInputSet.safeeduReg || '',
      safeeduDate: workerInputSet.safeeduDate || '',
      stressTotal: workerInputSet.stressTotal || '',
      stressResult: workerInputSet.stressResult || '',
      sex: workerInputSet.sex || '',
      ...newPrejobtype,
      ...newJobtype,
    };
    const { wDate, eDate, sjName, cdName, type, wSafetydeviceText, takeMedicineText, wFregnum, wFregdate, nedusignImg, redusignImg, ...newObj } = reqData;
    const req = trimObject(newObj);
    const res = await apiPost({ path: '/worker/info', req });
    const { statusCode, data, message } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      getReportListAPI();
      setOpenModal((prev) => ({ ...prev, status: false }));
      workerInfoQuery.refetch();
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '근로자 정보(팝업)',
        action: '저장',
        etc: `${workerInputSet.wName}(${workerInputSet.wCd})`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  const onClickSaveWorkerInput = () => {
    setIsSaveClicked(true);
    if (sjCd.sjCd === '' || workerInputSet.wName === '') return toast.warning(t('필수값을 입력하세요'));

    // 공종+직종 Y 인 경우 공종/직종 값을 하나라도 선택한 경우, 공종/직종 값을 모두 선택하도록
    if (userInfo.prejobtypeYn === 'Y') {
      if (!prejobtypeState.isNewValue) {
        if ((prejobtypeState.value !== '' && jobtypeState.value === '') || (jobtypeState.value !== '' && prejobtypeState.value === '')) {
          return toast.warning(t('공종/직종 항목을 선택하세요'));
        }
      }
    }

    // 휴대폰/비상연락처 유효성 체크
    if (workerInputSet.wHnum && workerInputSet.wHnum !== '' && !workerInputSet.wHnum.includes('--')) {
      const hNumArray = workerInputSet.wHnum.split('-');
      if (hNumArray[0]?.length < 3 || hNumArray[1]?.length < 3 || hNumArray[2]?.length < 4) return toast.warning(t('휴대폰번호를 확인하세요'));
    }

    if (workerInputSet.wEnum && workerInputSet.wEnum !== '') {
      const eNumArray = workerInputSet.wEnum.split('-');
      if (eNumArray[0]?.length < 3 || eNumArray[1]?.length < 3 || eNumArray[2]?.length < 4) return toast.warning(t('비상연락처를 확인하세요'));
    }

    if (Number(workerInputSet.wLowpress) > 0 && Number(workerInputSet.wHighpress) > 0) {
      // 혈압범위 유효성 체크
      if (Number(workerInputSet.wLowpress) < bloodPressureRange.fromLowpress || Number(workerInputSet.wLowpress) > bloodPressureRange.toLowpress) {
        if (lowestBloodPressureRef.current) {
          lowestBloodPressureRef.current.focus();
        }
        return toast.warning(t('최저혈압 범위를 확인하세요'));
      }

      if (Number(workerInputSet.wHighpress) < bloodPressureRange.fromHighpress || Number(workerInputSet.wHighpress) > bloodPressureRange.toHighpress) {
        if (highestBloodPressureRef.current) highestBloodPressureRef.current.focus();
        return toast.warning(t('최고혈압 범위를 확인하세요'));
      }
    }
    if (wBdate && (wBdate?.length < 8 || !isValidYYYYMMDD(wBdate))) return toast.warning(t('생년월일을 확인해주세요'));
    if (wSafetydate && (wSafetydate?.length < 8 || !isValidYYYYMMDD(wSafetydate))) return toast.warning(t('보호구 지급일자를 확인하세요'));
    if (wHndate && (wHndate?.length < 8 || !isValidYYYYMMDD(wHndate))) return toast.warning(t('검진 일자를 확인하세요'));
    if (wHsdate && (wHsdate?.length < 8 || !isValidYYYYMMDD(wHsdate))) return toast.warning(t('검진 일자를 확인하세요'));
    if (wJobdate && (wJobdate?.length < 8 || !isValidYYYYMMDD(wJobdate))) return toast.warning(t('취업일자를 확인하세요'));
    if (wRetiredate && (wRetiredate?.length < 8 || !isValidYYYYMMDD(wRetiredate))) return toast.warning(t('퇴직일자를 확인하세요'));

    if (!isViewMode) {
      if (isNewAdd) return saveNewWorkerInputAPI();
      return saveWorkerInputAPI();
    }
    return undefined;
  };

  const deleteAPI = async () => {
    const req = { hCd: code.hCd, sCd: code.sCd, wCd: currentWorkerWCd, editor: userInfo.userId };
    const res = await apiDelete({ path: '/worker/info', req });
    const { statusCode, message } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      getReportListAPI && getReportListAPI();
      setOpenModal((prev) => ({ ...prev, status: false }));
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '근로자 정보(팝업)',
        action: '삭제',
        etc: `${workerInputSet.wName}(${workerInputSet.wCd})`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  const onClickDeleteWorkerInfo = () => {
    setOpenSubModal((prev: any) => ({ ...prev, status: true, type: 'delete', api: deleteAPI }));
  };

  const onClickPrev = () => {
    if (reportArray && !isNewAdd) {
      if (currentWorkerIndex !== undefined && currentWorkerIndex > 0) {
        const newIndex = currentWorkerIndex - 1;
        if (listCount) {
          navigate({
            pathname: location.pathname,
            search: `?pageNum=${Math.floor(newIndex / listCount) + 1}`,
          });
        }
        if (setRowKey) setRowKey(newIndex);
        setCurrentWorkerIndex(newIndex);
      } else toast.info(t('이전 근로자가 없습니다'));
    }
  };

  const onClickNext = () => {
    if (reportArray && !isNewAdd) {
      if (currentWorkerIndex !== undefined && reportArray.length - 1 > currentWorkerIndex) {
        const newIndex = currentWorkerIndex + 1;
        if (listCount) {
          navigate({
            pathname: location.pathname,
            search: `?pageNum=${Math.floor(newIndex / listCount) + 1}`,
          });
        }
        if (setReportArray) setReportArray([...reportArray]);

        if (setRowKey) setRowKey(newIndex);
        setCurrentWorkerIndex(newIndex);
      } else toast.info(t('다음 근로자가 없습니다'));
    }
  };
  return (
    <ModalBackground onClick={onClickClose} role='presentation'>
      <InfoWorkerModalStyle>
        <div
          className='modal'
          role='presentation'
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className='inputForm-head flex-between'>
            {!isNewAdd && (
              <div className='innerTabChip flex-start'>
                <div className={`tab ${innerTab === 0 ? 'activeTab' : undefined}`} role='button' tabIndex={0} onClick={() => setInnerTab(0)}>
                  {t('근로자 정보')}
                </div>
                <div className={`tab ${innerTab === 1 ? 'activeTab' : undefined}`} role='button' tabIndex={0} onClick={() => setInnerTab(1)}>
                  {t('근로자 출역내역')}
                </div>
                <div className={`tab ${innerTab === 2 ? 'activeTab' : undefined}`} role='button' tabIndex={0} onClick={() => setInnerTab(2)}>
                  {t('근로자 교육내역')}
                </div>
              </div>
            )}
            {isNewAdd && !changeModal && <div className='modalHeaderTitle'>{t('신규 근로자')}</div>}
            {isNewAdd && changeModal && (
              <div>
                <BackButton func={() => setChangeModal(false)} />
                <div className='modalHeaderCenter'>{t('근로자 검색')}</div>
              </div>
            )}
            <div className='closeBtn' onClick={onClickClose} role='presentation'>
              <span className='material-symbols-rounded'>close</span>
            </div>
          </div>
          {innerTab === 0 &&
            (isNewAdd ? (
              <div className='inputForm-body'>
                <div className={`modal-contents ${changeModal ? 'noPadding' : ''}`}>
                  <WorkerInfo
                    isNewAdd={isNewAdd}
                    isViewMode={isViewMode}
                    originalWorkerInputSet={originalWorkerInputSet}
                    workerInputSet={workerInputSet}
                    setWorkerInputSet={setWorkerInputSet}
                    code={code}
                    auth={auth}
                    onClickClose={onClickClose}
                    bloodPressureRange={bloodPressureRange}
                    setBloodPressureRange={setBloodPressureRange}
                    highestBloodPressureRef={highestBloodPressureRef}
                    lowestBloodPressureRef={lowestBloodPressureRef}
                    getReportListAPI={getReportListAPI}
                    currentWorkerWCd={currentWorkerWCd}
                    insuranceCheckValue={insuranceCheckValue}
                    setInsuranceCheckValue={setInsuranceCheckValue}
                    safetyDeviceCheckValue={safetyDeviceCheckValue}
                    setSafetyDeviceCheckValue={setSafetyDeviceCheckValue}
                    bloodPressure={bloodPressure}
                    setBloodPressure={setBloodPressure}
                    address={address}
                    setAddress={setAddress}
                    userImage={userImage}
                    setUserImage={setUserImage}
                    preJobtypeNormalCdList={preJobtypeNormalCdList}
                    setPreJobtypeNormalCdList={setPreJobtypeNormalCdList}
                    jobtypeNormalCdList={jobtypeNormalCdList}
                    setjobtypeNormalCdList={setjobtypeNormalCdList}
                    jobtypeOnlyNormalCdList={jobtypeOnlyNormalCdList}
                    prejobtypeState={prejobtypeState}
                    setPrejobtypeState={setPrejobtypeState}
                    jobtypeState={jobtypeState}
                    setJobtypeState={setJobtypeState}
                    isSaveClicked={isSaveClicked}
                    grid={grid}
                    setChangeModal={setChangeModal}
                    changeModal={changeModal}
                    wHnumState={wHnumState}
                    setWHNumState={setWHNumState}
                    wEnumState={wEnumState}
                    setWENumState={setWENumState}
                    wJobdate={wJobdate}
                    setWJobdate={setWJobdate}
                    wRetiredate={wRetiredate}
                    setWRetiredate={setWRetiredate}
                    wBdate={wBdate}
                    setWBdate={setWBdate}
                    wHndate={wHndate}
                    setWHndate={setWHndate}
                    wHsdate={wHsdate}
                    setWHsdate={setWHsdate}
                    wSafetydate={wSafetydate}
                    setWSafetydate={setWSafetydate}
                    wGubun={wGubun}
                    setWGubun={setWGubun}
                    sjCd={sjCd}
                    setSjCd={setSjCd}
                    wForeignyn={wForeignyn}
                    setWForeignyn={setWForeignyn}
                    wHnresult={wHnresult}
                    setWHnresult={setWHnresult}
                    wHsresult={wHsresult}
                    setWHsresult={setWHsresult}
                    takeMedicineValue={takeMedicineValue}
                    setTakeMedicineValue={setTakeMedicineValue}
                    isLoading={isLoading}
                    msCdValue={msCdValue}
                    setMsCdValue={setMsCdValue}
                  />
                </div>
              </div>
            ) : workerInfoQuery.isSuccess && workerInfoQuery.data.status === 200 ? (
              <div className='inputForm-body'>
                <div className='modal-contents'>
                  <WorkerInfo
                    isNewAdd={isNewAdd}
                    isViewMode={isViewMode}
                    originalWorkerInputSet={originalWorkerInputSet}
                    workerInputSet={workerInputSet}
                    setWorkerInputSet={setWorkerInputSet}
                    code={code}
                    auth={auth}
                    onClickClose={onClickClose}
                    bloodPressureRange={bloodPressureRange}
                    setBloodPressureRange={setBloodPressureRange}
                    highestBloodPressureRef={highestBloodPressureRef}
                    lowestBloodPressureRef={lowestBloodPressureRef}
                    getReportListAPI={getReportListAPI}
                    currentWorkerWCd={currentWorkerWCd}
                    insuranceCheckValue={insuranceCheckValue}
                    setInsuranceCheckValue={setInsuranceCheckValue}
                    safetyDeviceCheckValue={safetyDeviceCheckValue}
                    setSafetyDeviceCheckValue={setSafetyDeviceCheckValue}
                    bloodPressure={bloodPressure}
                    setBloodPressure={setBloodPressure}
                    address={address}
                    setAddress={setAddress}
                    userImage={userImage}
                    setUserImage={setUserImage}
                    preJobtypeNormalCdList={preJobtypeNormalCdList}
                    setPreJobtypeNormalCdList={setPreJobtypeNormalCdList}
                    jobtypeNormalCdList={jobtypeNormalCdList}
                    setjobtypeNormalCdList={setjobtypeNormalCdList}
                    jobtypeOnlyNormalCdList={jobtypeOnlyNormalCdList}
                    prejobtypeState={prejobtypeState}
                    setPrejobtypeState={setPrejobtypeState}
                    jobtypeState={jobtypeState}
                    setJobtypeState={setJobtypeState}
                    isSaveClicked={isSaveClicked}
                    grid={grid}
                    setChangeModal={setChangeModal}
                    changeModal={changeModal}
                    wHnumState={wHnumState}
                    setWHNumState={setWHNumState}
                    wEnumState={wEnumState}
                    setWENumState={setWENumState}
                    wJobdate={wJobdate}
                    setWJobdate={setWJobdate}
                    wRetiredate={wRetiredate}
                    setWRetiredate={setWRetiredate}
                    wBdate={wBdate}
                    setWBdate={setWBdate}
                    wHndate={wHndate}
                    setWHndate={setWHndate}
                    wHsdate={wHsdate}
                    setWHsdate={setWHsdate}
                    wSafetydate={wSafetydate}
                    setWSafetydate={setWSafetydate}
                    wGubun={wGubun}
                    setWGubun={setWGubun}
                    sjCd={sjCd}
                    setSjCd={setSjCd}
                    wForeignyn={wForeignyn}
                    setWForeignyn={setWForeignyn}
                    wHnresult={wHnresult}
                    setWHnresult={setWHnresult}
                    wHsresult={wHsresult}
                    setWHsresult={setWHsresult}
                    takeMedicineValue={takeMedicineValue}
                    setTakeMedicineValue={setTakeMedicineValue}
                    isLoading={isLoading}
                    msCdValue={msCdValue}
                    setMsCdValue={setMsCdValue}
                  />
                </div>
              </div>
            ) : (
              <div className='centered-content'>
                <PulseLoader color='rgb(0, 122, 255)' size='10px' />
              </div>
            ))}

          {innerTab === 1 && (
            <WorkerAttend
              isViewMode={isViewMode}
              reportArray={reportArray}
              getReportListAPI={getReportListAPI}
              workerInputSet={workerInputSet}
              setWorkerInputSet={setWorkerInputSet}
              code={code}
              auth={auth}
              onClickClose={onClickClose}
              currentWorkerWCd={currentWorkerWCd}
              currentWorkerIndex={currentWorkerIndex}
              onClickPrev={onClickPrev}
              onClickNext={onClickNext}
              setOpenModal={setOpenModal}
            />
          )}
          {innerTab === 2 && <WorkerEducation workerInputSet={workerInputSet} code={code} auth={auth} onClickClose={onClickClose} currentWorkerWCd={currentWorkerWCd} />}
          {!isNewAdd && innerTab === 0 && (
            <div className='modal-footer flex-between'>
              <div className='flex-basic arrows'>
                <BtnBlue onClick={onClickPrev}>
                  <span className='material-symbols-rounded'>arrow_back_ios_new</span>
                  <p>{t('이전')}</p>
                  <div>
                    <p>Page</p>
                    <p>Up</p>
                  </div>
                </BtnBlue>
                <BtnBlue onClick={onClickNext}>
                  <div>
                    <p>Page</p>
                    <p>Down</p>
                  </div>
                  <p>{t('다음')}</p>
                  <span className='material-symbols-rounded'>arrow_forward_ios</span>
                </BtnBlue>
              </div>
              <div className='flex-basic'>
                {auth.deleteAuth && <BtnRed onClick={onClickDeleteWorkerInfo}>{t('삭제')}</BtnRed>}
                {auth.updateAuth && <BtnBlue onClick={onClickSaveWorkerInput}>{t('저장')}</BtnBlue>}
              </div>
            </div>
          )}
          {isNewAdd && !changeModal && (
            <div className='modal-footer flex-end'>
              <div className='flex-basic'>{(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={onClickSaveWorkerInput}>{t('저장')}</BtnBlue>}</div>
            </div>
          )}
        </div>
        <Portal openModal={openSubModal.status}>{openSubModal.status && openSubModal.type === 'delete' && <DeleteModal openModal={openSubModal} setOpenModal={setOpenSubModal} />}</Portal>
      </InfoWorkerModalStyle>
    </ModalBackground>
  );
};

const WorkerInfo = ({
  isNewAdd,
  originalWorkerInputSet,
  workerInputSet,
  setWorkerInputSet,
  code,
  auth,
  bloodPressureRange,
  setBloodPressureRange,
  highestBloodPressureRef,
  lowestBloodPressureRef,
  isViewMode,
  currentWorkerWCd,
  insuranceCheckValue,
  setInsuranceCheckValue,
  safetyDeviceCheckValue,
  setSafetyDeviceCheckValue,
  bloodPressure,
  setBloodPressure,
  address,
  setAddress,
  userImage,
  setUserImage,
  preJobtypeNormalCdList,
  setPreJobtypeNormalCdList,
  jobtypeNormalCdList,
  setjobtypeNormalCdList,
  jobtypeOnlyNormalCdList,
  prejobtypeState,
  setPrejobtypeState,
  jobtypeState,
  setJobtypeState,
  isSaveClicked,
  grid,
  setChangeModal,
  changeModal,
  wHnumState,
  setWHNumState,
  wEnumState,
  setWENumState,
  wJobdate,
  setWJobdate,
  wRetiredate,
  setWRetiredate,
  wBdate,
  setWBdate,
  wHndate,
  setWHndate,
  wHsdate,
  setWHsdate,
  wSafetydate,
  setWSafetydate,
  wGubun,
  setWGubun,
  sjCd,
  setSjCd,
  wForeignyn,
  setWForeignyn,
  wHnresult,
  setWHnresult,
  wHsresult,
  setWHsresult,
  takeMedicineValue,
  setTakeMedicineValue,
  isLoading,
  msCdValue,
  setMsCdValue,
}: any) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const wNameRef = useRef<HTMLInputElement>(null);
  const [chunkedGrid, setChunkedGrid] = useState<any[]>([]);
  const [site, setSite] = useState<any>({});
  const [siteList, setSiteList] = useState<any[]>([]);
  const [otherSiteWorkerList, setOtherSiteWorkerList] = useState<IWorker[]>([]);
  const [wName, setWName] = useState({ wName: '' });
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '' });
  const [columns, setColumns] = useState<any[]>([]);
  const [isSearchClicked, setIsSearchClicked] = useState(false); // 검색버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [loading, setLoading] = useState(false);
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅

  useEffect(() => {
    setColumns([
      {
        header: t('근로자명'),
        name: 'wName',
        sortable: true,
        minWidth: 150,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('협력업체'),
        name: 'sjName',
        sortable: true,
        width: 200,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('휴대폰번호'),
        name: 'wHnum',
        align: 'center',
        sortable: true,
        minWidth: 140,
      },
      {
        header: t('생년월일'),
        name: 'wBdate',
        align: 'center',
        sortable: true,
        minWidth: 140,
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    if (isF9Pressed) {
      if (changeModal) onClickSearchSubModal();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    setBloodPressureRange((prev: any) => ({ ...prev, fromLowpress: userInfo.fromLowpress, toLowpress: userInfo.toLowpress, fromHighpress: userInfo.fromHighpress, toHighpress: userInfo.toHighpress }));
  }, [userInfo.fromLowpress, userInfo.toLowpress, userInfo.fromHighpress, userInfo.toHighpress]);

  useEffect(() => {
    if (openModal.status === true && openModal.type === 'workerSearch') {
      setWName({ wName: openModal.wName });
      getSiteListAPI();
    }
  }, [openModal]);

  useEffect(() => {
    const newGrid = [...grid];

    // 그리드아이템을 3개씩 잘라서 배열에 넣어 이중배열로 만들어서 setState
    const result = newGrid.map((_, index) => {
      if ((index + 1) % 3 === 0) {
        return newGrid.slice(index - 2, index + 1);
      }
      return undefined;
    });

    setChunkedGrid(result.filter((item) => item));
  }, [grid]);

  const getSiteListAPI = async () => {
    const req = { hCd: code.hCd, sCd: code.sCd };
    const res = await apiGet({ path: '/site/other', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      const formatSelectBoxFormList = data.siteInfoList.map((el2: any, i: number) => {
        return { type: 'sCd', sCd: el2.sCd, name: el2.sName, cdSort: i + 1 };
      });
      const newObj = { type: 'sCd', sCd: '', name: t('선택'), cdSort: 0 };
      const addAllSelect: any = [...formatSelectBoxFormList, newObj];
      const sortedArray: any = arraySortByAscdOrder(addAllSelect, 'cdSort');
      setSiteList(sortedArray);
    } else {
      // toast.error(t(ERROR));
    }
  };

  const onClickSearchSubModal = () => {
    setIsSearchClicked(true);
    if (!site.sCd) return toast.warning(t('현장을 선택하세요'));
    if (wName.wName?.trim() === '') return toast.warning(t('근로자명을 입력하세요'));
    {
      setLoading(true);
      return getOtherSiteWorkerList();
    }
  };

  const openSearchModalOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onClickSearchSubModal();
    }
  };

  const getOtherSiteWorkerList = async () => {
    const req = { hCd: code.hCd, sCd: site.sCd, wName: wName.wName };
    const res = await apiGet({ path: '/worker/modal', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      setLoading(false);
      setOtherSiteWorkerList(data.workerList);
    } else {
      setLoading(false);
      // toast.error(t(ERROR));
    }
  };

  const onClickCloseSubModal = () => {
    setIsSearchClicked(false);
    setOpenModal({ status: false, type: '' });
    setChangeModal(false);
    setSite({ sCd: '' });
    setWName({ wName: '' });
    setOtherSiteWorkerList([]);
  };

  const onClickRow = (rowData: any) => {
    const { wAddress1, wAddress2, wBlood, wEnum, wHnum } = rowData;
    // setOpenSubModal({ status: false });
    setChangeModal(false);
    setOpenModal({ status: false, type: '' });
    setSite({ sCd: '' });
    setWName({ wName: '' });
    setOtherSiteWorkerList([]);
    setWorkerInputSet((prev: any) => ({
      ...prev,
      wAddress1,
      wAddress2,
      wBlood,
      wEnum,
      wHnum,
      wName: rowData.wName,
      wBdate: rowData.wBdate,
    }));

    const splitNumber = wHnum.split('-');
    setWHNumState((prev: any) => ({
      ...prev,
      num1: splitNumber[0],
      num2: splitNumber[1],
      num3: splitNumber[2],
    }));
  };

  const componentRef = useRef<HTMLDivElement>(null);
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight, componentRef.current?.offsetWidth]);

  return (
    <div>
      {!changeModal && (
        <WorkerGrid>
          {chunkedGrid.length > 0 &&
            chunkedGrid.map((cell: any, index: number) => (
              <tr key={`${cell}_${index}`}>
                {cell.map((cell2: any, index2: number) => (
                  <td
                    key={`${cell2.id}_${index2}`}
                    // className={`${cell2.content && cell2.content.etc1 === 1 ? 'grid-column-1' : '' ? 'grid-row-1' : ''}${cell2.content && cell2.content.etc1 === 2 ? 'grid-column-2' : ''} ${
                    //   cell2.content && cell2.content.etc1 === 3 ? 'grid-column-3' : ''
                    // }`}
                    style={{
                      width: '33.3%',
                      height: '2.5rem',
                      display: cell2 && cell2.hidden === true ? 'none' : '',
                      // display: 'inherit',
                      borderRadius: '0.5rem',
                      minWidth: '20rem',
                    }}
                    rowSpan={cell2.content && cell2.content.wCd === '2' && 4}
                    colSpan={cell2.content && cell2.content.etc1}
                  >
                    <Control
                      isNewAdd={isNewAdd}
                      isViewMode={isViewMode}
                      code={code}
                      workerInputSet={workerInputSet}
                      setWorkerInputSet={setWorkerInputSet}
                      wCd={cell2.content?.wCd}
                      cdName={cell2.content?.cdName}
                      propertyName={cell2?.propertyName}
                      normalCd={cell2?.normalCd}
                      highestBloodPressureRef={highestBloodPressureRef}
                      lowestBloodPressureRef={lowestBloodPressureRef}
                      bloodPressureRange={bloodPressureRange}
                      setOpenModal={setOpenModal}
                      currentWorkerWCd={currentWorkerWCd}
                      insuranceCheckValue={insuranceCheckValue}
                      setInsuranceCheckValue={setInsuranceCheckValue}
                      safetyDeviceCheckValue={safetyDeviceCheckValue}
                      setSafetyDeviceCheckValue={setSafetyDeviceCheckValue}
                      bloodPressure={bloodPressure}
                      setBloodPressure={setBloodPressure}
                      address={address}
                      setAddress={setAddress}
                      userImage={userImage}
                      setUserImage={setUserImage}
                      preJobtypeNormalCdList={preJobtypeNormalCdList}
                      setPreJobtypeNormalCdList={setPreJobtypeNormalCdList}
                      jobtypeNormalCdList={jobtypeNormalCdList}
                      setjobtypeNormalCdList={setjobtypeNormalCdList}
                      prejobtypeState={prejobtypeState}
                      setPrejobtypeState={setPrejobtypeState}
                      jobtypeOnlyNormalCdList={jobtypeOnlyNormalCdList}
                      jobtypeState={jobtypeState}
                      setJobtypeState={setJobtypeState}
                      isSaveClicked={isSaveClicked}
                      setChangeModal={setChangeModal}
                      wHnumState={wHnumState}
                      setWHNumState={setWHNumState}
                      wEnumState={wEnumState}
                      setWENumState={setWENumState}
                      wJobdate={wJobdate}
                      setWJobdate={setWJobdate}
                      wRetiredate={wRetiredate}
                      setWRetiredate={setWRetiredate}
                      wBdate={wBdate}
                      setWBdate={setWBdate}
                      wHndate={wHndate}
                      setWHndate={setWHndate}
                      wHsdate={wHsdate}
                      setWHsdate={setWHsdate}
                      wSafetydate={wSafetydate}
                      setWSafetydate={setWSafetydate}
                      wGubun={wGubun}
                      setWGubun={setWGubun}
                      sjCd={sjCd}
                      setSjCd={setSjCd}
                      wForeignyn={wForeignyn}
                      setWForeignyn={setWForeignyn}
                      wHnresult={wHnresult}
                      setWHnresult={setWHnresult}
                      wHsresult={wHsresult}
                      setWHsresult={setWHsresult}
                      takeMedicineValue={takeMedicineValue}
                      setTakeMedicineValue={setTakeMedicineValue}
                      isLoading={isLoading}
                      msCdValue={msCdValue}
                      setMsCdValue={setMsCdValue}
                    />
                  </td>
                ))}
              </tr>
            ))}
        </WorkerGrid>
      )}
      {changeModal && (
        <SubModal className='subModal searchOption'>
          <div className='flex-basic searchWrapper'>
            <div className='flex-basic'>
              <SearchSelectBoxSm
                options={siteList}
                defaultOption={t('타 현장 필수선택')}
                // defaultOption={t('선택')}
                state={site}
                setState={setSite}
                stateKey='sCd'
                codeKey='name'
                optionHeight='height-md'
                getBorderStyle={isSearchClicked ? applyBorderStyle(site.sCd, 'red', 'sCd') : undefined}
              />
            </div>
            <Input
              className='required'
              type='text'
              // label={t('근로자명')}
              placeholder={t('근로자명')}
              name='wName'
              state={wName}
              setState={setWName}
              inputRef={wNameRef}
              onKeyDown={openSearchModalOnEnter}
              getBorderStyle={isSearchClicked ? applyBorderStyle(wName.wName, 'red', 'wName') : undefined}
            />
            {/* <BtnBlue onClick={onClickSearchSubModal}>{t("검색")}</BtnBlue> */}
            <BtnGhost onClick={onClickSearchSubModal} className='searchBtn'>
              {t('검색')}
              <span className='shortcut-f9'>F9</span>
            </BtnGhost>
          </div>
          <div ref={componentRef} className='tui-container'>
            <TuiGrid data={otherSiteWorkerList} columns={columns} usePagenation={false} onClickRow={onClickRow} frozenCount={1} height={tuiHeight} />
          </div>
        </SubModal>
      )}
      <Portal openModal={loading}>
        <LoadingModalBackground>
          <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' style={{ width: 'inherit', height: 'inherit' }} />
        </LoadingModalBackground>
      </Portal>
      <Portal openModal={openModal?.status}>{openModal.status === true && openModal.type === 'delete' && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
    </div>
  );
};

const Control = ({
  isNewAdd,
  isViewMode,
  code,
  workerInputSet,
  setWorkerInputSet,
  wCd,
  cdName,
  propertyName,
  normalCd,
  lowestBloodPressureRef,
  highestBloodPressureRef,
  bloodPressureRange,
  setOpenModal,
  currentWorkerWCd,
  insuranceCheckValue,
  setInsuranceCheckValue,
  safetyDeviceCheckValue,
  setSafetyDeviceCheckValue,
  bloodPressure,
  setBloodPressure,
  address,
  setAddress,
  userImage,
  setUserImage,
  preJobtypeNormalCdList,
  setPreJobtypeNormalCdList,
  jobtypeNormalCdList,
  setjobtypeNormalCdList,
  jobtypeOnlyNormalCdList,
  prejobtypeState,
  setPrejobtypeState,
  jobtypeState,
  setJobtypeState,
  isSaveClicked,
  setChangeModal,
  wHnumState,
  setWHNumState,
  wEnumState,
  setWENumState,
  wJobdate,
  setWJobdate,
  wRetiredate,
  setWRetiredate,
  wBdate,
  setWBdate,
  wHndate,
  setWHndate,
  wHsdate,
  setWHsdate,
  wSafetydate,
  setWSafetydate,
  wGubun,
  setWGubun,
  sjCd,
  setSjCd,
  wForeignyn,
  setWForeignyn,
  wHnresult,
  setWHnresult,
  wHsresult,
  setWHsresult,
  takeMedicineValue,
  setTakeMedicineValue,
  isLoading,
  msCdValue,
  setMsCdValue,
}: any) => {
  const { t } = useTranslation();
  const INSURANCE = 0;
  const SAFETY_DEVICE = 1;
  const { hCd, sCd } = code;
  const toDay = todayYYYYMMDD();
  const userInfo = useRecoilValue(userState);
  const wNameRef = useRef<HTMLInputElement>(null);
  const telLengthState = { num1: 3, num2: 4, num3: 4 }; // 전화번호 입력 길이
  const [wNationalityNormalCdList, setWNationalityNormalCdList] = useState([]); // 국적 일반코드리스트
  const [wVisaNormalCdList, setWVisaNormalCdList] = useState([]); // 비자 일반코드리스트
  const [wBloodNormalCdList, setWBloodNormalCdList] = useState([]); // 혈액형 일반코드리스트
  const [sjCdList, setSjCdList] = useState([]); // 협력업체 코드리스트
  const [orgSjCdList, setOrgSjCdList] = useState([]); // 협력업체 코드리스트
  const [nHealthCdList, setNHealthCdList] = useState([]); // 일반 건강검진결과 목록 리스트
  const [sHealthCdList, setSHealthCdList] = useState([]); // 특수 건강검진결과 목록 리스트
  const [wGubunCdList, setWGubunCdList] = useState<IComCdList[]>([]); // 근로자구분 목록 리스트
  const [selectAll, setSelectAll] = useState(false);
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const msiteYn = useRecoilValue(msiteUseYnState);

  useEffect(() => {
    if (isF9Pressed) {
      onClickWNameSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  const handleCheckboxChange = (index: number, type: number) => {
    if (type === INSURANCE) {
      setInsuranceCheckValue(insuranceCheckValue.map((item: any, i: number) => (i === index ? { ...item, check: !item.check } : item)));
    }
    if (type === SAFETY_DEVICE) {
      setSafetyDeviceCheckValue(safetyDeviceCheckValue.map((item: any, i: number) => (i === index ? { ...item, check: !item.check } : item)));
      if (safetyDeviceCheckValue[index].check) {
        setSelectAll(false);
      }
    }
  };

  useEffect(() => {
    const hasCheckedValue = safetyDeviceCheckValue.find((v: any) => v.check === true);
    if (!hasCheckedValue) {
      setWorkerInputSet({ ...workerInputSet, wSafetydate: '' });
      setSelectAll(false);
    } else if (workerInputSet.wSafetydate === '') {
      setWorkerInputSet({ ...workerInputSet, wSafetydate: todayYYYYMMDD() });
    }

    const allChecked = safetyDeviceCheckValue.every((item: any) => item.check);
    if (allChecked) setSelectAll(true);
  }, [safetyDeviceCheckValue]);

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectAll(false);
      for (const v of safetyDeviceCheckValue) {
        v.check = false;
      }
      setWorkerInputSet({ ...workerInputSet, wSafetydate: '' });
    } else {
      setSelectAll(true);
      for (const v of safetyDeviceCheckValue) {
        v.check = true;
      }
      if (workerInputSet.wSafetydate === '') {
        setWorkerInputSet({ ...workerInputSet, wSafetydate: todayYYYYMMDD() });
      }
    }
  };

  useEffect(() => {
    if (wCd === '3') getSiteJoinList();
    if (wCd === '15') getNormalCodeListAPI('wNationality', normalCd, setWNationalityNormalCdList);
    if (wCd === '17') getNormalCodeListAPI('wVisa', normalCd, setWVisaNormalCdList);
    if (wCd === '18') getNormalCodeListAPI('wBlood', normalCd, setWBloodNormalCdList);
    if (wCd === '12') {
      // getPrejobtypeAPI('000'); // 공종목록 호출
      // if (!isNewAdd) getJobtypeAPI(workerInputSet.wPrejobtype); // 직종목록 호출
      if (isNewAdd) {
        setPrejobtypeState({ label: t('선택'), value: '', isNewValue: false });
        setJobtypeState({ label: t('선택'), value: '', isNewValue: false });
      }
    }
    if (wCd === '27') getHealthResultAPI('N', setNHealthCdList);
    if (wCd === '28') getHealthResultAPI('S', setSHealthCdList);
    if (wCd === '34') setComCdListState(COMCD_WORKER_GUBUN, setWGubunCdList, false);
  }, [isNewAdd, currentWorkerWCd]);

  useEffect(() => {
    if (address.address1 !== '') setWorkerInputSet((prev: any) => ({ ...prev, wAddress1: address.address1 }));
    if (address.address2 !== '') setWorkerInputSet((prev: any) => ({ ...prev, wAddress2: address.address2 }));
    if (address.postNum !== '') setWorkerInputSet((prev: any) => ({ ...prev, wZipcode: address.postNum }));
  }, [address]);

  useEffect(() => {
    if (Number(bloodPressure.high) > 0) setWorkerInputSet((prev: any) => ({ ...prev, wHighpress: Number(bloodPressure.high) }));
    if (Number(bloodPressure.low) > 0) setWorkerInputSet((prev: any) => ({ ...prev, wLowpress: Number(bloodPressure.low) }));
  }, [bloodPressure]);

  useEffect(() => {
    if (wHnumState.num1 !== '' && wHnumState.num2 !== '' && wHnumState.num3 !== '') {
      const mergedNum = `${wHnumState.num1}-${wHnumState.num2}-${wHnumState.num3}`;
      setWorkerInputSet((prev: any) => ({ ...prev, wHnum: mergedNum }));
    }
  }, [wHnumState]);

  useEffect(() => {
    if (wEnumState.num1 !== '' && wEnumState.num2 !== '' && wEnumState.num3 !== '') {
      const mergedNum = `${wEnumState.num1}-${wEnumState.num2}-${wEnumState.num3}`;
      setWorkerInputSet((prev: any) => ({ ...prev, wEnum: mergedNum }));
    }
  }, [wEnumState]);

  useEffect(() => {
    // 공종이 변경되었을 때 직종목록 다시 호출하고, 공종선택값 변경, 직종선택값 초기화
    if (userInfo.prejobtypeYn === 'Y' && prejobtypeState.value !== workerInputSet.wPrejobtype) {
      getJobtypeAPI(prejobtypeState.value);
      setJobtypeState((prev: any) => ({ ...prev, label: t('선택'), value: '' }));
    }
  }, [prejobtypeState.value]);

  useEffect(() => {
    if (workerInputSet.wEnum === '' || workerInputSet.wEnum === null) {
      setWENumState((prev: any) => ({ ...prev, num1: '', num2: '', num3: '' }));
    } else if (workerInputSet.wEnum) {
      const splitNumber = workerInputSet.wEnum.split('-');
      setWENumState((prev: any) => ({ ...prev, num1: splitNumber[0], num2: splitNumber[1], num3: splitNumber[2] }));
    }
  }, [workerInputSet.wEnum]);

  // useEffect(() => {
  //   setAddress({ address1: workerInputSet.wAddress1, address2: workerInputSet.wAddress2, postNum: workerInputSet.wZipcode });
  // }, [workerInputSet.wZipcode, workerInputSet.wAddress1, workerInputSet.wAddress2]);

  useEffect(() => {
    if (wHndate !== '') setWorkerInputSet((prev: any) => ({ ...prev, wHndate }));
  }, [wHndate]);

  useEffect(() => {
    if (wHsdate !== '') setWorkerInputSet((prev: any) => ({ ...prev, wHsdate }));
  }, [wHsdate]);

  useEffect(() => {
    if (wBdate !== '') setWorkerInputSet((prev: any) => ({ ...prev, wBdate }));
  }, [wBdate]);

  useEffect(() => {
    if (wJobdate !== '') setWorkerInputSet((prev: any) => ({ ...prev, wJobdate }));
  }, [wJobdate]);

  useEffect(() => {
    if (wRetiredate !== '') setWorkerInputSet((prev: any) => ({ ...prev, wRetiredate }));
  }, [wRetiredate]);

  useEffect(() => {
    if (wSafetydate !== '') setWorkerInputSet((prev: any) => ({ ...prev, wSafetydate }));
  }, [wSafetydate]);

  // 협력업체 목록 조회 API
  const getSiteJoinList = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, delYnCheck: 'Y' };
    const res = await apiGet({ path: '/siteJoin', req });

    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      if (data.siteJoinList?.length > 0) {
        const useYList = data.siteJoinList.filter((el: any) => el.useYn === 'Y');
        setSjCdList(useYList.map((el: any) => ({ type: 'sjCd', sjCd: el.sjCd, cdName: el.sjName })));
        setOrgSjCdList(data.siteJoinList.map((el: any) => ({ type: 'sjCd', sjCd: el.sjCd, cdName: el.sjName })));
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  const getHealthResultAPI = async (type: string, setState: Dispatch<SetStateAction<any>>) => {
    const req = { hCd: code.hCd, sCd: code.sCd, hcpCodeOne: type };
    const res = await apiGet({ path: '/setting/health/box', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      if (type === 'S') {
        const newArray = data.healthSet.map((el: any) => {
          return { type: 'wHsresult', wHsresult: el.hcpCode, cdName: el.hcpName };
        });
        const addNoValueObj = { type: 'wHsresult', wHsresult: '', cdName: t('선택') }; // 리스트에 선택값 추가
        const resultArray = [addNoValueObj, ...newArray];
        setState(resultArray);
      }

      if (type === 'N') {
        const newArray = data.healthSet.map((el: any) => {
          return { type: 'wHnresult', wHnresult: el.hcpCode, cdName: el.hcpName };
        });
        const addNoValueObj = { type: 'wHnresult', wHnresult: '', cdName: t('선택') }; // 리스트에 선택값 추가
        const resultArray = [addNoValueObj, ...newArray];
        setState(resultArray);
      }
    }
  };

  const getNormalCodeListAPI = async (type: string, subCd: string, setState: Dispatch<SetStateAction<any>>) => {
    const req = { hCd, sCd, subCd };
    const res = await apiGet({ path: '/code/normal/site', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const newArray = data.normalList.map((el: any) => ({ type, [type]: el.cdName, cdName: el.cdName }));
      const addNoValueObj = { type, [type]: '', cdName: t('선택') }; // 리스트에 선택값 추가
      const resultArray = [addNoValueObj, ...newArray];
      setState(resultArray);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 직종 코드리스트 호출
  const getJobtypeAPI = async (grCd: string) => {
    const req = {
      hCd: code.hCd,
      sCd: code.sCd,
      grCd,
    };
    const res = await apiGet({ path: '/code/normal/prejobtype', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      // 공종+직종일 때 직종리스트
      if (userInfo.prejobtypeYn === 'Y') {
        const formatSelectBoxFormList = data.prejobtypeList.map((el: IComCd) => {
          return { value: el.subCd, label: el.cdName };
        });
        const addDefaultObject = { value: '', label: t('선택') };
        const newArray: any = [addDefaultObject, ...formatSelectBoxFormList];
        setjobtypeNormalCdList(newArray);
      } else {
        // 직종만일 때 직종리스트
        const jobTypeList = data.prejobtypeList.filter((el: any) => el.subCd.charAt(0) === 'B');
        const formatSelectBoxFormList = jobTypeList.map((el: IComCd) => {
          return { value: el.subCd, label: el.cdName };
        });
        const addDefaultObject = { value: '', label: t('선택') };
        const newArray: any = [addDefaultObject, ...formatSelectBoxFormList];
        setjobtypeNormalCdList(newArray);
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      try {
        const compressedImageBlob = await readAndCompressImage(file, config);

        // Create a new File object from the Blob
        const compressedImage = new File([compressedImageBlob], file.name, {
          type: compressedImageBlob.type,
          lastModified: Date.now(),
        });

        fileToBase64(compressedImage, (result: string) => {
          setUserImage({ img: result, isUpdate: true });
          setWorkerInputSet((prev: any) => ({ ...prev, wImgBase64: result.split(',')[1] }));
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onChangeWName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const checkedValue = quoteRegex(value);
    setWorkerInputSet((prev: any) => ({ ...prev, [name]: checkedValue }));
  };

  const handleChangeCreatableSelect = (newValue: any, actionMeta: any, type: string) => {
    const typeName = `${type}Name`;
    if (newValue) {
      if (actionMeta.action === 'create-option') {
        if (type === 'wPrejobtype') {
          setPrejobtypeState({ value: newValue.value, label: newValue.label, isNewValue: true });
        } else {
          setJobtypeState({ value: newValue.value, label: newValue.label, isNewValue: true });
        }
      } else if (type === 'wPrejobtype') {
        setPrejobtypeState({ value: newValue.value, label: newValue.label, isNewValue: false });
        setJobtypeState({ value: '', label: '선택', isNewValue: false }); // 공종선택값이 변경될 경우 직종선택값 선택으로 초기화
        getJobtypeAPI(newValue.value); // 공종선택값이 변경될 경우 직종목록변경
      } else {
        setJobtypeState({ value: newValue.value, label: newValue.label, isNewValue: false });
      }
    } else {
      // 밸류를 지웠을때
      setWorkerInputSet((prev: IWorkerInputSet) => ({ ...prev, [type]: '', [typeName]: '' }));
    }
  };

  const onClickWNameSearch = () => {
    setOpenModal({ status: true, type: 'workerSearch', wName: workerInputSet.wName });
    setChangeModal(true);
  };

  const searchOnEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (wNameRef.current) {
        onClickWNameSearch();
      }
    }
  };

  if (wCd === '1') {
    /**
     * cdName : 근로자 명
     * propertyName : wName
     */
    return (
      <div className='control input'>
        <label className='required' htmlFor='wName'>
          {t(cdName)}
        </label>
        <div className='nameContainer'>
          <input
            type='text'
            name={propertyName && propertyName}
            disabled={isViewMode}
            value={workerInputSet.wName}
            onChange={onChangeWName}
            ref={wNameRef}
            onKeyDown={(e) => searchOnEnterKeyDown(e)}
            style={isSaveClicked ? applyBorderStyle(workerInputSet.wName, 'red', 'wName') : undefined}
            maxLength={50}
          />
          {isNewAdd && (
            <BtnGhost onClick={onClickWNameSearch} className='searchBtn'>
              {t('검색')}
              <span className='shortcut-f9'>F9</span>
            </BtnGhost>
          )}
        </div>
      </div>
    );
  }

  if (wCd === '2') {
    /**
     * cdName : 근로자 사진
     * propertyName : wImg
     */
    const randomString = Math.random().toString(36).substring(7); // 랜덤 문자열을 쿼리스트링으로 추가해서 이미지 캐싱 방지

    return (
      <div className='control' style={{ height: '100%', justifyContent: 'center' }}>
        <div className='userImageWrapper fileBoxWrapper'>
          {userImage.img !== '' ? <img src={userImage.isUpdate ? `${userImage.img}` : `${userImage.img}?v=${randomString}`} style={{ height: '100%' }} /> : <IoPersonAdd size={50} />}
          {!isViewMode && (
            <>
              <input type='file' className='hiddenFileBox' accept='image/jpg, image/png, image/jpeg' id='filebox' name='dIfilename' onChange={handleFileInputChange} />
              <label htmlFor='filebox' className='searchBox'>
                <div className='smallBtn' id='filebox'>
                  {t('찾아보기')}
                </div>
              </label>
            </>
          )}
        </div>
      </div>
    );
  }

  if (wCd === '3') {
    /**
     * cdName : 협력업체명
     * propertyName : sjCd
     */
    return (
      <div className='control selector'>
        <label className='required' htmlFor={propertyName}>
          {t(cdName)}
        </label>
        <SearchSelectBoxSm
          options={sjCdList}
          defaultOption={sjCd.cdName || t('선택')}
          state={sjCd}
          setState={setSjCd}
          stateKey='sjCd'
          codeKey='cdName'
          initiateKey={sjCd[propertyName]}
          disabled={isViewMode}
          optionHeight='height-lg'
          getBorderStyle={isSaveClicked ? applyBorderStyle(sjCd[propertyName], 'red', 'sjCd') : undefined}
        />
      </div>
    );
  }

  if (wCd === '4' || wCd === '16' || wCd === '25' || wCd === '26' || wCd === '35') {
    /**
     * cdName : 영문명 || 여권번호 || 특이사항 || 비고 || 비콘 ID
     * propertyName : wEname || wPassport || wSmemo || wBigo || wBcid
     */
    const placeholder = () => {
      if (wCd === '35') return t('영문키 변경 후 입력');
      return '';
    };

    return (
      <div className='control input'>
        <Input
          type='text'
          label={t(cdName)} //
          name={propertyName && propertyName}
          state={workerInputSet}
          setState={setWorkerInputSet}
          disabled={isViewMode}
          placeholder={placeholder()}
        />
      </div>
    );
  }

  if (wCd === '5') {
    /**
     * cdName : 근로상태
     * propertyName : wWorkstatus
     */
    const findWorkStatusName = WORK_STATUS_INPUT.find((el: any) => el.subCd === workerInputSet[propertyName]);
    if (isNewAdd || findWorkStatusName === undefined) return null;
    return (
      <div className='control viewOnly'>
        <label>{t(cdName)}</label>
        <div>{t(findWorkStatusName.cdName)}</div>
      </div>
    );
  }

  if (wCd === '6') {
    /**
     * cdName : 생년월일
     * propertyName : wBdate
     */
    return (
      <div className='control datePickerWrapper'>
        <label htmlFor={wCd}>{t(cdName)}</label>
        <DatePicker startDate={workerInputSet[propertyName]} setDate={setWBdate} popperPlacement='bottom' disabled={isViewMode} />
      </div>
    );
  }

  if (wCd === '7') {
    /**
     * cdName : 휴대폰
     * propertyName : wHnum
     */
    return (
      <div className='control passport'>
        <label htmlFor={cdName}>{t(cdName)}</label>
        <NumbersInput state={wHnumState} setState={setWHNumState} lengthState={telLengthState} disabled={isViewMode} />
      </div>
    );
  }

  if (wCd === '8') {
    /**
     * cdName : 비상연락처
     * propertyName : wEnum
     */
    return (
      <div className='control mysterious'>
        <label htmlFor={cdName}>{t(cdName)}</label>
        <NumbersInput state={wEnumState} setState={setWENumState} lengthState={telLengthState} disabled={isViewMode} />
      </div>
    );
  }

  if (wCd === '9') {
    /**
     * cdName : 주소
     * propertyName : wZipcode, wAddress1, wAddress2
     */
    return (
      <div className='control address'>
        <label htmlFor={wCd}>{t(cdName)}</label>
        <div className='flex-basic'>
          <AddressSearch setState={setAddress} disabled={isViewMode} />
          <Input type='text' placeholder={t('우편번호')} id='postNum' name='postNum' state={address} setState={setAddress} disabled={isViewMode} />
          <Input type='text' id='address1' name='address1' state={address} setState={setAddress} placeholder={t('주소')} disabled={isViewMode} />
          <Input type='text' id='address2' name='address2' state={address} setState={setAddress} placeholder={t('상세주소')} disabled={isViewMode} maxLength={50} />
        </div>
      </div>
    );
  }

  if (wCd === '10') {
    /**
     * cdName : 취업일자
     * propertyName : wJobdate
     */
    return (
      <div className='control datePickerWrapper'>
        <label htmlFor={wCd}>{t(cdName)}</label>
        <DatePicker startDate={isNewAdd ? toDay : workerInputSet[propertyName]} setDate={setWJobdate} popperPlacement='bottom' disabled={isViewMode} />
      </div>
    );
  }

  if (wCd === '11') {
    /**
     * cdName : 퇴직일자
     * propertyName : wRetiredate
     */
    return (
      <div className='control datePickerWrapper'>
        <label htmlFor={wCd}>{t(cdName)}</label>
        <DatePicker startDate={workerInputSet[propertyName]} setDate={setWRetiredate} popperPlacement='bottom' disabled={isViewMode} />
      </div>
    );
  }

  if (userInfo.prejobtypeYn === 'Y' && wCd === '12') {
    /**
     * cdName : 공종+직종
     * propertyName : ''
     */
    return (
      <div className='control preJobtypeCreateSelect'>
        <div className='flex-basic'>
          <label htmlFor='preJobtype'>{t('공종')}</label>
          <CreatableSelect
            className='reactSelect'
            classNames={{
              option: (state) => (state.isFocused ? 'option-hover' : ''),
            }}
            isClearable={false}
            options={preJobtypeNormalCdList}
            defaultValue={isNewAdd || prejobtypeState.value === '' ? { value: '', label: t('선택') } : { value: prejobtypeState.value, label: prejobtypeState.label }}
            value={prejobtypeState}
            placeholder={t('선택')}
            formatCreateLabel={(inputValue) => `${t('등록')} "${inputValue}"`}
            onChange={(newValue, actionMeta) => handleChangeCreatableSelect(newValue, actionMeta, 'wPrejobtype')}
            isDisabled={isViewMode}
            menuPlacement='auto'
          />
        </div>
        <div className='flex-basic'>
          <label htmlFor='preJobtype'>{t('직종')}</label>
          <CreatableSelect
            className='reactSelect'
            classNames={{
              option: (state) => (state.isFocused ? 'option-hover' : ''),
            }}
            isClearable={false}
            options={jobtypeNormalCdList}
            defaultValue={isNewAdd || jobtypeState.value === '' ? { value: '', label: t('선택') } : { value: jobtypeState.value, label: jobtypeState.label }}
            value={jobtypeState}
            placeholder={t('선택')}
            formatCreateLabel={(inputValue) => `${t('등록')} "${inputValue}"`}
            onChange={(newValue, actionMeta) => handleChangeCreatableSelect(newValue, actionMeta, 'wJobtype')}
            isDisabled={isViewMode}
            menuPlacement='auto'
          />
        </div>
      </div>
    );
  }

  if (userInfo.prejobtypeYn !== 'Y' && wCd === '13') {
    /**
     * cdName : 직종
     * propertyName : ''
     */
    let jobCdName = '';
    const defaultjob: any = jobtypeOnlyNormalCdList.find((el: any) => el.wJobtype === workerInputSet.wJobtype);
    if (defaultjob !== undefined) {
      jobCdName = defaultjob.name;
    }
    return (
      <div className='control jobtypeCreateSelect'>
        <div className='flex-basic'>
          <label htmlFor='jobtype'>{t('직종')}</label>
          <CreatableSelect
            className='reactSelect'
            classNames={{
              option: (state) => (state.isFocused ? 'option-hover' : ''),
            }}
            isClearable={false}
            options={jobtypeOnlyNormalCdList}
            defaultValue={isNewAdd || jobtypeState.value === '' ? { value: '', label: t('선택') } : { value: jobtypeState.value, label: jobtypeState.label }}
            value={jobtypeState}
            placeholder={t('선택')}
            formatCreateLabel={(inputValue) => `${t('등록')} "${inputValue}"`}
            onChange={(newValue, actionMeta) => handleChangeCreatableSelect(newValue, actionMeta, 'wjobtype')}
            isDisabled={isViewMode}
            menuPlacement='auto'
          />
        </div>
      </div>
    );
  }

  if (wCd === '14') {
    /**
     * cdName : 외국인 여부
     * propertyName : wForeignyn
     */

    let wForeignynCdname = '';

    const wForeignynObj: any = FOREIGN_YN_INPUT?.find((el: any) => el.wForeignyn === wForeignyn.wForeignyn);
    if (wForeignynObj) wForeignynCdname = wForeignynObj.cdName;

    return (
      <div className='control selector'>
        <label htmlFor={propertyName}>{t(cdName)}</label>
        <SelectBox
          options={FOREIGN_YN_INPUT}
          defaultOption={wForeignynCdname || t('선택')}
          state={wForeignyn}
          setState={setWForeignyn}
          stateKey='wForeignyn'
          disabled={isViewMode}
          initiateKey={workerInputSet[propertyName]}
        />
      </div>
    );
  }

  if (wCd === '34') {
    /**
     * cdName :근로자 구분
     * propertyName : wGubun
     */
    let gubunCdName = '';
    const findWGubun = wGubunCdList.find((el: any) => el[COMCD_WORKER_GUBUN] === wGubun[COMCD_WORKER_GUBUN]);
    const findDefaultWGubun = wGubunCdList.find((el: any) => el[COMCD_WORKER_GUBUN] === '01'); // 근로자코드 기본값은 코드 01에 해당하는 값

    if (findWGubun) gubunCdName = findWGubun.cdName;
    else if (findDefaultWGubun) gubunCdName = findDefaultWGubun.cdName;

    return (
      <div className='control selector'>
        <label htmlFor={propertyName}>{t(cdName)}</label>
        <SelectBox options={wGubunCdList} defaultOption={gubunCdName} state={wGubun} setState={setWGubun} stateKey={COMCD_WORKER_GUBUN} disabled={isViewMode} initiateKey={wGubun.wGubun} />
      </div>
    );
  }

  if (wCd === '15') {
    /**
     * cdName : 국적
     * propertyName : wNationality
     */
    return (
      <div className='control searchSelect'>
        <label htmlFor={propertyName}>{t(cdName)}</label>
        <SearchSelectBoxSm
          options={wForeignyn.wForeignyn === 'N' ? [] : wNationalityNormalCdList}
          defaultOption={workerInputSet[propertyName] ? workerInputSet[propertyName] : !isViewMode ? t('선택') : ''}
          state={workerInputSet}
          setState={setWorkerInputSet}
          stateKey={propertyName}
          codeKey='cdName'
          initiateKey={workerInputSet[propertyName]}
          disabled={isViewMode || wForeignyn.wForeignyn === 'N'}
          optionHeight='height-base'
        />
      </div>
    );
  }

  if (wCd === '17' || wCd === '18') {
    /**
     * cdName :비자 || 혈액형
     * propertyName : wVisa || wBlood
     */
    return (
      <div className='control searchSelect'>
        <label htmlFor={propertyName}>{t(cdName)}</label>
        <SearchSelectBoxSm
          options={wCd === '15' ? wNationalityNormalCdList : wCd === '17' ? wVisaNormalCdList : wBloodNormalCdList}
          defaultOption={workerInputSet[propertyName] ? workerInputSet[propertyName] : !isViewMode ? t('선택') : ''}
          state={workerInputSet}
          setState={setWorkerInputSet}
          stateKey={propertyName}
          codeKey='cdName'
          initiateKey={workerInputSet[propertyName]}
          disabled={isViewMode}
          optionHeight='height-base'
        />
      </div>
    );
  }

  if (wCd === '19') {
    /**
     * cdName : 혈압
     * propertyName : wLowpress, wHighpress
     */
    return (
      <div className='control bloodPressureWrapper'>
        <label htmlFor={wCd}>{t(cdName)}</label>
        <div className='flex-basic'>
          <div className='flex-basic'>
            <label htmlFor='bloodPressureLow'>
              {t('최저혈압')}
              <span>
                {bloodPressureRange.fromLowpress}
                {t('이상')} {bloodPressureRange.toLowpress}
                {t('이하')}
              </span>
            </label>
            <Input id='bloodPressureLow' type='number' name='low' state={bloodPressure} setState={setBloodPressure} maxLength={3} disabled={isViewMode} inputRef={lowestBloodPressureRef} />
          </div>
          <div className='flex-basic'>
            <label htmlFor='bloodPressureHigh'>
              {t('최고혈압')}
              <span>
                {bloodPressureRange.fromHighpress}
                {t('이상')} {bloodPressureRange.toHighpress}
                {t('이하')}
              </span>
            </label>
            <Input id='bloodPressureHigh' type='number' name='high' state={bloodPressure} setState={setBloodPressure} maxLength={3} disabled={isViewMode} inputRef={highestBloodPressureRef} />
          </div>
        </div>
      </div>
    );
  }

  if (wCd === '20') {
    /**
     * cdName : 보험
     * propertyName : wInsurance
     */
    return (
      <div className='control insuranceCheck'>
        <label htmlFor={wCd}>{t(cdName)}</label>
        <div className='flex-basic'>
          {insuranceCheckValue?.map((item: any, index: number) => (
            <div key={`${item.name}_${index}`}>
              <label htmlFor={item.name}>
                <input id={item.name} type='checkbox' checked={item.check} onChange={() => handleCheckboxChange(index, INSURANCE)} disabled={isViewMode} />
                {t(item.name)}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (wCd === '21') {
    /**
     * cdName : 보호구
     * propertyName : wSafetydate, wSafetydevice, wSafetydeviceetc
     */

    // 보호구를 1개이상 선택했을 때 데이트피커 활성화
    const findTrueVale = safetyDeviceCheckValue.find((el: any) => el.check);

    const properties = propertyName.split(',');
    return (
      <div className='control equipments'>
        <label htmlFor={wCd}>{t(cdName)}</label>
        <div className='flex-basic'>
          <div className='datePickerWrapper flex-basic noGrow'>
            <label htmlFor='deviceDate'>{t('지급일자')}</label>
            <DatePicker startDate={workerInputSet[properties[0]]} setDate={setWSafetydate} popperPlacement='bottom' disabled={!findTrueVale} />
          </div>
          <div className='serialCheckbox'>
            <div className={isViewMode ? 'dpNone' : undefined}>
              <label htmlFor='all'>
                <input id='all' type='checkbox' checked={selectAll} onChange={handleSelectAllChange} />
                {t('전체선택')}
              </label>
            </div>
            {safetyDeviceCheckValue.map((item: any, index: number) => (
              <div key={`${item.name}_${index}`}>
                <label htmlFor={item.name}>
                  <input id={item.name} type='checkbox' checked={item.check} onChange={() => handleCheckboxChange(index, SAFETY_DEVICE)} disabled={isViewMode} />
                  {t(item.name)}
                </label>
              </div>
            ))}
          </div>
          {/* <div className='flex-basic wSafetydeviceetc'> */}
          <Input label={t('기타')} type='text' name='wSafetydeviceetc' state={workerInputSet} setState={setWorkerInputSet} disabled={isViewMode} />
          {/* </div> */}
        </div>
      </div>
    );
  }

  if (wCd === '22' || wCd === '29') {
    /**
     * cdName : 교육일자 || 최종 출역일자
     * propertyName : wEdudate || inoutDate
     */
    return (
      <div className='control viewOnly'>
        <label htmlFor={wCd}>{t(cdName)}</label>
        <div>{formatDateYMD(workerInputSet[propertyName])}</div>
      </div>
    );
  }
  if (wCd === '23' || wCd === '30' || wCd === '31' || wCd === '32' || wCd === '33') {
    /**
     * cdName : 안전지적 건수 || 등록일자 || 등록자명 || 수정일자 || 수정자명
     * propertyName : wPenaltycount || wDate || writer || eDate || editor
     */
    if (isNewAdd) return null;
    return (
      <div className='control viewOnly'>
        <label htmlFor={wCd}>{t(cdName)}</label>
        <div>{workerInputSet[propertyName]}</div>
      </div>
    );
  }

  if (wCd === '24') {
    /**
     * cdName : 출입금지 여부
     * propertyName : wBlackyn
     */
    if (isNewAdd) return null;
    return (
      <div className='control viewOnly'>
        <label htmlFor={wCd}>{t(cdName)}</label>
        <div>{workerInputSet[propertyName] === 'N' ? t('해제') : t('적용')}</div>
      </div>
    );
  }

  if (wCd === '27' || wCd === '28') {
    /**
     * cdName : 일반 건강검진 || 특수 건강검진
     * propertyName : wHndate, wHnresult, wHnbigo || wHsdate, wHsresult, wHsbigo
     */
    const properties = propertyName.split(',');

    let nResult = '';
    let sResult = '';
    const nResultObj: any = nHealthCdList?.find((el: any) => el.wHnresult === setWHndate.wHnresult);
    if (nResultObj) nResult = nResultObj.cdName;

    const sResultObj: any = sHealthCdList?.find((el: any) => el.wHsresult === setWHndate.wHsresult);
    if (sResultObj) sResult = sResultObj.cdName;

    return (
      <div className='control healthCheck'>
        <label htmlFor={wCd}>{t(cdName)}</label>
        <div className='flex-basic'>
          <div className='datePickerWrapper'>
            <label htmlFor={wCd}>{t('검진 일자')}</label>
            <DatePicker startDate={workerInputSet[properties[0]]} setDate={wCd === '27' ? setWHndate : setWHsdate} popperPlacement='bottom' disabled={isViewMode} />
          </div>
          <div className='result'>
            <label htmlFor='result'>{t('결과')}</label>
            <SelectBox
              options={wCd === '27' ? nHealthCdList : sHealthCdList}
              defaultOption={wCd === '27' ? nResult || t('선택') : sResult || t('선택')}
              state={wCd === '27' ? wHnresult : wHsresult}
              setState={wCd === '27' ? setWHnresult : setWHsresult}
              stateKey={wCd === '27' ? 'wHnresult' : 'wHsresult'}
              disabled={isViewMode}
              optionHeight='height-base'
              initiateKey={wCd === '27' ? wHnresult.wHnresult : wHsresult.wHsresult}
            />
          </div>
          <Input label={t('비고')} type='text' name={properties[2]} state={workerInputSet} setState={setWorkerInputSet} disabled={isViewMode} />
        </div>
      </div>
    );
  }

  if (wCd === '36') {
    /**
     * cdName : 약복용
     * propertyName : takeMedicine
     */
    return (
      <TakeMedicine
        takeMedicineValue={takeMedicineValue} //
        setTakeMedicineValue={setTakeMedicineValue}
        isViewMode={isViewMode}
        wCd={wCd}
        cdName={cdName}
        workerInputSet={workerInputSet}
        setWorkerInputSet={setWorkerInputSet}
        propertyName={propertyName}
      />
    );
  }

  if (wCd === '37' || wCd === '40') {
    /**
     * cdName : 맥박수 | 혈당
     * propertyName : pulseRate | bloodSugar
     */
    return (
      <ThreeDigitNumberInput
        isViewMode={isViewMode} //
        cdName={cdName}
        workerInputSet={workerInputSet}
        setWorkerInputSet={setWorkerInputSet}
        propertyName={propertyName}
      />
    );
  }

  if (wCd === '38') {
    /**
     * cdName : 직무스트레스
     */
    return (
      <StressTotal
        isViewMode={isViewMode} //
        cdName={cdName}
        workerInputSet={workerInputSet}
        setWorkerInputSet={setWorkerInputSet}
      />
    );
  }

  if (wCd === '39') {
    /**
     * cdName : 성별
     * propertyName : sex
     */
    return (
      <Sex //
        propertyName={propertyName}
        cdName={cdName}
        isViewMode={isViewMode}
        workerInputSet={workerInputSet}
        setWorkerInputSet={setWorkerInputSet}
        isNewAdd={isNewAdd}
      />
    );
  }

  if (wCd === '41') {
    /**
     * cdName : 보건교육
     */
    return (
      <SafeEdu //
        cdName={cdName}
        isViewMode={isViewMode}
        workerInputSet={workerInputSet}
        setWorkerInputSet={setWorkerInputSet}
        isNewAdd={isNewAdd}
      />
    );
  }

  if (wCd === '42' && msiteYn === 'Y') {
    /**
     * cdName : 다중출역현장
     * propertyName : msiteCd
     */
    return (
      <MsiteCd
        isViewMode={isViewMode} //
        wCd={wCd}
        cdName={cdName}
        workerInputSet={msCdValue}
        setWorkerInputSet={setMsCdValue}
        propertyName={propertyName}
        isLoading={isLoading}
        isNewAdd={isNewAdd}
      />
    );
  }

  if (wCd === '42' && msiteYn === 'N') {
    return null;
  }

  return (
    <div className='control'>
      <div className='alsdjaksljd'>
        <label htmlFor={wCd}>{cdName}</label>
        <div>{workerInputSet[propertyName]}</div>
      </div>
    </div>
  );
};

const WorkerAttend = ({ isViewMode, reportArray, workerInputSet, code, auth, currentWorkerWCd, currentWorkerIndex, getReportListAPI, onClickPrev, onClickNext, setOpenModal }: any) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const [workerAttendList, setWorkerAttendList] = useState<any[]>([]);
  const [beforeWorkerAttendList, setBeforeWorkerAttendList] = useState<any[]>([]);
  const [sjCdList, setSjCdList] = useState([]);
  const [orgSjCdList, setOrgSjCdList] = useState([]);
  const [openSubModal, setOpenSubModal] = useState<IModal>({ status: false, type: '' });
  const [visibleRangePicker, setVisibleRangePicker] = useState(false);
  const [searchClick, setSearchClick] = useState(false);
  const [dates, setDates] = useState({ start: '', end: '' });
  const [rangeState, setRangeState] = useState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const { isPgUpPressed, setIsPgUpPressed } = useOnKeydownPgUp();
  const { isPgDnPressed, setIsPgDnPressed } = useOnKeydownPgDn();

  useEffect(() => {
    getSiteJoinList();
  }, [currentWorkerIndex]);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearchWorkerAttendList();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    if (isPgUpPressed) {
      onClickPrev();
      setIsPgUpPressed(false);
    }
  }, [isPgUpPressed]);

  useEffect(() => {
    if (isPgDnPressed) {
      onClickNext();
      setIsPgDnPressed(false);
    }
  }, [isPgDnPressed]);

  useEffect(() => {
    if (reportArray && currentWorkerIndex !== undefined) {
      // 데이트레인지에 출역일자 세팅
      const aDateFormat = dayjs(reportArray[currentWorkerIndex]?.aDate || code.aDate, 'YYYY-MM-DD').toDate();
      setRangeState([{ startDate: aDateFormat, endDate: aDateFormat, key: 'selection' }]);
      setDates({ start: reportArray[currentWorkerIndex]?.aDate || code.aDate, end: reportArray[currentWorkerIndex]?.aDate || code.aDate });
      setSearchClick(true);
      // 근로자 출역내역 조회
      // getWorkerAttendList(reportArray[currentWorkerIndex]?.aDate, reportArray[currentWorkerIndex]?.aDate, reportArray[currentWorkerIndex]?.wCd);
    }
  }, [currentWorkerIndex, reportArray]);

  const getSiteJoinList = async () => {
    const req = { hCd: code.hCd, sCd: code.sCd };
    const res = await apiGet({ path: '/siteJoin', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      if (data.siteJoinList?.length > 0) {
        const useYList = data.siteJoinList.filter((el: any) => el.useYn === 'Y').map((el: any) => ({ type: 'sjCd', sjCd: el.sjCd, cdName: el.sjName }));
        setSjCdList(useYList);
        setOrgSjCdList(data.siteJoinList.map((siteJoin: any) => ({ ...siteJoin, type: 'sjCd' })));
      }
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  const fetchAttendData = async () => {
    try {
      const res = await apiGet({
        path: '/worker/attend',
        req: {
          hCd: code.hCd,
          sCd: code.sCd,
          wCd: currentWorkerWCd,
          aDate1: dates.start?.replaceAll('-', '') || '',
          aDate2: dates.end?.replaceAll('-', '') || '',
        },
      });
      const { workerAttend } = res.data.data;
      // min, hour, sec 값을 잘라서 객체안에 추가
      const newArray = workerAttend.map((el: any, i: number) => ({
        ...el,
        aDate: el.aDate.substring(0, 8),
        index: i,
        hour: el.aDate.substring(8, 10),
        min: el.aDate.substring(10, 12),
        sec: el.aDate.substring(12, 14),
        aDateF: el.aDate.substring(0, 8),
        flag: FLAG_NONE,
      }));
      setWorkerAttendList(newArray);
      setBeforeWorkerAttendList(newArray);
      setSearchClick(false);
      return newArray;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  const attendListQuery = useQuery(['attendInfo'], () => fetchAttendData(), {
    enabled: !!currentWorkerWCd && !!userInfo.hCd && !!userInfo.sCd && searchClick,
  });

  const onClickSearchWorkerAttendList = () => {
    setVisibleRangePicker(false);
    setSearchClick(true);
    setDates({ start: formatDateToStrBar(rangeState[0].startDate), end: formatDateToStrBar(rangeState[0].endDate) });
    // getWorkerAttendList(dates.start, dates.end);
  };

  const onClickAddWorkerAttendRow = () => {
    const data = {
      index: workerAttendList?.length,
      hCd: code.hCd,
      sCd: code.sCd,
      wCd: currentWorkerWCd,
      aDate: '',
      hour: '00',
      min: '00',
      sec: '00',
      sjCd: workerInputSet.sjCd,
      sjName: workerInputSet.sjName,
      aDateF: todayYYYYMMDD(),
      wPrejobtype: workerInputSet.wPrejobtype,
      wJobtype: workerInputSet.wJobtype,
      fLocation: '',
      fLocationName: '',
      fNum: '',
      fName: '',
      fInout: '',
      flag: FLAG_CREATE_OR_UPDATE,
    };
    setWorkerAttendList((prev: any) => [...prev, data]);
    setBeforeWorkerAttendList((prev: any) => [...prev, data]);
  };

  const onClickSaveWorkerAttendRow = () => {
    setIsSaveClicked(true);
    const required = ['hour', 'min', 'sec', 'sjCd'];

    const emptyCheck = workerAttendList.filter((el: any) => {
      const check = required.find((el2: any) => el[el2] === '' && el.flag === FLAG_CREATE_OR_UPDATE);
      return check;
    });

    if (emptyCheck?.length > 0) {
      toast.warning(t('필수입력값을 모두 입력하세요'));
    } else {
      // 빈값이 없는 경우 수정,업데이트된 로우들만 서버에 전달
      const dbSendRows = workerAttendList.filter((el: any) => el.flag === FLAG_CREATE_OR_UPDATE);

      if (dbSendRows?.length > 0) {
        const addADate = dbSendRows.map((el) => {
          if (el.aDateF?.length === 8 && isValidYYYYMMDD(el.aDateF)) {
            const date = dayjs(el.aDateF).format('YYYYMMDD');
            return {
              ...el,
              hCd: code.hCd,
              sCd: code.sCd,
              wCd: currentWorkerWCd,
              aDate: `${date}${el.hour}${el.min}${el.sec}`,
              aDate1: dates.start,
              aDate2: dates.end,
              wPrejobtype: workerInputSet.wPrejobtype,
              wJobtype: workerInputSet.wJobtype,
            };
          }
          return {
            ...el,
            hCd: code.hCd,
            sCd: code.sCd,
            wCd: currentWorkerWCd,
            aDate: '',
            aDate1: dates.start,
            aDate2: dates.end,
            wPrejobtype: workerInputSet.wPrejobtype,
            wJobtype: workerInputSet.wJobtype,
          };
        });
        const checkAdate = addADate.find((el: any) => el.aDate === '');
        if (!checkAdate) {
          const newArray = addADate.map(({ hour, min, sec, aDateF, fName, ...rest }) => rest);
          // 서버 dto 순서에 맞추지 않으면 에러나므로 순서대로 정렬
          // attendIdx가 없는경우(신규추가인경우) 0
          const sortArray = newArray.map(({ attendIdx, hCd, sCd, wCd, aDate, sjCd, wPrejobtype, wJobtype, flag, fNum, fLocation, fInout }) => {
            return { attendIdx: attendIdx || 0, hCd, sCd, wCd, aDate, sjCd, wPrejobtype, wJobtype, flag, fNum, fLocation, fInout };
          });
          saveWorkerAttendListAPI(sortArray);
        } else toast.warning(t('출역일자를 확인하세요'));
      } else toast.warning(t('변경된 내용이 없습니다'));
    }
  };

  const saveWorkerAttendListAPI = async (array: any[]) => {
    const req = { workerAttendReqDto: array };
    const res = await apiPost({ path: '/worker/attend', req });
    const { statusCode, data, message } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      /**
       * 수정자 : 홍선영
       * 날짜 : 2023.10.16
       * 수정내용 : 출역내역 저장,삭제시 출역일자(레인지피커) 및 출역내역결과값이 오늘날짜로 리셋되지 않게 하기위해 아래 주석처리함
       */
      // getReportListAPI();
      onClickSearchWorkerAttendList();
      setOpenModal((prev: any) => ({ ...prev, status: false }));
      getReportListAPI();
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '근로자 출역내역(팝업)',
        action: '저장',
        etc: `신규 ${array.filter((v: any) => v.attendIdx === 0)?.length}건, 수정 ${array.filter((v: any) => v.attendIdx !== 0)?.length}건`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  const deleteAPI = async (req: any, i: number, aDate: string) => {
    if (req.attendIdx !== 0) {
      const res = await apiDelete({ path: '/worker/attend', req });
      const { statusCode, message } = res.data;
      if (statusCode === 200) {
        toast.success(t(message));
        setOpenModal((prev: any) => ({ ...prev, status: false }));
        getReportListAPI();
        const copyArray = [...workerAttendList];
        const newArray = copyArray.filter((v: any, index: number) => index !== i);
        setWorkerAttendList(newArray);
        setBeforeWorkerAttendList(newArray);
        /**
         * 수정자 : 홍선영
         * 날짜 : 2023.10.16
         * 수정내용 : 출역내역 저장,삭제시 출역일자(레인지피커) 및 출역내역결과값이 오늘날짜로 리셋되지 않게 하기위해 아래 주석처리함
         */
        // getReportListAPI();
        await logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '근로자 출역내역(팝업)',
          action: '삭제',
          etc: `${workerInputSet.wName}(${req.wCd}) ${formatDate(aDate)}`,
        });
      } else {
        // toast.error(t(ERROR));
      }
    }
  };

  const onClickDeleteWorkerAttendRow = (el: any, i: number) => {
    const tableArray = [...workerAttendList];
    if (el.attendIdx !== undefined) {
      const { attendIdx } = el;
      // 기존 로우를 삭제 할 때
      const data = { attendIdx, hCd: code.hCd, sCd: code.sCd, wCd: currentWorkerWCd };
      setOpenSubModal((prev: any) => ({ ...prev, status: true, type: 'delete', tableArray, setWorkerAttendList, api: () => deleteAPI(data, i, el.aDate), el: data, index: i }));
    } else {
      // 새로 추가한 로우를 삭제할 때
      tableArray.splice(i, 1);
      setWorkerAttendList(tableArray);
      setBeforeWorkerAttendList(tableArray);
    }
  };

  const handleDateChange = (index: number, date: string) => {
    const newData = [...workerAttendList];
    newData[index].aDateF = date;
    newData[index].flag = FLAG_CREATE_OR_UPDATE;
    setWorkerAttendList(newData);
    setBeforeWorkerAttendList(newData);
  };

  const onClickRangeInput = () => {
    if (!visibleRangePicker) setVisibleRangePicker(true);
  };

  const {
    data: faceSetData,
    isLoading: isLoadingFaceSetData,
    isFetching: isFetchingFaceSetData,
  } = useQuery(['faseSetGet', userInfo.hCd, userInfo.sCd], () => fetchFacesetData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
  });

  const [faceSetList, setFaceSetList] = useState(faceSetData || []);

  const fetchFacesetData = async () => {
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
      const res = await apiGet({ path: '/setting/faceSet', req });
      const { data } = res.data;
      const newArray: any = data.faceSet.map((el: any, i: number) => {
        return { type: 'fNum', fNum: el.fNum, name: el.fName, cdSort: i + 1, fLocation: el.fLocation, fLocationName: el.fLocationName, fInout: el.fInout };
      });
      const sortedArray: any = arraySortByAscdOrder(newArray, 'cdSort');
      setFaceSetList(sortedArray);
      return sortedArray;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  // const getFaceSetAPI = async () => {
  //   const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
  //   const res = await apiGet({ path: '/setting/faceSet', req });
  //   const { data, statusCode, message } = res.data;
  //   if (statusCode === 200) {
  //     const newArray: any = data.faceSet.map((el: any, i: number) => {
  //       return { type: 'fNum', fNum: el.fNum, name: el.fName, cdSort: i + 1, fLocation: el.fLocation, fLocationName: el.fLocationName, fInout: el.fInout };
  //     });
  //     const sortedArray: any = arraySortByAscdOrder(newArray, 'cdSort');
  //     setFaceSetList(sortedArray);
  //   } else {
  //     // toast.error(t(ERROR));
  //   }
  // };

  useEffect(() => {
    if (beforeWorkerAttendList && beforeWorkerAttendList.length > 0 && faceSetList && faceSetList.length > 0) {
      const arr = beforeWorkerAttendList.map((v: any, i: number) => {
        faceSetList.map((v2: any) => {
          if (v.fNum === v2.fNum) {
            v.index = i;
            v.fName = v2.name;
            v.fInout = v2.fInout;
            v.fLocation = v2.fLocation;
            v.fLocationName = v2.fLocationName;
            v.flag = 'Y';
          }
        });
        return v;
      });
      setWorkerAttendList(arr);
    }
  }, [beforeWorkerAttendList, faceSetList]);

  const isLoadingAttendList = attendListQuery.isLoading || attendListQuery.isFetching || isLoadingFaceSetData || isFetchingFaceSetData;

  return (
    <div className='inputForm-body attendList'>
      {!isLoadingAttendList ? (
        <InputTable>
          <div className='modalRibbon'>
            <div>
              <span className='namespaceEllipsis'>
                {t('근로자명')}
                <span>{workerInputSet?.wName}</span>
              </span>
              <span>
                {t('근로자 코드')}
                <span>{currentWorkerWCd}</span>
              </span>
              {/* {workerInputSet?.wName} ({currentWorkerWCd}) {t("출역일자")} */}
            </div>
            <div className='inputCalendar'>
              <span>{t('출역일자')}</span>
              <input
                id='modalStartInput'
                type='text'
                value={formatDateToStrBar(rangeState[0].startDate)}
                onMouseDown={onClickRangeInput}
                onChange={(e) => onChangeRangeInput(e, 'start', setRangeState, setDates)}
              />
              <span>-</span>
              <input
                id='modalEndInput'
                type='text'
                value={formatDateToStrBar(rangeState[0].endDate)}
                onMouseDown={onClickRangeInput}
                onChange={(e) => onChangeRangeInput(e, 'end', setRangeState, setDates)}
              />
              {/* <BtnBlue onClick={onClickSearchWorkerAttendList}>{t("검색")}</BtnBlue> */}
              <BtnGhost onClick={onClickSearchWorkerAttendList} className='searchBtn'>
                {t('검색')}
                <span className='shortcut-f9'>F9</span>
              </BtnGhost>
            </div>
          </div>
          {visibleRangePicker && (
            <div className='rangePickerWrapper'>
              <RangePicker state={rangeState} setState={setRangeState} setVisible={setVisibleRangePicker} />
            </div>
          )}
          <div className='thead' style={{ padding: '0 0.5rem' }}>
            <div className='tr'>
              <div className='trCol2p5 flex-center tableStickyNo'>{t('순번')}</div>
              <div className='trCol11 flex-center required'>{t('협력업체')}</div>
              <div className='trCol8 flex-center'>{t('장비명')}</div>
              <div className='trCol6 flex-center content-overflow'>{t('출입위치')}</div>
              <div className='trCol4 flex-center content-overflow'>{t('출입구분')}</div>
              <div className='trCol8 flex-center required'>{t('출역일자')}</div>
              <div className='trCol17p5 flex-center'>{t('출역시간')}</div>
              {auth.deleteAuth && <div className='trCol4'> </div>}
            </div>
          </div>
          <div className='table' style={{ padding: '0 0.5rem' }}>
            <div className='tbody'>
              {workerAttendList.map((el: any, i: number) => (
                <div className='tr' key={`attend_tbody_${el.index}_${i}`}>
                  <div className='trCol2p5 flex-center tableStickyNo'>{i + 1}</div>
                  <div className='trCol11 flex-center'>
                    <SearchSelectBoxs
                      options={sjCdList}
                      defaultOption={el?.sjName || t('선택')}
                      state={workerAttendList}
                      setState={setWorkerAttendList}
                      setBeforeState={setBeforeWorkerAttendList}
                      stateKey='sjCd'
                      codeKey='cdName'
                      index={i}
                      useFlag
                      getBorderStyle={isSaveClicked ? applyBorderStyle(el.sjCd, 'red', 'sjCd') : undefined}
                      disabled={isViewMode}
                      optionHeight='height-sm'
                    />
                  </div>
                  {/* <div className='trCol6 flex-center tableStickyTitle'>{workerInputSet.wName}</div> */}
                  <div className='trCol8 flex-center'>
                    <SearchSelectBoxs
                      options={faceSetList}
                      defaultOption={el.fName || t('선택')}
                      state={beforeWorkerAttendList}
                      setState={setBeforeWorkerAttendList}
                      stateKey='fNum'
                      codeKey='name'
                      index={i}
                      useFlag
                      disabled={isViewMode}
                      optionHeight='height-md'
                    />
                  </div>
                  <div className='trCol6 flex-center content-overflow'>{el.fLocationName}</div>
                  <div className='trCol4 flex-center content-overflow'>{el.fInout !== '' && (el.fInout === 'I' ? t('출근') : t('퇴근'))}</div>
                  <div className='trCol8 flex-center datePickerWrapper'>
                    <DatePickerInTable
                      state={workerAttendList}
                      setState={setWorkerAttendList}
                      stateKey='aDateF'
                      onChangeState={handleDateChange}
                      index={i}
                      popperPlacement='bottom'
                      isSaveClicked={isSaveClicked}
                      disabled={isViewMode}
                    />
                  </div>
                  <div className='trCol17p5 flex-center'>
                    <div className='flex-basic reactSelectWrapper'>
                      <ReactSelect
                        options={hourList2}
                        state={workerAttendList}
                        setState={setWorkerAttendList}
                        setBeforeState={setBeforeWorkerAttendList}
                        value={{ label: el.hour, value: el.hour }}
                        defaultValue={{ label: el.hour, value: el.hour }}
                        stateKey='hour'
                        index={i}
                        isClearable={false}
                        isSearchable={false}
                        optionsPlacement='auto'
                        isDisabled={isViewMode}
                      />
                      <span style={{ padding: '0 .5rem', width: 'fit-content' }}>{t('시')}</span>
                    </div>
                    <div className='flex-basic reactSelectWrapper'>
                      <ReactSelect
                        options={minList2}
                        state={workerAttendList}
                        setState={setWorkerAttendList}
                        setBeforeState={setBeforeWorkerAttendList}
                        value={{ label: el.min, value: el.min }}
                        defaultValue={{ label: el.min, value: el.min }}
                        stateKey='min'
                        index={i}
                        isClearable={false}
                        isSearchable={false}
                        optionsPlacement='auto'
                        isDisabled={isViewMode}
                      />
                      <span style={{ padding: '0 .5rem', width: 'fit-content' }}>{t('분')}</span>
                    </div>
                    <div className='flex-basic reactSelectWrapper'>
                      <ReactSelect
                        options={minList2}
                        state={workerAttendList}
                        setState={setWorkerAttendList}
                        setBeforeState={setBeforeWorkerAttendList}
                        value={{ label: el.sec, value: el.sec }}
                        defaultValue={{ label: el.sec, value: el.sec }}
                        stateKey='sec'
                        index={i}
                        isClearable={false}
                        isSearchable={false}
                        optionsPlacement='auto'
                        isDisabled={isViewMode}
                      />
                      <span style={{ paddingLeft: '0.5rem', width: 'fit-content' }}>{t('초')}</span>
                    </div>
                  </div>
                  {auth.deleteAuth && (
                    <div className='trCol4 flex-center'>
                      <BtnRed onClick={() => onClickDeleteWorkerAttendRow(el, i)}>{el.attendIdx === undefined ? t('제거') : t('삭제')}</BtnRed>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </InputTable>
      ) : (
        <div className='centered-content'>
          <PulseLoader color='rgb(0, 122, 255)' size='10px' />
        </div>
      )}
      <div className='modal-footer flex-between'>
        <div className='flex-basic arrows'>
          <BtnBlue onClick={onClickPrev}>
            <span className='material-symbols-rounded'>arrow_back_ios_new</span>
            <p>{t('이전')}</p>
            <div>
              <p>Page</p>
              <p>Up</p>
            </div>
          </BtnBlue>
          <BtnBlue onClick={onClickNext}>
            <div>
              <p>Page</p>
              <p>Down</p>
            </div>
            <p>{t('다음')}</p>
            <span className='material-symbols-rounded'>arrow_forward_ios</span>
          </BtnBlue>
        </div>
        <div className='flex-basic'>
          {auth.createAuth && <BtnBlue onClick={onClickAddWorkerAttendRow}>{t('신규항목 추가')}</BtnBlue>}
          {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={onClickSaveWorkerAttendRow}>{t('저장')}</BtnBlue>}
        </div>
      </div>
      <Portal openModal={openSubModal?.status}>{openSubModal.status && <DeleteModal openModal={openSubModal} setOpenModal={setOpenSubModal} />}</Portal>
    </div>
  );
};

const WorkerEducation = ({ workerInputSet, code, auth, onClickClose, currentWorkerWCd }: any) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '' });
  const [workerEduList, setWorkerEduList] = useState<any[]>([]);

  useEffect(() => {
    getWorkerEduList();
  }, []);

  const getWorkerEduList = async () => {
    const req = { hCd: code.hCd, sCd: code.sCd, wCd: currentWorkerWCd };
    const res = await apiGet({ path: '/worker/edu', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      setWorkerEduList(data.workerEdu);
    } else {
      // toast.error(t(ERROR));
    }
  };

  const deleteAPI = async (req: any, i: number, sDate: string) => {
    const res = await apiDelete({ path: '/worker/edu', req });
    const { statusCode, message } = res.data;

    if (statusCode === 200) {
      toast.success(t(message));
      setOpenModal((prev) => ({ ...prev, status: false }));
      const copyArray = [...workerEduList];
      const newArray = copyArray.filter((v: any, index: number) => index !== i);
      setWorkerEduList(newArray);
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '근로자 교육내역(팝업)',
        action: '삭제',
        etc: `${workerInputSet?.wName}(${currentWorkerWCd}) ${formatDateYMD(sDate)}`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  const onClickDeleteWorkerEduRow = (el: any, i: number) => {
    const tableArray = [...workerEduList];
    const { sSeq, sEduseq } = el;
    const data = { hCd: code.hCd, sCd: code.sCd, wCd: currentWorkerWCd, sSeq, sEduseq, editor: userInfo.userId };
    setOpenModal((prev: any) => ({ ...prev, status: true, type: 'delete', tableArray, setWorkerEduList, api: () => deleteAPI(data, i, el.sDate), el: data, index: i }));
  };
  return (
    <div className='inputForm-body attendList'>
      <InputTable>
        <div className='modalRibbon'>
          <div>
            {workerInputSet && (
              <span>
                {t('근로자명')}
                <span>{workerInputSet?.wName}</span>
              </span>
            )}
            <span>
              {t('근로자 코드')}
              <span>{currentWorkerWCd}</span>
            </span>
            {/* {workerInputSet?.wName} ({currentWorkerWCd}) {t("출역일자")} */}
          </div>
        </div>
        <div className='thead eduList' style={{ padding: '0 0.5rem' }}>
          <div className='tr'>
            <div className='trCol2p5 flex-center tableStickyNo'>{t('순번')}</div>
            <div className='trCol8 flex-center'>{t('교육 일자')}</div>
            <div className='trCol12 flex-center tableStickyTitle content-overflow'>{t('교육 구분')}</div>
            <div className='trCol8 flex-center'>{t('교육 장소')}</div>
            <div className='trCol8 flex-center'>{t('교육 시간')}</div>
            {/* <div className={auth.deleteAuth ? 'trCol40' : 'trCol50'}>{t("교육 시간")}</div> */}
            {auth.deleteAuth && <div className='trCol4'> </div>}
          </div>
        </div>
        <div className='table' style={{ padding: '0 0.5rem' }}>
          <div className='tbody eduList'>
            {workerEduList.map((el: any, i: number) => (
              <div className='tr' key={`edu_tbody_${i}`}>
                <div className='trCol2p5 flex-center tableStickyNo text_tertiary'>{i + 1}</div>
                <div className='trCol8 flex-center text_secondary'>{formatDateYMD(el.sDate)}</div>
                <div className='trCol12 flex-center tableStickyTitle content-overflow text_tertiary'>{el.sCode}</div>
                <div className='trCol8 flex-center text_tertiary'>{el.sPlace}</div>
                <div className='trCol8 flex-center text_tertiary'>
                  {/* <div className={auth.deleteAuth ? 'trCol40' : 'trCol50'}> */}
                  {`${el.sStime?.slice(0, 2)}:${el.sStime?.slice(2)}`} - {`${el.sEtime?.slice(0, 2)}:${el.sEtime?.slice(2)}`}
                </div>
                {auth.deleteAuth && (
                  <div className='trCol4 flex-center'>
                    <BtnRed onClick={() => onClickDeleteWorkerEduRow(el, i)}>{t('삭제')}</BtnRed>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        {/* </div> */}
      </InputTable>
      {/* </div> */}
      <Portal openModal={openModal?.status}>{openModal.status && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
    </div>
  );
};
export default InfoWorkerModal;
