/**
 * 작성자 : 김광민
 * 날짜 : 2024.04.01
 * 기능 : PTZ 속도와 화면분할, 캡쳐파일 저장 위치, 동영상 파일 저장 위치를 설정하는 컴포넌트
 */

import styled from 'styled-components';
import { IModal } from 'customTypes';
import { Dispatch, SetStateAction, useState } from 'react';
import { WebSDKcctv } from '../../utils/webSDKcctvClass';
import SelectOptionsButtons from './CctvSettings/SelectOptionsButtons';
import SetDownloadPath from './CctvSettings/SetDownloadPath';
import { initPath } from '../../services/webSDK';
import { IsInfraType } from '../../pages/s_cctv/S_cctv1/RealtimeCCTV/IsInfraType';
import { apiPatch } from '../../services/_common';
import { useRecoilValue, useRecoilState } from 'recoil';
import { localCctvSettingState, userState } from '../../atoms';

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5010;
  width: 100%;
  height: 100%;
  height: 100vh;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 60%);
  backdrop-filter: blur(0.5rem);
  transition: all ease 0.8s;
  display: flex;
  justify-content: center;
  align-items: center;
  > .cctvSettingsModal {
    position: relative;
    z-index: 6000;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 2rem);
    max-height: calc(100dvh - 2rem);
    width: 32rem;
    background-color: ${({ theme }: { theme: any }) => theme.board};
    border-radius: 0.5rem;
    overflow: hidden;
    user-select: none;
    @media screen and (max-width: 1023px) {
      width: calc(100vw - 2rem);
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      height: 100vh;
      height: inherit;
      overflow-y: auto;
      border-radius: 0;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      padding: 0.5rem;
      > .titleName {
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0 0.5rem;
      }
      > .closeButton {
        border-radius: 0.5rem;
        margin: 0.25rem;
        padding: 0.25rem;
        display: flex;
        cursor: pointer;
        color: ${({ theme }: { theme: any }) => theme.text_disabled};
        &:hover {
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
          background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
          box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.2);
        }
      }
    }
    .body {
      flex-grow: 1;
      padding: 1rem;
      padding-bottom: 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
    .footer {
      display: flex;
      justify-content: flex-end;
      background-color: ${({ theme }: { theme: any }) => theme.board};
      border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      padding: 0.5rem;
      > .button {
        display: flex;
        align-items: center;
        height: 2.5rem;
        min-width: 5.5rem;
        justify-content: center;
        border-radius: 0.25rem;
        font-size: 0.875rem;
        letter-spacing: normal;
        background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        padding: 0 0.5rem;
        cursor: pointer;
        background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
        color: ${({ theme }: { theme: any }) => theme.text_reverse};
        &:active {
          background-color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
        }
        &.disabled {
          pointer-events: none;
          color: ${({ theme }: { theme: any }) => theme.text_disabled};
          background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
        }
      }
    }
  }
  > .confirmModal {
    position: relative;
    z-index: 6000;
    display: flex;
    flex-direction: column;
    height: 10rem;
    max-height: calc(100vh - 2rem);
    max-height: calc(100dvh - 2rem);
    width: 24rem;
    background-color: ${({ theme }: { theme: any }) => theme.board};
    border-radius: 0.5rem;
    overflow: hidden;
    user-select: none;
    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 6rem;
      flex-shrink: 0;
      border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      .main {
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      }
      .sub {
        font-weight: 600;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
      }
    }
    .buttons {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5em;
      padding: 0.5rem 0.75rem;
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3rem;
        height: 100%;
        width: calc(50% - 0.25rem);
        border-radius: 0.25rem;
        gap: 0.5rem;
        cursor: pointer;
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        &:hover {
          background-color: ${({ theme }: { theme: any }) => theme.board};
          color: ${({ theme }: { theme: any }) => theme.text_secondary};
          box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.05);
          &:active {
            background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
            color: ${({ theme }: { theme: any }) => theme.text_secondary};
            box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
`;

type Props = {
  setOpenModal: Dispatch<SetStateAction<{ status: boolean; type: string }>>; //
  cctvOBJ: WebSDKcctv;
  cctvSettings: CctvSettings;
  setSliderValue: Dispatch<SetStateAction<number>>;
};

const CctvSettings = ({ setOpenModal, cctvOBJ, cctvSettings, setSliderValue }: Props) => {
  const userInfo = useRecoilValue(userState);
  const { userId } = userInfo;
  const [localCctvSettings, setLocalCctvSettings] = useRecoilState(localCctvSettingState);
  const initialFontLocation = localCctvSettings.camCLocation || cctvSettings.camCLocation;
  const [fontLocation, setFontLocation] = useState(initialFontLocation);
  const isInfraType = IsInfraType(); // 인프라용의 경우 다운로드 폴더 미노출
  // 폰트 위치 Options 생성
  const fontLocationOptions = [
    { key: '0', name: '미사용' },
    { key: '1', name: '왼쪽 상단' },
    { key: '2', name: '왼쪽 하단' },
    { key: '3', name: '오른쪽 상단' },
    { key: '4', name: '오른쪽 하단' },
  ];
  const initialFontColor = localCctvSettings.camFColor || cctvSettings.camFColor;
  const [fontColor, setFontColor] = useState(initialFontColor);
  // 폰트 색상 Options 생성
  const fontColorOptions = [
    { key: '255/255/13', icon: 'format_color_text', val: '255/255/13', color: '#FFFF0D' },
    { key: '0/176/80', icon: 'format_color_text', val: '0/176/80', color: '#00B050' },
    { key: '255/102/0', icon: 'format_color_text', val: '255/102/0', color: '#FF6600' },
    { key: '191/191/191', icon: 'format_color_text', val: '191/191/191', color: '#BFBFBF' },
    { key: '0/112/191', icon: 'format_color_text', val: '0/112/191', color: '#0070BF' },
    { key: '255/0/0', icon: 'format_color_text', val: '255/0/0', color: '#FF0000' },
    { key: '255/255/255', icon: 'format_color_text', val: '255/255/255', color: '#FFFFFF' },
  ];

  // PTZ 속도
  const initialPtzSpeed = localCctvSettings.camSpeed || cctvSettings.camSpeed;
  const [ptzSpeed, setPtzSpeed] = useState(initialPtzSpeed);
  // PTZ 속도 Options 생성
  const ptzSpeedOptions = Array.from({ length: 7 }, (_, index) => {
    const indexKey = index + 1;
    const key = indexKey.toString();
    return {
      key,
      name: key,
    };
  });

  // 화면 분할
  const initialGridView = localCctvSettings.camCType || cctvSettings.camCType;
  const [gridView, setGridView] = useState(initialGridView);
  // 화면 분할 Options 생성
  const gridViewOptions = Array.from({ length: 4 }, (_, index) => {
    const indexKey: number = index + 1;
    const key: string = indexKey.toString();
    const name = (indexKey * indexKey).toString();
    return {
      key,
      name,
    };
  });

  // 저장 버튼 클릭 후 확인 모달 유무
  const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false);

  // 캡쳐 파일 저장 위치
  const [captureDownloadPath, setCaptureDownloadPath] = useState<string | null>(initPath(localCctvSettings.camCPath || cctvSettings.camCPath));
  // 동영상 파일 저장 위치
  const [videoDownloadPath, setVideoDownloadPath] = useState<string | null>(initPath(localCctvSettings.camMPath || cctvSettings.camMPath));

  // 모달 닫기
  const onClickClose = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };

  // 다운로드 경로 JSON 문자열 변환 함수
  const setJsonPath = (path: string | null) => {
    if (path === 'undefined' || path === undefined || path === null || path === '') {
      return '';
    }
    return JSON.stringify(path);
  };

  const getCamListValue = (camList: any) => {
    if (!camList || camList === 'undefined') return '';
    return typeof camList === 'string' ? camList : JSON.stringify(camList);
  };

  const onClickSave = async () => {
    const captureDownload = setJsonPath(captureDownloadPath);
    const videoDownload = setJsonPath(videoDownloadPath);

    const req = {
      ...cctvSettings,
      // 화면 분할 1*1(1) | 2*2(4) | 3*3(9) | 4*4(16)
      camCType: gridView.toString() as '1' | '2' | '3' | '4',
      // 카메라명 폰트 위치
      camCLocation: fontLocation,
      // 카메라명 폰트 색상
      camFColor: fontColor,
      // PTZ 카메라 이동 속도
      camSpeed: ptzSpeed.toString() as '1' | '2' | '3' | '4' | '5' | '6' | '7',
      // 캡쳐 파일 저장 경로
      camCPath: captureDownload,
      // 동영상 파일 저장 경로
      camMPath: videoDownload,
      camList1: '',
      camList2: '',
      camList3: '',
      camList4: '',
      camList5: '',
      userId,
    };

    await apiPatch({ path: '/user/cctv', req }).then(() => {
      setLocalCctvSettings(req);
      if (gridView) {
        cctvOBJ.changeWndNum(Number(gridView));
      }
      if (ptzSpeed) {
        setSliderValue(Number(ptzSpeed));
      }
      if (fontColor || fontLocation) {
        cctvOBJ?.setSnapPolygon({ camFColor: fontColor, camCLocation: fontLocation });
      }
      if (captureDownloadPath || videoDownloadPath) {
        // webSDK 다운로드 경로 저장
        cctvOBJ.setLocalCfg({
          capturePath: captureDownloadPath, // 캡쳐 파일 저장 경로
          videoPath: videoDownloadPath, // 동영상 파일 저장 경로
        });
      }
    });
    onClickClose();
  };

  // 변경사항 유무에 따라 저장 버튼 활성/비활성
  const isDisableSave = () => {
    const isFontLocation = (localCctvSettings.camCLocation || cctvSettings.camCLocation) === fontLocation;
    const isFontColor = (localCctvSettings.camFColor || cctvSettings.camFColor) === fontColor;
    const isPtzSpeed = (localCctvSettings.camSpeed || cctvSettings.camSpeed) === ptzSpeed;
    const isGridView = (localCctvSettings.camCType || cctvSettings.camCType) === gridView;
    const isCaptureDownloadPath = initPath(localCctvSettings.camCPath || cctvSettings.camCPath) === captureDownloadPath;
    const isVideoDownloadPath = initPath(localCctvSettings.camMPath || cctvSettings.camMPath) === videoDownloadPath;
    return isFontLocation && isFontColor && isPtzSpeed && isGridView && isCaptureDownloadPath && isVideoDownloadPath;
  };

  return (
    <Root onClick={onClickClose} role='presentation'>
      <div
        className='cctvSettingsModal'
        role='presentation'
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className='header'>
          <div className='titleName'>CCTV 환경설정</div>
          <span
            className='material-symbols-rounded closeButton' //
            onClick={onClickClose}
            role='presentation'
          >
            close
          </span>
        </div>
        <div className='body'>
          <SelectOptionsButtons
            label='카메라 PTZ 속도'
            state={ptzSpeed} //
            setState={setPtzSpeed}
            options={ptzSpeedOptions}
            icon='speed'
          />
          <SelectOptionsButtons
            label='화면 분할'
            state={gridView} //
            setState={setGridView}
            options={gridViewOptions}
            icon='grid_view'
          />
          {!isInfraType && (
            <>
              <SetDownloadPath
                label='캡쳐 다운로드 폴더 경로'
                icon='photo_camera' //
                onClick={() => cctvOBJ.downloadPathSetting(setCaptureDownloadPath)}
                path={captureDownloadPath}
              />
              <SetDownloadPath
                label='동영상 다운로드 폴더 경로'
                icon='videocam' //
                onClick={() => cctvOBJ.downloadPathSetting(setVideoDownloadPath)}
                path={videoDownloadPath}
              />
            </>
          )}
          <SelectOptionsButtons
            label='카메라명 폰트 위치'
            state={fontLocation} //
            setState={setFontLocation}
            options={fontLocationOptions}
            icon='my_location'
          />
          <SelectOptionsButtons
            label='카메라명 폰트 색상'
            state={fontColor} //
            setState={setFontColor}
            options={fontColorOptions}
            icon='palette'
          />
        </div>
        <div className='footer'>
          <div
            className={`button ${isDisableSave() ? 'disabled' : ''}`} //
            onClick={onClickSave}
            role='presentation'
          >
            저장
          </div>
        </div>
      </div>
      {/* {!isShowConfirmModal ? (
      ) : (
        <div
          className='confirmModal'
          role='presentation'
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className='title'>
            <div className='main'>CCTV 환결설정이 성공적으로 저장되었습니다.</div>
            <div className='sub'>계속해서 수정하시겠습니까?</div>
          </div>
          <div className='buttons'>
            <span
              className='button button_cancel' //
              role='presentation'
              onClick={onClickClose}
            >
              아니요
            </span>
            <span
              className='button button_confirm' //
              role='presentation'
              onClick={() => setIsShowConfirmModal(false)}
            >
              예
            </span>
          </div>
        </div>
      )} */}
    </Root>
  );
};

export default CctvSettings;
