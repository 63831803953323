import { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import Cookies from 'js-cookie';

import { ERROR } from '../../_constants';
import { IModal, ILogin } from 'customTypes';
import { IUser, userState } from '../../atoms';
import { ModalBackground, Modal } from '../../assets/styles/Modal';
import { BtnBlue, BtnWhite } from '../Button';
import Input from '../Input';
import { formatDate } from '../../utils/formatDate';
import { isPasswordRegExpPass } from '../../utils/regExp';
import { usePasswordValidationCheck } from '../../utils/usePasswordValidationCheck';
import { logPost } from '../../services/log';
import { registerPwd, registerPwdchangeDate } from '../../services/register';

interface IPasswordState {
  password: string;
  passwordConfirm: string;
}

const PasswordChangeTermModal = ({ openModal, setOpenModal, state, setState, resetState }: ILogin) => {
  const { t } = useTranslation();
  const setUserInfo = useSetRecoilState<IUser>(userState);
  const focusRef = useRef<HTMLInputElement>(null);
  const [pwAlert, setPwAlert] = useState(false);
  const [newPassword, setNewPassword] = useState<IPasswordState>({
    password: '',
    passwordConfirm: '',
  });
  const limitMonth = state && Math.floor(state.pwdLimitTerm / 30);
  const yyyymmdd = formatDate(state?.userInfo.pwdchangeDate);

  const { isPasswordPassed, passwordAlertMessage } = usePasswordValidationCheck(newPassword.password, newPassword.passwordConfirm);

  // 모달창닫기
  const onClickClose = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };

  // 패스워드 input에서 포커스 아웃되었을 때 비밀번호정규식 확인문구 노출
  const onBlur = () => {
    if (!isPasswordRegExpPass(newPassword?.password)) setPwAlert(true);
    else setPwAlert(false);
  };

  // 새 비밀번호 DB insert API 호출
  const saveNewPasswordAPI = async () => {
    const res = await registerPwd(state?.id, newPassword?.password, state.accessToken);
    const { statusCode, message } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      const { userId, userName, gCd, sessionTimeout, hCd, hName, sCd, sName, pwdchangeDate, prejobtypeYn, eduPlace, eduCharge, eduStartTime, eduEndTime, elderlyAgeSet } = state.userInfo;
      Cookies.set('prosafeToken', state.accessToken);
      setUserInfo((prev) => ({
        ...prev,
        userId,
        userName,
        gCd,
        sessionTimeout,
        hCd,
        hName,
        sCd,
        sName,
        pwdchangeDate,
        prejobtypeYn,
        eduPlace,
        eduCharge,
        eduStartTime,
        eduEndTime,
        elderlyAgeSet,
      }));
      await logPost({
        hCd,
        sCd,
        userId,
        menu: '로그인',
        action: '비밀번호 변경',
        etc: ``,
      });
      window.location.replace('/');
    } else if (statusCode === 4014) {
      toast.error(t(message));
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 새 비밀번호 저장버튼 클릭시
  // 1. 비밀번호 빈값,두 비밀번호 일치확인. 불일치시 알럿메시지
  // 2. 1번 통과시 정규식체크하고 통과시 새 비밀번호 저장 API 호출. 정규식 미충족시 비밀번호 인풋으로 포커스이동
  const onClickSaveNewPassword = () => {
    const { password } = newPassword;

    if (isPasswordPassed) {
      if (isPasswordRegExpPass(password)) {
        saveNewPasswordAPI();
      } else {
        toast.warning(t('비밀번호 조건을 확인하세요'));
        focusRef.current?.focus();
      }
    } else toast.warning(t(passwordAlertMessage));
  };

  const onClickChangePasswordLater = async () => {
    const res = await registerPwdchangeDate(state.id, state.accessToken);
    const { statusCode, message } = res.data;
    if (statusCode === 200) {
      Cookies.set('prosafeToken', state.accessToken);
      window.location.replace('/');
    } else {
      // toast.error(t(ERROR));
    }
  };

  return (
    <ModalBackground>
      <Modal>
        <div className='modal fitHeight'>
          <div className='inputForm-head'>
            <h5 className='bold'>{openModal?.title}</h5>
          </div>
          <div className='inputForm-body'>
            <div className='modal-contents'>
              <div className='inputForm-col'>
                <div className='inputFormText'>
                  {t('사용자님의 소중한 개인정보를 안전하게 보호하기 위해')}
                  <span className='bold'> {t('비밀번호 변경을 안내드립니다.')}</span>
                  <br /> {t('타인으로부터 개인정보를 보호하기 위하여 비밀번호를 주기적으로 변경해주시기 바랍니다.')}
                  <br />
                  <div className='content'>
                    <div className='bold'>{t('안전한 비밀번호를 만들기 위해서는?')}</div>
                    <div>
                      - <span className='red'>{t('영문 대문자, 소문자, 숫자, 특수문자')}</span> {t('3종류 조합해서 최소 8자 이상 설정')}
                    </div>
                    <div>- {t('생일, 주민등록번호, 전화번호 등 개인정보가 포함된 비밀번호는 피해서 설정')}</div>
                    <div>
                      - <span className='red'>{t('3개월에 1번')}</span> {t('이상 주기적으로 변경')}
                    </div>
                  </div>
                </div>
              </div>
              <div className='inputForm-col'>
                <div className='inputForm-row flexStart'>
                  <label htmlFor='userId' className='flex30'>
                    {t('사용자 ID')}
                  </label>
                  <div className='flex70'>{state?.userInfo.userId}</div>
                </div>
                <div className='inputForm-row flexStart'>
                  <label htmlFor='userName' className='flex30'>
                    {t('사용자명')}
                  </label>
                  <div className='flex70'>{state?.userInfo.userName}</div>
                </div>
                <div className='inputForm-row flexStart'>
                  <label htmlFor='pwdChangeDate' className='flex30'>
                    {t('이전 변경일시')}
                  </label>
                  <div className='flex70'>{yyyymmdd}</div>
                </div>
                <div className='inputForm-col labelRow'>
                  <Input name='password' label={t('신규 비밀번호')} type='password' state={newPassword} setState={setNewPassword} placeholder='' blur={onBlur} inputRef={focusRef} />
                </div>
                <div className='flexColumn'>
                  <small className='red text-left'>{pwAlert && t('※ 최소 8자리 이상 : 영어 대문자, 소문자, 숫자, 특수문자 중 3종류 조합')}</small>
                </div>
                <div className='inputForm-col labelRow'>
                  <Input name='passwordConfirm' label={t('비밀번호 확인')} type='password' state={newPassword} setState={setNewPassword} placeholder='' />
                </div>
              </div>
            </div>
            <div className='modal-footer flex-end'>
              <BtnBlue onClick={onClickSaveNewPassword}>{t('입력 완료')}</BtnBlue>
              <BtnWhite onClick={onClickChangePasswordLater}>
                {limitMonth}
                {t('개월 후에 변경하기')}
              </BtnWhite>
            </div>
          </div>
        </div>
      </Modal>
    </ModalBackground>
  );
};

export default PasswordChangeTermModal;
