import React, { useEffect, useRef, useState } from 'react';
import TimelineContainer from './TimelineContainer';
import styled from 'styled-components';
import { rangeToHHMMSS, HHMMSStoTime, TimeToRange } from '../../../../utils/timeline';

const Root = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }: { theme: any }) => theme.color.zinc_900};
  flex-grow: 1;
  height: 100%;
  position: relative;
  overflow: hidden;
  width: 30%;
  &.intervalWidth15 .sub-interval {
    width: 1.5rem;
  }
  &.intervalWidth25 .sub-interval {
    width: 2.5rem;
  }
  &.intervalWidth40 .sub-interval {
    width: 4rem;
  }
  .red-line {
    position: absolute;
    bottom: 0;
    bottom: 0.75rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    height: 1rem;
    width: 0;
    border-left: 2px solid red;
    border-right: 2px solid red;
  }
  .scroll-container {
    overflow-x: scroll;
    height: 100%;
    display: flex;
    .timeline-container {
      display: flex;
      align-items: center;
      height: 100%;
      flex-shrink: 0;
      padding: 0 50%;
      user-select: none;
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
    }
  }
  &.isDragging .sub-interval {
    cursor: grabbing !important;
  }
`;

interface Props {
  intervalWidth: string;
  selectedOption: 6 | 12 | 24;
  recordData: Playback[];
  selectedCameraInfo: SelectedCctvCameraInfo;
  onClickPause: () => void;
  videoWindow: VideoWindow;
}

const Timeline = ({
  selectedOption, //
  recordData,
  intervalWidth,
  selectedCameraInfo,
  onClickPause,
  videoWindow,
}: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  // 타임라인 드래그 상태
  const [isDragging, setIsDragging] = useState<boolean>(false);
  // 드래그 시작 위치
  const [startX, setStartX] = useState<number>(0);
  // 스크롤 위치
  const [scrollLeft, setScrollLeft] = useState<number>(0);

  const {
    windowIndex,
    clock: { currentTime, setCurrentTime },
  } = videoWindow;
  const interval = selectedOption * 5;
  const { isPlaying } = selectedCameraInfo;
  const [localTime, setLocalTime] = useState(0);

  // 스크롤 최대값 구하는 함수
  const getScrollLeftAtMax = (): number => {
    const container = scrollRef.current;
    if (container) {
      return container.scrollWidth - container.clientWidth;
    }
    return 0;
  };

  useEffect(() => {
    setLocalTime(currentTime);
  }, [currentTime]);

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      const range = TimeToRange(localTime, getScrollLeftAtMax());
      scrollContainer!.scrollLeft = range;
    }
  }, [localTime, scrollRef, getScrollLeftAtMax]);

  useEffect(() => {
    if (!isPlaying) {
      const timeoutId = setTimeout(() => {
        setCurrentTime(localTime);
      }, 500);

      return () => clearTimeout(timeoutId);
    }
    return () => {};
  }, [localTime, setCurrentTime, isPlaying]);

  // 스크롤 컨테이너 스크롤 함수
  const handleScroll = () => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer && !isDragging) {
      const maxScrollLeft = getScrollLeftAtMax();
      const scrollTime = rangeToHHMMSS(scrollContainer.scrollLeft, maxScrollLeft);
      const conditionTime = HHMMSStoTime(scrollTime);
      setLocalTime(conditionTime);
    }
  };

  // 스크롤 컨테이너 마우스 다운 함수
  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);
    setStartX(e.clientX - scrollRef.current!.offsetLeft);
    setScrollLeft(scrollRef.current!.scrollLeft);
    // video 재생 중인 경우 일시 정지
    if (selectedCameraInfo.isPlaying) {
      onClickPause();
    }
  };

  // 스크롤 컨테이너 드래그 함수
  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    // 드래그 중이 아니고 스크롤 중이면 return
    if (!isDragging) return;
    // 스크롤 최대 값
    const maxScrollLeft = getScrollLeftAtMax();
    // 현재 마우스 위치에서 왼쪽 가장자리까지의 거리를 계산
    const x = e.clientX - scrollRef.current!.offsetLeft;
    // 이전 마우스 위치와 현재 마우스 위치 사이의 거리를 계산하여 이동 방향을 결정
    const distanceMoved = x - startX;
    // 스크롤의 이동 속도를 조절하되, 이동 방향에 따라 속도의 부호를 변경
    const walk = distanceMoved * 0.25;

    let newScrollLeft = scrollLeft - walk;
    // 최소값과 최대값 제한
    newScrollLeft = Math.max(0, Math.min(newScrollLeft, maxScrollLeft));
    scrollRef.current!.scrollLeft = newScrollLeft;

    // startX와 scrollLeft 업데이트
    setStartX(x);
    setScrollLeft(newScrollLeft);

    // currentTime 업데이트
    const scrollTime = rangeToHHMMSS(newScrollLeft, maxScrollLeft);
    const conditionTime = HHMMSStoTime(scrollTime);

    requestAnimationFrame(() => {
      setLocalTime(conditionTime);
    });
  };

  // 스크롤 컨테이너 마우스 업 함수
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <Root className={`timeline ${intervalWidth} ${isDragging ? 'isDragging' : ''}`}>
      <div
        className='scroll-container' //
        ref={scrollRef}
        onScroll={handleScroll}
      >
        <div
          className='timeline-container' //
          role='slider'
          tabIndex={0}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          {Array.from({ length: (24 * 60) / interval }, (_, index) => (
            <TimelineContainer //
              key={index}
              interval={interval}
              recordData={recordData}
              index={index}
            />
          ))}
        </div>
      </div>
      <div className='red-line' />
    </Root>
  );
};

export default Timeline;
