import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../translation/i18n';
import { ReactComponent as SnowFlower } from '../../assets/images/icons/snow-flower.svg';
import { ReactComponent as SnowIce30 } from '../../assets/images/icons/snow-ice30.svg';
import { ReactComponent as SnowBall } from '../../assets/images/icons/snow-ball.svg';
import { ReactComponent as Sun } from '../../assets/images/icons/sun.svg';
import { ReactComponent as SunCloud } from '../../assets/images/icons/sunCloud.svg';
import { ReactComponent as Blur } from '../../assets/images/icons/blur.svg';
import { ReactComponent as Umbrella } from '../../assets/images/icons/umbrella.svg';
import sun from '../../assets/images/icons/sun.png';
import cloud from '../../assets/images/icons/cloud.png';
import blur from '../../assets/images/icons/blur.png';
import rain from '../../assets/images/icons/rain.png';
import snow from '../../assets/images/icons/snow.png';
import littleCloud from '../../assets/images/icons/little_cloud.png';
import { useQuery } from '@tanstack/react-query';
import { weather3DaysGet, weatherDayGet, weatherWeekGet } from '../../services/weather';
import { apiGet } from '../../services/_common';

const animateWeather = (showSvg: boolean, weather: string) => {
  switch (weather) {
    case 'sunny': {
      return (
        <div className='container'>
          <div className='svg-container show-svg '>
            <Sun className='rotating-sun' />
          </div>
        </div>
      );
    }
    case 'little_cloud': {
      return (
        <div className='container'>
          <div className={`svg-container ${showSvg ? 'show-svg floating' : ''}`}>
            <SunCloud className='little_cloud' />
          </div>
        </div>
      );
    }

    case 'blur':
    case 'cloudy': {
      return (
        <div className='container'>
          <div className='svg-container show-svg floating'>
            {/* <div className='svg-container cloudy show-svg floating'> */}
            <Blur className='blur' />
            {/* <SunCloud />
              <Cloud style={{ position: 'absolute', left: '10%' }} /> */}
          </div>
        </div>
      );
    }

    case 'rain': {
      const newDrops: any[] = [];
      let increment = 0;
      while (increment < 95) {
        const randoHundo = Math.floor(Math.random() * 100);
        const randoFiver = Math.floor(Math.random() * 5) + 2;
        increment += randoFiver;

        const dropStyle = {
          left: `${increment}%`,
          animationDelay: `0.${randoHundo}s`,
          animationDuration: `0.8${randoHundo}s`,
        };

        newDrops.push(<div className='raindrop' style={dropStyle} key={increment} />);
      }

      return (
        <div className='container'>
          {newDrops}
          <div className={`svg-container ${showSvg ? 'show-svg bouncing' : ''}`}>
            <Umbrella style={{ position: 'absolute', right: '2%', top: '-10%' }} />
          </div>
        </div>
      );
    }

    case 'shower': {
      const newDrops: any[] = [];
      let increment = 0;
      while (increment < 95) {
        const randoHundo = Math.floor(Math.random() * 100);
        const randoFiver = Math.floor(Math.random() * 5) + 2;
        increment += randoFiver;

        const dropStyle = {
          left: `${increment}%`,
          animationDelay: `0.${randoHundo}s`,
          animationDuration: `0.3${randoHundo}s`,
        };

        newDrops.push(<div className='raindrop' style={dropStyle} key={increment} />);
      }

      return (
        <div className='container'>
          {newDrops}
          <div className={`svg-container ${showSvg ? 'show-svg bouncing' : ''}`}>
            <Umbrella style={{ position: 'absolute', right: '2%', top: '-10%' }} />
          </div>
        </div>
      );
    }

    case 'sleet': {
      const newDrops: any[] = [];
      let increment = 0;
      const types = ['raindrop', 'snowice', 'sunflower'];

      while (increment < 95) {
        const randoHundo = Math.floor(Math.random() * 100);
        const randoFiver = Math.floor(Math.random() * 5) + 2;
        const randoLeftRight = Math.floor(Math.random() * 5) - 2;
        const randomType = types[Math.floor(Math.random() * types.length)];

        increment += randoFiver;

        let animationDuration;
        switch (randomType) {
          case 'raindrop':
            animationDuration = `${2 + Math.random()}s`;
            break;
          case 'snowice':
            animationDuration = `${5 + Math.random()}s`;
            break;
          case 'sunflower':
            animationDuration = `${7 + Math.random()}s`;
            break;
          default:
            animationDuration = `${5 + Math.random()}s`;
            break;
        }

        const dropStyle = {
          left: `${increment}%`,
          animationDelay: `0.${randoHundo}s`,
          animationDuration,
          '--random-left-right': `${randoLeftRight}vw`,
        };

        newDrops.push(
          <div className={randomType} style={dropStyle as React.CSSProperties} key={increment}>
            {randomType === 'snowice' && <SnowIce30 />}
            {randomType === 'sunflower' && <SnowFlower />}
          </div>
        );
      }

      return (
        <div className='container'>
          {newDrops}
          <div className={`svg-container ${showSvg ? 'show-svg bouncing' : ''}`}>
            <Umbrella style={{ position: 'absolute', right: '2%', top: '-10%' }} />
          </div>
        </div>
      );
    }

    case 'snow': {
      const newDrops: any[] = [];
      let increment = 0;
      while (increment < 95) {
        const randoHundo = Math.floor(Math.random() * 100);
        const randoFiver = Math.floor(Math.random() * 5) + 2;
        const randoLeftRight = Math.floor(Math.random() * 5) - 2;
        increment += randoFiver;

        const dropStyle = {
          left: `${increment}%`,
          animationDelay: `0.${randoHundo}s`,
          animationDuration: `${5 + Math.random()}s`,
          '--random-left-right': `${randoLeftRight}vw`,
        };
        newDrops.push(
          <div className='snowdrop' style={dropStyle as React.CSSProperties} key={increment}>
            <SnowIce30 />
            <SnowBall />
          </div>
        );
      }

      return newDrops;
    }

    case 'sunCloud': {
      return (
        <div className='container'>
          <div className={`svg-container ${showSvg ? 'show-svg floating' : ''}`}>
            <SunCloud className='little_cloud beside-sun' />
          </div>
          <Sun className='rotating-sun' />
        </div>
      );
    }
    default: {
      return null;
    }
  }
};

const getWeatherIcon = (weather: string) => {
  switch (weather) {
    case 'sunny':
      return <img src={sun} width='100%' />;
    case 'little_cloud':
      return <img src={littleCloud} width='100%' />;
    case 'cloudy':
      return <img src={cloud} width='100%' />;
    case 'blur':
      return <img src={blur} width='100%' />;
    case 'rain':
      return <img src={rain} width='100%' />;
    case 'sleet':
      return <img src={snow} width='100%' />;
    case 'snow':
      return <img src={snow} width='100%' />;
    case 'shower':
      return <img src={rain} width='100%' />;
    default:
      return null;
  }
};

const getTimeStatus = (): string => {
  const currentHour = dayjs().hour();

  if (currentHour >= 0 && currentHour < 6) return 'morning';
  if (currentHour >= 6 && currentHour < 18) return 'afternoon';
  return 'evening';
};

export const WeatherInfo = ({ name, userInfo }: any) => {
  const { t } = useTranslation();
  const todayjs = dayjs();
  let format = todayjs.format(`MM월 DD일 dddd`);
  if (i18n.language === 'en-US') format = todayjs.locale('en').format(`MMMM D dddd`);
  else format = todayjs.locale('ko').format(`MM월 DD일 dddd`);

  const days = Array.from({ length: 3 }).map((_, index) => {
    const targetDay = dayjs().add(index, 'day'); // +1 to start from tomorrow
    return {
      order: index,
      date: targetDay.date(),
      day: targetDay.format('ddd'),
    };
  });
  const [geoInfo, setGeoInfo] = useState({ latitude: '', longitude: '', address: '' });

  /**
   * 수정자 : 한영광
   * 수정일자 : 2023.12.01
   * 수정내용 : 로컬스토리지에 날씨 데이터를 저장하고, 재 로딩 시 날씨 API 요청에 대한 응답이 오기 전에 먼저 보여주도록 처리하여 로딩이 느린 부분 개선
   */
  const after2DaysLocalStorage = window.localStorage.getItem('after2Days');
  const currentWeatherLocalStorage = window.localStorage.getItem('currentWeather');
  const localAfter2Days = after2DaysLocalStorage ? JSON.parse(after2DaysLocalStorage) : days;
  const localCurrentWeather = currentWeatherLocalStorage ? JSON.parse(currentWeatherLocalStorage) : { temp: '', icon: '', wsd: '', sky: '' };
  const [after2Days, setAfter2Days] = useState<any[]>(localAfter2Days);
  const [currentWeather, setCurrentWeather] = useState(localCurrentWeather);
  const [showSvg, setShowSvg] = useState(false);

  const siteInfoQuery = useQuery(['siteInfoGet2', userInfo], () => apiGet({ path: '/site/info', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && userInfo.sCd !== '00000',
  });

  const headInfoQuery = useQuery(['headInfoGet', userInfo.hCd], () => apiGet({ path: '/head/info', req: { hCd: userInfo.hCd } }), {
    enabled: !!userInfo.hCd && !(userInfo.sCd === '' || userInfo.sCd === '00000'),
  });

  const dayWeatherQuery = useQuery(['dayWeather2', geoInfo], () => weatherDayGet({ latitude: geoInfo.latitude, longitude: geoInfo.longitude, address: geoInfo.address }), {
    enabled: !!geoInfo.latitude && !!geoInfo.longitude && !!geoInfo.address,
  });

  const threeDaysWeatherQuery = useQuery(['threeDaysWeather', geoInfo], () => weather3DaysGet({ latitude: geoInfo.latitude, longitude: geoInfo.longitude, address: geoInfo.address }), {
    enabled: !!geoInfo.latitude && !!geoInfo.longitude && !!geoInfo.address,
  });

  useEffect(() => {
    if (after2Days) {
      window.localStorage.setItem('after2Days', JSON.stringify(after2Days));
    }
    if (currentWeather) {
      window.localStorage.setItem('currentWeather', JSON.stringify(currentWeather));
    }
  }, [after2Days, currentWeather]);

  useEffect(() => {
    if (headInfoQuery.isSuccess && headInfoQuery.data && headInfoQuery.data.data.statusCode === 200) {
      const { headInfo } = headInfoQuery.data.data.data;
      setGeoInfo({ latitude: headInfo.latitude, longitude: headInfo.longitude, address: headInfo.address1 });
    }
  }, [headInfoQuery.isSuccess, headInfoQuery.isRefetching]);

  useEffect(() => {
    if (siteInfoQuery.isSuccess && siteInfoQuery.data && siteInfoQuery.data.data.statusCode === 200) {
      const { siteInfo } = siteInfoQuery.data.data.data;
      setGeoInfo({ latitude: siteInfo.latitude, longitude: siteInfo.longitude, address: siteInfo.address1 });
    }
  }, [siteInfoQuery.isSuccess, siteInfoQuery.isRefetching]);

  useEffect(() => {
    if (dayWeatherQuery.isSuccess && dayWeatherQuery.data && dayWeatherQuery.data.data.statusCode === 200) {
      const { sky, tmp, wsd } = dayWeatherQuery.data.data.data;
      setCurrentWeather((prev: any) => ({ ...prev, tmp, wsd, sky }));
    }
  }, [dayWeatherQuery.isSuccess, dayWeatherQuery.isRefetching, geoInfo]);

  useEffect(() => {
    if (threeDaysWeatherQuery.isSuccess && threeDaysWeatherQuery.data && threeDaysWeatherQuery.data.data.statusCode === 200) {
      const { weekSky1, weekSky2, weekTmn1, weekTmn2, weekTmx1, weekTmx2, pm10, todayTmn, todayTmx, morTmp, aftTmp, dinTmp, morPop, aftPop, dinPop } = threeDaysWeatherQuery.data.data.data;
      const copyArray = [...after2Days];
      const status = getTimeStatus();

      copyArray.map((el: any) => {
        if (el.order === 0) {
          copyArray[0] = {
            ...el,
            sky: weekSky1,
            minTemp: todayTmn,
            maxTemp: todayTmx,
            icon: getWeatherIcon(weekSky1),
            morTmp,
            aftTmp,
            dinTmp,
            pm10,
            rainPct: status === 'morning' ? morPop : status === 'evening' ? dinPop : aftPop,
          };
        }
        if (el.order === 1) {
          copyArray[1] = {
            ...el,
            sky: weekSky1,
            minTemp: weekTmn1,
            maxTemp: weekTmx1,
            icon: getWeatherIcon(weekSky1),
          };
        }
        if (el.order === 2) {
          copyArray[2] = {
            ...el,
            sky: weekSky2,
            minTemp: weekTmn2,
            maxTemp: weekTmx2,
            icon: getWeatherIcon(weekSky2),
          };
        }
      });
      setAfter2Days(copyArray);
    }
  }, [threeDaysWeatherQuery.isSuccess, threeDaysWeatherQuery.isRefetching, geoInfo]);

  const onClickRefresh = () => {
    dayWeatherQuery.refetch();
    threeDaysWeatherQuery.refetch();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSvg(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  // if (
  //   !dayWeatherQuery.isSuccess ||
  //   dayWeatherQuery.data.status !== 200 ||
  //   dayWeatherQuery.isRefetching ||
  //   !threeDaysWeatherQuery.isSuccess ||
  //   threeDaysWeatherQuery.data.status !== 200 ||
  //   threeDaysWeatherQuery.isRefetching
  // ) {
  //   return (
  //     <div className='centered-content'>
  //       <PulseLoader color='rgb(0, 122, 255)' size='10px' />
  //     </div>
  //   );
  // }
  return (
    <div className='weatherTodayInfo'>
      <div className='widget-header'>
        <div className='widget-title flex-between '>
          <span>{t(name)}</span>
          <div className='refresh-btn white' onClick={onClickRefresh} role='presentation'>
            <span className='material-symbols-rounded'>refresh</span>
          </div>
        </div>
      </div>
      <div className='widget-body'>
        <div className='weatherToday' style={{ color: '#fff' }}>
          <div className='weatherInfo'>
            <div>{t('오늘의 날씨')}</div>
            <div className='weather-dateInfo'>{format}</div>
          </div>
          <div className='weatherAnimationWrapper'>{animateWeather(showSvg, currentWeather.sky)}</div>
        </div>
        <div className='weatherTodayDetail'>
          <div className='weatherBoxGroup'>
            <div className='weatherBox text'>
              <div>
                <dt>{t('강수확률')}</dt>
                <dd>{after2Days[0].rainPct}%</dd>
              </div>
              <div>
                <dt>{t('미세먼지')}</dt>
                <dd>{after2Days[0].pm10}㎍/㎥</dd>
              </div>
              <div>
                <dt>{t('풍속')}</dt>
                <dd>{currentWeather.wsd}m/s</dd>
              </div>
            </div>
            <div className='weatherBox text'>
              <div>
                <dt>{t('오전')}</dt>
                <dd>{after2Days[0].morTmp}°</dd>
              </div>
              <div>
                <dt>{t('정오')}</dt>
                <dd>{after2Days[0].aftTmp}°</dd>
              </div>
              <div>
                <dt>{t('오후')}</dt>
                <dd>{after2Days[0].dinTmp}°</dd>
              </div>
            </div>
            <div className='weatherBox icon'>
              <div>
                <dt>{t('내일')}</dt>
                <div className='weatherBox-details'>
                  <div className='lowest'>
                    {t('최저')} <span>{after2Days[1].minTemp}</span>°
                  </div>
                  <div className='highest'>
                    {t('최고')} <span>{after2Days[1].maxTemp}</span>°
                  </div>
                </div>
              </div>
              <dd>
                <div>{getWeatherIcon(after2Days[1].sky)}</div>
              </dd>
            </div>
            <div className='weatherBox icon'>
              <div>
                <dt>{t('모레')}</dt>
                <div className='weatherBox-details'>
                  <div className='lowest'>
                    {t('최저')} <span>{after2Days[2].minTemp}</span>°
                  </div>
                  <div className='highest'>
                    {t('최고')} <span>{after2Days[2].maxTemp}</span>°
                  </div>
                </div>
              </div>
              <dd>
                <div>{getWeatherIcon(after2Days[2].sky)}</div>
              </dd>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const WeatherWeekInfo = ({ name, userInfo }: any) => {
  const { t } = useTranslation();
  const [geoInfo, setGeoInfo] = useState({ latitude: '', longitude: '', address: '' });
  const [showSvg, setShowSvg] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSvg(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const days = Array.from({ length: 6 }).map((_, index) => {
    const targetDay = dayjs().add(index + 1, 'day'); // +1 to start from tomorrow
    return {
      order: index,
      date: targetDay.date(),
      day: targetDay.format('ddd'),
      sky: '',
    };
  });

  type IDays = {
    order: number;
    date: number;
    day: string;
    minTemp?: string;
    maxTemp?: string;
    sky: string;
    icon?: any;
  };
  const after6DaysLocalStorage = window.localStorage.getItem('after6Days');
  const currentWeatherLocalStorage = window.localStorage.getItem('currentWeatherW');
  const localAfter6Days = after6DaysLocalStorage ? JSON.parse(after6DaysLocalStorage) : days;
  const localCurrentWeather = currentWeatherLocalStorage ? JSON.parse(currentWeatherLocalStorage) : { icon: '', wsd: '', tmp: '', adrs: '', minTemp: '', maxTemp: '', rainPct: '', sky: '' };
  const [after6Days, setAfter6Days] = useState<IDays[]>(localAfter6Days);
  const [currentWeather, setCurrentWeather] = useState(localCurrentWeather);

  const siteInfoQuery = useQuery(['siteInfoGet2', userInfo], () => apiGet({ path: '/site/info', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && userInfo.sCd !== '00000',
  });

  const headInfoQuery = useQuery(['headInfoGet', userInfo.hCd], () => apiGet({ path: '/head/info', req: { hCd: userInfo.hCd } }), {
    enabled: !!userInfo.hCd && !(userInfo.sCd === '' || userInfo.sCd === '00000'),
  });

  const dayWeatherQuery = useQuery(['dayWeather', geoInfo], () => weatherDayGet({ latitude: geoInfo.latitude, longitude: geoInfo.longitude, address: geoInfo.address }), {
    retry: 3,
    enabled: !!geoInfo.latitude && !!geoInfo.longitude && !!geoInfo.address,
  });

  const weekWeatherQuery = useQuery(['weekWeather', geoInfo], () => weatherWeekGet({ latitude: geoInfo.latitude, longitude: geoInfo.longitude, address: geoInfo.address }), {
    retry: 3,
    enabled: !!geoInfo.latitude && !!geoInfo.longitude && !!geoInfo.address,
  });

  useEffect(() => {
    if (after6Days) {
      window.localStorage.setItem('after6Days', JSON.stringify(after6Days));
    }
    if (currentWeather) {
      window.localStorage.setItem('currentWeatherW', JSON.stringify(currentWeather));
    }
  }, [after6Days, currentWeather]);

  useEffect(() => {
    if (headInfoQuery.isSuccess && headInfoQuery.data && headInfoQuery.data.data.statusCode === 200) {
      const { headInfo } = headInfoQuery.data.data.data;
      setGeoInfo({ latitude: headInfo.latitude, longitude: headInfo.longitude, address: headInfo.address1 });
      const shortAdd = headInfo.address1.split(' ');
      setCurrentWeather((prev: any) => ({ ...prev, adrs: shortAdd[1] }));
    }
  }, [headInfoQuery.isSuccess, headInfoQuery.isRefetching]);

  useEffect(() => {
    if (siteInfoQuery.isSuccess && siteInfoQuery.data && siteInfoQuery.data.data.statusCode === 200) {
      const { siteInfo } = siteInfoQuery.data.data.data;
      setGeoInfo({ latitude: siteInfo.latitude, longitude: siteInfo.longitude, address: siteInfo.address1 });
      const shortAdd = siteInfo.address1.split(' ');
      setCurrentWeather((prev: any) => ({ ...prev, adrs: shortAdd[1] }));
    }
  }, [siteInfoQuery.isSuccess, siteInfoQuery.isRefetching]);

  useEffect(() => {
    if (dayWeatherQuery.isSuccess && dayWeatherQuery.data && dayWeatherQuery.data.data.statusCode === 200) {
      const { sky, tmp, wsd } = dayWeatherQuery.data.data.data;
      setCurrentWeather((prev: any) => ({ ...prev, tmp, wsd, sky }));
    }
  }, [dayWeatherQuery.isSuccess, dayWeatherQuery.isRefetching, geoInfo]);

  useEffect(() => {
    if (weekWeatherQuery.isSuccess && weekWeatherQuery.data && weekWeatherQuery.data.data.statusCode === 200) {
      const {
        morPop,
        todayTmn,
        todayTmx,
        aftPop,
        dinPop,
        weekSky1,
        weekSky2,
        weekSky3,
        weekSky4,
        weekSky5,
        weekSky6,
        weekTmn1,
        weekTmn2,
        weekTmn3,
        weekTmn4,
        weekTmn5,
        weekTmn6,
        weekTmx1,
        weekTmx2,
        weekTmx3,
        weekTmx4,
        weekTmx5,
        weekTmx6,
      } = weekWeatherQuery.data.data.data;

      const status = getTimeStatus();
      setCurrentWeather((prev: any) => ({ ...prev, minTemp: todayTmn, maxTemp: todayTmx, rainPct: status === 'morning' ? morPop : status === 'evening' ? dinPop : aftPop }));

      const copyArray = [...after6Days];
      copyArray.map((el: IDays, i: number) => {
        const targetDay = dayjs().add(i + 1, 'day'); // +1 to start from tomorrow
        if (el.order === 0) {
          copyArray[0] = {
            ...el,
            date: targetDay.date(),
            day: targetDay.format('ddd'),
            sky: weekSky1,
            minTemp: weekTmn1,
            maxTemp: weekTmx1,
          };
        }
        if (el.order === 1) {
          copyArray[1] = {
            ...el,
            date: targetDay.date(),
            day: targetDay.format('ddd'),
            sky: weekSky2,
            minTemp: weekTmn2,
            maxTemp: weekTmx2,
          };
        }
        if (el.order === 2) {
          copyArray[2] = {
            ...el,
            date: targetDay.date(),
            day: targetDay.format('ddd'),
            sky: weekSky3,
            minTemp: weekTmn3,
            maxTemp: weekTmx3,
          };
        }
        if (el.order === 3) {
          copyArray[3] = {
            ...el,
            date: targetDay.date(),
            day: targetDay.format('ddd'),
            sky: weekSky4,
            minTemp: weekTmn4,
            maxTemp: weekTmx4,
          };
        }
        if (el.order === 4) {
          copyArray[4] = {
            ...el,
            date: targetDay.date(),
            day: targetDay.format('ddd'),
            sky: weekSky5,
            minTemp: weekTmn5,
            maxTemp: weekTmx5,
          };
        }
        if (el.order === 5) {
          copyArray[5] = {
            ...el,
            date: targetDay.date(),
            day: targetDay.format('ddd'),
            sky: weekSky6,
            minTemp: weekTmn6,
            maxTemp: weekTmx6,
          };
        }
      });
      setAfter6Days(copyArray);
    }
  }, [weekWeatherQuery.isSuccess, weekWeatherQuery.isRefetching, geoInfo]);

  const onClickRefresh = () => {
    dayWeatherQuery.refetch();
    weekWeatherQuery.refetch();
  };

  return (
    <div className='weatherWeekInfo'>
      <div className='widget-header'>
        <div className='widget-title flex-between '>
          {/* <span>{t(name)}</span> */}
          <span>{t('기상 정보')}</span>
          <div className='refresh-btn white' onClick={onClickRefresh} role='presentation'>
            <span className='material-symbols-rounded'>refresh</span>
          </div>
        </div>
      </div>
      <div className='widget-body'>
        <div className='weatherToday notWeek' style={{ color: '#fff' }}>
          <div className='weatherInfo'>
            <div>
              {t('주간 날씨')} • {currentWeather.adrs}
            </div>
            <div className='weatherBox text'>
              <div>
                <dt>
                  {t('현재')} {currentWeather.tmp}°
                </dt>
              </div>
              <div>
                <dt>
                  {t('최저')} {Math.floor(Number(currentWeather.minTemp))}°
                </dt>
                <dt>
                  {t('최고')} {Math.floor(Number(currentWeather.maxTemp))}°
                </dt>
              </div>
              <div>
                <dt>
                  {t('강수확률')} {currentWeather.rainPct}%
                </dt>
              </div>
            </div>
          </div>
          <div className='weatherAnimationWrapper'>{animateWeather(showSvg, currentWeather.sky)}</div>
        </div>
        <div className='weatherWeek'>
          {after6Days.map(({ date, day, maxTemp, minTemp, sky }: IDays) => {
            return (
              <div key={date}>
                <div className='date'>
                  <div>{t(day)}</div>
                  <div>{date}</div>
                </div>
                <div className='weatherWeekIcon'>{getWeatherIcon(sky)}</div>
                <div className='weatherWeekTemp'>
                  <div className='lowest'>{minTemp}°</div>
                  <div className='highest'>{maxTemp}°</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
