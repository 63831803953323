/**
 * 작성자 : 홍선영
 * 날짜 : 2023.06.08
 * 경로 : 설정관리-장비관리-센서관리탭 (현장관리자)
 */

import { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { IComCdList, IModal } from 'customTypes';
import { userState } from '../../../atoms';
import { COMCD_SENSOR_LIST, FLAG_CREATE_OR_UPDATE, USE_YN } from '../../../_constants';
import { InputTable } from '../../../assets/styles/InputTable';
import illustrator from '../../../assets/images/illustration/31.svg';
import { BtnBlue, BtnRed } from '../../../components/Button';
import Portal from '../../../components/Portal';
import BackButton from '../../../components/BackButton';
import SelectBoxs from '../../../components/SelectBoxs';
import DeleteModal from '../../../components/Modal/DeleteModal2';
import { trimArray } from '../../../utils/trimArray';
import { useSetAuth } from '../../../utils/useSetAuth';
import { limitDigits } from '../../../utils/limitDigits';
import { scrollToNodeTop } from '../../../utils/scrollToNodeTop';
import { applyBorderStyle } from '../../../utils/applyBorderStyle';
import { scrollToNodeBottom } from '../../../utils/scrollToNodeBottom';
import { arraySortByAscdOrder } from '../../../utils/arraySortByAscdOrder';
import { logPost } from '../../../services/log';
import { apiDelete, apiGet, apiPost } from '../../../services/_common';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 0.5rem;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  /* overflow-x: auto; */
  .material-symbols-rounded {
    font-variation-settings: 'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 48;
  }
  .arrow-icon span {
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    gap: 0.25rem;
    cursor: pointer;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    border-radius: 5rem;
    padding-right: 1rem;
    padding-left: 0.5rem;
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
  }
  .icon-btn:hover,
  .icon-btn:active {
    color: ${({ theme }: { theme: any }) => theme.text_primary};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
  }
  .emptyData {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    padding-bottom: 4rem;
    img {
      width: 16rem;
      /* user-drag: none; */
      -webkit-user-drag: none;
    }
    span {
      color: ${({ theme }: { theme: any }) => theme.filled_violet};
    }
  }
  > div:nth-child(2) {
    flex-grow: 1;
    width: 100%;
    overflow: auto;
  }
  .inputFormsWrapper {
    border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    padding: 0.5rem;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: row;
    .table {
      height: fit-content;
    }
  }
  .formTitle {
    padding: 0 0.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .titleDetail {
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    span {
      color: ${({ theme }: { theme: any }) => theme.text_primary};
    }
    span::before {
      content: '•';
      margin: 0 0.25rem;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
    }
  }
  .thead {
    position: sticky;
    top: 0;
  }
  .table {
    // overflow-y: auto;
    flex-grow: 1;
  }
  .tr {
    display: flex;
    > div {
      flex-shrink: 0;
    }
    input[type='number'] {
      text-align: center;
    }
    input[type='text'],
    input[type='number'] {
      height: 2rem;
      margin: 0 0.25rem;
    }
    button {
      padding: 0 0.5rem;
      font-size: 0.875rem;
      border-radius: 0.25rem;
    }
    ul > li {
      width: 100% !important;
      > span {
        width: 100%;
      }
    }
  }

  .inputFormsWrapper.smallTab {
    flex-direction: row;
    align-items: center;
    padding: 0 0.5rem;
    font-weight: 500;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 0rem !important;
    }
    .tab {
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
      cursor: pointer;
      height: 3.5rem;
      border-bottom: 2px solid transparent;
    }
    .activeTab {
      font-weight: 700;
      border-bottom: 2px solid ${({ theme }: { theme: any }) => theme.selected_primary};
      color: ${({ theme }: { theme: any }) => theme.selected_primary};
    }
  }

  /* .tbody.withInput .tr {
    > div:nth-child(n + 2):nth-last-child(n + 2) {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      padding: 0;
    }
  } */
`;

interface ISensorObject {
  hCd: string;
  sCd: string;
  grCd: string;
  subCd: string;
  sName: string;
  useYn: string;
  writer: string;
  editor: string;
}

interface ISensorDetailObject {
  hCd: string;
  sCd: string;
  grCd: string;
  rootSubCd?: string;
  subCd: string;
  sName: string;
  sDefault: string;
  writer: string;
  editor: string;
  flag: string;
  useYn?: string;
}

interface Props {
  size: { innerSize: { W: number } };
}
const SensorSetting = ({ size }: Props) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const [sensorListTable, setSensorListTable] = useState<ISensorObject[]>([]);
  const [sensorDetailListTable, setSensorDetailListTable] = useState<any[]>([]);
  const [multiSensorList, setMultiSensorList] = useState<ISensorU[]>([]);
  const sensorDetailListTableRefs = useRef<Array<HTMLInputElement | null>>(Array(sensorDetailListTable.length).fill(null));
  const [selectedSensor, setSelectedSensor] = useState({ grCd: '', sName: '', hasSubTab: false });
  const [defaultSensorDetail, setDefaultSensorDetail] = useState<string | undefined>(); // 센서목록상세 리스트 중 디폴트항목의 subCd
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const scrollContainerRef = useRef<HTMLInputElement>(null);
  const [addRowStatus, setAddRowStatus] = useState(false); // 신규추가 상태값
  const MULTI_SENSOR = 0;
  const NORMAL_SENSOR = 1;
  const [activeTab, setActiveTab] = useState(MULTI_SENSOR); // 하위메뉴의 내부탭 액티브상태값 (멀티센서 탭이 디폴트)

  // 신규추가시 스크롤 마지막으로 이동
  useEffect(() => {
    if (addRowStatus) {
      scrollToNodeBottom(scrollContainerRef);
      setAddRowStatus(false);
    }
  }, [addRowStatus]);

  useEffect(() => {
    getSensorListAPI().then((res: any) => {
      if (res.status === 200) {
        getComCdList(res.data.data.sensorList);
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '장비 관리 > 센서 관리',
          action: '조회',
          etc: ``,
        });
      }
    });
  }, []);

  // 공통코드 센서목록 항목의 etc1값이 1인 경우, 다중서브탭임 (e.g. 설정관리-센서관리-다중 유해가스 측정기)
  const getComCdList = async (sensorList: any) => {
    const req = { grCd: COMCD_SENSOR_LIST };
    const res = await apiGet({ path: '/code/detail', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const newArray = sensorList.map((el: any) => {
        const findObj = data.comCdList.find((el2: IComCdList) => el2.subCd === el.subCd && el2.etc1 === '1');
        if (findObj) return { subCd: el.subCd, sName: el.sName, useYn: el.useYn, subTab: true };
        return { subCd: el.subCd, sName: el.sName, useYn: el.useYn, subTab: false };
      });
      setSensorListTable(newArray);
    }
  };

  const getSensorListAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/sensor', req });
    return res;
  };

  const saveSensorListAPI = async (sensorArray: ISensorPost[]) => {
    const req = { sensorReqDto: sensorArray };
    const res = await apiPost({ path: '/sensor', req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      setSensorListTable(data.sensorList);
      toast.success(t(message));
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '장비 관리 > 센서 관리',
        action: `저장`,
        etc: ``,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  const saveSensorDetailListAPI = async (sensorDetailArray: ISensorDetailObject[], setList: Dispatch<SetStateAction<ISensorDetailObject[]>>) => {
    const requiredField = ['subCd', 'sName'];

    const subCdIndices: { [key: string]: number } = {};
    let firstDuplicateIndex = -1; // 중복된 subCd들 중 첫번째 subCd의 인덱스

    for (let i = 0; i < sensorDetailArray.length; i += 1) {
      const { subCd, flag } = sensorDetailArray[i];

      if (subCd in subCdIndices) {
        firstDuplicateIndex = subCdIndices[subCd];
        break;
      } else {
        subCdIndices[subCd] = i;
      }
    }

    const emptyCheck = sensorDetailArray.filter((el: any) => {
      const check = requiredField.find((el2: string) => el[el2] === '' && el.flag === FLAG_CREATE_OR_UPDATE);
      return check;
    });

    if (emptyCheck.length > 0) {
      toast.warning(t('필수입력값을 모두 입력하세요'));
    } else if (firstDuplicateIndex > -1) {
      toast.warning(t('중복된 센서 번호가 있습니다.'));
      // 중복된 센서번호 인풋으로 포커스이동
      sensorDetailListTableRefs && sensorDetailListTableRefs.current[firstDuplicateIndex]?.focus();
    } else {
      // 빈값이 없는 경우 수정,업데이트된 로우들만 서버에 전달
      const dbSendRows = sensorDetailArray.filter((el: ISensorDetailObject) => el.flag === FLAG_CREATE_OR_UPDATE);
      if (dbSendRows.length > 0) {
        const req = { sensorDetailReqDto: trimArray(dbSendRows) };
        const res = await apiPost({ path: '/sensor/detail', contentType: 'application/json', req });
        const { statusCode, data, message } = res.data;
        if (statusCode === 200) {
          if (data.sensorList.length > 0) {
            // 응답데이터에 hCd, sCd, grCd 추가
            const newListWIthRootSubCd = data.sensorList.map((el: ISensorDetailObject) => {
              return { ...el, hCd: userInfo.hCd, sCd: userInfo.sCd, grCd: selectedSensor.grCd, editor: userInfo.userId };
            });
            setList(arraySortByAscdOrder(newListWIthRootSubCd, 'subCd'));

            //  (센서목록 상세 기본값을 바꾸고 저장할때, 저장시점에 defaultSensorDetail값이 바뀌었다면, 디폴트값 비교해서 기존로우의 플래그를 Y로 변경시키기 위함)
            const selectedRow = data.sensorList.find((el: ISensorDetailObject) => el.sDefault === 'Y');
            if (selectedRow !== undefined) {
              setDefaultSensorDetail(selectedRow.subCd);
            }
          }
          toast.success(t(message));
          await logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: '장비 관리 > 센서 관리',
            action: `${selectedSensor.sName} 저장`,
            etc: `신규 ${dbSendRows.filter((v: any) => !v.rootSubCd).length}건, 수정 ${dbSendRows.filter((v: any) => v.rootSubCd).length}건`,
          });
        } else {
          // toast.error(t(ERROR));
        }
      }
    }
  };

  const saveSensorUListAPI = async (sensorDetailArray: ISensorU[], setList: Dispatch<SetStateAction<ISensorU[]>>) => {
    // 빈값이 없는 경우 수정,업데이트된 로우들만 서버에 전달
    if (sensorDetailArray.length > 0) {
      const reqData = sensorDetailArray.map(({ hCd, sCd, ssCd, subCd, sDefault, useYn, delYn }: ISensorU) => ({ hCd, sCd, ssCd, subCd: '08', sDefault, useYn, delYn, editor: userInfo.userId }));
      const req = { sensorUReqDto: reqData };
      const res = await apiPost({ path: '/sensor/ulist', req });
      const { statusCode, data, message } = res.data;
      if (statusCode === 200) {
        if (data.sensorUList.length > 0) {
          // 응답데이터에 hCd, sCd, grCd 추가
          const newArray = data.sensorUList.map(({ ssCd, subCd, cdName, sDefault, useYn, delYn, uListYn }: ISensorU) => ({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            ssCd,
            subCd,
            sName: cdName,
            sDefault,
            useYn,
            delYn,
            editor: userInfo.userId,
            uListYn,
          }));

          setList(newArray);
        }
        toast.success(t(message));
        await logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '장비 관리 > 센서 관리',
          action: `${selectedSensor.sName} 저장`,
          etc: `신규 ${sensorDetailArray.filter((v: any) => !v.rootSubCd).length}건, 수정 ${sensorDetailArray.filter((v: any) => v.rootSubCd).length}건`,
        });
      } else {
        // toast.error(t(ERROR));
      }
    }
  };

  const getSensorDetailAPI = async (grCd: string) => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, grCd };
    const res = await apiGet({ path: '/sensor/detail', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      // 응답데이터에 hCd, sCd, grCd 추가
      const newListWIthRootSubCd = data.sensorList.map((el: ISensorDetailObject) => {
        return { ...el, hCd: userInfo.hCd, sCd: userInfo.sCd, grCd, editor: userInfo.userId };
      });
      setSensorDetailListTable(arraySortByAscdOrder(newListWIthRootSubCd, 'subCd'));

      //  센서 상세목록 중 기본값으로 선택되어있는 로우의 subCd 값 setState
      //  (센서목록 상세 기본값을 바꾸고 저장할때, 저장시점에 defaultSensorDetail값이 바뀌었다면, 디폴트값 비교해서 기존로우의 플래그를 Y로 변경시키기 위함)
      const selectedRow = data.sensorList.find((el: ISensorDetailObject) => el.sDefault === 'Y');
      if (selectedRow !== undefined) {
        setDefaultSensorDetail(selectedRow.subCd);
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  const getMultiSensorListAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/sensor/ulist', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const newArray = data.sensorUList.map((el: any) => ({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        sDefault: el.sDefault,
        ssCd: el.ssCd,
        subCd: el.subCd,
        sName: el.cdName,
        useYn: el.useYn,
        delYn: el.delYn,
        editor: userInfo.userId,
        uListYn: el.uListYn,
      }));
      setMultiSensorList(newArray);
    }
  };

  const deleteAPI = async (el: ISensorDetailObject, i: number) => {
    if (el.rootSubCd !== undefined) {
      const req = {
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        grCd: el.grCd,
        subCd: el.subCd,
        editor: userInfo.userId,
      };

      const res = await apiDelete({ path: '/sensor/detail', contentType: 'application/json', req });
      const { statusCode, data } = res.data;
      if (statusCode === 200) {
        setOpenModal((prev) => ({ ...prev, status: false }));
        const copyArray = [...sensorDetailListTable];
        const newArray = copyArray.filter((v: ISensorDetailObject, index: number) => index !== i);
        setSensorDetailListTable(newArray);
        await logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '장비 관리 > 센서 관리',
          action: `${selectedSensor.sName} 삭제`,
          etc: `${el.sName}(${el.subCd})`,
        });
      } else {
        // toast.error(t(ERROR));
      }
    }
  };

  const deleteSensorUAPI = async (el: ISensorU) => {
    const reqData = { hCd: el.hCd, sCd: el.sCd, ssCd: el.ssCd, subCd: el.subCd, sDefault: el.sDefault, useYn: el.useYn, delYn: el.delYn, editor: el.editor };
    const req = { sensorUReqDto: [reqData] };
    const res = await apiPost({ path: '/sensor/ulist', req });
    const { statusCode, data, message } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      setOpenModal((prev) => ({ ...prev, status: false }));
      const newArray = data.sensorUList.map(({ ssCd, subCd, cdName, sDefault, useYn, delYn, editor, uListYn }: ISensorU) => ({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        ssCd,
        subCd,
        sName: cdName,
        sDefault,
        useYn,
        delYn,
        editor,
        uListYn,
      }));
      setMultiSensorList(newArray);
    }
  };

  const onClickSaveSensorList = () => {
    const newArray = sensorListTable.map((el: ISensorObject) => {
      return { hCd: userInfo.hCd, sCd: userInfo.sCd, grCd: '000', subCd: el.subCd, sName: el.sName, useYn: el.useYn, writer: userInfo.userId, editor: userInfo.userId };
    });
    saveSensorListAPI(newArray);
  };

  const onClickSaveSensorDetailList = (list: ISensorDetailObject[], setList: Dispatch<SetStateAction<ISensorDetailObject[]>>) => {
    setIsSaveClicked(true);
    const sDefaultYnExist = list.find((el: ISensorDetailObject) => el.sDefault === 'Y');
    if (sDefaultYnExist !== undefined) {
      // 기본값이 Y이면서 동시에 사용유무가 N 인 경우
      const defaultYn = list.find((el: any) => el.sDefault === 'Y' && el.useYn === 'N');
      if (defaultYn !== undefined) {
        toast.warning(t('기본값 항목의 경우 미사용할 수 없습니다'));
      } else {
        const copyArray = [...list];
        // 센서목록 기본값 항목이 새로 추가된 로우로 변경되었을 경우, 기존 항목의 sDefault값이 변경되었으므로, 기존항목의 flag값을 Y로 변경
        const findObject = list.find((el: ISensorDetailObject) => el.sDefault === 'Y');
        if (findObject !== undefined) {
          // 기본값으로 설정되어있는 객체의 rootSubCd가 undefine인 경우(신규로우인 경우)
          if (findObject.rootSubCd === undefined) {
            const oriDefaultIndex = list.findIndex((el: ISensorDetailObject) => el.rootSubCd === defaultSensorDetail);
            if (oriDefaultIndex !== -1) {
              copyArray[oriDefaultIndex] = { ...copyArray[oriDefaultIndex], flag: FLAG_CREATE_OR_UPDATE, editor: userInfo.userId };
              setList(copyArray);
            }
          }
        }
        saveSensorDetailListAPI(copyArray, setList);
      }
    } else {
      toast.warning(t('기본값을 선택하세요.'));
    }
  };

  const onClickSaveSensorUList = (list: ISensorU[], setList: Dispatch<SetStateAction<ISensorU[]>>) => {
    setIsSaveClicked(true);
    const sDefaultYnExist = list.find((el: ISensorU) => el.sDefault === 'Y');
    if (sDefaultYnExist !== undefined) {
      // 기본값이 Y이면서 동시에 사용유무가 N 인 경우
      const defaultYn = list.find((el: any) => el.sDefault === 'Y' && el.useYn === 'N');
      if (defaultYn !== undefined) toast.warning(t('기본값 항목의 경우 미사용할 수 없습니다'));
      else saveSensorUListAPI(list, setList);
    } else {
      toast.warning(t('기본값을 선택하세요.'));
    }
  };

  useEffect(() => {
    if (selectedSensor.grCd !== '') {
      if (selectedSensor.hasSubTab) {
        if (activeTab === MULTI_SENSOR) getMultiSensorListAPI();
        else getSensorDetailAPI(selectedSensor.grCd);
      } else getSensorDetailAPI(selectedSensor.grCd);
    }
  }, [selectedSensor.grCd, activeTab]);

  const onClickSensorRow = (sensorParam: any) => {
    setSelectedSensor({ grCd: sensorParam.subCd, sName: sensorParam.sName, hasSubTab: sensorParam.subTab });
    scrollToNodeTop(scrollContainerRef);
  };

  const backToMain = () => {
    setSelectedSensor({ grCd: '', sName: '', hasSubTab: false });
  };

  const onClickAddSensorRow = (setList: Dispatch<SetStateAction<ISensorDetailObject[]>>) => {
    const data: ISensorDetailObject = {
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      grCd: selectedSensor.grCd,
      subCd: '',
      sName: '',
      sDefault: 'N',
      writer: userInfo.userId,
      editor: userInfo.userId,
      flag: FLAG_CREATE_OR_UPDATE,
      useYn: 'Y',
    };

    setList((prev: ISensorDetailObject[]) => [...prev, data]);
    setAddRowStatus(true);
  };

  const onClickDeleteSensorDetailRow = (el: ISensorDetailObject, i: number) => {
    const tableArray = [...sensorDetailListTable];

    if (el.sDefault === 'N') {
      if (el.rootSubCd !== undefined) {
        // 기존 로우를 삭제할 때 (코드값이 있을때)
        // 삭제 시 에디터아이디 추가
        const data = { ...el, editor: userInfo.userId };
        setOpenModal((prev: any) => ({ ...prev, status: true, type: 'delete', tableArray, setSensorDetailListTable, api: () => deleteAPI(data, i), el: data, index: i }));
      } else {
        // 새로 추가한 로우를 삭제할 때
        tableArray.splice(i, 1);
        setSensorDetailListTable(tableArray);
      }
    } else toast.warning(t('기본 센서장비는 삭제하실 수 없습니다.'));
  };

  const onClickDeleteSensorU = (el: ISensorU, i: number) => {
    const tableArray = [...multiSensorList];
    if (el.sDefault === 'N') {
      if (el.subCd) {
        const data = { ...el, editor: userInfo.userId, delYn: 'Y' };
        setOpenModal((prev: any) => ({ ...prev, status: true, type: 'delete', tableArray, setMultiSensorList, api: () => deleteSensorUAPI(data), data, index: i }));
      } else {
        // COMCD에 새로 추가된 로우를 삭제할 때
        tableArray.splice(i, 1);
        setMultiSensorList(tableArray);
      }
    } else toast.warning(t('기본 센서장비는 삭제하실 수 없습니다.'));
  };

  // 센서목록 상세 로우 수정
  const onChangeSensorDetail = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    const { name, value } = e.currentTarget;
    const tableArray = [...sensorDetailListTable];
    let insertValue = value;

    if (name === 'subCd') insertValue = limitDigits(value); //  input name이 subCd인 경우 입력 자리수 제한
    tableArray[i] = {
      ...tableArray[i],
      [name]: insertValue,
      flag: FLAG_CREATE_OR_UPDATE,
      editor: userInfo.userId,
    };

    setSensorDetailListTable(tableArray);
  };

  // 센서목록 라디오 옵션 수정
  const onChangeRadioOption = (i: number, rootSubCd: string | undefined, list: any[], setList: Dispatch<SetStateAction<any[]>>) => {
    const copyArray = [...list];

    if (rootSubCd !== undefined) {
      // 선택된 객체의 sDefault값을 Y로 업데이트하고,
      copyArray[i] = { ...copyArray[i], sDefault: 'Y', flag: FLAG_CREATE_OR_UPDATE, editor: userInfo.userId };
      // 기존에 기본선택 되어있던 객체를 찾아서 sDefault값을 N으로, flag값을 Y로 업데이트
      const findIndex = list.findIndex((el: ISensorDetailObject) => el.sDefault === 'Y');
      if (findIndex !== -1) {
        copyArray[findIndex] = { ...list[findIndex], sDefault: 'N', flag: FLAG_CREATE_OR_UPDATE, editor: userInfo.userId };
      }

      setList(copyArray);
    } else {
      // 선택된 객체의 sDefault값을 Y로 업데이트하고,
      copyArray[i] = { ...copyArray[i], sDefault: 'Y', flag: FLAG_CREATE_OR_UPDATE, editor: userInfo.userId };

      // 새로 추가한 로우들 중 기본값으로 선택한 로우를 제외하고 나머지 새로우들의 sDefault값을 N으로 업데이트
      const newArray = copyArray.map((el: ISensorDetailObject, index: number) => {
        if (index !== i) return { ...el, sDefault: 'N' };
        return { ...el, sDefault: 'Y' };
      });
      setList(newArray);
    }
  };

  const renderCreateBtn = () => {
    if (auth.createAuth) {
      if (selectedSensor.hasSubTab && activeTab === MULTI_SENSOR) {
        return <BtnBlue onClick={() => onClickSaveSensorUList(multiSensorList, setMultiSensorList)}>{t('저장')}</BtnBlue>;
      }
      return (
        <>
          <BtnBlue onClick={() => onClickAddSensorRow(setSensorDetailListTable)}>{t('신규항목 추가')}</BtnBlue>
          <BtnBlue onClick={() => onClickSaveSensorDetailList(sensorDetailListTable, setSensorDetailListTable)}>{t('저장')}</BtnBlue>
        </>
      );
    }
    return null;
  };

  const renderSaveBtn = () => {
    if (auth.updateAuth) {
      return (
        <div className='buttonsWrapper'>
          <BtnBlue onClick={onClickSaveSensorList}>{t('저장')}</BtnBlue>
        </div>
      );
    }
    return null;
  };

  const renderDeleteBtn = (el: any, i: number) => {
    if (auth.deleteAuth) {
      return <BtnRed onClick={() => onClickDeleteSensorDetailRow(el, i)}>{el.rootSubCd !== undefined ? t('삭제') : t('제거')}</BtnRed>;
    }
    if (el.rootSubCd === undefined && (auth.createAuth || auth.updateAuth)) {
      return <BtnRed onClick={() => onClickDeleteSensorDetailRow(el, i)}>{t('제거')}</BtnRed>;
    }
    return null;
  };

  return (
    <div className='content-container twoColumn column-55'>
      {(size.innerSize.W > 1024 || selectedSensor.grCd === '') && (
        <Root>
          <div className='inputFormsWrapper'>
            <div className='formTitle'>{t('센서 목록')}</div>
          </div>
          <InputTable className='margin-left-05'>
            <div className='thead'>
              <div className='tr'>
                <div className='trCol2p5 flex-center tableStickyNo'>No</div>
                <div className='trCol8 flex-center content-overflow'>{t('센서 구분')}</div>
                <div className='trCol8 flex-center'>{t('사용유무')}</div>
              </div>
            </div>
            {/* </div> */}
            <div className='table'>
              <div className='tbody'>
                {sensorListTable?.length > 0 ? (
                  sensorListTable.map((el: ISensorObject, i: number) => (
                    <div className={`tr ${selectedSensor.grCd === el.subCd && 'active'}`} role='button' tabIndex={0} key={`sensor_${i}_${el.subCd}`} onClick={() => onClickSensorRow(el)}>
                      <div className='trCol2p5 flex-center tableStickyNo'>{i + 1}</div>
                      <div className='trCol8 flex-basic content-overflow'>{el.sName}</div>
                      <div className='trCol8 flex-center'>
                        <SelectBoxs
                          options={USE_YN}
                          defaultOption={el.useYn === 'Y' ? t('사용') : t('미사용')}
                          state={sensorListTable}
                          setState={setSensorListTable}
                          rawData={sensorListTable}
                          setRawData={setSensorListTable}
                          stateKey='useYn'
                          codeKey='cdName'
                          index={i}
                          object={el}
                          primaryKey='subCd'
                          width='80%'
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='noData'>No data.</div>
                )}
              </div>
            </div>
          </InputTable>
          <ButtonsWrapper>{renderSaveBtn()}</ButtonsWrapper>
        </Root>
      )}
      {(size.innerSize.W >= 1024 || selectedSensor.grCd !== '') &&
        (selectedSensor.grCd !== '' ? (
          <Root>
            {selectedSensor.hasSubTab ? (
              <div className='inputFormsWrapper smallTab'>
                {size.innerSize.W < 1024 && <BackButton func={() => backToMain()} />}
                <div className={`tab ${activeTab === MULTI_SENSOR ? `activeTab` : undefined}`} role='button' tabIndex={0} onClick={() => setActiveTab(MULTI_SENSOR)}>
                  {t('다중 센서 사용 목록')}
                </div>
                <div className={`tab ${activeTab === NORMAL_SENSOR ? `activeTab` : undefined}`} role='button' tabIndex={0} onClick={() => setActiveTab(NORMAL_SENSOR)}>
                  {t('센서 목록')}
                </div>
              </div>
            ) : (
              <div className='inputFormsWrapper'>
                <div className='formTitle titleDetail'>
                  {t('센서 목록 상세')}
                  <span>{selectedSensor.sName}</span>
                </div>
              </div>
            )}
            {selectedSensor.hasSubTab && activeTab === MULTI_SENSOR ? (
              <InputTable className='margin-left-05'>
                <div>
                  <div className='thead'>
                    <div className='tr'>
                      <div className='trCol2p5 flex-center tableStickyNo'>No</div>
                      <div className='trCol4 flex-center required'>{t('기본값')}</div>
                      <div className='trCol6 flex-center'>{t('코드')}</div>
                      <div className='trCol6 flex-center content-overflow'>{t('센서명')}</div>
                      <div className='trCol8 flex-center'>{t('사용유무')}</div>
                      <div className='trCol4'> </div>
                    </div>
                  </div>
                  <div className='table'>
                    <div className='tbody withInput'>
                      {multiSensorList.map((el: ISensorU, i: number) => (
                        <div className='tr' role='button' tabIndex={0} key={`sensor_${el.ssCd}`}>
                          <div className='trCol2p5 flex-center tableStickyNo'>{i + 1}</div>
                          <div className='trCol4 flex-center'>
                            <input
                              type='radio'
                              name='sDefault'
                              checked={el.sDefault === 'Y'}
                              disabled={el.useYn === 'N'}
                              onChange={(e) => onChangeRadioOption(i, el.ssCd, multiSensorList, setMultiSensorList)}
                            />
                          </div>
                          <div className='trCol6 flex-center text-center'>{el.ssCd}</div>
                          <div className='trCol6 flex-basic content-overflow'>{el.sName}</div>
                          <div className='trCol8 flex-center'>
                            <SelectBoxs
                              options={USE_YN}
                              defaultOption={el.useYn === 'Y' ? t('사용') : t('미사용')}
                              state={multiSensorList}
                              setState={setMultiSensorList}
                              rawData={multiSensorList}
                              setRawData={setMultiSensorList}
                              stateKey='useYn'
                              codeKey='cdName'
                              index={i}
                              object={el}
                              primaryKey='ssCd'
                              width='80%'
                            />
                          </div>
                          {el.uListYn === 'Y' ? (
                            <div className='trCol4 flex-center'>{auth.deleteAuth && <BtnRed onClick={() => onClickDeleteSensorU(el, i)}>{el.subCd ? '삭제' : '제거'}</BtnRed>}</div>
                          ) : (
                            <div className='trCol4 flex-center'> </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </InputTable>
            ) : (
              <InputTable className='margin-left-05'>
                <div ref={scrollContainerRef}>
                  <div className='thead'>
                    <div className='tr'>
                      <div className='trCol2p5 flex-center tableStickyNo'>No</div>
                      <div className='trCol4 flex-center'>{t('기본값')}</div>
                      <div className='trCol6 flex-center required'>{t('센서 번호')}</div>
                      <div className='trCol6 flex-center required content-overflow'>{t('센서장비 명')}</div>
                      <div className='trCol4'> </div>
                    </div>
                  </div>
                  <div className='table'>
                    <div className='tbody withInput'>
                      {sensorDetailListTable.map((el: ISensorDetailObject, i: number) => (
                        <div className='tr' role='button' tabIndex={0} key={`sensor_${i}`}>
                          <div className='trCol2p5 flex-center tableStickyNo'>{i + 1}</div>
                          <div className='trCol4 flex-center'>
                            <input type='radio' name='sDefault' checked={el.sDefault === 'Y'} onChange={(e) => onChangeRadioOption(i, el.rootSubCd, sensorDetailListTable, setSensorDetailListTable)} />
                          </div>
                          <div className='trCol6 flex-center text-center'>
                            <input
                              ref={(refEl) => {
                                sensorDetailListTableRefs.current[i] = refEl;
                              }}
                              type='number'
                              id='subCd'
                              name='subCd'
                              value={el.subCd}
                              onChange={(e) => onChangeSensorDetail(e, i)}
                              style={isSaveClicked ? applyBorderStyle(el.subCd, 'red', 'subCd') : undefined}
                            />
                          </div>
                          <div className='trCol6 flex-center content-overflow'>
                            <input
                              type='text'
                              id='sName'
                              name='sName'
                              value={el.sName}
                              onChange={(e) => onChangeSensorDetail(e, i)}
                              style={isSaveClicked ? applyBorderStyle(el.sName, 'red', 'sName') : undefined}
                            />
                          </div>
                          <div className='trCol4 flex-center'>{renderDeleteBtn(el, i)}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </InputTable>
            )}
            <ButtonsWrapper>{renderCreateBtn()}</ButtonsWrapper>
          </Root>
        ) : (
          <Root>
            <div className='emptyData'>
              <img src={illustrator} alt='noData' />
              <span>{t('왼쪽 목록에서 센서를 선택해주세요')}</span>
            </div>
          </Root>
        ))}
      <Portal openModal={openModal?.status}>{openModal && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
    </div>
  );
};

export default SensorSetting;
