/**
 * 작성자 : 김광민
 * 날짜 : 2023.12.19
 * 경로 : 터널 관리 - 구역 현황 관리 - 차트 모달
 */

import { useState } from 'react';
import styled from 'styled-components';

const ProgressBar = styled.div<{ progress: number; timeOut: number }>`
  display: flex;
  flex-grow: 1;
  border-radius: 3rem;
  height: 2rem;
  position: relative;
  overflow: hidden;
  &.start {
    flex-direction: row-reverse;
  }
  &.level1 {
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
  }
  &.level2 {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
  }
  &.level3 {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_violet};
  }
  &.level4 {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
  }
  &.level5 {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
  }
  > .progressbar {
    width: 0%;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.25rem;
    gap: 0.375rem;
    transition: width 500ms ease-in-out;
    &.progressValue {
      width: ${(props) => props.progress}%;
      transition-delay: ${(props) => props.timeOut * 50}ms;
    }
    &.start {
      flex-direction: row-reverse;
    }
    &.level0 {
      background-color: transparent !important;
    }
    &.level1 {
      background-color: ${({ theme }: { theme: any }) => theme.filled_slate};
    }
    &.level2 {
      background-color: ${({ theme }: { theme: any }) => theme.filled_amber};
    }
    &.level3 {
      background-color: ${({ theme }: { theme: any }) => theme.filled_indigo};
    }
    &.level4 {
      background-color: ${({ theme }: { theme: any }) => theme.filled_green};
    }
    &.level5 {
      background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
    }
  }
  .text,
  .symbol,
  .meter,
  .percentage {
    display: flex;
    align-items: center;
    height: 1.5rem;
    font-size: 0.875rem;
    border-radius: 1rem;
    padding: 0 0.5rem;
    font-weight: 400;
  }
  .text,
  .symbol,
  .meter {
    color: ${({ theme }: { theme: any }) => theme.text_reverse};
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    &.level1 {
      background-color: ${({ theme }: { theme: any }) => theme.filled_slate_deep};
    }
    &.level2 {
      background-color: ${({ theme }: { theme: any }) => theme.filled_amber_deep};
    }
    &.level3 {
      background-color: ${({ theme }: { theme: any }) => theme.filled_indigo_deep};
    }
    &.level4 {
      background-color: ${({ theme }: { theme: any }) => theme.filled_green_deep};
    }
    &.level5 {
      background-color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
    }
    .emphasize {
      color: ${({ theme }: { theme: any }) => theme.text_reverse};
    }
  }
  .percentage {
    background-color: ${({ theme }: { theme: any }) => theme.board};
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
    .emphasize {
      font-weight: 600;
    }
  }
  .text {
    font-size: 0.75rem;
    word-break: keep-all;
  }
  .emphasize {
    font-weight: 500;
  }
  &.reverse {
    flex-direction: row-reverse;
    &.start {
      flex-direction: row;
    }
    > .progressbar {
      flex-direction: row-reverse;
      &.start {
        flex-direction: row;
      }
    }
  }
`;

type Props = { rlength: number; length: number; pointType: 'start' | 'end'; showOption: string; timeOut: number; pointView?: boolean; isTunnelReversed: boolean };

const TunnelChartProgressBar = ({ rlength, length, pointType, showOption, timeOut, pointView = false, isTunnelReversed }: Props) => {
  // 진행률 구하는 함수
  const getPercentage = ({ length: lengthValue, rlength: rlengthValue }: { length: number; rlength: number }) => {
    if (isNaN(lengthValue) || isNaN(rlengthValue) || lengthValue === 0) {
      return 0;
    }
    return Math.floor((rlengthValue / Math.max(lengthValue, 1)) * 100);
  };

  // 차트 애니메이션
  const [animate, setAnimate] = useState('');
  const percentage = isNaN(length) || isNaN(rlength) || length === 0 ? 0 : getPercentage({ rlength, length });
  const direction = isTunnelReversed ? 'reverse' : '';

  if (isNaN(percentage)) {
    // percentage가 NaN이면 렌더링하지 않습니다.
    return null;
  }

  const getColor = () => {
    if (percentage > 20 && percentage <= 40) {
      return 'level2';
    }
    if (percentage > 40 && percentage <= 60) {
      return 'level3';
    }
    if (percentage > 60 && percentage <= 80) {
      return 'level4';
    }
    if (percentage > 80 && percentage <= 100) {
      return 'level5';
    }
    return 'level1';
  };

  const getAnimate = () => {
    setTimeout(() => {
      setAnimate('progressValue');
    }, timeOut * 10);
  };

  if (showOption === 'N') {
    return null;
  }

  if (showOption === 'Y') {
    getAnimate();
  }

  const renderOption = () => {
    if (!pointView) {
      return (
        <div className={`meter ${getColor()} ${pointType}`}>
          <span className='emphasize'>{rlength.toLocaleString()}</span>m
        </div>
      );
    }
    return <div className={`text ${getColor()} ${pointType}`}>{pointType === 'start' ? '시점' : '종점'}</div>;
  };

  return (
    <ProgressBar className={`${getColor()} ${pointType} ${direction}`} progress={percentage} timeOut={timeOut}>
      <div className={`progressbar ${getColor()} ${pointType} ${percentage === 0 ? 'level0' : ''} ${animate !== '' ? animate : ''}`}>
        {renderOption()}
        <div className={`percentage ${pointType}`}>
          <span className='emphasize'>{getPercentage({ rlength, length })}</span>%
        </div>
      </div>
    </ProgressBar>
  );
};

export default TunnelChartProgressBar;
