import { t } from 'i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import TuiGrid from './Table/TuiGrid';

const TuiGridInvisible = styled.div`
  display: none;
`;

const InvisibleTuiGrid = ({ data: tAreaGrid, excelBtn, setExcelBtn }: { data: TareaData[]; excelBtn: boolean; setExcelBtn: Dispatch<SetStateAction<boolean>> }) => {
  const [tableState, setTableState] = useState<any>([]);
  const columns = [
    {
      header: t('구역 명'),
      name: 'tatName',
    },
    {
      header: t('총 공사길이'),
      name: 'tLength',
    },
    {
      header: t('시점 총 공사 길이'),
      name: 'sLength',
    },
    {
      header: t('시점 현재 공사 길이'),
      name: 'sRLength',
    },
    {
      header: t('시점 작업 내용'),
      name: 'sTjcName',
    },
    {
      header: t('시점 장비 상태'),
      name: 'sTesName',
    },
    {
      header: t('시점 비고'),
      name: 'sBigo',
    },
    {
      header: t('종점 총 공사 길이'),
      name: 'eLength',
    },
    {
      header: t('종점 현재 공사 길이'),
      name: 'eRLength',
    },
    {
      header: t('종점 작업 내용'),
      name: 'eTjcName',
    },
    {
      header: t('종점 장비 상태'),
      name: 'eTesName',
    },
    {
      header: t('종점 비고'),
      name: 'eBigo',
    },
    {
      header: t('구역 비고'),
      name: 'bigo',
    },
    {
      header: t('사용유무'),
      name: 'useYn',
    },
  ];

  // tableState date 가공
  useEffect(() => {
    const result = tAreaGrid.map((tArea) => {
      const { tatName, tLength, sLength, sRLength, sTjcName, sTesName, sBigo, eLength, eRLength, eTjcName, eTesName, eBigo, bigo, useYn } = tArea;
      return { tatName, tLength, sLength, sRLength, sTjcName, sTesName, sBigo, eLength, eRLength, eTjcName, eTesName, eBigo, bigo, useYn };
    });
    setTableState([...result]);
  }, [tAreaGrid]);

  return (
    <TuiGridInvisible>
      <TuiGrid data={tableState} columns={columns} excelBtn={excelBtn} setExcelBtn={setExcelBtn} usePagenation scrollX rowHeight={48} />
    </TuiGridInvisible>
  );
};

export default InvisibleTuiGrid;
