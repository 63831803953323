import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useGetFrozenCount } from '../utils/getFrozenCount';
import TuiGrid from './Table/TuiGrid';
import TuiGridWrapper from './Table/TuiGridWrapper';

type Props = {
  tableState: TableState[];
  columns: Columns[];
  listCount: number;
  setExcelBtn?: Dispatch<SetStateAction<boolean>>;
  excelBtn?: boolean;
  onClickRow?: (rowData: any) => Promise<void>;
  fileName?: string;
};

type TableState = { dvName: string; dvNo: string; checkTime: string; avgMq: number; minMq: string };
type Columns = { align: string; filter: string; header: string; minWidth: number; name: string; sortable: boolean };

const TuiGridTable: React.FC<Props> = ({ tableState, columns, listCount, setExcelBtn, excelBtn, onClickRow, fileName }) => {
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  const componentRef = useRef<HTMLDivElement>(null);
  const frozenCount = useGetFrozenCount();

  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight]);

  return (
    <TuiGridWrapper componentRef={componentRef}>
      {tableState.length !== 0 && componentRef.current && (
        <TuiGrid
          data={tableState}
          columns={columns}
          perPage={listCount}
          excelBtn={excelBtn}
          setExcelBtn={setExcelBtn}
          usePagenation
          onClickRow={onClickRow}
          scrollX
          height={tuiHeight}
          frozenCount={frozenCount}
          fileName={fileName}
        />
      )}
    </TuiGridWrapper>
  );
};

export default TuiGridTable;
