/**
 * 작성자 : 김광민
 * 날짜 : 2024.05.02
 * 기능 : 태블릿 안전교육 관리 화면에서 공통으로 사용되는 에디터 컴포넌트
 */

import styled from 'styled-components';
import { BtnBlue, BtnRed, InitBtn } from '../../../components/Button';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ButtonContainer from '../../s_cctv/S_cctv1/RealtimeCCTV/ButtonContainer';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { useTranslation } from 'react-i18next';
import { IAuth } from 'customTypes';
import RadioContainer from '../../../components/button/RadioContainer';
import { usePostTabletTemplete } from '../../../hooks/useTablet';
import Portal from '../../../components/Portal';
import YesOrNoConfirm from '../../../components/Modal/YesOrNo';
import InfoTextWithIcon from '../../../components/Modal/InfoTextWithIcon';
import JobTypeModal from './JobTypeModal';
import { ckeditorConfig } from '../../../services/ckeditorConfig';
import { toast } from 'react-toastify';
import BigButtonContainer from '../../../components/button/BigButtonContainer';
import SelectJobtypeBar from './SelectJobtypeBar';
import SearchSelectBoxSm from '../../../components/SearchSelectBoxSm';
import { useFetchCommonCodeList } from '../../../services/useSetCodeListInSelectBoxForm';
import { COMCD_STEMPLET_GUBUN } from '../../../_constants';
import HtmlPreviewModal from './HtmlPreviewModal';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  padding: 0.5rem;
  bottom: 0;
  position: relative;
  height: 7.5rem;
  div {
    display: flex;
    gap: 0.5rem;
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    }
    button {
      height: 2.5rem;
      font-size: 0.875rem;
    }
  }
`;

const Root = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow: hidden;
  justify-content: flex-start;
  .header {
    padding: 0.75rem;
    height: 3rem;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    display: flex;
    gap: 0.5rem;
    &:nth-child(n + 2) {
      border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    }
    ul > li {
      min-width: 15rem !important;
    }
    .title {
      flex-shrink: 0;
      width: fit-content;
      min-width: 4rem;
      font-weight: 600;
      margin-right: 0.5rem;
    }
  }
  .editor {
    height: 30vh;
    flex-grow: 1;
    border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
    overflow: auto;
    padding: 0.5rem;
    .ck-editor {
      height: 100%;
      display: flex;
      flex-direction: column;
      .ck-editor__main {
        flex-grow: 1;
        overflow: auto;
        display: flex;
        flex-direction: column;
        .ck-source-editing-area {
          height: 5rem;
          flex-grow: 1;
          .ck-source-editing-area textarea {
            overflow: auto;
          }
        }
      }
    }
    .ck-content {
      min-height: 10rem;
      height: 100%;
      overflow: auto;
    }
    textarea {
      overflow: auto;
    }
  }
  .tagContainer {
    user-select: none;
    background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
    padding: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.2rem 0.5rem;
    width: 100%;
    height: 8rem;
    overflow: auto;
  }
  .seperator {
    margin: 0.5rem 0;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    div {
      height: 1px;
      flex-grow: 1;
      background-color: rgba(0, 0, 0, 0.1);
    }
    span {
      border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    }
  }

  .inputForm {
    border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem;
    width: 100%;
    height: 3.5rem;
    background-color: transparent;
    > div {
      justify-content: space-between;
      height: inherit;
    }
    button {
      height: 2.5rem;
      font-size: 0.75rem;
      flex-shrink: 0;
      padding: 0 0.75rem;
      border-radius: 0.25rem;
    }
    label {
      width: 10rem;
      font-weight: 500;
      flex-shrink: 0;
      font-size: 0.875rem;
    }
    .inputForm-row.labelOutInput {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
    }
  }
  .scrollBox {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    height: 100%;
    flex-grow: 1;
  }
`;

interface Props {
  rawData: TabletTemplate[];
  filteredData: TabletTemplate[];
  auth: IAuth;
  tagOptions: { signName: string; signTag: string }[];
  selectedIndex: number | null;
  setSelectedIndex: Dispatch<SetStateAction<number | null>>;
  refetch: () => void;
  tGubun: 'C' | 'J';
  setWarning: Dispatch<SetStateAction<boolean>>;
  template: { type: string; [COMCD_STEMPLET_GUBUN]: string; cdName: string };
  setTemplate: Dispatch<SetStateAction<{ type: string; [COMCD_STEMPLET_GUBUN]: string; cdName: string }>>;
}

const TabletEduEditor = ({ rawData, filteredData, auth, tagOptions, selectedIndex, setSelectedIndex, refetch, tGubun, setWarning, template, setTemplate }: Props) => {
  const { t } = useTranslation();
  const INIT_TEMPLATE = { type: COMCD_STEMPLET_GUBUN, [COMCD_STEMPLET_GUBUN]: '', cdName: '미선택' };
  const [tName, setTName] = useState<string>('');
  const [tContents, setTContents] = useState<string>('');
  const [useYn, setUseYn] = useState<string>('N');
  const [jobtypeIdx, setJobtypeIdx] = useState<string>('');
  const [openModal, setOpenModal] = useState<{ status: boolean; type: string }>({
    status: false,
    type: 'confirm',
  });
  const readOnly = false;
  const [ckEditor, setCkEditor] = useState<any>(null);
  const { data: STEMPLETComCdList } = useFetchCommonCodeList(COMCD_STEMPLET_GUBUN, false, 'etc1', tGubun); // 스마트안전교육 양식지 공통코드 목록

  const postTabletTemplete = usePostTabletTemplete();

  // 목록에서 선택한 행을 form data에 적용
  useEffect(() => {
    onClickInit();
    setWarning(false);
  }, [selectedIndex, filteredData]);

  // 양식지 저장 버튼 활성화 여부 체크
  useEffect(() => {
    setWarning(!isDisabledInit());
  }, [tName, tContents, useYn, template[COMCD_STEMPLET_GUBUN]]);

  // 직종값이 변경된 상태에서 사용유무 토글 변경 시 알림창 노출
  useEffect(() => {
    if (jobtypeIdx && tGubun === 'J') setWarning(!isDisabledInit());
  }, [jobtypeIdx]);

  // 태그 삽입 함수
  const insertTag = (tagName: string) => {
    if (ckEditor) {
      const { model } = ckEditor;
      const { selection } = model.document;
      if (selection && selection.focus) {
        model.change((writer: any) => {
          const position = selection.getFirstPosition();
          writer.insertText(`${tagName}`, position);
        });
      } else {
        const root = model.document.getRoot();
        const lastPosition = root.getChild(root.childCount - 1);
        model.change((writer: any) => {
          writer.insertText(`${tagName}`, lastPosition, 'end');
        });
      }
      ckEditor.editing.view.focus();
    }
  };

  // 양식지 내용 수정 함수
  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setTName(value);
  };

  // 양식지 초기화 함수
  const onClickInit = async () => {
    if (selectedIndex !== null && selectedIndex !== undefined && filteredData[selectedIndex]) {
      setTName(filteredData[selectedIndex].tName);
      setTContents(filteredData[selectedIndex].tContents);
      setUseYn(filteredData[selectedIndex].useYn);
      setJobtypeIdx(filteredData[selectedIndex].jobtypeIdx);
      setTemplate({
        type: COMCD_STEMPLET_GUBUN,
        [COMCD_STEMPLET_GUBUN]: filteredData[selectedIndex].tGubun,
        cdName: STEMPLETComCdList?.find((el: any) => el[COMCD_STEMPLET_GUBUN] === filteredData[selectedIndex].tGubun)?.cdName || '',
      });
    } else {
      setTName('');
      setTContents('');
      setUseYn('N');
      setJobtypeIdx('');
      setTemplate(INIT_TEMPLATE);
      setSelectedIndex(null);
    }
  };

  // 양식지 삭제 버튼 클릭 함수
  const onClickDelete = async () => {
    if (selectedIndex === null) {
      setTName('');
      setTContents('');
      setUseYn('N');
      setJobtypeIdx('');
    } else {
      const requestData = filteredData[selectedIndex];
      const res = await postTabletTemplete({ ...requestData, delYn: 'Y' });
      if (res.statusCode === 200) {
        toast.success(t(res.message));
        setSelectedIndex(null);
        refetch();
      }
    }
  };

  // 양식지 저장 비동기 함수
  const updateTabletTemplete = async (el: TabletTemplate) => {
    const res = await postTabletTemplete(el);
    if (res.statusCode === 200) {
      // toast.success(t(res.message));
      refetch();
    }
  };

  // 양식지 저장 버튼 클릭 함수
  const onClickSave = async () => {
    if (!template[COMCD_STEMPLET_GUBUN]) {
      toast.warn(t('양식지 구분을 선택하세요'));
      return;
    }
    if (!tName) {
      toast.warn(t('양식지 명을 작성하세요'));
      return;
    }
    if (tGubun === 'J' && !jobtypeIdx) {
      toast.warn(t('직종을 선택하세요'));
      return;
    }
    if (!tContents || tContents === '<p>&nbsp;</p>') {
      toast.warn(t('양식지 내용을 작성하세요'));
      return;
    }

    // 수정중인 기존 양식지 또는 신규 작성중인 양식지의 사용유무가 'Y'인 경우
    // 다른 양식지의 사용유무를 'N'으로 변경
    if (useYn === 'Y') {
      // 같은종류의 양식지만 필터링
      const filterData = rawData.filter((item) => item.tGubun === template[COMCD_STEMPLET_GUBUN]);
      filterData.map((el) => {
        // 새 양식지에서 양식지의 사용유무가 'Y'인 경우
        selectedIndex === null && //
          el.useYn === 'Y' &&
          updateTabletTemplete({ ...el, useYn: 'N' });
        // 기존 양식지에서 수정중인 양식양식지의 사용유무가 'Y'인 경우
        selectedIndex !== null && //
          filteredData[selectedIndex].tNum &&
          filteredData[selectedIndex].tNum !== el.tNum &&
          el.useYn === 'Y' &&
          updateTabletTemplete({ ...el, useYn: 'N' });
      });
    }

    // 1. 현재 작성중인 양식 객체화
    const init = {
      tGubun: template[COMCD_STEMPLET_GUBUN],
      tName,
      tContents,
      useYn,
      delYn: 'N',
      jobtypeIdx,
    };

    // 2. 새 양식지인지 기존 양식지인지 구분해서 데이터 가공
    const formData =
      selectedIndex === null
        ? init
        : {
            ...init, //
            jobtypeIdx,
            tNum: filteredData[selectedIndex].tNum,
            writer: filteredData[selectedIndex].writer,
          };
    // 3. 양식지 저장 함수 호출 및 목록 새로고침
    const res = await postTabletTemplete(formData);
    if (res.statusCode === 200) {
      toast.success(t(res.message));
      refetch();
    }
  };

  const isDisabledInit = () => {
    if (selectedIndex !== null && filteredData[selectedIndex] && tGubun !== 'J') {
      return (
        tName === filteredData[selectedIndex].tName && //
        tContents === filteredData[selectedIndex].tContents &&
        useYn === filteredData[selectedIndex].useYn &&
        template[COMCD_STEMPLET_GUBUN] === filteredData[selectedIndex].tGubun
      );
    }
    if (selectedIndex !== null && filteredData[selectedIndex] && tGubun === 'J') {
      return (
        tName === filteredData[selectedIndex].tName && //
        tContents === filteredData[selectedIndex].tContents &&
        useYn === filteredData[selectedIndex].useYn &&
        jobtypeIdx === filteredData[selectedIndex].jobtypeIdx &&
        template[COMCD_STEMPLET_GUBUN] === filteredData[selectedIndex].tGubun
      );
    }
    if (tGubun === 'J') {
      return tName === '' && tContents === '<p>&nbsp;</p>' && jobtypeIdx === '';
    }

    return tName === '' && tContents === '<p>&nbsp;</p>';
  };

  const onClickJobtypeSelectModal = () => {
    setOpenModal({ status: true, type: 'jobtype' });
  };

  const onHtmlBtn = () => {
    window.open('https://products.aspose.app/cells/ko/conversion/excel-to-html');
  };

  const onClickEditorPreview = () => {
    setOpenModal({ status: true, type: 'editorPreview' });
  };

  const renderEditor = () => (
    <CKEditor
      key={selectedIndex}
      config={ckeditorConfig as any}
      editor={Editor as any}
      data={tContents}
      onReady={(editor: any) => {
        setCkEditor(editor);
        const toolbarElement = editor.ui.view.toolbar.element;
        if (readOnly) {
          editor.enableReadOnlyMode(editor.id);
          toolbarElement.style.display = 'none';
        } else {
          editor.disableReadOnlyMode(editor.id);
          toolbarElement.style.display = 'flex';
        }
      }}
      onChange={(event: any, editor: any) => {
        const contentData = editor.getData();
        setTContents(contentData);
      }}
    />
  );

  return (
    <Root>
      <div className='header flex-between'>
        <span className='title'>{t('양식지 명')}</span>
        <input
          type='text' //
          id='signName'
          name='signListName'
          value={tName}
          onChange={(e) => onChangeName(e)}
          disabled={!auth.createAuth && !auth.updateAuth}
          maxLength={50}
        />
        <RadioContainer
          name={t('사용')} //
          onClick={() => setUseYn('Y')}
          isSelected={useYn === 'Y'}
        />
        <RadioContainer
          name={t('미사용')} //
          onClick={() => setUseYn('N')}
          isSelected={useYn === 'N'}
        />
      </div>
      <div className='header flex-start'>
        <span className='title'>{t('양식지 구분')}</span>
        <div className='inputForm-row withLabel'>
          <SearchSelectBoxSm
            options={STEMPLETComCdList}
            defaultOption={t('미선택')}
            state={template}
            setState={setTemplate}
            stateKey={COMCD_STEMPLET_GUBUN}
            codeKey='cdName'
            initiateKey={template[COMCD_STEMPLET_GUBUN]}
            filterbar='filter-1-right'
            comboWidth='expand-box-md'
            optionHeight='height-md'
          />
        </div>
      </div>
      {tGubun === 'J' && (
        <SelectJobtypeBar //
          jobtypeIdx={jobtypeIdx}
          setJobtypeIdx={setJobtypeIdx}
          onClickJobtypeSelectModal={onClickJobtypeSelectModal}
        />
      )}
      <InfoTextWithIcon
        icon='flash_on' //
        text={t('엑셀과 워드 문서 복사 / 붙여넣기 가능합니다.')}
      />
      <div className='editor'>{renderEditor()}</div>
      <div className='tagContainer'>
        {tagOptions?.map((tag) => (
          <ButtonContainer
            key={tag.signTag}
            name={tag.signName}
            onClick={() => {
              insertTag(tag.signTag);
            }}
          />
        ))}
      </div>
      <ButtonsWrapper>
        <div>
          {selectedIndex !== null &&
            auth.deleteAuth && ( //
              <BtnRed onClick={() => setOpenModal({ status: true, type: 'confirm' })}>{t('삭제')}</BtnRed>
            )}
          <div className='flex-flex'>
            {/* <BtnGhost onClick={() => onHtmlBtn()}>html convert</BtnGhost> */}
            <BigButtonContainer
              icon='preview' //
              name='에디터 미리보기'
              onClick={() => onClickEditorPreview()}
              disabled={tContents?.trim() === '' || tContents === '<p>&nbsp;</p>'}
            />
            <BigButtonContainer
              icon='open_in_new' //
              name='HTML 변환기'
              onClick={() => onHtmlBtn()}
            />
          </div>
        </div>
        <div className={isDisabledInit() ? 'disabled' : ''}>
          <InitBtn
            onClick={onClickInit} //
          >
            {t('초기화')}
          </InitBtn>
          {(auth.createAuth || auth.updateAuth) && ( //
            <BtnBlue onClick={onClickSave}>{t('저장')}</BtnBlue>
          )}
        </div>
      </ButtonsWrapper>
      <Portal openModal={openModal?.status}>
        {openModal.type === 'jobtype' && (
          <JobTypeModal
            setSelectedProps={setJobtypeIdx} //
            setOpenModal={setOpenModal}
            selectedProps={jobtypeIdx}
          />
        )}
        {openModal.type === 'editorPreview' && <HtmlPreviewModal setOpenModal={setOpenModal} htmlSource={tContents} />}
        {openModal.type === 'confirm' && (
          <YesOrNoConfirm
            mainText={t('양식지를 정말 삭제하시겠습니까?')}
            onClickClose={() => setOpenModal({ status: false, type: 'confirm' })} //
            onClickConfirm={() => {
              onClickDelete();
              setOpenModal({ status: false, type: 'confirm' });
            }}
          />
        )}
      </Portal>
    </Root>
  );
};

export default TabletEduEditor;
