/**
 * 작성자 : 홍선영
 * 날짜 : 2024.02.29
 * 기능 : 데이터패치 후 객체를 셀렉트박스 형식에 맞게 변환 한 뒤 데이터 및 useQuery함수들을 리턴하는 hook
 */

import { useRecoilValue } from 'recoil';
import { useQuery } from '@tanstack/react-query';

import { IComCdList } from 'customTypes';
import { INIT_CCD_A, INIT_EQUIP_A, INIT_NCD_A, INIT_TATCD_A, INIT_TES_A, INIT_TJC_A } from '../_constants';
import { IUser, userState } from '../atoms';
import { apiGet } from './_common';
import { arraySortByAscdOrder } from '../utils/arraySortByAscdOrder';
import { useFetchCctvList } from './useFetchCctvList';

export const useFetchEquipCodeList = () => {
  const userInfo = useRecoilValue<IUser>(userState);
  const queryKey = ['sensorUnpersonGet', userInfo.hCd, userInfo.sCd];

  const { data, isError, isLoading, refetch } = useQuery(queryKey, () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd, // hCd 및 sCd가 유효한 경우에만 쿼리 사용
  });

  // 장비 데이터 fetch 함수
  const fetchData = async () => {
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, subCd: 'I' }; // 사용자 정보 및 subCd 필터로 요청 개체 준비
      const res = await apiGet({ path: '/code/pmsNormalTitle', req }); // 데이터를 검색하기 위해 API 호출

      // 응답에서 장비 데이터를 추출
      const sensorListForSelectBox = res.data.data.pmsNormalTitleList.map((el: any) => ({
        type: 'equip',
        equip: el.subCd,
        cdName: el.cdName,
      }));

      // 초기 '전체' 옵션(INIT_EQUIP_A)을 가져온 데이터와 결합
      const result = [INIT_EQUIP_A, ...sensorListForSelectBox];

      // 포맷된 장비 목록 반환
      return result;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  return { data, isError, isLoading, refetch };
};

export const useFetchNvrCodeList = () => {
  const { hCd, sCd } = useRecoilValue<IUser>(userState);

  // NVR목록 조회 useQuery
  const { data, isLoading, isError, refetch } = useQuery(['camNvrGet', hCd, sCd], () => fetchData(), {
    enabled: !!hCd && !!sCd && sCd !== '00000',
  });

  const fetchData = async () => {
    try {
      const req = { hCd, sCd, nCd: '001', cCd: '01' };
      const res = await apiGet({ path: '/cam/nvr', req });
      const nvrListForSelectBox = res.data.data.nvrList.filter((el1: any) => el1.useYn === 'Y').map((el: any) => ({ type: 'nCd', nCd: el.nCd, cdName: el.nName }));
      const result = [INIT_NCD_A, ...nvrListForSelectBox];
      return result;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  return { data, isError, isLoading, refetch };
};

export const useFetchCameraCodeList = (nCd: string) => {
  const { hCd, sCd } = useRecoilValue<IUser>(userState);

  // 카메라목록 조회 useQuery
  const { data, isLoading, isError, refetch } = useQuery(['camNvrGet', hCd, sCd, nCd], () => fetchData(), {
    enabled: !!hCd && !!sCd && sCd !== '00000' && nCd !== 'A',
  });

  const fetchData = async () => {
    try {
      const req = { hCd, sCd, nCd };
      const res = await apiGet({ path: '/cam/camera', req });
      const nvrListForSelectBox = res.data.data.cameraList.filter((el1: any) => el1.useYn === 'Y').map((el: any) => ({ type: 'cCd', cCd: el.cCd, cdName: el.cName }));
      const result = [INIT_CCD_A, ...nvrListForSelectBox];
      return result;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  return { data, isError, isLoading, refetch };
};

export const useFetchCameraCodeListWithoutNcd = () => {
  const { cameraInfo, isError, isLoading } = useFetchCctvList();
  const camListForSelectBox = cameraInfo.map((el: any, i: number) => ({
    type: 'cam',
    cam: i,
    cdName: el.cName,
    ip: el.ip,
    pPort: el.pPort,
    channelNum: el.channelNum,
    id: el.id,
    password: el.password,
  }));

  return { data: camListForSelectBox, isError, isLoading };
};

// 터널 코드 목록 조회
export const useFetchTunnelCodeList = () => {
  const userInfo = useRecoilValue<IUser>(userState);
  const queryKey = 'tatCdList';
  const dependencies = [queryKey, userInfo.hCd, userInfo.sCd];

  const { data, isError, isLoading, refetch } = useQuery(dependencies, () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd, // hCd 및 sCd가 유효한 경우에만 쿼리 사용
  });

  // 데이터 fetch 함수
  const fetchData = async () => {
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, useYn: 'Y' };
      const res = await apiGet({ path: '/tarea', req }); // 데이터를 검색하기 위해 API 호출

      const tunnelCdListForSelectBox = res.data.data.tareaList.map((el: any) => ({
        type: 'tatCd',
        tatCd: el.tatCd,
        cdName: el.tatName,
      }));

      // 초기 '전체' 옵션을 가져온 데이터와 결합
      const result = [INIT_TATCD_A, ...tunnelCdListForSelectBox];

      // 포맷된 목록 반환
      return result;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  return { data, isError, isLoading, refetch };
};

// 터널 작업내용목록 조회
export const useFetchTunnelJcCodeList = () => {
  const userInfo = useRecoilValue<IUser>(userState);
  const queryKey = 'tjcCdList';
  const dependencies = [queryKey, userInfo.hCd, userInfo.sCd];

  const { data, isError, isLoading, refetch } = useQuery(dependencies, () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd, // hCd 및 sCd가 유효한 경우에만 쿼리 사용
  });

  // 데이터 fetch 함수
  const fetchData = async () => {
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, useYn: 'Y' };
      const res = await apiGet({ path: '/tarea/jclist', req }); // 데이터를 검색하기 위해 API 호출

      const jcListForSelectBox = res.data.data.tareaJcList.map((el: any) => ({
        type: 'tjcCd',
        tjcCd: el.tjcCd,
        cdName: el.tjcName,
      }));

      // 초기 '전체' 옵션을 가져온 데이터와 결합
      const result = [INIT_TJC_A, ...jcListForSelectBox];

      // 포맷된 목록 반환
      return result;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  return { data, isError, isLoading, refetch };
};

// 터널 작업내용목록 조회
export const useFetchTunnelEsCodeList = () => {
  const userInfo = useRecoilValue<IUser>(userState);
  const queryKey = 'tesCdList';
  const dependencies = [queryKey, userInfo.hCd, userInfo.sCd];

  const { data, isError, isLoading, refetch } = useQuery(dependencies, () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd, // hCd 및 sCd가 유효한 경우에만 쿼리 사용
  });

  // 데이터 fetch 함수
  const fetchData = async () => {
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, useYn: 'Y' };
      const res = await apiGet({ path: '/tarea/eslist', req }); // 데이터를 검색하기 위해 API 호출

      const esListForSelectBox = res.data.data.tareaEsList.map((el: any) => ({
        type: 'tesCd',
        tesCd: el.tesCd,
        cdName: el.tesName,
      }));

      // 초기 '전체' 옵션을 가져온 데이터와 결합
      const result = [INIT_TES_A, ...esListForSelectBox];

      // 포맷된 목록 반환
      return result;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  return { data, isError, isLoading, refetch };
};

// 장비목록 조회
export const useFetchFaseSetCodeList = () => {
  const userInfo = useRecoilValue<IUser>(userState);
  const queryKey = 'faceSetCdList';
  const dependencies = [queryKey, userInfo.hCd, userInfo.sCd];

  const { data, isError, isLoading, refetch } = useQuery(dependencies, () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd, // hCd 및 sCd가 유효한 경우에만 쿼리 사용
  });

  // 데이터 fetch 함수
  const fetchData = async () => {
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
      const res = await apiGet({ path: '/setting/faceSet', req }); // 데이터를 검색하기 위해 API 호출

      const sortedArray: any = arraySortByAscdOrder(res.data.data.faceSet, 'cdSort');
      const faceSetListForSelectBox = sortedArray.map((el: any) => ({
        type: 'fNum',
        fNum: el.fNum,
        cdName: el.fName,
      }));

      // 포맷된 목록 반환
      return faceSetListForSelectBox;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  return { data, isError, isLoading, refetch };
};

// 협력업체 목록 조회
export const useFetchSiteJoinCodeList = () => {
  const userInfo = useRecoilValue<IUser>(userState);
  const queryKey = 'sjCdList';
  const dependencies = [queryKey, userInfo.hCd, userInfo.sCd];

  const { data, isError, isLoading, refetch } = useQuery(dependencies, () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd, // hCd 및 sCd가 유효한 경우에만 쿼리 사용
  });

  // 데이터 fetch 함수
  const fetchData = async () => {
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
      const res = await apiGet({ path: '/siteJoin', req }); // 데이터를 검색하기 위해 API 호출

      const faceSjcdListForSelectBox = res.data.data.siteJoinList
        .filter((el: any) => el.useYn === 'Y')
        .map((el: any) => ({
          type: 'sjCd',
          sjCd: el.sjCd,
          cdName: el.sjName,
        }));

      // 포맷된 목록 반환
      return faceSjcdListForSelectBox;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  return { data, isError, isLoading, refetch };
};

export const useFetchCommonCodeList = (comCd: string, isAllIncluded: boolean, etcKey?: string, etcValue?: string) => {
  const userInfo = useRecoilValue<IUser>(userState);
  const queryKey = [`codeDetail_${comCd}_${isAllIncluded}_${etcValue}`, comCd, isAllIncluded];

  const fetchData = async (comCdParam: string) => {
    try {
      const req = { grCd: comCdParam };
      const res = await apiGet({ path: '/code/detail', req });
      let codeList = res.data.data.comCdList;

      // 옵션 etcKey 및 etcValue를 기반으로 한 필터 코드 목록
      if (etcKey && etcValue) codeList = codeList.filter((code: IComCdList) => code[etcKey as keyof IComCdList] === etcValue);

      // 코드 목록을 매핑하여 셀렉트박스형식으로 변환 후 정렬
      const codeListInSelectBoxForm = codeList
        .filter((el: IComCdList) => el.useYn === 'Y')
        .map((el: IComCdList) => ({
          type: el.grCd,
          [el.grCd]: el.subCd,
          cdName: el.cdName,
        }));

      const sortedArray = arraySortByAscdOrder(codeListInSelectBoxForm, 'cdSort');

      // 필요한 경우 '전체' 옵션 포함
      if (isAllIncluded) {
        const defaultObject = { type: comCd, [comCd]: 'A', cdName: '전체' };
        return [defaultObject, ...sortedArray];
      }
      return sortedArray;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  /**
   * 작성자 : 홍선영
   * 날짜 : 2024.03.04
   * 기능 : 공통코드 데이터패치 후 객체를 셀렉트박스 형식에 맞게 변환 한 뒤 데이터 및 useQuery함수들을 리턴하는 hook
   *        - 공통코드의 경우, 빈번한 수정이 일어나는 데이터가 아니므로 일정시간동안은 캐싱해둔 데이터를 사용하도록 설정함.
   */
  const { data, isError, isLoading, isFetching, refetch } = useQuery(queryKey, () => fetchData(comCd), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
    // staleTime: 60000 * 60 * 2, // 2시간/
  });

  return { data, isError, isLoading, isFetching, refetch };
};

export const useFetchNormalCodeList = (subCd: string) => {
  const userInfo = useRecoilValue<IUser>(userState);
  const queryKey = [`normalcdGet_${subCd}`, userInfo.hCd, userInfo.sCd];

  const { data, isError, isLoading, isFetching, refetch } = useQuery(queryKey, () => fetchData(subCd), {
    enabled: !!userInfo.hCd && !!userInfo.sCd, // hCd 및 sCd가 유효한 경우에만 쿼리 사용
  });

  // 데이터 fetch 함수
  const fetchData = async (code: string) => {
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, subCd: code }; // 사용자 정보 및 subCd 필터로 요청 개체 준비
      const res = await apiGet({ path: '/code/pmsNormalTitle', req }); // 데이터를 검색하기 위해 API 호출

      // 응답에서 x데이터를 추출
      const sensorListForSelectBox = res.data.data.pmsNormalTitleList.map((el: any) => ({
        type: subCd,
        [subCd]: el.subCd,
        cdName: el.cdName,
      }));

      // 포맷된 목록 반환
      return sensorListForSelectBox;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  return { data, isError, isLoading, isFetching, refetch };
};
