/**
 * 작성자 : 홍선영
 * 날짜 : 2023.07.13
 * 기능 : 근로자 연명부 엑셀 간편등록 모달
 */

import { useState, Dispatch, SetStateAction, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import * as XLSX from 'xlsx';

import { userState } from '../../atoms';
import { IComCd, IModal } from 'customTypes';
import { JOBTYPE_EXCEL_FORM_URL, PREJOBTYPE_EXCEL_FORM_URL } from '../../_constants';
import { ModalBackground, Modal } from '../../assets/styles/Modal';
import { BtnBlue } from '../Button';
import { removeNonDigits } from '../../utils/removeNonDigits';
import { checkValidDateString } from '../../utils/checkValidDateString';
import { arraySortByAscdOrder } from '../../utils/arraySortByAscdOrder';
import illustrator from '../../assets/images/illustration/128.svg';
import { useTranslation } from 'react-i18next';
import BackButton from '../BackButton';
import { apiGet } from '../../services/_common';

interface IProps {
  state: any[];
  setState: Dispatch<SetStateAction<any[]>>;
  setOpenModal: Dispatch<SetStateAction<IModal>>;
  onClickClose: () => void;
}

interface ISelectedFile {
  type: string;
  url: string | ArrayBuffer | null;
}

const RegisterExcelModal = ({ state, setState, setOpenModal, onClickClose }: IProps) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [items, setItems] = useState<any[] | null>(null); // store excel data here
  const [wJobtypeOptionArray, setWJobtypeOptionArray] = useState<any[]>([]);
  const [siteJoinCdList, setSiteJoinCdList] = useState<any | null>(null); // 협력업체 코드리스트
  const [preJobtypeCdList, setPreJobtypeCdList] = useState([]); // (공종+직종일 때) 공종 코드리스트
  const EXCEL_COL_TITLE_1 = t('협력업체 (필수)');
  const EXCEL_COL_TITLE_2 = t('공종');
  const EXCEL_COL_TITLE_3 = t('직종');
  const EXCEL_COL_TITLE_4 = t(`근로자명 (필수)`);
  const EXCEL_COL_TITLE_5 = t(`생년월일 (8자리)`);
  const EXCEL_COL_TITLE_6 = t(`핸드폰번호`);

  useEffect(() => {
    getSiteJoinListAPI(); // 협력업체목록 호출
    if (userInfo.prejobtypeYn === 'Y') getPrejobtypeAPI(); // (공종+직종일 때) 공종목록 호출
    if (userInfo.prejobtypeYn === 'N') getJobtypeAPI('000'); // (직종만일 때) 직종목록 호출
  }, []);

  const getSiteJoinListAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/siteJoin', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      const useYList = data.siteJoinList.filter((el: any) => el.useYn === 'Y'); // 사용중인 협력업체만 필터링
      const formatSelectBoxFormList = useYList.map((el: any, i: number) => {
        // 셀렉트박스 컴포넌트에서 사용하는 형태로 변경
        return { type: 'sjCd', sjCd: el.sjCd, name: el.sjName, cdSort: i + 1 };
      });
      const newObj = { type: 'sjCd', sjCd: '', name: t('선택'), cdSort: 0 };
      const addAllSelect: any = [...formatSelectBoxFormList, newObj];
      const sortedArray: any = arraySortByAscdOrder(addAllSelect, 'cdSort');
      setSiteJoinCdList(sortedArray);
    } else {
      // toast.error(t(ERROR));
    }
  };

  const onClickReturn = () => {
    setOpenModal((prev) => ({ ...prev, type: '' }));
    // setOpenModal((prev: IModal) => ({ ...prev, status: false }));
    // setOpenModal((prev) => ({ ...prev, status: false }));
    // setItems(null);
  };

  useEffect(() => {
    if (items && items.length > 0) {
      // 공종값 배열로 만들기
      const wPrejobtypeValues = Array.from(new Set(items.map((item) => item[EXCEL_COL_TITLE_2])));
      const newValues = wPrejobtypeValues.map((el) => getPrejobtypeCd(el)).filter((el2) => el2 !== '');
      newValues.map((el) => {
        if (userInfo.prejobtypeYn === 'Y') return getJobtypeAPI(el);
        return getJobtypeAPI('000');
      });
    }
  }, [items]);

  // (공종+직종일 때) 공종 코드리스트 호출
  const getPrejobtypeAPI = async () => {
    const req = {
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      grCd: '000',
    };
    const res = await apiGet({ path: '/code/normal/prejobtype', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const prejobTypeList = data.prejobtypeList.filter((el: any) => el.subCd.charAt(0) === 'A');
      const formatSelectBoxFormList = prejobTypeList.map((el: IComCd, i: number) => {
        return { type: 'wPrejobtype', wPrejobtype: el.subCd, name: el.cdName, cdSort: i + 1 };
      });
      const newObj = { type: 'wPrejobtype', wPrejobtype: '', name: t('선택'), cdSort: 0 };
      const addAllSelect: any = [...formatSelectBoxFormList, newObj];
      const sortedArray: any = arraySortByAscdOrder(addAllSelect, 'cdSort');
      setPreJobtypeCdList(sortedArray);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 직종 코드리스트 호출
  const getJobtypeAPI = async (grCd: string) => {
    const req = {
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      grCd,
    };
    const res = await apiGet({ path: '/code/normal/prejobtype', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      // 공종+직종일 때 직종리스트
      if (userInfo.prejobtypeYn === 'Y') {
        const formatSelectBoxFormList = data.prejobtypeList.map((el: IComCd, i: number) => {
          return { type: 'wJobtype', wJobtype: el.subCd, name: el.cdName, cdSort: i + 1 };
        });
        const newObj = { type: 'wJobtype', wJobtype: '', name: t('선택'), cdSort: 0 };
        const addAllSelect: any = [...formatSelectBoxFormList, newObj];
        const sortedArray: any = arraySortByAscdOrder(addAllSelect, 'cdSort');
        setWJobtypeOptionArray((prev: any) => ({ ...prev, [grCd]: sortedArray }));
      } else {
        // 직종만일 때 직종리스트
        const jobTypeList = data.prejobtypeList.filter((el: any) => el.subCd.charAt(0) === 'B');
        const formatSelectBoxFormList = jobTypeList.map((el: IComCd, i: number) => {
          return { type: 'wJobtype', wJobtype: el.subCd, name: el.cdName, cdSort: i + 1 };
        });
        const newObj = { type: 'wJobtype', wJobtype: '', name: t('선택'), cdSort: 0 };
        const addAllSelect: any = [...formatSelectBoxFormList, newObj];
        const sortedArray: any = arraySortByAscdOrder(addAllSelect, 'cdSort');
        setWJobtypeOptionArray((prev: any) => [...prev, sortedArray]);
      }
    } else {
      // toast.error(t(ERROR));
    }
    return null;
  };

  const arraysEqual = (a: any[], b: any[]): boolean => {
    return a.length === b.length && a.every((value, index) => value?.replace(/\s/g, '') === b[index]?.replace(/\s/g, ''));
  };

  // 엑셀파일 JSON으로 변환
  const readExcel = (file: Blob) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      const workBook = XLSX.read(data, { type: 'binary' });

      workBook.SheetNames.forEach((sheetName) => {
        const rows: any = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName]);

        if (rows.length > 0) {
          const keys = Object.keys(rows[0]);

          let columnNameArray;
          if (userInfo.prejobtypeYn === 'Y') {
            columnNameArray = [EXCEL_COL_TITLE_1, EXCEL_COL_TITLE_2, EXCEL_COL_TITLE_3, EXCEL_COL_TITLE_4, EXCEL_COL_TITLE_5, EXCEL_COL_TITLE_6];
          } else {
            columnNameArray = [EXCEL_COL_TITLE_1, EXCEL_COL_TITLE_3, EXCEL_COL_TITLE_4, EXCEL_COL_TITLE_5, EXCEL_COL_TITLE_6];
          }

          if (arraysEqual(keys, columnNameArray)) {
            setItems(rows);
          } else toast.warning(t('엑셀 양식이 일치하지 않습니다.'));
        }
      });
    };
    reader.readAsBinaryString(file);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (files && files.length > 0) {
      readExcel(files[0]);
    }
  };

  // 협력없체 코드네임으로 코드값 반환
  const getSiteJoinCd = (cdName: string) => {
    if (cdName) {
      const findObj = siteJoinCdList.find((el: any) => el.name.replace(/\s/g, '') === cdName.replace(/\s/g, ''));
      if (findObj) return findObj.sjCd;
      return '';
    }
    return '';
  };

  // 공종 코드네임으로 코드값 반환
  const getPrejobtypeCd = (cdName: string) => {
    if (cdName) {
      const findObj: any = preJobtypeCdList.find((el: any) => el.name.replace(/\s/g, '') === cdName.replace(/\s/g, ''));
      if (findObj) return findObj.wPrejobtype;
      return '';
    }
    return '';
  };

  // 공종+직종사용일 때 직종 코드네임으로 코드값 반환
  const getJobtypeCd = (prejobtypeCd: any, cdName: string) => {
    if (wJobtypeOptionArray[prejobtypeCd]) {
      const findObj: any = wJobtypeOptionArray[prejobtypeCd].find((el: any) => el.name.replace(/\s/g, '') === cdName.replace(/\s/g, ''));
      if (findObj) return findObj.wJobtype;
      return '';
    }
    return '';
  };

  // 직종만 사용일 때 직종 코드네임으로 코드값 반환
  const getOnlyJobtypeCd = (cdName: any) => {
    if (wJobtypeOptionArray[0]) {
      const findObj: any = wJobtypeOptionArray[0].find((el: any) => el.name.replace(/\s/g, '') === cdName.replace(/\s/g, ''));
      if (findObj) return findObj.wJobtype;
      return '';
    }
    return '';
  };

  const onClickRegisterExcelData = () => {
    if (items) {
      const newArray = items.map((el: any) => {
        return {
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          sjCd: getSiteJoinCd(el[EXCEL_COL_TITLE_1]) || '',
          wPrejobtype: userInfo.prejobtypeYn === 'Y' ? getPrejobtypeCd(el[EXCEL_COL_TITLE_2]) || '' : '000',
          wJobtype: userInfo.prejobtypeYn === 'Y' ? getJobtypeCd(getPrejobtypeCd(el[EXCEL_COL_TITLE_2]), el[EXCEL_COL_TITLE_3]) || '' : getOnlyJobtypeCd(el[EXCEL_COL_TITLE_3]) || '',
          wName: el[EXCEL_COL_TITLE_4] || '',
          wBdate: (checkValidDateString(el[EXCEL_COL_TITLE_5]) ? el[EXCEL_COL_TITLE_5] : '') || '',
          wHnum: removeNonDigits(el[EXCEL_COL_TITLE_6], 11) || '',
          wHnum1: removeNonDigits(el[EXCEL_COL_TITLE_6]?.slice(0, 3), 3) || '',
          wHnum2: removeNonDigits(el[EXCEL_COL_TITLE_6]?.slice(3, -4), 4) || '',
          // 중간번호를 잘못입력했다면, 중간번호와 마지막번호 중 어느것을 잘못입력한 지 모르기때문에 둘 다 빈값처리
          wHnum3: (removeNonDigits(el[EXCEL_COL_TITLE_6]?.slice(3, -4), 4) === '' ? '' : removeNonDigits(el[EXCEL_COL_TITLE_6]?.slice(-4), 4)) || '',
          options: userInfo.prejobtypeYn === 'Y' ? wJobtypeOptionArray[getPrejobtypeCd(el[EXCEL_COL_TITLE_2])] || [] : wJobtypeOptionArray[0] || [],
          writer: userInfo.userId,
          editor: userInfo.userId,
          origin: 'excel', // 엑셀에서 등록한 데이터인지, 웹에서 신규항목 추가한 데이터인 지, 데이터의 오리진
        };
      });

      setState([...state, ...newArray]);
      setOpenModal((prev) => ({ ...prev, status: false }));
    }
  };

  return (
    <ModalBackground onClick={onClickClose} role='presentation'>
      <Modal>
        <div
          className='inModal pb05'
          role='presentation'
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className='inputForm-head flex-between'>
            <BackButton func={() => onClickReturn()} />
            <div className='modalHeaderTitle'>{t('근로자 엑셀등록')}</div>
            <div className='closeBtn' onClick={onClickClose} role='presentation'>
              <span className='material-symbols-rounded'>close</span>
            </div>
          </div>
          <div className='modal-body'>
            <div>
              <img src={illustrator} alt='noData' />
              <h5 className='red'>{t('주의사항 (반드시 확인)')}</h5>
              <div>{t('화면의 주의사항 및 근로자 등록 양식을 반드시 지켜주시길 바랍니다.')}</div>
            </div>
            <ul>
              <li>
                <h6>1</h6>
                <div>
                  <h6>{t('엑셀의 셀 서식 관련사항')}</h6>
                  <div>
                    <p>
                      <span className='red'>{t('모든 데이터는 [텍스트 서식]으로')}</span> {t('바꿔주셔야 합니다.')}
                    </p>
                    <div className='description'>
                      <span className='material-symbols-rounded'>chevron_right</span>
                      {t('열 전체 선택 후 일괄 적용')}
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <h6>2</h6>
                <div>
                  <h6>{t('엑셀 데이터 입력 관련사항')}</h6>
                  <div className='lists'>
                    <div>
                      <p>
                        <span className='red'>{t('협력업체, 근로자명 필수 입력')}</span> {t('값이며 반드시 입력하셔야 합니다.')}
                      </p>
                      <div className='description'>
                        <span className='material-symbols-rounded'>chevron_right</span>
                        {t('나머지 입력은 없어도 무방')}
                      </div>
                    </div>
                    <div>
                      <p>{t('협력업체, 근로자명 중 1개라도 없을 시 해당 근로자 데이터가 등록이 되지 않습니다.')}</p>
                      <div className='description'>
                        <span className='material-symbols-rounded'>chevron_right</span>
                        {t('필수 입력값')}
                      </div>
                    </div>
                    <div>
                      <p>
                        <span className='blue'>{t('휴대폰번호, 생년월일은 하이픈(-)을 빼고 숫자만 입력하셔야 합니다')}</span>
                      </p>
                      <div className='description'>
                        <span className='material-symbols-rounded'>chevron_right</span>
                        {t('문자 값, 기호 값 입력 금지')}
                      </div>
                    </div>
                    <p>
                      <span className='blue'>
                        {t('휴대폰번호')}, {t('생년월일')}
                      </span>
                      {t('에 문자 값이 들어갈 경우 해당 데이터가 등록되지 않습니다.')}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <h6>3</h6>
                <div>
                  <h6>{t('근로자 엑셀 등록 양식')}</h6>
                  {/* <p>
                  근로자 엑셀 등록 양식 : &nbsp;
                  <span className='underline bold'>
                  <a href={userInfo.prejobtypeYn === 'Y' ? PREJOBTYPE_EXCEL_FORM_URL : JOBTYPE_EXCEL_FORM_URL}>다운로드</a>
                  </span>
                </p> */}
                  <div>
                    <p>{t('주의사항 및 파일 양식을 반드시 지켜주세요.')}</p>
                    <div className='description'>
                      <span className='material-symbols-rounded'>chevron_right</span>
                      {t('잘못 등록한 근로자에 대해서는 책임지지 않음.')}
                    </div>
                  </div>
                  <a href={userInfo.prejobtypeYn === 'Y' ? PREJOBTYPE_EXCEL_FORM_URL : JOBTYPE_EXCEL_FORM_URL}>{t('다운로드')}</a>
                </div>
              </li>
            </ul>
          </div>
          <div className='fileBoxWrapper'>
            <label htmlFor='file'>{t('근로자 엑셀 파일')}</label>
            <input
              type='file'
              accept='.xls,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
              id='filebox'
              name='dIfilename'
              ref={inputFileRef}
              onChange={handleChange}
            />
            <label htmlFor='filebox' className='searchBox'>
              <div className='fileButton' id='filebox'>
                {t('파일선택')}
              </div>
            </label>
          </div>
          <div className='modal-footer flex-center'>
            <BtnBlue onClick={onClickRegisterExcelData}>{t('등록')}</BtnBlue>
          </div>
        </div>
      </Modal>
    </ModalBackground>
  );
};

export default RegisterExcelModal;
