/**
 * 작성자 : 홍선영
 * 날짜 : 2023.05.16
 * 경로 : 근로자 관리 - 근로자 서명관리
 */

import { useEffect, useRef, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import i18n from '../../../translation/i18n';
import { INIT_CHECK_OPTION, LIST_COUNT } from '../../../_constants';
import { IUser, userState } from '../../../atoms';
import { ContentsContainerRoot } from '../../../assets/styles/ContentsContainerRoot';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import WorkerSearchOption from './workerSearchOption';
import WorkerPrintDateilModal from './workerPrintDateilModal';
import Portal from '../../../components/Portal';
import TuiGrid from '../../../components/Table/TuiGrid';
import IssueGuide from '../../../components/IssueGuide';
import TuiGridWrapper from '../../../components/Table/TuiGridWrapper';
import CustomBtnRenderer from '../../../components/CustomBtnRenderer';
import CustomCheckableBoxRenderer from '../../../components/CustomCheckableBoxRenderer';
import { useSetAuth } from '../../../utils/useSetAuth';
import { useGetFrozenCount } from '../../../utils/getFrozenCount';
import useSetListCount from '../../../utils/useSetListCount';
import InfoWorkerModal from '../../../components/Modal/InfoWorkerModal';
import dayjs from 'dayjs';
import { logPost } from '../../../services/log';

const Root = styled(ContentsContainerRoot)`
  .checks {
    flex-wrap: wrap;
    font-size: 0.875rem;
    gap: 0.5rem;
    label {
    }
  }

  .secondSearchOption {
    flex-wrap: wrap;
  }

  .btn-in-cell {
    height: 2rem;
    font-size: 0.875rem;
    padding-left: 0.5rem;
    padding-right: 0.75rem;
    margin: 0 auto;
    font-weight: 600;
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.board};
    }
    .material-symbols-rounded {
      margin-right: 0.25rem;
    }
  }

  .btn-blue {
    background-color: ${({ theme }: { theme: any }) => theme.filled_blue} !important;
    border: none !important;
    color: ${({ theme }: { theme: any }) => theme.color.blue_100} !important;
  }

  @media print {
    .printable-contents {
      display: none;
    }
  }
`;

const WorkerSignManagement = () => {
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const userInfo = useRecoilValue<IUser>(userState);
  const { eExcelYn, eListYn } = userInfo;
  const { t } = useTranslation();
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [tableState, setTableState] = useState<any[]>([]);
  const [filterTableState, setFilterTableState] = useState<any[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  const [tuiWidth, setTuiWidth] = useState<null | number>(null);
  const [columns, setColumns] = useState<any[]>([]);
  const [excelBtn, setExcelBtn] = useState(false);
  const [rowKey, setRowKey] = useState('');
  const [checkList, setCheckList] = useState([]); // 헤더체크된 로우리스트
  const [checkOption, setCheckOption] = useState(INIT_CHECK_OPTION);
  const [checked, setChecked] = useState<{ columnName: string | null; checked: boolean }>({ columnName: null, checked: false });
  const { listCount, setListCount } = useSetListCount('15'); // 검색줄수설정 훅
  const [openModal, setOpenModal] = useState<{ status: boolean; type: string; data?: any; code?: string; aDate?: string; index?: number }>({
    status: false,
    type: '',
    data: null,
    code: '',
    index: 0,
  });
  const [refetchFlag, setRefetchFlag] = useState(false);

  useEffect(() => {
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '근로자 관리 > 근로자 서명 관리',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    if (componentRef.current !== null) setTuiHeight(componentRef.current.offsetHeight);
  }, [componentRef.current?.offsetHeight]);

  useEffect(() => {
    if (componentRef.current !== null && componentRef.current !== undefined) setTuiWidth(componentRef.current?.offsetWidth);
  }, [componentRef.current?.offsetWidth]);

  const onClickDetailBtn = (rKey: number) => {
    const targetRow = filterTableState[rKey];
    const findIndex = filterTableState.findIndex((el: any) => el.wCd === targetRow.wCd);
    if (targetRow && findIndex !== -1) {
      setRowKey(`${rKey}`);
      setOpenModal({
        status: true,
        type: 'printDetail',
        index: findIndex,
        data: {
          workerInfo: { ...targetRow },
          consignImg: targetRow.consignImg,
          safeeduImg: targetRow.safeeduImg,
          muscularImg: targetRow.muscularImg,
          painImg: targetRow.painImg,
          stressImg: targetRow.stressImg,
          C01: targetRow.C01,
          C02: targetRow.C02,
          C03: targetRow.C03,
          C04: targetRow.C04,
          C05: targetRow.C05,
          C06: targetRow.C06,
          C07: targetRow.C07,
          C08: targetRow.C08,
          C09: targetRow.C09,
          C10: targetRow.C10,
          J01: targetRow.J01,
          J02: targetRow.J02,
          J03: targetRow.J03,
          J04: targetRow.J04,
          J05: targetRow.J05,
          J06: targetRow.J06,
          J07: targetRow.J07,
          J08: targetRow.J08,
          J09: targetRow.J09,
          J10: targetRow.J10,
        },
      });
    }
  };

  const fixedPrefixColumns = [
    {
      header: t('근로자명'),
      name: 'wName',
      sortable: true,
      minWidth: 130,
      renderer: { classNames: ['text_secondary'] },
    },
    {
      header: t('협력업체'),
      name: 'sjName',
      sortable: true,
      className: '',
      width: 160,
      renderer: { classNames: ['text_secondary'] },
    },
  ];
  const fixedSuffixColumns = [
    {
      header: t('직종'),
      name: 'wJobtypeName',
      sortable: true,
      minWidth: 120,
    },
    {
      header: t('취업일자'),
      name: 'wJobdate',
      align: 'center',
      sortable: true,
      minWidth: 120,
    },
    {
      header: t('근로 상태'),
      name: 'wWorkstatusName',
      align: 'center',
      sortable: true,
      minWidth: 120,
    },
    {
      header: t('근로자정보'),
      name: 'workerInfoCheck',
      align: 'center',
      renderer: { type: CustomCheckableBoxRenderer },
      minWidth: 80,
    },
    {
      header: t('공통 양식지'),
      name: 'cCheck',
      align: 'center',
      renderer: { type: CustomCheckableBoxRenderer },
      minWidth: 80,
    },
    {
      header: t('직종별 양식지'),
      name: 'jCheck',
      align: 'center',
      renderer: { type: CustomCheckableBoxRenderer },
      minWidth: 80,
    },
    {
      header: t('근골격계'),
      name: 'muscularCheck',
      align: 'center',
      renderer: { type: CustomCheckableBoxRenderer },
      minWidth: 80,
    },
    {
      header: t('통증조사표'),
      name: 'painCheck',
      align: 'center',
      renderer: { type: CustomCheckableBoxRenderer },
      minWidth: 80,
    },
    {
      header: t('직무스트레스'),
      name: 'stressCheck',
      align: 'center',
      renderer: { type: CustomCheckableBoxRenderer },
      minWidth: 80,
    },
    {
      header: t('상세보기'),
      name: 'button',
      align: 'center',
      renderer: { type: CustomBtnRenderer, options: { onClickDetailBtn, auth, t } },
      minWidth: 80,
    },
  ];
  const prejobtypeColumn = [{ header: t('공종'), name: 'wPrejobtypeName', sortable: true, minWidth: 120 }];

  useEffect(() => {
    const col = [...fixedPrefixColumns, ...(userInfo.prejobtypeYn === 'Y' ? prejobtypeColumn : []), ...fixedSuffixColumns];
    setColumns(col);
  }, [userInfo.prejobtypeYn, i18n.language, tableState]);

  const onClickCheckBox = (list: any) => {
    setCheckList(list);
  };

  const onClickColumn = (columnName: string | null) => {
    return columnName;
  };

  // 근로자 Row 클릭
  const onClickRow = (rowData: any, columnName: any, filteredIndex: number) => {
    setRowKey(`${filteredIndex}`);
    setOpenModal({ status: true, type: 'infoWorker', code: rowData?.wCd, aDate: dayjs().format('YYYY-MM-DD'), index: filteredIndex });
  };

  // 근로자정보 모달에서 정보 수정 시 flag를 true로 변경하고 refetch하여 tuigrid 목록 재조회
  const onClickRefresh = () => {
    setRefetchFlag(true);
  };

  const frozenCount = useGetFrozenCount();

  const loading = isFetching;

  if (isError) return <IssueGuide />;

  return (
    <>
      <div className='content-container oneColumn'>
        <Root loading={loading}>
          <WorkerSearchOption
            setIsFetching={setIsFetching}
            setIsError={setIsError}
            checkAll={checkAll}
            setCheckAll={setCheckAll}
            checkOption={checkOption}
            setCheckOption={setCheckOption}
            tableState={tableState}
            setTableState={setTableState}
            filterTableState={filterTableState}
            setFilterTableState={setFilterTableState}
            setRowKey={setRowKey}
            checkList={checkList}
            setCheckList={setCheckList}
            onClickColumn={onClickColumn}
            setChecked={setChecked}
            listCount={listCount}
            setListCount={setListCount}
            refetchFlag={refetchFlag}
            setRefetchFlag={setRefetchFlag}
          />
          <TuiGridWrapper componentRef={componentRef}>
            {tuiWidth !== null && (
              <TuiGrid
                data={tableState}
                filterTableState={filterTableState}
                setFilterTableState={setFilterTableState}
                columns={columns}
                perPage={Number(listCount[LIST_COUNT])}
                excelBtn={excelBtn}
                setExcelBtn={setExcelBtn}
                usePagenation
                onClickRow={onClickRow}
                onClickCheckBox={onClickCheckBox}
                customCheck
                checkedColumn={checked}
                scrollX
                height={tuiHeight}
                rowKey={rowKey}
                eListYn={eListYn}
                eExcelYn={eExcelYn}
                rowHeight={48}
                frozenCount={frozenCount}
                setRowKey={setRowKey}
              />
            )}
          </TuiGridWrapper>
        </Root>
      </div>
      {loading && (
        <LoadingModalBackground>
          <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
        </LoadingModalBackground>
      )}
      <Portal openModal={openModal?.status}>
        {openModal.status && openModal.type === 'printDetail' && (
          <WorkerPrintDateilModal setOpenModal={setOpenModal} data={openModal.data} index={openModal.index} reportArray={filterTableState} setRowKey={setRowKey} listCount={listCount[LIST_COUNT]} />
        )}
        {openModal.status && openModal.type === 'infoWorker' && openModal.code !== undefined && (
          <InfoWorkerModal
            innerTabIndex={0}
            getReportListAPI={onClickRefresh}
            reportArray={filterTableState}
            setReportArray={setFilterTableState}
            setOpenModal={setOpenModal}
            auth={auth}
            code={{ hCd: userInfo.hCd, sCd: userInfo.sCd, wCd: openModal.code, aDate: openModal.aDate }}
            index={openModal.index}
            setRowKey={setRowKey}
            listCount={Number(listCount[LIST_COUNT])}
          />
        )}
      </Portal>
    </>
  );
};

export default WorkerSignManagement;
