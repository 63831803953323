export const sortArrayByTimeDesc = (array: any) => {
  const sortedArray = [...array];

  sortedArray?.sort((a: any, b: any) => {
    const dateTimeA = `${a?.aDate} ${a?.aTime}`;
    const dateTimeB = `${b?.aDate} ${b?.aTime}`;
    return dateTimeB.localeCompare(dateTimeA); // 최근순
  });
  return sortedArray;
};
