import ButtonContainer from '../../pages/s_cctv/S_cctv1/RealtimeCCTV/ButtonContainer';

// 녹화 중지 버튼
const offRecordButton = <ButtonContainer icon='stop' name='전체 녹화 중지' className='negative' darkModeOnly />;
// 녹화 시작 버튼
const onRecordButton = <ButtonContainer icon='fiber_manual_record' name='전체 녹화 시작' className='negative' darkModeOnly />;
// 캡쳐 버튼
const captureButton = <ButtonContainer icon='photo_camera' name='캡쳐' darkModeOnly />;
// 화면 제거 버튼
const removeQueueButton = <ButtonContainer icon='remove_from_queue' name='화면 제거' darkModeOnly />;
// 리프레쉬 버튼
const refreshButton = <ButtonContainer icon='refresh' name='새로고침' darkModeOnly />;
// 종료 버튼
const closeButton = <ButtonContainer icon='close' name='종료' darkModeOnly />;
// 접기 버튼
const foldButton = <ButtonContainer icon='left_panel_close' name='접기' darkModeOnly />;
// 펼치기 버튼
const unfoldButton = <ButtonContainer icon='left_panel_open' name='펼치기' darkModeOnly />;
// 전체화면 버튼
const fullScreenButton = <ButtonContainer icon='fullscreen' name='전체화면' darkModeOnly />;
// 화면 분할 버튼
const gridScreenButton = <ButtonContainer icon='grid_view' name='화면 분할' darkModeOnly />;
// 스트림 버튼
const streamButton = <ButtonContainer icon='cast' name='스트림' darkModeOnly />;
// 메인 스트림 버튼
const mainStream = <ButtonContainer icon='looks_one' name='메인' darkModeOnly />;
// 서브 스트림 버튼
const subStream = <ButtonContainer icon='looks_two' name='서브' darkModeOnly />;
// 트랜드코딩(third) 스트림 버튼
const thirdStream = <ButtonContainer icon='looks_3' name='트랜스코딩' darkModeOnly />;
// 트랜드(third) 스트림 버튼
const thirdStreamInShort = <ButtonContainer icon='looks_3' name='트랜스' darkModeOnly />;
// 처음으로 버튼
const firstPaginationButton = <ButtonContainer icon='skip_previous' darkModeOnly />;
// 이전 버튼
const previousPaginationButton = <ButtonContainer icon='navigate_before' darkModeOnly />;
// 다음 버튼
const nextPaginationButton = <ButtonContainer icon='navigate_next' darkModeOnly />;
// 마지막으로 버튼
const lastPaginationButton = <ButtonContainer icon='skip_next' darkModeOnly />;
// 재생 버튼
const playArrowButton = <ButtonContainer icon='play_arrow' darkModeOnly />;
// 거꾸로 재생 버튼
const playArrowReverseButton = <ButtonContainer icon='play_arrow' className='play_arrow_reverse' darkModeOnly />;
// 재생 정지 버튼
const playStopButton = <ButtonContainer icon='stop' darkModeOnly />;
// 일시 정지 버튼
const pauseButton = <ButtonContainer icon='pause' darkModeOnly />;

export {
  offRecordButton, //
  onRecordButton,
  captureButton,
  removeQueueButton,
  refreshButton,
  closeButton,
  foldButton,
  unfoldButton,
  fullScreenButton,
  gridScreenButton,
  streamButton,
  mainStream,
  subStream,
  thirdStream,
  thirdStreamInShort,
  firstPaginationButton,
  previousPaginationButton,
  nextPaginationButton,
  lastPaginationButton,
  playArrowButton,
  playArrowReverseButton,
  playStopButton,
  pauseButton,
};
