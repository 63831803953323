import styled from 'styled-components';
import { v4 } from 'uuid';

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .row.header {
    flex-grow: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 0.25rem;
    align-items: center;
    max-height: 2.5rem;
    height: 2.5rem;
  }
`;

type Props = { sensorList: any };

const TareaEmptyDataRows = ({ sensorList }: Props) => {
  return (
    <Root>
      <div className='row header' />
      {sensorList.map(() => (
        <div key={v4()} className='row' />
      ))}
    </Root>
  );
};

export default TareaEmptyDataRows;
