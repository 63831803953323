import { createRoot } from 'react-dom/client';
import styled from 'styled-components';

const CheckBox = styled.div`
  align-items: center;
  > label {
    font-weight: initial;
  }
  .material-symbols-rounded {
    color: ${({ theme }: { theme: any }) => theme.filled_green};
  }
`;
class CustomCheckBoxRenderer {
  element;

  constructor(props: any) {
    this.element = document.createElement('div');
    const root = createRoot(this.element);

    root.render(
      <CheckBox>
        {/* <input type='checkbox' checked={props.formattedValue === 'true'} disabled /> */}
        {props.formattedValue === 'true' && <span className='material-symbols-rounded check'>check</span>}
      </CheckBox>
    );
  }

  getElement() {
    return this.element;
  }
}
export default CustomCheckBoxRenderer;
