/**
 * 작성자 : 김광민
 * 날짜 : 2023.12.21
 * 경로 : 대시보드 - 굴진 진행현황
 */

import { useTranslation } from 'react-i18next';
import { PulseLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import TunnelProgressBar from '../chart/TunnelProgressBar';
import { EndPoint, StartPoint } from '../TunnelPoint';
import { useRecoilValue } from 'recoil';
import { tInfoUpdateState } from '../../atoms';
import { apiGet } from '../../services/_common';

const ExcavationProcess = ({ name, userInfo }: any) => {
  const { t } = useTranslation();
  const [tareaGrid, setTareaGrid] = useState<TareaData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const isTunnelInfoUpdated = useRecoilValue(tInfoUpdateState);

  // 터널 리스트 조회 API
  const fetchTareaData = async () => {
    setIsLoading(true);
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
      const res = await apiGet({ path: '/tarea', req });
      const {
        statusCode,
        data: { tareaList },
      } = res.data;
      if (statusCode === 200) {
        const result = tareaList.map((data: TareaData, idx: number) => ({ ...data, no: idx + 1 }));
        const mutateResult = result
          .map((tarea: any) => {
            const start = { tatName: tarea.tatName, length: tarea.sLength, rLength: tarea.sRLength, useYn: tarea.sUseYn, side: 'start' };
            const end = { tatName: tarea.tatName, length: tarea.eLength, rLength: tarea.eRLength, useYn: tarea.eUseYn, side: 'end' };
            return [start, end];
          })
          .flat()
          .filter((element: any) => element.useYn === 'Y');
        setTareaGrid(mutateResult);
      }
    } catch (error) {
      console.error('Error fetching tarea data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTareaData();
  }, [isTunnelInfoUpdated]);

  const onClickRefresh = () => fetchTareaData();

  if (isLoading) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <div className='equipInfo excavationProcess'>
      <div className='widget-header margin-top'>
        <div className='widget-title flex-between'>
          <span className='tunnel-title'>
            <span className='tunnel-name tight'>{t('굴진 진행현황')}</span>
            <span className='tunnel-guide'>
              <StartPoint size='small' />
              <span className='seperator tight' />
              <EndPoint size='small' />
            </span>
          </span>
          <div className='refresh-btn' onClick={onClickRefresh} role='presentation'>
            <span className='material-symbols-rounded'>refresh</span>
          </div>
        </div>
      </div>
      <TunnelProgressBar data={tareaGrid} />
    </div>
  );
};

export default ExcavationProcess;
