/**
 * 작성자 : 홍선영
 * 날짜 : 2023.05.10
 * 기능 : 객체 값 선/후행 공백제거
 */
export const trimObject = (object: any) => {
  const keys = Object?.keys(object);

  // 동일한 속성으로 새 개체 만들기
  const newObj = { ...object };
  keys.map((el) => {
    // 동적 키로 속성 값 가져오기
    const propertyValue = newObj[el];

    if (typeof propertyValue === 'string') {
      // 속성 값에서 선행 및 후행 공백 제거
      newObj[el] = propertyValue?.trim();
    }
  });
  return newObj;
};
