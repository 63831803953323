/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.08
 * 경로 : 터널관리 - 구역 현황 관리 > 종합진행현황
 */

import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

const Root = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  background-color: ${({ theme }: { theme: any }) => theme.board};
  box-shadow: 0px 4px 12px 4px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  &.disabled {
    box-shadow: none;
    .select {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
      color: ${({ theme }: { theme: any }) => theme.text_disabled};
    }
  }
  .select {
    font-size: 0.875rem;
    border-radius: 0.25rem;
    padding: 0.125rem 0.75rem;
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    &.selected {
      background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
      color: ${({ theme }: { theme: any }) => theme.text_reverse};
    }
  }
`;

// 필터 뷰의 속성 정의
type FilterViewProps = {
  start: boolean;
  end: boolean;
};

// 컴포넌트에 전달되는 속성 정의
type Props = {
  isPointVisible: FilterViewProps;
  setIsPointVisible: Dispatch<SetStateAction<FilterViewProps>>;
  isStartPointUse: boolean;
  isEndPointUse: boolean;
};

const TinyTunnelTab = ({ isPointVisible, setIsPointVisible, isStartPointUse, isEndPointUse }: Props) => {
  // 필터 뷰 조절 함수
  const handleFilterView = (e: React.MouseEvent<HTMLSpanElement>, type: 'start' | 'end' | 'all') => {
    e.stopPropagation();

    if (type === 'all') {
      setIsPointVisible({ start: true, end: true });
    }
    if (type === 'start') {
      setIsPointVisible({ start: true, end: false });
    }
    if (type === 'end') {
      setIsPointVisible({ start: false, end: true });
    }
  };

  // 렌더링
  if (isStartPointUse && isEndPointUse) {
    return (
      <Root>
        <span className={`select ${isPointVisible.start && isPointVisible.end ? 'selected' : ''}`} role='button' onClick={(e) => handleFilterView(e, 'all')} tabIndex={0}>
          전체보기
        </span>
        <span className={`select ${isPointVisible.start && !isPointVisible.end ? 'selected' : ''}`} role='button' onClick={(e) => handleFilterView(e, 'start')} tabIndex={0}>
          시점만 보기
        </span>
        <span className={`select ${!isPointVisible.start && isPointVisible.end ? 'selected' : ''}`} role='button' onClick={(e) => handleFilterView(e, 'end')} tabIndex={0}>
          종점만 보기
        </span>
      </Root>
    );
  }
  // startPoint 또는 endPoint가 false인 경우
  return (
    <Root className={`${isPointVisible.start && isPointVisible.end ? 'disabled' : ''}`}>
      {/* 시점 또는 종점만 보기 버튼 (비활성화) */}
      <span className={`select `} role='button' onClick={(e) => handleFilterView(e, 'all')} tabIndex={0}>
        {isPointVisible.start === true ? '시점만 보기' : '종점만 보기'}
      </span>
    </Root>
  );
};

export default TinyTunnelTab;
