/**
 * 작성자 : 한영광
 * 날짜 : 2023.04.27
 * 기능 : 마스터일반관리 -> 사용자관리 (pages/m_common/common2/headInfo.tsx) 페이지에서 사용
 * 날짜 : 2023.05.11
 * 수정사항: (본사소개 -> 본사정보 페이지) 수정 여부에 따라 입력창 desabled 처리
 *
 *  작성자 : 홍선영
 *  날짜 : 2023.05.10
 *  수정사항: 마지막 인풋이 길이조건을 충족했는 지 여부 추가 (충족시 다음인풋으로 포커스 넘기기 위함)
 *           첫번째 인풋의 포커싱 여부 추가 (상위 인풋의 입력이 마무리되었을 때 넘버인풋의 첫번째칸으로 포커스이동 시키기 위함)
 */

import { useRef, Dispatch, SetStateAction, useEffect } from 'react';
import { applyBorderStyle } from '../utils/applyBorderStyle';

interface INumbersInput {
  state: any;
  setState: Dispatch<SetStateAction<any>>;
  lengthState: any;
  isFirstInputFocused?: boolean;
  setIsLastInputCompleted?: Dispatch<SetStateAction<boolean>>;
  disabled?: boolean;
  isSaveClicked?: boolean;
}

const NumbersInput = ({ state, setState, lengthState, isFirstInputFocused, setIsLastInputCompleted, disabled = false, isSaveClicked }: INumbersInput) => {
  const firstInputRef = useRef<HTMLInputElement>(null); // 첫번째 칸 인풋 레프
  const minInputRef = useRef<HTMLInputElement>(null); // 두번째 칸 인풋 레프
  const lastInputRef = useRef<HTMLInputElement>(null); // 마지막 칸 인풋 레프

  useEffect(() => {
    if (isFirstInputFocused) {
      firstInputRef.current?.focus();
    }
  }, [isFirstInputFocused]);

  // input의 길이가 maxLength를 충족하면, 다음 인풋칸으로 포커스 이동
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>, nextRef: any, maxLength: number) => {
    const { name, value } = e.currentTarget;
    let newValue = value?.replace(/[^\d]/g, '');

    if (newValue.length > maxLength) {
      newValue = newValue.slice(0, maxLength);
    }
    if (nextRef !== null) {
      if (newValue.length === maxLength) nextRef.current?.focus();
    }
    if (name === 'num3') {
      if (newValue.length >= lengthState.num3) {
        setIsLastInputCompleted && setIsLastInputCompleted(true);
      } else {
        setIsLastInputCompleted && setIsLastInputCompleted(false);
      }
    }

    setState({ ...state, [name]: newValue });
  };

  return (
    <div className='flex-basic'>
      <input
        className='text-center'
        type='text'
        name='num1'
        value={state.num1}
        ref={firstInputRef}
        onChange={(e) => onChangeInput(e, minInputRef, lengthState.num1)}
        disabled={disabled}
        style={isSaveClicked ? applyBorderStyle(state.num1, 'red', 'num1') : undefined}
      />
      <span className='dash'>-</span>
      <input
        className='text-center'
        type='text'
        name='num2'
        value={state.num2}
        ref={minInputRef}
        onChange={(e) => onChangeInput(e, lastInputRef, lengthState.num2)}
        disabled={disabled}
        style={isSaveClicked ? applyBorderStyle(state.num2, 'red', 'num2') : undefined}
      />
      <span className='dash'>-</span>
      <input
        className='text-center'
        type='text'
        name='num3'
        value={state.num3}
        ref={lastInputRef}
        onChange={(e) => onChangeInput(e, null, lengthState.num3)}
        disabled={disabled}
        style={isSaveClicked ? applyBorderStyle(state.num3, 'red', 'num3') : undefined}
      />
    </div>
  );
};

export default NumbersInput;
