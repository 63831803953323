/**
 * 작성자 : 홍선영
 * 날짜 : 2023.05.15
 * 기능 : 날짜선택 캘린더
 */

import React, { useState, useEffect, forwardRef, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { ko, enUS } from 'date-fns/esm/locale';
import InputMask from 'react-input-mask';
import { applyBorderStyle } from '../utils/applyBorderStyle';
import { formatDateToStr, isValidYYYYMMDD } from '../utils/formatDate';
import i18n from '../translation/i18n';
import { useTranslation } from 'react-i18next';

const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(customParseFormat);

const StyledDatePicker = styled(DatePicker)`
  border: none;
  font-weight: 400;
  line-height: 100%;
  background-color: rgb(243, 246, 249);
  color: #707070;
`;

const CustomInput = forwardRef(({ value, onChange, datePickerRef, setDate, setWbdateSelect, isSaveClicked, inputValue, setInputValue, disabled, showTimeSelect, ...rest }: any, ref) => {
  const [lackValue, setLackValue] = useState(false);
  const handleClick = () => {
    if (setWbdateSelect) {
      setWbdateSelect(true);
    }
    if (datePickerRef.current !== null) {
      datePickerRef.current.setOpen(true);
    }
  };

  const onChangeMaskValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!showTimeSelect) {
      const maskValue = e.currentTarget.value;
      const removeDash = maskValue?.replace(/[_-]/g, '');
      setInputValue(removeDash);
      if ((removeDash !== '' && removeDash.length > 0 && removeDash.length < 8) || (removeDash.length === 8 && !isValidYYYYMMDD(removeDash))) {
        setLackValue(true);
      } else setLackValue(false);
    }
  };

  const handleInputFocus = (e: any) => {
    e.target.select();
    handleClick();
    // if (datePickerRef.current) {
    //   datePickerRef.current.setSelected(null, false);
    // }
  };

  return (
    <InputMask
      disabled={disabled}
      className='inputMask'
      mask={showTimeSelect ? `${inputValue}` : '9999-99-99'}
      alwaysShowMask={false}
      value={inputValue}
      onChange={(e) => onChangeMaskValue(e)}
      /**
       * 수정자 : 한영광
       * 수정날짜 : 2023-10-13
       * 수정내용 : 날짜 데이터를 드래그로 선택할 때 커서가 인풋창에 머물러있으면 선택이 풀리는 문제가 있어서 onFocus일 때 달력이 열리도록 수정함
       */
      // onClick={handleClick}
      // onMouseDown={handleClick}
      onFocus={(e) => handleInputFocus(e)}
      style={lackValue || isSaveClicked ? applyBorderStyle(inputValue?.replace(/[_-]/g, ''), 'red', 'date') : undefined}
    />
  );
});
CustomInput.displayName = 'CustomInput';

const DatePickerComponent = ({
  startDate = '',
  setDate,
  setWbdateSelect,
  startRange,
  endRange,
  popperPlacement,
  isSaveClicked,
  disabled,
  inline = false,
  renderDayPointer = false,
  records,
  showTimeSelect = false,
  timeIntervals = 30,
}: any) => {
  const [inputValue, setInputValue] = useState(startDate);
  const datePickerRef = useRef(null);
  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const range = (start: any, end: any, step = 1) => {
    const result = [];
    for (let i = start; i < end; i += step) {
      result.push(i);
    }
    return result;
  };
  const { t } = useTranslation();

  /**
   * 수정자 : 한영광
   * 수정날짜 : 2023-07-04
   * 수정내용 : 인자값에 연도범위 시작(startRnage), 종료(endRange) 추가하여 페이지별로 연도 범위를 설정할 수 있게 함
   */
  // const years = range(1990, new Date().getFullYear() + 100, 1);
  const years = range(startRange || new Date().getFullYear() - 80, endRange || new Date().getFullYear() + 20, 1);
  const [format, setFormat] = useState(!showTimeSelect ? (startDate && startDate.length === 8 ? dayjs(startDate, 'YYYYMMDD').toDate() : null) : dayjs(startDate, 'YYYYMMDDHHMMSS').toDate());

  useEffect(() => {
    if ((startDate && startDate.length === 8) || startDate === '') {
      setInputValue(startDate);
    }

    if (showTimeSelect && startDate.length === 19) {
      setInputValue(startDate);
    }
  }, [startDate]);

  useEffect(() => {
    if (!showTimeSelect) {
      setDate(inputValue);
      setFormat(inputValue && inputValue.length === 8 ? dayjs(inputValue, 'YYYYMMDD').toDate() : null);
    } else {
      setDate(inputValue);
      setFormat(inputValue && inputValue.length === 19 ? dayjs(inputValue, 'YYYYMMDDhhmmss').toDate() : null);
    }
  }, [inputValue]);

  const onChangeDatePicker = (date: any, isTimeIncluded: boolean) => {
    if (inputValue?.length === 8) {
      setDate(dayjs(date).format('YYYYMMDD'));
    }
    if (date) {
      if (!isTimeIncluded) {
        setDate(dayjs(date).format('YYYYMMDD'));
        setInputValue(dayjs(date).format('YYYYMMDD'));
      } else {
        setDate(dayjs(date).format('YYYYMMDD HH:mm:ss'));
        setInputValue(dayjs(date).format('YYYY-MM-DD HH:mm:ss'));
      }
    }
  };

  // 날짜를 표시할 때 커스텀 콘텐츠를 렌더링하는 함수
  const renderDayContents = (day: number, date: Date) => {
    // 레코드가 존재하는 경우에만 작업을 수행
    if (records) {
      const dateStr = formatDateToStr(date);
      // 레코드를 확인하고 조건에 따라 UI 업데이트(dot 표시)
      return (
        <div>
          {day}
          {records[dateStr] && <span className='redDot' />}
        </div>
      );
    }
    return null;
  };

  // 월이 변경될 때 호출되는 핸들러 함수, 월 셀렉트를 변경하면 해당 월의 1일로 setState
  const handleMonthChange = (date: Date) => {
    const updatedDate = new Date(date.getFullYear(), date.getMonth(), 1);
    setDate(dayjs(updatedDate).format('YYYYMMDD'));
  };

  return (
    <StyledDatePicker
      disabled={disabled}
      popperPlacement={popperPlacement || 'auto'}
      ref={datePickerRef}
      locale={i18n.language === 'ko-KR' ? ko : enUS}
      dateFormat={!showTimeSelect ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm'}
      timeFormat='HH:mm'
      selected={format}
      onChange={(date: any) => {
        onChangeDatePicker(date, showTimeSelect);
      }}
      inline={inline}
      renderDayContents={renderDayPointer && renderDayContents}
      onMonthChange={renderDayPointer && handleMonthChange}
      showTimeSelect={showTimeSelect}
      timeIntervals={timeIntervals}
      timeCaption={showTimeSelect && t('시간')}
      showTimeSelectOnly={false}
      customInput={
        <CustomInput
          setDate={setDate}
          datePickerRef={datePickerRef}
          setWbdateSelect={setWbdateSelect}
          isSaveClicked={isSaveClicked}
          inputValue={inputValue}
          setInputValue={setInputValue}
          disabled={disabled}
          showTimeSelect={showTimeSelect}
        />
      }
      renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
        <div className='custom-header'>
          <div className='arrow' onClick={decreaseMonth} role='button' tabIndex={0}>
            <span className='material-symbols-outlined'>arrow_left</span>
          </div>
          <div className='selectGroup'>
            <div className='selectBox'>
              <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(Number(value))}>
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <span className='material-symbols-outlined'>keyboard_arrow_down</span>
            </div>
            <div className='selectBox'>
              <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                {months.map((option) => (
                  <option key={option} value={option}>
                    <div>
                      {option}
                      {t('월')}
                    </div>
                  </option>
                ))}
              </select>
              <span className='material-symbols-outlined'>keyboard_arrow_down</span>
            </div>
          </div>
          <div className='arrow' role='button' tabIndex={0} onClick={increaseMonth}>
            <span className='material-symbols-outlined'>arrow_right</span>
          </div>
        </div>
      )}
    />
  );
};

export default DatePickerComponent;
