/**
 * 작성자 : 김광민
 * 날짜 : 2023.12.19
 * 경로 : 터널 관리 - 구역 현황 관리 - 차트 모달
 */

import { Modal, ModalBackground } from '../../assets/styles/Modal';
import TunnelButterFlyChart from '../chart/TunnelButterFlyChart';

type Props = {
  onClickClose?: () => void;
  data: TareaData[];
};

const DivergentStackedBarsModal = ({ onClickClose, data: propsData }: Props) => {
  return (
    <ModalBackground onClick={onClickClose} role='presentation'>
      <Modal minWidth={60}>
        <div
          className='modal tunnelChart'
          role='presentation'
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className='inputForm-head'>
            <div className='modalHeaderTitle'>굴진 진행 현황</div>
            <div className='closeBtn' onClick={onClickClose} role='presentation'>
              <span className='material-symbols-rounded'>close</span>
            </div>
          </div>
          <TunnelButterFlyChart data={propsData} />
        </div>
      </Modal>
    </ModalBackground>
  );
};

export default DivergentStackedBarsModal;
