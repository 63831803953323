/**
 * 작성자 : 홍선영
 * 날짜 : 2023.06.12
 * 경로 :  설정관리-협력업체관리-협력업체정보탭(현장관리자) 인풋폼
 */

import { useEffect, Dispatch, SetStateAction, useState, useRef } from 'react';
import styled from 'styled-components';
import { BtnBlue, BtnRed } from '../Button';
import Input from '../Input';
import NumbersInput from '../NumbersInput';
import { COMCD_USE_YN } from '../../_constants';
import { AddressSearch } from '../AddressSearch';
import { applyBorderStyle } from '../../utils/applyBorderStyle';
import { useSetAuth } from '../../utils/useSetAuth';
import SelectBox from '../SelectBox';
import { useTranslation } from 'react-i18next';

interface IUserInputFormStyle {
  width: string;
  flexDirection?: string;
}

interface IUserInputForm {
  style: IUserInputFormStyle;
  state: any;
  setState: Dispatch<SetStateAction<any>>;
  isNewAdd?: boolean;
  userInfoInputFormRef: React.RefObject<any>;
  onClickNewAdd: () => void;
  onClickSave: () => void;
  onClickDelete: () => void;
  sjTelNumState: any;
  setSjTelNumState: Dispatch<SetStateAction<any>>;
  sjChargetelNumState: any;
  setSjChargetelNumState: Dispatch<SetStateAction<any>>;
  sjNumber: any;
  setSjNumber: Dispatch<SetStateAction<any>>;
  inputFormUseYn: any;
  setInputFormUseYn: Dispatch<SetStateAction<any>>;
  useYnComCdList: any;
  isSaveClicked: boolean;
}
const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 0.5rem;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;
const Root = styled.div<IUserInputFormStyle>`
  padding: 0.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* width: ${(props) => props.width}; */
  height: fit-content;
  overflow: auto;
  justify-content: flex-start;
  .inputForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem;
    button {
      height: 2.5rem;
      font-size: 0.75rem;
      flex-shrink: 0;
      padding: 0 0.75rem;
      border-radius: 0.25rem;
    }
    button.gray {
      font-weight: 500;
      color: ${({ theme }: { theme: any }) => theme.color.zinc_200};
    }
    label {
      width: 6rem;
      font-weight: 500;
      flex-shrink: 0;
      font-size: 0.875rem;
    }
    .inputForm-group-1280 {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (min-width: 1280px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
      }
    }
    .inputForm-group-1536 {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (min-width: 1536px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
      }
    }
    .inputForm-group-1536.withBtn {
      flex-direction: row;
      gap: 0.5rem;
      button {
        transition: none;
      }
      @media (min-width: 1536px) {
        gap: 2rem;
        button {
          margin-left: 0;
          width: fit-content;
        }
      }
    }
    .inputForm-group-1536.withBtn.inputColumnFour {
      input {
        padding: 0;
      }
    }
    .inputForm-row.labelInInput {
      display: flex;
      gap: 0.5rem;
      flex-grow: 1;
      > div {
        flex-grow: 1;
      }
    }
    .inputForm-row.labelOutInput,
    .inputForm-row.labelInInput > div > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      flex-grow: 1;
      input {
        flex-shrink: 1;
        height: 2.5rem;
        font-size: 0.875rem;
        padding: 0 0.5rem;
        &:disabled {
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          color: ${({ theme }: { theme: any }) => theme.text_disabled};
        }
      }
      > div.viewOnly {
        height: 2.5rem;
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_primary};
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        border-radius: 0.25rem;
        padding: 0 0.75rem;
        display: flex;
        align-items: center;
      }
      > div {
        flex-grow: 1;
        > ul {
          height: 2.5rem;
          li {
            display: flex;
            width: 100%;
            max-width: 100%;
            span {
              flex-grow: 1;
              width: 4rem;
            }
          }
        }
      }
    }
    .detailInfo-group {
      margin: 1rem 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      @media (min-width: 1536px) {
        grid-template-columns: repeat(3, 1fr);
      }
      .flex-col.detailInfo {
        flex-grow: 1;
        width: 100%;
        div {
          height: 2.5rem;
          color: ${({ theme }: { theme: any }) => theme.text_primary};
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          border-radius: 0.25rem;
          display: flex;
          align-items: center;
          padding: 0 0.5rem;
          font-size: 0.875rem;
        }
      }
    }
    /* .inputForm-row.labelInInput > div > div {
    display: flex;
    background-color: gray;
  } */
  }
  input {
    :disabled {
      background-color: #eeeeee;
    }
  }
  .required {
    &::after {
      content: '*';
      padding: 0 0.2rem;
      color: red;
    }
  }
  @media screen and (max-width: 1023px) {
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    /* padding-top: 5rem; */
    height: fit-content;
    overflow: none;
  }
`;

const UserInfoInputFormSiteJoin = ({
  style,
  state,
  setState,
  isNewAdd,
  userInfoInputFormRef,
  onClickNewAdd,
  onClickSave,
  onClickDelete,
  sjTelNumState,
  setSjTelNumState,
  sjChargetelNumState,
  setSjChargetelNumState,
  sjNumber,
  setSjNumber,
  inputFormUseYn,
  setInputFormUseYn,
  useYnComCdList,
  isSaveClicked,
}: IUserInputForm) => {
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const sjCeonameRef = useRef<HTMLInputElement>(null);
  const sjChargenameRef = useRef<HTMLInputElement>(null);
  const telLengthState = { num1: 3, num2: 4, num3: 4 }; // 전화번호 입력 길이
  const sjNumLengthState = { num1: 3, num2: 2, num3: 5 }; // 사업자 등록번호 입력 길이
  const [sjNumLastInput, setSjNumLastInput] = useState(false); // 사업자번호 마지막인풋 길이충족 여부
  const [sjTelNumLastInput, setSjTelNumLastInput] = useState(false); // 대표 전화번호 마지막인풋 길이충족 여부

  useEffect(() => {
    if (isNewAdd && state.sjTel === '') {
      setSjTelNumState((prev: any) => ({ ...prev, num1: '', num2: '', num3: '' }));
    } else if (state.sjTel) {
      const splitNumber = state.sjTel.split('-');

      setSjTelNumState((prev: any) => ({
        ...prev,
        num1: splitNumber[0],
        num2: splitNumber[1],
        num3: splitNumber[2],
      }));
    }
  }, [state.sjTel, isNewAdd]);

  useEffect(() => {
    if (isNewAdd && state.sjChargetel === '') {
      setSjChargetelNumState((prev: any) => ({ ...prev, num1: '', num2: '', num3: '' }));
    } else if (state.sjChargetel) {
      const splitNumber = state.sjChargetel.split('-');

      setSjChargetelNumState((prev: any) => ({
        ...prev,
        num1: splitNumber[0],
        num2: splitNumber[1],
        num3: splitNumber[2],
      }));
    }
  }, [state.sjChargetel, isNewAdd]);

  useEffect(() => {
    if (isNewAdd && state.sjNum === '') {
      setSjNumber((prev: any) => ({ ...prev, num1: '', num2: '', num3: '' }));
    } else if (state.sjNum) {
      const splitNumber = state.sjNum.split('-');

      setSjNumber((prev: any) => ({
        ...prev,
        num1: splitNumber[0],
        num2: splitNumber[1],
        num3: splitNumber[2],
      }));
    }
  }, [state.sjNum, isNewAdd]);

  useEffect(() => {
    if (sjNumLastInput) {
      sjCeonameRef.current?.focus();
    }
  }, [sjNumLastInput]);

  useEffect(() => {
    if (sjTelNumLastInput) {
      sjChargenameRef.current?.focus();
    }
  }, [sjTelNumLastInput]);

  return (
    <>
      <Root width={style.width}>
        <div className='inputForm' ref={userInfoInputFormRef}>
          {!isNewAdd && (
            <div className='inputForm-group-1536 withBtn'>
              <div className='inputForm-row labelOutInput'>
                <label htmlFor='code'>{t('코드')}</label>
                <div className='viewOnly'>{state.sjCd}</div>
              </div>
            </div>
          )}
          <div className='inputForm-group-1536'>
            <div className='inputForm-row labelInInput'>
              <Input
                type='text'
                className='required'
                label={t('협력업체명')}
                name='sjName'
                state={state}
                setState={setState}
                disabled={!auth.updateAuth}
                getBorderStyle={isSaveClicked ? applyBorderStyle(state.sjName, 'red', 'sjName') : undefined}
              />
            </div>
            <div className='inputForm-row labelOutInput'>
              <label htmlFor='sjNum'>{t('사업자 번호')}</label>
              <NumbersInput state={sjNumber} setState={setSjNumber} lengthState={sjNumLengthState} setIsLastInputCompleted={setSjNumLastInput} disabled={!auth.updateAuth} />
            </div>
          </div>

          <div className='inputForm-group-1536'>
            <div className='inputForm-row labelInInput'>
              <Input type='text' label={t('대표자명')} name='sjCeoname' state={state} setState={setState} inputRef={sjCeonameRef} disabled={!auth.updateAuth} />
            </div>
            <div className='inputForm-row labelOutInput'>
              <label htmlFor='sjTel'>{t('대표 전화번호')}</label>
              <NumbersInput state={sjTelNumState} setState={setSjTelNumState} lengthState={telLengthState} setIsLastInputCompleted={setSjTelNumLastInput} disabled={!auth.updateAuth} />
            </div>
          </div>

          <div className='inputForm-group-1536'>
            <div className='inputForm-row labelInInput'>
              <Input type='text' label={t('담당자명')} name='sjChargename' state={state} setState={setState} inputRef={sjChargenameRef} disabled={!auth.updateAuth} />
            </div>
            <div className='inputForm-row labelOutInput'>
              <label htmlFor='sjChargetel'>{t('담당자 전화번호')}</label>
              <NumbersInput state={sjChargetelNumState} setState={setSjChargetelNumState} lengthState={telLengthState} disabled={!auth.updateAuth} />
            </div>
          </div>

          <div className='inputForm-group-1536 withBtn'>
            <div className='inputForm-row labelOutInput content-overflow'>
              <label htmlFor='postNum'>{t('우편번호')}</label>
              <Input type='text' id='postNum' name='postNum' state={state} setState={setState} />
            </div>
            <div className='inputForm-row labelOutInput'>
              <AddressSearch setState={setState} disabled={!auth.updateAuth} />
            </div>
          </div>

          <div className='inputForm-group-1536'>
            <div className='inputForm-row labelOutInput'>
              <label htmlFor='address_1'>{t('주소')}</label>
              <Input type='text' id='address_1' name='address1' state={state} setState={setState} />
            </div>
            <div className='inputForm-row labelOutInput'>
              <label htmlFor='address_2'>{t('상세주소')}</label>
              <Input type='text' id='address_2' name='address2' state={state} setState={setState} placeholder='' disabled={!auth.updateAuth} maxLength={50} />
            </div>
          </div>

          <div className='inputForm-row labelInInput'>
            <Input label={t('비고')} type='text' id='bigo' name='bigo' state={state} setState={setState} disabled={!auth.updateAuth} maxLength={200} />
          </div>

          <div className='inputForm-group-1536'>
            <div className='inputForm-row labelOutInput'>
              <label htmlFor='useYn'>{t('사용유무')}</label>
              <SelectBox
                options={useYnComCdList}
                defaultOption={isNewAdd ? t('사용') : t(inputFormUseYn.cdName)}
                state={inputFormUseYn}
                setState={setInputFormUseYn}
                stateKey={COMCD_USE_YN}
                initiateKey={inputFormUseYn[COMCD_USE_YN]}
                grantChk={!auth.updateAuth}
              />
            </div>
          </div>

          {!isNewAdd && (
            <div className='detailInfo-group'>
              <div className='flex-col detailInfo'>
                <label htmlFor='createDate'>{t('등록일자')}</label>
                <div>{state?.wDate}</div>
              </div>
              <div className='flex-col detailInfo'>
                <label htmlFor='createUser'>{t('등록자')}</label>
                <div>{state.writer}</div>
              </div>
              <div className='flex-col detailInfo'>
                <label htmlFor='updateDate'>{t('수정일자')}</label>
                <div>{state.eDate}</div>
              </div>
              <div className='flex-col detailInfo'>
                <label htmlFor='updateUser'>{t('수정자')}</label>
                <div>{state.editor}</div>
              </div>
            </div>
          )}
        </div>
      </Root>
      <ButtonsWrapper>
        {/* {auth.createAuth && auth.updateAuth && <BtnBlue onClick={onClickNewAdd}>신규등록</BtnBlue>} */}
        {!isNewAdd && auth.deleteAuth && <BtnRed onClick={onClickDelete}>{t('삭제')}</BtnRed>}
        {auth.updateAuth && <BtnBlue onClick={onClickSave}>{t('저장')}</BtnBlue>}
      </ButtonsWrapper>
    </>
  );
};

export default UserInfoInputFormSiteJoin;
