/**
 * 작성자 : 홍선영
 * 날짜 : 2024.06.03
 * 경로 : 안전관리 - 근로자 안전교육 보고서
 */

import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { LIST_COUNT } from '../../../_constants';
import { IUser, userState } from '../../../atoms';
import i18n from '../../../translation/i18n';
import { ContentsContainerRoot } from '../../../assets/styles/ContentsContainerRoot';
import { useGetFrozenCount } from '../../../utils/getFrozenCount';
import useSetListCount from '../../../utils/useSetListCount';
import { useFetchNormalCodeList } from '../../../services/useSetCodeListInSelectBoxForm';
import SafeeduReportSearchOption from './safeeduReportSearchOption';
import TuiGrid from '../../../components/Table/TuiGrid';
import IssueGuide from '../../../components/IssueGuide';
import TuiGridWrapper from '../../../components/Table/TuiGridWrapper';
import CustomCheckBoxRenderer from '../../../components/CustomCheckBoxRenderer';
import CustomImageRenderer from '../../../components/CustomImageRenderer';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import { PulseLoader } from 'react-spinners';
import { logPost } from '../../../services/log';

const Root = styled(ContentsContainerRoot)`
  .checks {
    padding-right: 1rem;
  }
`;

const SafeeduReport = () => {
  const userInfo = useRecoilValue<IUser>(userState);
  const { eExcelYn, eListYn } = userInfo;
  const { t } = useTranslation();
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [tableState, setTableState] = useState<any[]>([]);
  const [filterTableState, setFilterTableState] = useState<any[]>([]);
  const componentRef = useRef<HTMLDivElement>(null);
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  const [tuiWidth, setTuiWidth] = useState<null | number>(null);
  const [columns, setColumns] = useState<any[]>([]);
  const [excelBtn, setExcelBtn] = useState(false);
  const [rowKey, setRowKey] = useState('');
  const { listCount, setListCount } = useSetListCount('15'); // 검색줄수설정 훅
  const frozenCount = useGetFrozenCount();
  const [refetchFlag, setRefetchFlag] = useState(false);
  const { data: safeeduList, isLoading: isLoadingSafeeduList, isFetching: isFetchingSafeeduList } = useFetchNormalCodeList('F');
  const [showSign, setShowSign] = useState(false);

  useEffect(() => {
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '안전 교육 > 근로자 안전교육 보고서',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    if (componentRef.current !== null) setTuiHeight(componentRef.current.offsetHeight);
  }, [componentRef.current?.offsetHeight]);

  useEffect(() => {
    if (componentRef.current !== null && componentRef.current !== undefined) setTuiWidth(componentRef.current?.offsetWidth);
  }, [componentRef.current?.offsetWidth]);

  const fixedPrefixColumns = [
    {
      header: t('근로자명'),
      name: 'wName',
      sortable: true,
      minWidth: 130,
      renderer: { classNames: ['text_secondary'] },
    },
    {
      header: t('교육일자'),
      name: 'sDate',
      align: 'center',
      sortable: true,
      minWidth: 120,
    },
    {
      header: t('협력업체'),
      name: 'sjName',
      sortable: true,
      filter: 'select',
      className: '',
      width: 160,
      renderer: { classNames: ['text_secondary'] },
    },
  ];

  const fixedSuffixColumns = [
    {
      header: t('취업일자'),
      name: 'wJobdate',
      align: 'center',
      sortable: true,
      minWidth: 120,
    },
    {
      header: t('퇴직일자'),
      name: 'wRetiredate',
      align: 'center',
      sortable: true,
      minWidth: 120,
    },
    {
      header: t('근로 상태'),
      name: 'wWorkstatusName',
      align: 'center',
      sortable: true,
      filter: 'select',
      minWidth: 120,
    },
  ];

  const prejobtypeColumn = {
    header: t('공종'),
    name: 'wPrejobtypeName',
    sortable: true,
    filter: 'select',
    minWidth: 120,
  };

  const jobtypeColumn = {
    header: t('직종'),
    name: 'wJobtypeName',
    sortable: true,
    filter: 'select',
    minWidth: 120,
  };

  const hasSignFileColumn = {
    header: t('서명'),
    name: 'hasSignFile',
    align: 'center',
    sortable: true,
    renderer: {
      type: CustomCheckBoxRenderer,
    },
  };

  const signFileColumn = {
    header: t('서명'),
    name: 'signFile',
    align: 'center',
    sortable: true,
    renderer: {
      type: CustomImageRenderer,
    },
  };

  useEffect(() => {
    const col = [...fixedPrefixColumns, userInfo.prejobtypeYn !== 'Y' ? jobtypeColumn : prejobtypeColumn, jobtypeColumn, showSign ? signFileColumn : hasSignFileColumn, ...fixedSuffixColumns];
    setColumns(col);
  }, [userInfo.prejobtypeYn, i18n.language, showSign]);

  const loading = isFetching || isLoadingSafeeduList || isFetchingSafeeduList;

  if (isError) return <IssueGuide />;

  return (
    <>
      <div className='content-container oneColumn'>
        <Root loading={loading}>
          <SafeeduReportSearchOption
            setIsFetching={setIsFetching}
            setIsError={setIsError}
            tableState={tableState}
            setTableState={setTableState}
            filterTableState={filterTableState}
            setFilterTableState={setFilterTableState}
            setRowKey={setRowKey}
            listCount={listCount}
            setListCount={setListCount}
            refetchFlag={refetchFlag}
            setRefetchFlag={setRefetchFlag}
            safeeduList={safeeduList && safeeduList}
            showSign={showSign}
            setShowSign={setShowSign}
          />
          <TuiGridWrapper componentRef={componentRef}>
            {tuiWidth !== null && (
              <TuiGrid
                data={tableState}
                filterTableState={filterTableState}
                setFilterTableState={setFilterTableState}
                columns={columns}
                perPage={Number(listCount[LIST_COUNT])}
                excelBtn={excelBtn}
                setExcelBtn={setExcelBtn}
                usePagenation
                scrollX
                height={tuiHeight}
                rowKey={rowKey}
                eListYn={eListYn}
                eExcelYn={eExcelYn}
                rowHeight={48}
                frozenCount={frozenCount}
                setRowKey={setRowKey}
              />
            )}
          </TuiGridWrapper>
        </Root>
      </div>
      {loading && (
        <LoadingModalBackground>
          <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
        </LoadingModalBackground>
      )}
    </>
  );
};

export default SafeeduReport;
