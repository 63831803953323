/**
 * 작성자 : 홍선영
 * 날짜 : 2023.07.20
 * 기능 : f9키 엔터 useState hook
 */

import { useEffect, useState } from 'react';

const useOnKeydownF9 = () => {
  const [isF9Pressed, setIsF9Pressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'F9' || event.keyCode === 120) {
        setIsF9Pressed(true);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      setIsF9Pressed(false);
    };
  }, []);

  return { isF9Pressed, setIsF9Pressed };
};
export default useOnKeydownF9;
