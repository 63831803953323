import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { todayYYYYMMDD } from '../../utils/formatDate';
import { getPreviousDays, getPreviousMonthsDateRanges, getPreviousWeeks } from '../../utils/getPrevWeeks';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { arraySortByAscdOrder } from '../../utils/arraySortByAscdOrder';
import { getWeekStartAndEnd } from '../../utils/getWeekStartAndEnd';
import { PulseLoader } from 'react-spinners';
import PieChart from '../PieChart';
import { InputTable } from '../../assets/styles/InputTable';
import BarChart from '../BarChart';
import { apiGet } from '../../services/_common';

// 출역 종합 정보
export const AttendTotalInfo = ({ name, userInfo, theme }: any) => {
  type IDayAttend = {
    name: string;
    count: number;
  };
  const { t } = useTranslation();
  const todayjs = dayjs();
  const toDay = todayYYYYMMDD();
  const twoWeekDays = getPreviousDays(toDay, 14);
  const eightWeeks = getPreviousWeeks(8);
  const thisWeek = getPreviousWeeks(1);
  const twelveMonths = getPreviousMonthsDateRanges(14);
  const [displayTwelveMonths, setDisplayTwelveMonths] = useState<any>([{ thisMonth: '', lastMonth: '', twoMonthsBefore: '' }]);
  const thisMonth = getPreviousMonthsDateRanges(1);
  const [innerTab, setInnerTab] = useState<number>(0);
  const [dayAttendTable, setDayAttendTable] = useState<IDayAttend[]>([]);
  const [totalAttendTable, setTotalAttendTable] = useState<IDayAttend[]>([]);
  const [oldAttendPieData, setOldAttendPieData] = useState<IDayAttend[]>([]); // 고령근로자 파이차트 데이터
  const [tableState, setTableState] = useState<IDayAttend[]>([]);
  const [weekAttendTable, setWeekAttendTable] = useState<any[]>([]); // 주간 출역내역
  const [monthAttendTable, setMonthAttendTable] = useState<any[]>([]); // 월간 출역내역
  const [totalCount, setTotalCount] = useState<number>();
  const [oldTotalCount, setOldTotalCount] = useState<number>();
  const [selectedDay, setSelectedDay] = useState(todayjs);
  const [selectedWeek, setSelectedWeek] = useState(thisWeek[0]);
  const [selectedMonth, setSelectedMonth] = useState(thisMonth[0]);
  const [dates, setDates] = useState({ today: '', yesterday: '', twoDaysBefore: '' });
  const [weeks, setWeeks] = useState<any>({ thisWeek: '', lastWeek: '', twoWeeksBefore: '' });
  const [months, setMonths] = useState<any>({ thisMonth: '', lastMonth: '', twoMonthsBefore: '' });
  const [attendBarData, setAttendBarData] = useState<any[]>([]);
  const [sjCdList, setSjCdList] = useState<any[]>([]);

  const siteJoinCdQuery = useQuery(['siteJoinGet2', userInfo.hCd, userInfo.sCd], () => apiGet({ path: '/siteJoin', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, delYnCheck: 'Y' } }), {
    retry: 3,
    enabled: !!userInfo.hCd && !!userInfo.sCd,
  });

  useEffect(() => {
    if (siteJoinCdQuery.isSuccess && siteJoinCdQuery.data && siteJoinCdQuery.data.data.statusCode === 200) {
      const { siteJoinList } = siteJoinCdQuery.data.data.data;
      if (siteJoinList.length > 0) {
        const formatSelectBoxFormList = siteJoinList.map((el2: any, i: number) => {
          return { type: 'sjCd', sjCd: el2.sjCd, name: el2.sjName };
        });
        const sortedArray: any = arraySortByAscdOrder(formatSelectBoxFormList, 'sjCd');
        setSjCdList(sortedArray);
      }
    }
  }, [siteJoinCdQuery.isSuccess, siteJoinCdQuery.isRefetching]);

  const getPastDates = () => {
    // 선택일 기준으로 선택일,전일,2일전 날짜 구하기
    const today = selectedDay.format('YYYY-MM-DD');
    const yesterday = selectedDay.subtract(1, 'day').format('YYYY-MM-DD');
    const twoDaysBefore = selectedDay.subtract(2, 'day').format('YYYY-MM-DD');
    return {
      today,
      yesterday,
      twoDaysBefore,
    };
  };

  const getPastWeeks = () => {
    // 선택주 기준으로 선택주,전주,2주전 날짜 구하기
    const week1 = getWeekStartAndEnd(selectedWeek.startDate, 1);
    const week2 = getWeekStartAndEnd(selectedWeek.startDate, 2);
    return {
      thisWeek: { startDate: selectedWeek.startDate, endDate: selectedWeek.endDate },
      lastWeek: { startDate: week1.startDate, endDate: week1.endDate },
      twoWeeksBefore: { startDate: week2.startDate, endDate: week2.endDate },
    };
  };

  const getPastMonths = () => {
    const index = twelveMonths.findIndex((item) => item.month === selectedMonth.month && item.year === selectedMonth.year);

    if (index < 0 || index < 2) {
      throw new Error('Invalid month/year selection or insufficient data');
    }

    return {
      thisMonth: {
        startDate: twelveMonths[index].startDate,
        endDate: twelveMonths[index].endDate,
      },
      lastMonth: {
        startDate: twelveMonths[index - 1].startDate,
        endDate: twelveMonths[index - 1].endDate,
      },
      twoMonthsBefore: {
        startDate: twelveMonths[index - 2].startDate,
        endDate: twelveMonths[index - 2].endDate,
      },
    };
  };

  useEffect(() => {
    // 선택날짜가 변경될때마다 선택일,전일,2일전 날짜 세팅
    setDates(getPastDates());
  }, [selectedDay]);

  useEffect(() => {
    // 선택 주가 변경될때마다 주,전일,2주전 날짜 세팅
    setWeeks(getPastWeeks());
  }, [selectedWeek]);

  useEffect(() => {
    const newArray = twelveMonths.filter((_, index) => index > 1);
    setDisplayTwelveMonths(newArray);
    // 선택 주가 변경될때마다 주,전일,2주전 날짜 세팅
    setMonths(getPastMonths());
  }, [selectedMonth]);

  useEffect(() => {
    // innerTab이 변경될 때마다 오늘날짜(이번주)로 초기화
    /**
     * 수정자 : 한영광
     * 수정일자 : 2023-09-20
     * 수정내용 : selectedDay를 사용하는 탭은 선택한 탭을 유지해달라는 요청사항이 있어서 탭 변경 시 초기화하는 코드 주석 처리함
     */
    // setSelectedDay(todayjs);
    setSelectedWeek(thisWeek[0]);
    setSelectedMonth(thisMonth[0]);
  }, [innerTab]);

  // 통합(고령+일반)출역조회 API
  const getAttendDayAPI = async (date1Param: string, date2Param: string, type: string) => {
    const aDate = { aDate1: date1Param, aDate2: date2Param };
    const req = { ...aDate, hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/attend/day', req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      const { attendList } = data;

      // sjCd별로 그룹핑
      const grouped = attendList.reduce((acc: any, item: any) => {
        const group = acc.find((g: any) => g.sjCd === item.sjCd);
        const findSjCdName = sjCdList.find((el: any) => el.sjCd === item.sjCd);

        if (group) {
          group.attendData.push(item);
        } else {
          acc.push({
            sjCd: item.sjCd,
            sjName: findSjCdName !== undefined ? findSjCdName.name : '',
            attendData: [item],
          });
        }
        return acc;
      }, []);

      let groupedCountResult;

      if (type === 'day') {
        // 오늘/어제/그제 카운트
        groupedCountResult = grouped.map((group: any) => {
          const count = {
            todayCnt: 0,
            yesterdayCnt: 0,
            twoDaysBeforeCnt: 0,
          };

          group.attendData.forEach((item: any) => {
            if (item.aDate === dates.today) count.todayCnt += 1;
            if (item.aDate === dates.yesterday) count.yesterdayCnt += 1;
            if (item.aDate === dates.twoDaysBefore) count.twoDaysBeforeCnt += 1;
          });

          return {
            sjCd: group.sjCd,
            sjName: group.sjName,
            ...count,
          };
        });
        setTableState(groupedCountResult);
        getOldAttendAPI(String(Number(toDay) - Number(`${userInfo.elderlyAgeSet}0000`)), groupedCountResult);
      }

      if (type === 'week') {
        const isInDateRange = (date: string, startDate: string, endDate: string): boolean => {
          // 해당날짜가 시작~끝 날짜 범위에 해당하는 지
          return date >= startDate && date <= endDate;
        };

        const getResult = (attend: any[]): any[] => {
          return attend.map((cooperation) => {
            let thisWeekCnt = 0;
            let lastWeekCnt = 0;
            let twoWeeksBeforeCnt = 0;

            // 금주, 전주, 2주전 카운트
            cooperation.attendData.forEach((el: any) => {
              if (isInDateRange(el.aDate, weeks.thisWeek.startDate, weeks.thisWeek.endDate)) {
                thisWeekCnt += 1;
              } else if (isInDateRange(el.aDate, weeks.lastWeek.startDate, weeks.lastWeek.endDate)) {
                lastWeekCnt += 1;
              } else if (isInDateRange(el.aDate, weeks.twoWeeksBefore.startDate, weeks.twoWeeksBefore.endDate)) {
                twoWeeksBeforeCnt += 1;
              }
            });

            return {
              sjCd: cooperation.sjCd,
              sjName: cooperation.sjName,
              thisWeekCnt,
              lastWeekCnt,
              twoWeeksBeforeCnt,
            };
          });
        };

        const result = getResult(grouped);
        setWeekAttendTable(result);
      }

      if (type === 'month') {
        const isInDateRange = (date: string, startDate: string, endDate: string): boolean => {
          // 해당날짜가 시작~끝 날짜 범위에 해당하는 지
          return date >= startDate && date <= endDate;
        };

        const getResult = (attend: any[]): any[] => {
          return attend.map((cooperation) => {
            let thisMonthCnt = 0;
            let lastMonthCnt = 0;
            let twoMonthsBeforeCnt = 0;

            // 금월, 전월, 2개월전 카운트
            cooperation.attendData.forEach((el: any) => {
              if (isInDateRange(el.aDate, months.thisMonth.startDate, months.thisMonth.endDate)) {
                thisMonthCnt += 1;
              } else if (isInDateRange(el.aDate, months.lastMonth.startDate, months.lastMonth.endDate)) {
                lastMonthCnt += 1;
              } else if (isInDateRange(el.aDate, months.twoMonthsBefore.startDate, months.twoMonthsBefore.endDate)) {
                twoMonthsBeforeCnt += 1;
              }
            });

            return {
              sjCd: cooperation.sjCd,
              sjName: cooperation.sjName,
              thisMonthCnt,
              lastMonthCnt,
              twoMonthsBeforeCnt,
            };
          });
        };

        const result = getResult(grouped);
        setMonthAttendTable(result);
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 고령근로자 출역조회 API
  const getOldAttendAPI = async (oldDataParam: string, groupedCountResultParamParam: any) => {
    const obj = { aDate1: dates.twoDaysBefore.replaceAll('-', ''), aDate2: dates.today.replaceAll('-', ''), oldDate1: '', oldDate2: oldDataParam };
    const req = { ...obj, hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/attend/old', req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      const { attendList } = data;
      const newArray = attendList.filter((el: any) => el.age !== null);

      // sjCd별로 그룹핑
      const grouped = newArray.reduce((acc: any, item: any) => {
        const group = acc.find((g: any) => g.sjCd === item.sjCd);
        const findSjCdName = sjCdList.find((el: any) => el.sjCd === item.sjCd);

        if (group) {
          group.attendData.push(item);
        } else {
          acc.push({
            sjCd: item.sjCd,
            sjName: findSjCdName !== undefined ? findSjCdName.name : '',
            attendData: [item],
          });
        }
        return acc;
      }, []);

      const groupedCountResultToday = grouped.map((group: any) => {
        const count = {
          todayCnt: 0,
        };
        group.attendData.forEach((item: any) => {
          if (item.aDate === dates.today) count.todayCnt += 1;
        });
        return {
          name: group.sjName,
          count: count.todayCnt,
        };
      });

      // 오늘/어제/그제 카운트
      const groupedCountResult = grouped.map((group: any) => {
        const count = {
          todayCnt: 0,
          yesterdayCnt: 0,
          twoDaysBeforeCnt: 0,
        };

        group.attendData.forEach((item: any) => {
          if (item.aDate === dates.today) count.todayCnt += 1;
          if (item.aDate === dates.yesterday) count.yesterdayCnt += 1;
          if (item.aDate === dates.twoDaysBefore) count.twoDaysBeforeCnt += 1;
        });

        return {
          sjCd: group.sjCd,
          sjName: group.sjName,
          ...count,
        };
      });
      const removeNoCount = groupedCountResultToday.filter((el: any) => el.count > 0);
      setOldAttendPieData(removeNoCount);
      setOldTotalCount(groupedCountResultToday.reduce((acc: number, curr: any) => acc + curr.count, 0));

      // Create a lookup table for oldAgeWorkerAttend
      const oldAgeLookup = groupedCountResult.reduce((acc: any, curr: any) => {
        acc[curr.sjCd] = curr;
        return acc;
      }, {});

      const result = groupedCountResultParamParam.map((item: any) => {
        // Fetch the corresponding old age data or default to zero counts
        const oldAgeData = oldAgeLookup[item.sjCd] || {
          todayCnt: 0,
          yesterdayCnt: 0,
          twoDaysBeforeCnt: 0,
        };

        return {
          sjCd: item.sjCd,
          sjName: item.sjName,

          normalWorkerTodayCnt: item.todayCnt - oldAgeData.todayCnt,
          normalWorkerYesterdayCnt: item.yesterdayCnt - oldAgeData.yesterdayCnt,
          normalWorkertwoDaysBeforeCnt: item.twoDaysBeforeCnt - oldAgeData.twoDaysBeforeCnt,

          oldWorkerTodayCnt: oldAgeData.todayCnt,
          oldWorkerYesterdayCnt: oldAgeData.yesterdayCnt,
          oldWorkertwoDaysBeforeCnt: oldAgeData.twoDaysBeforeCnt,

          totalWorkerTodayCnt: item.todayCnt,
          totalWorkerYesterdayCnt: item.yesterdayCnt,
          totalWorkertwoDaysBeforeCnt: item.twoDaysBeforeCnt,
        };
      });

      setTotalAttendTable(result);

      const normalWorkerToday = result.map((el: any) => el.normalWorkerTodayCnt);
      const oldWorkerToday = result.map((el: any) => el.oldWorkerTodayCnt);
      const data1 = {
        label: t('일반'),
        data: normalWorkerToday,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      };

      const data2 = {
        label: t('고령'),
        data: oldWorkerToday,
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      };

      setAttendBarData([data1, data2]);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 업체별 출역현황 조회 API
  const getAttendSitejoinAPI = async (date1Param: string, date2Param: string, type: string) => {
    const aDate = { aDate1: date1Param, aDate2: date2Param };
    const req = { ...aDate, hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/attend/sitejoin', req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      const { attendList } = data;
      const newArray = attendList.map((el: any) => {
        return { name: el.sjName, count: Number(el.cnt) };
      });
      setDayAttendTable(newArray);
      setTotalCount(newArray.reduce((acc: number, curr: any) => acc + curr.count, 0));
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  const onClickDate = (dateParam: string) => {
    setSelectedDay(dayjs(dateParam));
  };

  const onClickWeek = (weekParam: any) => {
    setSelectedWeek(weekParam);
  };

  const onClickMonth = (monthParam: any) => {
    setSelectedMonth(monthParam);
  };

  useEffect(() => {
    if (sjCdList?.length > 0 && userInfo.sCd !== '00000') {
      getAttendSitejoinAPI(dates.today.replaceAll('-', ''), dates.today.replaceAll('-', ''), 'day').then((res: any) => {
        if (res.status === 200) {
          getAttendDayAPI(dates.twoDaysBefore.replaceAll('-', ''), dates.today.replaceAll('-', ''), 'day');
        }
      });
    }
  }, [sjCdList, dates, userInfo.sCd]);

  useEffect(() => {
    if (sjCdList?.length > 0 && userInfo.sCd !== '00000') {
      getAttendDayAPI(weeks.twoWeeksBefore.startDate?.replaceAll('-', ''), weeks.thisWeek.endDate?.replaceAll('-', ''), 'week');
    }
  }, [weeks, userInfo.sCd]);

  useEffect(() => {
    if (sjCdList?.length > 0 && userInfo.sCd !== '00000') {
      getAttendDayAPI(months.twoMonthsBefore.startDate.replaceAll('-', ''), months.thisMonth.endDate.replaceAll('-', ''), 'month');
    }
  }, [months, userInfo.sCd]);

  const onClickRefresh = () => {
    siteJoinCdQuery.refetch();
  };

  if (!siteJoinCdQuery.isSuccess || siteJoinCdQuery.data.status !== 200 || siteJoinCdQuery.isRefetching) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }
  // 협력업체 일반
  const getNormalWorkerTodayCnt = () => {
    return totalAttendTable
      .map((el: any) => el.normalWorkerTodayCnt)
      .reduce((prev, cur) => {
        return prev + cur;
      }, 0);
  };
  // 협력업체 고령
  const getOldWorkerTodayCnt = () => {
    return totalAttendTable
      .map((el: any) => el.oldWorkerTodayCnt)
      .reduce((prev, cur) => {
        return prev + cur;
      }, 0);
  };
  // 협력업체 금일합계
  const getTotalWorkerTodayCnt = () => {
    return totalAttendTable
      .map((el: any) => el.totalWorkerTodayCnt)
      .reduce((prev, cur) => {
        return prev + cur;
      }, 0);
  };
  // 협력업체 전일
  const getTotalWorkerYesterdayCnt = () => {
    return totalAttendTable
      .map((el: any) => el.totalWorkerYesterdayCnt)
      .reduce((prev, cur) => {
        return prev + cur;
      }, 0);
  };
  // 협력업체 2일 전
  const getTotalWorkertwoDaysBeforeCnt = () => {
    return totalAttendTable
      .map((el: any) => el.totalWorkertwoDaysBeforeCnt)
      .reduce((prev, cur) => {
        return prev + cur;
      }, 0);
  };
  // 주간 주간합계
  const getThisWeekCnt = () => {
    return weekAttendTable
      .map((el: any) => el.thisWeekCnt)
      .reduce((prev, cur) => {
        return prev + cur;
      }, 0);
  };
  // 주간 전주
  const getLastWeekCnt = () => {
    return weekAttendTable
      .map((el: any) => el.lastWeekCnt)
      .reduce((prev, cur) => {
        return prev + cur;
      }, 0);
  };
  // 주간 2주 전
  const getTwoWeeksBeforeCnt = () => {
    return weekAttendTable
      .map((el: any) => el.twoWeeksBeforeCnt)
      .reduce((prev, cur) => {
        return prev + cur;
      }, 0);
  };
  // 주간 월간합계
  const getThisMonthCnt = () => {
    return monthAttendTable
      .map((el: any) => el.thisMonthCnt)
      .reduce((prev, cur) => {
        return prev + cur;
      }, 0);
  };
  // 주간 전월
  const getLastMonthCnt = () => {
    return monthAttendTable
      .map((el: any) => el.lastMonthCnt)
      .reduce((prev, cur) => {
        return prev + cur;
      }, 0);
  };
  // 주간 2달 전
  const getTwoMonthsBeforeCnt = () => {
    return monthAttendTable
      .map((el: any) => el.twoMonthsBeforeCnt)
      .reduce((prev, cur) => {
        return prev + cur;
      }, 0);
  };
  const colorPicker = (el: number): 'red' | 'blue' | 'green' | 'amber' | 'teal' | 'violet' | 'pink' | 'indigo' | 'orange' | 'sky' | 'lime' | undefined => {
    if (el % 11 === 0) return 'green';
    if (el % 11 === 1) return 'blue';
    if (el % 11 === 2) return 'teal';
    if (el % 11 === 3) return 'pink';
    if (el % 11 === 4) return 'orange';
    if (el % 11 === 5) return 'violet';
    if (el % 11 === 6) return 'red';
    if (el % 11 === 7) return 'indigo';
    if (el % 11 === 8) return 'sky';
    if (el % 11 === 9) return 'lime';
    if (el % 11 === 10) return 'amber';
    return undefined;
  };
  return (
    <div className='attendTotalInfo'>
      <div className='widget-header'>
        <div className='widget-title flex-between innerTab'>
          <div className='widget-title-innerTab'>
            <span>{t(name)}</span>
            <div className='innerTab flex-basic'>
              <div className={innerTab === 0 ? 'active' : undefined} role='presentation' onClick={() => setInnerTab(0)}>
                {t('출역 차트')}
              </div>
              <div className={innerTab === 1 ? 'active' : undefined} role='presentation' onClick={() => setInnerTab(1)}>
                {t('협력업체')}
              </div>
              <div className={innerTab === 2 ? 'active' : undefined} role='presentation' onClick={() => setInnerTab(2)}>
                {t('협력업체별 차트')}
              </div>
              <div className={innerTab === 3 ? 'active' : undefined} role='presentation' onClick={() => setInnerTab(3)}>
                {t('주간')}
              </div>
              <div className={innerTab === 4 ? 'active' : undefined} role='presentation' onClick={() => setInnerTab(4)}>
                {t('월간')}
              </div>
            </div>
          </div>
          <div className='refresh-btn' onClick={onClickRefresh} role='presentation'>
            <span className='material-symbols-rounded'>refresh</span>
          </div>
        </div>
        <div className='oneWeekCalendar-wrapper'>
          {(innerTab === 0 || innerTab === 1 || innerTab === 2) && (
            <div className='oneWeekCalendar flex-between'>
              {twoWeekDays.map((el: any) => (
                <div
                  key={el.date}
                  className={`${el.fullDate === selectedDay.format('YYYY-MM-DD') ? 'todayHighlight' : ''} dayWrapper`}
                  role='button'
                  tabIndex={0}
                  onClick={() => onClickDate(el.fullDate)}
                >
                  <div className='day'>{t(el.dayName)}</div>
                  <div className='date bold'>{el.date}</div>
                </div>
              ))}
            </div>
          )}
          {innerTab === 3 && (
            <div className='oneWeekCalendar flex-between'>
              {eightWeeks.map((el: any) => (
                <div key={el.weekNumber} className={`${el.weekNumber === selectedWeek.weekNumber ? 'todayHighlight' : ''} dayWrapper`} role='button' tabIndex={0} onClick={() => onClickWeek(el)}>
                  <div className='day'>{el.month}</div>
                  <div className='date bold'>
                    {el.weekInMonth}
                    {t('주')}
                  </div>
                </div>
              ))}
            </div>
          )}
          {innerTab === 4 && (
            <div className='oneWeekCalendar flex-between'>
              {displayTwelveMonths.map((el: any) => (
                <div
                  key={`${el.year}_${el.month}`}
                  className={`${el.month === selectedMonth.month ? 'todayHighlight' : ''} dayWrapper thinPadding`}
                  role='button'
                  tabIndex={0}
                  onClick={() => onClickMonth(el)}
                >
                  <div className='day'>
                    {el.year}
                    {t('년')}
                  </div>
                  <div className='date bold'>
                    {el.month}
                    {t('월')}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {innerTab === 0 ? (
        <div className='pieChartWrapper flex-basic'>
          <div>
            <div className='pie-group'>
              <div className='flex-basic pie'>
                <PieChart data={dayAttendTable} />
              </div>
              <div className='flex-col legends'>
                {dayAttendTable?.map((el, idx) => (
                  <div className='legend' key={el.name}>
                    <div className={colorPicker(idx)} />
                    <span className='name'>{el.name}</span>
                    <span className='count'>{el.count}</span>
                    {t('명')}
                  </div>
                ))}
                {dayAttendTable && dayAttendTable.length > 6 ? (
                  <div>
                    {`…  외 `}
                    <span className='text_primary'>{`${dayAttendTable.length - 6}`}</span>
                    {t('개')} {t('협력업체')}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className='text-center'>
              {t('출역인원')}
              <span className='badge'>{totalCount}</span>
              {t('명')}
            </div>
          </div>
          <div>
            <div className='pie-group'>
              <div className='flex-basic pie'>
                <PieChart data={oldAttendPieData} />
              </div>
              <div className='flex-col legends'>
                {oldAttendPieData?.map((el, idx) => (
                  <div className='legend' key={el.name}>
                    <div className={colorPicker(idx)} />
                    <span className='name'>{el.name}</span>
                    <span className='count'>{el.count}</span>
                    {t('명')}
                  </div>
                ))}
                {oldAttendPieData && oldAttendPieData.length > 6 ? (
                  <div>
                    {`…  외 `}
                    <span className='text_primary'>{`${oldAttendPieData.length - 6}`}</span>
                    {t('개')} {t('협력업체')}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className='text-center'>
              {t('고령 근로자')}
              <span className='badge'>{oldTotalCount}</span>
              {t('명')}
            </div>
          </div>
        </div>
      ) : undefined}
      {innerTab === 1 && (
        <div className='widget-body table'>
          <InputTable>
            <div className='thead'>
              <div className='tr'>
                <div className='trCol2p5 flex-basic tableStickyNo content-overflow padding-left'>{t('협력업체')}</div>
                <div className='trCol4 flex-center'>{t('일반')}</div>
                <div className='trCol4 flex-center'>{t('고령')}</div>
                <div className='trCol4 flex-center'>{t('금일 합계')}</div>
                <div className='trCol4 flex-center'>{t('전일')}</div>
                <div className='trCol5 flex-center padding-right'>{t('2일 전')}</div>
              </div>
            </div>
            <div className='table'>
              <div className='tbody'>
                {totalAttendTable?.length > 0 ? (
                  totalAttendTable.map((el: any, i: number) => (
                    <div className='tr' key={`attendInfo_${i}`}>
                      <div className='trCol2p5 flex-basic tableStickyNo content-overflow padding-left'>{el.sjName}</div>
                      <div className='trCol4 flex-center shrink0'>
                        <span className={`pointValue ${el.normalWorkerTodayCnt > 0 && el.normalWorkerTodayCnt <= 10 ? 'under10' : ''} ${el.normalWorkerTodayCnt > 10 ? 'over10' : ''}`}>
                          {el.normalWorkerTodayCnt}
                        </span>
                      </div>
                      <div className='trCol4 flex-center shrink0'>
                        <span className={`pointValue ${el.oldWorkerTodayCnt > 0 && el.oldWorkerTodayCnt <= 10 ? 'under10' : ''} ${el.oldWorkerTodayCnt > 10 ? 'over10' : ''}`}>
                          {el.oldWorkerTodayCnt}
                        </span>
                      </div>
                      <div className='trCol4 flex-center shrink0'>
                        <span className={`pointValue ${el.totalWorkerTodayCnt > 0 && el.totalWorkerTodayCnt <= 10 ? 'under10' : ''} ${el.totalWorkerTodayCnt > 10 ? 'over10' : ''}`}>
                          {el.totalWorkerTodayCnt}
                        </span>
                      </div>
                      <div className='trCol4 flex-center shrink0'>
                        <span className={`pointValue ${el.totalWorkerYesterdayCnt > 0 && el.totalWorkerYesterdayCnt <= 10 ? 'under10' : ''} ${el.totalWorkerYesterdayCnt > 10 ? 'over10' : ''}`}>
                          {el.totalWorkerYesterdayCnt}
                        </span>
                      </div>
                      <div className='trCol5 flex-center shrink0 padding-right'>
                        <span
                          className={`pointValue ${el.totalWorkertwoDaysBeforeCnt > 0 && el.totalWorkertwoDaysBeforeCnt <= 10 ? 'under10' : ''} ${el.totalWorkertwoDaysBeforeCnt > 10 ? 'over10' : ''}`}
                        >
                          {el.totalWorkertwoDaysBeforeCnt}
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='noData'>No data.</div>
                )}
              </div>
            </div>
            <tfoot>
              <div className='tr'>
                <div className='trCol2p5 flex-basic tableStickyNo content-overflow padding-left'>
                  {t('합계')}
                  <span className='subNumber'>{totalAttendTable.length}</span>
                </div>
                <div className='trCol4 flex-center shrink0'>
                  <span className={`pointValue ${getNormalWorkerTodayCnt() > 0 && getNormalWorkerTodayCnt() <= 10 ? 'under10' : ''} ${getNormalWorkerTodayCnt() > 10 ? 'over10' : ''}`}>
                    {getNormalWorkerTodayCnt()}
                  </span>
                </div>
                <div className='trCol4 flex-center shrink0'>
                  <span className={`pointValue ${getOldWorkerTodayCnt() > 0 && getOldWorkerTodayCnt() <= 10 ? 'under10' : ''} ${getOldWorkerTodayCnt() > 10 ? 'over10' : ''}`}>
                    {getOldWorkerTodayCnt()}
                  </span>
                </div>
                <div className='trCol4 flex-center shrink0'>
                  <span className={`pointValue ${getTotalWorkerTodayCnt() > 0 && getTotalWorkerTodayCnt() <= 10 ? 'under10' : ''} ${getTotalWorkerTodayCnt() > 10 ? 'over10' : ''}`}>
                    {getTotalWorkerTodayCnt()}
                  </span>
                </div>
                <div className='trCol4 flex-center shrink0'>
                  <span className={`pointValue ${getTotalWorkerYesterdayCnt() > 0 && getTotalWorkerYesterdayCnt() <= 10 ? 'under10' : ''} ${getTotalWorkerYesterdayCnt() > 10 ? 'over10' : ''}`}>
                    {getTotalWorkerYesterdayCnt()}
                  </span>
                </div>
                <div className='trCol5 flex-center shrink0 padding-right'>
                  <span
                    className={`pointValue ${getTotalWorkertwoDaysBeforeCnt() > 0 && getTotalWorkertwoDaysBeforeCnt() <= 10 ? 'under10' : ''} ${getTotalWorkertwoDaysBeforeCnt() > 10 ? 'over10' : ''}`}
                  >
                    {getTotalWorkertwoDaysBeforeCnt()}
                  </span>
                </div>
              </div>
            </tfoot>
          </InputTable>
        </div>
      )}
      {innerTab === 2 && (
        <div className='barChartWrapper'>
          <BarChart labelsParam={totalAttendTable?.filter((el: any) => el.totalWorkerTodayCnt > 0).map((el: any) => el.sjName)} barData={attendBarData} />
        </div>
      )}
      {innerTab === 3 && (
        <div className='widget-body table'>
          <InputTable>
            <div className='thead'>
              <div className='tr'>
                <div className='trCol2p5 flex-basic tableStickyNo content-overflow padding-left'>{t('협력업체')}</div>
                <div className='trCol4 flex-center'>{t('주간합계')}</div>
                <div className='trCol4 flex-center'>{t('전주')}</div>
                <div className='trCol5 flex-center padding-right'>{t('2주 전')}</div>
              </div>
            </div>
            <div className='table'>
              <div className='tbody'>
                {weekAttendTable?.length > 0 ? (
                  weekAttendTable.map((el: any, i: number) => (
                    <div className='tr' key={`attendInfo_${i}`}>
                      <div className='trCol2p5 flex-basic tableStickyNo content-overflow padding-left'>{el.sjName}</div>
                      <div className='trCol4 flex-center shrink0'>
                        <span className={`pointValue ${el.thisWeekCnt > 0 && el.thisWeekCnt <= 10 ? 'under10' : ''} ${el.thisWeekCnt > 10 ? 'over10' : ''}`}>{el.thisWeekCnt}</span>
                      </div>
                      <div className='trCol4 flex-center shrink0'>
                        <span className={`pointValue ${el.lastWeekCnt > 0 && el.lastWeekCnt <= 10 ? 'under10' : ''} ${el.lastWeekCnt > 10 ? 'over10' : ''}`}>{el.lastWeekCnt}</span>
                      </div>
                      <div className='trCol5 flex-center shrink0 padding-right'>
                        <span className={`pointValue ${el.twoWeeksBeforeCnt > 0 && el.twoWeeksBeforeCnt <= 10 ? 'under10' : ''} ${el.twoWeeksBeforeCnt > 10 ? 'over10' : ''}`}>
                          {el.twoWeeksBeforeCnt}
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='noData'>No data.</div>
                )}
              </div>
            </div>
            <tfoot>
              <div className='tr'>
                <div className='trCol2p5 flex-basic tableStickyNo content-overflow padding-left'>
                  {t('합계')}
                  <span className='subNumber'>{weekAttendTable.length}</span>
                </div>
                <div className='trCol4 flex-center shrink0'>
                  <span className={`pointValue ${getThisWeekCnt() > 0 && getThisWeekCnt() <= 10 ? 'under10' : ''} ${getThisWeekCnt() > 10 ? 'over10' : ''}`}>{getThisWeekCnt()}</span>
                </div>
                <div className='trCol4 flex-center shrink0'>
                  <span className={`pointValue ${getLastWeekCnt() > 0 && getLastWeekCnt() <= 10 ? 'under10' : ''} ${getLastWeekCnt() > 10 ? 'over10' : ''}`}>{getLastWeekCnt()}</span>
                </div>
                <div className='trCol5 flex-center shrink0 padding-right'>
                  <span className={`pointValue ${getTwoWeeksBeforeCnt() > 0 && getTwoWeeksBeforeCnt() <= 10 ? 'under10' : ''} ${getTwoWeeksBeforeCnt() > 10 ? 'over10' : ''}`}>
                    {getTwoWeeksBeforeCnt()}
                  </span>
                </div>
              </div>
            </tfoot>
          </InputTable>
        </div>
      )}
      {innerTab === 4 && (
        <div className='widget-body table'>
          <InputTable>
            <div className='thead'>
              <div className='tr'>
                <div className='trCol2p5 flex-basic tableStickyNo content-overflow padding-left'>{t('협력업체')}</div>
                <div className='trCol4 flex-center'>{t('월간합계')}</div>
                <div className='trCol4 flex-center'>{t('전월')}</div>
                <div className='trCol5 flex-center padding-right'>{t('2달 전')}</div>
              </div>
            </div>
            <div className='table'>
              <div className='tbody'>
                {monthAttendTable?.length > 0 ? (
                  monthAttendTable.map((el: any, i: number) => (
                    <div className='tr' key={`attendInfo_${i}`}>
                      <div className='trCol2p5 flex-basic tableStickyNo content-overflow padding-left'>{el.sjName}</div>
                      <div className='trCol4 flex-center shrink0'>
                        <span className={`pointValue ${el.thisMonthCnt > 0 && el.thisMonthCnt <= 10 ? 'under10' : ''} ${el.thisMonthCnt > 10 ? 'over10' : ''}`}>{el.thisMonthCnt}</span>
                      </div>
                      <div className='trCol4 flex-center shrink0'>
                        <span className={`pointValue ${el.lastMonthCnt > 0 && el.lastMonthCnt <= 10 ? 'under10' : ''} ${el.lastMonthCnt > 10 ? 'over10' : ''}`}>{el.lastMonthCnt}</span>
                      </div>
                      <div className='trCol5 flex-center shrink0 padding-right'>
                        <span className={`pointValue ${el.twoMonthsBeforeCnt > 0 && el.twoMonthsBeforeCnt <= 10 ? 'under10' : ''} ${el.twoMonthsBeforeCnt > 10 ? 'over10' : ''}`}>
                          {el.twoMonthsBeforeCnt}
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='noData'>No data.</div>
                )}
              </div>
            </div>
            <tfoot>
              <div className='tr'>
                <div className='trCol2p5 flex-basic tableStickyNo content-overflow padding-left'>
                  {t('합계')}
                  <span className='subNumber'>{monthAttendTable.length}</span>
                </div>
                <div className='trCol4 flex-center shrink0'>
                  <span className={`pointValue ${getThisMonthCnt() > 0 && getThisMonthCnt() <= 10 ? 'under10' : ''} ${getThisMonthCnt() > 10 ? 'over10' : ''}`}>{getThisMonthCnt()}</span>
                </div>
                <div className='trCol4 flex-center shrink0'>
                  <span className={`pointValue ${getLastMonthCnt() > 0 && getLastMonthCnt() <= 10 ? 'under10' : ''} ${getLastMonthCnt() > 10 ? 'over10' : ''}`}>{getLastMonthCnt()}</span>
                </div>
                <div className='trCol5 flex-center shrink0 padding-right'>
                  <span className={`pointValue ${getTwoMonthsBeforeCnt() > 0 && getTwoMonthsBeforeCnt() <= 10 ? 'under10' : ''} ${getTwoMonthsBeforeCnt() > 10 ? 'over10' : ''}`}>
                    {getTwoMonthsBeforeCnt()}
                  </span>
                </div>
              </div>
            </tfoot>
          </InputTable>
        </div>
      )}
    </div>
  );
};
