import { IModal } from 'customTypes';
import { BtnBlue, BtnGhost, BtnRed, BtnWhite } from '../Button';
import { ModalBackground, Modal } from '../../assets/styles/Modal';
import { CCTV_VIEWER_PLUGIN_URL } from '../../_constants';
import { useTranslation } from 'react-i18next';

const WebSdkModal = ({ openModal, setOpenModal, state }: any) => {
  const { t } = useTranslation();
  // 닫기버튼 클릭
  const onClickClose = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };
  // 다운로드버튼 클릭
  const onClickDownload = () => {
    window.location.href = CCTV_VIEWER_PLUGIN_URL;
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };
  return (
    <ModalBackground>
      <Modal>
        <div className='modal delete'>
          <div className='inputForm-head'>{t('CCTV VIEWER를 설치해주세요')}</div>
          <div className='inputForm-body'>
            <div className='modal-footer flex-end'>
              <BtnGhost onClick={onClickDownload}>{t('CCTV Viewer 다운로드')}</BtnGhost>
              <BtnGhost onClick={onClickClose}>{t('닫기')}</BtnGhost>
            </div>
          </div>
        </div>
      </Modal>
    </ModalBackground>
  );
};

export default WebSdkModal;
