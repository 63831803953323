/*
 * 작성자 : 김광민
 * 날짜 : 2023.08.09
 * 기능 : 메인메뉴 리스트 출력 컴포넌트
 */

import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';

import { IModal, ISideMenuObject } from 'customTypes';
import { msiteListState, msiteUseYnState, themeState, userState } from '../atoms';
import Portal from './Portal';
import SearchSelectBoxSm from './SearchSelectBoxSm';
import UserInfoSettingModal from './Modal/UserInfoSettingModal';
import * as cctv from '../utils/cctv';
import Language from './language';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../utils/checkMobile';
import { apiGet } from '../services/_common';
import { useThemeLogo } from '../services/getInitialColorMode';
import { getMSiteList } from '../hooks/useMSite';

interface AsideMainMenuProps {
  foldMenu: boolean;
  foldingMenu: (e: React.MouseEvent<HTMLElement>) => void;
  sideMenuList: ISideMenuObject[];
  onClickAsideList: (el: any) => void;
  mainSelected: string;
  mainFocus: string;
  closeAside: () => void;
  openMenu: boolean;
}

const Root = styled.div`
  display: flex;
  &.hideRoot {
    visibility: hidden;
    position: absolute;
  }
  .aside {
    /* height: 100vh; */
    height: inherit;
    /* min-height: -webkit-fill-available; */
    position: fixed;
    z-index: 5000;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: ${({ theme }: { theme: any }) => theme.board};
  }
  .aside-top {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0 1.5rem;
    margin-top: 1.5rem;
    @media (min-width: 1024px) {
      padding: 0 0.75rem;
      margin: 0.5rem 0;
    }
    .icon-menu {
      user-select: none;
      cursor: pointer;
      width: 3.5rem;
      height: 3rem;
      align-items: center;
      justify-content: center;
      border-radius: 1rem;
      /* border-radius: 0.25rem; */
      span.rotate180 {
        transform: rotate(180deg);
      }
      @media (min-width: 1024px) {
        :hover {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
        }
      }
    }
    .hamburger {
      display: none;
    }
    .close-menu {
      position: fixed;
      top: 1rem;
      right: 1rem;
      display: flex;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
      width: 2rem;
      height: 2rem;
    }
    @media (min-width: 1024px) {
      .hamburger {
        display: flex;
        color: ${({ theme }: { theme: any }) => theme.text_primary};
      }
      .close-menu {
        display: none;
      }
    }
    .logo {
      flex-grow: 1;
      height: 3rem;
      /* height: 3.5rem; */
      @media (min-width: 640px) {
        height: 2.5rem;
      }
      cursor: pointer;
      > img {
        height: inherit;
        user-select: none;
      }
    }
  }
  .aside-top {
    align-items: flex-end;
  }
  .aside-fold {
    width: 5rem;
  }
  .aside-default {
    width: 100vw;
    width: 20rem;
    width: 17.5rem;
    @media (min-width: 640px) {
      width: 16rem;
    }
  }
  .aside-icon {
    height: 4rem;
    display: flex;
    justify-content: flex-end;
    > svg {
      height: 70%;
    }
  }
  .aside-container {
    width: 100%;
    height: 100%;
    padding: 0 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow: hidden auto;
    &.fold {
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      &::-webkit-scrollbar-track {
        width: 0;
      }
    }
  }
  .aside-bottom {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem;
    border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    .language-select {
      width: 4rem;
    }
  }
  .main-select {
    > .flex-between {
      > div {
        > ul {
          position: static;
        }
      }
    }
  }
  .main-list,
  .main-select {
    display: flex;
    position: relative;
    height: 3rem;
    padding: 0 0.25rem;
    align-items: center;
    gap: 0.25rem;
    user-select: none;
    cursor: pointer;
    border-radius: 0.25rem;
    width: 100%;
    .locationLabel {
      font-weight: 600;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      /* background-color: ${({ theme }: { theme: any }) => theme.tonal}; */
      // padding: 0 0.75rem;
      height: 2rem;
      border-radius: 1rem;
      font-size: 0.875rem;
      gap: 0.5rem;
      span.bullet {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
        border-radius: 1rem;
        padding: 0.25rem;
      }
      > div {
        flex-grow: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .main-title {
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      font-weight: ${({ theme }: { theme: any }) => theme.font_semibold};
      /* font-weight: ${({ theme }: { theme: any }) => theme.font_medium}; */
    }
    .icon-menu {
      font-size: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3rem;
      width: 3rem;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      &.padding {
        width: 3.5rem;
        padding: 0 0.5rem;
      }
      > span {
        font-variation-settings: 'FILL' 1;
      }
    }
  }

  .main-select {
    width: 100%;
    padding: 0px 0.75rem;
    margin: 0.5rem 0px;
    cursor: default;
    flex-direction: column;
    height: fit-content;
    .textLabel {
      text-align: center;
      cursor: pointer;
      height: 2.5rem;
      border-radius: 5px;
      line-height: 2.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        background-color: rgb(209 213 219 / 0.5);
      }
    }

    > div {
      width: inherit;
      > div {
        flex: 0.5;
        overflow: hidden;
        &:nth-child(2) {
        }
        ul,
        li {
          width: inherit !important;
          justify-content: space-between;
          > span {
            font-size: 0.825rem !important;
          }
          > ul > li > span {
            font-size: 0.825rem;
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .main-list:hover {
      background-color: rgb(209 213 219 / 0.5);
      * {
        color: rgb(0 0 0 / 0.8);
        color: ${({ theme }: { theme: any }) => theme.text_primary};
      }
      .icon-menu > span {
        font-variation-settings: 'FILL' 1;
      }
      .menu-tooltip {
        opacity: 1;
        color: white;
        visibility: visible;
        &.bottom {
          top: -1.75rem;
        }
      }
    }
  }
  .main-list.active {
    background-color: ${({ theme }: { theme: any }) => theme.selected_primary};
    .icon-menu > span {
      font-variation-settings: 'FILL' 1;
    }
    * {
      color: #ffffff;
    }
  }
  .main-list.focus {
    background-color: rgb(209 213 219);
    .icon-menu > span {
      font-variation-settings: 'FILL' 1;
    }
    * {
      color: ${({ theme }: { theme: any }) => theme.color.gray_900};
    }
  }
  .menu-tooltip {
    visibility: visible;
    font-size: 0.75rem;
    color: white;
    background-color: black;
    font-weight: 300;
    opacity: 0;
    border-radius: 0.75rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 3rem;
    z-index: 6000;
    text-align: center;
    width: max-content;
    transition: ease-in-out;
    transition-duration: 100ms;
    transition-delay: 700ms;
    padding: 0.125rem 0.5rem;
    user-select: none;
    pointer-events: none;
    &.bottom {
      top: -1.75rem;
    }
  }
`;

const AsideMainMenu = ({ foldMenu, foldingMenu, sideMenuList, onClickAsideList, mainSelected, mainFocus, closeAside, openMenu }: AsideMainMenuProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [theme, setTheme] = useRecoilState(themeState);
  const [userInfo, setUserInfo] = useRecoilState(userState);
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: t('사용자 정보') });
  const [headList, setHeadList] = useState<any[]>([]);
  const [siteList, setSiteList] = useState<any[]>([]);
  const [hCd, setHcd] = useState({ type: 'hCd', hCd: userInfo.hCd !== 'M000' ? userInfo.hCd : '', cdName: userInfo.hName });
  const [sCd, setScd] = useState({ type: 'sCd', sCd: userInfo.sCd, cdName: userInfo.sName });
  const [firstRenderCheck, setFirstRenderCheck] = useState(false);
  const randomString = Math.random().toString(36).substring(7); // 랜덤 문자열을 쿼리스트링으로 추가해서 이미지 캐싱 방지
  const navigate = useNavigate();
  const setMsiteUseYn = useSetRecoilState(msiteUseYnState);
  const setMsiteList = useSetRecoilState(msiteListState);

  useEffect(() => {
    setHcd({ type: 'hCd', hCd: userInfo.hCd !== 'M000' ? userInfo.hCd : '', cdName: userInfo.hName });
    setScd({ type: 'sCd', sCd: userInfo.sCd, cdName: userInfo.sName });
  }, [userInfo]);

  useEffect(() => {
    if (userInfo.hCd !== hCd.hCd && pathname !== '/') {
      setUserInfo((prev) => ({ ...prev, hCd: hCd.hCd, hName: hCd.cdName }));
      navigate('/');
    }
  }, [hCd.hCd]);

  useEffect(() => {
    if (userInfo.sCd !== sCd.sCd && pathname !== '/') {
      setUserInfo((prev) => ({ ...prev, sCd: sCd.sCd, sName: sCd.cdName }));
      navigate('/');
    }
  }, [sCd.sCd]);

  // msiteUseYn, msiteList 전역변수 저장
  useEffect(() => {
    if (hCd.hCd !== '' && sCd.sCd !== '') {
      Promise.all([
        apiGet({ path: '/site' }), //
        getMSiteList('/site/msite', { hCd: hCd.hCd, sCd: sCd.sCd }),
      ]).then(([siteRes, msiteRes]) => {
        const { statusCode, data } = siteRes.data;
        if (statusCode === 200) {
          const filteredSite = data.siteInfoList.filter(
            (el: any) =>
              el.delYn === 'N' && //
              el.hCd === hCd.hCd &&
              el.sCd === sCd.sCd
          );

          const msiteUseYn =
            filteredSite.length > 0 //
              ? filteredSite[0].msiteYn
              : 'N';

          setMsiteUseYn(msiteUseYn);
          setMsiteList(msiteRes);
        }
      });
    }
  }, [hCd.hCd, sCd.sCd]);

  const darkModeHandling = () => {
    if (theme === 'light') setTheme('dark');
    else setTheme('light');
    if (cctv.getPluginOBJECT()?.oPlugin && pathname === '/') {
      cctv.destroy();
    }
  };

  const headInfoQuery = useQuery(['headInfoGet', userInfo.hCd], () => apiGet({ path: '/head/info', req: { hCd: userInfo.hCd } }), { retry: 3 });
  const headListQuery = useQuery(['headListGet'], () => apiGet({ path: '/menu/headInfo' }));
  const siteListQuery = useQuery(['siteListGet', hCd.hCd], () => apiGet({ path: '/menu/siteInfo', req: { hCd: hCd.hCd } }), { enabled: !!hCd.hCd });
  const logo = useThemeLogo(headInfoQuery, theme);

  useEffect(() => {
    if (headListQuery.isSuccess && headListQuery.data && headListQuery.data.status === 200) {
      const newArray = headListQuery.data.data.data.map((el: any) => ({ type: 'hCd', hCd: el.hCd, cdName: el.hName }));
      setHeadList(newArray);
    }
  }, [headListQuery.isSuccess, headListQuery.isRefetching]);

  useEffect(() => {
    if (siteListQuery.isSuccess && siteListQuery.data && siteListQuery.data.status === 200) {
      const newArray = siteListQuery.data.data.data.map((el: any) => ({ type: 'sCd', sCd: el.sCd, cdName: el.sName }));
      setSiteList(newArray);
    }
  }, [siteListQuery.isSuccess, siteListQuery.isRefetching]);

  useEffect(() => {
    if (cctv.getPluginOBJECT()?.oPlugin) {
      if (openModal.status) {
        cctv.hideWndAll();
      } else {
        cctv.showWndAll();
      }
    }
  }, [openModal.status]);

  useEffect(() => {
    // 영업담당자의 경우 본사메뉴 노출
    if (hCd.hCd && (userInfo.gCd === 'MM' || userInfo.gCd === 'AM') && firstRenderCheck) {
      // 본사만 선택했을 때
      const findHName = headList.find((el) => el.hCd === hCd.hCd);
      setUserInfo((prev) => ({
        ...prev,
        hCd: hCd.hCd,
        hName: findHName ? findHName.cdName : prev.hName,
        sCd: '00000',
        sName: '',
      }));
      setScd({ type: 'sCd', sCd: '', cdName: '' });
    } else {
      setFirstRenderCheck(true);
    }
  }, [hCd.hCd]);

  useEffect(() => {
    if (hCd.hCd && sCd.sCd && (userInfo.gCd === 'MM' || userInfo.gCd === 'HM' || userInfo.gCd === 'AM')) {
      /**
       * 수정자 : 홍선영
       * 날짜 : 2023.12.01
       * 수정내용 : 사이드바 로고를 클릭하여 대시보드 리로드시, 전역상태값인 setUserInfo가 여러번 set되어서 페이지가 여러번 리로드되는 문제 수정.
       * getSiteInfoAPI에서 setUserInfo 하고 있으므로, 아래의 중복되는 setUserInfo 주석처리함.
       *
       * 수정자 : 한영광
       * 수정일자 : 2023.12.05
       * 수정내용 : 현장 정보 조회, 사이드 메뉴 조회 API 요청은 이제 Aside 컴포넌트에서만 하도록 수정 (userInfo.hCd, userInfo.sCd 상태값이 변경되면 API 요청함)
       */
      const findSName = siteList.find((el) => el.sCd === sCd.sCd);
      setUserInfo((prev) => ({
        ...prev,
        sCd: sCd.sCd,
        sName: findSName ? findSName.cdName : prev.sName,
      }));
      // getSiteInfoAPI(hCd.hCd, sCd.sCd);
      // if (userInfo.sCd !== sCd.sCd) getMenuSiteInfoAPI(hCd.hCd, sCd.sCd);
    }
  }, [sCd.sCd]);

  const findMenuIcon = (mtName: string) => {
    switch (mtName) {
      case '마스터 관리':
        return 'key';
      case '마스터 메뉴 관리':
        return 'tune';
      case '일반 관리':
        return 'settings';
      case '사내메뉴타이틀1':
        return 'title';
      case '사내메뉴타이틀2':
        return 'subtitles';
      case '사내메뉴타이틀3':
        return 'list';
      case '본사 소개':
        return 'apartment';
      case '사용자 관리':
        return 'manage_accounts';
      case '현장 정보 / CCTV':
        return 'videocam';
      case '현장 소개':
        return 'pin_drop';
      case '근로자 관리':
        return 'groups';
      case '출역 관리':
        return 'calendar_month';
      case '안전 관리':
        return 'heart_check';
      case '센서 관리':
        return 'sensors';
      case '현장 CCTV':
        return 'videocam';
      case '설정 관리':
        return 'settings';
      case '터널 관리':
        return 'database';
      case '방송 장비 관리':
        return 'google_home_devices';
      case '태블릿 근로자 관리':
        return 'tablet';
      default:
        return 'fiber_manual_record';
    }
  };

  // 사용자설정 클릭
  const onClickOpenUserSetting = () => {
    setOpenModal((prev) => ({ ...prev, status: true }));
  };

  const onClickClose = () => {
    setOpenModal((prev: any) => ({ ...prev, status: false }));
    if (isMobile()) closeAside();
  };

  const onClickHName = () => {
    onClickAsideList('home');
    // 본사명 라벨 클릭시 현장선택 초기화
    setUserInfo((prev) => ({
      ...prev,
      hCd: userInfo.hCd,
      sCd: '00000',
      sName: '',
    }));

    setScd({ type: 'sCd', sCd: '', cdName: '' });
  };

  const renderHcdScd = () => {
    if (userInfo.gCd === 'MM') {
      return (
        <div className='flex-between'>
          <SearchSelectBoxSm
            options={headList}
            defaultOption={userInfo.hName ? userInfo.hName : t('본사 선택')}
            state={hCd}
            setState={setHcd}
            stateKey='hCd'
            codeKey='cdName'
            initiateKey={hCd.hCd}
            asideMain
          />
          <SearchSelectBoxSm
            options={siteList}
            defaultOption={userInfo.sName ? userInfo.sName : t('현장 선택')}
            state={sCd}
            setState={setScd}
            stateKey='sCd'
            codeKey='cdName'
            initiateKey={sCd.sCd}
            asideMain
          />
        </div>
      );
    }

    if (userInfo.gCd === 'HM' || userInfo.gCd === 'AM') {
      return (
        <div className='flex-between locationLabel'>
          <div className='textLabel' role='presentation' onClick={onClickHName}>
            {userInfo.hName}
          </div>
          <SearchSelectBoxSm options={siteList} defaultOption={userInfo.sName ? userInfo.sName : t('현장 선택')} state={sCd} setState={setScd} stateKey='sCd' codeKey='cdName' initiateKey={sCd.sCd} />
        </div>
      );
    }

    return (
      <div className='flex-basic locationLabel'>
        {userInfo.gCd?.substring(0, 1) !== 'S' && <div className='text-center'>{userInfo.hName}</div>}
        <div className=''>{userInfo.sName}</div>
      </div>
    );
  };

  const handleLogoClick = () => {
    if (pathname === '/') {
      window.location.reload();
    } else {
      onClickAsideList('home');
    }
  };

  return (
    <Root className={openMenu ? 'showRoot' : 'hideRoot'}>
      <div className={`${!foldMenu ? 'aside-default' : 'aside-fold'} aside`} id='aside'>
        <div className='aside-top'>
          <div className='icon-menu hamburger' role='presentation' onMouseDown={(e) => foldingMenu(e)}>
            <span className={`${foldMenu && 'rotate180'} material-symbols-rounded`}>menu_open</span>
          </div>
          <div className='icon-menu close-menu' role='presentation' onMouseDown={() => closeAside()}>
            <span className='material-symbols-rounded'>close </span>
          </div>
          {!foldMenu && logo && (
            <a role='presentation' onClick={handleLogoClick} className='logo'>
              <img src={`${logo}?v=${randomString}`} alt='' />
            </a>
          )}
        </div>
        {!foldMenu && (
          <div className='main-select' role='presentation'>
            {foldMenu && (
              <div className='icon-menu padding'>
                <span className='material-symbols-rounded'>published_with_changes</span>
              </div>
            )}
            {foldMenu && <span className='menu-tooltip'>{t('본사/현장선택')}</span>}
            {renderHcdScd()}
          </div>
        )}
        <div className={`aside-container ${foldMenu ? 'fold' : ''}`}>
          {sideMenuList && sideMenuList?.length > 0
            ? sideMenuList?.map((menuEl) => (
                <a
                  key={menuEl.mtCd}
                  role='presentation'
                  onClick={() => onClickAsideList(menuEl.sub)}
                  className={menuEl.mtCd === mainSelected ? 'active main-list' : menuEl.mtCd === mainFocus ? 'focus main-list' : 'main-list'}
                >
                  <div className='icon-menu'>
                    <span className='material-symbols-rounded'>{findMenuIcon(menuEl.mtName)}</span>
                  </div>
                  {foldMenu && <span className='menu-tooltip'>{t(menuEl.mtName)}</span>}
                  {!foldMenu && (
                    <div key={menuEl.mtCd} className='main-title'>
                      {menuEl.useYn === 'N' ? <span style={{ color: 'red' }}>{t(menuEl.mtName)}</span> : t(menuEl.mtName)}
                    </div>
                  )}
                </a>
              ))
            : undefined}
          <div>
            <a role='presentation' className='main-list' onClick={darkModeHandling}>
              <div className='icon-menu'>
                <span className='material-symbols-rounded'>{theme === 'light' ? 'dark_mode' : 'light_mode'}</span>
              </div>
              {foldMenu && <span className='menu-tooltip'>{theme === 'light' ? t('다크모드 설정') : t('라이트모드 설정')}</span>}
              {!foldMenu && <div className='main-title'>{theme === 'light' ? t('다크모드 설정') : t('라이트모드 설정')}</div>}
            </a>
          </div>
          {/* 
            * 수정자 : 홍선영
            * 날짜 : 2023.10.17
            * 수정내용 : 사이드바에 본사명 선택기능 추가하여 아래 본사목록으로 돌아가는 기능 대체하였으므로 주석처리함.
            * 
          {(userInfo.gCd.substring(0, 1) === 'H' || userInfo.gCd.substring(0, 1) === 'A') && userInfo.sCd !== '00000' && (
            <div>
              <a role='presentation' className='main-list' onClick={headMenu}>
                <div className='icon-menu'>
                  <span className='material-symbols-rounded'>apartment</span>
                </div>
                {foldMenu && <span className='menu-tooltip'>본사 메뉴</span>}
                {!foldMenu && <div className='main-title'>본사 메뉴</div>}
              </a>
            </div>
          )} */}
        </div>
        <div className='aside-bottom'>
          <div className='main-list' role='presentation' onClick={onClickOpenUserSetting}>
            <div className='icon-menu'>
              <span className='material-symbols-rounded'>account_box</span>
            </div>
            {foldMenu && <span className='menu-tooltip bottom'>{t('사용자 정보')}</span>}
            {!foldMenu && <div className='main-title'>{t('사용자 정보')}</div>}
          </div>
          <div className='language-select'>{!foldMenu && <Language />}</div>
        </div>
      </div>
      <Portal openModal={openModal?.status}>{openModal && <UserInfoSettingModal openModal={openModal} setOpenModal={setOpenModal} onClickClose={onClickClose} closeAside={closeAside} />}</Portal>
    </Root>
  );
};
export default AsideMainMenu;
