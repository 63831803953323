/**
 * 작성자 : 한영광
 * 날짜 : 2023.12.26
 * 기능 : 터널 관리 > 굴진/일반 정보 > 굴진/일반 정보 팝업 > 방송 장비 정보 탭 (현장용)
 */

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiGet } from '../services/_common';
import { toast } from 'react-toastify';
import { IUser } from '../atoms';
import { BtnBlue } from './Button';
import { logPost } from '../services/log';
import { useQuery } from '@tanstack/react-query';
import { PulseLoader } from 'react-spinners';
import { IModal } from 'customTypes';
import Portal from './Portal';
import BroadcastEquipModal from './Modal/BroadcastEquipModal';
import TareaEquipInfoContent from './TareaEquipInfoContent';
import BroadcastWithLabelButton from './button/BroadcastWithLabelButton';
import { isMobile } from '../utils/checkMobile';

interface Props {
  rowState: TareaData;
  userInfo: IUser;
  onClickPrev: any;
  onClickNext: any;
  auth: any;
  showPrevNextBtn: boolean;
}

const TareaEquipInfo = ({ rowState, userInfo, onClickPrev, onClickNext, auth, showPrevNextBtn }: Props) => {
  const { t } = useTranslation();
  const [startEquipList, setStartEquipList] = useState<EquipList[]>([]);
  const [endEquipList, setEndEquipList] = useState<EquipList[]>([]);
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });

  const dependencies = [userInfo.hCd, userInfo.sCd, rowState];
  const params = { hCd: userInfo.hCd, sCd: userInfo.sCd, tatCd: rowState.tatCd };

  const equipListQuery = useQuery(['equipListGet', ...dependencies], () => apiGet({ path: '/tarea/d/bs', req: params }), {
    enabled: dependencies.every(Boolean),
    onSuccess: (fetchedData) => {
      if (!fetchedData) {
        console.error('API 호출 결과가 없습니다.');
        return;
      }
      const { tareaDList } = fetchedData.data.data;
      const filteredData = tareaDList.filter((item: any) => item.bsCd).map((item: any) => ({ ...item, checked: false }));
      const startData = filteredData.filter((item: any) => item.tatGubun === 'S');
      const endData = filteredData.filter((item: any) => item.tatGubun === 'E');
      setStartEquipList(startData);
      setEndEquipList(endData);
      logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '터널관리 방송 장비 정보',
        action: '저장',
        etc: ``,
      });
    },
  });

  // 선택 방송 버튼 클릭
  const onClickSpeaker = () => {
    const checkList = [...startEquipList.filter((v: any) => v.checked), ...endEquipList.filter((v: any) => v.checked)];
    if (checkList.length === 0) {
      toast.warning(t('장비를 선택해주세요'));
      return;
    }
    setOpenModal((prev: any) => ({ ...prev, status: true, type: 'broadcast', data: [...checkList] }));
  };

  const onClickRow = (row: any) => {
    setOpenModal((prev: any) => ({ ...prev, status: true, type: 'broadcast', data: [{ ...row }] }));
  };

  if (equipListQuery.isLoading) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  if (equipListQuery.isError) {
    console.error('API 호출 중 오류 발생:', equipListQuery.isError);
  }

  return (
    <>
      <TareaEquipInfoContent
        rowState={rowState}
        onClickRow={onClickRow}
        startEquipList={startEquipList}
        setStartEquipList={setStartEquipList}
        endEquipList={endEquipList}
        setEndEquipList={setEndEquipList}
        userInfo={userInfo}
      />
      <div className={`modal-footer ${showPrevNextBtn ? 'flex-between' : 'flex-end'}`}>
        {showPrevNextBtn ? (
          <div className='flex-basic arrows'>
            <BtnBlue onClick={onClickPrev}>
              <span className='material-symbols-rounded'>arrow_back_ios_new</span>
              <p>{t('이전')}</p>
              <div>
                <p>Page</p>
                <p>Up</p>
              </div>
            </BtnBlue>
            <BtnBlue onClick={onClickNext}>
              <div>
                <p>Page</p>
                <p>Down</p>
              </div>
              <p>{t('다음')}</p>
              <span className='material-symbols-rounded'>arrow_forward_ios</span>
            </BtnBlue>
          </div>
        ) : undefined}
        <div>{!(userInfo.cctvProtocol === '1' && isMobile()) && <BroadcastWithLabelButton onClick={onClickSpeaker} />}</div>
      </div>
      <Portal openModal={openModal?.status}>{openModal.type === 'broadcast' && <BroadcastEquipModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
    </>
  );
};

export default TareaEquipInfo;
