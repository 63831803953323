/**
 * 작성자 : 홍선영
 * 날짜 : 2023.08.18
 * 기능 : 두 시간 값의 차이가 10분 이하인지 계산하여 true/false 리턴 (최신상태 확인)
 *
 * @param time1 :string e.g."2023-08-18 09:26:09"
 * @param time2 :string e.g. "2023-07-28 16:24:56"
 * @returns boolean
 */

import dayjs from 'dayjs';

export const getTimeDifference = (time1: string, time2: string) => {
  // 시간을 dayjs 객체로 변환
  const time1Obj = dayjs(time1);
  const time2Obj = dayjs(time2);

  // 시간차이를 밀리초로 변환
  const difference = time1Obj.diff(time2Obj);

  // 시간차이가 10분이하인지 (600,000 milliseconds)
  const isLessThan10Minutes = Math.abs(difference) < 600_000;
  return isLessThan10Minutes;
};

export const getTimeDifferenceFromCurrentTime = (dataTime: string) => {
  const currentTime: any = new Date();
  const targetTime: any = new Date(dataTime);
  let diff = currentTime - targetTime;

  if (diff < 0) {
    return '0시간 0분 0초';
  }

  let days = Math.floor(diff / (1000 * 60 * 60 * 24));
  diff -= days * (1000 * 60 * 60 * 24);

  let hours = Math.floor(diff / (1000 * 60 * 60));
  diff -= hours * (1000 * 60 * 60);

  let minutes = Math.floor(diff / (1000 * 60));
  diff -= minutes * (1000 * 60);

  let seconds = Math.floor(diff / 1000);
  if (!days) return ` ${hours}시간 ${minutes}분 ${seconds}초`;
  if (!days && !hours) return `${minutes}분 ${seconds}초`;
  if (!days && !hours && !minutes) return `${minutes}분 ${seconds}초`;
  if (!days && !hours && !minutes && !seconds) return `0초`;
  return `${days}일 ${hours}시간 ${minutes}분 ${seconds}초`;
};
