/**
 * 작성자 : 홍선영
 * 날짜 : 2023.03.29
 * 기능 : 노드 상단으로 스크롤을 이동시키는 함수
 */

export const scrollToNodeTop = (node: React.RefObject<any>) => {
  /**
   * 수정자 : 김광범
   * 날짜 : 2023.10.30
   * 수정내용 : 위치값 오류로 인한 스크롤 상단 이동 수정
   */
  // if (node.current !== null)
  //   node.current.scroll({
  //     top: 0,
  //     behavior: 'smooth',
  //   });
  if (node.current !== null) node.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
};
