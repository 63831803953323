/**
 * 작성자 : 홍선영
 * 날짜 : 2023.07.27
 * 기능 : 8자리 숫자 string(e.g. "20230101") 값을 받아서,
 *        1) 해당 값이 숫자로만 이루어진 string인지,
 *        2) 8자리를 만족하는 지 여부를 판단해서
 *        3) return boolean
 */

export const checkValidDateString = (date: string) => {
  const regEx = /^[0-9]{8}$/;
  return regEx.test(date);
};
