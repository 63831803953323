import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';

const todayjs = dayjs();

const useTodayDate = (todayParam: Dayjs | null) => {
  const [selectedDay, setSelectedDay] = useState(todayParam || todayjs);

  useEffect(() => {
    if (todayParam) setSelectedDay(todayParam);
  }, [todayParam]);

  return { selectedDay, setSelectedDay };
};

export default useTodayDate;
