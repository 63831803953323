/* eslint-disable */
// overall save the current selected window
// 현재 선택된 창을 저장하는 전역 변수
// don't have to set the variable; default to use the current selected window without transmiting value when the interface has window parameters

import { formatDateToYYYYMMDDHHMMSS } from './formatDate';

// 인터페이스에 창 매개변수가 있을 때 값을 전달하지 않고 현재 선택된 창을 기본값으로 사용하는 것
var g_iWndIndex = 0;
var g_oLocalConfig = {}; //local configuration variable
var division = 4;

// Error Code
var ERROR_CODE_UNKNOWN = 1000;
var ERROR_CODE_NETWORKERROR = 1001;
var ERROR_CODE_PARAMERROR = 1002;

// login
var ERROR_CODE_LOGIN_NOLOGIN = 2000;
var ERROR_CODE_LOGIN_REPEATLOGIN = 2001;
var ERROR_CODE_LOGIN_NOSUPPORT = 2002;

// preview and playback
var ERROR_CODE_PLAY_PLUGININITFAIL = 3000;
var ERROR_CODE_PLAY_NOREPEATPLAY = 3001;
var ERROR_CODE_PLAY_PLAYBACKABNORMAL = 3002;
var ERROR_CODE_PLAY_PLAYBACKSTOP = 3003;
var ERROR_CODE_PLAY_NOFREESPACE = 3004;

// talk
var ERROR_CODE_TALK_FAIL = 5000;

var openModal = null;

export function init(setOpenModal, setCctvFlow, divPlugin, setPluginOBJ, setSelectWndIndex, cctvDivisions, width, onMouseEventListener) {
  if (setOpenModal) openModal = setOpenModal;
  let oPromise = new Promise(function (resolve, reject) {
    WebVideoCtrl.I_InitPlugin({
      bWndFull: true, //Wether support doule clicking to switch the full-screen mode: it's supported by default; true:support, false:not support
      iWndowType: cctvDivisions || 4,
      bDebugMode: true,
      cbSelWnd: function (xmlDoc) {
        g_iWndIndex = parseInt($(xmlDoc).find('SelectWnd').eq(0).text(), 10);
        if (setSelectWndIndex) setSelectWndIndex(g_iWndIndex);
      },
      cbInitPluginComplete: function () {
        WebVideoCtrl.I_InsertOBJECTPlugin(divPlugin, width, onMouseEventListener).then(
          (e) => {
            if (setPluginOBJ) {
              setPluginOBJ(e);
            }
            if (setCctvFlow) {
              setCctvFlow(1);
            }
            WebVideoCtrl.I_CheckPluginVersion().then((bFlag) => {
              if (bFlag) {
                alert('Detect the latest version, please double click HCWebSDKPlugin.exe to update!');
              }
            });
            // console.log('초기화 성공');
            resolve();
          },
          (e) => {
            if (e.errorCode === 3000) {
              if (setOpenModal) {
                setOpenModal((prev) => ({
                  ...prev,
                  status: true,
                  type: 'websdk',
                }));
              }
            }
            console.log(e);
            reject();
          }
        );
      },
    });
  });
  return oPromise;
}

export function destroy() {
  WebVideoCtrl.I_Destory();
}

export function destoryPlugin(pluginOBJ) {
  WebVideoCtrl.I_DestoryPlugin(pluginOBJ);
}

export function hideWndAll() {
  WebVideoCtrl.I_HideWndAll();
}

export function showWndAll() {
  WebVideoCtrl.I_ShowWndAll();
}

export function hideWnd(pluginOBJ) {
  WebVideoCtrl.I_HideWnd(pluginOBJ);
}

export function showWnd(pluginOBJ) {
  WebVideoCtrl.I_ShowWnd(pluginOBJ);
}

export function getPluginOBJECT() {
  return WebVideoCtrl.I_GetPluginOBJECT();
}

export function selectWnd(wnd) {
  WebVideoCtrl.I_SelectWnd(wnd);
}

export function setMousePTZ(value) {
  WebVideoCtrl.I_SetMousePTZ(value);
}

export function getSelectWnd() {
  return this.g_iWndIndex;
}

export function login(ip, port, id, password, setCctvFlow, pluginOBJ) {
  let oPromise = new Promise(function (resolve, reject) {
    WebVideoCtrl.I_Login(
      ip,
      '1',
      port,
      id,
      password,
      {
        timeout: 3000,
        success: function (xmlDoc) {
          // console.log(ip + ' login successful');
          console.log('로그인 성공');
          resolve();
          if (setCctvFlow) setCctvFlow(2);
        },
        error: function (oError) {
          if (oError.errorCode === 2001) {
            console.log('로그인 성공');
            resolve();
            setCctvFlow(2);
            console.log(ip + ' is already login');
          } else {
            console.log(ip + ' 로그인 실패', oError);
            resolve('fail');
            // reject(oError);
          }
          // if (ERROR_CODE_LOGIN_REPEATLOGIN === status) {
          //   console.log(ip + ' is already login');
          // } else {
          //   console.log(ip + ' login failed', oError.errorCode, oError.errorMsg);
          // }
        },
      },
      pluginOBJ
    ).then(
      (e) => {
        // console.log('login then()', e);
      },
      (e) => {
        // console.log('login reject()', e);
      }
    );
  });
  return oPromise;
}

export function logout(nName) {
  if (null == nName) {
    return;
  }
  WebVideoCtrl.I_Logout(nName).then(
    () => {
      console.log(nName + ' ' + 'logout successful');
    },
    () => {
      console.log(nName + ' ' + 'logout failed');
    }
  );
}

// stream type 1 : main 2 : sub
// 메인스트림은 기본적으로 미리보기용으로 채택됨
export function startRealPlay(ip, stream, channel, g_iWndIndex, rtspPort, pluginOBJ, gubun) {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex, pluginOBJ);
  let oPromise = new Promise(function (resolve, reject) {
    var realPlay = function (reRealPlayCount) {
      if (reRealPlayCount < 5) {
        WebVideoCtrl.I_StartRealPlay(
          ip,
          {
            winIdx: g_iWndIndex,
            iStreamType: parseInt(stream, 10),
            iChannelID: parseInt(channel, 10),
            bZeroChannel: false,
            iPort: rtspPort,
            // success: function () {
            //   var szInfo = 'start real play success.';
            //   if (g_iWndIndex >= 0 && g_iWndIndex < 16) console.log('재생 성공', { g_iWndIndex });
            //   resolve({ ip, stream, channel, g_iWndIndex, rtspPort });
            // },
            // error: function (oError) {
            //   // console.log(ip + ' start real play failed ', oError.errorCode, oError.errorMsg);
            //   // if (g_iWndIndex < 0 || g_iWndIndex >= division * division) return resolve({ ip, stream, channel, g_iWndIndex, rtspPort });
            //   realPlay((reRealPlayCount += 1));
            //   // reject(oError);
            // },
          }
          // pluginOBJ
        ).then(
          (e) => {
            // if (g_iWndIndex >= 0 && g_iWndIndex < 16) console.log('재생 성공', { g_iWndIndex });
            resolve({ ip, stream, channel, g_iWndIndex, rtspPort });
          },
          (e) => {
            // console.log(e, '????2', { g_iWndIndex });
            if (e.errorCode === 3001 || e.errorCode === 501) {
              if (gubun) {
                WebVideoCtrl.I_Stop(
                  {
                    success: function () {
                      // console.log('I_Stop', { ip, stream, channel, g_iWndIndex, rtspPort });
                      // if (g_iWndIndex < 0 || g_iWndIndex >= division * division) return resolve({ ip, stream, channel, g_iWndIndex, rtspPort });
                      realPlay((reRealPlayCount += 1));
                    },
                    error: function (e) {
                      console.log('error', { ip, stream, channel, g_iWndIndex, rtspPort });
                      // reject(e);
                    },
                  },
                  g_iWndIndex,
                  pluginOBJ
                );
              } else {
                resolve({ ip, stream, channel, g_iWndIndex, rtspPort });
              }
            }
            // if (e.errorCode === 3001) {
            //   // console.log('재생 성공2', { ip, stream, channel, g_iWndIndex, rtspPort });
            //   resolve({ ip, stream, channel, g_iWndIndex, rtspPort });
            // }
            else if (e.errorCode === 2001) {
              // if (g_iWndIndex < 0 || g_iWndIndex >= division * division) return resolve({ ip, stream, channel, g_iWndIndex, rtspPort });
              realPlay((reRealPlayCount += 1));
            } else {
              // ? errorCode 26은 websdk 재설치 필요한듯?
              // console.log({ openModal, gubun });
              if (openModal && gubun) {
                console.log(e.errorCode);
                if (e.errorCode === undefined || e.errorCode === 26) {
                  console.log('destory and openModal');
                  console.log('error NVR info : ', { ip, stream, channel, g_iWndIndex, rtspPort, pluginOBJ, gubun });
                  destroy();
                  openModal((prev) => ({
                    ...prev,
                    status: true,
                    type: 'websdk',
                  }));
                }
              }
              reject(e);
            }
          }
        );
      } else {
        reject('repeat > 5');
      }
    };
    if (oWndInfo != null) {
      WebVideoCtrl.I_Stop(
        {
          success: function () {
            realPlay(0);
          },
          error: function (e) {
            console.log('error', { ip, stream, channel, g_iWndIndex, rtspPort }, e);
          },
        },
        g_iWndIndex,
        pluginOBJ
      );
    } else {
      realPlay(0);
    }
  });
  return oPromise;
}

export function changeStream(ip, stream, channel, g_iWndIndex, rtspPort, pluginOBJ) {
  WebVideoCtrl.I_StartRealPlay(
    ip,
    {
      winIdx: g_iWndIndex,
      iStreamType: parseInt(stream, 10),
      iChannelID: parseInt(channel, 10),
      bZeroChannel: false,
      iPort: rtspPort,
      success: function () {
        var szInfo = 'start real play success.';
        // console.log(szInfo, { ip, stream, channel, g_iWndIndex, rtspPort });
      },
      error: function (oError) {
        console.log(ip + ' start real play failed ', oError.errorCode, oError.errorMsg);
      },
    },
    pluginOBJ
  );
}

// stop real play
export function clickStopRealPlay(wndIndex) {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(wndIndex),
    szInfo = '';
  let oPromise = new Promise(function (resolve, reject) {
    WebVideoCtrl.I_Stop(
      {
        success: function () {
          szInfo = 'stop real play success.';
          // console.log(oWndInfo.szDeviceIdentify + ' ' + szInfo, wndIndex);
          resolve(wndIndex);
        },
        error: function (oError) {
          // console.error(szDeviceIdentify + ' stop real play failed ', oError.errorCode, oError.errorMsg);
          reject(oError);
        },
      },
      wndIndex
    );
  });
  return oPromise;
}

export function stopRealPlayWithIndex(windowIndex) {
  let szInfo = '';
  WebVideoCtrl.I_Stop(
    {
      success: function () {
        szInfo = 'stop real play success.';
        // console.log(szInfo, windowIndex);
      },
      error: function (oError) {
        // console.log('stop real play failed ', oError.errorCode, oError.errorMsg);
      },
    },
    windowIndex
  );
}

// stop all real play
export function stopAllRealPlay(pluginOBJ) {
  return WebVideoCtrl.I_StopAllPlay(pluginOBJ);
}

export function changeWndNum(cctvDivisions, pluginOBJ) {
  cctvDivisions = parseInt(cctvDivisions, 10);
  WebVideoCtrl.I_ChangeWndNum(cctvDivisions, pluginOBJ).then(
    () => {
      division = cctvDivisions;
      // console.log('Change window number successful!');
    },
    (oError) => {
      var szInfo = 'Change window number failed!';
      console.log(szInfo, oError.errorCode, oError.errorMsg);
    }
  );
}

// get channel info
export async function getChannelInfo(ip) {
  var szDeviceIdentify = ip,
    oSel = $('#channels').empty();

  if (null == szDeviceIdentify) {
    return;
  }

  const arr = [];

  // IP channel
  await WebVideoCtrl.I_GetDigitalChannelInfo(szDeviceIdentify, {
    success: function (xmlDoc) {
      var oChannels = $(xmlDoc).find('InputProxyChannelStatus');
      $.each(oChannels, function (i) {
        var id = $(this).find('id').eq(0).text(),
          name = $(this).find('name').eq(0).text(),
          online = $(this).find('online').eq(0).text();
        if ('false' == online) {
          return true;
        }
        if ('' == name) {
          name = 'IPCamera ' + (i < 9 ? '0' + (i + 1) : i + 1);
        }
        oSel.append("<option value='" + id + "' bZero='false'>" + name + '</option>');
        arr.push({ id, name, online });
      });
      console.log(szDeviceIdentify + ' get IP channel success.');
      return arr;
    },
    error: function (oError) {
      console.log(szDeviceIdentify + ' get IP channel failed ', oError.errorCode, oError.errorMsg);
    },
  });
  return arr;
}

// PTZ control, 9- auto; 1,2,3,4,5,6,7,8 -  PTZ direction control by mouse
var g_bPTZAuto = false;
export function mouseDownPTZControl(iPTZIndex, iPTZSpeed) {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex),
    bZeroChannel = $('#channels option').eq(g_iWndIndex).attr('bZero') == 'true' ? true : false;

  if (bZeroChannel) {
    // zero-channel does not support PTZ
    return;
  }

  if (oWndInfo != null) {
    if (9 == iPTZIndex && g_bPTZAuto) {
      iPTZSpeed = 0; // you can close auto mode by setting speed to 0 when auto is start already
    } else {
      g_bPTZAuto = false; // auto mode will be close when you clik other direction
    }

    WebVideoCtrl.I_PTZControl(iPTZIndex, false, {
      iPTZSpeed: iPTZSpeed,
      success: function (xmlDoc) {
        if (9 == iPTZIndex && g_bPTZAuto) {
          console.log(oWndInfo.szDeviceIdentify + ' stop PTZ success.');
        } else {
          console.log(oWndInfo.szDeviceIdentify + ' start PTZ success.');
        }
        if (9 == iPTZIndex) {
          g_bPTZAuto = !g_bPTZAuto;
        }
      },
      error: function (oError) {
        console.log(oWndInfo.szDeviceIdentify + ' start PTZ failed ', oError.errorCode, oError.errorMsg);
      },
    });
  }
}

// stop PTZ direction
export function mouseUpPTZControl() {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);

  if (oWndInfo != null) {
    WebVideoCtrl.I_PTZControl(1, true, {
      success: function (xmlDoc) {
        console.log(oWndInfo.szDeviceIdentify + ' stop PTZ direction successful.');
      },
      error: function (oError) {
        console.log(oWndInfo.szDeviceIdentify + ' stop PTZ direction failed ', oError.errorCode, oError.errorMsg);
      },
    });
  }
}

// enable 3D zoom
export function clickEnable3DZoom(wndIndex) {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(wndIndex);
  const oPromise = new Promise((resolve, reject) => {
    if (oWndInfo != null) {
      WebVideoCtrl.I_Enable3DZoom().then(
        () => {
          resolve(wndIndex);
        },
        (oError) => {
          reject(oError);
        }
      );
    }
  });
  return oPromise;
}

// diasble 3D zoom
export function clickDisable3DZoom(wndIndex) {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(wndIndex);
  const oPromise = new Promise((resolve, reject) => {
    if (oWndInfo != null) {
      WebVideoCtrl.I_Disable3DZoom().then(
        () => {
          resolve(wndIndex);
        },
        (oError) => {
          reject(oError);
        }
      );
    }
  });
  return oPromise;
}

// enable E-zoom
export function clickEnableEZoom(wndIndex) {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(wndIndex);
  const oPromise = new Promise((resolve, reject) => {
    if (oWndInfo != null) {
      WebVideoCtrl.I_EnableEZoom(wndIndex).then(
        () => {
          resolve(wndIndex);
        },
        (oError) => {
          reject(oError);
        }
      );
    }
  });
  return oPromise;
}

//disable E-zoom
export function clickDisableEZoom(wndIndex) {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(wndIndex);
  const oPromise = new Promise((resolve, reject) => {
    if (oWndInfo != null) {
      WebVideoCtrl.I_DisableEZoom().then(
        () => {
          resolve(wndIndex);
        },
        (oError) => {
          console.log(oError);
          // reject(oError);
        }
      );
    }
  });
  return oPromise;
}

export function ptzZoomIn() {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);

  if (oWndInfo != null) {
    WebVideoCtrl.I_PTZControl(10, false, {
      iWndIndex: g_iWndIndex,
      success: function (xmlDoc) {
        // console.log(oWndInfo.szDeviceIdentify + ' Zoom+success.');
      },
      error: function (oError) {
        // console.log(oWndInfo.szDeviceIdentify + '  Zoom+success failed ', oError.errorCode, oError.errorMsg);
      },
    });
  }
}

export function ptzZoomOut() {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);

  if (oWndInfo != null) {
    WebVideoCtrl.I_PTZControl(11, false, {
      iWndIndex: g_iWndIndex,
      success: function (xmlDoc) {
        // console.log(oWndInfo.szDeviceIdentify + ' Zoom-success.');
      },
      error: function (oError) {
        // console.log(oWndInfo.szDeviceIdentify + '  Zoom-failed.', oError.errorCode, oError.errorMsg);
      },
    });
  }
}

export function ptzZoomStop() {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);

  if (oWndInfo != null) {
    WebVideoCtrl.I_PTZControl(11, true, {
      iWndIndex: g_iWndIndex,
      success: function (xmlDoc) {
        // console.log(oWndInfo.szDeviceIdentify + ' stop zoom success.');
      },
      error: function (oError) {
        // console.log(oWndInfo.szDeviceIdentify + '  stop zoom failed ', oError.errorCode, oError.errorMsg);
      },
    });
  }
}

export function ptzIrisIn() {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);

  if (oWndInfo != null) {
    WebVideoCtrl.I_PTZControl(14, false, {
      iWndIndex: g_iWndIndex,
      success: function (xmlDoc) {
        // console.log(oWndInfo.szDeviceIdentify + ' Iris+success.');
      },
      error: function (oError) {
        // console.log(oWndInfo.szDeviceIdentify + '  Iris+failed ', oError.errorCode, oError.errorMsg);
      },
    });
  }
}

export function ptzIrisOut() {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);

  if (oWndInfo != null) {
    WebVideoCtrl.I_PTZControl(15, false, {
      iWndIndex: g_iWndIndex,
      success: function (xmlDoc) {
        // console.log(oWndInfo.szDeviceIdentify + ' Iris-success.');
      },
      error: function (oError) {
        // console.log(oWndInfo.szDeviceIdentify + '  Iris-failed ', oError.errorCode, oError.errorMsg);
      },
    });
  }
}

export function ptzIrisStop() {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);

  if (oWndInfo != null) {
    WebVideoCtrl.I_PTZControl(14, true, {
      iWndIndex: g_iWndIndex,
      success: function (xmlDoc) {
        // console.log(oWndInfo.szDeviceIdentify + ' stop Iris success.');
      },
      error: function (oError) {
        // console.log(oWndInfo.szDeviceIdentify + '  stop Iris failed ', oError.errorCode, oError.errorMsg);
      },
    });
  }
}

export function ptzFocusIn() {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);

  if (oWndInfo != null) {
    WebVideoCtrl.I_PTZControl(12, false, {
      iWndIndex: g_iWndIndex,
      success: function (xmlDoc) {
        // console.log(oWndInfo.szDeviceIdentify + ' focus+success.');
      },
      error: function (oError) {
        // console.log(oWndInfo.szDeviceIdentify + '  focus+failed ', oError.errorCode, oError.errorMsg);
      },
    });
  }
}

export function ptzFocusOut() {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);

  if (oWndInfo != null) {
    WebVideoCtrl.I_PTZControl(13, false, {
      iWndIndex: g_iWndIndex,
      success: function (xmlDoc) {
        // console.log(oWndInfo.szDeviceIdentify + ' focus-success.');
      },
      error: function (oError) {
        // console.log(oWndInfo.szDeviceIdentify + '  focus-failed ', oError.errorCode, oError.errorMsg);
      },
    });
  }
}

export function ptzFocusStop() {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);

  if (oWndInfo != null) {
    WebVideoCtrl.I_PTZControl(12, true, {
      iWndIndex: g_iWndIndex,
      success: function (xmlDoc) {
        // console.log(oWndInfo.szDeviceIdentify + ' stop focus success.');
      },
      error: function (oError) {
        // console.log(oWndInfo.szDeviceIdentify + '  stop focus failed ', oError.errorCode, oError.errorMsg);
      },
    });
  }
}

// reconnection
export function reconnect(szDeviceIdentify, repeatCount) {
  let repeat = repeatCount || 0;
  const oPromise = new Promise((resolve, reject) => {
    WebVideoCtrl.I_Reconnect(szDeviceIdentify, {
      timeout: 3000,
      success: function (xmlDoc) {
        resolve(xmlDoc);
        // console.log(szDeviceIdentify + ' reconnect success');
      },
      error: function (e) {
        if (repeat < 3) {
          setTimeout(function () {
            console.log('??');
            reconnect(szDeviceIdentify, (repeat += 1));
          }, 1000);
        } else {
          reject(e);
        }
      },
    });
  });
  return oPromise;
}

// open option dialog 0: folder, 1: file
export async function clickOpenFileDlg(setFilePath) {
  await WebVideoCtrl.I_OpenFileDlg(0).then(
    function (szDirPath) {
      if (szDirPath != -1 && szDirPath != '' && szDirPath != null) {
        setFilePath(szDirPath);
      }
    },
    function () {
      console.log('Failed to open file path.');
    }
  );
}

export function clickOpenDirectory(openPath) {
  WebVideoCtrl.I_OpenDirectory(openPath || g_oLocalConfig.recordPath).then(
    function () {
      console.log('Success to open file path.');
    },
    function () {
      console.log('Failed to open file path.');
    }
  );
}

// get local parameters
export function clickGetLocalCfg() {
  WebVideoCtrl.I_GetLocalCfg().then(
    (oLocalCofing) => {
      g_oLocalConfig = oLocalCofing;
      // console.log('Get local configuration success.', g_oLocalConfig);
    },
    (oError) => {
      var szInfo = 'Get local configuration failed.';
      console.log(szInfo, oError.errorCode, oError.errorMsg);
    }
  );
}

// set local parameters
export async function clickSetLocalCfg(capturePath, videoPath) {
  g_oLocalConfig.capturePath = capturePath;
  g_oLocalConfig.recordPath = videoPath;
  g_oLocalConfig.playbackPicPath = capturePath;
  g_oLocalConfig.playbackFilePath = videoPath;
  g_oLocalConfig.downloadPath = videoPath;
  WebVideoCtrl.I_SetLocalCfg(g_oLocalConfig).then(
    () => {
      console.log('Set local configuration success.');
    },
    (oError) => {
      var szInfo = 'Set local configuration failed.';
      console.log(szInfo, oError.errorCode, oError.errorMsg);
    }
  );
}

export function setTextOverlay(szDeviceIdentify, text) {
  const channelId = g_iWndIndex + 1;
  var szUrl = 'ISAPI/System/Video/inputs/channels/' + channelId + '/overlays/text';
  WebVideoCtrl.I_GetTextOverlay(szUrl, szDeviceIdentify, {
    success: function (data) {
      const textOverlayListEl = $(data).find('TextOverlayList').eq(0);
      const textOverlayEl = textOverlayListEl.find('TextOverlay');
      if (textOverlayEl.length > 0) {
        textOverlayEl.remove();
      }
      textOverlayListEl.append(`
      <TextOverlay>
      <id>1</id>
      <enabled>true</enabled>
      <displayText>${text}</displayText>
      <positionX>50</positionX>
      <positionY>450</positionY>
      </TextOverlay>`);
      var xmldoc = toXMLStr(data);
      var newOptions = {
        type: 'PUT',
        data: xmldoc,
        success: function (r) {
          console.log(szDeviceIdentify + ' set osd info successful');
        },
        error: function (oError) {
          console.log(szDeviceIdentify + ' set osd info failed', oError.errorCode, oError.errorMsg);
        },
      };

      WebVideoCtrl.I_SendHTTPRequest(szDeviceIdentify, szUrl, newOptions);
    },
    error: function (oError) {
      console.log(szDeviceIdentify + ' set osd info failed ', oError.errorCode, oError.errorMsg);
    },
  });
}
function toXMLStr(oXmlDoc) {
  var szXmlDoc = '';

  try {
    var oSerializer = new XMLSerializer();
    szXmlDoc = oSerializer.serializeToString(oXmlDoc);
  } catch (e) {
    try {
      szXmlDoc = oXmlDoc.xml;
    } catch (e) {
      return '';
    }
  }
  if (szXmlDoc.indexOf('<?xml') == -1) {
    szXmlDoc = "<?xml version='1.0' encoding='utf-8'?>" + szXmlDoc;
  }

  return szXmlDoc;
}

// full screen
export function clickFullScreen() {
  WebVideoCtrl.I_FullScreen(true).then(
    () => {
      console.log(' full screen success.');
    },
    (oError) => {
      console.log('full screen failed ', oError.errorCode, oError.errorMsg);
    }
  );
}

// start record
export function clickStartRecord(wndIndex) {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(wndIndex);
  const oPromise = new Promise((resolve, reject) => {
    if (oWndInfo != null) {
      var szChannelID = wndIndex + 1,
        szFileName = oWndInfo.szDeviceIdentify + '_' + szChannelID + '_' + new Date().getTime();

      WebVideoCtrl.I_StartRecord(szFileName, wndIndex, {
        bDateDir: true,
        success: function () {
          resolve(wndIndex);
        },
        error: function (oError) {
          reject(oError);
        },
      });
    }
  });
  return oPromise;
}

// start record with window index
export function startRecordWithIndex(cameraName, windowIndex) {
  let oWndInfo = WebVideoCtrl.I_GetWindowStatus(windowIndex);

  if (oWndInfo != null) {
    let szFileName = cameraName + '_' + formatDateToYYYYMMDDHHMMSS(new Date());
    WebVideoCtrl.I_StartRecord(szFileName, windowIndex);
  }
}

// start record All
export function clickStartRecordAll(wndIndex) {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(wndIndex);
  const oPromise = new Promise((resolve, reject) => {
    if (oWndInfo != null) {
      var szChannelID = wndIndex + 1,
        szFileName = oWndInfo.szDeviceIdentify + '_' + szChannelID + '_' + new Date().getTime();

      WebVideoCtrl.I_StartRecord(szFileName, wndIndex, {
        bDateDir: true,
        success: function () {
          resolve(wndIndex);
        },
        error: function (e) {
          reject(wndIndex, e);
        },
      });
    }
  });
  return oPromise;
}

// stop record
export function clickStopRecord(wndIndex) {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(wndIndex);
  const oPromise = new Promise((resolve, reject) => {
    if (oWndInfo != null) {
      WebVideoCtrl.I_StopRecord(wndIndex, {
        success: function () {
          resolve(wndIndex);
        },
        error: function (oError) {
          reject(oError);
        },
      });
    }
  });
  return oPromise;
}

// stop record with window index
export function stopRecordWithIndex(windowIndex) {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(windowIndex),
    szInfo = '';

  if (oWndInfo != null) {
    WebVideoCtrl.I_StopRecord(windowIndex);
  }
}

// stop record All
export function clickStopRecordAll(wndIndex) {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(wndIndex);
  const oPromise = new Promise((resolve, reject) => {
    if (oWndInfo != null) {
      WebVideoCtrl.I_StopRecord(wndIndex, {
        wndIndex,
        success: function () {
          resolve(wndIndex);
        },
        error: function (e) {
          reject(wndIndex, e);
        },
      });
    }
  });
  return oPromise;
}

// capture
export async function clickCapturePic(szType) {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex),
    szInfo = '';
  const oPromise = new Promise((resolve, reject) => {
    if (oWndInfo != null) {
      var oLocalConfig = WebVideoCtrl.I_GetLocalCfg();
      var szCaptureFileFormat = '0';
      if (oLocalConfig) {
        szCaptureFileFormat = oLocalConfig.captureFileFormat;
      }

      var szChannelID = g_iWndIndex + 1;
      var szPicName = oWndInfo.szDeviceIdentify + '_' + szChannelID + '_' + new Date().getTime();
      if ('playback' === szType) {
        szPicName = 'playback_' + oWndInfo.szDeviceIdentify + '_' + szChannelID + '_' + new Date().getTime();
      }

      szPicName += '0' === szCaptureFileFormat ? '.jpg' : '.bmp';
      WebVideoCtrl.I_CapturePic(szPicName, {
        bDateDir: true,
      }).then(
        function () {
          szInfo = 'capture success';
          // console.log(oWndInfo.szDeviceIdentify + ' ' + szInfo);
          resolve(true);
        },
        function (oError) {
          szInfo = ' capture failed';
          reject(false);
        }
      );
    }
  });
  return oPromise;
}
/**
 * 작성자 : 한영광
 * 작성일자 : 2024.04.01
 * 작성내용 : CCTV 화면 위에 텍스트를 오버레이 할 때 배경 색상을 넣는 방법으로 Polygon을 2개 설정하여
 *           카메라 명의 글자 수에 따라 '█' 문자열을 카메라명 뒤에 렌더링되도록 처리
 */
function countKoreanCharacters(str) {
  let count = 0;
  const koreanCharRegex = /[\uAC00-\uD7A3]/g;

  const matches = str.match(koreanCharRegex);
  if (matches) {
    count = matches.length;
  }

  return count;
}

function fillBlocks(str) {
  const squareString = '█';
  let squareStrings = '';
  const totalLength = str.length;
  const KLength = countKoreanCharacters(str);
  for (let i = 1; i <= KLength; i++) {
    squareStrings = squareStrings + squareString + squareString;
  }
  for (let i = 1; i <= totalLength - KLength; i++) {
    squareStrings = squareStrings + squareString;
  }
  return squareStrings + squareString;
}

//set graph ifno
export function clickSetSnapPolygon(wndIndex, cName, cctvDivisions, camCLocation, camFColor) {
  if (wndIndex < cctvDivisions * cctvDivisions && wndIndex >= 0) {
    const perHeightDivision = 4 / (cctvDivisions * cctvDivisions) / 100;
    const perWidthDivision = cctvDivisions / 4 / 100;
    const [R, G, B] = camFColor.split('/');
    const background = fillBlocks(cName);
    const textWidth = background.length + 5;
    const addWidth = textWidth * perWidthDivision;
    const addHeight = perHeightDivision;
    let location = '';
    switch (camCLocation) {
      case '1': // 왼쪽 상단
        location = `<point><x>${0.01}</x><y>${0.01}</y></point>`;
        break;
      case '2': // 왼쪽 하단
        location = `<point><x>${0.01}</x><y>${0.9 + addHeight}</y></point>`;
        break;
      case '3': // 오른쪽 상단
        location = `<point><x>${0.9 - addWidth}</x><y>${0.01}</y></point>`;
        break;
      case '4': // 오른쪽 하단
        location = `<point><x>${0.9 - addWidth}</x><y>${0.9 + addHeight}</y></point>`;
        break;
    }
    if (camCLocation === '0') {
      return;
    }
    // WebVideoCtrl.I_ClearSnapInfo(wndIndex);
    var szInfo = "<?xml version='1.0' encoding='utf-8'?>";
    szInfo += '<SnapPolygonList>';

    szInfo += '<SnapPolygon>';
    szInfo += `<id>0</id>`;
    szInfo += `<wndIndex>${wndIndex}</wndIndex>`;
    szInfo += '<polygonType>1</polygonType>';
    szInfo += `<tips>${fillBlocks(cName)}</tips>`;
    szInfo += '<isClosed>false</isClosed>';
    szInfo += '<color><r>0</r><g>0</g><b>0</b></color>';
    szInfo += '<pointList>';
    szInfo += location;
    szInfo += '</pointList>';
    szInfo += '</SnapPolygon>';
    szInfo += '<SnapPolygon>';
    szInfo += `<id>1</id>`;
    szInfo += `<wndIndex>${wndIndex}</wndIndex>`;
    szInfo += '<polygonType>1</polygonType>';
    szInfo += `<tips>${cName}</tips>`;
    szInfo += '<isClosed>false</isClosed>';
    szInfo += `<color><r>${R}</r><g>${G}</g><b>${B}</b></color>`;
    szInfo += '<pointList>';
    szInfo += location;
    szInfo += '</pointList>';
    szInfo += '</SnapPolygon>';

    szInfo += '</SnapPolygonList>';

    WebVideoCtrl.I_SetSnapPolygonInfo(wndIndex, szInfo).then(
      () => {
        console.log('set the graph succeed.');
      },
      (oError) => {
        console.log('set the graph failed ', oError.errorCode, oError.errorMsg);
      }
    );
  }
}

// record searching
var g_iSearchTimes = 0;
export function clickRecordSearch(szDeviceIdentify, stream, channelNum, startTime, endTime) {
  var szDeviceIdentify = szDeviceIdentify,
    iChannelID = channelNum,
    iStreamType = stream,
    szStartTime = startTime,
    szEndTime = endTime;

  const oPromise = new Promise((resolve, reject) => {
    WebVideoCtrl.I_RecordSearch(szDeviceIdentify, iChannelID, szStartTime, szEndTime, {
      iStreamType: iStreamType,
      iSearchPos: g_iSearchTimes,
      success: function (xmlDoc) {
        const result = [];
        if ('MORE' === $(xmlDoc).find('responseStatusStrg').eq(0).text()) {
          for (var i = 0, nLen = $(xmlDoc).find('searchMatchItem').length; i < nLen; i++) {
            var szPlaybackURI = $(xmlDoc).find('playbackURI').eq(i).text();
            if (szPlaybackURI.indexOf('name=') < 0) {
              break;
            }
            var szStartTime = $(xmlDoc).find('startTime').eq(i).text();
            var szEndTime = $(xmlDoc).find('endTime').eq(i).text();
            var szFileName = szPlaybackURI.substring(szPlaybackURI.indexOf('name=') + 5, szPlaybackURI.indexOf('&size='));
            result.push({
              szPlaybackURI,
              szFileName,
              szStartTime: szStartTime.replace('T', ' ').replace('Z', ''),
              szEndTime: szEndTime.replace('T', ' ').replace('Z', ''),
              size: Math.floor(Number(szPlaybackURI.split('&size=')[1]) / 1024 / 1024),
            });
          }
        } else if ('OK' === $(xmlDoc).find('responseStatusStrg').eq(0).text()) {
          var iLength = $(xmlDoc).find('searchMatchItem').length;
          for (var i = 0; i < iLength; i++) {
            var szPlaybackURI = $(xmlDoc).find('playbackURI').eq(i).text();
            if (szPlaybackURI.indexOf('name=') < 0) {
              break;
            }
            var szStartTime = $(xmlDoc).find('startTime').eq(i).text();
            var szEndTime = $(xmlDoc).find('endTime').eq(i).text();
            var szFileName = szPlaybackURI.substring(szPlaybackURI.indexOf('name=') + 5, szPlaybackURI.indexOf('&size='));
            result.push({
              szPlaybackURI,
              szFileName,
              szStartTime: szStartTime.replace('T', ' ').replace('Z', ''),
              szEndTime: szEndTime.replace('T', ' ').replace('Z', ''),
              size: Math.floor(Number(szPlaybackURI.split('&size=')[1]) / 1024 / 1024),
            });
          }
        } else if ('NO MATCHES' === $(xmlDoc).find('responseStatusStrg').eq(0).text()) {
          setTimeout(function () {
            g_iSearchTimes = 0;
            // console.log(szDeviceIdentify + ' no record file.');
          }, 50);
        }
        resolve(result);
      },
      error: function (oError) {
        g_iSearchTimes = 0;
        console.log(szDeviceIdentify + ' earch record file failed ', oError.errorCode, oError.errorMsg);
        reject(oError);
      },
    });
  });
  return oPromise;
}

// start play back
export function clickStartPlayback(szDeviceIdentify, rtspPort, stream, channelNum, startTime, endTime, wndIndex) {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(wndIndex),
    szDeviceIdentify = szDeviceIdentify,
    iChannelID = channelNum,
    iStreamType = stream,
    iRtspPort = rtspPort,
    szStartTime = startTime,
    szEndTime = endTime;

  var oTransCodeParam = {
    TransFrameRate: '16', // 0: full, 5: 1, 6: 2, 7: 4, 8: 6, 9: 8, 10: 10, 11: 12, 12: 16, 14: 15, 15: 18, 13: 20, 16: 22
    TransResolution: '2', // 255: Auto, 3: 4CIF, 2: QCIF, 1: CIF
    TransBitrate: '23', // 2: 32K, 3: 48K, 4: 64K, 5: 80K, 6: 96K, 7: 128K, 8: 160K, 9: 192K, 10: 224K, 11: 256K, 12: 320K, 13: 384K, 14: 448K, 15: 512K, 16: 640K, 17: 768K, 18: 896K, 19: 1024K, 20: 1280K, 21: 1536K, 22: 1792K, 23: 2048K, 24: 3072K, 25: 4096K, 26: 8192K
  };

  const oPromise = new Promise((resolve, reject) => {
    const startPlayback = () => {
      WebVideoCtrl.I_StartPlayback(szDeviceIdentify, {
        iWndIndex: wndIndex,
        iRtspPort: iRtspPort,
        iStreamType: iStreamType,
        iChannelID: iChannelID,
        szStartTime: szStartTime,
        szEndTime: szEndTime,
        // oTransCodeParam: oTransCodeParam,
        success: function () {
          console.log(szDeviceIdentify + ' ' + 'start playback success.');
          resolve();
        },
        error: function (oError) {
          console.log(szDeviceIdentify + 'start playback failed ', oError.errorCode, oError.errorMsg);
          reject();
        },
      });
    };

    if (oWndInfo != null) {
      WebVideoCtrl.I_Stop({
        success: function () {
          startPlayback();
        },
      });
    } else {
      startPlayback();
    }
  });
  return oPromise;
}

//start reverse play
export function clickReversePlayback(szDeviceIdentify, rtspPort, stream, channelNum, startTime, endTime, wndIndex) {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(wndIndex),
    szDeviceIdentify = szDeviceIdentify,
    iChannelID = channelNum,
    iStreamType = stream,
    iRtspPort = rtspPort,
    szStartTime = startTime,
    szEndTime = endTime;
  const oPromise = new Promise((resolve, reject) => {
    var reversePlayback = function () {
      WebVideoCtrl.I_ReversePlayback(szDeviceIdentify, {
        iWndIndex: wndIndex,
        iRtspPort: iRtspPort,
        iStreamType: iStreamType,
        iChannelID: iChannelID,
        szStartTime: szStartTime,
        szEndTime: szEndTime,
        success: function () {
          console.log(szDeviceIdentify + ' ' + 'start reverse play success.');
          resolve();
        },
        error: function (oError) {
          console.log(szDeviceIdentify + 'start reverse play failed ', oError.errorCode, oError.errorMsg);
          reject();
        },
      });
    };

    if (oWndInfo != null) {
      WebVideoCtrl.I_Stop({
        success: function () {
          reversePlayback();
        },
      });
    } else {
      reversePlayback();
    }
  });
  return oPromise;
}

// pause
export function clickPause(wndIndex) {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(wndIndex),
    szInfo = '';
  const oPromise = new Promise((resolve, reject) => {
    if (oWndInfo != null) {
      WebVideoCtrl.I_Pause({
        success: function () {
          szInfo = 'pause success.';
          console.log(oWndInfo.szDeviceIdentify + ' ' + szInfo);
          resolve();
        },
        error: function (oError) {
          szInfo = 'pause failed ';
          console.log(oWndInfo.szDeviceIdentify + szInfo, oError.errorCode, oError.errorMsg);
          reject();
        },
      });
    }
  });
  return oPromise;
}

// resume
export function clickResume(wndIndex) {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(wndIndex),
    szInfo = '';
  const oPromise = new Promise((resolve, reject) => {
    if (oWndInfo != null) {
      WebVideoCtrl.I_Resume({
        success: function () {
          szInfo = 'resume success.';
          console.log(oWndInfo.szDeviceIdentify + ' ' + szInfo);
          resolve();
        },
        error: function (oError) {
          szInfo = 'resume failed ';
          console.log(oWndInfo.szDeviceIdentify + szInfo, oError.errorCode, oError.errorMsg);
          reject();
        },
      });
    }
  });
  return oPromise;
}

// slow play
export function clickPlaySlow(wndIndex) {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(wndIndex),
    szInfo = '';
  const oPromise = new Promise((resolve, reject) => {
    if (oWndInfo != null) {
      WebVideoCtrl.I_PlaySlow({
        success: function () {
          szInfo = 'slow play success.';
          console.log(oWndInfo.szDeviceIdentify + ' ' + szInfo);
          resolve();
        },
        error: function (oError) {
          szInfo = 'slow play failed ';
          console.log(oWndInfo.szDeviceIdentify + szInfo, oError.errorCode, oError.errorMsg);
          reject();
        },
      });
    }
  });
  return oPromise;
}

// fast play
export function clickPlayFast(wndIndex) {
  var oWndInfo = WebVideoCtrl.I_GetWindowStatus(wndIndex),
    szInfo = '';
  const oPromise = new Promise((resolve, reject) => {
    if (oWndInfo != null) {
      WebVideoCtrl.I_PlayFast({
        success: function () {
          szInfo = 'fast play success.';
          console.log(oWndInfo.szDeviceIdentify + ' ' + szInfo);
          resolve();
        },
        error: function (oError) {
          szInfo = 'fast play failed ';
          console.log(oWndInfo.szDeviceIdentify + szInfo, oError.errorCode, oError.errorMsg);
          reject();
        },
      });
    }
  });
  return oPromise;
}

// download video
export function clickStartDownloadRecord(ip, szPlaybackURI, fileName) {
  const oPromise = new Promise((resolve, reject) => {
    WebVideoCtrl.I_StartDownloadRecord(ip, szPlaybackURI, fileName, {
      bDateDir: true,
    }).then(
      (iDownloadID) => {
        // console.log(iDownloadID);
        resolve();
      },
      (oError) => {
        // WebVideoCtrl.I_GetLastError().then((iErrorValue) => {
        //   if (34 == iErrorValue) {
        //     console.log(szDeviceIdentify + ' download already.');
        //   } else if (33 == iErrorValue) {
        //     console.log(szDeviceIdentify + ' lack of space.');
        //   } else {
        //     console.log(szDeviceIdentify + ' download failed.');
        //   }
        // });
        reject();
      }
    );
  });
  return oPromise;
}
// sownload stop
export function clickStopDownload() {
  const oPromise = new Promise((resolve, reject) => {
    WebVideoCtrl.I_StopDownloadRecord().then(
      () => {
        // showOPInfo('stop download successful.');
        // clearInterval(g_tDownloadProcess);
        // g_tDownloadProcess = 0;
        // g_iDownloadID = -1;
        // $('#downProcess').remove();
        resolve();
      },
      (oError) => {
        // showOPInfo(szDeviceIdentify + ' stop download failed ', oError.errorCode, oError.errorMsg);
        reject();
      }
    );
  });
  return oPromise;
}

// resize window
export function resizeWindow(width, height) {
  const oPromise = new Promise(async (resolve, reject) => {
    try {
      await WebVideoCtrl.I_Resize(width, height);
      resolve();
    } catch (error) {
      console.error('resize error', error);
      reject();
    }
  });
  return oPromise;
}
