import styled from 'styled-components';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { BtnBlue } from '../../../components/Button';
import { InputTable } from '../../../assets/styles/InputTable';
import JobtypeOnTable from './JobtypeOnTable';
import ClickableContainer from '../../s_cctv/S_cctv1/RealtimeCCTV/ClickableContainer';
import JobTypeFilter from './JobTypeFilter';
import TableLamp from './TableLamp';
import { useTabletVideo } from '../../../hooks/useTabletVideo';
import { useRecoilValue } from 'recoil';
import { IUser, userState } from '../../../atoms';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  justify-content: flex-end;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`;

const Root = styled.div`
  .header {
    padding: 0.5rem;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    display: flex;
    gap: 0.5rem;
    .title {
      flex-shrink: 0;
      width: fit-content;
      font-weight: 600;
      margin-right: 0.5rem;
    }
    .search {
      width: 20rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      border-radius: 0.25rem;
      padding: 0 0.5rem;
      input {
        padding: 0;
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
        background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
        :focus {
          outline: none;
        }
      }
      .material-symbols-rounded {
        user-select: none;
        font-size: 1.375rem;
        &.close-icon {
          font-variation-settings: 'FILL' 1, 'wght' 500;
          cursor: pointer;
          font-size: 1.25rem;
          border-radius: 0.25rem;
          padding: 0.125rem;
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          &:hover {
            box-shadow: 0 0 0.25rem 0.125rem ${({ theme }: { theme: any }) => theme.tonal_deep};
          }
          &:active {
            background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
          }
          &.hidden {
            display: none;
          }
        }
      }
    }
    button {
      flex-shrink: 0;
      padding: 0 0.75rem;
      width: 4.5rem;
      height: 2.5rem;
      font-size: 0.875rem;
      border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      &:hover {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
      }
    }
  }
  .formTitle {
    padding: 0 0.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  /* .header {
    padding: 0.75rem;
    height: 3rem;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
  } */
  .scrollBox {
    overflow: auto;
    display: flex;
  }
`;

interface Props {
  data: JobtypeVideoRow[];
  sType: string;
  selectedIndex: number | null;
  setSelectedIndex: Dispatch<SetStateAction<number | null>>;
  tableState: JobtypeVideoRow[];
  setTableState: Dispatch<SetStateAction<JobtypeVideoRow[]>>;
}

const VideoLists = ({ data, sType, selectedIndex, setSelectedIndex, tableState, setTableState }: Props) => {
  const { t } = useTranslation();
  const size = useOutletContext<any>();
  const [tableWidth, setTableWidth] = useState<number>(0);
  const childElementRef = useRef<HTMLSpanElement>(null);
  const parentElementRef = useRef<HTMLDivElement>(null);
  const { updateUseYn } = useTabletVideo(sType as '1' | '2');
  const userInfo = useRecoilValue<IUser>(userState);
  const [wPrejobtype, setWPrejobtype] = useState({ type: 'wPrejobtype', wPrejobtype: '', cdName: '' }); // 공종 상태
  const [wJobtype, setWJobtype] = useState({ type: 'wJobtype', wJobtype: '', cdName: '' }); // 직종 상태

  // 양식지 명 너비 적용
  useEffect(() => {
    const parent = parentElementRef.current;
    const child = childElementRef.current;
    const updateTableWidth = () => {
      if (parent && child) {
        const width = parent.clientWidth - child.clientWidth;
        setTableWidth(width);
      }
    };

    const observer = new ResizeObserver(updateTableWidth);
    if (parent) observer.observe(parent);
    if (child) observer.observe(child);

    return () => {
      if (parent) observer.unobserve(parent);
      if (child) observer.unobserve(child);
    };
  }, [data]);

  useEffect(() => {
    if (data) {
      const userPrejobtypeYn = userInfo.prejobtypeYn;
      const searchJobtypeIdx = userPrejobtypeYn === 'Y' ? `${wPrejobtype.wPrejobtype}${wJobtype.wJobtype}` : wJobtype.wJobtype;
      const filterArray = data.filter((el: JobtypeVideoRow) => el.preJobtypeYn === userPrejobtypeYn);
      const result = filterArray.filter((item: JobtypeVideoRow) => item.jobtypeIdx && item.jobtypeIdx.includes(searchJobtypeIdx));

      if (searchJobtypeIdx) setTableState(result);
      else setTableState(data);
    }
  }, [data]);

  useEffect(() => {
    setSelectedIndex(null);
  }, []);

  // 중장비 안전보건 서약서 양식지인지 아닌지 확인
  const isJobtypeChip = sType === '2';

  return (
    <Root className={size.innerSize.W >= 1024 || size.innerSize.W < 1024 ? 'showRoot' : 'hideRoot'}>
      {sType === '2' && (
        <JobTypeFilter
          data={data} //
          setTableState={setTableState}
          setSelectedIndex={setSelectedIndex}
          wPrejobtype={wPrejobtype}
          setWPrejobtype={setWPrejobtype}
          wJobtype={wJobtype}
          setWJobtype={setWJobtype}
        />
      )}
      <InputTable className='margin-left-05'>
        <div className='thead'>
          <div className='tr'>
            <div className='trCol2p5 flex-center tableStickyNo'>No</div>
            <div
              className='trCol10 flex-center required tableStickyTitle content-overflow' //
              ref={parentElementRef}
            >
              {t('안전교육 제목')}
            </div>
            <div className='trCol6 flex-center'>{t('사용유무')}</div>
          </div>
        </div>
        <div className='table'>
          <div className='tbody'>
            {tableState?.map((el: JobtypeVideoRow, i: number) => (
              <div
                className={`tr ${selectedIndex === i ? 'selected' : ''}`} //
                role='button'
                tabIndex={0}
                key={`${el.sSeq}_${i}`}
                onClick={() => setSelectedIndex(i)}
              >
                <div className='trCol2p5 flex-center tableStickyNo text_disabled'>{i + 1}</div>
                <div className='trCol10 flex-start tableStickyTitle content-overflow'>
                  {!isJobtypeChip ? (
                    el.sTitle
                  ) : (
                    <>
                      <span ref={childElementRef}>{el.sTitle}</span>
                      <JobtypeOnTable
                        jobtypeIdx={tableState[i].jobtypeIdx ?? ''} //
                        selected={i === selectedIndex}
                        width={tableWidth}
                      />
                    </>
                  )}
                </div>
                <div className='trCol6 flex-center selector'>
                  {/* {el.useYn === 'Y' ? t('사용') : t('미사용')} */}
                  <ClickableContainer
                    onClick={() => updateUseYn(el)} //
                    button={<TableLamp use={el.useYn === 'Y'} />}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </InputTable>
      <ButtonsWrapper>
        <BtnBlue
          className={selectedIndex === null ? 'disabled' : ''}
          onClick={() => setSelectedIndex(null)} //
        >
          {t('새 양식지 작성')}
        </BtnBlue>
      </ButtonsWrapper>
    </Root>
  );
};

export default VideoLists;
