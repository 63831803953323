/**
 * 작성자 : 김광민
 * 날짜 : 2024.02.06
 * 경로 : 터널 모달 > 굴진/일반정보 외 공통 모듈
 */

import styled from 'styled-components';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { getTotalLength, getTotalPercentage, getTotalRLength } from '../utils/tunnel';
import HalfDoughnutChart from './chart/HalfDoughnutChart';
import TinyTunnelTab from './tunnel/TinyTunnelTab';

const Root = styled.div`
  height: 10rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  .chart {
    position: absolute;
    width: 100%;
    height: 8rem;
    margin-bottom: 2rem;
    canvas {
      position: absolute;
    }
  }
  .badge {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: fit-content;
    border-radius: 0.5rem;
    padding-left: 0.25rem;
    padding-right: 1rem;
    outline: 1px solid ${({ theme }: { theme: any }) => theme.tonal};
    box-shadow: 0px 4px 12px 4px rgba(0, 0, 0, 0.05);
    .titleName {
      height: 2.5rem;
      display: flex;
      gap: 0.125rem;
      align-items: center;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      border-radius: 2rem;
      padding-top: 0.125rem;
      flex-shrink: 0;
    }
    .tatCd {
      font-size: 0.875rem;
      font-weight: 600;
      color: ${({ theme }: { theme: any }) => theme.filled_blue};
      margin-left: 0.25rem;
      background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
      border-radius: 0.5rem;
      min-width: 1.25rem;
      padding: 0 0.375rem;
      text-align: center;
      height: 1.75rem;
      line-height: 1.75rem;
    }
  }

  .chartInfo {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .percentage {
      font-size: 2.5rem;
      font-weight: 600;
      margin-left: 0.5rem;
      margin-top: 2.25rem;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      text-align: center;
      .symbol {
        font-size: 1.25rem;
        font-weight: 400;
        color: ${({ theme }: { theme: any }) => theme.text_disabled};
        margin-left: 0.125rem;
      }
    }
    .description {
      font-size: 0.875rem;
      margin-top: 1.75rem;
      text-align: center;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      color: ${({ theme }: { theme: any }) => theme.filled_blue};
      border-radius: 2rem;
      padding: 0 1rem;
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      outline: 2px solid ${({ theme }: { theme: any }) => theme.tonal_blue};
      height: 2rem;
      display: flex;
      align-items: center;
    }
  }
`;

type Props = {
  data: TunnelType;
  isPointVisible: FilterViewType;
  setIsPointVisible: Dispatch<SetStateAction<FilterViewType>>;
  isEndPointUse: boolean;
  isStartPointUse: boolean;
  visiblePointFilter?: boolean;
};

const TareaNormalInfoHeader = ({ data, isPointVisible, setIsPointVisible, isEndPointUse, isStartPointUse, visiblePointFilter = true }: Props) => {
  const { t } = useTranslation();

  return (
    <Root>
      <div className='chart'>
        <HalfDoughnutChart value={100} />
        <HalfDoughnutChart value={(getTotalRLength(data) / getTotalLength(data)) * 100} background animation={false} />
      </div>
      <div className='badge'>
        <span className='tatCd'>{data?.tatCd ?? ''}</span>
        <span className='titleName'>{data?.tatName ?? ''}</span>
      </div>
      <div className='chartInfo'>
        <div className='percentage'>
          {getTotalPercentage(data)}
          <span className='symbol'>%</span>
        </div>
        <span className='description'>{t('전체 진행률')}</span>
      </div>
      {visiblePointFilter && <TinyTunnelTab isPointVisible={isPointVisible} setIsPointVisible={setIsPointVisible} isStartPointUse={isStartPointUse} isEndPointUse={isEndPointUse} />}
    </Root>
  );
};

export default TareaNormalInfoHeader;
