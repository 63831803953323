import { useEffect, useState } from 'react';
import { useSetAuth } from '../../../utils/useSetAuth';
import TabletEduEditor from './TabletEduEditor';
import TabletEduList from './TabletEduList';
import { useGetTabletTemplete, useSigncode } from '../../../hooks/useTablet';
import { PulseLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { IUser, userState } from '../../../atoms';
import { logPost } from '../../../services/log';
import { useFetchCommonCodeList } from '../../../services/useSetCodeListInSelectBoxForm';
import { COMCD_STEMPLET_GUBUN } from '../../../_constants';
import IssueGuide from '../../../components/IssueGuide';
import { useTranslation } from 'react-i18next';

const AgreementsForm = ({ tGubun }: { tGubun: 'C' | 'J' }) => {
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const userInfo = useRecoilValue<IUser>(userState);

  // 양식 조회
  const { data, isLoading, isError, refetch } = useGetTabletTemplete(tGubun);
  const [newData, setNewData] = useState<TabletTemplate[]>(data?.sort((a: any, b: any) => b.wDate?.localeCompare(a.wDate)) || []);
  const [filteredData, setFilteredData] = useState<TabletTemplate[]>(newData?.sort((a: any, b: any) => b.wDate?.localeCompare(a.wDate)) || []);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [warning, setWarning] = useState(false);
  const { data: STEMPLETComCdList, isLoading: isLoadingTempleteCode } = useFetchCommonCodeList(COMCD_STEMPLET_GUBUN, true, 'etc1', tGubun); // 스마트안전교육 양식지 공통코드 목록
  const INIT_TEMPLATE_A = { type: COMCD_STEMPLET_GUBUN, [COMCD_STEMPLET_GUBUN]: 'A', cdName: t('전체') };
  const [template, setTemplate] = useState(INIT_TEMPLATE_A);
  const [filteredSigncode, setFilteredSigncode] = useState([]);

  // 사인코드(태그) 조회
  const { signcodeQuery } = useSigncode();
  const {
    data: signcodeData, //
    isLoading: signcodeIsLoading,
    isError: signcodeIsError,
  } = signcodeQuery;

  useEffect(() => {
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '태블릿 안전교육 관리 > 개인정보 동의서',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    setFilteredSigncode(signcodeData?.filter((v: any) => v.useYn === 'Y'));
  }, [signcodeData]);

  useEffect(() => {
    if (data) {
      const newArrayWithTempletName = data.map((el: any) => ({ ...el, templetName: STEMPLETComCdList?.find((temp) => temp[COMCD_STEMPLET_GUBUN] === el.tGubun)?.cdName }));
      const sorted = newArrayWithTempletName?.sort((a: any, b: any) => b.wDate?.localeCompare(a.wDate));
      setNewData(sorted);
    }
  }, [data, STEMPLETComCdList]);

  if (isLoading || signcodeIsLoading || isLoadingTempleteCode) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  if (isError || signcodeIsError) return <IssueGuide />;

  return (
    <div className='content-container setting-medical'>
      <TabletEduList
        rawData={newData}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        auth={auth}
        refetch={refetch}
        warning={warning}
        tGubun={tGubun}
        setTemplate={setTemplate}
      />
      <TabletEduEditor
        rawData={newData}
        filteredData={filteredData}
        auth={auth}
        tagOptions={filteredSigncode}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        refetch={refetch}
        tGubun={tGubun}
        setWarning={setWarning}
        template={template}
        setTemplate={setTemplate}
      />
    </div>
  );
};

export default AgreementsForm;
