/**
 * 작성자 : 홍선영
 * 날짜 : 2023.09.04
 * 경로 : 안전 관리/게시판 - 현장전달사항
 */

import { useState, useRef, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { PulseLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { IComCdList, IModal, ITabObject } from 'customTypes';
import { COMCD_USE_YN, INIT_USE_YN_A, INIT_USE_YN_Y } from '../../../_constants';
import { pageInfoState, userState } from '../../../atoms';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import i18n from '../../../translation/i18n';
import { BtnGhost } from '../../../components/Button';
import RangePicker, { onChangeRangeInput } from '../../../components/RangePicker';
import { formatDateYMD, isValidYYYYMMDD } from '../../../utils/formatDate';
import Input from '../../../components/Input';
import Portal from '../../../components/Portal';
import TuiGrid from '../../../components/Table/TuiGrid';
import SelectBox from '../../../components/SelectBox';
import BackButton from '../../../components/BackButton';
import IssueGuide from '../../../components/IssueGuide';
import DeleteModal from '../../../components/Modal/DeleteModal2';
import TuiGridWrapper from '../../../components/Table/TuiGridWrapper';
import DashboardViewModal from '../../../components/Modal/DashboardViewModal';
import SiteTransferEditorInputForm from '../../../components/Form/SiteTransferEditorInputForm';
import { ynFilter } from '../../../utils/ynFilter';
import { trimObject } from '../../../utils/trimObject';
import { setComCdListState } from '../../../utils/setComCdListState';
import { logPost } from '../../../services/log';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import { ContentsContainerRoot } from '../../../assets/styles/ContentsContainerRoot';
import { apiDelete, apiGet, apiPost } from '../../../services/_common';
import { useFetchCommonCodeList } from '../../../services/useSetCodeListInSelectBoxForm';

const Root = styled(ContentsContainerRoot)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &.hideRoot {
    visibility: hidden;
    position: absolute;
  }
  .tui-container {
    flex-grow: 1;
    overflow: hidden;
    margin-left: 0.5rem;
  }
  > .inputFormsWrapper {
    height: 3.5rem !important;
    flex-shrink: 0;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    padding: 0 1rem;
    border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  }
  .arrow-icon span {
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
  .icon-btn {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    gap: 0.25rem;
    cursor: pointer;
    border-radius: 5rem;
    padding-right: 1rem;
    padding-left: 0.5rem;
    font-size: 0.875rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .icon-btn:hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
  }
`;
interface Notice {
  cSeq: string;
  cTitle: string;
  cContent: string;
  bigo: string;
  useYn: string;
  cDate: string;
}

const Transfer = () => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const [columns, setColumns] = useState<any[]>([]);
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const userInfoInputFormRef = useRef<any>(null); // 인풋 폼 ref (테이블 로우를 클릭했을 때 바로 inputForm 으로 스크롤 이동시키기 위한 ref)
  const { data: useYnComCdListWithAll } = useFetchCommonCodeList(COMCD_USE_YN, true); // 사용여부 공통코드 목록 (전체포함)
  const { data: useYnComCdList } = useFetchCommonCodeList(COMCD_USE_YN, false);
  const [initNoticeList, setInitNoticeList] = useState<Notice[]>([]);
  const [noticeListForTui, setNoticeListForTui] = useState<Notice[]>([]);
  const [rowState, setRowState] = useState({
    hCd: '',
    sCd: '',
    cSeq: '',
    cTitle: '',
    cContent: '',
    cDate: '',
    bigo: '',
    useYn: '',
    writer: '',
    editor: '',
  }); // 서버에서 받아 온 raw data 어레이 중 1줄의 데이터 state
  const [isNewAdd, setIsNewAdd] = useState(true); // 신규등록 여부
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const [searchTitle, setSearchTitle] = useState({ title: '' });
  const [userInputDateRange, setUserInputDateRange] = useState({ start: '', end: '' });
  const [useYn, setUseYn] = useState(INIT_USE_YN_A);
  const [inputFormUseYn, setInputFormUseYn] = useState(INIT_USE_YN_Y);
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const { userMenuList } = userInfo;
  const [newTabList, setNewTabList] = useState<ITabObject[]>();
  const [selectedRowKey, setSelectedRowKey] = useState<any>(0);
  const [visibleRangePicker, setVisibleRangePicker] = useState(false);
  const [pickerDateRange, setPickerDateRange] = useState([{ startDate: '', endDate: '', key: 'selection' }]);
  const [editorData, setEditorData] = useState<string>('');
  const [cDate, setCDate] = useState('');
  const [openSubPage, setOpenSubPage] = useState(false);
  const [cSeq, setCSeq] = useState<null | string>(null);
  const [method, setMethod] = useState<'get' | 'delete'>('get');

  useEffect(() => {
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '안전관리/게시판 > 현장 전달사항',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    const arr: any[] = [];
    userMenuList.map((v: any) => {
      const result = tabInfo?.tabList?.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  useEffect(() => {
    setColumns([
      {
        header: t('코드'),
        name: 'cSeq',
        align: 'center',
        sortable: true,
        width: 60,
      },
      {
        header: t('적용일자'),
        name: 'cDate',
        sortable: true,
        filter: 'select',
        minWidth: 120,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('제목'),
        name: 'cTitle',
        align: 'left',
        sortable: true,
        minWidth: 200,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('비고'),
        name: 'bigo',
        align: 'left',
        sortable: true,
        minWidth: 120,
      },
      {
        header: t('사용유무'),
        name: 'useYnCdName',
        align: 'center',
        sortable: true,
        minWidth: 80,
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    applyFilter(initNoticeList);
  }, [userInputDateRange, useYn[COMCD_USE_YN], searchTitle.title, initNoticeList]);

  useEffect(() => {
    if (pickerDateRange[0].startDate) {
      setUserInputDateRange((prev) => ({ ...prev, start: dayjs(pickerDateRange[0].startDate).format('YYYY-MM-DD') }));
    }
    if (pickerDateRange[0].endDate) {
      setUserInputDateRange((prev) => ({ ...prev, end: dayjs(pickerDateRange[0].endDate).format('YYYY-MM-DD') }));
    }
  }, [pickerDateRange[0].startDate, pickerDateRange[0].endDate]);

  useEffect(() => {
    if (cDate !== '') setRowState((prev: any) => ({ ...prev, cDate: cDate?.replace('-', '') }));
  }, [cDate, selectedRowKey]);

  const convertToDate = (dateString: string) => new Date(`${dateString}T00:00:00`);

  const applyFilter = (array: any[]) => {
    const titleFilteredArray = array.filter((item: any) => {
      return item.cTitle?.toLowerCase()?.includes(searchTitle.title?.toLowerCase());
    });
    let newFilter = titleFilteredArray;
    if (userInputDateRange.start !== '' && userInputDateRange.end !== '') {
      newFilter = titleFilteredArray.filter((item) => {
        const itemDate = convertToDate(item.cDate);
        const startDate = convertToDate(userInputDateRange.start);
        const endDate = convertToDate(userInputDateRange.end);

        return itemDate >= startDate && itemDate <= endDate;
      });
    }

    const result = ynFilter(newFilter, 'useYn', useYn[COMCD_USE_YN]);
    if (result.length > 0) {
      const newArray = result.map((el: any, i: number) => ({ ...el, useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용'), rowKey: i, sortKey: i }));
      setNoticeListForTui(newArray);
    } else {
      setNoticeListForTui([]);
    }
  };

  const {
    data: noticeList,
    isFetching,
    isRefetching,
    isError,
    refetch: refetchNoticeList,
  } = useQuery(['noticeList', userInfo.hCd, userInfo.sCd], () => fetchNoticeList(method), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
    cacheTime: 0,
  });

  const getParams = (methodParam: string) => {
    const commonReq = { hCd: userInfo.hCd, sCd: userInfo.sCd };
    if (methodParam === 'get') return commonReq;
    return { ...commonReq, cSeq, editor: userInfo.userId };
  };

  const fetchNoticeList = async (methodParam: string) => {
    try {
      const req = getParams(methodParam);
      let res;
      if (methodParam === 'get') res = await apiGet({ path: '/safe/communicate', req });
      else res = await apiDelete({ path: '/safe/communicate', req });
      const { communicateList } = res.data?.data || [];
      if (communicateList) {
        const newArray = communicateList.map((el: any) => ({
          ...el,
          useYn: el.useYn === t('사용') ? 'Y' : 'N',
          useYnCdName: el.useYn,
        }));
        return { method, data: newArray };
      }
      return { method };
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  useEffect(() => {
    // 조회/삭제 성공시
    if (noticeList) {
      // 조회했을 때
      if (noticeList.method === 'get') {
        setNoticeListForTui(noticeList.data);
        setInitNoticeList(noticeList.data);
      } else {
        // 삭제했을 때
        setMethod('get'); // 데이터 리패치

        setSelectedRowKey(null);
        initiateState();
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '현장 전달사항',
          action: '삭제',
          etc: `${rowState.cSeq}`,
        });
      }
    }
  }, [noticeList]);

  const { data: noticeData, isError: isErrorNoticeData } = useQuery(['noticeGet', userInfo.hCd, userInfo.sCd, cSeq], () => fetchNoticeData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && cSeq !== null,
    cacheTime: 0,
  });

  const fetchNoticeData = async () => {
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, cSeq };
      const res = await apiGet({ path: '/safe/communicateview', req });
      return res.data.data;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  useEffect(() => {
    if (noticeData) {
      setRowState(noticeData);
      setIsNewAdd(false);
      setEditorData(noticeData.cContent);
    }
  }, [noticeData]);

  const createNewUserAPI = async (reqData: any) => {
    const req = trimObject(reqData);
    const res = await apiPost({ path: '/safe/communicate', contentType: 'application/json', req });
    const { statusCode, data, message } = res.data;
    if (statusCode === 200) {
      setRowState(data);
      setEditorData(data.cContent);

      const newData = { ...data, useYnCdName: data.useYn === 'Y' ? t('사용') : t('미사용') };
      setInitNoticeList((prev) => [newData, ...prev]);
      setSelectedRowKey(0);
      setCSeq(data.cSeq);

      toast.success(t(message));
      setIsNewAdd(false);
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '안전관리/게시판-현장 전달사항',
        action: '신규등록',
        etc: `${data.cSeq}`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  const updateUserAPI = async (reqData: any) => {
    const req = trimObject(reqData);
    const res = await apiPost({ path: '/safe/communicate', contentType: 'application/json', req });
    const { statusCode, data, message } = res.data;

    if (statusCode === 200) {
      setRowState(data);
      setEditorData(data.cContent);

      const newData = {
        ...data,
        useYnCdName: data.useYn === 'Y' ? t('사용') : t('미사용'),
      };

      // 수정 데이터 tui에서 찾아서 수정
      const copyInitArray = [...initNoticeList];

      const findInitIndex = initNoticeList.findIndex((el: any) => el.cSeq === data.cSeq);

      if (findInitIndex !== -1) {
        copyInitArray[findInitIndex] = newData;
        setInitNoticeList(copyInitArray);
      }

      toast.success(t(message));
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '안전관리/게시판-현장 전달사항',
        action: '저장',
        etc: `${data.cSeq}`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  const onClickInitiateSearchOption = () => {
    setSearchTitle({ title: '' });
    setUserInputDateRange({ start: '', end: '' });
    setPickerDateRange([{ startDate: '', endDate: '', key: 'selection' }]);
    setUseYn(INIT_USE_YN_A);
    onClickNewRegistration(); // 우측 인풋창도 초기화
  };

  // 사용자 Row 클릭
  const onClickRow = (rowData: any) => {
    if (rowData.cSeq !== '') {
      setCDate(rowData?.cDate.replaceAll('-', ''));
      setInputFormUseYn({ type: COMCD_USE_YN, [COMCD_USE_YN]: rowData.useYn, cdName: t(rowData.useYnCdName) });
      setCSeq(rowData.cSeq.toString());
    }
    setSelectedRowKey(rowData.rowKey);
    if (size.innerSize.W < 1024) {
      setOpenSubPage(true);
    }
  };

  // 인풋폼 초기화
  const initiateState = () => {
    Object.keys(rowState).map((el: any) => {
      // 기본값 제외하고 초기화
      return setRowState((prev: any) => ({
        ...prev,
        [el]: '',
        useYn: 'Y',
        writer: userInfo.userId,
        editor: userInfo.userId,
      }));
    });
    setInputFormUseYn(INIT_USE_YN_Y);
    setIsNewAdd(true);
    setIsSaveClicked(false);
    setCDate('');
    setEditorData('');
    setCSeq(null);
  };

  const onClickNewRegistration = () => {
    initiateState();
    setSelectedRowKey(null);
    setOpenSubPage(true);
  };

  const onClickSave = async () => {
    setIsSaveClicked(true);
    const newData: any = {
      ...rowState,
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      cDate: cDate || '',
      cContent: editorData,
      useYn: inputFormUseYn[COMCD_USE_YN],
      editor: userInfo.userId,
      writer: isNewAdd ? userInfo.userId : rowState.writer,
    };
    const { wDate, eDate, ...resultData } = newData;
    if (newData.cTitle?.trim() === '') return toast.warning(t('제목을 입력하세요'));
    if (!cDate) return toast.warning(t('적용일자를 선택하세요'));
    if (cDate && (cDate.length < 8 || !isValidYYYYMMDD(cDate))) return toast.warning(t('적용일자를 확인하세요'));
    if (isNewAdd) return createNewUserAPI(resultData);
    return updateUserAPI(resultData);
  };

  useEffect(() => {
    refetchNoticeList();
  }, [method]);

  const deleteAPI = () => {
    setMethod('delete');
  };

  const onClickDelete = () => {
    setOpenModal((prev) => ({ ...prev, status: true, type: 'delete', api: deleteAPI }));
  };

  const onClickRangeInput = () => {
    if (!visibleRangePicker) setVisibleRangePicker(true);
  };

  const onClickDashboardView = () => {
    const newData: any = {
      ...rowState,
      cDate: formatDateYMD(cDate),
      cContent: editorData,
    };

    setOpenModal((prev) => ({ ...prev, status: true, type: 'dashboardView', data: newData }));
  };
  const componentRef = useRef<HTMLDivElement>(null);
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);

  const backToMain = () => {
    initiateState();
    setSelectedRowKey(null);
    setOpenSubPage(false);
  };

  const hasDataForTab = noticeList && !!noticeList.data;
  const isLoading = !columns || isFetching || isRefetching;
  const shouldRenderData = !isLoading && hasDataForTab;

  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight, componentRef.current?.offsetWidth, shouldRenderData]);

  if (isError) {
    return <IssueGuide />;
  }

  return (
    <div className={`content-container ${size.innerSize.W >= 1024 ? 'twoColumn column-55 max800' : 'oneColumn'}`}>
      <Root loading={isLoading} className={size.innerSize.W >= 1024 || (size.innerSize.W < 1024 && !openSubPage) ? 'showRoot' : 'hideRoot'}>
        <SearchOptions align='left'>
          <div className='inputsWrapper'>
            <div className='inputForm-row'>
              <div className='inputForm-col withLabelComCf'>
                <label htmlFor='useYn'>{t('사용유무')}</label>
                <SelectBox
                  options={useYnComCdListWithAll}
                  defaultOption={useYn.cdName}
                  state={useYn}
                  setState={setUseYn}
                  stateKey={COMCD_USE_YN}
                  initiateKey={useYn[COMCD_USE_YN]}
                  filterbar='filter-1-left'
                />
              </div>
            </div>

            <div className='inputForm-row-fit'>
              <span className='calendarLabel'>{t('작성일자')}</span>
              <div className='inputForm-col'>
                <div className='flex-basic'>
                  <input
                    id='startInput'
                    type='text'
                    value={userInputDateRange.start}
                    onMouseDown={onClickRangeInput}
                    onChange={(e) => onChangeRangeInput(e, 'start', setPickerDateRange, setUserInputDateRange)}
                  />
                  <span className='wave'> ~ </span>
                  <input
                    id='endInput'
                    type='text'
                    value={userInputDateRange.end}
                    onMouseDown={onClickRangeInput}
                    onChange={(e) => onChangeRangeInput(e, 'end', setPickerDateRange, setUserInputDateRange)}
                  />
                </div>
                {visibleRangePicker && (
                  <div className='rangePickerWrapper'>
                    <RangePicker state={pickerDateRange} setState={setPickerDateRange} setVisible={setVisibleRangePicker} />
                  </div>
                )}
              </div>
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col'>
                <Input label='' placeholder={t('제목')} type='text' id='title' name='title' state={searchTitle} setState={setSearchTitle} />
              </div>
            </div>
          </div>
          <div className='inputsWrapper'>
            <div className='secondSearchOption'>
              <div className='flex-basic textBtnGroup'>
                <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
              </div>
              <div className='flex-basic iconBtnGroup'>
                <BtnGhost onClick={onClickNewRegistration}>
                  <span className='material-symbols-rounded'>add</span>
                  {t('등록')}
                </BtnGhost>
              </div>
            </div>
          </div>
        </SearchOptions>
        {isLoading ? (
          <LoadingModalBackground>
            <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' style={{ width: 'inherit', height: 'inherit' }} />
          </LoadingModalBackground>
        ) : shouldRenderData ? (
          <TuiGridWrapper componentRef={componentRef}>
            <TuiGrid data={noticeListForTui} columns={columns} onClickRow={onClickRow} perPage={5} rowKey={selectedRowKey} scrollX height={tuiHeight} frozenCount={1} />
          </TuiGridWrapper>
        ) : (
          <IssueGuide />
        )}
      </Root>
      {(size.innerSize.W >= 1024 || (size.innerSize.W < 1024 && openSubPage)) && (
        <Root loading={false}>
          <div className='inputFormsWrapper'>
            {size.innerSize.W < 1024 && <BackButton func={() => backToMain()} />}
            <div className='formTitle'>{isNewAdd ? t('새로운 전달사항') : t('전달사항 상세')}</div>
          </div>
          {!isErrorNoticeData ? (
            <SiteTransferEditorInputForm
              state={rowState}
              setState={setRowState}
              isNewAdd={isNewAdd}
              userInfoInputFormRef={userInfoInputFormRef}
              onClickNewAdd={onClickNewRegistration}
              onClickSave={onClickSave}
              onClickDelete={onClickDelete}
              onClickPreview={onClickDashboardView}
              inputFormUseYn={inputFormUseYn}
              setInputFormUseYn={setInputFormUseYn}
              useYnComCdList={useYnComCdList}
              isSaveClicked={isSaveClicked}
              date={cDate}
              setDate={setCDate}
              editorData={editorData}
              setEditorData={setEditorData}
            />
          ) : (
            <IssueGuide />
          )}
        </Root>
      )}
      <Portal openModal={openModal?.status}>
        {openModal && openModal.type === 'delete' && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}
        {openModal && openModal.type === 'dashboardView' && <DashboardViewModal openModal={openModal} setOpenModal={setOpenModal} />}
      </Portal>
    </div>
  );
};

export default Transfer;
