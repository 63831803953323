/**
 * 작성자 : 홍선영
 * 날짜 : 2023.03.29
 * 기능 : 특정 노드의 스크롤이 맨 하단에 닿았는 지 여부를 true/false로 반환하는 함수
 *        (사용 예 : 보고서의 하단까지 스크롤 했을 때 true/false값을 받아서 플로팅버튼 노출)
 */

import { useEffect, useState, useCallback } from 'react';

export const useDetectScrolledToBottom = (node: React.RefObject<any>) => {
  const [isBottom, setIsBottom] = useState(false);
  const handleScroll = useCallback(() => {
    const { scrollTop, scrollHeight, clientHeight } = node.current;
    const nearBottom = scrollHeight - clientHeight <= scrollTop + 50;
    const exactBottom = scrollTop + clientHeight === scrollHeight;
    if (node && nearBottom) {
      setIsBottom(true);
    } else setIsBottom(false);
  }, [node]);

  useEffect(() => {
    if (node?.current) {
      node.current.addEventListener('scroll', handleScroll, true);
      return () => node.current && node.current.removeEventListener('scroll', handleScroll, true);
    }
    return undefined;
  }, [node, handleScroll]);

  return { isBottom };
};
