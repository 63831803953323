import styled from 'styled-components';

export const PhotoTableListStyle = styled.div`
  user-select: none;
  height: 100%;
  > .widget-header {
    width: 100%;
    margin: 0.75rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    > .widget-title {
      padding: 0 1rem;
      height: 2rem;
      align-items: center;
      > span {
        font-weight: ${({ theme }: { theme: any }) => theme.font_semibold};
        font-size: 1.125rem;
        line-height: 1.2;
        color: ${({ theme }: { theme: any }) => theme.text_primary};
        > .badge {
          font-weight: 500;
          font-size: 0.875rem;
          padding: 0.25rem 0.25rem;
          border-radius: 0.25rem;
          background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
          margin: 0 0.5rem;
          color: rgb(62, 151, 255);
          display: inline-block;
          min-width: 1.5rem;
          text-align: center;
        }
      }
      > .widget-title-innerTab,
      > .widget-btn-group {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        > span {
          font-weight: ${({ theme }: { theme: any }) => theme.font_semibold};
          font-size: 1.125rem;
          color: ${({ theme }: { theme: any }) => theme.text_primary};
        }
        > button {
          height: 2rem;
          font-weight: ${({ theme }: { theme: any }) => theme.font_bold};
          padding: 0 0.75rem;
          border-radius: 1rem;
          font-size: 0.875rem;
          color: ${({ theme }: { theme: any }) => theme.text_secondary};
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          border-radius: 0.5rem;
          gap: 0;
        }
        > button:hover {
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
        }
        > button:nth-child(1) {
          padding-left: 0.25rem;
          padding-right: 0.5rem;
          .material-symbols-rounded {
            font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 20;
            color: #78829d;
          }
        }
        > .innerTab {
          gap: 0.25rem;
          > div {
            cursor: pointer;
            height: 2rem;
            padding: 0 0.75rem;
            display: flex;
            align-items: center;
            font-weight: 500;
            color: ${({ theme }: { theme: any }) => theme.text_secondary};
            border-radius: 1rem;
            font-weight: ${({ theme }: { theme: any }) => theme.font_medium};
            /* font-size: 0.875rem; */
          }
          > div.active {
            font-weight: ${({ theme }: { theme: any }) => theme.font_bold};
            color: #3e97ff;
            background-color: ${({ theme }: { theme: any }) => theme.tonal};
          }
          > div:hover {
            background-color: ${({ theme }: { theme: any }) => theme.tonal};
          }
        }
      }
    }
  }
  > .widget-header.safety {
    gap: 0;
    > .widget-subTitle {
      font-size: 0.875rem;
      line-height: 1rem;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      padding: 0 1rem;
    }
  }
  .widget-body {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .widget-body.safety {
    gap: 1rem;
    > div:nth-child(1) {
      background-color: rgb(255, 247, 237);
    }
  }
  .widget-body.photoTable {
    flex-grow: 1;
    overflow: hidden;

    .green {
      color: ${({ theme }: { theme: any }) => theme.filled_green} !important;
      background-color: ${({ theme }: { theme: any }) => theme.tonal_green} !important;
    }

    > .cardContainer {
      padding: 0.5rem;
      height: 100%;

      .card {
        padding: 0.5rem;

        > div {
          padding: 0.5rem;
        }
      }

      .tabName {
        width: 100%;
        border-radius: 0.5rem;
        height: 5rem;
        font-size: 1.8rem;
        font-weight: 600;
        text-align: center;
        color: ${({ theme }: { theme: any }) => theme.filled_blue};
        background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        line-height: 2.2;
      }

      .count {
        height: calc(100% - 4.5rem);
        font-size: 12rem;
        color: ${({ theme }: { theme: any }) => theme.text_secondary} !important;

        .countText {
          font-size: 3rem;
          padding: 6rem 0.5rem 0.5rem 1rem;
          color: ${({ theme }: { theme: any }) => theme.text_tertiary} !important;
        }
      }

      > div {
        height: inherit;
        > div {
          height: inherit;
          > div {
            height: inherit;
            > div {
              height: inherit;
              > div {
                height: inherit;
                > div {
                  height: inherit;
                }
              }
            }
          }
        }
      }

      .slick-slider .slick-track,
      .slick-slider .slick-list {
        width: 100%;
      }

      .slick-track {
        > div > div > div {
          overflow: hidden;
          text-overflow: ellipsis;
          > .tabName {
            white-space: nowrap;
          }
        }
      }

      .slick-slide .active {
        font-weight: bold;
        color: ${({ theme }: { theme: any }) => theme.filled_blue};
      }
    }

    > .innerTab {
      // width: 6rem;
      width: 100%;
      display: flex;
      border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      word-break: auto-phrase;
      text-align: center;

      .slick-slider .slick-track,
      .slick-slider .slick-list {
        width: 100%;
      }

      .slick-track {
        > div > div > div {
          overflow: hidden;
          text-overflow: ellipsis;
          > .tabName {
            white-space: nowrap;
          }
        }
      }

      .slick-slide .active {
        font-weight: bold;
        color: ${({ theme }: { theme: any }) => theme.filled_blue};
      }

      > div {
        min-width: 5rem;
        height: 3rem;
        display: flex;
        align-items: center;
        flex: 1 1 50%;
        justify-content: center;
        border-bottom: 2px solid transparent;
        font-size: 0.875rem;
        cursor: pointer;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
        font-weight: 700;
      }
      > div.active {
        color: ${({ theme }: { theme: any }) => theme.text_primary};
        border-bottom: 2px solid ${({ theme }: { theme: any }) => theme.selected_primary};
      }
      .text-container .active {
        display: block;
        animation: slideIn 2s forwards;
      }

      @keyframes slideIn {
        from {
          opacity: 0;
          transform: translateX(-100%);
        }
        to {
          opacity: 1;
          transform: translateX(0);
        }
      }

      @keyframes slide {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-100%);
        }
      }
    }
    > .innerTab.nonClick > div {
      pointer-events: none;
      cursor: initial;
    }
    > .sliderWrapper {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
      .text-container {
        display: flex;
        flex-direction: column;
        position: relative;
      }

      .rowWrapper {
        pointer-events: none;
        width: 100%;
        padding-right: 0.5rem;
        padding-left: 1rem;
        cursor: pointer;
        gap: 0.5rem;
        height: 3.5rem;
        > div:nth-child(1) {
          flex-grow: 1;
          gap: 0.5rem;
          width: 12rem;
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
          .imgWrapper {
            width: 2.25rem;
            height: 2.25rem;
            border-radius: 0.25rem;
            overflow: hidden;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.05);
            .material-symbols-rounded {
              font-variation-settings: 'FILL' 1, 'wght' 600, 'GRAD' 200, 'opsz' 48;
              color: ${({ theme }: { theme: any }) => theme.text_tertiary};
              font-size: 1.75rem;
            }

            img {
              width: inherit;
              height: inherit;
              object-fit: cover;
            }
          }
          > div:nth-child(2) {
            width: calc(100% - 2.75rem);
            > div:nth-child(2),
            > .rowContent {
              width: 100%;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              font-size: 0.875rem;
              line-height: 1.25rem;
              height: 1.25rem;
              color: ${({ theme }: { theme: any }) => theme.text_disabled};
              span {
                // color: ${({ theme }: { theme: any }) => theme.text_primary};
                font-size: 0.875rem;
                line-height: 1rem;
              }
            }
          }
        }

        .ellipsis {
          max-width: 9rem;
          max-height: 1.5rem;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .buttonsWrapper {
        gap: 0.5rem;
        button {
          height: inherit;
          padding: 0;
          // width: 2.5rem;
          font-size: 0.875rem;
          height: 1.25rem;
          span {
            font-weight: 700;
            margin: 0 0.5rem;
          }
        }
        button.violet {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_violet};
          color: ${({ theme }: { theme: any }) => theme.filled_violet};
        }
        button.gray {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        }
        button.blue {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
          color: ${({ theme }: { theme: any }) => theme.filled_blue};
        }
        button.amber {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_amber};
          color: ${({ theme }: { theme: any }) => theme.filled_amber};
        }
      }
    }
  }
`;
