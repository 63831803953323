import { useEffect, useState } from 'react';
import { IoPersonAdd } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { IModal } from 'customTypes';
import { BtnGray } from '../Button';
import { ModalBackground, Modal } from '../../assets/styles/Modal';

const Thumbnail = ({ openModal, setOpenModal }: any) => {
  const { t } = useTranslation();
  const [data, setData] = useState(openModal.data);

  useEffect(() => {
    setData(openModal.data);
  }, [openModal.data]);

  // 닫기버튼 클릭
  const onClickClose = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };

  return (
    <ModalBackground onClick={onClickClose}>
      <Modal maxWidth='80vw'>
        <div className='modal height-fit width-fit'>
          <div className='inputForm-head'>
            <div className='modalHeaderTitle'> </div>
            <div className='closeBtn' onClick={onClickClose} role='presentation'>
              <span className='material-symbols-rounded'>close</span>
            </div>
          </div>
          <div className='inputForm-body' style={{ overflowY: 'hidden', justifyContent: 'center', alignItems: 'center' }}>
            <div className='modal-contents col-w100'>
              <div className='thumbnailWrapper thumbnailModal'>{data.aImgPath ? <img className='thumbnail' src={data.aImgPath} /> : <IoPersonAdd size={50} />}</div>
              {!openModal.thumbnailOnly && (
                <dl className='thumbnailData'>
                  <div className='flex-basic'>
                    <dt className='text_primary'>출역일자</dt>
                    <dd className='text_tertiary'>{data?.aDate}</dd>
                  </div>
                  <div className='flex-basic'>
                    <dt className='text_primary'>출역시간</dt>
                    <dd className='text_tertiary'>{data?.aTime}</dd>
                  </div>
                  <div className='flex-basic'>
                    <dt className='text_primary'>장비명</dt>
                    <dd className='text_tertiary'>{data?.fName}</dd>
                  </div>
                  <div className='flex-basic'>
                    <dt className='text_primary'>출입위치</dt>
                    <dd className='text_tertiary'>{data?.fLocationName}</dd>
                  </div>
                </dl>
              )}
            </div>
          </div>
          <div className='modal-footer flex-end'>
            <BtnGray onClick={onClickClose}>{t('닫기')}</BtnGray>
          </div>
        </div>
      </Modal>
    </ModalBackground>
  );
};

export default Thumbnail;
