/**
 * 작성자 : 홍선영
 * 작성일자 : 2024.04.30
 * 작성내용 : 비디오파일 선택/재생/삭제 컴포넌트
 */

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';

const Root = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  .title {
    min-width: 6rem;
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .fileBoxInput {
    font-size: 0.875rem;
  }
  .play,
  .add {
    user-select: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 3rem;
    border-radius: 0.25rem;
    .material-symbols-rounded {
      font-size: 1.5rem;
      font-variation-settings: 'FILL' 1;
    }
  }
  .play {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
    .material-symbols-rounded {
      color: ${({ theme }: { theme: any }) => theme.filled_blue};
    }
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep_blue};
      &:active {
        outline: 0.125rem solid ${({ theme }: { theme: any }) => theme.filled_blue};
        outline: 0.125rem solid ${({ theme }: { theme: any }) => theme.filled_slate};
      }
    }
  }
  .add {
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    .material-symbols-rounded {
      color: ${({ theme }: { theme: any }) => theme.filled_slate_deep};
    }
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
      background-color: ${({ theme }: { theme: any }) => theme.filled_slate_deep};
      .material-symbols-rounded {
        color: ${({ theme }: { theme: any }) => theme.bw_inverse};
      }
      &:active {
        background-color: ${({ theme }: { theme: any }) => theme.filled_slate};
      }
    }
  }
  .delete {
    user-select: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 3rem;
    border-radius: 0.25rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
    .material-symbols-rounded {
      font-size: 1.5rem;
      font-variation-settings: 'FILL' 1;
    }
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep_red};
      &:active {
        outline: 0.125rem solid ${({ theme }: { theme: any }) => theme.filled_red};
      }
    }
  }
  .fileBoxInput {
    flex-grow: 1;
    background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
    &::placeholder {
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    }
  }
`;

interface IVideoSelect {
  caption: string;
  vType: string;
  handleFileInput: any;
  file: File | null;
  filePath: string;
  fileName: string;
  isEnrolled: boolean;
  onClickDeleteVideo: any;
}

const VideoSelect = ({ caption, vType, handleFileInput, file, fileName, filePath, isEnrolled, onClickDeleteVideo }: IVideoSelect) => {
  const { t } = useTranslation();
  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const onClickPlay = (enrolled: boolean, path: string, fileParam: File | null) => {
    if (enrolled) {
      if (fileParam) {
        // 파일객체가 있을때 (신규 등록된 파일 url)
        const videoUrl = URL.createObjectURL(fileParam);
        window.open(videoUrl, '_blank', 'width=1200, height=600');
      } else {
        // URL이 있을때 (기존 비디오 저장경로 url)
        // const awsPath = 'https://itlogs3server.s3.ap-northeast-2.amazonaws.com/SafeEdu';
        // const extractedString = path.slice(awsPath.length);
        // const url = `/safeedu_video?path=${extractedString}`;
        window.open(path, '_blank', 'width=1200, height=600');
      }
    } else {
      inputFileRef.current?.click();
    }
  };

  return (
    <Root>
      <label
        htmlFor='tsTitle' //
        className={`title ${vType === 'ko' ? 'required' : ''}`}
      >
        {t(caption)}
      </label>
      <label
        htmlFor='filebox1' //
        className={`searchBox flex-center ${isEnrolled ? 'play' : 'add'}`}
        role='presentation'
        onClick={() => onClickPlay(isEnrolled, filePath, file)}
      >
        <span className='material-symbols-rounded'>{isEnrolled ? 'play_arrow' : 'add'}</span>
      </label>

      {!isEnrolled && (
        <input
          className='hiddenFileBox' //
          type='file'
          accept='video/mp4'
          id='filebox'
          name='dIfilename'
          ref={inputFileRef}
          onChange={(type) => handleFileInput(type)}
        />
      )}
      <input
        placeholder={t('50MB 까지 첨부 가능')} //
        className={`fileBoxInput ${isEnrolled ? '' : 'guide'}`}
        style={{ width: '50%', lineHeight: '2' }}
        type='text'
        id='filebox'
        name='dIfilename'
        value={fileName || ''}
        disabled
      />
      {fileName && (
        <label
          htmlFor='del' //
          className={`delete red ${isEnrolled ? '' : 'disabled'}`}
          role='presentation'
          onClick={(type) => onClickDeleteVideo(type)}
        >
          <span className='material-symbols-rounded'>delete</span>
        </label>
      )}
    </Root>
  );
};

export default VideoSelect;
