/**
 * 작성자 : 한영광
 * 날짜 : 2023.12.12
 * 기능 : MQTT 클래스
 */
import * as mqtt from 'mqtt/dist/mqtt';

export class Mqtt {
  mqttClient: any;

  constructor() {
    this.connect();
  }

  connect() {
    if (process.env.REACT_APP_MQTT_URL) {
      this.mqttClient = mqtt.connect(process.env.REACT_APP_MQTT_URL);
    }
  }

  disConnect() {
    this.mqttClient.disConnect();
    this.mqttClient = null;
  }

  end() {
    // console.log('end 실행');
    this.mqttClient.end();
  }

  connected() {
    return this.mqttClient.connected;
  }

  reconnect() {
    this.mqttClient.reconnect();
  }

  getClient() {
    return this.mqttClient;
  }

  subscribe(topic: string) {
    this.mqttClient.subscribe(topic, (e: any) => {
      if (!e) {
        // console.log(`Subscribe: ${topic}`);
      } else {
        // console.log(e);
      }
    });
  }

  unSubscribe(topic: string) {
    this.mqttClient.unsubscribe(topic, (e: any) => {
      if (!e) {
        console.log(`Unsubscribe: ${topic}`);
      } else {
        console.log(e);
      }
    });
  }

  publish(topic: string, msg: string) {
    // console.log(`publish 실행 (${topic})`);
    this.mqttClient.publish(topic, msg);
  }

  on(event: string, cb: any) {
    // console.log(`on 실행 (${event})`);
    this.mqttClient.on(event, cb);
  }
}
