import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 0.5rem;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;

export const Root = styled.div`
  &.hideRoot {
    visibility: hidden;
    position: absolute;
  }
  .cctvWrapper {
    height: 40rem;
  }
  .cctvWrapperHidden {
    height: 0.1rem;
    width: 0.1rem;
  }
  .tui-container {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    margin-left: 0.5rem;
  }
  .tableWrapper {
    flex-grow: 1;
    height: calc(100% - 57px);
    overflow: auto;
  }
  input[type='checkbox'],
  input[type='radio'] {
    margin: 0;
    outline: none;
  }
  .inputFormsWrapper {
    border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    padding: 0.5rem;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    .table {
      height: fit-content;
    }
  }
  .inputFormsWrapper.smallTab {
    flex-direction: row;
    align-items: center;
    padding: 0 0.5rem;
    font-weight: 500;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 0rem !important;
    }
    .tab {
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
      cursor: pointer;
      height: 3.5rem;
      border-bottom: 2px solid transparent;
    }
    .activeTab {
      font-weight: 700;
      border-bottom: 2px solid ${({ theme }: { theme: any }) => theme.selected_primary};
      color: ${({ theme }: { theme: any }) => theme.selected_primary};
    }
  }
  .formTitle {
    padding: 0 0.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .end {
    justify-content: end;
    > button {
      margin-left: 1rem;
    }
  }
  .thead {
    position: sticky;
    top: 0;
    z-index: 3;
  }
  .thead > .tr {
    > div {
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
    }
  }
  .tr {
    > div {
      padding: 0 0.5rem;
      flex-shrink: 0;
      > div {
        width: 100%;
        /* z-index: 1; */
        > div > ul {
          display: flex;
          justify-content: center;
          > li {
            width: 100% !important;
            height: 2.25rem !important;
            span {
              width: 100%;
            }
          }
        }
      }
      button {
        width: 3.5rem;
        padding: 0;
        /* padding: 0 0.5rem; */
        font-size: 0.875rem;
      }
    }
    > div:nth-child(1),
    > div:nth-child(3) {
      z-index: 2;
    }
    input[type='text'] {
      height: 2rem;
      /* border-radius: 0; */
      /* outline: none; */
      font-size: 0.875rem;
    }
  }
  .tbody > div {
    // background-color: ${({ theme }: { theme: any }) => theme.tonal};
  }
  .grid {
    display: grid;
    margin-bottom: 1rem;
    align-items: center;
    min-height: 3.4rem;
  }

  .grid100,
  .grid100T,
  .grid60,
  .grid50,
  .grid30 {
    > div > div {
      display: grid;
      grid-template-columns: 9rem 1fr;
      align-items: center;
    }
  }

  .grid100T {
    grid-template-columns: 9rem 1fr;
  }

  .grid50,
  .grid60 {
    grid-template-columns: 1fr 1fr;
  }

  .grid50 {
    grid-gap: 1rem;
  }

  .grid30 {
    grid-template-columns: 1.5fr 0.7fr 0.8fr;
  }

  .plainText {
    grid-template-columns: 9rem 1fr;

    > :last-child {
      display: block;
    }
  }

  .marginBottomNone {
    margin-bottom: 0;
  }
  .rtspCctvViewWrapper {
    flex-grow: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 40rem;
    > .cctvWrapper {
      height: inherit;
      > div {
        height: inherit;
        canvas {
          position: inherit;
          width: 100%;
          height: inherit;
          border-radius: 0.5rem;
          overflow: hidden;
        }
      }
    }
  }
  .webSdkCctvViewWrapper {
    /* background-color: black; */
    flex-grow: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    canvas {
      width: 100%;
      height: auto;
      border-radius: 0.5rem;
      overflow: hidden;
    }
  }

  .fit-width {
    width: fit-content !important;
  }
`;

interface ICCTVInputFormStyle {
  width: string;
}

export const SubRoot = styled.div<ICCTVInputFormStyle>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  overflow: auto;
  justify-content: flex-start;
  > div {
    width: max-content;
  }

  .inputForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    width: 100%;
    button {
      height: 2.5rem;
      font-size: 0.75rem;
      flex-shrink: 0;
      padding: 0 0.75rem;
      border-radius: 0.25rem;
    }
    button.gray {
      font-weight: 500;
      color: ${({ theme }: { theme: any }) => theme.color.zinc_200};
    }
    label {
      width: 6rem;
      font-weight: 500;
      flex-shrink: 0;
      font-size: 0.875rem;
    }
    ul > li {
      width: inherit !important;
      max-width: inherit !important;
    }
    .inputForm-group-1280 {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (min-width: 1280px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
      }
    }
    .inputForm-group-1536 {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (min-width: 1536px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
      }
    }
    .inputForm-group-1536.withBtn {
      flex-direction: row;
      gap: 0.5rem;
      button {
        transition: none;
      }
      @media (min-width: 1536px) {
        gap: 2rem;
        button {
          margin-left: 0;
          width: fit-content;
        }
      }
    }
    .inputForm-group-1536.withBtn.inputColumnFour {
      input {
        padding: 0;
      }
    }
    .inputForm-row.labelInInput {
      display: flex;
      gap: 0.5rem;
      flex-grow: 1;
      > div {
        flex-grow: 1;
      }
    }
    .inputForm-row.labelOutInput,
    .inputForm-row.labelInInput > div > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      input {
        flex-shrink: 1;
        height: 2.5rem;
        font-size: 0.875rem;
        padding: 0 0.5rem;
        &:disabled {
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          color: ${({ theme }: { theme: any }) => theme.text_disabled};
        }
      }
      > div.viewOnly {
        height: 2.5rem;
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_primary};
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 0.25rem;
        padding: 0 0.75rem;
        display: flex;
        align-items: center;
      }
      > div {
        flex-grow: 1;
        > div > div > ul {
          height: 2.5rem;
          li {
            display: flex;
            width: 100%;
            max-width: 100%;
            span {
              flex-grow: 1;
              width: 4rem;
            }
          }
        }
      }
    }
    .detailInfo-group {
      margin: 1rem 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      @media (min-width: 1536px) {
        grid-template-columns: repeat(3, 1fr);
      }
      .flex-col.detailInfo {
        flex-grow: 1;
        width: 100%;
        div {
          height: 2.5rem;
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          border-radius: 0.25rem;
          display: flex;
          align-items: center;
          padding: 0 0.5rem;
          font-size: 0.875rem;
        }
      }
    }
  }
  input {
    :disabled {
      background-color: #eeeeee;
    }
  }
  .required {
    &::after {
      content: '*';
      padding: 0 0.2rem;
      color: red;
    }
  }
  @media screen and (max-width: 1023px) {
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: fit-content;
    overflow: none;
  }
`;
