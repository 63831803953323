import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import SelectBox from '../../../components/SelectBox';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Root = styled.div`
  max-width: 24rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  .labelName {
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    font-weight: 600;
  }
`;

interface Props {
  options: Record<string, string>[];
  dataKey: string;
  defaultOption: string;
  setSelectState: Dispatch<SetStateAction<any>>;
}

const SelectSettings = ({ options, dataKey, defaultOption, setSelectState }: Props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({ [dataKey]: defaultOption, type: dataKey });

  useEffect(() => {
    setSelectState((prev: any) => ({ ...prev, [dataKey]: state.maxAge.toString() }));
  }, [state]);

  return (
    <Root>
      <span className='labelName'>{t('최대 연령')}</span>
      <SelectBox
        options={options} //
        defaultOption={`${defaultOption}세`}
        state={state}
        setState={setState}
        stateKey='maxAge'
        dropDownWidth='fit-content'
      />
    </Root>
  );
};

export default SelectSettings;
