import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { INumbers } from 'customTypes';
import { userState } from '../../atoms';
import { ERROR } from '../../_constants';
import { ModalBackground, Modal } from '../../assets/styles/Modal';
import { applyBorderStyle } from '../../utils/applyBorderStyle';
import { phoneNumRegex, emailRegex, passwordOnlyRegex, passwordRegex, telNumRegex } from '../../utils/checkRegex';
import Input from '../Input';
import IpInput from '../IpInput';
import NumbersInput from '../NumbersInput';
import { BtnBlue, BtnGray, BtnGhost } from '../Button';
import { logPost } from '../../services/log';
import { registerIp, registerPwd } from '../../services/register';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../utils/checkMobile';
import { apiGet, apiPatch } from '../../services/_common';

interface IPasswordState {
  password: string;
  passwordConfirm: string;
}

const UserInfoSettingModal = ({ openModal, setOpenModal, onClickClose, closeAside }: any) => {
  const { t } = useTranslation();
  const accessToken = Cookies.get('prosafeToken');
  const [userInfo, setUserInfo] = useRecoilState(userState);
  const resetUserInfo = useResetRecoilState(userState);
  const focusRef = useRef<HTMLInputElement>(null);
  const userNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const [signupInfo, setSignupInfo] = useState({
    userId: '',
    userName: '',
    hpNum: '',
    telNum: '',
    eMail: '',
    ipInfo: '',
    userPwd: '',
  });
  const [hpNumState, sethpNumState] = useState<INumbers>({ num1: '010', num2: '', num3: '' });
  const [telNumState, setTelNumState] = useState<INumbers>({ num1: '', num2: '', num3: '' });
  const [ipInfoState, setIpInfoState] = useState<INumbers>({ num1: '', num2: '', num3: '', num4: '' });
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [pwAlert, setPwAlert] = useState(false);
  const [newPassword, setNewPassword] = useState<IPasswordState>({
    password: '',
    passwordConfirm: '',
  });
  const [isSettingPassword, setIsSettingPassword] = useState(false); // 패스워드 변경여부
  const telNumLengthState = { num1: 3, num2: 4, num3: 4 }; // 전화번호 입력 길이
  const hpNumLengthState = { num1: 3, num2: 4, num3: 4 }; // 휴대전화 입력 길이
  const [telNumLastInput, setTelNumLastInput] = useState(false);
  const [hpNumLastInput, setHpNumLastInput] = useState(false);

  useEffect(() => {
    if (userInfo.gCd.substring(0, 1) === 'M') {
      getMasterUserInfoAPI();
    }
    if (userInfo.gCd.substring(0, 1) === 'H') {
      getHeadUserInfoAPI();
    }
    if (userInfo.gCd.substring(0, 1) === 'S') {
      getSiteUserInfoAPI();
    }
    if (userInfo.gCd.substring(0, 1) === 'A') {
      getAgentUserInfoAPI();
    }
  }, [userInfo.gCd]);

  // 마스터유저 조회 API
  const getMasterUserInfoAPI = async () => {
    const res = await apiGet({ path: '/user/manager' });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const findUser = data.userList.find((el: any) => el.userId === userInfo.userId);
      if (findUser) {
        setSignupInfo((prev) => ({ ...prev, ...findUser }));
        if (findUser.hpNum) {
          const split = findUser.hpNum.split('-');
          sethpNumState({ num1: split[0], num2: split[1], num3: split[2] });
        }
        if (findUser.telNum) {
          const split = findUser.telNum.split('-');
          setTelNumState({ num1: split[0], num2: split[1], num3: split[2] });
        }
        if (findUser.ipInfo) {
          const split = findUser.ipInfo.split('.');
          setIpInfoState({ num1: split[0], num2: split[1], num3: split[2], num4: split[3] });
        }
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 본사유저 조회 API
  const getHeadUserInfoAPI = async () => {
    const res = await apiGet({ path: '/user/head' });
    const { data, statusCode } = res.data;

    if (statusCode === 200) {
      const findUser = data.userList.find((el: any) => el.userId === userInfo.userId);
      if (findUser) {
        setSignupInfo((prev) => ({ ...prev, ...findUser }));
        if (findUser.hpNum) {
          const split = findUser.hpNum.split('-');
          sethpNumState({ num1: split[0], num2: split[1], num3: split[2] });
        }
        if (findUser.telNum) {
          const split = findUser.telNum.split('-');
          setTelNumState({ num1: split[0], num2: split[1], num3: split[2] });
        }
        if (findUser.ipInfo) {
          const split = findUser.ipInfo.split('.');
          setIpInfoState({ num1: split[0], num2: split[1], num3: split[2], num4: split[3] });
        }
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 현장유저 조회 API
  const getSiteUserInfoAPI = async () => {
    const res = await apiGet({ path: '/user/site' });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const findUser = data.userList.find((el: any) => el.userId === userInfo.userId);
      if (findUser) {
        setSignupInfo((prev) => ({ ...prev, ...findUser }));
        if (findUser.hpNum) {
          const split = findUser.hpNum.split('-');
          sethpNumState({ num1: split[0], num2: split[1], num3: split[2] });
        }
        if (findUser.telNum) {
          const split = findUser.telNum.split('-');
          setTelNumState({ num1: split[0], num2: split[1], num3: split[2] });
        }
        if (findUser.ipInfo) {
          const split = findUser.ipInfo.split('.');
          setIpInfoState({ num1: split[0], num2: split[1], num3: split[2], num4: split[3] });
        }
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 영업유저 조회 API
  const getAgentUserInfoAPI = async () => {
    const res = await apiGet({ path: '/user/agent' });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const findUser = data.userList.find((el: any) => el.userId === userInfo.userId);
      if (findUser) {
        setSignupInfo((prev) => ({ ...prev, ...findUser }));
        if (findUser.hpNum) {
          const split = findUser.hpNum.split('-');
          sethpNumState({ num1: split[0], num2: split[1], num3: split[2] });
        }
        if (findUser.telNum) {
          const split = findUser.telNum.split('-');
          setTelNumState({ num1: split[0], num2: split[1], num3: split[2] });
        }
        if (findUser.ipInfo) {
          const split = findUser.ipInfo.split('.');
          setIpInfoState({ num1: split[0], num2: split[1], num3: split[2], num4: split[3] });
        }
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  const onChangeuserName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setSignupInfo((prev) => ({ ...prev, [name]: value?.trim() }));
  };

  // 패스워드 input에서 포커스 아웃되었을 때 비밀번호정규식 확인문구
  const onBlur = () => {
    const { isPasswordRegexPass } = passwordOnlyRegex(newPassword?.password);
    if (!isPasswordRegexPass) setPwAlert(true);
    else setPwAlert(false);
  };

  // 패스워드확인 input에서 포커스 아웃되었을 때 비밀번호정규식 확인문구
  const onBlurPasswordConfirm = () => {
    const { isPasswordRegexPass } = passwordOnlyRegex(newPassword?.passwordConfirm);
    if (!isPasswordRegexPass) setPwAlert(true);
    else setPwAlert(false);
  };

  // 현재IP버튼 API호출 후 ip setState
  const onClickGetCurrentIp = async () => {
    const res = await registerIp();
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const ipSplit = data.split('.');
      setIpInfoState({ num1: ipSplit[0], num2: ipSplit[1], num3: ipSplit[2], num4: ipSplit[3] });
      setSignupInfo((prev) => ({ ...prev, ipInfo: data }));
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 관리유저 수정 API
  const patchManagerUserInfoAPI = async (reqData: any) => {
    const { delYn, eDate, loginerrorCount, wDate, writer, ...rest } = reqData;
    let req = rest;
    if (isSettingPassword) {
      // 패스워드 변경값이 있는경우, 이미 패스워드기간 유효성 통과시 저장을 했으므로 패스워드값 제외
      /**
       * 수정자 : 한영광
       * 수정일자 : 2023.10.18
       * 수정내용 : userPwd 값이 undefined이 되는 경우 쿼리에서 비밀번호를 undefined로 저장하는 문제가 있어서 공백으로 보내도록 수정함
       */
      // const { userPwd, ...rest2 } = rest;
      const rest2 = { ...rest, userPwd: '' };
      req = rest2;
    }
    const res = await apiPatch({ path: '/user/manager', contentType: 'application/json', req });
    const { statusCode, message } = res.data;
    if (statusCode === 200) {
      setOpenModal((prev: any) => ({ ...prev, status: false }));
      if (isMobile()) {
        closeAside();
      }
      toast.success(t(message));
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '사용자정보 수정',
        action: '사용자정보 수정 성공',
        etc: ``,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 본사유저 수정 API
  const patchHeadUserInfoAPI = async (reqData: any) => {
    const { delYn, eDate, wDate, writer, gName, hName, ip, loginDate, pwdChangeDate, ...rest } = reqData;
    let req = rest;
    if (isSettingPassword) {
      // 패스워드 변경값이 있는경우, 이미 패스워드기간 유효성 통과시 저장을 했으므로 패스워드값 ''으로 전송
      const rest2 = { ...rest, userPwd: '' };
      req = rest2;
    }
    const res = await apiPatch({ path: '/user/head', contentType: 'application/json', req });
    const { statusCode, message } = res.data;
    if (statusCode === 200) {
      setOpenModal((prev: any) => ({ ...prev, status: false }));
      if (isMobile()) {
        closeAside();
      }
      toast.success(t(message));
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '사용자정보 수정',
        action: '사용자정보 수정 성공',
        etc: ``,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 현장유저 수정 API
  const patchSiteUserInfoAPI = async (reqData: any) => {
    const { delYn, eDate, wDate, writer, gName, hName, sName, sjName, ...rest } = reqData;
    let req = rest;
    if (isSettingPassword) {
      // 패스워드 변경값이 있는경우, 이미 패스워드기간 유효성 통과시 저장을 했으므로 패스워드값 ''으로 전송
      const rest2 = { ...rest, userPwd: '' };
      req = rest2;
    }
    const res = await apiPatch({ path: '/user/site', contentType: 'application/json', req });
    const { statusCode, message } = res.data;
    if (statusCode === 200) {
      setOpenModal((prev: any) => ({ ...prev, status: false }));
      if (isMobile()) {
        closeAside();
      }
      toast.success(t(message));
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '사용자정보 수정',
        action: '사용자정보 수정 성공',
        etc: ``,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 영업유저 수정 API
  const patchAgentUserInfoAPI = async (reqData: any) => {
    const { delYn, eDate, loginerrorCount, wDate, writer, gName, hName, pwdCheckyn, approvalYn, ...rest } = reqData;
    let req = rest;
    if (isSettingPassword) {
      // 패스워드 변경값이 있는경우, 이미 패스워드기간 유효성 통과시 저장을 했으므로 패스워드값 ''으로 전송
      const rest2 = { ...rest, userPwd: '' };
      req = rest2;
    }
    const res = await apiPatch({ path: '/user/agent', contentType: 'application/json', req });
    const { statusCode, message } = res.data;
    if (statusCode === 200) {
      setOpenModal((prev: any) => ({ ...prev, status: false }));
      if (isMobile()) {
        closeAside();
      }
      toast.success(t(message));
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '사용자정보 수정',
        action: '사용자정보 수정 성공',
        etc: ``,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 회원정보수정 API 호출
  const updateUserInfoAPI = async () => {
    const mergedHpNumNumber = `${hpNumState.num1}-${hpNumState.num2}-${hpNumState.num3}`;
    const mergedTelNumber = telNumState.num1 === '' && telNumState.num2 === '' && telNumState.num3 === '' ? '' : `${telNumState.num1}-${telNumState.num2}-${telNumState.num3}`;
    const mergedIpNumber =
      ipInfoState.num1 === '' && ipInfoState.num2 === '' && ipInfoState.num3 === '' && ipInfoState.num4 === '' ? '' : `${ipInfoState.num1}.${ipInfoState.num2}.${ipInfoState.num3}.${ipInfoState.num4}`;

    const reqData = { ...signupInfo, telNum: mergedTelNumber, hpNum: mergedHpNumNumber, ipInfo: mergedIpNumber, userPwd: isSettingPassword ? newPassword.password : signupInfo.userPwd };
    if (userInfo.gCd.substring(0, 1) === 'M') {
      patchManagerUserInfoAPI(reqData);
    }
    if (userInfo.gCd.substring(0, 1) === 'H') {
      patchHeadUserInfoAPI(reqData);
    }
    if (userInfo.gCd.substring(0, 1) === 'S') {
      patchSiteUserInfoAPI(reqData);
    }
    if (userInfo.gCd.substring(0, 1) === 'A') {
      patchAgentUserInfoAPI(reqData);
    }
  };

  // 회원가입버튼 클릭
  const onClickSave = async () => {
    setIsSaveClicked(true);
    const mergedHpNumNumber = `${hpNumState.num1}-${hpNumState.num2}-${hpNumState.num3}`;
    const mergedTelNumber = telNumState.num1 === '' && telNumState.num2 === '' && telNumState.num3 === '' ? '' : `${telNumState.num1}-${telNumState.num2}-${telNumState.num3}`;

    const { password, passwordConfirm } = newPassword;
    const { isPasswordRegexPass, passwordRegexAlertMsg } = passwordRegex(password, passwordConfirm);
    const { isPhoneNumRegexPass, phoneNumRegexAlertMsg } = phoneNumRegex(mergedHpNumNumber);
    const { isTelNumRegexPass, telNumRegexAlertMsg } = telNumRegex(mergedTelNumber);
    const { isEmailRegexPass, emailRegexAlertMsg } = emailRegex(signupInfo.eMail);

    if (isSettingPassword && !isPasswordRegexPass) {
      focusRef.current?.focus();
      return toast.warning(t(passwordRegexAlertMsg));
    }
    if (isSettingPassword && !(await isNewPwd())) return toast.warning(t('사용할 수 없는 비밀번호입니다.'));
    if (isSettingPassword && signupInfo.userPwd === password) return toast.warning(t('이미 사용중인 비밀번호입니다.'));
    if (!signupInfo.userName?.trim()) {
      userNameRef.current?.focus();
      return toast.warning(t('성명을 입력하세요'));
    }
    if (!isEmailRegexPass) {
      emailRef.current?.focus();
      return toast.warning(t(emailRegexAlertMsg));
    }
    if (!isPhoneNumRegexPass) return toast.warning(t(phoneNumRegexAlertMsg));
    if (mergedTelNumber !== '--' && !isTelNumRegexPass) return toast.warning(t(telNumRegexAlertMsg));
    if (
      !(ipInfoState.num1 === '' && ipInfoState.num2 === '' && ipInfoState.num3 === '' && ipInfoState.num4 === '') &&
      (ipInfoState.num1 === '' || ipInfoState.num2 === '' || ipInfoState.num3 === '' || ipInfoState.num4 === '')
    ) {
      return toast.warning(t('접속 IP를 확인하세요'));
    }

    return updateUserInfoAPI();
  };

  // 사용자 변경 비밀번호가 비밀번호변경기간 안에 이미 사용했던 비밀번호인지 체크
  const isNewPwd = async () => {
    if (accessToken) {
      const res = await registerPwd(userInfo.userId, newPassword?.password, accessToken);
      const { statusCode, message } = res.data;
      if (statusCode !== 200) return false;
      return true;
    }
    return false;
  };

  // 비밀번호 변경버튼 클릭. 비밀번호 변경상태가 true일 때 비밀번호 값 setState
  const onClickPasswordChange = () => {
    const { password, passwordConfirm } = newPassword;

    // 비밀번호 입풋창이 열려있는 상태에서 변경버튼 클릭한 경우
    if (isSettingPassword) {
      setNewPassword({ password: '', passwordConfirm: '' });
      setIsSaveClicked(false);
    } else {
      const { isPasswordRegexPass, passwordRegexAlertMsg } = passwordRegex(password, passwordConfirm);
      if (password === '' && passwordConfirm === '') return setIsSettingPassword((prev: boolean) => !prev); // 아무것도 입력하지 않았을때 인풋닫힘
      if (!isPasswordRegexPass) return toast.warning(t(passwordRegexAlertMsg)); // 비밀번호 정규식확인
      setSignupInfo((prev: any) => ({ ...prev, password: newPassword })); // 정규식 통과시 setState
    }

    return setIsSettingPassword((prev: boolean) => !prev);
  };

  const onClickLogout = () => {
    resetUserInfo();
    Cookies.remove('prosafeToken');
    Cookies.remove('refreshToken');
    window.location.replace('/');
  };

  return (
    <ModalBackground onClick={onClickClose} role='presentation'>
      <Modal>
        <div
          className='modal userInfoSetting'
          role='presentation'
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className='inputForm-head'>
            <h5 className='medium'>{t(openModal?.title)}</h5>
            {onClickClose && (
              <div className='closeBtn' onClick={onClickClose} role='presentation'>
                <span className='material-symbols-rounded'>close</span>
              </div>
            )}
          </div>
          <div className='inputForm-body'>
            <div id='join' className='modal-contents'>
              <div className='inputForm-row'>
                <div className='inputForm-col col-w50'>
                  <label htmlFor='userId'>{t('아이디')}</label>
                  <input type='text' id='userId' name='userId' value={signupInfo?.userId} disabled maxLength={50} />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col col-w100'>
                  <label className='required' htmlFor='userId'>
                    {t('성명')}
                  </label>
                  <div className='flex-between iscontents-m'>
                    <input
                      ref={userNameRef}
                      type='text'
                      id='userName'
                      name='userName'
                      onChange={onChangeuserName}
                      value={signupInfo?.userName}
                      style={isSaveClicked ? applyBorderStyle(signupInfo?.userName, 'red', 'userName') : undefined}
                      maxLength={50}
                    />
                    <BtnGray className='btn-small' onClick={onClickPasswordChange}>
                      {t('비밀번호 변경')} {isSettingPassword && t('취소')}
                    </BtnGray>
                  </div>
                </div>
              </div>
              {isSettingPassword && (
                <>
                  <div className='inputForm-row'>
                    <div className='inputForm-col col-w50'>
                      <Input
                        name='password'
                        label={t('신규 비밀번호')}
                        type='password'
                        state={newPassword}
                        setState={setNewPassword}
                        placeholder={t('대/소문자, 숫자, 특문 중 3종류 포함')}
                        blur={onBlur}
                        maxLength={20}
                        inputRef={focusRef}
                        getBorderStyle={isSaveClicked ? applyBorderStyle(newPassword.password, 'red', 'password') : undefined}
                      />
                    </div>
                    <div className='inputForm-col col-w50'>
                      <Input
                        name='passwordConfirm'
                        label={t('신규 비밀번호 재입력')}
                        type='password'
                        state={newPassword}
                        setState={setNewPassword}
                        placeholder={t('비밀번호 확인')}
                        blur={onBlurPasswordConfirm}
                        maxLength={20}
                        getBorderStyle={isSaveClicked ? applyBorderStyle(newPassword.passwordConfirm, 'red', 'passwordConfirm') : undefined}
                      />
                    </div>
                  </div>
                  <div>
                    <small className='red text-left'>
                      {t('현재 입력 글자수')} : {newPassword.password?.length}
                    </small>
                    <small className='red text-left'>( {t('※ 최소 8자리 이상 : 영어 대문자, 소문자, 숫자, 특수문자 중 3종류 조합')} )</small>
                  </div>
                </>
              )}
              <div className='inputForm-row'>
                <div className='inputForm-col col-w100'>
                  <div className='flex-basic'>
                    <label htmlFor='email' className='required'>
                      {t('이메일')}
                    </label>
                    <small className='text-left text_secondary'>( {t('본인 확인용 이메일이 발송됩니다. 정확히 입력해 주세요.')} )</small>
                  </div>
                  <Input
                    inputRef={emailRef}
                    className='required'
                    label=''
                    type='text'
                    id='eMail'
                    name='eMail'
                    state={signupInfo}
                    setState={setSignupInfo}
                    getBorderStyle={isSaveClicked ? applyBorderStyle(signupInfo?.eMail, 'red', 'eMail') : undefined}
                  />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col col-w100'>
                  <label htmlFor='hpNum' className='required'>
                    {t('휴대폰번호')}
                  </label>
                  <NumbersInput state={hpNumState} setState={sethpNumState} lengthState={hpNumLengthState} setIsLastInputCompleted={setHpNumLastInput} isSaveClicked={isSaveClicked} />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col col-w100'>
                  <label htmlFor='hpNum'>{t('전화번호')}</label>
                  <NumbersInput
                    state={telNumState}
                    setState={setTelNumState}
                    lengthState={telNumLengthState}
                    setIsLastInputCompleted={setTelNumLastInput}
                    isFirstInputFocused={hpNumLastInput}
                    isSaveClicked={
                      isSaveClicked &&
                      !(telNumState.num1 === '' && telNumState.num2 === '' && telNumState.num3 === '') &&
                      (telNumState.num1 === '' || telNumState.num2 === '' || telNumState.num3 === '')
                    }
                  />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col col-w100'>
                  <label htmlFor='hpNum'>{t('접속 IP')}</label>
                  <div className='flex-between'>
                    <IpInput
                      stateName='ipInfo'
                      separator='.'
                      state={ipInfoState}
                      setState={setIpInfoState}
                      setInfoState={setSignupInfo}
                      isFirstInputFocused={telNumLastInput}
                      isSaveClicked={
                        isSaveClicked &&
                        !(ipInfoState.num1 === '' && ipInfoState.num2 === '' && ipInfoState.num3 === '' && ipInfoState.num4 === '') &&
                        (ipInfoState.num1 === '' || ipInfoState.num2 === '' || ipInfoState.num3 === '' || ipInfoState.num4 === '')
                      }
                    />
                    <BtnGray className='btn-small' onClick={onClickGetCurrentIp}>
                      {t('현재 PC IP 적용')}
                    </BtnGray>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer flex-between'>
            <div>
              <BtnGhost onClick={onClickLogout}>
                <span className='material-symbols-rounded'>logout </span>
                {t('로그아웃')}
              </BtnGhost>
            </div>
            <div className='flex-end'>
              {/* <BtnGhost onClick={onClickClose}>취소</BtnGhost> */}
              <BtnBlue onClick={onClickSave}>{t('저장')}</BtnBlue>
            </div>
          </div>
        </div>
      </Modal>
    </ModalBackground>
  );
};

export default UserInfoSettingModal;
