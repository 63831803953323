/**
 * 작성자 : 한영광
 * 날짜 : 2023.07.17
 * 기능 : API 요청 함수
 */

import axios from 'axios';
import Cookies from 'js-cookie';
import { registerRefresh } from './register';

export const logGet = async (data: any) => {
  const accessToken = Cookies.get('prosafeToken');
  const config = {
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
      authorization: `Bearer ${accessToken}`,
    },
    params: { ...data },
  };
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/log`, config)
    .then((res: any) => {
      if (res.status === 210) {
        alert('관리자가 설정을 변경하여 리로드됩니다.');
        window.location.replace('/?oeditYn=Y');
      }
      return res;
    })
    .catch(async (e: any) => {
      if (e.response.status === 401) {
        if (e.response.data.statusCode === 4018) {
          Cookies.remove('prosafeToken');
          Cookies.remove('refreshToken');
          window.location.replace('/?auth=fail');
          return e.response;
        }
        const res2 = await registerRefresh();
        if (res2.status === 200) {
          const res3: any = await logGet(data);
          return res3;
        }
      }
      return e.response;
    });
};

/**
 * 작성자 : 한영광
 * 날짜 : 2023.08.29
 * 기능 : 사용자 로그를 저장하는 API 요청 함수
 */
interface LogPost {
  hCd: string;
  sCd: string;
  userId: string;
  menu: string;
  action: string;
  etc: string;
}
export const logPost = async (data: LogPost) => {
  const accessToken = Cookies.get('prosafeToken');
  const config = {
    headers: {
      'Content-type': 'application/json',
      authorization: `Bearer ${accessToken}`,
    },
  };
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}/log`, data, config)
    .then((res: any) => {
      if (res.status === 210) {
        alert('관리자가 설정을 변경하여 리로드됩니다.');
        window.location.replace('/?oeditYn=Y');
      }
      return res;
    })
    .catch(async (e: any) => {
      if (e.response.status === 401) {
        if (e.response.data.statusCode === 4018) {
          Cookies.remove('prosafeToken');
          Cookies.remove('refreshToken');
          window.location.replace('/?auth=fail');
          return e.response;
        }
        const res2 = await registerRefresh();
        if (res2.status === 200) {
          const res3: any = await logPost(data);
          return res3;
        }
      }
      return e.response;
    });
};
