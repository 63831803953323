/**
 * 작성자 : 홍선영
 * 날짜 : 2023.09.04
 * 기능 : 상황판
 */

import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { v1 } from 'uuid';
import { useQuery } from '@tanstack/react-query';
import { PulseLoader } from 'react-spinners';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useRecoilValue } from 'recoil';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { IoCloseCircleSharp } from 'react-icons/io5';
import dayjs from 'dayjs';
import Slider from 'react-slick';
import styled from 'styled-components';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import 'dayjs/locale/ko'; // Import Korean locale

import { bScreenMode, userState } from '../atoms';
import { DASHBOARD_GUBUN } from '../_constants';
import { IComCd, IComCdList, IDashboard, IDashboardM } from 'customTypes';
import { BtnBlue } from './Button';
import DoughnutChart from './DoughnutChart';
import { arraySortByAscdOrder } from '../utils/arraySortByAscdOrder';
import { formatDateYMD, todayYYYYMMDD } from '../utils/formatDate';
import { getPreviousDays } from '../utils/getPrevWeeks';
import { getAgeFromCertainDate } from '../utils/getAge';
import { weather3DaysGet, weatherDayGet, weatherWeekGet } from '../services/weather';
import { ReactComponent as SnowFlower } from '../assets/images/icons/snow-flower.svg';
import { ReactComponent as SnowIce30 } from '../assets/images/icons/snow-ice30.svg';
import { ReactComponent as SnowBall } from '../assets/images/icons/snow-ball.svg';
import { ReactComponent as Sun } from '../assets/images/icons/sun.svg';
import { ReactComponent as SunCloud } from '../assets/images/icons/sunCloud.svg';
import { ReactComponent as Blur } from '../assets/images/icons/blur.svg';
import { ReactComponent as Umbrella } from '../assets/images/icons/umbrella.svg';
import sun from '../assets/images/icons/sun.png';
import cloud from '../assets/images/icons/cloud.png';
import blur from '../assets/images/icons/blur.png';
import rain from '../assets/images/icons/rain.png';
import snow from '../assets/images/icons/snow.png';
import littleCloud from '../assets/images/icons/little_cloud.png';
import TextEditor from './TextEditor';
import Itlog from '../assets/images/logo/itlog.png';
import { InputTable } from '../assets/styles/InputTable';
import { useTranslation } from 'react-i18next';
import i18n from '../translation/i18n';
import { apiGet } from '../services/_common';
import MGasInfo from './dashboard/MGasInfo';
import { WorkerLocation } from './dashboard/WorkerLocation';
import { WorkerAttend } from './dashboard/WorkerAttend';
import { EquipAttend } from './dashboard/EquipAttend';
import TunnelAreaInfo from './dashboard/TunnelAreaInfo';
import TunnelTotalInfo from './dashboard/TunnelTotalInfo';
import TunnelAreaInfoWithTatCd from './dashboard/TunnelAreaInfoWithTatCd';
import { TunnelExcavationProcess } from './dashboard/TunnelExcavationProcess';
import TunnelExcavationDoughnut from './dashboard/TunnelExcavationDoughnut';
import TunnelWorkAndEquipment from './dashboard/TunnelWorkAndEquipment';
import TunnelTotalInfoMini from './dashboard/TunnelTotalInfoMini';
import DateDashboard from './dashboard/Date';
import MGasInfoWithTatCd from './dashboard/MGasInfoWithTatCd';
import { useThemeLogo } from '../services/getInitialColorMode';
import TunnelTotalTable from './dashboard/TunnelTotalTable';
import TunnelTotalTableWithTatCd from './dashboard/TunnelTotalTableWithTatCd';
import { WorkerAttendMultiSite } from './dashboard/WorkerAttendMultiSite';
import { WorkerAttendMultiSiteExit } from './dashboard/WorkerAttendMultiSiteExit';
import WeatherWide from './dashboard/WeatherWide';
import { WorkerAttendCountMultiSiteExit } from './dashboard/WorkerAttendCountMultiSiteExit';
import { SiteJoinAttendInfo } from './dashboard/SiteJoinAttendInfo';

dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.locale('ko'); // Set the locale to Korean

interface IProps {
  isViewMode: boolean; // 세팅화면인지/뷰화면인지 여부
  dGubun: string; // 현장용 일반 상황판인지, 구역별 상황판인지 타입
  tatCd?: string; // 구역코드
}

interface RootStyleProps extends IProps {
  maxRowCount: number;
}

interface DraggableCellStyleProps {
  isDragging: boolean;
}

interface DroppableCellStyleProps extends IProps {
  isOver: boolean;
  canDrop: boolean;
  cellHeight: number;
  cellWidth: number;
}

const Root = styled.div<RootStyleProps>`
  height: 100%;
  .dragNDropZone {
    display: flex;
    justify-content: space-between;
    align-items: start;
    height: 100%;
    .dragZone {
      display: ${(props) => (props.isViewMode ? `none !important` : `flex`)};
      flex: 0.15;
      display: flex;
      flex-direction: column;
      .dragCell {
        margin: 0.5rem 0;
      }
    }

    .dropZone {
      height: 100%;
      flex: ${(props) => (props.isViewMode ? `1` : `0.85`)};
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(8, 1fr);
      gap: 1rem;

      > div {
        margin: 0;
        background-color: ${({ theme }: { theme: any }) => theme.board};
        overflow: hidden;
      }
    }
  }

  @media screen and (max-width: 1023px) {
    .dragNDropZone .dropZone {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const DraggableCellStyle = styled.div<DraggableCellStyleProps>`
  // min-width: min-content;
  background-color: #5ac8fa;
  color: #fff;
  padding: 0.5rem;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 1rem;
  cursor: pointer;
  opacity: ${(props) => (props.isDragging ? '0.5' : '1')};
  height: 90%;
`;

const DroppableCellStyle = styled.div<DroppableCellStyleProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 100px;
  background-color: ${(props) => (props.isOver && props.canDrop ? 'red' : '#f3f6f9')};
  height: 100%;
  border-radius: 5px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.03);
  .dropCell {
    width: 100%;
    height: 100%;
    /* height: calc(${(props) => props.cellHeight * 12.5}% + ${(props) => props.cellHeight - 1}rem); */
    height: 100%;
  }
  .closeIconWrapper {
    display: ${(props) => (props.isViewMode ? `none !important` : `flex`)};
  }
`;

interface DraggableCellProps {
  cell: any;
  draggable: boolean;
}

interface Piece {
  dCd: string;
  name: string;
  width: number;
  height: number;
  isInDropZone: boolean;
  location: { column: number; row: number; mRow?: number };
}

interface DropItem {
  type: string;
  item: Piece;
}

const DraggableCell = ({ cell, draggable }: DraggableCellProps) => {
  const { t } = useTranslation();
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'cell',
    item: cell,
    end: (item: any, monitor: any) => {
      // if (monitor.didDrop() && item.isInGrid && onRemoveFromGrid) {
      //   onRemoveFromGrid(item); // Call the function to remove the piece from the grid
      // }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <DraggableCellStyle ref={drag} isDragging={isDragging}>
      {`${cell.name} (${t('가로')}${cell.width} / ${t('세로')}${cell.height})`}
    </DraggableCellStyle>
  );
};

const DroppableZone = ({ children, onDrop, cell, dragPieces, setDragPieces, dropZoneGrid, setDropZoneGrid, isViewMode, isRefreshTimeExpired, dGubun, tatCd }: any) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'cell',
    drop: (item, monitor) => {
      onDrop(item);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const onClickRemove = (cancelPiece: any) => {
    const copyDragPieces = [...dragPieces];

    // cancelPiece의 id와 일치하는 항목을 드래그 피스 배열에서 찾기
    const index = copyDragPieces.findIndex((piece) => piece.dCd === cancelPiece.dCd);

    // 일치하는 아이템이 있으면 드래그피스 배열 업데이트
    if (index !== -1) {
      // cancelPiece의 속성을 사용하여 새 항목을 생성하고 InDropZone을 false 설정
      const updatedItem = {
        ...copyDragPieces[index],
        ...cancelPiece,
        isInDropZone: false,
      };

      // 업데이트된 아이템으로 드래그피스 배열의 아이템 대치
      copyDragPieces[index] = updatedItem;
      setDragPieces(copyDragPieces); // 드래그피스배열 setState
    }
  };
  return (
    <DroppableCellStyle
      ref={drop}
      canDrop={canDrop}
      isOver={isOver}
      style={{
        gridColumnStart: cell.location.column + 1,
        gridRowStart: cell.location.row + 1,
        gridColumnEnd: cell.location.column + cell.width + 1,
        gridRowEnd: cell.location.row + cell.height + 1,
        backgroundColor: cell.name === '' ? '#fff' : undefined,
      }}
      isViewMode={isViewMode}
      cellHeight={cell.height}
      cellWidth={cell.width}
      dGubun={dGubun}
    >
      <span className='dropCell' style={cell.name === '' ? { display: 'none' } : { display: 'block' }}>
        <Control content={children} isRefreshTimeExpired={isRefreshTimeExpired} tatCd={tatCd} />
      </span>
      <span className='closeIconWrapper' style={cell.name === '' ? { display: 'none' } : { display: 'block' }}>
        <IoCloseCircleSharp className='closeIcon' onClick={() => onClickRemove(cell)} />
      </span>
    </DroppableCellStyle>
  );
};

const SiteDashboard = ({ isViewMode, dGubun, tatCd }: IProps) => {
  const { t } = useTranslation();
  const size = useOutletContext<any>();
  const userInfo = useRecoilValue(userState);
  const [reLocatedDragPieces, setReLocatedDragPieces] = useState<Piece[]>([]); // 위치 수정한 드래그피스 데이터(width 조절시 다시 되돌아가기 위한 state값)
  const [dropZoneGrid, setDropZoneGrid] = useState<any[]>([]);
  const [dropZoneMaxRowCount, setDropZoneMaxRowCount] = useState<number>(0);
  const [dashboardCdList, setDashboardCdList] = useState<IComCd[]>([]);
  const [dashboardSetList, setDashboardSetList] = useState<IDashboard[]>([]);
  const [dashboardMList, setDashboardMList] = useState<Piece[]>([]);
  const [dragPieces, setDragPieces] = useState<Piece[]>([]);
  const D_GUBUN_H = '1'; // 본사용 대시보드 구분 공통코드 목록 (etc1값)
  const D_GUBUN_S = '2'; // 현장용 대시보드 구분 공통코드 목록(etc1값)
  const dGubunCd = dGubun && dGubun;
  const [timer, setTimer] = useState<number>(0); // '분' 기준, 30분마다 대시보드 리로드되게 하는 타이머
  const [isRefreshTimeExpired, setIsRefreshTimeExpired] = useState<boolean>(false);
  const refreshTime = 1000 * 60 * 30;

  useEffect(() => {
    // 매분마다 인터벌 업데이트
    const interval = setInterval(() => {
      // 1분마다 타이머 1씩 플러스
      setTimer((prevTimer) => {
        if (prevTimer >= 30) {
          // 30분에 도달하면 만료여부 true, 타이머 0으로 setState
          setIsRefreshTimeExpired(true);
          return 0;
        }
        return prevTimer + 1;
      });
    }, refreshTime); // 60000ms = 1분

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (isRefreshTimeExpired) setTimer(0);
  }, [isRefreshTimeExpired]);

  const dGubunComCdQuery = useQuery(['dashboardGubunComcdGet', dGubunCd, isRefreshTimeExpired], () => apiGet({ path: '/code/detail', req: { grCd: DASHBOARD_GUBUN } }), {
    enabled: !!dGubunCd && !!DASHBOARD_GUBUN,
  });

  const dashboardMListQuery = useQuery(
    ['dashboardMasterGet', userInfo.hCd, userInfo.sCd, dGubunCd, isRefreshTimeExpired],
    () => apiGet({ path: '/dashboard/master', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, dGubun: dGubunCd } }),
    {
      retry: 3,
      enabled: !!userInfo.hCd && !!userInfo.sCd && !!dGubunCd,
    }
  );

  const dashboardSetListQuery = useQuery(['dashboardListGet', userInfo.hCd, userInfo.sCd, dGubunCd, isRefreshTimeExpired], () => apiGet({ path: '/dashboard' }), {
    retry: 3,
    enabled: !!userInfo.hCd && !!userInfo.sCd && !!dGubunCd,
  });

  useEffect(() => {
    if (dashboardSetListQuery.isSuccess && dashboardSetListQuery.data) {
      if (dashboardSetListQuery.data.data.data) {
        const { DashboardSetList } = dashboardSetListQuery.data.data.data;
        setDashboardSetList(DashboardSetList);
      }
    }
  }, [dashboardSetListQuery.isSuccess, dashboardSetListQuery.isRefetching]);

  useEffect(() => {
    if (dashboardSetList && dashboardMListQuery.isSuccess && dashboardMListQuery.data) {
      if (dashboardMListQuery.data.data.data) {
        const { DashboardMList } = dashboardMListQuery.data.data.data;
        const useYDashboard = dashboardSetList.filter((setListEl: IDashboard) => setListEl.useYn === 'Y');
        const result = useYDashboard.map((setListEl: IDashboard) => {
          const dataItem = DashboardMList.find((mListEl: IDashboardM) => mListEl.dCd === setListEl.dCd);
          return {
            dCd: setListEl.dCd,
            name: setListEl.dName,
            isInDropZone: !!dataItem,
            ...(dataItem ? { location: { column: dataItem.dCol, row: dataItem.dRow } } : { location: { column: -1, row: -1 } }),
            width: setListEl.dCol,
            height: setListEl.dRow,
          };
        });
        setDashboardMList(result);
      }
    }
  }, [dashboardMListQuery.isSuccess, dashboardMListQuery.isRefetching, dashboardSetList]);

  useEffect(() => {
    if (dashboardMList?.length > 0) {
      setDragPieces(dashboardMList);
    }
  }, [dashboardMList]);

  useEffect(() => {
    if (dGubunComCdQuery.isSuccess && dGubunComCdQuery.data) {
      if (dGubunComCdQuery.data.data.data) {
        const { comCdList } = dGubunComCdQuery.data.data.data;
        // 로그인한 유저가 본사/현장 유저인지에 따라 구분목록 세팅
        let newArray = [];
        if (userInfo.gCd.substring(0, 1) === 'S') {
          newArray = comCdList.filter(({ useYn, etc1 }: IComCdList) => useYn === 'Y' && etc1 === D_GUBUN_S).map(({ subCd, cdName, cdSort }: IComCd) => ({ subCd, cdName, cdSort }));
        }
        if (userInfo.gCd.substring(0, 1) === 'H') {
          newArray = comCdList.filter(({ useYn, etc1 }: IComCdList) => useYn === 'Y' && etc1 === D_GUBUN_H).map(({ subCd, cdName, cdSort }: IComCd) => ({ subCd, cdName, cdSort }));
        }
        const sorted = arraySortByAscdOrder(newArray, 'cdSort');
        setDashboardCdList(sorted);
      }
    }
  }, [dGubunComCdQuery.isSuccess, dGubunComCdQuery.isRefetching]);

  useEffect(() => {
    const mergedGrid = dragPieces.map((gridItem: any) => {
      const matchPiece = dragPieces.find((piece) => piece.dCd === gridItem.dCd);
      return matchPiece ? { ...gridItem, ...matchPiece } : gridItem;
    });

    setDropZoneGrid(mergedGrid);
  }, [dragPieces]);

  const onClickSave = () => {};

  const handleDrop = (dropItem: Piece, dropCell: any) => {
    const copyDragPieces = [...dragPieces];

    // dropItem의 id와 일치하는 항목을 드래그 피스 배열에서 찾기
    const index = copyDragPieces.findIndex((piece) => piece.dCd === dropItem.dCd);

    // 일치하는 아이템이 있으면 드래그피스 배열 업데이트
    if (index !== -1) {
      // dropItem의 속성을 사용하여 새 항목을 생성하고 InDropZone을 true로 설정
      const updatedItem = {
        ...copyDragPieces[index],
        ...dropItem,
        isInDropZone: true,
        location: dropCell.location,
      };

      // 업데이트된 아이템으로 드래그피스 배열의 아이템 대치
      copyDragPieces[index] = updatedItem;
      setDragPieces(copyDragPieces); // 드래그피스배열 setState
      setReLocatedDragPieces(copyDragPieces);
    }
  };

  const sortedDragPiecies: Piece[] = dragPieces.sort((a, b) => {
    // 로우 오름차순으로 sorting
    if (a.location.row < b.location.row) return -1;
    if (a.location.row > b.location.row) return 1;

    // 로우값이 같을때, 컬럼 오름차순으로 sorting
    if (a.location.column < b.location.column) return -1;
    if (a.location.column > b.location.column) return 1;

    // 로우값과 컬럼값이 동일할때
    return 0;
  });

  useEffect(() => {
    if (reLocatedDragPieces.length === 0) {
      setReLocatedDragPieces(dragPieces);
    }
  }, [dragPieces]);

  return (
    <Root maxRowCount={dropZoneMaxRowCount} isViewMode={isViewMode} dGubun={dGubunCd}>
      {!isViewMode && (
        <div className='buttonsWrapper flex-end'>
          <BtnBlue type='button' onClick={onClickSave}>
            {t('저장')}
          </BtnBlue>
        </div>
      )}
      <DndProvider backend={HTML5Backend}>
        <div className='dragNDropZone'>
          <div className='dragZone'>{dragPieces.map((el) => !el.isInDropZone && <DraggableCell key={el.dCd} cell={el} draggable />)}</div>
          <div className='dropZone'>
            {dropZoneGrid?.map(
              (el) =>
                el.isInDropZone && (
                  <DroppableZone
                    key={v1()}
                    onDrop={(item: Piece) => handleDrop(item, el)}
                    cell={el}
                    dragPieces={dragPieces}
                    setDragPieces={setDragPieces}
                    dropZoneGrid={dropZoneGrid}
                    setDropZoneGrid={setDropZoneGrid}
                    isViewMode={isViewMode}
                    isRefreshTimeExpired={isRefreshTimeExpired}
                    tatCd={tatCd}
                  >
                    <DraggableCell key={v1()} cell={el} draggable />
                  </DroppableZone>
                )
            )}
          </div>
        </div>
      </DndProvider>
    </Root>
  );
};

export default SiteDashboard;

interface IControl {
  content: any;
  isRefreshTimeExpired: any;
  tatCd?: string;
}

const ControlStyle = styled.div<any>`
  height: inherit;
  position: relative;

  .weatherTodayInfo,
  .weatherWeekInfo {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    > .widget-half-background {
      background-color: #3e97ff;
      position: absolute;
      top: 0;
      border-radius: 0.5rem;
      width: 100%;
      height: 50%;
    }
    > .widget-header {
      width: 100%;
      padding: 0.75rem 1rem;
      z-index: 1;
      background-color: ${({ theme }: { theme: any }) => theme.weather_bg};
      > .widget-title {
        height: 2rem;
        color: white;
        > span {
          font-weight: 600;
          font-size: 1.125rem;
          line-height: 1.2;
        }
      }
      > .widget-subTitle {
        font-size: 0.875rem;
        line-height: 1rem;
        color: rgba(0, 0, 0, 0.7);
      }
    }
    > .widget-body {
      width: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: visible;
      z-index: 1;
      .weatherToday {
        flex: 1 0 30%;
        width: 100%;
        max-height: 10rem;
        -webkit-box-pack: justify;
        justify-content: space-around;
        -webkit-box-align: center;
        align-items: center;
        background-color: ${({ theme }: { theme: any }) => theme.weather_bg};
        .weatherInfo {
          position: absolute;
          padding: 0 1rem;
          > div:nth-child(1) {
            font-weight: 500;
            font-size: 1.125rem;
          }
        }
        .weatherBox {
          height: 6rem;
          border-radius: 8px;
          padding: 0 1rem;
          text-align: center;
          background: #f3f6f9;
          font-size: 0.875rem;
          dt {
            font-weight: 500;
          }
          dl > div {
            display: flex;
            width: inherit;
            > dt,
            > dd {
              flex: 0.5;
            }
            > dt {
              text-align: start;
            }
            > dd {
              text-align: center;
            }
          }
        }
        .weatherBox.text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          > div {
            display: flex;
            justify-content: space-between;
          }
        }
        .weatherAnimationWrapper {
          .container {
            > div {
              svg {
                pointer-events: none;
              }
            }
          }
        }
      }
      .weatherToday.notWeek .weatherInfo {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        z-index: 10;
        > .weatherBox {
          height: auto;
          background-color: transparent;
          padding: 0;
          > div {
            justify-content: flex-start;
            gap: 0.5rem;
            height: 1.25rem;
          }
        }
      }
      .weatherWeek {
        flex: 1 0 50%;
        padding: 0 1rem;
        display: flex;
        z-index: 999;
        background-color: ${({ theme }: { theme: any }) => theme.board};
        > div {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .date {
            > div {
              font-size: 0.875rem;
              font-weight: 600;
            }
            > div:nth-child(1) {
              color: ${({ theme }: { theme: any }) => theme.text_secondary};
            }
            > div:nth-child(2) {
              color: ${({ theme }: { theme: any }) => theme.text_primary};
            }
          }
          .weatherWeekIcon {
            > img {
              width: 2.5rem;
            }
          }
          .weatherWeekTemp {
            > div {
              font-weight: ${({ theme }: { theme: any }) => theme.font_semibold};
              color: ${({ theme }: { theme: any }) => theme.text_secondary};
              font-size: 0.75rem;
            }
          }
        }
      }
      .weatherTodayDetail {
        height: 50%;
        flex: 1 0 50%;
        background-color: ${({ theme }: { theme: any }) => theme.board};
        z-index: 10;
        .weatherBoxGroup {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 0.5rem;
          bottom: 1rem;
          position: absolute;
          width: calc(100% - 2rem);
          left: 50%;
          transform: translate(-50%, 0);
          .weatherBox {
            height: 6rem;
            border-radius: 8px;
            padding: 0 1rem;
            text-align: center;
            background: ${({ theme }: { theme: any }) => theme.tonal};
            font-size: 0.875rem;
            dt,
            > div > dt,
            > div > dd {
              font-weight: 500;
              color: ${({ theme }: { theme: any }) => theme.text_primary};
            }
            dl > div {
              display: flex;
              width: inherit;
              > dt,
              > dd {
                flex: 0.5;
              }
              > dt {
                text-align: start;
              }
              > dd {
                text-align: center;
              }
            }
          }
          .weatherBox.text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            > div {
              display: flex;
              justify-content: space-between;
            }
          }
          .weatherBox.icon {
            display: flex;
            align-items: center;
            justify-content: space-between;
            dt {
              text-align: left;
            }
            > dd {
              position: relative;
              > div:nth-child(1) img {
                height: 4rem;
              }
            }
            .weatherBox-details {
              display: flex;
              flex-direction: column;
              /* gap: 0.5rem; */
              justify-content: center;
              font-size: 0.75rem;
              > div {
                height: 1.25rem;
                font-weight: 600;
                color: ${({ theme }: { theme: any }) => theme.text_secondary};
              }
            }
          }
        }
      }
    }

    .contents {
      height: calc(100% - 4rem);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      > div {
        flex: 0.5;
      }
    }

    .weatherAnimationWrapper {
      width: 100%;
      height: 100%;
      overflow: visible;

      .container {
        width: 100%;
        height: inherit;
        position: relative;

        .svg-container {
          left: 0;
          svg {
            width: 160px;
          }
        }
      }
      .rotating-sun {
        animation: rotation 10s infinite linear;
        position: absolute;
        top: -2.5rem;
        right: 0.5rem;
      }
      .little_cloud {
        position: absolute;
        right: 4%;
        top: -35%;
        &.beside-sun {
          right: 8%;
        }
      }
      .blur {
        position: absolute;
        right: 6%;
        top: -35%;
      }
      .cloudy {
        left: 25% !important;
        top: 17%;
      }
    }
  }

  .snowdrop {
    position: absolute;
    bottom: 0;
    width: 2px;
    height: 15px;
    background: transparent;
    opacity: 0.7;
    animation-name: snowfall;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    top: -20px;
  }

  @keyframes snowfall {
    0% {
      transform: translate3d(0, 0, 0);
    }
    25% {
      transform: translate3d(var(--random-left-right, 0), 25vh, 0);
    }
    50% {
      transform: translate3d(0, 50vh, 0);
    }
    75% {
      transform: translate3d(var(--random-left-right, 0), 75vh, 0);
    }
    100% {
      transform: translate3d(0, 100vh, 0);
    }
  }

  @keyframes fall {
    to {
      transform: translateY(100vh);
    }
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .svg-container {
    position: absolute;
    top: 0;
    left: -100%;
    width: inherit;
    height: inherit;
    transition: left 1s ease-in-out;
  }

  .show-svg {
    left: 25%;
  }

  .floating {
    animation: float 3s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }

  .bouncing {
    animation: bounce 4s ease infinite;
  }

  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    14%,
    29% {
      transform: translateY(-20px);
    }
  }

  .raindrop,
  .snowice,
  .sunflower {
    position: absolute;
    bottom: 0;
    opacity: 0.7;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    top: -20px;
  }

  .raindrop {
    width: 2px;
    height: 15px;
    background: lightgray;
    animation-name: fallRaindrop;
  }

  .snowice {
    width: 10px;
    height: 10px;
    background: transparent;
    animation-name: fallSnowIce;
  }

  .sunflower {
    width: 15px;
    height: 15px;
    background: transparent;
    animation-name: fallSunflower;
  }

  @keyframes fallRaindrop {
    to {
      transform: translateY(100vh);
    }
  }

  @keyframes fallSnowIce {
    to {
      transform: translateY(100vh);
    }
  }

  @keyframes fallSunflower {
    to {
      transform: translateY(100vh);
    }
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    div.refresh-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      border-radius: 2rem;
      /* background-color: #f9f9f9; */
      .material-symbols-rounded {
        font-variation-settings: 'FILL' 1, 'wght' 300, 'GRAD' 0, 'opsz' 20;
        color: #78829d;
      }
    }
    div.refresh-btn:hover {
      background-color: #f1f1f2;
    }
    div.refresh-btn.white {
      .material-symbols-rounded {
        color: white;
        opacity: 0.6;
      }
      /* background-color: rgba(255, 255, 255, 0.1); */
    }
    div.refresh-btn.white:hover {
      .material-symbols-rounded {
        opacity: 1;
      }
      background-color: rgba(255, 255, 255, 0.15);
    }
  }
  .totalInfo,
  .safetyViolationInfo {
    height: 100%;
    position: relative;
    > .widget-header {
      width: 100%;
      padding: 0.75rem 1rem;
      > .widget-title {
        height: 2rem;
        > span {
          color: ${({ theme }: { theme: any }) => theme.text_primary};
          font-weight: ${({ theme }: { theme: any }) => theme.font_semibold};
          font-size: 1.125rem;
          line-height: 1.2;
        }
      }
      > .widget-subTitle {
        font-size: 0.875rem;
        line-height: 1rem;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
      }
    }
    > .widget-body {
      flex-grow: 1;
      width: 100%;
      padding: 0 1rem;
      margin-bottom: 1rem;

      user-select: none;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      > div {
        gap: 0.75rem;
        > div:nth-child(1) {
          width: 3rem;
          height: 3rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(0, 0, 0, 0.025);
          border-radius: 0.5rem;
          .material-symbols-rounded {
            font-variation-settings: 'FILL' 1, 'wght' 600, 'GRAD' 200, 'opsz' 40;
            font-size: 1.5rem;
          }
        }
        > div.red {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
        }
        > div.orange {
          color: #fb923c;
          background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
        }
        > div.green {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
        }
        > div.blue {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
        }
        > div:nth-child(2) {
          display: flex;
          flex-direction: column;
          font-size: 0.875rem;
          font-weight: 600;
          line-height: 1.25rem;
          color: ${({ theme }: { theme: any }) => theme.text_secondary};
          gap: 0.25rem;
          > div {
            font-size: 1rem;
            span {
              font-size: 1rem;
              color: ${({ theme }: { theme: any }) => theme.text_primary};
              font-weight: ${({ theme }: { theme: any }) => theme.font_semibold};
            }
          }
        }
        /* > div:nth-child(2) {
          font-size: 0.875rem;
          font-weight: 600;
          line-height: 1.25rem;
          color: rgba(0, 0, 0, 0.6);
          > div {
            font-size: 1rem;
            span {
              font-size: 1.25rem;
              color: rgba(0, 0, 0, 0.8);
              font-weight: 600;
            }
          }
        } */
      }
      > div.infoBox {
        gap: 0.5rem;
        font-size: 0.75rem;
        > div:nth-child(2) {
          gap: 0;
          span {
            margin-right: 0.125rem;
          }
        }
      }
    }
    .widget-body.safety {
      gap: 1rem;
      margin: 1rem 0;
      > div:nth-child(1) {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
      }
      > div:nth-child(2) {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
      }
      > div {
        border-radius: 0.25rem;
        display: flex;
        padding: 0 0.75rem;
        > div:nth-child(1) {
          width: 100%;
          text-align: left;
          justify-content: flex-start;
          height: 100%;
          align-items: flex-start;
          span {
            font-size: 3rem;
            padding-top: 0.5rem;
          }
        }
        > div:nth-child(2) {
          width: 100%;
          align-items: flex-end;
          gap: 0;
          height: 100%;
          justify-content: flex-end;
          padding-bottom: 0.75rem;
          span {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
  .dDay,
  .accidentInfo,
  .siteNote,
  .safetyViolationInfoDetail,
  .imageBoard,
  .noxiousGas,
  .excavationProcess,
  .mGas {
    user-select: none;
    height: 100%;
    > .widget-header {
      width: 100%;
      margin: 0.75rem 0;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      > .widget-title {
        padding: 0 1rem;
        height: 2rem;
        align-items: center;
        > span {
          font-weight: ${({ theme }: { theme: any }) => theme.font_semibold};
          font-size: 1.125rem;
          line-height: 1.2;
          color: ${({ theme }: { theme: any }) => theme.text_primary};
          > .badge {
            font-weight: 500;
            font-size: 0.875rem;
            padding: 0.25rem 0.25rem;
            border-radius: 0.25rem;
            background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
            margin: 0 0.5rem;
            color: rgb(62, 151, 255);
            display: inline-block;
            min-width: 1.5rem;
            text-align: center;
          }
        }
        > .widget-title-innerTab,
        > .widget-btn-group {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          > span {
            font-weight: ${({ theme }: { theme: any }) => theme.font_semibold};
            font-size: 1.125rem;
            color: ${({ theme }: { theme: any }) => theme.text_primary};
          }
          > button {
            height: 2rem;
            font-weight: ${({ theme }: { theme: any }) => theme.font_bold};
            padding: 0 0.75rem;
            border-radius: 1rem;
            font-size: 0.875rem;
            color: ${({ theme }: { theme: any }) => theme.text_secondary};
            background-color: ${({ theme }: { theme: any }) => theme.tonal};
            border-radius: 0.5rem;
            gap: 0;
          }
          > button:hover {
            background-color: ${({ theme }: { theme: any }) => theme.tonal};
          }
          > button:nth-child(1) {
            padding-left: 0.25rem;
            padding-right: 0.5rem;
            .material-symbols-rounded {
              font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 20;
              color: #78829d;
            }
          }
          > .innerTab {
            gap: 0.25rem;
            > div {
              cursor: pointer;
              height: 2rem;
              padding: 0 0.75rem;
              display: flex;
              align-items: center;
              font-weight: 500;
              color: ${({ theme }: { theme: any }) => theme.text_secondary};
              border-radius: 1rem;
              font-weight: ${({ theme }: { theme: any }) => theme.font_medium};
              /* font-size: 0.875rem; */
            }
            > div.active {
              font-weight: ${({ theme }: { theme: any }) => theme.font_bold};
              color: #3e97ff;
              background-color: ${({ theme }: { theme: any }) => theme.tonal};
            }
            > div:hover {
              background-color: ${({ theme }: { theme: any }) => theme.tonal};
            }
          }
        }
      }
    }
    > .widget-header.safety {
      gap: 0;
      > .widget-subTitle {
        font-size: 0.875rem;
        line-height: 1rem;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
        padding: 0 1rem;
      }
    }
    .oneWeekCalendar-wrapper {
      padding: 0 1rem;
      width: 100%;
    }
    .oneWeekCalendar {
      gap: 0.25rem;
      .dayWrapper {
        flex-grow: 1;
        padding: 0.75rem 0;
        max-width: 2.75rem;
        border-radius: 2rem;
        text-align: center;
        cursor: pointer;
        .day {
          font-weight: ${({ theme }: { theme: any }) => theme.font_medium};
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        }
        .date {
          color: ${({ theme }: { theme: any }) => theme.text_primary};
        }
      }
      .todayHighlight {
        background-color: ${({ theme }: { theme: any }) => theme.selected_primary};
        > div.day,
        > div.date {
          color: #fff;
        }
        > div.day {
          font-weight: 500;
        }
      }
    }
    .widget-body {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .widget-body.safety {
      gap: 1rem;
      > div:nth-child(1) {
        background-color: rgb(255, 247, 237);
      }
    }
    .widget-body.note {
      width: 100%;
      height: 100%;
      .sliderWrapper {
        height: inherit;
        .slick-list .slick-track .slick-slide > div {
          padding: 0 1rem;
          th,
          td {
            border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
          }
        }
        .ck-content {
          border: 1px solid lightgray;
          border-radius: 0.25rem;
          padding: 0.5rem;
          height: 7rem;
          overflow: hidden;
          > * {
            margin: 0;
          }
        }
        .slick-arrow {
          display: none !important;
        }
        .slick-dots {
          top: -2.25rem;
          width: inherit;
          right: 1rem;
          li {
            margin: 0;
            width: 24px;
            button {
              width: 1.5rem;
            }
            button:before {
              transition: all ease-in-out 0.2s;
              font-family: 'slick';
              font-size: 15px;
              line-height: 20px;
              position: absolute;
              top: 0;
              left: 0;
              /* width: 12px; */
              height: 6px;
              border-radius: 0.5rem;
              content: '';
              text-align: center;
              color: black;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              background-color: rgb(62, 151, 255);
            }
          }
        }
        .noteTitle {
          padding-bottom: 0.5rem;
          > div:nth-child(1) > span:nth-child(2) {
            color: ${({ theme }: { theme: any }) => theme.text_primary};
          }
          > div:nth-child(2) {
            color: ${({ theme }: { theme: any }) => theme.text_secondary};
            font-size: 0.875rem;
          }
        }
        .ck-content {
          border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
        }
        .ck-editor__editable {
          background-color: ${({ theme }: { theme: any }) => theme.board};
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          * {
            color: ${({ theme }: { theme: any }) => theme.text_primary};
          }
        }
        .titleBar {
          width: 0.125rem;
          height: 1rem;
          background-color: #2977ff;
          margin-right: 0.5rem;
        }
      }
    }
    .widget-body.photoTable {
      flex-grow: 1;
      overflow: hidden;
      > .innerTab {
        width: 100%;
        display: flex;
        border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
        > div {
          height: 3rem;
          display: flex;
          align-items: center;
          flex: 1 1 50%;
          justify-content: center;
          border-bottom: 2px solid transparent;
          font-size: 0.875rem;
          cursor: pointer;
          color: ${({ theme }: { theme: any }) => theme.text_secondary};
          font-weight: 700;
        }
        > div.active {
          color: ${({ theme }: { theme: any }) => theme.text_primary};
          border-bottom: 2px solid ${({ theme }: { theme: any }) => theme.selected_primary};
        }
        .text-container .active {
          display: block;
          animation: slideIn 2s forwards;
        }

        @keyframes slideIn {
          from {
            opacity: 0;
            transform: translateX(-100%);
          }
          to {
            opacity: 1;
            transform: translateX(0);
          }
        }

        @keyframes slide {
          0% {
            transform: translateY(0);
          }
          100% {
            transform: translateY(-100%);
          }
        }
      }
      > .innerTab.nonClick > div {
        pointer-events: none;
        cursor: initial;
      }
      > .sliderWrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        .text-container {
          display: flex;
          flex-direction: column;
          position: relative;
        }

        .rowWrapper {
          pointer-events: none;
          width: 100%;
          padding-right: 0.5rem;
          padding-left: 1rem;
          cursor: pointer;
          gap: 0.5rem;
          height: 3.5rem;
          > div:nth-child(1) {
            flex-grow: 1;
            gap: 0.5rem;
            width: 12rem;
            .imgWrapper {
              width: 2.25rem;
              height: 2.25rem;
              border-radius: 0.25rem;
              overflow: hidden;
              flex-shrink: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: rgba(0, 0, 0, 0.05);
              .material-symbols-rounded {
                font-variation-settings: 'FILL' 1, 'wght' 600, 'GRAD' 200, 'opsz' 48;
                color: rgba(0, 0, 0, 0.25);
                font-size: 1.75rem;
              }

              img {
                width: inherit;
                height: inherit;
                object-fit: cover;
              }
            }
            > div:nth-child(2) {
              width: calc(100% - 2.75rem);
              > div:nth-child(1),
              > .rowContent {
                width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 0.875rem;
                line-height: 1.25rem;
                height: 1.25rem;
                color: ${({ theme }: { theme: any }) => theme.text_disabled};
                span {
                  color: ${({ theme }: { theme: any }) => theme.text_primary};
                  font-size: 0.875rem;
                  line-height: 1rem;
                }
              }
            }
          }
        }
        .buttonsWrapper {
          button {
            height: inherit;
            padding: 0;
            width: 2.5rem;
            font-size: 0.875rem;
            height: 1.75rem;
            span {
              font-weight: 700;
              margin: 0 0.125rem;
            }
          }
          button.red {
            background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
            color: ${({ theme }: { theme: any }) => theme.filled_red};
          }
          button.amber {
            background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
            color: ${({ theme }: { theme: any }) => theme.filled_amber};
          }
        }
      }
    }
    .widget-body.chart {
      width: 100%;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      .doughnutChartWrapper {
        height: inherit;
        > canvas {
          width: 10rem;
          height: 10rem;
          margin: 0 auto;
        }
      }
      .content {
        margin: 1rem 0;
        display: flex;
        align-items: center;
        gap: 1rem;
        dl {
          display: flex;
          align-items: center;
          gap: 0.25rem;
          color: ${({ theme }: { theme: any }) => theme.text_secondary};
          > .chart-color {
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 1rem;
          }
          > .chart-color.blue {
            background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
          }
          > .chart-color.green {
            background-color: ${({ theme }: { theme: any }) => theme.filled_green};
          }
          > .chart-color.red {
            background-color: ${({ theme }: { theme: any }) => theme.filled_red};
          }
          > span > span.value {
            font-weight: 600;
            color: ${({ theme }: { theme: any }) => theme.text_primary};
          }
        }
      }
    }
    .widget-body.progressBar {
      width: 100%;
      flex-grow: 1;
      padding: 0 1rem;
      justify-content: space-between;
      margin-bottom: 1rem;
      > .progressBar-group {
        display: flex;
        flex-direction: column;
        > .progressBar-text {
          > span:nth-child(1) {
            color: ${({ theme }: { theme: any }) => theme.text_secondary};
          }
          > span:nth-child(2) {
            color: ${({ theme }: { theme: any }) => theme.text_primary};
          }
        }
        .fullBar {
          height: 2rem;
          border-radius: 1rem;
          margin-top: 0.25rem;
          overflow: hidden;
          display: flex;
          .fillBar {
            background-color: rgb(80, 205, 137);
            height: 100%;
          }
          .emptyBar {
            background-color: rgb(80, 205, 137);
            height: 100%;
            opacity: 0.35;
          }
        }
      }
      .content {
        width: 100%;
        font-size: 0.875rem;
        dl {
          gap: 0.5rem;
          > dt {
            color: ${({ theme }: { theme: any }) => theme.text_secondary};
            font-weight: 600;
          }
        }
        dd {
          color: ${({ theme }: { theme: any }) => theme.text_primary};
        }
        > div {
          > span {
            border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
            padding: 0.5rem 0;
            margin: 0 0.5rem;
          }
          span.green {
            padding: 0.125rem 0.375rem;
            background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
            border-radius: 0.25rem;
          }
          span.blue {
            padding: 0.125rem 0.375rem;
            background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
            border-radius: 0.25rem;
          }
        }
      }
    }
    .widget-body.donut {
      width: 100%;
      height: 100%;
      align-items: center;
      flex-direction: row;
      > .doughnutChartWrapper {
        overflow: hidden;
        /* height: 8rem; */
        padding: 0 1rem;
        margin-bottom: 1rem;
        width: 11rem;
        flex-shrink: 0;
      }
      > .content {
        flex: 1 1 auto;
        padding-right: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        padding-bottom: 2rem;
        dd,
        dt {
          font-size: 0.875rem;
          color: ${({ theme }: { theme: any }) => theme.text_primary};
        }
        dl {
          gap: 0.5rem;
          padding: 0 0.25rem;
          > dt {
            color: ${({ theme }: { theme: any }) => theme.text_secondary};
            font-weight: 600;
            gap: 0.5rem;
            div {
              width: 0.75rem;
              height: 0.75rem;
              border-radius: 1rem;
            }
            div.dot {
              background-color: #3e98ff29;
              display: flex;
              justify-content: center;
              align-items: center;
              > div {
                width: 0.25rem;
                height: 0.25rem;
                background-color: #3e97ff;
              }
            }
            div.fill {
              background-color: #3e97ff;
            }
            div.empty {
              background-color: #3e97ff;
              opacity: 0.25;
            }
          }
          > dd {
            gap: 0.25rem;
            span {
              padding: 0.125rem 0.375rem;
              color: #3e97ff;
              background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
              border-radius: 0.25rem;
              line-height: 1rem;
            }
          }
        }
        > dl:nth-child(1) {
          flex-direction: column;
          gap: 0.5rem;
          padding: 0;
          > dd:nth-child(2) {
            order: -999;
            gap: 0;
            font-size: 1.5rem;
            color: rgba(0, 0, 0, 0.6);
            span {
              font-size: 2rem;
              background-color: transparent;
              padding: 0 0.25rem;
            }
          }
        }
      }
    }
    .widget-body.table {
      width: 100%;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      overflow: hidden;
      > div {
        height: 100%;
        .table {
          flex-grow: 1;
        }
      }
      .tr {
        pointer-events: none;
        .padding-right {
          padding-right: 1rem;
        }
        .tableStickyNo {
          padding-left: 1rem;
          font-weight: 600;
        }
        span.redLight {
          width: 2.125rem;
          height: 1.5rem;
          color: #f1416c;
          font-weight: 600;
          text-align: center;
          border-radius: 0.25rem;
          background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
        }
        span.greenLight {
          width: 2.125rem;
          height: 1.5rem;
          color: #50cd89;
          font-weight: 600;
          text-align: center;
          border-radius: 0.25rem;
          background-color: rgb(232, 255, 243);
        }
      }
      .tr.hover {
      }
    }
    .widget-body.graph {
      width: 100%;
      padding: 0 1rem;
      > div:nth-child(1) {
        width: 100%;
        > ul > li {
          width: 100%;
          max-width: none;
          > span {
            width: 100%;
          }
        }
      }
      > .lineChartWrapper {
        padding-bottom: 1rem;
      }
    }
    .widget-body.cctv-wide {
      flex-direction: row;
      gap: 0.5rem;
      padding: 0 1rem;
      height: 100%;
      overflow: hidden;
      > div:nth-child(2) {
        flex-grow: 1;
      }
      .cctvWrapper {
        border-radius: 0.25rem;
        padding: 0;
        > div {
          border-radius: 0;
        }
      }
      .controllerWrapper {
        border-radius: 0;
        background-color: transparent;
      }
      .controllerWrapper {
        .leftControl,
        .rightControl {
          display: none;
        }
        justify-content: center;
        .controllerBtn {
          padding: 0;
          width: 2rem;
          height: 2rem;
          color: ${({ theme }: { theme: any }) => theme.text_secondary};
        }
        .controllerBtn.activePage {
          border: none;
          color: ${({ theme }: { theme: any }) => theme.text_primary};
          background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
        }
      }
      .camListWrapper {
        display: flex;
        flex-direction: column;
        overflow: auto;
        flex-shrink: 0;
        margin-bottom: 1rem;
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        border-radius: 0.25rem;
        > button {
          height: 2.5rem;
          width: 10rem;
          padding: 0px 1rem;
          font-size: 0.875rem;
          justify-content: flex-start;
          flex-shrink: 0;
          font-weight: 500;
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          text-align: left;
          &:hover {
            background-color: transparent;
          }
        }
        .active {
          background-color: transparent;
          border: none;
          color: rgba(0, 0, 0, 0.8);
        }
      }
    }
    .cameraLabel {
      font-size: 1rem !important;
    }
  }
  .cctv {
    .cctvWrapper,
    .controllerWrapper {
      max-height: 22rem;
      max-width: inherit;
    }
    .contents {
      > :first-child {
        padding-bottom: 1rem;
      }
    }
  }
  .safetyMainPoint {
    overflow: hidden;
    height: 100%;
    .content {
      font-size: 3rem;
      height: inherit;
      width: 100%;
      .flow {
        height: inherit;
        text-align: center;
      }
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
      .logoWrapper {
        width: 12rem;
        padding: 1rem;
        z-index: 2;
        background-color: ${({ theme }: { theme: any }) => theme.board};
        flex-shrink: 0;
      }
      .marquee {
        overflow: hidden;
        white-space: nowrap;
        flex-grow: 1;
        height: 100%;
        z-index: 1;
        white-space: nowrap;
        display: flex;
        align-items: center;
      }
      .marquee-content {
        display: inline-block;
        padding-left: calc(100% - 12rem);
      }
      @keyframes marquee {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-100%);
        }
      }

      .blink {
        animation: blinker 1s linear infinite;
      }

      @keyframes blinker {
        50% {
          opacity: 0;
        }
      }
    }
  }
  .imageBoard {
    height: 100%;
    .content {
      padding: 0;
      margin: 0;
      width: 100%;
      height: calc(100% - 3.5rem);
      flex-grow: 1;
      > div {
        height: 100%;
        .slick-list {
          height: inherit;
          div {
            height: 100%;
          }
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: block;
        margin: auto;
      }

      .slick-arrow {
        display: none !important;
      }

      .slick-dots {
        top: -2rem;
        width: inherit;
        right: 1rem;
        display: flex !important;
        gap: 0.5rem;
        li {
          margin: 0;
          width: 0.75rem;
          height: 0.75rem;
          border-radius: 0.25rem;
          button {
            width: 0.75rem;
            width: 24px;
          }
          button:before {
            transition: all ease-in-out 0.2s;
            font-family: 'slick';
            font-size: 15px;
            line-height: 20px;
            position: absolute;
            top: 0;
            left: 0;
            width: 12px;
            height: 12px;
            border-radius: 0.25rem;
            content: '';
            text-align: center;
            color: black;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            background-color: rgb(62, 151, 255);
          }
        }

        .slick-active {
          button:before {
            width: 16px;
            width: 24px;
          }
        }
      }
    }
  }

  .safetyViolationInfo {
    display: flex;
    flex-direction: column;
    .widget-header {
    }
    .content {
      height: calc(100% - 4rem);

      .boxWrapper {
        height: calc(100% - 2rem);
        gap: 2rem;
        padding-top: 1rem;
      }

      .box {
        > div {
          text-align: end;
          span {
            color: rgb(63, 66, 84);
            font-size: 3rem;
          }
        }
        flex: 0.5;

        border-radius: 0.8rem;
        padding: 2rem;
        // text-align: center;
        background: rgb(243, 246, 249);
      }
    }
  }

  .accidentInfo {
    height: 100%;

    .content {
      display: flex;
      justify-content: space-evenly;
      gap: 0.25rem;

      .doughnutChartWrapper {
        width: 40%;
      }

      .chartContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 1rem;
      }

      dt,
      dd {
        font-size: 1.4rem;
      }

      dt {
        /* margin-right: 1rem; */
        display: flex;
      }
    }
  }

  .noxiousGas,
  .excavationProcess,
  .mGas {
    .doubleLine {
      line-height: 1rem;
      text-align: center;
    }

    .labelSet {
      > span {
        padding: 0 0.5rem;
        margin-left: 0.5rem;
      }
    }

    .textBox {
      font-size: 0.875rem;
      border-radius: 5px;
      /* border-radius: 1rem; */
      padding: 0 0.2rem;
      margin-left: 0.5rem;
    }

    .blue {
      color: ${({ theme }: { theme: any }) => theme.filled_blue};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
    }

    .red {
      color: ${({ theme }: { theme: any }) => theme.filled_red};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
    }

    .violet {
      color: ${({ theme }: { theme: any }) => theme.filled_violet};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_violet};
    }

    .orange {
      color: ${({ theme }: { theme: any }) => theme.filled_orange};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
    }

    .green {
      color: ${({ theme }: { theme: any }) => theme.filled_green};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
    }

    .amber {
      color: ${({ theme }: { theme: any }) => theme.filled_red};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
    }

    .slate {
      color: ${({ theme }: { theme: any }) => theme.filled_slate};
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
    }
  }

  .excavationProcess {
    .title,
    .content {
      width: 30%;
    }

    .textBox {
      border-radius: 5px;
      padding: 0 0.5rem;
    }

    .table .table > .tbody > .tr > * {
      height: 1.2rem;
      margin: 0.5rem 0;
    }
    /* CSS for the progress container */
    .progress-container {
      // width: 100%;
      height: 1.2rem;
      border: 0;
      position: relative;

      /* Styling the progress bar */
      progress {
        width: 100%;
        height: 100%;
        appearance: none;
        border-radius: 15px;
        background-color: rgba(80, 205, 137, 0.15);
      }

      /* Styling the uncompleted progress bar */
      progress::-webkit-progress-bar {
        background-color: rgba(80, 205, 137, 0.15); /* Uncompleted progress color */
        border-radius: 15px; /* Apply the same border radius as the progress element */
      }

      /* Styling the value (completed progress) */
      progress::-webkit-progress-bar {
        border-radius: 15px;
      }

      /* Styling the value (completed progress) */
      progress::-webkit-progress-value {
        background-color: rgba(80, 205, 137, 1);
        border-radius: 15px;
      }

      /* Styling the text inside the progress bar */
      .progress-text {
        text-align: center;
        font-size: 14px;
        line-height: 20px;
        color: white;
        background-color: #4caf50;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      .progressValue {
        position: relative;
        height: inherit;
        top: -26px;
        color: #fff;
      }
    }
  }

  .mGas {
    .mGasColumn > div {
      height: 2.25rem !important;
    }
    .thead {
      border-bottom: none;
      > :last-child {
        > div {
          border-right: 1px solid ${({ theme }: { theme: any }) => theme.outline_em};
        }
      }
      > .tr {
        border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};

        > div {
          padding: 0.5rem;
          text-align: center;
        }
      }
    }

    > .table {
      .thead {
        border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline_em};
      }
      > div {
        padding: 1rem 0;
      }
    }
    .mGasColumn {
      &.stickyTd {
        position: sticky;
        left: 0;
        background-color: ${({ theme }: { theme: any }) => theme.board};
      }
      > div {
        border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    }
    .textLight {
      text-align: center;
      min-width: 3rem;
      font-size: 0.875rem;
      padding: 0 0.25rem;
      border-radius: 0.25rem;
    }
    .light {
      display: block;
      border-radius: 50%;
      background-color: transparent;
      font-size: 1.5rem;
    }
  }

  .mGas.tempEdit {
    .widget-header {
      padding-bottom: 0.5rem !important;
      margin-bottom: 0 !important;
    }
  }
`;

const animateWeather = (showSvg: boolean, weather: string) => {
  switch (weather) {
    case 'sunny': {
      return (
        <div className='container'>
          <div className='svg-container show-svg '>
            <Sun className='rotating-sun' />
          </div>
        </div>
      );
    }

    case 'little_cloud': {
      return (
        <div className='container'>
          <div className={`svg-container ${showSvg ? 'show-svg floating' : ''}`}>
            <SunCloud className='little_cloud' />
          </div>
        </div>
      );
    }

    case 'blur':
    case 'cloudy': {
      return (
        <div className='container'>
          <div className='svg-container show-svg floating'>
            {/* <div className='svg-container cloudy show-svg floating'> */}
            <Blur className='blur' />
            {/* <SunCloud />
            <Cloud style={{ position: 'absolute', left: '10%', bottom: '30%' }} /> */}
          </div>
        </div>
      );
    }

    case 'rain': {
      const newDrops: any[] = [];
      let increment = 0;
      while (increment < 95) {
        const randoHundo = Math.floor(Math.random() * 100);
        const randoFiver = Math.floor(Math.random() * 5) + 2;
        increment += randoFiver;

        const dropStyle = {
          left: `${increment}%`,
          animationDelay: `0.${randoHundo}s`,
          animationDuration: `0.8${randoHundo}s`,
        };

        newDrops.push(<div className='raindrop' style={dropStyle} key={increment} />);
      }

      return (
        <div className='container'>
          {newDrops}
          <div className={`svg-container ${showSvg ? 'show-svg bouncing' : ''}`}>
            <Umbrella style={{ position: 'absolute', right: '5%', top: '-30%' }} />
          </div>
        </div>
      );
    }
    case 'shower': {
      const newDrops: any[] = [];
      let increment = 0;
      while (increment < 95) {
        const randoHundo = Math.floor(Math.random() * 100);
        const randoFiver = Math.floor(Math.random() * 5) + 2;
        increment += randoFiver;

        const dropStyle = {
          left: `${increment}%`,
          animationDelay: `0.${randoHundo}s`,
          animationDuration: `0.3${randoHundo}s`,
        };

        newDrops.push(<div className='raindrop' style={dropStyle} key={increment} />);
      }
      return (
        <div className='container'>
          {newDrops}
          <div className={`svg-container ${showSvg ? 'show-svg bouncing' : ''}`}>
            <Umbrella style={{ position: 'absolute', right: '5%', top: '-30%' }} />
          </div>
        </div>
      );
    }

    case 'sleet': {
      const newDrops: any[] = [];
      let increment = 0;
      const types = ['raindrop', 'snowice', 'sunflower'];

      while (increment < 95) {
        const randoHundo = Math.floor(Math.random() * 100);
        const randoFiver = Math.floor(Math.random() * 5) + 2;
        const randoLeftRight = Math.floor(Math.random() * 5) - 2;
        const randomType = types[Math.floor(Math.random() * types.length)];

        increment += randoFiver;

        let animationDuration;
        switch (randomType) {
          case 'raindrop':
            animationDuration = `${2 + Math.random()}s`;
            break;
          case 'snowice':
            animationDuration = `${5 + Math.random()}s`;
            break;
          case 'sunflower':
            animationDuration = `${7 + Math.random()}s`;
            break;
          default:
            animationDuration = `${5 + Math.random()}s`;
            break;
        }

        const dropStyle = {
          left: `${increment}%`,
          animationDelay: `0.${randoHundo}s`,
          animationDuration,
          '--random-left-right': `${randoLeftRight}vw`,
        };

        newDrops.push(
          <div className={randomType} style={dropStyle as React.CSSProperties} key={increment}>
            {randomType === 'snowice' && <SnowIce30 />}
            {randomType === 'sunflower' && <SnowFlower />}
          </div>
        );
      }

      return (
        <div className='container'>
          {newDrops}
          <div className={`svg-container little_cloud ${showSvg ? 'show-svg bouncing' : ''}`}>
            <Umbrella style={{ position: 'absolute', right: '5%', top: '0%' }} />
          </div>
        </div>
      );
    }

    case 'snow': {
      const newDrops: any[] = [];
      let increment = 0;
      while (increment < 95) {
        const randoHundo = Math.floor(Math.random() * 100);
        const randoFiver = Math.floor(Math.random() * 5) + 2;
        const randoLeftRight = Math.floor(Math.random() * 5) - 2;
        increment += randoFiver;

        const dropStyle = {
          left: `${increment}%`,
          animationDelay: `0.${randoHundo}s`,
          animationDuration: `${5 + Math.random()}s`,
          '--random-left-right': `${randoLeftRight}vw`,
        };

        newDrops.push(
          <div className='snowdrop' style={dropStyle as React.CSSProperties} key={increment}>
            <SnowIce30 />
            <SnowBall />
          </div>
        );
      }

      return newDrops;
    }

    case 'sunCloud': {
      return (
        <div className='container'>
          <div className={`svg-container ${showSvg ? 'show-svg floating' : ''}`}>
            <SunCloud className='little_cloud beside-sun' />
          </div>
          <Sun className='rotating-sun' />
        </div>
      );
    }

    default: {
      return <Sun className='rotating-sun' />;
    }
  }
};

const getWeatherIcon = (weather: string) => {
  switch (weather) {
    case 'sunny':
      return <img src={sun} width='100%' />;
    case 'little_cloud':
      return <img src={littleCloud} width='100%' />;
    case 'cloudy':
      return <img src={cloud} width='100%' />;
    case 'blur':
      return <img src={blur} width='100%' />;
    case 'rain':
      return <img src={rain} width='100%' />;
    case 'sleet':
      return <img src={snow} width='100%' />;
    case 'snow':
      return <img src={snow} width='100%' />;
    case 'shower':
      return <img src={rain} width='100%' />;
    default:
      return null;
  }
};

const getTimeStatus = (): string => {
  const currentHour = dayjs().hour();

  if (currentHour >= 0 && currentHour < 6) {
    return 'morning';
  }
  if (currentHour >= 6 && currentHour < 18) {
    return 'afternoon';
  }
  return 'evening';
};

const Control = ({ content, isRefreshTimeExpired, tatCd }: IControl) => {
  const { dCd, name, isInDropZone, height, width } = content && content.props.cell;
  const userInfo = useRecoilValue(userState);
  const toDay = todayYYYYMMDD();
  const weekDays = getPreviousDays(toDay, 7);
  return (
    <ControlStyle dCd={dCd} cellHeight={height} cellWidth={width}>
      {/* 기상정보 */}
      {dCd === '031' && isInDropZone && <WeatherInfo name={name} userInfo={userInfo} isRefreshTimeExpired={isRefreshTimeExpired} />}
      {/* 기상정보 */}
      {(dCd === '074' || dCd === '075') && isInDropZone && <WeatherWide name={name} userInfo={userInfo} isRefreshTimeExpired={isRefreshTimeExpired} width={dCd === '074' ? 'large' : 'medium'} />}
      {/* 기상 정보 (주간) */}
      {dCd === '032' && isInDropZone && <WeatherWeekInfo name={name} userInfo={userInfo} isRefreshTimeExpired={isRefreshTimeExpired} />}
      {/* 종합 현황 */}
      {dCd === '003' && isInDropZone && <TotalInfo name={name} userInfo={userInfo} isRefreshTimeExpired={isRefreshTimeExpired} />}
      {/* D-DAY */}
      {dCd === '021' && isInDropZone && <Dday name={name} weekDays={weekDays} userInfo={userInfo} isRefreshTimeExpired={isRefreshTimeExpired} />}
      {/* 현장 전달사항 */}
      {dCd === '022' && isInDropZone && <SiteNotice name={name} userInfo={userInfo} isRefreshTimeExpired={isRefreshTimeExpired} />}
      {/* 안전 중점사항 */}
      {(dCd === '023' || dCd === '076') && isInDropZone && <SafetyMainPoint name={name} userInfo={userInfo} isRefreshTimeExpired={isRefreshTimeExpired} />}
      {/* 이미지 게시판 */}
      {dCd === '024' && isInDropZone && <ImageBoard name={name} userInfo={userInfo} isRefreshTimeExpired={isRefreshTimeExpired} />}
      {/* 안전 위반 현황 */}
      {dCd === '026' && isInDropZone && <SafetyViolationInfo name={name} userInfo={userInfo} isRefreshTimeExpired={isRefreshTimeExpired} />}
      {/* 무사고 현황 */}
      {dCd === '028' && isInDropZone && <AccidentInfo name={name} userInfo={userInfo} isRefreshTimeExpired={isRefreshTimeExpired} />}
      {/* 안전 위반 현황 상세 */}
      {dCd === '030' && isInDropZone && <SafetyViolationInfoDetail name={name} userInfo={userInfo} isRefreshTimeExpired={isRefreshTimeExpired} />}
      {/* 유해가스 종합현황 (도형) */}
      {dCd === '036' && isInDropZone && <MGasInfo name={name} userInfo={userInfo} autoRefresh size='large' valueType='shape' autoPlay refresh={false} />}
      {/* 굴진 진행 현황 */}
      {dCd === '038' && isInDropZone && <ExcavationProcess name={name} userInfo={userInfo} isRefreshTimeExpired={isRefreshTimeExpired} />}
      {/* 근로자 위치 현황 */}
      {dCd === '040' && isInDropZone && <ExcavationWorkerPosition name={name} userInfo={userInfo} isRefreshTimeExpired={isRefreshTimeExpired} />}
      {/* 유해가스 종합현황 (숫자) */}
      {dCd === '045' && isInDropZone && <MGasInfo name={name} userInfo={userInfo} autoRefresh size='large' valueType='number' autoPlay refresh={false} />}
      {/* 터널 종합 진행 현황(대형) */}
      {dCd === '052' && isInDropZone && <TunnelTotalInfo name={name} userInfo={userInfo} size='large' visibleRefreshBtn={false} uniqueKey={dCd} />}
      {/* 터널 종합 진행 현황 */}
      {dCd === '053' && isInDropZone && <TunnelTotalInfo name={name} userInfo={userInfo} visibleRefreshBtn={false} uniqueKey={dCd} />}
      {/* 터널 구역별 종합 진행 현황(대형) */}
      {dCd === '054' && isInDropZone && <TunnelAreaInfo name={name} userInfo={userInfo} size='large' visibleRefreshBtn={false} uniqueKey={dCd} />}
      {/* 터널 구역별 종합 진행 현황 */}
      {dCd === '055' && isInDropZone && <TunnelAreaInfo name={name} userInfo={userInfo} size='large' visibleRefreshBtn={false} uniqueKey={dCd} />}
      {/* 터널 종합 진행 현황(일반) */}
      {dCd === '056' && isInDropZone && <TunnelTotalTable name={name} userInfo={userInfo} uniqueKey={dCd} visibleRefreshBtn={false} />}
      {/* 근로자 출입현황(다중현장용) */}
      {dCd === '070' && isInDropZone && <WorkerAttendMultiSite name={name} userInfo={userInfo} refreshTimeCount={60} uniqueKey={dCd} />}
      {/* 근로자 출입현황(다중현장용(1개 출입구용)) */}
      {dCd === '071' && isInDropZone && <WorkerAttendMultiSiteExit name={name} userInfo={userInfo} refreshTimeCount={60} uniqueKey={dCd} />}
      {/* 근로자 출입현황(다중현장용(1개 출입구용, 인원만)) */}
      {(dCd === '072' || dCd === '073') && isInDropZone && (
        <WorkerAttendCountMultiSiteExit name='근로자 출입현황' userInfo={userInfo} refreshTimeCount={60} uniqueKey={dCd} slidesToShow={dCd === '072' ? 5 : 2} />
      )}
      {/* 협력업체 출역 정보 */}
      {dCd === '077' && isInDropZone && <SiteJoinAttendInfo name={name} userInfo={userInfo} refreshTimeCount={60 * 5} />}
      {/* 근로자 출입현황(다중현장용(1개 출입구용, 인원만, 롯데건설-인천효성3블럭 용으로만 사용)) */}
      {(dCd === '078' || dCd === '082') && isInDropZone && (
        <WorkerAttendCountMultiSiteExit name='근로자 출입현황' userInfo={{ hCd: 'H002', sCd: 'S0001' }} refreshTimeCount={60} uniqueKey={dCd} slidesToShow={dCd === '078' ? 5 : 2} />
      )}
      {/* 근로자 출입현황(다중현장용(1개 출입구용, 인원만, 롯데건설-인천효성3블럭 용으로만 사용, 슬라이딩제거)) */}
      {dCd === '090' && isInDropZone && <WorkerAttendCountMultiSiteExit name='근로자 출입현황' userInfo={{ hCd: 'H002', sCd: 'S0001' }} refreshTimeCount={60} uniqueKey={dCd} slidesToShow={3} />}
      {/*  */}
      {/* 구역별 상황판 */}
      {/* 터널 구역별 종합 진행 현황(대형) */}
      {dCd === '057' && isInDropZone && tatCd && <TunnelAreaInfoWithTatCd name={name} userInfo={userInfo} tatCd={tatCd} uniqueKey={dCd} visibleRefreshBtn={false} />}
      {/* 터널 구역별 종합 진행 현황 */}
      {dCd === '058' && isInDropZone && tatCd && <TunnelAreaInfoWithTatCd name={name} userInfo={userInfo} tatCd={tatCd} uniqueKey={dCd} visibleRefreshBtn={false} />}
      {dCd === '059' && isInDropZone && tatCd && <TunnelTotalTableWithTatCd name={name} userInfo={userInfo} tatCd={tatCd} uniqueKey={dCd} />}
      {/* 현재일자 / 시간 */}
      {dCd === '060' && isInDropZone && <DateDashboard />}
      {/* 유해가스 종합현황 (원형) */}
      {dCd === '061' && isInDropZone && tatCd && <MGasInfoWithTatCd name={name} userInfo={userInfo} tatCd={tatCd} />}
      {/* 굴진 진행 현황 */}
      {dCd === '062' && isInDropZone && tatCd && <TunnelExcavationProcess name={name} userInfo={userInfo} refreshTimeCount={10} tatCd={tatCd} />}
      {/* 터널 작업 / 장비 현황 */}
      {dCd === '063' && isInDropZone && tatCd && <TunnelWorkAndEquipment name={name} userInfo={userInfo} tatCd={tatCd} />}
      {/* 터널 종합 진행 현황(소형) */}
      {dCd === '064' && isInDropZone && tatCd && <TunnelTotalInfoMini name={name} userInfo={userInfo} tatCd={tatCd} />}
      {/* 근로자 위치 현황 */}
      {dCd === '065' && isInDropZone && tatCd && <WorkerLocation name={name} userInfo={userInfo} tatCd={tatCd} />}
      {/* 근로자 출입 현황 */}
      {dCd === '066' && isInDropZone && tatCd && <WorkerAttend name={name} userInfo={userInfo} refreshTimeCount={15} tatCd={tatCd} />}
      {/* 장비 출입 현황 */}
      {dCd === '067' && isInDropZone && tatCd && <EquipAttend name={name} userInfo={userInfo} refreshTimeCount={60} tatCd={tatCd} />}
      {/* 굴진 진행 현황(도넛형) */}
      {dCd === '068' && isInDropZone && tatCd && <TunnelExcavationDoughnut name={name} userInfo={userInfo} tatCd={tatCd} />}
      {/* 유해가스 종합현황 (수치) */}
      {dCd === '069' && isInDropZone && tatCd && <MGasInfoWithTatCd name={name} userInfo={userInfo} valueType='number' tatCd={tatCd} />}
    </ControlStyle>
  );
};

const Dday = ({ name, userInfo, isRefreshTimeExpired }: any) => {
  const { t } = useTranslation();
  const [processPercentage, setProcessPercentage] = useState<number>(0);
  const [processDays, setProcessDays] = useState<number>(0);
  const [leftDays, setLeftDays] = useState<number>(0);
  const [startDate, setStartDate] = useState<string>('');
  const [finishDate, setFinishDate] = useState<string>('');

  const siteInfoQuery = useQuery(['siteInfoGet', userInfo, isRefreshTimeExpired], () => apiGet({ path: '/site/info', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }), {
    retry: 3,
  });

  useEffect(() => {
    if (siteInfoQuery.isSuccess && siteInfoQuery.data) {
      if (siteInfoQuery.data.data.data) {
        const { siteInfo } = siteInfoQuery.data.data.data;
        setStartDate(siteInfo.sDate);
        setFinishDate(siteInfo.fDate);
        const today = dayjs().format('YYYY-MM-DD');
        const today2 = dayjs(today);
        const nStartDate = dayjs(siteInfo.sDate);
        const nFinishDate = dayjs(siteInfo.fDate);

        const nprocessDays = today2.diff(nStartDate, 'day');
        const nleftDays = nFinishDate.diff(today2, 'day');
        const totalDays = nFinishDate.diff(nStartDate, 'day');
        const percentage = Math.round((nprocessDays / totalDays) * 100);
        setProcessPercentage(percentage);
        setProcessDays(nprocessDays);
        setLeftDays(nleftDays);
      }
    }
  }, [siteInfoQuery.isSuccess, siteInfoQuery.isRefetching]);

  const onClickRefresh = () => {
    siteInfoQuery.refetch();
  };

  if (!siteInfoQuery.isSuccess || siteInfoQuery.data.status !== 200 || siteInfoQuery.isRefetching) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <div className='dDay'>
      <div className='widget-header'>
        <div className='widget-title flex-between'>
          <span>{t(name)}</span>
        </div>
      </div>
      <div className='widget-body progressBar'>
        <div className='content'>
          <dl className='flex-basic'>
            <dt>{t('전체 기간')}</dt>
            <dd>
              {startDate} ~ {finishDate}
            </dd>
          </dl>
          <div className='flex-basic'>
            <dl className='flex-basic'>
              <dt>{t('진행 일 수')}</dt>
              <dd>
                <span className='green'>{processDays}</span> {t('-일')}
              </dd>
            </dl>
            <span />
            <dl className='flex-basic'>
              <dt>{t('남은 일 수')}</dt>
              <dd>
                <span className='blue'>{leftDays}</span> {t('-일')}
              </dd>
            </dl>
          </div>
        </div>
        <div className='progressBar-group flex-col'>
          <div className='progressBar-text flex-between'>
            <span>{t('전체 진행률')}</span>
            <span>{processPercentage}%</span>
          </div>
          <div className='fullBar'>
            <div className='fillBar' style={{ width: `${processPercentage}%` }} />
            <div className='emptyBar' style={{ width: `${100 - processPercentage}%` }} />
          </div>
        </div>
      </div>
    </div>
  );
};

const WeatherInfo = ({ name, userInfo, isRefreshTimeExpired }: any) => {
  const { t } = useTranslation();
  const todayjs = dayjs();
  let format = todayjs.format(`MM월 DD일 dddd`);
  if (i18n.language === 'en-US') {
    format = todayjs.locale('en').format(`MMMM D dddd`);
  } else {
    format = todayjs.locale('ko').format(`MM월 DD일 dddd`);
  }
  const days = Array.from({ length: 3 }).map((_, index) => {
    const targetDay = dayjs().add(index, 'day'); // +1 to start from tomorrow
    return {
      order: index,
      date: targetDay.date(),
      day: targetDay.format('ddd'),
    };
  });
  const [geoInfo, setGeoInfo] = useState({ latitude: '', longitude: '', address: '' });
  const after2DaysLocalStorage = window.localStorage.getItem('after2Days');
  const currentWeatherLocalStorage = window.localStorage.getItem('currentWeather');
  const localAfter2Days = after2DaysLocalStorage ? JSON.parse(after2DaysLocalStorage) : days;
  const localCurrentWeather = currentWeatherLocalStorage ? JSON.parse(currentWeatherLocalStorage) : { temp: '', icon: '', wsd: '', sky: '' };
  const [after2Days, setAfter2Days] = useState<any[]>(localAfter2Days);
  const [currentWeather, setCurrentWeather] = useState(localCurrentWeather);
  const [showSvg, setShowSvg] = useState(false);

  const siteInfoQuery = useQuery(['siteInfoGet2', userInfo, isRefreshTimeExpired], () => apiGet({ path: '/site/info', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && userInfo.sCd !== '00000',
  });

  const dayWeatherQuery = useQuery(['dayWeather2', geoInfo, isRefreshTimeExpired], () => weatherDayGet({ latitude: geoInfo.latitude, longitude: geoInfo.longitude, address: geoInfo.address }), {
    retry: 3,
    enabled: !!geoInfo.latitude && !!geoInfo.longitude && !!geoInfo.address,
  });

  const threeDaysWeatherQuery = useQuery(
    ['threeDaysWeather', geoInfo, isRefreshTimeExpired],
    () => weather3DaysGet({ latitude: geoInfo.latitude, longitude: geoInfo.longitude, address: geoInfo.address }),
    {
      retry: 3,
      enabled: !!geoInfo.latitude && !!geoInfo.longitude && !!geoInfo.address,
    }
  );

  useEffect(() => {
    if (after2Days) {
      window.localStorage.setItem('after2Days', JSON.stringify(after2Days));
    }
    if (currentWeather) {
      window.localStorage.setItem('currentWeather', JSON.stringify(currentWeather));
    }
  }, [after2Days, currentWeather]);

  useEffect(() => {
    if (siteInfoQuery.isSuccess && siteInfoQuery.data) {
      if (siteInfoQuery.data.data.data) {
        const { siteInfo } = siteInfoQuery.data.data.data;
        setGeoInfo({ latitude: siteInfo.latitude, longitude: siteInfo.longitude, address: siteInfo.address1 });
      }
    }
  }, [siteInfoQuery.isSuccess, siteInfoQuery.isRefetching]);

  useEffect(() => {
    if (dayWeatherQuery.isSuccess && dayWeatherQuery.data) {
      if (dayWeatherQuery.data.data.data) {
        const { sky, tmp, wsd } = dayWeatherQuery.data.data.data;
        setCurrentWeather((prev: any) => ({ ...prev, tmp, wsd, sky }));
      }
    }
  }, [dayWeatherQuery.isSuccess, dayWeatherQuery.isRefetching, geoInfo]);

  useEffect(() => {
    if (threeDaysWeatherQuery.isSuccess && threeDaysWeatherQuery.data) {
      if (threeDaysWeatherQuery.data.data.data) {
        const { weekSky1, weekSky2, weekTmn1, weekTmn2, weekTmx1, weekTmx2, pm10, todayTmn, todayTmx, morTmp, aftTmp, dinTmp, morPop, aftPop, dinPop } = threeDaysWeatherQuery.data.data.data;
        const copyArray = [...after2Days];
        const status = getTimeStatus();

        copyArray.map((el: any) => {
          if (el.order === 0) {
            copyArray[0] = {
              ...el,
              sky: weekSky1,
              minTemp: todayTmn,
              maxTemp: todayTmx,
              icon: getWeatherIcon(weekSky1),
              morTmp,
              aftTmp,
              dinTmp,
              pm10,
              rainPct: status === 'morning' ? morPop : status === 'evening' ? dinPop : aftPop,
            };
          }
          if (el.order === 1) {
            copyArray[1] = {
              ...el,
              sky: weekSky1,
              minTemp: weekTmn1,
              maxTemp: weekTmx1,
              icon: getWeatherIcon(weekSky1),
            };
          }
          if (el.order === 2) {
            copyArray[2] = {
              ...el,
              sky: weekSky2,
              minTemp: weekTmn2,
              maxTemp: weekTmx2,
              icon: getWeatherIcon(weekSky2),
            };
          }
        });
        setAfter2Days(copyArray);
      }
    }
  }, [threeDaysWeatherQuery.isSuccess, threeDaysWeatherQuery.isRefetching, geoInfo]);

  const onClickRefresh = () => {
    dayWeatherQuery.refetch();
    threeDaysWeatherQuery.refetch();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSvg(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  // if (
  //   !dayWeatherQuery.isSuccess ||
  //   dayWeatherQuery.data.status !== 200 ||
  //   dayWeatherQuery.isRefetching ||
  //   !threeDaysWeatherQuery.isSuccess ||
  //   threeDaysWeatherQuery.data.status !== 200 ||
  //   threeDaysWeatherQuery.isRefetching
  // ) {
  //   return (
  //     <div className='centered-content'>
  //       <PulseLoader color='rgb(0, 122, 255)' size='10px' />
  //     </div>
  //   );
  // }
  return (
    <div className='weatherTodayInfo'>
      <div className='widget-header'>
        <div className='widget-title flex-between '>
          <span>{t(name)}</span>
        </div>
      </div>
      <div className='widget-body'>
        <div className='weatherToday' style={{ color: '#fff' }}>
          <div className='weatherInfo'>
            <div>{t('오늘의 날씨')}</div>
            <div className='weather-dateInfo'>{format}</div>
          </div>
          <div className='weatherAnimationWrapper'>{animateWeather(showSvg, currentWeather.sky)}</div>
        </div>
        <div className='weatherTodayDetail'>
          <div className='weatherBoxGroup'>
            <div className='weatherBox text'>
              <div>
                <dt>{t('강수확률')}</dt>
                <dd>{after2Days[0].rainPct}%</dd>
              </div>
              <div>
                <dt>{t('미세먼지')}</dt>
                <dd>{after2Days[0].pm10}㎍/㎥</dd>
              </div>
              <div>
                <dt>{t('풍속')}</dt>
                <dd>{currentWeather.wsd}m/s</dd>
              </div>
            </div>
            <div className='weatherBox text'>
              <div>
                <dt>{t('오전')}</dt>
                <dd>{after2Days[0].morTmp}℃</dd>
              </div>
              <div>
                <dt>{t('정오')}</dt>
                <dd>{after2Days[0].aftTmp}℃</dd>
              </div>
              <div>
                <dt>{t('오후')}</dt>
                <dd>{after2Days[0].dinTmp}℃</dd>
              </div>
            </div>
            <div className='weatherBox icon'>
              <div>
                <dt>{t('내일')}</dt>
                <div className='weatherBox-details'>
                  <div className='lowest'>
                    {t('최저')} <span>{after2Days[1].minTemp}</span>℃
                  </div>
                  <div className='highest'>
                    {t('최고')} <span>{after2Days[1].maxTemp}</span>℃
                  </div>
                </div>
              </div>
              <dd>
                <div>{getWeatherIcon(after2Days[1].sky)}</div>
              </dd>
            </div>
            <div className='weatherBox icon'>
              <div>
                <dt>{t('모레')}</dt>
                <div className='weatherBox-details'>
                  <div className='lowest'>
                    {t('최저')} <span>{after2Days[2].minTemp}</span>℃
                  </div>
                  <div className='highest'>
                    {t('최고')} <span>{after2Days[2].maxTemp}</span>℃
                  </div>
                </div>
              </div>
              <dd>
                <div>{getWeatherIcon(after2Days[2].sky)}</div>
              </dd>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const WeatherWeekInfo = ({ name, userInfo, isRefreshTimeExpired }: any) => {
  const { t } = useTranslation();
  const [geoInfo, setGeoInfo] = useState({ latitude: '', longitude: '', address: '' });
  const [showSvg, setShowSvg] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSvg(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const days = Array.from({ length: 6 }).map((_, index) => {
    const targetDay = dayjs().add(index + 1, 'day'); // +1 to start from tomorrow
    return {
      order: index,
      date: targetDay.date(),
      day: targetDay.format('ddd'),
    };
  });

  type IDays = {
    order: number;
    date: number;
    day: string;
    minTemp?: string;
    maxTemp?: string;
    sky: string;
    icon?: any;
  };
  const after6DaysLocalStorage = window.localStorage.getItem('after6Days');
  const currentWeatherLocalStorage = window.localStorage.getItem('currentWeatherW');
  const localAfter6Days = after6DaysLocalStorage ? JSON.parse(after6DaysLocalStorage) : days;
  const localCurrentWeather = currentWeatherLocalStorage ? JSON.parse(currentWeatherLocalStorage) : { icon: '', wsd: '', tmp: '', adrs: '', minTemp: '', maxTemp: '', rainPct: '', sky: '' };
  const [after6Days, setAfter6Days] = useState<IDays[]>(localAfter6Days);
  const [currentWeather, setCurrentWeather] = useState(localCurrentWeather);

  const siteInfoQuery = useQuery(['siteInfoGet2', userInfo, isRefreshTimeExpired], () => apiGet({ path: '/site/info', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && userInfo.sCd !== '00000',
  });

  const dayWeatherQuery = useQuery(['dayWeather', geoInfo, isRefreshTimeExpired], () => weatherDayGet({ latitude: geoInfo.latitude, longitude: geoInfo.longitude, address: geoInfo.address }), {
    retry: 3,
    enabled: !!geoInfo.latitude && !!geoInfo.longitude && !!geoInfo.address,
  });

  const weekWeatherQuery = useQuery(['weekWeather', geoInfo, isRefreshTimeExpired], () => weatherWeekGet({ latitude: geoInfo.latitude, longitude: geoInfo.longitude, address: geoInfo.address }), {
    retry: 3,
    enabled: !!geoInfo.latitude && !!geoInfo.longitude && !!geoInfo.address,
  });

  useEffect(() => {
    if (after6Days) {
      window.localStorage.setItem('after6Days', JSON.stringify(after6Days));
    }
    if (currentWeather) {
      window.localStorage.setItem('currentWeatherW', JSON.stringify(currentWeather));
    }
  }, [after6Days, currentWeather]);

  useEffect(() => {
    if (siteInfoQuery.isSuccess && siteInfoQuery.data) {
      if (siteInfoQuery.data.data.data) {
        const { siteInfo } = siteInfoQuery.data.data.data;
        setGeoInfo({ latitude: siteInfo.latitude, longitude: siteInfo.longitude, address: siteInfo.address1 });
        const shortAdd = siteInfo.address1.split(' ');
        setCurrentWeather((prev: any) => ({ ...prev, adrs: shortAdd[1] }));
      }
    }
  }, [siteInfoQuery.isSuccess, siteInfoQuery.isRefetching]);

  useEffect(() => {
    if (dayWeatherQuery.isSuccess && dayWeatherQuery.data) {
      if (dayWeatherQuery.data.data.data) {
        const { sky, tmp, wsd } = dayWeatherQuery.data.data.data;
        setCurrentWeather((prev: any) => ({ ...prev, tmp, wsd, sky }));
      }
    }
  }, [dayWeatherQuery.isSuccess, dayWeatherQuery.isRefetching, geoInfo]);

  useEffect(() => {
    if (weekWeatherQuery.isSuccess && weekWeatherQuery.data) {
      if (weekWeatherQuery.data.data.data) {
        const {
          morPop,
          todayTmn,
          todayTmx,
          aftPop,
          dinPop,
          weekSky1,
          weekSky2,
          weekSky3,
          weekSky4,
          weekSky5,
          weekSky6,
          weekTmn1,
          weekTmn2,
          weekTmn3,
          weekTmn4,
          weekTmn5,
          weekTmn6,
          weekTmx1,
          weekTmx2,
          weekTmx3,
          weekTmx4,
          weekTmx5,
          weekTmx6,
        } = weekWeatherQuery.data.data.data;

        const status = getTimeStatus();
        setCurrentWeather((prev: any) => ({ ...prev, minTemp: todayTmn, maxTemp: todayTmx, rainPct: status === 'morning' ? morPop : status === 'evening' ? dinPop : aftPop }));

        const copyArray = [...after6Days];
        copyArray.map((el: IDays, i: number) => {
          const targetDay = dayjs().add(i + 1, 'day'); // +1 to start from tomorrow
          if (el.order === 0) {
            copyArray[0] = {
              ...el,
              date: targetDay.date(),
              day: targetDay.format('ddd'),
              sky: weekSky1,
              minTemp: weekTmn1,
              maxTemp: weekTmx1,
              // icon: getWeatherIcon(weekSky1),
            };
          }
          if (el.order === 1) {
            copyArray[1] = {
              ...el,
              date: targetDay.date(),
              day: targetDay.format('ddd'),
              sky: weekSky2,
              minTemp: weekTmn2,
              maxTemp: weekTmx2,
              // icon: getWeatherIcon(weekSky2),
            };
          }
          if (el.order === 2) {
            copyArray[2] = {
              ...el,
              date: targetDay.date(),
              day: targetDay.format('ddd'),
              sky: weekSky3,
              minTemp: weekTmn3,
              maxTemp: weekTmx3,
              // icon: getWeatherIcon(weekSky3),
            };
          }
          if (el.order === 3) {
            copyArray[3] = {
              ...el,
              date: targetDay.date(),
              day: targetDay.format('ddd'),
              sky: weekSky4,
              minTemp: weekTmn4,
              maxTemp: weekTmx4,
              // icon: getWeatherIcon(weekSky4),
            };
          }
          if (el.order === 4) {
            copyArray[4] = {
              ...el,
              date: targetDay.date(),
              day: targetDay.format('ddd'),
              sky: weekSky5,
              minTemp: weekTmn5,
              maxTemp: weekTmx5,
              // icon: getWeatherIcon(weekSky5),
            };
          }
          if (el.order === 5) {
            copyArray[5] = {
              ...el,
              date: targetDay.date(),
              day: targetDay.format('ddd'),
              sky: weekSky6,
              minTemp: weekTmn6,
              maxTemp: weekTmx6,
              // icon: getWeatherIcon(weekSky6),
            };
          }
        });
        setAfter6Days(copyArray);
      }
    }
  }, [weekWeatherQuery.isSuccess, weekWeatherQuery.isRefetching, geoInfo]);

  const onClickRefresh = () => {
    dayWeatherQuery.refetch();
    weekWeatherQuery.refetch();
  };

  // if (
  //   !dayWeatherQuery.isSuccess ||
  //   dayWeatherQuery.data.status !== 200 ||
  //   dayWeatherQuery.isRefetching ||
  //   !weekWeatherQuery.isSuccess ||
  //   weekWeatherQuery.data.status !== 200 ||
  //   weekWeatherQuery.isRefetching
  // ) {
  //   return (
  //     <div className='centered-content'>
  //       <PulseLoader color='rgb(0, 122, 255)' size='10px' />
  //     </div>
  //   );
  // }

  return (
    <div className='weatherWeekInfo'>
      <div className='widget-header'>
        <div className='widget-title flex-between '>
          <span>{t('기상 정보')}</span>
        </div>
      </div>
      <div className='widget-body'>
        <div className='weatherToday notWeek' style={{ color: '#fff' }}>
          <div className='weatherInfo'>
            <div>
              {t('주간 날씨')} • {currentWeather.adrs}
            </div>
            <div className='weatherBox text'>
              <div>
                <dt>
                  {t('현재')} {currentWeather.tmp}℃
                </dt>
              </div>
              <div>
                <dt>
                  {t('최저')} {Math.floor(Number(currentWeather.minTemp))}℃{' '}
                </dt>
                <dt>
                  {t('최고')} {Math.floor(Number(currentWeather.maxTemp))}℃
                </dt>
              </div>
              <div>
                <dt>
                  {t('강수확률')} {currentWeather.rainPct}%
                </dt>
              </div>
            </div>
          </div>
          <div className='weatherAnimationWrapper'>{animateWeather(showSvg, currentWeather.sky)}</div>
        </div>
        <div className='weatherWeek'>
          {after6Days.map(({ date, day, maxTemp, minTemp, sky }: IDays) => {
            return (
              <div key={date}>
                <div className='date'>
                  <div>{t(day)}</div>
                  <div>{date}</div>
                </div>
                <div className='weatherWeekIcon'>{getWeatherIcon(sky)}</div>
                <div className='weatherWeekTemp'>
                  <div className='lowest'>{minTemp}℃</div>
                  <div className='highest'>{maxTemp}℃</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const TotalInfo = ({ name, userInfo, isRefreshTimeExpired }: any) => {
  dayjs.locale(i18n.language === 'en-US' ? 'en' : 'ko');
  const { t } = useTranslation();
  const todayjs = dayjs();
  const format = todayjs.format('YYYYMMDD');
  // const format2 = todayjs.format(i18n.language === 'en-US' ? `MMMM D dddd` : `MM월 DD일 dddd`);
  let format2 = todayjs.format(`MM월 DD일 dddd`);
  if (i18n.language === 'en-US') {
    format2 = todayjs.locale('en').format(`MMMM D dddd`);
  } else {
    format2 = todayjs.locale('ko').format(`MM월 DD일 dddd`);
  }
  const workerSearchObj = {
    wWorkstatus: 'I',
    wJobdate1: format,
    wJobdate2: format,
  };
  const attendSearchObj = {
    aDate1: format,
    aDate2: format,
  };
  const oldWorkerWearchObj = {
    wWorkstatus: 'I',
    oldDate1: '',
    oldDate2: String(Number(format) - Number(`${userInfo.elderlyAgeSet}0000`)),
  };

  const [info, setInfo] = useState({ newWorkerCnt: 0, todayAttendCnt: 0, todayOldWorkerAttendCnt: 0, todayNormalWorkerCnt: 0 });

  const newWorkerQuery = useQuery(['TotalInfoNewWorkerGet', userInfo, isRefreshTimeExpired], () => apiGet({ path: '/worker', req: { ...workerSearchObj, hCd: userInfo.hCd, sCd: userInfo.sCd } }));
  const attendQuery = useQuery(['attendCntGet', userInfo, isRefreshTimeExpired], () => apiGet({ path: '/attend/day', req: { ...attendSearchObj, hCd: userInfo.hCd, sCd: userInfo.sCd } }));
  const oldWorkerQuery = useQuery(['oldWorkerGet', userInfo, isRefreshTimeExpired], () => apiGet({ path: '/worker', req: { ...oldWorkerWearchObj, hCd: userInfo.hCd, sCd: userInfo.sCd } }));

  useEffect(() => {
    if (newWorkerQuery.isSuccess && newWorkerQuery.data) {
      setInfo((prev) => ({ ...prev, newWorkerCnt: newWorkerQuery.data.data.data.workerList.length }));
    }
  }, [newWorkerQuery.isSuccess, newWorkerQuery.isRefetching]);

  useEffect(() => {
    if (attendQuery.isSuccess && attendQuery.data) {
      if (attendQuery.data.data.data) {
        const { attendList } = attendQuery.data.data.data;

        // 금일출역 중 고령근로자 카운트
        const newArray = attendList.map((el: any) => {
          if (el.wBdate !== null) {
            const age = getAgeFromCertainDate(el.wBdate, todayjs);
            return { ...el, age };
          }
          return { ...el, age: 0 };
        });

        const oldWorkerArray = newArray.filter((el: any) => Number(userInfo.elderlyAgeSet <= el.age));

        setInfo((prev) => ({ ...prev, todayAttendCnt: attendList.length, todayOldWorkerAttendCnt: oldWorkerArray.length, todayNormalWorkerCnt: attendList.length - oldWorkerArray.length }));
      }
    }
  }, [attendQuery.isSuccess, attendQuery.isRefetching]);

  useEffect(() => {
    if (oldWorkerQuery.isSuccess && oldWorkerQuery.data) {
      const { workerList } = oldWorkerQuery.data?.data.data || [];
      const newArray = workerList?.filter((el: any) => el.wBdate !== null);

      setInfo((prev) => ({ ...prev, oldWorkerCnt: newArray.length }));
    }
  }, [oldWorkerQuery.isSuccess, oldWorkerQuery.isRefetching]);

  const onClickRefresh = () => {
    attendQuery.refetch();
    newWorkerQuery.refetch();
    oldWorkerQuery.refetch();
  };

  if (
    !newWorkerQuery.isSuccess ||
    newWorkerQuery.data.status !== 200 ||
    newWorkerQuery.isRefetching ||
    !attendQuery.isSuccess ||
    attendQuery.data.status !== 200 ||
    attendQuery.isRefetching ||
    !oldWorkerQuery.isSuccess ||
    oldWorkerQuery.data.status !== 200 ||
    oldWorkerQuery.isRefetching
  ) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <div className='totalInfo'>
      <div className='widget-header'>
        <div className='widget-title flex-between'>
          <span>{t(name)}</span>
        </div>
        <div className='widget-subTitle'>{format2}</div>
      </div>
      <div className='widget-body'>
        <div className='infoBox flex-basic'>
          <div className='red'>
            <span className='material-symbols-rounded'>person_add</span>
          </div>
          <div>
            <div>
              <span className='count'>{info.newWorkerCnt}</span>
              {t('명')}
            </div>
            {t('신규 근로자')}
          </div>
        </div>
        <div className='infoBox flex-basic'>
          <div className='blue'>
            <span className='material-symbols-rounded'>leaderboard</span>
          </div>
          <div>
            <div>
              <span className='count'>{info.todayAttendCnt}</span>
              {t('명')}
            </div>
            {t('금일 출역')}
          </div>
        </div>
        <div className='infoBox flex-basic'>
          <div className='orange'>
            <span className='material-symbols-rounded'>groups</span>{' '}
          </div>
          <div>
            <div>
              <span className='count'>{info.todayOldWorkerAttendCnt}</span>
              {t('명')}
            </div>
            {t('금일 고령 출역')}
          </div>
        </div>
        <div className='infoBox flex-basic'>
          <div className='green'>
            <span className='material-symbols-rounded'>group</span>{' '}
          </div>
          <div>
            <div>
              <span className='count'>{info.todayNormalWorkerCnt}</span>
              {t('명')}
            </div>
            {t('금일 일반 출역')}
          </div>
        </div>
      </div>
    </div>
  );
};

const settings = {
  dots: true,
  dotsClass: 'slick-dots slick-thumb',
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 30000,
};

const SiteNotice = ({ name, userInfo, isRefreshTimeExpired }: any) => {
  const { t } = useTranslation();
  const [siteNoteList, setSiteNoteList] = useState([]);

  const siteNoteBoardQuery = useQuery(['siteNoteBoardGet', userInfo, isRefreshTimeExpired], () => apiGet({ path: '/safe/communicatecontents', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }));
  useEffect(() => {
    if (siteNoteBoardQuery.isSuccess && siteNoteBoardQuery.data) {
      if (siteNoteBoardQuery.data.data.data) {
        const { communicateList } = siteNoteBoardQuery.data.data.data;
        setSiteNoteList(communicateList);
      }
    }
  }, [siteNoteBoardQuery.isSuccess, siteNoteBoardQuery.isRefetching]);

  if (!siteNoteBoardQuery.isSuccess || siteNoteBoardQuery.data.status !== 200 || siteNoteBoardQuery.isRefetching) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <div className='siteNote'>
      <div className='widget-header'>
        <div className='widget-title flex-between'>
          <span>{t(name)}</span>
        </div>
      </div>
      <div className='widget-body note'>
        <div className='sliderWrapper'>
          <Slider {...settings}>
            {siteNoteList.map((el: any, i: number) => (
              <div key={`${el.sortKey}_${i}`}>
                <div className='flex-between noteTitle'>
                  <div className='flex-start'>
                    <span className='titleBar' />
                    <span>{el.cTitle}</span>
                  </div>
                  <div>{el.cDate}</div>
                </div>
                <TextEditor content={el.cContent} readOnly />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

interface ISafeBoardT {
  sbdAction: string;
  sbdFontcolor: string;
  sbdName: string;
}

const SafetyMainPoint = ({ name, userInfo, isRefreshTimeExpired }: any) => {
  const colorMode = useRecoilValue(bScreenMode);
  const [combinedText, setCombinedText] = useState<any>();
  const [flowTime, setFlowTime] = useState<number>(0);
  const randomString = Math.random().toString(36).substring(7); // 랜덤 문자열을 쿼리스트링으로 추가해서 이미지 캐싱 방지

  const safetyMainPointBoardQuery = useQuery(['siteSafetyMainPointBoardGet', userInfo, isRefreshTimeExpired], () =>
    apiGet({ path: '/safe/boardcontents', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } })
  );
  useEffect(() => {
    if (safetyMainPointBoardQuery.isSuccess && safetyMainPointBoardQuery.data) {
      if (safetyMainPointBoardQuery.data.data.data) {
        const { safeBoardTList } = safetyMainPointBoardQuery.data.data.data;

        const marqueeContent = safeBoardTList.map((el: ISafeBoardT, index: number) => (
          <span key={index} className={el.sbdAction === 'Y' ? 'blink' : ''} style={{ color: el.sbdFontcolor }}>
            {el.sbdName} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
        ));

        setCombinedText(marqueeContent);

        const totalLengthWithoutSpaces = safeBoardTList
          .map((el: ISafeBoardT) => el.sbdName.replace(/\s+/g, ''))
          .reduce((sum: number, sbdNameWithoutSpaces: string) => sum + sbdNameWithoutSpaces.length, 0);

        const getFlowTime = () => {
          if (totalLengthWithoutSpaces < 10) return totalLengthWithoutSpaces * 1.5;
          if (totalLengthWithoutSpaces < 50) return totalLengthWithoutSpaces;
          if (totalLengthWithoutSpaces < 100) return totalLengthWithoutSpaces / 3;
          if (totalLengthWithoutSpaces < 200) return totalLengthWithoutSpaces / 2.5;
          return totalLengthWithoutSpaces / 2.5;
        };

        setFlowTime(getFlowTime());
      }
    }
  }, [safetyMainPointBoardQuery.isSuccess, safetyMainPointBoardQuery.isRefetching]);

  const headInfoQuery = useQuery(['headInfoGet', userInfo.hCd, isRefreshTimeExpired], () => apiGet({ path: '/head/info', req: { hCd: userInfo.hCd } }), {
    retry: 3,
  });
  const theme = colorMode === '1' ? 'light' : 'dark';
  const logo = useThemeLogo(headInfoQuery, theme);

  if (!safetyMainPointBoardQuery.isSuccess || safetyMainPointBoardQuery.data.status !== 200 || safetyMainPointBoardQuery.isRefetching) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <div className='safetyMainPoint'>
      <div className='content' style={{ fontSize: '3rem', display: 'flex' }}>
        <div className='logoWrapper'>
          <img src={logo !== '' ? `${logo}?v=${randomString}` : Itlog} alt='' />
        </div>
        <div className='marquee'>
          <div className='marquee-content' style={{ animation: `marquee ${flowTime}s linear infinite` }}>
            {combinedText}
          </div>
        </div>
      </div>
    </div>
  );
};

const ImageBoard = ({ name, userInfo, isRefreshTimeExpired }: any) => {
  const imageBoardSettings = {
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    // autoplaySpeed: 5000,
    autoplaySpeed: 30000,
  };

  const { t } = useTranslation();
  const [siteImageBoardList, setSiteImageBoardList] = useState<ISafeBoardT[]>([]);

  const siteImageBoardQuery = useQuery(['siteImageBoardGet', userInfo, isRefreshTimeExpired], () => apiGet({ path: '/safe/imageboardcontents', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }));

  useEffect(() => {
    if (siteImageBoardQuery.isSuccess && siteImageBoardQuery.data) {
      if (siteImageBoardQuery.data.data.data) {
        const { imageBoardTList } = siteImageBoardQuery.data.data.data;
        setSiteImageBoardList(imageBoardTList);
      }
    }
  }, [siteImageBoardQuery.isSuccess, siteImageBoardQuery.isRefetching]);

  if (!siteImageBoardQuery.isSuccess || siteImageBoardQuery.data.status !== 200 || siteImageBoardQuery.isRefetching) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <div className='imageBoard'>
      <div className='widget-header'>
        <div className='widget-title flex-between '>
          <span>{t(name)}</span>
        </div>
      </div>
      <div className='content'>
        <Slider {...imageBoardSettings}>
          {siteImageBoardList.map((el: any, i: number) => (
            <img key={`${i}`} src={el.iFilename} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

interface ISafetyViolation {
  blackCount: string;
  pointCount: string;
}

const SafetyViolationInfo = ({ name, userInfo, isRefreshTimeExpired }: any) => {
  const { t } = useTranslation();
  const yesterday = dayjs().subtract(1, 'day');
  // dayjs.locale(i18n.language === 'en-US' ? 'en' : 'ko');
  // const format = yesterday.format(i18n.language === 'en-US' ? `MMMM D dddd` : `MM월 DD일 dddd`);
  let format = yesterday.format(`MM월 DD일 dddd`);
  if (i18n.language === 'en-US') {
    format = yesterday.locale('en').format(`MMMM D dddd`);
  } else {
    format = yesterday.locale('ko').format(`MM월 DD일 dddd`);
  }
  const [safetyViolatWorkerCount, setSafetyViolatWorkerCount] = useState<ISafetyViolation | null>(null);

  const safetyViolatWorkerQuery = useQuery(['safetyViolationGet', userInfo, isRefreshTimeExpired], () => apiGet({ path: '/safe/pointblackcontents', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }));

  useEffect(() => {
    if (safetyViolatWorkerQuery.isSuccess && safetyViolatWorkerQuery.data) {
      if (safetyViolatWorkerQuery.data.data.data) {
        const { blackCount, pointCount } = safetyViolatWorkerQuery.data.data.data[0];
        setSafetyViolatWorkerCount({ blackCount, pointCount });
      }
    }
  }, [safetyViolatWorkerQuery.isSuccess, safetyViolatWorkerQuery.isRefetching]);

  if (!safetyViolatWorkerQuery.isSuccess || safetyViolatWorkerQuery.data.status !== 200 || safetyViolatWorkerQuery.isRefetching) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <div className='safetyViolationInfo'>
      <div className='widget-header'>
        <div className='widget-title flex-between'>
          <span>{t(name)}</span>
        </div>
        <div className='widget-subTitle'>
          {format} ({t('전일')})
        </div>
      </div>
      <div className='widget-body safety'>
        <div className='box flex-basic'>
          <div className='orange'>
            <span className='material-symbols-rounded'>warning</span>
            {/* <IoWarning size={30} /> */}
          </div>
          <div>
            <div>
              <span>{safetyViolatWorkerCount?.pointCount} </span>
              {t('명')}
            </div>
            {t('안전 지적')}
          </div>
        </div>
        <div className='box flex-basic'>
          <div className='red'>
            <span className='material-symbols-rounded'>block</span>
            {/* <IoBan size={30} /> */}
          </div>
          <div>
            <div>
              <span>{safetyViolatWorkerCount?.blackCount} </span>
              {t('명')}
            </div>
            {t('출입 금지')}
          </div>
        </div>
      </div>
    </div>
  );
};

interface IAccidentInfo {
  accidentSdate: string;
  accidentEdate: string;
  accidentTday: string;
  accidentAday: string;
  accidentRday: string;
}

const AccidentInfo = ({ name, userInfo, isRefreshTimeExpired }: any) => {
  const { t } = useTranslation();
  const [accidentInfo, setAccidentInfo] = useState<IAccidentInfo>();

  const accidentInfoQuery = useQuery(['safeAccidentmStatusGet', userInfo, isRefreshTimeExpired], () => apiGet({ path: '/safe/accidentm/status', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }));

  useEffect(() => {
    if (accidentInfoQuery.isSuccess && accidentInfoQuery.data) {
      if (accidentInfoQuery.data.data.data) {
        const { accidentAday, accidentEdate, accidentRday, accidentSdate, accidentTday } = accidentInfoQuery.data.data.data;
        setAccidentInfo({ accidentAday, accidentEdate, accidentRday, accidentSdate, accidentTday });
      }
    }
  }, [accidentInfoQuery.isSuccess, accidentInfoQuery.isRefetching]);

  if (!accidentInfoQuery.isSuccess || accidentInfoQuery.data.status !== 200 || accidentInfoQuery.isRefetching) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  if (!accidentInfoQuery.isSuccess || accidentInfoQuery.data.status !== 200 || accidentInfoQuery.isRefetching || !accidentInfo) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <div className='accidentInfo'>
      <div className='widget-header'>
        <div className='widget-title flex-between'>
          <span>{t(name)}</span>
        </div>
      </div>
      <div className='widget-body donut'>
        <div className='doughnutChartWrapper'>
          {accidentInfo && (
            <DoughnutChart
              showLegend={false}
              percentage={[Number(accidentInfo?.accidentAday), Number(accidentInfo?.accidentRday)]}
              label={[t('달성 일 수'), t('남은 일 수')]}
              centerText={false}
              colorSet={['#3E97FF', '#3e98ff30']}
              borderStatus={false}
            />
          )}
        </div>
        <div className='content'>
          <dl className='flex-between'>
            <dd>
              {accidentInfo && formatDateYMD(accidentInfo?.accidentSdate)} ~ {accidentInfo && formatDateYMD(accidentInfo?.accidentEdate)}
            </dd>
          </dl>
          <dl className='flex-between'>
            <dt className='flex-basic'>
              <div className='dot'>
                <div />
              </div>
              {t('목표 일 수')}
            </dt>
            <dd className='flex-basic'>
              <span>{accidentInfo?.accidentTday}</span>
              {t('일')}
            </dd>
          </dl>
          <dl className='flex-between'>
            <dt className='flex-basic'>
              <div className='fill' />
              {t('달성 일 수')}
            </dt>
            <dd className='flex-basic'>
              <span>{accidentInfo?.accidentAday}</span>
              {t('일')}
            </dd>
          </dl>
          <dl className='flex-between'>
            <dt className='flex-basic'>
              <div className='empty' />
              {t('남은 일 수')}
            </dt>
            <dd className='flex-basic'>
              <span>{accidentInfo?.accidentRday}</span>
              {t('일')}
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
};

interface IBlack {
  bSeq: number;
  wCd: string;
  wName: string;
  sjCd: string;
  sjName: string;
  bMemo: string;
  wImg: string;
}

interface IPoint {
  sSeq: number;
  sPointseq: number;
  wCd: string;
  wName: string;
  sjCd: string;
  sjName: string;
  sMemo: string;
  pointCount: string;
  wImg: string;
}

const SafetyViolationInfoDetail = ({ name, userInfo, isRefreshTimeExpired }: any) => {
  const { t } = useTranslation();
  const yesterday = dayjs().subtract(1, 'day');
  // const format = yesterday.format(i18n.language === 'en-US' ? `MMMM D dddd` : `MM월 DD일 dddd`);
  let format = yesterday.format(`MM월 DD일 dddd`);
  if (i18n.language === 'en-US') {
    format = yesterday.locale('en').format(`MMMM D dddd`);
  } else {
    format = yesterday.locale('ko').format(`MM월 DD일 dddd`);
  }
  const [blackList, setBlackList] = useState<IBlack[]>([]);
  const [pointList, setPointList] = useState<IPoint[]>([]);
  const [sliderTabIndex, setSliderTabIndex] = useState<number>(0);
  const sliderArray = [0, 1]; // 0: 안전지적, 1: 출입금지

  const safePointQuery = useQuery(['safePointcontentsGet', userInfo, isRefreshTimeExpired], () => apiGet({ path: '/safe/pointcontents', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }));
  const safeBlackQuery = useQuery(['safeBlackcontentsGet', userInfo, isRefreshTimeExpired], () => apiGet({ path: '/safe/blackcontents', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }));

  useEffect(() => {
    if (safePointQuery.isSuccess && safePointQuery.data) {
      setPointList(safePointQuery.data.data.data);
    }
  }, [safePointQuery.isSuccess, safePointQuery.isRefetching]);

  useEffect(() => {
    if (safeBlackQuery.isSuccess && safeBlackQuery.data) {
      setBlackList(safeBlackQuery.data.data.data);
    }
  }, [safeBlackQuery.isSuccess, safeBlackQuery.isRefetching]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSliderTabIndex((prev) => (prev + 1) % sliderArray.length);
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  if (
    !safePointQuery.isSuccess ||
    safePointQuery.data.status !== 200 ||
    safePointQuery.isRefetching ||
    !safeBlackQuery.isSuccess ||
    safeBlackQuery.data.status !== 200 ||
    safeBlackQuery.isRefetching
  ) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <div className='safetyViolationInfoDetail'>
      <div className='widget-header safety'>
        <div className='widget-title flex-between'>
          <span>{t(name)}</span>
        </div>
        <div className='widget-subTitle'>
          {format} ({t('전일')})
        </div>
      </div>
      <div className='widget-body photoTable'>
        <div className='innerTab nonClick'>
          <div className={sliderTabIndex === 0 ? 'active' : undefined}>
            {t('안전 지적')} ({pointList.length})<div />
          </div>
          <div className={sliderTabIndex === 1 ? 'active' : undefined}>
            {t('출입 금지')} ({blackList.length})<div />
          </div>
        </div>
        <div className='sliderWrapper'>
          {sliderArray.map((el: number) => (
            <div
              key={`${el}_${sliderTabIndex}`}
              style={{ animation: `slide ${sliderTabIndex === 0 ? pointList.length * 2 : blackList.length * 2}s linear infinite` }}
              className={sliderTabIndex === 0 ? (pointList.length > 3 ? 'text-container active' : undefined) : blackList.length > 3 ? 'text-container active' : undefined}
            >
              {sliderTabIndex === 0
                ? pointList.map((pEl: IPoint) => (
                    <div key={`${pEl.sSeq}_${pEl.sPointseq}`} className='rowWrapper flex-basic'>
                      <div className='flex-basic'>
                        <div className='imgWrapper'>{pEl.wImg ? <img src={pEl.wImg} alt='' /> : <span className='material-symbols-rounded'>person </span>}</div>
                        <div>
                          <div>
                            <span className='' style={{ paddingRight: '0.5rem' }}>
                              {pEl.wName}
                            </span>
                            <span className='bold'>{pEl.sjName}</span>
                          </div>
                          <div className='rowContent' style={{ maxWidth: '22rem' }}>
                            {pEl.sMemo}
                          </div>
                        </div>
                      </div>
                      <div className='buttonsWrapper flex-basic'>
                        <button type='button' className={Number(pEl.pointCount) === 1 ? 'amber' : 'red'}>
                          <span>{pEl.pointCount}</span>
                          {t('건')}
                        </button>
                      </div>
                    </div>
                  ))
                : blackList.map((bEl: IBlack) => (
                    <div key={`${bEl.bSeq}`} className='rowWrapper flex-between'>
                      <div className='flex-basic'>
                        <div className='imgWrapper'>{bEl.wImg ? <img src={bEl.wImg} alt='' /> : <span className='material-symbols-rounded'>person </span>}</div>
                        <div className='rowContent' style={{ maxWidth: '28rem' }}>
                          <div>
                            <span className='' style={{ paddingRight: '1rem' }}>
                              {bEl.wName}
                            </span>
                            <span className=''>{bEl.sjName}</span>
                          </div>
                          <div className='rowContent'>{bEl.bMemo}</div>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const NoxiousGas = ({ name, userInfo, isRefreshTimeExpired }: any) => {
  const { t } = useTranslation();
  const equipQuery = useQuery(['equipInfoGet', userInfo, isRefreshTimeExpired], () => apiGet({ path: '/setting/faceSet', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }));

  const dummyData = [
    { name: '시작부_1', gas1: 0.7, gas2: 0.6, gas3: 18.2, gas4: 16.2, gas5: 16.2, gas6: 0.8, point: 0 },
    { name: '시작부_2', gas1: 0.8, gas2: 16.2, gas3: 23.4, gas4: 16.2, gas5: 1.6, gas6: 56.7, point: 0 },
    { name: '종점부_1', gas1: 16.2, gas2: 16.2, gas3: 0.2, gas4: 16.6, gas5: 16.2, gas6: 13.9, point: 1 },
    { name: '종점부_2', gas1: 16.3, gas2: 86.4, gas3: 21.8, gas4: 14.1, gas5: 73.1, gas6: 0.3, point: 1 },
    { name: '종점부_3', gas1: 0.7, gas2: 0.6, gas3: 18.2, gas4: 16.2, gas5: 16.2, gas6: 0.8, point: 1 },
    { name: '종점부_4', gas1: 0.8, gas2: 16.2, gas3: 23.4, gas4: 16.2, gas5: 1.6, gas6: 56.7, point: 1 },
  ];

  if (!equipQuery.isSuccess || equipQuery.data.status !== 200 || equipQuery.isRefetching) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <div className='noxiousGas'>
      <div className='widget-header margin-top'>
        <div className='widget-title flex-between innerBtn'>
          <span>{t(name)}</span>
          <div className='labelSet flex-end'>
            <span className='textBox orange'>{t('주의')}</span>
            <span className='textBox red'>{t('위험')}</span>
            <span className='textBox violet'>{t('경보')}</span>
          </div>
        </div>
      </div>
      <div>
        <div className='widget-body table' style={{ width: '100%' }}>
          <InputTable>
            <div className='thead'>
              <div className='tr'>
                <div className='flex-center col-w28 doubleLine'>{t('굴진명')}</div>
                <div className='flex-center col-w12 doubleLine'>{t('산소')}</div>
                <div className='flex-center col-w12 doubleLine'>{t('일산화탄소')}</div>
                <div className='flex-center col-w12 doubleLine'>{t('중화 수소')}</div>
                <div className='flex-center col-w12 doubleLine'>{t('가연성가스')}</div>
                <div className='flex-center col-w12 doubleLine'>{t('이산화탄소')}</div>
                <div className='flex-center col-w12 doubleLine'>{t('이산화질소')}</div>
              </div>
            </div>
            <div className='table' style={{ overflow: 'hidden' }}>
              <div className='tbody' style={{ animation: `slide ${dummyData.length * 2}s linear infinite` }}>
                {dummyData?.length > 0 ? (
                  dummyData.map((el: any, i: number) => (
                    <div className='tr' key={`equipInfo_${i}`}>
                      <div className='flex-center col-w28'>
                        {el.name}
                        <span className={`textBox ${el.point === 0 ? 'blue' : 'slate'}`}>{el.point === 0 ? t('시점') : t('종점')}</span>
                      </div>
                      <div className='flex-center col-w12'>
                        <span className={`textBox ${el.gas1 <= 10 ? 'green' : el.gas1 <= 19.9 ? 'orange' : el.gas1 >= 29.9 ? 'red' : 'violet'}`}>{el.gas1}</span>
                      </div>
                      <div className='flex-center col-w12'>
                        <span className={`textBox ${el.gas2 <= 10 ? 'green' : el.gas2 <= 19.9 ? 'orange' : el.gas2 >= 29.9 ? 'red' : 'violet'}`}>{el.gas2}</span>
                      </div>
                      <div className='flex-center col-w12'>
                        <span className={`textBox ${el.gas3 <= 10 ? 'green' : el.gas3 <= 19.9 ? 'orange' : el.gas3 >= 29.9 ? 'red' : 'violet'}`}>{el.gas3}</span>
                      </div>
                      <div className='flex-center col-w12'>
                        <span className={`textBox ${el.gas4 <= 10 ? 'green' : el.gas4 <= 19.9 ? 'orange' : el.gas4 >= 29.9 ? 'red' : 'violet'}`}>{el.gas4}</span>
                      </div>
                      <div className='flex-center col-w12'>
                        <span className={`textBox ${el.gas5 <= 10 ? 'green' : el.gas5 <= 19.9 ? 'orange' : el.gas5 >= 29.9 ? 'red' : 'violet'}`}>{el.gas5}</span>
                      </div>
                      <div className='flex-center col-w12'>
                        <span className={`textBox ${el.gas6 <= 10 ? 'green' : el.gas6 <= 19.9 ? 'orange' : el.gas6 >= 29.9 ? 'red' : 'violet'}`}>{el.gas6}</span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='noData'>No data.</div>
                )}
              </div>
            </div>
          </InputTable>
        </div>
      </div>
    </div>
  );
};

const ExcavationProcess = ({ name, userInfo, isRefreshTimeExpired }: any) => {
  const { t } = useTranslation();
  const equipQuery = useQuery(['equipInfoGet', userInfo, isRefreshTimeExpired], () => apiGet({ path: '/setting/faceSet', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }));

  const dummyData = [
    { name: '시작부_1', point: 0, value: 65 },
    { name: '시작부_2', point: 0, value: 46 },
    { name: '종점부_1', point: 1, value: 73 },
    { name: '종점부_2', point: 1, value: 36 },
    { name: '종점부_3', point: 1, value: 25 },
    { name: '종점부_4', point: 1, value: 12 },
  ];

  if (!equipQuery.isSuccess || equipQuery.data.status !== 200 || equipQuery.isRefetching) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }
  return (
    <div className='equipInfo excavationProcess'>
      <div className='widget-header margin-top'>
        <div className='widget-title flex-between'>
          <span>{t(name)}</span>
        </div>
      </div>
      <div className='widget-body table' style={{ width: '100%' }}>
        <InputTable>
          <div className='thead'>
            <div className='tr'>
              <div className='flex-center col-w40'>{t('굴진명')}</div>
              <div className='flex-center col-w60'>{t('진행률')}</div>
            </div>
          </div>
          <div className='table' style={{ overflow: 'hidden' }}>
            <div className='tbody' style={{ animation: `slide ${dummyData.length * 2}s linear infinite` }}>
              {dummyData?.length > 0 ? (
                dummyData.map((el: any, i: number) => (
                  <div className='tr' key={`equipInfo_${i}`}>
                    <div className='flex-center col-w40'>
                      {el.name} <span className={`textBox ${el.point === 0 ? 'blue' : 'slate'}`}>{el.point === 0 ? t('시점') : t('종점')}</span>
                    </div>
                    <div className='col-w60 progress-container'>
                      <progress id={`equipInfo_${i}`} value={el.value} max='100' />
                      <div className='progressValue' style={{ transform: `${el.value >= 12 ? `translateX(${(el.value - 8) / 2}%)` : ''}` }}>
                        {el.value}%
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className='noData'>No data.</div>
              )}
            </div>
          </div>
        </InputTable>
      </div>
    </div>
  );
};

const ExcavationWorkerPosition = ({ name, userInfo, isRefreshTimeExpired }: any) => {
  const { t } = useTranslation();
  const equipQuery = useQuery(['equipInfoGet', userInfo, isRefreshTimeExpired], () => apiGet({ path: '/setting/faceSet', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }));

  const dummyData = [
    { name: '시작부_1', value: 5, point: 0 },
    { name: '시작부_2', value: 16, point: 0 },
    { name: '종점부_1', value: 23, point: 1 },
    { name: '종점부_2', value: 18, point: 1 },
    { name: '종점부_3', value: 25, point: 1 },
    { name: '종점부_4', value: 1, point: 1 },
  ];

  if (!equipQuery.isSuccess || equipQuery.data.status !== 200 || equipQuery.isRefetching) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }
  return (
    <div className='equipInfo excavationProcess'>
      <div className='widget-header margin-top'>
        <div className='widget-title flex-between'>
          <span>{t(name)}</span>
        </div>
      </div>
      <div className='widget-body table' style={{ width: '100%' }}>
        <InputTable>
          <div className='thead'>
            <div className='tr'>
              <div className='flex-center col-w40'>{t('굴진명')}</div>
              <div className='flex-center col-w60'>{t('근로자 인원')}</div>
            </div>
          </div>
          <div className='table' style={{ overflow: 'hidden' }}>
            <div className='tbody' style={{ animation: `slide ${dummyData.length * 2}s linear infinite` }}>
              {dummyData?.length > 0 ? (
                dummyData.map((el: any, i: number) => (
                  <div className='tr' key={`equipInfo_${i}`}>
                    <div className='flex-center col-w40'>
                      {el.name}
                      <span className={`textBox ${el.point === 0 ? 'blue' : 'slate'}`}>{el.point === 0 ? t('시점') : t('종점')}</span>
                    </div>
                    <div className='flex-center col-w60'>{el.value}</div>
                  </div>
                ))
              ) : (
                <div className='noData'>No data.</div>
              )}
            </div>
          </div>
        </InputTable>
      </div>
    </div>
  );
};
