import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &.flex-grow {
    flex-grow: 1;
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.padding-05 {
    padding: 0 0.5rem;
  }
`;

type Props = {
  button: React.ReactNode;
  onClick: (e: React.MouseEvent<any>) => void;
  disabled?: boolean;
  flexGrow?: boolean;
  className?: string;
};

const ClickableContainer = ({ button, onClick, disabled = false, flexGrow = false, className = '' }: Props) => {
  return (
    <Root className={`${disabled ? 'disabled' : ''} ${flexGrow ? 'flex-grow' : ''} ${className}`} role='button' tabIndex={0} onClick={onClick}>
      {button}
    </Root>
  );
};

export default ClickableContainer;
