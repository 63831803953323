/**
 * 작성자 : 홍선영
 * 날짜 : 2023.06.08
 * 경로 : 설정관리-장비관리 페이지 (현장관리자)
 */

import styled from 'styled-components';
import Tab from '../../components/Tab';
import { useOutletContext } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IUser, pageInfoState, userState } from '../../atoms';
import { useEffect, useState } from 'react';
import { ITabObject } from 'customTypes';
import SensorSetting from './setting5/sensorSetting';
import EquipSetting from './setting5/equipSetting';
import SensorRangeSetting from './setting5/sensorRangeSetting';
import BeaconSetting from './setting5/beaconSetting';
import { logPost } from '../../services/log';

const Root = styled.div``;

const SMSetting5 = () => {
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  const [userInfo, setUserInfo] = useRecoilState<IUser>(userState);
  const { userMenuList } = userInfo;
  const [newTabList, setNewTabList] = useState<ITabObject[]>();

  useEffect(() => {
    const arr: any[] = [];
    userMenuList.map((v: any) => {
      const result = tabInfo?.tabList?.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  const onClickTab = (mrCd: string) => {
    setCurrentTabMrCd(mrCd);
  };

  const renderPage = () => {
    const activeTab = userMenuList.find((el: ITabObject) => el.mrCd === currentTabMrCd);
    if (activeTab !== undefined) {
      const { mrCd } = activeTab;
      switch (mrCd) {
        case '001':
          return (
            <div className='content-container oneColumn'>
              <EquipSetting />
            </div>
          );
        case '002':
          return <SensorSetting size={size} />;
        case '003':
          return (
            <div className='content-container oneColumn'>
              <SensorRangeSetting />
            </div>
          );
        case '004':
          return <BeaconSetting />;
      }
    }
    return undefined;
  };

  return (
    <div className='contents'>
      <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      {renderPage()}
    </div>
  );
};

export default SMSetting5;
