import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { PulseLoader } from 'react-spinners';
import { InputTable } from '../../assets/styles/InputTable';
import { apiGet } from '../../services/_common';

// 근로자 위치 현황
export const ExcavationWorkerPosition = ({ name, userInfo }: any) => {
  const { t } = useTranslation();
  const equipQuery = useQuery(['equipInfoGet', userInfo], () => apiGet({ path: '/setting/faceSet', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }), { enabled: userInfo.sCd !== '00000' });

  const dummyData = [
    { name: '시작부_1', value: 5, point: 0 },
    { name: '시작부_2', value: 16, point: 0 },
    { name: '종점부_1', value: 23, point: 1 },
    { name: '종점부_2', value: 18, point: 1 },
    { name: '종점부_3', value: 25, point: 1 },
    { name: '종점부_4', value: 1, point: 1 },
  ];

  const onClickRefresh = () => {
    equipQuery.refetch();
  };

  if (!equipQuery.isSuccess || equipQuery.data.status !== 200 || equipQuery.isRefetching) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }
  return (
    <div className='equipInfo excavationProcess'>
      <div className='widget-header margin-top'>
        <div className='widget-title flex-between'>
          <span>{t(name)}</span>
          <div className='refresh-btn' onClick={onClickRefresh} role='presentation'>
            <span className='material-symbols-rounded'>refresh</span>
          </div>
        </div>
      </div>
      <div className='widget-body table'>
        <InputTable>
          <div className='thead'>
            <div className='tr'>
              <div className='flex-center col-w40'>{t('굴진명')}</div>
              <div className='flex-center col-w60'>{t('근로자 인원')}</div>
            </div>
          </div>
          <div className='table' style={{ overflowX: 'hidden' }}>
            <div className='tbody'>
              {dummyData?.length > 0 ? (
                dummyData.map((el: any, i: number) => (
                  <div className='tr' key={`equipInfo_${i}`}>
                    <div className='flex-center col-w40'>
                      {el.name}
                      <span className={`textBox ${el.point === 0 ? 'blue' : 'slate'}`}>{el.point === 0 ? t('시점') : t('종점')}</span>
                    </div>
                    <div className='flex-center col-w60'>{el.value}</div>
                  </div>
                ))
              ) : (
                <div className='noData'>No data.</div>
              )}
            </div>
          </div>
        </InputTable>
      </div>
    </div>
  );
};
