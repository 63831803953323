/**
 * 작성자 : 김광민
 * 날짜 : 2024.02.13
 * 기능 : 터널 관리 > 방송 장비 정보
 */

import styled from 'styled-components';
import { Dispatch, SetStateAction } from 'react';
import BroadcastButton from '../button/BroadcastButton';
import CheckBoxCircle from '../button/CheckBoxCircle';

const Root = styled.div`
  display: flex;
  width: 100%;
  user-select: none;
  .checkbox {
    width: 4rem;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 0.75rem 0;
    .title {
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      .titleName {
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      }
      .titleCount {
        color: ${({ theme }: { theme: any }) => theme.text_disabled};
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        padding: 0 0.5rem;
        border-radius: 0.25rem;
        min-width: 1.75rem;
        text-align: center;
        margin-right: 0.5rem;
        height: 1.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .description {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    .seperator {
      height: 1rem;
      border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    }
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 3rem;
      width: fit-content;
      flex-shrink: 0;
      height: 1.75rem;
      border-radius: 0.25rem;
      font-size: 0.75rem;
      word-break: keep-all;
      gap: 0.5rem;
      padding: 0 0.375rem;
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      .material-symbols-rounded {
        font-variation-settings: 'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 24;
        font-size: 1.125rem;
      }
      &.camera {
        .material-symbols-rounded {
          font-size: 1rem;
          padding: 1px;
        }
      }
    }
  }
  .broadcast {
    height: 100%;
    width: 4rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

type Props = {
  item: EquipList;
  index: number;
  onClickRow: (el: any) => void;
  handleCheckboxChange: (i: number, el: any, tableStateS: any, setTableStateS: any, allCheckBoxsS: any, setAllCheckBoxsS: any) => void;
  equipList: EquipList[];
  setEquipList: Dispatch<SetStateAction<EquipList[]>>;
  isCheckAll: boolean;
  setIsCheckAll: Dispatch<SetStateAction<boolean>>;
};

const EquipListItem = ({ item, index, onClickRow, handleCheckboxChange, equipList, setEquipList, isCheckAll, setIsCheckAll }: Props) => {
  const handleRootClick = () => {
    handleCheckboxChange(index, item, equipList, setEquipList, isCheckAll, setIsCheckAll);
  };

  return (
    <Root role='button' tabIndex={0} onClick={handleRootClick}>
      <div className='checkbox'>
        <CheckBoxCircle checked={item.checked} onClick={handleRootClick} scale />
      </div>
      <div className='body'>
        <div className='title'>
          <span className='titleCount'>{index + 1}</span>
          <span className='titleName'>{item.tadName}</span>
        </div>
        <div className='description'>
          <div className='broadcast'>
            <span className='material-symbols-rounded'>volume_up</span>
            <span className='equipmentName'>{item.bsName}</span>
          </div>
          <span className='seperator' />
          <div className='camera'>
            <span className='material-symbols-rounded'>nest_cam_iq_outdoor</span>
            <span className='cameraName'>{item.cName}</span>
          </div>
        </div>
      </div>
      <div className='broadcast'>
        <BroadcastButton onClick={() => onClickRow(item)} />
      </div>
    </Root>
  );
};

export default EquipListItem;
