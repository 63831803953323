/**
 * 작성자 : 홍선영
 * 날짜 : 2024.03.22
 * 경로 : 현장 CCTV - CCTV 실시간 보기
 */
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { IModal } from 'customTypes';
import { localCctvSettingState, mouseDownIndexState, userState } from '../../atoms';
import { CCTV_PLAY_SIDE_MENU_WIDTH, CCTV_REALTIME_BOTTOM_CONTROL_HEIGHT } from '../../_constants';
import Portal from '../../components/Portal';
import LeftedToast from '../../components/cctv/LeftedToast';
import WebSdkModal from '../../components/Modal/WebSdkModal';
import BottomMenu from './S_cctv1/RealtimeCCTV/BottomMenu';
import { CameraStatus, WebSDKcctv } from '../../utils/webSDKcctvClass';
import { clickOpenDirectory } from '../../utils/cctv';
import { isCamInCurrentGrid } from '../../utils/isCamInCurrentGrid';
import { nvrListOverwrite } from '../../utils/nvrListOverwrite';
import { logPost } from '../../services/log';
import { PulseLoader } from 'react-spinners';

const Root = styled.div`
  flex: 1 0 50%;
  .cctvWrapper {
    height: calc(100vh - 3rem);
    position: relative;
  }
`;

const Loader = styled.div`
  position: absolute;
  width: 85%;
  height: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  min-height: 6.5rem;
  margin-bottom: 0.75rem;
  &.siteDashboard {
    min-height: 8rem;
  }
`;

type Props = {
  draggingCamInfo: any;
  setDraggingCamInfo: any;
  droppedWndIndex: number | null;
  isLoading: boolean;
  setDroppedWndIndex: Dispatch<SetStateAction<number | null>>;
  cctvOBJ: WebSDKcctv | undefined;
  setCctvOBJ: Dispatch<SetStateAction<WebSDKcctv | undefined>>;
  cameraInfo: any;
  setIsDragging: Dispatch<SetStateAction<boolean>>;
  visiblePanel: boolean;
  setVisiblePanel: Dispatch<SetStateAction<boolean>>;
  nvrList: IMainMenu[];
  setNvrList: Dispatch<SetStateAction<IMainMenu[]>>;
  cctvSettings: CctvSettings;
  bookmarkedCams: IMainMenu[] | string;
  setBookmarkedCams: Dispatch<SetStateAction<IMainMenu[] | string>>;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  selectedCameraInfo: SelectedCctvCameraInfo | null;
  setSelectedCameraInfo: Dispatch<SetStateAction<SelectedCctvCameraInfo | null>>;
  isRecording: boolean;
  setIsRecording: Dispatch<SetStateAction<boolean>>;
  cctvDivisions: number;
  setCctvDivisions: Dispatch<SetStateAction<number>>;
  playingCamStatus: any[];
  setPlayingCamStatus: Dispatch<SetStateAction<any[]>>;
  sdkInitLoading: boolean;
  setSdkInitLoading: Dispatch<SetStateAction<boolean>>;
  camPlayLoading: boolean;
  setCamPlayLoading: Dispatch<SetStateAction<boolean>>;
  integrationCamList: any;
};

const WebSdkRealtime = ({
  isLoading,
  draggingCamInfo, // 드래그한 카메라 객체 정보
  setDraggingCamInfo, // 드래그한 카메라 객체 정보 변경 함수
  droppedWndIndex, // 드래그한 카메라 객체를 드랍한 윈도우 인덱스
  setDroppedWndIndex, // 드래그한 카메라 객체를 드랍한 윈도우 인덱스 변경 함수
  cctvOBJ, // cctv 객체
  setCctvOBJ, // cctv 객체 변경 함수
  cameraInfo,
  nvrList,
  setNvrList,
  setIsDragging, // 드래그 상태 변경 함수
  visiblePanel, // 왼쪽 사이드 패널 접기/펼치기 상태
  setVisiblePanel, // 왼쪽 사이드 패널 상태 변경 함수
  cctvSettings, // cctv 사용자세팅값
  bookmarkedCams, // 선택한 즐겨찾기의 카메라 목록
  setBookmarkedCams,
  currentPage, // 사용자가 보고있는 현재 페이지
  setCurrentPage, // 현재 페이지 setState
  selectedCameraInfo,
  setSelectedCameraInfo,
  isRecording,
  setIsRecording,
  cctvDivisions,
  setCctvDivisions,
  playingCamStatus,
  setPlayingCamStatus,
  sdkInitLoading,
  setSdkInitLoading,
  camPlayLoading,
  setCamPlayLoading,
  integrationCamList,
}: Props) => {
  const userInfo = useRecoilValue(userState);
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const [selectWndIndex, setSelectWndIndex] = useRecoilState(mouseDownIndexState);
  const [initCamPlayLoading, setInitCamPlayLoading] = useState(true);
  const [websdkInitChk, setWebsdkInitChk] = useState(false);
  const [localCctvSettings] = useRecoilState(localCctvSettingState);
  // const [beforeCctvDivisions, setBeforeCctvDivisions] = useState<number>(4);
  const [isChangeDivisions, setIsChangeDivisions] = useState(false);
  const [ptzControll, setPtzControll] = useState<string>();
  const [beforePtzControll, setBeforePtzControll] = useState<string>();
  const grid = cctvDivisions * cctvDivisions;
  const divPlugin = 'divPlugin';

  // 현재 페이지의 카메라 스트림을 변경하는 함수
  const onClickSetStream = async (value: '1' | '2' | '3') => {
    if (playingCamStatus) {
      const currentPlayingCamStatus = playingCamStatus.filter((el: any) => isCamInCurrentGrid(el.windowIndex, cctvDivisions, currentPage) && el.windowIndex !== null);
      await cctvOBJ?.changeStreamAll(currentPlayingCamStatus, Number(value));
      setCamPlayLoading(false);
    }

    if (nvrList.length > 0) {
      const newCamList = nvrList //
        .map((cameraGroup: any) => ({
          ...cameraGroup,
          subList: cameraGroup.subList //
            .map((camera: any) => {
              const condition = camera.isPlaying && isCamInCurrentGrid(camera.windowIndex, cctvDivisions, currentPage);
              if (condition) {
                return { ...camera, stream: value };
              }
              return { ...camera };
            }),
        }));
      setNvrList(newCamList);

      logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: 'CCTV 실시간 보기-인프라용',
        action: `모든 재생 카메라 스트림 변경`,
        etc: `${value === '1' ? '메인' : '서브'}`,
      });
    }
  };

  // 1.선택한 카메라, 2.현재 페이지의 카메라, 3.모든 카메라를 화면에서 제거 하는 함수
  const onClickRemoveScreen = async (value: 'selected' | 'onScreen' | 'all') => {
    if (value === 'selected' || value === 'onScreen' || value === 'all') {
      const updateCamList = nvrList.map((cameraGroup: any) => ({
        ...cameraGroup,
        subList: cameraGroup.subList.map((camera: any) => {
          const condition =
            value === 'all' ||
            (value === 'selected' &&
              camera.isPlaying &&
              selectedCameraInfo !== null &&
              selectedCameraInfo.nCd === camera.nCd &&
              selectedCameraInfo.cCd === camera.cCd &&
              selectedCameraInfo.windowIndex === camera.windowIndex &&
              isCamInCurrentGrid(camera.windowIndex, cctvDivisions, currentPage)) ||
            (value === 'onScreen' && camera.isPlaying && isCamInCurrentGrid(camera.windowIndex, cctvDivisions, currentPage));

          if (condition) {
            cctvOBJ?.stopWithIndex(camera.windowIndex).then((camStatus) => {
              setPlayingCamStatus([...camStatus]);
            });
            return { ...camera, isPlaying: false };
          }
          return { ...camera };
        }),
      }));
      setCamPlayLoading(false);
      setNvrList(updateCamList);
      logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: 'CCTV 실시간 보기-인프라용',
        action: `카메라 화면 제거`,
        etc: `${value}`,
      });
    }
  };

  // 녹화하려는 카메라가 현재 페이지에 있는 지 확인후 녹화시작 실행
  const onClickRecordAll = (recordAllReq: boolean) => {
    let recordingCount = 0;
    const updateCamList = nvrList.map((cameraGroup: any) => ({
      ...cameraGroup,
      subList: cameraGroup.subList.map((camera: any) => {
        const condition = camera.isPlaying && isCamInCurrentGrid(camera.windowIndex, cctvDivisions, currentPage);
        const windowIndex = camera.windowIndex % grid;
        if (condition && !recordAllReq && camera.isRecording) {
          camera.isRecording === true && cctvOBJ?.recordStopWithIndex(windowIndex);
          return { ...camera, isRecording: false };
        }
        if (condition && recordAllReq && !camera.isRecording) {
          recordingCount += 1;
          cctvOBJ?.recordWithIndex(windowIndex);
          return { ...camera, isRecording: true };
        }
        return { ...camera };
      }),
    }));
    setNvrList(updateCamList);

    if (recordAllReq && recordingCount > 0) {
      // 전체녹화
      setIsRecording(true);
    } else if (!recordAllReq && recordingCount === 0) {
      // 전체녹화정지
      toast(<LeftedToast message='녹화 완료' onClick={() => clickOpenDirectory()} />, { position: 'top-left', autoClose: 3000 });
      setIsRecording(recordAllReq);
    } else if (recordAllReq && recordingCount === 0) {
      // 전체녹화요청 했지만 재생중인 카메라가 없을때
      toast(<LeftedToast message='재생중인 상태에서 녹화를 시작하세요' />, { position: 'top-left', autoClose: 3000 });
    }
  };

  // 새로고침
  const onClickRefresh = () => {
    const filterNvrList = getNvrInfoFromCameraInfoPage(cameraInfo, nvrList).filter((v: any, i: number) => cameraInfo.findIndex((v2: any) => v.ip === v2.ip) === i);
    const data = filterNvrList //
      .map((v: any) => ({ ip: v.ip, pPort: v.pPort, id: v.id, password: v.password }));
    data //
      .forEach(async (nvr: { ip: string; pPort: string; id: string; password: string }) => {
        await cctvOBJ?.reconnectOne(nvr);
      });
    setCamPlayLoading(false);
    toast(<LeftedToast message='새로고침 완료' />, { position: 'top-left', autoClose: 3000 });
  };

  // cctvObj가 있는경우 재생중인카메라 정지하고 프로미스결과값 리턴
  const stopCctvObj = async () => {
    if (cctvOBJ?.getPluginOBJ()?.oPlugin) {
      const res = await cctvOBJ.stop();
      return res;
    }
    return null;
  };

  useEffect(() => {
    if (sdkInitLoading && cctvSettings && !isLoading) {
      initWebSdk(Number(cctvDivisions));
    }
  }, [cctvSettings, isLoading]);

  useEffect(() => {
    if (ptzControll && selectedCameraInfo?.cName && (ptzControll === 'full' || ptzControll !== beforePtzControll)) {
      switch (ptzControll) {
        case 'full':
          logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: 'CCTV 실시간 보기-인프라용',
            action: 'Full Screen',
            etc: `${selectedCameraInfo.cName}`,
          });
          break;
        case 'zoom':
          logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: 'CCTV 실시간 보기-인프라용',
            action: '마우스 Zoom',
            etc: `${selectedCameraInfo.cName}`,
          });
          break;
        case 'ptz':
          logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: 'CCTV 실시간 보기-인프라용',
            action: '마우스 PTZ',
            etc: `${selectedCameraInfo.cName}`,
          });
          break;
      }
      setBeforePtzControll(ptzControll);
      setPtzControll('');
    }
  }, [ptzControll, selectedCameraInfo, beforePtzControll]);

  const onMousePtzEventListener = (type: string) => {
    setPtzControll(type);
  };

  const initWebSdk = async (cType: number) => {
    const width = visiblePanel ? window.innerWidth - CCTV_PLAY_SIDE_MENU_WIDTH : window.innerWidth;
    setSdkInitLoading(true);
    const webSdk = new WebSDKcctv({ setOpenModal, divPlugin, setSelectWndIndex, cType, onMousePtzEventListener });
    await webSdk.init(width).then(async () => {
      setCctvOBJ(webSdk);
      setWebsdkInitChk(true);
    });
  };

  /**
   * 수정자 : 한영광
   * 수정일자 : 2024.06.28
   * 수정내용 : 기존에는 설정된 모든 NVR에 로그인을 시도한 후 카메라 실시간 재생을 진행하여 설정된 NVR이 많은 경우 첫 로드 시간이 길어지는 문제 발생
   *           -> 첫 페이지에 사용되는 NVR만 먼저 로그인 후 해당 카메라만 실행하여 첫 로드 시간을 줄이고
   *              그 이후에 나머지 NVR에 대한 로그인을 백그라운드에서 실행 되도록 처리
   */
  useEffect(() => {
    if (websdkInitChk && typeof bookmarkedCams !== 'string' && bookmarkedCams.length > 0 && sdkInitLoading) {
      pageNvrsLoginStart(bookmarkedCams);
    }
    // setTimeout(() => allNvrsLoginStart(), 2000);
  }, [websdkInitChk, bookmarkedCams]);

  const pageNvrsLoginStart = async (nvrs: any) => {
    setCamPlayLoading(true);
    setInitCamPlayLoading(true);
    await cctvOBJ?.login(getNvrInfoFromCameraInfoPage(cameraInfo, nvrs)).then((failNvrs: any[]) => {
      if (failNvrs.length > 0) {
        const newNvrList: any[] = nvrList.map((nvr, i) => {
          const failNvr = failNvrs.find((v) => v.ip === nvr.ip);
          if (failNvr) {
            return { ...nvr, fail: true };
          }
          return { ...nvr, fail: false };
        });
        setNvrList(newNvrList);
      }
      setSdkInitLoading(false);
    });
  };

  const getNvrInfoFromCameraInfoPage = (camInfo: any, nvrs: any) => {
    const filterNvrList = camInfo.filter((v: any, i: number) => camInfo.findIndex((v2: any) => v.ip === v2.ip) === i);
    const integrationCamListCurrentPage = nvrs.filter((v: any) => v.subList.find((v2: any) => v2.isPlaying && v2.windowIndex < cctvDivisions * cctvDivisions * currentPage));
    const filterNvrsPage = filterNvrList.filter((v: any) => integrationCamListCurrentPage.find((v2: any) => v.ip === v2.ip));
    return filterNvrsPage.map((v: any) => ({ ip: v.ip, pPort: v.pPort, id: v.id, password: v.password }));
  };

  useEffect(() => {
    // 북마크된 플레이리스트가 없는경우, 빈화면노출
    if (bookmarkedCams.length === 0 || bookmarkedCams === '') {
      cctvOBJ?.stop();
    } else if (typeof bookmarkedCams !== 'string' && bookmarkedCams.length > 0) {
      // 북마크 이동시 기존 카메라 스탑하고,
      // 북마크된 플레이리스트가 있는 경우, windowIndex 위치에 카메라 배치
      if (!sdkInitLoading) {
        // cctvOBJ?.stop();
        stopCctvObj().then(() => {
          setCamPlayLoading(true);
          setInitCamPlayLoading(true);
          cctvOBJ?.changeWndNum(Number(cctvDivisions));
          setWindowIndexAndStartPlay(nvrListOverwrite(nvrList, bookmarkedCams));
          setNvrList(nvrListOverwrite(nvrList, bookmarkedCams));
        });
      }
    }
  }, [sdkInitLoading, bookmarkedCams]);

  // 페이지번호 변경시
  useEffect(() => {
    cctvOBJ?.setCurrentPage(currentPage);
    if (!sdkInitLoading) {
      if (isChangeDivisions) {
        setIsChangeDivisions(false);
      } else {
        stopCctvObj().then(() => {
          setCamPlayLoading(true);
          setInitCamPlayLoading(true);
          setWindowIndexAndStartPlay(nvrList);
        });
      }
    }
  }, [currentPage]);

  useEffect(() => {
    // 카메라 또는 nvr명 드래그 해서 분할화면에 드랍한 경우
    if (droppedWndIndex !== null && draggingCamInfo !== null && draggingCamInfo.length !== 0) {
      setCamPlayLoading(true);
      const findNvr = cameraInfo.find((v: any) => v.ip === draggingCamInfo[0].ip);
      if (findNvr) {
        cctvOBJ?.login([{ ip: findNvr.ip, pPort: findNvr.pPort, id: findNvr.id, password: findNvr.password }]).then((failNvrs: any[]) => {
          let cNameList = '';
          draggingCamInfo.forEach((camInfo: any, i: number) => {
            cNameList = cNameList ? `${cNameList}, ${camInfo.cName}` : `${camInfo.cName}`;
            const alreadyCamStatus = playingCamStatus?.find((v) => v.nCd === camInfo.nCd && v.cCd === camInfo.cCd);
            if (alreadyCamStatus) {
              cctvOBJ?.stopWithIndex(alreadyCamStatus.windowIndex);
            }
            cctvOBJ?.stopWithIndex(droppedWndIndex + i);
          });
          startCamera(draggingCamInfo, droppedWndIndex);
          setTimeout(() => {
            setCamPlayLoading(false);
          }, 500);
          initDragAndDroppedWnd();
          const nvrinfo = nvrList.find((nvr) => nvr.nCd === draggingCamInfo[0].nCd);
          logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: 'CCTV 실시간 보기-인프라용',
            action: `${nvrinfo?.nName}`,
            etc: `${cNameList}`,
          });

          if (failNvrs.length > 0) {
            const newNvrList: any[] = nvrList.map((nvr, i) => {
              const failNvr = failNvrs.find((v) => v.ip === nvr.ip);
              if (failNvr) {
                return { ...nvr, fail: true };
              }
              return { ...nvr, fail: false };
            });
            setNvrList(newNvrList);
          }
        });
      }
    }
  }, [draggingCamInfo, droppedWndIndex]);

  // // 현재 페이지 및 화면분할을 기반으로 카메라 피드 재생을 시작하는 함수
  const setWindowIndexAndStartPlay = async (camList: any[]) => {
    if (camList.length > 0) {
      await pageNvrsLoginStart(camList);
      // 현재 페이지에서 재생해야 할 새 카메라 목록 생성
      // 현재 페이지, 화면분할을 기준으로 보여져야 하는 카메라만 필터링
      /**
       * 수정자 : 한영광
       * 수정일자 : 2024.06.26
       * 수정내용 : 현재 페이지에 해당하지 않는 windowIndex인 camera들도 startCamera() 메서드에서 cameraStatus값 갱신을 위해 isCameraInCurrentGrid() 함수를 사용하지 않음
       */
      const newCamList = camList
        .map((el: any) => el.subList)
        .flat()
        // .filter((el2: any) => el2.isPlaying && isCameraInCurrentGrid);
        .filter((el2: any) => el2.isPlaying);
      // 필터링된 카메라 목록을 반복하여 각 카메라를 시작
      await Promise.all(
        newCamList.map(async (cam, i) => {
          await startCamera([cam], cam.windowIndex);
        })
      );
      setInitCamPlayLoading(false);
      setTimeout(() => {
        setCamPlayLoading(false);
      }, 500);
      setResizeWnd(visiblePanel);
    }
  };

  // // 대상 카메라가 현재 보고있는 그리드에 있는 지 확인하는 함수
  // const isCameraInCurrentGrid = (windowIndex: number) => {
  //   if (windowIndex >= (currentPage - 1) * (cctvDivisions * cctvDivisions) && windowIndex < cctvDivisions * cctvDivisions * currentPage) {
  //     return true;
  //   }
  //   return false;
  // };

  const startCamera = async (cameras: ISubMenu[], startWndNum?: number) => {
    const camCLocation = localCctvSettings.camCLocation || cctvSettings.camCLocation;
    const camFColor = localCctvSettings.camFColor || cctvSettings.camFColor;
    await cctvOBJ?.start(cameras, startWndNum, cctvDivisions, camCLocation, camFColor).then((camStatus: CameraStatus[]) => {
      updateNvrList(camStatus);
      setPlayingCamStatus(camStatus);
      cctvOBJ?.clickWnd(0);
      setSelectWndIndex(0);
    });
  };

  const updateNvrList = (camStatus: CameraStatus[]) => {
    const newNvrList = nvrListIsPlayingAllFalse();
    camStatus.forEach((cam: CameraStatus) => {
      newNvrList.map((nvr, i) => {
        if (cam.nCd === nvr.nCd) {
          nvr.subList.map((sub, j) => {
            if (sub.cCd === cam.cCd) {
              newNvrList[i].subList[j].isPlaying = cam.isPlaying;
              newNvrList[i].subList[j].fail = cam.isFail;
              newNvrList[i].subList[j].windowIndex = Number(cam.windowIndex);
            }
          });
        }
      });
    });
    setNvrList(newNvrList);
  };

  // selectedCameraInfo 업데이트
  useEffect(() => {
    if (cctvOBJ && selectWndIndex !== null && !camPlayLoading) {
      const pageWndIndex = selectWndIndex + (currentPage - 1) * cctvDivisions * cctvDivisions;
      const cameraStatus = cctvOBJ?.getCameraStatus(pageWndIndex);
      setSelectedCameraInfo({ ...cameraStatus, windowIndex: pageWndIndex });
    }
  }, [selectWndIndex, cctvOBJ, camPlayLoading]);

  useEffect(() => {
    setResizeWnd(visiblePanel);
  }, [visiblePanel]);

  const setResizeWnd = (visible: boolean) => {
    const width = visible ? window.innerWidth - CCTV_PLAY_SIDE_MENU_WIDTH : window.innerWidth;
    const height = window.innerHeight - CCTV_REALTIME_BOTTOM_CONTROL_HEIGHT;
    cctvOBJ?.resizeWnd(width, height);
  };

  // 화면분할 클릭 (1분할, 4분할, ...16분할)
  const onClickDivision = (divisionNum: number) => {
    setCctvDivisions(divisionNum);
    if (currentPage > 1) {
      setIsChangeDivisions(true);
      setCurrentPage(1);
    }
    if (cctvOBJ?.getPluginOBJ()?.oPlugin) {
      cctvOBJ.changeWndNum(divisionNum);
      // cctvOBJ.setSnapPolygon({ camFColor: localCctvSettings.camFColor, camCLocation: localCctvSettings.camCLocation });
    }
    startPlayCctvOfDivisions(divisionNum);
    // setBeforeCctvDivisions(divisionNum);
  };

  const startPlayCctvOfDivisions = async (divisionNum: number) => {
    if (nvrList.length > 0) {
      const camCLocation = localCctvSettings.camCLocation || cctvSettings.camCLocation;
      const camFColor = localCctvSettings.camFColor || cctvSettings.camFColor;
      setCamPlayLoading(true);
      await pageNvrsLoginStart(nvrList);
      const newCamList = nvrList
        .map((el: any) => el.subList)
        .flat()
        .filter((el2: any) => el2.isPlaying);
      await Promise.all(
        newCamList.map(async (cam, i) => {
          await cctvOBJ?.start([cam], cam.windowIndex, divisionNum, camCLocation, camFColor).then((camStatus: CameraStatus[]) => {
            updateNvrList(camStatus);
            setPlayingCamStatus([...camStatus]);
            cctvOBJ?.clickWnd(0);
            setSelectWndIndex(0);
          });
        })
      );
      setInitCamPlayLoading(false);
    }
    setTimeout(() => {
      setCamPlayLoading(false);
    }, 500);
  };

  // drag 및 dropped 윈도우 상태 초기화
  const initDragAndDroppedWnd = () => {
    setDraggingCamInfo(null);
    setDroppedWndIndex(null);
  };

  const nvrListIsPlayingAllFalse = () => {
    const newNvrList = nvrList;
    nvrList.map((nvr, i) => {
      nvr.subList.map((sub, j) => {
        newNvrList[i].subList[j].isPlaying = false;
      });
    });
    return newNvrList;
  };

  const mouseUpHandler = (moveEvent: MouseEvent) => {
    if (!camPlayLoading && !sdkInitLoading) {
      const clientWidth = document.querySelector('.cctvWrapper')?.clientWidth || 0;
      const clientHeight = document.querySelector('.cctvWrapper')?.clientHeight || 0;
      const blockWidth = Math.floor(clientWidth / (cctvDivisions || 4));
      const blockHeight = Math.floor(clientHeight / (cctvDivisions || 4));
      const xPosition = Math.floor(moveEvent.offsetX / blockWidth);
      const yPosition = Math.floor(moveEvent.offsetY / blockHeight);
      const index = yPosition * cctvDivisions + xPosition;
      setDroppedWndIndex(index + (currentPage - 1) * (cctvDivisions * cctvDivisions));
    } else {
      toast(<LeftedToast message='기다려 주세요..' />, { position: 'top-left', autoClose: 3000 });
    }
  };
  // useEffect(() => {
  //   if (!sdkInitLoading) {
  //     if (initCamPlayLoading) {
  //       console.log('cctvOBJ?.hideWnd();');
  //       cctvOBJ?.hideWnd();
  //     } else {
  //       console.log('cctvOBJ?.showWnd();');
  //       cctvOBJ?.showWnd();
  //     }
  //   }
  // }, [initCamPlayLoading, sdkInitLoading]);

  return (
    <Root>
      {/* {initCamPlayLoading && (
        <Loader>
          <PulseLoader color='rgb(0, 122, 255)' size='10px' />
        </Loader>
      )} */}
      <div
        id={divPlugin}
        className='cctvWrapper'
        // 수정자 : 한영광
        // 수정일자 : 2024.03.20
        // 수정내용 1. : WebSDK에서 드래그&드랍 기능 구현을 위해 Mouse Up 이벤트 리스너를 추가
        // 수정내용 2. : Mouse Up 위치의 윈도우 포지션값 구하는 계산식 추가
        role='button'
        tabIndex={0}
        onMouseUp={() => {
          document.addEventListener('mouseup', mouseUpHandler, { once: true });
          setIsDragging(false);
        }}
      />
      <BottomMenu // 모니터링 하단 UI 메뉴막대
        visiblePanel={visiblePanel}
        setVisiblePanel={setVisiblePanel}
        cctvOBJ={cctvOBJ}
        onClickDivision={onClickDivision}
        divisionCount={cctvDivisions}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        onClickSetStream={onClickSetStream}
        onClickRemoveScreen={onClickRemoveScreen}
        isRecording={isRecording}
        onClickRefresh={onClickRefresh}
        onClickRecord={onClickRecordAll}
        selectedCameraInfo={selectedCameraInfo}
        playingCamStatus={playingCamStatus}
        camPlayLoading={camPlayLoading}
        setCamPlayLoading={setCamPlayLoading}
      />
      <Portal openModal={openModal?.status}>
        {openModal && ( // CCTV 미설치 안내 모달
          <WebSdkModal openModal={openModal} setOpenModal={setOpenModal} />
        )}
      </Portal>
    </Root>
  );
};

export default WebSdkRealtime;
