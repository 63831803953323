import styled from 'styled-components';
import { formatDate } from '../../../utils/formatDate';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  max-width: 28rem;
  .labelName {
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    font-weight: 600;
  }
  .field {
    font-size: 0.875rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
    padding: 0.25rem 1rem;
    border-radius: 0.25rem;
    min-width: 10rem;
    text-align: center;
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
  }
`;

interface Props {
  data: string;
  isDate?: boolean;
  labelName: string;
}

const HistorySettings = ({ data, isDate = false, labelName }: Props) => {
  return (
    <Root>
      <span className='labelName'>{labelName}</span>
      <span className='field'>{isDate ? formatDate(data) : data}</span>
    </Root>
  );
};

export default HistorySettings;
