/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.23
 * 경로 : 터널관리 - 구역 현황 관리 > 종합진행현황
 */

import { useQuery } from '@tanstack/react-query';
import { logPost } from '../../services/log';
import { ERROR } from '../../_constants';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import styled from 'styled-components';
import { EndPoint, StartPoint } from '../TunnelPoint';
import TunnelLegends from '../tunnel/TunnelLegends';
import { workAndEquipment } from '../../services/Tunnel';
import { apiGet } from '../../services/_common';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  user-select: none;
  > .title {
    height: 2rem;
    width: 100%;
    padding: 0 1rem;
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .name {
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      font-weight: ${({ theme }: { theme: any }) => theme.font_semibold};
      font-size: 1.125rem;
      line-height: 1.2;
    }
  }
  > .content {
    width: 100%;
    flex-grow: 1;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.25rem;
    margin-bottom: 0.5rem;
    > .point {
      width: 100%;
      flex-grow: 1;
      max-height: calc(50% - 0.125rem);
      display: flex;
      gap: 0.5rem;
      align-items: center;
      border-radius: 0.5rem;
      padding: 0.375rem 0.5rem;
      &.startPoint {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
        .body .material-symbols-rounded {
          color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
        }
      }
      &.endPoint {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
        .body .material-symbols-rounded {
          color: ${({ theme }: { theme: any }) => theme.filled_amber_deep};
        }
      }
      .header {
        width: 4rem;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.75rem;
        background-color: ${({ theme }: { theme: any }) => theme.alpha_75};
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
        padding: 0.5rem;
        flex-shrink: 0;
      }
      .body {
        flex-grow: 1;
        width: calc(100% - 4.5rem);
        flex-shrink: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 0.5rem;
        border-radius: 0.25rem;
        background-color: ${({ theme }: { theme: any }) => theme.alpha_50};
        > div {
          flex-grow: 1;
          font-size: 0.875rem;
          font-weight: 600;
          max-height: calc(50% - 0.125rem);
          max-height: 50%;
          display: flex;
          align-items: center;
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
          .material-symbols-rounded {
            font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
            font-size: 1rem;
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
`;

type Props = { name: string; userInfo: any; tatCd: string };

const TunnelWorkAndEquipment = ({ name, userInfo, tatCd }: Props) => {
  const { t } = useTranslation();

  // Tarea 데이터 요청 함수
  const fetchTareaData = async ({ hCd, sCd }: { hCd: string; sCd: string }) => {
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, tatCd };
      const res = await apiGet({ path: `/tarea/t`, req });
      const { data } = res.data || {};
      return data || [];
    } catch (error) {
      console.error('API 호출 중 오류 발생:', error);
      toast.error(t(ERROR));
      throw new Error('API 호출 중 오류 발생');
    }
  };

  // Tarea 데이터 요청 상태값
  const { data, isError, isLoading } = useQuery(['TunnelWorkAndEquipment', userInfo.hCd, userInfo.sCd], () => fetchTareaData(userInfo), {
    refetchInterval: 60000, // 60초마다 자동 refetch
  });

  // 시점 및 종점 렌더링 함수
  const renderPoint = (point: 'start' | 'end') => {
    const { eTjcName, eTesName, sTjcName, sTesName, sUseYn, eUseYn } = data;
    const style = point === 'start' ? 'startPoint' : 'endPoint';
    const tjcName = point === 'start' ? sTjcName : eTjcName;
    const tesName = point === 'start' ? sTesName : eTesName;
    const useYn = point === 'start' ? sUseYn : eUseYn;

    if (useYn === 'N') return null;

    return (
      <div className={`point ${style}`}>
        <div className='header'>{point === 'start' ? <StartPoint vertical /> : <EndPoint vertical />}</div>
        <div className='body'>
          <div className='tjc'>
            <span className='material-symbols-rounded'>construction</span>
            {tjcName}
          </div>
          <div className='tes'>
            <span className='material-symbols-rounded'>front_loader</span>
            {tesName}
          </div>
        </div>
      </div>
    );
  };

  // 로딩 중일 때
  if (isLoading) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  if (isError) {
    console.error('API 호출 중 오류 발생:', data);
  }

  return (
    <Root>
      <div className='title'>
        <span className='name'>{t('작업 / 장비 현황')}</span>
        <TunnelLegends filter={workAndEquipment} />
      </div>
      <div className='content'>
        {renderPoint('start')}
        {renderPoint('end')}
      </div>
    </Root>
  );
};

export default TunnelWorkAndEquipment;
