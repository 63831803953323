/**
 * 작성자 : 홍선영
 * 날짜 : 2023.03.10
 * 기능 : 검색어를 입력하여 옵션리스트를 검색하고 값을 선택해서 setState 하는 셀렉트박스 컴포넌트
 */

import React, { SetStateAction, useEffect, useState, Dispatch, useRef, useCallback, useLayoutEffect } from 'react';
import { SelectBoxSmDropStyle } from '../assets/styles/SelectBoxSmDrop';
import { v1 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface ISearchSelectBox {
  options: any; // 셀렉트박스 배열 [{ 키네임1: 코드값1 }, { 키네임2: 코드값2 }, { 키네임3: 코드값3 }]
  defaultOption: string; // 셀렉트 디폴트옵션
  state: any; //  state값
  setState: Dispatch<SetStateAction<any>>; //  useState set액션
  stateKey: string; //  state 키값
  codeKey: string;
  initiateKey?: any;
  disabled?: boolean;
  getBorderStyle?: any; // 선택값이 없을 때 보더 스타일
  filterbar?: 'filter-1-left' | 'filter-1-leftToCenter' | 'filter-1-center' | 'filter-1-centerToRight' | 'filter-1-right' | 'filter-2-left' | 'filter-2-center' | 'filter-2-right';
  align?: 'left' | 'right';
  dropDownWidth?: 'fit-content' | 'expand-content' | 'expand-content-md' | 'expand-content-sm' | 'expand-content-xs';
  asideMain?: boolean;
  comboWidth?: 'expand-box' | 'expand-box-md' | 'expand-box-sm';
  optionHeight?: 'height-lg' | 'height-md' | 'height-base' | 'height-sm';
  clickAble?: boolean; // 클릭 시 드롭다운 가능여부 (true: 드롭다운 안됨, false: 드롭다운 됨)
  toastContent?: string; // 드롭다운 안되는 경우 보여줄 토스트 메시지
  onClick?: () => void;
}

const SearchSelectBox = ({
  options,
  defaultOption,
  state,
  setState,
  stateKey,
  codeKey,
  initiateKey,
  disabled,
  getBorderStyle,
  filterbar,
  dropDownWidth = 'fit-content',
  align = 'left',
  asideMain = false,
  comboWidth,
  optionHeight,
  clickAble,
  toastContent,
  onClick,
}: ISearchSelectBox) => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLUListElement>(null);
  //   const wrapperRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>({ type: stateKey, [stateKey]: state[stateKey], cdName: defaultOption });
  const [userInput, setUserInput] = useState({ value: '' });
  const [searchResultMenu, setSearchResultMenu] = useState([]);
  const [optionIndex, setOptionIndex] = useState(0);
  const { value } = userInput;
  const [dropdownPosition, setDropdownPosition] = useState<'bottomToTop' | 'topToBottom'>('topToBottom');

  useEffect(() => {
    if (initiateKey && options !== undefined && options.length > 0) {
      // initiate key prop이 변경되었을 때 셀렉트박스 cdName값(선택된옵션)을 다시 세팅
      const findCdName = options.find((el: any) => el[stateKey] === initiateKey || el.cdName === initiateKey);
      if (findCdName !== undefined) {
        setSelectedOption({ type: stateKey, [stateKey]: state[stateKey], cdName: findCdName.cdName });
      } else {
        /**
         * 수정자 : 홍선영
         * 날짜 : 2024.02.01
         * 수정 내용 : 해당 코드가 '삭제' 또는 '미사용' 처리 된 경우,
         *            추후 조회 시 옵션목록과 일치하는 코드값이 없기 때문에 아래 로직에 의해 '미선택' 으로 표시되는 문제
         *            선택가능한 목록에서는 사라져야 하는것이 맞으나, 기존 선택한 코드명은 표시되어야 하므로 아래 로직 수정
         *
         *            e.g. 협력업체1 근로자가 있는 상태에서 협력업체1을 삭제/미사용 하면,
         *            근로자정보 모달에서 해당근로자의 협력업체명이 미선택으로 뜨고,
         *            필수입력값인 협력업체를 선택하라고 뜨면서 수정값 저장이 안됨.
         */
        setSelectedOption({ type: stateKey, [stateKey]: state[stateKey] || '', cdName: t(`${defaultOption}`) || t('미선택') });
      }
    }
  }, [initiateKey, options]);

  const search = useCallback(() => {
    if (value !== '') {
      //  dropdown메뉴를 순회하면서 검색문자를 포함한 옵션배열 리턴(대소문자 구분없이 검색)
      const result: any = options?.filter((word: any) => {
        return word[codeKey].toLowerCase().includes(value.toLowerCase());
      });
      setSearchResultMenu(result);
    } else {
      //  검색입력값이 없거나 모두지워졌을때 검색메뉴배열 비우기
      setSearchResultMenu([]);
    }
  }, [value, codeKey, options]);

  useEffect(() => {
    search();
  }, [search]);

  useEffect(() => {
    // 선택한 옵션객체에 코드키가 있을때 state값 업데이트
    if (selectedOption[stateKey] !== undefined) {
      setState({ ...state, type: stateKey, [stateKey]: selectedOption[stateKey], cdName: selectedOption.cdName });
    }
  }, [selectedOption]);

  // 콤보박스 클릭시 옵션리스트 열기
  const onClickDropdown = () => {
    if (clickAble && toastContent) {
      toast.warn(t(toastContent));
      return;
    }
    setIsDropdownOpen(!isDropdownOpen);
  };

  useLayoutEffect(() => {
    const updatePosition = () => {
      if (wrapperRef.current) {
        const rect = wrapperRef.current.getBoundingClientRect();
        if (window.innerHeight - 72 < rect.bottom && rect.top - 80 > rect.height) {
          setDropdownPosition('bottomToTop');
        } else {
          setDropdownPosition('topToBottom');
        }
      }
    };
    updatePosition();
  }, [isDropdownOpen]);

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput({ ...userInput, value: e.currentTarget.value });
  };

  // 콤보박스에서 방향키 위아래로 움직였을 때 옵션이동
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLDivElement>, option: any) => {
    const key = e.key || e.keyCode;
    if ((key === 'ArrowUp' || key === 38) && optionIndex > 0) {
      //  방향키 위
      setOptionIndex((prev) => prev - 1);
    }
    if ((key === 'ArrowDown' || key === 40) && optionIndex < option.length - 1) {
      //  방향키 아래
      setOptionIndex((prev) => prev + 1);
    }
    if (options.length !== 0 && (key === 'Enter' || key === 13)) {
      //  엔터
      setSelectedOption(option[optionIndex]);
      setIsDropdownOpen(!isDropdownOpen);
      setUserInput({ value: '' });
    }
  };

  //  검색입력값이 없을 때 기본메뉴배열 노출
  const renderAllDropdownMenu = () => {
    if (value === '' && Array.isArray(options)) {
      return options?.map((el: any) => (
        <li
          className={state[stateKey] === el[stateKey] ? 'selectedOption' : undefined}
          key={v1()}
          role='presentation'
          onClick={() => {
            setSelectedOption(el);
            setIsDropdownOpen(!isDropdownOpen);
          }}
        >
          {el[codeKey]}
        </li>
      ));
    }
    return null;
  };

  //  검색문자를 포함하는 메뉴배열 노출
  const renderSearchDropdownMenu = () => {
    return searchResultMenu.map((el, i) => (
      <li
        className={optionIndex === i ? 'selectedOption' : undefined}
        key={v1()}
        role='presentation'
        onClick={() => {
          setSelectedOption(el);
          setUserInput({ value: '' });
        }}
      >
        {el[codeKey]}
      </li>
    ));
  };

  useLayoutEffect(() => {
    const updatePosition = () => {
      if (wrapperRef.current) {
        const rect = wrapperRef.current.getBoundingClientRect();
        if (window.innerHeight - 72 < rect.bottom && rect.top - 80 > rect.height) {
          setDropdownPosition('bottomToTop');
        } else {
          setDropdownPosition('topToBottom');
        }
      }
    };
    updatePosition();
  }, [isDropdownOpen]);

  const handleClick = () => {
    onClickDropdown();
    onClick && onClick();
  };

  const emptyData = options?.length === 0;

  const renderCdName = () => {
    if (emptyData) return `${t('목록 없음')}`;
    if (selectedOption[codeKey] !== undefined && state[stateKey] !== '') return t(selectedOption[codeKey]);
    return t(defaultOption);
  };

  return (
    <SelectBoxSmDropStyle isDropdownOpen={isDropdownOpen}>
      <ul style={getBorderStyle}>
        <li className={`defaultOption ${emptyData ? 'disabled' : ''} ${isDropdownOpen ? 'opened' : ''} ${comboWidth}`} role='presentation' onClick={handleClick}>
          <span>{renderCdName()}</span>
          <div>
            <span className='material-symbols-rounded'>keyboard_arrow_down</span>
          </div>
        </li>
        {!disabled ? (
          <ul
            className={`optionBox ${isDropdownOpen ? 'on' : 'off'} ${dropDownWidth} ${align} ${filterbar ?? ''} ${asideMain ? 'aside-main' : ''} ${filterbar && 'filterbar'} ${
              !filterbar && dropdownPosition === 'bottomToTop' && 'bottomToTop'
            } ${optionHeight ?? ''}`}
            ref={wrapperRef}
          >
            <li className='searchBox'>
              <input
                type='text'
                onKeyDown={searchResultMenu.length !== 0 ? (e) => handleKeyDown(e, searchResultMenu) : (e) => handleKeyDown(e, options)}
                onChange={(e) => onChangeInput(e)}
                placeholder={t('검색...')}
                value={value}
              />
            </li>
            {searchResultMenu.length !== 0 ? renderSearchDropdownMenu() : renderAllDropdownMenu()}
          </ul>
        ) : null}
        <div role='button' className={` ${isDropdownOpen && 'optionBox-filter'}`} onMouseDown={() => setIsDropdownOpen(false)} aria-label='back-filter-button' tabIndex={0} />
      </ul>
    </SelectBoxSmDropStyle>
  );
};

export default React.memo(SearchSelectBox);
