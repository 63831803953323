import styled from 'styled-components';

interface ISearchOption {
  align?: string;
}

export const SearchOptions = styled.div<ISearchOption>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  .filterGroup {
    display: flex;
    gap: 0.5rem;
  }
  .seperator {
    border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    height: 1rem;
    margin: 0 0.5rem;
  }
  &.border-bottom {
    border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  }
  &.setting-medical {
    padding: 0.5rem 1rem;
  }
  .px-75 {
    /* padding: 0.5rem 0.75rem; */
  }
  > button {
    height: 2.5rem;
    width: 6rem;
  }
  .searchData {
    display: flex;
    font-size: 0.875rem;
    align-items: center;
    padding: 0 1rem;
    border-radius: 0.25rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    > span {
      color: ${({ theme }: { theme: any }) => theme.text_disabled};
      margin-right: 0.5rem;
    }
  }
  .searchResult {
    padding: 0 0.75rem;
    font-size: 0.875rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    height: 2.5rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    span {
      font-weight: 600;
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      margin-left: 0.25rem;
      /* margin-right: 0.125rem; */
    }
  }
  .firstSearchOption {
    width: 100%;
    flex-shrink: 0;
    padding: 0.5rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  }
  .inputsWrapperGroup {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 100%;
    overflow: hidden;
    .icon-btn {
      flex-shrink: 0;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      gap: 0.25rem;
      cursor: pointer;
      border-radius: 5rem;
      padding-right: 1rem;
      padding-left: 0.5rem;
      font-size: 0.875rem;
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      &:hover {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
      }
      > span {
        color: ${({ theme }: { theme: any }) => theme.text_primary};
      }
      &.arrow-icon span {
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
      }
    }
  }
  .inputsWrapper {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    @media (max-width: 1024px) {
      flex-wrap: nowrap;
      overflow: auto;
      &::-webkit-scrollbar {
        height: 0rem;
      }
    }
    &.sensor-results {
      > div {
        flex-shrink: 0;
      }
    }
    .inputForm-col {
      &.maxWidth-8 {
        input {
          width: 8rem;
        }
        &.maxWidth-10 {
          input {
            width: 10rem;
          }
        }
      }
    }
    .inputForm-row-w200 {
      width: 30rem;
    }
    .inputForm-row.withLabel {
      display: flex;
      align-items: center;
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      border-radius: 0.25rem;
      position: relative;
      label {
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
        padding: 0 0.75rem;
        user-select: none;
        font-weight: 600;
      }
      > div:nth-child(2) {
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
        border-radius: 0 0.25rem 0.25rem 0;
        :focus-within {
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
        }
        > div > ul {
          position: static;
          > li:first-child {
            /* border-radius: 0; */
            outline: none;
          }
        }
      }
    }
    .inputForm-row {
      flex-shrink: 0;
    }
    .inputForm-row-fit {
      flex-shrink: 0;
      width: fit-content;
      height: 2.5rem;
      border-radius: 0.25rem;
      display: flex;
      align-items: center;
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      .inputForm-col:focus-within {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_deep} !important;
        > div > input {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_deep} !important;
        }
      }

      &.notOutline:focus-within {
        outline: none;
      }
      span.calendarLabel {
        font-size: 0.875rem;
        padding: 0 0.75rem;
        user-select: none;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
        font-weight: 600;
      }
      span.calendarFooter {
        font-size: 0.875rem;
        padding-right: 0.75rem;
        padding-left: 0.25rem;
        user-select: none;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
        font-weight: 400;
      }
      > div:nth-child(2) {
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
        border-radius: 0 0.25rem 0.25rem 0;
        span.wave {
          color: ${({ theme }: { theme: any }) => theme.text_secondary};
        }
        :focus-within {
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
        }
      }
    }
    input {
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
    }
    input[type='text'] {
      height: 2.5rem;
      border-radius: 0.25rem;
      font-size: 0.875rem;
      font-weight: 500;
    }
    input#startInput,
    input#endInput,
    input#startInput2,
    input#endInput2 {
      height: 2.5rem;
      border-radius: 0.25rem;
      font-size: 0.875rem;
      width: 6rem;
      padding: 0;
      text-align: center;
      outline: none;
      font-weight: 500;
    }
    .inputDash {
      user-select: none;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
    }
    ul {
      height: 2.5rem;
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  .filter-btn {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.25rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    :hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  .sensorOverflow {
    overflow: initial;
    .secondSearchOption {
      /* overflow: initial; */
      overflow: auto;
    }
  }
`;
