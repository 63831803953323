/**
 * 작성자 : 홍선영
 * 날짜 : 2023.11.03
 * 기능 : 협력업체 목록 useState hook
 */

import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { IUser, userState } from '../atoms';
import { apiGet } from '../services/_common';
import { arraySortByAscdOrder } from './arraySortByAscdOrder';

interface ISiteJoin {
  sjCd: string;
  sjName: string;
  bigo: string;
  useYn: string;
}

// 보고서 상단 검색조건의 협력업체, '협력업체 전체' 선택 포함하는 목록
export const useSetSjcd = () => {
  const { t } = useTranslation();
  const [siteJoinInfoList, setSiteJoinInfoList] = useState<ISiteJoin[]>([]);
  const userInfo = useRecoilValue<IUser>(userState);

  // 협력업체 리스트 조회
  const siteJoinQuery = useQuery(['siteJoinGet', userInfo], () => apiGet({ path: '/siteJoin', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
  });

  useEffect(() => {
    if (siteJoinQuery.isSuccess) {
      const { status, data } = siteJoinQuery.data;
      if (status === 200) {
        if (data.data.siteJoinList) {
          const newList = data.data.siteJoinList
            .filter((v1: ISiteJoin) => v1.useYn === 'Y')
            .map((v2: ISiteJoin) => {
              return {
                type: 'sjCd',
                sjCd: v2.sjCd,
                cdName: v2.sjName,
              };
            });
          setSiteJoinInfoList([{ type: 'sjCd', sjCd: '', cdName: t('전체') }, ...newList]);
        }
      }
    }
  }, [siteJoinQuery.isSuccess]);

  return { siteJoinInfoList };
};

// 삭제된 협력업체 포함하는 목록
export const useSetSjcdWDelY = () => {
  const userInfo = useRecoilValue<IUser>(userState);

  // 협력업체 리스트 조회
  const {
    data: sjCdList,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  } = useQuery(['siteJoinDelYGet', userInfo], () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
  });

  const fetchData = async () => {
    try {
      const path = '/siteJoin';
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, delYnCheck: 'Y' };
      const res = await apiGet({ path, req });
      const sortedArray = arraySortByAscdOrder(res.data.data.siteJoinList, 'sjCd');
      const formattedList = sortedArray.map((v2: ISiteJoin) => {
        return {
          type: 'sjCd',
          sjCd: v2.sjCd,
          cdName: v2.sjName,
        };
      });
      return formattedList;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  return { sjCdList, isLoading, isFetching, isRefetching, refetch };
};
