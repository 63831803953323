/**
 * 작성자 : 홍선영
 * 날짜 : 2023.06.29
 * 기능 : 출역관리-일출역현황(현장용) 등록 모달
 */

import { useState, useEffect, Dispatch, SetStateAction, useRef } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { PulseLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import ReactSelect from '../ReactSelect';

import { userState } from '../../atoms';
import { IAuth, IModal } from 'customTypes';
import { hourList2, minList2 } from '../../_constants';
import { ModalBackground, Modal, LoadingModalBackground } from '../../assets/styles/Modal';
import { BtnBlue, BtnGhost } from '../Button';
import Input from '../Input';
import SelectBox from '../SelectBox';
import IssueGuide from '../IssueGuide';
import DatePicker from '../DatePicker';
import DeleteModal from './DeleteModal2';
import WorkerListModal from './WorkerListModal';
import SearchSelectBoxSm from '../SearchSelectBoxSm';
import { isValidYYYYMMDD } from '../../utils/formatDate';
import { applyBorderStyle } from '../../utils/applyBorderStyle';
import useOnKeydownF9 from '../../utils/useOnKeydownF9';
import { logPost } from '../../services/log';
import { apiGet, apiPost } from '../../services/_common';
import { useFetchFaseSetCodeList, useFetchSiteJoinCodeList } from '../../services/useSetCodeListInSelectBoxForm';

interface IProps {
  getReportListAPI: () => void;
  setOpenModal: Dispatch<SetStateAction<IModal>>;
  auth: IAuth;
  code: { hCd: string; sCd: string };
}

interface IWorkerAttendInput {
  attendIdx: number;
  wCd: string;
  sjName: string;
  sjCd: string;
  wName: string;
  fNum: string;
  fName: string;
  fLocation: string;
  fInout: string;
  aDate: string;
  wPrejobtype?: string;
  wJobtype?: string;
}

interface IFaseSet {
  fNum: string;
  fName: string;
  fLocation: string;
  fLocationName: string;
  fInout: string;
}

const EnrollmentAttendModal = ({ getReportListAPI, setOpenModal, auth, code }: IProps) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const initialWorkerAttend = {
    attendIdx: 0,
    wCd: '',
    aDate: '',
    sjCd: '',
    sjName: '',
    fNum: '',
    fName: '',
    fLocation: '',
    fInout: '',
    wName: '',
  };
  const wNameRef = useRef<HTMLInputElement>(null);
  const [openSubModal, setOpenSubModal] = useState<IModal>({ status: false, type: '', isOverlappingModal: true });
  const [selectedUser, setSelectedUser] = useState<any>(undefined); // 근로자 검색팝업에서 선택한 유저
  const [isViewMode, setIsViewMode] = useState(!auth.createAuth && !auth.updateAuth); // 권한에 따른 뷰 or 수정모드 여부
  const [workerInputSet, setWorkerInputSet] = useState<IWorkerAttendInput>(initialWorkerAttend);
  const [aDate, setADate] = useState('');
  const [hour, setHour] = useState({ label: '00', value: '00' });
  const [min, setMin] = useState({ label: '00', value: '00' });
  const [sec, setSec] = useState({ label: '00', value: '00' });
  const [faceSetState, setFaceSetState] = useState<IFaseSet>({ fNum: '', fName: '', fInout: '', fLocation: '', fLocationName: '' });
  const [searchCheck, setSearchCheck] = useState<boolean>(false); // 근로자 검색절차 완료 여부
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const [sjCd, setSjCd] = useState({ type: 'sjCd', sjCd: '', cdName: t('선택') });
  const [faceSet, setFaceSet] = useState({ type: 'fNum', fNum: '', cdName: t('선택') });
  const { data: faceSetList, isLoading: isLoadingFaseSetList, isError: isErrorFaceSetList } = useFetchFaseSetCodeList();
  const { data: siteJoinList, isLoading: isLoadingSiteJoinList, isError: isErrorSiteJoinList } = useFetchSiteJoinCodeList();

  // 장비목록정보 useQuery
  const {
    data: faceSetInfoList,
    isError: isErorrFaceSetInfo,
    isLoading: isLoadingFaceSetInfo,
  } = useQuery(['faceSet', userInfo.hCd, userInfo.sCd], () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd, // hCd 및 sCd가 유효한 경우에만 쿼리 사용
  });
  const [faceSetInfo, setFaceSetInfo] = useState(faceSetInfoList || []);

  // 장비목록정보 데이터 fetch 함수
  const fetchData = async () => {
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
      const res = await apiGet({ path: '/setting/faceSet', req }); // 데이터를 검색하기 위해 API 호출
      const result = res.data.data.faceSet;
      setFaceSetInfo(result);
      return result;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  useEffect(() => {
    if (aDate !== '') setWorkerInputSet((prev: IWorkerAttendInput) => ({ ...prev, aDate: aDate?.replace('-', '') }));
  }, [aDate]);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearchWorker();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    // 근로자 검색 팝업에서 선택버튼까지 클릭했을 때 setState
    if (selectedUser && (selectedUser.sjCd !== '' || selectedUser.wName !== '')) {
      setWorkerInputSet((prev: IWorkerAttendInput) => ({
        ...prev,
        wName: selectedUser.wName,
        wCd: selectedUser.wCd,
        wPrejobtype: selectedUser.wPrejobtype,
        wJobtype: selectedUser.wJobtype,
      }));
      setSjCd({ type: 'sjCd', sjCd: selectedUser.sjCd, cdName: selectedUser.sjName });
      setSearchCheck(true);
    }
  }, [selectedUser]);

  useEffect(() => {
    // 근로자 검색을 마친 상태에서  근로자명을 변경했을 때 검색여부 false로 변경
    if (selectedUser) {
      if (selectedUser.wName !== workerInputSet.wName || selectedUser.wCd !== workerInputSet.wCd) {
        setSearchCheck(false);
      }
    }
  }, [workerInputSet.wName, workerInputSet.sjCd]);

  // 근로자 검색
  const onClickSearchWorker = async () => {
    if (!openSubModal.status) {
      setOpenSubModal((prev) => ({ ...prev, status: true, type: 'workerList' }));
    }
  };

  const onClickClose = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };

  const onClickSaveWorkerAttend = () => {
    setIsSaveClicked(true);
    if (!searchCheck) return toast.warning(t('근로자 검색을 완료하세요'));
    if (!aDate) return toast.warning(t('출역일자를 선택하세요'));
    if (aDate && (aDate.length < 8 || !isValidYYYYMMDD(aDate))) return toast.warning(t('출역일자를 확인하세요'));
    if (sjCd.sjCd === '' || workerInputSet.aDate === '' || hour.value === '' || min.value === '' || sec.value === '') {
      return toast.warning(t('필수입력값을 모두 입력하세요'));
    }
    if (selectedUser === undefined) return toast.warning(t('근로자를 선택하세요'));
    const reqData = {
      attendIdx: 0,
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      sjCd: sjCd.sjCd,
      wCd: workerInputSet.wCd,
      aDate: `${aDate}${hour.value}${min.value}${sec.value}`,
      fNum: faceSetState.fNum,
      fLocation: faceSetState.fLocation,
      fInout: faceSetState.fInout,
      wPrejobtype: workerInputSet.wPrejobtype,
      wJobtype: workerInputSet.wJobtype,
    };
    return attendPostAPI(reqData);
  };

  const attendPostAPI = async (req: any) => {
    const res = await apiPost({ path: '/attend', req });
    const { statusCode } = res.data;
    if (statusCode === 200) {
      getReportListAPI();
      toast.success(t('개인별 일출역 등록 성공'));
      setOpenModal((prev: IModal) => ({ ...prev, status: false }));
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '개인 출역 수기등록(팝업)',
        action: '신규 등록',
        etc: `${workerInputSet.wName}(${req.wCd})`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  useEffect(() => {
    if (faceSet.fNum) {
      const findObj: any = faceSetInfo.find((el: any) => el.fNum === faceSet.fNum);
      if (findObj) {
        setFaceSetState({ fNum: findObj.fNum, fName: findObj.cdNamd, fLocation: findObj.fLocation, fLocationName: findObj.fLocationName, fInout: findObj.fInout });
      } else {
        setFaceSetState({ fNum: '', fName: '', fLocation: '', fLocationName: '', fInout: '' });
      }
    }

    const escKeyEscClose = (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        onClickClose();
      }
    };
    window.addEventListener('keydown', escKeyEscClose);
  }, [faceSet.fNum]);

  const searchOnEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (wNameRef.current) {
        onClickSearchWorker();
      }
    }
  };

  if (openSubModal.status === true && openSubModal.type === 'delete') {
    return (
      <ModalBackground onClick={onClickClose} role='presentation'>
        <DeleteModal openModal={openSubModal} setOpenModal={setOpenSubModal} />
      </ModalBackground>
    );
  }
  if (openSubModal.status === true && openSubModal.type !== 'delete') {
    return (
      <ModalBackground>
        <WorkerListModal
          openModal={openSubModal}
          setOpenModal={setOpenSubModal}
          state={selectedUser}
          setState={setSelectedUser}
          isIncludeBlackUser
          useCheckbox={false}
          filter={{ sjCd: '', wName: workerInputSet.wName }}
          onClickClose={onClickClose}
        />
      </ModalBackground>
    );
  }

  const isLoading = isLoadingFaseSetList || isLoadingSiteJoinList || isLoadingFaceSetInfo;
  const isError = isErrorFaceSetList || isErrorSiteJoinList || isErorrFaceSetInfo;

  if (isError) return <IssueGuide />;

  return (
    <ModalBackground onClick={onClickClose} role='presentation'>
      <Modal>
        <div
          className='modal height-fit signup'
          role='presentation'
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {isLoading ? (
            <LoadingModalBackground>
              <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
            </LoadingModalBackground>
          ) : (
            <>
              <div className='inputForm-head'>
                <div className='modalHeaderTitle'>{t('개인 출역 수기등록')}</div>
                <div className='closeBtn' onClick={onClickClose} role='presentation'>
                  <span className='material-symbols-rounded'>close</span>
                </div>
              </div>
              <div className={`inputForm-body ${isViewMode ? 'view' : undefined}`}>
                <div className='modal-contents'>
                  <div className='modal-element'>
                    <label htmlFor='wName' className='required'>
                      {t('근로자명')}
                    </label>
                    <div className='inputWithBtnRow'>
                      <Input
                        className='required'
                        type='text'
                        name='wName'
                        inputRef={wNameRef}
                        state={workerInputSet}
                        setState={setWorkerInputSet}
                        onKeyDown={searchOnEnterKeyDown}
                        getBorderStyle={isSaveClicked ? applyBorderStyle(workerInputSet.wName, 'red', 'wName') : undefined}
                      />
                      <BtnGhost onClick={onClickSearchWorker} className='searchBtn'>
                        {t('검색')}
                        <span className='shortcut-f9'>F9</span>
                      </BtnGhost>
                    </div>
                  </div>
                  <div className='modal-element'>
                    <label htmlFor='sjCd' className='required'>
                      {t('협력업체')}
                    </label>
                    <SearchSelectBoxSm
                      options={siteJoinList}
                      defaultOption={sjCd.cdName || t('선택')}
                      state={sjCd}
                      setState={setSjCd}
                      stateKey='sjCd'
                      codeKey='cdName'
                      initiateKey={workerInputSet.wCd}
                      getBorderStyle={isSaveClicked ? applyBorderStyle(sjCd.sjCd, 'red', 'sjCd') : undefined}
                      optionHeight='height-sm'
                    />
                  </div>
                  <div className='modal-element'>
                    <label htmlFor='blackYn'>{t('인식기기 명')}</label>
                    <SelectBox
                      options={faceSetList}
                      defaultOption={t('선택')}
                      state={faceSet}
                      setState={setFaceSet}
                      stateKey='fNum'
                      initiateKey={faceSet.fNum}
                      disabled={!auth.updateAuth && !auth.createAuth}
                      optionHeight='height-sm'
                    />
                  </div>
                  <div className='modal-row'>
                    <div className='modal-element'>
                      <label htmlFor='sjName'>{t('장비위치')}</label>
                      <div className='searchAfterText'>{faceSetState.fLocationName}</div>
                    </div>

                    <div className='modal-element'>
                      <label htmlFor='sjName'>{t('장비 출입구분')}</label>
                      <div className='searchAfterText'>{faceSetState.fInout !== '' && (faceSetState.fInout === 'I' ? t('출근') : t('퇴근'))}</div>
                    </div>
                  </div>

                  <div className='datePickerWrapper'>
                    <label htmlFor='aDate' className='required'>
                      {t('출역일자')}
                    </label>
                    <div>
                      <DatePicker startDate={workerInputSet.aDate} setDate={setADate} popperPlacement='top' isSaveClicked={isSaveClicked} />
                    </div>
                  </div>

                  <div className='modal-element'>
                    <label htmlFor='pwdLimitCount' className='required'>
                      {t('출역시간')}
                    </label>
                    <div className='modal-element-time'>
                      <div>
                        <ReactSelect
                          defaultValue={{ label: '00', value: '00' }}
                          options={hourList2}
                          value={hour}
                          state={hour}
                          setState={setHour}
                          isClearable={false}
                          isSearchable={false}
                          optionsPlacement='top'
                        />
                        <span>{t('시')}</span>
                      </div>
                      <div>
                        <ReactSelect
                          defaultValue={{ label: '00', value: '00' }}
                          options={minList2}
                          value={min}
                          state={min}
                          setState={setMin}
                          isClearable={false}
                          isSearchable={false}
                          optionsPlacement='top'
                        />
                        <span>{t('분')}</span>
                      </div>
                      <div>
                        <ReactSelect
                          defaultValue={{ label: '00', value: '00' }}
                          options={minList2}
                          value={sec}
                          state={sec}
                          setState={setSec}
                          isClearable={false}
                          isSearchable={false}
                          optionsPlacement='top'
                        />
                        <span>{t('초')}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal-footer flex-end'>
                <BtnGhost onClick={onClickClose}>{t('취소')}</BtnGhost>
                {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={onClickSaveWorkerAttend}>{t('저장')}</BtnBlue>}
              </div>
            </>
          )}
        </div>
      </Modal>
    </ModalBackground>
  );
};

export default EnrollmentAttendModal;
