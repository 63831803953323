import styled from 'styled-components';
import ClickableContainer from '../../s_cctv/S_cctv1/RealtimeCCTV/ClickableContainer';
import { BtnGhost } from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { IUser, userState } from '../../../atoms';
import { useRecoilValue } from 'recoil';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import SearchSelectBoxSm from '../../../components/SearchSelectBoxSm';
import useSetJobtype from '../../../utils/useSetJobtype';

const Root = styled.div`
  padding: 0.5rem;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: ${({ theme }: { theme: any }) => theme.text_secondary};
  display: flex;
  gap: 0.5rem;
  .title {
    flex-shrink: 0;
    width: fit-content;
    font-weight: 600;
    margin-right: 0.5rem;
  }
  .search {
    width: 20rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
    border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    border-radius: 0.25rem;
    padding: 0 0.5rem;
    input {
      padding: 0;
      font-size: 0.875rem;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      :focus {
        outline: none;
      }
    }
    .material-symbols-rounded {
      user-select: none;
      font-size: 1.375rem;
      &.close-icon {
        font-variation-settings: 'FILL' 1, 'wght' 500;
        cursor: pointer;
        font-size: 1.25rem;
        border-radius: 0.25rem;
        padding: 0.125rem;
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        &:hover {
          box-shadow: 0 0 0.25rem 0.125rem ${({ theme }: { theme: any }) => theme.tonal_deep};
        }
        &:active {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
        }
        &.hidden {
          display: none;
        }
      }
    }
  }
  button {
    flex-shrink: 0;
    padding: 0 0.75rem;
    width: 4.5rem;
    height: 2.5rem;
    font-size: 0.875rem;
    border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
    }
  }
`;

interface Props {
  data: JobtypeVideoRow[];
  setTableState: Dispatch<SetStateAction<JobtypeVideoRow[]>>;
  setSelectedIndex: Dispatch<SetStateAction<number | null>>;
  wPrejobtype: { type: string; wPrejobtype: string; cdName: string };
  setWPrejobtype: Dispatch<SetStateAction<{ type: string; wPrejobtype: string; cdName: string }>>;
  wJobtype: { type: string; wJobtype: string; cdName: string };
  setWJobtype: Dispatch<SetStateAction<{ type: string; wJobtype: string; cdName: string }>>;
}

const JobTypeFilter = ({ data, setTableState, setSelectedIndex, wPrejobtype, setWPrejobtype, wJobtype, setWJobtype }: Props) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue<IUser>(userState);

  // 공종 & 직종 목록
  const { prejobtypeList, jobtypeList } = useSetJobtype(wPrejobtype.wPrejobtype);

  const [jikJongList, setJikJongList] = useState<any[]>([]);
  const [initiate, setinitiate] = useState('');

  useEffect(() => {
    setWJobtype({ type: 'wJobtype', wJobtype: '', cdName: '' });
    if (wPrejobtype.wPrejobtype === '') setJikJongList([]);
  }, [wPrejobtype.wPrejobtype]);

  useEffect(() => {
    setJikJongList(jobtypeList);
  }, [jobtypeList]);

  useEffect(() => {
    onClickSearch();
  }, [wPrejobtype.wPrejobtype, wJobtype.wJobtype]);

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    setinitiate(`${Math.random()}`);
    setWJobtype({ type: 'wJobtype', wJobtype: '', cdName: '' });
    setWPrejobtype({ type: 'wPrejobtype', wPrejobtype: '', cdName: '' });
    setSelectedIndex(null);
  };

  // 검색 버튼 클릭
  const onClickSearch = () => {
    if (data) {
      const userPrejobtypeYn = userInfo.prejobtypeYn;
      const searchJobtypeIdx = userPrejobtypeYn === 'Y' ? `${wPrejobtype.wPrejobtype}${wJobtype.wJobtype}` : wJobtype.wJobtype;
      const filterArray = data.filter((el: JobtypeVideoRow) => el.preJobtypeYn === userPrejobtypeYn);
      const result = filterArray.filter((item: JobtypeVideoRow) => item.jobtypeIdx && item.jobtypeIdx.includes(searchJobtypeIdx));

      if (searchJobtypeIdx) setTableState(result);
      else setTableState(data);
    }
  };

  return (
    <Root>
      <ClickableContainer
        onClick={() => onClickInitiateSearchOption()}
        disabled={(userInfo.prejobtypeYn === 'Y' && wPrejobtype.wPrejobtype === '' && wJobtype.wJobtype === '') || (userInfo.prejobtypeYn !== 'Y' && wJobtype.wJobtype === '')}
        button={<BtnGhost>{t('초기화')}</BtnGhost>}
      />
      {userInfo.prejobtypeYn === 'Y' && (
        <SearchSelectBoxSm
          options={prejobtypeList}
          defaultOption={t('공종 전체')}
          state={wPrejobtype}
          setState={setWPrejobtype}
          stateKey='wPrejobtype'
          codeKey='cdName'
          initiateKey={initiate}
          filterbar='filter-1-right'
          comboWidth='expand-box-md'
          optionHeight='height-md'
        />
      )}
      <SearchSelectBoxSm
        options={jikJongList}
        defaultOption={t('직종 전체')}
        state={wJobtype}
        setState={setWJobtype}
        stateKey='wJobtype'
        codeKey='cdName'
        initiateKey={wJobtype.wJobtype}
        filterbar='filter-1-right'
        comboWidth='expand-box-md'
        optionHeight='height-md'
      />
    </Root>
  );
};

export default JobTypeFilter;
