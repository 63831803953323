/**
 * 작성자 : 홍선영
 * 날짜 : 2023.06.05
 * 경로 : 설정관리-환경설정-사용자로그인탭 (현장관리자)
 */

import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { pageInfoState, userState } from '../../../atoms';
import { BtnBlue } from '../../../components/Button';
import { logPost } from '../../../services/log';
import { apiGet, apiPost } from '../../../services/_common';
import { applyBorderStyle } from '../../../utils/applyBorderStyle';
import { useSetAuth } from '../../../utils/useSetAuth';
import { ERROR } from '../../../_constants';

const Root = styled.div`
  align-items: center;
  overflow: auto !important;
  .inputForm {
    /* position: absolute; */
    width: 100%;
    padding: 1rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow: auto;
    @media (min-width: 1024px) {
      padding: 3rem;
      width: 40rem;
    }
    > .common {
      background-color: rgba(255, 0, 0, 0.05);
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      height: 3rem;
      padding: 0 1rem;
      color: red;
      span.material-symbols-rounded {
        font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
      }
    }
    .setting-row {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
      }
      .textGroup {
        label {
          font-weight: 500;
          color: ${({ theme }: { theme: any }) => theme.text_primary};
        }
        p {
          font-size: 0.875rem;
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        }
      }
      .textInInput {
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        border-radius: 0.25rem;
        display: flex;
        align-items: center;
        width: 6rem;
        input {
          outline: none;
          text-align: center;
          background-color: transparent;
          font-weight: 500;
          padding: 0;
        }
        span {
          border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
          padding: 0 0.75rem;
          user-select: none;
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        }
      }
    }
  }
  .buttonsWrapper {
    display: flex;
    justify-content: flex-start;
    @media (min-width: 768px) {
      justify-content: flex-end;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

interface ILoginSet {
  pwdReuseTerm: number | string;
  pwdLimitCount: number | string;
  pwdLimitTerm: number | string;
  sessionTimeout: number | string;
}

const UserLoginSetting = () => {
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const [userInput, setUserInput] = useState<ILoginSet>({ pwdReuseTerm: 0, pwdLimitCount: 0, pwdLimitTerm: 0, sessionTimeout: 0 });
  const { pwdReuseTerm, pwdLimitCount, pwdLimitTerm, sessionTimeout } = userInput;
  const userInfo = useRecoilValue(userState);
  const pageInfo = useRecoilValue(pageInfoState);
  const pwdReuseTermRef = useRef<HTMLInputElement>(null);
  const pwdLimitCountRef = useRef<HTMLInputElement>(null);
  const pwdLimitTermRef = useRef<HTMLInputElement>(null);
  const sessionTimeoutRef = useRef<HTMLInputElement>(null);
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값

  const userCode = {
    hCd: userInfo.hCd,
    sCd: userInfo.sCd,
  };

  useEffect(() => {
    getLoginSettingAPI();
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '환경 설정 > 사용자 로그인',
      action: '조회',
      etc: ``,
    });
  }, []);

  const getLoginSettingAPI = async () => {
    const res = await apiGet({ path: '/setting/login', req: userCode });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const isEmpty = Object.keys(data).length === 0;
      if (!isEmpty) {
        setUserInput(data.loginSet);
      }
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  const saveLoginSettingAPI = async () => {
    const req = {
      pwdReuseTerm: userInput.pwdReuseTerm?.toString(),
      pwdLimitCount: userInput.pwdLimitCount?.toString(),
      pwdLimitTerm: userInput.pwdLimitTerm?.toString(),
      sessionTimeout: userInput.sessionTimeout?.toString(),
      hCd: userCode.hCd,
      sCd: userCode.sCd,
      writer: userInfo.userId,
      editor: userInfo.userId,
    };
    const res = await apiPost({ path: '/setting/login', contentType: 'application/json', req });
    const { statusCode, message } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '환경 설정 > 사용자 로그인',
        action: '저장',
        etc: ``,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setUserInput((prev) => ({ ...prev, [name]: value }));
  };

  const onClickSave = () => {
    setIsSaveClicked(true);

    if (userInput.pwdReuseTerm === '') {
      toast.warning(t('빈칸을 입력하세요'));
      return pwdReuseTermRef.current?.focus();
    }
    if (userInput.pwdLimitCount === '') {
      toast.warning(t('빈칸을 입력하세요'));
      return pwdLimitCountRef.current?.focus();
    }
    if (userInput.pwdLimitTerm === '') {
      toast.warning(t('빈칸을 입력하세요'));
      return pwdLimitTermRef.current?.focus();
    }
    if (userInput.sessionTimeout === '') {
      toast.warning(t('빈칸을 입력하세요'));
      return sessionTimeoutRef.current?.focus();
    }
    if (Number(userInput.pwdReuseTerm) > 730) {
      toast.warning(t('패스워드 재사용 금지기간은 최대 730일 입니다'));
      return pwdReuseTermRef.current?.focus();
    }
    if (Number(userInput.pwdLimitCount) > 5) {
      toast.warning(t('패스워드 오류 제한 횟수는 최대 5회 입니다'));
      return pwdLimitCountRef.current?.focus();
    }
    if (Number(userInput.pwdLimitTerm) > 365) {
      toast.warning(t('패스워드 변경 기간은 최대 365일 입니다'));
      return pwdLimitTermRef.current?.focus();
    }
    if (Number(userInput.sessionTimeout) > 300) {
      toast.warning(t('세션 타임아웃 시간은 최대 300분 입니다'));
      return sessionTimeoutRef.current?.focus();
    }
    return saveLoginSettingAPI();
  };

  return (
    <Root>
      <div className='inputForm'>
        {/* <div className='common'>&#8251; 각 항목에 0 입력 시 미적용</div> */}
        <div className='common'>
          <span className='material-symbols-rounded'>warning</span>
          {t('각 항목에 0 입력 시 미적용')}
        </div>
        <div className='setting-row'>
          <div className='textGroup'>
            <label htmlFor='pwdReuseTerm'>{t('패스워드 재사용 금지기간')}</label>
            <p>
              {t('일수. 예: 1년')}&rarr; 365. {t('최대 2년(730)')}
            </p>
            {/* <p>1년은 365일 입력, 최대 730일(2년) 가능</p> */}
          </div>
          <div className='textInInput'>
            <input
              type='number'
              id='pwdReuseTerm'
              name='pwdReuseTerm'
              value={pwdReuseTerm}
              ref={pwdReuseTermRef}
              onChange={onChangeInput}
              disabled={!auth.createAuth && !auth.updateAuth}
              style={isSaveClicked ? applyBorderStyle(pwdReuseTerm, 'red', 'pwdReuseTerm') : undefined}
            />
            <span>{t('일')}</span>
          </div>
        </div>
        <div className='setting-row'>
          <div className='textGroup'>
            <label htmlFor='pwdLimitCount'>{t('패스워드 오류 제한 횟수')}</label>
            <p>{t('횟수. 최대 5회')}</p>
            {/* <p>최대 5회까지 가능</p> */}
          </div>
          <div className='textInInput'>
            <input
              type='number'
              id='pwdLimitCount'
              name='pwdLimitCount'
              value={pwdLimitCount}
              ref={pwdLimitCountRef}
              onChange={onChangeInput}
              disabled={!auth.createAuth && !auth.updateAuth}
              style={isSaveClicked ? applyBorderStyle(pwdLimitCount, 'red', 'pwdLimitCount') : undefined}
            />
            <span>{t('회')}</span>
          </div>
        </div>
        <div className='setting-row'>
          <div className='textGroup'>
            <label htmlFor='pwdLimitTerm'>{t('패스워드 변경 기간')}</label>
            <p>
              {t('일수. 예: 3개월')} &rarr; 90. {t('최대 1년(365)')}
            </p>
            {/* <div>3개월은 90 입력. 최대 365(1년)</div> */}
          </div>
          <div className='textInInput'>
            <input
              type='number'
              id='pwdLimitTerm'
              name='pwdLimitTerm'
              value={pwdLimitTerm}
              ref={pwdLimitTermRef}
              onChange={onChangeInput}
              disabled={!auth.createAuth && !auth.updateAuth}
              style={isSaveClicked ? applyBorderStyle(pwdLimitTerm, 'red', 'pwdLimitTerm') : undefined}
            />
            <span>{t('일')}</span>
          </div>
        </div>
        <div className='setting-row'>
          <div className='textGroup'>
            <label htmlFor='sessionTimeout'>{t('세션 타임아웃 시간')}</label>
            <p>
              {t('분단위. 예: 1시간 30분')} &rarr; 90. {t('최대 5시간(300)')}
            </p>
            {/* <div>1시간 30분은 90 입력. 최대 300까지(5시간)</div> */}
          </div>
          <div className='textInInput'>
            <input
              type='number'
              id='sessionTimeout'
              name='sessionTimeout'
              value={sessionTimeout}
              ref={sessionTimeoutRef}
              onChange={onChangeInput}
              disabled={!auth.createAuth && !auth.updateAuth}
              style={isSaveClicked ? applyBorderStyle(sessionTimeout, 'red', 'sessionTimeout') : undefined}
            />
            <span>{t('분')}</span>
          </div>
        </div>
        {(auth.createAuth || auth.updateAuth) && (
          <div className='buttonsWrapper'>
            <div className='flexRow'>
              <BtnBlue onClick={onClickSave}>{t('저장')}</BtnBlue>
            </div>
          </div>
        )}
      </div>
    </Root>
  );
};

export default UserLoginSetting;
