/**
 * 작성자 : 김광민
 * 날짜 : 2023.12.19
 * 경로 : 터널 관리 - 구역 현황 관리 - 차트 모달
 */

import styled from 'styled-components';

const Thead = styled.thead`
  display: flex;
  position: relative;
  color: ${({ theme }: { theme: any }) => theme.text_tertiary};
  font-size: 0.875rem;
  word-break: keep-all;
  z-index: 10;
  position: sticky;
  top: 0;
  width: 100%;
  min-width: fit-content;
  border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  background-color: ${({ theme }: { theme: any }) => theme.board};
  &.borderNone {
    border: none;
  }
  tr {
    display: flex;
    flex-grow: 1;
  }
  th {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: 3rem;
    flex-shrink: 0;
    background-color: ${({ theme }: { theme: any }) => theme.board};
  }
  .tableTitle {
    font-size: 0.875rem;
    display: flex;
    justify-content: flex-start;
    padding: 0 1rem;
    align-items: center;
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
  }
  .slashUnit {
    margin: 0 0.25rem;
    font-weight: 300;
    color: ${({ theme }: { theme: any }) => theme.text_disabled};
  }
  .meterUnit {
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    color: ${({ theme }: { theme: any }) => theme.filled_slate};
    margin-left: 0.25rem;
    border-radius: 0.25rem;
    height: 1.25rem;
    width: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2px;
  }
`;

const Tbody = styled.tbody`
  width: 100%;
  min-width: fit-content;
  tr {
    position: relative;
    display: flex;
    max-height: 3.25rem;
    > td {
      display: flex;
      align-items: center;
      height: 3rem;
      padding: 0 0.25rem;
      flex-shrink: 0;
      font-size: 0.875rem;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      background-color: ${({ theme }: { theme: any }) => theme.board};
      gap: 0.5rem;
      &.tableTitle {
        justify-content: flex-start;
        padding: 0 1rem;
      }
      span.length {
        width: 4.5rem;
        font-weight: 400;
        padding: 0 0.75rem;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        &.start {
          color: ${({ theme }: { theme: any }) => theme.filled_blue};
          background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
          justify-content: flex-end;
        }
        &.end {
          color: ${({ theme }: { theme: any }) => theme.filled_amber};
          background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
        }
        .emphasize {
          font-weight: 500;
        }
      }
    }
  }
`;

const Tfoot = styled.tfoot`
  padding: 0.5rem 0;
  display: flex;
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }: { theme: any }) => theme.board};
  min-width: 40rem;
  tr {
    display: flex;
  }
  .total {
    padding: 0 1rem;
    font-size: 0.875rem;
    font-weight: 400;
    color: ${({ theme }: { theme: any }) => theme.text_disabled};
    border-radius: 0.5rem;
    span {
      margin-left: 0.5rem;
      font-size: 0.875rem;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      border-radius: 0.5rem;
      padding: 0 0.5rem;
    }
    display: flex;
    align-items: center;
  }
`;

export { Thead, Tbody, Tfoot };
