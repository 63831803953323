/**
 * 작성자 : 한영광
 * 날짜 : 2023.12.27
 * 기능 : 터널 관리 > 굴진/일반 정보 > 굴진/일반 정보 팝업 > CCTV 정보 탭 (현장용)
 */
/**
 * 수정자 : 김광민
 * 수정 날짜 : 2024.02.15
 * 수정 이유 : 가독성 향상과 유지보수를 위한 코드 분리, 변수 변경 및 삭제, 재사용 코드 컴포넌트화, 스타일 수정, 일부 로직 수정
 */

import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiGet } from '../services/_common';
import { IUser } from '../atoms';
import { BtnBlue } from './Button';
import { PulseLoader } from 'react-spinners';
import TareaCCTVInfoContent from './TareaCCTVInfoContent';

interface Props {
  rowState: TareaData;
  userInfo: IUser;
  onClickPrev: any;
  onClickNext: any;
  auth: any;
  showPrevNextBtn: boolean;
}

const TareaCCTVInfo = ({ rowState, userInfo, onClickPrev, onClickNext, auth, showPrevNextBtn }: Props) => {
  const { t } = useTranslation();
  const [startPointList, setStartPointList] = useState<CctvList[]>([]);
  const [endPointList, setEndPointList] = useState<CctvList[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const dependencies = [userInfo.hCd, userInfo.sCd, rowState];
  const params = { hCd: userInfo.hCd, sCd: userInfo.sCd, tatCd: rowState.tatCd };

  const tareaCamQuery = useQuery(['tareaCameraGet', ...dependencies], () => apiGet({ path: '/tarea/camera', req: params }), {
    enabled: dependencies.every(Boolean),
    onSuccess: (fetchedData) => {
      if (!fetchedData) {
        console.error('API 호출 결과가 없습니다.');
      }

      // 데이터 필터링 및 상태 설정 함수
      const filterAndSetData = (data: CctvList[], gubun: string, setState: React.Dispatch<React.SetStateAction<any[]>>) => {
        // 데이터 필터링
        const filteredData = data.filter((item) => item.tatGubun === gubun).map((item, index: number) => ({ ...item, subCd: index + 1, cdName: item.cName }));
        // 상태 설정
        setState(filteredData);
      };

      // 시점, 종점 데이터 상태 설정
      const { tareaCList } = fetchedData.data.data;
      filterAndSetData(tareaCList, 'S', setStartPointList);
      filterAndSetData(tareaCList, 'E', setEndPointList);
      setIsLoading(false);
    },
    onError: (error) => {
      console.error('API 호출 중 오류 발생:', error);
    },
  });

  if (tareaCamQuery.isLoading) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  if (tareaCamQuery.isError) {
    console.error('API 호출 중 오류 발생:', tareaCamQuery.isError);
  }

  return (
    <>
      <TareaCCTVInfoContent userInfo={userInfo} rowState={rowState} startPointList={startPointList} endPointList={endPointList} isLoading={isLoading} />
      <div className='modal-footer flex-between'>
        {showPrevNextBtn ? (
          <div className='flex-basic arrows'>
            <BtnBlue onClick={onClickPrev}>
              <span className='material-symbols-rounded'>arrow_back_ios_new</span>
              <p>{t('이전')}</p>
              <div>
                <p>Page</p>
                <p>Up</p>
              </div>
            </BtnBlue>
            <BtnBlue onClick={onClickNext}>
              <div>
                <p>Page</p>
                <p>Down</p>
              </div>
              <p>{t('다음')}</p>
              <span className='material-symbols-rounded'>arrow_forward_ios</span>
            </BtnBlue>
          </div>
        ) : undefined}
        <div className='flex-between'>
          <div id='divPlugin' className='cctvWrapperHidden' />
        </div>
      </div>
    </>
  );
};

export default TareaCCTVInfo;
