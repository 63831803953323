import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import DatePickerComponent from '../../../../components/DatePicker';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 20rem;
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};

  .react-datepicker {
    outline: none !important;
    border: none;
    .react-datepicker__triangle {
      display: none;
    }
    .react-datepicker__triangle::before {
      border-bottom-color: ${({ theme }: { theme: any }) => theme.outline} !important;
    }

    .react-datepicker__month-container {
      max-width: 21rem;
    }

    .react-datepicker__day--outside-month {
      cursor: default;
      visibility: hidden;
    }
  }
  .react-datepicker__header {
    border-bottom: none;
  }
  .custom-header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    // range picker와 스타일 통일
    justify-content: space-between;
    padding: 0.25rem 1rem;

    .selectGroup {
      display: flex;
      gap: 0.75em;
      > .selectBox {
        position: relative;
        > span {
          position: absolute;
          right: 0.25rem;
          top: 50%;
          transform: translateY(-50%);
          user-select: none;
          pointer-events: none;
          font-size: 1.125rem;
          color: ${({ theme }: { theme: any }) => theme.text_disabled};
        }
      }
    }
    select {
      width: 30%;
      width: 5rem;
      /* &::after {
        content: '월';
        color: ${({ theme }: { theme: any }) => theme.color.zinc_300};
      } */
      cursor: pointer;
      option {
        text-align: left;
      }
    }
    > div:not(.selectGroup) {
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.25rem;
      /* &:hover {
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
      } */
      &:first-child,
      &:last-child {
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
        user-select: none;
        color: ${({ theme }: { theme: any }) => theme.text_primary};
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
      }
    }

    /* > :nth-child(2) {
      padding: 0 1rem;
    } */
  }
  .react-datepicker__day {
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    color: ${({ theme }: { theme: any }) => theme.btn_text};
    background-color: ${({ theme }: { theme: any }) => theme.selected_primary};
    border-radius: 0.25rem;
    // range picker와 스타일 통일
    height: 1.625rem !important;
    width: 2.5rem !important;
    // margin: 0 auto;
    border-radius: 1rem;
    border-radius: 1rem;
    position: relative;
    &:hover {
      height: 2.25rem !important;
      width: 2.75rem !important;
      border-radius: 0.25rem;
    }
  }
  .react-datepicker__day-names {
    // height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.4rem;
    > div {
      user-select: none;
      color: ${({ theme }: { theme: any }) => theme.text_disabled};
      width: 2.5rem;
      flex-grow: 1;
      // height: 1rem;
    }
  }
  .react-datepicker__month {
    margin: 0 0.8rem;
    text-align: center;
    padding-bottom: 0.75rem;
    .react-datepicker__week,
    .react-datepicker__day-names {
      height: 2.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      > div {
        width: 2.5rem;
        height: inherit;
        justify-content: center;
        align-items: center;
        display: flex;
        // range picker와 스타일 통일
        width: 2.75rem;
        height: 2.25rem;
        font-weight: 400;
        font-size: 0.75rem;
        padding-top: 0.125rem;
        &:hover {
          background-color: ${({ theme }: { theme: any }) => theme.selected_primary};
          color: white;
        }
      }
    }
  }

  .react-datepicker__day--selected {
    color: ${({ theme }: { theme: any }) => theme.color.zinc_100};
    border-radius: 1.042em;
    right: 2px;
  }

  .redDot {
    position: relative;
    top: -5px;
    right: 3px;

    &::after {
      content: '•';
      padding: 0 0.25rem;
      color: red;
      font-size: 1.2rem;
      position: absolute;
    }
  }
`;

interface Props {
  date: string;
  setDate: Dispatch<SetStateAction<string>>;
  records: Records | null;
}

const RecordCalendar = ({ date, setDate, records }: Props) => {
  return (
    <Root>
      <div className='datePickerWrapper'>
        <DatePickerComponent startDate={date} setDate={setDate} popperPlacement='bottom' inline renderDayPointer={records !== null} records={records} />
      </div>
    </Root>
  );
};

export default RecordCalendar;
