import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 0.5rem;
  border-radius: 0.25rem;
  .text {
    z-index: 1;
    visibility: hidden;
    position: absolute;
    word-break: keep-all;
    width: 20rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: center;
    transform: translateX(-50%);
    top: 2.5rem;
    left: 50%;
    background-color: ${({ theme }: { theme: any }) => theme.bw};
    color: ${({ theme }: { theme: any }) => theme.bw_inverse};
    padding: 0.5rem;
    border-radius: 0.25rem;
    transition: all 0.15s ease-in-out;
  }
  &:hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    .text {
      visibility: visible;
    }
  }
  .material-symbols-rounded {
    color: ${({ theme }: { theme: any }) => theme.filled_slate};
  }
`;

type Props = {
  icon: string;
  text: string;
};

const TooltipWithIcon = ({ icon, text }: Props) => {
  const { t } = useTranslation();
  return (
    <Root>
      <span className='material-symbols-rounded'>{icon}</span>
      <span className='text'>{t(text)}</span>
    </Root>
  );
};

export default TooltipWithIcon;
