import styled from 'styled-components';

export const CctvLeftSideMenuStyle = styled.div`
  flex: 0 0 21rem;
  width: 21rem;
  height: 100%;
  height: 100vh;
  height: 100dvh;
  border-right: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  flex-direction: column;
  > .selectStream > div > ul > li {
    min-width: 100%;
  }
  .list {
    height: 30%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .openFolder,
  .selectStream {
    padding: 0.25rem 0.5rem;
  }

  .selectStream {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    > div {
      flex-grow: 1;
    }
  }
  .bookmarkWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    padding-left: 0.5rem;
    gap: 0.5rem;
    border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    > .bookmark {
      all: unset;
      cursor: pointer;
      user-select: none;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};

      .material-symbols-rounded {
        font-size: 24px;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;

        :hover {
          color: ${({ theme }: { theme: any }) => theme.selected_primary};
          font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
        }
      }

      &:hover {
        background-color: transparent;
      }
    }

    > span {
      cursor: pointer;
      user-select: none;
    }

    > :nth-child(2) {
      flex-grow: 1;
      > ul > li {
        width: inherit;
        max-width: none;
      }
    }
  }
  .menuWrapper {
    overflow-y: auto;
    height: calc(100% - 4rem);
    padding: 0.5rem;
    padding-bottom: 1rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  &.hidden {
    display: none;
  }
`;
