/**
 * 작성자 : 김광민
 * 날짜 : 2023.12.19
 * 경로 : 터널 관리 - 구역 현황 관리
 */

import { t } from 'i18next';
import styled from 'styled-components';
import PointIcon from './tunnel/PointIcon';

const TunnelPointContainer = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 8rem;
  gap: 0.5rem;
  align-items: center;
  border-radius: 0.25rem;
  &.reverse {
    flex-direction: row-reverse;
  }
  &.inverse {
    .pointTitle {
      color: ${({ theme }: { theme: any }) => theme.bw_inverse};
    }
  }
  &.small {
    .material-symbols-rounded {
      font-size: 1.25rem;
      padding: 0.375rem;
      border-radius: 0.75rem;
    }
  }
  &.large {
    .material-symbols-rounded {
      font-size: 1.5rem;
      padding: 0.375rem;
      border-radius: 0.75rem;
      font-variation-settings: 'FILL' 0, 'wght' 500, 'GRAD' 0, 'opsz' 24;
      box-shadow: 0px 4px 16px 4px rgba(0, 0, 0, 0.1);

      /* outline: 0.5rem solid ${({ theme }: { theme: any }) => theme.board}; */
    }
  }
  .material-symbols-rounded {
    font-size: 1.375rem;
    padding: 0.375rem;
    border-radius: 0.75rem;
    &.start {
      color: ${({ theme }: { theme: any }) => theme.filled_blue};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
    }
    &.end {
      color: ${({ theme }: { theme: any }) => theme.filled_amber};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
    }
  }
  .pointTitle {
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
  }
  &.vertical {
    flex-direction: column;
    gap: 0.25rem;
    width: 100%;
    .material-symbols-rounded {
      padding: 0.25rem;
      font-size: 1.5rem;
      background-color: transparent;
      color: ${({ theme }: { theme: any }) => theme.bw_inverse};
      &.start {
        color: ${({ theme }: { theme: any }) => theme.filled_blue};
      }
      &.end {
        color: ${({ theme }: { theme: any }) => theme.filled_amber};
      }
    }
    .pointTitle {
      font-size: 0.875rem;
      font-weight: 400;
      /* padding: 0.25rem 0.75rem; */
      border-radius: 1rem;
      /* border-radius: 0.25rem; */
      line-height: 1.25rem;
      color: ${({ theme }: { theme: any }) => theme.text_reverse};
      width: 100%;
      text-align: center;
      word-break: keep-all;
      display: flex;
      height: 1.5rem;
      justify-content: center;
      align-items: center;
      color: ${({ theme }: { theme: any }) => theme.text_reverse};
      &.start {
        background-color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
      }
      &.end {
        background-color: ${({ theme }: { theme: any }) => theme.filled_amber_deep};
      }
    }
  }
`;

type Props = { size?: 'small' | 'large'; reverse?: boolean; inverse?: boolean; vertical?: boolean };

type TunnelPointProps = Props & { name: 'start' | 'end' };

const TunnelPoint = ({ name, size, reverse = false, inverse = false, vertical = false }: TunnelPointProps) => {
  // startPoint 또는 endPoint인지 구분
  const position = name === 'start';

  return (
    <TunnelPointContainer className={`${position ? 'start' : 'end'} ${size ?? ''} ${reverse ? 'reverse' : ''} ${inverse ? 'inverse' : ''} ${vertical ? 'vertical' : ''}`}>
      {position ? <PointIcon isStart classNameOfStyle='start' /> : <PointIcon isStart={false} classNameOfStyle='end' />}
      <span className={`pointTitle ${position ? 'start' : 'end'}`}>{position ? t('시점') : t('종점')}</span>
    </TunnelPointContainer>
  );
};

const StartPoint = ({ size, reverse = false, inverse = false, vertical = false }: Props) => {
  return <TunnelPoint name='start' size={size} reverse={reverse} inverse={inverse} vertical={vertical} />;
};

const EndPoint = ({ size, reverse = false, inverse = false, vertical = false }: Props) => {
  return <TunnelPoint name='end' size={size} reverse={reverse} inverse={inverse} vertical={vertical} />;
};

export { StartPoint, EndPoint };
