/**
 * 작성자 : 홍선영
 * 날짜 : 2023.10.26
 * 경로 : 멀티 비디오 플레이어 및 하단 컨트롤러 (플레이어 배율, 페이징)
 */

import { useEffect, useRef, useState, Dispatch, SetStateAction } from 'react';
import { IoCaretBack, IoCaretForward, IoPlayBackSharp, IoPlayForwardSharp } from 'react-icons/io5';
import JSMpegPlayer from '@cycjimmy/jsmpeg-player';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Root = styled.div<RootStyle>`
  height: 100%;
  display: flex;
  flex-direction: column;
  .cctvWrapper {
    margin-bottom: 0.5rem;
    svg {
      display: none;
    }
    > * {
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
    }
    .tag {
      position: absolute;
      z-index: 999;
      color: white;
      background-color: black;
      border-radius: 3px;
      margin: 0.3rem;
      padding: 0.2rem 0.5rem;
    }

    .cover {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 99;
    }

    .hovered-icon:hover {
      color: orange;
      transform: scale(1.2);
      cursor: pointer;
    }

    display: grid;
    padding: 0.5rem;
    grid-template-columns: repeat(${(props) => props.colCount}, 1fr);
    grid-template-rows: repeat(${(props) => props.colCount}, 1fr);
    grid-gap: 0.25rem;
    background: #151521;
    flex-grow: 1;
    overflow: auto;
    > div {
      border-radius: 0.5rem;
      overflow: hidden;
    }
    .controllerWrapper {
      border-radius: 0 0 0.75rem 0.75rem;
    }

    > div {
      > canvas {
        background-color: black;
      }
      > :nth-child(2) {
        width: 100%;
        height: 100%;

        > svg {
          fill: black;
          height: inherit;
          max-height: inherit;
          max-width: inherit;
          width: inherit;
        }
      }
    }

    // 텍스트 드래그방지
    .cameraLabel {
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
    }
  }

  .controllerWrapper {
    padding: 0 0.5rem;
    display: flex;
    justify-content: space-between;
    border-radius: 0rem 0rem 0.8rem 0.8rem;
    .leftControl,
    .rightControl {
      display: flex;
    }
    > div {
      flex: 0.3;
    }
    .control {
      display: flex;
      gap: 0.5rem;

      .controllerBtn {
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 0.5rem;
        cursor: pointer;
        border: 1px solid ${({ theme }: { theme: any }) => theme.tonal_deep};
        border-radius: 5px;
        width: 3rem;
        height: 3rem;
        text-align: center;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};

        &:hover {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
        }
      }
    }

    .leftControl {
    }

    .middleControl {
      justify-content: center;
      gap: 0.25rem;
      .controllerBtn {
        border: none;
        &:hover {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
        }
      }
    }
    .rightControl {
      > div {
        display: flex;
        flex: 0.5;
      }
    }

    .activePage {
      border: none;
      font-weight: 500;
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
    }

    *[data-tooltip] {
      position: relative;
      z-index: 999;
    }

    *[data-tooltip]::after {
      content: attr(data-tooltip);

      position: absolute;
      top: -28px;
      right: -20px;
      width: -webkit-fill-available;

      pointer-events: none;
      opacity: 0;
      -webkit-transition: opacity 0.15s ease-in-out;
      -moz-transition: opacity 0.15s ease-in-out;
      -ms-transition: opacity 0.15s ease-in-out;
      -o-transition: opacity 0.15s ease-in-out;
      transition: opacity 0.15s ease-in-out;

      display: block;
      background: #fff;
      padding: 2px;
      border: 1px solid #c0c0c0;
      border-radius: 4px;
      box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.4);
    }

    *[data-tooltip]:hover::after {
      opacity: 1;
    }
  }

  .emptyContainer {
    display: flex;
    color: ${({ theme }: { theme: any }) => theme.color.zinc_200};
  }
`;

interface ICameraObject {
  wsNum: string;
  nName: string;
  cName: string;
}

interface IProps {
  currentCameraInfo: ICameraObject[]; // 현재 화면에 보여지고있는(페이징 된) 플레이어들의 정보
  totalCameraInfo: ICameraObject[]; // 전체 플레이어 정보 (페이징되지 않은)
  cctvDivisions: number; // 화면분할 수
  setCctvDivisions: Dispatch<SetStateAction<any>>; // 화면분할 전환 시 setState
  currentPage: number; // 현재 보고 있는 페이지 번호
  setCurrentPage: Dispatch<SetStateAction<number>>; // 페이지 이동 시 setState
  dashboard: boolean; // 대시보드에 들어간 컴포넌트인지 여부
}

interface RootStyle {
  colCount: number;
}

const isCameraListExist: (cameraCnt: number) => boolean = (cameraCnt: number) => {
  if (cameraCnt > 0) return true;
  return false;
};

const MultiVideoPlayer = ({ cctvDivisions, setCctvDivisions, currentPage, setCurrentPage, currentCameraInfo, totalCameraInfo, dashboard }: IProps) => {
  const { t } = useTranslation();
  const playerRefs = useRef<(any | null)[]>([]);
  const [visiblePageNumbers, setVisiblePageNumbers] = useState<number[]>([]);
  const [pageSetStart, setPageSetStart] = useState<number>(1);
  const [pageSetEnd, setPageSetEnd] = useState<number>(5);
  const [totalPages, setTotalPages] = useState<number>(Math.ceil(totalCameraInfo.length / cctvDivisions));
  const [destroy, setDestroy] = useState<boolean>(false);
  const camExist = isCameraListExist(totalCameraInfo.length);

  // 화면분할 클릭 (1분할, 4분할, ...16분할)
  const onClickDivision = (divisionNum: number) => {
    if (camExist) {
      if (divisionNum !== 0) {
        setCctvDivisions(divisionNum);
        if (destroy) {
          setDestroy(false);
          // setDestroyTimer(0);
        }
      }
      // else if (divisionNum === 0 && currentSelectVideo !== null) {
      // 1개화면 선택하고, 풀스크린버튼 클릭한 경우
      // requestFullscreen(currentSelectVideo);
      // }
    }
  };

  useEffect(() => {
    // 현재 페이지가 현재 보이는 범위를 벗어나는지 확인
    if (currentPage < pageSetStart || currentPage > pageSetEnd) {
      // 현재 페이지가 새 visiblePage 범위에 속하도록 범위 조정
      setPageSetStart(Math.floor((currentPage - 1) / 5) * 5 + 1);
    }
  }, [currentPage]);

  useEffect(() => {
    // pageSetStart가 변경될 때마다 현재 5페이지 세트의 끝 페이지 계산
    // 총 페이지를 초과하지 않도록 Math.min을 사용
    setPageSetEnd(Math.min(pageSetStart + 4, totalPages));
  }, [pageSetStart, totalPages]);

  useEffect(() => {
    // 화면분할 선택에 따른 총 페이지 수
    const pages = Math.ceil(totalCameraInfo.length / cctvDivisions);
    setTotalPages(pages);

    const endPage = Math.min(pageSetStart + 4, pages);
    // 현재 카메라 정보 또는 CCTV 분할이 변경될 때 현재 범위에 대한 페이지 번호 배열생성
    setVisiblePageNumbers(Array.from({ length: endPage - pageSetStart + 1 }, (_, i) => i + pageSetStart));
  }, [currentCameraInfo, cctvDivisions, pageSetStart]);

  // 페이지 이동 시 타이머 리셋
  useEffect(() => {
    setDestroy(false);
    playerRefs.current = [];
  }, [currentPage]);

  const goToPage = (page: number) => {
    setCurrentPage(page);
  };

  const goToNextPages = () => {
    if (camExist) {
      // 다음 페이지가 있는 경우에만 다음 페이지 집합으로 이동
      if (pageSetStart + 5 <= totalPages) {
        setCurrentPage(pageSetStart + 5);
      } else if (totalPages <= 5 && currentPage !== totalPages) {
        // 전체 페이지가 5개 이하인 경우 한 번에 한 페이지씩 이동
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const goToPrevPages = () => {
    if (camExist) {
      // 이전 페이지 집합이 있는 경우에만 이동
      if (pageSetStart - 5 >= 1) {
        setCurrentPage(pageSetStart - 5);
      } else if (pageSetStart !== 1) {
        // 전체 페이지를 되돌릴 수 없는 경우 첫 번째 페이지로 이동
        setCurrentPage(1);
        setPageSetStart(1);
      } else if (totalPages <= 5 && currentPage !== 1) {
        // 전체 페이지가 5개 이하인 경우 한 번에 한 페이지씩 이동
        setCurrentPage(currentPage - 1);
      }
    }
  };

  const goToFirstPage = () => {
    if (camExist) setCurrentPage(1);
  };

  const goToLastPage = () => {
    if (camExist) setCurrentPage(totalPages);
  };

  return (
    <Root colCount={cctvDivisions === 1 ? 1 : cctvDivisions === 4 ? 2 : cctvDivisions === 9 ? 3 : 4}>
      <CctvCanvas currentCameraInfo={currentCameraInfo} cctvDivisions={cctvDivisions} dashboard={dashboard} totalCameraInfo={totalCameraInfo} />
      <div className='controllerWrapper'>
        <div className='control leftControl'>
          <div className='controllerBtn' role='presentation' data-tooltip={`1${t('분할')}`} onClick={() => onClickDivision(1)}>
            1
          </div>
          <div className='controllerBtn' role='presentation' data-tooltip={`4${t('분할')}`} onClick={() => onClickDivision(4)}>
            4
          </div>
          <div className='controllerBtn' role='presentation' data-tooltip={`9${t('분할')}`} onClick={() => onClickDivision(9)}>
            9
          </div>
          <div className='controllerBtn' role='presentation' data-tooltip={`16${t('분할')}`} onClick={() => onClickDivision(16)}>
            16
          </div>
          <div className='controllerBtn' role='presentation' data-tooltip={t('전체화면')} onClick={() => onClickDivision(0)}>
            F
          </div>
        </div>
        <div className='control middleControl'>
          <div className='controllerBtn' role='button' tabIndex={0} onClick={goToFirstPage}>
            <IoPlayBackSharp />
          </div>
          <div className='controllerBtn' role='button' tabIndex={0} onClick={goToPrevPages}>
            <IoCaretBack />
          </div>
          {visiblePageNumbers?.map((el, index) => (
            <div key={el} onClick={() => goToPage(el)} className={`controllerBtn ${el === currentPage ? 'activePage' : undefined}`} role='button' tabIndex={0}>
              {el}
            </div>
          ))}
          <div className='controllerBtn' role='button' tabIndex={0} onClick={goToNextPages}>
            <IoCaretForward />
          </div>
          <div className='controllerBtn' role='button' tabIndex={0} onClick={goToLastPage}>
            <IoPlayForwardSharp />
          </div>
        </div>
        <div className='control rightControl'>
          <div> </div>
          <div> </div>
        </div>
      </div>
    </Root>
  );
};

interface ICctvCanvas {
  currentCameraInfo: ICameraObject[];
  cctvDivisions: number; // 화면분할 수
  dashboard: boolean;
  totalCameraInfo: ICameraObject[];
}

const CctvCanvas = ({ currentCameraInfo, cctvDivisions, dashboard, totalCameraInfo }: ICctvCanvas) => {
  const playerRefs = useRef<(any | null)[]>([]);
  const schema = process.env.REACT_APP_SOCKET_SCHEMA;
  const [players, setPlayers] = useState<any[]>([]);
  const [orgPlayerInfo, setOrgPlayerInfo] = useState<any[]>([]);
  const [timer, setTimer] = useState<number>(0);
  const [destroyAll, setDestroyAll] = useState<boolean>(false);
  const DESTROY_TIME = 30;

  useEffect(() => {
    setDestroyAll(false);

    // 현재카메라배열만큼 플레이어객체생성
    const initPlayer = () => {
      const array: any = [];

      currentCameraInfo?.map((source: ICameraObject, index: number) => {
        const port = schema === 'ws' ? `:${source.wsNum}` : source.wsNum;
        const url = `${schema}://${process.env.REACT_APP_SOCKET_IP}${port}`;

        const targetElement = document.getElementById(`video-canvas_${source.wsNum}`);
        if (targetElement) {
          const socketInfo = { index, wsNum: source.wsNum };
          // array.push(socketInfo);
          setOrgPlayerInfo((prev) => [...prev, socketInfo]);

          const playerObj = new JSMpegPlayer.VideoElement(
            targetElement,
            url,
            { autoPlay: false, control: true },
            {
              onVideoDecode: () => {
                if (playerObj.player.video.canPlay === false) {
                  // 소켓연결 실패한 비디오객체는 destroy처리
                  playerObj.destroy();
                }
              },
              onPlay: () => {
                const socketInfo2 = { index, wsNum: source.wsNum, isPlaying: true };
                array.push(socketInfo2);

                // 비디오가 다시 플레이 될 때 replayBtn div 삭제
                const myDiv = document.getElementById(`cctvCanvas_${source.wsNum}`);
                if (myDiv) {
                  const replayBtnToRemove = myDiv.querySelectorAll(`.replayBtn_${source.wsNum}`);
                  if (replayBtnToRemove && replayBtnToRemove.length > 0) {
                    replayBtnToRemove.forEach((element) => {
                      element.remove();
                    });
                  }
                  const disconnectToRemove = myDiv.querySelectorAll(`.disConnect_${source.wsNum}`);
                  if (disconnectToRemove && disconnectToRemove.length > 0) {
                    disconnectToRemove.forEach((element) => {
                      element.remove();
                    });
                  }
                  const loadingToRemove = myDiv.querySelectorAll(`.loading_${source.wsNum}`);
                  if (loadingToRemove && loadingToRemove.length > 0) {
                    loadingToRemove.forEach((element) => {
                      element.remove();
                    });
                  }
                }
              },
              onPause: () => {
                const playingAray = array.filter((el: any) => el.isPlaying);
                array.map((el: any) => el.isPlaying && renderPlayBtn(el.wsNum, playingAray));

                if (destroyAll) {
                  array.map((el: any) => {
                    const canvasElement = document.querySelector(`.cctvCanvas_${el.wsNum}`);
                    if (canvasElement) {
                      const replayBtnDiv = document.createElement('div');
                      replayBtnDiv.className = `replayBtn_${el.wsNum}`;
                      replayBtnDiv.style.zIndex = '998';
                      replayBtnDiv.style.position = 'absolute';
                      replayBtnDiv.style.color = '#fff';
                      replayBtnDiv.style.backgroundColor = '#000';
                      replayBtnDiv.style.borderRadius = '5px';
                      replayBtnDiv.style.top = '0'; // Position the replayBtnDiv over the canvas
                      replayBtnDiv.style.width = '100%';
                      replayBtnDiv.style.height = '100%';
                      replayBtnDiv.style.display = 'flex';
                      replayBtnDiv.style.justifyContent = 'center';
                      replayBtnDiv.style.alignItems = 'center';
                      const iconSize = '3em';
                      const materialIcon = document.createElement('i');
                      materialIcon.className = `material-symbols-rounded`;
                      materialIcon.textContent = 'play_circle';
                      materialIcon.style.fontSize = iconSize;
                      materialIcon.style.cursor = 'pointer';

                      materialIcon.addEventListener('mouseenter', function () {
                        materialIcon.classList.add('hovered-icon');
                      });

                      materialIcon.addEventListener('mouseleave', function () {
                        materialIcon.classList.remove('hovered-icon');
                      });

                      replayBtnDiv.appendChild(materialIcon);

                      materialIcon.addEventListener('click', function () {
                        onClickPlayer(el.wsNum, array);
                      });
                      canvasElement.appendChild(replayBtnDiv);
                    }
                  });
                }
              },
            }
          );
          setPlayers((prev) => [...prev, playerObj]);
          renderDisconnectDiv(source.wsNum);
        }
      });
    };

    if (currentCameraInfo?.length > 0) {
      initPlayer();
      setTimer(0);
    }

    return () => {
      // players.map((el) => el.destroy());
      setPlayers([]);
      setOrgPlayerInfo([]);
      setTimer(0);
    };
  }, [currentCameraInfo]);

  useEffect(() => {
    setDestroyAll(false);
    return () => {
      // players.map((el) => el.destroy());
      setPlayers([]);
      setOrgPlayerInfo([]);
      setTimer(0);
    };
  }, [cctvDivisions]);

  const renderLoadingDiv = (wsNumParam: string) => {
    const canvasElement = document.querySelector(`.cctvCanvas_${wsNumParam}`);
    if (canvasElement) {
      const loadingDiv = document.createElement('div');
      loadingDiv.className = `loading_${wsNumParam}`;
      loadingDiv.style.zIndex = '998';
      loadingDiv.style.position = 'absolute';
      loadingDiv.style.color = '#fff';
      loadingDiv.style.backgroundColor = '#000';
      loadingDiv.style.borderRadius = '5px';
      loadingDiv.style.top = '0'; // Position the loadingDiv over the canvas
      loadingDiv.style.width = '100%';
      loadingDiv.style.height = '100%';
      loadingDiv.style.display = 'flex';
      loadingDiv.style.justifyContent = 'center';
      loadingDiv.style.alignItems = 'center';
      loadingDiv.style.cursor = 'default';
      const iconSize = '3em';
      const materialIcon = document.createElement('i');
      materialIcon.className = `material-symbols-rounded`;
      materialIcon.textContent = 'pending';
      materialIcon.style.fontSize = iconSize;
      loadingDiv.appendChild(materialIcon);
      canvasElement.appendChild(loadingDiv);
    }
  };

  const renderDisconnectDiv = (wsNumParam: string) => {
    const canvasElement = document.querySelector(`.cctvCanvas_${wsNumParam}`);
    if (canvasElement) {
      const disconnectDiv = document.createElement('div');
      disconnectDiv.className = `disConnect_${wsNumParam}`;
      disconnectDiv.style.zIndex = '998';
      disconnectDiv.style.position = 'absolute';
      disconnectDiv.style.color = '#fff';
      disconnectDiv.style.backgroundColor = '#000';
      disconnectDiv.style.borderRadius = '5px';
      disconnectDiv.style.top = '0'; // Position the disconnectDiv over the canvas
      disconnectDiv.style.width = '100%';
      disconnectDiv.style.height = '100%';
      disconnectDiv.style.display = 'flex';
      disconnectDiv.style.justifyContent = 'center';
      disconnectDiv.style.alignItems = 'center';
      disconnectDiv.style.cursor = 'default';
      const iconSize = '3em';
      const materialIcon = document.createElement('i');
      materialIcon.className = `material-symbols-rounded`;
      materialIcon.textContent = 'videocam_off';
      materialIcon.style.fontSize = iconSize;
      disconnectDiv.appendChild(materialIcon);
      canvasElement.appendChild(disconnectDiv);
    }
  };

  const renderPlayBtn = (wsNumParam: string, infoArrayParam: any[] | undefined) => {
    const canvasElement = document.querySelector(`.cctvCanvas_${wsNumParam}`);
    if (canvasElement) {
      const replayBtnDiv = document.createElement('div');
      replayBtnDiv.className = `replayBtn_${wsNumParam}`;
      replayBtnDiv.style.zIndex = '998';
      replayBtnDiv.style.position = 'absolute';
      replayBtnDiv.style.color = '#fff';
      replayBtnDiv.style.backgroundColor = '#000';
      replayBtnDiv.style.borderRadius = '5px';
      replayBtnDiv.style.top = '0'; // Position the replayBtnDiv over the canvas
      replayBtnDiv.style.width = '100%';
      replayBtnDiv.style.height = '100%';
      replayBtnDiv.style.display = 'flex';
      replayBtnDiv.style.justifyContent = 'center';
      replayBtnDiv.style.alignItems = 'center';
      const iconSize = '3em';
      const materialIcon = document.createElement('i');
      materialIcon.className = `material-symbols-rounded`;
      materialIcon.textContent = 'play_circle';
      materialIcon.style.fontSize = iconSize;
      materialIcon.style.cursor = 'pointer';

      materialIcon.addEventListener('mouseenter', function () {
        materialIcon.classList.add('hovered-icon');
      });

      materialIcon.addEventListener('mouseleave', function () {
        materialIcon.classList.remove('hovered-icon');
      });

      replayBtnDiv.appendChild(materialIcon);

      materialIcon.addEventListener('click', function () {
        if (infoArrayParam) onClickPlayer(wsNumParam, infoArrayParam);
        else onClickPlayer(wsNumParam, undefined);
      });
      canvasElement.appendChild(replayBtnDiv);
    }
  };

  const applyVideoBorder = (wsParam: string, infoArray: any[] | undefined) => {
    if (!dashboard) {
      if (infoArray) {
        infoArray.map((el) => {
          const canvasElement = document.getElementById(`cctvCanvas_${el.wsNum}`);
          if (canvasElement) {
            if (el.wsNum === wsParam) canvasElement.style.border = '1px solid red';
            else canvasElement.style.border = 'none';
          }
        });
      } else {
        orgPlayerInfo.map((el) => {
          const canvasElement = document.getElementById(`cctvCanvas_${el.wsNum}`);
          if (canvasElement) {
            if (el.wsNum === wsParam) canvasElement.style.border = '1px solid red';
            else canvasElement.style.border = 'none';
          }
        });
      }
    }
  };

  useEffect(() => {
    if (timer >= DESTROY_TIME) {
      setDestroyAll(true);
      return undefined;
    }
    const tick = setTimeout(() => setTimer(timer + 1), 1000);
    return () => clearTimeout(tick);
  }, [timer]);

  useEffect(() => {
    if (destroyAll) {
      if (players?.length > 0) {
        players.map((el) => el.destroy());
        setPlayers([]);
      }
    }
  }, [destroyAll]);

  const onClickPlayer = (wsParam: string, infoArrayParam: any[] | undefined) => {
    applyVideoBorder(wsParam, infoArrayParam);
    renderLoadingDiv(wsParam);

    if (infoArrayParam === undefined) {
      const findOrgPlayer = orgPlayerInfo.find((el) => wsParam === el.wsNum);
      const findPlayer = players.find((el) => wsParam === el.els.wrapper.id?.split('_')[1]);

      if (findPlayer) {
        // if (findPlayer.player.isPlaying) findPlayer?.stop();
        // else {
        //   findPlayer?.play();
        // }
      } else if (findOrgPlayer) {
        setTimer(0);
        setDestroyAll(false);
        const targetElement = document.getElementById(`video-canvas_${wsParam}`);
        if (targetElement) {
          const port = schema === 'ws' ? `:${wsParam}` : wsParam;
          const url = `${schema}://${process.env.REACT_APP_SOCKET_IP}${port}`;
          const playerObj = new JSMpegPlayer.VideoElement(
            targetElement,
            url,
            { autoPlay: false, control: true },
            {
              onVideoDecode: () => {
                if (playerObj.player.video.decodedTime >= DESTROY_TIME) {
                  playerObj.destroy();
                }
                if (playerObj.player.video.canPlay === false) {
                  // 소켓연결 실패한 비디오객체는 destroy처리
                  playerObj.destroy();
                }
              },
              onPlay: () => {
                const findOrgPlayerInfo = orgPlayerInfo.map((el) => (el.wsNum === wsParam ? { ...el, isPlaying: true } : { ...el }));
                setOrgPlayerInfo(findOrgPlayerInfo);

                // 비디오가 다시 플레이 될 때 replayBtn div 삭제
                const myDiv = document.getElementById(`cctvCanvas_${wsParam}`);
                if (myDiv) {
                  const replayBtnToRemove = myDiv.querySelectorAll(`.replayBtn_${wsParam}`);
                  if (replayBtnToRemove && replayBtnToRemove.length > 0) {
                    replayBtnToRemove.forEach((element) => {
                      element.remove();
                    });
                  }
                  const disconnectToRemove = myDiv.querySelectorAll(`.disConnect_${wsParam}`);
                  if (disconnectToRemove && disconnectToRemove.length > 0) {
                    disconnectToRemove.forEach((element) => {
                      element.remove();
                    });
                  }
                  const loadingToRemove = myDiv.querySelectorAll(`.loading_${wsParam}`);
                  if (loadingToRemove && loadingToRemove.length > 0) {
                    loadingToRemove.forEach((element) => {
                      element.remove();
                    });
                  }
                }
              },
              onPause: () => {
                renderPlayBtn(wsParam, undefined);
                const findOrgPlayerInfo = orgPlayerInfo.map((el) => (el.wsNum === wsParam ? { ...el, isPlaying: false } : { ...el }));
                setOrgPlayerInfo(findOrgPlayerInfo);
              },
            }
          );
          setPlayers((prev) => [...prev, playerObj]);
        }
      }
    } else {
      const findOrgPlayer = infoArrayParam.find((el) => wsParam === el.wsNum);

      if (findOrgPlayer) {
        setTimer(0);
        setDestroyAll(false);
        const targetElement = document.getElementById(`video-canvas_${wsParam}`);
        if (targetElement) {
          const port = schema === 'ws' ? `:${wsParam}` : wsParam;
          const url = `${schema}://${process.env.REACT_APP_SOCKET_IP}${port}`;
          const playerObj = new JSMpegPlayer.VideoElement(
            targetElement,
            url,
            { autoPlay: false, control: true },
            {
              onVideoDecode: () => {
                if (playerObj.player.video.decodedTime >= DESTROY_TIME) {
                  playerObj.destroy();
                }
                if (playerObj.player.video.canPlay === false) {
                  playerObj.destroy();
                }
              },
              onPlay: () => {
                // 비디오가 다시 플레이 될 때 replayBtn div 삭제
                const myDiv = document.getElementById(`cctvCanvas_${wsParam}`);
                if (myDiv) {
                  const replayBtnToRemove = myDiv.querySelectorAll(`.replayBtn_${wsParam}`);
                  if (replayBtnToRemove && replayBtnToRemove.length > 0) {
                    replayBtnToRemove.forEach((element) => {
                      element.remove();
                    });
                  }
                  const disconnectToRemove = myDiv.querySelectorAll(`.disConnect_${wsParam}`);
                  if (disconnectToRemove && disconnectToRemove.length > 0) {
                    disconnectToRemove.forEach((element) => {
                      element.remove();
                    });
                  }
                  const loadingToRemove = myDiv.querySelectorAll(`.loading_${wsParam}`);
                  if (loadingToRemove && loadingToRemove.length > 0) {
                    loadingToRemove.forEach((element) => {
                      element.remove();
                    });
                  }
                }
              },
              onPause: () => {
                renderPlayBtn(wsParam, infoArrayParam);
              },
            }
          );
          // setPlayers((prev) => [...prev, playerObj]);
        }
      }
    }
  };

  // 플레이어 더블클릭 시 전체화면 전환
  const requestFullscreen = (index: number) => {
    let current = playerRefs.current[index];
    if (current.els !== undefined) {
      current = playerRefs.current[index].els.wrapper;
    } else {
      current = playerRefs.current[index];
    }

    if (current.requestFullscreen) {
      current.requestFullscreen();
    } else if ((current as any).mozRequestFullScreen) {
      /* Firefox */
      (current as any).mozRequestFullScreen();
    } else if ((current as any).webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      (current as any).webkitRequestFullscreen();
    } else if ((current as any).msRequestFullscreen) {
      /* IE/Edge */
      (current as any).msRequestFullscreen();
    }
  };

  // 전체화면 상태에서 더블클릭시 풀스크린 해제
  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if ((document as any).mozCancelFullScreen) {
      /* Firefox */
      (document as any).mozCancelFullScreen();
    } else if ((document as any).webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      (document as any).webkitExitFullscreen();
    } else if ((document as any).msExitFullscreen) {
      /* IE/Edge */
      (document as any).msExitFullscreen();
    }
  };

  const handleDoubleClick = (index: number) => {
    if (!document.fullscreenElement) {
      requestFullscreen(index);
    } else if (document.fullscreenElement) {
      exitFullscreen();
    }
  };

  if (totalCameraInfo.length === 0) {
    return <div className='cctvWrapper flex-center emptyContainer'>카메라 목록이 없습니다.</div>;
  }

  return (
    <div className='cctvWrapper' id='cctvWrapper'>
      {currentCameraInfo?.map((player, index) => (
        <div key={player.wsNum} className={`cctvCanvas_${player.wsNum}`} id={`cctvCanvas_${player.wsNum}`} style={{ position: 'relative', height: '100%', width: '100%' }}>
          <div className='tag'>
            {player.nName} {player.cName}
          </div>
          <div
            id={`video-canvas_${player.wsNum}`}
            role='presentation'
            onClick={() => applyVideoBorder(player.wsNum, undefined)}
            onDoubleClick={() => handleDoubleClick(index)}
            ref={(el) => {
              if (el) playerRefs.current[index] = el;
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default MultiVideoPlayer;
