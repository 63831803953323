/**
 * 작성자 : 한영광
 * 날짜 : 2024.01.05
 * 기능 : 긴급 상황 알림 정보 모달
 */
/*
 * 수정자 : 김광민
 * 수정 날짜 : 2024.02.29
 * 수정 이유 1 : global style 걷어내고 컴포넌트 내 스타일시트 추가
 * 수정 이유 2 :html 구조 재구성 및 전체 스타일시트 수정
 * 수정 이유 3 :map 메서드로 감싸는 부분 별도 컴포넌트로 추출
 * 수정 이유 4 :브라우저 너비에 따른 반응형 대응
 */

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { diffTime, formatDate, formatDateType } from '../utils/formatDate';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
  border-radius: 0.25rem;
  overflow: hidden;
  padding: 0.75rem;
  flex-shrink: 0;
  margin-top: 0.75rem;
  &:last-child {
    margin-bottom: 0.75rem;
  }
  .cardTitle {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .titleCount {
      font-size: 0.938rem;
      min-width: 2rem;
      height: 1.75rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0.25rem;
      background-color: ${({ theme }: { theme: any }) => theme.filled_red};
      border-radius: 0.25rem;
      color: ${({ theme }: { theme: any }) => theme.text_reverse};
    }
    .titleLabel {
      color: ${({ theme }: { theme: any }) => theme.text_disabled};
      font-size: 0.875rem;
    }
    .titleName {
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      font-size: 0.875rem;
      font-weight: 400;
      &.chip {
        border-radius: 22rem;
        height: 1.75rem;
        line-height: 1.75rem;
        padding: 0 0.75rem;
        color: ${({ theme }: { theme: any }) => theme.text_reverse};
        background-color: ${({ theme }: { theme: any }) => theme.filled_slate_deep};
      }
      &.lime {
        background-color: ${({ theme }: { theme: any }) => theme.filled_lime};
        color: ${({ theme }: { theme: any }) => theme.lime};
      }
      &.green {
        background-color: ${({ theme }: { theme: any }) => theme.filled_green};
        color: ${({ theme }: { theme: any }) => theme.tonal_green};
      }
      &.blue {
        background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
        color: ${({ theme }: { theme: any }) => theme.tonal_blue};
      }
    }
    .seperator {
      height: 1rem;
      border-left: 2px solid ${({ theme }: { theme: any }) => theme.outline_em};
    }
  }
  .row {
    width: 100%;
    display: flex;
    box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    overflow: hidden;
    .cell {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .th {
        font-size: 0.875rem;
        height: 2rem;
        line-height: 2rem;
        color: ${({ theme }: { theme: any }) => theme.text_disabled};
        text-align: center;
        border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
        padding: 0 0.25rem;
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
      }
      .td {
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        background-color: ${({ theme }: { theme: any }) => theme.board};
        height: 2.5rem;
        line-height: 2.5rem;
        padding: 0 0.25rem;
        text-align: center;
        &.txt_green {
          color: ${({ theme }: { theme: any }) => theme.filled_green};
        }
        &.txt_sky {
          color: ${({ theme }: { theme: any }) => theme.filled_sky};
        }
        &.txt_pink {
          color: ${({ theme }: { theme: any }) => theme.filled_pink};
        }
        &.txt_slate {
          color: ${({ theme }: { theme: any }) => theme.filled_slate};
        }
        &.txt_lime {
          color: ${({ theme }: { theme: any }) => theme.filled_lime};
        }
      }
    }
  }
`;

const AlertModalCard = ({ data, index }: { data: any; index: number }) => {
  const { t } = useTranslation();

  const getChipClass = (gubun: string) => {
    switch (gubun) {
      case 'S':
        return 'lime';
      case 'B':
        return 'green';
      default:
        return 'blue';
    }
  };

  const gubunName = (gubun: string) => {
    switch (gubun) {
      case 'S':
        return t('센서');
      case 'B':
        return t('비콘');
      case 'F':
        return t('출입통제');
      default:
        return '';
    }
  };

  const getTextClass = (level: string) => {
    switch (level) {
      case '1':
        return 'txt_green';
      case '2':
        return 'txt_sky';
      case '3':
        return 'txt_pink';
      case '4':
        return 'txt_slate';
      default:
        return 'txt_lime';
    }
  };

  const elapsedTime = (time: string) => {
    const currentTime: any = new Date();
    const previousTime: any = formatDateType(time);
    const newTime = currentTime - previousTime < 0 ? 0 : currentTime - previousTime;
    const elapsedSeconds = Math.floor(newTime / 1000);
    const timeData = diffTime(elapsedSeconds);
    return `${timeData.hours}${t('시간')} ${timeData.min}${t('분')} ${timeData.sec}${t('초')}`;
  };

  const levelName = (level: string) => {
    switch (level) {
      case '1':
        return t('주의');
      case '2':
        return t('경고');
      case '3':
        return t('위험');
      case '4':
        return t('호출');
      case '5':
        return t('침입');
      default:
        return '';
    }
  };

  const typeName = (type: string) => {
    switch (type) {
      case 'O2':
        return t('산소');
      case 'NO2':
        return t('이산화질소');
      case 'CO':
        return t('일산화탄소');
      case 'CO2':
        return t('이산화탄소');
      case 'H2S':
        return t('황화수소');
      case 'CH4':
        return t('메탄');
      case 'C':
        return t('긴급호출');
      case 'UP':
        return t('비인가자출입');
      default:
        return '';
    }
  };

  const renderContents = (el: any) => {
    if (el.gubun === 'S') {
      return `${el.tatName} / ${el.tatGubun === 'S' ? t('시점') : t('종점')}`;
    }
    if (el.gubun === 'B') {
      return `${el.tatName} / ${el.tatGubun === 'S' ? t('시점') : t('종점')} / ${el.tadName} / ${el.wName}`;
    }
    return `${el.tatName}`;
  };

  return (
    <Root key={v4()}>
      <div className='cardTitle'>
        <span className='titleCount'>{index + 1}</span>
        <div className='titleLabel'>{t('구분')}</div>
        <span className='seperator' />
        <div className={`titleName chip ${getChipClass(data.gubun)}`}>{gubunName(data.gubun)}</div>
      </div>
      <div className='row'>
        <div className='cell'>
          <div className='th'>{t('단계')}</div>
          <div className={`td ${getTextClass(data.level)}`}>{levelName(data.level)}</div>
        </div>
        <div className='cell'>
          <div className='th'>{t('시간')}</div>
          <div className='td'>{formatDate(data.time)}</div>
        </div>
        <div className='cell'>
          <div className='th'>{t('경과시간')}</div>
          <div className='td'>{elapsedTime(data.time)}</div>
        </div>
      </div>
      <div className='row'>
        <div className='cell'>
          <div className='th'>{t('유형')}</div>
          <div className='td'>{typeName(data.type)}</div>
        </div>
        <div className='cell'>
          <div className='th'>{t('구역 / 관련 내용')}</div>
          <div className='td'>{renderContents(data)}</div>
        </div>
      </div>
    </Root>
  );
};

export default AlertModalCard;
