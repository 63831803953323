import React, { useState } from 'react';
import styled from 'styled-components';
import ButtonContainer from '../RealtimeCCTV/ButtonContainer';
import ClickableContainer from '../RealtimeCCTV/ClickableContainer';
import { WebSDKcctv } from '../../../../utils/webSDKcctvClass';
import Timeline from './Timeline';

const Root = styled.div`
  display: flex;
  align-items: center;
  max-width: calc(100vw - 21rem);
  height: 4rem;
  &.invisiblePanel {
    max-width: 100vw;
  }
  .wideOptions,
  .timeOptions {
    user-select: none;
    display: flex;
    flex-direction: column;
    padding: 0 0.5rem;
    justify-content: flex-start;
    height: 100%;
    gap: 0.25rem;
    .optionName {
      font-size: 0.875rem;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      color: ${({ theme }: { theme: any }) => theme.color.zinc_400};
      line-height: 1.25;
    }
    .buttons {
      display: flex;
      gap: 0.25rem;
    }
  }
`;

interface TimelineProps {
  visiblePanel: boolean;
  cctvOBJ: WebSDKcctv;
  recordData: Playback[];
  selectedCameraInfo: SelectedCctvCameraInfo;
  onClickPause: () => void;
  videoWindow: VideoWindow;
}

const TimelineBar: React.FC<TimelineProps> = ({
  visiblePanel, //
  cctvOBJ,
  recordData,
  selectedCameraInfo,
  onClickPause,
  videoWindow,
}) => {
  // interval 간격 상태
  const [selectedOption, setSelectedOption] = useState<6 | 12 | 24>(12);
  // 타임라인 cell 너비 상태
  const [intervalWidth, setIntervalWidth] = useState<15 | 25 | 40>(25);

  // 타임라인 시간 간격 변경 함수
  const onClickTimelineOption = (type: 'add' | 'remove') => {
    // + 버튼 클릭시
    if (type === 'add' && selectedOption === 6) {
      setSelectedOption(12);
    } else if (type === 'add' && selectedOption === 12) {
      setSelectedOption(24);
    }
    // - 버튼 클릭시
    if (type === 'remove' && selectedOption === 12) {
      setSelectedOption(6);
    } else if (type === 'remove' && selectedOption === 24) {
      setSelectedOption(12);
    }
  };

  // 타임라인 너비 변경 함수
  const onClickIntervalWidth = (type: 'zoomIn' | 'zoomOut') => {
    if (type === 'zoomIn' && intervalWidth === 15) {
      setIntervalWidth(25);
    } else if (type === 'zoomIn' && intervalWidth === 25) {
      setIntervalWidth(40);
    } else if (type === 'zoomOut' && intervalWidth === 40) {
      setIntervalWidth(25);
    } else if (type === 'zoomOut' && intervalWidth === 25) {
      setIntervalWidth(15);
    }
  };

  const intervalWidthName = () => {
    switch (intervalWidth) {
      case 15:
        return '좁게';
      case 25:
        return '기본 너비';
      case 40:
      default:
        return '넓게';
    }
  };

  const selectedOptionName = () => {
    switch (selectedOption) {
      case 6:
        return '30분 간격';
      case 12:
        return '1시간 간격';
      case 24:
      default:
        return '2시간 간격';
    }
  };

  const intervalWidthClass = () => {
    switch (intervalWidth) {
      case 15:
        return 'intervalWidth15';
      case 25:
        return 'intervalWidth25';
      case 40:
      default:
        return 'intervalWidth40';
    }
  };

  return (
    <Root className={!visiblePanel ? 'invisiblePanel' : ''}>
      <div className='wideOptions'>
        <span className='optionName'>{intervalWidthName()}</span>
        <div className='buttons'>
          <ClickableContainer // 캡쳐
            button={<ButtonContainer icon='zoom_out' darkModeOnly iconWeight={300} />}
            onClick={() => onClickIntervalWidth('zoomOut')}
            disabled={intervalWidth === 15}
          />
          <ClickableContainer // 캡쳐
            button={<ButtonContainer icon='zoom_in' darkModeOnly iconWeight={300} />}
            onClick={() => onClickIntervalWidth('zoomIn')}
            disabled={intervalWidth === 40}
          />
        </div>
      </div>
      <Timeline //
        intervalWidth={intervalWidthClass()}
        selectedOption={selectedOption}
        recordData={recordData}
        selectedCameraInfo={selectedCameraInfo}
        onClickPause={onClickPause}
        videoWindow={videoWindow}
      />
      <div className='timeOptions'>
        <span className='optionName'>{selectedOptionName()}</span>
        <div className='buttons'>
          <ClickableContainer // 간격 확대
            button={<ButtonContainer icon='add' darkModeOnly />}
            onClick={() => onClickTimelineOption('remove')}
            disabled={selectedOption === 6}
          />
          <ClickableContainer // 간격 축소
            button={<ButtonContainer icon='remove' darkModeOnly />}
            onClick={() => onClickTimelineOption('add')}
            disabled={selectedOption === 24}
          />
        </div>
      </div>
    </Root>
  );
};

export default TimelineBar;
