/**
 * 작성자 : 홍선영
 * 날짜 : 2023.03.31
 * 기능 : 인풋값(이메일, 패스워드, 휴대폰번호)의 빈값여부, 정규식일치여부를 확인하고
 *        통과여부(boolean), 알럿메시지문구를 리턴하는 함수 모음
 */

import { EMAIL, EMAIL_TYPE, PASSWORD, PASSWORD_TYPE, PASSWORD_WRONG, MOBILE_PHONENUMBER, MOBILE_PHONENUMBER_WRONG, PHONENUMBER, PHONENUMBER_WRONG } from '../_constants';

export const emailRegex = (email: string) => {
  const trimmedEmail: string = email?.trim();
  let isEmailRegexPass: boolean = false;
  let emailRegexAlertMsg: string = '';

  if (!trimmedEmail) {
    emailRegexAlertMsg = EMAIL;
    return { isEmailRegexPass, emailRegexAlertMsg };
  }
  if (trimmedEmail.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i) === null) {
    emailRegexAlertMsg = EMAIL_TYPE;
    return { isEmailRegexPass, emailRegexAlertMsg };
  }
  isEmailRegexPass = true;
  return { isEmailRegexPass, emailRegexAlertMsg };
};

export const passwordOnlyRegex = (password: string) => {
  const trimmedPassword: string = password?.trim();
  let isPasswordRegexPass: boolean = false;
  let passwordRegexAlertMsg: string = '';

  if (trimmedPassword === '') {
    passwordRegexAlertMsg = PASSWORD;
    return { isPasswordRegexPass, passwordRegexAlertMsg };
  }

  if (trimmedPassword.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[~!@#$%^&*()_+-|}{:"?><,./;'\]\[=`])[A-Za-z\d~!@#$%^&*()_+-|}{:"?><,./;'\]\[=`].{8,20}$/) === null) {
    passwordRegexAlertMsg = PASSWORD_TYPE;
    return { isPasswordRegexPass, passwordRegexAlertMsg };
  }
  isPasswordRegexPass = true;
  return { isPasswordRegexPass, passwordRegexAlertMsg };
};

export const passwordRegex = (password: string, passwordConfirm: string) => {
  const trimmedPassword: string = password?.trim();
  const trimmedPasswordConfirm: string = passwordConfirm?.trim();
  let isPasswordRegexPass: boolean = false;
  let passwordRegexAlertMsg: string = '';

  if (trimmedPassword === '' || trimmedPasswordConfirm === '') {
    passwordRegexAlertMsg = PASSWORD;
    return { isPasswordRegexPass, passwordRegexAlertMsg };
  }
  if (trimmedPassword !== trimmedPasswordConfirm) {
    passwordRegexAlertMsg = PASSWORD_WRONG;
    return { isPasswordRegexPass, passwordRegexAlertMsg };
  }
  if (trimmedPassword.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[~!@#$%^&*()_+-|}{:"?><,./;'\]\[=`])[A-Za-z\d~!@#$%^&*()_+-|}{:"?><,./;'\]\[=`].{8,20}$/) === null) {
    passwordRegexAlertMsg = PASSWORD_TYPE;
    return { isPasswordRegexPass, passwordRegexAlertMsg };
  }
  isPasswordRegexPass = true;
  return { isPasswordRegexPass, passwordRegexAlertMsg };
};

export const phoneNumRegex = (phoneNum: string) => {
  const trimmedPhoneNum: string = phoneNum?.trim();
  const phoneNumArray = trimmedPhoneNum?.split('-');
  let isPhoneNumRegexPass: boolean = false;
  let phoneNumRegexAlertMsg: string = '';

  if (trimmedPhoneNum === '') {
    phoneNumRegexAlertMsg = MOBILE_PHONENUMBER;
    return { isPhoneNumRegexPass, phoneNumRegexAlertMsg };
  }
  if (phoneNumArray[0].trim() === '' || phoneNumArray[1].trim() === '' || phoneNumArray[2].trim() === '') {
    phoneNumRegexAlertMsg = MOBILE_PHONENUMBER;
    return { isPhoneNumRegexPass, phoneNumRegexAlertMsg };
  }
  if (phoneNumArray[0].trim().length < 3 || phoneNumArray[1].trim().length < 3 || phoneNumArray[2].trim().length < 4) {
    phoneNumRegexAlertMsg = MOBILE_PHONENUMBER_WRONG;
    return { isPhoneNumRegexPass, phoneNumRegexAlertMsg };
  }
  isPhoneNumRegexPass = true;
  return { isPhoneNumRegexPass, phoneNumRegexAlertMsg };
};

export const telNumRegex = (telNum: string) => {
  const trimmedTelNum: string = telNum?.trim();
  const telNumArray = trimmedTelNum?.split('-');
  let isTelNumRegexPass: boolean = false;
  let telNumRegexAlertMsg: string = '';

  if (trimmedTelNum === '') {
    isTelNumRegexPass = true;
    return { isTelNumRegexPass, telNumRegexAlertMsg };
  }
  if (telNumArray[0].trim() === '' || telNumArray[1].trim() === '' || telNumArray[2].trim() === '') {
    telNumRegexAlertMsg = PHONENUMBER_WRONG;
    return { isTelNumRegexPass, telNumRegexAlertMsg };
  }
  if (telNumArray[0].trim().length < 2 || telNumArray[1].trim().length < 3 || telNumArray[2].trim().length < 4) {
    telNumRegexAlertMsg = PHONENUMBER_WRONG;
    return { isTelNumRegexPass, telNumRegexAlertMsg };
  }
  isTelNumRegexPass = true;
  return { isTelNumRegexPass, telNumRegexAlertMsg };
};

// 특수문자 입력 제거
export const characterRegex = (str: any) => {
  return str.replace(/[\{\}\[\]\/?.,;:|\)*~`!^\_+┼<>@\#$%&\'\"\\\(\=]/g, '');
};

export const quoteRegex = (str: any) => {
  return str.replace(/[']/gi, '');
};

export const dateRegex = (str: any) => {
  return str.replace(/[^0-9\-]+$/g, '');
};

export const numberRegex = (str: any) => {
  return str.replace(/[^0-9]+$/g, '');
};

export const decimalNumberRegex = (str: string) => {
  return str.replace(/[^0-9.]+$/g, '');
};

export const allowOneDecimalPointOnly = (str: string) => {
  const cleanedStr = str.replace(/\.+/g, '.');
  const lastCharacter = cleanedStr.charAt(cleanedStr.length - 1);
  if (lastCharacter === '.' && cleanedStr.indexOf('.') !== cleanedStr.length - 1) {
    return cleanedStr.slice(0, -1);
  }

  return cleanedStr;
};
