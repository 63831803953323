/**
 * 작성자 : 홍선영
 * 날짜 : 2023.04.20
 * 기능 : 마스터메뉴관리-메뉴관리 페이지
 */

import { useState, useEffect, Dispatch, SetStateAction, useRef } from 'react';
import { toast } from 'react-toastify';
import { useOutletContext } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';

import { IComCdList, IMenuDetail, IMenuReport, IMenuTitle, IModal, ISideMenuObject, ISizeObj, ITabObject } from 'customTypes';
import { IUser, pageInfoState, sideMenuState, userState } from '../../atoms';
import { COMCD_MENU_GRANT, FLAG_CREATE_OR_UPDATE, FLAG_DELETE, USE_YN } from '../../_constants';
import { InputTable } from '../../assets/styles/InputTable';
import Head from './menu1/head';
import Site from './menu1/site';
import Master from './menu1/master';
import Company from './menu1/company';
import SelectBoxs from '../../components/SelectBoxs';
import Portal from '../../components/Portal';
import Tab from '../../components/Tab';
import DeleteModal from '../../components/Modal/DeleteModal';
import { BtnBlue, BtnRed } from '../../components/Button';

import { trimArray } from '../../utils/trimArray';
import { limitDigits } from '../../utils/limitDigits';
import { increaseArrayNo } from '../../utils/increaseArrayNo';
import { scrollToNodeBottom } from '../../utils/scrollToNodeBottom';
import { arraySortByAscdOrder } from '../../utils/arraySortByAscdOrder';
import { logPost } from '../../services/log';
import { applyBorderStyle } from '../../utils/applyBorderStyle';
import illustrator from '../../assets/images/illustration/31.svg';
import emptyIllustrator from '../../assets/images/illustration/304.svg';
import { useTranslation } from 'react-i18next';
import BackButton from '../../components/BackButton';
import { apiDelete, apiGet, apiPost } from '../../services/_common';

const EmptyData = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  height: 100%;
  width: 100%;
  padding-top: 6rem !important;
  img {
    width: 16rem;
    -webkit-user-drag: none;
  }
  p {
    color: ${({ theme }: { theme: any }) => theme.filled_violet};
    word-break: keep-all;
    text-align: center;
  }
  p:nth-child(2) {
    padding: 1rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    border-radius: 0.5rem;
    margin: 1rem;
  }
  p:nth-child(3) {
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    margin: 0 2rem;
  }
`;
const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  justify-content: flex-end;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;
const Root = styled.div`
  > div:nth-child(2) {
    overflow: auto;
  }
  .inputFormsWrapper {
    padding: 0.5rem;
    border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  }
  .inputFormsWrapper.goBack {
    flex-direction: row;
    .icon-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      gap: 0.25rem;
      cursor: pointer;
      border-radius: 5rem;
      padding-right: 1rem;
      padding-left: 0.5rem;
      font-size: 0.875rem;
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      height: 100%;
    }
    .icon-btn:hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
    }
  }
  .inputFormsWrapper.report {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .closeBtn {
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5rem;
      cursor: pointer;
      margin: 0 0.5rem;
      color: ${({ theme }: { theme: any }) => theme.text_primary};
    }
    .closeBtn:hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
    }
    .material-symbols-rounded {
      color: rgba(0, 0, 0, 0.8);
      font-variation-settings: 'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 20;
    }
  }
  .formTitle {
    padding: 0 0.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .emptyData {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    padding-bottom: 4rem;
    img {
      width: 16rem;
      /* user-drag: none; */
      -webkit-user-drag: none;
    }
    span {
      color: ${({ theme }: { theme: any }) => theme.filled_violet};
    }
  }
  /* display: flex;
  flex-direction: column; */
`;

interface IProps {
  subCd: string;
  size?: ISizeObj;
}

interface SubObject {
  mtCd: string;
  mdCd: string;
  mdName: string;
  cdSort: number;
}
interface MainObject {
  mtCd: string;
  mtName: string;
  cdSort: number;
  sub: SubObject[];
}

interface IMenuObject {
  mtCd: string;
  menus: MainObject[];
  cdSort?: number;
}

const Component = ({ subCd, size }: IProps) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const { userMenuList } = userInfo;
  const scrollContainerRef = useRef<HTMLInputElement>(null);
  const scrollSubContainerRef = useRef<HTMLInputElement>(null);
  const scrollReportContainerRef = useRef<HTMLInputElement>(null);
  const [tableState, setTableState] = useState<IMenuTitle[]>([]);
  const [subTableState, setSubTableState] = useState<IMenuDetail[]>([]);
  const [reportTableState, setReportTableState] = useState<IMenuReport[]>([]);
  const [rawData, setRawData] = useState<IMenuTitle[]>([]);
  const [subRawData, setSubRawData] = useState<IMenuDetail[]>([]);
  const [reportRawData, setReportRawData] = useState<IMenuReport[]>([]);
  const [newRowIndex, setNewRowIndex] = useState<number>(0); // 신규항목 추가 클릭했을 때 새 로우객체에 인덱스추가
  const [newSubRowIndex, setNewSubRowIndex] = useState<number>(0); // 신규항목 추가 클릭했을 때 새 로우객체에 인덱스추가
  const [newReportRowIndex, setNewReportRowIndex] = useState<number>(0); // 신규항목 추가 클릭했을 때 새 로우객체에 인덱스추가
  const [rawDataLength, setRawDataLength] = useState<number>(0); // 테이블에 표시할 로우 넘버
  const [subRawDataLength, setSubRawDataLength] = useState<number>(0); // 테이블에 표시할 로우 넘버
  const [reportRawDataLength, setReportRawDataLength] = useState<number>(0); // 테이블에 표시할 로우 넘버
  const [isTableInitialRender, setIsTableInitialRender] = useState(true); // 컴포넌트의 이니셜렌더 여부
  const [isSubTableInitialRender, setIsSubTableInitialRender] = useState(true); // 컴포넌트의 이니셜렌더 여부
  const [isReportTableInitialRender, setIsReportTableInitialRender] = useState(true); // 컴포넌트의 이니셜렌더 여부
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const [mtCd, setMtCd] = useState({ showMtCd: false, mtCd: '', mtName: '' });
  const [mdCd, setMdCd] = useState({ showMdCd: false, mdCd: '', mdName: '' });
  const [selectedMrCd, setSelectedMrCd] = useState<string | number>('');
  const [searchLineList, setSearchLineList] = useState<any>([]);
  const [initiateLineSelectComponent, setInitiateLineSelectComponent] = useState(false);
  const [menuGrantComCdList, setMenuGrantComCdList] = useState<IComCdList[]>([]);
  const setSideMenuList = useSetRecoilState<ISideMenuObject[]>(sideMenuState);
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장1버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [isSave2Clicked, setIsSave2Clicked] = useState(false); // 저장2버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [isSave3Clicked, setIsSave3Clicked] = useState(false); // 저장3버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [viewReport, setViewReport] = useState(false);

  useEffect(() => {
    getMenuTitleListAPI().then((res: any) => {
      if (res.status === 200) {
        getComCdMenuGrantAPI();
      }
    });
  }, []);

  useEffect(() => {
    if (subCd) {
      let menu = '';
      switch (subCd) {
        case 'M':
          menu = '마스터 메뉴 관리';
          break;
        case 'C':
          menu = '사내 메뉴 관리';
          break;
        case 'H':
          menu = '본사 메뉴 관리';
          break;
        case 'S':
          menu = '현장 메뉴 관리';
          break;
      }
      logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu,
        action: '조회',
        etc: ``,
      });
    }
  }, [subCd]);

  useEffect(() => {
    // 메뉴타이틀 상태 변경시 메뉴명테이블 초기화
    setMdCd({ showMdCd: false, mdCd: '', mdName: '' });
  }, [mtCd.mtCd]);

  useEffect(() => {
    //  로우가 1개 이상이고, 이니셜렌더가 아닌경우 스크롤을 하단으로 이동
    if (tableState.length > 0 && !isTableInitialRender) scrollToNodeBottom(scrollContainerRef);
  }, [tableState.length]);

  useEffect(() => {
    if (subTableState.length > 0 && !isSubTableInitialRender) scrollToNodeBottom(scrollSubContainerRef);
  }, [subTableState.length]);

  useEffect(() => {
    if (reportTableState.length > 0 && !isReportTableInitialRender) scrollToNodeBottom(scrollReportContainerRef);
  }, [reportTableState.length]);

  // 메뉴 타이틀 조회 API(첫번째 테이블)
  const getMenuTitleListAPI = async () => {
    const res = await apiGet({ path: '/menu/title', req: { tab: subCd } });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      //
      const copyArray = [...data.menuTitleList];
      // 배열의 각 개체에 인덱스 속성 추가
      copyArray.forEach((obj: IMenuTitle, index: number) => {
        obj.no = index + 1; // 1부터 인덱스 시작에 1 추가
      });

      setTableState(data.menuTitleList);
      setRawData(data.menuTitleList);
      setNewRowIndex(data.menuTitleList.length);
      setRawDataLength(data.menuTitleList.length); //  로우데이터의 길이에 따라 신규추가항목의 No 를 세팅
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  // 타이틀 메뉴명 조회 API (두번째테이블)
  const getMenuDetailListAPI = async (mtCdParam: string) => {
    const res = await apiGet({ path: '/menu/detail', req: { mtCd: mtCdParam } });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      //
      const copyArray = [...data.menuDetailList];
      // 배열의 각 개체에 인덱스 속성 추가
      copyArray.forEach((obj: IMenuTitle, index: number) => {
        obj.no = index + 1; // 1부터 인덱스 시작에 1 추가
      });

      setSubTableState(data.menuDetailList);
      setSubRawData(data.menuDetailList);
      setNewSubRowIndex(data.menuDetailList.length);
      setSubRawDataLength(data.menuDetailList.length); //  로우데이터의 길이에 따라 신규추가항목의 No 를 세팅
    } else {
      // toast.error(t(ERROR));
    }
  };

  //  보고서 조회 API (하단 마지막테이블)
  const getMenuReportListAPI = async (mtCdParam: string, mdCdParam: string) => {
    const req = { mtCd: mtCdParam, mdCd: mdCdParam };
    const res = await apiGet({ path: '/menu/report', req });

    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      //
      const copyArray = [...data.menuReportList];
      // 배열의 각 개체에 인덱스 속성 추가
      copyArray.forEach((obj: IMenuReport, index: number) => {
        obj.no = index + 1; // 1부터 인덱스 시작에 1 추가
      });

      setReportTableState(data.menuReportList);
      setReportRawData(data.menuReportList);
      setNewReportRowIndex(data.menuReportList.length);
      setReportRawDataLength(data.menuReportList.length); //  로우데이터의 길이에 따라 신규추가항목의 No 를 세팅

      const newListCount = data.searchLineList.map((el: IMenuReport) => ({
        type: 'mrList',
        mrList: Number(el),
      }));
      setSearchLineList(newListCount);

      if (data.menuReportList.length > 0) {
        //  기본값으로 선택되어있는 로우의 mrCd setState (기본값이 변경되었을 때 flag Y로 변경용)
        const selectedRow = data.menuReportList.find((el: IMenuReport) => el.rDefault === 'Y');
        if (selectedRow !== undefined) {
          setSelectedMrCd(selectedRow.mrCd);
        }
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 사이드메뉴 조회 API
  const getSideMenuListAPI = async () => {
    const res = await apiGet({ path: '/menu' });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      // 사이드메뉴 조회 데이터 중 mtCd의 첫글자에 따라 마스터/본사/현장/사내 메뉴별로 다시 묶기
      const result: { [key: string]: MainObject[] } = {};
      data.forEach((item: any) => {
        const firstChar = item.mtCd.charAt(0);
        if (!result[firstChar]) {
          result[firstChar] = [];
        }
        result[firstChar].push(item);
      });

      const menuArray: IMenuObject[] = Object.entries(result).map(([key, value]) => ({
        mtCd: key,
        menus: value,
      }));

      // 공통코드 API 조회 해서 메뉴정렬순서를 객체에 추가
      const updatedMenuArray = menuArray.map((menuEl) => {
        const matchingGrant = menuGrantComCdList.find((grant: any) => grant.rootSubCd === menuEl.mtCd);
        if (matchingGrant) {
          return { ...menuEl, cdSort: matchingGrant.cdSort };
        }
        return menuEl;
      });

      const sortedMenuList = arraySortByAscdOrder(updatedMenuArray, 'cdSort');
      const resultArray: any[] = [];
      sortedMenuList.map((el) => {
        const newArray = arraySortByAscdOrder(el.menus, 'cdSort');
        newArray.map((el2) => {
          if (el2.mtCd.substring(0, 1) === 'M') {
            resultArray.push(el2);
          }
          let chk = false;
          const sub: any[] = [];
          let duplichk = '';
          userMenuList?.map((v: any) => {
            if (el2.mtCd === v.mtCd) {
              el2.sub.map((el3: any) => {
                if (el3.mdCd === v.mdCd && duplichk !== v.mdCd) {
                  chk = true;
                  duplichk = v.mdCd;
                  sub.push(el3);
                }
              });
            }
          });
          if (chk) resultArray.push({ ...el2, sub });
        });
      });
      setSideMenuList(resultArray);
    } else {
      // toast.error(t(ERROR));
    }
  };

  const getComCdMenuGrantAPI = async () => {
    const req = { grCd: COMCD_MENU_GRANT };
    const res = await apiGet({ path: '/code/detail', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      setMenuGrantComCdList(data.comCdList);
    } else {
      // toast.error(t(ERROR));
    }
  };

  useEffect(() => {
    if (menuGrantComCdList && menuGrantComCdList.length > 0) {
      getSideMenuListAPI();
    }
  }, [menuGrantComCdList]);

  const saveMenuTitleArrayAPI = async (menuTitleArray: IMenuTitle[]) => {
    if (menuTitleArray.length > 0) {
      const req = { menuTitleList: trimArray(menuTitleArray) };
      const res = await apiPost({ path: `/menu/title?tab=${subCd}`, contentType: 'application/json', req });
      const { message, statusCode, data } = res.data;
      if (statusCode === 200) {
        toast.success(t(message));
        getSideMenuListAPI();

        const copyArray = [...data.menuTitleList];
        // Add an index property to each object in the array
        copyArray.forEach((obj: IMenuTitle, index: number) => {
          obj.no = index + 1; // Adding 1 to start index from 1
        });

        setTableState(copyArray);
        setRawData(data.menuTitleList);
        setNewRowIndex(data.menuTitleList.length);
        setRawDataLength(data.menuTitleList.length); //  로우데이터의 길이에 따라 신규추가항목의 No 를 세팅

        const findIndex = menuTitleArray.findIndex((el: IMenuTitle) => el.mtCd === mtCd.mtCd);
        if (findIndex !== -1) {
          setMtCd((prev) => ({ ...prev, mtCd: menuTitleArray[findIndex].mtCd, mtName: menuTitleArray[findIndex].mtName })); //  저장 성공시 마지막클릭한 로우 기준으로 코드항목테이블 항목명변경
        }
      } else {
        // toast.error(t(ERROR));
      }
    }
  };

  const saveMenuDetailArrayAPI = async (menuDetailArray: IMenuDetail[]) => {
    if (menuDetailArray.length > 0) {
      const res = await apiPost({ path: '/menu/detail', contentType: 'application/json', req: { menuDetailList: trimArray(menuDetailArray) } });
      const { message, statusCode, data } = res.data;
      if (statusCode === 200) {
        toast.success(t(message));
        getSideMenuListAPI();

        const copyArray = [...data.menuDetailList];
        // Add an index property to each object in the array
        copyArray.forEach((obj: IMenuTitle, index: number) => {
          obj.no = index + 1; // Adding 1 to start index from 1
        });

        setSubTableState(copyArray);
        setSubRawData(data.menuDetailList);
        setNewSubRowIndex(data.menuDetailList.length);
        setSubRawDataLength(data.menuDetailList.length); //  로우데이터의 길이에 따라 신규추가항목의 No 를 세팅

        const findIndex = menuDetailArray.findIndex((el: IMenuDetail) => el.mdCd === mdCd.mdCd);
        if (findIndex !== -1) {
          setMdCd((prev) => ({ ...prev, mdCd: menuDetailArray[findIndex].mdCd, mdName: menuDetailArray[findIndex].mdName })); //  저장 성공시 마지막클릭한 로우 기준으로 코드항목테이블 항목명변경
        }
      } else {
        // toast.error(t(ERROR));
      }
    }
  };

  const saveMenuReportArrayAPI = async (menuReportArray: IMenuReport[]) => {
    if (menuReportArray.length > 0) {
      const mrListToNumber = menuReportArray.map((obj) => {
        return {
          ...obj,
          mrList: Number(obj.mrList),
        };
      });
      const req = { menuReportList: trimArray(mrListToNumber) };
      const res = await apiPost({ path: '/menu/report', contentType: 'application/json', req });
      const { message, statusCode, data } = res.data;
      if (statusCode === 200) {
        toast.success(t(message));
        getSideMenuListAPI();

        const copyArray = [...data.menuReportList];
        // Add an index property to each object in the array
        copyArray.forEach((obj: IMenuReport, index: number) => {
          obj.no = index + 1; // Adding 1 to start index from 1
        });

        setReportTableState(copyArray);
        setReportRawData(data.menuReportList);
        setNewReportRowIndex(data.menuReportList.length);
        setReportRawDataLength(data.menuReportList.length); //  로우데이터의 길이에 따라 신규추가항목의 No 를 세팅

        const newListCount = data.searchLineList.map((el: IMenuReport) => ({
          type: 'mrList',
          mrList: el,
        }));
        setSearchLineList(newListCount);
        setInitiateLineSelectComponent((prev) => !prev); // 라인컴포넌트가 리렌더될수 있도록 처리

        if (data.menuReportList.length > 0) {
          //  기본값으로 선택되어있는 로우의 mrCd setState (기본값이 변경되었을 때 flag Y로 변경용)
          const selectedRow = data.menuReportList.find((el: IMenuReport) => el.rDefault === 'Y');
          if (selectedRow !== undefined) {
            setSelectedMrCd(selectedRow.mrCd);
          }
        }
      } else {
        // toast.error(t(ERROR));
      }
    }
  };

  const deleteCodeAPI = async (el: IMenuTitle) => {
    const res = await apiDelete({ path: '/menu/title', req: { mtCd: el.mtCd, editor: userInfo.userId } });
    const { message, statusCode, data } = res.data;
    if (statusCode === 200) {
      setMtCd({ showMtCd: false, mtCd: '', mtName: '' });
      setOpenModal((prev) => ({ ...prev, status: false }));

      const copyArray = [...data.menuTitleList];
      // Add an index property to each object in the array
      copyArray.forEach((obj: IMenuTitle, index: number) => {
        obj.no = index + 1; // Adding 1 to start index from 1
      });

      setTableState(copyArray);
      setRawData(data.menuTitleList);
      setNewRowIndex(data.menuTitleList.length);
      setRawDataLength(data.menuTitleList.length); //  로우데이터의 길이에 따라 신규추가항목의 No 를 세팅
    } else {
      // toast.error(t(ERROR));
    }
  };

  const deletesubCodeAPI = async (el: IMenuDetail) => {
    const req = { mtCd: mtCd.mtCd, mdCd: el.mdCd, editor: userInfo.userId };
    const res = await apiDelete({ path: '/menu/detail', req });
    const { message, statusCode, data } = res.data;
    if (statusCode === 200) {
      setMdCd({ showMdCd: false, mdCd: '', mdName: '' });
      setOpenModal((prev) => ({ ...prev, status: false }));

      const copyArray = [...data.menuDetailList];
      // Add an index property to each object in the array
      copyArray.forEach((obj: IMenuTitle, index: number) => {
        obj.no = index + 1; // Adding 1 to start index from 1
      });

      setSubTableState(copyArray);
      setSubRawData(data.menuDetailList);
      setNewSubRowIndex(data.menuDetailList.length);
      setSubRawDataLength(data.menuDetailList.length); //  로우데이터의 길이에 따라 신규추가항목의 No 를 세팅
    } else {
      // toast.error(t(ERROR));
    }
  };

  const deleteReportAPI = async (el: IMenuReport) => {
    const req = { mtCd: mtCd.mtCd, mdCd: mdCd.mdCd, mrCd: el.mrCd, editor: userInfo.userId };
    const res = await apiDelete({ path: '/menu/report', req });
    const { message, statusCode, data } = res.data;
    if (statusCode === 200) {
      const copyArray = [...data.menuReportList];
      // Add an index property to each object in the array
      copyArray.forEach((obj: IMenuReport, index: number) => {
        obj.no = index + 1; // Adding 1 to start index from 1
      });

      setReportTableState(copyArray);
      setReportRawData(data.menuReportList);
      setNewReportRowIndex(data.menuReportList.length);
      setReportRawDataLength(data.menuReportList.length); //  로우데이터의 길이에 따라 신규추가항목의 No 를 세팅

      if (data.menuReportList.length > 0) {
        //  기본값으로 선택되어있는 로우의 mrCd setState (기본값이 변경되었을 때 flag Y로 변경용)
        const selectedRow = data.menuReportList.find((dataEl: IMenuReport) => dataEl.rDefault === 'Y');
        if (selectedRow !== undefined) {
          setSelectedMrCd(selectedRow.mrCd);
        }
      }
      // setShowSubTable({ status: false, subCd: '', cdName: '' });/
      setOpenModal((prev) => ({ ...prev, status: false }));
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 메뉴 타이틀 신규항목 추가 클릭
  const onClickAddTableRow = () => {
    setNewRowIndex((prev) => prev + 1);

    const data = {
      index: newRowIndex,
      mtCd: '',
      mtName: '',
      useYn: 'Y',
      cdSort: '',
      bigo: '',
      flag: FLAG_CREATE_OR_UPDATE,
      writer: userInfo.userId,
    };

    // setTableState((prev: any[]) => [...prev, data]);
    increaseArrayNo(data, tableState, setTableState, newRowIndex, 'mtCd', 'mtName', userInfo.userId);
    setRawData((prev: any[]) => [...prev, data]);

    setIsTableInitialRender(false);
  };

  //  메뉴명 신규항목 추가 클릭
  const onClickAddSubTableRow = () => {
    // 메뉴타이틀 로우를 선택했을 때만 메뉴명 로우 추가
    if (mtCd.mtCd !== '') {
      setNewSubRowIndex((prev) => prev + 1);

      const data = {
        index: newSubRowIndex,
        mtCd: mtCd.mtCd,
        mdCd: '',
        mdName: '',
        useYn: 'Y',
        cdSort: '',
        flag: FLAG_CREATE_OR_UPDATE,
        writer: userInfo.userId,
        editor: userInfo.userId,
        bigo: '',
      };

      // setTableState((prev: any[]) => [...prev, data]);
      increaseArrayNo(data, subTableState, setSubTableState, newSubRowIndex, 'mdCd', 'mdName', userInfo.userId);
      setSubRawData((prev: any[]) => [...prev, data]);

      setIsSubTableInitialRender(false);
    }
  };

  // 보고서 신규항목추가 클릭
  const onClickAddReportTableRow = () => {
    // 메뉴타이틀 로우를 선택했을 때만 메뉴명 로우 추가
    if (mdCd.mdCd !== '') {
      setNewReportRowIndex((prev) => prev + 1);

      const data = {
        index: newReportRowIndex,
        mtCd: mtCd.mtCd,
        mdCd: mdCd.mdCd,
        mrCd: '',
        mrName: '',
        mrMemo: '',
        useYn: 'Y',
        cdSort: '',
        mrList: searchLineList[0].mrList,
        flag: FLAG_CREATE_OR_UPDATE,
        writer: userInfo.userId,
        bigo: '',
        rDefault: 'N',
      };

      // setTableState((prev: any[]) => [...prev, data]);
      increaseArrayNo(data, reportTableState, setReportTableState, newReportRowIndex, 'mrCd', 'mrName', userInfo.userId);
      setReportRawData((prev: any[]) => [...prev, data]);

      setIsReportTableInitialRender(false);
    }
  };

  const saveRow = (requiredFieldArray: string[], rawState: any[], saveAPI: (array: any[]) => void) => {
    const emptyCheck = rawState.filter((el: any) => {
      const check = requiredFieldArray.find((el2: any) => el[el2] === '' && el.flag === FLAG_CREATE_OR_UPDATE);
      return check;
    });
    if (emptyCheck.length > 0) {
      toast.warning(t('필수입력값을 모두 입력하세요'));
    } else {
      // 빈값이 없는 경우 수정,업데이트된 로우들만 서버에 전달
      const dbSendRows = rawState.filter((el: IMenuTitle) => el.flag === FLAG_CREATE_OR_UPDATE);
      const newArray = dbSendRows.map(({ index, no, ...rest }) => rest);
      saveAPI(newArray);
    }
  };

  // cdSort값을 string에서 number로 변경
  const convertCdSortToNumber = (array: any[]) => {
    return array.map((el) => ({ ...el, cdSort: Number(el.cdSort) }));
  };

  // cdSort의 value(number) 값이 0 이상인 지 확인
  const isCdSortValueGreaterThanZero = (array: any[]) => {
    const check = array.find((el) => el.cdSort <= 0 && el.flag === FLAG_CREATE_OR_UPDATE);

    if (check === undefined) return true;
    return toast.warning(t('0 이상의 정렬값을 입력하세요'));
  };

  const onClickSaveTableState = () => {
    setIsSaveClicked(true);
    const convertedData = convertCdSortToNumber(rawData);
    if (isCdSortValueGreaterThanZero(convertedData)) {
      const required = ['mtName', 'cdSort']; // 필수입력값 name
      saveRow(required, convertedData, saveMenuTitleArrayAPI);
    }
  };

  const onClickSaveSubTableState = () => {
    setIsSave2Clicked(true);
    const convertedData = convertCdSortToNumber(subRawData);
    if (isCdSortValueGreaterThanZero(convertedData)) {
      const required = ['mdName', 'cdSort']; // 필수입력값 name
      saveRow(required, convertedData, saveMenuDetailArrayAPI);
    }
  };

  const onClickSaveReportTableState = () => {
    setIsSave3Clicked(true);
    // 기본값이 Y이면서 동시에 사용유무가 N 인 경우
    const rDefaultYUseYnN = reportTableState.find((el) => el.rDefault === 'Y' && el.useYn === 'N');
    if (rDefaultYUseYnN !== undefined) {
      return toast.warning(t('기본값 보고서의 경우 미사용할 수 없습니다'));
    }

    const findRDefault = reportTableState.find((el: IMenuReport) => el.rDefault === 'Y');
    const rawDataArray: any = [...reportRawData]; // 데이터테이블 로우 어레이
    let updateRawArray;
    if (findRDefault !== undefined) {
      if (typeof selectedMrCd === 'string') {
        //  선택된 기본값이 기존 로우인경우
        // 데이터테이블 업데이트
        updateRawArray = rawDataArray.map((rawEl: IMenuReport) => {
          return rawEl.mrCd === selectedMrCd
            ? { ...rawEl, rDefault: 'Y', flag: FLAG_CREATE_OR_UPDATE, editor: userInfo.userId }
            : { ...rawEl, rDefault: 'N', flag: rawEl.flag === FLAG_DELETE ? FLAG_DELETE : FLAG_CREATE_OR_UPDATE, editor: userInfo.userId };
        });
        setReportRawData(updateRawArray);
      } else {
        //  선택된 기본값이 새 로우인 경우
        // 데이터 테이블 업데이트
        updateRawArray = rawDataArray.map((el: IMenuReport) => {
          return el.index === selectedMrCd
            ? { ...el, rDefault: 'Y', flag: FLAG_CREATE_OR_UPDATE, editor: userInfo.userId }
            : { ...el, rDefault: 'N', flag: el.flag === FLAG_DELETE ? FLAG_DELETE : FLAG_CREATE_OR_UPDATE, editor: userInfo.userId };
        });
        setReportRawData(updateRawArray);
      }

      const convertedData = convertCdSortToNumber(updateRawArray);
      if (isCdSortValueGreaterThanZero(convertedData)) {
        const required = ['mrName', 'cdSort']; // 필수입력값 name
        saveRow(required, convertedData, saveMenuReportArrayAPI);
      }
    } else toast.warning(t('보고서 기본값을 선택하세요'));
    return undefined;
  };

  const onChangeTableState = (e: React.ChangeEvent<HTMLInputElement>, i: number, el: IMenuTitle) => {
    const { name, value } = e.currentTarget;
    let insertValue: string | number = value;

    if (name === 'cdSort') insertValue = limitDigits(value); //  input name이 cdSort인 경우, value값을 Number로 변환
    const viewDataArray = [...tableState]; // 뷰테이블 로우 어레이
    const rawDataArray: any = [...rawData]; // 데이터테이블 로우 어레이

    let findIndex;
    if (el.index === undefined) {
      // 새로운 로우가 아닐때, 데이터테이블에서 해당하는 로우의 인덱스값 찾기
      findIndex = rawData.findIndex((rawEl: IMenuTitle) => rawEl.mtCd === el.mtCd);
    } else {
      // 새로운 로우일때 인덱스값
      findIndex = el.index;
    }

    viewDataArray[i] = { ...viewDataArray[i], [name]: insertValue, flag: FLAG_CREATE_OR_UPDATE }; // 뷰테이블 로우 업데이트
    if (findIndex !== -1) {
      // 데이터테이블 로우 업데이트
      rawDataArray[findIndex] = { ...rawDataArray[findIndex], [name]: insertValue, flag: FLAG_CREATE_OR_UPDATE, editor: userInfo.userId };
    }

    setTableState(viewDataArray);
    setRawData(rawDataArray);
  };

  // 타이틀 메뉴명 수정될때
  const onChangeSubTableState = (e: React.ChangeEvent<HTMLInputElement>, i: number, el: IMenuDetail) => {
    const { name, value } = e.currentTarget;
    let insertValue: string | number = value;

    if (name === 'cdSort') insertValue = limitDigits(value); //  input name이 cdSort인 경우, value값을 Number로 변환
    const viewDataArray = [...subTableState]; // 뷰테이블 로우 어레이
    const rawDataArray: any = [...subRawData]; // 데이터테이블 로우 어레이

    let findIndex;
    if (el.index === undefined) {
      // 새로운 로우가 아닐때, 데이터테이블에서 해당하는 로우의 인덱스값 찾기
      findIndex = subRawData.findIndex((rawEl: any) => rawEl.mdCd === el.mdCd);
    } else {
      // 새로운 로우일때 인덱스값
      findIndex = el.index;
    }

    viewDataArray[i] = { ...viewDataArray[i], [name]: insertValue, flag: FLAG_CREATE_OR_UPDATE }; // 뷰테이블 로우 업데이트
    if (findIndex !== -1) {
      // 데이터테이블 로우 업데이트
      rawDataArray[findIndex] = { ...rawDataArray[findIndex], [name]: insertValue, flag: FLAG_CREATE_OR_UPDATE, editor: userInfo.userId };
    }

    setSubTableState(viewDataArray);
    setSubRawData(rawDataArray);
  };

  // 타이틀 메뉴명 수정될때
  const onChangeReportTableState = (e: React.ChangeEvent<HTMLInputElement>, i: number, el: IMenuReport) => {
    const { name, value } = e.currentTarget;

    let insertValue: string | number = value;

    if (name === 'cdSort') insertValue = limitDigits(value); //  input name이 cdSort인 경우, value값을 Number로 변환
    const viewDataArray = [...reportTableState]; // 뷰테이블 로우 어레이
    const rawDataArray: any = [...reportRawData]; // 데이터테이블 로우 어레이

    let findIndex;
    if (el.index === undefined) {
      // 새로운 로우가 아닐때, 데이터테이블에서 해당하는 로우의 인덱스값 찾기
      findIndex = reportRawData.findIndex((rawEl: any) => rawEl.mrCd === el.mrCd);
    } else {
      // 새로운 로우일때 인덱스값
      findIndex = el.index;
    }

    viewDataArray[i] = { ...viewDataArray[i], [name]: insertValue, flag: FLAG_CREATE_OR_UPDATE }; // 뷰테이블 로우 업데이트
    if (findIndex !== -1) {
      // 데이터테이블 로우 업데이트
      rawDataArray[findIndex] = { ...rawDataArray[findIndex], [name]: insertValue, flag: FLAG_CREATE_OR_UPDATE, editor: userInfo.userId };
    }

    setReportTableState(viewDataArray);
    setReportRawData(rawDataArray);
  };

  const onChangeRadioOption = (e: React.ChangeEvent<HTMLInputElement>, i: number, el: IMenuReport) => {
    const viewDataArray = [...reportTableState]; // 뷰테이블 로우 어레이
    const rawDataArray: any = [...reportRawData]; // 데이터테이블 로우 어레이

    if (el.index === undefined) {
      // 뷰데이터 업데이트
      const updateViewArray = viewDataArray.map((viewEl, viewIndex) => {
        return viewIndex === i ? { ...viewEl, rDefault: 'Y', flag: FLAG_CREATE_OR_UPDATE, editor: userInfo.userId } : { ...viewEl, rDefault: 'N' };
      });
      setReportTableState(updateViewArray);
      setSelectedMrCd(el.mrCd);
    } else {
      // 뷰데이터 업데이트
      const updateViewArray = viewDataArray.map((viewEl, viewIndex) => {
        return viewIndex === i ? { ...viewEl, rDefault: 'Y' } : { ...viewEl, rDefault: 'N' };
      });
      setReportTableState(updateViewArray);
      setSelectedMrCd(el.index);
    }
  };

  const decreaseArrayNo = (array: IMenuTitle[], dataLength: number, setState: Dispatch<SetStateAction<IMenuTitle[]>>) => {
    let increase = 0;

    const newArray = array.map((el, i) => {
      if (el.index !== undefined) {
        increase += 1;
        const copyArray = [...array];
        copyArray[i] = {
          ...copyArray[i],
          no: dataLength + increase,
        };
        return copyArray[i];
      }
      return el;
    });
    setState(newArray);
  };

  const deleteRow = (
    el: any,
    i: number,
    state: any[],
    setState: Dispatch<SetStateAction<any[]>>,
    rawState: any[],
    setRawState: Dispatch<SetStateAction<any[]>>,
    deleteAPI: (el: any) => void,
    setInitialRender: Dispatch<SetStateAction<boolean>>,
    rawStateLength: number
  ) => {
    // 새로 추가한 로우 제거 클릭 시
    if (el.index !== undefined) {
      // 뷰테이블 로우 삭제
      const updatedArray = [...state];
      updatedArray.splice(i, 1);

      decreaseArrayNo(updatedArray, rawStateLength, setState);

      // 데이터테이블 로우 삭제 (플래그 D로 업데이트)
      const findIndex = rawState.findIndex((rawEl: any) => rawEl.index === el.index);
      const rawDataArray: any = [...rawState];
      if (findIndex !== -1) {
        rawDataArray[findIndex] = {
          ...rawDataArray[findIndex],
          flag: FLAG_DELETE,
        };
        setRawState(rawDataArray);
      }
    } else {
      // 삭제 시 에디터아이디 추가
      const data = {
        ...el,
        editor: userInfo.userId,
      };
      setOpenModal((prev: any) => ({ ...prev, status: true, state, setState, api: deleteAPI, el: data, index: i }));
    }
    setInitialRender(true); // 삭제 클릭시 테이블길이 변화인한 스크롤하단 이동방지
  };

  const onClickDelete = (el: IMenuTitle, i: number) => {
    deleteRow(el, i, tableState, setTableState, rawData, setRawData, deleteCodeAPI, setIsTableInitialRender, rawDataLength);
  };

  const onClickSubDelete = (el: IMenuDetail, i: number) => {
    deleteRow(el, i, subTableState, setSubTableState, subRawData, setSubRawData, deletesubCodeAPI, setIsSubTableInitialRender, subRawDataLength);
  };

  const onClickReportDelete = (el: IMenuReport, i: number) => {
    if (el.rDefault === 'N') {
      deleteRow(el, i, reportTableState, setReportTableState, reportRawData, setReportRawData, deleteReportAPI, setIsReportTableInitialRender, reportRawDataLength);
    } else toast.warning(t('기본 보고서는 삭제하실 수 없습니다'));
  };

  const onClickTableState = (el: IMenuTitle) => {
    if (el.index === undefined) {
      //  새 로우가 아닐 때
      const { mtName } = el;
      setMtCd({ showMtCd: true, mtCd: el.mtCd, mtName });
      getMenuDetailListAPI(el.mtCd);

      // 클릭하여 현재 보고있던 타이틀로우가 아닐 경우
      if (mtCd.mtCd !== el.mtCd) {
        // 보고서테이블 초기화 (타이틀의 보고서 뿌려진상태에서 타이틀로우 변경후 다른메뉴 클릭시 리스트 깜빡임현상 없도록)
        setReportTableState([]);
      }
    }
  };

  const onClickSubTableState = (el: IMenuDetail) => {
    if (el.index === undefined) {
      //  새 로우가 아닐 때
      const { mdName } = el;
      setMdCd({ showMdCd: true, mdCd: el.mdCd, mdName });
      getMenuReportListAPI(el.mtCd, el.mdCd);
    }
  };

  const onClickMenu = (el: any) => {
    if (el !== undefined) {
      onClickSubTableState(el);
      setViewReport(true);
    }
  };

  return (
    <>
      <div className='content-container setting-medical'>
        {!viewReport && (
          <Root>
            <div className='inputFormsWrapper'>
              <div className='formTitle'>{t('메뉴 타이틀')}</div>
            </div>
            <InputTable className='margin-left-05'>
              <div className='thead'>
                <div className='tr'>
                  <div className='trCol2p5 flex-center tableStickyNo'>No</div>
                  <div className='trCol5 flex-center'>{t('코드')}</div>
                  <div className='trCol10 required flex-center content-overflow'>{t('타이틀 명')}</div>
                  <div className='trCol5 flex-center'>{t('사용유무')}</div>
                  <div className='trCol4 required flex-center'>{t('정렬')}</div>
                  <div className='trCol10 flex-center content-overflow'>{t('비고')}</div>
                  <div className='trCol4'> </div>
                </div>
              </div>
              <div className='table' ref={scrollContainerRef}>
                <div className='tbody'>
                  {tableState?.length > 0 ? (
                    tableState.map((el: any, i: number) => (
                      <div className='tr' role='button' tabIndex={0} key={i} onClick={() => onClickTableState(el)}>
                        <div className='trCol2p5 flex-center tableStickyNo'>{el.no}</div>
                        <div className='trCol5 flex-center'>{el.mtCd}</div>
                        <div className='trCol10 flex-basic content-overflow'>
                          <input
                            type='text'
                            id='mtName'
                            name='mtName'
                            value={t(el.mtName)}
                            onChange={(e) => onChangeTableState(e, i, el)}
                            style={isSaveClicked ? applyBorderStyle(t(el.mtName), 'red', 'mtName') : undefined}
                            onClick={(event) => {
                              if (size?.innerSize.W !== undefined && size.innerSize.W < 1024) {
                                event.stopPropagation();
                              }
                            }}
                            maxLength={50}
                          />
                        </div>
                        <div className='trCol5 flex-center'>
                          <SelectBoxs
                            options={USE_YN}
                            defaultOption={el.useYn === 'Y' ? t('사용') : t('미사용')}
                            state={tableState}
                            setState={setTableState}
                            rawData={rawData}
                            setRawData={setRawData}
                            stateKey='useYn'
                            codeKey='cdName'
                            index={i}
                            object={el}
                            primaryKey='mtCd'
                          />
                        </div>
                        <div className='trCol4 flex-center'>
                          <input
                            type='text'
                            id='cdSort'
                            name='cdSort'
                            value={el.cdSort}
                            onChange={(e) => onChangeTableState(e, i, el)}
                            style={isSaveClicked ? applyBorderStyle(el.cdSort, 'red', 'cdSort') : undefined}
                            onClick={(event) => {
                              if (size?.innerSize.W !== undefined && size.innerSize.W < 1024) {
                                event.stopPropagation();
                              }
                            }}
                            maxLength={50}
                          />
                        </div>
                        <div className='trCol10 flex-center content-overflow'>
                          <input
                            type='text'
                            id='bigo'
                            name='bigo'
                            value={el.bigo || ''}
                            onClick={(event) => {
                              if (size?.innerSize.W !== undefined && size.innerSize.W < 1024) {
                                event.stopPropagation();
                              }
                            }}
                            onChange={(e) => onChangeTableState(e, i, el)}
                            maxLength={200}
                          />
                        </div>
                        <div className='trCol4 flex-center'>
                          <BtnRed
                            onClick={(event) => {
                              if (size?.innerSize.W !== undefined && size.innerSize.W < 1024) {
                                event.stopPropagation();
                              }
                              onClickDelete(el, i);
                            }}
                          >
                            {el.mtCd !== '' ? t('삭제') : t('제거')}
                          </BtnRed>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className='noData'>No data.</div>
                  )}
                </div>
              </div>
            </InputTable>
            <ButtonsWrapper>
              <BtnBlue onClick={onClickAddTableRow}>{t('신규항목 추가')}</BtnBlue>
              <BtnBlue onClick={onClickSaveTableState}>{t('저장')}</BtnBlue>
            </ButtonsWrapper>
          </Root>
        )}
        {mtCd.showMtCd ? (
          <Root>
            <div className='inputFormsWrapper goBack'>
              {viewReport && <BackButton func={() => setViewReport(false)} />}
              <div className='formTitle'>
                {mtCd.showMtCd ? `${t(mtCd.mtName)}(${mtCd.mtCd})` : undefined}
                {t('메뉴명')}
              </div>
            </div>
            <InputTable>
              <div className='thead'>
                <div className='tr'>
                  <div className='trCol2p5 flex-center tableStickyNo'>No</div>
                  <div className='trCol5 flex-center'>{t('코드')}</div>
                  <div className='trCol10 required flex-center content-overflow'>{t('타이틀 명')}</div>
                  <div className='trCol5 flex-center'>{t('사용유무')}</div>
                  <div className='trCol4 required flex-center'>{t('정렬')}</div>
                  <div className='trCol10 flex-center content-overflow'>{t('비고')}</div>
                  <div className='trCol4'> </div>
                </div>
              </div>
              <div className='table' ref={scrollSubContainerRef}>
                <div className='tbody'>
                  {subTableState.map((el: any, i: number) => (
                    <div className='tr' role='button' tabIndex={0} key={i} onClick={() => onClickMenu(el)}>
                      <div className='trCol2p5 flex-center tableStickyNo'>{el.no}</div>
                      <div className='trCol5 flex-center'>{el.mdCd}</div>
                      <div className='trCol10 flex-basic content-overflow'>
                        <input
                          type='text'
                          id='mdName'
                          name='mdName'
                          value={t(el.mdName)}
                          onChange={(e) => onChangeSubTableState(e, i, el)}
                          style={isSave2Clicked ? applyBorderStyle(t(el.mdName), 'red', 'mdName') : undefined}
                          maxLength={50}
                        />
                      </div>
                      <div className='trCol5 flex-center'>
                        <SelectBoxs
                          options={USE_YN}
                          defaultOption={el.useYn === 'Y' ? t('사용') : t('미사용')}
                          state={subTableState}
                          setState={setSubTableState}
                          rawData={subRawData}
                          setRawData={setSubRawData}
                          stateKey='useYn'
                          codeKey='cdName'
                          index={i}
                          object={el}
                          primaryKey='mdCd'
                        />
                      </div>
                      <div className='trCol4 flex-center'>
                        <input
                          type='text'
                          id='cdSort'
                          name='cdSort'
                          value={el.cdSort}
                          onChange={(e) => onChangeSubTableState(e, i, el)}
                          style={isSave2Clicked ? applyBorderStyle(el.cdSort, 'red', 'cdSort') : undefined}
                          maxLength={50}
                        />
                      </div>
                      <div className='trCol10 flex-center content-overflow'>
                        <input type='text' id='bigo' name='bigo' value={el.bigo || ''} onChange={(e) => onChangeSubTableState(e, i, el)} maxLength={200} />
                      </div>
                      <div className='trCol4 flex-center'>
                        <BtnRed onClick={() => onClickSubDelete(el, i)}>{el.mdCd !== '' ? t('삭제') : t('제거')}</BtnRed>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </InputTable>
            <ButtonsWrapper>
              <BtnBlue onClick={onClickAddSubTableRow}>{t('신규항목 추가')}</BtnBlue>
              <BtnBlue onClick={onClickSaveSubTableState}>{t('저장')}</BtnBlue>
            </ButtonsWrapper>
          </Root>
        ) : (
          size !== undefined &&
          size.innerSize.W >= 1024 && (
            <Root>
              <div className='emptyData'>
                <img src={illustrator} alt='noData' />
                <span>{t('왼쪽 목록에서 메뉴 타이틀을 선택해주세요')}</span>
              </div>
            </Root>
          )
        )}
        {viewReport && (
          <Root>
            <div className='inputFormsWrapper report'>
              <div className='formTitle'>
                {mdCd.showMdCd ? `${t(mdCd.mdName)}(${mdCd.mdCd})` : undefined}
                {t('보고서')}
              </div>
            </div>
            <InputTable>
              <div className='thead'>
                <div className='tr'>
                  <div className='trCol2p5 flex-center tableStickyNo'>No</div>
                  <div className='trCol5 flex-center'>{t('코드')}</div>
                  <div className='trCol10 required flex-center content-overflow tableStickyTitle'>{t('보고서명')}</div>
                  <div className='trCol10 required flex-center content-overflow'>{t('보고서 내용')}</div>
                  <div className='trCol5 flex-center'>{t('검색줄수')}</div>
                  <div className='trCol4 flex-center'>{t('기본값')}</div>
                  <div className='trCol5 flex-center'>{t('사용유무')}</div>
                  <div className='trCol4 flex-center required'>{t('정렬')}</div>
                  <div className='trCol10 flex-center content-overflow'>{t('비고')}</div>
                  <div className='trCol4'> </div>
                </div>
              </div>
              <div className={`${reportTableState?.length > 0 ? 'table' : 'table img'}`} ref={scrollReportContainerRef}>
                {mdCd.showMdCd && reportTableState.length > 0 ? (
                  <div className='tbody'>
                    {reportTableState.map((el: any, i: number) => (
                      <div className='tr' role='button' tabIndex={0} key={i}>
                        <div className='trCol2p5 flex-center tableStickyNo'>{el.no}</div>
                        <div className='trCol5 flex-center'>{el.mrCd}</div>
                        <div className='trCol10 flex-center content-overflow tableStickyTitle'>
                          <input
                            type='text'
                            id='mrName'
                            name='mrName'
                            value={t(el.mrName)}
                            onChange={(e) => onChangeReportTableState(e, i, el)}
                            style={isSave3Clicked ? applyBorderStyle(t(el.mrName), 'red', 'mrName') : undefined}
                            maxLength={50}
                          />
                        </div>
                        <div className='trCol10 flex-center content-overflow'>
                          <input type='text' id='mrMemo' name='mrMemo' value={el.mrMemo} onChange={(e) => onChangeReportTableState(e, i, el)} maxLength={50} />
                        </div>
                        <div className='trCol5 flex-center'>
                          <SelectBoxs
                            options={searchLineList}
                            defaultOption={el.mrList}
                            state={reportTableState}
                            setState={setReportTableState}
                            rawData={reportRawData}
                            setRawData={setReportRawData}
                            stateKey='mrList'
                            codeKey='mrList'
                            index={i}
                            object={el}
                            primaryKey='mrCd'
                            initiateKey={initiateLineSelectComponent}
                            optionHeight='height-sm'
                          />
                        </div>
                        <div className='trCol4 flex-center'>
                          <input type='radio' name='rDefault' disabled={el.useYn === 'N'} checked={el.rDefault === 'Y'} onChange={(e) => onChangeRadioOption(e, i, el)} />
                        </div>
                        <div className='trCol5 flex-center'>
                          <SelectBoxs
                            options={USE_YN}
                            defaultOption={el.useYn === 'Y' ? t('사용') : t('미사용')}
                            state={reportTableState}
                            setState={setReportTableState}
                            rawData={reportRawData}
                            setRawData={setReportRawData}
                            stateKey='useYn'
                            codeKey='cdName'
                            index={i}
                            object={el}
                            primaryKey='mrCd'
                          />
                        </div>
                        <div className='trCol4 flex-center'>
                          <input
                            type='text'
                            id='cdSort'
                            name='cdSort'
                            value={el.cdSort}
                            onChange={(e) => onChangeReportTableState(e, i, el)}
                            style={isSave3Clicked ? applyBorderStyle(el.cdSort, 'red', 'cdSort') : undefined}
                            maxLength={50}
                          />
                        </div>
                        <div className='trCol10 flex-center content-overflow'>
                          <input type='text' id='bigo' name='bigo' value={el.bigo} onChange={(e) => onChangeReportTableState(e, i, el)} maxLength={200} />
                        </div>
                        <div className='trCol4 flex-center'>
                          <BtnRed onClick={() => onClickReportDelete(el, i)}>{el.mrCd !== '' ? t('삭제') : t('제거')}</BtnRed>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <EmptyData>
                    <img src={emptyIllustrator} alt='noData' />
                    {/* <p>필터를 적용해 주세요.</p> */}
                    <p>{t('데이터가 존재하지 않습니다')}</p>
                  </EmptyData>
                  // <div className='noData'>No data.</div>
                )}
              </div>
            </InputTable>
            <ButtonsWrapper>
              <BtnBlue onClick={onClickAddReportTableRow}>{t('신규항목 추가')}</BtnBlue>
              <BtnBlue onClick={onClickSaveReportTableState}>{t('저장')}</BtnBlue>
            </ButtonsWrapper>
          </Root>
        )}
      </div>
      <Portal openModal={openModal?.status}>{openModal && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
    </>
  );
};

const Mmenu1 = () => {
  const [userInfo, setUserInfo] = useRecoilState<IUser>(userState);
  const { userMenuList } = userInfo;
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);

  const onClickTab = (mrCd: string) => {
    setCurrentTabMrCd(mrCd);
  };

  const renderPage = () => {
    const activeTab = tabInfo.tabList?.find((el: ITabObject) => el.mrCd === currentTabMrCd);
    if (activeTab !== undefined) {
      const { mrCd } = activeTab;
      switch (mrCd) {
        case '001':
          return <Master content={<Component subCd='M' size={size} />} />;
        case '002':
          return <Company content={<Component subCd='C' size={size} />} />;
        case '003':
          return <Head content={<Component subCd='H' size={size} />} />;
        case '004':
          return <Site content={<Component subCd='S' size={size} />} />;
      }
    }
    return undefined;
  };

  return (
    <div className='contents'>
      <Tab tabList={tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      {renderPage()}
    </div>
  );
};

export default Mmenu1;
