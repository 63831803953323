/**
 * 작성자 : 홍선영
 * 날짜 : 2023.04.12
 * 기능 : 마스터관리-현장일반코드관리 페이지
 */

import React, { useState, useEffect, SetStateAction, Dispatch, useRef } from 'react';
import { v1 } from 'uuid';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { useOutletContext } from 'react-router-dom';

import { pageInfoState, userState } from '../../atoms';
import styled from 'styled-components';
import { InputTable } from '../../assets/styles/InputTable';
import { IModal } from 'customTypes';
import { FLAG_CREATE_OR_UPDATE, FLAG_DELETE } from '../../_constants';
import Tab from '../../components/Tab';
import Portal from '../../components/Portal';
import DeleteModal from '../../components/Modal/DeleteModal';
import { BtnBlue, BtnRed } from '../../components/Button';
import { trimArray } from '../../utils/trimArray';
import { applyBorderStyle } from '../../utils/applyBorderStyle';
import { scrollToNodeBottom } from '../../utils/scrollToNodeBottom';
import { logPost } from '../../services/log';
import { scrollToNodeTop } from '../../utils/scrollToNodeTop';
import { useTranslation } from 'react-i18next';
import BackButton from '../../components/BackButton';
import { apiGet, apiPost } from '../../services/_common';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 0.5rem;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;
const Root = styled.div`
  height: initial;
  @media (min-width: 1024px) {
    height: 100%;
  }
  > div:nth-child(2) {
    flex-grow: 1;
    overflow: hidden;
    width: 100%;
    overflow: auto;
    position: relative;
  }
  .inputFormsWrapper {
    border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    padding: 0.5rem;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .inputFormsWrapper.smallTab {
    flex-direction: row;
    padding: 0 0.5rem;
    font-weight: 500;
    width: 100%;
    overflow: auto;
    gap: 0;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 0rem !important;
    }
    .tab {
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
      cursor: pointer;
      height: 3.5rem;
      border-bottom: 2px solid transparent;
      flex-shrink: 0;
    }
    .activeTab {
      font-weight: 700;
      color: ${({ theme }: { theme: any }) => theme.selected_primary};
      border-bottom: 2px solid ${({ theme }: { theme: any }) => theme.selected_primaty};
    }
  }
  .formTitle {
    padding: 0 0.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .titleDetail {
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    span {
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      /* font-weight: 600; */
      margin: 0 0.25rem;
    }
  }
  .material-symbols-rounded {
    font-variation-settings: 'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 48;
  }
  .arrow-icon span {
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    gap: 0.25rem;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 5rem;
    padding-right: 1rem;
    padding-left: 0.5rem;
    font-size: 0.875rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .icon-btn:hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
  }
`;

interface IComCdList {
  index: number;
  no?: number;
  grCd: string;
  subCd: string;
  cdName: string;
  flag: string;
  writer: string;
  wDate?: string;
  editor?: string;
  eDate?: string;
}

const Master3 = () => {
  const { t } = useTranslation();
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  const userInfo = useRecoilValue(userState);
  const scrollContainerRef = useRef<HTMLInputElement>(null);
  const scrollSubContainerRef = useRef<HTMLInputElement>(null);
  const [tableState, setTableState] = useState<IComCdList[]>([]);
  const [rawData, setRawData] = useState<IComCdList[]>([]);
  const [comCdList, setComCdList] = useState<IComCdList[]>([]);
  const [normalCdTitle, setNormalCdTitle] = useState<any>({ subCd: 'A', cdName: `${t('공종')}/${t('직종')}`, etc1: '2' });

  // const [normalCdTitle, setNormalCdTitle] = useState<any>({ subCd: '', cdName: '', etc1: '' });
  const [showSubTable, setShowSubTable] = useState({ status: false, subCd: '', cdName: '' });
  const [subTableState, setSubTableState] = useState<any[]>([]);
  const [subRawData, setSubRawData] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const [newRowIndex, setNewRowIndex] = useState<number>(0); // 신규항목 추가 클릭했을 때 새 로우객체에 인덱스추가
  const [newSubRowIndex, setNewSubRowIndex] = useState<number>(0); // 신규항목 추가 클릭했을 때 새 로우객체에 인덱스추가
  const [rawDataLength, setRawDataLength] = useState<number>(0); // 테이블에 표시할 로우 넘버
  const [subRawDataLength, setSubRawDataLength] = useState<number>(0); // 테이블에 표시할 로우 넘버
  const [isTableInitialRender, setIsTableInitialRender] = useState(true); // 컴포넌트의 이니셜렌더 여부
  const [isSubTableInitialRender, setIsSubTableInitialRender] = useState(true); // 서브테이블 컴포넌트의 이니셜렌더 여부
  const SUB_TABLE_EXIST = '2'; // etc1 값이 2일때, 서브테이블 있음
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장1버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [isSave2Clicked, setIsSave2Clicked] = useState(false); // 저장2버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const { userMenuList } = userInfo;

  useEffect(() => {
    //  로우가 1개 이상이고, 이니셜렌더가 아닌경우 스크롤을 하단으로 이동
    if (tableState.length > 0 && !isTableInitialRender) scrollToNodeBottom(scrollContainerRef);
  }, [tableState.length]);

  useEffect(() => {
    if (subTableState.length > 0 && !isSubTableInitialRender) scrollToNodeBottom(scrollSubContainerRef);
  }, [subTableState.length]);

  // 현장일반코드 리스트 요청
  const getNormalCodeAPI = async () => {
    const res = await apiGet({ path: '/code/normal' });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      setComCdList(data.comCdList);
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  // 첫번째 테이블 리스트 요청
  const getNormalCdTitleAPI = async (subCd: string) => {
    const req = { subCd };
    const res = await apiGet({ path: '/code/normalTitle', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const copyArray = [...data.normalCdList];
      // Add an index property to each object in the array
      copyArray.forEach((obj: IComCdList, index: number) => {
        obj.no = index + 1; // Adding 1 to start index from 1
      });

      setTableState(copyArray);
      setRawData(data.normalCdList);
      setNewRowIndex(data.normalCdList.length);
      setRawDataLength(data.normalCdList.length);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 현장일반코드 버튼 클릭시, 첫번째테이블 리스트 요청
  // 공종, 직종버튼 클릭시 서브테이블 상세항목(두번째테이블) view status true
  const onClickNormalCdBtn = (el: any) => {
    const { subCd, cdName } = el;
    getNormalCdTitleAPI(el.subCd);
    setNormalCdTitle((prev: any) => ({ ...prev, subCd, cdName, etc1: el.etc1 }));
    if (el.etc1 !== SUB_TABLE_EXIST) setShowSubTable((prev) => ({ ...prev, status: false }));
    scrollToNodeTop(scrollContainerRef);
  };

  const backToMain = () => {
    setShowSubTable((prev) => ({ ...prev, status: false }));
  };

  const increaseArrayNo = (data: any, state: IComCdList[], setState: Dispatch<SetStateAction<IComCdList[]>>, newIndex: number) => {
    const copyArray2 = [...state, data];
    copyArray2.map((el, i) => {
      if (el.index !== undefined) {
        const copyArray = [...copyArray2];
        copyArray[i] = {
          ...copyArray[i],
          index: newIndex,
          grCd: '000',
          subCd: normalCdTitle.subCd,
          cdName: '',
          flag: FLAG_CREATE_OR_UPDATE,
          writer: userInfo.userId,
          no: copyArray2.length,
        };
        setState(copyArray);
      }
    });
  };

  // 로우 추가 클릭
  const onClickAddTableRow = () => {
    if (normalCdTitle.subCd !== '') {
      setNewRowIndex((prev) => prev + 1);

      const data = {
        index: newRowIndex,
        grCd: '000',
        subCd: normalCdTitle.subCd,
        cdName: '',
        flag: FLAG_CREATE_OR_UPDATE,
        writer: userInfo.userId,
      };
      setTableState((prev: IComCdList[]) => [...prev, data]);
      setRawData((prev: IComCdList[]) => [...prev, data]);

      increaseArrayNo(data, tableState, setTableState, newRowIndex);
      setIsTableInitialRender(false);
    }
  };

  // 상세항목 로우 추가 클릭
  const onClickAddSubTableRow = () => {
    if (normalCdTitle.etc1 === SUB_TABLE_EXIST && showSubTable.status) {
      if (showSubTable.subCd.length > 1) {
        setNewSubRowIndex((prev) => prev + 1);

        const data = {
          index: newSubRowIndex,
          subCd: '',
          grCd: showSubTable.subCd,
          cdName: '',
          flag: FLAG_CREATE_OR_UPDATE,
          writer: userInfo.userId,
        };
        setSubTableState((prev: IComCdList[]) => [...prev, data]);
        setSubRawData((prev: IComCdList[]) => [...prev, data]);

        increaseArrayNo(data, subTableState, setSubTableState, newSubRowIndex);
        setIsSubTableInitialRender(false);
      } else toast.warning(t('입력하신 타이틀 저장 후 상세항목을 입력하세요'));
    }
  };

  // 뷰테이블 값이 수정될때
  const onChangeTableState = (e: React.ChangeEvent<HTMLInputElement>, i: number, el: IComCdList) => {
    const { name, value } = e.currentTarget;
    const viewDataArray = [...tableState]; // 뷰테이블 로우 어레이
    const rawDataArray: any = [...rawData]; // 데이터테이블 로우 어레이

    let findIndex;
    if (el.index === undefined) {
      // 새로운 로우가 아닐때, 데이터테이블에서 해당하는 로우의 인덱스값 찾기
      findIndex = rawData.findIndex((rawEl: IComCdList) => rawEl.subCd === el.subCd);
    } else {
      // 새로운 로우일때 인덱스값
      findIndex = el.index;
    }

    // 신규로우 수정하는 경우
    viewDataArray[i] = { ...viewDataArray[i], [name]: value, flag: FLAG_CREATE_OR_UPDATE }; // 뷰테이블 로우 업데이트
    if (findIndex !== -1) {
      // 데이터테이블 로우 업데이트
      rawDataArray[findIndex] = { ...rawDataArray[findIndex], [name]: value, flag: FLAG_CREATE_OR_UPDATE, editor: userInfo.userId };
    }

    setTableState(viewDataArray);
    setRawData(rawDataArray);
  };

  // 서브 뷰테이블 값이 수정될때
  const onChangeSubTableState = (e: React.ChangeEvent<HTMLInputElement>, i: number, el: any) => {
    const { name, value } = e.currentTarget;
    const viewDataArray: any = [...subTableState]; // 뷰테이블 로우 어레이
    const rawDataArray: any = [...subRawData]; // 데이터테이블 로우 어레이
    let findIndex;

    if (el.index === undefined) {
      // 새로운 로우가 아닐때, 데이터테이블에서 해당하는 로우의 인덱스값 찾기
      findIndex = subRawData.findIndex((rawEl: IComCdList) => rawEl.subCd === el.subCd); // 데이터테이블에서 해당하는 로우의 인덱스값
    } else {
      findIndex = el.index; // 새로운 로우일때 인덱스값
    }

    // 그룹코드 외 다른항목을 수정한 경우 또는 신규로우의 그룹코드를 수정하는 경우
    viewDataArray[i] = { ...viewDataArray[i], [name]: value, flag: FLAG_CREATE_OR_UPDATE }; // 뷰테이블 로우 업데이트
    setSubTableState(viewDataArray);

    if (findIndex !== -1) {
      // 데이터테이블 로우 업데이트
      rawDataArray[findIndex] = { ...rawDataArray[findIndex], [name]: value, flag: FLAG_CREATE_OR_UPDATE, editor: userInfo.userId };
    }
    setSubRawData(rawDataArray);
  };

  const deleteCodeAPI = async (el: any) => {
    const req = {
      grCd: el.grCd,
      subCd: el.subCd,
      editor: el.editor,
    };
    const res = await apiPost({ path: '/code/normalDelete', req });
    const { message, statusCode } = res.data;
    if (statusCode === 200) {
      setShowSubTable({ status: false, subCd: '', cdName: '' });
      setOpenModal((prev) => ({ ...prev, status: false }));
    } else {
      // toast.error(t(ERROR));
    }
  };

  const deletesubCodeAPI = async (el: any) => {
    const req = {
      grCd: el.grCd,
      subCd: el.subCd,
      editor: el.editor,
    };
    const res = await apiPost({ path: '/code/normalDelete', req });
    const { message, statusCode } = res.data;
    if (statusCode === 200) {
      setOpenModal((prev) => ({ ...prev, status: false }));
    } else {
      // toast.error(t(ERROR));
    }
  };

  const decreaseArrayNo = (array: IComCdList[], dataLength: number, setState: Dispatch<SetStateAction<IComCdList[]>>) => {
    let increase = 0;

    const newArray = array.map((el, i) => {
      if (el.index !== undefined) {
        increase += 1;
        const copyArray = [...array];
        copyArray[i] = {
          ...copyArray[i],
          no: dataLength + increase,
        };
        return copyArray[i];
      }
      return el;
    });
    setState(newArray);
  };

  const onClickDelete = (el: IComCdList, i: number) => {
    // 새로 추가한 로우 제거 클릭 시
    if (el.index !== undefined) {
      // 뷰테이블 로우 삭제
      const updatedArray = [...tableState];
      updatedArray.splice(i, 1);

      decreaseArrayNo(updatedArray, rawDataLength, setTableState);

      // 데이터테이블 로우 삭제 (플래그 D로 업데이트)
      const findIndex = rawData.findIndex((rawEl: IComCdList) => rawEl.index === el.index);
      const rawDataArray: any = [...rawData];
      if (findIndex !== -1) {
        rawDataArray[findIndex] = {
          ...rawDataArray[findIndex],
          flag: FLAG_DELETE,
        };
        setRawData(rawDataArray);
      }
    } else {
      // 삭제 시 에디터아이디 추가
      const data = {
        ...el,
        editor: userInfo.userId,
      };
      setOpenModal((prev) => ({ ...prev, status: true, state: tableState, setState: setTableState, api: deleteCodeAPI, el: data, index: i }));
    }
    setIsTableInitialRender(true); // 삭제 클릭시 테이블길이 변화인한 스크롤하단 이동방지
  };

  // 서브로우 삭제 클릭
  const onClickSubDelete = (el: any, i: number) => {
    // 새로 추가한 로우 제거 클릭 시
    if (el.index !== undefined) {
      // 뷰테이블 로우 삭제
      const updatedArray = [...subTableState];
      updatedArray.splice(i, 1);

      decreaseArrayNo(updatedArray, subRawDataLength, setSubTableState);

      // setSubTableState(updatedArray);
      // 데이터테이블 로우 삭제 (플래그 D로 업데이트)
      const findIndex = subRawData.findIndex((rawEl: IComCdList) => rawEl.index === el.index);
      const rawDataArray: any = [...subRawData];
      if (findIndex !== -1) {
        rawDataArray[findIndex] = {
          ...rawDataArray[findIndex],
          flag: FLAG_DELETE,
        };
        setSubRawData(rawDataArray);
      }
    } else {
      // 삭제 시 에디터아이디 추가
      const data = {
        ...el,
        editor: userInfo.userId,
      };
      setOpenModal((prev) => ({ ...prev, status: true, state: subTableState, setState: setSubTableState, api: deletesubCodeAPI, el: data, index: i }));
    }
    setIsSubTableInitialRender(true); // 삭제 클릭시 테이블길이 변화인한 스크롤하단 이동방지
  };

  // 공종/직종시 두번째 테이블 리스트 요청
  const getSubTableDataAPI = async (subCd: string) => {
    const req = { grCd: subCd };
    const res = await apiGet({ path: '/code/normalSub', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const copyArray = [...data.normalCdList];
      // Add an index property to each object in the array
      copyArray.forEach((obj: IComCdList, index: number) => {
        obj.no = index + 1; // Adding 1 to start index from 1
      });

      setSubRawData(copyArray);
      setSubTableState(data.normalCdList);
      setNewSubRowIndex(data.normalCdList.length);
      setSubRawDataLength(data.normalCdList.length);
      setShowSubTable((prev) => ({ ...prev, status: true }));
    } else {
      // toast.error(t(ERROR));
    }
  };

  const onClickOpenSubTable = (el: any) => {
    // 공종/직종일  때만 서브테이블 오픈
    if (normalCdTitle.etc1 === SUB_TABLE_EXIST) {
      setShowSubTable((prev) => ({ ...prev, subCd: el.subCd, cdName: el.cdName }));
      getSubTableDataAPI(el.subCd);
    }
  };

  const saveNormalCdArrayAPI = async (normalCdArray: any) => {
    if (normalCdArray.length > 0) {
      const req = {
        normalCdReqDto: trimArray(normalCdArray),
      };
      const res = await apiPost({ path: '/code/normal', req });
      const { message, statusCode, data } = res.data;
      if (statusCode === 200) {
        toast.success(t(message));

        const copyArray = [...data.normalCdList];
        // Add an index property to each object in the array
        copyArray.forEach((obj: IComCdList, index: number) => {
          obj.no = index + 1; // Adding 1 to start index from 1
        });

        setTableState(copyArray);
        setRawData(data.normalCdList);
        setNewRowIndex(data.normalCdList.length);

        const findIndex = normalCdArray.findIndex((el: any) => el.subCd === showSubTable.subCd);
        if (findIndex !== -1) {
          setShowSubTable((prev) => ({ ...prev, subCd: normalCdArray[findIndex].subCd, cdName: normalCdArray[findIndex].cdName })); //  저장 성공시 마지막클릭한 로우 기준으로 코드항목테이블 항목명변경
        }
      } else {
        // toast.error(t(ERROR));
      }
    }
  };

  const saveSubNormalCdArrayAPI = async (normalCdArray: any) => {
    if (normalCdArray.length > 0) {
      const req = {
        normalCdReqDto: trimArray(normalCdArray),
      };
      const res = await apiPost({ path: '/code/normal', req });
      const { message, statusCode, data } = res.data;
      if (statusCode === 200) {
        toast.success(t(message));

        const copyArray = [...data.normalCdList];
        // Add an index property to each object in the array
        copyArray.forEach((obj: IComCdList, index: number) => {
          obj.no = index + 1; // Adding 1 to start index from 1
        });

        setSubTableState(copyArray);
        setSubRawData(data.normalCdList);
        setNewRowIndex(data.normalCdList.length);
      } else {
        // toast.error(t(ERROR));
      }
    }
  };

  const onClickSaveTableState = () => {
    setIsSaveClicked(true);
    if (normalCdTitle.subCd !== '') {
      const newRowRequired = ['cdName'];

      const emptyCheck = rawData.filter((el: any) => {
        const check = newRowRequired.find((el2: any) => el[el2] === '' && el.flag === FLAG_CREATE_OR_UPDATE);
        return check;
      });

      if (emptyCheck.length > 0) {
        toast.warning(t('필수입력값을 모두 입력하세요'));
      } else {
        // 빈값이 없는 경우 수정,업데이트된 로우들만 서버에 전달
        const dbSendRows = rawData.filter((el: any) => el.flag === FLAG_CREATE_OR_UPDATE);
        const newArray = dbSendRows.map(({ index, no, wDate, eDate, ...rest }) => rest);

        saveNormalCdArrayAPI(newArray);
      }
    }
  };

  const onClickSaveSubTableState = () => {
    setIsSave2Clicked(true);
    if (subRawData.length > 0) {
      const newRowRequired = ['cdName'];
      const emptyCheck = subRawData.filter((el: any) => {
        const check = newRowRequired.find((el2) => el[el2] === '' && el.flag === FLAG_CREATE_OR_UPDATE);
        return check;
      });
      if (subRawData[0].grCd.length === 1) {
        toast.warning(t('입력하신 타이틀 저장 후 상세항목을 입력하세요'));
      } else if (emptyCheck.length > 0) {
        toast.warning(t('필수입력값을 모두 입력하세요'));
      } else {
        const dbSendRows = subRawData.filter((el) => el.flag === FLAG_CREATE_OR_UPDATE);
        const newArray = dbSendRows.map(({ index, wDate, eDate, no, ...rest }) => rest);
        saveSubNormalCdArrayAPI(newArray);
      }
    }
  };

  useEffect(() => {
    getNormalCodeAPI().then((res: any) => {
      if (res.status === 200) {
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '현장 일반코드 관리',
          action: '조회',
          etc: ``,
        });
      }
    });
    getNormalCdTitleAPI('A');
  }, []);
  const onClickTab = (mrCd: string) => {
    setCurrentTabMrCd(mrCd);
  };
  return (
    <div className='contents'>
      <Tab tabList={tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      <div className={`${showSubTable.status ? 'content-container twoColumn column-55' : 'content-container oneColumn'}`}>
        {(size.innerSize.W >= 1024 || (size.innerSize.W < 1024 && !showSubTable.status)) && (
          <Root>
            <div className='inputFormsWrapper smallTab'>
              {comCdList?.map((el) => (
                <div key={v1()} className={`tab ${normalCdTitle.subCd === el.subCd ? 'activeTab' : undefined}`} role='button' tabIndex={0} onClick={() => onClickNormalCdBtn(el)}>
                  {t(el.cdName)}
                </div>
              ))}
            </div>
            <InputTable className='margin-left-05'>
              <div ref={scrollContainerRef}>
                <div className='thead'>
                  <div className='tr'>
                    <div className='trCol2p5 flex-center tableStickyNo'>No</div>
                    <div className='trCol3 flex-center'>{t('코드')}</div>
                    <div className='trCol12 flex-center tableStickyTitle content-overflow'>{t('항목 명')}</div>
                    <div className='trCol12 flex-center '>{t('수정일자')}</div>
                    <div className='trCol6 flex-center'>{t('수정자')}</div>
                    <div className='trCol6'> </div>
                  </div>
                </div>
                <div className='tbody'>
                  {tableState?.length > 0 ? (
                    tableState?.map((el, i) => (
                      <div className='tr' key={`sub${el.subCd}_${i}`} role='button' tabIndex={0} onClick={() => onClickOpenSubTable(el)}>
                        <div className='trCol2p5 flex-center tableStickyNo'>{el.no}</div>
                        <div className='trCol3 flex-center'>{el.index === undefined ? el.subCd : ''}</div>
                        <div className='trCol12 flex-center tableStickyTitle content-overflow'>
                          <input
                            type='text'
                            value={el.cdName}
                            id='cdName'
                            name='cdName'
                            onChange={(e) => onChangeTableState(e, i, el)}
                            style={isSaveClicked ? applyBorderStyle(el.cdName, 'red', 'cdName') : undefined}
                            onClick={(event) => {
                              if (size.innerSize.W < 1024) {
                                event.stopPropagation();
                              }
                            }}
                            maxLength={50}
                          />
                        </div>
                        <div className='trCol12 flex-center '>{el.eDate}</div>
                        <div className='trCol6 flex-center'>{el.index === undefined ? el.editor : ''}</div>
                        <div
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          role='presentation'
                          className='trCol6 buttonWrapper flex-center'
                        >
                          <BtnRed
                            onClick={(event) => {
                              if (size.innerSize.W < 1024) {
                                event.stopPropagation();
                              }
                              onClickDelete(el, i);
                            }}
                          >
                            {el.index === undefined ? t('삭제') : t('제거')}
                          </BtnRed>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className='noData'>No data.</div>
                  )}
                </div>
              </div>
              {/* </div> */}
            </InputTable>
            <ButtonsWrapper>
              <BtnBlue onClick={onClickAddTableRow}>{t('신규항목 추가')}</BtnBlue>
              <BtnBlue onClick={onClickSaveTableState}>{t('저장')}</BtnBlue>
            </ButtonsWrapper>
          </Root>
        )}
        {showSubTable.status && (
          <Root>
            <div className='inputFormsWrapper flexRowEm'>
              {size.innerSize.W < 1024 && <BackButton func={() => backToMain()} />}
              <div className='formTitle titleDetail'>
                {t('코드')}
                <span>{showSubTable.subCd}</span>• {t('항목 명')} <span> {t(showSubTable.cdName)}</span>
              </div>
            </div>
            {normalCdTitle.etc1 === SUB_TABLE_EXIST && (
              <InputTable className='margin-left-05'>
                <div ref={scrollSubContainerRef}>
                  <div className='thead'>
                    <div className='tr'>
                      <div className='trCol2p5 flex-center tableStickyNo'>No</div>
                      <div className='trCol3 flex-center'>{t('코드')}</div>
                      <div className='trCol12 flex-center content-overflow  tableStickyTitle'>{t('항목 명')}</div>
                      <div className='trCol12 flex-center'>{t('수정일자')}</div>
                      <div className='trCol6 flex-center'>{t('수정자')}</div>
                      <div className='trCol6'> </div>
                    </div>
                  </div>
                  <div className='tbody'>
                    {showSubTable.status && subTableState?.length > 0 ? (
                      subTableState?.map((el, i) => (
                        <div className='tr' key={`sub${el.subCd}_${i}`} role='button' tabIndex={0}>
                          <div className='trCol2p5 flex-center tableStickyNo'>{el.no}</div>
                          <div className='trCol3 flex-center'>{el.index === undefined ? el.subCd : ''}</div>
                          <div className='trCol12 flex-center content-overflow tableStickyTitle'>
                            <input
                              type='text'
                              value={el.cdName}
                              id='cdName'
                              name='cdName'
                              onChange={(e) => onChangeSubTableState(e, i, el)}
                              style={isSave2Clicked ? applyBorderStyle(el.cdName, 'red', 'cdName') : undefined}
                              maxLength={50}
                            />
                          </div>
                          <div className='trCol12 flex-center'>{el.eDate}</div>
                          <div className='trCol6 flex-center'>{el.index === undefined ? el.editor : ''}</div>
                          <div className='trCol6 flex-center buttonWrapper'>
                            <BtnRed onClick={() => onClickSubDelete(el, i)}>{el.index === undefined ? t('삭제') : t('제거')}</BtnRed>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className='noData'>No data.</div>
                    )}
                  </div>
                </div>
              </InputTable>
            )}
            <ButtonsWrapper>
              <BtnBlue onClick={onClickAddSubTableRow}>{t('신규항목 추가')}</BtnBlue>
              <BtnBlue onClick={onClickSaveSubTableState}>{t('저장')}</BtnBlue>
            </ButtonsWrapper>
          </Root>
        )}
        <Portal openModal={openModal?.status}>{openModal && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
      </div>
    </div>
  );
};

export default Master3;
