/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.15
 * 경로 : 대시보드 > 터널 종합 진행 현황
 */

import styled from 'styled-components';

const TunnelChipContainer = styled.div`
  text-align: center;
  .tunnelChip {
    cursor: pointer;
    min-width: 4rem;
    user-select: none;
    height: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    overflow: hidden;
    background-color: ${({ theme }: { theme: any }) => theme.board};
    border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    .material-symbols-rounded {
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
      font-size: 1.125rem;
      width: 2.5rem;
      border-right: 1px solid ${({ theme }: { theme: any }) => theme.outline_em};
    }
    .chipValue {
      flex-grow: 1;
      font-size: 0.875rem;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    }
    &:hover {
      box-shadow: 0px 4px 8px 0 rgba(0, 0, 0, 0.15);
      border: 1px solid ${({ theme }: { theme: any }) => theme.outline_em};
    }
    &:active {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
    }
  }
  &.small {
    .tunnelChip {
      height: 2rem;
      min-width: fit-content;
      /* padding: 0 0.25rem; */
      .material-symbols-rounded {
        font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
        font-size: 0.875rem;
        width: 1.5rem;
      }
      .chipValue {
        font-size: 0.75rem;
        /* min-width: 2.5rem; */
        padding: 0 0.25rem;
      }
    }
  }
  &.x-small {
    .tunnelChip {
      height: 2rem;
      min-width: fit-content;
      border-radius: 1rem;
      padding: 0 0.25rem;
      .chipValue {
        min-width: 2.5rem;
        padding: 0 0.5rem;
      }
    }
  }
  &.disabled {
    .tunnelChip {
      cursor: inherit;
      border: none;
      background-color: transparent;
      box-shadow: none;
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      .material-symbols-rounded {
        color: ${({ theme }: { theme: any }) => theme.text_disabled};
        color: ${({ theme }: { theme: any }) => theme.outline_em};
      }
      .chipValue {
        color: ${({ theme }: { theme: any }) => theme.outline_em};
      }
    }
  }
  &.dashboard {
  }
`;

type ChipData = {
  value: string | number;
  icon: string;
  disabled?: boolean;
  size?: 'small' | 'x-small' | '';
  dashboard?: boolean;
  onClick?: any;
};

const TunnelChipView = ({ value, icon, disabled = false, size = '', dashboard = false, onClick }: ChipData) => {
  return (
    <TunnelChipContainer className={`${disabled ? 'disabled' : ''} ${size ?? ''} ${dashboard ? 'dashboard' : ''}`}>
      <div className='tunnelChip' onClick={onClick} role='presentation'>
        <span className='material-symbols-rounded'>{icon}</span>
        <div className={`chipValue ${Number(value) >= 3 && 'high'} ${value === '○' && 'ok'}`}>{value}</div>
      </div>
    </TunnelChipContainer>
  );
};

export default TunnelChipView;
