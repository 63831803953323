import { IAuth } from 'customTypes';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../translation/i18n';
import { useQuery } from '@tanstack/react-query';
import { PulseLoader } from 'react-spinners';
import { BtnGhost } from '../Button';
import Slider from 'react-slick';
import Portal from '../Portal';
import EnrollmentBlackWorkerModal from '../Modal/EnrollmentBlackWorkerModal';
import SafePointModal from '../Modal/safePointModal';
import InfoBlackWorkerModal from '../Modal/InfoBlackWorkerModal';
import { apiGet } from '../../services/_common';

interface IBlack {
  bSeq: number;
  wCd: string;
  wName: string;
  sjCd: string;
  sjName: string;
  bMemo: string;
  wImg: string;
}

interface IPoint {
  sSeq: number;
  sPointseq: number;
  wCd: string;
  wName: string;
  sjCd: string;
  sjName: string;
  sMemo: string;
  pointCount: string;
  wImg: string;
}

// 안전 위반 현황 상세
export const SafetyViolationInfoDetail = ({ name, userInfo, openModal, setOpenModal }: any) => {
  const { t } = useTranslation();
  const settings = {
    dots: false,
    dotsClass: 'slick-dots slick-thumb',
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 60000,
    beforeChange: (current: number, next: number) => setSliderTabIndex(next),
  };

  const [dashboardAuth, setDashboardAuth] = useState<IAuth>({ createAuth: false, updateAuth: false, deleteAuth: false, excelAuth: false, printAuth: false });
  const yesterday = dayjs().subtract(1, 'day');
  let format = yesterday.format(`MM월 DD일 dddd`);
  if (i18n.language === 'en-US') {
    format = yesterday.locale('en').format(`MMMM D dddd`);
  } else {
    format = yesterday.locale('ko').format(`MM월 DD일 dddd`);
  }
  const [blackList, setBlackList] = useState<IBlack[]>([]);
  const [pointList, setPointList] = useState<IPoint[]>([]);
  const [sliderTabIndex, setSliderTabIndex] = useState<number>(0);
  const sliderArray = [0, 1]; // 0: 안전지적, 1: 출입금지
  // const [openModal, setOpenModal] = useState<any>({ status: false, type: '', code: { wCd: '', bSeq: '' } });

  useEffect(() => {
    if (userInfo.userMenuList) {
      if (userInfo.gCd === 'MM') setDashboardAuth({ createAuth: true, updateAuth: true, deleteAuth: true, printAuth: true, excelAuth: true });
      else {
        const menuAuth = userInfo.userMenuList.find((el: any) => el.mtCd === 'S004' && el.mdCd === '002' && el.mrCd === '001');
        if (menuAuth !== undefined) {
          setDashboardAuth({
            createAuth: menuAuth.cYn === 'Y',
            updateAuth: menuAuth.uYn === 'Y',
            deleteAuth: menuAuth.dYn === 'Y',
            excelAuth: menuAuth.eYn === 'Y',
            printAuth: menuAuth.pYn === 'Y',
          });
        }
      }
    }
  }, [userInfo.userMenuList]);

  const safePointQuery = useQuery(['safePointcontentsGet', userInfo], () => apiGet({ path: '/safe/pointcontents', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }), {
    enabled: userInfo.sCd !== '00000',
  });
  const safeBlackQuery = useQuery(['safeBlackcontentsGet', userInfo], () => apiGet({ path: '/safe/blackcontents', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }), {
    enabled: userInfo.sCd !== '00000',
  });

  useEffect(() => {
    if (safePointQuery.isSuccess && safePointQuery.data && safePointQuery.data.data.statusCode === 200) {
      setPointList(safePointQuery.data.data.data);
    }
  }, [safePointQuery.isSuccess, safePointQuery.isRefetching]);

  useEffect(() => {
    if (safeBlackQuery.isSuccess && safeBlackQuery.data && safeBlackQuery.data.data.statusCode === 200) {
      setBlackList(safeBlackQuery.data.data.data);
    }
  }, [safeBlackQuery.isSuccess, safeBlackQuery.isRefetching]);

  const onClickNewAdd = (tabIndexParam: number) => {
    if (tabIndexParam === 0) {
      setOpenModal({ status: true, type: 'safePoint', code: { sSeq: 0 } }); // 신규 등록시 순번0
    } else {
      setOpenModal({ status: true, type: 'enrollmentBlackWorker' });
    }
  };

  // 출입금지 근로자 정보 리스트 조회 API
  const getWorkerAPI = async () => {
    safeBlackQuery.refetch();
  };

  // 안전지적 정보 리스트 조회 API
  const getSafePointtAPI = async () => {
    safePointQuery.refetch();
  };

  const onClickViewPointWorker = (sSeqParam: any) => {
    setOpenModal({ status: true, type: 'safePoint', code: { sSeq: sSeqParam } });
  };

  const onClickViewBlackWorker = (bSeqParam: any, wCdParam: string) => {
    setOpenModal({ status: true, type: 'infoBlackWorker', code: { bSeq: bSeqParam, wCd: wCdParam, writer: userInfo.userId } });
  };

  const onClickRefresh = () => {
    safePointQuery.refetch();
    safeBlackQuery.refetch();
  };

  if (
    !safePointQuery.isSuccess ||
    safePointQuery.data.status !== 200 ||
    safePointQuery.isRefetching ||
    !safeBlackQuery.isSuccess ||
    safeBlackQuery.data.status !== 200 ||
    safeBlackQuery.isRefetching
  ) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <div className='safetyViolationInfoDetail'>
      <div className='widget-header safety'>
        <div className='widget-title flex-between innerBtn'>
          <span>{t(name)}</span>
          <div className='widget-btn-group'>
            {dashboardAuth.createAuth && (
              <BtnGhost onClick={() => onClickNewAdd(sliderTabIndex)}>
                <span className='material-symbols-rounded'>add</span>
                {t('등록')}
              </BtnGhost>
            )}
            <div className='refresh-btn' onClick={onClickRefresh} role='presentation'>
              <span className='material-symbols-rounded'>refresh</span>
            </div>
          </div>
        </div>
        <div className='widget-subTitle'>
          {format} ({t('전일')})
        </div>
      </div>
      <div className='widget-body photoTable'>
        <div className='innerTab'>
          <div className={sliderTabIndex === 0 ? 'active' : undefined} role='presentation' onClick={() => setSliderTabIndex(0)}>
            {t('안전 지적')} ({pointList.length})<div />
          </div>
          <div className={sliderTabIndex === 1 ? 'active' : undefined} role='presentation' onClick={() => setSliderTabIndex(1)}>
            {t('출입 금지')} ({blackList.length})<div />
          </div>
        </div>
        <div className='sliderWrapper'>
          <Slider {...settings}>
            {sliderArray.map((el: number) => (
              <div key={el} className='text-container'>
                {sliderTabIndex === 0
                  ? pointList.map((pEl: IPoint) => (
                      <div key={`${pEl.sSeq}_${pEl.sPointseq}`} className='rowWrapper flex-basic' role='presentation' onClick={() => onClickViewPointWorker(pEl.sSeq)}>
                        <div className='flex-basic'>
                          <div className='imgWrapper'>{pEl.wImg ? <img src={pEl.wImg} alt='' /> : <span className='material-symbols-rounded'>person </span>}</div>
                          <div className={sliderTabIndex === 0 ? 'safetyContent' : ''}>
                            <div>
                              <span className='' style={{ paddingRight: '0.5rem' }}>
                                {pEl.wName}
                              </span>
                              <span className=''>{pEl.sjName}</span>
                            </div>
                            <div className='rowContent'>{pEl.sMemo}</div>
                          </div>
                        </div>
                        <div className='buttonsWrapper flex-basic'>
                          <button type='button' className={Number(pEl.pointCount) === 1 ? 'amber' : 'red'}>
                            <span>{pEl.pointCount}</span>
                            {t('건')}
                          </button>
                        </div>
                      </div>
                    ))
                  : blackList.map((bEl: IBlack) => (
                      <div key={`${bEl.bSeq}`} className='rowWrapper flex-between' role='presentation' onClick={() => onClickViewBlackWorker(bEl.bSeq, bEl.wCd)}>
                        <div className='flex-basic'>
                          <div className='imgWrapper'>{bEl.wImg ? <img src={bEl.wImg} alt='' /> : <span className='material-symbols-rounded'>person </span>}</div>
                          <div className={`rowContent ${sliderTabIndex === 1 ? 'blockContent' : ''}`}>
                            <div>
                              <span className='' style={{ paddingRight: '0.5rem' }}>
                                {bEl.wName}
                              </span>
                              <span className=''>{bEl.sjName}</span>
                            </div>
                            <div className='rowContent'>{bEl.bMemo}</div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <Portal openModal={openModal?.status}>
        {openModal.status && openModal.type === 'enrollmentBlackWorker' && (
          <EnrollmentBlackWorkerModal getReportListAPI={getWorkerAPI} setOpenModal={setOpenModal} auth={dashboardAuth} code={{ hCd: userInfo.hCd, sCd: userInfo.sCd }} dashboard />
        )}
        {openModal.status && openModal.type === 'safePoint' && (
          <SafePointModal getReportListAPI={getSafePointtAPI} setOpenModal={setOpenModal} auth={dashboardAuth} code={{ hCd: userInfo.hCd, sCd: userInfo.sCd, sSeq: openModal.code.sSeq }} dashboard />
        )}
        {openModal.status && openModal.type === 'infoBlackWorker' && openModal.code && (
          <InfoBlackWorkerModal
            getReportListAPI={getWorkerAPI}
            setOpenModal={setOpenModal}
            auth={dashboardAuth}
            code={{ hCd: userInfo.hCd, sCd: userInfo.sCd, wCd: openModal.code.wCd, bSeq: openModal.code.bSeq, writer: openModal.code.writer }}
          />
        )}
      </Portal>
    </div>
  );
};
