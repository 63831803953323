/**
 * 작성자 : 김광범
 * 날짜 : 2024.06.20
 * 기능 : 도메인 정보 추출
 *        portal : 전체조회, itlogtest : 아이티로그, 그외 sub domain 사용
 */

export const getSubdomain = (hostname: string) => {
  const domainParts = hostname.split('.');
  // 도메인이 적어도 세 부분으로 나뉘어져 있는지 확인 (예: sub.example.com)
  if (domainParts.length > 2) {
    return domainParts[0] === 'portal' ? '' : domainParts.slice(0, -2).join('.');
  }

  // 서브 도메인이 없는 경우
  return '';
};
