/**
 * 작성자 : 홍선영
 * 날짜 : 2023.06.27
 * 기능 : 개인 출역 모달
 */

import { useState, useEffect, Dispatch, SetStateAction } from 'react';

import { IAuth, IModal } from 'customTypes';
import { ERROR, FLAG_CREATE_OR_UPDATE, FLAG_NONE, hourList2, minList2 } from '../../_constants';
import { Modal, ModalBackground } from '../../assets/styles/Modal';
import { BtnBlue, BtnGray, BtnRed } from '../Button';

import DatePickerComponent from '../DatePicker';
import { useRecoilValue } from 'recoil';
import { userState } from '../../atoms';
import { trimObject } from '../../utils/trimObject';
import Portal from '../Portal';
import DeleteModal from './DeleteModal2';
import { InputTable } from '../../assets/styles/InputTable';
import { todayYYYYMMDD } from '../../utils/formatDate';
import ReactDatePicker from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import { arraySortByAscdOrder } from '../../utils/arraySortByAscdOrder';
import SearchSelectBoxs from '../SearchSelectBoxs';
import ReactSelect from '../ReactSelect';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { apiDelete, apiGet } from '../../services/_common';

interface ICode {
  hCd: string;
  sCd: string;
  wCd: string;
  bSeq: string;
  writer: string;
}

interface IProps {
  setOpenModal: Dispatch<SetStateAction<IModal>>;
  auth: IAuth;
  code: ICode;
}

interface IAttend {
  attendIdx: string;
  aDate: string;
  sjCd: string;
}

const InfoAttendModal = ({ setOpenModal, auth, code }: IProps) => {
  const { t } = useTranslation();
  const toDay = todayYYYYMMDD();
  const userInfo = useRecoilValue(userState);
  const initialAttend = {
    attendIdx: '',
    aDate: '',
    sjCd: '',
  };
  const [workerInputSet, setWorkerInputSet] = useState<IAttend>(initialAttend);
  const [searchOption, setSearchOption] = useState<any>();
  const [openModal2, setOpenModal2] = useState<IModal>({ status: false, type: '' });
  const [isViewMode, setIsViewMode] = useState(!auth.createAuth && !auth.updateAuth); // 권한에 따른 뷰 or 수정모드 여부
  const [aDate, setADate] = useState(toDay);
  const [workerAttendList, setWorkerAttendList] = useState<any[]>([]);
  const [sjCdList, setSjCdList] = useState([]);

  useEffect(() => {
    getSiteJoinList();
  }, []);

  useEffect(() => {
    setSearchOption((prev: any) => ({ ...prev, aDate }));
  }, [aDate]);

  const getWorkerAttendList = async () => {
    const req = { hCd: code.hCd, sCd: code.sCd, wCd: code.wCd, aDate1: aDate, aDate2: aDate };
    const res = await apiGet({ path: '/worker/attend', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      // min, hour, sec 값을 잘라서 객체안에 추가
      const newArray = data.workerAttend.map((el: any) => ({
        ...el,
        hour: el.aDate.substring(8, 10),
        min: el.aDate.substring(10, 12),
        sec: el.aDate.substring(12, 14),
        aDateF: formatDate(el.aDate),
        flag: FLAG_NONE,
      }));
      const sortedArray: any = arraySortByAscdOrder(newArray, 'attendIdx');
      setWorkerAttendList(sortedArray);
    } else {
      // toast.error(t(ERROR));
    }
  };

  const getSiteJoinList = async () => {
    const req = { hCd: code.hCd, sCd: code.sCd };
    const res = await apiGet({ path: '/siteJoin', req });

    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      if (data.siteJoinList.length > 0) {
        const useYList = data.siteJoinList.filter((el: any) => el.useYn === 'Y');
        setSjCdList(
          useYList.map((siteJoin: any) => {
            return { ...siteJoin, type: 'sjCd' };
          })
        );
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  const saveBlackWorkerInfoAPI = async (dataParam: IAttend) => {
    // const { sjName, sjCd, wJobdate, wName, ...rest } = dataParam;
    // const reqdata = { ...rest, hCd: code.hCd, sCd: code.sCd, wCd: code.wCd, bDate: bDate.replaceAll('-', ''), writer: code.writer, editor: userInfo.userId };
    // const res = await workerBlackinfoPost([reqdata]);
    // const { statusCode, data, message } = res.data;
    // if (statusCode === 200) {
    //   setWorkerInputSet(dataParam);
    //   window.location.reload();
    // }
    // alert(message);
  };

  // Format a string of form "YYYYMMDDHHMMSS" into a JavaScript Date object
  const formatDate = (str: any) => {
    if (!str || str.length !== 14) return null;
    const year = parseInt(str.slice(0, 4), 10);
    const month = parseInt(str.slice(4, 6), 10) - 1; // JavaScript months are 0-indexed
    const day = parseInt(str.slice(6, 8), 10);
    const hours = parseInt(str.slice(8, 10), 10);
    const minutes = parseInt(str.slice(10, 12), 10);
    const seconds = parseInt(str.slice(12, 14), 10);
    return new Date(year, month, day, hours, minutes, seconds);
  };

  // 닫기버튼 클릭
  const onClickClose = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };

  // const deleteAPI = async () => {
  //   const res = await workerBlackinfoDelete({ hCd: code.hCd, sCd: code.sCd, bSeq: code.bSeq, editor: userInfo.userId });
  //   const { statusCode, message } = res.data;
  //   if (statusCode === 200) {
  //     onClickClose();
  //     window.location.reload(); // TODO 테이블데이터 변경으로 수정
  //   } else alert(message);
  // };

  const onClickSaveBlackWorkerInput = () => {
    // if (workerInputSet.bDate?.trim() === '' || workerInputSet.bMemo?.trim() === '') {
    //   return alert('필수입력값을 모두 입력하세요');
    // }
    const trimData = trimObject(workerInputSet);
    return saveBlackWorkerInfoAPI(trimData);
  };

  const onClickDeleteBlackWorker = () => {
    setOpenModal2((prev) => ({ ...prev, status: true, type: 'delete', api: deleteAPI }));
  };

  const handleDateChange = (date: any, index: number) => {
    const newData = [...workerAttendList];
    newData[index].aDateF = date;
    newData[index].flag = FLAG_CREATE_OR_UPDATE;
    setWorkerAttendList(newData);
  };

  useEffect(() => {}, [workerAttendList]);

  const onClickSearchAttend = () => {
    getWorkerAttendList();
  };

  const defaultSjCd = (sjCd: string) => {
    const findObject: any = sjCdList.find((el2: any) => el2.sjCd === sjCd);
    if (findObject !== undefined) {
      return findObject.sjName;
    }
    return t('선택');
  };

  const deleteAPI = async (req: any, i: number) => {
    if (req.attendIdx !== 0) {
      const res = await apiDelete({ path: '/worker/attend', req });
      const { statusCode, data } = res.data;

      if (statusCode === 200) {
        setOpenModal((prev) => ({ ...prev, status: false }));
        const copyArray = [...workerAttendList];
        const newArray = copyArray.filter((v: any, index: number) => index !== i);
        setWorkerAttendList(newArray);
      } else {
        // toast.error(t(ERROR));
      }
    }
  };

  const onClickDeleteWorkerAttendRow = (el: any, i: number) => {
    const tableArray = [...workerAttendList];
    if (el.attendIdx !== 0) {
      const { attendIdx } = el;
      // 기존 로우를 삭제 할 때
      const data = { attendIdx, hCd: code.hCd, sCd: code.sCd, wCd: code.wCd };
      setOpenModal((prev: any) => ({ ...prev, status: true, type: 'delete', tableArray, setWorkerAttendList, api: () => deleteAPI(data, i), el: data, index: i }));
    } else {
      // 새로 추가한 로우를 삭제할 때
      tableArray.splice(i, 1);
      setWorkerAttendList(tableArray);
    }
  };

  useEffect(() => {}, [workerAttendList]);

  return (
    <ModalBackground>
      <Modal minWidth={100}>
        <div className='modal'>
          <div className='inputForm-head'>
            <h5>{t('개인 출역 수정')}</h5>
          </div>
          <div className={`inputForm-body ${isViewMode ? 'view' : undefined}`}>
            <div className='flex-basic searchWrapper'>
              <div className='flex-basic'>
                <label htmlFor='date'>
                  <span>
                    {t('이름')} ({code?.wCd}) {t('출역일자')}
                  </span>
                </label>
                <div className='flex-basic col-w60 datePickerWrapper'>
                  <DatePickerComponent startDate={aDate} setDate={setADate} />
                </div>
              </div>
              <BtnBlue onClick={onClickSearchAttend}>{t('검색')}</BtnBlue>
            </div>
            <div>
              <InputTable>
                <div className='thead'>
                  <div className='tr'>
                    <div className='trCol5'>{t('순번')}</div>
                    <div className={auth.deleteAuth ? 'trCol20' : 'trCol30'}>{t('협력업체')}</div>
                    <div className='trCol10'>{t('근로자명')}</div>
                    <div className='trCol15'>{t('출역일자')}</div>
                    <div className='trCol40'>{t('출역시간')}</div>
                    {auth.deleteAuth && <div className='trCol10'> </div>}
                  </div>
                </div>
                <div className='table' style={{ height: '52rem' }}>
                  <div className='tbody'>
                    {workerAttendList.map((el: any, i: number) => (
                      <div className='tr' key={i}>
                        <div className='trCol5'>{i + 1}</div>
                        <div className={auth.deleteAuth ? 'trCol20' : 'trCol30'}>
                          <SearchSelectBoxs
                            options={sjCdList}
                            defaultOption={defaultSjCd(el.sjCd)}
                            state={workerAttendList}
                            setState={setWorkerAttendList}
                            stateKey='sjCd'
                            codeKey='sjName'
                            index={i}
                            initiateKey={workerAttendList.length}
                            useFlag
                            disabled={!auth.createAuth && !auth.updateAuth}
                          />
                        </div>
                        <div className='trCol10'>{t('이름')}</div>
                        <div className='trCol15 datePickerWrapper'>
                          <ReactDatePicker locale={ko} selected={el.aDateF} onChange={(date: any) => handleDateChange(date, i)} dateFormat='yyyy-MM-dd' />
                        </div>
                        <div className='trCol40 flex-basic'>
                          <div className='flex-basic'>
                            <ReactSelect
                              options={hourList2}
                              state={workerAttendList}
                              setState={setWorkerAttendList}
                              defaultValue={{ label: el.hour, value: el.hour }}
                              stateKey='hour'
                              index={i}
                              isClearable={false}
                              optionsPlacement='auto'
                            />
                            <span style={{ padding: '0 1rem 0 .5rem' }}>{t('시')}</span>
                          </div>
                          <div className='flex-basic'>
                            <ReactSelect
                              options={minList2}
                              state={workerAttendList}
                              setState={setWorkerAttendList}
                              defaultValue={{ label: el.min, value: el.min }}
                              stateKey='min'
                              index={i}
                              isClearable={false}
                              optionsPlacement='auto'
                            />
                            <span style={{ padding: '0 1rem 0 .5rem' }}>{t('분')}</span>
                          </div>
                          <div className='flex-basic'>
                            <ReactSelect
                              options={minList2}
                              state={workerAttendList}
                              setState={setWorkerAttendList}
                              defaultValue={{ label: el.sec, value: el.sec }}
                              stateKey='sec'
                              index={i}
                              isClearable={false}
                              optionsPlacement='auto'
                            />
                            <span style={{ paddingLeft: '0.5rem' }}>{t('초')}</span>
                          </div>
                        </div>
                        {auth.deleteAuth && (
                          <div className='trCol10'>
                            <BtnRed onClick={() => onClickDeleteWorkerAttendRow(el, i)}>{t('삭제')}</BtnRed>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </InputTable>
            </div>
            <div className='modal-footer flex-end'>
              <BtnGray onClick={onClickClose}>{t('닫기')}</BtnGray>
            </div>
          </div>
          <Portal openModal={openModal2?.status}>
            {openModal2.status === true ? openModal2.type === 'delete' ? <DeleteModal openModal={openModal2} setOpenModal={setOpenModal2} /> : undefined : undefined}
          </Portal>
        </div>
      </Modal>
    </ModalBackground>
  );
};

export default InfoAttendModal;
