import { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { ISizeObj } from 'customTypes';
import { toast, ToastContainer } from 'react-toastify';
import Cookies from 'js-cookie';
import GlobalStyle from './assets/styles/GlobalStyle';
import Login from './pages/Login';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import SiteDashboard from './pages/SiteDashboard';
import NotFound from './pages/NotFound';

import Master1 from './pages/master/Master1';
import Master2 from './pages/master/Master2';
import Master3 from './pages/master/Master3';
import Master4 from './pages/master/Master4';
import Master6 from './pages/master/master6';
import Mmenu1 from './pages/m_menu/M_menu1';
import Mmenu2 from './pages/m_menu/M_menu2';
import Mcommon1 from './pages/m_common/M_common1';
import Mcommon3 from './pages/m_common/M_common3';
import Mcommon4 from './pages/m_common/M_common4';
import Mcommon5 from './pages/m_common/M_common5';
import Mcommon6 from './pages/m_common/M_common6';

import Hinfo1 from './pages/h_info/H_info1';
import Huser1 from './pages/h_user/H_user1';
import Hcctv1 from './pages/h_cctv/H_cctv1';
import Hcctv2 from './pages/h_cctv/H_cctv2';
import Hcommon2 from './pages/h_common/H_common2';

import Scctv1 from './pages/s_cctv/S_cctv1';
import SMSetting1 from './pages/sm_setting/SM_setting1';
import SMSetting2 from './pages/sm_setting/SM_setting2';
import SMSetting3 from './pages/sm_setting/SM_setting3';
import SMSetting4 from './pages/sm_setting/SM_setting4';
import SMSetting5 from './pages/sm_setting/SM_setting5';
import SMSetting6 from './pages/sm_setting/SM_setting6';
import SMSetting7 from './pages/sm_setting/SM_setting7';
import SMSetting9 from './pages/sm_setting/SM_setting9';
import SMSetting10 from './pages/sm_setting/SM_setting10';
import SMSetting11 from './pages/sm_setting/SM_setting11';

import Suser1 from './pages/s_user/S_user1';
import Sinfo1 from './pages/s_info/S_info1';
import Sworker1 from './pages/s_worker/S_worker1';
import Sworker2 from './pages/s_worker/S_worker2';
import Sworker4 from './pages/s_worker/S_worker4';
import Sworker5 from './pages/s_worker/S_worker5';
import Sworker11 from './pages/s_worker/S_worker11';
import Sworker10 from './pages/s_worker/S_worker10';
import Sworker9 from './pages/s_worker/S_worker9';
import Sworker8 from './pages/s_worker/S_worker8';
import Sworker7 from './pages/s_worker/S_worker7';
import Sworker6 from './pages/s_worker/S_worker6';
import Sattend1 from './pages/s_attend/S_attend1';
import Sattend8 from './pages/s_attend/S_attend8';
import Sattend7 from './pages/s_attend/S_attend7';
import Sattend6 from './pages/s_attend/S_attend6';
import Sattend5 from './pages/s_attend/S_attend5';
import Sattend4 from './pages/s_attend/S_attend4';
import Sattend3 from './pages/s_attend/S_attend3';
import Sattend2 from './pages/s_attend/S_attend2';
import Ssafe1 from './pages/s_safe/S_safe1';
import Ssafe2 from './pages/s_safe/S_safe2';
// import Ssafe3 from './pages/s_safe/S_safe3';
import Ssafe4 from './pages/s_safe/S_safe4';
import Ssafe5 from './pages/s_safe/S_safe5';
import Ssafe6 from './pages/s_safe/S_safe6';
import Ssafe7 from './pages/s_safe/S_safe7';
import Ssensor1 from './pages/s_sensor/S_sensor1';
import Ssensor2 from './pages/s_sensor/S_sensor2';
import Ssensor3 from './pages/s_sensor/S_sensor3';
import Ssensor4 from './pages/s_sensor/S_sensor4';
import Ssensor5 from './pages/s_sensor/S_sensor5';
import Ssensor6 from './pages/s_sensor/S_sensor6';
import Ssensor7 from './pages/s_sensor/S_sensor7';
import Sbroadcast1 from './pages/s_broadcast/S_broadcast1';
import LogReport from './pages/LogReport';
import { ThemeProvider } from 'styled-components';
import { light, dark } from './assets/styles/theme';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { bScreenMode, IUser, themeState, tunnelReverseState, userState } from './atoms';
import * as cctv from './utils/cctv';
import { useTranslation } from 'react-i18next';
import Stunnel1 from './pages/s_tunnel/S_tunnel1';
import { Mqtt } from './utils/mqttClass';
import AlertModal from './components/Modal/AlertModal';
import useTimer from './utils/useTimer';
import { fetchTunnelReversed } from './services/Tunnel';
import RealtimeCCTV from './pages/s_cctv/S_cctv1/RealtimeCCTV';
import PlayCCTV from './pages/s_cctv/S_cctv1/PlayCCTV';
import DownloadCctv from './pages/s_cctv/S_cctv1/PlayCCTV/DownloadCctv';
import Video from './components/Video';
import SMSetting12 from './pages/sm_setting/SM_setting12';
import Ssafeedu1 from './pages/s_safeedu/S_safeedu1';
import Ssafeedu2 from './pages/s_safeedu/S_safeedu2';

const App = () => {
  const { t } = useTranslation();
  const token = Cookies.get('prosafeToken');
  const refreshToken = Cookies.get('refreshToken');
  const userInfo = useRecoilValue<IUser>(userState);
  const location = useLocation();
  const [login, setLogin] = useState(token !== undefined);
  const theme = useRecoilValue(themeState);
  const colorMode = useRecoilValue(bScreenMode);
  const resetUserInfo = useResetRecoilState(userState);
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '' });
  const [mqtt, setMqtt] = useState<Mqtt>();
  const ALERT_DURATION = 60; // 알럿창 유지 시간(초)

  const [mode, setMode] = useState('COL'); // 가로세로모드
  const [size, setSize] = useState<ISizeObj>({
    innerSize: { W: 0, H: 0 },
    clientSize: { W: 0, H: 0 },
  });

  const setIsReversed = useSetRecoilState(tunnelReverseState);

  useEffect(() => {
    document.oncontextmenu = function () {
      return false;
    };
  }, []);

  useEffect(() => {
    /**
     * 수정자 : 한영광
     * 수정일자 : 2023.11.20
     * 수정내용 : 상환판용 계정은 중복 로그인 가능하게 처리
     *           (test 계정은 임시로 중복로그인 가능하게 처리함 출시 이후 삭제할 예정)
     */
    if (login) {
      if (mqtt && mqtt.connected()) {
        mqtt.end();
      }
      setMqtt(new Mqtt());
    }
  }, [userInfo.userId, userInfo.hCd, userInfo.sCd, login]);

  useEffect(() => {
    if (mqtt) {
      const alertDataClosure = alertDataFunction();
      let duplicateUserToken = '';
      mqtt.on('connect', () => {
        // console.log('Connected to MQTT broker mqttClient');
        if (userInfo.userId && userInfo.userId !== 'siteDashboardViewer' && userInfo.userId !== 'test') {
          mqtt.publish(`${userInfo.userId}`, `signin/${refreshToken}`);
          mqtt.subscribe(`${userInfo.userId}`);
        }
        if (userInfo.sCd !== '00000') {
          mqtt.subscribe(`${userInfo.hCd}${userInfo.sCd}/#`);
        }
      });
      mqtt.on('message', (topic: any, message: any) => {
        const [key, userToken] = message.toString().split('/');
        if (topic === userInfo.userId && userToken !== duplicateUserToken) {
          if (key === 'signin' && userToken !== refreshToken) {
            mqtt.publish(`${userInfo.userId}`, `not empty/${refreshToken}`);
          }

          if (key === 'not empty' && userToken !== refreshToken) {
            alert(`${t('먼저 로그인된 기기에서 로그아웃됩니다')}`);
            mqtt.publish(`${userInfo.userId}`, `logout/${refreshToken}`);
          }

          if (key === 'logout' && userToken !== refreshToken) {
            mqtt.end();
            setLogin(false);
            Cookies.remove('prosafeToken');
            Cookies.remove('refreshToken');
            alert(`${t('다른 기기에서 로그인')}`);
          }

          duplicateUserToken = userToken;
        } else if (topic.split('/')[1] === 'board' && window.location.pathname === '/board') {
          mqtt.end();
          window.location.reload();
        } else if (topic.split('/')[1] === 'serverS') {
          const newAlertData = alertDataClosure.addAlertData(JSON.parse(message.toString()).data);
          if (window.location.pathname !== '/board') {
            setOpenModal({ status: true, type: 'alertModal', data: newAlertData, initAlertData: alertDataClosure.initAlertData });
          }
        } else if (topic.split('/')[1] === 'serverBC') {
          const newAlertData = alertDataClosure.addAlertData(JSON.parse(message.toString()).data);
          if (window.location.pathname !== '/board') {
            setOpenModal({ status: true, type: 'alertModal', data: newAlertData, initAlertData: alertDataClosure.initAlertData });
          }
        }
      });

      mqtt.on('error', (error: any) => {
        console.error('MQTT mqttClient error:', error);
      });
      mqtt.on('disconnect', (e: any) => {
        console.log('disconnect', e);
      });
    }
  }, [mqtt]);

  useEffect(() => {
    if (token !== undefined) setLogin(true);
    else setLogin(false);
  }, [token]);

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    window.localStorage.setItem('color-mode', theme);
  }, [theme]);

  // 터널 역방향/정방향 전역변수 설정
  useEffect(() => {
    if (login) {
      fetchTunnelReveredState();
    }
  }, [login, userInfo.sCd]);

  useEffect(() => {
    sizeCheck();
    window.addEventListener('resize', sizeCheck);
    window.screen.orientation.addEventListener('change', sizeCheck);
    return () => {
      window.removeEventListener('resize', sizeCheck);
      window.screen.orientation.removeEventListener('change', sizeCheck);
    };
  }, [window.innerWidth, userInfo.userId]);

  const sizeCheck = () => {
    if (window.screen.availWidth < window.screen.availHeight) setMode('ROW');
    setSize({
      innerSize: { W: window.innerWidth, H: window.innerHeight },
      clientSize: { W: window.screen.availWidth, H: window.screen.availHeight },
    });
    if (cctv.getPluginOBJECT()?.oPlugin && location.pathname === '/') {
      cctv.destroy();
    }
  };

  const alertDataFunction = () => {
    let alertData: any[] = [];
    const addAlertData = (data: any) => {
      alertData.push(data);
      if (useTimer.getCount() > 0) {
        useTimer.setCount(ALERT_DURATION);
      } else {
        useTimer.startCounter(ALERT_DURATION, initAlertData, setOpenModal);
      }
      return alertData.sort((a, b) => b.time - a.time);
    };
    const initAlertData = () => {
      alertData = [];
    };
    return {
      addAlertData,
      initAlertData,
    };
  };

  useEffect(() => {
    setScreenSize();
  });

  // 터널 정방향/역방향 설정 함수
  const fetchTunnelReveredState = async () => {
    const isTunnelReversed = await fetchTunnelReversed(userInfo);
    isTunnelReversed !== null && setIsReversed(isTunnelReversed);
  };

  const checkScreenMode = () => {
    if (location.pathname === '/board') {
      return colorMode === '1' ? light : dark;
    }
    return theme === 'light' ? light : dark;
  };

  function setScreenSize() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  return (
    <ThemeProvider theme={location.pathname !== undefined && checkScreenMode}>
      <GlobalStyle />
      <ToastContainer
        style={{ zIndex: 999999 }}
        position='top-center'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
      <Routes>
        {login ? (
          <Route path='/' element={<Layout size={size} />}>
            <Route index element={<Dashboard />} />
            {/* <Route path='bTest' element={<SiteDashboard />} />  */}
            <Route path='master1' element={<Master1 />} />
            <Route path='master2' element={<Master2 />} />
            <Route path='master3' element={<Master3 />} />
            <Route path='master4' element={<Master4 />} />
            <Route path='master6' element={<Master6 />} />
            <Route path='m_menu1' element={<Mmenu1 />} />
            <Route path='m_menu2' element={<Mmenu2 />} />
            <Route path='m_common1' element={<Mcommon1 />} />
            <Route path='m_common3' element={<Mcommon3 />} />
            <Route path='m_common4' element={<Mcommon4 />} />
            <Route path='m_common5' element={<Mcommon5 />} />
            <Route path='m_common6' element={<Mcommon6 />} />
            <Route path='m_log' element={<LogReport />} />
            <Route path='h_info1' element={<Hinfo1 />} />
            <Route path='h_user1' element={<Huser1 />} />
            <Route path='h_cctv1' element={<Hcctv1 />} />
            <Route path='h_cctv2' element={<Hcctv2 />} />
            <Route path='h_common2' element={<Hcommon2 />} />
            <Route path='h_log' element={<LogReport />} />
            <Route path='s_info1' element={<Sinfo1 />} />
            <Route path='s_worker1' element={<Sworker1 />} />
            <Route path='s_worker2' element={<Sworker2 />} />
            <Route path='s_worker4' element={<Sworker4 />} />
            <Route path='s_worker5' element={<Sworker5 />} />
            <Route path='s_worker6' element={<Sworker6 />} />
            <Route path='s_worker7' element={<Sworker7 />} />
            <Route path='s_worker8' element={<Sworker8 />} />
            <Route path='s_worker9' element={<Sworker9 />} />
            <Route path='s_worker10' element={<Sworker10 />} />
            <Route path='s_worker11' element={<Sworker11 />} />
            <Route path='s_attend1' element={<Sattend1 />} />
            <Route path='s_attend2' element={<Sattend2 />} />
            <Route path='s_attend3' element={<Sattend3 />} />
            <Route path='s_attend4' element={<Sattend4 />} />
            <Route path='s_attend5' element={<Sattend5 />} />
            <Route path='s_attend6' element={<Sattend6 />} />
            <Route path='s_attend7' element={<Sattend7 />} />
            <Route path='s_attend8' element={<Sattend8 />} />
            <Route path='s_safe1' element={<Ssafe1 />} />
            <Route path='s_safe2' element={<Ssafe2 />} />
            {/* <Route path='s_safe3' element={<Ssafe3 />} /> */}
            <Route path='s_safe4' element={<Ssafe4 />} />
            <Route path='s_safe5' element={<Ssafe5 />} />
            <Route path='s_safe6' element={<Ssafe6 />} />
            <Route path='s_safe7' element={<Ssafe7 />} />
            <Route path='s_sensor1' element={<Ssensor1 />} />
            <Route path='s_sensor2' element={<Ssensor2 />} />
            <Route path='s_sensor3' element={<Ssensor3 />} />
            <Route path='s_sensor4' element={<Ssensor4 />} />
            <Route path='s_sensor5' element={<Ssensor5 />} />
            <Route path='s_sensor6' element={<Ssensor6 />} />
            <Route path='s_sensor7' element={<Ssensor7 />} />
            <Route path='s_user1' element={<Suser1 />} />
            <Route path='s_tunnel1' element={<Stunnel1 />} />
            <Route path='s_safeedu1' element={<Ssafeedu1 />} />
            <Route path='s_safeedu2' element={<Ssafeedu2 />} />
            <Route path='sm_setting1' element={<SMSetting1 mqtt={mqtt} />} />
            <Route path='sm_setting2' element={<SMSetting2 />} />
            <Route path='sm_setting3' element={<SMSetting3 />} />
            <Route path='sm_setting4' element={<SMSetting4 mqtt={mqtt} />} />
            <Route path='sm_setting5' element={<SMSetting5 />} />
            <Route path='sm_setting6' element={<SMSetting6 />} />
            <Route path='sm_setting7' element={<SMSetting7 />} />
            <Route path='sm_setting9' element={<SMSetting9 />} />
            <Route path='sm_setting10' element={<SMSetting10 />} />
            <Route path='sm_setting11' element={<SMSetting11 />} />
            <Route path='sm_setting12' element={<SMSetting12 />} />
            <Route path='s_cctv1' element={<Scctv1 />} />
            <Route path='s_log' element={<LogReport />} />
            <Route path='s_broadcast' element={<Sbroadcast1 />} />
            <Route path='/board' element={<SiteDashboard />} />
            <Route path='realtime_cctv' element={<RealtimeCCTV />} />
            <Route path='play_cctv' element={<PlayCCTV />} />
            <Route path='download_cctv' element={<DownloadCctv />} />
            <Route path='safeedu_video' element={<Video />} />
          </Route>
        ) : (
          <>
            <Route path='/' element={<Login />} />
            <Route path='/board' element={<SiteDashboard />} />
          </>
        )}
        <Route path='*' element={<NotFound />} />
      </Routes>
      {openModal.status && openModal.type === 'alertModal' && <AlertModal openModal={openModal} setOpenModal={setOpenModal} />}
    </ThemeProvider>
  );
};

export default App;
