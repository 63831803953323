import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import { WebSDKcctv } from '../../utils/webSDKcctvClass';
import { IModal } from 'customTypes';
import { useRecoilState } from 'recoil';
import { mouseDownIndexState } from '../../atoms';
import { getDayStartAndEndTimeStamps } from '../../utils/formatDate';
import BottomMenu from './S_cctv1/PlayCCTV/BottomMenu';
import { CCTV_PLAY_BOTTOM_CONTROL_HEIGHT, CCTV_PLAY_SIDE_MENU_WIDTH } from '../../_constants';
import { extractDate, extractTime, timeToHHMMSS, HHMMSStoTime } from '../../utils/timeline';
import { initPath } from '../../services/webSDK';

const Root = styled.div`
  flex: 1 0 50%;
  overflow: hidden;
  .cctvWrapper {
    height: calc(100% - 7rem);
    background-color: ${({ theme }: { theme: any }) => theme.color.zinc_900};
  }
  .control-bar {
    height: calc(100vh - 7rem);
  }
`;

interface Props {
  draggingCamInfo: any;
  setDraggingCamInfo: any;
  droppedWndIndex: number | null;
  cctvSettings: CctvSettings;
  setDroppedWndIndex: Dispatch<SetStateAction<number | null>>;
  cctvOBJ: WebSDKcctv | undefined;
  setCctvOBJ: Dispatch<SetStateAction<WebSDKcctv | undefined>>;
  cctvFlow: number;
  setCctvFlow: Dispatch<SetStateAction<number>>;
  cameraInfo: any;
  setIsDragging: Dispatch<SetStateAction<boolean>>;
  visiblePanel: boolean;
  setVisiblePanel: Dispatch<SetStateAction<boolean>>;
  nvrList: IMainMenu[];
  setNvrList: Dispatch<SetStateAction<IMainMenu[]>>;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  selectedCameraInfo: SelectedCctvCameraInfo;
  setSelectedCameraInfo: Dispatch<SetStateAction<SelectedCctvCameraInfo>>;
  dayRecordFiles: Playback[];
  selectedStream: Stream;
  cctvDivisions: 1 | 2 | 3 | 4;
  setCctvDivisions: Dispatch<SetStateAction<1 | 2 | 3 | 4>>;
  windows: {
    windowIndex: number;
    clock: {
      currentTime: number; //
      setCurrentTime: Dispatch<SetStateAction<number>>;
      setIsPlaying: Dispatch<SetStateAction<boolean>>;
      setSpeed: Dispatch<SetStateAction<1 | 2 | 4>>;
      speed: 1 | 2 | 4;
      currentDate: string;
      setCurrentDate: Dispatch<SetStateAction<string>>;
      isReversed: boolean;
      setIsReversed: Dispatch<SetStateAction<boolean>>;
    };
  }[];
}

const WebSdkPlay = ({
  draggingCamInfo, // 드래그한 카메라 객체 정보
  setDraggingCamInfo, // 드래그한 카메라 객체 정보 변경 함수
  droppedWndIndex, // 드래그한 카메라 객체를 드랍한 윈도우 인덱스
  cctvSettings, // cctv 사용자세팅값
  setDroppedWndIndex, // 드래그한 카메라 객체를 드랍한 윈도우 인덱스 변경 함수
  cctvOBJ, // cctv 객체
  setCctvOBJ, // cctv 객체 변경 함수
  cctvFlow,
  setCctvFlow,
  cameraInfo,
  nvrList,
  setNvrList,
  setIsDragging, // 드래그 상태 변경 함수
  visiblePanel, // 왼쪽 사이드 패널 접기/펼치기 상태
  setVisiblePanel, // 왼쪽 사이드 패널 상태 변경 함수
  currentPage, // 사용자가 보고있는 현재 페이지
  setCurrentPage, // 현재 페이지 setState
  selectedCameraInfo,
  setSelectedCameraInfo,
  dayRecordFiles,
  selectedStream,
  cctvDivisions,
  setCctvDivisions,
  windows,
}: Props) => {
  const divPlugin = 'divPlugin';
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const [mouseDownIndex, setMouseDownIndex] = useRecoilState(mouseDownIndexState);

  // const [windowIndex, setWindowIndex] = useState<number>(0);

  const selectedStartDate = getDayStartAndEndTimeStamps(new Date()).start;
  const selectedEndDate = getDayStartAndEndTimeStamps(new Date()).end;
  const initSelectedDateTime = { start: selectedStartDate, end: selectedEndDate };
  const [selectedDateTime, setSelectedDateTime] = useState(initSelectedDateTime);

  const [videoWindow, setVideoWindow] = useState<VideoWindow | null>(null);
  const [recordData, setRecordData] = useState<Playback[]>([]);

  useEffect(() => {
    if (cctvFlow === 0) {
      const flowChangeListener = (flow: number) => setCctvFlow(flow);
      const newCctvObj = new WebSDKcctv({
        setOpenModal, //
        flowChangeListener,
        divPlugin,
        cType: cctvDivisions,
        setSelectWndIndex: setMouseDownIndex,
      });
      newCctvObj.init();
      setCctvOBJ(newCctvObj);
    }
    if (cctvFlow === 1) {
      const filterNvrList = cameraInfo.filter((v: any, i: number) => cameraInfo.findIndex((v2: any) => v.ip === v2.ip) === i);
      const data = filterNvrList.map((v: any) => ({ ip: v.ip, pPort: v.pPort, id: v.id, password: v.password }));
      cctvOBJ?.login(data);
    }
    if (cctvFlow === 2) cctvOBJ?.changeWndNum(cctvDivisions);
  }, [cctvFlow]);

  useEffect(() => {
    if (cameraInfo.length > 0 && cctvFlow === 2) {
      const res = cctvOBJ?.stop();
      res?.then(() => {
        const updateNvrList = nvrList.map((el: IMainMenu) => {
          const newSubList = el.subList.map((camera: ISubMenu) =>
            camera.isPlaying //
              ? { ...camera, isPlaying: false }
              : camera
          );
          return { ...el, subList: newSubList };
        });
        setNvrList(updateNvrList);
      });
    }
  }, [cameraInfo, cctvFlow]);

  // 대상 카메라가 현재 보고있는 그리드에 있는 지 확인하는 함수
  const grid = cctvDivisions * cctvDivisions;
  const isCameraInCurrentGrid = (index: number) => {
    if (index >= (currentPage - 1) * grid && index < (currentPage - 1) * grid + grid) {
      return true;
    }
    return false;
  };

  // 선택한 window의 selectedCameraInfo 업데이트
  useEffect(() => {
    if (cctvOBJ && mouseDownIndex !== null) {
      const cameraData = cctvOBJ.getCameraStatus(mouseDownIndex);
      setSelectedCameraInfo(cameraData);
      // 1. mouseDownIndex 변경시 videoWindow, selectedDate 업데이트
      // 2. selectedDate는 선택한 윈도우에 저장된 video 일시를 가져옴
      const windowsData = windows.find((el: any) => el.windowIndex === mouseDownIndex);
      if (windowsData) {
        const { currentDate } = windowsData.clock;
        const start = currentDate ? `${currentDate} 00:00:00` : '';
        const end = currentDate ? `${currentDate} 23:59:59` : '';
        setSelectedDateTime({ start, end });

        // 선택한 윈도우 업데이트
        setVideoWindow(windowsData);
      }
    }
  }, [mouseDownIndex, cctvOBJ, windows]);

  // 윈도우에 있는 화면 클릭시 해당 카메라에 저장된 비디오 조회 및 저장
  useEffect(() => {
    // cctvOBJ가 있고, 화면이 비어 카메라가 있는 조건
    const condition = cctvFlow === 2 && cctvOBJ && selectedCameraInfo.pPort !== '';
    const startTime = selectedDateTime.start;
    const endTime = selectedDateTime.end;
    if (condition) {
      cctvOBJ
        // 저장된 비디오가 있는지 조회
        .recordSearch(selectedCameraInfo, 1, startTime, endTime)
        // 조회된 비디오 상태 저장
        .then((res: any) => setRecordData(res));
    } else {
      setRecordData([]);
    }
  }, [cctvFlow, cctvOBJ, selectedDateTime, selectedCameraInfo]);

  // 화면분할 클릭 (1분할, 4분할, ...16분할)
  const onClickDivision = (divisionNum: 1 | 2 | 3 | 4) => {
    setCctvDivisions(divisionNum);
    if (cctvOBJ?.getPluginOBJ()?.oPlugin) {
      cctvOBJ.changeWndNum(divisionNum);
      // cctvOBJ.changeWndNumWithoutInitialize(divisionNum);
    }
  };

  // 재생 속도 변경
  const changePlaybackSpeed = async (speed: 'fast' | 'slow') => {
    const { windowIndex } = selectedCameraInfo;
    const response =
      speed === 'fast' //
        ? await cctvOBJ?.playFast(windowIndex || 0)
        : await cctvOBJ?.playSlow(windowIndex || 0);
    if (response?.success) {
      const { data: playSpeed } = response;
      setSelectedCameraInfo((prev) => ({ ...prev, playbackSpeed: playSpeed }));
      videoWindow?.clock.setSpeed(playSpeed);
    }
  };

  // 목록의 윈도우인덱스와 재생 상태 업데이트 함수
  const updateWindowIndexAndPlayStatus = (draggingCams: ISubMenu) => {
    // 드래그된 카메라가 있을 경우에만 실행
    const { nCd, cCd } = draggingCams;
    const updatedNvrList = nvrList?.filter((nvr: IMainMenu) => nvr.nCd === nCd);
    const updatedSubListArray = updatedNvrList[0]?.subList.map((camera: ISubMenu) =>
      camera.cCd === cCd ? { ...draggingCams, windowIndex: draggingCams.windowIndex + (currentPage - 1) * grid } : camera
    );
    setNvrList(nvrList.map((nvr: IMainMenu) => (nvr.nCd === nCd ? { ...nvr, subList: updatedSubListArray } : nvr)));
  };

  // 녹화파일 재생을 처리하는 비동기 함수
  const handleCameraPlayback = async (camera: ISubMenu, stream: number, startTime: string, endTime: string, droppedIdx: number) => {
    try {
      // 녹화파일 검색
      const recordSearchRes = await cctvOBJ?.recordSearch(camera, stream, startTime, endTime);
      if (recordSearchRes) {
        // 검색된 녹화파일로 재생
        const recordStartRes = await cctvOBJ?.startPlayback(camera, stream, recordSearchRes[0].szStartTime, recordSearchRes[0].szEndTime, droppedIdx);
        if (recordStartRes) {
          // 현재 재생 중이고, 주어진 카메라와 일치하는 카메라만 필터링
          const relevantCams: any = recordStartRes.find((cam: any) => cam.isPlaying && cam?.nCd === camera?.nCd && cam?.cCd === camera?.cCd);
          if (relevantCams) {
            // 0.1초 뒤 pause (썸네일효과 위한 처리)
            pauseAfterTimeout(relevantCams, droppedIdx);
          }
          // 드래그한 카메라 정보 setState
          setDraggingCamInfo([camera]);
        }
      }
    } catch (error) {
      console.error('Error handling camera playback:', error);
    }
  };

  const onClickStopAndPlay = async (reverse?: 'reverse') => {
    const { windowIndex } = selectedCameraInfo;
    if (windowIndex !== null) {
      // if (mouseDownIndex !== null) {
      const response = await cctvOBJ?.stopWithIndex(windowIndex);
      if (response) {
        !response[windowIndex].isPlaying && onClickPlay(reverse);
        // !response[mouseDownIndex].isPlaying && onClickPlay(reverse);
      }
    }
  };

  const onClickPlay = async (reverse?: 'reverse') => {
    const condition =
      // mouseDownIndex !== null &&
      selectedCameraInfo.ip !== '' && // 비어 있는 윈도우가 아닌 경우
      selectedCameraInfo.isPlaying === false && // 카메라가 재생 중이 아닌 경우
      videoWindow?.clock.currentTime !== undefined &&
      videoWindow?.windowIndex !== null;
    if (condition) {
      const {
        windowIndex,
        clock: { currentTime, currentDate },
      } = videoWindow;
      const { ip, stream, channelNum, port, pPort, cName, nCd, cCd } = selectedCameraInfo;
      const camera = { ip, stream, channelNum, port, pPort, cName, nCd, cCd };
      const isReversed = reverse === 'reverse';
      const startTime = isReversed ? recordData[0].szStartTime : `${currentDate} ${timeToHHMMSS(currentTime)}`;
      const endTime = isReversed ? `${currentDate} ${timeToHHMMSS(currentTime)}` : recordData[recordData.length - 1].szEndTime;
      // 재생 시작 메서드
      const response =
        reverse === 'reverse'
          ? await cctvOBJ?.startReversePlayback(camera, Number(stream), startTime, endTime, windowIndex)
          : await cctvOBJ?.startPlayback(camera, Number(stream), startTime, endTime, windowIndex);
      if (response) {
        const cctvCamera = response.find((cam) => cam.cCd === cCd);
        const { setIsPlaying } = videoWindow.clock;
        cctvCamera?.isPlaying && setIsPlaying(true);
        cctvCamera?.isPlaying && setSelectedCameraInfo({ ...selectedCameraInfo, isPlaying: true });
      }
    }
  };

  const onClickPause = async () => {
    console.log(mouseDownIndex, 'mouseDownIndex');
    console.log(videoWindow?.windowIndex, 'videoWindow?.windowIndex');
    const condition =
      mouseDownIndex !== null &&
      selectedCameraInfo.ip !== '' && // 비어 있는 윈도우가 아닌 경우
      selectedCameraInfo.isPlaying === true && // 카메라 재생 중인 경우
      videoWindow?.clock.currentTime !== undefined;
    if (condition) {
      const response = await cctvOBJ?.pause(mouseDownIndex);
      if (response) {
        setSelectedCameraInfo({ ...selectedCameraInfo, isPlaying: false });
        const { setIsPlaying } = videoWindow.clock;
        setIsPlaying(false);
      }
    }
  };

  const onClickStop = async () => {
    const condition =
      selectedCameraInfo.ip !== '' && // 비어있는 윈도우가 아니고
      videoWindow?.clock.currentTime !== undefined; //
  };

  // 특정 시간 후 카메라 일시정지하는 함수
  const pauseAfterTimeout = (camera: ISubMenu, droppedIdx: number) => {
    setTimeout(() => {
      // 일시정지 메서드 실행
      cctvOBJ?.pause(droppedIdx).then((pauseRes: any) => {
        const cameraObj = pauseRes.find((el: any) => el.nCd === camera.nCd && el.cCd === camera.cCd);
        if (cameraObj) {
          // 윈도우 인덱스와 재생 상태 업데이트
          updateWindowIndexAndPlayStatus(cameraObj);
          // 선택된 카메라정보 업데이트
          cctvOBJ && setSelectedCameraInfo(cctvOBJ.getCameraStatus(cameraObj.windowIndex));
        }
      });
    }, 10);
  };

  useEffect(() => {
    // 카메라를 드래그 해서 분할화면에 드랍한 경우
    if (droppedWndIndex !== null && draggingCamInfo !== null) {
      // 윈도우에 드랍되지 않은 카메라만 필터링
      const notPlayingList = draggingCamInfo //
        .filter((el: ISubMenu) => !el.isPlaying && el.isPausing === undefined);
      if (notPlayingList.length > 0 && dayRecordFiles.length > 0) {
        // 첫번째 video 시작 및 종료 시간
        const { szStartTime, szEndTime } = dayRecordFiles[0];
        // video 시작시간 일자와 종료시간 일자가 같은지 비교
        const comparison = extractDate(szStartTime) === extractDate(szEndTime);
        // 첫번째 video 시작 일자 새로 선언
        const conditionDate = extractDate(szEndTime);
        // 첫번째 video 시작 시간 새로 선언
        const conditionTime = comparison ? extractTime(szStartTime) : '00:00:00';

        // 선택한 윈도우의 일시 업데이트
        const windowsData = windows.find((el: any) => el.windowIndex === droppedWndIndex);
        if (windowsData) {
          const { setCurrentDate, setCurrentTime: setCurrentTimeValue } = windowsData.clock;
          // window의 일자 업데이트
          setCurrentDate(conditionDate);
          // window의 시간 업데이트
          const currentTimeValue = HHMMSStoTime(conditionTime);
          setCurrentTimeValue(currentTimeValue);
          // videoWindow 상태 업데이트
          setVideoWindow(windowsData);
        }

        // 일자가 일치하지 않으면 종료시간 일시 변경 '종료일 00:00:00'
        const conditionDateTime = comparison ? szStartTime : `${extractDate(szEndTime)} 00:00:00`;
        // 해당 날짜에 첫번째로 녹화된 파일 재생
        handleCameraPlayback(
          notPlayingList[0], //
          selectedStream.stream,
          // 경우의 수를 고려한 시작 시간
          conditionDateTime,
          // video 중 가장 마지막 파일 종료 시간
          dayRecordFiles[dayRecordFiles.length - 1].szEndTime,
          droppedWndIndex
        );
      } else {
        setDraggingCamInfo(null);
        setVideoWindow(null);
      }
      // 드롭된 윈도우 인덱스 초기화
      setDroppedWndIndex(null);
    }
  }, [droppedWndIndex, draggingCamInfo]);

  useEffect(() => {
    const width = visiblePanel ? window.innerWidth - CCTV_PLAY_SIDE_MENU_WIDTH : window.innerWidth;
    const height = window.innerHeight - CCTV_PLAY_BOTTOM_CONTROL_HEIGHT;
    cctvOBJ?.resizeWnd(width, height);
  }, [visiblePanel]);

  const mouseUpHandler = (moveEvent: MouseEvent) => {
    const clientWidth = document.querySelector('.cctvWrapper')?.clientWidth || 0;
    const clientHeight = document.querySelector('.cctvWrapper')?.clientHeight || 0;
    const blockWidth = Math.floor(clientWidth / (cctvDivisions || 4));
    const blockHeight = Math.floor(clientHeight / (cctvDivisions || 4));
    const xPosition = Math.floor(moveEvent.offsetX / blockWidth);
    const yPosition = Math.floor(moveEvent.offsetY / blockHeight);
    const index = yPosition * cctvDivisions + xPosition;
    // console.log('drop index', index);
    setDroppedWndIndex(index);
    cctvOBJ?.getCameraStatus(index);
  };

  if (videoWindow === null) return null;

  return (
    <Root>
      <div
        id={divPlugin}
        className='cctvWrapper'
        role='button'
        tabIndex={0}
        onMouseUp={() => {
          document.addEventListener('mouseup', mouseUpHandler, { once: true });
        }}
      />
      <BottomMenu //
        visiblePanel={visiblePanel}
        setVisiblePanel={setVisiblePanel}
        cctvOBJ={cctvOBJ}
        cctvDivisions={cctvDivisions}
        onClickDivision={onClickDivision}
        selectedCameraInfo={selectedCameraInfo}
        setSelectedCameraInfo={setSelectedCameraInfo}
        nvrList={nvrList}
        setNvrList={setNvrList}
        recordData={recordData}
        videoWindow={videoWindow}
        onClickPlay={onClickStopAndPlay}
        onClickStopAndPlay={onClickStopAndPlay}
        onClickPause={onClickPause}
        capturePath={initPath(cctvSettings.camCPath)}
        onClickSpeed={changePlaybackSpeed}
      />
    </Root>
  );
};

export default WebSdkPlay;
