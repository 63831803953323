/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.30
 * 경로 : 터널 모달 > 굴진/일반정보 모듈
 */

import styled from 'styled-components';

const PercentageColorBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.25rem;
  padding: 0 0.25rem;
  .colorbar {
    display: flex;
    height: 0.75rem;
    width: 100%;
    overflow: hidden;
    border-radius: 1rem;
    .section {
      width: 20%;
      border-right: 3px solid;
      &.level1 {
        border: none;
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
      }
      &.level2 {
        border-color: ${({ theme }: { theme: any }) => theme.filled_amber};
        background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
      }
      &.level3 {
        border-color: ${({ theme }: { theme: any }) => theme.filled_indigo};
        background-color: ${({ theme }: { theme: any }) => theme.tonal_violet};
      }
      &.level4 {
        border-color: ${({ theme }: { theme: any }) => theme.filled_green};
        background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
      }
      &.level5 {
        border-color: ${({ theme }: { theme: any }) => theme.filled_blue};
        background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
      }
    }
  }
  .percentage {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: flex-end;
    span {
      font-size: 0.75rem;
      font-weight: 400;
      color: ${({ theme }: { theme: any }) => theme.text_disabled};
      padding: 0 0.5rem;
      border-radius: 1rem;
      display: flex;
      height: 1.25rem;
      align-items: center;
      justify-content: center;
      width: 2rem;
    }
    .start,
    .end {
      position: absolute;
    }
    .start {
      right: 0;
    }
    .end {
      left: 0;
    }
    div {
      width: 20%;
      > span {
        transform: translateX(-50%);
      }
    }
  }
  &.reverse {
    .colorbar {
      flex-direction: row-reverse;
      .section {
        border-right: none;
        border-left: 3px solid;
        &.level1 {
          border: none;
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
        }
        &.level2 {
          border-color: ${({ theme }: { theme: any }) => theme.filled_amber};
          background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
        }
        &.level3 {
          border-color: ${({ theme }: { theme: any }) => theme.filled_indigo};
          background-color: ${({ theme }: { theme: any }) => theme.tonal_violet};
        }
        &.level4 {
          border-color: ${({ theme }: { theme: any }) => theme.filled_green};
          background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
        }
        &.level5 {
          border-color: ${({ theme }: { theme: any }) => theme.filled_blue};
          background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
        }
      }
    }
    .percentage {
      flex-direction: row-reverse;
      justify-content: flex-start;
      .start {
        right: auto;
        left: 0;
      }
      .end {
        left: auto;
        right: 0;
      }
    }
  }
`;

type Props = {
  reverse?: boolean;
};

const PercentageColorBar = ({ reverse = false }: Props) => {
  return (
    <PercentageColorBarContainer className={`percentagebar ${reverse ? '' : 'reverse'}`}>
      <div className='colorbar'>
        <div className='section level5' />
        <div className='section level4' />
        <div className='section level3' />
        <div className='section level2' />
        <div className='section level1' />
      </div>
      <div className='percentage'>
        <span className='end'>100%</span>
        <div>
          <span>80%</span>
        </div>
        <div>
          <span>60%</span>
        </div>
        <div>
          <span>40%</span>
        </div>
        <div>
          <span>20%</span>
        </div>
        <span className='start'>0%</span>
      </div>
    </PercentageColorBarContainer>
  );
};

export default PercentageColorBar;
