/*
 * 작성자 : 김광민
 * 날짜 : 2024.07.04
 * 기능 : 상황판에서 가로 사이즈 5 기상종합 컴포넌트 중 시간별날씨
 */

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import sun from '../../assets/images/icons/sun.png';
import cloud from '../../assets/images/icons/lighten_cloud.png';
import blur from '../../assets/images/icons/lighten_blur.png';
import rain from '../../assets/images/icons/white_rain.png';
import snow from '../../assets/images/icons/white_snow.png';
import littleCloud from '../../assets/images/icons/little_cloud.png';
import { PulseLoader } from 'react-spinners';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  background-color: ${({ theme }: { theme: any }) => theme.tonal_deep_blue};
  background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
  .loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .weatherTime {
    width: 100%;
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 0.25rem;
    padding: 0 0.5rem;
    margin: 1rem 0;
    margin: 0.75rem 0;
    .weatherData {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: ${({ theme }: { theme: any }) => theme.alpha_15};
      border-radius: 0.5rem;
      padding-top: 1rem;
      padding-top: 0.5rem;
      overflow: hidden;
      .data {
        display: flex;
        align-items: center;
        font-size: 0.75rem;
        line-height: 1.25;
        font-weight: 500;
        color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
        color: ${({ theme }: { theme: any }) => theme.inverse_alpha_75};
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
        color: ${({ theme }: { theme: any }) => theme.text_primary};
        color: ${({ theme }: { theme: any }) => theme.alpha_75};
        &.time {
          color: ${({ theme }: { theme: any }) => theme.board};
        }
      }
      .info {
        padding: 0.25rem 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.125rem;
        background-color: ${({ theme }: { theme: any }) => theme.alpha_50};
        background-color: ${({ theme }: { theme: any }) => theme.alpha_25};
        border-radius: 0.5rem;
      }
      .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        position: relative;
        overflow: visible;
        &:nth-child(4) {
          margin-top: 1rem;
        }
        &:nth-child(5) {
          margin-top: 1.5rem;
        }
        .icon-wrapper {
          flex-grow: 1;
          position: absolute;
          height: 1.25rem;
          width: 1.25rem;
          left: 0.625rem;
          transform: translate(-50%);
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .material-symbols-rounded {
          font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0;
          &.rain {
            font-size: 0.75rem;
            color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
          }
          &.temp {
            color: ${({ theme }: { theme: any }) => theme.filled_red_deep};
            font-size: 1rem;
          }
        }
        .wrapper-data {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: 0.75rem;
          color: ${({ theme }: { theme: any }) => theme.text_primary};
          font-size: 0.75rem;
          font-weight: 600;
          .unit {
            font-size: 0.5rem;
            color: ${({ theme }: { theme: any }) => theme.text_tertiary};
            font-weight: 600;
          }
        }
      }
      .icon {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.5rem;
        padding-bottom: 0.5rem;
      }
    }
  }
`;

const getWeatherIcon = (weather: string) => {
  switch (weather) {
    case 'sunny':
      return <img src={sun} width='100%' />;
    case 'little_cloud':
      return <img src={littleCloud} width='100%' />;
    case 'cloudy':
      return <img src={cloud} width='100%' />;
    case 'blur':
      return <img src={blur} width='100%' />;
    case 'rain':
      return <img src={rain} width='100%' />;
    case 'sleet':
      return <img src={snow} width='100%' />;
    case 'snow':
      return <img src={snow} width='100%' />;
    case 'shower':
      return <img src={rain} width='100%' />;
    default:
      return null;
  }
};

interface TimeInfo {
  ampm: '오전' | '오후';
  hour: number;
  minute: string;
}

function getTime(milliseconds: string): TimeInfo {
  const hour = Number(milliseconds.slice(0, 2));
  const minute = milliseconds.slice(2, 4);
  return {
    ampm: hour >= 12 ? '오후' : '오전',
    hour: hour > 12 ? hour - 12 : hour,
    minute,
  };
}

type Props = {
  timeWeather: TimeWeatherProps[];
};

type TimeWeatherProps = {
  date: string;
  pcp: string;
  pop: string;
  sky: string;
  sno: string;
  time: string;
  wsd: string;
  tmp: string;
};

const WeatherTotalTime = ({ timeWeather }: Props) => {
  const { t } = useTranslation();

  return (
    <Root className='large'>
      {timeWeather.length === 0 ? (
        <div className='loading'>
          <PulseLoader color='rgb(255, 255, 255)' size='8px' />
        </div>
      ) : (
        // <PulseLoader color='rgb(0, 122, 255)' size='10px' />
        <div className='weatherTime'>
          {timeWeather.map((weather: TimeWeatherProps) => {
            const { pop, sky, time, tmp } = weather;
            const { ampm, hour, minute } = getTime(time);
            return (
              <div key={time} className='weatherData'>
                <div className='data ampm'>{ampm}</div>
                <div className='data time'>{`${hour}:${minute}`}</div>
                <div className='icon'>{getWeatherIcon(sky)}</div>
                <div className='info'>
                  <div className='wrapper'>
                    <div className='icon-wrapper'>
                      <span className='material-symbols-rounded temp'>thermometer</span>
                    </div>
                    <div className='wrapper-data'>
                      {tmp} <span className='unit'>°</span>
                    </div>
                  </div>
                  <div className='wrapper '>
                    <div className='icon-wrapper'>
                      <span className='material-symbols-rounded rain'>water_drop</span>
                    </div>
                    <div className='wrapper-data'>
                      {pop}
                      <span className='unit'>%</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </Root>
  );
};

export default WeatherTotalTime;
