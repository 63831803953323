/**
 * 작성자 : 김광민
 * 날짜 : 2023.12.28
 * 경로 : 터널관리 - 구역 현황 관리 > 종합진행현황
 */

import { useEffect, useState } from 'react';
import { useSetAuth } from '../../utils/useSetAuth';
import { MultiTunnelContainer } from '../../assets/styles/Tunnels';
import { TAREA_INNERTAB_REALTIME_ATTEND, TAREA_INNERTAB_NORMAL } from '../../_constants';
import Portal from '../Portal';
import TunnelChipView from './TunnelChipView';
import TareaInfoModal from '../Modal/tAreaInfoModal';
import MultiTunnelPoint from './MultiTunnelPoint';
import HalfDoughnutChart from '../chart/HalfDoughnutChart';

type Props = {
  tunnelData: TunnelDataType;
  tunnelRawData: TareaData;
  tunnelRawDataList: TareaData[];
  filter: TunnelFilterData[];
};

const MultiTunnel = ({ tunnelData, tunnelRawData, tunnelRawDataList, filter }: Props) => {
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  // State로 변환된 시작점 및 종점 데이터
  const [mutateData, setMutateData] = useState<{ startPoint: MutateDataType; endPoint: MutateDataType } | null>(null);
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '' });

  const {
    attendCount,
    eBsCount,
    eCameraCount,
    eDeviceCount,
    eDvNo,
    eLength,
    eRLength,
    eTesCd,
    eTesName,
    eTjcCd,
    eTjcName,
    eUseYn,
    eWorkerCount,
    sBsCount,
    sCameraCount,
    sDeviceCount,
    sDvNo,
    sLength,
    sRLength,
    sTesCd,
    sTesName,
    sTjcCd,
    sTjcName,
    sUseYn,
    sWorkerCount,
    tatCd,
    tatName,
  } = tunnelData;

  // mutate 함수에서 startPoint와 endPoint를 설정하고 mutateData에 저장합니다.
  useEffect(() => {
    const mutate = () => {
      // 시점 데이터 생성
      const startPoint = {
        tatCd,
        tatGubun: 'S',
        useYn: sUseYn,
        dvNo: sDvNo,
        workerCount: sWorkerCount,
        deviceCount: sDeviceCount,
        cameraCount: sCameraCount,
        bsCount: sBsCount,
        length: sLength,
        rLength: sRLength,
        tesName: sTesName,
        tjcName: sTjcName,
      };

      // 종점 데이터 생성
      const endPoint = {
        tatCd,
        tatGubun: 'E',
        useYn: eUseYn,
        dvNo: eDvNo,
        workerCount: eWorkerCount,
        deviceCount: eDeviceCount,
        cameraCount: eCameraCount,
        bsCount: eBsCount,
        length: eLength,
        rLength: eRLength,
        tesName: eTesName,
        tjcName: eTjcName,
      };

      // 변이 데이터 업데이트
      setMutateData({ startPoint, endPoint });
    };
    mutate();
  }, [tunnelData]);

  const getSize = () => (getBothTunnels() ? 'twoPoints' : 'onePoint');

  const getBothTunnels = () => startPoint && endPoint;

  // 시작점 및 종점 활성화 여부
  const startPoint = mutateData?.startPoint.useYn === 'Y';
  const endPoint = mutateData?.endPoint.useYn === 'Y';

  // 전체 길이 및 반경 길이 계산 함수
  const getLength = () => {
    const startLength = mutateData?.startPoint.length ?? 0;
    const endLength = mutateData?.endPoint.length ?? 0;
    return startLength + endLength;
  };
  const getRLength = () => {
    const startRLength = mutateData?.startPoint.rLength ?? 0;
    const endRLength = mutateData?.endPoint.rLength ?? 0;
    return startRLength + endRLength;
  };

  // 전체 진행률 계산
  const totalLengthToPercentage = getLength() !== 0 ? ((getRLength() / getLength()) * 100).toFixed(0) : '0';

  const renderPoint = () => {
    return (
      <>
        {startPoint && <MultiTunnelPoint data={mutateData?.startPoint} filter={filter} onClick={onClickTunnelChip} />}
        {endPoint && <MultiTunnelPoint data={mutateData?.endPoint} filter={filter} onClick={onClickTunnelChip} />}
      </>
    );
  };

  const onClickTunnelInfo = (tareaParam: any, innerTabIndex: number, pointType?: 'S' | 'E') => {
    const findIndex = tunnelRawDataList.findIndex((el: TareaData) => el.tatCd === tareaParam.tatCd);
    if (findIndex !== -1) {
      setOpenModal((prev: any) => ({ ...prev, status: true, type: 'tareaDetail', data: tareaParam, index: findIndex, innerTabIndex, pointType }));
    }
  };

  const onClickAttendChip = (e: React.MouseEvent<HTMLDivElement>, param: number) => {
    e.stopPropagation();
    onClickTunnelInfo(tunnelData, param);
  };

  const onClickTunnelChip = (param: number, pointType: 'S' | 'E') => {
    onClickTunnelInfo(tunnelData, param, pointType);
  };

  return (
    <MultiTunnelContainer className={`${getSize()}`}>
      <div className='card' role='presentation' onClick={() => onClickTunnelInfo(tunnelRawData, TAREA_INNERTAB_NORMAL)}>
        <div className='titleName'>{tatName ?? ''}</div>
        <div className='chart'>
          <HalfDoughnutChart value={100} />
          <HalfDoughnutChart value={(getRLength() / getLength()) * 100} background />
          <div className='percentage'>
            {totalLengthToPercentage}
            <span className='symbol'>%</span>
          </div>
          <span className='title'>전체 진행률</span>
        </div>
        <div className='attendCount'>
          <TunnelChipView
            value={attendCount ?? 0}
            icon='familiar_face_and_zone'
            size='x-small'
            onClick={(e: React.MouseEvent<HTMLDivElement>) => onClickAttendChip(e, TAREA_INNERTAB_REALTIME_ATTEND)}
          />
        </div>
      </div>
      <div className='contentBox'>{renderPoint()}</div>
      <Portal openModal={openModal?.status}>
        {openModal.type === 'tareaDetail' && (
          <TareaInfoModal
            setOpenModal={setOpenModal}
            auth={auth}
            activeTab={openModal.innerTabIndex}
            data={openModal.data}
            dataList={tunnelRawDataList}
            index={openModal.index}
            showPrevNextBtn={false}
            pointType={openModal.pointType}
          />
        )}
      </Portal>
    </MultiTunnelContainer>
  );
};

export default MultiTunnel;
