/*
 * 작성자 : 김광민
 * 날짜 : 2024.07.04
 * 기능 : 상황판에서 가로 사이즈 5 기상종합 컴포넌트
 */

import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { apiGet } from '../../services/_common';
import { weatherWeekGet } from '../../services/weather';
import styled from 'styled-components';
import WeatherWideNow from './WeatherWideNow';
import WeatherWideWeek from './WeatherWideWeek';
import WeatherWideTime from './WeatherWideTime';

const Root = styled.div`
  height: inherit;
  display: grid !important;
  grid-template-columns: repeat(3, 1fr) !important;
  overflow: hidden;
  > div {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
`;

// 6일간의 날씨 데이터를 초기화
const days = Array.from({ length: 6 }).map((_, index) => {
  const targetDay = dayjs().add(index + 1, 'day'); // +1 to start from tomorrow
  return {
    order: index,
    date: targetDay.date(),
    day: targetDay.format('ddd'),
    sky: '',
  };
});

// 현재 시간에 따른 상태 반환
const getTimeStatus = (): string => {
  const currentHour = dayjs().hour();
  if (currentHour >= 0 && currentHour < 6) return 'morning';
  if (currentHour >= 6 && currentHour < 18) return 'afternoon';
  return 'evening';
};

const WeatherWide = ({ userInfo, isRefreshTimeExpired, width }: any) => {
  const { hCd, sCd } = userInfo;
  const [geoInfo, setGeoInfo] = useState({ latitude: '', longitude: '', address: '' });

  // 로컬 스토리지에서 데이터 가져오기
  const after6DaysLocalStorage = window.localStorage.getItem('after6Days');
  const currentWeatherLocalStorage = window.localStorage.getItem('currentWeatherWide');
  const timeWeatherLocalStorage = window.localStorage.getItem('localeTimeWeather');

  const localAfter6Days = after6DaysLocalStorage //
    ? JSON.parse(after6DaysLocalStorage)
    : days;

  const localCurrentWeather = currentWeatherLocalStorage //
    ? JSON.parse(currentWeatherLocalStorage)
    : { wsd: '', tmp: '', minTemp: '', maxTemp: '', rainPct: '', sky: '', pm10: '', pm25: '' };

  const localTimeWeather = timeWeatherLocalStorage //
    ? JSON.parse(timeWeatherLocalStorage)
    : [];

  const [after6Days, setAfter6Days] = useState<IDays[]>(localAfter6Days);
  const [currentWeather, setCurrentWeather] = useState(localCurrentWeather);
  const [timeWeather, setTimeWeather] = useState(localTimeWeather);

  // 사이트 정보 쿼리 설정
  const siteInfo = {
    path: '/site/info',
    dependencies: [hCd, sCd, isRefreshTimeExpired],
    req: { hCd, sCd },
    options: {
      enabled: !!hCd && !!sCd && sCd !== '00000',
    },
  };

  const siteInfoQuery = useQuery(
    [siteInfo.path, ...siteInfo.dependencies], //
    () => apiGet({ path: siteInfo.path, req: siteInfo.req }),
    siteInfo.options
  );

  // 날씨 쿼리 옵션 설정
  const weatherQueryOptions = {
    retry: 3,
    enabled: geoInfo.latitude !== '' && geoInfo.longitude !== '' && geoInfo.address !== '',
  };

  const weekWeatherQuery = useQuery(
    ['weekWeather', geoInfo], //
    () => weatherWeekGet({ latitude: geoInfo.latitude, longitude: geoInfo.longitude, address: geoInfo.address }),
    weatherQueryOptions
  );

  const timeWeatherPath = `/weather/time?latitude=${geoInfo.latitude}&longitude=${geoInfo.longitude}&address=${geoInfo.address}`;
  const timeWeatherQuery = useQuery(
    [timeWeatherPath, geoInfo], //
    () => apiGet({ path: timeWeatherPath }),
    weatherQueryOptions
  );

  // 사이트 정보 쿼리 결과 처리
  useEffect(() => {
    if (!siteInfoQuery.isLoading && siteInfoQuery.data) {
      if (siteInfoQuery.data.data.data) {
        const {
          siteInfo: { latitude, longitude, address1: address },
        } = siteInfoQuery.data.data.data;
        setGeoInfo({ latitude, longitude, address });
        const district = address.split(' ')[1];
        setCurrentWeather((prev: any) => ({ ...prev, district }));
      }
    }
  }, [siteInfoQuery.isLoading, siteInfoQuery.data]);

  // 주간 날씨 쿼리 결과 처리
  useEffect(() => {
    if (!weekWeatherQuery.isLoading && weekWeatherQuery.data) {
      if (weekWeatherQuery.data.data.data) {
        const {
          morPop,
          todayTmn,
          todayTmx,
          aftPop,
          dinPop,
          weekSky1,
          weekSky2,
          weekSky3,
          weekSky4,
          weekSky5,
          weekSky6,
          weekTmn1,
          weekTmn2,
          weekTmn3,
          weekTmn4,
          weekTmn5,
          weekTmn6,
          weekTmx1,
          weekTmx2,
          weekTmx3,
          weekTmx4,
          weekTmx5,
          weekTmx6,
        } = weekWeatherQuery.data.data.data;
        const status = getTimeStatus();
        setCurrentWeather((prev: any) => ({
          ...prev,
          minTemp: todayTmn,
          maxTemp: todayTmx,
          rainPct: status === 'morning' ? morPop : status === 'evening' ? dinPop : aftPop,
        }));

        const updatedDays = after6Days.map((el: IDays, i: number) => {
          const targetDay = dayjs().add(i + 1, 'day'); // +1 to start from tomorrow
          return {
            ...el,
            date: targetDay.date(),
            day: targetDay.format('ddd'),
            sky: i === 0 ? weekSky1 : i === 1 ? weekSky2 : i === 2 ? weekSky3 : i === 3 ? weekSky4 : i === 4 ? weekSky5 : weekSky6,
            minTemp: i === 0 ? weekTmn1 : i === 1 ? weekTmn2 : i === 2 ? weekTmn3 : i === 3 ? weekTmn4 : i === 4 ? weekTmn5 : weekTmn6,
            maxTemp: i === 0 ? weekTmx1 : i === 1 ? weekTmx2 : i === 2 ? weekTmx3 : i === 3 ? weekTmx4 : i === 4 ? weekTmx5 : weekTmx6,
          };
        });

        setAfter6Days(updatedDays);
      }
    }
  }, [weekWeatherQuery.isLoading, weekWeatherQuery.data]);

  // 시간별 날씨 쿼리 결과 처리
  useEffect(() => {
    if (!timeWeatherQuery.isLoading && timeWeatherQuery.data) {
      if (timeWeatherQuery.data.data.data) {
        const { pm10, pm25, weatherList } = timeWeatherQuery.data.data.data;
        const current = weatherList[0];
        setCurrentWeather((prev: any) => {
          const { wsd, tmp, sky } = current;
          return { ...prev, sky, wsd, tmp, pm10, pm25 };
        });
        setTimeWeather(weatherList.filter((_: any, i: number) => i !== 0));
      }
    }
  }, [timeWeatherQuery.isLoading, timeWeatherQuery.data]);

  return (
    <Root>
      <WeatherWideNow //
        currentWeather={currentWeather}
        width={width}
      />
      <WeatherWideTime //
        timeWeather={timeWeather}
        width={width}
      />
      <WeatherWideWeek //
        after6Days={after6Days}
        width={width}
      />
    </Root>
  );
};

export default WeatherWide;
