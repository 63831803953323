/**
 * 작성자 : 홍선영
 * 날짜 : 2023.12.18
 * 경로 : 설정관리 > 방송 장비 관리 > 방송 장비 정보
 */

import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { PulseLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { IoChevronUpSharp } from 'react-icons/io5';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

import i18n from '../../../translation/i18n';
import { userState } from '../../../atoms';
import { IComCdList } from 'customTypes';
import { BS_GUBUN, COMCD_USE_YN, INIT_USE_YN_A, INIT_USE_YN_Y, PROTOCOL } from '../../../_constants';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { ynFilter } from '../../../utils/ynFilter';
import { trimObject } from '../../../utils/trimObject';
import { formatDate } from '../../../utils/formatDate';
import { useSetAuth } from '../../../utils/useSetAuth';
import { scrollToNodeTop } from '../../../utils/scrollToNodeTop';
import { applyBorderStyle } from '../../../utils/applyBorderStyle';
import { setComCdListState } from '../../../utils/setComCdListState';
import { useDetectScrolledToBottom } from '../../../utils/useDetectScrolledToBottom';
import { BtnBlue, BtnGhost, BtnGreen, BtnRed } from '../../../components/Button';
import Input from '../../../components/Input';
import Portal from '../../../components/Portal';
import TuiGrid from '../../../components/Table/TuiGrid';
import SelectBox from '../../../components/SelectBox';
import PointIcon from '../../../components/tunnel/PointIcon';
import IssueGuide from '../../../components/IssueGuide';
import BackButton from '../../../components/BackButton';
import DeleteModal from '../../../components/Modal/DeleteModal2';
import SearchSelectBoxSm from '../../../components/SearchSelectBoxSm';
import BroadcastEquipModal from '../../../components/Modal/BroadcastEquipModal';
import { logPost } from '../../../services/log';
import { apiGet, apiPost } from '../../../services/_common';
import { useFetchCommonCodeList } from '../../../services/useSetCodeListInSelectBoxForm';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import { TuiGridTwoColumnStyle } from '../../../assets/styles/TuiGridTwoColumnStyle';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  padding: 0.5rem;
  > div {
    gap: 0.5rem;
  }
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;

const SubRoot = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  overflow: auto;
  justify-content: flex-start;

  .emptyData {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    padding-bottom: 4rem;
    gap: 1rem;
    img {
      width: 24rem;
      /* user-drag: none; */
      -webkit-user-drag: none;
    }
    span {
      color: ${({ theme }: { theme: any }) => theme.filled_violet};
    }
  }

  .inputForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    width: 100%;
    button {
      height: 2.5rem;
      font-size: 0.75rem;
      flex-shrink: 0;
      padding: 0 0.75rem;
      border-radius: 0.25rem;
    }
    button.gray {
      font-weight: 500;
      color: ${({ theme }: { theme: any }) => theme.color.zinc_200};
    }
    label {
      width: 6rem;
      font-weight: 500;
      flex-shrink: 0;
      font-size: 0.875rem;
    }
    .inputForm-group-1280 {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (min-width: 1280px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
      }
    }
    .inputForm-group-1536 {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (min-width: 1536px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
      }
    }
    .inputForm-group-1536.withBtn {
      flex-direction: row;
      gap: 0.5rem;
      button {
        transition: none;
      }
      @media (min-width: 1536px) {
        gap: 2rem;
        button {
          margin-left: 0;
          width: fit-content;
        }
      }
    }
    .inputForm-group-1536.withBtn.inputColumnFour {
      input {
        padding: 0;
      }
    }
    .inputForm-row.labelInInput {
      display: flex;
      gap: 0.5rem;
      flex-grow: 1;
      > div {
        flex-grow: 1;
      }
    }
    .inputForm-row.labelOutInput,
    .inputForm-row.labelInInput > div > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      input {
        flex-shrink: 1;
        height: 2.5rem;
        font-size: 0.875rem;
        padding: 0 0.5rem;
        &:disabled {
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          color: ${({ theme }: { theme: any }) => theme.text_disabled};
        }
      }
      > div.viewOnly {
        height: 2.5rem;
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_primary};
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 0.25rem;
        padding: 0 0.75rem;
        display: flex;
        align-items: center;
      }
      > div {
        flex-grow: 1;
        > div > div > ul {
          height: 2.5rem;
          li {
            display: flex;
            width: 100%;
            max-width: 100%;
            span {
              flex-grow: 1;
              width: 4rem;
            }
          }
        }
      }
    }
    .detailInfo-group {
      margin: 1rem 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      @media (min-width: 1536px) {
        grid-template-columns: repeat(3, 1fr);
      }
      .flex-col.detailInfo {
        flex-grow: 1;
        width: 100%;
        div {
          height: 2.5rem;
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          border-radius: 0.25rem;
          display: flex;
          align-items: center;
          padding: 0 0.5rem;
          font-size: 0.875rem;
        }
      }
    }
  }
  input {
    :disabled {
      background-color: #eeeeee;
    }
  }
  .required {
    &::after {
      content: '*';
      padding: 0 0.2rem;
      color: red;
    }
  }

  @media screen and (max-width: 1023px) {
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: fit-content;
    overflow: none;
  }
`;

const TunnelPoint = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.25rem;
  width: fit-content;
  margin: 0 auto;
  padding: 0.5rem 0.75rem;
  border-radius: 1rem;
  background-color: ${({ theme }: { theme: any }) => theme.tonal};
  cursor: pointer;
  &.start {
    color: ${({ theme }: { theme: any }) => theme.filled_blue};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
  }

  &.end {
    color: ${({ theme }: { theme: any }) => theme.filled_amber};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
  }
`;

interface IBs {
  bsCd: string;
  bsName: string;
  bigo: string;
  useYn: string;
}

const BroadcastSetting = () => {
  const userInfo = useRecoilValue(userState);
  const emptyRowState = {
    hCd: userInfo.hCd,
    sCd: userInfo.sCd,
    editor: userInfo.userId,
    bsCd: '',
    bsGubun: '01',
    bsName: '',
    bsIp: '',
    bsPort: '',
    protocol: 'HTTP',
    pPort: '80',
    sPort: '',
    bsId: '',
    bsPwd: '',
    nCd: '',
    cCd: '',
    bigo: '',
    useYn: 'Y',
    delYn: 'N',
  };
  const BROADCAST_EQUIP_SETTING = 0; // 방송 장비 설정
  const TUNNEL_SETTING_INFO = 1; // 터널 설치 정보
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const size = useOutletContext<any>();
  const [viewTable, setViewTable] = useState<boolean>(true);
  const node = useRef<any>(null);
  const userInfoInputFormRef = useRef<any>(null); // 인풋 폼 ref (테이블 로우를 클릭했을 때 바로 inputForm 으로 스크롤 이동시키기 위한 ref)
  const { isBottom } = useDetectScrolledToBottom(userInfoInputFormRef); // 스크롤이 해당노드의 하단에 근접했는지 여부
  const INIT_PROTOCOL = { type: 'protocol', protocol: 'HTTP', cdName: 'HTTP' };
  const INIT_BS_GUBUN = { type: BS_GUBUN, [BS_GUBUN]: '01', cdName: 'HIKVISION' };
  const [searchOptionUseYn, setSearchOptionUseYn] = useState(INIT_USE_YN_A);
  const [bsGubunComCdList, setBsGubunComCdList] = useState<IComCdList[]>([]); // nvr구분코드 목록
  const [searchOption, setSearchOption] = useState({ searchBsName: '' });
  const componentRef = useRef<HTMLDivElement>(null);
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  const [columns, setColumns] = useState<any[]>([]);
  const [selectedRowKey, setSelectedRowKey] = useState<any>(null);
  const [isNewAdd, setIsNewAdd] = useState(true); // 신규등록 여부
  const [activeTab, setActiveTab] = useState(BROADCAST_EQUIP_SETTING); // 하위메뉴의 내부탭 액티브상태값
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [rowState, setRowState] = useState<any>(emptyRowState);
  const [protocol, setProtocol] = useState(INIT_PROTOCOL);
  const [bsGubun, setBsGubun] = useState(INIT_BS_GUBUN);
  const [nCd, setnCd] = useState({ type: 'nCd', nCd: '', cdName: '' });
  const [cCd, setcCd] = useState({ type: 'cCd', cCd: '', cdName: '' });
  const [taread, setTaread] = useState({ bsCd: '', tatName: '', tatGubun: '', tadName: '', useYn: '', delYn: '' });
  const [useYn, setUseYn] = useState(INIT_USE_YN_Y);
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '', title: '' });
  const [nCdList, setnCdList] = useState<any[]>([]);
  const [cCdList, setcCdList] = useState<any[]>([]);
  const { data: useYnComCdListWithAll } = useFetchCommonCodeList(COMCD_USE_YN, true); // 사용여부 공통코드 목록 (전체포함)
  const { data: useYnComCdList } = useFetchCommonCodeList(COMCD_USE_YN, false); // 사용유무 공통코드 목록

  const bsNameRef = useRef<HTMLInputElement>(null);
  const bsIpRef = useRef<HTMLInputElement>(null);
  const bsIdRef = useRef<HTMLInputElement>(null);
  const bsPwdRef = useRef<HTMLInputElement>(null);

  const {
    data: bsList,
    isLoading,
    isFetching,
    isError,
  } = useQuery(['bsList', userInfo], () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && userInfo.sCd !== '00000',
  });

  const [tableState, setTableState] = useState<IBs[]>(bsList?.map((el: IBs) => ({ ...el, useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용') })) || []);
  const [initTableState, setInitTableState] = useState<IBs[]>(bsList?.map((el: IBs) => ({ ...el, useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용') })) || []);

  const camNvrQuery = useQuery(['camNvrGet', userInfo.hCd, userInfo.sCd], () => apiGet({ path: '/cam/nvr', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, nCd: '001', cCd: '01' } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && activeTab === BROADCAST_EQUIP_SETTING,
  });

  const camCameraQuery = useQuery(['camCameraGet', userInfo.hCd, userInfo.sCd, nCd.nCd], () => apiGet({ path: '/cam/camera', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, nCd: nCd.nCd } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && activeTab === BROADCAST_EQUIP_SETTING && !!nCd.nCd,
  });

  // 방송장비 목록 데이터패치 함수
  const fetchData = async () => {
    try {
      const res = await apiGet({ path: '/bs', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } });
      const result = res.data.data;
      const newArray = result?.map((el: IBs) => ({ ...el, useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용') }));
      setTableState(newArray);
      setInitTableState(newArray);
      return result;
    } catch (error) {
      console.error('error', error);
      throw new Error('error');
    }
  };

  // NVR 목록 데이터패치 함수
  useEffect(() => {
    const fetchNvrData = async () => {
      if (camNvrQuery.isSuccess && camNvrQuery.data.status === 200) {
        const { nvrList } = camNvrQuery.data.data.data;
        const newArray = nvrList.filter((el: any) => el.useYn === 'Y').map((nvr: any) => ({ type: 'nCd', nCd: nvr.nCd, cdName: nvr.nName }));
        setnCdList([{ type: 'nCd', nCd: '', cdName: t('미선택') }, ...newArray]);
      }
    };

    fetchNvrData().catch(console.error);
  }, [camNvrQuery.isSuccess, camNvrQuery.isRefetching]);

  // 카메라 목록 데이터패치 함수
  useEffect(() => {
    const fetchCameraData = async () => {
      if (camCameraQuery.isSuccess && camCameraQuery.data.status === 200) {
        const { cameraList } = camCameraQuery.data.data.data;
        const newArray = cameraList.filter((el: any) => el.useYn === 'Y').map((el: any) => ({ type: 'cCd', cCd: el.cCd, cdName: el.cName }));
        setcCdList([{ type: 'cCd', cCd: '', cdName: t('미선택') }, ...newArray]);
      }
    };

    fetchCameraData().catch(console.error);
  }, [camCameraQuery.isSuccess, camCameraQuery.isRefetching, nCd.nCd]);

  useEffect(() => {
    if (rowState.nCd !== nCd.nCd) setcCd({ type: 'cCd', cCd: '', cdName: t('미선택') });
  }, [nCd.nCd]);

  useEffect(() => {
    setComCdListState(BS_GUBUN, setBsGubunComCdList, false);
    setSearchOptionUseYn(INIT_USE_YN_A);

    setColumns([
      { header: t('코드'), name: 'bsCd', align: 'center', sortable: true, width: 80, renderer: { classNames: ['text_secondary', 'font_semibold'] } },
      { header: t('방송 스피커 명'), name: 'bsName', sortable: true, minWidth: 200, renderer: { classNames: ['text_secondary', 'font_semibold'] } },
      { header: t('비고'), name: 'bigo', sortable: true, minWidth: 120 },
      { header: t('사용유무'), name: 'useYnCdName', align: 'center', sortable: true, minWidth: 100 },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    if (componentRef.current !== null) setTuiHeight(componentRef.current.offsetHeight);
  }, [componentRef.current?.offsetHeight, componentRef.current?.offsetWidth]);

  useEffect(() => {
    applyFilter(initTableState);
  }, [searchOption.searchBsName, searchOptionUseYn[COMCD_USE_YN]]);

  const applyFilter = (array: any[]) => {
    // 필터링된 어레이 리턴, 대소문자구분X
    const filteredArray = array.filter((item: any) => item.bsName?.toLowerCase()?.includes(searchOption.searchBsName?.toLowerCase()));
    const result = ynFilter(filteredArray, 'useYn', searchOptionUseYn[COMCD_USE_YN]);

    if (result.length > 0) setTableState(result.map((el: any, i) => ({ ...el, rowKey: i, sortKey: i })));
    else setTableState([]);
  };

  // 초기화 버튼 클릭. 옵션, 인풋창 초기화
  const onClickInitiateSearchOption = () => {
    setSearchOption({ searchBsName: '' });
    setSearchOptionUseYn(INIT_USE_YN_A);
    onClickNewAdd(); // 우측 인풋창도 초기화
    setSelectedRowKey(null);
  };

  // 신규등록 버튼 클릭해서 새 입력창(iputForm) 노출
  const onClickNewAdd = () => {
    setIsNewAdd(true);
    initiateState();
    setActiveTab(BROADCAST_EQUIP_SETTING);
    if (size.innerSize.W < 1024) setViewTable(false);
  };

  // 스테이트 객체의 밸류를 ''로 초기화
  const initiateState = () => {
    setIsSaveClicked(false);
    setSelectedRowKey(null);
    setRowState(emptyRowState);
    setnCd({ type: 'nCd', nCd: '', cdName: t('미선택') });
    setcCd({ type: 'cCd', cCd: '', cdName: t('미선택') });
    setUseYn(INIT_USE_YN_Y);
    setBsGubun(INIT_BS_GUBUN);
    setProtocol(INIT_PROTOCOL);
  };

  // Row 클릭
  const onClickRow = (rowData: any) => {
    if (rowData) {
      const { rowKey, rowSpanMap, sortKey, uniqueKey, _attributes, _disabledPriority, _relationListItemMap, ...newRowData } = rowData;
      setRowState(newRowData);
      setSelectedRowKey(rowData.rowKey);
      setIsNewAdd(false);
      setIsSaveClicked(false);
      setViewTable(false);
      setnCd({ type: 'nCd', nCd: rowData.nCd, cdName: '' });
      setcCd({ type: 'cCd', cCd: rowData.cCd, cdName: '' });
      setUseYn({ type: COMCD_USE_YN, [COMCD_USE_YN]: rowData.useYn, cdName: rowData.useYn === 'Y' ? t('사용') : t('미사용') });
      setBsGubun({ type: BS_GUBUN, [BS_GUBUN]: rowData.bsGubun, cdName: bsGubunComCdList.find((v: any) => v.BS_GUBUN === rowData.bsGubun)?.cdName || '' });
      setProtocol({ type: 'protocol', protocol: rowData.protocol, cdName: rowData.protocol });

      if (rowData.bsCd) getBsDetail(rowData.bsCd);
    }
  };

  const getBsDetail = (bsCdParam: string) => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, bsCd: bsCdParam };

    // 터널 상세 구역 정보 조회 (스피커)
    apiGet({ path: '/bs/d', req }).then((res: any) => {
      if (res.data.statusCode === 200) {
        if (res.data.data.tareaD) {
          setTaread(res.data.data.tareaD);
        } else {
          setTaread({ bsCd: '', tatName: '', tatGubun: '', tadName: '', useYn: '', delYn: '' });
        }
      }
    });
  };

  const backToMain = () => {
    setViewTable(true);
    setSelectedRowKey(null);
  };

  const deleteAPI = async () => {
    const req = {
      ...rowState,
      delYn: 'Y',
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      editor: userInfo.userId,
    };
    delete req.wDate;
    delete req.eDate;
    delete req.useYnCdName;
    const res = await apiPost({ path: '/bs', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      const newArray = data?.map((el: any) => ({ ...el, useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용') }));

      applyFilter(newArray);
      setInitTableState(newArray);
      initiateState();
      setSelectedRowKey(null);
      toast.success(t('방송 장비 삭제 성공'));

      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '방송장비 정보',
        action: '삭제',
        etc: `${rowState.bsName}(${rowState.bsCd})`,
      });
    }
  };

  const onClickDelete = () => {
    setOpenModal((prev: any) => ({ ...prev, status: true, type: 'delete', api: () => deleteAPI() }));
  };

  const onClickSave = (activeTabParam: number) => {
    if (activeTabParam === 0) {
      // 방송 장비 설정 탭인 경우
      const newRowState = {
        ...rowState,
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        editor: userInfo.userId,
        useYn: useYn[COMCD_USE_YN],
        protocol: protocol.protocol,
        bsGubun: bsGubun[BS_GUBUN],
        nCd: nCd.nCd,
        cCd: cCd.cCd,
      };
      delete newRowState.wDate;
      delete newRowState.eDate;
      delete newRowState.useYnCdName;
      const req = trimObject(newRowState);
      setIsSaveClicked(true);
      if (!req.bsName) {
        bsNameRef.current?.focus();
        return toast.warning(t('스피커 명을 입력하세요'));
      }
      if (!req.bsIp) {
        bsIpRef.current?.focus();
        return toast.warning(t('접속 주소를 입력하세요'));
      }
      if (!req.bsId) {
        bsIdRef.current?.focus();
        return toast.warning(t('아이디를 입력하세요'));
      }
      if (!req.bsPwd) {
        bsPwdRef.current?.focus();
        return toast.warning(t('비밀번호를 입력하세요'));
      }
      return postAPI(req);
    }
    return null;
  };

  const postAPI = async (reqParam: any) => {
    const res = await apiPost({ path: '/bs', req: reqParam });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      const newArray = data?.map((el: any) => ({ ...el, useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용') }));

      if (reqParam.bsCd) {
        // 기존데이터 수정 시 해당객체 찾아서 rowState에 업데이트
        const find = data.find((el: any) => el.bsCd === reqParam.bsCd);
        setRowState(find);
      } else {
        // 신규데이터 저장 시 해당객체 rowState에 업데이트
        setRowState(data[data.length - 1]);
        setSelectedRowKey(tableState.length);
        setIsNewAdd(false);
      }

      applyFilter(newArray);
      setInitTableState(newArray);
      toast.success(t(message));
    }
  };

  const onClickPreview = () => {
    setOpenModal((prev: any) => ({ ...prev, status: true, type: 'broadcast', data: [{ ...rowState, ...taread }] }));
  };

  if (isError) return <IssueGuide />;
  if (isLoading || isFetching)
    return (
      <LoadingModalBackground>
        <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
      </LoadingModalBackground>
    );

  return (
    <div className={`content-container ${size.innerSize.W >= 1024 ? 'twoColumn column-55 max800' : 'oneColumn'}`}>
      <TuiGridTwoColumnStyle className={size.innerSize.W >= 1024 || viewTable ? 'showRoot' : 'hideRoot'}>
        <SearchOptions align='left'>
          {isBottom && (
            <div className='floatingBtnWrapper flex-center'>
              <button type='button' onClick={() => scrollToNodeTop(node)}>
                <IoChevronUpSharp size={20} style={{ stroke: 'white' }} />
              </button>
            </div>
          )}
          <div className='inputsWrapper'>
            <div className='inputForm-row'>
              <div className='inputForm-col withLabelComCf'>
                <label htmlFor='useYn'>{t('사용유무')}</label>
                <SelectBox
                  options={useYnComCdListWithAll}
                  defaultOption={searchOptionUseYn.cdName}
                  state={searchOptionUseYn}
                  setState={setSearchOptionUseYn}
                  stateKey={COMCD_USE_YN}
                  initiateKey={searchOptionUseYn[COMCD_USE_YN]}
                />
              </div>
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col '>
                <Input placeholder={t('스피커 명')} label='' type='text' id='searchBsName' name='searchBsName' state={searchOption} setState={setSearchOption} />
              </div>
            </div>
          </div>
          <div className='inputsWrapper'>
            <div className='secondSearchOption'>
              <div className='flex-basic textBtnGroup'>
                <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
              </div>
              <div className='flex-basic iconBtnGroup'>
                <BtnGhost onClick={onClickNewAdd}>
                  <span className='material-symbols-rounded'>add</span>
                  {t('등록')}
                </BtnGhost>
              </div>
            </div>
          </div>
        </SearchOptions>
        <div ref={componentRef} className='tui-container'>
          <TuiGrid data={tableState} columns={columns} perPage={15} rowKey={selectedRowKey} onClickRow={onClickRow} frozenCount={1} height={tuiHeight} />
        </div>
      </TuiGridTwoColumnStyle>
      {(size.innerSize.W >= 1024 || !viewTable) && (
        <TuiGridTwoColumnStyle>
          {isNewAdd ? (
            <div className='inputFormsWrapper flexRowEm'>
              {size.innerSize.W < 1024 && <BackButton func={() => backToMain()} />}
              <div className='formTitle'>{t('방송 장비 등록')}</div>
            </div>
          ) : (
            <div className='inputFormsWrapper smallTab'>
              {size.innerSize.W < 1024 && <BackButton func={() => backToMain()} />}
              <div className={`tab ${activeTab === BROADCAST_EQUIP_SETTING ? 'activeTab' : undefined}`} role='button' tabIndex={0} onClick={() => setActiveTab(BROADCAST_EQUIP_SETTING)}>
                {t('방송 장비 설정')}
              </div>
              <div className={`tab ${activeTab === TUNNEL_SETTING_INFO ? 'activeTab' : undefined}`} role='button' tabIndex={0} onClick={() => setActiveTab(TUNNEL_SETTING_INFO)}>
                {t('터널 설치 정보')}
              </div>
            </div>
          )}

          {activeTab === BROADCAST_EQUIP_SETTING && (
            <SubRoot>
              <div className='inputForm'>
                <div className='inputForm-group-1536'>
                  {!isNewAdd && (
                    <div className='inputForm-row labelOutInput'>
                      <label htmlFor='code'>{t('코드')}</label>
                      <div className='viewOnly'>{rowState.bsCd}</div>
                    </div>
                  )}
                  <div className='inputForm-row labelOutInput'>
                    <label htmlFor='nvrGubun'>{t('제조사')}</label>
                    <div>
                      <SelectBox options={bsGubunComCdList} defaultOption={bsGubun.cdName} state={bsGubun} setState={setBsGubun} stateKey={BS_GUBUN} initiateKey={bsGubun[BS_GUBUN]} />
                    </div>
                  </div>
                </div>
                <div className='inputForm-group-1536'>
                  <div className='inputForm-row labelInInput'>
                    <Input
                      className='required'
                      label={t('스피커 명')}
                      type='text'
                      id='bsName'
                      name='bsName'
                      state={rowState}
                      setState={setRowState}
                      disabled={!auth.updateAuth}
                      inputRef={bsNameRef}
                      getBorderStyle={isSaveClicked ? applyBorderStyle(rowState.bsName, 'red', 'bsName') : undefined}
                    />
                  </div>
                  <div className='inputForm-row labelOutInput'>
                    <label htmlFor='useYn'>{t('사용유무')}</label>
                    <div>
                      <SelectBox options={useYnComCdList} defaultOption={useYn.cdName || t('사용')} state={useYn} setState={setUseYn} stateKey={COMCD_USE_YN} initiateKey={useYn[COMCD_USE_YN]} />
                    </div>
                  </div>
                </div>
                <div className='inputForm-group-1536'>
                  <div className='inputForm-row labelOutInput'>
                    <label htmlFor='bsIp' className='required'>
                      {t('접속 주소')}
                    </label>
                    <Input
                      type='text'
                      id='bsIp'
                      name='bsIp'
                      state={rowState}
                      setState={setRowState}
                      disabled={!auth.updateAuth}
                      inputRef={bsIpRef}
                      getBorderStyle={isSaveClicked ? applyBorderStyle(rowState.bsIp || '', 'red', 'bsIp') : undefined}
                    />
                  </div>
                  <div className='inputForm-row labelOutInput'>
                    <label htmlFor='bsPort'>{t('RTSP 포트번호')}</label>
                    <Input type='number' id='bsPort' name='bsPort' state={rowState} setState={setRowState} trim disabled={!auth.updateAuth} />
                  </div>
                </div>
                <div className='inputForm-group-1536'>
                  <div className='inputForm-row labelOutInput'>
                    <label htmlFor='protocol'>{t('연결 방식')}</label>
                    <SelectBox options={PROTOCOL} defaultOption={protocol.protocol} state={protocol} setState={setProtocol} stateKey='protocol' initiateKey={protocol.protocol} />
                  </div>
                  <div className='inputForm-row labelOutInput'>
                    <label htmlFor='pPort'>
                      {protocol.protocol} {t('포트번호')}
                    </label>
                    <Input type='number' id='pPort' name='pPort' state={rowState} setState={setRowState} trim disabled={!auth.updateAuth} />
                  </div>
                </div>
                <div className='inputForm-group-1536'>
                  <div className='inputForm-row labelOutInput'>
                    <label htmlFor='sPort'>{t('SERVER 포트번호')}</label>
                    <Input type='number' id='sPort' name='sPort' state={rowState} setState={setRowState} trim disabled={!auth.updateAuth} />
                  </div>
                </div>
                <div className='inputForm-group-1536'>
                  <div className='inputForm-row labelOutInput'>
                    <label htmlFor='bsId' className='required'>
                      {t('아이디')}
                    </label>
                    <Input
                      type='text'
                      id='bsId'
                      name='bsId'
                      state={rowState}
                      setState={setRowState}
                      trim
                      disabled={!auth.updateAuth}
                      inputRef={bsIdRef}
                      getBorderStyle={isSaveClicked ? applyBorderStyle(rowState.bsId || '', 'red', 'bsId') : undefined}
                    />
                  </div>
                  <div className='inputForm-row labelOutInput'>
                    <label htmlFor='password' className='required'>
                      {t('비밀번호')}
                    </label>
                    <Input
                      type='password'
                      id='popasswordrt'
                      name='bsPwd'
                      state={rowState}
                      setState={setRowState}
                      trim
                      disabled={!auth.updateAuth}
                      inputRef={bsPwdRef}
                      getBorderStyle={isSaveClicked ? applyBorderStyle(rowState.bsPwd || '', 'red', 'popasswordrt') : undefined}
                    />
                  </div>
                </div>
                <div className='inputForm-group-1536'>
                  <div className='inputForm-row labelOutInput'>
                    <label htmlFor='nCd'>{t('NVR 명')}</label>
                    <SearchSelectBoxSm
                      options={nCdList}
                      defaultOption={nCd.cdName || t('미선택')}
                      state={nCd}
                      setState={setnCd}
                      stateKey='nCd'
                      codeKey='cdName'
                      initiateKey={nCd.nCd}
                      filterbar='filter-1-left'
                      optionHeight='height-md'
                    />
                  </div>
                  <div className='inputForm-row labelOutInput'>
                    <label htmlFor='cCd'>{t('카메라 명')}</label>
                    <SearchSelectBoxSm
                      options={cCdList}
                      defaultOption={cCd.cdName || t('미선택')}
                      state={cCd}
                      setState={setcCd}
                      stateKey='cCd'
                      codeKey='cdName'
                      initiateKey={cCd.cCd}
                      filterbar='filter-1-left'
                      optionHeight='height-md'
                    />
                  </div>
                </div>
                <div className='inputForm-row labelInInput'>
                  <Input label={t('비고')} type='text' id='bigo' name='bigo' state={rowState} setState={setRowState} disabled={!auth.updateAuth} maxLength={200} />
                </div>
                {!isNewAdd ? (
                  <div className='detailInfo-group'>
                    <div className='flex-col detailInfo'>
                      <label htmlFor='createDate'>{t('등록일자')}</label>
                      <div>{formatDate(rowState?.wDate)}</div>
                    </div>
                    <div className='flex-col detailInfo'>
                      <label htmlFor='createUser'>{t('등록자')}</label>
                      <div>{rowState.writer}</div>
                    </div>

                    <div className='flex-col detailInfo'>
                      <label htmlFor='updateDate'>{t('수정일자')}</label>
                      <div>{formatDate(rowState.eDate)}</div>
                    </div>
                    <div className='flex-col detailInfo'>
                      <label htmlFor='updateUser'>{t('수정자')}</label>
                      <div>{rowState.editor}</div>
                    </div>
                  </div>
                ) : undefined}
              </div>
            </SubRoot>
          )}
          {activeTab === TUNNEL_SETTING_INFO && (
            <SubRoot>
              {taread.tatName ? (
                <div className='inputForm'>
                  <div className='inputForm-row labelOutInput'>
                    <label htmlFor='code'>{t('구역명')}</label>
                    <div>{taread.tatName}</div>
                  </div>
                  <div className='inputForm-row labelOutInput'>
                    <label htmlFor='nvrGubun'>{t('구분')}</label>
                    <div style={{ flexGrow: '0' }}>
                      {taread.tatGubun?.toLocaleLowerCase() === 's' ? (
                        <TunnelPoint className={taread.tatGubun?.toLocaleLowerCase() === 's' ? 'start' : ''}>
                          <PointIcon isStart />
                          <span>{t('시점')}</span>
                        </TunnelPoint>
                      ) : (
                        <TunnelPoint className={taread.tatGubun?.toLocaleLowerCase() === 'e' ? 'end' : ''}>
                          <PointIcon isStart={false} />
                          <span>{t('종점')}</span>
                        </TunnelPoint>
                      )}
                    </div>
                  </div>
                  <div className='inputForm-row labelOutInput'>
                    <label htmlFor='code'>{t('상세구역 명')}</label>
                    <div>{taread.tadName}</div>
                  </div>
                  <div className='inputForm-row labelOutInput'>
                    <label htmlFor='nvrGubun'>{t('운영여부')}</label>
                    <div>{taread.delYn === 'Y' ? t('삭제') : taread.useYn === 'Y' ? t('운영중') : t('미운영')}</div>
                  </div>
                </div>
              ) : (
                <IssueGuide />
              )}
            </SubRoot>
          )}
          <ButtonsWrapper className='flex-between'>
            {rowState.bsCd ? (
              <div className='flex-basic'>
                <BtnGreen onClick={onClickPreview}>{t('방송 테스트')}</BtnGreen>
              </div>
            ) : (
              <div> </div>
            )}
            <div className='flex-basic'>
              {!isNewAdd && auth.deleteAuth && <BtnRed onClick={onClickDelete}>{t('삭제')}</BtnRed>}
              {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={() => onClickSave(activeTab)}>{t('저장')}</BtnBlue>}
            </div>
          </ButtonsWrapper>
        </TuiGridTwoColumnStyle>
      )}
      <Portal openModal={openModal?.status}>
        {openModal.type === 'delete' && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}
        {openModal.type === 'broadcast' && <BroadcastEquipModal openModal={openModal} setOpenModal={setOpenModal} />}
      </Portal>
    </div>
  );
};

export default BroadcastSetting;
