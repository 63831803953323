import { useTranslation } from 'react-i18next';
import Input from '../../Input';
import { useEffect } from 'react';

interface Props {
  workerInputSet: any;
  setWorkerInputSet: any;
  propertyName: string;
  isViewMode: boolean;
  cdName: string;
}

const ThreeDigitNumberInput = ({ workerInputSet, setWorkerInputSet, propertyName, isViewMode, cdName }: Props) => {
  const { t } = useTranslation();
  const value = workerInputSet[propertyName];

  useEffect(() => {
    let newValue = value;
    // 첫 번째 문자(0)를 삭제한 새로운 문자열 생성
    if (value?.length === 2 && value[0] === '0') {
      newValue = value.substring(1);
    }
    // .이 포함되어 있는 경우 .도 제거하여 새로운 문자열 생성
    if (typeof value === 'string' && value?.includes('.')) {
      newValue = value.replace('.', '');
    }
    setWorkerInputSet({ ...workerInputSet, [propertyName]: newValue });
  }, [value]); // 상태가 변경될 때마다 useEffect 실행

  return (
    <div className='control input'>
      <Input
        type='text'
        label={t(cdName)} //
        name={propertyName && propertyName}
        state={workerInputSet}
        setState={setWorkerInputSet}
        disabled={isViewMode}
        placeholder='0'
        valueType='decimal'
        maxLength={3}
      />
    </div>
  );
};

export default ThreeDigitNumberInput;
