/**
 * 작성자 : 홍선영
 * 날짜 : 2023.12.06
 * 경로 : 설정관리 > 터널관리 > 구역정보
 */

import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

import { userState } from '../../../atoms';
import { IModal } from 'customTypes';
import { COMCD_USE_YN, ERROR, INIT_USE_YN_A, INIT_USE_YN_Y } from '../../../_constants';
import i18n from '../../../translation/i18n';
import { InputTable } from '../../../assets/styles/InputTable';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import illustrator from '../../../assets/images/illustration/304.svg';
import { BtnBlue, BtnGhost, BtnRed } from '../../../components/Button';
import Input from '../../../components/Input';
import Portal from '../../../components/Portal';
import SelectBox from '../../../components/SelectBox';
import PointIcon from '../../../components/tunnel/PointIcon';
import IssueGuide from '../../../components/IssueGuide';
import BackButton from '../../../components/BackButton';
import SelectBoxs from '../../../components/SelectBoxs';
import DeleteModal from '../../../components/Modal/DeleteModal2';
import SearchSelectBoxs from '../../../components/SearchSelectBoxs';
import { ynFilter } from '../../../utils/ynFilter';
import { trimObject } from '../../../utils/trimObject';
import { useSetAuth } from '../../../utils/useSetAuth';
import { applyBorderStyle } from '../../../utils/applyBorderStyle';
import { apiDelete, apiGet, apiPost } from '../../../services/_common';
import { trimArray } from '../../../utils/trimArray';
import { useFetchCommonCodeList } from '../../../services/useSetCodeListInSelectBoxForm';
import { logPost } from '../../../services/log';

interface ITArea {
  tatCd: string;
  tatName: string;
  cdSort: number;
  sUseYn: string;
  eUseYn: string;
  bigo: string;
  useYn: string;
}

interface ITAreaT {
  hCd: string;
  sCd: string;
  tatCd: string;
  tatName: string;
  sUseYn: string;
  sLength: number;
  sDvNo: string;
  eUseYn: string;
  eLength: number;
  eDvNo: string;
  tLength: string;
  tatFidCd: string;
  bigo: string;
  useYn: string;
  delYn: string;
  writer: string;
  wDate: string;
  editor: string;
  eDate: string;
}

const Root = styled.div`
  &.hideRoot {
    visibility: hidden;
    position: absolute;
  }
  .inputFormsWrapper.smallTab {
    flex-direction: row;
    align-items: center;
    padding: 0 0.5rem;
    font-weight: 500;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 0rem !important;
    }

    .tab {
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
      cursor: pointer;
      height: 3.5rem;
      border-bottom: 2px solid transparent;
    }
    .activeTab {
      font-weight: 700;
      border-bottom: 2px solid ${({ theme }: { theme: any }) => theme.selected_primary};
      color: ${({ theme }: { theme: any }) => theme.selected_primary};
    }
  }
  .emptyData {
    -webkit-box-flex: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    user-select: none;
    padding-bottom: 4rem;
    gap: 1rem;

    img {
      width: 14rem;
      /* user-drag: none; */
      -webkit-user-drag: none;
    }
    span {
      color: ${({ theme }: { theme: any }) => theme.filled_violet};
    }
  }
`;

const SubRoot = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  overflow: auto;
  justify-content: flex-start;
  > div {
    width: max-content;
  }

  .inputForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    width: 100%;
    button {
      height: 2.5rem;
      font-size: 0.75rem;
      flex-shrink: 0;
      padding: 0 0.75rem;
      border-radius: 0.25rem;
    }
    button.gray {
      font-weight: 500;
      color: ${({ theme }: { theme: any }) => theme.color.zinc_200};
    }
    label {
      width: 6rem;
      font-weight: 500;
      flex-shrink: 0;
      font-size: 0.875rem;
      text-wrap: wrap;
    }
    .inputForm-group-1280 {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (min-width: 1280px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
      }
    }
    .inputForm-group-1536 {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (min-width: 1536px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
      }
    }
    .inputForm-group-1536.withBtn {
      flex-direction: row;
      gap: 0.5rem;
      button {
        transition: none;
      }
      @media (min-width: 1536px) {
        gap: 2rem;
        button {
          margin-left: 0;
          width: fit-content;
        }
      }
    }
    .inputForm-group-1536.withBtn.inputColumnFour {
      input {
        padding: 0;
      }
    }
    .inputForm-row.labelInInput {
      display: flex;
      gap: 0.5rem;
      flex-grow: 1;
      > div {
        flex-grow: 1;
      }
    }
    .inputForm-row.labelOutInput,
    .inputForm-row.labelInInput > div > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      input {
        flex-shrink: 1;
        height: 2.5rem;
        font-size: 0.875rem;
        padding: 0 0.5rem;
        &:disabled {
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          color: ${({ theme }: { theme: any }) => theme.text_disabled};
        }
      }
      > div.viewOnly {
        height: 2.5rem;
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_primary};
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 0.25rem;
        padding: 0 0.75rem;
        display: flex;
        align-items: center;
      }
      > div {
        flex-grow: 1;
        > div > ul {
          height: 2.5rem;
          li {
            display: flex;
            width: 100%;
            max-width: 100%;
            span {
              flex-grow: 1;
              width: 4rem;
            }
          }
        }
      }
    }
    .detailInfo-group {
      margin: 1rem 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      @media (min-width: 1536px) {
        grid-template-columns: repeat(3, 1fr);
      }
      .flex-col.detailInfo {
        flex-grow: 1;
        width: 100%;
        div {
          height: 2.5rem;
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          border-radius: 0.25rem;
          display: flex;
          align-items: center;
          padding: 0 0.5rem;
          font-size: 0.875rem;
        }
      }
    }
  }
  input {
    :disabled {
      background-color: #eeeeee;
    }
  }
  .required {
    &::after {
      content: '*';
      padding: 0 0.2rem;
      color: red;
    }
  }

  .dotBox {
    gap: 1rem;
    border-radius: 0.475rem;
    padding: 1rem;
    &.blue {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
    }
    &.yellow {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
    }
  }

  @media screen and (max-width: 1023px) {
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: fit-content;
    overflow: none;
  }
`;

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 0.5rem;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;

const TunnelPoint = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.25rem;
  width: fit-content;
  margin: 0 auto;
  padding: 0.5rem 0.75rem;
  border-radius: 1rem;
  background-color: ${({ theme }: { theme: any }) => theme.tonal};
  cursor: pointer;
  &.start {
    color: ${({ theme }: { theme: any }) => theme.filled_blue};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
  }

  &.end {
    color: ${({ theme }: { theme: any }) => theme.filled_amber};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
  }
`;

const INFO = 0; // 구역 정보
const DETAIL_INFO = 1; // 상세구역 정보
const CCTV_INFO = 2; // CCTV 정보

const DraggableRow = ({
  type,
  key,
  el,
  auth,
  index,
  moveRow,
  tableState,
  setTableState,
  initTableState,
  setInitTableState,
  onClickRow,
  activeTab,
  options,
  setOpenModal,
  deleteAPI,
  isSaveClicked,
  selectedKey,
  bcsCdList,
  bsCdList,
}: any) => {
  const trRef = useRef(null);
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const newCdArray = tableState.map((elParam: any) => elParam.cdSort);
  const cdSortArray = newCdArray.sort((a: number, b: number) => a - b);

  const onClickDelete = () => {
    const tableArray = [...tableState];
    if (el.tadCd) {
      // 기존 로우 삭제 클릭시
      setOpenModal((prev: any) => ({ ...prev, status: true, type: 'delete', api: () => deleteAPI(el.tadCd) }));
    } else {
      // 새로 추가한 로우 제거 클릭시
      tableArray.splice(index, 1);
      setTableState(tableArray);
    }
  };

  const [, drop] = useDrop({
    accept: 'ROW',
    hover(item: any, monitor) {
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }
      moveRow(tableState, setTableState, dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop: (item, monitor) => {
      const copyArray = [...tableState];
      const newArray = copyArray.map((cpItem, cpIndex) => ({ ...cpItem, cdSort: cdSortArray[cpIndex], editor: userInfo.userId }));
      setTableState(newArray);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'ROW',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(trRef));

  const findBsName = (bsCdParam: string) => {
    const find = bsCdList?.find((bs: any) => bs.bsCd === bsCdParam);
    if (find) return find.cdName;
    return t('미선택');
  };

  const findBcsName = (bcsIdParam: string) => {
    const find = bcsCdList?.find((bcs: any) => bcs.bcsId === bcsIdParam);
    if (find) return find.cdName;
    return t('미선택');
  };

  return (
    <div ref={trRef} style={{ opacity: isDragging ? 0 : 1 }} role='presentation' onClick={() => onClickRow && onClickRow(el, index)}>
      <div className={`tr ${selectedKey && el.tatCd === selectedKey ? 'selected' : ''}`} key={key}>
        <div className='trCol2p5 flex-center tableStickyNo'>{index + 1}</div>
        {type === 'tatCd' ? (
          <>
            <div className='trCol4 flex-center'>{el.tatCd}</div>
            <div className='trCol10 flex-basic tableStickyTitle content-overflow'>{el.tatName}</div>
            <div className='trCol6 flex-center'>{el.sUseYn === 'Y' ? t('사용') : t('미사용')}</div>
            <div className='trCol6 flex-center'>{el.eUseYn === 'Y' ? t('사용') : t('미사용')}</div>
            <div className='trCol8 flex-basic'>{el.bigo}</div>
            <div className='trCol6 flex-center'>{el.useYn === 'Y' ? t('사용') : t('미사용')}</div>
          </>
        ) : (
          <>
            <div className='trCol4 flex-center'>{el.tadCd}</div>
            <div className='trCol10 flex-basic tableStickyTitle content-overflow'>
              <Input
                className='required'
                type='text'
                id='tadName'
                name='tadName'
                state={tableState}
                setState={setTableState}
                stateType={{ type: 'array', index }}
                getBorderStyle={isSaveClicked ? applyBorderStyle(el.tadName, 'red', 'tadName') : undefined}
              />
            </div>
            <div className='trCol10 flex-center selectBox'>
              <SearchSelectBoxs
                options={bcsCdList}
                defaultOption={findBcsName(tableState[index].bcsId) || t('미선택')}
                state={tableState}
                setState={setTableState}
                stateKey='bcsId'
                codeKey='cdName'
                index={index}
                useFlag={false}
                dropDownWidth='fit-content'
                optionHeight='height-sm'
                disabled={false}
                searchPlaceholder={t('영문키 변경 후 입력')}
                initiateKey={tableState}
              />
            </div>
            <div className='trCol10 flex-center selectBox'>
              <SearchSelectBoxs
                options={bsCdList}
                defaultOption={findBsName(tableState[index].bsCd) || t('미선택')}
                state={tableState}
                setState={setTableState}
                stateKey='bsCd'
                codeKey='cdName'
                index={index}
                useFlag={false}
                dropDownWidth='fit-content'
                optionHeight='height-sm'
                disabled={false}
                initiateKey={tableState}
              />
            </div>
            <div className='trCol8 flex-basic'>
              <Input className='required' type='text' id='bigo' name='bigo' state={tableState} setState={setTableState} stateType={{ type: 'array', index }} maxLength={200} />
            </div>
            <div className='trCol6 flex-center'>
              <SelectBoxs
                options={options}
                defaultOption={el.useYn === 'Y' ? t('사용') : t('미사용')}
                state={tableState}
                setState={setTableState}
                rawData={initTableState}
                setRawData={setInitTableState}
                stateKey='useYn'
                codeKey='cdName'
                index={index}
                object={el}
                primaryKey='tadCd'
                initiateKey={tableState}
              />
            </div>
            {auth.deleteAuth && (
              <div className='trCol4 flex-center'>
                <BtnRed onClick={onClickDelete}>{el.tadCd ? t('삭제') : t('제거')}</BtnRed>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const dragRow = (list: any[], setList: Dispatch<SetStateAction<any[]>>, fromIndex: number, toIndex: number) => {
  const updatedData = [...list];
  const [movedItem] = updatedData.splice(fromIndex, 1);
  updatedData.splice(toIndex, 0, movedItem);
  setList(updatedData);
};

const TAreaInfo = () => {
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const userInfo = useRecoilValue(userState);
  const size = useOutletContext<any>();
  const [searchOption, setSearchOption] = useState({ tatName: '' });
  const [searchOptionUseYn, setSearchOptionUseYn] = useState(INIT_USE_YN_A);
  const [tableState, setTableState] = useState<ITArea[]>([]);
  const [initTableState, setInitTableState] = useState<ITArea[]>([]);
  const [tAreaDList, setTAreaDList] = useState<ITAreaD[]>([]);
  const [initTAreaDList, setInitTAreaDList] = useState<ITAreaD[]>([]);
  const [tAreaCList, setTAreaCList] = useState<ITAreaC[]>([]);
  const [initTAreaCList, setInitTAreaCList] = useState<ITAreaC[]>([]);
  const [isNewAdd, setIsNewAdd] = useState(true); // 신규등록 여부
  const [viewTable, setViewTable] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState(INFO); // 하위메뉴의 내부탭 액티브상태값
  const [rowState, setRowState] = useState<any>({
    hCd: userInfo.hCd,
    sCd: userInfo.sCd,
    tatCd: '',
    tatName: '',
    sUseYn: '',
    sLength: 0,
    sDvNo: '',
    eUseYn: '',
    eLength: 0,
    eDvNo: '',
    tLength: '',
    tatFidCd: '',
    bigo: '',
    useYn: '',
    delYn: 'N',
    writer: '',
    wDate: '',
    editor: userInfo.userId,
    eDate: '',
  });
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [tatCdList, setTatCdList] = useState<any[]>([]);
  const [sDvNoList, setSDvNoList] = useState<any[]>([]);
  const [eDvNoList, setEDvNoList] = useState<any[]>([]);
  const [sUseYn, setSUseYn] = useState(INIT_USE_YN_Y);
  const [eUseYn, setEUseYn] = useState(INIT_USE_YN_Y);
  const [sDvNo, setSDvNo] = useState({ type: 'sDvNo', sDvNo: '', cdName: '' });
  const [eDvNo, setEDvNo] = useState({ type: 'eDvNo', eDvNo: '', cdName: '' });
  const [tatFidCd, setTatFidCd] = useState({ type: 'tatFidCd', tatFidCd: '', cdName: '' }); // 출입장비 위치명
  const [useYn, setUseYn] = useState(INIT_USE_YN_Y);
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const [selectedTat, setSelectedTat] = useState<string | null>(null);
  const [tatGubun, setTatGubun] = useState<string | null>(null);
  const { data: useYnComCdListWithAll } = useFetchCommonCodeList(COMCD_USE_YN, true); // 사용여부 공통코드 목록 (전체포함)
  const { data: useYnComCdList } = useFetchCommonCodeList(COMCD_USE_YN, false); // 사용유무 공통코드 목록

  const tAreaQuery = useQuery(['tAreaGet', userInfo.hCd, userInfo.sCd], () => apiGet({ path: '/tarea', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }), { enabled: !!userInfo.hCd && !!userInfo.sCd });
  const tAreatQuery = useQuery(['tAreatTGet', userInfo.hCd, userInfo.sCd, selectedTat], () => apiGet({ path: '/tarea/t', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, tatCd: selectedTat } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && !!selectedTat,
  });
  const sensorQuery = useQuery(['sensorDetailGet', userInfo.hCd, userInfo.sCd], () => apiGet({ path: '/sensor/detail', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, grCd: '08' } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
  });
  const normalCodeQuery = useQuery(['codeNormalSiteGet', userInfo.hCd, userInfo.sCd], () => apiGet({ path: '/code/normal/site', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, subCd: 'j' } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
  });

  useEffect(() => {
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '터널 설정 관리 > 구역 정보',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    if (tAreaQuery.isSuccess && tAreaQuery.data.status === 200) {
      const { tareaList } = tAreaQuery.data.data.data;
      setTableState(tareaList);
      setInitTableState(tareaList);
    }
  }, [tAreaQuery.isSuccess, tAreaQuery.isRefetching]);

  useEffect(() => {
    if (tAreatQuery.isSuccess && tAreatQuery.data.status === 200) {
      const { data } = tAreatQuery.data.data;
      setRowState((prev: ITAreaT) => ({
        ...prev,
        ...data,
        sLength: parseInt(data.sLength, 10)?.toLocaleString(),
        eLength: parseInt(data.eLength, 10)?.toLocaleString(),
        tLength: parseInt(data.tLength, 10)?.toLocaleString(),
      }));
      setTatFidCd({ type: 'tatFidCd', tatFidCd: data.tatFidCd, cdName: '' });
      setSDvNo({ type: 'sDvNo', sDvNo: data.sDvNo, cdName: '' });
      setEDvNo({ type: 'eDvNo', eDvNo: data.eDvNo, cdName: '' });
    }
  }, [tAreatQuery.isSuccess, tAreatQuery.isRefetching]);

  useEffect(() => {
    if (sensorQuery.isSuccess && sensorQuery.data.status === 200) {
      const { sensorList } = sensorQuery.data.data.data;
      const newArrayS = sensorList.map((el: any) => ({ type: 'sDvNo', sDvNo: el.subCd, cdName: el.sName }));
      const newArrayE = sensorList.map((el: any) => ({ type: 'eDvNo', eDvNo: el.subCd, cdName: el.sName }));
      setSDvNoList([{ type: 'sDvNo', sDvNo: '', cdName: t('미선택') }, ...newArrayS]);
      setEDvNoList([{ type: 'eDvNo', eDvNo: '', cdName: t('미선택') }, ...newArrayE]);
    }
  }, [sensorQuery.isSuccess, sensorQuery.isRefetching]);

  useEffect(() => {
    if (normalCodeQuery.isSuccess && normalCodeQuery.data.status === 200) {
      const { normalList } = normalCodeQuery.data.data.data;
      const newArray = normalList.map((el: any) => ({ type: 'tatFidCd', tatFidCd: el.subCd, cdName: el.cdName }));
      setTatCdList([{ type: 'tatFidCd', tatFidCd: '', cdName: t('미선택') }, ...newArray]);
    }
  }, [normalCodeQuery.isSuccess, normalCodeQuery.isRefetching]);

  useEffect(() => {
    setSearchOptionUseYn(INIT_USE_YN_A);
  }, [i18n.language]);

  useEffect(() => {
    applyFilter(initTableState);
  }, [searchOption.tatName, searchOptionUseYn[COMCD_USE_YN]]);

  const parseAndFormatNumber = (value: number) => {
    return parseInt(value?.toString().replace(/,/g, ''), 10).toLocaleString();
  };

  useEffect(() => {
    // 시점, 종점 더한 뒤 localString 변환
    if (sUseYn[COMCD_USE_YN] === 'Y' && eUseYn[COMCD_USE_YN] === 'Y') {
      const tLength = Number(rowState.sLength?.toString().replace(/,/g, '')) + Number(rowState.eLength?.toString().replace(/,/g, ''));
      setRowState((prev: ITAreaT) => ({ ...prev, tLength: tLength.toLocaleString() }));
    } else if (sUseYn[COMCD_USE_YN] === 'Y' && eUseYn[COMCD_USE_YN] === 'N') {
      setRowState((prev: ITAreaT) => ({ ...prev, tLength: parseAndFormatNumber(rowState.sLength) }));
    } else if (sUseYn[COMCD_USE_YN] === 'N' && eUseYn[COMCD_USE_YN] === 'Y') {
      setRowState((prev: ITAreaT) => ({ ...prev, tLength: parseAndFormatNumber(rowState.eLength) }));
    } else if (sUseYn[COMCD_USE_YN] === 'N' && eUseYn[COMCD_USE_YN] === 'N') {
      setRowState((prev: ITAreaT) => ({ ...prev, tLength: '0' }));
    }
  }, [rowState.sLength, rowState.eLength, sUseYn[COMCD_USE_YN], eUseYn[COMCD_USE_YN]]);

  useEffect(() => {
    if (rowState.tatCd) {
      if (rowState.sUseYn === 'Y') setTatGubun('S');
      else if (rowState.eUseYn === 'Y') setTatGubun('E');
      else setTatGubun(null);
    }
  }, [rowState.tatCd]);

  const applyFilter = (array: ITArea[]) => {
    // 검색옵션 변경됐을 때 필터링 처리
    // 필터링된 어레이 리턴, 대소문자구분X
    const titleFilteredArray = array.filter((el: any) => el.tatName?.toLowerCase()?.includes(searchOption.tatName?.toLowerCase()));
    const result = ynFilter(titleFilteredArray, 'useYn', searchOptionUseYn[COMCD_USE_YN]);

    if (result.length > 0) setTableState(result);
    else setTableState([]);
  };

  const onClickInitiateSearchOption = () => {
    setSearchOptionUseYn(INIT_USE_YN_A);
    setSearchOption({ tatName: '' });
  };

  const onClickRow = (rowData: any, index: number) => {
    setIsNewAdd(false);
    setIsSaveClicked(false);
    setSelectedTat(rowData.tatCd);
    setSUseYn({ type: COMCD_USE_YN, [COMCD_USE_YN]: rowData.sUseYn, cdName: '' });
    setEUseYn({ type: COMCD_USE_YN, [COMCD_USE_YN]: rowData.eUseYn, cdName: '' });
    setUseYn({ type: COMCD_USE_YN, [COMCD_USE_YN]: rowData.useYn, cdName: '' });
    if (size.innerSize.W < 1024) setViewTable(false);
  };

  const backToMain = () => {
    setViewTable(true);
  };

  // 신규등록 버튼 클릭해서 새 입력창(iputForm) 노출
  const onClickNewRegistration = () => {
    setIsNewAdd(true);
    setIsSaveClicked(false);
    setActiveTab(INFO);
    initiateState();
    if (size.innerSize.W < 1024) setViewTable(false);
  };

  // 등록 버튼 클릭 시 시/종점 사용유무 사용으로 초기화
  // 구역명, 비고 ''로 초기화
  // 시/종점 총 공사구간 0으로, 유해가스 센서명 미선택으로 초기화
  const initiateState = () => {
    setSearchOptionUseYn(INIT_USE_YN_A);
    setSearchOption({ tatName: '' });
    Object.keys(rowState).map((el: any) => setRowState((prev: any) => ({ ...prev, [el]: '', hCd: userInfo.hCd, sCd: userInfo.sCd, delYn: 'N', editor: userInfo.userId })));
    setSUseYn(INIT_USE_YN_Y);
    setEUseYn(INIT_USE_YN_Y);
    setSDvNo({ type: 'sDvNo', sDvNo: '', cdName: '' });
    setEDvNo({ type: 'eDvNo', eDvNo: '', cdName: '' });
    setTatFidCd({ type: 'tatFidCd', tatFidCd: '', cdName: '' });
    setUseYn(INIT_USE_YN_Y);
    setSelectedTat(null);
  };

  const onClickSaveOrder = async () => {
    if (tableState.length > 0) {
      const newArray = tableState.map(({ tatCd }, index) => ({ hCd: userInfo.hCd, sCd: userInfo.sCd, tatCd, cdSort: index + 1 }));
      const req = { tareaTSortReqDto: newArray };
      const res = await apiPost({ path: '/tarea/t/sort', req });
      const { statusCode } = res.data || {};
      if (statusCode === 200) {
        toast.success(t('정렬 업데이트 성공'));
      }
    }
  };

  const deleteAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, tatCd: selectedTat, editor: userInfo.userId };
    const res = await apiDelete({ path: '/tarea/t', req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      setIsSaveClicked(false);
      setIsNewAdd(false);
      initiateState();
      setTableState(data[1].tareaList);
      toast.success(t(message));
    } // else toast.error(t(ERROR));
  };

  const onClickDelete = () => {
    setOpenModal((prev) => ({ ...prev, status: true, type: 'delete', api: deleteAPI }));
  };

  const tAreatPostAPI = async () => {
    const reqData = {
      ...rowState,
      sUseYn: sUseYn[COMCD_USE_YN],
      sDvNo: sDvNo.sDvNo,
      eUseYn: eUseYn[COMCD_USE_YN],
      eDvNo: eDvNo.eDvNo,
      tatFidCd: tatFidCd.tatFidCd,
      useYn: useYn[COMCD_USE_YN],
      editor: userInfo.userId,
      sLength: rowState.sLength?.toString()?.replace(/[^\d]/g, ''),
      eLength: rowState.eLength?.toString()?.replace(/[^\d]/g, ''),
      tLength: rowState.tLength?.toString()?.replace(/[^\d]/g, ''),
      ...(isNewAdd && { writer: userInfo.userId }),
    };
    const { wDate, eDate, sRLength, sTjcCd, sTesCd, sBigo, eRLength, eTjcCd, eTesCd, eBigo, sTjcName, sTesName, eTjcName, eTesName, ...rest } = reqData;
    const req = trimObject(rest);
    const res = await apiPost({ path: '/tarea/t', req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      setIsSaveClicked(false);
      setIsNewAdd(false);
      setSelectedTat(data[0].tatCd);
      setRowState((prev: ITAreaT) => ({
        ...prev,
        ...data[0],
        sLength: parseInt(data[0].sLength, 10)?.toLocaleString().toString(),
        eLength: parseInt(data[0].eLength, 10)?.toLocaleString().toString(),
        tLength: parseInt(data[0].tLength, 10)?.toLocaleString().toString(),
      }));
      setTableState(data[1].tareaList);
      toast.success(t(message));
    }
  };

  const tAreaDPostAPI = async () => {
    const trim = trimArray(tAreaDList);
    const array = trim.map((el) => ({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      tatCd: selectedTat,
      tatGubun,
      tadCd: el.tadCd,
      tadName: el.tadName,
      bcsId: el.bcsId,
      bsCd: el.bsCd,
      cdSort: el.cdSort,
      bigo: el.bigo,
      useYn: el.useYn,
      delYn: 'N',
      writer: el.writer,
      editor: userInfo.userId,
    }));
    const req = { tareaDReqDto: array };
    const res = await apiPost({ path: '/tarea/d', req });
    const { data, statusCode, message } = res.data || {};
    if (statusCode === 200) {
      setTAreaDList(data.tareaDList);
      setInitTAreaDList(data.tareaDList);
      setIsSaveClicked(false);
      toast.success(message);
    } else toast.error(t(ERROR));
  };

  const findNName = (nCdParam: string, nvrList: any[]) => {
    const findNvr = nvrList.find((nvr) => nvr.nCd === nCdParam);
    return findNvr ? findNvr.nName : null;
  };

  const findCName = async (nCdParam: string, cCdParam: string) => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, nCd: nCdParam, cCd: cCdParam };
    const res = await apiGet({ path: '/cam/camera/box', req });
    const {
      data: { cameraList },
      statusCode,
    } = res.data || {};
    if (statusCode === 200) {
      const findCam = cameraList.find((cam: any) => cam.cCd === cCdParam);
      return findCam ? findCam.cName : null;
    }
    return null;
  };

  const getTareaCAPI = async () => {
    const reqTarea = { hCd: userInfo.hCd, sCd: userInfo.sCd, tatCd: selectedTat, tatGubun };
    const camRes = await apiGet({ path: '/tarea/c', req: reqTarea });
    const reqCam = { hCd: userInfo.hCd, sCd: userInfo.sCd, nCd: '001', cCd: '01' };
    const nvrRes = await apiGet({ path: '/cam/nvr', req: reqCam });

    const {
      data: { tareaCList },
    } = camRes.data || {};
    const {
      data: { nvrList },
    } = nvrRes.data || {};

    if (camRes.data.statusCode === 200 && nvrRes.data.statusCode === 200) {
      const newArray = await Promise.all(
        tareaCList.map(async (cEl: any, i: number) => {
          const nName = findNName(cEl.nCd, nvrList);
          const cName = await findCName(cEl.nCd, cEl.cCd);
          return { ...cEl, nName, cName, index: i };
        })
      );
      setTAreaCList(newArray);
      setInitTAreaCList(newArray);
    }
  };

  const tAreaCPostAPI = async () => {
    const trim = trimArray(tAreaCList);
    const array = trim.map((el) => ({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      tatCd: selectedTat,
      tatGubun,
      tacCd: el.tacCd,
      nCd: el.nCd,
      cCd: el.cCd,
      useYn: el.useYn,
      delYn: 'N',
      writer: el.writer,
      editor: userInfo.userId,
    }));
    const req = { tareaCReqDto: array };
    const res = await apiPost({ path: '/tarea/c', req });
    const { data, statusCode, message } = res.data || {};
    if (statusCode === 200) {
      getTareaCAPI();
      // setTAreaCList(data.tareaCList);
      // setInitTAreaCList(data.tareaCList);
      toast.success(message);
      setIsSaveClicked(false);
    } else toast.error(t(ERROR));
  };

  // 중복값 체크
  const isUnique = (array: any[]) => {
    // 사용유무 사용인 로우만 중복확인
    const useYArray = array.filter((row: any) => row.useYn === 'Y');
    const seenBcsIds = new Set();
    const seenBsCds = new Set();

    for (const item of useYArray) {
      // Check bcsId for non-empty and uniqueness
      if (item.bcsId && seenBcsIds.has(item.bcsId)) {
        return false;
      }
      if (item.bcsId) {
        seenBcsIds.add(item.bcsId);
      }

      // Check bsCd for non-empty and uniqueness
      if (item.bsCd && seenBsCds.has(item.bsCd)) {
        return false;
      }
      if (item.bsCd) {
        seenBsCds.add(item.bsCd);
      }
    }

    return true; // All non-empty bcsId and bsCd values are unique
  };

  const onClickSave = (activeTabParam: number) => {
    setIsSaveClicked(true);
    if (activeTabParam === INFO) {
      if (rowState.tatName.trim() === '') toast.warning(t('구역 명을 입력하세요.'));
      else tAreatPostAPI();
    } else if (activeTabParam === DETAIL_INFO) {
      if (selectedTat && tatGubun) {
        const required = ['tadName'];
        const emptyCheck = tAreaDList.filter((el: any) => {
          const check = required.find((el2: any) => el[el2] === '');
          return check;
        });
        if (emptyCheck.length > 0) toast.warning(t('필수입력값을 모두 입력하세요'));
        else if (!isUnique(tAreaDList)) toast.warning(t('비콘 스캐너 ID / 방송 장비는 중복선택 할 수 없습니다.'));
        else tAreaDPostAPI();
      } else toast.warning(t('구역 및 시/종점을 선택하세요.'));
    } else if (activeTabParam === CCTV_INFO) {
      if (selectedTat && tatGubun) {
        const required = ['cCd', 'nCd'];
        const emptyCheck = tAreaCList.filter((el: any) => {
          const check = required.find((el2: any) => el[el2] === '');
          return check;
        });
        if (emptyCheck.length > 0) toast.warning(t('필수입력값을 모두 입력하세요'));
        else tAreaCPostAPI();
      } else toast.warning(t('구역 및 시/종점을 선택하세요.'));
    }
  };

  const onClickNewAdd = (activeTabParam: number) => {
    if (selectedTat && tatGubun) {
      if (activeTabParam === DETAIL_INFO) {
        const data: ITAreaD = {
          tatCd: selectedTat,
          tatGubun,
          tadCd: '',
          tadName: '',
          bcsId: '',
          bsCd: '',
          cdSort: tAreaDList.length + 1,
          bigo: '',
          useYn: 'Y',
          writer: userInfo.userId,
          editor: userInfo.userId,
        };
        setTAreaDList((prev: any) => [...prev, data]);
        setInitTAreaDList((prev: any) => [...prev, data]);
      } else if (activeTabParam === CCTV_INFO) {
        const data: ITAreaC = {
          index: tAreaCList.length + 1,
          tatCd: selectedTat,
          tatGubun,
          tacCd: '',
          nCd: '',
          cCd: '',
          useYn: 'Y',
          writer: userInfo.userId,
          editor: userInfo.userId,
        };
        setTAreaCList((prev: any) => [...prev, data]);
        setInitTAreaCList((prev: any) => [...prev, data]);
      }
    } else toast.warning(t('구역 및 시/종점을 선택하세요.'));
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={`content-container ${size.innerSize.W >= 1024 ? 'twoColumn column-55 max800' : 'oneColumn'}`}>
        <Root className={size.innerSize.W >= 1024 || viewTable ? 'showRoot' : 'hideRoot'}>
          <SearchOptions align='left'>
            <div className='inputsWrapper'>
              <div className='inputForm-row'>
                <div className='inputForm-col withLabelComCf'>
                  <label htmlFor='useYn'>{t('사용유무')}</label>
                  <SelectBox
                    options={useYnComCdListWithAll}
                    defaultOption={searchOptionUseYn.cdName}
                    state={searchOptionUseYn}
                    setState={setSearchOptionUseYn}
                    stateKey={COMCD_USE_YN}
                    initiateKey={searchOptionUseYn[COMCD_USE_YN]}
                    filterbar='filter-1-left'
                  />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <Input label='' placeholder={t('구역 명')} type='text' id='tatName' name='tatName' state={searchOption} setState={setSearchOption} />
                </div>
              </div>
            </div>
            <div className='inputsWrapper'>
              <div className='secondSearchOption'>
                <div className='flex-basic textBtnGroup'>
                  <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
                </div>
                <div className='flex-basic iconBtnGroup'>
                  {(auth.createAuth || auth.updateAuth) && <BtnGhost onClick={onClickSaveOrder}>{t('정렬 저장')}</BtnGhost>}
                  <BtnGhost onClick={onClickNewRegistration}>
                    <span className='material-symbols-rounded'>add</span>
                    {t('등록')}
                  </BtnGhost>
                </div>
              </div>
            </div>
          </SearchOptions>
          <InputTable className='margin-left-05'>
            <div className='tableTip'>
              <span className='material-symbols-rounded'>lightbulb </span>
              <span>{t('드래그 앤 드롭을 지원합니다. 행 순서를 위,아래로 변경하세요')}</span>
            </div>
            <div className='thead'>
              <div className='tr'>
                <div className='trCol2p5 flex-center tableStickyNo'>No</div>
                <div className='trCol4 flex-center'>{t('코드')}</div>
                <div className='trCol10 flex-center tableStickyTitle content-overflow'>{t('구역 명')}</div>
                <div className='trCol6 flex-center'>{t('시점')}</div>
                <div className='trCol6 flex-center'>{t('종점')}</div>
                <div className='trCol8 flex-center'>{t('비고')}</div>
                <div className='trCol6 flex-center'>{t('사용유무')}</div>
              </div>
            </div>
            {tableState.length > 0 ? (
              <div className='table'>
                <div className='tbody'>
                  {tableState.map((el, i) => (
                    <DraggableRow
                      type='tatCd'
                      key={el.tatCd}
                      index={i}
                      el={el}
                      auth={auth}
                      moveRow={dragRow}
                      tableState={tableState}
                      setTableState={setTableState}
                      initTableState={initTableState}
                      setInitTableState={setInitTableState}
                      onClickRow={onClickRow}
                      activeTab={activeTab}
                      selectedKey={selectedTat}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <IssueGuide />
            )}
          </InputTable>
        </Root>
        {(size.innerSize.W >= 1024 || !viewTable) && (
          <Root>
            <div className='inputFormsWrapper smallTab'>
              {size.innerSize.W < 1024 && <BackButton func={() => backToMain()} />}
              <div className={`tab ${activeTab === INFO ? `activeTab` : undefined}`} role='button' tabIndex={0} onClick={() => setActiveTab(INFO)}>
                {t('구역 정보')}
              </div>
              <div className={`tab ${activeTab === DETAIL_INFO ? `activeTab` : undefined}`} role='button' tabIndex={0} onClick={() => setActiveTab(DETAIL_INFO)}>
                {t('상세구역 정보')}
              </div>
              <div className={`tab ${activeTab === CCTV_INFO ? `activeTab` : undefined}`} role='button' tabIndex={0} onClick={() => setActiveTab(CCTV_INFO)}>
                {t('CCTV 정보')}
              </div>
            </div>
            {activeTab === INFO && (
              <SubRoot>
                <div className='inputForm'>
                  <div className='inputForm-group-1536'>
                    {!isNewAdd && (
                      <div className='inputForm-row labelOutInput'>
                        <label htmlFor='code'>{t('코드')}</label>
                        <div className='viewOnly'>{rowState?.tatCd}</div>
                      </div>
                    )}
                  </div>
                  <div className='inputForm-group-1536'>
                    <div className='inputForm-row labelInInput'>
                      <Input
                        className='required'
                        label={t('구역 명')}
                        type='text'
                        id='tatName'
                        name='tatName'
                        state={rowState}
                        setState={setRowState}
                        disabled={!auth.updateAuth && !auth.createAuth}
                        getBorderStyle={isSaveClicked ? applyBorderStyle(rowState.tatName, 'red', 'tatName') : undefined}
                      />
                    </div>
                  </div>
                  <div className='inputForm-group-1536'>
                    <div className='flexColumn dotBox blue'>
                      <div>{t('시점')}</div>
                      <div className='inputForm-row labelOutInput'>
                        <label htmlFor='protocol'>{t('사용유무')}</label>
                        <div>
                          <SelectBox options={useYnComCdList} defaultOption={sUseYn.cdName} state={sUseYn} setState={setSUseYn} stateKey={COMCD_USE_YN} initiateKey={sUseYn[COMCD_USE_YN]} />
                        </div>
                      </div>
                      <div className='inputForm-row labelInInput'>
                        <Input
                          label={t('총 공사 구간 (m)')}
                          type='text'
                          id='sLength'
                          name='sLength'
                          valueType='localeString'
                          state={rowState}
                          setState={setRowState}
                          disabled={!auth.updateAuth && !auth.createAuth}
                        />
                      </div>
                      <div className='inputForm-row labelOutInput'>
                        <label htmlFor='sDvNo'>{t('유해가스 센서명')}</label>
                        <div>
                          <SelectBox options={sDvNoList} defaultOption={sDvNo.cdName || t('미선택')} state={sDvNo} setState={setSDvNo} stateKey='sDvNo' initiateKey={sDvNo.sDvNo} />
                        </div>
                      </div>
                    </div>
                    <div className='flexColumn dotBox yellow'>
                      <div>{t('종점')}</div>
                      <div className='inputForm-row labelOutInput'>
                        <label htmlFor='eUseYn'>{t('사용유무')}</label>
                        <div>
                          <SelectBox options={useYnComCdList} defaultOption={eUseYn.cdName} state={eUseYn} setState={setEUseYn} stateKey={COMCD_USE_YN} initiateKey={eUseYn[COMCD_USE_YN]} />
                        </div>
                      </div>
                      <div className='inputForm-row labelInInput'>
                        <Input
                          label={t('총 공사 구간 (m)')}
                          type='text'
                          id='eLength'
                          name='eLength'
                          valueType='localeString'
                          state={rowState}
                          setState={setRowState}
                          disabled={!auth.updateAuth && !auth.createAuth}
                        />
                      </div>
                      <div className='inputForm-row labelOutInput'>
                        <label htmlFor='eDvNo'>{t('유해가스 센서명')}</label>
                        <div>
                          <SelectBox options={eDvNoList} defaultOption={eDvNo.cdName || t('미선택')} state={eDvNo} setState={setEDvNo} stateKey='eDvNo' initiateKey={eDvNo.eDvNo} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='inputForm-group-1536'>
                    <div className='inputForm-row labelInInput'>
                      <Input label={t('시/종점 총 공사 구간 (m)')} type='text' id='tLength' name='tLength' valueType='localeString' state={rowState} setState={setRowState} disabled />
                    </div>
                  </div>
                  <div className='inputForm-group-1536'>
                    <div className='inputForm-row labelOutInput'>
                      <label htmlFor='tatFidCd'>{t('출입장비 위치명')}</label>
                      <div>
                        <SelectBox options={tatCdList} defaultOption={tatFidCd.cdName || t('미선택')} state={tatFidCd} setState={setTatFidCd} stateKey='tatFidCd' initiateKey={tatFidCd.tatFidCd} />
                      </div>
                    </div>
                  </div>
                  <div className='inputForm-group-1536'>
                    <div className='inputForm-row labelOutInput'>
                      <label htmlFor='useYn'>{t('사용유무')}</label>
                      <div>
                        <SelectBox options={useYnComCdList} defaultOption={useYn.cdName} state={useYn} setState={setUseYn} stateKey={COMCD_USE_YN} initiateKey={useYn[COMCD_USE_YN]} />
                      </div>
                    </div>
                  </div>
                  <div className='inputForm-row labelInInput'>
                    <Input label={t('비고')} type='text' id='bigo' name='bigo' state={rowState} setState={setRowState} disabled={!auth.updateAuth && !auth.createAuth} maxLength={200} />
                  </div>
                  {!isNewAdd ? (
                    <div className='detailInfo-group'>
                      <div className='flex-col detailInfo'>
                        <label htmlFor='createDate'>{t('등록일자')}</label>
                        <div>{rowState?.wDate}</div>
                      </div>
                      <div className='flex-col detailInfo'>
                        <label htmlFor='createUser'>{t('등록자')}</label>
                        <div>{rowState.writer}</div>
                      </div>

                      <div className='flex-col detailInfo'>
                        <label htmlFor='updateDate'>{t('수정일자')}</label>
                        <div>{rowState.eDate}</div>
                      </div>
                      <div className='flex-col detailInfo'>
                        <label htmlFor='updateUser'>{t('수정자')}</label>
                        <div>{rowState.editor}</div>
                      </div>
                    </div>
                  ) : undefined}
                </div>
              </SubRoot>
            )}
            {activeTab === DETAIL_INFO && (
              <DetailInfoTab
                rowState={rowState}
                sUseYn={sUseYn}
                eUseYn={eUseYn}
                auth={auth}
                tatCd={selectedTat}
                userInfo={userInfo}
                useYnComCdList={useYnComCdList}
                activeTab={activeTab}
                // onClickSave={onClickSave}
                tableState={tAreaDList}
                setTableState={setTAreaDList}
                initTableState={initTAreaDList}
                setInitTableState={setInitTAreaDList}
                tatGubun={tatGubun}
                setTatGubun={setTatGubun}
                isSaveClicked={isSaveClicked}
              />
            )}
            {activeTab === CCTV_INFO && (
              <CctvInfoTab
                rowState={rowState}
                sUseYn={sUseYn}
                eUseYn={eUseYn}
                auth={auth}
                tatCd={selectedTat}
                userInfo={userInfo}
                useYnComCdList={useYnComCdList}
                activeTab={activeTab}
                // onClickSave={onClickSave}
                tableState={tAreaCList}
                setTableState={setTAreaCList}
                initTableState={initTAreaCList}
                setInitTableState={setInitTAreaCList}
                tatGubun={tatGubun}
                setTatGubun={setTatGubun}
                isSaveClicked={isSaveClicked}
              />
            )}
            <ButtonsWrapper>
              {activeTab !== INFO && auth.createAuth && <BtnBlue onClick={() => onClickNewAdd(activeTab)}>{t('신규항목 추가')}</BtnBlue>}
              {activeTab === INFO && !isNewAdd && auth.deleteAuth && <BtnRed onClick={onClickDelete}>{t('삭제')}</BtnRed>}
              {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={() => onClickSave(activeTab)}>{t('저장')}</BtnBlue>}
            </ButtonsWrapper>
          </Root>
        )}

        <Portal openModal={openModal?.status}>{openModal && openModal.type === 'delete' && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
      </div>
    </DndProvider>
  );
};

interface Prop {
  rowState: any;
  sUseYn: any;
  eUseYn: any;
  auth: any;
  tatCd: string | null;
  userInfo: any;
  useYnComCdList: any;
  activeTab: any;
  tableState: any[];
  initTableState: any[];
  setTableState: Dispatch<SetStateAction<any[]>>;
  setInitTableState: Dispatch<SetStateAction<any[]>>;
  tatGubun: string | null;
  setTatGubun: Dispatch<SetStateAction<string | null>>;
  isSaveClicked: boolean;
}
interface ITAreaD {
  tatCd: string;
  tatGubun: string;
  tadCd: string;
  tadName: string;
  bcsId: string;
  bsCd: string;
  cdSort: number;
  bigo: string;
  useYn: string;
  writer?: string;
  editor?: string;
}

interface ITAreaC {
  index: number;
  tatCd: string;
  tatGubun: string;
  tacCd: string;
  nCd: string;
  cCd: string;
  useYn: string;
  writer?: string;
  editor?: string;
}

const DetailInfoTab = ({
  rowState,
  sUseYn,
  eUseYn,
  auth,
  tatCd,
  userInfo,
  useYnComCdList,
  activeTab,
  tableState,
  setTableState,
  initTableState,
  setInitTableState,
  tatGubun,
  setTatGubun,
  isSaveClicked,
}: Prop) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const [bsCdList, setBsCdList] = useState<any[]>([]); // 방송장비 목록
  const [bcsCdList, setBcsCdList] = useState<any[]>([]); // 비콘 스캐너 목록

  const tAreaDQuery = useQuery(['tAreaDGet', userInfo.hCd, userInfo.sCd, tatCd, tatGubun], () => apiGet({ path: '/tarea/d', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, tatCd, tatGubun } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && !!tatCd && !!tatGubun,
  });

  useEffect(() => {
    if (tAreaDQuery.isSuccess && tAreaDQuery.data.status === 200) {
      const { tareaDList } = tAreaDQuery.data.data.data;
      setTableState(tareaDList);
    }
  }, [tAreaDQuery.isSuccess, tAreaDQuery.isRefetching]);

  const bsYQuery = useQuery(['bsYGet', userInfo.hCd, userInfo.sCd, tatCd, tatGubun], () => apiGet({ path: '/bs/y', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, tatCd, tatGubun } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && !!tatCd && !!tatGubun,
  });

  const bcYQuery = useQuery(['bcYGet', userInfo.hCd, userInfo.sCd, tatCd, tatGubun], () => apiGet({ path: '/beacon/bcs/y', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, tatCd, tatGubun } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && !!tatCd && !!tatGubun,
  });

  useEffect(() => {
    if (bsYQuery.isSuccess && bsYQuery.data.status === 200) {
      const { data } = bsYQuery.data.data;
      const newArray = data.map((el2: any) => ({ type: 'bsCd', bsCd: el2.bsCd, cdName: el2.bsName }));
      setBsCdList([{ type: 'bsCd', bsCd: '', cdName: t('미선택') }, ...newArray]);
    }
  }, [bsYQuery.isSuccess, bsYQuery.isRefetching]);

  useEffect(() => {
    if (bcYQuery.isSuccess && bcYQuery.data.status === 200) {
      const { data } = bcYQuery.data.data;
      const newArray = data.map((el2: any) => ({ type: 'bcsId', bcsId: el2.bcsId, cdName: el2.bcsName }));
      setBcsCdList([{ type: 'bcsId', bcsId: '', cdName: t('미선택') }, ...newArray]);
    }
  }, [bcYQuery.isSuccess, bcYQuery.isRefetching]);

  const deleteAPI = async (tadCdParam: string) => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, tatCd, tatGubun, tadCd: tadCdParam, editor: userInfo.userId };
    const res = await apiDelete({ path: '/tarea/d', req });
    const { statusCode, data, message } = res.data || {};
    if (statusCode === 200) {
      setTableState(data.tareaDList);
      toast.success(t(message));
    }
  };

  return (
    <>
      <SearchOptions align='left'>
        <div className='inputsWrapper'>
          <div className='secondSearchOption'>
            {tatCd && <div className='formTitle'>{`${t('구역 명')} : ${rowState.tatName} (${rowState.tatCd})`}</div>}
            <div className='blue'>{t('100m 단위로 상세구역 정보 설정을 권면합니다')}</div>
          </div>
        </div>
      </SearchOptions>
      <SearchOptions align='left'>
        <div className='inputsWrapper'>
          <div className='secondSearchOption'>
            <div className='flex-basic textBtnGroup'>
              {sUseYn[COMCD_USE_YN] === 'Y' && (
                <TunnelPoint className={tatGubun === 'S' ? 'start' : ''} role='presentation' onClick={() => setTatGubun('S')}>
                  <PointIcon isStart />
                  <span>{t('시점')}</span>
                </TunnelPoint>
              )}
              {eUseYn[COMCD_USE_YN] === 'Y' && (
                <TunnelPoint className={tatGubun === 'E' ? 'end' : ''} role='presentation' onClick={() => setTatGubun('E')}>
                  <PointIcon isStart={false} />
                  <span>{t('종점')}</span>
                </TunnelPoint>
              )}
            </div>
          </div>
        </div>
      </SearchOptions>
      <InputTable className='margin-left-05'>
        <div className='tableTip'>
          <span className='material-symbols-rounded'>lightbulb </span>
          <span>{t('드래그 앤 드롭을 지원합니다. 행 순서를 위,아래로 변경하세요')}</span>
        </div>
        <div className='thead'>
          <div className='tr'>
            <div className='trCol2p5 flex-center tableStickyNo'>No</div>
            <div className='trCol4 flex-center'>{t('코드')}</div>
            <div className='trCol10 flex-center tableStickyTitle content-overflow required'>{t('상세구역 명')}</div>
            <div className='trCol10 flex-center'>{t('비콘스캐너 명')}</div>
            <div className='trCol10 flex-center'>{t('방송 장비')}</div>
            <div className='trCol8 flex-center'>{t('비고')}</div>
            <div className='trCol6 flex-center'>{t('사용유무')}</div>
            {auth.deleteAuth && <div className='trCol4 flex-center'> </div>}
          </div>
        </div>
        {tatCd && tatGubun ? (
          tableState.length > 0 ? (
            <div className='table'>
              <div className='tbody'>
                {tableState.map((el, i) => (
                  <DraggableRow
                    type='tadCd'
                    key={`${el.cdSort}_${i}`}
                    index={i}
                    el={el}
                    auth={auth}
                    moveRow={dragRow}
                    tableState={tableState}
                    setTableState={setTableState}
                    initTableState={initTableState}
                    setInitTableState={setInitTableState}
                    activeTab={activeTab}
                    options={useYnComCdList}
                    setOpenModal={setOpenModal}
                    deleteAPI={deleteAPI}
                    isSaveClicked={isSaveClicked}
                    bcsCdList={bcsCdList}
                    bsCdList={bsCdList}
                  />
                ))}
              </div>
            </div>
          ) : (
            <IssueGuide />
          )
        ) : (
          <IssueGuide text='구역 및 시/종점 정보를 선택해주세요' illustrator={{ visible: true, src: illustrator }} />
        )}
      </InputTable>
      <Portal openModal={openModal?.status}>{openModal && openModal.type === 'delete' ? <DeleteModal openModal={openModal} setOpenModal={setOpenModal} /> : undefined}</Portal>
    </>
  );
};

const CctvInfoTab = ({
  rowState,
  sUseYn,
  eUseYn,
  auth,
  tatCd,
  userInfo,
  useYnComCdList,
  activeTab,
  tableState,
  setTableState,
  initTableState,
  setInitTableState,
  tatGubun,
  setTatGubun,
  isSaveClicked,
}: Prop) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const [nCdList, setnCdList] = useState<any[]>([]);
  const [cCdList, setcCdList] = useState<any[]>([]);

  const camNvrQuery = useQuery(['camNvrGet', userInfo.hCd, userInfo.sCd], () => apiGet({ path: '/cam/nvr', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, nCd: '001', cCd: '01' } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && activeTab === CCTV_INFO,
  });

  const tAreaCQuery = useQuery(['tareaCGet', userInfo.hCd, userInfo.sCd, tatCd, tatGubun], () => apiGet({ path: '/tarea/c', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, tatCd, tatGubun } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && !!tatCd && !!tatGubun,
  });

  const findNName = (nCdParam: string, nvrList: any[]) => {
    const findNvr = nvrList.find((nvr) => nvr.nCd === nCdParam);
    return findNvr ? findNvr.nName : null;
  };

  const findCName = async (nCdParam: string, cCdParam: string) => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, nCd: nCdParam, cCd: cCdParam };
    const res = await apiGet({ path: '/cam/camera/box', req });
    const {
      data: { cameraList },
      statusCode,
    } = res.data || {};
    if (statusCode === 200) {
      const findCam = cameraList.find((cam: any) => cam.cCd === cCdParam);
      return findCam ? findCam.cName : null;
    }
    return null;
  };

  const fetchData1 = async () => {
    if (tAreaCQuery.isSuccess && tAreaCQuery.data.status === 200 && camNvrQuery.isSuccess && camNvrQuery.data.status === 200) {
      const { tareaCList } = tAreaCQuery.data.data.data;
      const { nvrList } = camNvrQuery.data.data.data;

      const newArray = await Promise.all(
        tareaCList.map(async (cEl: any, i: number) => {
          const nName = findNName(cEl.nCd, nvrList);
          const cName = await findCName(cEl.nCd, cEl.cCd);
          return { ...cEl, nName, cName, index: i };
        })
      );
      setTableState(newArray);
      setInitTableState(newArray);
    }
  };

  useEffect(() => {
    fetchData1().catch(console.error);
  }, [tAreaCQuery.isSuccess, tAreaCQuery.isRefetching, camNvrQuery.isSuccess, camNvrQuery.isRefetching]);

  useEffect(() => {
    const fetchData = async () => {
      if (camNvrQuery.isSuccess && camNvrQuery.data.status === 200) {
        const { nvrList } = camNvrQuery.data.data.data;
        const newArray = nvrList.filter((nvrParam: any) => nvrParam.useYn === 'Y').map((nvr: any) => ({ type: 'nCd', nCd: nvr.nCd, cdName: nvr.nName }));
        setnCdList(newArray);
        const subListPromises = nvrList.map((el: any) => getcCdListAPI(el.nCd)); // Creating an array of promises
        const resolvedPromises = await Promise.all(subListPromises); // Using Promise.all to resolve all promises
        const subList = nvrList.map((el: any, index: number) => ({ [el.nCd]: resolvedPromises[index] })); // Combining results with their respective keys
        setcCdList(subList);
      }
    };

    fetchData().catch(console.error);
  }, [camNvrQuery.isSuccess, camNvrQuery.isRefetching]);

  const getcCdListAPI = async (nCdParam: string) => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, nCd: nCdParam };
    const res = await apiGet({ path: '/cam/camera', req });
    const {
      data: { cameraList },
      statusCode,
    } = res.data || {};
    if (statusCode === 200) {
      const newArray = cameraList.filter((cCdParam: any) => cCdParam.useYn === 'Y').map((el: any) => ({ type: 'cCd', cCd: el.cCd, cdName: el.cName }));
      const result = newArray;
      return result;
    }
    return null;
  };

  const getTareaCAPI = async () => {
    const reqTarea = { hCd: userInfo.hCd, sCd: userInfo.sCd, tatCd, tatGubun };
    const camRes = await apiGet({ path: '/tarea/c', req: reqTarea });
    const reqCam = { hCd: userInfo.hCd, sCd: userInfo.sCd, nCd: '001', cCd: '01' };
    const nvrRes = await apiGet({ path: '/cam/nvr', req: reqCam });

    const {
      data: { tareaCList },
    } = camRes.data || {};
    const {
      data: { nvrList },
    } = nvrRes.data || {};

    if (camRes.data.statusCode === 200 && nvrRes.data.statusCode === 200) {
      const newArray = await Promise.all(
        tareaCList.map(async (cEl: any) => {
          const nName = findNName(cEl.nCd, nvrList);
          const cName = await findCName(cEl.nCd, cEl.cCd);
          return { ...cEl, nName, cName };
        })
      );
      setTableState(newArray);
      setInitTableState(newArray);
    }
  };

  const deleteAPI = async (tacCdParam: string) => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, tatCd, tatGubun, tacCd: tacCdParam, editor: userInfo.userId };
    const res = await apiDelete({ path: '/tarea/c', req });
    const { statusCode, data, message } = res.data || {};
    if (statusCode === 200) {
      getTareaCAPI();

      toast.success(t(message));
    } else toast.error(t(ERROR));
  };

  const onClickDelete = (el: ITAreaC, index: number) => {
    const tableArray = [...tableState];
    if (el.tacCd) {
      // 기존 로우 삭제 클릭시
      setOpenModal((prev: any) => ({ ...prev, status: true, type: 'delete', api: () => deleteAPI(el.tacCd) }));
    } else {
      // 새로 추가한 로우 제거 클릭시
      tableArray.splice(index, 1);
      setTableState(tableArray);
    }
  };

  // nCd 키값으로 카메라목록 배열에서 목록을 찾아서 리턴
  const findArrayByKey = (key: string) => {
    // Find the object with the specified key using a safer approach
    const object = cCdList.find((obj: any) => Object.prototype.hasOwnProperty.call(obj, key));

    // If the object is found, return the array associated with the key
    return object ? object[key] : null;
  };

  return (
    <>
      <SearchOptions align='left'>
        <div className='inputsWrapper'>
          <div className='secondSearchOption'>{tatCd && <div className='formTitle'>{`${t('구역 명')} : ${rowState.tatName} (${rowState.tatCd})`}</div>}</div>
        </div>
      </SearchOptions>
      <SearchOptions align='left'>
        <div className='inputsWrapper'>
          <div className='secondSearchOption'>
            <div className='flex-basic textBtnGroup'>
              {sUseYn[COMCD_USE_YN] === 'Y' && (
                <TunnelPoint className={tatGubun === 'S' ? 'start' : ''} role='presentation' onClick={() => setTatGubun('S')}>
                  <PointIcon isStart />
                  <span>{t('시점')}</span>
                </TunnelPoint>
              )}
              {eUseYn[COMCD_USE_YN] === 'Y' && (
                <TunnelPoint className={tatGubun === 'E' ? 'end' : ''} role='presentation' onClick={() => setTatGubun('E')}>
                  <PointIcon isStart={false} />
                  <span>{t('종점')}</span>
                </TunnelPoint>
              )}
            </div>
          </div>
        </div>
      </SearchOptions>
      <InputTable className='margin-left-05'>
        <div className='thead'>
          <div className='tr'>
            <div className='trCol2p5 flex-center tableStickyNo'>No</div>
            <div className='trCol4 flex-center'>{t('코드')}</div>
            <div className='trCol12 flex-center required'>{t('NVR 명')}</div>
            <div className='trCol12 flex-center required'>{t('카메라 명')}</div>
            <div className='trCol10 flex-center'>{t('사용유무')}</div>
            {auth.deleteAuth && <div className='trCol4 flex-center content-overflow'> </div>}
          </div>
        </div>
        {tatCd && tatGubun ? (
          tableState.length > 0 ? (
            <div className='table'>
              <div className='tbody'>
                {tableState.map((el, i) => (
                  <div className='tr' key={el.index}>
                    <div className='trCol2p5 flex-center tableStickyNo'>{i + 1}</div>
                    <div className='trCol4 flex-center'>{el.tacCd}</div>
                    <div className='trCol12 flex-center selectBox'>
                      <SearchSelectBoxs
                        options={nCdList}
                        defaultOption={tableState[i].nName || t('미선택')}
                        state={tableState}
                        setState={setTableState}
                        stateKey='nCd'
                        codeKey='cdName'
                        index={i}
                        useFlag={false}
                        dropDownWidth='fit-content'
                        optionHeight='height-sm'
                        disabled={false}
                        hasSubList={{ codeKey: 'cCd', nameKey: 'cName' }}
                        getBorderStyle={isSaveClicked ? applyBorderStyle(tableState[i].nCd, 'red', 'nCd') : undefined}
                        initiateKey={tableState[i].nName}
                      />
                    </div>
                    <div className='trCol12 flex-center selectBox'>
                      <SearchSelectBoxs
                        options={findArrayByKey(tableState[i].nCd)}
                        defaultOption={tableState[i].cName || t('미선택')}
                        state={tableState}
                        setState={setTableState}
                        stateKey='cCd'
                        codeKey='cdName'
                        index={i}
                        useFlag={false}
                        dropDownWidth='fit-content'
                        optionHeight='height-sm'
                        disabled={false}
                        getBorderStyle={isSaveClicked ? applyBorderStyle(tableState[i].cCd, 'red', 'cCd') : undefined}
                        initiateKey={tableState[i].cName}
                      />
                    </div>
                    <div className='trCol10 flex-center selectBox'>
                      <SelectBoxs
                        options={useYnComCdList}
                        defaultOption={el.useYn === 'Y' ? t('사용') : t('미사용')}
                        state={tableState}
                        setState={setTableState}
                        rawData={initTableState}
                        setRawData={setInitTableState}
                        stateKey='useYn'
                        codeKey='cdName'
                        index={i}
                        object={el}
                        primaryKey='tacCd'
                        initiateKey={tableState}
                      />
                    </div>
                    {auth.deleteAuth && (
                      <div className='trCol4 flex-center content-overflow'>
                        <BtnRed onClick={() => onClickDelete(el, i)}>{el.tacCd ? t('삭제') : t('제거')}</BtnRed>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <IssueGuide />
          )
        ) : (
          <IssueGuide text='구역 및 시/종점 정보를 선택해주세요' illustrator={{ visible: true, src: illustrator }} />
        )}
      </InputTable>
      <Portal openModal={openModal?.status}>{openModal && openModal.type === 'delete' ? <DeleteModal openModal={openModal} setOpenModal={setOpenModal} /> : undefined}</Portal>
    </>
  );
};

export default TAreaInfo;
