/**
 * 작성자 : 홍선영
 * 날짜 : 2023.10.18
 * 경로 : 안전관리-비인가자 출입 정보 (현장용)
 *
 * 수정자 : 홍선영
 * 날짜 : 2024.02.29
 * 수정내용 : 코드 리팩토링 (상수 분리, 셀렉트박스 코드목록 데이터패치 분리, 이미지TuiGrid 분리하여 재사용)
 *
 */

import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PulseLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

import { ITabObject } from 'customTypes';
import { IUser, pageInfoState, userState } from '../../atoms';
import { ContentsContainerRoot } from '../../assets/styles/ContentsContainerRoot';
import { LoadingModalBackground } from '../../assets/styles/Modal';
import Tab from '../../components/Tab';
import IssueGuide from '../../components/IssueGuide';
import TuiGridImage from '../../components/TuiGridImage';
import SearchFilterForUnattendPerson from './safe5/SearchFilterForUnattendPerson';
import { formatDate, todayYYYYMMDD } from '../../utils/formatDate';
import useSetListCount from '../../utils/useSetListCount';
import { apiGet } from '../../services/_common';
import { logPost } from '../../services/log';
import { useFetchEquipCodeList } from '../../services/useSetCodeListInSelectBoxForm';

const Root = styled(ContentsContainerRoot)`
  display: flex;
  flex-direction: column;
`;

const Ssafe5 = () => {
  const { t } = useTranslation();
  const INIT_EQUIP = { type: 'equip', equip: 'A', cdName: t('전체') };
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  const userInfo = useRecoilValue<IUser>(userState);
  const { userMenuList } = userInfo;
  const [newTabList, setNewTabList] = useState<ITabObject[]>();
  const [equip, setEquip] = useState(INIT_EQUIP);
  const toDay = todayYYYYMMDD();
  const [userInputDateRange, setUserInputDateRange] = useState({ start: toDay, end: toDay });
  const { listCount, setListCount, patchUserMenuAPI } = useSetListCount(); // 검색줄수설정 훅
  const { data: equipList, isError: isErrorEquipList, isLoading: isLoadingEquipList } = useFetchEquipCodeList(); // 장비목록 조회 useQuery

  useEffect(() => {
    const arr: any[] = [];
    userMenuList.map((v: any) => {
      const result = tabInfo?.tabList?.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  const onClickTab = (mrCd: string) => {
    setCurrentTabMrCd(mrCd);
  };

  const fetchUnpersonDataInTuiGridForm = async (hCd: string, sCd: string, equipCd: string, date1: string, date2: string) => {
    try {
      const req = { hCd, sCd, dvNo: equipCd, sDate1: date1?.replaceAll('-', ''), sDate2: date2.replaceAll('-', '') };
      const res = await apiGet({ path: '/safe/unperson', req });
      const formattedList = res.data.data.map((item: any, index: number) => ({
        index,
        url: item.url,
        dvName: item.dvName,
        sensorNo: item.sensorNo,
        date: formatDate(item.date),
      }));
      const formattedTableData = formattedList.map((el: any, index: number) => ({
        cellData: `<div key=${index}>
            <div className='flex-flex flex-col'>
              <img src=${el.url} alt='' />
            </div>
            <div>
              <ul>
                <li>${t('장비')} : ${el.dvName}</li>
                <li>${el.date}</li>
              </ul>
            </div>
          </div>`,
        url: el.url,
      }));
      return formattedTableData;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  // 비인가자 조회 useQuery
  const {
    data: unpersonList,
    isLoading: isLoadingUnpersonList,
    isRefetching: isRefetchingUnpersonList,
    isError: isErrorUnpersonList,
    isInitialLoading: InitLoadingUnpersonList,
    refetch,
  } = useQuery(
    ['safeUnpersonGet', userInfo.hCd, userInfo.sCd],
    () => fetchUnpersonDataInTuiGridForm(userInfo.hCd, userInfo.sCd, equip.equip !== 'A' ? equip.equip : '', userInputDateRange.start, userInputDateRange.end),
    {
      // enabled: !!userInfo.hCd && !!userInfo.sCd && userInputDateRange.start !== '' && userInputDateRange.end !== '',
      enabled: false,
      cacheTime: 0,
    }
  );

  const renderPage = () => {
    const activeTab = newTabList?.find((el: ITabObject) => el.mrCd === currentTabMrCd);
    if (activeTab !== undefined) {
      const { mrCd } = activeTab;
      switch (mrCd) {
        case '001':
          return (
            <>
              <SearchFilterForUnattendPerson
                equipList={equipList || []}
                equip={equip}
                setEquip={setEquip}
                userInputDateRange={userInputDateRange}
                setUserInputDateRange={setUserInputDateRange}
                listCount={listCount}
                setListCount={setListCount}
                patchUserMenuAPI={patchUserMenuAPI}
                dataCount={unpersonList?.length || 0}
                refetch={refetch}
              />
              <TuiGridImage data={unpersonList} listCount={listCount} loading={InitLoadingUnpersonList || isRefetchingUnpersonList} error={isErrorUnpersonList} />
            </>
          );
      }
    }
    return undefined;
  };

  if (isErrorEquipList) return <IssueGuide />;
  if (isLoadingEquipList)
    return (
      <LoadingModalBackground>
        <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
      </LoadingModalBackground>
    );

  return (
    <div className='contents'>
      <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      <div className='content-container oneColumn'>
        <Root loading={isLoadingEquipList || InitLoadingUnpersonList || isRefetchingUnpersonList}>{renderPage()}</Root>
      </div>
    </div>
  );
};

export default Ssafe5;
