import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem;
  border-radius: 2rem;
  background-color: ${({ theme }: { theme: any }) => theme.tonal};
  user-select: none;
  .title {
    display: flex;
    align-items: center;
    gap: 0.375rem;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    .material-symbols-rounded {
      margin-left: 0.25rem;
      font-size: 1.125rem;
      font-variation-settings: 'FILL' 1;
    }
    .label {
      font-size: 0.75rem;
      font-weight: 600;
      margin-right: 0.25rem;
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .button {
      padding: 0 1rem;
      border-radius: 2rem;
      min-width: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 0.875rem;
      font-weight: 600;
      user-select: none;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.1);
    }
  }
  &:hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
    .button:hover {
      font-weight: 400;
      background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
      color: ${({ theme }: { theme: any }) => theme.text_reverse};
    }
  }
`;

type Props = {
  icon: string;
  label?: string;
  options: { title: string; path: null | string; name: string }[];
  onClick: (path: null | string) => void;
};

const OpenFolderButtons = ({ icon, label, options, onClick }: Props) => {
  return (
    <Root>
      <div className='title'>
        <span className='material-symbols-rounded'>{icon}</span>
        {label && <span className='label'>{label}</span>}
      </div>
      <div className='buttons'>
        {options.map((button: any) => (
          <span //
            key={button.title}
            className='button'
            onClick={() => onClick(button.path)}
            // onClick={() => onClick('C:\\Users\\itlog\\Desktop\\demo')}
            role='button'
            tabIndex={0}
          >
            {button.name}
          </span>
        ))}
      </div>
    </Root>
  );
};

export default OpenFolderButtons;
