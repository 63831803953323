import { useTranslation } from 'react-i18next';

const WorkerInfoPrintForm = ({ item }: any) => {
  const { t } = useTranslation();
  const randomString = Math.random().toString(36).substring(7); // 랜덤 문자열을 쿼리스트링으로 추가해서 이미지 캐싱 방지

  return (
    <table className='custom-table'>
      <tbody>
        <tr>
          <td>{t('성명')}</td>
          <td>{item.orgWName}</td>
          <td>{t('생년월일')}</td>
          <td>{item.wBdate}</td>
        </tr>
        <tr>
          <td>{t('휴대폰')}</td>
          <td>{item.wHnum}</td>
          <td>{t('비상연락처')}</td>
          <td>{item.wEnum}</td>
        </tr>
        <tr>
          <td>{t('협력업체')}</td>
          <td>{item.sjName}</td>
          <td>{t('직종')}</td>
          <td>{item.wJobtypeName}</td>
        </tr>
        <tr>
          <td>{t('개인보호구')}</td>
          <td>{item.wSafetydeviceText}</td>
          <td>{t('약 복용여부')}</td>
          <td>{item.takeMedicineText}</td>
        </tr>
        <tr>
          <td>{t('맥박수')}</td>
          <td>{item.pulseRate}</td>
          <td>{t('혈액형')}</td>
          <td>{item.wBlood}</td>
        </tr>
        <tr>
          <td>{t('최저혈압')}</td>
          <td>{item.wLowpress}</td>
          <td>{t('최고혈압')}</td>
          <td>{item.wHighpress}</td>
        </tr>
        <tr>
          <td>{t('기초안전보건교육 등록번호')}</td>
          <td>{item.safeeduReg}</td>
          <td>{t('기초안전보건교육 이수일자')}</td>
          <td>{item.safeeduDate}</td>
        </tr>
        <tr>
          <td>{t('직무스트레스 총점수')}</td>
          <td>{item.stressTotal}</td>
          <td>{t('직무스트레스 결과')}</td>
          <td>{item.stressResult}</td>
        </tr>
        <tr>
          <td>{t('외국인 등록번호')}</td>
          <td>{item.wFregnum}</td>
          <td>{t('비자종류')}</td>
          <td>{item.wVisa}</td>
        </tr>
        <tr>
          <td>{t('외국인 등록증 발급일자')}</td>
          <td colSpan={3}>{item.wFregdate}</td>
        </tr>
        <tr>
          <td>{t('주소')}</td>
          <td colSpan={3}>{`${item.wAddress1 ? item.wAddress1 : ''} ${item.wAddress2 ? item.wAddress2 : ''}`}</td>
        </tr>
        <tr>
          <td>{t('취업일자')}</td>
          <td>{item.wJobdate}</td>
          <td>{t('안전교육서명')}</td>
          <td>{item.nedusignImg ? <img src={`${item.nedusignImg}?v=${randomString}`} alt={t('안전교육 서명')} style={{ maxHeight: '3rem' }} /> : null}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default WorkerInfoPrintForm;
