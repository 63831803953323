import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../../translation/i18n';

const Root = styled.div`
  height: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  background-color: ${({ theme }: { theme: any }) => theme.board};
  * {
    line-height: normal;
  }
  .current-date {
    height: 2rem;
    font-size: 1.2rem;
    text-align: center;
    display: flex;
    align-items: flex-end;
    font-weight: 500;
    letter-spacing: -0.025rem;
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
  }
  .current-time {
    flex-grow: 1;
    text-align: center;
    height: 3.75rem;
    font-size: 4rem;
    font-weight: 700;
    display: flex;
    gap: 0.5rem;
    align-items: flex-end;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
    padding-bottom: 0.75rem;
    .ampm {
      font-weight: 200;
      letter-spacing: -0.025rem;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
    }
  }
`;

const DateDashboard: React.FC = () => {
  const [date, setDate] = useState<string>('');
  const [currentTime, setCurrentTime] = useState<{ hours: string; minutes: string; ampm: string }>({
    hours: '',
    minutes: '',
    ampm: '',
  });

  useEffect(() => {
    // Update current time every second
    const updateTime = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      const hours = now.getHours() % 12 || 12; // Convert 24-hour format to 12-hour format
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const ampm = now.getHours() >= 12 ? 'PM' : 'AM';

      const daysOfWeekKor = ['일', '월', '화', '수', '목', '금', '토'];
      const daysOfWeekEng = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

      const dayOfWeek = i18n.language === 'ko-KR' ? daysOfWeekKor[now.getDay()] : daysOfWeekEng[now.getDay()];
      const y = i18n.language === 'ko-KR' ? '년' : '-';
      const m = i18n.language === 'ko-KR' ? '월' : '-';
      const d = i18n.language === 'ko-KR' ? '일' : '';

      setDate(`${year}${y} ${month}${m} ${day}${d}(${dayOfWeek})`);
      setCurrentTime({ hours: hours.toString(), minutes, ampm });
    };

    const intervalId = setInterval(() => {
      updateTime();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Root>
      <div className='current-date'>{date}</div>
      <div className='current-time'>
        {currentTime.hours}:{currentTime.minutes}
        <span className='ampm'>{currentTime.ampm}</span>
      </div>
    </Root>
  );
};

export default DateDashboard;
