import { IAuth } from 'customTypes';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateYMD, todayYYYYMMDD } from '../../utils/formatDate';
import { getPreviousDays } from '../../utils/getPrevWeeks';
import { useQuery } from '@tanstack/react-query';
import { getAgeFromCertainDate } from '../../utils/getAge';
import ImageRenderer from '../ImageRenderer.tsx';
import { PulseLoader } from 'react-spinners';
import { BtnGhost } from '../Button';
import TuiGrid from '../Table/TuiGrid';
import Portal from '../Portal';
import InfoWorkerModal from '../Modal/InfoWorkerModal';
import i18n from '../../translation/i18n';
import EnrollmentAttendModal from '../Modal/EnrollmentAttendModal';
import { apiGet } from '../../services/_common';
import useTodayDate from '../../hooks/useTodayDate';

// 신규 근로자
export const NewWorker = ({ name, userInfo, openModal, setOpenModal }: any) => {
  const { t } = useTranslation();
  const { selectedDay, setSelectedDay } = useTodayDate(null);
  const today = todayYYYYMMDD();
  const [selectedDayString, setSelectedDayString] = useState(today);
  const {
    data: newWorkerData,
    refetch,
    isLoading,
    isFetching,
  } = useQuery(['newWorkerGet', userInfo.sCd, selectedDayString], () => fetchNewWorkerList(), {
    enabled: !!selectedDayString && !!userInfo.sCd && userInfo.sCd !== '00000',
  });
  const [dashboardAuth, setDashboardAuth] = useState<IAuth>({ createAuth: false, updateAuth: false, deleteAuth: false, excelAuth: false, printAuth: false });
  const toDay = todayYYYYMMDD();
  const twoWeekDays = getPreviousDays(toDay, 14);
  const [columns, setColumns] = useState<any[]>([]);
  const [tableState, setTableState] = useState<any[]>(newWorkerData || []);
  // const [openModal, setOpenModal] = useState<any>({ status: false, type: '', code: '', index: 0 });
  const [filterTableState, setFilterTableState] = useState<any[]>([]);
  const [rowKey, setRowKey] = useState('');

  useEffect(() => {
    setRowKey('');
  }, [selectedDay]);

  useEffect(() => {
    if (userInfo.userMenuList) {
      if (userInfo.gCd === 'MM') setDashboardAuth({ createAuth: true, updateAuth: true, deleteAuth: true, printAuth: true, excelAuth: true });
      else {
        const menuAuth = userInfo.userMenuList.find((el: any) => el.mtCd === 'S002' && el.mdCd === '001' && el.mrCd === '001');
        if (menuAuth !== undefined) {
          setDashboardAuth({
            createAuth: menuAuth.cYn === 'Y',
            updateAuth: menuAuth.uYn === 'Y',
            deleteAuth: menuAuth.dYn === 'Y',
            excelAuth: menuAuth.eYn === 'Y',
            printAuth: menuAuth.pYn === 'Y',
          });
        }
      }
    }
  }, [userInfo.userMenuList]);

  const fetchNewWorkerList = async () => {
    try {
      const path = '/worker';
      const req = { wWorkstatus: 'I', wJobdate1: selectedDayString, wJobdate2: selectedDayString, hCd: userInfo.hCd, sCd: userInfo.sCd };
      const res = await apiGet({ path, req });
      const { workerList } = res.data.data;
      const newArray = workerList?.map((el: any) => {
        const valueOfAge = getAgeFromCertainDate(el.wBdate, selectedDay);
        const wNameFormatted = `${el.wName?.substring(0, 1)}*${el.wName?.substring(2)}`;
        const wJobtypeDiv = el.wJobtypeName ? `<div>${el.wJobtypeName}</div>` : '';

        const badgeOfAge = `<span class="badge">${valueOfAge}${t('세')}</span>`;
        const isOldAge = Number(userInfo.elderlyAgeSet) <= valueOfAge;
        const badgeOfOlder = isOldAge ? `<span class="badge red">${t('고령')}</span>` : '';
        const noBirthData = '<span class="disabled" >생년월일 미입력</span>';

        const wBdateSpan = el.wBdate ? `${badgeOfAge} ${badgeOfOlder}` : noBirthData;

        return {
          ...el,
          aImg: el.wImg,
          wNamewJobtype: `${wNameFormatted}${wJobtypeDiv}`,
          sjName: el.sjName,
          wBdatewAge: `${el.wBdate ? el.wBdate : ''}${wBdateSpan}`,
          wJobdate: el.wJobdate,
        };
      });
      setTableState(newArray);
      return newArray;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  useEffect(() => {
    setColumns([
      {
        header: t('등록 사진'),
        name: 'aImg',
        align: 'center',
        renderer: {
          type: ImageRenderer,
        },
        width: 80,
      },
      {
        header: t('근로자명/직종'),
        name: 'wNamewJobtype',
        sortable: true,
        minWidth: 120,
        renderer: { classNames: ['line-2-title'] },
      },
      {
        header: t('협력업체명'),
        name: 'sjName',
        sortable: true,
        minWidth: 120,
      },
      {
        header: t('생년월일/연령'),
        name: 'wBdatewAge',
        sortable: true,
        minWidth: 180,
        renderer: { classNames: ['line-2-birth'] },
      },
      {
        header: t('취업일자'),
        name: 'wJobdate',
        align: 'center',
        sortable: true,
        minWidth: 120,
      },
    ]);
  }, [i18n.language]);

  const onClickDate = (dateParam: string) => {
    setSelectedDay(dayjs(dateParam));
    setSelectedDayString(dateParam.replaceAll('-', ''));
  };

  // 신규 등록 버튼 클릭
  const onClickNewWorker = () => {
    setOpenModal({ status: true, type: 'infoWorker', code: '' });
  };

  // 근로자 Row 클릭
  const onClickRow = (rowData: any, columnName: any, filteredIndex: number) => {
    setRowKey(`${filteredIndex}`);
    setOpenModal({ status: true, type: 'infoWorker', code: rowData.wCd, aDate: dayjs().format('YYYY-MM-DD'), index: filteredIndex });
  };

  const onClickRefresh = () => {
    refetch();
  };

  // if (!newWorkerQuery.isSuccess || newWorkerQuery.data.status !== 200 || newWorkerQuery.isRefetching) {
  //   return (
  //     <div className='centered-content'>
  //       <PulseLoader color='rgb(0, 122, 255)' size='10px' />
  //     </div>
  //   );
  // }
  return (
    <div className='newWorker'>
      <div className='widget-header margin-top'>
        <div className='widget-title flex-between innerBtn'>
          <span>
            {t(name)}
            <span className='badge'>{tableState.length}</span>
          </span>
          <div className='widget-btn-group'>
            {dashboardAuth.createAuth && (
              <BtnGhost onClick={onClickNewWorker}>
                <span className='material-symbols-rounded'>add</span>
                {t('등록')}
              </BtnGhost>
            )}
            <div className='refresh-btn' onClick={onClickRefresh} role='presentation'>
              <span className='material-symbols-rounded'>refresh</span>
            </div>
          </div>
        </div>
        <div className='oneWeekCalendar-wrapper'>
          <div className='oneWeekCalendar flex-between'>
            {twoWeekDays.map((el: any) => (
              <div
                key={el.date}
                className={`${el.fullDate === formatDateYMD(selectedDayString) ? 'todayHighlight' : ''} dayWrapper`}
                role='button'
                tabIndex={0}
                onClick={() => onClickDate(el.fullDate)}
              >
                <div className='day'>{t(el.dayName)}</div>
                <div className='date bold'>{el.date}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {isLoading || isFetching || !newWorkerData ? (
        <div className='centered-content' style={{ width: '100%' }}>
          <PulseLoader color='rgb(0, 122, 255)' size='10px' />
        </div>
      ) : (
        <div className='tuiGridWrapper scroll'>
          <TuiGrid
            data={tableState}
            filterTableState={filterTableState}
            setFilterTableState={setFilterTableState}
            columns={columns}
            height={276}
            // usePagenation
            perPage={tableState.length}
            rowHeight={56}
            onClickRow={onClickRow}
            rowKey={rowKey}
            dashboard
            setRowKey={setRowKey}
          />
        </div>
      )}
      <Portal openModal={openModal?.status}>
        {openModal.status && openModal.type === 'infoWorker' && openModal.code !== undefined && (
          <InfoWorkerModal
            innerTabIndex={0}
            getReportListAPI={onClickRefresh}
            reportArray={filterTableState}
            setReportArray={setFilterTableState}
            setOpenModal={setOpenModal}
            auth={dashboardAuth}
            code={{ hCd: userInfo.hCd, sCd: userInfo.sCd, wCd: openModal.code, aDate: openModal.aDate }}
            index={openModal.index}
            setRowKey={setRowKey}
          />
        )}
      </Portal>
    </div>
  );
};

// 신규 고령 근로자
export const NewOldWorker = ({ name, userInfo, openModal, setOpenModal }: any) => {
  const { t } = useTranslation();
  const { selectedDay, setSelectedDay } = useTodayDate(null);
  const today = todayYYYYMMDD();
  const [selectedDayString, setSelectedDayString] = useState(today);
  const {
    data: newWorkerData,
    refetch,
    isLoading,
    isFetching,
  } = useQuery(['newOldWorkerGet', userInfo.sCd, selectedDayString], () => fetchNewWorkerList(), {
    enabled: !!selectedDayString && !!userInfo.sCd && userInfo.sCd !== '00000',
  });
  const [dashboardAuth, setDashboardAuth] = useState<IAuth>({ createAuth: false, updateAuth: false, deleteAuth: false, excelAuth: false, printAuth: false });
  const toDay = todayYYYYMMDD();
  const twoWeekDays = getPreviousDays(toDay, 14);
  const [columns, setColumns] = useState<any[]>([]);
  const [tableState, setTableState] = useState<any[]>(newWorkerData || []);
  // const [openModal, setOpenModal] = useState<any>({ status: false, type: '', code: '', index: 0 });
  const [filterTableState, setFilterTableState] = useState<any[]>([]);
  const [rowKey, setRowKey] = useState('');

  useEffect(() => {
    setRowKey('');
  }, [selectedDay]);

  useEffect(() => {
    if (userInfo.userMenuList) {
      if (userInfo.gCd === 'MM') setDashboardAuth({ createAuth: true, updateAuth: true, deleteAuth: true, printAuth: true, excelAuth: true });
      else {
        const menuAuth = userInfo.userMenuList.find((el: any) => el.mtCd === 'S002' && el.mdCd === '001' && el.mrCd === '001');
        if (menuAuth !== undefined) {
          setDashboardAuth({
            createAuth: menuAuth.cYn === 'Y',
            updateAuth: menuAuth.uYn === 'Y',
            deleteAuth: menuAuth.dYn === 'Y',
            excelAuth: menuAuth.eYn === 'Y',
            printAuth: menuAuth.pYn === 'Y',
          });
        }
      }
    }
  }, [userInfo.userMenuList]);

  const fetchNewWorkerList = async () => {
    try {
      const path = '/worker';
      const req = {
        wWorkstatus: 'I',
        oldDate1: '',
        oldDate2: String(Number(selectedDayString) - Number(`${userInfo.elderlyAgeSet}0000`)),
        wJobdate1: selectedDayString,
        wJobdate2: selectedDayString,
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
      };
      const res = await apiGet({ path, req });
      const { workerList } = res.data.data;
      const removeNoBdateWorker = workerList.filter((el: any) => el.wBdate !== null);
      const newArray = removeNoBdateWorker?.map((el: any) => {
        const valueOfAge = getAgeFromCertainDate(el.wBdate, selectedDay);
        const wNameFormatted = `${el.wName?.substring(0, 1)}*${el.wName?.substring(2)}`;
        const wJobtypeDiv = el.wJobtypeName ? `<div>${el.wJobtypeName}</div>` : '';

        const badgeOfAge = `<span class="badge">${valueOfAge}${t('세')}</span>`;
        const noBirthData = '<span class="disabled" >생년월일 미입력</span>';

        const wBdateSpan = el.wBdate ? badgeOfAge : noBirthData;

        return {
          ...el,
          aImg: el.wImg,
          wNamewJobtype: `${wNameFormatted}${wJobtypeDiv}`,
          sjName: el.sjName,
          wBdatewAge: `${el.wBdate ? el.wBdate : ''}${wBdateSpan}`,
          wJobdate: el.wJobdate,
        };
      });
      setTableState(newArray);
      return newArray;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  useEffect(() => {
    setColumns([
      {
        header: t('등록 사진'),
        name: 'aImg',
        align: 'center',
        renderer: {
          type: ImageRenderer,
        },
        width: 60,
      },
      {
        header: t('근로자명/직종'),
        name: 'wNamewJobtype',
        sortable: true,
        minWidth: 120,
        renderer: { classNames: ['line-2-title'] },
      },
      {
        header: t('협력업체명'),
        name: 'sjName',
        sortable: true,
        minWidth: 120,
      },
      {
        header: t('생년월일/연령'),
        name: 'wBdatewAge',
        sortable: true,
        align: 'center',
        minWidth: 120,
        renderer: { classNames: ['line-2-birth'] },
      },
      {
        header: t('취업일자'),
        name: 'wJobdate',
        align: 'center',
        sortable: true,
        minWidth: 120,
      },
    ]);
  }, [i18n.language]);

  const onClickDate = (dateParam: string) => {
    setSelectedDay(dayjs(dateParam));
    setSelectedDayString(dateParam.replaceAll('-', ''));
  };

  // 신규 등록 버튼 클릭
  const onClickNewWorker = () => {
    setOpenModal({ status: true, type: 'infoWorker', code: '' });
  };

  // 근로자 Row 클릭
  const onClickRow = (rowData: any, columnName: any, filteredIndex: number) => {
    setRowKey(`${filteredIndex}`);
    setOpenModal({ status: true, type: 'infoWorker', code: rowData.wCd, aDate: dayjs().format('YYYY-MM-DD'), index: filteredIndex });
  };

  const onClickRefresh = () => {
    refetch();
  };

  // if (!newWorkerQuery.isSuccess || newWorkerQuery.data.status !== 200 || newWorkerQuery.isRefetching) {
  //   return (
  //     <div className='centered-content'>
  //       <PulseLoader color='rgb(0, 122, 255)' size='10px' />
  //     </div>
  //   );
  // }

  return (
    <div className='newWorker'>
      <div className='widget-header margin-top'>
        <div className='widget-title flex-between innerBtn'>
          <span>
            {t(name)}
            <span className='badge'>{tableState.length}</span>
          </span>
          <div className='widget-btn-group'>
            {dashboardAuth.createAuth && (
              <BtnGhost onClick={onClickNewWorker}>
                <span className='material-symbols-rounded'>add</span>
                {t('등록')}
              </BtnGhost>
            )}
            <div className='refresh-btn' onClick={onClickRefresh} role='presentation'>
              <span className='material-symbols-rounded'>refresh</span>
            </div>
          </div>
        </div>
        <div className='oneWeekCalendar-wrapper'>
          <div className='oneWeekCalendar flex-between'>
            {twoWeekDays.map((el: any) => (
              <div
                key={el.date}
                className={`${el.fullDate === formatDateYMD(selectedDayString) ? 'todayHighlight' : ''} dayWrapper`}
                role='button'
                tabIndex={0}
                onClick={() => onClickDate(el.fullDate)}
              >
                <div className='day'>{t(el.dayName)}</div>
                <div className='date bold'>{el.date}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {isLoading || isFetching || !newWorkerData ? (
        <div className='centered-content' style={{ width: '100%' }}>
          <PulseLoader color='rgb(0, 122, 255)' size='10px' />
        </div>
      ) : (
        <div className='tuiGridWrapper scroll'>
          <TuiGrid
            data={tableState}
            filterTableState={filterTableState}
            setFilterTableState={setFilterTableState}
            columns={columns}
            height={276}
            // usePagenation
            perPage={tableState.length}
            onClickRow={onClickRow}
            rowKey={rowKey}
            dashboard
            rowHeight={56}
            setRowKey={setRowKey}
          />
        </div>
      )}
      <Portal openModal={openModal?.status}>
        {openModal.status && openModal.type === 'infoWorker' && openModal.code !== undefined && (
          <InfoWorkerModal
            innerTabIndex={0}
            getReportListAPI={onClickRefresh}
            reportArray={filterTableState}
            setReportArray={setFilterTableState}
            setOpenModal={setOpenModal}
            auth={dashboardAuth}
            code={{ hCd: userInfo.hCd, sCd: userInfo.sCd, wCd: openModal.code, aDate: openModal.aDate }}
            index={openModal.index}
            setRowKey={setRowKey}
          />
        )}
      </Portal>
    </div>
  );
};

// 고령 근로자
export const OldWorker = ({ name, userInfo, openModal, setOpenModal }: any) => {
  const todayjs = dayjs();
  const { t } = useTranslation();
  const [dashboardAuth, setDashboardAuth] = useState<IAuth>({ createAuth: false, updateAuth: false, deleteAuth: false, excelAuth: false, printAuth: false });
  const [columns, setColumns] = useState<any[]>([]);
  const [tableState, setTableState] = useState<any[]>([]);
  // const [openModal, setOpenModal] = useState<any>({ status: false, type: '', code: '', index: 0 });
  const [filterTableState, setFilterTableState] = useState<any[]>([]);
  const [rowKey, setRowKey] = useState('');

  useEffect(() => {
    if (userInfo.userMenuList) {
      if (userInfo.gCd === 'MM') setDashboardAuth({ createAuth: true, updateAuth: true, deleteAuth: true, printAuth: true, excelAuth: true });
      else {
        const menuAuth = userInfo.userMenuList.find((el: any) => el.mtCd === 'S002' && el.mdCd === '001' && el.mrCd === '001');
        if (menuAuth !== undefined) {
          setDashboardAuth({
            createAuth: menuAuth.cYn === 'Y',
            updateAuth: menuAuth.uYn === 'Y',
            deleteAuth: menuAuth.dYn === 'Y',
            excelAuth: menuAuth.eYn === 'Y',
            printAuth: menuAuth.pYn === 'Y',
          });
        }
      }
    }
  }, [userInfo.userMenuList]);

  const oldWorkerQuery = useQuery(
    ['oldWorkerGet', userInfo],
    () =>
      apiGet({
        path: '/worker',
        req: {
          wWorkstatus: 'I',
          oldDate1: '',
          oldDate2: String(Number(todayjs.format('YYYYMMDD')) - Number(`${userInfo.elderlyAgeSet}0000`)),
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
        },
      }),
    {
      enabled: !!todayjs && !!userInfo.sCd && userInfo.sCd !== '00000',
    }
  );

  useEffect(() => {
    if (oldWorkerQuery.isSuccess && oldWorkerQuery.data?.data.statusCode === 200) {
      const { workerList } = oldWorkerQuery.data.data.data;
      const newArray = workerList?.filter((el: any) => el.wBdate !== null);

      const newArray2 = newArray?.map((el: any) => {
        const valueOfAge = getAgeFromCertainDate(el.wBdate, todayjs);
        const wNameFormatted = `${el.wName?.substring(0, 1)}*${el.wName?.substring(2)}`;
        const wJobtypeDiv = el.wJobtypeName ? `<div>${el.wJobtypeName}</div>` : '';

        const badgeOfAge = `<span class="badge">${valueOfAge}${t('세')}</span>`;

        return {
          ...el,
          aImg: el.wImg,
          wNamewJobtype: `${wNameFormatted}${wJobtypeDiv}`,
          sjName: el.sjName,
          wBdatewAge: `${el.wBdate}${badgeOfAge}`,
          wJobdate: el.wJobdate,
        };
      });
      setTableState(newArray2);
    }
  }, [oldWorkerQuery.isSuccess, oldWorkerQuery.isRefetching]);

  useEffect(() => {
    setColumns([
      {
        header: t('등록 사진'),
        name: 'aImg',
        align: 'center',
        renderer: {
          type: ImageRenderer,
        },
        width: 60,
      },
      {
        header: t('근로자명/직종'),
        name: 'wNamewJobtype',
        sortable: true,
        renderer: { classNames: ['line-2-title'] },
        minWidth: 120,
      },
      {
        header: t('협력업체명'),
        name: 'sjName',
        sortable: true,
        minWidth: 120,
      },
      {
        header: t('생년월일/연령'),
        name: 'wBdatewAge',
        sortable: true,
        minWidth: 120,
        renderer: { classNames: ['line-2-birth'] },
      },
      {
        header: t('취업일자'),
        name: 'wJobdate',
        align: 'center',
        sortable: true,
        minWidth: 120,
      },
    ]);
  }, [i18n.language]);

  // 신규 등록 버튼 클릭
  const onClickNewWorker = () => {
    setOpenModal({ status: true, type: 'infoWorker', code: '' });
  };

  // 근로자 Row 클릭
  const onClickRow = (rowData: any, columnName: any, filteredIndex: number) => {
    setRowKey(`${filteredIndex}`);
    setOpenModal({ status: true, type: 'infoWorker', code: rowData.wCd, aDate: dayjs().format('YYYY-MM-DD'), index: filteredIndex });
  };

  const onClickRefresh = () => {
    oldWorkerQuery.refetch();
  };

  if (!oldWorkerQuery.isSuccess || oldWorkerQuery.data.status !== 200 || oldWorkerQuery.isRefetching) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <div className='oldWorker'>
      <div className='widget-header'>
        <div className='widget-title flex-between innerBtn'>
          <span>
            {t(name)}
            <span className='badge'>{tableState.length}</span>
          </span>
          <div className='widget-btn-group'>
            {dashboardAuth.createAuth && (
              <BtnGhost onClick={onClickNewWorker}>
                <span className='material-symbols-rounded'>add</span>
                {t('등록')}
              </BtnGhost>
            )}
            <div className='refresh-btn' onClick={onClickRefresh} role='presentation'>
              <span className='material-symbols-rounded'>refresh</span>
            </div>
          </div>
        </div>
      </div>
      <div className='tuiGridWrapper scroll'>
        <TuiGrid
          data={tableState}
          filterTableState={filterTableState}
          setFilterTableState={setFilterTableState}
          columns={columns}
          height={344}
          perPage={tableState.length}
          onClickRow={onClickRow}
          rowKey={rowKey}
          dashboard
          rowHeight={56}
          setRowKey={setRowKey}
        />
      </div>
      <Portal openModal={openModal?.status}>
        {openModal.status && openModal.type === 'infoWorker' && openModal.code !== undefined && (
          <InfoWorkerModal
            innerTabIndex={0}
            getReportListAPI={onClickRefresh}
            reportArray={filterTableState}
            setReportArray={setFilterTableState}
            setOpenModal={setOpenModal}
            auth={dashboardAuth}
            code={{ hCd: userInfo.hCd, sCd: userInfo.sCd, wCd: openModal.code, aDate: openModal.aDate }}
            index={openModal.index}
            setRowKey={setRowKey}
          />
        )}
      </Portal>
    </div>
  );
};

// 근로자 출역현황
export const WorkerAttend = ({ name, userInfo, openModal, setOpenModal, showImg }: any) => {
  const { selectedDay, setSelectedDay } = useTodayDate(null);
  const today = todayYYYYMMDD();
  const [selectedDayString, setSelectedDayString] = useState(today);

  const {
    data: workerAttendDayData,
    refetch,
    isLoading,
    isFetching,
  } = useQuery(['workerAttendGet', userInfo.sCd, selectedDayString], () => fetchAttendList(), {
    enabled: !!selectedDayString && !!userInfo.sCd && userInfo.sCd !== '00000',
  });

  const { t } = useTranslation();
  const [dashboardAuth, setDashboardAuth] = useState<IAuth>({ createAuth: false, updateAuth: false, deleteAuth: false, excelAuth: false, printAuth: false });
  const toDay = todayYYYYMMDD();
  const twoWeekDays = getPreviousDays(toDay, 14);
  const [columns, setColumns] = useState<any[]>([]);
  const [tableState, setTableState] = useState<any[]>(workerAttendDayData || []);
  // const [openModal, setOpenModal] = useState<any>({ status: false, type: '', code: '', index: 0 });
  const [filterTableState, setFilterTableState] = useState<any[]>([]);
  const [rowKey, setRowKey] = useState('');

  const imgColumn = {
    header: t('출역 사진'),
    name: 'aImg',
    align: 'center',
    renderer: {
      type: ImageRenderer,
    },
    width: 80,
  };
  const fixedSuffixColumns = [
    {
      header: t('근로자명/직종'),
      name: 'wNamewJobtype',
      sortable: true,
      minWidth: 120,
      renderer: { classNames: ['line-2-title'] },
    },
    {
      header: t('협력업체 명/위치'),
      name: 'sjName',
      sortable: true,
      minWidth: 120,
      renderer: { classNames: ['line-2-title'] },
    },
    {
      header: t('생년월일/구분'),
      name: 'wBdatewAge',
      sortable: true,
      minWidth: 180,
      renderer: { classNames: ['line-2-birth'] },
    },
    {
      header: t('출역일자/시간'),
      name: 'aTimeMax',
      align: 'center',
      sortable: true,
      minWidth: 120,
      renderer: { classNames: ['line-2-title'] },
    },
  ];

  useEffect(() => {
    const col = showImg ? [imgColumn, ...fixedSuffixColumns] : fixedSuffixColumns;
    setColumns(col);
  }, [i18n.language]);

  useEffect(() => {
    setRowKey('');
  }, [selectedDay]);

  useEffect(() => {
    if (userInfo.userMenuList) {
      if (userInfo.gCd === 'MM') setDashboardAuth({ createAuth: true, updateAuth: true, deleteAuth: true, printAuth: true, excelAuth: true });
      else {
        const menuAuth = userInfo.userMenuList.find((el: any) => el.mtCd === 'S003' && el.mdCd === '001' && el.mrCd === '001');
        if (menuAuth !== undefined) {
          setDashboardAuth({
            createAuth: menuAuth.cYn === 'Y',
            updateAuth: menuAuth.uYn === 'Y',
            deleteAuth: menuAuth.dYn === 'Y',
            excelAuth: menuAuth.eYn === 'Y',
            printAuth: menuAuth.pYn === 'Y',
          });
        }
      }
    }
  }, [userInfo.userMenuList]);

  useEffect(() => {
    if (workerAttendDayData) {
      setTableState(workerAttendDayData);
    }
  }, [workerAttendDayData]);

  const fetchAttendList = async () => {
    try {
      const path = '/attend/day';
      const req = {
        aDate1: selectedDayString,
        aDate2: selectedDayString,
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
      };
      const res = await apiGet({ path, req });
      const { attendList } = res.data.data;
      const newArray = attendList?.map((el: any) => {
        const valueOfAge = getAgeFromCertainDate(el.wBdate, selectedDay);
        const wNameFormatted = `${el.wName?.substring(0, 1)}*${el.wName?.substring(2)}`;
        const wJobtypeDiv = el.wJobtypeName ? `<div>${el.wJobtypeName}</div>` : '';
        const fLocationNameDiv = el.fLocationName ? `<div>${el.fLocationName}</div> ` : '';

        const badgeOfAge = `<span class="badge">${valueOfAge}${t('세')}</span>`;
        const isOldAge = Number(userInfo.elderlyAgeSet) <= valueOfAge;
        const badgeOfOlder = isOldAge ? `<span class="badge red">${t('고령')}</span>` : '';
        const noBirthData = '<span class="disabled" >생년월일 미입력</span>';

        const wBdateSpan = el.wBdate ? `${badgeOfAge} ${badgeOfOlder}` : noBirthData;

        const setBullet = (color: 'red' | 'green') => {
          return `<span class="bullet ${color}"></span>`;
        };

        const goToWork = `<div class="inAndOut green">${setBullet('green')} 출근</div>`;
        const getOffWork = `<div class="inAndOut red">${setBullet('red')} 퇴근</div>`;

        const fInoutDiv = el.fInout === 'O' ? getOffWork : el.fInout ? goToWork : '';

        const aTimeMaxDiv = `<div>${el.aTimeMax}</div>`;

        return {
          ...el,
          aImg: el.aImg,
          wNamewJobtype: `${wNameFormatted}${wJobtypeDiv}`,
          sjName: `${el.sjName} ${fLocationNameDiv}`,
          wBdatewAge: `${el.wBdate ? el.wBdate : ''}${wBdateSpan} ${fInoutDiv}`,
          aTimeMax: `${el.aDate}${aTimeMaxDiv}`,
        };
      });
      setTableState(newArray);
      return newArray;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  const onClickDate = (dateParam: string) => {
    setSelectedDay(dayjs(dateParam));
    setSelectedDayString(dateParam.replaceAll('-', ''));
  };

  // 신규 등록 버튼 클릭
  const onClickNewWorker = () => {
    setOpenModal({ status: true, type: 'enrollmentAttend' });
  };

  useEffect(() => {
    if (openModal.type === 'infoWorker' && openModal.status === false) {
      /**
       * 수정자 : 한영광
       * 수정일자 : 2023.11.06
       * 수정내용 : 모달창 닫을 때 리패치 하게되면 rowkey 에러 발생해서 주석처리함
       *            (리패치가 꼭 필요한 경우 tuigrid 파라미터에 rowkey 삭제 할 예정)
       */
      // 근로자 출역내역 창을 닫을 때 데이터 리패치
      // onClickRefresh();
    }
  }, [openModal]);

  // 근로자 Row 클릭
  const onClickRow = (rowData: any, columnName: any, filteredIndex: number) => {
    setRowKey(`${filteredIndex}`);
    setOpenModal({ status: true, type: 'infoWorker', code: rowData.wCd, aDate: rowData.aDate, index: filteredIndex });
  };

  const onClickRefresh = () => {
    refetch();
  };

  // if (isLoading) {
  //   return (
  //     <div className='centered-content'>
  //       <PulseLoader color='rgb(0, 122, 255)' size='10px' />
  //     </div>
  //   );
  // }

  return (
    <div className='newWorker'>
      <div className='widget-header margin-top'>
        <div className='widget-title flex-between innerBtn'>
          <span>
            {t('근로자 출역현황')}
            <span className='badge'>{tableState?.length || 0}</span>
          </span>
          <div className='widget-btn-group'>
            {dashboardAuth.createAuth && (
              <BtnGhost onClick={onClickNewWorker}>
                <span className='material-symbols-rounded'>add</span>
                {t('등록')}
              </BtnGhost>
            )}
            <div className='refresh-btn' onClick={onClickRefresh} role='presentation'>
              <span className='material-symbols-rounded'>refresh</span>
            </div>
          </div>
        </div>
        <div className='oneWeekCalendar-wrapper'>
          <div className='oneWeekCalendar flex-between'>
            {twoWeekDays.map((el: any) => (
              <div
                key={el.date}
                className={`${el.fullDate === formatDateYMD(selectedDayString) ? 'todayHighlight' : ''} dayWrapper`}
                role='button'
                tabIndex={0}
                onClick={() => onClickDate(el.fullDate)}
              >
                <div className='day'>{t(el.dayName)}</div>
                <div className='date bold'>{el.date}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {isLoading || isFetching || !workerAttendDayData ? (
        <div className='centered-content' style={{ width: '100%' }}>
          <PulseLoader color='rgb(0, 122, 255)' size='10px' />
        </div>
      ) : (
        <div className='tuiGridWrapper scroll'>
          <TuiGrid
            data={tableState}
            filterTableState={filterTableState}
            setFilterTableState={setFilterTableState}
            columns={columns}
            height={276}
            perPage={tableState.length}
            onClickRow={onClickRow}
            rowKey={rowKey}
            rowHeight={56}
            dashboard
            setRowKey={setRowKey}
          />
        </div>
      )}

      <Portal openModal={openModal?.status}>
        {openModal.status && openModal.type === 'infoWorker' && openModal.code !== undefined && (
          <InfoWorkerModal
            innerTabIndex={1}
            getReportListAPI={onClickRefresh}
            reportArray={filterTableState}
            setReportArray={setFilterTableState}
            setOpenModal={setOpenModal}
            auth={dashboardAuth}
            code={{ hCd: userInfo.hCd, sCd: userInfo.sCd, wCd: openModal.code, aDate: openModal.aDate }}
            index={openModal.index}
            setRowKey={setRowKey}
          />
        )}
        {openModal.status && openModal.type === 'enrollmentAttend' && (
          <EnrollmentAttendModal getReportListAPI={onClickRefresh} setOpenModal={setOpenModal} auth={dashboardAuth} code={{ hCd: userInfo.hCd, sCd: userInfo.sCd }} />
        )}
      </Portal>
    </div>
  );
};

// 고령 근로자 출역현황
export const OldWorkerAttend = ({ name, userInfo, openModal, setOpenModal }: any) => {
  const { t } = useTranslation();
  const { selectedDay, setSelectedDay } = useTodayDate(null);
  const today = todayYYYYMMDD();
  const [selectedDayString, setSelectedDayString] = useState(today);

  const {
    data: oldworkerAttendDayData,
    refetch,
    isLoading,
    isFetching,
  } = useQuery(['oldworkerAttendGet', userInfo.sCd, selectedDayString], () => fetchOldWorkerAttendList(), {
    enabled: !!selectedDayString && !!userInfo.sCd && userInfo.sCd !== '00000',
  });
  const [dashboardAuth, setDashboardAuth] = useState<IAuth>({ createAuth: false, updateAuth: false, deleteAuth: false, excelAuth: false, printAuth: false });
  const toDay = todayYYYYMMDD();
  const twoWeekDays = getPreviousDays(toDay, 14);
  const [columns, setColumns] = useState<any[]>([]);
  const [tableState, setTableState] = useState<any[]>(oldworkerAttendDayData || []);
  // const [openModal, setOpenModal] = useState<any>({ status: false, type: '', code: '', index: 0 });
  const [filterTableState, setFilterTableState] = useState<any[]>([]);
  const [rowKey, setRowKey] = useState('');

  useEffect(() => {
    setColumns([
      {
        header: t('출역 사진'),
        name: 'aImg',
        align: 'center',
        renderer: {
          type: ImageRenderer,
        },
        width: 80,
      },
      {
        header: t('근로자명/직종'),
        name: 'wNamewJobtype',
        sortable: true,
        minWidth: 120,
        renderer: { classNames: ['line-2-title'] },
      },
      {
        header: t('협력업체 명/위치'),
        name: 'sjName',
        sortable: true,
        minWidth: 120,
        renderer: { classNames: ['line-2-title'] },
      },
      {
        header: t('생년월일/구분'),
        name: 'wBdatewAge',
        sortable: true,
        minWidth: 180,
        renderer: { classNames: ['line-2-birth'] },
      },
      {
        header: t('출역일자/시간'),
        name: 'aTimeMax',
        align: 'center',
        sortable: true,
        minWidth: 120,
        renderer: { classNames: ['line-2-title'] },
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    setRowKey('');
  }, [selectedDay]);

  useEffect(() => {
    if (userInfo.userMenuList) {
      if (userInfo.gCd === 'MM') setDashboardAuth({ createAuth: true, updateAuth: true, deleteAuth: true, printAuth: true, excelAuth: true });
      else {
        const menuAuth = userInfo.userMenuList.find((el: any) => el.mtCd === 'S003' && el.mdCd === '008' && el.mrCd === '001');
        if (menuAuth !== undefined) {
          setDashboardAuth({
            createAuth: menuAuth.cYn === 'Y',
            updateAuth: menuAuth.uYn === 'Y',
            deleteAuth: menuAuth.dYn === 'Y',
            excelAuth: menuAuth.eYn === 'Y',
            printAuth: menuAuth.pYn === 'Y',
          });
        }
      }
    }
  }, [userInfo.userMenuList]);

  const fetchOldWorkerAttendList = async () => {
    try {
      const path = '/attend/old';
      const req = {
        aDate1: selectedDayString,
        aDate2: selectedDayString,
        oldDate1: '',
        oldDate2: String(Number(selectedDayString) - Number(`${userInfo.elderlyAgeSet}0000`)),
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
      };
      const res = await apiGet({ path, req });
      const newArray = res.data.data.attendList?.map((el: any) => {
        const valueOfAge = getAgeFromCertainDate(el.wBdate, selectedDay);
        const wNameFormatted = `${el.wName?.substring(0, 1)}*${el.wName?.substring(2)}`;
        const wJobtypeDiv = el.wJobtypeName ? `<div>${el.wJobtypeName}</div>` : '';
        const fLocationNameDiv = el.fLocationName ? `<div>${el.fLocationName}</div> ` : '';

        const badgeOfAge = `<span class="badge">${valueOfAge}${t('세')}</span>`;
        const noBirthData = '<span class="disabled" >생년월일 미입력</span>';

        const wBdateSpan = el.wBdate ? badgeOfAge : noBirthData;

        const setBullet = (color: 'red' | 'green') => {
          return `<span class="bullet ${color}"></span>`;
        };

        const goToWork = `<div class="inAndOut green">${setBullet('green')} 출근</div>`;
        const getOffWork = `<div class="inAndOut red">${setBullet('red')} 퇴근</div>`;

        const fInoutDiv = el.fInout === 'O' ? getOffWork : el.fInout ? goToWork : '';

        const aTimeMaxDiv = `<div>${el.aTimeMax}</div>`;

        return {
          ...el,
          wNamewJobtype: `${wNameFormatted}${wJobtypeDiv}`,
          sjName: `${el.sjName} ${fLocationNameDiv}`,
          wBdatewAge: `${el.wBdate ? el.wBdate : ''}${wBdateSpan} ${fInoutDiv}`,
          aTimeMax: `${el.aDate}${aTimeMaxDiv}`,
        };
      });
      setTableState(newArray);
      return newArray;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  const onClickDate = (dateParam: string) => {
    setSelectedDay(dayjs(dateParam));
    setSelectedDayString(dateParam.replaceAll('-', ''));
  };

  // 신규 등록 버튼 클릭
  const onClickNewWorker = () => {
    setOpenModal({ status: true, type: 'enrollmentAttend' });
  };

  useEffect(() => {
    if (openModal.type === 'infoWorker' && openModal.status === false) {
      /**
       * 수정자 : 한영광
       * 수정일자 : 2023.11.06
       * 수정내용 : 모달창 닫을 때 리패치 하게되면 rowkey 에러 발생해서 주석처리함
       *            (리패치가 꼭 필요한 경우 tuigrid 파라미터에 rowkey 삭제 할 예정)
       */
      // 근로자 출역내역 창을 닫을 때 데이터 리패치
      // onClickRefresh();
    }
  }, [openModal]);

  // 근로자 Row 클릭
  const onClickRow = (rowData: any, columnName: any, filteredIndex: number) => {
    setRowKey(`${filteredIndex}`);
    setOpenModal({ status: true, type: 'infoWorker', code: rowData.wCd, aDate: rowData.aDate, index: filteredIndex });
  };

  const onClickRefresh = () => {
    refetch();
  };

  // if (isLoading) {
  //   return (
  //     <div className='centered-content'>
  //       <PulseLoader color='rgb(0, 122, 255)' size='10px' />
  //     </div>
  //   );
  // }

  return (
    <div className='newWorker'>
      <div className='widget-header margin-top'>
        <div className='widget-title flex-between innerBtn'>
          <span>
            {t(name)}
            <span className='badge'>{tableState?.length || 0}</span>
          </span>
          <div className='widget-btn-group'>
            {dashboardAuth.createAuth && (
              <BtnGhost onClick={onClickNewWorker}>
                <span className='material-symbols-rounded'>add</span>
                {t('등록')}
              </BtnGhost>
            )}
            <div className='refresh-btn' onClick={onClickRefresh} role='presentation'>
              <span className='material-symbols-rounded'>refresh</span>
            </div>
          </div>
        </div>
        <div className='oneWeekCalendar-wrapper'>
          <div className='oneWeekCalendar flex-between'>
            {twoWeekDays.map((el: any) => (
              <div
                key={el.date}
                className={`${el.fullDate === formatDateYMD(selectedDayString) ? 'todayHighlight' : ''} dayWrapper`}
                role='button'
                tabIndex={0}
                onClick={() => onClickDate(el.fullDate)}
              >
                <div className='day'>{t(el.dayName)}</div>
                <div className='date bold'>{el.date}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {isLoading || isFetching || !oldworkerAttendDayData ? (
        <div className='centered-content' style={{ width: '100%' }}>
          <PulseLoader color='rgb(0, 122, 255)' size='10px' />
        </div>
      ) : (
        <div className='tuiGridWrapper scroll'>
          <TuiGrid
            data={tableState}
            filterTableState={filterTableState}
            setFilterTableState={setFilterTableState}
            columns={columns}
            height={276}
            perPage={tableState.length}
            onClickRow={onClickRow}
            rowHeight={56}
            dashboard
            setRowKey={setRowKey}
          />
        </div>
      )}
      <Portal openModal={openModal?.status}>
        {openModal.status && openModal.type === 'infoWorker' && openModal.code !== undefined && (
          <InfoWorkerModal
            innerTabIndex={1}
            getReportListAPI={onClickRefresh}
            reportArray={filterTableState}
            setReportArray={setFilterTableState}
            setOpenModal={setOpenModal}
            auth={dashboardAuth}
            code={{ hCd: userInfo.hCd, sCd: userInfo.sCd, wCd: openModal.code, aDate: openModal.aDate }}
            index={openModal.index}
            setRowKey={setRowKey}
          />
        )}
        {openModal.status && openModal.type === 'enrollmentAttend' && (
          <EnrollmentAttendModal getReportListAPI={onClickRefresh} setOpenModal={setOpenModal} auth={dashboardAuth} code={{ hCd: userInfo.hCd, sCd: userInfo.sCd }} />
        )}
      </Portal>
    </div>
  );
};
