import { Dispatch, SetStateAction } from 'react';
import BackButton from './BackButton';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const InnerTabStyle = styled.div`
  border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  padding: 0.5rem;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  .table {
    height: fit-content;
  }

  &.smallTab {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0.5rem;
    font-weight: 500;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 0rem !important;
    }
    .tab {
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
      cursor: pointer;
      height: 3.5rem;
      border-bottom: 2px solid transparent;
      width: fit-content;
      user-select: none;
    }
    .activeTab {
      font-weight: 700;
      border-bottom: 2px solid ${({ theme }: { theme: any }) => theme.selected_primary};
      color: ${({ theme }: { theme: any }) => theme.selected_primary};
    }
  }
`;

interface IProps {
  visibleBackBtn: boolean;
  tabList: { key: number; name: string }[];
  activeTab: number;
  setActiveTab?: Dispatch<SetStateAction<number>>;
  backToMain: () => void;
  backBtnPlacement?: 'left' | 'right';
}

const InnerTabs = ({ visibleBackBtn, tabList, activeTab, setActiveTab, backToMain, backBtnPlacement = 'left' }: IProps) => {
  const { t } = useTranslation();
  const size = useOutletContext<any>();

  return (
    <InnerTabStyle className='smallTab'>
      {(size.innerSize.W < 1024 || visibleBackBtn) && <BackButton func={() => backToMain()} />}
      {/* {backToMain && <BackButton func={() => backToMain()} backBtnPlacement={backBtnPlacement} />} */}
      {tabList.map((el) => (
        <div
          className={`tab ${el.key === activeTab ? 'activeTab' : ''}`}
          key={el.key}
          role='presentation'
          onClick={() => {
            setActiveTab && setActiveTab(el.key);
          }}
        >
          {t(el.name)}
        </div>
      ))}
    </InnerTabStyle>
  );
};

export default InnerTabs;
