import { toast } from 'react-toastify';
import { ID, ERROR } from '../_constants';
/**
 * 작성자 : 홍선영
 * 날짜 : 2023.03.31
 * 기능 : 아이디 중복확인 함수.
 *        중복확인 API를 호출해서 중복여부, 알럿메시지값, API response의 status code값을 리턴
 */

import { registerId } from '../services/register';

export const checkIdDuplicate = (id: string) => {
  const trimmedId: string = id?.trim();
  let isIdDuplicate: boolean = true;
  let idDuplicateAlertMsg: string = '';
  let resStatusCode: number;

  // 아이디 중복체크 API 호출
  const checkDuplicateAPI = async (checkId: string) => {
    const res = await registerId(checkId);
    const { statusCode, message } = res.data;
    resStatusCode = statusCode;

    if (statusCode === 200) {
      // 사용가능한아이디(200) 인 경우
      isIdDuplicate = false;
      idDuplicateAlertMsg = message;
      return { isIdDuplicate, idDuplicateAlertMsg, resStatusCode };
    }
    // else {
    //   // toast.error(t(ERROR));
    // }
    idDuplicateAlertMsg = message;
    return { isIdDuplicate, idDuplicateAlertMsg, resStatusCode };
  };

  if (trimmedId === '') {
    idDuplicateAlertMsg = ID;
    resStatusCode = -1;
    return { isIdDuplicate, idDuplicateAlertMsg, resStatusCode };
  }
  return checkDuplicateAPI(id);
};
