import { MOBILE_PHONENUMBER, MOBILE_PHONENUMBER_WRONG } from '../_constants';
/**
 * 작성자 : 홍선영
 * 날짜 : 2023.05.10
 * 기능 : 객체 프로퍼티의 빈값여부 확인하여
 *        조건충족여부(boolean)와 알럿메시지(string) 리턴하는 함수
 *
 * 수정자 : 한영광
 * 날짜 : 2023.05.31
 * 수정내용 : checkPropertyEmpty함수는 전화번호 입력 여부만 확인하고 있어서 전화번호 입력 갯수도 체크하도록 수정하고 함수명도 변경함
 */

export const checkPhonNumEmpty = (object: any) => {
  let isEmpty = false;
  let emptyAlertMessage = '';
  const keys = Object?.keys(object);
  const newObj = { ...object };
  keys.map((el) => {
    const propertyValue = newObj[el];
    if (!propertyValue?.trim()) {
      emptyAlertMessage = MOBILE_PHONENUMBER;
      isEmpty = true;
    } else if (el === 'num1' && propertyValue.length < 2) {
      emptyAlertMessage = MOBILE_PHONENUMBER_WRONG;
      isEmpty = true;
    } else if (el === 'num2' && propertyValue.length < 3) {
      emptyAlertMessage = MOBILE_PHONENUMBER_WRONG;
      isEmpty = true;
    } else if (el === 'num3' && propertyValue.length < 4) {
      emptyAlertMessage = MOBILE_PHONENUMBER_WRONG;
      isEmpty = true;
    }
  });
  return { isEmpty, emptyAlertMessage };
};

/**
 * 수정 전
 */
// export const checkPropertyEmpty = (object: any, objectName: string) => {
//   let isEmpty = false;
//   let emptyAlertMessage = '';
//   const keys = Object?.keys(object);
//   // 동일한 속성으로 새 개체 만들기
//   const newObj = { ...object };
//   keys.map((el) => {
//     // 동적 키로 속성 값 가져오기
//     const propertyValue = newObj[el];
//     // 빈값이 있을 경우
//     if (propertyValue?.trim() === '') {
//       emptyAlertMessage = `${objectName}를(을) 입력하세요.`;
//       isEmpty = true;
//       return;
//     }
//     isEmpty = false;
//   });

//   return { isEmpty, emptyAlertMessage };
// };
