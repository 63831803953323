/**
 * 작성자 : 김광민
 * 날짜 : 2023.12.28
 * 경로 : 터널관리 - 구역 현황 관리 > 종합진행현황
 */

import { useRecoilValue } from 'recoil';
import { IUser, tInfoUpdateState, userState } from '../../../atoms';
import { useQuery } from '@tanstack/react-query';
import { PulseLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import { initialFilter } from '../../../services/Tunnel';
import { apiGet } from '../../../services/_common';
import TunnelTrackFilter from '../../../components/TunnelTrackFilter';
import styled from 'styled-components';
import MultiTunnel from '../../../components/tunnel/MultiTunnel';
import SingleTunnel from '../../../components/tunnel/SingleTunnel';
import { logPost } from '../../../services/log';

const Tunnels = styled.div<{ tunnelLength?: number }>`
  margin-bottom: 0.5rem;
  padding: 0 0.5rem;
  display: flex;
  gap: 0.5rem;
  overflow: auto;
  flex-grow: 1;
  &.noOverFlow {
    overflow: hidden;
    > div {
      overflow: hidden auto;
    }
  }
  &.grid {
    display: grid;
    grid-template-columns: repeat(${(props) => props.tunnelLength}, 1fr);
  }
`;
// 보기설정 옵션
const viewTunnelType = [
  { type: 'viewStatus', viewStatus: 'A', cdName: '전체' },
  { type: 'viewStatus', viewStatus: 'S', cdName: '구역별' },
];

const GeneralStatus = () => {
  const userInfo = useRecoilValue<IUser>(userState);
  const isTunnelInfoUpdated = useRecoilValue(tInfoUpdateState);

  // 필터 상태값 및 설정 함수
  const [filter, setFilter] = useState<TunnelFilterData[]>(initialFilter);

  // 종합/구역별 보기 상태값 및 설정 함수
  const [viewType, setViewType] = useState<TunnelViewType>(viewTunnelType[0]);

  // 필터 타입 코드 상태값 및 설정 함수
  const [selectedTatCd, setSelectedTatCd] = useState('');

  // 터널목록 데이터 상태값 및 설정 함수
  const [tunnelList, setTunnelList] = useState<TareaData[]>([]);

  // Tarea 데이터 요청 파라미터 함수
  const tareaParams = ({ hCd, sCd }: { hCd: string; sCd: string }) => {
    const req = viewType.viewStatus === 'A' ? { hCd, sCd } : { hCd, sCd, tatCd: selectedTatCd };
    const path = viewType.viewStatus === 'A' ? '/tarea/t/total' : '/tarea/d/total';
    return { req, path };
  };

  // Tarea 데이터 요청 함수
  const fetchTareaData = async ({ hCd, sCd }: { hCd: string; sCd: string }) => {
    try {
      const { path, req } = tareaParams({ hCd, sCd });
      const res = await apiGet({ path, req });
      const { data } = res.data || {};
      // 보기 설정이 전체일때 tarealist[0] tatCd를 구역별보기의 디폴트로 지정
      if (data?.tareaList.length !== 0 && viewType.viewStatus === 'A') {
        setSelectedTatCd(data.tareaList[0].tatCd);
        setTunnelList(data.tareaList);
      }
      return data?.tareaList || [];
    } catch (error) {
      console.error('API 호출 중 오류 발생:', error);
      throw new Error('API 호출 중 오류 발생');
    }
  };

  // Tarea 데이터 요청 상태값
  const { data, isError, isLoading, refetch } = useQuery(['tarea', userInfo.hCd, userInfo.sCd, viewType.viewStatus], () => fetchTareaData({ hCd: userInfo.hCd, sCd: userInfo.sCd }));

  useEffect(() => {
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: `터널 리스트 > 종합 진행 현황`,
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    refetch();
  }, [isTunnelInfoUpdated]);

  // 로딩 중일 때
  if (isLoading) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  if (isError) {
    console.error('API 호출 중 오류 발생:', data);
  }

  const renderContent = () => {
    // 종합 보기일 때
    if (viewType.viewStatus === 'A') {
      return (
        <Tunnels tunnelLength={getTunnelLengths(data).length || 0}>
          {data.map((tunnel: any, index: number) => (
            <MultiTunnel tunnelData={tunnel} key={index} filter={filter} tunnelRawData={tunnel} tunnelRawDataList={data} />
          ))}
        </Tunnels>
      );
    }
    // 구역별 보기일 때
    return (
      <Tunnels className='noOverFlow'>
        <SingleTunnel data={data} tunnelList={tunnelList} setTunnelList={setTunnelList} selectedTatCd={selectedTatCd} filter={filter} />
      </Tunnels>
    );
  };

  const getTunnelLengths = (calculateData: any[]) => {
    // 터널의 시작점과 종점을 추출하여 배열로 만듭니다.
    const lengths = calculateData.flatMap((tunnel: any) => {
      const start = tunnel.sUseYn === 'Y' ? { tunnelName: tunnel.tatName, useYn: tunnel.sUseYn } : null;
      const end = tunnel.eUseYn === 'Y' ? { tunnelName: tunnel.tatName, useYn: tunnel.eUseYn } : null;
      return start && end ? [start] : [start, start];
    });

    // null 값이 아닌 것들만 필터링하여 반환합니다.
    const filteredLengths = lengths.filter((item) => item !== null);

    return filteredLengths;
  };

  // 로딩이 끝났을 때
  return (
    <div>
      <TunnelTrackFilter
        filter={filter}
        setFilter={setFilter}
        total={data.length}
        viewTunnelType={viewTunnelType}
        viewType={viewType}
        setViewType={setViewType}
        tunnelList={tunnelList}
        selectedTatCd={selectedTatCd}
        setSelectedTatCd={setSelectedTatCd}
        initialChips={initialFilter}
      />
      {renderContent()}
    </div>
  );
};

export default GeneralStatus;
