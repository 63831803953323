/**
 * 작성자 : 한영광
 * 날짜 : 2023.06.21
 * 경로 : 근로자 관리 - 근로자 출역일자 퇴직처리 탭
 */
import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { IoChevronUpSharp } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { IComCdList } from 'customTypes';
import { IUser, userState } from '../../../atoms';
import { INIT_WORKER_STATUS, LIST_COUNT, WORK_STATUS_SEARCH } from '../../../_constants';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { FloatingButtonToTop } from '../../../assets/styles/FloatingButtonToTop';
import { ContentsContainerRoot } from '../../../assets/styles/ContentsContainerRoot';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import { BtnGhost } from '../../../components/Button';
import Input from '../../../components/Input';
import Portal from '../../../components/Portal';
import TuiGrid from '../../../components/Table/TuiGrid';
import SelectBox from '../../../components/SelectBox';
import TuiGridWrapper from '../../../components/Table/TuiGridWrapper';
import InfoWorkerModal from '../../../components/Modal/InfoWorkerModal';
import SearchSelectBoxSm from '../../../components/SearchSelectBoxSm';
import DatePickerComponent from '../../../components/DatePicker';
import RemoveButtonRenderer from '../../../components/RemoveBtnRenderer';
import { useSetSjcd } from '../../../utils/useSetSjcd';
import { useSetAuth } from '../../../utils/useSetAuth';
import { trimObject } from '../../../utils/trimObject';
import { scrollToNodeTop } from '../../../utils/scrollToNodeTop';
import { setComCdListState } from '../../../utils/setComCdListState';
import { useGetFrozenCount } from '../../../utils/getFrozenCount';
import { today, todayYYYYMMDD } from '../../../utils/formatDate';
import { useDetectScrolledToBottom } from '../../../utils/useDetectScrolledToBottom';
import useSetJobtype from '../../../utils/useSetJobtype';
import useOnKeydownF9 from '../../../utils/useOnKeydownF9';
import i18n from '../../../translation/i18n';
import { logPost } from '../../../services/log';
import useSetListCount from '../../../utils/useSetListCount';
import { apiGet, apiPost } from '../../../services/_common';
import ShortcutButton from '../../../components/button/ShortcutButton';

const Root = styled(ContentsContainerRoot)`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  gap: 0.5rem;

  .btn-in-cell {
    height: 2rem;
    font-size: 0.875rem;
    padding-left: 0.5rem;
    padding-right: 0.75rem;
    margin: 0 auto;
    font-weight: 600;
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.board};
    }
    .material-symbols-rounded {
      margin-right: 0.25rem;
    }
  }
  .btn-blue {
    background-color: ${({ theme }: { theme: any }) => theme.filled_blue} !important;
    border: none !important;
    /* color: ${({ theme }: { theme: any }) => theme.tonal_blue} !important; */
    color: ${({ theme }: { theme: any }) => theme.color.blue_100} !important;
  }
  .btn-red {
    background-color: ${({ theme }: { theme: any }) => theme.filled_red} !important;
    border: none !important;
    /* color: ${({ theme }: { theme: any }) => theme.tonal_red} !important; */
    color: ${({ theme }: { theme: any }) => theme.color.red_100} !important;
  }
  .btn-blue,
  .btn-red {
    .material-symbols-rounded {
      font-size: 1.375rem;
    }
  }
`;

const Release = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const tableRef = useRef<HTMLDivElement>(null);
  const wNameRef = useRef<HTMLInputElement>(null);
  const { siteJoinInfoList } = useSetSjcd();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const { listCount, setListCount, patchUserMenuAPI } = useSetListCount(); // 검색줄수설정 훅
  const toDay = todayYYYYMMDD();
  const [userInfo, setUserInfo] = useRecoilState<IUser>(userState);
  const { eExcelYn, eListYn } = userInfo;
  const [tableState, setTableState] = useState<any[]>([]);
  const [orgTableState, setOrgTableState] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [inoutDate, setInoutDate] = useState(String(Number(toDay) - 100));
  const [listCountComCdList, setListCountComCdList] = useState<IComCdList[]>([]); // 검색줄수 공통코드
  const [jikJongList, setJikJongList] = useState<any[]>([]);
  const [checkList, setCheckList] = useState<any[]>([]);
  const [searchOption, setSearchOption] = useState({ wName: '', inoutDate: String(Number(toDay) - 100) });
  const [wWorkstatus, setWWorkstatus] = useState(INIT_WORKER_STATUS);
  const [sjCd, setSjCd] = useState({ type: 'sjCd', sjCd: '', cdName: '' });
  const [wPrejobtype, setWPrejobtype] = useState({ type: 'wPrejobtype', wPrejobtype: '', cdName: '' });
  const [wJobtype, setWJobtype] = useState({ type: 'wJobtype', wJobtype: '', cdName: '' });
  const { prejobtypeList, jobtypeList } = useSetJobtype(wPrejobtype.wPrejobtype);
  const [excelBtn, setExcelBtn] = useState(false);
  const { isBottom } = useDetectScrolledToBottom(tableRef);
  const [initiate, setinitiate] = useState('');
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '', code: '', index: 0 });
  const [filterTableState, setFilterTableState] = useState<any[]>([]);
  const [rowKey, setRowKey] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setComCdListState(LIST_COUNT, setListCountComCdList, false);
    getWorkerAPI().then((res: any) => {
      setLoading(false);
      logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '근로자 출역일자 퇴직처리 ',
        action: '조회',
        etc: ``,
      });
    });
  }, []);

  useEffect(() => {
    setColumns(
      userInfo.prejobtypeYn === 'Y'
        ? [
            {
              header: t('근로자명'),
              name: 'wName',
              sortable: true,
              filter: 'select',
              width: 200,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('협력업체'),
              name: 'sjName',
              sortable: true,
              minWidth: 130,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('공종'),
              name: 'wPrejobtypeName',
              sortable: true,
              filter: 'select',
              minWidth: 120,
            },
            {
              header: t('직종'),
              name: 'wJobtypeName',
              sortable: true,
              filter: 'select',
              minWidth: 120,
            },
            {
              header: t('휴대폰번호'),
              name: 'wHnum',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('취업일자'),
              name: 'wJobdate',
              align: 'center',
              sortable: true,
              minWidth: 120,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('퇴직일자'),
              name: 'wRetiredate',
              align: 'center',
              sortable: true,
              minWidth: 120,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('최종 출역일자'),
              name: 'inoutDate',
              align: 'center',
              sortable: true,
              minWidth: 160,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('근로 상태'),
              name: 'wWorkstatusName',
              align: 'center',
              sortable: true,
              filter: 'select',
              minWidth: 120,
            },
            {
              header: t('상태변경'),
              name: 'wWorkstatus',
              align: 'center',
              renderer: {
                type: RemoveButtonRenderer,
                options: {
                  onRemoveButtonClicked,
                  auth,
                  t,
                },
              },
              filter: 'select',
              minWidth: 120,
            },
          ]
        : [
            {
              header: t('근로자명'),
              name: 'wName',
              align: 'center',
              sortable: true,
              filter: 'select',
              width: 200,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('협력업체'),
              name: 'sjName',
              align: 'center',
              sortable: true,
              minWidth: 150,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('직종'),
              name: 'wJobtypeName',
              align: 'center',
              sortable: true,
              filter: 'select',
              minWidth: 120,
            },
            {
              header: t('휴대폰번호'),
              name: 'wHnum',
              align: 'center',
              sortable: true,
              minWidth: 140,
            },
            {
              header: t('취업일자'),
              name: 'wJobdate',
              align: 'center',
              sortable: true,
              minWidth: 120,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('퇴직일자'),
              name: 'wRetiredate',
              align: 'center',
              sortable: true,
              minWidth: 120,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('최종 출역일자'),
              name: 'inoutDate',
              align: 'center',
              sortable: true,
              minWidth: 160,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('근로 상태'),
              name: 'wWorkstatusName',
              align: 'center',
              sortable: true,
              filter: 'select',
              minWidth: 120,
            },
            {
              header: t('상태변경'),
              name: 'wWorkstatus',
              align: 'center',
              renderer: {
                type: RemoveButtonRenderer,
                options: {
                  onRemoveButtonClicked,
                  auth,
                  t,
                },
              },
              filter: 'select',
              minWidth: 120,
            },
          ]
    );
  }, [userInfo.prejobtypeYn, tableState, i18n.language]);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    setWJobtype({ type: 'wJobtype', wJobtype: '', cdName: '' });
    if (wPrejobtype.wPrejobtype === '') setJikJongList([]);
  }, [wPrejobtype.wPrejobtype]);

  useEffect(() => {
    setJikJongList(jobtypeList);
  }, [jobtypeList]);

  useEffect(() => {
    setSearchOption((prev: any) => ({ ...prev, inoutDate }));
  }, [inoutDate]);

  useEffect(() => {
    setTableState(
      orgTableState.map((v: any) => {
        return { ...v, wWorkstatusName: t(v.wWorkstatusName) };
      })
    );
  }, [i18n.language]);

  // 근로자 정보 리스트 조회 API
  const getWorkerAPI = async () => {
    const newSearchOption = {
      ...searchOption,
      wWorkstatus: wWorkstatus.wWorkstatus === 'A' ? '' : wWorkstatus.wWorkstatus,
      sjCd: sjCd.sjCd,
      wPrejobtype: wPrejobtype.wPrejobtype,
      wJobtype: wJobtype.wJobtype,
    };
    const trimData = trimObject(newSearchOption);
    const req = { ...trimData, hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/worker', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      setLoading(false);
      setOrgTableState(data.workerList);
      setTableState(
        data.workerList
          .filter((v: any) => v.inoutDate)
          .map((v: any) => {
            delete v.wDate;
            return { ...v, wWorkstatusName: t(v.wWorkstatusName) };
          })
      );
      setCheckList([]);
    } else {
      setLoading(false);
      // toast.error(t(ERROR));
    }
    return res;
  };

  // 퇴직(재직) 처리 API
  const workerRetireAPI = async (wWorkstatusParam: string, workerList: any) => {
    const req = {
      wWorkstatus: wWorkstatusParam,
      workerRetireReqDto: workerList,
    };
    const res = await apiPost({ path: '/worker/retire', req });
    const { statusCode } = res.data;
    if (statusCode === 200) {
      if (wWorkstatusParam === 'R') {
        toast.success(t('근로자 퇴직처리 저장 성공'));
        await logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '근로자 출역일자 퇴직처리',
          action: '근로자 퇴직처리',
          etc: `퇴직처리 된 근로자 수 ${workerList.length}명`,
        });
      } else {
        toast.success(t('근로자 재직처리 저장 성공'));
        await logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '근로자 출역일자 퇴직처리',
          action: '근로자 재직처리',
          etc: `재직처리 된 근로자 수 ${workerList.length}명`,
        });
      }
      getWorkerAPI();
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 테이블 안에 퇴직(재직) 버튼 클릭
  const onRemoveButtonClicked = (rowIndex: any, option: string) => {
    const workerInfo = [
      {
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        wCd: tableState[rowIndex].wCd,
        editor: userInfo.userId,
      },
    ];
    workerRetireAPI(option, workerInfo);
  };

  // 검색 버튼 클릭
  const onClickSearch = () => {
    setRowKey('');
    if (!openModal.status) {
      setLoading(true);
      navigate({
        pathname: location.pathname,
        search: '',
      });
      getWorkerAPI();
    }
  };

  const initiateSearchOptions = () => {
    setWWorkstatus(INIT_WORKER_STATUS);
    setSjCd({ type: 'sjCd', sjCd: '', cdName: '' });
    setWPrejobtype({ type: 'wPrejobtype', wPrejobtype: '', cdName: '' });
    setWJobtype({ type: 'wJobtype', wJobtype: '', cdName: '' });
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    Object.keys(searchOption).map((el: any) => {
      return setSearchOption((prev) => ({ ...prev, [el]: '', inoutDate: String(Number(toDay) - 100) }));
    });
    setinitiate(`${Math.random()}`);
    initiateSearchOptions();
    setInoutDate(String(Number(toDay) - 100));
  };

  // 일괄 재직 버튼 클릭
  const onClickWorkerIn = () => {
    if (checkList.length !== 0) {
      const workerInfoList = checkList.map((v: any) => {
        return {
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          wCd: v.wCd,
          editor: userInfo.userId,
        };
      });
      workerRetireAPI('I', workerInfoList);
    } else {
      toast.warning(t('변경할 근로자를 선택하세요'));
    }
  };

  // 일괄 퇴직 버튼 클릭
  const onClickWorkerOut = () => {
    if (checkList.length !== 0) {
      const workerInfoList = checkList.map((v: any) => {
        return {
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          wCd: v.wCd,
          editor: userInfo.userId,
        };
      });
      workerRetireAPI('R', workerInfoList);
    } else {
      toast.warning(t('변경할 근로자를 선택하세요'));
    }
  };

  // 엑셀 저장 버튼 클릭
  const onClickExcelSave = () => {
    if (tableState.length <= 0) return;
    setExcelBtn(true);
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '근로자 출역일자 퇴직처리',
      action: '엑셀 저장',
      etc: ``,
    });
  };

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  // 인쇄 버튼 클릭
  const onClickPrint = () => {
    handlePrint();
  };

  // 근로자 Row 클릭
  const onClickRow = (rowData: any, columnName: any, filteredIndex: number) => {
    setRowKey(`${filteredIndex}`);
    setOpenModal({ status: true, type: 'infoWorker', code: rowData.wCd, index: filteredIndex });
  };

  // checkBox 클릭
  const onClickCheckBox = (rowKeyList: any) => {
    setCheckList(rowKeyList);
  };

  const renderIOBtn = () => {
    if (auth.createAuth || auth.updateAuth) {
      switch (wWorkstatus.wWorkstatus) {
        case 'A':
          return (
            <>
              <BtnGhost className='btn-red' onClick={onClickWorkerOut}>
                <span className='material-symbols-rounded'>close</span> {t('일괄 퇴직')}
              </BtnGhost>
              <BtnGhost className='btn-blue' onClick={onClickWorkerIn}>
                <span className='material-symbols-rounded'>done</span> {t('일괄 재직')}
              </BtnGhost>
            </>
          );
        case 'I':
          return (
            <BtnGhost className='btn-red' onClick={onClickWorkerOut}>
              <span className='material-symbols-rounded'>close</span> {t('일괄 퇴직')}
            </BtnGhost>
          );
        default:
          return (
            <BtnGhost className='btn-blue' onClick={onClickWorkerIn}>
              <span className='material-symbols-rounded'>done</span> {t('일괄 재직')}
            </BtnGhost>
          );
      }
    }
    return null;
  };

  const renderExcelBtn = () => {
    if (auth.excelAuth) {
      return (
        <BtnGhost onClick={onClickExcelSave}>
          <span className='icon-ms-xlsx'>X</span> {t('엑셀')}
        </BtnGhost>
      );
    }
    return null;
  };

  const renderPrintBtn = () => {
    if (auth.printAuth) {
      return (
        <BtnGhost onClick={onClickPrint}>
          <span className='material-symbols-rounded'>print</span>
          {t('인쇄')}
        </BtnGhost>
      );
    }
    return null;
  };

  // 근로자명 입력창에서 엔터입력시 검색
  const loginOnEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (wNameRef.current) onClickSearch();
    }
  };
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight, componentRef.current?.offsetWidth]);

  const frozenCount = useGetFrozenCount();

  return (
    <>
      <Root loading={loading}>
        <SearchOptions>
          {isBottom && (
            <FloatingButtonToTop>
              <button type='button' onClick={() => scrollToNodeTop(tableRef)}>
                <IoChevronUpSharp size={20} style={{ stroke: 'white' }} />
              </button>
            </FloatingButtonToTop>
          )}
          <div className='inputsWrapper'>
            <div className='inputForm-col withLabelComCf'>
              <label htmlFor='wWorkstatus'>{t('근로 상태')}</label>
              <SelectBox
                options={WORK_STATUS_SEARCH}
                defaultOption={t('재직')}
                state={wWorkstatus}
                setState={setWWorkstatus}
                stateKey='wWorkstatus'
                initiateKey={wWorkstatus.wWorkstatus}
                filterbar='filter-1-left'
              />
            </div>
            <div className='inputForm-row withLabel'>
              <label htmlFor='sjCd'>{t('협력업체')}</label>
              <div className='inputForm-col'>
                <SearchSelectBoxSm
                  options={siteJoinInfoList}
                  defaultOption={t('전체')}
                  state={sjCd}
                  setState={setSjCd}
                  stateKey='sjCd'
                  codeKey='cdName'
                  initiateKey={initiate}
                  filterbar='filter-1-left'
                  optionHeight='height-md'
                />
              </div>
            </div>
            <div className='inputForm-row-fit notOutline'>
              <span className='calendarLabel'>{t('최종 출역일자')}</span>
              <div className='inputForm-col'>
                <div className='datePickerWrapper filter-1-center'>
                  <DatePickerComponent startDate={inoutDate} setDate={setInoutDate} popperPlacement='bottom' />
                </div>
              </div>
              <span className='calendarFooter'>{t('이전')}</span>
            </div>
            {userInfo.prejobtypeYn === 'Y' && (
              <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <SearchSelectBoxSm
                    options={prejobtypeList}
                    defaultOption={t('공종 전체')}
                    state={wPrejobtype}
                    setState={setWPrejobtype}
                    stateKey='wPrejobtype'
                    codeKey='cdName'
                    initiateKey={initiate}
                    filterbar='filter-1-right'
                    comboWidth='expand-box-sm'
                    optionHeight='height-md'
                  />
                </div>
              </div>
            )}
            <div className='inputForm-row'>
              <div className='inputForm-col'>
                <SearchSelectBoxSm
                  options={jikJongList}
                  defaultOption={t('직종 전체')}
                  state={wJobtype}
                  setState={setWJobtype}
                  stateKey='wJobtype'
                  codeKey='cdName'
                  initiateKey={wJobtype.wJobtype}
                  filterbar='filter-1-right'
                  comboWidth='expand-box-sm'
                  optionHeight='height-md'
                />
              </div>
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col'>
                <Input placeholder={t('근로자명')} label='' type='text' id='wName' name='wName' state={searchOption} setState={setSearchOption} inputRef={wNameRef} onKeyDown={loginOnEnterKeyDown} />
              </div>
            </div>
          </div>
          <div className='inputsWrapper'>
            <div className='secondSearchOption'>
              <div className='flex-basic textBtnGroup'>
                <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
                {/* <BtnGhost onClick={onClickSearch} className='searchBtn'>
                  {t('검색')}
                  <span className='shortcut-f9'>F9</span>
                </BtnGhost> */}
                <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
                <div className='searchResult'>
                  {t('총')}
                  <span>{tableState.length}</span> {t('개')}
                </div>
                <div className='inputForm-row'>
                  <div className='inputForm-col withLabelComCf'>
                    <label htmlFor='useYn'>{t('보기 설정')}</label>
                    <SelectBox
                      options={listCountComCdList}
                      defaultOption={listCount[LIST_COUNT]}
                      state={listCount}
                      setState={setListCount}
                      stateKey={LIST_COUNT}
                      initiateKey={listCount[LIST_COUNT]}
                      setTableLinesAPI={patchUserMenuAPI}
                      optionHeight='height-sm'
                      rsearch
                    />
                  </div>
                </div>
              </div>
              <div className='flex-basic iconBtnGroup'>
                {renderIOBtn()}
                {renderExcelBtn()}
                {/* {renderPrintBtn()} */}
              </div>
            </div>
          </div>
        </SearchOptions>
        <TuiGridWrapper componentRef={componentRef}>
          <TuiGrid
            data={tableState}
            filterTableState={filterTableState}
            setFilterTableState={setFilterTableState}
            columns={columns}
            perPage={Number(listCount[LIST_COUNT])}
            excelBtn={excelBtn}
            setExcelBtn={setExcelBtn}
            usePagenation
            onClickRow={onClickRow}
            onClickCheckBox={onClickCheckBox}
            scrollX
            height={tuiHeight}
            rowKey={rowKey}
            eListYn={eListYn}
            eExcelYn={eExcelYn}
            frozenCount={frozenCount}
            setRowKey={setRowKey}
          />
        </TuiGridWrapper>
      </Root>
      {loading && (
        <LoadingModalBackground>
          <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
        </LoadingModalBackground>
      )}
      <Portal openModal={openModal?.status}>
        {openModal.status && openModal.type === 'infoWorker' && openModal.code && (
          <InfoWorkerModal
            innerTabIndex={0}
            getReportListAPI={getWorkerAPI}
            reportArray={filterTableState}
            setReportArray={setFilterTableState}
            setOpenModal={setOpenModal}
            auth={auth}
            code={{ hCd: userInfo.hCd, sCd: userInfo.sCd, wCd: openModal.code, aDate: today() }}
            index={openModal.index}
            setRowKey={setRowKey}
            listCount={Number(listCount[LIST_COUNT])}
          />
        )}
      </Portal>
    </>
  );
};

export default Release;
