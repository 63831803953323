import styled from 'styled-components';

const Root = styled.div`
  user-select: none;
  height: 100%;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
  color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
  border-radius: 0.25rem;
  padding: 0 1rem;
  font-size: 0.875rem;
  position: relative;
  .material-symbols-rounded {
    font-variation-settings: 'FILL' 1, 'wght' 600;
    font-size: 1rem;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(30%, -30%);
    color: ${({ theme }: { theme: any }) => theme.filled_red};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep_red};
    border-radius: 50%;
    opacity: 0;
    height: 1.25rem;
    width: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep_blue};
    .material-symbols-rounded {
      opacity: 1;
      &:hover {
        color: ${({ theme }: { theme: any }) => theme.bw_inverse};
        background-color: ${({ theme }: { theme: any }) => theme.filled_red};
      }
    }
  }
`;

interface Props {
  selectedItem: string;
  removeJobtype: (selectedItem: string) => void;
  name: string;
}

const JobtypeChip = ({ selectedItem, removeJobtype, name }: Props) => {
  return (
    <Root className='chip'>
      {name}
      <span
        className='material-symbols-rounded' //
        onClick={() => removeJobtype(selectedItem)}
        role='button'
        tabIndex={0}
      >
        close
      </span>
    </Root>
  );
};

export default JobtypeChip;
