import styled from 'styled-components';

const Root = styled.div`
  cursor: pointer;
  flex-shrink: 0;
  width: 2.75rem;
  height: 2.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  background-color: ${({ theme }: { theme: any }) => theme.board};
  .material-symbols-rounded {
    font-size: 1.75rem;
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
  }
  &:hover {
    outline: 0.25rem solid ${({ theme }: { theme: any }) => theme.tonal_deep};
    .material-symbols-rounded {
      color: ${({ theme }: { theme: any }) => theme.text_primary};
    }
    &:active {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
      outline: 0.25rem solid ${({ theme }: { theme: any }) => theme.filled_blue};
      .arrow {
        color: ${({ theme }: { theme: any }) => theme.filled_blue};
      }
    }
  }
`;

interface Props {
  fold: boolean;
}

const ArrowBoxInModal = ({ fold }: Props) => {
  const icon = fold ? 'keyboard_arrow_down' : 'keyboard_arrow_up';

  return (
    <Root>
      <span className='material-symbols-rounded'>{icon}</span>
    </Root>
  );
};

export default ArrowBoxInModal;
