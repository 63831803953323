import { useLocation } from 'react-router-dom';

const Video = () => {
  const location = useLocation();
  const awsPath = 'https://itlogs3server.s3.ap-northeast-2.amazonaws.com/SafeEdu';
  const filePath = `${awsPath}/${location.search}`?.split('=')[1];
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <video controls autoPlay style={{ width: 'inherit', height: 'inherit' }}>
        <source src={`${awsPath}/${filePath}`} type='video/mp4' />
        <track src='captions_en.vtt' kind='captions' label='English' default />
      </video>
    </div>
  );
};

export default Video;
