/**
 * 작성자 : 김광민
 * 날짜 : 2023.12.19
 * 경로 : 터널 관리 - 구역 현황 관리
 */

import { useEffect, useState } from 'react';
import { IUser, userState } from '../../../atoms';
import { useRecoilValue } from 'recoil';
import { apiGet } from '../../../services/_common';
import TunnelFilter from '../../../components/TunnelFilter';
import TunnelTable from '../../../components/TunnelTable';
import Portal from '../../../components/Portal';
import DivergentStackedBarsModal from '../../../components/Modal/DivergentStackedBarsModal';
import { logPost } from '../../../services/log';

const BaseInfo = () => {
  const userInfo = useRecoilValue<IUser>(userState);
  const [tareaGrid, setTareaGrid] = useState<TareaData[]>([]);
  const [tareaGridMaster, setTareaGridMaster] = useState<TareaData[]>([]);
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '' });

  // 터널 리스트 조회 API
  const getTareaAPI = async () => {
    try {
      const requestParams = { hCd: userInfo.hCd, sCd: userInfo.sCd };
      const response = await apiGet({ path: `/tarea`, req: requestParams });
      const {
        data: { tareaList },
        statusCode,
      } = response.data !== undefined && response.data;
      if (statusCode === 200) {
        return tareaList;
      }
      // 오류 상황에 대한 처리 추가
      console.error('Error during API call:', response);
      return [];
    } catch (error) {
      // 예외 발생 시에 대한 처리 추가
      console.error('Error during API call:', error);
      return [];
    }
  };

  useEffect(() => {
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: `터널 리스트 > 굴진 / 일반 정보`,
      action: '조회',
      etc: ``,
    });
  }, []);

  // 현재 페이지 이동시 api 호출
  useEffect(() => {
    if (tareaGridMaster.length === 0) {
      getTareaAPI()
        .then((res) => {
          const result = res.map((data: TareaData, idx: number) => {
            return { ...data, no: idx + 1 };
          });
          setTareaGridMaster([...result]);
          setTareaGrid([...result]);
        })
        .catch((error) => {
          console.error('Error during API call:', error);
        });
    }
  }, [tareaGridMaster]);

  return (
    <div>
      <TunnelFilter tareaGridMaster={tareaGridMaster} tareaGrid={tareaGrid} setTareaGrid={setTareaGrid} onClickChartBtn={() => setOpenModal({ status: !openModal.status, type: '' })} />
      <TunnelTable tareaGrid={tareaGrid} setTareaGrid={setTareaGrid} />
      <Portal openModal={openModal?.status}>{openModal.status && <DivergentStackedBarsModal data={tareaGrid} onClickClose={() => setOpenModal({ status: !openModal.status, type: '' })} />}</Portal>
    </div>
  );
};

export default BaseInfo;
