/**
 * 작성자 : 홍선영
 * 날짜 : 2023.09.04
 * 기능 : 현장설정관리-메인화면관리-상황판설정 페이지
 */

import { useEffect, useState } from 'react';
import { v1 } from 'uuid';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useRecoilValue } from 'recoil';
import { useOutletContext } from 'react-router-dom';
import { IoCloseCircleSharp } from 'react-icons/io5';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';

import { themeState, userState } from '../../../atoms';
import { DASHBOARD_GUBUN } from '../../../_constants';
import { IComCd, IComCdList, IDashboard, IDashboardM } from 'customTypes';
import { BtnBlue } from '../../../components/Button';
import { useSetAuth } from '../../../utils/useSetAuth';
import { arraySortByAscdOrder } from '../../../utils/arraySortByAscdOrder';
import { logPost } from '../../../services/log';
import { dark, light } from '../../../assets/styles/theme';
import { useTranslation } from 'react-i18next';
import { apiGet, apiPost } from '../../../services/_common';

interface RootStyleProps {
  maxRowCount: number;
}

interface DraggableCellStyleProps {
  isDragging: boolean;
}

interface DroppableCellStyleProps {
  isOver: boolean;
  canDrop: boolean;
}

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  justify-content: flex-end;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;
const Root = styled.div<RootStyleProps>`
  overflow-y: hidden !important;
  .main {
    flex-grow: 1;
    overflow: auto;
  }
  .dragNDropZone {
    display: flex;
    height: 100%;
    > div {
      padding: 1rem;
    }

    .dragZone {
      max-height: calc(100vh - 7.5rem);
      position: sticky;
      top: 0;
      height: fit-content;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      flex-shrink: 0;
      > div {
        border-radius: 0.25rem;
        -webkit-box-pack: start;
        justify-content: flex-start;
        line-height: 1.125rem;
        word-break: keep-all;
        padding: 0.5rem 0.75rem;
        font-weight: 400;
      }
    }

    .dropZone {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(8, 1fr);
      gap: 0.125rem;
      flex-grow: 1;
      > div {
        margin: 0;
        border-radius: 0.25rem;
        overflow: hidden;
        position: relative;
        > div:nth-child(1) {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2.5rem;
          height: 2.5rem;
          position: absolute;
        }
      }
    }
  }

  @media screen and (max-width: 1023px) {
    .dragNDropZone .dropZone {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const DraggableCellStyle = styled.div<DraggableCellStyleProps>`
  color: ${({ theme }: { theme: any }) => theme.text_primary};
  background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
  font-size: 0.875rem;
  width: 14rem;
  padding: 0.5rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: ${(props) => (props.isDragging ? '0.5' : '1')};
`;

const DroppableCellStyle = styled.div<DroppableCellStyleProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: ${(props) => (props.isOver && props.canDrop ? 'red' : '#5ac8fa')};
  height: 100%;
  border-radius: 0.25rem;
  .dropCell {
    width: 100%;
    flex-grow: 1;
    div {
      word-break: keep-all;
      text-align: center;
      line-height: 1.25;
      max-height: 6rem;
      min-height: 100%;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }
  .closeIcon {
    font-size: 24px;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    &:hover {
      color: ${({ theme }: { theme: any }) => theme.text_primary};
    }
  }
`;

interface DraggableCellProps {
  cell: any;
  dragPieces: any;
  setDragPieces: any;
}

interface Piece {
  dCd: string;
  name: string;
  width: number;
  height: number;
  isInDropZone: boolean;
  location: { column: number; row: number; mRow?: number };
  cdSort: number;
}

interface DropItem {
  type: string;
  item: Piece;
}

const DraggableCell = ({ cell, dragPieces, setDragPieces }: DraggableCellProps) => {
  const { t } = useTranslation();
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'cell',
    item: cell,
    end: (item: any, monitor: any) => {
      // if (monitor.didDrop() && item.isInGrid && onRemoveFromGrid) {
      //   onRemoveFromGrid(item); // Call the function to remove the piece from the grid
      // }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  useEffect(() => {
    if (isDragging) {
      const copyDragPieces = [...dragPieces];
      // cancelPiece의 id와 일치하는 항목을 드래그 피스 배열에서 찾기
      const index = copyDragPieces.findIndex((piece) => piece.dCd === cell.dCd);
      // 일치하는 아이템이 있으면 드래그피스 배열 업데이트
      if (index !== -1) {
        // cancelPiece의 속성을 사용하여 새 항목을 생성하고 InDropZone을 false 설정
        const updatedItem = {
          ...copyDragPieces[index],
          ...cell,
          isInDropZone: false,
        };

        // 업데이트된 아이템으로 드래그피스 배열의 아이템 대치
        copyDragPieces[index] = updatedItem;
        setDragPieces(copyDragPieces); // 드래그피스배열 setState
      }
    }
  }, [isDragging]);

  return (
    <DraggableCellStyle ref={drag} isDragging={isDragging}>
      {`${t(cell.name)} (${t('가로')}${cell.width} / ${t('세로')}${cell.height})`}
    </DraggableCellStyle>
  );
};

const DroppableZone = ({ children, onDrop, cell, dragPieces, setDragPieces, dropZoneGrid, setDropZoneGrid, auth }: any) => {
  // const { auth } = useSetAuth();
  const theme = useRecoilValue(themeState);
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'cell',
    drop: (item, monitor) => {
      onDrop(item);
      // console.log('drop cell ', cell.location);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const onClickRemove = (cancelPiece: any) => {
    const copyDragPieces = [...dragPieces];

    // cancelPiece의 id와 일치하는 항목을 드래그 피스 배열에서 찾기
    const index = copyDragPieces.findIndex((piece) => piece.dCd === cancelPiece.dCd);

    // 일치하는 아이템이 있으면 드래그피스 배열 업데이트
    if (index !== -1) {
      // cancelPiece의 속성을 사용하여 새 항목을 생성하고 InDropZone을 false 설정
      const updatedItem = {
        ...copyDragPieces[index],
        ...cancelPiece,
        isInDropZone: false,
      };

      // 업데이트된 아이템으로 드래그피스 배열의 아이템 대치
      copyDragPieces[index] = updatedItem;
      setDragPieces(copyDragPieces); // 드래그피스배열 setState
    }
  };

  return (
    <DroppableCellStyle
      ref={drop}
      canDrop={canDrop}
      isOver={isOver}
      style={{
        gridColumnStart: cell.location.column + 1,
        gridRowStart: cell.location.row + 1,
        gridColumnEnd: cell.location.column + cell.width + 1,
        gridRowEnd: cell.location.row + cell.height + 1,
        backgroundColor: isOver
          ? canDrop
            ? theme === 'light'
              ? light.selected_primary
              : dark.selected_primary
            : 'red'
          : cell.name === ''
          ? theme === 'light'
            ? light.board
            : dark.board
          : undefined,
      }}
    >
      <div style={cell.name === '' || (!auth.createAuth && !auth.updateAuth) ? { display: 'none' } : { display: 'flex' }}>
        <IoCloseCircleSharp className='closeIcon' onClick={() => onClickRemove(cell)} />
      </div>
      <div className='dropCell' style={cell.name === '' ? { display: 'none' } : { display: 'flex' }}>
        {children}
      </div>
    </DroppableCellStyle>
  );
};

const SiteDashboardSetting = ({ mqtt, dGubun }: any) => {
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const userInfo = useRecoilValue(userState);
  const size = useOutletContext<any>();
  const [reLocatedDragPieces, setReLocatedDragPieces] = useState<Piece[]>([]); // 위치 수정한 드래그피스 데이터(width 조절시 다시 되돌아가기 위한 state값)
  const [dropZoneGrid, setDropZoneGrid] = useState<any[]>([]);
  const [dropZoneMaxRowCount, setDropZoneMaxRowCount] = useState<number>(0);
  const [dashboardCdList, setDashboardCdList] = useState<any[]>([]);
  const [dashboardSetList, setDashboardSetList] = useState<IDashboard[]>([]);
  const [dashboardMList, setDashboardMList] = useState<Piece[]>([]);
  const [dragPieces, setDragPieces] = useState<Piece[]>([]);
  const D_GUBUN_H = '1'; // 본사용 대시보드 구분 공통코드 목록 (etc1값)
  const D_GUBUN_S = '2'; // 현장용 대시보드 구분 공통코드 목록(etc1값)
  const [dGubunCd, setDGubunCd] = useState({ subCd: dGubun });

  useEffect(() => {
    if (dGubun) {
      setDGubunCd({ subCd: dGubun });
      logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: dGubun === 'b' ? '메인화면 관리 > 상황판 설정' : '메인화면 관리 > 구역별 상황판 설정',
        action: '조회',
        etc: ``,
      });
    }
  }, [dGubun]);

  const dGubunComCdQuery = useQuery(['dashboardGubunComcdGet', userInfo.gCd], () => apiGet({ path: '/code/detail', req: { grCd: DASHBOARD_GUBUN } }), {
    enabled: !!userInfo.gCd && !!DASHBOARD_GUBUN,
  });

  const dashboardMListQuery = useQuery(
    ['dashboardMasterGet', userInfo.hCd, userInfo.sCd, dGubunCd.subCd],
    () => apiGet({ path: '/dashboard/master', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, dGubun: dGubunCd.subCd } }),
    {
      retry: 3,
      enabled: !!userInfo.hCd && !!userInfo.sCd && !!dGubunCd.subCd,
    }
  );

  const dashboardSetListQuery = useQuery(['dashboardListGet', userInfo.hCd, userInfo.sCd], () => apiGet({ path: '/dashboard' }), {
    retry: 3,
  });

  useEffect(() => {
    if (dGubunCd.subCd !== '' && dashboardSetListQuery.isSuccess && dashboardSetListQuery.data) {
      const { DashboardSetList } = dashboardSetListQuery.data.data.data;
      let newArray = [];
      if (dGubunCd.subCd === 'h') {
        // 본사용
        newArray = DashboardSetList.filter((el: any) => el.useYn === 'Y' && el.huseYn === 'Y');
      }
      if (dGubunCd.subCd === 's') {
        // 현장용
        newArray = DashboardSetList.filter((el: any) => el.useYn === 'Y' && el.suseYn === 'Y');
      }
      if (dGubunCd.subCd === 'b') {
        // 현장 상황판용
        newArray = DashboardSetList.filter((el: any) => el.useYn === 'Y' && el.buseYn === 'Y');
      }
      if (dGubunCd.subCd === 't') {
        // 구역별 상황판용
        newArray = DashboardSetList.filter((el: any) => el.useYn === 'Y' && el.tuseYn === 'Y');
      }
      setDashboardSetList(newArray);
    }
  }, [dashboardSetListQuery.isSuccess, dashboardSetListQuery.isRefetching, dGubunCd.subCd]);

  useEffect(() => {
    if (dashboardSetList && dashboardMListQuery.isSuccess && dashboardMListQuery.data) {
      const { DashboardMList } = dashboardMListQuery.data.data.data;

      const useYDashboard = dashboardSetList.filter((setListEl: IDashboard) => setListEl.useYn === 'Y');
      const result = useYDashboard.map((setListEl: IDashboard) => {
        const dataItem = DashboardMList.find((mListEl: IDashboardM) => mListEl.dCd === setListEl.dCd);
        return {
          dCd: setListEl.dCd,
          name: setListEl.dName,
          isInDropZone: !!dataItem,
          ...(dataItem ? { location: { column: dataItem.dCol, row: dataItem.dRow } } : { location: { column: -1, row: -1 } }),
          width: setListEl.dCol,
          height: setListEl.dRow,
          cdSort: setListEl.cdSort,
        };
      });
      setDashboardMList(result);
      // setDashboardMList(initialDragPiecies); // Dummy data
    }
  }, [dashboardMListQuery.isSuccess, dashboardMListQuery.isRefetching, dashboardSetList]);

  useEffect(() => {
    if (dashboardMList?.length > 0) {
      setDragPieces(dashboardMList);
    }
  }, [dashboardMList]);

  useEffect(() => {
    if (dGubunComCdQuery.isSuccess && dGubunComCdQuery.data) {
      const { comCdList } = dGubunComCdQuery.data.data.data;
      // 유저의 본사/현장 선택여부 따라 구분목록 세팅 (MM 유저의 경우에는 본사까지만 선택한 경우 대시보드구분 본사용목록세팅, 현장까지 선택한경우 대시보드구분 현장용목록 세팅)
      let newArray = [];

      if (userInfo.hCd && userInfo.sCd !== '00000') {
        newArray = comCdList.filter(({ useYn, etc1 }: IComCdList) => useYn === 'Y' && etc1 === D_GUBUN_S).map(({ subCd, cdName }: IComCd) => ({ type: 'subCd', subCd, name: cdName }));
      } else {
        newArray = comCdList.filter(({ useYn, etc1 }: IComCdList) => useYn === 'Y' && etc1 === D_GUBUN_H).map(({ subCd, cdName }: IComCd) => ({ type: 'subCd', subCd, name: cdName }));
      }
      const sorted = arraySortByAscdOrder(newArray, 'cdSort');
      setDashboardCdList(sorted);
    }
  }, [dGubunComCdQuery.isSuccess, dGubunComCdQuery.isRefetching]);

  const generateInitialGrid = (sizeParam: number) => {
    let maxRow = 0;
    const dropZonePieces = dragPieces.filter((el) => el.isInDropZone);
    const updatedDropZonePieces = addMissingPieces(dropZonePieces);

    dropZonePieces.forEach((piece) => {
      const endRow = piece.location.row + piece.height - 1;
      if (endRow > maxRow) maxRow = endRow;
    });
    setDropZoneMaxRowCount(maxRow + 1);
    return updatedDropZonePieces;
  };

  function addMissingPieces(dropZonePieces: any[]) {
    const dropPieces =
      dropZonePieces.length > 0
        ? dropZonePieces
        : [
            {
              dropZoneId: 0,
              name: '',
              isInDropZone: true,
              location: {
                row: 0,
                column: 0,
              },
              width: 1,
              height: 1,
            },
          ];

    let maxColumn = 0;
    let maxRow = 0;

    // 컬럼, 로우의 최대값 구하기
    dropPieces.forEach((piece: any) => {
      // maxColumn = Math.max(maxColumn, piece.location.column + piece.width);
      maxColumn = 5;
      maxRow = 8; // 상황판용 드랍존 세로는 최대 8줄까지로 제한
    });

    // 2차배열 생성하고 false로 채우기
    const grid = Array(maxRow)
      .fill(false)
      .map(() => Array(maxColumn).fill(false));

    // 그리드에 사용된 셀을 표시
    dropPieces.forEach((piece: any) => {
      for (let i = 0; i < piece.height; i += 1) {
        for (let j = 0; j < piece.width; j += 1) {
          grid[piece.location.row + i][piece.location.column + j] = true;
        }
      }
    });

    // 그리드 상에 빈곳 채워넣기
    for (let row = 0; row < maxRow; row += 1) {
      for (let column = 0; column < maxColumn; column += 1) {
        if (!grid[row][column]) {
          dropPieces.push({
            dropZoneId: dropPieces.length,
            // name: `New Piece ${dropPieces.length}`,
            name: '',
            isInDropZone: true,
            location: {
              row,
              column,
            },
            width: 1,
            height: 1,
          });
        }
      }
    }

    return dropPieces;
  }

  useEffect(() => {
    const initialGrid = generateInitialGrid(4);
    const mergedGrid = initialGrid.map((gridItem: any) => {
      const matchPiece = dragPieces.find((piece) => piece.dCd === gridItem.dCd);
      return matchPiece ? { ...gridItem, ...matchPiece } : gridItem;
    });

    setDropZoneGrid(mergedGrid);
  }, [dragPieces]);

  const onClickSave = () => {
    const savePieces = dragPieces.filter((el: Piece) => el.isInDropZone);
    if (savePieces.length > 0) {
      const newArray = savePieces.map((el: Piece) => ({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        dGubun: dGubunCd.subCd,
        dCd: el.dCd,
        dRow: el.location.row,
        dCol: el.location.column,
        editor: userInfo.userId,
      }));
      saveDashboardSettingAPI(newArray);
    } else {
      /**
       * 수정자 : 한영광
       * 수정일자 : 2023.10.16
       * 수정내용 : 데이터가 비어있는 경우 예외처리가 안돼있어서 추가함 (백엔드에서 데이터가 비어있는 경우를 구분할 수 있도록 dCd값을 0으로 보냄)
       */
      const newArray = [
        {
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          dGubun: dGubunCd.subCd,
          dCd: '0',
          editor: userInfo.userId,
        },
      ];
      saveDashboardSettingAPI(newArray);
    }
  };

  const saveDashboardSettingAPI = async (array: any[]) => {
    const req = { dashboardMReqDto: array };
    const res = await apiPost({ path: '/dashboard/master', req });
    const { status, data } = res;
    if (status === 200) {
      toast.success(t(data.message));

      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: 'DashBoard 설정',
        action: `현장 상황판 설정 저장`,
        etc: ``,
      });
      mqtt.mqtt.publish(`${userInfo.hCd}${userInfo.sCd}/board`, 'test');
    } else toast.warning(t(data.message));
  };

  const handleDrop = (dropItem: Piece, dropCell: any) => {
    const copyDragPieces = [...dragPieces];

    if (dropItem.dCd === '009') {
      const findObj = copyDragPieces.find((el) => el.dCd === '010' && el.isInDropZone);
      if (findObj !== undefined) {
        toast.info(t('소형/대형 중 하나의 유형만 배치 가능합니다.'));
        return;
      }
    }

    if (dropItem.dCd === '010') {
      const findObj = copyDragPieces.find((el) => el.dCd === '009' && el.isInDropZone);
      if (findObj !== undefined) {
        toast.info(t('소형/대형 중 하나의 유형만 배치 가능합니다.'));
        return;
      }
    }

    if (dropItem.dCd === '011') {
      const findObj = copyDragPieces.find((el) => el.dCd === '012' && el.isInDropZone);
      if (findObj !== undefined) {
        toast.info(t('소형/대형 중 하나의 유형만 배치 가능합니다.'));
        return;
      }
    }

    if (dropItem.dCd === '012') {
      const findObj = copyDragPieces.find((el) => el.dCd === '011' && el.isInDropZone);
      if (findObj !== undefined) {
        toast.info(t('소형/대형 중 하나의 유형만 배치 가능합니다.'));
        return;
      }
    }

    /**
     * 수정자 : 한영광
     * 수정일자 : 2023.10.16
     * 수정내용 : 아이템 이동 시 겹치는 부분이 생기는 문제가 있어 아이템 사이즈 만큼 점유된 셀 체크를 하도록 수정함
     */
    // 이미 점유된 셀에 드랍시도할 경우 드랍취소
    const dropItemBeforeLocation: any[] = []; // dropItemBeforeLocation는 drop한 item의 기존 location을 담을 변수
    for (let x = dropItem.location.column; x < dropItem.location.column + dropItem.width; x += 1) {
      for (let y = dropItem.location.row; y < dropItem.location.row + dropItem.height; y += 1) {
        dropItemBeforeLocation.push({ x, y });
      }
    }
    let dropCheck = false; // dropCheck가 true일때 드랍 취소
    const newDropZoneGrid = dropZoneGrid.filter((el) => !el.dCd);
    if (dropCell.dCd) {
      dropCheck = true;
    } else {
      for (let x = dropCell.location.column; x < dropCell.location.column + dropItem.width; x += 1) {
        for (let y = dropCell.location.row; y < dropCell.location.row + dropItem.height; y += 1) {
          let check = false;
          newDropZoneGrid.forEach((el: any) => {
            // newDropZoneGrid는 비어있는 셀 리스트
            if (el.location.column === x && el.location.row === y) {
              check = true; // check가 true일 때 해당 셀은 드랍 가능
            }
            dropItemBeforeLocation.map((data: any) => {
              if (data.x === x && data.y === y) {
                check = true; // check가 true일 때 해당 셀은 드랍 가능
              }
            });
          });
          if (!check) {
            dropCheck = true; // dropCheck가 true일때 드랍 취소
          }
        }
      }
    }

    if (dropCheck) {
      if (dropItem.isInDropZone) {
        // dropItem의 id와 일치하는 항목을 드래그 피스 배열에서 찾기
        const index = copyDragPieces.findIndex((piece) => piece.dCd === dropItem.dCd);

        // 일치하는 아이템이 있으면 드래그피스 배열 업데이트
        if (index !== -1) {
          // dropItem의 속성을 사용하여 새 항목을 생성하고 InDropZone을 true로 설정
          const updatedItem = {
            ...copyDragPieces[index],
            ...dropItem,
            isInDropZone: true,
            location: dropItem.location,
          };
          // console.log({ updatedItem });
          // 업데이트된 아이템으로 드래그피스 배열의 아이템 대치
          copyDragPieces[index] = updatedItem;
          setDragPieces(copyDragPieces); // 드래그피스배열 setState
          setReLocatedDragPieces(copyDragPieces);
        }
      }
      return;
    }
    // dropItem의 id와 일치하는 항목을 드래그 피스 배열에서 찾기
    const index = copyDragPieces.findIndex((piece) => piece.dCd === dropItem.dCd);

    // 일치하는 아이템이 있으면 드래그피스 배열 업데이트
    if (index !== -1) {
      // dropItem의 속성을 사용하여 새 항목을 생성하고 InDropZone을 true로 설정
      const updatedItem = {
        ...copyDragPieces[index],
        ...dropItem,
        isInDropZone: true,
        location: dropCell.location,
      };

      // 업데이트된 아이템으로 드래그피스 배열의 아이템 대치
      copyDragPieces[index] = updatedItem;
      setDragPieces(copyDragPieces); // 드래그피스배열 setState
      setReLocatedDragPieces(copyDragPieces);
    }
  };

  const sortedDragPiecies: Piece[] = dragPieces.sort((a, b) => {
    // 로우 오름차순으로 sorting
    if (a.location.row < b.location.row) return -1;
    if (a.location.row > b.location.row) return 1;

    // 로우값이 같을때, 컬럼 오름차순으로 sorting
    if (a.location.column < b.location.column) return -1;
    if (a.location.column > b.location.column) return 1;

    // 로우값과 컬럼값이 동일할때
    return 0;
  });

  useEffect(() => {
    if (reLocatedDragPieces.length === 0) {
      setReLocatedDragPieces(dragPieces);
    }
  }, [dragPieces]);

  // useEffect(() => {
  //   const updateDragPiecesForMobile = () => {
  //     // 가로 사이즈가 1024 미만으로 줄어들때, 컬럼을 통합하고 로우 오름차순, 컬럼 오름차순으로 정렬순서 객체에 넣기
  //     let mRowCounter = 0;
  //     return sortedDragPiecies.map((piece) => {
  //       const { height } = piece;
  //       const updatedPiece = {
  //         ...piece,
  //         location: { row: mRowCounter, column: 0 },
  //       };
  //       mRowCounter += height;
  //       return updatedPiece;
  //     });
  //   };

  //   if (size.innerSize.W <= 1024) {
  //     const updatedDragPiecies = updateDragPiecesForMobile();
  //     setDragPieces(updatedDragPiecies);
  //   } else if (reLocatedDragPieces.length > 0) {
  //     // 가로 사이즈 1024 이상일 때 기존 피스 location대로 다시 배치
  //     setDragPieces(reLocatedDragPieces);
  //   }
  // }, [size]);
  return (
    <Root maxRowCount={dropZoneMaxRowCount}>
      <div className='main'>
        <DndProvider backend={HTML5Backend}>
          <div className='dragNDropZone'>
            <div className='dragZone'>
              {dragPieces
                .sort((a, b) => {
                  if (a.cdSort > b.cdSort) return 1;
                  if (a.cdSort < b.cdSort) return -1;
                  return 0;
                })
                .map((el, i) => !el.isInDropZone && <DraggableCell key={v1()} cell={el} dragPieces={dragPieces} setDragPieces={setDragPieces} />)}
            </div>
            <div className='dropZone'>
              {dropZoneGrid?.map(
                (el, i) =>
                  el.isInDropZone && (
                    <DroppableZone
                      key={`${el.dCd}_${i}`}
                      onDrop={(item: Piece) => handleDrop(item, el)}
                      cell={el}
                      dragPieces={dragPieces}
                      setDragPieces={setDragPieces}
                      dropZoneGrid={dropZoneGrid}
                      setDropZoneGrid={setDropZoneGrid}
                      auth={auth}
                    >
                      <DraggableCell key={el.dCd} cell={el} dragPieces={dragPieces} setDragPieces={setDragPieces} />
                    </DroppableZone>
                  )
              )}
            </div>
          </div>
        </DndProvider>
      </div>
      {(auth.createAuth || auth.updateAuth) && (
        <ButtonsWrapper>
          <BtnBlue type='button' onClick={onClickSave}>
            {t('저장')}
          </BtnBlue>
        </ButtonsWrapper>
      )}
    </Root>
  );
};

export default SiteDashboardSetting;
