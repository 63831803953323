/**
 * 작성자 : 한영광
 * 날짜 : 2023.06.12
 * 경로 : 현장소개 -> 현장정보 (src/pages/s_info/S_info1)
 */

import { useState, useEffect } from 'react';
import { ITabObject } from 'customTypes';
import { useRecoilValue } from 'recoil';
import { pageInfoState, userState } from '../../atoms';
import SiteInfo from './info1/siteInfo';
import SiteView from './info1/siteView';
import Tab from '../../components/Tab';
import { useOutletContext } from 'react-router-dom';
import { logPost } from '../../services/log';

const Sinfo1 = () => {
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const userInfo = useRecoilValue(userState);
  const [newTabList, setNewTabList] = useState<ITabObject[]>();
  const { userMenuList } = userInfo;
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);

  useEffect(() => {
    const arr: any[] = [];
    userMenuList.map((v: any) => {
      const result = tabInfo?.tabList?.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  const onClickTab = (mrCd: string) => {
    setCurrentTabMrCd(mrCd);
  };

  const renderPage = () => {
    const activeTab = newTabList?.find((el: ITabObject) => el.mrCd === currentTabMrCd);
    if (activeTab !== undefined) {
      const { mrCd } = activeTab;
      switch (mrCd) {
        case '001':
          return <SiteInfo />;
        case '002':
          return <SiteView />;
      }
    }
    return undefined;
  };
  return (
    <div className='contents'>
      <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      <div className='content-container oneColumn'>{renderPage()}</div>
    </div>
  );
};

export default Sinfo1;
