/**
 * 작성자 : 홍선영
 * 날짜 : 2023.04.22
 * 경로 : CCTV 재생(녹화파일) 다운로드 윈도우
 */

import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { BtnBlue, BtnGhost, BtnRed } from '../../../../components/Button';
import { SearchOptions } from '../../../../assets/styles/SearchOptions';
import SelectBox from '../../../../components/SelectBox';
import { IModal } from 'customTypes';
import { streamSelectBoxOptions } from '../../../../_constants';
import DatePickerComponent from '../../../../components/DatePicker';
import { TareaModalStyle } from '../../../../assets/styles/TareaModal';
import { InputTable } from '../../../../assets/styles/InputTable';
import styled from 'styled-components';
import { getDayStartAndEndTimeStamps } from '../../../../utils/formatDate';
import { useFetchCameraCodeListWithoutNcd } from '../../../../services/useSetCodeListInSelectBoxForm';
import IssueGuide from '../../../../components/IssueGuide';
import { PulseLoader } from 'react-spinners';
import { LoadingModalBackground } from '../../../../assets/styles/Modal';
import { WebSDKcctv } from '../../../../utils/webSDKcctvClass';
import { mouseDownIndexState } from '../../../../atoms';
import { useRecoilState } from 'recoil';
import * as cctv from '../../../../utils/cctv';
import useOnKeydownF9 from '../../../../utils/useOnKeydownF9';
import TunnelTdProgressBar from '../../../../components/TunnelTdProgressbar';

const CctvDownloadModalStyle = styled(TareaModalStyle)`
  overflow: auto;
  .modal > .inputForm-body.attendList > div:first-child > .modalRibbon {
    gap: 0.5rem;
    > div:first-child {
      flex-grow: 0;
    }

    .inputCalendar {
      .react-datepicker {
        display: flex;
        .react-datepicker__time-container,
        .react-datepicker__time-box {
          width: 100%;
          border: none;

          > .react-datepicker__time-list {
            > .react-datepicker__time-list-item {
              justify-content: center;
              align-items: center;
              display: flex;
              &.react-datepicker__time-list-item--selected {
                background-color: ${({ theme }: { theme: any }) => theme.selected_primary};
              }
            }
          }
        }
      }
      .inputMask {
        width: 10.8rem;
      }
    }

    .btns {
      display: flex;
      gap: 0.5rem;
      .initBtn {
        padding: 0 0.75rem;
        width: 4.5rem;
        border: 1px solid #e4e4e7;
        background-color: #fafafa;
      }
      > button {
        height: 2.5rem;
        font-size: 0.875rem;
        width: fit-content;
      }
    }
  }

  .minWidth9 {
    width: 7.5rem;
    li {
      width: 100% !important;
    }
  }

  .end {
    display: none !important;
  }

  .modal-footer {
    justify-content: end;
  }
`;

interface PlaybackTable extends Playback {
  checked: boolean;
  progress: number;
  isDownloading: boolean;
}

const DownloadCctv = () => {
  const divPlugin = 'divPlugin';
  const { t } = useTranslation();
  const today = getDayStartAndEndTimeStamps(new Date());
  const [startDate, setStartDate] = useState(today.start);
  const [endDate, setEndDate] = useState(today.end);
  const { data: cameraData, isError, isLoading } = useFetchCameraCodeListWithoutNcd();
  const [camera, setCamera] = useState<any>({ type: 'cam', cam: '', cdName: '' });
  const [stream, setStream] = useState({ type: 'stream', stream: streamSelectBoxOptions[0]?.stream, cdName: streamSelectBoxOptions[0]?.cdName });
  const [cctvOBJ, setCctvOBJ] = useState<WebSDKcctv>();
  const [cctvFlow, setCctvFlow] = useState(0);
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: 'websdk', title: '' });
  const [mouseDownIndex, setMouseDownIndex] = useRecoilState(mouseDownIndexState);
  const [recordSearch, setRecordSearch] = useState<PlaybackTable[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [downloadCount, setDownloadCount] = useState<number>(0);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅

  useEffect(() => {
    if (cctv.getPluginOBJECT()?.oPlugin) {
      cctv.destroy();
    }
  }, []);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    if (isLoading === false) setCamera(cameraData[0]);
  }, [isLoading]);

  useEffect(() => {
    if (cctvFlow === 0) {
      const flowChangeListener = (flow: number) => setCctvFlow(flow);
      setTimeout(() => {
        setCctvOBJ(new WebSDKcctv({ setOpenModal, flowChangeListener, divPlugin, setSelectWndIndex: setMouseDownIndex }));
      }, 1000);
    }
    if (cctvFlow === 1) {
      const filterNvrList = cameraData.filter((v: any, i: number) => cameraData.findIndex((v2: any) => v.ip === v2.ip) === i);
      const data = filterNvrList.map((v: any) => ({ ip: v.ip, pPort: v.pPort, id: v.id, password: v.password }));
      cctvOBJ?.login(data);
    }
    if (cctvFlow === 2) cctvOBJ?.changeWndNum(4);
  }, [cctvFlow]);

  useEffect(() => {
    const checkAllResult = recordSearch.map((el: PlaybackTable) => ({ ...el, checked: selectAll }));
    setRecordSearch(checkAllResult);
  }, [selectAll]);

  const onChangeSelectAll = () => {
    setSelectAll((prev) => !prev);
  };

  const onChangeCheckboxValue = (index: number) => {
    setRecordSearch((prev) => prev.map((el, i) => (i === index ? { ...el, checked: !el.checked } : el)));
  };

  // 선택된 카메라/스트림/일자의 녹화 파일을 검색하는 비동기 함수
  const fetchRecords = async (camInfoParam: any, streamParam: number, sDate: string, eDate: string) => {
    if (cctvOBJ) {
      const recordSearchRes: any = await cctvOBJ?.recordSearch(camInfoParam, streamParam, sDate, eDate);
      return recordSearchRes;
    }
    return null;
  };

  const onClickSearch = async () => {
    const recordRes = await fetchRecords({ ip: camera.ip, channelNum: camera.channelNum, pPort: camera.pPort }, stream.stream, startDate, endDate);
    if (recordRes) {
      const newResult = recordRes.map((el: PlaybackTable) => ({ ...el, checked: false, ip: camera.ip, channelNum: camera.channelNum, pPort: camera.pPort, progress: 0, isDownloading: false }));
      setRecordSearch(newResult);
    }
  };

  const onClickInitiateSearchOption = () => {
    setCamera(cameraData[0]);
    setStream(streamSelectBoxOptions[0]);
    setStartDate(today.start);
    setEndDate(today.end);
  };

  const onClickStopDownload = async () => {
    if (isDownloading) {
      await cctvOBJ?.stopDownloadRecord();
      setIsDownloading(false);
    }
  };

  const onClickStartDownload = async () => {
    const downloads = recordSearch.filter((el: PlaybackTable) => el.checked);

    if (downloads.length > 0) {
      startDownload({ ip: camera.ip, channelNum: camera.channelNum, pPort: camera.pPort, stream: '', port: '', cName: '', nCd: '', cCd: '' }, downloads[0].szPlaybackURI, setProgress);
      setDownloadCount(1);
      setIsDownloading(true);

      const targetIndex = recordSearch.findIndex((el: PlaybackTable) => el.szPlaybackURI === downloads[0].szPlaybackURI);
      const newRecordSearch = [...recordSearch];
      if (targetIndex !== -1) newRecordSearch[targetIndex] = { ...newRecordSearch[targetIndex], isDownloading: true, progress };
      setRecordSearch(newRecordSearch);
    } else alert(t('녹화 파일을 선택하세요.'));
  };

  function startDownload(arg1: any, arg2: string, arg3: Dispatch<SetStateAction<number>>) {
    cctvOBJ?.startDownloadRecord(arg1, arg2, arg3);
  }

  const startNextDownload = (count: number) => {
    const downloads = recordSearch.filter((el: PlaybackTable) => el.checked);

    if (count <= downloads.length) {
      startDownload({ ip: camera.ip, channelNum: camera.channelNum, pPort: camera.pPort, stream: '', port: '', cName: '', nCd: '', cCd: '' }, downloads[count - 1].szPlaybackURI, setProgress);

      const newRecordSearch = [...recordSearch];
      const prevTargetIndex = recordSearch.findIndex((el: PlaybackTable) => el.szPlaybackURI === downloads[count - 2].szPlaybackURI);
      if (prevTargetIndex !== -1) newRecordSearch[prevTargetIndex] = { ...newRecordSearch[prevTargetIndex], isDownloading: false, progress: 100 };

      const currenttargetIndex = recordSearch.findIndex((el: PlaybackTable) => el.szPlaybackURI === downloads[count - 1].szPlaybackURI);
      if (currenttargetIndex !== -1) newRecordSearch[currenttargetIndex] = { ...newRecordSearch[currenttargetIndex], isDownloading: true, progress };
      setRecordSearch(newRecordSearch);
    } else {
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    const targetIndex = recordSearch.findIndex((el: PlaybackTable) => el.isDownloading);
    const newRecordSearch = [...recordSearch];
    if (progress >= 100) {
      if (targetIndex !== -1) newRecordSearch[targetIndex] = { ...newRecordSearch[targetIndex], isDownloading: false, progress };
      setRecordSearch(newRecordSearch);
      setProgress(0);
      startNextDownload(downloadCount + 1);
      setDownloadCount((prev) => prev + 1);
    } else {
      if (targetIndex !== -1) newRecordSearch[targetIndex] = { ...newRecordSearch[targetIndex], progress };
      setRecordSearch(newRecordSearch);
    }
  }, [progress]);

  useEffect(() => {
    console.log(startDate);
  }, [startDate]);

  useEffect(() => {
    console.log(endDate);
  }, [endDate]);

  if (isError) return <IssueGuide />;
  if (isLoading) {
    return (
      <LoadingModalBackground>
        <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
      </LoadingModalBackground>
    );
  }

  return (
    <CctvDownloadModalStyle>
      <div
        className='modal'
        role='presentation'
        onClick={(event) => {
          event.stopPropagation();
        }}
        style={{ width: '100vw', height: '100vh' }}
      >
        <div className='inputForm-head flex-between'>
          <div className='flex-start'>{t('CCTV 녹화 파일 다운로드')}</div>
        </div>
        <div className='inputForm-body attendList'>
          <InputTable>
            <div className='modalRibbon' style={{ zIndex: '1' }}>
              <SearchOptions style={{ width: 'fit-content', padding: '0' }}>
                <div className='inputsWrapper'>
                  <div className='secondSearchOption'>
                    <div className='flex-basic textBtnGroup'>
                      <div className='inputForm-row withLabel'>
                        <label htmlFor='wWorkstatus'>{t('카메라 명')}</label>
                        <div className='inputForm-col minWidth9'>
                          <SelectBox
                            options={cameraData || []}
                            defaultOption={cameraData ? cameraData[0].cdName : ''}
                            state={camera}
                            setState={setCamera}
                            stateKey='cam'
                            filterbar='filter-1-left'
                            dropDownWidth='fit-content'
                            optionHeight='height-md'
                            isWebSdk
                          />
                        </div>
                      </div>
                      <div className='inputForm-row withLabel'>
                        <label htmlFor='wWorkstatus'>{t('스트림 유형')}</label>
                        <div className='inputForm-col'>
                          <SelectBox
                            options={streamSelectBoxOptions}
                            defaultOption={streamSelectBoxOptions[0].cdName}
                            state={stream}
                            setState={setStream}
                            stateKey='stream'
                            filterbar='filter-1-left'
                            dropDownWidth='fit-content'
                            optionHeight='height-md'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SearchOptions>
              <div className='inputForm-row labelOutInput inputCalendar'>
                <span>{t('시작 시간')}</span>
                <div className='datePickerWrapper'>
                  <DatePickerComponent startDate={startDate} setDate={setStartDate} popperPlacement='bottom' showTimeSelect timeIntervals={30} />
                </div>
              </div>
              <div className='inputForm-row labelOutInput inputCalendar'>
                <span>{t('종료 시간')}</span>
                <div className='datePickerWrapper'>
                  <DatePickerComponent startDate={endDate} setDate={setEndDate} popperPlacement='bottom' showTimeSelect timeIntervals={30} />
                </div>
              </div>
              <div className='btns'>
                <BtnGhost onClick={onClickSearch} className='searchBtn'>
                  {t('검색')}
                  <span className='shortcut-f9'>F9</span>
                </BtnGhost>
                <BtnGhost onClick={onClickInitiateSearchOption} className='initBtn'>
                  {t('초기화')}
                </BtnGhost>
              </div>
            </div>
            <div className='thead' style={{ padding: '0 0.5rem', zIndex: '0' }}>
              <div className='tr'>
                <div className='trCol2p5 flex-center tableStickyNo'>
                  <input type='checkbox' checked={selectAll} onChange={onChangeSelectAll} disabled={isDownloading} />
                </div>
                <div className='trCol2p5 flex-center'>{t('순번')}</div>
                <div className='trCol10 flex-center'>{t('시작 시간')}</div>
                <div className='trCol10 flex-center'>{t('종료 시간')}</div>
                <div className='trCol12 flex-center content-overflow'>{t('파일명')}</div>
                <div className='trCol6 flex-center'>{t('파일 용량')}</div>
                <div className='trCol8 flex-center content-overflow'>{t('진행률')}</div>
              </div>
            </div>
            <div className='table' style={{ padding: '0 0.5rem' }}>
              <div className='tbody'>
                {recordSearch.map((el: PlaybackTable, i: number) => (
                  <div className='tr' key={el.szFileName}>
                    <div className='trCol2p5 flex-center'>
                      <input type='checkbox' checked={el.checked} onChange={() => onChangeCheckboxValue(i)} disabled={isDownloading} />
                    </div>
                    <div className='trCol2p5 flex-center'>{i + 1}</div>
                    <div className='trCol10 flex-center'>{el.szStartTime}</div>
                    <div className='trCol10 flex-center'>{el.szEndTime}</div>
                    <div className='trCol12 flex-center content-overflow'>{el.szFileName}</div>
                    <div className='trCol6 flex-center'>{el.size} MB</div>
                    <div className='trCol8 flex-center content-overflow'>
                      {el.isDownloading ? <TunnelTdProgressBar rlength={el.progress} length={100} meter={false} /> : !el.isDownloading && el.progress === 100 ? t('다운로드 완료') : null}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </InputTable>
        </div>
        <div className='modal-footer flex-between'>
          <div className='flex-basic'>
            <BtnRed onClick={onClickStopDownload} disabled={!isDownloading}>
              <p>{t('다운로드 정지')}</p>
            </BtnRed>
            <BtnBlue onClick={onClickStartDownload} disabled={isDownloading}>
              <p>{t('다운로드')}</p>
            </BtnBlue>
          </div>
        </div>
      </div>
      <div id={divPlugin} className='cctvWrapper' />
    </CctvDownloadModalStyle>
  );
};

export default DownloadCctv;
