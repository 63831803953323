/**
 * 작성자 : 한영광
 * 날짜 : 2023.07.28
 * 경로 : 설정관리-장비관리-출입장비설정탭 (현장관리자)
 */
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { IUser, userState } from '../../../atoms';
import { InputTable } from '../../../assets/styles/InputTable';
import { useTranslation } from 'react-i18next';
import { apiDelete, apiGet } from '../../../services/_common';
import { logPost } from '../../../services/log';
import { useQuery } from '@tanstack/react-query';
import DeleteOnTable from './DeleteOnTable';
import { toast } from 'react-toastify';
import Portal from '../../../components/Portal';
import DeleteModal from './DeleteModal';

const Root = styled.div`
  > div {
    width: 100%;
    .tr {
      display: flex;
      width: 100%;
      min-width: fit-content;
      > div {
        flex-shrink: 0;
      }
    }
  }
`;

type details = {
  main: string;
  sub: string;
  title: string;
};

type TableObject = {
  fInout: details;
  fIp: number; // 장비 IP
  fKind: string;
  fKindName: details;
  fLastdate: null | string;
  fLocation: string;
  fLocationName: details;
  fMac: string; // 장비 MAC
  fName: details;
  fNum: number; // 장비번호
  fPublicIp: string;
  fVersion: number; // 장비 버전
};

const EquipSetting = () => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue<IUser>(userState);
  const path = '/setting/faceSet';
  const [openModal, setOpenModal] = useState<{ status: boolean; value: string }>({ status: false, value: '' });

  useEffect(() => {
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '장비 관리 > 출입 장비 정보',
      action: '조회',
      etc: ``,
    });
  }, []);

  const getFaceSetAPI = async () => {
    const { hCd, sCd } = userInfo;
    const req = { hCd, sCd };
    const res = await apiGet({ path, req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const result = data.faceSet.map((v: any) => {
        return {
          ...v,
          fName: { main: v.fName, sub: v.fIp, title: 'IP' },
          fKindName: { main: v.fKindName, sub: v.fMac, title: 'MAC' },
          fInout: {
            main: v.fInout === 'I' ? t('출근') : t('퇴근'),
            sub: v.fLastdate ? `${v.fLastdate.substring(0, 4)}-${v.fLastdate.substring(4, 6)}-${v.fLastdate.substring(6, 8)} ${v.fLastdate.substring(8, 10)}:${v.fLastdate.substring(10, 12)}` : null,
            title: t('마지막 접속시간'),
          },
          fLocationName: { main: v.fLocationName, sub: v.fVersion, title: 'Version' },
        };
      });
      return result;
    }
    return [];
  };

  const reactQuery = useQuery([path, userInfo.hCd, userInfo.sCd], getFaceSetAPI);
  const { data, isLoading, isError, refetch } = reactQuery;

  const handleDelete = async (fMac: string) => {
    const { hCd, sCd } = userInfo;
    const req = { hCd, sCd, fMac };
    const res = await apiDelete({ path, req });
    const { statusCode, message } = res.data;
    if (statusCode === 200) {
      toast.success(message);
      refetch();
      setOpenModal({ status: false, value: '' });
    }
  };

  if (isLoading) return null;
  if (isError) return null;

  return (
    <Root>
      <InputTable className='margin-left-05'>
        <div className='thead'>
          <div className='tr'>
            <div className='trCol2p5 flex-center tableStickyNo'>No.</div>
            <div className='trCol5 flex-center content-overflow tableStickyTitle'>{t('장비명')}</div>
            <div className='trCol6 flex-center content-overflow'>{t('장비종류')}</div>
            <div className='trCol8 flex-center content-overflow'>{t('장비위치')}</div>
            <div className='trCol6 flex-center'>{t('장비타입')}</div>
            <div className='trCol6 flex-center'>{t('장비번호')}</div>
            <div className='trCol8 flex-center'>IP</div>
            <div className='trCol16 flex-center'>MAC</div>
            <div className='trCol12 flex-center'>{t('마지막 접속시간')}</div>
            <div className='trCol6 flex-center'>Version</div>
            <div className='trCol6 flex-center'>-</div>
          </div>
        </div>
        <div className='table'>
          <div className='tbody'>
            {data.map((el: TableObject, i: number) => (
              <div className='tr' role='button' tabIndex={0} key={i}>
                <div className='trCol2p5 flex-center tableStickyNo'>{i + 1}</div>
                <div className='trCol5 flex-basic content-overflow tableStickyTitle font_semibold'>{el.fName.main}</div>
                <div className='trCol6 flex-basic content-overflow'>{el.fKindName.main}</div>
                <div className='trCol8 flex-basic content-overflow'>{el.fLocationName.main}</div>
                <div className='trCol6 flex-center'>{el.fInout.main}</div>
                <div className='trCol6 flex-center'>{el.fNum}</div>
                <div className='trCol8 flex-center'>{el.fIp}</div>
                <div className='trCol16 flex-center'>{el.fMac}</div>
                <div className='trCol12 flex-center'>{el.fInout.sub}</div>
                <div className='trCol6 flex-center'>{el.fVersion}</div>
                <div className='trCol6 flex-center'>
                  <DeleteOnTable
                    onClick={() => {
                      setOpenModal({ status: true, value: el.fMac });
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </InputTable>
      <Portal //
        openModal={openModal?.status}
      >
        {openModal && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} onClick={handleDelete} />}
      </Portal>
    </Root>
  );
};

export default EquipSetting;
