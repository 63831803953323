/**
 * 작성자 : 김광민
 * 날짜 : 2024.03.14
 * 경로 : 대시보드 & 상황판 > 터널 종합 진행 현황 (일반)
 */

import styled from 'styled-components';
import { EndPoint, StartPoint } from './TunnelPoints';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import Portal from '../Portal';
import TareaInfoModal from '../Modal/tAreaInfoModal';
import { useSetAuth } from '../../utils/useSetAuth';

const Root = styled.div`
  flex-grow: 1;
  display: flex;
  width: 100%;
  overflow: hidden;
  .tableHeader,
  .tableBody {
    overflow-x: scroll;
  }
  .tableHeader {
    padding-left: 0.5rem;
    display: flex;
    flex-direction: column;
    flex: 0 0 9rem;
    > .tareaName,
    > .rowHeader {
      display: flex;
      padding: 0 1rem;
      gap: 0.25rem;
    }
    .tareaName {
      display: flex;
      align-items: center;
      flex: 0 0 4rem;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      font-size: 0.875rem;
      .material-symbols-rounded {
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      }
    }
  }
  .tableBody {
    margin-right: 0.5rem;
    display: flex;
    flex-grow: 1;
    overflow-y: hidden;
    position: relative;
    &.playNow {
      pointer-events: none;
    }
    .background-rows {
      position: absolute;
      width: 100%;
      height: 100%;
      padding-right: 0.5rem;
      pointer-events: none;
      &.area {
        flex-grow: 1;
        .pointSide {
          max-width: none !important;
        }
      }
    }
    .area {
      flex: 0 0;
      display: flex;
      flex-direction: column;
      border-radius: 0.5rem;
      min-width: 8rem;
      &.expand {
        min-width: 16rem;
        .areaBody {
          min-width: 16rem;
          .pointSide .rowData:last-child span {
            visibility: hidden;
          }
          > .rowData:last-child {
            position: absolute;
            width: 100%;
            bottom: 0;
            border: none;
            justify-content: center;
            height: 3.5rem;
          }
        }
      }
      .areaHeader {
        text-align: center;
        font-size: 0.875rem;
        flex: 0 0 4rem;
        padding-top: 0.5rem;
        .pointLabel {
          display: flex;
          min-width: 8rem;
          width: 100%;
        }
      }
      .areaBody {
        display: flex;
        flex: 1 0;
        position: relative;
        .pointSide {
          flex: 1 0 8rem;
          display: flex;
          max-width: 8rem;
          flex-direction: column;
          .rowData {
            justify-content: center;
          }
        }
      }
      &:hover {
        cursor: pointer;
      }
      &:hover,
      &.focused {
        box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.15);
        background-color: ${({ theme }: { theme: any }) => theme.board};
        .areaHeader {
          color: ${({ theme }: { theme: any }) => theme.filled_blue};
          border-radius: 0.5rem 0.5rem 0 0;
          box-shadow: inset 0px 2px 1px 0 ${({ theme }: { theme: any }) => theme.filled_blue}, inset -2px 0px 1px 0 ${({ theme }: { theme: any }) => theme.filled_blue},
            inset 2px 0px 1px 0 ${({ theme }: { theme: any }) => theme.filled_blue};
        }
        .areaBody {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
          border-radius: 0 0 0.5rem 0.5rem;
          box-shadow: inset 0px -2px 1px 0 ${({ theme }: { theme: any }) => theme.filled_blue}, inset -2px 0px 1px 0 ${({ theme }: { theme: any }) => theme.filled_blue},
            inset 2px 0px 1px 0 ${({ theme }: { theme: any }) => theme.filled_blue};
        }
      }
    }
  }
  .rowData {
    flex: 1 0 2.125rem;
    display: flex;
    gap: 0.25rem;
    align-items: center;
    border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    padding: 0 0.75rem;
    .textData {
      word-break: keep-all;
      line-height: 1.25;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .material-symbols-rounded {
      font-variation-settings: 'FILL' 1;
      font-size: 1.25rem;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      padding: 0 0.125rem;
      border-radius: 0.5rem;
      margin-right: 0.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 2rem;
      height: 1.75rem;
    }
  }
`;

type Props = { data: any; autoPlay: boolean };

const TunnelTable = ({ data, autoPlay }: Props) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const focusedRef = useRef<HTMLDivElement | null>(null);
  const [focusedIndex, setFocusedIndex] = useState<number>(0);

  const initOpenModal = { status: false, type: '' };
  const [openModal, setOpenModal] = useState<any>(initOpenModal);

  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅

  // 터널 데이터를 시점과 종점으로 분리하고 가공하는 함수
  const mutateData = (listData: any[]) => {
    // 터널의 시점과 종점을 추출합니다.
    const result = listData.map((tunnel: any) => {
      const { eTesName, eTjcName, sTesName, sTjcName, eWorkerCount, sWorkerCount, sUseYn, eUseYn, tatName, eDeviceCount, sDeviceCount, attendCount } = tunnel;
      const isStart = sUseYn === 'Y';
      const isEnd = eUseYn === 'Y';
      const start = isStart
        ? {
            position: 'start',
            tesName: sTesName,
            tjcName: sTjcName,
            workerCount: sWorkerCount,
            deviceCount: sDeviceCount,
            attendCount,
          }
        : null;
      const end = isEnd
        ? {
            position: 'end',
            tesName: eTesName,
            tjcName: eTjcName,
            workerCount: eWorkerCount,
            deviceCount: eDeviceCount,
            attendCount,
          }
        : null;

      return { tunnelName: tatName, isExpand: isStart && isEnd, attendCount, start, end };
    });

    return result;
  };

  const tableData = mutateData(data);
  const tableHeaderList = [
    { name: '근로자', icon: 'group' },
    { name: '장비', icon: 'front_loader' },
    { name: '작업 내용', icon: 'construction' },
    { name: '장비 상태', icon: 'manufacturing' },
    { name: '출역 인원', icon: 'familiar_face_and_zone' },
  ];

  // autoPlay, focusedIndex 상태 변경시 구동되는 자동재생 Hook
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    // autoPlay가 true면 3.5초 후에 updateIndex 실행
    if (autoPlay) {
      timeoutId = setTimeout(updateIndex, 3500);
    }
    return () => clearTimeout(timeoutId);
  }, [autoPlay, focusedIndex]);

  // autoPlay 상태 변경시 구동되는 자동재생 Hook
  useEffect(() => {
    // autoPlay가 false면 focuseIndex 초기화
    if (!autoPlay) {
      setFocusedIndex(0);
    }
  }, [autoPlay]);

  // scrollIntoView 실행
  useEffect(() => {
    const element = focusedRef.current;
    if (focusedIndex !== -1 && element) {
      const elementRect = element.getBoundingClientRect();
      const parentRect = element.parentElement?.getBoundingClientRect();
      // 요소가 가로 스크롤 영역 밖에 있으면 수동으로 스크롤 조작
      if (elementRect.left < parentRect!.left || elementRect.right > parentRect!.right) {
        element.parentElement?.scrollTo({
          left: elementRect.right - parentRect!.right + element.parentElement!.scrollLeft,
          behavior: 'smooth',
        });
      }
    }
  }, [focusedIndex, autoPlay]);

  useEffect(() => {
    if (!openModal.status) {
      setOpenModal(initOpenModal);
    }
  }, [openModal.status]);

  const renderList = (list: any) => {
    const { tesName, tjcName, workerCount, deviceCount, attendCount } = list;
    return (
      <div className={`pointSide ${list.position}`}>
        <div className='rowData'>{workerCount}</div>
        <div className='rowData'>{deviceCount}</div>
        <div className='rowData'>
          <div className='textData'>{tesName}</div>
        </div>
        <div className='rowData'>
          <div className='textData'>{tjcName}</div>
        </div>
        <div className='rowData'>
          <span>{attendCount}</span>
        </div>
      </div>
    );
  };

  // props를 받아서 className을 반환하는 함수
  const setAreaStyle = (expand: boolean, index: number) => {
    const expandStyle = expand ? 'expand' : '';
    const focusedStyle = autoPlay && index === focusedIndex ? 'focused' : '';
    return `${expandStyle} ${focusedStyle}`;
  };

  // 자동재생 시 다음 index를 구하는 함수
  const updateIndex = () =>
    setFocusedIndex((prev) => {
      return prev === tableData.length - 1 ? 0 : prev + 1;
    });

  const onClickTunnelInfo = (tareaParam: any, index: number) => {
    setOpenModal((prev: any) => ({ ...prev, status: true, data: tareaParam, index }));
  };

  return (
    <Root>
      <div className='tableHeader'>
        <div className='tareaName'>{t('구역 명')}</div>
        {tableHeaderList.map((list: { name: string; icon: string }) => (
          <div className='rowData' key={list.name}>
            <span className='material-symbols-rounded'>{list.icon !== '' ? list.icon : 'circle'}</span>
            {t(list.name)}
          </div>
        ))}
      </div>
      <div className={`tableBody ${autoPlay ? 'playNow' : ''}`} ref={wrapperRef}>
        {tableData.map((list, index: number) => (
          <div //
            className={`area ${setAreaStyle(list.isExpand, index)}`}
            key={list.tunnelName}
            ref={autoPlay && index === focusedIndex ? focusedRef : null}
            onClick={() => onClickTunnelInfo(data[index], index)}
            role='button'
            tabIndex={0}
          >
            <div className='areaHeader'>
              {list.tunnelName}
              <div className='pointLabel'>
                {list.start && <StartPoint />}
                {list.end && <EndPoint />}
              </div>
            </div>
            <div className='areaBody'>
              {list.start && renderList(list.start)}
              {list.end && renderList(list.end)}
              {list.isExpand ? <div className='rowData'>{list.attendCount}</div> : ''}
            </div>
          </div>
        ))}
        <div className='background-rows area'>
          <div className='areaHeader' />
          <div className='areaBody'>
            <div className='pointSide'>
              {tableHeaderList.map((list, index: number) => (
                <div className='rowData' key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Portal openModal={openModal?.status}>
        {openModal.status && (
          <TareaInfoModal //
            setOpenModal={setOpenModal}
            auth={auth}
            activeTab={0}
            data={openModal.data}
            dataList={data}
            index={openModal.index}
            showPrevNextBtn={false}
          />
        )}
      </Portal>
    </Root>
  );
};

export default TunnelTable;
