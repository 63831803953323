/**
 * 작성자 : 홍선영
 * 날짜 : 2024.01.18
 * 기능 : 근로자 위치 현황 대시보드 컴포넌트 (구역별 상황판)
 */

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PulseLoader } from 'react-spinners';
import { useQuery } from '@tanstack/react-query';

import { apiGet } from '../../services/_common';
import { PhotoTableListStyle } from '../../assets/styles/PhotoTableList';
import IssueGuide from '../IssueGuide';
import { formatMMDDHHMMSS } from '../../utils/formatDate';

interface Iprops {
  name: string;
  userInfo: any;
  tatCd: string;
}

interface IworkerLocation {
  tatGubun: string;
  tadCd: string;
  tadName: string;
  wImg: string;
  wCd: string;
  wName: string;
  sjCd: string;
  sjName: string;
  wPrejobtype: string;
  wPrejobtypeName?: any;
  wJobtype: string;
  wJobtypeName?: any;
  bDate: string;
}

export const WorkerLocation = ({ name, userInfo, tatCd }: Iprops) => {
  const { t } = useTranslation();
  const [sliderTabIndex, setSliderTabIndex] = useState<number>(0);

  // 근로자 위치현황 조회
  const { data, isLoading, isRefetching } = useQuery(['tAreaWorkerLocationGet', userInfo.hCd, userInfo.sCd, tatCd, sliderTabIndex], () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && !!tatCd,
    refetchInterval: 60 * 1000,
  });

  const fetchData = async () => {
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, tatCd };
      const res = await apiGet({ path: `/tarea/board/worker`, req });
      const tareaList = res.data.data?.tareaList || [];
      const s = tareaList.filter((el: IworkerLocation) => el.tatGubun?.toLowerCase() === 's');
      const e = tareaList.filter((el: IworkerLocation) => el.tatGubun?.toLowerCase() === 'e');

      if (s.length === 0) setSliderTabIndex(1); // 시점 목록이 없는경우 슬라이드탭 종점으로 이동

      return { sList: s, eList: e };
    } catch (error) {
      console.error(error);
      throw new Error('error /msg');
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setSliderTabIndex((prevTabIndex) => (prevTabIndex === 0 && data?.eList.length > 0 ? 1 : 0));
    }, 60000);

    return () => clearInterval(interval);
  }, [data]);

  if (isLoading || isRefetching || !data) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <PhotoTableListStyle>
      <div className='widget-header safety'>
        <div className='widget-title flex-between'>
          <span>
            {t(name)}
            <span className='badge'>{data.sList.length + data.eList.length || 0}</span>
          </span>
        </div>
      </div>
      <div className='widget-body photoTable'>
        <div className='innerTab nonClick'>
          {data.sList?.length > 0 ? (
            <div className={sliderTabIndex === 0 ? 'active' : undefined}>
              {t('시점')} ({data.sList.length})<div />
            </div>
          ) : undefined}
          {data.eList?.length > 0 ? (
            <div className={sliderTabIndex === 1 ? 'active' : undefined}>
              {t('종점')} ({data.eList.length})<div />
            </div>
          ) : undefined}
        </div>
        <div className='sliderWrapper'>
          <div
            style={{
              animation: (sliderTabIndex === 0 ? data.sList.length : data.eList.length) > 3 ? `slide ${sliderTabIndex === 0 ? data.sList.length * 2 : data.eList.length * 2}s linear infinite` : 'none',
            }}
            className={sliderTabIndex === 0 ? (data.sList.length > 3 ? 'text-container active' : undefined) : data.eList.length > 3 ? 'text-container active' : undefined}
          >
            <WorkerList workerList={sliderTabIndex === 0 ? data.sList : data.eList} />
          </div>
        </div>
      </div>
    </PhotoTableListStyle>
  );
};

const WorkerList = ({ workerList }: { workerList: IworkerLocation[] }) => {
  const randomString = Math.random().toString(36).substring(7); // 랜덤 문자열을 쿼리스트링으로 추가해서 이미지 캐싱 방지
  return (
    <div>
      {workerList.map((worker: IworkerLocation, i: number) => (
        <div key={`${worker.wCd}_${worker.tatGubun}_${i}`} className='rowWrapper flex-basic'>
          <div className='flex-basic'>
            <div className='imgWrapper'>{worker.wImg ? <img src={`${worker.wImg}?v=${randomString}`} alt='' /> : <span className='material-symbols-rounded'>person </span>}</div>
            <div className='flex-col'>
              <div className='flex-basic flex-between'>
                <div className='ellipsis' style={{ paddingRight: '0.5rem' }}>
                  {worker.wName}
                </div>
                <div className='buttonsWrapper flex-basic'>
                  <button type='button' className='violet'>
                    <span>{worker.tadName}</span>
                  </button>
                </div>
              </div>
              <div className='flex-basic flex-between'>
                <div className='ellipsis' style={{ paddingRight: '0.5rem' }}>
                  {worker.sjName} {worker.wPrejobtypeName}
                </div>
                <div className='buttonsWrapper flex-basic'>
                  <button type='button' className='gray'>
                    <span>{formatMMDDHHMMSS(worker.bDate)}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
