/**
 * 작성자 : 홍선영
 * 날짜 : 2023.08.11
 * 기능 : 대시보드 컴포넌트
 */

import { startTransition, useEffect, useRef, useState } from 'react';
import { v1 } from 'uuid';
import { useQuery } from '@tanstack/react-query';
import { PulseLoader } from 'react-spinners';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { DndProvider, useDrag } from 'react-dnd';
import { useRecoilState, useRecoilValue } from 'recoil';

import dayjs from 'dayjs';
import styled from 'styled-components';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import 'dayjs/locale/ko'; // Import Korean locale

import { asideFoldState, themeState, userState } from '../atoms';
import { DASHBOARD_GUBUN } from '../_constants';
import { IComCd, IComCdList, IDashboard, IDashboardM } from 'customTypes';
import { BtnDeep } from './Button';
import Portal from './Portal';
import MultiVideoPlayer from './MultipleVideoPlayer3';
import { isMobile } from '../utils/checkMobile';
import { arraySortByAscdOrder } from '../utils/arraySortByAscdOrder';
import { todayYYYYMMDD } from '../utils/formatDate';
import { getPreviousDays } from '../utils/getPrevWeeks';
import illustrator from '../assets/images/illustration/61.svg';
import notSupport from '../assets/images/illustration/78.svg';
import monitoring from '../assets/images/illustration/74.svg';
import WebSdkModal from './Modal/WebSdkModal';
import * as cctv from '../utils/cctv';
import WebSdk from '../pages/s_cctv/WebSdk';
import MGasInfo from './dashboard/MGasInfo';
import ExcavationProcess from './dashboard/ExcavationProcess';
import ExcavationProcessLarge from './dashboard/ExcavationProcessLarge';
import TunnelTotalInfo from './dashboard/TunnelTotalInfo';
import TunnelAreaInfo from './dashboard/TunnelAreaInfo';
import TunnelTotalTable from './dashboard/TunnelTotalTable';
import { WeatherInfo, WeatherWeekInfo } from './dashboard/Weather';
import { TotalInfo } from './dashboard/TotalInfo';
import { NewOldWorker, NewWorker, OldWorker, OldWorkerAttend, WorkerAttend } from './dashboard/WorkerList';
import { AttendTotalInfo } from './dashboard/AttendTotalInfo';
import { SafetyViolationInfo } from './dashboard/SafetyViolationInfo';
import { AttendInfo } from './dashboard/AttendInfo';
import { Dday } from './dashboard/Dday';
import { EquipInfo } from './dashboard/EquipInfo';
import { PmInfo } from './dashboard/PmInfo';
import { AccidentInfo } from './dashboard/AccidentInfo';
import { SafetyViolationInfoDetail } from './dashboard/SafetyViolationInfoDetail';
import { ExcavationWorkerPosition } from './dashboard/ExcavationWorkerPosition';
import SelectBox from './SelectBox';
import { apiGet } from '../services/_common';
import WeatherTotal from './dashboard/WeatherTotal';

dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.locale('ko'); // Set the locale to Korean

interface IProps {
  isViewMode: boolean; // 세팅화면인지/뷰화면인지 여부
}

interface RootStyleProps extends IProps {
  maxRowCount: number;
}

interface DraggableCellStyleProps {
  isDragging: boolean;
}

interface DroppableCellStyleProps extends IProps {
  cellHeight: number;
  cellWidth: number;
}

const Root = styled.div<RootStyleProps>`
  .dragNDropZone {
    display: flex;
    justify-content: space-between;
    align-items: start;

    .dragZone {
      display: ${(props) => (props.isViewMode ? `none !important` : `flex`)};
      flex: 0.15;
      display: flex;
      flex-direction: column;
      .dragCell {
        margin: 0.5rem 0;
      }
    }

    .dropZone {
      flex: ${(props) => (props.isViewMode ? `1` : `0.85`)};
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(${(props) => props.maxRowCount}, 1fr);
      gap: 1rem;

      > div {
        overflow: hidden;
        margin: 0;
        background-color: ${({ theme }: { theme: any }) => theme.board};
      }
    }
  }

  @media screen and (max-width: 1679px) {
    .dragNDropZone .dropZone {
      display: flex;
      flex-direction: row;
      flex-flow: wrap;
      width: calc(100% - 16rem);
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 1023px) {
    .dragNDropZone .dropZone {
      display: flex;
      flex-wrap: wrap;
    }
  }
`;

const DraggableCellStyle = styled.div<DraggableCellStyleProps>`
  // min-width: min-content;
  background-color: #5ac8fa;
  color: #fff;
  padding: 0.5rem;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 1rem;
  cursor: pointer;
  opacity: ${(props) => (props.isDragging ? '0.5' : '1')};
  height: 90%;
`;

const DroppableCellStyle = styled.div<DroppableCellStyleProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  border-radius: 5px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.03);
  /* outline: ${({ theme }: { theme: any }) => theme.outline_status}; */
  outline: ${({ theme }: { theme: any }) => theme.outline_status};

  .dropCell {
    width: 100%;
    height: ${(props) => props.cellHeight}rem;
  }

  .closeIconWrapper {
    display: ${(props) => (props.isViewMode ? `none !important` : `flex`)};
  }

  .closeIcon {
    font-size: 24px;
    color: yellow;
    transition: transform 0.1s ease-in-out;

    &:hover {
      transform: scale(1.2);
    }
  }

  @media screen and (max-width: 1679px) {
    width: ${(props) => (props.cellWidth === 1 ? 'calc(50% - 0.5rem)' : props.cellWidth === 2 ? '100%' : props.cellWidth === 3 ? '100%' : '100%')};
    /* width: ${(props) => (props.cellWidth === 1 ? 'calc(50% - 0.5rem)' : props.cellWidth === 2 ? '100%' : props.cellWidth === 3 ? '66.6%' : '100%')}; */
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

interface DraggableCellProps {
  cell: any;
}

interface Piece {
  dCd: string;
  name: string;
  width: number;
  height: number;
  isInDropZone: boolean;
  location: { column: number; row: number; mRow?: number };
}

const DraggableCell = ({ cell }: DraggableCellProps) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'cell',
    item: cell,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return <DraggableCellStyle ref={drag} isDragging={isDragging} />;
};

const DroppableZone = ({ children, cell, isViewMode }: any) => {
  return (
    <DroppableCellStyle
      style={{
        gridColumnStart: cell.location.column + 1,
        gridRowStart: cell.location.row + 1,
        gridColumnEnd: cell.location.column + cell.width + 1,
        gridRowEnd: cell.location.row + cell.height + 1,
        backgroundColor: cell.name === '' ? '#fff' : undefined,
      }}
      isViewMode={isViewMode}
      cellHeight={cell.height === 1 ? 12 : 25}
      cellWidth={cell.width}
    >
      <div className='dropCell' style={cell.name === '' ? { display: 'none' } : { display: 'block' }}>
        <Control content={children} />
      </div>
    </DroppableCellStyle>
  );
};

const DashboardSetting = ({ isViewMode }: IProps) => {
  const userInfo = useRecoilValue(userState);
  const [reLocatedDragPieces, setReLocatedDragPieces] = useState<Piece[]>([]); // 위치 수정한 드래그피스 데이터(width 조절시 다시 되돌아가기 위한 state값)
  const [dropZoneGrid, setDropZoneGrid] = useState<any[]>([]);
  const [dropZoneMaxRowCount, setDropZoneMaxRowCount] = useState<number>(0);
  const [dashboardCdList, setDashboardCdList] = useState<IComCd[]>([]);
  const [dashboardSetList, setDashboardSetList] = useState<IDashboard[]>([]);
  const [dashboardMList, setDashboardMList] = useState<Piece[]>([]);
  const [dragPieces, setDragPieces] = useState<Piece[]>([]);
  const D_GUBUN_H = '1'; // 본사용 대시보드 구분 공통코드 목록 (etc1값)
  const D_GUBUN_S = '2'; // 현장용 대시보드 구분 공통코드 목록(etc1값)
  const dGubun = userInfo.sCd === '00000' ? 'h' : 's';
  const dGubunComCdQuery = useQuery(['dashboardGubunComcdGet', dGubun], () => apiGet({ path: '/code/detail', req: { grCd: DASHBOARD_GUBUN } }), {
    enabled: !!dGubun && !!DASHBOARD_GUBUN,
  });

  const dashboardMListQuery = useQuery(['dashboardMasterGet', userInfo.hCd, userInfo.sCd, dGubun], () => apiGet({ path: '/dashboard/master', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, dGubun } }), {
    retry: 3,
    enabled: !!userInfo.hCd && !!userInfo.sCd && !!dGubun,
  });

  const dashboardSetListQuery = useQuery(['dashboardListGet', userInfo.hCd, userInfo.sCd, dGubun], () => apiGet({ path: '/dashboard' }), {
    retry: 3,
    enabled: !!userInfo.hCd && !!userInfo.sCd && !!dGubun,
  });

  useEffect(() => {
    if (dashboardSetListQuery.isSuccess && dashboardSetListQuery.data) {
      const { DashboardSetList } = dashboardSetListQuery.data.data.data;
      setDashboardSetList(DashboardSetList);
    }
  }, [dashboardSetListQuery.isSuccess, dashboardSetListQuery.isRefetching]);

  const setPiece = (dataItemParam: any, bigo: string) => {
    if (dataItemParam) {
      // 유저의 메뉴 권한에 따른 대시보드 노출처리 함수
      if (bigo) {
        // 비고값이 있는경우, 권한유무에 따라 처리
        const authArray = bigo.split(',');
        const mtCd = authArray[0];
        const mdCd = authArray[1];
        const mrCd = authArray[2];
        const hasMenuAuth = userInfo.userMenuList?.find((el: any) => el.mtCd === mtCd && el.mdCd === mdCd && el.mrCd === mrCd);
        if (hasMenuAuth !== undefined) return true;
        return false;
      }
      return true; // 비고값이 없으면 전체공개하는 대시보드
    }
    return false;
  };

  useEffect(() => {
    if (dashboardSetList && dashboardMListQuery.isSuccess && dashboardMListQuery.data) {
      const { DashboardMList } = dashboardMListQuery.data.data.data;
      const useYDashboard = dashboardSetList.filter((setListEl: IDashboard) => setListEl.useYn === 'Y');
      const result = useYDashboard.map((setListEl: IDashboard) => {
        const dataItem = DashboardMList.find((mListEl: IDashboardM) => mListEl.dCd === setListEl.dCd);
        return {
          dCd: setListEl.dCd,
          name: setListEl.dName,
          isInDropZone: setPiece(dataItem, setListEl.bigo),
          // isInDropZone: !!dataItem,
          ...(dataItem ? { location: { column: dataItem.dCol, row: dataItem.dRow } } : { location: { column: -1, row: -1 } }),
          width: setListEl.dCol,
          height: setListEl.dRow,
        };
      });

      const sortedData = result.sort((a: any, b: any) => {
        if (a.location.row !== b.location.row) {
          return a.location.row - b.location.row;
        }
        return a.location.column - b.location.column;
      });
      setDashboardMList(sortedData);
      // setDashboardMList(initialDragPiecies); // Dummy data
    }
  }, [dashboardMListQuery.isSuccess, dashboardMListQuery.isRefetching, dashboardSetList]);

  useEffect(() => {
    if (dashboardMList?.length > 0) {
      setDragPieces(dashboardMList);
    }
  }, [dashboardMList]);

  useEffect(() => {
    if (dGubunComCdQuery.isSuccess && dGubunComCdQuery.data) {
      const { comCdList } = dGubunComCdQuery.data.data.data;
      // 로그인한 유저가 본사/현장 유저인지에 따라 구분목록 세팅
      let newArray = [];
      if (userInfo.gCd.substring(0, 1) === 'S') {
        newArray = comCdList.filter(({ useYn, etc1 }: IComCdList) => useYn === 'Y' && etc1 === D_GUBUN_S).map(({ subCd, cdName, cdSort }: IComCd) => ({ subCd, cdName, cdSort }));
      }
      if (userInfo.gCd.substring(0, 1) === 'H') {
        newArray = comCdList.filter(({ useYn, etc1 }: IComCdList) => useYn === 'Y' && etc1 === D_GUBUN_H).map(({ subCd, cdName, cdSort }: IComCd) => ({ subCd, cdName, cdSort }));
      }
      const sorted = arraySortByAscdOrder(newArray, 'cdSort');
      setDashboardCdList(sorted);
    }
  }, [dGubunComCdQuery.isSuccess, dGubunComCdQuery.isRefetching]);

  useEffect(() => {
    const mergedGrid = dragPieces.map((gridItem: any) => {
      const matchPiece = dragPieces.find((piece) => piece.dCd === gridItem.dCd);
      return matchPiece ? { ...gridItem, ...matchPiece } : gridItem;
    });

    setDropZoneGrid(mergedGrid);
  }, [dragPieces]);

  const handleDrop = (dropItem: Piece, dropCell: any) => {
    const copyDragPieces = [...dragPieces];

    // dropItem의 id와 일치하는 항목을 드래그 피스 배열에서 찾기
    const index = copyDragPieces.findIndex((piece) => piece.dCd === dropItem.dCd);

    // 일치하는 아이템이 있으면 드래그피스 배열 업데이트
    if (index !== -1) {
      // dropItem의 속성을 사용하여 새 항목을 생성하고 InDropZone을 true로 설정
      const updatedItem = {
        ...copyDragPieces[index],
        ...dropItem,
        isInDropZone: true,
        location: dropCell.location,
      };

      // 업데이트된 아이템으로 드래그피스 배열의 아이템 대치
      copyDragPieces[index] = updatedItem;
      setDragPieces(copyDragPieces); // 드래그피스배열 setState
      setReLocatedDragPieces(copyDragPieces);
    }
  };

  useEffect(() => {
    if (reLocatedDragPieces.length === 0) {
      setReLocatedDragPieces(dragPieces);
    }
  }, [dragPieces]);

  return (
    <Root maxRowCount={dropZoneMaxRowCount} isViewMode={isViewMode}>
      <DndProvider backend={HTML5Backend}>
        <div className='dragNDropZone'>
          <div className='dragZone'>{dragPieces.map((el) => !el.isInDropZone && <DraggableCell key={el.dCd} cell={el} />)}</div>
          <div className='dropZone'>
            {dropZoneGrid?.map(
              (el) =>
                el.isInDropZone && (
                  <DroppableZone
                    key={v1()}
                    onDrop={(item: Piece) => handleDrop(item, el)}
                    cell={el}
                    dragPieces={dragPieces}
                    setDragPieces={setDragPieces}
                    dropZoneGrid={dropZoneGrid}
                    setDropZoneGrid={setDropZoneGrid}
                    isViewMode={isViewMode}
                  >
                    <DraggableCell key={v1()} cell={el} />
                  </DroppableZone>
                )
            )}
          </div>
        </div>
      </DndProvider>
    </Root>
  );
};

export default DashboardSetting;

interface IControl {
  content: any;
}

const ControlStyle = styled.div<any>`
  height: inherit;
  position: relative;
  .tunnel-title {
    display: flex;
    .tunnel-name {
      width: 8rem;
      &.tight {
        width: fit-content;
        margin-right: 1rem;
      }
    }
    .tunnel-guide {
      display: flex;
      align-items: center;
      .seperator {
        margin: 0 1rem;
        border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline_em};
        height: 1rem;
        &.tight {
          margin: 0 0.5rem;
        }
      }
    }
  }
  .weatherTodayInfo,
  .weatherWeekInfo {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    > .widget-half-background {
      background-color: #3e97ff;
      position: absolute;
      top: 0;
      border-radius: 0.5rem;
      width: 100%;
      height: 50%;
    }
    > .widget-header {
      width: 100%;
      padding: 0.75rem 1rem;
      z-index: 1;
      background-color: ${({ theme }: { theme: any }) => theme.weather_bg};
      > .widget-title {
        // height: 2rem;
        color: white;
        > span {
          font-weight: 600;
          font-size: 1.125rem;
          line-height: 1.2;
        }
      }
      > .widget-subTitle {
      }
    }
    > .widget-body {
      width: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      /* overflow: hidden; */
      overflow: visible;
      z-index: 1;
      .weatherToday {
        flex: 1 0 50%;
        width: 100%;
        max-height: 10rem;
        -webkit-box-pack: justify;
        justify-content: space-around;
        -webkit-box-align: center;
        align-items: center;
        background-color: ${({ theme }: { theme: any }) => theme.weather_bg};
        .weatherInfo {
          position: absolute;
          padding: 1rem;
          > div:nth-child(1) {
            font-weight: 500;
            font-size: 1.125rem;
          }
        }
        .weatherBox {
          height: 6rem;
          border-radius: 8px;
          padding: 0 1rem;
          text-align: center;
          background: #f3f6f9;
          font-size: 0.875rem;
          dt {
            font-weight: 500;
          }
          dl > div {
            display: flex;
            width: inherit;
            > dt,
            > dd {
              flex: 0.5;
            }
            > dt {
              text-align: start;
            }
            > dd {
              text-align: center;
            }
          }
        }
        .weatherBox.text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          > div {
            display: flex;
            justify-content: space-between;
          }
        }
        .weatherAnimationWrapper {
          .container {
            > div {
              svg {
                pointer-events: none;
              }
            }
          }
        }
      }
      .weatherToday.notWeek .weatherInfo {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        z-index: 10;
        > .weatherBox {
          height: auto;
          background-color: transparent;
          padding: 0;
          > div {
            justify-content: flex-start;
            gap: 0.5rem;
            height: 1.25rem;
          }
        }
      }
      .weatherWeek {
        flex: 1 0 50%;
        padding: 0 1rem;
        display: flex;
        background-color: ${({ theme }: { theme: any }) => theme.board};
        z-index: 999;
        > div {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .date {
            > div {
              font-size: 0.875rem;
              font-weight: 600;
            }
            > div:nth-child(1) {
              color: ${({ theme }: { theme: any }) => theme.text_secondary};
            }
            > div:nth-child(2) {
              color: ${({ theme }: { theme: any }) => theme.text_primary};
            }
          }
          .weatherWeekIcon {
            > img {
              width: 2.5rem;
            }
          }
          .weatherWeekTemp {
            > div {
              font-weight: 600;
              color: ${({ theme }: { theme: any }) => theme.text_secondary};
              font-size: 0.75rem;
            }
          }
        }
      }
      .weatherTodayDetail {
        height: 100%;
        background-color: ${({ theme }: { theme: any }) => theme.board};
        z-index: 10;
        .weatherBoxGroup {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 0.5rem;
          bottom: 1rem;
          position: absolute;
          width: calc(100% - 2rem);
          left: 50%;
          transform: translate(-50%, 0);
          .weatherBox {
            height: 6rem;
            border-radius: 8px;
            padding: 0 1rem;
            text-align: center;
            background: ${({ theme }: { theme: any }) => theme.tonal};
            font-size: 0.875rem;
            dt,
            > div > dt,
            > div > dd {
              font-weight: 500;
              color: ${({ theme }: { theme: any }) => theme.text_primary};
            }

            dl > div {
              display: flex;
              width: inherit;
              > dt,
              > dd {
                flex: 0.5;
              }
              > dt {
                text-align: start;
              }
              > dd {
                text-align: center;
              }
            }
          }
          .weatherBox.text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            > div {
              display: flex;
              justify-content: space-between;
            }
          }
          .weatherBox.icon {
            display: flex;
            align-items: center;
            justify-content: space-between;
            dt {
              text-align: left;
            }
            > dd {
              position: relative;
              > div:nth-child(1) img {
                height: 4rem;
              }
            }
            .weatherBox-details {
              display: flex;
              flex-direction: column;
              /* gap: 0.5rem; */
              justify-content: center;
              font-size: 0.75rem;
              > div {
                height: 1.25rem;
                font-weight: 600;
                color: ${({ theme }: { theme: any }) => theme.text_secondary};
              }
            }
          }
        }
      }
    }

    .contents {
      height: calc(100% - 4rem);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      > div {
        flex: 0.5;
      }
    }

    .weatherAnimationWrapper {
      width: 100%;
      height: 100%;
      overflow: visible;

      .container {
        width: 100%;
        height: inherit;
        position: relative;

        .svg-container {
          left: 0;
        }
      }

      .rotating-sun {
        animation: rotation 10s infinite linear;
        position: absolute;
        top: -0.5rem;
        right: 1.5rem;
      }
      .little_cloud {
        position: absolute;
        right: 1%;
        top: -15%;
        &.beside-sun {
          right: 6%;
        }
      }
      .blur {
        position: absolute;
        right: 8%;
        top: -10%;
        width: 180px;
      }
      .cloudy {
        left: 25% !important;
        top: 17%;
      }
    }
  }

  .snowdrop {
    position: absolute;
    bottom: 0;
    width: 2px;
    height: 15px;
    background: transparent;
    opacity: 0.7;
    animation-name: snowfall;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    top: -20px;
  }

  @keyframes snowfall {
    0% {
      transform: translate3d(0, 0, 0);
    }
    25% {
      transform: translate3d(var(--random-left-right, 0), 25vh, 0);
    }
    50% {
      transform: translate3d(0, 50vh, 0);
    }
    75% {
      transform: translate3d(var(--random-left-right, 0), 75vh, 0);
    }
    100% {
      transform: translate3d(0, 100vh, 0);
    }
  }

  @keyframes fall {
    to {
      transform: translateY(100vh);
    }
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .svg-container {
    position: absolute;
    top: 0;
    left: -100%;
    width: inherit;
    height: inherit;
    transition: left 1s ease-in-out;
  }

  .show-svg {
    left: 25%;
  }

  .floating {
    animation: float 3s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }

  .bouncing {
    animation: bounce 4s ease infinite;
  }

  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    14%,
    29% {
      transform: translateY(-20px);
    }
  }

  .raindrop,
  .snowice,
  .sunflower {
    position: absolute;
    bottom: 0;
    opacity: 0.7;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    top: -20px;
  }

  .raindrop {
    width: 2px;
    height: 15px;
    background: lightgray;
    animation-name: fallRaindrop;
  }

  .snowice {
    width: 10px;
    height: 10px;
    background: transparent;
    animation-name: fallSnowIce;
  }

  .sunflower {
    width: 15px;
    height: 15px;
    background: transparent;
    animation-name: fallSunflower;
  }

  @keyframes fallRaindrop {
    to {
      transform: translateY(100vh);
    }
  }

  @keyframes fallSnowIce {
    to {
      transform: translateY(100vh);
    }
  }

  @keyframes fallSunflower {
    to {
      transform: translateY(100vh);
    }
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    div.refresh-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      border-radius: 2rem;
      /* background-color: #f9f9f9; */
      .material-symbols-rounded {
        font-variation-settings: 'FILL' 1, 'wght' 300, 'GRAD' 0, 'opsz' 20;
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        opacity: 0.6;
      }
    }
    div.refresh-btn:hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      .material-symbols-rounded {
        opacity: 1;
      }
    }
    div.refresh-btn.white {
      .material-symbols-rounded {
        color: white;
        opacity: 0.6;
      }
      /* background-color: rgba(255, 255, 255, 0.1); */
    }
    div.refresh-btn.white:hover {
      .material-symbols-rounded {
        opacity: 1;
      }
      background-color: rgba(255, 255, 255, 0.15);
    }
    div.play-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 95%;
      border-radius: 0.5rem;
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      .material-symbols-rounded {
        font-size: 10rem;
        font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        opacity: 0.6;
      }
      &.cctv {
        .material-symbols-rounded {
          font-variation-settings: 'FILL' 1;
          font-size: 4rem;
        }
      }
    }
    div.play-btn:hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
      .material-symbols-rounded {
        opacity: 1;
      }
    }
    div.play-btn.white {
      .material-symbols-rounded {
        color: white;
        opacity: 0.6;
      }
      /* background-color: rgba(255, 255, 255, 0.1); */
    }
    div.play-btn.white:hover {
      .material-symbols-rounded {
        opacity: 1;
      }
      background-color: rgba(255, 255, 255, 0.15);
    }
  }
  .totalInfo,
  .safetyViolationInfo {
    height: 100%;
    position: relative;
    > .widget-header {
      width: 100%;
      padding: 0.75rem 1rem;
      > .widget-title {
        // height: 2rem;
        > span {
          /* font-weight: 600; */
          font-size: 1.125rem;
          line-height: 1.2;
          font-weight: ${({ theme }: { theme: any }) => theme.font_semibold};
          color: ${({ theme }: { theme: any }) => theme.text_primary};
        }
      }
      > .widget-subTitle {
        font-size: 0.875rem;
        line-height: 1rem;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
        /* color: rgba(0, 0, 0, 0.7); */
      }
    }
    > .widget-body {
      flex-grow: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 1rem;
      margin-bottom: 1rem;
      > div.infoBox {
        flex: 1 1 25%;
        gap: 1rem;
        > div:nth-child(1) {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          border-radius: 0.5rem;
          width: 4rem;
          height: 4rem;
          flex-shrink: 0;
          font-size: 1.5rem;
          .material-symbols-rounded {
            font-variation-settings: 'FILL' 1, 'wght' 600, 'GRAD' 200, 'opsz' 48;
            /* color: rgba(0, 0, 0, 0.25); */
            font-size: 1.75rem;
          }
        }
        > div.red {
          /* background-color: #fff5f8; */
          background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
        }
        > div.orange {
          color: #fb923c;
          fill: #fb923c;
          /* background-color: #fff7ed; */
          background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
        }
        > div.green {
          /* background-color: #f0fdf4; */
          background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
        }
        > div.blue {
          /* background-color: #eef2ff; */
          background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
        }
        > div.labelAndValue {
          flex-grow: 1;
          gap: 1rem;
          font-weight: 600;
          height: 100%;
          .infoBox-label {
            color: ${({ theme }: { theme: any }) => theme.text_secondary};
            font-weight: 600;
          }
          div.infoBox-value {
            display: flex;
            gap: 0.25rem;
            align-items: center;
            color: ${({ theme }: { theme: any }) => theme.text_tertiary};
            margin-right: 1rem;
            span {
              font-size: 1.25rem;
              font-weight: 600;
              color: ${({ theme }: { theme: any }) => theme.text_primary};
              padding-bottom: 0.125rem;
            }
          }
        }
      }
      /* > div.infoBox:nth-last-child(n + 2) > div.labelAndValue {
        border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      } */
    }
    > .widget-body.safety {
      user-select: none;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      > div {
        gap: 0.75rem;
        > div:nth-child(1) {
          width: 4.5rem;
          height: 4.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(0, 0, 0, 0.025);
          border-radius: 0.5rem;
          .material-symbols-rounded {
            font-variation-settings: 'FILL' 1, 'wght' 600, 'GRAD' 200, 'opsz' 40;
            font-size: 2rem;
          }
        }
        > div.red {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
        }
        > div.orange {
          color: #fb923c;
          fill: #fb923c;
          background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
        }
        > div:nth-child(2) {
          font-size: 0.875rem;
          line-height: 1.25rem;
          color: ${({ theme }: { theme: any }) => theme.text_secondary};
          font-weight: ${({ theme }: { theme: any }) => theme.font_semibold};

          > div {
            font-size: 1rem;
            span {
              font-size: 1.25rem;
              color: ${({ theme }: { theme: any }) => theme.text_primary};
              font-weight: ${({ theme }: { theme: any }) => theme.font_semibold};
            }
          }
        }
      }
    }
  }

  .pmInfo,
  .cctvNarrow {
    li {
      width: 100% !important;
      max-width: none !important;
    }
  }

  .attend,
  .attendTotalInfo,
  .equipInfo,
  .oldWorker,
  .newWorker,
  .pmInfo,
  .dDay,
  .accidentInfo,
  .cctvWide,
  .cctvNarrow,
  .safetyViolationInfoDetail,
  .noxiousGas,
  .mGas {
    user-select: none;
    height: 100%;
    > .widget-header {
      width: 100%;
      padding: 0.75rem 0;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      &.margin-top {
        margin-bottom: 0;
      }
      > .widget-title {
        padding: 0 1rem;
        align-items: center;
        > span {
          flex-shrink: 0;
          font-weight: ${({ theme }: { theme: any }) => theme.font_semibold};
          font-size: 1.125rem;
          line-height: 1.2;
          color: ${({ theme }: { theme: any }) => theme.text_primary};
          display: flex;
          align-items: center;
          > .badge {
            font-weight: 500;
            font-size: 0.875rem;
            padding: 0.125rem 0.5rem;
            border-radius: 0.25rem;
            background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
            margin: 0 0.5rem;
            color: rgb(62, 151, 255);
            display: inline-block;
            min-width: 1.75rem;
            text-align: center;
          }
        }
        > .widget-title-innerTab,
        > .widget-btn-group {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          > span {
            font-weight: ${({ theme }: { theme: any }) => theme.font_semibold};
            font-size: 1.125rem;
            color: ${({ theme }: { theme: any }) => theme.text_primary};
          }
          > button {
            height: 2rem;
            font-weight: ${({ theme }: { theme: any }) => theme.font_bold};
            padding: 0 0.75rem;
            border-radius: 1rem;
            font-size: 0.875rem;
            color: ${({ theme }: { theme: any }) => theme.text_secondary};
            background-color: ${({ theme }: { theme: any }) => theme.tonal};
            border-radius: 0.5rem;
            gap: 0;
          }
          > button:hover {
            background-color: ${({ theme }: { theme: any }) => theme.tonal};
          }
          > button:nth-child(1) {
            padding-left: 0.25rem;
            padding-right: 0.5rem;
            .material-symbols-rounded {
              font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 20;
              color: #78829d;
            }
          }
          > .innerTab {
            gap: 0.25rem;
            > div {
              cursor: pointer;
              height: 2rem;
              padding: 0 0.75rem;
              display: flex;
              align-items: center;
              color: ${({ theme }: { theme: any }) => theme.text_secondary};
              border-radius: 1rem;
              font-weight: ${({ theme }: { theme: any }) => theme.font_medium};
              /* font-size: 0.875rem; */
            }
            > div.active {
              font-weight: ${({ theme }: { theme: any }) => theme.font_bold};
              color: #3e97ff;
              background-color: ${({ theme }: { theme: any }) => theme.tonal};
            }
            > div:hover {
              background-color: ${({ theme }: { theme: any }) => theme.tonal};
            }
          }
        }
      }
      > .widget-subTitle {
      }
    }
    .widget-header.safety {
      gap: 0;
      .widget-subTitle {
        padding: 0 1rem;
        font-size: 0.875rem;
        line-height: 1rem;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
      }
    }
    .oneWeekCalendar-wrapper {
      padding: 0 1rem;
      width: 100%;
    }
    .oneWeekCalendar {
      overflow: auto;
      ::-webkit-scrollbar {
        height: 0rem;
      }
      gap: 0;
      justify-content: space-between;
      .dayWrapper {
        // flex-grow: 1;
        padding: 0.75rem;
        min-width: fit-content;
        // max-width: 2.75rem;
        border-radius: 2rem;
        text-align: center;
        cursor: pointer;
        .day {
          font-weight: ${({ theme }: { theme: any }) => theme.font_medium};
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        }
        .date {
          color: ${({ theme }: { theme: any }) => theme.text_primary};
        }

        &.thinPadding {
          padding: 0.75rem 0.45rem;
        }
      }
      .dayWrapper:hover {
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
      }
      .todayHighlight,
      .todayHighlight:hover {
        background-color: ${({ theme }: { theme: any }) => theme.selected_primary};
        > div.day,
        > div.date {
          color: #fff;
        }
        > div.day {
          font-weight: 500;
        }
      }
    }
    .widget-body {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .widget-body.photoTable {
      flex-grow: 1;
      overflow: hidden;
      > .innerTab {
        width: 100%;
        display: flex;
        border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
        > div {
          height: 3rem;
          display: flex;
          align-items: center;
          flex: 1 1 50%;
          justify-content: center;
          border-bottom: 2px solid transparent;
          font-size: 0.875rem;
          cursor: pointer;
          color: ${({ theme }: { theme: any }) => theme.text_secondary};
          font-weight: 700;
        }
        > div.active {
          color: ${({ theme }: { theme: any }) => theme.text_primary};
          border-bottom: 2px solid ${({ theme }: { theme: any }) => theme.selected_primary};
        }
      }
      > .sliderWrapper {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        position: relative;
        ::-webkit-scrollbar-thumb {
          background-color: transparent;
        }
        &:hover::-webkit-scrollbar-thumb {
          background-color: ${({ theme }: { theme: any }) => theme.scrollBar};
        }
        .text-container {
          display: flex;
          flex-direction: column;
          position: relative;
        }

        .rowWrapper {
          width: 100%;
          padding-right: 0.5rem;
          padding-left: 1rem;
          cursor: pointer;
          gap: 0.5rem;
          height: 3.5rem;
          > div:nth-child(1) {
            flex-grow: 1;
            gap: 0.5rem;
            width: 12rem;
            .imgWrapper {
              width: 2.25rem;
              height: 2.25rem;
              border-radius: 0.25rem;
              overflow: hidden;
              flex-shrink: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: rgba(0, 0, 0, 0.05);
              .material-symbols-rounded {
                font-variation-settings: 'FILL' 1, 'wght' 600, 'GRAD' 200, 'opsz' 48;
                color: rgba(0, 0, 0, 0.25);
                font-size: 1.75rem;
              }

              img {
                width: inherit;
                height: inherit;
                object-fit: cover;
              }
            }
            > div:nth-child(2) {
              width: calc(100% - 2.75rem);
              > div:nth-child(1),
              > .rowContent {
                width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 0.875rem;
                line-height: 1.25rem;
                height: 1.25rem;
                color: ${({ theme }: { theme: any }) => theme.text_disabled};
                span {
                  color: ${({ theme }: { theme: any }) => theme.text_primary};
                  font-size: 0.875rem;
                  line-height: 1rem;
                }
              }
            }
          }
        }
        .rowWrapper:hover {
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
        }
        .buttonsWrapper {
          button {
            height: inherit;
            padding: 0;
            width: 2.5rem;
            font-size: 0.875rem;
            height: 1.75rem;
            span {
              font-weight: 700;
              margin: 0 0.125rem;
            }
          }
          button.red {
            background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
            color: ${({ theme }: { theme: any }) => theme.filled_red};
          }
          button.amber {
            background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
            color: ${({ theme }: { theme: any }) => theme.filled_orange};
          }
        }
      }
    }
    .widget-body.chart {
      width: 100%;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      .doughnutChartWrapper {
        height: inherit;
        > canvas {
          width: 10rem;
          height: 10rem;
          margin: 0 auto;
        }
      }
      .content {
        margin: 1rem 0;
        display: flex;
        align-items: center;
        gap: 1rem;
        dl {
          display: flex;
          align-items: center;
          gap: 0.25rem;
          color: ${({ theme }: { theme: any }) => theme.text_secondary};
          > .chart-color {
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 1rem;
          }
          > .chart-color.blue {
            background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
          }
          > .chart-color.green {
            background-color: ${({ theme }: { theme: any }) => theme.filled_green};
          }
          > .chart-color.red {
            background-color: ${({ theme }: { theme: any }) => theme.filled_red};
          }
          > span > span.value {
            font-weight: 600;
            color: ${({ theme }: { theme: any }) => theme.text_primary};
          }
        }
      }
    }
    .widget-body.progressBar {
      width: 100%;
      flex-grow: 1;
      padding: 0 1rem;
      justify-content: space-between;
      margin-bottom: 1rem;
      > .progressBar-group {
        display: flex;
        flex-direction: column;
        > .progressBar-text {
          > span:nth-child(1) {
            color: ${({ theme }: { theme: any }) => theme.text_secondary};
          }
          > span:nth-child(2) {
            color: ${({ theme }: { theme: any }) => theme.text_primary};
          }
        }
        .fullBar {
          height: 0.75rem;
          border-radius: 1rem;
          margin-top: 0.25rem;
          overflow: hidden;
          display: flex;
          .fillBar {
            background-color: rgb(80, 205, 137);
            height: 100%;
          }
          .emptyBar {
            background-color: rgb(80, 205, 137);
            height: 100%;
            opacity: 0.35;
          }
        }
      }
      .content {
        width: 100%;
        font-size: 0.875rem;
        dl {
          gap: 0.5rem;
          > dt {
            color: ${({ theme }: { theme: any }) => theme.text_secondary};
            font-weight: 600;
          }
        }
        dd {
          color: ${({ theme }: { theme: any }) => theme.text_primary};
        }
        > div {
          > span {
            border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
            padding: 0.5rem 0;
            margin: 0 0.5rem;
          }
          span.green {
            padding: 0.125rem 0.375rem;
            background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
            border-radius: 0.25rem;
          }
          span.blue {
            padding: 0.125rem 0.375rem;
            background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
            border-radius: 0.25rem;
          }
        }
      }
    }
    .widget-body.donut {
      width: 100%;
      flex-direction: row;
      > .doughnutChartWrapper {
        overflow: hidden;
        height: 7rem;
        padding: 0 1rem;
        margin-bottom: 1rem;
        width: 9rem;
        flex-shrink: 0;
      }
      > .content {
        flex: 1 1 auto;
        padding-right: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        dd,
        dt {
          font-size: 0.875rem;
          color: ${({ theme }: { theme: any }) => theme.text_primary};
        }
        dl {
          gap: 0.5rem;
          > dt {
            color: ${({ theme }: { theme: any }) => theme.text_secondary};
            font-weight: 600;
            gap: 0.5rem;
            div {
              width: 0.75rem;
              height: 0.75rem;
              border-radius: 1rem;
            }
            div.fill {
              background-color: #3e97ff;
            }
            div.empty {
              background-color: #3e97ff;
              opacity: 0.25;
            }
          }
          > dd {
            gap: 0.25rem;
            span {
              padding: 0.125rem 0.375rem;
              color: #3e97ff;
              background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
              border-radius: 0.25rem;
              line-height: 1rem;
            }
          }
        }
      }
    }
    .widget-body.table {
      width: 100%;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      overflow: hidden;
      > div {
        height: 100%;
        .table {
          flex-grow: 1;
        }
      }
      .tr {
        pointer-events: none;
        .padding-right {
          padding-right: 1rem;
        }
        .tableStickyNo {
          padding-left: 1rem;
          font-weight: 600;
        }
        span.redLight {
          width: 2.125rem;
          height: 1.5rem;
          color: #f1416c;
          font-weight: 600;
          text-align: center;
          border-radius: 0.25rem;
          background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
        }
        span.greenLight {
          width: 2.125rem;
          height: 1.5rem;
          color: #50cd89;
          font-weight: 600;
          text-align: center;
          border-radius: 0.25rem;
          background-color: rgb(232, 255, 243);
        }
      }
      .tr.hover {
      }
    }
    .widget-body.graph {
      width: 100%;
      height: 100%;
      padding: 0 1rem;
      > div:nth-child(1) {
        width: 100%;
        > ul > li {
          width: 100%;
          max-width: none;
          > span {
            width: 100%;
          }
        }
      }
      > .lineChartWrapper {
        padding-bottom: 1rem;
        height: 100%;
      }
    }
    .widget-body.cctv-narrow {
      flex-grow: 1;
      padding: 0 1rem;
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      > div:nth-child(1) {
        > ul > li {
          width: 100%;
          max-width: none;
          span {
            width: 100%;
          }
        }
      }
      .cctvWrapper {
        border-radius: 0.25rem;
        padding: 0;
      }
      .cameraLabel {
        display: none;
      }
      .controllerWrapper {
        display: none;
      }
    }
    .widget-body.cctv-wide {
      flex-direction: row;
      gap: 0.5rem;
      padding: 0 1rem;
      height: 100%;
      overflow: hidden;
      > div:nth-child(2) {
        flex-grow: 1;
      }
      .contents {
        padding: 0rem 0.75rem;
      }

      .cctvWrapper {
        border-radius: 0.25rem;
        padding: 0;
        > div {
          border-radius: 0;
        }
      }
      .controllerWrapper {
        border-radius: 0;
        background-color: transparent;
        .leftControl,
        .rightControl {
          display: none;
        }
        justify-content: center;
        .controllerBtn {
          padding: 0;
          width: 2rem;
          height: 2rem;
          color: ${({ theme }: { theme: any }) => theme.text_secondary};
        }
        .controllerBtn.activePage {
          border: none;
          font-weight: 500;
          color: ${({ theme }: { theme: any }) => theme.text_primary};
          background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
        }
      }
      .camListWrapper {
        display: flex;
        flex-direction: column;
        overflow: auto;
        flex-shrink: 0;
        margin-bottom: 1rem;
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        border-radius: 0.25rem;
        ::-webkit-scrollbar-thumb {
          background-color: transparent;
        }
        &:hover::-webkit-scrollbar-thumb {
          background-color: ${({ theme }: { theme: any }) => theme.scrollBar};
        }
        > button {
          height: 2.5rem;
          width: 10rem;
          padding: 0px 1rem;
          font-size: 0.875rem;
          justify-content: flex-start;
          flex-shrink: 0;
          font-weight: 500;
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          text-align: left;
          /* &:hover {
            background-color: transparent;
          } */
        }
        .active {
          background-color: transparent;
          border: none;
          color: ${({ theme }: { theme: any }) => theme.text_secondary};
        }
      }

      .controllerWrapper {
        padding-bottom: 0.5rem;
      }
    }
    .tag {
      font-size: 0.875rem !important;
    }

    &.blue {
      background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
    }
    &.green {
      background-color: ${({ theme }: { theme: any }) => theme.filled_green};
    }
    &.red {
      background-color: ${({ theme }: { theme: any }) => theme.filled_red};
    }
    &.amber {
      background-color: ${({ theme }: { theme: any }) => theme.filled_amber};
    }
    &.teal {
      background-color: ${({ theme }: { theme: any }) => theme.filled_teal};
    }
    &.violet {
      background-color: ${({ theme }: { theme: any }) => theme.filled_violet};
    }
    &.pink {
      background-color: ${({ theme }: { theme: any }) => theme.filled_pink};
    }
    &.indigo {
      background-color: ${({ theme }: { theme: any }) => theme.filled_indigo};
    }
    &.sky {
      background-color: ${({ theme }: { theme: any }) => theme.filled_sky};
    }
    &.lime {
      background-color: ${({ theme }: { theme: any }) => theme.filled_lime};
    }
    &.orange {
      background-color: ${({ theme }: { theme: any }) => theme.filled_orange};
    }
  }
  .cctv {
    .cctvWrapper,
    .controllerWrapper {
      max-height: 22rem;
      max-width: inherit;
    }
    .contents {
      > :first-child {
        padding-bottom: 1rem;
      }
    }
  }
  .pieChartWrapper {
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    padding: 0 2rem;
    gap: 1rem;
    margin-bottom: 2rem;
    > div {
      flex: 1 1 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      > div.text-center {
        font-weight: ${({ theme }: { theme: any }) => theme.font_medium};
        color: ${({ theme }: { theme: any }) => theme.text_primary};
        & > span {
          border-radius: 0.5rem;
          background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
          color: #3e97ff;
          margin: 0 0.25rem;
          padding: 0.25rem 0.5rem;
        }
      }
      > div.pie-group {
        display: flex;
        gap: 1rem;
        height: 100%;
        > div.pie {
          width: 8rem;
          canvas {
          }
        }
        > div.legends {
          flex: 1 0 25%;
          -webkit-box-align: center;
          align-items: flex-start;
          justify-content: center;
          height: 100%;
          font-size: 0.875rem;
          > div {
            color: ${({ theme }: { theme: any }) => theme.text_tertiary};
            display: flex;
            align-items: center;
            &.legend:nth-child(n + 7) {
              display: none;
            }
            span.text_primary {
              margin-left: 0.25rem;
              font-weight: 600;
            }
            div {
              border-radius: 10rem;
              width: 0.75rem;
              height: 0.75rem;
              margin-right: 0.25rem;
              &.blue {
                background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
              }
              &.green {
                background-color: ${({ theme }: { theme: any }) => theme.filled_green};
              }
              &.red {
                background-color: ${({ theme }: { theme: any }) => theme.filled_red};
              }
              &.amber {
                background-color: ${({ theme }: { theme: any }) => theme.filled_amber};
              }
              &.teal {
                background-color: ${({ theme }: { theme: any }) => theme.filled_teal};
              }
              &.violet {
                background-color: ${({ theme }: { theme: any }) => theme.filled_violet};
              }
              &.pink {
                background-color: ${({ theme }: { theme: any }) => theme.filled_pink};
              }
              &.indigo {
                background-color: ${({ theme }: { theme: any }) => theme.filled_indigo};
              }
              &.sky {
                background-color: ${({ theme }: { theme: any }) => theme.filled_sky};
              }
              &.lime {
                background-color: ${({ theme }: { theme: any }) => theme.filled_lime};
              }
              &.orange {
                background-color: ${({ theme }: { theme: any }) => theme.filled_orange};
              }
            }
            span.name {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              max-width: 7rem;
            }
            span.count {
              margin-left: 0.5rem;
              margin-right: 0.125rem;
              color: ${({ theme }: { theme: any }) => theme.text_primary};
              font-weight: ${({ theme }: { theme: any }) => theme.font_bold};
            }
          }
        }
      }
    }
  }
  .lineChartWrapper {
    height: 280px;
  }
  .barChartWrapper {
    height: 100%;
    width: 100%;
    padding: 1rem;
  }
  .tuiGridWrapper {
    width: 100%;
    &.scroll {
      .tui-grid-body-area {
        overflow-x: scroll;
      }
    }

    &.noScroll {
      /* .tui-grid-body-container {
        width: 100% !important;
        .tui-grid-table-container {
          width: 100% !important;
        }
      } */
      .tui-grid-body-area {
        overflow-x: hidden;
      }
    }
  }

  .noxiousGas,
  .excavationProcess,
  .mGas {
    .tui-grid-content-area {
      > .tui-grid-lside-area {
        /* display: none !important;
        width: 0 !important; */
      }

      > .tui-grid-rside-area {
        /* margin-left: 0 !important; */
        > .tui-grid-body-area > .tui-grid-body-container > .tui-grid-table-container > .tui-grid-table > tbody > tr > td > div > span {
          padding: 0.5rem;
          /* border-radius: 5px; */
          border-radius: 1rem;
        }
      }

      .tui-grid-cell-content > span {
        border-radius: 5px;
        padding: 0.25rem 0.75rem !important;
      }
    }

    .labelSet {
      /* margin: 0 1rem; */
      flex-grow: 1;
      > span {
        padding: 0 0.25rem !important;
        margin-left: 0.25rem;
      }
    }

    .textBox {
      /* border-radius: 5px; */
      border-radius: 1rem;
      padding: 0 0.2rem !important;
      margin-left: 0.125rem;
      font-size: 0.875rem;
      word-break: keep-all;
    }

    .blue {
      color: ${({ theme }: { theme: any }) => theme.filled_blue};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
    }

    .red {
      color: ${({ theme }: { theme: any }) => theme.filled_red};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
    }

    .violet {
      color: ${({ theme }: { theme: any }) => theme.filled_violet};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_violet};
    }

    .orange {
      color: ${({ theme }: { theme: any }) => theme.filled_orange};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
    }

    .green {
      color: ${({ theme }: { theme: any }) => theme.filled_green};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
    }

    .amber {
      color: ${({ theme }: { theme: any }) => theme.filled_amber};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_amber};
    }

    .slate {
      color: ${({ theme }: { theme: any }) => theme.filled_slate};
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
    }
  }

  .excavationProcess,
  .mGas {
    .title,
    .content {
      width: 30%;
    }

    .textBox {
      border-radius: 5px;
      padding: 0 0.5rem;
    }

    .table .table > .tbody > .tr > * {
      height: 1.2rem;
      margin: 0.5rem 0;
    }
    /* CSS for the progress container */
    .progress-container {
      // width: 100%;
      height: 1.2rem;
      border: 0;
      position: relative;

      .progressValue {
        position: relative;
        height: inherit;
        top: -26px;
        color: #fff;
      }
    }

    /* Styling the progress bar */
    progress {
      width: 100%;
      height: 100%;
      appearance: none;
      border-radius: 15px;
      background-color: rgba(80, 205, 137, 0.15);
    }

    /* Styling the uncompleted progress bar */
    progress::-webkit-progress-bar {
      background-color: rgba(80, 205, 137, 0.15); /* Uncompleted progress color */
      border-radius: 15px; /* Apply the same border radius as the progress element */
    }

    /* Styling the value (completed progress) */
    progress::-webkit-progress-bar {
      border-radius: 15px;
    }

    /* Styling the value (completed progress) */
    progress::-webkit-progress-value {
      background-color: rgba(80, 205, 137, 1);
      border-radius: 15px;
    }

    /* Styling the text inside the progress bar */
    .progress-text {
      text-align: center;
      font-size: 14px;
      line-height: 20px;
      color: white;
      background-color: #4caf50;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .mGas {
    .thead {
      border-bottom: none;
      > :last-child {
        > div {
          border-right: 1px solid ${({ theme }: { theme: any }) => theme.outline_em};
        }
      }
      > .tr {
        border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};

        > div {
          padding: 0.5rem;
          text-align: center;
        }
      }
    }

    > .table {
      .thead {
        border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline_em};
      }
      > div {
        padding: 1rem 0;
      }
    }

    .mGasColumn {
      &.stickyTd {
        position: sticky;
        left: 0;
        background-color: ${({ theme }: { theme: any }) => theme.board};
      }
      > div {
        border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    }
    .textLight {
      text-align: center;
      min-width: 3rem;
      font-size: 0.875rem;
      padding: 0 0.25rem;
      border-radius: 0.25rem;
    }
    .light {
      display: block;
      border-radius: 50%;
      text-align: center;
      font-size: 1.5rem;
      &.blue {
        color: ${({ theme }: { theme: any }) => theme.filled_blue};
        background-color: transparent;
      }
      &.green {
        color: ${({ theme }: { theme: any }) => theme.filled_green};
        background-color: transparent;
      }
      &.orange {
        color: ${({ theme }: { theme: any }) => theme.filled_orange};
        background-color: transparent;
      }
      &.red {
        color: ${({ theme }: { theme: any }) => theme.filled_red};
        background-color: transparent;
      }
    }
  }

  .emptyData {
    width: 100%;
    height: calc(100% - 4rem);
    img {
      width: inherit;
      height: inherit;
      object-fit: contain;
      padding: 3rem;
      -webkit-user-drag: none;
    }
    .main_text {
      padding: 1rem;
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      border-radius: 0.5rem;
      margin: 0 1rem;
      .font_semibold {
        margin-left: 0.25rem;
      }
      .seperator {
        margin: 0 0.5rem;
        border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline_em};
      }
    }
  }

  @media screen and (max-width: 767px) {
    .widget-title-innerTab {
      flex-direction: column;
      align-items: flex-start !important;
      .innerTab {
        overflow: scroll;
        ::-webkit-scrollbar {
          height: 0rem;
        }
        > div {
          min-width: fit-content;
        }
      }
    }

    .camListWrapper {
      display: none !important;
    }

    .pieChartWrapper {
      flex-direction: column;
      overflow: scroll;
      justify-content: flex-start;
    }

    .mobile_disabled {
      display: none !important;
    }
  }

  .mGas.tempEdit {
    .widget-header {
      padding-bottom: 0.5rem !important;
    }
  }
`;

const Control = ({ content }: IControl) => {
  const { dCd, name, isInDropZone } = content && content.props.cell;
  const userInfo = useRecoilValue(userState);
  const toDay = todayYYYYMMDD();
  const weekDays = getPreviousDays(toDay, 7);
  const theme = useRecoilValue(themeState);
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '', code: '', index: 0 });

  const handleStateChange = (v: any) => {
    setOpenModal(v);
  };

  useEffect(() => {
    if (cctv.getPluginOBJECT()?.oPlugin) {
      if (openModal.status) {
        cctv.hideWndAll();
      } else {
        cctv.showWndAll();
      }
    }
  }, [openModal]);

  return (
    <ControlStyle dCd={dCd}>
      {userInfo.sCd === '00000' ? (
        <>
          {/* 기상정보 */}
          {dCd === '001' && isInDropZone && <WeatherInfo name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} />}
          {/* 기상 정보 (주간) */}
          {dCd === '002' && isInDropZone && <WeatherWeekInfo name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} />}
        </>
      ) : (
        <>
          {/* 기상정보 */}
          {dCd === '001' && isInDropZone && <WeatherInfo name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} />}
          {/* 기상 정보 (주간) */}
          {dCd === '002' && isInDropZone && <WeatherWeekInfo name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} />}
          {/* 종합 현황 */}
          {dCd === '003' && isInDropZone && <TotalInfo name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} />}
          {/* 신규 근로자 */}
          {dCd === '004' && isInDropZone && <NewWorker name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} />}
          {/* 고령 근로자 */}
          {dCd === '005' && isInDropZone && <OldWorker name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} />}
          {/* 출역 정보 */}
          {dCd === '006' && isInDropZone && <AttendInfo name={name} weekDays={weekDays} openModal={openModal} setOpenModal={handleStateChange} userInfo={userInfo} theme={theme} />}
          {/* 출역 종합 정보 */}
          {dCd === '007' && isInDropZone && <AttendTotalInfo name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} />}
          {/* 출입 장비  연결 정보 */}
          {dCd === '008' && isInDropZone && <EquipInfo name={name} weekDays={weekDays} openModal={openModal} setOpenModal={handleStateChange} userInfo={userInfo} />}
          {/* 현장 CCTV (소형) */}
          {dCd === '009' && isInDropZone && <Cctv name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} />}
          {/* 현장 CCTV (대형) */}
          {dCd === '010' && isInDropZone && <CctvWide name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} />}
          {/* 미세먼지 측정기 */}
          {dCd === '013' && isInDropZone && <PmInfo name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} sensorCd='01' />}
          {/* 소음 측정기 */}
          {dCd === '014' && isInDropZone && <PmInfo name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} sensorCd='02' />}
          {/* 질소 측정기 */}
          {dCd === '015' && isInDropZone && <PmInfo name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} sensorCd='03' />}
          {/* 풍속 측정기 */}
          {dCd === '016' && isInDropZone && <PmInfo name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} sensorCd='04' />}
          {/* 산소 측정기 */}
          {dCd === '017' && isInDropZone && <PmInfo name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} sensorCd='05' />}
          {/* 가스 측정기 */}
          {dCd === '018' && isInDropZone && <PmInfo name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} sensorCd='06' />}
          {/* D-DAY */}
          {dCd === '019' && isInDropZone && <Dday name={name} weekDays={weekDays} openModal={openModal} setOpenModal={handleStateChange} userInfo={userInfo} />}
          {/* 신규 고령 근로자 */}
          {dCd === '020' && isInDropZone && <NewOldWorker name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} />}
          {/* 안전 위반 현황 */}
          {dCd === '025' && isInDropZone && <SafetyViolationInfo name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} />}
          {/* 무사고 현황 */}
          {dCd === '027' && isInDropZone && <AccidentInfo name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} />}
          {/* 안전 위반 현황 상세 */}
          {dCd === '029' && isInDropZone && <SafetyViolationInfoDetail name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} />}
          {/* 근로자 출역 현황 */}
          {dCd === '033' && isInDropZone && <WorkerAttend name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} showImg />}
          {/* 고령 근로자 출역 현황 */}
          {dCd === '034' && isInDropZone && <OldWorkerAttend name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} />}
          {/* 유해가스 현황 (도형, 대형) */}
          {dCd === '035' && isInDropZone && <MGasInfo name={name} userInfo={userInfo} autoRefresh={false} size='large' valueType='shape' />}
          {/* 굴진 진행 현황 */}
          {dCd === '037' && isInDropZone && <ExcavationProcess name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} />}
          {/* 굴진 진행 현황 */}
          {dCd === '042' && isInDropZone && <ExcavationProcessLarge name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} />}
          {/* 근로자 위치 현황 */}
          {dCd === '039' && isInDropZone && <ExcavationWorkerPosition name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} />}
          {/* 유해가스 현황 (도형, 소형) */}
          {dCd === '041' && isInDropZone && <MGasInfo name={name} userInfo={userInfo} autoRefresh={false} size='small' valueType='shape' />}
          {/* 유해가스 현황 (숫자, 대형) */}
          {dCd === '044' && isInDropZone && <MGasInfo name={name} userInfo={userInfo} autoRefresh={false} size='large' valueType='number' />}
          {/* 유해가스 현황 (숫자, 소형) */}
          {dCd === '046' && isInDropZone && <MGasInfo name={name} userInfo={userInfo} autoRefresh={false} size='small' valueType='number' />}
          {/* 터널 종합 진행 현황(대형) */}
          {dCd === '047' && isInDropZone && <TunnelTotalInfo name={name} userInfo={userInfo} size='large' uniqueKey={dCd} />}
          {/* 터널 종합 진행 현황 */}
          {dCd === '048' && isInDropZone && <TunnelTotalInfo name={name} userInfo={userInfo} uniqueKey={dCd} />}
          {/* 터널 구역별 종합 진행 현황(대형) */}
          {dCd === '049' && isInDropZone && <TunnelAreaInfo name={name} userInfo={userInfo} size='large' uniqueKey={dCd} />}
          {/* 터널 구역별 종합 진행 현황 */}
          {dCd === '050' && isInDropZone && <TunnelAreaInfo name={name} userInfo={userInfo} uniqueKey={dCd} />}
          {/* 터널 종합 진행 현황(일반) */}
          {dCd === '051' && isInDropZone && <TunnelTotalTable name={name} userInfo={userInfo} uniqueKey={dCd} />}
          {/* 기상정보/시간대별(2칸) 79 */}
          {dCd === '079' && isInDropZone && <WeatherTotal name={name} userInfo={userInfo} combination={{ time: true, week: false }} />}
          {/* 기상정보/주간(2칸) 80 */}
          {dCd === '080' && isInDropZone && <WeatherTotal name={name} userInfo={userInfo} combination={{ time: false, week: true }} />}
          {/* 기상종합(3칸) 81 */}
          {dCd === '081' && isInDropZone && <WeatherTotal name={name} userInfo={userInfo} combination={{ time: true, week: true }} />}
          {/* 근로자 출역 현황(출역사진 제외) */}
          {dCd === '089' && isInDropZone && <WorkerAttend name={name} userInfo={userInfo} openModal={openModal} setOpenModal={handleStateChange} showImg={false} />}
        </>
      )}
    </ControlStyle>
  );
};

const Cctv = ({ name, userInfo, openModal, setOpenModal }: any) => {
  interface ICameraObject {
    wsNum: string;
    nName: string;
    cName: string;
  }
  const mobile = isMobile();
  const [foldMenu] = useRecoilState<boolean>(asideFoldState);
  const { t } = useTranslation();
  // const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const [cctvDivisions, setCctvDivisions] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [cameraInfo, setCameraInfo] = useState<ICameraObject[]>([]);
  const [currentCameraInfo, setCurrentCameraInfo] = useState<ICameraObject[]>([]);
  const [nvrCdList, setNvrCdList] = useState([]);
  const [selectedCam, setSelectedCam] = useState<any>({ subCd: 0, cdName: '' });
  const [webSdkPlay, setWebSdkPlay] = useState(false);
  const [playBtn, setPlayBtn] = useState(true);

  const cctvElement = useRef<HTMLDivElement>(null);

  const onScroll = (e: any) => {
    if (userInfo.cctvProtocol === '1' && e.target.className?.includes('contents dashboard')) {
      const top = cctvElement?.current?.getBoundingClientRect();
      if (cctv.getPluginOBJECT()?.oPlugin) {
        cctv.destroy();
        setWebSdkPlay(false);
      }
      if (top && top.top > 0) {
        setPlayBtn(true);
      } else {
        setPlayBtn(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', (e) => onScroll(e), true);
    return () => {
      window.removeEventListener('scroll', (e) => onScroll(e), true);
    };
  }, []);

  useEffect(() => {
    if (cctv.getPluginOBJECT()?.oPlugin) {
      cctv.destroy();
      setWebSdkPlay(false);
    }
    setPlayBtn(true);
  }, [foldMenu]);

  const siteCamQuery: any = useQuery(['siteCameraGet', userInfo.hCd, userInfo.sCd], () => apiGet({ path: '/cam/site', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }), {
    retry: 3,
    enabled: !!userInfo.hCd && !!userInfo.sCd,
  });

  useEffect(() => {
    if (siteCamQuery.isSuccess && siteCamQuery.data && siteCamQuery.data.data.statusCode === 200) {
      const { cameraList } = siteCamQuery.data.data.data;
      if (cameraList.length > 0) {
        setCameraInfo(cameraList);
        const newArray = cameraList.map((el: any, index: number) => {
          return { ...el, name: `${el.nName} ${el.cName}`, cdName: `${el.nName} ${el.cName}`, subCd: index, type: 'subCd' };
        });
        setSelectedCam(newArray[0]);
        setNvrCdList(newArray);
      }
    }
  }, [siteCamQuery.isSuccess, siteCamQuery.isFetching]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * cctvDivisions;
    const endIndex = startIndex + cctvDivisions;
    const currentPosts = cameraInfo.slice(startIndex, endIndex);
    setCurrentCameraInfo(currentPosts);
  }, [currentPage, cameraInfo]);

  useEffect(() => {
    setCurrentPage(1);
  }, [cctvDivisions]);

  useEffect(() => {
    setCurrentPage(selectedCam.subCd + 1);
  }, [selectedCam]);

  const onClickRefresh = () => {
    if (cctv.getPluginOBJECT()?.oPlugin) {
      cctv.destroy();
    }
    siteCamQuery.refetch();
  };

  const onClickPlay = () => {
    setWebSdkPlay(true);
    setPlayBtn(false);
  };

  if (!siteCamQuery.isSuccess || siteCamQuery.data.status !== 200 || siteCamQuery.isRefetching) {
    {
      return (
        <div className='centered-content'>
          <PulseLoader color='rgb(0, 122, 255)' size='10px' />
        </div>
      );
    }
  }
  return (
    <>
      <div className='cctvNarrow'>
        <div className='widget-header'>
          <div className='widget-title flex-between'>
            <span>{t('현장 CCTV')}</span>
            {/* <span>{t(name)}</span> */}
            <div className='refresh-btn' onClick={onClickRefresh} role='presentation'>
              <span className='material-symbols-rounded'>refresh</span>
            </div>
          </div>
        </div>
        {userInfo.cctvProtocol === '0' ? (
          <div className='emptyData'>
            <img src={monitoring} alt='' />
            <p className='main_text text-center'>{t('설정관리-일반관리 메뉴에서 CCTV 연결방식을 설정하세요')}</p>
          </div>
        ) : userInfo.cctvProtocol === '1' && mobile ? (
          <div className='emptyData'>
            <img src={notSupport} alt='' />
            <p className='main_text text-center'>{t('모바일 환경에서 지원되지 않는 화면입니다')}</p>
          </div>
        ) : currentPage && currentCameraInfo?.length > 0 && cameraInfo?.length > 0 ? (
          <div className='widget-body cctv-narrow' ref={cctvElement}>
            {userInfo.cctvProtocol === '2' ? (
              <>
                <SelectBox options={nvrCdList} defaultOption={selectedCam.cdName} state={selectedCam} setState={setSelectedCam} stateKey='subCd' optionHeight='height-sm' />
                <MultiVideoPlayer
                  cctvDivisions={1}
                  setCctvDivisions={setCctvDivisions}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  currentCameraInfo={currentCameraInfo}
                  totalCameraInfo={cameraInfo}
                  dashboard
                />
              </>
            ) : webSdkPlay ? (
              <>
                <SelectBox options={nvrCdList} defaultOption={selectedCam.cdName} state={selectedCam} setState={setSelectedCam} stateKey='subCd' isWebSdk hideWebSdk optionHeight='height-sm' />
                <WebSdk divisionCount={1} currentPageNumber={currentPage} setCurrentPageNumber={setCurrentPage} height='17.5rem' dashboard />
              </>
            ) : (
              <>
                <SelectBox options={nvrCdList} defaultOption={selectedCam.cdName} state={selectedCam} setState={setSelectedCam} stateKey='subCd' isWebSdk optionHeight='height-sm' />
                {playBtn && (
                  <div className='play-btn cctv' onClick={onClickPlay} role='presentation'>
                    <span className='material-symbols-rounded'>play_arrow</span>
                    {/* <span className='material-symbols-rounded'>play_circle</span> */}
                  </div>
                )}
              </>
            )}
          </div>
        ) : null}
      </div>
      <Portal openModal={openModal?.status}>{openModal && <WebSdkModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
    </>
  );
};

const CctvWide = ({ name, userInfo, openModal, setOpenModal }: any) => {
  interface ICameraObject {
    wsNum: string;
    nName: string;
    cName: string;
  }
  const [foldMenu] = useRecoilState<boolean>(asideFoldState);
  const { t } = useTranslation();
  const [cctvDivisions, setCctvDivisions] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [cameraInfo, setCameraInfo] = useState<ICameraObject[]>([]);
  const [currentCameraInfo, setCurrentCameraInfo] = useState<ICameraObject[]>([]);
  // const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const [webSdkPlay, setWebSdkPlay] = useState(false);
  const [playBtn, setPlayBtn] = useState(true);
  const mobile = isMobile();

  const cameraRef = useRef<HTMLButtonElement>(null);
  const cctvElement = useRef<HTMLDivElement>(null);

  const onScroll = (e: any) => {
    if (userInfo.cctvProtocol === '1' && e.target.className?.includes('contents dashboard')) {
      const top = cctvElement?.current?.getBoundingClientRect();
      if (cctv.getPluginOBJECT()?.oPlugin) {
        cctv.destroy();
        setWebSdkPlay(false);
      }
      if (top && top.top > 0) {
        setPlayBtn(true);
      } else {
        setPlayBtn(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', (e) => onScroll(e), true);
    return () => {
      window.removeEventListener('scroll', (e) => onScroll(e), true);
    };
  }, []);

  useEffect(() => {
    if (cctv.getPluginOBJECT()?.oPlugin) {
      cctv.destroy();
      setWebSdkPlay(false);
    }
    setPlayBtn(true);
  }, [foldMenu]);

  const siteCamQuery: any = useQuery(['siteCameraGet', userInfo.hCd, userInfo.sCd], () => apiGet({ path: '/cam/site', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }), {
    retry: 3,
    enabled: !!userInfo.hCd && !!userInfo.sCd,
  });

  useEffect(() => {
    if (siteCamQuery.isSuccess && siteCamQuery.data && siteCamQuery.data.data.statusCode === 200) {
      const { cameraList } = siteCamQuery.data.data.data;
      if (cameraList.length > 0) {
        setCameraInfo(cameraList);
      }
    }
  }, [siteCamQuery.isSuccess, siteCamQuery.isFetching]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * cctvDivisions;
    const endIndex = startIndex + cctvDivisions;
    const currentPosts = cameraInfo.slice(startIndex, endIndex);
    setCurrentCameraInfo(currentPosts);
  }, [currentPage, cameraInfo]);

  useEffect(() => {
    cameraRef.current?.focus();
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [cctvDivisions]);

  const onClickRefresh = () => {
    if (cctv.getPluginOBJECT()?.oPlugin) {
      cctv.destroy();
    }
    siteCamQuery.refetch();
  };

  const onClickPlay = () => {
    setWebSdkPlay(true);
    setPlayBtn(false);
  };

  const onClickNvr = (index: number) => {
    startTransition(() => {
      setCurrentPage(index + 1);
    });
  };

  if (!siteCamQuery.isSuccess || siteCamQuery.data.status !== 200 || siteCamQuery.isRefetching) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <>
      <div className='cctvWide'>
        <div className='widget-header'>
          <div className='widget-title flex-between'>
            <span>{t('현장 CCTV')}</span>
            <div className='refresh-btn' onClick={onClickRefresh} role='presentation'>
              <span className='material-symbols-rounded'>refresh</span>
            </div>
          </div>
        </div>
        {userInfo.cctvProtocol === '0' ? (
          <div className='emptyData'>
            <img src={monitoring} alt='' />
            <p className='main_text text-center'>설정관리-일반관리 메뉴에서 CCTV 연결방식을 설정하세요.</p>
          </div>
        ) : userInfo.cctvProtocol === '1' && mobile ? (
          <div className='emptyData'>
            <img src={notSupport} alt='' />
            <p className='main_text text-center'>모바일 환경에서 지원되지 않는 화면입니다.</p>
          </div>
        ) : (
          <div className='widget-body cctv-wide' ref={cctvElement}>
            <div className='camListWrapper'>
              {cameraInfo.map(({ wsNum, nName, cName }, index) => (
                <BtnDeep className={index + 1 === currentPage ? 'nvrBtn' : 'nvrBtn active'} ref={index + 1 === currentPage ? cameraRef : null} key={wsNum} onClick={() => onClickNvr(index)}>
                  {`${nName} ${cName}`}
                </BtnDeep>
              ))}
            </div>
            {currentPage && currentCameraInfo?.length > 0 && cameraInfo?.length > 0 ? (
              userInfo.cctvProtocol === '2' ? (
                <MultiVideoPlayer
                  cctvDivisions={1}
                  setCctvDivisions={setCctvDivisions}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  currentCameraInfo={currentCameraInfo}
                  totalCameraInfo={cameraInfo}
                  dashboard
                />
              ) : webSdkPlay ? (
                <WebSdk divisionCount={1} currentPageNumber={currentPage} setCurrentPageNumber={setCurrentPage} dashboard height='18.5rem' />
              ) : (
                playBtn && (
                  <div className='play-btn' onClick={onClickPlay} role='presentation'>
                    <span className='material-symbols-rounded'>play_circle</span>
                  </div>
                )
              )
            ) : null}
          </div>
        )}
      </div>
      <Portal openModal={openModal?.status}>{openModal && <WebSdkModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
    </>
  );
};
