/**
 * 작성자 : 홍선영
 * 날짜 : 2023.09.04
 * 경로 :  설정관리-협력업체관리-협력업체정보탭(현장관리자) 인풋폼
 */

import { useEffect, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Input from '../Input';
import { COMCD_USE_YN } from '../../_constants';
import { BtnBlue, BtnGreen, BtnRed } from '../Button';
import { useSetAuth } from '../../utils/useSetAuth';
import { applyBorderStyle } from '../../utils/applyBorderStyle';
import DatePicker from '../DatePicker';
import TextEditor from '../TextEditor';
import SelectBox from '../SelectBox';

interface IUserInputForm {
  state: any;
  setState: Dispatch<SetStateAction<any>>;
  isNewAdd?: boolean;
  userInfoInputFormRef: React.RefObject<any>;
  onClickNewAdd: () => void;
  onClickSave: () => void;
  onClickDelete: () => void;
  onClickPreview: () => void;
  inputFormUseYn: any;
  setInputFormUseYn: Dispatch<SetStateAction<any>>;
  useYnComCdList: any;
  isSaveClicked: boolean;
  date: string;
  setDate: Dispatch<SetStateAction<string>>;
  editorData: string;
  setEditorData: Dispatch<SetStateAction<string>>;
}

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 0.5rem;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;
const Root = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  overflow: hidden;
  /* overflow: auto; */
  justify-content: flex-start;
  .editorWrapper .ck-content {
    min-height: 10rem;
    /* height: 100%; */
    /* height: 25rem; */
  }
  .seperator {
    margin: 0.5rem 0;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    div {
      height: 1px;
      flex-grow: 1;
      background-color: rgba(0, 0, 0, 0.1);
    }
    span {
      border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    }
  }
  .inputForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    flex-grow: 1;
    overflow: auto;
    button {
      height: 2.5rem;
      font-size: 0.75rem;
      flex-shrink: 0;
      padding: 0 0.75rem;
      border-radius: 0.25rem;
    }
    button.gray {
      font-weight: 500;
      color: ${({ theme }: { theme: any }) => theme.color.zinc_200};
    }
    label {
      width: 6.5rem;
      font-weight: 500;
      flex-shrink: 0;
      font-size: 0.875rem;
    }
    .inputForm-group-1280 {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (min-width: 1280px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
      }
    }
    .inputForm-group-1536 {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (min-width: 1536px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
      }
    }
    .inputForm-group-1536.withBtn {
      flex-direction: row;
      gap: 0.5rem;
      button {
        transition: none;
      }
      @media (min-width: 1536px) {
        gap: 2rem;
        button {
          margin-left: 0;
          width: fit-content;
        }
      }
    }
    .inputForm-group-1536.withBtn.inputColumnFour {
      input {
        padding: 0;
      }
    }
    .inputForm-row.labelInInput {
      display: flex;
      gap: 0.5rem;
      flex-grow: 1;
      > div {
        flex-grow: 1;
      }
    }
    .inputForm-row.labelInInput.noGrow {
      flex-grow: 0;
    }
    .inputForm-row.labelInInput.noGrow.title > div > div {
      flex-direction: column;
      align-items: flex-start;
    }
    .inputForm-row.labelOutInput,
    .inputForm-row.labelInInput > div > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      flex-grow: 1;
      input {
        flex-shrink: 1;
        height: 2.5rem;
        font-size: 0.875rem;
        padding: 0 0.5rem;
        &:disabled {
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          color: ${({ theme }: { theme: any }) => theme.text_disabled};
        }
      }
      > div.viewOnly {
        height: 2.5rem;
        font-size: 0.875rem;
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        color: ${({ theme }: { theme: any }) => theme.text_disabled};
        border-radius: 0.25rem;
        padding: 0 0.75rem;
        display: flex;
        align-items: center;
      }
      > div {
        flex-grow: 1;
        > ul {
          height: 2.5rem;
          li {
            display: flex;
            width: 100%;
            max-width: 100%;
            span {
              flex-grow: 1;
              width: 4rem;
            }
          }
        }
      }
    }
    .detailInfo-group {
      margin: 1rem 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      @media (min-width: 1536px) {
        grid-template-columns: repeat(3, 1fr);
      }
      .flex-col.detailInfo {
        flex-grow: 1;
        width: 100%;
        div {
          height: 2.5rem;
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          border-radius: 0.25rem;
          display: flex;
          align-items: center;
          padding: 0 0.5rem;
          font-size: 0.875rem;
        }
      }
    }
  }
  input {
    :disabled {
      background-color: #eeeeee;
    }
  }
  .required {
    &::after {
      content: '*';
      padding: 0 0.2rem;
      color: red;
    }
  }
  @media screen and (max-width: 1023px) {
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    /* padding-top: 5rem; */
    height: fit-content;
    overflow: none;
  }
`;

const SiteTransferEditorInputForm = ({
  state,
  setState,
  isNewAdd,
  userInfoInputFormRef,
  onClickNewAdd,
  onClickSave,
  onClickDelete,
  onClickPreview,
  inputFormUseYn,
  setInputFormUseYn,
  useYnComCdList,
  isSaveClicked,
  date,
  setDate,
  editorData,
  setEditorData,
}: IUserInputForm) => {
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅

  useEffect(() => {
    setEditorData(state.cContent);
  }, [state.cContent]);

  useEffect(() => {
    if (inputFormUseYn[COMCD_USE_YN] !== '') {
      setState((prev: any) => ({ ...prev, useYn: inputFormUseYn[COMCD_USE_YN] }));
    }
  }, [inputFormUseYn[COMCD_USE_YN]]);

  return (
    <Root>
      <div className='inputForm' ref={userInfoInputFormRef}>
        <div className='inputForm-row labelInInput noGrow title'>
          <Input
            className='required'
            label={t('제목')}
            type='text'
            id='cTitle'
            name='cTitle'
            state={state}
            setState={setState}
            disabled={!auth.updateAuth}
            getBorderStyle={isSaveClicked ? applyBorderStyle(state.cTitle, 'red', 'cTitle') : undefined}
          />
        </div>
        <div className='editorWrapper'>
          <TextEditor content={editorData} setContent={setEditorData} readOnly={false} />
        </div>
        <div className='inputForm-group-1536'>
          <div className='inputForm-row labelOutInput datePickerWrapper'>
            <label className='required' htmlFor='cDate'>
              {t('적용일자')}
            </label>
            <DatePicker startDate={date} setDate={setDate} popperPlacement='bottom' isSaveClicked={isSaveClicked} />
          </div>
          <div className='inputForm-row labelOutInput'>
            <label htmlFor='useYn'>{t('사용유무')}</label>
            <SelectBox
              options={useYnComCdList}
              defaultOption={isNewAdd ? t('사용') : t(inputFormUseYn.cdName)}
              state={inputFormUseYn}
              setState={setInputFormUseYn}
              stateKey={COMCD_USE_YN}
              initiateKey={inputFormUseYn[COMCD_USE_YN]}
              grantChk={!auth.updateAuth}
            />
          </div>
        </div>
        <div className='inputForm-row labelInInput noGrow'>
          <Input label={t('비고')} type='text' id='bigo' name='bigo' state={state} setState={setState} disabled={!auth.updateAuth} maxLength={200} />
        </div>
        {!isNewAdd ? (
          <div className='detailInfo-group'>
            <div className='flex-col detailInfo'>
              <label htmlFor='createDate'>{t('등록일자')}</label>
              <div>{state?.wDate}</div>
            </div>
            <div className='flex-col detailInfo'>
              <label htmlFor='createUser'>{t('등록자')}</label>
              <div>{state.writer}</div>
            </div>

            <div className='flex-col detailInfo'>
              <label htmlFor='updateDate'>{t('수정일자')}</label>
              <div>{state.eDate}</div>
            </div>
            <div className='flex-col detailInfo'>
              <label htmlFor='updateUser'>{t('수정자')}</label>
              <div>{state.editor}</div>
            </div>
          </div>
        ) : undefined}
      </div>
      <ButtonsWrapper>
        {!isNewAdd && auth.deleteAuth && <BtnRed onClick={onClickDelete}>{t('삭제')}</BtnRed>}
        {!isNewAdd && <BtnGreen onClick={onClickPreview}>{t('미리보기')}</BtnGreen>}
        {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={onClickSave}>{t('저장')}</BtnBlue>}
      </ButtonsWrapper>
    </Root>
  );
};

export default SiteTransferEditorInputForm;
