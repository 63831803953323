import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  label {
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    min-width: 6rem;
  }
  .value {
    height: 2.5rem;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    border-radius: 0.2rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    border-radius: 0.25rem;
    font-size: 0.875rem;
    width: 100%;
    justify-content: center;
  }
`;

interface Props {
  name: string;
  value: string;
}

const DetailsLog = ({ name, value }: Props) => {
  return (
    <Root>
      <label htmlFor={name}>{name}</label>
      <div className='value'>{value}</div>
    </Root>
  );
};

export default DetailsLog;
