/**
 * 작성자 : 김광민
 * 날짜 : 2024.03.29
 * 기능 : 1. PTZ 컨트롤러 컴포넌트는 PTZ 카메라의 이동 및 제어를 담당하는 컴포넌트입니다.
 *        2. 카메라를 위, 아래, 좌, 우 등으로 이동하거나 특정 동작을 수행할 수 있도록 버튼을 제공합니다.
 *        3. 확대, 축소, 조리개 조절, 초점 이동 등의 기능을 제공하는 캡슐 버튼도 포함되어 있습니다.
 */

import styled from 'styled-components';
import PTZCameraMovementButton from '../../../../components/button/PTZCameraMovementButton';
import PTZInteractionControlCapsule from './PTZInteractionControlCapsule';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import SmallIconButton from '../../../../components/button/SmallIconButton';
import { WebSDKcctv } from '../../../../utils/webSDKcctvClass';
import { PTZDIRECTION } from '../../../../_constants';
import OpenFolderButtons from './OpenFolderButtons';
import Portal from '../../../../components/Portal';
import CctvSettings from '../../../../components/Modal/CctvSettings';
import { useRecoilValue } from 'recoil';
import { mouseDownIndexState, userState } from '../../../../atoms';
import { initPath } from '../../../../services/webSDK';
import { IsInfraType } from './IsInfraType';
import SwitchManyButtons from '../../../../components/button/SwitchButtons';
import ExpandButton from '../../../../components/button/ExpandButton';
import { logPost } from '../../../../services/log';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  padding: 0.5rem 0;
  &.disabled {
    .ptzController {
      .cameraMovementButtons,
      .cameraInteractionControls,
      .cameraSettings {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }
  .ptzHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    padding: 0 0.5rem;
    .ptzHeaderLeft {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    .label {
      font-size: 0.875rem;
      font-weight: 700;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      padding: 0 0.25rem;
      .seperator {
        height: 1rem;
        border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
        margin: 0 0.5rem;
      }
      .badge {
        font-size: 0.75rem;
        font-weight: 600;
        letter-spacing: normal;
        color: ${({ theme }: { theme: any }) => theme.filled_green_deep};
        border-radius: 0.25rem;
        height: 1.5rem;
      }
    }
  }
  .ptzController {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0.5rem;
    &.folded {
      display: none;
    }
  }
  .cameraMovementButtons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.25rem;
    padding: 0 0.5rem;
  }
  .cameraInteractionControls {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
  }
  .cameraSettings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 0.5rem;
  }
  .openFolder {
    padding: 0.25rem 0.5rem;
  }
  .rangeSlideContainer {
    margin-bottom: 0.5rem;
    padding: 0 0.75rem;
    display: flex;
    flex-direction: column;
    .name {
      letter-spacing: initial;
      font-size: 0.75rem;
      font-weight: 600;
      user-select: none;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
    }
    input {
      padding: 0;
      height: fit-content;
      &:active {
        outline: none !important;
      }
    }
  }
`;

type Props = {
  cctvOBJ: WebSDKcctv;
  cctvSettings: CctvSettings;
  selectedCctvCameraInfo: SelectedCctvCameraInfo | null;
  nvrList: IMainMenu[];
  zoomType: number;
  setZoomType: Dispatch<SetStateAction<number>>;
};

type PTZControllerButtonType = {
  areaLabel: string; //
  name: string;
  icon: string;
  mouseUp: () => void;
  mouseDown: () => void;
  iconSize: 'small' | 'medium' | 'large';
};

type PTZCapsuleButtonType = {
  title: string;
  name: string;
  left: { mouseDown: () => void; icon: string };
  right: { mouseDown: () => void; icon: string };
  mouseUp: () => void;
};

const PTZController = React.memo(
  ({
    cctvOBJ,
    cctvSettings,
    // updateCctvSettings,
    selectedCctvCameraInfo,
    nvrList,
    zoomType,
    setZoomType,
  }: Props) => {
    const userInfo = useRecoilValue(userState);
    // PTZ 카메라 이동 속도
    const [sliderValue, setSliderValue] = useState(Number(cctvSettings?.camSpeed));
    // 줌 타입(1=디지털, 2=3D, 3=PTG)
    const zoomTypeButtons = [
      { text: '디지털', value: 1 }, //
      { text: '3D', value: 2 },
      { text: 'PTZ', value: 3 },
    ];
    // CCTV 화면설정 모달 상태
    const [openModal, setOpenModal] = useState({ status: false, type: 'cctvSettings' });
    // PTZ Controller disable 상태
    const mouseDownIndex = useRecoilValue(mouseDownIndexState);
    const downloadFolders = [
      { title: 'capture', path: initPath(cctvSettings.camCPath), name: '캡쳐' },
      { title: 'video', path: initPath(cctvSettings.camMPath), name: '동영상' },
    ];
    const isInfraType = IsInfraType(); // 인프라용의 경우 다운로드 폴더 미노출
    const [fold, setFold] = useState(true);

    useEffect(() => {
      setSliderValue(Number(cctvSettings?.camSpeed));
    }, [cctvSettings?.camSpeed]);

    // 3d 줌 활성화/비활성화 적용
    useEffect(() => {
      if (selectedCctvCameraInfo?.isPlaying) {
        // 3D줌 활성화
        if (zoomType === 2) {
          cctvOBJ.pointZoomEnable(selectedCctvCameraInfo.windowIndex as number);
          cctvOBJ.zoomDisable(selectedCctvCameraInfo.windowIndex as number);
          cctvOBJ.mousePTZ(selectedCctvCameraInfo.windowIndex as number, false);
        }
        // 디지털줌 활성화
        if (zoomType === 1) {
          cctvOBJ.pointZoomDisable(selectedCctvCameraInfo.windowIndex as number);
          cctvOBJ.zoomEnable(selectedCctvCameraInfo.windowIndex as number);
          cctvOBJ.mousePTZ(selectedCctvCameraInfo.windowIndex as number, false);
        }
        // PTZ 활성화
        if (zoomType === 3) {
          cctvOBJ.pointZoomDisable(selectedCctvCameraInfo.windowIndex as number);
          cctvOBJ.zoomDisable(selectedCctvCameraInfo.windowIndex as number);
          cctvOBJ.mousePTZ(selectedCctvCameraInfo.windowIndex as number, true);
        }
      }
    }, [zoomType, selectedCctvCameraInfo]);

    // PTZ speed 변경 적용
    useEffect(() => {
      cctvOBJ.ptzSpeed = sliderValue;
    }, [sliderValue]);

    useEffect(() => {
      if (cctvOBJ.pluginOBJ !== undefined) {
        openModal.status ? cctvOBJ.hideWnd() : cctvOBJ.showWnd();
      }
    }, [openModal.status, cctvOBJ]);

    // handleSliderValue 함수는 슬라이더 값이 변경될 때 호출되어
    // 해당 값을 정수로 변환하여 state에 설정하는 로직을 수행합니다.
    const handleSliderValue = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSliderValue(parseInt(e.target.value, 10));
    };

    // PTZ 정지 함수
    const ptzStop = () => cctvOBJ.ptzStop();

    // PTZ 컨트롤러 버튼
    const PTZConrollerButtons: PTZControllerButtonType[] = [
      {
        name: '왼쪽 위로 이동', //
        areaLabel: 'top left',
        icon: 'north_west',
        mouseUp: ptzStop,
        mouseDown: () => {
          cctvOBJ.ptzOn(PTZDIRECTION.UPLEFT);
          logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: 'CCTV 실시간 보기-인프라용',
            action: `카메라 PTZ`,
            etc: `${selectedCctvCameraInfo?.cName} 왼쪽 상단`,
          });
        },
        iconSize: 'small',
      },
      {
        name: '위로 이동', //
        areaLabel: 'top',
        icon: 'arrow_drop_up',
        mouseUp: ptzStop,
        mouseDown: () => {
          cctvOBJ.ptzOn(PTZDIRECTION.UP);
          logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: 'CCTV 실시간 보기-인프라용',
            action: `카메라 PTZ`,
            etc: `${selectedCctvCameraInfo?.cName} 상단`,
          });
        },
        iconSize: 'medium',
      },
      {
        name: '오른쪽 위로 이동', //
        areaLabel: 'top right',
        icon: 'north_east',
        mouseUp: ptzStop,
        mouseDown: () => {
          cctvOBJ.ptzOn(PTZDIRECTION.UPRIGHT);
          logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: 'CCTV 실시간 보기-인프라용',
            action: `카메라 PTZ`,
            etc: `${selectedCctvCameraInfo?.cName} 오른쪽 상단`,
          });
        },
        iconSize: 'small',
      },
      {
        name: '왼쪽으로 이동', //
        areaLabel: 'left',
        icon: 'arrow_left',
        mouseUp: ptzStop,
        mouseDown: () => {
          cctvOBJ.ptzOn(PTZDIRECTION.LEFT);
          logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: 'CCTV 실시간 보기-인프라용',
            action: `카메라 PTZ`,
            etc: `${selectedCctvCameraInfo?.cName} 왼쪽`,
          });
        },
        iconSize: 'medium',
      },
      {
        name: '360도 회전', //
        areaLabel: 'turn arround',
        icon: '360',
        mouseUp: ptzStop,
        mouseDown: () => {
          cctvOBJ.ptzOn(PTZDIRECTION.AUTO);
          logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: 'CCTV 실시간 보기-인프라용',
            action: `카메라 PTZ`,
            etc: `${selectedCctvCameraInfo?.cName} 360도 회전`,
          });
        },
        iconSize: 'large',
      },
      {
        name: '오른쪽으로 이동', //
        areaLabel: 'right',
        icon: 'arrow_right',
        mouseUp: ptzStop,
        mouseDown: () => {
          cctvOBJ.ptzOn(PTZDIRECTION.RIGHT);
          logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: 'CCTV 실시간 보기-인프라용',
            action: `카메라 PTZ`,
            etc: `${selectedCctvCameraInfo?.cName} 오른쪽`,
          });
        },
        iconSize: 'medium',
      },
      {
        name: '왼쪽 아래로 이동', //
        areaLabel: 'bottom left',
        icon: 'south_west',
        mouseUp: ptzStop,
        mouseDown: () => {
          cctvOBJ.ptzOn(PTZDIRECTION.DOWNLEFT);
          logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: 'CCTV 실시간 보기-인프라용',
            action: `카메라 PTZ`,
            etc: `${selectedCctvCameraInfo?.cName} 왼쪽 하단`,
          });
        },
        iconSize: 'small',
      },
      {
        name: '아래로 이동', //
        areaLabel: 'down',
        icon: 'arrow_drop_down',
        mouseUp: ptzStop,
        mouseDown: () => {
          cctvOBJ.ptzOn(PTZDIRECTION.DOWN);
          logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: 'CCTV 실시간 보기-인프라용',
            action: `카메라 PTZ`,
            etc: `${selectedCctvCameraInfo?.cName} 하단`,
          });
        },
        iconSize: 'medium',
      },
      {
        name: '오른쪽 아래로 이동', //
        areaLabel: 'bottom right',
        icon: 'south_east',
        mouseUp: ptzStop,
        mouseDown: () => {
          cctvOBJ.ptzOn(PTZDIRECTION.DOWNRIGHT);
          logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: 'CCTV 실시간 보기-인프라용',
            action: `카메라 PTZ`,
            etc: `${selectedCctvCameraInfo?.cName} 오른쪽 하단`,
          });
        },
        iconSize: 'small',
      },
    ];

    // PTZ 캡슐 버튼
    const PTZcapsuleButtons: PTZCapsuleButtonType[] = [
      {
        title: 'zoom', //
        name: '확대 및 축소',
        left: {
          mouseDown: () => {
            cctvOBJ.digitalZoomOut();
            logPost({
              hCd: userInfo.hCd,
              sCd: userInfo.sCd,
              userId: userInfo.userId,
              menu: 'CCTV 실시간 보기-인프라용',
              action: `카메라 Zoom`,
              etc: `${selectedCctvCameraInfo?.cName} Zoom OUT`,
            });
          },
          icon: 'zoom_out',
        },
        right: {
          mouseDown: () => {
            cctvOBJ.digitalZoomIn();
            logPost({
              hCd: userInfo.hCd,
              sCd: userInfo.sCd,
              userId: userInfo.userId,
              menu: 'CCTV 실시간 보기-인프라용',
              action: `카메라 Zoom`,
              etc: `${selectedCctvCameraInfo?.cName} Zoom IN`,
            });
          },
          icon: 'zoom_in',
        },
        mouseUp: () => cctvOBJ.digitalZoomStop(),
      },
      {
        title: 'aperture', //
        name: '조리개 조절',
        left: {
          mouseDown: () => {
            cctvOBJ.irisOut();
            logPost({
              hCd: userInfo.hCd,
              sCd: userInfo.sCd,
              userId: userInfo.userId,
              menu: 'CCTV 실시간 보기-인프라용',
              action: `카메라 조리개 조절 -`,
              etc: `${selectedCctvCameraInfo?.cName}`,
            });
          },
          icon: 'remove',
        },
        right: {
          mouseDown: () => {
            cctvOBJ.irisIn();
            logPost({
              hCd: userInfo.hCd,
              sCd: userInfo.sCd,
              userId: userInfo.userId,
              menu: 'CCTV 실시간 보기-인프라용',
              action: `카메라 조리개 조절 +`,
              etc: `${selectedCctvCameraInfo?.cName}`,
            });
          },
          icon: 'add',
        },
        mouseUp: () => cctvOBJ.irisStop(),
      },
      {
        title: 'focus', //
        name: '초점 이동',
        left: {
          mouseDown: () => {
            cctvOBJ.focusOut();
            logPost({
              hCd: userInfo.hCd,
              sCd: userInfo.sCd,
              userId: userInfo.userId,
              menu: 'CCTV 실시간 보기-인프라용',
              action: `카메라 초점 이동 -`,
              etc: `${selectedCctvCameraInfo?.cName}`,
            });
          },
          icon: 'flip_to_back',
        },
        right: {
          mouseDown: () => {
            cctvOBJ.focusIn();
            logPost({
              hCd: userInfo.hCd,
              sCd: userInfo.sCd,
              userId: userInfo.userId,
              menu: 'CCTV 실시간 보기-인프라용',
              action: `카메라 초점 이동 +`,
              etc: `${selectedCctvCameraInfo?.cName}`,
            });
          },
          icon: 'flip_to_front',
        },
        mouseUp: () => cctvOBJ.focusStop(),
      },
    ];

    // 다운로드 폴더 열기
    const handleOpenFolder = (path: null | string) => {
      if (path && path !== 'undefined') {
        return cctvOBJ.openDirectory(path);
      }
      return cctvOBJ.openDirectory();
    };

    const isDisable = () => {
      return !selectedCctvCameraInfo?.isPlaying;
    };

    return (
      <Root className={isDisable() ? 'disabled' : ''}>
        <div className='ptzHeader'>
          <div className='ptzHeaderLeft'>
            <ExpandButton isExpanded={fold} onClickExpandButton={() => setFold(!fold)} />
            <span className='label'>
              PTZ
              <span className='seperator' />
              <span className='badge'>Pan-Tilt-Zoom</span>
            </span>
          </div>
          <SmallIconButton icon='settings' onClick={() => setOpenModal({ ...openModal, status: true })} />
        </div>
        <div className={`ptzController ${fold ? 'folded' : ''}`}>
          <div className='cameraMovementButtons'>
            {PTZConrollerButtons.map((button) => (
              // PTZ 컨트롤러 버튼
              <PTZCameraMovementButton //
                key={button.name}
                data={button}
              />
            ))}
          </div>
          <div className='cameraInteractionControls'>
            {PTZcapsuleButtons.map((button) => (
              // 확대 및 축소, 조리개 조절, 초점 이동
              <PTZInteractionControlCapsule key={button.name} data={button} />
            ))}
          </div>
          <div className='rangeSlideContainer'>
            <span className='name'>카메라 PTZ 속도</span>
            <input
              type='range' // 슬라이더 타입
              min='1' // 최소값
              max='7' // 최대값
              step='1' // 슬라이더 값 변경 단위
              value={sliderValue} // 슬라이더 값
              onChange={handleSliderValue} // 슬라이더 값 변경 이벤트
            />
          </div>
          <div className='cameraSettings'>
            <SwitchManyButtons //
              label='드래그 줌'
              icon='left_click'
              state={zoomType}
              setState={setZoomType}
              buttons={zoomTypeButtons}
            />
          </div>
          {!isInfraType && (
            <div className='openFolder'>
              <OpenFolderButtons //
                label='다운로드 폴더 열기'
                icon='folder_open'
                options={downloadFolders}
                onClick={handleOpenFolder}
              />
            </div>
          )}
        </div>
        <Portal openModal={openModal?.status}>
          <CctvSettings
            setOpenModal={setOpenModal} //
            cctvOBJ={cctvOBJ}
            cctvSettings={cctvSettings}
            setSliderValue={setSliderValue}
          />
        </Portal>
      </Root>
    );
  }
);

PTZController.displayName = 'PTZController';

export default PTZController;
