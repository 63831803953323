/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.08
 * 경로 : 터널관리 - 구역 현황 관리 > 종합진행현황
 */

import styled from 'styled-components';

type Props = {
  length: number;
  rLength: number;
  reverse?: boolean;
  nameOnly?: boolean;
};

const TotalLengthContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  &.reverse {
    flex-direction: row-reverse;
  }
  .capsule {
    padding: 0 0.5rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    /* 공통 */
    &.level1 {
      .title,
      .meter {
        color: ${({ theme }: { theme: any }) => theme.filled_slate_light};
      }
    }
    &.level2 {
      .title,
      .meter {
        color: ${({ theme }: { theme: any }) => theme.filled_amber};
      }
    }
    &.level3 {
      .title,
      .meter {
        color: ${({ theme }: { theme: any }) => theme.filled_violet};
      }
    }
    &.level4 {
      .title,
      .meter {
        color: ${({ theme }: { theme: any }) => theme.filled_green_deep};
      }
    }
    &.level5 {
      .title,
      .meter {
        color: ${({ theme }: { theme: any }) => theme.filled_blue};
      }
    }
    /* 전체 */
    &.total {
      &.level1 {
        .dot {
          border: 2px solid ${({ theme }: { theme: any }) => theme.filled_slate};
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
        }
      }
      &.level2 {
        .dot {
          border: 2px solid ${({ theme }: { theme: any }) => theme.filled_amber};
          background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
        }
      }
      &.level3 {
        .dot {
          border: 2px solid ${({ theme }: { theme: any }) => theme.filled_violet};
          background-color: ${({ theme }: { theme: any }) => theme.tonal_violet};
        }
      }
      &.level4 {
        .dot {
          border: 2px solid ${({ theme }: { theme: any }) => theme.filled_green};
          background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
        }
      }
      &.level5 {
        .dot {
          border: 2px solid ${({ theme }: { theme: any }) => theme.filled_blue};
          background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
        }
      }
    }
    /* 현재 */
    &.present {
      &.level1 {
        /* background-color: ${({ theme }: { theme: any }) => theme.tonal}; */
        .dot {
          background-color: ${({ theme }: { theme: any }) => theme.filled_slate};
        }
      }
      &.level2 {
        /* background-color: ${({ theme }: { theme: any }) => theme.tonal_orange}; */
        .dot {
          background-color: ${({ theme }: { theme: any }) => theme.filled_amber};
        }
      }
      &.level3 {
        /* background-color: ${({ theme }: { theme: any }) => theme.tonal_violet}; */
        .dot {
          background-color: ${({ theme }: { theme: any }) => theme.filled_violet};
        }
      }
      &.level4 {
        /* background-color: ${({ theme }: { theme: any }) => theme.tonal_green}; */
        .dot {
          background-color: ${({ theme }: { theme: any }) => theme.filled_green};
        }
      }
      &.level5 {
        /* background-color: ${({ theme }: { theme: any }) => theme.tonal_blue}; */
        .dot {
          background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
        }
      }
    }
    .dot {
      display: flex;
      width: 0.625rem;
      height: 0.625rem;
      border-radius: 1rem;
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
      /* margin-right: 0.25rem; */
    }
    .title {
      font-size: 0.75rem;
      word-break: keep-all;
      &.invisible {
        display: none;
      }
    }
    .meter {
      font-size: 0.75rem;
      font-weight: 400;
      .emphasize {
        font-weight: 500;
        margin-right: 0.125rem;
      }
    }
  }
`;

const TotalPointLength = ({ length, rLength, reverse = false, nameOnly = false }: Props) => {
  const getColor = () => {
    const percentage = Math.floor((rLength / length) * 100);
    if (rLength === 0 || (length === 0 && rLength > 0)) {
      return 'level1';
    }
    if (percentage > 20 && percentage <= 40) {
      return 'level2';
    }
    if (percentage > 40 && percentage <= 60) {
      return 'level3';
    }
    if (percentage > 60 && percentage <= 80) {
      return 'level4';
    }
    if (percentage > 80 && percentage <= 100) {
      return 'level5';
    }
    return 'level1';
  };

  return (
    <TotalLengthContainer className={`${reverse ? '' : 'reverse'}`}>
      <span className={`capsule total ${getColor()}`}>
        <span className='dot' />
        <span className={`title ${nameOnly ? 'invisible' : 'visible'}`}>전체</span>
        <span className='meter'>
          <span className='emphasize'>{length.toLocaleString()}</span>m
        </span>
      </span>
      <span className={`capsule present ${getColor()}`}>
        <span className='dot' />
        <span className={`title ${nameOnly ? 'invisible' : 'visible'}`}>현재</span>
        <span className='meter'>
          <span className='emphasize'>{rLength.toLocaleString()}</span>m
        </span>
      </span>
    </TotalLengthContainer>
  );
};

export default TotalPointLength;
