/**
 * 작성자 : 김광민
 * 날짜 : 2024.03.05
 * 기능 : 접고 펼치는 기능이 있고 길이 제한이 있는 텍스트 입력 컴포넌트
 */

import { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import TextareaAutosize from 'react-textarea-autosize';

const Root = styled.div`
  width: 100%;
  border-radius: 0.25rem;
  .title-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.75rem;
    border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    border-bottom: none;
    padding: 0 0.5rem;
    border-radius: 0.25rem 0.25rem 0 0;
    background-color: ${({ theme }: { theme: any }) => theme.board};

    .title {
      display: flex;
      align-items: center;
      gap: 0.375rem;
      .material-symbols-rounded {
        cursor: pointer;
        color: ${({ theme }: { theme: any }) => theme.text_disabled};
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        padding: 0.125rem;
        border-radius: 0.25rem;
        &:hover {
          box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.2);
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        }
        &:active {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
        }
      }
      .label {
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      }
    }
    .count {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep_green};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
      border-radius: 0.25rem;
      font-size: 0.875rem;
      padding: 0 0.5rem;
      .value {
        color: ${({ theme }: { theme: any }) => theme.filled_green};
      }
      .max-length {
        color: ${({ theme }: { theme: any }) => theme.filled_green_deep};
      }
    }
  }
  textarea {
    border-radius: 0 0 0.25rem 0.25rem;
    border: 1px solid ${({ theme }: { theme: any }) => theme.outline_em};
    border-collapse: collapse;
    width: 100%;
    padding: 1rem;
    letter-spacing: normal;
    font-size: 0.938rem;
    line-height: 1.6;
    font-weight: 400;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    resize: none;
    min-height: 8rem;
    max-height: 24rem;
    background-color: ${({ theme }: { theme: any }) => theme.board};
    &:focus {
      box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.15);
    }
  }
  &.collapsed {
    .title-bar {
      border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      border-radius: 0.25rem;
      &:hover {
        box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.1);
      }
    }
    textarea {
      display: none;
    }
  }
`;

type Props = {
  name: string;
  label: string;
  state: any;
  setState: Dispatch<SetStateAction<any>>;
  maxLength?: number;
  isExpandedState?: boolean;
};

const AccordionTextArea = ({ name, label, state, setState, maxLength, isExpandedState = false }: Props) => {
  const [isExpanded, setIsExpanded] = useState(isExpandedState);

  const onChangeValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value, name: targetName } = e.target;
    // 최대 길이 제한 처리
    const newValue = maxLength !== undefined ? value.slice(0, maxLength) : value;
    // 스프레드 연산자와 함께 상태 업데이트
    setState((prev: any) => ({ ...prev, [targetName]: newValue }));
  };

  const getStyle = () => {
    const expandedState = isExpanded ? 'expanded' : 'collapsed';
    return `${expandedState}`;
  };

  return (
    <Root className={getStyle()}>
      <div className='title-bar'>
        <div className='title'>
          <span className='material-symbols-rounded' onClick={() => setIsExpanded(!isExpanded)} role='button' tabIndex={0}>
            {!isExpanded ? 'chevron_right' : 'expand_more'}
          </span>
          <span className='label'>{label}</span>
        </div>
        <div className='count'>
          <span className='value'>{state[name].length}</span>
          <span className='max-length'> / {maxLength}</span>
        </div>
      </div>
      <TextareaAutosize maxLength={300} rows={5} name={name} onChange={onChangeValue} value={state[name]} />
    </Root>
  );
};

export default AccordionTextArea;
