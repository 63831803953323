import { useEffect, useState } from 'react';
import styled from 'styled-components';

import WebSdkPlay from '../WebSdkPlay';
import LeftSideMenu from './PlayCCTV/LeftSideMenu';
import * as cctv from '../../../utils/cctv';
import { WebSDKcctv } from '../../../utils/webSDKcctvClass';
import { useFetchCctvList } from '../../../services/useFetchCctvList';
import { useGetCctvSettings } from '../../../hooks/useCctvSettings';
import IssueGuide from '../../../components/IssueGuide';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import { PulseLoader } from 'react-spinners';
import { streamSelectBoxOptions } from '../../../_constants';
import useWindows from '../../../hooks/useWindows';

const Root = styled.div`
  width: 100vw;
  width: 100dvw;
  height: 100vh;
  height: 100dvh;
  display: flex;
  .main {
    flex: 1 0;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }: { theme: any }) => theme.color.black};
  }
  &.isDragging * {
    cursor: grabbing !important;
  }
`;

const PlayCCTV = () => {
  const { cameraInfo, nvrList, setNvrList, isError: isErrorCctvList, isLoading: isLoadingCctvList } = useFetchCctvList();
  const [visiblePanel, setVisiblePanel] = useState(true); // 왼쪽 사이드 패널 접힘/펼침 상태

  const [draggingCamInfo, setDraggingCamInfo] = useState<ISubMenu[] | null>(null); // 드래그한 카메라객체 정보
  const [isDragging, setIsDragging] = useState(false); // 마우스 드래그 상태 - 커서 모양 변경 용도
  const [cctvOBJ, setCctvOBJ] = useState<WebSDKcctv>();
  const [cctvFlow, setCctvFlow] = useState(0);
  const [droppedWndIndex, setDroppedWndIndex] = useState<null | number>(null); // 드래그한 카메라객체를 드랍한 윈도우 인덱스
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data: cctvSettings, isLoading: isCctvSettingsLoading, isError: isCctvSettingsError, isFetching: isCctvSettingFetching, isRefetching: isCctvSettingRefetching } = useGetCctvSettings(); // CCTV 설정
  const [selectedCctvCameraInfo, setSelectedCctvCameraInfo] = useState<SelectedCctvCameraInfo>({} as SelectedCctvCameraInfo);
  // const [cctvDivisions, setCctvDivisions] = useState<number>(Number(cctvSettings?.camCType) || 2);
  const [cctvDivisions, setCctvDivisions] = useState<1 | 2 | 3 | 4>(2);
  const [dayRecordFiles, setDayRecordFiles] = useState<Playback[]>([]); // 해당일자의 녹화파일 리스트
  const [selectedStream, setSelectedStream] = useState<Stream>(streamSelectBoxOptions[0]); // 녹화파일 검색 스트림 선택값

  // useClocks 커스텀 hook 선언
  const windows = useWindows();

  // plugin 제거
  useEffect(() => {
    if (cctv.getPluginOBJECT()?.oPlugin) {
      cctv.destroy();
    }
  }, []);

  const isError = isErrorCctvList || isCctvSettingsError;
  const isLoading = isLoadingCctvList || isCctvSettingsLoading || isCctvSettingFetching || isCctvSettingRefetching;

  if (isError) {
    return <IssueGuide />;
  }

  if (isLoading) {
    return (
      <LoadingModalBackground>
        <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
      </LoadingModalBackground>
    );
  }

  return (
    <Root>
      <LeftSideMenu
        cctvOBJ={cctvOBJ}
        visiblePanel={visiblePanel}
        draggingCamInfo={draggingCamInfo}
        setDraggingCamInfo={setDraggingCamInfo}
        nvrList={nvrList}
        setNvrList={setNvrList}
        isLoading={isLoading}
        isError={isError}
        setIsDragging={setIsDragging}
        selectedCctvCameraInfo={selectedCctvCameraInfo}
        cctvDivisions={cctvDivisions}
        cctvSettings={cctvSettings}
        setDayRecordFiles={setDayRecordFiles}
        selectedStream={selectedStream}
        setSelectedStream={setSelectedStream}
      />
      <div className='main'>
        <WebSdkPlay
          cctvOBJ={cctvOBJ} // cctv 객체
          setCctvOBJ={setCctvOBJ} // cctv 객체 변경 함수
          draggingCamInfo={draggingCamInfo} // 드래그한 카메라 객체 정보
          setDraggingCamInfo={setDraggingCamInfo} // 드래그한 카메라 객체 정보 변경 함수
          droppedWndIndex={droppedWndIndex} // 드래그한 카메라 객체를 드랍한 윈도우 인덱스
          setDroppedWndIndex={setDroppedWndIndex} // 드래그한 카메라 객체를 드랍한 윈도우 인덱스 변경 함수
          cctvFlow={cctvFlow}
          setCctvFlow={setCctvFlow}
          cctvSettings={cctvSettings || null}
          cameraInfo={cameraInfo}
          setIsDragging={setIsDragging} // 드래그 상태 변경 함수
          visiblePanel={visiblePanel} // 왼쪽 사이드 패널 접기/펼치기 상태
          setVisiblePanel={setVisiblePanel} // 왼쪽 사이드 패널 상태 변경 함수
          nvrList={nvrList}
          setNvrList={setNvrList}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          selectedCameraInfo={selectedCctvCameraInfo}
          setSelectedCameraInfo={setSelectedCctvCameraInfo}
          dayRecordFiles={dayRecordFiles} // 해당일자의 녹화파일 리스트
          selectedStream={selectedStream}
          cctvDivisions={cctvDivisions}
          setCctvDivisions={setCctvDivisions}
          windows={windows}
        />
      </div>
    </Root>
  );
};

export default PlayCCTV;
