import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem;
  border-radius: 2rem;
  background-color: ${({ theme }: { theme: any }) => theme.tonal};
  user-select: none;
  width: 100%;
  .title {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    .material-symbols-rounded {
      margin-left: 0.25rem;
      margin-left: 0.125rem;
      font-size: 1.25rem;
      font-size: 1.5rem;
      font-variation-settings: 'FILL' 1;
    }
    .label {
      font-size: 0.75rem;
      font-weight: 600;
      margin-right: 0.25rem;
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    .switch {
      padding: 0 1rem;
      border-radius: 2rem;
      min-width: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 0.875rem;
      user-select: none;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      &:hover:not(.on) {
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
        box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.1);
      }
    }
    .on {
      background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
      color: ${({ theme }: { theme: any }) => theme.text_reverse};
    }
    .seperator {
      height: 1rem;
      border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline_em};
      margin: 0 0.25rem;
    }
  }
  &:hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
    .off {
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
    }
  }
`;

type Props = {
  icon: string;
  state: number;
  setState: Dispatch<SetStateAction<number>>;
  label?: string;
  buttons: {
    text: string;
    value: number;
  }[];
};

const SwitchManyButtons = ({ icon, state, setState, label, buttons }: Props) => {
  const { t } = useTranslation();
  return (
    <Root>
      <div className='title'>
        <span className='material-symbols-rounded'>{icon}</span>
        {label && <span className='label'>{label}</span>}
      </div>
      <div className='buttons'>
        {buttons.map((button, i) => (
          <>
            {i !== 0 && <span className='seperator' />}
            <span //
              className={`switch ${state === button.value ? 'on' : 'off'}`}
              onClick={() => setState(button.value)}
              role='button'
              tabIndex={0}
            >
              {t(button.text)}
            </span>
          </>
        ))}
      </div>
    </Root>
  );
};

export default SwitchManyButtons;
