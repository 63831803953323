/**
 * 작성자 : 홍선영
 * 날짜 : 2023.08.11
 * 기능 : 본사일반관리-메인화면관리 페이지
 */

import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { ITabObject } from 'customTypes';
import { IUser, pageInfoState, userState } from '../../atoms';
import Tab from '../../components/Tab';
import DashboardSetting from '../sm_setting/setting4/dashboardSetting';
import illustrator from '../../assets/images/illustration/74.svg';
import { useTranslation } from 'react-i18next';

const EmptyData = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  padding-bottom: 8rem !important;
  img {
    width: 24rem;
    -webkit-user-drag: none;
  }
  p {
    color: ${({ theme }: { theme: any }) => theme.filled_violet};
    word-break: keep-all;
    text-align: center;
  }
  p:nth-child(2) {
    padding: 1rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    border-radius: 0.5rem;
    margin: 1rem;
  }
  p:nth-child(3) {
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    margin: 0 2rem;
  }
`;

const Hcommon2 = () => {
  const { t } = useTranslation();
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  const [userInfo, setUserInfo] = useRecoilState<IUser>(userState);
  const { userMenuList } = userInfo;
  const [newTabList, setNewTabList] = useState<ITabObject[]>();

  useEffect(() => {
    const arr: any[] = [];
    userMenuList.map((v: any) => {
      const result = tabInfo?.tabList?.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  const onClickTab = (mrCd: string) => {
    setCurrentTabMrCd(mrCd);
  };

  const renderPage = () => {
    const activeTab = newTabList?.find((el: ITabObject) => el.mrCd === currentTabMrCd);
    if (activeTab !== undefined) {
      const { mrCd } = activeTab;
      switch (mrCd) {
        case '001':
          // return <Dashboard isViewMode={false} />;
          return <DashboardSetting />;
      }
    }
    return undefined;
  };
  return (
    <div className='contents'>
      <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      <div className='content-container oneColumn'>
        {size.innerSize.W >= 1024 ? (
          renderPage()
        ) : (
          <EmptyData>
            <img src={illustrator} alt='noData' />
            <p>{t('데스크톱 환경에서 설정해 주세요')}</p>
            <p>{t('화면이 안 나오신다면 브라우저의 가로 폭를 늘려주세요')}</p>
          </EmptyData>
        )}
      </div>
    </div>
  );
};

export default Hcommon2;
