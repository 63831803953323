import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useEffect, useState } from 'react';
import i18n from '../translation/i18n';
import Select from 'react-select';
import styled from 'styled-components';

dayjs.extend(relativeTime);

const ReactSelectStyle = styled.div`
  .select__single-value {
    cursor: pointer;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .basic-single > :nth-child(3) {
    border: 0;
    background-color: transparent;

    span {
      background-color: ${({ theme }: { theme: any }) => theme.outline};
    }

    > :last-child > :last-child {
      > svg {
        height: 1rem;
        width: 1rem;
      }
    }
  }

  .select__menu {
    position: absolute;
    z-index: 9999;
    .select__menu-list {
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      .select__option {
        color: ${({ theme }: { theme: any }) => theme.text_primary};
        cursor: pointer;
        &:hover,
        &.select__option--is-focused {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
        }
        &.select__option--is-selected {
          background-color: ${({ theme }: { theme: any }) => theme.selected_primary};
        }
      }
    }
  }

  img {
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    border: 0.5px solid ${({ theme }: { theme: any }) => theme.tonal};
  }
`;

const options: any[] = [
  {
    value: 'ko-KR',
    label: (
      <div>
        <img src='/img/country/K.svg' />
      </div>
    ),
  },
  {
    value: 'en-US',
    label: (
      <div>
        <img src='/img/country/E.svg' />
      </div>
    ),
  },
  // {
  //   value: 'vi-VN',
  //   label: (
  //     <div>
  //       <img src='/img/country/V.svg' />
  //     </div>
  //   ),
  // },
];

const Language: React.FC = (): any => {
  const localeLang = i18n.language.substring(0, 2);
  const [localeState, setlocaleState] = useState(options.find((v: any) => v.value === i18n.language));

  useEffect(() => {
    dayjs.locale(localeLang);
  }, [i18n.language]);

  const customStyles: any = {
    // container: (provided: any, state: any, mode: any) => ({}),
    singleValue: (provided: any) => ({
      ...provided,
      marginLeft: '0',
      marginRight: '0',
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      padding: '0',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      display: 'grid',
      padding: '3px',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      padding: '0',
    }),
    indicatorSeparator: () => null,
  };

  const handleChangeSelect = (e: any) => {
    const locale = e.value;
    i18n.changeLanguage(locale);
    setlocaleState(options.find((v: any) => v.value === locale));
    window.localStorage.setItem('language', locale);
  };

  return (
    <ReactSelectStyle>
      <Select className='basic-single' classNamePrefix='select' value={localeState} options={options} styles={customStyles} onChange={handleChangeSelect} isSearchable={false} menuPlacement='auto' />
    </ReactSelectStyle>
  );
};
export default Language;
