import styled from 'styled-components';
import ClickableContainer from './ClickableContainer';
import { firstPaginationButton, lastPaginationButton, nextPaginationButton, previousPaginationButton } from '../../../../components/button/DarkButtons';
import { Dispatch, SetStateAction } from 'react';

const Root = styled.div`
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  user-select: none;
  color: ${({ theme }: { theme: any }) => theme.color.white};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 0.25rem;
  .page {
    margin: 0 0.75rem;
  }
`;

interface IProps {
  divisionCount: number;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  disabled?: boolean;
}

const Pagination = ({ divisionCount, currentPage = 1, setCurrentPage, disabled = false }: IProps) => {
  const pageCount = Math.ceil(128 / (divisionCount * divisionCount));

  return (
    <Root className={disabled ? 'disabled' : ''}>
      <ClickableContainer // 처음으로
        button={firstPaginationButton}
        onClick={() => setCurrentPage(1)}
        disabled={currentPage === 1}
      />
      <ClickableContainer // 이전
        button={previousPaginationButton}
        onClick={() => setCurrentPage(currentPage - 1)}
        disabled={currentPage === 1}
      />
      <span className='page'>
        {currentPage}/{pageCount}
      </span>
      <ClickableContainer // 다음
        button={nextPaginationButton}
        onClick={() => setCurrentPage(currentPage + 1)}
        disabled={currentPage === pageCount}
      />
      <ClickableContainer // 마지막으로
        button={lastPaginationButton}
        onClick={() => setCurrentPage(pageCount)}
        disabled={currentPage === pageCount}
      />
    </Root>
  );
};

export default Pagination;
