import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useOutletContext } from 'react-router-dom';

import { IUser, pageInfoState, userState } from '../../atoms';
import { ITabObject } from 'customTypes';
import Tab from '../../components/Tab';
import JobtypeVideo from './setting12/JobtypeVideo';
import TabletInputSettings from './setting12/TabletInputSettings';
import AgreementsForm from './setting12/AgreementsForm';
import SiteJobtypeSetting from './setting12/SiteJobtypeSetting';
import SafeEduExamSetting from './setting12/SafeEduExamSetting';

const SMSetting12 = () => {
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const userInfo = useRecoilValue<IUser>(userState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  const [newTabList, setNewTabList] = useState<ITabObject[]>();
  const { userMenuList } = userInfo;

  useEffect(() => {
    const arr: any[] = [];
    userMenuList.map((v: any) => {
      const result = tabInfo?.tabList //
        ?.find(
          (el: ITabObject) =>
            el.mtCd === v.mtCd && //
            el.mdCd === v.mdCd &&
            el.mrCd === v.mrCd
        );
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  const onClickTab = (mrCd: string) => {
    setCurrentTabMrCd(mrCd);
  };

  const renderPage = () => {
    const activeTab = userMenuList.find((el: ITabObject) => el.mrCd === currentTabMrCd);
    if (activeTab !== undefined) {
      const { mrCd } = activeTab;
      switch (mrCd) {
        case '001':
          return <TabletInputSettings />; // 태블릿 입력 설정
        case '002':
          return <AgreementsForm tGubun='C' />; // 공통 양식지
        // case '003':
        //   return <SafetyAgreements />;
        case '004':
          return <AgreementsForm tGubun='J' />; // 직종별 양식지
        case '005':
          return <JobtypeVideo sType='1' />; // 필수 동영상 설정
        case '006':
          return <JobtypeVideo sType='2' />; // 직종별 동영상 설정
        case '007':
          return <SiteJobtypeSetting />; // 협력업체 직종 관리
        case '008':
          return <SafeEduExamSetting />; // 안전보건 교육 문제 관리
      }
    }
    return null;
  };

  return (
    <div className='contents'>
      <Tab
        tabList={newTabList || tabInfo.tabList} //
        currentTabMrCd={currentTabMrCd}
        setCurrentTabMrCd={setCurrentTabMrCd}
        onClickTab={onClickTab}
        size={size}
      />
      {renderPage()}
    </div>
  );
};

export default SMSetting12;
