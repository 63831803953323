import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

const TunnelChipContainer = styled.button`
  padding: 0;
  height: fit-content;
  color: initial;
  &:hover {
    background-color: transparent;
  }
  .tunnellChip {
    user-select: none;
    height: 2.5rem;
    display: flex;
    padding-left: 0.25rem;
    padding-right: 1rem;
    align-items: center;
    border-radius: 2rem;
    overflow: hidden;
    outline: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
    cursor: pointer;
    &:active {
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
    }
    &.fixed {
      cursor: default;
      &:active {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      }
      &.checked {
        padding-right: 0.25rem;
        .done {
          color: ${({ theme }: { theme: any }) => theme.filled_green};
          font-variation-settings: 'FILL' 1, 'wght' 600, 'GRAD' 0, 'opsz' 24;
        }
      }
      .chipName {
        gap: 0.25rem;
      }
    }
    &.default {
      .done {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
        outline: 1px solid ${({ theme }: { theme: any }) => theme.tonal_deep_blue};
        color: ${({ theme }: { theme: any }) => theme.tonal_deep_blue};
        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 1.5rem;
        width: 1.5rem;
        font-variation-settings: 'FILL' 1, 'wght' 600, 'GRAD' 0, 'opsz' 24;
      }
      padding-right: 0.25rem;
      &.checked {
        .done {
          outline: none;
          background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
          color: ${({ theme }: { theme: any }) => theme.board};
        }
      }
      .chipName {
        gap: 0.5rem;
      }
    }
    .chipIcon {
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      display: flex;
      height: 100%;
      width: 2.25rem;
      border-radius: 2rem;
      justify-content: center;
      align-items: center;
      .material-symbols-rounded {
        font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
        font-size: 1.25rem;
      }
    }
    .chipName {
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      border-radius: 1rem;
      padding-right: 0.25rem;
      display: flex;
      font-size: 0.875rem;
      height: 1.75rem;
      align-items: center;
    }
    .material-symbols-rounded {
      font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
      font-size: 1.25rem;
    }
  }
`;

type ChipData = { title: string; status: boolean; name: string; icon: string };

type Props = {
  chipData: ChipData;
  setFilter: Dispatch<SetStateAction<ChipData[]>>;
  defaultOption: boolean;
};

const TunnelChipButton = ({ chipData, setFilter, defaultOption }: Props) => {
  const handleClick = () => {
    setFilter((prevFilter) => {
      const updatedFilter = prevFilter.map((item) => {
        if (item.title === chipData.title) {
          return { ...item, status: !item.status };
        }
        return item;
      });
      return updatedFilter;
    });
  };

  return (
    <TunnelChipContainer onClick={defaultOption ? () => {} : handleClick}>
      <div className={`tunnellChip ${chipData.status && 'checked'} ${defaultOption ? 'fixed' : 'default'}`}>
        <div className='chipIcon'>
          <span className='material-symbols-rounded'>{chipData.icon}</span>
        </div>
        <div className='chipName'>
          {chipData.name}
          <span className={`material-symbols-rounded done ${chipData.status ? 'visible' : 'invisible'}`}>check</span>
        </div>
      </div>
    </TunnelChipContainer>
  );
};

export default TunnelChipButton;
