/**
 * 작성자 : 김광민
 * 날짜 : 2023.11.06
 * 기능 : 화면이 렌더링 될때 tuigrid의 고정 column을 browser size에 따라 변동 적용
 */
import { useOutletContext } from 'react-router-dom';

// tuigrid의 frozen count 렌더링
export const useGetFrozenCount = () => {
  const size = useOutletContext<any>();
  if (size.innerSize.W > 1024) {
    return 1;
  }
  return 0;
};
