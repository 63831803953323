import styled from 'styled-components';
import ClickableContainer from '../RealtimeCCTV/ClickableContainer';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { pauseButton, playArrowButton, playArrowReverseButton, playStopButton } from '../../../../components/button/DarkButtons';
import { WebSDKcctv } from '../../../../utils/webSDKcctvClass';
import { timeToHHMMSS } from '../../../../utils/timeline';
import { removeCameraWindow } from '../../../../services/webSDK';

const Root = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  color: ${({ theme }: { theme: any }) => theme.color.white};
  user-select: none;
  gap: 0.25rem;
  .time {
    margin: 0 1rem;
    width: 4rem;
  }
`;

interface Props {
  cctvOBJ: WebSDKcctv | undefined; // cctv 객체
  selectedCameraInfo: SelectedCctvCameraInfo;
  setSelectedCameraInfo: Dispatch<SetStateAction<SelectedCctvCameraInfo>>;
  nvrList: IMainMenu[];
  setNvrList: Dispatch<SetStateAction<IMainMenu[]>>;
  // currentTime: number;
  onClickPlay: (reverse?: 'reverse') => void;
  onClickPause: () => void;
  videoWindow: any;
  onClickStopAndPlay: (reverse?: 'reverse') => void;
}

const ControllCenterBar = ({
  cctvOBJ, //
  selectedCameraInfo,
  setSelectedCameraInfo,
  nvrList,
  setNvrList,
  // currentTime,
  onClickPlay,
  onClickPause,
  videoWindow,
  onClickStopAndPlay,
}: Props) => {
  const disabled = selectedCameraInfo.windowIndex === null;
  const { isReversed, setIsReversed } = videoWindow.clock;
  const { isPlaying } = selectedCameraInfo;

  // console.log(videoWindow, 'videoWindow');

  const playReverse = async () => {
    // 거꾸로 재생 중일때
    if (isPlaying && isReversed) onClickPause();
    // 일반 재생 중일때
    if (isPlaying && !isReversed) {
      onClickPlay('reverse');
      setIsReversed(true);
    }
    // 재생 중이 아닐때
    if (!isPlaying) {
      onClickPlay('reverse');
      setIsReversed(true);
    }
  };

  const play = () => {
    // 일반 재생 중일때
    if (isPlaying && !isReversed) onClickPause();
    // 거꾸로 재생 중일때
    if (isPlaying && isReversed) {
      onClickPlay();
      setIsReversed(false);
    }
    // 재생 중이 아닐때
    if (!isPlaying) {
      onClickPlay();
      setIsReversed(false);
    }
  };

  // 정지 버튼 클릭 함수
  const onClickStop = () => {
    if (cctvOBJ)
      removeCameraWindow(
        cctvOBJ, //
        selectedCameraInfo,
        setSelectedCameraInfo,
        nvrList,
        setNvrList
      );
  };

  return (
    <Root>
      <ClickableContainer //
        button={playStopButton}
        onClick={onClickStop}
        disabled={disabled}
      />
      <ClickableContainer //
        button={isPlaying && isReversed ? pauseButton : playArrowReverseButton}
        onClick={playReverse}
        disabled={disabled}
      />
      <div className='time'>{timeToHHMMSS(videoWindow.clock.currentTime)}</div>
      <ClickableContainer //
        button={isPlaying && !isReversed ? pauseButton : playArrowButton}
        onClick={play}
        disabled={disabled}
      />
    </Root>
  );
};

export default ControllCenterBar;
