import { useRecoilValue } from 'recoil';
import { IUser, userState } from '../atoms';
import { apiGet } from '../services/_common';
import { useQuery } from '@tanstack/react-query';
import { Dispatch, SetStateAction } from 'react';

export const useGetJobTypeLists = (preJobTypeYn: 'Y' | 'N') => {
  const { prejobtypeYn } = useRecoilValue(userState);
  const { hCd, sCd } = useRecoilValue<IUser>(userState);
  const mainPath = '/code/pmsNormalTitle';
  const subPath = '/code/pmsNormalSub';

  const setList = (item: any) => ({
    key: prejobtypeYn === 'Y' ? `${item.grCd}${item.subCd}` : item.subCd,
    name: item.cdName,
  });

  const getSubList = async (grCd: string) => {
    const response = await apiGet({ path: subPath, req: { hCd, sCd, grCd } });
    if (response.data?.statusCode === 200) {
      const { pmsNormalSubList } = response.data.data;
      const data = pmsNormalSubList.map(setList);
      return data;
    }
    return [];
  };

  const getList = async () => {
    const subCd = preJobTypeYn === 'Y' ? 'A' : 'B';
    const response = await apiGet({ path: mainPath, req: { hCd, sCd, subCd } });

    if (response.data?.statusCode === 200) {
      const { pmsNormalTitleList } = response.data.data;
      // preJobTypeYn 이 'Y'인 경우, subList 조회
      if (subCd === 'A') {
        const ALists = await Promise.all(
          pmsNormalTitleList.map(async (item: any) => ({
            grCd: item.subCd,
            grName: item.cdName,
            subList: await getSubList(item.subCd),
          }))
        );
        const filteredData = ALists.filter((item: any) => item.subList.length !== 0);
        return filteredData;
      }
      // preJobTypeYn 이 'N'인 경우
      const BLists = pmsNormalTitleList.map(setList);
      return BLists;
    }
    return [];
  };

  const jobTypeQuery = useQuery([mainPath, hCd, sCd], getList);

  return jobTypeQuery;
};

export const flattenSubLists = (data: JobtypeFoldableList[]): JobtypeList[] => {
  return data.reduce((acc: JobtypeList[], group: JobtypeFoldableList) => {
    return acc.concat(group.subList);
  }, []);
};

export const countSelectedAtFoldable = (flattenedList: JobtypeList[], selectedArray: string[]): number => {
  const newArray = new Set(selectedArray);
  return flattenedList.reduce((count, item) => {
    if (newArray.has(item.key)) {
      return count + 1;
    }
    return count;
  }, 0);
};

export const findJobTypeName = (key: string, jobTypes: JobtypeList[]): string | undefined => {
  const jobType = jobTypes?.find((item) => {
    if (item === undefined) {
      return undefined;
    }
    return item.key === key;
  });
  return jobType ? jobType.name : undefined;
};

export const handleListClick = (item: JobtypeList, setState: Dispatch<SetStateAction<string[]>>, state: string[]) => {
  if (state.includes(item.key)) {
    setState(state.filter((value) => value !== item.key));
  } else {
    setState([...state, item.key]);
  }
};
