/**
 * 작성자 : 한영광
 * 날짜 : 2023.06.26
 * 경로 : 출역 관리 - 연 출역현황
 */
import { useEffect, useState, useRef } from 'react';
import { PulseLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { IoChevronUpSharp } from 'react-icons/io5';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

import { pageInfoState, userState } from '../../atoms';
import { BtnGhost } from '../../components/Button';
import { YEAR_LIST } from '../../_constants';
import { SearchOptions } from '../../assets/styles/SearchOptions';
import { scrollToNodeTop } from '../../utils/scrollToNodeTop';
import { useDetectScrolledToBottom } from '../../utils/useDetectScrolledToBottom';
import { FloatingButtonToTop } from '../../assets/styles/FloatingButtonToTop';
import { pad, todayYYYYMMDD } from '../../utils/formatDate';
import Tab from '../../components/Tab';
import Portal from '../../components/Portal';
import AttendListMonthlyModal from '../../components/Modal/AttendListMonthlyModal';
import useOnKeydownF9 from '../../utils/useOnKeydownF9';
import { logPost } from '../../services/log';
import { ITabObject } from 'customTypes';
import SearchSelectBoxSm from '../../components/SearchSelectBoxSm';
import { useSetAuth } from '../../utils/useSetAuth';
import TuiGrid from '../../components/Table/TuiGrid';
import TuiGridWrapper from '../../components/Table/TuiGridWrapper';
import { useGetFrozenCount } from '../../utils/getFrozenCount';
import { LoadingModalBackground } from '../../assets/styles/Modal';
import { ContentsContainerRoot } from '../../assets/styles/ContentsContainerRoot';
import i18n from '../../translation/i18n';
import { toast } from 'react-toastify';
import { apiGet } from '../../services/_common';
import SelectBox from '../../components/SelectBox';
import ShortcutButton from '../../components/button/ShortcutButton';

const Root = styled(ContentsContainerRoot)`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0.5rem;
  padding: 2rem 0;
`;

interface IModal {
  status: boolean;
  type: string;
  searchOption: ISearchOption;
}

interface ISearchOption {
  sjName: string;
  sjCd: string;
  aDate: string;
}

const Sattend3 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  const toDay = todayYYYYMMDD();
  const userInfo = useRecoilValue(userState);
  const { userMenuList } = userInfo;
  const [columns, setColumns] = useState<any[]>([]);
  const [summary, setSummary] = useState({});
  const tableRef = useRef<HTMLDivElement>(null);
  const [siteJoinInfoList, setSiteJoinInfoList] = useState<any[]>([]);
  const [orgSiteJoinInfoList, setOrgSiteJoinInfoList] = useState<any[]>([]);
  const [sjCd, setSjCd] = useState({ type: 'sjCd', sjCd: '', cdName: '' });
  const [aYear, setAYear] = useState({ type: 'aYear', aYear: toDay.substring(0, 4), cdName: toDay.substring(0, 4) });
  const [excelBtn, setExcelBtn] = useState(false);
  const [searchBtn, setSearchBtn] = useState(false);
  const [tableState, setTableState] = useState<any[]>([]);
  const { isBottom } = useDetectScrolledToBottom(tableRef);
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', searchOption: { sjName: '', sjCd: '', aDate: '' } });
  const [newTabList, setNewTabList] = useState<ITabObject[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const arr: any[] = [];
    userMenuList.map((v: any) => {
      const result = tabInfo?.tabList?.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  useEffect(() => {
    getSjCdAPI().then((res: any) => {
      if (res.status === 200) {
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '연 출역현황',
          action: '조회',
          etc: ``,
        });
      }
    });
  }, []);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    const header = [];
    header.push({ header: t('협력업체'), name: 'sjName', align: 'left', filter: 'select', width: 200, renderer: { classNames: ['text_secondary'] } });
    header.push({ header: t('전년 누계'), name: 'lastYearCnt', align: 'center', minWidth: 100, renderer: { classNames: ['text_secondary'] } });
    header.push({ header: t('금년 누계'), name: 'yearCnt', align: 'center', minWidth: 100, renderer: { classNames: ['text_secondary'] } });
    for (let i = 0; i < 12; i += 1) {
      header.push({
        header: `${i + 1}${t('-월')}`,
        name: `${aYear.aYear}${pad(i + 1, 2)}`,
        align: 'center',
        minWidth: 60,
        renderer: { classNames: ['text_secondary'] },
      });
    }
    setColumns(header);

    const summaryArr = [];
    for (let i = 0; i < 12; i += 1) {
      summaryArr.push({
        header: `${i + 1}${t('월')}`,
        name: `${aYear.aYear}${pad(i + 1, 2)}`,
        align: 'center',
      });
    }
    const summaryObj: any = {};
    summaryArr.map((v: any) => {
      summaryObj[v.name] = {
        template: (valueMap: any) => {
          return `${valueMap.sum}`;
        },
      };
    });
    setSummary({
      height: 30,
      position: 'top',
      columnContent: {
        sjName: t('전체'),
        lastYearCnt: {
          template: (valueMap: any) => {
            return `${valueMap.sum}`;
          },
        },
        yearCnt: {
          template: (valueMap: any) => {
            return `${valueMap.sum}`;
          },
        },
        ...summaryObj,
      },
    });
  }, [searchBtn, i18n.language]);

  // 연 출역현황 조회 API
  const getAttendYearAPI = async () => {
    const req = { sjCd: sjCd.sjCd, aYear: aYear.aYear, hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/attend/year', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      setLoading(false);
      setTableState(
        data.attendList.map((v: any) => {
          return {
            sjName: v.sjName,
            ...v.month,
            lastYearCnt: v.lastYearCnt,
            yearCnt: v.yearCnt,
          };
        })
      );
    } else {
      setLoading(false);
      // toast.error(t(ERROR));
    }
  };

  // 협력업체 리스트 조회 API
  const getSjCdAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, delYnCheck: 'Y' };
    const res = await apiGet({ path: '/siteJoin', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const newList = data.siteJoinList.filter((v1: any) => v1.useYn === 'Y').map((v2: any) => ({ type: 'sjCd', sjCd: v2.sjCd, cdName: v2.sjName }));
      setSiteJoinInfoList([{ type: 'sjCd', sjCd: '', cdName: t('전체') }, ...newList]);
      setOrgSiteJoinInfoList(data.siteJoinList);
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  // 검색 버튼 클릭
  const onClickSearch = () => {
    if (!openModal.status) {
      setLoading(true);
      navigate({
        pathname: location.pathname,
        search: '',
      });
      getAttendYearAPI();
      setSearchBtn(!searchBtn);
    }
  };

  // 엑셀 저장 버튼 클릭
  const onClickExcelSave = () => {
    if (tableState.length <= 0) return;
    setExcelBtn(true);
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '연 출역현황',
      action: '엑셀 저장',
      etc: ``,
    });
  };

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  // 인쇄 버튼 클릭
  const onClickPrint = () => {
    handlePrint();
  };

  // Row 클릭
  const onClickRow = (rowData: any, columnName: string) => {
    if (rowData === 'summary' && columnName !== 'yearCnt' && columnName !== 'lastYearCnt' && columnName !== 'sjName' && columnName !== '_number') {
      const data = { sjName: '', sjCd: '', aDate: columnName };
      setOpenModal({ status: true, type: 'monthlyAttendList', searchOption: data });
      return;
    }
    if (columnName !== 'yearCnt' && columnName !== 'lastYearCnt' && columnName !== 'sjName' && columnName !== '_number' && rowData[columnName] !== 0) {
      const sjObject = orgSiteJoinInfoList.find((el) => el.sjName === rowData.sjName);
      /**
       * 수정자 : 한영광
       * 수정일자 : 2023.10.20
       * 수정내용 : 협력업체가 없는 출역데이터 예외처리 -> 로컬에서 출역데이터 전달 시 협력업체 없이 전달될 수 있음
       */
      const data = { sjName: rowData.sjName, sjCd: sjObject ? sjObject.sjCd : '', aDate: columnName };
      setOpenModal({ status: true, type: 'monthlyAttendList', searchOption: data });
      // if (sjObject) {
      //   const data = { sjName: rowData.sjName, sjCd: sjObject.sjCd, aDate: columnName };
      //   setOpenModal({ status: true, type: 'monthlyAttendList', searchOption: data });
      //   // navigate('/s_attend2', { state: data });
      // }
    }
  };

  const renderExcelBtn = () => {
    if (auth.excelAuth) {
      return (
        <BtnGhost onClick={onClickExcelSave}>
          <span className='icon-ms-xlsx'>X</span> {t('엑셀')}
          {/* <span className='material-symbols-rounded'>download</span>저장 */}
        </BtnGhost>
      );
    }
    return null;
  };

  const renderPrintBtn = () => {
    if (auth.printAuth) {
      return (
        <BtnGhost onClick={onClickPrint}>
          <span className='material-symbols-rounded'>print</span>
          {t('인쇄')}
        </BtnGhost>
      );
    }
    return null;
  };

  const onClickTab = (tab: string) => {
    setCurrentTabMrCd(tab);
  };
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight, componentRef.current?.offsetWidth]);

  const frozenCount = useGetFrozenCount();

  return (
    <div className='contents'>
      <div className='content-container oneColumn'>
        <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
        <Root loading={loading}>
          <SearchOptions>
            {isBottom && (
              <FloatingButtonToTop>
                <button type='button' onClick={() => scrollToNodeTop(tableRef)}>
                  <IoChevronUpSharp size={20} style={{ stroke: 'white' }} />
                </button>
              </FloatingButtonToTop>
            )}
            <div className='inputsWrapper'>
              <div className='inputForm-row withLabel'>
                <label htmlFor='sjCd'>{t('협력업체')}</label>
                <div className='inputForm-col'>
                  <SearchSelectBoxSm
                    options={siteJoinInfoList}
                    defaultOption={t('전체')}
                    state={sjCd}
                    setState={setSjCd}
                    stateKey='sjCd'
                    codeKey='cdName'
                    filterbar='filter-1-left'
                    optionHeight='height-md'
                  />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <SelectBox options={YEAR_LIST} defaultOption={aYear.aYear} state={aYear} setState={setAYear} stateKey='aYear' filterbar='filter-1-left' optionHeight='height-md' />
                </div>
              </div>
            </div>
            <div className='inputsWrapper'>
              <div className='secondSearchOption'>
                <div className='flex-basic textBtnGroup'>
                  <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
                  {/* <BtnGhost onClick={onClickSearch} className='searchBtn'>
                    {t('검색')}
                    <span className='shortcut-f9'>F9</span>
                  </BtnGhost> */}
                </div>
                <div className='flex-basic iconBtnGroup'>
                  {renderExcelBtn()}
                  {/* {renderPrintBtn()} */}
                </div>
              </div>
            </div>
          </SearchOptions>
          <TuiGridWrapper componentRef={componentRef}>
            <TuiGrid
              data={tableState}
              columns={columns}
              perPage={5}
              excelBtn={excelBtn}
              setExcelBtn={setExcelBtn}
              onClickRow={onClickRow}
              summary={summary}
              height={tuiHeight}
              frozenCount={frozenCount}
            />
          </TuiGridWrapper>
        </Root>
      </div>
      {loading && (
        <LoadingModalBackground>
          <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
        </LoadingModalBackground>
      )}
      <Portal openModal={openModal?.status}>
        {openModal.status && openModal.type === 'monthlyAttendList' && <AttendListMonthlyModal setOpenModal={setOpenModal} filter={openModal.searchOption} />}
      </Portal>
    </div>
  );
};

export default Sattend3;
