import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Root = styled.div`
  flex-shrink: 0;
  user-select: none;
  cursor: pointer;
  background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
  background-color: ${({ theme }: { theme: any }) => theme.board};
  color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
  border: 0.125rem solid ${({ theme }: { theme: any }) => theme.tonal_deep_blue};
  margin: 0 0.5rem;
  border-radius: 0.25rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  font-size: 0.875rem;
  &:hover {
    outline: 0.25rem solid ${({ theme }: { theme: any }) => theme.tonal_blue};
    background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
    color: ${({ theme }: { theme: any }) => theme.bw_inverse};
    &:active {
      background-color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
    }
  }
`;

interface Props {
  foldList: { fold: boolean }[];
  setFoldList: React.Dispatch<React.SetStateAction<{ fold: boolean }[]>>;
}

const FoldAll = ({ foldList, setFoldList }: Props) => {
  const { t } = useTranslation();
  const isFolded = foldList.every((el) => el.fold);
  const fold = !isFolded;

  return (
    <Root //
      onClick={() => {
        setFoldList(foldList.map((el) => ({ ...el, fold })));
      }}
    >
      {isFolded ? t('전체 펼치기') : t('전체 접기')}
    </Root>
  );
};

export default FoldAll;
