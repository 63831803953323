import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { DropdownIndicatorProps, MenuPlacement, components } from 'react-select';
import styled from 'styled-components';
import { FLAG_CREATE_OR_UPDATE } from '../_constants';

export interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

export const colourOptions: readonly ColourOption[] = [
  { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
  { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: false },
  { value: 'purple', label: 'Purple', color: '#5243AA' },
  { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
  { value: 'orange', label: 'Orange', color: '#FF8B00' },
  { value: 'yellow', label: 'Yellow', color: '#FFC400' },
  { value: 'green', label: 'Green', color: '#36B37E' },
  { value: 'forest', label: 'Forest', color: '#00875A' },
  { value: 'slate', label: 'Slate', color: '#253858' },
  { value: 'silver', label: 'Silver', color: '#666666' },
];

export interface StateOption {
  readonly value: string;
  readonly label: string;
}

export interface GroupedOption {
  readonly label: string;
  readonly options: readonly ColourOption[];
}

interface ReactSelectStyleProps {
  isSaveClicked: boolean | undefined;
  isValueEmpty: boolean;
}

const ReactSelectStyle = styled.div<ReactSelectStyleProps>`
  height: 2.5rem;
  .select__single-value {
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .basic-single > :nth-child(3) {
    padding: 0;
    margin: 0;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    border: ${(props) => (props.isValueEmpty && props.isSaveClicked ? '2px solid red' : 'none')};
    border-radius: 5px;
    height: 2rem;
    > div:first-child {
      width: 2rem;
      text-align: center;
    }
    > div {
      padding: 0;
      margin: 0;
      height: 100%;
      > div {
        margin: 0;
        padding: 0 0.25rem;
        font-size: 0.875rem;
        svg {
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        }
      }
    }
    span {
      background-color: ${({ theme }: { theme: any }) => theme.outline};
    }
  }

  .select__menu {
    position: absolute;
    z-index: 9999;
    .select__menu-list {
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      .select__option {
        color: ${({ theme }: { theme: any }) => theme.text_primary};
        cursor: pointer;
        &:hover,
        &.select__option--is-focused {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
        }
        &.select__option--is-selected {
          background-color: ${({ theme }: { theme: any }) => theme.selected_primary};
        }
      }
    }
  }
`;

interface IProps {
  options: IOption[];
  value?: IOption;
  state: any;
  setState: Dispatch<SetStateAction<any>>;
  defaultValue?: IOption;
  index?: number; // 스테이트가 배열인경우
  stateKey?: any;
  isClearable: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isRtl?: boolean;
  optionsPlacement?: MenuPlacement | undefined;
  isSaveClicked?: boolean;
  setBeforeState?: Dispatch<SetStateAction<any>>; //  useState set액션
}

interface IOption {
  label: string;
  value: string;
}

const ReactSelect = ({
  options,
  value,
  state,
  setState,
  defaultValue,
  index,
  stateKey,
  isClearable,
  isSearchable,
  isDisabled,
  isLoading,
  isRtl,
  optionsPlacement,
  isSaveClicked,
  setBeforeState,
}: IProps) => {
  const { t } = useTranslation();
  const handleChange = (option: IOption | null) => {
    if (option !== null) {
      if (index !== undefined) {
        const newItems = [...state];
        newItems[index] = { ...state[index], [stateKey]: option.value, flag: FLAG_CREATE_OR_UPDATE }; // 뷰테이블 로우 업데이트
        setState(newItems);
        if (setBeforeState) {
          setBeforeState(newItems);
        }
      } else {
        setState(option);
        if (setBeforeState) {
          setBeforeState(option);
        }
      }
    }
  };

  // const DropdownIndicator = (props: DropdownIndicatorProps<ColourOption, true>) => {
  //   return <span className='material-symbols-rounded'>keyboard_arrow_down</span>;
  // };

  return (
    <ReactSelectStyle isSaveClicked={isSaveClicked} isValueEmpty={!(value && value.value !== '')}>
      <Select
        // components={{ DropdownIndicator }}
        className='basic-single'
        classNamePrefix='select'
        defaultValue={defaultValue || options[0]}
        value={value}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        name='color'
        options={options}
        menuPlacement={optionsPlacement}
        noOptionsMessage={() => t('결과 없음')}
        onChange={handleChange}
      />

      {/* <div
        style={{
          color: 'hsl(0, 0%, 40%)',
          display: 'inline-block',
          fontSize: 12,
          fontStyle: 'italic',
          marginTop: '1em',
        }}
      >
        <Checkbox checked={isClearable} onChange={() => setIsClearable((state) => !state)}>
          Clearable
        </Checkbox>
        <Checkbox checked={isSearchable} onChange={() => setIsSearchable((state) => !state)}>
          Searchable
        </Checkbox>
        <Checkbox checked={isDisabled} onChange={() => setIsDisabled((state) => !state)}>
          Disabled
        </Checkbox>
        <Checkbox checked={isLoading} onChange={() => setIsLoading((state) => !state)}>
          Loading
        </Checkbox>
        <Checkbox checked={isRtl} onChange={() => setIsRtl((state) => !state)}>
          RTL
        </Checkbox>
      </div> */}
    </ReactSelectStyle>
  );
};

export default ReactSelect;
