import styled from 'styled-components';

const Root = styled.div`
  cursor: pointer;
  display: flex;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 2rem;
  border-radius: 2rem;
  flex-shrink: 0;
  padding-left: 0.625rem;
  padding-right: 0.75rem;
  color: ${({ theme }: { theme: any }) => theme.text_tertiary};
  background-color: ${({ theme }: { theme: any }) => theme.tonal};
  font-size: 0.875rem;
  font-weight: 500;
  &:hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
  }
  &:active {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
  }
  .material-symbols-rounded {
    font-size: 1.125rem;
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
  }
  &.small {
    height: 1.5rem;
    gap: 0.125rem;
  }
  &.blue {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
    color: ${({ theme }: { theme: any }) => theme.filled_blue};
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep_blue};
    }
    &.light {
      background-color: #eef2ff;
      color: ${({ theme }: { theme: any }) => theme.color.blue_500};
      &:hover {
        background-color: ${({ theme }: { theme: any }) => theme.color.indigo_100};
      }
    }
    &.dark {
      background-color: #212e48;
      background-color: ${({ theme }: { theme: any }) => theme.color.sky_900};
      color: ${({ theme }: { theme: any }) => theme.color.blue_400};
      &:hover {
        background-color: ${({ theme }: { theme: any }) => theme.color.sky_800};
      }
    }
  }
  &.red {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
    color: ${({ theme }: { theme: any }) => theme.filled_red};
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep_red};
    }
    &.light {
      background-color: #fff5f8;
      color: ${({ theme }: { theme: any }) => theme.color.rose_500};
      &:hover {
        background-color: ${({ theme }: { theme: any }) => theme.color.red_100};
      }
    }
    &.dark {
      background-color: #3a2434;
      color: ${({ theme }: { theme: any }) => theme.color.rose_500};
      &:hover {
        background-color: ${({ theme }: { theme: any }) => theme.color.red_900};
      }
    }
  }
`;

type Props = {
  icon?: string;
  onClick: () => void;
  text: string;
  color?: 'blue' | 'red';
  size?: 'small';
  fixTheme?: 'dark' | 'light';
};

const SmallIconWidthTextButton = ({ icon, onClick, text, color, size, fixTheme }: Props) => {
  const style = () => {
    return `${color ?? ''} ${size ?? ''} ${fixTheme ?? ''}`;
  };
  return (
    <Root role='button' tabIndex={0} onClick={onClick} className={style()}>
      {icon && <span className='material-symbols-rounded'>{icon}</span>}
      {text}
    </Root>
  );
};

export default SmallIconWidthTextButton;
