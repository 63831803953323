import { t } from 'i18next';

export const LOCK_DYN = [
  { type: 'lockYn', lockYn: 'A', lName: '전체' },
  { type: 'lockYn', lockYn: 'Y', lName: '잠금' },
  { type: 'lockYn', lockYn: 'N', lName: '미잠금' },
];

export const USE_DYN = [
  { type: 'useYn', useYn: 'A', cdName: '전체' },
  { type: 'useYn', useYn: 'Y', cdName: '사용' },
  { type: 'useYn', useYn: 'N', cdName: '미사용' },
];

export const USE_YN = [
  { type: 'useYn', useYn: 'Y', cdName: '사용' },
  { type: 'useYn', useYn: 'N', cdName: '미사용' },
];

export const SELECT_HNAME_OR_ADMINUSERNAME = [
  { type: 'nameType', nameType: 'hName', cdName: '본사명' },
  { type: 'nameType', nameType: 'userName', cdName: '관리자명' },
];

export const SELECT_HNAME_OR_SALESUSERNAME = [
  { type: 'nameType', nameType: 'hName', cdName: '본사명' },
  { type: 'nameType', nameType: 'userName', cdName: '영업 담당자명' },
];

export const SELECT_SNAME_OR_ADMINUSERNAME = [
  { type: 'nameType', nameType: 'sName', cdName: '현장명' },
  { type: 'nameType', nameType: 'userName', cdName: '관리자명' },
];

export const SELECT_SNAME_OR_HNAME = [
  { type: 'nameType', nameType: 'sName', cdName: '현장명' },
  { type: 'nameType', nameType: 'hName', cdName: '본사명' },
];

// CCTV 카메라 스트림항목
export const CAMERA_STREAM = [
  { type: 'stream', stream: '2', uName: 'Sub' },
  { type: 'stream', stream: '1', uName: 'Main' },
  { type: 'stream', stream: '3', uName: 'Third' },
];

// 근로자 근로상태
export const WORK_STATUS_SEARCH = [
  { type: 'wWorkstatus', wWorkstatus: 'A', cdName: '전체' },
  { type: 'wWorkstatus', wWorkstatus: 'I', cdName: '재직' },
  { type: 'wWorkstatus', wWorkstatus: 'R', cdName: '퇴직' },
  { type: 'wWorkstatus', wWorkstatus: 'D', cdName: '삭제' },
];

// 직무스트레스 결과
export const WORKER_STRESS_RESULT = [
  { type: 'stressResult', stressResult: 'A', cdName: '전체' },
  { type: 'stressResult', stressResult: '하위 25%', cdName: '하위 25%' },
  { type: 'stressResult', stressResult: '하위 50%', cdName: '하위 50%' },
  { type: 'stressResult', stressResult: '상위 50%', cdName: '상위 50%' },
  { type: 'stressResult', stressResult: '상위 25%', cdName: '상위 25%' },
];

// 근로자 근로상태 (인풋)
export const WORK_STATUS_INPUT = [
  { subCd: 'I', cdName: '재직' },
  { subCd: 'R', cdName: '퇴직' },
  { subCd: 'D', cdName: '삭제' },
  { subCd: 'T', cdName: '대기' },
];

// 근로자 성별
export const SEX = [
  { type: 'sex', value: '', cdName: '선택' },
  { type: 'sex', value: '1', cdName: '남성' },
  { type: 'sex', value: '2', cdName: '여성' },
];

// 근로자 외국인 유무
export const FOREIGN_YN = [
  { type: 'wForeignyn', wForeignyn: 'A', cdName: '전체' },
  { type: 'wForeignyn', wForeignyn: 'Y', cdName: '외국인' },
  { type: 'wForeignyn', wForeignyn: 'N', cdName: '내국인' },
];

// 근로자 외국인 유무
export const FOREIGN_YN_INPUT = [
  { type: 'wForeignyn', wForeignyn: '', cdName: '선택' },
  { type: 'wForeignyn', wForeignyn: 'Y', cdName: '외국인' },
  { type: 'wForeignyn', wForeignyn: 'N', cdName: '내국인' },
];

// CCTV 연결설정
export const CCTV_MODE = [
  { type: 'cctvProtocol', cctvProtocol: '0', cdName: '미선택' },
  { type: 'cctvProtocol', cctvProtocol: '1', cdName: 'WEB SDK' },
  { type: 'cctvProtocol', cctvProtocol: '2', cdName: 'RTSP' },
];

// 화면색상 모드
export const SCREEN_COLOR_MODE = [
  { type: 'bScreenMode', bScreenMode: '1', cdName: 'LIGHT' },
  { type: 'bScreenMode', bScreenMode: '2', cdName: 'DARK' },
];

// CCTV 통신 프로토콜
export const PROTOCOL = [
  { type: 'protocol', protocol: 'HTTP', cdName: 'HTTP' },
  { type: 'protocol', protocol: 'HTTPS', cdName: 'HTTPS' },
];

// 언어 설정 (상황판)
export const BOARD_LANG = [
  { type: 'bLangMode', bLangMode: '1', cdName: 'ko-KR' },
  { type: 'bLangMode', bLangMode: '2', cdName: 'en-US' },
  { type: 'bLangMode', bLangMode: '3', cdName: 'vi-VN' },
];

export const preJobTypeList = [
  { type: 'jobtype', jobtype: 'N', cdName: '직종만 사용' },
  { type: 'jobtype', jobtype: 'Y', cdName: '공종+직종 사용' },
];

// 근로자 근로상태
export const BLACK_YN = [
  { type: 'blackYn', blackYn: 'A', cdName: '전체' },
  { type: 'blackYn', blackYn: 'Y', cdName: '적용' },
  { type: 'blackYn', blackYn: 'N', cdName: '해제' },
];

// 근로자 근로상태
export const BLACK_YN_INPUT = [
  { type: 'blackYn', blackYn: 'Y', cdName: '적용' },
  { type: 'blackYn', blackYn: 'N', cdName: '해제' },
];

// 근로자 근로상태
export const SEARCH_CATEGORY = [
  { type: 'category', category: 'before', cdName: '출생일 이전' },
  { type: 'category', category: 'middle', cdName: '출생기간 선택' },
];

// 센서관리
export const SENSOR_TIME = [
  { type: 'time', time: '1', cdName: '시간' },
  { type: 'time', time: '2', cdName: '상세' },
];

// 안전지적 조치여부
export const SRSUM_YN = [
  { type: 'srSumYn', srSumYn: 'A', cdName: '전체' },
  { type: 'srSumYn', srSumYn: 'Y', cdName: '조치' },
  { type: 'srSumYn', srSumYn: 'N', cdName: '미조치' },
];

// 방송테스트
export const BROADCASTING = [
  { type: 'broadcasting', broadcasting: t('마이크'), cdName: t('마이크') },
  { type: 'broadcasting', broadcasting: t('사운드'), cdName: t('사운드') },
];

// 긴급 상황 조치 상태 코드
export const ACTION_STATUS = [
  { type: 'actionStatus', actionStatus: 'A', cdName: '전체' },
  { type: 'actionStatus', actionStatus: '0', cdName: '미조치' },
  { type: 'actionStatus', actionStatus: '1', cdName: '조치중' },
  { type: 'actionStatus', actionStatus: '2', cdName: '조치완료' },
];

// 긴급 상황 조치 상태 코드
export const ACTION_STATUS2 = [
  { type: 'actionStatus', actionStatus: '0', cdName: '미조치' },
  { type: 'actionStatus', actionStatus: '1', cdName: '조치중' },
  { type: 'actionStatus', actionStatus: '2', cdName: '조치완료' },
];

// 수직구 방향 목록
export const TUNNEL_DIRECTION = [
  { type: 'tDirection', tDirection: 'F', cdName: '정방향' },
  { type: 'tDirection', tDirection: 'R', cdName: '역방향' },
];

export const bookmarkSelectBoxOptions = [
  { type: 'camList', camList: '', cdName: '즐겨찾기 선택' },
  { type: 'camList', camList: 'camList', cdName: '통합 즐겨찾기' },
  { type: 'camList', camList: 'camList1', cdName: '즐겨찾기 1' },
  { type: 'camList', camList: 'camList2', cdName: '즐겨찾기 2' },
  { type: 'camList', camList: 'camList3', cdName: '즐겨찾기 3' },
  { type: 'camList', camList: 'camList4', cdName: '즐겨찾기 4' },
  { type: 'camList', camList: 'camList5', cdName: '즐겨찾기 5' },
];

export const streamSelectBoxOptions = [
  { type: 'stream', stream: 1, cdName: '메인 스트림' },
  { type: 'stream', stream: 2, cdName: '서브 스트림' },
  { type: 'stream', stream: 3, cdName: '트랜스코딩' },
];

// 근로자 안전교육 시험 결과
export const SAFEEDU_EXAM_RESULT = [
  { type: 'eduexamResult', eduexamResult: 'A', cdName: '전체' },
  { type: 'eduexamResult', eduexamResult: 'pass', cdName: '합격' },
  { type: 'eduexamResult', eduexamResult: 'nonPass', cdName: '불합격' },
];

// 최근 5년 연도
const toYear = new Date().getFullYear();
export const YEAR_LIST = [
  // { type: 'aYear', aYear: ``, cdName: `전체` },
  { type: 'aYear', aYear: `${toYear}`, cdName: `${toYear}` },
  { type: 'aYear', aYear: `${toYear - 1}`, cdName: `${toYear - 1}` },
  { type: 'aYear', aYear: `${toYear - 2}`, cdName: `${toYear - 2}` },
  { type: 'aYear', aYear: `${toYear - 3}`, cdName: `${toYear - 3}` },
  { type: 'aYear', aYear: `${toYear - 4}`, cdName: `${toYear - 4}` },
];

export const MONTH_LIST = [
  // { type: 'month', month: '', cdName: '전체' },
  { type: 'month', month: '01', cdName: '01' },
  { type: 'month', month: '02', cdName: '02' },
  { type: 'month', month: '03', cdName: '03' },
  { type: 'month', month: '04', cdName: '04' },
  { type: 'month', month: '05', cdName: '05' },
  { type: 'month', month: '06', cdName: '06' },
  { type: 'month', month: '07', cdName: '07' },
  { type: 'month', month: '08', cdName: '08' },
  { type: 'month', month: '09', cdName: '09' },
  { type: 'month', month: '10', cdName: '10' },
  { type: 'month', month: '11', cdName: '11' },
  { type: 'month', month: '12', cdName: '12' },
];

export const hourList = Array.from({ length: 24 }, (_, i) => {
  const hourString = i.toString().padStart(2, '0'); // Pads the hour with a leading zero if it's less than 10
  return {
    type: 'hour',
    hour: hourString,
    cdName: hourString,
    cdSort: i + 101,
  };
});

export const hourList2 = Array.from({ length: 24 }, (_, i) => {
  const hourString = i.toString().padStart(2, '0'); // Pads the hour with a leading zero if it's less than 10
  return {
    label: hourString,
    value: hourString,
    cdSort: i,
  };
});

export const minList = Array.from({ length: 60 }, (_, i) => {
  const minString = i.toString().padStart(2, '0'); // Pads the hour with a leading zero if it's less than 10
  return {
    type: 'min',
    min: minString,
    cdName: minString,
    cdSort: i + 101,
  };
});

export const minList2 = Array.from({ length: 60 }, (_, i) => {
  const minString = i.toString().padStart(2, '0'); // Pads the hour with a leading zero if it's less than 10
  return {
    label: minString,
    value: minString,
    cdSort: i,
  };
});

export const elderlyAgeList = Array.from({ length: 30 }, (_, i) => {
  const elderlyAgeString = (i + 51).toString().padStart(2, '0'); // Pads the hour with a leading zero if it's less than 10
  return {
    type: 'elderlyAge',
    elderlyAge: elderlyAgeString,
    cdName: elderlyAgeString,
    cdSort: i + 101,
  };
});
const defaultObject = { type: 'elderlyAge', elderlyAge: '', cdName: '선택', cdSort: 0 };

export const elderlyAgeListWithDefault = [defaultObject, ...elderlyAgeList];

export const FLAG_NONE = 'N'; // 변화가 없을 때 플래그
export const FLAG_DELETE = 'D'; // 삭제 플래그
export const FLAG_CREATE_OR_UPDATE = 'Y'; // 신규 또는 수정 플래그

export const COMCD_MENU_GRANT = 'MENU_GRANT'; // 공통코드-메뉴화면 권한
export const COMCD_JONGMOK = 'JONGMOK'; // 공통코드-종목
export const COMCD_UPTAE = 'UPTAE'; // 공통코드-업태
export const COMCD_WORKERINPUT_SET = 'WORKERINPUT_SET'; // 공통코드-근로자 정보 입력
export const COMCD_S_STATUS = 'S_STATUS'; // 공통코드-현장상태
export const COMCD_USE_YN1 = 'USE_YN1'; // 공통코드-사용유무 미사용먼저
export const COMCD_S_VERSION = 'S_VERSION'; // 공통코드-현장 프로그램 버전
export const COMCD_USE_YN = 'USE_YN'; // 공통코드-사용유무
export const COMCD_LOCK_YN = 'LOCK_YN'; // 공통코드-잠금유무
export const COMCD_APPROVAL_YN = 'APPROVAL_YN'; // 공통코드-사용자 계정 승인여부
export const COMCD_REG_YN = 'REG_YN'; // 공통코드-승인여부
export const COMCD_INFRA = 'INFRA'; // 공통코드-건설사 인프라
export const LIST_COUNT = 'LIST_COUNT'; // 공통코드-검색줄수
export const NVR_GUBUN = 'NVR_GUBUN'; // 공통코드-NVR 제조사구분
export const BS_GUBUN = 'BS_GUBUN'; // 공통코드-스피커 제조사구분
export const USER_GRANT = 'USER_GRANT'; // 공통코드-사용자권한
export const DASHBOARD_GUBUN = 'DASHBOARD_GUBUN'; // 공통코드-대시보드 구분
export const COMCD_WORKER_GUBUN = 'WORKER_GUBUN'; // 공통코드-근로자 근로형태
export const COMCD_SENSOR_RANGE = 'SENSOR_RANGE'; // 센서구분 / 측정 범위용
export const COMCD_SENSOR_LIST = 'SENSOR_LIST'; // 공통코드-센서 목록
export const COMCD_EMERGENCY_GUBUN = 'EMERGENCY_GUBUN'; // 공통코드-긴급 상황 구분
export const COMCD_EMERGENCY_LEVEL = 'EMERGENCY_LEVEL'; // 공통코드-긴급 상황 LEVEL
export const COMCD_EMERGENCY_TYPE = 'EMERGENCY_TYPE'; // 공통코드-긴급 상황 유형
export const COMCD_STEMPLET_GUBUN = 'STEMPLET_GUBUN'; // 공통코드-스마트 안전교육 양식지 구분
export const PREJOBTYPE_EXCEL_FORM_URL = `https://itlogs3server.s3.ap-northeast-2.amazonaws.com/ProSafe2/set/Excel/prosafeExcelUpload(%EA%B3%B5%EC%A2%85%2B%EC%A7%81%EC%A2%85).xlsx`; // (공종+직종용)근로자 간편등록 엑셀 지정양식 URL
export const JOBTYPE_EXCEL_FORM_URL = `https://itlogs3server.s3.ap-northeast-2.amazonaws.com/ProSafe2/set/Excel/prosafeExcelUpload(%EC%A7%81%EC%A2%85).xlsx`; // (직종용)근로자 간편등록 엑셀 지정양식 URL
export const SAFE_DEFAULT_COLOR = '#000';
export const CCTV_VIEWER_PLUGIN_URL = `https://itlogs3server.s3.ap-northeast-2.amazonaws.com/ProSafe2/set/util/HCWebSDKPlugin.exe`; // WEB SDK 파일 다운로드 URL

export const UNAUTHORIZED = '토큰 만료 다시 로그인 하세요';
export const ERROR = '요청하신 작업을 완수하지 못했습니다 잠시 후 다시 시도하세요';
export const EMAIL = '이메일을 입력하세요';
export const EMAIL_TYPE = '이메일 형식을 확인하세요';
export const PASSWORD = '비밀번호를 입력하세요';
export const PASSWORD_TYPE = '최소 8자리 이상, 영어 대문자, 소문자, 숫자, 특수문자 중 3종류를 조합하여 주세요';
export const PASSWORD_WRONG = '비밀번호가 일치하지 않습니다';
export const MOBILE_PHONENUMBER = '휴대폰번호를 입력하세요';
export const MOBILE_PHONENUMBER_WRONG = '휴대폰번호를 확인하세요';
export const PHONENUMBER = '전화번호를 입력하세요';
export const PHONENUMBER_WRONG = '전화번호를 확인하세요';
export const ID = '아이디를 입력하세요';
export const ID_DUPLICATE = '아이디 중복검사를 완료하세요';
export const NAME = '성명을 입력하세요';
export const CHARACTER = '특수문자는 입력할 수 없습니다';
export const GRANT = '권한이 없습니다';

export const SENSOR_HINT: any = {
  NO2: { level1: '6 ppm - 20 ppm (예:18)', level2: '21ppm - 50 ppm (예:48)', level3: '50 ppm 초과 (예:51)' },
  CO: { level1: '31 ppm - 100 ppm (예:95)', level2: '101 ppm - 500 ppm (예:490)', level3: '500 ppm 초과 (예:501)' },
  CO2: { level1: '1001 ppm -2000 ppm (예:1500)', level2: '2001 ppm - 3000 ppm (예:2800)', level3: '3000 ppm 초과 (예:3001)' },
  H2S: { level1: '11 ppm - 20 ppm (예:15)', level2: '21 ppm - 50 ppm (예:40)', level3: '50 ppm 초과 (예:70)' },
  CH4: { level1: '5% - 7% (예:6)', level2: '8% - 10% (예:9)', level3: '10% 초과 (예:12)' },
};

export const INIT_USE_YN_A = { type: COMCD_USE_YN, [COMCD_USE_YN]: 'A', cdName: t('전체') };
export const INIT_USE_YN_Y = { type: COMCD_USE_YN, [COMCD_USE_YN]: 'Y', cdName: t('사용') };
export const INIT_LOCK_YN_A = { type: COMCD_LOCK_YN, [COMCD_LOCK_YN]: 'A', cdName: t('전체') };
export const INIT_LOCK_YN_N = { type: COMCD_LOCK_YN, [COMCD_LOCK_YN]: 'N', cdName: t('미잠금') };
export const INIT_APPROVAL_YN_A = { type: COMCD_APPROVAL_YN, [COMCD_APPROVAL_YN]: 'A', cdName: t('전체') };
export const INIT_NCD_A = { type: 'nCd', nCd: 'A', cdName: '전체' };
export const INIT_CCD_A = { type: 'cCd', cCd: 'A', cdName: '전체' };
export const INIT_EQUIP_A = { type: 'equip', equip: 'A', cdName: '전체' };
export const INIT_TATCD_A = { type: 'tatCd', tatCd: 'A', cdName: '전체' };
export const INIT_TJC_A = { type: 'tjcCd', tjcCd: 'A', cdName: '전체' };
export const INIT_TES_A = { type: 'tesCd', tesCd: 'A', cdName: '전체' };
export const INIT_WORKER_STATUS = { type: 'wWorkstatus', wWorkstatus: 'I', cdName: t('재직') };
export const INIT_WORKER_STRESS_RESULT = { type: 'stressResult', stressResult: 'A', cdName: t('전체') };
export const TAREA_INNERTAB_NORMAL = 0; // 굴진/일반 정보
export const TAREA_INNERTAB_ATTEND = 1; // 근로자 출역 정보
export const TAREA_INNERTAB_LOCATION = 2; // 근로자/장비 위치 정보
export const TAREA_INNERTAB_MGAS = 3; // 유해 가스 정보
export const TAREA_INNERTAB_CCTV = 4; // CCTV 정보
export const TAREA_INNERTAB_EQUIP = 5; // 방송 장비 정보
export const TAREA_INNERTAB_REALTIME_ATTEND = 6; // 실시간 잔류 현황
export const SITE_DASHBOARD = 'b';
export const TAREA_DASHBOARD = 't';
export const PTZDIRECTION = {
  UP: 1,
  DOWN: 2,
  LEFT: 3,
  RIGHT: 4,
  UPLEFT: 5,
  DOWNLEFT: 6,
  UPRIGHT: 7,
  DOWNRIGHT: 8,
  AUTO: 9,
};
export const CCTV_PLAY_BOTTOM_CONTROL_HEIGHT = 112; // CCTV 재생화면에서 하단 컨트롤바 높이
export const CCTV_REALTIME_BOTTOM_CONTROL_HEIGHT = 48; // CCTV 실시간보기에서 하단 컨트롤바 높이
export const CCTV_PLAY_SIDE_MENU_WIDTH = 336; // CCTV 재생화면에서 좌측메뉴 너비
export const INIT_CHECK_OPTION = [
  { key: 'workerInfoCheck', name: '근로자 정보', checked: false },
  { key: 'cCheck', name: '공통 양식지', checked: false },
  { key: 'jCheck', name: '직종별 양식지', checked: false },
  { key: 'muscularCheck', name: '근골격계 증상조사표', checked: false },
  { key: 'painCheck', name: '작업관련 통증 조사표', checked: false },
  { key: 'stressCheck', name: '직무스트레스 평가표', checked: false },
];
export const LANGUAGE_TYPES = {
  K: '한국어',
  E: '영어',
  C: '중국어(간체)',
  V: '베트남어',
  T: '태국어',
  M: '미얀마어',
  G: '캄보디아어',
};
