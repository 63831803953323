/**
 * 작성자 : 한영광
 * 날짜 : 2023.05.24
 * 기능 : 본사사용자관리-사용자정보-사용자정보 페이지
 */

import { IModal } from 'customTypes';
import { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import { useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';

import { userState } from '../../../atoms';
import { COMCD_LOCK_YN, COMCD_USE_YN, ERROR, USER_GRANT } from '../../../_constants';
import UserInfoInputFormHead from '../../../components/Form/UserInfoInputFormHead';
import { checkPhonNumEmpty } from '../../../utils/checkPhonNumEmpty';
import { emailRegex } from '../../../utils/checkRegex';
import { checkUserInputRequiredValue } from '../../../utils/checkUserInputRequiredValue';
import { trimObject } from '../../../utils/trimObject';
import { registerTempPwd } from '../../../services/register';
import { logPost } from '../../../services/log';
import { useTranslation } from 'react-i18next';
import Portal from '../../../components/Portal';
import DeleteModal from '../../../components/Modal/DeleteModal2';
import { apiDelete, apiPatch, apiPost } from '../../../services/_common';

interface Iprops {
  orgUserInfo: any;
  setOrgUserInfo: Dispatch<SetStateAction<any>>;
  applyFilter: (array: any[]) => void;
  orgUserList: any[];
  setOrgUserList: Dispatch<SetStateAction<any>>;
  getUserHeadAPI: () => void;
  lockYnComCdList: any;
  useYnComCdList: any;
  userGrantComCdList: any;
  initBtnClick: any;
  setInitBtnClick: Dispatch<SetStateAction<any>>;
  addBtnClick: boolean;
  setSelectedRowKey: Dispatch<SetStateAction<any>>;
  hpNumState: any;
  telNumState: any;
  ipInfoState: any;
  setHpNumState: Dispatch<SetStateAction<any>>;
  setTelNumState: Dispatch<SetStateAction<any>>;
  setIpInfoState: Dispatch<SetStateAction<any>>;
  useYn: any;
  lockYn: any;
  setUseYn: Dispatch<SetStateAction<any>>;
  setLockYn: Dispatch<SetStateAction<any>>;
}
const UserInfo = ({
  orgUserInfo,
  setOrgUserInfo,
  applyFilter,
  orgUserList,
  setOrgUserList,
  getUserHeadAPI,
  lockYnComCdList,
  useYnComCdList,
  userGrantComCdList,
  initBtnClick,
  setInitBtnClick,
  addBtnClick,
  setSelectedRowKey,
  hpNumState,
  telNumState,
  ipInfoState,
  setHpNumState,
  setTelNumState,
  setIpInfoState,
  useYn,
  lockYn,
  setUseYn,
  setLockYn,
}: Iprops) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const userIdRef = useRef<HTMLInputElement>(null);
  const userNameRef = useRef<HTMLInputElement>(null);
  const eMailRef = useRef<HTMLInputElement>(null);

  const [rowState, setRowState] = useState({
    userId: '',
    userName: '',
    hpNum: '',
    telNum: '',
    eMail: '',
    ipInfo: '',
    bigo: '',
    eDate: '',
    editor: '',
    lockYn: 'N',
    useYn: 'Y',
    approvalYn: '',
    userPwd: '',
    wDate: '',
    writer: '',
    loginerrorCount: '',
    isDuplicateChecked: false,
    gCd: '',
  }); // 서버에서 받아 온 raw data 어레이 중 1줄의 데이터 state
  const [isNewAdd, setIsNewAdd] = useState(true); // 신규등록 여부
  const userInfoInputFormRef = useRef<any>(null); // 인풋 폼 ref (테이블 로우를 클릭했을 때 바로 inputForm 으로 스크롤 이동시키기 위한 ref)
  const [gCd, setGCd] = useState({ type: USER_GRANT, [USER_GRANT]: '', cdName: '' });
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });

  useEffect(() => {
    if (orgUserInfo.length !== 0) {
      const newUserInfo = orgUserList.filter((v) => v.userId === orgUserInfo.userId)[0];
      if (newUserInfo) {
        setIsNewAdd(false);
        setRowState({ isDuplicateChecked: true, ...newUserInfo });
        setGCd({ type: USER_GRANT, [USER_GRANT]: newUserInfo.gCd, cdName: newUserInfo.gName });
      }
    }
  }, [orgUserList]);

  useEffect(() => {
    if (orgUserInfo.length !== 0) {
      setIsNewAdd(false);
      setRowState({ isDuplicateChecked: true, ...orgUserInfo });
      setLockYn({ type: COMCD_LOCK_YN, [COMCD_LOCK_YN]: orgUserInfo.lockYn });
      setUseYn({ type: COMCD_USE_YN, [COMCD_USE_YN]: orgUserInfo.useYn });
      setGCd({ type: USER_GRANT, [USER_GRANT]: orgUserInfo.gCd, cdName: orgUserInfo.gName });
    }
  }, [orgUserInfo]);

  useEffect(() => {
    if (!isNewAdd) {
      setRowState({ ...rowState, isDuplicateChecked: true });
    }
  }, [isNewAdd]);

  useEffect(() => {
    if (initBtnClick) {
      onClickNewRegistration();
      setInitBtnClick(false);
    }
  }, [initBtnClick]);

  useEffect(() => {
    if (addBtnClick) {
      setIsNewAdd(true);
      initiateState();
    }
  }, [addBtnClick]);

  const onClickNewRegistration = () => {
    setIsNewAdd(true);
    setSelectedRowKey(null);
    initiateState();
  };

  // 스테이트 객체의 밸류를 ''로 초기화
  const initiateState = () => {
    setOrgUserInfo([]);
    Object.keys(rowState).map((el: any) => {
      return setRowState((prev: any) => ({ ...prev, [el]: '', isDuplicateChecked: false, useYn: 'Y', lockYn: 'N' }));
    });
    setIsSaveClicked(false);
  };

  // 신규등록 API
  const insertNewUserAPI = async (resultData: any) => {
    const trimData = trimObject(resultData);
    const req = {
      userId: trimData.userId,
      userName: trimData.userName,
      userPwd: trimData.newPassword,
      gCd: trimData.gCd,
      hCd: trimData.hCd,
      sCd: trimData.sCd,
      hpNum: trimData.hpNum,
      telNum: trimData.telNum,
      eMail: trimData.eMail,
      ipInfo: trimData.ipInfo,
      pwdchangeDate: trimData.pwdchangeDate,
      useYn: trimData.useYn,
      lockYn: trimData.lockYn,
      bigo: trimData.bigo,
      approvalYn: 'Y',
      pwdCheckyn: 'Y',
      loginerrorCount: 0,
      writer: userInfo.userId,
      editor: userInfo.userId,
    };
    const res = await apiPost({ path: '/user/head', contentType: 'application/json', req });
    const { statusCode, message, data } = res.data;
    if (statusCode === 200) {
      const filterUsers = data[1].userList.filter((el: any) => el.hCd === userInfo.hCd && el.sCd === userInfo.sCd);
      setRowState({ ...data[0] });
      setSelectedRowKey(0);
      applyFilter(filterUsers);
      setOrgUserList(filterUsers);
      setOrgUserInfo({ ...data[0] });
      setIsNewAdd(false);
      toast.success(t(message));

      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '사용자 정보 / 메뉴 관리',
        action: '신규등록',
        etc: `${resultData.userName}(${resultData.userId})`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 수정 API
  const updateUserAPI = async (resultData: any) => {
    const trimData = trimObject(resultData);
    const req = {
      userId: trimData.userId,
      userName: trimData.userName,
      userPwd: trimData.userPwd,
      gCd: trimData.gCd,
      hCd: trimData.hCd,
      sCd: trimData.sCd,
      hpNum: trimData.hpNum,
      telNum: trimData.telNum,
      eMail: trimData.eMail,
      ipInfo: trimData.ipInfo,
      pwdchangeDate: trimData.pwdchangeDate,
      useYn: trimData.useYn,
      lockYn: trimData.lockYn,
      bigo: trimData.bigo,
      approvalYn: trimData.approvalYn,
      pwdCheckyn: trimData.pwdCheckyn,
      loginerrorCount: trimData.loginerrorCount,
      editor: userInfo.userId,
    };
    const res = await apiPatch({ path: '/user/head', contentType: 'application/json', req });
    const { statusCode, message, data } = res.data;
    if (statusCode === 200) {
      const filterUsers = data[1].userList.filter((el: any) => el.hCd === userInfo.hCd && el.sCd === userInfo.sCd);
      setRowState({ ...data[0] });
      applyFilter(filterUsers);
      setOrgUserList(filterUsers);
      setOrgUserInfo(resultData);
      toast.success(t(message));

      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '사용자 정보 / 메뉴 관리',
        action: '수정',
        etc: `${resultData.userName}(${resultData.userId})`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 승인 API
  const updateUserApprovalAPI = async (req: any) => {
    const res = await apiPatch({ path: '/user/approval', req });
    const { statusCode, message, data } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      const { userPwd, wDate, writer, eDate, editor, approvalYn } = data;
      setRowState((prev) => ({
        ...prev,
        writer,
        wDate,
        editor,
        eDate,
        approvalYn,
        userPwd,
      }));
      const newUserList = orgUserList.map((v: any) => {
        if (v.userId === data.userId) {
          return data;
        }
        return v;
      });
      applyFilter(newUserList);
      setOrgUserList(newUserList);
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '사용자 정보 / 메뉴 관리',
        action: '승인',
        etc: `${rowState.userName}(${rowState.userId})`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 저장버튼 클릭해서 save API 호출하여 데이터전송
  const onClickSave = async () => {
    setIsSaveClicked(true);
    const mergedHpNumber = `${hpNumState.num1}-${hpNumState.num2}-${hpNumState.num3}`;
    const mergedTelNumber = telNumState.num1 === '' && telNumState.num2 === '' && telNumState.num3 === '' ? '' : `${telNumState.num1}-${telNumState.num2}-${telNumState.num3}`;
    const mergedIpNumber =
      ipInfoState.num1 === '' && ipInfoState.num2 === '' && ipInfoState.num3 === '' && ipInfoState.num4 === '' ? '' : `${ipInfoState.num1}.${ipInfoState.num2}.${ipInfoState.num3}.${ipInfoState.num4}`;
    const { eMail } = rowState;
    const { isUserInputRequiredValuePass, userInputRequiredValueMsg, info } = checkUserInputRequiredValue(rowState);
    const { isEmpty, emptyAlertMessage } = checkPhonNumEmpty(hpNumState);
    const { isEmailRegexPass, emailRegexAlertMsg } = emailRegex(eMail);
    if (gCd[USER_GRANT] === '') return toast.warning(t('권한을 선택하세요'));
    if (!isUserInputRequiredValuePass) {
      if (info === 'userId') {
        userIdRef.current?.focus();
      } else if (info === 'userName') {
        userNameRef.current?.focus();
      }
      return toast.warning(t(userInputRequiredValueMsg));
    }
    if (isEmpty) return toast.warning(t(emptyAlertMessage));
    if (!isEmailRegexPass) {
      eMailRef.current?.focus();
      return toast.warning(t(emailRegexAlertMsg));
    }

    // ! sCd: '00000' 고정값
    const resultData = { ...rowState, hpNum: mergedHpNumber, telNum: mergedTelNumber, ipInfo: mergedIpNumber, gCd: gCd[USER_GRANT], sCd: '00000', hCd: userInfo.hCd };

    if (isNewAdd) return insertNewUserAPI(resultData);
    return updateUserAPI(resultData);
  };

  // 승인 버튼 클릭
  const onClickApproval = async () => {
    const reqData = {
      userId: rowState.userId,
      editor: userInfo.userId,
      gCd: 'H',
    };
    updateUserApprovalAPI(reqData);
  };

  // 비밀번호 초기화 요청 API
  const initPasswordAPI = async () => {
    const res = await registerTempPwd(rowState.userId, rowState.eMail);
    const { statusCode, message, data } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      getUserHeadAPI();
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 비밀번호 초기화 버튼 클릭
  const onClickInitPwd = () => {
    setOpenModal((prev) => ({ ...prev, status: true, type: 'initPw', api: initPasswordAPI }));
  };

  const onClickDelete = async () => {
    setOpenModal((prev) => ({ ...prev, status: true, type: 'delete', api: deleteAPI }));
  };

  // 삭제 API
  const deleteAPI = async () => {
    const req = { userId: rowState.userId, editor: userInfo.userId };
    const res = await apiDelete({ path: '/user/head', req });
    const { message, statusCode, data } = res.data;
    if (statusCode === 200) {
      const filterUsers = data[1].userList.filter((el: any) => el.hCd === userInfo.hCd && el.sCd === userInfo.sCd);
      toast.success(t(message));
      applyFilter(filterUsers);
      setOrgUserList(filterUsers);
      initiateState();
      setIsNewAdd(true);
      setSelectedRowKey(null);

      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '사용자 정보 / 메뉴 관리',
        action: '삭제',
        etc: `${rowState.userName}(${rowState.userId})`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  return (
    <>
      <UserInfoInputFormHead
        style={{ width: '100%' }}
        state={rowState}
        setState={setRowState}
        isNewAdd={isNewAdd}
        userInfoInputFormRef={userInfoInputFormRef}
        onClickNewAdd={onClickNewRegistration}
        onClickSave={onClickSave}
        onClickApproval={onClickApproval}
        onClickDelete={onClickDelete}
        onClickInitPwd={onClickInitPwd}
        // typeName={t('사용자')}
        telNumState={telNumState}
        setTelNumState={setTelNumState}
        hpNumState={hpNumState}
        setHpNumState={setHpNumState}
        ipInfoState={ipInfoState}
        setIpInfoState={setIpInfoState}
        gCd={gCd}
        setGCd={setGCd}
        lockYn={lockYn}
        useYn={useYn}
        setUseYn={setUseYn}
        setLockYn={setLockYn}
        lockYnComCdList={lockYnComCdList}
        useYnComCdList={useYnComCdList}
        userGrantComCdList={userGrantComCdList}
        userIdRef={userIdRef}
        userNameRef={userNameRef}
        eMailRef={eMailRef}
        isSaveClicked={isSaveClicked}
      />
      <Portal openModal={openModal?.status}>{openModal && (openModal.type === 'delete' || openModal.type === 'initPw') && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
    </>
  );
};

export default UserInfo;
