import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import JobtypeChip from './JobtypeChip';
import { useEffect, useState } from 'react';
import { findJobTypeName, flattenSubLists, useGetJobTypeLists } from '../../../hooks/useJobType';
import { PulseLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { userState } from '../../../atoms';

const Root = styled.div`
  user-select: none;
  display: flex;
  align-items: stretch;
  border-radius: 0.25rem;
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.tonal_deep};
  height: 3rem;
  .name {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    padding: 0 0.75rem;
    min-width: 4.375rem;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .button {
    flex-shrink: 0;
    user-select: none;
    cursor: pointer;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }: { theme: any }) => theme.filled_slate_deep};
    color: ${({ theme }: { theme: any }) => theme.bw_inverse};
    border-radius: 0.25rem;
    padding: 0 0.75rem;
    margin: 0.5rem;
    font-size: 0.875rem;
    font-weight: 400;
    &:hover {
      outline: 0.25rem solid ${({ theme }: { theme: any }) => theme.outline};
      &:active {
        background-color: ${({ theme }: { theme: any }) => theme.filled_slate};
      }
    }
  }
  .scroll-container {
    /* padding-top: 0.75rem; */
    flex-grow: 1;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    /* overflow-x: scroll; */
    overflow-y: hidden;
    white-space: nowrap;
  }
  .checklist {
    flex-grow: 1;
    margin: 0.5rem 0;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
    padding: 0 0.375rem;
    .material-symbols-rounded {
      font-size: 1.375rem;
      color: ${({ theme }: { theme: any }) => theme.filled_green};
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.75rem;
      width: 1.75rem;
      border-radius: 0.25rem;
    }
    .description {
      font-size: 0.875rem;
      color: ${({ theme }: { theme: any }) => theme.filled_green_deep};
    }
  }
`;

interface Props {
  jobtypeIdx: string;
  setJobtypeIdx: (jobtypeIdx: string) => void;
  onClickJobtypeSelectModal: () => void;
}

const SelectJobtypeBar = ({ jobtypeIdx, setJobtypeIdx, onClickJobtypeSelectModal }: Props) => {
  const { t } = useTranslation();
  const { prejobtypeYn } = useRecoilValue(userState);
  const { data: jobTypeLists, isLoading, isError } = useGetJobTypeLists(prejobtypeYn as 'Y' | 'N');
  const [flattenSubList, setFlattenSubList] = useState<JobtypeList[]>([]);
  const flattenList = prejobtypeYn === 'Y' ? flattenSubList : jobTypeLists;

  useEffect(() => {
    if (jobTypeLists && prejobtypeYn === 'Y') {
      setFlattenSubList(flattenSubLists(jobTypeLists));
    }
  }, [jobTypeLists, prejobtypeYn]);

  const removeJobtype = (item: string) => {
    // 문자열을 배열로 변환
    const array = jobtypeIdx.split(',');
    // 배열에서 특정 요소 제거
    const filteredArray = array.filter((element) => element !== item);
    // 배열을 다시 문자열로 변환
    const resultString = filteredArray.join(',');

    setJobtypeIdx(resultString);
  };

  if (isLoading) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <Root>
      <div className='name'>{t('직종')}</div>
      <div //
        className='button'
        onClick={onClickJobtypeSelectModal}
        role='button'
        tabIndex={0}
      >
        {t('선택하기')}
      </div>
      {jobtypeIdx ? (
        <div className='scroll-container'>
          {jobtypeIdx.split(',').map((selectedItem: string) => (
            <JobtypeChip //
              key={selectedItem}
              selectedItem={selectedItem}
              removeJobtype={removeJobtype}
              name={findJobTypeName(selectedItem, flattenList) ?? selectedItem}
            />
          ))}
        </div>
      ) : (
        <div className='checklist'>
          <span className='material-symbols-rounded'>check</span>
          <span className='description'>{t('다중선택 가능')}</span>
        </div>
      )}
    </Root>
  );
};

export default SelectJobtypeBar;
