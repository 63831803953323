import styled from 'styled-components';
import InfoTextWithIcon from '../../../components/Modal/InfoTextWithIcon';
import { useTranslation } from 'react-i18next';
import RadioContainer from '../../../components/button/RadioContainer';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSetAuth } from '../../../utils/useSetAuth';
import SelectJobtypeBar from './SelectJobtypeBar';
import Portal from '../../../components/Portal';
import JobTypeModal from './JobTypeModal';
import VideoSelect from '../../../components/VideoSelect';
import { toast } from 'react-toastify';
import { IUser, userState } from '../../../atoms';
import { useRecoilValue } from 'recoil';
import { apiPost } from '../../../services/_common';
import { getFileNameFromURL } from '../../../utils/formatFilePath';
import { formatDate } from '../../../utils/formatDate';
import { BtnBlue, BtnRed } from '../../../components/Button';
import DetailsLog from './DetailsLog';
import DeleteModal from '../../../components/Modal/DeleteModal2';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  padding: 0.5rem;
  > div {
    gap: 0.5rem;
  }
  justify-content: flex-end;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;

const Root = styled.div`
  max-width: none !important;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  justify-content: flex-start;
  .header {
    padding: 0.75rem;
    height: 3rem;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    .title {
      flex-shrink: 0;
      width: fit-content;
      font-weight: 600;
      margin-right: 0.5rem;
    }
  }
  .video-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    flex-grow: 1;
  }
  .detailInfo-group {
    border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    padding: 1.5rem 0;
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem 4rem;
  }
  .seperator {
    margin: 0.5rem 0;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    div {
      height: 1px;
      flex-grow: 1;
      background-color: rgba(0, 0, 0, 0.1);
    }
    span {
      border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    }
  }

  .inputForm {
    border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem;
    width: 100%;
    height: 3.5rem;
    background-color: transparent;
    > div {
      justify-content: space-between;
      height: inherit;
    }
    button {
      height: 2.5rem;
      font-size: 0.75rem;
      flex-shrink: 0;
      padding: 0 0.75rem;
      border-radius: 0.25rem;
    }
    label {
      width: 10rem;
      font-weight: 500;
      flex-shrink: 0;
      font-size: 0.875rem;
    }
    .inputForm-row.labelOutInput {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
    }
  }
  .scrollBox {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    height: 100%;
    flex-grow: 1;
  }
`;

const INIT_VIDEO = {
  ko: { isEnrolled: false, path: null, fileName: null, file: null }, // 한국어
  en: { isEnrolled: false, path: null, fileName: null, file: null }, // 영어
  vi: { isEnrolled: false, path: null, fileName: null, file: null }, // 베트남어
  zh: { isEnrolled: false, path: null, fileName: null, file: null }, // 중국어
  th: { isEnrolled: false, path: null, fileName: null, file: null }, // 태국어
  my: { isEnrolled: false, path: null, fileName: null, file: null }, // 미얀마어
  km: { isEnrolled: false, path: null, fileName: null, file: null }, // 캄보디아어
};

const videos = [
  { caption: '한국어', vType: 'ko' },
  { caption: '영어', vType: 'en' },
  { caption: '베트남어', vType: 'vi' },
  { caption: '중국어', vType: 'zh' },
  { caption: '태국어', vType: 'th' },
  { caption: '미얀마어', vType: 'my' },
  { caption: '캄보디아어', vType: 'km' },
];

interface Props {
  data: any;
  sType: string;
  selectedIndex: number | null;
  refetch: () => void;
  uploading: boolean;
  setUploading: Dispatch<SetStateAction<boolean>>;
}

const VideoDetails = ({ data, sType, selectedIndex, refetch, uploading, setUploading }: Props) => {
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const userInfo = useRecoilValue<IUser>(userState);

  const [useYn, setUseYn] = useState<'N' | 'Y'>('N');
  const [jobtypeIdx, setJobtypeIdx] = useState<string>('');

  const INIT_ROW_STATE = { sTitle: '', sSeq: null, video: INIT_VIDEO, delYn: 'N' };
  const [rowState, setRowState] = useState<any>(INIT_ROW_STATE);

  const [openModal, setOpenModal] = useState<{ status: boolean; type: string }>({
    status: false,
    type: 'confirm',
  });

  // 목록에서 선택한 행을 rowState에 적용
  useEffect(() => {
    onClickInit();
  }, [selectedIndex, data]);

  // 상세화면 초기화 함수
  const onClickInit = async () => {
    if (selectedIndex !== null && selectedIndex !== undefined && data[selectedIndex]) {
      const {
        sTitle,
        sSeq,
        delYn,
        wDate,
        writer,
        eDate,
        editor,
        sMovK,
        sMovE,
        sMovV,
        sMovC,
        sMovT,
        sMovM,
        sMovG,
        sMovKUpload,
        sMovEUpload,
        sMovCUpload,
        sMovVUpload,
        sMovTUpload,
        sMovMUpload,
        sMovGUpload,
      } = data[selectedIndex];
      if (sType === '2') setJobtypeIdx(data[selectedIndex].jobtypeIdx);
      setUseYn(data[selectedIndex].useYn);
      setRowState({
        sSeq,
        sTitle,
        video: {
          ko: { isEnrolled: !!sMovK, path: sMovK, fileName: getFileNameFromURL(sMovK), file: null, sMovUpload: sMovKUpload }, // 한국어
          en: { isEnrolled: !!sMovE, path: sMovE, fileName: getFileNameFromURL(sMovE), file: null, sMovUpload: sMovEUpload }, // 영어
          vi: { isEnrolled: !!sMovV, path: sMovV, fileName: getFileNameFromURL(sMovV), file: null, sMovUpload: sMovVUpload }, // 베트남어
          zh: { isEnrolled: !!sMovC, path: sMovC, fileName: getFileNameFromURL(sMovC), file: null, sMovUpload: sMovCUpload }, // 중국어
          th: { isEnrolled: !!sMovT, path: sMovT, fileName: getFileNameFromURL(sMovT), file: null, sMovUpload: sMovTUpload }, // 태국어
          my: { isEnrolled: !!sMovM, path: sMovM, fileName: getFileNameFromURL(sMovM), file: null, sMovUpload: sMovMUpload }, // 미얀마어
          km: { isEnrolled: !!sMovG, path: sMovG, fileName: getFileNameFromURL(sMovG), file: null, sMovUpload: sMovGUpload }, // 캄보디아어
        },
        delYn,
        wDate: formatDate(wDate),
        writer,
        eDate: formatDate(eDate),
        editor,
      });
    } else {
      setUseYn('N');
      setJobtypeIdx('');
      setRowState(INIT_ROW_STATE);
    }
  };

  // 안전교육 제목 onChange 함수
  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length > 50) return;
    setRowState((prev: any) => ({ ...prev, sTitle: value }));
  };

  const onClickJobtypeSelectModal = () => {
    setOpenModal({ status: true, type: 'jobtype' });
  };

  const onClickDelete = () => {
    setOpenModal((prev) => ({ ...prev, status: true, type: 'delete', api: () => onClickSave(true) }));
  };

  const videofilePostAPI = async (req: any, isDelete: boolean) => {
    const res = await apiPost({ path: '/tablet/videofile', contentType: 'multipart/form-data', req });
    try {
      if (res.data.statusCode === 200) {
        const { sSeq: resSeq, writer: resWriter, editor: resEditor, wDate: resWdate, eDate: resEdate } = res.data.data;
        if (!isDelete) {
          // setIsNewAdd(false);
          setRowState((prev: any) => ({ ...prev, sSeq: resSeq, writer: resWriter, editor: resEditor, wDate: resWdate, eDate: resEdate }));
          toast.success(t(res.data.message));
        } else {
          // setIsNewAdd(true);
          setRowState(INIT_ROW_STATE);
          toast.success(t('삭제 성공'));
        }
      }
    } catch (error) {
      console.error(error);
      throw new Error('error');
    } finally {
      setUploading(false);
      refetch();
    }
  };

  // 기존에 사용중인 안전교육이 있는 지 확인
  const checkArrayYnExist = (sSeq: number) => {
    const findUseY = data.find((el: JobtypeVideoRow) => el.useYn === 'Y' && el.sSeq !== sSeq);
    if (findUseY) return true;
    return false;
  };

  const onClickSave = (isDelete: boolean) => {
    const { hCd, sCd, userId } = userInfo;
    const { sSeq, sTitle, delYn, writer, video } = rowState;
    // setIsSaveClicked(true);
    if (sTitle?.trim() === '') return toast.warning(t('필수입력값을 모두 입력하세요'));
    // if (useYn[COMCD_USE_YN] === 'Y' && checkArrayYnExist(sSeq)) return toast.warning(t('이미 사용중인 안전교육이 있습니다.'));
    if (sType === '2' && jobtypeIdx === '') return toast.warning(t('직종을 선택하세요'));
    if (!video.ko.file && !video.ko.path && !isDelete) return toast.warning(t('한국어 비디오를 등록하세요'));
    setUploading(true);

    const req = {
      hCd,
      sCd,
      sSeq,
      sType,
      jobtypeIdx,
      sTitle: sTitle?.trim(),
      useYn,
      delYn: isDelete ? 'Y' : delYn,
      writer: writer === undefined ? userInfo.userId : writer,
      editor: userId,
      sMovK: video.ko.file ? video.ko.file : video.ko.path,
      sMovE: video.en.file ? video.en.file : video.en.path,
      sMovC: video.zh.file ? video.zh.file : video.zh.path,
      sMovV: video.vi.file ? video.vi.file : video.vi.path,
      sMovT: video.th.file ? video.th.file : video.th.path,
      sMovM: video.my.file ? video.my.file : video.my.path,
      sMovG: video.km.file ? video.km.file : video.km.path,
      sMovKUpload: video.ko.sMovUpload,
      sMovEUpload: video.en.sMovUpload,
      sMovCUpload: video.zh.sMovUpload,
      sMovVUpload: video.vi.sMovUpload,
      sMovTUpload: video.th.sMovUpload,
      sMovMUpload: video.my.sMovUpload,
      sMovGUpload: video.km.sMovUpload,
    };
    return videofilePostAPI(req, isDelete);
  };

  const onClickDeleteVideo = (vTypeParam: string) => {
    setRowState((prev: any) => ({ ...prev, video: { ...prev.video, [vTypeParam]: { isEnrolled: false, fileName: null, file: null, path: null } } }));
  };

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>, vTypeParam: string) => {
    event.preventDefault();
    const fileList = event.target.files;

    if (fileList) {
      const file = fileList[0];
      const fileSize = file.size;
      // 20MB in Bytes (20 * 1024 * 1024)
      if (fileSize > 52428800) {
        toast.warning(`${t('파일크기는 50MB를 초과할 수 없습니다')} (${Math.ceil(fileSize / 1024 / 1024)} / 50MB)`);
      } else {
        setRowState((prev: any) => ({ ...prev, video: { ...prev.video, [vTypeParam]: { isEnrolled: true, fileName: file.name, file, path: null } } }));
      }
      event.target.value = '';
    }
  };

  const logs = [
    { name: '등록일자', value: rowState.wDate }, //
    { name: '등록자', value: rowState.writer },
    { name: '수정일자', value: rowState.eDate },
    { name: '수정자', value: rowState.editor },
  ];

  return (
    <Root>
      <div className='header'>
        <span className='title'>{t('안전교육 제목')}</span>
        <input
          type='text' //
          id='sTitle'
          name='sTitle'
          value={rowState.sTitle}
          onChange={(e) => onChangeName(e)}
          disabled={!auth.createAuth && !auth.updateAuth}
          maxLength={50}
        />
        <RadioContainer
          name={t('사용')} //
          onClick={() => setUseYn('Y')}
          isSelected={useYn === 'Y'}
        />
        <RadioContainer
          name={t('미사용')} //
          onClick={() => setUseYn('N')}
          isSelected={useYn === 'N'}
        />
      </div>
      {sType === '2' && (
        <SelectJobtypeBar //
          jobtypeIdx={jobtypeIdx}
          setJobtypeIdx={setJobtypeIdx}
          onClickJobtypeSelectModal={onClickJobtypeSelectModal}
        />
      )}
      <InfoTextWithIcon
        icon='smart_display' //
        text={t('동영상 확장자는 MP4만 가능합니다')}
      />
      <div className='video-container'>
        {videos.map((el) => (
          <VideoSelect
            key={el.vType}
            caption={el.caption}
            vType={el.vType}
            file={rowState.video[el.vType].file}
            filePath={rowState.video[el.vType].path}
            fileName={rowState.video[el.vType].fileName}
            isEnrolled={rowState.video[el.vType].isEnrolled}
            handleFileInput={(e: React.ChangeEvent<HTMLInputElement>) => handleFileInputChange(e, el.vType)}
            onClickDeleteVideo={() => onClickDeleteVideo(el.vType)}
          />
        ))}
        {selectedIndex !== null ? (
          <div className='detailInfo-group'>
            {logs.map((log) => (
              <DetailsLog
                key={log.name} //
                name={log.name} //
                value={log.value}
              />
            ))}
          </div>
        ) : null}
      </div>
      <ButtonsWrapper className='flex-between'>
        <div className='flex-basic'>
          {selectedIndex !== null && auth.deleteAuth && <BtnRed onClick={onClickDelete}>{t('삭제')}</BtnRed>}
          {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={() => onClickSave(false)}>{uploading ? t('저장중...') : t('저장')}</BtnBlue>}
        </div>
      </ButtonsWrapper>
      <Portal openModal={openModal?.status}>
        {openModal.status && openModal.type === 'jobtype' && (
          <JobTypeModal
            setSelectedProps={setJobtypeIdx} //
            setOpenModal={setOpenModal}
            selectedProps={jobtypeIdx}
          />
        )}
        {openModal.status && openModal.type === 'delete' && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}
      </Portal>
    </Root>
  );
};

export default VideoDetails;
