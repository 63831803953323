/**
 * 작성자 : 김광민
 * 날짜 : 2023.12.21
 * 경로 : 대시보드 - 굴진 진행현황 1*1
 */

import styled from 'styled-components';
import { Tbody } from '../../assets/styles/TunnelTable';
import { useTranslation } from 'react-i18next';
import TunnelTdProgressBar from '../TunnelTdProgressbar';
import PointIcon from '../tunnel/PointIcon';
import IssueGuide from '../IssueGuide';

const Root = styled.table`
  display: flex;
  flex-direction: column;
  overflow: scroll auto;
  width: 100%;
  height: fit-content;
  flex-grow: 1;
  user-select: none;
  padding: 0 0.5rem;
  overflow: hidden auto;
  padding-bottom: 0.5rem;
  tr {
    flex-grow: 1;
  }
  td {
    height: 2.75rem !important;
  }
  .tdGrow {
    flex-grow: 1;
  }
  .td10 {
    width: 10rem;
  }
  .td12 {
    width: 12rem;
    padding-left: 0.5rem;
    padding-right: 0;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-end {
    justify-content: flex-end;
  }
  .td {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .widgetLength {
      display: flex;
      align-items: center;
      font-size: 0.75rem;
      padding: 0 0.5rem;
      border-radius: 1rem;
      font-weight: 400;
      &.start {
        color: ${({ theme }: { theme: any }) => theme.filled_blue};
        background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
      }
      &.end {
        color: ${({ theme }: { theme: any }) => theme.filled_amber};
        background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
      }
      .material-symbols-rounded {
        font-size: 1.25rem;
      }
      .emphasize {
        margin-right: 0.125rem;
        font-weight: 500;
        width: 2.5rem;
        text-align: right;
      }
    }
  }
`;

const TunnelProgressBar = ({ data }: { data: any }) => {
  const { t } = useTranslation();

  if (data?.length === 0) return <IssueGuide illustrator={{ visible: false }} />;
  return (
    <Root>
      <Tbody>
        {data.map((barData: any) => {
          const isStart = barData.side === 'start';
          return (
            <tr className='progressBarSm' key={`${barData.tatName}_${barData.side}`}>
              <td className='td12 td'>
                <span className='name'>{t(barData.tatName)}</span>
                <span className={`widgetLength ${isStart ? 'start' : 'end'}`}>
                  {isStart ? <PointIcon isStart classNameOfStyle='start' /> : <PointIcon isStart={false} classNameOfStyle='end' />}
                  <span className='emphasize'>{barData.length.toLocaleString()}</span>m
                </span>
              </td>
              <td className='tdGrow'>
                <TunnelTdProgressBar rlength={Number(barData.rLength)} length={Number(barData.length)} size='small' />
              </td>
            </tr>
          );
        })}
      </Tbody>
    </Root>
  );
};

export default TunnelProgressBar;
