import styled from 'styled-components';
import TimelineSubInterval from './TimelineSubInterval';
import { formatNumber } from '../../../../utils/timeline';
import React from 'react';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  height: 100%;
  &:first-child .main-interval {
    border-left-width: 2px;
  }
  &:last-child {
    .end {
      display: flex !important;
      right: 0;
      left: initial;
      transform: translateX(50%);
    }
    .main-interval {
      border-right-width: 2px;
    }
  }
  .time {
    user-select: none;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-50%);
    color: ${({ theme }: { theme: any }) => theme.color.zinc_400};
    font-size: 0.875rem;
    &.end {
      display: none;
    }
  }
  .main-interval {
    height: 1.5rem;
    background-color: ${({ theme }: { theme: any }) => theme.color.zinc_800};
    border-left: 1px solid ${({ theme }: { theme: any }) => theme.color.zinc_400};
    border-right: 1px solid ${({ theme }: { theme: any }) => theme.color.zinc_400};
    display: flex;
    align-items: flex-end;
  }
`;

interface Props {
  interval: number;
  recordData: Playback[];
  index: number;
}

const TimelineContainer = React.memo(({ recordData, interval, index: timelineIndex }: Props) => {
  const startTime = timelineIndex * interval;
  const hours = Math.floor(startTime / 60);
  const minutes = Math.floor(startTime % 60);
  const timeString = `${formatNumber(hours)}:${formatNumber(minutes)}`;

  return (
    <Root key={startTime} className='timeline-bar'>
      <span className='time'>{timeString}</span>
      <div className='main-interval'>
        {Array.from({ length: 6 }, (_, index) => (
          <TimelineSubInterval //
            key={index}
            start={startTime + index * (interval / 6)}
            end={startTime + (index + 1) * (interval / 6)}
            recordData={recordData}
          />
        ))}
      </div>
      <span className='time end'>24:00</span>
    </Root>
  );
});

TimelineContainer.displayName = 'TimelineContainer';

export default TimelineContainer;
