import { useTranslation } from 'react-i18next';
import { Modal, ModalBackground } from '../../../assets/styles/Modal';
import { BtnGhost, BtnRed } from '../../../components/Button';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  openModal: { status: boolean; value: string };
  setOpenModal: Dispatch<SetStateAction<{ status: boolean; value: string }>>;
  onClick: (fMac: string) => void;
}

const DeleteModal = ({ openModal, setOpenModal, onClick }: Props) => {
  const { t } = useTranslation();

  // 닫기버튼 클릭
  const onClickClose = () => {
    setOpenModal({ status: false, value: '' });
  };

  return (
    <ModalBackground>
      <Modal>
        <div className='modal delete'>
          <div className='inputForm-head'>{t('정말 삭제 하시겠습니까?')}</div>
          <div className='inputForm-body'>
            <div className='modal-footer flex-end'>
              <BtnGhost onClick={onClickClose}>{t('아니요')}</BtnGhost>
              <BtnRed onClick={() => onClick(openModal.value)}>{t('네')}</BtnRed>
            </div>
          </div>
        </div>
      </Modal>
    </ModalBackground>
  );
};

export default DeleteModal;
