/**
 * 작성자 : 홍선영
 * 날짜 : 2024.01.11
 * 기능 : 타이머 (초)
 */
/**
 * 수정자 : 한영광
 * 날짜 : 2024.01.12
 * 수정내용 : 기존 코드는 timer state 값이 변경될 때마다 재렌더링 되는 이슈, 긴급 상황 알림이 추가 되는 경우 시간이 초기화되지 않는 이슈가 있어서 수정함
 *
 */

const useTimer = (function () {
  let count = 0;
  const timer = (initAlertData: any, setOpenModal: any) => {
    if (count > 0) {
      setTimeout(() => {
        count -= 1;
        timer(initAlertData, setOpenModal);
      }, 1000);
    } else {
      initAlertData();
      setOpenModal((prev: any) => ({ ...prev, status: false }));
    }
  };
  return {
    startCounter(num: number, initAlertData: any, setOpenModal: any) {
      count = num;
      timer(initAlertData, setOpenModal);
    },
    getCount() {
      return count;
    },
    setCount(num: number) {
      count = num;
    },
  };
})();
export default useTimer;
