/**
 * 작성자 : 홍선영
 * 날짜 : 2023.12.13
 * 기능 : 대시보드 컴포넌트 > 유해가스 현황
 */
/**
 * 수정자 : 홍선영
 * 날짜 : 2024.01.11
 * 수정 내용 : 스타일링 및 구조 변경
 */
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { apiGet } from '../../services/_common';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { v1, v4 } from 'uuid';
import { arraySortByAscdOrder } from '../../utils/arraySortByAscdOrder';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import Carousel from 'react-multi-carousel';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-multi-carousel/lib/styles.css';
import { CustomDot } from './Carousel';
import { EndPoint, StartPoint } from '../tunnel/TunnelPoints';

type Props = {
  name: string;
  userInfo: any;
  refresh?: boolean;
  autoRefresh?: boolean;
  autoPlay?: boolean;
  valueType?: 'shape' | 'number';
  size?: 'small' | 'large';
};

// TunnelTable 스타일드 컴포넌트 정의
const MGasTable = styled.div`
  flex-grow: 1;
  display: flex;
  width: 100%;
  .tableHeader,
  .tableBody {
    padding-bottom: 0.75rem;
  }
  .tableHeader {
    padding-left: 0.5rem;
    display: flex;
    flex-direction: column;
    flex: 0 0 9rem;
    > div {
      padding: 0 1rem;
      gap: 0.25rem;
    }
    .sensorName {
      display: flex;
      align-items: center;
      flex: 0 0 3.5rem;
      background-color: ${({ theme }: { theme: any }) => theme.board};
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      font-size: 0.875rem;
      .material-symbols-rounded {
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      }
    }
    .sensors {
      font-size: 0.875rem;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      padding: 0 0.75rem;
      .counter {
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        padding: 0 0.5rem;
        border-radius: 0.5rem;
        margin-right: 0.25rem;
      }
    }
  }
  .tableBody {
    padding-right: 0.5rem;
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    position: relative;
    .carousel-container {
      width: 100%;
    }
    .background-rows {
      position: absolute;
      width: 100%;
      height: 100%;
      padding-right: 0.5rem;
      padding-bottom: 0.75rem;
      pointer-events: none;
    }
    .react-multi-carousel-track {
      height: 100%;
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
      .react-multi-carousel-item {
        display: flex;
        flex-direction: column;
      }
    }
    .area {
      flex: 1 0 8rem;
      display: flex;
      flex-direction: column;
      .areaHeader {
        text-align: center;
        font-size: 0.875rem;
        flex: 0 0 3.5rem;
        .pointLabel {
          display: flex;
        }
      }
      .pointSide {
        display: flex;
        flex: 1 0 10rem;
        .pointDetail {
          flex: 1 0 4rem;
          display: flex;
          flex-direction: column;
          .mgasValue {
            justify-content: center;
          }
        }
      }
    }
  }
  .sensors,
  .mgasValue {
    flex: 1 0 2.125rem;
    display: flex;
    align-items: center;
    border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    .removeBackground {
      background-color: transparent !important;
      font-size: 1.375rem !important;
    }
  }
`;

// MGasInfoValue 컴포넌트 정의
const MGasInfo = ({ name, userInfo, refresh = true, autoRefresh, autoPlay = false, valueType = 'shape', size = 'small' }: Props) => {
  const { t } = useTranslation();
  const fetchData = (url: string, req: any) => () => apiGet({ path: url, req });

  const tAreaSensorQuery = useQuery(['tareaSensorGet', userInfo.hCd, userInfo.sCd], fetchData('/tarea/t/sensor', { hCd: userInfo.hCd, sCd: userInfo.sCd }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
  });

  const sensorUListQuery = useQuery(['sensorUListGet', userInfo.hCd, userInfo.sCd], fetchData('/sensor/ulist', { hCd: userInfo.hCd, sCd: userInfo.sCd }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
  });

  const [tAreaList, setTAreaList] = useState<any[]>([]);
  const [sensorList, setSensorList] = useState<string[]>([]);
  const [mergedData, setMergedData] = useState<MergeType[]>([]);

  // 배열안에 시점과 종점 객체를 병합하는 함수
  const getMergedData = (arr: any[]) => {
    const result: MergeType[] = [];
    arr.forEach((obj) => {
      const { tatCd, tatGubun, tatName: tatNameTemp, ...rest } = obj;
      const existingItem = result.find((el) => el.tatCd === tatCd);
      const newObject: MergeType = {
        tatCd,
        tatName: tatNameTemp,
        ...(tatGubun === 's' ? { start: { tatGubun, ...rest } } : { end: { tatGubun, ...rest } }),
      };

      if (!existingItem) {
        result.push(newObject);
      } else {
        // 만약 해당 아이템이 존재하면 'start' 또는 'end' 객체 업데이트
        tatGubun === 's' ? (existingItem.start = { tatGubun, ...rest }) : (existingItem.end = { tatGubun, ...rest });
      }
    });
    return result;
  };

  // useEffect: 자동 새로고침 설정
  useEffect(() => {
    const autoRefreshWidget = () => tAreaSensorQuery.refetch();
    if (autoRefresh) {
      const interval = setInterval(autoRefreshWidget, 60000);
      return () => clearInterval(interval);
    }
    return () => {};
  }, [autoRefresh]);
  // }, [autoRefresh, tAreaSensorQuery]);

  // useEffect: tAreaList 변경 시 mergedData 업데이트
  useEffect(() => {
    const data = getMergedData(tAreaList);
    setMergedData(data);
  }, [tAreaList]);

  // useEffect: tAreaSensorQuery 성공 및 재요청 시 데이터 처리
  useEffect(() => {
    if (tAreaSensorQuery.isSuccess && tAreaSensorQuery.data.status === 200) {
      const { tareaList } = tAreaSensorQuery.data.data.data;
      setTAreaList(tareaList);
    }
  }, [tAreaSensorQuery.isSuccess, tAreaSensorQuery.isRefetching]);

  // useEffect: sensorUListQuery 성공 및 재요청 시 데이터 처리
  useEffect(() => {
    if (sensorUListQuery.isSuccess && sensorUListQuery.data.status === 200) {
      const { sensorUList } = sensorUListQuery.data.data.data;
      const useYList = sensorUList.filter((el: any) => el.useYn === 'Y');
      setSensorList(arraySortByAscdOrder(useYList, 'etc1'));
    }
  }, [sensorUListQuery.isSuccess, sensorUListQuery.isRefetching]);

  // 유해가스목록 갯수에 따라 carousel에 display할 아이템갯수 구하는 함수
  const calculateCarouselItemCount = useCallback(() => {
    if (tAreaList.length <= 2) return tAreaList.length;
    if (tAreaList.length <= 4) return 2;
    return 3;
  }, [size, tAreaList.length]);

  const carouselCount = calculateCarouselItemCount();

  const responsive = useMemo(
    () => ({
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: size === 'large' ? carouselCount : 1,
        slidesToSlide: 1, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: size === 'large' ? 2 : 1,
        slidesToSlide: 1, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    }),
    [size, tAreaList.length]
  );

  // 새로고침 버튼 클릭 시
  const onClickRefresh = () => {
    tAreaSensorQuery.refetch();
  };

  const renderList = ({ data, sensorListData, side }: { data: any; sensorListData: any[]; side: string }) => {
    // valueType이 shape 인지 여부
    const valueTypeStatus = valueType === 'shape';
    // valueType이 shape가 아닌 경우 'removeBackground' 반환
    const removeBackground = valueTypeStatus ? 'removeBackground' : '';

    const getValue = (props: any) => {
      // 레벨 값이 0, 1, 2, 3 중 하나인지 여부
      const isShapeValue = (level: string) => ['0', '1', '2', '3'].includes(level);
      // 데이터 값이 NC, null 중 하나인지 여부
      const isDataValue = (dataProp: string | null) => dataProp === 'NC' || dataProp === null;

      // valueType이 shape일 때
      if (valueTypeStatus) {
        // level 값이 0, 1, 2, 3 중 하나일 때 ●, 아닐 때 -
        const levelProp = `level${props}`;
        return isShapeValue(data[levelProp]) ? '●' : '-';
      }

      // valueType이 number일 때
      const dataProp = `data${props}`;
      // 데이터 값이 NC, null일 때 -
      return isDataValue(data[dataProp]) ? '-' : Number(data[dataProp]).toLocaleString();
    };

    return (
      <div className='pointDetail'>
        {sensorListData.map((sensor: any) => {
          // valueType이 shape가 맞는지 여부
          const levelProp = `level${sensor.etc1}`;
          // 해당 센서의 레벨 값
          const levelValue = data[levelProp];
          // 레벨 값으로 색상 지정
          const getClassName = () => {
            if (levelValue === '0') return 'blue';
            if (levelValue === '1') return 'green';
            if (levelValue === '2') return 'amber';
            if (levelValue === '3') return 'red';
            return '';
          };

          return (
            <div key={sensor.etc1} className='mgasValue'>
              <span className={`textLight ${getClassName()} ${removeBackground}`}>{getValue(sensor.etc1)}</span>
            </div>
          );
        })}
      </div>
    );
  };

  // 로딩 중일 때
  if (!tAreaSensorQuery.isSuccess || tAreaSensorQuery.data.status !== 200 || tAreaSensorQuery.isRefetching) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <div className='mGas tempEdit'>
      <div className='widget-header margin-top'>
        <div className='widget-title flex-between innerBtn'>
          <span>{t('유해가스 현황')}</span>
          <div className='labelSet flex-end'>
            <span className='textBox blue'>{t('좋음')}</span>
            <span className='textBox green'>{t('주의')}</span>
            <span className='textBox amber'>{t('위험')}</span>
            <span className='textBox red'>{t('경보')}</span>
          </div>
          {refresh && (
            <div className='widget-btn-group'>
              <div className='refresh-btn' onClick={onClickRefresh} role='presentation'>
                <span className='material-symbols-rounded'>refresh</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <MGasTable>
        <div className='tableHeader'>
          <div className='sensorName'>
            <span className='material-symbols-rounded'>sensors</span>
            {t('센서명')}
          </div>
          {sensorList.map((el: any, idx: number) => (
            <div className='sensors' key={el.ssCd}>
              <span className='counter'>{idx + 1}</span>
              {el.cdName}
            </div>
          ))}
        </div>
        <div className='tableBody'>
          <Carousel
            swipeable
            draggable
            responsive={responsive}
            infinite
            autoPlay={autoPlay}
            autoPlaySpeed={3000}
            keyBoardControl
            transitionDuration={500}
            containerClass='carousel-container'
            removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
            dotListClass='custom-dot-list-style'
            showDots
            customDot={<CustomDot mergedData={mergedData} />}
          >
            {mergedData.map((el: any) => (
              <div key={v1()} className='area'>
                <div className='areaHeader'>
                  {el.tatName}
                  <div className='pointLabel'>
                    {el.start && <StartPoint />}
                    {el.end && <EndPoint />}
                  </div>
                </div>
                <div className='pointSide'>
                  {el.start && renderList({ data: el.start, sensorListData: sensorList, side: 'start' })}
                  {el.end && renderList({ data: el.end, sensorListData: sensorList, side: 'end' })}
                </div>
              </div>
            ))}
          </Carousel>
          <div className='background-rows area'>
            <div className='areaHeader' />
            <div className='pointSide'>
              <div className='pointDetail'>
                {sensorList.map(() => (
                  <div className='mgasValue' key={v4()} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </MGasTable>
    </div>
  );
};

export default MGasInfo;
