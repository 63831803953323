/**
 * 작성자 : 김광민
 * 날짜 : 2024.03.05
 * 기능 : 모달 내 하단에 위치한 버튼 컴포넌트
 */

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
  color: ${({ theme }: { theme: any }) => theme.text_reverse};
  padding: 0.5rem;
  border-radius: 0.25rem;
  transition: all 0.15s ease-in-out;
  min-width: 7rem;
  height: 3rem;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  cursor: pointer;
  .material-symbols-rounded {
    font-size: 1.5rem;
    font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
  }
  &:hover {
    background-color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
    color: ${({ theme }: { theme: any }) => theme.tonal_blue};
  }
  &.negative {
    background-color: transparent;
    color: ${({ theme }: { theme: any }) => theme.filled_red};
    font-weight: 500;
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.filled_red};
      color: ${({ theme }: { theme: any }) => theme.tonal_red};
    }
  }
`;

type Props = { onClick: () => void; text: string; negative?: boolean };

const ModalBottomButton = ({ onClick, text, negative = false }: Props) => {
  const { t } = useTranslation();

  return (
    <Root role='button' tabIndex={0} onClick={onClick} className={negative ? 'negative' : ''}>
      {t(text)}
    </Root>
  );
};

export default ModalBottomButton;
