import styled from 'styled-components';
import ClickableContainer from '../RealtimeCCTV/ClickableContainer';
import ButtonContainer from '../RealtimeCCTV/ButtonContainer';
import { captureButton } from '../../../../components/button/DarkButtons';
import { WebSDKcctv, emptyCameraStatus } from '../../../../utils/webSDKcctvClass';
import { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import LeftedToast from '../../../../components/cctv/LeftedToast';

const Root = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  color: ${({ theme }: { theme: any }) => theme.color.white};
  user-select: none;
  gap: 0.25rem;
  > div:last-child {
    margin-left: 0.75rem;
  }
`;
interface Props {
  cctvOBJ: WebSDKcctv | undefined; // cctv 객체
  selectedCameraInfo: SelectedCctvCameraInfo;
  setSelectedCameraInfo: Dispatch<SetStateAction<SelectedCctvCameraInfo>>;
  nvrList: IMainMenu[];
  setNvrList: Dispatch<SetStateAction<IMainMenu[]>>;
  capturePath: string;
}

const backToPage = () => {
  // navigate('/s_cctv1');
  // navigate(-1);
  window.history.back();
};

const ControllRightBar = ({ cctvOBJ, selectedCameraInfo, setSelectedCameraInfo, nvrList, setNvrList, capturePath }: Props) => {
  const disabled = selectedCameraInfo.windowIndex === null;
  const handleOpenWindow = () => {
    window.open(`/download_cctv`, '_blank', 'width=1200, height=600');
  };

  const buttons = [
    {
      // 캡쳐
      key: 'capture',
      button: captureButton,
      onClick: () => {
        cctvOBJ?.capture().then(() => {
          toast(
            <LeftedToast message='캡쳐 완료' onClick={() => cctvOBJ?.openDirectory(capturePath)} />, //
            { position: 'top-left', autoClose: 3000 }
          );
        });
      },
      disabled,
    },
    {
      // 줌인
      key: 'zoom_in',
      button: <ButtonContainer icon='frame_inspect' name='eZoom' darkModeOnly />,
      onClick: () => {},
      disabled,
    },
    {
      // 자르기
      key: 'cut',
      button: <ButtonContainer icon='cut' name='자르기' darkModeOnly />,
      onClick: () => {},
      disabled,
    },
    {
      // 다운로드
      key: 'download',
      button: <ButtonContainer icon='download' name='다운로드' darkModeOnly />,
      onClick: () => handleOpenWindow(),
    },
    {
      // 비우기
      key: 'tab_close',
      button: <ButtonContainer icon='tab_close' name='비우기' darkModeOnly />,
      onClick: () => onClickStop(),
      disabled,
    },
    {
      // 종료
      key: 'close',
      button: <ButtonContainer icon='close' name='종료' darkModeOnly />,
      onClick: backToPage,
    },
  ];

  // 정지 버튼 클릭 함수
  const onClickStop = async () => {
    if (cctvOBJ) {
      const stopRes = await cctvOBJ?.stop();
      if (stopRes !== undefined) {
        setSelectedCameraInfo(emptyCameraStatus);

        // 메뉴 목록 UI 업데이트
        const updateNvrList = nvrList.map((el: any) => {
          const newSubList = el.subList.map((camera: any) => ({
            cCd: camera.cCd,
            cName: camera.cName,
            nCd: camera.nCd,
            stream: camera.stream,
            channelNum: camera.channelNum,
            ip: camera.ip,
            port: camera.port,
            pPort: camera.pPort,
            isPlaying: false,
            isRecording: false,
          }));
          return { ...el, subList: newSubList };
        });
        setNvrList(updateNvrList);
      }
    }
  };

  return (
    <Root>
      {buttons.map((button) => (
        <ClickableContainer //
          key={button.key}
          button={button.button}
          onClick={button.onClick}
          disabled={button.disabled}
        />
      ))}
    </Root>
  );
};

export default ControllRightBar;
