/**
 * 작성자 : 김광민
 * 날짜 : 2024.03.15
 * 경로 : 구역별 상황판 > 터널 종합 진행 현황 (일반)
 */

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PointIcon from './PointIcon';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

const Root = styled.div`
  flex-grow: 1;
  display: flex;
  width: 100%;
  overflow: hidden;
  .tableHeader,
  .tableBody {
    overflow: hidden;
  }
  .tableHeader {
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    flex: 0 0 10rem;
    > .tareaName,
    > .rowHeader {
      display: flex;
      padding: 0 1rem;
      gap: 0.25rem;
    }
    .tareaName {
      display: flex;
      align-items: center;
      flex: 0 0 2.5rem;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      font-size: 0.875rem;
      .material-symbols-rounded {
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      }
    }
  }
  .tableBody {
    margin-right: 0.5rem;
    display: flex;
    flex-grow: 1;
    overflow-y: hidden;
    position: relative;
    .point {
      min-width: 100%;
      flex: 0 0;
      display: flex;
      flex-direction: column;
      border-radius: 0.5rem;
      transition: all 0.85s ease-in-out;
      opacity: 0;
      &.background-rows {
        position: absolute;
        left: 0;
        min-width: 200%;
        height: 100%;
        padding-right: 0.5rem;
        pointer-events: none;
      }
      &.visible {
        opacity: 1;
      }
      .pointHeader {
        font-size: 0.875rem;
        flex: 0 0 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        .name {
          font-size: 0.875rem;
          font-weight: 400;
          padding: 0.5rem 0.75rem;
          border-radius: 1rem;
          color: ${({ theme }: { theme: any }) => theme.text_reverse};
          line-height: 1.25rem;
          display: flex;
          align-items: center;
          gap: 0.375rem;
          height: 1.5rem;
          justify-content: center;
          margin-right: 1rem;
        }
        .material-symbols-rounded {
          font-variation-settings: 'FILL' 0, 'wght' 600, 'GRAD' 0, 'opsz' 24;
          font-size: 1.125rem;
          height: 1.25rem;
        }
        &.startPoint {
          .name {
            background-color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
          }
          .material-symbols-rounded {
            color: ${({ theme }: { theme: any }) => theme.filled_blue};
          }
        }
        &.endPoint {
          .name {
            background-color: ${({ theme }: { theme: any }) => theme.filled_amber_deep};
          }
          .material-symbols-rounded {
            color: ${({ theme }: { theme: any }) => theme.filled_amber};
          }
        }
      }
      .pointBody {
        flex: 1 0 8rem;
        display: flex;
        width: 100%;
        flex-direction: column;
        .rowData {
          justify-content: center;
        }
      }
      &.focused {
        box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.15);
        background-color: ${({ theme }: { theme: any }) => theme.board};
        .areaHeader {
          color: ${({ theme }: { theme: any }) => theme.filled_blue};
          border-radius: 0.5rem 0.5rem 0 0;
          box-shadow: inset 0px 2px 1px 0 ${({ theme }: { theme: any }) => theme.filled_blue}, inset -2px 0px 1px 0 ${({ theme }: { theme: any }) => theme.filled_blue},
            inset 2px 0px 1px 0 ${({ theme }: { theme: any }) => theme.filled_blue};
        }
        .areaBody {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
          border-radius: 0 0 0.5rem 0.5rem;
          box-shadow: inset 0px -2px 1px 0 ${({ theme }: { theme: any }) => theme.filled_blue}, inset -2px 0px 1px 0 ${({ theme }: { theme: any }) => theme.filled_blue},
            inset 2px 0px 1px 0 ${({ theme }: { theme: any }) => theme.filled_blue};
        }
      }
    }
  }
  .rowData {
    flex: 1 0 2.125rem;
    display: flex;
    gap: 0.25rem;
    align-items: center;
    border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    padding-right: 0.5rem;
    .textData {
      word-break: keep-all;
      line-height: 1.25;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .material-symbols-rounded {
      font-variation-settings: 'FILL' 1;
      font-size: 1.25rem;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      padding: 0 0.125rem;
      border-radius: 0.5rem;
      margin-right: 0.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 2rem;
      height: 1.75rem;
    }
  }
`;

type Props = { data: any; setFocus: Dispatch<SetStateAction<'start' | 'end'>> };

const TunnelTableWithTatCd = ({ data, setFocus }: Props) => {
  const [focusedPoint, setFocusedPoint] = useState<'start' | 'end'>('start');
  const focusedRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();

  // 터널 데이터를 시점과 종점으로 분리하고 가공하는 함수
  const mutateData = (listData: any[]) => {
    // 터널의 시점과 종점을 추출합니다.
    const result = listData.map((tunnel: any) => {
      const { eTesName, eTjcName, sTesName, sTjcName, eWorkerCount, sWorkerCount, sUseYn, eUseYn, tatName, eDeviceCount, sDeviceCount, attendCount } = tunnel;
      const isStart = sUseYn === 'Y';
      const isEnd = eUseYn === 'Y';
      const start = isStart
        ? {
            position: 'start',
            tesName: sTesName,
            tjcName: sTjcName,
            workerCount: sWorkerCount,
            deviceCount: sDeviceCount,
            attendCount,
          }
        : null;
      const end = isEnd
        ? {
            position: 'end',
            tesName: eTesName,
            tjcName: eTjcName,
            workerCount: eWorkerCount,
            deviceCount: eDeviceCount,
            attendCount,
          }
        : null;

      return { tunnelName: tatName, isExpand: isStart && isEnd, attendCount, start, end };
    });

    return result;
  };

  const updateFocus = () => {
    setFocusedPoint((prev) => (prev === 'start' ? 'end' : 'start'));
    setFocus((prev) => (prev === 'start' ? 'end' : 'start'));
  };

  const TRANSITION_TIME = 5000;

  // autoPlay, focusedIndex 상태 변경시 구동되는 자동재생 Hook
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    // 시점과 종점이 모두 있는 경우에만 함수 실행
    if (data[0].sUseYn === 'Y' && data[0].eUseYn === 'Y') {
      // autoPlay가 true면 TRANSITION_TIME 후에 updateIndex 실행
      timeoutId = setTimeout(updateFocus, TRANSITION_TIME);
    }
    return () => clearTimeout(timeoutId);
  }, [focusedPoint]);

  // scrollIntoView 실행
  useEffect(() => {
    const element = focusedRef.current;
    if (element) {
      const elementRect = element.getBoundingClientRect();
      const parentRect = element.parentElement?.getBoundingClientRect();
      // 요소가 가로 스크롤 영역 밖에 있으면 수동으로 스크롤 조작
      if (elementRect.left < parentRect!.left || elementRect.right > parentRect!.right) {
        element.parentElement?.scrollTo({
          left: elementRect.right - parentRect!.right + element.parentElement!.scrollLeft,
        });
      }
    }
  }, [focusedPoint]);

  const tableData = mutateData(data)[0];
  const tableHeaderList = [
    { name: '근로자', icon: 'group' },
    { name: '장비', icon: 'front_loader' },
    { name: '작업 내용', icon: 'construction' },
    { name: '장비 상태', icon: 'manufacturing' },
    { name: '출역 인원', icon: 'familiar_face_and_zone' },
  ];

  // 시점/종점 테이블 렌더링
  const renderPoint = ({ propsData }: { propsData: any }) => {
    if (!propsData) {
      return null;
    }

    const { tesName, tjcName, workerCount, deviceCount, attendCount, position } = propsData;
    const isStart = position === 'start';

    return (
      <div className={`point ${position === focusedPoint ? 'visible' : 'invisible'}`} ref={position === focusedPoint ? focusedRef : null}>
        <div className={`pointHeader ${isStart ? 'startPoint' : 'endPoint'}`}>
          <PointIcon isStart={isStart} />
          <span className='name'>{isStart ? t('시점') : t('종점')}</span>
        </div>
        <div className={`pointBody ${propsData.position}`}>
          <div className='rowData'>{workerCount}</div>
          <div className='rowData'>{deviceCount}</div>
          <div className='rowData'>
            <div className='textData'>{tesName}</div>
          </div>
          <div className='rowData'>
            <div className='textData'>{tjcName}</div>
          </div>
          <div className='rowData'>
            <span>{attendCount}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Root>
      <div className='tableHeader'>
        <div className='tareaName'>{t('구역 명')}</div>
        {tableHeaderList.map((list: { name: string; icon: string }) => (
          <div className='rowData' key={list.name}>
            <span className='material-symbols-rounded'>{list.icon !== '' ? list.icon : 'circle'}</span>
            {t(list.name)}
          </div>
        ))}
      </div>
      <div className='tableBody'>
        {renderPoint({ propsData: tableData.start })}
        {renderPoint({ propsData: tableData.end })}
        <div className='background-rows point visible'>
          <div className='pointHeader' />
          <div className='pointBody'>
            {tableHeaderList.map((list, index: number) => (
              <div className='rowData' key={index} />
            ))}
          </div>
        </div>
      </div>
    </Root>
  );
};

export default TunnelTableWithTatCd;
