/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.09
 * 경로 : 터널관리 - 구역 현황 관리 > 종합진행현황
 */

import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useRecoilValue } from 'recoil';
import { bScreenMode, themeState } from '../../atoms';
import { dark, light } from '../../assets/styles/theme';

type HalfDoughnutChartProps = {
  value: number; // 숫자 타입의 props로 받아올 값
  background?: boolean; // 차트 배경 여부를 결정하는 props
  chartType?: 'doughnut' | 'circle' | 'half'; // 차트 타입을 결정하는 props
  siteDashboard?: boolean;
  animation?: boolean;
};

const HalfDoughnutChart: React.FC<HalfDoughnutChartProps> = React.memo(({ value, background = false, chartType = 'doughnut', siteDashboard = false, animation }) => {
  const theme = useRecoilValue(themeState);
  const siteDashboardTheme = useRecoilValue(bScreenMode);

  // value가 유효한 숫자인지 확인하고, 아니라면 0으로 설정
  const validValue = Number(value) ? value : 0;

  const themeCondition = () => {
    if (siteDashboard) return siteDashboardTheme === '1';
    return theme === 'light';
  };

  const themeColors = themeCondition() ? light : dark;
  const ringColor = themeColors?.tonal_deep;
  const ringBackgroundColor = themeColors?.filled_blue;

  const chartSettings = {
    doughnut: { circumference: 260, rotation: -130, cutout: '70%' },
    circle: { circumference: 360, rotation: 0.5 * Math.PI, cutout: '70%' },
    half: { circumference: 200, rotation: -100, cutout: '80%' },
  };

  const { circumference, rotation, cutout } = chartSettings[chartType];

  const data = {
    datasets: [
      {
        data: [validValue, 100 - validValue],
        backgroundColor: [ringBackgroundColor, 'transparent'],
        borderColor: ['transparent', 'transparent'],
        borderRadius: 100,
      },
    ],
  };

  // 차트 배경 데이터 설정
  const backgroundData = {
    datasets: [
      {
        data: [validValue],
        backgroundColor: [ringColor],
        borderColor: ['transparent'],
        borderRadius: chartType === 'circle' ? 0 : 100,
      },
    ],
  };

  // 차트 옵션 설정
  const options = {
    cutout,
    circumference,
    rotation,
    maintainAspectRatio: false,
    animation: {
      animateRotate: animation !== undefined && animation === false ? animation : background,
    },
    events: [],
    devicePixelRatio: 2,
    // 기본값보다 높은 해상도로 렌더링
  };

  if (isNaN(validValue)) {
    return null;
  }

  // Doughnut 컴포넌트를 반환합니다.
  return <Doughnut data={background ? data : backgroundData} options={options} />;
});
// 컴포넌트의 이름을 설정합니다.
HalfDoughnutChart.displayName = 'HalfDoughnutChart';

export default HalfDoughnutChart;
