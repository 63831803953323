/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.15
 * 경로 : 대시보드 > 터널 구역별 종합 진행 현황
 */

import { useTranslation } from 'react-i18next';
import { PulseLoader } from 'react-spinners';
import React, { useEffect, useState, memo } from 'react';
import { areaFilter, useFetchTareaData } from '../../services/Tunnel';
import PlayButton from './PlayButton';
import TunnelLegends from '../tunnel/TunnelLegends';
import RefreshButton from './RefreshButton';
import SingleTunnelAreaInfo from '../tunnel/SingleTunnelAreaInfo';
import { useRecoilValue } from 'recoil';
import { tInfoUpdateState } from '../../atoms';
import styled from 'styled-components';
import SmallIconButton from '../button/SmallIconButton';

const Root = styled.div`
  user-select: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  > .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
    padding: 0 1rem;
    > .title {
      flex-shrink: 0;
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.2;
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    .desktop-legends {
      display: none;
      @media (min-width: 768px) {
        display: flex;
      }
    }
    .mobile-legends {
      position: absolute;
      left: 0;
      top: 3.5rem;
      padding: 0.75rem;
      background-color: ${({ theme }: { theme: any }) => theme.board};
      box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.1);
      width: 100%;
      z-index: 1000;
      outline: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      &.hidden {
        display: none;
      }
      > div {
        flex-wrap: wrap;
        row-gap: 0.5rem;
      }
    }
    > .icons {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    .icon {
      &.hidden {
        display: none;
      }
    }
    .desktop-icon {
      display: none;
      @media (min-width: 768px) {
        display: flex;
      }
    }
    .mobile-icon {
      &.hidden {
        display: none;
      }
      @media (min-width: 768px) {
        display: none;
      }
    }
  }
  > .content {
    flex-grow: 1;
    width: 100%;
    display: flex;
    padding: 0 0.5rem;
    margin-bottom: 0.5rem;
    overflow: hidden;
  }
`;

type Props = {
  name: string;
  userInfo: any;
  size?: 'medium' | 'large';
  visibleRefreshBtn?: boolean;
  uniqueKey: string;
};

// 터널 구역별 종합 진행 현황
const TunnelAreaInfo: React.FC<Props> = React.memo(({ name, userInfo, visibleRefreshBtn = true, uniqueKey }) => {
  const { t } = useTranslation();
  const [autoPlay, setAutoPlay] = useState(visibleRefreshBtn === false);
  const [tunnelList, setTunnelList] = useState<any[]>([]);
  const [selectedTatCd, setSelectedTatCd] = useState('');
  const [isVisibleLegend, setIsVisibleLegend] = useState(false);
  const [isOpenMobileTab, setIsOpenMobileTab] = useState(false);

  const isTunnelInfoUpdated = useRecoilValue(tInfoUpdateState);
  const { data, isError, isLoading, isRefetching, refetch } = useFetchTareaData({ uniqueKey, dependencies: [isTunnelInfoUpdated], autoRefetch: !visibleRefreshBtn });

  useEffect(() => {
    if (data && data.length > 0) {
      setTunnelList(data);
      if (selectedTatCd === '') setSelectedTatCd(data[0].tatCd);
    }
    // refetch();
  }, [data]);

  // autoPlay가 true일 때 currentIndex 및 nextIndex 계산
  const updateSelectedTatCd = () => {
    const currentIndex = tunnelList.findIndex((item) => item.tatCd === selectedTatCd);
    const nextIndex = currentIndex + 1 < tunnelList.length ? currentIndex + 1 : 0;
    setSelectedTatCd(tunnelList[nextIndex]?.tatCd || '');
  };

  // 새로고침 버튼 클릭 시
  const onClickRefresh = () => {
    // setSelectedTatCd('');
    refetch();
  };

  const handleVisibleLegend = () => {
    if (isOpenMobileTab) {
      setIsOpenMobileTab(!isOpenMobileTab);
    }
    setIsVisibleLegend(!isVisibleLegend);
  };

  const handleMobileTab = () => {
    if (isVisibleLegend) {
      setIsVisibleLegend(!isVisibleLegend);
    }
    setIsOpenMobileTab(!isOpenMobileTab);
  };

  if (isError) {
    console.error('API 호출 중 오류 발생:', data);
  }

  return (
    <Root>
      <div className='header'>
        <div className='title'>
          <div className='mobile-icon'>
            <SmallIconButton icon='side_navigation' onClick={handleMobileTab} size='large' color='blue' />
          </div>
          {t('터널 구역별 종합 진행 현황')}
          <div className='desktop-legends'>
            <TunnelLegends filter={areaFilter} />
          </div>
        </div>
        <div className={`mobile-legends ${isVisibleLegend ? 'visible' : 'hidden'}`}>
          <TunnelLegends filter={areaFilter} />
        </div>
        <div className='icons'>
          <div className='mobile-icon'>
            {isVisibleLegend ? ( //
              <SmallIconButton icon='close' onClick={handleVisibleLegend} />
            ) : (
              <SmallIconButton icon='format_list_bulleted' onClick={handleVisibleLegend} />
            )}
          </div>
          {visibleRefreshBtn && (
            <>
              <div className='desktop-icon'>
                <PlayButton autoPlay={autoPlay} onClick={() => setAutoPlay(!autoPlay)} />
              </div>
              <div className={`icon ${isVisibleLegend ? 'hidden' : 'visible'}`}>
                <RefreshButton onClick={onClickRefresh} />{' '}
              </div>
            </>
          )}
        </div>
      </div>
      {isLoading || isRefetching ? (
        <div className='loader-background'>
          <PulseLoader color='rgb(0, 122, 255)' size='10px' />
        </div>
      ) : (
        <SingleTunnelAreaInfo
          tunnelList={tunnelList}
          setTunnelList={setTunnelList}
          selectedTatCd={selectedTatCd}
          filter={areaFilter}
          setSelectedTatCd={setSelectedTatCd}
          autoPlay={visibleRefreshBtn === false ? true : autoPlay}
          updateSelectedTatCd={updateSelectedTatCd}
          siteDashboard={visibleRefreshBtn === false}
          openMobileTab={isOpenMobileTab}
          closeMobileTab={handleMobileTab}
        />
      )}
    </Root>
  );
});

TunnelAreaInfo.displayName = 'TunnelAreaInfo';

export default TunnelAreaInfo;
