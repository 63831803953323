import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import { IComCdList } from 'customTypes';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { IUser, userState } from '../../../atoms';
import { FOREIGN_YN, INIT_CHECK_OPTION, INIT_WORKER_STATUS, LIST_COUNT, WORK_STATUS_SEARCH } from '../../../_constants';
import { BtnGhost } from '../../../components/Button';
import Input, { CheckBox } from '../../../components/Input';
import SelectBox from '../../../components/SelectBox';
import ShortcutButton from '../../../components/button/ShortcutButton';
import SearchSelectBoxSm from '../../../components/SearchSelectBoxSm';
import RangePicker, { onChangeRangeInput } from '../../../components/RangePicker';
import { useSetSjcd } from '../../../utils/useSetSjcd';
import { todayYYYYMMDD } from '../../../utils/formatDate';
import useSetJobtype from '../../../utils/useSetJobtype';
import useOnKeydownF9 from '../../../utils/useOnKeydownF9';
import useSetListCount from '../../../utils/useSetListCount';
import useSetNationality from '../../../utils/useSetNationality';
import dayjs from 'dayjs';
import { apiGet } from '../../../services/_common';
import { useQuery } from '@tanstack/react-query';
import { PrintContextConsumer, useReactToPrint } from 'react-to-print';
import WorkerInfoPrintForm from './workerInfoPrintForm';
import { setComCdListState } from '../../../utils/setComCdListState';
import { useSetAuth } from '../../../utils/useSetAuth';
import { useTemplateGubunList } from './services';

interface IWorkerSearchOption {
  setIsFetching: Dispatch<SetStateAction<boolean>>; // 검색데이터 패칭상태
  setIsError: Dispatch<SetStateAction<boolean>>;
  tableState: any[];
  setTableState: Dispatch<SetStateAction<any[]>>;
  filterTableState: any[];
  setFilterTableState: Dispatch<SetStateAction<any[]>>;
  checkAll: boolean;
  setCheckAll: Dispatch<SetStateAction<boolean>>;
  checkOption: { key: string; name: string; checked: boolean }[];
  setCheckOption: Dispatch<SetStateAction<{ key: string; name: string; checked: boolean }[]>>;
  setRowKey: Dispatch<SetStateAction<string>>;
  checkList: any[];
  setCheckList: Dispatch<SetStateAction<any>>;
  onClickColumn: Function;
  setChecked: Dispatch<SetStateAction<{ columnName: string | null; checked: boolean }>>;
  listCount: any;
  setListCount: Dispatch<SetStateAction<any>>;
  refetchFlag: boolean;
  setRefetchFlag: Dispatch<SetStateAction<boolean>>;
}

const WorkerSearchOption = ({
  setIsFetching,
  setIsError,
  tableState,
  setTableState,
  filterTableState,
  setFilterTableState,
  checkAll,
  setCheckAll,
  checkOption,
  setCheckOption,
  setRowKey,
  checkList,
  setCheckList,
  onClickColumn,
  setChecked,
  listCount,
  setListCount,
  refetchFlag,
  setRefetchFlag,
}: IWorkerSearchOption) => {
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const toDay = todayYYYYMMDD();
  const { siteJoinInfoList } = useSetSjcd();
  const { nationalityList } = useSetNationality();
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const [wPrejobtype, setWPrejobtype] = useState({ type: 'wPrejobtype', wPrejobtype: '', cdName: '' });
  const { prejobtypeList, jobtypeList } = useSetJobtype(wPrejobtype.wPrejobtype);
  const [sjCd, setSjCd] = useState({ type: 'sjCd', sjCd: '', cdName: '' });
  const [wWorkstatus, setWWorkstatus] = useState(INIT_WORKER_STATUS);
  const [wForeignyn, setWForeignyn] = useState({ type: 'wForeignyn', wForeignyn: 'A', cdName: t('전체') });
  const [_nationalityList, _setNationalityList] = useState<any[]>(nationalityList);
  const [wNationality, setWNationality] = useState({ type: 'wNationality', wNationality: 'A', cdName: t('국적 전체') });
  const [searchOption, setSearchOption] = useState({ wName: '' });
  const [visibleRangePicker, setVisibleRangePicker] = useState(false);
  const [pickerDateRange, setPickerDateRange] = useState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
  const [userInputDateRange, setUserInputDateRange] = useState({ start: toDay, end: toDay });
  const userInfo = useRecoilValue<IUser>(userState);
  const [initiate, setinitiate] = useState('');
  const [jikJongList, setJikJongList] = useState<any[]>([]);
  const [wJobtype, setWJobtype] = useState({ type: 'wJobtype', wJobtype: '', cdName: '' });
  const [listCountComCdList, setListCountComCdList] = useState<IComCdList[]>([]); // 검색줄수 공통코드
  const { patchUserMenuAPI } = useSetListCount(); // 검색줄수설정 훅
  const randomString = Math.random().toString(36).substring(7); // 랜덤 문자열을 쿼리스트링으로 추가해서 이미지 캐싱 방지
  const { data: CtemplateList, isLoading: isLoadingCtemplateList } = useTemplateGubunList('C');
  const { data: JtemplateList, isLoading: isLoadingJtemplateList } = useTemplateGubunList('J');

  useEffect(() => {
    setComCdListState(LIST_COUNT, setListCountComCdList, false);
  }, []);

  const wNameRef = useRef<HTMLInputElement>(null);
  const { data, isLoading, isFetching, isError, refetch } = useQuery(['workerList', userInfo.hCd, userInfo.sCd], () => fetchData(), {
    enabled: true,
    cacheTime: 0,
  });

  useEffect(() => {
    if (refetchFlag) refetch();
  }, [refetchFlag]);

  const fetchData = async () => {
    try {
      const path = '/worker';
      const req = {
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        wName: searchOption.wName,
        wWorkstatus: wWorkstatus.wWorkstatus === 'A' ? '' : wWorkstatus.wWorkstatus,
        sjCd: sjCd.sjCd,
        wForeignyn: wForeignyn.wForeignyn === 'A' ? '' : wForeignyn.wForeignyn,
        wNationality: wNationality.wNationality === 'A' ? '' : wNationality.wNationality,
        wJobdate1: userInputDateRange.start.replaceAll('-', ''),
        wJobdate2: userInputDateRange.end.replaceAll('-', ''),
        wPrejobtype: wPrejobtype.wPrejobtype,
        wJobtype: wJobtype.wJobtype,
      };
      const res = await apiGet({ path, req });
      const newArray = res.data.data.workerList.map((el: any) => ({
        ...el,
        workerInfoCheck: false,
        cCheck: false, // 공통 양식지
        jCheck: false, // 직종별 양식지
        muscularCheck: false,
        painCheck: false,
        stressCheck: false,
        orgWName: el.wName,
      }));
      setTableState(newArray);
      setFilterTableState(newArray);
      return newArray;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    } finally {
      setRefetchFlag(false);
    }
  };

  useEffect(() => {
    setIsFetching(isFetching);
  }, [isLoading, isFetching]);

  useEffect(() => {
    setIsError(isError);
  }, [isError]);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    setWNationality({ type: 'wNationality', wNationality: 'A', cdName: t('국적 전체') });
    if (wForeignyn.wForeignyn === 'Y' || wForeignyn.wForeignyn === 'A') _setNationalityList(nationalityList);
    else _setNationalityList([]);
  }, [wForeignyn.wForeignyn, nationalityList]);

  useEffect(() => {
    setWJobtype({ type: 'wJobtype', wJobtype: '', cdName: '' });
    if (wPrejobtype.wPrejobtype === '') setJikJongList([]);
  }, [wPrejobtype.wPrejobtype]);

  useEffect(() => {
    setJikJongList(jobtypeList);
  }, [jobtypeList]);

  useEffect(() => {
    if (pickerDateRange[0].startDate) {
      setUserInputDateRange((prev: any) => ({ ...prev, start: dayjs(pickerDateRange[0].startDate).format('YYYY-MM-DD') }));
    }
    if (pickerDateRange[0].endDate) {
      setUserInputDateRange((prev: any) => ({ ...prev, end: dayjs(pickerDateRange[0].endDate).format('YYYY-MM-DD') }));
    }
  }, [pickerDateRange[0].startDate, pickerDateRange[0].endDate]);

  // 검색 버튼 클릭
  const onClickSearch = () => {
    setRowKey('');
    if (userInputDateRange.end && userInputDateRange.start > userInputDateRange.end) {
      toast.warning(t('날짜를 다시 입력하세요'));
      setSearchOption((prev) => ({ ...prev, end: '' }));
    } else {
      setVisibleRangePicker(false);
      setCheckOption(INIT_CHECK_OPTION);
      refetch();
    }
  };

  const onClickRangeInput = () => {
    if (!visibleRangePicker) setVisibleRangePicker(true);
  };

  const initiateSearchOptions = () => {
    setWWorkstatus(INIT_WORKER_STATUS);
    setSjCd({ type: 'sjCd', sjCd: '', cdName: '' });
    setWForeignyn({ type: 'wForeignyn', wForeignyn: 'A', cdName: t('전체') });
    setWNationality({ type: 'wNationality', wNationality: 'A', cdName: t('국적 전체') });
    setWPrejobtype({ type: 'wPrejobtype', wPrejobtype: '', cdName: '' });
    setWJobtype({ type: 'wJobtype', wJobtype: '', cdName: '' });
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    setSearchOption({ wName: '' });
    setinitiate(`${Math.random()}`);
    initiateSearchOptions();
    setPickerDateRange([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
    setUserInputDateRange({ start: '', end: '' });
    setCheckOption(INIT_CHECK_OPTION);
    setRowKey('');

    const newArray = tableState.map((filteredEl: any) => ({
      ...filteredEl,
      workerInfoCheck: false,
      cCheck: false, // 공통 양식지
      jCheck: false, // 직종별 양식지
      muscularCheck: false,
      painCheck: false,
      stressCheck: false,
    }));
    setTableState(newArray);
  };

  // 근로자명 입력창에서 엔터입력시 검색
  const loginOnEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (wNameRef.current) onClickSearch();
    }
  };

  // 검색옵션 체크박스 선택 이벤트 핸들러
  const onChanceCheck = (key: string, check: boolean) => {
    const newSearchState = checkOption.map((el) => (el.key === key ? { ...el, checked: !el.checked } : el));
    setCheckOption(newSearchState);
    setChecked({ columnName: key, checked: !check });
  };

  // 체크박스 전체선택시 이벤트 핸들러
  const onChanceCheckAll = async () => {
    setCheckOption((prev) => prev.map((el) => ({ ...el, checked: !checkAll })));
    const newCheckAll = !checkAll;
    await setChecked({ columnName: 'workerInfoCheck', checked: newCheckAll });
    await setChecked({ columnName: 'cCheck', checked: newCheckAll });
    await setChecked({ columnName: 'jCheck', checked: newCheckAll });
    await setChecked({ columnName: 'muscularCheck', checked: newCheckAll });
    await setChecked({ columnName: 'painCheck', checked: newCheckAll });
    await setChecked({ columnName: 'stressCheck', checked: newCheckAll });
  };

  useEffect(() => {
    const allChecked = checkOption.every((item) => item.checked);
    if (allChecked) setCheckAll(allChecked);

    const someUnchecked = checkOption.some((item) => !item.checked);
    if (someUnchecked) setCheckAll(!someUnchecked);
  }, [checkOption]);

  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const hasOptionChecked = (users: any[]): boolean => {
    for (const user of users) {
      for (const option of INIT_CHECK_OPTION) {
        if (user[option.key] === true) {
          return true; // 체크된 값이 하나라도 있는경우
        }
      }
    }
    return false; // 체크된 값에 하나도 없는 경우
  };

  /**
   * 작성자 : 홍선영
   * 날짜 : 2024.06.12
   * 기능 : 서명항목체크값이 하나도 없는경우 유저 체크리스트에서 제외
   */
  const removeFromCheckListIfNoCheck = (users: any[]): any[] => {
    const userArray: any[] = [];
    for (const user of users) {
      let hasTrueValue = false; // 적어도 한개의 true값이 있는지 여부

      for (const option of INIT_CHECK_OPTION) {
        if (user[option.key] === true) {
          hasTrueValue = true; // 하나라도 true체크값이 있는경우
          break;
        }
      }

      if (hasTrueValue) userArray.push(user);
    }
    return userArray;
  };

  // 인쇄 버튼 클릭
  const onClickPrint = async () => {
    if (checkList.length > 0) {
      if (hasOptionChecked(checkList)) {
        await new Promise<void>((resolve) => {
          // 로우헤더는 체크되어있지만, 서명항목체크값이 하나도 없는경우 인쇄목록에서 제외
          setCheckList(removeFromCheckListIfNoCheck(checkList));
          resolve();
        });
        // tuigrid 필터링을 실행한 경우
        // eslint-disable-next-line no-underscore-dangle
        if (filterTableState.some((el: any) => el._attributes.checked)) {
          // 필터링한 순서대로 체크리스트 재정렬 후 프린트
          await new Promise<void>((resolve) => {
            // 로우헤더는 체크되어있지만, 서명항목체크값이 하나도 없는경우 인쇄목록에서 제외
            // eslint-disable-next-line no-underscore-dangle
            setCheckList(removeFromCheckListIfNoCheck(filterTableState.filter((el: any) => el._attributes.checked)));
            resolve();
          });
        }
        handlePrint();
      } else toast.warning(t('인쇄항목을 선택하세요'));
    } else {
      toast.warning(t('근로자를 선택하세요.'));
    }
  };

  // 공통/직종별 양식지 이미지 리턴
  const printList = (item: any, list: any) => {
    return list
      .map((el: any) => {
        if (Object.keys(item).includes(el.key) && item[el.key]) {
          return <img key={el.key} className='full-page-image' src={`${item[el.key]}?v=${randomString}`} alt='양식지 이미지' />;
        }
        return null;
      })
      .filter(Boolean);
  };

  if (isLoadingCtemplateList || isLoadingJtemplateList) return null;

  return (
    <SearchOptions>
      <div className='inputsWrapper'>
        <div className='inputForm-col withLabelComCf'>
          <label htmlFor='wWorkstatus'>{t('근로 상태')}</label>
          <SelectBox
            options={WORK_STATUS_SEARCH}
            defaultOption={t('재직')}
            state={wWorkstatus}
            setState={setWWorkstatus}
            stateKey='wWorkstatus'
            initiateKey={wWorkstatus.wWorkstatus}
            filterbar='filter-1-left'
          />
        </div>
        <div className='inputForm-row withLabel'>
          <label htmlFor='sjCd'>{t('협력업체')}</label>
          <div className='inputForm-col'>
            <SearchSelectBoxSm
              options={siteJoinInfoList}
              defaultOption={t('전체')}
              state={sjCd}
              setState={setSjCd}
              stateKey='sjCd'
              codeKey='cdName'
              initiateKey={sjCd.sjCd}
              filterbar='filter-1-left'
              optionHeight='height-md'
            />
          </div>
        </div>
        <div className='inputForm-col withLabelComCf'>
          <label htmlFor='wForeignyn'>{t('외국인여부')}</label>
          <SelectBox
            options={FOREIGN_YN}
            defaultOption={t('전체')}
            state={wForeignyn}
            setState={setWForeignyn}
            stateKey='wForeignyn'
            initiateKey={wForeignyn.wForeignyn}
            filterbar='filter-1-left'
            optionHeight='height-md'
          />
        </div>
        <div className='inputForm-row'>
          <div className='inputForm-col'>
            <SelectBox
              options={_nationalityList}
              defaultOption={t('국적 전체')}
              state={wNationality}
              setState={setWNationality}
              stateKey='wNationality'
              initiateKey={wNationality.wNationality}
              filterbar='filter-1-center'
            />
          </div>
        </div>
        <div className='inputForm-row-fit'>
          <span className='calendarLabel'>{t('취업일자')}</span>
          <div className='inputForm-col'>
            <div className='flex-basic'>
              <input
                id='startInput'
                type='text'
                value={userInputDateRange.start}
                onMouseDown={onClickRangeInput}
                onChange={(e) => onChangeRangeInput(e, 'start', setPickerDateRange, setUserInputDateRange)}
              />
              <span className='inputDash'> ~ </span>
              <input
                id='endInput'
                type='text'
                value={userInputDateRange.end}
                onMouseDown={onClickRangeInput}
                onChange={(e) => onChangeRangeInput(e, 'end', setPickerDateRange, setUserInputDateRange)}
              />
            </div>
            {visibleRangePicker && (
              <div className='rangePickerWrapper'>
                <RangePicker state={pickerDateRange} setState={setPickerDateRange} setVisible={setVisibleRangePicker} />
              </div>
            )}
          </div>
        </div>
        {userInfo.prejobtypeYn === 'Y' && (
          <div className='inputForm-row'>
            <div className='inputForm-col'>
              <SearchSelectBoxSm
                options={prejobtypeList}
                defaultOption={t('공종 전체')}
                state={wPrejobtype}
                setState={setWPrejobtype}
                stateKey='wPrejobtype'
                codeKey='cdName'
                initiateKey={initiate}
                filterbar='filter-1-right'
                comboWidth='expand-box-sm'
                optionHeight='height-md'
              />
            </div>
          </div>
        )}
        <div className='inputForm-row'>
          <div className='inputForm-col'>
            <SearchSelectBoxSm
              options={jikJongList}
              defaultOption={t('직종 전체')}
              state={wJobtype}
              setState={setWJobtype}
              stateKey='wJobtype'
              codeKey='cdName'
              initiateKey={wJobtype.wJobtype}
              filterbar='filter-1-right'
              comboWidth='expand-box-sm'
              optionHeight='height-md'
            />
          </div>
        </div>
        <div className='inputForm-row'>
          <div className='inputForm-col'>
            <Input placeholder={t('근로자명')} label='' type='text' id='wName' name='wName' state={searchOption} setState={setSearchOption} inputRef={wNameRef} onKeyDown={loginOnEnterKeyDown} />
          </div>
        </div>
      </div>
      <div className='inputsWrapper'>
        <div className='secondSearchOption'>
          <div className='flex-basic textBtnGroup'>
            <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
            <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
            <div className='searchResult'>
              {t('총')}
              <span>{tableState.length}</span>
              {t('개')}
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col withLabelComCf'>
                <label htmlFor='useYn'>{t('보기 설정')}</label>
                <SelectBox
                  options={listCountComCdList}
                  defaultOption={listCount.cdName}
                  state={listCount}
                  setState={setListCount}
                  stateKey={LIST_COUNT}
                  initiateKey={listCount[LIST_COUNT]}
                  setTableLinesAPI={patchUserMenuAPI}
                  optionHeight='height-sm'
                  rsearch
                />
              </div>
            </div>
          </div>
          <div className='flex-end checks'>
            <CheckBox>
              <input type='checkbox' checked={checkAll} id='checkAll' onChange={onChanceCheckAll} />
              <label htmlFor='checkAll'>{t('전체선택')}</label>
            </CheckBox>
            {checkOption?.map((el) => (
              <CheckBox key={el.key}>
                <input type='checkbox' checked={el.checked} id={el.name} onChange={() => onChanceCheck(el.key, el.checked)} />
                <label htmlFor={el.name}>{el.name}</label>
              </CheckBox>
            ))}
          </div>
          {auth.printAuth && (
            <div className='flex-basic iconBtnGroup'>
              <BtnGhost onClick={onClickPrint}>
                <span className='material-symbols-rounded'>print</span>
                {t('인쇄')}
              </BtnGhost>
            </div>
          )}
          <PrintContextConsumer>
            {() => (
              <div style={{ display: 'none' }}>
                <div ref={componentRef} className='printable-contents'>
                  {checkList.map((item: any) => (
                    <div key={item.wCd} className='image-container'>
                      {item.workerInfoCheck && (
                        <div className='flex-center printWorkerInfo'>
                          {item.wImg ? (
                            <img className='workerInfoImg' src={`${item.wImg}?v=${randomString}`} alt='근로자 이미지' />
                          ) : (
                            <span className='material-symbols-outlined noUser workerInfoImg'>person</span>
                          )}
                          <div className='contents flex-center'>
                            <WorkerInfoPrintForm item={item} />
                          </div>
                        </div>
                      )}
                      {item.cCheck && printList(item, CtemplateList)}
                      {item.jCheck && printList(item, JtemplateList)}
                      {item.muscularCheck && item.muscularImg && <img className='full-page-image' src={`${item.muscularImg}?v=${randomString}`} alt='근골격계 증상조사표 이미지' />}
                      {item.painCheck && item.painImg && <img className='full-page-image' src={`${item.painImg}?v=${randomString}`} alt='작업관련 통증조사표 이미지' />}
                      {item.stressCheck && item.stressImg && <img className='full-page-image' src={`${item.stressImg}?v=${randomString}`} alt='직무스트레스 평가표 이미지' />}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </PrintContextConsumer>
        </div>
      </div>
    </SearchOptions>
  );
};

export default WorkerSearchOption;
