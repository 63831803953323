/**
 * 작성자 : 김광민
 * 날짜 : 2023.12.19
 * 경로 : 터널 관리 - 구역 현황 관리 - 차트 모달
 */

import TunnelChartProgressBar from './TunnelChartProgressBar';

type Props = {
  data: TunnelChartData;
  startPoint: boolean;
  endPoint: boolean;
  orderChange: boolean;
  timeOut: number;
  isTunnelReversed: boolean;
};

const TunnelChart = ({ data, startPoint, endPoint, orderChange, timeOut, isTunnelReversed }: Props) => {
  const { eLength, eRLength, sLength, sRLength, tatName, useYn, eUseYn, sUseYn } = data;

  if (useYn === 'N') {
    return null;
  }

  return (
    <tr className='tr' key={tatName}>
      <td className='td8 tableTitle'>{tatName}</td>
      {startPoint && (
        <td className={`td12 justify-center tdGrow ${orderChange ? 'order1' : ''}`}>
          <TunnelChartProgressBar rlength={sRLength} length={sLength} pointType='start' showOption={sUseYn} timeOut={timeOut} isTunnelReversed={isTunnelReversed} />
          {sUseYn === 'Y' ? (
            <span className='length start'>
              <span className='emphasize'>{sLength.toLocaleString()}</span>m
            </span>
          ) : (
            ''
          )}
        </td>
      )}
      {endPoint && (
        <td className={`td12 justify-center tdGrow ${orderChange ? 'order3' : ''}`}>
          <TunnelChartProgressBar rlength={eRLength} length={eLength} pointType='end' showOption={eUseYn} timeOut={timeOut} isTunnelReversed={isTunnelReversed} />
          {eUseYn === 'Y' ? (
            <span className='length end'>
              <span className='emphasize'>{eLength.toLocaleString()}</span>m
            </span>
          ) : (
            ''
          )}
        </td>
      )}
    </tr>
  );
};

export default TunnelChart;
