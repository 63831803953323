/**
 * 작성자 : 홍선영
 * 날짜 : 2023.07.11
 * 기능 : 안전지적 등록/수정/뷰 모달
 */

import { useState, useEffect, Dispatch, SetStateAction, useRef } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { userState } from '../../atoms';
import ReactSelect from '../ReactSelect';

import { IAuth, IComCd, IModal } from 'customTypes';
import { InputTable } from '../../assets/styles/InputTable';
import { ModalBackground, Modal } from '../../assets/styles/Modal';
import { BtnBlue, BtnGhost, BtnLightSkyBlue, BtnRed } from '../Button';
import Input from '../Input';
import Portal from '../Portal';
import Textarea from '../Textarea';
import Thumbnail from './Thumbnail';
import DeleteModal from './DeleteModal2';
import WorkerListModal from './WorkerListModal';
import DatePicker from '../DatePicker';
import { trimObject } from '../../utils/trimObject';
import { formatDateYMD, isValidYYYYMMDD, todayYYYYMMDD } from '../../utils/formatDate';
import { getFileNameFromURL, isImageFile } from '../../utils/formatFilePath';
import { logPost } from '../../services/log';
import { useTranslation } from 'react-i18next';
import { apiDelete, apiGet, apiPost } from '../../services/_common';

interface IProps {
  getReportListAPI: () => void;
  setOpenModal: Dispatch<SetStateAction<IModal>>;
  auth: IAuth;
  code: { hCd: string; sCd: string; sSeq: number };
  dashboard?: boolean; // 대시보드 모달 여부
}

interface ISafePointT {
  sSeq: number;
  sDate: string;
  sCode: string;
  sLocation: string;
  sChargename: string;
  sMemo: string;
  sFile?: any;
  srSum?: any;
  srDate?: any;
  srChargename?: any;
  srMemo?: any;
  srFile?: any;
}

interface ISelectedFile {
  type: string;
  url: string | null;
}

const SafePointModal = ({ getReportListAPI, setOpenModal, auth, code, dashboard }: IProps) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const initialEduInfo = {
    sLocation: '',
    sDate: '',
    srDate: '',
    sChargename: '',
    sMemo: '',
    sCode: '',
  };
  const [openSubModal, setOpenSubModal] = useState<IModal>({ status: false, type: '', isOverlappingModal: true });
  const [tableState, setTableState] = useState<any[]>([]); // 안전지적 근로자 리스트
  const [workerInputSet, setWorkerInputSet] = useState<any>(initialEduInfo);
  const [sDate, setSDate] = useState(todayYYYYMMDD());
  const [srDate, setSrDate] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [filePath, setFilePath] = useState<string>('');
  const [selectedFileUrl, setSelectedFileUrl] = useState<ISelectedFile>({ type: '', url: '' });
  const [selectedSrFile, setSelectedSrFile] = useState<File | undefined>(undefined);
  const [srFilePath, setSrFilePath] = useState<string>('');
  const [selectedSrFileUrl, setSelectedSrFileUrl] = useState<ISelectedFile>({ type: '', url: '' });
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const inputSrFileRef = useRef<HTMLInputElement | null>(null);
  const [isNewAdd, setIsNewAdd] = useState(false);
  const [pointtypeNormalCdList, setPointtypeNormalCdList] = useState([]);
  const [pointType, setPointType] = useState({ label: '', value: '' });
  const IMAGE = 'image';
  const FILE = 'file';
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [firstApiReq, setFirstApiReq] = useState(false);

  useEffect(() => {
    getNormalCdTitleAPI().then((res: any) => {
      if (res.status === 200) {
        setFirstApiReq(true);
      }
    });
  }, []);

  useEffect(() => {
    if (code.sSeq > 0 && firstApiReq) {
      getSafePointInfoAPI();
    }
    if (code.sSeq === 0) {
      setIsNewAdd(true);
    }
  }, [code, firstApiReq]);

  useEffect(() => {
    if (sDate !== '') setWorkerInputSet((prev: any) => ({ ...prev, sDate: sDate?.replace('-', '') }));
  }, [sDate]);

  useEffect(() => {
    if (srDate !== '') setWorkerInputSet((prev: any) => ({ ...prev, srDate: srDate?.replace('-', '') }));
  }, [srDate]);

  useEffect(() => {
    if (pointtypeNormalCdList.length > 0) {
      const findsCodeName: any = pointtypeNormalCdList.find((el: any) => el.value === pointType.value);
      if (findsCodeName) setPointType((prev: any) => ({ ...prev, label: findsCodeName.label }));
    }
  }, [pointtypeNormalCdList, pointType.value]);

  const getSafePointInfoAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, sSeq: Number(code.sSeq) };
    const res = await apiGet({ path: '/safe/point', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const { safePointD, safePointT } = data;
      setPointType({ label: '', value: safePointT.sCode });
      setWorkerInputSet((prev: any) => ({
        ...prev,
        sCode: safePointT.sCode,
        sLocation: safePointT.sLocation,
        sChargename: safePointT.sChargename,
        srChargename: safePointT.srChargename,
        sMemo: safePointT.sMemo,
        srMemo: safePointT.srMemo,
        srSum: safePointT.srSum,
      }));
      setSDate(safePointT.sDate);
      setSrDate(safePointT.srDate);
      setTableState(safePointD);
      setFilePath(safePointT.sFile);
      setSrFilePath(safePointT.srFile);

      const isImage = isImageFile(safePointT.sFile); // 경로 확장자에 따라 이미지인지 아닌지 판별
      setSelectedFileUrl({ url: safePointT.sFile, type: isImage ? IMAGE : FILE });

      const isSrImage = isImageFile(safePointT.srFile); // 경로 확장자에 따라 이미지인지 아닌지 판별
      setSelectedSrFileUrl({ url: safePointT.srFile, type: isSrImage ? IMAGE : FILE });
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 안전교육 조회 API
  const getNormalCdTitleAPI = async () => {
    const req = {
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      subCd: 'G',
    };
    const res = await apiGet({ path: '/code/pmsNormalTitle', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const formatSelectBoxFormList = data.pmsNormalTitleList.map((el: IComCd) => {
        return { value: el.subCd, label: el.cdName };
      });
      const addDefaultObject = { value: '', label: t('선택') };
      const newArray: any = [addDefaultObject, ...formatSelectBoxFormList];
      setPointtypeNormalCdList(newArray);
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  // 근로자 검색
  const onClickSearchWorker = async () => {
    setOpenSubModal((prev: any) => ({ ...prev, status: true, type: 'workerList' }));
  };

  const onClickClose = () => {
    if (!openSubModal.status) setOpenModal((prev: IModal) => ({ ...prev, status: false }));
    else setOpenSubModal((prev: IModal) => ({ ...prev, status: false }));
  };

  const deleteAPI = async (worker: any) => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, sSeq: code.sSeq, wCd: worker.wCd, sPointseq: worker.sPointseq, editor: userInfo.userId };
    const res = await apiDelete({ path: '/safe/pointD', contentType: 'application/json', req });
    const { statusCode, message } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      const newArray = tableState.filter((el) => el.sPointseq !== worker.sPointseq);
      setTableState(newArray);
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '안전 지적 정보(팝업)',
        action: '근로자 삭제',
        etc: `${worker.wName}(${worker.wCd})`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  const onClickDeletePointWorker = (el: any, index: number) => {
    if (el.sPointseq === undefined) {
      // 새로 선택한 근로자일경우
      const newArray = tableState.filter((_, i) => index !== i);
      setTableState(newArray);
    } else {
      setOpenSubModal((prev: any) => ({ ...prev, status: true, type: 'delete', api: () => deleteAPI(el) }));
    }
  };

  const onClickSaveSafePoint = () => {
    setIsSaveClicked(true);
    if (pointType.value === '') return toast.warning(t('안전지적 구분을 선택하세요'));

    if (!sDate) return toast.warning(t('적발일자를 선택하세요'));
    if (sDate && (sDate.length < 8 || !isValidYYYYMMDD(sDate))) return toast.warning(t('적발일자를 확인하세요'));
    if (srDate && (srDate.length < 8 || !isValidYYYYMMDD(srDate))) return toast.warning(t('확인일자를 확인하세요'));
    if (tableState.length === 0) return toast.warning(t('근로자를 선택하세요'));

    const trimData = trimObject(workerInputSet);
    return saveSafePointInfoAPI(trimData);
  };

  const saveSafePointInfoAPI = async (userInput: ISafePointT) => {
    const newArray = tableState
      .map((el) => {
        return {
          sPointseq: el.sPointseq || 0,
          wCd: el.wCd,
        };
      })
      .filter((el2: any) => el2.sPointseq === 0);

    const safePointTOjb = {
      file1: selectedFile,
      file2: selectedSrFile,
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      sSeq: code.sSeq,
      sCode: pointType.value,
      sDate: sDate || '',
      srDate: srDate || '',
      sLocation: userInput.sLocation,
      sChargename: userInput.sChargename,
      srChargename: userInput.srChargename,
      sFile: filePath,
      srFile: srFilePath,
      srSum: userInput.srSum,
      sMemo: userInput.sMemo,
      srMemo: userInput.srMemo,
      writer: userInfo.userId,
      editor: userInfo.userId,
    };

    const filteredObject = Object.fromEntries(Object.entries(safePointTOjb).filter(([key, value]) => value != null && value !== ''));
    const req = getReq({ ...filteredObject, safePointWorkerReqDto: newArray });
    const res = await apiPost({ path: '/safe/point', contentType: 'multipart/form-data', req });
    const { statusCode, data, message } = res.data;
    if (statusCode === 200) {
      // setWorkerInputSet(data);
      toast.success(t(message));
      getReportListAPI();
      setOpenModal((prev) => ({ ...prev, status: false }));
      if (isNewAdd) {
        await logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '안전 지적 정보(팝업)',
          action: '신규 등록',
          etc: `${formatDateYMD(sDate)}(${code.sSeq})`,
        });
      } else {
        await logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '안전 지적 정보(팝업)',
          action: '안전 지적 정보 저장',
          etc: `${formatDateYMD(sDate)}(${code.sSeq})`,
        });
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  const getReq = (reqData: any) => {
    const formData = new FormData();
    Object.keys(reqData).forEach((key) => {
      if (key === 'safePointWorkerReqDto') {
        reqData[key].forEach((point: any, index: number) => {
          Object.keys(point).forEach((pointKey) => {
            formData.append(`safePointWorkerReqDto[${index}][${pointKey}]`, point[pointKey]);
          });
        });
      } else {
        formData.append(key, reqData[key]);
      }
    });
    return formData;
  };

  const onClickDeleteSafePoint = async () => {
    setOpenSubModal((prev) => ({ ...prev, status: true, type: 'delete', api: deleteSafePointAPI }));
  };

  const deleteSafePointAPI = async () => {
    const newArray = tableState.map((el) => {
      return {
        sPointseq: el.sPointseq || 0,
        wCd: el.wCd,
      };
    });
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, sSeq: code.sSeq, editor: userInfo.userId, safePointWorkerReqDto: newArray };
    const res = await apiDelete({ path: '/safe/point', contentType: 'application/json', req });
    const { statusCode, message } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      getReportListAPI();
      setOpenModal((prev) => ({ ...prev, status: false }));
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '안전 지적 정보(팝업)',
        action: '안전 지적 정보 삭제',
        etc: `${formatDateYMD(sDate)}(${code.sSeq})`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const fileList = event.target.files;

    if (fileList) {
      const file = fileList[0];
      const fileSize = file.size;

      // 5MB in Bytes (5 * 1024 * 1024)
      if (fileSize > 5242880) {
        toast.warning(`${t('파일크기는 5MB를 초과할 수 없습니다')} (${fileSize / 1024 / 1024}/5MB)`);
      } else if (type === 'sr') {
        setSelectedSrFile(file);
        setSrFilePath(file.name);

        // 썸네일
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedSrFileUrl((prev: any) => ({ ...prev, type: IMAGE, url: reader.result as string }));
        };

        if (!file.type.startsWith('image/')) {
          // 파일이 이미지가 아닐때
          setSelectedSrFileUrl((prev: any) => ({ ...prev, type: FILE, url: reader.result as string }));
        } else {
          reader.readAsDataURL(file);
        }
      } else if (type === 's') {
        setSelectedFile(file);
        setFilePath(file.name);

        // 썸네일
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedFileUrl((prev: any) => ({ ...prev, type: IMAGE, url: reader.result as string }));
        };

        if (!file.type.startsWith('image/')) {
          // 파일이 이미지가 아닐때
          setSelectedFileUrl((prev: any) => ({ ...prev, type: FILE, url: reader.result as string }));
        } else {
          reader.readAsDataURL(file);
        }
      }
    }
  };

  const onClickDeleteThumbnail = (type: string) => {
    if (type === 's') {
      setFilePath('');
      setSelectedFileUrl({ type: '', url: '' });
    } else {
      setSrFilePath('');
      setSelectedSrFileUrl({ type: '', url: '' });
    }
  };

  const onClickThumbnail = (url: string) => {
    // 썸네일 확대클릭
    setOpenSubModal((prev: any) => ({ ...prev, status: true, type: 'thumbnail', data: { aImgPath: url }, thumbnailOnly: true }));
  };

  const renderFileBtn = (type: string) => {
    if (auth.createAuth || (auth.updateAuth && code.sSeq > 0)) {
      return (
        <>
          <label htmlFor={type === 's' ? 'filebox' : 'filebox2'} className='searchBox'>
            <div className='fileButton' id='filebox'>
              {t('파일선택')}
            </div>
          </label>
          {/* <span>5MB 까지 첨부 가능</span> */}
        </>
      );
    }
    return null;
  };

  const renderDeleteBtn = (el: any, i: number) => {
    if (auth.deleteAuth || auth.createAuth || auth.updateAuth) {
      return (
        <div className='trCol4 flex-center'>
          <BtnRed onClick={() => onClickDeletePointWorker(el, i)}>{el.sPointseq !== undefined ? t('삭제') : t('제거')}</BtnRed>
        </div>
      );
    }
    return null;
  };

  return (
    <ModalBackground onClick={onClickClose} role='presentation'>
      {openSubModal.status !== true && (
        <Modal>
          <div
            className='modal'
            role='presentation'
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div className='inputForm-head'>
              <div className='modalHeaderTitle'>
                {t('안전 지적')} {isNewAdd ? t('등록') : t('정보')}
              </div>
              <div className='closeBtn' onClick={onClickClose} role='presentation'>
                <span className='material-symbols-rounded'>close</span>
              </div>
            </div>

            <div className='modal-contents'>
              <div className='filter-option-grid'>
                <div className='reactSelectrWrapper'>
                  <label htmlFor='pointType' className='w6rem required'>
                    {t('구분')}
                  </label>
                  <ReactSelect
                    defaultValue={pointType}
                    options={pointtypeNormalCdList}
                    value={pointType}
                    state={pointType}
                    setState={setPointType}
                    isClearable={false}
                    isSearchable={false}
                    isSaveClicked={isSaveClicked}
                  />
                </div>
                <Input className='w6rem' label={t('적발장소')} type='text' name='sLocation' state={workerInputSet} setState={setWorkerInputSet} disabled={!auth.updateAuth && !auth.createAuth} />

                <div className=' plainText marginBottomNone datePickerWrapper'>
                  <label htmlFor='sDate' className='w6rem equired required'>
                    {t('적발일자')}
                  </label>
                  <DatePicker startDate={workerInputSet.sDate} setDate={setSDate} popperPlacement='bottom' isSaveClicked={isSaveClicked} />
                </div>
                <Input className='w6rem' label={t('적발담당자')} type='text' name='sChargename' state={workerInputSet} setState={setWorkerInputSet} disabled={!auth.updateAuth && !auth.createAuth} />
                <div className='span2 fileBoxWrapper'>
                  <label className='w6rem' htmlFor='file'>
                    {t('첨부 파일')}
                  </label>
                  <div className='flex-basic'>
                    <input
                      className='hiddenFileBox'
                      style={{ width: '50%', lineHeight: '2' }}
                      type='file'
                      accept='file'
                      id='filebox'
                      name='dIfilename'
                      ref={inputFileRef}
                      onChange={(e) => handleFileInputChange(e, 's')}
                    />
                    <input
                      placeholder={t('5MB 까지 첨부 가능')}
                      className='fileBoxInput'
                      style={{ width: '50%', lineHeight: '2' }}
                      type='text'
                      id='filebox'
                      name='dIfilename'
                      value={getFileNameFromURL(filePath)}
                      disabled
                    />
                    <div className='flex-basic'>{renderFileBtn('s')}</div>
                  </div>
                </div>
                {!isNewAdd && selectedFileUrl.url && (
                  <div className='span2 fileBoxWrapper preview'>
                    <label htmlFor='file' className='w6rem'>
                      {t('미리보기')}
                    </label>
                    <div className='preview'>
                      {/* <div className={selectedFileUrl.type === IMAGE ? 'thumbnailWrapper' : 'fileWrapper flex-start'}> */}
                      {selectedFileUrl.type === IMAGE ? (
                        <img className='thumbnail' src={selectedFileUrl.url || filePath} alt='' onClick={() => onClickThumbnail(selectedFileUrl.url || filePath)} role='presentation' />
                      ) : (
                        <div>{getFileNameFromURL(filePath)}</div>
                      )}
                      <div className='buttonsWrapper'>
                        <BtnGhost>
                          <a href={filePath}>{t('다운로드')}</a>
                        </BtnGhost>
                        <BtnGhost onClick={() => onClickDeleteThumbnail('s')}>
                          <span className='material-symbols-rounded'>delete</span>
                          {t('삭제')}
                        </BtnGhost>
                      </div>
                    </div>
                  </div>
                  // </div>
                )}
                <div className='span2'>
                  <Textarea
                    className='w6rem'
                    display='flex'
                    flexDirection='row'
                    label={t('위반 내용')}
                    name='sMemo'
                    state={workerInputSet}
                    set={setWorkerInputSet}
                    disabled={!auth.updateAuth && !auth.createAuth}
                  />
                </div>
              </div>

              <div className='flex-between tableTop'>
                <div className='tableTitle required'>
                  <span>{t('안전지적 근로자')}</span>
                  <span className='red' style={{ paddingLeft: '1rem' }}>
                    {t('안전지적 3회 이상일 경우 출입금지자 자동 등록')}
                  </span>
                </div>
                <div className='buttonsWrapper'>
                  {(auth.createAuth || auth.updateAuth) && (
                    <BtnLightSkyBlue className='btn-small' onClick={onClickSearchWorker}>
                      {t('근로자 검색')}
                    </BtnLightSkyBlue>
                  )}
                </div>
              </div>

              <InputTable className='inputTable-overflow'>
                <div className='thead'>
                  <div className='tr'>
                    <div className='trCol2p5 flex-center tableStickyNo'>{t('순번')}</div>
                    <div className='trCol8 flex-center content-overflow'>{t('협력업체')}</div>
                    <div className='trCol8 flex-center content-overflow'>{t('근로자명')}</div>
                    {auth.deleteAuth && <div className='trCol4'> </div>}
                  </div>
                </div>
                <div className='table'>
                  <div className='tbody'>
                    {tableState.map((el: any, i: number) => (
                      <div className='tr' key={i}>
                        <div className='trCol2p5 flex-center tableStickyNo'>{i + 1}</div>
                        <div className='trCol8 flex-basic content-overflow'>{el.sjName}</div>
                        <div className='trCol8 flex-basic content-overflow'>{el.wName}</div>
                        {renderDeleteBtn(el, i)}
                      </div>
                    ))}
                  </div>
                </div>
              </InputTable>
              <div className='filter-option-grid point'>
                {/* <h5 className='subHead'>안전지적 조치사항</h5> */}
                {/* <div className='span2' /> */}
                <Input className='w6rem' label={t('조치결과 요약')} type='text' name='srSum' state={workerInputSet} setState={setWorkerInputSet} disabled={!auth.updateAuth && !auth.createAuth} />
                <div className='grid plainText marginBottomNone datePickerWrapper'>
                  <label className='w6rem' htmlFor='srDate'>
                    {t('확인 일자')}
                  </label>
                  <DatePicker startDate={workerInputSet.srDate} setDate={setSrDate} popperPlacement='bottom' />
                </div>
                <Input className='w6rem' label={t('확인 담당자')} type='text' name='srChargename' state={workerInputSet} setState={setWorkerInputSet} disabled={!auth.updateAuth && !auth.createAuth} />

                <div className='span2 fileBoxWrapper'>
                  <label className='w6rem' htmlFor='file'>
                    {t('첨부 파일')}
                  </label>
                  <div className='flex-basic'>
                    <input
                      className='hiddenFileBox'
                      style={{ width: '50%', lineHeight: '2' }}
                      type='file'
                      accept='file'
                      id='filebox2'
                      name='dIfilename'
                      ref={inputSrFileRef}
                      onChange={(e) => handleFileInputChange(e, 'sr')}
                    />
                    <input
                      placeholder={t('5MB 까지 첨부 가능')}
                      className='fileBoxInput'
                      style={{ width: '50%', lineHeight: '2' }}
                      type='text'
                      id='filebox2'
                      name='dIfilename'
                      value={getFileNameFromURL(srFilePath)}
                      disabled
                    />
                    <div className='flex-basic'>{renderFileBtn('sr')}</div>
                  </div>
                </div>
                {!isNewAdd && selectedSrFileUrl.url && (
                  // <div className='fileBoxWrapper'>
                  //   <label htmlFor='file'> </label>
                  //   <div className='flex-basic' style={{ paddingTop: '1rem' }}>
                  //     <div className={selectedSrFileUrl.type === IMAGE ? 'thumbnailWrapper' : 'fileWrapper flex-start'}>
                  //       {selectedSrFileUrl.type === IMAGE ? (
                  //         <img className='thumbnail' src={selectedSrFileUrl.url || srFilePath} alt='' onClick={() => onClickThumbnail(selectedSrFileUrl.url || srFilePath)} role='presentation' />
                  //       ) : (
                  //         <div>{getFileNameFromURL(srFilePath)}</div>
                  //       )}
                  //       <div className='flex-end buttonsWrapper'>
                  //         <BtnLightViolet>
                  //           <a href={srFilePath}>다운로드</a>
                  //         </BtnLightViolet>
                  //         <BtnRed onClick={() => onClickDeleteThumbnail('sr')}>삭제</BtnRed>
                  //       </div>
                  //     </div>
                  //   </div>
                  // </div>
                  <div className='span2 fileBoxWrapper preview'>
                    <label htmlFor='file' className='w6rem'>
                      {t('미리보기')}
                    </label>
                    <div className='preview'>
                      {selectedSrFileUrl.type === IMAGE ? (
                        <img className='thumbnail' src={selectedSrFileUrl.url || srFilePath} alt='' onClick={() => onClickThumbnail(selectedSrFileUrl.url || srFilePath)} role='presentation' />
                      ) : (
                        <div>{getFileNameFromURL(srFilePath)}</div>
                      )}
                      <div className='buttonsWrapper'>
                        <BtnGhost>
                          <a href={srFilePath}>{t('다운로드')}</a>
                        </BtnGhost>
                        <BtnGhost onClick={() => onClickDeleteThumbnail('sr')}>
                          <span className='material-symbols-rounded'>delete</span>
                          {t('삭제')}
                        </BtnGhost>
                      </div>
                    </div>
                  </div>
                )}
                <div className='span2'>
                  <Textarea
                    className='w6rem'
                    display='flex'
                    flexDirection='row'
                    label={t('상세 내용')}
                    name='srMemo'
                    state={workerInputSet}
                    set={setWorkerInputSet}
                    disabled={!auth.updateAuth && !auth.createAuth}
                  />
                </div>
              </div>
            </div>
            <div className='modal-footer flex-end'>
              {auth.deleteAuth && !isNewAdd && <BtnRed onClick={onClickDeleteSafePoint}>{t('삭제')}</BtnRed>}
              {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={onClickSaveSafePoint}>{t('저장')}</BtnBlue>}
              {/* <BtnGray onClick={onClickClose}>닫기</BtnGray> */}
            </div>
          </div>
        </Modal>
      )}
      {openSubModal.status === true && openSubModal.type === 'workerList' && (
        <WorkerListModal openModal={openSubModal} setOpenModal={setOpenSubModal} state={tableState} setState={setTableState} isIncludeBlackUser useCheckbox onClickClose={onClickClose} />
      )}
      <Portal openModal={openSubModal?.status}>
        {openSubModal.status === true && openSubModal.type === 'delete' && <DeleteModal openModal={openSubModal} setOpenModal={setOpenSubModal} />}
        {openSubModal.status === true && openSubModal.type === 'thumbnail' && <Thumbnail openModal={openSubModal} setOpenModal={setOpenSubModal} />}
      </Portal>
    </ModalBackground>
  );
};

export default SafePointModal;
