/**
 * 작성자 : 한영광
 * 날짜 : 2023.05.24
 * 기능 : 본사사용자관리-사용자정보-사용자메뉴관리 페이지
 */

import { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import { useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { ERROR } from '../../../_constants';
import { userState } from '../../../atoms';
import { BtnBlue, BtnGreen } from '../../../components/Button';
import { InputTable } from '../../../assets/styles/InputTable';
import SelectBoxs from '../../../components/SelectBoxs';
import { logPost } from '../../../services/log';
import { useSetAuth } from '../../../utils/useSetAuth';
import { useTranslation } from 'react-i18next';
import { IModal } from 'customTypes';
import Portal from '../../../components/Portal';
import UserListModal from '../../../components/Modal/UserListModal';
import { apiGet, apiPost } from '../../../services/_common';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 0.5rem;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;
const Root = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex-grow: 1;
  .thead {
    position: sticky;
    top: 0;
  }
  .detailInfo {
    padding: 0.5rem;
    position: sticky;
    left: 0;
    .userSet-data {
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      height: 2.5rem;
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      padding: 0 0.5rem;
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      outline: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      border-radius: 0.25rem;
      span:nth-child(n + 2)::before {
        content: '•';
        margin: 0 0.25rem;
      }
    }
  }
`;
const CheckBox = styled.div`
  display: flex;
  align-items: center;
  > label {
    font-size: 1.25rem;
    font-weight: initial;
  }
`;

interface Iprops {
  orgUserInfo: any;
  setOrgUserInfo: Dispatch<SetStateAction<any>>;
  initBtnClick: any;
  setInitBtnClick: Dispatch<SetStateAction<any>>;
  viewTableState: any[];
}
interface IMenuTableObject {
  index: number;
  userId: string;
  hCd: string;
  sCd: string;
  gCd: string;
  mtCd: string;
  mtName: string;
  mdCd: string;
  mdName: string;
  mrCd: string;
  mrName: string;
  useYn: string;
  cYn: string;
  uYn: string;
  dYn: string;
  eYn: string;
  pYn: string;
  rSearch: number;
  writer: string;
  depth: string;
}

const UserMenu = ({ orgUserInfo, setOrgUserInfo, initBtnClick, setInitBtnClick, viewTableState }: Iprops) => {
  const { t } = useTranslation();
  const { auth, userMenuGrant } = useSetAuth(); // 사용자 권한값 훅
  const userInfo = useRecoilValue(userState);
  const { userMenuList } = userInfo;
  const [allCheckBoxs, setAllCheckBoxs] = useState({
    useYn: 'N',
    cYn: 'N',
    uYn: 'N',
    dYn: 'N',
    eYn: 'N',
    pYn: 'N',
  });
  const [listCount, setListCount] = useState<any[]>([]);
  const [menuTableState, setMenuTableState] = useState<IMenuTableObject[]>([]);
  const [rawData, setRawData] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState<any>({ status: false });

  useEffect(() => {
    if (orgUserInfo.length !== 0) {
      getUserMenuAPI(orgUserInfo.userId);
    }
  }, [orgUserInfo]);

  useEffect(() => {
    getListCountAPI();
  }, []);

  useEffect(() => {
    if (initBtnClick) {
      setMenuTableState([]);
      setInitBtnClick(false);
    }
  }, [initBtnClick]);

  // 공통코드(검색 줄수) 조회 API
  const getListCountAPI = async () => {
    const res = await apiGet({ path: '/menu/ListCount' });
    const { data, message, statusCode } = res.data;
    if (statusCode === 200) {
      const newListCount = data.map((el: any, i: number) => {
        return {
          idx: i,
          type: 'rSearch',
          rSearch: el,
        };
      });
      setListCount(newListCount);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 사용자 메뉴 조회 API
  const getUserMenuAPI = async (userId: string) => {
    const req = { userId, hCd: userInfo.hCd, sCd: '00000' };
    const res = await apiGet({ path: '/menu/usermenu', req });
    const { data, message, statusCode } = res.data;
    if (statusCode === 200) {
      const newTableState = data
        .filter((v: any) => v.depth !== '0')
        .map((el: any, i: number) => {
          return { index: i + 1, ...el };
        });
      setMenuTableState(newTableState);
      setRawData(data);
      setAllCheckBoxs({
        useYn: 'N',
        cYn: 'N',
        uYn: 'N',
        dYn: 'N',
        eYn: 'N',
        pYn: 'N',
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 헤더 체크박스 클릭
  const handleCheckboxChangeHeader = (state: any, setState: Dispatch<SetStateAction<any>>, key: string) => {
    setState({ ...state, [key]: state[key] === 'Y' ? 'N' : 'Y' });
    const newMenuTableState: any = [...menuTableState];
    for (const v of newMenuTableState) {
      v[key] = state[key] === 'Y' ? 'N' : 'Y';
    }
    setMenuTableState(newMenuTableState);
  };

  // 메뉴 체크박스 클릭
  const handleCheckboxChangeMenu = (state: string, key: string, mdCd: string, mtCd: string) => {
    const newMenuTableState: any = [...menuTableState];
    let checkHeader = true;
    for (const v of newMenuTableState) {
      if (v.mdCd === mdCd && v.mtCd === mtCd) {
        v[key] = state === 'Y' ? 'N' : 'Y';
      }
      if (v[key] === 'N') {
        checkHeader = false;
      }
    }

    if (state === 'Y') {
      setAllCheckBoxs({ ...allCheckBoxs, [key]: 'N' });
    } else if (checkHeader) {
      setAllCheckBoxs({ ...allCheckBoxs, [key]: 'Y' });
    }
    setMenuTableState(newMenuTableState);
  };

  // 보고서 체크박스 클릭
  const handleCheckboxChangeReport = (state: string, i: number, key: string) => {
    const newMenuTableState: any = [...menuTableState];
    newMenuTableState[i][key] = state === 'Y' ? 'N' : 'Y';
    let checkMenu = true;
    let checkHeader = true;
    for (const v of newMenuTableState) {
      if (v[key] === 'N') {
        checkHeader = false;
      }
      if (v.mdCd === newMenuTableState[i].mdCd && v.mtCd === newMenuTableState[i].mtCd && v.depth === '1') {
        v[key] = state === 'N' && 'N';
      } else if (v.mdCd === newMenuTableState[i].mdCd && v.mtCd === newMenuTableState[i].mtCd && v.depth === '2' && v[key] === 'N') {
        checkMenu = false;
      }
    }
    if (checkMenu) {
      for (const v of newMenuTableState) {
        if (v.mdCd === newMenuTableState[i].mdCd && v.mtCd === newMenuTableState[i].mtCd && v.depth === '1') {
          v[key] = 'Y';
        }
      }
    }
    if (state === 'Y') {
      setAllCheckBoxs({ ...allCheckBoxs, [key]: 'N' });
    } else if (checkHeader) {
      setAllCheckBoxs({ ...allCheckBoxs, [key]: 'Y' });
    }
    setMenuTableState(newMenuTableState);
  };

  // 저장 버튼 클릭
  const onClickSave = async () => {
    let reqData = menuTableState
      .filter((v: any) => v.depth === '2' && v.useYn === 'Y')
      .map((v: any) => {
        return {
          userId: orgUserInfo.userId,
          hCd: userInfo.hCd,
          sCd: '00000',
          gCd: orgUserInfo.gCd,
          mtCd: v.mtCd,
          mdCd: v.mdCd,
          mrCd: v.mrCd,
          useYn: v.useYn,
          cYn: v.cYn,
          uYn: v.uYn,
          dYn: v.dYn,
          eYn: v.eYn,
          pYn: v.pYn,
          rSearch: Number(v.rSearch),
          writer: userInfo.userId,
          depth: v.depth,
        };
      });
    /**
     * 수정자 : 한영광
     * 수정일자 : 2023.05.30
     * 수정내용 : 모든 보고서 사용유무가 false인 경우를 depth === ''로 체크함
     */
    if (reqData.length === 0) {
      reqData = [
        {
          userId: orgUserInfo.userId,
          hCd: userInfo.hCd,
          sCd: '00000',
          gCd: orgUserInfo.gCd,
          mtCd: '',
          mdCd: '',
          mrCd: '',
          useYn: '',
          cYn: '',
          uYn: '',
          dYn: '',
          eYn: '',
          pYn: '',
          rSearch: 0,
          writer: '',
          depth: '',
        },
      ];
    }
    const req = { userMenuReqDtoList: reqData };
    const res = await apiPost({ path: '/menu/usermenu', contentType: 'application/json', req });
    const { data, message, statusCode } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      const newTableState = data
        .filter((v: any) => v.depth !== '0')
        .map((el: any, i: number) => {
          return { index: i + 1, ...el };
        });
      setMenuTableState(newTableState);
      setRawData(data);
      setAllCheckBoxs({
        useYn: 'N',
        cYn: 'N',
        uYn: 'N',
        dYn: 'N',
        eYn: 'N',
        pYn: 'N',
      });
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '사용자 정보 / 메뉴 관리',
        action: '메뉴 저장',
        etc: `${orgUserInfo.userName}(${orgUserInfo.userId})`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  const onClickCopy = () => {
    setOpenModal({ status: true });
  };

  const onClickClose = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };

  const tableRaw = (el: any, i: number) => {
    return (
      <>
        <div className='trCol2p5 flex-center tableStickyNo'>{el.index}</div>
        <div className='trCol6 tableStickyTitle'>{t(el.mtName)}</div>
        <div className='trCol11'>{t(el.mdName)}</div>
        <div className='trCol12 tableStickyTitle'>{t(el.mrName)}</div>
        <div className='trCol6p5 flex-center'>
          <input
            type='checkbox'
            checked={el.useYn === 'Y'}
            onChange={() => (el.depth === '1' ? handleCheckboxChangeMenu(el.useYn, 'useYn', el.mdCd, el.mtCd) : handleCheckboxChangeReport(el.useYn, i, 'useYn'))}
          />
        </div>
        <div className='trCol5 flex-center'>
          {el.depth === '0' ? null : (
            <input
              type='checkbox'
              checked={el.cYn === 'Y'}
              onChange={() => (el.depth === '1' ? handleCheckboxChangeMenu(el.cYn, 'cYn', el.mdCd, el.mtCd) : handleCheckboxChangeReport(el.cYn, i, 'cYn'))}
            />
          )}
        </div>
        <div className='trCol5 flex-center'>
          {el.depth === '0' ? null : (
            <input
              type='checkbox'
              checked={el.uYn === 'Y'}
              onChange={() => (el.depth === '1' ? handleCheckboxChangeMenu(el.uYn, 'uYn', el.mdCd, el.mtCd) : handleCheckboxChangeReport(el.uYn, i, 'uYn'))}
            />
          )}
        </div>
        <div className='trCol5 flex-center'>
          {el.depth === '0' ? null : (
            <input
              type='checkbox'
              checked={el.dYn === 'Y'}
              onChange={() => (el.depth === '1' ? handleCheckboxChangeMenu(el.dYn, 'dYn', el.mdCd, el.mtCd) : handleCheckboxChangeReport(el.dYn, i, 'dYn'))}
            />
          )}
        </div>
        <div className='trCol5 flex-center'>
          {el.depth === '0' ? null : (
            <input
              type='checkbox'
              checked={el.eYn === 'Y'}
              onChange={() => (el.depth === '1' ? handleCheckboxChangeMenu(el.eYn, 'eYn', el.mdCd, el.mtCd) : handleCheckboxChangeReport(el.eYn, i, 'eYn'))}
            />
          )}
        </div>
        <div className='trCol5 flex-center'>
          {el.depth === '0' ? null : (
            <input
              type='checkbox'
              checked={el.pYn === 'Y'}
              onChange={() => (el.depth === '1' ? handleCheckboxChangeMenu(el.pYn, 'pYn', el.mdCd, el.mtCd) : handleCheckboxChangeReport(el.pYn, i, 'pYn'))}
            />
          )}
        </div>
        <div className='trCol5 flex-center'>
          {el.depth === '2' ? (
            <SelectBoxs
              options={listCount}
              defaultOption={el.rSearch}
              state={menuTableState}
              setState={setMenuTableState}
              rawData={rawData}
              setRawData={setRawData}
              stateKey='rSearch'
              codeKey='rSearch'
              index={i}
              object={el}
              primaryKey='type'
              initiateKey={rawData}
            />
          ) : null}
        </div>
      </>
    );
  };

  return (
    <Root>
      <div className='tableWrapper'>
        <div className='detailInfo'>
          <div className='userSet-data'>
            <span>{orgUserInfo.userId}</span>
            <span>{orgUserInfo.userName}</span>
            <span>{t(orgUserInfo.gName)}</span>
          </div>
        </div>
        <InputTable>
          <div>
            <div className='tableTop' />
          </div>
          <div className='thead'>
            <div className='tr'>
              <div className='trCol2p5 flex-center tableStickyNo'>{t('No')}</div>
              <div className='trCol6 tableStickyTitle'>{t('타이틀 명')}</div>
              <div className='trCol11'>{t('메뉴 명')}</div>
              <div className='trCol12 tableStickyTitle'>{t('보고서명')}</div>
              <div className='trCol6p5 flex-center gap05'>
                <input type='checkbox' checked={allCheckBoxs.useYn === 'Y'} onChange={() => handleCheckboxChangeHeader(allCheckBoxs, setAllCheckBoxs, 'useYn')} />
                {t('사용유무')}
              </div>
              <div className='trCol5 flex-center gap05'>
                <input type='checkbox' checked={allCheckBoxs.cYn === 'Y'} onChange={() => handleCheckboxChangeHeader(allCheckBoxs, setAllCheckBoxs, 'cYn')} />
                {t('등록')}
              </div>
              <div className='trCol5 flex-center gap05'>
                <input type='checkbox' checked={allCheckBoxs.uYn === 'Y'} onChange={() => handleCheckboxChangeHeader(allCheckBoxs, setAllCheckBoxs, 'uYn')} />
                {t('수정')}
              </div>
              <div className='trCol5 flex-center gap05'>
                <input type='checkbox' checked={allCheckBoxs.dYn === 'Y'} onChange={() => handleCheckboxChangeHeader(allCheckBoxs, setAllCheckBoxs, 'dYn')} />
                {t('삭제')}
              </div>
              <div className='trCol5 flex-center gap05'>
                <input type='checkbox' checked={allCheckBoxs.eYn === 'Y'} onChange={() => handleCheckboxChangeHeader(allCheckBoxs, setAllCheckBoxs, 'eYn')} />
                {t('엑셀')}
              </div>
              <div className='trCol5 flex-center gap05'>
                <input type='checkbox' checked={allCheckBoxs.pYn === 'Y'} onChange={() => handleCheckboxChangeHeader(allCheckBoxs, setAllCheckBoxs, 'pYn')} />
                {t('인쇄')}
              </div>
              <div className='trCol5 flex-center'>{t('보기 설정')}</div>
            </div>
          </div>
          <div className='table'>
            <div className='tbody'>
              {menuTableState.length === 0 && <center>{/* <h1>사용자를 선택하세요</h1> */}</center>}
              {menuTableState?.map((el: any, i: number) =>
                el.depth === '1' ? (
                  <div className='tr' key={i} role='button' tabIndex={0} style={{ backgroundColor: '#F5F5F5' }}>
                    {tableRaw(el, i)}
                  </div>
                ) : (
                  <div className='tr' key={i} role='button' tabIndex={0}>
                    {tableRaw(el, i)}
                  </div>
                )
              )}
            </div>
          </div>
        </InputTable>
      </div>
      <ButtonsWrapper>
        {menuTableState.length !== 0 && (auth.createAuth || auth.updateAuth) && (
          <>
            {(userInfo.gCd === 'MM' || userInfo.gCd === 'HM') && (
              <BtnGreen
                onClick={() => {
                  onClickCopy();
                }}
              >
                {t('권한 복사')}
              </BtnGreen>
            )}
            <BtnBlue
              onClick={() => {
                onClickSave();
              }}
            >
              {t('저장')}
            </BtnBlue>
          </>
        )}
      </ButtonsWrapper>
      <Portal openModal={openModal?.status}>
        <UserListModal state={viewTableState} onClickClose={onClickClose} getUserMenuAPI={getUserMenuAPI} />
      </Portal>
    </Root>
  );
};

export default UserMenu;
