/**
 * 길이 계산 함수
 * @param data - 길이 데이터가 포함된 객체
 * @param type - 계산할 길이 타입 ('sLength', 'eLength', 'sRLength', 'eRLength')
 * @returns 계산된 길이 값
 */
export const getLength = (data: { [key: string]: string | number }, type: 'sLength' | 'eLength' | 'sRLength' | 'eRLength'): number => {
  const value = data?.[type];
  return typeof value === 'number' ? value : Number(value.replace(/,/g, ''));
};

/**
 * 전체 Length 계산 함수
 * @param data - 길이 데이터가 포함된 객체
 * @returns 전체 Length 값
 */
export const getTotalLength = (data: { [key: string]: string | number }): number => getLength(data, 'sLength') + getLength(data, 'eLength');

/**
 * 전체 RLength 계산 함수
 * @param data - 길이 데이터가 포함된 객체
 * @returns 전체 RLength 값
 */
export const getTotalRLength = (data: { [key: string]: string | number }): number => getLength(data, 'sRLength') + getLength(data, 'eRLength');

/**
 * 전체 진행률 계산 함수
 * @param data - 길이 데이터가 포함된 객체
 * @returns 전체 진행률 (문자열 형태의 퍼센트 값)
 */
export const getTotalPercentage = (data: { [key: string]: string | number }): string => {
  const totalLength = getLength(data, 'sLength') + getLength(data, 'eLength');
  const totalRLength = getLength(data, 'sRLength') + getLength(data, 'eRLength');
  return totalLength !== 0 ? ((totalRLength / totalLength) * 100).toFixed(0) : '0';
};
