/**
 * 작성자 : 홍선영
 * 날짜 : 2023.07.20
 * 기능 : 권한 useState값 세팅하는 hook
 */

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { IAuth } from 'customTypes';
import { IUser, pageInfoState, userState } from '../atoms';
import CheckUserMenuGrant from './checkUserMenuGrant';
import { useTranslation } from 'react-i18next';
import { apiGet } from '../services/_common';

export const useSetAuth = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const userInfo = useRecoilValue<IUser>(userState);
  const { mtCd, mdCd, mrCd } = useRecoilValue(pageInfoState);
  const [userMenuGrant, setUserMenuGrant] = useState<any>({
    cYn: '',
    uYn: '',
    dYn: '',
    eYn: '',
    pYn: '',
    rSearch: '15',
  });
  const [auth, setAuth] = useState<IAuth>({
    createAuth: false,
    updateAuth: false,
    deleteAuth: false,
    excelAuth: false,
    printAuth: false,
  });

  useEffect(() => {
    if (
      userInfo.gCd !== 'MM' &&
      mtCd[0] !== 'M' &&
      !(userInfo.gCd === 'AM' && mtCd.substring(0, 1) === 'H') &&
      !(userInfo.gCd === 'AM' && mtCd.substring(0, 1) === 'S') &&
      !(userInfo.gCd.substring(0, 1) === 'H' && mtCd.substring(0, 1) === 'S')
    ) {
      // 사용자 사이드메뉴, 메뉴권한 리스트 조회 API
      const getUserSideMenuAPI = async () => {
        const res = await apiGet({ path: '/menu/userSideMenu', req: { userId: userInfo.userId } });
        const { data, statusCode } = res.data;
        if (statusCode === 200) {
          setUserMenuGrant(CheckUserMenuGrant({ mtCd, mdCd, mrCd }, data.userMenuList, pathname, t));
        }
      };
      getUserSideMenuAPI();
    }
  }, []);

  useEffect(() => {
    if (userInfo.gCd === 'MM') setAuth({ createAuth: true, updateAuth: true, deleteAuth: true, printAuth: true, excelAuth: true });
    if (mtCd.substring(0, 1) === 'H') {
      if (userInfo.gCd === 'AM') setAuth({ createAuth: true, updateAuth: true, deleteAuth: true, printAuth: true, excelAuth: true });
    } else if (mtCd.substring(0, 1) === 'S') {
      if (userInfo.gCd === 'AM') setAuth({ createAuth: true, updateAuth: true, deleteAuth: true, printAuth: true, excelAuth: true });
      if (userInfo.gCd.substring(0, 1) === 'H') setAuth({ createAuth: true, updateAuth: true, deleteAuth: true, printAuth: true, excelAuth: true });
    }
    if (userMenuGrant.cYn === 'Y') setAuth((prev) => ({ ...prev, createAuth: true, updateAuth: true }));
    if (userMenuGrant.uYn === 'Y') setAuth((prev) => ({ ...prev, updateAuth: true }));
    if (userMenuGrant.dYn === 'Y') setAuth((prev) => ({ ...prev, deleteAuth: true }));
    if (userMenuGrant.eYn === 'Y') setAuth((prev) => ({ ...prev, excelAuth: true }));
    if (userMenuGrant.pYn === 'Y') setAuth((prev) => ({ ...prev, printAuth: true }));
  }, [userMenuGrant, userInfo.gCd]);

  return { auth, userMenuGrant };
};
