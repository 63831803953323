import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import TunnelTdProgressBar from './TunnelTdProgressbar';
import { EndPoint, StartPoint } from './TunnelPoint';

const renderSpreadRow = ({ tarea, showOption }: { tarea: TareaData; showOption: boolean }) => {
  const { sLength, eLength, sUseYn, eUseYn, eRLength, sRLength, sBigo, eBigo, sTesName, eTesName, sTjcName, eTjcName } = tarea;
  if (showOption) {
    return (
      <>
        {renderRow({ useYnType: 's', useYn: sUseYn, length: sLength, rlength: sRLength, bigo: sBigo, tjcName: sTjcName, tesName: sTesName })}
        {renderRow({ useYnType: 'e', useYn: eUseYn, length: eLength, rlength: eRLength, bigo: eBigo, tjcName: eTjcName, tesName: eTesName })}
      </>
    );
  }
  return null;
};

type RenderRow = { useYnType: string; useYn: string; length: number; rlength: number; bigo: string; tjcName: string; tesName: string };

const renderRow = ({ useYnType, useYn, length, rlength, bigo, tjcName, tesName }: RenderRow) => {
  if (useYn === 'Y') {
    return (
      <tr className='tr subTr'>
        <td className='td4 justify-center sticky' />
        <td className='td2p5 justify-center' />
        <td className='td4 justify-center' />
        <td className='td10'>{useYnType === 's' ? <StartPoint /> : <EndPoint />}</td>
        <td className='td8 tdGrow justify-end'>
          <DistanceTd length={length} />
        </td>
        <td className='td12 tdGrow'>
          <TunnelTdProgressBar rlength={rlength} length={length} />
        </td>
        <td className='td12 tdGrow bigo text-ellipsis'>{tjcName}</td>
        <td className='td10 tdGrow bigo text-ellipsis'>{tesName}</td>
        <td className='td10 tdGrow bigo text-ellipsis'>{bigo}</td>
      </tr>
    );
  }
  return null;
};

const DistanceTdCell = styled.div`
  display: flex;
  gap: 0.5rem;
  font-weight: 400;
  span.emphasize {
    font-weight: 600;
  }
  > span.kilometer {
    font-weight: 400;
    border-radius: 0.5rem;
    padding: 0 0.375rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
    color: ${({ theme }: { theme: any }) => theme.filled_blue};
  }
`;
const DistanceTd = ({ length }: { length: number }) => {
  return (
    <DistanceTdCell>
      <span className='meter'>
        <span className='emphasize'>{length.toLocaleString()}</span>
      </span>
      <span className='kilometer'>
        <span className='emphasize'>{(length / 1000).toLocaleString()}</span>
        km
      </span>
    </DistanceTdCell>
  );
};

type Props = {
  tarea: TareaData;
  expandRowList: { cd: string; status: boolean }[];
  setExpandRowList: Dispatch<SetStateAction<{ cd: string; status: boolean }[]>>;
  onClickRow: (tarea: TareaData) => void;
};

const TunnelTableBody = ({ tarea, expandRowList, setExpandRowList, onClickRow }: Props) => {
  const { tatName, no, tatCd, tLength, sLength, eLength, bigo, useYn } = tarea;
  const [showOption, setShowOption] = useState(expandRowList[no - 1] ? expandRowList[no - 1].status : true);

  useEffect(() => {
    if (expandRowList[no - 1]) {
      setShowOption(expandRowList[no - 1].status);
    }
  }, [expandRowList[no - 1]]);

  // 펼치기 접기 버튼 클릭 이벤트
  const onClickExpand = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setExpandRowList((prevExpandRowList) => {
      const updatedExpandRowList = [...prevExpandRowList];
      updatedExpandRowList[no - 1] = { ...updatedExpandRowList[no - 1], status: !showOption };
      return updatedExpandRowList;
    });
  };

  return (
    <>
      <tr className='tr clickable' key={tatName} role='presentation' onClick={() => onClickRow(tarea)}>
        <td className='td4 justify-center sticky'>
          <button type='button' onClick={(e) => onClickExpand(e)} className={showOption ? 'less' : 'more'}>
            {showOption ? <span className='material-symbols-rounded'>expand_less</span> : <span className='material-symbols-rounded'>expand_more</span>}
          </button>
        </td>
        <td className='td2p5 justify-center'>{no}</td>
        <td className='td4 justify-center'>{tatCd}</td>
        <td className='td10'>{tatName}</td>
        <td className='td8 tdGrow justify-end'>
          <DistanceTd length={tLength} />
        </td>
        <td className='td12 tdGrow justify-end'>{!showOption && <DistanceTd length={sLength} />}</td>
        <td className='td12 tdGrow justify-end'>{!showOption && <DistanceTd length={eLength} />}</td>
        <td className='td10 tdGrow justify-center'>{useYn === 'Y' ? <span className='material-symbols-rounded done'>done</span> : <span className='material-symbols-rounded close'>close</span>}</td>
        <td className='td10 tdGrow bigo text-ellipsis'>{bigo}</td>
      </tr>
      {renderSpreadRow({ tarea, showOption })}
    </>
  );
};

export default TunnelTableBody;
