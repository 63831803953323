/**
 * 작성자 : 홍선영
 * 날짜 : 2024.05.03
 * 경로 : 협력업체 직종 관리
 */

import React, { Dispatch, RefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PulseLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { userState } from '../../../atoms';
import { apiDelete, apiGet, apiPost } from '../../../services/_common';
import IssueGuide from '../../../components/IssueGuide';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import Input from '../../../components/Input';
import SelectBox from '../../../components/SelectBox';
import { useFetchCommonCodeList } from '../../../services/useSetCodeListInSelectBoxForm';
import { COMCD_USE_YN, FLAG_CREATE_OR_UPDATE, INIT_USE_YN_A } from '../../../_constants';
import { BtnBlue, BtnGhost, BtnRed } from '../../../components/Button';
import { InputTable } from '../../../assets/styles/InputTable';
import { IAuth, IModal } from 'customTypes';
import Portal from '../../../components/Portal';
import { toast } from 'react-toastify';
import { getJobypeCodeList, jobtypeStringToArray, jobtypeArrayToString } from '../../../utils/selectJobtypes';
import { ynFilter } from '../../../utils/ynFilter';
import ShortcutButton from '../../../components/button/ShortcutButton';
import useOnKeydownF9 from '../../../utils/useOnKeydownF9';
import { logPost } from '../../../services/log';
import { useSetAuth } from '../../../utils/useSetAuth';
import { applyBorderStyle } from '../../../utils/applyBorderStyle';
import { scrollToNodeBottom } from '../../../utils/scrollToNodeBottom';
import { trimArray } from '../../../utils/trimArray';
import DeleteModal from '../../../components/Modal/DeleteModal2';
import illustrator from '../../../assets/images/illustration/78.svg';
import { useOutletContext } from 'react-router-dom';
import BackButton from '../../../components/BackButton';
import SiteJobtypeTableRow from './SiteJobtypeTableRow';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  > div:nth-child(3) {
    overflow: auto;
    flex-shrink: 0;
  }
  .tbody > .tr > div.selector {
    > div {
      width: 100%;
      > div > ul > li {
        width: 100%;
        > span {
          width: 100%;
        }
      }
    }
  }

  .secondSearchOption {
    width: fit-content;
  }

  .textBtnGroup button {
    padding: 0 0.75rem;
    width: 4.5rem;
    height: 2.5rem;
    font-size: 0.875rem;
    font-weight: 600;
  }

  .tbody > .tr > div {
    height: 3.5rem;
  }
  .scroll {
    overflow: auto;
    > button {
      width: fit-content;
      margin: 0.2rem;
      padding: 0.25rem;
    }
  }
  .formTitle {
    padding: 0 0.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .titleDetail {
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    span {
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      margin: 0 0.25rem;
    }
  }
  .inputFormsWrapper {
    padding: 0.5rem;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: row;
    .table {
      height: fit-content;
    }
  }

  .inputFormsWrapper.smallTab {
    flex-direction: row;
    align-items: center;
    padding: 0 0.5rem;
    font-weight: 500;
    border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 0rem !important;
    }

    .tab {
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
      cursor: pointer;
      height: 3.5rem;
      border-bottom: 2px solid transparent;
    }
    .activeTab {
      font-weight: 700;
      border-bottom: 2px solid ${({ theme }: { theme: any }) => theme.selected_primary};
      color: ${({ theme }: { theme: any }) => theme.selected_primary};
    }
  }

  .fullwidth > div {
    width: 100%;
  }
`;

const EmptyData = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  height: 100%;
  width: 100%;
  padding-bottom: 4rem !important;
  img {
    width: 16rem;
    /* user-drag: none; */
    -webkit-user-drag: none;
  }
  p {
    color: ${({ theme }: { theme: any }) => theme.filled_violet};
    word-break: keep-all;
    text-align: center;
  }
  p:nth-child(2) {
    padding: 1rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    border-radius: 0.5rem;
    margin: 1rem;
  }
  p:nth-child(3) {
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    margin: 0 2rem;
  }
`;

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 0.5rem;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;

interface ISiteJoin {
  sjCd: string;
  sjName: string;
  bigo: string;
  jobtypeIdx: string;
  useYn: 'Y' | 'N';
}

const JOBTYPE_SETTING = 0;
const SITEJOIN_JOBTYPE_SETTING = 1;

const SiteJobtypeSetting = () => {
  const userInfo = useRecoilValue(userState);
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(JOBTYPE_SETTING); // 하위메뉴의 내부탭 액티브상태값

  return (
    <div className='content-container oneColumn'>
      <Root>
        <div className='inputFormsWrapper smallTab'>
          <div className={`tab ${activeTab === JOBTYPE_SETTING ? `activeTab` : ''}`} role='button' tabIndex={0} onClick={() => setActiveTab(JOBTYPE_SETTING)}>
            {userInfo.prejobtypeYn === 'Y' ? t('공종/직종') : t('직종')}
          </div>
          <div className={`tab ${activeTab === SITEJOIN_JOBTYPE_SETTING ? `activeTab` : ''}`} role='button' tabIndex={0} onClick={() => setActiveTab(SITEJOIN_JOBTYPE_SETTING)}>
            {t('협력업체 직종 관리')}
          </div>
        </div>
        {activeTab === JOBTYPE_SETTING ? <JobtypeSettingTab /> : <SiteJoinJobtypeSettingTab />}
      </Root>
    </div>
  );
};

// 공종/직종 or 직종 항목 추가 탭
const JobtypeSettingTab = () => {
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const userInfo = useRecoilValue(userState);
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장1버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [isSave2Clicked, setIsSave2Clicked] = useState(false); // 저장2버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const [showSubTable, setShowSubTable] = useState({ status: false, subCd: '', cdName: '' });
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollSubContainerRef = useRef<HTMLDivElement>(null);
  const [isTableInitialRender, setIsTableInitialRender] = useState(true); // 컴포넌트의 이니셜렌더 여부
  const size = useOutletContext<any>();

  const {
    data: normalCdList,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useQuery(['normalCdList', userInfo.hCd, userInfo.sCd], () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
  });
  const [tableState, setTableState] = useState(normalCdList || []);
  const [initTableState, setInitTableState] = useState(normalCdList || []);
  const { data: normalCdSubList } = useQuery(['normalCdSubList', userInfo.hCd, userInfo.sCd, showSubTable.subCd], () => fetchSubData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && !!showSubTable.subCd,
  });
  const [subTableState, setSubTableState] = useState(normalCdSubList || []);
  const [initSubTableState, setInitSubTableState] = useState(normalCdSubList || []);
  const [searchOption, setSearchOption] = useState({ cdName: '' });
  const contentRef = useRef<HTMLInputElement>(null);
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  const onClickOpenSubTable = (row: any) => {
    if (userInfo.prejobtypeYn === 'Y') {
      setShowSubTable((prev) => ({ ...prev, status: true, subCd: row.subCd, cdName: row.cdName }));
      // 새로 추가한 항목일때 subTable목록 초기화
      if (!row.subCd) {
        setSubTableState([]);
        setInitSubTableState([]);
      }
    }
  };

  const fetchData = async () => {
    try {
      const res = await apiGet({ path: '/code/pmsNormalTitle', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, subCd: userInfo.prejobtypeYn === 'Y' ? 'A' : 'B' } });
      const { pmsNormalTitleList } = res.data.data;
      setTableState(pmsNormalTitleList);
      setInitTableState(pmsNormalTitleList);
      return pmsNormalTitleList;
    } catch (error) {
      console.error('error', error);
      throw new Error('error');
    }
  };

  const fetchSubData = async () => {
    try {
      const res = await apiGet({ path: '/code/pmsNormalSub', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, grCd: showSubTable.subCd } });
      const { pmsNormalSubList } = res.data.data;
      setSubTableState(pmsNormalSubList);
      setInitSubTableState(pmsNormalSubList);
      return pmsNormalSubList;
    } catch (error) {
      console.error('error', error);
      throw new Error('error');
    }
  };

  const deleteAPI = async (deleteObj: any, typeParam: 'main' | 'sub') => {
    const req = {
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      grCd: deleteObj.grCd,
      subCd: deleteObj.subCd,
      editor: userInfo.userId,
    };
    const res = await apiPost({ path: '/code/pmsNormalDelete', req });

    const { statusCode, data, message } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      setOpenModal((prev) => ({ ...prev, status: false }));
      if (typeParam === 'main') {
        setShowSubTable((prev) => ({ ...prev, subCd: '', cdName: '' })); // 삭제 후 서브테이블타이틀 초기화
        // 삭제 후 subTable 초기화
        setSubTableState([]);
        setInitSubTableState([]);
        setTableState(data.pmsNormalList);
        setInitTableState(data.pmsNormalList);
      } else {
        setSubTableState(data.pmsNormalList);
        setInitSubTableState(data.pmsNormalList);
      }

      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '태블릿 안전교육관리 > 협력업체 직종관리',
        action: `${userInfo.prejobtypeYn === 'Y' ? '공종/직종' : '직종'}${typeParam === 'sub' && '(상세항목)'} 삭제`,
        etc: `${deleteObj.cdName}(${deleteObj.subCd})`,
      });
    }
  };

  const onClickDeleteRow = (index: number, obj: any, type: 'main' | 'sub') => {
    if (obj.subCd) {
      // 삭제
      setOpenModal((prev) => ({ ...prev, status: true, type: 'delete', api: () => deleteAPI(obj, type) }));
    } else if (type === 'main') {
      // 제거
      const updatedArray = [...tableState];
      updatedArray.splice(index, 1);
      setTableState(updatedArray);
    } else if (type === 'sub') {
      const updatedArray = [...subTableState];
      updatedArray.splice(index, 1);
      setSubTableState(updatedArray);
    }
  };

  const onClickAdd = (type: 'main' | 'sub') => {
    if (type === 'main' || (type === 'sub' && showSubTable.subCd)) {
      const newRow = {
        grCd: userInfo.prejobtypeYn === 'Y' ? '000' : showSubTable.subCd,
        subCd: '',
        cdName: '',
        writer: userInfo.userId,
        editor: userInfo.userId,
      };
      if (type === 'main') {
        setIsTableInitialRender(false); // 스크롤 아래로 이동
        scrollToNodeBottom(scrollContainerRef);
        setTableState((prev: any) => [...prev, newRow]);
      } else {
        scrollToNodeBottom(scrollSubContainerRef);
        setSubTableState((prev: any) => [...prev, newRow]);
      }
    } else {
      toast.warning(t('신규 항목을 추가하시고 저장해 주세요'));
    }
  };

  useEffect(() => {
    //  로우가 1개 이상이고, 이니셜렌더가 아닌경우 스크롤을 하단으로 이동
    if (tableState.length > 0 && !isTableInitialRender) {
      scrollToNodeBottom(scrollContainerRef);
    }
  }, [tableState.length]);

  useEffect(() => {
    //  로우가 1개 이상이고, 이니셜렌더가 아닌경우 스크롤을 하단으로 이동
    if (subTableState.length > 0 && !isTableInitialRender) {
      scrollToNodeBottom(scrollSubContainerRef);
    }
  }, [subTableState.length]);

  const findDifferences = (original: any[], updated: any[]) => {
    const updates = updated.filter((upd) => {
      const orgEntry = original.find((org) => org.subCd === upd.subCd);
      return orgEntry ? Object.keys(upd).some((key) => upd[key] !== orgEntry[key]) : true;
    });

    return updates;
  };

  const onClickSave = async (type: 'main' | 'sub') => {
    if (type === 'main') setIsSaveClicked(true);
    else setIsSave2Clicked(true);

    const updatedArray = type === 'main' ? findDifferences(initTableState, tableState) : findDifferences(initSubTableState, subTableState);
    const emptyCheckKey = ['cdName'];

    const emptyCheck = updatedArray.filter((el: any) => {
      const check = emptyCheckKey.find((el2: any) => el[el2] === '');
      return check;
    });

    if (emptyCheck.length > 0) {
      toast.warning(t('필수입력값을 모두 입력하세요'));
      return;
    }

    if (updatedArray.length === 0) {
      toast.warning(t('신규/수정 사항이 없습니다'));
      return;
    }

    const newTableState = updatedArray.map((el: any) => ({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      grCd: type === 'main' ? '000' : showSubTable.subCd,
      subCd: type === 'main' ? (el.subCd ? el.subCd : userInfo.prejobtypeYn === 'Y' ? 'A' : 'B') : el.subCd,
      cdName: el.cdName,
      editor: userInfo.userId,
      flag: el.flag,
    }));

    const trimReq = trimArray(newTableState);
    const dto = type === 'main' ? 'pmsNormalReqDto' : 'pmsNormalSubReqDto';
    const req = { [dto]: trimReq };
    const path = type === 'main' ? 'pmsNormal' : 'pmsNormalSub';
    const res = await apiPost({ path: `/code/${path}`, req });

    const { message, statusCode, data } = res.data;

    if (statusCode === 200) {
      setSearchOption({ cdName: '' });
      if (type === 'main') {
        setTableState(data.pmsNormalTitleList);
        setInitTableState(data.pmsNormalTitleList);
        setIsSaveClicked(false);
      } else {
        setSubTableState(data.pmsNormalSubList);
        setInitSubTableState(data.pmsNormalSubList);
        setIsSave2Clicked(false);
      }
      toast.success(t(message));
      setIsTableInitialRender(true);

      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '태블릿 안전교육 관리 > 협력업체 직종 관리',
        action: '저장',
        etc: `${userInfo.prejobtypeYn === 'Y' && '공종/'}직종 탭 ${type === 'sub' && '(상세항목)'}`,
      });
    }
  };

  const backToMain = () => {
    setShowSubTable((prev) => ({ ...prev, status: false }));
  };

  const applyFilter = (array: any[]) => {
    const filterOptions = {
      cdName: searchOption.cdName,
    };

    // 필터링된 어레이 리턴, 대소문자구분X
    const filteredArray = array.filter((item: any) => {
      return item.cdName?.toLowerCase()?.includes(filterOptions.cdName?.toLowerCase());
    });
    if (filteredArray.length > 0) setTableState(filteredArray);
    else setTableState([]);
  };

  // 엔터입력시 검색
  const onEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (contentRef.current) onClickSearch();
    }
  };

  const onClickSearch = () => {
    applyFilter(initTableState);
  };

  const onClickInitiateSearchOption = () => {
    setSearchOption({ cdName: '' });
    setTableState(initTableState);
  };

  if (isError) return <IssueGuide />;

  if (isLoading || isFetching)
    return (
      <LoadingModalBackground>
        <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
      </LoadingModalBackground>
    );

  return (
    <>
      <SearchOptions>
        <div className='inputsWrapper'>
          <div className='inputForm-row'>
            <div className='inputForm-col'>
              <Input
                label=''
                placeholder={t(`${userInfo.prejobtypeYn === 'Y' ? '공종명' : '직종명'}`)}
                type='text'
                id='cdName'
                name='cdName'
                state={searchOption}
                setState={setSearchOption}
                inputRef={contentRef}
                onKeyDown={onEnterKeyDown}
              />
            </div>
          </div>
          <div className='secondSearchOption'>
            <div className='flex-basic textBtnGroup'>
              <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
              <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
              <div className='searchResult'>
                {t('총')}
                <span>{tableState?.length || 0}</span>
                {t('개')}
              </div>
            </div>
          </div>
        </div>
      </SearchOptions>
      <div style={{ flexShrink: 1 }} className={`${showSubTable.status ? 'content-container twoColumn column-55' : 'content-container oneColumn'}`}>
        {(size.innerSize.W >= 1024 || (size.innerSize.W < 1024 && !showSubTable.status)) && (
          <Root>
            <EditableInputTable
              auth={auth}
              scrollContainerRef={scrollContainerRef}
              tableState={tableState}
              setTableState={setTableState}
              isSaveClicked={isSaveClicked}
              onClickOpenSubTable={onClickOpenSubTable}
              onClickDeleteRow={onClickDeleteRow}
              type='main'
            />
            <ButtonsWrapper>
              {auth.createAuth && <BtnBlue onClick={() => onClickAdd('main')}>{t('신규항목 추가')}</BtnBlue>}
              {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={() => onClickSave('main')}>{t('저장')}</BtnBlue>}
            </ButtonsWrapper>
          </Root>
        )}

        {showSubTable.status && userInfo.prejobtypeYn === 'Y' && (
          <Root>
            <div className='inputFormsWrapper'>
              {size.innerSize.W < 1024 && <BackButton func={() => backToMain()} />}
              <div className='formTitle titleDetail'>
                {t('코드')}
                <span>{showSubTable.subCd}</span>• {t('항목 명')} <span> {showSubTable.cdName}</span>
              </div>
            </div>
            <EditableInputTable
              auth={auth}
              scrollContainerRef={scrollSubContainerRef}
              tableState={subTableState}
              setTableState={setSubTableState}
              isSaveClicked={isSave2Clicked}
              onClickDeleteRow={onClickDeleteRow}
              type='sub'
              showSubTable={showSubTable}
            />
            <ButtonsWrapper>
              {auth.createAuth && <BtnBlue onClick={() => onClickAdd('sub')}>{t('신규항목 추가')}</BtnBlue>}
              {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={() => onClickSave('sub')}>{t('저장')}</BtnBlue>}
            </ButtonsWrapper>
          </Root>
        )}
      </div>
      <Portal openModal={openModal?.status}>{openModal && openModal.type === 'delete' ? <DeleteModal openModal={openModal} setOpenModal={setOpenModal} /> : undefined}</Portal>
    </>
  );
};

// 협력업체 직종관리 탭
const SiteJoinJobtypeSettingTab = () => {
  const userInfo = useRecoilValue(userState);
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const { hCd, sCd } = userInfo;

  const queryKey = 'siteJoin';
  const dependencies = [queryKey, hCd, sCd];
  const queryOptions = { enabled: !!hCd && !!sCd && sCd !== '00000' };

  const siteJoinQuery = useQuery(dependencies, () => fetchData(), queryOptions);
  const { data: siteJoinListData, isLoading, isFetching, isError, refetch } = siteJoinQuery;

  const [tableState, setTableState] = useState<ISiteJoin[]>(siteJoinListData || []);
  const [initTableState, setInitTableState] = useState<ISiteJoin[]>(siteJoinListData || []);

  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅

  const [searchOption, setSearchOption] = useState({ sjName: '' });
  const contentRef = useRef<HTMLInputElement>(null);

  const { data: useYnComCdListWithAll } = useFetchCommonCodeList(COMCD_USE_YN, true); // 사용여부 공통코드 목록 (전체포함)
  const [useYn, setUseYn] = useState(INIT_USE_YN_A);

  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });

  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [isTableInitialRender, setIsTableInitialRender] = useState(true); // 컴포넌트의 이니셜렌더 여부

  const fetchData = async () => {
    try {
      const path = '/siteJoin';
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
      const res = await apiGet({ path, req });
      const result = res.data.data?.siteJoinList;
      const promises = result?.map(async (el: ISiteJoin) => (el.jobtypeIdx === null ? { ...el, jobtypeIdx: '' } : el));
      const promisedResult = await Promise.all(promises);
      setTableState(promisedResult);
      setInitTableState(promisedResult);
      return promisedResult;
    } catch (error) {
      console.error('error', error);
      throw new Error('error');
    }
  };

  useEffect(() => {
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '태블릿 안전교육 관리 > 협력업체 직종 관리',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  const applyFilter = (array: any[], setArray: Dispatch<SetStateAction<any>>) => {
    const filterOptions = { sjName: searchOption.sjName };

    // 필터링된 어레이 리턴, 대소문자구분X
    const filteredArray = array.filter((item: any) => {
      return item.sjName?.toLowerCase()?.includes(filterOptions.sjName?.toLowerCase());
    });
    const result = ynFilter(filteredArray, 'useYn', useYn[COMCD_USE_YN]);
    if (result.length > 0) setArray(result);
    else setArray([]);
  };

  // 엔터입력시 검색
  const onEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (contentRef.current) onClickSearch();
    }
  };

  const onClickSearch = () => {
    // if (useYn[COMCD_USE_YN] !== 'A' || searchOption.sjName !== '') {
    applyFilter(siteJoinListData || [], setTableState);
    applyFilter(siteJoinListData || [], setInitTableState);
    // }
  };

  const onClickInitiateSearchOption = () => {
    setSearchOption({ sjName: '' });
    setUseYn(INIT_USE_YN_A);
    setTableState(siteJoinListData || []);
    setInitTableState(siteJoinListData || []);
  };

  const findDifferences = (original: any[], updated: any[]) => {
    const updates = updated.filter((upd) => {
      const orgEntry = original.find((org) => org.sjCd === upd.sjCd);
      // 밸류 비교, 업데이트플래그(사용유무) 확인해서 수정사항이 있는 지 판별
      return orgEntry ? Object.keys(upd).some((key) => upd[key] !== orgEntry[key] || upd?.flag === FLAG_CREATE_OR_UPDATE) : true;
    });

    return updates;
  };

  const onClickSave = async () => {
    setIsSaveClicked(true);
    const updatedArray = findDifferences(initTableState, tableState);
    const emptyCheckKey = ['sjName'];

    const emptyCheck = updatedArray.filter((el: any) => {
      const check = emptyCheckKey.find((el2: any) => el[el2] === '');
      return check;
    });

    if (emptyCheck.length > 0) {
      toast.warning(t('필수입력값을 모두 입력하세요'));
      return;
    }

    if (updatedArray.length === 0) {
      toast.warning(t('신규/수정 사항이 없습니다'));
      return;
    }

    const newTableState = updatedArray.map((el: any) => ({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      sjCd: el.sjCd,
      sjName: el.sjName,
      bigo: el.bigo,
      useYn: el.useYn,
      jobtypeIdx: el.jobtypeIdx,
      writer: el.sjCd ? el.writer : userInfo.userId,
      editor: userInfo.userId,
    }));
    const trimReq = trimArray(newTableState);
    const req = { postSjJobtypeDto: trimReq };
    const res = await apiPost({ path: '/sitejoin/jobtype', req });

    if (res.data.statusCode === 200) {
      refetch();
      setIsSaveClicked(false);
      toast.success(t(res.data.message));
      setSearchOption({ sjName: '' });
      setUseYn(INIT_USE_YN_A);

      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '태블릿 안전교육 관리 > 협력업체 직종 관리',
        action: '저장',
        etc: `협력업체 직종 관리 탭`,
      });
    }
  };

  const onClickAdd = () => {
    const newRow = {
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      sjCd: null,
      sjName: '',
      bigo: '',
      useYn: 'Y',
      jobtypeIdx: null,
      writer: userInfo.userId,
      editor: userInfo.userId,
    };
    setTableState((prev: any) => [...prev, newRow]);
    setIsTableInitialRender(false); // 스크롤 아래로 이동
  };

  useEffect(() => {
    //  로우가 1개 이상이고, 이니셜렌더가 아닌경우 스크롤을 하단으로 이동
    if (tableState.length > 0 && !isTableInitialRender) scrollToNodeBottom(scrollContainerRef);
  }, [tableState.length]);

  const deleteAPI = async (sjParam: any) => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, sjCd: sjParam.sjCd, editor: userInfo.userId };
    const res = await apiDelete({ path: '/sitejoin/info', contentType: 'application/json', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      const { siteJoinList } = data;

      const promises = await siteJoinList?.map(async (el: ISiteJoin) => {
        if (el.jobtypeIdx) {
          const res2 = await getJobypeCodeList(userInfo, el.jobtypeIdx);
          return { ...el, jobtype: jobtypeStringToArray(userInfo, res2, el.jobtypeIdx), useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용') };
        }
        return { ...el, useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용') };
      });
      const promisedResult = await Promise.all(promises);
      applyFilter(promisedResult, setTableState);
      applyFilter(promisedResult, setInitTableState);

      toast.success(t('협력업체 삭제 성공'));

      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '태블릿 안전교육 관리 > 협력업체 직종 관리',
        action: '삭제',
        etc: `${sjParam.sjName}(${sjParam.sjCd})`,
      });
    }
  };

  const onClickDeleteRow = (index: number, obj: any) => {
    if (obj.sjCd) {
      // 삭제
      setOpenModal((prev) => ({ ...prev, status: true, type: 'delete', api: () => deleteAPI(obj) }));
    } else {
      // 제거
      const updatedArray = [...tableState];
      updatedArray.splice(index, 1);
      setTableState(updatedArray);
    }
  };

  if (isError) return <IssueGuide />;

  if (isLoading || isFetching)
    return (
      <LoadingModalBackground>
        <PulseLoader className='flex-center' color='rgb(0, 122 , 255)' size='1rem' />
      </LoadingModalBackground>
    );

  return (
    <>
      <SearchOptions>
        <div className='inputsWrapper'>
          <div className='inputForm-row'>
            <div className='inputForm-col withLabelComCf'>
              <label htmlFor='useYn'>{t('사용유무')}</label>
              <SelectBox
                options={useYnComCdListWithAll}
                defaultOption={useYn.cdName}
                state={useYn}
                setState={setUseYn}
                stateKey={COMCD_USE_YN}
                initiateKey={useYn[COMCD_USE_YN]}
                filterbar='filter-1-left'
              />
            </div>
          </div>
          <div className='inputForm-row'>
            <div className='inputForm-col'>
              <Input label='' placeholder={t('협력업체명')} type='text' id='sjName' name='sjName' state={searchOption} setState={setSearchOption} inputRef={contentRef} onKeyDown={onEnterKeyDown} />
            </div>
          </div>
          <div className='secondSearchOption'>
            <div className='flex-basic textBtnGroup'>
              <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
              <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
              <div className='searchResult'>
                {t('총')}
                <span>{tableState?.length || 0}</span>
                {t('개')}
              </div>
            </div>
          </div>
        </div>
      </SearchOptions>
      <InputTable className='margin-left-05' style={{ flexShrink: '1' }} ref={scrollContainerRef}>
        <div className='thead'>
          <div className='tr'>
            <div className='trCol2p5 flex-center tableStickyNo'>No</div>
            <div className='trCol4 flex-center'>{t('코드')}</div>
            <div className='trCol12 required flex-center tableStickyTitle'>{t('협력업체명')}</div>
            <div className='trCol8 flex-center'>{t('비고')}</div>
            <div className='trCol6 flex-center'>{t('사용유무')}</div>
            <div className='trCol4 flex-center content-overflow'>{t('직종')}</div>
          </div>
        </div>
        <div className='table'>
          <div className='tbody'>
            {tableState?.map((el: any, i: number) => (
              <SiteJobtypeTableRow
                key={el.sjCd} //
                rowData={el}
                rowIndex={i}
                tableState={tableState}
                setTableState={setTableState}
                onClickDeleteRow={onClickDeleteRow}
                isSaveClicked={isSaveClicked}
                originalData={initTableState}
              />
            ))}
          </div>
        </div>
      </InputTable>
      <ButtonsWrapper>
        {auth.createAuth && <BtnBlue onClick={onClickAdd}>{t('신규항목 추가')}</BtnBlue>}
        {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={onClickSave}>{t('저장')}</BtnBlue>}
      </ButtonsWrapper>
      <Portal openModal={openModal?.status}>{openModal && openModal.type === 'delete' ? <DeleteModal openModal={openModal} setOpenModal={setOpenModal} /> : undefined}</Portal>
    </>
  );
};

interface IEditableInputTable {
  auth: IAuth;
  scrollContainerRef: RefObject<HTMLDivElement>;
  tableState: any;
  setTableState: any;
  isSaveClicked: boolean;
  onClickDeleteRow: (index: number, rowObj: any, type: 'main' | 'sub') => void;
  type: 'main' | 'sub';
  onClickOpenSubTable?: (rowObj: any) => void;
  showSubTable?: { status: boolean; subCd: string; cdName: string };
}

// 인풋목록 컴포넌트
const EditableInputTable = ({ auth, scrollContainerRef, tableState, setTableState, isSaveClicked, onClickOpenSubTable, onClickDeleteRow, type, showSubTable }: IEditableInputTable) => {
  const { t } = useTranslation();
  const size = useOutletContext<any>();

  return (
    <InputTable key={type} className='margin-left-05' style={{ flexShrink: '1' }} ref={scrollContainerRef}>
      <div>
        <div className='thead'>
          <div className='tr'>
            <div className='trCol2p5 flex-center tableStickyNo'>{t('No')}</div>
            <div className='trCol3 flex-center'>{t('코드')}</div>
            <div className='trCol12 flex-center tableStickyTitle content-overflow required'>{t('항목 명')}</div>
            <div className='trCol12 flex-center '>{t('수정일자')}</div>
            <div className='trCol6 flex-center'>{t('수정자')}</div>
            {auth.deleteAuth && <div className='trCol6' />}
          </div>
        </div>
        <div className='tbody'>
          {type === 'main' || (type === 'sub' && showSubTable?.status) ? (
            tableState?.length > 0 ? (
              tableState?.map((el: any, i: number) => (
                <div className='tr' key={`sub${el.subCd}_${i}`} role='button' tabIndex={0} onClick={() => onClickOpenSubTable && onClickOpenSubTable(el)}>
                  <div className='trCol2p5 flex-center tableStickyNo'>{i + 1}</div>
                  <div className='trCol3 flex-center'>{el.subCd ? el.subCd : ''}</div>
                  <div className='trCol12 flex-center tableStickyTitle content-overflow fullwidth'>
                    <Input
                      type='text'
                      id='cdName'
                      name='cdName'
                      state={tableState}
                      setState={setTableState}
                      stateType={{ type: 'array', index: i }}
                      getBorderStyle={isSaveClicked ? applyBorderStyle(el.cdName, 'red', 'cdName') : undefined}
                      useFlag
                      maxLength={20}
                      onClick={(event) => {
                        if (size.innerSize.W < 1024) {
                          event.stopPropagation();
                        }
                      }}
                    />
                  </div>
                  <div className='trCol12 flex-center'>{el.eDate}</div>
                  <div className='trCol6 flex-center'>{el.subCd ? el.editor : ''}</div>
                  {auth.deleteAuth && (
                    <div className='trCol6 flex-center'>
                      <BtnRed onClick={() => onClickDeleteRow(i, el, type)}>{el.subCd ? t('삭제') : t('제거')}</BtnRed>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <EmptyData>
                <img src={illustrator} alt='noData' />
                <p>{t('상세 항목이 존재하지 않습니다')}</p>
                <p>{t('항목을 선택하거나 신규 항목을 추가하시고 저장해 주세요')}</p>
              </EmptyData>
            )
          ) : (
            <EmptyData>
              <img src={illustrator} alt='noData' />
              <p>{t('상세 항목이 존재하지 않습니다')}</p>
              <p>{t('항목을 선택하거나 신규 항목을 추가하시고 저장해 주세요')}</p>
            </EmptyData>
          )}
        </div>
      </div>
    </InputTable>
  );
};

export default SiteJobtypeSetting;
