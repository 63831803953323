import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { BtnRed } from '../../../components/Button';
import ClickableContainer from '../../s_cctv/S_cctv1/RealtimeCCTV/ClickableContainer';
import TableLamp from '../../sm_setting/setting12/TableLamp';
import { useTranslation } from 'react-i18next';
import { IModal } from 'customTypes';
import Portal from '../../../components/Portal';
import YesOrNoConfirm from '../../../components/Modal/YesOrNo';
import SelectBox from '../../../components/SelectBox';
import { getMsCdList } from '../../../hooks/useMSite';
import { applyBorderStyle } from '../../../utils/applyBorderStyle';

interface Props {
  rowData: any;
  setTableState: Dispatch<SetStateAction<any>>;
  index: number;
  emptyCheck?: boolean;
  headOptions: { type: String; mhCd: string; cdName: string }[];
  deleteMSite: (rowData: Msite) => void;
}

type MsCd = { type: 'msCd'; msCd: string; cdName: string };

const TableRow = ({ rowData, setTableState, index, emptyCheck, headOptions, deleteMSite }: Props) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: 'confirm' });

  // headOptions - 첫번째 셀렉트 박스 옵션 (건설사)
  // headOptions에서 mhCd 찾기
  const comparisonMhCd = headOptions.find((el) => el.mhCd === rowData.mhCd);
  // mhCd 초기화
  const mhCdInit = { type: 'mhCd', mhCd: '', cdName: '' }; //
  // mhCd 상태
  const [mhCd, setMhCd] = useState(comparisonMhCd ?? mhCdInit);
  // isLoading 상태
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // siteOptions - 두번째 셀렉트 박스 옵션 (현장명)
  const [siteOptions, setSiteOptions] = useState<MsCd[]>([]);
  // siteOptions에서 msCd 찾기
  const comparisonMsCd = siteOptions.find((el) => el.msCd === rowData.msCd);
  // msCd 초기화
  const msCdInit = { type: 'msCd', msCd: '', cdName: '' };
  // msCd 상태
  const [msCd, setMsCd] = useState(comparisonMsCd ?? msCdInit);

  // console.log(headOptions, 'headOptions');
  // console.log(siteOptions, 'siteOptions');

  // mhCd 상태가 변경될 때 현장명 리스트 조회
  useEffect(() => {
    if (mhCd.mhCd !== '' || rowData.mhCd !== '') {
      const hcd = mhCd.mhCd !== '' ? mhCd.mhCd : rowData.mhCd;
      getMsCdList(hcd).then((res: any) => {
        setSiteOptions(res);
        const comparison = res.find((el: any) => el.msCd === rowData.msCd);
        setMsCd(comparison ?? msCdInit);
        setTimeout(() => {
          setIsLoading(false);
        }, 100);
      });
    }
  }, [mhCd]);

  // mhCd, msCd 상태가 변경될 때 상태값 업데이트
  useEffect(() => {
    // mhCd 상태가 변경될 때 상태값 업데이트
    if (mhCd.mhCd !== '') {
      setString('mhCd', mhCd.mhCd);
    }
    // msCd 상태가 변경될 때 상태값 업데이트
    if (msCd.msCd !== '') {
      setString('msCd', msCd.msCd);
    }
  }, [mhCd, msCd]);

  // 신규 항목 제거 함수
  const deleteNewItem = () => {
    setTableState((prev: any) => {
      return prev.filter((_: any, i: number) => i !== index);
    });
  };

  // 서명 항목 제거 알고리즘 함수
  const handleDelete = () => {
    // 기존 항목 삭제시
    if (rowData.sSeq) {
      setOpenModal({ ...openModal, status: true });
      return;
    }
    // 신규 항목 제거시 입력 필드가 비어있는 경우
    deleteNewItem();
  };

  // 'Y' 또는 'N' 상태값 업데이트 함수
  const setYn = (key: string, value: 'Y' | 'N') => {
    setTableState((prev: any) => {
      const newState = [...prev];
      newState[index] = { ...newState[index], [key]: value };
      return newState;
    });
  };

  // string 타입의 상태값 업데이트 함수
  const setString = (key: string, value: string) => {
    setTableState((prev: any) => {
      const newState = [...prev];
      newState[index] = { ...newState[index], [key]: value };
      return newState;
    });
  };

  const borderstyled = { outline: `2px solid red`, borderRadius: '5px' };
  return (
    <div className='tr'>
      <div className='trCol2p5 flex-center tableStickyNo'>{index + 1}</div>
      <div className='trCol22 flex-basic gap50'>
        <SelectBox
          options={headOptions}
          defaultOption={mhCd.cdName !== '' ? mhCd.cdName : t('미선택')} //
          state={mhCd}
          setState={setMhCd}
          stateKey='mhCd'
          dropDownWidth='expand-content-sm'
          getBorderStyle={emptyCheck && mhCd.mhCd === '' ? borderstyled : undefined}
        />
        {mhCd.mhCd !== '' && !isLoading && (
          <SelectBox
            options={siteOptions}
            defaultOption={msCd.cdName !== '' ? msCd.cdName : t('미선택')} //
            state={msCd}
            setState={setMsCd}
            stateKey='msCd'
            dropDownWidth='expand-content-sm'
            getBorderStyle={emptyCheck && msCd.msCd === '' ? borderstyled : undefined}
          />
        )}
      </div>
      <div className='trCol5 flex-center content-overflow'>
        <ClickableContainer
          onClick={() => setYn('wregYn', rowData.wregYn === 'Y' ? 'N' : 'Y')} //
          button={<TableLamp use={rowData.wregYn === 'Y'} />}
        />
      </div>
      <div className='trCol5 flex-center content-overflow'>
        <ClickableContainer
          onClick={() => setYn('useYn', rowData.useYn === 'Y' ? 'N' : 'Y')} //
          button={<TableLamp use={rowData.useYn === 'Y'} />}
        />
      </div>
      <div
        className='trCol5 flex-center' //
      >
        <BtnRed onClick={handleDelete}>
          {rowData.sSeq //
            ? t('삭제')
            : t('제거')}
        </BtnRed>
      </div>
      <Portal openModal={openModal?.status}>
        <YesOrNoConfirm
          mainText={t('다중 현장 관리 목록을 정말 삭제하시겠습니까?')}
          subText={t('저장하지 않은 변경 사항은 유실됩니다.')}
          onClickClose={() => setOpenModal({ status: false, type: 'confirm' })} //
          onClickConfirm={() => {
            if (rowData.sSeq) {
              deleteMSite(rowData);
            } else {
              deleteNewItem();
            }
            setOpenModal({ status: false, type: 'confirm' });
          }}
        />
      </Portal>
    </div>
  );
};

export default TableRow;
