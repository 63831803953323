import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import FoldAll from './FoldAll';
import JobTypeFoldableList from './JobTypeFoldableList';
import { countSelectedAtFoldable, flattenSubLists } from '../../../hooks/useJobType';
import CountBoxInModal from './CountBoxInModal';
import { useTranslation } from 'react-i18next';

interface Props {
  jobTypeLists: any;
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
  onClickSave: () => void;
}

const JobTypeALists = ({ jobTypeLists, selected, setSelected, onClickSave }: Props) => {
  const { t } = useTranslation();
  const [foldList, setFoldList] = useState<{ fold: boolean }[]>([]);
  const [flattenSubList, setFlattenSubList] = useState<JobtypeList[]>([]);

  // jobtypelists 변경시 foldlist 및 flattenSubList 초기화
  useEffect(() => {
    if (jobTypeLists) {
      const initialFoldList = jobTypeLists.map(() => ({ fold: true }));
      setFoldList(initialFoldList);
      setFlattenSubList(flattenSubLists(jobTypeLists));
    }
  }, [jobTypeLists]);

  const count = countSelectedAtFoldable(flattenSubList, selected);
  const totalCount = flattenSubList.length;

  return (
    <>
      <div className='body'>
        <FoldAll //
          foldList={foldList}
          setFoldList={setFoldList}
        />
        {jobTypeLists?.map((list: any, index: number) => (
          <JobTypeFoldableList
            key={`${list.title}_${index}`} //
            list={list}
            selected={selected}
            setSelected={setSelected}
            foldList={foldList}
            setFoldList={setFoldList}
            index={index}
          />
        ))}
      </div>
      <div className='footer'>
        <CountBoxInModal
          count={count} //
          totalCount={totalCount}
        />
        <button className='button' type='button' onClick={onClickSave}>
          {t('적용')}
        </button>
      </div>
    </>
  );
};

export default JobTypeALists;
