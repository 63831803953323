/**
 * 작성자 : 김광민
 * 날짜 : 2024.05.09
 * 기능 : 태블릿 입력설정 QR코드 출력 모달 내 QR코드 컴포넌트
 */

import { Dispatch, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import { themeState } from '../../../atoms';
import { useRecoilValue } from 'recoil';

const Root = styled.div`
  flex-grow: 1;
  max-height: calc(100% - 6.5rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 6rem;
  flex-shrink: 0;
  .qr-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-bottom: 2.5rem;
    padding-bottom: 4rem;
    overflow: visible;
    width: fit-content;
    width: 100%;
    .logo {
      display: none;
      position: absolute;
      background-color: ${({ theme }: { theme: any }) => theme.board};
      object-fit: contain;
    }
    &.dark {
      .qrcode {
        filter: invert();
      }
    }
    &.small {
      .qrTitle {
        font-size: 1.5rem;
        bottom: 1.75rem;
        .seperator {
          margin: 0 0.5rem;
          height: 1.25rem;
        }
      }
    }
    &.medium {
      .qrTitle {
        font-size: 2rem;
        bottom: 1rem;
        .seperator {
          margin: 0 0.75rem;
          height: 1.75rem;
        }
      }
    }
    &.large,
    &.x-large {
      .qrTitle {
        font-size: 3rem;
      }
    }
    &.large {
      .logo {
        display: flex;
        width: 10rem;
        height: 10rem;
        padding: 1rem;
      }
      .qrTitle {
        bottom: -0.5rem;
        .seperator {
          margin: 0 1rem;
          height: 2.25rem;
        }
      }
    }
    &.x-large {
      .logo {
        display: flex;
        width: 13rem;
        height: 13rem;
        padding: 2rem;
      }
      .qrTitle {
        bottom: -1rem;
        .seperator {
          margin: 0 1rem;
          height: 2.5rem;
        }
      }
    }
    .qrTitle {
      position: absolute;
      bottom: 0;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      display: flex;
      align-items: center;
      word-break: break-all;
      flex-shrink: 0;
      .seperator {
        border-left: 0.125rem solid ${({ theme }: { theme: any }) => theme.outline};
      }
      .codeName {
        color: ${({ theme }: { theme: any }) => theme.text_disabled};
      }
    }
  }
`;

interface Props {
  size: 'small' | 'medium' | 'large' | 'x-large';
  title: string;
  code: string;
  qrCode?: string | null;
  logo: string;
  print: boolean;
  setPrint: Dispatch<SetStateAction<boolean>>;
}

const QrcodeSettings = ({ size, title, code, qrCode, logo, print, setPrint }: Props) => {
  const theme = useRecoilValue(themeState);

  useEffect(() => {
    if (print) {
      window.print();
      setPrint(false);
    }
  }, [print]);

  return (
    <Root>
      <div className={`qr-container ${size} ${print ? '' : theme}`}>
        {qrCode && ( //
          <img src={qrCode} alt='qrcode' className='qrcode' />
        )}
        <img src={logo} alt='' className='logo' />
        <div className='qrTitle'>
          {title}
          <span className='seperator' />
          <span className='codeName'>{code}</span>
        </div>
      </div>
    </Root>
  );
};

export default QrcodeSettings;
