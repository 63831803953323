/**
 * 작성자 : 홍선영
 * 날짜 : 2023.07.27
 * 기능 : string 값을 받아서, 숫자가 아닌 string모두 제거하고 최대길이값 여부 만족하면 return value
 */

export const removeNonDigits = (str: string, maxLength: number) => {
  if (str) {
    const remove = str.replace(/\D/g, '');
    if (remove.length > 0) {
      const finalStrLength = remove.length;

      if (finalStrLength > maxLength) return '';
      return remove;
    }
    return '';
  }
  return '';
};
