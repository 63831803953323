/**
 * 작성자 : 홍선영
 * 날짜 : 2023.08.17
 * 기능 : 1) 오늘기준 만나이 계산 함수
 *        2) 선택일자 기준 만나이 계산 함수
 */
import dayjs from 'dayjs';

export const getAge = (birthday: string): number => {
  const birthDate = dayjs(birthday);
  const today = dayjs();

  let age = today.year() - birthDate.year();

  if (today.month() < birthDate.month() || (today.month() === birthDate.month() && today.date() < birthDate.date())) {
    age -= 1;
  }

  return age;
};

export const getAgeFromCertainDate = (birthday: string, date: any): number => {
  const birthDate = dayjs(birthday);

  let age = date.year() - birthDate.year();

  if (date.month() < birthDate.month() || (date.month() === birthDate.month() && date.date() < birthDate.date())) {
    age -= 1;
  }

  return age;
};
