/**
 * 작성자 : 김광민
 * 날짜 : 2024.02.19
 * 기능 : 터널 관리 > 방송 장비 정보
 */

import styled from 'styled-components';

const Root = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  gap: 0.5rem;
  padding: 0 0.5rem;
  height: 2.25rem;
  background-color: ${({ theme }: { theme: any }) => theme.board};
  background-color: ${({ theme }: { theme: any }) => theme.tonal};
  .dot {
    display: flex;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 1rem;
  }
  .message {
    font-size: 0.875rem;
  }
  &.negative {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
    .dot {
      background-color: ${({ theme }: { theme: any }) => theme.filled_red_deep};
    }
    .message {
      color: ${({ theme }: { theme: any }) => theme.filled_red_deep};
    }
  }
  &.positive {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
    .dot {
      background-color: ${({ theme }: { theme: any }) => theme.filled_red_green};
    }
    .message {
      color: ${({ theme }: { theme: any }) => theme.filled_green_deep};
    }
  }
`;

type Props = {
  color: 'positive' | 'negative';
  message: string;
};

const VariableMessage = ({ color, message }: Props) => {
  return (
    <Root className={color}>
      <span className='dot' />
      <span className='message'>{message}</span>
    </Root>
  );
};

export default VariableMessage;
