/**
 * 작성자 : 홍선영
 * 날짜 : 2023.06.22
 * 기능 : 근로자 간편등록 모달
 */

import { useState, useEffect, Dispatch, SetStateAction, useRef } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';

import { userState, msiteUseYnState } from '../../atoms';
import { ERROR } from '../../_constants';
import { IAuth, IComCd, IModal } from 'customTypes';
import { InputTable } from '../../assets/styles/InputTable';
import { ModalBackground } from '../../assets/styles/Modal';
import { BtnBlue, BtnGreen, BtnRed } from '../Button';
import SearchSelectBoxs from '../SearchSelectBoxs';
import RegisterExcelModal from './RegisterExcelModal';
import { trimArray } from '../../utils/trimArray';
import { logPost } from '../../services/log';
import { arraySortByAscdOrder } from '../../utils/arraySortByAscdOrder';
import { InfoWorkerModalStyle } from '../../assets/styles/InfoWorkerModal';
import SearchSelectBoxSm from '../SearchSelectBoxSm';
import { scrollToNodeBottom } from '../../utils/scrollToNodeBottom';
import { useTranslation } from 'react-i18next';
import { apiGet, apiPost } from '../../services/_common';

interface IMsite {
  hCd: string;
  sCd: string;
  sSeq: string;
  mhCd: string;
  mhName: string;
  msCd: string;
  msName: string;
  wregYn: string;
  useYn: string;
  checked: boolean;
}

interface IProps {
  getReportListAPI: () => void;
  setOpenModal: Dispatch<SetStateAction<IModal>>;
  auth: IAuth;
  code: { hCd: string; sCd: string };
  mSiteCheck: IMsite[];
}

interface ISiteJoinList {
  sjCd: string;
  sjName: string;
  bigo: string;
  useYn: string;
}

interface IWorkerSimple {
  checked?: boolean;
  hCd: string;
  sCd: string;
  wCd: string;
  wName: string;
  sjCd: string;
  wPrejobtype: string;
  wJobtype: string;
  wHnum: string;
  wHnum1?: string;
  wHnum2?: string;
  wHnum3?: string;
  wBdate: string;
  writer: string;
  editor: string;
  options?: any;
}

interface IEnrollInfo {
  sjCd: string;
  wPrejobtype: string;
  wJobtype: string;
}

const EnrollmentWorkerModal = ({ getReportListAPI, setOpenModal, auth, code, mSiteCheck }: IProps) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const [enrollInfo, setEnrollInfo] = useState<IEnrollInfo>({
    sjCd: '',
    wPrejobtype: '',
    wJobtype: '',
  });
  const [openSubModal, setOpenSubModal] = useState<IModal>({
    status: false,
    type: '',
  });
  const [siteJoinCdList, setSiteJoinCdList] = useState<ISiteJoinList[]>([]); // 협력업체 코드리스트
  const [preJobtypeCdList, setPreJobtypeCdList] = useState([]); // (공종+직종일 때) 공종 코드리스트
  const [jobtypeCdList, setJobtypeCdList] = useState<any[]>([]); // 직종 코드리스트
  const [enrollJobtypeCdList, setEnrollJobtypeCdList] = useState([]); // 직종 코드리스트(신규항목추가)
  const [addRowCount, setAddRowCount] = useState<number>(1); // 로우 추가 개수 값
  const [tableState, setTableState] = useState<IWorkerSimple[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const inputRefs = useRef<(HTMLInputElement | null)[][]>([]);
  const scrollContainerRef = useRef<HTMLInputElement>(null);
  const [isTableInitialRender, setIsTableInitialRender] = useState(true); // 컴포넌트의 이니셜렌더 여부
  const [mSiteCheckState, setMSiteCheckState] = useState(mSiteCheck);
  const msiteUseYn = useRecoilValue(msiteUseYnState);

  const initialWorkerSimple = {
    checked: false,
    hCd: code.hCd,
    sCd: code.sCd,
    wCd: '',
    wName: '',
    sjCd: '',
    wPrejobtype: '',
    wJobtype: '',
    wHnum: '',
    wHnum1: '010',
    wHnum2: '',
    wHnum3: '',
    wBdate: '',
    writer: userInfo.userId,
    editor: userInfo.userId,
  };

  const dummy = { hCd: userInfo.hCd, sCd: userInfo.sCd };

  useEffect(() => {
    //  로우가 1개 이상이고, 이니셜렌더가 아닌경우 스크롤을 하단으로 이동
    if (tableState.length > 0 && !isTableInitialRender) scrollToNodeBottom(scrollContainerRef);
  }, [tableState.length]);

  useEffect(() => {
    getSiteJoinListAPI(); // 협력업체목록 호출
    if (userInfo.prejobtypeYn === 'Y') getPrejobtypeAPI(); // (공종+직종일 때) 공종목록 호출
    if (userInfo.prejobtypeYn === 'N') getJobtypeAPI('000'); // (직종만일 때) 직종목록 호출
  }, []);

  // 공종이 변경되었을 때 직종목록 다시 호출하고, 직종선택값 초기화
  useEffect(() => {
    setEnrollInfo((prev) => ({ ...prev, wJobtype: '' }));
    if (enrollInfo.wPrejobtype !== '') {
      getJobtypeAPI(enrollInfo.wPrejobtype);
    } else {
      setEnrollJobtypeCdList([]);
    }
  }, [enrollInfo.wPrejobtype]);

  useEffect(() => {
    setTableState((prev) => prev.map((el) => ({ ...el, checked: selectAll })));
  }, [selectAll]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, tableState.length);
  }, [tableState]);

  const getSiteJoinListAPI = async () => {
    const req = { hCd: dummy.hCd, sCd: dummy.sCd };
    const res = await apiGet({ path: '/siteJoin', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      const useYList = data.siteJoinList.filter((el: ISiteJoinList) => el.useYn === 'Y'); // 사용중인 협력업체만 필터링
      const formatSelectBoxFormList = useYList.map((el: ISiteJoinList, i: number) => {
        // 셀렉트박스 컴포넌트에서 사용하는 형태로 변경
        return {
          type: 'sjCd',
          sjCd: el.sjCd,
          name: el.sjName,
          cdSort: i + 1,
        };
      });
      const newObj = { type: 'sjCd', sjCd: '', name: t('선택'), cdSort: 0 };
      const addAllSelect: any = [...formatSelectBoxFormList, newObj];
      const sortedArray: any = arraySortByAscdOrder(addAllSelect, 'cdSort');
      setSiteJoinCdList(sortedArray);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // (공종+직종일 때) 공종 코드리스트 호출
  const getPrejobtypeAPI = async () => {
    const req = {
      hCd: code.hCd,
      sCd: code.sCd,
      grCd: '000',
    };
    const res = await apiGet({ path: `/code/normal/prejobtype`, req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const prejobTypeList = data.prejobtypeList.filter((el: any) => el.subCd.charAt(0) === 'A');
      const formatSelectBoxFormList = prejobTypeList.map((el: IComCd, i: number) => {
        return {
          type: 'wPrejobtype',
          wPrejobtype: el.subCd,
          name: el.cdName,
          cdSort: i + 1,
        };
      });
      const newObj = {
        type: 'wPrejobtype',
        wPrejobtype: '',
        name: t('선택'),
        cdSort: 0,
      };
      const addAllSelect: any = [...formatSelectBoxFormList, newObj];
      const sortedArray: any = arraySortByAscdOrder(addAllSelect, 'cdSort');
      setPreJobtypeCdList(sortedArray);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 직종 코드리스트 호출
  const getJobtypeAPI = async (grCd: string) => {
    const req = {
      hCd: code.hCd,
      sCd: code.sCd,
      grCd,
    };
    const res = await apiGet({ path: '/code/normal/prejobtype', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      // 공종+직종일 때 직종리스트
      if (userInfo.prejobtypeYn === 'Y') {
        const formatSelectBoxFormList = data.prejobtypeList.map((el: IComCd, i: number) => {
          return {
            type: 'wJobtype',
            wJobtype: el.subCd,
            name: el.cdName,
            cdSort: i + 1,
          };
        });
        const newObj = {
          type: 'wJobtype',
          wJobtype: '',
          name: t('선택'),
          cdSort: 0,
        };
        const addAllSelect: any = [...formatSelectBoxFormList, newObj];
        const sortedArray: any = arraySortByAscdOrder(addAllSelect, 'cdSort');
        // setJobtypeCdList(formatSelectBoxFormList);
        setEnrollJobtypeCdList(sortedArray);
      } else {
        // 직종만일 때 직종리스트
        const jobTypeList = data.prejobtypeList.filter((el: any) => el.subCd.charAt(0) === 'B');
        const formatSelectBoxFormList = jobTypeList.map((el: IComCd, i: number) => {
          return {
            type: 'wJobtype',
            wJobtype: el.subCd,
            name: el.cdName,
            cdSort: i + 1,
          };
        });
        const newObj = {
          type: 'wJobtype',
          wJobtype: '',
          name: t('선택'),
          cdSort: 0,
        };
        const addAllSelect: any = [...formatSelectBoxFormList, newObj];
        const sortedArray: any = arraySortByAscdOrder(addAllSelect, 'cdSort');
        // setJobtypeCdList(formatSelectBoxFormList);
        setEnrollJobtypeCdList(sortedArray);
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  const saveNewWorkersAPI = async (array: IWorkerSimple[]) => {
    const req = { workerSimpleReqDto: array };
    const res = await apiPost({ path: '/worker/simple', req });
    const { statusCode, message } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      getReportListAPI();
      onClickClose();
      for (const v of array) {
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '근로자 정보(팝업)',
          action: '근로자 간편등록',
          etc: `${v.wName}`,
        });
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 협력업체, 공종, 직종 일괄적용
  const applyAll = () => {
    if (userInfo.prejobtypeYn === 'Y') {
      const newArray = tableState.map((el: any) =>
        el.checked
          ? {
              ...el,
              checked: false, // 일괄적용 후 셀렉트값 false로 변경
              sjCd: enrollInfo.sjCd,
              wPrejobtype: enrollInfo.wPrejobtype,
              wJobtype: enrollInfo.wJobtype,
              options: enrollJobtypeCdList,
            }
          : { ...el }
      );
      setTableState(newArray);
    }

    if (userInfo.prejobtypeYn === 'N') {
      const newArray = tableState.map((el: any) =>
        el.checked
          ? {
              ...el,
              checked: false, // 일괄적용 후 셀렉트값 false로 변경
              sjCd: enrollInfo.sjCd,
              wPrejobtype: '000',
              wJobtype: enrollInfo.wJobtype,
              options: enrollJobtypeCdList,
            }
          : { ...el }
      );
      setTableState(newArray);
    }

    setSelectAll(false);
  };

  const onChangeAddCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (!isNaN(Number(value)) && value.length <= 2) {
      setAddRowCount(parseInt(value, 10));
    }
  };

  const onChangeSelectAll = () => {
    setSelectAll((prev) => !prev);
  };

  const onChangeCheckboxValue = (index: number) => {
    setTableState((prev) => prev.map((el, i) => (i === index ? { ...el, checked: !el.checked } : el)));
  };

  const onChangeInputValue = (e: React.ChangeEvent<HTMLInputElement>, index: number, maxLength?: number) => {
    const { name, value } = e.currentTarget;
    const newData: any = [...tableState];

    // 입력 자리수를 넘지 않을때만 스테이트 업데이트
    if (maxLength) {
      if (value.length <= maxLength) {
        newData[index][name] = value?.trim();
        setTableState(newData);
      }
    } else {
      newData[index][name] = value?.trim();
      setTableState(newData);
    }

    const inputIndex = name === 'wHnum1' ? 1 : 2;
    if ((name === 'wHnum1' && value.length === 3) || (name === 'wHnum2' && value.length === 4)) {
      setTimeout(() => inputRefs.current[index][inputIndex]?.focus(), 0);
    }
  };

  const onClickAddRowCount = () => {
    const newRows = Array.from({ length: addRowCount }, () => ({
      ...initialWorkerSimple,
      sjCd: enrollInfo.sjCd,
      wPrejobtype: userInfo.prejobtypeYn === 'Y' ? enrollInfo.wPrejobtype : '000',
      wJobtype: enrollInfo.wJobtype,
      options: enrollJobtypeCdList,
      origin: 'web', // 엑셀에서 등록한 데이터인지, 웹에서 신규항목 추가한 데이터인 지, 데이터의 오리진
    }));
    setTableState((prev) => [...prev, ...newRows]);
    setIsTableInitialRender(false);
    // setAddRowCount(1);
    // setEnrollInfo({ sjCd: '', wPrejobtype: '', wJobtype: '' });
  };

  const onClickDeleteWorker = (index: number) => {
    setTableState((prev) => prev.filter((_, i) => i !== index));
    setIsTableInitialRender(true);
  };

  const onClickClose = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };

  const onClickExcelEnroll = () => {
    setOpenSubModal((prev: IModal) => ({
      ...prev,
      status: true,
      type: 'registerExcell',
    }));
  };
  function getBorderStyle(data: any, borderColor: string, property: string): React.CSSProperties {
    /**
     * 보더처리하는 경우
     * 1) 엑셀에서 가져온 공종/직종 데이터가 맵핑이 안됐을때
     * 2) 저장을 클릭한 후 필수입력값(근로자명, 협력업체)이 빈값일 때
     */
    if (data[property] === '' && (data.origin === 'excel' || (data.origin === 'web' && (property === 'wName' || property === 'sjCd')))) {
      return { border: `1px solid ${borderColor}` };
    }
    if (property === 'wBdate' && data[property] !== '' && data[property].length < 8) {
      return { border: `1px solid ${borderColor}` };
    }
    if (property === 'wHnum1' && (data.wHnum2 !== '' || data.wHnum3 !== '') && data[property] !== '010') {
      return { border: `1px solid ${borderColor}` };
    }
    if (property === 'wHnum2' && data[property] !== '' && data[property].length < 3) {
      return { border: `1px solid ${borderColor}` };
    }
    if (property === 'wHnum3' && data[property] !== '' && data[property].length < 4) {
      return { border: `1px solid ${borderColor}` };
    }
    return {};
  }

  const onClickSaveWorkers = () => {
    setIsSaveClicked(true);
    const requiredFieldArray = ['wName', 'sjCd'];
    let wBdateChk = false;
    let numberChk = false;
    const emptyCheck = tableState.filter((el: any) => {
      if (el.wBdate !== '' && el.wBdate.length < 8) wBdateChk = true;
      if ((el.wHnum2 !== '' || el.wHnum3 !== '') && el.wHnum1 !== '010') numberChk = true;
      if (el.wHnum2 !== '' && el.wHnum2.length < 3) numberChk = true;
      if (el.wHnum3 !== '' && el.wHnum3.length < 4) numberChk = true;
      const check = requiredFieldArray.find((el2: string) => el[el2] === '');
      return check;
    });
    if (emptyCheck.length > 0) {
      toast.warning(t('필수입력값을 모두 입력하세요'));
    } else if (numberChk) {
      toast.warning(t('휴대폰번호를 확인하세요'));
    } else if (wBdateChk) {
      toast.warning(t('생년월일을 확인해주세요'));
    } else {
      // 전화번호 이어붙이기
      const mergeWHnum = tableState.map((el) => {
        if (el.wHnum2 !== '' && el.wHnum3 !== '') {
          return { ...el, wHnum: `${el.wHnum1}-${el.wHnum2}-${el.wHnum3}` };
        }
        return el;
      });
      const newArray = mergeWHnum.map(({ checked, wHnum1, wHnum2, wHnum3, options, origin, ...rest }: any) => rest);
      // 간편등록은 신규등록이므로 아래로직 주석처리함
      // const mSiteString = mSiteCheckState
      //   .map((el) => {
      //     const find = mSiteCheck.find((orgMsite) => orgMsite.sSeq === el.sSeq);
      //     // 기존에 선택되었던 현장이지만 체크 해제후 저장할때 msite 끝에 'D'삽입
      //     if (find) {
      //       if (find.checked && !el.checked) {
      //         return `${el.mhCd}${el.msCd}D`;
      //       }
      //       if (!find.checked && !el.checked) {
      //         return null;
      //       }
      //     }
      //     return `${el.mhCd}${el.msCd}`;
      //   })
      //   .filter((item) => item !== null)
      //   ?.join(',');

      // msiteCd 추가
      const mSiteString = mSiteCheckState
        ?.filter((el) => el.checked)
        ?.map((el2) => `${el2.mhCd}${el2.msCd}`)
        ?.join(',');
      const addMsiteCd = newArray.map((el: any) => ({ ...el, msiteCd: msiteUseYn === 'Y' ? mSiteString : '' }));
      saveNewWorkersAPI(trimArray(addMsiteCd));
    }
  };

  const defaultSjCd = (codeParams: string) => {
    const findObject: any = siteJoinCdList.find((el: any) => el.sjCd === codeParams);
    if (findObject !== undefined) return findObject.name;
    return undefined;
  };

  const defaultPrejobtype = (codeParams: string) => {
    const findObject: any = preJobtypeCdList.find((el: any) => el.wPrejobtype === codeParams);
    if (findObject !== undefined) return findObject.name;
    return undefined;
  };

  const defaultJobtype = (codeParams: string) => {
    const findObject: any = jobtypeCdList.find((el: any) => el.wJobtype === codeParams);
    if (findObject !== undefined) return findObject.name;
    return undefined;
  };

  const defaultJobtype2 = (codeParams: string, i: number) => {
    const findObject: any = tableState[i].options.find((el: any) => el.wJobtype === codeParams);
    if (findObject !== undefined) return findObject.name;
    return undefined;
  };

  const handleCheck = (sSeqParam: string) => {
    const newArray = mSiteCheckState.map((el) => (el.sSeq === sSeqParam ? { ...el, checked: !el.checked } : el));
    setMSiteCheckState(newArray);
  };

  const getChecked = () => tableState.filter((el: any) => el.checked === true).length;

  return (
    <ModalBackground onClick={onClickClose} role='presentation'>
      <InfoWorkerModalStyle>
        {!(openSubModal.status && openSubModal.type === 'registerExcell') ? (
          <div
            className='modal'
            onClick={(event) => {
              event.stopPropagation();
            }}
            role='presentation'
          >
            <div className='inputForm-head flex-between'>
              <div className='modalHeaderTitle'>{t('근로자 간편등록')}</div>
              <div className='closeBtn' onClick={onClickClose} role='presentation'>
                <span className='material-symbols-rounded'>close</span>
              </div>
            </div>
            <div className='tableTop'>
              <div className='flex-basic'>
                <div className='flex-basic'>
                  <div className=''>
                    <SearchSelectBoxSm
                      options={siteJoinCdList}
                      defaultOption={t('협력업체 선택')}
                      state={enrollInfo}
                      setState={setEnrollInfo}
                      stateKey='sjCd'
                      codeKey='name'
                      optionHeight='height-md'
                    />
                  </div>
                </div>
                {userInfo.prejobtypeYn === 'Y' && (
                  <div className='flex-basic prejobtype '>
                    <div>
                      <SearchSelectBoxSm
                        options={preJobtypeCdList}
                        defaultOption={t('공종 선택')}
                        state={enrollInfo}
                        setState={setEnrollInfo}
                        stateKey='wPrejobtype'
                        codeKey='name'
                        optionHeight='height-md'
                      />
                    </div>
                  </div>
                )}
                <div className='flex-basic '>
                  <div>
                    <SearchSelectBoxSm
                      options={enrollJobtypeCdList}
                      defaultOption={t('직종 선택')}
                      state={enrollInfo}
                      setState={setEnrollInfo}
                      stateKey='wJobtype'
                      codeKey='name'
                      optionHeight='height-md'
                    />
                  </div>
                </div>
                <div className='flex-basic'>
                  <input type='number' value={addRowCount} onChange={onChangeAddCount} />
                  <span>{t('개')}</span>
                </div>
                {auth.createAuth && <BtnBlue onClick={onClickAddRowCount}>{t('항목 추가')}</BtnBlue>}

                {msiteUseYn === 'Y' && mSiteCheckState && mSiteCheckState?.length && (
                  <div className='flex-basic mSiteCheck'>
                    {mSiteCheckState?.map((el: IMsite) => (
                      <div key={el.sSeq} className='flex-center checkbox-item'>
                        <label className='flex-center' htmlFor={el.sSeq}>
                          <input id={el.sSeq} name={el.sSeq} type='checkbox' checked={el.checked} onChange={() => handleCheck(el.sSeq)} />
                          {el.msName}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* <div className='buttonsWrapper'>{auth.createAuth && <BtnBlue onClick={onClickAddRowCount}>항목 추가</BtnBlue>}</div> */}
            </div>
            {getChecked() > 0 && (
              <div className='checkedApply'>
                <span>{getChecked()}</span>
                {t('개')} {t('항목이 선택되었습니다')}
                <BtnBlue onClick={applyAll}>{t('일괄 적용')}</BtnBlue>
              </div>
            )}
            <div className='inputForm-body'>
              <InputTable className='margin-left-05'>
                <div ref={scrollContainerRef}>
                  <div className='thead'>
                    <div className='tr'>
                      <div className='trCol2p5 flex-center tableStickyNo'>
                        <input type='checkbox' checked={selectAll} onChange={onChangeSelectAll} />
                      </div>
                      <div className='trCol2p5 flex-center'>No</div>
                      <div className='trCol6 required flex-center tableStickyTitle content-overflow'>{t('근로자명')}</div>
                      <div className='trCol10 required flex-center'>{t('협력업체')}</div>
                      {userInfo.prejobtypeYn === 'Y' && <div className='trCol8 flex-center'>{t('공종')}</div>}
                      <div className='trCol8 flex-center'>{t('직종')}</div>
                      <div className='trCol12 flex-center'>
                        {t('휴대폰번호')}({t('숫자만 입력')})
                      </div>
                      <div className='trCol6 flex-center'>
                        {t('생년월일')}({t('8자리')})
                      </div>
                      <div className='trCol5 flex-center'> </div>
                    </div>
                  </div>
                  <div className='table'>
                    <div className='tbody'>
                      {tableState.map((el: IWorkerSimple, i: number) => (
                        <div className='tr' key={i}>
                          <div className='trCol2p5 flex-center tableStickyNo'>
                            <input type='checkbox' checked={el.checked || false} onChange={() => onChangeCheckboxValue(i)} />
                          </div>
                          <div className='trCol2p5 flex-center'>{i + 1}</div>
                          <div className='trCol6 flex-center tableStickyTitle content-overflow'>
                            <input
                              type='text'
                              name='wName'
                              value={el.wName}
                              onChange={(e) => onChangeInputValue(e, i)}
                              style={isSaveClicked ? getBorderStyle(el, 'red', 'wName') : undefined}
                              maxLength={50}
                            />
                          </div>
                          <div className='trCol10 flex-center selectBox'>
                            <SearchSelectBoxs
                              options={siteJoinCdList}
                              defaultOption={el.sjCd !== '' ? defaultSjCd(el.sjCd) : t('선택')}
                              state={tableState}
                              setState={setTableState}
                              stateKey='sjCd'
                              codeKey='name'
                              index={i}
                              useFlag={false}
                              getBorderStyle={isSaveClicked ? getBorderStyle(el, 'red', 'sjCd') : undefined}
                              disabled={!auth.createAuth && !auth.updateAuth}
                              optionHeight='height-sm'
                            />
                          </div>
                          {userInfo.prejobtypeYn === 'Y' && (
                            <div className='trCol8 flex-center selectBox'>
                              <SearchSelectBoxs
                                options={preJobtypeCdList}
                                defaultOption={el.wPrejobtype !== '' ? defaultPrejobtype(el.wPrejobtype) : t('선택')}
                                state={tableState}
                                setState={setTableState}
                                stateKey='wPrejobtype'
                                codeKey='name'
                                index={i}
                                useFlag={false}
                                getBorderStyle={getBorderStyle(el, 'lightgreen', 'wPrejobtype')}
                                disabled={!auth.createAuth && !auth.updateAuth}
                                optionHeight='height-sm'
                              />
                            </div>
                          )}
                          <div className='trCol8 flex-center selectBox'>
                            <SearchSelectBoxs
                              options={el.options}
                              defaultOption={el.wJobtype !== '' ? defaultJobtype2(el.wJobtype, i) : t('선택')}
                              state={tableState}
                              setState={setTableState}
                              stateKey='wJobtype'
                              codeKey='name'
                              index={i}
                              useFlag={false}
                              getBorderStyle={getBorderStyle(el, 'lightgreen', 'wJobtype')}
                              disabled={!auth.createAuth && !auth.updateAuth}
                              optionHeight='height-sm'
                            />
                          </div>
                          <div className='trCol12 flex-center phoneNumber'>
                            <input
                              type='number'
                              name='wHnum1'
                              value={el.wHnum1}
                              onChange={(e) => onChangeInputValue(e, i, 3)}
                              ref={(refEl) => {
                                if (!inputRefs.current[i]) {
                                  inputRefs.current[i] = [];
                                }
                                inputRefs.current[i][0] = refEl;
                              }}
                              style={getBorderStyle(el, 'lightgreen', 'wHnum1')}
                            />
                            <span>-</span>
                            <input
                              type='number'
                              name='wHnum2'
                              value={el.wHnum2}
                              onChange={(e) => onChangeInputValue(e, i, 4)}
                              ref={(refEl) => {
                                if (!inputRefs.current[i]) {
                                  inputRefs.current[i] = [];
                                }
                                inputRefs.current[i][1] = refEl;
                              }}
                              style={getBorderStyle(el, 'lightgreen', 'wHnum2')}
                            />
                            <span>-</span>
                            <input
                              type='number'
                              name='wHnum3'
                              value={el.wHnum3}
                              onChange={(e) => onChangeInputValue(e, i, 4)}
                              ref={(refEl) => {
                                if (!inputRefs.current[i]) {
                                  inputRefs.current[i] = [];
                                }
                                inputRefs.current[i][2] = refEl;
                              }}
                              style={getBorderStyle(el, 'lightgreen', 'wHnum3')}
                            />
                          </div>
                          <div className='trCol6 flex-center selectBox'>
                            <input type='number' name='wBdate' value={el.wBdate} onChange={(e) => onChangeInputValue(e, i, 8)} style={getBorderStyle(el, 'lightgreen', 'wBdate')} maxLength={8} />
                          </div>
                          <div className='trCol5 flex-center delete'>
                            <BtnRed onClick={() => onClickDeleteWorker(i)}>{t('제거')}</BtnRed>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </InputTable>
            </div>

            <div className='modal-footer flex-between'>
              <BtnGreen onClick={onClickExcelEnroll}>{t('엑셀등록')}</BtnGreen>
              {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={onClickSaveWorkers}>{t('저장')}</BtnBlue>}
            </div>
          </div>
        ) : (
          <RegisterExcelModal state={tableState} setState={setTableState} setOpenModal={setOpenSubModal} onClickClose={onClickClose} />
        )}
      </InfoWorkerModalStyle>
    </ModalBackground>
  );
};

export default EnrollmentWorkerModal;
