import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { msiteListState } from '../../../atoms';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const Root = styled.div`
  height: 2.5rem;
  .checkbox-container {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    border-radius: 0.25rem;
    .checkbox-item {
      height: 100%;
      &.hidden {
        display: none;
      }
      label {
        &:hover {
          cursor: pointer;
        }
        width: fit-content;
        padding-right: 0 !important;
      }
      input {
        width: 1rem;
        height: 1rem;
        outline: none !important;
      }
    }
  }
`;

interface Props {
  isViewMode: boolean;
  wCd: string;
  cdName: string;
  workerInputSet: any;
  setWorkerInputSet: any;
  propertyName: string;
  isLoading: boolean;
  isNewAdd: boolean;
}

const MsiteCd = ({ isViewMode, wCd, cdName, workerInputSet, setWorkerInputSet, propertyName, isLoading, isNewAdd }: Props) => {
  const { t } = useTranslation();
  const listData = useRecoilValue(msiteListState);
  const [list, setList] = useState<any[]>([]);
  const [editKey, setEditKey] = useState(false);

  const msiteCdIntoCheckValue = (lista: any) => {
    const newList = lista
      ?.filter((el: any) => el.useYn === 'Y')
      ?.map((el2: any) => ({ name: el2.msName, check: el2.wregYn === 'Y', value: `${el2.mhCd}${el2.msCd}` }))
      ?.filter((el3: any) => el3);

    return newList;
  };

  useEffect(() => {
    if (isNewAdd) {
      const newList = msiteCdIntoCheckValue(listData);
      setList(newList);

      let value: string = '';
      newList.forEach((item: any) => {
        if (item.check) {
          value = value !== '' ? `${value},${item.value}` : `${item.value}`;
        }
      });
      const forEachResult = value.length === 0 ? '' : value;
      setWorkerInputSet(forEachResult);
    }
  }, []);

  useEffect(() => {
    if (!isNewAdd && !editKey) {
      const splitMsiteCd = workerInputSet ? workerInputSet.split(',') : [];
      // 3. msiteCd 값 배열로 분리한 값과 listData 비교하여 check 값 세팅
      const newData =
        listData
          ?.filter((el: any) => el.useYn === 'Y')
          ?.map((item: any) => {
            const listValue = `${item.mhCd}${item.msCd}`;
            const isIncluded = splitMsiteCd.includes(listValue);
            return {
              name: item.msName, //
              value: listValue,
              check: isIncluded,
            };
          }) || [];
      setList(newData);
    }
  }, [workerInputSet]);

  useEffect(() => {
    if (isNewAdd || (!isNewAdd && editKey)) {
      const newList = list
        ?.map((el: any) => (el.check ? el.value : null))
        ?.filter((el2: any) => el2)
        ?.join(',');
      setWorkerInputSet(newList);
    }
  }, [list]);

  const handleChange = (e: any) => {
    setEditKey(true);

    const { name } = e.target;
    setList((prev) =>
      prev.map((item: any) =>
        item.value === name //
          ? { ...item, check: !item.check }
          : item
      )
    );
  };

  return (
    <Root className='control insuranceCheck'>
      <label htmlFor={wCd}>{t(cdName)}</label>
      <div className='flex-basic checkbox-container'>
        {list?.length > 0 &&
          list?.map((item: any, index: number) => (
            <div key={item.value} className={`checkbox-item ${item.hidden ? 'hidden' : ''}`}>
              <label htmlFor={item.value}>
                <input
                  id={item.value} //
                  name={item.value}
                  type='checkbox'
                  checked={item.check}
                  onChange={handleChange}
                  disabled={isViewMode}
                />
                {t(item.name)}
              </label>
            </div>
          ))}
      </div>
    </Root>
  );
};

export default MsiteCd;
