import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PulseLoader } from 'react-spinners';
import DoughnutChart from '../DoughnutChart';
import { InputTable } from '../../assets/styles/InputTable';
import { color } from '../../assets/styles/theme';
import { apiGet } from '../../services/_common';
import useTodayDate from '../../hooks/useTodayDate';
import { formatDateYMD, todayYYYYMMDD } from '../../utils/formatDate';
import { useSetSjcdWDelY } from '../../utils/useSetSjcd';

// 출역 정보
export const AttendInfo = ({ name, weekDays, userInfo, theme }: any) => {
  const { t } = useTranslation();
  const [innerTab, setInnerTab] = useState<number>(0);
  const [attendCount, setAttendCount] = useState<any>({ todayCnt: 0, yesterdayCnt: 0, towDaysBeforeCnt: 0 });
  const [attendPercent, setAttendPercent] = useState<any>({ todayPercent: 0, yesterdayPercent: 0, towDaysBeforePercent: 0 });
  const { selectedDay, setSelectedDay } = useTodayDate(null);
  const todayYMD = todayYYYYMMDD();
  const [selectedDayString, setSelectedDayString] = useState(todayYMD);
  const [tableState, setTableState] = useState([]);
  const { sjCdList, isFetching } = useSetSjcdWDelY();
  const [siteJoinInfoListWDelY, setSiteJoinInfoListWDelY] = useState(sjCdList || []);

  useEffect(() => {
    if (sjCdList) setSiteJoinInfoListWDelY(sjCdList);
  }, [sjCdList]);

  const {
    data: dayAttendData,
    isFetching: isFetchingDayAttend,
    refetch: refetchDayAttend,
  } = useQuery(['attendDayGet', userInfo.hCd, userInfo.sCd, selectedDay], () => fetchAttendDayData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && !!sjCdList && !!selectedDay,
  });

  useEffect(() => {
    if (dayAttendData) {
      setAttendCount(dayAttendData.counts);
      setAttendPercent(dayAttendData.percents);
      setTableState(dayAttendData.groupedCountResult);
    }
  }, [dayAttendData]);

  const fetchAttendDayData = async () => {
    try {
      const dates = getPastDates();
      const path = '/attend/day';
      const aDate = { aDate1: dates.twoDaysBefore.replaceAll('-', ''), aDate2: dates.today.replaceAll('-', '') };
      const req = { ...aDate, hCd: userInfo.hCd, sCd: userInfo.sCd };
      const res = await apiGet({ path, req });

      const { attendList } = res.data.data;
      const counts = getCounts(dates, attendList);
      setAttendCount(counts);

      const percents = getPercents(counts);
      setAttendPercent(percents);
      // sjCd별로 그룹핑
      const grouped = attendList.reduce((acc: any, item: any) => {
        const group = acc.find((g: any) => g.sjCd === item.sjCd);
        const findSjCdName = siteJoinInfoListWDelY.find((el: any) => el.sjCd === item.sjCd);

        if (group) {
          group.attendData.push(item);
        } else {
          acc.push({
            sjCd: item.sjCd,
            sjName: findSjCdName !== undefined ? findSjCdName.cdName : '',
            attendData: [item],
          });
        }
        return acc;
      }, []);

      // 오늘/어제/그제 카운트
      const groupedCountResult = grouped.map((group: any) => {
        const count = {
          todayCnt: 0,
          yesterdayCnt: 0,
          twoDaysBeforeCnt: 0,
        };

        group.attendData.forEach((item: any) => {
          if (item.aDate === dates.today) count.todayCnt += 1;
          if (item.aDate === dates.yesterday) count.yesterdayCnt += 1;
          if (item.aDate === dates.twoDaysBefore) count.twoDaysBeforeCnt += 1;
        });

        return {
          sjCd: group.sjCd,
          sjName: group.sjName,
          ...count,
        };
      });
      setTableState(groupedCountResult);
      return { counts, percents, groupedCountResult };
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  const getPastDates = () => {
    // 선택한 날짜로부터 오늘,어제,2일전 날짜 구하기
    const today = selectedDay.format('YYYY-MM-DD');
    const yesterday = selectedDay.subtract(1, 'day').format('YYYY-MM-DD');
    const twoDaysBefore = selectedDay.subtract(2, 'day').format('YYYY-MM-DD');

    return {
      today,
      yesterday,
      twoDaysBefore,
    };
  };

  const getCounts = (dateInfo: any, attendInfo: []) => {
    const todayCnt = attendInfo.filter((item: any) => item.aDate === dateInfo.today).length;
    const yesterdayCnt = attendInfo.filter((item: any) => item.aDate === dateInfo.yesterday).length;
    const twoDaysBeforeCnt = attendInfo.filter((item: any) => item.aDate === dateInfo.twoDaysBefore).length;

    return {
      todayCnt,
      yesterdayCnt,
      twoDaysBeforeCnt,
    };
  };

  const getPercents = (countParam: any) => {
    const total = countParam.todayCnt + countParam.yesterdayCnt + countParam.twoDaysBeforeCnt;
    const result = {
      todayPercent: Math.round((countParam.todayCnt / total) * 100),
      yesterdayPercent: Math.round((countParam.yesterdayCnt / total) * 100),
      twoDaysBeforePercent: Math.round((countParam.twoDaysBeforeCnt / total) * 100),
    };
    return result;
  };

  const onClickDate = (dateParam: string) => {
    setSelectedDay(dayjs(dateParam));
    setSelectedDayString(dateParam.replaceAll('-', ''));
  };

  const onClickRefresh = () => {
    refetchDayAttend();
  };

  const getTodayCnt = () => {
    return tableState
      .map((el: any) => el.todayCnt)
      .reduce((prev, cur) => {
        return prev + cur;
      }, 0);
  };
  const getYesterdayCnt = () => {
    return tableState
      .map((el: any) => el.yesterdayCnt)
      .reduce((prev, cur) => {
        return prev + cur;
      }, 0);
  };
  const getTwoDaysBeforeCnt = () => {
    return tableState
      .map((el: any) => el.twoDaysBeforeCnt)
      .reduce((prev, cur) => {
        return prev + cur;
      }, 0);
  };
  return (
    <div className='attend'>
      <div className='widget-header'>
        <div className='widget-title flex-between innerTab'>
          <div className='widget-title-innerTab'>
            <span>{t(name)}</span>
            <div className='innerTab flex-basic'>
              <div className={innerTab === 0 ? 'active' : undefined} role='presentation' onClick={() => setInnerTab(0)}>
                {t('출역')}
              </div>
              <div className={innerTab === 1 ? 'active' : undefined} role='presentation' onClick={() => setInnerTab(1)}>
                {t('협력업체')}
              </div>
            </div>
          </div>
          <div className='refresh-btn' onClick={onClickRefresh} role='presentation'>
            <span className='material-symbols-rounded'>refresh</span>
          </div>
        </div>
        <div className='oneWeekCalendar-wrapper'>
          <div className='oneWeekCalendar flex-between'>
            {weekDays.map((el: any) => (
              <div
                key={el.date}
                className={`${el.fullDate === formatDateYMD(selectedDayString) ? 'todayHighlight' : ''} dayWrapper`}
                role='button'
                tabIndex={0}
                onClick={() => onClickDate(el.fullDate)}
              >
                <div className='day'>{t(el.dayName)}</div>
                <div className='date bold'>{el.date}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {isFetching || !siteJoinInfoListWDelY || isFetchingDayAttend || !dayAttendData ? (
        <div className='centered-content' style={{ width: '100%' }}>
          <PulseLoader color='rgb(0, 122, 255)' size='10px' />
        </div>
      ) : innerTab === 0 ? (
        <div className='widget-body chart'>
          <div className='doughnutChartWrapper'>
            <DoughnutChart
              showLegend={false}
              percentage={[attendPercent.todayPercent, attendPercent.yesterdayPercent, attendPercent.twoDaysBeforePercent]}
              label={[t('금일'), t('전일'), t('2일 전')]}
              centerText={false}
              colorSet={[theme === 'light' ? color.blue : color.blue_60, theme === 'light' ? color.green : color.green_60, theme === 'light' ? color.red : color.red_60]}
              borderStatus={false}
            />
          </div>
          <div className='content'>
            <dl>
              <dd className='chart-color blue' />
              <dt>{t('금일')}</dt>
              <span>
                <span className='value'>{attendCount.todayCnt}</span> {t('명')}
              </span>
            </dl>
            <dl>
              <dd className='chart-color green' />
              <dt>{t('전일')}</dt>
              <span>
                <span className='value'>{attendCount.yesterdayCnt}</span> {t('명')}
              </span>
            </dl>
            <dl>
              <dd className='chart-color red' />
              <dt>{t('2일 전')}</dt>
              <span>
                <span className='value'>{attendCount.twoDaysBeforeCnt}</span> {t('명')}
              </span>
            </dl>
          </div>
        </div>
      ) : (
        <div className='widget-body table'>
          <InputTable>
            <div className='thead'>
              <div className='tr'>
                <div className='trCol2p5 flex-basic tableStickyNo content-overflow padding-left'>{t('협력업체')}</div>
                <div className='trCol3 flex-center'>{t('금일')}</div>
                <div className='trCol3 flex-center'>{t('전일')}</div>
                <div className='trCol4 flex-center padding-right'>{t('2일 전')}</div>
              </div>
            </div>
            <div className='table'>
              <div className='tbody'>
                {tableState?.length > 0 ? (
                  tableState.map((el: any, i: number) => (
                    <div className='tr' key={`attendInfo_${i}`}>
                      <div className='trCol2p5 flex-basic tableStickyNo content-overflow padding-left'>{el.sjName}</div>
                      <div className='trCol3 flex-center shrink0'>
                        <span className={`pointValue ${el.todayCnt > 0 && el.todayCnt <= 10 ? 'under10' : ''} ${el.todayCnt > 10 ? 'over10' : ''}`}>{el.todayCnt}</span>
                      </div>
                      <div className='trCol3 flex-center shrink0'>
                        <span className={`pointValue ${el.yesterdayCnt > 0 && el.yesterdayCnt <= 10 ? 'under10' : ''} ${el.yesterdayCnt > 10 ? 'over10' : ''}`}>{el.yesterdayCnt}</span>
                      </div>
                      <div className='trCol4 flex-center shrink0 padding-right'>
                        <span className={`pointValue ${el.twoDaysBeforeCnt > 0 && el.twoDaysBeforeCnt <= 10 ? 'under10' : ''} ${el.twoDaysBeforeCnt > 10 ? 'over10' : ''}`}>{el.twoDaysBeforeCnt}</span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='noData'>No data.</div>
                )}
              </div>
            </div>
            <tfoot>
              <div className='tr'>
                <div className='trCol2p5 flex-basic tableStickyNo content-overflow padding-left'>
                  {t('합계')}
                  <span className='subNumber'>{tableState.length}</span>
                </div>
                <div className='trCol3 flex-center shrink0'>
                  <span className={`pointValue ${getTodayCnt() > 0 && getTodayCnt() <= 10 ? 'under10' : ''} ${getTodayCnt() > 10 ? 'over10' : ''}`}>{getTodayCnt()}</span>
                </div>
                <div className='trCol3 flex-center shrink0'>
                  <span className={`pointValue ${getYesterdayCnt() > 0 && getYesterdayCnt() <= 10 ? 'under10' : ''} ${getYesterdayCnt() > 10 ? 'over10' : ''}`}>{getYesterdayCnt()}</span>
                </div>
                <div className='trCol4 flex-center shrink0 padding-right'>
                  <span className={`pointValue ${getTwoDaysBeforeCnt() > 0 && getTwoDaysBeforeCnt() <= 10 ? 'under10' : ''} ${getTwoDaysBeforeCnt() > 10 ? 'over10' : ''}`}>
                    {getTwoDaysBeforeCnt()}
                  </span>
                </div>
              </div>
            </tfoot>
          </InputTable>
        </div>
      )}
    </div>
  );
};
