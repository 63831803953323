import styled from 'styled-components';

export const SubModal = styled.div`
  .tui-container {
    flex-grow: 1;
    overflow: hidden;
    margin-left: 0.75rem;
  }
  .searchWrapper {
    padding: 0.5rem;
    gap: 0.5rem;
    > button {
      width: 4rem;
      padding: 0;
      height: 2.5rem;
      font-size: 0.875rem;
    }
    > div:nth-child(1) {
      gap: 0.5rem;
      font-size: 0.875rem;
      > div > ul > li {
        width: 10rem;
        max-width: none;
        span {
          width: 100%;
        }
      }
    }
    > div:nth-child(2) > div {
      display: flex;
      flex-direction: row;
      input {
        font-size: 0.875rem;
      }
    }
  }
`;
