/**
 * 작성자 : 홍선영
 * 날짜 : 2023.04.10
 * 기능 : 특정항목 기준으로 배열 오름차순 정렬
 *
 */

export const arraySortByAscdOrder = (array: any[], sort: string) => {
  return array?.sort((a: any, b: any) => a[sort] - b[sort]);
};
