import styled from 'styled-components';

const Root = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  width: 100%;
  height: 2.75rem;
  border-radius: 0.25rem;
  color: ${({ theme }: { theme: any }) => theme.text_secondary};
  background-color: ${({ theme }: { theme: any }) => theme.tonal};
  .material-symbols-rounded {
    font-variation-settings: 'FILL' 1;
    font-size: 1.75rem;
    opacity: 1;
    transition: opacity 0.15s ease-in-out;
    &.small {
      font-variation-settings: 'wght' 700;
      font-size: 1.125rem;
    }
    &.medium {
      font-size: 2rem;
    }
    &.large {
      font-variation-settings: 'wght' 350;
      font-size: 2.25rem;
    }
  }
  .name {
    position: absolute;
    opacity: 0;
    font-size: 0.75rem;
    font-weight: 700;
    word-break: keep-all;
    width: 3.5rem;
    line-height: 1.25;
    white-space: normal;
    text-align: center;
    justify-content: center;
    align-items: center;
    transition: opacity 0.15s ease-in-out;
  }
  &:hover {
    color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep_blue};
    .material-symbols-rounded {
      opacity: 0;
      transition-delay: 1s;
    }
    .name {
      opacity: 1;
      transition-delay: 1.25s;
    }
    &:active {
      color: ${({ theme }: { theme: any }) => theme.filled_blue};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
    }
  }
`;

type Props = {
  data: {
    areaLabel: string;
    name: string;
    icon: string;
    mouseDown: () => void;
    mouseUp: () => void;
    iconSize: 'small' | 'medium' | 'large';
  };
};

const PTZCameraMovementButton = ({ data: { name, icon = 'add', mouseDown, mouseUp, iconSize, areaLabel } }: Props) => {
  return (
    <Root //
      onMouseDown={mouseDown}
      onMouseUp={mouseUp}
      aria-label={areaLabel}
    >
      <span className={`material-symbols-rounded ${iconSize ?? ''}`}>{icon}</span>
      <span className='name'>{name}</span>
    </Root>
  );
};

export default PTZCameraMovementButton;
