import { useTranslation } from 'react-i18next';
import Input from '../../../components/Input';
import { applyBorderStyle } from '../../../utils/applyBorderStyle';
import ClickableContainer from '../../s_cctv/S_cctv1/RealtimeCCTV/ClickableContainer';
import TableLamp from './TableLamp';
import { useSetAuth } from '../../../utils/useSetAuth';
import { useEffect, useState } from 'react';
import { userState } from '../../../atoms';
import { useRecoilValue } from 'recoil';
import { findJobTypeName, flattenSubLists, useGetJobTypeLists } from '../../../hooks/useJobType';
import JobtypeChip from './JobtypeChip';
import styled from 'styled-components';
import JobTypeModal from './JobTypeModal';
import Portal from '../../../components/Portal';

const Root = styled.div`
  .jobtype-chip-container {
    height: 100%;
    display: flex;
    align-items: stretch;
    gap: 0.5rem;
    .button {
      flex-shrink: 0;
      user-select: none;
      cursor: pointer;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.25rem;
      padding: 0 0.5rem;
      margin: auto 0;
      .material-symbols-rounded {
        font-variation-settings: 'FILL' 1, 'wght' 400;
        font-size: 1.5rem;
      }
    }
    .default-button {
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      color: ${({ theme }: { theme: any }) => theme.filled_slate_deep};
      &:hover {
        outline: 0.25rem solid ${({ theme }: { theme: any }) => theme.filled_slate};
        &:active {
          transform: scale(0.95);
        }
      }
    }
    .add-button {
      background-color: ${({ theme }: { theme: any }) => theme.filled_slate_deep};
      color: ${({ theme }: { theme: any }) => theme.board};
      &:hover {
        outline: 0.25rem solid ${({ theme }: { theme: any }) => theme.tonal_deep};
        &:active {
          transform: scale(0.95);
        }
      }
    }
    .delete-button {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
      color: ${({ theme }: { theme: any }) => theme.filled_red};
      &:hover {
        outline: 0.25rem solid ${({ theme }: { theme: any }) => theme.filled_red};
        &:active {
          transform: scale(0.95);
        }
      }
    }
    .scroll-container {
      padding-top: 0.75rem;
      padding-right: 0.5rem;
      flex-grow: 1;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
    }
    .checklist-container {
      flex-grow: 1;
      display: flex;
      align-items: center;
    }
    .checklist {
      margin: 0.5rem 0;
      height: 2rem;
      border-radius: 0.25rem;
      display: flex;
      align-items: center;
      gap: 0.25rem;
      background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
      padding-left: 0.375rem;
      padding-right: 1rem;
      .material-symbols-rounded {
        font-size: 1.375rem;
        color: ${({ theme }: { theme: any }) => theme.filled_green};
        display: flex;
        align-items: center;
        justify-content: center;
        height: 1.75rem;
        width: 1.75rem;
        border-radius: 0.25rem;
      }
      .description {
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.filled_green_deep};
      }
    }
  }
`;

interface Props {
  rowData: any;
  rowIndex: number;
  tableState: any;
  setTableState: any;
  onClickDeleteRow: any;
  isSaveClicked: boolean;
  originalData: any;
}

const SiteJobtypeTableRow = ({ rowData, rowIndex, tableState, setTableState, onClickDeleteRow, isSaveClicked, originalData }: Props) => {
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const initJobtypeIdx = rowData.jobtypeIdx || '';
  const [jobtypeIdx, setJobtypeIdx] = useState<string>(initJobtypeIdx);
  const [openModal, setOpenModal] = useState<{ status: boolean; type: string }>({
    status: false,
    type: 'jobtype',
  });

  const { prejobtypeYn } = useRecoilValue(userState);
  const { data: jobTypeLists, isLoading, isError } = useGetJobTypeLists(prejobtypeYn as 'Y' | 'N');
  const [flattenSubList, setFlattenSubList] = useState<JobtypeList[]>([]);
  const flattenList = prejobtypeYn === 'Y' ? flattenSubList : jobTypeLists;

  useEffect(() => {
    if (jobTypeLists && prejobtypeYn === 'Y') {
      setFlattenSubList(flattenSubLists(jobTypeLists));
    }
  }, [jobTypeLists, prejobtypeYn]);

  useEffect(() => {
    setTableState((prev: any[]) => {
      // 배열의 특정 요소를 불변성을 유지하며 업데이트
      const updatedArray = prev.map((item, index) => {
        if (index === rowIndex) {
          return { ...item, jobtypeIdx };
        }
        return item;
      });
      return updatedArray;
    });
  }, [jobtypeIdx]); // rowIndex도 의존성 배열에 추가

  const removeJobtype = (item: string) => {
    // 문자열을 배열로 변환
    const array = jobtypeIdx.split(',');
    // 배열에서 특정 요소 제거
    const filteredArray = array.filter((element) => element !== item);
    // 배열을 다시 문자열로 변환
    const resultString = filteredArray.join(',');

    setJobtypeIdx(resultString);
  };

  const onClickCheckToggle = () => {
    const newArray = tableState.map((el: any, i: number) => (i === rowIndex ? { ...el, useYn: el.useYn === 'Y' ? 'N' : 'Y' } : el));
    setTableState(newArray);
  };

  return (
    <Root
      className='tr' //
      role='button'
      tabIndex={0}
    >
      <div className='trCol2p5 flex-center tableStickyNo'>{rowIndex + 1}</div>
      <div className='trCol4 flex-center'>{rowData.sjCd}</div>
      <div className='trCol12 flex-center tableStickyTitle'>
        <Input
          type='text'
          id='sjName'
          name='sjName'
          state={tableState}
          setState={setTableState}
          stateType={{ type: 'array', index: rowIndex }}
          getBorderStyle={isSaveClicked ? applyBorderStyle(rowData.sjName, 'red', 'sjName') : undefined}
          useFlag
          maxLength={20}
        />
      </div>
      <div className='trCol8 flex-center'>
        <Input type='text' id='bigo' name='bigo' state={tableState} setState={setTableState} stateType={{ type: 'array', index: rowIndex }} useFlag maxLength={200} />
      </div>
      <div className='trCol6 flex-center'>
        <ClickableContainer
          onClick={onClickCheckToggle} //
          button={<TableLamp use={rowData.useYn === 'Y'} />}
        />
      </div>

      <div className='trCol4 flex-start content-overflow scroll jobtype-chip-container'>
        <div //
          className='button add-button'
          onClick={() => setOpenModal({ status: true, type: 'jobtype' })}
          role='button'
          tabIndex={0}
        >
          <span className='material-symbols-rounded'>add</span>
        </div>
        {rowData.jobtypeIdx !== null && rowData.jobtypeIdx !== '' ? (
          <div className='scroll-container'>
            {rowData.jobtypeIdx.split(',').map((selectedItem: string) => (
              <JobtypeChip //
                key={selectedItem}
                selectedItem={selectedItem}
                removeJobtype={removeJobtype}
                name={findJobTypeName(selectedItem, flattenList) ?? selectedItem}
              />
            ))}
          </div>
        ) : (
          <div className='checklist-container'>
            <div className='checklist'>
              <span className='material-symbols-rounded'>check</span>
              <span className='description'>{t('다중선택 가능')}</span>
            </div>
          </div>
        )}
        {
          // jobtypeIdx가 ''(빈 문자열)이 아닌 경우 지우기 버튼 표시
          jobtypeIdx !== '' && (
            <div
              className='button default-button' //
              onClick={() => setJobtypeIdx('')}
              role='button'
              tabIndex={0}
            >
              <span className='material-symbols-rounded'>backspace</span>
            </div>
          )
        }
        {
          // jobtypeIdx가 변경된 경우에만 복원 버튼 표시
          // 원본 데이터의 jobtypeIdx가 ''(빈 문자열) 또는 null이 아니어야 함
          originalData[rowIndex]?.jobtypeIdx !== '' && //
            originalData[rowIndex]?.jobtypeIdx !== null &&
            jobtypeIdx !== (originalData[rowIndex]?.jobtypeIdx || '') && (
              <div
                className='button default-button' //
                onClick={() => setJobtypeIdx(originalData[rowIndex].jobtypeIdx || '')}
                role='button'
                tabIndex={0}
              >
                <span className='material-symbols-rounded'>undo</span>
              </div>
            )
        }
        {auth.deleteAuth && (
          <div
            className='button delete-button' //
            onClick={() => onClickDeleteRow(rowIndex, rowData)}
            role='button'
            tabIndex={0}
          >
            <span className='material-symbols-rounded'>delete</span>
          </div>
        )}
      </div>

      <Portal openModal={openModal?.status}>
        {openModal && openModal.type === 'jobtype' && (
          <JobTypeModal
            setSelectedProps={setJobtypeIdx} //
            setOpenModal={setOpenModal}
            selectedProps={jobtypeIdx}
          />
        )}
      </Portal>
    </Root>
  );
};

export default SiteJobtypeTableRow;
