/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.08
 * 경로 : 터널관리 - 구역 현황 관리 > 종합진행현황
 */

import { Dispatch, SetStateAction, useEffect, useLayoutEffect, useState } from 'react';
import { SingleTunnelContainer } from '../../assets/styles/Tunnels';
import { PulseLoader } from 'react-spinners';
import { useSetAuth } from '../../utils/useSetAuth';
import { TAREA_INNERTAB_REALTIME_ATTEND, TAREA_INNERTAB_NORMAL } from '../../_constants';
import TunnelChipView from './TunnelChipView';
import SingleTunnelPoint from './SingleTunnelPoint';
import HalfDoughnutChart from '../chart/HalfDoughnutChart';
import TinyTunnelTab from './TinyTunnelTab';
import Portal from '../Portal';
import TareaInfoModal from '../Modal/tAreaInfoModal';
import IssueGuide from '../IssueGuide';

type Props = {
  tunnelList: TareaData[];
  setTunnelList: Dispatch<SetStateAction<TareaData[]>>;
  selectedTatCd: string;
  data?: any;
  filter: TunnelFilterData[];
  dashboard?: boolean;
  autoPlay?: boolean;
  updateSelectedTatCd?: () => void;
  siteDashboard?: boolean;
};

const SingleTunnel = ({ tunnelList, setTunnelList, selectedTatCd, data: detailData, filter, dashboard = false, autoPlay = false, updateSelectedTatCd, siteDashboard = false }: Props) => {
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  // State로 변환된 시작점 및 종점 데이터
  const [mutateData, setMutateData] = useState<{ startPoint: MutateDataType; endPoint: MutateDataType } | null>(null);
  const [transition, setTransition] = useState(false);
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '' });
  // 선택된 터널 데이터
  const selectedTunnelData: any = tunnelList?.find((tunnel: TunnelType) => tunnel.tatCd === selectedTatCd);

  // 데이터 변이 감지 시 시점 및 종점 데이터 업데이트
  useEffect(() => {
    const {
      eBsCount,
      eCameraCount,
      eDeviceCount,
      eDvNo,
      eLength,
      eRLength,
      eTesName,
      eTjcName,
      eUseYn,
      eWorkerCount,
      sBsCount,
      sCameraCount,
      sDeviceCount,
      sDvNo,
      sLength,
      sRLength,
      sTesName,
      sTjcName,
      sUseYn,
      sWorkerCount,
      tatCd,
    } = selectedTunnelData || {};

    const mutate = () => {
      if (!selectedTunnelData) return;

      // 시점 데이터 생성
      const startPoint = {
        tatCd,
        tatGubun: 'S',
        useYn: sUseYn,
        dvNo: sDvNo,
        workerCount: sWorkerCount,
        deviceCount: sDeviceCount,
        cameraCount: sCameraCount,
        bsCount: sBsCount,
        length: sLength,
        rLength: sRLength,
        tesName: sTesName,
        tjcName: sTjcName,
      };

      // 종점 데이터 생성
      const endPoint = {
        tatCd,
        tatGubun: 'E',
        useYn: eUseYn,
        dvNo: eDvNo,
        workerCount: eWorkerCount,
        deviceCount: eDeviceCount,
        cameraCount: eCameraCount,
        bsCount: eBsCount,
        length: eLength,
        rLength: eRLength,
        tesName: eTesName,
        tjcName: eTjcName,
      };

      // 변이 데이터 업데이트
      setMutateData({ startPoint, endPoint });
    };
    mutate();
  }, [tunnelList, selectedTatCd]);

  // 시점 및 종점 활성화 여부
  const startPoint = mutateData?.startPoint.useYn === 'Y';
  const endPoint = mutateData?.endPoint.useYn === 'Y';

  // 시점 및 종점 재생 상태 및 업데이트 함수
  const [isStartPlayFinish, setIsStartPlayFinish] = useState(false);
  const [isEndPlayFinish, setIsEndPlayFinish] = useState(false);

  // 시점 및 종점 재생 상태 업데이트
  useLayoutEffect(() => {
    // 시점은 사용안하고 종점만 사용할 때
    if (autoPlay && !startPoint && endPoint) {
      setIsStartPlayFinish(true);
      setIsEndPlayFinish(false);
    }
    // 종점은 사용안하고 시점만 사용할 때
    if (autoPlay && !endPoint && startPoint) {
      setIsStartPlayFinish(false);
      setIsEndPlayFinish(true);
    }
    // 시점 및 종점 모두 사용할 때
    if (autoPlay && endPoint && startPoint) {
      setIsStartPlayFinish(false);
      setIsEndPlayFinish(false);
    }
  }, [mutateData, autoPlay]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    // detailData가 없을 때
    if (autoPlay && detailData?.length === 0) {
      timeoutId = setTimeout(() => updateSelectedTatCd && updateSelectedTatCd(), 3000);
    }
    // detailData가 있고 시점 및 종점 재생이 끝났을 때
    if (autoPlay && detailData?.length !== 0 && isStartPlayFinish && isEndPlayFinish) {
      timeoutId = setTimeout(() => updateSelectedTatCd && updateSelectedTatCd(), 1000);
    }
    return () => clearTimeout(timeoutId);
  }, [detailData, isStartPlayFinish, isEndPlayFinish]);

  // 변이 데이터 렌더링
  const renderPoint = (): JSX.Element => (
    <>
      {startPoint && filterView.start && (
        <SingleTunnelPoint
          masterData={mutateData?.startPoint}
          detailData={detailData}
          filter={filter}
          transition={transition}
          dashboard={dashboard}
          autoPlay={autoPlay}
          selectedTatCd={selectedTatCd}
          updatePlayNow={() => setIsStartPlayFinish(true)}
          onClick={onClickTunnelChip}
          siteDashboard={siteDashboard}
        />
      )}
      {endPoint && filterView.end && (
        <SingleTunnelPoint
          masterData={mutateData?.endPoint}
          detailData={detailData}
          filter={filter}
          transition={transition}
          dashboard={dashboard}
          autoPlay={autoPlay}
          selectedTatCd={selectedTatCd}
          updatePlayNow={() => setIsEndPlayFinish(true)}
          onClick={onClickTunnelChip}
          siteDashboard={siteDashboard}
        />
      )}
    </>
  );

  // 전체 길이 및 반경 길이 계산 함수
  const getLength = () => {
    const startLength = mutateData?.startPoint.length ?? 0;
    const endLength = mutateData?.endPoint.length ?? 0;
    return startLength + endLength;
  };
  const getRLength = () => {
    const startRLength = mutateData?.startPoint.rLength ?? 0;
    const endRLength = mutateData?.endPoint.rLength ?? 0;
    return startRLength + endRLength;
  };

  // 전체 진행률 계산
  const totalLengthToPercentage = getLength() !== 0 ? ((getRLength() / getLength()) * 100).toFixed(0) : '0';

  // 필터 뷰 상태 및 초기화 효과
  const [filterView, setFilterView] = useState({ start: true, end: true });

  useEffect(() => {
    // 터널 이름이 변경될 때 필터 뷰 초기화
    setFilterView({ start: true, end: true });
  }, [selectedTunnelData?.tatName]);

  // 선택된 터널 데이터가 업데이트될 때 transition 상태를 업데이트하고, 500ms 이후에 다시 초기화
  useEffect(() => {
    const updateTransition = () => {
      setTransition(true);
      setTimeout(() => {
        setTransition(false);
      }, 1500);
    };
    updateTransition();
  }, [selectedTatCd]);

  const onClickTunnelInfo = (tareaParam: any, innerTabIndex: number, pointType?: 'S' | 'E', selectedTadCd?: string) => {
    const findIndex = tunnelList.findIndex((tunnel: TunnelType) => tunnel.tatCd === tareaParam.tatCd);
    if (findIndex !== -1) {
      setOpenModal((prev: any) => ({ ...prev, status: true, type: 'tareaDetail', data: tareaParam, index: findIndex, innerTabIndex, pointType, selectedTadCd }));
    }
  };

  const onClickAttendChip = (e: React.MouseEvent<HTMLDivElement>, param: number) => {
    e.stopPropagation();
    onClickTunnelInfo(selectedTunnelData, param);
  };

  const onClickTunnelChip = (param: number, pointType: 'S' | 'E', selectedTadCd: string) => {
    onClickTunnelInfo(selectedTunnelData, param, pointType, selectedTadCd);
  };

  if (tunnelList.length === 0 || !selectedTatCd) {
    return <IssueGuide />;
  }

  return (
    <SingleTunnelContainer className={`${dashboard ? 'dashboard' : ''} ${siteDashboard ? 'siteDashboard' : ''}`}>
      <div className='chart' role='presentation' onClick={() => onClickTunnelInfo(selectedTunnelData, TAREA_INNERTAB_NORMAL)}>
        <div className='titleName'>{selectedTunnelData?.tatName}</div>

        <HalfDoughnutChart value={100} chartType={siteDashboard ? 'doughnut' : !dashboard ? 'doughnut' : 'circle'} siteDashboard={siteDashboard} />
        <HalfDoughnutChart value={(getRLength() / getLength()) * 100} background chartType={siteDashboard ? 'doughnut' : !dashboard ? 'doughnut' : 'circle'} siteDashboard={siteDashboard} />
        <div className='percentage'>
          {totalLengthToPercentage}
          <span className='symbol'>%</span>
        </div>
        <span className='title'>전체 진행률</span>
        <div className='attendCount'>
          <TunnelChipView
            value={selectedTunnelData?.attendCount}
            icon='familiar_face_and_zone'
            size='x-small'
            onClick={(e: React.MouseEvent<HTMLDivElement>) => onClickAttendChip(e, TAREA_INNERTAB_REALTIME_ATTEND)}
          />
        </div>
        {!dashboard && <TinyTunnelTab isPointVisible={filterView} setIsPointVisible={setFilterView} isStartPointUse={startPoint} isEndPointUse={endPoint} />}
      </div>
      <div className='contentBox'>{renderPoint()}</div>
      <Portal openModal={openModal?.status}>
        {openModal.type === 'tareaDetail' && (
          <TareaInfoModal
            setOpenModal={setOpenModal}
            auth={auth}
            activeTab={openModal.innerTabIndex}
            data={openModal.data}
            dataList={tunnelList}
            setDataList={setTunnelList}
            index={openModal.index}
            showPrevNextBtn={false}
            pointType={openModal.pointType}
            selectedTadCd={openModal.selectedTadCd}
          />
        )}
      </Portal>
    </SingleTunnelContainer>
  );
};

export default SingleTunnel;
