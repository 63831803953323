/**
 * 작성자 : 한영광
 * 날짜 : 2023.04.26
 * 기능 : 마스터일반관리 - 본사관리 - 본사정보탭
 *
 * 작성자 : 홍선영
 * 날짜 : 2023.05.18
 * 수정사항 : 공통코드 항목을 쓰는 SelectBox컴포넌트를 ComCdSelectBox로 수정함
 *            ViewTable2를 삭제하고 ViewTable로 통합
 *
 * 2024.03.11 김광민
 * 수정사항 1. : 라이트모드/다크모드 상태 추가
 * 수정사항 2. : 동작 로직은 유지한채 가독성 향상을 위한 리팩토링
 */

import { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

import { userState } from '../../../atoms';
import { IComCdList, IModal } from 'customTypes';
import { COMCD_JONGMOK, COMCD_UPTAE, COMCD_USE_YN, INIT_USE_YN_A, INIT_USE_YN_Y } from '../../../_constants';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { BtnGhost } from '../../../components/Button';
import Input from '../../../components/Input';
import Portal from '../../../components/Portal';
import ViewTable from '../../../components/Table/ViewTable';
import DeleteModal from '../../../components/Modal/DeleteModal2';
import SelectBox from '../../../components/SelectBox';
import HeadInfoInputForm from '../../../components/Form/HeadInfoInputForm';
import { trimObject } from '../../../utils/trimObject';
import { setComCdListState } from '../../../utils/setComCdListState';
import { logPost } from '../../../services/log';
import BackButton from '../../../components/BackButton';
import { apiDelete, apiGet, apiPost } from '../../../services/_common';
import { useFetchCommonCodeList } from '../../../services/useSetCodeListInSelectBoxForm';

const Root = styled.div`
  &.hideRoot {
    visibility: hidden;
    position: absolute;
  }
  .inputForm-body {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  > .inputFormsWrapper {
    height: 3.5rem !important;
    flex-shrink: 0;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    padding: 0 0.5rem;
    border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    @media (min-width: 1024px) {
      padding: 0 1rem;
    }
  }
`;

type IsUpdatedLogoImage = 'N' | 'U' | 'D';
type Logo = { light: string; dark: string };

const HeadInfo = () => {
  const { t } = useTranslation();
  const INIT_UPTAE = { type: COMCD_UPTAE, [COMCD_UPTAE]: '', cdName: t('미선택') };
  const INIT_JONGMOK = { type: COMCD_JONGMOK, [COMCD_JONGMOK]: '', cdName: t('미선택') };
  const size = useOutletContext<any>();

  const userInfo = useRecoilValue(userState);
  const node = useRef<any>(null);
  const userInfoInputFormRef = useRef<any>(null); // 인풋 폼 ref
  const [searchOptionHName, setSearchOptionHName] = useState({ hName: '' });
  const [searchOptionUseYn, setSearchOptionUseYn] = useState(INIT_USE_YN_A);
  const [isNewAdd, setIsNewAdd] = useState(true); // 신규등록 여부
  const [rowState, setRowState] = useState({
    address1: '',
    address2: '',
    bigo: '',
    delYn: '',
    eDate: '',
    editor: '',
    hCd: '',
    hCeoname: '',
    hLogoimage: '',
    hDLogoimage: '',
    hName: '',
    hNum: '',
    hTel: '',
    jongmok: '',
    postNum: '',
    subDomain: '',
    uptae: '',
    useYn: 'Y',
    wDate: null,
    writer: null,
    latitude: '',
    longitude: '',
  }); // 서버에서 받아 온 raw data 어레이 중 1줄의 데이터 state
  const [viewData, setViewData] = useState([{}]);
  // 서버에서 받아 온 raw data 중 viewTable에 뿌려줄 데이터만 담는 state
  const [columnCount, setColumnCount] = useState(0);
  const [masterHeadList, setMasterHeadList] = useState<any>([]);
  const [orgMasterHeadList, setOrgMasterHeadList] = useState<any>([]); // 필터링되지않은 본사리스트
  const [uptaeComCdList, setUptaeComCdList] = useState<IComCdList[]>([]); // 업태 공통코드
  const [jongmokComCdList, setJongmokComCdList] = useState<IComCdList[]>([]); // 종목 공통코드
  const [hTelState, setHTelState] = useState({ num1: '', num2: '', num3: '' });
  const [hNumState, setHNumState] = useState({ num1: '', num2: '', num3: '' });
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });

  const initLogo = { light: '', dark: '' };
  const [logoImage, setLogoImage] = useState<Logo>(initLogo);
  const [typeOfLogoImage, setTypeOfLogoImage] = useState<Logo>(initLogo);
  // 로고 이미지 상태(D: 삭제, U: 수정, N: 변경없음)
  const [isUpdatedLogoImage, setIsUpdatedLogoImage] = useState<{ light: IsUpdatedLogoImage; dark: IsUpdatedLogoImage }>({ light: 'N', dark: 'N' });

  const [uptae, setUptae] = useState(INIT_UPTAE);
  const [jongmok, setJongmok] = useState(INIT_JONGMOK);
  const [useYn, setUseYn] = useState(INIT_USE_YN_Y);
  // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [viewMainPage, setViewMainPage] = useState(true);
  // 사용여부 공통코드 목록 (검색조건에서 사용, 전체포함)
  const { data: useYnComCdListWithAll } = useFetchCommonCodeList(COMCD_USE_YN, true);
  // 사용유무 공통코드 목록 (사용자 정보에서 사용)
  const { data: useYnComCdList } = useFetchCommonCodeList(COMCD_USE_YN, false);

  // 랜덤 문자열을 쿼리스트링으로 추가해서 이미지 캐싱 방지
  const randomString = Math.random().toString(36).substring(7);

  useEffect(() => {
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '본사 관리 > 본사 정보',
      action: '조회',
      etc: ``,
    });
  }, []);

  // 본사 정보를 불러오는 API 호출 후,
  // 성공적으로 데이터를 받아온 경우 사용유무 검색 옵션을 초기화합니다.
  useEffect(() => {
    const getHeadList = () => {
      getHeadListAPI().then((res: any) => {
        // API 호출 결과가 성공(200)인 경우
        if (res.status === 200) {
          // 사용유무 검색 옵션을 '전체'로 설정합니다.
          setSearchOptionUseYn(INIT_USE_YN_A);
        }
      });
    };
    getHeadList();
    // viewMainPage 상태가 변경될 때마다 이 useEffect를 다시 실행합니다.
  }, [viewMainPage, rowState]);

  useEffect(() => {
    // 업태값이 변경되면 종목 api 호출 및 종목선택값 초기화
    if (uptae[COMCD_UPTAE] !== '') {
      getJongmokListAPI(uptae[COMCD_UPTAE]);
    }
  }, [uptae[COMCD_UPTAE], viewMainPage]);

  useEffect(() => {
    // 본사 선택 시 로고 이미지 상태를 업데이트합니다.
    setLogoImage({
      // 로고 이미지가 있으면 사용하고, 없으면 빈 문자열을 할당합니다.
      light: rowState.hLogoimage ? `${rowState.hLogoimage}?v=${randomString}` : '',
      dark: rowState.hDLogoimage ? `${rowState.hDLogoimage}?v=${randomString}` : '',
    });
    // }, [rowState.hCd, viewMainPage, rowState.hLogoimage, rowState.hDLogoimage]);
  }, [rowState, viewMainPage]);

  useEffect(() => {
    // 도메인에 따라서 뷰테이블에 전달해야하는 키값만 viewTableProperties 배열안에 작성하면 됨.
    if (masterHeadList && masterHeadList.length > 0) {
      const viewTableProperties = ['hCd', 'hName', 'useYn'];
      setDataIntoViewTable(viewTableProperties);
    }
  }, [masterHeadList, viewMainPage]);

  useEffect(() => {
    // 검색옵션 변경됐을 때 필터링 처리
    // 필터링 기준
    const filterOptions = {
      hName: searchOptionHName.hName,
    };

    // 필터링된 어레이 리턴, 대소문자구분X
    const filteredArray = orgMasterHeadList.filter((item: any) => {
      return item.hName?.toLowerCase()?.includes(filterOptions.hName?.toLowerCase());
    });

    const useYnFilter = YNFilter(filteredArray, 'useYn', searchOptionUseYn.USE_YN);

    if (useYnFilter.length > 0) {
      setMasterHeadList(useYnFilter);
    } else {
      setMasterHeadList([]);
    }
  }, [searchOptionHName, searchOptionUseYn.USE_YN, orgMasterHeadList, viewMainPage]);

  const YNFilter = (initialArray: any[], key: string, option: any) => {
    if (option === 'Y') {
      return initialArray.filter((el: any) => el[key] === 'Y');
    }
    if (option === 'N') {
      return initialArray.filter((el: any) => el[key] === 'N');
    }
    return initialArray;
  };

  // 로우데이터에서 뷰테이블에 보여줄 데이터만 뽑아서 배열에 담아 리턴
  const setDataIntoViewTable = (properties: any) => {
    setColumnCount(properties.length);
    const viewDataArray: any = [];
    const allKeys = Object.keys(masterHeadList[0]);

    masterHeadList?.map((el: any, i: number) => {
      const result = allKeys.reduce((next, key: any) => {
        if (properties.includes(key)) {
          return { ...next, [key]: masterHeadList[i][key] };
        }
        return next;
      }, {});
      return viewDataArray.push(result);
    });
    setViewData(viewDataArray);
  };

  // 삭제버튼 클릭으로 삭제 모달창 띄우기
  const onClickDelete = () => {
    setOpenModal((prev) => ({ ...prev, status: true, type: 'delete', api: deleteHeadAPI }));
  };

  // 본사정보 조회 API
  const getHeadListAPI = async () => {
    const res = await apiGet({ path: '/head' });
    const { status, data } = res;
    if (status === 200) {
      // 삭제되지 않은 본사만 필터링해서 setState
      const activeHeadInfos = data.data.headInfoList.filter((el: any) => el.delYn === 'N');
      setMasterHeadList(activeHeadInfos);
      setOrgMasterHeadList(activeHeadInfos);
      setComCdListState(COMCD_UPTAE, setUptaeComCdList, false); // 공통코드(업태) 리스트 조회 API
    }
    return res;
  };

  // 공통코드(종목) 리스트 조회 API
  const getJongmokListAPI = async (uptaeCode: string) => {
    const req = { grCd: COMCD_JONGMOK };
    const res = await apiGet({ path: '/code/detail', req });
    const { status, data } = res;
    if (status === 200) {
      const subJongmok = data.data.comCdList.filter((el: any) => el.etc1 === uptaeCode);
      const newArray = subJongmok.map((el: any) => ({ type: COMCD_JONGMOK, [COMCD_JONGMOK]: el.subCd, cdName: el.cdName }));
      setJongmokComCdList(newArray);
    }
  };

  // 본사 정보를 저장하거나 수정하는 API 함수
  const postHeadAPI = async (reqData: any) => {
    // reqData에서 필요한 데이터를 구조 분해 할당으로 추출
    const { address1, address2, bigo, delYn, hCd, hCeoname, hLogoimage, hDLogoimage, hName, hNum, hTel, postNum, subDomain, latitude, longitude, logoBase64, logoBase64D } = reqData;
    // API 요청에 필요한 데이터를 req 객체에 할당
    const req = {
      address1,
      address2,
      bigo,
      delYn,
      hCd,
      hCeoname,
      hLogoimage, // 라이트모드 로고
      hDLogoimage, // 다크모드 로고
      hName,
      hNum,
      hTel,
      postNum,
      subDomain,
      uptae: uptae[COMCD_UPTAE], // 업태 코드
      jongmok: jongmok[COMCD_JONGMOK], // 종목 코드
      useYn: useYn[COMCD_USE_YN], // 사용 여부
      latitude,
      longitude,
      logoBase64, // 라이트모드 로고 Base64
      logoBase64D, // 다크모드 로고 Base64
      logoType: reqData.logoType, // 라이트모드 로고 타입
      logoTypeD: reqData.logoTypeD, // 다크모드 로고 타입
      editor: userInfo.userId, // 수정자 정보
      ...(reqData.hCd && { writer: userInfo.userId }), // hCd가 있으면 작성자 정보 추가
    };

    // API POST 요청 실행
    const res = await apiPost({ path: '/head', contentType: 'multipart/form-data', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      // 요청 성공 시
      const { headInfo } = data;

      // 새로운 본사 정보를 상태에 반영
      const newRowState = {
        ...headInfo,
        hLogoimage: headInfo.hLogoimage !== '' ? `${headInfo.hLogoimage}?v=${randomString}` : '',
        hDLogoimage: headInfo.hDLogoimage !== '' ? `${headInfo.hDLogoimage}?v=${randomString}` : '',
      };
      setRowState(newRowState);
      // 업태와 종목 상태 업데이트
      setUptae({ type: COMCD_UPTAE, [COMCD_UPTAE]: headInfo.uptae, cdName: headInfo.uptaeName });
      setJongmok({ type: COMCD_JONGMOK, [COMCD_JONGMOK]: headInfo.jongmok || '', cdName: headInfo.jongmokName || t('미선택') });

      // 본사 정보 목록을 업데이트
      const newMasterHeadList = [];
      if (!req.hCd) {
        // 신규 본사 정보인 경우
        newMasterHeadList.push(...masterHeadList, newRowState);
      } else {
        // 기존 본사 정보 수정인 경우
        for (let v of masterHeadList) {
          if (v.hCd === newRowState.hCd) {
            v = newRowState;
          }
          newMasterHeadList.push(v);
        }
      }
      // 상태 업데이트
      setMasterHeadList(newMasterHeadList);
      setOrgMasterHeadList(newMasterHeadList);
      // 성공 알림
      toast.success(t('본사 정보 저장 성공'));
      // 로그 기록
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '마스터일반관리-본사관리',
        action: '본사 정보 저장&수정',
        etc: `${reqData.hName}(${reqData.hCd || '신규'})`,
      });
    } else {
      // 요청 실패 시 에러 처리
      // toast.error(t(ERROR));
      toast.error(t('본사 정보 저장 실패'));
    }
  };

  // 본사정보 삭제 API
  const deleteHeadAPI = async () => {
    const req = { hCd: rowState.hCd, editor: userInfo.userId };
    const res = await apiDelete({ path: '/head', req });
    const { status, data } = res;
    if (status === 200) {
      const newMasterHeadList = [];
      for (const v of masterHeadList) {
        if (v.hCd !== req.hCd) {
          newMasterHeadList.push(v);
        }
      }
      setMasterHeadList(newMasterHeadList);
      setOrgMasterHeadList(newMasterHeadList);
      initiateState();
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '마스터일반관리-본사관리',
        action: '본사 정보 삭제',
        etc: `${rowState.hName}(${rowState.hCd})`,
      });
    }
  };

  /**
   * 저장 버튼 클릭 이벤트 핸들러입니다.
   * 입력된 데이터를 검증한 후 API를 호출하여 데이터를 저장합니다.
   */
  const onClickSave = () => {
    // 저장 버튼 클릭 상태를 true로 설정합니다.
    setIsSaveClicked(true);
    // 필수 입력값 목록을 정의합니다.
    const requireList = [uptae[COMCD_UPTAE], rowState.hName];
    // 필수 입력값이 모두 입력되었는지 검증합니다.
    for (const row of requireList) {
      if (row === '') {
        // 필수 입력값이 누락된 경우 경고 메시지를 표시하고 함수를 종료합니다.
        return toast.warning(t('필수입력값을 모두 입력하세요'));
      }
    }
    // 전화번호와 사업자 번호를 '-'를 사용하여 병합합니다.
    const hTelMergedNumber = `${hTelState.num1}-${hTelState.num2}-${hTelState.num3}`;
    const hNumMergedNumber = `${hNumState.num1}-${hNumState.num2}-${hNumState.num3}`;

    // 로고 업데이트 상태에 따라 로고 이미지의 Base64 인코딩 값을 설정합니다.
    const getLogoBase64 = (logoState: string, isUpdateState: string) => {
      if (isUpdateState === 'U') {
        // 로고가 업데이트된 경우 Base64 인코딩 값을 추출합니다.
        return logoState.split(',')[1] || '';
      }
      // 로고가 업데이트된 경우가 아니라면 모든 로고 관련 값을 초기화합니다.
      return '';
    };

    // 현재 상태에서 로고 이미지 정보를 추출합니다.
    let { hLogoimage, hDLogoimage } = rowState;

    // 라이트모드/다크모드의 Base64 인코딩 값을 설정합니다.
    const logoBase64 = getLogoBase64(logoImage.light, isUpdatedLogoImage.light);
    if (isUpdatedLogoImage.light === 'D') {
      hLogoimage = '';
    }
    const logoBase64D = getLogoBase64(logoImage.dark, isUpdatedLogoImage.dark);
    if (isUpdatedLogoImage.dark === 'D') {
      hDLogoimage = '';
    }

    // API 요청 데이터를 준비합니다.
    const reqData = {
      ...rowState,
      hTel: hTelMergedNumber,
      hNum: hNumMergedNumber,
      editor: userInfo.userId,
      delYn: 'N',
      hLogoimage,
      hDLogoimage,
      logoBase64,
      logoBase64D,
      logoType: typeOfLogoImage.light,
      logoTypeD: typeOfLogoImage.dark,
    };
    // 요청 데이터에서 불필요한 공백을 제거합니다.
    const trimData = trimObject(reqData);

    // 준비된 데이터를 사용하여 API를 호출합니다.
    return postHeadAPI(trimData);
  };

  // 신규등록 버튼 클릭해서 새 입력창(iputForm) 노출
  const onClickNewRegistration = () => {
    initiateState();
    setIsNewAdd(true);
    setUseYn(INIT_USE_YN_Y);
    setUptae(INIT_UPTAE);
    setJongmok(INIT_JONGMOK);
    if (size.innerSize.W < 1024) setViewMainPage(false);
  };

  const onClickInitiateSearchOption = () => {
    setSearchOptionUseYn(INIT_USE_YN_A);
    setSearchOptionHName({ hName: '' });
    setUseYn(INIT_USE_YN_Y);
    // onClickNewRegistration(); // 우측 인풋창도 초기화
  };

  // 스테이트 객체의 밸류를 ''로 초기화
  const initiateState = () => {
    Object.keys(rowState).map((el: any) => {
      return setRowState((prev: any) => ({ ...prev, [el]: '', useYn: 'Y' }));
    });
    setIsSaveClicked(false);
  };

  const backToMain = () => {
    setViewMainPage(true);
  };

  return (
    <div className={`content-container ${size.innerSize.W >= 1024 ? 'twoColumn column-55 max800' : 'oneColumn'}`}>
      <Root className={size.innerSize.W >= 1024 || viewMainPage ? 'showRoot' : 'hideRoot'}>
        <SearchOptions>
          <div className='inputsWrapper'>
            <div className='inputForm-row'>
              <div className='inputForm-col withLabelComCf'>
                <label htmlFor='useYn'>{t('사용유무')}</label>
                <SelectBox
                  options={useYnComCdListWithAll}
                  defaultOption={searchOptionUseYn.cdName}
                  state={searchOptionUseYn}
                  setState={setSearchOptionUseYn}
                  stateKey={COMCD_USE_YN}
                  initiateKey={searchOptionUseYn[COMCD_USE_YN]}
                  filterbar='filter-1-left'
                />
              </div>
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col'>
                <Input placeholder={t('본사명')} label='' type='text' id='hName' name='hName' state={searchOptionHName} setState={setSearchOptionHName} />
              </div>
            </div>
          </div>
          <div className='inputsWrapper'>
            <div className='secondSearchOption'>
              <div className='flex-basic textBtnGroup'>
                <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
              </div>
              <div className='flex-basic iconBtnGroup'>
                <BtnGhost onClick={onClickNewRegistration}>
                  <span className='material-symbols-rounded'>add</span>
                  {t('등록')}
                </BtnGhost>
              </div>
            </div>
          </div>
        </SearchOptions>
        <div className='searchContent inputForm-body' ref={node}>
          <ViewTable
            style={{
              width: 'calc(50% - 2rem)',
              colCount: columnCount,
            }}
            rawData={masterHeadList}
            state={viewData}
            setRowState={setRowState}
            setIsNewAdd={setIsNewAdd}
            userInfoInputFormRef={userInfoInputFormRef}
            setViewMainPage={() => setViewMainPage(false)}
            setUseYn={setUseYn}
            setJongmok={setJongmok}
            setUptae={setUptae}
          />
        </div>
      </Root>
      {(size.innerSize.W >= 1024 || !viewMainPage) && (
        <Root>
          <div className='inputFormsWrapper flexRowEm'>
            {size.innerSize.W < 1024 && <BackButton func={() => backToMain()} />}

            <div className='formTitle'>{isNewAdd ? t(`새로운 본사 정보`) : t(`본사 정보 상세`)}</div>
          </div>
          <HeadInfoInputForm
            state={rowState}
            setState={setRowState}
            uptaeList={uptaeComCdList}
            jongmokList={jongmokComCdList}
            useYnList={useYnComCdList}
            uptae={uptae}
            setUptae={setUptae}
            jongmok={jongmok}
            setJongmok={setJongmok}
            useYn={useYn}
            setUseYn={setUseYn}
            hTelState={hTelState}
            setHTelState={setHTelState}
            hNumState={hNumState}
            setHNumState={setHNumState}
            isNewAdd={isNewAdd}
            onClickSave={onClickSave}
            onClickDelete={onClickDelete}
            logoImage={logoImage}
            setLogoImage={setLogoImage}
            setTypeOfLogoImage={setTypeOfLogoImage}
            setIsUpdatedLogoImage={setIsUpdatedLogoImage}
            isSaveClicked={isSaveClicked}
            isUpdatedLogoImage={isUpdatedLogoImage}
          />
        </Root>
      )}
      <Portal openModal={openModal?.status}>{openModal && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
    </div>
  );
};

export default HeadInfo;
