/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.15
 * 경로 : 터널관리, 대시보드 & 상황판 > 터널
 */

import styled from 'styled-components';

const RefreshButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  .material-symbols-rounded {
    font-variation-settings: 'FILL' 1, 'wght' 300, 'GRAD' 0, 'opsz' 20;
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    opacity: 0.6;
  }
  &:hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    .material-symbols-rounded {
      opacity: 1;
    }
  }
  &:active {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep} !important;
  }
`;

const RefreshButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <RefreshButtonContainer>
      <div className='refresh-btn' onClick={onClick} role='presentation'>
        <span className='material-symbols-rounded'>refresh</span>
      </div>
    </RefreshButtonContainer>
  );
};

export default RefreshButton;
