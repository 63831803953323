/**
 * 작성자 : 한영광
 * 날짜 : 2023.05.11
 * 경로 : 본사소개 -> 본사정보 (src/pages/h_info/H_info1)
 *
 * 작성자 : 홍선영
 * 날짜 : 2023.05.17
 * 수정사항 : 탭메뉴 동적출력되도록 수정
 */
import { useState, useEffect, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { IComCdList, IModal, ITabObject } from 'customTypes';
import { userState, pageInfoState } from '../../atoms';
import { trimObject } from '../../utils/trimObject';
import Tab from '../../components/Tab';
import Portal from '../../components/Portal';
import DeleteModal from '../../components/Modal/DeleteModal2';
import HeadInfoInputForm from '../../components/Form/HeadInfoInputForm2';
import { COMCD_JONGMOK, COMCD_UPTAE, ERROR } from '../../_constants';
import { setComCdListState } from '../../utils/setComCdListState';
import { logPost } from '../../services/log';
import { useTranslation } from 'react-i18next';
import { apiGet, apiPost } from '../../services/_common';

const Root = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  .buttonsWrapper {
    align-items: flex-end;
    margin: 0px 0.5rem;

    > button {
      margin-left: 0.5rem;
    }
  }

  .searchContent {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 10rem;
  }

  .row {
    display: flex;
    flex-direction: row;
  }
`;

type UpdatedLogo = 'N' | 'U' | 'D';
type IsUpdatedLogoImage = { light: UpdatedLogo; dark: UpdatedLogo };
type Logo = { light: string; dark: string };

const Hinfo1 = () => {
  const { t } = useTranslation();
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const hNameRef = useRef<HTMLInputElement>(null);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);

  const randomString = Math.random().toString(36).substring(7); // 랜덤 문자열을 쿼리스트링으로 추가해서 이미지 캐싱 방지

  const userInfo = useRecoilValue(userState);
  const { userMenuList } = userInfo;
  const userInfoInputFormRef = useRef<any>(null);
  // 테이블 로우를 클릭했을 때 바로 inputForm 으로 스크롤 이동시키기 위한 인풋 폼 ref
  const [rowState, setRowState] = useState({
    address1: '',
    address2: '',
    bigo: '',
    delYn: '',
    eDate: '',
    editor: '',
    hCd: '',
    hCeoname: '',
    hLogoimage: '',
    hDLogoimage: '',
    hName: '',
    hNum: '',
    hTel: '',
    jongmok: '',
    jongmokName: '',
    postNum: '',
    subDomain: '',
    uptae: '',
    uptaeName: '',
    useYn: 'Y',
    wDate: null,
    writer: null,
  }); // 서버에서 받아 온 raw data 어레이 중 1줄의 데이터 state
  const [disabled, setDisabled] = useState(true); // 인풋/셀렉트박스 disable여부
  const [masterHeadList, setMasterHeadList] = useState<any>([]);
  const [uptaeComCdList, setUptaeComCdList] = useState<IComCdList[]>([]); // 업태 공통코드
  const [jongmokComCdList, setJongmokComCdList] = useState<IComCdList[]>([]); // 종목 공통코드
  const [hTelState, setHTelState] = useState({ num1: '', num2: '', num3: '' });
  const [hNumState, setHNumState] = useState({ num1: '', num2: '', num3: '' });
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const [logoImage, setLogoImage] = useState<Logo>({ light: '', dark: '' });
  const [typeOfLogoImage, setTypeOfLogoImage] = useState<Logo>({ light: '', dark: '' });
  // 로고 이미지 상태(D: 삭제, U: 수정, N: 변경없음)
  const [isUpdatedLogoImage, setIsUpdatedLogoImage] = useState<IsUpdatedLogoImage>({ light: 'N', dark: 'N' });
  const [uptae, setUptae] = useState<any>({ type: COMCD_UPTAE, [COMCD_UPTAE]: '', cdName: '', cdSort: 0 });
  const [jongmok, setJongmok] = useState<any>({ type: COMCD_JONGMOK, [COMCD_JONGMOK]: '', cdName: '', cdSort: 0 });
  // 업태값에 따라 필터링된 종목리스트
  const [filteredJongmokList, setFilteredJongmokList] = useState<any[]>([]);
  const [orgJongmokList, setOrgJongmokList] = useState<any[]>([]); // 종목전체리스트
  // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [newTabList, setNewTabList] = useState<ITabObject[]>();
  const initJongmok = { type: COMCD_JONGMOK, [COMCD_JONGMOK]: '', cdName: t('미선택') };

  useEffect(() => {
    const arr: any[] = [];
    userMenuList.map((v: any) => {
      const result = tabInfo?.tabList?.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  useEffect(() => {
    getHeadInfoAPI().then((res: any) => {
      if (res.status === 200) {
        getOrgJongmokListAPI();
        setComCdListState(COMCD_UPTAE, setUptaeComCdList, false);
        setComCdListState(COMCD_JONGMOK, setJongmokComCdList, false);
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '본사 정보',
          action: '조회',
          etc: ``,
        });
      }
    });
  }, [userInfo.hCd]);

  // 공통코드(종목) 리스트 조회 API
  const getOrgJongmokListAPI = async () => {
    const req = { grCd: COMCD_JONGMOK };
    const res = await apiGet({ path: '/code/detail', req });
    const { status, data } = res;
    if (status === 200) {
      setOrgJongmokList(data.data.comCdList);
    }
  };

  // 공통코드(종목) 리스트 조회 API
  const getJongmokListAPI = async (uptaeCode: string) => {
    const req = { grCd: COMCD_JONGMOK };
    const res = await apiGet({ path: '/code/detail', req });
    const { status, data } = res;
    if (status === 200) {
      const subJongmok = data.data.comCdList.filter((el: any) => el.etc1 === uptaeCode);
      const newArray = subJongmok.map((el: any) => ({ type: COMCD_JONGMOK, [COMCD_JONGMOK]: el.subCd, cdName: el.cdName }));
      setFilteredJongmokList([initJongmok, ...newArray]);
    }
  };

  // 업태값 변경에 따른 종목 리스트 필터링
  const filterJongmokList = (uptaeCode: string) => {
    const newJongmokList = orgJongmokList.filter((el: any) => el.etc1 === uptaeCode);
    const resultArray = newJongmokList.map((el: any) => ({ type: COMCD_JONGMOK, [COMCD_JONGMOK]: el.subCd, cdName: el.cdName }));
    setFilteredJongmokList([initJongmok, ...resultArray]);
  };

  useEffect(() => {
    if (rowState.uptae && uptae[COMCD_UPTAE]) {
      // 변경된 업태값과 원본업태값이 일치하지 않는경우
      if (uptae[COMCD_UPTAE] !== rowState.uptae) {
        // 해당업태에 해당하는 종목리스트 업데이트, 종목선택값 미선택으로 초기화
        filterJongmokList(uptae[COMCD_UPTAE]);
        setJongmok(initJongmok);
      } else {
        // 원본업태값에 해당하는 종목리스트 업데이트
        filterJongmokList(rowState.uptae);
      }
    }
  }, [uptae[COMCD_UPTAE], rowState]);

  // 수정버튼 클릭으로 취소, 저장 버튼 띄우기
  const onClickUpdate = () => {
    setDisabled(false);
  };

  // 취소버튼 클릭으로 수정 버튼 띄우기
  const onClickCancel = () => {
    getHeadInfoAPI();
    setDisabled(true);
    setIsUpdatedLogoImage({ light: 'N', dark: 'N' });
    setIsSaveClicked(false);
  };

  // 본사정보 조회 API
  const getHeadInfoAPI = async () => {
    const res = await apiGet({ path: '/head/info', req: { hCd: userInfo.hCd } });
    const { status, data } = res;
    if (status === 200) {
      const { headInfo } = data.data;
      const lightThemeLogo = headInfo.hLogoimage;
      const darkThemeLogo = headInfo.hDLogoimage;
      setLogoImage({
        // 로고 이미지가 있으면 사용하고, 없으면 빈 문자열을 할당합니다.
        light: lightThemeLogo ? `${lightThemeLogo}?v=${randomString}` : '',
        dark: darkThemeLogo ? `${darkThemeLogo}?v=${randomString}` : '',
      });
      setRowState(headInfo);
      const splitHtel = headInfo.hTel.split('-');
      setHTelState({
        num1: splitHtel[0],
        num2: splitHtel[1],
        num3: splitHtel[2],
      });

      const splitHnum = headInfo.hNum.split('-');
      setHNumState({
        num1: splitHnum[0],
        num2: splitHnum[1],
        num3: splitHnum[2],
      });
      if (headInfo.uptae) {
        setUptae({
          type: COMCD_UPTAE,
          [COMCD_UPTAE]: headInfo.uptae,
          cdName: headInfo.uptaeName,
        });
        getJongmokListAPI(headInfo.uptae);
        setJongmok({
          type: COMCD_JONGMOK,
          [COMCD_JONGMOK]: headInfo.jongmok,
          cdName: headInfo.jongmokName,
        });
      }
    }
    return res;
  };

  // 본사정보 저장&수정 API
  const postHeadAPI = async (reqData: any) => {
    const { wDate, eDate, uptaeName, jongmokName, ...req } = reqData;
    const res = await apiPost({ path: '/head', contentType: 'multipart/form-data', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      const { headInfo } = data;
      const newRowState = {
        //
        ...headInfo,
        hLogoimage: `${headInfo.hLogoimage}`,
        hDLogoimage: `${headInfo.hDLogoimage}`,
      };
      setRowState(newRowState);
      const newMasterHeadList = [];
      if (!req.hCd) {
        newMasterHeadList.push(...masterHeadList, newRowState);
      } else {
        for (let v of masterHeadList) {
          if (v.hCd === newRowState.hCd) {
            v = newRowState;
          }
          newMasterHeadList.push(v);
        }
      }
      setMasterHeadList(newMasterHeadList);
      setDisabled(true);
      toast.success(t('본사 정보 저장 성공'));
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '본사소개-본사정보',
        action: '수정',
        etc: ``,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 저장버튼 클릭해서 save API 호출하여 데이터전송
  const onClickSave = () => {
    // 저장 버튼 클릭 상태를 true로 설정합니다.
    setIsSaveClicked(true);
    // 필수 입력값 목록을 정의합니다.
    const requireList = [uptae[COMCD_UPTAE], rowState.hName];
    for (let i = 0; i < requireList.length; i += 1) {
      if (requireList[i] === '') {
        if (i === 1) {
          hNameRef.current?.focus();
          return toast.warning(t('본사명을 입력하셔야 합니다'));
        }
        return toast.warning(t('업태를 선택하셔야 합니다'));
      }
    }
    // 전화번호와 사업자 번호를 '-'를 사용하여 병합합니다.
    const hTelMergedNumber = `${hTelState.num1}-${hTelState.num2}-${hTelState.num3}`;
    const hNumMergedNumber = `${hNumState.num1}-${hNumState.num2}-${hNumState.num3}`;

    // 로고 업데이트 상태에 따라 로고 이미지의 Base64 인코딩 값을 설정합니다.
    const getLogoBase64 = (logoState: string, isUpdateState: string) => {
      if (isUpdateState === 'U') {
        // 로고가 업데이트된 경우 Base64 인코딩 값을 추출합니다.
        return logoState.split(',')[1] || '';
      }
      // 로고가 업데이트된 경우가 아니라면 모든 로고 관련 값을 초기화합니다.
      return '';
    };

    // 현재 상태에서 로고 이미지 정보를 추출합니다.
    let { hLogoimage, hDLogoimage } = rowState;

    // 라이트모드/다크모드의 Base64 인코딩 값을 설정합니다.
    const logoBase64 = getLogoBase64(logoImage.light, isUpdatedLogoImage.light);
    if (isUpdatedLogoImage.light === 'D') {
      hLogoimage = '';
    }
    const logoBase64D = getLogoBase64(logoImage.dark, isUpdatedLogoImage.dark);
    if (isUpdatedLogoImage.dark === 'D') {
      hDLogoimage = '';
    }

    const reqData = {
      ...rowState,
      hTel: hTelMergedNumber,
      hNum: hNumMergedNumber,
      uptae: uptae[COMCD_UPTAE],
      jongmok: jongmok[COMCD_JONGMOK],
      editor: userInfo.userId,
      hLogoimage,
      hDLogoimage,
      logoBase64,
      logoBase64D,
      logoType: typeOfLogoImage.light,
      logoTypeD: typeOfLogoImage.dark,
    };
    // 요청 데이터에서 불필요한 공백을 제거합니다.
    const trimData = trimObject(reqData);

    // 준비된 데이터를 사용하여 API를 호출합니다.
    return postHeadAPI(trimData);
  };

  const onClickTab = (mrCd: string) => {
    setCurrentTabMrCd(mrCd);
  };

  return (
    <div className='contents'>
      <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      <div className='content-container oneColumn'>
        <Root>
          <HeadInfoInputForm
            state={rowState}
            setState={setRowState}
            uptae={uptae}
            setUptae={setUptae}
            jongmok={jongmok}
            setJongmok={setJongmok}
            hTelState={hTelState}
            setHTelState={setHTelState}
            hNumState={hNumState}
            setHNumState={setHNumState}
            disabled={disabled}
            userInfoInputFormRef={userInfoInputFormRef}
            onClickSave={onClickSave}
            onClickCancel={onClickCancel}
            onClickUpdate={onClickUpdate}
            logoImage={logoImage}
            setLogoImage={setLogoImage}
            setTypeOfLogoImage={setTypeOfLogoImage}
            setIsUpdatedLogoImage={setIsUpdatedLogoImage}
            isUpdatedLogoImage={isUpdatedLogoImage}
            uptaeComCdList={uptaeComCdList}
            jongmokComCdList={filteredJongmokList}
            hNameRef={hNameRef}
            isSaveClicked={isSaveClicked}
          />
          <Portal openModal={openModal?.status}>{openModal && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
        </Root>
      </div>
    </div>
  );
};

export default Hinfo1;
