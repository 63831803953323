/**
 * 작성자 : 홍선영
 * 날짜 : 2024.03.20
 * 기능 : 0.5초 후 디바운스 값을 반환하는 hook
 *        키보드인풋과 같이 연속적으로 빠르게 변화하는 값을 매번 업데이트 할 필요가 없는 경우 사용.
 *        (한글처럼 자/모음을 조합하는 경우 매 키보드 입력마다 결과값을 업데이트할 필요가 없음. e.g. Input 검색필터)
 */

import { useEffect, useState } from 'react';

export const useDebounce = (value: string) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // 0.5초 후 디바운스 값 업데이트
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, 500); // 지연시간

    return () => {
      clearTimeout(handler);
    };
  }, [value]); // 값이 변경되는 경우에만 재호출

  return debouncedValue;
};
