import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PulseLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import 'material-symbols';
import dayjs from 'dayjs';
import i18n from './translation/i18n';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: any) => console.log(`${error.message}`),
    // onError: (error: any) => toast.error(`${error.message}`),
  }),
  defaultOptions: {
    queries: {
      // suspense: true,
      refetchOnWindowFocus: false,
    },
  },
});

if (i18n.language?.length) {
  dayjs.locale(i18n.language.substring(0, 2));
}

root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <RecoilRoot>
      <BrowserRouter>
        {/* <Suspense
          fallback={
            <div className='centered-content'>
              <PulseLoader color='rgb(0, 122, 255)' size={"10"} />
            </div>
          }
        > */}
        <App />
        {/* </Suspense> */}
      </BrowserRouter>
    </RecoilRoot>
    {/* <ReactQueryDevtools initialIsOpen /> */}
  </QueryClientProvider>
  // </React.StrictMode>
);
