import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import sun from '../../assets/images/icons/sun.png';
import cloud from '../../assets/images/icons/lighten_cloud.png';
import blur from '../../assets/images/icons/lighten_blur.png';
import rain from '../../assets/images/icons/white_rain.png';
import snow from '../../assets/images/icons/white_snow.png';
import littleCloud from '../../assets/images/icons/little_cloud.png';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
  .title {
    height: 4rem;
    width: 100%;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.75rem;
    font-weight: 700;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
  }
  .weatherWeek {
    width: 100%;
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 0.5rem;
    padding: 0 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    margin: 2rem 0;
    .weatherDay {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: ${({ theme }: { theme: any }) => theme.alpha_25};
      border-radius: 1rem;
      border-radius: 0.5rem;
      overflow: hidden;
      .dayTitle {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 0.5rem;
        width: 100%;
        &.red {
          color: ${({ theme }: { theme: any }) => theme.filled_red};
        }
        &.blue {
          color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
        }
        .day {
          font-size: 1.25rem;
          font-weight: 500;
          padding: 1rem 0;
          color: ${({ theme }: { theme: any }) => theme.board};
          width: 100%;
          text-align: center;
        }
        .date {
          font-size: 1.125rem;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: ${({ theme }: { theme: any }) => theme.alpha_75};
          border-radius: 2rem;
          width: 3rem;
          height: 3rem;
        }
      }
      .icon {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 1.25rem;
        padding: 0 0.75rem;
        margin: 1rem 0.5rem;
        margin: 0.5rem;
      }
      .temp {
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        background-color: ${({ theme }: { theme: any }) => theme.alpha_50};
        padding: 0.5rem 0;
        border-radius: 0.5rem;
        width: 100%;
        .data-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.125rem;
          font-size: 1.25rem;
          font-weight: 600;
          margin: 0.5rem 0;
          color: ${({ theme }: { theme: any }) => theme.text_primary};
          .unit {
            font-size: 1rem;
            font-weight: 500;
            color: ${({ theme }: { theme: any }) => theme.text_tertiary};
          }
          .material-symbols-rounded {
            font-size: 1.375rem;
            font-variation-settings: 'FILL' 1, 'wght' 500;
            &.lowest {
              color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
            }
            &.highest {
              color: ${({ theme }: { theme: any }) => theme.filled_red};
            }
          }
        }
      }
    }
  }
  &.medium {
    .weatherWeek {
      padding: 0;
      padding-right: 1rem;
      margin: 1rem 0;
      grid-template-columns: repeat(3, 1fr);
      .weatherDay {
        padding-top: 0.75rem;
        .day {
          display: none;
        }
        .date {
          height: 2.5rem;
          width: 2.5rem;
          font-size: 1.25rem;
        }
        .icon {
          margin: 0;
          padding: 0.5rem;
          padding: 0 0.75rem;
          width: 5rem;
        }
        .temp {
          padding: 0.5rem 0;
          flex-direction: row;
          justify-content: space-between;
          padding-right: 0.5rem;
          padding-left: 0.25rem;
          .data-wrapper {
            margin: 0;
            font-size: 1.0625rem;
            .material-symbols-rounded {
              font-size: 1.125rem;
            }
          }
        }
      }
    }
  }
`;

const getWeatherIcon = (weather: string) => {
  switch (weather) {
    case 'sunny':
      return <img src={sun} width='100%' />;
    case 'little_cloud':
      return <img src={littleCloud} width='100%' />;
    case 'cloudy':
      return <img src={cloud} width='100%' />;
    case 'blur':
      return <img src={blur} width='100%' />;
    case 'rain':
      return <img src={rain} width='100%' />;
    case 'sleet':
      return <img src={snow} width='100%' />;
    case 'snow':
      return <img src={snow} width='100%' />;
    case 'shower':
      return <img src={rain} width='100%' />;
    default:
      return null;
  }
};

interface Props {
  after6Days: IDays[];
  width: 'large' | 'medium';
}

const WeatherWideWeek = ({ after6Days, width }: Props) => {
  const { t } = useTranslation();

  return (
    <Root className={width}>
      {/* <div className='title'>{t('주간 날씨')}</div> */}
      <div className='weatherWeek'>
        {after6Days.map(({ date, day, maxTemp, minTemp, sky }: IDays) => {
          return (
            <div key={date} className='weatherDay'>
              <div className={`dayTitle ${day === '토' ? 'blue' : ''} ${day === '일' ? 'red' : ''}`}>
                <div className='day'>{t(day)}</div>
                <div className='date'>{date}</div>
              </div>
              <div className='icon'>{getWeatherIcon(sky)}</div>
              <div className='temp'>
                <div className='data-wrapper'>
                  <span className='material-symbols-rounded lowest'>arrow_downward</span>
                  {minTemp}
                  {/* <span className='unit'>℃</span> */}
                  <span className='unit'>°</span>
                </div>
                <div className='data-wrapper'>
                  <span className='material-symbols-rounded highest'>arrow_upward</span>
                  {maxTemp}
                  {/* <span className='unit'>℃</span> */}
                  <span className='unit'>°</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Root>
  );
};

export default WeatherWideWeek;
