/**
 * 작성자 : 김광민
 * 작성일자 : 2024.05.02
 * 작성내용 : 태블릿 안전교육 관리 화면에 사용되는 custom hook & 함수 모음
 */

// useTablet.ts
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { apiGet, apiPost } from '../services/_common';
import { themeState, userState } from '../atoms';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode';
import { useThemeLogo } from '../services/getInitialColorMode';

/**
 * useQuery를 사용하여 태블릿 안전교육 관리 데이터를 가져오는 custom hook
 * @param tNumber - 태블릿 안전교육 구분 번호
 * @returns tabletEduQuery - 태블릿 안전교육 관련 api 값을 불러오는 쿼리
 */
const useGetTabletTemplete = (tGubun: 'C' | 'J') => {
  const userInfo = useRecoilValue(userState);
  const { hCd, sCd } = userInfo;

  const QueryKey = `/tablet/templet_${tGubun}`;
  const tabletEduQuery = useQuery(
    [QueryKey, hCd, sCd, tGubun], // dependencies
    () => getTabletTemplete('/tablet/templet', { hCd, sCd, tGubun }), // fetch
    { enabled: !!hCd && !!sCd && !!tGubun } // option
  );

  return tabletEduQuery;
};

/**
 * 태블릿 안전교육 fetch & 데이터 가공 함수
 * @param path - API 경로
 * @param user - hCd, sCd, tGubun
 * @returns data - 태블릿 안전교육 data 값
 */
export const getTabletTemplete = async (path: string, user: { hCd: string; sCd: string; tGubun: 'C' | 'J' }) => {
  try {
    const res = await apiGet({ path, req: user });
    const { data } = res.data;
    const filteredData = data.filter((el: TabletTemplate) => el.delYn === 'N');
    return filteredData;
  } catch (error) {
    console.error('error', error);
    throw new Error('error');
  }
};

/**
 * 태블릿 안전교육 api post 함수
 * @param params - tGubun, tName, tContents, useYn, delYn, tNum?
 * @returns api success data
 */
const usePostTabletTemplete = () => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const { hCd, sCd, userId } = userInfo;

  const postTabletTemplete = async (params: any) => {
    const init = {
      hCd,
      sCd,
      tGubun: params.tGubun.toString(),
      tName: params.tName,
      tContents: params.tContents,
      useYn: params.useYn,
      delYn: params.delYn,
      writer: params.writer ? params.writer : userId,
      editor: userId,
      jobtypeIdx: params.jobtypeIdx,
    };

    const formData = params.tNum //
      ? { ...init, tNum: params.tNum }
      : init;

    try {
      const res = await apiPost({
        path: '/tablet/templet',
        req: {
          postTempletDto: [formData],
        },
      });
      return res.data;
    } catch (error) {
      console.error('error', error);
      throw new Error('error');
    }
  };

  return postTabletTemplete;
};

export const getSigncode = async (path: string) => {
  try {
    const res = await apiGet({ path });
    const { data } = res.data;
    const filteredData = data.filter((el: any) => el.delYn === 'N');
    return filteredData;
  } catch (error) {
    console.error('error', error);
    throw new Error('error');
  }
};

const useSigncode = () => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);

  const signcodeQuery = useQuery(
    ['/tablet/signcode'], //
    () => getSigncode('/tablet/signcode')
  );

  // 1. 삭제 버튼을 클릭하면 signIndex 유무에 따라
  // 2. setState 함수를 사용하거나 delete api를 호출하여
  // 3. tableState 상태를 업데이트하는 함수
  const deleteSigncode = async (
    rowData: Signcode & {
      signIndex: number; //
      wDate: string;
      eDate: string;
    }
  ) => {
    // signIndex가 있는 경우
    if (rowData.signIndex) {
      const { wDate, eDate, ...rest } = rowData;
      const req = {
        postSigncodeDto: [
          {
            ...rest,
            delYn: 'Y',
            editor: userInfo.userId,
          },
        ],
      };
      const res = await apiPost({
        path: '/tablet/signcode', //
        contentType: 'application/json',
        req,
      });
      const { message, statusCode } = res.data;
      if (statusCode === 200) {
        toast.success(t(message));
        signcodeQuery.refetch();
      }
    }
  };

  // 각각의 행 post api 요청 함수
  const postSigncode = async (req: any) => {
    const res = await apiPost({
      path: '/tablet/signcode', //
      contentType: 'application/json',
      req,
    });
    return res.data;
  };

  return { signcodeQuery, deleteSigncode, postSigncode };
};

/**
 * useQuery를 사용하여 태블릿 안전교육 관리 데이터를 가져오는 custom hook
 * @param tNumber - 태블릿 안전교육 구분 번호
 * @returns tabletEduQuery - 태블릿 안전교육 관련 api 값을 불러오는 쿼리
 */
const useGetTabletSettings = () => {
  const userInfo = useRecoilValue(userState);
  const { hCd, sCd, userId } = userInfo;
  const { t } = useTranslation();

  const headInfoQuery = useQuery(
    ['headInfoGet/tabletSetting', hCd, sCd],
    () => apiGet({ path: '/head/info', req: { hCd } }), //
    { retry: 3 }
  );
  const theme = useRecoilValue(themeState);
  const logo = useThemeLogo(headInfoQuery, theme);
  // 랜덤 문자열을 쿼리스트링으로 추가해서 이미지 캐싱 방지
  const randomString = Math.random().toString(36).substring(7);
  const logoUrl = `${logo}?v=${randomString}`;

  const queryKey = `/tablet/setting`;
  const tabletSettingQuery = useQuery(
    [queryKey, hCd, sCd], // dependencies
    () => getTabletSettings(queryKey, { hCd, sCd }), // fetch
    { enabled: !!hCd && !!sCd } // option
  );

  // post api 요청 함수
  const postTabletSettings = async (req: any) => {
    const res = await apiPost({
      path: '/tablet/setting', //
      contentType: 'application/json',
      req: {
        ...req, //
        hCd,
        sCd,
        time: req.time.toString(),
        recordTime: req.recordTime.toString(),
        writer: req.writer || userId,
        editor: userId,
      },
    });
    const { message, statusCode } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
    }
  };

  const isNonClickable = (originData: TabletSettings, editData: TabletSettings) => {
    if (originData === undefined || editData === undefined) {
      return false;
    }

    const propertiesToCheck: Array<keyof TabletSettings> = [
      'bloodYn',
      'eDate',
      'editor',
      'healthsafeName',
      'maxAge',
      'ocrYn',
      'personalinfoName',
      'recordTime',
      'safetydeviceYn',
      'soundYn',
      'stressYn',
      'takeMedicineYn',
      'time',
    ];
    const comparison = propertiesToCheck.every((property) => originData[property] === editData[property]);
    return comparison;
  };

  // QR 코드 생성 함수
  const generateQRCode = async (baseUrl: string, size: QrcodeSize): Promise<string> => {
    // const generateQRCode = async (baseUrl: string, hcd: string, scd: string, size: number): Promise<string> => {
    // QR 코드 생성에 필요한 URL 생성
    const url = `${baseUrl}`;
    // const url = `${baseUrl}?hcd=${hcd}&scd=${scd}`;

    const getSize = () => {
      switch (size) {
        case 'small':
          return 128;
        case 'medium':
          return 256;
        case 'large':
          return 512;
        case 'x-large':
        default:
          return 640;
      }
    };

    // QR 코드 생성 및 반환
    const qrCode = await QRCode.toDataURL(url, {
      errorCorrectionLevel: 'H',
      width: getSize(),
    });
    return qrCode;
  };

  return { tabletSettingQuery, postTabletSettings, isNonClickable, generateQRCode, logoUrl };
};

/**
 * 태블릿 안전교육 fetch & 데이터 가공 함수
 * @param path - API 경로
 * @param user - hCd, sCd
 * @returns data - 태블릿 안전교육 data 값
 */
export const getTabletSettings = async (path: string, user: { hCd: string; sCd: string }) => {
  try {
    const res = await apiGet({ path, req: user });
    const { data } = res.data;
    // const filteredData = data.filter((el: TabletTemplate) => el.delYn === 'N');
    return data;
  } catch (error) {
    console.error('error', error);
    throw new Error('error');
  }
};

/**
 * 태블릿 안전교육 api post 함수
 * @param params - tGubun, tName, tContents, useYn, delYn, tNum?
 * @returns api success data
 */
const usePostTabletTalk = () => {
  const userInfo = useRecoilValue(userState);
  const { hCd, sCd, userId } = userInfo;

  const postTabletTalk = async (params: any) => {
    const { tTitle, tContents, tBigo, useYn, delYn, tGubun, tCd, writer } = params;
    const init = {
      hCd,
      sCd,
      tGubun: tGubun.toString(),
      tTitle,
      tContents,
      tBigo,
      useYn,
      delYn,
      writer: userId,
      editor: userId,
    };

    const req = params.tCd //
      ? { ...init, tCd, writer }
      : init;

    try {
      const res = await apiPost({
        path: '/tablet/talk',
        req,
      });
      const { data } = res.data;
      return data;
    } catch (error) {
      console.error('error', error);
      throw new Error('error');
    }
  };

  return postTabletTalk;
};

const useGetTabletTalk = (tGubun: string) => {
  const userInfo = useRecoilValue(userState);
  const { hCd, sCd } = userInfo;
  const { t } = useTranslation();

  const path = '/tablet/talk';
  const queryKey = `${path}_${tGubun}`;
  const talkQuery = useQuery([queryKey, hCd, sCd, tGubun], () => fetchData(), {
    enabled:
      !!userInfo.hCd && //
      !!userInfo.sCd &&
      userInfo.sCd !== '00000' &&
      !!tGubun,
  });

  const fetchData = async () => {
    try {
      const res = await apiGet({ path, req: { hCd, sCd, tGubun } });
      const data = res.data.data.map((el: TabletTalk) => ({
        ...el, //
        useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용'),
      }));
      return data;
    } catch (error) {
      console.error('error', error);
      throw new Error('error');
    }
  };

  return talkQuery;
};

export {
  useGetTabletTemplete, //
  usePostTabletTemplete,
  useGetTabletSettings,
  useSigncode,
  usePostTabletTalk,
  useGetTabletTalk,
};
