import styled from 'styled-components';
import { findJobTypeName, flattenSubLists, useGetJobTypeLists } from '../../../hooks/useJobType';
import { userState } from '../../../atoms';
import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';

const Seperator = styled.span`
  height: 1.25rem;
  border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  margin-left: 1rem;
  &.selected {
    border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline_em};
  }
`;

const Root = styled.div<{ width: number }>`
  line-height: 3rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0 0.5rem;
  .container {
    display: block;
    max-width: ${({ width }: { width: number }) => width - 48}px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    .chip {
      user-select: none;
      display: inline-block;
      vertical-align: middle; /* 자식 요소의 y축 중앙 배치를 위해 추가 */
      margin-right: 0.25rem; /* chip 간 간격 조정 */
      padding: 0.125rem 0.5rem;
      border-radius: 0.25rem;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      font-size: 0.75rem;
      &.selected {
        color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
        background-color: ${({ theme }: { theme: any }) => theme.tonal_deep_blue};
      }
    }
  }
`;

interface Props {
  jobtypeIdx: string;
  selected: boolean;
  width: number;
}

const JobtypeOnTable = ({ jobtypeIdx, selected, width }: Props) => {
  const { prejobtypeYn } = useRecoilValue(userState);
  const { data: jobTypeLists, isLoading, isError } = useGetJobTypeLists(prejobtypeYn as 'Y' | 'N');
  const [flattenSubList, setFlattenSubList] = useState<JobtypeList[]>([]);
  const flattenList = prejobtypeYn === 'Y' ? flattenSubList : jobTypeLists;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (jobTypeLists) {
      prejobtypeYn === 'Y' && //
        setFlattenSubList(flattenSubLists(jobTypeLists));
      // setTimeout(() => {
      //   setLoading(false);
      // }, 500);
    }
  }, [jobTypeLists, prejobtypeYn]);

  if (isLoading) {
    return (
      <Root width={width}>
        <PulseLoader color='rgb(0, 122, 255)' size='6px' />
      </Root>
    );
  }

  if (jobtypeIdx === '') {
    return null;
  }

  return (
    <>
      <Seperator className={selected ? 'selected' : ''} />
      <Root width={width}>
        <div className='container'>
          {jobtypeIdx !== '' &&
            jobtypeIdx.split(',').map((selectedItem: string) => (
              <span
                key={selectedItem} //
                className={`chip ${selected ? 'selected' : ''}`}
              >
                {findJobTypeName(selectedItem, flattenList) ?? selectedItem}
              </span>
            ))}
        </div>
      </Root>
    </>
  );
};

export default JobtypeOnTable;
