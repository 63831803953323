/**
 * 작성자 : 한영광
 * 날짜 : 2023.05.24
 * 기능 : 본사 사용자관리-사용자정보
 */

import { useState, useRef, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { IComCd, IComCdList, INumbers, ITabObject } from 'customTypes';
import { pageInfoState, userState } from '../../atoms';
import Tab from '../../components/Tab';
import UserInfo from './user1/userInfo';
import UserMenu from './user1/userMenu';
import TuiGrid from '../../components/Table/TuiGrid';
import styled from 'styled-components';
import { SearchOptions } from '../../assets/styles/SearchOptions';
import Input from '../../components/Input';
import { BtnGhost } from '../../components/Button';
import { COMCD_APPROVAL_YN, COMCD_LOCK_YN, COMCD_USE_YN, ERROR, USER_GRANT } from '../../_constants';
import { setComCdListState } from '../../utils/setComCdListState';
import { logPost } from '../../services/log';
import SelectBox from '../../components/SelectBox';
import { scrollToNodeTop } from '../../utils/scrollToNodeTop';
import { useTranslation } from 'react-i18next';
import i18n from '../../translation/i18n';
import { useGetFrozenCount } from '../../utils/getFrozenCount';
import TuiGridWrapper from '../../components/Table/TuiGridWrapper';
import BackButton from '../../components/BackButton';
import { toast } from 'react-toastify';
import { apiGet } from '../../services/_common';

const Root = styled.div`
  /* width: 100%;
  border-radius: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden; */
  &.hideRoot {
    visibility: hidden;
    position: absolute;
  }
  .tui-container {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
  }
  .tableWrapper {
    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
  input[type='checkbox'] {
    margin: 0;
    width: 1rem;
    height: 1rem;
  }
  .inputFormsWrapper {
    border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    padding: 0.5rem;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    .table {
      height: fit-content;
    }
  }
  .inputFormsWrapper.smallTab {
    flex-direction: row;
    align-items: center;
    padding: 0 0.5rem;
    font-weight: 500;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 0rem !important;
    }
    .tab {
      /* width: fit-content; */
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
      cursor: pointer;
      height: 3.5rem;
      border-bottom: 2px solid transparent;
    }
    .activeTab {
      font-weight: 700;
      color: ${({ theme }: { theme: any }) => theme.selected_primary};
      border-bottom: 2px solid ${({ theme }: { theme: any }) => theme.selected_primaty};
    }
  }
  .formTitle {
    padding: 0 0.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .end {
    justify-content: end;
    > button {
      margin-left: 1rem;
    }
  }

  .thead > .tr,
  .tbody > .tr {
    > div {
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
    }
    .spaceBetween {
      justify-content: space-evenly;
    }
  }
  .grid {
    display: grid;
    margin-bottom: 1rem;
    align-items: center;
    min-height: 3.4rem;
  }

  .grid100,
  .grid100T,
  .grid60,
  .grid50,
  .grid30 {
    > div > div {
      display: grid;
      grid-template-columns: 9rem 1fr;
      align-items: center;
    }
  }

  .grid100T {
    grid-template-columns: 9rem 1fr;
  }

  .grid50,
  .grid60 {
    grid-template-columns: 1fr 1fr;
  }

  .grid50 {
    grid-gap: 1rem;
  }

  .grid30 {
    grid-template-columns: 1.5fr 0.7fr 0.8fr;
  }

  .plainText {
    grid-template-columns: 9rem 1fr;

    > :last-child {
      display: block;
    }
  }

  .marginBottomNone {
    margin-bottom: 0;
  }
`;
interface IViewTableObject {
  userId: string;
  userName: string;
  gName: string;
  lockYn: string;
  approvalYn: string;
  useYn: string;
}

const Huser1 = () => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const { userMenuList } = userInfo;
  const [initBtnClick, setInitBtnClick] = useState(false);
  const [addBtnClick, setAddBtnClick] = useState(true);
  const [columns, setColumns] = useState<any[]>([]);
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const node = useRef<any>(null);
  const [activeTabClick, setActiveTabClick] = useState(true);
  const userInfoInputFormRef = useRef<any>(null); // 인풋 폼 ref (테이블 로우를 클릭했을 때 바로 inputForm 으로 스크롤 이동시키기 위한 ref)
  // const { isBottom } = useDetectScrolledToBottom(userInfoInputFormRef); // 스크롤이 해당노드의 하단에 근접했는지 여부 (플로팅버튼 띄우기 위함)
  const [initTableState, setInitTableState] = useState<IViewTableObject[]>([]);
  const [viewTableState, setViewTableState] = useState<IViewTableObject[]>([]);
  const [viewTable, setViewTable] = useState<boolean>(true);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  const [useYnComCdList, setUseYnComCdList] = useState<IComCdList[]>([]); // 사용유무 공통코드
  const [lockYnComCdList, setLockYnComCdList] = useState<IComCdList[]>([]); // 잠금유무 공통코드
  const [useYnComCdList2, setUseYnComCdList2] = useState<IComCdList[]>([]); // 사용유무 공통코드(내부 컴포넌트 용)
  const [lockYnComCdList2, setLockYnComCdList2] = useState<IComCdList[]>([]); // 잠금유무 공통코드(내부 컴포넌트 용)
  const [userGrantComCdList, setUserGrantComCdList] = useState<IComCdList[]>([]); // 사용자 권한 공통코드
  const [approvalYnComCdList, setApprovalYnComCdList] = useState<IComCdList[]>([]); // 사용유무 공통코드
  const [orgUserList, setOrgUserList] = useState<any[]>([]);
  const [orgUserInfo, setOrgUserInfo] = useState([]);
  const [telNumState, setTelNumState] = useState<INumbers>({ num1: '', num2: '', num3: '' });
  const [hpNumState, setHpNumState] = useState<INumbers>({ num1: '010', num2: '', num3: '' });
  const [ipInfoState, setIpInfoState] = useState<INumbers>({ num1: '', num2: '', num3: '', num4: '' });
  const [searchOption, setSearchOption] = useState({
    userName: '',
  });
  const [searchOptionUseYn, setSearchOptionUseYn] = useState<any>({
    type: COMCD_USE_YN,
    [COMCD_USE_YN]: 'A',
    cdName: t('전체'),
    cdSort: 0,
  });
  const [searchOptionLockYn, setSearchOptionLockYn] = useState<any>({
    type: COMCD_LOCK_YN,
    [COMCD_LOCK_YN]: 'A',
    cdName: t('전체'),
    cdSort: 0,
  });
  const [searchOptionApprovalYn, setSearchOptionApprovalYn] = useState<any>({
    type: COMCD_APPROVAL_YN,
    [COMCD_APPROVAL_YN]: 'A',
    cdName: t('전체'),
    cdSort: 0,
  });
  const [newTabList, setNewTabList] = useState<ITabObject[]>();
  const [selectedRowKey, setSelectedRowKey] = useState<any>(null);
  const [lockYn, setLockYn] = useState<any>({ type: COMCD_LOCK_YN, [COMCD_LOCK_YN]: 'N', cdName: t('미잠금') }); // 잠금유무 공통코드
  const [useYn, setUseYn] = useState<any>({ type: COMCD_USE_YN, [COMCD_USE_YN]: 'Y', cdName: t('사용') }); // 사용유무 공통코드

  useEffect(() => {
    const arr: any[] = [];
    userMenuList.map((v: any) => {
      const result = tabInfo?.tabList?.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  useEffect(() => {
    setSearchOptionLockYn({ type: COMCD_LOCK_YN, [COMCD_LOCK_YN]: 'A', cdName: t('전체'), cdSort: 0 });
    setSearchOptionApprovalYn({ type: COMCD_APPROVAL_YN, [COMCD_APPROVAL_YN]: 'A', cdName: t('전체'), cdSort: 0 });
    setSearchOptionUseYn({ type: COMCD_USE_YN, [COMCD_USE_YN]: 'A', cdName: t('전체'), cdSort: 0 });

    setColumns([
      {
        header: t('ID'),
        name: 'userId',
        sortable: true,
        width: 100,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('사용자명'),
        name: 'userName',
        sortable: true,
        minWidth: 160,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('권한'),
        name: 'gName',
        align: 'center',
        sortable: true,
        filter: 'select',
        minWidth: 120,
      },
      {
        header: t('잠금유무'),
        name: 'lockYnCdName',
        align: 'center',
        sortable: true,
        minWidth: 80,
      },
      {
        header: t('승인여부'),
        name: 'approvalYnCdName',
        align: 'center',
        sortable: true,
        minWidth: 80,
      },
      {
        header: t('사용유무'),
        name: 'useYnCdName',
        align: 'center',
        sortable: true,
        minWidth: 80,
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    getUserHeadAPI().then((res: any) => {
      if (res.status === 200) {
        setComCdListState(COMCD_APPROVAL_YN, setApprovalYnComCdList, true);
        setComCdListState(COMCD_USE_YN, setUseYnComCdList, true);
        setComCdListState(COMCD_LOCK_YN, setLockYnComCdList, true);
        setComCdListState(COMCD_USE_YN, setUseYnComCdList2, false);
        setComCdListState(COMCD_LOCK_YN, setLockYnComCdList2, false);
        setComCdListState(USER_GRANT, setUserGrantComCdList, false, 'etc1', 'H');
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '사용자 정보 / 메뉴 관리',
          action: '조회',
          etc: ``,
        });
      }
    });
  }, []);

  useEffect(() => {
    applyFilter(initTableState);
  }, [searchOption, searchOptionUseYn[COMCD_USE_YN], searchOptionLockYn[COMCD_LOCK_YN], searchOptionApprovalYn[COMCD_APPROVAL_YN]]);

  useEffect(() => {
    if (addBtnClick) {
      setHpNumState({ num1: '010', num2: '', num3: '' });
      setTelNumState({ num1: '', num2: '', num3: '' });
      setIpInfoState({ num1: '', num2: '', num3: '', num4: '' });
    }
  }, [addBtnClick]);

  const applyFilter = (array: any[]) => {
    // 검색옵션 변경됐을 때 필터링 처리
    // 필터링 기준
    const filterOptions = {
      userName: searchOption.userName,
    };
    // 필터링된 어레이 리턴, 대소문자구분X
    const filteredArray = array.filter((item: any) => {
      return item.userName?.toLowerCase()?.includes(filterOptions.userName?.toLowerCase());
    });
    const lockFilter = YNFilter(filteredArray, 'lockYn', searchOptionLockYn[COMCD_LOCK_YN]);
    const approvalFilter = YNFilter(lockFilter, 'approvalYn', searchOptionApprovalYn[COMCD_APPROVAL_YN]);
    const result = YNFilter(approvalFilter, 'useYn', searchOptionUseYn[COMCD_USE_YN]);
    if (result.length > 0) {
      const newTableState: Array<any> = [];
      for (const v of result) {
        newTableState.push({
          userId: v.userId,
          userName: v.userName,
          gName: v.gName,
          lockYn: v.lockYn,
          approvalYn: v.approvalYn,
          useYn: v.useYn,
          lockYnCdName: v.lockYn === 'Y' ? t('잠금') : t('미잠금'),
          approvalYnCdName: v.approvalYn === 'Y' ? t('승인') : t('미승인'),
          useYnCdName: v.useYn === 'Y' ? t('사용') : t('미사용'),
        });
      }
      setViewTableState(newTableState);
    } else {
      setViewTableState([]);
    }
  };

  const YNFilter = (initialArray: IViewTableObject[], key: string, option: any) => {
    if (option === 'Y') {
      return initialArray.filter((el: any) => el[key] === 'Y');
    }
    if (option === 'N') {
      return initialArray.filter((el: any) => el[key] === 'N');
    }
    return initialArray;
  };

  // 사용자 정보 리스트 조회 API
  const getUserHeadAPI = async () => {
    const req = { hCd: userInfo.hCd };
    const res = await apiGet({ path: '/user/head', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const newTableState: Array<any> = [];
      for (const v of data.userList) {
        newTableState.push({
          userId: v.userId,
          userName: v.userName,
          gName: v.gName,
          lockYn: v.lockYn,
          approvalYn: v.approvalYn,
          pwdCheckyn: v.pwdCheckyn,
          useYn: v.useYn,
          lockYnCdName: v.lockYn === 'Y' ? t('잠금') : t('미잠금'),
          approvalYnCdName: v.approvalYn === 'Y' ? t('승인') : t('미승인'),
          useYnCdName: v.useYn === 'Y' ? t('사용') : t('미사용'),
        });
      }
      setOrgUserList(data.userList);
      setViewTableState(newTableState);
      setInitTableState(newTableState);
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  const onClickInitiateSearchOption = () => {
    setSearchOption((prev: any) => ({ ...prev, userName: '' }));
    setSearchOptionLockYn({ type: COMCD_LOCK_YN, [COMCD_LOCK_YN]: 'A', cdName: t('전체'), cdSort: 0 });
    setSearchOptionApprovalYn({ type: COMCD_APPROVAL_YN, [COMCD_APPROVAL_YN]: 'A', cdName: t('전체'), cdSort: 0 });
    setSearchOptionUseYn({ type: COMCD_USE_YN, [COMCD_USE_YN]: 'A', cdName: t('전체'), cdSort: 0 });
    getUserHeadAPI();
    setInitBtnClick(true);
    setAddBtnClick(true);
  };

  // 사용자 Row 클릭
  const onClickRow = (rowData: any) => {
    setSelectedRowKey(rowData.rowKey);
    setAddBtnClick(false);

    const user = orgUserList.find((v: any) => v.userId === rowData.userId);
    if (user) {
      setOrgUserInfo(user);

      const splitHpNumber = user.hpNum.split('-');
      setHpNumState({ num1: splitHpNumber[0] || '', num2: splitHpNumber[1] || '', num3: splitHpNumber[2] || '' });

      const splitTelNumber = user.telNum.split('-');
      setTelNumState({ num1: splitTelNumber[0] || '', num2: splitTelNumber[1] || '', num3: splitTelNumber[2] || '' });

      const splitIpNumber = user.ipInfo.split('.');
      setIpInfoState({ num1: splitIpNumber[0] || '', num2: splitIpNumber[1] || '', num3: splitIpNumber[2] || '', num4: splitIpNumber[3] || '' });
    }
    if (size.innerSize.W < 1024) {
      setViewTable(false);
    }
  };

  const onClickTab = (mrCd: string) => {
    setCurrentTabMrCd(mrCd);
  };

  const onClickSubTab = (tabName: string) => {
    if (tabName === 'info') {
      setActiveTabClick(true);
    } else {
      setActiveTabClick(false);
    }
  };
  const componentRef = useRef<HTMLDivElement>(null);
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight, componentRef.current?.offsetWidth]);
  const frozenCount = useGetFrozenCount();

  const backToMain = () => {
    setViewTable(true);
    setSelectedRowKey(null);
  };

  const addBtnAction = () => {
    setAddBtnClick(true);
    setUseYn({ type: COMCD_USE_YN, [COMCD_USE_YN]: 'Y', cdName: t('사용') });
    setLockYn({ type: COMCD_LOCK_YN, [COMCD_LOCK_YN]: 'N', cdName: t('미잠금') });
    if (size.innerSize.W < 1024) {
      setViewTable(false);
    }
  };

  const renderPage = () => {
    if (activeTabClick || addBtnClick) {
      return (
        <UserInfo
          orgUserInfo={orgUserInfo}
          setOrgUserInfo={setOrgUserInfo}
          applyFilter={applyFilter}
          orgUserList={orgUserList}
          setOrgUserList={setOrgUserList}
          getUserHeadAPI={getUserHeadAPI}
          lockYnComCdList={lockYnComCdList2}
          useYnComCdList={useYnComCdList2}
          userGrantComCdList={userGrantComCdList}
          initBtnClick={initBtnClick}
          setInitBtnClick={setInitBtnClick}
          addBtnClick={addBtnClick}
          setSelectedRowKey={setSelectedRowKey}
          hpNumState={hpNumState}
          telNumState={telNumState}
          ipInfoState={ipInfoState}
          setHpNumState={setHpNumState}
          setTelNumState={setTelNumState}
          setIpInfoState={setIpInfoState}
          useYn={useYn}
          lockYn={lockYn}
          setUseYn={setUseYn}
          setLockYn={setLockYn}
        />
      );
    }
    return <UserMenu orgUserInfo={orgUserInfo} setOrgUserInfo={setOrgUserInfo} initBtnClick={initBtnClick} setInitBtnClick={setInitBtnClick} viewTableState={viewTableState} />;
  };

  return (
    <div className='contents'>
      <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      <div className={`content-container ${size.innerSize.W >= 1024 ? 'twoColumn column-55 max800' : 'oneColumn'}`}>
        <Root className={size.innerSize.W >= 1024 || viewTable ? 'showRoot' : 'hideRoot'}>
          <SearchOptions align='left'>
            <div className='inputsWrapper'>
              <div className='inputForm-row'>
                <div className='inputForm-col withLabelComCf'>
                  <label htmlFor='lockYn'>{t('잠금유무')}</label>
                  <SelectBox
                    options={lockYnComCdList}
                    defaultOption={searchOptionLockYn.cdName}
                    state={searchOptionLockYn}
                    setState={setSearchOptionLockYn}
                    stateKey={COMCD_LOCK_YN}
                    initiateKey={searchOptionLockYn[COMCD_LOCK_YN]}
                    filterbar='filter-1-left'
                  />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col withLabelComCf'>
                  <label htmlFor='approvalYn'>{t('승인여부')}</label>
                  <SelectBox
                    options={approvalYnComCdList}
                    defaultOption={searchOptionApprovalYn.cdName}
                    state={searchOptionApprovalYn}
                    setState={setSearchOptionApprovalYn}
                    stateKey={COMCD_APPROVAL_YN}
                    initiateKey={searchOptionApprovalYn[COMCD_APPROVAL_YN]}
                    filterbar='filter-1-left'
                  />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col withLabelComCf'>
                  <label htmlFor='useYn'>{t('사용유무')}</label>
                  <SelectBox
                    options={useYnComCdList}
                    defaultOption={searchOptionUseYn.cdName}
                    state={searchOptionUseYn}
                    setState={setSearchOptionUseYn}
                    stateKey={COMCD_USE_YN}
                    initiateKey={searchOptionUseYn[COMCD_USE_YN]}
                    filterbar='filter-1-center'
                  />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <Input label='' placeholder={t('사용자명')} type='text' id='userName' name='userName' state={searchOption} setState={setSearchOption} />
                </div>
              </div>
            </div>
            <div className='inputsWrapper'>
              <div className='secondSearchOption'>
                <div className='flex-basic textBtnGroup'>
                  <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
                </div>
                <div className='flex-basic iconBtnGroup'>
                  <BtnGhost onClick={() => addBtnAction()}>
                    <span className='material-symbols-rounded'>add</span>
                    {t('등록')}
                  </BtnGhost>
                </div>
              </div>
            </div>
          </SearchOptions>
          <TuiGridWrapper componentRef={componentRef}>
            <TuiGrid data={viewTableState} columns={columns} perPage={15} onClickRow={onClickRow} rowKey={selectedRowKey} frozenCount={frozenCount} height={tuiHeight} />
          </TuiGridWrapper>
        </Root>
        {(size.innerSize.W >= 1024 || !viewTable) && (
          <Root>
            {addBtnClick ? (
              <div className='inputFormsWrapper flexRowEm'>
                {size.innerSize.W < 1024 && <BackButton func={() => backToMain()} />}
                <div className='formTitle'>{t('새로운 사용자')}</div>
              </div>
            ) : (
              <div className='inputFormsWrapper smallTab'>
                {size.innerSize.W < 1024 && <BackButton func={() => backToMain()} />}
                <div className={`tab ${activeTabClick && 'activeTab'}`} role='button' tabIndex={0} onClick={() => onClickSubTab('info')}>
                  {t('사용자 정보')}
                </div>
                <div className={`tab ${!activeTabClick && 'activeTab'} userMenu`} role='button' tabIndex={0} onClick={() => onClickSubTab('menu')}>
                  {t('사용자 메뉴 관리')}
                </div>
              </div>
            )}
            {renderPage()}
          </Root>
        )}
      </div>
    </div>
  );
};

export default Huser1;
