import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import PointIcon from './tunnel/PointIcon';

class CustomRenderer {
  element;

  constructor(props: any) {
    this.element = document.createElement('div');
    const root = ReactDOM.createRoot(this.element);
    const { rowKey } = props;
    const { tatGubun } = props.grid.store.data.filteredRawData[rowKey] || {};
    if (tatGubun?.toLowerCase() === 's') {
      root.render(
        <RecoilRoot>
          <div className='tunnelPoint start'>
            <PointIcon isStart />
            <span>시점</span>
          </div>
        </RecoilRoot>
      );
    } else if (tatGubun?.toLowerCase() === 'e') {
      root.render(
        <RecoilRoot>
          <div className='tunnelPoint end'>
            <PointIcon isStart={false} />
            <span>종점</span>
          </div>
        </RecoilRoot>
      );
    }
  }

  getElement() {
    return this.element;
  }
}
export default CustomRenderer;
