import styled from 'styled-components';
import { Tbody, Tfoot, Thead } from '../../assets/styles/TunnelTable';
import { useTranslation } from 'react-i18next';
import TunnelChart from '../TunnelChart';
import PercentageBar from '../PercentageBar';
import { EndPoint, StartPoint } from '../TunnelPoint';
import { useRecoilValue } from 'recoil';
import { tunnelReverseState } from '../../atoms';

const Root = styled.table`
  display: flex;
  flex-direction: column;
  overflow: scroll auto;
  width: 100%;
  height: fit-content;
  flex-grow: 1;
  user-select: none;
  padding: 0 0.5rem;
  overflow: hidden auto;
  tr {
    flex-grow: 1;
  }
  .tdGrow {
    flex-grow: 1;
  }
  .td8 {
    width: 8rem;
  }
  .td12 {
    width: 12rem;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-end {
    justify-content: flex-end;
  }
  .order1 {
    order: 1;
    > span.length {
      order: -1;
    }
  }
  .order2 {
    order: 2;
  }
  .order3 {
    order: 3;
  }
  &.dashboard {
    justify-content: space-between;
    overflow: auto;
    tbody {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      margin-bottom: 0.5rem;
      min-width: 40rem;
      tr {
        display: flex;
        align-items: center;
      }
    }
  }
`;

type Props = {
  data: TareaData[];
  header?: boolean;
  dashboard?: boolean;
};

const TunnelButterFlyChart = ({ data: propsData, header: headerStatus = true, dashboard = false }: Props) => {
  const isTunnelReversed = useRecoilValue(tunnelReverseState);

  const chartData = propsData.map((elements: TareaData) => {
    const { eLength, eRLength, sLength, sRLength, tatName, useYn, eUseYn, sUseYn } = elements;
    return { eLength, eRLength, sLength, sRLength, tatName, useYn, eUseYn, sUseYn };
  });
  const { t } = useTranslation();

  const pointOption = () => {
    const startPoint = chartData.filter((data) => data.sUseYn === 'Y').length !== 0;
    const endPoint = chartData.filter((data) => data.eUseYn === 'Y').length !== 0;
    return { startPoint, endPoint };
  };

  return (
    <Root className='dashboard'>
      {headerStatus && (
        <Thead className='borderNone'>
          <tr>
            <th className='td8 tableTitle'>{t('구역 명')}</th>
            {pointOption().startPoint && (
              <th className={`td12 tdGrow ${pointOption().startPoint && pointOption().endPoint ? 'order1' : ''}`}>
                <StartPoint />
              </th>
            )}
            {pointOption().endPoint && (
              <th className={`td12 tdGrow ${pointOption().startPoint && pointOption().endPoint ? 'order3' : ''}`}>
                <EndPoint />
              </th>
            )}
          </tr>
        </Thead>
      )}
      <Tbody>
        {chartData.map((data: TunnelChartData, idx: number) => (
          <TunnelChart
            data={data}
            key={data.tatName}
            startPoint={pointOption().startPoint}
            endPoint={pointOption().endPoint}
            orderChange={pointOption().startPoint && pointOption().endPoint}
            timeOut={idx}
            isTunnelReversed={isTunnelReversed}
          />
        ))}
      </Tbody>
      <Tfoot>
        <tr>
          <td className='td8 total'>
            {t('총합')}
            <span>{chartData.length}</span>
          </td>
          {/* 시점 진행률 */}
          {pointOption().startPoint && <PercentageBar pointOption={pointOption().startPoint && pointOption().endPoint} reverse={!isTunnelReversed} />}
          {/* 종점 진행률 */}
          {pointOption().endPoint && <PercentageBar pointOption={pointOption().startPoint && pointOption().endPoint} reverse={isTunnelReversed} />}
        </tr>
      </Tfoot>
    </Root>
  );
};

export default TunnelButterFlyChart;
