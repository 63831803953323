/**
 * 작성자 : 홍선영
 * 날짜 : 2023.03.31
 * 기능 : 유저정보 필수입력값(이메일, 패스워드, 휴대폰번호) 빈값여부 확인하여
 *        통과여부(boolean), 알럿메시지문구를 리턴하는 함수
 */

import { ID, ID_DUPLICATE, NAME } from '../_constants';

interface IUserInput {
  isDuplicateChecked: boolean;
  userId: string;
  userName: string;
}

export const checkUserInputRequiredValue = (userInput: IUserInput) => {
  const { userId, isDuplicateChecked, userName } = userInput;
  const trimmedUserId = userId?.trim();
  const trimmedUserName = userName?.trim();

  let isUserInputRequiredValuePass: boolean = false;
  let userInputRequiredValueMsg: string = '';

  if (trimmedUserId === '') {
    userInputRequiredValueMsg = ID;
    return { isUserInputRequiredValuePass, userInputRequiredValueMsg, info: 'userId' };
  }

  if (!isDuplicateChecked) {
    userInputRequiredValueMsg = ID_DUPLICATE;
    return { isUserInputRequiredValuePass, userInputRequiredValueMsg };
  }

  if (trimmedUserName === '') {
    userInputRequiredValueMsg = NAME;
    return { isUserInputRequiredValuePass, userInputRequiredValueMsg, info: 'userName' };
  }

  isUserInputRequiredValuePass = true;
  return { isUserInputRequiredValuePass, userInputRequiredValueMsg };
};
