import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Root = styled.div`
  position: relative;
  height: 2rem;
  width: fit-content;
  .options {
    z-index: 10;
    display: flex;
    background-color: ${({ theme }: { theme: any }) => theme.color.zinc_800};
    border-radius: 0.25rem;
    position: absolute;
    width: fit-content;
    flex-shrink: 0;
    top: 0;
    &.boxShadow {
      box-shadow: 0 0.25rem 0.75rem 0.5rem rgba(0, 0, 0, 0.75);
    }
    &.toRight {
      left: 0;
    }
    &.toLeft {
      right: 0;
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

type Option = { key: string; button: React.ReactNode; value: string };

type Props = {
  defaultOption?: React.ReactNode;
  options: Option[];
  state?: Option;
  setState?: Dispatch<SetStateAction<Option>>;
  disabled?: boolean;
  direction?: 'toLeft' | 'toRight';
  onClick?: (value: any) => void;
  boxShadow?: boolean;
};

const ClickExpandableContainer = ({ options, defaultOption, state, setState, disabled = false, direction = 'toRight', onClick, boxShadow = true }: Props) => {
  const [expandOption, setExpandOption] = useState(false);
  const defaultButton = defaultOption || state?.button;
  const expandRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (expandRef.current && !expandRef.current.contains(event.target as Node)) {
      setExpandOption(false);
    }
  };

  // option 클릭 이벤트
  const handleOptionsClick = (option: Option) => {
    setExpandOption(false);
    if (onClick) onClick(option.value);
    if (setState) setState(option);
  };

  // default 상태 클릭 이벤트
  const handleDefaultClick = () => {
    !expandOption && setExpandOption(true);
  };

  // options 렌더링
  const renderOptions = () => {
    return options.map((option) => {
      return (
        <div key={option.key} role='button' tabIndex={0} onClick={() => handleOptionsClick(option)}>
          {option.button}
        </div>
      );
    });
  };

  return (
    <Root
      className={disabled ? 'disabled' : ''} //
      onMouseLeave={() => setExpandOption(false)}
      onClick={handleDefaultClick}
    >
      {defaultButton}
      {expandOption && (
        <div className={`options ${direction} ${boxShadow ? 'boxShadow' : ''}`} ref={expandRef}>
          {renderOptions()}
        </div>
      )}
    </Root>
  );
};

export default ClickExpandableContainer;
