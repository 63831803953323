/**
 * 작성자 : 한영광
 * 날짜 : 2023.06.22
 * 경로 : 근로자 관리 - 근로자 현장 출입금지자 관리
 */
import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { IoChevronUpSharp } from 'react-icons/io5';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from 'styled-components';

import i18n from '../../translation/i18n';
import { IComCdList, ITabObject } from 'customTypes';
import { IUser, pageInfoState, userState } from '../../atoms';
import { BLACK_YN, LIST_COUNT } from '../../_constants';
import { SearchOptions } from '../../assets/styles/SearchOptions';
import { FloatingButtonToTop } from '../../assets/styles/FloatingButtonToTop';
import { ContentsContainerRoot } from '../../assets/styles/ContentsContainerRoot';
import { LoadingModalBackground } from '../../assets/styles/Modal';
import { BtnGhost } from '../../components/Button';
import Tab from '../../components/Tab';
import Input from '../../components/Input';
import Portal from '../../components/Portal';
import TuiGrid from '../../components/Table/TuiGrid';
import SelectBox from '../../components/SelectBox';
import TuiGridWrapper from '../../components/Table/TuiGridWrapper';
import SearchSelectBoxSm from '../../components/SearchSelectBoxSm';
import InfoBlackWorkerModal from '../../components/Modal/InfoBlackWorkerModal';
import EnrollmentBlackWorkerModal from '../../components/Modal/EnrollmentBlackWorkerModal';
import RangePicker, { onChangeRangeInput } from '../../components/RangePicker';
import { useSetAuth } from '../../utils/useSetAuth';
import { useSetSjcd } from '../../utils/useSetSjcd';
import { trimObject } from '../../utils/trimObject';
import { todayYYYYMMDD } from '../../utils/formatDate';
import { scrollToNodeTop } from '../../utils/scrollToNodeTop';
import { useGetFrozenCount } from '../../utils/getFrozenCount';
import { setComCdListState } from '../../utils/setComCdListState';
import { useDetectScrolledToBottom } from '../../utils/useDetectScrolledToBottom';
import useOnKeydownF9 from '../../utils/useOnKeydownF9';
import useSetListCount from '../../utils/useSetListCount';
import { logPost } from '../../services/log';
import { apiGet } from '../../services/_common';
import ShortcutButton from '../../components/button/ShortcutButton';

const Root = styled(ContentsContainerRoot)``;

const Sworker11 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { siteJoinInfoList } = useSetSjcd();
  const { auth, userMenuGrant } = useSetAuth(); // 사용자 권한값 훅
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const { listCount, setListCount, patchUserMenuAPI } = useSetListCount(); // 검색줄수설정 훅
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  const toDay = todayYYYYMMDD();
  const [userInfo, setUserInfo] = useRecoilState<IUser>(userState);
  const { userMenuList, eExcelYn, eListYn, ePrintYn } = userInfo;
  const [tableState, setTableState] = useState<any[]>([]);
  const [orgTableState, setOrgTableState] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const wNameRef = useRef<HTMLInputElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const [visibleRangePicker, setVisibleRangePicker] = useState(false);
  const [rangeState, setRangeState] = useState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
  const [listCountComCdList, setListCountComCdList] = useState<IComCdList[]>([]); // 검색줄수 공통코드
  const [searchOption, setSearchOption] = useState({ wName: '', start: toDay, end: toDay });
  const [sjCd, setSjCd] = useState({ type: 'sjCd', sjCd: '', cdName: '' });
  const [blackYn, setBlackYn] = useState({ type: 'blackYn', blackYn: 'Y', cdName: t('적용') });
  const [excelBtn, setExcelBtn] = useState(false);
  const { isBottom } = useDetectScrolledToBottom(tableRef);
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '', code: { wCd: '', bSeq: '' } });
  const [newTabList, setNewTabList] = useState<ITabObject[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const arr: any[] = [];
    userMenuList.map((v: any) => {
      const result = tabInfo?.tabList?.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  useEffect(() => {
    setLoading(true);
    getWorkerAPI().then((res: any) => {
      if (res.status === 200) {
        setComCdListState(LIST_COUNT, setListCountComCdList, false);
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '현장 출입금지자 관리',
          action: '조회',
          etc: ``,
        });
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    setColumns(
      userInfo.prejobtypeYn === 'Y'
        ? [
            {
              header: t('근로자명'),
              name: 'wName',
              sortable: true,
              width: 150,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('협력업체'),
              name: 'sjName',
              sortable: true,
              filter: 'select',
              width: 200,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('공종'),
              name: 'wPrejobtype',
              sortable: true,
              filter: 'select',
              minWidth: 120,
            },
            {
              header: t('직종'),
              name: 'wJobtype',
              sortable: true,
              filter: 'select',
              minWidth: 120,
            },
            {
              header: t('적발일자'),
              name: 'bDate',
              align: 'center',
              sortable: true,
              minWidth: 120,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('취업일자'),
              name: 'wJobdate',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('출입금지 사유'),
              name: 'bMemo',
              sortable: true,
              minWidth: 240,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('적용유무'),
              name: 'blackYn',
              align: 'center',
              sortable: true,
              minWidth: 100,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('등록일자'),
              name: 'wDate',
              align: 'center',
              sortable: true,
              minWidth: 160,
            },
            {
              header: t('등록자'),
              name: 'writer',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('수정일자'),
              name: 'eDate',
              align: 'center',
              sortable: true,
              minWidth: 160,
            },
            {
              header: t('수정자'),
              name: 'editor',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
          ]
        : [
            {
              header: t('근로자명'),
              name: 'wName',
              sortable: true,
              width: 150,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('협력업체'),
              name: 'sjName',
              sortable: true,
              width: 200,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('직종'),
              name: 'wJobtype',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('적발일자'),
              name: 'bDate',
              align: 'center',
              sortable: true,
              minWidth: 120,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('취업일자'),
              name: 'wJobdate',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('출입금지 사유'),
              name: 'bMemo',
              sortable: true,
              minWidth: 240,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('적용유무'),
              name: 'blackYn',
              align: 'center',
              sortable: true,
              minWidth: 100,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('등록일자'),
              name: 'wDate',
              align: 'center',
              sortable: true,
              minWidth: 160,
            },
            {
              header: t('등록자'),
              name: 'writer',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('수정일자'),
              name: 'eDate',
              align: 'center',
              sortable: true,
              minWidth: 160,
            },
            {
              header: t('수정자'),
              name: 'editor',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
          ]
    );
  }, [i18n.language]);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    if (!rangeState[0].startDate) {
      return;
    }
    setSearchOption((prev) => ({ ...prev, start: dayjs(rangeState[0].startDate).format('YYYY-MM-DD') }));
  }, [rangeState[0].startDate]);

  useEffect(() => {
    if (!rangeState[0].endDate) {
      return;
    }
    setSearchOption((prev) => ({ ...prev, end: dayjs(rangeState[0].endDate).format('YYYY-MM-DD') }));
  }, [rangeState[0].endDate]);

  useEffect(() => {
    setTableState(
      orgTableState.map((v: any) => {
        return { ...v, blackYn: t(v.blackYn) };
      })
    );
  }, [i18n.language]);

  // 근로자 정보 리스트 조회 API
  const getWorkerAPI = async () => {
    const newSearchOption = {
      ...searchOption,
      sjCd: sjCd.sjCd,
      bDate1: searchOption.start.replaceAll('-', ''),
      bDate2: searchOption.end.replaceAll('-', ''),
      blackYn: blackYn.blackYn === 'A' ? '' : blackYn.blackYn,
    };
    const { start, end, ...finalSearchObj } = newSearchOption; // searchOption에서 start, end값 삭제
    const trimData = trimObject(finalSearchObj);
    const req = { ...trimData, hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/worker/black', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      setLoading(false);
      setOrgTableState(data.blackList);
      setTableState(
        data.blackList.map((v: any) => {
          return { ...v, blackYn: t(v.blackYn) };
        })
      );
    } else {
      setLoading(false);
      // toast.error(t(ERROR));
    }
    return res;
  };

  // 검색 버튼 클릭
  const onClickSearch = () => {
    if (!openModal.status) {
      if (searchOption.end && searchOption.start > searchOption.end) {
        toast.warning(t('날짜를 다시 입력하세요'));
        setSearchOption((prev: any) => ({ ...prev, end: '' }));
      } else {
        setLoading(true);
        setVisibleRangePicker(false);
        navigate({
          pathname: location.pathname,
          search: '',
        });
        getWorkerAPI();
      }
    }
  };

  const initiateSearchOptions = () => {
    setBlackYn({ type: 'blackYn', blackYn: 'Y', cdName: t('적용') });
    setSjCd({ type: 'sjCd', sjCd: '', cdName: '' });
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    Object.keys(searchOption).map((el: any) => {
      return setSearchOption((prev) => ({ ...prev, [el]: '', start: '', end: '', blackYn: 'Y' }));
    });
    initiateSearchOptions();
    setRangeState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
  };

  // 신규 등록 버튼 클릭
  const onClickNewWorker = () => {
    setOpenModal({ status: true, type: 'enrollmentBlackWorker' });
  };

  // 엑셀 저장 버튼 클릭
  const onClickExcelSave = () => {
    if (tableState.length <= 0) return;
    setExcelBtn(true);
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '현장 출입금지자 관리',
      action: '엑셀 저장',
      etc: ``,
    });
  };

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  // 인쇄 버튼 클릭
  const onClickPrint = () => {
    handlePrint();
  };

  // 근로자 Row 클릭
  const onClickRow = (rowData: any) => {
    setOpenModal({ status: true, type: 'infoBlackWorker', code: { bSeq: rowData.bSeq, wCd: rowData.wCd, writer: rowData.writer } });
  };

  const renderNewWorkerBtn = () => {
    if (auth.createAuth) {
      return (
        <BtnGhost onClick={onClickNewWorker}>
          <span className='material-symbols-rounded'>add</span> {t('신규')}
        </BtnGhost>
      );
    }
    return null;
  };

  const renderExcelBtn = () => {
    if (auth.excelAuth) {
      return (
        <BtnGhost onClick={onClickExcelSave}>
          <span className='icon-ms-xlsx'>X</span> {t('엑셀')}
        </BtnGhost>
      );
    }
    return null;
  };

  const renderPrintBtn = () => {
    if (userInfo.gCd === 'MM' || userMenuGrant.pYn === 'Y') {
      return (
        <BtnGhost onClick={onClickPrint}>
          <span className='material-symbols-rounded'>print</span>
          {t('인쇄')}
        </BtnGhost>
      );
    }
    return null;
  };

  const onClickRangeInput = () => {
    if (!visibleRangePicker) setVisibleRangePicker(true);
  };

  const onClickTab = (tab: string) => {
    setCurrentTabMrCd(tab);
  };

  // 근로자명 입력창에서 엔터입력시 검색
  const loginOnEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (wNameRef.current) onClickSearch();
    }
  };
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight, componentRef.current?.offsetWidth]);

  const frozenCount = useGetFrozenCount();

  return (
    <div className='contents'>
      <div className='content-container oneColumn'>
        <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
        <Root loading={loading}>
          <SearchOptions>
            {isBottom && (
              <FloatingButtonToTop>
                <button type='button' onClick={() => scrollToNodeTop(tableRef)}>
                  <IoChevronUpSharp size={20} style={{ stroke: 'white' }} />
                </button>
              </FloatingButtonToTop>
            )}
            <div className='inputsWrapper'>
              <div className='inputForm-col withLabelComCf'>
                <label htmlFor='wWorkstatus'>{t('현장 출입금지자')}</label>
                <SelectBox options={BLACK_YN} defaultOption={t('적용')} state={blackYn} setState={setBlackYn} stateKey='blackYn' initiateKey={blackYn.blackYn} filterbar='filter-1-left' />
              </div>
              <div className='inputForm-row withLabel'>
                <label htmlFor='sjCd'>{t('협력업체')}</label>
                <div className='inputForm-col'>
                  <SearchSelectBoxSm
                    options={siteJoinInfoList}
                    defaultOption={t('전체')}
                    state={sjCd}
                    setState={setSjCd}
                    stateKey='sjCd'
                    codeKey='cdName'
                    initiateKey={sjCd.sjCd}
                    filterbar='filter-1-left'
                    optionHeight='height-md'
                  />
                </div>
              </div>
              <div className='inputForm-row-fit'>
                <span className='calendarLabel'>{t('적발일자')}</span>
                <div className='inputForm-col'>
                  <div className='flex-basic'>
                    <input id='startInput' type='text' value={searchOption.start} onMouseDown={onClickRangeInput} onChange={(e) => onChangeRangeInput(e, 'start', setRangeState, setSearchOption)} />
                    <span className='inputDash'> ~ </span>
                    <input id='endInput' type='text' value={searchOption.end} onMouseDown={onClickRangeInput} onChange={(e) => onChangeRangeInput(e, 'end', setRangeState, setSearchOption)} />
                  </div>
                  {visibleRangePicker && (
                    <div className='rangePickerWrapper'>
                      <RangePicker state={rangeState} setState={setRangeState} setVisible={setVisibleRangePicker} />
                    </div>
                  )}
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <Input placeholder={t('근로자명')} label='' type='text' id='wName' name='wName' state={searchOption} setState={setSearchOption} inputRef={wNameRef} onKeyDown={loginOnEnterKeyDown} />
                </div>
              </div>
            </div>
            <div className='inputsWrapper'>
              <div className='secondSearchOption'>
                <div className='flex-basic textBtnGroup'>
                  <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
                  {/* <BtnGhost onClick={onClickSearch} className='searchBtn'>
                    {t('검색')}
                    <span className='shortcut-f9'>F9</span>
                  </BtnGhost> */}
                  <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
                  <div className='searchResult'>
                    {t('총')}
                    <span>{tableState.length}</span>
                    {t('개')}
                  </div>
                  <div className='inputForm-row'>
                    <div className='inputForm-col withLabelComCf'>
                      <label htmlFor='useYn'>{t('보기 설정')}</label>
                      <SelectBox
                        options={listCountComCdList}
                        defaultOption={listCount[LIST_COUNT]}
                        state={listCount}
                        setState={setListCount}
                        stateKey={LIST_COUNT}
                        initiateKey={listCount[LIST_COUNT]}
                        setTableLinesAPI={patchUserMenuAPI}
                        optionHeight='height-sm'
                        rsearch
                      />
                    </div>
                  </div>
                </div>
                <div className='flex-basic iconBtnGroup'>
                  {renderNewWorkerBtn()}
                  {renderExcelBtn()}
                  {/* {renderPrintBtn()} */}
                </div>
              </div>
            </div>
          </SearchOptions>
          <TuiGridWrapper componentRef={componentRef}>
            <TuiGrid
              data={tableState}
              columns={columns}
              perPage={Number(listCount[LIST_COUNT])}
              excelBtn={excelBtn}
              setExcelBtn={setExcelBtn}
              usePagenation
              onClickRow={onClickRow}
              // height={560}
              // visibleTotalCount
              scrollX
              height={tuiHeight}
              eListYn={eListYn}
              eExcelYn={eExcelYn}
              frozenCount={frozenCount}
            />
          </TuiGridWrapper>
        </Root>
      </div>
      {loading && (
        <LoadingModalBackground>
          <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
        </LoadingModalBackground>
      )}
      <Portal openModal={openModal?.status}>
        {openModal.status && openModal.type === 'infoBlackWorker' && openModal.code && (
          <InfoBlackWorkerModal
            getReportListAPI={getWorkerAPI}
            setOpenModal={setOpenModal}
            auth={auth}
            code={{ hCd: userInfo.hCd, sCd: userInfo.sCd, wCd: openModal.code.wCd, bSeq: openModal.code.bSeq, writer: openModal.code.writer }}
          />
        )}
        {openModal.status && openModal.type === 'enrollmentBlackWorker' && (
          <EnrollmentBlackWorkerModal getReportListAPI={getWorkerAPI} setOpenModal={setOpenModal} auth={auth} code={{ hCd: userInfo.hCd, sCd: userInfo.sCd }} />
        )}
      </Portal>
    </div>
  );
};

export default Sworker11;
