import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(weekday);

/**
 *
 *
 * @param week
 * @param before 몇주 전인지 (e.g. 1주전, 2주전)
 * @returns
 */

export const getWeekStartAndEnd = (week: any, before: number) => {
  const startOfLastWeek = dayjs(week).subtract(before, 'week').startOf('week').add(0, 'day'); // Assuming Monday is the first day of the week
  const endOfLastWeek = dayjs(week).subtract(before, 'week').endOf('week').add(0, 'day');

  return {
    startDate: startOfLastWeek.format('YYYY-MM-DD'),
    endDate: endOfLastWeek.format('YYYY-MM-DD'),
  };
};

/**
 * 지정된 월 및 연도의 시작 날짜와 종료 날짜를 가져옴
 *
 * @param year
 * @param month
 * @returns 시작 날짜와 종료 날짜를 포함하는 객체
 */
export const getMonthStartAndEndDates = (year: number, month: number): { startDate: string; endDate: string } => {
  const startDate = dayjs().year(year).month(month).startOf('month').format('YYYY-MM-DD');
  const endDate = dayjs().year(year).month(month).endOf('month').format('YYYY-MM-DD');

  return { startDate, endDate };
};
