import { useTranslation } from 'react-i18next';

import { BtnGhost } from '../Button';
import { ModalBackground, Modal } from '../../assets/styles/Modal';
import { Dispatch, SetStateAction } from 'react';

interface IProps {
  openModal: { status: boolean; msg: string };
  setOpenModal: Dispatch<SetStateAction<{ status: boolean; msg: string }>>;
}

const WebSdkAlertModal = ({ openModal, setOpenModal }: IProps) => {
  const { t } = useTranslation();
  // 닫기버튼 클릭
  const onClickClose = () => {
    setOpenModal((prev: { status: boolean; msg: string }) => ({ ...prev, status: false }));
  };

  return (
    <ModalBackground onClick={onClickClose} role='presentation'>
      <Modal>
        <div
          className='modal delete'
          role='presentation'
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className='inputForm-head'>{t(openModal.msg)}</div>
          <div className='inputForm-body'>
            <div className='modal-footer flex-end'>
              <BtnGhost onClick={onClickClose}>{t('닫기')}</BtnGhost>
            </div>
          </div>
        </div>
      </Modal>
    </ModalBackground>
  );
};

export default WebSdkAlertModal;
