import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { CctvLeftSideMenuStyle } from '../../../../assets/styles/CctvLeftSideMenuStyle';
import ExpandButton from '../../../../components/button/ExpandButton';
import ExpandableMenu from '../../../../components/cctv/ExpandableMenu';
import { WebSDKcctv } from '../../../../utils/webSDKcctvClass';
import RecordCalendar from './RecordCalendar';
import SelectBox from '../../../../components/SelectBox';
import { streamSelectBoxOptions } from '../../../../_constants';
import OpenFolderButtons from '../RealtimeCCTV/OpenFolderButtons';
import { initPath } from '../../../../services/webSDK';
import { formatDateToStr, formatDateYMD, getFirstDayOfMonth, getLastDayOfMonth } from '../../../../utils/formatDate';

interface ILeftSideProps {
  draggingCamInfo: ISubMenu[] | null;
  setDraggingCamInfo: Dispatch<SetStateAction<ISubMenu[] | null>>;
  visiblePanel: boolean;
  nvrList: IMainMenu[];
  setNvrList: Dispatch<SetStateAction<IMainMenu[]>>;
  isLoading: boolean;
  isError: boolean;
  setIsDragging: Dispatch<SetStateAction<boolean>>;
  cctvOBJ: WebSDKcctv | undefined;
  selectedCctvCameraInfo: SelectedCctvCameraInfo | null;
  cctvDivisions: number;
  cctvSettings: CctvSettings;
  setDayRecordFiles: Dispatch<SetStateAction<Playback[]>>;
  selectedStream: Stream;
  setSelectedStream: Dispatch<SetStateAction<Stream>>;
}

const LeftSideMenu = ({
  visiblePanel,
  draggingCamInfo,
  setDraggingCamInfo,
  nvrList,
  setNvrList,
  isLoading,
  isError,
  setIsDragging,
  cctvOBJ,
  selectedCctvCameraInfo,
  cctvDivisions,
  cctvSettings,
  setDayRecordFiles,
  selectedStream,
  setSelectedStream,
}: ILeftSideProps) => {
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '', data: null, setDataFunction: null });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [expandAll, setExpandAll] = useState<boolean>(true); // 전체 확장/축소 토글상태값
  const [monthRecords, setMonthRecords] = useState<Records | null>(null); // 캘린더에 녹화파일 존재여부 표기하기 위한 상태값
  const [selectedDate, setSelectedDate] = useState(formatDateToStr(new Date())); // 캘린더에서 선택한 날짜

  const downloadFolders = [
    { title: 'capture', path: initPath(cctvSettings.camCPath), name: '캡쳐' },
    { title: 'video', path: initPath(cctvSettings.camMPath), name: '동영상' },
  ];

  // 다운로드 폴더 열기
  const handleOpenFolder = (path: null | string) => {
    if (path && path !== 'undefined') {
      return cctvOBJ?.openDirectory(path);
    }
    return cctvOBJ?.openDirectory();
  };

  // 전체 확장/축소 기능을 토글하는 함수
  const expandAllMenu = () => {
    setExpandAll((prev) => !prev);
  };

  // 전체 확장 상태가 변경될 때 NVR 리스트 업데이트
  useEffect(() => {
    setNvrList(nvrList.map((el: IMainMenu) => ({ ...el, expand: expandAll })));
  }, [expandAll]);

  const getFormattedMonthRange = (year: number, month: number): [string, string] => {
    return [`${getFirstDayOfMonth(year, month)} 00:00:00`, `${getLastDayOfMonth(year, month)} 23:59:59`];
  };

  const getFormattedDay = (date: string): [string, string] => {
    return [`${formatDateYMD(date)} 00:00:00`, `${formatDateYMD(date)} 23:59:59`];
  };

  // 선택된 카메라/스트림/일자의 녹화 파일을 검색하는 비동기 함수
  const fetchRecords = async (camInfo: any, stream: number, startDate: string, endDate: string) => {
    const recordSearchRes: any = await cctvOBJ?.recordSearch(camInfo, stream, startDate, endDate);
    return recordSearchRes;
  };

  // 일자별 파일존재여부를 업데이트하는 함수
  const updateMonthRecords = (recordSearchRes: Playback[]) => {
    const result: Records = {};
    recordSearchRes.forEach((file: Playback) => {
      const dateKey = file.szStartTime.split(' ')[0].replace(/-/g, '');
      result[dateKey] = true;
    });
    return result;
  };

  // 메뉴에서 카메라를 선택했을 때, 월/일단위 녹화파일 검색하는 함수
  const performSearch = async () => {
    if (!draggingCamInfo || draggingCamInfo.length === 0) {
      // toast(<LeftedToast message='카메라를 선택하세요' />, { position: 'top-left', autoClose: 3000 });
      return;
    }

    if (!selectedDate) return;

    const year = Number(selectedDate.slice(0, 4));
    const month = Number(selectedDate.slice(4, 6));
    const [monthStart, monthEnd] = getFormattedMonthRange(year, month);

    // 해당 월의 모든 녹화파일 검색
    const allRecords = await fetchAllRecords(draggingCamInfo[0], selectedStream.stream, monthStart, monthEnd);

    // 녹화파일 검색 (월), red dot 표시 위함
    if (allRecords) {
      setMonthRecords(updateMonthRecords(allRecords));

      const [dayStart, dayEnd] = getFormattedDay(selectedDate);
      const dayRecordsRes = await fetchRecords(draggingCamInfo[0], selectedStream.stream, dayStart, dayEnd);
      // 녹화파일 검색 (일), 녹화파일을 재생하기 위함
      if (dayRecordsRes) {
        setDayRecordFiles(dayRecordsRes);
      }
    }
  };

  // 해당 월의 모든 녹화 파일을 비동기적으로 가져오는 함수
  // WebSDK 플러그인의 fetchRecords 메서드 리턴값이 최대 50개 이므로, 다음검색결과값을 받아오기 위해 fetchRecords 반복수행
  const fetchAllRecords = async (camInfo: any, stream: number, startTime: string, endTime: string, accumulatedRecords: Playback[] = []): Promise<Playback[]> => {
    const records = await fetchRecords(camInfo, stream, startTime, endTime);

    // 기존에 누적된 결과값에 새로운 결과값을 추가하여 새로운 배열을 생성
    const newAccumulatedRecords = [...accumulatedRecords, ...records];
    // 레코드의 수가 50개 이상인 경우 추가 검색 수행
    if (records.length >= 50) {
      // 마지막 레코드의 종료 시간을 다음 검색의 시작 시간으로 사용
      const nextStartTime = records[49].szEndTime;
      // 마지막 녹화파일의 시작시간부터 추가검색
      return fetchAllRecords(camInfo, stream, nextStartTime, endTime, newAccumulatedRecords);
    }
    // 결과값이 50개 미만인 경우, 최종 결과 반환
    return newAccumulatedRecords;
  };

  useEffect(() => {
    cctvOBJ && performSearch();
  }, [draggingCamInfo, selectedDate, selectedStream.stream]);

  return (
    <CctvLeftSideMenuStyle className={visiblePanel ? 'visible' : 'hidden'} onMouseUp={() => setIsDragging(false)}>
      <div className='list'>
        <div className='bookmarkWrapper'>
          <ExpandButton isExpanded={expandAll} onClickExpandButton={expandAllMenu} />
        </div>
        <div className='menuWrapper'>
          {nvrList.map((el: IMainMenu) => (
            <ExpandableMenu
              key={el.nCd}
              mainMenu={el}
              nvrList={nvrList}
              setNvrList={setNvrList}
              setDraggingCamInfo={setDraggingCamInfo}
              cctvOBJ={cctvOBJ}
              setOpenModal={setOpenModal}
              setIsDragging={setIsDragging}
              cctvDivisions={cctvDivisions}
              currentPage={currentPage}
              selectedCctvCameraInfo={selectedCctvCameraInfo}
              draggingCamInfo={draggingCamInfo}
              type='playback'
              cctvSettings={cctvSettings}
            />
          ))}
        </div>
      </div>
      <RecordCalendar date={selectedDate} setDate={setSelectedDate} records={monthRecords} />
      <div className='selectStream'>
        <SelectBox options={streamSelectBoxOptions} defaultOption={selectedStream.cdName} state={selectedStream} setState={setSelectedStream} stateKey='stream' />
        {/* <BtnGraySmall onClick={performSearch}>검색</BtnGraySmall> */}
      </div>
      <div className='openFolder'>
        <OpenFolderButtons //
          label='다운로드 폴더 열기'
          icon='folder_open'
          options={downloadFolders}
          onClick={handleOpenFolder}
        />
      </div>
    </CctvLeftSideMenuStyle>
  );
};

export default LeftSideMenu;
