/**
 * 작성자 : 김광민
 * 날짜 : 2023.12.28
 * 경로 : 터널관리 - 구역 현황 관리 > 종합진행현황
 */

import { useTranslation } from 'react-i18next';
import { SearchOptions } from '../assets/styles/SearchOptions';
import TunnelChipButton from './TunnelChipButton';
import { BtnGhost } from './Button';
import { Dispatch, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import SelectBox from './SelectBox';

const TunnelNameButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0 0.25rem;
  background-color: ${({ theme }: { theme: any }) => theme.tonal};
  border-radius: 0.25rem;
  height: 2.5rem;
  border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  button {
    font-weight: 500;
    width: fit-content;
    height: 2rem !important;
    padding: 0 0.5rem !important;
    background-color: ${({ theme }: { theme: any }) => theme.board} !important;
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    font-size: 0.75rem;
    border: none;
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light} !important;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    }
    &:active {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep} !important;
    }
    &.selected {
      font-weight: 400;
      background-color: ${({ theme }: { theme: any }) => theme.text_tertiary} !important;
      background-color: ${({ theme }: { theme: any }) => theme.filled_blue} !important;
      color: ${({ theme }: { theme: any }) => theme.board};
    }
  }
`;

type Props = {
  filter: TunnelFilterData[];
  setFilter: Dispatch<SetStateAction<TunnelFilterData[]>>;
  total?: number;
  viewTunnelType?: TunnelViewType[];
  viewType?: TunnelViewType;
  setViewType: Dispatch<SetStateAction<TunnelViewType>>;
  tunnelList: TunnelType[];
  selectedTatCd: string;
  setSelectedTatCd: Dispatch<SetStateAction<string>>;
  initialChips: TunnelFilterData[];
};

const TunnelTrackFilter = ({ filter, setFilter, total = 0, viewTunnelType, viewType, setViewType, tunnelList, selectedTatCd, setSelectedTatCd, initialChips }: Props) => {
  const { t } = useTranslation();

  // 초기화 버튼 클릭 이벤트
  const onClickInitial = () => {
    setFilter(initialChips);
  };

  // 보기 설정 변경시 필터 초기화
  useEffect(() => {
    setFilter(initialChips);
  }, [viewType?.viewStatus]);

  const renderSearchResult = () => {
    if (viewType?.viewStatus === 'S') {
      return null;
    }
    return (
      <div className='searchResult'>
        {t('총')}
        <span>{total}</span>
        {t('개')}
      </div>
    );
  };

  const renderDetailTunnelTab = () => {
    if (viewType?.viewStatus !== 'S') {
      return null;
    }
    return (
      <TunnelNameButtons>
        {tunnelList.map((tunnel: TunnelType, index: number) => (
          <button type='button' onClick={() => setSelectedTatCd(tunnel.tatCd)} key={index} className={`${tunnel.tatCd === selectedTatCd ? 'selected' : ''}`}>
            {tunnel.tatName}
          </button>
        ))}
      </TunnelNameButtons>
    );
  };

  return (
    <SearchOptions>
      <div className='inputsWrapper'>
        <div className='inputForm-row withLabel'>
          <label htmlFor='wWorkstatus'>{t('보기 설정')}</label>
          <div className='inputForm-col'>
            <SelectBox
              options={viewTunnelType}
              defaultOption={viewType?.cdName ?? t('전체')}
              state={viewType}
              setState={setViewType}
              stateKey='viewStatus'
              initiateKey={viewType?.viewStatus}
              filterbar='filter-1-left'
            />
          </div>
        </div>
        {filter.map((button: TunnelFilterData, idx: number) => (
          <TunnelChipButton key={idx} chipData={button} setFilter={setFilter} defaultOption={idx === 0} />
        ))}
      </div>
      <div className='inputsWrapper'>
        <div className='secondSearchOption'>
          <div className='flex-basic textBtnGroup'>
            <BtnGhost onClick={() => onClickInitial()}>{t('초기화')}</BtnGhost>
            {renderSearchResult()}
            {renderDetailTunnelTab()}
          </div>
        </div>
      </div>
    </SearchOptions>
  );
};

export default TunnelTrackFilter;
