import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { IModal } from 'customTypes';
import { userState } from '../../../atoms';
import { COMCD_USE_YN, INIT_USE_YN_A } from '../../../_constants';
import { InputTable } from '../../../assets/styles/InputTable';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { BtnBlue, InitBtn } from '../../../components/Button';
import Portal from '../../../components/Portal';
import SelectBox from '../../../components/SelectBox';
import DeleteModal from '../../../components/Modal/DeleteModal2';
import SearchSelectBoxSm from '../../../components/SearchSelectBoxSm';
import RangePicker, { onChangeRangeInput } from '../../../components/RangePicker';
import { useFetchCommonCodeList } from '../../../services/useSetCodeListInSelectBoxForm';
import { apiGet, apiPost } from '../../../services/_common';
import { useSetAuth } from '../../../utils/useSetAuth';
import { useSetSjcd } from '../../../utils/useSetSjcd';
import { formatDate, todayYYYYMMDD } from '../../../utils/formatDate';
import useOnKeydownF9 from '../../../utils/useOnKeydownF9';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import { PulseLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import IssueGuide from '../../../components/IssueGuide';
import { logPost } from '../../../services/log';
import ScheduleSettingTableRow from './ScheduleSetting/ScheduleSettingTableRow';

const Root = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .margin-left-05 {
    overflow: auto;
  }

  .jobtypeBox {
    height: 18rem;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    overflow-y: auto;

    border: none;
    font-size: 1rem;
    width: 100%;
    padding: 0.75rem;
    color: #18181b;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    border-radius: 0.25rem;
  }

  .tbody > .tr > div {
    height: 3.5rem;
  }

  .scroll {
    overflow: auto;
    > button {
      width: fit-content;
      margin: 0.2rem;
      padding: 0.25rem;
    }
  }

  &.hideRoot {
    visibility: hidden;
    position: absolute;
  }
  > .inputFormsWrapper {
    height: 3.5rem !important;
    flex-shrink: 0;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    padding: 0 1rem;
    border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  }
  .arrow-icon span {
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
  .icon-btn {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    gap: 0.25rem;
    cursor: pointer;
    border-radius: 5rem;
    padding-right: 1rem;
    padding-left: 0.5rem;
    font-size: 0.875rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .icon-btn:hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
  }
`;

const SubRoot = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  overflow: auto;
  justify-content: flex-start;

  .emptyData {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    padding-bottom: 4rem;
    gap: 1rem;
    img {
      width: 24rem;
      -webkit-user-drag: none;
    }
    span {
      color: ${({ theme }: { theme: any }) => theme.filled_violet};
    }
  }

  .inputForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    width: 100%;
    button {
      height: 2.5rem;
      font-size: 0.75rem;
      flex-shrink: 0;
      padding: 0 0.75rem;
      border-radius: 0.25rem;
    }
    button.gray {
      font-weight: 500;
      color: ${({ theme }: { theme: any }) => theme.color.zinc_200};
    }
    label {
      width: 6rem;
      font-weight: 500;
      flex-shrink: 0;
      font-size: 0.875rem;
    }
    .inputForm-group-1280 {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (min-width: 1280px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
      }
    }
    .inputForm-group-1536 {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (min-width: 1536px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
      }
    }
    .inputForm-group-1536.withBtn {
      flex-direction: row;
      gap: 0.5rem;
      button {
        transition: none;
      }
      @media (min-width: 1536px) {
        gap: 2rem;
        button {
          margin-left: 0;
          width: fit-content;
        }
      }
    }
    .inputForm-group-1536.withBtn.inputColumnFour {
      input {
        padding: 0;
      }
    }
    .inputForm-row.labelInInput {
      display: flex;
      gap: 0.5rem;
      flex-grow: 1;
      > div {
        flex-grow: 1;
      }
    }
    .inputForm-row.labelOutInput,
    .inputForm-row.labelInInput > div > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      input {
        flex-shrink: 1;
        height: 2.5rem;
        font-size: 0.875rem;
        padding: 0 0.5rem;
        &:disabled {
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          color: ${({ theme }: { theme: any }) => theme.text_disabled};
        }
      }
      textarea {
        width: 100%;
      }
      > div.viewOnly {
        height: 2.5rem;
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_primary};
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 0.25rem;
        padding: 0 0.75rem;
        display: flex;
        align-items: center;
      }
      > div {
        flex-grow: 1;
        > div > div > ul {
          height: 2.5rem;
          li {
            display: flex;
            width: 100%;
            max-width: 100%;
            span {
              flex-grow: 1;
              width: 4rem;
            }
          }
        }
      }
    }
    .detailInfo-group {
      margin: 1rem 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      @media (min-width: 1536px) {
        grid-template-columns: repeat(3, 1fr);
      }
      .flex-col.detailInfo {
        flex-grow: 1;
        width: 100%;
        div {
          height: 2.5rem;
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          border-radius: 0.25rem;
          display: flex;
          align-items: center;
          padding: 0 0.5rem;
          font-size: 0.875rem;
        }
      }
    }

    ul > li {
      width: 100% !important;
      max-width: 100% !important;
    }
  }

  input {
    :disabled {
      background-color: #eeeeee;
    }
  }
  .required {
    &::after {
      content: '*';
      padding: 0 0.2rem;
      color: red;
    }
  }

  @media screen and (max-width: 1023px) {
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: fit-content;
    overflow: none;
  }
`;

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  padding: 0.5rem;
  gap: 0.5rem;
  justify-content: flex-end;

  > div {
    gap: 0.5rem;
  }

  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;

interface IEduSchedule {
  sjsIdx: number | null;
  hCd: string;
  sCd: string;
  sjsDate: string;
  sjCd: string;
  sjName: string;
  jobtypeIdx: string | null;
  useYn: string;
  writer: string;
  wDate: string;
  editor: string;
  eDate: string;
  jobtype: Jobtype[];
  useYnCdName: string;
}

interface Jobtype {
  key: string;
  name: string;
}

interface ISiteJoin {
  sjsIdx: number | null;
  sjCd: string;
  sjName: string;
  jobtypeIdx: string;
  useYn: string;
  sjsDate: string;
  writer?: string;
}

const ScheduleSetting = () => {
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const { t } = useTranslation();
  const { prejobtypeYn } = useRecoilValue(userState);

  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const { siteJoinInfoList } = useSetSjcd();
  const INIT_ROW_STATE = {
    sjsIdx: null,
    sjsDate: '',
    sjCd: '',
    sjName: t('미선택'),
    jobtypeIdx: '',
    useYn: 'Y',
    copy: false,
  };
  const { data: useYnComCdListWithAll } = useFetchCommonCodeList(COMCD_USE_YN, true); // 사용여부 공통코드 목록 (전체포함)
  const toDay = todayYYYYMMDD();
  const [visibleRangePicker, setVisibleRangePicker] = useState(false);

  const userInfo = useRecoilValue(userState);
  const { hCd, sCd, userId } = userInfo;

  const queryKey = 'sjsafeedudate';
  const dependencies = [queryKey, hCd, sCd];
  const queryOptions = {
    enabled: !!userInfo.hCd && !!sCd && sCd !== '00000',
  };

  const safeEduScheduleQuery = useQuery(dependencies, () => fetchData(), queryOptions);
  const { data: originData, isLoading, isError, refetch } = safeEduScheduleQuery;
  const [tableState, setTableState] = useState<ISiteJoin[]>([]);
  const [newTableState, setNewTableState] = useState<ISiteJoin[]>([]);

  const initState = () => {
    if (originData) {
      const data = filteredData([...originData]);
      setTableState(data);
      setIsSaveClicked(false);
    }
  };

  useEffect(() => {
    initState();
  }, [originData]);

  const initSjCd = { type: 'sjCd', sjCd: '', cdName: t('전체') };
  const [sjCdFilter, setSjCdFilter] = useState(initSjCd);
  const [useYnFilter, setUseYnFilter] = useState(INIT_USE_YN_A);
  const [dateRangeFilter, setDateRangeFilter] = useState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
  const [dateRange, setDateRange] = useState({ start: toDay, end: toDay });
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장1버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });

  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    try {
      const path = '/sitejoin/sjsafeedudate';
      const req = {
        hCd,
        sCd,
      };
      const res = await apiGet({ path, req });
      const result = res.data.data;
      const promises = result?.map(async (el: ISiteJoin) => (el.jobtypeIdx === null ? { ...el, jobtypeIdx: '' } : el));
      const promisedResult = await Promise.all(promises);
      return promisedResult;
    } catch (error) {
      console.error('error', error);
      throw new Error('error');
    }
  };

  useEffect(() => {
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '안전교육 일정관리 > 협력업체 안전교육 일정 관리',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    if (originData) {
      const handler = setTimeout(() => {
        const data = filteredData(originData);
        setTableState([...data]);
      }, 250);
      return () => {
        clearTimeout(handler);
      };
    }
    return undefined;
  }, [dateRange, sjCdFilter, useYnFilter]);

  useEffect(() => {
    if (isF9Pressed) {
      refetch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    if (dateRangeFilter[0].startDate) {
      setDateRange((prev) => ({ ...prev, start: dayjs(dateRangeFilter[0].startDate).format('YYYY-MM-DD') }));
    }
    if (dateRangeFilter[0].endDate) {
      setDateRange((prev) => ({ ...prev, end: dayjs(dateRangeFilter[0].endDate).format('YYYY-MM-DD') }));
    }
  }, [dateRangeFilter[0].startDate, dateRangeFilter[0].endDate]);

  const filteredData = (data: ISiteJoin[]) => {
    if (dateRange.start === '' || dateRange.end === '') {
      // toast.warning(t('날짜를 선택하세요'));
    } else if (dateRange.end && dateRange.start > dateRange.end) {
      toast.warning(t('날짜를 다시 입력하세요'));
      setDateRange((prev) => ({ ...prev, end: '' }));
    } else {
      const result = data?.filter((el: ISiteJoin) => {
        const filterSjCd = sjCdFilter.sjCd === '' ? true : el.sjCd === sjCdFilter.sjCd;
        const filterUseYn = useYnFilter.USE_YN === 'A' ? true : el.useYn === useYnFilter.USE_YN;
        const filterStartDate = formatDate(el.sjsDate) >= dateRange.start;
        const filterEndDate = formatDate(el.sjsDate) <= dateRange.end;
        return filterSjCd && filterUseYn && filterStartDate && filterEndDate;
      });
      if (result) {
        return result;
      }
    }
    return [];
  };

  const onClickRangeInput = () => {
    if (!visibleRangePicker) setVisibleRangePicker(true);
  };

  const onClickInitiateSearchOption = () => {
    setLoading(true);
    initState();
    setSjCdFilter(initSjCd);
    setUseYnFilter(INIT_USE_YN_A);
    setIsSaveClicked(false);
    setNewTableState([]);
    setDateRangeFilter([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
    setTimeout(() => {
      setLoading(false);
    }, 250);
  };

  const onClickDeleteRow = async (row: IEduSchedule) => {
    const { sjCd, sjsIdx, sjsDate, jobtypeIdx, writer } = row;
    if (sjsIdx === null) return null;
    const req = {
      hCd,
      sCd,
      sjsIdx,
      sjsDate: sjsDate.split('-').join(''),
      sjCd,
      jobtypeIdx,
      useYn: 'N',
      delYn: 'Y',
      writer,
      editor: userId,
    };
    const res = await postSafeeduScheduleAPI(req, true);
    if (res.statusCode === 200) {
      refetch();
      toast.success(t('삭제 성공'));
    }
    return null;
  };

  const onClickSave = async () => {
    setIsSaveClicked(true); // 모든 저장 로직이 시작되기 전에 한 번만 설정
    let response = [];

    if (newTableState.length > 0) {
      const warning = newTableState.find((rowState) => {
        const { sjsDate, sjCd, jobtypeIdx } = rowState;
        return !sjsDate || !sjCd || jobtypeIdx === '';
      });

      if (warning) {
        toast.warning(t('필수 입력값을 모두 입력하세요')); // 유효성 검사 실패시 경고 표시
        return;
      }
    }

    if (newTableState.length > 0) {
      const promises = newTableState.map((rowState) => {
        const { sjsIdx, sjsDate, sjCd, jobtypeIdx, useYn } = rowState;

        if (!sjsDate || !sjCd || jobtypeIdx === '') {
          return Promise.resolve(null); // 유효성 검사에 실패한 경우 null 반환
        }

        const req = {
          hCd,
          sCd,
          sjsIdx,
          sjsDate: sjsDate.split('-').join(''),
          sjCd,
          jobtypeIdx,
          useYn,
          delYn: 'N',
          writer: userId,
          editor: userId,
        };

        return postSafeeduScheduleAPI(req, false); // API 호출 프로미스 반환
      });

      try {
        const results = await Promise.all(promises);
        if (results.filter((row) => row !== null).length === 0) {
          return;
        }
        response.push(...results.filter((row) => row !== null));
        setNewTableState([]);
      } catch (error) {
        console.error('저장 중 오류 발생:', error);
      }
    }

    const origin = await filteredData(originData ?? []);
    if (origin !== null && origin.length > 0) {
      const promises = tableState.map((rowState, index) => {
        const { sjsIdx, sjsDate, sjCd, jobtypeIdx, useYn, writer } = rowState;
        if (
          rowState.sjsDate.split('-').join('') === origin[index].sjsDate && //
          rowState.sjCd === origin[index].sjCd &&
          rowState.jobtypeIdx === origin[index].jobtypeIdx &&
          rowState.useYn === origin[index].useYn
        ) {
          return Promise.resolve(null);
        }

        if (!sjsDate || !sjCd || jobtypeIdx === '') {
          toast.warning(t('필수 입력값을 모두 입력하세요')); // 유효성 검사 실패시 경고 표시
          return Promise.resolve(null); // 유효성 검사에 실패한 경우 null 반환
        }

        const req = {
          hCd,
          sCd,
          sjsIdx,
          sjsDate: sjsDate.split('-').join(''),
          sjCd,
          jobtypeIdx,
          useYn,
          delYn: 'N',
          writer,
          editor: userId,
        };

        return postSafeeduScheduleAPI(req, false); // API 호출 프로미스 반환
      });

      try {
        const results = await Promise.all(promises);
        response.push(...results.filter((row) => row !== null));
      } catch (error) {
        console.error('저장 중 오류 발생:', error);
      }
    }
    // response 중복 제거
    const messages = response.map((res) => res.message);
    const uniqueMessages = Array.from(new Set(messages));
    // 중복 제거된 메시지를 토스트로 표시
    uniqueMessages.forEach((message) => {
      toast.success(t(message));
    });
    await refetch();
  };

  const postSafeeduScheduleAPI = async (req: any, isDelete: boolean) => {
    const res = await apiPost({ path: '/sitejoin/sjsafeedudate', req });
    try {
      return res.data;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  const onClickDelete = (row: IEduSchedule) => {
    if (row.sjsIdx !== null) {
      setOpenModal((prev) => ({ ...prev, status: true, type: 'delete', api: () => onClickDeleteRow(row) }));
    } else {
      setNewTableState((prev) => {
        const updatedArray = prev.filter((item) => item !== row);
        return updatedArray;
      });
    }
  };

  const onClickNewAdd = () => {
    setIsSaveClicked(false);
    setNewTableState((prev) => [...prev, { ...INIT_ROW_STATE }]);
  };

  if (isLoading || loading)
    return (
      <LoadingModalBackground>
        <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
      </LoadingModalBackground>
    );

  if (isError) return <IssueGuide />;

  return (
    <div className='content-container oneColumn'>
      <Root>
        <SearchOptions align='left'>
          <div className='inputsWrapper'>
            <div className='inputForm-row withLabel'>
              <label htmlFor='sjCd'>{t('협력업체')}</label>
              <div className='inputForm-col'>
                <SearchSelectBoxSm
                  options={siteJoinInfoList}
                  defaultOption={t('전체')}
                  state={sjCdFilter}
                  setState={setSjCdFilter}
                  stateKey='sjCd'
                  codeKey='cdName'
                  initiateKey={sjCdFilter.sjCd}
                  filterbar='filter-1-left'
                  optionHeight='height-md'
                  dropDownWidth='expand-content-md'
                />
              </div>
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col withLabelComCf'>
                <label htmlFor='useYn'>{t('사용유무')}</label>
                <SelectBox
                  options={useYnComCdListWithAll}
                  defaultOption={useYnFilter.cdName}
                  state={useYnFilter}
                  setState={setUseYnFilter}
                  stateKey={COMCD_USE_YN}
                  initiateKey={useYnFilter[COMCD_USE_YN]}
                  filterbar='filter-1-left'
                />
              </div>
            </div>
            <div className='inputForm-row-fit'>
              <span className='calendarLabel'>{t('출역일자')}</span>
              <div className='inputForm-col'>
                <div className='flex-basic'>
                  <input id='startInput' type='text' value={dateRange.start} onMouseDown={onClickRangeInput} onChange={(e) => onChangeRangeInput(e, 'start', setDateRangeFilter, setDateRange)} />
                  <span className='inputDash'> ~ </span>
                  <input id='endInput' type='text' value={dateRange.end} onMouseDown={onClickRangeInput} onChange={(e) => onChangeRangeInput(e, 'end', setDateRangeFilter, setDateRange)} />
                </div>
                {visibleRangePicker && (
                  <div className='rangePickerWrapper'>
                    <RangePicker state={dateRangeFilter} setState={setDateRangeFilter} setVisible={setVisibleRangePicker} />
                  </div>
                )}
              </div>
            </div>
            <InitBtn onClick={onClickInitiateSearchOption}>{t('초기화')}</InitBtn>
            {/* <div className='searchResult'>
              {t('총')}
              <span>{tableState?.length || 0}</span>
              {t('개')}
            </div> */}
          </div>
        </SearchOptions>
        <InputTable className='margin-left-05'>
          <div className='thead'>
            <div className='tr'>
              <div className='trCol2p5 flex-center tableStickyNo'>No</div>
              <div className='trCol12p5 flex-center'>{t('협력업체 명')}</div>
              <div className='trCol7 flex-center'>{t('교육일자')}</div>
              <div className='trCol6 flex-center'>{t('사용유무')}</div>
              <div className='trCol20 flex-center content-overflow'>{userInfo.prejobtypeYn === 'Y' ? t('선택 공종+직종') : t('선택 직종')}</div>
            </div>
          </div>
          <div className='table'>
            <div className='tbody'>
              {newTableState
                ?.map((el: any, i: number) => (
                  <ScheduleSettingTableRow //
                    key={i}
                    rowData={el}
                    rowIndex={i}
                    setTableState={setNewTableState}
                    onClickDeleteRow={() => onClickDelete(el)}
                    isSaveClicked={isSaveClicked}
                    prejobtypeYn={prejobtypeYn as 'Y' | 'N'}
                  />
                ))
                .reverse()}
              {tableState?.map((el: any, i: number) => (
                <ScheduleSettingTableRow //
                  key={el.sjsIdx}
                  rowData={el}
                  rowIndex={i}
                  setTableState={setTableState}
                  onClickDeleteRow={() => onClickDelete(el)}
                  originalData={filteredData(originData)}
                  isSaveClicked={isSaveClicked}
                  prejobtypeYn={prejobtypeYn as 'Y' | 'N'}
                  setNewTableState={setNewTableState}
                />
              ))}
            </div>
          </div>
        </InputTable>
        <ButtonsWrapper>
          {auth.createAuth && <BtnBlue onClick={onClickNewAdd}>{t('신규항목 추가')}</BtnBlue>}
          {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={onClickSave}>{t('저장')}</BtnBlue>}
        </ButtonsWrapper>
      </Root>
      <Portal openModal={openModal?.status}>
        {openModal && //
          openModal.type === 'delete' && (
            <DeleteModal
              openModal={openModal} //
              setOpenModal={setOpenModal}
            />
          )}
      </Portal>
    </div>
  );
};

export default ScheduleSetting;
