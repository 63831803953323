import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PointIcon from './PointIcon';

// TunnelPoint 스타일드 컴포넌트 정의
const TunnelPoint = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: fit-content;
  margin: 0 auto;
  padding: 0 0.5rem;
  border-radius: 1rem;
  .material-symbols-rounded {
    font-size: 1.25rem;
    padding: 0.125rem;
    &.start {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
      color: ${({ theme }: { theme: any }) => theme.filled_blue};
      border-radius: 0.5rem;
    }
    &.end {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
      color: ${({ theme }: { theme: any }) => theme.filled_amber};
      border-radius: 0.5rem;
    }
  }
  .pointName {
    font-size: 0.75rem;
    word-break: keep-all;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
  }
`;

// 시점 컴포넌트 정의
export const StartPoint = () => {
  const { t } = useTranslation();

  return (
    <TunnelPoint>
      <PointIcon isStart classNameOfStyle='start' />
      <span className='pointName'>{t('시점')}</span>
    </TunnelPoint>
  );
};

// 종점 컴포넌트 정의
export const EndPoint = () => {
  const { t } = useTranslation();

  return (
    <TunnelPoint>
      <PointIcon isStart={false} classNameOfStyle='end' />
      <span className='pointName'>{t('종점')}</span>
    </TunnelPoint>
  );
};
