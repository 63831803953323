/**
 * 작성자 : 김광민
 * 날짜 : 2024.02.13
 * 기능 : 터널 관리 > 방송 장비 정보
 */

import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
  color: ${({ theme }: { theme: any }) => theme.filled_blue};
  padding: 0.5rem;
  border-radius: 2rem;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  .material-symbols-rounded {
    font-size: 1.5rem;
    font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
  }
  &:hover {
    background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
    color: ${({ theme }: { theme: any }) => theme.tonal_blue};
  }
`;

type Props = {
  onClick: () => void;
};

const BroadcastButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Root role='button' tabIndex={0} onClick={onClick}>
      <span className='material-symbols-rounded'>podcasts</span>
    </Root>
  );
};

export default React.memo(BroadcastButton);
