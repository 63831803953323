/**
 * 작성자 : 김광민
 * 날짜 : 2024.02.15
 * 기능 : 발생된 이슈와 관련된 일러스트 및 메시지를 함께 출력하는 컴포넌트
 */

import styled from 'styled-components';

import defaultIllustrator from '../assets/images/illustration/304.svg';
import { useTranslation } from 'react-i18next';

const Root = styled.div`
  display: flex;
  flex-grow: 1 !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  user-select: none;
  background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
  img {
    width: 12rem;
    object-fit: cover;
    vertical-align: bottom;
    -webkit-user-drag: none;
    @media (min-width: 768px) {
      width: 14rem;
    }
    @media (min-width: 1024px) {
      width: 16rem;
    }
  }
  p {
    padding: 0.5rem 0.75rem;
    color: ${({ theme }: { theme: any }) => theme.filled_violet};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_violet};
    border-radius: 0.25rem;
    font-size: 0.875rem;
    letter-spacing: 0;
    font-weight: 400;
    word-break: keep-all;
    text-align: center;
  }
`;

type Props = {
  text?: string;
  illustrator?: { visible: boolean; src?: string };
};

const IssueGuide = ({ text = '데이터가 존재하지 않습니다', illustrator = { visible: true, src: defaultIllustrator } }: Props) => {
  const { t } = useTranslation();
  const { visible, src } = illustrator;

  return (
    <Root>
      {visible ? <img src={src} alt='' /> : null}
      <p>{`${t(text)}`}</p>
    </Root>
  );
};

export default IssueGuide;
