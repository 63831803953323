/**
 * 작성자 : 김광민
 * 날짜 : 2024.02.13
 * 기능 : 터널 관리 > 방송 장비 정보
 */

import styled from 'styled-components';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import EquipListItem from '../tunnel/EquipListItem';
import CheckBoxCircle from '../button/CheckBoxCircle';
import TunnelPointHorizontalLabel from '../TunnelPointHorisontalLabel';

const Root = styled.div`
  width: calc(50% - 0.25rem);
  flex-grow: 1;
  height: 100%;
  background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.25rem;
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      .count {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      }
    }
    &:active {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
      .count {
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
      }
    }
    .checkbox {
      width: 4rem;
      flex-shrink: 0;
      flex-grow: 0;
    }
    .count {
      margin-left: 1.625rem;
      margin-right: 0.375rem;
      font-size: 0.875rem;
      padding: 0 0.5rem;
      min-width: 2rem;
      height: 1.75rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      border-radius: 0.25rem;
    }
  }
  .tareaLists {
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }: { theme: any }) => theme.board};
    border-radius: 0.25rem;
    box-shadow: 0 4px 12px 0px rgba(0, 0, 0, 0.05);
    &:hover {
      box-shadow: 0 4px 12px 0px rgba(0, 0, 0, 0.1);
    }
    > div:nth-child(n + 2) {
      border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    }
  }
`;

type Props = {
  isStartPoint?: boolean;
  onClickRow: (el: any) => void;
  handleCheckboxChangeAll: (setAllCheckBoxsS: any, allCheckBoxsS: any, tableStateS: any) => void;
  handleCheckboxChange: (i: number, el: any, tableStateS: any, setTableStateS: any, allCheckBoxsS: any, setAllCheckBoxsS: any) => void;
  equipList: EquipList[];
  setEquipList: Dispatch<SetStateAction<EquipList[]>>;
  isCheckAll: boolean;
  setIsCheckAll: Dispatch<SetStateAction<boolean>>;
};

const BroadCastPoint = ({ isStartPoint = false, onClickRow, handleCheckboxChangeAll, handleCheckboxChange, equipList, setEquipList, isCheckAll, setIsCheckAll }: Props) => {
  const { t } = useTranslation();

  const handleClick = () => {
    handleCheckboxChangeAll(setIsCheckAll, isCheckAll, equipList);
  };

  if (false) {
    return <div className='noData'>No data.</div>;
  }

  return (
    <Root>
      <div className='header' onClick={handleClick} role='button' tabIndex={0}>
        <div className='checkbox'>
          <CheckBoxCircle checked={isCheckAll} onClick={handleClick} />
        </div>
        <TunnelPointHorizontalLabel text='상세구역' isStartPoint={isStartPoint} />
        <span className='count'>{equipList.length}</span>
      </div>
      {equipList?.length > 0 && (
        <div className='tareaLists'>
          {equipList?.map((item: any, index: number) => (
            <EquipListItem
              key={v4()}
              item={item}
              index={index}
              onClickRow={onClickRow}
              handleCheckboxChange={handleCheckboxChange}
              equipList={equipList}
              setEquipList={setEquipList}
              isCheckAll={isCheckAll}
              setIsCheckAll={setIsCheckAll}
            />
          ))}
        </div>
      )}
    </Root>
  );
};

export default BroadCastPoint;
