/**
 * 작성자 : 홍선영
 * 날짜 : 2023.07.12
 * 기능 : aws 파일경로값(string)을 받아서 포매팅하는 함수
 */

// 파일이 이미지인지 아닌지 판별하는 함수. return boolean
export const isImageFile = (fileUrl: string) => {
  if (fileUrl) {
    // 이미지 확장자 배열
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
    // 파일 익스텐션 가져오기
    const ext = fileUrl.split('.').pop()?.toLowerCase();
    // 이미지 확장자인지 확인
    return imageExtensions.includes(ext || '');
  }
  return false;
};

// 경로 url에서 파일명(확장자포함) 리턴하는 함수
export const getFileNameFromURL = (fileUrl: string) => {
  if (fileUrl) {
    const lastSlashIndex = fileUrl.lastIndexOf('/');
    return fileUrl.substring(lastSlashIndex + 1);
  }
  return '';
};
