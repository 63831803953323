/**
 * 작성자 : 한영광
 * 날짜 : 2023.11.08
 * 기능 : 사용자 리스트 모달
 */

import { useEffect, useState, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import { IWorker } from 'customTypes';
import { userState } from '../../atoms';
import { Modal, ModalBackground } from '../../assets/styles/Modal';
import Input from '../Input';
import TuiGrid from '../Table/TuiGrid';
import i18n from '../../translation/i18n';
import { BtnGhost } from '../Button';

interface IProps {
  state: IWorker[];
  getUserMenuAPI: (userId: string) => void;
  onClickClose: () => void;
}

const UserListModal = ({ state, onClickClose, getUserMenuAPI }: IProps) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const userNameRef = useRef<HTMLInputElement>(null);
  const componentRef = useRef<HTMLDivElement>(null);
  const [columns, setColumns] = useState<any[]>([]);
  const [searchOption, setSearchOption] = useState({ userName: '', userId: '' });
  const [userList, setUserList] = useState(state);
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);

  useEffect(() => {
    userNameRef.current?.focus();
  }, []);

  useEffect(() => {
    setColumns(
      userInfo.prejobtypeYn === 'Y'
        ? [
            {
              header: t('이름'),
              name: 'userName',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('사용자 ID'),
              name: 'userId',
              align: 'center',
              sortable: true,
              width: 100,
            },
            {
              header: t('권한'),
              name: 'gName',
              sortable: true,
              width: 200,
            },
          ]
        : [
            {
              header: t('이름'),
              name: 'userName',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('사용자 ID'),
              name: 'userId',
              align: 'center',
              sortable: true,
              width: 100,
            },
            {
              header: t('권한'),
              name: 'gName',
              sortable: true,
              width: 200,
            },
          ]
    );
  }, [userInfo.prejobtypeYn, userList, i18n.language]);

  useEffect(() => {
    setUserList(
      state
        .filter((v: any) => {
          return v.userName?.toLowerCase()?.includes(searchOption.userName?.toLowerCase());
        })
        .filter((v: any) => {
          return v.userId?.toLowerCase()?.includes(searchOption.userId?.toLowerCase());
        })
    );
  }, [searchOption]);

  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight, componentRef.current?.offsetWidth]);

  // 사용자 Row 클릭
  const onClickRow = (rowData: any) => {
    getUserMenuAPI(rowData.userId);
    onClickClose();
  };
  const onClickInitiateSearchOption = () => {
    setSearchOption({ userName: '', userId: '' });
    userNameRef.current?.focus();
  };

  return (
    <ModalBackground>
      <Modal>
        <div className='modal'>
          <div className='inputForm-head'>
            <div className='modalHeaderCenter'>{t('사용자 검색')}</div>
            {onClickClose && (
              <div className='closeBtn' onClick={onClickClose} role='presentation'>
                <span className='material-symbols-rounded'>close</span>
              </div>
            )}
          </div>
          <div className='filter-option-flex'>
            <div className='modal-element'>
              <div className='inputWithBtnRow'>
                <Input className='col-w40' type='text' placeholder={t('이름')} name='userName' state={searchOption} setState={setSearchOption} inputRef={userNameRef} />
                <Input className='col-w40' type='text' placeholder={t('사용자 ID')} name='userId' state={searchOption} setState={setSearchOption} />
                <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
              </div>
            </div>
          </div>
          <div className='tuiGridWrapper tui-container' ref={componentRef}>
            <TuiGrid data={userList} columns={columns} perPage={15} usePagenation onClickRow={onClickRow} frozenCount={1} height={tuiHeight} scrollX visiblePages={5} rowClickCheckBox modal />
          </div>
        </div>
      </Modal>
    </ModalBackground>
  );
};

export default UserListModal;
