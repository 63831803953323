import { IModal } from 'customTypes';
import { BtnBlue, BtnWhite } from '../Button';
import { ModalBackground, Modal } from '../../assets/styles/Modal';
import { SketchPicker } from 'react-color';
import { useState } from 'react';
import { FLAG_CREATE_OR_UPDATE } from '../../_constants';
import { useRecoilState } from 'recoil';
import { IUser, userState } from '../../atoms';
import { useTranslation } from 'react-i18next';

const ColorPickerModal = ({ openModal, setOpenModal }: any) => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useRecoilState<IUser>(userState);
  const [selectColor, setSelectColor] = useState(openModal.color || '#000');

  // 닫기버튼 클릭
  const onClickClose = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
    openModal.setColorState(openModal.color);
  };

  const onClickConfirm = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
    if (openModal.type === 'object') {
      const newObj = { ...openModal.el, sbdFontcolor: selectColor, flag: FLAG_CREATE_OR_UPDATE, editor: userInfo.userId };
      openModal.state[openModal.index] = newObj;
    }
    if (openModal.type === 'array') {
      // 색상 일괄적용
      const copyArray = [...openModal.state];
      const newArray = copyArray.map((el: any) => (el.checkedSbd ? { ...el, sbdFontcolor: selectColor, flag: FLAG_CREATE_OR_UPDATE, editor: userInfo.userId, checkedSbd: false } : { ...el }));
      openModal.setState(newArray);
    }
  };

  const handleChangeColor = (colorParam: any) => {
    setSelectColor(colorParam.hex);
    openModal.setColorState(colorParam.hex);
  };

  return (
    <ModalBackground>
      <Modal>
        <div className='modal' style={{ minWidth: '0', height: 'fit-content', width: 'fit-content' }}>
          <div className='inputForm-head'>
            <h5>{t('폰트 색상을 선택하세요')}</h5>
          </div>
          <div className='inputForm-body'>
            <div className='modal' style={{ minWidth: '0', height: 'fit-content', width: 'fit-content' }}>
              {openModal && openModal.title === 'colorPicker' && <SketchPicker color={selectColor} onChange={handleChangeColor} />}
            </div>
            <div className='modal-footer flex-end'>
              <BtnBlue onClick={onClickConfirm}>{t('확인')}</BtnBlue>
              <BtnWhite onClick={onClickClose}>{t('닫기')}</BtnWhite>
            </div>
          </div>
        </div>
      </Modal>
    </ModalBackground>
  );
};

export default ColorPickerModal;
