import styled from 'styled-components';

interface SelectBoxStyleProps {
  isDropdownOpen: boolean;
}

export const SelectBoxSmDropStyle = styled.div<SelectBoxStyleProps>`
  &.spread li {
    width: 100% !important;
  }
  ul {
    list-style: none;
    list-style-type: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 2.5rem;
    li {
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
    }
    li:first-child.defaultOption {
      flex-shrink: 0;
      position: static;
      display: flex;
      cursor: pointer;
      line-height: 2;
      height: 2.5rem;
      width: fit-content;
      max-width: 12rem;
      padding-left: 0.75rem;
      border-radius: 0.25rem;
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      > span {
        font-size: 0.875rem;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 0.125rem;
        font-weight: 500;
        color: ${({ theme }: { theme: any }) => theme.text_primary};
        flex-grow: 1;
      }
      &.disabled {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
      }
      @media (min-width: 1024px) {
        &.expand-box-xs {
          min-width: 5rem;
        }
        &.expand-box-sm {
          min-width: 8rem;
        }
        &.expand-box-md {
          min-width: 12rem;
        }
        &.expand-box {
          min-width: 16rem;
        }
      }
      div {
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        > span {
          color: ${({ theme }: { theme: any }) => theme.text_secondary};
        }
      }
    }
    li:first-child.defaultOption.opened {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep} !important;
      > span,
      div > span {
        color: ${({ theme }: { theme: any }) => theme.text_primary};
      }
    }

    .optionBox {
      flex-shrink: 0;
      position: absolute;
      top: 2.625rem;
      /* top: 2.375rem; */
      left: 0;
      width: fit-content;
      min-width: -webkit-fill-available;
      max-width: -webkit-fill-available;
      height: fit-content;
      /* max-height: 16rem;
      max-height: 28rem; */
      overflow-y: auto;
      overflow-x: hidden;
      margin-top: 0.25rem;
      z-index: 5100;
      &.aside-main {
        position: absolute;
        left: 0.75rem;
        top: 2.625rem;
        width: 16rem !important;
        max-width: 20rem;
        min-width: 8rem;
        overflow-y: auto;
        max-height: 84vh;

        @media (min-width: 640px) {
          width: 14.5rem !important;
        }
      }
      &.bottomToTop {
        position: absolute;
        transform: translateY(-100%);
        top: -0.75rem;
      }
      &.height-sm {
        max-height: 12rem;
      }
      &.height-base {
        max-height: 16rem;
      }
      &.height-md {
        max-height: 20rem;
      }
      &.height-lg {
        max-height: 24rem;
      }
      @media (min-width: 640px) {
        &.expand-content-xs {
          width: 8rem;
          max-width: 8rem;
        }
        &.expand-content-sm {
          width: 12rem;
          max-width: 12rem;
        }
        &.expand-content-md {
          width: 16rem;
          max-width: 16rem;
        }
        &.expand-content {
          width: 20rem;
          max-width: 20rem;
        }
      }
      @media (min-width: 1024px) {
        &.right {
          left: auto;
          right: 0;
          right: 1.25rem;
        }
      }
      li {
        display: block;
        font-size: 0.875rem;
        flex-shrink: 0;
        width: 100%;
        padding: 0.8rem 0.5rem;
        //    * 수정자 : 홍선영
        //    * 날짜 : 2023.10.18
        //    * 수정내용 : 옵션목록 긴 텍스트일 때 글자가 아래로 떨어지게 함
        // height: 2.5rem;
        // line-height: 2.5rem;
        // white-space: nowrap;
        // overflow-x: hidden;
        // text-overflow: ellipsis;

        overflow: visible;
        white-space: break-spaces;
        height: fit-content;
        line-height: 1rem;

        color: ${({ theme }: { theme: any }) => theme.text_secondary};
        &:hover:not(li.searchBox, li.selectedOption) {
          color: ${({ theme }: { theme: any }) => theme.text_primary};
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
        }
      }
      li.searchBox {
        padding: 0 0.5rem;
        margin: 0.5rem 0;
        outline: none;
      }
      li.searchBox input {
        width: 100%;
        height: 2.5rem;
        font-size: 0.875rem;
        outline: none;
        box-shadow: 0 0 0 1px inset ${({ theme }: { theme: any }) => theme.outline};
      }
      li.searchBox input:focus {
        outline: none;
        box-shadow: 0 0 0 2px inset ${({ theme }: { theme: any }) => theme.outline};
      }
      .selectedOption,
      li.selectedOption:hover {
        /* background-color: ${({ theme }: { theme: any }) => theme.selected_primary};
        color: #ffffff; */
        color: ${({ theme }: { theme: any }) => theme.selected_primary};
        background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
        border-radius: 0;
        font-weight: 500;
      }
    }
    .on {
      /* background-color: ${({ theme }: { theme: any }) => theme.tonal}; */
      /* outline: ${({ theme }: { theme: any }) => theme.outlineStatus}; */
      display: flex;
      flex-direction: column;
      border-radius: 0.25rem;
      height: fit-content !important;
      background-color: ${({ theme }: { theme: any }) => theme.board};
      outline: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
      box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    }
    .off {
      display: none;
    }
    @media (max-width: 639px) {
      .filterbar {
        width: calc(100% - 2.5rem) !important;
        left: 50% !important;
        transform: translateX(-50%);
      }
    }
    @media (max-width: 1023px) {
      .filter-1-left {
        margin: 0;
        top: 7rem;
        left: 1.25rem;
        position: fixed;
        width: 48vw;
        max-width: 32rem;
        min-width: 16rem;
      }
      .filter-1-leftToCenter {
        margin: 0;
        top: 7rem;
        left: 10%;
        position: fixed;
        width: 48vw;
        max-width: 32rem;
        min-width: 16rem;
      }
      .filter-1-center {
        margin: 0;
        top: 7rem;
        left: 50%;
        transform: translate(-50%, 0);
        position: fixed;
        width: 48vw;
        max-width: 32rem;
        min-width: 16rem;
      }
      .filter-1-centerToRight {
        margin: 0;
        top: 7rem;
        left: auto;
        right: 10%;
        position: fixed;
        width: 48vw;
        max-width: 32rem;
        min-width: 16rem;
      }
      .filter-1-right {
        margin: 0;
        top: 7rem;
        left: auto;
        right: 1.25rem;
        position: fixed;
        width: 48vw;
        max-width: 32rem;
        min-width: 16rem;
      }
      .filter-2-left {
        margin: 0;
        top: 10rem;
        left: 1.25rem;
        position: fixed;
        width: 48vw;
        max-width: 32rem;
        min-width: 16rem;
      }
      .filter-2-leftToCenter {
        margin: 0;
        top: 10rem;
        left: 10%;
        position: fixed;
        width: 48vw;
        max-width: 32rem;
        min-width: 16rem;
      }
      .filter-2-center {
        margin: 0;
        top: 10rem;
        left: 50%;
        transform: translate(-50%, 0);
        position: fixed;
        width: 48vw;
        max-width: 32rem;
        min-width: 16rem;
      }
      .filter-2-right {
        margin: 0;
        top: 10rem;
        left: auto;
        right: 1.25rem;
        position: fixed;
        width: 48vw;
        max-width: 32rem;
        min-width: 16rem;
      }
    }
    .optionBox-filter {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: 4999;
    }
  }
  &.widthLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    border-radius: 0.25rem;
    gap: 0;
    position: relative;
    @media (max-width: 1024px) {
      position: static;
    }
    label {
      font-size: 0.875rem;
      padding: 0 0.75rem;
      user-select: none;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      font-weight: 600;
    }
    > div > ul,
    > ul {
      position: static;
      > li:first-child {
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        border-radius: 0;
        border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
        outline: none;
      }
    }
  }
`;
