import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useGetTabletSettings } from '../../../hooks/useTablet';
import { useTranslation } from 'react-i18next';
import ToggleSettings from './ToggleSettings';
import HistorySettings from './HistorySettings';
import SliderSettings from './SliderSettings';
import SelectSettings from './SelectSettings';
import { BtnBlue, BtnGreen } from '../../../components/Button';
import { useSetAuth } from '../../../utils/useSetAuth';
import ClickableContainer from '../../s_cctv/S_cctv1/RealtimeCCTV/ClickableContainer';
import Portal from '../../../components/Portal';
import TabletSettingsQrcode from './TabletSettingsQrCode';
import { PulseLoader } from 'react-spinners';
import { logPost } from '../../../services/log';
import { useRecoilValue } from 'recoil';
import { IUser, userState } from '../../../atoms';

const Root = styled.div`
  user-select: none;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: center;
  overflow: scroll hidden !important;
  .scroll-container {
    width: 100%;
    max-height: calc(100% - 4rem);
    overflow: hidden scroll;
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }
  .content {
    display: flex;
    flex-direction: column;
    margin: 4rem 2rem;
    min-width: 48rem;
    .title {
      color: ${({ theme }: { theme: any }) => theme.text_disabled};
      margin-top: 2.5rem;
      margin-bottom: 1.5rem;
      border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    }
    .grid-container {
      flex-shrink: 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.5rem 2.5rem;
      &:last-child {
        margin-top: 4rem;
      }
    }
  }
  .button-wrapper {
    width: 100%;
    border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem;
    justify-content: flex-end;
    button {
      height: 2.5rem;
      font-size: 0.875rem;
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
`;

const TabletInputSettings = () => {
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const userInfo = useRecoilValue<IUser>(userState);
  const { t } = useTranslation();
  const { tabletSettingQuery, postTabletSettings, isNonClickable } = useGetTabletSettings();
  // 테이블트 설정 데이터 쿼리
  const { data, isLoading, isError, refetch } = tabletSettingQuery;
  // 설정 데이터 상태
  const [settingsData, setSettingsData] = useState<any>(data);
  // 저장 버튼 활성화 여부 상태
  const [disabled, setDisabled] = useState<boolean>(true);
  // 모달 상태
  const [openModal, setOpenModal] = useState<{ type: string; status: boolean }>({ type: 'qrcode', status: false });

  useEffect(() => {
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '태블릿 안전교육 관리 > 태블릿 입력 설정',
      action: '조회',
      etc: ``,
    });
  }, []);

  // 초기 데이터 세팅
  useEffect(() => {
    setSettingsData(data);
  }, [data]);

  // 저장 버튼 비활성화 세팅
  useEffect(() => {
    if (settingsData) {
      const result = isNonClickable(data, settingsData);
      setTimeout(() => {
        setDisabled(result);
      }, 300);
    }
  }, [data, settingsData, disabled]);

  // 슬라이더 값 처리
  const handleSliderValue = (
    e: React.ChangeEvent<HTMLInputElement>, //
    dataKey: string
  ) => {
    setSettingsData((prev: any) => ({
      ...prev, //
      [dataKey]: parseInt(e.target.value, 10).toString(),
    }));
  };

  // 최대 연령 리스트
  const maxAgeList = Array.from({ length: 31 }).map((_, i) => ({
    maxAge: `${i + 50}`,
    cdName: `${i + 50}세`,
    type: 'maxAge',
  }));

  // 저장 버튼 클릭 함수
  const clickSave = async () => {
    const {
      eDate, //
      wDate,
      personalinfoName,
      healthsafeName,
      ...rest
    } = settingsData;
    await postTabletSettings(rest);
    await refetch();
  };

  if (isLoading || !settingsData) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  if (isError) return null;

  return (
    <div className='content-container setting-medical'>
      <Root>
        <div className='scroll-container'>
          <div className='content'>
            <div className='grid-container'>
              <SliderSettings
                labelName={t('사진촬영 예제 대기시간')}
                data={settingsData.time} //
                dataKey='time'
                handleSlider={handleSliderValue}
                maxValue={20}
              />
              <SliderSettings
                labelName={t('의견/건의사항 녹음시간')}
                data={settingsData.recordTime} //
                dataKey='recordTime'
                handleSlider={handleSliderValue}
                maxValue={60}
              />
              <ToggleSettings
                toggleData={settingsData.soundYn} //
                toggleKey='soundYn'
                setState={setSettingsData}
                labelName={t('음성안내 사용여부')}
              />
              <ToggleSettings
                toggleData={settingsData.ocrYn} //
                toggleKey='ocrYn'
                setState={setSettingsData}
                labelName={t('OCR 사용여부')}
              />
              {/* <HistorySettings //
                data={data.personalinfoName ?? t('미선택')}
                labelName={t('개인정보 동의서')}
              />
              <HistorySettings //
                data={data.healthsafeName ?? t('미선택')}
                labelName={t('안전보건준수 서약서')}
              /> */}
              <ToggleSettings
                toggleData={settingsData.stressYn} //
                toggleKey='stressYn'
                setState={setSettingsData}
                labelName={t('직무스트레스 평가표')}
              />
              <SelectSettings
                options={maxAgeList} //
                dataKey='maxAge'
                defaultOption={settingsData.maxAge}
                setSelectState={setSettingsData}
              />
            </div>
            <div className='title'>{t('사용자 입력 옵션')}</div>
            <div className='grid-container'>
              <ToggleSettings
                toggleData={settingsData.bloodYn} //
                toggleKey='bloodYn'
                setState={setSettingsData}
                labelName={t('혈액형')}
              />
              <ToggleSettings
                toggleData={settingsData.takeMedicineYn} //
                toggleKey='takeMedicineYn'
                setState={setSettingsData}
                labelName={t('약 복용여부')}
              />
              <ToggleSettings
                toggleData={settingsData.safetydeviceYn} //
                toggleKey='safetydeviceYn'
                setState={setSettingsData}
                labelName={t('개인보호구')}
              />
            </div>
            <div className='grid-container'>
              <HistorySettings //
                data={data.writer}
                labelName={t('등록자')}
              />
              <HistorySettings //
                data={data.wDate}
                labelName={t('등록일자')}
              />
              <HistorySettings //
                data={data.editor}
                labelName={t('수정자')}
              />
              <HistorySettings //
                data={data.eDate}
                labelName={t('수정일자')}
              />
            </div>
          </div>
        </div>
        {auth.updateAuth && ( //
          <div className='button-wrapper'>
            <BtnGreen
              className=''
              onClick={() => setOpenModal({ type: 'qrcode', status: true })} //
            >
              {t('QR코드 출력')}
            </BtnGreen>
            <ClickableContainer
              button={<BtnBlue className=''>{t('저장')}</BtnBlue>} //
              disabled={disabled}
              onClick={clickSave} //
            />
          </div>
        )}
      </Root>
      <Portal openModal={openModal?.status}>
        <TabletSettingsQrcode
          onClickClose={() => setOpenModal({ status: false, type: 'qrcode' })} //
        />
      </Portal>
    </div>
  );
};

export default TabletInputSettings;
