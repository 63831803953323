/**
 * 작성자 : 한영광
 * 날짜 : 2023.06.26
 * 경로 : 출역 관리 - 월 출역현황
 */
import { useEffect, useState, useRef } from 'react';
import { PulseLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { IoChevronUpSharp } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { userState } from '../../../atoms';
import { BtnGhost } from '../../../components/Button';
import { MONTH_LIST, YEAR_LIST } from '../../../_constants';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { scrollToNodeTop } from '../../../utils/scrollToNodeTop';
import { useDetectScrolledToBottom } from '../../../utils/useDetectScrolledToBottom';
import { FloatingButtonToTop } from '../../../assets/styles/FloatingButtonToTop';
import { pad, todayYYYYMMDD, getFirstDayOfMonth, getLastDayOfMonth } from '../../../utils/formatDate';
import Portal from '../../../components/Portal';
import AttendListModal from '../../../components/Modal/AttendListModal';
import useOnKeydownF9 from '../../../utils/useOnKeydownF9';
import { logPost } from '../../../services/log';
import SearchSelectBoxSm from '../../../components/SearchSelectBoxSm';
import { useSetAuth } from '../../../utils/useSetAuth';
import TuiGrid from '../../../components/Table/TuiGrid';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import TuiGridWrapper from '../../../components/Table/TuiGridWrapper';
import { useGetFrozenCount } from '../../../utils/getFrozenCount';
import { ContentsContainerRoot } from '../../../assets/styles/ContentsContainerRoot';
import i18n from '../../../translation/i18n';
import { toast } from 'react-toastify';
import { apiGet } from '../../../services/_common';
import SelectBox from '../../../components/SelectBox';
import ShortcutButton from '../../../components/button/ShortcutButton';

const Root = styled(ContentsContainerRoot)`
  display: flex;
  flex-direction: column;

  > :nth-child(1) {
    align-self: flex-start;
  }
  .secondSearchOption {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

interface IModal {
  status: boolean;
  type: string;
  searchOption: ISearchOption;
}

interface ISearchOption {
  sjName: string;
  sjCd: string;
  startDate: string;
  endDate: string;
}

const Sub = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const toDay = todayYYYYMMDD();
  const userInfo = useRecoilValue(userState);
  const [columns, setColumns] = useState<any[]>([]);
  const [summary, setSummary] = useState({});
  const tableRef = useRef<HTMLDivElement>(null);
  const [siteJoinInfoList, setSiteJoinInfoList] = useState<any[]>([]);
  const [orgSiteJoinInfoList, setOrgSiteJoinInfoList] = useState<any[]>([]);
  const [sjCd, setSjCd] = useState({ type: 'sjCd', sjCd: '', cdName: '' });
  const [aYear, setAYear] = useState({ type: 'aYear', aYear: toDay.substring(0, 4), cdName: toDay.substring(0, 4) });
  const [month, setMonth] = useState({ type: 'month', month: toDay.substring(4, 6), cdName: toDay.substring(4, 6) });
  const [excelBtn, setExcelBtn] = useState(false);
  const [tableState, setTableState] = useState<any[]>([]);
  const { isBottom } = useDetectScrolledToBottom(tableRef);
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', searchOption: { sjName: '', sjCd: '', startDate: '', endDate: '' } });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSjCdAPI();
  }, []);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    const days = new Date(Number(aYear.aYear), Number(month.month), 0).getDate();

    const header = [];
    header.push({ header: t('근로자'), name: 'wName', align: 'left', width: 200, renderer: { classNames: ['text_secondary'] } });
    header.push({ header: t('협력업체'), name: 'sjName', align: 'left', filter: 'select', minWidth: 150, renderer: { classNames: ['text_secondary'] } });
    for (let i = 0; i < days; i += 1) {
      header.push({
        header: `${i + 1}일`,
        name: `${aYear.aYear}${month.month}${pad(i + 1, 2)}`,
        align: 'center',
        minWidth: 60,
        renderer: { classNames: ['text_secondary'] },
      });
    }
    header.push({ header: t('총합'), name: 'total', align: 'center', minWidth: 60 });
    setColumns(header);

    const summaryArr = [];
    for (let i = 0; i < days; i += 1) {
      summaryArr.push({
        header: `${i + 1}일`,
        name: `${aYear.aYear}${month.month}${pad(i + 1, 2)}`,
        align: 'center',
      });
    }
    const summaryObj: any = {};
    summaryArr.map((v: any) => {
      summaryObj[v.name] = {
        template: (valueMap: any) => {
          return `${valueMap.sum}`;
        },
      };
    });
    setSummary({
      height: 30,
      position: 'top',
      columnContent: {
        wName: t('전체'),
        sjName: '',
        ...summaryObj,
        total: {
          template: (valueMap: any) => {
            return `${valueMap.sum}`;
          },
        },
      },
    });
  }, [tableState, i18n.language]);

  // 월 출역현황(근로자) 조회 API
  const getAttendMonthWorkerAPI = async () => {
    const newSearchOption = { sjCd: sjCd.sjCd, aMonth: `${aYear.aYear}${month.month}` };
    const req = { ...newSearchOption, hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/attend/month', req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      setLoading(false);
      setTableState(
        data.attendList.map((v: any) => {
          let total = 0;
          Object.entries(v.day).map(([key, val]: any) => {
            total += Number(val);
          });
          return {
            wName: v.wName,
            sjName: v.sjName,
            ...v.day,
            total,
          };
        })
      );
    } else {
      setLoading(false);
      // toast.error(t(ERROR));
    }
  };

  // 협력업체 리스트 조회 API
  const getSjCdAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, delYnCheck: 'Y' };
    const res = await apiGet({ path: '/siteJoin', req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      const newList = data.siteJoinList
        .filter((v1: any) => v1.useYn === 'Y')
        .map((v2: any) => {
          return {
            type: 'sjCd',
            sjCd: v2.sjCd,
            cdName: v2.sjName,
          };
        });
      setSiteJoinInfoList([{ type: 'sjCd', sjCd: '', cdName: t('전체') }, ...newList]);
      setOrgSiteJoinInfoList(
        data.siteJoinList.map((v2: any) => {
          return {
            type: 'sjCd',
            sjCd: v2.sjCd,
            cdName: v2.sjName,
          };
        })
      );
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  // 검색 버튼 클릭
  const onClickSearch = () => {
    if (!openModal.status) {
      setLoading(true);
      navigate({
        pathname: location.pathname,
        search: '',
      });
      getAttendMonthWorkerAPI();
    }
  };

  // 엑셀 저장 버튼 클릭
  const onClickExcelSave = () => {
    if (tableState.length <= 0) return;
    setExcelBtn(true);
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '일 출역현황(퇴직공제용)',
      action: '엑셀 저장',
      etc: ``,
    });
  };

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  // 인쇄 버튼 클릭
  const onClickPrint = () => {
    handlePrint();
  };

  const isTotalClickAble = (rowData: any, columnName: string) => {
    return columnName === 'wName' && rowData === 'summary';
  };

  const isClickAble = (rowData: any, columnName: string) => {
    return columnName !== 'wName' && columnName !== 'sjName' && columnName !== 'total' && columnName !== '_number' && rowData && rowData[columnName] !== 0;
  };

  const getSearchOptionOfMonth = () => {
    const searchYear = Object.keys(tableState[0])[0].slice(0, 4);
    const searchMonth = Object.keys(tableState[0])[0].slice(4, 6);
    return {
      sjName: '',
      sjCd: '',
      startDate: getFirstDayOfMonth(Number(searchYear), Number(searchMonth)),
      endDate: getLastDayOfMonth(Number(searchYear), Number(searchMonth)),
      wName: '',
    };
  };

  const getSearchOptionOfDay = (rowData: any, columnName: string) => {
    const sjObject = orgSiteJoinInfoList.find((siteJoinInfo) => siteJoinInfo.cdName === rowData.sjName);
    return {
      sjName: rowData.sjName,
      sjCd: sjObject ? sjObject.sjCd : '',
      startDate: `${columnName.substring(0, 4)}-${pad(Number(columnName.substring(4, 6)), 2)}-${pad(Number(columnName.substring(6, 8)), 2)}`,
      endDate: `${columnName.substring(0, 4)}-${pad(Number(columnName.substring(4, 6)), 2)}-${pad(Number(columnName.substring(6, 8)), 2)}`,
      wName: rowData.wName,
    };
  };

  // Row 클릭
  const onClickRow = (rowData: any, columnName: string) => {
    if (isTotalClickAble(rowData, columnName)) {
      const searchOption = getSearchOptionOfMonth();
      setOpenModal({ status: true, type: 'dailyAttendList', searchOption });
    }
    if (isClickAble(rowData, columnName)) {
      const searchOption = getSearchOptionOfDay(rowData, columnName);
      setOpenModal({ status: true, type: 'dailyAttendList', searchOption });
    }
  };

  const renderExcelBtn = () => {
    if (auth.excelAuth) {
      return (
        <BtnGhost onClick={onClickExcelSave}>
          {' '}
          <span className='icon-ms-xlsx'>X</span>
          {t('엑셀')}
        </BtnGhost>
      );
    }
    return null;
  };

  const renderPrintBtn = () => {
    if (auth.printAuth) {
      return (
        <BtnGhost onClick={onClickPrint}>
          <span className='material-symbols-rounded'>print</span>
          {t('인쇄')}
        </BtnGhost>
      );
    }
    return null;
  };
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight, componentRef.current?.offsetWidth]);

  const frozenCount = useGetFrozenCount();

  return (
    <>
      <Root loading={loading}>
        <SearchOptions>
          {isBottom && (
            <FloatingButtonToTop>
              <button type='button' onClick={() => scrollToNodeTop(tableRef)}>
                <IoChevronUpSharp size={20} style={{ stroke: 'white' }} />
              </button>
            </FloatingButtonToTop>
          )}
          <div className='inputsWrapper'>
            <div className='inputForm-row withLabel'>
              <label htmlFor='sjCd'>{t('협력업체')}</label>
              <div className='inputForm-col'>
                <SearchSelectBoxSm
                  options={siteJoinInfoList}
                  defaultOption={t('전체')}
                  state={sjCd}
                  setState={setSjCd}
                  stateKey='sjCd'
                  codeKey='cdName'
                  filterbar='filter-1-left'
                  optionHeight='height-md'
                />
              </div>
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col'>
                <SelectBox options={YEAR_LIST} defaultOption={aYear.aYear} state={aYear} setState={setAYear} stateKey='aYear' filterbar='filter-1-left' optionHeight='height-md' />
              </div>
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col'>
                <SelectBox options={MONTH_LIST} defaultOption={month.month} state={month} setState={setMonth} stateKey='month' filterbar='filter-1-left' optionHeight='height-sm' />
              </div>
            </div>
          </div>
          <div className='inputsWrapper'>
            <div className='secondSearchOption'>
              <div className='flex-basic textBtnGroup'>
                <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
                {/* <BtnGhost onClick={onClickSearch} className='searchBtn'>
                  {t('검색')}
                  <span className='shortcut-f9'>F9</span>
                </BtnGhost> */}
              </div>
              <div className='flex-basic iconBtnGroup'>
                {renderExcelBtn()}
                {/* {renderPrintBtn()} */}
              </div>
            </div>
          </div>
        </SearchOptions>
        <TuiGridWrapper componentRef={componentRef}>
          <TuiGrid
            data={tableState}
            columns={columns}
            perPage={5}
            excelBtn={excelBtn}
            setExcelBtn={setExcelBtn}
            onClickRow={onClickRow}
            frozenCount={frozenCount}
            summary={summary}
            height={tuiHeight}
          />
        </TuiGridWrapper>
        {loading && (
          <LoadingModalBackground>
            <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
          </LoadingModalBackground>
        )}
      </Root>
      <Portal openModal={openModal?.status}>{openModal.status && openModal.type === 'dailyAttendList' && <AttendListModal setOpenModal={setOpenModal} filter={openModal.searchOption} />}</Portal>
    </>
  );
};

export default Sub;
