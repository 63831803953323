/**
 *  2023.05.10 홍선영
 *  마스터일반관리 -> 현장관리 -> 현장 정보 탭 페이지 인풋폼
 *  pages/m_common/common4/siteInfo.tsx 에서 사용
 */
import { useEffect, Dispatch, SetStateAction, useState, useRef } from 'react';
import styled from 'styled-components';
import { BtnBlue, BtnGray as BtnRed } from '../Button';
import Input from '../Input';
import NumbersInput from '../NumbersInput';
import DatePickerComponent from '../DatePicker';
import { COMCD_INFRA, COMCD_S_STATUS, COMCD_S_VERSION } from '../../_constants';
import { AddressSearch } from '../AddressSearch';
import { applyBorderStyle } from '../../utils/applyBorderStyle';
import SearchSelectBoxSm from '../SearchSelectBoxSm';
import SelectBox from '../SelectBox';
import { useTranslation } from 'react-i18next';
import ToggleSettings from '../../pages/sm_setting/setting12/ToggleSettings';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 0.5rem;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;
const Root = styled.div<IUserInputFormStyle>`
  padding: 0.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  overflow: auto;
  justify-content: flex-start;
  .seperator {
    margin: 0.5rem 0;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    div {
      height: 1px;
      flex-grow: 1;
      background-color: rgba(0, 0, 0, 0.1);
    }
    span {
      border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    }
  }
  .inputForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem;
    button {
      height: 2.5rem;
      font-size: 0.75rem;
      flex-shrink: 0;
      padding: 0 0.75rem;
      border-radius: 0.25rem;
    }
    button.gray {
      font-weight: 500;
      color: ${({ theme }: { theme: any }) => theme.color.zinc_200};
    }
    label {
      width: 6.5rem;
      font-weight: 500;
      flex-shrink: 0;
      font-size: 0.875rem;
    }
    .inputForm-group-1280 {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (min-width: 1280px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
      }
    }
    .inputForm-group-1536 {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (min-width: 1536px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
      }
    }
    .inputForm-group-1536.withBtn {
      flex-direction: row;
      gap: 0.5rem;
      button {
        transition: none;
      }
      @media (min-width: 1536px) {
        gap: 2rem;
        button {
          margin-left: 0;
          width: fit-content;
        }
      }
    }
    .inputForm-group-1536.withBtn.inputColumnFour {
      input {
        padding: 0;
      }
    }
    .inputForm-row.labelInInput {
      display: flex;
      gap: 0.5rem;
      flex-grow: 1;
      > div {
        flex-grow: 1;
      }
    }
    .inputForm-row.labelOutInput,
    .inputForm-row.labelInInput > div > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      flex-grow: 1;
      input {
        flex-shrink: 1;
        height: 2.5rem;
        font-size: 0.875rem;
        padding: 0 0.5rem;
        &:disabled {
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          color: ${({ theme }: { theme: any }) => theme.text_disabled};
        }
      }
      > div.viewOnly {
        height: 2.5rem;
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_disabled};
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        border-radius: 0.25rem;
        padding: 0 0.75rem;
        display: flex;
        align-items: center;
      }
      > div {
        flex-grow: 1;
        > ul {
          height: 2.5rem;
          li {
            display: flex;
            width: 100%;
            max-width: 100%;
            span {
              flex-grow: 1;
              width: 4rem;
            }
          }
        }
      }
    }
    .detailInfo-group {
      margin: 1rem 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      @media (min-width: 1536px) {
        grid-template-columns: repeat(3, 1fr);
      }
      .flex-col.detailInfo {
        flex-grow: 1;
        width: 100%;
        div {
          height: 2.5rem;
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          border-radius: 0.25rem;
          display: flex;
          align-items: center;
          padding: 0 0.5rem;
          font-size: 0.875rem;
        }
      }
    }
  }
  input {
    :disabled {
      background-color: #eeeeee;
    }
  }
  .required {
    &::after {
      content: '*';
      padding: 0 0.2rem;
      color: red;
    }
  }
  @media screen and (max-width: 1023px) {
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: fit-content;
    overflow: none;
  }
`;

interface IUserInputFormStyle {
  width?: string;
  flexDirection?: string;
}

interface IUserInputForm {
  style?: IUserInputFormStyle;
  state: any;
  setState: Dispatch<SetStateAction<any>>;
  isNewAdd?: boolean;
  userInfoInputFormRef: React.RefObject<any>;
  onClickNewAdd: () => void;
  onClickSave: () => void;
  onClickDelete: () => void;
  hCd: any;
  setHCd: Dispatch<SetStateAction<any>>;
  sStatus: any;
  setSStatus: Dispatch<SetStateAction<any>>;
  sVersion: any;
  setSVersion: Dispatch<SetStateAction<any>>;
  sInfra: any;
  setSInfra: Dispatch<SetStateAction<any>>;
  sTelNumState: any;
  setSTelNumState: Dispatch<SetStateAction<any>>;
  hList: any;
  useYnComCdList: any;
  sStatusComCdList: any;
  sVersionComCdList: any;
  sInfraComCdList: any;
  isSaveClicked: boolean;
}

const SiteInfoInputForm = ({
  style,
  state,
  setState: setRowState,
  isNewAdd,
  userInfoInputFormRef,
  onClickNewAdd,
  onClickSave,
  onClickDelete,
  hCd,
  setHCd,
  sStatus,
  setSStatus,
  sVersion,
  setSVersion,
  sInfra,
  setSInfra,
  sTelNumState,
  setSTelNumState,
  hList,
  useYnComCdList,
  sStatusComCdList,
  sVersionComCdList,
  sInfraComCdList,
  isSaveClicked,
}: IUserInputForm) => {
  const { t } = useTranslation();
  const userInfoInputFormTopRef = useRef<any>(null); // 인풋 폼 ref (플로팅버튼 클릭했을 때 스크롤 상단으로 끌어올리기 위한 ref)
  // const { isBottom } = useDetectScrolledToBottom(userInfoInputFormTopRef); // 스크롤이 해당노드의 하단에 근접했는지 여부 (플로팅버튼 띄우기 위함)
  const sTelLengthState = { num1: 3, num2: 4, num3: 4 }; // 전화번호 입력 길이
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    if (sStatus[COMCD_S_STATUS] !== '') {
      setRowState((prev: any) => ({ ...prev, sStatus: sStatus[COMCD_S_STATUS] }));
    }
  }, [sStatus[COMCD_S_STATUS]]);

  useEffect(() => {
    if (sVersion[COMCD_S_VERSION] !== '') {
      setRowState((prev: any) => ({ ...prev, sVersion: sVersion[COMCD_S_VERSION] }));
    }
  }, [sVersion[COMCD_S_VERSION]]);

  useEffect(() => {
    if (sInfra[COMCD_INFRA] !== '') {
      setRowState((prev: any) => ({ ...prev, sInfra: sInfra[COMCD_INFRA] }));
    }
  }, [sInfra[COMCD_INFRA]]);

  useEffect(() => {
    if (isNewAdd && state.sTel === '') {
      setSTelNumState({ num1: '010', num2: '', num3: '' });
    } else if (state.sTel) {
      const splitNumber = state.sTel.split('-');
      setSTelNumState({
        num1: splitNumber[0],
        num2: splitNumber[1],
        num3: splitNumber[2],
      });
    }
  }, [state.sTel, isNewAdd]);

  useEffect(() => {
    if (startDate !== '') {
      setRowState((prev: any) => ({ ...prev, sDate: startDate }));
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate !== '') {
      setRowState((prev: any) => ({ ...prev, fDate: endDate }));
    }
  }, [endDate]);

  return (
    <>
      <Root ref={userInfoInputFormTopRef}>
        {/* {isBottom && <FloatingButtonToTop nodeRef={userInfoInputFormTopRef} />} */}
        <div className='inputForm'>
          <div className='inputForm-group-1536'>
            <div className='inputForm-row labelOutInput'>
              <label htmlFor='code'>{t('코드')}</label>
              <div className='viewOnly'>{isNewAdd ? '' : state.sCd}</div>
            </div>
            <div className='inputForm-row labelOutInput'>
              <label htmlFor='useYn' className='required'>
                {t('본사명')}
              </label>
              <div>
                <SearchSelectBoxSm
                  options={hList}
                  defaultOption={isNewAdd ? t('본사 선택') : t(hCd.cdName)}
                  state={hCd}
                  setState={setHCd}
                  stateKey='hCd'
                  codeKey='cdName'
                  initiateKey={hCd.hCd}
                  getBorderStyle={isSaveClicked ? applyBorderStyle(hCd.hCd, 'red', 'hCd') : undefined}
                  comboWidth='expand-box'
                />
              </div>
            </div>
          </div>
          <div className='inputForm-group-1536'>
            <div className='inputForm-row labelInInput'>
              <Input
                className='required'
                label={t('현장명')}
                type='text'
                id='sName'
                name='sName'
                state={state}
                setState={setRowState}
                getBorderStyle={isSaveClicked ? applyBorderStyle(state.sName, 'red', 'sName') : undefined}
              />
            </div>
          </div>
          <div className='inputForm-group-1536'>
            <div className='inputForm-row labelOutInput'>
              <label htmlFor='lockYn'>{t('현장상태')}</label>
              <SelectBox
                options={sStatusComCdList}
                defaultOption={isNewAdd ? t('운영중') : sStatus.cdName}
                state={sStatus}
                setState={setSStatus}
                stateKey={COMCD_S_STATUS}
                initiateKey={sStatus[COMCD_S_STATUS]}
              />
            </div>
            <div className='inputForm-row labelOutInput'>
              <label htmlFor='useYn'>{t('건설업종')}</label>
              <SelectBox options={sInfraComCdList} defaultOption={t('미선택')} state={sInfra} setState={setSInfra} stateKey={COMCD_INFRA} initiateKey={sInfra[COMCD_INFRA]} />
            </div>
          </div>
          <div className='inputForm-group-1536'>
            <div className='inputForm-row labelOutInput'>
              <label htmlFor='sDate'>{t('시공일자')}</label>
              <div className='datePickerWrapper'>
                <DatePickerComponent startDate={state?.sDate?.replaceAll('-', '')} setDate={setStartDate} popperPlacement='bottom' />
              </div>
            </div>
            <div className='inputForm-row labelOutInput'>
              <label htmlFor='fDate'>{t('종료일자')}</label>
              <div className='datePickerWrapper'>
                <DatePickerComponent startDate={state?.fDate?.replaceAll('-', '')} setDate={setEndDate} popperPlacement='bottom' />
              </div>
            </div>
          </div>
          <div className='inputForm-group-1536'>
            <div className='inputForm-row labelOutInput'>
              <label htmlFor='hpNum'>{t('현장 대표번호')}</label>
              <NumbersInput state={sTelNumState} setState={setSTelNumState} lengthState={sTelLengthState} />
            </div>
            <div className='inputForm-row labelOutInput'>
              <label htmlFor='useYn'>{t('프로그램 버전')}</label>
              <SelectBox
                options={sVersionComCdList}
                defaultOption={isNewAdd ? t('미선택') : t(state.sVersion)}
                state={sVersion}
                setState={setSVersion}
                stateKey={COMCD_S_VERSION}
                initiateKey={sVersion[COMCD_S_VERSION]}
              />
            </div>
          </div>
          <div className='inputForm-group-1536 withBtn'>
            <div className='inputForm-row labelOutInput content-overflow'>
              <label htmlFor={t('주소')}>{t('우편번호')}</label>
              <Input type='text' id='postNum' name='postNum' state={state} setState={setRowState} disabled />
            </div>
            <div className='inputForm-row labelOutInput'>
              <AddressSearch setState={setRowState} />
            </div>
          </div>
          <div className='inputForm-group-1536'>
            <div className='inputForm-row labelOutInput'>
              <label htmlFor={t('주소')}>{t('주소')}</label>
              <Input type='text' id='address1' name='address1' state={state} setState={setRowState} disabled />
            </div>
            <div className='inputForm-row labelOutInput'>
              <label htmlFor={t('상세주소')}>{t('상세주소')}</label>
              <Input type='text' id='address2' name='address2' state={state} setState={setRowState} placeholder={t('상세주소')} maxLength={200} />
            </div>
          </div>
          <div className='inputForm-group-1536'>
            <div className='inputForm-row labelOutInput'>
              <ToggleSettings
                toggleData={state.useYn} //
                toggleKey='useYn'
                setState={setRowState}
                labelName={t('사용유무')}
              />
            </div>
            <div className='inputForm-row labelOutInput'>
              <ToggleSettings
                toggleData={state.smsYn} //
                toggleKey='smsYn'
                setState={setRowState}
                labelName={t('SMS 사용여부')}
              />
            </div>
          </div>
          <div className='inputForm-group-1536'>
            <div className='inputForm-row labelOutInput'>
              <ToggleSettings
                toggleData={state.hTransyn} //
                toggleKey='hTransyn'
                setState={setRowState}
                labelName={t('본사연동 사용여부')}
              />
            </div>
            <div className='inputForm-row labelOutInput'>
              <label htmlFor='hTranscd'>{t('본사 연동코드')}</label>
              <div>
                <Input type='text' id='hTranscd' name='hTranscd' state={state} setState={setRowState} />
              </div>
            </div>
          </div>
          <div className='inputForm-group-1536'>
            <div className='inputForm-row labelOutInput'>
              <ToggleSettings
                toggleData={state.msiteYn} //
                toggleKey='msiteYn'
                setState={setRowState}
                labelName={t('다중 현장 관리')}
              />
            </div>
            <div className='inputForm-row labelOutInput'>
              <ToggleSettings
                toggleData={state.eduexamYn} //
                toggleKey='eduexamYn'
                setState={setRowState}
                labelName={t('안전보건 교육 시험 사용여부')}
              />
            </div>
          </div>
          <div className='inputForm-row labelInInput'>
            <Input label={t('비고')} type='text' id='bigo' name='bigo' state={state} setState={setRowState} maxLength={200} />
          </div>
          <div className='seperator'>
            <div />
            {t('보고서 사용유무 암호화')}
            <div />
          </div>
          <div className='inputForm-group-1536'>
            <div className='inputForm-row labelOutInput'>
              <ToggleSettings
                toggleData={state.eListYn} //
                toggleKey='eListYn'
                setState={setRowState}
                labelName={t('리스트')}
              />
            </div>
            <div className='inputForm-row labelOutInput'>
              <ToggleSettings
                toggleData={state.eExcelYn} //
                toggleKey='eExcelYn'
                setState={setRowState}
                labelName={t('엑셀')}
              />
            </div>
          </div>
          <div className='inputForm-group-1536'>
            <div className='inputForm-row labelOutInput'>
              <ToggleSettings
                toggleData={state.ePrintYn} //
                toggleKey='ePrintYn'
                setState={setRowState}
                labelName={t('인쇄')}
              />
            </div>
          </div>
          {!isNewAdd ? (
            <div className='detailInfo-group'>
              <div className='flex-col detailInfo'>
                <label htmlFor='createDate'>{t('등록일자')}</label>
                <div>{state?.wDate}</div>
              </div>
              <div className='flex-col detailInfo'>
                <label htmlFor='createUser'>{t('등록자')}</label>
                <div>{state.writer}</div>
              </div>
              <div className='flex-col detailInfo'>
                <label htmlFor='updateDate'>{t('수정일자')}</label>
                <div>{state.eDate}</div>
              </div>
              <div className='flex-col detailInfo'>
                <label htmlFor='updateUser'>{t('수정자')}</label>
                <div>{state.editor}</div>
              </div>
            </div>
          ) : undefined}
        </div>
      </Root>
      <ButtonsWrapper>
        {!isNewAdd ? <BtnRed onClick={onClickDelete}>{t('삭제')}</BtnRed> : undefined}
        <BtnBlue onClick={onClickSave}>{t('저장')}</BtnBlue>
      </ButtonsWrapper>
    </>
  );
};

export default SiteInfoInputForm;
