class CustomRemoveBtnRenderer {
  element: HTMLElement;
  root: any;

  constructor(props: any) {
    const { onClickTuiCustomButton, t, style } = props.columnInfo.renderer.options;
    const { rowKey } = props;

    this.element = document.createElement('button');
    this.element.textContent = t(style.text);
    this.element.style.backgroundColor = style.bgColor;
    this.element.style.height = '2rem';
    this.element.style.color = 'white';
    this.element.style.padding = '0 0.75rem 0 0.5rem';
    this.element.style.margin = '0 auto';
    this.element.style.border = 'none';
    this.element.style.borderRadius = '4px';
    this.element.style.cursor = 'pointer';
    this.element.style.fontSize = '0.875rem';

    this.element.onmouseover = () => {
      this.element.style.backgroundColor = style.hoverBgColor;
    };
    this.element.onmouseout = () => {
      this.element.style.backgroundColor = style.bgColor;
    };
    this.element.addEventListener('click', () => {
      const { filteredRawData } = props.grid.store.data;
      const findIndex = filteredRawData?.findIndex((el: any) => el.rowKey === rowKey);
      if (findIndex !== -1) onClickTuiCustomButton(filteredRawData[findIndex]);
      else onClickTuiCustomButton(filteredRawData[rowKey]);
    });
  }

  getElement() {
    return this.element;
  }
  destroy() {
    this.root.unmount();
  }
}
export default CustomRemoveBtnRenderer;
