import styled from 'styled-components';

const Root = styled.div`
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 3rem;
  .material-symbols-rounded {
    padding: 0.5rem 0.5rem;
    border-radius: 0.25rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
    color: ${({ theme }: { theme: any }) => theme.filled_red};
    font-size: 1.5rem;
    font-variation-settings: 'FILL' 1;
  }
  &:hover {
    .material-symbols-rounded {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep_red};
      color: ${({ theme }: { theme: any }) => theme.filled_red_deep};
    }
    &:active {
      .material-symbols-rounded {
        transform: scale(0.9);
      }
    }
  }
`;

interface Props {
  onClick: () => void;
}

const DeleteOnTable = ({ onClick }: Props) => {
  return (
    <Root onClick={onClick}>
      <span className='material-symbols-rounded'>delete</span>
    </Root>
  );
};

export default DeleteOnTable;
