/**
 * 날짜 문자열에서 "yyyy-mm-dd" 부분만 추출하는 함수
 * @param datetime 날짜 및 시간을 포함한 문자열
 * @returns "yyyy-mm-dd" 형식의 날짜 부분
 */
export function extractDate(datetime: string): string {
  // 공백을 기준으로 문자열을 분리하여 첫 번째 요소 반환
  return datetime.split(' ')[0];
}

/**
 * 날짜 문자열에서 "hh:mm:ss" 부분만 추출하는 함수
 * @param datetime 날짜 및 시간을 포함한 문자열
 * @returns "hh:mm:ss" 형식의 시간 부분
 */
export function extractTime(datetime: string): string {
  // 공백을 기준으로 문자열을 분리하여 두 번째 요소 반환
  return datetime.split(' ')[1];
}

// 숫자가 한 자리 일 경우 0을 붙여서 두 자리로 만드는 함수
export const formatNumber = (num: number) => {
  return num < 10 ? `0${num}` : `${num}`;
};

// 시간을 00:00:00(hh:mm:ss) 형식으로 변환하는 함수
export const timeToHHMMSS = (time: number): string => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);
  return `${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(seconds)}`;
};

// 시간을 00:00(hh:mm) 형식으로 변환하는 함수
export const timeToHHMM = (time: number) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  return `${formatNumber(hours)}:${formatNumber(minutes)}`;
};

// 하루의 일정 비율에 해당하는 시간을 구하는 함수
export const rangeToHHMMSS = (value: number, maxValue: number): string => {
  // 백분율 계산
  const percentage = (value / maxValue) * 100;
  // 하루의 총 초 계산
  const totalSeconds = Math.round((percentage / 100) * 24 * 60 * 60);
  const hours = Math.floor(totalSeconds / 3600); // 시간 계산
  const minutes = Math.floor((totalSeconds % 3600) / 60); // 분 계산
  const seconds = Math.floor(totalSeconds % 60); // 초 계산
  // 두자리 수 문자열로 변환
  const convertToString = (num: number) => num.toString().padStart(2, '0');
  // 시간, 분, 초를 반환
  return `${convertToString(hours)}:${convertToString(minutes)}:${convertToString(seconds)}`;
};

export const HHMMSStoRange = (time: string, maxValue: number): number => {
  // 주어진 시간을 시, 분, 초로 분리
  const [hours, minutes, seconds] = time.split(':').map((str) => parseInt(str, 10));
  // 시간을 초로 환산
  const totalSeconds = hours * 3600 + minutes * 60 + seconds;
  // 하루의 총 초수
  const totalSecondsInDay = 24 * 3600;
  // 백분율로 환산
  const percentage = (totalSeconds / totalSecondsInDay) * 100;
  // 상대 값으로 변환
  const valueInRange = (percentage * maxValue) / 100;
  return valueInRange;
};

export const TimeToRange = (time: number, maxValue: number): number => {
  // 하루의 총 초수
  const totalSecondsInDay = 24 * 3600;
  // 백분율로 환산
  const percentage = (time / totalSecondsInDay) * 100;
  // 상대 값으로 변환
  const valueInRange = (percentage * maxValue) / 100;
  return valueInRange;
};

// 00:00:00(hh:mm:ss) 형식의 시간을 분 단위로 변환하는 함수
export const HHMMSStoTime = (timeString: string): number => {
  // 주어진 시간을 시, 분, 초로 분리
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  // 시간을 초로 환산
  const totalSeconds = hours * 3600 + minutes * 60 + seconds;
  return totalSeconds;
};
