import { useTranslation } from 'react-i18next';
import Input from '../../Input';
import styled from 'styled-components';
import { useEffect } from 'react';

const Root = styled.div`
  &.stressWrapper {
    > div {
      display: flex;
      gap: 0.5rem;
      > div:first-child {
        div {
          gap: 0;
          display: flex;
          flex-direction: row;
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          border-radius: 0.25rem;
          overflow: hidden;
          > label {
            width: fit-content;
            padding: 0 0.75rem;
            border-right: 1px solid ${({ theme }: { theme: any }) => theme.outline};
          }
          input {
            text-align: center;
            width: 3.5rem !important;
            flex-grow: 0 !important;
          }
        }
      }
      .resultWrapper {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        border-radius: 0.25rem;
        overflow: hidden;
        height: 2.5rem;
        padding: 0 0.75rem;
        .labelName,
        .result {
          font-size: 0.875rem;
        }
        .labelName {
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
          color: ${({ theme }: { theme: any }) => theme.text_secondary};
        }
        .result {
          border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
          padding-left: 0.75rem;
          color: ${({ theme }: { theme: any }) => theme.text_disabled};
        }
      }
    }
  }
`;

interface Props {
  workerInputSet: any;
  setWorkerInputSet: any;
  isViewMode: boolean;
  cdName: string;
}

const StressTotal = ({ workerInputSet, setWorkerInputSet, isViewMode, cdName }: Props) => {
  const { t } = useTranslation();
  const { stressTotal } = workerInputSet;
  const isEmpty = stressTotal === '';

  // stressResult 업데이트
  useEffect(() => {
    if (stressTotal !== '') {
      setWorkerInputSet({
        ...workerInputSet, //
        stressResult: stressResult(stressTotal),
      });
    }
  }, [stressTotal]);

  // input에 ""."이 있으면 4, 없으면 3를 리턴하는 함수
  const handleMaxLength = () => {
    const dotRegex = /\./; // '.'(점)을 검사하는 정규식
    if (dotRegex.test(workerInputSet.stressTotal)) {
      return 4;
    }
    return 3;
  };

  // 1차 설계서 132p 참고치를 반영한 스트레스 결과 표시 함수
  const stressResult = (value: number) => {
    if (value <= 45) return '하위 25%';
    if (value <= 50.7) return '하위 50%';
    if (value <= 56.5) return '상위 50%';
    return '상위 25%';
  };

  const handleResult = () => {
    return isEmpty ? t('미입력') : stressResult(stressTotal);
  };

  return (
    <Root className='control stressWrapper'>
      <label>{t(cdName)}</label>
      <div className='flex-basic'>
        <Input
          type='text'
          label={t('점수 합계')} //
          name='stressTotal'
          state={workerInputSet}
          setState={setWorkerInputSet}
          disabled={isViewMode}
          placeholder='0'
          valueType='decimal'
          maxLength={handleMaxLength()}
        />
        <div className='resultWrapper'>
          <span className='labelName'>{t('결과')}</span>
          <div className='result'>{handleResult()}</div>
        </div>
      </div>
    </Root>
  );
};

export default StressTotal;
