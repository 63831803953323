/**
 * 작성자 : 홍선영
 * 날짜 : 2023.07.18
 * 기능 : 출역리스트 모달
 */

import { Dispatch, SetStateAction, useState, useRef, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import i18n from '../../translation/i18n';

import { userState } from '../../atoms';
import { LIST_COUNT } from '../../_constants';
import { IComCdList } from 'customTypes';
import TuiGrid from '../Table/TuiGrid';
import SelectBox from '../SelectBox';
import { setComCdListState } from '../../utils/setComCdListState';
import { arraySortByAscdOrder } from '../../utils/arraySortByAscdOrder';
import { Modal, ModalBackground } from '../../assets/styles/Modal';
import { apiGet } from '../../services/_common';

interface IModal {
  status: boolean;
  type: string;
  searchOption: ISearchOption;
}

interface ISearchOption {
  sjName: string;
  sjCd: string;
  startDate: string;
  endDate: string;
  wName?: string;
}

interface IProps {
  setOpenModal: Dispatch<SetStateAction<IModal>>;
  filter: ISearchOption;
}

const AttendListModal = ({ setOpenModal, filter }: IProps) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const [searchOption, setSearchOption] = useState({ sjCd: '', wName: '' });
  const [innerTab, setInnerTab] = useState(0);
  const [dailyAttendState, setDailyAttendState] = useState([]);
  const [dailyPersonalAttendState, setDailyPersonalAttendState] = useState([]);
  const { eListYn } = userInfo;
  const [siteJoinInfoList, setSiteJoinInfoList] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [columns2, setColumns2] = useState<any[]>([]);
  const [listCountComCdList, setListCountComCdList] = useState<IComCdList[]>([]); // 검색줄수 공통코드
  const [listCount, setListCount] = useState({
    type: LIST_COUNT,
    [LIST_COUNT]: '15',
    cdName: '15',
  });
  const [firstApiReq, setFirstApiReq] = useState(false);
  const [faceSetList, setFaceSetList] = useState<any[]>([]);
  const componentRef = useRef<HTMLDivElement>(null);
  const componentRef2 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getFaceSetAPI();
    getSjCdAPI().then((res: any) => {
      if (res.status === 200) {
        setComCdListState(LIST_COUNT, setListCountComCdList, false);
        setListCount({ LIST_COUNT: '15', cdName: '15', type: 'LIST_COUNT' });
        setFirstApiReq(true);
      }
    });
  }, []);

  useEffect(() => {
    if (innerTab === 1) {
      setColumns2(
        userInfo.prejobtypeYn === 'Y'
          ? [
              {
                header: t('근로자명'),
                name: 'wName',
                sortable: true,
                minWidth: 150,
                renderer: { classNames: ['text_secondary'] },
              },
              {
                header: t('협력업체'),
                name: 'sjName',
                sortable: true,
                filter: 'select',
                width: 200,
                renderer: { classNames: ['text_secondary'] },
              },
              {
                header: t('공종'),
                name: 'wPrejobtypeName',
                sortable: true,
                minWidth: 120,
              },
              {
                header: t('직종'),
                name: 'wJobtypeName',
                sortable: true,
                minWidth: 120,
              },
              {
                header: t('취업일자'),
                name: 'wJobdate',
                align: 'center',
                sortable: true,
                minWidth: 120,
              },
              {
                header: t('생년월일'),
                name: 'wBdate',
                align: 'center',
                sortable: true,
                minWidth: 120,
              },
              {
                header: t('출역일자'),
                name: 'aDate',
                align: 'center',
                sortable: true,
                filter: 'select',
                minWidth: 120,
                renderer: { classNames: ['text_secondary'] },
              },
              {
                header: t('출역시간'),
                name: 'aTime',
                align: 'center',
                sortable: true,
                minWidth: 120,
                renderer: { classNames: ['text_secondary'] },
              },
              {
                header: t('장비명'),
                name: 'fName',
                sortable: true,
                minWidth: 120,
              },
              {
                header: t('출입위치'),
                name: 'fLocationName',
                sortable: true,
                filter: 'select',
                minWidth: 120,
              },
              {
                header: t('체온'),
                name: 'temp',
                align: 'center',
                sortable: true,
                minWidth: 120,
              },
            ]
          : [
              {
                header: t('근로자명'),
                name: 'wName',
                sortable: true,
                minWidth: 150,
                renderer: { classNames: ['text_secondary'] },
              },
              {
                header: t('협력업체'),
                name: 'sjName',
                sortable: true,
                width: 200,
                renderer: { classNames: ['text_secondary'] },
              },
              {
                header: t('직종'),
                name: 'wJobtypeName',
                align: 'center',
                sortable: true,
                minWidth: 120,
              },
              {
                header: t('취업일자'),
                name: 'wJobdate',
                align: 'center',
                sortable: true,
                minWidth: 120,
              },
              {
                header: t('생년월일'),
                name: 'wBdate',
                align: 'center',
                sortable: true,
                minWidth: 120,
              },
              {
                header: t('출역일자'),
                name: 'aDate',
                align: 'center',
                sortable: true,
                filter: 'select',
                minWidth: 120,
              },
              {
                header: t('출역시간'),
                name: 'aTime',
                align: 'center',
                sortable: true,
                minWidth: 120,
              },
              {
                header: t('장비명'),
                name: 'fName',
                sortable: true,
                minWidth: 120,
              },
              {
                header: t('출입위치'),
                name: 'fLocationName',
                sortable: true,
                filter: 'select',
                minWidth: 120,
              },
              {
                header: t('체온'),
                name: 'temp',
                align: 'center',
                sortable: true,
                minWidth: 120,
              },
            ]
      );
    } else {
      setColumns(
        userInfo.prejobtypeYn === 'Y'
          ? [
              {
                header: t('근로자명'),
                name: 'wName',
                sortable: true,
                minWidth: 150,
                renderer: { classNames: ['text_secondary'] },
              },
              {
                header: t('협력업체'),
                name: 'sjName',
                sortable: true,
                filter: 'select',
                width: 200,
                renderer: { classNames: ['text_secondary'] },
              },
              {
                header: t('공종'),
                name: 'wPrejobtypeName',
                sortable: true,
                minWidth: 120,
              },
              {
                header: t('직종'),
                name: 'wJobtypeName',
                sortable: true,
                minWidth: 120,
              },
              {
                header: t('취업일자'),
                name: 'wJobdate',
                align: 'center',
                sortable: true,
                minWidth: 120,
              },
              {
                header: t('생년월일'),
                name: 'wBdate',
                align: 'center',
                sortable: true,
                minWidth: 120,
              },
              {
                header: t('출역일자'),
                name: 'aDate',
                align: 'center',
                sortable: true,
                filter: 'select',
                minWidth: 120,
                renderer: { classNames: ['text_secondary'] },
              },
              {
                header: t('출근시간'),
                name: 'aTimeMin',
                align: 'center',
                sortable: true,
                minWidth: 120,
                renderer: { classNames: ['text_secondary'] },
              },
              {
                header: t('퇴근시간'),
                name: 'aTimeMax',
                align: 'center',
                sortable: true,
                minWidth: 120,
                renderer: { classNames: ['text_secondary'] },
              },
              {
                header: t('최초체온'),
                name: 'tempMin',
                align: 'center',
                sortable: true,
                minWidth: 120,
              },
              {
                header: t('최종체온'),
                name: 'tempMax',
                align: 'center',
                sortable: true,
                minWidth: 120,
              },
            ]
          : [
              {
                header: t('근로자명'),
                name: 'wName',
                sortable: true,
                minWidth: 150,
                renderer: { classNames: ['text_secondary'] },
              },
              {
                header: t('협력업체'),
                name: 'sjName',
                sortable: true,
                filter: 'select',
                width: 200,
                renderer: { classNames: ['text_secondary'] },
              },
              {
                header: t('직종'),
                name: 'wJobtypeName',
                sortable: true,
                minWidth: 120,
              },
              {
                header: t('취업일자'),
                name: 'wJobdate',
                sortable: true,
                minWidth: 120,
              },
              {
                header: t('생년월일'),
                name: 'wBdate',
                align: 'center',
                sortable: true,
              },
              {
                header: t('출역일자'),
                name: 'aDate',
                align: 'center',
                sortable: true,
                filter: 'select',
                minWidth: 120,
                renderer: { classNames: ['text_secondary'] },
              },
              {
                header: t('출근시간'),
                name: 'aTimeMin',
                align: 'center',
                sortable: true,
                renderer: { classNames: ['text_secondary'] },
              },
              {
                header: t('퇴근시간'),
                name: 'aTimeMax',
                align: 'center',
                sortable: true,
                minWidth: 120,
                renderer: { classNames: ['text_secondary'] },
              },
              {
                header: t('최초체온'),
                name: 'tempMin',
                align: 'center',
                sortable: true,
                minWidth: 120,
              },
              {
                header: t('최종체온'),
                name: 'tempMax',
                align: 'center',
                sortable: true,
                minWidth: 120,
              },
            ]
      );
    }
  }, [innerTab, userInfo.prejobtypeYn, i18n.language]);

  useEffect(() => {
    if (firstApiReq) {
      getAttendDayAPI();
      getAttendDetailAPI();
      setFirstApiReq(false);
    }
  }, [firstApiReq]);

  const onClickClose = () => {
    setOpenModal((prev) => ({ ...prev, status: false }));
  };

  const onClickInnerTab = (tabIndex: number) => {
    setInnerTab(tabIndex);
  };

  // 협력업체 리스트 조회 API
  const getSjCdAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, delYnCheck: 'Y' };
    const res = await apiGet({ path: '/siteJoin', req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      setSiteJoinInfoList(
        data.siteJoinList.map((v2: any) => {
          if (filter?.sjName === v2.sjName) {
            setSearchOption((prev: any) => ({ ...prev, sjCd: v2.sjCd }));
          }
          return {
            type: 'sjCd',
            sjCd: v2.sjCd,
            name: v2.sjName,
          };
        })
      );
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  // 일 출역현황 조회 API
  const getAttendDayAPI = async () => {
    const newSearchOption = { ...filter, aDate1: filter?.startDate?.replaceAll('-', ''), aDate2: filter?.endDate?.replaceAll('-', '') };
    const { sjName, startDate, endDate, ...finalSearchObj } = newSearchOption;
    const req = { ...finalSearchObj, hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: `/attend/day`, req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      setDailyAttendState(data.attendList);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 개인별 일 출역현황 조회 API
  const getAttendDetailAPI = async () => {
    const newSearchOption = { ...filter, aDate1: filter?.startDate?.replaceAll('-', ''), aDate2: filter?.endDate?.replaceAll('-', '') };
    const { sjName, startDate, endDate, ...finalSearchObj } = newSearchOption; // searchOption에서 start, end값 삭제
    const req = { ...finalSearchObj, hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: `/attend/dayDetail`, req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      setDailyPersonalAttendState(data.attendList);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 장비 리스트 조회 API
  const getFaceSetAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/setting/faceSet', req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      const newArray: any[] = [];
      data.faceSet.map((el: any, i: number) => {
        newArray.push({ type: 'fNum', fNum: el.fNum, fName: el.fName, cdSort: i + 1, fLocation: el.fLocation, fLocationName: el.fLocationName, fInout: el.fInout });
      });
      const sortedArray: any = arraySortByAscdOrder(newArray, 'cdSort');
      setFaceSetList(sortedArray);
    } else {
      // toast.error(t(ERROR));
    }
  };

  const handlePrint = useReactToPrint({
    content: () => (innerTab === 0 ? componentRef.current : componentRef2.current),
  });

  // 인쇄 버튼 클릭
  const onClickPrint = () => {
    handlePrint();
  };

  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight, componentRef.current?.offsetWidth]);

  return (
    <ModalBackground onClick={onClickClose} role='presentation'>
      <Modal>
        <div
          className='inModal'
          role='presentation'
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className='inputForm-head flex-between'>
            <div className='innerTabChip flex-start'>
              <div className={`tab ${innerTab === 0 ? 'activeTab' : undefined}`} role='button' tabIndex={0} onClick={() => onClickInnerTab(0)}>
                {t('일 출역현황')}
              </div>
              <div className={`tab ${innerTab === 1 ? 'activeTab' : undefined}`} role='button' tabIndex={0} onClick={() => onClickInnerTab(1)}>
                {t('개인별 일 출역현황')}
              </div>
            </div>
            <div className='closeBtn' onClick={onClickClose} role='presentation'>
              <span className='material-symbols-rounded'>close</span>
            </div>
          </div>

          <div className='inputForm-body tui-body'>
            <div className='flex-between searchWrapper'>
              <div className='flex-basic'>
                <label htmlFor='listCount'>{t('보기 설정')}</label>
                <SelectBox
                  options={listCountComCdList}
                  defaultOption={listCount[LIST_COUNT]}
                  state={listCount}
                  setState={setListCount}
                  stateKey={LIST_COUNT}
                  initiateKey={listCount[LIST_COUNT]}
                  optionHeight='height-sm'
                  rsearch
                />
              </div>
              <div className='flex-end'>
                <div className='flex-basic'>
                  <label htmlFor='sjCd'>{t('협력업체')}</label>
                  <span>{filter.sjName}</span>
                </div>
                <div className='flex-basic'>
                  <label htmlFor='date'>{t('출역일자')}</label>
                  <div className='flex-basic'>
                    <span>{filter.startDate}</span>
                    <span>-</span>
                    <span>{filter.endDate}</span>
                  </div>
                </div>
                {filter.wName && (
                  <div className='flex-basic'>
                    <label htmlFor='wName'>{t('근로자명')}</label>
                    <input className='text-sm' type='text' value={filter.wName} maxLength={50} />
                  </div>
                )}
              </div>
            </div>
            <div className='modal-contents'>
              {innerTab === 0 ? (
                <div className='tuiGridWrapper tui-container' ref={componentRef}>
                  <TuiGrid data={dailyAttendState} columns={columns} perPage={Number(listCount[LIST_COUNT])} usePagenation rowHeight={48} frozenCount={1} height={tuiHeight} eListYn={eListYn} />
                </div>
              ) : (
                <div className='tuiGridWrapper tui-container' ref={componentRef2}>
                  <TuiGrid
                    data={dailyPersonalAttendState}
                    columns={columns2}
                    perPage={Number(listCount[LIST_COUNT])}
                    usePagenation
                    rowHeight={48}
                    frozenCount={1}
                    height={tuiHeight}
                    eListYn={eListYn}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </ModalBackground>
  );
};

export default AttendListModal;
