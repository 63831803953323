import { useRecoilValue } from 'recoil';
import { IUser, userState } from '../atoms';
import { useQuery } from '@tanstack/react-query';
import { apiGet, apiPost } from '../services/_common';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export const useTabletVideo = (sType: '1' | '2') => {
  const userInfo = useRecoilValue<IUser>(userState);
  const { t } = useTranslation();

  const queryKey = `getTabletVideo${sType}`;
  const dependencies = [queryKey, userInfo.hCd, userInfo.sCd, sType];
  const jobtypeVideoQuery = useQuery(dependencies, () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
  });

  const fetchData = async () => {
    try {
      const { hCd, sCd } = userInfo;
      const req = { hCd, sCd, sType };
      const res = await apiGet({ path: '/tablet/video', req });
      if (res.data.statusCode === 200) {
        const newArray = res.data.data //
          .filter(
            (el: JobtypeVideoRow) =>
              el.delYn === 'N' && //
              el.sType === sType
          );
        const addPrejobtypeYn = newArray //
          .map((el: JobtypeVideoRow) =>
            el.jobtypeIdx && //
            el.jobtypeIdx.split(',')[0].length === 4
              ? { ...el, preJobtypeYn: 'N' }
              : { ...el, preJobtypeYn: 'Y' }
          );
        return addPrejobtypeYn;
      }
      return null;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  const updateUseYn = async (rowState: any): Promise<void> => {
    const { hCd, sCd, userId } = userInfo;
    const { preJobtypeYn, eDate, wDate, ...rest } = rowState;
    const req = { ...rest, useYn: rowState.useYn === 'Y' ? 'N' : 'Y', hCd, sCd, editor: userId };
    const path = '/tablet/videofile';
    const contentType = 'multipart/form-data';
    console.log(req, 'req');
    const res = await apiPost({ path, contentType, req });
    try {
      if (res.data.statusCode === 200) {
        toast.success(t(res.data.message));
      }
    } catch (error) {
      console.error(error);
      throw new Error('error');
    } finally {
      jobtypeVideoQuery.refetch();
    }
  };

  return { jobtypeVideoQuery, updateUseYn };
};
