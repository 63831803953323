/**
 * 작성자 : 홍선영
 * 날짜 : 2023.11.24
 * 기능 : 보고서 상단 검색조건의 검색줄수 useState hook
 */

import { useEffect, useLayoutEffect, useState } from 'react';
import { useSetAuth } from './useSetAuth';
import { LIST_COUNT } from '../_constants';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IUser, pageInfoState, userState } from '../atoms';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { apiPatch } from '../services/_common';

const useSetListCount = (defaultCount?: string) => {
  const { userMenuGrant } = useSetAuth();
  const [userInfo, setUserInfo] = useRecoilState<IUser>(userState);
  const { mtCd, mdCd, mrCd } = useRecoilValue(pageInfoState);
  const [listCount, setListCount] = useState({
    type: LIST_COUNT,
    [LIST_COUNT]: defaultCount || '',
    cdName: '',
  });

  useLayoutEffect(() => {
    setListCount({ type: LIST_COUNT, [LIST_COUNT]: userMenuGrant.rSearch ? `${userMenuGrant.rSearch}` : '15', cdName: userMenuGrant.rSearch ? `${userMenuGrant.rSearch}` : '15' });
  }, [userMenuGrant]);

  // 검색줄수 설정 API
  const patchUserMenuAPI = async (el: any) => {
    const req = {
      userMenuReqDto: {
        userId: userInfo.userId,
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        gCd: userInfo.gCd,
        mtCd,
        mdCd,
        mrCd,
        useYn: 'Y',
        cYn: userMenuGrant.cYn,
        uYn: userMenuGrant.uYn,
        dYn: userMenuGrant.dYn,
        eYn: userMenuGrant.eYn,
        pYn: userMenuGrant.pYn,
        rSearch: Number(el[LIST_COUNT]),
        writer: userInfo.userId,
      },
    };
    const res = await apiPatch({ path: '/menu/usermenu', contentType: 'application/json', req });
    const { statusCode } = res.data;
    if (statusCode === 200) {
      const newUserMenuList = userInfo.userMenuList.map((v: any) => {
        const newV = { ...v };
        if (v.mtCd === mtCd && v.mdCd === mdCd && v.mrCd === mrCd) {
          newV.rSearch = Number(el[LIST_COUNT]);
        }
        return newV;
      });
      setUserInfo((prev: any) => ({ ...prev, userMenuList: newUserMenuList }));
      toast.success(t('기본값으로 설정 완료'));
    }
  };

  return { listCount, setListCount, patchUserMenuAPI };
};

export default useSetListCount;
