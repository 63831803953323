/**
 * 작성자 : 김광민
 * 날짜 : 2024.05.02
 * 기능 : 태블릿 안전교육 관리 화면에서 공통으로 사용되는 에디터 컴포넌트
 */

import styled from 'styled-components';
import { BtnBlue, BtnGhost, BtnRed } from '../../../components/Button';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IAuth } from 'customTypes';
import RadioContainer from '../../../components/button/RadioContainer';
import { usePostTabletTalk } from '../../../hooks/useTablet';
import Portal from '../../../components/Portal';
import YesOrNoConfirm from '../../../components/Modal/YesOrNo';
import InfoTextWithIcon from '../../../components/Modal/InfoTextWithIcon';
import TextareaAutosize from 'react-textarea-autosize';
import HistorySettings from '../setting12/HistorySettings';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  padding: 0.5rem;
  div {
    display: flex;
    gap: 0.5rem;
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    }
    button {
      height: 2.5rem;
      font-size: 0.875rem;
    }
  }
`;

const Root = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  overflow: hidden;
  justify-content: flex-start;
  .header {
    padding: 0.75rem;
    height: 3rem;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    .title {
      flex-shrink: 0;
      width: fit-content;
      font-weight: 600;
      margin-right: 0.5rem;
    }
    input {
      font-size: 0.875rem;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    }
  }
  .body {
    flex-grow: 1;
    overflow: hidden;
    padding: 0.5rem;
    .textareaTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.875rem;
      padding: 0.25rem;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    }
    .inputLabel {
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      font-weight: 600;
      font-size: 0.875rem;
    }
    .editor {
      width: 100%;
      border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      border-radius: 0.25rem;
      padding: 0.5rem 0.75rem;
      padding: 1rem 0.75rem;
      resize: none;
      font-size: 1rem;
      font-size: 0.875rem;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      :focus {
        outline: 0.125rem solid ${({ theme }: { theme: any }) => theme.filled_sky};
        outline: 2px solid #3699ff;
      }
    }
    .bigo {
      margin-top: 0.5rem;
    }
    input {
      font-size: 0.875rem;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    }
    .history {
      margin-top: 1.5rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem 0.75rem;
    }
  }
`;

interface Props {
  auth: IAuth;
  selectedIndex: number | null;
  setSelectedIndex: Dispatch<SetStateAction<number | null>>;
  data: TabletTalk[];
  refetch: () => void;
  tGubun: number;
  setWarning: Dispatch<SetStateAction<boolean>>;
}

const AllimtalkEditor = ({ auth, selectedIndex, setSelectedIndex, data, refetch, tGubun, setWarning }: Props) => {
  const { t } = useTranslation();
  const initInputs = { tTitle: '', tContents: '', tBigo: '', useYn: 'N' };
  const [inputs, setInputs] = useState<Record<string, string>>(initInputs);
  const [openModal, setOpenModal] = useState<{ status: boolean; type: string }>({
    status: false,
    type: 'confirm',
  });

  const postTabletTalk = usePostTabletTalk();

  // 목록에서 선택한 행을 form data에 적용
  useEffect(() => {
    if (selectedIndex !== null && data[selectedIndex]) {
      setInputs({
        tTitle: data[selectedIndex].tTitle,
        tContents: data[selectedIndex].tContents,
        useYn: data[selectedIndex].useYn,
        tBigo: data[selectedIndex].tBigo,
      });
    } else {
      setInputs(initInputs);
    }
  }, [selectedIndex, data]);

  // 알림톡 저장 버튼 활성화 여부 체크
  useEffect(() => {
    setWarning(!isDisabledInit());
  }, [inputs]);

  // 알림톡 내용 수정 함수
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // 알림톡 삭제/초기화 버튼 클릭 함수
  const onClickDelete = async () => {
    if (selectedIndex === null) {
      setInputs(initInputs);
    } else {
      const requestData = data[selectedIndex];
      const res = await postTabletTalk({ ...requestData, delYn: 'Y', tGubun });
      if (res) {
        setSelectedIndex(null);
        refetch();
      }
    }
  };

  // 알림톡 저장 비동기 함수
  const updateTabletTemplete = async (el: TabletTalk) => {
    const res = await postTabletTalk(el);
    if (res) {
      refetch();
    }
  };

  // 저장 버튼 클릭 함수
  const onClickSave = async () => {
    // 현재 수정/작성 중인 알림톡의 사용유무 값이 'Y'인 경우
    // 저장 전 모든 알림톡 미사용 상태로 변경
    if (inputs.useYn === 'Y') {
      data.map(
        (el) =>
          el.useYn === 'Y' && //
          updateTabletTemplete({ ...el, useYn: 'N', tGubun })
      );
    }

    // 1. 현재 작성중인 양식 객체화
    const init = {
      tGubun,
      tTitle: inputs.tTitle,
      tContents: inputs.tContents,
      tBigo: inputs.tBigo,
      useYn: inputs.useYn,
      delYn: 'N',
    };

    // 2. 새 알림톡인지 기존 알림톡인지 구분해서 데이터 가공
    const formData =
      selectedIndex === null
        ? init
        : {
            ...init, //
            tCd: data[selectedIndex].tCd,
            writer: data[selectedIndex].writer,
          };

    // 3. 알림톡 저장 함수 호출 및 목록 새로고침
    const res = await postTabletTalk(formData);
    if (res) refetch();
  };

  const isDisabledInit = () => {
    if (selectedIndex !== null && data[selectedIndex]) {
      const comparison =
        inputs.tTitle === data[selectedIndex].tTitle && //
        inputs.tContents === data[selectedIndex].tContents &&
        inputs.tBigo === data[selectedIndex].tBigo &&
        inputs.useYn === data[selectedIndex].useYn;
      return comparison;
    }
    const comparison =
      inputs.tTitle.length === 0 || //
      inputs.tContents.length === 0;
    return comparison || (inputs.tTitle === '' && inputs.tContents === '' && inputs.useYn === 'N');
  };

  return (
    <Root>
      <div className='header'>
        <span className='title'>{t('필독 제목')}</span>
        <input
          type='text' //
          id='tTitle'
          name='tTitle'
          value={inputs.tTitle}
          onChange={(e) => onChange(e)}
          disabled={!auth.createAuth && !auth.updateAuth}
          maxLength={50}
        />
        <RadioContainer
          name={t('사용')} //
          onClick={() => setInputs({ ...inputs, useYn: 'Y' })}
          isSelected={inputs.useYn === 'Y'}
        />
        <RadioContainer
          name={t('미사용')} //
          onClick={() => setInputs({ ...inputs, useYn: 'N' })}
          isSelected={inputs.useYn === 'N'}
        />
      </div>
      {selectedIndex === null && (
        <InfoTextWithIcon
          icon='flash_on' //
          text={t('필독 제목과 내용을 모두 입력해주세요.')}
        />
      )}
      <div className='body'>
        <div className='textareaTitle'>
          <span className='inputLabel'>{t('필독 내용')}</span>
          <span>{`${t('글자 수')} (${inputs.tContents.length}/500)`}</span>
        </div>
        <TextareaAutosize
          className='editor'
          maxLength={500} //
          minRows={8}
          maxRows={16}
          name={inputs.tContents}
          onChange={(e) => setInputs({ ...inputs, tContents: e.target.value })}
          value={inputs.tContents}
        />
        <div className='bigo'>
          <span className='inputLabel'>{t('비고')}</span>
          <input
            type='text' //
            id='tBigo'
            name='tBigo'
            value={inputs.tBigo}
            onChange={(e) => onChange(e)}
            disabled={!auth.createAuth && !auth.updateAuth}
            maxLength={50}
          />
        </div>
        {selectedIndex !== null && (
          <div className='history'>
            <HistorySettings //
              data={data[selectedIndex]?.wDate}
              labelName={t('등록일시')}
            />
            <HistorySettings //
              data={data[selectedIndex]?.writer}
              labelName={t('등록자')}
            />
            <HistorySettings //
              data={data[selectedIndex]?.eDate}
              labelName={t('수정일시')}
            />
            <HistorySettings //
              data={data[selectedIndex]?.editor}
              labelName={t('수정자')}
            />
          </div>
        )}
      </div>
      <ButtonsWrapper>
        <div>
          {selectedIndex !== null &&
            auth.deleteAuth && ( //
              <BtnRed onClick={() => setOpenModal({ status: true, type: 'confirm' })}>{t('삭제')}</BtnRed>
            )}
        </div>
        <div className={isDisabledInit() ? 'disabled' : ''}>
          <BtnGhost
            onClick={() => {
              if (selectedIndex === null) {
                setSelectedIndex(null);
                setInputs(initInputs);
              } else {
                setInputs({
                  tTitle: data[selectedIndex].tTitle,
                  tContents: data[selectedIndex].tContents,
                  useYn: data[selectedIndex].useYn,
                  tBigo: data[selectedIndex].tBigo,
                });
              }
            }} //
          >
            {t('초기화')}
          </BtnGhost>
          {(auth.createAuth || auth.updateAuth) && ( //
            <BtnBlue onClick={onClickSave}>저장</BtnBlue>
          )}
        </div>
      </ButtonsWrapper>
      <Portal openModal={openModal?.status}>
        {openModal && openModal.type === 'confirm' && (
          <YesOrNoConfirm
            mainText={t('알림톡을 정말 삭제하시겠습니까?')}
            onClickClose={() => setOpenModal({ status: false, type: 'confirm' })} //
            onClickConfirm={() => {
              onClickDelete();
              setOpenModal({ status: false, type: 'confirm' });
            }}
          />
        )}
      </Portal>
    </Root>
  );
};

export default AllimtalkEditor;
