/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.30
 * 경로 : 터널 모달 > 굴진/일반정보 모듈
 */

import styled from 'styled-components';
import SearchSelectBoxSm from '../SearchSelectBoxSm';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useState } from 'react';

const SelectBoxInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 8rem;
  justify-content: space-between;
  font-weight: 400;
  background-color: ${({ theme }: { theme: any }) => theme.board};
  padding: 0.75rem;
  border-radius: 0.5rem;
  outline: 1px solid ${({ theme }: { theme: any }) => theme.tonal_deep};
  width: calc(50% - 0.25rem);
  @media (min-width: 1024px) {
    padding: 0.875rem 1rem;
  }
  .title {
    display: flex;
    align-items: center;
    gap: 0.375rem;

    label {
      font-size: 0.875rem;
    }
  }
  .material-symbols-outlined {
    font-size: 1.125rem;
    border-radius: 2rem;
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
  }
  .expand-box-md {
    max-width: none !important;
    width: 100% !important;
  }
  &.hovered {
    outline: 1px solid ${({ theme }: { theme: any }) => theme.outline_em};
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
`;

type Props = {
  text: string;
  options: any[];
  defaultOption: string;
  state: any;
  setState: Dispatch<SetStateAction<any>>;
  stateKey: string;
  initiateKey: string;
};

const SelectBoxInfo = ({ text, options, defaultOption, state, setState, stateKey, initiateKey }: Props) => {
  const [isHovered, setIsHovered] = useState(false);

  const { t } = useTranslation();
  return (
    <SelectBoxInfoContainer onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} className={isHovered ? 'hovered' : ''}>
      <div className='title'>
        <span className='material-symbols-outlined'>{text === '작업내용' ? 'construction' : 'front_loader'}</span>
        <label htmlFor='eTjcCd'>{t(text)}</label>
      </div>

      <SearchSelectBoxSm
        options={options}
        defaultOption={defaultOption || t('미선택')}
        state={state}
        setState={setState}
        stateKey={stateKey}
        codeKey='cdName'
        initiateKey={initiateKey}
        optionHeight='height-sm'
        comboWidth='expand-box-md'
        onClick={() => setIsHovered(false)}
      />
    </SelectBoxInfoContainer>
  );
};

export default SelectBoxInfo;
