import styled from 'styled-components';
import Pagination from './Pagination';
import BottomLeftOptions from './BottomLeftOptions';
import BottomRightOptions from './BottomRightOptions';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { WebSDKcctv } from '../../../../utils/webSDKcctvClass';
import Portal from '../../../../components/Portal';
import WebSdkAlertModal from '../../../../components/Modal/WebSdkAlertModal';
import { toast } from 'react-toastify';
import LeftedToast from '../../../../components/cctv/LeftedToast';

const Root = styled.div`
  height: 3rem;
  width: 100%;
  background-color: ${({ theme }: { theme: any }) => theme.color.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
`;

type Props = {
  visiblePanel: boolean;
  setVisiblePanel: Dispatch<SetStateAction<boolean>>;
  cctvOBJ: WebSDKcctv | undefined;
  onClickDivision: (number: number) => void;
  divisionCount: number;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  onClickSetStream: (value: '1' | '2' | '3') => void;
  onClickRemoveScreen: (value: 'selected' | 'onScreen' | 'all') => void;
  // onClickRecord: (value: 'on' | 'off') => void;
  isRecording: boolean;
  onClickRefresh: () => void;
  onClickRecord: (value: boolean) => void;
  selectedCameraInfo: SelectedCctvCameraInfo | null;
  playingCamStatus: any[];
  camPlayLoading: boolean;
  setCamPlayLoading: Dispatch<SetStateAction<boolean>>;
};

const BottomMenu = ({
  visiblePanel, //
  setVisiblePanel,
  cctvOBJ,
  onClickDivision,
  divisionCount,
  currentPage,
  setCurrentPage,
  onClickSetStream,
  onClickRemoveScreen,
  isRecording,
  onClickRefresh,
  onClickRecord,
  selectedCameraInfo,
  playingCamStatus,
  camPlayLoading,
  setCamPlayLoading,
}: Props) => {
  const [openModal, setOpenModal] = useState({ status: false, msg: '녹화를 종료한 후에 시도하세요' });

  // 모달(녹화중카메라 알림) 여부에 따라 카메라윈도우 알럿창 show/hide
  useEffect(() => {
    if (cctvOBJ?.pluginOBJ !== undefined) {
      openModal.status ? cctvOBJ.hideWnd() : cctvOBJ.showWnd();
    }
  }, [openModal.status, cctvOBJ]);

  // 녹화 안내 확인 모달 창 띄우기 함수
  const getOpenModal = () => {
    setOpenModal((prev: { status: boolean; msg: string }) => ({ ...prev, status: true }));
  };

  // 녹화중인지 아닌지 확인 후 함수 실행 여부 확인 함수
  const validateOnClick = (key: 'division' | 'stream' | 'pagination' | 'remove' | 'refresh', value?: any) => {
    setCamPlayLoading(true);
    if (isRecording) {
      return getOpenModal();
    }
    switch (key) {
      case 'division':
        return onClickDivision(value);
      case 'stream':
        return onClickSetStream(value);
      case 'pagination':
        return setCurrentPage(value);
      case 'remove':
        return onClickRemoveScreen(value);
      case 'refresh':
        return onClickRefresh();
      default:
        return null;
    }
  };

  // cctvObj가 undefined일 경우 null 반환
  if (cctvOBJ === undefined) return null;

  return (
    <Root>
      <BottomLeftOptions
        visiblePanel={visiblePanel} //
        setVisiblePanel={setVisiblePanel}
        cctvOBJ={cctvOBJ}
        onClickDivision={
          (number) => validateOnClick('division', number) //
        }
        divisionCount={divisionCount}
        onClickSetStream={(stream) => validateOnClick('stream', stream)}
        camPlayLoading={camPlayLoading}
      />
      <Pagination
        divisionCount={divisionCount} //
        currentPage={currentPage}
        setCurrentPage={
          (prevPage) => validateOnClick('pagination', prevPage) //
        }
        disabled={camPlayLoading}
      />
      <BottomRightOptions
        cctvOBJ={cctvOBJ} //
        onClickRemoveScreen={
          (value: 'selected' | 'onScreen' | 'all') => validateOnClick('remove', value) //
        }
        isRecording={isRecording}
        onClickRefresh={
          () => validateOnClick('refresh') //
        }
        onClickRecord={onClickRecord}
        selectedCameraInfo={selectedCameraInfo}
        playingCamStatus={playingCamStatus}
        disabled={camPlayLoading}
      />
      <Portal openModal={openModal.status}>
        {openModal.status && (
          <WebSdkAlertModal // 녹화중인 카메라가 있음을 알리는 모달
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        )}
      </Portal>
    </Root>
  );
};

export default BottomMenu;
