/**
 * 작성자 : 한영광
 * 날짜 : 2023.06.30
 * 경로 : 안전관리 - 안전교육
 */

import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { IoChevronUpSharp } from 'react-icons/io5';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { LIST_COUNT } from '../../../_constants';
import { IComCdList, ITabObject } from 'customTypes';
import { IUser, pageInfoState, userState } from '../../../atoms';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { FloatingButtonToTop } from '../../../assets/styles/FloatingButtonToTop';
import RangePicker, { onChangeRangeInput } from '../../../components/RangePicker';
import { BtnGhost } from '../../../components/Button';
import Input from '../../../components/Input';
import Portal from '../../../components/Portal';
import SafeEduModal from '../../../components/Modal/safeEduModal';
import { todayYYYYMMDD } from '../../../utils/formatDate';
import { scrollToNodeTop } from '../../../utils/scrollToNodeTop';
import { setComCdListState } from '../../../utils/setComCdListState';
import { useDetectScrolledToBottom } from '../../../utils/useDetectScrolledToBottom';
import useOnKeydownF9 from '../../../utils/useOnKeydownF9';
import { logPost } from '../../../services/log';
import { useSetAuth } from '../../../utils/useSetAuth';
import { trimObject } from '../../../utils/trimObject';
import TuiGrid from '../../../components/Table/TuiGrid';
import SelectBox from '../../../components/SelectBox';
import i18n from '../../../translation/i18n';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import TuiGridWrapper from '../../../components/Table/TuiGridWrapper';
import { useGetFrozenCount } from '../../../utils/getFrozenCount';
import { ContentsContainerRoot } from '../../../assets/styles/ContentsContainerRoot';
import useSetListCount from '../../../utils/useSetListCount';
import { apiGet } from '../../../services/_common';
import ShortcutButton from '../../../components/button/ShortcutButton';

const Root = styled(ContentsContainerRoot)`
  display: flex;
  flex-direction: column;
`;

const Safeedu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const INIT_EDU = { type: 'sCode', sCode: 'A', cdName: t('전체') };
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const { listCount, setListCount, patchUserMenuAPI } = useSetListCount(); // 검색줄수설정 훅
  const tabInfo = useRecoilValue(pageInfoState);
  const toDay = todayYYYYMMDD();
  const [userInfo, setUserInfo] = useRecoilState<IUser>(userState);
  const { userMenuList } = userInfo;
  const [tableState, setTableState] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [sCodeList, setScodeList] = useState<any[]>([]);
  const size = useOutletContext<any>();
  const sTeacherRef = useRef<HTMLInputElement>(null);
  const sPlaceRef = useRef<HTMLInputElement>(null);
  const { defaultMrCd } = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(defaultMrCd);
  const tableRef = useRef<HTMLDivElement>(null);
  const [visibleRangePicker, setVisibleRangePicker] = useState(false);
  const [rowKey, setRowKey] = useState('');
  const [rangeState, setRangeState] = useState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
  const [listCountComCdList, setListCountComCdList] = useState<IComCdList[]>([]); // 검색줄수 공통코드
  const [searchOption, setSearchOption] = useState({ start: toDay, end: toDay, sPlace: '', sTeacher: '' });
  const [sCode, setSCode] = useState(INIT_EDU);
  const [excelBtn, setExcelBtn] = useState(false);
  const { isBottom } = useDetectScrolledToBottom(tableRef);
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '', code: { sSeq: '' } });
  const componentRef = useRef<HTMLDivElement>(null);
  const [newTabList, setNewTabList] = useState<ITabObject[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '안전 교육 > 안전교육',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    const arr: any[] = [];
    userMenuList.map((v: any) => {
      const result = tabInfo?.tabList?.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  useEffect(() => {
    setColumns([
      {
        header: t('교육 구분'),
        name: 'sName',
        align: 'left',
        sortable: true,
        filter: 'select',
        width: 200,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('교육 일자'),
        name: 'sDate',
        align: 'center',
        sortable: true,
        filter: 'select',
        minWidth: 120,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('교육 시작시간'),
        name: 'sStime',
        align: 'center',
        sortable: true,
        minWidth: 120,
      },
      {
        header: t('교육 종료시간'),
        name: 'sEtime',
        align: 'center',
        sortable: true,
        minWidth: 120,
      },
      {
        header: t('교육 장소'),
        name: 'sPlace',
        align: 'left',
        sortable: true,
        minWidth: 120,
      },
      {
        header: t('강사'),
        name: 'sTeacher',
        align: 'left',
        sortable: true,
        minWidth: 120,
      },
      {
        header: t('등록일자'),
        name: 'wDate',
        align: 'center',
        sortable: true,
        minWidth: 120,
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    setLoading(true);
    getSafeEdutAPI().then((res: any) => {
      if (res.status === 200) {
        getScodeAPI();
        setComCdListState(LIST_COUNT, setListCountComCdList, false);
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '안전 교육',
          action: '조회',
          etc: ``,
        });
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    if (!rangeState[0].startDate) {
      return;
    }
    setSearchOption((prev: any) => ({ ...prev, start: dayjs(rangeState[0].startDate).format('YYYY-MM-DD') }));
  }, [rangeState[0].startDate]);

  useEffect(() => {
    if (!rangeState[0].endDate) {
      return;
    }
    setSearchOption((prev: any) => ({ ...prev, end: dayjs(rangeState[0].endDate).format('YYYY-MM-DD') }));
  }, [rangeState[0].endDate]);

  // 안전교육 정보 리스트 조회 API
  const getSafeEdutAPI = async () => {
    const newSearchOption = { ...searchOption, sCode: sCode.sCode === 'A' ? '' : sCode.sCode, sDate1: searchOption.start.replaceAll('-', ''), sDate2: searchOption.end.replaceAll('-', '') };
    const { start, end, ...finalSearchObj } = newSearchOption; // searchOption에서 start, end값 삭제
    const trimData = trimObject(finalSearchObj);
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, ...trimData };
    const res = await apiGet({ path: '/safe/edut', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      setLoading(false);
      setTableState(
        data.edut.map((v: any) => {
          return {
            ...v,
            sStime: `${v.sStime.substring(0, 2)}:${v.sStime.substring(2)}`,
            sEtime: `${v.sEtime.substring(0, 2)}:${v.sEtime.substring(2)}`,
          };
        })
      );
    } else {
      setLoading(false);
      // toast.error(t(ERROR));
    }
    return res;
  };

  // 교육구분 리스트 조회 API
  const getScodeAPI = async () => {
    const req = {
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      subCd: 'F',
    };
    const res = await apiGet({ path: '/code/pmsNormalTitle', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const newNormalCdList: any[] = [];
      newNormalCdList.push(INIT_EDU);
      data.pmsNormalTitleList.map((v: any) => {
        newNormalCdList.push({
          type: 'sCode',
          sCode: v.subCd,
          cdName: v.cdName,
        });
      });
      setScodeList(newNormalCdList);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 검색 버튼 클릭
  const onClickSearch = () => {
    setRowKey('');
    if (!openModal.status) {
      if (searchOption.end && searchOption.start > searchOption.end) {
        toast.warning(t('날짜를 다시 입력하세요'));
        setSearchOption((prev: any) => ({ ...prev, end: '' }));
      } else {
        setLoading(true);
        setVisibleRangePicker(false);
        navigate({ pathname: location.pathname, search: '' });
        getSafeEdutAPI();
      }
    }
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    Object.keys(searchOption).map((el: any) => {
      return setSearchOption((prev: any) => ({ ...prev, [el]: '', start: toDay, end: toDay }));
    });
    setSCode(INIT_EDU);
    setRangeState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
  };

  // 신규 등록 버튼 클릭
  const onClickNewWorker = () => {
    setOpenModal({ status: true, type: 'safeEdu', code: { sSeq: 0 } }); // 신규 등록시 순번0
  };

  // 엑셀 저장 버튼 클릭
  const onClickExcelSave = () => {
    if (tableState.length <= 0) return;
    setExcelBtn(true);
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '안전 교육',
      action: '엑셀 저장',
      etc: ``,
    });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  // 인쇄 버튼 클릭
  const onClickPrint = () => {
    handlePrint();
  };

  // 근로자 Row 클릭
  const onClickRow = (rowData: any, columnName: any, filteredIndex: number) => {
    setRowKey(`${filteredIndex}`);
    setOpenModal({ status: true, type: 'safeEdu', code: { sSeq: rowData.sSeq } });
  };

  const renderCreateBtn = () => {
    if (auth.createAuth) {
      return (
        <BtnGhost onClick={onClickNewWorker}>
          <span className='material-symbols-rounded'>add</span> {t('추가')}
        </BtnGhost>
      );
    }
    return null;
  };

  const renderExcelBtn = () => {
    if (auth.excelAuth) {
      return (
        <BtnGhost onClick={onClickExcelSave}>
          <span className='icon-ms-xlsx'>X</span> {t('엑셀')}
        </BtnGhost>
      );
    }
    return null;
  };

  const renderPrintBtn = () => {
    if (auth.printAuth) {
      return (
        <BtnGhost onClick={onClickPrint}>
          <span className='material-symbols-rounded'>print</span>
          {t('인쇄')}
        </BtnGhost>
      );
    }
    return null;
  };

  // 강사명 입력창에서 엔터입력시 검색
  const loginOnEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (sTeacherRef.current) onClickSearch();
      if (sPlaceRef.current) onClickSearch();
    }
  };

  const onClickRangeInput = () => {
    if (!visibleRangePicker) setVisibleRangePicker(true);
  };

  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight, componentRef.current?.offsetWidth]);

  const frozenCount = useGetFrozenCount();

  return (
    <>
      <div className='content-container oneColumn'>
        <Root loading={loading}>
          <SearchOptions>
            {isBottom && (
              <FloatingButtonToTop>
                <button type='button' onClick={() => scrollToNodeTop(tableRef)}>
                  <IoChevronUpSharp size={20} style={{ stroke: 'white' }} />
                </button>
              </FloatingButtonToTop>
            )}
            <div className='inputsWrapper'>
              <div className='inputForm-col withLabelComCf'>
                <label htmlFor='sCode'>{t('교육 구분')}</label>
                <SelectBox
                  options={sCodeList}
                  defaultOption={t('전체')}
                  state={sCode}
                  setState={setSCode}
                  stateKey='sCode'
                  initiateKey={sCode.sCode}
                  filterbar='filter-1-left'
                  optionHeight='height-md'
                />
              </div>
              <div className='inputForm-row-fit'>
                <span className='calendarLabel'>{t('교육 일자')}</span>
                <div className='inputForm-col'>
                  <div className='flex-basic'>
                    <input id='startInput' type='text' value={searchOption.start} onMouseDown={onClickRangeInput} onChange={(e) => onChangeRangeInput(e, 'start', setRangeState, setSearchOption)} />
                    <span className='inputDash'> ~ </span>
                    <input id='endInput' type='text' value={searchOption.end} onMouseDown={onClickRangeInput} onChange={(e) => onChangeRangeInput(e, 'end', setRangeState, setSearchOption)} />
                  </div>
                  {visibleRangePicker && (
                    <div className='rangePickerWrapper'>
                      <RangePicker state={rangeState} setState={setRangeState} setVisible={setVisibleRangePicker} />
                    </div>
                  )}
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <Input
                    placeholder={t('교육 장소')}
                    label=''
                    type='text'
                    id='sPlace'
                    name='sPlace'
                    state={searchOption}
                    setState={setSearchOption}
                    inputRef={sPlaceRef}
                    onKeyDown={loginOnEnterKeyDown}
                  />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <Input
                    placeholder={t('강사명')}
                    label=''
                    type='text'
                    id='sTeacher'
                    name='sTeacher'
                    state={searchOption}
                    setState={setSearchOption}
                    inputRef={sTeacherRef}
                    onKeyDown={loginOnEnterKeyDown}
                  />
                </div>
              </div>
            </div>
            <div className='inputsWrapper'>
              <div className='secondSearchOption'>
                <div className='flex-basic textBtnGroup'>
                  <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
                  {/* <BtnGhost onClick={onClickSearch} className='searchBtn'>
                    {t('검색')}
                    <span className='shortcut-f9'>F9</span>
                  </BtnGhost> */}
                  <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
                  <div className='searchResult'>
                    {t('총')}
                    <span>{tableState.length}</span>
                    {t('개')}
                  </div>
                  <div className='inputForm-row'>
                    <div className='inputForm-col withLabelComCf'>
                      <label htmlFor='useYn'>{t('보기 설정')}</label>
                      <SelectBox
                        options={listCountComCdList}
                        defaultOption={listCount[LIST_COUNT]}
                        state={listCount}
                        setState={setListCount}
                        stateKey={LIST_COUNT}
                        initiateKey={listCount[LIST_COUNT]}
                        setTableLinesAPI={patchUserMenuAPI}
                        optionHeight='height-sm'
                        rsearch
                      />
                    </div>
                  </div>
                </div>
                <div className='flex-basic iconBtnGroup'>
                  {renderCreateBtn()}
                  {renderExcelBtn()}
                  {/* {renderPrintBtn()} */}
                </div>
              </div>
            </div>
          </SearchOptions>
          <TuiGridWrapper componentRef={componentRef}>
            <TuiGrid
              data={tableState}
              columns={columns}
              perPage={Number(listCount[LIST_COUNT])}
              excelBtn={excelBtn}
              setExcelBtn={setExcelBtn}
              usePagenation
              onClickRow={onClickRow}
              rowKey={rowKey}
              scrollX
              height={tuiHeight}
              frozenCount={frozenCount}
            />
          </TuiGridWrapper>
        </Root>
      </div>
      {loading && (
        <LoadingModalBackground>
          <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
        </LoadingModalBackground>
      )}
      <Portal openModal={openModal?.status}>
        {openModal.status && openModal.type === 'safeEdu' && (
          <SafeEduModal getReportListAPI={getSafeEdutAPI} setOpenModal={setOpenModal} auth={auth} code={{ hCd: userInfo.hCd, sCd: userInfo.sCd, sSeq: openModal.code.sSeq }} />
        )}
      </Portal>
    </>
  );
};

export default Safeedu;
