/**
 * 작성자 : 김광민
 * 날짜 : 2024.02.02
 * 경로 : 구역별 상황판 > 유해가스 종합현황 (원형, 수치형)
 */

import styled from 'styled-components';
import { v4 } from 'uuid';
import PointIcon from '../../tunnel/PointIcon';

const Root = styled.div`
  width: 50%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  .row.header {
    flex-grow: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 0.25rem;
    align-items: center;
    max-height: 2.5rem;
    height: 2.5rem;
    .name {
      font-size: 0.875rem;
      font-weight: 400;
      padding: 0.5rem 0.75rem;
      border-radius: 1rem;
      color: ${({ theme }: { theme: any }) => theme.text_reverse};
      line-height: 1.25rem;
      display: flex;
      align-items: center;
      gap: 0.375rem;
      height: 1.5rem;
      justify-content: center;
      margin-right: 1rem;
    }
    .nameOnly {
      font-size: 0.875rem;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    }
    .material-symbols-rounded {
      font-variation-settings: 'FILL' 0, 'wght' 600, 'GRAD' 0, 'opsz' 24;
      font-size: 1.125rem;
      height: 1.25rem;
    }
    &.startPoint {
      .name {
        background-color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
      }
      .material-symbols-rounded {
        color: ${({ theme }: { theme: any }) => theme.filled_blue};
      }
    }
    &.endPoint {
      .name {
        background-color: ${({ theme }: { theme: any }) => theme.filled_amber_deep};
      }
      .material-symbols-rounded {
        color: ${({ theme }: { theme: any }) => theme.filled_amber};
      }
    }
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    .textLight {
      text-align: center;
      min-width: 3rem;
      font-size: 0.875rem;
      padding: 0 0.25rem;
      border-radius: 0.25rem;
      color: ${({ theme }: { theme: any }) => theme.text_disabled};
    }
    .blue {
      color: ${({ theme }: { theme: any }) => theme.filled_blue};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
    }
    .red {
      color: ${({ theme }: { theme: any }) => theme.filled_red};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
    }
    .violet {
      color: ${({ theme }: { theme: any }) => theme.filled_violet};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_violet};
    }
    .orange {
      color: ${({ theme }: { theme: any }) => theme.filled_orange};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
    }
    .green {
      color: ${({ theme }: { theme: any }) => theme.filled_green};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
    }
    .amber {
      color: ${({ theme }: { theme: any }) => theme.filled_amber};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_amber};
    }
    .slate {
      color: ${({ theme }: { theme: any }) => theme.filled_slate};
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
    }
    .removeBackground {
      background-color: transparent;
    }
    .font-large {
      font-size: 1.375rem;
    }
  }
`;

type TareaListProps = {
  list: any;
  sensorList: any;
  valueType: 'shape' | 'number';
  title?: string;
};

const TareaData = ({ list, sensorList, valueType, title }: TareaListProps) => {
  const isStartPoint = list.tatGubun === 's';
  const isShapeType = valueType === 'shape';

  const isShapeValue = (level: string) => ['0', '1', '2', '3'].includes(level);
  // 레벨 값이 0, 1, 2, 3 중 하나인지 여부
  const isDataValue = (data: string | null) => data === 'NC' || data === null;
  // 데이터 값이 NC, null 중 하나인지 여부

  const getValue = (props: any) => {
    const levelProp = `level${props}`;
    const dataProp = `data${props}`;

    if (isShapeType) {
      // valueType이 shape일 때
      return isShapeValue(list[levelProp]) ? '●' : '-';
      // level 값이 0, 1, 2, 3 중 하나일 때 ●, 아닐 때 -
    }
    // valueType이 number일 때
    return isDataValue(list[dataProp]) || isNaN(Number(list[dataProp])) ? '-' : Number(list[dataProp]).toLocaleString();
    // 데이터 값이 NC, null, NaN일 때 -
  };

  const getClassName = (levelValue: string) => {
    const colorMap: { [key: string]: string } = { '0': 'blue', '1': 'green', '2': 'amber', '3': 'red' };
    return colorMap[levelValue] || '';
  };

  const setStyle = (sensor: any) => {
    const defaultStyle = 'textLight';
    const color = getClassName(list[`level${sensor.etc1}`]);
    const removeBackground = isShapeType ? 'removeBackground' : '';
    const fontSize = isShapeType && getValue(sensor.etc1) !== '-' ? 'font-large' : '';
    return `${defaultStyle} ${color} ${removeBackground} ${fontSize}`;
  };

  const renderName = () => {
    if (!title) {
      return (
        <>
          {isStartPoint ? <PointIcon isStart /> : <PointIcon isStart={false} />}
          <span className='name'>{isStartPoint ? '시점' : '종점'}</span>
        </>
      );
    }
    return <span className='nameOnly'>{title}</span>;
  };

  return (
    <Root>
      <div className={`row header ${isStartPoint ? 'startPoint' : 'endPoint'}`}>{renderName()}</div>
      {sensorList.map((sensor: any) => (
        <div key={v4()} className='row'>
          <span className={setStyle(sensor)}>{getValue(sensor.etc1)}</span>
        </div>
      ))}
    </Root>
  );
};

export default TareaData;
