/**
 * 작성자 : 홍선영
 * 날짜 : 2024.03.26
 * 기능 : 화면 왼쪽에 뜨는 모달창
 */

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { IModal } from 'customTypes';
import { ModalBackground } from '../../assets/styles/Modal';
import ButtonContainer from '../../pages/s_cctv/S_cctv1/RealtimeCCTV/ButtonContainer';

interface Props {
  openModal: any;
  setOpenModal: any;
}

const LeftedModalStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  width: 16rem;
  margin-left: 2rem;
  margin-bottom: 12rem;
  background-color: ${({ theme }: { theme: any }) => theme.tonal};
  border-radius: 5px;
  padding: 0.5rem;

  .flexColumn {
    gap: 0.5rem;
  }

  > button {
    padding: 1rem;
    margin: 1rem;
    align-self: end;
  }
`;

const LeftedModalBookmark = ({ openModal, setOpenModal }: Props) => {
  const { t } = useTranslation();

  const onClickClose = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };

  const onClickOption = (optionValue: string) => {
    openModal.setDataFunction(optionValue);
  };
  return (
    <ModalBackground onClick={onClickClose} role='presentation' className='leftedModal'>
      <LeftedModalStyle>
        <div>{t('즐겨찾기 선택')}</div>
        <div className='flexColumn'>
          {(openModal.gCd === 'MM' || openModal.gCd === 'SM') && <ButtonContainer icon='counter_0' name='통합 즐겨찾기' onClick={() => onClickOption('0')} />}
          <ButtonContainer icon='counter_1' name='즐겨찾기 1' onClick={() => onClickOption('1')} />
          <ButtonContainer icon='counter_2' name='즐겨찾기 2' onClick={() => onClickOption('2')} />
          <ButtonContainer icon='counter_3' name='즐겨찾기 3' onClick={() => onClickOption('3')} />
          <ButtonContainer icon='counter_4' name='즐겨찾기 4' onClick={() => onClickOption('4')} />
          <ButtonContainer icon='counter_5' name='즐겨찾기 5' onClick={() => onClickOption('5')} />
        </div>
      </LeftedModalStyle>
    </ModalBackground>
  );
};

export default LeftedModalBookmark;
