/**
 * 작성자 : 김광민
 * 날짜 : 2024.02.19
 * 기능 : 터널 관리 > 방송 장비 정보
 */

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Root = styled.button`
  height: 2.25rem;
  background-color: ${({ theme }: { theme: any }) => theme.board};
  outline: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  border-radius: 0.25rem;
  color: ${({ theme }: { theme: any }) => theme.text_tertiary};
  padding: 0.375rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;
  font-size: 0.875rem;
  user-select: none;
  .material-symbols-rounded {
    font-size: 1.5rem;
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
  }
  .buttonLabel {
    margin-right: 0.25rem;
  }
  &:hover {
    background-color: ${({ theme }: { theme: any }) => theme.board};
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  }
  &:active {
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
  }
  &.nonClick {
    pointer-events: none;
    cursor: initial;
  }
`;

type Props = {
  onClick?: () => void;
  icon: string;
  text: string;
};

const VariableButton = ({ onClick, icon, text }: Props) => {
  const { t } = useTranslation();
  const buttonLabel = t(text);
  return (
    <Root type='button' onClick={onClick} className={onClick ? '' : 'nonClick'}>
      <span className='material-symbols-rounded'>{icon}</span>
      <span className='buttonLabel'>{buttonLabel}</span>
    </Root>
  );
};

export default VariableButton;
