/**
 * 작성자 : 홍선영
 * 날짜 : 2024.05.16
 * 경로 : 안전관리 - 근로자 안전교육 의견/건의사항
 */

import styled from 'styled-components';
import { ContentsContainerRoot } from '../../../assets/styles/ContentsContainerRoot';
import IssueGuide from '../../../components/IssueGuide';
import { useSetAuth } from '../../../utils/useSetAuth';
import { useRecoilValue } from 'recoil';
import { IUser, userState } from '../../../atoms';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import useSetListCount from '../../../utils/useSetListCount';
import SafeeduInquirySearchOption from './safeeduInquirySearchOption';
import i18n from '../../../translation/i18n';
import TuiGridWrapper from '../../../components/Table/TuiGridWrapper';
import TuiGrid from '../../../components/Table/TuiGrid';
import { LIST_COUNT } from '../../../_constants';
import { useGetFrozenCount } from '../../../utils/getFrozenCount';
import dayjs from 'dayjs';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import { PulseLoader } from 'react-spinners';
import Portal from '../../../components/Portal';
import InfoWorkerModal from '../../../components/Modal/InfoWorkerModal';
import CustomAudioPlayBtnRenderer from '../../../components/CustomAudioPlayBtnRenderer';
import { logPost } from '../../../services/log';

const Root = styled(ContentsContainerRoot)`
  .btn-in-cell {
    height: 2rem;
    font-size: 0.875rem;
    padding-left: 0.5rem;
    padding-right: 0.75rem;
    margin: 0 auto;
    font-weight: 600;
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.board};
    }
    .material-symbols-rounded {
      margin-right: 0.25rem;
    }
  }
  .btn-blue {
    background-color: ${({ theme }: { theme: any }) => theme.filled_blue} !important;
    border: none !important;
    color: ${({ theme }: { theme: any }) => theme.color.blue_100} !important;
  }
  .btn-gray {
    background-color: ${({ theme }: { theme: any }) => theme.text_disabled} !important;
    border: none !important;
    color: ${({ theme }: { theme: any }) => theme.text_reverse} !important;
  }
`;

const SafeeduInquiry = () => {
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const userInfo = useRecoilValue<IUser>(userState);
  const { eExcelYn, eListYn } = userInfo;
  const { t } = useTranslation();
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [tableState, setTableState] = useState<any[]>([]);
  const [filterTableState, setFilterTableState] = useState<any[]>([]);
  const componentRef = useRef<HTMLDivElement>(null);
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  const [tuiWidth, setTuiWidth] = useState<null | number>(null);
  const [columns, setColumns] = useState<any[]>([]);
  const [excelBtn, setExcelBtn] = useState(false);
  const [rowKey, setRowKey] = useState('');
  const [checkList, setCheckList] = useState([]); // 헤더체크된 로우리스트
  const { listCount, setListCount } = useSetListCount('15'); // 검색줄수설정 훅
  const frozenCount = useGetFrozenCount();
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '', code: '', index: 0 });
  const [refetchFlag, setRefetchFlag] = useState(false);
  const [audioObj, setAudioObj] = useState<HTMLAudioElement | null>(null);

  useEffect(() => {
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '안전 교육 > 근로자 안전교육 의견/건의사항',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    if (componentRef.current !== null) setTuiHeight(componentRef.current.offsetHeight);
  }, [componentRef.current?.offsetHeight]);

  useEffect(() => {
    if (componentRef.current !== null && componentRef.current !== undefined) setTuiWidth(componentRef.current?.offsetWidth);
  }, [componentRef.current?.offsetWidth]);

  // 근로자 Row 클릭
  const onClickRow = (rowData: any, columnName: any, filteredIndex: number) => {
    setRowKey(`${filteredIndex}`);
    setOpenModal({ status: true, type: 'infoWorker', code: rowData?.wCd, aDate: dayjs().format('YYYY-MM-DD'), index: filteredIndex });
  };

  // 근로자정보 모달에서 정보 수정 시 flag를 true로 변경하고 refetch하여 tuigrid 목록 재조회
  const onClickRefresh = () => {
    setRefetchFlag(true);
  };

  useEffect(() => {
    setColumns(
      userInfo.prejobtypeYn === 'Y'
        ? [
            {
              header: t('근로자명'),
              name: 'wName',
              sortable: true,
              minWidth: 130,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('협력업체'),
              name: 'sjName',
              sortable: true,
              filter: 'select',
              className: '',
              width: 160,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('공종'),
              name: 'wPrejobtypeName',
              sortable: true,
              filter: 'select',
              minWidth: 120,
            },
            {
              header: t('직종'),
              name: 'wJobtypeName',
              sortable: true,
              filter: 'select',
              minWidth: 120,
            },
            {
              header: t('휴대폰번호'),
              name: 'wHnum',
              align: 'center',
              minWidth: 140,
            },
            {
              header: t('생년월일'),
              name: 'wBdate',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('취업일자'),
              name: 'wJobdate',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('퇴직일자'),
              name: 'wRetiredate',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('취업일자'),
              name: 'wJobdate',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('근로 상태'),
              name: 'wWorkstatusName',
              align: 'center',
              sortable: true,
              filter: 'select',
              minWidth: 120,
            },
            {
              header: '재생',
              align: 'center',
              name: 'button',
              renderer: { type: CustomAudioPlayBtnRenderer, options: { onClickPlayBtn, onClickStopBtn, auth, t } },
              minWidth: 60,
            },
          ]
        : [
            {
              header: t('근로자명'),
              name: 'wName',
              sortable: true,
              minWidth: 130,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('협력업체'),
              name: 'sjName',
              sortable: true,
              filter: 'select',
              width: 200,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('직종'),
              name: 'wJobtypeName',
              sortable: true,
              filter: 'select',
              minWidth: 120,
            },
            {
              header: t('휴대폰번호'),
              name: 'wHnum',
              align: 'center',
              minWidth: 140,
            },
            {
              header: t('생년월일'),
              name: 'wBdate',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('취업일자'),
              name: 'wJobdate',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('퇴직일자'),
              name: 'wRetiredate',
              align: 'center',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('근로 상태'),
              name: 'wWorkstatusName',
              align: 'center',
              sortable: true,
              filter: 'select',
              minWidth: 120,
            },
            {
              header: '재생',
              align: 'center',
              name: 'button',
              renderer: { type: CustomAudioPlayBtnRenderer, options: { onClickPlayBtn, onClickStopBtn, auth, t } },
              minWidth: 60,
            },
          ]
    );
  }, [userInfo.prejobtypeYn, i18n.language, tableState]);

  const onClickCheckBox = (list: any) => {
    setCheckList(list);
  };

  // const onClickPlayBtn = (rKey: number) => {
  //   const targetRow = filterTableState[rKey] || tableState[rKey];
  //   if (targetRow) {
  //     const audioPath = targetRow.recordFile;
  //     const audio = new Audio(audioPath);
  //     audio
  //       .play()
  //       .then(() => {
  //         console.log('Audio is playing');
  //       })
  //       .catch((e) => {
  //         console.error('Failed to play audio:', e);
  //       });
  //   }
  // };

  // Assuming audioObj is stored at a higher scope, maybe in React's state if using functional components
  const onClickPlayBtn = (rKey: number) => {
    const targetRow = filterTableState[rKey] || tableState[rKey];
    if (targetRow) {
      const audioPath = targetRow.recordFile;

      // Check if we're dealing with the same audio file
      if (audioObj && !audioObj.paused) {
        // If audio is playing, pause it
        audioObj.pause();
      }
      // Stop previous audio if it exists and is different
      if (audioObj) {
        audioObj.pause();
        audioObj.currentTime = 0; // Reset the playback position
      }
      // Create a new Audio object and play it
      const newAudioObj = new Audio(audioPath);
      newAudioObj
        .play()
        .then(() => {
          // console.log('Audio is playing');
          const newTabletState = tableState.map((row: any) => (row.rowKey === rKey ? { ...row, playing: true } : { ...row, playing: false }));
          setTableState(newTabletState);
          setAudioObj(newAudioObj);

          const handleAudioEnded = () => {
            onClickStopBtn(rKey);
          };

          if (newAudioObj) {
            newAudioObj.addEventListener('ended', handleAudioEnded);
          }

          // Cleanup function to remove the event listener
          return () => {
            if (newAudioObj) {
              newAudioObj.removeEventListener('ended', handleAudioEnded);
            }
          };
        })
        .catch((e) => {
          console.error('Failed to play audio:', e);
        });
    }
  };

  const onClickStopBtn = (rKey: number) => {
    const targetRow = filterTableState[rKey] || tableState[rKey];
    if (targetRow) {
      audioObj?.pause();
      const newTabletState = tableState.map((row: any) => {
        return { ...row, playing: false };
      });
      setTableState(newTabletState);
    }
  };

  const loading = isFetching;

  if (isError) return <IssueGuide />;

  return (
    <>
      <div className='content-container oneColumn'>
        <Root loading={loading}>
          <SafeeduInquirySearchOption
            setIsFetching={setIsFetching}
            setIsError={setIsError}
            tableState={tableState}
            setTableState={setTableState}
            filterTableState={filterTableState}
            setFilterTableState={setFilterTableState}
            setRowKey={setRowKey}
            checkList={checkList}
            listCount={listCount}
            setListCount={setListCount}
            refetchFlag={refetchFlag}
            setRefetchFlag={setRefetchFlag}
          />
          <TuiGridWrapper componentRef={componentRef}>
            {tuiWidth !== null && (
              <TuiGrid
                data={tableState}
                filterTableState={filterTableState}
                setFilterTableState={setFilterTableState}
                columns={columns}
                perPage={Number(listCount[LIST_COUNT])}
                excelBtn={excelBtn}
                setExcelBtn={setExcelBtn}
                usePagenation
                onClickCheckBox={onClickCheckBox}
                onClickRow={onClickRow}
                scrollX
                height={tuiHeight}
                rowKey={rowKey}
                eListYn={eListYn}
                eExcelYn={eExcelYn}
                rowHeight={48}
                frozenCount={frozenCount}
                setRowKey={setRowKey}
              />
            )}
          </TuiGridWrapper>
        </Root>
      </div>
      {loading && (
        <LoadingModalBackground>
          <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
        </LoadingModalBackground>
      )}
      <Portal openModal={openModal?.status}>
        {openModal.status && openModal.type === 'infoWorker' && openModal.code !== undefined && (
          <InfoWorkerModal
            innerTabIndex={0}
            getReportListAPI={onClickRefresh}
            reportArray={filterTableState}
            setReportArray={setFilterTableState}
            setOpenModal={setOpenModal}
            auth={auth}
            code={{ hCd: userInfo.hCd, sCd: userInfo.sCd, wCd: openModal.code, aDate: openModal.aDate }}
            index={openModal.index}
            setRowKey={setRowKey}
            listCount={Number(listCount[LIST_COUNT])}
          />
        )}
      </Portal>
    </>
  );
};

export default SafeeduInquiry;
