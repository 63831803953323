/**
 *  2023.04.28 한영광
 *  마스터일반관리 -> 사용자관리 (pages/m_common/common2/headInfo.tsx) 페이지에서 사용
 */
import { IModal } from 'customTypes';
import { BtnGhost, BtnRed } from '../Button';
import { ModalBackground, Modal } from '../../assets/styles/Modal';
import { useTranslation } from 'react-i18next';

const DeleteModal = ({ openModal, setOpenModal, state }: any) => {
  const { t } = useTranslation();

  // 닫기버튼 클릭
  const onClickClose = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };

  const onClickDelete = () => {
    openModal.api();
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };

  return (
    <ModalBackground>
      <Modal>
        <div className='modal delete'>
          <div className='inputForm-head'>{t(`정말 ${openModal.type === 'delete' ? '삭제' : '초기화'} 하시겠습니까?`)}</div>
          <div className='inputForm-body'>
            <div className='modal-footer flex-end'>
              <BtnGhost onClick={onClickClose}>{t('아니요')}</BtnGhost>
              <BtnRed onClick={onClickDelete}>{t('네')}</BtnRed>
            </div>
          </div>
        </div>
      </Modal>
    </ModalBackground>
  );
};

export default DeleteModal;
