/**
 * 작성자 : 홍선영
 * 날짜 : 2023.12.19
 * 기능 : 터널 관리-굴진/일반 정보-굴진/일반 정보 팝업-굴진 모달 (현장용)
 */

import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { userState } from '../../atoms';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import {
  TAREA_INNERTAB_ATTEND,
  TAREA_INNERTAB_CCTV,
  TAREA_INNERTAB_LOCATION,
  TAREA_INNERTAB_MGAS,
  TAREA_INNERTAB_NORMAL,
  TAREA_INNERTAB_EQUIP,
  TAREA_INNERTAB_REALTIME_ATTEND,
} from '../../_constants';
import * as cctv from '../../utils/cctv';
import useOnKeydownF9 from '../../utils/useOnKeydownF9';
import useOnKeydownPgUp from '../../utils/useOnKeydownPgUp';
import useOnKeydownPgDn from '../../utils/useOnKeydownPgDn';
import { IAuth, IModal } from 'customTypes';
import { ModalBackground } from '../../assets/styles/Modal';
import { TareaNormalInfo } from '../TareaNormalInfo';
import { TareaAttendInfo } from '../TareaAttendInfo';
import TareaEquipInfo from '../TareaEquipInfo';
import TareaCCTVInfo from '../TareaCCTVInfo';
import { TareaMGasInfo } from '../TareaMGasInfo';
import { TareaModalStyle } from '../../assets/styles/TareaModal';
import { TareaLocationInfo } from '../TareaLocationInfo';

interface IProps {
  setOpenModal: Dispatch<SetStateAction<IModal>>;
  auth: IAuth;
  activeTab: number;
  data: TareaData;
  dataList: TareaData[];
  setDataList?: Dispatch<SetStateAction<TareaData[]>>;
  index: number;
  showPrevNextBtn: boolean;
  pointType?: 'S' | 'E';
  selectedTadCd?: string;
}

const TareaInfoModal = ({ setOpenModal, auth, activeTab, data, dataList, index, setDataList, showPrevNextBtn = true, pointType, selectedTadCd }: IProps) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const [innerTab, setInnerTab] = useState(activeTab);
  const [rowState, setRowState] = useState(data);
  const [selectedIndex, setSelectedIndex] = useState(index);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  // 저장버튼 클릭시 필수입력값 보더색상 처리하기 위한 state 값
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9();
  // f9키 프레스 훅
  const { isPgUpPressed, setIsPgUpPressed } = useOnKeydownPgUp();
  const { isPgDnPressed, setIsPgDnPressed } = useOnKeydownPgDn();
  const tabs = [
    { id: TAREA_INNERTAB_NORMAL, name: t('굴진/일반 정보') },
    { id: TAREA_INNERTAB_ATTEND, name: t('근로자 출역 정보') },
    { id: TAREA_INNERTAB_REALTIME_ATTEND, name: t('실시간 잔류 현황') },
    { id: TAREA_INNERTAB_LOCATION, name: t('근로자/장비 위치 정보') },
    { id: TAREA_INNERTAB_MGAS, name: t('유해 가스 정보') },
    { id: TAREA_INNERTAB_CCTV, name: t('CCTV 정보') },
    { id: TAREA_INNERTAB_EQUIP, name: t('방송 장비 정보') },
  ];

  // F9, PgUp, PgDn 키 이벤트
  useEffect(() => {
    if (isF9Pressed) {
      setIsF9Pressed(false);
    }
    if (isPgUpPressed) {
      onClickPrev();
      setIsPgUpPressed(false);
    }
    if (isPgDnPressed) {
      onClickNext();
      setIsPgDnPressed(false);
    }
  }, [isF9Pressed, isPgUpPressed, isPgDnPressed]);

  // 모달 닫기
  const onClickClose = () => {
    if (cctv.getPluginOBJECT()?.oPlugin) {
      cctv.destroy();
    }
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };

  // selectedIndex 값 또는 해당로우데이터 변경시(수정한 경우) rowState 변경
  useEffect(() => {
    setRowState(dataList[selectedIndex] as TareaData);
  }, [selectedIndex, dataList[selectedIndex]]);

  // CCTV 아닌 탭 클릭 시 cctv.destroy() 호출
  useEffect(() => {
    if (innerTab !== TAREA_INNERTAB_CCTV && cctv.getPluginOBJECT()?.oPlugin) {
      cctv.destroy();
    }
  }, [innerTab]);

  // 이전 버튼 클릭
  const onClickPrev = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    } else toast.warning(t('이전 내역이 없습니다'));
  };

  // 다음 버튼 클릭
  const onClickNext = () => {
    if (selectedIndex < dataList.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    } else toast.warning(t('다음 내역이 없습니다'));
  };

  // 터널관리 모달 content 화면 렌더링
  const renderContent = () => {
    const defaultTab = (
      <TareaNormalInfo
        rowState={rowState}
        userInfo={userInfo}
        onClickPrev={onClickPrev}
        onClickNext={onClickNext}
        auth={auth}
        isSaveClicked={isSaveClicked}
        setDataList={setDataList}
        showPrevNextBtn={showPrevNextBtn}
      />
    );

    switch (innerTab) {
      case TAREA_INNERTAB_NORMAL:
        // 굴진/일반 정보
        return defaultTab;
      case TAREA_INNERTAB_ATTEND:
        // 근로자 출역 정보
        return (
          <TareaAttendInfo
            innerTabIndex={TAREA_INNERTAB_ATTEND}
            selectedIndex={selectedIndex}
            data={rowState}
            userInfo={userInfo}
            onClickPrev={onClickPrev}
            onClickNext={onClickNext}
            showPrevNextBtn={showPrevNextBtn}
          />
        );
      case TAREA_INNERTAB_REALTIME_ATTEND:
        // 실시간 잔류 현황
        return (
          <TareaAttendInfo
            innerTabIndex={TAREA_INNERTAB_REALTIME_ATTEND}
            selectedIndex={selectedIndex}
            data={rowState}
            userInfo={userInfo}
            onClickPrev={onClickPrev}
            onClickNext={onClickNext}
            showPrevNextBtn={showPrevNextBtn}
          />
        );
      case TAREA_INNERTAB_LOCATION:
        // 근로자/장비 위치 정보
        return (
          <TareaLocationInfo
            rowState={rowState}
            userInfo={userInfo}
            onClickPrev={onClickPrev}
            onClickNext={onClickNext}
            showPrevNextBtn={showPrevNextBtn}
            pointType={pointType}
            selectedTadCd={selectedTadCd}
          />
        );
      case TAREA_INNERTAB_MGAS:
        // 유해 가스 정보
        return <TareaMGasInfo data={rowState} onClickPrev={onClickPrev} onClickNext={onClickNext} showPrevNextBtn={showPrevNextBtn} />;
      case TAREA_INNERTAB_CCTV:
        // CCTV 정보
        return <TareaCCTVInfo rowState={rowState as TareaData} userInfo={userInfo} onClickPrev={onClickPrev} onClickNext={onClickNext} auth={auth} showPrevNextBtn={showPrevNextBtn} />;
      case TAREA_INNERTAB_EQUIP:
        // 방송 장비 정보
        return <TareaEquipInfo rowState={rowState} userInfo={userInfo} onClickPrev={onClickPrev} onClickNext={onClickNext} auth={auth} showPrevNextBtn={showPrevNextBtn} />;
      default:
        // 굴진/일반 정보
        return defaultTab;
    }
  };

  return (
    <ModalBackground onClick={onClickClose} role='presentation'>
      <TareaModalStyle>
        <div
          className='modal height-fit'
          role='presentation'
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className='inputForm-head flex-between'>
            <div className='innerTabChip flex-start'>
              {tabs.map((tab) => (
                <div key={tab.id} className={`tab ${innerTab === tab.id ? 'activeTab' : ''}`} role='button' tabIndex={0} onClick={() => setInnerTab(tab.id)}>
                  {tab.name}
                </div>
              ))}
            </div>
            <div className='closeBtn' onClick={onClickClose} role='presentation'>
              <span className='material-symbols-rounded'>close</span>
            </div>
          </div>
          {renderContent()}
        </div>
      </TareaModalStyle>
    </ModalBackground>
  );
};

export default TareaInfoModal;

// interface IProps {
//   setOpenModal: Dispatch<SetStateAction<IModal>>;
//   auth: IAuth;
//   activeTab: number;
//   data: TunnelType;
//   dataList: TunnelType[];
//   setDataList?: Dispatch<SetStateAction<TunnelType[]>>;
//   index: number;
//   showPrevNextBtn: boolean;
// }

// const TareaInfoModal = ({ setOpenModal, auth, activeTab, data, dataList, index, setDataList, showPrevNextBtn = true }: IProps) => {
//   const { t } = useTranslation();
//   const userInfo = useRecoilValue(userState);
//   const [innerTab, setInnerTab] = useState(activeTab);
//   const [rowState, setRowState] = useState(data);
//   const [selectedIndex, setSelectedIndex] = useState(index);
//   const [isSaveClicked, setIsSaveClicked] = useState(false);
//   // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
//   const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9();
//   // f9키 프레스 훅
//   const { isPgUpPressed, setIsPgUpPressed } = useOnKeydownPgUp();
//   const { isPgDnPressed, setIsPgDnPressed } = useOnKeydownPgDn();
//   const tabs = [
//     { id: TAREA_INNERTAB_NORMAL, name: t('굴진/일반 정보') },
//     { id: TAREA_INNERTAB_ATTEND, name: t('근로자 출역 정보') },
//     { id: TAREA_INNERTAB_REALTIME_ATTEND, name: t('실시간 잔류 현황') },
//     { id: TAREA_INNERTAB_LOCATION, name: t('근로자/장비 위치 정보') },
//     { id: TAREA_INNERTAB_MGAS, name: t('유해 가스 정보') },
//     { id: TAREA_INNERTAB_CCTV, name: t('CCTV 정보') },
//     { id: TAREA_INNERTAB_EQUIP, name: t('방송 장비 정보') },
//   ];

//   // F9, PgUp, PgDn 키 이벤트
//   useEffect(() => {
//     if (isF9Pressed) {
//       setIsF9Pressed(false);
//     }
//     if (isPgUpPressed) {
//       onClickPrev();
//       setIsPgUpPressed(false);
//     }
//     if (isPgDnPressed) {
//       onClickNext();
//       setIsPgDnPressed(false);
//     }
//   }, [isF9Pressed, isPgUpPressed, isPgDnPressed]);

//   // 모달 닫기
//   const onClickClose = () => {
//     if (cctv.getPluginOBJECT()?.oPlugin) {
//       cctv.destroy();
//     }
//     setOpenModal((prev: IModal) => ({ ...prev, status: false }));
//   };

//   // selectedIndex 값 변경시 rowState 변경
//   useEffect(() => {
//     setRowState(dataList[selectedIndex]);
//   }, [selectedIndex]);

//   // CCTV 아닌 탭 클릭 시 cctv.destroy() 호출
//   useEffect(() => {
//     if (innerTab !== TAREA_INNERTAB_CCTV && cctv.getPluginOBJECT()?.oPlugin) {
//       cctv.destroy();
//     }
//   }, [innerTab]);

//   // 이전 버튼 클릭
//   const onClickPrev = () => {
//     if (selectedIndex > 0) {
//       setSelectedIndex(selectedIndex - 1);
//     } else toast.warning(t('이전 내역이 없습니다'));
//   };

//   // 다음 버튼 클릭
//   const onClickNext = () => {
//     if (selectedIndex < dataList.length - 1) {
//       setSelectedIndex(selectedIndex + 1);
//     } else toast.warning(t('다음 내역이 없습니다'));
//   };

//   // 터널관리 모달 content 화면 렌더링
//   const renderContent = () => {
//     // 굴진/일반 정보
//     const TareaNormalInfo = React.lazy(() => import('../TareaNormalInfo').then((module) => ({ default: module.TareaNormalInfo })));
//     // 근로자 출역 정보
//     const TareaAttendInfo = React.lazy(() => import('../TareaAttendInfo').then((module) => ({ default: module.TareaAttendInfo })));
//     // 실시간 잔류 현황
//     const TareaRealtimeAttendInfo = React.lazy(() => import('../TareaRealtimeAttendInfo').then((module) => ({ default: module.TareaRealtimeAttendInfo })));
//     // 근로자/장비 위치 정보
//     const TareaLocationInfo = React.lazy(() => import('../TareaLocationInfo').then((module) => ({ default: module.TareaLocationInfo })));
//     // 유해 가스 정보
//     const TareaMGasInfo = React.lazy(() => import('../TareaMGasInfo').then((module) => ({ default: module.TareaMGasInfo })));
//     // CCTV 정보
//     const TareaCCTVInfo = React.lazy(() => import('../TareaCCTVInfo').then((module) => ({ default: module.TareaCCTVInfo })));
//     // 방송 장비 정보
//     const TareaEquipInfo = React.lazy(() => import('../TareaEquipInfo').then((module) => ({ default: module.TareaEquipInfo })));

//     // defaultTab : 굴진/일반 정보
//     const defaultTab = (
//       <React.Suspense fallback={<div>Loading...</div>}>
//         <TareaNormalInfo
//           rowState={rowState}
//           userInfo={userInfo}
//           onClickPrev={onClickPrev}
//           onClickNext={onClickNext}
//           auth={auth}
//           isSaveClicked={isSaveClicked}
//           setDataList={setDataList}
//           showPrevNextBtn={showPrevNextBtn}
//         />
//       </React.Suspense>
//     );

//     // 탭 클릭 시 해당 화면 렌더링
//     switch (innerTab) {
//       case TAREA_INNERTAB_NORMAL:
//         // 굴진/일반 정보
//         return defaultTab;
//       case TAREA_INNERTAB_ATTEND:
//         // 근로자 출역 정보
//         return <TareaAttendInfo selectedIndex={selectedIndex} data={rowState} userInfo={userInfo} onClickPrev={onClickPrev} onClickNext={onClickNext} showPrevNextBtn={showPrevNextBtn} />;
//       case TAREA_INNERTAB_REALTIME_ATTEND:
//         // 실시간 잔류 현황
//         return (
//           <React.Suspense fallback={<div>Loading...</div>}>
//             <TareaRealtimeAttendInfo selectedIndex={selectedIndex} data={rowState} userInfo={userInfo} onClickPrev={onClickPrev} onClickNext={onClickNext} showPrevNextBtn={showPrevNextBtn} />
//           </React.Suspense>
//         );

//       case TAREA_INNERTAB_LOCATION:
//         // 근로자/장비 위치 정보
//         return (
//           <React.Suspense fallback={<div>Loading...</div>}>
//             <TareaLocationInfo selectedIndex={selectedIndex} data={rowState} userInfo={userInfo} onClickPrev={onClickPrev} onClickNext={onClickNext} showPrevNextBtn={showPrevNextBtn} />
//           </React.Suspense>
//         );
//       case TAREA_INNERTAB_MGAS:
//         // 유해 가스 정보
//         return (
//           <React.Suspense fallback={<div>Loading...</div>}>
//             <TareaMGasInfo data={rowState} onClickPrev={onClickPrev} onClickNext={onClickNext} showPrevNextBtn={showPrevNextBtn} />
//           </React.Suspense>
//         );
//       case TAREA_INNERTAB_CCTV:
//         // CCTV 정보
//         return (
//           <React.Suspense fallback={<div>Loading...</div>}>
//             <TareaCCTVInfo rowState={rowState} userInfo={userInfo} onClickPrev={onClickPrev} onClickNext={onClickNext} auth={auth} showPrevNextBtn={showPrevNextBtn} />
//           </React.Suspense>
//         );
//       case TAREA_INNERTAB_EQUIP:
//         // 방송 장비 정보
//         return (
//           <React.Suspense fallback={<div>Loading...</div>}>
//             <TareaEquipInfo rowState={rowState} userInfo={userInfo} onClickPrev={onClickPrev} onClickNext={onClickNext} auth={auth} showPrevNextBtn={showPrevNextBtn} />
//           </React.Suspense>
//         );
//       default:
//         // 굴진/일반 정보
//         return defaultTab;
//     }
//   };

//   return (
//     <ModalBackground onClick={onClickClose} role='presentation'>
//       <TareaModalStyle>
//         <div
//           className='modal height-fit'
//           role='presentation'
//           onClick={(event) => {
//             event.stopPropagation();
//           }}
//         >
//           <div className='inputForm-head flex-between'>
//             <div className='innerTabChip flex-start'>
//               {tabs.map((tab) => (
//                 <div key={tab.id} className={`tab ${innerTab === tab.id ? 'activeTab' : ''}`} role='button' tabIndex={0} onClick={() => setInnerTab(tab.id)}>
//                   {tab.name}
//                 </div>
//               ))}
//             </div>
//             <div className='closeBtn' onClick={onClickClose} role='presentation'>
//               <span className='material-symbols-rounded'>close</span>
//             </div>
//           </div>
//           {renderContent()}
//         </div>
//       </TareaModalStyle>
//     </ModalBackground>
//   );
// };

// export default TareaInfoModal;
