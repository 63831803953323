/**
 * 작성자 : 홍선영
 * 날짜 : 2023.04.18
 * 기능 : 마스터관리-대시보드관리 페이지
 */

import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

import { pageInfoState, userState } from '../../atoms';
import { IModal } from 'customTypes';
import Input from '../../components/Input';
import SelectBoxs from '../../components/SelectBoxs';
import Portal from '../../components/Portal';
import DeleteModal from '../../components/Modal/DeleteModal';
import { InputTable } from '../../assets/styles/InputTable';
import { BtnBlue, BtnGhost, BtnRed } from '../../components/Button';
import { FLAG_DELETE, USE_DYN, USE_YN, FLAG_CREATE_OR_UPDATE, ERROR } from '../../_constants';
import { trimArray } from '../../utils/trimArray';
import { formatDate } from '../../utils/formatDate';
import { scrollToNodeBottom } from '../../utils/scrollToNodeBottom';
import { SearchOptions } from '../../assets/styles/SearchOptions';
import Tab from '../../components/Tab';
import { logPost } from '../../services/log';
import { applyBorderStyle } from '../../utils/applyBorderStyle';
import { scrollToNodeTop } from '../../utils/scrollToNodeTop';
import { useTranslation } from 'react-i18next';
import SelectBox from '../../components/SelectBox';
import { apiDelete, apiGet, apiPost } from '../../services/_common';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 0.5rem;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;
const Root = styled.div`
  display: flex;
  flex-direction: column;
  .tbody > .tr > div.selector {
    > div {
      width: 100%;
      > div > ul > li {
        width: 100%;
        > span {
          width: 100%;
        }
      }
    }
  }
  .textBtnGroup button {
    padding: 0 0.75rem;
    width: 4.5rem;
    height: 2.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
  }
`;

interface IDashboard {
  index: number;
  dCd: string;
  dName: string;
  dPname: string;
  dIfilename: string;
  huseYn: string;
  suseYn: string;
  buseYn: string;
  bigo: string;
  useYn: string;
  delYn: string;
  writer: string;
  wDate: string;
  editor: string;
  eDate: string;
  flag: string;
}

interface ISearchOption {
  dName: string;
  bigo: string;
}

type UseYn = {
  type: string;
  useYn: string;
  cdName: string;
};

const Master4 = () => {
  const { t } = useTranslation();
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  const userInfo = useRecoilValue(userState);
  const scrollContainerRef = useRef<HTMLInputElement>(null);
  const [orgList, setOriList] = useState<any[]>([]);
  const [rawData, setRawData] = useState<any[]>([]);
  const [tableState, setTableState] = useState<any[]>([]);
  const [searchOption, setSearchOption] = useState<ISearchOption>({ dName: '', bigo: '' });
  const [HuseYn, setHuseYn] = useState({ type: 'huseYn', huseYn: 'A', cdName: t('전체') });
  const [SuseYn, setSuseYn] = useState({ type: 'suseYn', suseYn: 'A', cdName: t('전체') });
  const [BuseYn, setBuseYn] = useState({ type: 'buseYn', buseYn: 'A', cdName: t('전체') });
  const [TuseYn, setTuseYn] = useState({ type: 'tuseYn', tuseYn: 'A', cdName: t('전체') });
  const [useYn, setUseYn] = useState({ type: 'useYn', useYn: 'A', cdName: t('전체') });

  const [newRowIndex, setNewRowIndex] = useState<number>(0); // 신규항목 추가 클릭했을 때 새 로우객체에 인덱스추가
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const [isTableInitialRender, setIsTableInitialRender] = useState(true); // 컴포넌트의 이니셜렌더 여부
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  //! initKey : 검색조건으로 필터링 된 상태에서 데이터를 수정하면 필터링 되서 해당 로우가 사라지면서 같은 인덱스를 갖게된 데이터가 바뀌는 오류 수정을 위해 저장 후 초기화 시키는 상태값
  const [initKey, setInitKey] = useState(true);
  const [huseDynList, setHuseDynList] = useState<any[]>([]);
  const [suseDynList, setSuseDynList] = useState<any[]>([]);
  const [buseDynList, setBuseDynList] = useState<any[]>([]);
  const [tuseDynList, setTuseDynList] = useState<any[]>([]);
  const [huseYnList, setHuseYnList] = useState<any[]>([]);
  const [suseYnList, setSuseYnList] = useState<any[]>([]);
  const [buseYnList, setBuseYnList] = useState<any[]>([]);
  const [tuseYnList, setTuseYnList] = useState<any[]>([]);

  useEffect(() => {
    setHuseDynList(USE_DYN.map((el: UseYn) => ({ type: 'huseYn', huseYn: el.useYn, cdName: el.cdName })));
    setSuseDynList(USE_DYN.map((el: UseYn) => ({ type: 'suseYn', suseYn: el.useYn, cdName: el.cdName })));
    setBuseDynList(USE_DYN.map((el: UseYn) => ({ type: 'buseYn', buseYn: el.useYn, cdName: el.cdName })));
    setTuseDynList(USE_DYN.map((el: UseYn) => ({ type: 'tuseYn', tuseYn: el.useYn, cdName: el.cdName })));

    setHuseYnList(USE_YN.map((el: UseYn) => ({ type: 'huseYn', huseYn: el.useYn, cdName: el.cdName })));
    setSuseYnList(USE_YN.map((el: UseYn) => ({ type: 'suseYn', suseYn: el.useYn, cdName: el.cdName })));
    setBuseYnList(USE_YN.map((el: UseYn) => ({ type: 'buseYn', buseYn: el.useYn, cdName: el.cdName })));
    setTuseYnList(USE_YN.map((el: UseYn) => ({ type: 'tuseYn', tuseYn: el.useYn, cdName: el.cdName })));
  }, []);

  useEffect(() => {
    setInitKey(!initKey);
  }, [tableState]);

  useEffect(() => {
    //  로우가 1개 이상이고, 이니셜렌더가 아닌경우 스크롤을 하단으로 이동
    if (tableState.length > 0 && !isTableInitialRender) scrollToNodeBottom(scrollContainerRef);
  }, [tableState.length]);

  // 뷰테이블 값이 수정될때
  const onChangeTableState = (e: React.ChangeEvent<HTMLInputElement>, i: number, el: IDashboard) => {
    const { name, value } = e.currentTarget;
    const viewDataArray = [...tableState]; // 뷰테이블 로우 어레이
    const rawDataArray: any = [...rawData]; // 데이터테이블 로우 어레이

    let findIndex;
    if (el.dCd !== '') {
      // 새로운 로우가 아닐때, 데이터테이블에서 해당하는 로우의 인덱스값 찾기
      findIndex = rawData.findIndex((rawEl: IDashboard) => rawEl.dCd === el.dCd);
    } else {
      // 새로운 로우일때 인덱스값
      findIndex = el.index;
    }

    // 신규로우 수정하는 경우
    viewDataArray[i] = { ...viewDataArray[i], [name]: value, flag: FLAG_CREATE_OR_UPDATE }; // 뷰테이블 로우 업데이트
    if (findIndex !== -1) {
      // 데이터테이블 로우 업데이트
      rawDataArray[findIndex] = { ...rawDataArray[findIndex], [name]: value, flag: FLAG_CREATE_OR_UPDATE, editor: userInfo.userId };
    }

    setTableState(viewDataArray);
    setRawData(rawDataArray);
  };

  // 로우 추가 클릭
  const onClickAddTableRow = () => {
    setNewRowIndex((prev) => prev + 1);

    const data = {
      index: newRowIndex,
      dCd: '',
      dName: '',
      dPname: '',
      dCol: '',
      dRow: '',
      cdSort: '',
      huseYn: 'N',
      suseYn: 'N',
      buseYn: 'N',
      tuseYn: 'N',
      bigo: '',
      useYn: 'Y',
      writer: userInfo.userId,
      editor: userInfo.userId,
      flag: FLAG_CREATE_OR_UPDATE,
    };
    setTableState((prev: IDashboard[]) => [...prev, data]);
    setRawData((prev: IDashboard[]) => [...prev, data]);
    setIsTableInitialRender(false);
  };

  useEffect(() => {
    getDashboardList().then((res: any) => {
      if (res.status === 200) {
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: 'DashBoard 관리',
          action: '조회',
          etc: ``,
        });
      }
    });
  }, []);

  const getDashboardList = async () => {
    const res = await apiGet({ path: '/dashboard' });
    const { data, message, statusCode } = res.data;
    if (statusCode === 200) {
      setTableState(data.DashboardSetList);
      setRawData(data.DashboardSetList);
      setOriList(data.DashboardSetList);
      setNewRowIndex(data.DashboardSetList.length);
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  const deleteCodeAPI = async (el: any) => {
    const req = {
      dCd: el.dCd,
      editor: el.editor,
    };
    const res = await apiDelete({ path: '/dashboard', contentType: 'application/json', req });
    const { message, statusCode } = res.data;
    if (statusCode === 200) {
      setOpenModal((prev) => ({ ...prev, status: false }));
      await logPost({ hCd: userInfo.hCd, sCd: userInfo.sCd, userId: userInfo.userId, menu: '마스터관리-DashBoard관리', action: 'DashBoard 삭제', etc: `${el.dCd} ${el.dName} 삭제` });
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 로우 삭제 클릭
  const onClickDelete = (el: IDashboard, i: number) => {
    // 새로 추가한 로우 제거 클릭 시
    if (el.dCd === '') {
      // 뷰테이블 로우 삭제
      const updatedArray = [...tableState];
      updatedArray.splice(i, 1);
      setTableState(updatedArray);

      // 데이터테이블 로우 삭제 (플래그 D로 업데이트)
      const findIndex = rawData.findIndex((rawEl: IDashboard) => rawEl.index === el.index);
      const rawDataArray: any = [...rawData];
      if (findIndex !== -1) {
        rawDataArray[findIndex] = {
          ...rawDataArray[findIndex],
          flag: FLAG_DELETE,
        };
        setRawData(rawDataArray);
      }
    } else {
      setOpenModal((prev) => ({ ...prev, status: true, state: tableState, setState: setTableState, api: deleteCodeAPI, el, index: i }));
    }
    setIsTableInitialRender(true); // 삭제 클릭시 테이블길이 변화인한 스크롤하단 이동방지
  };

  const saveDashboardArrayAPI = async (dashboardArray: any) => {
    if (dashboardArray.length > 0) {
      const req = { dashboardSetList: trimArray(dashboardArray) };
      const res = await apiPost({ path: '/dashboard', contentType: 'application/json', req });
      const { message, statusCode, data } = res.data;
      if (statusCode === 200) {
        toast.success(t(message));

        const filterOptions = {
          dName: searchOption.dName,
          huseYn: HuseYn.huseYn,
          suseYn: SuseYn.suseYn,
          buseYn: BuseYn.buseYn,
          useYn: useYn.useYn,
          bigo: searchOption.bigo,
        };
        // 필터링된 어레이 리턴, 대소문자구분X
        const filteredArray = data.DashboardSetList.filter((item: any) => {
          return item.dName?.toLowerCase()?.includes(filterOptions.dName?.toLowerCase()) && item.bigo?.toLowerCase()?.includes(filterOptions.bigo?.toLowerCase());
        });

        const useYnFilter = YNFilter(filteredArray, 'useYn', useYn.useYn);
        const huseYnFilter = YNFilter(useYnFilter, 'huseYn', HuseYn.huseYn);
        const suseYnFilter = YNFilter(huseYnFilter, 'suseYn', SuseYn.suseYn);
        const result = YNFilter(suseYnFilter, 'buseYn', BuseYn.buseYn);
        if (result.length > 0) {
          setTableState(result);
        } else {
          setTableState([]);
        }

        // setTableState(data.DashboardSetList);
        setRawData(data.DashboardSetList);
        setOriList(data.DashboardSetList);
        // setSearchOption((prev: any) => ({ ...prev, dName: '', huseYn: '', suseYn: '', buseYn: '', useYn: '', bigo: '' }));
        await logPost({ hCd: userInfo.hCd, sCd: userInfo.sCd, userId: userInfo.userId, menu: '마스터관리-DashBoard관리', action: 'DashBoard 저장&수정', etc: `` });
      } else {
        // toast.error(t(ERROR));
      }
    } else {
      toast.warn('변경사항이 없습니다');
    }
  };

  // 중복된 정렬값이 있는 지 여부를 판별하는 함수
  const checkForDuplicateCdSort = (data: any[]): boolean => {
    const seen = new Set();
    for (const item of data) {
      if (Number(item.cdSort) && seen.has(Number(item.cdSort))) return true; // 중복된 cdSort값 있는 경우
      if (Number(item.cdSort)) {
        seen.add(Number(item.cdSort));
      }
    }
    return false; // 중복된 cdSort값 없는 경우
  };

  // 공통그룹코드 어레이에 빈값이 있는 지 여부
  const isComCdCodeArrayEmpty = () => {
    const newRowRequired = ['dName', 'dCol', 'dRow', 'cdSort'];
    const emptyCheck = rawData.filter((el: any) => {
      // 새로 추가하는 로우면, dCd 제외한 나머지인풋 빈값 있는 지 확인
      const check = newRowRequired.find((el2: any) => el[el2] === '' && el.flag === FLAG_CREATE_OR_UPDATE);
      return check;
    });

    if (emptyCheck.length > 0) {
      toast.warning(t('필수입력값을 모두 입력하세요'));
    } else if (checkForDuplicateCdSort(rawData)) {
      toast.warning(t('중복된 정렬값이 있습니다'));
    } else {
      // 빈값이 없는 경우 수정,업데이트된 로우들만 서버에 전달
      const dbSendRows = rawData.filter((el: any) => el.flag === FLAG_CREATE_OR_UPDATE);
      const propertyToRemove = 'index'; // 임시로 새 로우에 추가해둔 index값 삭제
      const newArray = dbSendRows.map(({ [propertyToRemove]: _, ...rest }) => rest);
      const newArray2 = newArray.map((prev: any) => {
        return {
          ...prev,
          dCol: Number(prev.dCol),
          dRow: Number(prev.dRow),
          cdSort: prev.cdSort && Number(prev.cdSort),
        };
      });
      saveDashboardArrayAPI(newArray2);
    }
  };

  const onClickSaveTableState = () => {
    setIsSaveClicked(true);
    isComCdCodeArrayEmpty();
  };

  const onClickInitiateSearchOption = () => {
    setSearchOption((prev: any) => ({ ...prev, dName: '', bigo: '' }));
    scrollToNodeTop(scrollContainerRef);
    setHuseYn({ type: 'huseYn', huseYn: 'A', cdName: t('전체') });
    setSuseYn({ type: 'suseYn', suseYn: 'A', cdName: t('전체') });
    setBuseYn({ type: 'buseYn', buseYn: 'A', cdName: t('전체') });
    setTuseYn({ type: 'tuseYn', tuseYn: 'A', cdName: t('전체') });
    setUseYn({ type: 'useYn', useYn: 'A', cdName: t('전체') });
  };

  const YNFilter = (initialArray: IDashboard[], key: string, option: any) => {
    if (option === 'Y') {
      return initialArray.filter((el: any) => el[key] === 'Y');
    }
    if (option === 'N') {
      return initialArray.filter((el: any) => el[key] === 'N');
    }
    return initialArray;
  };

  useEffect(() => {
    // 필터링 기준
    const filterOptions = {
      dName: searchOption.dName,
      bigo: searchOption.bigo,
    };

    // 필터링된 어레이 리턴, 대소문자구분X
    const filteredArray = orgList.filter((item) => {
      return item.dName?.toLowerCase()?.includes(filterOptions.dName?.toLowerCase()) && item.bigo?.toLowerCase()?.includes(filterOptions.bigo?.toLowerCase());
    });

    const useYnFilter = YNFilter(filteredArray, 'useYn', useYn.useYn);
    const huseYnFilter = YNFilter(useYnFilter, 'huseYn', HuseYn.huseYn);
    const suseYnFilter = YNFilter(huseYnFilter, 'suseYn', SuseYn.suseYn);
    const buseYnFilter = YNFilter(suseYnFilter, 'buseYn', BuseYn.buseYn);
    const result = YNFilter(buseYnFilter, 'tuseYn', TuseYn.tuseYn);
    if (result.length > 0) setTableState(result);
    else setTableState([]);
  }, [searchOption, useYn.useYn, HuseYn.huseYn, SuseYn.suseYn, BuseYn.buseYn, TuseYn.tuseYn]);

  const onClickTab = (mrCd: string) => {
    setCurrentTabMrCd(mrCd);
  };

  return (
    <div className='contents'>
      <Tab tabList={tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      <div className='content-container oneColumn'>
        <Root>
          <SearchOptions>
            <div className='inputsWrapper'>
              <div className='flex-basic textBtnGroup'>
                <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
              </div>

              <div className='inputForm-row'>
                <div className='inputForm-col withLabelComCf'>
                  <label htmlFor='huseYn'>{t('본사용')}</label>
                  <SelectBox options={huseDynList} defaultOption={t('전체')} state={HuseYn} setState={setHuseYn} stateKey='huseYn' filterbar='filter-1-left' initiateKey={HuseYn.huseYn} />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col withLabelComCf'>
                  <label htmlFor='suseYn'>{t('현장용')}</label>
                  <SelectBox options={suseDynList} defaultOption={t('전체')} state={SuseYn} setState={setSuseYn} stateKey='suseYn' filterbar='filter-1-left' initiateKey={SuseYn.suseYn} />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col withLabelComCf'>
                  <label htmlFor='buseYn'>{t('상황판용')}</label>
                  <SelectBox options={buseDynList} defaultOption={t('전체')} state={BuseYn} setState={setBuseYn} stateKey='buseYn' filterbar='filter-1-center' initiateKey={BuseYn.buseYn} />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col withLabelComCf'>
                  <label htmlFor='tuseYn'>{t('구역 상황판용')}</label>
                  <SelectBox options={tuseDynList} defaultOption={t('전체')} state={TuseYn} setState={setTuseYn} stateKey='tuseYn' filterbar='filter-1-center' initiateKey={TuseYn.tuseYn} />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col withLabelComCf'>
                  <label htmlFor='useYn'>{t('사용유무')}</label>
                  <SelectBox options={USE_DYN} defaultOption={t('전체')} state={useYn} setState={setUseYn} stateKey='useYn' filterbar='filter-1-center' initiateKey={useYn.useYn} />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <Input label='' placeholder={t('DashBoard명')} type='text' id='dName' name='dName' state={searchOption} setState={setSearchOption} />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <Input label='' placeholder={t('비고')} type='text' id='bigo' name='bigo' state={searchOption} setState={setSearchOption} maxLength={200} />
                </div>
              </div>
            </div>
          </SearchOptions>
          <InputTable className='margin-left-05'>
            <div ref={scrollContainerRef}>
              <div className='thead'>
                <div className='tr'>
                  <div className='trCol2p5 flex-center tableStickyNo'>{t('코드')}</div>
                  <div className='trCol15 flex-center required tableStickyTitle'>{t('DashBoard명')}</div>
                  <div className='trCol10 flex-center'>{t('프로그램 화면명')}</div>
                  <div className='trCol7p5 required flex-center'>{t('가로')}(COL)</div>
                  <div className='trCol7p5 required flex-center'>{t('세로')}(ROW)</div>
                  <div className='trCol7p5 required flex-center'>{t('정렬')}</div>
                  <div className='trCol7p5 flex-center'>{t('본사용')}</div>
                  <div className='trCol7p5 flex-center'>{t('현장용')}</div>
                  <div className='trCol7p5 flex-center'>{t('상황판용')}</div>
                  <div className='trCol7p5 flex-center'>{t('구역 상황판용')}</div>
                  <div className='trCol7p5 flex-center'>{t('사용유무')}</div>
                  <div className='trCol10 flex-center'>{t('비고')}</div>
                  <div className='trCol10 flex-center'>{t('등록일자')}</div>
                  <div className='trCol5 flex-center'>{t('등록자')}</div>
                  <div className='trCol10 flex-center'>{t('수정일자')}</div>
                  <div className='trCol5 flex-center'>{t('수정자')}</div>
                  <div className='trCol5 flex-center'> </div>
                </div>
              </div>
              <div className='tbody'>
                {tableState?.length > 0 ? (
                  tableState.map((el: any, i: number) => (
                    <div className='tr' key={i}>
                      <div className='trCol2p5 flex-center tableStickyNo'>{el.dCd}</div>
                      <div className='trCol15 flex-center tableStickyTitle'>
                        <input
                          type='text'
                          id='dName'
                          name='dName'
                          value={el.dName}
                          onChange={(e) => onChangeTableState(e, i, el)}
                          style={isSaveClicked ? applyBorderStyle(el.dName, 'red', 'dName') : undefined}
                          maxLength={50}
                        />
                      </div>
                      <div className='trCol10 flex-center'>
                        <input type='text' id='dPname' name='dPname' value={el.dPname} onChange={(e) => onChangeTableState(e, i, el)} maxLength={50} />
                      </div>
                      <div className='trCol7p5 flex-center'>
                        <input
                          type='number'
                          id='dCol'
                          name='dCol'
                          value={el.dCol}
                          onChange={(e) => onChangeTableState(e, i, el)}
                          style={isSaveClicked ? applyBorderStyle(el.dCol, 'red', 'dCol') : undefined}
                        />
                      </div>
                      <div className='trCol7p5 flex-center'>
                        <input
                          type='number'
                          id='dRow'
                          name='dRow'
                          value={el.dRow}
                          onChange={(e) => onChangeTableState(e, i, el)}
                          style={isSaveClicked ? applyBorderStyle(el.dRow, 'red', 'dRow') : undefined}
                        />
                      </div>
                      <div className='trCol7p5 flex-center'>
                        <input
                          type='number'
                          id='cdSort'
                          name='cdSort'
                          value={el.cdSort}
                          onChange={(e) => onChangeTableState(e, i, el)}
                          style={isSaveClicked ? applyBorderStyle(el.cdSort, 'red', 'cdSort') : undefined}
                        />
                      </div>
                      {/* <div className='trCol13 fileBoxWrapper'>
                  <input type='file' className='hiddenFileBox' accept='image/jpg, image/png, image/jpeg' id='filebox' name='dIfilename' onChange={(e) => onChangeImage(e, i, el)} />
                  <input type='text' className='fileBoxInput' />
                  <label htmlFor='filebox' className='searchBox'>
                    <div id='filebox'>찾아보기</div>
                  </label>
                </div> */}
                      <div className='trCol7p5 flex-center selector'>
                        <SelectBoxs
                          options={huseYnList}
                          defaultOption={el.huseYn === 'Y' ? t('사용') : t('미사용')}
                          state={tableState}
                          setState={setTableState}
                          rawData={rawData}
                          setRawData={setRawData}
                          stateKey='huseYn'
                          codeKey='cdName'
                          index={i}
                          object={el}
                          primaryKey='dCd'
                          initiateKey={initKey}
                        />
                      </div>
                      <div className='trCol7p5 flex-center selector'>
                        <SelectBoxs
                          options={suseYnList}
                          defaultOption={el.suseYn === 'Y' ? t('사용') : t('미사용')}
                          state={tableState}
                          setState={setTableState}
                          rawData={rawData}
                          setRawData={setRawData}
                          stateKey='suseYn'
                          codeKey='cdName'
                          index={i}
                          object={el}
                          primaryKey='dCd'
                          initiateKey={initKey}
                        />
                      </div>
                      <div className='trCol7p5 flex-center selector'>
                        <SelectBoxs
                          options={buseYnList}
                          defaultOption={el.buseYn === 'Y' ? t('사용') : t('미사용')}
                          state={tableState}
                          setState={setTableState}
                          rawData={rawData}
                          setRawData={setRawData}
                          stateKey='buseYn'
                          codeKey='cdName'
                          index={i}
                          object={el}
                          primaryKey='dCd'
                          initiateKey={initKey}
                        />
                      </div>
                      <div className='trCol7p5 flex-center selector'>
                        <SelectBoxs
                          options={tuseYnList}
                          defaultOption={el.tuseYn === 'Y' ? t('사용') : t('미사용')}
                          state={tableState}
                          setState={setTableState}
                          rawData={rawData}
                          setRawData={setRawData}
                          stateKey='tuseYn'
                          codeKey='cdName'
                          index={i}
                          object={el}
                          primaryKey='dCd'
                          initiateKey={initKey}
                        />
                      </div>
                      <div className='trCol7p5 flex-center selector'>
                        <SelectBoxs
                          options={USE_YN}
                          defaultOption={el.useYn === 'Y' ? t('사용') : t('미사용')}
                          state={tableState}
                          setState={setTableState}
                          rawData={rawData}
                          setRawData={setRawData}
                          stateKey='useYn'
                          codeKey='cdName'
                          index={i}
                          object={el}
                          primaryKey='dCd'
                          initiateKey={initKey}
                        />
                      </div>
                      <div className='trCol10 flex-center'>
                        <input type='text' id='bigo' name='bigo' value={el.bigo} onChange={(e) => onChangeTableState(e, i, el)} maxLength={200} />
                      </div>
                      <div className='trCol10 flex-center' role='button' tabIndex={0}>
                        {formatDate(el.wDate)}
                      </div>
                      <div className='trCol5 flex-center' role='button' tabIndex={0}>
                        {el.dCd !== '' ? el.writer : ''}
                      </div>
                      <div className='trCol10 flex-center' role='button' tabIndex={0}>
                        {formatDate(el.eDate)}
                      </div>
                      <div className='trCol5 flex-center' role='button' tabIndex={0}>
                        {el.dCd !== '' ? el.editor : ''}
                      </div>
                      <div className='trCol5 flex-center'>
                        <BtnRed onClick={() => onClickDelete(el, i)}>{el.dCd !== '' ? t('삭제') : t('제거')}</BtnRed>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='noData'>No data.</div>
                )}
              </div>
            </div>
          </InputTable>
          <ButtonsWrapper>
            <BtnBlue onClick={onClickAddTableRow}>{t('신규항목 추가')}</BtnBlue>
            <BtnBlue onClick={onClickSaveTableState}>{t('저장')}</BtnBlue>
          </ButtonsWrapper>
        </Root>
        <Portal openModal={openModal?.status}>{openModal && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
      </div>
    </div>
  );
};

export default Master4;
