/**
 * 작성자 : 홍선영
 * 날짜 : 2023.07.03
 * 기능 : 일반관리-근로자정보관리 페이지
 */

import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { useOutletContext } from 'react-router-dom';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { msiteUseYnState, pageInfoState, themeState, userState } from '../../atoms';
import { IComCdList, ITabObject } from 'customTypes';
import { arraySortByAscdOrder } from '../../utils/arraySortByAscdOrder';
import { v1 } from 'uuid';
import styled from 'styled-components';
import { BtnBlue, BtnGray, BtnGreen } from '../../components/Button';
import { IoPersonAdd } from 'react-icons/io5';
import { COMCD_WORKERINPUT_SET, ERROR } from '../../_constants';
import Tab from '../../components/Tab';
import { logPost } from '../../services/log';
import { useSetAuth } from '../../utils/useSetAuth';
import illustrator from '../../assets/images/illustration/74.svg';
import { dark, light } from '../../assets/styles/theme';
import { useTranslation } from 'react-i18next';
import { apiGet, apiPost } from '../../services/_common';

interface PuzzlePiece {
  index: number;
  etc1: number; // 피스 사이즈
  isInGrid?: boolean;
  cdName: string;
  cdSort: number;
  wCd: string;
}

interface PuzzleCell {
  id: number;
  content?: PuzzlePiece;
  hidden?: boolean;
  wCol?: number;
  wRow?: number;
}

interface DraggablePuzzlePieceProps {
  piece: PuzzlePiece;
  onRemoveFromGrid?: (piece: PuzzlePiece) => void;
  draggable?: boolean;
}

interface DroppableCellProps {
  id: number;
  content?: PuzzlePiece;
  cell2?: any;
  onDrop: (id: number, piece: PuzzlePiece) => void;
  dropEffect?: (piece: PuzzlePiece, dropEffect: string) => void;
  children?: React.ReactNode;
  grid: any[];
  setGrid: Dispatch<SetStateAction<any>>;
  puzzlePieces: PuzzlePiece[];
  setPuzzlePieces: Dispatch<SetStateAction<any>>;
}

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  justify-content: flex-end;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;

const EmptyData = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  padding-bottom: 8rem !important;
  img {
    width: 24rem;
    /* user-drag: none; */
    -webkit-user-drag: none;
  }
  p {
    color: ${({ theme }: { theme: any }) => theme.filled_violet};
    word-break: keep-all;
    text-align: center;
  }
  p:nth-child(2) {
    padding: 1rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    border-radius: 0.5rem;
    margin: 1rem;
  }
  p:nth-child(3) {
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    margin: 0 2rem;
  }
`;

const Root = styled.div`
  .dndProvider {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: hidden;
    > div {
      flex: 1 1 50%;
      height: 100%;
    }
    > div:nth-child(1) {
      display: flex;
      align-content: flex-start;
      flex-wrap: wrap;
      gap: 0.25rem;
      overflow: auto;
    }
    > div:nth-child(2) {
      display: flex;
      overflow: auto;
    }
    .draggable {
      padding: 0.125rem;
    }
  }
  .buttonsWrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    > button {
      margin-left: 0.5rem;
    }
  }
  .closeIcon {
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-right: 0.75rem;
    font-size: 24px;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    .material-symbols-rounded {
      font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
      font-size: 20px;
    }
    &:hover {
      color: ${({ theme }: { theme: any }) => theme.text_primary};
    }
  }
  .flex-col {
    gap: 0;
    padding: 0 1rem;
    p {
      color: ${({ theme }: { theme: any }) => theme.text_disabled};
      font-size: 0.75rem;
      line-height: 0.875rem;
    }
  }
`;

const DroppableCellStyle = styled.td`
  // background-color: gray;
  .fullHeight {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .normalHeight {
    display: flex;
    align-items: center;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
    > div:nth-child(1) {
      flex-grow: 1;
    }
  }

  > div {
    width: 100%;
    > div {
      width: inherit;
      > input {
        flex-grow: 1;
      }
    }
  }

  > div {
    width: 100%;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    > span {
      padding: 0 1rem;
    }
  }
`;

const DraggablePuzzlePieceStyle = styled.div`
  align-items: center;
  background-color: blue;
  .userImagePiece {
    order: 2;
    text-align: center;
    padding-left: 0 !important;
    padding-top: 1rem;
  }
  .cdName {
    font-size: 0.875rem;
    user-select: none;
    max-width: 33.3%;
    min-width: 6rem;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  input,
  select {
    height: 1.75rem;
    color: ${({ theme }: { theme: any }) => theme.text_disabled};
    background-color: ${({ theme }: { theme: any }) => theme.board};
    margin: 0 auto;
    padding: 0 0.5rem;
  }
  input::placeholder {
    color: ${({ theme }: { theme: any }) => theme.text_disabled};
    background-color: ${({ theme }: { theme: any }) => theme.board};
    font-size: 0.75rem;
  }
  select {
    color: ${({ theme }: { theme: any }) => theme.text_disabled};
    background-color: ${({ theme }: { theme: any }) => theme.board};
  }

  input[type='checkbox'] {
    outline: none;
    width: 0.875rem;
  }

  .inputWrapper {
    > input {
      width: -webkit-fill-available !important;
    }
  }

  .serialSelectWrapper {
    /* width: 100% !important;
    max-width: none; */
    flex-grow: 1;
    display: flex;
    gap: 2rem;
    label {
      font-size: 0.875rem;
      user-select: none;
      max-width: 33.3%;
      min-width: 6rem;
    }
    > div {
      gap: 0;
      flex-grow: 1;
    }
    select {
      width: 100%;
    }
  }

  .serialInputWrapper {
    width: 100%;
    max-width: none;
    display: flex;
    justify-content: space-between;
    > input {
      width: 30%;
    }
    > input::placeholder {
      font-size: 0.75rem;
    }
    > span {
      padding: 0 0.2rem;
    }
  }

  .addressWrapper {
    display: flex;
    gap: 0.5rem;
    > button {
      max-height: 1.75rem;
      font-size: 0.75rem;
      opacity: 0.4;
    }
    > :first-child {
      width: 15%;
    }
    > :nth-child(2) {
      width: 15%;
    }
    > :nth-child(3) {
      width: 35%;
    }
    > :last-child {
      width: 35%;
    }
  }

  .serialInputSelectWrapper {
    justify-self: end;
    display: flex;
    gap: 0.5rem;
    flex-grow: 1;
    input,
    select,
    option {
      font-size: 0.75rem;
      width: 100%;
    }
  }

  .bloodPressureWrapper {
    span {
      padding: 0 1rem;
    }
    input {
      margin: none;
      width: 2rem;
    }
    max-width: none;
    > div {
      display: flex;
    }
  }

  .serialCheckbox {
    display: flex;
    flex-grow: 1;
    gap: 0.5rem;
    > div {
      display: flex;
      align-items: center;
    }
    span {
      display: none;
      padding: 0 0.25rem;
      font-size: 0.75rem;
      line-height: 0.875rem;
      flex-grow: 1;
      color: ${({ theme }: { theme: any }) => theme.text_disabled};
      @media (min-width: 1536px) {
        display: inline;
      }
    }
  }

  .serialInputCheckboxWrapper {
    gap: 0.5rem;
    flex-grow: 1;
    > div:nth-child(1),
    > div:last-child {
      width: 3.5rem;
      max-width: 5rem;
    }
    > div {
      display: flex;
      align-items: center;
      > span {
        display: none;
        padding: 0 0.5rem;
        font-size: 0.75rem;
        line-height: 0.875rem;
        flex-grow: 1;
        color: ${({ theme }: { theme: any }) => theme.text_disabled};
        @media (min-width: 1536px) {
          display: inline;
        }
      }
    }
    dd > input {
      font-size: 0.875rem;
    }
  }
`;

const DraggablePuzzlePiece = ({ piece, onRemoveFromGrid, draggable }: DraggablePuzzlePieceProps) => {
  const { t } = useTranslation();
  const theme = useRecoilValue(themeState);
  const msiteYn = useRecoilValue(msiteUseYnState);
  const userInfo = useRecoilValue(userState);
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'puzzle',
    item: piece,
    end: (item: any, monitor: any) => {
      if (monitor.didDrop() && item.isInGrid && onRemoveFromGrid) {
        onRemoveFromGrid(item); // Call the function to remove the piece from the grid
      }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const renderControll = (wCd: string) => {
    // 1칸
    // 1 근로자명 | 4 영문명 | 5 근로상태 | 6 생년월일 | 10 취업일자 | 11 퇴직일자
    // 16 여권번호 | 22 교육일자 | 23 안전지적 건수 | 24 출입금지 여부 | 25 특이사항
    // 26 비고 | 29 최종 출역일자 | 30 등록일자 | 31 등록자명 | 32 수정일자 | 33 수정자명
    // 35 비콘 ID | 37 맥박수 | 40 혈당
    if (
      wCd === '1' ||
      wCd === '4' ||
      wCd === '5' ||
      wCd === '6' ||
      wCd === '10' ||
      wCd === '11' ||
      wCd === '16' ||
      wCd === '22' ||
      wCd === '23' ||
      wCd === '24' ||
      wCd === '25' ||
      wCd === '26' ||
      wCd === '29' ||
      wCd === '30' ||
      wCd === '31' ||
      wCd === '32' ||
      wCd === '33' ||
      wCd === '35' ||
      wCd === '37' ||
      wCd === '40'
    ) {
      return <input type='text' disabled />;
    }

    // 1칸
    //  13 직종
    if (wCd === '13' && userInfo.prejobtypeYn !== 'Y') {
      return <select disabled> </select>;
    }

    // 1칸
    // 3 협력업체명 | 15 국적 | 13 직종 | 14 외국인 여부 | 17 비자 | 18 혈액형 | 34 근로자 구분 | 39 성별
    if (
      wCd === '3' || //
      wCd === '15' ||
      wCd === '14' ||
      wCd === '17' ||
      wCd === '18' ||
      wCd === '34' ||
      wCd === '39'
    ) {
      return <select disabled> </select>;
    }

    // 1칸
    // 25 특이사항 | 26 비고
    if (wCd === '25' || wCd === '26') {
      return (
        <div className='inputWrapper'>
          <input type='text' disabled />
        </div>
      );
    }

    // 1칸
    // 2 근로자 사진
    if (wCd === '2') {
      return (
        <div className='flex-center userImageWrapper'>
          <IoPersonAdd size={50} />
        </div>
      );
    }

    // 2칸
    // 12 공종+직종
    if (wCd === '12' && userInfo.prejobtypeYn === 'Y') {
      return (
        <div className='serialSelectWrapper flex-between'>
          <div className='flex-between'>
            <label htmlFor='a'>{t('공종')}</label>
            <select disabled> </select>
          </div>
          <div className='flex-between'>
            <label htmlFor='b'>{t('직종')}</label>
            <select disabled> </select>
          </div>
        </div>
      );
    }

    // 2칸
    // 20 보험
    if (wCd === '20') {
      return (
        <div className='serialCheckbox'>
          <div className='flex-center'>
            <input type='checkbox' defaultChecked disabled />
            <span>{t('고용보험')}</span>
          </div>
          <div className='flex-center'>
            <input type='checkbox' defaultChecked disabled />
            <span>{t('건강보험')}</span>
          </div>
          <div className='flex-center'>
            <input type='checkbox' defaultChecked disabled />
            <span>{t('퇴직공제 대상자')}</span>
          </div>
        </div>
      );
    }

    // 1칸
    // 7휴대폰 | 8 비상연락처
    if (wCd === '7' || wCd === '8') {
      return (
        <div className='serialInputWrapper flex-basic'>
          <input type='text' disabled />
          <span>-</span>
          <input type='text' disabled />
          <span>-</span>
          <input type='text' disabled />
        </div>
      );
    }

    // 2칸
    // 41 보건교육
    if (wCd === '41') {
      return (
        <div className='serialCheckbox flex-basic gap50'>
          <div className='flex-center shrink-0'>
            <span>{t('등록번호')}</span>
          </div>
          <div className='serialInputWrapper flex-basic'>
            <input type='text' disabled />
            <span>-</span>
            <input type='text' disabled />
            <span>-</span>
            <input type='text' disabled />
          </div>
          <div>
            <dd>
              <input type='text' placeholder={t('이수일자')} disabled />
            </dd>
          </div>
        </div>
      );
    }

    // 2칸
    // 27 일반 검강검진 | 28 특수 건강검진
    if (wCd === '27' || wCd === '28') {
      return (
        <div className='serialInputSelectWrapper'>
          <div>
            <dd>
              <input type='text' placeholder={t('검진 일자')} disabled />
            </dd>
          </div>
          <div>
            <dd>
              <select disabled>
                <option value={t('검진 결과')}>{t('검진 결과')}</option>
              </select>
            </dd>
          </div>
          <div>
            <dd>
              <input type='text' placeholder={t('비고')} disabled />
            </dd>
          </div>
        </div>
      );
    }

    // 2칸
    // 38 직무 스트레스
    if (wCd === '38') {
      return (
        <div className='serialInputSelectWrapper'>
          <div>
            <dd>
              <input type='text' placeholder={t('총 점수')} disabled />
            </dd>
          </div>
          <div>
            <dd>
              <input type='text' placeholder={t('결과')} disabled />
            </dd>
          </div>
        </div>
      );
    }

    // 2칸
    // 21 보호구
    if (wCd === '21') {
      return (
        <div className='serialInputCheckboxWrapper flex-basic'>
          <div>
            <dd>
              <input type='text' placeholder={t('지급일자')} disabled />
            </dd>
          </div>
          <div className='flex-center'>
            <input type='checkbox' defaultChecked disabled />
            <span>{t('전체선택')}</span>
          </div>
          <div className='flex-center'>
            <input type='checkbox' defaultChecked disabled />
            <span>{t('안전모')}</span>
          </div>
          <div className='flex-center'>
            <input type='checkbox' defaultChecked disabled />
            <span>{t('안전화')}</span>
          </div>
          <div className='flex-center'>
            <input type='checkbox' defaultChecked disabled />
            <span>{t('안전벨트')}</span>
          </div>
          <div className='flex-center'>
            <input type='checkbox' defaultChecked disabled />
            <span>{t('각반')}</span>
          </div>
          <div>
            <dd>
              <input type='text' placeholder={t('기타')} disabled />
            </dd>
          </div>
        </div>
      );
    }

    // 2칸
    // 36 약복용
    if (wCd === '36') {
      return (
        <div className='serialCheckbox'>
          <div className='flex-center'>
            <input type='checkbox' defaultChecked disabled />
            <span>{t('없음')}</span>
          </div>
          <div className='flex-center'>
            <input type='checkbox' defaultChecked disabled />
            <span>{t('고혈압')}</span>
          </div>
          <div className='flex-center'>
            <input type='checkbox' defaultChecked disabled />
            <span>{t('고지혈증')}</span>
          </div>
          <div className='flex-center'>
            <input type='checkbox' defaultChecked disabled />
            <span>{t('당뇨')}</span>
          </div>
        </div>
      );
    }

    // 3칸
    // 9 주소
    if (wCd === '9') {
      return (
        <div className='addressWrapper'>
          <BtnGray>{t('주소검색')}</BtnGray>
          <input type='text' disabled />
          <input type='text' disabled />
          <input type='text' disabled />
        </div>
      );
    }

    // 3칸
    // 42 다중 출역 현장
    if (wCd === '42' && msiteYn === 'Y') {
      return (
        <div className='serialCheckbox'>
          <div className='flex-center'>
            <input type='checkbox' defaultChecked disabled />
            <span>{t('A현장')}</span>
          </div>
          <div className='flex-center'>
            <input type='checkbox' defaultChecked disabled />
            <span>{t('B현장')}</span>
          </div>
          <div className='flex-center'>
            <input type='checkbox' defaultChecked disabled />
            <span>{t('C현장')}</span>
          </div>
          <div className='flex-center'>
            <input type='checkbox' defaultChecked disabled />
            <span>{t('D현장')}</span>
          </div>
        </div>
      );
    }

    // 2칸
    // 19 혈압
    if (wCd === '19') {
      return (
        <div className='bloodPressureWrapper flex-basic'>
          <input type='text' disabled /> <span>/</span>
          <input type='text' disabled />
          <div className='flex-col'>
            <p>{t('최저혈압: 60이상 200이하')}</p>
            <p>{t('최고혈압: 90이상 140이하')}</p>
          </div>
        </div>
      );
    }
    return <input type='text' disabled />;
  };

  return (
    <DraggablePuzzlePieceStyle
      className={piece.wCd === '2' ? 'flexColumn' : undefined}
      ref={draggable ? drag : undefined} // 근로자사진 피스의 경우 이동불가
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'pointer',
        // border: '1px solid gray',
        padding: '0.75rem',
        display: 'flex',
        width: `${piece.etc1 === 1 ? '32.3%' : piece.etc1 === 2 ? '65.2%' : 'calc(100% - 1rem)'}`,
        gridTemplateColumns: `repeat(${piece.etc1},1fr)`,
        maxWidth: 'inherit',
        height: 'fit-content',
        backgroundColor: theme === 'light' ? light.tonal : dark.tonal,
        borderRadius: '5px',
        // width: `${piece.etc1 * 20}rem`,
        // gridTemplateColumns: `repeat(auto-fill, minmax(17rem, ${piece.etc1}fr))`,
        // gridTemplateColumns: `repeat(${piece.etc1}, 1fr)`,
      }}
    >
      {piece.wCd !== '12' ? <div className={piece.wCd !== '2' ? 'cdName' : 'cdName userImagePiece'}>{t(piece.cdName)}</div> : null}
      {renderControll(piece.wCd)}
    </DraggablePuzzlePieceStyle>
  );
};

const DroppableCell = ({ id, cell2, content, onDrop, dropEffect, children, grid, setGrid, puzzlePieces, setPuzzlePieces }: DroppableCellProps) => {
  const theme = useRecoilValue(themeState);
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: 'puzzle',
      drop: (item: PuzzlePiece) => {
        onDrop(id, item);
      },
      dropEffect: (item: PuzzlePiece, effect: string) => {
        if (dropEffect) {
          dropEffect(item, effect);
        }
      },
      canDrop: (item: PuzzlePiece) => true, // Add your own logic for allowing drops
      collect: (monitor: any) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [grid]
  );

  const onClickRemove = () => {
    // 제거클릭한 피스를 본래 리스트에 다시 추가
    const targetRemoveCell = { ...cell2 };
    const { index, wCd, cdName, etc1, cdSort } = targetRemoveCell.content;
    const pieceObject = { index, wCd, cdName, etc1, cdSort };
    const newPuzzlePicies = [...puzzlePieces, pieceObject];
    setPuzzlePieces(arraySortByAscdOrder(newPuzzlePicies, 'cdSort'));

    //  제거클릭한 객체를 그리드에서 삭제(content, hidden값 삭제)
    const result = grid.map((el) => {
      if ((el.content && el.content.index) === targetRemoveCell.content.index) return { id: el.id };
      return el;
    });
    setGrid(result);
  };

  return (
    <DroppableCellStyle
      colSpan={content && content.etc1}
      rowSpan={content && content.wCd === '2' ? 4 : undefined}
      ref={drop}
      style={{
        // border: '1px solid black',
        backgroundColor: isOver ? (canDrop ? '#d6eeee' : 'red') : theme === 'light' ? light.board : dark.board,
        width: '33.3%',
        height: '2.5rem',
        display: cell2 && cell2.hidden === true ? 'none' : '',
        borderRadius: '0.5rem',
        overflow: 'hidden',
      }}
    >
      <div className={content && content.wCd === '2' ? 'fullHeight' : 'normalHeight'}>
        {children}
        {children && content && content.wCd !== '1' && content.wCd !== '2' && content.wCd !== '3' && content.wCd !== '6' && content.wCd !== '7' && (
          <div className='closeIcon' onClick={onClickRemove} role='presentation'>
            <span className='material-symbols-rounded'>cancel </span>
          </div>
        )}
      </div>
    </DroppableCellStyle>
  );
};

const Mcommon3 = () => {
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  const userInfo = useRecoilValue(userState);
  // const [initDragPieces, setInitDragPieces] = useState<PuzzlePiece[]>([]); // 초기화 클릭시 되돌릴 값(드래그쪽)
  // const [initDropPieces, setInitDropPieces] = useState<PuzzleCell[]>([]); // 초기화 클릭시 되돌릴 값(드랍쪽)
  const [puzzlePieces, setPuzzlePieces] = useState<PuzzlePiece[]>([]);
  const [grid, setGrid] = useState<PuzzleCell[]>([]);
  const [chunkedGrid, setChunkedGrid] = useState<any[]>([]);
  const { userMenuList } = userInfo;
  const [newTabList, setNewTabList] = useState<ITabObject[]>();
  const [workerInputSetCdList, setWorkerInputSetCdList] = useState<any[]>([]);
  const msiteYn = useRecoilValue(msiteUseYnState);

  useEffect(() => {
    const arr: any[] = [];
    userMenuList.map((v: any) => {
      const result = tabInfo?.tabList?.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  const filterPiecesByPrejobtypeYn = (array: any) => {
    if (userInfo.prejobtypeYn === 'Y') return array.filter((el: any) => el.wCd !== '13');
    return array.filter((el: any) => el.wCd !== '12');
  };

  const filterPiecesByMsiteYn = (array: any) => {
    if (msiteYn !== 'Y') return array.filter((el: any) => el.wCd !== '42');
    return array;
  };

  const getSettingWorkerAPI = async (picies: PuzzlePiece[]) => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/setting/worker', req });
    const { data, statusCode } = res.data;

    if (statusCode === 200) {
      // 사용자가 사용저장한 데이터 빼기
      const resultArray = picies.filter((piece) => !data.workerinputSetList.some((user: any) => user.wCd === piece.wCd));

      // 공종/직종, 직종사용 여부에 따라 피스 필터링
      const filteredPrejobtypeArray = filterPiecesByPrejobtypeYn(resultArray);
      // 다중현장 사용유무에 따라 피스 필터링
      const filteredMsiteArray = filterPiecesByMsiteYn(filteredPrejobtypeArray);

      setPuzzlePieces(arraySortByAscdOrder(filteredMsiteArray, 'cdSort'));
      // setInitDragPieces(arraySortByAscdOrder(resultArray, 'cdSort'));

      // 피스에 아이디, 컨텐츠 프로퍼티 추가
      const newArray4 = data.workerinputSetList.map((el: any) => {
        const object = picies.find((piece: PuzzlePiece) => piece.wCd === el.wCd);

        if (object !== undefined) {
          const { index, wCd, etc1, cdName, cdSort } = object;
          return { etc1, wCol: el.wCol, wRow: el.wRow, content: { index, cdSort, cdName, wCd, etc1, wCol: el.wCol, wRow: el.wRow, isInGrid: true } };
        }
        return undefined;
      });

      // etc1(사이즈)만큼 객체의 수를 추가하기
      const newArray3 = Array.from(newArray4, ({ etc1, wRow, wCol, ...rest }) => {
        const copyArray: any = [];
        for (let i = 0; i < etc1; i += 1) {
          if (i > 0) {
            const row = 3 * (wRow - 1);
            const col = wCol - 1 + i; //  컬럼숫자 증가
            const id = row + col;

            copyArray.push({ ...rest, id, hidden: true, wCol: col });
          } else {
            const row = 3 * (wRow - 1);
            const col = wCol - 1;
            const id = row + col;

            copyArray.push({ ...rest, id });
          }
        }
        return copyArray;
      }).flat();

      const initialGrid: PuzzleCell[] = Array.from({ length: 33 * 3 }, (_, index) => ({ id: index }));

      // 그리드의 해당 인덱스에 피스를 배치
      const result = initialGrid.map((el: any) => {
        const findObj = newArray3.find((arr3: any) => arr3.id === el.id);
        if (findObj) return findObj;
        return el;
      });
      setGrid(result);
      // setInitDropPieces(result);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // WORKERINPUT_SET 공통코드 조회 API 요청하여 puzzlePieces에 setState
  const getCodeDetail = async () => {
    const req = { grCd: COMCD_WORKERINPUT_SET };
    const res = await apiGet({ path: '/code/detail', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const sortedArray = arraySortByAscdOrder(data.comCdList, 'cdSort');
      const newArray = sortedArray.map((el: IComCdList, i: number) => {
        return { index: i + 1, etc1: Number(el.etc1), cdName: el.cdName, cdSort: el.cdSort, wCd: el.subCd };
      });
      setWorkerInputSetCdList(arraySortByAscdOrder(newArray, 'cdSort'));
      getSettingWorkerAPI(arraySortByAscdOrder(newArray, 'cdSort'));
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  useEffect(() => {
    getCodeDetail().then((res: any) => {
      if (res.status === 200) {
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '근로자 정보 관리 > 근로자 입력화면 설정',
          action: '조회',
          etc: ``,
        });
      }
    });
  }, []);

  useEffect(() => {
    // 그리드아이템을 3개씩 잘라서 배열에 넣어 이중배열로 만들어서 setState
    const result = grid.map((_, index) => {
      if ((index + 1) % 3 === 0) {
        return grid.slice(index - 2, index + 1);
      }
      return undefined;
    });
    setChunkedGrid(result.filter((item) => item));
  }, [grid]);

  useEffect(() => {
    arraySortByAscdOrder(puzzlePieces, 'cdSort');
  }, [puzzlePieces]);

  const onRemoveFromGrid = (piece: PuzzlePiece) => {
    // const newGrid = [...grid];
    // newGrid.forEach((cell) => {
    //   if (cell.content && cell.content.index === piece.index) {
    //     console.log('remove!! ');
    //     delete cell.content;
    //     delete cell.hidden;
    //   }
    // });
    // setGrid(newGrid);
    // // 사용 가능한 퍼즐 조각 목록에 제거된 조각을 다시 추가
    // setPuzzlePieces((prevPieces) => [...prevPieces, piece]);
  };

  // 사이즈가 1 이상인 피스의 타겟인덱스를 제외한, 다음셀에 hidden값 추가
  // e.g. 사이즈가 2인 피스이면, 타겟인덱스 다음셀에 hidden값 true 추가
  //      사이즈가 3인 피스이면, 타겟인덱스 다음셀(2)과 다다음셀(3)에 hidden값 true 추가
  const updatedHidden = (targetId: number, item: PuzzlePiece) => {
    const newGrid = [...grid];

    // 드랍한 피스들의 어레이
    const piece = newGrid.filter((el) => el.content && el.content.index === item.index);

    // 드랍한 피스의 사이즈가 1 이상인 경우, 두번째 피스부터 hidden 값을 true로 추가 (hidden piece를 display none 처리하기 위해)
    const newPieceArray = piece.map((el, i) => (i > 0 ? { ...el, hidden: true } : { ...el }));

    newPieceArray.map((el) => {
      const findIndex = newGrid.findIndex((el2) => el2.content?.index === el.content?.index && el2.id === el.id);
      if (findIndex >= 0) {
        newGrid[findIndex] = el;
      }
    });
    setGrid(newGrid);
  };

  const handleDrop = (targetId: number, piece: PuzzlePiece) => {
    // Check if there's enough space for the piece
    const targetIndex = grid.findIndex((cell) => cell.id === targetId);

    if (targetIndex + piece.etc1 > grid.length) {
      // console.log('드랍할 공간이 없음');
      return; // 드랍할 공간이 없으면, 드랍취소
    }

    // 필요한 셀이 모두 비어 있는지, 아니면 동일한 셀이 차지하고 있는지 확인
    for (let i = 0; i < piece.etc1; i += 1) {
      const targetContent = grid[targetIndex + i].content;
      if (targetContent && targetContent.index !== piece.index) {
        // console.log('occupied', targetContent.index);
        // console.log('occupied', piece.index);
        return; // Occupied by another piece, ignore the drop
      }
    }

    // 드랍했을 때 해당 열에서 남은 셀의 수
    const remainderCellCount = 3 - (targetIndex % 3);
    // 남은 셀의 수가 피스의 사이즈보다 작으면 드랍취소
    if (remainderCellCount < piece.etc1) {
      // console.log('드랍할 셀 부족');
      return;
    }

    const newGrid = [...grid];

    // 드랍존 내부에서 위치를 이동할 때
    // 그리드의 현재 위치에서 조각을 제거
    if (piece.isInGrid === true) {
      newGrid.map((cell) => {
        if (cell.content && cell.content.index === piece.index) {
          // create a copy of the object with the property removed
          const newObj = { ...cell };
          delete cell.content;
          delete cell.hidden;
          return newObj;
        }
        return { ...cell };
      });
    }

    // 그리드의 새 위치에 조각 배치
    for (let i = 0; i < piece.etc1; i += 1) {
      const { cdName, cdSort, etc1, index, wCd, isInGrid } = piece;
      // 피스에 행값과 열값 추가
      const newPiece = { cdName, cdSort, etc1, index, wCd, isInGrid, wRow: Math.floor(targetIndex / 3) + 1, wCol: (targetIndex % 3) + 1 };
      newGrid[targetIndex + i].content = newPiece;

      // 피스의 사이즈가 1 이상일 때 hidden value 업데이트
      if (i + 1 > 1) {
        newGrid[targetIndex + i].hidden = true;
      }
    }
    setGrid(newGrid);

    if (piece.etc1 > 1) {
      updatedHidden(targetIndex, piece);
    }
    // If the piece was not in the grid before, remove it from the list of puzzle pieces
    if (!piece.isInGrid) {
      setPuzzlePieces((prevPieces) => prevPieces.filter((p) => p.index !== piece.index));
    }
  };

  const handleDropEffect = (piece: PuzzlePiece, dropEffect: string) => {
    if (dropEffect === 'move' && piece.isInGrid) {
      onRemoveFromGrid(piece);
    }
  };

  const saveAPI = async (array: any) => {
    const req = { workerinputSetReqDto: array };
    const res = await apiPost({ path: '/setting/worker', req });
    const { statusCode, message, data } = res.data;
    if (statusCode === 200) {
      // 사용자가 사용저장한 데이터 빼기
      const resultArray = workerInputSetCdList.filter((piece) => !data.workerinputSetList.some((user: any) => user.wCd === piece.wCd));

      // 공종/직종, 직종사용 여부에 따라 피스 필터링
      const filteredPrejobtypeArray = filterPiecesByPrejobtypeYn(resultArray);
      // 다중현장 사용유무에 따라 피스 필터링
      const filteredMsiteArray = filterPiecesByMsiteYn(filteredPrejobtypeArray);

      setPuzzlePieces(arraySortByAscdOrder(filteredMsiteArray, 'cdSort'));
      // setInitDragPieces(arraySortByAscdOrder(resultArray, 'cdSort'));

      // 피스에 아이디, 컨텐츠 프로퍼티 추가
      const newArray4 = data.workerinputSetList.map((el: any) => {
        const object = workerInputSetCdList.find((piece: PuzzlePiece) => piece.wCd === el.wCd);

        if (object !== undefined) {
          const { index, wCd, etc1, cdName, cdSort } = object;
          return { etc1, wCol: el.wCol, wRow: el.wRow, content: { index, cdSort, cdName, wCd, etc1, wCol: el.wCol, wRow: el.wRow, isInGrid: true } };
        }
        return undefined;
      });

      // etc1(사이즈)만큼 객체의 수를 추가하기
      const newArray3 = Array.from(newArray4, ({ etc1, wRow, wCol, ...rest }) => {
        const copyArray: any = [];
        for (let i = 0; i < etc1; i += 1) {
          if (i > 0) {
            const row = 3 * (wRow - 1);
            const col = wCol - 1 + i; //  컬럼숫자 증가
            const id = row + col;

            copyArray.push({ ...rest, id, hidden: true, wCol: col });
          } else {
            const row = 3 * (wRow - 1);
            const col = wCol - 1;
            const id = row + col;

            copyArray.push({ ...rest, id });
          }
        }
        return copyArray;
      }).flat();

      const initialGrid: PuzzleCell[] = Array.from({ length: 33 * 3 }, (_, index) => ({ id: index }));

      // 그리드의 해당 인덱스에 피스를 배치
      const result = initialGrid.map((el: any) => {
        const findObj = newArray3.find((arr3: any) => arr3.id === el.id);
        if (findObj) return findObj;
        return el;
      });
      setGrid(result);
      // setInitDropPieces(result);

      toast.success(t(message));
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '근로자 정보 관리 > 근로자 입력화면 설정',
        action: '저장',
        etc: ``,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  const onClickSave = () => {
    const newArray = grid.filter((el: any) => el.content !== undefined && el.hidden !== true).map((el2) => el2.content);
    const resultArray = newArray.map(({ wCd, wRow, wCol }: any) => ({ wCd, wRow, wCol, hCd: userInfo.hCd, sCd: userInfo.sCd, writer: userInfo.userId }));
    saveAPI(resultArray);
  };

  // 드래그/드랍 초기화
  const onClickInitiate = () => {
    getCodeDetail();
    // setPuzzlePieces(initDragPieces);
    // setGrid(initDropPieces);
  };

  const onClickTab = (mrCd: string) => {
    setCurrentTabMrCd(mrCd);
  };

  return (
    <div className='contents'>
      <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      <div className='content-container oneColumn'>
        {size.innerSize.W >= 1024 ? (
          <Root>
            <DndProvider backend={HTML5Backend}>
              <div className='dndProvider'>
                <div className='draggable'>
                  {puzzlePieces.map((piece) => (
                    <DraggablePuzzlePiece key={v1()} piece={piece} draggable />
                  ))}
                </div>
                <div className='droppable'>
                  <table>
                    {chunkedGrid.map((cell, index) => (
                      <tr key={`${cell}_${index}`} style={{ width: '100%' }}>
                        {cell.map((cell2: any, index2: number) => (
                          <DroppableCell
                            key={`${cell2}_${index2}`}
                            id={cell2.id}
                            grid={grid}
                            setGrid={setGrid}
                            puzzlePieces={puzzlePieces}
                            setPuzzlePieces={setPuzzlePieces}
                            content={cell2.content}
                            cell2={cell2}
                            onDrop={handleDrop}
                            dropEffect={handleDropEffect}
                          >
                            {cell2.content && (
                              <DraggablePuzzlePiece key={`${cell2}_${index2}`} piece={{ ...cell2.content, isInGrid: true }} onRemoveFromGrid={onRemoveFromGrid} draggable={cell2.content.wCd !== '2'} />
                            )}
                          </DroppableCell>
                        ))}
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
            </DndProvider>
            <ButtonsWrapper>
              <BtnGreen type='button' onClick={onClickInitiate}>
                {t('초기화')}
              </BtnGreen>
              {(auth.createAuth || auth.updateAuth) && (
                <BtnBlue type='button' onClick={onClickSave}>
                  {t('저장')}
                </BtnBlue>
              )}
            </ButtonsWrapper>
          </Root>
        ) : (
          <EmptyData>
            <img src={illustrator} alt='noData' />
            <p>{t('데스크톱 환경에서 설정해 주세요')}</p>
            <p>{t('화면이 안 나오신다면 브라우저의 가로 폭를 늘려주세요')}</p>
          </EmptyData>
        )}
      </div>
    </div>
  );
};

export default Mcommon3;
