/**
 * 작성자 : 홍선영
 * 날짜 : 2023.08.14
 * 기능 : 오늘 날짜를 기준으로 n주간의 월, 해당월의 몇째주인지, 시작날짜,끝날짜 리턴하는 함수
 */

import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { arraySortByAscdOrder } from './arraySortByAscdOrder';
import 'dayjs/locale/ko';
import i18n from '../translation/i18n';

dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(isSameOrBefore);
dayjs.locale('ko');

const getWeekNumberOfMonth = (date: any) => {
  let currentDay = date.startOf('month');
  let weekNumber = 1;

  while (currentDay.isSameOrBefore(date)) {
    if (currentDay.weekday() === 0) {
      weekNumber += 1;
    }
    currentDay = currentDay.add(1, 'day');
  }

  return weekNumber;
};

export const getPreviousWeeks = (week: number) => {
  let weeks = [];

  // 파라미터 수만큼 반복
  for (let i = 0; i < week; i += 1) {
    const weekStart = dayjs().subtract(i, 'week').startOf('week');
    const weekEnd = dayjs().subtract(i, 'week').endOf('week');
    const month = weekStart.locale(i18n.language === 'en-US' ? 'en' : 'ko').format('MMMM');
    const weekNumberInMonth = getWeekNumberOfMonth(weekStart) - 1;

    weeks.push({
      weekNumber: weekStart.week(),
      weekInMonth: weekNumberInMonth,
      month,
      startDate: weekStart.format('YYYY-MM-DD'),
      endDate: weekEnd.format('YYYY-MM-DD'),
    });
  }
  const sortedWeeks = arraySortByAscdOrder(weeks, 'weekNumber');

  return sortedWeeks;
};

/**
 * 오늘부터 이전 12개월의 시작일과 종료일을 확인
 * @returns 지난 12개월 동안의 날짜 범위를 포함하는 배열
 */
export const getPreviousMonthsDateRanges = (months: number): any[] => {
  let currentDate = dayjs();
  const dateRanges: any[] = [];

  for (let i = 0; i < months; i += 1) {
    const startDate = currentDate.startOf('month').format('YYYY-MM-DD');
    const endDate = currentDate.endOf('month').format('YYYY-MM-DD');
    const month = currentDate.format('MM');
    const year = currentDate.format('YY');

    dateRanges.push({
      month,
      year,
      startDate,
      endDate,
    });

    currentDate = currentDate.subtract(1, 'month');
  }
  const sortedDateRange = dateRanges.sort((a, b) => (a.startDate > b.startDate ? 1 : b.startDate > a.startDate ? -1 : 0));
  return sortedDateRange;
};

/**
 * 선택한 날짜에서 이전 날짜 배열을 검색
 *
 * @param selectedDate - 시작할 날짜
 * @param period - 이전 날짜 수
 *
 * @returns 이전 날짜의 배열
 */
export const getPreviousDays = (selectedDate: string, period: number) => {
  dayjs.locale('ko'); // Set the locale to Korean
  const endDate = dayjs(selectedDate);
  const startDate = endDate.subtract(period - 1, 'day'); // Subtracting one less to include the end date in the range

  let days = [];

  for (let i = 0; i < period; i += 1) {
    const dayDate = startDate.add(i, 'day');
    days.push({
      dayName: dayDate.format('dddd').charAt(0), // 요일 앞자리만 (e.g. 월,화,수...)
      date: dayDate.format('DD'), // 날짜만 (e.g. 1,2,3...)
      fullDate: dayDate.format('YYYY-MM-DD'),
      isToday: dayDate.isSame(dayjs(), 'day'), // 오늘날짜 확인
    });
  }

  return days;
};
