import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useOutletContext } from 'react-router-dom';

import { IUser, pageInfoState, userState } from '../../atoms';
import { ITabObject } from 'customTypes';
import Tab from '../../components/Tab';
import ScheduleSetting from './safeedu1/ScheduleSetting';

const Ssafeedu2 = () => {
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const userInfo = useRecoilValue<IUser>(userState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  const [newTabList, setNewTabList] = useState<ITabObject[]>();
  const { userMenuList } = userInfo;

  useEffect(() => {
    const arr: any[] = [];
    userMenuList.map((v: any) => {
      const result = tabInfo?.tabList?.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  const onClickTab = (mrCd: string) => {
    setCurrentTabMrCd(mrCd);
  };

  const renderPage = () => {
    const activeTab = userMenuList.find((el: ITabObject) => el.mrCd === currentTabMrCd);
    if (activeTab !== undefined) {
      const { mrCd } = activeTab;
      switch (mrCd) {
        case '001':
          return <ScheduleSetting />;
      }
    }
    return null;
  };

  return (
    <div className='contents'>
      <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      {renderPage()}
    </div>
  );
};

export default Ssafeedu2;
