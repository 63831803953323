/**
 * 작성자 : 홍선영
 * 날짜 : 2023.06.05
 * 경로 : 설정관리-환경설정 페이지 (현장관리자)
 */

import Tab from '../../components/Tab';
import { useOutletContext } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { IUser, pageInfoState, userState } from '../../atoms';
import { useEffect, useState } from 'react';
import { ITabObject } from 'customTypes';
import UserLoginSetting from './setting1/userLoginSetting';
import GeneralSetting from './setting1/generalSetting';
import MedicalCheckupSetting from './setting1/medicalCheckupSetting';
import TunnelSetting from './setting1/tunnelSetting';
import { logPost } from '../../services/log';

const SMSetting1 = (mqtt: any) => {
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  const userInfo = useRecoilValue<IUser>(userState);
  const { userMenuList } = userInfo;
  const [newTabList, setNewTabList] = useState<ITabObject[]>();

  useEffect(() => {
    const arr: any[] = [];
    userMenuList.map((v: any) => {
      const result = tabInfo?.tabList?.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  const onClickTab = (mrCd: string) => {
    setCurrentTabMrCd(mrCd);
  };

  const activeTab = newTabList?.find((el: ITabObject) => el.mrCd === currentTabMrCd);
  const renderPage = () => {
    if (activeTab !== undefined) {
      const { mrCd } = activeTab;
      switch (mrCd) {
        case '001':
          return (
            <div className='content-container oneColumn'>
              <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
              <UserLoginSetting />
            </div>
          );
        case '002':
          return (
            <div className='content-container content-overflow oneColumn'>
              <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
              <GeneralSetting mqtt={mqtt} />
            </div>
          );
        case '003':
          return (
            <>
              <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
              <div className='content-container setting-medical'>
                <MedicalCheckupSetting />
              </div>
            </>
          );
        case '004':
          return (
            <div className='content-container content-overflow oneColumn'>
              <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
              <TunnelSetting />
            </div>
          );
      }
    }
    return undefined;
  };

  return <div className='contents'>{renderPage()}</div>;
};

export default SMSetting1;
