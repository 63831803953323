import Slider from 'react-slick';
import { IoRefresh } from 'react-icons/io5';
import styled from 'styled-components';

import { IModal } from 'customTypes';
import { BtnGray } from '../Button';
import { ModalBackground, Modal } from '../../assets/styles/Modal';
import { useTranslation } from 'react-i18next';

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5010;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 60%);
  .modal {
    z-index: 6000;
    display: flex;
    flex-direction: column;
    /* height: calc(100vh - 2rem); */
    height: fit-content;
    width: 36rem;
    background-color: ${({ theme }: { theme: any }) => theme.board};
    border-radius: 0.5rem;
    overflow: hidden;
    @media screen and (max-width: 1023px) {
      /* width: 80%; */
      width: calc(100vw - 2rem);
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      height: 100vh;
      overflow-y: auto;
      border-radius: 0;
    }
    > .inputForm-head {
      user-select: none;
      position: relative;
      font-weight: 500;
      flex-shrink: 0;
      padding: 0.5rem;
      height: 3.5rem;
      border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      > svg {
        width: 24px;
        height: 24px;
      }
      .modalHeaderTitle {
        padding: 0 0.25rem;
      }
      .modalHeaderCenter {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: ${({ theme }: { theme: any }) => theme.text_primary};
      }
    }
  }
  .closeBtn {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5rem;
    /* background-color: rgba(0, 0, 0, 0.1); */
    cursor: pointer;
    margin: 0 0.5rem;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .closeBtn:hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
  }
  .material-symbols-rounded {
    color: rgba(0, 0, 0, 0.8);
    font-variation-settings: 'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 20;
  }
`;
const SliderWrapper = styled.div`
  padding: 2rem;
  margin: 0 2rem;
  /* height: calc(100% - 3rem); */

  > div {
    height: inherit;
    display: flex;
    align-items: center;
    button:nth-child(1) {
      left: -4rem;
    }
    button:nth-child(3) {
      right: -4rem;
    }
    .slick-list {
      height: inherit;
      div {
        height: 100%;
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
    margin: auto;
  }
  .slick-arrow {
    width: 4.125rem;
    height: 4.125rem;
    background: transparent;
    box-shadow: none;
    z-index: 999;
    &:before {
      font-size: 3rem;
      color: lightgray;
      opacity: 0.5;
    }
  }
  .slick-dots {
    bottom: -1.75rem;
    width: inherit;
    right: 50%;
    transform: translate(50%, 50%);

    li {
      margin: 0;

      button:before {
        transition: all ease-in-out 0.05s;
        font-family: 'slick';
        font-size: 15px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 5px;
        height: 5px;
        border-radius: 5px;
        content: '';
        text-align: center;
        color: black;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: #2848ff;
      }
    }

    .slick-active {
      button:before {
        width: 12px;
      }
    }
  }
`;

const DashboardViewModalSlider = ({ openModal, setOpenModal }: any) => {
  const { t } = useTranslation();
  const settings = {
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  // 닫기버튼 클릭
  const onClickClose = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };
  return (
    <Root>
      {/* <Modal> */}
      <div className='modal dashboardImg'>
        <div className='inputForm-head flex-between'>
          <div className='modalHeaderTitle'>{t('미리보기')}</div>
          <div className='closeBtn' onClick={onClickClose} role='presentation'>
            <span className='material-symbols-rounded'>close</span>
          </div>
        </div>
        {/* <div className='inputForm-body '>
          <div className='flex-between' style={{ padding: '2rem' }}>
            <h3> </h3>
            <span>
              <IoRefresh size={20} />
            </span>
          </div>
        </div> */}
        <SliderWrapper>
          <Slider {...settings}>
            {openModal.data.map((el: any, i: number) => (
              <img key={`${el.cdSort}_${i}`} src={el.imgPath} />
            ))}
          </Slider>
        </SliderWrapper>
      </div>
      {/* </Modal> */}
    </Root>
  );
};

export default DashboardViewModalSlider;
