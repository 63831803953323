import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';

import * as cctv from '../../../utils/cctv';
import WebSdkRealtime from '../WebSdkRealtime';
import { localCctvSettingState, userState } from '../../../atoms';
import { bookmarkSelectBoxOptions } from '../../../_constants';
import { CameraStatus, WebSDKcctv } from '../../../utils/webSDKcctvClass';
import LeftSideMenu from './RealtimeCCTV/LeftSideMenu';
import { useFetchCctvList } from '../../../services/useFetchCctvList';
import { apiGet } from '../../../services/_common';
import { logPost } from '../../../services/log';
import { toast } from 'react-toastify';
import LeftedToast from '../../../components/cctv/LeftedToast';

const Root = styled.div`
  width: 100vw;
  width: 100dvw;
  height: 100vh;
  height: 100dvh;
  display: flex;
  .main {
    flex: 1 0;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }: { theme: any }) => theme.color.black};
  }
  &.isDragging * {
    cursor: grabbing !important;
  }
`;

const RealtimeCCTV = () => {
  const userInfo = useRecoilValue(userState);
  const { userId } = userInfo;
  const [visiblePanel, setVisiblePanel] = useState(true); // 왼쪽 사이드 패널 접힘/펼침 상태
  const [draggingCamInfo, setDraggingCamInfo] = useState<ISubMenu[] | null>(null); // 드래그한 카메라객체 정보
  const [droppedWndIndex, setDroppedWndIndex] = useState<null | number>(null); // 드래그한 카메라객체를 드랍한 윈도우 인덱스
  const [cctvOBJ, setCctvOBJ] = useState<WebSDKcctv>();
  const [isDragging, setIsDragging] = useState(false); // 마우스 드래그 상태 - 커서 모양 변경 용도
  const [initSelectedCamList, setInitSelectedCamList] = useState<{ type: string; camList: string; cdName: string }>(bookmarkSelectBoxOptions[0]);
  // cctvOBJ에서 선택한 카메라 상태
  const [selectedCctvCameraInfo, setSelectedCctvCameraInfo] = useState<SelectedCctvCameraInfo | null>(null);
  const [bookmarkedCams, setBookmarkedCams] = useState<IMainMenu[] | string>([]);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [playingCamStatus, setPlayingCamStatus] = useState<any[]>([]);
  const [sdkInitLoading, setSdkInitLoading] = useState(true);
  const [camPlayLoading, setCamPlayLoading] = useState(false);
  const [integrationCamList, setIntegrationCamList] = useState<any>();
  const { cameraInfo, nvrList, setNvrList, isError: isErrorCctvList, isLoading: isLoadingCctvList, sInfraList, setSInfraList } = useFetchCctvList();
  const [cctvSettings, setCctvSettings] = useState<CctvSettings>();
  const [cctvDivisions, setCctvDivisions] = useState<number>(4);
  const [integrationCamDivisions, setIntegrationCamDivisions] = useState<number>(3);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [localCctvSettings, setLocalCctvSettings] = useRecoilState(localCctvSettingState);

  useEffect(() => {
    if (cctv.getPluginOBJECT()?.oPlugin) {
      cctv.destroy();
    }
    getIntegrationCamList();
    getCctvSettings();
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: 'CCTV 실시간 보기',
      action: '조회',
      etc: ``,
    });
  }, []);

  const getIntegrationCamList = () => {
    apiGet({ path: '/cam/camlist', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }).then((res: any) => {
      if (res.data.data?.camList) {
        setIntegrationCamDivisions(res.data.data.camCType);
        setIntegrationCamList(JSON?.parse(res.data.data.camList));
      }
    });
  };

  const getCctvSettings = () => {
    apiGet({ path: '/user/cctv', req: { userId } }).then((res: any) => {
      if (res.data.data) {
        setCctvSettings(res.data.data);
      }
    });
  };

  useEffect(() => {
    setCctvDivisions(Number(localCctvSettings.camCType));
  }, [localCctvSettings.camCType]);

  useEffect(() => {
    if (cctvSettings) {
      setCctvDivisions(Number(cctvSettings.camCType));
    }
    if (integrationCamList) {
      setInitSelectedCamList(bookmarkSelectBoxOptions[1]);
      setBookmarkedCams(integrationCamList);
      setCctvDivisions(Number(integrationCamDivisions));
    } else if (cctvSettings?.camList1) {
      setBookmarkedCams(JSON.parse(cctvSettings?.camList1));
      setInitSelectedCamList(bookmarkSelectBoxOptions[2]);
    } else if (cctvSettings?.camList2) {
      setBookmarkedCams(JSON.parse(cctvSettings?.camList2));
      setInitSelectedCamList(bookmarkSelectBoxOptions[3]);
    } else if (cctvSettings?.camList3) {
      setBookmarkedCams(JSON.parse(cctvSettings?.camList3));
      setInitSelectedCamList(bookmarkSelectBoxOptions[4]);
    } else if (cctvSettings?.camList4) {
      setBookmarkedCams(JSON.parse(cctvSettings?.camList4));
      setInitSelectedCamList(bookmarkSelectBoxOptions[5]);
    } else if (cctvSettings?.camList5) {
      setBookmarkedCams(JSON.parse(cctvSettings?.camList5));
      setInitSelectedCamList(bookmarkSelectBoxOptions[6]);
    }
  }, [integrationCamList, cctvSettings, cctvOBJ]);

  useEffect(() => {
    if (camPlayLoading) {
      toast(<LeftedToast message='기다려 주세요..' />, { position: 'top-left', autoClose: 20000 });
    } else {
      toast.dismiss();
    }
  }, [camPlayLoading]);

  // RealtimeCCTV 컴포넌트에서 cctvSettings가 undefined일 때의 처리 추가
  if (!cctvSettings || nvrList.length === 0) {
    return null;
  }

  return (
    <Root className={isDragging ? 'isDragging' : ''}>
      <LeftSideMenu
        cctvOBJ={cctvOBJ}
        visiblePanel={visiblePanel}
        setDraggingCamInfo={setDraggingCamInfo}
        nvrList={nvrList}
        setNvrList={setNvrList}
        sInfraList={sInfraList}
        setSInfraList={setSInfraList}
        isLoading={isLoadingCctvList}
        isError={isErrorCctvList}
        setIsDragging={setIsDragging}
        cctvSettings={cctvSettings || null}
        bookmarkedCams={bookmarkedCams}
        setBookmarkedCams={setBookmarkedCams}
        initSelectedCamList={initSelectedCamList}
        setInitSelectedCamList={setInitSelectedCamList}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        selectedCctvCameraInfo={selectedCctvCameraInfo}
        setSelectedCctvCameraInfo={setSelectedCctvCameraInfo}
        cctvDivisions={cctvDivisions}
        setCctvDivisions={setCctvDivisions}
        isRecording={isRecording}
        setIsRecording={setIsRecording}
        playingCamStatus={playingCamStatus}
        setPlayingCamStatus={setPlayingCamStatus}
        sdkInitLoading={sdkInitLoading}
        setSdkInitLoading={setSdkInitLoading}
        camPlayLoading={camPlayLoading}
        setCamPlayLoading={setCamPlayLoading}
        integrationCamList={integrationCamList}
      />
      <div className='main'>
        <WebSdkRealtime
          cctvOBJ={cctvOBJ} // cctv 객체
          setCctvOBJ={setCctvOBJ} // cctv 객체 변경 함수
          isLoading={isLoadingCctvList}
          draggingCamInfo={draggingCamInfo} // 드래그한 카메라 객체 정보
          setDraggingCamInfo={setDraggingCamInfo} // 드래그한 카메라 객체 정보 변경 함수
          droppedWndIndex={droppedWndIndex} // 드래그한 카메라 객체를 드랍한 윈도우 인덱스
          setDroppedWndIndex={setDroppedWndIndex} // 드래그한 카메라 객체를 드랍한 윈도우 인덱스 변경 함수
          cameraInfo={cameraInfo}
          setIsDragging={setIsDragging} // 드래그 상태 변경 함수
          visiblePanel={visiblePanel} // 왼쪽 사이드 패널 접기/펼치기 상태
          setVisiblePanel={setVisiblePanel} // 왼쪽 사이드 패널 상태 변경 함수
          nvrList={nvrList}
          setNvrList={setNvrList}
          cctvSettings={cctvSettings || null}
          bookmarkedCams={bookmarkedCams} // 즐겨찾기 선택한 카메라목록
          setBookmarkedCams={setBookmarkedCams} // 즐겨찾기 카메라목록 상태값변경
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          selectedCameraInfo={selectedCctvCameraInfo}
          setSelectedCameraInfo={setSelectedCctvCameraInfo}
          isRecording={isRecording}
          setIsRecording={setIsRecording}
          cctvDivisions={cctvDivisions}
          setCctvDivisions={setCctvDivisions}
          playingCamStatus={playingCamStatus}
          setPlayingCamStatus={setPlayingCamStatus}
          sdkInitLoading={sdkInitLoading}
          setSdkInitLoading={setSdkInitLoading}
          camPlayLoading={camPlayLoading}
          setCamPlayLoading={setCamPlayLoading}
          integrationCamList={integrationCamList}
        />
      </div>
    </Root>
  );
};

export default RealtimeCCTV;
