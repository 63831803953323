/**
 * 작성자 : 한영광
 * 날짜 : 2023.06.30
 * 경로 : 안전관리 - 안전지적
 */

import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useReactToPrint } from 'react-to-print';
import { IoChevronUpSharp } from 'react-icons/io5';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { IComCdList } from 'customTypes';
import { LIST_COUNT, SRSUM_YN } from '../../../_constants';
import { IUser, userState } from '../../../atoms';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { FloatingButtonToTop } from '../../../assets/styles/FloatingButtonToTop';
import RangePicker, { onChangeRangeInput } from '../../../components/RangePicker';
import { BtnGhost, BtnGray } from '../../../components/Button';
import Input from '../../../components/Input';
import Portal from '../../../components/Portal';
import SafePointModal from '../../../components/Modal/safePointModal';
import { todayYYYYMMDD } from '../../../utils/formatDate';
import { scrollToNodeTop } from '../../../utils/scrollToNodeTop';
import { setComCdListState } from '../../../utils/setComCdListState';
import { useDetectScrolledToBottom } from '../../../utils/useDetectScrolledToBottom';
import useOnKeydownF9 from '../../../utils/useOnKeydownF9';
import { logPost } from '../../../services/log';
import { useSetAuth } from '../../../utils/useSetAuth';
import { trimObject } from '../../../utils/trimObject';
import TuiGrid from '../../../components/Table/TuiGrid';
import SelectBox from '../../../components/SelectBox';
import { useTranslation } from 'react-i18next';
import i18n from '../../../translation/i18n';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import { useGetFrozenCount } from '../../../utils/getFrozenCount';
import TuiGridWrapper from '../../../components/Table/TuiGridWrapper';
import { ContentsContainerRoot } from '../../../assets/styles/ContentsContainerRoot';
import useSetListCount from '../../../utils/useSetListCount';
import { apiGet } from '../../../services/_common';
import ShortcutButton from '../../../components/button/ShortcutButton';

const Root = styled(ContentsContainerRoot)`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
`;

const Main = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const INIT_SCODE = { type: 'sCode', sCode: 'A', cdName: t('전체') };
  const INIT_SRSUM_YN = { type: 'srSumYn', srSumYn: 'A', cdName: t('전체') };
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const { listCount, setListCount, patchUserMenuAPI } = useSetListCount(); // 검색줄수설정 훅
  const toDay = todayYYYYMMDD();
  const [userInfo, setUserInfo] = useRecoilState<IUser>(userState);
  const [tableState, setTableState] = useState<any[]>([]);
  const [orgTableState, setOrgTableState] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [sCodeList, setScodeList] = useState<any[]>([]);
  const tableRef = useRef<HTMLDivElement>(null);
  const sLocationRef = useRef<HTMLInputElement>(null);
  const [visibleRangePicker, setVisibleRangePicker] = useState(false);
  const [rangeState, setRangeState] = useState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
  const [visibleRangePicker2, setVisibleRangePicker2] = useState(false);
  const [rangeState2, setRangeState2] = useState([{ startDate: '', endDate: '', key: 'selection' }]);
  const [listCountComCdList, setListCountComCdList] = useState<IComCdList[]>([]); // 검색줄수 공통코드
  const [searchOption, setSearchOption] = useState({
    sDateStart: `${toDay.substring(0, 4)}-${toDay.substring(4, 6)}-${toDay.substring(6, 8)}`,
    sDateEnd: `${toDay.substring(0, 4)}-${toDay.substring(4, 6)}-${toDay.substring(6, 8)}`,
    srDateStart: '',
    srDateEnd: '',
    sLocation: '',
  });
  const [sCode, setSCode] = useState(INIT_SCODE);
  const [srSumYn, setSrSumYn] = useState(INIT_SRSUM_YN);
  const [excelBtn, setExcelBtn] = useState(false);
  const { isBottom } = useDetectScrolledToBottom(tableRef);
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '', code: { wCd: '' } });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '안전 지적',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    setColumns([
      {
        header: t('안전지적 구분'),
        name: 'sName',
        sortable: true,
        filter: 'select',
        width: 120,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('적발일자'),
        name: 'sDate',
        align: 'center',
        sortable: true,
        filter: 'select',
        minWidth: 120,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('위반 내용'),
        name: 'sMemo',
        sortable: true,
        minWidth: 240,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('적발장소'),
        name: 'sLocation',
        sortable: true,
        minWidth: 120,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('적발담당자'),
        name: 'sChargename',
        align: 'left',
        sortable: true,
        minWidth: 120,
      },
      {
        header: t('조치여부'),
        name: 'srSumYn',
        align: 'center',
        sortable: true,
        filter: 'select',
        minWidth: 120,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('조치일자'),
        name: 'srDate',
        align: 'center',
        sortable: true,
        minWidth: 120,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('조치결과 요약'),
        name: 'srSum',
        sortable: true,
        minWidth: 160,
      },
      {
        header: t('등록일자'),
        name: 'wDate',
        align: 'center',
        sortable: true,
        minWidth: 160,
      },
      {
        header: t('등록자'),
        name: 'writer',
        align: 'center',
        sortable: true,
        minWidth: 120,
      },
      {
        header: t('수정일자'),
        name: 'eDate',
        align: 'center',
        sortable: true,
        minWidth: 160,
      },
      {
        header: t('수정자'),
        name: 'editor',
        align: 'center',
        sortable: true,
        minWidth: 120,
      },
    ]);

    setTableState(
      orgTableState.map((v: any) => {
        return {
          ...v,
          srSumYn: t(v.srSumYn),
        };
      })
    );
  }, [i18n.language]);

  useEffect(() => {
    setLoading(true);
    getSafePointtAPI().then((res: any) => {
      if (res.status === 200) {
        getScodeAPI();
        setComCdListState(LIST_COUNT, setListCountComCdList, false);
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    if (!rangeState[0].startDate) {
      return;
    }
    setSearchOption((prev) => ({ ...prev, sDateStart: dayjs(rangeState[0].startDate).format('YYYY-MM-DD') }));
  }, [rangeState[0].startDate]);

  useEffect(() => {
    if (!rangeState[0].endDate) {
      return;
    }
    setSearchOption((prev) => ({ ...prev, sDateEnd: dayjs(rangeState[0].endDate).format('YYYY-MM-DD') }));
  }, [rangeState[0].endDate]);

  useEffect(() => {
    if (!rangeState2[0].startDate) {
      return;
    }
    setSearchOption((prev) => ({ ...prev, srDateStart: dayjs(rangeState2[0].startDate).format('YYYY-MM-DD') }));
  }, [rangeState2[0].startDate]);

  useEffect(() => {
    if (!rangeState2[0].endDate) {
      return;
    }
    setSearchOption((prev) => ({ ...prev, srDateEnd: dayjs(rangeState2[0].endDate).format('YYYY-MM-DD') }));
  }, [rangeState2[0].endDate]);

  // 안전지적 정보 리스트 조회 API
  const getSafePointtAPI = async () => {
    const newSearchOption = {
      ...searchOption,
      sCode: sCode.sCode === 'A' ? '' : sCode.sCode,
      srSumYn: srSumYn.srSumYn === 'A' ? '' : srSumYn.srSumYn,
      sDate1: searchOption.sDateStart.replaceAll('-', ''),
      sDate2: searchOption.sDateEnd.replaceAll('-', ''),
      srDate1: searchOption.srDateStart.replaceAll('-', ''),
      srDate2: searchOption.srDateEnd.replaceAll('-', ''),
    };
    const { sDateStart, sDateEnd, srDateStart, srDateEnd, ...finalSearchObj } = newSearchOption; // searchOption에서 start, end값 삭제
    const trimData = trimObject(finalSearchObj);
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, ...trimData };
    const res = await apiGet({ path: '/safe/pointt', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      setLoading(false);
      setOrgTableState(data);
      setTableState(
        data.map((v: any) => {
          return {
            ...v,
            srSumYn: t(v.srSumYn),
          };
        })
      );
    } else {
      setLoading(false);
      // toast.error(t(ERROR));
    }
    return res;
  };

  // 안전지적구분 리스트 조회 API
  const getScodeAPI = async () => {
    const req = {
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      subCd: 'G',
    };
    const res = await apiGet({ path: '/code/pmsNormalTitle', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const newNormalCdList: any[] = [];
      newNormalCdList.push(INIT_SCODE);
      data.pmsNormalTitleList.map((v: any) => {
        newNormalCdList.push({ type: 'sCode', sCode: v.subCd, cdName: v.cdName });
      });
      setScodeList(newNormalCdList);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 검색 버튼 클릭
  const onClickSearch = () => {
    if (!openModal.status) {
      if (searchOption.sDateEnd && searchOption.sDateStart > searchOption.sDateEnd) {
        toast.warning(t('날짜를 다시 입력하세요'));
        setSearchOption((prev: any) => ({ ...prev, sDateEnd: '' }));
      } else if (searchOption.srDateEnd && searchOption.srDateStart > searchOption.srDateEnd) {
        toast.warning(t('날짜를 다시 입력하세요'));
        setSearchOption((prev: any) => ({ ...prev, sDateEnd: '' }));
      } else {
        setLoading(true);
        setVisibleRangePicker(false);
        navigate({
          pathname: location.pathname,
          search: '',
        });
        getSafePointtAPI();
      }
    }
  };

  const initiateSearchOptions = () => {
    setSCode(INIT_SCODE);
    setSrSumYn(INIT_SRSUM_YN);
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    Object.keys(searchOption).map((el: any) => {
      return setSearchOption((prev) => ({
        ...prev,
        [el]: '',
        sDateStart: `${toDay.substring(0, 4)}-${toDay.substring(4, 6)}-${toDay.substring(6, 8)}`,
        sDateEnd: `${toDay.substring(0, 4)}-${toDay.substring(4, 6)}-${toDay.substring(6, 8)}`,
        srDateStart: '',
        srDateEnd: '',
      }));
    });
    initiateSearchOptions();
    setRangeState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
    setRangeState2([{ startDate: '', endDate: '', key: 'selection' }]);
  };

  // 신규 등록 버튼 클릭
  const onClickNewWorker = () => {
    setOpenModal({ status: true, type: 'safePoint', code: { sSeq: 0 } }); // 신규 등록시 순번0
  };

  // 엑셀 저장 버튼 클릭
  const onClickExcelSave = () => {
    if (tableState.length <= 0) return;
    setExcelBtn(true);
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '안전 지적',
      action: '엑셀 저장',
      etc: ``,
    });
  };

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  // 인쇄 버튼 클릭
  const onClickPrint = () => {
    handlePrint();
  };

  // 근로자 Row 클릭
  const onClickRow = (rowData: any) => {
    setOpenModal({ status: true, type: 'safePoint', code: { sSeq: rowData.sSeq } });
  };

  const renderCreateBtn = () => {
    if (auth.createAuth) {
      return (
        <BtnGhost onClick={onClickNewWorker}>
          <span className='material-symbols-rounded'>add</span> {t('추가')}
        </BtnGhost>
      );
    }
    return null;
  };

  const renderExcelBtn = () => {
    if (auth.excelAuth) {
      return (
        <BtnGhost onClick={onClickExcelSave}>
          <span className='icon-ms-xlsx'>X</span> {t('엑셀')}
        </BtnGhost>
      );
    }
    return null;
  };

  const renderPrintBtn = () => {
    if (auth.printAuth) {
      return <BtnGray onClick={onClickPrint}>{t('인쇄')}</BtnGray>;
    }
    return null;
  };

  // 입력창에서 엔터입력시 검색
  const loginOnEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (sLocationRef.current) onClickSearch();
    }
  };

  const onClickRangeInput = () => {
    if (!visibleRangePicker) setVisibleRangePicker(true);
  };

  const onClickRangeInput2 = () => {
    if (!visibleRangePicker2) setVisibleRangePicker2(true);
  };
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight, componentRef.current?.offsetWidth]);

  const frozenCount = useGetFrozenCount();

  return (
    <>
      <Root loading={loading}>
        <SearchOptions>
          {isBottom && (
            <FloatingButtonToTop>
              <button type='button' onClick={() => scrollToNodeTop(tableRef)}>
                <IoChevronUpSharp size={20} style={{ stroke: 'white' }} />
              </button>
            </FloatingButtonToTop>
          )}
          <div className='inputsWrapper'>
            <div className='inputForm-col withLabelComCf'>
              <label htmlFor='sCode'>{t('안전지적')}</label>
              <SelectBox
                options={sCodeList}
                defaultOption={t('전체')}
                state={sCode}
                setState={setSCode}
                stateKey='sCode'
                initiateKey={sCode.sCode}
                filterbar='filter-1-left'
                optionHeight='height-md'
              />
            </div>
            <div className='inputForm-col withLabelComCf'>
              <label htmlFor='srSumYn'>{t('조치여부')}</label>
              <SelectBox
                options={SRSUM_YN}
                defaultOption={t('전체')}
                state={srSumYn}
                setState={setSrSumYn}
                stateKey='srSumYn'
                initiateKey={srSumYn.srSumYn}
                filterbar='filter-1-left'
                optionHeight='height-md'
              />
            </div>
            <div className='inputForm-row-fit'>
              <span className='calendarLabel'>{t('적발일자')}</span>
              <div className='inputForm-col'>
                <div className='flex-basic'>
                  <input
                    id='startInput'
                    type='text'
                    value={searchOption.sDateStart}
                    onMouseDown={onClickRangeInput}
                    onChange={(e) => onChangeRangeInput(e, 'sDateStart', setRangeState, setSearchOption)}
                  />
                  <span className='inputDash'> ~ </span>
                  <input id='endInput' type='text' value={searchOption.sDateEnd} onMouseDown={onClickRangeInput} onChange={(e) => onChangeRangeInput(e, 'sDateEnd', setRangeState, setSearchOption)} />
                </div>
                {visibleRangePicker && (
                  <div className='rangePickerWrapper'>
                    <RangePicker state={rangeState} setState={setRangeState} setVisible={setVisibleRangePicker} />
                  </div>
                )}
              </div>
            </div>
            <div className='inputForm-row-fit'>
              <span className='calendarLabel'>{t('조치일자')}</span>
              <div className='inputForm-col'>
                <div className='flex-basic'>
                  <input
                    id='startInput2'
                    type='text'
                    value={searchOption.srDateStart}
                    onMouseDown={onClickRangeInput2}
                    onChange={(e) => onChangeRangeInput(e, 'srDateStart', setRangeState2, setSearchOption)}
                  />
                  <span className='inputDash'> ~ </span>
                  <input
                    id='endInput2'
                    type='text'
                    value={searchOption.srDateEnd}
                    onMouseDown={onClickRangeInput2}
                    onChange={(e) => onChangeRangeInput(e, 'srDateEnd', setRangeState2, setSearchOption)}
                  />
                </div>
                {visibleRangePicker2 && (
                  <div className='rangePickerWrapper'>
                    <RangePicker state={rangeState2} setState={setRangeState2} setVisible={setVisibleRangePicker} setVisible2={setVisibleRangePicker2} />
                  </div>
                )}
              </div>
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col'>
                <Input
                  placeholder={t('적발장소')}
                  label=''
                  type='text'
                  id='sLocation'
                  name='sLocation'
                  state={searchOption}
                  setState={setSearchOption}
                  inputRef={sLocationRef}
                  onKeyDown={loginOnEnterKeyDown}
                />
              </div>
            </div>
          </div>
          <div className='inputsWrapper'>
            <div className='secondSearchOption'>
              <div className='flex-basic textBtnGroup'>
                <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
                {/* <BtnGhost onClick={onClickSearch} className='searchBtn'>
                  {t('검색')}
                  <span className='shortcut-f9'>F9</span>
                </BtnGhost> */}
                <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
                <div className='searchResult'>
                  {t('총')}
                  <span>{tableState.length}</span>
                  {t('개')}
                </div>
                <div className='inputForm-row'>
                  <div className='inputForm-col withLabelComCf'>
                    <label htmlFor='listCount'>{t('보기 설정')}</label>
                    <SelectBox
                      options={listCountComCdList}
                      defaultOption={listCount[LIST_COUNT]}
                      state={listCount}
                      setState={setListCount}
                      stateKey={LIST_COUNT}
                      initiateKey={listCount[LIST_COUNT]}
                      setTableLinesAPI={patchUserMenuAPI}
                      optionHeight='height-sm'
                      rsearch
                    />
                  </div>
                </div>
              </div>
              <div className='flex-basic iconBtnGroup'>
                {renderCreateBtn()}
                {renderExcelBtn()}
                {/* {renderPrintBtn()} */}
              </div>
            </div>
          </div>
        </SearchOptions>
        <TuiGridWrapper componentRef={componentRef}>
          <TuiGrid
            data={tableState}
            columns={columns}
            perPage={Number(listCount[LIST_COUNT])}
            excelBtn={excelBtn}
            setExcelBtn={setExcelBtn}
            usePagenation
            onClickRow={onClickRow}
            // height={560}
            // visibleTotalCount
            scrollX
            height={tuiHeight}
            frozenCount={frozenCount}
          />
        </TuiGridWrapper>
      </Root>
      {loading && (
        <LoadingModalBackground>
          <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
        </LoadingModalBackground>
      )}
      <Portal openModal={openModal?.status}>
        {openModal.status && openModal.type === 'safePoint' && (
          <SafePointModal getReportListAPI={getSafePointtAPI} setOpenModal={setOpenModal} auth={auth} code={{ hCd: userInfo.hCd, sCd: userInfo.sCd, sSeq: openModal.code.sSeq }} />
        )}
      </Portal>
    </>
  );
};

export default Main;
