import { createGlobalStyle } from 'styled-components';

const styled = { createGlobalStyle };
const GlobalStyles = styled.createGlobalStyle`
  @import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/static/pretendard.css');

  :root {
    --vh: 100%;
    --app-height: 100%;
    --ck-color-base-background: ${({ theme }: { theme: any }) => theme.board};
    --ck-color-dropdown-panel-background: ${({ theme }: { theme: any }) => theme.board};
    --ck-color-split-button-hover-background: ${({ theme }: { theme: any }) => theme.board};
  }
  /* iOS only */
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available; //크롬에서안될 상황 대비 ios 노치 대응
  }
  html {
    width: 100%;
    height: -webkit-fill-available;
    /* 익스플로러 IE 10 확대 제어 */
    -ms-content-zooming: none;
  }
  body {
    padding: 0;
    margin: 0;
    width: 100%;
    /* height: 100vh; */
    height: var(--app-height);
    height: -webkit-fill-available;
    padding-bottom: env(safe-area-inset-bottom); //ios 노치 대응
    overflow: hidden;
    touch-action: none; //터치제어
    display: flex;
    background-color: ${({ theme }: { theme: any }) => theme.background};
  }

  /* 가로모드 */
  @media (orientation: landscape) {
    html {
    }
  }
  /* 세로모드 */
  @media (orientation: portrait) {
    html {
    }
  }
  * {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
    letter-spacing: -0.025rem;
    line-height: 1.5rem;
    font-family: 'Pretendard Variable', Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic',
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
    font-weight: 500;
    -webkit-tap-highlight-color: transparent;
    /* color: ${({ theme }: { theme: any }) => theme.text_disabled}; */
  }
  h1 {
    font-size: 2.5rem;
    letter-spacing: -0.05rem;
    line-height: 3.4rem;
  }
  h2 {
    font-size: 2.25rem;
    line-height: 3rem;
  }
  h3 {
    font-size: 1.75rem;
    line-height: 2.6rem;
  }
  h4 {
    font-size: 1.5rem;
    line-height: 2.4rem;
  }
  h5 {
    font-size: 1.25rem;
    line-height: 2.2rem;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  h6 {
    font-size: 1.125rem;
    line-height: 2rem;
  }
  .text-sm {
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem; /* 20px */
  }
  .text-xs {
    font-size: 0.75rem; /* 12px */
    line-height: 1rem; /* 16px */
  }
  .text-lg {
    font-size: 1.125rem; /* 18px */
    line-height: 1.75rem; /* 28px */
  }
  text-xl {
    font-size: 1.25rem; /* 20px */
    line-height: 1.75rem; /* 28px */
  }
  p {
  }
  .text_primary {
    color: ${({ theme }: { theme: any }) => theme.text_primary} !important;
  }
  .text_secondary {
    color: ${({ theme }: { theme: any }) => theme.text_secondary} !important;
  }
  .text_tertiary {
    color: ${({ theme }: { theme: any }) => theme.text_tertiary} !important;
  }
  .text_disabled {
    color: ${({ theme }: { theme: any }) => theme.text_disabled} !important;
  }
  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
    color: inherit;
  }
  ul,
  li {
    list-style: none;
  }
  li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  label {
    white-space: nowrap;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
  }
  img {
    object-fit: cover;
    vertical-align: bottom;
  }
  input,
  select {
    border: none;
    font-size: 1rem;
    width: 100%;
    height: 2.5rem;
    padding: 0 0.75rem;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    border-radius: 0.25rem;
    /* transition: all ease-in-out 0.2s; */
  }
  select {
    padding: 0 0.5rem;
  }
  @media (min-width: 1024px) {
    input:focus,
    select:active {
      outline: 2px solid #3699ff;
      background-color: ${({ theme }: { theme: any }) => theme.background};
    }
  }
  input::placeholder {
    color: rgb(126, 130, 153);
  }
  :-ms-input-placeholder {
    color: #b5b5c3 !important;
  }
  input[type='checkbox'],
  input[type='radio'] {
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 0.5rem;
    cursor: pointer;
  }
  button {
    font-size: 1rem;
    text-transform: uppercase;
    height: 3rem;
    padding: 0 2rem;
    cursor: pointer;
    border-radius: 4px;
    /* transition: all ease 0.2s; */
    color: #fff;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    user-select: none;
    &:hover {
      background-color: #313442;
    }
  }
  .btn-small {
    padding: 0 0.5rem;
    border-radius: 2px;
    font-size: 0.875rem;
    height: 2.5rem;
  }

  body {
    -webkit-overflow-scrolling: touch; /* iOS 스크롤 가속화 */
  }

  ::-webkit-scrollbar {
    width: 0.75rem;
    height: 0.75rem;
  }

  ::-webkit-scrollbar-track {
    width: 2rem;
    background-color: transparent !important;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: ${({ theme }: { theme: any }) => theme.scrollBar};
    background-clip: padding-box;
    border: 3px solid transparent;
  }
  ::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
  }
  .flexColumn {
    display: flex;
    flex-direction: column;
  }
  .flexRow {
    display: flex;
    flex-direction: row;
  }
  .flexRowEm {
    display: flex !important;
    flex-direction: row !important;
  }
  .flexColEm {
    display: flex !important;
    flex-direction: column !important;
  }
  .flex-flex {
    display: flex;
    justify-content: space-between;
  }
  .flex-basic {
    display: flex;
    align-items: center;
  }
  .flex-col {
    display: flex;
    flex-direction: column;
  }
  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
  }
  .flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .gap25 {
    gap: 0.25rem;
  }
  .gap50 {
    gap: 0.5rem;
  }
  .gap75 {
    gap: 0.75rem;
  }
  .shrink-0 {
    flex-shrink: 0;
  }
  .thin {
    font-weight: 200;
  }
  .light {
    font-weight: 300;
  }
  .regular {
    font-weight: 400;
  }
  .medium {
    font-weight: 500;
  }
  .bold {
    font-weight: 600;
  }
  .font_semibold {
    font-weight: ${({ theme }: { theme: any }) => theme.font_semibold};
  }
  .text-center {
    text-align: center;
  }
  .text-left {
    text-align: left;
    padding-left: 0.5rem;
  }
  .text-right {
    text-align: right;
    padding-right: 0.5rem;
  }
  .b-color {
    color: #7e8299;
  }
  .p-color {
    color: #3f4254;
  }
  .s-color {
    color: rgb(126, 130, 153);
  }
  .red {
    color: #f42a3f;
    fill: #f42a3f;
  }
  .green {
    color: #48d89e;
    fill: #48d89e;
  }
  .blue {
    color: rgb(62, 151, 255);
    fill: rgb(62, 151, 255);
  }
  .shadow {
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.03);
  }
  .show {
    display: block;
  }
  .hide {
    display: none;
  }
  .blur {
    opacity: 0.5;
    pointer-events: none;
  }
  svg {
    cursor: pointer;
  }
  svg.icon {
    width: 20px;
    height: 20px;
    & * {
      transition: all ease 0.2s;
      fill: #b5b5c3;
    }
  }

  .col-w100 {
    width: 100%;
  }
  .col-w95 {
    width: 95%;
  }
  .col-w90 {
    width: 90%;
  }
  .col-w85 {
    width: 85%;
  }
  .col-w80 {
    width: 80%;
  }
  .col-w75 {
    width: 75%;
  }
  .col-w70 {
    width: 70%;
  }
  .col-w65 {
    width: 65%;
  }
  .col-w60 {
    width: 60%;
  }
  .col-w55 {
    width: 55%;
  }
  .col-w50 {
    width: 50%;
  }
  .col-w45 {
    width: 45%;
  }
  .col-w40 {
    width: 40%;
  }
  .col-w35 {
    width: 35%;
  }
  .col-w30 {
    width: 30%;
  }
  .col-w28 {
    width: 28%;
  }
  .col-w25 {
    width: 25%;
  }
  .col-w20 {
    width: 20%;
  }
  .col-w15 {
    width: 15%;
  }
  .col-w12 {
    width: 12%;
  }
  .col-w10 {
    width: 10%;
  }
  .col-w7 {
    width: 7%;
  }
  .col-w5 {
    width: 5%;
  }
  .col-w0 {
    width: 0%;
  }

  .col-260 {
    width: 260px;
  }
  .col-240 {
    width: 240px;
  }
  .col-220 {
    width: 220px;
  }
  .col-200 {
    width: 200px;
  }
  .col-190 {
    width: 190px;
  }
  .col-180 {
    width: 180px;
  }
  .col-170 {
    width: 170px;
  }
  .col-160 {
    width: 160px;
  }
  .col-150 {
    width: 150px;
  }
  .col-140 {
    width: 140px;
  }
  .col-130 {
    width: 130px;
  }
  .col-120 {
    width: 120px;
  }
  .col-110 {
    width: 110px;
  }
  .col-100 {
    width: 100px;
  }
  .col-90 {
    width: 90px;
  }
  .col-80 {
    width: 80px;
  }
  .col-70 {
    width: 70px;
  }
  .col-60 {
    width: 60px;
  }
  .col-55 {
    width: 55px;
  }
  .col-50 {
    width: 50px;
  }
  .col-40 {
    width: 40px;
  }
  .col-30 {
    width: 30px;
  }
  .col-20 {
    width: 20px;
  }
  .col-10 {
    width: 10px;
  }

  #root {
    width: 100%;
    display: flex;
    /* height: 100vh; */
    /* min-height: -webkit-fill-available; */
  }

  .background {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.3);
  }
  /* 수정필요 */
  .inputForm-wrapper {
    width: 100%;
    height: 100%;
    border: 1px dashed #e1e5ea;
    border-radius: 6px;
    padding: 30px;
  }
  .inputForm-scroll-wrapper {
    width: 100%;
    height: 100%;
    border: 1px dashed #e1e5ea;
    border-radius: 0.5rem;
    padding: 30px 12px 30px 30px;
  }
  .inputForm-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .inputForm-body {
    width: 100%;
    height: 100%;
  }
  .inputForm-scroll-wrapper .inputForm-head {
    padding-right: 18px;
  }
  .inputForm-scroll-wrapper .inputForm-body {
    padding-right: 12px;
  }
  .inputForm-body .inputForm-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .inputForm-body .inputForm-row .plainText {
    width: inherit;
  }
  .inputForm-body .inputForm-row .required {
    &::after {
      content: '*';
      padding: 0 0.25rem;
      color: red;
    }
  }
  .inputForm-row:last-of-type {
    margin-bottom: 0;
  }
  .inputForm-body .inputForm-row .col-w50 {
    width: calc(50% - 6px);
  }
  .inputForm-body .inputForm-row .col-w30 {
    width: calc(33.3% - 0.5rem);
  }

  .inputForm-body .inputForm-row .inputForm-col {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .inputsWrapper .inputForm-col.withLabel div div {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    border-radius: 0.25rem;
    overflow: hidden;
    @media (min-width: 1024px) {
      :focus-within {
        outline: 2px solid #3699ff;
      }
    }
    label {
      font-size: 0.875rem;
      padding: 0 0.5rem;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      cursor: pointer;
      user-select: none;
    }
    input {
      outline: none;
      border-radius: 0;
      width: 3.25rem;
      padding: 0;
      text-align: center;
      border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    }
  }
  .inputsWrapper .inputForm-col.withLabelComCf {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    border-radius: 0.25rem;
    gap: 0;
    position: relative;
    @media (max-width: 1024px) {
      position: static;
    }
    label {
      font-size: 0.875rem;
      padding: 0 0.75rem;
      user-select: none;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      font-weight: 600;
    }
    > div > div > ul,
    > div > ul {
      position: static;

      > li:first-child {
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        /* border-radius: 0; */
        border-radius: 0 0.25rem 0.25rem 0;
        border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
        outline: none;
      }
    }
  }
  .inputsWrapper .datePickerWrapper input.inputMask {
    /* background-color: gray; */
    font-size: 0.875rem;
    height: 2.5rem;
    width: 6.5rem;
    padding: 0;
    text-align: center;
    outline: none;
    &:focus {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
    }
  }
  .inputsWrapper.sensor-results {
    align-items: center;
    .icon-menu {
      cursor: pointer;
      user-select: none;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      :hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
    .sensor-result-header,
    .sensor-result-data {
      user-select: none;
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      height: 2.5rem;
      border-radius: 0.25rem;
      overflow: hidden;
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      > div {
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 0.5rem;
        gap: 0.25rem;
        font-weight: 500;
        > span {
          font-weight: 400;
          display: flex;
          border-radius: 0.25rem;
          overflow: hidden;
          color: ${({ theme }: { theme: any }) => theme.text_secondary};
        }
        > div {
          padding: 0 0.25rem;
        }
      }
      > span {
        padding: 0 0.5rem;
      }
    }
  }
  .inputForm-body .inputForm-row .inputForm-col label {
    line-height: 1.5;
  }
  .inputForm-body .inputForm-row .iscontents-s input,
  .inputForm-body .inputForm-row .iscontents-s select {
    width: calc(100% - 2rem);
  }
  .inputForm-body .inputForm-row .iscontents-m input,
  .inputForm-body .inputForm-row .iscontents-m select {
    width: calc(100% - 3rem);
  }

  .inputForm-body .inputForm-row .inputForm-col input,
  .inputForm-body .inputForm-row .inputForm-col select {
    /* border: none; */
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    /* outline: 1px solid ${({ theme }: { theme: any }) => theme.outline}; */
  }

  .inputForm-body .inputForm-row .inputForm-col input:focus,
  .inputForm-body .inputForm-row .inputForm-col select:focus {
    outline: 2px solid #3699ff;
  }

  .inputForm-body .inputForm-row .inputForm-col .flex-between small {
    margin: 0 3px;
  }

  .inputForm-body .inputForm-row .image-form > :first-child {
    height: calc(100% - 2rem);
    padding: 10px;
  }

  .dash {
    padding: 0 0.25rem;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
  }

  .fileBoxWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .fileButton {
      color: ${({ theme }: { theme: any }) => theme.btn_text};
      background-color: ${({ theme }: { theme: any }) => theme.filled_violet};
      /* background-color: #8950fc; */
      padding: 0 1rem;
      border-radius: 0.25rem;
      font-weight: normal;
      cursor: pointer;
      &:hover {
        color: #eaeaea;
        background-color: rgba(137, 80, 252, 0.6);
      }
    }
  }

  .hiddenFileBox {
    display: none;
  }

  input::file-selector-button {
    display: none;
  }

  .searchBox {
    width: 20%;

    &::after {
      display: none;
    }
  }

  .minHeightFit {
    min-height: fit-content !important;
  }

  .minWidthFit {
    min-width: fit-content !important;
  }

  .datePickerWrapper {
    position: relative;
    &.noPosition {
      position: inherit;
    }
    &.filter-1-left,
    &.filter-1-center,
    &.filter-1-right {
      .react-datepicker__tab-loop {
        @media (max-width: 1023px) {
          position: fixed;
          top: 0;
          z-index: 10;
        }
      }
    }
    .react-datepicker-wrapper {
      width: 100% !important;
    }

    select {
      background-color: transparent;
      outline: none;

      -moz-appearance: none;
      appearance: none;
      -webkit-appearance: none;
      border: 0;
      /* padding: 10px 30px 10px 10px; */
      padding: 0.5rem 0.75rem;
      border-radius: 4px;
      outline: 0;
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      cursor: pointer;
      text-align: center;
      :hover {
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
      }
      :active {
        outline: rgb(54, 153, 255) solid 2px;
        background-color: rgba(243, 246, 249, 0.3);
      }
    }
    select:focus {
      background-color: ${({ theme }: { theme: any }) => theme.background};
    }
    .react-datepicker {
      outline: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      border: none;
      .react-datepicker__triangle {
        display: none;
      }
      .react-datepicker__triangle::before {
        border-bottom-color: ${({ theme }: { theme: any }) => theme.outline} !important;
      }
    }
    .react-datepicker-popper {
      z-index: 100;
      padding-top: 0.5rem;
      .react-datepicker {
        box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
      }
    }
    .react-datepicker__header {
      border-bottom: none;
    }
    .react-datepicker__tab-loop {
      .react-datepicker__month-container {
        background-color: ${({ theme }: { theme: any }) => theme.board};
        > :first-child {
          background-color: ${({ theme }: { theme: any }) => theme.board};
        }

        .custom-header {
          display: flex;
          align-items: center;
          justify-content: space-around;
          // range picker와 스타일 통일
          justify-content: space-between;
          padding: 0.25rem 1rem;

          .selectGroup {
            display: flex;
            gap: 0.75em;
            > .selectBox {
              position: relative;
              > span {
                position: absolute;
                right: 0.25rem;
                top: 50%;
                transform: translateY(-50%);
                user-select: none;
                pointer-events: none;
                font-size: 1.125rem;
                color: ${({ theme }: { theme: any }) => theme.text_disabled};
              }
            }
          }
          select {
            width: 30%;
            width: 5rem;
            /* &::after {
              content: '월';
              color: ${({ theme }: { theme: any }) => theme.color.zinc_300};
            } */
            cursor: pointer;
            option {
              text-align: left;
            }
          }
          > div:not(.selectGroup) {
            height: 2.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.25rem;
            /* &:hover {
              background-color: ${({ theme }: { theme: any }) => theme.tonal};
            } */
            &:first-child,
            &:last-child {
              width: 1.5rem;
              height: 1.5rem;
              cursor: pointer;
              user-select: none;
              color: ${({ theme }: { theme: any }) => theme.text_primary};
              background-color: ${({ theme }: { theme: any }) => theme.tonal};
            }
          }

          /* > :nth-child(2) {
            padding: 0 1rem;
          } */
        }
        .react-datepicker__day {
          color: ${({ theme }: { theme: any }) => theme.text_primary};
        }
        .react-datepicker__day--selected,
        .react-datepicker__day--keyboard-selected {
          color: ${({ theme }: { theme: any }) => theme.btn_text};
          background-color: ${({ theme }: { theme: any }) => theme.selected_primary};
          border-radius: 0.25rem;
          // range picker와 스타일 통일
          height: 1.625rem !important;
          width: 2.5rem !important;
          margin: 0 auto;
          border-radius: 1rem;
          border-radius: 1rem;
          position: relative;
          /* &:after {
            content: ' ';
            display: flex;
            background-color: white;
            width: 1rem;
            height: 0.25rem;
            position: absolute;
            bottom: 0;
            border-radius: 0.25rem;
          } */
          &:hover {
            height: 2.25rem !important;
            width: 2.75rem !important;
            border-radius: 0.25rem;
          }
        }
        /* .react-datepicker__day:hover,
        .react-datepicker__day:focus {
          color: ${({ theme }: { theme: any }) => theme.text_secondary};
          background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
        } */
        .react-datepicker__day-names {
          // height: 2.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 0.4rem;
          > div {
            user-select: none;
            color: ${({ theme }: { theme: any }) => theme.text_disabled};
            width: 2.5rem;
            flex-grow: 1;
            // height: 1rem;
          }
        }

        .react-datepicker__month {
          margin: 0 0.8rem;
          text-align: center;
          padding-bottom: 0.75rem;
          .react-datepicker__week,
          .react-datepicker__day-names {
            height: 2.25rem;
            display: flex;
            justify-content: center;
            align-items: center;
            > div {
              width: 2.5rem;
              height: inherit;
              justify-content: center;
              align-items: center;
              display: flex;
              // range picker와 스타일 통일
              width: 2.75rem;
              height: 2.25rem;
              font-weight: 400;
              font-size: 0.75rem;
              padding-top: 0.125rem;
              &:hover {
                background-color: ${({ theme }: { theme: any }) => theme.selected_primary};
                color: white;
              }
            }
          }
        }
      }
    }

    .react-datepicker__day--selected {
      color: ${({ theme }: { theme: any }) => theme.color.zinc_100};
      border-radius: 1.042em;
      right: 2px;
    }

    .applyBorder {
      border: 1px solid red;
    }
  }
  button.searchBtn {
    font-size: 0.875rem;
    font-weight: 600;
    width: fit-content !important;
    padding: 0.75rem !important;
    padding-right: 0.25rem !important;
    gap: 0.5rem;
    box-shadow: none;
    border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
    .shortcut-f9 {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      border-radius: 0.25rem;
      font-size: 0.75rem;
      letter-spacing: 0;
      width: 1.75rem;
      height: 1.75rem;
      letter-spacing: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid ${({ theme }: { theme: any }) => theme.outline_em};
      font-weight: 600;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    appearance: textfield;
  }

  .required {
    &::after {
      content: '*';
      padding: 0 0.25rem;
      color: red;
    }
  }
  .emtpyMessage {
    text-align: center;
  }
  .reactSelect {
    > :nth-child(3) {
      padding: 0;
      margin: 0;
      /* background-color: rgba(243, 246, 249, 0.3); */
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 0.25rem;
      height: fit-content;
      > div {
        padding: 0 0.75rem;
        margin: 0;
        > div {
          margin: 0;
        }
      }
      > div:nth-child(1) > div {
        color: ${({ theme }: { theme: any }) => theme.text_primary};
      }
      > div:nth-child(2) > div {
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      }
    }
    .option-hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep} !important;
      &:hover {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_deep} !important;
      }
    }
  }
  .modal {
    .w10 {
      width: 10%;
    }

    .w20 {
      width: 20%;
    }

    .w25 {
      width: 25%;
    }

    .w30 {
      width: 30%;
    }

    .w70 {
      width: 70%;
    }

    .w75 {
      width: 75%;
    }

    .w80 {
      width: 80%;
    }

    .w90 {
      width: 90%;
    }
    .w100 {
      width: 100%;
    }
  }
  .w100 {
    width: 100%;
  }
  .underline {
    text-decoration: underline;
    cursor: pointer;
  }
  .noData {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-size: 0.875rem;
    color: rgb(204, 204, 204);
    padding: 1rem;
  }
  .contents {
    flex-shrink: 1;
    width: 100%;
    /* flex-grow: 1; */
    /* background-color: blue; */
    height: inherit;
    height: 100vh;
    height: calc(100vh - 0);
    /* height: -webkit-fill-available; */
    /* height: fill-available; */
    overflow-y: hidden;
    padding: 0.5rem 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .content-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      height: inherit;
      gap: 0.5rem;
      .tui-overflow {
        overflow-y: auto;
      }
      @media (min-width: 1024px) {
        gap: 0.5rem;
      }
      &.setting-medical {
        overflow: auto;
        @media (min-width: 1024px) {
          height: 100%;
          overflow: hidden;
        }
      }
    }
    .oneColumn {
      > div:last-child,
      div.showRoot {
        width: 100%;
        background-color: ${({ theme }: { theme: any }) => theme.board};
        padding: 0;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        height: inherit;
        /* border: 1px solid ${({ theme }: { theme: any }) => theme.outline}; */
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.03);
        flex-grow: 1;
      }
    }
    .twoColumn {
      flex-direction: row;
      > div {
        background-color: ${({ theme }: { theme: any }) => theme.board};
        padding: 0;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0;
        /* border: 1px solid ${({ theme }: { theme: any }) => theme.outline_status}; */
        overflow: hidden;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.03);
      }

      @media screen and (max-width: 1023px) {
        flex-direction: column !important;
        /* overflow: scroll !important; */
      }
    }
    .column64 {
      > div:nth-child(1) {
        flex-grow: 1;
        width: 60%;
      }
      > div:nth-child(2) {
        flex-shrink: 0;
        width: 40%;
      }
    }
    .max600 {
      max-width: 600px;
    }
    .column-55 {
      > div {
        flex: 1 1 50%;
        overflow-x: hidden;
      }
    }
    .max800 {
      > div:nth-child(2) {
        max-width: 800px;
      }
    }
    .setting-medical {
      > div {
        flex-grow: 0;
        width: 100%;
        background-color: ${({ theme }: { theme: any }) => theme.board};
        padding: 0;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow-y: auto;
        border-radius: 1rem;
        @media (max-width: 767px) {
          height: fit-content;
          flex-shrink: 0;
        }
      }
      @media (min-width: 768px) {
        flex-direction: row;
        > div {
          flex-grow: 1;
          width: calc(50% - 0.25rem);
        }
      }
    }
    .mediaSet768 {
      height: fit-content;
      @media (min-width: 768px) {
        height: 100%;
      }
    }
    .mediaSet1024 {
      height: fit-content;
      @media (min-width: 1024px) {
        height: 100%;
      }
    }
    .content-overflow {
      flex-grow: 1;
      overflow: hidden;
    }
    .inputFormsWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      color: ${({ theme }: { theme: any }) => theme.text_primary};
    }
  }
  .contents.dashboard {
    overflow: auto;
    @media screen and (max-width: 767px) {
      ::-webkit-scrollbar {
        width: 0;
      }
    }
  }
  .setting_table_lines {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    label {
      font-size: 0.875rem;
      Button {
        height: 2rem;
      }
    }
  }
  .rangePickerWrapper,
  .rangePickerWrapper2 {
    position: relative;
  }
  .secondSearchOption {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    @media (max-width: 1023px) {
      overflow: auto;
    }
    /* overflow: auto; */
    &::-webkit-scrollbar {
      height: 0rem;
    }
    ul {
      height: 2.5rem;
    }
    > div {
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      gap: 0.5rem;
    }
    > div button {
      font-size: 0.875rem;
      font-weight: 600;
      height: 2.5rem;
      user-select: none;
    }
  }
  .textBtnGroup button {
    padding: 0 0.75rem;
    width: 4.5rem;
    border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
    }
  }
  .textBtnGroup .searchBtn {
    width: fit-content;
    padding: 0.75rem;
    padding-right: 0.25rem;
    gap: 0.5rem;
    box-shadow: none;
    border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    .shortcut-f9 {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      border-radius: 0.25rem;
      font-size: 0.75rem;
      letter-spacing: 0;
      width: 1.75rem;
      height: 1.75rem;
      letter-spacing: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid ${({ theme }: { theme: any }) => theme.outline_em};
      font-weight: 600;
    }
  }
  .iconBtnGroup {
    button {
      padding: 0 0.25rem;
      width: fit-content;
      min-width: 5rem;
      border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      &:hover {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
      }
      &.btn-red,
      &.btn-blue {
        min-width: fit-content;
        padding-left: 0.75rem;
        padding-right: 1rem;
      }
      span {
        font-variation-settings: 'FILL' 1, 'wght' 400, 'opsz' 20;
        font-size: 20px;
      }
      &.padding-more {
        padding: 0 0.75rem;
        > a {
          font-weight: 600;
        }
      }
    }
    &.autoWidth {
      button {
        width: fit-content;
        padding-left: 0.5rem;
        padding-right: 0.75rem;
      }
    }
  }
  .icon-ms-xlsx {
    background-color: #107b42;
    color: white;
    width: 1.25rem;
    border-radius: 0.125rem;
    font-weight: 600;
    font-size: 1rem !important;
  }
  .gap05 {
    gap: 0.5rem;
  }
  .gap10 {
    gap: 1rem;
  }
  .gap15 {
    gap: 1.5rem;
  }
  .gap20 {
    gap: 2rem;
  }
  .loader-background {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .centered-content {
    height: inherit;
    width: inherit;
    display: flex;
    > span {
      display: flex;
      justify-content: center;
      align-items: center;
      height: inherit;
      width: 100%;
    }
    &.full {
      height: 100%;
      width: 100%;
    }
  }
  .ck.ck-powered-by {
    display: none;
  }
  .ck.ck-button:not(.ck-disabled):hover,
  a.ck.ck-button:not(.ck-disabled):hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
  }
  .ck-reset_all :not(.ck-reset_all-excluded *),
  .ck.ck-reset_all {
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .ck.ck-button,
  a.ck.ck-button {
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
  }
  .ck.ck-toolbar {
    background-color: ${({ theme }: { theme: any }) => theme.board};
    border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  }
  .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
    border-color: ${({ theme }: { theme: any }) => theme.outline};
  }
  .ck.ck-toolbar .ck.ck-toolbar__separator {
    background-color: ${({ theme }: { theme: any }) => theme.outline};
  }
  .ck.ck-editor__editable_inline {
    border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  }
  .ck.ck-editor__main > .ck-editor__editable {
    /* background-color: ${({ theme }: { theme: any }) => theme.board}; */
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .ck.ck-button.ck-on:not(.ck-disabled),
  a.ck.ck-button.ck-on:not(.ck-disabled) {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
  }
  .ck.ck-button.ck-on:not(.ck-disabled):hover,
  a.ck.ck-button.ck-on:not(.ck-disabled):hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
  }
  .ck.ck-dropdown__panel {
    background-color: ${({ theme }: { theme: any }) => theme.board};
    border: 1px solid ${({ theme }: { theme: any }) => theme.outline_em};
  }
  .ck.ck-list__item .ck-button.ck-on:focus:not(.ck-switchbutton):not(.ck-disabled) {
    border-color: ${({ theme }: { theme: any }) => theme.outline_em};
  }
  .ck.ck-list__item .ck-button:hover:not(.ck-disabled) {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
  }
  .ck.ck-list__item .ck-button.ck-on,
  .ck.ck-list__item .ck-button.ck-on:hover:not(.ck-disabled) {
    background-color: ${({ theme }: { theme: any }) => theme.selected_primary};
  }
  .ck.ck-balloon-panel {
    // background-color: ${({ theme }: { theme: any }) => theme.board};
    border: 1px solid ${({ theme }: { theme: any }) => theme.outline_em};
  }
  .ck.ck-input {
    background-color: ${({ theme }: { theme: any }) => theme.board};
    color: ${({ theme }: { theme: any }) => theme.text_primary};
    border: 1px solid ${({ theme }: { theme: any }) => theme.outline_em};
    box-shadow: none;
  }
  .ck.ck-input:focus {
    outline: 1px solid ${({ theme }: { theme: any }) => theme.outline_em};
    box-shadow: none;
  }
  .ck.ck-labeled-field-view > .ck.ck-labeled-field-view__input-wrapper > .ck.ck-label {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .ck.ck-balloon-panel[class*='arrow_n']:after {
    border-color: transparent transparent ${({ theme }: { theme: any }) => theme.board} transparent;
  }
  .ck-reset_all :not(.ck-reset_all-excluded *),
  .ck.ck-reset_all {
    /* color: ${({ theme }: { theme: any }) => theme.board}; */
  }
  button.cd.cd-button.cd-disabled.ck-off.ck-button_width-text {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
    color: ${({ theme }: { theme: any }) => theme.text_disabled};
  }

  strong {
    font-weight: 600;
  }

  .printWorkerInfo {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2rem;

    td {
      font-size: 0.825rem;
    }

    > img {
      max-height: 35vh;
      max-width: 50%;
      object-fit: contain;
      padding-bottom: 1.5rem;
    }

    .noUser {
      width: 100%;
      font-size: 12rem;
      text-align: center;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    }

    .contents {
      height: fit-content;
      flex-direction: row;

      > :first-child {
        border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
        border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      }
      > :last-child {
        border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
        border-right: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      }
    }
  }

  @media print {
    .printWorkerInfo {
      max-height: 100vh !important;
      .custom-table {
        width: 100%;
        height: fit-content;
        max-height: 65vh !important;
        border-collapse: collapse;
      }

      .custom-table td:nth-child(1),
      .custom-table td:nth-child(3) {
        font-weight: bold;
        min-width: 8rem;
        width: 20vw;
      }

      .custom-table td:nth-child(2),
      .custom-table td:nth-child(4) {
        width: 30vw;
        max-width: 30vw;
        overflow: hidden;
      }

      .custom-table th,
      .custom-table td {
        border: 1px solid #ccc;
        padding: 8px;
        text-align: left;
      }

      .custom-table th {
        background-color: #f4f4f4;
        color: #333;
      }

      .custom-table tbody tr:nth-child(odd) {
        background-color: #f9f9f9;
      }
    }

    .full-page-image {
      height: 100% !important;
      page-break-after: avoid;
      page-break-before: avoid;
    }

    .printable-contents {
      width: 100%; /* Ensure the contents are full width */

      .image-container {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        width: 100%; /* Ensure the container takes the full width */
        page-break-after: always; /* Ensure each image starts on a new page */
        page-break-inside: avoid; /* Avoid breaking inside the container */

        > img {
          width: auto; /* Adjust width to fill the page */
          max-height: 95vh; /* Limit height to fit A4 page, adjusting margin as necessary */
          object-fit: contain; /* Ensures the aspect ratio is maintained */
        }

        .printWorkerInfo {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          page-break-inside: avoid; /* Avoid breaking inside the container */

          .workerInfoImg {
            max-height: 30vh;
            max-width: 50vw;
            object-fit: contain;
            padding-bottom: 1.5rem;
          }
        }
      }
    }

    .scrollableDiv {
      width: 100vw; /* Full width of the viewport */
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden; /* Prevent any overflow outside this div */
    }

    .scrollableDiv img {
      /* padding: 1rem; */
      width: 100%; /* this will ensure the image is always full width of its container */
      height: auto; /* maintain aspect ratio */
      max-height: 100vh;
      max-height: 100dvh;
      object-fit: contain; /* maintain aspect ratio without cropping */
      page-break-before: always; /* Ensure this image starts on a new page */
      page-break-after: always; /* Ensure no content follows on the same page */
      box-shadow: none; /* Remove any shadow effects during print */
    }

    @page {
      size: A4;
      margin: 12mm;
    }
  }
  .animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    @keyframes pulse {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
    }
  }
`;

export default GlobalStyles;
