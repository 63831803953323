import styled from 'styled-components';
import ControllBar from './ControllBar';
import TimelineBar from './TimelineBar';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { WebSDKcctv } from '../../../../utils/webSDKcctvClass';

const Root = styled.div``;

interface Props {
  visiblePanel: boolean;
  setVisiblePanel: Dispatch<SetStateAction<boolean>>;
  cctvOBJ: WebSDKcctv | undefined; // cctv 객체
  cctvDivisions: number; // cctv 분할
  onClickDivision: (number: 1 | 2 | 3 | 4) => void;
  selectedCameraInfo: SelectedCctvCameraInfo;
  setSelectedCameraInfo: Dispatch<SetStateAction<SelectedCctvCameraInfo>>;
  nvrList: IMainMenu[];
  setNvrList: Dispatch<SetStateAction<IMainMenu[]>>;
  recordData: Playback[];
  videoWindow: VideoWindow;
  onClickPlay: (reverse?: 'reverse') => void;
  onClickPause: () => void;
  capturePath: string;
  onClickSpeed: (speed: 'fast' | 'slow') => void;
  onClickStopAndPlay: (reverse?: 'reverse') => void;
}

const BottomMenu = ({
  visiblePanel, //
  setVisiblePanel,
  cctvOBJ,
  cctvDivisions,
  onClickDivision,
  selectedCameraInfo,
  setSelectedCameraInfo,
  nvrList,
  setNvrList,
  recordData,
  videoWindow,
  onClickPlay,
  onClickPause,
  capturePath,
  onClickSpeed,
  onClickStopAndPlay,
}: Props) => {
  // undefined인 경우 null 리턴
  if (cctvOBJ === undefined) return null;

  return (
    <Root>
      <ControllBar //
        visiblePanel={visiblePanel}
        setVisiblePanel={setVisiblePanel}
        cctvOBJ={cctvOBJ}
        cctvDivisions={cctvDivisions}
        onClickDivision={onClickDivision}
        selectedCameraInfo={selectedCameraInfo}
        setSelectedCameraInfo={setSelectedCameraInfo}
        nvrList={nvrList}
        setNvrList={setNvrList}
        videoWindow={videoWindow}
        onClickPlay={onClickPlay}
        onClickPause={onClickPause}
        capturePath={capturePath}
        onClickSpeed={onClickSpeed}
        onClickStopAndPlay={onClickStopAndPlay}
      />
      <TimelineBar //
        visiblePanel={visiblePanel}
        cctvOBJ={cctvOBJ}
        recordData={recordData}
        selectedCameraInfo={selectedCameraInfo}
        onClickPause={onClickPause}
        videoWindow={videoWindow}
      />
    </Root>
  );
};

export default BottomMenu;
