/**
 * 작성자 : 김광민
 * 날짜 : 2023.12.21
 * 경로 : 대시보드 - 굴진 진행현황, 터널 관리
 */

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const TunnelTdProgressBarCell = styled.div<{ progress: number }>`
  display: flex;
  border-radius: 3rem;
  height: 2rem;
  position: relative;
  overflow: hidden;
  &.grow {
    flex-grow: 1;
  }
  &.cornerEdge {
    border-radius: 0 1rem 1rem 0;
    .progressbar {
      border-radius: 0 1rem 1rem 0;
    }
    &.reverse {
      border-radius: 1rem 0 0 1rem;
      .progressbar {
        border-radius: 1rem 0 0 1rem;
      }
    }
  }
  &.reverse {
    flex-direction: row-reverse;
    .percentage {
      left: 0.375rem;
      right: auto;
    }
    .meter {
      right: 0.375rem;
      left: auto;
    }
  }
  &.innerCircle {
    .meter {
      left: 1rem;
    }
    &.reverse {
      .meter {
        right: 1rem;
        left: auto;
      }
    }
  }
  &.level1 {
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
  }
  &.level2 {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
  }
  &.level3 {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_violet};
  }
  &.level4 {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
  }
  &.level5 {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
  }
  > .progressbar {
    border-radius: 3rem;
    width: ${(props) => props.progress}%;
    height: 100%;
    transition: width 500ms ease-in-out;
    &.level1 {
      background-color: ${({ theme }: { theme: any }) => theme.filled_slate};
    }
    &.level2 {
      background-color: ${({ theme }: { theme: any }) => theme.filled_amber};
    }
    &.level3 {
      background-color: ${({ theme }: { theme: any }) => theme.filled_indigo};
    }
    &.level4 {
      background-color: ${({ theme }: { theme: any }) => theme.filled_green};
    }
    &.level5 {
      background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
    }
  }
  .meter,
  .point,
  .percentage {
    display: flex;
    align-items: center;
    height: 1.5rem;
    position: absolute;
    font-size: 0.875rem;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 1rem;
    padding: 0 0.5rem;
    font-weight: 400;
  }
  .meter {
    left: 0.375rem;
    color: ${({ theme }: { theme: any }) => theme.text_reverse};
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    .emphasize {
      color: ${({ theme }: { theme: any }) => theme.text_reverse};
    }
    &.level1 {
      background-color: ${({ theme }: { theme: any }) => theme.filled_slate};
    }
    &.level2 {
      background-color: ${({ theme }: { theme: any }) => theme.filled_amber};
    }
    &.level3 {
      background-color: ${({ theme }: { theme: any }) => theme.filled_indigo};
    }
    &.level4 {
      background-color: ${({ theme }: { theme: any }) => theme.filled_green};
    }
    &.level5 {
      background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
    }
  }
  .point {
    font-size: 0.75rem;
    color: ${({ theme }: { theme: any }) => theme.text_reverse};
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    &.start {
      right: 0.25rem;
    }
    &.end {
      left: 0.25rem;
    }
    &.level1 {
      background-color: ${({ theme }: { theme: any }) => theme.filled_slate_deep};
    }
    &.level2 {
      background-color: ${({ theme }: { theme: any }) => theme.filled_amber_deep};
    }
    &.level3 {
      background-color: ${({ theme }: { theme: any }) => theme.filled_indigo_deep};
    }
    &.level4 {
      background-color: ${({ theme }: { theme: any }) => theme.filled_green_deep};
    }
    &.level5 {
      background-color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
    }
  }
  .percentage {
    right: 0.375rem;
    background-color: ${({ theme }: { theme: any }) => theme.board};
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
    .emphasize {
      font-weight: 500;
    }
  }
  .emphasize {
    font-weight: 500;
  }
  &.small {
    height: 1.5rem;
    .meter,
    .percentage {
      font-size: 0.75rem;
    }
    .meter {
      left: 0;
    }
    .percentage {
      /* box-shadow: none; */
      height: 1rem;
    }
  }
`;

type Props = {
  rlength: number;
  length: number;
  size?: 'small';
  reverse?: boolean;
  cornerEdge?: boolean;
  innerCircle?: boolean;
  meter?: boolean;
  grow?: boolean;
  point?: 's' | 'e';
};

const TunnelTdProgressBar = ({ rlength, length, size, reverse = false, cornerEdge = false, innerCircle = false, meter = true, grow = true, point = 's' }: Props) => {
  const { t } = useTranslation();

  // 진행률 구하는 함수
  const getPercentage = () => {
    if (rlength === 0 || (length === 0 && rlength > 0)) {
      return 0;
    }
    return Math.floor((rlength / length) * 100);
  };

  // 진행률에 따라 색상 구하는 함수
  const getColor = () => {
    const percentage = getPercentage();
    if (percentage > 20 && percentage <= 40) {
      return 'level2';
    }
    if (percentage > 40 && percentage <= 60) {
      return 'level3';
    }
    if (percentage > 60 && percentage <= 80) {
      return 'level4';
    }
    if (percentage > 80 && percentage <= 100) {
      return 'level5';
    }
    return 'level1';
  };

  // meter 상태에 따라 차등 렌더링
  const renderValue = () => {
    if (meter) {
      return (
        <div className={`meter ${getColor()}`}>
          <span className='emphasize'>{rlength.toLocaleString()}</span>m
        </div>
      );
    }

    return <div className={`point ${getColor()} ${reverse ? 'start' : 'end'}`}>{point === 's' ? t('시점') : t('종점')}</div>;
  };

  return (
    <TunnelTdProgressBarCell
      className={`${getColor()} ${size ?? ''} ${reverse ? 'reverse' : ''} ${cornerEdge ? 'cornerEdge' : ''} ${innerCircle ? 'innerCircle' : ''} ${grow === true ? 'grow' : ''}`}
      progress={getPercentage()}
    >
      <div className={`progressbar ${getColor()}`}>
        {renderValue()}
        <div className='percentage'>
          <span className='emphasize'>{getPercentage()}</span>%
        </div>
      </div>
    </TunnelTdProgressBarCell>
  );
};

export default TunnelTdProgressBar;
