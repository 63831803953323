import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { FOREIGN_YN, LIST_COUNT } from '../../../_constants';
import { IUser, userState } from '../../../atoms';
import { IComCdList } from 'customTypes';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import Input from '../../../components/Input';
import SelectBox from '../../../components/SelectBox';
import SearchSelectBoxSm from '../../../components/SearchSelectBoxSm';
import RangePicker, { onChangeRangeInput } from '../../../components/RangePicker';
import { useSetSjcd } from '../../../utils/useSetSjcd';
import { todayYYYYMMDD } from '../../../utils/formatDate';
import useSetJobtype from '../../../utils/useSetJobtype';
import useOnKeydownF9 from '../../../utils/useOnKeydownF9';
import useSetListCount from '../../../utils/useSetListCount';
import { setComCdListState } from '../../../utils/setComCdListState';
import { apiGet } from '../../../services/_common';
import { BtnGhost } from '../../../components/Button';
import ShortcutButton from '../../../components/button/ShortcutButton';
import { useSetAuth } from '../../../utils/useSetAuth';
import { logPost } from '../../../services/log';

interface IIncompletedWorkerSearchOption {
  setIsFetching: Dispatch<SetStateAction<boolean>>; // 검색데이터 패칭상태
  setIsError: Dispatch<SetStateAction<boolean>>;
  tableState: any[];
  setTableState: Dispatch<SetStateAction<any[]>>;
  setRowKey: Dispatch<SetStateAction<string>>;
  listCount: any;
  setListCount: Dispatch<SetStateAction<any>>;
  setExcelBtn: Dispatch<SetStateAction<boolean>>;
  refetchFlag: boolean;
  setRefetchFlag: Dispatch<SetStateAction<boolean>>;
}

const IncompletedWorkerSearchOption = ({
  setIsFetching,
  setIsError,
  tableState,
  setTableState,
  setRowKey,
  listCount,
  setListCount,
  setExcelBtn,
  refetchFlag,
  setRefetchFlag,
}: IIncompletedWorkerSearchOption) => {
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const toDay = todayYYYYMMDD();
  const { siteJoinInfoList } = useSetSjcd();
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const [wPrejobtype, setWPrejobtype] = useState({ type: 'wPrejobtype', wPrejobtype: '', cdName: '' });
  const { prejobtypeList, jobtypeList } = useSetJobtype(wPrejobtype.wPrejobtype);
  const [sjCd, setSjCd] = useState({ type: 'sjCd', sjCd: '', cdName: '' });
  const [searchOption, setSearchOption] = useState({ wName: '', stressTotal1: null, stressTotal2: null });
  const [visibleRangePicker, setVisibleRangePicker] = useState(false);
  const [pickerDateRange, setPickerDateRange] = useState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
  const [userInputDateRange, setUserInputDateRange] = useState({ start: toDay, end: toDay });
  const userInfo = useRecoilValue<IUser>(userState);
  const [initiate, setinitiate] = useState('');
  const [jikJongList, setJikJongList] = useState<any[]>([]);
  const [wJobtype, setWJobtype] = useState({ type: 'wJobtype', wJobtype: '', cdName: '' });
  const [listCountComCdList, setListCountComCdList] = useState<IComCdList[]>([]); // 검색줄수 공통코드
  const { patchUserMenuAPI } = useSetListCount(); // 검색줄수설정 훅
  const wNameRef = useRef<HTMLInputElement>(null);
  const [wForeignyn, setWForeignyn] = useState({ type: 'wForeignyn', wForeignyn: 'A', cdName: t('전체') });

  const { data, isLoading, isFetching, isError, refetch } = useQuery(['workerList', userInfo.hCd, userInfo.sCd], () => fetchData(), {
    enabled: false,
    cacheTime: 0,
  });

  useEffect(() => {
    if (refetchFlag) refetch();
  }, [refetchFlag]);

  const fetchData = async () => {
    try {
      const path = '/worker';
      const req = {
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        wName: searchOption.wName,
        sjCd: sjCd.sjCd,
        wForeignyn: wForeignyn.wForeignyn === 'A' ? '' : wForeignyn.wForeignyn,
        wDate1: userInputDateRange.start.replaceAll('-', ''),
        wDate2: userInputDateRange.end.replaceAll('-', ''),
        wPrejobtype: wPrejobtype.wPrejobtype,
        wJobtype: wJobtype.wJobtype,
        wWorkstatus: 'T',
      };
      const res = await apiGet({ path, req });
      const newArray = res.data.data.workerList;
      setTableState(newArray);
      return newArray;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    } finally {
      setRefetchFlag(false);
    }
  };

  useEffect(() => {
    setComCdListState(LIST_COUNT, setListCountComCdList, false);
  }, []);

  useEffect(() => {
    setIsFetching(isFetching);
  }, [isLoading, isFetching]);

  useEffect(() => {
    setIsError(isError);
  }, [isError]);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    setWJobtype({ type: 'wJobtype', wJobtype: '', cdName: '' });
    if (wPrejobtype.wPrejobtype === '') setJikJongList([]);
  }, [wPrejobtype.wPrejobtype]);

  useEffect(() => {
    setJikJongList(jobtypeList);
  }, [jobtypeList]);

  useEffect(() => {
    if (pickerDateRange[0].startDate) {
      setUserInputDateRange((prev: any) => ({ ...prev, start: dayjs(pickerDateRange[0].startDate).format('YYYY-MM-DD') }));
    }
    if (pickerDateRange[0].endDate) {
      setUserInputDateRange((prev: any) => ({ ...prev, end: dayjs(pickerDateRange[0].endDate).format('YYYY-MM-DD') }));
    }
  }, [pickerDateRange[0].startDate, pickerDateRange[0].endDate]);

  // 검색 버튼 클릭
  const onClickSearch = () => {
    setRowKey('');
    if (userInputDateRange.end && userInputDateRange.start > userInputDateRange.end) {
      toast.warning(t('날짜를 다시 입력하세요'));
      setSearchOption((prev) => ({ ...prev, end: '', stressTotal1: null, stressTotal2: null }));
    } else {
      setVisibleRangePicker(false);
      refetch();
    }
  };

  // 엑셀 저장 버튼 클릭
  const onClickExcelSave = () => {
    if (tableState.length <= 0) return;
    setExcelBtn(true);
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '근로자 연명부',
      action: '엑셀 저장',
      etc: ``,
    });
  };

  // 근로자명 입력창에서 엔터입력시 검색
  const loginOnEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (wNameRef.current) onClickSearch();
    }
  };

  const onClickRangeInput = () => {
    if (!visibleRangePicker) setVisibleRangePicker(true);
  };

  const initiateSearchOptions = () => {
    setSjCd({ type: 'sjCd', sjCd: '', cdName: '' });
    setWForeignyn({ type: 'wForeignyn', wForeignyn: 'A', cdName: t('전체') });
    setWPrejobtype({ type: 'wPrejobtype', wPrejobtype: '', cdName: '' });
    setWJobtype({ type: 'wJobtype', wJobtype: '', cdName: '' });
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    setSearchOption({ wName: '', stressTotal1: null, stressTotal2: null });
    setinitiate(`${Math.random()}`);
    initiateSearchOptions();
    setPickerDateRange([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
    setUserInputDateRange({ start: '', end: '' });
    setRowKey('');
  };

  return (
    <SearchOptions>
      <div className='inputsWrapper'>
        <div className='inputForm-row withLabel'>
          <label htmlFor='sjCd'>{t('협력업체')}</label>
          <div className='inputForm-col'>
            <SearchSelectBoxSm
              options={siteJoinInfoList}
              defaultOption={t('전체')}
              state={sjCd}
              setState={setSjCd}
              stateKey='sjCd'
              codeKey='cdName'
              initiateKey={sjCd.sjCd}
              filterbar='filter-1-left'
              optionHeight='height-md'
            />
          </div>
        </div>
        <div className='inputForm-col withLabelComCf'>
          <label htmlFor='wForeignyn'>{t('외국인여부')}</label>
          <SelectBox
            options={FOREIGN_YN}
            defaultOption={t('전체')}
            state={wForeignyn}
            setState={setWForeignyn}
            stateKey='wForeignyn'
            initiateKey={wForeignyn.wForeignyn}
            filterbar='filter-1-left'
            optionHeight='height-md'
          />
        </div>
        {userInfo.prejobtypeYn === 'Y' && (
          <div className='inputForm-row'>
            <div className='inputForm-col'>
              <SearchSelectBoxSm
                options={prejobtypeList}
                defaultOption={t('공종 전체')}
                state={wPrejobtype}
                setState={setWPrejobtype}
                stateKey='wPrejobtype'
                codeKey='cdName'
                initiateKey={initiate}
                filterbar='filter-1-right'
                comboWidth='expand-box-sm'
                optionHeight='height-md'
              />
            </div>
          </div>
        )}
        <div className='inputForm-row'>
          <div className='inputForm-col'>
            <SearchSelectBoxSm
              options={jikJongList}
              defaultOption={t('직종 전체')}
              state={wJobtype}
              setState={setWJobtype}
              stateKey='wJobtype'
              codeKey='cdName'
              initiateKey={wJobtype.wJobtype}
              filterbar='filter-1-right'
              comboWidth='expand-box-sm'
              optionHeight='height-md'
            />
          </div>
        </div>
        <div className='inputForm-row-fit'>
          <span className='calendarLabel'>{t('등록일자')}</span>
          <div className='inputForm-col'>
            <div className='flex-basic'>
              <input
                id='startInput'
                type='text'
                value={userInputDateRange.start}
                onMouseDown={onClickRangeInput}
                onChange={(e) => onChangeRangeInput(e, 'start', setPickerDateRange, setUserInputDateRange)}
              />
              <span className='inputDash'> ~ </span>
              <input
                id='endInput'
                type='text'
                value={userInputDateRange.end}
                onMouseDown={onClickRangeInput}
                onChange={(e) => onChangeRangeInput(e, 'end', setPickerDateRange, setUserInputDateRange)}
              />
            </div>
            {visibleRangePicker && (
              <div className='rangePickerWrapper'>
                <RangePicker state={pickerDateRange} setState={setPickerDateRange} setVisible={setVisibleRangePicker} />
              </div>
            )}
          </div>
        </div>
        <div className='inputForm-row'>
          <div className='inputForm-col'>
            <Input placeholder={t('근로자명')} label='' type='text' id='wName' name='wName' state={searchOption} setState={setSearchOption} inputRef={wNameRef} onKeyDown={loginOnEnterKeyDown} />
          </div>
        </div>

        <div className='inputsWrapper'>
          <div className='secondSearchOption'>
            <div className='flex-basic textBtnGroup'>
              <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
              <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
              <div className='searchResult'>
                {t('총')}
                <span>{tableState.length}</span>
                {t('개')}
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col withLabelComCf'>
                  <label htmlFor='useYn'>{t('보기 설정')}</label>
                  <SelectBox
                    options={listCountComCdList}
                    defaultOption={listCount.cdName}
                    state={listCount}
                    setState={setListCount}
                    stateKey={LIST_COUNT}
                    initiateKey={listCount[LIST_COUNT]}
                    setTableLinesAPI={patchUserMenuAPI}
                    optionHeight='height-sm'
                    rsearch
                  />
                </div>
              </div>
            </div>
            {auth.excelAuth && (
              <div className='flex-basic iconBtnGroup'>
                <BtnGhost onClick={onClickExcelSave}>
                  <span className='icon-ms-xlsx'>X</span> {t('엑셀')}
                </BtnGhost>
              </div>
            )}
          </div>
        </div>
      </div>
    </SearchOptions>
  );
};

export default IncompletedWorkerSearchOption;
