import { useCallback, useEffect, useState } from 'react';
import { IModal } from 'customTypes';
import { BtnGray } from '../Button';
import { ModalBackground, Modal } from '../../assets/styles/Modal';
import Slider from 'react-slick';
import TextEditor from '../TextEditor';
import { IoRefresh } from 'react-icons/io5';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5010;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 60%);
  .modal {
    z-index: 6000;
    display: flex;
    flex-direction: column;
    /* height: calc(100vh - 2rem); */
    height: fit-content;
    width: 24rem;
    background-color: ${({ theme }: { theme: any }) => theme.board};
    border-radius: 0.5rem;
    overflow: hidden;
    @media screen and (max-width: 1023px) {
      /* width: 80%; */
      width: calc(100vw - 2rem);
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      height: 100vh;
      overflow-y: auto;
      border-radius: 0;
    }
    > .inputForm-head {
      user-select: none;
      position: relative;
      font-weight: 500;
      flex-shrink: 0;
      padding: 0.5rem;
      height: 3.5rem;
      border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      > svg {
        width: 24px;
        height: 24px;
      }
      .modalHeaderTitle {
        padding: 0 0.25rem;
        color: ${({ theme }: { theme: any }) => theme.text_primary};
      }
      .modalHeaderCenter {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: ${({ theme }: { theme: any }) => theme.text_primary};
      }
    }
  }
  .closeBtn {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5rem;
    cursor: pointer;
    margin: 0 0.5rem;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .closeBtn:hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
  }
  .material-symbols-rounded {
    /* color: rgba(0, 0, 0, 0.8); */
    font-variation-settings: 'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 20;
  }
`;
const SliderWrapper = styled.div`
  padding: 1rem 2rem 2rem 2rem;
  height: inherit;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .ck-content {
    border: 1px solid lightgray;
    border-radius: 0.25rem;
    padding: 1rem;
    height: 8rem;
    max-height: fit-content;
    /* height: 14vh; */
    /* max-height: 14vh; */
    overflow: hidden;
  }

  .slick-arrow {
    display: none !important;
  }

  .slick-dots {
    top: -2.6rem;
    width: inherit;
    right: 0;

    li {
      margin: 0;

      button:before {
        transition: all ease-in-out 0.2s;
        font-family: 'slick';
        font-size: 15px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 5px;
        height: 5px;
        border-radius: 5px;
        content: '';
        text-align: center;
        color: black;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: rgba(72, 216, 158, 1);
      }
    }

    .slick-active {
      button:before {
        width: 12px;
      }
    }
  }

  .titleBar {
    width: 0.5rem;
    height: 2rem;
    background-color: #2977ff;
    border-radius: 2px;
    margin-right: 0.5rem;
  }
  .flex-between {
    > div.flex-start {
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
    }
    > div:nth-child(2) {
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    }
  }
`;

const DashboardViewModal = ({ openModal, setOpenModal }: any) => {
  const { t } = useTranslation();
  const settings = {
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 20000,
  };

  // 닫기버튼 클릭
  const onClickClose = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };
  return (
    <Root>
      <div className='modal'>
        <div className='inputForm-head flex-between'>
          <div className='modalHeaderTitle'>{t('미리보기')}</div>
          <div className='closeBtn' onClick={onClickClose} role='presentation'>
            <span className='material-symbols-rounded'>close</span>
          </div>
        </div>
        <div className='inputForm-body'>
          <div className='modal-contents'>
            <SliderWrapper>
              <div className='flex-between'>
                <div className='flex-start'>
                  <span className='titleBar' />
                  {openModal.data.cTitle}
                </div>
                <div>{openModal.data.cDate}</div>
              </div>
              <TextEditor content={openModal.data.cContent} readOnly />
            </SliderWrapper>
          </div>
        </div>
      </div>
    </Root>
  );
};

export default DashboardViewModal;
