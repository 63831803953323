/**
 * 작성자 : 김광민
 * 날짜 : 2024.02.13
 * 기능 : 터널 관리 > 방송 장비 정보
 */

import styled from 'styled-components';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TareaNormalInfoHeader from './TareaNormalInfoHeader';
import BroadCastPoint from './Modal/BroadCastPoint';
import CheckBoxAllButton from './button/CheckBoxAllButton';
import InfoTextWithIcon from './Modal/InfoTextWithIcon';
import TooltipWithIcon from './Modal/TooltipWithIcon';
import { isMobile } from '../utils/checkMobile';
import IssueGuide from './IssueGuide';
import { IUser } from '../atoms';

const Root = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  gap: 1rem;
  overflow: auto;
  user-select: none;
  @media (min-width: 1024px) {
    padding: 0.75rem 1rem;
  }
  .contentContainer {
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .infoBar {
      display: none;
      @media (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .buttonsAndInfo {
          display: flex;
          gap: 0.5rem;
          .buttons {
            display: flex;
            gap: 0.5rem;
          }
        }
      }
    }
    .legendBox {
      display: flex;
      gap: 0.25rem;
      background-color: ${({ theme }: { theme: any }) => theme.board};
      box-shadow: 0 4px 12px 0px rgba(0, 0, 0, 0.05);
      padding: 0 0.375rem;
      border-radius: 0.5rem;
      height: 2.25rem;
      align-items: center;
      .seperator {
        height: 1rem;
        border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      }
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 3rem;
        height: 1.75rem;
        border-radius: 0.25rem;
        font-size: 0.875rem;
        word-break: keep-all;
        gap: 0.5rem;
        padding: 0 0.5rem;
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        .material-symbols-rounded {
          font-variation-settings: 'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 24;
          font-size: 1.25rem;
        }
        .legend-text {
          letter-spacing: 0;
          font-size: 0.875rem;
        }
        &.camera {
          .material-symbols-rounded {
            font-size: 1.125rem;
            padding: 1px;
          }
        }
      }
    }
    .pointContainer {
      display: flex;
      gap: 0.5rem;
      height: 100%;
    }
  }
`;

type Props = {
  rowState: any;
  onClickRow: (row: any) => void;
  startEquipList: EquipList[];
  setStartEquipList: Dispatch<SetStateAction<EquipList[]>>;
  endEquipList: EquipList[];
  setEndEquipList: Dispatch<SetStateAction<EquipList[]>>;
  userInfo: IUser;
};

const TareaEquipInfoContent = ({ userInfo, rowState, onClickRow, startEquipList, setStartEquipList, endEquipList, setEndEquipList }: Props) => {
  const { t } = useTranslation();

  const pointVisibleInitialState = { start: true, end: true };
  const [isPointVisible, setIsPointVisible] = useState(pointVisibleInitialState);

  const [isStartCheckAll, setIsStartCheckAll] = useState(false);
  const [isEndCheckAll, setIsEndCheckAll] = useState(false);

  const isStartPointUse = rowState?.sUseYn === 'Y';
  const isEndPointUse = rowState?.eUseYn === 'Y';

  // 헤더 체크박스 클릭
  const handleCheckboxChangeAll = (setAllCheckBoxs: any, allCheckBoxs: any, tableState: any) => {
    setAllCheckBoxs(!allCheckBoxs);
    const newTableState = [...tableState];
    for (const v of newTableState) {
      v.checked = !allCheckBoxs;
    }
  };

  // 체크박스 클릭
  const handleCheckboxChange = (i: number, el: any, tableState: any, setTableState: any, allCheckBoxs: any, setAllCheckBoxs: any) => {
    const newTableState = [...tableState];
    newTableState.map((v: any, i2: number) => {
      if (i === i2) {
        v.checked = !el.checked;
      }
    });
    if (!el.checked) {
      setAllCheckBoxs(false);
    } else {
      const allchecked = newTableState.find((v: any) => !v.checked);
      if (!allchecked) setAllCheckBoxs(true);
    }
    setTableState(newTableState);
  };

  // 전체 체크
  const checkAll = () => {
    if (isStartPointUse) {
      setIsStartCheckAll(true);
      setStartEquipList(startEquipList.map((item: any) => ({ ...item, checked: true })));
    }
    if (isEndPointUse) {
      setIsEndCheckAll(true);
      setEndEquipList(endEquipList.map((item: any) => ({ ...item, checked: true })));
    }
  };

  // 전체 체크 상태
  const checkAllStatus = () => {
    if (isStartCheckAll && isEndCheckAll) return true;
    return false;
  };

  // 전체 체크 해제
  const nonCheckAll = () => {
    if (isStartPointUse) {
      setIsStartCheckAll(false);
      setStartEquipList(startEquipList.map((item: any) => ({ ...item, checked: false })));
    }
    if (isEndPointUse) {
      setIsEndCheckAll(false);
      setEndEquipList(endEquipList.map((item: any) => ({ ...item, checked: false })));
    }
  };

  // 전체 체크 해제 상태
  const nonCheckAllStatus = () => {
    const startEquipListData = startEquipList.every((item: any) => !item.checked);
    const endEquipListData = endEquipList.every((item: any) => !item.checked);
    return startEquipListData && endEquipListData;
  };

  const renderPoint = () => {
    const renderStartPoint = isStartPointUse && isPointVisible.start;
    const renderEndPoint = isEndPointUse && isPointVisible.end;
    return (
      <div className='pointContainer'>
        {/* 시점 렌더링 */}
        {renderStartPoint && (
          <BroadCastPoint
            isStartPoint
            onClickRow={onClickRow}
            handleCheckboxChangeAll={handleCheckboxChangeAll}
            handleCheckboxChange={handleCheckboxChange}
            equipList={startEquipList}
            setEquipList={setStartEquipList}
            isCheckAll={isStartCheckAll}
            setIsCheckAll={setIsStartCheckAll}
          />
        )}
        {/* 종점 렌더링 */}
        {renderEndPoint && (
          <BroadCastPoint
            onClickRow={onClickRow}
            handleCheckboxChangeAll={handleCheckboxChangeAll}
            handleCheckboxChange={handleCheckboxChange}
            equipList={endEquipList}
            setEquipList={setEndEquipList}
            isCheckAll={isEndCheckAll}
            setIsCheckAll={setIsEndCheckAll}
          />
        )}
        {/* {rowState.sUseYn === 'N' && rowState.eUseYn === 'N' && <div className='noData'>No data.</div>} */}
      </div>
    );
  };

  // 연결 방식 미설정 또는 모바일인 경우
  if (userInfo.cctvProtocol === '1' && isMobile()) {
    const mobileNotSupport = '모바일 환경에서 지원되지 않는 화면입니다';
    return (
      <Root>
        <IssueGuide text={mobileNotSupport} />
      </Root>
    );
  }

  return (
    <Root>
      <TareaNormalInfoHeader data={rowState} isPointVisible={isPointVisible} setIsPointVisible={setIsPointVisible} isEndPointUse={isEndPointUse} isStartPointUse={isStartPointUse} />
      <div className='contentContainer'>
        <div className='infoBar'>
          <div className='buttonsAndInfo'>
            <div className='buttons'>
              <CheckBoxAllButton checked onClick={checkAll} disabled={checkAllStatus()} />
              <CheckBoxAllButton onClick={nonCheckAll} disabled={nonCheckAllStatus()} />
            </div>
            <InfoTextWithIcon icon='bolt' text='Windows 운영체제에서 Chrome 또는 Edge 브라우저를 이용해주세요.' />
            <TooltipWithIcon icon='help' text='현재 Android, iOS, MacOS 운영체제는 방송 기능을 지원하지 않습니다.' />
          </div>
          <div className='legendBox'>
            <div className='broadcast'>
              <span className='material-symbols-rounded'>volume_up</span>
              <div className='legend-text'>{t('방송장비')}</div>
            </div>
            <span className='seperator' />
            <div className='camera'>
              <span className='material-symbols-rounded'>nest_cam_iq_outdoor</span>
              <div className='legend-text'>{t('카메라')}</div>
            </div>
          </div>
        </div>
        {renderPoint()}
      </div>
    </Root>
  );
};

export default TareaEquipInfoContent;
