import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DatePickerComponent from '../../DatePicker';

const Root = styled.div`
  > div {
    flex-grow: 1;
  }
  .register {
    display: flex;
    align-items: center;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    border-radius: 0.25rem;
    .labelName {
      padding: 0 0.75rem;
      font-size: 0.875rem;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      border-right: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    }
    .inputsWrapper {
      display: flex;
      align-items: center;
      width: 15.5rem;
    }
  }
  .datePickerWrapper {
    flex-grow: 1;
    margin-left: 0.5rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    border-radius: 0.25rem;
    .labelName {
      width: fit-content;
      padding: 0 0.75rem;
      font-size: 0.875rem;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      border-right: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    }
    .inputMask {
      width: 8.5rem;
      text-align: center;
    }
  }
`;

interface Props {
  workerInputSet: any;
  cdName: string;
  isViewMode: boolean;
  setWorkerInputSet: any;
  isNewAdd: boolean;
}

const SafeEdu = ({ workerInputSet, cdName, isViewMode, setWorkerInputSet, isNewAdd }: Props) => {
  const { t } = useTranslation();
  const { safeeduReg, safeeduDate } = workerInputSet;

  const initInputs = { reg0: '', reg1: '', reg2: '' };
  const [inputs, setInputs] = useState<Record<string, string>>(initInputs);
  const [date, setDate] = useState(safeeduDate);
  const [isLoading, setIsLoading] = useState(true);
  const [editKey, setEditKey] = useState(false);

  const updateWorkerSafeeduRegData = () => {
    const regInit = safeeduReg !== '' ? safeeduReg?.split('-') : ['', '', ''];
    if (regInit) {
      setInputs({ ...inputs, reg0: regInit[0], reg1: regInit[1], reg2: regInit[2], date: safeeduDate });
    }
  };

  useEffect(() => {
    // 이전/다음 으로 근로자 이동 시 해당근로자정보 세팅
    if (editKey) {
      setEditKey(false);
      updateWorkerSafeeduRegData();
    }
  }, [workerInputSet.wCd]);

  useEffect(() => {
    if (safeeduReg !== undefined && safeeduDate !== undefined && !isNewAdd && !editKey) {
      updateWorkerSafeeduRegData();
      setIsLoading(false);
    }
  }, [safeeduReg, safeeduDate, isNewAdd]);

  useEffect(() => {
    if (isNewAdd || (!isNewAdd && editKey)) {
      const { reg0, reg1, reg2 } = inputs;
      const safeeduRegData = `${reg0}-${reg1}-${reg2}`;
      const result = safeeduRegData !== '--' ? safeeduRegData : '';
      setWorkerInputSet({ ...workerInputSet, safeeduReg: result, safeeduDate: date });
    }
  }, [inputs, date, editKey]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
    setEditKey(true);
  };

  return (
    <Root className='control inputs safeedu'>
      <label htmlFor={cdName}>{cdName}</label>
      <div className='flex-basic wrapper-container'>
        <div className='register'>
          <span className='labelName'>{t('등록번호')}</span>
          <div className='inputsWrapper'>
            <input
              className='text-center' //
              type='text'
              name='reg0'
              value={inputs.reg0}
              maxLength={10}
              onChange={onChange}
            />
            <span className='dash'>-</span>
            <input
              className='text-center' //
              type='text'
              name='reg1'
              value={inputs.reg1}
              maxLength={10}
              onChange={onChange}
            />
            <span className='dash'>-</span>
            <input
              className='text-center' //
              type='text'
              name='reg2'
              value={inputs.reg2}
              maxLength={10}
              onChange={onChange}
            />
          </div>
        </div>
        <div className='datePickerWrapper'>
          <label className='labelName'>{t('이수 일자')}</label>
          <DatePickerComponent
            startDate={safeeduDate} //
            setDate={setDate}
            popperPlacement='bottom'
            disabled={isViewMode}
          />
        </div>
      </div>
    </Root>
  );
};

export default SafeEdu;
