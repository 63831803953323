/**
 * 작성자 : 김광민
 * 날짜 : 2024.03.15
 * 경로 : 구역별 상황판 > 터널 종합 진행 현황 (일반)
 */

import styled from 'styled-components';
import { IUser } from '../../atoms';
import { useTranslation } from 'react-i18next';
import { PulseLoader } from 'react-spinners';
import { useQuery } from '@tanstack/react-query';
import { apiGet } from '../../services/_common';
import TunnelTableWithTatCd from '../tunnel/TunnelTableWithTatCd';
import { useState } from 'react';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  user-select: none;
  > .title {
    height: 2rem;
    width: 100%;
    padding: 0 1rem;
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: space-between;
    .name {
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      font-weight: ${({ theme }: { theme: any }) => theme.font_semibold};
      font-size: 1.125rem;
      line-height: 1.2;
    }
    .dotContainer {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .dot {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        opacity: 0.35;
        &.start {
          background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
        }
        &.end {
          background-color: ${({ theme }: { theme: any }) => theme.filled_amber};
        }
        &.focused {
          opacity: 1;
        }
      }
    }
  }
  .centered-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-grow: 1;
    color: ${({ theme }: { theme: any }) => theme.text_disabled};
    font-size: 0.875rem;
  }
`;

type Props = {
  name: string;
  userInfo: IUser;
  tatCd: string;
  uniqueKey: string;
};

const TunnelTotalTableWithTatCd = ({ name, userInfo, tatCd, uniqueKey }: Props) => {
  const [focus, setFocus] = useState<'start' | 'end'>('start');
  const { t } = useTranslation();

  // Tarea 데이터 요청 함수
  const fetchTareaData = async (userInfoData: IUser) => {
    const { hCd, sCd } = userInfoData;
    try {
      const req = { hCd, sCd, tatCd };
      const res = await apiGet({ path: `/tarea/t/total`, req });
      const { data } = res.data || {};
      return data?.tareaList || [];
    } catch (error) {
      console.error('API 호출 중 오류 발생:', error);
      throw new Error('Error');
    }
  };

  // Tarea 데이터 요청 상태값
  const dependencies = [uniqueKey, userInfo.hCd, userInfo.sCd];
  const { data, isError, isLoading } = useQuery(dependencies, () => fetchTareaData(userInfo), {
    refetchInterval: 60000, // 60초마다 자동 refetch
  });

  // 로딩 중일 때
  if (isLoading) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  if (isError) {
    console.error('API 호출 중 오류 발생:', data);
  }

  // 데이터가 있는지 확인합니다.
  const isData = data && data.length > 0;

  // 우측 상단의 dot 컨테이너 렌더링 함수
  const renderDots = () => {
    if (!isData) {
      return null;
    }

    const isStart = data[0].sUseYn === 'Y';
    const isEnd = data[0].eUseYn === 'Y';
    const isFocused = (position: string) => {
      return focus === position ? 'focused' : '';
    };

    return (
      <div className='dotContainer'>
        {isStart && <span className={`dot start ${isFocused('start')}`} />}
        {isEnd && <span className={`dot end ${isFocused('end')}`} />}
      </div>
    );
  };

  return (
    <Root>
      <div className='title'>
        <span className='name'>{t('터널 종합 진행 현황')}</span>
        {renderDots()}
      </div>
      {isData ? ( //
        <TunnelTableWithTatCd data={data} setFocus={setFocus} />
      ) : (
        <div className='centered-content'>{t('데이터가 존재하지 않습니다')}</div>
      )}
    </Root>
  );
};

export default TunnelTotalTableWithTatCd;
