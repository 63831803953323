/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.08
 * 경로 : 터널관리 - 구역 현황 관리 > 종합진행현황
 */

import { v1 } from 'uuid';
import SingleTunnel from './SingleTunnel';
import styled from 'styled-components';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { PulseLoader } from 'react-spinners';
import { useFetchTareaDetailData } from '../../services/Tunnel';

const SingleTunnelAreaInfoContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  padding: 0 0.5rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
  &.siteDashboard {
    overflow: visible;
    pointer-events: none;
  }
`;

const TunnelVerticalTabContainer = styled.div`
  width: 8rem;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  flex-shrink: 0;
  padding: 0.5rem;
  background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
  flex-direction: column;
  display: flex;
  transition: all 0.25s ease-in-out;
  @media (max-width: 767px) {
    display: flex;
    position: absolute;
    height: 100%;
    z-index: 1000;
    top: 0;
    left: -10rem;
    &.visible {
      left: 0;
      box-shadow: 0px 0.25rem 0.75rem 0.25rem rgba(0, 0, 0, 0.1);
    }
  }
  @media (min-width: 768px) {
    display: flex;
  }
  &:hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
  }
  > .tabContainer {
    min-height: 2rem;
    border-radius: 0.25rem;
    background-color: ${({ theme }: { theme: any }) => theme.board};
    box-shadow: 0px 4px 12px 4px rgba(0, 0, 0, 0.05);
    overflow: auto;
    .tab {
      cursor: pointer;
      font-size: 0.875rem;
      padding: 0 0.5rem;
      height: 2rem;
      display: flex;
      align-items: center;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      &.selected {
        background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
        color: ${({ theme }: { theme: any }) => theme.text_reverse};
      }
    }
  }
`;

type Props = {
  tunnelList: TareaData[];
  setTunnelList: Dispatch<SetStateAction<TareaData[]>>;
  selectedTatCd: string;
  filter: TunnelFilterData[];
  setSelectedTatCd?: Dispatch<SetStateAction<string>>;
  autoPlay?: boolean;
  updateSelectedTatCd?: () => void;
  siteDashboard: boolean;
  openMobileTab?: boolean;
  closeMobileTab?: () => void;
};

const SingleTunnelAreaInfo = ({ tunnelList, setTunnelList, selectedTatCd, filter, setSelectedTatCd, autoPlay = false, updateSelectedTatCd, siteDashboard, openMobileTab, closeMobileTab }: Props) => {
  const { data, isLoading, isError, refetch } = useFetchTareaDetailData({ tatCd: selectedTatCd });

  // 선택된 tab element의 인덱스 찾기
  const selectedIndex = tunnelList.findIndex((list: any) => list.tatCd === selectedTatCd);

  // useRef를 사용하여 선택된 tab element를 참조합니다.
  const selectedTabRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = selectedTabRef.current;

    // 선택된 tab element의 인덱스가 유효하면 scrollIntoView 실행
    if (selectedIndex !== -1 && element) {
      const elementRect = element.getBoundingClientRect();
      const parentRect = element.parentElement?.getBoundingClientRect();
      if (elementRect.top < parentRect!.top || elementRect.bottom > parentRect!.bottom) {
        // 요소가 가로 스크롤 영역 밖에 있으면 수동으로 스크롤 조작
        element.parentElement?.scrollTo({
          top: elementRect.bottom - parentRect!.bottom + element.parentElement!.scrollTop,
          behavior: 'smooth',
        });
      }
    }
  }, [selectedIndex]);

  if (isLoading) {
    return (
      <SingleTunnelAreaInfoContainer>
        <div className='centered-content'>
          <PulseLoader color='rgb(0, 122, 255)' size='10px' />
        </div>
      </SingleTunnelAreaInfoContainer>
    );
  }

  if (isError) {
    console.error('API 호출 중 오류 발생:', data);
  }

  return (
    <SingleTunnelAreaInfoContainer className={`${siteDashboard ? 'siteDashboard' : ''}`}>
      {setSelectedTatCd && (
        <TunnelVerticalTabContainer className={openMobileTab ? 'visible' : ''}>
          <div className='tabContainer'>
            {tunnelList.map((list: any, index: number) => (
              <div
                ref={index === selectedIndex ? selectedTabRef : null}
                className={`tab ${selectedTatCd === list.tatCd ? 'selected' : ''}`}
                role='button'
                tabIndex={0}
                key={v1()}
                onClick={() => {
                  closeMobileTab && closeMobileTab();
                  setSelectedTatCd(list.tatCd);
                }}
              >
                {list.tatName}
              </div>
            ))}
          </div>
        </TunnelVerticalTabContainer>
      )}
      <SingleTunnel
        data={data}
        tunnelList={tunnelList}
        setTunnelList={setTunnelList}
        selectedTatCd={selectedTatCd}
        filter={filter}
        dashboard
        autoPlay={autoPlay}
        updateSelectedTatCd={updateSelectedTatCd}
        siteDashboard={siteDashboard}
      />
    </SingleTunnelAreaInfoContainer>
  );
};

export default SingleTunnelAreaInfo;
