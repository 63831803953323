/**
 * 작성자 : 홍선영
 * 날짜 : 2023.05.22
 * 경로 : 설정관리 - CCTV관리 - CCTV 정보
 */

// @ts-ignore

import React, { useRef, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { v1 } from 'uuid';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { IoChevronUpSharp } from 'react-icons/io5';
import { useOutletContext } from 'react-router-dom';

import { userState } from '../../../atoms';
import { CAMERA_STREAM, CCTV_VIEWER_PLUGIN_URL, COMCD_INFRA, COMCD_USE_YN, INIT_USE_YN_A, NVR_GUBUN, PROTOCOL, USE_YN } from '../../../_constants';
import { ICameraObject, IComCd, IComCdList, IModal, INvrObject } from 'customTypes';
import i18n from '../../../translation/i18n';
import Input from '../../../components/Input';
import Portal from '../../../components/Portal';
import BackButton from '../../../components/BackButton';
import SelectBoxs from '../../../components/SelectBoxs';
import VideoPlayer from '../../../components/VideoPlayer';
import DeleteModal from '../../../components/Modal/DeleteModal';
import DeleteModal2 from '../../../components/Modal/DeleteModal2';
import { BtnBlue, BtnGhost, BtnGreen, BtnRed } from '../../../components/Button';
import * as cctv from '../../../utils/cctv';
import { ynFilter } from '../../../utils/ynFilter';
import { isMobile } from '../../../utils/checkMobile';
import { trimObject } from '../../../utils/trimObject';
import { useSetAuth } from '../../../utils/useSetAuth';
import { scrollToNodeTop } from '../../../utils/scrollToNodeTop';
import { applyBorderStyle } from '../../../utils/applyBorderStyle';
import { scrollToNodeBottom } from '../../../utils/scrollToNodeBottom';
import { arraySortByAscdOrder } from '../../../utils/arraySortByAscdOrder';
import { useDetectScrolledToBottom } from '../../../utils/useDetectScrolledToBottom';
import { InputTable } from '../../../assets/styles/InputTable';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { logPost } from '../../../services/log';
import { apiDelete, apiGet, apiPost } from '../../../services/_common';
import TuiGrid from '../../../components/Table/TuiGrid';
import WebSdkModal from '../../../components/Modal/WebSdkModal';
import SelectBox from '../../../components/SelectBox';
import CameraLoadModal from '../../../components/Modal/CameraLoadModal';
import { useFetchCommonCodeList } from '../../../services/useSetCodeListInSelectBoxForm';
import { ButtonsWrapper, Root, SubRoot } from './cctvInfoStyle';
import InfoTextWithIcon from '../../../components/Modal/InfoTextWithIcon';

const CctvInfo = ({ includeInfra }: { includeInfra: boolean }) => {
  const CAPTION_1 = '건설업종 구분 선택 시 정렬 가능';
  const CAPTION_2 = '드래그 앤 드롭을 지원합니다. 행의 앞부분을 끌어 순서를 위, 아래로 변경 후 정렬 저장버튼을 클릭하세요';
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const size = useOutletContext<any>();
  const userInfo = useRecoilValue(userState);
  const node = useRef<any>(null);
  const [viewTable, setViewTable] = useState<boolean>(true);
  const userInfoInputFormRef = useRef<any>(null); // 인풋 폼 ref (테이블 로우를 클릭했을 때 바로 inputForm 으로 스크롤 이동시키기 위한 ref)
  const { isBottom } = useDetectScrolledToBottom(userInfoInputFormRef); // 스크롤이 해당노드의 하단에 근접했는지 여부 (플로팅버튼 띄우기 위함)
  const [searchOption, setSearchOption] = useState({ searchnName: '' });
  const [nvrGubunComCdList, setNvrGubunComCdList] = useState([]); // NVR 제조사구분 공통코드
  const [isNewAdd, setIsNewAdd] = useState(true); // 신규등록 여부
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const [tableState, setTableState] = useState<INvrObject[]>([]);
  const [filterTableState, setFilterTableState] = useState<INvrObject[]>([]);
  const [cameraTableState, setCameraTableState] = useState<ICameraObject[]>([]);
  const [initTableState, setInitTableState] = useState<INvrObject[]>([]);
  const [initCameraTableState, setInitCameraTableState] = useState<any[]>([]);
  const [isCameraTableVisible, setIsCameraTableVisible] = useState({ visible: false, mode: 'view' }); // 카메라테이블 히든여부
  const [newCameraRowIndex, setNewCameraRowIndex] = useState<number>(0); // 신규항목 추가 클릭했을 때 새 로우객체에 인덱스추가
  const [selectedcCd, setSelectedcCd] = useState<string | number>(''); // 카메라 리스트중 main 카메라
  const [initCameraTableLength, setInitCameraTableLength] = useState<number>(0); // 테이블에 표시할 로우 넘버
  const [isCctvView, setIsCctvView] = useState(false);
  const [isCctvList, setIsCctvList] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState<any>({ subCd: '', cdName: '' });
  const [cameraSelectList, setCameraSelectList] = useState<any>([]);
  const [defaultCameraInfo, setDefaultCameraInfo] = useState<ICameraObject>();
  const [videoUrl, setVideoUrl] = useState('');
  const [setPlayer, isSetPlayer] = useState(false);
  const mobile = isMobile();
  const [cameraLoadList, setCameraLoadList] = useState<any[]>([]);
  const [cctvInputState, setcctvInputState] = useState<any>({
    hCd: '',
    id: '',
    ip: '',
    nCd: '',
    nName: '',
    nvrGubun: '',
    password: '',
    port: '',
    bigo: '',
    useYn: 'Y',
    useYnCdName: t('사용'),
    writer: userInfo.userId,
    editor: userInfo.userId,
  }); // cctv설정 input data
  const [searchOptionUseYn, setSearchOptionUseYn] = useState({ type: COMCD_USE_YN, USE_YN: 'A', cdName: t('전체') });
  const [inputFormUseYn, setInputFormUseYn] = useState({ type: COMCD_USE_YN, USE_YN: 'Y', cdName: t('사용') });
  const [nvrGubun, setNvrGubun] = useState({ type: 'nvrGubun', nvrGubun: '', cdName: '' });
  const [protocol, setProtocol] = useState({ type: 'protocol', protocol: 'HTTP', cdName: 'HTTP' });
  // 제조사 구분 공통코드 목록의 제일 첫번째 항목 (콤보선택 미선택시 0번째 인덱스항목값을 세팅하기 위해서)
  const [initialNvrGubun, setInitialNvrGubun] = useState<IComCd>({ subCd: '', cdName: '', cdSort: 0 });
  const [columns, setColumns] = useState<any[]>([]);
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장1버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [isSave2Clicked, setIsSave2Clicked] = useState(false); // 저장2버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [selectedRowKey, setSelectedRowKey] = useState<any>(null);
  const [cctvFlow, setCctvFlow] = useState(0);
  const scrollContainerRef = useRef<HTMLInputElement>(null);
  const [addRowStatus, setAddRowStatus] = useState(false); // 신규추가 상태값
  const { data: useYnComCdListWithAll } = useFetchCommonCodeList(COMCD_USE_YN, true); // 사용여부 공통코드 목록 (전체포함)
  const { data: useYnComCdList } = useFetchCommonCodeList(COMCD_USE_YN, false); // 사용유무 공통코드 목록
  const { data: infraComCdListWithAll } = useFetchCommonCodeList(COMCD_INFRA, true); // 인프라 공통코드 목록 (전체포함)
  const { data: infraComCdList } = useFetchCommonCodeList(COMCD_INFRA, false); // 인프라 공통코드 목록
  const [sInfra, setSInfra] = useState<any>({ type: COMCD_INFRA, [COMCD_INFRA]: '', cdName: t('미선택') }); // 인프라
  const [sInfraSearch, setSInfraSearch] = useState<any>({ type: COMCD_INFRA, [COMCD_INFRA]: 'A', cdName: t('전체') }); // 인프라 검색값
  const [showSortBtn, setShowSortBtn] = useState(false); // 정렬저장 버튼 visible여부
  const { data: nvrGubunList } = useQuery(['nvrGubunGet', userInfo.hCd, userInfo.sCd], () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
  });
  const [nvrGubunComcdList, setNvrGubunComcdList] = useState<IComCdList[]>(nvrGubunList || []);

  const fetchData = async () => {
    try {
      const reqCode = { grCd: NVR_GUBUN };
      const res2 = await apiGet({ path: '/code/detail', req: reqCode });
      setNvrGubunComcdList(res2.data.data.comCdList);
      return res2.data.data.comCdList;
    } catch (error) {
      console.error('error', error);
      throw new Error('error');
    }
  };

  useEffect(() => {
    setSInfraSearch({ type: COMCD_INFRA, [COMCD_INFRA]: 'A', cdName: t('전체') });
  }, [includeInfra]);

  // 신규추가시 스크롤 마지막으로 이동
  useEffect(() => {
    if (addRowStatus) {
      scrollToNodeBottom(scrollContainerRef);
      setAddRowStatus(false);
    }
  }, [addRowStatus]);

  const fixedPrefixColumns = [
    { header: t('코드'), name: 'nCd', align: 'center', sortable: true, renderer: { classNames: ['text_secondary', 'font_semibold'] }, minWidth: 30 },
    { header: t('NVR 명'), name: 'nName', sortable: true, renderer: { classNames: ['text_secondary', 'font_semibold'] }, minWidth: 250 },
  ];

  const fixedSuffixColumns = [
    { header: t('제조사'), name: 'nvrGubunCdName', sortable: true, minWidth: 130 },
    { header: t('비고'), name: 'bigo', sortable: true, minWidth: 100 },
    { header: t('사용유무'), name: 'useYnCdName', align: 'center', sortable: true, minWidth: 80 },
  ];

  const infraColumn = { header: t('건설업종 구분'), name: 'sInfraName', sortable: true, minWidth: 120 };

  useEffect(() => {
    getNvrListAPI().then((res: any) => {
      if (res.status === 200) {
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: 'CCTV 관리 > CCTV 정보',
          action: '조회',
          etc: ``,
        });
      }
    });
    setSearchOptionUseYn(INIT_USE_YN_A);

    const col = [...fixedPrefixColumns, includeInfra && infraColumn, ...fixedSuffixColumns];
    setColumns(col);
  }, [i18n.language, includeInfra]);

  useEffect(() => {
    // setSelectedRowKey('');
    // 검색옵션 변경됐을 때 필터링 처리
    // 필터링 기준
    const filterOptions = {
      nName: searchOption.searchnName,
      sInfra: sInfraSearch[COMCD_INFRA] === 'A' ? '' : sInfraSearch[COMCD_INFRA],
    };
    // 필터링된 어레이 리턴, 대소문자구분X
    const filteredArray = initTableState.filter((item: any) => {
      return item.nName?.toLowerCase()?.includes(filterOptions.nName?.toLowerCase()) && item.sInfra?.includes(filterOptions.sInfra);
    });

    /**
     * 수정자 : 홍선영
     * 날짜 : 2024.01.25
     * 수정내용 : tuigrid 데이터에서 검색필터(tuigrid필터가 아닌 커스텀검색필터)로 필터링한 상태에서,
     *           로우를 클릭하면 rowKey 에러 나는 부분 수정.
     *           저장/수정 등으로 인해 로우데이터(initTableState)가 변경된 경우, sortKey와 rowKey를 부여해서 setState함.
     */

    const result = ynFilter(filteredArray, 'useYn', searchOptionUseYn[COMCD_USE_YN]);
    if (result.length > 0) {
      setTableState(
        arraySortByAscdOrder(
          result.map((el: any, i) => ({ ...el, rowKey: i, sortKey: i })),
          'cdSort'
        )
      );
    } else setTableState([]);
  }, [searchOption.searchnName, searchOptionUseYn[COMCD_USE_YN], sInfraSearch[COMCD_INFRA], initTableState]);

  useEffect(() => {
    if (userInfo.cctvProtocol === '1' && !mobile) {
      if (isCctvList) {
        if (cctv.getPluginOBJECT()?.oPlugin && cctvFlow === 0) {
          cctv.destroy();
        }
        // web cctv sdk divPlugin 초기화
        if (cctvFlow === 0) cctv.init(setOpenModal, setCctvFlow, 'divPlugin', null, null, 1);
        if (cctvFlow === 1) cctv.login(cctvInputState.ip, cctvInputState.pPort, cctvInputState.id, cctvInputState.password, setCctvFlow);
      }
      if (isCctvView) {
        if (cctv.getPluginOBJECT()?.oPlugin && cctvFlow === 0) {
          cctv.destroy();
        }
        // web cctv sdk divPlugin 초기화
        if (cctvFlow === 0) cctv.init(setOpenModal, setCctvFlow, 'divPlugin', null, null, 1);
        if (cctvFlow === 1) cctv.login(cctvInputState.ip, cctvInputState.pPort, cctvInputState.id, cctvInputState.password, setCctvFlow);
        const camera = cameraTableState.find((v: any) => v.cCd === selectedCamera.subCd);
        if (cctvFlow === 2) cctv.startRealPlay(cctvInputState.ip, camera?.stream, camera?.cChannelNum, 0, cctvInputState.port);
      }
    }
  }, [selectedRowKey, cctvFlow, isCctvList, isCctvView]);

  // NVR 리스트 조회 API
  const getNvrListAPI = async () => {
    const reqNvr = {
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      nCd: '001',
      cCd: '01',
    };
    const res = await apiGet({ path: '/cam/nvr', req: reqNvr });
    if (res.data.statusCode === 200) {
      const reqCode = { grCd: NVR_GUBUN };
      const res2 = await apiGet({ path: '/code/detail', req: reqCode });
      if (res2.data.statusCode === 200) {
        const newArray = res.data.data.nvrList.map((el: INvrObject) => {
          const findObject = res2.data.data.comCdList.find((comCd: IComCdList) => comCd.subCd === el.nvrGubun);
          return { ...el, useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용'), nvrGubunCdName: findObject !== undefined ? findObject.cdName : '' };
        });
        setTableState(newArray);
        setInitTableState(newArray);
        const sortedList = arraySortByAscdOrder(res2.data.data.comCdList, 'cdSort');
        const formatSelectBoxFormList: any = sortedList.map((el: any) => ({ type: 'nvrGubun', nvrGubun: el.subCd, cdName: el.cdName }));
        setNvrGubunComCdList(formatSelectBoxFormList);
        // nvr구분리스트의 첫번째항목을 제조사 콤보박스 기본값으로 설정
        const firstNvrGubun = sortedList[0];
        setNvrGubun({ type: 'nvrGubun', nvrGubun: firstNvrGubun.subCd, cdName: firstNvrGubun.cdName });
        setInitialNvrGubun({ subCd: firstNvrGubun.subCd, cdName: firstNvrGubun.cdName, cdSort: firstNvrGubun.cdSort });
      }
    }
    return res;
  };

  // 카메라 리스트 조회 API
  const getCameraListAPI = async (object: any) => {
    const res = await apiGet({ path: '/cam/camera', req: object });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const copyArray = [...data.cameraList];
      // 배열의 각 개체에 인덱스 속성 추가
      copyArray.forEach((obj: ICameraObject, index: number) => {
        obj.no = index + 1; // 1부터 인덱스 시작에 1 추가
      });

      const newList = copyArray.map((el: any) => {
        return { ...el, cameraUseYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용'), streamCdName: el.stream === '1' ? 'Main' : el.stream === '2' ? 'Sub' : 'Third' };
      });

      setCameraTableState(newList);
      setInitCameraTableState(newList);
      setNewCameraRowIndex(data.cameraList.length);
      setInitCameraTableLength(data.cameraList.length); //  로우데이터의 길이에 따라 신규추가항목의 No 를 세팅
      setIsCameraTableVisible({ visible: true, mode: 'view' });

      if (data.cameraList.length > 0) {
        isSetPlayer(true);

        // 사용중인 카메라만 필터링
        const usingCamera = data.cameraList.filter((el: any) => el.useYn === 'Y');

        // 카메라 셀렉트박스에 맞는 데이터 형태로 저장
        const cameraSelectArray = usingCamera.map((el: any) => {
          return { subCd: el.cCd, cdName: el.cName, wsNum: el.wsNum };
        });

        setCameraSelectList(cameraSelectArray);

        const defaultRow = data.cameraList.find((el: ICameraObject) => el.mainPlayYn === 'Y');
        if (defaultRow !== undefined) {
          setSelectedcCd(defaultRow.cCd); //  기본값으로 선택되어있는 로우의 cCd setState (기본값이 변경되었을 때 flag Y로 변경용)
          setDefaultCameraInfo(defaultRow);
          setSelectedCamera({ subCd: defaultRow.cCd, cdName: defaultRow.cName }); // 셀렉트값
        }
      } else {
        isSetPlayer(false);
        setCameraSelectList([]);
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  // NVR 저장 API
  const saveNvrAPI = async () => {
    const newResult = {
      ...cctvInputState,
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      nvrGubun: nvrGubun.nvrGubun,
      protocol: protocol.protocol,
      useYn: inputFormUseYn[COMCD_USE_YN],
      writer: userInfo.userId,
      editor: userInfo.userId,
    };
    const trimData = trimObject(newResult);
    const { hCd, sCd, nCd, nName, ip, port, id, password, bigo, writer, editor } = trimData;
    const reqCam = {
      hCd,
      sCd,
      nCd,
      nvrGubun: trimData.nvrGubun,
      nName,
      ip,
      port,
      id,
      password,
      bigo,
      useYn: trimData.useYn,
      delYn: 'N',
      writer,
      editor,
      protocol: protocol.protocol,
      sInfra: sInfra[COMCD_INFRA],
      ...(userInfo.cctvProtocol === '1' && trimData.pPort !== undefined && { pPort: trimData.pPort }),
      ...(userInfo.cctvProtocol === '1' && trimData.sPort !== undefined && { sPort: trimData.sPort }),
    };

    const res = await apiPost({ path: '/cam/nvr', req: reqCam });
    const { data, message, statusCode } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      setcctvInputState(data[0].nvr);
      const reqCode = { grCd: NVR_GUBUN };
      const res2 = await apiGet({ path: '/code/detail', req: reqCode });
      if (res2.data.statusCode === 200) {
        const newArray = data[1].nvrList.map((el: INvrObject, i: number) => {
          const findObject = res2.data.data.comCdList.find((comCd: IComCdList) => comCd.subCd === el.nvrGubun);
          return { ...el, rowKey: i, sortKey: i, useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용'), nvrGubunCdName: findObject !== undefined ? findObject.cdName : '' };
        });

        // applyFilter(newArray);
        setInitTableState(newArray);

        initiateState();
        await logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: 'CCTV 관리 > CCTV 정보',
          action: 'CCTV 신규등록',
          etc: `${data[0].nvr.nName}(${data[0].nvr.nCd})`,
        });
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 카메라항목 저장 API
  const saveCameraListAPI = async (cameraReqDto: ICameraObject[]) => {
    if (cameraReqDto.length > 0) {
      const req = { cameraReqDto };
      const res = await apiPost({ path: '/cam/camera', req });
      const { message, statusCode, data } = res.data;
      if (statusCode === 200) {
        toast.success(t(message));

        const copyArray = [...data.cameraList];
        // Add an index property to each object in the array
        copyArray.forEach((obj: ICameraObject, index: number) => {
          obj.no = index + 1; // Adding 1 to start index from 1
        });

        setCameraTableState(copyArray);
        setInitCameraTableState(data.cameraList);
        // setNewSubRowIndex(data.cameraList.length);
        setInitCameraTableLength(data.cameraList.length); //  로우데이터의 길이에 따라 신규추가항목의 No 를 세팅

        if (data.cameraList.length > 0) {
          isSetPlayer(true);

          // 사용중인 카메라만 필터링
          const usingCamera = data.cameraList.filter((el: any) => el.useYn === 'Y');

          // 카메라 셀렉트박스에 맞는 데이터 형태로 저장
          const cameraSelectArray = usingCamera.map((el: any) => {
            return { subCd: el.cCd, cdName: el.cName, wsNum: el.wsNum };
          });
          setCameraSelectList(cameraSelectArray);

          const defaultRow = data.cameraList.find((el: ICameraObject) => el.mainPlayYn === 'Y');
          if (defaultRow !== undefined) {
            setSelectedcCd(defaultRow.cCd); //  기본값으로 선택되어있는 로우의 cCd setState (기본값이 변경되었을 때 flag Y로 변경용)
            setDefaultCameraInfo(defaultRow);
            setSelectedCamera({ subCd: defaultRow.cCd, cdName: defaultRow.cName }); // 셀렉트값
          }
        } else {
          isSetPlayer(false);
          setCameraSelectList([]);
        }
        await logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: 'CCTV 관리 > CCTV 정보',
          action: '카메라 저장',
          etc: `${cctvInputState.nName}(${cctvInputState.nCd}) ${cameraReqDto.length}건`,
        });
      } else {
        // toast.error(t(ERROR));
      }
    }
  };

  // NVR 수정 API
  const updateNvrAPI = async () => {
    const newResult = {
      ...cctvInputState,
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      nvrGubun: nvrGubun.nvrGubun,
      protocol: protocol.protocol,
      useYn: inputFormUseYn[COMCD_USE_YN],
      // writer: userInfo.userId,
      editor: userInfo.userId,
    };
    const trimData = trimObject(newResult);
    const { hCd, sCd, nCd, nName, ip, port, id, password, bigo, writer, editor } = trimData;
    const reqCam = {
      hCd,
      sCd,
      nCd,
      nvrGubun: trimData.nvrGubun,
      nName,
      ip,
      port,
      id,
      password,
      bigo,
      useYn: trimData.useYn,
      delYn: 'N',
      writer,
      editor,
      protocol: protocol.protocol,
      sInfra: sInfra[COMCD_INFRA],
      ...(userInfo.cctvProtocol === '1' && trimData.pPort !== undefined && { pPort: trimData.pPort }),
      ...(userInfo.cctvProtocol === '1' && trimData.sPort !== undefined && { sPort: trimData.sPort }),
    };

    const res = await apiPost({ path: '/cam/nvr', req: reqCam });

    const { data, message, statusCode } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      setcctvInputState(data[0].nvr);

      const reqCode = { grCd: NVR_GUBUN };
      const res2 = await apiGet({ path: '/code/detail', req: reqCode });
      if (res2.data.statusCode === 200) {
        const newArray = data[1].nvrList.map((el: INvrObject, i: number) => {
          const findObject = res2.data.data.comCdList.find((comCd: IComCdList) => comCd.subCd === el.nvrGubun);
          return { ...el, rowKey: i, sortKey: i, useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용'), nvrGubunCdName: findObject !== undefined ? findObject.cdName : '' };
        });

        // applyFilter(newArray);
        setInitTableState(newArray);
      }
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: 'CCTV 관리 > CCTV 정보',
        action: 'CCTV 저장',
        etc: `${data[0].nvr.nName}(${data[0].nvr.nCd})`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  // NVR 삭제 API
  const deleteNvrAPI = async () => {
    const newState = { ...cctvInputState, editor: userInfo.userId };
    const reqCam = {
      hCd: newState.hCd,
      sCd: newState.sCd,
      nCd: newState.nCd,
      editor: newState.editor,
    };
    const res = await apiDelete({ path: '/cam/nvr', req: reqCam });
    const { statusCode, message, data } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));

      const reqCode = { grCd: NVR_GUBUN };
      const res2 = await apiGet({ path: '/code/detail', req: reqCode });
      if (res2.data.statusCode === 200) {
        const newArray = data[1].nvrList.map((el: INvrObject) => {
          const findObject = res2.data.data.comCdList.find((comCd: IComCdList) => comCd.subCd === el.nvrGubun);
          return { ...el, useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용'), nvrGubunCdName: findObject !== undefined ? findObject.cdName : '' };
        });

        applyFilter(newArray);
        setInitTableState(newArray);
      }
      initiateState();
      setIsNewAdd(true);
      setSelectedRowKey(null);
      setIsCameraTableVisible({ visible: false, mode: 'view' }); // 카메라테이블 숨김
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: 'CCTV 관리 > CCTV 정보',
        action: 'CCTV 삭제',
        etc: `${newState.nName}(${newState.nCd})`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 카메라항목 삭제 API
  const deleteCameraAPI = async (el: ICameraObject) => {
    const req = {
      hCd: el.hCd,
      sCd: el.sCd,
      nCd: el.nCd,
      cCd: el.cCd,
      wsNum: el.wsNum,
      editor: el.editor,
    };
    const res = await apiDelete({ path: '/cam/camera', req });
    const { statusCode, data, message } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      setOpenModal((prev) => ({ ...prev, status: false }));
      setNewCameraRowIndex(data.cameraList.length);
      setInitCameraTableLength(data.cameraList.length); //  로우데이터의 길이에 따라 신규추가항목의 No 를 세팅

      if (data.cameraList.length > 0) {
        //  기본값으로 선택되어있는 로우의 mrCd setState (기본값이 변경되었을 때 flag Y로 변경용)
        const selectedRow = data.cameraList.find((camera: ICameraObject) => camera.mainPlayYn === 'Y');
        if (selectedRow !== undefined) {
          setSelectedcCd(selectedRow.cCd);
        }
      }
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: 'CCTV 관리 > CCTV 정보',
        action: '카메라 삭제',
        etc: `${el.cName}(${el.cCd})`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  const applyFilter = (array: any[]) => {
    // 필터링 기준
    const filterOptions = { nName: searchOption.searchnName };

    // 필터링된 어레이 리턴, 대소문자구분X
    const filteredArray = array.filter((item: any) => {
      return item.nName?.toLowerCase()?.includes(filterOptions.nName?.toLowerCase());
    });

    const result = ynFilter(filteredArray, 'useYn', searchOptionUseYn[COMCD_USE_YN]);
    if (result.length > 0) setTableState(result);
    else setTableState([]);
  };

  const onClickInitiateSearchOption = () => {
    setSearchOption({ searchnName: '' });
    setSearchOptionUseYn({ type: COMCD_USE_YN, USE_YN: 'A', cdName: t('전체') });
    if (includeInfra) setSInfraSearch({ type: COMCD_INFRA, [COMCD_INFRA]: 'A', cdName: t('전체') });
    onClickNewAdd(); // 우측 인풋창도 초기화
    setSelectedRowKey(null);
    setTableState(initTableState.map((el: any, i) => ({ ...el, rowKey: i, sortKey: i })));
  };

  // 저장버튼 클릭해서 save API 호출하여 데이터전송
  const onClickSave = () => {
    setIsSaveClicked(true);
    if (nvrGubun.nvrGubun === '') return toast.warning(t('제조사를 선택하세요'));
    if (cctvInputState.nName?.trim() === '') return toast.warning(t('NVR 명을 입력하세요'));
    if (cctvInputState.ip?.trim() === '') return toast.warning(t('접속 주소를 입력하세요'));
    if (cctvInputState.port === '') return toast.warning(t('RTSP 포트번호를 입력하세요'));
    if (userInfo.cctvProtocol === '1' && !cctvInputState.pPort?.trim()) return toast.warning(t('HTTP 포트번호를 입력하세요'));
    if (includeInfra && sInfra[COMCD_INFRA] === '') return toast.warning(t('건설업종을 선택하세요'));
    if (cctvInputState.id === '') return toast.warning(t('아이디를 입력하세요'));
    if (cctvInputState.password === '') return toast.warning(t('비밀번호를 입력하세요'));

    if (isNewAdd) return saveNvrAPI();
    return updateNvrAPI();
  };

  // 신규등록 버튼 클릭해서 새 입력창(iputForm) 노출
  const onClickNewAdd = () => {
    initiateState();
    setIsCctvList(false);
    setIsCctvView(false);
    setIsNewAdd(true);
    setIsCameraTableVisible({ visible: false, mode: 'view' });
    setSelectedRowKey(null);

    if (userInfo.cctvProtocol === '1') {
      setProtocol({ type: 'protocol', protocol: 'HTTP', cdName: 'HTTP' });
      setcctvInputState((prev: any) => ({ ...prev, pPort: '80' }));
    }
  };

  // 삭제버튼 클릭으로 삭제 모달창 띄우기
  const onClickDelete = () => {
    setOpenModal((prev) => ({ ...prev, status: true, type: 'delete', api: deleteNvrAPI }));
  };

  // 스테이트 객체의 밸류를 ''로 초기화
  const initiateState = () => {
    Object.keys(cctvInputState).map((el: any) => {
      // 기본값 제외하고 초기화
      return setcctvInputState((prev: any) => ({
        ...prev,
        [el]: '',
        inputFormUseYn: 'Y',
        inputFormUseYnCdName: t('사용'),
        nvrGubun: initialNvrGubun.subCd,
        writer: userInfo.userId,
        editor: userInfo.userId,
      }));
    });
    setInputFormUseYn({ type: COMCD_USE_YN, [COMCD_USE_YN]: 'Y', cdName: t('사용') });
    setNvrGubun({ type: 'nvrGubun', nvrGubun: initialNvrGubun.subCd, cdName: initialNvrGubun.cdName });
    setIsSaveClicked(false);
    if (includeInfra) {
      setSInfra({ type: COMCD_INFRA, [COMCD_INFRA]: '', cdName: t('미선택') });
    }
  };

  // 카메라 인풋 수정
  const onChangeCameraTableState = (e: React.ChangeEvent<HTMLInputElement>, i: number, el: ICameraObject) => {
    const { name, value } = e.currentTarget;
    const viewDataArray = [...cameraTableState]; // 뷰테이블 로우 어레이
    const rawDataArray: any = [...initCameraTableState]; // 데이터테이블 로우 어레이

    let findIndex;
    if (el.index === undefined) {
      // 새로운 로우가 아닐때, 데이터테이블에서 해당하는 로우의 인덱스값 찾기
      findIndex = initCameraTableState.findIndex((rawEl: any) => rawEl.cCd === el.cCd);
    } else {
      // 새로운 로우일때 인덱스값
      findIndex = el.index;
    }

    viewDataArray[i] = { ...viewDataArray[i], [name]: value }; // 뷰테이블 로우 업데이트
    if (findIndex !== -1) {
      // 데이터테이블 로우 업데이트
      rawDataArray[findIndex] = { ...rawDataArray[findIndex], [name]: value, editor: userInfo.userId };
    }

    setCameraTableState(viewDataArray);
    setInitCameraTableState(rawDataArray);
  };

  const onChangeRadioOption = (e: React.ChangeEvent<HTMLInputElement>, i: number, el: ICameraObject) => {
    const viewDataArray = [...cameraTableState]; // 뷰테이블 로우 어레이

    if (el.index === undefined) {
      // 뷰데이터 업데이트
      const updateViewArray = viewDataArray.map((viewEl, viewIndex) => {
        return viewIndex === i ? { ...viewEl, mainPlayYn: 'Y', editor: userInfo.userId } : { ...viewEl, mainPlayYn: 'N' };
      });
      setCameraTableState(updateViewArray);
      setSelectedcCd(el.cCd);
    } else {
      // 뷰데이터 업데이트
      const updateViewArray = viewDataArray.map((viewEl, viewIndex) => {
        return viewIndex === i ? { ...viewEl, mainPlayYn: 'Y' } : { ...viewEl, mainPlayYn: 'N' };
      });
      setCameraTableState(updateViewArray);
      setSelectedcCd(el.index);
    }
  };

  // 카메라 신규항목 추가 클릭
  const onClickAddCameraTableRow = () => {
    setNewCameraRowIndex((prev) => prev + 1);

    const data: ICameraObject = {
      index: newCameraRowIndex,
      hCd: cctvInputState.hCd,
      sCd: cctvInputState.sCd,
      nCd: cctvInputState.nCd,
      cCd: '',
      wsNum: '',
      cName: '',
      cChannelNum: '',
      stream: '2',
      mainPlayYn: 'N',
      bigo: '',
      useYn: 'Y',
      writer: userInfo.userId,
      editor: userInfo.userId,
    };

    setCameraTableState((prev: ICameraObject[]) => [...prev, data]);
    setInitCameraTableState((prev: ICameraObject[]) => [...prev, data]);
    setAddRowStatus(true);
  };

  // 카메라 불러오기 모달에서 선택 클릭
  const onClickAddCameraTableArray = (newCameraLoadList: any) => {
    const filterCameraLoadList = newCameraLoadList.filter((v: any) => v.check === true);
    filterCameraLoadList.map((v: any, i: number) => {
      setNewCameraRowIndex((prev) => prev + 1);

      const data: ICameraObject = {
        index: newCameraRowIndex + i,
        hCd: cctvInputState.hCd,
        sCd: cctvInputState.sCd,
        nCd: cctvInputState.nCd,
        cCd: '',
        wsNum: '',
        cName: v.name,
        cChannelNum: v.id,
        stream: '2',
        mainPlayYn: 'N',
        bigo: '',
        useYn: v.online === 'true' ? 'Y' : 'N',
        writer: userInfo.userId,
        editor: userInfo.userId,
      };
      setCameraLoadList(newCameraLoadList.filter((el: any) => el.check === false));
      setCameraTableState((prev: ICameraObject[]) => [...prev, data]);
      setInitCameraTableState((prev: ICameraObject[]) => [...prev, data]);
      setOpenModal((prev: IModal) => ({ ...prev, status: false }));
    });
  };

  // 카메라 불러오기 클릭
  const onClickCameraLoad = () => {
    const newCameraLoadList: any[] = [];
    cctv
      .getChannelInfo(cctvInputState.ip)
      .then((res: any) => {
        res.map((v: any) => {
          let check = false;
          for (const v2 of cameraTableState) {
            if (v.id == v2.cChannelNum) {
              check = true;
            }
          }
          if (!check) newCameraLoadList.push({ ...v, check: true });
        });
        setCameraLoadList([...newCameraLoadList]);
        toast.success(() => (
          <div>
            {t('연결 성공 !')}
            <br />
            {t('연결된 카메라 대수는')} {res.length}
            {t('대 입니다.')}
          </div>
        ));
        setOpenModal((prev) => ({
          ...prev,
          status: true,
          type: 'cameraLoad',
          cameraLoadList,
          setCameraLoadList,
          cameraTableState,
          setCameraTableState,
          onClickAddCameraTableArray,
        }));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const decreaseArrayNo = (array: ICameraObject[], dataLength: number, setState: Dispatch<SetStateAction<ICameraObject[]>>) => {
    let increase = 0;

    const newArray = array.map((el, i) => {
      if (el.index !== undefined) {
        increase += 1;
        const copyArray = [...array];
        copyArray[i] = {
          ...copyArray[i],
          no: dataLength + increase,
        };
        return copyArray[i];
      }
      return el;
    });
    setState(newArray);
  };

  // 카메라 항목 삭제 클릭
  const onClickDeleteRow = (el: ICameraObject, i: number) => {
    if (el.mainPlayYn === 'N') {
      // 새로 추가한 로우 제거 클릭 시
      if (el.index !== undefined) {
        const updatedArray = [...cameraTableState];
        updatedArray.splice(i, 1);

        decreaseArrayNo(updatedArray, initCameraTableLength, setCameraTableState);

        /**
         * 수정자 : 한영광
         * 수정일자 : 2023-11-02
         * 수정내용 : 신규항목 추가 후 제거 하면 저장할 때 해당 항목이 initCameraTableState에 남아있어 저장안되는 오류 수정
         */
        // 수정 후
        const rawDataArray: ICameraObject[] = [...initCameraTableState].filter((v: any) => v.index !== el.index);
        setInitCameraTableState(rawDataArray);
        // 수정 전
        // const findIndex = initCameraTableState.findIndex((rawEl: any) => rawEl.index === el.index);
        //   console.log(rawDataArray[findIndex]);
        // if (findIndex !== -1) {
        //   rawDataArray[findIndex] = {
        //     ...rawDataArray[findIndex],
        //   };
        //   console.log(rawDataArray);
        //   setInitCameraTableState(rawDataArray);
        // }
      } else {
        // 삭제 시 에디터아이디 추가
        const data = { ...el, editor: userInfo.userId };
        setOpenModal((prev: any) => ({ ...prev, status: true, type: 'array', state: cameraTableState, setState: setCameraTableState, api: deleteCameraAPI, el: data, index: i }));
      }
    } else toast.warning(t('기본 카메라는 삭제하실 수 없습니다.'));
  };

  // 카메라 항목 저장 클릭시 필수입력값 체크
  const onClickSaveCameraTable = () => {
    setIsSave2Clicked(true);
    // 기본값이 Y이면서 동시에 사용유무가 N 인 경우
    const mainPlayYnN = cameraTableState.find((el) => el.mainPlayYn === 'Y' && el.useYn === 'N');
    if (mainPlayYnN !== undefined) {
      return toast.warning(t('Main 카메라의 경우 미사용할 수 없습니다.'));
    }

    const findMainPlayCamera = cameraTableState.find((el: ICameraObject) => el.mainPlayYn === 'Y');
    const rawDataArray: any = [...initCameraTableState]; // 데이터테이블 로우 어레이
    let updateRawArray;
    if (findMainPlayCamera !== undefined) {
      if (typeof selectedcCd === 'string') {
        //  선택된 기본값이 기존 로우인경우
        // 데이터테이블 업데이트
        updateRawArray = rawDataArray.map((rawEl: ICameraObject) => {
          return rawEl.cCd === selectedcCd ? { ...rawEl, mainPlayYn: 'Y', editor: userInfo.userId } : { ...rawEl, mainPlayYn: 'N', editor: userInfo.userId };
        });
        setInitCameraTableState(updateRawArray);
      } else {
        //  선택된 기본값이 새 로우인 경우
        // 데이터 테이블 업데이트
        updateRawArray = rawDataArray.map((el: ICameraObject) => {
          return el.index === selectedcCd ? { ...el, mainPlayYn: 'Y', editor: userInfo.userId } : { ...el, mainPlayYn: 'N', editor: userInfo.userId };
        });
        setInitCameraTableState(updateRawArray);
      }
      const requiredFieldArray = ['cName', 'cChannelNum', 'stream', 'mainPlayYn', 'useYn'];
      const emptyCheck = updateRawArray.filter((el: any) => {
        const check = requiredFieldArray.find((el2: string) => el[el2] === '');
        return check;
      });
      if (emptyCheck.length > 0) {
        toast.warning(t('필수입력값을 모두 입력하세요'));
      } else {
        const newArray = updateRawArray.map(
          ({ index, no, cameraUseYnCdName, rowKey, sortKey, uniqueKey, _attributes, _disabledPriority, rowSpanMap, _relationListItemMap, streamCdName, flag, ...rest }: any) => rest
        );
        saveCameraListAPI(newArray);
      }
    } else toast.warning(t('Main 카메라를 선택하세요.'));
    return undefined;
  };

  const onClickCctvView = async () => {
    if (!isCctvView) {
      if (cctvInputState && cctvInputState.nCd !== '' && cameraTableState.length > 0) {
        setIsCctvList(false);
        setIsCctvView(true);
        setCctvFlow(0);
      } else toast.warning(t('등록된 NVR 또는 카메라가 없습니다. NVR과 카메라 등록 후 사용하실 수 있습니다.'));
    }
  };
  const onClickCctvList = () => {
    if (!isCctvList) {
      setIsCctvList(true);
      setIsCctvView(false);
      setCctvFlow(0);
    }
  };
  const onClickCctvSetting = () => {
    setIsCctvList(false);
    setIsCctvView(false);
    if (cctv.getPluginOBJECT()?.oPlugin) {
      cctv.destroy();
    }
  };

  // 카메라 셀렉트항목 선택시 selectedCamera subCd setState
  const onChangeCameraSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    const findObject = cameraTableState.find((el: any) => el.cCd === value);
    if (userInfo.cctvProtocol === '1' && !mobile && cctvFlow === 2) {
      cctv.startRealPlay(cctvInputState.ip, findObject?.stream, findObject?.cChannelNum, 0, cctvInputState.port);
    } else {
      if (findObject !== undefined) {
        setVideoUrl(findObject.wsNum);
      }
      setSelectedCamera({ subCd: value });
    }
  };

  useEffect(() => {
    if (defaultCameraInfo) setVideoUrl(defaultCameraInfo.wsNum);
  }, [defaultCameraInfo]);

  const CAMERA_CHANNEL_LIST: any = [];
  for (let i = 1; i <= 64; i += 1) {
    CAMERA_CHANNEL_LIST.push({ type: 'cChannelNum', cChannelNum: i.toString(), uName: i.toString() });
  }

  const renderDeleteBtn = (el: any, i: number) => {
    if (auth.deleteAuth) {
      return (
        <div className='trCol6 flex-center'>
          <BtnRed onClick={() => onClickDeleteRow(el, i)}>{el.cCd !== '' ? t('삭제') : t('제거')}</BtnRed>
        </div>
      );
    }
    if (el.cCd === '' && (auth.createAuth || auth.updateAuth)) {
      return <BtnRed onClick={() => onClickDeleteRow(el, i)}>{t('제거')}</BtnRed>;
    }
    return null;
  };

  const getCamNvrInfoAPI = async (objParam: any) => {
    const res = await apiGet({ path: '/cam/nvr/info', req: objParam });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      if (data.nvr.nCd) {
        const object = {
          hCd: data.nvr.hCd,
          sCd: data.nvr.sCd,
          nCd: data.nvr.nCd,
        };
        // 테이블 로우를 클릭했을 때 카메라리스트 조회 API요청
        getCameraListAPI(object);
        setIsSave2Clicked(false);
      }

      // protocol값이 null인경우 HTTP으로 설정 (기본선택값)
      setcctvInputState((prev: any) => ({ ...prev, ...data.nvr }));
      setProtocol({ type: 'protocol', protocol: data.nvr.protocol || 'HTTP', cdName: data.nvr.protocol || 'HTTP' });
    } else {
      // toast.error(t(ERROR));
    }
  };

  useEffect(() => {
    if (isNewAdd) {
      setcctvInputState((prev: any) => ({ ...prev, pPort: protocol.protocol === 'HTTP' ? '80' : '443' }));
    }
  }, [protocol.protocol]);

  // Row 클릭
  const onClickRow = (rowData: any, columnName: any, filterRowKey: any) => {
    onClickCctvSetting();
    if (rowData.nCd) {
      const object = { hCd: userInfo.hCd, sCd: userInfo.sCd, nCd: rowData.nCd, cCd: rowData.cCd };
      getCamNvrInfoAPI(object);
    }
    setSelectedRowKey(rowData.rowKey);
    setIsNewAdd(false);
    setNvrGubun({ type: 'nvrGubun', nvrGubun: rowData.nvrGubun, cdName: rowData.nvrGubunCdName });
    setInputFormUseYn({ type: COMCD_USE_YN, [COMCD_USE_YN]: rowData.useYn, cdName: rowData.useYnCdName });
    setIsSaveClicked(false);
    setViewTable(false);
    if (includeInfra) setSInfra({ type: COMCD_INFRA, [COMCD_INFRA]: rowData.sInfra, cdName: rowData.sInfraName });
  };

  const componentRef = useRef<HTMLDivElement>(null);
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  const [tuiWidth, setTuiWidth] = useState<null | number>(null);

  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight]);

  useEffect(() => {
    if (componentRef.current !== null && componentRef.current !== undefined) setTuiWidth(componentRef.current?.offsetWidth);
  }, [componentRef.current?.offsetWidth]);

  useEffect(() => {
    if (sInfraSearch[COMCD_INFRA] !== 'A') setShowSortBtn(true);
    else setShowSortBtn(false);
  }, [sInfraSearch[COMCD_INFRA]]);

  const backToMain = () => {
    setViewTable(true);
    setSelectedRowKey(null);
  };

  const addBtnAction = () => {
    onClickNewAdd();
    if (size.innerSize.W < 1024) {
      setViewTable(false);
    }
  };

  // 정렬 저장 클릭
  const onClickSaveSortOrder = async () => {
    const newArray = filterTableState.map((el: any) => ({ hCd: userInfo.hCd, sCd: userInfo.sCd, nCd: el.nCd, cdSort: el.sortKey }));
    const req = { nvrSortReqDto: newArray };
    const res = await apiPost({ path: '/cam/nvr/sort', req });
    const { data, message, statusCode } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));

      const result = data.nvrList.map((el: INvrObject) => {
        const findObject = nvrGubunComcdList.find((comCd: IComCdList) => comCd.subCd === el.nvrGubun);
        return { ...el, useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용'), nvrGubunCdName: findObject !== undefined ? findObject.cdName : '' };
      });
      const sortedArray = arraySortByAscdOrder(result, 'cdSort');
      setInitTableState(sortedArray);
      initiateState();

      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: 'CCTV 관리 > CCTV 정보',
        action: '정렬 저장',
        etc: ``,
      });
    }
  };

  return (
    <div className={`content-container ${size.innerSize.W >= 1024 ? 'twoColumn column-55 max800' : 'oneColumn'}`}>
      <Root className={size.innerSize.W >= 1024 || viewTable ? 'showRoot' : 'hideRoot'}>
        <SearchOptions align='left'>
          {isBottom && (
            <div className='floatingBtnWrapper flex-center'>
              <button type='button' onClick={() => scrollToNodeTop(node)}>
                <IoChevronUpSharp size={20} style={{ stroke: 'white' }} />
              </button>
            </div>
          )}
          <div className='inputsWrapper'>
            <div className='secondSearchOption fit-width'>
              <div className='flex-basic textBtnGroup'>
                <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
              </div>
            </div>
            {includeInfra && (
              <div className='inputForm-row'>
                <div className='inputForm-col withLabelComCf'>
                  <label htmlFor='useYn'>{t('건설업종 구분')}</label>
                  <SelectBox options={infraComCdListWithAll} defaultOption={t('전체')} state={sInfraSearch} setState={setSInfraSearch} stateKey={COMCD_INFRA} initiateKey={sInfraSearch[COMCD_INFRA]} />
                </div>
              </div>
            )}
            <div className='inputForm-row'>
              <div className='inputForm-col withLabelComCf'>
                <label htmlFor='useYn'>{t('사용유무')}</label>
                <SelectBox
                  options={useYnComCdListWithAll}
                  defaultOption={searchOptionUseYn.cdName}
                  state={searchOptionUseYn}
                  setState={setSearchOptionUseYn}
                  stateKey={COMCD_USE_YN}
                  initiateKey={searchOptionUseYn[COMCD_USE_YN]}
                />
              </div>
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col '>
                <Input placeholder={t('NVR 명')} label='' type='text' name='searchnName' state={searchOption} setState={setSearchOption} />
              </div>
            </div>
          </div>
          <div className='inputsWrapper'>
            <div className='secondSearchOption'>
              <div className='flex-between iconBtnGroup w100' style={{ justifyContent: !includeInfra ? 'flex-end' : '' }}>
                {includeInfra && <InfoTextWithIcon icon='flash_on' text={!showSortBtn ? CAPTION_1 : CAPTION_2} />}
                {userInfo.cctvProtocol === '1' && !mobile && (
                  <BtnGhost className='padding-more'>
                    <span className='material-symbols-rounded'>download </span>
                    <a href={CCTV_VIEWER_PLUGIN_URL}>CCTV Viewer {t('다운로드')}</a>
                  </BtnGhost>
                )}
              </div>
            </div>
          </div>
        </SearchOptions>
        <div ref={componentRef} className='tui-container'>
          {tuiWidth !== null && (
            <TuiGrid
              key={sInfraSearch[COMCD_INFRA]}
              data={tableState}
              columns={columns}
              perPage={15}
              rowKey={selectedRowKey}
              onClickRow={onClickRow}
              frozenCount={1}
              height={tuiHeight}
              draggable={includeInfra && sInfraSearch[COMCD_INFRA] !== 'A'}
              // filterTableState={filterTableState}
              setFilterTableState={setFilterTableState}
            />
          )}
        </div>
        <ButtonsWrapper>
          {showSortBtn && (auth.createAuth || auth.updateAuth) && <BtnGreen onClick={() => onClickSaveSortOrder()}>{t('정렬 저장')}</BtnGreen>}
          {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={() => addBtnAction()}>{t('새 CCTV 등록')}</BtnBlue>}
        </ButtonsWrapper>
      </Root>
      {(size.innerSize.W >= 1024 || !viewTable) && (
        <Root>
          {isNewAdd ? (
            <div className='inputFormsWrapper flexRowEm'>
              {size.innerSize.W < 1024 && <BackButton func={() => backToMain()} />}

              <div className='formTitle'>{t('NVR 등록')}</div>
            </div>
          ) : (
            <div className='inputFormsWrapper smallTab'>
              {size.innerSize.W < 1024 && <BackButton func={() => backToMain()} />}

              <div className={`tab ${!isCctvView && !isCctvList ? 'activeTab' : undefined}`} role='button' tabIndex={0} onClick={onClickCctvSetting}>
                {t('NVR 설정')}
              </div>
              <div className={`tab ${isCctvList ? 'activeTab' : undefined}`} role='button' tabIndex={0} onClick={onClickCctvList}>
                {t('NVR 목록')}
              </div>
              <div className={`tab ${isCctvView ? 'activeTab' : undefined}`} role='button' tabIndex={0} onClick={onClickCctvView}>
                {t('CCTV 보기')}
              </div>
            </div>
          )}

          {!isCctvList && !isCctvView && (
            <SubRoot width='100%'>
              <div className='inputForm'>
                <div className='inputForm-group-1536'>
                  {!isNewAdd && (
                    <div className='inputForm-row labelOutInput'>
                      <label htmlFor='code'>{t('코드')}</label>
                      <div className='viewOnly'>{cctvInputState?.nCd}</div>
                    </div>
                  )}
                  <div className='inputForm-row labelOutInput'>
                    <label htmlFor='nvrGubun'>{t('제조사')}</label>
                    <div>
                      <SelectBox
                        options={nvrGubunComCdList}
                        defaultOption={isNewAdd ? initialNvrGubun.cdName : nvrGubun.cdName}
                        state={nvrGubun}
                        setState={setNvrGubun}
                        stateKey='nvrGubun'
                        initiateKey={nvrGubun.nvrGubun}
                      />
                    </div>
                  </div>
                </div>

                <div className='inputForm-group-1536'>
                  <div className='inputForm-row labelInInput'>
                    <Input
                      className='required'
                      label={t('NVR 명')}
                      type='text'
                      id='nName'
                      name='nName'
                      state={cctvInputState}
                      setState={setcctvInputState}
                      disabled={!auth.updateAuth}
                      getBorderStyle={isSaveClicked ? applyBorderStyle(cctvInputState.nName, 'red', 'nName') : undefined}
                    />
                  </div>
                  <div className='inputForm-row labelOutInput'>
                    <label htmlFor='useYn'>{t('사용유무')}</label>
                    <div>
                      <SelectBox
                        options={useYnComCdList}
                        defaultOption={isNewAdd ? t('사용') : inputFormUseYn.cdName}
                        state={inputFormUseYn}
                        setState={setInputFormUseYn}
                        stateKey={COMCD_USE_YN}
                        initiateKey={inputFormUseYn[COMCD_USE_YN]}
                      />
                    </div>
                  </div>
                </div>

                <div className='inputForm-group-1536'>
                  <div className='inputForm-row labelOutInput'>
                    <label htmlFor='ip' className='required'>
                      {t('접속 주소')}
                    </label>
                    <Input
                      type='text'
                      id='ip'
                      name='ip'
                      state={cctvInputState}
                      setState={setcctvInputState}
                      disabled={!auth.updateAuth}
                      getBorderStyle={isSaveClicked ? applyBorderStyle(cctvInputState.ip, 'red', 'ip') : undefined}
                    />
                  </div>
                  <div className='inputForm-row labelOutInput'>
                    <label htmlFor='port' className='required'>
                      {t('RTSP 포트번호')}
                    </label>
                    <Input
                      type='number'
                      id='port'
                      name='port'
                      state={cctvInputState}
                      setState={setcctvInputState}
                      trim
                      disabled={!auth.updateAuth}
                      getBorderStyle={isSaveClicked ? applyBorderStyle(cctvInputState.port, 'red', 'port') : undefined}
                    />
                  </div>
                </div>
                {userInfo.cctvProtocol === '1' && (
                  <div className='inputForm-group-1536'>
                    <div className='inputForm-row labelOutInput'>
                      <label htmlFor='protocol'>{t('연결 방식')}</label>
                      <div>
                        <SelectBox options={PROTOCOL} defaultOption={protocol.protocol} state={protocol} setState={setProtocol} stateKey='protocol' initiateKey={cctvInputState.nCd} />
                      </div>
                    </div>
                    <div className='inputForm-row labelOutInput'>
                      <label htmlFor='pPort' className='required'>
                        {protocol.protocol} {t('포트번호')}
                      </label>
                      <Input
                        type='number'
                        id='pPort'
                        name='pPort'
                        state={cctvInputState}
                        setState={setcctvInputState}
                        trim
                        disabled={!auth.updateAuth}
                        getBorderStyle={isSaveClicked ? applyBorderStyle(cctvInputState.pPort || '', 'red', 'pPort') : undefined}
                      />
                    </div>
                  </div>
                )}
                <div className='inputForm-group-1536'>
                  <div className='inputForm-row labelOutInput'>
                    <label htmlFor='sPort'>{t('SERVER 포트번호')}</label>
                    <Input type='number' id='sPort' name='sPort' state={cctvInputState} setState={setcctvInputState} trim disabled={!auth.updateAuth} />
                  </div>
                  {includeInfra && (
                    <div className='inputForm-row labelOutInput'>
                      <label htmlFor='useYn' className='required'>
                        {t('건설업종')}
                      </label>
                      <SelectBox options={infraComCdList} defaultOption={t('미선택')} state={sInfra} setState={setSInfra} stateKey={COMCD_INFRA} initiateKey={sInfra[COMCD_INFRA]} />
                    </div>
                  )}
                </div>
                <div className='inputForm-group-1536'>
                  <div className='inputForm-row labelOutInput'>
                    <label htmlFor='id' className='required'>
                      {t('아이디')}
                    </label>
                    <Input
                      type='text'
                      id='id'
                      name='id'
                      state={cctvInputState}
                      setState={setcctvInputState}
                      trim
                      disabled={!auth.updateAuth}
                      getBorderStyle={isSaveClicked ? applyBorderStyle(cctvInputState.id, 'red', 'id') : undefined}
                    />
                  </div>
                  <div className='inputForm-row labelOutInput'>
                    <label htmlFor='password' className='required'>
                      {t('비밀번호')}
                    </label>
                    <Input
                      type='password'
                      id='popasswordrt'
                      name='password'
                      state={cctvInputState}
                      setState={setcctvInputState}
                      trim
                      disabled={!auth.updateAuth}
                      getBorderStyle={isSaveClicked ? applyBorderStyle(cctvInputState.password, 'red', 'popasswordrt') : undefined}
                    />
                  </div>
                </div>
                <div className='inputForm-row labelInInput'>
                  <Input label={t('비고')} type='text' id='bigo' name='bigo' state={cctvInputState} setState={setcctvInputState} disabled={!auth.updateAuth} maxLength={200} />
                </div>
                <div className='inputForm-group-1536' />
              </div>
            </SubRoot>
          )}
          {isCctvList && (
            <SubRoot width='100%'>
              <InputTable>
                <div ref={scrollContainerRef}>
                  <div className='thead'>
                    <div className='tr'>
                      <div className='trCol2p5 flex-center tableStickyNo'>No</div>
                      <div className='trCol4 flex-center'>{t('코드')}</div>
                      <div className='trCol10 flex-center required tableStickyTitle'>{t('카메라 명')}</div>
                      {/* <div className={auth.deleteAuth ? 'trCol15 required' : 'trCol20 required'}>카메라 명</div> */}
                      <div className='trCol6 flex-center required'>{t('채널번호')}</div>
                      <div className='trCol6 flex-center required'>{t('스트림')}</div>
                      <div className='trCol4 flex-center required'>{t('Main')}</div>
                      <div className='trCol12 flex-center content-overflow'>{t('비고')}</div>
                      <div className='trCol8 flex-center required'>{t('사용유무')}</div>
                      {auth.deleteAuth && <div className='trCol6'> </div>}
                    </div>
                  </div>
                  <div className='table'>
                    {cameraTableState.length > 0 ? (
                      <div className='tbody'>
                        {cameraTableState.map((el, i) => (
                          <div className='tr' role='button' tabIndex={0} key={i}>
                            <div className='trCol2p5 flex-center tableStickyNo'>{i + 1}</div>
                            <div className='trCol4 flex-center'>{el.cCd}</div>
                            {/* 카메라명 */}
                            <div className='trCol10 flex-center tableStickyTitle'>
                              {/* <div className={auth.deleteAuth ? 'trCol15' : 'trCol20'}> */}
                              <input
                                type='text'
                                name='cName'
                                value={el.cName}
                                onChange={(e) => onChangeCameraTableState(e, i, el)}
                                disabled={!auth.updateAuth}
                                style={isSave2Clicked ? applyBorderStyle(el.cName, 'red', 'cName') : undefined}
                                maxLength={50}
                              />
                            </div>
                            <div className='trCol6 flex-center'>
                              <SelectBoxs
                                options={CAMERA_CHANNEL_LIST}
                                defaultOption={el.cChannelNum !== '' ? el.cChannelNum : t('선택')}
                                state={cameraTableState}
                                setState={setCameraTableState}
                                rawData={initCameraTableState}
                                setRawData={setInitCameraTableState}
                                stateKey='cChannelNum'
                                codeKey='uName'
                                index={i}
                                object={el}
                                primaryKey='cCd'
                                disabled={userInfo.cctvProtocol === '1' ? true : !auth.updateAuth}
                                getBorderStyle={isSave2Clicked ? applyBorderStyle(el.cChannelNum, 'red', 'cChannelNum') : undefined}
                              />
                            </div>
                            <div className='trCol6 flex-center'>
                              <SelectBoxs
                                options={CAMERA_STREAM}
                                defaultOption={el.stream !== '' ? (el.stream === '1' ? 'Main' : el.stream === '3' ? 'Third' : 'Sub') : 'Sub'}
                                state={cameraTableState}
                                setState={setCameraTableState}
                                rawData={initCameraTableState}
                                setRawData={setInitCameraTableState}
                                stateKey='stream'
                                codeKey='uName'
                                index={i}
                                object={el}
                                primaryKey='cCd'
                                disabled={!auth.updateAuth}
                                getBorderStyle={isSave2Clicked ? applyBorderStyle(el.stream, 'red', 'stream') : undefined}
                              />
                            </div>
                            <div className='trCol4 flex-center'>
                              <input type='radio' name='mainPlayYn' disabled={el.useYn === 'N' || !auth.updateAuth} checked={el.mainPlayYn === 'Y'} onChange={(e) => onChangeRadioOption(e, i, el)} />
                            </div>
                            <div className='trCol12 flex-center content-overflow'>
                              <input type='text' name='bigo' value={el.bigo} onChange={(e) => onChangeCameraTableState(e, i, el)} disabled={!auth.updateAuth} maxLength={200} />
                            </div>
                            <div className='trCol8 flex-center'>
                              <SelectBoxs
                                options={USE_YN}
                                defaultOption={el.useYn === 'Y' ? t('사용') : t('미사용')}
                                state={cameraTableState}
                                setState={setCameraTableState}
                                rawData={initCameraTableState}
                                setRawData={setInitCameraTableState}
                                stateKey='useYn'
                                codeKey='cdName'
                                index={i}
                                object={el}
                                primaryKey='cCd'
                                // disabled={userInfo.cctvProtocol === '1' ? true : !auth.updateAuth}
                              />
                            </div>
                            {renderDeleteBtn(el, i)}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className='noData'>No data.</div>
                    )}
                  </div>
                </div>
                <div>
                  <div id='divPlugin' className='cctvWrapperHidden' />
                </div>
              </InputTable>
            </SubRoot>
          )}
          {isCctvView && (
            <div className={userInfo.cctvProtocol === '1' ? 'webSdkCctvViewWrapper' : 'rtspCctvViewWrapper'}>
              {/* rtsp, web sdk 여부에 따라 CSS스타일링 변경 */}
              <div className='flex-flex'>
                {/* <div className='caption'>카메라 선택</div> */}
                <select onChange={onChangeCameraSelect}>
                  {cameraSelectList.map((el: any) => (
                    <option key={v1()} value={el.subCd} selected={selectedCamera.subCd === el.subCd}>
                      {el.cdName}
                    </option>
                  ))}
                </select>
                {/* <ComCdSelectBox options={cameraSelectList} defaultOption={selectedCamera.cdName} state={selectedCamera} setState={setSelectedCamera} /> */}
              </div>
              {userInfo.cctvProtocol === '1' && !mobile ? (
                <div>
                  <div id='divPlugin' className='cctvWrapper' />
                </div>
              ) : (
                <VideoPlayer wsNum={videoUrl} options={{ autoplay: true }} setPlayer={setPlayer} />
              )}
            </div>
          )}
          {!isCctvList && !isCctvView && (
            <ButtonsWrapper>
              {!isNewAdd && auth.deleteAuth && <BtnRed onClick={onClickDelete}>{t('삭제')}</BtnRed>}
              {auth.updateAuth && <BtnBlue onClick={onClickSave}>{t('저장')}</BtnBlue>}
            </ButtonsWrapper>
          )}
          {isCctvList && (
            <ButtonsWrapper>
              {userInfo.cctvProtocol === '1' && !mobile && <BtnGreen onClick={onClickCameraLoad}>{t('카메라 불러오기')}</BtnGreen>}
              {auth.createAuth && userInfo.cctvProtocol !== '1' && <BtnBlue onClick={onClickAddCameraTableRow}>{t('신규항목 추가')}</BtnBlue>}
              {auth.updateAuth && <BtnBlue onClick={onClickSaveCameraTable}>{t('저장')}</BtnBlue>}
            </ButtonsWrapper>
          )}
          <Portal openModal={openModal?.status}>
            {openModal && openModal.type === 'websdk' && <WebSdkModal openModal={openModal} setOpenModal={setOpenModal} />}
            {openModal && openModal.type === 'delete' && <DeleteModal2 openModal={openModal} setOpenModal={setOpenModal} />}
            {openModal && openModal.type === 'array' && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}
            {openModal && openModal.type === 'cameraLoad' && <CameraLoadModal openModal={openModal} setOpenModal={setOpenModal} cameraLoadList={cameraLoadList} cameraTableState={cameraTableState} />}
          </Portal>
        </Root>
      )}
    </div>
  );
};

export default CctvInfo;
