/**
 * 작성자 : 홍선영
 * 날짜 : 2024.03.26
 * 기능 : 화면 왼쪽에 뜨는 토스트창
 */

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BtnGraySmall } from '../Button';

const LeftedToastStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

interface IProps {
  message: string;
  onClick?: () => void;
}

const LeftedToast = ({ message, onClick }: IProps) => {
  const { t } = useTranslation();

  return (
    <LeftedToastStyle>
      <div>{t(message)}</div>
      {onClick && (
        <BtnGraySmall type='button' onClick={onClick}>
          {t('보기')}
        </BtnGraySmall>
      )}
    </LeftedToastStyle>
  );
};

export default LeftedToast;
