/**
 * 작성자 : 김광민
 * 날짜 : 2024.02.15
 * 기능 : 1줄 짜리 아이콘이 있는 안내글
 */

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  height: fit-content;
  background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  color: ${({ theme }: { theme: any }) => theme.filled_amber_deep};
  flex-shrink: 0;
  .material-symbols-rounded {
    color: ${({ theme }: { theme: any }) => theme.filled_amber};
    font-variation-settings: 'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 24;
    font-size: 1.25rem;
  }
  .info-text {
    letter-spacing: 0;
  }
`;

type Props = {
  icon: string;
  text: string;
};

const InfoTextWithIcon = ({ icon, text }: Props) => {
  const { t } = useTranslation();
  return (
    <Root>
      <span className='material-symbols-rounded'>{icon}</span>
      <span className='info-text'>{t(text)}</span>
    </Root>
  );
};

export default InfoTextWithIcon;
