/**
 * 작성자 : 홍선영
 * 날짜 : 2023.06.27
 * 기능 : 출입금지자 등록 모달
 */

import { useState, useEffect, Dispatch, SetStateAction, useRef } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';

import { userState } from '../../atoms';
import { IAuth, IModal } from 'customTypes';
import { InputTable } from '../../assets/styles/InputTable';
import { ModalBackground, Modal } from '../../assets/styles/Modal';
import { BtnBlue, BtnGhost, BtnRed } from '../Button';
import Input from '../Input';
import Portal from '../Portal';
import DeleteModal from './DeleteModal2';
import WorkerListModal from './WorkerListModal';
import DatePicker from '../DatePicker';
import { trimObject } from '../../utils/trimObject';
import { applyBorderStyle } from '../../utils/applyBorderStyle';
import { logPost } from '../../services/log';
import { isValidYYYYMMDD } from '../../utils/formatDate';
import { useTranslation } from 'react-i18next';
import { apiPost } from '../../services/_common';

interface IProps {
  getReportListAPI: () => void;
  setOpenModal: Dispatch<SetStateAction<IModal>>;
  auth: IAuth;
  code: { hCd: string; sCd: string };
  dashboard?: boolean; // 대시보드모달여부
}

interface IBlackWorkerInput {
  hCd?: string;
  sCd?: string;
  bSeq?: string;
  wCd?: string;
  bDate: string;
  bChargename: string;
  bLocation: string;
  blackYn: string;
  bMemo: string;
  writer?: string;
  editor?: string;
  sjName?: string;
  sjCd?: string;
  wName?: string;
}

const EnrollmentBlackWorkerModal = ({ getReportListAPI, setOpenModal, auth, code, dashboard }: IProps) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const initialBlackWorker = {
    bDate: '',
    bChargename: '',
    bLocation: '',
    wName: '',
    wJobdate: '',
    sjName: '',
    blackYn: '',
    bMemo: '',
  };

  const [openSubModal, setOpenSubModal] = useState<IModal>({ status: false, type: '', isOverlappingModal: true });
  const [tableState, setTableState] = useState<any[]>([]); // 출입금지자 근로자 리스트
  const [isViewMode, setIsViewMode] = useState(!auth.createAuth && !auth.updateAuth); // 권한에 따른 뷰 or 수정모드 여부
  const [workerInputSet, setWorkerInputSet] = useState<IBlackWorkerInput>(initialBlackWorker);
  const [bDate, setBDate] = useState('');
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값

  useEffect(() => {
    if (bDate !== '') setWorkerInputSet((prev: IBlackWorkerInput) => ({ ...prev, bDate: bDate.replace('-', '') }));
  }, [bDate]);

  // 근로자 검색
  const onClickSearchWorker = async () => {
    setOpenSubModal((prev) => ({ ...prev, status: true, type: 'workerList' }));
  };

  const onClickClose = () => {
    if (!openSubModal.status) setOpenModal((prev: IModal) => ({ ...prev, status: false }));
    else setOpenSubModal((prev: IModal) => ({ ...prev, status: false }));
  };

  const onClickDeleteBlackWorkerRow = (index: number) => {
    const newArray = tableState.filter((_, i) => index !== i);
    setTableState(newArray);
  };

  const onClickSaveBlackWorkers = () => {
    setIsSaveClicked(true);
    if (workerInputSet.bMemo?.trim() === '') return toast.warning(t('필수입력값을 모두 입력하세요'));
    if (!bDate) return toast.warning(t('적발일자를 선택하세요'));
    if (bDate && (bDate.length < 8 || !isValidYYYYMMDD(bDate))) return toast.warning(t('적발일자를 확인하세요'));
    if (tableState.length <= 0) return toast.warning(t('근로자를 선택하세요'));

    const trimData = trimObject(workerInputSet);
    return saveBlackWorkerInfoAPI(trimData);
  };

  const saveBlackWorkerInfoAPI = async (userInput: IBlackWorkerInput) => {
    // blackWorkerDTO 형태에 맞게 새 배열 전송
    const newArray = tableState.map((el) => {
      return {
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        wCd: el.wCd,
        bDate,
        bChargename: userInput.bChargename,
        bLocation: userInput.bLocation,
        blackYn: 'Y',
        bMemo: userInput.bMemo,
        writer: userInfo.userId,
        editor: userInfo.userId,
      };
    });
    const req = { workerBlackReqDto: newArray };
    const res = await apiPost({ path: '/worker/blackinfo', req });
    const { statusCode, data, message } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      getReportListAPI();
      setOpenModal((prev) => ({ ...prev, status: false }));
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '현장 출입금지자 등록(팝업)',
        action: '신규 등록',
        etc: `신규 출입금지자 수 ${newArray.length}명`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  return (
    <ModalBackground onClick={onClickClose} role='presentation'>
      {openSubModal.status !== true && (
        <Modal>
          <div
            className='modal height-fit'
            role='presentation'
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div className='inputForm-head'>
              <div className='modalHeaderTitle'>{t('출입금지자 등록')}</div>
              <div className='closeBtn' onClick={onClickClose} role='presentation'>
                <span className='material-symbols-rounded'>close</span>
              </div>
            </div>
            <div className='black flexColumn flex-between'>
              <div className='filter-option-grid'>
                {/* <div className={`inputForm-body ${isViewMode ? 'view' : undefined}`}> */}
                <div className='datePickerWrapper noPosition'>
                  <label htmlFor='bDate' className='w7rem required'>
                    {t('적발일자')}
                  </label>
                  {/* <div style={{ position: 'fixed', transform: 'translate(7rem, 0%)', inset: 'none' }}> */}
                  <DatePicker startDate={workerInputSet.bDate} setDate={setBDate} popperPlacement='bottom' isSaveClicked={isSaveClicked} />
                </div>
                <Input className='w7rem' label={t('적발담당자')} type='text' name='bChargename' state={workerInputSet} setState={setWorkerInputSet} disabled={!auth.updateAuth && !auth.createAuth} />
                <Input className='w7rem' label={t('적발장소')} type='text' name='bLocation' state={workerInputSet} setState={setWorkerInputSet} disabled={!auth.updateAuth && !auth.createAuth} />
                <Input
                  className='w7rem required'
                  label={t('출입금지 사유')}
                  type='text'
                  name='bMemo'
                  state={workerInputSet}
                  setState={setWorkerInputSet}
                  disabled={!auth.updateAuth && !auth.createAuth}
                  getBorderStyle={isSaveClicked ? applyBorderStyle(workerInputSet.bMemo, 'red', 'bMemo') : undefined}
                />
              </div>
              <div className='tableTop flex-between col-w100'>
                <div className='tableTitle required'>{t('근로자 목록')}</div>
                <div className='buttonsWrapper'>
                  {auth.createAuth && (
                    <BtnGhost onClick={onClickSearchWorker} className='searchBtn'>
                      {t('검색')}
                      <span className='shortcut-f9'>F9</span>
                    </BtnGhost>
                  )}
                </div>
              </div>
              <InputTable>
                <div className='thead'>
                  <div className='tr'>
                    <div className='trCol2p5 flex-center'>{t('순번')}</div>
                    <div className='trCol10 flex-center content-overflow'>{t('협력업체')}</div>
                    <div className='trCol10 flex-center'>{t('근로자명')}</div>
                    {auth.deleteAuth && <div className='trCol6'> </div>}
                  </div>
                </div>
                <div className='table minHeight'>
                  <div className='tbody'>
                    {tableState.map((el: IBlackWorkerInput, i: number) => (
                      <div className='tr' key={i}>
                        <div className='trCol2p5 flex-center'>{i + 1}</div>
                        <div className='trCol10 flex-basic content-overflow'>{el.sjName}</div>
                        <div className='trCol10 flex-center'>{el.wName}</div>
                        {auth.deleteAuth && (
                          <div className='trCol6 flex-center'>
                            <BtnRed onClick={() => onClickDeleteBlackWorkerRow(i)}>{t('제거')}</BtnRed>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </InputTable>
              <div className='modal-footer flex-end col-w100'>{(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={onClickSaveBlackWorkers}>{t('저장')}</BtnBlue>}</div>
            </div>
          </div>
        </Modal>
      )}
      {openSubModal.status === true && openSubModal.type !== 'delete' && (
        <WorkerListModal
          openModal={openSubModal}
          setOpenModal={setOpenSubModal}
          state={tableState}
          setState={setTableState}
          isIncludeBlackUser
          useCheckbox
          dashboard={dashboard}
          onClickClose={onClickClose}
        />
      )}
      <Portal openModal={openSubModal?.status}>{openSubModal.status === true && openSubModal.type === 'delete' && <DeleteModal openModal={openSubModal} setOpenModal={setOpenSubModal} />}</Portal>
    </ModalBackground>
  );
};

export default EnrollmentBlackWorkerModal;
