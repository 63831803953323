/**
 * 작성자 : 한영광
 * 날짜 : 2023.06.12
 * 경로 : 현장소개 -> 현장정보 (src/pages/s_info/S_info1)
 */
import { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';

import { IComCd, IComCdList } from 'customTypes';
import { userState } from '../../../atoms';
import { trimObject } from '../../../utils/trimObject';
import SiteInfoInputForm from '../../../components/Form/SiteInfoInputForm2';
import { isValidYYYYMMDD } from '../../../utils/formatDate';
import { COMCD_INFRA, ERROR } from '../../../_constants';
import { setComCdListState } from '../../../utils/setComCdListState';
import { logPost } from '../../../services/log';
import { useTranslation } from 'react-i18next';
import { apiGet, apiPost } from '../../../services/_common';

interface IMaster {
  width: string;
  flexDirection: string;
}

const Root = styled.div<IMaster>`
  display: flex;
  width: 100%;
  height: 100%;
  /* justify-content: center;
  flex-direction: column; */

  .buttonsWrapper {
    align-items: flex-end;
    margin: 0px 0.5rem;

    > button {
      margin-left: 0.5rem;
    }
  }

  .searchContent {
    display: flex;
    flex-direction: ${(props) => props.flexDirection};
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 8rem;
  }

  .row {
    display: flex;
    flex-direction: row;
  }
`;

const SiteInfo = () => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const node = useRef<any>(null);
  const userInfoInputFormRef = useRef<any>(null); // 인풋 폼 ref (테이블 로우를 클릭했을 때 바로 inputForm 으로 스크롤 이동시키기 위한 ref)
  const [rowState, setRowState] = useState({
    address1: '',
    address2: '',
    bigo: '',
    sCd: '',
    sName: '',
    sDate: '',
    fDate: '',
    sInfra: '',
    sStatus: '',
    sTel: '',
    eDate: '',
    editor: '',
    wDate: null,
    writer: null,
  });
  const [sTelNumState, setSTelNumState] = useState({ num1: '', num2: '', num3: '' });
  const [sInfra, setSInfra] = useState<any>({ type: COMCD_INFRA, [COMCD_INFRA]: '', cdName: '', cdSort: 0 }); // sms사용유무 공통코드
  const [disabled, setDisabled] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [sInfraComCdList, setSInfraComCdList] = useState<IComCdList[]>([]); // 인프라 공통코드
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값

  useEffect(() => {
    setComCdListState(COMCD_INFRA, setSInfraComCdList, false);
    getSiteInfoAPI();
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '현장 정보',
      action: '조회',
      etc: ``,
    });
  }, []);

  // 수정버튼 클릭으로 취소, 저장 버튼 띄우기
  const onClickUpdate = () => {
    setDisabled(false);
  };
  // 취소버튼 클릭으로 수정 버튼 띄우기
  const onClickCancel = () => {
    getSiteInfoAPI();
    setDisabled(true);
    setIsSaveClicked(false);
  };

  // 현장정보 조회 API
  const getSiteInfoAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/site/info', req });
    const { status, data } = res;
    if (status === 200) {
      setRowState(data.data.siteInfo);
      setSInfra({
        type: COMCD_INFRA,
        [COMCD_INFRA]: data.data.siteInfo.sInfra,
        cdName: data.data.siteInfo.sInfraName,
        cdSort: 0,
      });
      const sTelArr = data.data.siteInfo.sTel.split('-');
      setSTelNumState({
        num1: sTelArr[0],
        num2: sTelArr[1],
        num3: sTelArr[2],
      });
      setStartDate(data.data.siteInfo.sDate?.replaceAll('-', ''));
      setEndDate(data.data.siteInfo.fDate?.replaceAll('-', ''));
    }
    return res;
  };

  // 현장정보 저장&수정 API
  const postSiteAPI = async (reqData: any) => {
    const { wDate, eDate, sInfraName, hName, ...newHeadInfo } = reqData;
    const req = newHeadInfo;
    const res = await apiPost({ path: '/site', req });
    const { statusCode, data, message } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      setRowState(data[0].siteInfo);
      setSInfra({
        type: COMCD_INFRA,
        [COMCD_INFRA]: data[0].siteInfo.sInfra,
        cdName: data[0].siteInfo.sInfraName,
        cdSort: 0,
      });
      const sTelArr = data[0].siteInfo.sTel.split('-');
      setSTelNumState({
        num1: sTelArr[0],
        num2: sTelArr[1],
        num3: sTelArr[2],
      });
      setStartDate(data[0].siteInfo.sDate?.replaceAll('-', ''));
      setEndDate(data[0].siteInfo.fDate?.replaceAll('-', ''));
      setDisabled(true);
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '현장 정보',
        action: '수정',
        etc: `${reqData.sName}(${reqData.sCd})`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  useEffect(() => {
    if (startDate !== '') setRowState((prev: any) => ({ ...prev, sDate: startDate }));
  }, [startDate]);

  useEffect(() => {
    if (endDate !== '') setRowState((prev: any) => ({ ...prev, fDate: endDate }));
  }, [endDate]);

  // 저장버튼 클릭해서 save API 호출하여 데이터전송
  const onClickSave = () => {
    setIsSaveClicked(true);
    const sTelMergedNumber = `${sTelNumState.num1}-${sTelNumState.num2}-${sTelNumState.num3}`;
    const reqData = { ...rowState, editor: userInfo.userId, sInfra: sInfra[COMCD_INFRA], sDate: startDate || '', fDate: endDate || '', sTel: sTelMergedNumber };
    const trimData = trimObject(reqData);
    if (!trimData.sName) return toast.warning(t('현장명을 입력하세요'));
    if (!startDate) return toast.warning(t('시공일자를 선택하세요'));
    if (startDate && (startDate.length < 8 || !isValidYYYYMMDD(startDate))) return toast.warning(t('시공일자를 확인하세요'));
    if (!endDate) return toast.warning(t('종료일자를 선택하세요'));
    if (endDate && (endDate.length < 8 || !isValidYYYYMMDD(endDate))) return toast.warning(t('종료일자를 확인하세요'));
    return postSiteAPI(trimData);
  };

  return (
    <Root width='100%' flexDirection='row'>
      <SiteInfoInputForm
        state={rowState}
        setState={setRowState}
        userInfoInputFormRef={userInfoInputFormRef}
        sTelNumState={sTelNumState}
        setSTelNumState={setSTelNumState}
        disabled={disabled}
        sInfra={sInfra}
        setSInfra={setSInfra}
        onClickSave={onClickSave}
        onClickCancel={onClickCancel}
        onClickUpdate={onClickUpdate}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        sInfraComCdList={sInfraComCdList}
        isSaveClicked={isSaveClicked}
      />
    </Root>
  );
};

export default SiteInfo;
