/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.24
 * 경로 : 구역별 상황판 > 터널 종합 진행 현황
 */

import { useQuery } from '@tanstack/react-query';
import { logPost } from '../../services/log';
import { ERROR } from '../../_constants';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import styled from 'styled-components';
import TunnelTotalInfoMiniPoint from './TunnelTotalInfoMiniPoint';
import { apiGet } from '../../services/_common';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  user-select: none;
  overflow: hidden;
  > .title {
    height: 2rem;
    width: 100%;
    padding: 0 1rem;
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: space-between;
    .name {
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      font-weight: ${({ theme }: { theme: any }) => theme.font_semibold};
      font-size: 1.125rem;
      line-height: 1.2;
    }
    .attend {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .material-symbols-rounded {
        font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      }
      .valueName {
        font-size: 0.75rem;
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      }
      .seperator {
        border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
        height: 1rem;
      }
      .count {
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.filled_blue};
        padding: 0 0.25rem;
        border-radius: 0.25rem;
        background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
        min-width: 1.5rem;
        text-align: center;
      }
    }
  }
  > .content {
    width: 100%;
    flex-grow: 1;
    padding: 0 0.5rem;
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

type Props = { name: string; userInfo: any; tatCd: string };

const TunnelTotalInfoMini = ({ name, userInfo, tatCd }: Props) => {
  const { t } = useTranslation();

  // Tarea 데이터 요청 함수
  const fetchTareaData = async ({ hCd, sCd }: { hCd: string; sCd: string }) => {
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, tatCd };
      const res = await apiGet({ path: `/tarea/t/total`, req });
      const { data } = res.data || {};
      return data?.tareaList[0] || [];
    } catch (error) {
      console.error('API 호출 중 오류 발생:', error);
      toast.error(t(ERROR));
      throw new Error('API 호출 중 오류 발생');
    }
  };

  // Tarea 데이터 요청 상태값
  const { data, isError, isLoading } = useQuery(['TunnelTotalInfoMini', userInfo.hCd, userInfo.sCd], () => fetchTareaData(userInfo), {
    refetchInterval: 60000, // 60초마다 자동 refetch
  });

  // 전체 길이 및 반경 길이 계산 함수
  const getPercentage = () => {
    const { eRLength, eLength, sRLength, sLength, sUseYn, eUseYn } = data;
    const rlength = ((sUseYn === 'Y' && sRLength) ?? 0) + ((eUseYn === 'Y' && eRLength) ?? 0);
    const length = ((sUseYn === 'Y' && sLength) ?? 0) + ((eUseYn === 'Y' && eLength) ?? 0);

    return length !== 0 ? (rlength / length) * 100 : 0;
  };

  // 시점 및 종점 렌더링 함수
  const renderPoint = (point: 'start' | 'end') => {
    const { sUseYn, eUseYn } = data;
    const useYn = point === 'start' ? sUseYn : eUseYn;

    if (useYn === 'N') return null;

    return <TunnelTotalInfoMiniPoint point={point} data={data} />;
  };

  // 로딩 중일 때
  if (isLoading) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  // 오류가 발생했을 때
  if (isError) {
    console.error('API 호출 중 오류 발생:', data);
  }

  return (
    <Root>
      <div className='title'>
        <span className='name'>{t('터널 종합 진행 현황')}</span>
        <div className='attend'>
          <span className='material-symbols-rounded'>familiar_face_and_zone</span>
          <span className='valueName'>{t('출역인원')}</span>
          <span className='seperator' />
          <span className='count'>{data?.attendCount}</span>
        </div>
      </div>
      <div className='content'>
        {renderPoint('start')}
        {renderPoint('end')}
      </div>
    </Root>
  );
};

export default TunnelTotalInfoMini;
