/**
 * 작성자 : 홍선영
 * 날짜 : 2023.01.09
 * 기능 : 터널 관리 > 굴진/일반 정보 > 굴진/일반 정보 팝업 > 근로자/장비 위치정보 탭 (현장용)
 */
/*
 * 수정자 : 김광민
 * 수정 날짜 : 2024.02.27
 * 수정 이유 : 가독성 향상과 유지보수를 위한 코드 분리, 변수 변경 및 삭제, 재사용 코드 컴포넌트화, 스타일 수정, 일부 로직 수정
 */

import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { IUser } from '../atoms';
import { IComCdList } from 'customTypes';
import { LIST_COUNT } from '../_constants';
import { setComCdListState } from '../utils/setComCdListState';
import { BtnBlue } from './Button';
import { apiGet } from '../services/_common';
import { PulseLoader } from 'react-spinners';
import styled from 'styled-components';
import useSetListCount from '../utils/useSetListCount';
import useOnKeydownF9 from '../utils/useOnKeydownF9';
import TareaLocationInfoAreaTabs from './TareaLocationInfoAreaTabs';
import ShortcutButton from './button/ShortcutButton';
import IssueGuide from './IssueGuide';
import TareaLocationInfoWorkerGrid from './TareaLocationInfoWorkerGrid';
import TareaLocationInfoEquipGrid from './TareaLocationInfoEquipGrid';
import SelectBox from './SelectBox';

const Root = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: auto;
  user-select: none;
  padding: 0.5rem;
  @media (min-width: 1024px) {
    padding: 0.75rem 1rem;
  }
  .nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: visible !important;
    flex-grow: 0 !important;
    flex-shrink: 0;
    gap: 0.5rem;
    .filterBar {
      z-index: 10;
      width: 100%;
      position: sticky;
      left: 0;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      gap: 0.5rem;
      touch-action: none;
      flex-grow: 0;
      flex-wrap: wrap;
      .titleArea {
        padding: 0.25rem 0.75rem;
        border-radius: 0.25rem;
        height: 2.5rem;
        flex-grow: 1;
        display: flex;
        gap: 0.25rem;
        align-items: center;
        background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
        font-size: 0.875rem;
        flex-shrink: 0;
        .titleLabel {
          font-weight: 400;
          color: ${({ theme }: { theme: any }) => theme.text_disabled};
          word-break: keep-all;
        }
        .titleName {
          font-weight: 500;
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
          word-break: keep-all;
        }
      }
      .filterButtons {
        display: flex;
        gap: 0.5rem;
      }
    }
    .tabBar {
      display: flex;
      gap: 0.5rem;
      overflow: hidden;
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
  .gridContainer {
    display: flex;
    gap: 0.5rem;
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    flex-grow: 1;
    height: 50%;
    flex-direction: column;
    @media screen and (min-width: 768px) {
      flex-direction: row;
    }
  }
`;

interface ITareaAttendInfo {
  rowState: TunnelType;
  userInfo: IUser;
  onClickPrev: () => void;
  onClickNext: () => void;
  showPrevNextBtn: boolean;
  pointType: 'S' | 'E' | undefined;
  selectedTadCd: string | undefined;
}

// 근로자/장비 위치 정보 탭
export const TareaLocationInfo = ({ rowState, userInfo, onClickPrev, onClickNext, showPrevNextBtn, pointType, selectedTadCd }: ITareaAttendInfo) => {
  const { t } = useTranslation();
  const INIT_TAT_GUBUN = { type: 'tatGubunCd', tatGubunCd: '', cdName: t('미선택') };
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const { listCount, setListCount } = useSetListCount('15'); // 검색줄수설정 훅
  const [listCountComCdList, setListCountComCdList] = useState<IComCdList[]>([]); // 검색줄수 공통코드
  const [tatGubunCd, setTatGubunCd] = useState(INIT_TAT_GUBUN);
  const [tatGubunCdList, setTatGubunCdList] = useState<any[]>([]);
  const [tadCd, setTadCd] = useState<string>('A');
  const [isSearchClicked, setIsSearchClicked] = useState(false);

  // LIST_COUNT 상태 초기화
  useEffect(() => {
    setComCdListState(LIST_COUNT, setListCountComCdList, false);

    // 선택한 구역의 시/종점 사용 유무에 따라 시/종점 선택셀렉트 변경
    const setTatGubun = () => {
      if (!rowState) {
        setTatGubunCdList([]);
        return;
      }

      const s = { type: 'tatGubunCd', tatGubunCd: 'S', cdName: t('시점') };
      const e = { type: 'tatGubunCd', tatGubunCd: 'E', cdName: t('종점') };

      const useStart = rowState.sUseYn === 'Y';
      const useEnd = rowState.eUseYn === 'Y';

      if (useStart && useEnd) {
        setTatGubunCdList([s, e]);
        if (pointType) setTatGubunCd(pointType === 'S' ? s : e);
        else setTatGubunCd(s);
      } else if (useStart) {
        setTatGubunCdList([s]);
        if (!pointType || pointType === 'S') setTatGubunCd(s);
      } else if (useEnd) {
        setTatGubunCdList([e]);
        if (!pointType || pointType === 'E') setTatGubunCd(e);
      } else {
        setTatGubunCdList([]);
      }
    };
    setTatGubun();
  }, [rowState]);

  // F9 키 눌렀을 때 검색 실행
  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  // 선택한 구역 코드 설정
  useEffect(() => {
    if (selectedTadCd) setTadCd(selectedTadCd);
    else setTadCd('A');
  }, [tatGubunCd.tatGubunCd]);

  // 선택한 구역의 세부 정보를 가져오는 쿼리
  const { data, isLoading, isRefetching, refetch } = useQuery(['getTAreaDList', userInfo.hCd, userInfo.sCd, rowState.tatCd, tatGubunCd.tatGubunCd], () => fetchTunnelDetailData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && !!rowState.tatCd,
  });

  const fetchTunnelDetailData = async () => {
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, tatCd: rowState.tatCd, tatGubun: tatGubunCd.tatGubunCd };
      const res = await apiGet({ path: '/tarea/d', req });
      return res.data.data.tareaDList;
    } catch (error) {
      console.error('error', error);
      throw new Error('error /msg');
    }
  };

  const onClickSearch = () => {
    refetch();
  };

  if (isLoading || isRefetching || listCountComCdList.length === 0) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <>
      <Root>
        <div className='nav'>
          <div className='filterBar'>
            <div className='titleArea'>
              <span className='titleLabel'>{t('구역 명')}</span>
              <span className='titleName'>
                {rowState.tatName} ({rowState.tatCd})
              </span>
            </div>
            <div className='filterButtons'>
              <SelectBox
                textLabel='시/종점'
                options={tatGubunCdList}
                defaultOption={t(tatGubunCd.cdName)}
                state={tatGubunCd}
                setState={setTatGubunCd}
                stateKey='tatGubunCd'
                initiateKey={tatGubunCd.tatGubunCd}
                optionHeight='height-md'
              />
              <SelectBox
                textLabel='보기 설정'
                options={listCountComCdList}
                defaultOption={listCount[LIST_COUNT]}
                state={listCount}
                setState={setListCount}
                stateKey={LIST_COUNT}
                initiateKey={listCount[LIST_COUNT]}
                optionHeight='height-sm'
                rsearch
              />
            </div>
            <ShortcutButton onClick={onClickSearch} buttonText='검색' shortcut='F9' />
          </div>
          <div className='tabBar'>
            <TareaLocationInfoAreaTabs options={data} setSelected={setTadCd} selected={tadCd} />
          </div>
        </div>
        <div className='gridContainer'>
          {data?.length === 0 ? (
            <IssueGuide />
          ) : (
            <>
              <TareaLocationInfoWorkerGrid
                userInfo={userInfo}
                tatCd={rowState.tatCd}
                tadCd={tadCd}
                tatGubunCd={tatGubunCd.tatGubunCd}
                perPage={Number(listCount[LIST_COUNT])}
                isSearchClicked={isSearchClicked}
                setIsSearchClicked={setIsSearchClicked}
              />
              <TareaLocationInfoEquipGrid
                userInfo={userInfo}
                tatCd={rowState.tatCd}
                tadCd={tadCd}
                tatGubunCd={tatGubunCd.tatGubunCd}
                perPage={Number(listCount[LIST_COUNT])}
                isSearchClicked={isSearchClicked}
                setIsSearchClicked={setIsSearchClicked}
              />
            </>
          )}
        </div>
      </Root>
      {showPrevNextBtn ? (
        <div className={`modal-footer ${showPrevNextBtn ? 'flex-between' : 'flex-end'}`}>
          <div className='flex-basic arrows'>
            <BtnBlue onClick={onClickPrev}>
              <span className='material-symbols-rounded'>arrow_back_ios_new</span>
              <p>{t('이전')}</p>
              <div>
                <p>Page</p>
                <p>Up</p>
              </div>
            </BtnBlue>
            <BtnBlue onClick={onClickNext}>
              <div>
                <p>Page</p>
                <p>Down</p>
              </div>
              <p>{t('다음')}</p>
              <span className='material-symbols-rounded'>arrow_forward_ios</span>
            </BtnBlue>
          </div>
        </div>
      ) : undefined}
    </>
  );
};
