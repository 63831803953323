/**
 * 작성자 : 한영광
 * 날짜 : 2023.05.24
 * 기능 : 현장사용자관리-사용자정보-사용자정보 페이지
 */

import { useState, useRef, Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import { IModal } from 'customTypes';
import { userState } from '../../../atoms';
import { COMCD_LOCK_YN, COMCD_USE_YN, USER_GRANT } from '../../../_constants';
import { trimObject } from '../../../utils/trimObject';
import { emailRegex } from '../../../utils/checkRegex';
import { checkPhonNumEmpty } from '../../../utils/checkPhonNumEmpty';
import { checkUserInputRequiredValue } from '../../../utils/checkUserInputRequiredValue';
import Portal from '../../../components/Portal';
import DeleteModal from '../../../components/Modal/DeleteModal2';
import UserInfoInputFormSite from '../../../components/Form/UserInfoInputFormSite';
import { logPost } from '../../../services/log';
import { registerTempPwd } from '../../../services/register';
import { apiDelete, apiPatch, apiPost } from '../../../services/_common';

interface Iprops {
  orgUserInfo: any;
  setOrgUserInfo: Dispatch<SetStateAction<any>>;
  applyFilter: (array: any[]) => void;
  initTableState: any[];
  setInitTableState: Dispatch<SetStateAction<any>>;
  getUserSiteAPI: () => void;
  lockYnComCdList: any;
  useYnComCdList: any;
  userGrantComCdList: any;
  siteJoinInfoList: any;
  setSiteJoinInfoList: Dispatch<SetStateAction<any>>;
  setSelectedRowKey: Dispatch<SetStateAction<any>>;
  hpNumState: any;
  telNumState: any;
  ipInfoState: any;
  setHpNumState: Dispatch<SetStateAction<any>>;
  setTelNumState: Dispatch<SetStateAction<any>>;
  setIpInfoState: Dispatch<SetStateAction<any>>;
  useYn: any;
  lockYn: any;
  setUseYn: Dispatch<SetStateAction<any>>;
  setLockYn: Dispatch<SetStateAction<any>>;
  isNewAdd: boolean;
  setIsNewAdd: Dispatch<SetStateAction<boolean>>;
  gCd: any;
  setGCd: Dispatch<SetStateAction<any>>;
  siteJoinInfo: any;
  setSiteJoinInfo: Dispatch<SetStateAction<any>>;
  isSaveClicked: boolean;
  setIsSaveClicked: Dispatch<SetStateAction<boolean>>;
  clearRowData: () => void;
}
const UserInfo = ({
  orgUserInfo,
  setOrgUserInfo,
  applyFilter,
  initTableState,
  setInitTableState,
  getUserSiteAPI,
  lockYnComCdList,
  useYnComCdList,
  userGrantComCdList,
  siteJoinInfoList,
  setSiteJoinInfoList,
  setSelectedRowKey,
  hpNumState,
  telNumState,
  ipInfoState,
  setHpNumState,
  setTelNumState,
  setIpInfoState,
  useYn,
  setUseYn,
  lockYn,
  setLockYn,
  isNewAdd,
  setIsNewAdd,
  gCd,
  setGCd,
  siteJoinInfo,
  setSiteJoinInfo,
  isSaveClicked,
  setIsSaveClicked,
  clearRowData,
}: Iprops) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const userIdRef = useRef<HTMLInputElement>(null);
  const userNameRef = useRef<HTMLInputElement>(null);
  const eMailRef = useRef<HTMLInputElement>(null);
  const userInfoInputFormRef = useRef<any>(null); // 인풋 폼 ref (테이블 로우를 클릭했을 때 바로 inputForm 으로 스크롤 이동시키기 위한 ref)
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });

  // 신규등록 API
  const insertNewUserAPI = async (resultData: any) => {
    const trimData = trimObject(resultData);
    const req = {
      userId: trimData.userId,
      userName: trimData.userName,
      userPwd: trimData.newPassword,
      gCd: trimData.gCd,
      hCd: trimData.hCd,
      sCd: trimData.sCd,
      sjCd: trimData.sjCd,
      hpNum: trimData.hpNum,
      telNum: trimData.telNum,
      eMail: trimData.eMail,
      ipInfo: trimData.ipInfo,
      pwdchangeDate: trimData.pwdchangeDate,
      useYn: trimData.useYn,
      lockYn: trimData.lockYn,
      bigo: trimData.bigo,
      approvalYn: 'Y',
      pwdCheckyn: 'Y',
      loginerrorCount: 0,
      writer: userInfo.userId,
      editor: userInfo.userId,
    };
    const res = await apiPost({ path: '/user/site', contentType: 'application/json', req });
    const { statusCode, message, data } = res.data;
    if (statusCode === 200) {
      const { userList } = data[1];
      const newArray = userList?.map((el: any) => ({
        ...el,
        useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용'),
        lockYnCdName: el.lockYn === 'Y' ? t('잠금') : t('미잠금'),
        approvalYnCdName: el.approvalYn === 'Y' ? t('승인') : t('미승인'),
      }));

      const filterUsers = newArray.filter((el: any) => el.hCd === userInfo.hCd && el.sCd === userInfo.sCd);

      applyFilter(filterUsers);
      toast.success(t(message));
      setSelectedRowKey(0);
      setInitTableState(filterUsers);
      setOrgUserInfo({ ...data[0], isDuplicateChecked: true });
      setIsNewAdd(false);

      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '사용자 정보',
        action: '신규 등록',
        etc: `${resultData.userName}(${resultData.userId})`,
      });
    }
  };

  // 수정 API
  const updateUserAPI = async (resultData: any) => {
    const trimData = trimObject(resultData);
    const req = {
      userId: trimData.userId,
      userName: trimData.userName,
      userPwd: trimData.userPwd,
      gCd: trimData.gCd,
      hCd: trimData.hCd,
      sCd: trimData.sCd,
      sjCd: trimData.sjCd,
      hpNum: trimData.hpNum,
      telNum: trimData.telNum,
      eMail: trimData.eMail,
      ipInfo: trimData.ipInfo,
      pwdchangeDate: trimData.pwdchangeDate,
      useYn: trimData.useYn,
      lockYn: trimData.lockYn,
      bigo: trimData.bigo,
      approvalYn: trimData.approvalYn,
      pwdCheckyn: trimData.pwdCheckyn,
      loginerrorCount: trimData.loginerrorCount,
      editor: userInfo.userId,
    };
    const res = await apiPatch({ path: '/user/site', contentType: 'application/json', req });
    const { statusCode, message, data } = res.data;
    if (statusCode === 200) {
      const { userList } = data[1];
      const newArray = userList?.map((el: any) => ({
        ...el,
        useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용'),
        lockYnCdName: el.lockYn === 'Y' ? t('잠금') : t('미잠금'),
        approvalYnCdName: el.approvalYn === 'Y' ? t('승인') : t('미승인'),
      }));
      const filterUsers = newArray.filter((el: any) => el.hCd === userInfo.hCd && el.sCd === userInfo.sCd);
      applyFilter(filterUsers);
      setInitTableState(filterUsers);
      setOrgUserInfo({ ...data[0], isDuplicateChecked: true });
      toast.success(t(message));

      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '사용자 정보',
        action: '저장',
        etc: `${resultData.userName}(${resultData.userId})`,
      });
    }
  };

  // 승인 API
  const updateUserApprovalAPI = async (req: any) => {
    const res = await apiPatch({ path: '/user/approval', req });
    const { statusCode, message, data } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      const { userPwd, wDate, writer, eDate, editor, approvalYn } = data;
      setOrgUserInfo((prev: any) => ({
        ...prev,
        writer,
        wDate,
        editor,
        eDate,
        approvalYn,
        userPwd,
      }));
      const newUserList = initTableState.map((el: ISiteUser) =>
        el.userId === data.userId
          ? {
              ...data,
              useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용'),
              lockYnCdName: el.lockYn === 'Y' ? t('잠금') : t('미잠금'),
              approvalYnCdName: t('승인'),
            }
          : el
      );
      applyFilter(newUserList);
      setInitTableState(newUserList);
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '사용자 정보',
        action: '승인처리',
        etc: `${data.userName}(${data.userId})`,
      });
    }
  };

  // 저장버튼 클릭해서 save API 호출하여 데이터전송
  const onClickSave = async () => {
    setIsSaveClicked(true);
    const mergedHpNumber = `${hpNumState.num1}-${hpNumState.num2}-${hpNumState.num3}`;
    const mergedTelNumber = telNumState.num1 === '' && telNumState.num2 === '' && telNumState.num3 === '' ? '' : `${telNumState.num1}-${telNumState.num2}-${telNumState.num3}`;
    const mergedIpNumber =
      ipInfoState.num1 === '' && ipInfoState.num2 === '' && ipInfoState.num3 === '' && ipInfoState.num4 === '' ? '' : `${ipInfoState.num1}.${ipInfoState.num2}.${ipInfoState.num3}.${ipInfoState.num4}`;
    const { eMail } = orgUserInfo;
    const { isUserInputRequiredValuePass, userInputRequiredValueMsg, info } = checkUserInputRequiredValue(orgUserInfo);
    const { isEmpty, emptyAlertMessage } = checkPhonNumEmpty(hpNumState);
    const { isEmailRegexPass, emailRegexAlertMsg } = emailRegex(eMail);
    if (gCd[USER_GRANT] === '') return toast.warning(t('권한을 선택하세요'));
    if (gCd[USER_GRANT] === 'ST' && siteJoinInfo.sjCd === '') return toast.warning(t('협력업체명을 선택하세요'));
    if (!isUserInputRequiredValuePass) {
      if (info === 'userId') {
        userIdRef.current?.focus();
      } else if (info === 'userName') {
        userNameRef.current?.focus();
      }
      return toast.warning(t(userInputRequiredValueMsg));
    }
    if (isEmpty) return toast.warning(t(emptyAlertMessage));
    if (!isEmailRegexPass) {
      eMailRef.current?.focus();
      return toast.warning(t(emailRegexAlertMsg));
    }

    const resultData = {
      ...orgUserInfo,
      hpNum: mergedHpNumber,
      telNum: mergedTelNumber,
      ipInfo: mergedIpNumber,
      gCd: gCd[USER_GRANT],
      sCd: userInfo.sCd,
      hCd: userInfo.hCd,
      sjCd: siteJoinInfo.sjCd,
      lockYn: lockYn[COMCD_LOCK_YN],
      useYn: useYn[COMCD_USE_YN],
    };
    if (isNewAdd) return insertNewUserAPI(resultData);
    return updateUserAPI(resultData);
  };

  // 승인 버튼 클릭
  const onClickApproval = async () => {
    const reqData = {
      userId: orgUserInfo.userId,
      editor: userInfo.userId,
      gCd: 'S',
    };
    updateUserApprovalAPI(reqData);
  };

  // 비밀번호 초기화 요청 API
  const initPasswordAPI = async () => {
    const res = await registerTempPwd(orgUserInfo.userId, orgUserInfo.eMail);
    const { statusCode, message } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '사용자 정보',
        action: '비밀번호 초기화',
        etc: `${orgUserInfo.userName}(${orgUserInfo.userId})`,
      });
      getUserSiteAPI();
    }
  };

  // 비밀번호 초기화 버튼 클릭
  const onClickInitPwd = () => {
    setOpenModal((prev) => ({ ...prev, status: true, type: 'initPw', api: initPasswordAPI }));
  };

  const onClickDelete = async () => {
    setOpenModal((prev) => ({ ...prev, status: true, type: 'delete', api: deleteAPI }));
  };

  // 삭제 API
  const deleteAPI = async () => {
    const req = { userId: orgUserInfo.userId, editor: userInfo.userId };
    const res = await apiDelete({ path: '/user/site', req });
    const { message, statusCode, data } = res.data;
    if (statusCode === 200) {
      const { userList } = data[1];
      const newArray = userList?.map((el: any) => ({
        ...el,
        useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용'),
        lockYnCdName: el.lockYn === 'Y' ? t('잠금') : t('미잠금'),
        approvalYnCdName: el.approvalYn === 'Y' ? t('승인') : t('미승인'),
      }));

      const filterUsers = newArray.filter((el: any) => el.hCd === userInfo.hCd && el.sCd === userInfo.sCd);
      toast.success(t(message));
      applyFilter(filterUsers);
      setInitTableState(filterUsers);
      clearRowData();

      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '사용자 정보',
        action: '삭제',
        etc: `${orgUserInfo.userName}(${orgUserInfo.userId})`,
      });
    }
  };

  return (
    <>
      <UserInfoInputFormSite
        style={{ width: '100%' }}
        state={orgUserInfo}
        setState={setOrgUserInfo}
        isNewAdd={isNewAdd}
        userInfoInputFormRef={userInfoInputFormRef}
        onClickSave={onClickSave}
        onClickApproval={onClickApproval}
        onClickDelete={onClickDelete}
        onClickInitPwd={onClickInitPwd}
        telNumState={telNumState}
        setTelNumState={setTelNumState}
        hpNumState={hpNumState}
        setHpNumState={setHpNumState}
        ipInfoState={ipInfoState}
        setIpInfoState={setIpInfoState}
        gCd={gCd}
        setGCd={setGCd}
        lockYn={lockYn}
        setLockYn={setLockYn}
        useYn={useYn}
        setUseYn={setUseYn}
        siteJoinInfo={siteJoinInfo}
        setSiteJoinInfo={setSiteJoinInfo}
        lockYnComCdList={lockYnComCdList}
        useYnComCdList={useYnComCdList}
        userGrantComCdList={userGrantComCdList}
        siteJoinInfoList={siteJoinInfoList}
        setSiteJoinInfoList={setSiteJoinInfoList}
        userIdRef={userIdRef}
        userNameRef={userNameRef}
        eMailRef={eMailRef}
        isSaveClicked={isSaveClicked}
      />
      <Portal openModal={openModal?.status}>{openModal && (openModal.type === 'delete' || openModal.type === 'initPw') && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
    </>
  );
};

export default UserInfo;
