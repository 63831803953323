/**
 * 작성자 : 한영광
 * 날짜 : 2023.04.26
 * 기능 : 마스터메뉴관리 -> 메뉴권한관리 -> 본사용 페이지
 */
import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { IoChevronUpSharp } from 'react-icons/io5';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { FloatingButtonToTop } from '../../../assets/styles/FloatingButtonToTop';
import { InputTable } from '../../../assets/styles/InputTable';
import { userState } from '../../../atoms';
import { BtnBlue } from '../../../components/Button';
import { scrollToNodeTop } from '../../../utils/scrollToNodeTop';
import { useDetectScrolledToBottom } from '../../../utils/useDetectScrolledToBottom';
import { ERROR } from '../../../_constants';
import { logPost } from '../../../services/log';
import { useTranslation } from 'react-i18next';
import { apiGet, apiPost } from '../../../services/_common';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 0.5rem;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;
const Root = styled.div`
  > div:nth-child(1) {
    overflow: auto;
  }
  .table.img {
    position: sticky;
    left: 0;
  }
`;

const CheckBox = styled.div`
  display: flex;
  align-items: center;
  > label {
    font-size: 1.25rem;
    font-weight: initial;
  }
`;

interface ITableObject {
  index: number;
  depth: string;
  mdCd: string;
  mdName: string;
  mtCd: string;
  mtName: string;
  useYn: string;
  wDate: string;
  writer: string;
}

const Head = () => {
  const { t } = useTranslation();
  const tableRef = useRef<HTMLDivElement>(null);
  const userInfo = useRecoilValue(userState);
  const [tableState, setTableState] = useState<ITableObject[]>([]);
  const [headList, setHeadList] = useState<any[]>([]);
  const [allCheckBoxs, setAllCheckBoxs] = useState(false);
  const { isBottom } = useDetectScrolledToBottom(tableRef);

  useEffect(() => {
    getGrantAPI();
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '본사 메뉴 권한 관리',
      action: '조회',
      etc: ``,
    });
  }, []);

  // 메뉴 조회 API
  const getGrantAPI = async () => {
    const res = await apiGet({ path: '/permission/grant', req: { code: 'H' } });
    const { data, message, statusCode } = res.data;
    if (statusCode === 200) {
      setTableState(data.menuGrantList);
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  // 메뉴 권한 저장 API
  const saveAPI = async (reqData: any[]) => {
    const req = { menuGrantReqDto: reqData };
    const res = await apiPost({ path: '/permission/grant', req });
    const { data, message, statusCode } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      setTableState(
        data.menuGrantList.map((el: any, i: number) => {
          return { index: i, ...el };
        })
      );
      setAllCheckBoxs(false);
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '마스터메뉴관리-메뉴권한관리',
        action: '본사메뉴권한 저장',
        etc: ``,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 체크박스 클릭
  const handleCheckboxChange = (i: number, el: ITableObject) => {
    const newTableState = [...tableState];
    if (el.depth === '0') {
      if (newTableState[i].useYn === 'Y') {
        for (const v of newTableState) {
          if (el.mtCd === v.mtCd) {
            v.useYn = 'N';
          }
        }
      } else {
        for (const v of newTableState) {
          if (el.mtCd === v.mtCd) {
            v.useYn = 'Y';
          }
        }
      }
    } else if (el.depth === '1') {
      if (newTableState[i].useYn === 'Y') {
        newTableState[i].useYn = 'N';
        // 사용유무 depth가 1인 로우의 사용유무가 모두 N 이면 depth가 0인 로우 사용유무도 N 으로 처리
        let depth1UseYn = 'N';
        for (const v of newTableState) {
          if (el.mtCd === v.mtCd && v.depth === '1' && v.useYn === 'Y') {
            depth1UseYn = 'Y';
          }
        }
        for (const v of newTableState) {
          if (el.mtCd === v.mtCd && v.depth === '0') {
            v.useYn = depth1UseYn;
          }
        }
      } else {
        newTableState[i].useYn = 'Y';
        for (const v of newTableState) {
          if (el.mtCd === v.mtCd && v.depth === '0') {
            v.useYn = 'Y';
          }
        }
      }
    }
    setTableState(newTableState);
  };

  // 체크박스 클릭
  const handleCheckboxChangeAll = () => {
    setAllCheckBoxs(!allCheckBoxs);
    const newTableState = [...tableState];
    for (const v of newTableState) {
      v.useYn = !allCheckBoxs ? 'Y' : 'N';
    }
  };

  // 저장 버튼 클릭
  const onClickSave = () => {
    const reqArr = tableState
      .filter((v) => v.useYn === 'Y' && v.depth === '1')
      .map((v) => {
        return {
          mgCd: 'H',
          mtCd: v.mtCd,
          mdCd: v.mdCd,
          writer: userInfo.userId,
          // wDate: '',
        };
      });
    if (reqArr.length === 0) {
      toast.warning(`${t('저장할 항목이 없습니다')}\r${t('검색 후 저장하세요')}`);
    } else {
      saveAPI(reqArr);
    }
  };

  const tableRaw = (el: any, i: number) => {
    return (
      <>
        <div className='trCol2p5 flex-center tableStickyNo'>{i + 1}</div>
        <div className='trCol5 flex-center'>{el.depth === '0' ? el.mtCd : null}</div>
        <div className='trCol10 flex-basic'>{el.depth === '0' ? t(el.mtName) : el.mdCd}</div>
        <div className='trCol10 flex-basic content-overflow mdName tableStickyTitle'>{t(el.mdName)}</div>
        <div className='trCol10 flex-basic bigo'>{el.depth === '1' ? el.bigo : null}</div>
        <div className='trCol5 flex-center'>
          <CheckBox>
            <input type='checkbox' checked={el.useYn === 'Y'} onChange={() => handleCheckboxChange(i, el)} />
          </CheckBox>
        </div>
        <div className='trCol10 flex-center'>{el.writer}</div>
        <div className='trCol12 flex-center'>{el.wDate}</div>
      </>
    );
  };

  return (
    <Root>
      <InputTable className='margin-left-05'>
        {isBottom && (
          <FloatingButtonToTop>
            <button type='button' onClick={() => scrollToNodeTop(tableRef)}>
              <IoChevronUpSharp size={20} style={{ stroke: 'white' }} />
            </button>
          </FloatingButtonToTop>
        )}
        <div className='thead'>
          <div className='tr'>
            <div className='trCol2p5 flex-center tableStickyNo'>No</div>
            <div className='trCol5 flex-center'>{t('타이틀 코드')}</div>
            <div className='trCol10 flex-center'>
              {t('타이틀 명')} / {t('메뉴 코드')}
            </div>
            <div className='trCol10 flex-center content-overflow tableStickyTitle'>{t('메뉴 명')}</div>
            <div className='trCol10 flex-center'>{t('비고')}</div>
            <div className='trCol5 flex-center'>
              <CheckBox>
                <input type='checkbox' checked={allCheckBoxs} onChange={() => handleCheckboxChangeAll()} />
              </CheckBox>
              &nbsp;{t('사용유무')}
            </div>
            <div className='trCol10 flex-center'>{t('등록자')}</div>
            <div className='trCol12 flex-center'>{t('등록일자')}</div>
          </div>
        </div>
        <div className='table' ref={tableRef}>
          <div className='tbody'>
            {tableState?.length > 0 ? (
              tableState?.map((el: any, i: number) =>
                el.depth === '0' ? (
                  <div className='tr' key={i} role='button' tabIndex={0} style={{ backgroundColor: '#F5F5F5' }}>
                    {tableRaw(el, i)}
                  </div>
                ) : (
                  <div className='tr' key={i} role='button' tabIndex={0}>
                    {tableRaw(el, i)}
                  </div>
                )
              )
            ) : (
              <div className='noData'>No data.</div>
            )}
          </div>
        </div>
      </InputTable>
      <ButtonsWrapper>
        <BtnBlue
          onClick={() => {
            onClickSave();
          }}
        >
          {t('저장')}
        </BtnBlue>
      </ButtonsWrapper>
      {/* <Portal openModal={openModal?.status}>{openModal && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal> */}
    </Root>
  );
};

export default Head;
