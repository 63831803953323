/**
 * 작성자 : 김광민
 * 날짜 : 2024.02.06
 * 경로 : 터널 모달 > 굴진/일반정보 외 공통 모듈
 */

import styled from 'styled-components';
import TareaMGas from './TareaMGas';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import TareaNormalInfoHeader from './TareaNormalInfoHeader';
import RefreshButton from './button/RefreshButton';
import IssueGuide from './IssueGuide';

const Root = styled.div`
  width: 100%;
  flex-grow: 1;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
  user-select: none;
  @media (min-width: 1024px) {
    padding: 0.75rem 1rem;
  }
  .TareaNormalInfoBody {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .refreshBar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .buttonAndInfoGroup {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .info {
          display: none;
          @media (min-width: 1024px) {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            height: 2.25rem;
            background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
            padding: 0 0.75rem;
            border-radius: 0.25rem;
            font-size: 0.875rem;
            color: ${({ theme }: { theme: any }) => theme.filled_amber_deep};
            .material-symbols-rounded {
              color: ${({ theme }: { theme: any }) => theme.filled_amber};
              font-variation-settings: 'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 24;
              font-size: 1.25rem;
            }
            .dash {
              background-color: ${({ theme }: { theme: any }) => theme.board};
              padding: 0 0.75rem;
              border-radius: 0.25rem;
              box-shadow: 0 4px 12px 0px rgba(0, 0, 0, 0.05);
              /* margin-left: 0.5rem; */
            }
          }
        }
      }
      .legendsGroup {
        display: flex;
        gap: 0.25rem;
        background-color: ${({ theme }: { theme: any }) => theme.board};
        box-shadow: 0 4px 12px 0px rgba(0, 0, 0, 0.05);
        padding: 0 0.375rem;
        border-radius: 0.5rem;
        height: 2.25rem;
        align-items: center;
        .legend {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 3rem;
          height: 1.5rem;
          border-radius: 0.25rem;
          margin-left: 0.125rem;
          font-size: 0.875rem;
          word-break: keep-all;
        }
        .blue {
          color: ${({ theme }: { theme: any }) => theme.filled_blue};
          background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
        }
        .red {
          color: ${({ theme }: { theme: any }) => theme.filled_red};
          background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
        }
        .orange {
          color: ${({ theme }: { theme: any }) => theme.filled_orange};
          background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
        }
        .green {
          color: ${({ theme }: { theme: any }) => theme.filled_green};
          background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
        }
        .amber {
          color: ${({ theme }: { theme: any }) => theme.filled_amber};
          background-color: ${({ theme }: { theme: any }) => theme.tonal_amber};
        }
      }
    }
    .pointInfoContainer {
      overflow-x: hidden;
      overflow-y: auto;
      flex-grow: 1;
      width: 100%;
      .pointInfo {
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
        gap: 0.5rem;
        min-height: 100%;
        max-height: fit-content;
      }
    }
  }
`;

type Props = {
  data: TunnelType;
  sensorList: SensorList[];
  sensorData: SensorData[];
  onClickRefresh: () => void;
};

const TareaMGasInfoContent = ({ data, sensorList, sensorData, onClickRefresh }: Props) => {
  const pointVisibleInitialState = { start: true, end: true };

  const { t } = useTranslation();
  const [isPointVisible, setIsPointVisible] = useState(pointVisibleInitialState);

  const isStartPointUse = data?.sUseYn === 'Y';
  const isEndPointUse = data?.eUseYn === 'Y';

  // 시점, 종점 렌더링 함수
  const renderPoint = () => {
    const renderStartPoint = isStartPointUse && isPointVisible.start;
    const renderEndPoint = isEndPointUse && isPointVisible.end;
    const renderNoData = data.sUseYn === 'N' && data.eUseYn === 'N';

    return (
      <div className='pointInfoContainer'>
        <div className='pointInfo'>
          {/* 시점을 렌더링합니다. */}
          {renderStartPoint && <TareaMGas tatGubun='s' sensorData={sensorData} sensorList={sensorList} />}
          {/* 종점을 렌더링합니다. */}
          {renderEndPoint && <TareaMGas tatGubun='e' sensorData={sensorData} sensorList={sensorList} />}
          {/* 데이터가 없는 경우 메시지를 렌더링합니다. */}
          {renderNoData && <div className='noData'>No data.</div>}
        </div>
      </div>
    );
  };

  // 센서 데이터가 없을 시
  if (sensorData.length === 0) {
    return (
      <Root>
        <IssueGuide text='연결 설정이 되어 있지 않습니다' />
      </Root>
    );
  }

  return (
    <Root>
      <TareaNormalInfoHeader data={data} isPointVisible={isPointVisible} setIsPointVisible={setIsPointVisible} isEndPointUse={isEndPointUse} isStartPointUse={isStartPointUse} />
      <div className='TareaNormalInfoBody'>
        <div className='refreshBar'>
          <div className='buttonAndInfoGroup'>
            <RefreshButton onClickRefresh={onClickRefresh} />
            <div className='info'>
              <span className='material-symbols-rounded'>bolt</span>
              <span className='info-text'>{t(`해당 일자의 데이터가 없을 시 다음과 같이 출력됩니다.`)}</span>
              <span className='dash'>-</span>
            </div>
          </div>
          <div className='legendsGroup'>
            <span className='legend blue'>{t('좋음')}</span>
            <span className='legend green'>{t('주의')}</span>
            <span className='legend amber'>{t('위험')}</span>
            <span className='legend red'>{t('경보')}</span>
          </div>
        </div>
        {renderPoint()}
      </div>
    </Root>
  );
};

export default TareaMGasInfoContent;
