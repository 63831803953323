import ReactDOM from 'react-dom/client';
import { IoPersonAdd } from 'react-icons/io5';
import AvatarIcon from './AvatarIcon';

class ImageRenderer {
  element;

  constructor(props: any) {
    this.element = document.createElement('div');
    const root = ReactDOM.createRoot(this.element);
    const randomString = Math.random().toString(36).substring(7); // 랜덤 문자열을 쿼리스트링으로 추가해서 이미지 캐싱 방지

    const { rowKey } = props;
    const { aImg } = props.grid.store.data.filteredRawData[rowKey];
    if (props.value) {
      root.render(<img src={`${props.value}?v=${randomString}`} width='40px' height='40px' style={{ borderRadius: '5px' }} />);
    } else {
      root.render(<AvatarIcon />);
      // root.render(<IoPersonAdd size={25} />);
    }
  }

  getElement() {
    return this.element;
  }
}
export default ImageRenderer;
