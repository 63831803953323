import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, Filler, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

interface Iprops {
  labels: any[]; // X 값
  datasets: any[]; // 데이터 ex. [{label: '', data: [], borderColor: '', backgoundColor: ''}]
  title: string; // 차트 제목
  showLegend: boolean; // 레전드 표기 여부
}

const LineChart = ({ labels, datasets, title, showLegend }: Iprops) => {
  // 데이터 설정
  const data = {
    labels,
    datasets,
  };

  // 옵션 설정
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    tension: 0.3,

    elements: {
      point: {
        radius: 0,
        pointHoverRadius: 5, // 호버했을때 포인트크기
        hitRadius: 20, // 호버영역크기
      },
    },
    plugins: {
      legend: {
        display: showLegend,
        position: 'bottom' as const,
      },
      title: {
        display: true,
        text: title,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          color: 'rgba(227, 227, 227, 0.2)',
        },
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default LineChart;
