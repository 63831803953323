/**
 * 작성자 : 홍선영
 * 날짜 : 2023.04.10
 * 기능 : 마스터관리-공통코드관리 페이지
 */

import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

import { pageInfoState, userState } from '../../atoms';
import Input from '../../components/Input';
import SelectBoxs from '../../components/SelectBoxs';
import { InputTable } from '../../assets/styles/InputTable';
import { BtnBlue, BtnGhost, BtnRed } from '../../components/Button';
import { FLAG_DELETE, USE_YN, FLAG_CREATE_OR_UPDATE, COMCD_USE_YN, INIT_USE_YN_A } from '../../_constants';
import { arraySortByAscdOrder } from '../../utils/arraySortByAscdOrder';
import { scrollToNodeBottom } from '../../utils/scrollToNodeBottom';
import { limitDigits } from '../../utils/limitDigits';
import { trimArray } from '../../utils/trimArray';
import { applyBorderStyle } from '../../utils/applyBorderStyle';
import { IModal } from 'customTypes';
import Portal from '../../components/Portal';
import DeleteModal from '../../components/Modal/DeleteModal';
import { SearchOptions } from '../../assets/styles/SearchOptions';
import Tab from '../../components/Tab';
import { logPost } from '../../services/log';
import illustrator from '../../assets/images/illustration/31.svg';
import { scrollToNodeTop } from '../../utils/scrollToNodeTop';
import { useTranslation } from 'react-i18next';
import BackButton from '../../components/BackButton';
import SelectBox from '../../components/SelectBox';
import { apiGet, apiPost } from '../../services/_common';
import { ynFilter } from '../../utils/ynFilter';
import { useFetchCommonCodeList } from '../../services/useSetCodeListInSelectBoxForm';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 0.5rem;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;

const Root = styled.div`
  &.hideRoot {
    visibility: hidden;
    position: absolute;
  }
  .emptyData {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    padding-bottom: 4rem;
    img {
      width: 16rem;
      /* user-drag: none; */
      -webkit-user-drag: none;
    }
    span {
      color: ${({ theme }: { theme: any }) => theme.filled_violet};
    }
  }
  .tbody > .tr > div.selector {
    > div {
      width: 100%;
      > div > ul > li {
        width: 100%;
        > span {
          width: 100%;
        }
      }
    }
  }
  > div:nth-child(2) {
    overflow: auto;
  }
  .content-container > div > div:nth-child(2) {
    overflow: auto;
  }
  .textBtnGroup button {
    padding: 0 0.75rem;
    width: 4.5rem;
    height: 2.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
  }
  .formTitle {
    padding: 0 0.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .titleDetail {
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    span {
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      /* font-weight: 600; */
      margin: 0 0.25rem;
    }
  }
  .inputFormsWrapper {
    border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    padding: 0.5rem;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: row;
    .table {
      height: fit-content;
    }
  }
  .formTitle {
    padding: 0 0.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .titleDetail {
    color: ${({ theme }: { theme: any }) => theme.text_seconary};
    span {
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      margin: 0 0.25rem;
    }
  }
`;
interface ITableObject {
  index: number;
  grCd: string;
  subCd: string;
  rootSubCd: string;
  cdName: string;
  useYn: string;
  bigo: string;
  wDate?: string;
  writer?: string;
  eDate?: string;
  editor?: string;
  flag: string;
}
interface ISubTableObject {
  grCd: string;
  rootSubCd: string;
  subCd: string;
  cdName: string;
  useYn: string;
  cdSort: string;
  etc1: string;
  etc2: string;
  etc3: string;
  etc4: string;
  etc5: string;
  bigo: string;
  wDate: string;
  writer: string;
  eDate: string;
  editor: string;
  flag: string;
}

const Master2 = () => {
  const { t } = useTranslation();
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  const userInfo = useRecoilValue(userState);
  const scrollContainerRef = useRef<HTMLInputElement>(null);
  const scrollSubContainerRef = useRef<HTMLInputElement>(null);
  const [orgList, setOriList] = useState<any[]>([]);
  const [rowRootSubCd, setRowRootSubCd] = useState('');
  const [rawData, setRawData] = useState<any[]>([]);
  const [subRawData, setSubRawData] = useState<any[]>([]);
  const [tableState, setTableState] = useState<any[]>([]);
  const [subTableState, setSubTableState] = useState<ISubTableObject[]>([]);
  const [showSubTable, setShowSubTable] = useState({ status: false, subCd: '', cdName: '', rootSubCd: '' });
  const [searchOption, setSearchOption] = useState({ subCd: '', cdName: '', bigo: '' });
  const [searchOptionUseYn, setSearchOptionUseYn] = useState(INIT_USE_YN_A);
  const [newRowIndex, setNewRowIndex] = useState<number>(0); // 신규항목 추가 클릭했을 때 새 로우객체에 인덱스추가
  const [newSubRowIndex, setNewSubRowIndex] = useState<number>(0); // 신규항목 추가 클릭했을 때 새 로우객체에 인덱스추가
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const [isTableInitialRender, setIsTableInitialRender] = useState(true); // 컴포넌트의 이니셜렌더 여부
  const [isSubTableInitialRender, setIsSubTableInitialRender] = useState(true); // 서브테이블 컴포넌트의 이니셜렌더 여부
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장1버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [isSave2Clicked, setIsSave2Clicked] = useState(false); // 저장2버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const { data: useYnComCdListWithAll } = useFetchCommonCodeList(COMCD_USE_YN, true); // 사용여부 공통코드 목록 (검색조건에서 사용, 전체포함)

  useEffect(() => {
    //  로우가 1개 이상이고, 이니셜렌더가 아닌경우 스크롤을 하단으로 이동
    if (tableState.length > 0 && !isTableInitialRender) scrollToNodeBottom(scrollContainerRef);
  }, [tableState.length]);

  useEffect(() => {
    if (subTableState.length > 0 && !isSubTableInitialRender) scrollToNodeBottom(scrollSubContainerRef);
  }, [subTableState.length]);

  // 뷰테이블 값이 수정될때
  const onChangeTableState = (e: React.ChangeEvent<HTMLInputElement>, i: number, el: ITableObject) => {
    const { name, value } = e.currentTarget;
    const viewDataArray = [...tableState]; // 뷰테이블 로우 어레이
    const rawDataArray: any = [...rawData]; // 데이터테이블 로우 어레이

    let findIndex;
    if (el.rootSubCd !== '') {
      // 새로운 로우가 아닐때, 데이터테이블에서 해당하는 로우의 인덱스값 찾기
      findIndex = rawData.findIndex((rawEl: ITableObject) => rawEl.rootSubCd === el.rootSubCd);
    } else {
      // 새로운 로우일때 인덱스값
      findIndex = el.index;
    }

    // 신규로우 수정하는 경우
    viewDataArray[i] = { ...viewDataArray[i], [name]: value, flag: FLAG_CREATE_OR_UPDATE }; // 뷰테이블 로우 업데이트
    if (findIndex !== -1) {
      // 데이터테이블 로우 업데이트
      rawDataArray[findIndex] = { ...rawDataArray[findIndex], [name]: value, flag: FLAG_CREATE_OR_UPDATE, editor: userInfo.userId };
    }

    setTableState(viewDataArray);
    setRawData(rawDataArray);
  };

  // 서브 뷰테이블 값이 수정될때
  const onChangeSubTableState = (e: React.ChangeEvent<HTMLInputElement>, i: number, el: any) => {
    const { name, value } = e.currentTarget;
    let insertValue: string | number = value;

    if (name === 'cdSort') insertValue = limitDigits(value); //  input name이 cdSort인 경우, value값을 Number로 변환
    const viewDataArray: any = [...subTableState]; // 뷰테이블 로우 어레이
    const rawDataArray: any = [...subRawData]; // 데이터테이블 로우 어레이
    let findIndex;

    if (el.rootSubCd !== '') {
      // 새로운 로우가 아닐때, 데이터테이블에서 해당하는 로우의 인덱스값 찾기
      findIndex = subRawData.findIndex((rawEl: ITableObject) => `${rawEl.grCd}${rawEl.rootSubCd}` === `${el.grCd}${el.rootSubCd}`); // 데이터테이블에서 해당하는 로우의 인덱스값
    } else {
      findIndex = el.index; // 새로운 로우일때 인덱스값
    }

    // 그룹코드 외 다른항목을 수정한 경우 또는 신규로우의 그룹코드를 수정하는 경우
    viewDataArray[i] = { ...viewDataArray[i], [name]: insertValue, flag: FLAG_CREATE_OR_UPDATE }; // 뷰테이블 로우 업데이트
    setSubTableState(viewDataArray);

    if (findIndex !== -1) {
      // 데이터테이블 로우 업데이트
      rawDataArray[findIndex] = { ...rawDataArray[findIndex], [name]: insertValue, flag: FLAG_CREATE_OR_UPDATE, editor: userInfo.userId };
    }
    setSubRawData(rawDataArray);
  };

  // 로우 추가 클릭
  const onClickAddTableRow = () => {
    setNewRowIndex((prev) => prev + 1);

    const data = {
      index: newRowIndex,
      grCd: '000',
      rootSubCd: '',
      subCd: '',
      cdName: '',
      useYn: 'Y',
      bigo: '',
      flag: FLAG_CREATE_OR_UPDATE,
      writer: userInfo.userId,
    };

    setTableState((prev: ITableObject[]) => [...prev, data]);
    setRawData((prev: ITableObject[]) => [...prev, data]);
    setIsTableInitialRender(false);
  };

  const backToMain = () => {
    setShowSubTable((prev) => ({ ...prev, status: false }));
  };

  useEffect(() => {
    getCodeMasterList().then((res: any) => {
      if (res.status === 200) {
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '공통코드 관리',
          action: '조회',
          etc: ``,
        });
      }
    });
  }, []);

  const getCodeMasterList = async () => {
    const res = await apiGet({ path: '/code/master' });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      setTableState(data.comCdList);
      setRawData(data.comCdList);
      setOriList(data.comCdList);
      setNewRowIndex(data.comCdList.length);
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  const deleteCodeAPI = async (el: any) => {
    const req = {
      grCd: el.grCd,
      rootSubCd: el.rootSubCd,
      editor: el.editor,
    };
    const res = await apiPost({ path: '/code/delete', req });
    const { statusCode } = res.data;
    if (statusCode === 200) {
      setShowSubTable({ status: false, subCd: '', cdName: '', rootSubCd: '' });
      setOpenModal((prev) => ({ ...prev, status: false }));
      await logPost({ hCd: userInfo.hCd, sCd: userInfo.sCd, userId: userInfo.userId, menu: '마스터관리-공통코드관리', action: '공통코드 삭제', etc: `${el.subCd}(${el.cdName}) 삭제` });
    } else {
      // toast.error(t(ERROR));
    }
  };

  const deletesubCodeAPI = async (el: any) => {
    const req = {
      grCd: el.grCd,
      rootSubCd: el.rootSubCd,
      editor: el.editor,
    };
    const res = await apiPost({ path: '/code/delete', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      setOpenModal((prev) => ({ ...prev, status: false }));
      setSubRawData(data.comCdList);
      await logPost({ hCd: userInfo.hCd, sCd: userInfo.sCd, userId: userInfo.userId, menu: '마스터관리-공통코드관리', action: '공통서브코드 삭제', etc: `${el.subCd}(${el.cdName}) 삭제` });
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 로우 삭제 클릭
  const onClickDelete = (el: ITableObject, i: number) => {
    // 새로 추가한 로우 제거 클릭 시
    if (el.rootSubCd === '') {
      // 뷰테이블 로우 삭제
      const updatedArray = [...tableState];
      updatedArray.splice(i, 1);
      setTableState(updatedArray);

      // 데이터테이블 로우 삭제 (플래그 D로 업데이트)
      const findIndex = rawData.findIndex((rawEl: ITableObject) => rawEl.index === el.index);
      const rawDataArray: any = [...rawData];
      if (findIndex !== -1) {
        rawDataArray[findIndex] = { ...rawDataArray[findIndex], flag: FLAG_DELETE };
        setRawData(rawDataArray);
      }
    } else {
      // 삭제 시 에디터아이디 추가
      const data = { ...el, editor: userInfo.userId };
      setOpenModal((prev) => ({ ...prev, status: true, state: tableState, setState: setTableState, api: deleteCodeAPI, el: data, index: i }));
    }
    setIsTableInitialRender(true); // 삭제 클릭시 테이블길이 변화인한 스크롤하단 이동방지
  };

  // 서브로우 삭제 클릭
  const onClickSubDelete = (el: any, i: number) => {
    // 새로 추가한 로우 제거 클릭 시
    if (el.rootSubCd === '') {
      // 뷰테이블 로우 삭제
      const updatedArray = [...subTableState];
      updatedArray.splice(i, 1);
      setSubTableState(updatedArray);

      // ! 데이터 테이블 로우 삭제 로직 수정
      // ! ---------------------------------
      // 데이터테이블 로우 삭제 (플래그 D로 업데이트)
      // const findIndex = subRawData.findIndex((rawEl: ITableObject) => rawEl.index === el.index);
      // const rawDataArray: any = [...subRawData];
      // if (findIndex !== -1) {
      //   rawDataArray[findIndex] = {
      //     ...rawDataArray[findIndex],
      //     flag: FLAG_DELETE,
      //   };
      //   setSubRawData(rawDataArray);
      // } else {
      //   setSubRawData(subRawData.filter((v: any) => v.index === el.index));
      // }
      setSubRawData(subRawData.filter((v: any, i2: number) => i2 !== el.index));
      // ! ---------------------------------
    } else {
      // 삭제 시 에디터아이디 추가
      const data = {
        ...el,
        editor: userInfo.userId,
      };
      setOpenModal((prev) => ({ ...prev, status: true, state: subTableState, setState: setSubTableState, api: deletesubCodeAPI, el: data, index: i }));
      // const findIndex = subRawData.findIndex((rawEl: ITableObject) => rawEl.index === el.index);
      // setSubRawData(subRawData)
    }
    setIsSubTableInitialRender(true); // 삭제 클릭시 테이블길이 변화인한 스크롤하단 이동방지
  };

  // 코드항목 로우 추가 클릭
  const onClickAddSubTableRow = () => {
    setNewSubRowIndex((prev) => prev + 1);

    const data = {
      index: newSubRowIndex,
      grCd: rowRootSubCd || '',
      rootSubCd: '',
      subCd: '',
      cdName: '',
      useYn: 'Y',
      cdSort: '',
      etc1: ' ',
      etc2: '',
      etc3: '',
      etc4: '',
      etc5: '',
      bigo: '',
      wDate: '',
      writer: userInfo.userId,
      eDate: '',
      editor: '',
      flag: FLAG_CREATE_OR_UPDATE,
    };
    setSubTableState((prev: ISubTableObject[]) => [...prev, data]);
    setSubRawData((prev: ISubTableObject[]) => [...prev, data]);
    setIsSubTableInitialRender(false);
  };

  const getCodeDetailAPI = async (code: string) => {
    const req = { grCd: code };
    const res = await apiGet({ path: '/code/detail', req });
    const { data, message, statusCode } = res.data;
    if (statusCode === 200) {
      setSubTableState(arraySortByAscdOrder(data.comCdList, 'cdSort'));
      setSubRawData(data.comCdList);
      setNewSubRowIndex(data.comCdList.length);
      setShowSubTable((prev) => ({ ...prev, status: true }));
    } else {
      // toast.error(t(ERROR));
    }
  };

  const onClickTableState = (i: number, el: any) => {
    if (el.rootSubCd !== '') {
      setShowSubTable((prev) => ({ ...prev, subCd: el.subCd, cdName: el.cdName, rootSubCd: el.rootSubCd }));
      setRowRootSubCd(el.rootSubCd);
      getCodeDetailAPI(el.rootSubCd);
    } else {
      setRowRootSubCd(el.subCd);
      setShowSubTable((prev) => ({ ...prev, status: false, subCd: el.subCd, cdName: el.cdName }));
    }
    // setIsSubTableScrollTop(true);
    scrollToNodeTop(scrollSubContainerRef);
  };

  const saveComCdArrayAPI = async (comCdArray: any) => {
    const newArr = comCdArray.map((v: any) => {
      return {
        ...v,
        wDate: v.wDate?.replaceAll('-', '').replaceAll(' ', '').replaceAll(':', ''),
        eDate: v.eDate?.replaceAll('-', '').replaceAll(' ', '').replaceAll(':', ''),
      };
    });
    const req = { comCdReqDto: trimArray(newArr) };
    const res = await apiPost({ path: '/code', req });
    const { message, statusCode, data } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      setTableState(data.comCdList);
      setRawData(data.comCdList);
      setOriList(data.comCdList);
      setSearchOption((prev: any) => ({ ...prev, subCd: '', cdName: '', bigo: '' }));

      const findIndex = newArr.findIndex((el: any) => el.rootSubCd === showSubTable.rootSubCd);
      if (findIndex !== -1) {
        setShowSubTable((prev) => ({ ...prev, subCd: newArr[findIndex].subCd, cdName: newArr[findIndex].cdName })); //  저장 성공시 마지막클릭한 로우 기준으로 코드항목테이블 항목명변경
      }
      await logPost({ hCd: userInfo.hCd, sCd: userInfo.sCd, userId: userInfo.userId, menu: '마스터관리-공통코드관리', action: '공통코드 저장&수정', etc: `` });
    } else {
      // toast.error(t(ERROR));
    }
  };

  const saveSubComCdArrayAPI = async (comCdArray: any) => {
    const req = { comCdReqDto: trimArray(comCdArray) };
    const res = await apiPost({ path: '/code', req });
    const { message, statusCode, data } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      setSubTableState(arraySortByAscdOrder(data.comCdList, 'cdSort'));
      setSubRawData(arraySortByAscdOrder(data.comCdList, 'cdSort'));
      setSearchOption((prev: any) => ({ ...prev, subCd: '', cdName: '', bigo: '' }));
      await logPost({ hCd: userInfo.hCd, sCd: userInfo.sCd, userId: userInfo.userId, menu: '마스터관리-공통코드관리', action: '공통서브코드 저장&수정', etc: `` });
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 공통그룹코드 어레이에 빈값이 있는 지 여부
  const isComCdCodeArrayEmpty = () => {
    const newRowRequired = ['subCd', 'cdName'];
    const emptyCheckKeys = ['rootSubCd', 'cdName'];
    const emptyCheckKeys2 = ['rootSubCd', 'subCd', 'cdName'];

    const emptyCheck = rawData.filter((el: any) => {
      if (el.rootSubCd === '') {
        // 새로 추가하는 로우면, rootSubCd 제외한 나머지인풋 빈값 있는 지 확인
        const check = newRowRequired.find((el2: any) => el[el2] === '' && el.flag === FLAG_CREATE_OR_UPDATE);
        return check;
      }
      if (el.flag === FLAG_CREATE_OR_UPDATE && el.rootSubCd !== '' && el.subCd === '') {
        // 기존에 있던 로우이고, 그룹코드값 말고 다른것을 수정한경우 빈값체크
        const check = emptyCheckKeys.find((el2: any) => el[el2] === '' && el.flag === FLAG_CREATE_OR_UPDATE);
        return check;
      }
      if (el.rootSubCd !== '' && el.subCd !== '') {
        // 기존에 있던 로우이고, 그룹코드값을 수정한 경우 빈값체크
        const check = emptyCheckKeys2.find((el2: any) => el[el2] === '' && el.flag === FLAG_CREATE_OR_UPDATE);
        return check;
      }
      const check = emptyCheckKeys2.find((el2: any) => el[el2] === '' && el.flag === FLAG_CREATE_OR_UPDATE);
      return check;
    });

    if (emptyCheck.length > 0) {
      toast.warning(t('필수입력값을 모두 입력하세요'));
    } else {
      // 빈값이 없는 경우 수정,업데이트된 로우들만 서버에 전달
      const dbSendRows = rawData.filter((el: any) => el.flag === FLAG_CREATE_OR_UPDATE);
      const propertyToRemove = 'index'; // 임시로 새 로우에 추가해둔 index값 삭제
      const newArray = dbSendRows.map(({ [propertyToRemove]: _, ...rest }) => rest);
      if (newArray.length === 0) {
        toast.warning(t('변경된 내용이 없습니다'));
      } else {
        saveComCdArrayAPI(newArray);
      }
    }
  };

  const onClickSaveTableState = () => {
    setIsSaveClicked(true);
    isComCdCodeArrayEmpty();
  };

  const onClickSaveSubTableState = () => {
    setIsSave2Clicked(true);
    const newRowRequired = ['subCd', 'cdName', 'cdSort'];
    const subRawSubCd = subRawData.map((v: any) => v.subCd);
    const setData = new Set(subRawSubCd);
    const emptyCheck = subRawData.filter((el: any) => {
      return newRowRequired.find((el2) => el[el2] === '' && el.flag === FLAG_CREATE_OR_UPDATE);
    });
    if (emptyCheck.length > 0) {
      toast.warning(t('필수입력값을 모두 입력하세요'));
    } else if (subRawSubCd.length !== setData.size) {
      toast.warning(t('중복된 코드가 있습니다'));
    } else {
      const dbSendRows = subRawData.filter((el) => el.flag === FLAG_CREATE_OR_UPDATE);
      const propertyToRemove = 'index'; // 임시로 새 로우에 추가해둔 index값 삭제
      const newArray = dbSendRows.map(({ [propertyToRemove]: _, ...rest }) => rest);
      if (newArray.length === 0) {
        toast.warning(t('변경된 내용이 없습니다'));
      } else {
        saveSubComCdArrayAPI(newArray);
      }
    }
  };

  const onClickInitiateSearchOption = () => {
    setSubTableState([]);
    setIsTableInitialRender(true);
    setIsSubTableInitialRender(true);
    scrollToNodeTop(scrollSubContainerRef);
    scrollToNodeTop(scrollContainerRef);
    setSearchOption((prev: any) => ({ ...prev, subCd: '', cdName: '', bigo: '' }));
    setSearchOptionUseYn(INIT_USE_YN_A);
  };

  useEffect(() => {
    // 필터링 기준
    const filterOptions = {
      subCd: searchOption.subCd,
      cdName: searchOption.cdName,
      useYn: searchOptionUseYn[COMCD_USE_YN],
      bigo: searchOption.bigo,
    };

    // 필터링된 어레이 리턴, 대소문자구분X
    const filteredArray = orgList.filter((item) => {
      return (
        item.rootSubCd?.toLowerCase()?.includes(filterOptions.subCd?.toLowerCase()) &&
        item.cdName?.toLowerCase()?.includes(filterOptions.cdName?.toLowerCase()) &&
        item.bigo?.toLowerCase()?.includes(filterOptions.bigo?.toLowerCase())
      );
    });

    const useYnFilter = ynFilter(filteredArray, 'useYn', searchOptionUseYn[COMCD_USE_YN]);
    if (useYnFilter.length > 0) setTableState(useYnFilter);
    else setTableState([]);
  }, [searchOption, searchOptionUseYn[COMCD_USE_YN]]);

  const onClickTab = (mrCd: string) => {
    setCurrentTabMrCd(mrCd);
  };

  return (
    <div className='contents'>
      <Tab tabList={tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      <div className={`content-container ${size.innerSize.W >= 1024 ? 'twoColumn column-55 max800' : 'oneColumn'}`}>
        {/* <Root> */}
        {(!showSubTable.status || size.innerSize.W >= 1024) && (
          <Root>
            <SearchOptions>
              <div className='inputsWrapper'>
                <div className='flex-basic textBtnGroup btn-border'>
                  <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
                </div>
                <div className='inputForm-row'>
                  <div className='inputForm-col withLabelComCf'>
                    <label htmlFor='useYn'>{t('사용유무')}</label>
                    <SelectBox
                      options={useYnComCdListWithAll}
                      defaultOption={searchOptionUseYn.cdName}
                      state={searchOptionUseYn}
                      setState={setSearchOptionUseYn}
                      stateKey={COMCD_USE_YN}
                      initiateKey={searchOptionUseYn[COMCD_USE_YN]}
                      filterbar='filter-1-left'
                    />
                  </div>
                </div>
                <div className='inputForm-col maxWidth-8'>
                  <Input label='' placeholder={t('그룹코드')} type='text' id='subCd' name='subCd' state={searchOption} setState={setSearchOption} />
                </div>
                <div className='inputForm-col maxWidth-8'>
                  <Input label='' placeholder={t('그룹코드 명')} type='text' id='cdName' name='cdName' state={searchOption} setState={setSearchOption} />
                </div>
                <div className='inputForm-col maxWidth-10'>
                  <Input label='' placeholder={t('비고')} type='text' id='bigo' name='bigo' state={searchOption} setState={setSearchOption} maxLength={200} />
                </div>
              </div>
            </SearchOptions>
            <InputTable className='margin-left-05'>
              <div ref={scrollContainerRef}>
                <div className='thead'>
                  <div className='tr'>
                    <div className='trCol10 required flex-center'>{t('그룹코드')}</div>
                    <div className='trCol10 required flex-center tableStickyNo'>{t('그룹코드 명')}</div>
                    <div className='trCol6 flex-center'>{t('사용유무')}</div>
                    <div className='trCol20 flex-center'>{t('비고')}</div>
                    <div className='trCol10 flex-center'>{t('등록일자')}</div>
                    <div className='trCol10 flex-center'>{t('등록자')}</div>
                    <div className='trCol10 flex-center'>{t('수정일자')}</div>
                    <div className='trCol10 flex-center'>{t('수정자')}</div>
                    <div className='trCol4 flex-center'> </div>
                  </div>
                </div>
                <div className='table'>
                  <div className='tbody'>
                    {tableState.map((el: any, i: number) => (
                      <div className='tr' role='button' tabIndex={0} key={i} onClick={() => onClickTableState(i, el)}>
                        <div className='trCol10 flex-center'>
                          <input
                            type='text'
                            id='subCd'
                            name='subCd'
                            value={el.subCd}
                            onClick={(event) => {
                              if (size.innerSize.W < 1024) {
                                event.stopPropagation();
                              }
                            }}
                            onChange={(e) => onChangeTableState(e, i, el)}
                            style={isSaveClicked ? applyBorderStyle(el.subCd, 'red', 'subCd') : undefined}
                            maxLength={50}
                          />
                        </div>
                        <div className='trCol10 flex-center tableStickyNo'>
                          <input
                            type='text'
                            id='cdName'
                            name='cdName'
                            value={el.cdName}
                            onClick={(event) => {
                              if (size.innerSize.W < 1024) {
                                event.stopPropagation();
                              }
                            }}
                            onChange={(e) => onChangeTableState(e, i, el)}
                            style={isSaveClicked ? applyBorderStyle(el.cdName, 'red', 'cdName') : undefined}
                            maxLength={50}
                          />
                        </div>
                        <div className='trCol6 flex-center selector'>
                          <SelectBoxs
                            options={USE_YN}
                            defaultOption={el.useYn === 'Y' ? t('사용') : t('미사용')}
                            state={tableState}
                            setState={setTableState}
                            rawData={rawData}
                            setRawData={setRawData}
                            stateKey='useYn'
                            codeKey='cdName'
                            index={i}
                            object={el}
                            primaryKey='rootSubCd'
                          />
                        </div>
                        <div className='trCol20 flex-center'>
                          <input
                            type='text'
                            id='bigo'
                            name='bigo'
                            value={el.bigo}
                            onClick={(event) => {
                              if (size.innerSize.W < 1024) {
                                event.stopPropagation();
                              }
                            }}
                            onChange={(e) => onChangeTableState(e, i, el)}
                            maxLength={200}
                          />
                        </div>
                        <div className='trCol10 flex-center' role='button' tabIndex={0}>
                          {el.wDate}
                        </div>
                        <div className='trCol10 flex-center' role='button' tabIndex={0}>
                          {el.rootSubCd !== '' ? el.writer : ''}
                        </div>
                        <div className='trCol10 flex-center' role='button' tabIndex={0}>
                          {el.eDate}
                        </div>
                        <div className='trCol10 flex-center' role='button' tabIndex={0}>
                          {el.rootSubCd !== '' ? el.editor : ''}
                        </div>
                        <div
                          className='trCol4 flex-center'
                          onClick={(event) => {
                            if (size.innerSize.W < 1024) {
                              event.stopPropagation();
                            }
                          }}
                          role='presentation'
                        >
                          <BtnRed onClick={() => onClickDelete(el, i)}>{el.rootSubCd !== '' ? t('삭제') : t('제거')}</BtnRed>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </InputTable>
            <ButtonsWrapper>
              <BtnBlue onClick={onClickAddTableRow}>{t('신규항목 추가')}</BtnBlue>
              <BtnBlue onClick={onClickSaveTableState}>{t('저장')}</BtnBlue>
            </ButtonsWrapper>
          </Root>
        )}
        {showSubTable.status ? (
          <Root>
            <div className='inputFormsWrapper'>
              {size.innerSize.W < 1024 && <BackButton func={() => backToMain()} />}
              <div className='formTitle titleDetail'>
                {t('코드')}
                <span>{showSubTable.subCd}</span>• {t('항목 명')} <span> {showSubTable.cdName}</span>
              </div>
            </div>
            <InputTable className='margin-left-05'>
              <div ref={scrollSubContainerRef}>
                <div className='thead'>
                  <div className='tr'>
                    <div className='trCol10 required flex-center'>{t('코드')}</div>
                    <div className='trCol10 required flex-center tableStickyNo'>{t('코드명')}</div>
                    <div className='trCol6 flex-center'>{t('사용유무')}</div>
                    <div className='trCol5 required flex-center'>{t('정렬')}</div>
                    <div className='trCol5 flex-center'>{t('기타')}1</div>
                    <div className='trCol5 flex-center'>{t('기타')}2</div>
                    <div className='trCol5 flex-center'>{t('기타')}3</div>
                    <div className='trCol5 flex-center'>{t('기타')}4</div>
                    <div className='trCol5 flex-center'>{t('기타')}5</div>
                    <div className='trCol30 flex-center'>{t('비고')}</div>
                    <div className='trCol4 flex-center'> </div>
                  </div>
                </div>
                <div className='table'>
                  <div className='tbody'>
                    {showSubTable.status && subTableState?.length > 0 ? (
                      subTableState?.map((el, i) => (
                        <div className='tr' key={`sub${el.grCd}_${i}`}>
                          <div className='trCol10 flex-center'>
                            <input
                              type='text'
                              value={el.subCd}
                              id='subCd'
                              name='subCd'
                              onChange={(e) => onChangeSubTableState(e, i, el)}
                              style={isSave2Clicked ? applyBorderStyle(el.subCd, 'red', 'subCd') : undefined}
                              maxLength={50}
                            />
                          </div>
                          <div className='trCol10 flex-center tableStickyNo'>
                            <input
                              type='text'
                              value={el.cdName}
                              id='cdName'
                              name='cdName'
                              onChange={(e) => onChangeSubTableState(e, i, el)}
                              style={isSave2Clicked ? applyBorderStyle(el.cdName, 'red', 'cdName') : undefined}
                              maxLength={50}
                            />
                          </div>
                          <div className='trCol6 flex-center selector'>
                            <SelectBoxs
                              options={USE_YN}
                              defaultOption={el.useYn === 'Y' ? t('사용') : t('미사용')}
                              state={subTableState}
                              setState={setSubTableState}
                              rawData={subRawData}
                              setRawData={setSubRawData}
                              stateKey='useYn'
                              codeKey='cdName'
                              index={i}
                              object={el}
                              primaryKey='rootSubCd'
                            />
                          </div>
                          <div className='trCol5 flex-center'>
                            <input
                              type='text'
                              value={el.cdSort}
                              id='cdSort'
                              name='cdSort'
                              onChange={(e) => onChangeSubTableState(e, i, el)}
                              style={isSave2Clicked ? applyBorderStyle(el.cdSort, 'red', 'cdSort') : undefined}
                              maxLength={50}
                            />
                          </div>
                          <div className='trCol5 flex-center'>
                            <input type='text' value={el.etc1} id='etc1' name='etc1' onChange={(e) => onChangeSubTableState(e, i, el)} maxLength={50} />
                          </div>
                          <div className='trCol5 flex-center'>
                            <input type='text' value={el.etc2} id='etc2' name='etc2' onChange={(e) => onChangeSubTableState(e, i, el)} maxLength={50} />
                          </div>
                          <div className='trCol5 flex-center'>
                            <input type='text' value={el.etc3} id='etc3' name='etc3' onChange={(e) => onChangeSubTableState(e, i, el)} maxLength={50} />
                          </div>
                          <div className='trCol5 flex-center'>
                            <input type='text' value={el.etc4} id='etc4' name='etc4' onChange={(e) => onChangeSubTableState(e, i, el)} maxLength={50} />
                          </div>
                          <div className='trCol5 flex-center'>
                            <input type='text' value={el.etc5} id='etc5' name='etc5' onChange={(e) => onChangeSubTableState(e, i, el)} maxLength={50} />
                          </div>
                          <div className='trCol30 flex-center'>
                            <input type='text' value={el.bigo} id='bigo' name='bigo' onChange={(e) => onChangeSubTableState(e, i, el)} maxLength={200} />
                          </div>
                          <div className='trCol4 flex-center'>
                            <BtnRed onClick={() => onClickSubDelete(el, i)}>{el.rootSubCd !== '' ? t('삭제') : t('제거')}</BtnRed>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className='noData'>No data.</div>
                    )}
                  </div>
                </div>
              </div>
            </InputTable>
            <ButtonsWrapper>
              <BtnBlue onClick={onClickAddSubTableRow}>{t('신규항목 추가')}</BtnBlue>
              <BtnBlue onClick={onClickSaveSubTableState}>{t('저장')}</BtnBlue>
            </ButtonsWrapper>
          </Root>
        ) : size.innerSize.W >= 768 ? (
          <Root>
            <div className='emptyData'>
              <img src={illustrator} alt='noData' />
              <span>{t('왼쪽 목록에서 코드를 선택해주세요')}</span>
            </div>
          </Root>
        ) : (
          ''
        )}
        <Portal openModal={openModal?.status}>{openModal && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
      </div>
    </div>
  );
};

export default Master2;
