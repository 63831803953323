/**
 * 작성자 : 홍선영
 * 날짜 : 2023.07.12
 * 기능 : textarea 컴포넌트
 */

import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

interface ITextareaStyle {
  display: string;
  flexDirection: string;
}

const TextareaStyle = styled.div<ITextareaStyle>`
  display: ${({ display }) => display};
  flex-direction: ${({ flexDirection }) => flexDirection};
  > label {
    line-height: 20px;
  }
  > textarea {
    all: unset;
    width: inherit;
    min-height: 10rem;
    padding: 0.8rem;
    /* background-color: rgb(243, 246, 249); */
    color: ${({ theme }: { theme: any }) => theme.text_primary};
    background-color: ${({ theme }: { theme: any }) => theme.background};
    border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    border-radius: 6px;
    transition: all 0.2s ease-in-out 0s;
    overflow: auto;
  }
`;

interface InputProps {
  name: string;
  label?: string;
  state: any;
  set: Dispatch<SetStateAction<any>>;
  placeholder?: string;
  blur?: () => void;
  className?: string;
  maxLength?: number;
  inputRef?: React.RefObject<HTMLTextAreaElement>;
  disabled?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  trim?: boolean; // 공백제거 여부 (e.g. 아이디, 비밀번호 등은 공백제거 true)
  display: string;
  flexDirection: 'row' | 'column';
  getBorderStyle?: any; // 선택값이 없을 때 보더 스타일
}

const Textarea = ({ name, label, state, set, placeholder, blur, className, maxLength, inputRef, disabled, onKeyDown, trim, display, flexDirection, getBorderStyle }: InputProps) => {
  const onChangeValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value, name: targetName } = e.target;
    // 최대 길이 제한 처리
    const newValue = maxLength !== undefined ? value.slice(0, maxLength) : value;
    // 스프레드 연산자와 함께 상태 업데이트
    set((prev: any) => ({ ...prev, [targetName]: trim ? newValue.trim() : newValue }));
  };

  return (
    <TextareaStyle display={display} flexDirection={flexDirection}>
      {label && <label className={className}>{label}</label>}
      <textarea
        placeholder={placeholder}
        name={name}
        onChange={onChangeValue}
        onBlur={blur}
        value={state[name]}
        maxLength={maxLength}
        ref={inputRef}
        disabled={disabled}
        onKeyDown={onKeyDown}
        style={getBorderStyle}
      />
    </TextareaStyle>
  );
};

export default Textarea;
