/**
 * 작성자 : 홍선영
 * 날짜 : 2023.06.12
 * 경로 : 설정관리-협력업체관리-협력업체정보탭 (현장관리자)
 */

import { useState, useRef, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { PulseLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { IoChevronUpSharp } from 'react-icons/io5';

import i18n from '../../../translation/i18n';
import { IModal, ITabObject } from 'customTypes';
import { COMCD_USE_YN, INIT_USE_YN_A, INIT_USE_YN_Y } from '../../../_constants';
import { pageInfoState, userState } from '../../../atoms';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { TuiGridTwoColumnStyle } from '../../../assets/styles/TuiGridTwoColumnStyle';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import { BtnGhost } from '../../../components/Button';
import Input from '../../../components/Input';
import Portal from '../../../components/Portal';
import TuiGrid from '../../../components/Table/TuiGrid';
import SelectBox from '../../../components/SelectBox';
import IssueGuide from '../../../components/IssueGuide';
import BackButton from '../../../components/BackButton';
import DeleteModal from '../../../components/Modal/DeleteModal2';
import UserInfoInputFormSiteJoin from '../../../components/Form/UserInfoInputFormSiteJoin';
import { ynFilter } from '../../../utils/ynFilter';
import { trimObject } from '../../../utils/trimObject';
import { scrollToNodeTop } from '../../../utils/scrollToNodeTop';
import { useDetectScrolledToBottom } from '../../../utils/useDetectScrolledToBottom';
import { logPost } from '../../../services/log';
import { useFetchCommonCodeList } from '../../../services/useSetCodeListInSelectBoxForm';
import { apiDelete, apiGet, apiPost } from '../../../services/_common';

interface ISiteJoin {
  sjCd: string;
  sjName: string;
  bigo: string;
  useYn: 'Y' | 'N';
}

const ManageSetting = () => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const userCode = { hCd: userInfo.hCd, sCd: userInfo.sCd, sjCd: '' };
  const [columns, setColumns] = useState<any[]>([]);
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const node = useRef<any>(null);
  const userInfoInputFormRef = useRef<any>(null); // 인풋 폼 ref (테이블 로우를 클릭했을 때 바로 inputForm 으로 스크롤 이동시키기 위한 ref)
  const { isBottom } = useDetectScrolledToBottom(userInfoInputFormRef); // 스크롤이 해당노드의 하단에 근접했는지 여부 (플로팅버튼 띄우기 위함)
  const { data: useYnComCdListWithAll } = useFetchCommonCodeList(COMCD_USE_YN, true); // 사용여부 공통코드 목록 (검색조건에서 사용, 전체포함)
  const { data: useYnComCdList } = useFetchCommonCodeList(COMCD_USE_YN, false); // 사용유무 공통코드 목록
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  const [rowState, setRowState] = useState({
    hCd: '',
    sCd: '',
    sjCd: '',
    sjName: '',
    sjNum: '',
    sjCeoname: '',
    sjTel: '',
    sjChargename: '',
    sjChargetel: '',
    postNum: '',
    address_1: '',
    address_2: '',
    address1: '',
    address2: '',
    bigo: '',
    useYn: '',
    writer: '',
    editor: '',
  }); // 서버에서 받아 온 raw data 어레이 중 1줄의 데이터 state
  const [isNewAdd, setIsNewAdd] = useState(true); // 신규등록 여부
  const [sjTelNumState, setSjTelNumState] = useState({ num1: '', num2: '', num3: '' });
  const [sjChargetelNumState, setSjChargetelNumState] = useState({ num1: '', num2: '', num3: '' });
  const [sjNumber, setSjNumber] = useState({ num1: '', num2: '', num3: '' });
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const [searchOption, setSearchOption] = useState({ sjName: '' });
  const [searchOptionUseYn, setSearchOptionUseYn] = useState(INIT_USE_YN_A);
  const [inputFormUseYn, setInputFormUseYn] = useState(INIT_USE_YN_Y);
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const { userMenuList } = userInfo;
  const [newTabList, setNewTabList] = useState<ITabObject[]>();
  const [selectedRowKey, setSelectedRowKey] = useState<any>(null);
  const [viewTable, setViewTable] = useState<boolean>(true);
  const {
    data: siteJoinListData,
    isLoading,
    isFetching,
    isError,
  } = useQuery(['siteJoin', userInfo.hCd, userInfo.sCd], () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && userInfo.sCd !== '00000',
  });
  const [tableState, setTableState] = useState<ISiteJoin[]>(siteJoinListData?.map((el: ISiteJoin) => ({ ...el, useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용') })) || []);
  const [initTableState, setInitTableState] = useState<ISiteJoin[]>(siteJoinListData?.map((el: ISiteJoin) => ({ ...el, useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용') })) || []);

  const fetchData = async () => {
    try {
      const res = await apiGet({ path: '/siteJoin', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } });
      const result = res.data.data?.siteJoinList;
      const newArray = result?.map((el: ISiteJoin) => ({ ...el, useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용') }));
      setTableState(newArray);
      setInitTableState(newArray);
      return result;
    } catch (error) {
      console.error('error', error);
      throw new Error('error');
    }
  };

  useEffect(() => {
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '협력업체 정보',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    const arr: any[] = [];
    userMenuList.map((v: any) => {
      const result = tabInfo?.tabList?.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  useEffect(() => {
    setSearchOptionUseYn(INIT_USE_YN_A);
    setInputFormUseYn(INIT_USE_YN_Y);

    setColumns([
      {
        header: t('코드'),
        name: 'sjCd',
        align: 'center',
        sortable: true,
        width: 80,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('협력업체명'),
        name: 'sjName',
        sortable: true,
        filter: 'select',
        minWidth: 200,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('비고'),
        name: 'bigo',
        sortable: true,
        minWidth: 100,
      },
      {
        header: t('사용유무'),
        name: 'useYnCdName',
        align: 'center',
        sortable: true,
        minWidth: 120,
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    applyFilter(initTableState);
  }, [searchOption, searchOptionUseYn[COMCD_USE_YN]]);

  const applyFilter = (array: ISiteJoin[]) => {
    // 검색옵션 변경됐을 때 필터링 처리
    // 필터링된 어레이 리턴, 대소문자구분X
    const filteredArray = array.filter((item: ISiteJoin) => item.sjName?.toLowerCase()?.includes(searchOption.sjName?.toLowerCase()));
    const result = ynFilter(filteredArray, 'useYn', searchOptionUseYn[COMCD_USE_YN]);

    if (result.length > 0) setTableState(result.map((el: ISiteJoin, i) => ({ ...el, rowKey: i, sortKey: i })));
    else setTableState([]);
  };

  const getSiteJoinAPI = async (sjCd: string) => {
    const req = { ...userCode, sjCd };
    const res = await apiGet({ path: '/sitejoin/info', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      const { siteJoinInfo } = data;
      setRowState({ ...siteJoinInfo, address1: siteJoinInfo.address_1, address2: siteJoinInfo.address_2 });
      setIsNewAdd(false);

      // 사업자번호 값이 없는 경우 state 초기화
      if (!siteJoinInfo.sjNum || siteJoinInfo.sjNum === '--') setSjNumber({ num1: '', num2: '', num3: '' });

      // 대표전화번호 값이 없는 경우 state 초기화
      if (!siteJoinInfo.sjTel || siteJoinInfo.sjTel === '--') setSjTelNumState({ num1: '', num2: '', num3: '' });

      // 담당자번호 값이 없는 경우 state 초기화
      if (!siteJoinInfo.sjChargetel || siteJoinInfo.sjChargetel === '--') setSjChargetelNumState({ num1: '', num2: '', num3: '' });
    }
  };

  const deleteAPI = async () => {
    const req = { hCd: userCode.hCd, sCd: userCode.sCd, sjCd: rowState.sjCd, editor: userInfo.userId };
    const res = await apiDelete({ path: '/sitejoin/info', contentType: 'application/json', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      const { siteJoinList } = data;
      const newArray = siteJoinList?.map((el: ISiteJoin) => ({ ...el, useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용') }));

      applyFilter(newArray);
      setInitTableState(newArray);
      initiateState();
      setSelectedRowKey(null);
      toast.success(t('협력업체 삭제 성공'));

      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '협력업체 정보',
        action: '삭제',
        etc: `${rowState.sjName}(${rowState.sjCd})`,
      });
    }
  };

  const onClickInitiateSearchOption = () => {
    setSearchOption((prev) => ({ ...prev, sjName: '' }));
    setSearchOptionUseYn(INIT_USE_YN_A);
    onClickNewRegistration(); // 우측 인풋창도 초기화
  };

  // 사용자 Row 클릭
  const onClickRow = (rowData: any) => {
    if (rowData.sjCd !== '') {
      getSiteJoinAPI(rowData.sjCd);
      setSelectedRowKey(rowData.rowKey);
      setInputFormUseYn({ type: COMCD_USE_YN, [COMCD_USE_YN]: rowData.useYn, cdName: rowData.useYnCdName });
    }
    if (size.innerSize.W < 1024) setViewTable(false);
  };

  const onClickTab = (mrCd: string) => {
    setCurrentTabMrCd(mrCd);
  };

  // 인풋폼 초기화
  const initiateState = () => {
    Object.keys(rowState).map((el: any) => {
      // 기본값 제외하고 초기화
      return setRowState((prev: any) => ({
        ...prev,
        [el]: '',
        useYn: 'Y',
        writer: userInfo.userId,
        editor: userInfo.userId,
        address1: '',
        address2: '',
      }));
    });
    setInputFormUseYn(INIT_USE_YN_Y);
    setSjNumber({ num1: '', num2: '', num3: '' });
    setSjTelNumState({ num1: '', num2: '', num3: '' });
    setSjChargetelNumState({ num1: '', num2: '', num3: '' });
    setIsNewAdd(true);
    setIsSaveClicked(false);
  };

  const onClickNewRegistration = () => {
    initiateState();
    setSelectedRowKey(null);
  };

  const onClickSave = async () => {
    setIsSaveClicked(true);

    const sjNum = `${sjNumber.num1 || ''}-${sjNumber.num2 || ''}-${sjNumber.num3 || ''}`;
    const sjTel = `${sjTelNumState.num1 || ''}-${sjTelNumState.num2 || ''}-${sjTelNumState.num3 || ''}`;
    const sjChargetel = `${sjChargetelNumState.num1 || ''}-${sjChargetelNumState.num2 || ''}-${sjChargetelNumState.num3 || ''}`;

    const newData: any = {
      ...rowState,
      hCd: userCode.hCd,
      sCd: userCode.sCd,
      sjNum,
      sjTel,
      sjChargetel,
      address_1: rowState.address1,
      address_2: rowState.address2,
      useYn: inputFormUseYn[COMCD_USE_YN],
      editor: userInfo.userId,
      writer: isNewAdd ? userInfo.userId : undefined,
    };
    const { address1, address2, latitude, longitude, wDate, eDate, useYnCdName, jobtypeIdx, ...resultData } = newData;

    if (rowState.sjName?.trim() === '') return toast.warning(t('협력업체명을 입력하세요'));
    return postAPI(resultData);
  };

  const postAPI = async (reqParam: any) => {
    const req = trimObject(reqParam);
    const res = await apiPost({ path: '/sitejoin/info', contentType: 'application/json', req });
    const { statusCode, data, message } = res.data;
    if (statusCode === 200) {
      const { siteJoinInfo } = data[0];
      const { siteJoinList } = data[1];
      const newArray = siteJoinList?.map((el: ISiteJoin) => ({ ...el, useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용') }));

      applyFilter(newArray);
      setRowState({ ...siteJoinInfo, address1: siteJoinInfo.address_1, address2: siteJoinInfo.address_2 });
      setInitTableState(newArray);
      toast.success(t(message));
      writeLog(siteJoinInfo);

      if (isNewAdd) {
        setSelectedRowKey(tableState.length);
        setIsNewAdd(false);
      }
    }
  };

  const writeLog = async (siteJoinInfo: any) => {
    if (isNewAdd) {
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '협력업체 정보',
        action: '신규등록',
        etc: `${siteJoinInfo.sjName}(${siteJoinInfo.sjCd})`,
      });
    } else {
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '협력업체 정보',
        action: '수정',
        etc: `${siteJoinInfo.sjName}(${siteJoinInfo.sjCd})`,
      });
    }
  };

  const onClickDelete = () => {
    setOpenModal((prev) => ({ ...prev, status: true, type: 'delete', api: deleteAPI }));
  };
  const componentRef = useRef<HTMLDivElement>(null);
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight, componentRef.current?.offsetWidth]);

  const backToMain = () => {
    setSelectedRowKey(null);
    setViewTable(true);
  };

  const addBtnAction = () => {
    onClickNewRegistration();
    if (size.innerSize.W < 1024) {
      setViewTable(false);
    }
  };

  if (isError) return <IssueGuide />;

  if (isLoading || isFetching)
    return (
      <LoadingModalBackground>
        <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
      </LoadingModalBackground>
    );

  return (
    <>
      <TuiGridTwoColumnStyle className={size.innerSize.W >= 1024 || viewTable ? 'showRoot' : 'hideRoot'}>
        <SearchOptions align='left'>
          {isBottom && (
            <div className='floatingBtnWrapper flex-center'>
              <button type='button' onClick={() => scrollToNodeTop(node)}>
                <IoChevronUpSharp size={20} style={{ stroke: 'white' }} />
              </button>
            </div>
          )}
          <div className='inputsWrapper'>
            <div className='inputForm-row'>
              <div className='inputForm-col withLabelComCf'>
                <label htmlFor='useYn'>{t('사용유무')}</label>
                <SelectBox
                  options={useYnComCdListWithAll}
                  defaultOption={searchOptionUseYn.cdName}
                  state={searchOptionUseYn}
                  setState={setSearchOptionUseYn}
                  stateKey={COMCD_USE_YN}
                  initiateKey={searchOptionUseYn[COMCD_USE_YN]}
                  filterbar='filter-1-left'
                />
              </div>
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col'>
                <Input placeholder={t('협력업체명')} label='' type='text' id='sjName' name='sjName' state={searchOption} setState={setSearchOption} />
              </div>
            </div>
          </div>
          <div className='inputsWrapper'>
            <div className='secondSearchOption'>
              <div className='flex-basic textBtnGroup'>
                <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
              </div>
              <div className='flex-basic iconBtnGroup'>
                <BtnGhost onClick={() => addBtnAction()}>
                  <span className='material-symbols-rounded'>add</span>
                  {t('등록')}
                </BtnGhost>
              </div>
            </div>
          </div>
        </SearchOptions>

        <div ref={componentRef} className='tui-container'>
          <TuiGrid data={tableState} columns={columns} perPage={15} onClickRow={onClickRow} rowKey={selectedRowKey} frozenCount={1} height={tuiHeight} />
        </div>
      </TuiGridTwoColumnStyle>
      {(size.innerSize.W >= 1024 || !viewTable) && (
        <TuiGridTwoColumnStyle>
          <div className='inputFormsWrapper flexRowEm'>
            {size.innerSize.W < 1024 && <BackButton func={() => backToMain()} />}
            <div className='formTitle'>{t('협력업체 정보')}</div>
          </div>
          <UserInfoInputFormSiteJoin
            style={{
              width: '100%',
            }}
            state={rowState}
            setState={setRowState}
            isNewAdd={isNewAdd}
            userInfoInputFormRef={userInfoInputFormRef}
            onClickNewAdd={onClickNewRegistration}
            onClickSave={onClickSave}
            onClickDelete={onClickDelete}
            sjNumber={sjNumber}
            setSjNumber={setSjNumber}
            sjTelNumState={sjTelNumState}
            setSjTelNumState={setSjTelNumState}
            sjChargetelNumState={sjChargetelNumState}
            setSjChargetelNumState={setSjChargetelNumState}
            inputFormUseYn={inputFormUseYn}
            setInputFormUseYn={setInputFormUseYn}
            useYnComCdList={useYnComCdList}
            isSaveClicked={isSaveClicked}
          />
        </TuiGridTwoColumnStyle>
      )}
      <Portal openModal={openModal?.status}>{openModal && openModal.type === 'delete' ? <DeleteModal openModal={openModal} setOpenModal={setOpenModal} /> : undefined}</Portal>
    </>
  );
};

export default ManageSetting;
