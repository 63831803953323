/**
 *  2023.05.10 홍선영
 *  수정사항: 공백제거(trim) 필요 여부에 따라 밸류값 트림 따로 처리
 */

import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { allowOneDecimalPointOnly, decimalNumberRegex, quoteRegex } from '../utils/checkRegex';
import { FLAG_CREATE_OR_UPDATE } from '../_constants';

interface Props {
  floatingPlaceholder: boolean | undefined;
  hasContent: boolean;
}

const InputBox = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: ${(props) => (props.floatingPlaceholder ? 'relative' : '')};

  .floatingPlaceholder {
    line-height: 20px;
    position: absolute;
    color: #c6c6c6;
    font-weight: normal;
    pointer-events: none;
    left: 10px;
    top: ${(props) => (!props.hasContent ? '0px' : '-10px')};
    font-size: ${(props) => (!props.hasContent ? '14px' : '12px')};
    color: ${(props) => (!props.hasContent ? '#c6c6c6' : '#328dd2')};
    transition: 300ms ease all;
    padding: 0.5rem 0;
  }

  input {
    height: ${(props) => (props.floatingPlaceholder ? '2.5rem !important' : undefined)};
    padding-top: ${(props) => (props.floatingPlaceholder ? '1rem' : '0')};
  }

  input:focus {
    outline: none;
  }

  input:focus ~ .floatingPlaceholder {
    top: -10px;
    font-size: 12px;
    color: #328dd2;
  }
`;

export const CheckBox = styled.div`
  display: flex;
  align-items: center;
  > label {
    user-select: none;
    /* font-size: 1.25rem; */
    /* font-weight: initial; */
  }
`;

interface InputProps {
  name: string;
  label?: string;
  type: string;
  state: any;
  setState: Dispatch<SetStateAction<any>>;
  placeholder?: string;
  floatingPlaceholder?: boolean;
  id?: string;
  blur?: () => void;
  className?: string;
  maxLength?: number;
  inputRef?: any;
  disabled?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  trim?: boolean; // 공백제거 여부 (e.g. 아이디, 비밀번호 등은 공백제거 true)
  getBorderStyle?: any; // 선택값이 없을 때 보더 스타일
  stateType?: { type: 'array' | 'object'; index: number };
  valueType?: 'decimal' | string;
  useFlag?: boolean;
  onFocus?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
}

const Input = ({
  name,
  label,
  type,
  state,
  setState,
  placeholder,
  floatingPlaceholder,
  id,
  blur,
  className,
  maxLength = 50,
  inputRef,
  disabled,
  onKeyDown,
  trim,
  getBorderStyle,
  stateType,
  valueType,
  useFlag,
  onFocus,
  onChange,
  onClick,
}: InputProps) => {
  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const checkedValue = quoteRegex(value);
    let newValue = checkedValue;

    if (maxLength && checkedValue.length > maxLength) {
      newValue = checkedValue.slice(0, maxLength);
    }

    if (valueType === 'decimal') {
      newValue = decimalNumberRegex(newValue);
      newValue = allowOneDecimalPointOnly(newValue);
    }

    if (valueType === 'localeString') {
      newValue = value?.replace(/[^\d]/g, '');
      newValue = value ? (!isNaN(parseInt(newValue, 10)) ? parseInt(newValue, 10).toLocaleString() : newValue) : '0';
    }

    if (valueType === 'stringNumber') {
      newValue = value?.replace(/[^\d]/g, '');
    }

    if (stateType?.type === 'array') {
      const copyState = [...state];
      copyState[stateType.index][e.target.name] = trim ? newValue.trim() : newValue;
      copyState[stateType.index] = useFlag ? { ...copyState[stateType.index], flag: FLAG_CREATE_OR_UPDATE } : { ...copyState[stateType.index] };
      setState(copyState);
    } else {
      setState((prev: any) => ({ ...prev, [e.target.name]: trim ? newValue?.trim() : newValue }));
    }
  };

  const onChangeCheckedValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState(e.target.checked);
  };

  return (
    <div>
      {type === 'checkbox' ? (
        <CheckBox>
          <input type={type} id={id} placeholder={placeholder} name={name} onChange={onChangeCheckedValue} checked={state} />
          <label htmlFor={id}>{label}</label>
        </CheckBox>
      ) : (
        <InputBox floatingPlaceholder={floatingPlaceholder} hasContent={stateType?.type === 'array' ? state[stateType.index][name]?.length > 0 : state[name]?.length > 0}>
          {!floatingPlaceholder && label && (
            <label htmlFor={`inputName-${name}`} className={className}>
              {label}
            </label>
          )}
          <input
            type={type}
            placeholder={placeholder}
            name={name}
            id={`inputName-${name}`}
            onChange={onChange || onChangeValue}
            onBlur={blur}
            value={stateType?.type === 'array' ? state[stateType.index][name] || '' : state[name] || ''}
            maxLength={maxLength}
            ref={inputRef}
            disabled={disabled}
            onKeyDown={onKeyDown}
            style={getBorderStyle}
            onFocus={onFocus}
            onClick={onClick}
          />
          {floatingPlaceholder && (
            <label htmlFor={`inputName-${name}`} className={className}>
              {label}
            </label>
          )}
        </InputBox>
      )}
    </div>
  );
};

export default Input;
