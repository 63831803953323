/**
 * 작성자 : 홍선영
 * 날짜 : 2024.06.26
 * 기능 : 근로자 출입 현황 대시보드 컴포넌트 (다중현장, 인원만)
 */

import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PulseLoader } from 'react-spinners';
import { useQuery } from '@tanstack/react-query';

import useDashboardTimer from '../../utils/useDashboardTimer';
import { apiGet } from '../../services/_common';
import IssueGuide from '../IssueGuide';
import Slider from 'react-slick';
import { sortArrayByTimeDesc } from '../../utils/arraySortByTimeDescOrder';
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }: { theme: any }) => theme.tonal_deep_blue};
  background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
  height: 100%;
  .header {
    width: 100%;
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.5rem;
    font-size: 3.25rem;
    font-weight: 700;
    gap: 1rem;
    gap: 2rem;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
    .badge {
      font-size: 3.5rem;
      padding: 2rem;
      background-color: ${({ theme }: { theme: any }) => theme.board};
      color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
      border-radius: 2rem;
    }
  }
  .body {
    width: 100%;
    height: 20rem;
    flex-grow: 1;
    padding: 0 1rem;
    margin-bottom: 2rem;
    .slick-slider {
      height: 100%;
      .slick-arrow {
        display: none !important;
      }
      .slick-list {
        height: 100%;
        overflow: visible;
        .slick-track {
          height: 100%;
          .slick-slide > div {
            height: 100%;
          }
        }
      }
    }
    .card-container {
      height: 100%;
      padding: 0 1rem;
      .card {
        height: 100%;
        overflow: hidden;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        box-shadow: 0 1rem 0.5rem 0.5rem rgba(0, 0, 0, 0.05);
        background-color: ${({ theme }: { theme: any }) => theme.board};
        .title {
          height: 7rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 3rem;
          font-weight: 700;
          border-radius: 0.5rem;
          background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
          background-color: ${({ theme }: { theme: any }) => theme.tonal_deep_blue};
          color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
          /* box-shadow: 0 0.25rem 0.5rem 0.25rem rgba(0, 0, 0, 0.25); */
          line-height: 1;
          text-align: center;
          > span {
            font-weight: 700;
          }
        }
        .count {
          flex-grow: 1;
          height: 14.75rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 7rem;
          color: ${({ theme }: { theme: any }) => theme.text_primary};
          font-weight: 700;
        }
      }
    }
  }
  &.narrow {
    .body {
      padding: 0 0.5rem;
      margin-bottom: 1rem;
      .card-container {
        padding: 0 0.5rem;
        .card {
          padding: 0.5rem;
          .title {
            > span {
              font-size: 2.2rem;
              width: 12rem;
              line-height: 1.2;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
`;

interface Iprops {
  name: string;
  userInfo: any;
  refreshTimeCount: number;
  uniqueKey: string;
  slidesToShow: number;
}

export const WorkerAttendCountMultiSiteExit = ({ name, userInfo, refreshTimeCount, uniqueKey, slidesToShow }: Iprops) => {
  const { t } = useTranslation();
  const { timer, setTimer } = useDashboardTimer(refreshTimeCount);
  const tabRefs = useRef<(any | null)[]>([]);
  const [tabList, setTabList] = useState([{ msCd: userInfo.sCd, msName: '당 현장', sliderIndex: 0, count: null }]);

  useEffect(() => {
    if (timer <= 0) {
      refetch();
      refetchMsite();
      setTimer(refreshTimeCount);
    }
  }, [timer]);

  // 다중현장목록 조회
  const {
    data: msite,
    isLoading: isLoadingMsite,
    isError: isErrorMsite,
    isFetched: isFetchedMsite,
    isRefetching: isRefetchingMsite,
    refetch: refetchMsite,
  } = useQuery(['msiteGet', userInfo.hCd, userInfo.sCd], () => fetchMsiteData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
  });

  // 근로자 출입현황 조회
  const { data, isLoading, isError, refetch } = useQuery([`attendMultiexitGet_${uniqueKey}`, userInfo.hCd, userInfo.sCd], () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && !!isFetchedMsite && isRefetchingMsite === false,
  });

  const fetchMsiteData = async () => {
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
      const res = await apiGet({ path: `/site/msite`, req });
      const newArray = res.data.data?.filter((el: any) => el.useYn === 'Y')?.map((el2: any) => ({ msCd: el2.msCd, msName: el2.msName }));

      // 현장코드로 정렬
      newArray.sort((a: any, b: any) => {
        const numA = parseInt(a.msCd.substring(1), 10);
        const numB = parseInt(b.msCd.substring(1), 10);
        return numA - numB;
      });
      const addIndex = newArray.map((el: any, i: number) => ({ ...el, sliderIndex: i + 1 }));
      const addMyMsite = [{ msCd: userInfo.sCd, msName: '당 현장', sliderIndex: 0 }, ...addIndex];
      return addMyMsite;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  const fetchData = async () => {
    try {
      if (isFetchedMsite && !isRefetchingMsite && msite) {
        const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
        const res = await apiGet({ path: `/attend/multiexit`, req });
        const attendList = res.data.data?.attendList || [];
        const totalWorkers = attendList.length;

        // 출역내역을 기반으로 현장별 근로자목록 생성
        const groupedData = groupBy(attendList, 'msCd');

        const result = Object.keys(groupedData).map((msCd, i) => ({
          msCd,
          worker: sortArrayByTimeDesc(groupedData[msCd]),
          sliderIndex: msite.find((el: any) => el.msCd === msCd)?.sliderIndex,
        }));

        // 현장코드로 정렬
        result.sort((a: any, b: any) => {
          const numA = parseInt(a.msCd.substring(1), 10);
          const numB = parseInt(b.msCd.substring(1), 10);
          return numA - numB;
        });

        // msName에 인원추가하여 탭리스트 setState
        const updatedTabList = msite.map((item: any) => {
          const match = result.find((bItem: any) => bItem.msCd === item.msCd);
          if (match) return { ...item, msName: match.worker[0]?.msName || item.msName, count: match.worker.length };
          return { ...item, msName: `${item.msName}`, count: 0 };
        });
        setTabList(updatedTabList);
        return { result, totalWorkers };
      }
      return null;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  const groupBy = (array: any, key: any) => {
    return array.reduce((result: any, currentValue: any) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
      return result;
    }, {});
  };

  const settings = {
    dots: false,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 1000,
    slidesToShow: tabList.length <= slidesToShow ? tabList.length : slidesToShow,
    slidesToScroll: 1,
    autoplay: tabList.length > slidesToShow,
    autoplaySpeed: 5 * 1000,
  };

  if (isError || isErrorMsite) return <IssueGuide />;

  if (isLoading || isLoadingMsite || !settings) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <Root className={slidesToShow !== 5 ? 'narrow' : ''}>
      <div className='header'>
        {t(name)}
        <span className='badge'>{data?.totalWorkers || 0}</span>
      </div>
      {/* <div className='widget-body photoTable'> */}
      <div className='body'>
        <Slider key={tabList?.length} {...settings}>
          {tabList?.map((el: any, i: number) => (
            <div className='card-container' key={el.sliderIndex}>
              <div className='card'>
                <div className='title' ref={tabRefs.current[i]}>
                  <span>{el.msName}</span>
                </div>
                <div className='count'>
                  {el.count}
                  {/* <span className='countText'>명</span> */}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      {/* </div> */}
    </Root>
  );
};
