/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.30
 * 경로 : 터널 모달 > 굴진/일반정보 모듈
 */

import { Dispatch, SetStateAction, useState } from 'react';
import { IUser } from '../atoms';
import { useQuery } from '@tanstack/react-query';
import { apiGet } from '../services/_common';
import { PulseLoader } from 'react-spinners';
import styled from 'styled-components';
import TareaNormalInfoPoint from './TareaNormalInfoPoint';
import TareaNormalInfoHeader from './TareaNormalInfoHeader';
import IssueGuide from './IssueGuide';

const Root = styled.div`
  width: 100%;
  flex-grow: 1;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
  @media (min-width: 1024px) {
    padding: 0.75rem 1rem;
  }
  .pointInfoContainer {
    flex-grow: 1;
    width: 100%;
    .pointInfo {
      overflow-x: auto;
      overflow-y: hidden;
      display: flex;
      gap: 0.5rem;
      min-height: 100%;
      max-height: fit-content;
    }
  }
`;

type Props = {
  data: any;
  setData: Dispatch<SetStateAction<any>>;
  userInfo: IUser;
  isSaveClicked: boolean;
};

// 작업내용 및 장비상태 데이터 가공 함수
const createList = (list: any[], cd: string, name: string) => {
  return list.reduce(
    (acc: any[], el: any) => {
      if (el.useYn === 'Y') {
        acc.push({ type: cd, [cd]: el[cd], cdName: el[name] });
      }
      return acc;
    },
    [{ type: cd, [cd]: '', cdName: '미선택' }]
  );
};

const TareaNormalInfoContent = ({ data, setData, userInfo, isSaveClicked }: Props) => {
  const [tjcList, setTjcList] = useState<any[]>([]);
  const [tesList, setTesList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const pointVisibleState = { start: true, end: true };
  const [isPointVisible, setIsPointVisible] = useState(pointVisibleState);

  const { hCd, sCd } = userInfo;
  const isStartPointUse = data?.sUseYn === 'Y';
  const isEndPointUse = data?.eUseYn === 'Y';

  // 작업내용 코드목록 조회
  const { error: tjcError, isLoading: tjcIsLoading } = useQuery(['tAreaJcListGet', hCd, sCd], () => apiGet({ path: '/tarea/jclist', req: { hCd, sCd } }), {
    enabled: !!hCd && !!sCd,
    onSuccess: (fetchedData) => {
      const { tareaJcList } = fetchedData.data.data;
      const mutateData = createList(tareaJcList, 'tjcCd', 'tjcName');
      setTjcList(mutateData);
      setIsLoading(false);
    },
  });

  // 장비상태 코드목록 조회
  const { error: tesError, isLoading: tesIsLoading } = useQuery(['tAreaEsListGet', hCd, sCd], () => apiGet({ path: '/tarea/eslist', req: { hCd, sCd } }), {
    enabled: !!hCd && !!sCd,
    onSuccess: (fetchedData) => {
      const { tareaEsList } = fetchedData.data.data;
      const mutateData = createList(tareaEsList, 'tesCd', 'tesName');
      setTesList(mutateData);
    },
  });

  // 로딩 중일 때
  if (tjcIsLoading || tesIsLoading || isLoading) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  if (tjcError || tesError) {
    console.error('API 호출 중 오류 발생:', data);
  }

  // 시점, 종점 데이터가 없을 경우
  if (data.sUseYn === 'N' && data.eUseYn === 'N') {
    return <IssueGuide text='시/종점 설정 정보가 없습니다' />;
  }

  // 시점, 종점 렌더링 함수
  const renderPoint = () => {
    return (
      <div className='pointInfoContainer'>
        <div className='pointInfo'>
          {isStartPointUse && isPointVisible.start && <TareaNormalInfoPoint position='s' tjcList={tjcList} tesList={tesList} data={data} setData={setData} isSaveClicked={isSaveClicked} />}
          {isEndPointUse && isPointVisible.end && <TareaNormalInfoPoint position='e' tjcList={tjcList} tesList={tesList} data={data} setData={setData} isSaveClicked={isSaveClicked} />}
        </div>
      </div>
    );
  };

  return (
    <Root>
      <TareaNormalInfoHeader data={data} isPointVisible={isPointVisible} setIsPointVisible={setIsPointVisible} isEndPointUse={isEndPointUse} isStartPointUse={isStartPointUse} />
      {renderPoint()}
    </Root>
  );
};

export default TareaNormalInfoContent;
