/**
 * 작성자 : 홍선영
 * 날짜 : 2023.07.18
 * 기능 : 월출역현황 모달
 */

import { Dispatch, SetStateAction, useState, useRef, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useRecoilValue } from 'recoil';

import { userState } from '../../atoms';
import { Modal, ModalBackground } from '../../assets/styles/Modal';
import Portal from '../Portal';
import AttendListModal from './AttendListModal';
import { pad } from '../../utils/formatDate';
import TuiGrid from '../Table/TuiGrid';
import { useTranslation } from 'react-i18next';
import i18n from '../../translation/i18n';
import { apiGet } from '../../services/_common';

interface IModal {
  status: boolean;
  type: string;
  searchOption: ISearchOption;
}

interface ISearchOption {
  sjName: string;
  sjCd: string;
  aDate: string;
}

interface IProps {
  setOpenModal: Dispatch<SetStateAction<IModal>>;
  filter: ISearchOption;
}

const AttendListMonthlyModal = ({ setOpenModal, filter }: IProps) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const [innerTab, setInnerTab] = useState(0);
  const [tableState, setTableState] = useState<any[]>([]);
  const [orgSiteJoinInfoList, setOrgSiteJoinInfoList] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [openSubModal, setOpenSubModal] = useState<any>({ status: false, type: '', searchOption: { sjName: '', sjCd: '', startDate: '', endDate: '' } });

  useEffect(() => {
    getAttendMonthAPI().then((res: any) => {
      if (res.status === 200) {
        getSjCdAPI();
      }
    });
  }, []);

  useEffect(() => {
    const days = new Date(Number(filter.aDate?.substring(0, 4)), Number(filter.aDate?.substring(4, 6)), 0).getDate();
    const header = [];
    header.push({ header: t('협력업체'), name: 'sjName', filter: 'select', width: 120, renderer: { classNames: ['text_secondary', 'font_semibold'] } });
    for (let i = 0; i < days; i += 1) {
      header.push({
        header: `${i + 1}${t('-일')}`,
        name: `${filter.aDate}${pad(i + 1, 2)}`,
        align: 'center',
        minWidth: 45,
      });
    }
    header.push({ header: t('총합'), name: 'total', align: 'center', width: 100 });
    setColumns(header);

    const summaryArr = [];
    for (let i = 0; i < days; i += 1) {
      summaryArr.push({
        header: `${i + 1}${t('-일')}`,
        name: `${filter.aDate}${pad(i + 1, 2)}`,
        align: 'center',
      });
    }
    const summaryObj: any = {};
    summaryArr.map((v: any) => {
      summaryObj[v.name] = {
        template: (valueMap: any) => {
          return `${valueMap.sum}`;
        },
      };
    });
  }, [tableState, i18n.language]);

  const onClickClose = () => {
    setOpenModal((prev) => ({ ...prev, status: false }));
  };

  // 협력업체 리스트 조회 API
  const getSjCdAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, delYnCheck: 'Y' };
    const res = await apiGet({ path: '/siteJoin', req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      const newList = data.siteJoinList
        .filter((v1: any) => v1.useYn === 'Y')
        .map((v2: any) => {
          return {
            type: 'sjCd',
            sjCd: v2.sjCd,
            name: v2.sjName,
          };
        });
      setOrgSiteJoinInfoList(
        data.siteJoinList.map((v2: any) => {
          return {
            type: 'sjCd',
            sjCd: v2.sjCd,
            name: v2.sjName,
          };
        })
      );
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 월 출역현황 조회 API
  const getAttendMonthAPI = async () => {
    const newSearchOption = { sjCd: filter.sjCd, aMonth: filter.aDate };
    const req = { ...newSearchOption, hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: `/attend/month`, req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      setTableState(
        data.attendList
          .filter((v: any) => (filter.sjCd ? v.sjCd === filter.sjCd : true))
          .map((v: any) => {
            let total = 0;
            Object.entries(v.day).map(([key, val]: any) => {
              total += Number(val);
            });
            return {
              sjName: v.sjName,
              ...v.day,
              total,
            };
          })
      );
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  const componentRef = useRef<HTMLDivElement>(null);
  const componentRef2 = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => (innerTab === 0 ? componentRef.current : componentRef2.current),
  });
  // 인쇄 버튼 클릭
  const onClickPrint = () => {
    handlePrint();
  };

  // Row 클릭
  const onClickRow = (rowData: any, columnName: string) => {
    if (columnName !== 'sjName' && columnName !== 'total' && columnName !== '_number' && rowData && rowData[columnName] !== 0) {
      // const data = { sjName: rowData.sjName, aDate: columnName };
      const sjObject = orgSiteJoinInfoList.find((el) => el.name === rowData.sjName);
      /**
       * 수정자 : 한영광
       * 수정일자 : 2023.10.20
       * 수정내용 : 협력업체가 없는 출역데이터 예외처리 (로컬에서 출역데이터 전달 시 협력업체 없이 전달될 수 있기 때문)
       */
      const data = {
        sjName: rowData.sjName,
        sjCd: sjObject ? sjObject.sjCd : '',
        startDate: `${columnName.substring(0, 4)}-${pad(Number(columnName.substring(4, 6)), 2)}-${pad(Number(columnName.substring(6, 8)), 2)}`,
        endDate: `${columnName.substring(0, 4)}-${pad(Number(columnName.substring(4, 6)), 2)}-${pad(Number(columnName.substring(6, 8)), 2)}`,
      };
      setOpenSubModal({ status: true, type: 'dailyAttendList', searchOption: data });
      // if (sjObject) {
      //   const data = {
      //     sjName: rowData.sjName,
      //     sjCd: sjObject.sjCd,
      //     startDate: `${columnName.substring(0, 4)}-${pad(Number(columnName.substring(4, 6)), 2)}-${pad(Number(columnName.substring(6, 8)), 2)}`,
      //     endDate: `${columnName.substring(0, 4)}-${pad(Number(columnName.substring(4, 6)), 2)}-${pad(Number(columnName.substring(6, 8)), 2)}`,
      //   };
      //   setOpenSubModal({ status: true, type: 'dailyAttendList', searchOption: data });
      //   // navigate('/s_attend1', { state: data });
      // }
    }
  };
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight, componentRef.current?.offsetWidth]);
  return (
    <ModalBackground onClick={onClickClose} role='presentation'>
      <Modal minWidth={80}>
        <div
          className='modal height-fit'
          role='presentation'
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className='inputForm-head'>
            <div className='modalHeaderTitle'>{t('월 출역현황')}</div>
            <div className='closeBtn' onClick={onClickClose} role='presentation'>
              <span className='material-symbols-rounded'>close</span>
            </div>
          </div>
          <div className='inputForm-body monthlyAttend'>
            <div className='flex-basic searchWrapper detail-info'>
              <div className='flex-basic'>
                <label htmlFor='sjCd'>{t('협력업체')}</label> <span className='seperator' />
                <span className='data'>{filter.sjName}</span>
              </div>
              <div className='flex-basic'>
                <label htmlFor='year'>{t('출역연도')}</label> <span className='seperator' />
                <span className='data'>{filter.aDate?.substring(0, 4)}</span>
              </div>
              <div className='flex-basic'>
                <label htmlFor='month'>{t('출역월')}</label> <span className='seperator' />
                <span className='data'>
                  {filter.aDate?.substring(4, 6)}
                  {t('월')}
                </span>
              </div>
            </div>
            <div className='tuiGridWrapper' ref={componentRef}>
              <TuiGrid data={tableState} columns={columns} perPage={5} onClickRow={onClickRow} frozenCount={1} height={tuiHeight} scrollX />
            </div>
          </div>
        </div>
        <Portal openModal={openSubModal?.status}>
          {openSubModal.status && openSubModal.type === 'dailyAttendList' && <AttendListModal setOpenModal={setOpenSubModal} filter={openSubModal.searchOption} />}
        </Portal>
      </Modal>
    </ModalBackground>
  );
};

export default AttendListMonthlyModal;
