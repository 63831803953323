/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.15
 * 경로 : 터널관리, 대시보드 & 상황판 > 터널
 */

import styled from 'styled-components';
import { areaFilter, initialFilter } from '../../services/Tunnel';
import { v1 } from 'uuid';

const TunnelLegendsContainer = styled.div`
  display: flex;
`;

const TunnelLegend = styled.div`
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  margin-right: 0.25rem;
  background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
  padding: 0.125rem 0.5rem;
  .legendName {
    font-size: 0.75rem;
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    word-break: keep-all;
  }
  .legendIcon {
    margin-right: 0.5rem;
    display: flex;
    .material-symbols-rounded {
      font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      font-size: 1rem;
    }
  }
`;

type Props = { filter: { title: string; status: boolean; name: string; icon: string }[] };
const TunnelLegends = ({ filter }: Props) => {
  return (
    <TunnelLegendsContainer>
      {filter.map((el: TunnelFilterData) => (
        <TunnelLegend key={v1()}>
          <div className='legendIcon'>
            <span className='material-symbols-rounded'>{el.icon}</span>
          </div>
          <div className='legendName'>{el.name}</div>
        </TunnelLegend>
      ))}
    </TunnelLegendsContainer>
  );
};

export default TunnelLegends;
