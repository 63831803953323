/**
 * 작성자 : 홍선영
 * 날짜 : 2023.04.17
 * 기능 : 노드 하단으로 스크롤을 이동시키는 함수
 */

export const scrollToNodeBottom = (node: React.RefObject<any>) => {
  /**
   * 수정자 : 김광범
   * 날짜 : 2023.10.30
   * 수정내용 : 위치값 오류로 인한 스크롤 하위 이동 수정
   */

  /**
   * 수정자 : 홍선영
   * 날짜 : 2024.01.17
   * 수정내용 : 2023.10.30 수정분으로 정상동작 하지 않아 기존코드(2023.04.17) 복원 후 해당코드 주석처리
   */

  if (node.current !== null) {
    const scrollContainer = node.current;
    const { scrollHeight } = scrollContainer;

    scrollContainer.scrollTo({
      top: scrollHeight,
      behavior: 'smooth',
    });
  }
  // if (node.current !== null) node.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
};
