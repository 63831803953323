import { useState } from 'react';
import { toast } from 'react-toastify';

import { IModal, ILogin } from 'customTypes';
import { ERROR } from '../../_constants';
import { BtnBlue, BtnWhite } from '../Button';
import { ModalBackground, Modal } from '../../assets/styles/Modal';
import Input from '../Input';
import { registerTempPwd } from '../../services/register';
import { useTranslation } from 'react-i18next';

const PasswordErrorCountModal = ({ openModal, setOpenModal, setState }: ILogin) => {
  const { t } = useTranslation();
  const [signupInfo, setSignupInfo] = useState({
    userId: '',
    isDuplicateChecked: false,
    userName: '',
    hpNum: '',
    telNum: '',
    eMail: '',
    ipInfo: '',
  });

  // 닫기버튼 클릭시 모달창 닫고 state값 초기화
  const onClickClose = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: !openModal?.status }));
    // resetState();
  };

  // 임시비밀번호 이메일전송 API 호출
  const sendTempPasswordAPI = async () => {
    const res = await registerTempPwd(signupInfo.userId, signupInfo.eMail);
    const { message, statusCode } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      setState((prev: any) => ({ ...prev, password: '' }));
      onClickClose();
    } else {
      /**
       * 수정자 : 홍선영
       * 날짜 : 2024.02.08
       * 수정내용 :  statuscode에 해당하는 응답메시지를 사용자에게 알리기 위해 아래 토스트 주석 해제함
       *            e.g. 잘못된 이메일이나 아이디를 입력한 경우 등
       */
      toast.error(t(message));
    }
  };

  // 임시비밀번호 전송버튼 클릭, 인풋빈값 여부확인, 패스워드 에러카운트 0으로 초기화
  const onClickSendTempPassword = () => {
    if (signupInfo.userId === '' || signupInfo.eMail === '') toast.warning(t('입력란을 모두 기입하세요'));
    else {
      setState((prev: any) => ({ ...prev, passwordErrorCount: 0 }));
      sendTempPasswordAPI();
    }
  };

  return (
    <ModalBackground>
      <Modal>
        <div className='modal fitHeight'>
          <div className='inputForm-head'>
            <h5 className='bold'>{openModal?.title}</h5>
          </div>
          <div className='inputForm-body'>
            <div className='modal-contents'>
              <div className='inputFormText'>
                {t('패스워드 불일치 횟수 제한으로 인해 귀하의 메일로 임시 비밀번호가 발송됩니다')}
                <br /> {t('시스템에 등록된 ID와 이메일 주소를 입력하여 주시기 바랍니다.')}
              </div>
              <div className='inputForm-col'>
                <div className='inputForm-col labelRow paddingBottom'>
                  <Input id='userId' name='userId' label={t('사용자 ID')} type='text' state={signupInfo} setState={setSignupInfo} placeholder={t('사용자 ID')} />
                </div>
                <div className='inputForm-col labelRow paddingBottom'>
                  <Input id='eMail' name='eMail' label={t('이메일')} type='text' state={signupInfo} setState={setSignupInfo} placeholder={t('이메일')} />
                </div>
              </div>
            </div>
            <div className='modal-footer flex-end'>
              <BtnBlue onClick={onClickSendTempPassword}>{t('입력 완료')}</BtnBlue>
              <BtnWhite onClick={onClickClose}>{t('닫기')}</BtnWhite>
            </div>
          </div>
        </div>
      </Modal>
    </ModalBackground>
  );
};

export default PasswordErrorCountModal;
