/**
 * 작성자 : 김광민
 * 날짜 : 2024.05.02
 * 기능 : 라디오 버튼 컴포넌트
 */

import styled from 'styled-components';

const Root = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.5rem;
  height: 2.25rem;
  height: 2rem;
  justify-content: center;
  border-radius: 0.25rem;
  color: ${({ theme }: { theme: any }) => theme.text_tertiary};
  cursor: pointer;
  flex-shrink: 0;
  .material-symbols-rounded {
    font-size: 1.375rem;
  }
  .name {
    letter-spacing: normal;
    font-size: 0.875rem;
    width: fit-content;
    word-break: keep-all;
    white-space: nowrap;
  }
  &:hover {
    .name {
      color: ${({ theme }: { theme: any }) => theme.filled_blue};
    }
    &:active {
      color: ${({ theme }: { theme: any }) => theme.filled_blue};
      .name {
        color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
      }
    }
  }
`;

type Props = {
  name: string;
  isSelected?: boolean;
  onClick?: () => void;
};

const RadioContainer = ({ name, onClick, isSelected = false }: Props) => {
  return (
    <Root role='button' onClick={onClick}>
      <span className='material-symbols-rounded'>{isSelected ? 'radio_button_checked' : 'radio_button_unchecked'}</span>
      <span className='name'>{name}</span>
    </Root>
  );
};

export default RadioContainer;
