/* eslint-disable */

import { useTranslation } from 'react-i18next';
import { Modal, ModalBackground } from '../../../assets/styles/Modal';
import { Dispatch, SetStateAction } from 'react';
import { IModal } from 'customTypes';
import DOMPurify from 'dompurify';

interface IProps {
  setOpenModal: Dispatch<SetStateAction<IModal>>;
  htmlSource: string | null;
}

const HtmlPreviewModal = ({ setOpenModal, htmlSource }: IProps) => {
  const { t } = useTranslation();
  const onClickClose = () => {
    setOpenModal((prev) => ({ ...prev, status: false }));
  };

  return (
    <ModalBackground onClick={onClickClose} role='presentation'>
      <Modal>
        <div
          className='modal raw-html'
          role='presentation'
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className='inputForm-head'>
            <div className='modalHeaderTitle'>{t('에디터 미리보기')}</div>
            <div className='closeBtn' onClick={onClickClose} role='presentation'>
              <span className='material-symbols-rounded'>close</span>
            </div>
          </div>
          <div className='inputForm-body'>{htmlSource !== null && <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlSource) }} />}</div>
        </div>
      </Modal>
    </ModalBackground>
  );
};

export default HtmlPreviewModal;
