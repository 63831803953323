/**
 * 작성자 : 김광민
 * 날짜 : 2024.02.26
 * 기능 : 터널 관리 > 굴진/일반 정보 > 굴진/일반 정보 팝업 > 근로자/장비 위치정보 탭 (현장용) >
 */

import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type Props = {
  options: any[];
  setSelected: Dispatch<SetStateAction<string>>;
  selected: string;
};

const Root = styled.div`
  flex-grow: 0;
  overflow: auto;
  .scrollbar-container {
    position: relative;
    width: fit-content;
    padding: 0 0.25rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    border-radius: 0.25rem;
    height: 2.5rem;
    border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    display: flex;
    align-items: center;
    gap: 0.25rem;
    button {
      font-weight: 500;
      width: fit-content;
      height: 2rem !important;
      padding: 0 0.5rem !important;
      background-color: ${({ theme }: { theme: any }) => theme.board} !important;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      font-size: 0.875rem;
      border: none;

      padding: 0 0.75rem;
      border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      &:hover {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_light} !important;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
      }
      &:active {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_deep} !important;
      }
      &.selected {
        font-weight: 400;
        background-color: ${({ theme }: { theme: any }) => theme.text_tertiary} !important;
        background-color: ${({ theme }: { theme: any }) => theme.filled_blue} !important;
        color: ${({ theme }: { theme: any }) => theme.board};
      }
    }
  }
`;

const TareaLocationInfoAreaTabs = ({ options, setSelected, selected }: Props) => {
  const { t } = useTranslation();

  if (!options || options.length === 0) return null;
  return (
    <Root>
      <div className='scrollbar-container'>
        {options.length > 0 ? (
          <button type='button' className={`button ${selected === 'A' ? 'selected' : ''}`} onClick={() => setSelected('A')} tabIndex={0}>
            {t('전체')}
          </button>
        ) : undefined}
        {options.map((el: any) => (
          <button type='button' className={`button ${selected === el.tadCd ? 'selected' : ''}`} key={el.tadCd} onClick={() => setSelected(el.tadCd)} tabIndex={0}>
            {el.tadName}
          </button>
        ))}
      </div>
    </Root>
  );
};

export default TareaLocationInfoAreaTabs;
