/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.15
 * 경로 : 대시보드 > 터널 구역별 종합 진행 현황
 */

import { useTranslation } from 'react-i18next';
import { PulseLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import { areaFilter, useFetchTareaData } from '../../services/Tunnel';
import TunnelLegends from '../tunnel/TunnelLegends';
import SingleTunnelAreaInfo from '../tunnel/SingleTunnelAreaInfo';
import { useRecoilValue } from 'recoil';
import { tInfoUpdateState } from '../../atoms';

type Props = {
  name: string;
  userInfo: any;
  tatCd: string;
  uniqueKey: string;
  visibleRefreshBtn: boolean;
};

const TunnelAreaInfoWithTatCd = ({ name, userInfo, tatCd, uniqueKey, visibleRefreshBtn }: Props) => {
  const { t } = useTranslation();
  const [tunnelList, setTunnelList] = useState<any[]>([]);
  const [autoPlay, setAutoPlay] = useState(true);
  const isTunnelInfoUpdated = useRecoilValue(tInfoUpdateState);
  const { data, isError, isLoading, isRefetching } = useFetchTareaData({ uniqueKey, dependencies: [isTunnelInfoUpdated], autoRefetch: !visibleRefreshBtn });

  useEffect(() => {
    if (data && data.length > 0) {
      setTunnelList(data);
    }
  }, [data]);

  // autoPlay가 false일 때 1.5초 간격으로 autoPlay 상태 업데이트
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (autoPlay === false) {
      intervalId = setInterval(() => setAutoPlay(true), 1500);
    }
    return () => clearInterval(intervalId);
  }, [autoPlay]);

  // autoPlay 상태 업데이트 함수
  const updateAutoPlay = () => {
    setAutoPlay(false);
  };

  if (isError) {
    console.error('API 호출 중 오류 발생:', data);
  }

  return (
    <div className='mGas tempEdit'>
      <div className='widget-header margin-top'>
        <div className='widget-title flex-between innerBtn'>
          <span>{t('터널 구역별 종합 진행 현황')}</span>
          <div className='labelSet flex-end'>
            <TunnelLegends filter={areaFilter} />
          </div>
        </div>
      </div>
      {isLoading || isRefetching ? (
        <div className='loader-background '>
          <PulseLoader color='rgb(0, 122, 255)' size='10px' />
        </div>
      ) : (
        <SingleTunnelAreaInfo tunnelList={tunnelList} setTunnelList={setTunnelList} selectedTatCd={tatCd} filter={areaFilter} autoPlay={autoPlay} updateSelectedTatCd={updateAutoPlay} siteDashboard />
      )}
    </div>
  );
};

export default TunnelAreaInfoWithTatCd;
