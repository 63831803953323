import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PulseLoader } from 'react-spinners';
import DoughnutChart from '../DoughnutChart';
import { formatDateYMD } from '../../utils/formatDate';
import { apiGet } from '../../services/_common';

interface IAccidentInfo {
  accidentSdate: string;
  accidentEdate: string;
  accidentTday: string;
  accidentAday: string;
  accidentRday: string;
}
// 무사고 현황
export const AccidentInfo = ({ name, userInfo }: any) => {
  const { t } = useTranslation();
  const [accidentInfo, setAccidentInfo] = useState<IAccidentInfo>();

  const accidentInfoQuery = useQuery(['safeAccidentmStatusGet', userInfo], () => apiGet({ path: '/safe/accidentm/status', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }));

  useEffect(() => {
    if (accidentInfoQuery.isSuccess && accidentInfoQuery.data && accidentInfoQuery.data.data.statusCode === 200) {
      const { accidentAday, accidentEdate, accidentRday, accidentSdate, accidentTday } = accidentInfoQuery.data.data.data;
      setAccidentInfo({ accidentAday, accidentEdate, accidentRday, accidentSdate, accidentTday });
    }
  }, [accidentInfoQuery.isSuccess, accidentInfoQuery.isRefetching]);

  if (!accidentInfoQuery.isSuccess || accidentInfoQuery.data.status !== 200 || accidentInfoQuery.isRefetching) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  const onClickRefresh = () => {
    accidentInfoQuery.refetch();
  };

  if (!accidentInfoQuery.isSuccess || accidentInfoQuery.data.status !== 200 || accidentInfoQuery.isRefetching || !accidentInfo) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <div className='accidentInfo'>
      <div className='widget-header'>
        <div className='widget-title flex-between'>
          <span>{t(name)}</span>
          <div className='refresh-btn' onClick={onClickRefresh} role='presentation'>
            <span className='material-symbols-rounded'>refresh</span>
          </div>
        </div>
      </div>
      <div className='widget-body donut'>
        <div className='doughnutChartWrapper'>
          {accidentInfo && (
            <DoughnutChart
              showLegend={false}
              percentage={[Number(accidentInfo?.accidentAday), Number(accidentInfo?.accidentRday)]}
              label={[t('달성 일 수'), t('남은 일 수')]}
              centerText={false}
              colorSet={['#3E97FF', '#3e98ff30']}
              borderStatus={false}
            />
          )}
        </div>
        <div className='content'>
          <dl className='flex-between'>
            <dd>
              {accidentInfo && formatDateYMD(accidentInfo?.accidentSdate)} ~ {accidentInfo && formatDateYMD(accidentInfo?.accidentEdate)}
            </dd>
            <dd className='flex-basic'>
              <span>{accidentInfo?.accidentTday}</span>
              {t('-일')}
            </dd>
          </dl>
          <dl className='flex-between'>
            <dt className='flex-basic'>
              <div className='fill' />
              {t('달성 일 수')}
            </dt>
            <dd className='flex-basic'>
              <span>{accidentInfo?.accidentAday}</span>
              {t('-일')}
            </dd>
          </dl>
          <dl className='flex-between'>
            <dt className='flex-basic'>
              <div className='empty' />
              {t('남은 일 수')}
            </dt>
            <dd className='flex-basic'>
              <span>{accidentInfo?.accidentRday}</span>
              {t('-일')}
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
};
