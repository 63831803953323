/**
 * 작성자 : 홍선영
 * 날짜 : 2024.03.22
 * 기능 : 비디오 재생여부에 따라 재생버튼이 노출/미노출 되는 아이콘
 */

import styled from 'styled-components';
import lens from '../../assets/images/icons/lens.png';

const CameraIconStyle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  cursor: default;
  user-select: none;
  border-radius: 50%;
  &.clickable:hover {
    cursor: pointer;
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
    outline: 3px solid ${({ theme }: { theme: any }) => theme.filled_blue};
    &:active {
      background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
      outline: 3px solid ${({ theme }: { theme: any }) => theme.tonal_blue};
    }
  }
  .material-symbols-rounded {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};

    background-color: ${({ theme }: { theme: any }) => theme.board};
    border-radius: 50%;
    width: 1.125rem;
    height: 1.125rem;
    font-size: 1.125rem;
    left: auto;
    top: auto;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(15%, 15%);
    box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.4);
  }
  /* .pauseIcon, */
  .playIcon {
    color: ${({ theme }: { theme: any }) => theme.filled_green};
  }
  .pauseIcon {
    color: ${({ theme }: { theme: any }) => theme.filled_slate_deep};
    font-size: 1rem;
  }
  .failIcon {
    color: ${({ theme }: { theme: any }) => theme.filled_red};
    font-variation-settings: 'wght' 700;
  }
  img {
    width: 24px;
    height: 24px;
  }
`;

interface Props {
  isFail: boolean;
  isPlaying: boolean;
  isPausing: boolean;
  onClick: () => void;
  type?: 'realtime' | string;
}

const CameraIcon = ({ isFail, isPlaying, isPausing, onClick, type }: Props) => {
  const clickable = type === 'realtime' && !isFail;
  return (
    <CameraIconStyle //
      className={clickable ? 'clickable' : ''}
      onClick={() => (clickable ? onClick() : {})}
    >
      <div
        className='lense' //
      >
        <img src={lens} />
      </div>
      {isFail && <span className='material-symbols-rounded failIcon'>close</span>}
      {isPlaying && <span className='material-symbols-rounded playIcon'>play_arrow</span>}
      {isPausing && <span className='material-symbols-rounded pauseIcon'>pause</span>}
    </CameraIconStyle>
  );
};

export default CameraIcon;
