/**
 * 작성자 : 한영광
 * 날짜 : 2023.07.17
 * 경로 : 설정관리 - 로그이력
 */

import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { LIST_COUNT } from '../_constants';
import { IComCdList, ITabObject } from 'customTypes';
import { IUser, pageInfoState, userState } from '../atoms';
import { SearchOptions } from '../assets/styles/SearchOptions';
import RangePicker, { onChangeRangeInput } from '../components/RangePicker';
import { BtnGhost, BtnGray } from '../components/Button';
import Tab from '../components/Tab';
import Input from '../components/Input';
import { todayYYYYMMDD } from '../utils/formatDate';
import { setComCdListState } from '../utils/setComCdListState';
import { logGet } from '../services/log';
import { useSetAuth } from '../utils/useSetAuth';
import TuiGrid from '../components/Table/TuiGrid';
import SelectBox from '../components/SelectBox';
import { useTranslation } from 'react-i18next';
import i18n from '../translation/i18n';
import TuiGridWrapper from '../components/Table/TuiGridWrapper';
import { useGetFrozenCount } from '../utils/getFrozenCount';
import useOnKeydownF9 from '../utils/useOnKeydownF9';
import { ContentsContainerRoot } from '../assets/styles/ContentsContainerRoot';
import { LoadingModalBackground } from '../assets/styles/Modal';
import { PulseLoader } from 'react-spinners';
import useSetListCount from '../utils/useSetListCount';
import ShortcutButton from '../components/button/ShortcutButton';

const Root = styled(ContentsContainerRoot)`
  .tui-container {
    flex-grow: 1;
    overflow: hidden;
    margin-left: 0.5rem;
  }
`;

const LogReport = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const { listCount, setListCount, patchUserMenuAPI } = useSetListCount(); // 검색줄수설정 훅
  const tabInfo = useRecoilValue(pageInfoState);
  const toDay = todayYYYYMMDD();
  const userInfo = useRecoilValue<IUser>(userState);
  const { userMenuList } = userInfo;
  const [tableState, setTableState] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const size = useOutletContext<any>();
  const userNameRef = useRef<HTMLInputElement>(null);
  const { mtCd, defaultMrCd } = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(defaultMrCd);
  const [visibleRangePicker, setVisibleRangePicker] = useState(false);
  const [rangeState, setRangeState] = useState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
  const [listCountComCdList, setListCountComCdList] = useState<IComCdList[]>([]); // 검색줄수 공통코드
  const [searchOption, setSearchOption] = useState({ start: toDay, end: toDay, userName: '' });
  const [excelBtn, setExcelBtn] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);
  const [newTabList, setNewTabList] = useState<ITabObject[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const arr: any[] = [];
    userMenuList?.map((v: any) => {
      const result = tabInfo?.tabList?.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    setComCdListState(LIST_COUNT, setListCountComCdList, false);
    setColumns([
      {
        header: t('화면 메뉴명'),
        name: 'menu',
        sortable: true,
        filter: 'select',
        width: 240,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('동작 내용'),
        name: 'action',
        sortable: true,
        align: 'center',
        minWidth: 80,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('기타'),
        name: 'etc',
        sortable: true,
        minWidth: 120,
      },
      {
        header: t('접속 IP'),
        name: 'ip',
        align: 'center',
        sortable: true,
        minWidth: 140,
      },
      {
        header: t('사용자 ID'),
        name: 'userId',
        sortable: true,
        minWidth: 120,
      },
      {
        header: t('사용자명'),
        name: 'userName',
        align: 'center',
        sortable: true,
        filter: 'select',
        minWidth: 120,
      },
      {
        header: t('로그 저장 일자 / 시간'),
        name: 'logDate',
        align: 'center',
        sortable: true,
        minWidth: 160,
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    setTableState([]);
    onClickInitiateSearchOption();
  }, [location.pathname]);

  useEffect(() => {
    if (!rangeState[0].startDate) {
      return;
    }
    setSearchOption((prev: any) => ({ ...prev, start: dayjs(rangeState[0].startDate).format('YYYY-MM-DD') }));
  }, [rangeState[0].startDate]);

  useEffect(() => {
    if (!rangeState[0].endDate) {
      return;
    }
    setSearchOption((prev: any) => ({ ...prev, end: dayjs(rangeState[0].endDate).format('YYYY-MM-DD') }));
  }, [rangeState[0].endDate]);

  // 로그 리스트 조회 API
  const getLogAPI = async () => {
    const newSearchOption = { ...searchOption, logDate1: searchOption.start.replaceAll('-', ''), logDate2: searchOption.end.replaceAll('-', '') };
    const { start, end, ...finalSearchObj } = newSearchOption; // searchOption에서 start, end값 삭제
    let res;
    if (mtCd[0] === 'M') {
      res = await logGet({ hCd: 'M000', sCd: '00000', ...finalSearchObj });
    } else {
      res = await logGet({ hCd: userInfo.hCd, sCd: userInfo.sCd, ...finalSearchObj });
    }
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      setLoading(false);
      setTableState(data);
    } else {
      setLoading(false);
    }
  };

  // 검색 버튼 클릭
  const onClickSearch = () => {
    if (!searchOption.start && !searchOption.end) {
      toast.warning(t('날짜를 입력하세요'));
    } else if (searchOption.end && searchOption.start > searchOption.end) {
      toast.warning(t('날짜를 다시 입력하세요'));
      setSearchOption((prev: any) => ({ ...prev, end: '' }));
    } else {
      setLoading(true);
      setVisibleRangePicker(false);
      getLogAPI();
    }
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    Object.keys(searchOption).map((el: any) => {
      return setSearchOption((prev: any) => ({ ...prev, [el]: '', start: toDay, end: toDay }));
    });
    setRangeState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
  };

  // 엑셀 저장 버튼 클릭
  const onClickExcelSave = () => {
    if (tableState.length <= 0) return;
    setExcelBtn(true);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  // 인쇄 버튼 클릭
  const onClickPrint = () => {
    handlePrint();
  };

  // Row 클릭
  const onClickRow = (rowData: any) => {
    // setOpenModal({ status: true, type: 'safeEdu', code: { sSeq: rowData.sSeq } });
  };

  const renderExcelBtn = () => {
    if (auth.excelAuth) {
      return (
        <BtnGhost onClick={onClickExcelSave}>
          <span className='icon-ms-xlsx'>X</span> {t('엑셀')}
        </BtnGhost>
      );
    }
    return null;
  };

  const renderPrintBtn = () => {
    if (auth.printAuth) {
      return <BtnGray onClick={onClickPrint}>{t('인쇄')}</BtnGray>;
    }
    return null;
  };

  // 사용자명 입력창에서 엔터입력시 검색
  const onEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (userNameRef.current) onClickSearch();
    }
  };

  const onClickRangeInput = () => {
    if (!visibleRangePicker) setVisibleRangePicker(true);
  };

  const onClickTab = (tab: string) => {
    setCurrentTabMrCd(tab);
  };
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight, componentRef.current?.offsetWidth]);

  const frozenCount = useGetFrozenCount();

  return (
    <div className='contents'>
      <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      <div className='content-container oneColumn'>
        <Root loading={loading}>
          <SearchOptions>
            {/* {isBottom && (
              <FloatingButtonToTop>
                <button type='button' onClick={() => scrollToNodeTop(tableRef)}>
                  <IoChevronUpSharp size={20} style={{ stroke: 'white' }} />
                </button>
              </FloatingButtonToTop>
            )} */}
            <div className='inputsWrapper'>
              <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <Input
                    placeholder={t('사용자명')}
                    label=''
                    type='text'
                    id='userName'
                    name='userName'
                    state={searchOption}
                    setState={setSearchOption}
                    inputRef={userNameRef}
                    onKeyDown={onEnterKeyDown}
                    trim
                  />
                </div>
              </div>
              <div className='inputForm-row-fit'>
                <span className='calendarLabel'>{t('로그일자')}</span>
                <div className='inputForm-col'>
                  <div className='flex-basic'>
                    <input id='startInput' type='text' value={searchOption.start} onMouseDown={onClickRangeInput} onChange={(e) => onChangeRangeInput(e, 'start', setRangeState, setSearchOption)} />
                    <span> ~ </span>
                    <input id='endInput' type='text' value={searchOption.end} onMouseDown={onClickRangeInput} onChange={(e) => onChangeRangeInput(e, 'end', setRangeState, setSearchOption)} />
                  </div>
                  {visibleRangePicker && (
                    <div className='rangePickerWrapper'>
                      <RangePicker state={rangeState} setState={setRangeState} setVisible={setVisibleRangePicker} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='inputsWrapper'>
              <div className='secondSearchOption'>
                <div className='flex-basic textBtnGroup'>
                  <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
                  {/* <BtnGhost onClick={onClickSearch} className='searchBtn'>
                    {t('검색')}
                    <span className='shortcut-f9'>F9</span>
                  </BtnGhost> */}
                  <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
                  <div className='searchResult'>
                    {t('총')}
                    <span>{tableState.length}</span>
                    {t('개')}
                  </div>
                  <div className='inputForm-row'>
                    <div className='inputForm-col withLabelComCf'>
                      <label htmlFor='useYn'>{t('보기 설정')}</label>
                      <SelectBox
                        options={listCountComCdList}
                        defaultOption={listCount.cdName}
                        state={listCount}
                        setState={setListCount}
                        stateKey={LIST_COUNT}
                        initiateKey={listCount[LIST_COUNT]}
                        setTableLinesAPI={patchUserMenuAPI}
                        optionHeight='height-sm'
                        rsearch
                      />
                    </div>
                  </div>
                </div>
                <div className='flex-basic iconBtnGroup'>
                  {renderExcelBtn()}
                  {/* {renderPrintBtn()} */}
                </div>
              </div>
            </div>
          </SearchOptions>
          <TuiGridWrapper componentRef={componentRef}>
            <TuiGrid
              data={tableState}
              columns={columns}
              perPage={Number(listCount[LIST_COUNT])}
              excelBtn={excelBtn}
              setExcelBtn={setExcelBtn}
              usePagenation
              onClickRow={onClickRow}
              scrollX
              height={tuiHeight}
              frozenCount={frozenCount}
            />
          </TuiGridWrapper>
        </Root>
      </div>
      {loading && (
        <LoadingModalBackground>
          <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
        </LoadingModalBackground>
      )}
    </div>
  );
};

export default LogReport;
