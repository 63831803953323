import useClock from './useClock';

const useWindows = () => {
  const clock1 = useClock();
  const clock2 = useClock();
  const clock3 = useClock();
  const clock4 = useClock();
  const clock5 = useClock();
  const clock6 = useClock();
  const clock7 = useClock();
  const clock8 = useClock();
  const clock9 = useClock();
  const clock10 = useClock();
  const clock11 = useClock();
  const clock12 = useClock();
  const clock13 = useClock();
  const clock14 = useClock();
  const clock15 = useClock();
  const clock16 = useClock();

  const windows = [
    { windowIndex: 0, clock: clock1 }, //
    { windowIndex: 1, clock: clock2 },
    { windowIndex: 2, clock: clock3 },
    { windowIndex: 3, clock: clock4 },
    { windowIndex: 4, clock: clock5 },
    { windowIndex: 5, clock: clock6 },
    { windowIndex: 6, clock: clock7 },
    { windowIndex: 7, clock: clock8 },
    { windowIndex: 8, clock: clock9 },
    { windowIndex: 9, clock: clock10 },
    { windowIndex: 10, clock: clock11 },
    { windowIndex: 11, clock: clock12 },
    { windowIndex: 12, clock: clock13 },
    { windowIndex: 13, clock: clock14 },
    { windowIndex: 14, clock: clock15 },
    { windowIndex: 15, clock: clock16 },
  ];

  return windows;
};

export default useWindows;
