import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { IUser, pageInfoState, userState } from '../../atoms';
import { ITabObject } from 'customTypes';
import Tab from '../../components/Tab';
import BaseInfo from './s_tunnel1/BaseInfo';
import DetailInfo from './s_tunnel1/DetailInfo';
import GeneralStatus from './s_tunnel1/GeneralStatus';
import { logPost } from '../../services/log';

const Stunnel1 = () => {
  const size = useOutletContext<any>();
  const userInfo = useRecoilValue<IUser>(userState);
  const tabInfo = useRecoilValue(pageInfoState);
  const { defaultMrCd } = useRecoilValue(pageInfoState);
  const { userMenuList } = userInfo;
  const [newTabList, setNewTabList] = useState<ITabObject[]>([]);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(defaultMrCd);

  const onClickTab = (tab: string) => {
    setCurrentTabMrCd(tab);
  };

  // 탭 정보 불러오기
  useEffect(() => {
    const arr: any[] = [];
    userMenuList.forEach((v: any) => {
      const result = tabInfo?.tabList.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
      }
      setNewTabList(arr);
    });
  }, [tabInfo.tabList, userMenuList]);

  const renderPage = () => {
    switch (currentTabMrCd) {
      case '001':
        return <BaseInfo />;
      case '002':
        return <GeneralStatus />;
      case '003':
        return <DetailInfo />;
      default:
        return null;
    }
  };

  return (
    <div className='contents'>
      <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      <div className='content-container oneColumn'>{renderPage()}</div>
    </div>
  );
};

export default Stunnel1;
