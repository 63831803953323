/**
 * 작성자 : 김광민
 * 날짜 : 2023.12.19
 * 경로 : 터널 관리 - 구역 현황 관리
 */

import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import { IUser, userState } from '../atoms';
import { COMCD_USE_YN, INIT_USE_YN_A } from '../_constants';
import { SearchOptions } from '../assets/styles/SearchOptions';
import { BtnGhost } from './Button';
import Input from './Input';
import SelectBox from './SelectBox';
import InvisibleTuiGrid from './InvisibleTuiGrid';
import SearchSelectBoxSm from './SearchSelectBoxSm';
import useOnKeydownF9 from '../utils/useOnKeydownF9';
import { logPost } from '../services/log';
import { useFetchCommonCodeList } from '../services/useSetCodeListInSelectBoxForm';
import ShortcutButton from './button/ShortcutButton';

type Props = {
  tareaGridMaster: TareaData[];
  tareaGrid: TareaData[];
  setTareaGrid: Dispatch<SetStateAction<TareaData[]>>;
  onClickChartBtn: () => void;
};

const TunnelFilter = ({ tareaGridMaster, tareaGrid, setTareaGrid, onClickChartBtn }: Props) => {
  const userInfo = useRecoilValue<IUser>(userState);
  const { t } = useTranslation();
  const { data: useYnComCdListWithAll } = useFetchCommonCodeList(COMCD_USE_YN, true); // 사용여부 공통코드 목록 (전체포함)

  const [selectedUseYn, setSelectedUseYn] = useState({ type: COMCD_USE_YN, [COMCD_USE_YN]: 'A', cdName: '전체' });

  // 구역명
  const [tAreaList, setTAreaList] = useState<{ type: string; cdName: string; fName: string }[]>([]);
  const [selectedTArea, setSelectedTArea] = useState({ type: 'tatName', cdName: '전체', fName: '' });

  // 작업내용
  const [tjcList, setTjcList] = useState<{ type: string; cdName: string; fName: string }[]>([]);
  const [selectedTjc, setSelectedTjc] = useState({ type: 'tjcName', cdName: '전체', fName: '' });

  // 작업내용
  const [tesList, setTesList] = useState<{ type: string; cdName: string; fName: string }[]>([]);
  const [selectedTes, setSelectedTes] = useState({ type: 'tesName', cdName: '전체', fName: '' });

  // 비고
  const bigoRef = useRef<HTMLInputElement>(null);
  const [searchBigo, setSearchBigo] = useState({ wName: '' });

  // 엑셀 저장 버튼
  const [excelBtn, setExcelBtn] = useState(false);

  // f9키 프레스 훅
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9();

  // 초기화 버튼 클릭
  const handleInitiate = () => {
    setTareaGrid([...tareaGridMaster]);
    setSelectedUseYn(INIT_USE_YN_A);
    setSelectedTArea({ type: 'tatName', cdName: '전체', fName: '' });
    setSelectedTjc({ type: 'tjcName', cdName: '전체', fName: '' });
    setSelectedTes({ type: 'tesName', cdName: '전체', fName: '' });
    setSearchBigo({ wName: '' });
  };

  // 작업내용 및 장비상태 필터 데이터 가공
  const extractList = (type: 'tjc' | 'tes') => {
    const listUp = tareaGridMaster.flatMap((tarea) => (type === 'tjc' ? [tarea.eTjcName, tarea.sTjcName] : [tarea.eTesName, tarea.sTesName]));
    const nullToString = listUp.map((element) => (element === null ? '내용없음' : element));
    const deleteDuplication = nullToString.filter((element, index) => {
      return nullToString.indexOf(element) === index;
    });
    return deleteDuplication.map((element) => ({ type: type === 'tjc' ? 'tjcName' : 'tesName', cdName: element, fName: element }));
  };

  // 현재 페이지 이동시 필터 리스트 생성
  useEffect(() => {
    if (tareaGridMaster.length !== 0) {
      const tAreaResult = tareaGridMaster.map((tarea) => ({ type: 'tatName', cdName: tarea.tatName, fName: tarea.tatName }));
      setTAreaList([{ type: 'tatName', cdName: '전체', fName: '' }, ...tAreaResult]);
      setTjcList([{ type: 'tjcName', cdName: '전체', fName: '' }, ...extractList('tjc')]);
      setTesList([{ type: 'tesName', cdName: '전체', fName: '' }, ...extractList('tes')]);
    }
  }, [tareaGridMaster]);

  // 검색버튼 클릭 이벤트
  const onClickSearch = () => {
    const result = tareaGridMaster.filter((tarea) => {
      const useYn = selectedUseYn[COMCD_USE_YN] === 'A' ? true : tarea.useYn === selectedUseYn[COMCD_USE_YN];
      const tArea = selectedTArea.fName === '' ? true : tarea.tatName === selectedTArea.fName;
      const tjc = selectedTjc.fName === '' ? true : tarea.eTjcName === selectedTjc.fName || tarea.sTjcName === selectedTjc.fName || (tarea.sTjcName === null && selectedTjc.fName === '내용없음');
      const tes = selectedTes.fName === '' ? true : tarea.eTesName === selectedTes.fName || tarea.sTesName === selectedTes.fName || (tarea.sTesName === null && selectedTes.fName === '내용없음');
      const bigo = searchBigo.wName === '' ? true : tarea.bigo.includes(searchBigo.wName) || tarea.eBigo.includes(searchBigo.wName) || tarea.sBigo.includes(searchBigo.wName);
      return useYn && tArea && tjc && tes && bigo;
    });
    setTareaGrid([...result]);
  };

  // f9 keydown 이벤트
  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  // enter keydown 이벤트
  const loginOnEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (bigoRef.current) onClickSearch();
    }
  };

  // 엑셀 저장 버튼 클릭
  const onClickExcelSave = () => {
    if (tareaGrid.length === 0) toast.warning(t('데이터가 존재하지 않습니다'));
    if (tareaGrid.length > 0) {
      setExcelBtn(true);
      logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '구역 현황 관리',
        action: '엑셀 저장',
        etc: ``,
      });
    }
    return null;
  };

  return (
    <SearchOptions>
      <div className='inputsWrapper'>
        <div className='inputForm-row withLabel'>
          <label htmlFor='useYn'>{t('사용유무')}</label>
          <div className='inputForm-col'>
            <SelectBox
              options={useYnComCdListWithAll}
              defaultOption={t('전체')}
              state={selectedUseYn}
              setState={setSelectedUseYn}
              stateKey={COMCD_USE_YN}
              filterbar='filter-1-left'
              initiateKey={selectedUseYn[COMCD_USE_YN]}
              dropDownWidth='expand-content-sm'
              optionHeight='height-md'
            />
          </div>
        </div>
        <div className='inputForm-row'>
          <div className='inputForm-col'>
            <SearchSelectBoxSm
              options={tAreaList}
              defaultOption={t('구역명 전체')}
              state={selectedTArea}
              setState={setSelectedTArea}
              stateKey='fName'
              codeKey='cdName'
              initiateKey=''
              filterbar='filter-1-left'
              dropDownWidth='expand-content-xs'
              optionHeight='height-md'
            />
          </div>
        </div>
        <div className='inputForm-row'>
          <div className='inputForm-col'>
            <SearchSelectBoxSm
              options={tjcList}
              defaultOption={t('작업내용 전체')}
              state={selectedTjc}
              setState={setSelectedTjc}
              stateKey='fName'
              codeKey='cdName'
              initiateKey=''
              filterbar='filter-1-left'
              dropDownWidth='expand-content-xs'
              optionHeight='height-md'
            />
          </div>
        </div>
        <div className='inputForm-row'>
          <div className='inputForm-col'>
            <SearchSelectBoxSm
              options={tesList}
              defaultOption={t('장비상태 전체')}
              state={selectedTes}
              setState={setSelectedTes}
              stateKey='fName'
              codeKey='cdName'
              initiateKey=''
              filterbar='filter-1-left'
              dropDownWidth='expand-content-xs'
              optionHeight='height-md'
            />
          </div>
        </div>
        <div className='inputForm-row'>
          <div className='inputForm-col'>
            <Input placeholder={t('비고')} label='' type='text' id='wName' name='wName' state={searchBigo} setState={setSearchBigo} inputRef={bigoRef} onKeyDown={loginOnEnterKeyDown} />
          </div>
        </div>
      </div>
      <div className='inputsWrapper'>
        <div className='secondSearchOption'>
          <div className='flex-basic textBtnGroup'>
            <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
            {/* <BtnGhost onClick={onClickSearch} className='searchBtn'>
              {t('검색')}
              <span className='shortcut-f9'>F9</span>
            </BtnGhost> */}
            <BtnGhost onClick={handleInitiate}>{t('초기화')}</BtnGhost>
            <div className='searchResult'>
              {t('총')}
              <span>{tareaGrid.length}</span>
              {t('개')}
            </div>
          </div>
          <div className='flex-basic iconBtnGroup'>
            <BtnGhost onClick={onClickChartBtn}>
              <span className='material-symbols-rounded'>monitoring</span>
              {t('차트')}
            </BtnGhost>
            {/* <BtnGhost onClick={() => onClickChart({ tableData: mainTableState, type: 'lineChart' })}>
          <span className='material-symbols-rounded'>monitoring</span>
          {t('차트')}
        </BtnGhost> */}
            <BtnGhost onClick={() => onClickExcelSave()}>
              <span className='icon-ms-xlsx'>X</span> {t('엑셀')}
            </BtnGhost>
            {tareaGrid.length > 0 && <InvisibleTuiGrid data={tareaGrid} excelBtn={excelBtn} setExcelBtn={setExcelBtn} />}
          </div>
        </div>
      </div>
    </SearchOptions>
  );
};

export default TunnelFilter;
