/**
 * 작성자 : 홍선영
 * 날짜 : 2023.12.28
 * 경로 : 설정관리 > 방송 장비 관리 > 터널 방송 장비 목록 탭
 */
import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { PulseLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { IoChevronUpSharp } from 'react-icons/io5';
import styled from 'styled-components';

import { IComCdList } from 'customTypes';
import { COMCD_USE_YN, INIT_CCD_A, INIT_NCD_A, INIT_USE_YN_A, LIST_COUNT } from '../../../_constants';
import { IUser, asideFoldState, userState } from '../../../atoms';
import i18n from '../../../translation/i18n';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { FloatingButtonToTop } from '../../../assets/styles/FloatingButtonToTop';
import { ContentsContainerRoot } from '../../../assets/styles/ContentsContainerRoot';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import { BtnGhost } from '../../../components/Button';
import Portal from '../../../components/Portal';
import TuiGrid from '../../../components/Table/TuiGrid';
import SelectBox from '../../../components/SelectBox';
import CustomRenderer from '../../../components/CustomRenderer';
import TuiGridWrapper from '../../../components/Table/TuiGridWrapper';
import SearchSelectBoxSm from '../../../components/SearchSelectBoxSm';
import BroadcastEquipModal from '../../../components/Modal/BroadcastEquipModal';
import { scrollToNodeTop } from '../../../utils/scrollToNodeTop';
import { useGetFrozenCount } from '../../../utils/getFrozenCount';
import { setComCdListState } from '../../../utils/setComCdListState';
import { useDetectScrolledToBottom } from '../../../utils/useDetectScrolledToBottom';
import useOnKeydownF9 from '../../../utils/useOnKeydownF9';
import useSetListCount from '../../../utils/useSetListCount';
import { apiGet } from '../../../services/_common';
import { useFetchCommonCodeList, useFetchNvrCodeList } from '../../../services/useSetCodeListInSelectBoxForm';
import ShortcutButton from '../../../components/button/ShortcutButton';
import { logPost } from '../../../services/log';

const Root = styled(ContentsContainerRoot)`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .btn-in-cell {
    height: 2rem;
    font-size: 0.875rem;
    padding-left: 0.5rem;
    padding-right: 0.75rem;
    margin: 0 auto;
    font-weight: 600;
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.board};
    }
    .material-symbols-rounded {
      margin-right: 0.25rem;
    }
  }
  .btn-blue {
    background-color: ${({ theme }: { theme: any }) => theme.filled_blue} !important;
    border: none !important;
    /* color: ${({ theme }: { theme: any }) => theme.tonal_blue} !important; */
    color: ${({ theme }: { theme: any }) => theme.color.blue_100} !important;
  }
  .btn-red {
    background-color: ${({ theme }: { theme: any }) => theme.filled_red} !important;
    border: none !important;
    /* color: ${({ theme }: { theme: any }) => theme.tonal_red} !important; */
    color: ${({ theme }: { theme: any }) => theme.color.red_100} !important;
  }
  .btn-blue,
  .btn-red {
    .material-symbols-rounded {
      font-size: 1.375rem;
    }
  }

  .tunnelPoint {
    display: flex;
    justify-content: center;
    gap: 0.25rem;
    width: fit-content;
    margin: 0 auto;
    padding: 0.5rem 0.75rem;
    border-radius: 1rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    cursor: pointer;

    &.start {
      color: ${({ theme }: { theme: any }) => theme.filled_blue};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
    }

    &.end {
      color: ${({ theme }: { theme: any }) => theme.filled_amber};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
    }
  }
`;

const BroadcastList = () => {
  const { t } = useTranslation();
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const { listCount, setListCount, patchUserMenuAPI } = useSetListCount(); // 검색줄수설정 훅
  const userInfo = useRecoilValue<IUser>(userState);
  const { eExcelYn, eListYn, ePrintYn } = userInfo;
  const [columns, setColumns] = useState<any[]>([]);
  const tableRef = useRef<HTMLDivElement>(null);
  const [listCountComCdList, setListCountComCdList] = useState<IComCdList[]>([]); // 검색줄수 공통코드
  const [checkList, setCheckList] = useState<any[]>([]);
  const [excelBtn, setExcelBtn] = useState(false);
  const [tableState, setTableState] = useState<any[]>([]);
  const [tableState2, setTableState2] = useState<any[]>([]);
  const [orgTableState, setOrgTableState] = useState<any[]>([]);
  const { isBottom } = useDetectScrolledToBottom(tableRef);
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '', code: '', index: 0 });
  const [rowKey, setRowKey] = useState('');
  const [loading, setLoading] = useState(false);
  const foldMenu = useRecoilValue(asideFoldState);
  const [searchOptionUseYn, setSearchOptionUseYn] = useState(INIT_USE_YN_A);
  const [bsList, setBsList] = useState<any>([]);
  const [cCdList, setcCdList] = useState<any[]>([]);
  const [tatCdList, setTatCdList] = useState<any[]>([]);
  const [tatGubunCdList, setTatGubunCdList] = useState<any[]>([]);
  const [tAreaList, setTAreaList] = useState<any[]>([]);
  const [tadCdList, setTadCdList] = useState<any[]>([]);
  const [bsCd, setBsCd] = useState({ type: 'bsCd', bsCd: 'A', cdName: t('전체') });
  const [nCd, setnCd] = useState(INIT_NCD_A);
  const [cCd, setcCd] = useState(INIT_CCD_A);
  const [tatCd, setTatCd] = useState({ type: 'tatCd', tatCd: 'A', cdName: t('전체') });
  const [tatGubunCd, setTatGubunCd] = useState({ type: 'tatGubunCd', tatGubunCd: 'A', cdName: t('전체') });
  const [tadCd, setTadCd] = useState({ type: 'tadCd', tadCd: 'A', cdName: t('전체') });
  const componentRef = useRef<HTMLDivElement>(null);
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  const { data: useYnComCdListWithAll } = useFetchCommonCodeList(COMCD_USE_YN, true); // 사용여부 공통코드 목록 (검색조건에서 사용, 전체포함)
  const { data: nvrList } = useFetchNvrCodeList(); // NVR목록 조회 useQuery
  const MESSAGE = t('구역명과 시/종점을 먼저 선택하세요');

  useEffect(() => {
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '터널 방송 장비 목록 ',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight, foldMenu]);

  const frozenCount = useGetFrozenCount();

  const bsTotalQuery = useQuery(['bsTotal', userInfo], () => apiGet({ path: '/bs/total', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && userInfo.sCd !== '00000',
  });

  useEffect(() => {
    if (bsTotalQuery.isSuccess && bsTotalQuery.data.status === 200) {
      const { data } = bsTotalQuery.data.data;
      setTableState2(data.map((el: any) => ({ ...el, useYnCdName: el.useYn === 'Y' ? '사용' : '미사용' })));
      setOrgTableState(data.map((el: any) => ({ ...el, useYnCdName: el.useYn === 'Y' ? '사용' : '미사용' })));

      setComCdListState(LIST_COUNT, setListCountComCdList, false);
    }
  }, [bsTotalQuery.isSuccess, bsTotalQuery.isRefetching]);

  const bsMQuery = useQuery(['bsM', userInfo], () => apiGet({ path: '/bs', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && userInfo.sCd !== '00000',
  });

  useEffect(() => {
    if (bsMQuery.isSuccess && bsMQuery.data.status === 200) {
      const { data } = bsMQuery.data.data;
      const newArray = data.filter((el: any) => el.useYn === 'Y').map((el2: any) => ({ type: 'bsCd', bsCd: el2.bsCd, cdName: el2.bsName }));
      setBsList([{ type: 'bsCd', bsCd: 'A', cdName: t('전체') }, ...newArray]);
    }
  }, [bsMQuery.isSuccess, bsMQuery.isRefetching]);

  const camCameraQuery = useQuery(['camCameraGet', userInfo.hCd, userInfo.sCd, nCd.nCd], () => apiGet({ path: '/cam/camera', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, nCd: nCd.nCd } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && !!nCd.nCd && nCd.nCd !== 'A',
  });

  const tAreaQuery = useQuery(['tareaGet', userInfo.hCd, userInfo.sCd, nCd.nCd], () => apiGet({ path: '/tarea', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
  });

  const tAreaDQuery = useQuery(
    ['tAreaDGet', userInfo.hCd, userInfo.sCd, tatCd.tatCd, tatGubunCd.tatGubunCd],
    () => apiGet({ path: '/tarea/d', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, tatCd: tatCd.tatCd, tatGubun: tatGubunCd.tatGubunCd } }),
    {
      enabled: !!userInfo.hCd && !!userInfo.sCd && !!tatCd.tatCd && tatCd.tatCd !== 'A' && !!tatGubunCd.tatGubunCd && tatGubunCd.tatGubunCd !== 'A',
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      if (camCameraQuery.isSuccess && camCameraQuery.data.status === 200) {
        const { cameraList } = camCameraQuery.data.data.data;
        const newArray = cameraList.filter((el: any) => el.useYn === 'Y').map((el: any) => ({ type: 'cCd', cCd: el.cCd, cdName: el.cName }));
        setcCdList([{ type: 'cCd', cCd: 'A', cdName: t('전체') }, ...newArray]);
      }
    };

    fetchData().catch(console.error);
  }, [camCameraQuery.isSuccess, camCameraQuery.isRefetching, nCd.nCd]);

  useEffect(() => {
    const fetchData = async () => {
      if (tAreaQuery.isSuccess && tAreaQuery.data.status === 200) {
        const { tareaList } = tAreaQuery.data.data.data;
        setTAreaList(tareaList);
        const newArray = tareaList.filter((el: any) => el.useYn === 'Y').map((el: any) => ({ type: 'tatCd', tatCd: el.tatCd, cdName: el.tatName }));
        setTatCdList([{ type: 'tatCd', tatCd: 'A', cdName: t('전체') }, ...newArray]);
      }
    };

    fetchData().catch(console.error);
  }, [tAreaQuery.isSuccess, tAreaQuery.isRefetching]);

  useEffect(() => {
    if (tAreaDQuery.isSuccess && tAreaDQuery.data.status === 200) {
      const { tareaDList } = tAreaDQuery.data.data.data;
      const newArray = tareaDList.filter((el: any) => el.useYn === 'Y').map((el: any) => ({ type: 'tadCd', tadCd: el.tadCd, cdName: el.tadName }));
      setTadCdList([{ type: 'tadCd', tadCd: 'A', cdName: t('전체') }, ...newArray]);
    }
  }, [tAreaDQuery.isSuccess, tAreaDQuery.isRefetching, tatCd.tatCd]);

  useEffect(() => {
    setColumns([
      {
        header: t('코드'),
        name: 'bsCd',
        align: 'center',
        sortable: true,
        minWidth: 80,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('방송 스피커명'),
        name: 'bsName',
        sortable: true,
        width: 200,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('NVR 명'),
        name: 'nName',
        sortable: true,
        minWidth: 120,
      },
      {
        header: t('카메라 명'),
        name: 'cName',
        sortable: true,
        minWidth: 120,
      },
      {
        header: t('구역 명'),
        name: 'tatName',
        sortable: true,
        minWidth: 140,
      },
      {
        header: t('시/종점'),
        name: 'tatGubun',
        align: 'center',
        renderer: {
          type: CustomRenderer,
        },
        sortable: true,
        minWidth: 120,
      },
      {
        header: t('상세구역 명'),
        name: 'tadName',
        sortable: true,
        minWidth: 120,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('비고'),
        name: 'bigo',
        sortable: true,
        minWidth: 180,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('사용유무'),
        name: 'useYnCdName',
        align: 'center',
        sortable: true,
        minWidth: 120,
      },
    ]);
  }, [userInfo.prejobtypeYn, tableState, i18n.language]);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    setTableState(
      orgTableState.map((v: any) => {
        delete v.wDate;
        return { ...v, wWorkstatusName: t(v.wWorkstatusName) };
      })
    );
  }, [i18n.language]);

  // 검색버튼 클릭 이벤트
  const onClickSearch = () => {
    const searchFilter = { bsCd: bsCd.bsCd, nCd: nCd.nCd, cCd: cCd.cCd, tatCd: tatCd.tatCd, tatGubun: tatGubunCd.tatGubunCd, tadCd: tadCd.tadCd, useYn: searchOptionUseYn[COMCD_USE_YN] };
    const filteredData = orgTableState.filter((item: any) => {
      return Object.entries(searchFilter).every(([key, filterValue]) => {
        if (filterValue === 'A') return true; // 필터 값이 'A'이면 기준 무시 (return true)
        return item[key] === filterValue; // 그렇지 않으면 항목의 속성이 필터의 속성과 일치하는지 확인
      });
    });
    setTableState2(filteredData);
  };

  const initiateSearchOptions = () => {
    setBsCd({ type: 'bsCd', bsCd: 'A', cdName: t('전체') });
    setnCd(INIT_NCD_A);
    setcCd(INIT_CCD_A);
    setTatCd({ type: 'tatCd', tatCd: 'A', cdName: t('전체') });
    setTadCd({ type: 'tadCd', tadCd: 'A', cdName: t('전체') });
    setTatGubunCd({ type: 'tatGubunCd', tatGubunCd: 'A', cdName: t('전체') });
    setSearchOptionUseYn(INIT_USE_YN_A);
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    initiateSearchOptions();
    setTableState2(orgTableState);
  };

  // 선택 방송 버튼 클릭
  const onClickBs = () => {
    if (checkList.length !== 0) {
      setOpenModal((prev: any) => ({ ...prev, status: true, type: 'broadcast', data: [...checkList] }));
    } else toast.warning(t('방송 스피커를 선택하세요'));
  };

  // 방송장비 Row 클릭
  const onClickRow = (rowData: any, columnName: any, filteredIndex: number) => {
    setRowKey(`${filteredIndex}`);
  };

  // checkBox 클릭
  const onClickCheckBox = (rowKeyList: any) => {
    setCheckList(rowKeyList);
  };

  useEffect(() => {
    // CCTV명이 변경된 경우, 카메라명 셀렉트값, 목록 초기화
    setcCd(INIT_CCD_A);
    if (nCd.nCd === 'A') setcCdList([INIT_CCD_A]);
  }, [nCd.nCd]);

  // 선택한 구역의 시/종점 사용 유무에 따라 시/종점 선택셀렉트 변경
  useEffect(() => {
    if (tatCd.tatCd) {
      const findTarea = tAreaList.find((el: any) => el.tatCd === tatCd.tatCd);
      const d = { type: 'tatGubunCd', tatGubunCd: 'A', cdName: t('전체') };
      const s = { type: 'tatGubunCd', tatGubunCd: 'S', cdName: t('시점') };
      const e = { type: 'tatGubunCd', tatGubunCd: 'E', cdName: t('종점') };
      if (findTarea) {
        if (findTarea.sUseYn === 'Y' && findTarea.eUseYn === 'Y') setTatGubunCdList([d, s, e]);
        else if (findTarea.sUseYn === 'Y' && findTarea.eUseYn === 'N') setTatGubunCdList([d, s]);
        else if (findTarea.sUseYn === 'N' && findTarea.eUseYn === 'Y') setTatGubunCdList([d, e]);
        else setTatGubunCdList([d]);
      } else setTatGubunCdList([d, s, e]);
    }
    setTatGubunCd({ type: 'tatGubunCd', tatGubunCd: 'A', cdName: t('전체') });

    // 구역명이 변경된 경우, 상세구역명 셀렉트값, 목록 초기화
    setTadCd({ type: 'tadCd', tadCd: 'A', cdName: t('전체') });
    setTadCdList([{ type: 'tadCd', tadCd: 'A', cdName: t('전체') }]);
  }, [tatCd.tatCd]);

  useEffect(() => {
    // 시/종점이 변경된 경우, 상세구역명 셀렉트값, 목록 초기화
    setTadCd({ type: 'tadCd', tadCd: 'A', cdName: t('전체') });
    if (tatGubunCd.tatGubunCd === 'A') setTadCdList([{ type: 'tadCd', tadCd: 'A', cdName: t('전체') }]);
  }, [tatGubunCd.tatGubunCd]);

  return (
    <>
      <Root loading={loading}>
        <SearchOptions>
          {isBottom && (
            <FloatingButtonToTop>
              <button type='button' onClick={() => scrollToNodeTop(tableRef)}>
                <IoChevronUpSharp size={20} style={{ stroke: 'white' }} />
              </button>
            </FloatingButtonToTop>
          )}
          <div className='inputsWrapper'>
            <div className='inputForm-row withLabel'>
              <label htmlFor='wWorkstatus'>{t('방송 스피커명')}</label>
              <div className='inputForm-col'>
                <SearchSelectBoxSm
                  options={bsList}
                  defaultOption={t('전체')}
                  state={bsCd}
                  setState={setBsCd}
                  stateKey='bsCd'
                  codeKey='cdName'
                  initiateKey={bsCd.bsCd}
                  filterbar='filter-1-left'
                  dropDownWidth='fit-content'
                  optionHeight='height-md'
                />
              </div>
            </div>
            <div className='inputForm-row withLabel'>
              <label htmlFor='wWorkstatus'>{t('NVR 명')}</label>
              <div className='inputForm-col'>
                <SearchSelectBoxSm
                  options={nvrList}
                  defaultOption={t('전체')}
                  state={nCd}
                  setState={setnCd}
                  stateKey='nCd'
                  codeKey='cdName'
                  initiateKey={nCd.nCd}
                  filterbar='filter-1-left'
                  optionHeight='height-md'
                />
              </div>
            </div>
            <div className='inputForm-row withLabel'>
              <label htmlFor='wWorkstatus'>{t('카메라 명')}</label>
              <div className='inputForm-col'>
                <SearchSelectBoxSm
                  options={cCdList}
                  defaultOption={t('전체')}
                  state={cCd}
                  setState={setcCd}
                  stateKey='cCd'
                  codeKey='cdName'
                  initiateKey={cCd.cCd}
                  filterbar='filter-1-left'
                  optionHeight='height-md'
                />
              </div>
            </div>
            <div className='inputForm-row withLabel'>
              <label htmlFor='tatCd'>{t('구역 명')}</label>
              <div className='inputForm-col'>
                <SearchSelectBoxSm
                  options={tatCdList}
                  defaultOption={t('전체')}
                  state={tatCd}
                  setState={setTatCd}
                  stateKey='tatCd'
                  codeKey='cdName'
                  initiateKey={tatCd.tatCd}
                  filterbar='filter-1-right'
                  comboWidth='expand-box-sm'
                  optionHeight='height-md'
                />
              </div>
            </div>
            <div className='inputForm-row withLabel'>
              <label htmlFor='tatGubunCd'>{t('시/종점')}</label>
              <div className='inputForm-col'>
                <SelectBox
                  options={tatGubunCdList}
                  defaultOption={t('전체')}
                  state={tatGubunCd}
                  setState={setTatGubunCd}
                  stateKey='tatGubunCd'
                  initiateKey={tatGubunCd.tatGubunCd}
                  optionHeight='height-md'
                />
              </div>
            </div>
            <div className='inputForm-row withLabel'>
              <label htmlFor='tadCd'>{t('상세구역 명')}</label>
              <div className='inputForm-col'>
                <SearchSelectBoxSm
                  options={tadCdList}
                  defaultOption={t('전체')}
                  state={tadCd}
                  setState={setTadCd}
                  stateKey='tadCd'
                  codeKey='cdName'
                  initiateKey={tadCd.tadCd}
                  filterbar='filter-1-right'
                  comboWidth='expand-box-sm'
                  optionHeight='height-md'
                  clickAble={tatGubunCd.tatGubunCd === 'A' || tatCd.tatCd === 'A'}
                  toastContent={MESSAGE}
                />
              </div>
            </div>
            <div className='inputForm-row withLabel'>
              <label htmlFor='wWorkstatus'>{t('사용유무')}</label>
              <div className='inputForm-col'>
                <SelectBox
                  options={useYnComCdListWithAll}
                  defaultOption={t('전체')}
                  state={searchOptionUseYn}
                  setState={setSearchOptionUseYn}
                  stateKey={COMCD_USE_YN}
                  initiateKey={searchOptionUseYn[COMCD_USE_YN]}
                />
              </div>
            </div>
          </div>
          <div className='inputsWrapper'>
            <div className='secondSearchOption'>
              <div className='flex-basic textBtnGroup'>
                <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
                {/* <BtnGhost onClick={onClickSearch} className='searchBtn'>
                  {t('검색')}
                  <span className='shortcut-f9'>F9</span>
                </BtnGhost> */}
                <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
                <div className='searchResult'>
                  {t('총')}
                  <span>{tableState2?.length || 0}</span>
                  {t('개')}
                </div>
                <div className='inputForm-row'>
                  <div className='inputForm-col withLabelComCf'>
                    <label htmlFor='useYn'>{t('보기 설정')}</label>
                    <SelectBox
                      options={listCountComCdList}
                      defaultOption={listCount[LIST_COUNT]}
                      state={listCount}
                      setState={setListCount}
                      stateKey={LIST_COUNT}
                      initiateKey={listCount[LIST_COUNT]}
                      setTableLinesAPI={patchUserMenuAPI}
                      optionHeight='height-sm'
                      rsearch
                    />
                  </div>
                </div>
              </div>
              <div className='flex-basic iconBtnGroup'>
                <BtnGhost className='btn-blue' onClick={onClickBs}>
                  <span className='material-symbols-rounded'>volume_up</span> {t('선택 방송')}
                </BtnGhost>
              </div>
            </div>
          </div>
        </SearchOptions>
        <TuiGridWrapper componentRef={componentRef}>
          <TuiGrid
            data={tableState2}
            columns={columns}
            perPage={Number(listCount[LIST_COUNT])}
            excelBtn={excelBtn}
            setExcelBtn={setExcelBtn}
            usePagenation
            onClickRow={onClickRow}
            onClickCheckBox={onClickCheckBox}
            // height={componentRef.current?.offsetHeight}
            // visibleTotalCount
            scrollX
            height={tuiHeight}
            rowKey={rowKey}
            eListYn={eListYn}
            eExcelYn={eExcelYn}
            frozenCount={frozenCount}
          />
        </TuiGridWrapper>
      </Root>
      {loading && (
        <LoadingModalBackground>
          <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
        </LoadingModalBackground>
      )}
      <Portal openModal={openModal?.status}>{openModal.type === 'broadcast' && <BroadcastEquipModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
      {/* <Portal openModal={openModal?.status}>
        {openModal.status && openModal.type === 'infoWorker' && openModal.code && (
          <InfoWorkerModal
            innerTabIndex={0}
            getReportListAPI={getWorkerAPI}
            reportArray={filterTableState}
            setReportArray={setFilterTableState}
            setOpenModal={setOpenModal}
            auth={auth}
            code={{ hCd: userInfo.hCd, sCd: userInfo.sCd, wCd: openModal.code, aDate: today() }}
            index={openModal.index}
            setRowKey={setRowKey}
            listCount={Number(listCount[LIST_COUNT])}
          />
        )}
      </Portal> */}
    </>
  );
};

export default BroadcastList;
