/**
 * 작성자 : 홍선영
 * 날짜 : 2023.05.02
 * 경로 : 마스터일반관리-본사관리 페이지
 */

import { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { COMCD_LOCK_YN, COMCD_USE_YN, INIT_LOCK_YN_A, INIT_LOCK_YN_N, INIT_USE_YN_A, INIT_USE_YN_Y, SELECT_HNAME_OR_ADMINUSERNAME, SELECT_HNAME_OR_SALESUSERNAME } from '../../_constants';
import { IModal, INumbers, ITabObject } from 'customTypes';
import { IUser, pageInfoState, userState } from '../../atoms';
import { SearchOptions } from '../../assets/styles/SearchOptions';
import { BtnGhost } from '../../components/Button';
import Tab from '../../components/Tab';
import Input from '../../components/Input';
import Portal from '../../components/Portal';
import UserInfoInputFormAdmin from '../../components/Form/UserInfoInputFormAdmin';
import HeadInfo from './common3/headInfo';
import SelectBox from '../../components/SelectBox';
import ViewTable from '../../components/Table/ViewTable';
import BackButton from '../../components/BackButton';
import HeadManager from './common3/headManager';
import SalesPerson from './common3/salesPerson';
import DeleteModal from '../../components/Modal/DeleteModal2';
import { ynFilter } from '../../utils/ynFilter';
import { trimObject } from '../../utils/trimObject';
import { checkPhonNumEmpty } from '../../utils/checkPhonNumEmpty';
import { emailRegex, passwordRegex } from '../../utils/checkRegex';
import { checkUserInputRequiredValue } from '../../utils/checkUserInputRequiredValue';
import { apiDelete, apiGet, apiPatch, apiPost } from '../../services/_common';
import { logPost } from '../../services/log';
import { useFetchCommonCodeList } from '../../services/useSetCodeListInSelectBoxForm';

const Root = styled.div`
  &.hideRoot {
    visibility: hidden;
    position: absolute;
  }
  .inputForm-body {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  > .inputFormsWrapper {
    height: 3.5rem !important;
    flex-shrink: 0;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    padding: 0 0.5rem;
    border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    @media (min-width: 1024px) {
      padding: 0 1rem;
    }
  }
`;

interface IProps {
  type: string;
  typeName: string;
  gCd: 'HM' | 'AM';
}

const Component = ({ type, typeName, gCd }: IProps) => {
  const { t } = useTranslation();
  const size = useOutletContext<any>();
  const userInfo = useRecoilValue(userState);
  const userInfoInputFormRef = useRef<any>(null); // 인풋 폼 ref (테이블 로우를 클릭했을 때 바로 inputForm 으로 스크롤 이동시키기 위한 ref)
  const [searchOption, setSearchOption] = useState({ type: 'nameType', nameType: 'hName', cdName: t('본사명') });
  const [searchName, setSearchName] = useState({ hName: '', userName: '' });
  const [searchOptionUseYn, setSearchOptionUseYn] = useState(INIT_USE_YN_A);
  const [searchOptionLockYn, setSearchOptionLockYn] = useState(INIT_LOCK_YN_A);
  const [isNewAdd, setIsNewAdd] = useState(true); // 신규등록 여부
  const [isSettingPassword, setIsSettingPassword] = useState(false); // 패스워드 변경여부
  const [rowState, setRowState] = useState({
    userId: '',
    isDuplicateChecked: false,
    userName: '',
    hpNum: '',
    telNum: '',
    eMail: '',
    ipInfo: '',
    displayPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
    bigo: '',
    delYn: '',
    eDate: '',
    editor: '',
    lockYn: 'N',
    useYn: 'Y',
    userPwd: '',
    wDate: '',
    writer: '',
    loginerrorCount: 0,
    gCd, // 고정값,
    hCd: '',
    sCd: '00000', // 고정값,
    hName: '',
    gName: '',
  }); // 서버에서 받아 온 raw data 어레이 중 1줄의 데이터 state
  const [viewData, setViewData] = useState([{}]); // 서버에서 받아 온 raw data 중 viewTable에 뿌려줄 데이터만 담는 state
  const [userList, setUserList] = useState<any>([]);
  const [orgUserList, setOrgUserList] = useState<any>([]); // 필터링되지않은 유저리스트
  const [telNumState, setTelNumState] = useState<INumbers>({ num1: '', num2: '', num3: '' });
  const [hpNumState, setHpNumState] = useState<INumbers>({ num1: '010', num2: '', num3: '' });
  const [ipInfoState, setIpInfoState] = useState<INumbers>({ num1: '', num2: '', num3: '', num4: '' });
  const [hCd, setHCd] = useState({ hCd: '', cdName: '' });
  const [lockYn, setLockYn] = useState(INIT_LOCK_YN_N); // 잠금유무 공통코드
  const [useYn, setUseYn] = useState(INIT_USE_YN_Y); // 사용유무 공통코드
  const [headList, setHeadList] = useState<any[]>([]);
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [viewMainPage, setViewMainPage] = useState(true);
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const { data: lockYnComCdListWithAll } = useFetchCommonCodeList(COMCD_LOCK_YN, true); // 잠금여부 공통코드 목록 (검색조건에서 사용, 전체포함)
  const { data: useYnComCdListWithAll } = useFetchCommonCodeList(COMCD_USE_YN, true); // 사용여부 공통코드 목록 (검색조건에서 사용, 전체포함)
  const { data: lockYnComCdList } = useFetchCommonCodeList(COMCD_LOCK_YN, false); // 잠금유무 공통코드 목록 (사용자 정보에서 사용)
  const { data: useYnComCdList } = useFetchCommonCodeList(COMCD_USE_YN, false); // 사용유무 공통코드 목록 (사용자 정보에서 사용)

  useEffect(() => {
    getUserListAPI().then((res: any) => {
      if (res?.status === 200) {
        getHeadInfoAPI();
      }
    });
  }, []);

  useEffect(() => {
    if (type) {
      logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: type === 'head' ? '본사 관리 > 본사 관리자' : '본사 관리 > 영업 담당자',
        action: '조회',
        etc: ``,
      });
    }
  }, [type]);

  // 본사 리스트 조회 API
  const getHeadInfoAPI = async () => {
    const res = await apiGet({ path: '/menu/headInfo' });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const newArray = data.map((el: any) => ({ type: 'hCd', hCd: el.hCd, cdName: el.hName }));
      setHeadList(newArray);
    }
    return res;
  };

  // 로우데이터에서 뷰테이블에 보여줄 데이터만 뽑아서 배열에 담아 리턴
  const setDataIntoViewTable = (properties: any) => {
    const viewDataArray: any = [];
    const allKeys = Object.keys(userList[0]);
    userList?.map((el: any, i: number) => {
      const result = allKeys.reduce((next, key: any) => {
        if (properties.includes(key)) {
          return { ...next, [key]: userList[i][key] };
        }
        return next;
      }, {});
      return viewDataArray.push(result);
    });
    setViewData(viewDataArray);
  };

  useEffect(() => {
    // 도메인에 따라서 뷰테이블에 전달해야하는 키값만 viewTableProperties 배열안에 작성하면 됨.
    if (userList && userList.length > 0) {
      const viewTableProperties = ['hCd', 'hName', 'userId', 'userName', 'gName', 'lockYn', 'useYn'];
      setDataIntoViewTable(viewTableProperties);
    }
  }, [userList]);

  const applyFilter = (array: any[]) => {
    // 필터링 기준
    const filterOptions = {
      nameType: searchOption.nameType,
      userName: searchName.userName,
      hName: searchName.hName,
      useYn: searchOptionUseYn[COMCD_USE_YN],
      lockYn: searchOptionLockYn[COMCD_LOCK_YN],
    };

    // 필터링된 어레이 리턴, 대소문자구분X
    const filteredArray = array.filter((item: any) => {
      if (filterOptions.nameType === 'hName') {
        return item[searchOption.nameType]?.toLowerCase()?.includes(filterOptions.hName?.toLowerCase());
      }
      return item[searchOption.nameType]?.toLowerCase()?.includes(filterOptions.userName?.toLowerCase());
    });

    if ((searchOption.nameType === 'hName' && searchName.hName === '') || (searchOption.nameType === 'userName' && searchName.userName === '')) {
      const useYnFilter = ynFilter(array, 'useYn', searchOptionUseYn[COMCD_USE_YN]);
      const result = ynFilter(useYnFilter, 'lockYn', searchOptionLockYn[COMCD_LOCK_YN]);
      if (result.length > 0) setUserList(result);
      else setUserList([]);
    } else {
      const useYnFilter = ynFilter(filteredArray, 'useYn', searchOptionUseYn[COMCD_USE_YN]);
      const result = ynFilter(useYnFilter, 'lockYn', searchOptionLockYn[COMCD_LOCK_YN]);
      if (result.length > 0) setUserList(result);
      else setUserList([]);
    }
  };

  useEffect(() => {
    applyFilter(orgUserList);
  }, [searchOption.nameType, searchName.hName, searchName.userName, searchOptionLockYn[COMCD_LOCK_YN], searchOptionUseYn[COMCD_USE_YN]]);

  const userAuth = gCd === 'HM' ? 'head' : 'agent';

  // 신규등록 API
  const insertNewUserAPI = async (resultData: any) => {
    const trimData = trimObject(resultData);

    const req = {
      userId: trimData.userId,
      userName: trimData.userName,
      userPwd: trimData.newPassword,
      gCd: trimData.gCd,
      hCd: trimData.hCd,
      sCd: trimData.sCd,
      hpNum: trimData.hpNum,
      telNum: trimData.telNum,
      eMail: trimData.eMail,
      ipInfo: trimData.ipInfo,
      pwdchangeDate: trimData.pwdchangeDate,
      useYn: trimData.useYn,
      lockYn: trimData.lockYn,
      bigo: trimData.bigo,
      loginerrorCount: trimData.loginerrorCount || 0,
      approvalYn: 'Y',
      pwdCheckyn: 'N',
      writer: userInfo.userId,
      editor: userInfo.userId,
    };
    const res = await apiPost({ path: `/user/${userAuth}`, contentType: 'application/json', req });
    const { statusCode, message, data } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      const { userPwd, wDate, writer, eDate, editor, gName } = data[0];
      setRowState((prev) => ({ ...prev, displayPassword: rowState.newPassword, writer, wDate, editor, eDate, gName }));
      applyFilter(data[1].userList);
      setOrgUserList(data[1].userList);
      setIsNewAdd(false);
      switch (rowState.gCd) {
        case 'HM':
          await logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: '마스터일반관리-본사관리',
            action: '본사 관리자 신규등록',
            etc: `본사명 : ${resultData.hName} / 관리자아이디 : ${resultData.userId} / 관리자명 : ${resultData.userName}`,
          });
          break;
        case 'AM':
          await logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: '마스터일반관리-본사관리',
            action: '영업 담당자 신규등록',
            etc: `본사명 : ${resultData.hName} / 담당자아이디 : ${resultData.userId} / 담당자명 : ${resultData.userName}`,
          });
          break;
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 수정 API
  const updateUserAPI = async (resultData: any) => {
    const trimData = trimObject(resultData);
    const req = {
      userId: trimData.userId,
      userName: trimData.userName,
      userPwd: trimData.userPwd,
      gCd: trimData.gCd,
      hCd: trimData.hCd,
      sCd: trimData.sCd,
      hpNum: trimData.hpNum,
      telNum: trimData.telNum,
      eMail: trimData.eMail,
      ipInfo: trimData.ipInfo,
      pwdchangeDate: trimData.pwdchangeDate,
      useYn: trimData.useYn,
      lockYn: trimData.lockYn,
      bigo: trimData.bigo,
      // 본사관리자 수정이면, approvalYn,pwdCheckyn,loginerrorCount 값 포함, 영업담당자 수정이면 제외
      editor: userInfo.userId,
      ...(gCd === 'HM' && { approvalYn: trimData.approvalYn }),
      ...(gCd === 'HM' && { pwdCheckyn: trimData.pwdCheckyn }),
      ...(gCd === 'HM' && { loginerrorCount: trimData.loginerrorCount }),
    };
    const res = await apiPatch({ path: `/user/${userAuth}`, contentType: 'application/json', req });

    const { statusCode, message, data } = res.data;
    if (statusCode === 200) {
      setRowState({ ...data[0], displayPassword: data[0].userPwd });
      applyFilter(data[1].userList);
      setOrgUserList(data[1].userList);
      toast.success(t(message));

      switch (rowState.gCd) {
        case 'HM':
          await logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: '마스터일반관리-본사관리',
            action: '본사 관리자 수정',
            etc: `본사명 : ${resultData.hName} / 관리자아이디 : ${resultData.userId} / 관리자명 : ${resultData.userName}`,
          });
          break;
        case 'AM':
          await logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: '마스터일반관리-본사관리',
            action: '영업 담당자 수정',
            etc: `본사명 : ${resultData.hName} / 담당자아이디 : ${resultData.userId} / 담당자명 : ${resultData.userName}`,
          });
          break;
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 조회 API
  const getUserListAPI = async () => {
    const res = await apiGet({ path: `/user/${userAuth}` });
    const { status, data } = res;
    if (status === 200) {
      // 삭제되지 않은 유저만 필터링해서 setState
      const activeUsers = data.data.userList.filter((el: any) => el.delYn === 'N');
      setUserList(activeUsers);
      setOrgUserList(activeUsers);
    }
    return res;
  };

  const onClickDelete = async () => {
    setOpenModal((prev) => ({ ...prev, status: true, type: 'delete', api: deleteAPI }));
  };

  // 삭제 API
  const deleteAPI = async () => {
    const req = { userId: rowState.userId, editor: rowState.editor };
    const res = await apiDelete({ path: `/user/${userAuth}`, req });
    const { message, statusCode } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      // 삭제된로우 리스트에서 제거
      const newArray = userList.filter((el: any) => el.userId !== rowState.userId);
      setUserList(newArray);

      switch (rowState.gCd) {
        case 'HM':
          await logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: '마스터일반관리-본사관리',
            action: '본사 관리자 삭제',
            etc: `본사명 : ${rowState.hName} / 관리자아이디 : ${rowState.userId} / 관리자명 : ${rowState.userName}`,
          });
          break;
        case 'AM':
          await logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: '마스터일반관리-본사관리',
            action: '영업 담당자 삭제',
            etc: `본사명 : ${rowState.hName} / 담당자아이디 : ${rowState.userId} / 담당자명 : ${rowState.userName}`,
          });
          break;
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 저장버튼 클릭해서 save API 호출하여 데이터전송
  const onClickSave = async () => {
    setIsSaveClicked(true);
    const mergedHpNumber = `${hpNumState.num1}-${hpNumState.num2}-${hpNumState.num3}`;
    const mergedTelNumber = telNumState.num1 === '' && telNumState.num2 === '' && telNumState.num3 === '' ? '' : `${telNumState.num1}-${telNumState.num2}-${telNumState.num3}`;
    const mergedIpNumber =
      ipInfoState.num1 === '' && ipInfoState.num2 === '' && ipInfoState.num3 === '' && ipInfoState.num4 === '' ? '' : `${ipInfoState.num1}.${ipInfoState.num2}.${ipInfoState.num3}.${ipInfoState.num4}`;
    const resultData = { ...rowState, hpNum: mergedHpNumber, telNum: mergedTelNumber, ipInfo: mergedIpNumber };

    const { newPassword, newPasswordConfirm, eMail } = resultData;
    const { isUserInputRequiredValuePass, userInputRequiredValueMsg } = checkUserInputRequiredValue(resultData);
    const { isEmpty, emptyAlertMessage } = checkPhonNumEmpty(hpNumState);
    const { isEmailRegexPass, emailRegexAlertMsg } = emailRegex(eMail);
    if (resultData.hCd === '') return toast.warning(t('본사명을 선택하세요'));
    if (isNewAdd && !isUserInputRequiredValuePass) return toast.warning(t(userInputRequiredValueMsg));
    if (isSettingPassword) return toast.warning(t('비밀번호 변경버튼을 클릭하여 변경을 완료하세요'));
    if (isNewAdd || (!isNewAdd && isSettingPassword)) {
      const { isPasswordRegexPass, passwordRegexAlertMsg } = passwordRegex(newPassword, newPasswordConfirm);
      if (!isPasswordRegexPass) return toast.warning(t(passwordRegexAlertMsg));
    }
    if (isEmpty) return toast.warning(t(emptyAlertMessage));
    if (!isEmailRegexPass) return toast.warning(t(emailRegexAlertMsg));
    if (isNewAdd) return insertNewUserAPI(resultData);

    return updateUserAPI(resultData);
  };

  // 신규등록 버튼 클릭해서 새 입력창(iputForm) 노출
  const onClickNewRegistration = () => {
    initiateState();
    setIsNewAdd(true);
    setUseYn(INIT_USE_YN_Y);
    setLockYn(INIT_LOCK_YN_N);
    if (size.innerSize.W < 1024) {
      setViewMainPage(false);
    }
  };

  const backToMain = () => {
    setViewMainPage(true);
  };

  // 스테이트 객체의 밸류를 ''로 초기화(고정값인 gCd, sCd는 제외)
  const initiateState = () => {
    const keys = Object.keys(rowState);
    const filteredKeys = keys.filter((keyEl: string) => keyEl !== 'gCd' && keyEl !== 'sCd');
    filteredKeys.map((el) => {
      return setRowState((prev: any) => ({ ...prev, [el]: '', isDuplicateChecked: false, useYn: 'Y', lockYn: 'N' }));
    });

    // hCd 초기화
    setHCd({ hCd: '', cdName: '' });
    setIsSaveClicked(false);
    setHpNumState({ num1: '010', num2: '', num3: '' });
    setTelNumState({ num1: '', num2: '', num3: '' });
    setIpInfoState({ num1: '', num2: '', num3: '', num4: '' });
  };

  const onClickInitiateSearchOption = () => {
    setSearchOptionUseYn(INIT_USE_YN_A);
    setSearchOptionLockYn(INIT_LOCK_YN_A);
    setSearchOption({ type: 'nameType', nameType: 'hName', cdName: t('본사명') });
    setUseYn(INIT_USE_YN_Y);
    setLockYn(INIT_LOCK_YN_N);
    setSearchName({ hName: '', userName: '' });

    if (size.innerSize.W >= 1024) {
      onClickNewRegistration(); // 우측 인풋창도 초기화
    }
  };

  return (
    <div className={`content-container ${size.innerSize.W >= 1024 ? 'twoColumn column-55 max800' : 'oneColumn'}`}>
      <Root className={size.innerSize.W >= 1024 || viewMainPage ? 'showRoot' : 'hideRoot'}>
        <SearchOptions>
          <div className='inputsWrapper'>
            <div className='inputForm-row'>
              <div className='inputForm-col withLabelComCf'>
                <label htmlFor='useYn'>{t('잠금유무')}</label>
                <SelectBox
                  options={lockYnComCdListWithAll}
                  defaultOption={t('전체')}
                  state={searchOptionLockYn}
                  setState={setSearchOptionLockYn}
                  stateKey={COMCD_LOCK_YN}
                  initiateKey={searchOptionLockYn[COMCD_LOCK_YN]}
                  filterbar='filter-1-left'
                />
              </div>
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col withLabelComCf'>
                <label htmlFor='useYn'>{t('사용유무')}</label>
                <SelectBox
                  options={useYnComCdListWithAll}
                  defaultOption={t('전체')}
                  state={searchOptionUseYn}
                  setState={setSearchOptionUseYn}
                  stateKey={COMCD_USE_YN}
                  initiateKey={searchOptionUseYn[COMCD_USE_YN]}
                  filterbar='filter-1-left'
                />
              </div>
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col'>
                <SelectBox
                  options={type === 'head' ? SELECT_HNAME_OR_ADMINUSERNAME : SELECT_HNAME_OR_SALESUSERNAME}
                  defaultOption={searchOption.nameType === 'hName' ? t('본사명') : t('관리자명')}
                  state={searchOption}
                  setState={setSearchOption}
                  stateKey='nameType'
                  initiateKey={searchOption.nameType}
                  filterbar='filter-1-center'
                />
              </div>
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col'>
                <Input
                  label=''
                  placeholder={searchOption.nameType === 'hName' ? t('본사명') : t('관리자명')}
                  type='text'
                  id={searchOption.nameType === 'hName' ? 'hName' : 'userName'}
                  name={searchOption.nameType === 'hName' ? 'hName' : 'userName'}
                  state={searchName}
                  setState={setSearchName}
                />
              </div>
            </div>
          </div>
          <div className='inputsWrapper'>
            <div className='secondSearchOption'>
              <div className='flex-basic textBtnGroup'>
                <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
              </div>
              <div className='flex-basic iconBtnGroup'>
                <BtnGhost onClick={onClickNewRegistration}>
                  <span className='material-symbols-outlined'>add</span>
                  {t('등록')}
                </BtnGhost>
              </div>
            </div>
          </div>
        </SearchOptions>
        <div className='searchContent inputForm-body'>
          <ViewTable
            rawData={userList}
            state={viewData}
            setRowState={setRowState}
            setIsNewAdd={setIsNewAdd}
            setIsSettingPassword={setIsSettingPassword}
            userInfoInputFormRef={userInfoInputFormRef}
            typeName={typeName}
            setHCd={setHCd}
            setLockYn={setLockYn}
            setUseYn={setUseYn}
            setTelNumState={setTelNumState}
            setHpNumState={setHpNumState}
            setIpInfoState={setIpInfoState}
            setViewMainPage={() => setViewMainPage(false)}
          />
        </div>
      </Root>
      {(size.innerSize.W >= 1024 || !viewMainPage) && (
        <Root>
          <div className='inputFormsWrapper flexRowEm'>
            {size.innerSize.W < 1024 && <BackButton func={() => backToMain()} />}
            <div className='formTitle'>{isNewAdd ? `${t('새로운')} ${t(typeName)}` : `${t(typeName)} ${t('상세')}`}</div>
          </div>
          <UserInfoInputFormAdmin
            state={rowState}
            setState={setRowState}
            isNewAdd={isNewAdd}
            userInfoInputFormRef={userInfoInputFormRef}
            isSettingPassword={isSettingPassword}
            setIsSettingPassword={setIsSettingPassword}
            onClickNewAdd={onClickNewRegistration}
            onClickSave={onClickSave}
            onClickDelete={onClickDelete}
            typeName={typeName}
            telNumState={telNumState}
            setTelNumState={setTelNumState}
            hpNumState={hpNumState}
            setHpNumState={setHpNumState}
            ipInfoState={ipInfoState}
            setIpInfoState={setIpInfoState}
            hCd={hCd}
            setHCd={setHCd}
            useYn={useYn}
            setUseYn={setUseYn}
            lockYn={lockYn}
            setLockYn={setLockYn}
            hList={headList}
            lockYnComCdList={lockYnComCdList}
            useYnComCdList={useYnComCdList}
            isSaveClicked={isSaveClicked}
          />
        </Root>
      )}
      <Portal openModal={openModal?.status}>{openModal && openModal.type === 'delete' && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
    </div>
  );
};

const Mcommon3 = () => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue<IUser>(userState);
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);

  const onClickTab = (mrCd: string) => {
    setCurrentTabMrCd(mrCd);
  };

  const renderPage = () => {
    const activeTab = tabInfo.tabList?.find((el: ITabObject) => el.mrCd === currentTabMrCd);
    if (activeTab !== undefined) {
      const { mrCd } = activeTab;
      switch (mrCd) {
        case '001':
          return <HeadInfo />;
        case '002':
          return <HeadManager content={<Component type='head' typeName={t('본사 관리자')} gCd='HM' />} />;
        case '003':
          return <SalesPerson content={<Component type='agent' typeName={t('영업 담당자')} gCd='AM' />} />;
      }
    }
    return undefined;
  };

  return (
    <div className='contents'>
      <Tab tabList={tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      {renderPage()}
    </div>
  );
};

export default Mcommon3;
