/**
 *  2023.05.11 한영광
 *  본사소개 -> 본사정보 페이지 인풋폼
 *  pages/h_info.tsx 에서 사용
 */

import { useEffect, Dispatch, SetStateAction, useState, useRef } from 'react';
import styled from 'styled-components';
import { AddressSearch } from '../AddressSearch';
import { BtnBlue, BtnGhost, BtnGray as BtnRed } from '../Button';
import Input from '../Input';
import NumbersInput from '../NumbersInput';
import { applyBorderStyle } from '../../utils/applyBorderStyle';
import { readAndCompressImage } from 'browser-image-resizer';
import { useSetAuth } from '../../utils/useSetAuth';
import { SearchOptions } from '../../assets/styles/SearchOptions';
import SelectBox from '../SelectBox';
import { useTranslation } from 'react-i18next';
import illustrator from '../../assets/images/illustration/61.svg';
import { COMCD_JONGMOK, COMCD_UPTAE } from '../../_constants';
import LogoFileInput from '../LogoFileInput';

const ButtonsWrapper = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  justify-content: flex-end;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;
const Root = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  .title div {
    height: 2.5rem;
    display: flex;
    align-items: center;
    gap: 0;
    color: ${({ theme }: { theme: any }) => theme.text_primary};

    span {
      padding: 0.25rem;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
    }
    span::after {
      content: '•';
      margin-left: 0.25rem;
    }
  }
  .inputFormContainer {
    flex-grow: 1;
    overflow: auto;
    width: 100%;
  }
  .inputForm {
    width: 100%;
    padding: 1rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    @media (min-width: 1280px) {
      padding: 1rem 3rem;
      width: 64rem;
    }
  }
  .setting-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 2rem;
    row-gap: 1rem;
    padding: 1rem 0;
    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .setting-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 2.5rem;
    > div:nth-child(2) {
      font-size: 0.875rem;
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      border-radius: 0.25rem;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0.5rem 0.75rem;
    }
    > div:first-child > div {
      gap: 0;
      flex-direction: row;
      align-items: center;
      label {
        width: 8rem;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
      }
      > input {
        flex-grow: 1;
        flex-shrink: 1;
        width: 50%;
      }
    }
    div.flex-basic {
      background-color: transparent;
      outline: none;
      padding: 0;
      input {
        width: 4rem;
        font-size: 0.875rem;
        padding: 0;
      }
    }
    @media (min-width: 768px) {
    }
    label {
      font-weight: 500;
      font-size: 0.875rem;
      width: 8rem;
      flex-shrink: 0;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
    }
    > div {
      flex-grow: 1;
      ul li {
        max-width: 100%;
        width: 100%;
        span {
          width: 100%;
        }
      }
    }
    input[type='text'] {
      height: 2.5rem;
      border-radius: 0.25rem;
      font-size: 0.875rem;
    }
    .setting-row-content {
      display: flex;
      align-items: center;
      > span {
        padding: 0 0.5rem;
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
      }
      input[type='number'] {
        height: 2.5rem;
        border-radius: 0.25rem;
        font-size: 0.875rem;
        text-align: center;
        width: 4rem;
      }
      .setting-row-element {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 0.875rem;
      }
    }
    .setting-row-content.time {
      gap: 1rem;
    }
    .setting-row-content-group {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      @media (min-width: 640px) {
        display: flex;
        align-items: center;
      }
    }
  }
  .setting-row.withSelector {
    > div:nth-child(2) {
      padding: 0;
      > div {
        width: 100%;
        li {
          width: 100%;
          max-width: 100%;
          > span {
            width: 100%;
          }
        }
      }
    }
  }
  .setting-row.image-wrap {
    grid-row: span 5;
    display: flex;
    /* flex-direction: column; */
    /* justify-content: center; */
    width: 100%;
    height: 100%;
    max-height: 16.5rem;
    gap: 0.5rem;
    overflow: hidden;
    user-select: none;
    .titleName {
      width: 7.5rem;
      font-weight: 500;
      flex-shrink: 0;
      font-size: 0.875rem;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
    }
    .logo-input-container {
      flex-grow: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
      padding: 0.5rem;
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      .description {
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.filled_amber_deep};
        display: flex;
        align-items: center;
        gap: 0.25rem;
        .material-symbols-rounded {
          color: ${({ theme }: { theme: any }) => theme.filled_orange};
          font-size: 1.25rem;
        }
      }
    }
  }
  .setting-row.widthAddress {
    > div:nth-child(2) {
      padding: 0;
      background-color: transparent;
      outline: none;
      > div {
        width: 50%;
        /* flex-shrink: 1; */
        flex-grow: 1;
      }
      input {
        width: 100%;
      }
    }
    > div.inputWidthBtn {
      display: flex;
      gap: 0.5rem;
      > div > div {
        flex-direction: row;
      }
    }
    button {
      width: 6rem;
      padding: 0;
      height: 2.5rem;
      font-size: 0.875rem;
    }
  }
  .false {
    button {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;
    }
    > div:nth-child(2) {
      pointer-events: none;
      outline: none;
      li {
        color: ${({ theme }: { theme: any }) => theme.text_disabled};
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
      }
    }
  }
  .setting-row.datePickerWrapper {
    > div:nth-child(2) {
      padding: 0;
      > div {
        display: flex;
      }
    }
    input {
      width: 50%;
      flex-grow: 1;
      font-size: 0.875rem;
    }
  }

  .buttonsWrapper {
    justify-content: flex-end;
  }

  input {
    :disabled {
      color: ${({ theme }: { theme: any }) => theme.text_disabled};
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
    }
  }

  .grid {
    display: grid;
    margin-bottom: 1rem;
    align-items: center;
    min-height: 3.4rem;
  }

  .grid100,
  .grid100T,
  .grid60,
  .grid50,
  .grid30 {
    > div > div {
      display: grid;
      grid-template-columns: 9rem 1fr;
      align-items: center;
    }
  }

  .grid100T {
    grid-template-columns: 9rem 1fr;
  }

  .grid50,
  .grid60 {
    grid-template-columns: 1fr 1fr;
  }

  .grid50 {
    grid-gap: 1rem;
  }

  .grid30 {
    grid-template-columns: 1.5fr 0.7fr 0.8fr;
  }

  .plainText {
    grid-template-columns: 9rem 1fr;

    > :last-child {
      display: block;
    }
  }

  .marginBottomNone {
    margin-bottom: 0;
  }

  @media screen and (max-width: 1439px) {
    .plainText {
      grid-template-columns: 9rem 1fr;

      > div {
        display: block !important;
        margin-bottom: 0 !important;
      }
    }

    .grid50 {
      grid-template-columns: 1fr;
      > small {
        display: grid;
        grid-template-columns: 1fr;
      }

      > div > div {
      }
    }

    .grid60 {
      grid-template-columns: 0.7fr 0.3fr;
      > div > button {
        width: 100%;
      }
    }
  }

  .addressWrapper {
    > div {
      margin-bottom: 1rem;
    }
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    /* padding-top: 5rem; */
    height: fit-content;
    overflow: none;
  }
`;

type Logo = { light: string; dark: string };
type UpdatedLogoState = 'N' | 'D' | 'U';
type IsUpdatedLogoImage = { light: UpdatedLogoState; dark: UpdatedLogoState };

interface IUserInputForm {
  state: any;
  setState: Dispatch<SetStateAction<any>>;
  uptae: any;
  setUptae: Dispatch<SetStateAction<any>>;
  jongmok: any;
  setJongmok: Dispatch<SetStateAction<any>>;
  hTelState: any;
  setHTelState: Dispatch<SetStateAction<any>>;
  hNumState: any;
  setHNumState: Dispatch<SetStateAction<any>>;
  disabled: boolean;
  userInfoInputFormRef: React.RefObject<any>;
  onClickSave: () => void;
  onClickUpdate: () => void;
  onClickCancel: () => void;
  logoImage: Logo;
  setLogoImage: Dispatch<SetStateAction<Logo>>;
  setTypeOfLogoImage: Dispatch<SetStateAction<Logo>>;
  setIsUpdatedLogoImage: Dispatch<SetStateAction<IsUpdatedLogoImage>>;
  isUpdatedLogoImage: IsUpdatedLogoImage;
  uptaeComCdList: any;
  jongmokComCdList: any;
  hNameRef: React.RefObject<HTMLInputElement>;
  isSaveClicked: boolean;
}

const HeadInfoInputForm = (props: IUserInputForm) => {
  const {
    state,
    setState,
    uptae,
    setUptae,
    jongmok,
    setJongmok,
    hTelState,
    setHTelState,
    hNumState,
    setHNumState,
    disabled,
    userInfoInputFormRef,
    onClickSave,
    onClickUpdate,
    onClickCancel,
    logoImage,
    setLogoImage,
    setTypeOfLogoImage,
    setIsUpdatedLogoImage,
    isUpdatedLogoImage,
    uptaeComCdList,
    jongmokComCdList,
    hNameRef,
    isSaveClicked,
  } = props;

  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const hCeonameRef = useRef<HTMLInputElement>(null);
  const [hNumLastInput, setHNumLastInput] = useState(false);

  useEffect(() => {
    if (hNumLastInput) hCeonameRef.current?.focus();
  }, [hNumLastInput]);

  // 로고 테마에 따른 상태값을 설정합니다.
  const [lightThemeLogo, setLightThemeLogo] = useState<string>('');
  const [darkThemeLogo, setDarkThemeLogo] = useState<string>('');
  const [typeOfLightLogoImage, setTypeOfLightLogoImage] = useState<any | null>();
  const [typeOfDarkLogoImage, setTypeOfDarkLogoImage] = useState<any | null>();
  const [isUpdatedLightThemeLogo, setIsUpdatedLightThemeLogo] = useState<UpdatedLogoState>(isUpdatedLogoImage.light);
  const [isUpdatedDarkThemeLogo, setIsUpdatedDarkThemeLogo] = useState<UpdatedLogoState>(isUpdatedLogoImage.dark);

  // // useEffect를 사용하여 로고 상태를 업데이트합니다.
  useEffect(() => {
    setLightThemeLogo(logoImage.light);
    setDarkThemeLogo(logoImage.dark);
  }, [logoImage.light, logoImage.dark]);

  // useEffect를 사용하여 로고 상태를 업데이트합니다.
  useEffect(() => {
    setLogoImage({ light: lightThemeLogo, dark: darkThemeLogo });
    setTypeOfLogoImage({ light: typeOfLightLogoImage, dark: typeOfDarkLogoImage });
    setIsUpdatedLogoImage({ light: isUpdatedLightThemeLogo, dark: isUpdatedDarkThemeLogo });
  }, [lightThemeLogo, darkThemeLogo, typeOfLightLogoImage, typeOfDarkLogoImage, isUpdatedDarkThemeLogo, isUpdatedLightThemeLogo]);

  // 전화번호 및 사업자등록번호 입력 길이를 정의합니다.
  const hTelLengthState = { num1: 3, num2: 4, num3: 4 }; // 전화번호 입력 길이
  const hNumLengthState = { num1: 3, num2: 2, num3: 5 }; // 사업자 등록번호 입력 길이

  return (
    <Root>
      <SearchOptions align='left'>
        <div className='inputsWrapper'>
          <div className='secondSearchOption title'>
            <div>
              <span>{t('본사명')}</span>
              {state.hName}
            </div>
          </div>
        </div>
      </SearchOptions>
      <div className='inputFormContainer'>
        <div className='inputForm' ref={userInfoInputFormRef}>
          <div className='setting-grid'>
            <div className='setting-row image-wrap col-span-img'>
              <span className='titleName'>{t('회사 로고')}</span>
              <div className='logo-input-container'>
                <LogoFileInput theme='light' logo={lightThemeLogo} setLogo={setLightThemeLogo} setLogoType={setTypeOfLightLogoImage} isUpdatedLogo={setIsUpdatedLightThemeLogo} disabled={disabled} />
                <LogoFileInput theme='dark' logo={darkThemeLogo} setLogo={setDarkThemeLogo} setLogoType={setTypeOfDarkLogoImage} isUpdatedLogo={setIsUpdatedDarkThemeLogo} disabled={disabled} />
                <span className='description'>
                  <span className='material-symbols-rounded'>info</span>
                  {t('svg 파일 사용을 권장합니다')}
                </span>
              </div>
            </div>
            <div className='setting-row'>
              <label htmlFor='auth'>{t('코드')}</label>
              <div className='input'>{state.hCd}</div>
            </div>
            <div className='setting-row noGrid'>
              <Input
                className='required'
                label={t('본사명')}
                type='text'
                id='hName'
                name='hName'
                state={state}
                setState={setState}
                disabled={disabled}
                inputRef={hNameRef}
                getBorderStyle={isSaveClicked ? applyBorderStyle(state.hName, 'red', 'hName') : undefined}
              />
            </div>
            <div className='setting-row'>
              <label htmlFor='hNum'>{t('사업자 번호')}</label>
              <NumbersInput state={hNumState} setState={setHNumState} lengthState={hNumLengthState} setIsLastInputCompleted={setHNumLastInput} disabled={disabled} />
            </div>
            <div className='setting-row noGrid'>
              <Input inputRef={hCeonameRef} label={t('대표자명')} type='text' id='hCeoname' name='hCeoname' state={state} setState={setState} disabled={disabled} />
            </div>
            <div className='setting-row'>
              <label htmlFor='hTel'>{t('대표 전화번호')}</label>
              <NumbersInput state={hTelState} setState={setHTelState} lengthState={hTelLengthState} disabled={disabled} />
            </div>
            <div className={`setting-row withSelector ${!disabled && 'editMode'}`}>
              <label className='required' htmlFor='uptae'>
                {t('업태')}
              </label>
              <div>
                <SelectBox
                  options={uptaeComCdList}
                  defaultOption={state.uptaeName}
                  state={uptae}
                  setState={setUptae}
                  stateKey={COMCD_UPTAE}
                  initiateKey={uptae[COMCD_UPTAE]}
                  disabled={disabled}
                  getBorderStyle={isSaveClicked ? applyBorderStyle(uptae[COMCD_UPTAE], 'red', 'subCd') : undefined}
                />
              </div>
            </div>
            <div className={`setting-row withSelector ${!disabled && 'editMode'}`}>
              <label htmlFor='jongmok'>{t('종목')}</label>
              <div>
                <SelectBox
                  options={jongmokComCdList}
                  defaultOption={jongmok.cdName}
                  state={jongmok}
                  setState={setJongmok}
                  stateKey={COMCD_JONGMOK}
                  initiateKey={jongmok[COMCD_JONGMOK]}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className={`setting-row widthAddress ${!disabled && 'editMode'}`}>
              <label htmlFor={t('우편번호')}>{t('우편번호')}</label>
              <div className='inputWidthBtn'>
                <Input type='number' id='postNum' name='postNum' state={state} setState={setState} placeholder={t('우편번호')} disabled />
                <AddressSearch setState={setState} disabled={disabled} />
              </div>
            </div>
            <div className='setting-row widthAddress' />
            <div className='setting-row widthAddress'>
              <label htmlFor={t('주소')}>{t('주소')}</label>
              <Input type='address' id='address1' name='address1' state={state} setState={setState} placeholder={t('주소')} disabled />
            </div>
            <div className='setting-row widthAddress'>
              <label htmlFor={t('상세주소')}>{t('상세주소')}</label>
              <Input type='text' id='address2' name='address2' state={state} setState={setState} placeholder={t('상세주소')} disabled={disabled} maxLength={200} />
            </div>
          </div>
          <div className='setting-row noGrid'>
            <Input label={t('비고')} type='text' id='bigo' name='bigo' state={state} setState={setState} disabled={disabled} maxLength={200} />
          </div>
          <div className='setting-grid'>
            <div className='setting-row'>
              <label htmlFor='createDate'>{t('등록일자')}</label>
              <div>{state?.wDate}</div>
            </div>
            <div className='setting-row'>
              <label htmlFor='createUser'>{t('등록자')}</label>
              <div>{state.writer}</div>
            </div>
            <div className='setting-row'>
              <label htmlFor='updateDate'>{t('수정일자')}</label>
              <div>{state.eDate}</div>
            </div>
            <div className='setting-row'>
              <label htmlFor='updateUser'>{t('수정자')}</label>
              <div>{state.editor}</div>
            </div>
          </div>
        </div>
      </div>
      <ButtonsWrapper>
        {!disabled ? (
          <>
            <BtnGhost onClick={onClickCancel}>{t('취소')}</BtnGhost>
            <BtnBlue onClick={onClickSave}>{t('저장')}</BtnBlue>
          </>
        ) : auth.createAuth || auth.updateAuth ? (
          <BtnBlue onClick={onClickUpdate}>{t('수정')}</BtnBlue>
        ) : null}
      </ButtonsWrapper>
    </Root>
  );
};

export default HeadInfoInputForm;
