import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ButtonContainer from '../../s_cctv/S_cctv1/RealtimeCCTV/ButtonContainer';
import ClickableContainer from '../../s_cctv/S_cctv1/RealtimeCCTV/ClickableContainer';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 24rem;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .label {
      font-size: 0.875rem;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      font-weight: 600;
    }
    .control-container {
      display: flex;
      align-items: center;
      .value-wrapper {
        font-size: 0.875rem;
        min-width: 2.5rem;
        display: flex;
        justify-content: center;
        margin: 0 0.5rem;
        color: ${({ theme }: { theme: any }) => theme.text_disabled};
        .value {
          font-weight: 600;
          font-size: 1rem;
          color: ${({ theme }: { theme: any }) => theme.text_secondary};
          margin-right: 0.125rem;
        }
      }
    }
  }
  input {
    padding: 0;
    height: 2.5rem;
    &:focus {
      outline: none;
    }
  }
`;

interface Props {
  handleSlider: (e: React.ChangeEvent<HTMLInputElement>, dataKey: string) => void;
  dataKey: string;
  data: string;
  labelName: string;
  maxValue: number;
}

const SliderSettings = ({ handleSlider, dataKey, data, labelName, maxValue }: Props) => {
  const { t } = useTranslation();

  const handleIncrement = () => {
    if (Number(data) < maxValue) {
      const event = { target: { value: Number(data) + 1 } } as unknown;
      handleSlider(event as React.ChangeEvent<HTMLInputElement>, dataKey);
    }
  };

  const handleDecrement = () => {
    if (Number(data) > 1) {
      const event = { target: { value: Number(data) - 1 } } as unknown;
      handleSlider(event as React.ChangeEvent<HTMLInputElement>, dataKey);
    }
  };

  return (
    <Root>
      <div className='header'>
        <span className='label'>{labelName}</span>
        <div className='control-container'>
          <ClickableContainer
            button={<ButtonContainer icon='remove' />} //
            onClick={handleDecrement}
            disabled={Number(data) === 1}
          />
          <span className='value-wrapper'>
            <span className='value'>{data}</span>
            {t('초')}
          </span>
          <ClickableContainer
            button={<ButtonContainer icon='add' />} //
            onClick={handleIncrement}
            disabled={Number(data) === Number(maxValue)}
          />
        </div>
      </div>
      <input
        type='range' // 슬라이더 타입
        min='1' // 최소값
        max={maxValue.toString()} // 최대값
        step='1' // 슬라이더 값 변경 단위
        value={data} // 슬라이더 값
        onChange={(e) => handleSlider(e, dataKey)} // 슬라이더 값 변경 이벤트
      />
    </Root>
  );
};

export default SliderSettings;
