/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.25
 * 경로 : 구역별 상황판 > 유해가스 종합현황 (원형, 수치형)
 */

import { useQuery } from '@tanstack/react-query';
import { apiGet } from '../../services/_common';
import { useTranslation } from 'react-i18next';
import { PulseLoader } from 'react-spinners';
import React from 'react';
import styled from 'styled-components';
import SensorTable from './MGasInfoWithTatCd/SensorTable';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  user-select: none;
  > .title {
    height: 2rem;
    width: 100%;
    padding: 0 1rem;
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: space-between;
    .name {
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      font-weight: ${({ theme }: { theme: any }) => theme.font_semibold};
      font-size: 1.125rem;
      line-height: 1.2;
    }
    .legendGroup {
      display: flex;
      gap: 0.25rem;
      .legend {
        padding: 0 0.375rem;
        border-radius: 0.25rem;
        font-size: 0.875rem;
      }
    }
  }
  .blue {
    color: ${({ theme }: { theme: any }) => theme.filled_blue};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
  }
  .red {
    color: ${({ theme }: { theme: any }) => theme.filled_red};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
  }
  .violet {
    color: ${({ theme }: { theme: any }) => theme.filled_violet};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_violet};
  }
  .orange {
    color: ${({ theme }: { theme: any }) => theme.filled_orange};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
  }
  .green {
    color: ${({ theme }: { theme: any }) => theme.filled_green};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
  }
  .amber {
    color: ${({ theme }: { theme: any }) => theme.filled_amber};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_amber};
  }
  .slate {
    color: ${({ theme }: { theme: any }) => theme.filled_slate};
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
  }
`;

type Props = {
  name: string;
  userInfo: any;
  valueType?: 'shape' | 'number';
  tatCd: string;
};

const MGasInfoWithTatCd = ({ name, userInfo, valueType = 'shape', tatCd }: Props) => {
  const { t } = useTranslation();

  const defaultParams = { hCd: userInfo.hCd, sCd: userInfo.sCd };
  const tatCdParams = { ...defaultParams, tatCd };

  const request = {
    tareaList: { queryKey: `tareaSensorGetInArea_${valueType}`, endPoint: '/tarea/t/sensor', params: tatCdParams },
    sensorList: { queryKey: `sensorUListGetInArea_${valueType}`, endPoint: '/sensor/ulist', params: defaultParams },
  };

  const fetchData = (url: string, req: any) => () => apiGet({ path: url, req }).then((res) => res.data.data);

  const sensorQueryKey = [request.sensorList.queryKey, userInfo.hCd, userInfo.sCd];
  const tareaQueryKey = [request.tareaList.queryKey, userInfo.hCd, userInfo.sCd];

  const sensorQuery = useQuery(sensorQueryKey, fetchData(request.sensorList.endPoint, request.sensorList.params), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
    refetchInterval: 60000,
    staleTime: 30000,
  });

  const tareaQuery = useQuery(tareaQueryKey, fetchData(request.tareaList.endPoint, request.tareaList.params), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
    refetchInterval: 60000,
    staleTime: 30000,
  });

  const isLoading = sensorQuery.isLoading || tareaQuery.isLoading;
  const isError = sensorQuery.isError || tareaQuery.isError;

  if (isLoading) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  if (isError) {
    console.error('API 호출 중 오류 발생:', sensorQuery.isError ? sensorQuery.data : tareaQuery.data);
  }

  return (
    <Root>
      <div className='title'>
        <span className='name'>{t('유해가스 현황')}</span>
        <div className='legendGroup'>
          <span className='legend blue'>{t('좋음')}</span>
          <span className='legend green'>{t('주의')}</span>
          <span className='legend amber'>{t('위험')}</span>
          <span className='legend red'>{t('경보')}</span>
        </div>
      </div>
      <SensorTable sensorList={sensorQuery?.data?.sensorUList} tareaData={tareaQuery?.data?.tareaList} valueType={valueType} />
    </Root>
  );
};

export default React.memo(MGasInfoWithTatCd);
