import styled from 'styled-components';

const PTZSmallCapsuleButton = styled.div`
  &.material-symbols-rounded {
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    font-variation-settings: 'FILL' 1;
    font-size: 1.375rem;
    width: 3rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    &:hover {
      cursor: pointer;
      color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep_blue};
      &:active {
        color: ${({ theme }: { theme: any }) => theme.filled_blue};
        background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
      }
    }
    &.left {
      border-radius: 1rem 0 0 1rem;
      border-right: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    }
    &.right {
      border-radius: 0 1rem 1rem 0;
    }
  }
`;

type PTZCapsuleButtonProps = { position: 'left' | 'right'; data: { mouseDown: () => void; icon: string }; mouseUp: () => void };

const PTZCapsuleButton = ({ data, position, mouseUp }: PTZCapsuleButtonProps) => {
  return (
    <PTZSmallCapsuleButton //
      className={`material-symbols-rounded ${position}`}
      onMouseDown={data.mouseDown}
      onMouseUp={mouseUp}
      role='presentation'
    >
      {data.icon}
    </PTZSmallCapsuleButton>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .capsuleName {
    letter-spacing: initial;
    font-size: 0.75rem;
    font-weight: 600;
    user-select: none;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
  }
  .capsuleContainer {
    display: flex;
  }
`;

type Props = {
  data: {
    name: string; //
    title: string;
    left: { mouseDown: () => void; icon: string };
    right: { mouseDown: () => void; icon: string };
    mouseUp: () => void;
  };
};

const PTZInteractionControlCapsule = ({ data }: Props) => {
  return (
    <Root>
      <span className='capsuleName'>{data.name}</span>
      <div className='capsuleContainer'>
        <PTZCapsuleButton data={data.left} position='left' mouseUp={data.mouseUp} />
        <PTZCapsuleButton data={data.right} position='right' mouseUp={data.mouseUp} />
      </div>
    </Root>
  );
};

export default PTZInteractionControlCapsule;
