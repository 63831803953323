/**
 * 작성자 : 홍선영
 * 날짜 : 2024.03.22
 * 기능 : 스트림타입에 따라 아이콘 내부 글자를 노출하는 아이콘
 */

import styled from 'styled-components';
import ClickExpandableContainer from '../../pages/s_cctv/S_cctv1/RealtimeCCTV/ClickExpandableContainer';
import { mainStream, subStream, thirdStreamInShort } from './DarkButtons';
import { useEffect, useState } from 'react';
import ButtonContainer from '../../pages/s_cctv/S_cctv1/RealtimeCCTV/ButtonContainer';

const ResolutionButtonStyle = styled.div`
  margin: 0.2rem 0;

  > div > .options {
    /* box-shadow: 0 0.25rem 0.75rem 0.5rem rgba(0, 0, 0, 0.35); */
  }

  .darkModeOnly {
    /* width: 3.2rem; */
    /* > :first-child {
      display: none;
    } */
  }
`;

interface IResolutionButton {
  stream: string;
  onClick: (streamType: string) => void;
  disabled?: boolean;
}

type Option = { key: string; button: React.ReactNode; value: string };

// 스트림 상태
const streamOptions = [
  { key: 'main', button: mainStream, value: '1' },
  { key: 'sub', button: subStream, value: '2' },
  // { key: 'third', button: thirdStreamInShort, value: '3' },
];

const ResolutionButton = ({ stream, onClick, disabled = false }: IResolutionButton) => {
  const [streamState, setStreamState] = useState<Option>(streamOptions?.find((el) => el.value === stream) || streamOptions[1]);

  useEffect(() => {
    const streamObj = streamOptions?.find((el) => el.value === stream);
    if (streamObj) setStreamState(streamObj);
  }, [stream]);

  useEffect(() => {
    if (stream !== streamState.value) onClick(streamState.value);
  }, [streamState.value]);

  const defaultStream = <ButtonContainer icon={streamState.value === '1' ? 'looks_one' : 'looks_two'} />;

  return (
    <ResolutionButtonStyle>
      <ClickExpandableContainer // 스트림
        defaultOption={defaultStream}
        options={streamOptions}
        state={streamState}
        setState={setStreamState}
        direction='toLeft'
        disabled={disabled}
        boxShadow={false}
      />
    </ResolutionButtonStyle>
  );
};
export default ResolutionButton;
