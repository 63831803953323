/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.15
 * 경로 : 대시보드 > 터널 종합 진행 현황
 */

import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import Carousel from 'react-multi-carousel';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-multi-carousel/lib/styles.css';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CustomDot } from '../dashboard/Carousel';
import MultiTunnelTotalInfoCard from './MultiTunnelTotalInfoCard';
import { PulseLoader } from 'react-spinners';

const TunnelsContainer = styled.div<{ tunnelLength?: number }>`
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 0 0.5rem;
  display: flex;
  gap: 0.5rem;
  overflow: auto;
  flex-grow: 1;
  &.noOverFlow {
    overflow: hidden;
    > div {
      overflow: hidden auto;
    }
  }
  &.grid {
    display: grid;
    grid-template-columns: repeat(${(props) => props.tunnelLength}, 1fr);
  }
  .tunnels {
    width: 100%;
    align-items: baseline;
  }
`;

type Props = {
  tunnelElements: { title: string; status: boolean; name: string; icon: string }[];
  getTunnelLengths: any;
  data: any;
  size: 'medium' | 'large';
  autoPlay: boolean;
  siteDashboard: boolean;
};

const MultiTunnelTotalInfo = ({ tunnelElements, getTunnelLengths, data, size, autoPlay, siteDashboard }: Props) => {
  const [carouselKey, setCarouselKey] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const carouselRef = useRef<Carousel>(null);

  // useCallback을 사용하여 containerWidth 계산 함수 최적화
  const calculateContainerWidth = useCallback(() => {
    return carouselRef?.current?.containerRef?.current?.offsetWidth;
  }, []);
  const containerWidth = calculateContainerWidth();

  // 터널 수에 따라 carousel에 display할 아이템갯수 구하는 함수
  const calculateCarouselItemCount = useCallback(() => {
    if (size === 'large') {
      if (data.length >= 3) return 3;
      return data.length;
    }
    {
      if (data.length >= 2) return 2;
      return data.length;
    }
  }, [size, data.length]);
  const carouselCount = calculateCarouselItemCount();

  const responsive = useMemo(
    () => ({
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: carouselCount,
        slidesToSlide: 1,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: size === 'large' ? 2 : 2,
        slidesToSlide: 1,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1,
      },
    }),
    [size]
  );

  useEffect(() => {
    if (data) {
      setIsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (autoPlay && !siteDashboard) {
      setCarouselKey((prevKey) => prevKey + 1);
    }
  }, [autoPlay, siteDashboard]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className='loader-background'>
          <PulseLoader color='rgb(0, 122, 255)' size='10px' />
        </div>
      );
    }
    return (
      <Carousel
        key={carouselKey}
        ref={carouselRef}
        swipeable
        draggable
        responsive={responsive}
        infinite
        autoPlay={autoPlay}
        autoPlaySpeed={5000}
        keyBoardControl
        transitionDuration={500}
        containerClass='carousel-container'
        removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
        dotListClass='custom-dot-list-style'
        showDots
        className='tunnels'
        customDot={<CustomDot mergedData={data} />}
      >
        {data.map((tunnel: any, index: number) => (
          <MultiTunnelTotalInfoCard
            tunnelData={tunnel}
            key={index}
            filter={tunnelElements}
            dashboard
            chartType='circle'
            carouselWidth={containerWidth || 0}
            siteDashboard={siteDashboard}
            tunnelRawDataList={data}
          />
        ))}
      </Carousel>
    );
  };

  return <TunnelsContainer tunnelLength={getTunnelLengths.length || 0}>{renderContent()}</TunnelsContainer>;
};

export default MultiTunnelTotalInfo;
