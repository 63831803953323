/**
 * 작성자 : 한영광
 * 날짜 : 2023.12.12
 * 기능 : WebSDK 클래스
 */
import { apiGet } from '../services/_common';
import * as speaker from './speaker';

interface Speaker {
  index: number;
  ip: string;
  port: string;
  id: string;
  pwd: string;
}

export class WebSDKspeaker {
  index: number;
  status: number; // 0: 연결중, 1: 대기중, 2: 방송중, 3: 방송불가
  flow: number;
  setOpenModal: any;
  data: Speaker;
  stateChangeListener: any;
  pluginOBJ: any;

  constructor(setOpenModal: any, index: number, stateChangeListener: any, data: any) {
    this.index = index;
    this.status = 0;
    this.flow = 0;
    this.setOpenModal = setOpenModal;
    this.data = data;
    this.stateChangeListener = stateChangeListener;
    // this.init();
  }

  getStatus() {
    return this.status;
  }
  setStatus(status: number) {
    if (this.stateChangeListener) {
      this.stateChangeListener({ index: this.index, status });
    }
    this.status = status;
  }

  getFlow() {
    return this.flow;
  }
  setFlow(flow: number) {
    this.flow = flow;
    this.next();
  }

  next() {
    if (this.flow === 1) {
      this.login();
    }
  }

  getData() {
    return this.data;
  }

  /**
   * 작성자 : 한영광
   * 작성일자 : 2024.01.19
   * 작성내용 : 기존 WebSDK는 여러 장비에 동시에 연결한 경우 가장 최근 장비의 데이터만 유지되어 이전에 연결한 장비의 제어가 안되는 문제가 발생함
   *           따라서 WebSDK 플러그인에 연결시 해당 연결을 객체화 하여 여러 장비의 연결을 한 경우에도 각각 제어할 수 있도록 작업함
   */
  setPluginOBJ(obj: any) {
    this.pluginOBJ = obj;
  }

  getPluginOBJ() {
    return this.pluginOBJ;
  }

  async init() {
    // await this.beforeInitStop();
    await speaker.init(
      this.setOpenModal,
      // (e: any) => this.setFlow(e),
      (e: any) => this.setStatus(e),
      (e: any) => this.setPluginOBJ(e)
    );
  }

  async login() {
    await speaker
      .login(this.data.ip, this.data.port, this.data.id, this.data.pwd)
      .then(async (r) => {
        await this.connChk();
      })
      .catch((e) => {
        this.setStatus(e);
      });
  }

  logout() {
    speaker.logout(`${this.data.ip}_${this.data.port}`);
  }

  async connChk() {
    await speaker
      .clickStartVoiceTalk(`${this.data.ip}_${this.data.port}`)
      .then(async (r) => {
        this.setStatus(1);
        await this.stop();
      })
      .catch((e) => {
        console.log(e);
        this.setStatus(3);
      });
  }

  start() {
    speaker
      .clickStartVoiceTalk(
        `${this.data.ip}_${this.data.port}`
        // (e: any) => this.setStatus(e)
      )
      .then((r) => {
        this.setStatus(r);
      })
      .catch((e) => {
        this.setStatus(e);
      });
  }

  async stop() {
    await this.reqCloseAPI();
    await speaker
      .clickStopVoiceTalk(this.status)
      .then((r) => {
        this.setStatus(r);
      })
      .catch((e) => {
        this.setStatus(e);
      });
  }

  async beforeInitStop() {
    await this.reqCloseAPI();
    await speaker.clickStopVoiceTalk(this.status);
  }

  async reqCloseAPI() {
    await apiGet({ path: '/bs/isapi', req: { url: `http://${this.data.ip}:${this.data.port}/ISAPI/System/TwoWayAudio/channels/1/close`, method: 'PUT', id: this.data.id, pwd: this.data.pwd } });
  }
}
