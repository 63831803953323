/**
 * 작성자 : 홍선영
 * 날짜 : 2023.08.02
 * 기능 : 저장을 클릭한 후 필수입력값이 빈값이거나, 프로퍼티별 정규식이 일치하지 않는경우 보더 표시
 *
 */

import { emailRegex, passwordOnlyRegex } from './checkRegex';
import { isValidYYYYMMDD } from './formatDate';

export function applyBorderStyle(data: any, borderColor: string, property: string, newPassword?: string): React.CSSProperties {
  // 값이 비었을 경우 보더처리
  if (data === '') {
    // return { border: `1px solid ${borderColor}` };
    return { outline: `2px solid ${borderColor}`, borderRadius: '5px' };
  }

  // 프로퍼티가 패스워드이면서 패스워드형식에 맞지 않을 경우 보더처리
  if (property === 'newPassword' || property === 'newPasswordConfirm') {
    const { isPasswordRegexPass } = passwordOnlyRegex(data); // 패스워드 정규식확인함수
    if (!isPasswordRegexPass) return { outline: `2px solid ${borderColor}`, borderRadius: '5px' };
    // if (!isPasswordRegexPass) return { border: `1px solid ${borderColor}` };
  }

  // 프로퍼티가 비밀번호 확인(2)이면서, 비밀번호(1)와 일치하지 않을경우 보더처리
  if (property === 'newPasswordConfirm') {
    if (data?.trim() !== newPassword?.trim()) return { outline: `2px solid ${borderColor}`, borderRadius: '5px' };
  }

  // 프로퍼티가 이메일이면서 이메일 형식이 맞지 않을 경우 보더처리
  if (property === 'eMail') {
    const { isEmailRegexPass } = emailRegex(data); // 이메일 정규식확인함수
    if (!isEmailRegexPass) return { outline: `2px solid ${borderColor}`, borderRadius: '5px' };
  }

  // 프로퍼티가 데이트인 경우 숫자길이가 8자리를 충족하지 않을 경우 보더처리
  // 유효한 날짜가 아닌경우 보더처리
  if (property === 'date') {
    const isInvalidDateLength = (data?.match(/\d/g) || []).length < 8;
    const isValidDate = isValidYYYYMMDD(data);
    if (isInvalidDateLength) return { outline: `2px solid ${borderColor}`, borderRadius: '5px' };
    if (!isValidDate) return { outline: `2px solid ${borderColor}`, borderRadius: '5px' };
  }
  return {};
}
