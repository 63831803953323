import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { IUser, bScreenMode, userState } from '../atoms';
import SiteDashboard from '../components/SiteDashboard';
import { registerSignin } from '../services/register';
import Cookies from 'js-cookie';
import i18n from '../translation/i18n';
import { SITE_DASHBOARD, TAREA_DASHBOARD } from '../_constants';
import { apiGet } from '../services/_common';

const Dashboard = () => {
  const [userInfo, setUserInfo] = useRecoilState<IUser>(userState);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const setColorMode = useSetRecoilState(bScreenMode);
  const [accessToken, setAccessToken] = useState();
  const queryParamHCd = queryParams.get('hCd');
  const queryParamSCd = queryParams.get('sCd');
  const queryParamTatCd = queryParams.get('areaCd');

  useEffect(() => {
    if (queryParamHCd && queryParamSCd) {
      loginAPI();
    }
  }, []);

  const loginAPI = async () => {
    const res = await registerSignin({ id: 'siteDashboardViewer', password: 'test', passwordErrorCount: 0 });
    const { data, message, statusCode, errorCount } = res.data;
    if (statusCode === 200) {
      const { userId } = data.userInfo;
      const { userMenuList } = data;
      data?.accessToken && Cookies.set('prosafeToken', data.accessToken);
      data?.refreshToken && Cookies.set('refreshToken', data.refreshToken);
      setAccessToken(data.accessToken);
      setUserInfo((prev) => ({
        ...prev,
        ...data.userInfo,
        userId,
        userMenuList,
      }));

      getSiteInfoAPI(queryParamHCd, queryParamSCd);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 현장 정보 조회 API
  const getSiteInfoAPI = async (hCd: any, sCd: any) => {
    const req = { hCd, sCd };
    const res = await apiGet({ path: '/site/info', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      getSiteSetAPI(hCd, sCd, data);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 현장 설정 정보 조회 API
  const getSiteSetAPI = async (hCd: string, sCd: string, siteInfo: any) => {
    const req = { hCd, sCd };
    const res = await apiGet({ path: '/setting/general', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      setUserInfo((prev) => ({
        ...prev,
        hCd,
        sCd,
        eExcelYn: siteInfo.eExcelYn,
        eListYn: siteInfo.eListYn,
        ePrintYn: siteInfo.ePrintYn,
        prejobtypeYn: data.generalSet.prejobtypeYn,
        elderlyAgeSet: data.generalSet.elderlyAgeSet,
        sessionTimeout: data.generalSet.sessionTimeout,
        eduPlace: data.generalSet.eduPlace,
        eduCharge: data.generalSet.eduCharge,
        eduStartTime: data.generalSet.eduStartTime,
        eduEndTime: data.generalSet.eduEndTime,
        fromHighpress: data.generalSet.fromHighpress,
        fromLowpress: data.generalSet.fromLowpress,
        toHighpress: data.generalSet.toHighpress,
        toLowpress: data.generalSet.toLowpress,
        accidentEdate: data.generalSet.accidentEdate,
        accidentSdate: data.generalSet.accidentSdate,
        accidentTday: data.generalSet.accidentTday,
      }));
      setColorMode(data.generalSet.bScreenMode);
      const lang = data.generalSet.bLangMode;
      switch (lang) {
        case '2':
          i18n.changeLanguage('en-US');
          break;
        case '3':
          i18n.changeLanguage('vi-VN');
          break;
        default:
          i18n.changeLanguage('ko-KR');
          break;
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  if (queryParamHCd && queryParamSCd && queryParamTatCd)
    return <div className='contents dashboard-monitor'>{accessToken && <SiteDashboard isViewMode dGubun={TAREA_DASHBOARD} tatCd={queryParamTatCd} />}</div>;
  if (queryParamHCd && queryParamSCd) return <div className='contents dashboard-monitor'>{accessToken && <SiteDashboard isViewMode dGubun={SITE_DASHBOARD} />}</div>;
  return <div style={{ backgroundColor: '#fff', minHeight: 'calc(100vh - 4rem)', padding: '2rem' }}>NotFound</div>;
};

export default Dashboard;
