import styled from 'styled-components';
import ButtonContainer from '../../../pages/s_cctv/S_cctv1/RealtimeCCTV/ButtonContainer';
import { Dispatch, SetStateAction } from 'react';

const Root = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
  /* justify-content: space-between;
  align-items: center; */
  > .label {
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    display: flex;
    align-items: center;
    gap: 0.25rem;
    height: 2rem;
    .material-symbols-rounded {
      font-variation-settings: 'FILL' 0, 'wght' 300;
      font-size: 1.5rem;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    }
  }
  .buttons {
    display: flex;
    gap: 0.25rem;
    > div {
      flex-grow: 1;
    }
  }
`;

type Props = {
  setState: Dispatch<SetStateAction<any>>;
  state: any;
  label: string;
  options: { key: string; name?: string; icon?: string; color?: string }[];
  icon: string;
};

const SelectOptionsButtons = ({ setState, state, label, options, icon }: Props) => {
  return (
    <Root>
      <span className='label'>
        <span className='material-symbols-rounded'>{icon}</span>
        {label}
      </span>
      <div className='buttons'>
        {options.map((item) => {
          const isSelected = state === item.key;
          return (
            <div key={item.key} role='presentation' onClick={() => setState && setState(item.key)}>
              <ButtonContainer name={item.name} selected={isSelected} icon={item.icon} color={item.color} minWidth />
            </div>
          );
        })}
      </div>
    </Root>
  );
};

export default SelectOptionsButtons;
