import { useEffect, useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { IModal, ILogin, INumbers } from 'customTypes';
import { BtnBlue, BtnWhite, BtnGray } from '../Button';
import { ModalBackground, Modal } from '../../assets/styles/Modal';
import { checkIdDuplicate } from '../../utils/checkIdDuplicate';
import { checkUserInputRequiredValue } from '../../utils/checkUserInputRequiredValue';
import { emailRegex } from '../../utils/checkRegex';
import Input from '../Input';
import { registerIp, registerSignup, registerHeadInfo, registerSiteInfo, registerSiteJoinInfo } from '../../services/register';
import NumbersInput from '../NumbersInput';
import IpInput from '../IpInput';
import { applyBorderStyle } from '../../utils/applyBorderStyle';
import { logPost } from '../../services/log';
import { useTranslation } from 'react-i18next';
import { checkPhonNumEmpty } from '../../utils/checkPhonNumEmpty';
import SearchSelectBoxSm from '../SearchSelectBoxSm';
import { getSubdomain } from '../../utils/getDomain';

const RadioLabel = styled.label`
  /* font-size: 1.8rem; */
  user-select: none;
  /* width: 8rem; */
  padding: 0 1.5rem;
  height: 3rem;
  background: #f3f6f9;
  border-radius: 2rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #777;
  margin-right: 0.2rem;
  /* font-weight: 100 !important; */
  background-color: white;
  outline: 1px solid rgba(0, 0, 0, 0.15);
`;

const RadioInput = styled.input.attrs({ type: 'radio' })`
  display: none;
  + ${RadioLabel}:before {
    content: '';
    display: inline-block;
    border: 0px solid #fff;
    margin-right: 0px;
    height: 0px;
    width: 0px;
    border-radius: 3px;
    vertical-align: middle;
    transition: all 0.1s linear;
  }
  &:checked + ${RadioLabel} {
    background: #1261ff;
    color: #fff;
    box-shadow: none;
  }
  &:checked + ${RadioLabel}:before {
    border-left-width: 2px;
    border-bottom-width: 2px;
    border-top-width: 0px;
    border-right-width: 0px;
    margin-right: 6px;
    transform: rotate(-45deg) translate(2px, -1px);
    height: 5px;
    width: 10px;
    border-radius: 0px;
    margin-right: 0.5rem;
  }
`;

const SignupModal = ({ openModal, setOpenModal, setState }: ILogin) => {
  const { t } = useTranslation();
  const [siteName, setSiteName] = useState<any>({ hCd: '', sCd: '', sjCd: '' });
  const [gCd, setGCd] = useState('SU');
  const [hList, setHlist] = useState<any[]>([]);
  const [sList, setSlist] = useState([]);
  const [sjList, setSjList] = useState([]);
  const [signupInfo, setSignupInfo] = useState({
    userId: '',
    isDuplicateChecked: false,
    userName: '',
    hpNum: '',
    telNum: '',
    eMail: '',
    ipInfo: '',
    approvalYn: 'N',
    pwdCheckyn: 'N',
  });
  const [hpNumState, sethpNumState] = useState<INumbers>({ num1: '010', num2: '', num3: '' });
  const [telNumState, setTelNumState] = useState<INumbers>({ num1: '', num2: '', num3: '' });
  const [ipInfoState, setIpInfoState] = useState<INumbers>({ num1: '', num2: '', num3: '', num4: '' });
  const telLengthState = { num1: 3, num2: 4, num3: 4 }; // 전화번호 입력 길이
  const hpNumLengthState = { num1: 3, num2: 4, num3: 4 }; // 휴대전화 입력 길이
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [telNumLastInput, setTelNumLastInput] = useState(false);
  const [hpNumLastInput, setHpNumLastInput] = useState(false);
  const emailRef = useRef<HTMLInputElement>(null);

  // 마운트시 hCd 가져오는 API 호출
  useEffect(() => {
    const currentHostname = window.location.hostname;
    const subDomain = getSubdomain(currentHostname);
    getHCdAPI(subDomain);
  }, []);

  useEffect(() => {
    if (telNumLastInput) {
      emailRef.current?.focus();
    }
  }, [telNumLastInput]);

  // 유저가 hCd를 선택했을 때 sCd setState
  useEffect(() => {
    if (siteName.hCd !== '') getSCdAPI(siteName.hCd);
  }, [siteName.hCd]);

  // 유저가 sCd를 선택했을 때 sjCd setState
  const getSjCd = useCallback(() => {
    if (siteName.hCd !== '' && siteName.sCd !== '') getSjCdAPI(siteName.hCd, siteName.sCd);
  }, [siteName.sCd, siteName.hCd]);

  useEffect(() => {
    getSjCd();
  }, [getSjCd]);

  useEffect(() => {
    if (gCd === 'HU') setSiteName((prev: any) => ({ ...prev, sCd: '00000', sjCd: '' }));
    else setSiteName((prev: any) => ({ ...prev, sCd: '', sjCd: '' }));
  }, [siteName.hCd]);

  useEffect(() => {
    setSiteName((prev: any) => ({ ...prev, sjCd: '' }));
  }, [siteName.sCd]);

  // 닫기버튼 클릭
  const onClickClose = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: !openModal?.status }));
  };

  // 등록 사용자타입 setState
  const onChangeUserType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setGCd(value);
    if (value === 'HU') {
      // 본사사용자 선택하는 경우 sCd: 00000
      setSiteName((prev: any) => ({ ...prev, sCd: '00000', sjCd: '' }));
    } else {
      setSiteName((prev: any) => ({ ...prev, hCd: hList.length === 1 ? prev.hCd : '', sCd: '', sjCd: '' }));
    }
  };

  // hCd API 호출해서 hCd목록 setState
  const getHCdAPI = async (subDomain: string) => {
    const res = await registerHeadInfo(subDomain);
    const { data } = res.data;
    if (data.length === 1) {
      setSiteName((prev: any) => ({ ...prev, ...data[0] }));
    }
    setHlist(data);
  };

  // sCd API 호출해서 sCd목록 setState
  const getSCdAPI = async (hCd: string) => {
    const res = await registerSiteInfo(hCd);
    const { data } = res.data;
    setSlist(data);
  };

  // sjCd API 호출해서 sjCd목록 setState
  const getSjCdAPI = async (hCd: string, sCd: string) => {
    const res = await registerSiteJoinInfo(hCd, sCd);
    const { data } = res.data;
    setSjList(data);
  };

  // (회원가입) id onChange시 state값 업데이트
  const onChangeId = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setSignupInfo((prev) => ({ ...prev, [name]: value?.trim(), isDuplicateChecked: false }));
  };

  // (회원가입) signupInfo onChange시 state값 업데이트
  const onChangeSignupInfo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setSignupInfo((prev) => ({ ...prev, [name]: value?.trim() }));
  };

  // 현재IP버튼 API호출 후 ip setState
  const onClickGetCurrentIp = async () => {
    const res = await registerIp();
    const { data, message, statusCode } = res.data;
    if (statusCode === 200) {
      const ipSplit = data.split('.');
      setIpInfoState({ num1: ipSplit[0], num2: ipSplit[1], num3: ipSplit[2], num4: ipSplit[3] });
      setSignupInfo((prev) => ({ ...prev, ipInfo: data }));
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 회원가입 API 호출
  const signupAPI = async (resultData: any) => {
    const res = await registerSignup(resultData, gCd, siteName);
    const { statusCode } = res.data;
    if (statusCode === 200) {
      setState((prev: any) => ({ ...prev, id: resultData?.userId, password: '', passwordErrorCount: 0 }));

      let gName = '';
      switch (gCd) {
        case 'SU':
          gName = t('현장 사용자');
          break;
        case 'ST':
          gName = t('현장 협력업체');
          break;
      }
      await logPost({
        hCd: siteName.hCd,
        sCd: siteName.sCd,
        userId: resultData.userId,
        menu: '회원가입',
        action: '회원가입 성공',
        etc: `${gName}`,
      });
      toast.success(t('회원가입에 성공하였습니다. 관리자 승인 후 입력하신 이메일 정보로 임시 비밀번호가 발송됩니다.'));
      onClickClose();
    } else {
      // toast.error(t(ERROR));
    }
  };

  const onClickCheckDuplicateId = async () => {
    const { idDuplicateAlertMsg, resStatusCode } = await checkIdDuplicate(signupInfo.userId);

    if (resStatusCode === 200) setSignupInfo((prev) => ({ ...prev, isDuplicateChecked: true }));
    if (resStatusCode === 409) setSignupInfo((prev) => ({ ...prev, userId: '' }));

    return toast.warning(t(idDuplicateAlertMsg));
  };

  // 회원가입버튼 클릭
  const onClickSignup = () => {
    setIsSaveClicked(true);
    const mergedHpNumber = `${hpNumState.num1}-${hpNumState.num2}-${hpNumState.num3}`;
    const mergedTelNumber = telNumState.num1 === '' && telNumState.num2 === '' && telNumState.num3 === '' ? '' : `${telNumState.num1}-${telNumState.num2}-${telNumState.num3}`;
    const mergedIpNumber =
      ipInfoState.num1 === '' && ipInfoState.num2 === '' && ipInfoState.num3 === '' && ipInfoState.num4 === '' ? '' : `${ipInfoState.num1}.${ipInfoState.num2}.${ipInfoState.num3}.${ipInfoState.num4}`;

    const { hCd, sCd, sjCd } = siteName;
    const { isUserInputRequiredValuePass, userInputRequiredValueMsg } = checkUserInputRequiredValue(signupInfo);
    const { isEmpty, emptyAlertMessage } = checkPhonNumEmpty(hpNumState);
    const { isEmailRegexPass, emailRegexAlertMsg } = emailRegex(signupInfo.eMail);

    if (gCd === 'HU' && hCd === '') return toast.warning(t('본사명을 선택하세요'));
    if (gCd === 'SU' && (hCd === '' || sCd === '')) return toast.warning(t('본사, 현장명을 선택하세요'));
    if (gCd === 'ST' && (hCd === '' || sCd === '' || sjCd === '')) return toast.warning(t('본사, 현장, 협력업체 명을 선택하세요'));

    if (!isUserInputRequiredValuePass) return toast.warning(t(userInputRequiredValueMsg));
    if (isEmpty) return toast.warning(t(emptyAlertMessage));
    if (!isEmailRegexPass) return toast.warning(t(emailRegexAlertMsg));

    const resultData = { ...signupInfo, hpNum: mergedHpNumber, telNum: mergedTelNumber, ipInfo: mergedIpNumber };
    return signupAPI(resultData);
  };

  return (
    <ModalBackground>
      <Modal>
        <div className='modal signup'>
          <div className='inputForm-head'>
            <h5 className='medium'>{openModal?.title}</h5>
          </div>
          <div className='inputForm-body'>
            <div id='join' className='modal-contents'>
              <div className='inputForm-row'>
                <div className='inputForm-col col-w50'>
                  <div className='flex-basic'>
                    <RadioInput id='HU' name='userType' type='radio' onChange={onChangeUserType} value='HU' />
                    <RadioLabel htmlFor='HU'>{t('본사 사용자')}</RadioLabel>
                    <RadioInput id='SU' name='userType' type='radio' onChange={onChangeUserType} value='SU' defaultChecked />
                    <RadioLabel htmlFor='SU'>{t('현장 사용자')}</RadioLabel>
                    <RadioInput id='ST' name='userType' type='radio' onChange={onChangeUserType} value='ST' />
                    <RadioLabel htmlFor='ST'>{t('현장 협력업체')}</RadioLabel>
                  </div>
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col col-w50'>
                  <label className='required' htmlFor='bsName'>
                    {t('본사명')}
                  </label>
                  {hList.length === 1 ? (
                    <input type='text' id='hName' name='hName' value={siteName.hName} disabled />
                  ) : (
                    <SearchSelectBoxSm
                      options={hList}
                      defaultOption={siteName.hCd ? siteName.hName : t('본사 선택')}
                      state={siteName}
                      setState={setSiteName}
                      stateKey='hCd'
                      codeKey='hName'
                      initiateKey={gCd}
                      getBorderStyle={isSaveClicked ? applyBorderStyle(siteName.hCd, 'red', 'hCd') : undefined}
                      dropDownWidth='expand-content-sm'
                      optionHeight='height-base'
                    />
                  )}
                </div>
              </div>
              {gCd === 'SU' || gCd === 'ST' ? (
                <div className='inputForm-row'>
                  <div className='inputForm-col col-w50'>
                    <label className='required' htmlFor='hjName'>
                      {t('현장명')}
                    </label>
                    <SearchSelectBoxSm
                      options={sList}
                      defaultOption={t('현장 선택')}
                      state={siteName}
                      setState={setSiteName}
                      stateKey='sCd'
                      codeKey='sName'
                      initiateKey={gCd}
                      getBorderStyle={isSaveClicked ? applyBorderStyle(siteName.sCd, 'red', 'sCd') : undefined}
                      dropDownWidth='expand-content-sm'
                      optionHeight='height-base'
                    />
                  </div>
                </div>
              ) : undefined}
              {gCd === 'ST' ? (
                <div className='inputForm-row'>
                  <div className='inputForm-col col-w50'>
                    <label className='required' htmlFor='hrName'>
                      {t('협력업체명')}
                    </label>
                    <SearchSelectBoxSm
                      options={sjList}
                      defaultOption={t('협력업체 선택')}
                      state={siteName}
                      setState={setSiteName}
                      stateKey='sjCd'
                      codeKey='sjName'
                      initiateKey={gCd}
                      getBorderStyle={isSaveClicked ? applyBorderStyle(siteName.sjCd, 'red', 'sjCd') : undefined}
                      dropDownWidth='expand-content-sm'
                      optionHeight='height-base'
                    />
                  </div>
                </div>
              ) : undefined}
              <div className='inputForm-row'>
                <div className='inputForm-col col-w50'>
                  <label className='required' htmlFor='userId'>
                    {t('아이디')}
                  </label>
                  <div className='flex-between iscontents-m'>
                    <input
                      type='text'
                      id='userId'
                      name='userId'
                      onChange={onChangeId}
                      value={signupInfo?.userId}
                      style={isSaveClicked ? applyBorderStyle(signupInfo.userId, 'red', 'userId') : undefined}
                      maxLength={50}
                    />
                    <BtnGray className='btn-small' onClick={onClickCheckDuplicateId}>
                      {t('중복검사')}
                    </BtnGray>
                  </div>
                </div>
                <div className='inputForm-col col-w50'>
                  <Input
                    className='required'
                    label={t('성명')}
                    type='text'
                    id='userName'
                    name='userName'
                    state={signupInfo}
                    setState={setSignupInfo}
                    getBorderStyle={isSaveClicked ? applyBorderStyle(signupInfo.userName, 'red', 'userName') : undefined}
                  />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col col-w100'>
                  <label htmlFor='hpNum' className='required'>
                    {t('휴대폰번호')}
                  </label>
                  <NumbersInput state={hpNumState} setState={sethpNumState} lengthState={hpNumLengthState} setIsLastInputCompleted={setHpNumLastInput} isSaveClicked={isSaveClicked} />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col col-w100'>
                  <label htmlFor='hpNum'>{t('전화번호')}</label>
                  <NumbersInput state={telNumState} setState={setTelNumState} lengthState={telLengthState} isFirstInputFocused={hpNumLastInput} setIsLastInputCompleted={setTelNumLastInput} />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col col-w100'>
                  <Input
                    className='required'
                    inputRef={emailRef}
                    label={t('이메일')}
                    type='text'
                    id='eMail'
                    name='eMail'
                    state={signupInfo}
                    setState={setSignupInfo}
                    getBorderStyle={isSaveClicked ? applyBorderStyle(signupInfo.eMail, 'red', 'eMail') : undefined}
                  />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col col-w100'>
                  <label htmlFor='hpNum'>{t('접속 IP')}</label>
                  <div className='flex-between'>
                    <IpInput stateName='ipInfo' separator='.' state={ipInfoState} setState={setIpInfoState} setInfoState={setSignupInfo} />
                    <BtnGray className='btn-small' onClick={onClickGetCurrentIp}>
                      {t('현재 PC IP 적용')}
                    </BtnGray>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer flex-end'>
              <BtnWhite onClick={onClickClose}>{t('취소')}</BtnWhite>
              <BtnBlue onClick={onClickSignup}>{t('저장')}</BtnBlue>
            </div>
          </div>
        </div>
      </Modal>
    </ModalBackground>
  );
};

export default SignupModal;
