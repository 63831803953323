/**
 * 작성자 : 홍선영
 * 날짜 : 2024.06.03
 * 경로 : 안전관리 - 근로자 안전교육 보고서
 */

import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { useTranslation } from 'react-i18next';
import { todayYYYYMMDD } from '../../../utils/formatDate';
import { useSetSjcd } from '../../../utils/useSetSjcd';
import useOnKeydownF9 from '../../../utils/useOnKeydownF9';
import useSetJobtype from '../../../utils/useSetJobtype';
import { LIST_COUNT } from '../../../_constants';
import { useRecoilValue } from 'recoil';
import { IUser, userState } from '../../../atoms';
import { IComCdList } from 'customTypes';
import useSetListCount from '../../../utils/useSetListCount';
import SelectBox from '../../../components/SelectBox';
import SearchSelectBoxSm from '../../../components/SearchSelectBoxSm';
import ShortcutButton from '../../../components/button/ShortcutButton';
import { BtnGhost } from '../../../components/Button';
import { toast } from 'react-toastify';
import { setComCdListState } from '../../../utils/setComCdListState';
import { useQuery } from '@tanstack/react-query';
import { apiGet } from '../../../services/_common';
import DatePickerComponent from '../../../components/DatePicker';
import { CheckBox } from '../../../components/Input';
import { PrintContextConsumer, useReactToPrint } from 'react-to-print';
import SafeEduSignForm from './SafeEduSignForm';
import { useSetAuth } from '../../../utils/useSetAuth';

interface ISafeeduReportSearchOption {
  setIsFetching: Dispatch<SetStateAction<boolean>>; // 검색데이터 패칭상태
  setIsError: Dispatch<SetStateAction<boolean>>;
  tableState: any[];
  setTableState: Dispatch<SetStateAction<any[]>>;
  filterTableState: any[];
  setFilterTableState: Dispatch<SetStateAction<any[]>>;
  setRowKey: Dispatch<SetStateAction<string>>;
  listCount: any;
  setListCount: Dispatch<SetStateAction<any>>;
  refetchFlag: boolean;
  setRefetchFlag: Dispatch<SetStateAction<boolean>>;
  safeeduList: any[];
  showSign: boolean;
  setShowSign: Dispatch<SetStateAction<boolean>>;
}

const SafeeduReportSearchOption = ({
  setIsFetching,
  setIsError,
  tableState,
  setTableState,
  filterTableState,
  setFilterTableState,
  setRowKey,
  listCount,
  setListCount,
  refetchFlag,
  setRefetchFlag,
  safeeduList,
  showSign,
  setShowSign,
}: ISafeeduReportSearchOption) => {
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const { t } = useTranslation();
  const toDay = todayYYYYMMDD();
  const userInfo = useRecoilValue<IUser>(userState);
  const { siteJoinInfoList } = useSetSjcd();
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const [wPrejobtype, setWPrejobtype] = useState({ type: 'wPrejobtype', wPrejobtype: '', cdName: '' });
  const { prejobtypeList, jobtypeList } = useSetJobtype(wPrejobtype.wPrejobtype);
  const [sjCd, setSjCd] = useState({ type: 'sjCd', sjCd: '', cdName: '' });
  const [initiate, setinitiate] = useState('');
  const [jikJongList, setJikJongList] = useState<any[]>([]);
  const [wJobtype, setWJobtype] = useState({ type: 'wJobtype', wJobtype: '', cdName: '' });
  const [listCountComCdList, setListCountComCdList] = useState<IComCdList[]>([]); // 검색줄수 공통코드
  const { patchUserMenuAPI } = useSetListCount(); // 검색줄수설정 훅
  const INIT_EDU = safeeduList ? { type: 'F', F: safeeduList[0]?.F, cdName: safeeduList[0]?.cdName } : { type: 'F', F: '', cdName: '' };
  const [eduCode, setEduCode] = useState(INIT_EDU);
  const [sDate, setSDate] = useState(toDay);
  const [showSjName, setShowSjName] = useState({ status: false, name: '' });

  const { data, isLoading, isFetching, isError, refetch } = useQuery(['safeedud', userInfo.hCd, userInfo.sCd], () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && !!eduCode.F,
    cacheTime: 0,
  });

  useEffect(() => {
    if (refetchFlag) refetch();
  }, [refetchFlag]);

  //  패치한 데이터를 검색필터값에 따라 필터링하는 함수
  const filterData = (fetchedData: any, filterParam: any) => {
    return fetchedData.filter((item: any) => {
      // 필터의 각 키를 확인
      return Object.entries(filterParam).every(([key, value]) => {
        // 필터 값이 비어 있으면 필터 기준 무시
        if (!value) return true;

        // 항목의 값이 필터 값과 일치하는지 확인 (일관된 비교를 위해 둘 다 문자열로 변환)
        return item[key]?.toString() === value?.toString();
      });
    });
  };

  const fetchData = async () => {
    try {
      const path = '/safe/edud';
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, sDate };

      const res = await apiGet({ path, req });
      const newArray = res.data.data;
      const filter = {
        sCode: eduCode.F,
        sjCd: sjCd.sjCd,
        wPrejobtype: wPrejobtype.wPrejobtype,
        wJobtype: wJobtype.wJobtype,
      };

      const filteredData = filterData(newArray, filter);

      const result = filteredData.map((el: any) => ({ ...el, signFile: el.signFile, hasSignFile: !!el.signFile }));

      // 필터링 한 협력업체 유무에 따라 해당 값을 인쇄시 전달
      if (sjCd.sjCd) setShowSjName({ status: true, name: sjCd.cdName });
      else setShowSjName({ status: false, name: '' });

      setTableState(result);
      return newArray;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    } finally {
      setRefetchFlag(false);
    }
  };
  useEffect(() => {
    setComCdListState(LIST_COUNT, setListCountComCdList, false);
  }, []);

  useEffect(() => {
    setIsFetching(isFetching);
  }, [isLoading, isFetching]);

  useEffect(() => {
    setIsError(isError);
  }, [isError]);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    setWJobtype({ type: 'wJobtype', wJobtype: '', cdName: '' });
    if (wPrejobtype.wPrejobtype === '') setJikJongList([]);
  }, [wPrejobtype.wPrejobtype]);

  useEffect(() => {
    setJikJongList(jobtypeList);
  }, [jobtypeList]);

  // 검색 버튼 클릭
  const onClickSearch = () => {
    setRowKey('');
    if (sDate && sDate.length < 8) toast.warning(t('날짜를 다시 입력하세요'));
    else refetch();
  };

  const initiateSearchOptions = () => {
    setEduCode(INIT_EDU);
    setSjCd({ type: 'sjCd', sjCd: '', cdName: '' });
    setWPrejobtype({ type: 'wPrejobtype', wPrejobtype: '', cdName: '' });
    setWJobtype({ type: 'wJobtype', wJobtype: '', cdName: '' });
    setSDate(toDay);
    setShowSign(false);
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    setinitiate(`${Math.random()}`);
    initiateSearchOptions();
  };

  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const onClickPrint = () => {
    if (tableState.length > 0) handlePrint();
  };

  const onChanceCheck = () => {
    setShowSign((prev) => !prev);
  };

  return (
    <SearchOptions>
      <div className='inputsWrapper'>
        <div className='inputForm-col withLabelComCf'>
          <label htmlFor='wWorkstatus'>{t('교육 구분')}</label>
          <SelectBox options={safeeduList} defaultOption={INIT_EDU?.cdName} state={eduCode} setState={setEduCode} stateKey='F' initiateKey={eduCode?.cdName} filterbar='filter-1-left' />
        </div>
        <div className='inputForm-row withLabel'>
          <label htmlFor='sjCd'>{t('협력업체')}</label>
          <div className='inputForm-col'>
            <SearchSelectBoxSm
              options={siteJoinInfoList}
              defaultOption={t('전체')}
              state={sjCd}
              setState={setSjCd}
              stateKey='sjCd'
              codeKey='cdName'
              initiateKey={sjCd.sjCd}
              filterbar='filter-1-left'
              optionHeight='height-md'
            />
          </div>
        </div>
        <div className='inputForm-row'>
          <div className='inputForm-col'>
            <div className='datePickerWrapper'>
              <DatePickerComponent startDate={sDate} setDate={setSDate} popperPlacement='bottom' />
            </div>
          </div>
        </div>
        {userInfo.prejobtypeYn === 'Y' && (
          <div className='inputForm-row'>
            <div className='inputForm-col'>
              <SearchSelectBoxSm
                options={prejobtypeList}
                defaultOption={t('공종 전체')}
                state={wPrejobtype}
                setState={setWPrejobtype}
                stateKey='wPrejobtype'
                codeKey='cdName'
                initiateKey={initiate}
                filterbar='filter-1-right'
                comboWidth='expand-box-sm'
                optionHeight='height-md'
              />
            </div>
          </div>
        )}
        <div className='inputForm-row'>
          <div className='inputForm-col'>
            <SearchSelectBoxSm
              options={jikJongList}
              defaultOption={t('직종 전체')}
              state={wJobtype}
              setState={setWJobtype}
              stateKey='wJobtype'
              codeKey='cdName'
              initiateKey={wJobtype.wJobtype}
              filterbar='filter-1-right'
              comboWidth='expand-box-sm'
              optionHeight='height-md'
            />
          </div>
        </div>
      </div>
      <div className='inputsWrapper'>
        <div className='secondSearchOption'>
          <div className='flex-basic textBtnGroup'>
            <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
            <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
            <div className='searchResult'>
              {t('총')}
              <span>{tableState?.length || 0}</span>
              {t('개')}
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col withLabelComCf'>
                <label htmlFor='useYn'>{t('보기 설정')}</label>
                <SelectBox
                  options={listCountComCdList}
                  defaultOption={listCount.cdName}
                  state={listCount}
                  setState={setListCount}
                  stateKey={LIST_COUNT}
                  initiateKey={listCount[LIST_COUNT]}
                  setTableLinesAPI={patchUserMenuAPI}
                  optionHeight='height-sm'
                  rsearch
                />
              </div>
            </div>
          </div>

          <div className='flex-basic iconBtnGroup'>
            <div className='flex-end checks'>
              <CheckBox>
                <input type='checkbox' checked={showSign} id='showSign' onChange={onChanceCheck} />
                <label htmlFor='showSign'>{t('서명보기')}</label>
              </CheckBox>
            </div>
            {auth.printAuth && (
              <BtnGhost onClick={onClickPrint}>
                <span className='material-symbols-rounded'>print </span>
                {t('인쇄')}
              </BtnGhost>
            )}
          </div>
          <PrintContextConsumer>
            {() => (
              <div style={{ display: 'none' }}>
                <div ref={componentRef}>
                  <SafeEduSignForm item={tableState} showSign={showSign} showSjName={showSjName} eduDate={sDate} />
                </div>
              </div>
            )}
          </PrintContextConsumer>
        </div>
      </div>
    </SearchOptions>
  );
};

export default SafeeduReportSearchOption;
