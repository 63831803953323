import styled from 'styled-components';
import ControllRightBar from './ControllRightBar';
import ControllCenterBar from './ControllCenterBar';
import ControllLeftBar from './ControllLeftBar';
import { Dispatch, SetStateAction } from 'react';
import { WebSDKcctv } from '../../../../utils/webSDKcctvClass';

const Root = styled.div`
  height: 3rem;
  width: 100%;
  background-color: ${({ theme }: { theme: any }) => theme.color.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
`;

interface Props {
  visiblePanel: boolean;
  setVisiblePanel: Dispatch<SetStateAction<boolean>>;
  cctvOBJ: WebSDKcctv | undefined; // cctv 객체
  cctvDivisions: number; // cctv 분할
  onClickDivision: (number: 1 | 2 | 3 | 4) => void;
  selectedCameraInfo: SelectedCctvCameraInfo;
  setSelectedCameraInfo: Dispatch<SetStateAction<SelectedCctvCameraInfo>>;
  nvrList: IMainMenu[];
  setNvrList: Dispatch<SetStateAction<IMainMenu[]>>;
  currentDate?: string;
  videoWindow: VideoWindow;
  onClickPlay: (reverse?: 'reverse') => void;
  onClickPause: () => void;
  capturePath: string;
  onClickSpeed: (speed: 'fast' | 'slow') => void;
  onClickStopAndPlay: (reverse?: 'reverse') => void;
}

const ControllBar = ({
  visiblePanel,
  setVisiblePanel,
  cctvOBJ,
  cctvDivisions,
  onClickDivision,
  selectedCameraInfo,
  setSelectedCameraInfo,
  nvrList,
  setNvrList,
  currentDate,
  videoWindow,
  onClickPlay,
  onClickPause,
  capturePath,
  onClickSpeed,
  onClickStopAndPlay,
}: Props) => {
  return (
    <Root className='controllBar'>
      <ControllLeftBar //
        cctvOBJ={cctvOBJ}
        visiblePanel={visiblePanel}
        setVisiblePanel={setVisiblePanel}
        cctvDivisions={cctvDivisions}
        onClickDivision={onClickDivision}
        selectedCameraInfo={selectedCameraInfo}
        onClickSpeed={onClickSpeed}
      />
      <ControllCenterBar //
        cctvOBJ={cctvOBJ}
        selectedCameraInfo={selectedCameraInfo}
        setSelectedCameraInfo={setSelectedCameraInfo}
        nvrList={nvrList}
        setNvrList={setNvrList}
        onClickPlay={onClickPlay}
        onClickPause={onClickPause}
        videoWindow={videoWindow}
        onClickStopAndPlay={onClickStopAndPlay}
      />
      <ControllRightBar cctvOBJ={cctvOBJ} selectedCameraInfo={selectedCameraInfo} setSelectedCameraInfo={setSelectedCameraInfo} nvrList={nvrList} setNvrList={setNvrList} capturePath={capturePath} />
    </Root>
  );
};

export default ControllBar;
