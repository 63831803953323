/**
 * 작성자 : 홍선영
 * 날짜 : 2024.01.22
 * 기능 : 장비 출입 현황 대시보드 컴포넌트 (구역별 상황판)
 */

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PulseLoader } from 'react-spinners';
import { useQuery } from '@tanstack/react-query';

import { formatMMDDHHMMSS } from '../../utils/formatDate';
import useDashboardTimer from '../../utils/useDashboardTimer';
import { PhotoTableListStyle } from '../../assets/styles/PhotoTableList';
import { apiGet } from '../../services/_common';

interface Iprops {
  name: string;
  userInfo: any;
  tatCd: string;
  refreshTimeCount: number;
}

interface IequipAttend {
  tatGubun: string;
  tadCd: string;
  tadName: string;
  wImg: string;
  wCd: string;
  wName: string;
  sjCd: string;
  sjName: string;
  wPrejobtype: string;
  wPrejobtypeName?: any;
  wJobtype: string;
  wJobtypeName?: any;
  bDate: string;
}

export const EquipAttend = ({ name, userInfo, tatCd, refreshTimeCount }: Iprops) => {
  const { t } = useTranslation();
  const { timer, setTimer } = useDashboardTimer(refreshTimeCount);

  useEffect(() => {
    if (timer <= 0) {
      refetch();
      setTimer(refreshTimeCount);
    }
  }, [timer]);

  // 장비 출입현황 조회
  const { data, isLoading, refetch } = useQuery(['tAreaEquipAttendGet', userInfo.hCd, userInfo.sCd, tatCd], () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && !!tatCd,
  });

  const fetchData = async () => {
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, tatCd };
      const res = await apiGet({ path: '/tarea/board/equipment', req });
      const tareaList = res.data.data?.tareaList || [];
      return tareaList;
    } catch (error) {
      console.error(error);
      throw new Error('error /msg');
    }
  };

  if (isLoading || !data) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <PhotoTableListStyle>
      <div className='widget-header safety'>
        <div className='widget-title flex-between'>
          <span>
            {t(name)}
            <span className='badge'>{data.length}</span>
          </span>
        </div>
      </div>
      <div className='widget-body photoTable'>
        <div className='sliderWrapper'>
          <div className='text-container active' style={data.length > 14 ? { animation: `slide ${data.length * 2}s linear infinite` } : undefined}>
            <WorkerList workerList={data} />
          </div>
        </div>
      </div>
    </PhotoTableListStyle>
  );
};

const WorkerList = ({ workerList }: { workerList: IequipAttend[] }) => {
  const { t } = useTranslation();

  return (
    <div>
      {workerList.map((worker: IequipAttend) => (
        <div key={`${worker.wCd}`} className='rowWrapper flex-basic'>
          <div className='flex-basic'>
            <div className='imgWrapper'>{worker.wImg ? <img src={worker.wImg} alt='' /> : <span className='material-symbols-rounded'>person </span>}</div>
            <div className='flex-col'>
              <div className='flex-basic flex-between'>
                <div className='ellipsis' style={{ paddingRight: '0.5rem' }}>
                  {worker.wName}
                </div>
                <div className='buttonsWrapper flex-basic'>
                  <button type='button' className={worker.tatGubun?.toLocaleLowerCase() === 's' ? 'blue' : 'amber'}>
                    <span>{worker.tatGubun?.toLocaleLowerCase() === 's' ? t('시점') : t('종점')}</span>
                  </button>
                  <button type='button' className='violet'>
                    <span>{worker.tadName}</span>
                  </button>
                </div>
              </div>
              <div className='flex-basic flex-between'>
                <div className='ellipsis' style={{ paddingRight: '0.5rem' }}>
                  {worker.sjName} {worker.wPrejobtypeName}
                </div>
                <div className='buttonsWrapper flex-basic'>
                  <button type='button' className='gray'>
                    <span>{formatMMDDHHMMSS(worker.bDate)}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
