/**
 * 작성자 : 홍선영
 * 날짜 : 2024.03.26
 * 기능 : 화면 왼쪽에 뜨는 모달창
 */

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { IModal } from 'customTypes';
import { ModalBackground } from '../../assets/styles/Modal';
import ButtonContainer from '../../pages/s_cctv/S_cctv1/RealtimeCCTV/ButtonContainer';

interface Props {
  openModal: any;
  setOpenModal: any;
}

const LeftedModalStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  width: 16rem;
  margin-left: 2rem;
  margin-bottom: 12rem;
  background-color: ${({ theme }: { theme: any }) => theme.tonal};
  border-radius: 5px;
  padding: 0.5rem;

  .flexColumn {
    gap: 0.5rem;
  }
`;

const LeftedModal = ({ openModal, setOpenModal }: Props) => {
  const { t } = useTranslation();

  const onClickClose = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };

  const onClickStream = (optionValue: string) => {
    openModal.setDataFunction(optionValue);
  };

  return (
    <ModalBackground onClick={onClickClose} role='presentation' className='leftedModal'>
      <LeftedModalStyle>
        <div>{t('스트림 선택')}</div>
        <div className='flexColumn'>
          <ButtonContainer icon='looks_one' name='메인' onClick={() => onClickStream('1')} />
          <ButtonContainer icon='looks_two' name='서브' onClick={() => onClickStream('2')} />
          <ButtonContainer icon='looks_3' name='트랜스코딩' onClick={() => onClickStream('3')} />
        </div>
      </LeftedModalStyle>
    </ModalBackground>
  );
};

export default LeftedModal;
