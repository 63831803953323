import { createRoot } from 'react-dom/client';
import styled from 'styled-components';

const Root = styled.div`
  img {
    max-height: 2.8rem;
  }
`;
class CustomImageRenderer {
  element;

  constructor(props: any) {
    this.element = document.createElement('div');
    const root = createRoot(this.element);
    root.render(<Root>{props.value ? <img src={props.value} alt='서명이미지' /> : null}</Root>);
  }

  getElement() {
    return this.element;
  }
}
export default CustomImageRenderer;
