import styled from 'styled-components';
import ButtonContainer from '../../../pages/s_cctv/S_cctv1/RealtimeCCTV/ButtonContainer';
import { useCallback, useEffect, useState } from 'react';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  > .title {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    .label {
      font-size: 0.875rem;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
    }
    .material-symbols-rounded {
      font-variation-settings: 'FILL' 0, 'wght' 300;
      font-size: 1.5rem;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    }
    .clipboard {
      font-size: 0.75rem;
      padding: 0 0.75rem;
      color: ${({ theme }: { theme: any }) => theme.filled_green_deep};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
      border-radius: 1rem;
    }
  }
  > .bottom {
    &.isPath {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }
    .pathContainer {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      border-radius: 0.25rem;
      padding: 0.25rem 0.5rem;
      height: 2rem;
      overflow: hidden;
      .path {
        padding-left: 0.25rem;
        color: ${({ theme }: { theme: any }) => theme.text_disabled};
        font-weight: 400;
        letter-spacing: normal;
        font-size: 0.875rem;
        text-overflow: ellipsis;
        word-break: keep-all;
        overflow: hidden;
        white-space: nowrap;
      }
      .material-symbols-rounded {
        font-size: 1.25rem;
        color: ${({ theme }: { theme: any }) => theme.text_disabled};
        &.copied {
          font-size: 1.5rem;
          color: ${({ theme }: { theme: any }) => theme.filled_green};
        }
      }
      &:hover {
        cursor: pointer;
        .path {
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        }
        .material-symbols-rounded:not(.copied) {
          color: ${({ theme }: { theme: any }) => theme.text_secondary};
        }
      }
    }
  }
`;

type Props = { icon: string; label: string; onClick: () => void; path: null | string };

const SetDownloadPath = ({ icon, label, onClick, path }: Props) => {
  // 클립보드에 다운로드 폴더 경로 저장 여부
  const [copied, setCopied] = useState(false);
  // 클립보드에 path 저장
  const handleCopyToClipboard = useCallback((text: string) => {
    navigator.clipboard.writeText(text);
  }, []);

  // copied가 true이면 3초 뒤에 false로 변경
  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [copied]);

  // path가 null 이거나 'undefined' 이면 false, 아니면 true
  const isPath = !(path === null || path === 'undefined' || path === '');

  return (
    <Root>
      <span className='title'>
        <span className='material-symbols-rounded'>{icon}</span>
        <span className='label'>{label}</span>
        {copied && <span className='clipboard'>경로 복사됨</span>}
      </span>
      <div className={`bottom ${isPath ? 'isPath' : ''}`}>
        {isPath && (
          <div
            className='pathContainer' //
            role='presentation'
            onClick={() => {
              handleCopyToClipboard(path);
              setCopied(true);
            }}
          >
            <div className='path'>{path}</div>
            <span className='material-symbols-rounded'>content_copy</span>
          </div>
        )}
        <ButtonContainer name={isPath ? '변경하기' : '설정하기'} onClick={onClick} />
      </div>
    </Root>
  );
};

export default SetDownloadPath;
