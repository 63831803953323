import { useRecoilValue, useRecoilState } from 'recoil';
/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.15
 * 경로 : 터널관리, 대시보드 & 상황판 > 터널
 */

import { useTranslation } from 'react-i18next';
import { apiGet, externalApiPost } from './_common';
import { useQuery } from '@tanstack/react-query';
import { tInfoUpdateState, userState } from '../atoms';
import { Dispatch, SetStateAction } from 'react';
import * as speaker from '../utils/speaker';

export const initialFilter = [
  { title: 'attend', status: true, name: '출역인원', icon: 'familiar_face_and_zone' },
  { title: 'mGas', status: true, name: '유해가스', icon: 'sensors' },
  { title: 'location', status: true, name: '근로자', icon: 'group' },
  { title: 'device', status: true, name: '장비', icon: 'front_loader' },
  { title: 'cctv', status: true, name: 'CCTV', icon: 'nest_cam_iq_outdoor' },
  { title: 'broadCast', status: true, name: '방송장비', icon: 'volume_up' },
];

export const areaFilter = initialFilter.filter((item) => ['출역인원', '근로자', '장비'].includes(item.name));

export const workAndEquipment = [
  { title: 'work', status: true, name: '작업 내용', icon: 'construction' },
  { title: 'equipment', status: true, name: '장비 상태', icon: 'front_loader' },
];

// 터널 정방향/역방향 설정을 불러오는 함수
export const fetchTunnelReversed = async (userInfo: any) => {
  const { hCd, sCd } = userInfo;
  try {
    const requestParams = { hCd, sCd };
    const response = await apiGet({ path: '/setting/tunnel', req: requestParams });
    const { tDirection } = response.data?.data || {};
    // 터널 방향이 존재하면 상태 업데이트
    if (tDirection) {
      return tDirection !== 'F';
    }
    return null;
  } catch (error) {
    console.error(error);
    throw new Error('error');
  }
};

// Tarea 데이터를 가져오는 hook
export const useFetchTareaData = ({ uniqueKey, dependencies, autoRefetch = true }: { uniqueKey: string; dependencies: any[]; autoRefetch?: boolean }) => {
  const [userInfo] = useRecoilState(userState);
  const { t } = useTranslation();
  // Tarea 데이터 요청 함수
  const fetchTareaData = async () => {
    try {
      const requestParams = { hCd: userInfo.hCd, sCd: userInfo.sCd };
      const response = await apiGet({ path: `/tarea/t/total`, req: requestParams });
      const { data } = response.data || {};
      return data?.tareaList || [];
    } catch (error) {
      console.error('API 호출 중 오류 발생:', error);
      // toast.error(t(ERROR));
      throw new Error('API 호출 중 오류 발생');
    }
  };
  // Tarea 데이터 요청 상태값
  const { data, isError, isLoading, isRefetching, refetch } = useQuery([`TunnelTotalInfo_${uniqueKey}`, userInfo.hCd, userInfo.sCd, ...dependencies], fetchTareaData, {
    refetchInterval: autoRefetch ? 60000 : false, // 60초마다 자동 refetch
  });
  return { data, isError, isLoading, isRefetching, refetch };
};

// Tarea detail 데이터를 가져오는 hook
export const useFetchTareaDetailData = ({ tatCd }: { tatCd: string }) => {
  const { t } = useTranslation();
  const [userInfo] = useRecoilState(userState);
  const updateTunnelSetting = useRecoilValue(tInfoUpdateState);

  // Tarea 데이터 요청 함수
  const fetchTareaData = async (userInfoParam: any) => {
    try {
      const requestParams = { hCd: userInfoParam.hCd, sCd: userInfoParam.sCd, tatCd };
      const response = await apiGet({ path: `/tarea/d/total`, req: requestParams });
      const { data } = response.data || {};
      return data?.tareaList || [];
    } catch (error) {
      console.error('API 호출 중 오류 발생:', error);
      // toast.error(t(ERROR));
      throw new Error('API 호출 중 오류 발생');
    }
  };

  // Tarea 데이터 요청 상태값
  const { data, isError, isLoading, refetch } = useQuery([`TunnelTotalInfo_detail`, userInfo.hCd, userInfo.sCd, updateTunnelSetting, tatCd], () => fetchTareaData(userInfo));
  return { data, isError, isLoading, refetch };
};

const START_CHAIM_AUDIO = 'https://itlogs3server.s3.ap-northeast-2.amazonaws.com/ProSafe2/bs_sound/STARTCHAIM.mp3';
export const startAudio = new Audio(START_CHAIM_AUDIO);

const END_CHAIM_AUDIO = 'https://itlogs3server.s3.ap-northeast-2.amazonaws.com/ProSafe2/bs_sound/ENDCHAIM.mp3';
export const endAudio = new Audio(END_CHAIM_AUDIO);

const googleCloudAPI = async (text: string, voice: any) => {
  const TTSURL = process.env.REACT_APP_GOOGLE_CLOUD_TTS_URL || '';
  const TTSCONFIG = {
    audioConfig: {
      audioEncoding: 'LINEAR16',
      effectsProfileId: ['small-bluetooth-speaker-class-device'],
      pitch: 0,
      speakingRate: 1,
    },
  };
  const response = await externalApiPost(TTSURL, { ...TTSCONFIG, input: { text }, voice });
  const audio = new Audio(`data:audio/mp3;base64,${response.data.audioContent}`);
  return audio;
};

// 입력 텍스트 길이 계산
export const getByteLength = (str: string) => {
  // UTF-8로 인코딩된 문자열의 byte 길이를 반환합니다.
  return new Blob([str]).size;
};

// 사용 가능한 스피커가 있는지 확인
export const hasAvailableSpeaker = (listData: any) => {
  const check = listData.filter((list: any) => list.getStatus() !== 3);
  return check.length === 0;
};

// 연결 중인 스피커가 있는지 확인
export const hasConnectingSpeaker = (listData: any) => {
  const connecting = listData.filter((el: any) => el.getStatus() === 0);
  return connecting.length > 0;
};

// 연결된 스피커 목록 가져오기
export const getConnectedSpeakers = (listData: any) => {
  return listData.filter((el: any) => el.getStatus() === 1);
};

// 텍스트 입력이 비어있는지 확인
export const isInputEmpty = (inputData: any) => {
  return inputData.ko === '' && inputData.ch === '' && inputData.vi === '' && inputData.en === '';
};

// 코드 정리 위한 헬퍼 함수
export const stopAudio = (list: any) => {
  startAudio.pause();
  startAudio.currentTime = 0;
  endAudio.pause();
  endAudio.currentTime = 0;
  list.forEach((item: any) => {
    item.pause();
    item.currentTime = 0;
  });
};

export const stopSpeakers = (list: any) => {
  list.forEach((el: any) => {
    el.stop();
    el.logout();
  });
  speaker.destroy();
};

type UsePlayTTS = { inputState: any; toggleStartAudio: boolean; setAudioList: Dispatch<SetStateAction<any>>; onClickStop: () => void };

export const playTTS = async ({ inputState, toggleStartAudio, setAudioList, onClickStop }: UsePlayTTS) => {
  setTimeout(() => {
    if (toggleStartAudio) {
      startAudio.play();
    }
  }, 500);

  const newAudioList: any[] = [];

  if (inputState.ko) {
    const koTTS: any = await googleCloudAPI(inputState.ko, {
      languageCode: 'ko-KR',
      name: 'ko-KR-Standard-A',
    });
    newAudioList.push(koTTS);
  }
  if (inputState.ch) {
    const chTTS: any = await googleCloudAPI(inputState.ch, {
      languageCode: 'cmn-CN',
      name: 'cmn-CN-Standard-A',
    });
    newAudioList.push(chTTS);
  }
  if (inputState.vi) {
    const viTTS: any = await googleCloudAPI(inputState.vi, {
      languageCode: 'vi-VN',
      name: 'vi-VN-Standard-A',
    });
    newAudioList.push(viTTS);
  }
  if (inputState.en) {
    const enTTS: any = await googleCloudAPI(inputState.en, {
      languageCode: 'en-US',
      name: 'en-US-Standard-A',
    });
    newAudioList.push(enTTS);
  }
  setAudioList(newAudioList);
  newAudioList.map((v: any, i: number) => {
    v.addEventListener(
      'ended',
      () => {
        if (newAudioList.length === i + 1) {
          onClickStop();
        } else {
          newAudioList[i + 1].play();
        }
      },
      { once: true }
    );
  });

  if (toggleStartAudio) {
    startAudio.addEventListener(
      'ended',
      () => {
        newAudioList[0].play();
      },
      { once: true }
    );
  } else {
    newAudioList[0].play();
  }
};
