/**
 * 작성자 : 한영광
 * 날짜 : 2023.05.23
 * 기능 : 주소 데이터로 경도, 위도 가져오는 KAKAO API요청 함수
 */
import axios from 'axios';

const API_KEY = process.env.REACT_APP_KAKAO_RESTAPI; // 발급받은 카카오 API 키

const getAddressInfo = async (query: string) => {
  try {
    const response = await axios.get(`https://dapi.kakao.com/v2/local/search/address.json?query=${query}`, {
      headers: {
        Authorization: `KakaoAK ${API_KEY}`,
      },
    });
    const { x, y } = response.data.documents[0];
    return { longitude: x, latitude: y };
  } catch (error) {
    console.error('카카오 로컬 API 호출 중 오류 발생:', error);
    return {};
  }
};

export default getAddressInfo;
