/**
 * 작성자 : 김광민
 * 날짜 : 2024.02.02
 * 경로 : 구역별 상황판 > 유해가스 종합현황 (원형, 수치형)
 */

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import TareaData from './TareaData';
import TareaEmptyDataRows from './TareaEmptyDataRows';

const SensorTableContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  padding: 0 0.5rem;
  display: flex;
  margin-bottom: 0.5rem;
  .column.header {
    padding-left: 0.5rem;
    display: flex;
    flex-direction: column;
    flex: 0 0 9rem;
    .row {
      font-size: 0.875rem;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      padding: 0;
      display: flex;
      align-items: center;
      &.header {
        height: 2.5rem;
        max-height: 2.5rem;
      }
      .material-symbols-rounded {
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        font-size: 1.25rem;
      }
      .counter {
        color: ${({ theme }: { theme: any }) => theme.text_disabled};
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        padding: 0 0.5rem;
        border-radius: 0.25rem;
      }
      .material-symbols-rounded,
      .counter {
        min-width: 1.5rem;
        text-align: center;
        margin-right: 0.375rem;
      }
    }
  }
  .column.body {
    display: flex;
    flex-grow: 1;
  }
  .row {
    flex-grow: 1;
    &:nth-child(n + 2) {
      border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    }
  }
`;

const SensorTable = ({ sensorList, tareaData, valueType }: any) => {
  const { t } = useTranslation();
  const [startTareaData, setStartTareaData] = useState<SensorData | null>(null);
  const [endTareaData, setEndTareaData] = useState<SensorData | null>(null);

  useEffect(() => {
    const findStartData = tareaData.find((el: any) => el.tatGubun?.toLowerCase() === 's');
    const findEndData = tareaData.find((el: any) => el.tatGubun?.toLowerCase() === 'e');
    if (findStartData) setStartTareaData(findStartData);
    if (findEndData) setEndTareaData(findEndData);
  }, [tareaData]);

  const renderSensorRows = () =>
    sensorList.map((el: any, idx: number) => (
      <div className='row' key={el.ssCd}>
        <span className='counter'>{idx + 1}</span>
        {el.cdName}
      </div>
    ));

  const renderTareaData = () => {
    if (startTareaData || endTareaData) {
      return (
        <>
          {startTareaData && <TareaData list={startTareaData} sensorList={sensorList} valueType={valueType} />}
          {endTareaData && <TareaData list={endTareaData} sensorList={sensorList} valueType={valueType} />}
        </>
      );
    }
    return <TareaEmptyDataRows sensorList={sensorList} />;
  };

  return (
    <SensorTableContainer>
      <div className='column header'>
        <div className='row header'>
          <span className='material-symbols-rounded'>sensors</span>
          {t('센서명')}
        </div>
        {renderSensorRows()}
      </div>
      <div className='column body'>{renderTareaData()}</div>
    </SensorTableContainer>
  );
};

export default SensorTable;
