import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { useTranslation } from 'react-i18next';
import { todayYYYYMMDD } from '../../../utils/formatDate';
import { useSetSjcd } from '../../../utils/useSetSjcd';
import useOnKeydownF9 from '../../../utils/useOnKeydownF9';
import useSetJobtype from '../../../utils/useSetJobtype';
import { INIT_WORKER_STATUS, LIST_COUNT, WORK_STATUS_SEARCH } from '../../../_constants';
import { useRecoilValue } from 'recoil';
import { IUser, userState } from '../../../atoms';
import { IComCdList } from 'customTypes';
import useSetListCount from '../../../utils/useSetListCount';
import SelectBox from '../../../components/SelectBox';
import SearchSelectBoxSm from '../../../components/SearchSelectBoxSm';
import RangePicker, { onChangeRangeInput } from '../../../components/RangePicker';
import Input from '../../../components/Input';
import ShortcutButton from '../../../components/button/ShortcutButton';
import { BtnGhost } from '../../../components/Button';
import { toast } from 'react-toastify';
import { setComCdListState } from '../../../utils/setComCdListState';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { apiGet } from '../../../services/_common';

interface ISafeeduInquirySearchOption {
  setIsFetching: Dispatch<SetStateAction<boolean>>; // 검색데이터 패칭상태
  setIsError: Dispatch<SetStateAction<boolean>>;
  tableState: any[];
  setTableState: Dispatch<SetStateAction<any[]>>;
  filterTableState: any[];
  setFilterTableState: Dispatch<SetStateAction<any[]>>;
  setRowKey: Dispatch<SetStateAction<string>>;
  checkList: any[];
  listCount: any;
  setListCount: Dispatch<SetStateAction<any>>;
  refetchFlag: boolean;
  setRefetchFlag: Dispatch<SetStateAction<boolean>>;
}

const SafeeduInquirySearchOption = ({
  setIsFetching,
  setIsError,
  tableState,
  setTableState,
  filterTableState,
  setFilterTableState,
  setRowKey,
  checkList,
  listCount,
  setListCount,
  refetchFlag,
  setRefetchFlag,
}: ISafeeduInquirySearchOption) => {
  const { t } = useTranslation();
  const toDay = todayYYYYMMDD();
  const userInfo = useRecoilValue<IUser>(userState);
  const { siteJoinInfoList } = useSetSjcd();
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const [wPrejobtype, setWPrejobtype] = useState({ type: 'wPrejobtype', wPrejobtype: '', cdName: '' });
  const { prejobtypeList, jobtypeList } = useSetJobtype(wPrejobtype.wPrejobtype);
  const [sjCd, setSjCd] = useState({ type: 'sjCd', sjCd: '', cdName: '' });
  const [wWorkstatus, setWWorkstatus] = useState(INIT_WORKER_STATUS);
  const [searchOption, setSearchOption] = useState({ wName: '' });
  const [visibleRangePicker, setVisibleRangePicker] = useState(false);
  const [pickerDateRange, setPickerDateRange] = useState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
  const [userInputDateRange, setUserInputDateRange] = useState({ start: toDay, end: toDay });
  const [initiate, setinitiate] = useState('');
  const [jikJongList, setJikJongList] = useState<any[]>([]);
  const [wJobtype, setWJobtype] = useState({ type: 'wJobtype', wJobtype: '', cdName: '' });
  const [listCountComCdList, setListCountComCdList] = useState<IComCdList[]>([]); // 검색줄수 공통코드
  const { patchUserMenuAPI } = useSetListCount(); // 검색줄수설정 훅
  const wNameRef = useRef<HTMLInputElement>(null);

  const { data, isLoading, isFetching, isError, refetch } = useQuery(['workerList', userInfo.hCd, userInfo.sCd], () => fetchData(), {
    enabled: false,
    cacheTime: 0,
  });

  useEffect(() => {
    if (refetchFlag) refetch();
  }, [refetchFlag]);

  const fetchData = async () => {
    try {
      const path = '/worker';
      const req = {
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        wName: searchOption.wName,
        wWorkstatus: wWorkstatus.wWorkstatus === 'A' ? '' : wWorkstatus.wWorkstatus,
        sjCd: sjCd.sjCd,
        wJobdate1: userInputDateRange.start.replaceAll('-', ''),
        wJobdate2: userInputDateRange.end.replaceAll('-', ''),
        wPrejobtype: wPrejobtype.wPrejobtype,
        wJobtype: wJobtype.wJobtype,
      };
      const res = await apiGet({ path, req });
      const newArray = res.data.data.workerList.filter((v: any) => v.recordFile);
      setTableState(
        newArray.map((v: any) => {
          return {
            ...v,
            playing: false,
          };
        })
      );
      return newArray;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    } finally {
      setRefetchFlag(false);
    }
  };
  useEffect(() => {
    setComCdListState(LIST_COUNT, setListCountComCdList, false);
  }, []);

  useEffect(() => {
    setIsFetching(isFetching);
  }, [isLoading, isFetching]);

  useEffect(() => {
    setIsError(isError);
  }, [isError]);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    setWJobtype({ type: 'wJobtype', wJobtype: '', cdName: '' });
    if (wPrejobtype.wPrejobtype === '') setJikJongList([]);
  }, [wPrejobtype.wPrejobtype]);

  useEffect(() => {
    setJikJongList(jobtypeList);
  }, [jobtypeList]);

  useEffect(() => {
    if (pickerDateRange[0].startDate) {
      setUserInputDateRange((prev: any) => ({ ...prev, start: dayjs(pickerDateRange[0].startDate).format('YYYY-MM-DD') }));
    }
    if (pickerDateRange[0].endDate) {
      setUserInputDateRange((prev: any) => ({ ...prev, end: dayjs(pickerDateRange[0].endDate).format('YYYY-MM-DD') }));
    }
  }, [pickerDateRange[0].startDate, pickerDateRange[0].endDate]);

  // 검색 버튼 클릭
  const onClickSearch = () => {
    setRowKey('');
    if (userInputDateRange.end && userInputDateRange.start > userInputDateRange.end) {
      toast.warning(t('날짜를 다시 입력하세요'));
      setSearchOption((prev) => ({ ...prev, end: '' }));
    } else {
      setVisibleRangePicker(false);
      refetch();
    }
  };

  const onClickRangeInput = () => {
    if (!visibleRangePicker) setVisibleRangePicker(true);
  };

  const initiateSearchOptions = () => {
    setWWorkstatus(INIT_WORKER_STATUS);
    setSjCd({ type: 'sjCd', sjCd: '', cdName: '' });
    setWPrejobtype({ type: 'wPrejobtype', wPrejobtype: '', cdName: '' });
    setWJobtype({ type: 'wJobtype', wJobtype: '', cdName: '' });
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    setSearchOption({ wName: '' });
    setinitiate(`${Math.random()}`);
    initiateSearchOptions();
    setPickerDateRange([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
    setUserInputDateRange({ start: '', end: '' });
  };

  // 근로자명 입력창에서 엔터입력시 검색
  const loginOnEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (wNameRef.current) onClickSearch();
    }
  };

  const onClickDownloadRecordFiles = () => {
    checkList.forEach((item, index) => {
      if (item.recordFile) {
        setTimeout(() => {
          const fileName = `${item.wName}(${item.wCd})_${item.sjName}.mp3`;
          downloadFile(item.recordFile, fileName || 'download.mp3');
        }, index * 1000); // Delay each download by 1 second
      }
    });
  };

  const downloadFile = async (audioUrl: any, filename: string) => {
    try {
      const response = await fetch(audioUrl);
      if (!response.ok) throw new Error('Network response was not ok');
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      console.error('There was an error:', err);
    }
  };

  return (
    <SearchOptions>
      <div className='inputsWrapper'>
        <div className='inputForm-col withLabelComCf'>
          <label htmlFor='wWorkstatus'>{t('근로 상태')}</label>
          <SelectBox
            options={WORK_STATUS_SEARCH}
            defaultOption={t('재직')}
            state={wWorkstatus}
            setState={setWWorkstatus}
            stateKey='wWorkstatus'
            initiateKey={wWorkstatus.wWorkstatus}
            filterbar='filter-1-left'
          />
        </div>
        <div className='inputForm-row withLabel'>
          <label htmlFor='sjCd'>{t('협력업체')}</label>
          <div className='inputForm-col'>
            <SearchSelectBoxSm
              options={siteJoinInfoList}
              defaultOption={t('전체')}
              state={sjCd}
              setState={setSjCd}
              stateKey='sjCd'
              codeKey='cdName'
              initiateKey={sjCd.sjCd}
              filterbar='filter-1-left'
              optionHeight='height-md'
            />
          </div>
        </div>
        <div className='inputForm-row-fit'>
          <span className='calendarLabel'>{t('취업일자')}</span>
          <div className='inputForm-col'>
            <div className='flex-basic'>
              <input
                id='startInput'
                type='text'
                value={userInputDateRange.start}
                onMouseDown={onClickRangeInput}
                onChange={(e) => onChangeRangeInput(e, 'start', setPickerDateRange, setUserInputDateRange)}
              />
              <span className='inputDash'> ~ </span>
              <input
                id='endInput'
                type='text'
                value={userInputDateRange.end}
                onMouseDown={onClickRangeInput}
                onChange={(e) => onChangeRangeInput(e, 'end', setPickerDateRange, setUserInputDateRange)}
              />
            </div>
            {visibleRangePicker && (
              <div className='rangePickerWrapper'>
                <RangePicker state={pickerDateRange} setState={setPickerDateRange} setVisible={setVisibleRangePicker} />
              </div>
            )}
          </div>
        </div>
        {userInfo.prejobtypeYn === 'Y' && (
          <div className='inputForm-row'>
            <div className='inputForm-col'>
              <SearchSelectBoxSm
                options={prejobtypeList}
                defaultOption={t('공종 전체')}
                state={wPrejobtype}
                setState={setWPrejobtype}
                stateKey='wPrejobtype'
                codeKey='cdName'
                initiateKey={initiate}
                filterbar='filter-1-right'
                comboWidth='expand-box-sm'
                optionHeight='height-md'
              />
            </div>
          </div>
        )}
        <div className='inputForm-row'>
          <div className='inputForm-col'>
            <SearchSelectBoxSm
              options={jikJongList}
              defaultOption={t('직종 전체')}
              state={wJobtype}
              setState={setWJobtype}
              stateKey='wJobtype'
              codeKey='cdName'
              initiateKey={wJobtype.wJobtype}
              filterbar='filter-1-right'
              comboWidth='expand-box-sm'
              optionHeight='height-md'
            />
          </div>
        </div>
        <div className='inputForm-row'>
          <div className='inputForm-col'>
            <Input placeholder={t('근로자명')} label='' type='text' id='wName' name='wName' state={searchOption} setState={setSearchOption} inputRef={wNameRef} onKeyDown={loginOnEnterKeyDown} />
          </div>
        </div>
      </div>
      <div className='inputsWrapper'>
        <div className='secondSearchOption'>
          <div className='flex-basic textBtnGroup'>
            <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
            <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
            <div className='searchResult'>
              {t('총')}
              <span>{tableState.length}</span>
              {t('개')}
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col withLabelComCf'>
                <label htmlFor='useYn'>{t('보기 설정')}</label>
                <SelectBox
                  options={listCountComCdList}
                  defaultOption={listCount.cdName}
                  state={listCount}
                  setState={setListCount}
                  stateKey={LIST_COUNT}
                  initiateKey={listCount[LIST_COUNT]}
                  setTableLinesAPI={patchUserMenuAPI}
                  optionHeight='height-sm'
                  rsearch
                />
              </div>
            </div>
          </div>
          <div className='flex-basic iconBtnGroup'>
            <BtnGhost onClick={onClickDownloadRecordFiles}>
              <span className='material-symbols-rounded'>download </span>
              {t('선택 다운로드')}
            </BtnGhost>
          </div>
        </div>
      </div>
    </SearchOptions>
  );
};

export default SafeeduInquirySearchOption;
