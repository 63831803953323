/**
 * 작성자 : 한영광
 * 날짜 : 2023.05.11
 * 기능 : 이미지 타입 변경 함수.
 *        file을 base64로 바꿔준다.
 */

export function fileToBase64(file: File, cb: Function) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
}
