/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.15
 * 경로 : 터널관리, 대시보드 & 상황판 > 터널
 */

import styled from 'styled-components';

const MultiTunnelTotalCard = styled.div<{ carouselWidth: number }>`
  user-select: none;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 0 0.25rem;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
  &.flexGrow1 {
    flex-grow: 2;
  }
  &.flexGrow2 {
    flex-grow: 1;
  }
  .card {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5rem;
    padding-bottom: 3rem;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      .titleName {
        box-shadow: 0px 4px 12px 4px rgba(0, 0, 0, 0.05);
        background-color: ${({ theme }: { theme: any }) => theme.board};
      }
      .chart {
        width: 7.25rem;
      }
    }
    .titleName {
      min-width: 8rem;
      z-index: 100;
      position: absolute;
      top: 0;
      transform: translateY(25%);
      text-align: center;
      border-radius: 0.25rem;
      border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      color: ${({ theme }: { theme: any }) => theme.filled_blue};
      font-size: 0.875rem;
      padding: 0.125rem 0.5rem;
      word-break: keep-all;
      transition: ease-in-out 0.25s;
    }
    .attendCount {
      position: absolute;
      bottom: 1rem;
      transform: translateY(25%);
    }
    .chart {
      width: 7rem;
      height: 8rem;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      transition: ease-in-out 0.25s;
      canvas {
        position: absolute;
      }
      .percentage {
        z-index: 10;
        padding-top: 1.75rem;
        padding-top: 2rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.75rem;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
        margin-left: 0.5rem;
        .symbol {
          font-size: 0.75rem;
          margin-left: 0.125rem;
          color: ${({ theme }: { theme: any }) => theme.text_disabled};
        }
      }
      .title {
        position: absolute;
        bottom: 0;
        transform: translateY(25%);
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      }
    }
  }
  &.dummy {
    margin-bottom: 0.25rem;
    align-items: center;
    .card {
      pointer-events: none;
      max-width: 18rem;
      padding: 0;
      flex-direction: row-reverse;
      width: 100%;
      gap: 0.5rem;
      .titleInfo {
        flex-grow: 1;
        min-height: 5rem;
        .titleName {
          text-align: left;
          gap: 0.25rem;
          border: none;
          position: static;
          padding: 0.375rem 0;

          span.label {
            font-weight: 400;
            /* font-size: 0.75rem; */
            color: ${({ theme }: { theme: any }) => theme.text_tertiary};
            color: ${({ theme }: { theme: any }) => theme.filled_blue};
            background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
            border-radius: 0.25rem;
            padding: 0.25rem 0.5rem;
            margin-right: 0.5rem;
          }
        }
        .attendCount {
          position: static;
          display: flex;
          .tunnelChip {
            height: 1.875rem;
            padding: 0;
            .material-symbols-rounded {
              font-size: 0.875rem;
            }
          }
        }
      }
      .chart {
        width: 5rem;
        height: 5rem;
        .percentage {
          font-size: 1.25rem;
          top: 35%;
          transform: translateY(-50%);
          margin-left: 0.125rem;
          line-height: 2rem;
        }
        .title {
          display: none;
        }
      }
    }
    .contentBox {
      width: 100%;
    }
  }
  .contentBox {
    display: flex;
    gap: 0.5rem;
  }
  &.dashboard {
    margin-bottom: 0.25rem;
    align-items: center;
    .card {
      max-width: 18rem;
      padding: 0;
      justify-content: center;
      width: 100%;
      gap: 0;
      .titleInfo {
        .titleName {
          text-align: left;
          border: none;
          position: absolute;
          top: 50%;
          left: calc(50% - 2.5rem);
          transform: translate(-100%, -50%);
          padding: 0.25rem 1rem;
          background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
          border-radius: 1.5rem;
          min-width: auto;
          span.label {
            display: none;
            font-weight: 400;
            color: ${({ theme }: { theme: any }) => theme.text_tertiary};
            color: ${({ theme }: { theme: any }) => theme.filled_blue};
            background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
            border-radius: 0.25rem;
            padding: 0.25rem 0.5rem;
            margin-right: 0.5rem;
          }
        }
        .attendCount {
          display: flex;
          top: 50%;
          right: calc(50% - 2.5rem);
          transform: translate(100%, -50%);
          height: fit-content;
          .tunnelChip {
            padding: 0;
            .material-symbols-rounded {
              font-size: 0.875rem;
            }
          }
        }
      }
      .chart {
        width: 5rem;
        height: 5rem;
        .percentage {
          font-size: 1.25rem;
          top: 35%;
          transform: translateY(-50%);
          margin-left: 0.125rem;
          line-height: 2rem;
        }
        .title {
          display: none;
        }
      }
      &:hover {
        background-color: transparent;
        .titleName {
          box-shadow: 0px 4px 8px 0 rgba(0, 0, 0, 0.15);
          background-color: ${({ theme }: { theme: any }) => theme.board};
          outline: 1px solid ${({ theme }: { theme: any }) => theme.filled_blue};
        }
      }
    }
    .contentBox {
      width: 100%;
    }
  }
  &.siteDashboard {
    pointer-events: none;
    .card {
      position: absolute;
      top: -0.5rem;
      .chart {
        height: 5rem;
        .percentage {
          top: 44%;
          font-size: 1.375rem;
          margin-left: 0.25rem;
        }
      }
      .titleInfo {
        .titleName,
        .attendCount {
          top: 60%;
        }
        .titleName {
          left: calc(50% - 3rem);
        }
        .attendCount {
          right: calc(50% - 3rem);
        }
      }
    }
    .contentBox {
      padding-top: 5rem;
      .capsule {
        height: 1.25rem;
      }
    }
  }
`;

const MultiTunnelContainer = styled.div`
  user-select: none;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  flex-grow: 1;
  &.onePoint {
    width: 14rem;
  }
  &.twoPoints {
    width: 24rem;
  }
  .card {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5rem;
    padding-bottom: 3rem;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
    .titleName {
      min-width: 8rem;
      z-index: 100;
      position: absolute;
      top: 0;
      transform: translateY(25%);
      text-align: center;
      border-radius: 0.25rem;
      border-radius: 1rem;
      border: 1px solid transparent;
      font-size: 0.875rem;
      padding: 0.125rem 0.5rem;
      word-break: keep-all;
      transition: ease-in-out 0.25s;
      background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
      color: ${({ theme }: { theme: any }) => theme.filled_blue};
    }
    .attendCount {
      position: absolute;
      bottom: 1rem;
      transform: translateY(25%);
    }
    .chart {
      width: 7rem;
      height: 8rem;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      transition: ease-in-out 0.25s;
      canvas {
        position: absolute;
      }
      .percentage {
        z-index: 10;
        padding-top: 1.75rem;
        padding-top: 2rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.75rem;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
        margin-left: 0.5rem;
        .symbol {
          font-size: 0.75rem;
          margin-left: 0.125rem;
          color: ${({ theme }: { theme: any }) => theme.text_disabled};
        }
      }
      .title {
        position: absolute;
        bottom: 0;
        transform: translateY(25%);
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      }
    }
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      .titleName {
        box-shadow: 0px 4px 12px 4px rgba(0, 0, 0, 0.05);
        background-color: ${({ theme }: { theme: any }) => theme.board};
        border: 1px solid ${({ theme }: { theme: any }) => theme.filled_blue};
      }
      .chart {
        width: 7.25rem;
      }
    }
  }
  &.dashboard {
    .card {
      pointer-events: none;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 1.25rem;
      flex-direction: row-reverse;
      flex-direction: row;
      .titleName {
        left: 7rem;
        text-align: left;
        border: none;
      }
      .chart {
        width: 5rem;
        height: 5rem;
        .percentage {
          font-size: 1.375rem;
          top: 35%;
          transform: translateY(-50%);
          margin-left: 0.125rem;
          line-height: 2rem;
        }
        .title {
          display: none;
        }
      }
      .attendCount {
        left: 7rem;
      }
    }
  }
  .contentBox {
    display: flex;
    gap: 0.5rem;
  }
`;

const TunnelPointContainer = styled.div`
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: calc(50% - 0.25rem);
  > div {
    flex-grow: 0;
    width: 100%;
  }

  .bigo {
    display: flex;
    gap: 0.5rem;
  }
  .capsule-group {
    display: flex;
    gap: 0.5rem;
    &.reverse {
      flex-direction: row-reverse;
    }
  }
  .detailPoint {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  .detailPoint.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.25rem;
  }
  &.single {
    width: calc(50% - 0.25rem);
    min-width: 8rem;
    &.dashboard {
      .bigo {
        flex-direction: row;
      }
    }
  }
  &.siteDashboard {
    gap: 0.25rem;
    width: calc(50% - 0.25rem);
  }
`;

const SingleTunnelContainer = styled.div`
  user-select: none;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @media (min-width: 1560px) {
    &.flexGrow1 {
      flex-grow: 1;
    }
    &.flexGrow2 {
      flex-grow: 2;
    }
  }
  .chart {
    flex-shrink: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 1rem;
    padding: 1rem 0;
    cursor: pointer;
    height: 12rem;
    @media (min-width: 1024px) {
      height: 16rem;
    }
    &:hover {
      font-weight: 400;
      .titleName {
        box-shadow: 0px 4px 12px 4px rgba(0, 0, 0, 0.05);
      }
      .titleBox {
        visibility: visible;
      }
    }
    .titleName {
      z-index: 100;
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      border-radius: 0.25rem;
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      color: ${({ theme }: { theme: any }) => theme.filled_blue};
      font-size: 0.875rem;
      padding: 0.25rem 0.5rem;
      border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      transition: ease-in-out 0.25s;
      .seperator {
        border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
        font-size: 0.75rem;
        height: 0.75rem;
        width: 1px;
        margin: 0 0.5rem;
      }
      .label {
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      }
    }
    .percentage {
      padding-top: 3.5rem;
      font-size: 2rem;
      font-size: 3.5rem;
      font-weight: 600;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      margin-left: 0.75rem;
      .symbol {
        font-size: 1.25rem;
        color: ${({ theme }: { theme: any }) => theme.text_disabled};
      }
    }
    canvas {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .title {
      font-size: 0.875rem;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    }
  }
  .attendCount {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0.5rem;
  }
  .contentBox {
    display: flex;
    gap: 0.5rem;
    flex-grow: 1;
  }
  &.dashboard {
    width: calc(100% - 8.5rem);
    .chart {
      height: 6.75rem;
      background-color: transparent;
      padding: 0;
      margin-bottom: 0;
      .titleName {
        box-shadow: none;
        position: absolute;
        top: 50%;
        transform: translate(-100%, -60%);
        left: calc(50% - 3.5rem);
        border: none;
        padding: 0.25rem 0.75rem;
        padding: 0.25rem 1.5rem;
        border-radius: 1rem;
        height: 2rem;
        background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
        outline: 1px solid transparent;
      }
      canvas {
        top: -0.5rem;
      }
      .percentage {
        padding-bottom: 0.125rem;
        font-size: 1.5rem;
        margin-left: 0.25rem;
        padding-top: 0;
        .symbol {
          font-size: 0.75rem;
          margin-left: 0.125rem;
        }
      }
      .title {
        display: none;
      }
      &:hover {
        font-weight: 400;
        .titleName {
          box-shadow: 0px 4px 8px 0 rgba(0, 0, 0, 0.15);
          outline: 1px solid ${({ theme }: { theme: any }) => theme.filled_blue};
          background-color: ${({ theme }: { theme: any }) => theme.board};
        }
        .titleBox {
          visibility: visible;
        }
      }
    }
    .attendCount {
      top: 50%;
      bottom: auto;
      transform: translate(100%, -60%);
      right: calc(50% - 3.5rem);
    }
  }
  &.siteDashboard {
    position: relative;
    justify-content: end;
    .chart {
      position: absolute;
      top: -2rem;
      height: 6rem;
      .percentage {
        padding-top: 1rem;
        font-size: 1.75rem;
        font-weight: 600;
        margin-left: 0.5rem;
      }
      .titleName {
        transform: translate(-100%, -35%);
        left: calc(50% - 4rem);
      }
      .attendCount {
        transform: translate(100%, -35%);
        right: calc(50% - 4rem);
      }
    }

    .contentBox {
      flex-grow: 0 !important;
    }
  }
`;

export { SingleTunnelContainer, MultiTunnelContainer, TunnelPointContainer, MultiTunnelTotalCard };
