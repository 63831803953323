/**
 * 작성자 : 한영광
 * 날짜 : 2023.08.09
 * 경로 : 현장정보/CCTV - 현장연결 (src/pages/h_cctv/H_cctv2)
 */
import { useEffect, useState, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { IUser, pageInfoState, userState } from '../../atoms';
import { BtnGhost } from '../../components/Button';
import { COMCD_S_STATUS, COMCD_USE_YN, LIST_COUNT } from '../../_constants';
import { SearchOptions } from '../../assets/styles/SearchOptions';
import Tab from '../../components/Tab';
import Input from '../../components/Input';
import { IComCd, IComCdList } from 'customTypes';
import { setComCdListState } from '../../utils/setComCdListState';
import { logPost } from '../../services/log';
import TuiGrid from '../../components/Table/TuiGrid';
import SelectBox from '../../components/SelectBox';
import { useTranslation } from 'react-i18next';
import i18n from '../../translation/i18n';
import { apiGet } from '../../services/_common';

const Root = styled.div`
  .textBtnGroup button {
    padding: 0 0.75rem;
    width: 4.5rem;
    height: 2.5rem;
    font-size: 0.875rem;
    font-weight: 500;
  }
  .tui-container {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
  }
  .table {
    max-height: 65vh;
  }
  .tr {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .mtName,
    .mdName,
    .mrName,
    .bigo {
      justify-content: left;
    }
  }
`;

interface ITableObject {
  index: number;
  mtCd: string;
  hCd: string;
  hName: string;
  sCd: string;
  sName: string;
  sStatus: string;
  sDate: string;
  fDate: string;
  bigo: string;
  useYn: string;
}

const Hcctv2 = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useRecoilState<IUser>(userState);
  const { userMenuList } = userInfo;
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  const tableRef = useRef<HTMLDivElement>(null);
  const [sStatusComCdList, setSStatusComCdList] = useState<IComCdList[]>([]); // 현장상태 운영여부 공통코드
  const [listCountComCdList, setListCountComCdList] = useState<IComCdList[]>([]); // 검색줄수 공통코드
  const [useYnComCdList, setUseYnComCdList] = useState<IComCdList[]>([]); // 사용유무 공통코드
  const [columns, setColumns] = useState<any[]>([]);
  const [searchOption, setSearchOption] = useState({
    sName: '',
    bigo: '',
  });
  const [listCount, setListCount] = useState({
    type: LIST_COUNT,
    [LIST_COUNT]: '15',
    cdName: '15',
  });
  const [sStatus, setSStatus] = useState<IComCd>({
    subCd: 'A',
    cdName: t('전체'),
    cdSort: 0,
  });
  const [useYn, setUseYn] = useState<IComCd>({
    subCd: 'A',
    cdName: t('전체'),
    cdSort: 0,
  });

  useEffect(() => {
    setListCount({ LIST_COUNT: '15', cdName: '15', type: 'LIST_COUNT' });
    setSStatus({ subCd: 'A', cdName: t('전체'), cdSort: 0 });
    setUseYn({ subCd: 'A', cdName: t('전체'), cdSort: 0 });
  }, []);

  const [excelBtn, setExcelBtn] = useState(false);
  const [tableState, setTableState] = useState<ITableObject[]>([]);
  const [initTableState, setInitTableState] = useState<ITableObject[]>([]);
  // const { isBottom } = useDetectScrolledToBottom(tableRef);

  useEffect(() => {
    getSiteAPI().then((res: any) => {
      if (res.status === 200) {
        setComCdListState(COMCD_S_STATUS, setSStatusComCdList, true);
        setComCdListState(COMCD_USE_YN, setUseYnComCdList, true);
        setComCdListState(LIST_COUNT, setListCountComCdList, false);
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '현장 연결',
          action: '조회',
          etc: ``,
        });
      }
    });
    setColumns([
      {
        header: t('현장코드'),
        name: 'sCd',
        align: 'center',
        sortable: true,
        width: 100,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('현장명'),
        name: 'sName',
        sortable: true,
        minWidth: 120,
        renderer: { classNames: ['text_secondary'] },
        // filter: 'select',
      },
      {
        header: t('운영상태'),
        name: 'sStatusCdName',
        align: 'center',
        sortable: true,
        minWidth: 100,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('시공일자'),
        name: 'sDate',
        align: 'center',
        sortable: true,
        minWidth: 120,
      },
      {
        header: t('종료일자'),
        name: 'fDate',
        align: 'center',
        sortable: true,
        minWidth: 120,
      },
      {
        header: t('사용유무'),
        name: 'useYnCdName',
        align: 'center',
        sortable: true,
        minWidth: 100,

        // filter: 'select',
      },
      {
        header: t('비고'),
        name: 'bigo',
        sortable: true,
        minWidth: 160,
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    // 검색옵션 변경됐을 때 필터링 처리
    // 필터링 기준
    const filterOptions = {
      sName: searchOption.sName,
      bigo: searchOption.bigo,
    };

    // 필터링된 어레이 리턴, 대소문자구분X
    const filteredArray = initTableState.filter((item: any) => {
      return item.sName?.toLowerCase()?.includes(filterOptions.sName?.toLowerCase()) && item.bigo?.toLowerCase()?.includes(filterOptions.bigo?.toLowerCase());
    });

    const sStatusFilter = YNFilter(filteredArray, 'sStatus', sStatus.subCd);
    const result = YNFilter(sStatusFilter, 'useYn', useYn.subCd);

    if (result.length > 0) {
      setTableState(result);
    } else {
      setTableState([]);
    }
  }, [searchOption, useYn.subCd, sStatus.subCd]);

  const YNFilter = (initialArray: ITableObject[], key: string, option: any) => {
    if (option === 'Y') {
      return initialArray.filter((el: any) => el[key] === 'Y');
    }
    if (option === 'N') {
      return initialArray.filter((el: any) => el[key] === 'N');
    }

    return initialArray;
  };

  // 현장 정보 리스트 조회 API
  const getSiteAPI = async () => {
    const res = await apiGet({ path: '/site' });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const newTableState: Array<any> = [];
      for (const v of data.siteInfoList) {
        if (v.hCd === userInfo.hCd) {
          newTableState.push({
            hCd: v.hCd,
            hName: v.hName,
            sCd: v.sCd,
            sName: v.sName,
            sStatus: v.sStatus,
            sStatusCdName: v.sStatus === 'Y' ? t('운영중') : t('종료'),
            sDate: v.sDate,
            fDate: v.fDate,
            bigo: v.bigo,
            useYn: v.useYn,
            useYnCdName: v.useYn === 'Y' ? t('사용') : t('미사용'),
          });
        }
      }
      setTableState(newTableState);
      setInitTableState(newTableState);
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  // 현장 정보 조회 API
  const getSiteInfoAPI = async (hCd: string, sCd: string) => {
    const req = { hCd, sCd };
    const res = await apiGet({ path: '/site/info', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      getSiteSetAPI(hCd, sCd, data.siteInfo);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 현장 설정 정보 조회 API
  const getSiteSetAPI = async (hCd: string, sCd: string, siteInfo: any) => {
    const req = { hCd, sCd };
    const res = await apiGet({ path: '/setting/general', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      setUserInfo((prev) => ({
        ...prev,
        hCd,
        sCd,
        sName: siteInfo.sName,
        eExcelYn: siteInfo.eExcelYn,
        eListYn: siteInfo.eListYn,
        ePrintYn: siteInfo.ePrintYn,
        prejobtypeYn: data.generalSet.prejobtypeYn,
        elderlyAgeSet: data.generalSet.elderlyAgeSet,
        sessionTimeout: data.generalSet.sessionTimeout,
        eduPlace: data.generalSet.eduPlace,
        eduCharge: data.generalSet.eduCharge,
        eduStartTime: data.generalSet.eduStartTime,
        eduEndTime: data.generalSet.eduEndTime,
        fromHighpress: data.generalSet.fromHighpress,
        fromLowpress: data.generalSet.fromLowpress,
        toHighpress: data.generalSet.toHighpress,
        toLowpress: data.generalSet.toLowpress,
        accidentEdate: data.generalSet.accidentEdate,
        accidentSdate: data.generalSet.accidentSdate,
        accidentTday: data.generalSet.accidentTday,
      }));
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 현장 메뉴 조회 API
  const getMenuSiteInfoAPI = async (hCd: string, sCd: string) => {
    const res = await apiGet({ path: '/menu/siteInfo', req: { hCd, sCd } });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      setUserInfo((prev) => ({
        ...prev,
        userMenuList: data.filter((v: any) => v.useYn === 'Y'),
      }));
    } else {
      // toast.error(t(ERROR));
    }
  };

  const onClickRow = (rowData: any, columnName: string) => {
    getSiteInfoAPI(rowData.hCd, rowData.sCd);
    getMenuSiteInfoAPI(rowData.hCd, rowData.sCd);
    toast.info(`${t('본사')}(${rowData.hCd}), ${t('현장')}(${rowData.sCd}) ${t('코드 전역변수에 저장')}`);
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    setSearchOption((prev: any) => ({ ...prev, sName: '', bigo: '' }));
    setUseYn({ subCd: 'A', cdName: t('전체'), cdSort: 0 });
    setSStatus({ subCd: 'A', cdName: t('전체'), cdSort: 0 });
    // setListCount({ subCd: '15', cdName: '15', cdSort: 0 });
  };

  const onClickTab = (mrCd: string) => {
    setCurrentTabMrCd(mrCd);
  };
  const componentRef = useRef<HTMLDivElement>(null);
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight, componentRef.current?.offsetWidth]);
  return (
    <div className='contents'>
      <Tab tabList={tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      <div className='content-container oneColumn'>
        <Root>
          <SearchOptions>
            {/* {isBottom && (
              <FloatingButtonToTop>
                <button type='button' onClick={() => scrollToNodeTop(tableRef)}>
                  <IoChevronUpSharp size={20} style={{ stroke: 'white' }} />
                </button>
              </FloatingButtonToTop>
            )} */}
            <div className='inputsWrapper'>
              <div className='flex-basic textBtnGroup'>
                <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
              </div>

              <div className='inputForm-row'>
                <div className='inputForm-col withLabelComCf'>
                  <label htmlFor='useYn'>{t('보기 설정')}</label>
                  <SelectBox
                    options={listCountComCdList}
                    defaultOption={listCount[LIST_COUNT]}
                    state={listCount}
                    setState={setListCount}
                    stateKey={LIST_COUNT}
                    initiateKey={listCount[LIST_COUNT]}
                    optionHeight='height-sm'
                    rsearch
                  />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col withLabelComCf'>
                  <label htmlFor='sStatus'>{t('운영상태')}</label>
                  <div>
                    <SelectBox options={sStatusComCdList} defaultOption={sStatus.cdName} state={sStatus} setState={setSStatus} initiateKey={sStatus.cdName} filterbar='filter-1-leftToCenter' />
                  </div>
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col withLabelComCf'>
                  <label htmlFor='useYn'>{t('사용유무')}</label>
                  <div>
                    <SelectBox options={useYnComCdList} defaultOption={useYn.cdName} state={useYn} setState={setUseYn} initiateKey={useYn.cdName} filterbar='filter-1-center' />
                  </div>
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <Input label='' placeholder={t('현장명')} type='text' id='sName' name='sName' state={searchOption} setState={setSearchOption} />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <Input label='' placeholder={t('비고')} type='text' id='bigo' name='bigo' state={searchOption} setState={setSearchOption} maxLength={200} />
                </div>
              </div>
            </div>
          </SearchOptions>
          <div ref={componentRef} className='tui-container'>
            <TuiGrid
              data={tableState}
              columns={columns}
              perPage={Number(listCount[LIST_COUNT])}
              excelBtn={excelBtn}
              setExcelBtn={setExcelBtn}
              usePagenation
              onClickRow={onClickRow}
              frozenCount={1}
              height={tuiHeight}
            />
          </div>
        </Root>
      </div>
    </div>
  );
};

export default Hcctv2;
