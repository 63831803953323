/**
 * 작성자 : 홍선영
 * 날짜 : 2023.10.06
 * 기능 : 모바일 여부 확인
 */

export const isMobile = () => {
  const check = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  if (check) return true;
  return false;
};
