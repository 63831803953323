/**
 * 작성자 : 한영광
 * 날짜 : 2023.05.23
 * 경로 : 현장정보/CCTV -> 현장관리 (src/pages/h_cctv/H_cctv1)
 */
import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

import { ITabObject } from 'customTypes';
import { pageInfoState, userState } from '../../atoms';
import illustrator from '../../assets/images/illustration/74.svg';
import locationIllustrator from '../../assets/images/illustration/23.svg';
import Tab from '../../components/Tab';
import SiteInfo from './cctv/siteInfo';
import SiteCCTV from './cctv/siteCCTV';
import MapContainer from './cctv/mapContainer';
import { logPost } from '../../services/log';
import { COMCD_INFRA } from '../../_constants';
import { apiGet } from '../../services/_common';
import * as cctv from '../../utils/cctv';

const EmptyData = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  padding-bottom: 8rem !important;
  img {
    width: 24rem;
    -webkit-user-drag: none;
  }
  p {
    color: ${({ theme }: { theme: any }) => theme.filled_violet};
    word-break: keep-all;
    text-align: center;
  }
  p:nth-child(2) {
    padding: 1rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    border-radius: 0.5rem;
    margin: 1rem;
  }
  p:nth-child(3) {
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    margin: 0 2rem;
  }
`;
const Root = styled.div`
  /* flex-grow: 1; */
  .searchContent {
    display: flex;
    justify-content: space-between;

    .buttonsWrapper {
      display: flex;
      margin-bottom: 0rem;

      > button {
        margin-left: 1rem;
      }
    }
  }
  .inputFormsWrapper {
    border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    padding: 0.5rem;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    .table {
      height: fit-content;
    }
  }

  .inputFormsWrapper.smallTab {
    flex-direction: row;
    padding: 0 0.5rem;
    font-weight: 500;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 0rem !important;
    }
    .tab {
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
      cursor: pointer;
      height: 3.5rem;
      border-bottom: 2px solid transparent;
    }
    .activeTab {
      font-weight: 700;
      color: ${({ theme }: { theme: any }) => theme.selected_primary};
      border-bottom: 2px solid ${({ theme }: { theme: any }) => theme.selected_primaty};
    }
  }

  .end {
    justify-content: end;
    > button {
      margin-left: 1rem;
    }
  }

  .table {
    height: 64rem;
  }

  .tab {
    padding: 0 1rem;
    cursor: pointer;
    height: 3rem;
  }

  .activeTab {
    font-weight: bold;
    border-bottom: 2px solid rgb(63, 66, 84);
  }

  .grid {
    display: grid;
    margin-bottom: 1rem;
    align-items: center;
    // min-height: 3.4rem;
  }

  .grid100,
  .grid100T,
  .grid60,
  .grid50,
  .grid30 {
    > div > div {
      display: grid;
      grid-template-columns: 9rem 1fr;
      align-items: center;
    }
  }

  .grid100T {
    grid-template-columns: 9rem 1fr;
  }

  .grid50,
  .grid60 {
    grid-template-columns: 1fr 1fr;
  }

  .grid50 {
    grid-gap: 1rem;
  }

  .grid30 {
    grid-template-columns: 1.5fr 0.7fr 0.8fr;
  }

  .plainText {
    grid-template-columns: 9rem 1fr;

    > :last-child {
      display: block;
    }
  }

  .marginBottomNone {
    margin-bottom: 0;
  }

  .dataEmpty {
    font-size: 1.5rem;
    text-align: center;
  }

  @media screen and (max-width: 767px) {
    .searchContent {
      flex-direction: column;
      > div {
        width: 100%;
      }
    }
  }
`;

const Hcctv1 = () => {
  const { t } = useTranslation();
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  const userInfo = useRecoilValue(userState);
  const { userMenuList } = userInfo;
  const [activeTabClick, setActiveTabClick] = useState(true);
  const [siteList, setSiteList] = useState<any>([]);
  const [siteInfo, setSiteInfo] = useState({});
  const [newTabList, setNewTabList] = useState<ITabObject[]>();

  useEffect(() => {
    const arr: any[] = [];
    userMenuList.map((v: any) => {
      const result = tabInfo?.tabList?.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  useEffect(() => {
    getSiteAPI().then((res: any) => {
      if (res.status === 200) {
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '현장 정보 / CCTV',
          action: '조회',
          etc: ``,
        });
      }
    });
  }, []);

  // 현장 리스트 조회 API
  const getSiteAPI = async () => {
    const res = await apiGet({ path: '/site' });
    const { status, data } = res;
    if (status === 200) {
      const result = data.data.siteInfoList.filter((v: any) => v.useYn === 'Y' && v.hCd === userInfo.hCd);
      getCodeDetail(result);
    }
    return res;
  };

  // 공통코드(인프라) 리스트 조회 API
  const getCodeDetail = async (siteInfoList: any) => {
    const req = { grCd: COMCD_INFRA };
    const res = await apiGet({ path: '/code/detail', req });
    const { status, data } = res;
    if (status === 200) {
      setSiteList(infraToName(siteInfoList, data.data.comCdList));
    }
  };

  const infraToName = (siteInfoList: any, infraList: any) => {
    for (const site of siteInfoList) {
      for (const cd of infraList) {
        if (site.sInfra == cd.subCd) {
          site.sInfraName = cd.cdName;
        }
      }
    }
    return siteInfoList;
  };

  const onClickSubTab = (tabName: string) => {
    if (tabName === 'info') {
      if (cctv.getPluginOBJECT()?.oPlugin) {
        cctv.destroy();
      }
      setActiveTabClick(true);
    } else {
      setActiveTabClick(false);
    }
  };

  const renderPage = () => {
    if (activeTabClick) {
      return <SiteInfo siteInfo={siteInfo} />;
    }
    return <SiteCCTV siteInfo={siteInfo} />;
  };

  const onClickTab = (tab: string) => {
    setCurrentTabMrCd(tab);
  };

  return (
    <div className='contents'>
      <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      {size.innerSize.W >= 1024 ? (
        <div className='content-container twoColumn column-55'>
          <Root>
            <MapContainer siteList={siteList} setSiteInfo={setSiteInfo} />
          </Root>
          {Object.keys(siteInfo).length === 0 ? (
            <Root>
              <EmptyData>
                <img src={locationIllustrator} alt='noData' />
                <p>{t('왼쪽 지도에서 현장을 선택하세요')}</p>
                {/* <p>화면이 안 나오신다면 브라우저의 가로 폭를 늘려주세요.</p> */}
              </EmptyData>
            </Root>
          ) : (
            // <div className='dataEmpty'>현장을 선택하세요.</div>
            <Root>
              <div className='inputFormsWrapper smallTab'>
                <div className='flexRow marginBottom'>
                  <div className={`tab ${activeTabClick && 'activeTab'}`} role='button' tabIndex={0} onClick={() => onClickSubTab('info')}>
                    {t('현장 정보')}
                  </div>
                  <div className={`tab ${!activeTabClick && 'activeTab'}`} role='button' tabIndex={0} onClick={() => onClickSubTab('menu')}>
                    {t('현장 CCTV')}
                  </div>
                </div>
              </div>
              {renderPage()}
            </Root>
          )}
        </div>
      ) : (
        <div className='content-container oneColumn'>
          <Root>
            <EmptyData>
              <img src={illustrator} alt='noData' />
              <p>{t('데스크톱 환경에서 설정해 주세요')}</p>
              <p>{t('화면이 안 나오신다면 브라우저의 가로 폭를 늘려주세요')}</p>
            </EmptyData>
          </Root>
        </div>
      )}
    </div>
  );
};

export default Hcctv1;
