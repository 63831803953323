/**
 * 작성자 : 홍선영
 * 날짜 : 2024.04.29
 * 경로 : 안전교육 직종 동영상 설정 (현장관리자)
 */

import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';
import IssueGuide from '../../../components/IssueGuide';
import VideoDetails from './VideoDetails';
import VideoLists from './VideoLists';
import { useTabletVideo } from '../../../hooks/useTabletVideo';
import { logPost } from '../../../services/log';
import { IUser, userState } from '../../../atoms';
import { useRecoilValue } from 'recoil';
import { ContentsContainerRoot } from '../../../assets/styles/ContentsContainerRoot';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import { PulseLoader } from 'react-spinners';

const Root = styled(ContentsContainerRoot)``;

interface IVideoFile {
  isEnrolled: boolean;
  path: null | string;
  fileName: null | string;
  file: File | null;
}

interface IVideo {
  ko: IVideoFile;
  en: IVideoFile;
  vi: IVideoFile;
  zh: IVideoFile;
  th: IVideoFile;
  my: IVideoFile;
  km: IVideoFile;
}

interface IVideoRow {
  sSeq: number | null;
  sTitle: string;
  jobtypeIdx: string | null;
  video: IVideo;
  delYn: string;
  useYn: string;
  wDate: string | null;
  writer: string;
  eDate: string | null;
  editor: string | null;
}

interface IJobtypeVideo {
  sType: string;
}

const JobtypeVideo = ({ sType }: IJobtypeVideo) => {
  const size = useOutletContext<any>();
  const userInfo = useRecoilValue<IUser>(userState);
  const { jobtypeVideoQuery } = useTabletVideo(sType as '1' | '2');
  const { data, isLoading, isError, isRefetching, refetch } = jobtypeVideoQuery;
  const [tableState, setTableState] = useState<JobtypeVideoRow[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [uploading, setUploading] = useState(false);
  const loading = isLoading || uploading || isRefetching;

  // 필수 & 직종별 동영상 설정 화면간 이동시 selectedIndex 초기화
  useEffect(() => {
    setSelectedIndex(null);
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: sType === '1' ? '태블릿 안전교육 관리 > 안전교육 공통(필수) 동영상 설정' : '태블릿 안전교육 관리 > 안전교육 직종 동영상 설정',
      action: '조회',
      etc: ``,
    });
  }, [sType]);

  // VideoLists에서 data 필터링 사용을 위해 데이터 원본 상태 유지
  useEffect(() => {
    if (data) setTableState(data);
  }, [data]);

  const contentStyle =
    size.innerSize.W >= 1024 //
      ? 'twoColumn column-55 max800'
      : 'oneColumn';

  if (isError) return <IssueGuide />;

  return (
    <>
      <Root loading={loading} className={`content-container ${contentStyle}`}>
        <VideoLists
          data={data} //
          sType={sType}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          tableState={tableState}
          setTableState={setTableState}
        />
        <VideoDetails //
          data={tableState}
          sType={sType}
          selectedIndex={selectedIndex}
          refetch={refetch}
          uploading={uploading}
          setUploading={setUploading}
        />
      </Root>
      {loading && (
        <LoadingModalBackground>
          <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
        </LoadingModalBackground>
      )}
    </>
  );
};

export default JobtypeVideo;
