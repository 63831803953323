import { useRecoilValue } from 'recoil';
import { tunnelReverseState } from '../../atoms';

type Props = {
  classNameOfStyle?: string;
  isStart: boolean;
};

const PointIcon = ({ classNameOfStyle, isStart = true }: Props) => {
  const isReversed = useRecoilValue(tunnelReverseState);
  const icon = !isReversed ? isStart : !isStart;
  return <span className={`material-symbols-rounded ${classNameOfStyle ?? ''}`}>{icon ? 'line_end_circle' : 'line_start_circle'}</span>;
};

export default PointIcon;
