import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Root = styled.div`
  @media print {
    width: 60rem;
  }
  width: 100%;
  .printTitle {
    color: ${({ theme }: { theme: any }) => theme.text_primary};
    text-align: center;
    font-size: 1.375rem;
    font-weight: 700;
    font-weight: 800;
    margin: 1rem 0;
  }
  .card-wrapper {
    min-height: fit-content;
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
    .card {
      flex-shrink: 0;
      min-height: 6.25rem;
      outline: 1px solid ${({ theme }: { theme: any }) => theme.tonal_deep};
      padding: 0.5rem 0.75rem;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      border-radius: 0.5rem;
      overflow: hidden;
      * {
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        font-size: 0.875rem;
      }
      &.photo {
        grid-row: span 3;
        padding: 0;
        img {
          flex-grow: 1;
          border-radius: 0.25rem;
        }
        &.noImg {
          display: flex;
          justify-content: center;
          align-items: center;
          .material-symbols-rounded {
            font-variation-settings: 'FILL' 1;
            font-size: 3.5rem;
            color: ${({ theme }: { theme: any }) => theme.text_disabled};
          }
        }
      }
      .title {
        display: flex;
        align-items: center;
        gap: 0.375rem;
        font-weight: 600;
        .index {
          width: 1.5rem;
          height: 1.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          border-radius: 50%;
        }
      }
    }
  }
  .sign {
    height: 5rem;
    padding: 0 1rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;
    .title {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      .name {
        min-width: 8rem;
        text-align: center;
        font-weight: 600;
        color: ${({ theme }: { theme: any }) => theme.text_primary};
      }
    }
    img {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      mix-blend-mode: multiply;
      width: 8rem;
      max-height: 5rem;
      object-fit: contain;
    }
    .mark {
      color: ${({ theme }: { theme: any }) => theme.text_disabled};
    }
  }
`;

interface Props {
  data: any;
}

const WorkerInfoPrint = ({ data }: Props) => {
  const { t } = useTranslation();
  const randomString = Math.random().toString(36).substring(7); // 랜덤 문자열을 쿼리스트링으로 추가해서 이미지 캐싱 방지

  const {
    nedusignImg,
    orgWName, //
    wCd,
    wBdate,
    wHnum,
    wEnum,
    wSafetydeviceText,
    wJobtypeName,
    wBlood,
    wLowpress,
    wHighpress,
    sjName,
    takeMedicineText,
    pulseRate,
    wJobdate,
    wAddress1,
    wAddress2,
    wFregdate,
    wFregnum,
    wVisa,
    stressResult,
    stressTotal,
    safeeduDate,
    safeeduReg,
    wImg,
  } = data;

  const info = [
    {
      title: '성명',
      value: orgWName,
    },
    {
      title: '생년월일',
      value: wBdate,
    },
    {
      title: '휴대폰',
      value: wHnum,
    },
    {
      title: '비상연락처',
      value: wEnum,
    },
    {
      title: '협력업체',
      value: sjName,
    },
    {
      title: '직종',
      value: wJobtypeName,
    },
    {
      title: '개인보호구',
      value: wSafetydeviceText,
    },
    {
      title: '약 복용여부',
      value: takeMedicineText,
    },
    {
      title: '맥박수',
      value: pulseRate,
    },
    {
      title: '혈액형',
      value: wBlood,
    },
    {
      title: '최저혈압',
      value: wLowpress,
    },
    {
      title: '최고혈압',
      value: wHighpress,
    },
    {
      title: '기초안전보건교육 등록번호',
      value: safeeduReg,
    },
    {
      title: '기초안전보건교육 이수일자',
      value: safeeduDate,
    },
    {
      title: '직무스트레스 총점수',
      value: stressTotal,
    },
    {
      title: '직무스트레스 결과',
      value: stressResult,
    },
    {
      title: '외국인 등록번호',
      value: wFregnum,
    },
    {
      title: '비자종류',
      value: wVisa,
    },
    {
      title: '외국인 등록증 발급일자',
      value: wFregdate,
    },
    {
      title: '주소',
      value: `${wAddress1 ?? ''} ${wAddress2 ?? ''}`,
    },
    {
      title: '취업일자',
      value: wJobdate,
    },
  ];

  return (
    <Root>
      <div className='printTitle'>{t('근로자 정보')}</div>
      <div className='card-wrapper'>
        <div className={`card photo ${wImg ? '' : 'noImg'}`}>
          {wImg ? ( //
            <img key={wCd} src={wImg} alt='이미지' />
          ) : (
            <span className='material-symbols-rounded'>person</span>
          )}
        </div>
        {info.map((item, index) => (
          <div className='card' key={item.title}>
            <div className='title'>
              <span className='index'>{index + 1}</span>
              {t(item.title)}
            </div>
            <div className='value'>{item.value}</div>
          </div>
        ))}
      </div>
      {nedusignImg ? ( //
        <div className='sign'>
          <div className='title'>
            {t('안전교육 서명')} : <span className='name'>{orgWName}</span>
          </div>
          <div className='mark'>{t('(사인)')}</div>
          <img //
            src={`${nedusignImg}?v=${randomString}`}
            alt={t('안전교육 서명')}
          />
        </div>
      ) : null}
    </Root>
  );
};

export default WorkerInfoPrint;
