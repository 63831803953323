import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, BarElement, CategoryScale, LinearScale, BarController, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, ArcElement, BarElement, BarController, LinearScale, Title, Tooltip, Legend);

interface BarChartProps {
  labelsParam: string[];
  barData: any;
}

const BarChart: React.FC<BarChartProps> = ({ labelsParam, barData }) => {
  const data = {
    labels: labelsParam,
    datasets: barData,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        // Assuming you want to change the x-axis labels
        // ticks: {
        //   // Option 2: Shorten Labels
        //   callback(value: any) {
        //     if (value.length > 6) {
        //       return `${value.substr(0, 6)}...`; // truncate to 10 chars
        //     }
        //     return value;
        //   },
        //   //   maxRotation: 0, // this will set the max rotation to 0
        //   //   minRotation: 0, // this will set the min rotation to 0
        // },
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as 'bottom',
      },
      tooltip: {
        callbacks: {
          title(context: any) {
            return context[0].label;
          },
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default BarChart;
