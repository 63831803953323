/**
 * 작성자 : 김광민
 * 날짜 : 2024.02.26
 * 기능 : 단축키가 존재하는 버튼
 */

import styled from 'styled-components';

const Root = styled.div`
  user-select: none;
  cursor: pointer;
  background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
  border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  gap: 0.5rem;
  height: 2.5rem;
  padding: 0.75rem;
  padding-right: 0.25rem;
  .buttonText {
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
  }
  .shortcut {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
    color: ${({ theme }: { theme: any }) => theme.text_disabled};
    border-radius: 0.25rem;
    font-size: 0.75rem;
    letter-spacing: 0;
    width: 1.75rem;
    height: 1.75rem;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${({ theme }: { theme: any }) => theme.outline_em};
    font-weight: 600;
  }
  &:hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    .shortcut {
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    }
  }
  &:active {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
  }
  &.useIcon {
    padding-left: 0.5rem;
    .buttonText {
      display: flex;
      align-items: center;
      /* color: ${({ theme }: { theme: any }) => theme.text_secondary}; */
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      font-weight: 600;
      .material-symbols-rounded {
        font-size: 1.375rem;
        font-size: 1.25rem;
        margin-right: 0.25rem;
        /* margin-right: 0.125rem; */
      }
    }
    .shortcut {
      /* color: ${({ theme }: { theme: any }) => theme.text_tertiary}; */
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
    }
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
    }
  }
`;

type Props = {
  onClick: () => void;
  buttonText: string;
  shortcut?: string;
  icon?: string;
};

const ShortcutButton = ({ onClick, buttonText, shortcut, icon }: Props) => {
  return (
    <Root onClick={onClick} role='button' className='useIcon'>
      <span className='buttonText'>
        {icon && <span className='material-symbols-rounded'>{icon}</span>}
        {buttonText}
      </span>
      {shortcut && <span className='shortcut'>{shortcut}</span>}
    </Root>
  );
};

export default ShortcutButton;
