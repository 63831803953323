/**
 * 작성자 : 홍선영
 * 날짜 : 2023.11.03
 * 기능 : 보고서 상단 검색조건의 공종 또는 직종 목록 useState hook (return 공종목록, 직종목록)
 */

import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { IUser, userState } from '../atoms';

import { apiGet } from '../services/_common';

const useSetJobtype = (grCd: string, isAllIncluded: boolean = true) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue<IUser>(userState);
  const [prejobtypeList, setPrejobtypeList] = useState<IJobtype[]>([]);
  const [jobtypeList, setJobtypeList] = useState<IJobtype[]>([]);

  // 공종 리스트 조회 (prejobtypeYn === 'Y')
  const prejobtypeListQuery = useQuery(['prejobtypeListGet', userInfo], () => apiGet({ path: '/code/pmsNormalTitle', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, subCd: 'A' } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && userInfo.prejobtypeYn === 'Y',
  });

  useEffect(() => {
    if (prejobtypeListQuery.isSuccess) {
      const { status, data } = prejobtypeListQuery.data;
      if (status === 200) {
        if (data.data.pmsNormalTitleList) {
          const newList = data.data.pmsNormalTitleList.map((el: any) => {
            return {
              type: 'wPrejobtype',
              wPrejobtype: el.subCd,
              cdName: el.cdName,
            };
          });
          if (isAllIncluded) setPrejobtypeList([{ type: 'wPrejobtype', wPrejobtype: '', cdName: t('공종 전체') }, ...newList]);
          else setPrejobtypeList(newList);
        }
      }
    }
  }, [prejobtypeListQuery.isSuccess]);

  // 직종 리스트 조회 (prejobtypeYn === 'Y' 일때 그 하위직종)
  const subJobtypeListQuery = useQuery(['subJobtypeListGet', userInfo, grCd], () => apiGet({ path: '/code/pmsNormalSub', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, grCd } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && userInfo.prejobtypeYn === 'Y' && !!grCd,
  });

  useEffect(() => {
    if (subJobtypeListQuery.isSuccess) {
      const { status, data } = subJobtypeListQuery.data;
      if (status === 200) {
        if (data.data.pmsNormalSubList) {
          const newList = data.data.pmsNormalSubList.map((el: any) => {
            return {
              type: 'wJobtype',
              wJobtype: el.subCd,
              cdName: el.cdName,
            };
          });
          if (isAllIncluded) setJobtypeList([{ type: 'wJobtype', wJobtype: '', cdName: t('직종 전체') }, ...newList]);
          else setJobtypeList(newList);
        }
      }
    }
  }, [subJobtypeListQuery.isSuccess, grCd]);

  // 단일직종 리스트 조회 (prejobtypeYn === 'N')
  const jobtypeListQuery = useQuery(['jobtypeListGet', userInfo], () => apiGet({ path: '/code/pmsNormalTitle', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, subCd: 'B' } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && userInfo.prejobtypeYn !== 'Y',
  });

  useEffect(() => {
    if (jobtypeListQuery.isSuccess) {
      const { status, data } = jobtypeListQuery.data;
      if (status === 200) {
        if (data.data.pmsNormalTitleList) {
          const newList = data.data.pmsNormalTitleList.map((el: any) => {
            return {
              type: 'wJobtype',
              wJobtype: el.subCd,
              cdName: el.cdName,
            };
          });
          if (isAllIncluded) setJobtypeList([{ type: 'wJobtype', wJobtype: '', cdName: t('직종 전체') }, ...newList]);
          else setJobtypeList(newList);
        }
      }
    }
  }, [jobtypeListQuery.isSuccess]);

  return { prejobtypeList, jobtypeList };
};

export default useSetJobtype;
