/**
 * 작성자 : 김광민
 * 날짜 : 2024.03.05
 * 기능 : 방송장비테스트 모달 내 사용중인 언어를 칩 형태로 나열하는 컴포넌트
 */

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 } from 'uuid';

const Root = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 0.5rem;
  padding: 0 0.75rem;
  backdrop-filter: blur(1rem);
  height: 3rem;
  flex-shrink: 0;
  .label {
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_disabled};
  }
  .chip {
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    border: 1px solid ${({ theme }: { theme: any }) => theme.tonal_deep};
    border-radius: 2rem;
    padding: 0 0.75rem;
    display: flex;
    height: 2rem;
    align-items: center;
  }
`;

type Props = { data: any };

const BroadcastLanguage = ({ data }: Props) => {
  const { t } = useTranslation();
  const [convertedData, setConvertedData] = useState<Array<{ language: string; content: string }>>([]);

  useEffect(() => {
    const convertStateToArray = () => {
      return Object.entries(data).map(([key, value]) => ({
        language: key,
        content: String(value),
      }));
    };

    setConvertedData(convertStateToArray());
  }, [data]);

  const languages: { [key: string]: string } = { ko: '한국어', en: '영어', ch: '중국어', vi: '베트남어' };

  return (
    <Root>
      <span className='label'>{t('사용 중인 언어')}</span>
      {convertedData.map((item) => {
        if (languages[item.language] && item.content.length !== 0) {
          return (
            <span className='chip' key={v4()}>
              {languages[item.language]}
            </span>
          );
        }

        return null;
      })}
    </Root>
  );
};

export default BroadcastLanguage;
