import { t } from 'i18next';
import styled from 'styled-components';

type Props = {
  func: () => void;
  backBtnPlacement?: 'left' | 'right';
};

const Root = styled.div`
  height: fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  gap: 0.25rem;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 5rem;
  padding-right: 1rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  background-color: ${({ theme }: { theme: any }) => theme.tonal};
  color: ${({ theme }: { theme: any }) => theme.text_primary};
  &:hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
  }
  > span {
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  &.arrow-icon span {
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  &.left {
    order: 0;
  }
  &.right {
    order: 9;
  }
`;
const BackButton = ({ func, backBtnPlacement }: Props) => {
  return (
    <Root className={`${`icon-btn arrow-icon ${backBtnPlacement}`}`} role='presentation' onMouseDown={func}>
      <span className='material-symbols-rounded'>arrow_back</span>
      {t('뒤로가기')}
    </Root>
  );
};

export default BackButton;
