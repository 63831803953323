import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import ToggleButton from '../../../../components/button/ToggleButton';
import ButtonContainer from './ButtonContainer';
import ClickableContainer from './ClickableContainer';
import ClickExpandableContainer from './ClickExpandableContainer';
import DarkSmallTabContainer from './DarkSmallTabContainer';
import { foldButton, fullScreenButton, mainStream, streamButton, subStream, thirdStream, unfoldButton } from '../../../../components/button/DarkButtons';
import { WebSDKcctv } from '../../../../utils/webSDKcctvClass';

const Root = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  user-select: none;
  gap: 0.25rem;
  > div:first-child {
    margin-right: 0.75rem;
  }
`;

type Props = {
  visiblePanel: boolean;
  setVisiblePanel: Dispatch<SetStateAction<boolean>>;
  cctvOBJ: WebSDKcctv;
  onClickDivision: (number: number) => void;
  divisionCount: number;
  onClickSetStream: (value: '1' | '2' | '3') => void;
  camPlayLoading: boolean;
};

const BottomLeftOptions = ({
  visiblePanel, //
  setVisiblePanel,
  cctvOBJ,
  onClickDivision,
  divisionCount,
  onClickSetStream,
  camPlayLoading,
}: Props) => {
  // 스트림 상태
  const streamOptions = [
    { key: 'main', button: mainStream, value: '1' },
    { key: 'sub', button: subStream, value: '2' },
    // 트랜스 코딩 스트림은 사용하지 않음
    // { key: 'third', button: thirdStream, value: '3' },
  ];

  // 화면 분할 Options 생성
  const gridOptions = Array.from({ length: 4 }, (_, index) => {
    const indexKey = index + 1;
    const selected = divisionCount === indexKey;
    const key = indexKey.toString();
    const name = (indexKey * indexKey).toString();
    return {
      key,
      selected,
      disabled: false,
      button: <ButtonContainer name={name} selected={selected} darkModeOnly />,
    };
  });

  const fullScreen = () => {
    cctvOBJ?.fullScreen();
  };

  return (
    <Root>
      <ToggleButton // 사이드 패널 접기&펼치기
        onToggle={foldButton}
        offToggle={unfoldButton}
        setState={setVisiblePanel}
        state={visiblePanel}
        disabled={camPlayLoading}
      />
      <ClickExpandableContainer // 스트림
        defaultOption={streamButton}
        options={streamOptions}
        onClick={onClickSetStream as (value: string) => void}
        disabled={camPlayLoading}
      />
      <DarkSmallTabContainer
        icon='grid_view' // 화면 분할
        name='화면 분할'
        tabs={gridOptions}
        onClick={onClickDivision}
        disabled={camPlayLoading}
      />
      <ClickableContainer // 풀스크린
        button={fullScreenButton}
        onClick={fullScreen}
        disabled={camPlayLoading}
      />
    </Root>
  );
};

export default BottomLeftOptions;
