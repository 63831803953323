/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.30
 * 경로 : 터널 모달 > 굴진/일반정보 모듈
 */

import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Input from '../Input';
import { applyBorderStyle } from '../../utils/applyBorderStyle';

const DistanceInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400;
  background-color: ${({ theme }: { theme: any }) => theme.board};
  padding: 0.75rem;
  flex: 1 0 40%;
  border-radius: 0.5rem;
  height: 8rem;
  justify-content: space-between;
  @media (min-width: 1024px) {
    padding: 0.875rem 1rem;
  }
  .distanceTitle {
    flex-shrink: 0;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    word-break: keep-all;
    .dot {
      display: flex;
      width: 0.875rem;
      height: 0.875rem;
      border-radius: 1rem;
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
      margin-right: 0.5rem;
    }
  }
  .distance {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.25rem;
    .kilometer {
      font-weight: 400;
      font-size: 0.875rem;
      color: ${({ theme }: { theme: any }) => theme.text_disabled};
      .emphasize {
        font-weight: 600;
        margin-right: 0.125rem;
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      }
    }
    .meter {
      height: 2.5rem;
      padding: 0 0.75rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-radius: 0.25rem;
      position: relative;
      .required {
        position: absolute;
        left: 0.375rem;
        font-size: 0.75rem;
        font-weight: 600;
        color: ${({ theme }: { theme: any }) => theme.filled_red};
        background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
        padding: 0 0.375rem;
        border-radius: 0.25rem;
        border: 1px solid ${({ theme }: { theme: any }) => theme.tonal_deep_red};
        &::after {
          display: none;
        }
      }
    }
  }
  &.length {
    .distance {
      .meter {
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        color: ${({ theme }: { theme: any }) => theme.text_disabled};
        pointer-events: none;
      }
    }
  }
  &.rlength {
    .distance {
      .meter {
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        input {
          text-align: right;
        }
        .symbol {
          position: absolute;
          right: 0.5rem;
          font-size: 1rem;
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        }
        &.focusOut {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
          outline: 1px solid ${({ theme }: { theme: any }) => theme.tonal_deep};
        }
      }
    }
    outline: 1px solid transparent;
    outline: 1px solid ${({ theme }: { theme: any }) => theme.tonal_deep};
    &:hover {
      outline: 1px solid ${({ theme }: { theme: any }) => theme.outline_em};
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      transition: all 0.2s ease-in-out;
    }
  }
  // 총 공사길이 dot 색상
  &.length {
    &.level1 {
      .dot {
        border: 2px solid ${({ theme }: { theme: any }) => theme.filled_slate};
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
      }
    }
    &.level2 {
      .dot {
        border: 2px solid ${({ theme }: { theme: any }) => theme.filled_amber};
        background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
      }
    }
    &.level3 {
      .dot {
        border: 2px solid ${({ theme }: { theme: any }) => theme.filled_violet};
        background-color: ${({ theme }: { theme: any }) => theme.tonal_violet};
      }
    }
    &.level4 {
      .dot {
        border: 2px solid ${({ theme }: { theme: any }) => theme.filled_green};
        background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
      }
    }
    &.level5 {
      .dot {
        border: 2px solid ${({ theme }: { theme: any }) => theme.filled_blue};
        background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
      }
    }
  }
  /* 현재 굴진길이 dot 색상 */
  &.rlength {
    &.level1 .dot {
      background-color: ${({ theme }: { theme: any }) => theme.filled_slate};
    }
    &.level2 .dot {
      background-color: ${({ theme }: { theme: any }) => theme.filled_amber};
    }
    &.level3 .dot {
      background-color: ${({ theme }: { theme: any }) => theme.filled_violet};
    }
    &.level4 .dot {
      background-color: ${({ theme }: { theme: any }) => theme.filled_green};
    }
    &.level5 .dot {
      background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
    }
  }
`;

type Props = { length: number; rlength: number; viewType: 'length' | 'rlength'; position: 's' | 'e'; isSaveClicked: boolean; data: any; setData: Dispatch<SetStateAction<any>> };

const DistanceInfo = ({ length, rlength, viewType, position, isSaveClicked, data, setData }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [focusOut, setFocusOut] = useState(false);
  // 현재 굴진길이 포커스아웃 여부
  const { t } = useTranslation();
  const value = viewType === 'length' ? length : rlength;
  const rlengthName = position === 's' ? 'sRLength' : 'eRLength';

  const editable = viewType === 'rlength';

  // 현재 굴진길이 입력값 체크
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value.replace(/\s/g, '');
    if (isNaN(Number(inputValue))) {
      toast.warning(t('숫자만 입력할 수 있습니다.'));
      return;
    }
    if (inputValue === '') {
      inputValue = '0';
    }
    const rlengthNumber = Number(inputValue);
    if (viewType === 'rlength') {
      if (rlengthNumber > length) {
        if (position === 's') {
          setData((prev: any) => ({ ...prev, sRLength: 0 }));
        } else if (position === 'e') {
          setData((prev: any) => ({ ...prev, eRLength: 0 }));
        }
        toast.warning(t('총 공사 길이를 초과할 수 없습니다.'));
      } else {
        setData((prev: any) => ({ ...prev, [rlengthName]: rlengthNumber }));
      }
    }
  };

  // 현재 굴진길이 색상
  const getColor = () => {
    const percentage = Math.floor((rlength / length) * 100);
    if (rlength === 0 || (length === 0 && rlength > 0)) {
      return 'level1';
    }
    if (percentage <= 20) {
      return 'level1';
    }
    if (percentage <= 40) {
      return 'level2';
    }
    if (percentage <= 60) {
      return 'level3';
    }
    if (percentage <= 80) {
      return 'level4';
    }
    return 'level5';
  };

  // 총 공사길이, 현재 굴진길이 렌더링
  const renderInput = () => {
    if (viewType === 'length') {
      return (
        <span className='meter'>
          <span className='emphasize'>{value.toLocaleString()}</span>m
        </span>
      );
    }

    return (
      <span className={`meter ${focusOut ? 'focusOut' : ''}`}>
        {editable && <span className='required'>{t('필수 입력')}</span>}
        <span className='symbol'>m</span>
        <Input
          inputRef={inputRef}
          className='required'
          type='text'
          name={rlengthName}
          valueType='localeString'
          state={data}
          setState={setData}
          blur={() => setFocusOut(false)}
          onFocus={() => setFocusOut(true)}
          getBorderStyle={isSaveClicked ? applyBorderStyle(rlength, 'red', rlengthName) : undefined}
          onChange={handleChange}
        />
      </span>
    );
  };

  return (
    <DistanceInfoContainer className={`${viewType ?? ''} ${getColor()}`} onClick={() => inputRef.current && inputRef.current?.focus()}>
      <span className={`distanceTitle ${editable ? 'required' : ''}`}>
        <span className='dot' />
        {viewType === 'length' ? '총 공사 길이' : '현재 굴진 길이'}
      </span>
      <div className='distance'>
        <span className='kilometer'>
          <span className='emphasize'>{(value / 1000).toLocaleString()}</span>
          km
        </span>
        {renderInput()}
      </div>
    </DistanceInfoContainer>
  );
};

export default DistanceInfo;
