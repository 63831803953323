/**
 * 작성자 : 한영광
 * 날짜 : 2023.09.01
 * 기능 : 날짜선택 캘린더 (테이블 안에서 사용)
 */

import React, { useState, useEffect, forwardRef, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { ko, enUS } from 'date-fns/esm/locale';
import InputMask from 'react-input-mask';
import { applyBorderStyle } from '../utils/applyBorderStyle';
import { formatDateToStr, isValidYYYYMMDD } from '../utils/formatDate';
import i18n from '../translation/i18n';

const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(customParseFormat);

const StyledDatePicker = styled(DatePicker)`
  border: none;
  font-weight: 400;
  line-height: 100%;
  background-color: rgb(243, 246, 249);
  color: #707070;
`;

const CustomInput = ({ index, onChangeState, inputValue, onChange, setInputValue, datePickerRef, setWbdateSelect, isSaveClicked, disabled, ...rest }: any) => {
  const [lackValue, setLackValue] = useState(false);

  const handleClick = () => {
    if (setWbdateSelect) {
      setWbdateSelect(true);
    }
    if (datePickerRef.current !== null) {
      datePickerRef.current.setOpen(true);
    }
  };

  const onChangeMaskValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const maskValue = e.currentTarget.value;
    const removeDash = maskValue?.replace(/[_-]/g, '');
    setInputValue(removeDash);
    if ((removeDash !== '' && removeDash.length > 0 && removeDash.length < 8) || (removeDash.length === 8 && !isValidYYYYMMDD(removeDash))) {
      setLackValue(true);
    } else setLackValue(false);
  };

  return (
    <InputMask
      className='inputMask'
      mask='9999-99-99'
      value={inputValue}
      onChange={(e) => onChangeMaskValue(e)}
      onClick={handleClick}
      style={lackValue || isSaveClicked ? applyBorderStyle(inputValue, 'red', 'date') : undefined}
      disabled={disabled}
    />
  );
};

const DatePickerInTable = ({ state, setState, stateKey, index, onChangeState, setWbdateSelect, startRange, endRange, popperPlacement, isSaveClicked, disabled }: any) => {
  const datePickerRef = useRef(null);
  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const range = (start: any, end: any, step = 1) => {
    const result = [];
    for (let i = start; i < end; i += step) {
      result.push(i);
    }
    return result;
  };
  const years = range(startRange || new Date().getFullYear() - 80, endRange || new Date().getFullYear() + 20, 1);
  const format = state[index][stateKey] && state[index][stateKey].length === 8 ? dayjs(state[index][stateKey], 'YYYYMMDD').toDate() : null;
  const [inputValue, setInputValue] = useState(state[index][stateKey]);

  useEffect(() => {
    setInputValue(state[index][stateKey]);
  }, [state]);

  useEffect(() => {
    // if (inputValue.length === 8 || inputValue.length === 0) {
    onChangeState(index, inputValue);
    // }
  }, [inputValue]);

  return (
    <StyledDatePicker
      popperPlacement={popperPlacement || 'auto'}
      ref={datePickerRef}
      locale={i18n.language === 'ko-KR' ? ko : enUS}
      dateFormat='yyyy-MM-dd'
      selected={format}
      onChange={(date: any) => {
        if (inputValue?.length === 8 || inputValue === '') {
          onChangeState(index, formatDateToStr(date));
        }
        if (date) {
          onChangeState(index, formatDateToStr(date));
        }
      }}
      disabled={disabled}
      customInput={
        <CustomInput
          index={index}
          onChangeState={onChangeState}
          datePickerRef={datePickerRef}
          setWbdateSelect={setWbdateSelect}
          isSaveClicked={isSaveClicked}
          inputValue={inputValue}
          setInputValue={setInputValue}
          disabled={disabled}
        />
      }
      renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
        <div className='custom-header'>
          <div className='arrow' onClick={decreaseMonth} role='button' tabIndex={0}>
            {'<'}
          </div>
          <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(Number(value))}>
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <div className='arrow' role='button' tabIndex={0} onClick={increaseMonth}>
            {'>'}
          </div>
        </div>
      )}
    />
  );
};

export default DatePickerInTable;
