/**
 * 작성자 : 홍선영
 * 날짜 : 2023.12.22
 * 기능 : 터널 관리 > 굴진/일반 정보 > 굴진/일반 정보 팝업 > 유해 가스 정보 (현장용)
 */

import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TareaData from './dashboard/MGasInfoWithTatCd/TareaData';
import { v4 } from 'uuid';
import PointIcon from './tunnel/PointIcon';
import IssueGuide from './IssueGuide';

const SensorTableContainer = styled.div`
  flex-grow: 1;
  padding: 0.5rem;
  display: flex;
  margin-bottom: 0.5rem;
  background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
  border-radius: 0.5rem;
  height: 26rem;
  @media (max-width: 1023px) {
    min-width: calc(100dvw - 1rem);
  }
  @media (min-width: 1024px) {
    width: calc(50% - 0.25rem);
  }
  .column.header {
    padding-left: 0.5rem;
    display: flex;
    flex-direction: column;
    flex: 0 0 9rem;
    .row {
      font-size: 0.875rem;
      padding: 0;
      display: flex;
      align-items: center;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      &.header {
        height: 2.5rem;
        max-height: 2.5rem;
      }
      &.start {
        .name {
          background-color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
        }
        .material-symbols-rounded {
          color: ${({ theme }: { theme: any }) => theme.filled_blue};
        }
      }
      &.end {
        .name {
          background-color: ${({ theme }: { theme: any }) => theme.filled_amber_deep};
        }
        .material-symbols-rounded {
          color: ${({ theme }: { theme: any }) => theme.filled_amber};
        }
      }
      .material-symbols-rounded {
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        font-size: 1.25rem;
      }
      .name {
        font-size: 0.875rem;
        font-weight: 400;
        padding: 0.5rem 0.75rem;
        border-radius: 1rem;
        color: ${({ theme }: { theme: any }) => theme.text_reverse};
        line-height: 1.25rem;
        display: flex;
        align-items: center;
        gap: 0.375rem;
        height: 1.5rem;
        justify-content: center;
      }
      .counter {
        color: ${({ theme }: { theme: any }) => theme.text_disabled};
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        padding: 0 0.5rem;
        border-radius: 0.25rem;
      }
      .material-symbols-rounded,
      .counter {
        min-width: 1.5rem;
        text-align: center;
        margin-right: 0.375rem;
      }
    }
  }
  .column.body {
    display: flex;
    flex-grow: 1;
    background-color: ${({ theme }: { theme: any }) => theme.board};
    border-radius: 0.25rem;
    box-shadow: 0 4px 12px 0px rgba(0, 0, 0, 0.05);
  }
  .row {
    flex-grow: 1;
    &:nth-child(n + 2) {
      border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    }
  }
`;

interface IProps {
  tatGubun: string;
  sensorData: any[];
  sensorList: any[];
}

const TareaMGas = ({ tatGubun, sensorData, sensorList }: IProps) => {
  const { t } = useTranslation();
  const [findTareaData, setFindTareaData] = useState<any>([]);

  useEffect(() => {
    const mutateTareaData = sensorData.find((el: any) => el.tatGubun?.toLowerCase() === tatGubun?.toLowerCase());
    if (mutateTareaData) setFindTareaData(mutateTareaData);
  }, [tatGubun, sensorData]);

  // 센서 리스트 렌더링
  const renderSensorListRows = () =>
    sensorList.map((el: any, idx: number) => (
      <div className='row' key={el.ssCd}>
        <span className='counter'>{idx + 1}</span>
        {el.cdName}
      </div>
    ));

  // 센서 데이터 렌더링
  const renderSensorDataRows = () => {
    if (findTareaData) {
      return (
        <>
          <TareaData list={findTareaData} sensorList={sensorList} valueType='shape' title='상태' />
          <TareaData list={findTareaData} sensorList={sensorList} valueType='number' title='수치' />
        </>
      );
    }
    return (
      <div className='column point'>
        <div className='row header' />
        {sensorList.map(() => (
          <div className='row' key={v4()} />
        ))}
      </div>
    );
  };

  if (sensorData.length === 0) {
    return <IssueGuide text='연결 설정이 되어 있지 않습니다' />;
  }

  return (
    <SensorTableContainer>
      <div className='column header'>
        <div className={`row header ${findTareaData.tatGubun === 's' ? 'start' : 'end'}`}>
          {findTareaData.tatGubun === 's' ? <PointIcon isStart /> : <PointIcon isStart={false} />}
          <span className='name'>{findTareaData.tatGubun === 's' ? t('시점') : t('종점')}</span>
        </div>
        {renderSensorListRows()}
      </div>
      <div className='column body'>{renderSensorDataRows()}</div>
    </SensorTableContainer>
  );
};

export default TareaMGas;
