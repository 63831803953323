/**
 * 작성자 : 홍선영
 * 날짜 : 2023.07.24
 * 기능 : page up키 엔터 useState hook
 */

import { useEffect, useState } from 'react';

const useOnKeydownPgUp = () => {
  const [isPgUpPressed, setIsPgUpPressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Page Up' || event.keyCode === 33) {
        event.preventDefault();
        setIsPgUpPressed(true);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      setIsPgUpPressed(false);
    };
  }, []);

  return { isPgUpPressed, setIsPgUpPressed };
};
export default useOnKeydownPgUp;
