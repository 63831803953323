/**
 * 작성자 : 홍선영
 * 날짜 : 2024.05.03
 * 기능 : 직종선택 모달창
 */

import { Dispatch, SetStateAction, useState } from 'react';
import { t } from 'i18next';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { IModal } from 'customTypes';
import { userState } from '../../../atoms';
import { useGetJobTypeLists } from '../../../hooks/useJobType';
import { PulseLoader } from 'react-spinners';
import JobTypeALists from './JobTypeALists';
import JobTypeBLists from './JobTypeBLists';

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5010;
  width: 100%;
  height: 100%;
  height: 100vh;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 60%);
  backdrop-filter: blur(0.5rem);
  transition: all ease 0.8s;
  display: flex;
  justify-content: center;
  align-items: center;
  .centered-content {
    height: 100%;
  }
  > .cctvSettingsModal {
    position: relative;
    z-index: 6000;
    display: flex;
    flex-direction: column;
    height: 36rem;
    max-height: calc(100vh - 2rem);
    max-height: calc(100dvh - 2rem);
    width: 32rem;
    background-color: ${({ theme }: { theme: any }) => theme.board};
    border-radius: 0.5rem;
    overflow: hidden;
    user-select: none;
    @media screen and (max-width: 1023px) {
      width: calc(100vw - 2rem);
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      height: 100vh;
      height: inherit;
      overflow-y: auto;
      border-radius: 0;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      padding: 0.5rem;
      > .titleName {
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0 0.5rem;
      }
      > .closeButton {
        border-radius: 0.5rem;
        margin: 0.25rem;
        padding: 0.25rem;
        display: flex;
        cursor: pointer;
        color: ${({ theme }: { theme: any }) => theme.text_disabled};
        &:hover {
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
          background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
          box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.2);
        }
      }
    }
    .body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      overflow-y: scroll;
      padding: 0.5rem 0;
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
    }
    .result {
      border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      display: flex;
      flex-wrap: wrap;
      overflow: auto;
      min-height: 6rem;
      background-color: ${({ theme }: { theme: any }) => theme.board};
    }
    .footer {
      display: flex;
      justify-content: flex-end;
      justify-content: space-between;
      background-color: ${({ theme }: { theme: any }) => theme.board};
      border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      padding: 0.5rem;
      padding-left: 1rem;
      > .button {
        display: flex;
        align-items: center;
        height: 2.5rem;
        min-width: 5.5rem;
        justify-content: center;
        border-radius: 0.25rem;
        font-size: 0.875rem;
        letter-spacing: normal;
        background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        padding: 0 0.5rem;
        cursor: pointer;
        background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
        color: ${({ theme }: { theme: any }) => theme.text_reverse};
        &:active {
          background-color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
        }
        &.disabled {
          pointer-events: none;
          color: ${({ theme }: { theme: any }) => theme.text_disabled};
          background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
        }
      }
    }
  }
  > .confirmModal {
    position: relative;
    z-index: 6000;
    display: flex;
    flex-direction: column;
    height: 10rem;
    max-height: calc(100vh - 2rem);
    max-height: calc(100dvh - 2rem);
    width: 24rem;
    background-color: ${({ theme }: { theme: any }) => theme.board};
    border-radius: 0.5rem;
    overflow: hidden;
    user-select: none;
    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 6rem;
      flex-shrink: 0;
      border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      .main {
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      }
      .sub {
        font-weight: 600;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
      }
    }
    .buttons {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5em;
      padding: 0.5rem 0.75rem;
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3rem;
        height: 100%;
        width: calc(50% - 0.25rem);
        border-radius: 0.25rem;
        gap: 0.5rem;
        cursor: pointer;
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        &:hover {
          background-color: ${({ theme }: { theme: any }) => theme.board};
          color: ${({ theme }: { theme: any }) => theme.text_secondary};
          box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.05);
          &:active {
            background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
            color: ${({ theme }: { theme: any }) => theme.text_secondary};
            box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
`;

type Props = {
  setOpenModal: Dispatch<SetStateAction<{ status: boolean; type: string }>>; //
  selectedProps: string;
  setSelectedProps: Dispatch<SetStateAction<string>>; // 저장 클릭해서 최종 선택한 공/직종 목록
};

const JobTypeModal = ({ setOpenModal, selectedProps, setSelectedProps }: Props) => {
  const { prejobtypeYn } = useRecoilValue(userState);

  const { data: jobTypeLists, isLoading, isError, refetch } = useGetJobTypeLists(prejobtypeYn as 'Y' | 'N');
  const [selected, setSelected] = useState<string[]>(selectedProps.split(',').filter((el) => el !== ''));

  // 모달 닫기 버튼 클릭 함수
  const onClickClose = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };

  // 저장 버튼 클릭 함수
  const onClickSave = () => {
    setSelectedProps(selected.join(','));
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
    refetch();
  };

  if (isError) return null;

  return (
    <Root onClick={onClickClose} role='presentation'>
      <div className='cctvSettingsModal' role='presentation' onClick={(event) => event.stopPropagation()}>
        <div className='header'>
          <div className='titleName'>{t('직종선택')}</div>
          <span
            className='material-symbols-rounded closeButton' //
            onClick={onClickClose}
            role='presentation'
          >
            close
          </span>
        </div>
        {isLoading && (
          <div className='centered-content'>
            <PulseLoader color='rgb(0, 122, 255)' size='10px' />
          </div>
        )}
        {!isLoading && prejobtypeYn === 'Y' && (
          <JobTypeALists //
            jobTypeLists={jobTypeLists}
            selected={selected}
            setSelected={setSelected}
            onClickSave={onClickSave}
          />
        )}
        {!isLoading && prejobtypeYn === 'N' && (
          <JobTypeBLists //
            jobTypeLists={jobTypeLists}
            selected={selected}
            setSelected={setSelected}
            onClickSave={onClickSave}
          />
        )}
      </div>
    </Root>
  );
};

export default JobTypeModal;
