/**
 * 작성자 : 홍선영
 * 날짜 : 2024.02.29
 * 기능 : 이미지를 바둑판형식으로 배열하는 tuiGrid
 */

import { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import styled from 'styled-components';

import { LIST_COUNT } from '../_constants';
import { ContentsContainerRoot } from '../assets/styles/ContentsContainerRoot';
import { LoadingModalBackground } from '../assets/styles/Modal';
import Portal from './Portal';
import TuiGrid from './Table/TuiGrid';
import Thumbnail from './Modal/Thumbnail';
import IssueGuide from './IssueGuide';

const Root = styled(ContentsContainerRoot)`
  flex-grow: 1;
  .imgWrapper {
    gap: 2rem;
    flex-wrap: wrap;
    padding: 0.5rem;
    height: calc(100% - 10rem);
    overflow: scroll;
  }

  // tui grid 바둑판형식으로 배치 수정
  .tui-container {
    flex-grow: 1;
    height: 100%;
    .tui-grid-content-area {
      > .tui-grid-lside-area {
        display: none !important;
        width: 0 !important;
      }

      > .tui-grid-rside-area {
        height: calc(100% - 3rem) !important;
        margin-left: 0 !important;
      }
    }

    .tui-grid-header-area {
      display: none;
    }

    .tui-grid-body-area {
      height: 71vh !important;
      overflow-x: hidden !important;
    }

    table tbody {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;

      .tui-grid-cell-content {
        > div {
          > :first-child {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          > :last-child {
            padding-top: 1rem;
          }
        }
      }

      > tr:nth-last-child(n + 2),
      > :last-child {
        border-bottom: none;
        flex: 1 1 16.2%;
        flex-grow: 0;

        display: flex;
        justify-content: center;

        > td {
          > div {
            padding: 0.75rem;
          }
        }
      }

      > tr {
        height: fit-content !important;
        > td {
          width: 100%;
          > div {
            width: inherit;
          }
        }
      }

      .tui-grid-cell img {
        border-radius: 5px;
        width: 100%;
        height: 100%;
        min-height: 12rem;
        max-height: 12rem;
      }
    }

    .tui-grid-pagination {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
`;

interface Props {
  data: { cellData: string; url: string }[] | undefined;
  listCount: { type: string; LIST_COUNT: string; cdName: string };
  loading: boolean;
  error: boolean;
}

const TuiGridImage = ({ data, listCount, loading, error }: Props) => {
  const [columns, setColumns] = useState<any[] | null>(null);
  const [openSubModal, setOpenSubModal] = useState<any>({ status: false, type: '', isOverlappingModal: true });

  useEffect(() => {
    setColumns([{ header: 'cellData', name: 'cellData' }]);
  }, []);

  // 썸네일 확대클릭
  const onClickRow = (rowData: any) => {
    setOpenSubModal((prev: any) => ({ ...prev, status: true, type: 'thumbnail', data: { aImgPath: rowData.url }, thumbnailOnly: true }));
  };

  const isLoading = !columns || loading;
  const shouldRenderData = !isLoading && data;

  if (error) return <IssueGuide />;

  if (isLoading)
    return (
      <LoadingModalBackground>
        <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' style={{ width: 'inherit', height: 'inherit' }} />
      </LoadingModalBackground>
    );

  if (!shouldRenderData) return <IssueGuide text='검색을 완료하세요' />;

  return (
    <Root loading={isLoading}>
      <div className='tui-container'>
        <TuiGrid data={data} columns={columns} perPage={Number(listCount[LIST_COUNT])} usePagenation scrollX onClickRow={onClickRow} />
      </div>
      <Portal openModal={openSubModal?.status}>{openSubModal.status === true && openSubModal.type === 'thumbnail' && <Thumbnail openModal={openSubModal} setOpenModal={setOpenSubModal} />}</Portal>
    </Root>
  );
};

export default TuiGridImage;
