/**
 * 작성자 : 김광민
 * 날짜 : 2024.05.08
 * 기능 : 태블릿 입력설정 QR코드 출력 모달 컴포넌트
 */

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useGetTabletSettings } from '../../../hooks/useTablet';
import ButtonContainer from '../../s_cctv/S_cctv1/RealtimeCCTV/ButtonContainer';
import { userState } from '../../../atoms';
import { useRecoilValue } from 'recoil';
import QrcodeSettings from './QrcodeSettings';

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5010;
  width: 100%;
  height: 100%;
  height: 100vh;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 60%);
  backdrop-filter: blur(0.5rem);
  transition: all ease 0.8s;
  display: flex;
  justify-content: center;
  align-items: center;
  > .confirmModal {
    position: relative;
    z-index: 6000;
    display: flex;
    flex-direction: column;
    width: calc(100vw - 2rem);
    width: calc(100dvw - 2rem);
    height: calc(100vh - 2rem);
    height: calc(100dvh - 2rem);
    background-color: ${({ theme }: { theme: any }) => theme.board};
    border-radius: 0.5rem;
    overflow: hidden;
    user-select: none;
    &.print {
      height: 100vh;
      height: 100dvh;
      width: 100vw;
      width: 100dvw;
      .nav,
      .footer {
        display: none;
      }
    }
    .nav {
      display: flex;
      padding: 1rem;
      padding: 0.5rem;
      justify-content: space-between;
      .buttons {
        display: flex;
        gap: 0.25rem;
      }
    }
  }
`;

type Props = {
  onClickClose: () => void;
};

const TabletSettingsQrcode = ({ onClickClose }: Props) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);

  const { generateQRCode, logoUrl } = useGetTabletSettings();
  const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null);
  const [size, setSize] = useState<'small' | 'medium' | 'large' | 'x-large'>('medium');

  const [print, setPrint] = useState(false);

  useEffect(() => {
    const generateQR = async () => {
      const url = await generateQRCode(`https://portaledu.prosafe.kr/main?code=${userInfo.hCd}${userInfo.sCd}`, size);
      setQrCodeUrl(url);
    };
    generateQR();
  }, [size]);

  return (
    <Root onClick={onClickClose} role='presentation'>
      <div
        className={`confirmModal ${print ? 'print' : ''}`}
        role='presentation'
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className='nav'>
          <div className='buttons'>
            <ButtonContainer
              name={t('Small')} //
              onClick={() => setSize('small')}
              selected={size === 'small'}
              width='width60'
              size='medium'
            />
            <ButtonContainer
              name={t('Medium')} //
              onClick={() => setSize('medium')}
              selected={size === 'medium'}
              width='width60'
              size='medium'
            />
            <ButtonContainer
              name={t('Large')} //
              onClick={() => setSize('large')}
              selected={size === 'large'}
              width='width60'
              size='medium'
            />
            <ButtonContainer
              name={t('X-Large')} //
              onClick={() => setSize('x-large')}
              selected={size === 'x-large'}
              width='width60'
              size='medium'
            />
          </div>
          <div className='buttons'>
            <ButtonContainer
              icon='print' //
              name={t('인쇄')}
              onClick={() => setPrint(true)}
              size='medium'
            />
            <ButtonContainer
              icon='close' //
              onClick={onClickClose}
              size='medium'
            />
          </div>
        </div>
        <QrcodeSettings
          size={size} //
          title={userInfo.sName}
          code={`${userInfo.hCd}${userInfo.sCd}`}
          qrCode={qrCodeUrl}
          logo={logoUrl}
          print={print}
          setPrint={setPrint}
        />
      </div>
    </Root>
  );
};

export default TabletSettingsQrcode;
