/*
 * 작성자 : 김광민
 * 날짜 : 2024.07.04
 * 기능 : 상황판에서 가로 사이즈 5 기상종합 컴포넌트
 */

import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { apiGet } from '../../services/_common';
import { weatherWeekGet } from '../../services/weather';
import styled from 'styled-components';
import WeatherTotalNow from './WeatherTotalNow';
import WeatherTotalTime from './WeatherTotalTime';
import WeatherTotalWeek from './WeatherTotalWeek';
import { PulseLoader } from 'react-spinners';

const Root = styled.div`
  height: inherit;
  display: grid !important;
  overflow: hidden;

  > div {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  &.grid-cols-3 {
    grid-template-columns: repeat(3, 1fr) !important;
  }
  &.grid-cols-2 {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  &.grid-cols-1 {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  &.hide-child-1 > div:nth-child(1) {
    display: none;
  }
  &.hide-child-2 > div:nth-child(2) {
    display: none;
  }
  &.hide-child-3 > div:nth-child(3) {
    display: none;
  }
  &.loading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
  }
`;

// 6일간의 날씨 데이터를 초기화
const days = Array.from({ length: 6 }).map((_, index) => {
  const targetDay = dayjs().add(index + 1, 'day'); // +1 to start from tomorrow
  return {
    order: index,
    date: targetDay.date(),
    day: targetDay.format('ddd'),
    sky: '',
  };
});

// 현재 시간에 따른 상태 반환
const getTimeStatus = (): string => {
  const currentHour = dayjs().hour();
  if (currentHour >= 0 && currentHour < 6) return 'morning';
  if (currentHour >= 6 && currentHour < 18) return 'afternoon';
  return 'evening';
};

const WeatherTotal = ({ userInfo, combination }: any) => {
  const { hCd, sCd } = userInfo;
  const [geoInfo, setGeoInfo] = useState({ latitude: '', longitude: '', address: '' });

  // 로컬 스토리지에서 데이터 가져오기
  const after6DaysLocalStorage = window.localStorage.getItem('after6Days');
  const currentWeatherLocalStorage = window.localStorage.getItem('currentWeatherWide');
  const timeWeatherLocalStorage = window.localStorage.getItem('localeTimeWeather');

  const localAfter6Days = after6DaysLocalStorage //
    ? JSON.parse(after6DaysLocalStorage)
    : days;

  const localCurrentWeather = currentWeatherLocalStorage //
    ? JSON.parse(currentWeatherLocalStorage)
    : { wsd: '', tmp: '', minTemp: '', maxTemp: '', rainPct: '', sky: '', pm10: '', pm25: '' };

  const localTimeWeather = timeWeatherLocalStorage //
    ? JSON.parse(timeWeatherLocalStorage)
    : [];

  const [after6Days, setAfter6Days] = useState<IDays[]>(localAfter6Days);
  const [currentWeather, setCurrentWeather] = useState(localCurrentWeather);
  const [timeWeather, setTimeWeather] = useState(localTimeWeather);
  const [width, setWidth] = useState(window.innerWidth);
  const [autoPlay, setAutoPlay] = useState(false);
  const [hideColumn, setHideColumn] = useState<number>(0);
  const [hideChild, setHideChild] = useState<string>('');

  // 사이트 정보 쿼리 설정
  const siteInfo = {
    path: '/site/info',
    dependencies: [hCd, sCd],
    req: { hCd, sCd },
    options: {
      enabled: !!hCd && !!sCd && sCd !== '00000',
    },
  };

  const siteInfoQuery = useQuery(
    [siteInfo.path, ...siteInfo.dependencies], //
    () => apiGet({ path: siteInfo.path, req: siteInfo.req }),
    siteInfo.options
  );

  // 날씨 쿼리 옵션 설정
  const weatherQueryOptions = {
    retry: 3,
    enabled: geoInfo.latitude !== '' && geoInfo.longitude !== '' && geoInfo.address !== '',
  };

  const weekWeatherQuery = useQuery(
    ['weekWeather', geoInfo], //
    () => weatherWeekGet({ latitude: geoInfo.latitude, longitude: geoInfo.longitude, address: geoInfo.address }),
    weatherQueryOptions
  );

  const timeWeatherPath = `/weather/time?latitude=${geoInfo.latitude}&longitude=${geoInfo.longitude}&address=${geoInfo.address}`;
  const timeWeatherQuery = useQuery(
    [timeWeatherPath, geoInfo], //
    () => apiGet({ path: timeWeatherPath }),
    weatherQueryOptions
  );

  const dayWeatherPath = `/weather/day?latitude=${geoInfo.latitude}&longitude=${geoInfo.longitude}&address=${geoInfo.address}`;
  const { data: dayWeatherData } = useQuery(
    [`dayWeatherKey_${geoInfo.latitude}_${geoInfo.longitude}_${geoInfo.address}`, geoInfo, hCd, sCd], //
    () => fetchDayWeatherData(dayWeatherPath),
    weatherQueryOptions
  );

  const fetchDayWeatherData = async (path: string) => {
    try {
      const res = await apiGet({ path });
      const { sky, tmp, wsd } = res.data.data;
      setCurrentWeather((prev: any) => ({ ...prev, tmp, wsd, sky }));
      return { sky, tmp, wsd };
    } catch (error) {
      console.error('error', error);
      throw new Error('error');
    }
  };

  useEffect(() => {
    if (dayWeatherData) {
      const { sky, tmp, wsd } = dayWeatherData;
      setCurrentWeather((prev: any) => ({ ...prev, tmp, wsd, sky }));
    }
  }, [dayWeatherData]);

  useEffect(() => {
    if (currentWeather) {
      window.localStorage.setItem('currentWeather', JSON.stringify(currentWeather));
    }
  }, [currentWeather]);

  // 사이트 정보 쿼리 결과 처리
  useEffect(() => {
    if (!siteInfoQuery.isLoading && siteInfoQuery.data) {
      const {
        siteInfo: { latitude, longitude, address1: address },
      } = siteInfoQuery.data.data.data;
      setGeoInfo({ latitude, longitude, address });
      const district = address.split(' ')[1];
      setCurrentWeather((prev: any) => ({ ...prev, district }));
    }
  }, [siteInfoQuery.isLoading, siteInfoQuery.data]);

  // 주간 날씨 쿼리 결과 처리
  useEffect(() => {
    if (!weekWeatherQuery.isLoading && weekWeatherQuery.data) {
      const {
        morPop,
        todayTmn,
        todayTmx,
        aftPop,
        dinPop,
        weekSky1,
        weekSky2,
        weekSky3,
        weekSky4,
        weekSky5,
        weekSky6,
        weekTmn1,
        weekTmn2,
        weekTmn3,
        weekTmn4,
        weekTmn5,
        weekTmn6,
        weekTmx1,
        weekTmx2,
        weekTmx3,
        weekTmx4,
        weekTmx5,
        weekTmx6,
      } = weekWeatherQuery.data.data.data;
      const status = getTimeStatus();
      setCurrentWeather((prev: any) => ({
        ...prev,
        minTemp: todayTmn,
        maxTemp: todayTmx,
        rainPct: status === 'morning' ? morPop : status === 'evening' ? dinPop : aftPop,
      }));

      const updatedDays = after6Days.map((el: IDays, i: number) => {
        const targetDay = dayjs().add(i + 1, 'day'); // +1 to start from tomorrow
        return {
          ...el,
          date: targetDay.date(),
          day: targetDay.format('ddd'),
          sky: i === 0 ? weekSky1 : i === 1 ? weekSky2 : i === 2 ? weekSky3 : i === 3 ? weekSky4 : i === 4 ? weekSky5 : weekSky6,
          minTemp: i === 0 ? weekTmn1 : i === 1 ? weekTmn2 : i === 2 ? weekTmn3 : i === 3 ? weekTmn4 : i === 4 ? weekTmn5 : weekTmn6,
          maxTemp: i === 0 ? weekTmx1 : i === 1 ? weekTmx2 : i === 2 ? weekTmx3 : i === 3 ? weekTmx4 : i === 4 ? weekTmx5 : weekTmx6,
        };
      });

      setAfter6Days(updatedDays);
    }
  }, [weekWeatherQuery.isLoading, weekWeatherQuery.data]);

  // 시간별 날씨 쿼리 결과 처리
  useEffect(() => {
    if (!timeWeatherQuery.isLoading && timeWeatherQuery.data) {
      if (timeWeatherQuery.data.data.data) {
        const { pm10, pm25, weatherList } = timeWeatherQuery.data.data.data;
        // const current = weatherList[0];
        setCurrentWeather((prev: any) => {
          //   const { wsd, tmp, sky } = current;
          return { ...prev, pm10, pm25 };
        });
        setTimeWeather(weatherList.filter((_: any, i: number) => i !== 0));
      }
    }
  }, [timeWeatherQuery.isLoading, timeWeatherQuery.data]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    const { time, week } = combination;
    const timeOnly = time && !week;
    const weekOnly = !time && week;
    if (timeOnly || weekOnly) {
      if (width < 768) {
        setAutoPlay(true);
      } else {
        setAutoPlay(false);
      }
    } else if (time && week) {
      if (width < 1280) {
        setAutoPlay(true);
      } else {
        setAutoPlay(false);
      }
    } else {
      setAutoPlay(false);
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [window.innerWidth]);

  useEffect(() => {
    let intervalId: any;

    if (autoPlay) {
      intervalId = setInterval(() => {
        const { time, week } = combination;
        trasitionColumn(time && week, hideColumn, width); // 특정 함수 a 호출
      }, 2000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [autoPlay, hideColumn, width]);

  const trasitionColumn = (isThreeColumn: boolean, column: number, size: number) => {
    if (isThreeColumn) {
      setHideColumn(column >= 3 ? 1 : column + 1);
      if (size < 1280 && size >= 768) {
        setHideChild(
          column >= 3 //
            ? 'hide-child-1'
            : `hide-child-${column + 1}`
        );
      } else {
        setHideChild(
          column >= 3 //
            ? 'hide-child-1 hide-child-2'
            : column === 2
            ? 'hide-child-2 hide-child-3'
            : 'hide-child-3 hide-child-1'
        );
      }
    }
    if (!isThreeColumn) {
      if (column === 1) {
        setHideColumn(2);
        setHideChild('hide-child-2');
      } else {
        setHideColumn(1);
        setHideChild('hide-child-1');
      }
    }
  };

  const getGrid = ({ time, week }: { time: boolean; week: boolean }) => {
    const timeOnly = time && !week;
    const weekOnly = !time && week;
    if (timeOnly || weekOnly) {
      if (width >= 768) return 'grid-cols-2';
      return 'grid-cols-1';
    }
    if (width >= 1280) return 'grid-cols-3';
    if (width >= 768) return 'grid-cols-2';
    return 'grid-cols-1';
  };

  if (timeWeather.length === 0) {
    return (
      <Root className='loading'>
        <div>
          <PulseLoader color='rgb(255, 255, 255)' size='8px' />
        </div>
      </Root>
    );
  }

  return (
    <Root className={`${getGrid(combination)} ${autoPlay ? hideChild : ''}`}>
      <WeatherTotalNow //
        currentWeather={currentWeather}
      />
      {combination.time ? (
        <WeatherTotalTime //
          timeWeather={timeWeather}
        />
      ) : null}
      {combination.week ? (
        <WeatherTotalWeek //
          after6Days={after6Days}
        />
      ) : null}
    </Root>
  );
};

export default WeatherTotal;
