import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { PulseLoader } from 'react-spinners';
import { apiGet } from '../../services/_common';

// D-DAY
export const Dday = ({ name, userInfo }: any) => {
  const { t } = useTranslation();
  const [processPercentage, setProcessPercentage] = useState<number>(0);
  const [processDays, setProcessDays] = useState<number>(0);
  const [leftDays, setLeftDays] = useState<number>(0);
  const [startDate, setStartDate] = useState<string>('');
  const [finishDate, setFinishDate] = useState<string>('');

  const siteInfoQuery = useQuery(['siteInfoGet', userInfo], () => apiGet({ path: '/site/info', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
  });

  useEffect(() => {
    if (siteInfoQuery.isSuccess && siteInfoQuery.data && siteInfoQuery.data.data.statusCode === 200) {
      const { siteInfo } = siteInfoQuery.data.data.data;
      if (siteInfo) {
        setStartDate(siteInfo.sDate);
        setFinishDate(siteInfo.fDate);
        const today = dayjs().format('YYYY-MM-DD');
        const today2 = dayjs(today);
        const nStartDate = dayjs(siteInfo.sDate);
        const nFinishDate = dayjs(siteInfo.fDate);

        const nprocessDays = today2.diff(nStartDate, 'day');
        const nleftDays = nFinishDate.diff(today2, 'day');
        const totalDays = nFinishDate.diff(nStartDate, 'day');
        const percentage = Math.round((nprocessDays / totalDays) * 100);
        setProcessPercentage(percentage);
        setProcessDays(nprocessDays);
        setLeftDays(nleftDays);
      }
    }
  }, [siteInfoQuery.isSuccess, siteInfoQuery.isRefetching]);

  const onClickRefresh = () => {
    siteInfoQuery.refetch();
  };

  if (!siteInfoQuery.isSuccess || siteInfoQuery.data.status !== 200 || siteInfoQuery.isRefetching) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }
  // const totalPercentage = { width: 100 - processPercentage% };
  return (
    <div className='dDay'>
      <div className='widget-header'>
        <div className='widget-title flex-between'>
          <span>{t(name)}</span>
          <div className='refresh-btn' onClick={onClickRefresh} role='presentation'>
            <span className='material-symbols-rounded'>refresh</span>
          </div>
        </div>
      </div>
      <div className='widget-body progressBar'>
        <div className='content'>
          <dl className='flex-basic'>
            <dt>{t('전체 기간')}</dt>
            <dd>
              {startDate} ~ {finishDate}
            </dd>
          </dl>
          <div className='flex-basic'>
            <dl className='flex-basic'>
              <dt>{t('진행 일 수')}</dt>
              <dd>
                <span className='green'>{processDays}</span> {t('-일')}
              </dd>
            </dl>
            <span />
            <dl className='flex-basic'>
              <dt>{t('남은 일 수')}</dt>
              <dd>
                <span className='blue'>{leftDays}</span> {t('-일')}
              </dd>
            </dl>
          </div>
        </div>
        <div className='progressBar-group flex-col'>
          <div className='progressBar-text flex-between'>
            <span>{t('전체 진행률')}</span>
            <span>{processPercentage}%</span>
          </div>
          <div className='fullBar'>
            <div className='fillBar' style={{ width: `${processPercentage}%` }} />
            <div className='emptyBar' style={{ width: `${100 - processPercentage}%` }} />
          </div>
        </div>
      </div>
    </div>
  );
};
