/**
 * 작성자 : 김광민
 * 날짜 : 2024.05.02
 * 기능 : 예/아니요 확인 모달 컴포넌트
 */

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5010;
  width: 100%;
  height: 100%;
  height: 100vh;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 60%);
  backdrop-filter: blur(0.5rem);
  transition: all ease 0.8s;
  display: flex;
  justify-content: center;
  align-items: center;
  > .confirmModal {
    position: relative;
    z-index: 6000;
    display: flex;
    flex-direction: column;
    height: 10rem;
    max-height: calc(100vh - 2rem);
    max-height: calc(100dvh - 2rem);
    width: 24rem;
    background-color: ${({ theme }: { theme: any }) => theme.board};
    border-radius: 0.5rem;
    overflow: hidden;
    user-select: none;
    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 6rem;
      flex-shrink: 0;
      border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      .main {
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      }
      .sub {
        font-weight: 600;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
      }
    }
    .buttons {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5em;
      padding: 0.5rem 0.75rem;
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3rem;
        height: 100%;
        width: calc(50% - 0.25rem);
        border-radius: 0.25rem;
        gap: 0.5rem;
        cursor: pointer;
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        &:hover {
          background-color: ${({ theme }: { theme: any }) => theme.board};
          color: ${({ theme }: { theme: any }) => theme.text_secondary};
          box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.05);
          &:active {
            background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
            color: ${({ theme }: { theme: any }) => theme.text_secondary};
            box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
`;

type Props = {
  onClickClose: () => void;
  onClickConfirm: () => void;
  mainText: string;
  subText?: string;
};

const YesOrNoConfirm = ({ onClickClose, onClickConfirm, mainText, subText }: Props) => {
  const { t } = useTranslation();

  return (
    <Root onClick={onClickClose} role='presentation'>
      <div
        className='confirmModal'
        role='presentation'
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className='title'>
          <div className='main'>{mainText}</div>
          {subText && <div className='sub'>{subText}</div>}
        </div>
        <div className='buttons'>
          <span
            className='button button_cancel' //
            role='presentation'
            onClick={onClickClose}
          >
            {t('아니요')}
          </span>
          <span
            className='button button_confirm' //
            role='presentation'
            onClick={onClickConfirm}
          >
            {t('예')}
          </span>
        </div>
      </div>
    </Root>
  );
};

export default YesOrNoConfirm;
