import React from 'react';
import styled from 'styled-components';

interface Props {
  onChange: () => void;
  status: boolean;
  text: string;
}
const Root = styled.div`
  user-select: none;
  display: flex;
  color: ${({ theme }: { theme: any }) => theme.text_primary};
  background-color: ${({ theme }: { theme: any }) => theme.tonal};
  border-radius: 0.25rem;
  height: 2.5rem;
  align-items: center;
  flex-shrink: 0;

  span {
    padding-left: 0.75rem;
    font-size: 0.875rem;
  }
  > div {
    transition: justify-content 0.5s ease-in-out;
  }
  .flex-start {
    background-color: #c4c4c4;
    outline: 2px solid #c4c4c4;
    div {
      color: #a0a0a0;
    }
  }
  .flex-end {
    background-color: #10bd10;
    outline: 2px solid #10bd10;
    div {
      color: #009b00;
    }
  }
`;

const Switch = styled.div`
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  width: 3rem;
  height: 1.5rem;
  border-radius: 0.25rem;
  margin: 0 0.625rem;
  > div {
    /* outline: 2px solid white; */
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }: { theme: any }) => theme.bw_inverse};
    border-radius: 0.25rem;
    width: 2rem;
    height: 1.5rem;
    font-size: 0.75rem;
    letter-spacing: 1;
  }
`;

export default function Toggle({ onChange, status, text }: Props) {
  return (
    <Root>
      <span>{text}</span>
      <Switch onClick={onChange} role='presentation' className={`${status ? 'flex-end' : 'flex-start'}`}>
        <div>{status ? 'ON' : 'OFF'}</div>
        {/* <div /> */}
      </Switch>
    </Root>
  );
}
