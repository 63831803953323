import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import { IComCdList } from 'customTypes';
import { IUser, userState } from '../../../atoms';
import { LIST_COUNT } from '../../../_constants';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { useSetSjcd } from '../../../utils/useSetSjcd';
import { todayYYYYMMDD } from '../../../utils/formatDate';
import { setComCdListState } from '../../../utils/setComCdListState';
import useOnKeydownF9 from '../../../utils/useOnKeydownF9';
import useSetListCount from '../../../utils/useSetListCount';
import { apiGet } from '../../../services/_common';
import { BtnGhost } from '../../../components/Button';
import Input from '../../../components/Input';
import SelectBox from '../../../components/SelectBox';
import ShortcutButton from '../../../components/button/ShortcutButton';
import SearchSelectBoxSm from '../../../components/SearchSelectBoxSm';
import DatePickerComponent from '../../../components/DatePicker';

interface IWorkerImageSearchOption {
  setIsFetching: Dispatch<SetStateAction<boolean>>; // 검색데이터 패칭상태
  setIsError: Dispatch<SetStateAction<boolean>>;
  tableState: any[];
  setTableState: Dispatch<SetStateAction<any[]>>;
  listCount: any;
  setListCount: Dispatch<SetStateAction<any>>;
  refetchFlag: boolean;
  setRefetchFlag: Dispatch<SetStateAction<boolean>>;
}

const WorkerImageSearchOption = ({ setIsFetching, setIsError, tableState, setTableState, listCount, setListCount, refetchFlag, setRefetchFlag }: IWorkerImageSearchOption) => {
  const { t } = useTranslation();
  const toDay = todayYYYYMMDD();
  const { siteJoinInfoList } = useSetSjcd();
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const [sjCd, setSjCd] = useState({ type: 'sjCd', sjCd: '', cdName: '' });
  const [searchOption, setSearchOption] = useState({ wName: '' });
  const userInfo = useRecoilValue<IUser>(userState);
  const [listCountComCdList, setListCountComCdList] = useState<IComCdList[]>([]); // 검색줄수 공통코드
  const { patchUserMenuAPI } = useSetListCount(); // 검색줄수설정 훅
  const wNameRef = useRef<HTMLInputElement>(null);
  const [wDate, setWDate] = useState(toDay);

  const { data, isLoading, isFetching, isError, refetch } = useQuery(['workerList', userInfo.hCd, userInfo.sCd], () => fetchData(), {
    enabled: false,
    cacheTime: 0,
  });

  useEffect(() => {
    if (refetchFlag) refetch();
  }, [refetchFlag]);

  const fetchData = async () => {
    try {
      const path = '/worker';
      const req = {
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        wName: searchOption.wName,
        sjCd: sjCd.sjCd,
        wDate,
        wWorkstatus: 'I',
      };
      const res = await apiGet({ path, req });

      const formattedList = res.data.data.workerList.map((item: any, index: number) => ({
        index,
        url: item.wImg,
        wName: item.wName,
        sjName: item.sjName,
      }));

      const formattedTableData = formattedList.map((el: any, index: number) => ({
        cellData: `<div key=${index}>
            <div className='flex-flex flex-col'>
              <img src=${el.url} alt=''/>
            </div>
            <div>
              <ul>
                <li>${el.sjName}</li>
                <li>${el.wName}</li>
              </ul>
            </div>
          </div>`,
        url: el.url,
      }));

      setTableState(formattedTableData);
      return formattedTableData;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    } finally {
      setRefetchFlag(false);
    }
  };

  useEffect(() => {
    setComCdListState(LIST_COUNT, setListCountComCdList, false);
  }, []);

  useEffect(() => {
    setIsFetching(isFetching);
  }, [isLoading, isFetching]);

  useEffect(() => {
    setIsError(isError);
  }, [isError]);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  // 검색 버튼 클릭
  const onClickSearch = () => {
    if (wDate && wDate.length < 8) toast.warning(t('날짜를 다시 입력하세요'));
    else refetch();
  };

  // 근로자명 입력창에서 엔터입력시 검색
  const loginOnEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (wNameRef.current) onClickSearch();
    }
  };

  const initiateSearchOptions = () => {
    setSjCd({ type: 'sjCd', sjCd: '', cdName: '' });
    setWDate(toDay);
    setSearchOption({ wName: '' });
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    initiateSearchOptions();
  };

  return (
    <SearchOptions>
      <div className='inputsWrapper'>
        <div className='inputForm-row withLabel'>
          <label htmlFor='sjCd'>{t('협력업체')}</label>
          <div className='inputForm-col'>
            <SearchSelectBoxSm
              options={siteJoinInfoList}
              defaultOption={t('전체')}
              state={sjCd}
              setState={setSjCd}
              stateKey='sjCd'
              codeKey='cdName'
              initiateKey={sjCd.sjCd}
              filterbar='filter-1-left'
              optionHeight='height-md'
            />
          </div>
        </div>
        <div className='inputForm-row'>
          <div className='inputForm-col'>
            <div className='datePickerWrapper'>
              <DatePickerComponent startDate={wDate} setDate={setWDate} popperPlacement='bottom' />
            </div>
          </div>
        </div>
        <div className='inputForm-row'>
          <div className='inputForm-col'>
            <Input placeholder={t('근로자명')} label='' type='text' id='wName' name='wName' state={searchOption} setState={setSearchOption} inputRef={wNameRef} onKeyDown={loginOnEnterKeyDown} />
          </div>
        </div>
      </div>
      <div className='inputsWrapper'>
        <div className='secondSearchOption'>
          <div className='flex-basic textBtnGroup'>
            <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
            <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
            <div className='searchResult'>
              {t('총')}
              <span>{tableState?.length || 0}</span>
              {t('개')}
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col withLabelComCf'>
                <label htmlFor='listCount'>{t('보기 설정')}</label>
                <SelectBox
                  options={listCountComCdList}
                  defaultOption={listCount.cdName}
                  state={listCount}
                  setState={setListCount}
                  stateKey={LIST_COUNT}
                  initiateKey={listCount[LIST_COUNT]}
                  setTableLinesAPI={patchUserMenuAPI}
                  optionHeight='height-sm'
                  rsearch
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SearchOptions>
  );
};

export default WorkerImageSearchOption;
