/**
 * 작성자 : 홍선영
 * 날짜 : 2023.04.06
 * 기능 : 인풋이 특정 길이를 충족하면 다음 인풋으로 포커스를 이동시키고,
 *        마지막 값 입력시 구분자로 인풋값 모두 붙여서 스테이트 업데이트 하는 컴포넌트
 */

import { useRef, Dispatch, SetStateAction, useEffect } from 'react';
import { limitDigits } from '../utils/limitDigits';
import { applyBorderStyle } from '../utils/applyBorderStyle';

interface INumbersInput {
  stateName: string;
  separator: string;
  state: any;
  setState: Dispatch<SetStateAction<any>>;
  setInfoState: Dispatch<SetStateAction<any>>;
  isFirstInputFocused?: boolean;
  isSaveClicked?: boolean;
}

const IpInput = ({ stateName, separator, state, setState, setInfoState, isFirstInputFocused, isSaveClicked }: INumbersInput) => {
  const firstInputRef = useRef<HTMLInputElement>(null); // 첫번째 칸 인풋 레프
  const secondInputRef = useRef<HTMLInputElement>(null); // 두번째 칸 인풋 레프
  const thirdInputRef = useRef<HTMLInputElement>(null); // 두번째 칸 인풋 레프
  const lastInputRef = useRef<HTMLInputElement>(null); // 마지막 칸 인풋 레프

  useEffect(() => {
    if (isFirstInputFocused) {
      firstInputRef.current?.focus();
    }
  }, [isFirstInputFocused]);

  // 마지막 인풋 입력 시 '.'로 인풋 1,2,3 연결하고 state 값 업데이트
  const mergeNumbers = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    // 마지막 인풋 길이 3자리 길이제한
    if (name === 'num4') {
      if (value.length > 3) {
        limitDigits(value);
      } else {
        setState((prev: any) => ({ ...prev, [name]: value }));
      }
    } else {
      setState((prev: any) => ({ ...prev, [name]: value }));
    }
  };

  // input의 길이가 maxLength를 충족하면, 다음 인풋칸으로 포커스 이동
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>, nextRef: any, maxLength: number) => {
    const { name, value } = e.currentTarget;
    setState((prev: any) => ({ ...prev, [name]: value }));
    if (nextRef !== null) {
      if (value.length >= maxLength) nextRef.current?.focus();
    }
  };

  const onBlur = () => {
    const mergedNumber = `${state.num1}${separator}${state.num2}${separator}${state.num3}${separator}${state.num4}`;
    setInfoState((prev: any) => ({ ...prev, [stateName]: mergedNumber }));
  };

  return (
    <div className='flex-basic'>
      <input
        className='text-center'
        type='number'
        name='num1'
        value={state.num1}
        onChange={(e) => onChangeInput(e, secondInputRef, 3)}
        ref={firstInputRef}
        style={isSaveClicked ? applyBorderStyle(state.num1, 'red', 'num1') : undefined}
      />
      <span className='dash'>{separator}</span>
      <input
        className='text-center'
        type='number'
        name='num2'
        value={state.num2}
        ref={secondInputRef}
        onChange={(e) => onChangeInput(e, thirdInputRef, 3)}
        style={isSaveClicked ? applyBorderStyle(state.num2, 'red', 'num2') : undefined}
      />
      <span className='dash'>{separator}</span>
      <input
        className='text-center'
        type='number'
        name='num3'
        value={state.num3}
        ref={thirdInputRef}
        onChange={(e) => onChangeInput(e, lastInputRef, 3)}
        style={isSaveClicked ? applyBorderStyle(state.num3, 'red', 'num3') : undefined}
      />
      <span className='dash'>{separator}</span>
      <input
        className='text-center'
        type='number'
        name='num4'
        value={state.num4}
        ref={lastInputRef}
        onChange={mergeNumbers}
        style={isSaveClicked ? applyBorderStyle(state.num4, 'red', 'num4') : undefined}
      />
      {/* <input className='text-center' type='number' name='num4' value={state.num4} ref={lastInputRef} onChange={mergeNumbers} onBlur={onBlur} /> */}
    </div>
  );
};

export default IpInput;
