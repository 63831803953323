/**
 * 작성자 : 홍선영
 * 날짜 : 2023.11.28
 * 경로 : 설정관리-장비관리-센서 측정 범위 관리 (현장관리자)
 */

import { useEffect, useState } from 'react';
import styled from 'styled-components';

import i18n from '../../../translation/i18n';
import { ContentsContainerRoot } from '../../../assets/styles/ContentsContainerRoot';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { BtnBlue, BtnGhost, BtnRed } from '../../../components/Button';
import { COMCD_SENSOR_RANGE, COMCD_USE_YN, INIT_USE_YN_A, SENSOR_HINT } from '../../../_constants';
import { setComCdListState } from '../../../utils/setComCdListState';
import { IComCdList, IModal, ISensorRange } from 'customTypes';
import SelectBox from '../../../components/SelectBox';
import Input from '../../../components/Input';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { IUser, userState } from '../../../atoms';
import { InputTable } from '../../../assets/styles/InputTable';
import SelectBoxs from '../../../components/SelectBoxs';
import { toast } from 'react-toastify';
import Portal from '../../../components/Portal';
import DeleteModal from '../../../components/Modal/DeleteModal2';
import { useSetAuth } from '../../../utils/useSetAuth';
import { useTranslation } from 'react-i18next';
import { applyBorderStyle } from '../../../utils/applyBorderStyle';
import { apiGet, apiPost } from '../../../services/_common';
import { useFetchCommonCodeList } from '../../../services/useSetCodeListInSelectBoxForm';
import { logPost } from '../../../services/log';
import InfoTextWithIcon from '../../../components/Modal/InfoTextWithIcon';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 0.5rem;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;

const Root = styled(ContentsContainerRoot)`
  .secondSearchOption {
    width: fit-content;
  }

  ul > li {
    width: 100% !important;
  }

  .textBtnGroup button {
    padding: 0 0.75rem;
    width: 4.5rem;
    height: 2.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
  }

  .o2Select > div {
    width: 9.2rem;
  }
`;

const defaultTable = [
  // {
  //   srCd: 'O2',
  //   level1: '',
  //   level2: '',
  //   level3: '',
  //   useYn: 'Y',
  //   sName: '산소',
  //   delYn: 'N',
  // },
  {
    srCd: 'NO2',
    level1: '',
    level2: '',
    level3: '',
    useYn: 'Y',
    sName: '이산화질소',
    delYn: 'N',
  },
  {
    srCd: 'CO',
    level1: '',
    level2: '',
    level3: '',
    useYn: 'Y',
    sName: '일산화탄소',
    delYn: 'N',
  },
  {
    srCd: 'CO2',
    level1: '',
    level2: '',
    level3: '',
    useYn: 'Y',
    sName: '이산화탄소',
    delYn: 'N',
  },
  {
    srCd: 'H2S',
    level1: '',
    level2: '',
    level3: '',
    useYn: 'Y',
    sName: '황화수소',
    delYn: 'N',
  },
  {
    srCd: 'CH4',
    level1: '',
    level2: '',
    level3: '',
    useYn: 'Y',
    sName: '메탄',
    delYn: 'N',
  },
];

const SensorRangeSetting = () => {
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const userInfo = useRecoilValue<IUser>(userState);
  const [loading, setLoading] = useState(false);
  const [searchOptionUseYn, setSearchOptionUseYn] = useState(INIT_USE_YN_A);
  const [searchOption, setSearchOption] = useState({ sensorName: '' });
  const [table, setTable] = useState<ISensorRange[]>([]);
  const [initTable, setInitTable] = useState<ISensorRange[]>([]);
  const [sensorRangeList, setSensorRangeList] = useState<IComCdList[]>([]);
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const sortOrder = ['O2', 'NO2', 'CO', 'CO2', 'H2S', 'CH4'];
  const [isFirstInput, setIsFirstInput] = useState(false);
  const [isO2Del, setIsO2Del] = useState(false);
  const [o2UseYn, setO2UseYn] = useState({ type: COMCD_USE_YN, [COMCD_USE_YN]: 'Y', cdName: t('사용') });
  const { data: useYnComCdListWithAll } = useFetchCommonCodeList(COMCD_USE_YN, true); // 사용여부 공통코드 목록 (전체포함)
  const { data: useYnComCdList } = useFetchCommonCodeList(COMCD_USE_YN, false); // 사용유무 공통코드 목록

  const sensorRangeQuery = useQuery(['sensorRangeGet', userInfo.hCd, userInfo.sCd], () => apiGet({ path: '/sensor/range', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
  });

  useEffect(() => {
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '장비 관리 > 센서 측정 범위 관리',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    setSearchOptionUseYn(INIT_USE_YN_A);
  }, [i18n.language]);

  useEffect(() => {
    if (sensorRangeQuery.isSuccess && sensorRangeQuery.data.status === 200) {
      const { rangeList } = sensorRangeQuery.data.data.data;
      setComCdListState(COMCD_SENSOR_RANGE, setSensorRangeList, false);
      if (rangeList.length > 0) {
        let newArray = rangeList;

        // 목록에서 산소값 제외 (고정값 넣기 위함)
        const findO2 = rangeList.find((el: any) => el.srCd === 'O2');
        if (findO2) {
          const removeO2 = rangeList.filter((el: any) => el.srCd !== 'O2');
          newArray = removeO2;
          setO2UseYn({ type: COMCD_USE_YN, [COMCD_USE_YN]: findO2.useYn, cdName: findO2.useYn === 'Y' ? t('사용') : t('미사용') });
          if (findO2.delYn === 'Y') setIsO2Del(true);
          else setIsO2Del(false);
        }

        const sortedData = sortTableOrder(newArray);

        const filterDelYnN = newArray.filter((el: ISensorRange) => el.delYn === 'N');
        if (filterDelYnN.length > 0) {
          setTable(sortedData);
          setInitTable(sortedData);
        } else {
          setIsFirstInput(false);
        }
      } else {
        // 최초로 입력하는 경우, 기본값을 가져옴
        setIsFirstInput(true);
        setTable(defaultTable);
        setInitTable(defaultTable);
      }
    }
  }, [sensorRangeQuery.isSuccess, sensorRangeQuery.isRefetching]);

  const sortTableOrder = (array: ISensorRange[]) => {
    array.sort((a: ISensorRange, b: ISensorRange) => {
      const indexA = sortOrder.indexOf(a.srCd);
      const indexB = sortOrder.indexOf(b.srCd);

      return indexA - indexB;
    });
    return array;
  };

  const findSensorRangeCode = (sensorCode: string) => {
    const findCode = sensorRangeList.find((el: any) => el[COMCD_SENSOR_RANGE] === sensorCode);
    if (findCode) return t(`${findCode.cdName}`);
    return '';
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    Object.keys(searchOption).map((el: any) => setSearchOption((prev: any) => ({ ...prev, [el]: '' })));
    setSearchOptionUseYn(INIT_USE_YN_A);
  };

  const onClickSave = () => {
    setIsSaveClicked(true);
    const newArray = table.map((el: any) => ({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      srCd: el.srCd,
      level1: el.level1,
      level2: el.level2,
      level3: el.level3,
      useYn: el.useYn,
      delYn: el.delYn,
      writer: userInfo.userId,
      editor: userInfo.userId,
    }));
    const delO2 = newArray.filter((v: any) => v.srCd !== 'O2');
    const filterDel = delO2.filter((el: ISensorRange) => el.delYn === 'N');
    const emptyCheck = filterDel.filter((el: any) => {
      const required = ['level1', 'level2', 'level3']; // 필수입력값 name
      const check = required.find((el2: any) => el[el2] === '');
      return check;
    });

    if (emptyCheck.length > 0) toast.warning(t('필수입력값을 모두 입력하세요'));
    else saveSensorRangeAPI(newArray);
  };

  const saveSensorRangeAPI = async (sensorRangeListParam: ISensorRange[]) => {
    const defaultO2Value = {
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      srCd: 'O2',
      level1: '19',
      level2: '0.5',
      level3: '22.5',
      useYn: o2UseYn[COMCD_USE_YN],
      delYn: 'N',
      writer: userInfo.userId,
      editor: userInfo.userId,
    };

    const req = { sensorRangeReqDto: [defaultO2Value, ...sensorRangeListParam] };
    const res = await apiPost({ path: '/sensor/range', req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      setIsFirstInput(false);
      toast.success(t(message));

      let newArray = data.rangeList;

      // 목록에서 산소값 제외 (고정값 넣기 위함)
      const findO2 = newArray.find((el: any) => el.srCd === 'O2');
      if (findO2) {
        const removeO2 = newArray.filter((el: any) => el.srCd !== 'O2');
        newArray = removeO2;
        setO2UseYn({ type: COMCD_USE_YN, [COMCD_USE_YN]: findO2.useYn, cdName: findO2.useYn === 'Y' ? t('사용') : t('미사용') });
        if (findO2.delYn === 'Y') setIsO2Del(true);
        else setIsO2Del(false);
      }

      const sortedData = sortTableOrder(newArray);
      setTable(sortedData);
      setInitTable(sortedData);
    }
  };

  const deleteAPI = async (deleteObj: any) => {
    const req = {
      sensorRangeReqDto: table.map((el: ISensorRange) =>
        el.srCd === deleteObj.srCd
          ? {
              hCd: userInfo.hCd,
              sCd: userInfo.sCd,
              srCd: el.srCd,
              level1: el.level1,
              level2: el.level2,
              level3: el.level3,
              useYn: el.useYn,
              delYn: 'Y',
              writer: userInfo.userId,
              editor: userInfo.userId,
            }
          : {
              hCd: userInfo.hCd,
              sCd: userInfo.sCd,
              srCd: el.srCd,
              level1: el.level1,
              level2: el.level2,
              level3: el.level3,
              useYn: el.useYn,
              delYn: el.delYn,
              writer: userInfo.userId,
              editor: userInfo.userId,
            }
      ),
    };
    const res = await apiPost({ path: '/sensor/range', req });
    // const res = await sensorRangePost(reqData);
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      toast.success(t('센서 범위 목록 삭제 성공'));

      if (data.rangeList.length > 0) {
        const filterDelYnN = data.rangeList.filter((el: ISensorRange) => el.delYn === 'N');
        const sortedData = sortTableOrder(filterDelYnN);
        setTable(sortedData);
        setInitTable(sortedData);
      } else {
        setIsFirstInput(false);
      }
    }
  };

  const deleteO2API = async (deleteObj: any) => {
    const req = { sensorRangeReqDto: [deleteObj] };
    const res = await apiPost({ path: '/sensor/range', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      toast.success(t('센서 범위 목록 삭제 성공'));
      setIsO2Del(true);
    }
  };

  const onClickDelete = (delObj: any, index: number) => {
    if (!isFirstInput) {
      const data = { ...delObj, editor: userInfo.userId }; // 삭제 시 에디터아이디 추가
      setOpenModal((prev: any) => ({ ...prev, type: 'delete', status: true, table, setTable, api: () => deleteAPI(data), el: data, index }));
    } else {
      const newArray = table.map((el: ISensorRange) => (el.srCd === delObj.srCd ? { ...delObj, delYn: 'Y' } : { ...el }));
      setTable(newArray);
    }
  };

  const onClickDeleteO2 = () => {
    const defaultO2Value = {
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      srCd: 'O2',
      level1: '19',
      level2: '0.5',
      level3: '22.5',
      useYn: o2UseYn[COMCD_USE_YN],
      delYn: 'Y',
      writer: userInfo.userId,
      editor: userInfo.userId,
    };
    if (!isFirstInput) {
      setOpenModal((prev: any) => ({ ...prev, type: 'delete', status: true, table, setTable, api: () => deleteO2API(defaultO2Value), el: defaultO2Value }));
    }
  };

  useEffect(() => {
    // 검색옵션 변경됐을 때 필터링 처리
    // 필터링된 어레이 리턴, 대소문자구분X
    const filteredArray = initTable.filter((item: any) => {
      return item.sName?.toLowerCase()?.includes(searchOption.sensorName?.toLowerCase());
    });

    const result = YNFilter(filteredArray, 'useYn', searchOptionUseYn[COMCD_USE_YN]);
    if (result.length > 0) setTable(result);
    else setTable([]);
  }, [searchOption.sensorName, searchOptionUseYn[COMCD_USE_YN]]);

  const YNFilter = (initialArray: ISensorRange[], key: string, option: any) => {
    if (option === 'Y') {
      return initialArray.filter((el: any) => el[key] === 'Y');
    }
    if (option === 'N') {
      return initialArray.filter((el: any) => el[key] === 'N');
    }
    return initialArray;
  };

  return (
    <Root loading={loading}>
      <SearchOptions>
        <div className='inputsWrapper'>
          <div className='flex-basic textBtnGroup'>
            <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
          </div>
          <div className='inputForm-col withLabelComCf'>
            <label htmlFor='searchOptionUseYn'>{t('사용유무')}</label>
            <SelectBox
              options={useYnComCdListWithAll}
              defaultOption={t('전체')}
              state={searchOptionUseYn}
              setState={setSearchOptionUseYn}
              stateKey={COMCD_USE_YN}
              initiateKey={searchOptionUseYn[COMCD_USE_YN]}
              filterbar='filter-1-left'
            />
          </div>
          <div className='inputForm-row'>
            <div className='inputForm-col'>
              <Input placeholder={t('센서명')} label='' type='text' id='sensorName' name='sensorName' state={searchOption} setState={setSearchOption} />
            </div>
          </div>
        </div>
      </SearchOptions>
      <InfoTextWithIcon icon='lightbulb' text={t('O2(산소)는 입력 불필요')} />
      <InputTable>
        <div className='thead'>
          <div className='tr'>
            <div className='trCol2p5 flex-center tableStickyNo'>No</div>
            <div className='trCol12 flex-center content-overflow'>{t('코드')}</div>
            <div className='trCol12 flex-center content-overflow'>{t('센서명')}</div>
            <div className='trCol12 flex-center content-overflow required'>{t('주의')}</div>
            <div className='trCol12 flex-center content-overflow required'>{t('경고')}</div>
            <div className='trCol12 flex-center content-overflow required'>{t('위험')}</div>
            <div className='trCol12 flex-center'>{t('사용유무')}</div>
            <div className='trCol6 flex-basic shrink0 padding-right-05'> </div>
          </div>
        </div>
        <div className='table'>
          <div className='tbody'>
            {!isO2Del && (
              <div className='tr' role='button' tabIndex={0}>
                <div className='trCol2p5 flex-center tableStickyNo'>1</div>
                <div className='trCol12 flex-center content-overflow'>O2</div>
                <div className='trCol12 flex-center content-overflow'>{t('산소')}</div>
                <div className='trCol12 flex-center content-overflow'>
                  <div>
                    <div>{t('하한가')} : 18.5% ~ 19%</div>
                    <div>{t('상한가')} : 22.5% ~ 23%</div>
                  </div>
                </div>
                <div className='trCol12 flex-center content-overflow'>
                  <div>
                    <div>{t('하한가')} : 18% ~ 18.5%</div>
                    <div>{t('상한가')} : 23% ~ 23.5%</div>
                  </div>
                </div>
                <div className='trCol12 flex-center content-overflow'>
                  <div>
                    <div>
                      {t('하한가')} : 18% {t('미만')}
                    </div>
                    <div>
                      {t('상한가')} : 23.5% {t('초과')}
                    </div>
                  </div>
                </div>
                <div className='trCol12 flex-center o2Select'>
                  <SelectBox
                    options={useYnComCdList}
                    defaultOption={o2UseYn[COMCD_USE_YN] === 'Y' ? t('사용') : t('미사용')}
                    state={o2UseYn}
                    setState={setO2UseYn}
                    stateKey={COMCD_USE_YN}
                    initiateKey={o2UseYn[COMCD_USE_YN]}
                    filterbar='filter-1-left'
                  />
                </div>
                {auth.deleteAuth && (
                  <div className='trCol6 flex-center shrink0 padding-right-05'>
                    <BtnRed onClick={onClickDeleteO2}>{isFirstInput ? t('제거') : t('삭제')}</BtnRed>
                  </div>
                )}
              </div>
            )}

            {table?.length > 0 ? (
              table.map((el: ISensorRange, i: number) => {
                if (el.delYn !== 'Y') {
                  return (
                    <div className='tr' role='button' tabIndex={0} key={`sensor_${i}`}>
                      <div className='trCol2p5 flex-center tableStickyNo'>{isO2Del ? i + 1 : i + 2}</div>
                      <div className='trCol12 flex-center content-overflow'>{el.srCd}</div>
                      <div className='trCol12 flex-center content-overflow'>{findSensorRangeCode(el.srCd)}</div>
                      <div className='trCol12 flex-center content-overflow'>
                        <Input
                          name='level1'
                          type='input'
                          state={table}
                          setState={setTable}
                          className='floatingPlaceholder'
                          label={SENSOR_HINT[el.srCd]?.level1}
                          floatingPlaceholder
                          trim
                          stateType={{ type: 'array', index: i }}
                          valueType='decimal'
                          disabled={!auth.createAuth && !auth.updateAuth}
                          getBorderStyle={isSaveClicked ? applyBorderStyle(el.level1, 'red', 'level1') : undefined}
                        />
                        <span style={{ padding: '0 0.5rem' }}>{t('이상')}</span>
                      </div>
                      <div className='trCol12 flex-center content-overflow'>
                        <Input
                          name='level2'
                          type='input'
                          state={table}
                          setState={setTable}
                          className='floatingPlaceholder'
                          label={SENSOR_HINT[el.srCd]?.level2}
                          floatingPlaceholder
                          trim
                          stateType={{ type: 'array', index: i }}
                          valueType='decimal'
                          disabled={!auth.createAuth && !auth.updateAuth}
                          getBorderStyle={isSaveClicked ? applyBorderStyle(el.level2, 'red', 'level2') : undefined}
                        />
                        <span style={{ padding: '0 0.5rem' }}>{t('이상')}</span>
                      </div>
                      <div className='trCol12 flex-center content-overflow'>
                        <Input
                          name='level3'
                          type='input'
                          state={table}
                          setState={setTable}
                          className='floatingPlaceholder'
                          label={SENSOR_HINT[el.srCd]?.level3}
                          floatingPlaceholder
                          trim
                          stateType={{ type: 'array', index: i }}
                          valueType='decimal'
                          disabled={!auth.createAuth && !auth.updateAuth}
                          getBorderStyle={isSaveClicked ? applyBorderStyle(el.level3, 'red', 'level3') : undefined}
                        />
                        <span style={{ padding: '0 0.5rem' }}>{t('이상')}</span>
                      </div>
                      <div className='trCol12 flex-center'>
                        <SelectBoxs
                          options={useYnComCdList}
                          defaultOption={el.useYn === 'Y' ? t('사용') : t('미사용')}
                          state={table}
                          setState={setTable}
                          rawData={initTable}
                          setRawData={setInitTable}
                          stateKey='useYn'
                          codeKey='cdName'
                          index={i}
                          object={el}
                          primaryKey='useYn'
                          width='80%'
                        />
                      </div>
                      {auth.deleteAuth && (
                        <div className='trCol6 flex-center shrink0 padding-right-05'>
                          <BtnRed onClick={() => onClickDelete(el, i)}>{isFirstInput ? t('제거') : t('삭제')}</BtnRed>
                        </div>
                      )}
                    </div>
                  );
                }
                return null;
              })
            ) : (
              <div className='noData'>No data.</div>
            )}
          </div>
        </div>
      </InputTable>
      <ButtonsWrapper>{(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={onClickSave}>{t('저장')}</BtnBlue>}</ButtonsWrapper>
      <Portal openModal={openModal?.status}>{openModal && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
    </Root>
  );
};

export default SensorRangeSetting;
