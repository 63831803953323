/**
 * 작성자 : 홍선영
 * 날짜 : 2024.07.03
 * 경로 : 설정 관리 - 태블릿 안전교육 관리 - 안전보건 교육 문제 관리 (현장 관리자)
 */

import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { IUser, userState } from '../../../atoms';
import { InputTable } from '../../../assets/styles/InputTable';
import { useSetAuth } from '../../../utils/useSetAuth';
import { BtnBlue, BtnRed } from '../../../components/Button';
import InnerTabs from '../../../components/InnerTabs';
import IssueGuide from '../../../components/IssueGuide';
import InfoTextWithIcon from '../../../components/Modal/InfoTextWithIcon';
import { ButtonsWrapper, Root } from '../setting7/cctvInfoStyle';
import { apiGet, apiPost } from '../../../services/_common';
import ClickableContainer from '../../s_cctv/S_cctv1/RealtimeCCTV/ClickableContainer';
import TableLamp from './TableLamp';
import { IAuth, IModal } from 'customTypes';
import RadioContainer from '../../../components/button/RadioContainer';
import Input from '../../../components/Input';
import styled from 'styled-components';
import DetailsLog from './DetailsLog';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import { PulseLoader } from 'react-spinners';
import { ExpandMenuStyle, MainMenuStyle, SubMenuListStyle } from '../../../components/cctv/ExpandableMenu';
import ExpandButton from '../../../components/button/ExpandButton';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { LANGUAGE_TYPES } from '../../../_constants';
import Portal from '../../../components/Portal';
import DeleteModal from '../../../components/Modal/DeleteModal2';
import { scrollToNodeBottom } from '../../../utils/scrollToNodeBottom';
import { toast } from 'react-toastify';
import { logPost } from '../../../services/log';

const INNER_TABS_MAIN = [{ key: 0, name: '문제 문항' }];
const INNER_TABS_SUB = [{ key: 1, name: '문제 항목' }];

const SafeEduEditorStyle = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: fit-content;
  height: 100%;
  overflow: hidden;
  justify-content: flex-start;

  .header {
    padding: 0.75rem;
    height: fit-content;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    display: flex;
    gap: 0.5rem;

    .wrap {
      flex-wrap: wrap;
    }

    &:nth-child(n + 2) {
      border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    }

    .title {
      flex-shrink: 0;
      width: fit-content;
      min-width: 4rem;
      font-weight: 600;
      margin-right: 0.5rem;
    }
    .flexGrow {
      flex-grow: 1;
    }

    .radioLabel {
      padding: 0.2rem 0;
      cursor: pointer;

      > label {
        user-select: none;
        padding-right: 0.5rem;
        cursor: pointer;
      }
      :hover {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
        border-radius: 5px;
      }
    }
  }

  .detailInfo-group {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem 4rem;
  }
  .editor_container {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    overflow: auto;
    > div > div {
      display: flex;
      flex-direction: row;
      font-size: 0.875rem;
      gap: 1rem;

      label {
        min-width: 6rem;
        display: flex;
        justify-content: flex-start;
      }
    }
  }
  .question_container {
    overflow: scroll;
    flex-grow: 1;
    min-height: 26vh;

    > div {
      background-color: transparent;
      > div {
        border-top: none;
        padding: 0;
        gap: 0;
        :hover {
          background-color: transparent;
        }
      }
    }

    .question_num {
      padding: 0.5rem;
      font-weight: 500;
      font-size: 0.875rem;
      text-align: center;
      width: 2.5rem;
      border-radius: 0.25rem;
      display: inline-block;
      min-width: 1.5rem;
      text-align: center;
    }

    .mainMenuContainer {
      justify-content: flex-start;
      gap: 0.5rem;
      padding-left: 0.375rem;
      border-top: none;

      .button {
        flex-shrink: 0;
        user-select: none;
        cursor: pointer;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.25rem;
        padding: 0 0.5rem;
        margin: auto 0;
        .material-symbols-rounded {
          font-variation-settings: 'FILL' 1, 'wght' 400;
          font-size: 1.5rem;
        }
      }
      .default-button {
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        color: ${({ theme }: { theme: any }) => theme.filled_slate_deep};
        &:hover {
          outline: 0.25rem solid ${({ theme }: { theme: any }) => theme.filled_slate};
          &:active {
            transform: scale(0.95);
          }
        }
      }
      .delete-button {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
        color: ${({ theme }: { theme: any }) => theme.filled_red};
        &:hover {
          outline: 0.25rem solid ${({ theme }: { theme: any }) => theme.filled_red};
          &:active {
            transform: scale(0.95);
          }
        }
      }
      .languageType {
        min-width: 5.2rem;
        padding: 0.5rem 0;
      }
    }

    .namespace {
      font-size: 0.85rem;
      margin-left: 0;
    }
  }
  .badge {
    font-size: 0.8rem;
    font-weight: 600;
    letter-spacing: normal;
    border-radius: 0.25rem;
    height: inherit;
    padding: 0.25rem;
    margin: 0 0.125rem;
    user-select: none;
  }

  .blue {
    color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
  }

  .green {
    color: ${({ theme }: { theme: any }) => theme.filled_blue_green};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
  }

  .black {
    color: ${({ theme }: { theme: any }) => theme.filled_slate_deep};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
  }

  .violet {
    color: ${({ theme }: { theme: any }) => theme.filled_violet_deep};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_violet};
  }

  .text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .max-28 {
    max-width: 28rem;
  }

  @media screen and (max-width: 767px) {
    .detailInfo-group {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const INIT_LANGUAGE_YN = [
  { key: 'K', value: true, name: '한국어' },
  { key: 'E', value: false, name: '영어' },
  { key: 'C', value: false, name: '중국어(간체)' },
  { key: 'V', value: false, name: '베트남어' },
  { key: 'T', value: false, name: '태국어' },
  { key: 'M', value: false, name: '미얀마어' },
  { key: 'G', value: false, name: '캄보디아어' },
];

const INIT_EXAM_T: IEduexamT = {
  hCd: null,
  sCd: null,
  mSeq: null,
  tSeq: null,
  dSeq: null,
  kContents: '',
  eContents: '',
  cContents: '',
  vContents: '',
  tContents: '',
  mContents: '',
  gContents: '',
  cdSort: null,
  delYn: 'N',
  writer: null,
  editor: null,
  expand: true,
  languageYn: INIT_LANGUAGE_YN,
};

const INIT_EXAM_M: IEduexamM = {
  hCd: '',
  sCd: '',
  mSeq: null,
  mTitle: '',
  tTitle: '',
  bigo: '',
  useYn: true,
  tAnsnum: null,
  delYn: 'N',
  wDate: null,
  eDate: null,
  writer: null,
  editor: null,
  postEduexamTDto: [
    {
      hCd: '',
      sCd: '',
      mSeq: null,
      tSeq: null,
      dSeq: null,
      kContents: '',
      eContents: '',
      cContents: '',
      vContents: '',
      tContents: '',
      mContents: '',
      gContents: '',
      cdSort: 0,
      delYn: 'N',
      writer: null,
      editor: null,
    },
  ],
};

const sortArraybyCd = (array: IEduexamT[]) => {
  const result = array.sort((a: IEduexamT, b: IEduexamT) => {
    const sortA = a.cdSort === null ? Number.MAX_SAFE_INTEGER : a.cdSort;
    const sortB = b.cdSort === null ? Number.MAX_SAFE_INTEGER : b.cdSort;
    return sortA - sortB;
  });

  return result;
};

const updateAnswer = (array: IEduexamT[], tAnsnum: string | null) => {
  const sorted = sortArraybyCd(array);

  const languageSetKeys = Object.keys(LANGUAGE_TYPES);
  const result = sorted.map((item: IEduexamT, i: number) => {
    const languageYn = languageSetKeys.map((lan: string) => {
      const languageLower = lan.toLocaleLowerCase();
      const key = lan as keyof typeof LANGUAGE_TYPES;
      return { key: lan, value: !!item[`${languageLower}Contents`], name: LANGUAGE_TYPES[key] };
    });
    const isAnswer = i + 1 === Number(tAnsnum);
    return { ...item, expand: false, tAnsYn: isAnswer, languageYn };
  });

  return result;
};

const SafeEduExamSetting = () => {
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const size = useOutletContext<any>();
  const userInfo = useRecoilValue(userState);
  const [viewTable, setViewTable] = useState<boolean>(true);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null); // 문제문항 선택인덱스
  const [isNewAdd, setIsNewAdd] = useState(true); // 신규등록 여부
  const [activeTab, setActiveTab] = useState(0);
  const [selectedQuestionM, setSelectedQuestionM] = useState<IEduexamM>(INIT_EXAM_M);
  const [selectedQuestionT, setSelectedQuestionT] = useState<IEduexamT | null>(null);
  const [requiredLanguage, setRequiredLanguage] = useState(INIT_LANGUAGE_YN);

  const {
    data: mList,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useQuery(['safeEduExamMGet', userInfo.hCd, userInfo.sCd], () => fetchDataM(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
  });

  const [questionMList, setQuestionMList] = useState<IEduexamM[]>(mList || []);

  const { data: tList, refetch: refetchTList } = useQuery(['safeEduExamTGet', userInfo.hCd, userInfo.sCd, selectedQuestionM?.mSeq], () => fetchDataT(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && !!selectedQuestionM && !!selectedQuestionM.mSeq,
  });

  const init = { ...INIT_EXAM_T, hCd: userInfo.hCd, sCd: userInfo.sCd, writer: userInfo.userId, editor: userInfo.userId, cdSort: 0 };
  const [questionTList, setQuestionTList] = useState<IEduexamT[]>(tList || [init]);

  const { data: dList } = useQuery(['safeEduExamDGet', userInfo.hCd, userInfo.sCd, selectedQuestionM?.mSeq, selectedQuestionT], () => fetchDataD(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && !!selectedQuestionM && !!selectedQuestionM.mSeq && !!selectedQuestionT && !!selectedQuestionT.tSeq,
  });

  // 문제 목록
  const fetchDataM = async () => {
    try {
      const res = await apiGet({ path: '/eduexam/m', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } });
      const result = res.data.data.map((el: any) => (el.useYn === 'Y' ? { ...el, useYn: true } : { ...el, useYn: false }));
      setQuestionMList(result);
      return result;
    } catch (error) {
      console.error('error', error);
      throw new Error('error');
    }
  };

  // 문제 문항(문제)
  const fetchDataT = async () => {
    try {
      const res = await apiGet({ path: '/eduexam/t', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, mSeq: selectedQuestionM?.mSeq } });
      const result = res.data.data.filter((el: IEduexamM) => el.delYn !== 'Y');
      const newArray = updateAnswer(result, '');
      setQuestionTList(newArray);
      return result;
    } catch (error) {
      console.error('error', error);
      throw new Error('error');
    }
  };

  // 문제 항목(객관식보기)
  const fetchDataD = async () => {
    try {
      const res = await apiGet({ path: '/eduexam/d', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, mSeq: selectedQuestionM?.mSeq, tSeq: selectedQuestionT?.tSeq } });
      const result = res.data.data.filter((el: IEduexamT) => el.delYn !== 'Y');
      if (result.length > 0) return result;
      return [init];
    } catch (error) {
      console.error('error', error);
      throw new Error('error');
    }
  };

  const addBtnAction = () => {
    setIsNewAdd(true);
    setSelectedIndex(null);
    setSelectedQuestionM(INIT_EXAM_M);
    setQuestionTList([init]);
    if (size.innerSize.W < 1024) setViewTable(false);
  };

  const backToMain = () => {
    setViewTable(true);
    if (activeTab !== 0) {
      setActiveTab(0);
    } else {
      setSelectedIndex(null);
      setSelectedQuestionT(null);
      setSelectedQuestionM(INIT_EXAM_M);
      setQuestionTList([init]);
      setIsNewAdd(true);
    }
  };

  const updateUseYn = async (array: IEduexamM[], useYnString: 'Y' | 'N', msg: boolean) => {
    const updateNewY = array.find((el) => el.useYn);
    if (updateNewY) {
      const { wDate, eDate, ...rest } = updateNewY;
      const questionT = { ...rest, hCd: userInfo.hCd, sCd: userInfo.sCd, useYn: useYnString };
      const req = { ...questionT, postEduexamTDto: [] };
      await apiPost({ path: '/eduexam', contentType: 'application/json', req });
      if (msg) toast.success(t('사용유무가 업데이트 되었습니다'));
    }
  };

  const handleUseYnClick = async (e: React.MouseEvent<HTMLDivElement>, check: boolean, index: number) => {
    e.preventDefault();
    e.stopPropagation();
    const updatedCheckValue = !check;

    if (updatedCheckValue) {
      const newArray = questionMList.map((el, i) => (i === index ? { ...el, useYn: updatedCheckValue } : { ...el, useYn: !updatedCheckValue }));
      setQuestionMList(newArray);

      updateUseYn(questionMList, 'N', false); // 기존 사용중인 문제관리를 N으로 업데이트
      updateUseYn(newArray, 'Y', true); // 새로 사용등록한 문제관리 Y 업데이트
    } else {
      const newArray = questionMList.map((el, i) => (i === index ? { ...el, useYn: updatedCheckValue } : el));
      setQuestionMList(newArray);

      updateUseYn(questionMList, 'N', true); // 미사용클릭시 N 업데이트
    }
  };

  const onClickEduExamM = (index: number | null, mObj: IEduexamM | null) => {
    if (index !== null) {
      setSelectedIndex(index);
      setIsNewAdd(false);
    }
    if (mObj) setSelectedQuestionM(mObj);
  };

  if (isError) return <IssueGuide />;

  if (isLoading || isFetching)
    return (
      <LoadingModalBackground>
        <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
      </LoadingModalBackground>
    );

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={`content-container ${size.innerSize.W >= 1024 ? 'twoColumn column-55 max800' : 'oneColumn'}`}>
        <Root className={size.innerSize.W >= 1024 || viewTable ? 'showRoot' : 'hideRoot'}>
          {selectedIndex !== null ? (
            <>
              <InnerTabs visibleBackBtn tabList={INNER_TABS_MAIN} activeTab={activeTab} backToMain={backToMain} backBtnPlacement='right' />
              {activeTab === 0 && (
                <EduExamEditor
                  key='main'
                  type='main'
                  userInfo={userInfo}
                  questionRows={questionTList}
                  setQuestionRows={setQuestionTList}
                  auth={auth}
                  isNewAdd={isNewAdd}
                  setIsNewAdd={setIsNewAdd}
                  selectedQuestionM={selectedQuestionM}
                  setSelectedQuestionM={setSelectedQuestionM}
                  selectedQuestionT={selectedQuestionT}
                  setSelectedQuestionT={setSelectedQuestionT}
                  questionMList={questionMList}
                  backToMain={backToMain}
                  refetchMList={refetch}
                  requiredLanguage={requiredLanguage}
                  setRequiredLanguage={setRequiredLanguage}
                  setViewTable={setViewTable}
                  setActiveTab={setActiveTab}
                />
              )}
            </>
          ) : (
            <>
              <InputTable className='margin-left-05 noHorizontalScroll'>
                <div className='thead'>
                  <div className='tr'>
                    <div className='trCol2p5 flex-center shrink0'>No</div>
                    <div className='trColGrow flex-center shrink1'>{t('문제 관리명')}</div>
                    <div className='trCol6 flex-center shrink1'>{t('사용유무')}</div>
                  </div>
                </div>
                {questionMList.length > 0 ? (
                  <div className='table'>
                    <div className='tbody'>
                      {questionMList?.map((el, i) => (
                        <div key={i} className={`tr ${selectedIndex !== null && i === selectedIndex ? 'selected' : ''}`} role='presentation' onClick={() => onClickEduExamM(i, el)}>
                          <div className='trCol2p5 flex-center shrink0'>{i + 1}</div>
                          <div className='trColGrow flex-basic shrink1 text-ellipsis max-28'>{el.mTitle}</div>
                          <div className='trCol6 flex-center'>
                            <ClickableContainer
                              onClick={(e) => handleUseYnClick(e, el.useYn, i)} //
                              button={<TableLamp use={el.useYn} />}
                              disabled={!auth.updateAuth}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <IssueGuide text='문제 관리 목록이 없습니다' />
                )}
              </InputTable>
              <ButtonsWrapper>{(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={() => addBtnAction()}>{t('새 문제 관리 작성')}</BtnBlue>}</ButtonsWrapper>
            </>
          )}
        </Root>
        {(size.innerSize.W >= 1024 || !viewTable) && (
          <Root>
            <InnerTabs visibleBackBtn={false} tabList={selectedIndex === null ? INNER_TABS_MAIN : INNER_TABS_SUB} activeTab={selectedIndex === null ? 0 : 1} backToMain={backToMain} />
            {selectedIndex !== null ? (
              selectedQuestionT !== null ? (
                <EduExamEditor
                  key='sub'
                  type='sub'
                  userInfo={userInfo}
                  questionRows={dList || [init]}
                  auth={auth}
                  isNewAdd={isNewAdd}
                  setIsNewAdd={setIsNewAdd}
                  selectedQuestionM={selectedQuestionM}
                  setSelectedQuestionM={setSelectedQuestionM}
                  selectedQuestionT={selectedQuestionT}
                  setSelectedQuestionT={setSelectedQuestionT}
                  refetchTList={refetchTList}
                  setViewTable={setViewTable}
                  setActiveTab={setActiveTab}
                />
              ) : (
                <IssueGuide text='문제 문항을 선택하세요' />
              )
            ) : (
              <EduExamEditor
                key='main'
                type='main'
                userInfo={userInfo}
                questionRows={questionTList}
                setQuestionRows={setQuestionTList}
                auth={auth}
                isNewAdd={isNewAdd}
                setIsNewAdd={setIsNewAdd}
                selectedQuestionM={selectedQuestionM}
                setSelectedQuestionM={setSelectedQuestionM}
                selectedQuestionT={selectedQuestionT}
                setSelectedQuestionT={setSelectedQuestionT}
                questionMList={questionMList}
                backToMain={backToMain}
                refetchMList={refetch}
                requiredLanguage={requiredLanguage}
                setRequiredLanguage={setRequiredLanguage}
                refetchTList={refetchTList}
                setViewTable={setViewTable}
                setActiveTab={setActiveTab}
              />
            )}
          </Root>
        )}
      </div>
    </DndProvider>
  );
};

interface IEduExamEditor {
  questionRows: any;
  setQuestionRows?: Dispatch<SetStateAction<any>>;
  auth: IAuth;
  isNewAdd: boolean;
  setIsNewAdd: Dispatch<SetStateAction<boolean>>;
  type: 'main' | 'sub';
  selectedQuestionM: IEduexamM;
  setSelectedQuestionM: Dispatch<SetStateAction<IEduexamM>>;
  userInfo: IUser;
  selectedQuestionT?: IEduexamT | null;
  setSelectedQuestionT?: Dispatch<SetStateAction<IEduexamT | null>>;
  questionMList?: IEduexamM[];
  backToMain?: () => void;
  refetchMList?: () => void;
  refetchTList?: () => void;
  requiredLanguage?: IlanYn[];
  setRequiredLanguage?: Dispatch<SetStateAction<IlanYn[]>>;
  setActiveTab: Dispatch<SetStateAction<number>>;
  setViewTable: Dispatch<SetStateAction<boolean>>;
}

const EduExamEditor = ({
  questionRows,
  setQuestionRows,
  auth,
  isNewAdd,
  setIsNewAdd,
  type,
  selectedQuestionM,
  setSelectedQuestionM,
  userInfo,
  selectedQuestionT,
  setSelectedQuestionT,
  questionMList,
  backToMain,
  refetchMList,
  refetchTList,
  requiredLanguage,
  setRequiredLanguage,
  setActiveTab,
  setViewTable,
}: IEduExamEditor) => {
  const { t } = useTranslation();
  const [useYn, setUseYn] = useState(false);
  const [selectedQuestionD, setSelectedQuestionD] = useState<IEduexamT[]>(questionRows || INIT_EXAM_T);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const size = useOutletContext<any>();

  useEffect(() => {
    selectedQuestionM && updateQuestionD(questionRows);
    if (questionRows) {
      if (questionRows[0]?.languageYn) setRequiredLanguage && setRequiredLanguage(questionRows[0]?.languageYn);
    }
  }, [questionRows]);

  // 문제문항을 선택을 변경한 경우
  useEffect(() => {
    if (selectedQuestionM) {
      setUseYn(selectedQuestionM.useYn);
      if (selectedQuestionT) {
        setIsNewAdd(false);
        setSelectedQuestionM((prev: IEduexamM) => ({ ...prev, tTitle: selectedQuestionT.kContents, languageYn: selectedQuestionT.languageYn }));
      }
    }
  }, [selectedQuestionT && selectedQuestionT.tSeq]);

  const updateQuestionD = (array: IEduexamT[]) => {
    const newArray = updateAnswer(array, selectedQuestionT?.tAnsnum || null);
    setSelectedQuestionD(newArray);
  };

  useEffect(() => {
    if (isNewAdd) initiateState();
  }, [isNewAdd]);

  const initiateState = () => {
    setUseYn(false);
    // setSelectedQuestionM(INIT_EDUEXAM_T);
  };

  const onClickAddNewQuestion = () => {
    if (type === 'main' && selectedQuestionD.length >= 10) {
      toast.warning(t('최대 10문항까지 작성 가능합니다.'));
      return;
    }
    if (type === 'sub' && selectedQuestionD.length >= 4) {
      toast.warning(t('최대 4항목까지 작성 가능합니다.'));
      return;
    }

    const updateLanguage = { ...INIT_EXAM_T, languageYn: requiredLanguage };
    const newObj = { ...updateLanguage, cdSort: selectedQuestionD.length, writer: userInfo.userId, editor: userInfo.userId, mSeq: selectedQuestionM.mSeq };
    // const newObj = { ...INIT_EXAM_T, cdSort: selectedQuestionD.length, writer: userInfo.userId, editor: userInfo.userId, mSeq: selectedQuestionM.mSeq, tSeq: tSeq?.tSeq || null };
    setSelectedQuestionD((prev) => [...prev, newObj]);
    scrollToNodeBottom(scrollContainerRef);
  };

  const onClickQuestionRow = (tRow: IEduexamT) => {
    if (type === 'main' && tRow && tRow.mSeq && tRow.tSeq) {
      const newTRow = { ...tRow, languageYn: requiredLanguage };
      setSelectedQuestionT && setSelectedQuestionT(newTRow);

      if (size.innerSize.W < 1024) {
        setActiveTab(1);
        setViewTable(false);
      }
    }
  };

  const onClickSaveExam = async () => {
    if (!selectedQuestionM) return;
    if (selectedQuestionM.mTitle?.trim() === '') {
      toast.warning(t('제목을 입력하세요'));
      return;
    }
    if (selectedQuestionD.find((el) => el.kContents?.trim() === '')) {
      toast.warning(t('한국어 항목을 입력하세요'));
      return;
    }

    const newQuestionT = {
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      mSeq: selectedQuestionM?.mSeq,
      mTitle: selectedQuestionM.mTitle,
      bigo: selectedQuestionM.bigo,
      useYn: useYn ? 'Y' : 'N',
      delYn: selectedQuestionM.delYn,
      writer: selectedQuestionM?.mSeq ? selectedQuestionM.writer : userInfo.userId,
      editor: userInfo.userId,
    };

    if (type === 'main') {
      const languageInputFilled = validateContents(requiredLanguage, selectedQuestionD);
      if (!languageInputFilled) {
        toast.warning(t('필수 언어 항목을 모두 입력하세요'));
        return;
      }

      // 수정중인 기존 문제관리 또는 신규 작성중인 문제관리의 사용유무가 'Y'인 경우
      // 다른 문제관리의 사용유무를 'N'으로 변경
      // 목록중 1개만 사용중이어야 함
      if (useYn) {
        const hasY = questionMList?.find((el) => el.useYn);
        if (hasY) {
          const { wDate, eDate, ...rest } = hasY;
          const questionT = { ...rest, hCd: userInfo.hCd, sCd: userInfo.sCd, useYn: 'N' };
          const req = { ...questionT, postEduexamTDto: [] };
          await apiPost({ path: '/eduexam', contentType: 'application/json', req });
        }
      }

      // 필수입력언어 체크 해제한 컨텐츠값 ''로 업데이트
      const newRequiredLan = requiredLanguage?.filter((el: IlanYn) => !el.value);
      const removeUnrequiredLang = selectedQuestionD.map((el: IEduexamT) => {
        newRequiredLan?.forEach((lan: IlanYn) => {
          const contentKey = `${lan.key.toLowerCase()}Contents`;
          el[contentKey] = ''; // 선택되지 않은 언어 ''로 초기화
        });
        return el;
      });

      const newQuestionD = removeUnrequiredLang.map(({ expand, wDate, eDate, tAnsYn, tAnsnum, languageYn, ...rest }) => ({
        ...rest,
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        mSeq: selectedQuestionM?.mSeq,
      }));

      const sorted = sortArraybyCd(newQuestionD); // cdSort 오름차순으로 정렬
      const reSortedQuestionD = sorted.map((el, i) => ({ ...el, cdSort: i })); // cdSort 빈값을 채우기
      const req = { ...newQuestionT, postEduexamTDto: reSortedQuestionD };
      const res = await apiPost({ path: '/eduexam', req });
      const { statusCode, message } = res.data;
      if (statusCode === 200) {
        const { pmsEduexamMs, pmsEduexamTs } = res.data.data;
        const result = pmsEduexamTs.filter((item: IEduexamT) => item.delYn !== 'Y');

        setQuestionRows && setQuestionRows(result);
        refetchMList && refetchMList();
        const { eDate, editor, writer, wDate, mSeq } = pmsEduexamMs[0];
        const { delYn, hCd, sCd, mTitle, bigo } = selectedQuestionM;

        const baseObject = {
          hCd,
          sCd,
          bigo,
          delYn,
          useYn,
          writer,
          editor,
          wDate,
          eDate,
          mTitle,
          tAnsnum: null,
          languageYn: requiredLanguage,
        };
        if (!selectedQuestionM.mSeq) {
          setSelectedQuestionM({ ...baseObject, mSeq });
        } else {
          setSelectedQuestionM({ ...baseObject, mSeq: selectedQuestionM.mSeq });
        }
        setIsNewAdd(false);
        toast.success(t(message));
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '안전보건 교육 문제 관리 (문제 문항)',
          action: '저장/수정',
          etc: ``,
        });
      }
    } else {
      const languageInputFilled = validateContents(selectedQuestionM?.languageYn, selectedQuestionD);
      if (!languageInputFilled) {
        toast.warning(t('필수 언어 항목을 모두 입력하세요'));
        return;
      }

      const sorted = sortArraybyCd(selectedQuestionD); // cdSort 오름차순으로 정렬
      const reSortedQuestionD = sorted.map((el, i) => ({ ...el, cdSort: i })); // cdSort 빈값을 채우기

      const newQuestionD = reSortedQuestionD.map(({ expand, wDate, eDate, tAnsYn, languageYn, ...rest }) => ({
        ...rest,
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        mSeq: selectedQuestionM?.mSeq,
        tSeq: selectedQuestionT?.tSeq,
      }));

      const ansNum = reSortedQuestionD.findIndex((item) => item.tAnsYn);
      if (ansNum !== -1) {
        const ansNumToString = (ansNum + 1).toString();
        const req = { tAnsnum: ansNumToString, postEduexamDDto: newQuestionD };
        const res = await apiPost({ path: '/eduexam/d', req });
        const { statusCode, message } = res.data;
        if (statusCode === 200) {
          refetchTList && refetchTList();
          const newArray = updateAnswer(res.data.data, ansNumToString);
          setSelectedQuestionD(newArray);
          toast.success(t(message));
          logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: '안전보건 교육 문제 관리 (문제 항목)',
            action: '저장/수정',
            etc: ``,
          });
        }
      } else toast.warning(t('정답 항목을 선택하세요'));
    }
  };

  const onClickDeleteExamM = () => {
    setOpenModal((prev) => ({ ...prev, status: true, type: 'delete', api: () => deleteAPI() }));
  };

  const deleteAPI = async () => {
    const newQuestionT = {
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      mSeq: selectedQuestionM.mSeq,
      mTitle: selectedQuestionM.mTitle,
      bigo: selectedQuestionM.bigo,
      useYn: selectedQuestionM?.useYn ? 'Y' : 'N',
      delYn: 'Y',
      writer: selectedQuestionM.writer,
      editor: userInfo.userId,
    };
    const newQuestionD = selectedQuestionD.map(({ expand, wDate, eDate, tAnsYn, tAnsnum, languageYn, ...rest }) => ({ ...rest, hCd: userInfo.hCd, sCd: userInfo.sCd, mSeq: selectedQuestionM?.mSeq }));
    const req = { ...newQuestionT, postEduexamTDto: newQuestionD };
    const res = await apiPost({ path: '/eduexam', contentType: 'application/json', req });

    const { statusCode, message } = res.data;
    if (statusCode === 200) {
      const { pmsEduexamTs } = res.data.data;
      const result = pmsEduexamTs.filter((item: IEduexamT) => item.delYn !== 'Y');
      setIsNewAdd(true);
      updateQuestionD(result);
      refetchMList && refetchMList();
      toast.success(t(message));
      backToMain && backToMain();
      logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '안전보건 교육 문제 관리',
        action: '삭제',
        etc: ``,
      });
    }
  };

  // 필수언어 입력값을 모두 입력했는 지 확인
  const validateContents = (languageYn: IlanYn[] | undefined, entries: any) => {
    if (languageYn) {
      return languageYn.every(({ key, value }) => {
        if (value) {
          // 해당 언어가 필수인 경우
          // `entries` 배열의 모든 항목에 대해 해당 언어의 콘텐츠가 비어있지 않은지 확인
          return entries.every((entry: IEduexamT) => {
            const contentKey = `${key.toLowerCase()}Contents`;
            return entry[contentKey]; // 콘텐츠가 비어 있지 않은지 확인
          });
        }
        // 해당 언어가 필수가 아닌 경우, 자동으로 true를 반환하여 검증을 통과
        return true;
      });
    }
    // `languageYn` 배열이 존재하지 않는 경우, false를 반환
    return false;
  };

  const handleUseYnClick = (e: React.MouseEvent<HTMLDivElement>, check: boolean, i: number) => {
    if (requiredLanguage) {
      const updatedCheckValue = !check;
      const copyArray = [...requiredLanguage];
      const newArray = copyArray.map((el, index) => {
        return index === i && el.key !== 'K' ? { ...el, value: updatedCheckValue } : el;
      });
      setRequiredLanguage && setRequiredLanguage(newArray);
    }
  };

  return (
    <SafeEduEditorStyle>
      <div className='header'>
        <span className='title required'>{t('문제 관리명')}</span>
        <div className='flexGrow'>
          <Input
            type='text'
            id={type === 'main' ? 'mTitle' : 'tTitle'}
            name={type === 'main' ? 'mTitle' : 'tTitle'}
            state={selectedQuestionM}
            setState={setSelectedQuestionM}
            disabled={(!auth.createAuth && !auth.updateAuth) || type === 'sub'}
            maxLength={100}
          />
        </div>
        {type === 'main' && (
          <>
            <RadioContainer name={t('사용')} onClick={() => setUseYn(true)} isSelected={useYn} />
            <RadioContainer name={t('미사용')} onClick={() => setUseYn(false)} isSelected={!useYn} />
          </>
        )}
      </div>
      {type === 'main' && (
        <div className='header'>
          <span className='title required'>{t('필수 입력 언어')}</span>
          <div className='flex-basic wrap'>
            {type === 'main' &&
              requiredLanguage?.map((lan: IlanYn, i: number) => (
                <div className='flex-basic radioLabel' key={lan.key} role='presentation' onClick={(e) => handleUseYnClick(e, lan.value, i)}>
                  <ClickableContainer
                    className='padding-05'
                    disabled={lan.key === 'K'}
                    onClick={(e) => e.preventDefault()} //
                    button={<TableLamp use={lan.value} />}
                  />
                  <label htmlFor={lan.key}>{lan.name}</label>
                </div>
              ))}
          </div>
        </div>
      )}
      <div className='flex-between' style={{ margin: '0.5rem' }}>
        <div>
          <span className='badge black'>총 출제 문제 {type === 'main' ? '문항 10' : '항목 4'}개까지 가능</span>
        </div>
        {type === 'sub' && (
          <div className='flex-basic'>
            <div>
              <span className='badge black'>{t('필수입력 언어 항목')}</span>
            </div>
            <div>
              {selectedQuestionM.languageYn
                ?.filter((item) => item.value)
                ?.map((item2) => (
                  <span className={`badge ${item2.key === 'K' ? 'blue' : 'violet'}`} key={item2.key}>
                    {item2.name}
                  </span>
                ))}
            </div>
          </div>
        )}
      </div>
      <InfoTextWithIcon icon='flash_on' text='드래그 앤 드롭을 지원합니다. 행의 앞부분을 끌어 순서를 위, 아래로 변경 후 저장버튼을 클릭하세요' />
      {/* <div className='flex-basic'>
        <div>순번</div>
        <div>언어</div>
        <div>항목</div>
      </div> */}
      <div className='question_container' ref={scrollContainerRef}>
        {selectedQuestionD.map((el, i) => (
          <DraggableRow
            key={`${type}_${el.cdSort}_${i}`}
            el={el}
            index={i}
            tableState={selectedQuestionD}
            setTableState={setSelectedQuestionD}
            selectedQuestionM={selectedQuestionM}
            setSelectedQuestionM={setSelectedQuestionM}
            selectedQuestionT={selectedQuestionT}
            setSelectedQuestionT={setSelectedQuestionT}
            auth={auth}
            moveRow={dragRow}
            onClickQuestionRow={() => {
              if (type === 'main') return onClickQuestionRow(el);
              return null;
            }}
            type={type}
            requiredLanguage={type === 'main' ? requiredLanguage : selectedQuestionT?.languageYn}
            refetchTList={refetchTList}
          />
        ))}
      </div>
      {type === 'main' && (
        <div className='editor_container'>
          <Input label={t('비고')} type='text' id='bigo' name='bigo' state={selectedQuestionM} setState={setSelectedQuestionM} disabled={!auth.updateAuth} maxLength={200} />
          {selectedQuestionM && (
            <div className='detailInfo-group'>
              <DetailsLog name={t('등록일자')} value={selectedQuestionM.wDate || ''} />
              <DetailsLog name={t('등록자')} value={selectedQuestionM.writer || ''} />
              <DetailsLog name={t('수정일자')} value={selectedQuestionM.eDate || ''} />
              <DetailsLog name={t('수정자')} value={selectedQuestionM.editor || ''} />
            </div>
          )}
        </div>
      )}
      <ButtonsWrapper style={{ justifyContent: 'space-between' }}>
        <div>
          <BtnBlue disabled={type === 'sub' && isNewAdd} onClick={onClickAddNewQuestion}>
            {t('새 문항 추가')}
          </BtnBlue>
        </div>
        <div className='flex-between'>
          {auth.deleteAuth && type === 'main' && (
            <BtnRed onClick={onClickDeleteExamM} disabled={isNewAdd}>
              {t('삭제')}
            </BtnRed>
          )}
          {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={onClickSaveExam}>{t('저장')}</BtnBlue>}
        </div>
      </ButtonsWrapper>
      {openModal.status && (
        <Portal openModal={openModal.status}>
          <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />
        </Portal>
      )}
    </SafeEduEditorStyle>
  );
};

const DraggableRow = ({
  el,
  index,
  tableState,
  setTableState,
  selectedQuestionM,
  setSelectedQuestionM,
  selectedQuestionT,
  setSelectedQuestionT,
  auth,
  moveRow,
  onClickQuestionRow,
  type,
  requiredLanguage,
  refetchTList,
}: any) => {
  const trRef = useRef(null);
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const newCdArray = tableState.map((elParam: any) => elParam.cdSort);
  const cdSortArray = newCdArray.sort((a: number, b: number) => a - b);
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });

  const [, drop] = useDrop({
    accept: 'ROW',
    hover(item: any, monitor) {
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }
      moveRow(tableState, setTableState, dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop: (item, monitor) => {
      const copyArray = [...tableState];
      const newArray = copyArray.map((cpItem, cpIndex) => ({ ...cpItem, cdSort: cdSortArray[cpIndex], editor: userInfo.userId }));
      setTableState(newArray);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'ROW',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(trRef));

  const onChange = (tGubun: string, newValue: string) => {
    const updatedState = tableState.map((item: IEduexamT, i: number) => (i === index ? { ...item, [`${tGubun.toLocaleLowerCase()}Contents`]: newValue } : item));
    setTableState(updatedState);
  };

  const onClickRemoveInputContents = (e: React.MouseEvent<HTMLDivElement>, tGubun: string, dIndex: number) => {
    e.stopPropagation();
    const copyState = [...tableState];
    copyState[dIndex][`${tGubun.toLocaleLowerCase()}Contents`] = '';
    setTableState(copyState);
  };

  const onClickDeleteQuestion = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const target = tableState[index];
    if (target.tSeq) {
      if (type !== 'main' && target.tAnsYn) {
        toast.warning(t('정답은 삭제하실 수 없습니다'));
      } else {
        // 삭제
        setOpenModal((prev) => ({ ...prev, status: true, type: 'delete', api: () => deleteAPI(target) }));
      }
    } else {
      // 제거
      const copyState = [...tableState];
      copyState.splice(index, 1);
      setTableState(copyState);
    }
  };

  const deleteAPI = async (deleteObj: any) => {
    if (!selectedQuestionM) return;

    const newQuestionT = {
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      mSeq: selectedQuestionM.mSeq,
      mTitle: selectedQuestionM.mTitle,
      bigo: selectedQuestionM.bigo,
      useYn: selectedQuestionM?.useYn ? 'Y' : 'N',
      delYn: selectedQuestionM.delYn,
      writer: selectedQuestionM.writer,
      editor: userInfo.userId,
    };

    if (type === 'main') {
      const { tSeq, kContents, eContents, cContents, vContents, tContents, mContents, gContents, cdSort, writer } = tableState[index];
      const postEduexamTDto = [
        {
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          mSeq: selectedQuestionM.mSeq,
          delYn: 'Y',
          tSeq,
          kContents,
          eContents,
          cContents,
          vContents,
          tContents,
          mContents,
          gContents,
          cdSort,
          writer,
          editor: userInfo.userId,
        },
      ];

      const req = { ...newQuestionT, postEduexamTDto };
      const res = await apiPost({ path: '/eduexam', req });
      const { statusCode, message } = res.data;
      if (statusCode === 200) {
        const { pmsEduexamMs } = res.data.data;
        const { eDate, editor } = pmsEduexamMs[0];
        // 삭제하는 로우 제외하여 state 업데이트
        const result = tableState.filter((_: IEduexamT, i: number) => i !== index);
        setTableState(result);
        // 삭제하는 문제문항의 문제항목 목록이 노출되어있을 경우 삭제 후 null처리
        if (selectedQuestionT && selectedQuestionT.mSeq === selectedQuestionM.mSeq && tableState[index].tSeq === selectedQuestionT.tSeq) {
          setSelectedQuestionT(null);
        }
        // 수정자, 수정일자 업데이트
        setSelectedQuestionM((prev: IEduexamM) => ({ ...prev, eDate, editor }));
        refetchTList && refetchTList();
        toast.success(t(message));
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '안전보건 교육 문제 관리 (문제 문항)',
          action: '삭제',
          etc: ``,
        });
      }
    } else {
      const copyState = [...tableState];
      copyState[index] = { ...tableState[index], delYn: 'Y', mSeq: selectedQuestionM.mSeq };
      const newArray = copyState.filter((item: IEduexamT) => item.delYn !== 'Y');

      // 삭제하는 항목를 제외함으로써 업데이트된 정답인덱스 찾기
      const ansNum = newArray.findIndex((item: IEduexamT) => item.tAnsYn);

      if (ansNum !== -1) {
        const ansNumToString = (ansNum + 1).toString();
        const removeTuseYnArray = copyState.map(({ expand, wDate, eDate, tAnsnum, languageYn, tAnsYn, ...rest }: IEduexamT) => rest);
        const req = { tAnsnum: ansNumToString, postEduexamDDto: removeTuseYnArray };
        const res = await apiPost({ path: '/eduexam/d', req });

        const { statusCode, message, data } = res.data;
        if (statusCode === 200) {
          const result = data.filter((item: IEduexamT) => item.delYn !== 'Y');
          const array = updateAnswer(result, ansNumToString); // 정답 업데이트
          refetchTList && refetchTList(); // 문제문항 목록 리패치
          setSelectedQuestionT((prev: IEduexamT) => ({ ...prev, tAnsnum: ansNumToString }));
          setTableState(array);

          toast.success(t(message));
          logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: '안전보건 교육 문제 관리 (문제 항목)',
            action: '삭제',
            etc: ``,
          });
        }
      } else toast.warning(t('정답은 삭제하실 수 없습니다'));
    }
  };

  const expandMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const newArray = tableState.map((item: IEduexamT, i: number) => (i === index ? { ...item, expand: !item.expand } : item));
    setTableState(newArray);
  };

  const handleUseYnClick = (e: React.MouseEvent<HTMLDivElement>, check: boolean, i: number) => {
    e.preventDefault();
    e.stopPropagation();
    const updatedCheckValue = !check;
    const copyArray = [...tableState];
    if (updatedCheckValue) {
      const newArray = copyArray.map((viewEl, viewIndex) => {
        return viewIndex === i ? { ...viewEl, tAnsYn: updatedCheckValue } : { ...viewEl, tAnsYn: !updatedCheckValue };
      });
      setTableState(newArray);
    }
  };

  return (
    <React.Fragment key={`${el.cdSort}_${index}`}>
      <ExpandMenuStyle ref={trRef} onClick={onClickQuestionRow} role='presentation' style={{ opacity: isDragging ? 0 : 1 }}>
        <MainMenuStyle>
          <ExpandButton isExpanded={el.expand} onClickExpandButton={(e) => expandMenu(e)} className='margin-05' />
          {type === 'sub' && (
            <ClickableContainer
              className='padding-05'
              onClick={(e) => handleUseYnClick(e, el.tAnsYn, index)} //
              button={<TableLamp use={el.tAnsYn} />}
            />
          )}
          <span className='question_num black'>{index + 1}</span>
          <QuestionRow
            key={`${el.tSeq}_K`}
            tGubun='K'
            languageType='한국어'
            tContents={el.kContents}
            onChangeQuestion={(e: React.ChangeEvent<HTMLInputElement>) => onChange('K', e.target.value)}
            onClickRemoveInputContents={(e: React.MouseEvent<HTMLDivElement>) => onClickRemoveInputContents(e, 'K', index)}
            onClickDeleteQuestion={(e: React.MouseEvent<HTMLDivElement>) => onClickDeleteQuestion(e)}
            auth={auth}
            tIndex={index}
            qIndex={index}
            type={type}
          />
        </MainMenuStyle>
      </ExpandMenuStyle>
      {el.expand && requiredLanguage && (
        <>
          {requiredLanguage
            .filter((lan: IlanYn) => lan.value)
            .map(({ key, name }: IlanYn) => {
              if (key === 'K') return null;

              // 언어 로우가 표시되어야 하는지 여부를 결정
              const isLanguageEnabled = requiredLanguage?.some((item: IlanYn) => item.key === key && item.value);

              // 언어가 활성화된 경우 'sub' 유형에 대한 QuestionRow만 렌더링, main의 경우 항상 렌더링
              if (type === 'sub' && !isLanguageEnabled) return null;

              return (
                <QuestionRow
                  key={`${el.tSeq}_${key}`}
                  tGubun={key}
                  languageType={name}
                  tContents={el[`${key.toLowerCase()}Contents`]}
                  onChangeQuestion={(e: any) => onChange(key, e.target.value)}
                  onClickRemoveInputContents={(e: React.MouseEvent<HTMLDivElement>) => onClickRemoveInputContents(e, key, index)}
                  onClickDeleteQuestion={(e: React.MouseEvent<HTMLDivElement>) => onClickDeleteQuestion(e)}
                  auth={auth}
                  tIndex={index}
                  qIndex={index}
                  type={type}
                />
              );
            })}
        </>
      )}
      {openModal.status && (
        <Portal openModal={openModal.status}>
          <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />
        </Portal>
      )}
    </React.Fragment>
  );
};

interface IQuestionRow {
  tGubun: string;
  languageType: string | undefined;
  tContents: string;
  onChangeQuestion: any;
  onClickDeleteQuestion: (e: React.MouseEvent<HTMLDivElement>, param1: number) => void;
  onClickRemoveInputContents: (e: React.MouseEvent<HTMLDivElement>, param1: number, param2: number) => void;
  auth: IAuth;
  tIndex: number;
  qIndex: number;
  type: 'main' | 'sub';
}

const QuestionRow = ({ tGubun, languageType, tContents, onChangeQuestion, onClickRemoveInputContents, onClickDeleteQuestion, auth, tIndex, qIndex, type }: IQuestionRow) => {
  return (
    <SubMenuListStyle key={tGubun} className='mainMenuContainer' style={{ paddingLeft: `${tGubun !== 'K' ? (type === 'main' ? '5.85rem' : '8.35rem') : ''}` }}>
      <div className={`languageType question_num ${tGubun !== 'K' ? 'violet' : 'blue'}`}>{languageType}</div>
      <input className='namespace' type='text' name='tContents' value={tContents} onChange={(e) => onChangeQuestion(e, tIndex, qIndex)} maxLength={200} />
      {auth.deleteAuth &&
        (tGubun === 'K' ? (
          <div className='button delete-button' role='presentation' onClick={(e) => onClickDeleteQuestion(e, tIndex)}>
            <span className='material-symbols-rounded'>delete</span>
          </div>
        ) : (
          <div className='button default-button' role='presentation' onClick={(e) => onClickRemoveInputContents(e, tIndex, qIndex)}>
            <span className='material-symbols-rounded'>backspace</span>
          </div>
        ))}
    </SubMenuListStyle>
  );
};

const dragRow = (list: any[], setList: Dispatch<SetStateAction<any[]>>, fromIndex: number, toIndex: number) => {
  const updatedData = [...list];
  const [movedItem] = updatedData.splice(fromIndex, 1);
  updatedData.splice(toIndex, 0, movedItem);
  setList(updatedData);
};

export default SafeEduExamSetting;
