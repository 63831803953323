/**
 * 작성자 : 김광민
 * 날짜 : 2024.04.29
 * 기능 : 태블릿 안전교육 관리 화면에서 공통으로 사용되는 목록 컴포넌트
 */

import styled from 'styled-components';
import { InputTable } from '../../../assets/styles/InputTable';
import { BtnBlue, BtnGhost } from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { IAuth } from 'customTypes';
import TableLamp from './TableLamp';
import Portal from '../../../components/Portal';
import ClickableContainer from '../../s_cctv/S_cctv1/RealtimeCCTV/ClickableContainer';
import YesOrNoConfirm from '../../../components/Modal/YesOrNo';
import { toast } from 'react-toastify';
import JobtypeOnTable from './JobtypeOnTable';
import SearchSelectBoxSm from '../../../components/SearchSelectBoxSm';
import { useFetchCommonCodeList } from '../../../services/useSetCodeListInSelectBoxForm';
import { COMCD_STEMPLET_GUBUN, COMCD_USE_YN, INIT_USE_YN_A } from '../../../_constants';
import SelectBox from '../../../components/SelectBox';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import Input from '../../../components/Input';
import { ynFilter } from '../../../utils/ynFilter';
import { PulseLoader } from 'react-spinners';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import { ContentsContainerRoot } from '../../../assets/styles/ContentsContainerRoot';
import { apiPost } from '../../../services/_common';
import { IUser, userState } from '../../../atoms';
import { useRecoilValue } from 'recoil';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  justify-content: flex-end;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`;

const Root = styled(ContentsContainerRoot)`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .table {
    .tbody {
      min-width: 100%;
    }
  }

  .tr {
    max-width: 100%;
  }

  .w5 {
    width: 5%;
    max-width: 5%;
  }

  .w10 {
    width: 10%;
    max-width: 10%;
  }

  .w15 {
    width: 15%;
    max-width: 15%;
  }

  .w20 {
    width: 20%;
    max-width: 20%;
  }

  .w25 {
    width: 25%;
    max-width: 25%;
  }

  .w60 {
    width: 60%;
    max-width: 60%;
  }

  .filter {
    padding: 0.5rem;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    display: flex;
    gap: 0.5rem;
    .minWith14 {
      ul li {
        min-width: 14rem !important;
      }
    }

    .title {
      flex-shrink: 0;
      width: fit-content;
      font-weight: 600;
      margin-right: 0.5rem;
    }
    .search {
      width: 20rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      border-radius: 0.25rem;
      padding: 0 0.5rem;
      input {
        padding: 0;
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
        background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
        :focus {
          outline: none;
        }
      }
      .material-symbols-rounded {
        user-select: none;
        font-size: 1.375rem;
        &.close-icon {
          font-variation-settings: 'FILL' 1, 'wght' 500;
          cursor: pointer;
          font-size: 1.25rem;
          border-radius: 0.25rem;
          padding: 0.125rem;
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          &:hover {
            box-shadow: 0 0 0.25rem 0.125rem ${({ theme }: { theme: any }) => theme.tonal_deep};
          }
          &:active {
            background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
          }
          &.hidden {
            display: none;
          }
        }
      }
    }
    button {
      flex-shrink: 0;
      padding: 0 0.75rem;
      width: 4.5rem;
      height: 2.5rem;
      font-size: 0.875rem;
      border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      &:hover {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
      }
    }
  }

  .text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .jobtype {
    max-width: 32rem;
    .container {
      min-width: 12rem;
    }

    > :first-child {
      min-width: 12rem;
      max-width: 12rem;
    }
  }
`;

interface Props {
  rawData: TabletTemplate[];
  filteredData: TabletTemplate[];
  setFilteredData: Dispatch<SetStateAction<TabletTemplate[]>>;
  auth: IAuth;
  selectedIndex: number | null;
  setSelectedIndex: Dispatch<SetStateAction<number | null>>;
  refetch: () => void;
  warning: boolean;
  tGubun: 'C' | 'J';
  setTemplate: Dispatch<SetStateAction<{ type: string; [COMCD_STEMPLET_GUBUN]: string; cdName: string }>>;
}

type OpenModalType = { status: boolean; type: string; tGubun: string; tNum: string; useYn: 'Y' | 'N' };

const TabletEduList = ({
  rawData, //
  filteredData,
  setFilteredData,
  auth,
  selectedIndex,
  setSelectedIndex,
  refetch,
  warning,
  tGubun,
  setTemplate,
}: Props) => {
  const { t } = useTranslation();
  const INIT_TEMPLATE_A = { type: COMCD_STEMPLET_GUBUN, [COMCD_STEMPLET_GUBUN]: 'A', cdName: t('전체') };
  const scrollContainerRef = useRef<HTMLInputElement>(null);
  const INITIAL_STATE_OPEN_MODAL = { status: false, type: 'confirm', tGubun: '', tNum: '', useYn: 'N' as 'Y' | 'N' };
  const userInfo = useRecoilValue<IUser>(userState);
  const [openModal, setOpenModal] = useState<OpenModalType>(INITIAL_STATE_OPEN_MODAL);
  const [response, setResponse] = useState<any>([]);
  const [tableWidth, setTableWidth] = useState<number>(0);
  const myElementRef = useRef<HTMLDivElement>(null);
  const parentElementRef = useRef<HTMLDivElement>(null);
  const { data: useYnComCdListWithAll } = useFetchCommonCodeList(COMCD_USE_YN, true); // 사용여부 공통코드 목록 (전체포함)
  const { data: STEMPLETComCdList } = useFetchCommonCodeList(COMCD_STEMPLET_GUBUN, true, 'etc1', tGubun); // 스마트안전교육 양식지 공통코드 목록 (전체포함)
  const [useYn, setUseYn] = useState(INIT_USE_YN_A);
  const [searchOptionTemplate, setSearchOptionTemplate] = useState(INIT_TEMPLATE_A);
  const [searchOption, setSearchOption] = useState({ formName: '' });
  const [currentTGubun, setCurrentTGubun] = useState(tGubun);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setCurrentTGubun(tGubun);
  }, [tGubun]);

  useEffect(() => {
    setSearchOptionTemplate(INIT_TEMPLATE_A);
    setUseYn(INIT_USE_YN_A);
    setSearchOption({ formName: '' });
    setSelectedIndex(null);
  }, [currentTGubun]);

  // 양식지 명 너비 적용
  useEffect(() => {
    const parent = parentElementRef.current;
    const child = myElementRef.current;
    const updateTableWidth = () => {
      if (parent && child) {
        const width = parent.clientWidth - child.clientWidth;
        // setTableWidth(width);
      }
    };

    const observer = new ResizeObserver(updateTableWidth);
    if (parent) observer.observe(parent);
    if (child) observer.observe(child);

    setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => {
      if (parent) observer.unobserve(parent);
      if (child) observer.unobserve(child);
    };
  }, [rawData]);

  const updateUseYn = async (tGubunParam: string, tNumParam: string, useYnParam: 'Y' | 'N') => {
    const inUse = useYnParam === 'Y';

    // 같은종류의 양식지만 필터링
    const filterData = rawData.filter((item) => item.tGubun === tGubunParam);

    // 사용유무가 'Y'는 'N'으로 'N'은 'Y"로 변경
    const newArray = filterData.map((el) => {
      const isCurrent = el.tNum === tNumParam;
      const newUseYn: 'Y' | 'N' = inUse ? (isCurrent ? 'N' : el.useYn) : isCurrent ? 'Y' : 'N';
      return {
        hCd: el.hCd,
        sCd: el.sCd,
        tNum: el.tNum,
        tGubun: el.tGubun,
        tName: el.tName,
        tContents: el.tContents,
        jobtypeIdx: el.jobtypeIdx,
        useYn: newUseYn,
        delYn: el.delYn,
        writer: el.writer,
        editor: userInfo.userId,
      };
    });
    const res = await apiPost({ path: '/tablet/templet', req: { postTempletDto: newArray } });
    const { message, statusCode } = res.data;
    if (statusCode === 200) {
      refetch();
      toast.success(t(message));
    }
  };

  const handleUseYnClick = (el: TabletTemplate, index: number) => {
    // 오른쪽 양식지 템플릿에 변경된 내용이 있는 경우
    if (warning) {
      // 양식지 변경 확인 모달 열기
      el.tNum && setOpenModal({ status: true, type: 'confirm', tGubun: el.tGubun, tNum: el.tNum, useYn: el.useYn });
    } else {
      // 사용유무 업데이트
      el.tNum && updateUseYn(el.tGubun, el.tNum, el.useYn);
    }
  };

  // 양식지 사용유무 업데이트 후 알림 띄우기
  useEffect(() => {
    const filterData = rawData?.filter((item) => item.useYn === 'Y');
    // 사용유무가 'Y'인 양식지가 있는 경우
    const hasY = filterData?.length > 0 && response.length > 1;
    // 사용유무가 'Y'인 양식지가 없는 경우
    const hasNotY = filterData?.length === 0 && response.length > 0;
    if (hasY || hasNotY) {
      // Map을 이용해 message와 statusCode를 키로 사용하여 객체를 병합
      const map = new Map<string, { message: string; statusCode: number }>();

      response.forEach((item: any) => {
        const key = `${item.message}-${item.statusCode}`;
        if (!map.has(key)) {
          map.set(key, { message: item.message, statusCode: item.statusCode });
        }
      });

      // Map의 값을 배열로 변환하여 반환
      const result = Array.from(map.values());
      result.forEach((item: any) => {
        if (item.statusCode === 200) {
          toast.success(t(item.message));
        }
      });
      setResponse([]);
    }
  }, [response]);

  const onClickInitiateSearchOption = () => {
    setFilteredData(rawData);
    setSearchOptionTemplate(INIT_TEMPLATE_A);
    setUseYn(INIT_USE_YN_A);
    setSearchOption({ formName: '' });
  };

  const applyFilter = (array: any[]) => {
    const filterOptions = {
      formName: searchOption.formName,
      tGubun: searchOptionTemplate[COMCD_STEMPLET_GUBUN] === 'A' ? '' : searchOptionTemplate[COMCD_STEMPLET_GUBUN],
    };

    // 필터링된 어레이 리턴, 대소문자구분X
    const filteredArray = array?.filter((item: any) => {
      return item.tName?.toLowerCase()?.includes(filterOptions.formName?.toLowerCase()) && item.tGubun?.toLowerCase()?.includes(filterOptions.tGubun?.toLowerCase());
    });
    const result = ynFilter(filteredArray, 'useYn', useYn[COMCD_USE_YN]);
    if (result.length > 0) setFilteredData(result);
    else {
      setFilteredData([]);
      setSelectedIndex(null);
    }
  };

  useEffect(() => {
    if (rawData) applyFilter(rawData);
  }, [rawData, useYn[COMCD_USE_YN], searchOptionTemplate[COMCD_STEMPLET_GUBUN], searchOption.formName]);

  // 엔터입력시 검색
  const inputOnEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      // if (wNameRef.current) onClickSearch();
    }
  };

  const onClickRow = (row: TabletTemplate, index: number) => {
    setSelectedIndex(index);
    setTemplate({
      type: COMCD_STEMPLET_GUBUN,
      [COMCD_STEMPLET_GUBUN]: row.tGubun,
      cdName: STEMPLETComCdList?.find((el: any) => el[COMCD_STEMPLET_GUBUN] === row.tGubun)?.cdName || '',
    });
  };

  if (!rawData || rawData === undefined || rawData.length === undefined) {
    return null;
  }

  // 직종별 양식지인지 아닌지 확인
  const isJobtypeChip = currentTGubun !== undefined && currentTGubun === 'J';

  return (
    <Root loading={loading}>
      <SearchOptions>
        <div className='inputsWrapper filter'>
          <ClickableContainer onClick={onClickInitiateSearchOption} button={<BtnGhost>{t('초기화')}</BtnGhost>} />

          <div className='inputForm-col withLabelComCf'>
            <label htmlFor='wWorkstatus'>{t('사용유무')}</label>
            <SelectBox
              options={useYnComCdListWithAll}
              defaultOption={useYn.cdName}
              state={useYn}
              setState={setUseYn}
              stateKey={COMCD_USE_YN}
              initiateKey={useYn[COMCD_USE_YN]}
              filterbar='filter-1-left'
            />
          </div>
          <div className='inputForm-row withLabel minWith15'>
            <label htmlFor='template'>{t('양식지 구분')}</label>
            <SearchSelectBoxSm
              options={STEMPLETComCdList}
              defaultOption={t('전체')}
              state={searchOptionTemplate}
              setState={setSearchOptionTemplate}
              stateKey={COMCD_STEMPLET_GUBUN}
              codeKey='cdName'
              initiateKey={searchOptionTemplate[COMCD_STEMPLET_GUBUN]}
              filterbar='filter-1-right'
              comboWidth='expand-box-md'
              optionHeight='height-md'
            />
          </div>
          <div className='inputForm-row'>
            <div className='inputForm-col'>
              <Input placeholder={t('양식지명')} label='' type='text' id='formName' name='formName' state={searchOption} setState={setSearchOption} onKeyDown={inputOnEnterKeyDown} />
            </div>
          </div>
        </div>
      </SearchOptions>
      <InputTable className='margin-left-05 noHorizontalScroll'>
        <div className='thead'>
          <div className='tr'>
            <div className='w5 flex-center'>No</div>
            <div className='w25 flex-center'>{t('양식지 구분')}</div>
            <div
              className='w60 flex-center' //
              ref={parentElementRef}
            >
              {t('양식지 명')}
            </div>
            <div className='w10 flex-center'>{t('사용유무')}</div>
          </div>
        </div>
        <div className='table'>
          <div className='tbody'>
            {filteredData?.length > 0 ? (
              filteredData.map((el: TabletTemplate, i: number) => (
                <div
                  className={`tr ${selectedIndex === i ? 'selected' : ''}`} //
                  role='button'
                  tabIndex={0}
                  key={`tabletTemplet_${el.tGubun}_${el.tNum}`}
                  onClick={() => onClickRow(el, i)}
                >
                  <div className='w5 flex-center'>{i + 1}</div>
                  <div className='w25 flex-start'>{el.templetName}</div>
                  <div className='w60 flex-start'>
                    {!isJobtypeChip ? (
                      <div className='flex-basic'>
                        <span className='text-ellipsis'>{el.tName}</span>
                      </div>
                    ) : (
                      <div className='flex-basic jobtype' ref={myElementRef}>
                        <div className='text-ellipsis'>{el.tName}</div>
                        <JobtypeOnTable
                          jobtypeIdx={filteredData[i].jobtypeIdx} //
                          selected={i === selectedIndex}
                          width={tableWidth}
                        />
                      </div>
                    )}
                  </div>
                  <div className='w10 flex-center'>
                    <ClickableContainer
                      onClick={() => handleUseYnClick(el, i)} //
                      button={<TableLamp use={el.useYn === 'Y'} />}
                    />
                  </div>
                </div>
              ))
            ) : (
              <div className='noData'>No data.</div>
            )}
          </div>
        </div>
      </InputTable>
      {auth.createAuth && ( //
        <ButtonsWrapper>
          <BtnBlue
            className={selectedIndex === null ? 'disabled' : ''}
            onClick={() => setSelectedIndex(null)} //
          >
            {t('새 양식지 작성')}
          </BtnBlue>
        </ButtonsWrapper>
      )}
      <Portal openModal={openModal?.status}>
        {openModal.tGubun && openModal.tNum && (
          <YesOrNoConfirm
            mainText={t('사용하는 양식지를 변경하시겠습니까?')}
            subText={t('저장되지 않은 내용은 유실됩니다.')}
            onClickClose={() => setOpenModal({ status: false, type: 'confirm', tGubun: '', tNum: '', useYn: 'N' })} //
            onClickConfirm={() => {
              updateUseYn(openModal.tGubun, openModal.tNum, openModal.useYn);
              setOpenModal({ status: false, type: 'confirm', tGubun: '', tNum: '', useYn: 'N' });
            }}
          />
        )}
      </Portal>
      {loading && (
        <LoadingModalBackground>
          <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
        </LoadingModalBackground>
      )}
    </Root>
  );
};

export default TabletEduList;
