import styled from 'styled-components';

const Root = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  user-select: none;
  &:hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
  }
  &:active {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
  }
  &:hover,
  &:active {
    .material-symbols-rounded {
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
    }
  }
  .material-symbols-rounded {
    font-size: 1.25rem;
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
  }
  &.small {
    height: 1.75rem;
    width: 1.75rem;
    .material-symbols-rounded {
      font-size: 1.125rem;
    }
  }
  &.large {
    border-radius: 0.25rem;
    .material-symbols-rounded {
      font-size: 1.5rem;
    }
  }
  &.tonal {
    /* background-color: ${({ theme }: { theme: any }) => theme.tonal}; */
    .material-symbols-rounded {
      color: ${({ theme }: { theme: any }) => theme.text_disabled};
    }
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
      .material-symbols-rounded {
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      }
    }
  }
  &.blue {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
    .material-symbols-rounded {
      color: ${({ theme }: { theme: any }) => theme.filled_blue};
    }
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep_blue};
    }
  }
  &.red {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
    .material-symbols-rounded {
      color: ${({ theme }: { theme: any }) => theme.filled_red};
    }
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
    }
  }
`;

type Props = {
  icon: string;
  onClick: () => void;
  size?: 'large' | 'small';
  color?: 'blue' | 'red' | 'tonal';
};

const SmallIconButton = ({ icon, onClick, size, color }: Props) => {
  const style = () => {
    return ` ${size ?? ''} ${color ?? ''}`;
  };
  return (
    <Root role='button' tabIndex={0} onClick={onClick} className={style()}>
      <span className='material-symbols-rounded'>{icon}</span>
    </Root>
  );
};

export default SmallIconButton;
