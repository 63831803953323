/**
 * 작성자 : 한영광
 * 날짜 : 2024.01.24
 * 경로 : 설정관리 > 알림톡 관리 > 긴급 상황 알림톡 내용 & 비콘 호출 알림톡 내용
 */

import { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { PulseLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { IoChevronUpSharp } from 'react-icons/io5';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

import i18n from '../../../translation/i18n';
import { userState } from '../../../atoms';
import { COMCD_USE_YN, INIT_USE_YN_Y, INIT_USE_YN_A } from '../../../_constants';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import { BtnBlue, BtnGhost, BtnRed } from '../../../components/Button';
import Input from '../../../components/Input';
import Portal from '../../../components/Portal';
import TuiGrid from '../../../components/Table/TuiGrid';
import Textarea from '../../../components/Textarea';
import SelectBox from '../../../components/SelectBox';
import IssueGuide from '../../../components/IssueGuide';
import BackButton from '../../../components/BackButton';
import DeleteModal from '../../../components/Modal/DeleteModal2';
import { ynFilter } from '../../../utils/ynFilter';
import { useSetAuth } from '../../../utils/useSetAuth';
import { trimObject } from '../../../utils/trimObject';
import { useDebounce } from '../../../utils/useDebounce';
import { scrollToNodeTop } from '../../../utils/scrollToNodeTop';
import { applyBorderStyle } from '../../../utils/applyBorderStyle';
import { useDetectScrolledToBottom } from '../../../utils/useDetectScrolledToBottom';
import { apiDelete, apiGet, apiPost } from '../../../services/_common';
import { useFetchCommonCodeList } from '../../../services/useSetCodeListInSelectBoxForm';
import { TuiGridTwoColumnStyle } from '../../../assets/styles/TuiGridTwoColumnStyle';
import { logPost } from '../../../services/log';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  padding: 0.5rem;
  > div {
    gap: 0.5rem;
  }
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;

const SubRoot = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  overflow: auto;
  justify-content: flex-start;

  .emptyData {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    padding-bottom: 4rem;
    gap: 1rem;
    img {
      width: 24rem;
      /* user-drag: none; */
      -webkit-user-drag: none;
    }
    span {
      color: ${({ theme }: { theme: any }) => theme.filled_violet};
    }
  }

  .inputForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    width: 100%;
    button {
      height: 2.5rem;
      font-size: 0.75rem;
      flex-shrink: 0;
      padding: 0 0.75rem;
      border-radius: 0.25rem;
    }
    button.gray {
      font-weight: 500;
      color: ${({ theme }: { theme: any }) => theme.color.zinc_200};
    }
    label {
      width: 6rem;
      font-weight: 500;
      flex-shrink: 0;
      font-size: 0.875rem;
    }
    .inputForm-group-1280 {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (min-width: 1280px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
      }
    }
    .inputForm-group-1536 {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (min-width: 1536px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
      }
    }
    .inputForm-group-1536.withBtn {
      flex-direction: row;
      gap: 0.5rem;
      button {
        transition: none;
      }
      @media (min-width: 1536px) {
        gap: 2rem;
        button {
          margin-left: 0;
          width: fit-content;
        }
      }
    }
    .inputForm-group-1536.withBtn.inputColumnFour {
      input {
        padding: 0;
      }
    }
    .inputForm-row.labelInInput {
      display: flex;
      gap: 0.5rem;
      flex-grow: 1;
      > div {
        flex-grow: 1;
      }
    }
    .inputForm-row.labelOutInput,
    .inputForm-row.labelInInput > div > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      input {
        flex-shrink: 1;
        height: 2.5rem;
        font-size: 0.875rem;
        padding: 0 0.5rem;
        &:disabled {
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          color: ${({ theme }: { theme: any }) => theme.text_disabled};
        }
      }
      textarea {
        width: 100%;
      }
      > div.viewOnly {
        height: 2.5rem;
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_primary};
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 0.25rem;
        padding: 0 0.75rem;
        display: flex;
        align-items: center;
      }
      > div {
        flex-grow: 1;
        > div > div > ul {
          height: 2.5rem;
          li {
            display: flex;
            width: 100%;
            max-width: 100%;
            span {
              flex-grow: 1;
              width: 4rem;
            }
          }
        }
      }
    }
    .detailInfo-group {
      margin: 1rem 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      @media (min-width: 1536px) {
        grid-template-columns: repeat(3, 1fr);
      }
      .flex-col.detailInfo {
        flex-grow: 1;
        width: 100%;
        div {
          height: 2.5rem;
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          border-radius: 0.25rem;
          display: flex;
          align-items: center;
          padding: 0 0.5rem;
          font-size: 0.875rem;
        }
      }
    }
  }
  input {
    :disabled {
      background-color: #eeeeee;
    }
  }
  .required {
    &::after {
      content: '*';
      padding: 0 0.2rem;
      color: red;
    }
  }

  @media screen and (max-width: 1023px) {
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: fit-content;
    overflow: none;
  }
`;

interface IMsg {
  editor: string;
  writer: string;
  wDate: string;
  eDate: string;
  tsCd: string;
  tsTitle: string;
  tsContents: string;
  useYn: string;
  bigo: string;
  rowKey?: number;
  sortKey?: number;
}

interface IData {
  tsGubun: string;
}

interface Props {
  prop: IData;
}

const Allimtalk = ({ prop }: Props) => {
  const userInfo = useRecoilValue(userState);
  const emptyRowState = {
    hCd: userInfo.hCd,
    sCd: userInfo.sCd,
    wDate: '',
    eDate: '',
    tsCd: '',
    tsTitle: '',
    tsContents: '',
    useYn: 'Y',
    bigo: '',
  };
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const userInfoInputFormRef = useRef<any>(null); // 인풋 폼 ref (테이블 로우를 클릭했을 때 바로 inputForm 으로 스크롤 이동시키기 위한 ref)
  const { isBottom } = useDetectScrolledToBottom(userInfoInputFormRef); // 스크롤이 해당노드의 하단에 근접했는지 여부
  const node = useRef<any>(null);
  const [isNewAdd, setIsNewAdd] = useState(true); // 신규등록 여부
  const [viewTable, setViewTable] = useState<boolean>(true);
  const size = useOutletContext<any>();
  const [rowState, setRowState] = useState<any>(emptyRowState);
  const [searchOption, setSearchOption] = useState({ tsTitle: '', tsContents: '', bigo: '' });
  const [searchOptionUseYn, setSearchOptionUseYn] = useState(INIT_USE_YN_A);
  const [useYn, setUseYn] = useState(INIT_USE_YN_Y);
  const [selectedRowKey, setSelectedRowKey] = useState<any>(null);
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const componentRef = useRef<HTMLDivElement>(null);
  const [columns, setColumns] = useState<any[]>([]);
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '', title: '' });
  const { data: useYnComCdListWithAll } = useFetchCommonCodeList(COMCD_USE_YN, true); // 사용여부 공통코드 목록 (전체포함)
  const { data: useYnComCdList } = useFetchCommonCodeList(COMCD_USE_YN, false); // 사용유무 공통코드 목록
  const tsTitleRef = useRef<HTMLInputElement>(null);
  const tsContentsRef = useRef<HTMLTextAreaElement>(null);
  const {
    data: msgList,
    isLoading,
    isFetching,
    isError,
  } = useQuery(['msgTs', userInfo.hCd, userInfo.sCd, prop.tsGubun], () => fetchData(prop.tsGubun), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && userInfo.sCd !== '00000' && !!prop.tsGubun,
  });
  const [tableState, setTableState] = useState<IMsg[]>(msgList || []);
  const [initTableState, setInitTableState] = useState<IMsg[]>(msgList || []);

  const debouncedtsTitle = useDebounce(searchOption.tsTitle); // 마지막 키보드입력으로부터 0.5초 뒤 업데이트
  const debouncedtsContents = useDebounce(searchOption.tsContents);
  const debouncedtsbigo = useDebounce(searchOption.bigo);

  const fetchData = async (tsGubunParam: string) => {
    try {
      const res = await apiGet({ path: '/msg/ts', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, tsGubun: tsGubunParam } });
      const result = res.data.data.tsList?.map((el: IMsg) => ({ ...el, useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용') }));
      setTableState(result);
      setInitTableState(result);
      return result;
    } catch (error) {
      console.error('error', error);
      throw new Error('error');
    }
  };

  useEffect(() => {
    onClickInitiateSearchOption(); // 긴급상황-비콘호출 탭 이동 시 검색필터 초기화
    onClickNewAdd();
    if (size.innerSize.W < 1024 && !viewTable) setViewTable(true);

    if (prop.tsGubun) {
      logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: prop.tsGubun === '01' ? '알림톡 관리 > 긴급 상황 알림톡 내용' : '알림톡 관리 > 비콘 호출 알림톡 내용',
        action: '조회',
        etc: ``,
      });
    }
  }, [prop.tsGubun]);

  useEffect(() => {
    setSearchOptionUseYn(INIT_USE_YN_A);

    setColumns([
      { header: t('제목'), name: 'tsTitle', align: 'center', sortable: true, width: 220, renderer: { classNames: ['text_secondary', 'font_semibold'] } },
      { header: t('내용'), name: 'tsContents', sortable: true, minWidth: 200, renderer: { classNames: ['text_secondary', 'font_semibold'] } },
      { header: t('비고'), name: 'bigo', sortable: true, width: 160 },
      { header: t('사용유무'), name: 'useYnCdName', align: 'center', sortable: true, width: 100 },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    if (componentRef.current !== null) setTuiHeight(componentRef.current.offsetHeight);
  }, [componentRef.current?.offsetHeight, componentRef.current?.offsetWidth]);

  const applyFilter = (array: IMsg[]) => {
    // 필터링된 어레이 리턴, 대소문자구분X
    const filteredTsTitle = array.filter((item: IMsg) => item.tsTitle?.toLowerCase()?.includes(debouncedtsTitle?.toLowerCase()));
    const filteredTsContents = filteredTsTitle.filter((item: IMsg) => item.tsContents?.toLowerCase()?.includes(debouncedtsContents?.toLowerCase()));
    const filteredBigo = filteredTsContents.filter((item: IMsg) => item.bigo?.toLowerCase()?.includes(debouncedtsbigo?.toLowerCase()));
    const result = ynFilter(filteredBigo, 'useYn', searchOptionUseYn[COMCD_USE_YN]);

    if (result.length > 0) setTableState(result.map((el: IMsg, i) => ({ ...el, rowKey: i, sortKey: i })));
    else setTableState([]);
  };

  useEffect(() => {
    applyFilter(initTableState);
  }, [debouncedtsTitle, debouncedtsContents, debouncedtsbigo, searchOptionUseYn]);

  useEffect(() => {
    setUseYn({ type: COMCD_USE_YN, [COMCD_USE_YN]: rowState.useYn, cdName: rowState.useYn === 'Y' ? t('사용') : t('미사용') });
  }, [rowState.tsCd]);

  // Row 클릭
  const onClickRow = (rowData: any, columnName: any, filterRowKey: any) => {
    const find = tableState.find((el: IMsg) => el.rowKey === Number(filterRowKey));
    if (find) {
      // const { rowKey, rowSpanMap, sortKey, uniqueKey, _attributes, _disabledPriority, _relationListItemMap, ...newRowData } = rowData;
      setRowState(find);
      setSelectedRowKey(find.rowKey);
      setUseYn({ type: COMCD_USE_YN, [COMCD_USE_YN]: rowData.useYn, cdName: rowData.useYnCdName });
    }
    setIsNewAdd(false);
    setIsSaveClicked(false);
    setViewTable(false);
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    setSearchOption({ tsTitle: '', tsContents: '', bigo: '' }); // 검색필터 초기화
    setSearchOptionUseYn(INIT_USE_YN_A); // 검색필터 초기화
    clearRowData();
  };

  // 로우클릭으로 매핑된 우측 데이터인풋 초기화
  const clearRowData = () => {
    setRowState(emptyRowState); // 스테이트값 초기화
    setIsNewAdd(true); // 신규등록 여부
    setIsSaveClicked(false); // 저장클릭여부
    setUseYn(INIT_USE_YN_Y); // 내부 useYn값
    setSelectedRowKey(null); // TuiGrid 로우키값
  };

  const onClickNewAdd = () => {
    clearRowData();
    if (size.innerSize.W < 1024) setViewTable(false);
  };

  const onClickSave = () => {
    const reqData = {
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      tsCd: rowState.tsCd || '',
      tsTitle: rowState.tsTitle,
      tsContents: rowState.tsContents,
      tsGubun: prop.tsGubun,
      useYn: useYn[COMCD_USE_YN],
      bigo: rowState.bigo,
      editor: userInfo.userId,
    };
    const req = trimObject(reqData);
    setIsSaveClicked(true);
    if (!req.tsTitle) {
      tsTitleRef.current?.focus();
      return toast.warning(t('알림톡 제목을 입력하세요'));
    }
    if (!req.tsContents) {
      tsContentsRef.current?.focus();
      return toast.warning(t('알림톡 내용을 입력하세요'));
    }
    return postAPI(reqData);
  };

  const postAPI = async (reqParam: any) => {
    const res = await apiPost({ path: '/msg/ts', req: reqParam });
    if (res.data.statusCode === 200) {
      toast.success(t('알림톡 내용 저장 성공'));
      const { data } = res.data;
      if (isNewAdd) addMsgArray(data); // 신규 등록
      else updateMsgArray(data); // 수정
      // setSelectedRowKey(tableState.length);
      setIsNewAdd(false);
      setIsSaveClicked(false);
      setViewTable(false);
    }
  };

  // 테이블에 새로운 메시지객체 추가
  const addMsgArray = (responseData: IMsg) => {
    const newObj = { ...responseData, useYnCdName: responseData.useYn === 'Y' ? t('사용') : t('미사용') };

    const updateTableState = (prev: IMsg[]) => {
      const updatedArray =
        responseData.useYn === 'Y'
          ? prev.map((el) => ({ ...el, useYn: 'N', useYnCdName: t('미사용') })) // 신규저장항목 사용여부가 '사용'인 경우 기존로우 모두 '미사용'으로 변경
          : prev; // 신규저장항목 사용여부 '미사용'인 경우 배열에 객체 추가만

      const newArray = [...updatedArray, newObj];
      applyFilter(newArray);
      return newArray;
    };

    setRowState(newObj);
    setInitTableState(updateTableState);
  };

  // 테이블의 기존 메시지객체 수정
  const updateMsgArray = (responseData: IMsg) => {
    const newObj = { ...responseData, useYnCdName: responseData.useYn === 'Y' ? t('사용') : t('미사용') };
    const copyArray = [...initTableState];
    const findIndex = copyArray.findIndex((el: IMsg) => responseData.tsCd === el.tsCd); // 수정한 객체의 인덱스 찾기
    if (findIndex !== -1) {
      setRowState(newObj);
      setSelectedRowKey(rowState.rowKey);

      copyArray[findIndex] = newObj;
      if (responseData.useYn === 'Y') {
        setInitTableState((prev: IMsg[]) => {
          const newArray = [...prev.map((el: IMsg, i: number) => (i === findIndex ? copyArray[findIndex] : { ...el, useYn: 'N', useYnCdName: t('미사용') }))];
          applyFilter(newArray);
          return newArray;
        });
      } else {
        applyFilter(copyArray);
        setInitTableState(copyArray);
      }
    }
  };

  const onClickDelete = () => {
    setOpenModal((prev: any) => ({ ...prev, status: true, type: 'delete', api: () => deleteApi() }));
  };

  const deleteApi = async () => {
    const req = {
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      tsGubun: prop.tsGubun,
      tsCd: rowState.tsCd,
      editor: userInfo.userId,
    };
    const res = await apiDelete({ path: '/msg/ts', req });
    if (res.data.statusCode === 200) {
      const { data } = res.data;
      toast.success(t('알림톡 내용 삭제 성공'));
      onClickNewAdd();
      setSelectedRowKey(null);
      setViewTable(true);
      applyFilter(data.tsList?.map((el: IMsg) => ({ ...el, useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용') })));
      setInitTableState(data.tsList?.map((el: IMsg) => ({ ...el, useYnCdName: el.useYn === 'Y' ? t('사용') : t('미사용') })));
    }
  };

  const backToMain = () => {
    setViewTable(true);
    setSelectedRowKey(null);
  };

  if (isError) return <IssueGuide />;

  if (isLoading || isFetching)
    return (
      <LoadingModalBackground>
        <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
      </LoadingModalBackground>
    );

  return (
    <div className={`content-container ${size.innerSize.W >= 1024 ? 'twoColumn column-55 max800' : 'oneColumn'}`}>
      <TuiGridTwoColumnStyle className={size.innerSize.W >= 1024 || viewTable ? 'showRoot' : 'hideRoot'}>
        <SearchOptions align='left'>
          {isBottom && (
            <div className='floatingBtnWrapper flex-center'>
              <button type='button' onClick={() => scrollToNodeTop(node)}>
                <IoChevronUpSharp size={20} style={{ stroke: 'white' }} />
              </button>
            </div>
          )}
          <div className='inputsWrapper'>
            <div className='inputForm-row'>
              <div className='inputForm-col withLabelComCf'>
                <label htmlFor='useYn'>{t('사용유무')}</label>
                <SelectBox
                  options={useYnComCdListWithAll}
                  defaultOption={searchOptionUseYn.cdName}
                  state={searchOptionUseYn}
                  setState={setSearchOptionUseYn}
                  stateKey={COMCD_USE_YN}
                  initiateKey={searchOptionUseYn[COMCD_USE_YN]}
                  filterbar='filter-1-left'
                />
              </div>
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col '>
                <Input placeholder={t('제목')} label='' type='text' id='tsTitle' name='tsTitle' state={searchOption} setState={setSearchOption} />
              </div>
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col '>
                <Input placeholder={t('내용')} label='' type='text' id='tsContents' name='tsContents' state={searchOption} setState={setSearchOption} />
              </div>
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col '>
                <Input placeholder={t('비고')} label='' type='text' id='bigo' name='bigo' state={searchOption} setState={setSearchOption} maxLength={200} />
              </div>
            </div>
          </div>
          <div className='inputsWrapper'>
            <div className='secondSearchOption'>
              <div className='flex-basic textBtnGroup'>
                <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
              </div>
              <div className='flex-basic iconBtnGroup'>
                <BtnGhost onClick={onClickNewAdd}>
                  <span className='material-symbols-rounded'>add</span>
                  {t('등록')}
                </BtnGhost>
              </div>
            </div>
          </div>
        </SearchOptions>
        <div ref={componentRef} className='tui-container'>
          <TuiGrid data={tableState} columns={columns} perPage={15} rowKey={selectedRowKey} onClickRow={onClickRow} frozenCount={1} height={tuiHeight} />
        </div>
      </TuiGridTwoColumnStyle>
      {(size.innerSize.W >= 1024 || !viewTable) && (
        <TuiGridTwoColumnStyle>
          {isNewAdd ? (
            <div className='inputFormsWrapper flexRowEm'>
              {size.innerSize.W < 1024 && <BackButton func={() => backToMain()} />}
              <div className='formTitle'>{t('새로운 알림톡')}</div>
            </div>
          ) : (
            <div className='inputFormsWrapper smallTab'>
              {size.innerSize.W < 1024 && <BackButton func={() => backToMain()} />}
              <div className='tab' role='button'>
                {t('알림톡 내용')}
              </div>
            </div>
          )}
          <SubRoot>
            <div className='inputForm'>
              {!isNewAdd && (
                <div className='inputForm-row labelOutInput'>
                  <label htmlFor='code'>{t('코드 번호')}</label>
                  <div className='viewOnly'>{rowState.tsCd}</div>
                </div>
              )}
              <div className='inputForm-row labelOutInput'>
                <label htmlFor='tsTitle' className='required'>
                  {t('알림톡 제목')}
                </label>
                <Input
                  type='text'
                  id='tsTitle'
                  name='tsTitle'
                  state={rowState}
                  setState={setRowState}
                  disabled={!auth.updateAuth}
                  inputRef={tsTitleRef}
                  getBorderStyle={isSaveClicked ? applyBorderStyle(rowState.tsTitle || '', 'red', 'tsTitle') : undefined}
                />
              </div>
              <div className='inputForm-row labelOutInput'>
                <label htmlFor='tsContents' className='required'>
                  {t('알림톡 내용')}
                </label>
                <Textarea
                  display='flex'
                  flexDirection='row'
                  name='tsContents'
                  state={rowState}
                  set={setRowState}
                  disabled={!auth.updateAuth}
                  inputRef={tsContentsRef}
                  getBorderStyle={isSaveClicked ? applyBorderStyle(rowState.tsContents || '', 'red', 'tsContents') : undefined}
                />
              </div>

              <div className='inputForm-row labelOutInput'>
                <label htmlFor='useYn'>{t('사용유무')}</label>
                <div>
                  <SelectBox options={useYnComCdList} defaultOption={useYn.cdName || t('사용')} state={useYn} setState={setUseYn} stateKey={COMCD_USE_YN} initiateKey={useYn[COMCD_USE_YN]} />
                </div>
              </div>
              <div className='inputForm-row labelInInput'>
                <Input label={t('비고')} type='text' id='bigo' name='bigo' state={rowState} setState={setRowState} disabled={!auth.updateAuth} maxLength={200} />
              </div>
              {!isNewAdd ? (
                <div className='detailInfo-group'>
                  <div className='flex-col detailInfo'>
                    <label htmlFor='createDate'>{t('등록일자')}</label>
                    <div>{rowState?.wDate}</div>
                  </div>
                  <div className='flex-col detailInfo'>
                    <label htmlFor='createUser'>{t('등록자')}</label>
                    <div>{rowState.writer}</div>
                  </div>

                  <div className='flex-col detailInfo'>
                    <label htmlFor='updateDate'>{t('수정일자')}</label>
                    <div>{rowState?.eDate}</div>
                  </div>
                  <div className='flex-col detailInfo'>
                    <label htmlFor='updateUser'>{t('수정자')}</label>
                    <div>{rowState.editor}</div>
                  </div>
                </div>
              ) : undefined}
            </div>
          </SubRoot>

          <ButtonsWrapper className='flex-between'>
            <div> </div>
            <div className='flex-basic'>
              {!isNewAdd && auth.deleteAuth && <BtnRed onClick={onClickDelete}>{t('삭제')}</BtnRed>}
              {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={() => onClickSave()}>{t('저장')}</BtnBlue>}
            </div>
          </ButtonsWrapper>
        </TuiGridTwoColumnStyle>
      )}
      <Portal openModal={openModal?.status}>{openModal.type === 'delete' && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
    </div>
  );
};

export default Allimtalk;
