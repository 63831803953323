/*
 * 작성자 : 한정문
 * 날짜 : 2023.03.27
 * 기능 : 메뉴 리스트 출력 컴포넌트
 *
 * 작성자 : 홍선영
 * 날짜 : 2023.05.16
 * 수정사항 : 사이드메뉴 리스트 조회 API 연결
 */

import { useState, useEffect, useLayoutEffect } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import { IPageInfo, pageInfoState, sideMenuState, userState, asideState, asideFoldState, asideSubState } from '../atoms';
import { IComCdList, ISideMenuObject } from 'customTypes';
import { COMCD_MENU_GRANT } from '../_constants';
import { arraySortByAscdOrder } from '../utils/arraySortByAscdOrder';
import { PulseLoader } from 'react-spinners';
import AsideSubMenu from './AsideSubMenu';
import AsideMainMenu from './AsideMainMenu';
import * as cctv from '../utils/cctv';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { apiGet } from '../services/_common';

const Root = styled.div<IStyleProps>`
  &.hideRoot {
    visibility: hidden;
    position: absolute;
  }
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  height: -webkit-fill-available;
  height: fill-available;
  display: flex;
  transition: left 0.1s ease-in;
  overflow: hidden;
  z-index: 4999;
  @media (min-width: 1024px) {
    position: static;
  }
  .loader-background {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 17.5rem;
    background-color: ${({ theme }: { theme: any }) => theme.background};
    @media (min-width: 640px) {
      width: 16rem;
    }
  }
  .back-filter {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.6;
    z-index: 4999;
    @media (min-width: 1024px) {
      display: none;
    }
  }
`;

interface IStyleProps {
  W: number;
  height: number;
}

interface IAsideProps {
  W: number;
  openMenu: boolean;
}

interface SubObject {
  mtCd: string;
  mdCd: string;
  mdName: string;
  cdSort: number;
}

interface MainObject {
  mtCd: string;
  mtName: string;
  cdSort: number;
  sub: SubObject[];
}

interface IMenuObject {
  mtCd: string;
  menus: MainObject[];
  cdSort?: number;
}

const Aside = ({ W, openMenu }: IAsideProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const resetUserInfo = useResetRecoilState(userState);
  const [userInfo, setUserInfo] = useRecoilState(userState);
  const { userMenuList } = userInfo;
  const pageInfo = useRecoilValue(pageInfoState);
  const setPageInfo = useSetRecoilState<IPageInfo>(pageInfoState);
  const [height, setHeight] = useState(0);
  const [menuGrantComCdList, setMenuGrantComCdList] = useState<IComCdList[]>([]);
  const setOpenAside = useSetRecoilState(asideState);
  const [openSubAside, setOpenSubAside] = useRecoilState(asideSubState);
  const [mainSelected, setMainSelected] = useState<string>('');
  const [mainFocus, setMainFocus] = useState<string>('');
  const [subSelected, setSubSelected] = useState<string>('');
  const [sideMenuList, setSideMenuList] = useRecoilState<ISideMenuObject[]>(sideMenuState);
  const [subMenu, setSubMenu] = useState([]);
  // 데스크톱 환경에서 메뉴 접기/펼치기
  const [loading, setLoading] = useState(false);
  const [foldMenu, setFoldMenu] = useRecoilState<boolean>(asideFoldState);
  const navigate = useNavigate();

  const closeAside = () => {
    setOpenAside(false);
  };

  // Aside 메뉴 리스트를 클릭했을 때의 동작을 처리하는 함수
  const onClickAsideList = (el: any) => {
    const { mtCd, mdCd } = el[0];

    // 메뉴가 'home'이 아니고 하위 메뉴가 없는 경우
    if (el !== 'home' && el.length === 1) {
      // 현재 경로와 클릭한 메뉴의 경로가 다를 경우 CCTV 객체 파괴
      if (setLinkPath(mtCd, mdCd) !== pathname) {
        const cctvObject = cctv.getPluginOBJECT()?.oPlugin;
        if (cctvObject) cctv.destroy();
      }

      // 페이지 정보 설정 및 상태 업데이트
      setPageInfoState(`${mtCd}${mdCd}`);
      setSubSelected('');
      setSubMenu([]);
      setMainFocus('');
      setMainSelected(mtCd);
      navigate(setLinkPath(el[0].mtCd, el[0].mdCd));
      setOpenSubAside(false);

      // 뷰포트가 작은 경우 사이드 메뉴 닫기
      if (W < 1024) {
        setOpenAside(false);
      }
      // 메뉴가 'home'인 경우
    } else if (el === 'home') {
      const cctvObject = cctv.getPluginOBJECT()?.oPlugin;
      if (cctvObject) cctv.destroy();

      // 뷰포트가 작은 경우 사이드 메뉴 닫기
      if (W < 1024) setOpenAside(false);

      // 현재 경로가 루트인 경우 0으로 이동, 아닌 경우 루트로 이동
      if (pathname === '/') navigate(0);
      else navigate('/');

      setSubSelected('');
      setSubMenu([]);
      setMainFocus('');
      setMainSelected('');
      setOpenSubAside(false);

      // 그 외의 경우
    } else {
      setSubMenu(el);
      setMainFocus(el[0].mtCd);

      // 메인 포커스가 같은 경우 서브 메뉴 열고 닫기
      if (el[0].mtCd === mainFocus) {
        setMainFocus('');
        setOpenSubAside((prev) => !prev);
      } else {
        setOpenSubAside(true);
      }
    }
  };

  // 페이지 이동 함수
  const pageMovement = (sub: any) => {
    // CCTV 객체 파괴
    const cctvObject = cctv.getPluginOBJECT()?.oPlugin;
    if (cctvObject) {
      cctv.destroy();
    }

    // 포커스 메뉴 찾기
    const focusMenu = sideMenuList.find((list) => list.mtCd === sub.mtCd)?.mtName;
    const { mtCd, mdCd, mdName, sub2 } = sub;

    // 하위 탭 정렬
    const copyArray = [...sub2];
    const sortedTabList = arraySortByAscdOrder(copyArray, 'cdSort');

    // 디폴트 탭 설정
    const defaultTab = userMenuList?.find((el: any) => el.mtCd === mtCd && el.mdCd === mdCd && el.depth !== '1')?.mrCd || sortedTabList?.find((el: any) => el.rDefault === 'Y')?.mrCd; // 디폴트 탭 코드명
    if (focusMenu !== undefined) {
      // 페이지 정보 설정
      setPageInfo((prev) => ({
        ...prev, //
        header: { mtName: focusMenu, mdName },
        defaultMrCd: defaultTab,
        tabList: sortedTabList,
        mtCd: sub.mtCd,
        mdCd: sub.mdCd,
        mrCd: defaultTab,
      }));
    }

    // 메인 및 서브 메뉴 선택 설정
    setMainSelected(mtCd);
    setSubSelected(mdCd);
    setMainFocus('');
    setOpenSubAside(false);

    // 작은 화면일 경우 사이드 메뉴 닫기
    if (W < 1024) {
      setOpenAside(false);
    }
  };

  const foldingMenu = (): void => {
    setFoldMenu(!foldMenu);
  };

  const viewMainMenu = () => {
    if (W < 1280 && openSubAside) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (openSubAside && cctv.getPluginOBJECT()?.oPlugin) cctv.hideWndAll();
    else if (!openSubAside && cctv.getPluginOBJECT()?.oPlugin) cctv.showWndAll();
  }, [openSubAside]);

  // 사이드메뉴 조회 API
  const getSideMenuListAPI = async () => {
    let req;
    switch (userInfo.gCd.substring(0, 1)) {
      case 'S':
        req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
        break;
      case 'H':
        req = { hCd: userInfo.hCd };
        break;
      case 'A':
        req = { hCd: userInfo.hCd };
        break;
      default:
        break;
    }
    const res = await apiGet({ path: '/menu', req });
    const { statusCode, data, message } = res.data;
    if (statusCode === 200) {
      // 사이드메뉴 조회 데이터 중 mtCd의 첫글자에 따라
      // 마스터/본사/현장/사내 메뉴별로 다시 묶기
      const result: { [key: string]: MainObject[] } = {};
      data.forEach((item: any) => {
        const firstChar = item.mtCd.charAt(0);
        if (!result[firstChar]) {
          result[firstChar] = [];
        }
        result[firstChar].push(item);
      });

      const menuArray: IMenuObject[] = Object.entries(result).map(([key, value]) => ({
        mtCd: key,
        menus: value,
      }));

      // 공통코드 API 조회 해서 메뉴정렬순서를 객체에 추가
      const updatedMenuArray = menuArray.map((menuEl) => {
        const matchingGrant = menuGrantComCdList.find((grant: any) => grant.rootSubCd === menuEl.mtCd);
        if (matchingGrant) {
          return { ...menuEl, cdSort: matchingGrant.cdSort };
        }
        return menuEl;
      });

      const sortedMenuList = arraySortByAscdOrder(updatedMenuArray, 'cdSort');
      const resultArray: any[] = [];
      sortedMenuList.map((el) => {
        const newArray = arraySortByAscdOrder(el.menus, 'cdSort');
        newArray.map((el2) => {
          if (userInfo.gCd === 'MM') {
            if (el2.mtCd.substring(0, 1) === 'M') {
              resultArray.push(el2);
            }
          }
          let chk = false;
          const sub: any[] = [];
          let duplichk = '';
          let el22 = el2;
          userMenuList?.map((v: any) => {
            if (el2.mtCd === v.mtCd) {
              el2.sub.map((el3: any) => {
                if (el3.mdCd === v.mdCd && duplichk !== v.mdCd) {
                  if (userInfo.gCd === 'MM') {
                    if (v.depth === '1') {
                      chk = true;
                      duplichk = v.mdCd;
                      sub.push({ ...el3, useYn: v.useYn });
                    }
                  } else {
                    chk = true;
                    duplichk = v.mdCd;
                    sub.push({ ...el3 });
                  }
                }
              });
              if (userInfo.gCd === 'MM') {
                if (v.depth === '1') {
                  el22 = { ...el2, useYn: v.useYn };
                }
              } else {
                el22 = { ...el2 };
              }
            }
          });
          if (chk) resultArray.push({ ...el22, sub });
        });
      });
      setSideMenuList(resultArray);
      setLoading(true);
    } else {
      setLoading(true);
      // toast.error(t(ERROR));
    }
  };

  // 현장 정보 조회 API
  const getSiteInfoAPI = async (hCd: string, sCd: string) => {
    const req = { hCd, sCd };
    const res = await apiGet({ path: '/site/info', req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      if (Object.keys(data).length > 0) {
        getSiteSetAPI(hCd, sCd, data.siteInfo);
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 현장 설정 정보 조회 API
  const getSiteSetAPI = async (hCdParam: string, sCdParam: string, siteInfo: any) => {
    /**
     * 수정자 : 한영광
     * 수정일자 : 2024.02.27
     * 수정내용 : 반복된 코드 사용을 줄이고 유지보수 편의성 향상을 위해 리팩토링된 GET 방식 API 요청 함수 적용
     */
    const req = { hCd: hCdParam, sCd: sCdParam };
    const res = await apiGet({ path: '/setting/general', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      if (Object.keys(data).length > 0) {
        setUserInfo((prev) => ({
          ...prev,
          hCd: hCdParam,
          sCd: sCdParam,
          hName: siteInfo.hName,
          sName: siteInfo.sName,
          eExcelYn: siteInfo.eExcelYn,
          eListYn: siteInfo.eListYn,
          ePrintYn: siteInfo.ePrintYn,
          prejobtypeYn: data.generalSet.prejobtypeYn,
          elderlyAgeSet: data.generalSet.elderlyAgeSet,
          sessionTimeout: data.generalSet.sessionTimeout,
          eduPlace: data.generalSet.eduPlace,
          eduCharge: data.generalSet.eduCharge,
          eduStartTime: data.generalSet.eduStartTime,
          eduEndTime: data.generalSet.eduEndTime,
          fromHighpress: data.generalSet.fromHighpress,
          fromLowpress: data.generalSet.fromLowpress,
          toHighpress: data.generalSet.toHighpress,
          toLowpress: data.generalSet.toLowpress,
          accidentEdate: data.generalSet.accidentEdate,
          accidentSdate: data.generalSet.accidentSdate,
          accidentTday: data.generalSet.accidentTday,
          cctvProtocol: data.generalSet.cctvProtocol,
          bScreenMode: data.generalSet.bScreenMode,
        }));
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  const isAuthOfMasterUser = userInfo.gCd === 'MM';
  const isAuthOfSiteUser = userInfo.gCd !== 'MM' && userInfo.gCd !== 'AM' && userInfo.gCd !== 'HM' && userInfo.gCd !== 'HU';

  // 본사 메뉴 조회 API
  const getMenuHeadInfoAPI = async (hCdParam: string) => {
    let reqParam;
    if (isAuthOfMasterUser) {
      reqParam = { path: `/menu/headInfo/${hCdParam}` };
    } else {
      reqParam = { path: '/menu/userSideMenu', req: { userId: userInfo.userId } };
    }
    const res = await apiGet(reqParam);
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      setUserInfo((prev) => ({ ...prev, userMenuList: isAuthOfMasterUser ? data : data.userMenuList }));
      if (pathname !== '/') {
        setPageInfo((prev) => ({ ...prev, header: { mtName: t('대시보드'), mdName: '' }, mtCd: '', mdCd: '', mrCd: '' }));
        // window.location.replace('/');
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 현장 메뉴 조회 API
  const getMenuSiteInfoAPI = async (hCdParam: string, sCdParam: string) => {
    let reqParam;
    if (isAuthOfSiteUser) {
      reqParam = { path: '/menu/userSideMenu', req: { userId: userInfo.userId } };
    } else {
      reqParam = { path: `/menu/siteInfo/${sCdParam}`, req: { hCd: hCdParam } };
    }
    const res = await apiGet(reqParam);
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      setUserInfo((prev) => ({ ...prev, userMenuList: getUserMenuList(data) }));

      if (pathname !== '/') {
        setPageInfo((prev) => ({ ...prev, header: { mtName: t('대시보드'), mdName: '' }, mtCd: '', mdCd: '', mrCd: '' }));
        // window.location.replace('/');
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  const getUserMenuList = (data: any) => {
    if (isAuthOfMasterUser) {
      return data;
    }
    if (isAuthOfSiteUser) {
      return data.userMenuList;
    }
    return data.filter((v: any) => v.useYn === 'Y');
  };

  const getComCdMenuGrantAPI = async () => {
    const req = { grCd: COMCD_MENU_GRANT };
    const res = await apiGet({ path: '/code/detail', req });
    const { statusCode, data, message } = res.data;
    if (statusCode === 200) {
      setMenuGrantComCdList(data.comCdList);
    } else {
      setLoading(true);
      // toast.error(t(ERROR));
    }
  };

  useEffect(() => {
    getComCdMenuGrantAPI();
  }, []);

  useEffect(() => {
    if (userInfo.userId === 'siteDashboardViewer') {
      resetUserInfo();
      Cookies.remove('prosafeToken');
      Cookies.remove('refreshToken');
    }
  }, [userInfo.userId, pathname]);

  useEffect(() => {
    if (userInfo.hCd !== 'M000') {
      if (!userInfo.sCd || userInfo.sCd === '00000') {
        getMenuHeadInfoAPI(userInfo.hCd);
      } else {
        getMenuSiteInfoAPI(userInfo.hCd, userInfo.sCd);
        getSiteInfoAPI(userInfo.hCd, userInfo.sCd);
      }
    }
  }, [userInfo.hCd, userInfo.sCd]);

  useEffect(() => {
    getSideMenuListAPI();
    if (pathname === '/') {
      setOpenSubAside(false);
      // 대시보드 컴포넌트에서 근로자연명부페이지의 권한을 줌 (근로자 신규등록 및 조회/수정 모달때문)
      setPageInfo((prev) => ({ ...prev, header: { mtName: t('대시보드'), mdName: '' }, mtCd: '', mdCd: '', mrCd: '' }));
    }
  }, [userMenuList]);

  useLayoutEffect(() => {
    sideMenuList.map((sub) => {
      sub.sub?.map((sub2) => {
        if (sub2.mtCd === pageInfo.mtCd) {
          setMainSelected(sub2.mtCd);
        }
      });
    });
  }, []);

  const setPageInfoState = (mtCdmdCd: string) => {
    const mtCd = mtCdmdCd.substring(0, 4);
    const mdCd = mtCdmdCd.substring(4, 7);
    const menuObject: any = sideMenuList.find((el) => el.mtCd === mtCd);
    if (menuObject) {
      const subMenuObject = menuObject.sub.find((el: any) => el.mdCd === mdCd);
      if (subMenuObject) {
        // setMainSelected(mtCd);
        setHeight(menuObject.sub.length * 35);
        const copyArray = [...subMenuObject.sub2];
        const sortedTabList = arraySortByAscdOrder(copyArray, 'cdSort');
        const defaultTab = userMenuList?.find((el: any) => el.mtCd === mtCd && el.mdCd === mdCd && el.depth !== '1')?.mrCd || sortedTabList?.find((el: any) => el.rDefault === 'Y')?.mrCd; // 디폴트 탭 코드명
        // 헤더명, 탭리스트 전역저장
        setPageInfo((prev) => ({
          ...prev,
          header: { mtName: menuObject.mtName, mdName: subMenuObject.mdName },
          defaultMrCd: defaultTab,
          tabList: sortedTabList,
          mtCd,
          mdCd,
          mrCd: defaultTab,
        }));
      }
    }
  };

  useEffect(() => {
    switch (pathname) {
      case '/master1': // 마스터관리-관리자정보
        setPageInfoState('M001001');
        break;
      case '/master2': // 마스터관리-공통코드관리
        setPageInfoState('M001002');
        break;
      case '/master3': // 마스터관리-현장일반코드관리
        setPageInfoState('M001003');
        break;
      case '/master4': // 마스터관리-Dashboard관리
        setPageInfoState('M001004');
        break;
      case '/master6': // 마스터관리-서명항목코드관리
        setPageInfoState('M001006');
        break;
      case '/m_menu1': // 마스터메뉴관리-메뉴관리
        setPageInfoState('M002001');
        break;
      case '/m_menu2': // 마스터메뉴관리-메뉴권한관리
        setPageInfoState('M002002');
        break;
      case '/m_common1': // 일반관리-메뉴관리
        setPageInfoState('M003001');
        break;
      case '/m_common2': // 일반관리-사내관리
        setPageInfoState('M003002');
        break;
      case '/m_common3': // 일반관리-본사관리
        setPageInfoState('M003003');
        break;
      case '/m_common4': // 일반관리-현장관리
        setPageInfoState('M003004');
        break;
      case '/m_common5': // 일반관리-근로자입력화면설정
        setPageInfoState('M003005');
        break;
      case '/m_common6': // 일반관리-현장연결
        setPageInfoState('M003006');
        break;
      case '/m_log': // 일반관리-로그 이력
        setPageInfoState('M003007');
        break;
      // case 'C001':
      //   '/company1';
      //   break;
      // case 'C002':
      //   '/company2';
      //   break;
      // case 'C003':
      //   '/company3';
      //   break;
      case '/h_info1': // 본사소개-본사정보
        setPageInfoState('H001001');
        break;
      case '/h_user1':
        setPageInfoState('H002001');
        break;
      case '/h_cctv1':
        setPageInfoState('H004001'); // 현장정보/CCTV-현장관리 (본사에서 현장을 확인하는 페이지)
        break;
      case '/h_cctv2':
        setPageInfoState('H004002'); // 현장정보/CCTV-현장연결 (본사에서 현장을 연결하는 페이지)
        break;
      case '/h_common2': // 본사 설정관리-메인화면관리
        setPageInfoState('H005001');
        break;
      case '/h_log':
        setPageInfoState('H005002'); // 본사 설정관리/로그이력
        break;
      case '/s_info1':
        setPageInfoState('S001001'); // 현장 소개 > 현장 정보 / 조감도
        break;
      case '/s_worker1':
        setPageInfoState('S002001'); // 근로자 연명부
        break;
      case '/s_worker2':
        setPageInfoState('S002002'); // 근로자 퇴직처리
        break;
      case '/s_worker4':
        setPageInfoState('S002004'); // 근로자 업체이직
        break;
      case '/s_worker5':
        setPageInfoState('S002005'); // 근로자 보호구지급 관리
        break;
      case '/s_worker6':
        setPageInfoState('S002006'); // 외국인 근로자 관리
        break;
      case '/s_worker7':
        setPageInfoState('S002007'); // 근로자 혈압 관리
        break;
      case '/s_worker8':
        setPageInfoState('S002008'); // 고령 근로자 관리
        break;
      case '/s_worker9':
        setPageInfoState('S002009'); // 근로자 일반건강 관리
        break;
      case '/s_worker10':
        setPageInfoState('S002010'); // 근로자 특수건강 관리
        break;
      case '/s_worker11':
        setPageInfoState('S002011'); // 근로자 현장 출입금지자 관리
        break;
      case '/s_attend1':
        setPageInfoState('S003001'); // 일 출역현황
        break;
      case '/s_attend2':
        setPageInfoState('S003002'); // 월 출역현황
        break;
      case '/s_attend3':
        setPageInfoState('S003003'); // 연 출역현황
        break;
      case '/s_attend4':
        setPageInfoState('S003004'); // 업체별 출역현황
        break;
      case '/s_attend5':
        setPageInfoState('S003005'); // 개인별 일 출역현황
        break;
      case '/s_attend6':
        setPageInfoState('S003006'); // 개인별 월 출역현황
        break;
      case '/s_attend7':
        setPageInfoState('S003007'); // 혈압 이상자 출역현황
        break;
      case '/s_attend8':
        setPageInfoState('S003008'); // 고령 근로자 출역현황
        break;
      case '/s_safe1':
        setPageInfoState('S004001'); // 근로자 안전관리
        break;
      case '/s_safe2':
        setPageInfoState('S004002'); // 근로자 안전지적
        break;
      case '/s_safe4':
        setPageInfoState('S004004'); // 안전 관리/게시판
        break;
      case '/s_safe5':
        setPageInfoState('S004005'); // 비인가자 출입 정보
        break;
      case '/s_safe6':
        setPageInfoState('S004006'); // 긴급 상황 현황
        break;
      case '/s_safe7':
        setPageInfoState('S004007'); // AI 카메라
        break;
      case '/s_sensor1':
        setPageInfoState('S005001'); // 미세먼지 측정기
        break;
      case '/s_sensor2':
        setPageInfoState('S005002'); // 소음 측정기
        break;
      case '/s_sensor3':
        setPageInfoState('S005003'); // 질소 측정기
        break;
      case '/s_sensor4':
        setPageInfoState('S005004'); // 풍속 측정기
        break;
      case '/s_sensor5':
        setPageInfoState('S005005'); // 산소 측정기
        break;
      case '/s_sensor6':
        setPageInfoState('S005006'); // 가스 측정기
        break;
      case '/s_sensor7':
        setPageInfoState('S005007'); // 다중 유해가스 측정기
        break;
      case '/s_cctv1':
        setPageInfoState('S006001'); // 현장CCTV-현장 CCTV보기
        break;
      case '/s_user1':
        setPageInfoState('S007001');
        break;
      case '/sm_setting1': // 설정관리-환경설정
        setPageInfoState('S008001');
        break;
      case '/sm_setting2': // 설정관리-근로자 정보 관리
        setPageInfoState('S008002');
        break;
      case '/sm_setting3': // 설정관리-코드관리
        setPageInfoState('S008003');
        break;
      case '/sm_setting4': // 설정관리-메인화면 관리
        setPageInfoState('S008004');
        break;
      case '/sm_setting5': // 설정관리-장비관리
        setPageInfoState('S008005');
        break;
      case '/sm_setting6': // 설정관리-협력업체관리
        setPageInfoState('S008006');
        break;
      case '/sm_setting7': // 설정관리-CCTV관리
        setPageInfoState('S008007');
        break;
      case '/s_log': // 설정관리-로그이력
        setPageInfoState('S008008');
        break;
      case '/s_tunnel1': // 터널관리-구역 현황 관리
        setPageInfoState('S010001');
        break;
      case '/s_safeedu1': // 태블릿 근로자관리-근로자 관리
        setPageInfoState('S012001');
        break;
      case '/s_safeedu2': // 태블릿 근로자관리-안전교육 일정 관리
        setPageInfoState('S012002');
        break;
      case '/sm_setting9': // 설정관리-터널 설정 관리
        setPageInfoState('S008009');
        break;
      case '/sm_setting10': // 설정관리-방송 장비 관리
        setPageInfoState('S008010');
        break;
      case '/sm_setting11':
        setPageInfoState('S008011'); // 알림톡 관리
        break;
      case '/sm_setting12':
        setPageInfoState('S008012'); // 알림톡 관리
        break;
      case '/s_broadcast':
        setPageInfoState('S011001'); // 방송 장비 관리
        break;
      default:
        break;
    }
  }, [pathname]);

  const setLinkPath = (mtCd: string, mdCd: string) => {
    const code = `${mtCd}${mdCd}`;
    switch (code) {
      case 'M001001': // 마스터관리-관리자정보
        return '/master1';
      case 'M001002': // 마스터관리-공통코드관리
        return '/master2';
      case 'M001003': // 마스터관리-현장일반코드관리
        return '/master3';
      case 'M001004': // 마스터관리-Dashboard관리
        return '/master4';
      case 'M001006': // 마스터관리-서명항목코드관리
        return '/master6';
      case 'M002001': // 마스터메뉴관리-메뉴관리
        return '/m_menu1';
      case 'M002002': // 마스터메뉴관리-메뉴권한관리
        return '/m_menu2';
      case 'M003001': // 일반관리-메뉴관리
        return '/m_common1';
      case 'M003002': // 일반관리-사내관리
        return '/m_common2';
      case 'M003003': // 일반관리-본사관리
        return '/m_common3';
      case 'M003004': // 일반관리-현장관리
        return '/m_common4';
      case 'M003005': // 일반관리-근로자입력화면설정
        return '/m_common5';
      case 'M003006': // 일반관리-현장연결
        return '/m_common6';
      case 'M003007': // 일반관리-로그이력
        return '/m_log';
      case 'C001':
        return '/company1';
      case 'C002':
        return '/company2';
      case 'C003':
        return '/company3';
      case 'H001001': // 본사소개-본사정보
        return '/h_info1';
      case 'H002001':
        return '/h_user1';
      // case 'H003002': // 본사 일반관리-메인화면관리
      //   return '/h_common2';
      case 'H004001':
        return '/h_cctv1'; // 현장정보/CCTV-현장관리 (본사에서 현장을 확인하는 페이지)
      case 'H004002':
        return '/h_cctv2'; // 현장정보/CCTV-현장연결 (본사에서 현장을 연결하는 페이지)
      case 'H005001':
        return '/h_common2'; // 본사 설정관리 - 메인화면관리
      case 'H005002':
        return '/h_log'; // 본사 설정관리 - 로그이력
      case 'S001001':
        return '/s_info1'; // 현장 소개 > 현장 정보 / 조감도
      case 'S002001':
        return '/s_worker1'; // 근로자 연명부
      case 'S002002':
        return '/s_worker2'; // 근로자 퇴직처리
      case 'S002004':
        return '/s_worker4'; // 근로자 업체이직
      case 'S002005':
        return '/s_worker5'; // 근로자 보호구지급 관리
      case 'S002006':
        return '/s_worker6'; // 외국인 근로자 관리
      case 'S002007':
        return '/s_worker7'; // 근로자 혈압 관리
      case 'S002008':
        return '/s_worker8'; // 고령 근로자 관리
      case 'S002009':
        return '/s_worker9'; // 근로자 일반건강 관리
      case 'S002010':
        return '/s_worker10'; // 근로자 특수건강 관리
      case 'S002011':
        return '/s_worker11'; // 근로자 현장 출입금지자 관리
      case 'S003001':
        return '/s_attend1'; // 일 출역현황
      case 'S003002':
        return '/s_attend2'; // 월 출역현황
      case 'S003003':
        return '/s_attend3'; // 연 출역현황
      case 'S003004':
        return '/s_attend4'; // 업체별 출역현황
      case 'S003005':
        return '/s_attend5'; // 개인별 일 출역현황
      case 'S003006':
        return '/s_attend6'; // 개인별 월 출역현황
      case 'S003007':
        return '/s_attend7'; // 혈압 이상자 출역현황
      case 'S003008':
        return '/s_attend8'; // 고령 근로자 출역현황
      case 'S004001':
        return '/s_safe1'; // 근로자 안전관리
      case 'S004002':
        return '/s_safe2'; // 근로자 안전지적
      // case 'S004003':
      //   return '/s_safe3'; // 근로자 안전지적(근로자)
      case 'S004004':
        return '/s_safe4'; // 안전 관리/게시판
      case 'S004005':
        return '/s_safe5'; // 비인가자 출입 정보
      case 'S004006':
        return '/s_safe6'; // 긴급 상황 현황
      case 'S004007':
        return '/s_safe7'; // AI 카메라
      case 'S005001':
        return '/s_sensor1'; // 미세먼지 측정기
      case 'S005002':
        return '/s_sensor2'; // 소음 측정기
      case 'S005003':
        return '/s_sensor3'; // 질소 측정기
      case 'S005004':
        return '/s_sensor4'; // 풍속 측정기
      case 'S005005':
        return '/s_sensor5'; // 산소 측정기
      case 'S005006':
        return '/s_sensor6'; // 가스 측정기
      case 'S005007':
        return '/s_sensor7'; // 다중 유해가스 측정기
      case 'S006001':
        return '/s_cctv1'; // 현장CCTV-현장 CCTV보기
      case 'S007001':
        return '/s_user1';
      case 'S008001': // 설정관리-환경설정
        return '/sm_setting1';
      case 'S008002': // 설정관리-근로자 정보 관리
        return '/sm_setting2';
      case 'S008003': // 설정관리-코드관리
        return '/sm_setting3';
      case 'S008004': // 설정관리-메인화면 관리
        return '/sm_setting4';
      case 'S008005': // 설정관리-장비관리
        return '/sm_setting5';
      case 'S008006': // 설정관리-협력업체관리
        return '/sm_setting6';
      case 'S008007': // 설정관리-CCTV관리
        return '/sm_setting7';
      case 'S008008': // 설정관리-로그이력
        return '/s_log';
      case 'S010001': // 터널관리-구역 현황 관리
        return '/s_tunnel1';
      case 'S012001': // 태블릿 근로자관리-근로자 관리
        return '/s_safeedu1';
      case 'S012002': // 태블릿 근로자관리-안전교육 일정 관리
        return '/s_safeedu2';
      case 'S008009': // 설정관리-터널관리
        return '/sm_setting9';
      case 'S008010': // 설정관리-방송 장비 관리
        return '/sm_setting10';
      case 'S008011': // 설정관리-알림톡 관리
        return '/sm_setting11';
      case 'S008012': // 설정관리-태블릿 안전교육 관리
        return '/sm_setting12';
      case 'S011001':
        return '/s_broadcast'; // 방송 장비 관리
      default:
        return '/';
    }
  };

  if (!loading) {
    return (
      <Root W={W} height={height}>
        <div className='loader-background'>
          <PulseLoader color='rgb(0, 122, 255)' size='10px' />
        </div>
      </Root>
    );
  }

  return (
    <Root W={W} height={height} className={openMenu ? 'showRoot' : 'hideRoot'}>
      <AsideMainMenu
        foldMenu={foldMenu}
        foldingMenu={foldingMenu}
        sideMenuList={sideMenuList}
        onClickAsideList={onClickAsideList}
        mainSelected={mainSelected}
        mainFocus={mainFocus}
        closeAside={closeAside}
        openMenu={viewMainMenu()}
      />
      {openSubAside && (
        <AsideSubMenu
          subMenu={subMenu}
          pageMovement={pageMovement}
          setLinkPath={setLinkPath}
          subSelected={subSelected}
          setSubSelected={setSubSelected}
          setMainFocus={setMainFocus}
          closeAside={closeAside}
        />
      )}
      <div className='back-filter' onClick={() => closeAside()} role='presentation' />
    </Root>
  );
};
export default Aside;
