import { useTranslation } from 'react-i18next';
import { todayYYYYMMDD } from '../../utils/formatDate';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { arraySortByAscdOrder } from '../../utils/arraySortByAscdOrder';
import { PulseLoader } from 'react-spinners';
import { InputTable } from '../../assets/styles/InputTable';
import { apiGet } from '../../services/_common';
import { IUser } from '../../atoms';
import styled from 'styled-components';

const Root = styled.div`
  user-select: none;
  height: 100%;
  > .widget-header {
    width: 100%;
    padding: 0.75rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    &.margin-top {
      margin-bottom: 0;
    }
    > .widget-title {
      padding: 0 1rem;
      align-items: center;
      > span {
        flex-shrink: 0;
        font-weight: ${({ theme }: { theme: any }) => theme.font_semibold};
        font-size: 1.125rem;
        line-height: 1.2;
        color: ${({ theme }: { theme: any }) => theme.text_primary};
        display: flex;
        align-items: center;
      }
      > .widget-title-innerTab,
      > .widget-btn-group {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        > span {
          font-weight: ${({ theme }: { theme: any }) => theme.font_semibold};
          font-size: 1.125rem;
          color: ${({ theme }: { theme: any }) => theme.text_primary};
        }
        > button {
          height: 2rem;
          font-weight: ${({ theme }: { theme: any }) => theme.font_bold};
          padding: 0 0.75rem;
          border-radius: 1rem;
          font-size: 0.875rem;
          color: ${({ theme }: { theme: any }) => theme.text_secondary};
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          border-radius: 0.5rem;
          gap: 0;
        }
        > button:hover {
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
        }
        > button:nth-child(1) {
          padding-left: 0.25rem;
          padding-right: 0.5rem;
          .material-symbols-rounded {
            font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 20;
            color: #78829d;
          }
        }
        > .innerTab {
          gap: 0.25rem;
          > div {
            cursor: pointer;
            height: 2rem;
            padding: 0 0.75rem;
            display: flex;
            align-items: center;
            color: ${({ theme }: { theme: any }) => theme.text_secondary};
            border-radius: 1rem;
            font-weight: ${({ theme }: { theme: any }) => theme.font_medium};
          }
          > div:hover {
            background-color: ${({ theme }: { theme: any }) => theme.tonal};
          }
        }
      }
    }
  }

  .widget-body {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .widget-body.table {
    width: 100%;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    overflow: hidden;
    > div {
      height: 100%;
      .table {
        flex-grow: 1;
      }
    }
    .tr {
      pointer-events: none;
      .padding-right {
        padding-right: 1rem;
      }
      .tableStickyNo {
        padding-left: 1rem;
        font-weight: 600;
      }
    }
    .tr.hover {
    }
  }
`;

interface IProps {
  name: string;
  userInfo: IUser;
  refreshTimeCount: number;
}

interface IGroupedAttend {
  sjCd: string;
  sjName: string;
  normalWorkerTodayCnt: number;
  oldWorkerTodayCnt: number;
  totalWorkerTodayCnt: number;
}

interface ISiteJoin {
  type: string;
  sjCd: string;
  name: string;
}

// 협력업체 출역 정보
export const SiteJoinAttendInfo = ({ name, userInfo, refreshTimeCount }: IProps) => {
  const { t } = useTranslation();
  const toDay = todayYYYYMMDD();
  const [totalAttendTable, setTotalAttendTable] = useState<IGroupedAttend[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, delYnCheck: 'Y' };
      const res = await apiGet({ path: `/siteJoin`, req });
      const formatSelectBoxFormList = res.data.data.siteJoinList.map((el2: any) => {
        return { type: 'sjCd', sjCd: el2.sjCd, name: el2.sjName };
      });
      const sortedArray = arraySortByAscdOrder(formatSelectBoxFormList, 'sjCd');
      return sortedArray;
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      throw new Error('error');
    }
  };

  const {
    data: sjCdList,
    isLoading: isLoadingSjcdList,
    isFetching: isFetchingSjcdList,
    isRefetching: isRefetchingSjcdList,
    isError,
  } = useQuery(['siteJoinGet2', userInfo.hCd, userInfo.sCd], () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
    refetchInterval: refreshTimeCount * 1000,
  });

  useEffect(() => {
    const fetch = async () => {
      if (sjCdList) {
        if (sjCdList.length === 0) {
          setIsLoading(false);
          return;
        }

        try {
          const dayAttendResult = await getAttendDayAPI(sjCdList);
          if (!dayAttendResult) {
            setIsLoading(false);
            return;
          }

          const elderOffset = Number(`${userInfo.elderlyAgeSet}0000`);
          const oldDate = String(Number(toDay) - elderOffset);
          const oldAttendResult = await getOldAttendAPI(oldDate, dayAttendResult, sjCdList);

          if (oldAttendResult) {
            setTotalAttendTable(oldAttendResult);
          }
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching attendance data:', error);
          setIsLoading(false);
        }
      }
    };

    fetch();
  }, [sjCdList, userInfo.elderlyAgeSet, toDay]);

  // 통합(고령+일반)출역조회 API
  const getAttendDayAPI = async (siteJoinListParam: ISiteJoin[]) => {
    const aDate = { aDate1: toDay, aDate2: toDay };
    const req = { ...aDate, hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/attend/day', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const { attendList } = data;

      // sjCd별로 그룹핑
      const grouped = attendList.reduce((acc: any, item: any) => {
        const group = acc.find((g: any) => g.sjCd === item.sjCd);
        const findSjCdName = siteJoinListParam.find((el: ISiteJoin) => el.sjCd === item.sjCd);

        if (group) {
          group.attendData.push(item);
        } else {
          acc.push({
            sjCd: item.sjCd,
            sjName: findSjCdName !== undefined ? findSjCdName.name : '',
            attendData: [item],
          });
        }
        return acc;
      }, []);

      // 카운트
      const groupedCountResult = grouped.map((group: any) => {
        const count = { todayCnt: 0 };
        group.attendData.forEach((item: any) => {
          if (item.aDate?.replaceAll('-', '') === toDay) count.todayCnt += 1;
        });

        return { sjCd: group.sjCd, sjName: group.sjName, ...count };
      });
      return groupedCountResult;
      // await getOldAttendAPI(String(Number(toDay) - Number(`${userInfo.elderlyAgeSet}0000`)), groupedCountResult, siteJoinListParam);
    }
    return null;
  };

  // 고령근로자 출역조회 API
  const getOldAttendAPI = async (oldDataParam: string, groupedCountResultParam: any, siteJoinListParam: ISiteJoin[]) => {
    const obj = { aDate1: toDay, aDate2: toDay, oldDate1: '', oldDate2: oldDataParam };
    const req = { ...obj, hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/attend/old', req });

    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const { attendList } = data;
      const newArray = attendList.filter((el: any) => el.age !== null);

      // sjCd별로 그룹핑
      const grouped = newArray.reduce((acc: any, item: any) => {
        const group = acc.find((g: any) => g.sjCd === item.sjCd);
        const findSjCdName = siteJoinListParam.find((el: any) => el.sjCd === item.sjCd);

        if (group) {
          group.attendData.push(item);
        } else {
          acc.push({
            sjCd: item.sjCd,
            sjName: findSjCdName !== undefined ? findSjCdName.name : '',
            attendData: [item],
          });
        }
        return acc;
      }, []);

      // 오늘/어제/그제 카운트
      const groupedCountResult = grouped.map((group: any) => {
        const count = { todayCnt: 0 };

        group.attendData.forEach((item: any) => {
          if (item.aDate?.replaceAll('-', '') === toDay) count.todayCnt += 1;
        });

        return { sjCd: group.sjCd, sjName: group.sjName, ...count };
      });

      // oldAgeWorkerAttend 조회 테이블 생성
      const oldAgeLookup = groupedCountResult.reduce((acc: any, curr: any) => {
        acc[curr.sjCd] = curr;
        return acc;
      }, {});

      const result = groupedCountResultParam.map((item: any) => {
        // oldAgeData 데이터를 가져오거나 기본값을 0 으로 설정
        const oldAgeData = oldAgeLookup[item.sjCd] || {
          todayCnt: 0,
        };

        return {
          sjCd: item.sjCd,
          sjName: item.sjName,
          normalWorkerTodayCnt: item.todayCnt - oldAgeData.todayCnt,
          oldWorkerTodayCnt: oldAgeData.todayCnt,
          totalWorkerTodayCnt: item.todayCnt,
        };
      });

      const removeZeroCountRow = result.filter((el: any) => el.totalWorkerTodayCnt > 0);
      // setTotalAttendTable(removeZeroCountRow);
      return removeZeroCountRow;
    }
    return null;
  };

  // 협력업체 일반
  const getNormalWorkerTodayCnt = () => {
    return totalAttendTable
      ?.map((el: any) => el.normalWorkerTodayCnt)
      ?.reduce((prev, cur) => {
        return prev + cur;
      }, 0);
  };

  // 협력업체 고령
  const getOldWorkerTodayCnt = () => {
    return totalAttendTable
      ?.map((el: any) => el.oldWorkerTodayCnt)
      ?.reduce((prev, cur) => {
        return prev + cur;
      }, 0);
  };

  // 협력업체 금일합계
  const getTotalWorkerTodayCnt = () => {
    return totalAttendTable
      ?.map((el: any) => el.totalWorkerTodayCnt)
      ?.reduce((prev, cur) => {
        return prev + cur;
      }, 0);
  };

  const animationStyle = {
    animation: `slide ${totalAttendTable && totalAttendTable.length * 1.6}s linear infinite`,
  };

  if (isError) return null;

  if (isLoadingSjcdList || isFetchingSjcdList || isRefetchingSjcdList || isLoading) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <Root>
      <div className='widget-header'>
        <div className='widget-title flex-between innerTab'>
          <div className='widget-title-innerTab'>
            <span>{t(name)}</span>
          </div>
        </div>
      </div>
      <div className='widget-body table'>
        <InputTable>
          <div className='thead'>
            <div className='tr'>
              <div className='trCol2p5 flex-basic tableStickyNo content-overflow padding-left'>{t('협력업체')}</div>
              <div className='trCol4 flex-center'>{t('일반')}</div>
              <div className='trCol4 flex-center'>{t('고령')}</div>
              <div className='trCol4 flex-center'>{t('금일 합계')}</div>
            </div>
          </div>
          <div className='table'>
            <div className='tbody' style={totalAttendTable && totalAttendTable?.length > 4 ? animationStyle : undefined}>
              {totalAttendTable && totalAttendTable?.length > 0 ? (
                totalAttendTable?.map((el: any, i: number) => (
                  <div className='tr' key={`attendInfo_${i}`}>
                    <div className='trCol2p5 flex-basic tableStickyNo content-overflow padding-left'>{el.sjName}</div>
                    <div className='trCol4 flex-center shrink0'>
                      <span className={`pointValue ${el.normalWorkerTodayCnt > 0 && el.normalWorkerTodayCnt <= 10 ? 'under10' : ''} ${el.normalWorkerTodayCnt > 10 ? 'over10' : ''}`}>
                        {el.normalWorkerTodayCnt}
                      </span>
                    </div>
                    <div className='trCol4 flex-center shrink0'>
                      <span className={`pointValue ${el.oldWorkerTodayCnt > 0 && el.oldWorkerTodayCnt <= 10 ? 'under10' : ''} ${el.oldWorkerTodayCnt > 10 ? 'over10' : ''}`}>
                        {el.oldWorkerTodayCnt}
                      </span>
                    </div>
                    <div className='trCol4 flex-center shrink0'>
                      <span className={`pointValue ${el.totalWorkerTodayCnt > 0 && el.totalWorkerTodayCnt <= 10 ? 'under10' : ''} ${el.totalWorkerTodayCnt > 10 ? 'over10' : ''}`}>
                        {el.totalWorkerTodayCnt}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <div className='noData'>No data.</div>
              )}
            </div>
          </div>
          <tfoot>
            <div className='tr'>
              <div className='trCol2p5 flex-basic tableStickyNo content-overflow padding-left'>
                {t('합계')}
                <span className='subNumber'>{totalAttendTable?.length || 0}</span>
              </div>
              <div className='trCol4 flex-center shrink0'>
                <span className={`pointValue ${getNormalWorkerTodayCnt() > 0 && getNormalWorkerTodayCnt() <= 10 ? 'under10' : ''} ${getNormalWorkerTodayCnt() > 10 ? 'over10' : ''}`}>
                  {getNormalWorkerTodayCnt()}
                </span>
              </div>
              <div className='trCol4 flex-center shrink0'>
                <span className={`pointValue ${getOldWorkerTodayCnt() > 0 && getOldWorkerTodayCnt() <= 10 ? 'under10' : ''} ${getOldWorkerTodayCnt() > 10 ? 'over10' : ''}`}>
                  {getOldWorkerTodayCnt()}
                </span>
              </div>
              <div className='trCol4 flex-center shrink0'>
                <span className={`pointValue ${getTotalWorkerTodayCnt() > 0 && getTotalWorkerTodayCnt() <= 10 ? 'under10' : ''} ${getTotalWorkerTodayCnt() > 10 ? 'over10' : ''}`}>
                  {getTotalWorkerTodayCnt()}
                </span>
              </div>
            </div>
          </tfoot>
        </InputTable>
      </div>
    </Root>
  );
};
