import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  takeMedicineValue: any;
  setTakeMedicineValue: any;
  isViewMode: boolean;
  wCd: string;
  cdName: string;
  workerInputSet: any;
  setWorkerInputSet: any;
  propertyName: string;
}

const TakeMedicine = ({ takeMedicineValue, setTakeMedicineValue, isViewMode, wCd, cdName, workerInputSet, setWorkerInputSet, propertyName }: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const isEmpty =
    workerInputSet?.takeMedicine === '' //
      ? takeMedicineValue.filter((item: any) => item.check).length === 0
      : workerInputSet?.takeMedicine === '0';

  // 1. workerInpustSet 데이 받아와서 takeMedicineValue에 업데이트
  useEffect(() => {
    if (workerInputSet && workerInputSet?.takeMedicine !== undefined) {
      const splitData = workerInputSet?.takeMedicine?.split('/');
      const update = takeMedicineValue?.map((item: any) => {
        return {
          ...item,
          check: splitData?.includes(item.value),
        };
      });
      setTakeMedicineValue(update);
      setIsLoading(false);
    }
  }, [workerInputSet]);

  // 2. 초기화 완료 후 조작된 takeMedicineValue값을 workerInputSet에 업데이트
  useEffect(() => {
    if (!isLoading && takeMedicineValue !== undefined) {
      let value: string = '';
      let textValue: string = '';
      takeMedicineValue.forEach((item: any) => {
        if (item.check) {
          value = value !== '' ? `${value}/${item.value}` : `${item.value}`;
          textValue = textValue !== '' ? `${textValue}/${item.name}` : `${item.name}`;
        }
      });
      const forEachResult = value.length === 0 ? '0' : value;
      const forEachTextResult = textValue.length === 0 ? '0' : textValue;

      if (workerInputSet?.takeMedicine !== forEachResult) {
        setWorkerInputSet({
          ...workerInputSet, //
          takeMedicine: forEachResult,
          takeMedicineText: forEachTextResult,
        });
      }
    }
  }, [takeMedicineValue, isLoading]);

  const handleChange = (e: any) => {
    const { name } = e.target;
    setTakeMedicineValue((prev: any) => {
      return prev.map((item: any) => (item.value === name ? { ...item, check: !item.check } : item));
    });
  };

  const handleChangeAll = () => {
    const updatedValues = //
      takeMedicineValue.map((item: any) => ({ ...item, check: isEmpty }));
    setTakeMedicineValue(updatedValues);
  };

  return (
    <div className='control insuranceCheck'>
      <label htmlFor={wCd}>{t(cdName)}</label>
      <div className='flex-basic'>
        {!isLoading && (
          <>
            <div>
              <label htmlFor='noTakeMedicine'>
                <input
                  id='noTakeMedicine' //
                  type='checkbox'
                  checked={isEmpty}
                  onChange={handleChangeAll}
                  disabled={isViewMode}
                />
                {t('없음')}
              </label>
            </div>
            {takeMedicineValue?.map((item: any, index: number) => (
              <div key={`${item.name}_${index}`}>
                <label htmlFor={item.name}>
                  <input
                    id={item.name} //
                    name={item.value}
                    type='checkbox'
                    checked={item.check}
                    onChange={handleChange}
                    disabled={isViewMode}
                  />
                  {t(item.name)}
                </label>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default TakeMedicine;
