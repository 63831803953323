export const color: any = {
  blue: '#3E97FF',
  blue_60: 'rgba(62, 152, 255, 0.8)',
  blue_darker: '#0095E8',
  blue_lighter: '#F1FAFF',
  green: '#50CD89',
  green_60: 'rgba(72, 216, 158, 0.8)',
  green_darker: '#47BE7D',
  green_lighter: '#E8FFF3',
  orange: '#FFC700',
  orange_60: 'rgba(251, 146, 60, 0.8)',
  orange_darker: '#F1BC00',
  orange_lighter: '#FFF8DD',
  red: '#F1416C',
  red_60: 'rgba(244, 42, 63, 0.8)',
  red_darker: '#D9214E',
  red_lighter: '#FFF5F8',
  red_deep: '#b3000c',
  lightgray: '#DBDFE9',
  lightgray_darker: '#B5B5C3',
  lightgray_lighter: '#F9F9F9',
  darkgray: '#071437',
  darkgray_darker: '#050F29',
  darkgray_lighter: '#F1F1F2',
  gray_50: '#f9fafb',
  gray_100: '#f3f4f6',
  gray_200: '#e5e7eb',
  gray_300: '#d1d5db',
  gray_400: '#9ca3af',
  gray_500: '#6b7280',
  gray_600: '#4b5563',
  gray_700: '#374151',
  gray_800: '#1f2937',
  gray_900: '#111827',
  gray_950: '#030712',
  slate_50: '#f9fafb',
  slate_100: '#F9F9F9',
  slate_200: '#F1F1F2',
  slate_300: '#DBDFE9',
  slate_400: '#B5B5C3',
  slate_500: '#99A1B7',
  slate_600: '#78829D',
  slate_700: '#4B5675',
  slate_800: '#252F4A',
  slate_900: '#071437',
  slate_950: '#030712',
  zinc_50: '#fafafa',
  zinc_100: '#f4f4f5',
  zinc_200: '#e4e4e7',
  zinc_300: '#d4d4d8',
  zinc_400: '#a1a1aa',
  zinc_500: '#71717a',
  zinc_600: '#52525b',
  zinc_700: '#3f3f46',
  zinc_800: '#27272a',
  zinc_900: '#18181b',
  zinc_950: '#09090b',
  blue_50: '#eff6ff',
  blue_100: '#dbeafe',
  blue_200: '#bfdbfe',
  blue_300: '#93c5fd',
  blue_400: '#60a5fa',
  blue_500: '#3b82f6',
  blue_600: '#2563eb',
  blue_700: '#1d4ed8',
  blue_800: '#1e40af',
  blue_900: '#1e3a8a',
  blue_950: '#172554',
  green_50: '#f0fdf4',
  green_100: '#dcfce7',
  green_200: '#bbf7d0',
  green_300: '#86efac',
  green_400: '#4ade80',
  green_500: '#22c55e',
  green_600: '#16a34a',
  green_700: '#15803d',
  green_800: '#166534',
  green_900: '#14532d',
  green_950: '#052e16',
  amber_50: '#fffbeb',
  amber_100: '#fef3c7',
  amber_200: '#fde68a',
  amber_300: '#fde047',
  amber_400: '#fbbf24',
  amber_500: '#f59e0b',
  amber_600: '#d97706',
  amber_700: '#b45309',
  amber_800: '#92400e',
  amber_900: '#78350f',
  amber_950: '#451a03',
  red_50: '#fef2f2',
  red_100: '#fee2e2',
  red_200: '#fecaca',
  red_300: '#fca5a5',
  red_400: '#f87171',
  red_500: '#ef4444',
  red_600: '#dc2626',
  red_700: '#b91c1c',
  red_800: '#991b1b',
  red_900: '#7f1d1d',
  red_950: '#450a0a',
  rose_50: '#fff1f2',
  rose_100: '#ffe4e6',
  rose_200: '#fecdd3',
  rose_300: '#fda4af',
  rose_400: '#fb7185',
  rose_500: '#f43f5e',
  rose_600: '#e11d48',
  rose_700: '#be123c',
  rose_800: '#9f1239',
  rose_900: '#881337',
  rose_950: '#4c0519',
  violet_50: '#f5f3ff',
  violet_100: '#ede9fe',
  violet_200: '#ddd6fe',
  violet_300: '#c4b5fd',
  violet_400: '#a78bfa',
  violet_500: '#8b5cf6',
  violet_600: '#7c3aed',
  violet_700: '#6d28d9',
  violet_800: '#5b21b6',
  violet_900: '#4c1d95',
  violet_950: '#2e1065',
  pink_50: '#fdf2f8',
  pink_100: '#fce7f3',
  pink_200: '#fbcfe8',
  pink_300: '#f9a8d4',
  pink_400: '#f472b6',
  pink_500: '#ec4899',
  pink_600: '#db2777',
  pink_700: '#be185d',
  pink_800: '#9d174d',
  pink_900: '#831843',
  pink_950: '#500724',
  emerald_50: '#ecfdf5',
  emerald_100: '#d1fae5',
  emerald_200: '#a7f3d0',
  emerald_300: '#6ee7b7',
  emerald_400: '#34d399',
  emerald_500: '#10b981',
  emerald_600: '#059669',
  emerald_700: '#047857',
  emerald_800: '#065f46',
  emerald_900: '#064e3b',
  emerald_950: '#022c22',
  teal_50: '#f0fdfa',
  teal_100: '#ccfbf1',
  teal_200: '#99f6e4',
  teal_300: '#5eead4',
  teal_400: '#2dd4bf',
  teal_500: '#14b8a6',
  teal_600: '#0d9488',
  teal_700: '#0f766e',
  teal_800: '#115e59',
  teal_900: '#134e4a',
  teal_950: '#042f2e',
  yellow_50: '#fefce8',
  yellow_100: '#fef9c3',
  yellow_200: '#fef08a',
  yellow_300: '#fde047',
  yellow_400: '#facc15',
  yellow_500: '#eab308',
  yellow_600: '#ca8a04',
  yellow_700: '#a16207',
  yellow_800: '#854d0e',
  yellow_900: '#713f12',
  yellow_950: '#422006',
  orange_50: '#fff7ed',
  orange_100: '#ffedd5',
  orange_200: '#fed7aa',
  orange_300: '#fdba74',
  orange_400: '#fb923c',
  orange_500: '#f97316',
  orange_600: '#ea580c',
  orange_700: '#c2410c',
  orange_800: '#9a3412',
  orange_900: '#7c2d12',
  orange_950: '#431407',
  lime_50: '#f7fee7',
  lime_100: '#ecfccb',
  lime_200: '#d9f99d',
  lime_300: '#bef264',
  lime_400: '#a3e635',
  lime_500: '#84cc16',
  lime_600: '#65a30d',
  lime_700: '#4d7c0f',
  lime_800: '#3f6212',
  lime_900: '#365314',
  lime_950: '#1a2e05',
  sky_50: '#f0f9ff',
  sky_100: '#e0f2fe',
  sky_200: '#bae6fd',
  sky_300: '#7dd3fc',
  sky_400: '#38bdf8',
  sky_500: '#0ea5e9',
  sky_600: '#0284c7',
  sky_700: '#0369a1',
  sky_800: '#075985',
  sky_900: '#0c4a6e',
  sky_950: '#082f49',
  indigo_50: '#eef2ff',
  indigo_100: '#e0e7ff',
  indigo_200: '#c7d2fe',
  indigo_300: '#a5b4fc',
  indigo_400: '#818cf8',
  indigo_500: '#6366f1',
  indigo_600: '#4f46e5',
  indigo_700: '#4338ca',
  indigo_800: '#3730a3',
  indigo_900: '#312e81',
  indigo_950: '#1e1b4b',
  white: '#ffffff',
  black: '#000000',
};

export const light = {
  mode: 'light',
  background: '#FCFCFC',
  board: color.white,
  trans: `rgb(255, 255, 255)`,
  outline: color.zinc_200,
  outline_em: color.zinc_300,
  outline_status: `1px solid ${color.gray_200}`,
  tonal_light: color.zinc_50,
  tonal: color.gray_100,
  tonal_deep: color.gray_200,
  text_primary: color.zinc_900,
  text_secondary: color.zinc_600,
  text_tertiary: color.zinc_500,
  text_disabled: color.zinc_400,
  text_reverse: color.white,
  font_regular: '400',
  font_medium: '500',
  font_semibold: '600',
  font_bold: '700',
  outline_icon: `'FILL' 0, 'wght' 400`,
  scrollBar: color.zinc_200,
  tonal_green: '#f0fdf4',
  tonal_deep_green: color.green_100,
  tonal_blue: '#eef2ff',
  tonal_deep_blue: color.indigo_100,
  tonal_violet: color.violet_50,
  tonal_amber: color.amber_50,
  tonal_orange: '#fff7ed',
  tonal_red: '#fff5f8',
  tonal_deep_red: color.red_100,
  weather_bg: '#3e97ff',
  selected_primary: color.blue_500,
  selected_secondary: color.blue_500,
  selected_text: color.blue_600,
  btn_text: color.zinc_100,
  filled_blue: color.blue_500,
  filled_blue_light: color.blue_400,
  filled_blue_deep: color.blue_600,
  filled_blue_dark: color.blue_800,
  filled_red: color.rose_500,
  filled_red_deep: color.rose_600,
  filled_red_dark: color.rose_800,
  filled_green: color.emerald_500,
  filled_green_light: color.emerald_400,
  filled_green_deep: color.emerald_600,
  filled_orange: color.orange_500,
  filled_violet: color.violet_500,
  filled_violet_deep: color.violet_600,
  filled_pink: color.pink_500,
  filled_teal: color.teal_500,
  filled_amber: color.amber_500,
  filled_amber_light: color.amber_400,
  filled_amber_deep: color.amber_600,
  filled_yellow: color.yellow_500,
  filled_sky: color.sky_500,
  filled_indigo: color.indigo_500,
  filled_indigo_light: color.indigo_400,
  filled_indigo_deep: color.indigo_600,
  filled_lime: color.lime_500,
  filled_slate: color.slate_500,
  filled_slate_light: color.slate_400,
  filled_slate_deep: color.slate_600,
  badge_primary_text: color.blue_500,
  badge_primary_background: color.blue_50,
  bw: color.gray_900,
  bw_inverse: 'rgba(255,255,255, 0.9)',
  transBwReverse: 'rgba(0,0,0,0.3)',
  rdrDayEndPreview: 'multiply',
  alpha_15: 'rgba(255,255,255, 0.15)',
  alpha_25: 'rgba(255,255,255, 0.25)',
  alpha_50: 'rgba(255,255,255, 0.5)',
  alpha_75: 'rgba(255,255,255, 0.75)',
  inverse_alpha_25: 'rgba(0,0,0,0.25)',
  inverse_alpha_50: 'rgba(0,0,0,0.5)',
  inverse_alpha_75: 'rgba(0,0,0,0.75)',
  color: { ...color },
};

export const dark = {
  mode: 'dark',
  background: '#131313',
  board: '#1c1c1c',
  trans: `rgba(0, 0, 0, 0.75)`,
  outline: '#3f3f46',
  outline_em: color.zinc_600,
  outline_status: 'none',
  tonal_light: '#131313',
  tonal: color.zinc_800,
  tonal_deep: '#374151',
  text_primary: color.zinc_200,
  text_secondary: '#b5b5c3',
  text_tertiary: color.zinc_400,
  text_disabled: color.zinc_500,
  text_reverse: color.zinc_300,
  font_regular: '300',
  font_medium: '400',
  font_semibold: '500',
  font_bold: '600',
  outline_icon: `'FILL' 0, 'wght' 300`,
  scrollBar: '#4b5563',
  tonal_green: '#1c3238',
  tonal_deep_green: color.green_600,
  tonal_blue: '#212e48',
  tonal_deep_blue: color.blue_950,
  tonal_violet: color.violet_950,
  tonal_amber: color.amber_950,
  tonal_orange: '#332800',
  tonal_red: '#3a2434',
  tonal_deep_red: color.red_600,
  weather_bg: 'rgb(47 50 62)',
  selected_primary: '#0095E8',
  selected_secondary: '#0095E8',
  selected_text: color.blue_300,
  btn_text: color.zinc_200,
  filled_blue: color.blue_500,
  filled_blue_light: color.blue_500,
  filled_blue_deep: color.blue_600,
  filled_blue_dark: color.blue_700,
  filled_red: color.rose_700,
  filled_red_deep: color.rose_800,
  filled_red_dark: color.rose_600,
  filled_green: color.green_600,
  filled_green_light: color.green_500,
  filled_green_deep: color.green_700,
  filled_orange: color.orange_600,
  filled_violet: color.violet_500,
  filled_violet_deep: color.violet_600,
  filled_pink: color.pink_600,
  filled_teal: color.teal_600,
  filled_amber: color.amber_600,
  filled_amber_light: color.amber_500,
  filled_amber_deep: color.amber_700,
  filled_yellow: color.yellow_600,
  filled_sky: color.sky_600,
  filled_indigo: color.indigo_600,
  filled_indigo_light: color.indigo_500,
  filled_indigo_deep: color.indigo_700,
  filled_lime: color.lime_600,
  filled_slate: color.slate_600,
  filled_slate_light: color.slate_500,
  filled_slate_deep: color.slate_700,
  badge_primary_text: color.blue_200,
  badge_primary_background: color.blue_900,
  bw: color.gray_100,
  bw_inverse: color.gray_950,
  transBwReverse: 'rgba(255,255,255,0.3)',
  rdrDayEndPreview: 'lighten',
  alpha_15: 'rgba(0,0,0, 0.15)',
  alpha_25: 'rgba(0,0,0, 0.25)',
  alpha_50: 'rgba(0,0,0, 0.5)',
  alpha_75: 'rgba(0,0,0, 0.75)',
  inverse_alpha_25: 'rgba(255,255,255, 0.25)',
  inverse_alpha_50: 'rgba(255,255,255, 0.5)',
  inverse_alpha_75: 'rgba(255,255,255, 0.75)',
  color: { ...color },
};

export const theme = {
  light,
  dark,
};
export default theme;
