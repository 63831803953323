import { BtnBlue, BtnSkyBlue } from './Button';
import { createRoot } from 'react-dom/client';

class CustomAudioPlayBtnRenderer {
  element: any;
  root: any;

  constructor(props: any) {
    this.element = document.createElement('div');
    this.element.role = 'button';

    const { onClickPlayBtn, onClickStopBtn, t } = props.columnInfo.renderer.options;
    const { rowKey } = props;
    const { playing } = props.grid.store.data.rawData[rowKey];

    const root = createRoot(this.element);
    if (!playing) {
      root.render(
        <BtnBlue className='btn-in-cell btn-blue' type='button' onClick={() => onClickPlayBtn(rowKey)}>
          {t('재생')}
        </BtnBlue>
      );
    } else {
      root.render(
        <BtnSkyBlue className='btn-in-cell btn-gray' type='button' onClick={() => onClickStopBtn(rowKey)}>
          {t('중지')}
        </BtnSkyBlue>
      );
    }
  }

  getElement() {
    return this.element;
  }
  destroy() {
    this.root.unmount();
  }
}
export default CustomAudioPlayBtnRenderer;
