/**
 * 작성자 : 홍선영
 * 날짜 : 2023.07.17
 * 경로 : 현장소개 -> 현장조감도 (src/pages/s_info/S_info1)
 */

import { useRef, useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IModal } from 'customTypes';
import { pageInfoState, userState } from '../../../atoms';
import { ERROR } from '../../../_constants';
import { BtnBlue, BtnGhost, BtnRed } from '../../../components/Button';
import Portal from '../../../components/Portal';
import DeleteModal from '../../../components/Modal/DeleteModal2';
import { arraySortByAscdOrder } from '../../../utils/arraySortByAscdOrder';
import { readAndCompressImage } from 'browser-image-resizer';
import { logPost } from '../../../services/log';
import { useSetAuth } from '../../../utils/useSetAuth';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { useTranslation } from 'react-i18next';
import { apiDelete, apiGet, apiPost } from '../../../services/_common';

const ButtonsWrapper = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  justify-content: flex-end;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;
const Root = styled.div`
  overflow: hidden;
  .title div {
    height: 2.5rem;
    display: flex;
    align-items: center;
    gap: 0;
    /* color: ${({ theme }: { theme: any }) => theme.color}; */
    span.textOnly {
      padding: 0.25rem;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
    }
  }
  .buttonsWrapper {
    display: flex;
    justify-content: flex-end;
    padding-top: 0.5rem;
    .btn-small {
      min-width: 3rem;
      height: 2rem;
      border-radius: 0.25rem;
      font-size: 0.875rem;
    }
  }
  .slider {
    width: 100%;
    flex-grow: 1;
    overflow: auto;
    > div.slick-list {
      margin: 1rem 0;
      width: 100%;
      height: calc(100% - 16rem);
      min-height: 12rem;
      @media (min-width: 1024px) {
        height: calc(100% - 10rem);
      }
      div.slick-track {
        height: 100%;
        div {
          height: 100%;
        }
      }
    }
    > button {
      display: none !important;
    }
    img {
      object-fit: contain;
      object-position: center;
      height: 100%;
    }
    ul.slick-dots {
      margin: 1rem 0;
      display: flex;
      position: relative; /* Change this to position the dots relative to the carousel instead of absolute */
    }
    li {
      outline: 1px solid rgba(0, 0, 0, 0.1);
      width: 3rem;
      height: 3rem;
      background-color: gray;
      @media (min-width: 1024px) {
        width: 6rem;
        height: 4.5rem;
      }
    }
    .customPaging {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 1 / 1;
      }
    }
  }
  .editorWrapper {
    flex-grow: 1;
    overflow: auto;
    max-width: 64rem;
    /* max-width: 800px; */
    margin: 0 auto;
  }
  .thumbnailsWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > div {
      width: 20%;
      padding: 0.5rem;
      img {
        aspect-ratio: 1 /1;
      }
    }
  }

  .fileBoxWrapper {
    span {
      padding: 0 1rem;
      font-size: 0.875rem;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
    }
    .filesWrapper {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      background-color: rgba(0, 0, 0, 0.05);
      padding: 0.5rem;
      height: fit-content;
      /* border: 1px solid #f3f6f9; */
      border-radius: 5px;
      width: 100%;
      margin: 0.5rem;
      /* overflow-y: auto; */

      > div {
        font-size: 0.875rem;
        /* color: rgba(0, 0, 0, 0.8); */
        background-color: white;
        line-height: 2.5;
        padding: 0.5rem;
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        outline: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 0.25rem;
      }

      .btn-small {
        /* margin-left: 1rem; */
        width: 3rem;
        /* min-width: 5rem; */
        height: 1.75rem;
        font-size: 0.875rem;
        border-radius: 4px;
      }
    }
  }
`;

interface ISiteImage {
  imgSeq: number;
  imgFilename: string;
  imgBase64?: string;
  writer?: string;
  editor?: string;
}

// Compression configuration
const config = {
  quality: 0.7,
  maxWidth: 800,
  maxHeight: 600,
  autoRotate: true,
  debug: true,
};

const SiteView = () => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const pageInfo = useRecoilValue(pageInfoState);
  const [siteImageUrls, setSiteImageUrls] = useState<ISiteImage[]>([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef<Slider | null>(null);
  const [isEditable, setIsEditable] = useState(false);
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[] | []>([]);
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '' });
  const { auth } = useSetAuth();

  useEffect(() => {
    getSiteImagesAPI();
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '현장 조감도',
      action: '조회',
      etc: ``,
    });
  }, []);

  const customPaging = useCallback(
    (i: number) => {
      return (
        <a className='customPaging'>
          <img src={siteImageUrls[i].imgFilename} />
        </a>
      );
    },
    [siteImageUrls]
  );

  const settings = {
    customPaging,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current: number, next: number) => setCurrentSlide(next),
  };

  const getSiteImagesAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/site/siteImage', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const sortedArray = arraySortByAscdOrder(data.siteImage, 'imgSeq');
      setSiteImageUrls(sortedArray);
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  const onClickEdit = async () => {
    if (isEditable) {
      // 수정모드일때 저장API 요청
      if (selectedFiles.length > 0) {
        compressAndUploadImages();
      } else {
        toast.warning(t('선택된 파일이 없습니다'));
      }
    } else {
      setIsEditable((prev) => !prev);
    }
  };

  // 이미지 압축
  const compressAndUploadImages = async () => {
    try {
      const compressedImages = await Promise.all(selectedFiles.map((file) => readAndCompressImage(file, config)));
      // 압축한 파일을 base64로 변환
      const promises = compressedImages.map((file) => {
        return new Promise((resolve) => {
          const fileReader = new FileReader();

          fileReader.onload = function (e) {
            const base64String = e.target!.result as string;
            resolve({ hCd: userInfo.hCd, sCd: userInfo.sCd, imgSeq: 0, imgFilename: '', imgBase64: base64String.split(',')[1], writer: userInfo.userId, editor: userInfo.userId });
          };
          fileReader.readAsDataURL(file);
        });
      });

      const resultArray: any = await Promise.all(promises);
      postSiteImagesAPI(resultArray);
    } catch (error) {
      toast.error(t('이미지 압축 중 에러 발생 다시 시도하세요'));
      console.error(error);
    }
  };

  const postSiteImagesAPI = async (array: any) => {
    const req = { siteImageDto: array };
    const res = await apiPost({ path: '/site/siteImage', req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      setSiteImageUrls(data.siteImage);
      setIsEditable(false);
      setSelectedFiles([]);
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '현장 조감도',
        action: '저장',
        etc: ``,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  const onClickCancel = () => {
    setIsEditable(false);
    setSelectedFiles([]);
  };

  const deleteAPI = async (index: number, imgSeqParam: number) => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, imgSeq: imgSeqParam, editor: userInfo.userId };
    const res = await apiDelete({ path: '/site/siteImage', req });
    const { statusCode } = res.data;
    if (statusCode === 200) {
      toast.success(t('현장 이미지 삭제 성공'));
      setSiteImageUrls(siteImageUrls.filter((_, i) => i !== index));
      setOpenModal((prev) => ({ ...prev, status: false, type: 'delete' }));
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '현장 조감도',
        action: '삭제',
        etc: ``,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  const onClickDelete = (index: number, imgSeqParam: number) => {
    // DB 삭제요청
    setOpenModal((prev) => ({ ...prev, status: true, type: 'delete', api: () => deleteAPI(index, imgSeqParam) }));
  };

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;

    if (fileList) {
      const MAX_FILE_SIZE_MB = 5;
      const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // convert to bytes

      const fileListArray = Array.from(Object.values(fileList));
      const smallFiles = fileListArray.filter((file) => file.size <= MAX_FILE_SIZE_BYTES);

      if (fileListArray.length !== smallFiles.length) {
        toast.warning(t(`파일크기는 5MB를 초과할 수 없습니다`));
      }
      const combinedArray = [...selectedFiles, ...smallFiles];
      setSelectedFiles(combinedArray);
    }
  };

  const onClickDeleteSelectedFile = (index: number) => {
    // (저장하지 않은상태에서) 추가한 파일 제거
    setSelectedFiles(selectedFiles?.filter((_, i) => i !== index));
  };
  return (
    <Root>
      <SearchOptions align='left' className='border-bottom'>
        <div className='inputsWrapper'>
          <div className='secondSearchOption title'>
            {!isEditable ? (
              <div>
                <span className='textOnly'>{t('미리보기')}</span>
              </div>
            ) : (
              <div className='fileBoxWrapper flex-basic'>
                <input
                  className='hiddenFileBox'
                  style={{ width: '50%', lineHeight: '2' }}
                  type='file'
                  multiple
                  accept='image/*'
                  id='filebox'
                  name='dIfilename'
                  ref={inputFileRef}
                  onChange={handleFileInputChange}
                />
                <label htmlFor='filebox'>
                  <div className='fileButton' id='filebox'>
                    {t('파일선택')}
                  </div>
                </label>
                <span>{t('개별 이미지 당 5MB 까지 첨부 가능')}</span>
              </div>
            )}
          </div>
        </div>
      </SearchOptions>
      {!isEditable ? (
        <Slider {...settings} ref={sliderRef} className='slider'>
          {siteImageUrls.map((el: ISiteImage) => (
            <div key={el.imgSeq}>
              <img src={el.imgFilename} width='100%' />
            </div>
          ))}
        </Slider>
      ) : (
        <div className='editorWrapper '>
          {selectedFiles.length > 0 && (
            <div className='fileBoxWrapper'>
              <div className='filesWrapper'>
                {selectedFiles?.map((el, i) => (
                  <div key={`${el}_${i}`} className='flex-basic'>
                    <div>{el.name}</div>
                    <BtnRed className='btn-small' onClick={() => onClickDeleteSelectedFile(i)}>
                      {t('제거')}
                    </BtnRed>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className='thumbnailsWrapper'>
            {siteImageUrls.map((el: ISiteImage, i: number) => (
              <div key={el.imgSeq}>
                <img src={el.imgFilename} width='100%' />
                <div className='buttonsWrapper'>
                  <BtnRed className='btn-small' onClick={() => onClickDelete(i, el.imgSeq)}>
                    {t('삭제')}
                  </BtnRed>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <ButtonsWrapper>
        {isEditable && <BtnGhost onClick={onClickCancel}>{t('취소')}</BtnGhost>}
        {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={onClickEdit}>{isEditable ? t('저장') : t('수정')}</BtnBlue>}
      </ButtonsWrapper>
      <Portal openModal={openModal?.status}>{openModal.status && openModal.type === 'delete' && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
    </Root>
  );
};

export default SiteView;
