import React, { SetStateAction, useEffect, useState, Dispatch, useRef, useLayoutEffect } from 'react';
import { v1 } from 'uuid';
import { FLAG_CREATE_OR_UPDATE } from '../_constants';
import { useRecoilValue } from 'recoil';
import { userState } from '../atoms';
import { SelectBoxSmDropStyle } from '../assets/styles/SelectBoxSmDrop';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

interface ISelectBox {
  options: any; // 셀렉트박스 배열 ex => [{ type: 'useYn', useYn: 'Y', uName: '사용' }]
  defaultOption: string | number; // 셀렉트 디폴트옵션
  state: any; //  state값
  rawData: any; //  rawData state값
  setState: Dispatch<SetStateAction<any>>; //  useState set액션
  setRawData: Dispatch<SetStateAction<any>>; //  useState set액션
  stateKey: string; //  디비에 저장되는 데이터의 키값
  codeKey: string; // 화면에 보여지는 데이터의 키값
  index: number;
  object: any;
  primaryKey: string;
  initiateKey?: any; // initiateKey가 변경됐을 때 컴포넌트 리렌더
  disabled?: boolean; // 권한에 따라 셀렉트박스 선택 활성/비활성 시킬때
  width?: string;
  getBorderStyle?: any; // 선택값이 없을 때 보더 스타일
  optionHeight?: 'height-lg' | 'height-md' | 'height-base' | 'height-sm';
  spread?: boolean;
}

const SelectBoxs = ({
  options,
  defaultOption,
  state,
  rawData,
  setState,
  setRawData,
  stateKey,
  codeKey,
  index,
  object,
  primaryKey,
  initiateKey,
  disabled,
  width,
  getBorderStyle,
  optionHeight,
  spread,
}: ISelectBox) => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const userInfo = useRecoilValue(userState);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>({});
  const size = useOutletContext<any>();
  const [dropdownPosition, setDropdownPosition] = useState<'bottomToTop' | 'topToBottom' | null>(null);

  useEffect(() => {
    //  initiate key가 변경됐을 때 셀렉트옵션 초기화
    if (initiateKey) setSelectedOption({});
  }, [initiateKey]);

  useEffect(() => {
    const newItems = [...state]; // 뷰테이블 로우 어레이
    const rawDataArray: any = [...rawData]; // 데이터테이블 로우 어레이
    // 콤보박스 옵션을 선택했을 때
    if (Object.keys(selectedOption).length > 0) {
      let findIndex;
      if (object[primaryKey] !== '') {
        findIndex = rawData.findIndex((rawEl: any) => rawEl[primaryKey] === object[primaryKey]);
      } else {
        findIndex = object.index;
      }

      // const findIndex = rawData.findIndex((rawEl: any) => rawEl[primaryKey] === object[primaryKey]); // 데이터테이블에서 해당하는 로우의 인덱스값
      newItems[index] = { ...state[index], [stateKey]: selectedOption[selectedOption.type], flag: FLAG_CREATE_OR_UPDATE }; // 뷰테이블 로우 업데이트

      if (findIndex !== -1) {
        // 데이터테이블 로우 업데이트
        rawDataArray[findIndex] = { ...rawDataArray[findIndex], [stateKey]: selectedOption[selectedOption.type], flag: FLAG_CREATE_OR_UPDATE, editor: userInfo.userId };
      }
      setState(newItems);
      setRawData(rawDataArray);
    }
  }, [selectedOption, codeKey, stateKey]);

  // 콤보박스 바깥클릭 감지
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  // 콤보박스 열린상태에서 바깥 클릭했을 때 창닫기
  const handleClickOutside = (e: any) => {
    if (wrapperRef && !wrapperRef.current?.contains(e.target)) setIsDropdownOpen(false);
    else setIsDropdownOpen(true);
  };

  // 콤보박스 클릭시 옵션리스트 열기
  const onClickDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // 콤보박스에서 방향키 위아래로 움직였을 때 옵션이동
  // const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLDivElement>, option: any) => {
  //   const key = e.key || e.keyCode;
  //   if ((key === 'ArrowUp' || key === 38) && optionIndex > 0) {
  //     //  방향키 위
  //     setOptionIndex((prev) => prev - 1);
  //   }
  //   if ((key === 'ArrowDown' || key === 40) && optionIndex < option.length - 1) {
  //     //  방향키 아래
  //     setOptionIndex((prev) => prev + 1);
  //   }
  //   if (key === 'Enter' || key === 13) {
  //     //  엔터
  //     setSelectedOption(option[optionIndex]);
  //     setIsDropdownOpen(!isDropdownOpen);
  //   }
  // };

  //  메뉴배열 노출
  const renderAllDropdownMenu = () => {
    return options?.map((el: any, i: number) => (
      <li
        className={state[index][stateKey] === el[stateKey] ? 'selectedOption' : undefined}
        key={`${v1()}`}
        role='presentation'
        onClick={(event) => {
          if (size.innerSize.W < 1024) {
            event.stopPropagation();
          }
          setSelectedOption(el);
          setIsDropdownOpen(!isDropdownOpen);
        }}
      >
        {t(el[codeKey])}
      </li>
    ));
  };

  useLayoutEffect(() => {
    const updatePosition = () => {
      if (wrapperRef.current) {
        const rect = wrapperRef.current.getBoundingClientRect();
        if (window.innerHeight - 72 < rect.bottom) {
          setDropdownPosition('bottomToTop');
        } else {
          setDropdownPosition('topToBottom');
        }
      }
    };
    updatePosition();
  }, [isDropdownOpen]);

  return (
    <SelectBoxSmDropStyle isDropdownOpen={isDropdownOpen} className={`${spread ? 'spread' : ''}`} style={{ width }}>
      <ul style={getBorderStyle}>
        <li
          className={`defaultOption w100 ${disabled ? 'disabled' : ''}`}
          role='presentation'
          onClick={(event) => {
            if (size.innerSize.W < 1024) {
              event.stopPropagation();
            }
            onClickDropdown();
          }}
        >
          <span>{selectedOption[codeKey] === undefined || state[index][stateKey] ? defaultOption : t(selectedOption[codeKey])}</span>
          <div>
            <span className='material-symbols-rounded'>keyboard_arrow_down</span>
            {/* <IoChevronDownSharp /> */}
          </div>
        </li>
        {!disabled && (
          <div className={`optionBox ${isDropdownOpen ? 'on' : 'off'} ${dropdownPosition === 'bottomToTop' && 'bottomToTop'} ${optionHeight ?? ''}`} ref={wrapperRef}>
            {renderAllDropdownMenu()}
          </div>
        )}
      </ul>
    </SelectBoxSmDropStyle>
  );
};

export default React.memo(SelectBoxs);
