/**
 * 작성자 : 한영광
 * 날짜 : 2023.06.01
 * 기능 : 본사 현장정보/CCTV - 현장정보 탭
 */
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Root = styled.div`
  .inputForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem;

    .grid {
      margin-bottom: 0;
    }
  }

  padding: 0.5rem;
  -webkit-box-flex: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  overflow: auto;
  -webkit-box-pack: start;
  justify-content: flex-start;

  label {
    font-weight: semibold;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
  }

  .buttonsWrapper {
    justify-content: flex-end;
    padding: 1rem;
    .approvalBtn {
      margin-right: auto;
      margin-left: 0rem !important;
    }
  }

  input {
    :disabled {
      background-color: #eeeeee;
    }
  }

  .required {
    &::after {
      content: '*';
      padding: 0 0.2rem;
      color: red;
    }
  }

  .plainText > div {
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }

  .grid {
    display: grid;
    align-items: center;
    // min-height: 3.4rem;
  }

  .grid100,
  .grid100T,
  .grid60,
  .grid50,
  .grid30 {
    > div > div {
      display: grid;
      grid-template-columns: 9rem 1fr;
      align-items: center;
    }
  }

  .grid100T {
    grid-template-columns: 9rem 1fr;
  }

  .grid50,
  .grid60 {
    grid-template-columns: 1fr 1fr;
  }

  .grid50 {
    grid-gap: 1rem;
  }

  .grid30 {
    grid-template-columns: 1.5fr 0.7fr 0.8fr;
  }

  .plainText {
    grid-template-columns: 9rem 1fr;

    > :last-child {
      display: block;
    }
  }

  .marginBottomNone {
    margin-bottom: 0;
  }

  @media screen and (max-width: 1439px) {
    .plainText {
      grid-template-columns: 9rem 1fr;

      > div {
        display: block !important;
        margin-bottom: 0 !important;
      }
    }

    .grid50 {
      grid-template-columns: 1fr;
      > small {
        display: grid;
        grid-template-columns: 1fr;
      }

      > div > div {
      }
    }

    .grid60 {
      grid-template-columns: 0.7fr 0.3fr;
      > div > button {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 1023px) {
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    padding-top: 5rem;
    height: fit-content;
    overflow: none;
  }
`;

interface Iprops {
  siteInfo: any;
}
const SiteInfo = ({ siteInfo }: Iprops) => {
  const { t } = useTranslation();

  return (
    <Root>
      {!siteInfo.sCd ? (
        <div className='dataEmpty'>{t('현장을 선택하세요')}</div>
      ) : (
        <div className='inputForm'>
          <div className='grid grid100'>
            <div className='grid plainText marginBottomNone'>
              <label htmlFor='auth'>{t('코드')}</label>
              <div>{siteInfo.sCd}</div>
            </div>
          </div>
          <div className='grid grid100'>
            <div className='grid plainText marginBottomNone'>
              <label htmlFor='auth'>{t('현장명')}</label>
              <div>{siteInfo.sName}</div>
            </div>
          </div>
          <div className='grid grid100'>
            <div className='grid plainText marginBottomNone'>
              <label htmlFor='auth'>{t('현장 운영 상태')}</label>
              <div>{siteInfo.sStatus === 'Y' ? t('운영중') : t('종료')}</div>
            </div>
          </div>
          <div className='grid grid100'>
            <div className='grid plainText marginBottomNone'>
              <label htmlFor='auth'>{t('건설업종')}</label>
              <div>{siteInfo.sInfraName}</div>
            </div>
          </div>
          <div className='grid grid100'>
            <div className='grid plainText marginBottomNone'>
              <label htmlFor='auth'>{t('시공일자')}</label>
              <div>{siteInfo.sDate}</div>
            </div>
          </div>
          <div className='grid grid100'>
            <div className='grid plainText marginBottomNone'>
              <label htmlFor='auth'>{t('종료일자')}</label>
              <div>{siteInfo.fDate}</div>
            </div>
          </div>
          <div className='grid grid100'>
            <div className='grid plainText marginBottomNone'>
              <label htmlFor='auth'>{t('대표 전화번호')}</label>
              <div>{siteInfo.sTel}</div>
            </div>
          </div>
          <div className='grid grid100'>
            <div className='grid plainText marginBottomNone'>
              <label htmlFor='auth'>{t('주소')}</label>
              <div>
                {siteInfo.address1} {siteInfo.address2}
              </div>
            </div>
          </div>
          <div className='grid grid100'>
            <div className='grid plainText marginBottomNone'>
              <label htmlFor='auth'>{t('비고')}</label>
              <div>{siteInfo.bigo}</div>
            </div>
          </div>
          <div className='grid grid50'>
            <div className='grid plainText marginBottomNone'>
              <label htmlFor='auth'>{t('등록일자')}</label>
              <div>{siteInfo.wDate}</div>
            </div>
          </div>
          <div className='grid grid50'>
            <div className='grid plainText marginBottomNone'>
              <label htmlFor='auth'>{t('등록자')}</label>
              <div>{siteInfo.writer}</div>
            </div>
          </div>
          <div className='grid grid50'>
            <div className='grid plainText marginBottomNone'>
              <label htmlFor='auth'>{t('수정일자')}</label>
              <div>{siteInfo.eDate}</div>
            </div>
          </div>
          <div className='grid grid50'>
            <div className='grid plainText marginBottomNone'>
              <label htmlFor='auth'>{t('수정자')}</label>
              <div>{siteInfo.editor}</div>
            </div>
          </div>
        </div>
      )}
    </Root>
  );
};

export default SiteInfo;
