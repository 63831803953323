/**
 * 작성자 : 한영광
 * 날짜 : 2023.09.01
 * 경로 : 안전관리 / 게시판
 */

import Tab from '../../components/Tab';
import { useOutletContext } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { IUser, pageInfoState, userState } from '../../atoms';
import { useEffect, useState } from 'react';
import { ITabObject } from 'customTypes';
import Accident from './safe4/accident';
import Transfer from './safe4/transfer';
import Mainpoint from './safe4/mainpoint';
import ImageBoard from './safe4/imageboard';
import { logPost } from '../../services/log';

const Ssafe4 = () => {
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  const userInfo = useRecoilValue<IUser>(userState);
  const { userMenuList } = userInfo;
  const [newTabList, setNewTabList] = useState<ITabObject[]>();

  useEffect(() => {
    const arr: any[] = [];
    userMenuList.map((v: any) => {
      const result = tabInfo?.tabList?.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  const onClickTab = (mrCd: string) => {
    setCurrentTabMrCd(mrCd);
  };

  const renderPage = () => {
    const activeTab = newTabList?.find((el: ITabObject) => el.mrCd === currentTabMrCd);
    if (activeTab !== undefined) {
      const { mrCd } = activeTab;
      switch (mrCd) {
        case '001':
          return <Accident />;
        case '002':
          return <Transfer />;
        case '003':
          return <Mainpoint />;
        case '004':
          return <ImageBoard />;
      }
    }
    return undefined;
  };

  return (
    <div className='contents'>
      <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      {renderPage()}
    </div>
  );
};

export default Ssafe4;
