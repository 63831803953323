/**
 * 작성자 : 김광민
 * 날짜 : 2024.02.15
 * 기능 : 터널 관리 > 굴진/일반 정보 > 굴진/일반 정보 팝업 > CCTV 정보 탭 (현장용)
 */

import styled from 'styled-components';

const Root = styled.div`
  user-select: none;
  background-color: ${({ theme }: { theme: any }) => theme.tonal};
  border-radius: 0.5rem;
  width: 100%;
  height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.15s ease-in-out;
  .material-symbols-rounded {
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    font-variation-settings: 'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 24;
    font-size: 6rem;
    color: ${({ theme }: { theme: any }) => theme.outline_em};
    border-radius: 100%;
    font-size: 7rem;
    color: ${({ theme }: { theme: any }) => theme.text_disabled};
  }
  &:hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
    .material-symbols-rounded {
      background-color: ${({ theme }: { theme: any }) => theme.board};
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      box-shadow: 0 0 0 1rem inset ${({ theme }: { theme: any }) => theme.tonal_deep};
      &:hover {
        transform: scale(1.1);
      }
    }
  }
`;

type Props = { onClickPlay: () => void; height?: string };

const PlayVideoButton = ({ onClickPlay, height = '25rem' }: Props) => {
  return (
    <Root onClick={onClickPlay} role='presentation' style={{ height }}>
      <span className='material-symbols-rounded'>play_circle</span>
    </Root>
  );
};

export default PlayVideoButton;
