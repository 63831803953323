import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, CategoryScale, DoughnutController, Legend, Title, Tooltip } from 'chart.js';

const centerTextPlugin = {
  id: 'centerTextPlugin',
  beforeDraw: (chart: any) => {
    // Add this line to check if centerText is enabled.
    if (!chart?.options?.plugins?.elements?.center) return;

    // Get the needed variables from your chart
    const { ctx } = chart;
    const { text, color } = chart.options.plugins.elements.center;
    const { left, top, width, height } = chart.chartArea;

    const fontSizeToUse = Math.min(width, height) / 6;
    ctx.save();
    ctx.fillStyle = color;

    // Center the text both horizontally and vertically
    // const textX = left + width / 2 - ctx.measureText(text).width / 2;
    const textX = left + width / 2 - ctx.measureText(text).width - 10;
    const textY = top + height / 2 + 10;

    ctx.font = `${fontSizeToUse}px sans-serif`;
    ctx.fillText(text, textX, textY);
    ctx.restore();
  },
};

ChartJS.register(CategoryScale, ArcElement, DoughnutController, centerTextPlugin, Legend, Title, Title, Tooltip);

type DoughnutChartProps = {
  showLegend: boolean;
  percentage: number[];
  label: string[];
  colorSet: string[];
  centerText: boolean;
  circumference?: number;
  rotation?: number;
  cutout?: number;
  borderStatus?: boolean;
};

const DoughnutChart: React.FC<DoughnutChartProps> = ({ showLegend, percentage, label, centerText, colorSet, circumference = 360, rotation = 0, cutout = 50, borderStatus = false }) => {
  const borderType = () => {
    if (borderStatus === true) {
      return 'white';
    }
    return 'transparent';
  };

  const data = {
    labels: label,
    datasets: [
      {
        data: percentage,
        backgroundColor: colorSet,
        borderColor: borderType(),
      },
    ],
  };

  const options = {
    responsive: true,
    rotation,
    circumference,
    plugins: {
      legend: {
        display: showLegend,
        // position: 'right' as 'right',
        position: 'bottom' as 'right',
        // position: 'bottom',
      },
      tooltip: {
        enabled: true,
        // enabled: false,
      },
      elements: {
        center: centerText
          ? {
              text: `${percentage[0]}%`,
              color: '#3F4254', // Color of the center text
            }
          : null,
      },
    },
    cutout: `${cutout}%`,
  };

  return <Doughnut data={data} options={options} />;
  // return <Doughnut data={data} options={options} style={{ width: '100%', height: '100%' }} />;
};

export default DoughnutChart;
