/**
 * 작성자 : 홍선영
 * 날짜 : 2023.06.29
 * 기능 : 근로자검색 모달
 */

import { Dispatch, SetStateAction, useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import { IModal, IWorker } from 'customTypes';
import { userState } from '../../atoms';
import { LoadingModalBackground, Modal, ModalBackground } from '../../assets/styles/Modal';
import { BtnBlue, BtnGhost } from '../Button';
import Input from '../Input';
import { trimObject } from '../../utils/trimObject';
import { arraySortByAscdOrder } from '../../utils/arraySortByAscdOrder';
import SearchSelectBoxSm from '../SearchSelectBoxSm';
import Portal from '../Portal';
import TuiGrid from '../Table/TuiGrid';
import useOnKeydownF9 from '../../utils/useOnKeydownF9';
import i18n from '../../translation/i18n';
import BackButton from '../BackButton';
import { ERROR } from '../../_constants';
import { apiGet } from '../../services/_common';

interface ISearch {
  wName: string;
  sjCd: string;
  wPrejobtype?: string;
  wJobtype?: string;
}

interface IProps {
  openModal: IModal;
  setOpenModal: Dispatch<SetStateAction<IModal>>;
  state: IWorker[];
  setState: Dispatch<SetStateAction<any>>;
  isIncludeBlackUser: boolean; // 출입금지자도 근로자목록에 포함시킬 지 여부
  useCheckbox: boolean; // 체크박스 사용여부
  filter?: ISearch; // 모달에 전달하는 근로자명, 협력업체코드(검색옵션값을 미리 세팅해줄때 사용)
  dashboard?: boolean; // 대시보드모달여부
  onClickClose?: () => void;
}

const WorkerListModal = ({ openModal, setOpenModal, state, setState, isIncludeBlackUser, useCheckbox, filter, dashboard, onClickClose }: IProps) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const wNameRef = useRef<HTMLInputElement>(null);
  const [sjCdList, setSjCdList] = useState([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [checkList, setCheckList] = useState<any[]>([]);
  const [searchOption, setSearchOption] = useState({ sjCd: '', wName: '' });
  const [workerList, setWorkerList] = useState<IWorker[]>([]);
  const [selectedUser, setSelectedUser] = useState<any>({ selected: false, userInfo: {} });
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSiteJoinListAPI();
  }, []);

  useEffect(() => {
    if (filter) {
      let newFilter = filter;
      if (filter.wName) {
        newFilter = { ...filter, sjCd: '' };
        setLoading(true);
        getWorkerListAPI(newFilter); // 바로검색
      }
      setSearchOption(newFilter); // 검색조건값 세팅
    }
  }, [filter?.wName, filter?.sjCd]);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearchWorker();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  const getSiteJoinListAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/siteJoin', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      if (data.siteJoinList.length > 0) {
        const useYList = data.siteJoinList.filter((el: any) => el.useYn === 'Y');
        const formatSelectBoxFormList = useYList.map((el2: any, i: number) => {
          return { type: 'sjCd', sjCd: el2.sjCd, cdName: el2.sjName, cdSort: i + 1 };
        });

        const newObj = { type: 'sjCd', sjCd: '', cdName: t('전체'), cdSort: 0 };
        const addAllSelect: any = [...formatSelectBoxFormList, newObj];
        const sortedArray: any = arraySortByAscdOrder(addAllSelect, 'cdSort');
        setSjCdList(sortedArray);
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  const getWorkerListAPI = async (search: ISearch) => {
    const trimData = trimObject(search);
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, ...trimData };
    const res = await apiGet({ path: '/worker', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      setLoading(false);
      if (isIncludeBlackUser) {
        setWorkerList(
          data.workerList.map((prev: any) => {
            return {
              ...prev,
              blackYn: prev.wBlackyn === 'Y' ? t('출입금지') : t('출입가능'),
            };
          })
        );
      } else {
        // 출입금지자 아닌 유저들만
        const nonBlackUserList = data.workerList.filter((el: IWorker) => el.wBlackyn === 'N');
        setWorkerList(nonBlackUserList);
      }
    } else {
      setLoading(false);
      // toast.error(t(ERROR));
    }
  };

  useEffect(() => {
    setColumns(
      userInfo.prejobtypeYn === 'Y'
        ? [
            {
              header: t('근로자명'),
              name: 'wName',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('출입금지 여부'),
              name: 'blackYn',
              align: 'center',
              sortable: true,
              width: 100,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('협력업체'),
              name: 'sjName',
              sortable: true,
              width: 200,
              renderer: { classNames: ['text_secondary'] },
            },
            {
              header: t('공종'),
              name: 'wPrejobtypeName',
              sortable: true,
              minWidth: 120,
            },
            {
              header: t('직종'),
              name: 'wJobtypeName',
              sortable: true,
              minWidth: 120,
            },
          ]
        : [
            {
              header: t('근로자명'),
              name: 'wName',
              sortable: true,
              minWidth: 100,
            },
            {
              header: t('출입금지 여부'),
              name: 'blackYn',
              align: 'center',
              sortable: true,
              width: 120,
            },
            {
              header: t('협력업체'),
              name: 'sjName',
              sortable: true,
              minWidth: 100,
            },
            {
              header: t('직종'),
              name: 'wJobtypeName',
              sortable: true,
              minWidth: 100,
            },
          ]
    );
  }, [userInfo.prejobtypeYn, workerList, i18n.language]);

  // 근로자 Row 클릭
  const onClickRow = (rowData: any) => {
    if (!useCheckbox) {
      // 복수의 근로자를 선택하는 팝업이 아닐경우
      setSelectedUser({ selected: true, userInfo: rowData });
    }
  };

  // checkBox 클릭
  const onClickCheckBox = (rowKeyList: any) => {
    setCheckList(rowKeyList);
  };

  // 선택버튼 클릭
  const onClickSelectButton = () => {
    if (useCheckbox) {
      // 복수의 근로자를 선택한는 팝업일 경우
      if (checkList.length === 0) {
        toast.warning(t('선택한 근로자가 없습니다'));
      } else {
        // 검색에서 체크선택한 유저목록과 이미 선택되어있는 유저목록 합치기
        const combinedArray = [...state, ...checkList];

        const findBlackY = checkList.find((v: any) => v.wBlackyn === 'Y');
        if (findBlackY) {
          alert(t('이미 현장 출입이 금지된 근로자가 있습니다.'));
        } else {
          // 중복유저 삭제후 setState
          const filteredArray = combinedArray.reduce((acc, current) => {
            const isDuplicate = acc.find((item: any) => item.wCd === current.wCd);
            if (!isDuplicate) return acc.concat([current]);
            return acc;
          }, []);
          setState(filteredArray);
          toast.success(t('근로자가 추가되었습니다'));
        }
      }
    }

    if (!useCheckbox) {
      // 한명의 근로자만 선택하는 팝업에서 선택버튼을 클릭했을 경우
      if (!selectedUser.selected) {
        toast.warning(t('근로자를 선택하세요'));
      } else {
        setState(selectedUser.userInfo);
        setOpenModal((prev) => ({ ...prev, status: false }));
      }
    }
  };

  const onClickGoBack = () => {
    setOpenModal((prev) => ({ ...prev, status: false }));
  };

  const onClickSearchWorker = () => {
    setLoading(true);
    getWorkerListAPI({ sjCd: searchOption.sjCd, wName: searchOption.wName });
  };

  const searchOnEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (wNameRef.current) onClickSearchWorker();
    }
  };

  const findDefaultSjName = () => {
    if (searchOption.sjCd !== '') {
      const findObj: any = sjCdList.find((el: any) => el.sjCd === filter?.sjCd);
      if (findObj !== undefined) {
        return findObj.cdName;
      }
      return t('전체');
    }
    return t('전체');
  };
  const componentRef = useRef<HTMLDivElement>(null);
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight]);

  return (
    // <ModalBackground onClick={!loading ? onClickClose : () => {}} role='presentation'>
    <Modal onClick={!loading ? onClickClose : () => {}} role='presentation'>
      <div
        className='modal minHeight-609'
        role='presentation'
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {/* <div className='modal padding-bottom'> */}
        <div className='inputForm-head'>
          {openModal.isOverlappingModal && <BackButton func={() => onClickGoBack()} />}
          <div className='modalHeaderCenter'>{t('근로자 검색')}</div>
          {onClickClose && (
            <div className='closeBtn' onClick={onClickClose} role='presentation'>
              <span className='material-symbols-rounded'>close</span>
            </div>
          )}
        </div>
        {/* <div> */}
        <div className='filter-option-flex'>
          <SearchSelectBoxSm
            options={sjCdList}
            defaultOption={filter && filter.sjCd !== '' ? findDefaultSjName() : t('협력업체 전체')}
            state={searchOption}
            setState={setSearchOption}
            stateKey='sjCd'
            codeKey='cdName'
            optionHeight='height-sm'
            dropDownWidth='expand-content'
          />
          <div className='modal-element'>
            {/* <label htmlFor='wName'>근로자 명</label> */}
            <div className='inputWithBtnRow'>
              <Input
                className='col-w40'
                type='text'
                placeholder={t('근로자명')}
                // label='근로자 명'
                name='wName'
                state={searchOption}
                setState={setSearchOption}
                inputRef={wNameRef}
                onKeyDown={searchOnEnterKeyDown}
              />
              <BtnGhost onClick={onClickSearchWorker} className='searchBtn'>
                {t('검색')}
                <span className='shortcut-f9'>F9</span>
              </BtnGhost>
            </div>
          </div>
        </div>
        <div className='tuiGridWrapper tui-container overflow-none' ref={componentRef}>
          {useCheckbox ? (
            <TuiGrid
              data={workerList}
              columns={columns}
              perPage={15}
              usePagenation
              onClickRow={onClickRow}
              onClickCheckBox={onClickCheckBox}
              frozenCount={1}
              height={tuiHeight}
              scrollX
              visiblePages={5}
              rowClickCheckBox
              modal
            />
          ) : (
            <TuiGrid data={workerList} columns={columns} perPage={15} usePagenation onClickRow={onClickRow} frozenCount={1} height={tuiHeight} visiblePages={5} scrollX modal />
          )}
        </div>
        <div className='modal-footer flex-end'>
          <BtnBlue onClick={onClickSelectButton}>{t('선택')}</BtnBlue>
        </div>
      </div>
      <Portal openModal={loading}>
        <LoadingModalBackground>
          <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' style={{ width: 'inherit', height: 'inherit' }} />
        </LoadingModalBackground>
      </Portal>
    </Modal>
    // </ModalBackground>
  );
};

export default WorkerListModal;
