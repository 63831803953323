/**
 * 작성자 : 홍선영
 * 날짜 : 2023.03.29
 * 기능 : 정규식 체크해서 true/false 반환하는 함수들 모음 (이메일, 패스워드, 휴대폰)
 */

export const isEmailRegExpPass = (email: string) => {
  if (email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) {
    return true;
  }
  return false;
};

export const isPasswordRegExpPass = (password: string) => {
  if (password.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&].{8,20}$/)) {
    return true;
  }
  return false;
};

export const isPhoneNumRegExpPass = (phoneNum: string) => {
  if (phoneNum.match(/^\d{3}-\d{3,4}-\d{4}$/)) {
    return false;
  }
  return true;
};
