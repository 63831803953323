/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.15
 * 경로 : 대시보드 > 터널 종합 진행 현황
 */

import { useTranslation } from 'react-i18next';
import { PulseLoader } from 'react-spinners';
import React, { useEffect, useState } from 'react';
import { initialFilter, useFetchTareaData } from '../../services/Tunnel';
import MultiTunnelTotalInfo from '../tunnel/MultiTunnelTotalInfo';
import PlayButton from './PlayButton';
import TunnelLegends from '../tunnel/TunnelLegends';
import RefreshButton from './RefreshButton';
import { useRecoilValue } from 'recoil';
import { tInfoUpdateState } from '../../atoms';
import styled from 'styled-components';
import SmallIconButton from '../button/SmallIconButton';
import IssueGuide from '../IssueGuide';

const Root = styled.div`
  user-select: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  > .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
    padding: 0 1rem;
    > .title {
      flex-shrink: 0;
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.2;
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    .desktop-legends {
      display: none;
      @media (min-width: 768px) {
        display: flex;
      }
    }
    .mobile-legends {
      position: absolute;
      left: 0;
      top: 3.5rem;
      padding: 0.75rem;
      background-color: ${({ theme }: { theme: any }) => theme.board};
      box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.1);
      width: 100%;
      z-index: 100;
      outline: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      &.hidden {
        display: none;
      }
      > div {
        flex-wrap: wrap;
        row-gap: 0.5rem;
      }
    }
    > .icons {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .icon {
        &.hidden {
          display: none;
        }
      }
      .desktop-icon {
        display: none;
        @media (min-width: 768px) {
          display: flex;
        }
      }
      .mobile-icon {
        &.hidden {
          display: none;
        }
        @media (min-width: 768px) {
          display: none;
        }
      }
    }
  }
  > .content {
    flex-grow: 1;
    width: 100%;
    display: flex;
    padding: 0 0.5rem;
    margin-bottom: 0.5rem;
    overflow: hidden;
  }
`;

type Props = {
  name: string;
  userInfo: any;
  size?: 'medium' | 'large';
  visibleRefreshBtn?: boolean;
  uniqueKey: string;
};

// 터널 종합 진행 현황
const TunnelTotalInfo: React.FC<Props> = React.memo(({ name, userInfo, size = 'medium', visibleRefreshBtn = true, uniqueKey }) => {
  const { t } = useTranslation();
  const isTunnelInfoUpdated = useRecoilValue(tInfoUpdateState);
  const [autoPlay, setAutoPlay] = useState(visibleRefreshBtn === false);
  const [tunnelData, setTunnelData] = useState<{ tunnelName: string; useYn: string }[]>([]);
  const [isVisibleLegend, setIsVisibleLegend] = useState(false);

  const { data, isLoading, isError, isRefetching, refetch } = useFetchTareaData({ uniqueKey, dependencies: [isTunnelInfoUpdated], autoRefetch: !visibleRefreshBtn });

  useEffect(() => {
    const getTunnelLengths = (calculateData: any[]) => {
      // 터널의 시작점과 종점을 추출하여 배열로 만듭니다.
      const lengths = calculateData.flatMap((tunnel: any) => {
        const start = tunnel.sUseYn === 'Y' ? { tunnelName: tunnel.tatName, useYn: tunnel.sUseYn } : null;
        const end = tunnel.eUseYn === 'Y' ? { tunnelName: tunnel.tatName, useYn: tunnel.eUseYn } : null;
        return start && end ? [start] : [start, start];
      });

      // null 값이 아닌 것들만 필터링하여 반환합니다.
      return lengths.filter((item) => item !== null);
    };
    if (data) {
      const mutateData = getTunnelLengths(data);
      setTunnelData(mutateData as { tunnelName: string; useYn: string }[]);
    }
  }, [data]);

  // 새로고침 버튼 클릭 시
  const onClickRefresh = () => {
    setIsVisibleLegend(false);
    // 데이터 새로고침
    refetch();
  };

  if (isError) {
    console.error('API 호출 중 오류 발생:', data);
  }

  const renderContent = () => {
    if (isLoading || isRefetching || !tunnelData) {
      return (
        <div className='loader-background'>
          <PulseLoader color='rgb(0, 122, 255)' size='10px' />
        </div>
      );
    }

    if (!data || data.length === 0) {
      return <IssueGuide />;
    }

    return <MultiTunnelTotalInfo tunnelElements={initialFilter} getTunnelLengths={tunnelData} data={data} size={size} autoPlay={autoPlay} siteDashboard={!visibleRefreshBtn} />;
  };

  return (
    <Root>
      <div className='header'>
        <div className='title'>
          {t('터널 종합 진행 현황')}
          <div className='desktop-legends'>
            <TunnelLegends filter={initialFilter} />
          </div>
        </div>
        <div className={`mobile-legends ${isVisibleLegend ? 'visible' : 'hidden'}`}>
          <TunnelLegends filter={initialFilter} />
        </div>
        <div className='icons'>
          <div className='mobile-icon'>
            <SmallIconButton //
              icon={isVisibleLegend ? 'close' : 'format_list_bulleted'}
              onClick={() => setIsVisibleLegend(!isVisibleLegend)}
            />
          </div>
          {visibleRefreshBtn && (
            <>
              <div className='desktop-icon'>
                <PlayButton autoPlay={autoPlay} onClick={() => setAutoPlay(!autoPlay)} />
              </div>
              <div className={`icon ${isVisibleLegend ? 'hidden' : 'visible'}`}>
                <RefreshButton onClick={onClickRefresh} />{' '}
              </div>
            </>
          )}
        </div>
      </div>
      {renderContent()}
    </Root>
  );
});

TunnelTotalInfo.displayName = 'TunnelTotalInfo';

export default TunnelTotalInfo;
