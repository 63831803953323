/**
 * 작성자 : 김광민
 * 날짜 : 2023.11.29
 * 경로 : 센서 관리 - 다중 유해가스 측정기
 */
import { useEffect, useLayoutEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

import { IUser, pageInfoState, userState } from '../../atoms';
import { BtnGhost, BtnGray } from '../../components/Button';
import { ERROR, LIST_COUNT, SENSOR_TIME } from '../../_constants';
import { SearchOptions } from '../../assets/styles/SearchOptions';
import Tab from '../../components/Tab';
import { IComCdList, ISensorRange, ITabObject } from 'customTypes';
import { setComCdListState } from '../../utils/setComCdListState';
import { todayYYYYMMDD } from '../../utils/formatDate';
import Portal from '../../components/Portal';
import RangePicker, { onChangeRangeInput } from '../../components/RangePicker';
import dayjs from 'dayjs';
import LineChartModal from '../../components/Modal/lineChartModal';
import useOnKeydownF9 from '../../utils/useOnKeydownF9';
import { useSetAuth } from '../../utils/useSetAuth';
import { logPost } from '../../services/log';
import SelectBox from '../../components/SelectBox';
import illustrator from '../../assets/images/illustration/31.svg';
import nodataIllustrator from '../../assets/images/illustration/304.svg';
import i18n from '../../translation/i18n';
import { LoadingModalBackground } from '../../assets/styles/Modal';
import BackButton from '../../components/BackButton';
import useSetListCount from '../../utils/useSetListCount';
import TuiGridTable from '../../components/TuiGridTable';
import { useQuery } from '@tanstack/react-query';
import { apiGet } from '../../services/_common';
import ShortcutButton from '../../components/button/ShortcutButton';

const Root = styled.div`
  width: 100%;
  &.headerLeft {
    tbody {
      tr {
        th:last-child {
          text-align: left !important;
        }
      }
    }
  }
  &.hideRoot {
    visibility: hidden;
    position: absolute;
  }
  .tui-container {
    flex-grow: 1;
    overflow: hidden;
    margin-left: 0.5rem;
  }
  .loader {
    display: flex;
    width: 100%;
    height: 100%;
  }
  .emptyData {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    padding-bottom: 4rem;
    gap: 1rem;
    img {
      width: 14rem;
      /* user-drag: none; */
      -webkit-user-drag: none;
    }
    span {
      color: ${({ theme }: { theme: any }) => theme.filled_violet};
    }
  }
  .inputFormsWrapper {
    background-color: ${({ theme }: { theme: any }) => theme.board};
    border-radius: 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .searchContent {
    display: flex;
    justify-content: space-between;
    .buttonsWrapper {
      display: flex;
      margin-bottom: 0rem;
    }
  }

  .end {
    justify-content: end;
    > button {
      margin-left: 1rem;
    }
  }

  .thead > .tr {
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .spaceBetween {
      justify-content: space-evenly;
    }
  }

  .table {
    height: 64rem;
    padding: 0;

    > .tbody > .tr {
      padding: 0.4rem 1rem;
    }
  }

  .tab {
    padding: 0 1rem;
    cursor: pointer;
    height: 3rem;
  }

  .activeTab {
    font-weight: bold;
    border-bottom: 2px solid rgb(63, 66, 84);
  }

  .marginBottomNone {
    margin-bottom: 0;
  }

  .tui-grid-filter-dropdown select {
    box-sizing: border-box;
  }

  @media screen and (max-width: 767px) {
    .searchContent {
      flex-direction: column;
      > div {
        width: 100% !important;
      }
    }
  }
  .value {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    &::before {
      content: '●';
      font-size: 1.5rem;
      padding-top: 2px;
    }
    &.level {
      .value-title {
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      }
      &::before {
        color: transparent;
      }
    }
    &.level0 {
      .value-title {
        color: ${({ theme }: { theme: any }) => theme.filled_blue};
      }
      &::before {
        color: ${({ theme }: { theme: any }) => theme.filled_blue};
      }
    }
    &.level1 {
      .value-title {
        color: ${({ theme }: { theme: any }) => theme.filled_green};
      }
      &::before {
        color: ${({ theme }: { theme: any }) => theme.filled_green};
      }
    }
    &.level2 {
      .value-title {
        color: ${({ theme }: { theme: any }) => theme.filled_amber};
      }
      &::before {
        color: ${({ theme }: { theme: any }) => theme.filled_amber};
      }
    }
    &.level3 {
      .value-title {
        color: ${({ theme }: { theme: any }) => theme.filled_red};
      }
      &::before {
        color: ${({ theme }: { theme: any }) => theme.filled_red};
      }
    }
  }

  .secondSearchOption .guide.levels {
    flex-shrink: 0;
    display: flex;
    gap: 1rem;
    font-size: 0.875rem;
    margin: 0 0.5rem;
    @media (min-width: 768px) {
      margin-right: 1rem;
    }
    > div.value {
      > div {
        > span {
          line-height: 1rem;
          &:nth-child(2) {
            color: ${({ theme }: { theme: any }) => theme.text_tertiary};
          }
        }
      }
    }
  }

  .mgas-responsive {
    flex-wrap: nowrap;
    @media (min-width: 1024px) {
      flex-wrap: wrap;
      overflow: visible !important;
    }
  }
`;

const Ssensor7 = () => {
  const location = useLocation();
  const tabInfo = useRecoilValue(pageInfoState);
  const toDay = todayYYYYMMDD();
  const userInfo = useRecoilValue<IUser>(userState);
  const size = useOutletContext<any>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const INIT_F_NAME = { type: 'fName', fName: 'A', cdName: t('전체') };
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const { listCount, setListCount, patchUserMenuAPI } = useSetListCount(); // 검색줄수설정 훅
  const { userMenuList } = userInfo;
  const { defaultMrCd } = useRecoilValue(pageInfoState);
  const [rowState, setRowState] = useState<any>({});
  const [viewRowState, setViewRowState] = useState<boolean>(false);
  const [mainTableState, setMainTableState] = useState<any[]>([]);
  const [subTableState, setSubTableState] = useState<any[]>([]);
  const [exportTableState, setExportTableState] = useState<any[]>([]);
  const [orgTableState, setOrgTableState] = useState<any[]>([]);
  const [mainColumns, setMainColumns] = useState<any[]>([]);
  const [subColumns, setSubColumns] = useState<any[]>([]);
  const [exportColumns, setExportColumns] = useState<any[]>([]);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(defaultMrCd);
  const [visibleRangePicker, setVisibleRangePicker] = useState(false);
  const [listCountComCdList, setListCountComCdList] = useState<IComCdList[]>([]); // 검색줄수 공통코드
  const [fNameList, setFnameList] = useState<any[]>([]);
  const [fName, setFName] = useState(INIT_F_NAME);
  const [excelBtn, setExcelBtn] = useState(false);
  const [excelBtn2, setExcelBtn2] = useState(false);
  const [mainChartDataReverse, setMainChartDataReverse] = useState<any>([]);
  const [subChartDataReverse, setSubChartDataReverse] = useState<any>([]);
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '' });
  const [newTabList, setNewTabList] = useState<ITabObject[]>([]);
  const [loading, setLoading] = useState(false);
  const [mGasInfo, setMGasInfo] = useState({ type: '1', name: t('산소'), tableKey: { avg: 'avgO2', max: 'maxO2', min: 'minO2', value: 'O2', valueType: 'VOL' } });
  const [searchOption, setSearchOption] = useState({ start: toDay, end: toDay });
  const [sensorTime, setSensorTime] = useState<any>({ type: 'time', time: '1', cdName: t('시간') });
  const [rangeState, setRangeState] = useState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);

  // 현재 탭에 type과 name, table에 사용하는 key값 구하는 함수
  const getMgasInfo = (mrCd: string) => {
    switch (mrCd) {
      case '001':
        return { type: '1', name: t('산소'), tableKey: { avg: 'avgO2', max: 'maxO2', min: 'minO2', value: 'O2', valueType: 'VOL' }, status: '' };
      case '002':
        return { type: '2', name: t('이산화질소'), tableKey: { avg: 'avgNO2', max: 'maxNO2', min: 'minNO2', value: 'NO2', valueType: 'PPM' }, status: '' };
      case '003':
        return { type: '3', name: t('일산화탄소'), tableKey: { avg: 'avgCO', max: 'maxCO', min: 'minCO', value: 'CO', valueType: 'PPM' }, status: '' };
      case '004':
        return { type: '4', name: t('이산화탄소'), tableKey: { avg: 'avgCO2', max: 'maxCO2', min: 'minCO2', value: 'CO2', valueType: 'PPM' }, status: '' };
      case '005':
        return { type: '5', name: t('황화수소'), tableKey: { avg: 'avgH2S', max: 'maxH2S', min: 'minH2S', value: 'H2S', valueType: 'PPM' }, status: '' };
      case '006':
        return { type: '6', name: t('메탄'), tableKey: { avg: 'avgCH4', max: 'maxCH4', min: 'minCH4', value: 'CH4', valueType: 'LEL' }, status: '' };
      default:
        return { type: '1', name: t('산소'), tableKey: { avg: 'avgO2', max: 'maxO2', min: 'minO2', value: 'O2', valueType: 'VOL' }, status: '' };
    }
  };

  const [sensorStatus, setSensorStatus] = useState({ level1: 0, level2: 0, level3: 0, srCd: '' });
  const sensorRangeQuery = useQuery(['sensorRangeGet', userInfo.hCd, userInfo.sCd, mGasInfo.type], () => apiGet({ path: '/sensor/range', req: { hCd: userInfo.hCd, sCd: userInfo.sCd } }), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
  });

  // subtable 로더
  const [isLoading, setIsLoading] = useState(false);

  useLayoutEffect(() => {
    if (sensorRangeQuery.isSuccess && sensorRangeQuery.data.status === 200) {
      const sensorRangeList = sensorRangeQuery.data?.data.data.rangeList;
      if (sensorRangeList.length > 0) {
        const { level1, level2, level3, srCd } = sensorRangeList.filter((list: ISensorRange) => list.srCd === mGasInfo.tableKey.value)[0];
        setSensorStatus({ level1: level1 ? Number(level1) : 0, level2: level2 ? Number(level2) : 0, level3: level3 ? Number(level3) : 0, srCd });
      }
    }
  }, [sensorRangeQuery.isSuccess, sensorRangeQuery.isRefetching]);

  const mainDataSet = [
    {
      label: t('평균'),
      data: `${getMgasInfo(currentTabMrCd).tableKey.avg}`,
      borderColor: 'rgba(0, 221, 255, 0.5)',
      backgroundColor: 'rgba(0, 221, 255, 0.5)',
    },
    {
      label: t('최소'),
      data: `${getMgasInfo(currentTabMrCd).tableKey.min}`,
      borderColor: 'rgba(0, 119, 255, 0.5)',
      backgroundColor: 'rgba(0, 119, 255, 0.5)',
    },
    {
      label: t('최대'),
      data: `${getMgasInfo(currentTabMrCd).tableKey.max}`,
      borderColor: 'rgba(0, 0, 255, 0.5)',
      backgroundColor: 'rgba(0, 0, 255, 0.5)',
    },
  ];

  const subDataSet = [
    {
      label: t(mGasInfo.name),
      data: `${mGasInfo.tableKey.value}`,
      borderColor: 'rgba(0, 221, 255, 0.5)',
      backgroundColor: 'rgba(0, 221, 255, 0.5)',
    },
  ];

  useEffect(() => {
    const arr: any[] = [];
    userMenuList.map((v: any) => {
      const result = tabInfo?.tabList.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  useEffect(() => {
    if (!rangeState[0].startDate) {
      return;
    }
    setSearchOption((prev: any) => ({ ...prev, start: dayjs(rangeState[0].startDate).format('YYYY-MM-DD') }));
  }, [rangeState[0].startDate]);

  useEffect(() => {
    if (!rangeState[0].endDate) {
      return;
    }
    setSearchOption((prev: any) => ({ ...prev, end: dayjs(rangeState[0].endDate).format('YYYY-MM-DD') }));
  }, [rangeState[0].endDate]);

  const tuiFormatter = (value: any) => {
    const localeStringValue = Number(value.value)?.toLocaleString();
    return localeStringValue;
  };

  useEffect(() => {
    const defaultExportColumns = [
      {
        header: t('장비명'),
        name: 'dvName',
        align: 'left',
        sortable: true,
        width: 120,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('측정일자/시간'),
        name: 'checkTime',
        align: 'center',
        sortable: true,
        minWidth: 140,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: `${mGasInfo.tableKey.valueType}`,
        name: `${mGasInfo.tableKey.value}`,
        align: 'center',
        sortable: true,
        filter: 'number',
        minWidth: 120,
      },
    ];
    setMainColumns([
      {
        header: t('장비명'),
        name: 'dvName',
        align: 'left',
        sortable: true,
        filter: 'select',
        width: 120,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('측정일자'),
        name: 'checkTime',
        align: 'center',
        sortable: true,
        filter: 'select',
        minWidth: 120,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('평균'),
        name: `${mGasInfo.tableKey.avg}`,
        align: 'center',
        sortable: true,
        filter: 'number',
        minWidth: 120,
      },
      {
        header: t('최소'),
        name: `${mGasInfo.tableKey.min}`,
        align: 'center',
        sortable: true,
        filter: 'number',
        minWidth: 120,
      },
      {
        header: t('최대'),
        name: `${mGasInfo.tableKey.max}`,
        align: 'center',
        sortable: true,
        filter: 'number',
        minWidth: 120,
      },
    ]);
    setSubColumns([
      {
        header: t('장비명'),
        name: 'dvName',
        align: 'left',
        sortable: true,
        width: 120,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('측정일자/시간'),
        name: 'checkTime',
        align: 'center',
        sortable: true,
        minWidth: 140,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: `${mGasInfo.tableKey.valueType}`,
        name: `${mGasInfo.tableKey.value}_color`,
        align: 'center',
        sortable: false,
        width: 40,
      },
      {
        header: ` `,
        name: `${mGasInfo.tableKey.value}`,
        align: 'left',
        sortable: true,
        filter: 'number',
        width: 120,
        formatter: (value: string) => tuiFormatter(value),
      },
    ]);

    // subtable에서 보기 설정에 따라 엑셀 다운로드시 상태 값 넣어줄지 뺄지 정하는 로직
    if (sensorTime.time !== '1') {
      setExportColumns([
        ...defaultExportColumns,
        {
          header: t('상태'),
          name: `${mGasInfo.tableKey.value}_status`,
          align: 'center',
          sortable: true,
          filter: 'number',
          minWidth: 120,
        },
      ]);
    } else {
      setExportColumns([...defaultExportColumns]);
    }
    setSubTableState(
      orgTableState.map((v: any) => {
        return {
          ...v,
          checkTime: `${v.checkTime}${t('시')}`,
        };
      })
    );
  }, [i18n.language, mGasInfo.type, sensorTime.time]);

  useEffect(() => {
    if (viewRowState) setViewRowState(false);
    setLoading(true);
    getSensorAPI(currentTabMrCd).then((res: any) => {
      if (res.status === 200) {
        getNormalCdAPI();
        setComCdListState(LIST_COUNT, setListCountComCdList, false);
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: `${mGasInfo.name} 측정기`,
          action: '조회',
          etc: ``,
        });
      }
    });
  }, [currentTabMrCd, fName.fName]);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    if (Object.keys(rowState).length !== 0) getSensorDayAPI(rowState);
  }, [rowState, sensorTime]);

  // 센서 리스트 조회 API
  const getSensorAPI = async (mrCd: string) => {
    const { start, end } = searchOption;
    const req = {
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      dvNo: fName.fName === 'A' ? '' : fName.fName,
      date1: start.replaceAll('-', ''),
      date2: end.replaceAll('-', ''),
      type: getMgasInfo(mrCd).type,
    };
    const res = await apiGet({ path: '/sensor/mgas', req });
    const { data, statusCode } = res.data !== undefined && res.data;
    if (statusCode === 200) {
      setMGasInfo(getMgasInfo(mrCd));
      setLoading(false);
      setMainTableState(mutateData(data));
      const dvList = toDvList(mutateData(data));
      const newData = dvList.map((v: any) => {
        return mutateData(data).filter((v2: any) => v2.dvNo === v.dvNo);
      });
      setMainChartDataReverse(newData.map((v3: any) => v3.reverse()));
    } else {
      setLoading(false);
      // toast.error(t(ERROR));
    }
    return res;
  };

  const mutateData = (data: any) => {
    const result = data.map((element: any) => ({
      ...element,
      avgCH4: element.avgCH4,
      avgCO: element.avgCO,
      avgCO2: element.avgCO2,
      avgData7: element.avgData7,
      avgData8: element.avgData8,
      avgData9: element.avgData9,
      avgData10: element.avgData10,
      avgH2S: element.avgH2S,
      avgNO2: element.avgNO2,
      avgO2: element.avgO2,
      maxCH4: element.maxCH4,
      maxCO: element.maxCO,
      maxCO2: element.maxCO2,
      maxData7: element.maxData7,
      maxData8: element.maxData8,
      maxData9: element.maxData9,
      maxData10: element.maxData10,
      maxH2S: element.maxH2S,
      maxNO2: element.maxNO2,
      maxO2: element.maxO2,
      minCH4: element.minCH4,
      minCO: element.minCO,
      minCO2: element.minCO2,
      minData7: element.minData7,
      minData8: element.minData8,
      minData9: element.minData9,
      minData10: element.minData10,
      minH2S: element.minH2S,
      minNO2: element.minNO2,
      minO2: element.minO2,
    }));
    return result;
  };

  // 장비 리스트 조회 API
  const getNormalCdAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, grCd: '08' };
    const res = await apiGet({ path: '/sensor/detail', req });
    const { data, statusCode } = res.data !== undefined && res.data;
    if (statusCode === 200) {
      const newList = [];
      newList.push(INIT_F_NAME);
      data.sensorList.map((v: any) => {
        newList.push({ type: 'fName', fName: v.subCd, cdName: v.sName });
      });
      setFnameList(newList);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 센서 일별 조회 API
  const getSensorDayAPI = async (rowData: any) => {
    const req = {
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      dvNo: rowData.dvNo,
      date: rowData.checkTime?.replaceAll('-', ''),
      time: sensorTime.time,
      type: mGasInfo.type,
    };
    const res = await apiGet({ path: '/sensor/mgasDay', req });
    const getColor = (value: string) => {
      if (value === '0') return 'level0';
      if (value === '1') return 'level1';
      if (value === '2') return 'level2';
      if (value === '3') return 'level3';
      return 'level';
    };

    const getStatus = (value: string) => {
      if (value === '0') return t('좋음');
      if (value === '1') return t('주의');
      if (value === '2') return t('경고');
      if (value === '3') return t('위험');
      return '';
    };

    const mutateSubData = (data: any) => {
      const result = data.map((element: any) => ({
        ...element,
        CH4: element.CH4,
        CO: element.CO,
        CO2: element.CO2,
        H2S: element.H2S,
        NO2: element.NO2,
        O2: element.O2,
      }));
      return result;
    };
    const { data, statusCode } = res.data !== undefined && res.data;
    if (statusCode === 200) {
      if (sensorTime.time === '1') {
        setOrgTableState(mutateSubData(data));
        setSubTableState(
          mutateSubData(data).map((v: any) => {
            return {
              ...v,
              checkTime: `${v.checkTime}${t('시')}`,
              O2: `${v.O2}`,
              NO2: `${v.NO2}`,
              CO: `${v.CO}`,
              CO2: `${v.CO2}`,
              H2S: `${v.H2S}`,
              CH4: `${v.CH4}`,
            };
          })
        );
        setExportTableState(
          mutateSubData(data).map((v: any) => {
            return {
              ...v,
              checkTime: `${v.checkTime}${t('시')}`,
            };
          })
        );
        setSubChartDataReverse([data.reverse()]);
      } else {
        setSubTableState(
          data.map((v: any) => {
            return {
              ...v,
              O2_color: `<span class='value ${getColor(v.levelO2)}' />`,
              NO2_color: `<span class='value ${getColor(v.levelNO2)}' />`,
              CO_color: `<span class='value ${getColor(v.levelCO)}' />`,
              CO2_color: `<span class='value ${getColor(v.levelCO2)}' />`,
              H2S_color: `<span class='value ${getColor(v.levelH2S)}' />`,
              CH4_color: `<span class='value ${getColor(v.levelCH4)}' />`,
              O2: v.O2,
              NO2: v.NO2,
              CO: v.CO,
              CO2: v.CO2,
              H2S: v.H2S,
              CH4: v.CH4,
            };
          })
        );
        setExportTableState(
          data.map((v: any) => {
            return {
              ...v,
              O2_status: `${getStatus(v.levelO2)}`,
              NO2_status: `${getStatus(v.levelNO2)}`,
              CO_status: `${getStatus(v.levelCO)}`,
              CO2_status: `${getStatus(v.levelCO2)}`,
              H2S_status: `${getStatus(v.levelH2S)}`,
              CH4_status: `${getStatus(v.levelCH4)}`,
            };
          })
        );
        setSubChartDataReverse([data.reverse()]);
      }
    } else {
      toast.error(t(ERROR));
    }
  };

  const toDvList = (data: any[]) => {
    const map = new Map(data.map((m: any) => [m.dvNo, m])).entries();
    return Array.from(map).map((v: any) => {
      return { dvNo: v[1].dvNo, dvName: v[1].dvName };
    });
  };

  // 검색 버튼 클릭
  const onClickSearch = () => {
    if (!openModal.status) {
      if (searchOption.end && searchOption.start > searchOption.end) {
        toast.warning(t('날짜를 다시 입력하세요'));
        setSearchOption((prev: any) => ({ ...prev, end: '' }));
      } else {
        setLoading(true);
        setRowState({});
        setSubTableState([]);
        setVisibleRangePicker(false);
        setViewRowState(false);
        setRowState({});
        navigate({
          pathname: location.pathname,
          search: '',
        });
        getSensorAPI(currentTabMrCd);
      }
    }
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    Object.keys(searchOption).map((el: any) => {
      return setSearchOption((prev: any) => ({ ...prev, [el]: '', start: toDay, end: toDay }));
    });
    setFName(INIT_F_NAME);
    setRangeState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
    setViewRowState(false);
    setSubTableState([]);
  };

  // 차트 버튼 클릭
  const onClickChart = ({ tableData, type }: { tableData: any[]; type: any }) => {
    if (tableData.length !== 0) setOpenModal({ status: true, type });
    else toast.warning(t('데이터가 없습니다'));
  };

  // 엑셀 저장 버튼 클릭
  const onClickExcelSave = () => {
    if (mainTableState.length <= 0) return;
    setExcelBtn(true);
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: `${mGasInfo.name} 측정기`,
      action: '엑셀 저장',
      etc: `${searchOption.start}~${searchOption.end}`,
    });
  };

  // 엑셀 저장 버튼 클릭
  const onClickExcelSave2 = () => {
    if (subTableState.length <= 0) return;
    setExcelBtn2(true);
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: `${mGasInfo.name} 측정기`,
      action: '엑셀 저장',
      etc: `${rowState.checkTime}`,
    });
  };

  // 인쇄 버튼 클릭
  const onClickPrint = () => {
    //   const handlePrint = useReactToPrint({
    //     content: () => componentRef.current,
    //   });
    // handlePrint();
  };

  // 엑셀 버튼
  const renderExcelBtn = (onClickBtn: any) => {
    if (auth.excelAuth) {
      return (
        <BtnGhost onClick={onClickBtn}>
          <span className='icon-ms-xlsx'>X</span> {t('엑셀')}
        </BtnGhost>
      );
    }
    return null;
  };

  // 프린트 버튼
  const renderPrintBtn = (clickPrint: any) => {
    if (auth.printAuth) {
      return <BtnGray onClick={clickPrint}>{t('인쇄')}</BtnGray>;
    }
    return null;
  };

  // 측정기 Row 클릭
  const onClickRow = async (rowData: any): Promise<void> => {
    setIsLoading(true);
    if (rowData) {
      await setRowState(rowData);
      setViewRowState(true);
    }
    setIsLoading(false);
  };

  const onClickRangeInput = () => {
    if (!visibleRangePicker) setVisibleRangePicker(true);
  };

  const onClickTab = (tab: string) => {
    setSensorTime({
      type: 'time',
      time: '1',
      cdName: t('시간'),
    });
    setViewRowState(false);
    setRowState({});
    setCurrentTabMrCd(tab);
  };

  // subTable 상단 평균/최소/최대 값을 구하는 함수
  const getRowStateInfo = (key: string) => {
    const { value } = mGasInfo.tableKey;
    if (key === 'avg' && value === 'O2') return rowState.avgO2;
    if (key === 'min' && value === 'O2') return rowState.minO2;
    if (key === 'max' && value === 'O2') return rowState.maxO2;
    if (key === 'avg' && value === 'NO2') return rowState.avgNO2;
    if (key === 'min' && value === 'NO2') return rowState.minNO2;
    if (key === 'max' && value === 'NO2') return rowState.maxNO2;
    if (key === 'avg' && value === 'CO') return rowState.avgCO;
    if (key === 'min' && value === 'CO') return rowState.minCO;
    if (key === 'max' && value === 'CO') return rowState.maxCO;
    if (key === 'avg' && value === 'CO2') return rowState.avgCO2;
    if (key === 'min' && value === 'CO2') return rowState.minCO2;
    if (key === 'max' && value === 'CO2') return rowState.maxCO2;
    if (key === 'avg' && value === 'H2S') return rowState.avgH2S;
    if (key === 'min' && value === 'H2S') return rowState.minH2S;
    if (key === 'max' && value === 'H2S') return rowState.maxH2S;
    if (key === 'avg' && value === 'CH4') return rowState.avgCH4;
    if (key === 'min' && value === 'CH4') return rowState.minCH4;
    if (key === 'max' && value === 'CH4') return rowState.maxCH4;
    return null;
  };

  const getFileName = (tableType: 'main' | 'sub') => {
    const type = mGasInfo.name;
    const detail = sensorTime.time === '1' ? t('시간') : t('상세');
    const startDate = dayjs(rangeState[0].startDate).format('YYYY-MM-DD');
    const endDate = dayjs(rangeState[0].endDate).format('YYYY-MM-DD');
    const period = startDate === endDate ? startDate : `${startDate}~${endDate}`;

    if (tableType === 'sub') return `${type}(${detail})_${rowState.checkTime}`;
    return `${type}(${t('종합')})_${period}`;
  };

  const renderSubTable = () => {
    // 브라우저 가로폭이 1024보다 작거나 viewrowstate가 false일때
    if (!(size.innerSize.W >= 1024 || viewRowState)) {
      return null;
    }

    // viewrowstate가 false이고 왼쪽 데이터가 있을때
    if (!viewRowState && mainTableState.length !== 0) {
      return (
        <Root>
          <div className='emptyData'>
            <img src={illustrator} alt='noData' />
            <span>{t('왼쪽 목록에서 센서를 선택해주세요')}</span>
          </div>
        </Root>
      );
    }

    // 왼쪽 데이터가 없을때
    if (!viewRowState && mainTableState.length === 0) {
      return <Root />;
    }

    // loading 중일때
    if (isLoading) {
      return (
        <Root>
          <div className='loader'>
            <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' style={{ width: 'inherit', height: 'inherit' }} />
          </div>
        </Root>
      );
    }

    // 브라우저 가로폭이 1024보다 크거나 viewrowstate가 true일때
    return (
      <Root className='headerLeft'>
        <SearchOptions align='left'>
          <div className='inputsWrapperGroup'>
            {size.innerSize.W < 1024 && <BackButton func={() => setViewRowState(false)} />}
            <div className='inputsWrapper sensor-results'>
              <div className='sensor-result-header'>
                <span>{t('측정일')}</span>
                <div>{rowState.checkTime}</div>
              </div>
              <div className='sensor-result-data'>
                <span>{t(mGasInfo.name)}</span>
                <div>
                  <span>{t('평균')}</span>
                  {getRowStateInfo('avg')}
                </div>
                <div>
                  <span>{t('최소')}</span>
                  {getRowStateInfo('min')}
                </div>
                <div>
                  <span>{t('최대')}</span>
                  {getRowStateInfo('max')}
                </div>
              </div>
            </div>
          </div>
          <div className='inputsWrapper sensorOverflow'>
            <div className='secondSearchOption mgas-responsive'>
              <div className='flex-basic shrink-0'>
                <SelectBox options={SENSOR_TIME} defaultOption={sensorTime.cdName} state={sensorTime} setState={setSensorTime} stateKey='time' />
              </div>
              {sensorTime.time !== '1' && (
                <div className='guide levels'>
                  <div className='value level0 flex-basic'>
                    <div className='flex-col'>
                      <span className='value-title'>{t('좋음')}</span>
                      {sensorStatus.srCd !== 'O2' && (
                        <span>
                          {sensorStatus.level1} {t('미만')}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='value level1 flex-basic'>
                    <div className='flex-col'>
                      <span className='value-title'>{t('주의')}</span>
                      {sensorStatus.srCd !== 'O2' && (
                        <span>
                          {sensorStatus.level1} {t('이상')}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='value level2 flex-basic'>
                    <div className='flex-col'>
                      <span className='value-title'>{t('경고')}</span>
                      {sensorStatus.srCd !== 'O2' && (
                        <span>
                          {sensorStatus.level2} {t('이상')}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='value level3 flex-basic'>
                    <div className='flex-col'>
                      <span className='value-title'>{t('위험')}</span>
                      {sensorStatus.srCd !== 'O2' && (
                        <span>
                          {sensorStatus.level3} {t('이상')}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className='flex-basic iconBtnGroup'>
                <BtnGhost onClick={() => onClickChart({ tableData: subTableState, type: 'lineChart2' })}>
                  <span className='material-symbols-rounded'>monitoring</span>
                  {t('차트')}
                </BtnGhost>
                {renderExcelBtn(onClickExcelSave2)}
                {/* {renderPrintBtn(onClickPrint2)} */}
              </div>
            </div>
          </div>
        </SearchOptions>
        <TuiGridTable tableState={subTableState} columns={subColumns} listCount={Number(listCount[LIST_COUNT])} />
        <div className='hide'>
          <TuiGridTable tableState={exportTableState} columns={exportColumns} listCount={Number(listCount[LIST_COUNT])} setExcelBtn={setExcelBtn2} excelBtn={excelBtn2} fileName={getFileName('sub')} />
        </div>
      </Root>
    );
  };

  return (
    <div className='contents'>
      <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      <div className={`content-container ${size.innerSize.W >= 1024 ? 'twoColumn column-55 mediaSet768' : 'oneColumn'}`}>
        <Root className={size.innerSize.W >= 1024 || !viewRowState ? 'showRoot' : 'hideRoot'}>
          <SearchOptions>
            <div className='inputsWrapper'>
              <div className='inputForm-col withLabelComCf'>
                <label htmlFor='fName'>{t('장비')}</label>
                <SelectBox
                  options={fNameList}
                  defaultOption={t('전체')}
                  state={fName}
                  setState={setFName}
                  stateKey='fName'
                  filterbar='filter-1-left'
                  initiateKey={fName.fName}
                  optionHeight='height-md'
                />
              </div>
              <div className='inputForm-row-fit'>
                <span className='calendarLabel'>{t('조회일자')}</span>
                <div className='inputForm-col'>
                  <div className='flex-basic'>
                    <input id='startInput' type='text' value={searchOption.start} onMouseDown={onClickRangeInput} onChange={(e) => onChangeRangeInput(e, 'start', setRangeState, setSearchOption)} />
                    <span className='inputDash'> ~ </span>
                    <input id='endInput' type='text' value={searchOption.end} onMouseDown={onClickRangeInput} onChange={(e) => onChangeRangeInput(e, 'end', setRangeState, setSearchOption)} />
                  </div>
                  {visibleRangePicker && (
                    <div className='rangePickerWrapper'>
                      <RangePicker state={rangeState} setState={setRangeState} setVisible={setVisibleRangePicker} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='inputsWrapper'>
              <div className='secondSearchOption'>
                <div className='flex-basic textBtnGroup'>
                  <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
                  {/* <BtnGhost onClick={onClickSearch} className='searchBtn'>
                    {t('검색')}
                    <span className='shortcut-f9'>F9</span>
                  </BtnGhost> */}
                  <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
                  <div className='searchResult'>
                    {t('총')}
                    <span>{mainTableState.length}</span>
                    {t('개')}
                  </div>
                  <div className='inputForm-row'>
                    <div className='inputForm-col withLabelComCf'>
                      <label htmlFor='useYn'>{t('보기 설정')}</label>
                      <SelectBox
                        options={listCountComCdList}
                        defaultOption={listCount.cdName}
                        state={listCount}
                        setState={setListCount}
                        stateKey={LIST_COUNT}
                        initiateKey={listCount[LIST_COUNT]}
                        setTableLinesAPI={patchUserMenuAPI}
                        optionHeight='height-sm'
                        rsearch
                      />
                    </div>
                  </div>
                </div>

                <div className='flex-basic iconBtnGroup'>
                  <BtnGhost onClick={() => onClickChart({ tableData: mainTableState, type: 'lineChart' })}>
                    <span className='material-symbols-rounded'>monitoring</span>
                    {t('차트')}
                  </BtnGhost>
                  {renderExcelBtn(onClickExcelSave)}
                  {/* {renderPrintBtn(onClickPrint)} */}
                </div>
              </div>
            </div>
          </SearchOptions>
          {mainTableState.length !== 0 ? (
            <TuiGridTable
              tableState={mainTableState}
              columns={mainColumns}
              listCount={Number(listCount[LIST_COUNT])}
              setExcelBtn={setExcelBtn}
              excelBtn={excelBtn}
              onClickRow={onClickRow}
              fileName={getFileName('main')}
            />
          ) : (
            <div className='emptyData'>
              <img src={nodataIllustrator} alt='noData' />
              <span>{t('해당 일자의 데이터가 존재하지 않습니다')}</span>
            </div>
          )}
        </Root>
        {renderSubTable()}
      </div>
      <Portal openModal={loading}>
        <LoadingModalBackground>
          <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' style={{ width: 'inherit', height: 'inherit' }} />
        </LoadingModalBackground>
      </Portal>
      <Portal openModal={openModal?.status}>
        {openModal.status && (
          <LineChartModal
            setOpenModal={setOpenModal}
            chartData={openModal.type === 'lineChart' ? mainChartDataReverse : subChartDataReverse}
            datasets={openModal.type === 'lineChart' ? mainDataSet : subDataSet}
            title={t(`${mGasInfo.name} 측정기`)}
          />
        )}
      </Portal>
    </div>
  );
};

export default Ssensor7;
