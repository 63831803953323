/**
 * 작성자 : 홍선영
 * 날짜 : 2023.04.10
 * 기능 : 관리자 정보 인풋 폼
 * 경로 : 마스터관리 > 관리자정보 (src/pages/master/Master1)에서 사용
 */

import { useEffect, Dispatch, SetStateAction, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { COMCD_LOCK_YN, COMCD_USE_YN } from '../../_constants';
import { BtnBlue, BtnGray, BtnRed } from '../Button';
import Input from '../Input';
import IpInput from '../IpInput';
import NumbersInput from '../NumbersInput';
import SelectBox from '../SelectBox';
import { formatDate } from '../../utils/formatDate';
import { applyBorderStyle } from '../../utils/applyBorderStyle';
import { checkIdDuplicate } from '../../utils/checkIdDuplicate';
import { emailRegex, passwordOnlyRegex, passwordRegex } from '../../utils/checkRegex';
import { registerIp } from '../../services/register';
import { useTranslation } from 'react-i18next';

interface IUserInputFormStyle {
  width?: string;
  flexDirection?: string;
}

interface IUserInputForm {
  style: IUserInputFormStyle;
  state: any;
  setState: Dispatch<SetStateAction<any>>;
  isNewAdd?: boolean;
  userInfoInputFormRef: React.RefObject<any>;
  isSettingPassword: boolean;
  setIsSettingPassword: Dispatch<SetStateAction<any>>;
  onClickNewAdd: () => void;
  onClickSave: () => void;
  onClickDelete: () => void;
  telNumState: any;
  setTelNumState: Dispatch<SetStateAction<any>>;
  hpNumState: any;
  setHpNumState: Dispatch<SetStateAction<any>>;
  ipInfoState: any;
  setIpInfoState: Dispatch<SetStateAction<any>>;
  lockYn: any;
  setLockYn: Dispatch<SetStateAction<any>>;
  useYn: any;
  setUseYn: Dispatch<SetStateAction<any>>;
  lockYnComCdList: any;
  useYnComCdList: any;
  isSaveClicked: boolean;
}
const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 0.5rem;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;
const Root = styled.div<IUserInputFormStyle>`
  /* padding: 0.5rem; */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* width: ${(props) => props.width}; */
  height: fit-content;
  overflow: auto;
  justify-content: flex-start;
  .inputForm {
    overflow: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem;
    button {
      height: 2.5rem;
      font-size: 0.75rem;
      flex-shrink: 0;
      padding: 0 0.75rem;
      border-radius: 0.25rem;
    }
    button.gray {
      font-weight: 500;
      color: ${({ theme }: { theme: any }) => theme.color.zinc_200};
    }
    label {
      width: 5.5rem;
      font-weight: 500;
      flex-shrink: 0;
      font-size: 0.875rem;
    }
    .inputForm-group-1280 {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (min-width: 1280px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
      }
    }
    .inputForm-group-1536 {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (min-width: 1536px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
      }
    }
    .inputForm-group-1536.withBtn {
      flex-direction: row;
      gap: 0.5rem;
      button {
        transition: none;
        /* color: ${({ theme }: { theme: any }) => theme.tonal_deep}; */
      }
      @media (min-width: 1536px) {
        gap: 2rem;
        button {
          margin-left: 0;
          width: fit-content;
        }
      }
    }
    .inputForm-group-1536.withBtn.inputColumnFour {
      input {
        padding: 0;
      }
    }
    .inputForm-row.labelInInput {
      display: flex;
      gap: 0.5rem;
      flex-grow: 1;
      > div {
        flex-grow: 1;
      }
    }
    .inputForm-row.labelOutInput,
    .inputForm-row.labelInInput > div > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      input {
        flex-shrink: 1;
        height: 2.5rem;
        font-size: 0.875rem;
        padding: 0 0.5rem;
        &:disabled {
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          color: ${({ theme }: { theme: any }) => theme.text_disabled};
        }
      }
      > div.viewOnly {
        height: 2.5rem;
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_primary};
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        border-radius: 0.25rem;
        padding: 0 0.75rem;
        display: flex;
        align-items: center;
      }
      > div {
        flex-grow: 1;
        > ul {
          height: 2.5rem;
          li {
            display: flex;
            width: 100%;
            max-width: 100%;
            span {
              flex-grow: 1;
              width: 4rem;
            }
          }
        }
      }
    }
    .detailInfo-group {
      margin: 1rem 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      @media (min-width: 1536px) {
        grid-template-columns: repeat(3, 1fr);
      }
      .flex-col.detailInfo {
        flex-grow: 1;
        width: 100%;
        div {
          height: 2.5rem;
          color: ${({ theme }: { theme: any }) => theme.text_tertiary};
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          border-radius: 0.25rem;
          display: flex;
          align-items: center;
          padding: 0 0.5rem;
          font-size: 0.875rem;
        }
      }
    }
  }
  input {
    :disabled {
      background-color: #eeeeee;
    }
  }
  .required {
    &::after {
      content: '*';
      padding: 0 0.2rem;
      color: red;
    }
  }
  @media screen and (max-width: 1023px) {
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: fit-content;
    overflow: auto;
  }
`;

const UserInfoInputForm = ({
  style,
  state,
  setState,
  isNewAdd,
  userInfoInputFormRef,
  isSettingPassword,
  setIsSettingPassword,
  onClickNewAdd,
  onClickSave,
  onClickDelete,
  telNumState,
  setTelNumState,
  hpNumState,
  setHpNumState,
  ipInfoState,
  setIpInfoState,
  lockYn,
  setLockYn,
  useYn,
  setUseYn,
  lockYnComCdList,
  useYnComCdList,
  isSaveClicked,
}: IUserInputForm) => {
  const { t } = useTranslation();
  const userInfoInputFormTopRef = useRef<any>(null); // 인풋 폼 ref (플로팅버튼 클릭했을 때 스크롤 상단으로 끌어올리기 위한 ref)
  // const { isBottom } = useDetectScrolledToBottom(userInfoInputFormTopRef); // 스크롤이 해당노드의 하단에 근접했는지 여부 (플로팅버튼 띄우기 위함)
  const telLengthState = { num1: 3, num2: 4, num3: 4 }; // 전화번호 입력 길이
  const [hpNumLastInput, setHpNumLastInput] = useState(false); // 휴대번호 마지막인풋 길이충족 여부
  const [telNumLastInput, setTelNumLastInput] = useState(false); // 전화번호 마지막인풋 길이충족 여부

  useEffect(() => {
    if (isNewAdd) {
      setHpNumState({ num1: '010', num2: '', num3: '' });
      setTelNumState({ num1: '', num2: '', num3: '' });
      setIpInfoState({ num1: '', num2: '', num3: '', num4: '' });
    }
  }, [isNewAdd]);

  // 아이디 수정하면 중복체크상태 false로 변경
  useEffect(() => {
    if (isNewAdd) {
      setState((prev: any) => ({ ...prev, isDuplicateChecked: false }));
    }
  }, [state.userId]);

  useEffect(() => {
    if (useYn.USE_YN !== '') setState((prev: any) => ({ ...prev, useYn: useYn.USE_YN }));
  }, [useYn.USE_YN]);

  useEffect(() => {
    if (lockYn.LOCK_YN !== '') setState((prev: any) => ({ ...prev, lockYn: lockYn.LOCK_YN }));
  }, [lockYn.LOCK_YN]);

  // 비밀번호 변경버튼 클릭. 비밀번호 변경상태가 true일 때 비밀번호 값 setState
  const onClickPasswordChange = () => {
    const { newPassword, newPasswordConfirm } = state;

    // 비밀번호 입풋창이 열려있는 상태에서 변경버튼 클릭한 경우
    if (isSettingPassword) {
      const { isPasswordRegexPass, passwordRegexAlertMsg } = passwordRegex(newPassword, newPasswordConfirm);

      if (newPassword === '' && newPasswordConfirm === '') return setIsSettingPassword((prev: boolean) => !prev); // 아무것도 입력하지 않았을때 인풋닫힘
      if (!isPasswordRegexPass) return toast.warning(t(passwordRegexAlertMsg)); // 비밀번호 정규식확인
      setState((prev: any) => ({ ...prev, userPwd: newPassword })); // 정규식 통과시 setState
    }
    return setIsSettingPassword((prev: boolean) => !prev);
  };

  // 현재IP버튼 API호출 후 ip setState
  const onClickGetCurrentIp = async () => {
    const res = await registerIp();
    const { data, message, statusCode } = res.data;

    if (statusCode === 200) {
      const splitNumber = data.split('.');
      setIpInfoState((prev: any) => ({ ...prev, num1: splitNumber[0], num2: splitNumber[1], num3: splitNumber[2], num4: splitNumber[3] }));
      // setState((prev: any) => ({ ...prev, ipInfo: data }));
    } else {
      // toast.error(t(ERROR));
    }
  };

  const onClickCheckDuplicateId = async () => {
    const { isIdDuplicate, idDuplicateAlertMsg, resStatusCode } = await checkIdDuplicate(state.userId);

    if (isIdDuplicate) return toast.warning(t(idDuplicateAlertMsg));
    if (resStatusCode === 200) setState((prev: any) => ({ ...prev, isDuplicateChecked: true }));
    if (resStatusCode === 409) setState((prev: any) => ({ ...prev, userId: '' }));

    return toast.info(t(idDuplicateAlertMsg));
  };

  const renderPasswordChangeButton = () => {
    if (!isNewAdd) {
      if (!isSettingPassword) {
        return (
          <div>
            <BtnGray className='btn-small' onClick={onClickPasswordChange}>
              {t('비밀번호 변경')}
            </BtnGray>
          </div>
        );
      }
      return (
        <div>
          <BtnBlue className='btn-small' onClick={onClickPasswordChange}>
            {t('비밀번호 변경확인')}
          </BtnBlue>
        </div>
      );
    }
    return undefined;
  };

  const renderPasswordAlert = () => {
    if (!isNewAdd) {
      if (!isSettingPassword) {
        return undefined;
      }
      return (
        <div className='grid grid100 plainText minHeightFit'>
          <label>{}</label>
          <small className='red text-left'>{t('※ 최소 8자리 이상 : 영어 대문자, 소문자, 숫자, 특수문자 중 3종류 조합')}</small>
        </div>
      );
    }
    return (
      <div className='grid grid100 plainText minHeightFit'>
        <label>{}</label>
        <small className='red text-left'>{t('※ 최소 8자리 이상 : 영어 대문자, 소문자, 숫자, 특수문자 중 3종류 조합')}</small>
      </div>
    );
  };

  function getBorderStyle(data: any, borderColor: string, property: string): React.CSSProperties {
    // 저장을 클릭한 후 필수입력값이 빈값일 때 보더처리
    if (data === '') {
      return { border: `1px solid ${borderColor}` };
    }
    // 패스워드 형식이 맞지 않을 경우 보더처리
    if (property === 'newPassword' || property === 'newPasswordConfirm') {
      const { isPasswordRegexPass } = passwordOnlyRegex(data);
      if (!isPasswordRegexPass) return { border: `1px solid ${borderColor}` };
    }
    if (property === 'newPasswordConfirm') {
      if (state.newPassword?.trim() !== state.newPasswordConfirm?.trim()) return { border: `1px solid ${borderColor}` };
    }
    // 이메일 형식이 맞지 않을 경우 보더처리
    if (property === 'eMail') {
      const { isEmailRegexPass } = emailRegex(data);
      if (!isEmailRegexPass) return { border: `1px solid ${borderColor}` };
    }
    return {};
  }

  return (
    // <Root width={style.width} ref={userInfoInputFormTopRef}>
    <Root>
      {/* {isBottom && <FloatingButtonToTop nodeRef={userInfoInputFormTopRef} />} */}
      <div className='inputForm'>
        <div className='inputForm-group-1280'>
          <div className='inputForm-row labelOutInput'>
            <label htmlFor='auth'>{t('권한')}</label>
            <div className='viewOnly'>{t('마스터')}</div>
          </div>
        </div>
        <div className='inputForm-group-1536 withBtn'>
          <div className='inputForm-row labelInInput'>
            <Input
              className='required'
              label={t('아이디')}
              type='text'
              id='userId'
              name='userId'
              state={state}
              setState={setState}
              disabled={!isNewAdd}
              trim
              getBorderStyle={isSaveClicked ? applyBorderStyle(state.userId, 'red', 'userId') : undefined}
            />
          </div>
          {isNewAdd && (
            <BtnGray className='btn-small gray' onClick={onClickCheckDuplicateId}>
              {t('중복검사')}
            </BtnGray>
          )}
        </div>
        <div className='inputForm-group-1536 withBtn'>
          <div className='inputForm-row labelInInput'>
            <Input
              className='required'
              label={t('성명')}
              type='text'
              id='userName'
              name='userName'
              state={state}
              setState={setState}
              getBorderStyle={isSaveClicked ? applyBorderStyle(state.userName, 'red', 'userName') : undefined}
            />
          </div>
          <div className='inputForm-row labelOutInput'>{!isNewAdd && <div className='viewOnly'>{`(${state.displayPassword})`}</div>}</div>
        </div>
        {isNewAdd || (!isNewAdd && isSettingPassword) ? (
          <>
            <div className='inputForm-group-1280'>
              <div className='inputForm-row labelInInput'>
                <Input
                  className='required'
                  label={t('비밀번호')}
                  type='password'
                  id='newPassword'
                  name='newPassword'
                  state={state}
                  setState={setState}
                  trim
                  getBorderStyle={isSaveClicked ? applyBorderStyle(state.newPassword, 'red', 'newPassword') : undefined}
                />
              </div>
              <div className='inputForm-row labelOutInput'>
                <div>
                  <small className='red text-left'>
                    {t('현재 입력 글자수')} : {state.newPassword?.length}
                  </small>
                  {renderPasswordAlert()}
                </div>
              </div>
            </div>
            <div className='inputForm-group-1280'>
              <div className='inputForm-row labelInInput'>
                <Input
                  className='required'
                  label={t('비밀번호 확인')}
                  type='password'
                  id='newPasswordConfirm'
                  name='newPasswordConfirm'
                  state={state}
                  setState={setState}
                  trim
                  getBorderStyle={isSaveClicked ? applyBorderStyle(state.newPasswordConfirm, 'red', 'newPasswordConfirm', state.newPassword) : undefined}
                />
              </div>
              <div className='inputForm-row labelOutInput'>
                <div>
                  <BtnGray className='btn-small' onClick={onClickPasswordChange}>
                    {t('비밀번호 변경확인')}
                  </BtnGray>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className='inputForm-row labelOutInput'>
            <label htmlFor='password'>{t('비밀번호')}</label>
            <div>
              <BtnGray className='btn-small' onClick={onClickPasswordChange}>
                {isSettingPassword ? t(`비밀번호 변경확인`) : t(`비밀번호 변경`)}
              </BtnGray>
            </div>
          </div>
        )}
        <div className='inputForm-group-1536'>
          <div className='inputForm-row labelOutInput'>
            <label htmlFor='hpNum' className='required'>
              {t('휴대폰번호')}
            </label>
            <NumbersInput state={hpNumState} setState={setHpNumState} lengthState={telLengthState} setIsLastInputCompleted={setHpNumLastInput} isSaveClicked={isSaveClicked} />
          </div>
          <div className='inputForm-row labelOutInput'>
            <label htmlFor='telNum'>{t('전화번호')}</label>
            <NumbersInput state={telNumState} setState={setTelNumState} lengthState={telLengthState} isFirstInputFocused={hpNumLastInput} setIsLastInputCompleted={setTelNumLastInput} />
          </div>
        </div>
        <div className='inputForm-group-1280'>
          <div className='inputForm-row labelInInput'>
            <Input
              className='required'
              label={t('이메일')}
              type='text'
              id='eMail'
              name='eMail'
              state={state}
              setState={setState}
              trim
              getBorderStyle={isSaveClicked ? applyBorderStyle(state.eMail, 'red', 'eMail') : undefined}
            />
          </div>
        </div>
        <div className='inputForm-group-1536 withBtn inputColumnFour'>
          <div className='inputForm-row labelOutInput'>
            <label htmlFor='hpNum'>{t('접속 IP')}</label>
            <IpInput stateName='ipInfo' separator='.' state={ipInfoState} setState={setIpInfoState} setInfoState={setState} />
          </div>
          <div className='inputForm-row labelOutInput'>
            <BtnGray className='btn-small gray' onClick={onClickGetCurrentIp}>
              {/* 현재 PC IP 적용 */}
              {t('나의 IP 적용')}
            </BtnGray>
          </div>
        </div>
        <div className='inputForm-group-1280'>
          <div className='inputForm-row labelOutInput'>
            <label htmlFor='lockYn'>{t('잠금유무')}</label>
            <SelectBox options={lockYnComCdList} defaultOption={isNewAdd ? t('미잠금') : t(state.lockYn)} state={lockYn} setState={setLockYn} stateKey={COMCD_LOCK_YN} initiateKey={state.lockYn} />
          </div>
          <div className='inputForm-row labelOutInput'>
            <label htmlFor='useYn'>{t('사용유무')}</label>
            <SelectBox options={useYnComCdList} defaultOption={isNewAdd ? t('사용') : t(state.useYn)} state={useYn} setState={setUseYn} stateKey={COMCD_USE_YN} initiateKey={state.useYn} />
          </div>
        </div>
        <div className='inputForm-group-1280'>
          <div className='inputForm-row labelInInput'>
            <Input label={t('비고')} type='text' id='bigo' name='bigo' state={state} setState={setState} maxLength={200} />
          </div>
        </div>
        {!isNewAdd && (
          <div className='detailInfo-group'>
            <div className='flex-col detailInfo'>
              <label htmlFor='createDate'>{t('등록일자')}</label>
              <div>{formatDate(state?.wDate)}</div>
            </div>
            <div className='flex-col detailInfo'>
              <label htmlFor='createUser'>{t('등록자')}</label>
              <div>{state.writer}</div>
            </div>
            <div className='flex-col detailInfo'>
              <label htmlFor='updateDate'>{t('수정일자')}</label>
              <div>{formatDate(state.eDate)}</div>
            </div>
            <div className='flex-col detailInfo'>
              <label htmlFor='updateUser'>{t('수정자')}</label>
              <div>{state.editor}</div>
            </div>
          </div>
        )}
      </div>
      <ButtonsWrapper>
        {!isNewAdd ? <BtnRed onClick={onClickDelete}>{t('삭제')}</BtnRed> : undefined}
        <BtnBlue onClick={onClickSave}>{t('저장')}</BtnBlue>
      </ButtonsWrapper>
    </Root>
  );
};

export default UserInfoInputForm;
