/**
 * 작성자 : 홍선영
 * 날짜 : 2024.03.22
 * 기능 : 메인메뉴(NVR)와 확장가능한 서브메뉴리스트(Camera list)를 출력하는 컴포넌트
 */

import { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import CameraIcon from './CameraIcon';
import LeftedToast from './LeftedToast';
import ExpandButton from '../button/ExpandButton';
import RecordButton from '../button/RecordButton';
import ResolutionButton from '../button/ResolutionButton';
import { CameraStatus, WebSDKcctv } from '../../utils/webSDKcctvClass';
import { clickOpenDirectory } from '../../utils/cctv';
import { IsInfraType } from '../../pages/s_cctv/S_cctv1/RealtimeCCTV/IsInfraType';
import { mouseDownIndexState, userState } from '../../atoms';
import { useRecoilValue } from 'recoil';
import ClickableContainer from '../../pages/s_cctv/S_cctv1/RealtimeCCTV/ClickableContainer';
import ButtonContainer from '../../pages/s_cctv/S_cctv1/RealtimeCCTV/ButtonContainer';
import { isCamInCurrentGrid } from '../../utils/isCamInCurrentGrid';
import { logPost } from '../../services/log';

export const ExpandMenuStyle = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  border-radius: 0.5rem;
  background-color: ${({ theme }: { theme: any }) => theme.tonal};
  &:hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
  }
`;

export const MainMenuStyle = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }: { theme: any }) => theme.text_tertiary};
  padding: 0.5rem;
  gap: 0.75rem;
  &.expanded {
    border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
  &:hover {
    border-radius: 0.5rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
  }
  .mainMenuContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    flex-grow: 1;
    .title {
      font-size: 0.9rem;
      &.canHover {
        cursor: pointer;
        :hover {
          color: ${({ theme }: { theme: any }) => theme.selected_primary};
          backbround-color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        }
      }
    }
    .count-container {
      display: flex;
      align-items: center;
      justify-content: center;
      .total-count {
        min-width: 2rem;
        font-size: 0.8rem;
        color: ${({ theme }: { theme: any }) => theme.filled_blue};
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.5rem;
        border-radius: 1rem;
        background-color: ${({ theme }: { theme: any }) => theme.tonal_deep_blue};
        height: 1.25rem;
      }
    }
  }

  &.margin-none {
    margin: 0;
  }

  > .hover {
    cursor: pointer;
  }
`;

export const SubMenuListStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.375rem;
  padding-left: 1.5rem;
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  &.selected {
    z-index: 10;
    /* outline: 1px solid ${({ theme }: { theme: any }) => theme.filled_yellow}; */
    /* outline: 1px solid ${({ theme }: { theme: any }) => theme.filled_green}; */
    outline: 1px solid ${({ theme }: { theme: any }) => theme.filled_blue};
    &:last-child {
      border-radius: 0 0 0.5rem 0.5rem;
    }
  }
  &:nth-child(2) {
    border-top: none;
  }
  &:hover {
    // mouseDown 기능이 동작하지 않아 임의로 주석처리함. 추후 수정필요.
    // background-color: ${({ theme }: { theme: any }) => theme.tonal};
  }
  > input {
    margin-left: 1rem;
  }

  &.padding-bottom {
    padding-bottom: 0.5rem;
  }

  > .hover {
    cursor: pointer;
  }

  .cameraNamespace {
    margin-left: 0.25rem;
    font-size: 0.85rem;
    width: 6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    white-space: nowrap;
    flex-grow: 1;
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    :hover {
      /* color: ${({ theme }: { theme: any }) => theme.selected_primary}; */
      color: ${({ theme }: { theme: any }) => theme.filled_blue};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
      &:active {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_deep_blue};
      }
    }
    &.red {
      color: ${({ theme }: { theme: any }) => theme.filled_red};
    }
  }
  .record {
    width: 2.25rem;
    padding-left: 0.75rem;
    .name {
      overflow: hidden;
      opacity: 0;
      transition: width 0.5s ease-in-out, opacity 0.3s ease-in-out;
    }
    &:hover {
      width: fit-content;
      padding-left: 0.5rem;
      .name {
        opacity: 1;
      }
    }
  }
`;

export const InfraMenuStyle = styled(SubMenuListStyle)`
  padding: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.75rem;
  gap: 0.75rem;
  border-top: none !important;
  border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  &:last-child {
    border-bottom: none;
  }
  &.expanded {
    border-bottom: none;
    padding-bottom: 0.25rem;
  }
  > span {
    font-size: 0.9rem;
    font-weight: 600;
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    cursor: pointer;
    user-select: none;
    width: 100%;
    :hover {
      color: ${({ theme }: { theme: any }) => theme.selected_primary};
    }
  }
`;

interface IExpandableMenu {
  mainMenu: IMainMenu;
  nvrList: IMainMenu[];
  setNvrList: Dispatch<SetStateAction<IMainMenu[]>>;
  setDraggingCamInfo: Dispatch<SetStateAction<ISubMenu[] | null>>;
  cctvOBJ: WebSDKcctv | undefined;
  setOpenModal: Dispatch<SetStateAction<any>>;
  setIsDragging: Dispatch<SetStateAction<boolean>>;
  cctvDivisions: number;
  currentPage: number;
  selectedCctvCameraInfo: SelectedCctvCameraInfo | null;
  setIsRecording?: Dispatch<SetStateAction<boolean>>;
  type: 'realtime' | 'playback';
  draggingCamInfo?: ISubMenu[] | null;
  cctvSettings: CctvSettings;
  playingCamStatus?: any[];
  setPlayingCamStatus?: Dispatch<SetStateAction<any[]>>;
  sdkLoading?: boolean;
  setSdkLoading?: Dispatch<SetStateAction<boolean>>;
  setSelectedCctvCameraInfo?: Dispatch<SetStateAction<SelectedCctvCameraInfo | null>>;
}

// 각 NVR 및 연결된 카메라를 표시하는 컴포넌트
const ExpandableMenu = ({
  mainMenu,
  nvrList,
  setNvrList,
  setDraggingCamInfo,
  cctvOBJ,
  setOpenModal,
  setIsDragging,
  cctvDivisions,
  currentPage,
  selectedCctvCameraInfo,
  setIsRecording,
  type,
  draggingCamInfo,
  cctvSettings,
  playingCamStatus,
  setPlayingCamStatus,
  sdkLoading,
  setSdkLoading,
  setSelectedCctvCameraInfo,
}: IExpandableMenu) => {
  const userInfo = useRecoilValue(userState);
  const { nCd, nName, expand, subList, fail } = mainMenu;
  const isInfraType = IsInfraType(); // 인프라용 여부
  const mouseDownIndex = useRecoilValue(mouseDownIndexState);

  // 해당 메뉴의 확장/축소 상태를 토글하는 함수
  const expandMenu = () => {
    setNvrList(nvrList.map((el: IMainMenu) => (nCd === el.nCd ? { ...el, expand: !el.expand } : el)));
  };

  // 녹화버튼 클릭
  const onClickRecordButton = async (cameraParam: ISubMenu) => {
    const { windowIndex, isPlaying, isRecording, cCd } = cameraParam;
    const grid = cctvDivisions * cctvDivisions;
    const condition =
      windowIndex >= (currentPage - 1) * grid && //
      windowIndex < (currentPage - 1) * grid + grid;

    // 재생 중이 아닐때
    if (!isPlaying) {
      toast(
        <LeftedToast message='재생중인 상태에서 녹화를 시작하세요' />, //
        { position: 'top-left', autoClose: 3000 }
      );
      return;
    }

    // 재생 중이지만, 현재 페이지에 없을때
    if (isPlaying && !condition) {
      toast(
        <LeftedToast message='현재 페이지에서 재생 중이 아닙니다' />, //
        { position: 'top-left', autoClose: 3000 }
      );
      return;
    }

    try {
      if (!isRecording) {
        // 녹화 시작
        const cameraStatus = await cctvOBJ?.recordWithIndex(windowIndex % grid);
        if (cameraStatus) {
          // subList의 카메라 녹화상태값 업데이트
          const updatedSubList = subList.map((camera) =>
            camera.cCd === cCd //
              ? { ...camera, isRecording: true }
              : camera
          );
          // 목록 아이콘 업데이트
          setNvrList(
            nvrList.map((el: IMainMenu) =>
              el.nCd === nCd //
                ? { ...el, subList: updatedSubList }
                : el
            )
          );
          // 녹화중 전역상태값 업데이트
          saveCamRecordingInfo(cameraStatus);
        } else {
          toast(
            <LeftedToast message='시청중인 상태에서 녹화를 시작하세요' />, //
            { position: 'top-left', autoClose: 3000 }
          );
        }
      } else {
        // 녹화 정지
        const cameraStatus = await cctvOBJ?.recordStopWithIndex(windowIndex % grid);
        if (cameraStatus) {
          // subList의 카메라 녹화상태값 업데이트
          const updatedSubList = subList.map((camera) =>
            camera.cCd === cCd //
              ? { ...camera, isRecording: false }
              : camera
          );
          // 목록 아이콘 업데이트
          setNvrList(
            nvrList.map((el: IMainMenu) =>
              el.nCd === nCd //
                ? { ...el, subList: updatedSubList }
                : el
            )
          );
          // 녹화중 전역상태값 업데이트
          saveCamRecordingInfo(cameraStatus);
        }
        // 녹화보기 토스트
        toast(
          <LeftedToast message='녹화 완료' onClick={() => clickOpenDirectory()} />, //
          { position: 'top-left', autoClose: 3000 }
        );
      }
    } catch (error) {
      console.error('Error handling recording:', error);
    }
  };

  // 녹화중인 카메라가 1개이상 있으면 녹화중 true setRecoilState
  const saveCamRecordingInfo = (cameraStatus: any[]) => {
    const camRecordingCount = cameraStatus.filter((el: any) => el.isRecording).length;
    setIsRecording && setIsRecording(camRecordingCount > 0);
  };

  const changeStreamData = (streamType: string, camera: ISubMenu) => {
    // 카메라 스트림 변경
    if (camera.stream !== streamType) {
      const pageWndIndex: number = camera.windowIndex - (currentPage - 1) * cctvDivisions * cctvDivisions;
      cctvOBJ?.changeStream(camera, pageWndIndex, streamType);

      // 목록 아이콘 업데이트
      const updatedSubList = subList.map((el: ISubMenu) => (el.cCd === camera.cCd ? { ...el, stream: streamType } : el));
      setNvrList(nvrList.map((el: IMainMenu) => (el.nCd === camera.nCd ? { ...el, subList: updatedSubList } : el)));

      logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: 'CCTV 실시간 보기-인프라용',
        action: `카메라 스트림 변경`,
        etc: `${camera.cName}`,
      });
    }
  };

  // 스트림버튼 클릭
  const onClickStreamButton = (camera: ISubMenu) => {
    setOpenModal({ status: true, type: 'stream', data: camera, setDataFunction: (streamType: '1' | '2' | '3') => changeStreamData(streamType, camera) });
  };

  // const getCamInfoOnMouseDown = (camera: ISubMenu) => {
  //   // const updatedCam = { ...camera, isPlaying: !camera.isPlaying };
  //   setDraggingCamInfo([camera]);
  // };

  const getCamInfoOnMouseDown = (camera: ISubMenu) => {
    if (!mainMenu.fail) {
      setDraggingCamInfo([camera]);
      setIsDragging(true);
    }
  };

  const getCamListOnMouseDown = () => {
    if (type === 'realtime' && !mainMenu.fail) {
      setDraggingCamInfo(subList);
      setIsDragging(true);
    }
  };

  const onClickCameraLens = async (camObj: any): Promise<void> => {
    const { windowIndex, isPlaying, cCd } = camObj;
    const isInPage = isCamInCurrentGrid(windowIndex, cctvDivisions, currentPage);

    // 정지
    const stopCamera = async (cam: any) => {
      await cctvOBJ?.stopWithIndex(windowIndex);
      updateNvrListStop(cam, false);
      logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: 'CCTV 실시간 보기-인프라용',
        action: `카메라 화면 제거`,
        etc: `${cam.cName}`,
      });
    };

    // 시작
    const startCamera = async (cam: any) => {
      if (mouseDownIndex === null) return;
      const pageWndIndex = mouseDownIndex + (currentPage - 1) * cctvDivisions * cctvDivisions;
      const startResult = await cctvOBJ?.start([cam], pageWndIndex, cctvDivisions, cctvSettings.camCLocation, cctvSettings.camFColor);
      if (startResult) {
        updateNvrListStart(startResult);
        setSelectedCctvCameraInfo && setSelectedCctvCameraInfo(startResult[mouseDownIndex]);
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: 'CCTV 실시간 보기-인프라용',
          action: `카메라 재생`,
          etc: `${cam.cName}`,
        });
      }
    };

    if (isPlaying && !isInPage) {
      toast(<LeftedToast message='현재 페이지에서 재생 중이 아닙니다' />, { position: 'top-left', autoClose: 3000 });
    } else if (isPlaying) {
      await stopCamera(camObj);
    } else {
      await startCamera(camObj);
    }
  };

  const updateNvrListStart = (camStatus: CameraStatus[]) => {
    const newNvrList = nvrListIsPlayingAllFalse();
    camStatus.forEach((cam: CameraStatus) => {
      newNvrList.map((nvr, i) => {
        if (cam.nCd === nvr.nCd) {
          nvr.subList.map((sub, j) => {
            if (sub.cCd === cam.cCd) {
              newNvrList[i].subList[j].isPlaying = cam.isPlaying;
              newNvrList[i].subList[j].fail = cam.isFail;
              newNvrList[i].subList[j].windowIndex = Number(cam.windowIndex);
            }
          });
        }
      });
    });
    setNvrList([...newNvrList]);
    if (setPlayingCamStatus) {
      setPlayingCamStatus(camStatus);
    }
    if (setSdkLoading) {
      setSdkLoading(false);
    }
  };
  const nvrListIsPlayingAllFalse = () => {
    const newNvrList = nvrList;
    nvrList.map((nvr, i) => {
      nvr.subList.map((sub, j) => {
        newNvrList[i].subList[j].isPlaying = false;
      });
    });
    return newNvrList;
  };
  const updateNvrListStop = (cam: any, isPlaying: boolean, windowIndex?: number) => {
    setNvrList([
      ...nvrList.map((list: IMainMenu) =>
        list.nCd === cam.nCd
          ? {
              ...list,
              subList: list.subList.map((sub: any) => (sub.cCd === cam.cCd ? { ...sub, isPlaying, ...(windowIndex !== undefined && { windowIndex }) } : sub)),
            }
          : list
      ),
    ]);
  };

  return (
    <ExpandMenuStyle>
      <MainMenuStyle className={expand ? 'expanded' : ''}>
        <ExpandButton
          isExpanded={expand} //
          onClickExpandButton={expandMenu}
          disabled={subList.length === 0}
        />
        <div className='mainMenuContainer'>
          <span role='button' tabIndex={0} onMouseDown={getCamListOnMouseDown} className={`title ${type === 'realtime' ? 'canHover' : ''}`}>
            {nName}
          </span>
          <div className='count-container'>
            {/* <span className='count'>{subList.filter((el: ISubMenu) => el.isPlaying).length}</span> */}
            <span className='total-count'>{subList.length}</span>
          </div>
        </div>
      </MainMenuStyle>
      {expand &&
        subList.map((el: ISubMenu) => (
          <SubMenuListStyle key={`${el.nCd}_${el.cCd}_${el.windowIndex}`} className={selectedCctvCameraInfo?.nCd === el.nCd && selectedCctvCameraInfo?.cCd === el.cCd ? 'selected' : ''}>
            <CameraIcon
              type={type}
              isFail={fail || el.fail}
              isPlaying={el.isPlaying} //
              isPausing={el.isPausing}
              onClick={() => onClickCameraLens(el)}
            />
            <div
              role='button'
              tabIndex={0}
              className={`
              ${draggingCamInfo && draggingCamInfo[0]?.nCd === el.nCd && draggingCamInfo[0]?.cCd === el.cCd ? 'red' : ''} cameraNamespace`}
              onMouseDown={() => getCamInfoOnMouseDown(el)}
            >
              {el.cName}
            </div>
            {type === 'realtime' && !isInfraType && (
              <ClickableContainer // 녹화
                button={
                  <ButtonContainer
                    icon={el.isRecording ? 'stop' : 'fiber_manual_record'} //
                    name={el.isRecording ? '녹화 중지' : '녹화 시작'}
                    className={`record ${el.isPlaying ? 'negative' : ''}`}
                  />
                }
                onClick={() => onClickRecordButton(el)}
                disabled={!el.isPlaying}
              />
            )}
            {type === 'realtime' && (
              <ResolutionButton
                stream={el.stream} //
                onClick={(streamType: string) => changeStreamData(streamType, el)}
                disabled={el.isRecording}
              />
            )}
          </SubMenuListStyle>
        ))}
    </ExpandMenuStyle>
  );
};

export default ExpandableMenu;
