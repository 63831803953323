import { useState, useEffect } from 'react';

const useClock = () => {
  // 저장된 window의 video의 play 시간을 알기 위해 사용되는 상태
  const [currentTime, setCurrentTime] = useState<number>(0);
  // 저장된 window의 video를 조회하기 위해 사용되는 상태
  const [currentDate, setCurrentDate] = useState<string>('');
  const [speed, setSpeed] = useState<1 | 2 | 4>(1);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isReversed, setIsReversed] = useState<boolean>(false);

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    const updateClock = () => {
      const timerSpeed = 1000 / speed; // 밀리초 단위로 타이머 간격 설정
      timerId = setInterval(() => {
        setCurrentTime((prevTime) => {
          const calcTime = isReversed ? prevTime - 1 : prevTime + 1;
          return calcTime;
        }); // 1초마다 현재 시간 갱신
      }, timerSpeed);
    };

    const stopClock = () => {
      clearInterval(timerId); // 타이머 정지
    };

    if (isPlaying) {
      updateClock();
    } else {
      stopClock();
      setSpeed(1);
    }

    return () => clearInterval(timerId); // 컴포넌트 언마운트 시 타이머 정지
  }, [isPlaying, speed, currentTime]);

  const exportData = {
    setIsPlaying,
    currentTime, //
    setCurrentTime,
    currentDate,
    setCurrentDate,
    speed,
    setSpeed,
    isReversed,
    setIsReversed,
  };

  return exportData;
};

export default useClock;
