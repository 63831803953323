/**
 * 작성자 : 김광민
 * 날짜 : 2023.12.19
 * 경로 : 터널 관리 - 구역 현황 관리
 */

import styled from 'styled-components';
import TunnelTableBody from './TunnelTableBody';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Portal from './Portal';
import TareaInfoModal from './Modal/tAreaInfoModal';
import { TAREA_INNERTAB_NORMAL } from '../_constants';
import { useSetAuth } from '../utils/useSetAuth';
import { Thead } from '../assets/styles/TunnelTable';

const Root = styled.table`
  display: flex;
  flex-direction: column;
  overflow: scroll auto;
  width: 100%;
  height: fit-content;
  flex-grow: 1;
  user-select: none;
  .tdGrow {
    flex-grow: 1;
  }
  .td2p5 {
    width: 2.5rem;
  }
  .td4 {
    width: 4rem;
  }
  .td6 {
    width: 6rem;
  }
  .td8 {
    width: 8rem;
  }
  .td10 {
    width: 10rem;
  }
  .td12 {
    width: 12rem;
  }
  .td16 {
    width: 16rem;
  }
  .td2p5 {
    width: 2.5rem;
  }
  .justify-start {
    justify-content: flex-start;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-end {
    justify-content: flex-end;
  }
  .bigo {
    font-size: 0.875rem;
  }
  .sticky {
    position: sticky;
    left: 0;
  }
  button {
    height: 2rem;
    width: 2.5rem;
    padding: 0;
    background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
    &:active {
      background-color: ${({ theme }: { theme: any }) => theme.board};
    }
    .material-symbols-rounded {
      color: ${({ theme }: { theme: any }) => theme.filled_slate};
      font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
    }
  }
  .done {
    color: ${({ theme }: { theme: any }) => theme.filled_green};
  }
  .close {
    color: ${({ theme }: { theme: any }) => theme.filled_red};
  }
`;

const Tbody = styled.tbody`
  width: 100%;
  min-width: fit-content;
  > .tr {
    position: relative;
    display: flex;
    &:nth-child(n + 2) {
      border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    }
    &:hover {
      &.clickable td {
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
      }
      .tdSpread {
        display: flex;
      }
    }
    &.clickable {
      cursor: pointer;
    }
    > td {
      display: flex;
      align-items: center;
      height: 3rem;
      padding: 0 0.25rem;
      flex-shrink: 0;
      font-size: 0.875rem;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      background-color: ${({ theme }: { theme: any }) => theme.board};
      &.text-ellipsis {
        display: block;
        line-height: 3rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
`;

const TunnelTable = ({ tareaGrid, setTareaGrid }: { tareaGrid: TareaData[]; setTareaGrid: Dispatch<SetStateAction<TareaData[]>> }) => {
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const [expandRowList, setExpandRowList] = useState<{ cd: string; status: boolean }[]>([]);
  const [expandOption, setExpandOption] = useState(true);
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '', title: '' });

  // 모든 row 펼치기 값 true 적용
  useEffect(() => {
    if (tareaGrid.length > 0) {
      const result = tareaGrid.map((tarea) => ({ cd: tarea.tatCd, status: true }));
      setExpandRowList([...result]);
    }
  }, [tareaGrid]);

  // 모든 row 펼치기 상태가 true 인지 확인
  useEffect(() => {
    const result = expandRowList.length === expandRowList.filter((element) => element.status === true).length;
    setExpandOption(result);
  }, [expandRowList]);

  // 전체 펼치기, 접기 클릭 이벤트
  const onClickExpand = () => {
    setExpandRowList(expandRowList.map((element) => ({ cd: element.cd, status: !expandOption })));
  };

  const onClickRow = (tareaParam: TareaData) => {
    const findIndex = tareaGrid.findIndex((el: TareaData) => el.tatCd === tareaParam.tatCd);
    if (findIndex !== -1) {
      setOpenModal((prev: any) => ({ ...prev, status: true, type: 'tareaDetail', data: tareaParam, setDataList: setTareaGrid, index: findIndex }));
    }
  };

  return (
    <Root>
      <Thead>
        <tr>
          <th className='td4 justify-center sticky'>
            <button type='button' onClick={() => onClickExpand()} className={expandOption ? 'less' : 'more'}>
              {expandOption ? <span className='material-symbols-rounded'>expand_less</span> : <span className='material-symbols-rounded'>expand_more</span>}
            </button>
          </th>
          <th className='td2p5'>No</th>
          <th className='td4'>{t('코드')}</th>
          <th className='td10'>{t('구역 명')}</th>
          <th className='td8 tdGrow'>
            {t('총 공사 길이')}
            <span className='meterUnit'>m</span>
          </th>
          <th className='td12 tdGrow'>
            {t(`시점 총 공사 길이`)}
            <span className='meterUnit'>m</span>
            <span className='slashUnit'> / </span>
            {t(`진행률`)}
          </th>
          <th className='td12 tdGrow'>
            {t('종점 총 공사 길이')}
            <span className='meterUnit'>m</span>
            <span className='slashUnit'>/</span>
            {t(' 작업 내용')}
          </th>
          <th className='td10 tdGrow'>
            {t('사용유무')} / {t('작업 상태')}
          </th>
          <th className='td10 tdGrow'>{t('비고')}</th>
        </tr>
      </Thead>
      <Tbody>
        {tareaGrid.map((tarea: TareaData) => (
          <TunnelTableBody onClickRow={() => onClickRow(tarea)} tarea={tarea} key={tarea.tatName} setExpandRowList={setExpandRowList} expandRowList={expandRowList} />
        ))}
      </Tbody>
      <Portal openModal={openModal?.status}>
        {openModal.type === 'tareaDetail' && (
          <TareaInfoModal
            setOpenModal={setOpenModal}
            auth={auth}
            activeTab={TAREA_INNERTAB_NORMAL}
            data={openModal.data}
            dataList={tareaGrid}
            setDataList={openModal.setDataList}
            index={openModal.index}
            showPrevNextBtn
          />
        )}
      </Portal>
    </Root>
  );
};

export default TunnelTable;
