/**
 * 작성자 : 홍선영
 * 날짜 : 2023.01.09
 * 기능 : 터널 관리 > 굴진/일반 정보 > 굴진/일반 정보 팝업 > 근로자/장비 위치정보 탭 (현장용)
 */
/*
 * 수정자 : 김광민
 * 수정 날짜 : 2024.02.27
 * 수정 이유 : 가독성 향상과 유지보수를 위한 코드 분리, 변수 변경 및 삭제, 재사용 코드 컴포넌트화, 스타일 수정, 일부 로직 수정
 */

import { useEffect, useRef, useState } from 'react';
import { useGetFrozenCount } from '../utils/getFrozenCount';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import TuiGridWrapper from './Table/TuiGridWrapper';
import TuiGrid from './Table/TuiGrid';
import i18n from '../translation/i18n';
import IssueGuide from './IssueGuide';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
  border-radius: 0.5rem;
  width: 100%;
  padding: 0.5rem;
  @media screen and (min-width: 768px) {
    padding: 1rem;
    width: calc(50% - 0.25rem);
  }
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      display: flex;
      align-items: center;
      gap: 0.375rem;
      .material-symbols-rounded {
        font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
        font-size: 1.125rem;
        color: ${({ theme }: { theme: any }) => theme.text_disabled};
      }
      .titleName {
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      }
    }
    .count {
      font-size: 0.875rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.75rem;
      height: 1.75rem;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      border-radius: 0.25rem;
    }
  }
  > div:nth-child(2) {
    background-color: ${({ theme }: { theme: any }) => theme.board};
    margin-left: 0 !important;
    padding-left: 0.5rem;
    border-radius: 0.25rem;
    box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.025);
    width: 100%;
  }
  &.noData > div:nth-child(2) {
    background-color: transparent;
    padding-left: 0;
    box-shadow: none;
    margin: 2rem 0;
  }
`;

type Props = { perPage: number; gridData: any; isWorker: boolean };

const TareaLocationInfoGrid = ({ perPage, gridData, isWorker }: Props) => {
  const { t } = useTranslation();
  const frozenCount = useGetFrozenCount();
  const componentRef = useRef<HTMLDivElement>(null);
  const [columns, setColumns] = useState<any[]>([]);
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);

  const titleName = isWorker ? t('근로자') : t('장비');
  const icon = isWorker ? 'group' : 'front_loader';

  // 컴포넌트의 높이 설정
  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight, componentRef.current?.offsetWidth]);

  // 컬럼 정보 설정
  useEffect(() => {
    setColumns([
      {
        header: `${t('근로자명')} / ${t('직종')}`,
        name: 'wNamejobType',
        align: 'left',
        sortable: true,
        width: 130,
        // renderer: { classNames: ['text_secondary'] },
        renderer: { classNames: ['line-2-title'] },
      },
      {
        header: `${t('협력업체')} / ${t('상세구역 명')}`,
        name: 'sjNametadName',
        align: 'left',
        sortable: true,
        filter: 'select',
        width: 170,
        // renderer: { classNames: ['text_secondary'] },
        renderer: { classNames: ['line-2-title'] },
      },
      {
        header: t('최종시간'),
        name: 'bDate',
        align: 'center',
        sortable: true,
        minWidth: 120,
        // renderer: { classNames: ['text_secondary'] },
        renderer: { classNames: ['line-2-title'] },
      },
    ]);
  }, [i18n.language]);

  return (
    <Root className={gridData.length === 0 ? 'noData' : ''}>
      <div className='header'>
        <div className='title'>
          <span className='material-symbols-rounded'>{icon}</span>
          <span className='titleName'>{titleName}</span>
        </div>
        <span className='count'>{gridData.length}</span>
      </div>
      {gridData.length === 0 ? (
        <IssueGuide />
      ) : (
        <TuiGridWrapper componentRef={componentRef}>
          <TuiGrid data={gridData} columns={columns} perPage={perPage} usePagenation scrollX height={tuiHeight} frozenCount={frozenCount} eListYn='Y' />
        </TuiGridWrapper>
      )}
    </Root>
  );
};

export default TareaLocationInfoGrid;
