import { ITabObject } from 'customTypes';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { v1 } from 'uuid';
import { IPageInfo, pageInfoState, asideState, asideSubState } from '../atoms';

interface styleProps {
  W: number;
}

const Root = styled.div<styleProps>`
  display: flex;
  /* align-items: center; */
  align-items: center;
  align-items: flex-end;
  justify-content: space-between;
  .breadscrum {
    display: flex;
    align-items: center;
    span {
      user-select: none;
      &:last-child {
        margin-right: 0.5rem;
      }
      &:not(.material-symbols-rounded) {
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        font-weight: 500;
      }
      &.material-symbols-rounded {
        font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 20;
        color: ${({ theme }: { theme: any }) => theme.outline_em};
      }
    }
  }
  .tab-list {
    display: flex;
    gap: 0;
    align-items: center;
    border-radius: 0.25rem;
    gap: 0.5rem;
    flex-grow: 1;
    @media (min-width: 1024px) {
      margin: 0 0.5rem;
    }
    > div {
      border-radius: 0.25rem;
      overflow: hidden;
      padding: 0 1rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-weight: ${({ theme }: { theme: any }) => theme.font_medium};
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      user-select: none;
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      &.seperator {
        &:nth-child(2) {
          display: none;
        }
        &:nth-child(n + 3) {
          margin: 0 0.125rem;
          padding: 0;
          width: 2px;
          height: 1.5rem;
          background-color: ${({ theme }: { theme: any }) => theme.outline};
        }
      }
      :hover {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
      }
    }
    .icon-menu {
      width: 3rem;
      height: 2.5rem;
      border-radius: 0.25rem;
      padding: 0;
      flex-shrink: 0;
      @media (min-width: 1024px) {
        display: none;
      }
    }
    > div.active {
      font-weight: ${({ theme }: { theme: any }) => theme.font_semibold};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
      color: ${({ theme }: { theme: any }) => theme.tonal};
      color: ${({ theme }: { theme: any }) => theme.selected_primary};
      :hover {
      }
    }
    > select {
      flex-grow: 1;
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      &:active {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
        outline: none;
      }
    }
  }
`;

interface IProps {
  innerSize: {
    W: number;
    H: number;
  };
  clientSize: {
    W: number;
    H: number;
  };
}

interface ITabProps {
  tabList: ITabObject[];
  currentTabMrCd: string;
  setCurrentTabMrCd: Dispatch<SetStateAction<string>>;
  onClickTab: (key: string) => void;
  size: IProps;
}

const Tab = ({ tabList, currentTabMrCd, setCurrentTabMrCd, onClickTab, size }: ITabProps) => {
  const { t } = useTranslation();
  const [pageInfo, setPageInfo] = useRecoilState<IPageInfo>(pageInfoState);
  const setOpenAside = useSetRecoilState(asideState);
  const setOpenSubAside = useSetRecoilState(asideSubState);

  const onChangeSelectOption = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    setCurrentTabMrCd(value);
    setPageInfo((prev) => ({ ...prev, mrCd: value })); // 보고서를 선택할 때 mrCd 전역저장
  };

  const onClickReport = (mrCd: string) => {
    onClickTab(mrCd);
    setPageInfo((prev) => ({ ...prev, mrCd })); // 보고서를 선택할 때 mrCd 전역저장
    setOpenSubAside(false); // 탭을 클릭했을 때 서브사이드메뉴 닫기
  };

  useEffect(() => {
    // 페이지 리프레시 할때 mrCd 디폴트값으로 변경
    setPageInfo((prev) => ({ ...prev, mrCd: pageInfo.defaultMrCd }));
  }, []);

  return (
    <Root W={size.innerSize.W}>
      <div className='tab-list'>
        <div className='icon-menu hamburger' role='presentation' onMouseDown={() => setOpenAside(true)}>
          <span className='material-symbols-rounded'>menu </span>
        </div>
        {size.innerSize.W < 640 ? (
          <select onChange={onChangeSelectOption} value={currentTabMrCd}>
            {tabList.map((item) => (
              <option key={v1()} value={item.mrCd}>
                {t(item.mrName)}
              </option>
            ))}
          </select>
        ) : (
          <>
            {tabList.map((item) => (
              <React.Fragment key={v1()}>
                <div className='seperator' />
                <div role='presentation' key={v1()} onClick={() => onClickReport(item.mrCd)} className={item.mrCd === currentTabMrCd ? 'active' : ''}>
                  {t(item.mrName)}
                </div>
              </React.Fragment>
            ))}
          </>
        )}
      </div>
      {size.innerSize.W >= 1280 ? (
        <div className='breadscrum'>
          <span className='s-color'>{t(pageInfo?.header.mtName)}</span>
          {pageInfo.header.mdName && (
            <>
              <span className='material-symbols-rounded'>chevron_right</span>
              <span className='s-color'>{t(pageInfo.header.mdName)}</span>
            </>
          )}
        </div>
      ) : (
        ''
      )}
    </Root>
  );
};

export default Tab;
