/**
 * 작성자 : 홍선영
 * 날짜 : 2024.05.20
 * 기능 : 근로자 출입 현황 대시보드 컴포넌트 (다중현장)
 */

import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PulseLoader } from 'react-spinners';
import { useQuery } from '@tanstack/react-query';

import useDashboardTimer from '../../utils/useDashboardTimer';
import { apiGet } from '../../services/_common';
import { PhotoTableListStyle } from '../../assets/styles/PhotoTableList';
import IssueGuide from '../IssueGuide';
import Slider from 'react-slick';
import { sortArrayByTimeDesc } from '../../utils/arraySortByTimeDescOrder';

interface Iprops {
  name: string;
  userInfo: any;
  refreshTimeCount: number;
  uniqueKey: string;
}

interface IworkerAttend {
  aImg?: any;
  wCd: string;
  wName: string;
  sjCd: string;
  sjName: string;
  wPrejobtype: string;
  wPrejobtypeName: string;
  wJobtype: string;
  wJobtypeName: string;
  aDate: string;
  fLocationName: string;
  aTime: string;
}

export const WorkerAttendMultiSite = ({ name, userInfo, refreshTimeCount, uniqueKey }: Iprops) => {
  const { t } = useTranslation();
  const { timer, setTimer } = useDashboardTimer(refreshTimeCount);
  const tabRefs = useRef<(any | null)[]>([]);
  const [tabList, setTabList] = useState<any[]>([]);
  const [slideTimer, setSlideTimer] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    if (timer <= 0) {
      refetch();
      setTimer(refreshTimeCount);
    }
  }, [timer]);

  // 근로자 출입현황 조회
  const { data, isLoading, isError, refetch, isFetching } = useQuery([`attendMultiGet_${uniqueKey}`, userInfo.hCd, userInfo.sCd], () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
  });

  const fetchData = async () => {
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
      const res = await apiGet({ path: `/attend/multi`, req });
      console.log('res', res);

      const attendList = res.data.data?.attendList || [];
      const totalWorkers = attendList.length;

      // 출역내역을 기반으로 현장별 근로자목록 생성
      const groupedData = await groupBy(attendList, 'sCd');
      const result = Object.keys(groupedData).map((sCd, i) => ({
        sCd,
        sName: groupedData[sCd][0].sName,
        worker: sortArrayByTimeDesc(groupedData[sCd]),
        sliderIndex: i,
        count: groupedData[sCd].length,
      }));

      // 현장코드로 정렬
      result.sort((a: any, b: any) => {
        const numA = parseInt(a.sCd.substring(1), 10);
        const numB = parseInt(b.sCd.substring(1), 10);
        return numA - numB;
      });
      setTabList(result);

      // // 출역내역을 기반으로 탭목록 생성
      // const uniqueSites: any = Array.from(new Map(result.map((item) => [item.sCd, { code: item.sCd, sName: `${item.worker[0].sName} (${item.worker.length})` }])).values());
      // const addSliderIndex = uniqueSites.map((el: any, i: number) => ({ ...el, sliderIndex: i }));

      // const currentList = result.find((el: any) => el.sliderIndex === activeSlideNo);
      // if (currentList) {
      //   if (currentList.worker.length <= 70) setSlideTimer(currentList.worker.length);
      //   else setSlideTimer(currentList.worker.length * 1.3);
      //   return { list: currentList.worker, totalCount: totalWorkers };
      // }
      // {
      //   // 근로자 목록이 없으면 2초 뒤 다음탭 이동
      //   setSlideTimer(2);
      //   return null;
      // }
      return { result, totalWorkers };
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  const groupBy = (array: any, key: any) => {
    return array.reduce((result: any, currentValue: any) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
      return result;
    }, {});
  };

  const settings = {
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 1000,
    slidesToShow: data ? data?.result?.length : tabList.length,
    slidesToScroll: 1,
    autoplay: data ? data?.result?.length >= 2 : tabList.length >= 2,
    // autoplaySpeed: slideTimer <= 3 ? 5000 : slideTimer * 1400,
    autoplaySpeed: 20 * 1000,
    beforeChange: (current: number, next: number) => {
      if (data) {
        const nextTabLength = data?.result.find((el) => el.sliderIndex === next)?.worker.length;
        if (nextTabLength !== undefined) {
          setSlideTimer(nextTabLength);
        }
      }
      setActiveSlide(next);
    },
  };

  if (isError) return <IssueGuide />;

  if (isLoading || !settings || isFetching) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <PhotoTableListStyle>
      <div className='widget-header safety'>
        <div className='widget-title flex-between'>
          <span>
            {t('근로자 출입현황')}
            <span className='badge'>{data?.totalWorkers || 0}</span>
          </span>
        </div>
      </div>
      <div className='widget-body photoTable'>
        <div className='innerTab nonClick'>
          <Slider key={data?.result?.length} {...settings}>
            {data?.result?.map((el: any, i: number) => (
              <div key={el.sCd} className={activeSlide === el.sliderIndex ? 'active' : undefined} ref={tabRefs.current[i]}>
                <span className='tabName'>{el.sName}</span> ({el.count})
              </div>
            ))}
          </Slider>
        </div>
        <div className='sliderWrapper'>
          <div className='text-container active'>
            <WorkerList key={activeSlide} workerList={data?.result.find((el) => el.sliderIndex === activeSlide)?.worker || []} />
          </div>
        </div>
      </div>
    </PhotoTableListStyle>
  );
};

const WorkerList = ({ workerList }: { workerList: IworkerAttend[] }) => {
  const lastElementRef = useRef(null); // Create a ref for the last element
  const [animationKey, setAnimationKey] = useState(0);

  useEffect(() => {
    setAnimationKey((prevKey: any) => prevKey + 1);
  }, [workerList]);

  const animationStyle = {
    animation: `slide ${workerList.length * 1.6}s linear infinite`,
    paddingTop: `${workerList.length > 14 ? `2rem` : undefined}`,
  };

  if (workerList.length === 0) return null;
  return (
    <div key={animationKey} style={workerList.length > 14 ? animationStyle : undefined}>
      {workerList?.map((worker: IworkerAttend, index) => (
        <div key={`${worker.wCd}_${index}`} className='rowWrapper flex-basic' ref={index === workerList.length - 1 ? lastElementRef : null}>
          <div className='flex-basic'>
            <div className='imgWrapper'>{worker.aImg ? <img src={worker.aImg} alt='' /> : <span className='material-symbols-rounded'>person </span>}</div>
            <div className='flex-col'>
              <div className='flex-basic flex-between'>
                <div className='ellipsis' style={{ paddingRight: '0.5rem' }}>
                  {worker.wName}
                </div>
                <div className='buttonsWrapper flex-basic'>
                  <button type='button' className='amber'>
                    <span>{worker.fLocationName}</span>
                  </button>
                </div>
              </div>

              <div className='flex-basic flex-between'>
                <div style={{ paddingRight: '0.5rem' }}>
                  {worker.sjName} {worker.wJobtypeName ? `/ ${worker.wJobtypeName}` : ''}
                </div>
                <div className='buttonsWrapper flex-basic'>
                  <button type='button' className='gray'>
                    <span>{worker.aTime}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
