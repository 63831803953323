/**
 * 작성자 : 김광민
 * 날짜 : 2024.03.05
 * 기능 : 상태를 토글할 수 있는 칩 형태의 버튼 컴포넌트
 */

import styled from 'styled-components';

const Root = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 2.5rem;
  background-color: ${({ theme }: { theme: any }) => theme.tonal};
  padding-right: 1rem;
  padding-left: 0.5rem;
  border-radius: 2rem;
  gap: 0.375rem;
  border: 2px solid ${({ theme }: { theme: any }) => theme.outline};
  .material-symbols-rounded {
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
    color: ${({ theme }: { theme: any }) => theme.outline_em};
  }
  .text {
    color: ${({ theme }: { theme: any }) => theme.text_disabled};
    font-size: 0.875rem;
    letter-spacing: normal;
  }
  &.isClicked {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
    border-color: ${({ theme }: { theme: any }) => theme.tonal_deep_blue};
    &:hover {
      /* background-color: ${({ theme }: { theme: any }) => theme.tonal_deep_blue}; */
    }
    .material-symbols-rounded {
      color: ${({ theme }: { theme: any }) => theme.filled_blue};
    }
    .text {
      color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
    }
  }
`;

type Props = {
  onClick: () => void;
  status: boolean;
  text: string;
};

const CheckChipButton = ({ onClick, status, text }: Props) => {
  return (
    <Root role='button' tabIndex={0} onClick={onClick} className={status ? 'isClicked' : ''}>
      <span className='material-symbols-rounded'>check_circle</span>
      <span className='text'>{text}</span>
    </Root>
  );
};

export default CheckChipButton;
