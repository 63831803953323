/**
 * 작성자 : 홍선영
 * 날짜 : 2024.07.11
 * 경로 : 안전관리 - 안전보건 교육 시험 보고서
 */

import styled from 'styled-components';
import dayjs from 'dayjs';
import { PulseLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';

import i18n from '../../../translation/i18n';
import { LIST_COUNT } from '../../../_constants';
import { IUser, userState } from '../../../atoms';
import { ContentsContainerRoot } from '../../../assets/styles/ContentsContainerRoot';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import { useSetAuth } from '../../../utils/useSetAuth';
import { useGetFrozenCount } from '../../../utils/getFrozenCount';
import useSetListCount from '../../../utils/useSetListCount';
import Portal from '../../../components/Portal';
import TuiGrid from '../../../components/Table/TuiGrid';
import IssueGuide from '../../../components/IssueGuide';
import TuiGridWrapper from '../../../components/Table/TuiGridWrapper';
import InfoWorkerModal from '../../../components/Modal/InfoWorkerModal';
import SafeeduExamResultSearchOption from './safeeduExamResultSearchOption';
import { logPost } from '../../../services/log';

const Root = styled(ContentsContainerRoot)`
  .btn-in-cell {
    height: 2rem;
    font-size: 0.875rem;
    padding-left: 0.5rem;
    padding-right: 0.75rem;
    margin: 0 auto;
    font-weight: 600;
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.board};
    }
    .material-symbols-rounded {
      margin-right: 0.25rem;
    }
  }
  .btn-blue {
    background-color: ${({ theme }: { theme: any }) => theme.filled_blue} !important;
    border: none !important;
    color: ${({ theme }: { theme: any }) => theme.color.blue_100} !important;
  }
  .btn-gray {
    background-color: ${({ theme }: { theme: any }) => theme.text_disabled} !important;
    border: none !important;
    color: ${({ theme }: { theme: any }) => theme.text_reverse} !important;
  }

  .badge {
    font-weight: 500;
    font-size: 0.875rem;
    padding: 0.125rem 0.5rem;
    border-radius: 0.25rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
    margin: 0 0.5rem;
    color: rgb(62, 151, 255);
    display: inline-block;
    min-width: 1.75rem;
    text-align: center;
  }

  .blue {
    color: ${({ theme }: { theme: any }) => theme.filled_blue};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
  }

  .red {
    color: ${({ theme }: { theme: any }) => theme.filled_red};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
  }
`;

const SafeeduExamResult = () => {
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const userInfo = useRecoilValue<IUser>(userState);
  const { eExcelYn, eListYn } = userInfo;
  const { t } = useTranslation();
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [tableState, setTableState] = useState<any[]>([]); // 엑셀데이터 추출용
  const [filterTableState, setFilterTableState] = useState<any[]>([]);
  const [viewTableState, setViewTableState] = useState<any[]>([]); // 보고서 뷰 용
  const componentRef = useRef<HTMLDivElement>(null);
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  const [tuiWidth, setTuiWidth] = useState<null | number>(null);
  const [columns, setColumns] = useState<any[]>([]);
  const [excelBtn, setExcelBtn] = useState(false);
  const [rowKey, setRowKey] = useState('');
  const { listCount, setListCount } = useSetListCount('15'); // 검색줄수설정 훅
  const frozenCount = useGetFrozenCount();
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '', code: '', index: 0 });
  const [refetchFlag, setRefetchFlag] = useState(false);

  useEffect(() => {
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '안전 교육 > 안전보건 교육 시험 보고서',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    if (componentRef.current !== null) setTuiHeight(componentRef.current.offsetHeight);
  }, [componentRef.current?.offsetHeight]);

  useEffect(() => {
    if (componentRef.current !== null && componentRef.current !== undefined) setTuiWidth(componentRef.current?.offsetWidth);
  }, [componentRef.current?.offsetWidth]);

  // 근로자 Row 클릭
  const onClickRow = (rowData: any, columnName: any, filteredIndex: number) => {
    setRowKey(`${filteredIndex}`);
    setOpenModal({ status: true, type: 'infoWorker', code: rowData?.wCd, aDate: dayjs().format('YYYY-MM-DD'), index: filteredIndex });
  };

  // 근로자정보 모달에서 정보 수정 시 flag를 true로 변경하고 refetch하여 tuigrid 목록 재조회
  const onClickRefresh = () => {
    setRefetchFlag(true);
  };

  const fixedPrefixColumns = [
    {
      header: t('근로자명'),
      name: 'wName',
      sortable: true,
      minWidth: 130,
      renderer: { classNames: ['text_secondary'] },
    },
    {
      header: t('교육일자'),
      name: 'examDate',
      align: 'center',
      sortable: true,
      minWidth: 120,
    },
    {
      header: t('협력업체'),
      name: 'sjName',
      sortable: true,
      filter: 'select',
      className: '',
      width: 160,
      renderer: { classNames: ['text_secondary'] },
    },
  ];

  const prejobtypeColumn = {
    header: t('공종'),
    name: 'prejobtypeName',
    sortable: true,
    filter: 'select',
    minWidth: 120,
  };

  const fixedSuffixColumns = [
    {
      header: t('직종'),
      name: 'jobtypeName',
      sortable: true,
      filter: 'select',
      minWidth: 120,
    },
    {
      header: t('1'),
      name: 'result1',
      align: 'center',
      minWidth: 40,
    },
    {
      header: t('2'),
      name: 'result2',
      align: 'center',
      minWidth: 40,
    },
    {
      header: t('3'),
      name: 'result3',
      align: 'center',
      minWidth: 40,
    },
    {
      header: t('4'),
      name: 'result4',
      align: 'center',
      minWidth: 40,
    },
    {
      header: t('5'),
      name: 'result5',
      align: 'center',
      minWidth: 40,
    },
    {
      header: t('6'),
      name: 'result6',
      align: 'center',
      minWidth: 40,
    },
    {
      header: t('7'),
      name: 'result7',
      align: 'center',
      minWidth: 40,
    },
    {
      header: t('8'),
      name: 'result8',
      align: 'center',
      minWidth: 40,
    },
    {
      header: t('9'),
      name: 'result9',
      align: 'center',
      minWidth: 40,
    },
    {
      header: t('10'),
      name: 'result10',
      align: 'center',
      minWidth: 40,
    },
    {
      header: t('총점수'),
      name: 'texamResult',
      align: 'center',
      sortable: true,
      minWidth: 120,
    },
    {
      header: t('결과'),
      name: 'texamResultName',
      align: 'center',
      sortable: true,
      minWidth: 120,
    },
    {
      header: t('근로 상태'),
      name: 'wWorkstatusName',
      align: 'center',
      sortable: true,
      filter: 'select',
      minWidth: 120,
    },
  ];

  useEffect(() => {
    const col = [...fixedPrefixColumns, userInfo.prejobtypeYn === 'Y' && prejobtypeColumn, ...fixedSuffixColumns];
    setColumns(col);
  }, [userInfo.prejobtypeYn, i18n.language]);

  const loading = isFetching;

  if (isError) return <IssueGuide />;

  return (
    <>
      <div className='content-container oneColumn'>
        <Root loading={loading}>
          <SafeeduExamResultSearchOption
            setIsFetching={setIsFetching}
            setIsError={setIsError}
            tableState={tableState}
            setTableState={setTableState}
            setViewTableState={setViewTableState}
            setRowKey={setRowKey}
            listCount={listCount}
            setListCount={setListCount}
            refetchFlag={refetchFlag}
            setRefetchFlag={setRefetchFlag}
            setExcelBtn={setExcelBtn}
          />
          <TuiGridWrapper componentRef={componentRef}>
            {tuiWidth !== null && (
              <TuiGrid
                data={viewTableState}
                filterTableState={filterTableState}
                setFilterTableState={setFilterTableState}
                columns={columns}
                perPage={Number(listCount[LIST_COUNT])}
                // excelBtn={excelBtn}
                // setExcelBtn={setExcelBtn}
                usePagenation
                onClickRow={onClickRow}
                scrollX
                height={tuiHeight}
                rowKey={rowKey}
                eListYn={eListYn}
                eExcelYn={eExcelYn}
                rowHeight={48}
                frozenCount={frozenCount}
                setRowKey={setRowKey}
              />
            )}
          </TuiGridWrapper>
          <div style={{ display: 'none' }}>
            <TuiGrid
              data={tableState}
              filterTableState={filterTableState}
              setFilterTableState={setFilterTableState}
              columns={columns}
              perPage={Number(listCount[LIST_COUNT])}
              excelBtn={excelBtn}
              setExcelBtn={setExcelBtn}
              usePagenation
              onClickRow={onClickRow}
              scrollX
              height={tuiHeight}
              rowKey={rowKey}
              eListYn={eListYn}
              eExcelYn={eExcelYn}
              rowHeight={48}
              frozenCount={frozenCount}
              setRowKey={setRowKey}
            />
          </div>
        </Root>
      </div>
      {loading && (
        <LoadingModalBackground>
          <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
        </LoadingModalBackground>
      )}
      <Portal openModal={openModal?.status}>
        {openModal.status && openModal.type === 'infoWorker' && openModal.code !== undefined && (
          <InfoWorkerModal
            innerTabIndex={0}
            getReportListAPI={onClickRefresh}
            reportArray={filterTableState}
            setReportArray={setFilterTableState}
            setOpenModal={setOpenModal}
            auth={auth}
            code={{ hCd: userInfo.hCd, sCd: userInfo.sCd, wCd: openModal.code, aDate: openModal.aDate }}
            index={openModal.index}
            setRowKey={setRowKey}
            listCount={Number(listCount[LIST_COUNT])}
          />
        )}
      </Portal>
    </>
  );
};

export default SafeeduExamResult;
