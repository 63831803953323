/**
 * 작성자 : 홍선영
 * 날짜 : 2023.03.29
 * 기능 : YYYYMMDDHHMMSS(e.g 20220326173851) 형태의 string 날짜값을 YYYY-MM-DD HH:MM:SS로 변경하는 함수
 *
 * 수정자 : 한영광
 * 날짜 : 2023.05.11
 * 기능 : yyyymmdd(e.g 20220326) 형태의 string 날짜값을 YYYY-MM-DD로 변경하는 함수 추가
 */

export const pad = (number: number, length: number) => {
  let str = number.toString();
  while (str.length < length) {
    str = `0${str}`;
  }
  return str;
};

export const formatDate = (dateString: string) => {
  if (dateString) {
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    const hour = dateString.slice(8, 10);
    const minuite = dateString.slice(10, 12);
    const second = dateString.slice(12, 14);

    if (!hour && !minuite && !second) return `${year}-${month}-${day}`;
    return `${year}-${month}-${day} ${hour}:${minuite}:${second}`;
  }
  return '';
};

export const getFirstDayOfMonth = (year: number, month: number) => {
  const date = new Date(year, month - 1, 1);
  const yyyy = date.getFullYear().toString();
  const MM = pad(date.getMonth() + 1, 2);
  const dd = pad(date.getDate(), 2);
  return `${yyyy}-${MM}-${dd}`;
};

export const getLastDayOfMonth = (year: number, month: number) => {
  const date = new Date(year, month, 0);
  const yyyy = date.getFullYear().toString();
  const MM = pad(date.getMonth() + 1, 2);
  const dd = pad(date.getDate(), 2);
  return `${yyyy}-${MM}-${dd}`;
};

export const formatDateYMD = (dateString: string) => {
  if (dateString) {
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    return `${year}-${month}-${day}`;
  }
  return null;
};

export const todayYYYYMMDD = () => {
  const nowDate = new Date();
  const yyyy = nowDate.getFullYear().toString();
  const MM = pad(nowDate.getMonth() + 1, 2);
  const dd = pad(nowDate.getDate(), 2);
  return yyyy + MM + dd;
};

export const toMonthYYYMMDD = () => {
  const nowDate = new Date();
  const yyyy = nowDate.getFullYear().toString();
  const MM = pad(nowDate.getMonth() + 1, 2);
  const dd = pad(1, 2);
  return yyyy + MM + dd;
};

export const today = () => {
  const nowDate = new Date();
  const yyyy = nowDate.getFullYear().toString();
  const MM = pad(nowDate.getMonth() + 1, 2);
  const dd = pad(nowDate.getDate(), 2);
  return `${yyyy}-${MM}-${dd}`;
};

// Format a string of form "YYYYMMDDHHMMSS" into a JavaScript Date object
export const formatDateType = (str: any) => {
  if (!str || str.length !== 14) return null;
  const year = parseInt(str.slice(0, 4), 10);
  const month = parseInt(str.slice(4, 6), 10) - 1; // JavaScript months are 0-indexed
  const day = parseInt(str.slice(6, 8), 10);
  const hours = parseInt(str.slice(8, 10), 10);
  const minutes = parseInt(str.slice(10, 12), 10);
  const seconds = parseInt(str.slice(12, 14), 10);
  return new Date(year, month, day, hours, minutes, seconds);
};

export const formatDateToStr = (date: Date) => {
  if (!date) {
    return '';
  }
  const Date = date;
  const yyyy = Date.getFullYear().toString();
  const MM = pad(Date.getMonth() + 1, 2);
  const dd = pad(Date.getDate(), 2);
  return yyyy + MM + dd;
};

export const formatDateToStrBar = (date: Date) => {
  if (!date) {
    return '';
  }
  const Date = date;
  const yyyy = Date.getFullYear().toString();
  const MM = pad(Date.getMonth() + 1, 2);
  const dd = pad(Date.getDate(), 2);
  return `${yyyy}-${MM}-${dd}`;
};

export const formatDateStringToStrBar = (dateStr: string) => {
  if (!dateStr) {
    return '';
  }
  if (dateStr.length === 8) {
    return `${dateStr.slice(0, 4)}-${dateStr.slice(4, 6)}-${dateStr.slice(6)}`;
  }
  return dateStr;
};

export const todayDiffDay = (date: any) => {
  if (!date) return true;
  const nowDate = new Date();
  const yyyy = nowDate.getFullYear().toString();
  const MM = pad(nowDate.getMonth() + 1, 2);
  const dd = pad(nowDate.getDate(), 2);
  return Number(date) > Number(`${yyyy}${MM}${dd}`);
};

export const diffDay = (oldDate: any, newDate: any) => {
  const date1 = new Date(oldDate);
  const date2 = new Date(newDate);
  let diff = Math.abs(date2.getTime() - date1.getTime());
  diff = Math.ceil(diff / (1000 * 60 * 60 * 24));
  return diff;
};

export const diffTime = (time: any) => {
  const hours = Math.floor(time / 3600); // 시간
  const remainingSecondsAfterHours = time % 3600;
  const min = Math.floor(remainingSecondsAfterHours / 60); // 분
  const sec = remainingSecondsAfterHours % 60; // 초
  return { hours, min, sec };
  // return `${hours}시간 ${min}분 ${sec}초`;
};

export const isValidYYYYMMDD = (str: string) => {
  if (str?.length !== 8 || !/^\d{8}$/.test(str)) return false;

  const year = parseInt(str.substring(0, 4), 10);
  const month = parseInt(str.substring(4, 6), 10);
  const day = parseInt(str.substring(6, 8), 10);

  if (month < 1 || month > 12) return false;

  if (month === 2) {
    const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    return (isLeapYear && day >= 1 && day <= 29) || (!isLeapYear && day >= 1 && day <= 28);
  }

  if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
    return day >= 1 && day <= 31;
  }

  if ([4, 6, 9, 11].includes(month)) {
    return day >= 1 && day <= 30;
  }

  return false;
};

// YYYY-MM-DD HH:MM:SS 형식을 MM-DD HH:MM:SS으로 변환
export const formatMMDDHHMMSS = (str: string) => {
  if (str) return `${str?.substring(5, 10)} ${str?.substring(11)}`;
  return null;
};

export const formatDateToYYYYMMDDHHMMSS = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}${month}${day}${hours}${minutes}${seconds}`;
};

export const getDayStartAndEndTimeStamps = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const todayString = `${year}-${month}-${day}`;

  return { start: `${todayString} 00:00:00`, end: `${todayString} 23:59:59` };
};
