/**
 * 작성자 : 한영광
 * 날짜 : 2023.10.11
 * 기능 : 카메라 불러오기 모달
 */
import { IModal } from 'customTypes';
import { BtnBlue, BtnRed, BtnWhite } from '../Button';
import { ModalBackground, Modal } from '../../assets/styles/Modal';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const CameraLoadModal = ({ openModal, setOpenModal, cameraLoadList, cameraTableState }: any) => {
  const { t } = useTranslation();
  const { setCameraLoadList, onClickAddCameraTableArray } = openModal;
  const [selectAll, setSelectAll] = useState(true);

  // 닫기버튼 클릭
  const onClickClose = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };

  // 선택버튼 클릭
  const onClickSelect = () => {
    onClickAddCameraTableArray(cameraLoadList);
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectAll(false);
      for (const v of cameraLoadList) {
        v.check = false;
      }
    } else {
      setSelectAll(true);
      for (const v of cameraLoadList) {
        v.check = true;
      }
    }
  };

  const handleCheckboxChange = (index: number) => {
    if (cameraLoadList[index].check) {
      cameraLoadList[index].check = false;
    } else {
      cameraLoadList[index].check = true;
    }
    setCameraLoadList([...cameraLoadList]);
    const allCheck = cameraLoadList.filter((v: any) => v.check);
    if (allCheck.length === cameraLoadList.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };
  const [selectedCamera, setSelectedCamera] = useState(0);
  useEffect(() => {
    setSelectedCamera(cameraLoadList.filter((el: any) => el.check === true).length);
  }, [cameraLoadList.filter((el: any) => el.check === true).length]);
  return (
    <ModalBackground>
      <Modal>
        <div className='modal'>
          <div className='inputForm-head'>
            <div className='modalHeaderTitle camera-channel'>
              {t('카메라 채널')}
              <span className='camera-type'>
                <span className='online'>
                  <span className='bullet' />
                  ONLINE
                </span>
                <span className='offline'>
                  <span className='bullet' />
                  OFFLINE
                </span>
                <span className='connected'>{t('기 추가된 카메라')}</span>
              </span>
            </div>
            <div className='closeBtn' onClick={onClickClose} role='presentation'>
              <span className='material-symbols-rounded'>close</span>
            </div>
          </div>
          <div className='camera-channel-bar'>
            <label htmlFor='all'>
              <input id='all' type='checkbox' checked={selectAll} onChange={() => handleSelectAllChange()} />
              {cameraLoadList.length === selectedCamera ? t('전체선택 해제') : t('전체선택')}
            </label>
            <span className='guide'>
              <span>{selectedCamera}</span>
              {t('개의 카메라가 선택되었습니다.')}
            </span>
          </div>
          <div className='inputForm-body camera-channel'>
            <ul>
              {cameraLoadList.map((item: any, index: number) => (
                <li key={`${item.name}_${index}`}>
                  <label htmlFor={item.id}>
                    <input id={item.id} type='checkbox' checked={item.check} onChange={() => handleCheckboxChange(index)} />
                    <span className='camera-name'>{item.name}</span>
                    <div className={`badge ${item.online === 'true' ? 'green' : 'red'}`}>
                      <span className='bullet' />
                      <span className='bullet-status'>{item.online === 'true' ? 'ONLINE' : 'OFFLINE'}</span>
                    </div>
                  </label>
                </li>
              ))}
              {cameraTableState.map((item: any, index: number) => (
                <li key={`${item.cName}_${index}`}>
                  <label htmlFor={item.cName} className='none-select'>
                    <input id={item.id} type='checkbox' checked disabled />
                    <span className='camera-name'>{item.cName}</span>
                  </label>
                </li>
              ))}{' '}
            </ul>
          </div>
          <div className='modal-footer flex-between'>
            <div className='tipballoon text-sm'>
              <span className='material-symbols-rounded'>mood</span>
              {t('선택된 카메라를 CCTV 목록에 추가합니다')}
            </div>
            <BtnBlue onClick={onClickSelect}>{t('추가')}</BtnBlue>
          </div>
        </div>
      </Modal>
    </ModalBackground>
  );
};

export default CameraLoadModal;
