class CustomBtnRenderer {
  element: HTMLElement;
  root: any;

  constructor(props: any) {
    const { onClickDetailBtn, t } = props.columnInfo.renderer.options;
    const { rowKey } = props;

    this.element = document.createElement('button');
    this.element.textContent = t('상세보기');
    this.element.style.backgroundColor = '#3b82f6';
    this.element.style.height = '2rem';
    this.element.style.color = 'white';
    this.element.style.padding = '0 0.75rem 0 0.5rem';
    this.element.style.margin = '0 auto';
    this.element.style.border = 'none';
    this.element.style.borderRadius = '4px';
    this.element.style.cursor = 'pointer';
    this.element.style.fontSize = '0.875rem';

    this.element.onmouseover = () => {
      this.element.style.backgroundColor = '#60a5fa';
    };
    this.element.onmouseout = () => {
      this.element.style.backgroundColor = '#3b82f6';
    };
    this.element.addEventListener('click', () => {
      const findIndex = props.grid.store.data.filteredRawData?.findIndex((el: any) => el.rowKey === rowKey);
      if (findIndex !== -1) onClickDetailBtn(findIndex);
      else onClickDetailBtn(rowKey);
    });
  }

  getElement() {
    return this.element;
  }
  destroy() {
    this.root.unmount();
  }
}
export default CustomBtnRenderer;
