/*
 * 작성자 : 김광민
 * 날짜 : 2024.07.04
 * 기능 : 상황판에서 가로 사이즈 5 기상종합 컴포넌트 중 현재날씨
 */

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import i18n from '../../translation/i18n';
import { useEffect, useState } from 'react';
import { ReactComponent as Umbrella } from '../../assets/images/icons/umbrella.svg';
import { ReactComponent as SnowFlower } from '../../assets/images/icons/snow-flower.svg';
import { ReactComponent as SnowIce30 } from '../../assets/images/icons/snow-ice30.svg';
import { ReactComponent as SnowBall } from '../../assets/images/icons/snow-ball.svg';
import { ReactComponent as Sun } from '../../assets/images/icons/sun.svg';
import { ReactComponent as SunCloud } from '../../assets/images/icons/sunCloud.svg';
import { ReactComponent as Blur } from '../../assets/images/icons/blur.svg';
import { PulseLoader } from 'react-spinners';

const Root = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  padding: 0 1.5rem;
  padding: 0 1rem;
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
  * {
    color: ${({ theme }: { theme: any }) => theme.board};
    line-height: normal;
  }
  .loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .unit {
    font-weight: 200;
    margin-left: 0.25rem;
  }
  .seperator {
    height: 1rem;
    border-left: 0.125rem solid ${({ theme }: { theme: any }) => theme.alpha_25};
    margin-left: 0.25rem;
  }
  .title {
    height: 4rem;
    width: 100%;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.75rem;
    font-weight: 700;
  }
  .header {
    flex-grow: 1;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    .tempNow {
      font-size: 3.5rem;
      font-weight: 600;
      /* margin: 0.5rem 0; */
    }
    .discrict {
      font-size: 3rem;
      font-weight: 400;
      &:before {
        content: '•';
        margin-right: 1rem;
        color: ${({ theme }: { theme: any }) => theme.alpha_50};
      }
    }
  }
  .info {
    z-index: 100;
    width: 100%;
    display: flex;
    flex-direction: column;
    .tempNow {
      font-size: 1.75rem;
      font-weight: 600;
      margin-top: 0.5rem;
    }
    .discrict {
      font-size: 3rem;
      font-weight: 400;
      &:before {
        content: '•';
        margin-right: 1rem;
        color: ${({ theme }: { theme: any }) => theme.alpha_50};
      }
    }
    .maxAndMin {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    .details {
      display: flex;
      align-items: center;
      gap: 0.375rem;
      .material-symbols-rounded {
        font-size: 1.375rem;
        font-variation-settings: 'FILL' 1, 'wght' 300;
      }
      .label {
        font-size: 0.875rem;
        font-weight: 300;
      }
      .value {
        font-size: 0.875rem;
        font-weight: 500;
      }
    }
  }
  .weatherAnimationWrapper {
    .svg-container {
      position: initial;
      &.floating {
        position: absolute;
        left: auto;
        right: 0;
        top: 17.5%;
        top: 20.5%;
      }
      &.bouncing {
        position: absolute;
        left: auto;
        right: 0;
        top: 17.5%;
        top: 22.5%;
      }
      &.sunCloud {
        top: -10%;
        right: 4%;
      }
    }
    svg {
      width: 7rem;
      height: 7rem;
    }
    .rotating-sun {
      animation: rotation 10s infinite linear;
      position: absolute;
      top: 20%;
      right: 1rem;
      right: 3.5rem;
      transform: translateY(-50%);
      z-index: 100;
    }
    .little_cloud {
      position: absolute;
      right: 3rem;
      top: 0;
      &.beside-sun {
        z-index: 999;
        right: 3rem;
        top: 4rem;
      }
    }
    .blur {
      position: absolute;
      right: 4rem;
      top: -40%;
    }
    .cloudy {
      left: 25% !important;
      top: 17%;
    }
    .snowdrop svg {
      width: 4rem;
      height: 4rem;
    }
  }
`;

const animateWeather = (showSvg: boolean, weather: string) => {
  switch (weather) {
    case 'sunny': {
      return (
        <div className='container'>
          <div className='svg-container show-svg '>
            <Sun className='rotating-sun' />
          </div>
        </div>
      );
    }

    case 'little_cloud': {
      return (
        <div className='container'>
          <div className={`svg-container ${showSvg ? 'show-svg floating' : ''}`}>
            <SunCloud className='little_cloud' />
          </div>
        </div>
      );
    }

    case 'blur':
    case 'cloudy': {
      return (
        <div className='container'>
          <div className='svg-container show-svg floating'>
            <Blur className='blur' />
          </div>
        </div>
      );
    }

    case 'rain': {
      const newDrops: any[] = [];
      let increment = 0;
      while (increment < 95) {
        const randoHundo = Math.floor(Math.random() * 100);
        const randoFiver = Math.floor(Math.random() * 5) + 2;
        increment += randoFiver;

        const dropStyle = {
          left: `${increment}%`,
          animationDelay: `0.${randoHundo}s`,
          animationDuration: `0.8${randoHundo}s`,
        };

        newDrops.push(<div className='raindrop' style={dropStyle} key={increment} />);
      }

      return (
        <div className='container'>
          {newDrops}
          <div className={`svg-container ${showSvg ? 'show-svg bouncing' : ''}`}>
            <Umbrella style={{ position: 'absolute', right: '4rem', top: '-50%' }} />
          </div>
        </div>
      );
    }
    case 'shower': {
      const newDrops: any[] = [];
      let increment = 0;
      while (increment < 95) {
        const randoHundo = Math.floor(Math.random() * 100);
        const randoFiver = Math.floor(Math.random() * 5) + 2;
        increment += randoFiver;

        const dropStyle = {
          left: `${increment}%`,
          animationDelay: `0.${randoHundo}s`,
          animationDuration: `0.3${randoHundo}s`,
        };

        newDrops.push(<div className='raindrop' style={dropStyle} key={increment} />);
      }
      return (
        <div className='container'>
          {newDrops}
          <div className={`svg-container ${showSvg ? 'show-svg bouncing' : ''}`}>
            <Umbrella style={{ position: 'absolute', right: '5%', top: '-30%' }} />
          </div>
        </div>
      );
    }

    case 'sleet': {
      const newDrops: any[] = [];
      let increment = 0;
      const types = ['raindrop', 'snowice', 'sunflower'];

      while (increment < 95) {
        const randoHundo = Math.floor(Math.random() * 100);
        const randoFiver = Math.floor(Math.random() * 5) + 2;
        const randoLeftRight = Math.floor(Math.random() * 5) - 2;
        const randomType = types[Math.floor(Math.random() * types.length)];

        increment += randoFiver;

        let animationDuration;
        switch (randomType) {
          case 'raindrop':
            animationDuration = `${2 + Math.random()}s`;
            break;
          case 'snowice':
            animationDuration = `${5 + Math.random()}s`;
            break;
          case 'sunflower':
            animationDuration = `${7 + Math.random()}s`;
            break;
          default:
            animationDuration = `${5 + Math.random()}s`;
            break;
        }

        const dropStyle = {
          left: `${increment}%`,
          animationDelay: `0.${randoHundo}s`,
          animationDuration,
          '--random-left-right': `${randoLeftRight}vw`,
        };

        newDrops.push(
          <div className={randomType} style={dropStyle as React.CSSProperties} key={increment}>
            {randomType === 'snowice' && <SnowIce30 />}
            {randomType === 'sunflower' && <SnowFlower />}
          </div>
        );
      }

      return (
        <div className='container'>
          {newDrops}
          <div className={`svg-container little_cloud ${showSvg ? 'show-svg bouncing' : ''}`}>
            <Umbrella style={{ position: 'absolute', right: '4rem', top: '50%' }} />
          </div>
        </div>
      );
    }

    case 'snow': {
      const newDrops: any[] = [];
      let increment = 0;
      while (increment < 95) {
        const randoHundo = Math.floor(Math.random() * 100);
        const randoFiver = Math.floor(Math.random() * 5) + 2;
        const randoLeftRight = Math.floor(Math.random() * 5) - 2;
        increment += randoFiver;

        const dropStyle = {
          left: `${increment}%`,
          animationDelay: `0.${randoHundo}s`,
          animationDuration: `${5 + Math.random()}s`,
          '--random-left-right': `${randoLeftRight}vw`,
        };

        newDrops.push(
          <div className='snowdrop' style={dropStyle as React.CSSProperties} key={increment}>
            <SnowIce30 />
            <SnowBall />
          </div>
        );
      }

      return newDrops;
    }

    case 'sunCloud': {
      return (
        <div className='container'>
          <div className={`svg-container ${showSvg ? 'show-svg floating sunCloud' : ''}`}>
            <SunCloud className='little_cloud beside-sun' />
          </div>
          <Sun className='rotating-sun rotating-sunCloud' />
        </div>
      );
    }

    default: {
      return <Sun className='rotating-sun' />;
    }
  }
};

interface Props {
  currentWeather: any;
}

const WeatherTotalNow = ({ currentWeather }: Props) => {
  const { t } = useTranslation();

  const todayjs = dayjs(); // 현재 날짜와 시간을 가져옴
  let format = todayjs.format(`MM월 DD일 dddd`); // 기본 형식 설정

  // 언어가 영어일 경우 형식 변경
  if (i18n.language === 'en-US') {
    format = todayjs.locale('en').format(`MMMM D dddd`);
  } else {
    format = todayjs.locale('ko').format(`MM월 DD일 dddd`);
  }

  // SVG 표시 상태를 관리하는 상태
  const [showSvg, setShowSvg] = useState(false);

  // SVG 표시 상태를 관리하는 상태
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSvg(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Root className='weatherNow medium'>
      {currentWeather.tmp === '' ? (
        <div className='loading'>
          <PulseLoader color='rgb(255, 255, 255)' size='8px' />
        </div>
      ) : (
        <>
          <div className='info'>
            <div className='details'>
              <span className='material-symbols-rounded'>distance</span>
              <span className='label'>{t('위치')}</span>
              <span className='value'>{t(currentWeather.district)}</span>
            </div>
            <div className='tempNow'>
              {currentWeather.tmp}
              <span className='unit'>°</span>
            </div>
            <div className='maxAndMin'>
              <div className='details min'>
                <span className='material-symbols-rounded'>arrow_downward</span>
                <div className='value'>
                  {Math.floor(currentWeather.minTemp)}
                  <span className='unit'>°</span>
                </div>
              </div>
              <span className='seperator' />
              <div className='details max'>
                <span className='material-symbols-rounded'>arrow_upward</span>
                <div className='value'>
                  {Math.floor(currentWeather.maxTemp)}
                  <span className='unit'>°</span>
                </div>
              </div>
            </div>
            <div className='details'>
              <span className='material-symbols-rounded'>umbrella</span>
              <span className='label'>{t('강수확률')}</span>
              <span className='value'>
                {currentWeather.rainPct}
                <span className='unit'>%</span>
              </span>
            </div>
            <div className='details'>
              <span className='material-symbols-rounded'>masks</span>
              <span className='label'>{t('미세먼지')}</span>
              <span className='value'>
                {currentWeather.pm10}
                <span className='unit'>㎍/㎥</span>
              </span>
            </div>
            <div className='details'>
              <span className='material-symbols-rounded'>air</span>
              <span className='label'>{t('풍속')}</span>
              <span className='value'>
                {currentWeather.wsd}
                <span className='unit'>m/s</span>
              </span>
            </div>
          </div>
          <div className='weatherAnimationWrapper'>{animateWeather(showSvg, currentWeather.sky)}</div>
          {/* <div className='weatherAnimationWrapper'>{animateWeather(showSvg, 'sunny')}</div> */}
          {/* <div className='weatherAnimationWrapper'>{animateWeather(showSvg, 'little_cloud')}</div> */}
          {/* <div className='weatherAnimationWrapper'>{animateWeather(showSvg, 'blur')}</div> */}
          {/* {showSvg && <div className='weatherAnimationWrapper'>{animateWeather(showSvg, 'rain')}</div>} */}
          {/* {showSvg && <div className='weatherAnimationWrapper'>{animateWeather(showSvg, 'sleet')}</div>} */}
          {/* {showSvg && <div className='weatherAnimationWrapper'>{animateWeather(showSvg, 'snow')}</div>} */}
          {/* {showSvg && <div className='weatherAnimationWrapper'>{animateWeather(showSvg, 'sunCloud')}</div>} */}
        </>
      )}
    </Root>
  );
};

export default WeatherTotalNow;
