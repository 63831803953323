import styled from 'styled-components';

export const FloatingButtonToTop = styled.div`
  > button {
    all: unset;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    border-radius: 50%;
    background-color: #1261ff;
    position: absolute;
    bottom: 3rem;
    right: 3rem;
    margin: 0px auto;
    width: 40px;
    height: 40px;
    box-shadow: 0px 0px 1.5rem #eeeeee;
    transition: width 0.2s, height 0.2s ease-in-out 1s;

    &:hover {
      background-color: #eeeeee;
      width: 42px;
      height: 42px;
    }
  }
`;
