import { useTranslation } from 'react-i18next';
import { PulseLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import TunnelButterFlyChart from '../chart/TunnelButterFlyChart';
import { EndPoint, StartPoint } from '../TunnelPoint';
import { useRecoilValue } from 'recoil';
import { tInfoUpdateState } from '../../atoms';
import { apiGet } from '../../services/_common';

const ExcavationProcessLarge = ({ name, userInfo }: any) => {
  const { t } = useTranslation();
  const [tareaGrid, setTareaGrid] = useState<TareaData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const isTunnelInfoUpdated = useRecoilValue(tInfoUpdateState);

  // 터널 리스트 조회 API
  const fetchTareaData = async () => {
    setIsLoading(true);
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
      const res = await apiGet({ path: '/tarea', req });
      const {
        statusCode,
        data: { tareaList },
      } = res.data;
      if (statusCode === 200) {
        const result = tareaList.map((data: TareaData, idx: number) => ({ ...data, no: idx + 1 }));
        setTareaGrid(result);
      }
    } catch (error) {
      console.error('Error fetching tarea data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTareaData();
  }, [isTunnelInfoUpdated]);

  const onClickRefresh = () => fetchTareaData();

  if (isLoading) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <div className='equipInfo excavationProcessLarge'>
      <div className='widget-header margin-top'>
        <div className='widget-title flex-between'>
          <span className='tunnel-title'>
            <span className='tunnel-name'>{t('굴진 진행현황')}</span>
            <span className='tunnel-guide'>
              <StartPoint />
              <span className='seperator' />
              <EndPoint />
            </span>
          </span>
          <div className='refresh-btn' onClick={onClickRefresh} role='presentation'>
            <span className='material-symbols-rounded'>refresh</span>
          </div>
        </div>
      </div>
      <TunnelButterFlyChart data={tareaGrid} header={false} dashboard />
    </div>
  );
};

export default ExcavationProcessLarge;
