import styled from 'styled-components';

const Root = styled.div`
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  .material-symbols-rounded {
    font-size: 1.5rem;
    font-variation-settings: 'FILL' 1;
  }
  &.use {
    .material-symbols-rounded {
      color: ${({ theme }: { theme: any }) => theme.filled_green};
      &:hover {
        border-radius: 1rem;
        box-shadow: 0 0 0 2px ${({ theme }: { theme: any }) => theme.filled_green};
        background-color: ${({ theme }: { theme: any }) => theme.board};
      }
    }
  }
  &.noUse {
    .material-symbols-rounded {
      color: ${({ theme }: { theme: any }) => theme.tonal_deep};
      &:hover {
        border-radius: 1rem;
        box-shadow: 0 0 0 1px ${({ theme }: { theme: any }) => theme.filled_blue};
        background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
      }
    }
  }
`;

interface Props {
  use: boolean;
}

const TableLamp = ({ use }: Props) => {
  return (
    <Root //
      className={use ? 'use' : 'noUse'}
    >
      <span className='material-symbols-rounded'>{use ? 'check_circle' : 'circle'}</span>
    </Root>
  );
};

export default TableLamp;
