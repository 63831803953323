import styled from 'styled-components';
import ToggleButton from '../../../../components/button/ToggleButton';
import { foldButton, fullScreenButton, unfoldButton } from '../../../../components/button/DarkButtons';
import ClickableContainer from '../RealtimeCCTV/ClickableContainer';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import DarkSmallTabContainer from '../RealtimeCCTV/DarkSmallTabContainer';
import ButtonContainer from '../RealtimeCCTV/ButtonContainer';
import { WebSDKcctv } from '../../../../utils/webSDKcctvClass';

const Root = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  user-select: none;
  gap: 0.25rem;
  > div:first-child {
    margin-right: 0.75rem;
  }
`;

interface Props {
  cctvOBJ: WebSDKcctv | undefined; // cctv 객체
  visiblePanel: boolean;
  setVisiblePanel: Dispatch<SetStateAction<boolean>>;
  cctvDivisions: number;
  onClickDivision: (number: 1 | 2 | 3 | 4) => void;
  selectedCameraInfo: SelectedCctvCameraInfo;
  onClickSpeed: (speed: 'fast' | 'slow') => void;
}

const ControllLeftBar = ({
  cctvOBJ, //
  visiblePanel,
  setVisiblePanel,
  cctvDivisions,
  onClickDivision,
  selectedCameraInfo,
  onClickSpeed: onClickPlaybackSpeed,
}: Props) => {
  const isDisabled = selectedCameraInfo.ip === '' || !selectedCameraInfo.isPlaying;
  const { playbackSpeed } = selectedCameraInfo;
  const [speedLoading, setSpeedLoading] = useState(false);

  // 전체 화면
  const fullScreen = () => {
    cctvOBJ?.fullScreen();
  };

  // 화면 분할 Options 생성
  const gridOptions = Array.from({ length: 4 }, (_, index) => {
    const indexKey = index + 1;
    const selected = cctvDivisions === indexKey;
    const key = indexKey.toString();
    const name = (indexKey * indexKey).toString();
    return {
      key,
      selected,
      disabled: false,
      button: <ButtonContainer name={name} selected={selected} darkModeOnly />,
    };
  });

  const playSpeedButtonArray = [
    {
      key: '-1', //
      selected: false,
      button: <ButtonContainer icon='fast_rewind' selected={false} darkModeOnly />,
      disabled: speedLoading || isDisabled || playbackSpeed === 1,
    },
    {
      key: '1', //
      selected: false,
      button: <ButtonContainer icon='fast_forward' selected={false} darkModeOnly />,
      disabled: speedLoading || isDisabled || playbackSpeed === 4,
    },
  ];

  const onClickSpeed = (request: number) => {
    setSpeedLoading(true);
    onClickPlaybackSpeed(request === 1 ? 'fast' : 'slow');
    setTimeout(() => {
      setSpeedLoading(false);
    }, 500);
  };

  return (
    <Root>
      <ToggleButton // 사이드 패널 접기&펼치기
        onToggle={foldButton}
        offToggle={unfoldButton}
        setState={setVisiblePanel}
        state={visiblePanel}
      />
      <DarkSmallTabContainer
        icon='grid_view' // 화면 분할
        name='화면 분할'
        tabs={gridOptions}
        onClick={onClickDivision as (number: number) => void}
      />
      <DarkSmallTabContainer
        icon='speed' //
        name={`${playbackSpeed} 배속`}
        tabs={playSpeedButtonArray}
        onClick={onClickSpeed}
      />
      <ClickableContainer // 풀스크린
        button={fullScreenButton}
        onClick={fullScreen}
      />
    </Root>
  );
};

export default ControllLeftBar;
