/**
 * 작성자 : 한영광
 * 날짜 : 2023.06.26
 * 경로 : 출역 관리 - 업체별 출역현황
 */
import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { IoChevronUpSharp } from 'react-icons/io5';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { pageInfoState, userState } from '../../atoms';
import { BtnGhost } from '../../components/Button';
import { SearchOptions } from '../../assets/styles/SearchOptions';
import { useDetectScrolledToBottom } from '../../utils/useDetectScrolledToBottom';
import { FloatingButtonToTop } from '../../assets/styles/FloatingButtonToTop';
import { todayYYYYMMDD } from '../../utils/formatDate';
import { scrollToNodeTop } from '../../utils/scrollToNodeTop';
import RangePicker, { onChangeRangeInput } from '../../components/RangePicker';
import Tab from '../../components/Tab';
import useOnKeydownF9 from '../../utils/useOnKeydownF9';
import Portal from '../../components/Portal';
import AttendListModal from '../../components/Modal/AttendListModal';
import { ITabObject } from 'customTypes';
import SearchSelectBoxSm from '../../components/SearchSelectBoxSm';
import { useSetAuth } from '../../utils/useSetAuth';
import TuiGrid from '../../components/Table/TuiGrid';
import { LoadingModalBackground } from '../../assets/styles/Modal';
import useSetJobtype from '../../utils/useSetJobtype';
import TuiGridWrapper from '../../components/Table/TuiGridWrapper';
import { useGetFrozenCount } from '../../utils/getFrozenCount';
import { ContentsContainerRoot } from '../../assets/styles/ContentsContainerRoot';
import i18n from '../../translation/i18n';
import { logPost } from '../../services/log';
import { apiGet } from '../../services/_common';
import SelectBox from '../../components/SelectBox';
import ShortcutButton from '../../components/button/ShortcutButton';

const Root = styled(ContentsContainerRoot)`
  display: flex;
  flex-direction: column;
`;

interface IModal {
  status: boolean;
  type: string;
  searchOption: ISearchOption;
}
interface ISearchOption {
  sjName: string;
  sjCd: string;
  startDate: string;
  endDate: string;
}

const Sattend4 = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const INIT_F_NUM = { type: 'fNum', fNum: 'A', cdName: t('전체') };
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  const toDay = todayYYYYMMDD();
  const userInfo = useRecoilValue(userState);
  const { userMenuList } = userInfo;
  const [columns, setColumns] = useState<any[]>([]);
  const [summary, setSummary] = useState({});
  const tableRef = useRef<HTMLDivElement>(null);
  const [siteJoinInfoList, setSiteJoinInfoList] = useState<any[]>([]);
  const [orgSiteJoinInfoList, setOrgSiteJoinInfoList] = useState<any[]>([]);
  const [searchOption, setSearchOption] = useState({ start: toDay, end: toDay });
  const [sjCd, setSjCd] = useState({ type: 'sjCd', sjCd: '', cdName: '' });
  const [fNum, setFNum] = useState(INIT_F_NUM);
  const [wPrejobtype, setWPrejobtype] = useState({ type: 'wPrejobtype', wPrejobtype: '', cdName: '' });
  const [wJobtype, setWJobtype] = useState({ type: 'wJobtype', wJobtype: '', cdName: '' });
  const { prejobtypeList, jobtypeList } = useSetJobtype(wPrejobtype.wPrejobtype);
  const [visibleRangePicker, setVisibleRangePicker] = useState(false);
  const [rangeState, setRangeState] = useState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
  const [jikJongList, setJikJongList] = useState<any[]>([]);
  const [excelBtn, setExcelBtn] = useState(false);
  const [tableState, setTableState] = useState<any[]>([]);
  const [fNameList, setFnameList] = useState<any[]>([]);
  const { isBottom } = useDetectScrolledToBottom(tableRef);
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', searchOption: { sjName: '', sjCd: '', startDate: '', endDate: '' } });
  const [newTabList, setNewTabList] = useState<ITabObject[]>();
  const [loading, setLoading] = useState(false);
  const [initiate, setinitiate] = useState('');

  useEffect(() => {
    const arr: any[] = [];
    userMenuList.map((v: any) => {
      const result = tabInfo?.tabList?.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  useEffect(() => {
    getSjCdAPI().then((res: any) => {
      if (res.status === 200) {
        getAttendSitejoinAPI();
        getNormalCd();
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '업체별 출역현황',
          action: '조회',
          etc: ``,
        });
      }
    });
  }, []);

  useEffect(() => {
    const header = [];
    header.push({ header: t('협력업체'), name: 'sjName', align: 'left', filter: 'select', minWidth: 160, renderer: { classNames: ['text_secondary'] } });
    header.push({ header: t('출역인원'), name: 'cnt', align: 'center', width: 80, renderer: { classNames: ['text_secondary'] } });
    setColumns(header);

    setSummary({
      height: 30,
      position: 'top',
      columnContent: {
        sjName: t('전체'),
        cnt: {
          template: (valueMap: any) => {
            return `${valueMap.sum}`;
          },
        },
      },
    });
  }, [i18n.language]);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    if (!rangeState[0].startDate) {
      return;
    }
    setSearchOption((prev) => ({ ...prev, start: dayjs(rangeState[0].startDate).format('YYYY-MM-DD') }));
  }, [rangeState[0].startDate]);

  useEffect(() => {
    if (!rangeState[0].endDate) {
      return;
    }
    setSearchOption((prev) => ({ ...prev, end: dayjs(rangeState[0].endDate).format('YYYY-MM-DD') }));
  }, [rangeState[0].endDate]);

  useEffect(() => {
    setWJobtype({ type: 'wJobtype', wJobtype: '', cdName: '' });
    if (wPrejobtype.wPrejobtype === '') setJikJongList([]);
  }, [wPrejobtype.wPrejobtype]);

  useEffect(() => {
    setJikJongList(jobtypeList);
  }, [jobtypeList]);

  // 업체별 출역현황 조회 API
  const getAttendSitejoinAPI = async () => {
    const newSearchOption = {
      ...searchOption,
      fNum: fNum.fNum === 'A' ? '' : fNum.fNum,
      sjCd: sjCd.sjCd,
      aDate1: searchOption.start.replaceAll('-', ''),
      aDate2: searchOption.end.replaceAll('-', ''),
      wPrejobtype: wPrejobtype.wPrejobtype,
      wJobtype: wJobtype.wJobtype,
    };
    const { start, end, ...finalSearchObj } = newSearchOption; // searchOption에서 start, end값 삭제
    const req = { ...finalSearchObj, hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/attend/sitejoin', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      setLoading(false);
      setTableState(
        data.attendList.map((v: any) => {
          return {
            sjName: v.sjName,
            cnt: v.cnt,
          };
        })
      );
    } else {
      setLoading(false);
      // toast.error(t(ERROR));
    }
  };

  // 협력업체 리스트 조회 API
  const getSjCdAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, delYnCheck: 'Y' };
    const res = await apiGet({ path: '/siteJoin', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const newList = data.siteJoinList
        .filter((v1: any) => v1.useYn === 'Y')
        .map((v2: any) => {
          return {
            type: 'sjCd',
            sjCd: v2.sjCd,
            cdName: v2.sjName,
          };
        });
      setSiteJoinInfoList([{ type: 'sjCd', sjCd: '', cdName: t('전체') }, ...newList]);
      setOrgSiteJoinInfoList(
        data.siteJoinList.map((v: any) => {
          return {
            type: 'sjCd',
            sjCd: v.sjCd,
            cdName: v.sjName,
          };
        })
      );
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  // 장비 리스트 조회 API
  const getNormalCd = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, subCd: 'I' };
    const res = await apiGet({ path: '/code/normal/site', req });

    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const newList = [];
      newList.push(INIT_F_NUM);
      data.normalList.map((v: any) => {
        newList.push({ type: 'fNum', fNum: v.subCd, cdName: v.cdName });
      });
      setFnameList(newList);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 검색 버튼 클릭
  const onClickSearch = () => {
    if (!openModal.status) {
      if (searchOption.end && searchOption.start > searchOption.end) {
        toast.warning(t('날짜를 다시 입력하세요'));
        setSearchOption((prev) => ({ ...prev, end: '' }));
      } else {
        setLoading(true);
        setVisibleRangePicker(false);
        navigate({
          pathname: location.pathname,
          search: '',
        });
        getAttendSitejoinAPI();
      }
    }
  };

  const initiateSearchOptions = () => {
    setFNum(INIT_F_NUM);
    setSjCd({ type: 'sjCd', sjCd: '', cdName: '' });
    setWPrejobtype({ type: 'wPrejobtype', wPrejobtype: '', cdName: '' });
    setWJobtype({ type: 'wJobtype', wJobtype: '', cdName: '' });
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    Object.keys(searchOption).map((el: any) => {
      return setSearchOption((prev) => ({ ...prev, [el]: '', start: toDay, end: toDay }));
    });
    setinitiate(`${Math.random()}`);
    initiateSearchOptions();
    setRangeState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
  };

  // 엑셀 저장 버튼 클릭
  const onClickExcelSave = () => {
    if (tableState.length <= 0) return;
    setExcelBtn(true);
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '업체별 출역현황',
      action: '엑셀 저장',
      etc: ``,
    });
  };

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  // 인쇄 버튼 클릭
  const onClickPrint = () => {
    handlePrint();
  };

  // 협력업체 Row 클릭
  const onClickRow = (rowData: any, columnName: string) => {
    if (columnName !== 'total' && columnName !== '_number' && rowData && rowData[columnName] !== 0) {
      // const data = { sjName: rowData.sjName, aDate: columnName };
      const sjObject = orgSiteJoinInfoList.find((el) => el.cdName === rowData.sjName);
      if (sjObject) {
        const data = {
          sjName: rowData.sjName,
          sjCd: sjObject.sjCd,
          startDate: searchOption.start,
          endDate: searchOption.end,
        };
        setOpenModal({ status: true, type: 'dailyAttendList', searchOption: data });
        // navigate('/s_attend1', { state: data });
      }
    }
  };

  const renderExcelBtn = () => {
    if (auth.excelAuth) {
      return (
        <BtnGhost onClick={onClickExcelSave}>
          <span className='icon-ms-xlsx'>X</span> {t('엑셀')}
          {/* <span className='material-symbols-rounded'>download</span>저장 */}
        </BtnGhost>
      );
    }
    return null;
  };

  const renderPrintBtn = () => {
    if (auth.printAuth) {
      return (
        <BtnGhost onClick={onClickPrint}>
          <span className='material-symbols-rounded'>print</span>
          {t('인쇄')}
        </BtnGhost>
      );
    }
    return null;
  };

  const onClickRangeInput = () => {
    if (!visibleRangePicker) setVisibleRangePicker(true);
  };

  const onClickTab = (tab: string) => {
    setCurrentTabMrCd(tab);
  };
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight, componentRef.current?.offsetWidth]);

  const frozenCount = useGetFrozenCount();

  return (
    <div className='contents'>
      <div className='content-container oneColumn'>
        <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
        <Root loading={loading}>
          <SearchOptions>
            {isBottom && (
              <FloatingButtonToTop>
                <button type='button' onClick={() => scrollToNodeTop(tableRef)}>
                  <IoChevronUpSharp size={20} style={{ stroke: 'white' }} />
                </button>
              </FloatingButtonToTop>
            )}
            <div className='inputsWrapper'>
              <div className='inputForm-row withLabel'>
                <label htmlFor='sjCd'>{t('협력업체')}</label>
                <div className='inputForm-col'>
                  <SearchSelectBoxSm
                    options={siteJoinInfoList}
                    defaultOption={t('전체')}
                    state={sjCd}
                    setState={setSjCd}
                    stateKey='sjCd'
                    codeKey='cdName'
                    initiateKey={sjCd.sjCd}
                    filterbar='filter-1-left'
                    optionHeight='height-md'
                  />
                </div>
              </div>
              <div className='inputForm-col withLabelComCf'>
                <label htmlFor='wForeignyn'>{t('장비')}</label>
                <SelectBox options={fNameList} defaultOption={t('전체')} state={fNum} setState={setFNum} stateKey='fNum' initiateKey={fNum.fNum} filterbar='filter-1-left' optionHeight='height-md' />
              </div>
              <div className='inputForm-row-fit'>
                <span className='calendarLabel'>{t('출역일자')}</span>
                <div className='inputForm-col'>
                  <div className='flex-basic'>
                    <input id='startInput' type='text' value={searchOption.start} onMouseDown={onClickRangeInput} onChange={(e) => onChangeRangeInput(e, 'start', setRangeState, setSearchOption)} />
                    <span className='inputDash'> ~ </span>
                    <input id='endInput' type='text' value={searchOption.end} onMouseDown={onClickRangeInput} onChange={(e) => onChangeRangeInput(e, 'end', setRangeState, setSearchOption)} />
                  </div>
                  {visibleRangePicker && (
                    <div className='rangePickerWrapper'>
                      <RangePicker state={rangeState} setState={setRangeState} setVisible={setVisibleRangePicker} />
                    </div>
                  )}
                </div>
              </div>
              {userInfo.prejobtypeYn === 'Y' && (
                <div className='inputForm-row'>
                  <div className='inputForm-col'>
                    <SearchSelectBoxSm
                      options={prejobtypeList}
                      defaultOption={t('공종 전체')}
                      state={wPrejobtype}
                      setState={setWPrejobtype}
                      stateKey='wPrejobtype'
                      codeKey='cdName'
                      initiateKey={initiate}
                      filterbar='filter-1-center'
                      comboWidth='expand-box-sm'
                      optionHeight='height-md'
                    />
                  </div>
                </div>
              )}
              <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <SearchSelectBoxSm
                    options={jikJongList}
                    defaultOption={t('직종 전체')}
                    state={wJobtype}
                    setState={setWJobtype}
                    stateKey='wJobtype'
                    codeKey='cdName'
                    initiateKey={wJobtype.wJobtype}
                    filterbar='filter-1-center'
                    comboWidth='expand-box-sm'
                    optionHeight='height-md'
                  />
                </div>
              </div>
            </div>
            {/* <BtnGreen onClick={onClickSearch}>검색 (F9)</BtnGreen>
            <BtnGreen onClick={onClickInitiateSearchOption}>초기화</BtnGreen> */}
            <div className='inputsWrapper'>
              <div className='secondSearchOption'>
                <div className='flex-basic textBtnGroup'>
                  <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
                  {/* <BtnGhost onClick={onClickSearch} className='searchBtn'>
                    {t('검색')}
                    <span className='shortcut-f9'>F9</span>
                  </BtnGhost> */}
                  <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
                </div>
                <div className='flex-basic iconBtnGroup'>
                  {renderExcelBtn()}
                  {/* {renderPrintBtn()} */}
                </div>
              </div>
            </div>
          </SearchOptions>
          <TuiGridWrapper componentRef={componentRef} scrollXHidden>
            <TuiGrid
              data={tableState}
              columns={columns}
              perPage={5}
              excelBtn={excelBtn}
              setExcelBtn={setExcelBtn}
              onClickRow={onClickRow}
              summary={summary}
              height={tuiHeight}
              frozenCount={frozenCount}
              scrollX={false}
            />
          </TuiGridWrapper>
        </Root>
      </div>
      {loading && (
        <LoadingModalBackground>
          <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
        </LoadingModalBackground>
      )}
      <Portal openModal={openModal?.status}>{openModal.status && openModal.type === 'dailyAttendList' && <AttendListModal setOpenModal={setOpenModal} filter={openModal.searchOption} />}</Portal>
    </div>
  );
};

export default Sattend4;
