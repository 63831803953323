/* eslint-disable */
// overall save the current selected window
var g_iWndIndex = 0; //don't have to set the variable; default to use the current selected window without transmiting value when the interface has window parameters

//talk
export function init(setOpenModal, setStatus, setPluginOBJ) {
  let oPromise = new Promise(function (resolve, reject) {
    WebVideoCtrl.I_InitPlugin({
      bWndFull: true, //Wether support doule clicking to switch the full-screen mode: it's supported by default; true:support, false:not support
      iWndowType: 1,
      bDebugMode: true,
      cbInitPluginComplete: function () {
        WebVideoCtrl.I_InsertOBJECTPlugin('divPlugin').then(
          (e) => {
            if (setPluginOBJ) {
              setPluginOBJ(e);
            }
            WebVideoCtrl.I_CheckPluginVersion().then((bFlag) => {
              if (bFlag) {
                alert('Detect the latest version, please double click HCWebSDKPlugin.exe to update!');
              }
            });
            resolve();
          },
          (e) => {
            if (setStatus) {
              setStatus(3);
            }
            if (e.errorCode === 3000) {
              console.log(e);
              if (setOpenModal) {
                setOpenModal((prev) => ({
                  ...prev,
                  status: true,
                  type: 'websdk',
                }));
              }
            }
            console.log(e);
            reject();
          }
        );
      },
    });
  });
  return oPromise;
}

export function login(ip, port, id, password) {
  return new Promise((res, rej) => {
    WebVideoCtrl.I_Login(ip, '1', port, id, password, {
      timeout: 3000,
      success: function (xmlDoc) {
        console.log(ip + ' login successful');
        res(1);
      },
      error: function (oError) {
        if (oError.errorCode === 2001) {
          console.log(ip + ' is already login');
          res(1);
        } else {
          console.log(ip + ' login failed', oError.errorCode, oError.errorMsg);
          rej(3);
        }
      },
    }).then(
      (e) => {
        // console.log('login then()', e);
      },
      (e) => {
        // console.log('login reject()', e);
      }
    );
  });
}

export function logout(nName) {
  if (null == nName) {
    return;
  }
  WebVideoCtrl.I_Logout(nName).then(
    () => {
      console.log(nName + ' ' + 'logout successful');
    },
    () => {
      console.log(nName + ' ' + 'logout failed');
    }
  );
}

// start voice talk
export function clickStartVoiceTalk(ip, setBsStatus) {
  let oPromise = new Promise((res, rej) => {
    var startVoiceTalk = function (reStartCount) {
      var szDeviceIdentify = ip,
        iAudioChannel = 1;

      if (null == szDeviceIdentify) {
        return false;
      }

      if (isNaN(iAudioChannel)) {
        alert('please select channel first.');
        return false;
      }
      if (reStartCount < 5) {
        WebVideoCtrl.I_StartVoiceTalk(szDeviceIdentify, iAudioChannel).then(
          () => {
            console.log('start voice talk success.');
            // if (setBsStatus) {
            //   setBsStatus(1);
            // }
            res(2);
          },
          (oError) => {
            // console.log('start voice talk error.', oError);
            // if (setBsStatus) {
            //   setBsStatus(2);
            // }
            setTimeout(() => {
              startVoiceTalk(reStartCount + 1);
            }, 1000);
            // rej(3);
          }
        );
      } else {
        rej(3);
      }
    };
    startVoiceTalk(0);
  });
  return oPromise;
}

// stop voice talk
export function clickStopVoiceTalk(status) {
  return new Promise((res, rej) => {
    WebVideoCtrl.I_StopVoiceTalk().then(
      () => {
        console.log('stop voice talk success.');
        if (status === 2) {
          res(1);
        }
      },
      (oError) => {
        console.log('stop voice talk error.', oError);
        res(3);
      }
    );
  });
}

export function destroy() {
  WebVideoCtrl.I_Destory();
}
