import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useQuery } from '@tanstack/react-query';
import { IUser, userState } from '../atoms';
import { apiGet } from './_common';
import { IsInfraType } from '../pages/s_cctv/S_cctv1/RealtimeCCTV/IsInfraType';

export const useFetchCctvList = () => {
  const isInfraType = IsInfraType(); // 인프라용의 경우 녹화/캡쳐버튼 미노출
  const userInfo = useRecoilValue<IUser>(userState);
  const { hCd, sCd } = userInfo;
  const queryOptions = { enabled: !!hCd && !!sCd && sCd !== '00000' };

  // camList use query 데이터 페칭 및 상태 선언
  const camListQueryDependencies = ['camList', hCd, sCd];
  const camListQuery = useQuery(camListQueryDependencies, () => fetchData(), queryOptions);
  const { data: camList, isLoading: isLoadingCamList, isFetching: isFetchingCamList, isError: isErrorCamList, isSuccess: isCamListSuccess } = camListQuery;
  const [cameraInfo, setCameraInfo] = useState<any[]>(camList || []); // 모든 카메라 연결정보

  const fetchData = async () => {
    try {
      const res = await apiGet({ path: '/cam/site', req: { hCd, sCd } });
      const result = res.data.data.cameraList;
      setCameraInfo(result);
      return result;
    } catch (error) {
      console.error('error', error);
      throw new Error('error');
    }
  };

  // NVR 목록 useQuery
  const nvrListQueryDependencies = ['nvrList', hCd, sCd, camList];
  const nvrListQueryOptions = { enabled: !!hCd && !!sCd && sCd !== '00000' };
  const nvrListQuery = useQuery(nvrListQueryDependencies, () => fetchNvrData(), {
    ...nvrListQueryOptions,
    enabled: !!camList && isCamListSuccess,
  });
  const { data: nvrListData, isLoading: isLoadingNvr, isFetching: isFetchingNvr, isError: isErrorNvr } = nvrListQuery;
  const [nvrList, setNvrList] = useState(nvrListData || []);
  const [sInfraList, setSInfraList] = useState<any[] | null>(null);

  // 카메라 리스트를 가져오는 함수. 각 NVR에 대한 카메라 리스트를 비동기적으로 가져옴
  const getCamList = async (result: IMainMenu[]) => {
    try {
      const promises = result.map(async (el: IMainMenu) => {
        const subList = await fetchCameraData(el);
        return { ...el, expand: false, subList };
      });
      const promisedResult = await Promise.all(promises);

      /**
       * 작성자 : 홍선영
       * 날짜 : 2024.06.18
       * 기능 : CCTV목록이 인프라타입인 경우 정렬추가
       */
      if (isInfraType) {
        const grouped: Record<string, IMainMenu[]> = {};
        promisedResult.forEach((item) => {
          if (!grouped[item.sInfra]) grouped[item.sInfra] = [];
          grouped[item.sInfra].push(item);
        });

        // sInfra 값으로 정렬 후 cdSort를 사용하여 내부 정렬
        promisedResult.sort((a, b) => {
          // 먼저 sInfra를 비교합. null이나 undefined는 빈 문자열로 처리
          if ((a.sInfra || '') === (b.sInfra || '')) {
            // sInfra가 동일할 경우 cdSort를 비교. null인경우 가장 마지막으로 보냄
            const sortA = a.cdSort === null ? Number.MAX_SAFE_INTEGER : a.cdSort;
            const sortB = b.cdSort === null ? Number.MAX_SAFE_INTEGER : b.cdSort;
            return sortA - sortB;
          }
          // sInfra 기본 비교, null 또는 undefined는 빈 문자열로 처리
          return (a.sInfra || '')?.localeCompare(b.sInfra || '');
        });

        // 그룹화된 데이터를 사용하여 sInfra 리스트 생성
        const groupArray = Object.keys(grouped)
          .sort((a, b) => a.localeCompare(b)) // sInfra 오름차순정렬
          .map((key) => ({
            sInfra: key,
            sInfraName: grouped[key][0].sInfraName,
            cdsortKey: grouped[key][0].cdSort,
            expand: false,
          }));
        setSInfraList(groupArray);
      }
      setNvrList(promisedResult);
      return promisedResult;
    } catch (error) {
      console.error('error', error);
      throw new Error('error');
    }
  };

  // 카메라 목록 데이터패치 함수
  const fetchCameraData = async (param: IMainMenu) => {
    try {
      const req = { hCd, sCd, nCd: param.nCd };
      const res = await apiGet({ path: '/cam/camera', req });
      const { cameraList } = res.data.data;
      const useYCameraList = cameraList
        .filter((el: IMainMenu) => el.useYn === 'Y')
        .map((useYEl: ISubMenu) => ({
          cCd: useYEl.cCd,
          cName: useYEl.cName,
          nCd: useYEl.nCd,
          stream: useYEl.stream,
          channelNum: useYEl.cChannelNum,
          ip: param.ip,
          port: param.port,
          pPort: cameraInfo?.find((cam) => cam.wsNum === useYEl.wsNum)?.pPort || null,
          isPlaying: false,
          isRecording: false,
        }));
      return useYCameraList;
    } catch (error) {
      console.error('error', error);
      throw new Error('error');
    }
  };

  // NVR 목록 데이터패치 함수
  const fetchNvrData = async () => {
    const req = { hCd, sCd, nCd: '001', cCd: '01' };
    try {
      const res = await apiGet({ path: '/cam/nvr', req });
      const result = res.data.data.nvrList;
      const useYNvrList = result.filter((el: any) => el.useYn === 'Y');
      const newList = useYNvrList.map(({ nCd, nName, ip, password, port, id, sInfra, sInfraName, cdSort }: IMainMenu) => ({ nCd, nName, ip, password, port, id, sInfra, sInfraName, cdSort }));
      return getCamList(newList);
    } catch (error) {
      console.error('error', error);
      throw new Error('error');
    }
  };

  const isError = isErrorCamList || isErrorNvr;
  const isLoading = isLoadingCamList || isLoadingNvr || isFetchingCamList || isFetchingNvr;

  return { cameraInfo, nvrList, setNvrList, isError, isLoading, sInfraList, setSInfraList };
};
