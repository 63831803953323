/**
 * 작성자 : 홍선영
 * 날짜 : 2023.04.12
 * 기능 : 배열객체 값 선/후행 공백제거
 */
export const trimArray = (array: any[]) => {
  const keys = Object?.keys(array[0]);

  const trimmedArr = array?.map((obj: any) => {
    // 동일한 속성으로 새 개체 만들기
    const newObj = { ...obj };
    keys.map((el) => {
      // 동적 키로 속성 값 가져오기
      const propertyValue = newObj[el];

      if (typeof propertyValue === 'string') {
        // 속성 값에서 선행 및 후행 공백 제거
        newObj[el] = propertyValue?.trim();
      }
    });

    return newObj;
  });
  return trimmedArr;
};
