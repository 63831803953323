import styled from 'styled-components';

const Root = styled.div`
  user-select: none;
  cursor: pointer;
  flex-grow: 1;
  height: 2.75rem;
  padding: 0 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }: { theme: any }) => theme.board};
  .material-symbols-rounded {
    font-variation-settings: 'FILL' 1, 'wght' 600, 'GRAD' 0, 'opsz' 48;
    font-size: 1.5rem;
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    &.checked {
      color: ${({ theme }: { theme: any }) => theme.filled_blue} !important;
    }
  }
  .name {
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
  }
  &:hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
    outline: 0.25rem solid ${({ theme }: { theme: any }) => theme.tonal_deep};
    z-index: 10;
    &:active {
      outline: 0.25rem solid ${({ theme }: { theme: any }) => theme.filled_blue};
    }
  }
`;

interface Props {
  data: JobtypeList;
  isSelected: boolean;
}

const ListBoxInModal = ({ data, isSelected }: Props) => {
  const icon = isSelected ? 'check_box' : 'check_box_outline_blank';
  const iconStyle = `material-symbols-rounded checkbox ${isSelected ? 'checked' : ''}`;

  return (
    <Root>
      <span className={iconStyle}>{icon}</span>
      <span className='name'>{data.name}</span>
    </Root>
  );
};

export default ListBoxInModal;
