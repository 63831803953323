import styled from 'styled-components';

export interface IRoot {
  loading: boolean;
}

export const ContentsContainerRoot = styled.div<IRoot>`
  filter: ${(props) => (props.loading ? 'blur(0.1rem)' : '')};

  .tui-container {
    flex-grow: 1;
    overflow: hidden;
    margin-left: 0.5rem;
  }
`;
