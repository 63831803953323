import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import sun from '../../assets/images/icons/sun.png';
import cloud from '../../assets/images/icons/lighten_cloud.png';
import blur from '../../assets/images/icons/lighten_blur.png';
import rain from '../../assets/images/icons/white_rain.png';
import snow from '../../assets/images/icons/white_snow.png';
import littleCloud from '../../assets/images/icons/little_cloud.png';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
  .weatherWeek {
    width: 100%;
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 0.5rem;
    gap: 0.25rem;
    padding: 0 0.5rem;
    /* padding: 0 0.75rem; */
    margin: 1rem 0;
    margin: 0.75rem 0;
    .weatherDay {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: ${({ theme }: { theme: any }) => theme.alpha_15};
      color: ${({ theme }: { theme: any }) => theme.alpha_25};
      color: ${({ theme }: { theme: any }) => theme.board};
      border-radius: 1rem;
      border-radius: 0.5rem;
      overflow: hidden;
      &.red {
        color: ${({ theme }: { theme: any }) => theme.tonal_deep_red};
        /* color: ${({ theme }: { theme: any }) => theme.filled_red_deep}; */
        /* color: ${({ theme }: { theme: any }) => theme.filled_red_dark}; */
        /* background-color: ${({ theme }: { theme: any }) => theme.filled_red}; */
        /* background-color: ${({ theme }: { theme: any }) => theme.tonal_red}; */
      }
      &.blue {
        color: ${({ theme }: { theme: any }) => theme.tonal_deep_blue};
        /* color: ${({ theme }: { theme: any }) => theme.filled_blue_deep}; */
        /* color: ${({ theme }: { theme: any }) => theme.filled_blue_dark}; */
        /* background-color: ${({ theme }: { theme: any }) => theme.filled_blue_deep}; */
        /* background-color: ${({ theme }: { theme: any }) => theme.tonal_blue}; */
      }
      .dayTitle {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 0.5rem;
        width: 100%;
        * {
          line-height: 1.25;
        }
        .day {
          font-size: 0.75rem;
          font-weight: 500;
          padding-top: 0.375rem;
          padding-top: 0.5rem;
          width: 100%;
          text-align: center;
        }
        .date {
          font-size: 0.875rem;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2rem;
          min-width: 2.5rem;
        }
      }
      .icon {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.25rem;
        margin: 0 0.5rem;
        margin-bottom: 0.5rem;
        min-width: 3rem;
        width: 40%;
      }
      .temp {
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        gap: 0.125rem;
        padding: 0.25rem 0;
        width: 100%;
        background-color: ${({ theme }: { theme: any }) => theme.alpha_25};
        border-radius: 0.5rem;
        .data-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.125rem;
          font-size: 0.75rem;
          font-weight: 600;
          color: ${({ theme }: { theme: any }) => theme.text_primary};
          /* color: ${({ theme }: { theme: any }) => theme.board}; */
          padding-right: 0.25rem;
          .unit {
            font-size: 0.75rem;
            font-weight: 500;
            color: ${({ theme }: { theme: any }) => theme.text_tertiary};
            /* color: ${({ theme }: { theme: any }) => theme.alpha_50}; */
            /* color: ${({ theme }: { theme: any }) => theme.alpha_75}; */
          }
          .material-symbols-rounded {
            font-size: 1.125rem;
            font-size: 1rem;
            font-variation-settings: 'FILL' 1, 'wght' 600;
            &.lowest {
              color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
              /* color: ${({ theme }: { theme: any }) => theme.tonal_deep_blue}; */
            }
            &.highest {
              color: ${({ theme }: { theme: any }) => theme.filled_red};
              /* color: ${({ theme }: { theme: any }) => theme.tonal_deep_red}; */
            }
          }
        }
      }
    }
  }
`;

const getWeatherIcon = (weather: string) => {
  switch (weather) {
    case 'sunny':
      return <img src={sun} width='100%' />;
    case 'little_cloud':
      return <img src={littleCloud} width='100%' />;
    case 'cloudy':
      return <img src={cloud} width='100%' />;
    case 'blur':
      return <img src={blur} width='100%' />;
    case 'rain':
      return <img src={rain} width='100%' />;
    case 'sleet':
      return <img src={snow} width='100%' />;
    case 'snow':
      return <img src={snow} width='100%' />;
    case 'shower':
      return <img src={rain} width='100%' />;
    default:
      return null;
  }
};

interface Props {
  after6Days: IDays[];
}

const WeatherTotalWeek = ({ after6Days }: Props) => {
  const { t } = useTranslation();

  return (
    <Root className='large'>
      <div className='weatherWeek'>
        {after6Days.map(({ date, day, maxTemp, minTemp, sky }: IDays) => {
          return (
            <div
              key={date} //
              className={`weatherDay ${day === '토' ? 'blue' : ''} ${day === '일' ? 'red' : ''}`}
            >
              <div className='dayTitle'>
                <div className='day'>{t(day)}</div>
                <div className='date'>{date}</div>
              </div>
              <div className='icon'>{getWeatherIcon(sky)}</div>
              <div className='temp'>
                <div className='data-wrapper'>
                  <span className='material-symbols-rounded lowest'>arrow_downward</span>
                  {minTemp}
                  <span className='unit'>°</span>
                </div>
                <div className='data-wrapper'>
                  <span className='material-symbols-rounded highest'>arrow_upward</span>
                  {maxTemp}
                  <span className='unit'>°</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Root>
  );
};

export default WeatherTotalWeek;
