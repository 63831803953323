/**
 * 2023.04.25 한영광
 * 마스터일반관리 -> 사용자관리 -> 본사 정보 탭 페이지 인풋폼
 * pages/m_common/common2/headInfo.tsx 에서 사용
 *
 * 2023.05.10 홍선영
 * 수정사항: 띄어쓰기 방지 해제
 *          우편번호 숫자만 입력 가능하게 수정
 *          사업자번호 마지막칸 입력 후 대표자명으로 포커스 이동 추가
 *          등록일자/수정일자 표시 오류 수정
 *
 * 2024.03.11 김광민
 * 수정사항 1. : 라이트모드/다크모드 로고 input 코드 블럭 모듈로 분리
 * 수정사항 2. : 스타일 수정 및 html 구조 변경
 * 수정사항 3. : 동작 로직은 유지한채 가독성 향상을 위한 리팩토링
 */

import { useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { readAndCompressImage } from 'browser-image-resizer';
import SmallIconWidthTextButton from './button/SmallIconWidthTextButton';
import imagePlaceholder from '../assets/images/illustration/61.svg';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
  padding: 0.5rem;
  position: relative;
  .label {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    font-size: 0.875rem;
    padding: 0 0.75rem;
    border-radius: 0.25rem;
  }
  > img {
    padding: 1rem 2rem;
    width: 100%;
    height: 12.5rem;
    height: 100%;
    object-fit: contain;
    pointer-events: none;
  }
  .edit-container {
    position: absolute;
    display: flex;
    gap: 0.25rem;
    bottom: 0.375rem;
    right: 0.25rem;
    justify-content: flex-end;
    align-items: center;
  }
  &.light {
    background-color: ${({ theme }: { theme: any }) => theme.color.white};
    .label {
      color: ${({ theme }: { theme: any }) => theme.color.zinc_500};
      background-color: ${({ theme }: { theme: any }) => theme.color.gray_100};
      box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.15);
    }
  }
  &.dark {
    background-color: ${({ theme }: { theme: any }) => theme.color.gray_900};
    .label {
      color: ${({ theme }: { theme: any }) => theme.color.zinc_200};
      background-color: ${({ theme }: { theme: any }) => theme.color.gray_600};
      box-shadow: 0 0.25rem 0.75rem 0 rgba(255, 255, 255, 0.15);
    }
  }
`;

type Props = {
  logo: string;
  setLogo: React.Dispatch<React.SetStateAction<string>>;
  isUpdatedLogo: React.Dispatch<React.SetStateAction<'N' | 'D' | 'U'>>;
  theme: 'light' | 'dark';
  setLogoType: React.Dispatch<React.SetStateAction<string>>;
  disabled?: boolean;
};

const LogoFileInput = ({ logo, setLogo, isUpdatedLogo, theme, setLogoType, disabled = false }: Props) => {
  const { t } = useTranslation();

  // 파일 입력란에 대한 참조를 만듭니다.
  const fileInputRef = useRef<HTMLInputElement>(null);

  // 로고가 있는지 여부를 확인합니다.
  const isLogo = logo !== '';

  // 파일을 Data URL 형식으로 읽어오는 함수입니다.
  const readFileAsDataURL = async (file: Blob): Promise<string | ArrayBuffer | null> => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
      // 1. 읽어온 결과를 resolve 함수를 통해 반환합니다.
      // 2. 파일 읽기 실패 시 실행될 콜백 함수입니다.
      // 2. 에러를 reject 함수를 통해 반환합니다.
      // 3. 실제로 파일을 Data URL 형식으로 읽어옵니다.
    });
  };

  const processFile = async (file: File) => {
    const fileType = file.type;
    const isSvg = fileType === 'image/svg+xml';
    const config = { quality: 0.7 };

    try {
      const processedFile = isSvg ? file : await readAndCompressImage(file, config);
      const base64 = await readFileAsDataURL(processedFile);
      setLogoType(file.name.split('.')[1]);
      setLogo(base64 as string);
      isUpdatedLogo('U');
    } catch (error) {
      console.error('파일 처리 에러:', error);
    }
  };

  // 파일 입력 변경 이벤트 처리 함수입니다.
  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      await processFile(file);
      event.target.value = ''; // 입력 필드 초기화
    }
  };

  // 파일 입력 버튼 클릭 이벤트 처리 함수입니다.
  const handleButtonClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  // 로고 삭제 이벤트 처리 함수입니다.
  const handleDelete = () => {
    setLogo('');
    isUpdatedLogo('D');
  };

  const getStyle = () => {
    const editable = !disabled ? '' : 'uneditable';
    return `${theme} ${editable}`;
  };

  const inputLabel = theme === 'light' ? t('라이트 모드') : t('다크 모드');

  const renderPlaceholder = () => {
    if (!disabled) {
      return <SmallIconWidthTextButton text='가져오기' onClick={handleButtonClick} icon='image' color='blue' fixTheme={theme} />;
    }
    return <img src={imagePlaceholder} />;
  };

  return (
    <Root className={getStyle()}>
      <span className='label'>{inputLabel}</span>
      <input //
        type='file'
        accept='image/*'
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileInputChange}
      />
      {isLogo ? (
        <>
          <img src={`${logo}`} />
          {!disabled && (
            <div className='edit-container'>
              <SmallIconWidthTextButton text='삭제' onClick={handleDelete} icon='delete' color='red' size='small' fixTheme={theme} />
            </div>
          )}
        </>
      ) : (
        renderPlaceholder()
      )}
    </Root>
  );
};

export default LogoFileInput;
