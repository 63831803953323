/**
 * 작성자 : 한영광
 * 날짜 : 2023.05.23
 * 기능 : 카카오 지도 컴포넌트
 */
import { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { weatherDayGet } from '../../../services/weather';
import all from '../../../assets/images/icons/hammer.png';
// import all from '../../../assets/images/icons/a2.png';
import construct from '../../../assets/images/icons/build.svg';
import civil from '../../../assets/images/icons/excavator.svg';
import plant from '../../../assets/images/icons/factory.svg';
// import general from '../../../assets/images/icons/c2.png';
import general from '../../../assets/images/icons/crane.svg';
import sunny from '../../../assets/images/icons/sun.png';
import cloudy from '../../../assets/images/icons/cloud.png';
import littleCloud from '../../../assets/images/icons/little_cloud.png';
import blur from '../../../assets/images/icons/blur.png';
import rain from '../../../assets/images/icons/rain.png';
import snow from '../../../assets/images/icons/snow.png';
import { useTranslation } from 'react-i18next';

const Root = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  > div#map {
    width: 100%;
    flex-grow: 1;
    border-radius: 0 0 1rem 1rem;
    background-color: #a3d6eb;
  }
  .customOverlay {
    display: block;
    background: #50627f;
    color: #fff;
    text-align: center;
    line-height: 2rem;
    border-radius: 0.5rem;
    padding: 0 0.5rem;
  }
  .category {
    display: flex;
    padding: 0.5rem;
    > li {
      list-style: none;
      width: 4.5rem;
      padding: 0.5rem 0;
      text-align: center;
      cursor: pointer;
      user-select: none;
      border-radius: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.25rem;
      &.on,
      &:hover {
        background: rgb(245, 245, 245);
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        > div {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
        }
      }
      &:last-child {
        margin-right: 0;
        border-right: 0;
      }
      > div {
        position: relative;
        /* padding: 0.5rem; */
        width: 3rem;
        height: 3rem;
        overflow: hidden;
        background-position: center;
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        > img {
          /* width: 1.75rem; */
          height: 1.75rem;
        }
      }
      > span {
        /* margin: 0 auto 3px; */
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
        font-weight: 600;
      }
    }
  }
`;
declare global {
  interface Window {
    kakao: any;
  }
}

interface Iprops {
  siteList: any[];
  setSiteInfo: Dispatch<SetStateAction<any>>;
}

const weatherImg: any = {
  default:
    'https://media.istockphoto.com/id/1182692816/ko/%EB%B2%A1%ED%84%B0/%EB%A1%9C%EB%93%9C-%EC%95%84%EC%9D%B4%EC%BD%98%EC%9E%85%EB%8B%88%EB%8B%A4-%ED%9D%B0%EC%83%89-%EB%B0%B0%EA%B2%BD%EC%97%90-%EA%B2%A9%EB%A6%AC-%EB%90%9C-%EA%B8%B0%ED%98%B8%EB%A5%BC-%EB%8B%A4%EC%9A%B4%EB%A1%9C%EB%93%9C%ED%95%A9%EB%8B%88%EB%8B%A4-%EB%8D%B0%EC%9D%B4%ED%84%B0-%EB%A1%9C%EB%94%A9-%ED%91%9C%EC%8B%9C%EC%A4%84.jpg?s=612x612&w=is&k=20&c=DT0K9_-eRF7lt0gT25cc2yQd_8J0E-diBPTA_8gm6JQ=',
  sunny,
  cloudy,
  little_cloud: littleCloud,
  blur,
  rain,
  sleet: snow,
  snow,
  shower: rain,
};
const markerImg: any = {
  all,
  construct,
  civil,
  plant,
  general,
};
const MapContainer = ({ siteList, setSiteInfo }: Iprops) => {
  const { t } = useTranslation();
  const mapRef = useRef();
  const imageSize = new window.kakao.maps.Size('auto', 40); // 마커이미지의 크기입니다
  const imageOption = { offset: new window.kakao.maps.Point(15, 30) }; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.
  const [newSiteList, setNewSiteList] = useState<any[]>([]);
  const [constructMarker] = useState<any[]>([]); // 인프라가 건축인 현장 위치 리스트
  const [civilMarker] = useState<any[]>([]); // 인프라가 토목인 현장 위치 리스트
  const [plantMarker] = useState<any[]>([]); // 인프라가 플랜트인 현장 위치 리스트
  const [generalMarker] = useState<any[]>([]); // 인프라가 종합건축인 현장 위치 리스트

  useEffect(() => {
    const container = document.getElementById('map');
    const options = {
      center: new window.window.kakao.maps.LatLng(36.00760445817235, 127.62644427056583),
      level: 13,
    };
    const map = new window.window.kakao.maps.Map(container, options);
    mapRef.current = map;
    // 지도 확대 축소를 제어할 수 있는 줌 컨트롤을 생성합니다
    const zoomControl = new window.kakao.maps.ZoomControl();
    map.addControl(zoomControl, window.kakao.maps.ControlPosition.BOTTOMRIGHT);
  }, []);

  useEffect(() => {
    if (newSiteList.length !== 0) {
      for (const newSite of newSiteList) {
        if (newSite.weather) {
          const customOverlay = new window.kakao.maps.CustomOverlay({
            clickable: true,
            content: `
            <div class="customOverlay">${newSite.sName} (${newSite.sInfraName})
            <br/>
            <img src='${weatherImg[newSite.weather.sky] ? weatherImg[newSite.weather.sky] : weatherImg.default}' width='50' height='50'/></div>
            `,
            position: new window.kakao.maps.LatLng(newSite.latitude, newSite.longitude),
            xAnchor: 0.5,
            yAnchor: 1.5,
            zIndex: 3,
          });
          // 마커를 생성합니다
          const marker = createMarker(newSite.latitude, newSite.longitude, newSite.sInfra);
          window.kakao.maps.event.addListener(marker, 'click', function () {
            setSiteInfo(newSite);
          });
          window.kakao.maps.event.addListener(marker, 'mouseover', function () {
            customOverlay.setMap(mapRef.current);
          });
          window.kakao.maps.event.addListener(marker, 'mouseout', function () {
            customOverlay.setMap(null);
          });
        }
      }
      setMarker('all');
    }
  }, [newSiteList]);

  useEffect(() => {
    weatherDayGetAPI();
  }, [siteList]);

  const weatherDayGetAPI = async () => {
    const addWeather = siteList
      .filter((v: any) => v.address1 !== '')
      .map(async (site) => {
        if (site.latitude) {
          const { data } = await weatherDayGet({ latitude: site.latitude, longitude: site.longitude });
          return { ...site, weather: data.data };
        }
        return site;
      });
    const newArr = await Promise.all(addWeather);
    setNewSiteList(newArr);
  };

  const createMarker = (lat: string, long: string, sInfra: string) => {
    const img = sInfra === '01' ? markerImg.construct : sInfra === '02' ? markerImg.civil : sInfra === '03' ? markerImg.plant : markerImg.general;
    const markerImage = new window.kakao.maps.MarkerImage(img, imageSize, imageOption); // 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
    const marker = new window.kakao.maps.Marker({
      position: new window.kakao.maps.LatLng(lat, long), // 마커가 표시될 위치입니다
      image: markerImage, // 마커이미지 설정
      clickable: true,
    });
    switch (sInfra) {
      case '01':
        constructMarker.push(marker);
        break;
      case '02':
        civilMarker.push(marker);
        break;
      case '03':
        plantMarker.push(marker);
        break;
      case '04':
      default:
        generalMarker.push(marker);
        break;
    }
    return marker;
  };

  const setMarker = (category: string) => {
    switch (category) {
      case 'construct':
        constructMarker.forEach((marker) => marker.setMap(mapRef.current));
        civilMarker.forEach((marker) => marker.setMap(null));
        plantMarker.forEach((marker) => marker.setMap(null));
        generalMarker.forEach((marker) => marker.setMap(null));
        break;
      case 'civil':
        constructMarker.forEach((marker) => marker.setMap(null));
        civilMarker.forEach((marker) => marker.setMap(mapRef.current));
        plantMarker.forEach((marker) => marker.setMap(null));
        generalMarker.forEach((marker) => marker.setMap(null));
        break;
      case 'plant':
        constructMarker.forEach((marker) => marker.setMap(null));
        civilMarker.forEach((marker) => marker.setMap(null));
        plantMarker.forEach((marker) => marker.setMap(mapRef.current));
        generalMarker.forEach((marker) => marker.setMap(null));
        break;
      case 'general':
        constructMarker.forEach((marker) => marker.setMap(null));
        civilMarker.forEach((marker) => marker.setMap(null));
        plantMarker.forEach((marker) => marker.setMap(null));
        generalMarker.forEach((marker) => marker.setMap(mapRef.current));
        break;
      default:
        constructMarker.forEach((marker) => marker.setMap(mapRef.current));
        civilMarker.forEach((marker) => marker.setMap(mapRef.current));
        plantMarker.forEach((marker) => marker.setMap(mapRef.current));
        generalMarker.forEach((marker) => marker.setMap(mapRef.current));
        break;
    }
  };

  return (
    <Root>
      <ul className='category'>
        <li role='presentation' onClick={() => setMarker('all')}>
          <div className='button-all'>
            <img src={markerImg.all} />
          </div>
          <span>{t('전체')}</span>
        </li>
        <li role='presentation' onClick={() => setMarker('construct')}>
          <div>
            <img src={markerImg.construct} />
          </div>
          <span>{t('건축')}</span>
        </li>
        <li role='presentation' onClick={() => setMarker('civil')}>
          <div>
            <img src={markerImg.civil} />
          </div>
          <span>{t('토목')}</span>
        </li>
        <li role='presentation' onClick={() => setMarker('plant')}>
          <div>
            <img src={markerImg.plant} />
          </div>
          <span>{t('플랜트')}</span>
        </li>
        <li role='presentation' onClick={() => setMarker('general')}>
          <div>
            <img src={markerImg.general} />
          </div>
          <span>{t('종합건설')}</span>
        </li>
      </ul>
      <div id='map' />
    </Root>
  );
};

export default MapContainer;
