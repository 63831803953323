import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, CategoryScale, PieController, Title, Tooltip, Legend } from 'chart.js';
import { themeState } from '../atoms';
import { useRecoilValue } from 'recoil';
import { dark, light } from '../assets/styles/theme';

// Register the required components
ChartJS.register(CategoryScale, ArcElement, PieController, Title, Tooltip, Legend);

interface PieChartData {
  data: dataProps[];
}

type dataProps = {
  count: number;
  name: string;
};

const PieChart: React.FC<PieChartData> = ({ data }) => {
  const theme = useRecoilValue(themeState);
  const [dataProp, setDataProp] = useState<dataProps[]>(data);

  useEffect(() => {
    setDataProp(data);
  }, [data]);

  const chartData = {
    labels: dataProp.length > 11 ? dataProp.slice(0, 11).map((d: dataProps) => d.name) : dataProp.map((d: dataProps) => d.name),
    datasets: [
      {
        data: dataProp.map((d: dataProps) => d.count),
        backgroundColor: [
          theme === 'light' ? light.filled_green : dark.filled_green,
          theme === 'light' ? light.filled_blue : dark.filled_blue,
          theme === 'light' ? light.filled_teal : dark.filled_teal,
          theme === 'light' ? light.filled_pink : dark.filled_pink,
          theme === 'light' ? light.filled_orange : dark.filled_orange,
          theme === 'light' ? light.filled_violet : dark.filled_violet,
          theme === 'light' ? light.filled_red : dark.filled_red,
          theme === 'light' ? light.filled_indigo : dark.filled_indigo,
          theme === 'light' ? light.filled_sky : dark.filled_sky,
          theme === 'light' ? light.filled_lime : dark.filled_lime,
          theme === 'light' ? light.filled_amber : dark.filled_amber,
        ], // Add colors as per your requirement
        hoverBackgroundColor: [
          theme === 'light' ? light.filled_green : dark.filled_green,
          theme === 'light' ? light.filled_blue : dark.filled_blue,
          theme === 'light' ? light.filled_teal : dark.filled_teal,
          theme === 'light' ? light.filled_pink : dark.filled_pink,
          theme === 'light' ? light.filled_orange : dark.filled_orange,
          theme === 'light' ? light.filled_violet : dark.filled_violet,
          theme === 'light' ? light.filled_red : dark.filled_red,
          theme === 'light' ? light.filled_indigo : dark.filled_indigo,
          theme === 'light' ? light.filled_sky : dark.filled_sky,
          theme === 'light' ? light.filled_lime : dark.filled_lime,
          theme === 'light' ? light.filled_amber : dark.filled_amber,
        ], // Optional hover colors
      },
    ],
  };

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    borderWidth: 0,
    color: theme === 'light' ? '#071437' : '#ffffff',
    // onResize(chart: any) {
    //   chart.canvas.parentNode.style.height = `120px`;
    //   chart.update();
    // },
    animation: {
      animateRotate: false,
      animateScale: false,
    },
    plugins: {
      legend: {
        display: false,
        position: 'right' as 'right',
      },
    },
  };

  return <Pie data={chartData} options={options} />;
};
export default PieChart;
