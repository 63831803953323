// Import React
import React, { Dispatch, SetStateAction } from 'react';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import styled from 'styled-components';
import { ckeditorConfig } from '../services/ckeditorConfig';

interface TextEditorProps {
  content: string;
  setContent?: Dispatch<SetStateAction<string>>;
  readOnly: boolean;
}

const Root = styled.div`
  label {
    display: none !important;
  }
`;

const TextEditor: React.FC<TextEditorProps> = ({ content, setContent, readOnly }) => {
  return (
    <Root className='ckeditor'>
      <CKEditor
        config={{ ckeditorConfig } as any}
        editor={Editor as any}
        data={content}
        onReady={(editor: any) => {
          // You can store the "editor" and use when it is needed.

          const toolbarElement = editor.ui.view.toolbar.element;
          if (readOnly) {
            editor.enableReadOnlyMode(editor.id);
            toolbarElement.style.display = 'none';
          } else {
            editor.disableReadOnlyMode(editor.id);
            toolbarElement.style.display = 'flex';
          }
        }}
        onChange={(event: any, editor: any) => {
          const data = editor.getData();
          setContent && setContent(data);
        }}
        onBlur={(editor: any) => {}}
        onFocus={(editor: any) => {}}
      />
    </Root>
  );
};

export default TextEditor;
