/**
 * 작성자 : 김광민
 * 날짜 : 2024.04.01
 * 기능 1. : CCTV 설정 값을 불러오는 useQuery hook
 * 기능 2. : CCTV 설정 값을 수정하는 함수
 */

// useCctvSettings.ts
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { apiGet, apiPatch } from '../services/_common';
import { userState } from '../atoms';

/**
 * CCTV 설정 값을 불러오는 custom hook
 * @returns cctvSettingsQuery - CCTV 설정 값을 불러오는 쿼리
 */
const useGetCctvSettings = () => {
  const userInfo = useRecoilValue(userState);
  const { userId } = userInfo;

  const cctvSettingsPath = '/user/cctv';
  const cctvSettingsQuery = useQuery(
    [cctvSettingsPath, userId], // dependencies
    () => getCctvSettings(cctvSettingsPath, userId), // fetch
    { enabled: !!userId } // option
  );

  return cctvSettingsQuery;
};

/**
 * CCTV 설정 값을 가져오는 함수
 * @param path - API 경로
 * @param userId - 사용자 ID
 * @returns data - CCTV 설정 값
 */
export const getCctvSettings = async (path: string, userId: string) => {
  try {
    const res = await apiGet({ path, req: { userId } });
    const { data } = res.data;
    const result = {
      ...data,
      ...(!data.camList1 || data.camList1 === 'undefined' ? { camList1: '' } : { camList1: JSON?.parse(data?.camList1) }),
      ...(!data.camList2 || data.camList2 === 'undefined' ? { camList2: '' } : { camList2: JSON?.parse(data?.camList2) }),
      ...(!data.camList3 || data.camList3 === 'undefined' ? { camList3: '' } : { camList3: JSON?.parse(data?.camList3) }),
      ...(!data.camList4 || data.camList4 === 'undefined' ? { camList4: '' } : { camList4: JSON?.parse(data?.camList4) }),
      ...(!data.camList5 || data.camList5 === 'undefined' ? { camList5: '' } : { camList5: JSON?.parse(data?.camList5) }),
      ...((!data.camCPath || data.camCPath === 'undefined') && { camCPath: '' }),
      ...((!data.camMPath || data.camMPath === 'undefined') && { camMPath: '' }),
      ...((!data.camCLocation || data.camCLocation === 'undefined') && { camCLocation: '' }),
      ...((!data.camFColor || data.camFColor === 'undefined') && { camFColor: '' }),
    };

    return result;
  } catch (error) {
    console.error('error', error);
    throw new Error('error');
  }
};

/**
 * CCTV 설정 값을 수정하는 함수
 * @param userId - 사용자 ID
 * @param cctvSettings - 수정할 CCTV 설정
 * @returns updatedData - 수정된 CCTV 설정 값
 */
const patchCctvSettings = async (userId: string, cctvSettings: CctvSettings) => {
  const stringifyCctvSettings = {
    ...cctvSettings,
    ...(!cctvSettings.camList1 ? { camList1: '' } : { camList1: typeof cctvSettings?.camList1 === 'string' ? cctvSettings.camList1 : JSON?.stringify(cctvSettings?.camList1) }),
    ...(!cctvSettings.camList2 ? { camList2: '' } : { camList2: typeof cctvSettings?.camList2 === 'string' ? cctvSettings.camList2 : JSON?.stringify(cctvSettings?.camList2) }),
    ...(!cctvSettings.camList3 ? { camList3: '' } : { camList3: typeof cctvSettings?.camList3 === 'string' ? cctvSettings.camList3 : JSON?.stringify(cctvSettings?.camList3) }),
    ...(!cctvSettings.camList4 ? { camList4: '' } : { camList4: typeof cctvSettings?.camList4 === 'string' ? cctvSettings.camList4 : JSON?.stringify(cctvSettings?.camList4) }),
    ...(!cctvSettings.camList5 ? { camList5: '' } : { camList5: typeof cctvSettings?.camList5 === 'string' ? cctvSettings.camList5 : JSON?.stringify(cctvSettings?.camList5) }),
    ...(!cctvSettings.camCPath && { camCPath: '' }),
    ...(!cctvSettings.camMPath && { camMPath: '' }),
    ...(!cctvSettings.camCLocation && { camCLocation: '' }),
    ...(!cctvSettings.camFColor && { camFColor: '' }),
  };
  const request = { ...stringifyCctvSettings, userId };
  try {
    const res = await apiPatch({ path: '/user/cctv', req: request });

    const { data } = res.data;

    const result = {
      ...data,
      ...(!data.camList1 || data.camList1 === 'undefined' ? { camList1: '' } : { camList1: JSON?.parse(data?.camList1) }),
      ...(!data.camList2 || data.camList2 === 'undefined' ? { camList2: '' } : { camList2: JSON?.parse(data?.camList2) }),
      ...(!data.camList3 || data.camList3 === 'undefined' ? { camList3: '' } : { camList3: JSON?.parse(data?.camList3) }),
      ...(!data.camList4 || data.camList4 === 'undefined' ? { camList4: '' } : { camList4: JSON?.parse(data?.camList4) }),
      ...(!data.camList5 || data.camList5 === 'undefined' ? { camList5: '' } : { camList5: JSON?.parse(data?.camList5) }),
      ...(!data.camCPath && { camCPath: '' }),
      ...(!data.camMPath && { camMPath: '' }),
      ...(!data.camCLocation && { camCLocation: '' }),
      ...(!data.camFColor && { camFColor: '' }),
    };

    return result;
  } catch (error) {
    console.error('error', error);
    throw new Error('error');
  }
};

export { useGetCctvSettings, patchCctvSettings };
