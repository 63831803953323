/**
 * 작성자 : 한영광
 * 날짜 : 2023.06.01
 * 기능 : API 요청 함수
 */

import axios from 'axios';
import Cookies from 'js-cookie';
import { registerRefresh } from './register';

interface Iparam {
  latitude: string;
  longitude: string;
  address?: string;
}

export const weatherDayGet = async ({ latitude, longitude, address }: Iparam) => {
  const accessToken = Cookies.get('prosafeToken');
  const config = {
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
      authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/weather/day?latitude=${latitude}&longitude=${longitude}&address=${address}`, config)
    .then((res: any) => {
      if (res.status === 210) {
        alert('관리자가 설정을 변경하여 리로드됩니다.');
        window.location.replace('/?oeditYn=Y');
      } else if (res.status !== 200) {
        // 날씨요청 성공이 아닌경우 에러를 던져서 react-query가 받아 처리할수 있도록함
        // throw new Error(`Server responded with status: ${res.status}`);
      }
      return res;
    })
    .catch(async (e: any) => {
      if (e.response.status === 401) {
        if (e.response.data.statusCode === 4018) {
          Cookies.remove('prosafeToken');
          Cookies.remove('refreshToken');
          window.location.replace('/?auth=fail');
          return e.response;
        }
        const res2 = await registerRefresh();
        if (res2.status === 200) {
          const res3: any = await weatherDayGet({ latitude, longitude });
          return res3;
        }
      }
      throw e; // 에러시 react-query가 error throw를 캐치해서 retry등을 할수 있도록 처리
    });
};

export const weatherWeekGet = async ({ latitude, longitude, address }: Iparam) => {
  const accessToken = Cookies.get('prosafeToken');
  const config = {
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
      authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/weather/week?latitude=${latitude}&longitude=${longitude}&address=${address}`, config)
    .then((res: any) => {
      if (res.status === 210) {
        alert('관리자가 설정을 변경하여 리로드됩니다.');
        window.location.replace('/?oeditYn=Y');
      } else if (res.status !== 200) {
        // 날씨요청 성공이 아닌경우 에러를 던져서 react-query가 받아 처리할수 있도록함
        // throw new Error(`Server responded with status: ${res.status}`);
      }
      return res;
    })
    .catch(async (e: any) => {
      if (e.response.status === 401) {
        if (e.response.data.statusCode === 4018) {
          Cookies.remove('prosafeToken');
          Cookies.remove('refreshToken');
          window.location.replace('/?auth=fail');
          return e.response;
        }
        const res2 = await registerRefresh();
        if (res2.status === 200) {
          const res3: any = await weatherWeekGet({ latitude, longitude, address });
          return res3;
        }
      }
      throw e; // 에러시 react-query가 error throw를 캐치해서 retry등을 할수 있도록 처리
    });
};

export const weather3DaysGet = async ({ latitude, longitude, address }: Iparam) => {
  const accessToken = Cookies.get('prosafeToken');
  const config = {
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
      authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/weather/3days?latitude=${latitude}&longitude=${longitude}&address=${address}`, config)
    .then((res: any) => {
      if (res.status === 210) {
        alert('관리자가 설정을 변경하여 리로드됩니다.');
        window.location.replace('/?oeditYn=Y');
      } else if (res.status !== 200) {
        // 날씨요청 성공이 아닌경우 에러를 던져서 react-query가 받아 처리할수 있도록함
        // throw new Error(`Server responded with status: ${res.status}`);
      }
      return res;
    })
    .catch(async (e: any) => {
      if (e.response.status === 401) {
        if (e.response.data.statusCode === 4018) {
          Cookies.remove('prosafeToken');
          Cookies.remove('refreshToken');
          window.location.replace('/?auth=fail');
          return e.response;
        }
        const res2 = await registerRefresh();
        if (res2.status === 200) {
          const res3: any = await weather3DaysGet({ latitude, longitude, address });
          return res3;
        }
      }
      throw e; // 에러시 react-query가 error throw를 캐치해서 retry등을 할수 있도록 처리
    });
};
