/**
 * 작성자 : 홍선영
 * 날짜 : 2024.06.20
 * 기능 : 선택한 카메라가 현재 페이지에서 재생되고 있는 지 확인하여 boolean값을 반환하는 함수
 */

export const isCamInCurrentGrid = (index: number, cctvDivisions: number, currentPage: number) => {
  const totalGrid = cctvDivisions * cctvDivisions;
  const pageStartIndex = (currentPage - 1) * totalGrid;
  const pageEndIndex = (currentPage - 1) * totalGrid + totalGrid;

  if (index >= pageStartIndex && index < pageEndIndex) {
    return true;
  }
  return false;
};
