/**
 * 작성자 : 김광민
 * 날짜 : 2024.02.19
 * 기능 : 터널 관리 > 방송 장비 정보
 */

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PointIcon from './tunnel/PointIcon';

const Root = styled.div`
  font-size: 0.875rem;
  padding: 0;
  display: flex;
  align-items: center;
  color: ${({ theme }: { theme: any }) => theme.text_tertiary};
  height: 2.5rem;
  max-height: 2.5rem;
  gap: 0.5rem;
  &.start {
    .name {
      background-color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
    }
    .material-symbols-rounded {
      color: ${({ theme }: { theme: any }) => theme.filled_blue};
    }
  }
  &.end {
    .name {
      background-color: ${({ theme }: { theme: any }) => theme.filled_amber_deep};
    }
    .material-symbols-rounded {
      color: ${({ theme }: { theme: any }) => theme.filled_amber};
    }
  }
  .material-symbols-rounded {
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    font-size: 1.25rem;
    margin-left: 0.5rem;
  }
  .name {
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0.5rem 0.75rem;
    border-radius: 1rem;
    color: ${({ theme }: { theme: any }) => theme.text_reverse};
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    gap: 0.375rem;
    height: 1.5rem;
    justify-content: center;
  }
`;

type Props = {
  text?: string;
  isStartPoint: boolean;
};

const TunnelPointHorizontalLabel = ({ text, isStartPoint }: Props) => {
  const { t } = useTranslation();
  return (
    <Root className={`point ${isStartPoint ? 'start' : 'end'}`}>
      {isStartPoint ? <PointIcon isStart /> : <PointIcon isStart={false} />}
      <span className='name'>{isStartPoint ? t('시점') : t('종점')}</span>
      {text && <span className='tareaName'>{t(text)}</span>}
    </Root>
  );
};

export default TunnelPointHorizontalLabel;
