/**
 * 작성자 : 한영광
 * 날짜 : 2023.12.27
 * 기능 : 터널 관리 > 굴진/일반 정보 > 굴진/일반 정보 팝업 > CCTV 정보 탭 (현장용)
 */
/**
 * 수정자 : 김광민
 * 수정 날짜 : 2024.02.15
 * 수정 이유 : 가독성 향상과 유지보수를 위한 코드 분리, 변수 변경 및 삭제, 재사용 코드 컴포넌트화, 스타일 수정, 일부 로직 수정
 */

import { useState } from 'react';
import { IUser } from '../atoms';
import styled from 'styled-components';
import TareaNormalInfoHeader from './TareaNormalInfoHeader';
import InfoTextWithIcon from './Modal/InfoTextWithIcon';
import TooltipWithIcon from './Modal/TooltipWithIcon';
import CctvPoint from './Modal/CctvPoint';
import IssueGuide from './IssueGuide';
import { isMobile } from '../utils/checkMobile';
import { useTranslation } from 'react-i18next';
import illustrator from '../assets/images/illustration/304.svg';

const Root = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  gap: 1rem;
  overflow: auto;
  user-select: none;
  @media (min-width: 1024px) {
    padding: 0.75rem 1rem;
  }
  .contentContainer {
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .infoBar {
      display: none;
      @media (min-width: 1024px) {
        display: flex;
        gap: 0.5rem;
      }
    }
    .pointContainer {
      display: flex;
      gap: 0.5rem;
      height: 100%;
      .issueGuide {
        width: calc(50% - 0.25rem);
        flex-grow: 1;
        display: flex;
        align-items: center;
        background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
        border-radius: 0.5rem;
      }
    }
  }
`;

type Props = {
  userInfo: IUser;
  rowState: TareaData;
  startPointList: CctvList[];
  endPointList: CctvList[];
  isLoading: boolean;
};

const TareaCCTVInfoContent = ({ userInfo, rowState, startPointList, endPointList, isLoading }: Props) => {
  const { t } = useTranslation();

  const pointVisibleInitialState = { start: true, end: true };
  const [isPointVisible, setIsPointVisible] = useState(pointVisibleInitialState);

  const isStartPointUse = rowState?.sUseYn === 'Y';
  const isEndPointUse = rowState?.eUseYn === 'Y';

  const renderPoint = () => {
    const renderStartPoint = isStartPointUse && isPointVisible.start;
    const renderEndPoint = isEndPointUse && isPointVisible.end;

    return (
      <div className='pointContainer'>
        {/* 시점 렌더링 */}
        {renderStartPoint ? (
          <CctvPoint userInfo={userInfo} pointListData={startPointList} pointPosition='S' isLoading={isLoading} />
        ) : (
          <div className='issueGuide'>
            <IssueGuide />
          </div>
        )}
        {/* 종점 렌더링 */}
        {renderEndPoint ? (
          <CctvPoint userInfo={userInfo} pointListData={endPointList} pointPosition='E' isLoading={isLoading} />
        ) : (
          <div className='issueGuide'>
            <IssueGuide />
          </div>
        )}
      </div>
    );
  };

  // 연결 방식 미설정 또는 모바일인 경우
  if (userInfo.cctvProtocol === '0' || (userInfo.cctvProtocol === '1' && isMobile())) {
    const connectWay = '설정관리-일반관리 메뉴에서 CCTV 연결방식을 설정하세요';
    const mobileNotSupport = '모바일 환경에서 지원되지 않는 화면입니다';
    const text = userInfo.cctvProtocol === '0' ? connectWay : mobileNotSupport;
    return (
      <Root>
        <IssueGuide text={text} illustrator={{ visible: true, src: illustrator }} />
      </Root>
    );
  }

  return (
    <Root>
      <TareaNormalInfoHeader
        data={rowState}
        isPointVisible={isPointVisible}
        setIsPointVisible={setIsPointVisible}
        isEndPointUse={isEndPointUse}
        isStartPointUse={isStartPointUse}
        visiblePointFilter={false}
      />
      <div className='contentContainer'>
        <div className='infoBar'>
          <InfoTextWithIcon icon='bolt' text='Windows 운영체제에서 Chrome 또는 Edge 브라우저를 이용해주세요.' />
          <TooltipWithIcon icon='help' text='현재 Android, iOS, MacOS 운영체제는 방송 기능을 지원하지 않습니다.' />
        </div>
        {renderPoint()}
      </div>
    </Root>
  );
};

export default TareaCCTVInfoContent;
