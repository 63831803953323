/**
 *  2023.05.10 홍선영
 *  마스터일반관리 -> 현장관리 -> 현장 정보 탭 페이지 인풋폼
 *  pages/m_common/common4/siteInfo.tsx 에서 사용
 */
import { useEffect, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { BtnBlue, BtnGray as BtnGhost } from '../Button';
import Input from '../Input';
import NumbersInput from '../NumbersInput';
import { IComCd } from 'customTypes';
import DatePicker from '../DatePicker';
import { AddressSearch } from '../AddressSearch';
import { applyBorderStyle } from '../../utils/applyBorderStyle';
import SelectBox from '../SelectBox';
import { SearchOptions } from '../../assets/styles/SearchOptions';
import { useSetAuth } from '../../utils/useSetAuth';
import { formatDateYMD } from '../../utils/formatDate';
import { useTranslation } from 'react-i18next';
import { COMCD_INFRA } from '../../_constants';

const ButtonsWrapper = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  justify-content: flex-end;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;
const Root = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  .title div {
    height: 2.5rem;
    display: flex;
    align-items: center;
    gap: 0;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
    span {
      padding: 0.25rem;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
    }
    span::after {
      content: '•';
      margin-left: 0.25rem;
    }
  }
  .inputFormContainer {
    flex-grow: 1;
    overflow: auto;
    width: 100%;
  }
  .inputForm {
    width: 100%;
    padding: 1rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    @media (min-width: 1280px) {
      padding: 1rem 3rem;
      width: 64rem;
    }
  }
  .setting-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 2rem;
    row-gap: 1rem;
    padding: 0.75rem 0;
    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .setting-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 2.5rem;
    > div:nth-child(2) {
      font-size: 0.875rem;
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      border-radius: 0.25rem;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0.5rem 0.75rem;
    }
    > div:first-child > div {
      gap: 0;
      flex-direction: row;
      align-items: center;
      label {
        width: 8rem;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
      }
      > input {
        flex-grow: 1;
        flex-shrink: 1;
        width: 50%;
      }
    }
    div.flex-basic {
      background-color: transparent;
      outline: none;
      padding: 0;
      input {
        width: 4rem;
        font-size: 0.875rem;
        padding: 0;
      }
    }
    @media (min-width: 768px) {
    }
    label {
      font-weight: 500;
      font-size: 0.875rem;
      width: 8rem;
      flex-shrink: 0;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
    }
    > div {
      flex-grow: 1;
      ul li {
        max-width: 100%;
        width: 100%;
        span {
          width: 100%;
        }
      }
    }
    input[type='text'] {
      height: 2.5rem;
      border-radius: 0.25rem;
      font-size: 0.875rem;
    }
    .setting-row-content {
      display: flex;
      align-items: center;
      > span {
        padding: 0 0.5rem;
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
      }
      input[type='number'] {
        height: 2.5rem;
        border-radius: 0.25rem;
        font-size: 0.875rem;
        text-align: center;
        width: 4rem;
      }
      .setting-row-element {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 0.875rem;
      }
    }
    .setting-row-content.time {
      gap: 1rem;
    }
    .setting-row-content-group {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      @media (min-width: 640px) {
        display: flex;
        align-items: center;
      }
    }
  }
  .setting-row.withSelector {
    > div:nth-child(2) {
      padding: 0;
    }
    ul {
      width: 100%;
      li {
        width: 100%;
        max-width: 100%;
        > span {
          width: 100%;
        }
      }
    }
  }
  .setting-row.widthAddress {
    > div:nth-child(2) {
      padding: 0;
      background-color: transparent;
      outline: none;
      > div {
        width: 50%;
        /* flex-shrink: 1; */
        flex-grow: 1;
      }
      input {
        width: 100%;
      }
    }
    > div.inputWidthBtn {
      display: flex;
      gap: 0.5rem;
      > div > div {
        flex-direction: row;
      }
    }
    button {
      width: 6rem;
      padding: 0;
      height: 2.5rem;
      font-size: 0.875rem;
    }
  }
  .false {
    button {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;
    }
    > div:nth-child(2) {
      pointer-events: none;
      outline: none;
      li {
        color: ${({ theme }: { theme: any }) => theme.text_disabled};
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
      }
    }
  }
  .setting-row.noGrid {
    margin: 0.75rem 0;
  }
  .setting-row.datePickerWrapper {
    > div:nth-child(2) {
      padding: 0;
      > div {
        display: flex;
      }
    }
    input {
      width: 50%;
      flex-grow: 1;
      font-size: 0.875rem;
    }
  }

  .buttonsWrapper {
    justify-content: flex-end;
  }

  input {
    :disabled {
      color: ${({ theme }: { theme: any }) => theme.text_disabled};
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
    }
  }

  .grid {
    display: grid;
    margin-bottom: 1rem;
    align-items: center;
    min-height: 3.4rem;
  }

  .grid100,
  .grid100T,
  .grid60,
  .grid50,
  .grid30 {
    > div > div {
      display: grid;
      grid-template-columns: 9rem 1fr;
      align-items: center;
    }
  }

  .grid100T {
    grid-template-columns: 9rem 1fr;
  }

  .grid50,
  .grid60 {
    grid-template-columns: 1fr 1fr;
  }

  .grid50 {
    grid-gap: 1rem;
  }

  .grid30 {
    grid-template-columns: 1.5fr 0.7fr 0.8fr;
  }

  .plainText {
    grid-template-columns: 9rem 1fr;

    > :last-child {
      display: block;
    }
  }

  .marginBottomNone {
    margin-bottom: 0;
  }

  @media screen and (max-width: 1439px) {
    .plainText {
      grid-template-columns: 9rem 1fr;

      > div {
        display: block !important;
        margin-bottom: 0 !important;
      }
    }

    .grid50 {
      grid-template-columns: 1fr;
      > small {
        display: grid;
        grid-template-columns: 1fr;
      }

      > div > div {
      }
    }

    .grid60 {
      grid-template-columns: 0.7fr 0.3fr;
      > div > button {
        width: 100%;
      }
    }
  }

  .addressWrapper {
    > div {
      margin-bottom: 1rem;
    }
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    /* padding-top: 5rem; */
    height: fit-content;
    overflow: none;
  }
`;

interface IUserInputForm {
  state: any;
  setState: Dispatch<SetStateAction<any>>;
  userInfoInputFormRef: React.RefObject<any>;
  sInfra: any;
  setSInfra: Dispatch<SetStateAction<any>>;
  sTelNumState: any;
  setSTelNumState: Dispatch<SetStateAction<any>>;
  disabled: boolean;
  onClickSave: () => void;
  onClickCancel: () => void;
  onClickUpdate: () => void;
  startDate: any;
  setStartDate: Dispatch<SetStateAction<any>>;
  endDate: any;
  setEndDate: Dispatch<SetStateAction<any>>;
  sInfraComCdList: any;
  isSaveClicked: boolean;
}

const SiteInfoInputForm = ({
  state,
  setState,
  userInfoInputFormRef,
  sInfra,
  setSInfra,
  sTelNumState,
  setSTelNumState,
  disabled,
  onClickSave,
  onClickUpdate,
  onClickCancel,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  sInfraComCdList,
  isSaveClicked,
}: IUserInputForm) => {
  const { t } = useTranslation();
  const { auth } = useSetAuth();
  const sTelLengthState = { num1: 3, num2: 4, num3: 4 }; // 전화번호 입력 길이

  useEffect(() => {
    setState((prev: any) => ({ ...prev, sInfra: sInfra[COMCD_INFRA], sInfraName: sInfra.cdName })); // 보고서를 선택할 때 mrCd 전역저장
  }, [sInfra[COMCD_INFRA]]);

  return (
    <Root>
      <SearchOptions align='left' className='border-bottom'>
        <div className='inputsWrapper'>
          <div className='secondSearchOption title'>
            <div>
              <span>{t('현장명')}</span>
              {state.sName}
            </div>
          </div>
        </div>
      </SearchOptions>
      <div className='inputFormContainer'>
        <div className='inputForm' ref={userInfoInputFormRef}>
          <div className='setting-grid'>
            <div className='setting-row'>
              <label htmlFor='code'>{t('코드')}</label>
              <div>{state.sCd}</div>
            </div>
            <div className='setting-row'>
              <Input
                className='required'
                label={t('현장명')}
                type='text'
                id='sName'
                name='sName'
                state={state}
                setState={setState}
                disabled={disabled}
                getBorderStyle={isSaveClicked ? applyBorderStyle(state.sName, 'red', 'sName') : undefined}
              />
            </div>
          </div>
          <div className='setting-grid'>
            <div className='setting-row'>
              <label htmlFor='lockYn'>{t('현장 운영 상태')}</label>
              <div>{state.sStatus === 'Y' ? `${t('운영중')}` : `${t('종료')}`}</div>
            </div>
            <div className={`setting-row withSelector ${!disabled && 'editMode'}`}>
              <label htmlFor='useYn'>{t('건설업종')}</label>
              <SelectBox options={sInfraComCdList} defaultOption={sInfra.cdName} state={sInfra} setState={setSInfra} stateKey={COMCD_INFRA} initiateKey={sInfra[COMCD_INFRA]} disabled={disabled} />
            </div>
          </div>
          <div className='setting-grid'>
            <div className='setting-row datePickerWrapper'>
              <label className='required' htmlFor='sDate'>
                {t('시공일자')}
              </label>
              {disabled ? (
                <input type='text' value={startDate && formatDateYMD(startDate)} disabled />
              ) : (
                <DatePicker startDate={startDate} setDate={setStartDate} popperPlacement='bottom' isSaveClicked={isSaveClicked} />
              )}
            </div>
            <div className='setting-row datePickerWrapper'>
              <label className='required' htmlFor='fDate'>
                {t('종료일자')}
              </label>
              {disabled ? (
                <input type='text' value={endDate && formatDateYMD(endDate)} disabled />
              ) : (
                <DatePicker startDate={endDate} setDate={setEndDate} popperPlacement='bottom' isSaveClicked={isSaveClicked} />
              )}
            </div>
          </div>
          <div className='setting-grid'>
            <div className='setting-row'>
              <label htmlFor='hpNum'>{t('현장 대표번호')}</label>
              <NumbersInput state={sTelNumState} setState={setSTelNumState} lengthState={sTelLengthState} disabled={disabled} />
            </div>
          </div>
          <div className='setting-grid'>
            <div className={`setting-row widthAddress ${!disabled && 'editMode'}`}>
              <label htmlFor={t('우편번호')}>{t('우편번호')}</label>
              <div className='inputWidthBtn'>
                <Input type='text' id='postNum' name='postNum' state={state} setState={setState} disabled />
                <AddressSearch setState={setState} disabled={disabled} />
              </div>
            </div>
          </div>
          <div className='setting-grid'>
            <div className='setting-row widthAddress'>
              <label htmlFor={t('주소')}>{t('주소')}</label>
              <Input type='text' id='address1' name='address1' state={state} setState={setState} disabled placeholder={t('주소')} />
            </div>
            <div className='setting-row widthAddress'>
              <label htmlFor={t('상세주소')}>{t('상세주소')}</label>
              <Input type='text' id='address2' name='address2' state={state} setState={setState} placeholder={t('상세주소')} disabled={disabled} maxLength={200} />
            </div>
          </div>
          <div className='setting-row noGrid'>
            <Input label={t('비고')} type='text' id='bigo' name='bigo' state={state} setState={setState} disabled={disabled} maxLength={200} />
          </div>
          <div className='setting-grid'>
            <div className='setting-row'>
              <label htmlFor='createDate'>{t('등록일자')}</label>
              <div>{state?.wDate}</div>
            </div>
            <div className='setting-row'>
              <label htmlFor='createUser'>{t('등록자')}</label>
              <div>{state.writer}</div>
            </div>
            <div className='setting-row'>
              <label htmlFor='updateDate'>{t('수정일자')}</label>
              <div>{state.eDate}</div>
            </div>
            <div className='setting-row'>
              <label htmlFor='updateUser'>{t('수정자')}</label>
              <div>{state.editor}</div>
            </div>
          </div>
        </div>
      </div>
      <ButtonsWrapper>
        {!disabled ? (
          <>
            <BtnGhost onClick={onClickCancel}>{t('취소')}</BtnGhost>
            <BtnBlue onClick={onClickSave}>{t('저장')}</BtnBlue>
          </>
        ) : (
          (auth.createAuth || auth.updateAuth) && <BtnBlue onClick={onClickUpdate}>{t('수정')}</BtnBlue>
        )}
      </ButtonsWrapper>
    </Root>
  );
};

export default SiteInfoInputForm;
