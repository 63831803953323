/**
 * 작성자 : 홍선영
 * 날짜 : 2023.05.25
 * 경로 : 현장 CCTV - CCTV보기
 */
import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { userState } from '../../atoms';
import { IModal } from 'customTypes';
import { logPost } from '../../services/log';
import { WebSDKcctv } from '../../utils/webSDKcctvClass';
import { IoCaretBack, IoCaretForward, IoPlayBackSharp, IoPlayForwardSharp } from 'react-icons/io5';
import Portal from '../../components/Portal';
import WebSdkModal from '../../components/Modal/WebSdkModal';
import { useTranslation } from 'react-i18next';
import { apiGet } from '../../services/_common';
import { PTZDIRECTION } from '../../_constants';
import { PulseLoader } from 'react-spinners';

const Root = styled.div`
  .controllerWrapper {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    border-radius: 0rem 0rem 0.8rem 0.8rem;

    > div {
      flex: 0.3;
    }

    .control {
      display: flex;

      .controllerBtn {
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 0.5rem;
        cursor: pointer;
        border: 1px solid rgb(126, 130, 153);
        border-radius: 5px;
        margin: 0.5rem 0.8rem;
        width: 3rem;
        height: 3rem;
        text-align: center;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};

        &:hover {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
        }
      }
    }

    .leftControl {
    }

    .middleControl {
      justify-content: center;
      gap: 0.25rem;
      .controllerBtn {
        margin: 0.5rem 0;
        border: none;
        &:hover {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
        }
      }
    }
    .rightControl {
      > div {
        display: flex;
        flex: 0.5;
      }
    }

    .activePage {
      border: none;
      font-weight: 500;
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
    }

    *[data-tooltip] {
      position: relative;
      z-index: 999;
    }

    *[data-tooltip]::after {
      content: attr(data-tooltip);

      position: absolute;
      top: -28px;
      right: -20px;
      width: -webkit-fill-available;

      pointer-events: none;
      opacity: 0;
      -webkit-transition: opacity 0.15s ease-in-out;
      -moz-transition: opacity 0.15s ease-in-out;
      -ms-transition: opacity 0.15s ease-in-out;
      -o-transition: opacity 0.15s ease-in-out;
      transition: opacity 0.15s ease-in-out;

      display: block;
      background: #fff;
      padding: 2px;
      border: 1px solid #c0c0c0;
      border-radius: 4px;
      box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.4);
    }

    *[data-tooltip]:hover::after {
      opacity: 1;
    }
  }
`;

const Loader = styled.div`
  position: absolute;
  width: 90%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  min-height: 6.5rem;
  margin-bottom: 0.75rem;
  &.siteDashboard {
    min-height: 8rem;
  }
`;

const WebSdk = ({
  state,
  divisionCount = 4,
  currentPageNumber,
  setCurrentPageNumber,
  height = '49rem',
  dashboard,
  divPlugin = 'divPlugin',
  webSDKClosure,
  pointPosition,
  usePulseloader = false,
}: any) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const [cameraInfo, setCameraInfo] = useState<any[]>([]);
  const [visiblePageNumbers, setVisiblePageNumbers] = useState<number[]>([]);
  const [pageSetStart, setPageSetStart] = useState<number>(1);
  const [pageSetEnd, setPageSetEnd] = useState<number>(5);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentCameraInfo, setCurrentCameraInfo] = useState<any[]>([]);
  const [cctvDivisions, setCctvDivisions] = useState<number>(divisionCount);
  const [cctvFlow, setCctvFlow] = useState(0);
  const [cctvOBJ, setCctvOBJ] = useState<WebSDKcctv>();
  const [initCamPlayLoading, setInitCamPlayLoading] = useState(true);
  const [path, setPath] = useState('');

  useEffect(() => {
    getCameraListAPI();
  }, []);

  useEffect(() => {
    if (cameraInfo.length > 0) {
      if (!dashboard) {
        setCurrentCameraInfo(cameraInfo.filter((v: any, i: number) => i < cctvDivisions * cctvDivisions));
      }
      const pages = Math.ceil(cameraInfo.length / (cctvDivisions * cctvDivisions));
      setTotalPages(pages);
      const endPage = Math.min(pageSetStart + 4, pages);
      setVisiblePageNumbers(Array.from({ length: endPage - pageSetStart + 1 }, (_, i) => i + pageSetStart));

      if (cctvFlow === 0) {
        if (cctvOBJ?.getPluginOBJ()?.oPlugin) {
          cctvOBJ.stop();
        }
        const newCctvObj = new WebSDKcctv({ setOpenModal, flowChangeListener, divPlugin, cType: cctvDivisions });
        newCctvObj.init();
        setCctvOBJ(newCctvObj);
      }
      if (cctvFlow === 1) {
        const nvrList = cameraInfo.filter((v: any, i: number) => {
          return cameraInfo.findIndex((v2: any, j: number) => v.ip === v2.ip) === i;
        });
        const data = nvrList.map((v: any) => {
          return {
            ip: v.ip,
            pPort: v.pPort,
            id: v.id,
            password: v.password,
          };
        });
        // cctvOBJ?.hideWnd();
        cctvOBJ?.login(data);
      }
      if (cctvFlow === 2) {
        cctvOBJ?.changeWndNum(cctvDivisions);
      }
    }
  }, [cameraInfo, cctvFlow, cctvDivisions]);

  useEffect(() => {
    if (currentCameraInfo && currentCameraInfo.length > 0 && cctvFlow === 2) {
      startCamera(currentCameraInfo);
    }
  }, [currentCameraInfo, cctvFlow]);

  const startCamera = async (currentCamInfo: any[]) => {
    await cctvOBJ?.start(currentCamInfo);
    setInitCamPlayLoading(false);
  };

  useEffect(() => {
    // 현재 페이지가 현재 보이는 범위를 벗어나는지 확인
    if (currentPageNumber < pageSetStart || currentPageNumber > pageSetEnd) {
      // 현재 페이지가 새 visiblePage 범위에 속하도록 범위 조정
      setPageSetStart(Math.floor((currentPageNumber - 1) / 5) * 5 + 1);
    }

    if (cctvOBJ?.getPluginOBJ()?.oPlugin) {
      cctvOBJ.stop();
    }

    const newCameraInfo = cameraInfo.filter((v: any, i: number) => {
      return i >= (currentPageNumber - 1) * cctvDivisions * cctvDivisions && i < cctvDivisions * cctvDivisions * currentPageNumber;
    });
    setCurrentCameraInfo(newCameraInfo);
    // if (setCurrentPageNumber) setCurrentPageNumber(currentPageNumber);
  }, [currentPageNumber, cameraInfo, cctvOBJ]);

  useEffect(() => {
    // pageSetStart가 변경될 때마다 현재 5페이지 세트의 끝 페이지 계산
    // 총 페이지를 초과하지 않도록 Math.min을 사용
    setPageSetEnd(Math.min(pageSetStart + 4, totalPages));

    const endPage = Math.min(pageSetStart + 4, totalPages);
    setVisiblePageNumbers(Array.from({ length: endPage - pageSetStart + 1 }, (_, i) => i + pageSetStart));
  }, [pageSetStart, totalPages]);

  useEffect(() => {
    if (state) {
      setCameraInfo(state.length ? state : [state]);
    }
  }, [state]);

  useEffect(() => {
    if (cctvOBJ && webSDKClosure && pointPosition) {
      webSDKClosure.setCctvOBJ(cctvOBJ, pointPosition);
    }
  }, [cctvOBJ]);

  const flowChangeListener = (flow: any) => {
    setCctvFlow(flow);
  };

  const getCameraListAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/cam/site', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      if (!state) {
        setCameraInfo(data.cameraList);
      }
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  // 화면분할 클릭 (1분할, 4분할, ...16분할)
  const onClickDivision = (divisionNum: number) => {
    setCctvFlow(0);
    setCctvDivisions(divisionNum);
    if (cctvOBJ?.getPluginOBJ()?.oPlugin) {
      cctvOBJ.destroy();
    }
  };

  const goToPage = (page: number) => {
    setCurrentPageNumber(page);
  };
  const goToFirstPage = () => {
    setCurrentPageNumber(1);
  };
  const goToNextPages = () => {
    // 다음 페이지가 있는 경우에만 다음 페이지 집합으로 이동
    if (pageSetStart + 5 <= totalPages) {
      setCurrentPageNumber(pageSetStart + 5);
    } else if (totalPages <= 5 && currentPageNumber !== totalPages) {
      // 전체 페이지가 5개 이하인 경우 한 번에 한 페이지씩 이동
      setCurrentPageNumber(currentPageNumber + 1);
    }
  };
  const goToPrevPages = () => {
    // 이전 페이지 집합이 있는 경우에만 이동
    if (pageSetStart - 5 >= 1) {
      setCurrentPageNumber(pageSetStart - 5);
    } else if (pageSetStart !== 1) {
      // 전체 페이지를 되돌릴 수 없는 경우 첫 번째 페이지로 이동
      setCurrentPageNumber(1);
      setPageSetStart(1);
    } else if (totalPages <= 5 && currentPageNumber !== 1) {
      // 전체 페이지가 5개 이하인 경우 한 번에 한 페이지씩 이동
      setCurrentPageNumber(currentPageNumber - 1);
    }
  };
  const goToLastPage = () => {
    setCurrentPageNumber(totalPages);
  };

  useEffect(() => {
    if (initCamPlayLoading) {
      cctvOBJ?.hideWnd();
    } else {
      cctvOBJ?.showWnd();
    }
  }, [initCamPlayLoading]);

  return (
    <>
      <Root>
        {initCamPlayLoading && usePulseloader && (
          <Loader>
            <PulseLoader color='rgb(0, 122, 255)' size='10px' />
          </Loader>
        )}
        <div
          id={divPlugin}
          className='cctvWrapper'
          style={{ height }}
          /**
           * 작성자 : 한영광
           * 작성일자 : 2024.03.20
           * 작성내용 : WebSDK에서 드래그&드랍 기능 구현을 위해 Mouse Up 이벤트 리스너를 추가하고, Mouse Up 위치의 윈도우 포지션값 구하는 계산식 추가
           */
          // role='button'
          // tabIndex={0}
          // onMouseUp={() => {
          //   const mouseUpHandler = (moveEvent: MouseEvent) => {
          //     const clientWidth = document.querySelector('.cctvWrapper')?.clientWidth || 0;
          //     const clientHeight = document.querySelector('.cctvWrapper')?.clientHeight || 0;
          //     const blockWidth = Math.floor(clientWidth / (cctvDivisions || 4));
          //     const blockHeight = Math.floor(clientHeight / (cctvDivisions || 4));
          //     const xPosition = Math.floor(moveEvent.offsetX / blockWidth);
          //     const yPosition = Math.floor(moveEvent.offsetY / blockHeight);
          //     const dropedWndIndex = yPosition * cctvDivisions + xPosition;
          //     console.log(dropedWndIndex);
          //   };
          //   document.addEventListener('mouseup', mouseUpHandler, { once: true });
          // }}
        />
        <div className='controllerWrapper webSdk'>
          <div className='control leftControl webSdk'>
            <div className='controllerBtn' role='presentation' data-tooltip={`1${t('분할')}`} onClick={() => onClickDivision(1)}>
              1
            </div>
            <div className='controllerBtn' role='presentation' data-tooltip={`4${t('분할')}`} onClick={() => onClickDivision(2)}>
              4
            </div>
            <div className='controllerBtn' role='presentation' data-tooltip={`9${t('분할')}`} onClick={() => onClickDivision(3)}>
              9
            </div>
            <div className='controllerBtn' role='presentation' data-tooltip={`16${t('분할')}`} onClick={() => onClickDivision(4)}>
              16
            </div>
            {/**
             * 작성자 : 한영광
             * 작성일자 : 2024.03.20
             * 작성내용 : WebSDK PTZ 사용 예시
             */}
            {/* <div
              className='controllerBtn'
              role='presentation'
              onClick={() => {
                cctvOBJ?.openDirectory();
              }}
              onMouseDown={() => {
                const mouseDownHandler = () => {
                  cctvOBJ?.pointZoomEnable();
                };
                document.addEventListener('mousedown', mouseDownHandler, { once: true });
              }}
              onMouseUp={() => {
                const mouseUpHandler = () => {
                  cctvOBJ?.ptzStop();
                };
                document.addEventListener('mouseup', mouseUpHandler, { once: true });
              }}
            >
              BTN 1
            </div> */}
          </div>
          <div className='control middleControl webSdk'>
            <div className='controllerBtn webSdk' role='button' tabIndex={0} onClick={goToFirstPage}>
              <IoPlayBackSharp />
            </div>
            <div className='controllerBtn webSdk' role='button' tabIndex={0} onClick={goToPrevPages}>
              <IoCaretBack />
            </div>
            {visiblePageNumbers?.map((el, i) => (
              <div key={el} onClick={() => goToPage(el)} className={`controllerBtn webSdk ${el === currentPageNumber ? 'activePage' : undefined}`} role='button' tabIndex={0}>
                {el}
              </div>
            ))}
            <div className='controllerBtn webSdk' role='button' tabIndex={0} onClick={goToNextPages}>
              <IoCaretForward />
            </div>
            <div className='controllerBtn webSdk' role='button' tabIndex={0} onClick={goToLastPage}>
              <IoPlayForwardSharp />
            </div>
          </div>
          <div className='control rightControl'> </div>
        </div>
      </Root>
      <Portal openModal={openModal?.status}>{openModal && <WebSdkModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
    </>
  );
};

export default WebSdk;
