/**
 * 작성자 : 홍선영
 * 날짜 : 2024.01.17
 * 경로 : 설정관리 > 알림톡 관리 > 긴급 상황 목록 & 비콘 호출 목록
 */

import { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { userState } from '../../../atoms';
import { IModal } from 'customTypes';
import { COMCD_USE_YN, ERROR, FLAG_CREATE_OR_UPDATE, FLAG_NONE, INIT_USE_YN_A } from '../../../_constants';
import i18n from '../../../translation/i18n';
import { InputTable } from '../../../assets/styles/InputTable';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { BtnBlue, BtnGhost, BtnRed } from '../../../components/Button';
import Input from '../../../components/Input';
import Portal from '../../../components/Portal';
import SelectBoxs from '../../../components/SelectBoxs';
import DeleteModal from '../../../components/Modal/DeleteModal2';
import SelectBox from '../../../components/SelectBox';
import { ynFilter } from '../../../utils/ynFilter';
import { useSetAuth } from '../../../utils/useSetAuth';
import { applyBorderStyle } from '../../../utils/applyBorderStyle';
import { scrollToNodeBottom } from '../../../utils/scrollToNodeBottom';
import { useQuery } from '@tanstack/react-query';
import { apiDelete, apiGet, apiPost } from '../../../services/_common';
import { toast } from 'react-toastify';
import { trimArray } from '../../../utils/trimArray';
import { ContentsContainerRoot } from '../../../assets/styles/ContentsContainerRoot';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import { PulseLoader } from 'react-spinners';
import IssueGuide from '../../../components/IssueGuide';
import { useFetchCommonCodeList } from '../../../services/useSetCodeListInSelectBoxForm';
import { logPost } from '../../../services/log';

const Root = styled(ContentsContainerRoot)`
  .inputFormsWrapper.smallTab {
    flex-direction: row;
    align-items: center;
    padding: 0 0.5rem;
    font-weight: 500;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 0rem !important;
    }

    .tab {
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
      cursor: pointer;
      height: 3.5rem;
      border-bottom: 2px solid transparent;
    }
    .activeTab {
      font-weight: 700;
      border-bottom: 2px solid ${({ theme }: { theme: any }) => theme.selected_primary};
      color: ${({ theme }: { theme: any }) => theme.selected_primary};
    }
  }

  .tr > div > div {
    width: 100%;
    li {
      width: 100% !important;
    }
  }

  .secondSearchOption {
    width: fit-content;
  }

  .textBtnGroup button {
    padding: 0 0.75rem;
    width: 4.5rem;
    height: 2.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
  }
`;

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 0.5rem;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;

interface ItuList {
  bigo: string;
  tuCd: string;
  tuName: string;
  tuTel: string;
  useYn?: string;
  writer?: string;
  wDate?: string;
  editor: string;
  eDate?: string;
  tuGubun?: string;
  flag?: string;
}

interface IData {
  tuGubun: string;
}

interface Props {
  prop: IData;
}

const EmergencyList = ({ prop }: Props) => {
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const userInfo = useRecoilValue(userState);
  const [searchOption, setSearchOption] = useState({ tuName: '' });
  const [searchOptionUseYn, setSearchOptionUseYn] = useState(INIT_USE_YN_A);
  const [tableState, setTableState] = useState<ItuList[]>([]);
  const [initTableState, setInitTableState] = useState<ItuList[]>([]);
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const scrollContainerRef = useRef<HTMLInputElement>(null);
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const inputRefs = useRef<HTMLInputElement[] | null>(Array(tableState.length).fill(null));
  const tableStateRef = useRef(tableState);
  const { data: useYnComCdListWithAll } = useFetchCommonCodeList(COMCD_USE_YN, true); // 사용여부 공통코드 목록 (전체포함)
  const { data: useYnComCdList } = useFetchCommonCodeList(COMCD_USE_YN, false); // 사용유무 공통코드 목록

  useEffect(() => {
    if (prop.tuGubun) {
      logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: prop.tuGubun === '01' ? '알림톡 관리 > 긴급 상황 목록' : '알림톡 관리 > 비콘 호출 목록',
        action: '조회',
        etc: ``,
      });
    }
  }, [prop.tuGubun]);

  useEffect(() => {
    tableStateRef.current = tableState;
  }, [tableState]);

  // 긴급 상황목록 조회 useQuery
  const emergencyMsgListQuery = useQuery(['emergencyMsgListQuery', userInfo.hCd, userInfo.sCd, prop.tuGubun], () => fetchEmergencyMsgList(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && !!prop.tuGubun,
  });

  // 긴급 상황목록 조회 데이터패치 함수
  const fetchEmergencyMsgList = async () => {
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, tuGubun: prop.tuGubun };
      const res = await apiGet({ path: '/msg', req });
      const { tuList } = res.data.data || [];
      setTableState(tuList.map((el: ItuList) => ({ ...el, flag: FLAG_NONE })));
      setInitTableState(tuList.map((el: ItuList) => ({ ...el, flag: FLAG_NONE })));
      return tuList;
    } catch (error) {
      console.error(error);
      throw new Error('error /msg');
    }
  };

  useEffect(() => {
    setSearchOptionUseYn(INIT_USE_YN_A);
  }, [i18n.language]);

  useEffect(() => {
    if (tableState.length > 0) scrollToNodeBottom(scrollContainerRef);
  }, [tableState.length]);

  useEffect(() => {
    setSearchOption({ tuName: '' });
  }, [prop.tuGubun]);

  useEffect(() => {
    applyFilter(initTableState);
  }, [searchOption.tuName, searchOptionUseYn[COMCD_USE_YN]]);

  const applyFilter = (array: ItuList[]) => {
    // 검색옵션 변경됐을 때 필터링 처리
    // 필터링된 어레이 리턴, 대소문자구분X
    const titleFilteredArray = array.filter((el: any) => el.tuName?.toLowerCase()?.includes(searchOption.tuName?.toLowerCase()));
    const result = ynFilter(titleFilteredArray, 'useYn', searchOptionUseYn[COMCD_USE_YN]);
    if (result.length > 0) setTableState(result);
    else setTableState([]);
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    setSearchOptionUseYn(INIT_USE_YN_A);
    setSearchOption({ tuName: '' });
  };

  const onClickAddTableRow = () => {
    const newObj = { tuGubun: prop.tuGubun, tuCd: '', tuName: '', tuTel: '', useYn: 'Y', bigo: '', editor: userInfo.userId };
    setTableState((prev: ItuList[]) => [...prev, newObj]);
  };

  const onClickSave = () => {
    setIsSaveClicked(true);
    const required: (keyof ItuList)[] = ['tuName', 'tuTel'];
    const emptyCheck = tableState.filter((el: ItuList) => {
      const check = required.find((key: keyof ItuList) => el[key] === '' && el.flag === FLAG_CREATE_OR_UPDATE);
      return check !== undefined;
    });
    const tuTelFocusIndex = tableState.findIndex((el) => el.tuTel.length < 10);

    if (emptyCheck.length > 0) {
      toast.warning(t('필수입력값을 모두 입력하세요'));
    } else if (tuTelFocusIndex !== -1) {
      // 휴대폰번호 9자리 이하일경우 포커스
      // Delay the focus to allow for state update
      setTimeout(() => {
        inputRefs.current && inputRefs.current[tuTelFocusIndex]?.focus();
        toast.warning(t('휴대폰 번호를 확인하세요.'));
      }, 0);
    } else {
      const updateArray = tableState.filter((el: ItuList) => el.flag === FLAG_CREATE_OR_UPDATE);
      if (updateArray.length > 0) postAPI(updateArray);
    }
  };

  const postAPI = async (array: ItuList[]) => {
    const newArray = array.map((el: ItuList) => ({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      tuGubun: prop.tuGubun,
      tuCd: el.tuCd,
      tuName: el.tuName,
      tuTel: el.tuTel,
      bigo: el.bigo,
      useYn: el.useYn,
      editor: userInfo.userId,
      ...(el.tuCd === '' && { writer: userInfo.userId }),
    }));
    const req = { tuSaveReqDto: trimArray(newArray) };
    const res = await apiPost({ path: '/msg', req });
    const { data, statusCode, message } = res.data || {};
    if (statusCode === 200) {
      const { tuList } = data || [];
      applyFilter(tuList.map((el: ItuList) => ({ ...el, flag: FLAG_NONE })));
      setInitTableState(tuList.map((el: ItuList) => ({ ...el, flag: FLAG_NONE })));
      setIsSaveClicked(false);
      toast.success(t(message));
    }
  };

  const deleteAPI = async (el: ItuList) => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, tuGubun: prop.tuGubun, tuCd: el.tuCd, editor: userInfo.userId };
    const res = await apiDelete({ path: '/msg', req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      setIsSaveClicked(false);
      toast.success(t(message));
      applyFilter(data.tuList.map((resEl: ItuList) => ({ ...resEl, flag: FLAG_NONE })));
      setInitTableState(data.tuList.map((resEl: ItuList) => ({ ...resEl, flag: FLAG_NONE })));
    } else toast.error(t(ERROR));
  };

  const onClickDelete = (el: ItuList, i: number) => {
    if (el.tuCd) {
      // 기존 데이터 삭제시
      const data = { ...el, editor: userInfo.userId };
      setOpenModal((prev) => ({ ...prev, type: 'delete', status: true, state: tableState, setState: setTableState, api: () => deleteAPI(data), el: data, index: i }));
    } else {
      // 제거 (새로 추가한 데이터 제거시)
      const updatedArray = [...tableState];
      updatedArray.splice(i, 1);
      setTableState(updatedArray);
    }
  };

  if (!emergencyMsgListQuery || !emergencyMsgListQuery.data)
    return (
      <LoadingModalBackground>
        <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
      </LoadingModalBackground>
    );

  return (
    <div className='content-container oneColumn'>
      <Root loading={emergencyMsgListQuery.isLoading}>
        <SearchOptions align='left'>
          <div className='inputsWrapper'>
            <div className='flex-basic textBtnGroup'>
              <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col withLabelComCf'>
                <label htmlFor='useYn'>{t('사용유무')}</label>
                <SelectBox
                  options={useYnComCdListWithAll}
                  defaultOption={searchOptionUseYn.cdName}
                  state={searchOptionUseYn}
                  setState={setSearchOptionUseYn}
                  stateKey={COMCD_USE_YN}
                  initiateKey={searchOptionUseYn[COMCD_USE_YN]}
                  filterbar='filter-1-left'
                />
              </div>
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col'>
                <Input label='' placeholder={t('담당자명')} type='text' id='tuName' name='tuName' state={searchOption} setState={setSearchOption} />
              </div>
            </div>
          </div>
        </SearchOptions>
        <InputTable className='margin-left-05' ref={scrollContainerRef}>
          <div className='thead'>
            <div className='tr'>
              <div className='trCol2p5 flex-center tableStickyNo'>No</div>
              <div className='trCol4 flex-center'>{t('코드')}</div>
              <div className='trCol12 flex-center required'>{t('담당자명')}</div>
              <div className='trCol12 flex-center required'>{t('휴대폰번호')}</div>
              <div className='trCol8 flex-center'>{t('사용유무')}</div>
              <div className='trCol12 flex-center content-overflow'>{t('비고')}</div>
              <div className='trCol10 flex-center'>{t('등록일자')}</div>
              <div className='trCol6 flex-center'>{t('등록자')}</div>
              <div className='trCol10 flex-center'>{t('수정일자')}</div>
              <div className='trCol6 flex-center'>{t('수정자')}</div>
              {auth.deleteAuth && <div className='trCol4 flex-center'> </div>}
            </div>
          </div>
          <div className='table img'>
            <div className='tbody'>
              {tableState.length > 0 ? (
                tableState.map((el: ItuList, index: number) => (
                  <div className='tr' key={`${index}_${el.tuCd}`}>
                    <div className='trCol2p5 flex-center tableStickyNo'>{index + 1}</div>
                    <div className='trCol4 flex-center'>{el.tuCd}</div>
                    <div className='trCol12 flex-center'>
                      <Input
                        type='text'
                        id='tuName'
                        name='tuName'
                        state={tableState}
                        setState={setTableState}
                        stateType={{ type: 'array', index }}
                        getBorderStyle={isSaveClicked ? applyBorderStyle(el.tuName, 'red', 'name') : undefined}
                        useFlag
                        maxLength={8}
                      />
                    </div>
                    <div className='trCol12 flex-center'>
                      <Input
                        type='text'
                        id='tuTel'
                        name='tuTel'
                        state={tableState}
                        setState={setTableState}
                        stateType={{ type: 'array', index }}
                        getBorderStyle={isSaveClicked ? applyBorderStyle(el.tuTel, 'red', 'tel') : undefined}
                        useFlag
                        valueType='stringNumber'
                        maxLength={11}
                        inputRef={(input: any) => {
                          const refs = inputRefs?.current;
                          if (refs) {
                            refs[index] = input;
                          }
                        }}
                      />
                    </div>
                    <div className='trCol8 flex-center'>
                      <SelectBoxs
                        options={useYnComCdList}
                        defaultOption={el.useYn === 'Y' ? t('사용') : t('미사용')}
                        state={tableState}
                        setState={setTableState}
                        rawData={initTableState}
                        setRawData={setInitTableState}
                        stateKey='useYn'
                        codeKey='cdName'
                        index={index}
                        object={el}
                        primaryKey='useYn'
                        initiateKey={tableState[index].useYn}
                      />
                    </div>
                    <div className='trCol12 flex-center content-overflow'>
                      <Input type='text' id='bigo' name='bigo' state={tableState} setState={setTableState} stateType={{ type: 'array', index }} useFlag maxLength={200} />
                    </div>
                    <div className='trCol10 flex-center'>{el.wDate}</div>
                    <div className='trCol6 flex-center'>{el.writer}</div>
                    <div className='trCol10 flex-center'>{el.eDate}</div>
                    <div className='trCol6 flex-center'>{el.editor}</div>
                    {auth.deleteAuth && (
                      <div className='trCol4 flex-center'>
                        <BtnRed onClick={() => onClickDelete(el, index)}>{el.tuCd ? t('삭제') : t('제거')}</BtnRed>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <IssueGuide />
              )}
            </div>
          </div>
        </InputTable>
        <ButtonsWrapper>
          {auth.createAuth && <BtnBlue onClick={onClickAddTableRow}>{t('신규항목 추가')}</BtnBlue>}
          {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={onClickSave}>{t('저장')}</BtnBlue>}
        </ButtonsWrapper>
      </Root>
      {emergencyMsgListQuery.isLoading ? (
        <LoadingModalBackground>
          <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
        </LoadingModalBackground>
      ) : undefined}
      <Portal openModal={openModal?.status}>{openModal && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
    </div>
  );
};

export default EmergencyList;
