import { createRoot } from 'react-dom/client';

class CustomCheckableBoxRenderer {
  element;
  private props;

  constructor(props: any) {
    this.props = props;
    this.element = document.createElement('input');
    this.element.type = 'checkbox';

    this.element.addEventListener('change', (e) => {
      props.grid.setValue(props.rowKey, props.columnName, this.element.checked);
    });

    const root = createRoot(this.element);
    root.render(((this.props = props), (this.element.checked = props.value)));
  }

  getElement() {
    return this.element;
  }
}

export default CustomCheckableBoxRenderer;
