/**
 * 작성자 : 김광민
 * 날짜 : 2024.04.29
 * 기능 : 태블릿 안전교육 관리 화면에서 공통으로 사용되는 목록 컴포넌트
 */

import styled from 'styled-components';
import { InputTable } from '../../../assets/styles/InputTable';
import { BtnBlue, BtnGhost } from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { IAuth } from 'customTypes';
import { usePostTabletTalk } from '../../../hooks/useTablet';
import Portal from '../../../components/Portal';
import ClickableContainer from '../../s_cctv/S_cctv1/RealtimeCCTV/ClickableContainer';
import YesOrNoConfirm from '../../../components/Modal/YesOrNo';
import TableLamp from '../setting12/TableLamp';
import SelectBox from '../../../components/SelectBox';
import { COMCD_USE_YN, INIT_USE_YN_A } from '../../../_constants';
import { useFetchCommonCodeList } from '../../../services/useSetCodeListInSelectBoxForm';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  justify-content: flex-end;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  .header {
    padding: 0.5rem;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    display: flex;
    gap: 0.5rem;
    .title {
      flex-shrink: 0;
      width: fit-content;
      font-weight: 600;
      margin-right: 0.5rem;
    }
    .search {
      width: 20rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      border-radius: 0.25rem;
      padding: 0 0.5rem;
      input {
        padding: 0;
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
        background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
        :focus {
          outline: none;
        }
      }
      .material-symbols-rounded {
        user-select: none;
        font-size: 1.375rem;
        &.close-icon {
          font-variation-settings: 'FILL' 1, 'wght' 500;
          cursor: pointer;
          font-size: 1.25rem;
          border-radius: 0.25rem;
          padding: 0.125rem;
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          &:hover {
            box-shadow: 0 0 0.25rem 0.125rem ${({ theme }: { theme: any }) => theme.tonal_deep};
          }
          &:active {
            background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
          }
          &.hidden {
            display: none;
          }
        }
      }
    }
    button {
      flex-shrink: 0;
      padding: 0 0.75rem;
      width: 4.5rem;
      height: 2.5rem;
      font-size: 0.875rem;
      border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      &:hover {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
      }
    }
  }
  .bigo {
    &::before {
      content: '|';
      margin: 0 0.375rem;
      color: ${({ theme }: { theme: any }) => theme.text_disabled};
    }
    color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
  }
`;

interface Props {
  originData: TabletTalk[];
  data: TabletTalk[];
  setData: Dispatch<SetStateAction<TabletTalk[]>>;
  auth: IAuth;
  selectedIndex: number | null;
  setSelectedIndex: Dispatch<SetStateAction<number | null>>;
  refetch: () => void;
  warning: boolean;
  tGubun: number;
}

const AllimtalkList = ({
  originData, //
  data,
  setData,
  auth,
  selectedIndex,
  setSelectedIndex,
  refetch,
  warning,
  tGubun,
}: Props) => {
  const { t } = useTranslation();
  const scrollContainerRef = useRef<HTMLInputElement>(null);
  const postTabletTalk = usePostTabletTalk();

  const { data: useYnComCdListWithAll } = useFetchCommonCodeList(COMCD_USE_YN, true); // 사용여부 공통코드 목록 (전체포함)
  const [selectFilter, setSelectFilter] = useState(INIT_USE_YN_A);
  const [inputFilter, setInputFilter] = useState<string>('');
  const [openModal, setOpenModal] = useState<{ status: boolean; type: string; selectedIndex: number | null }>({ status: false, type: 'confirm', selectedIndex: null });

  useEffect(() => {
    const filteredData = originData.filter((el) => {
      const tTitle = el.tTitle.toLowerCase().includes(inputFilter.toLowerCase());
      const tContents = el.tContents.toLowerCase().includes(inputFilter.toLowerCase());
      const tBigo = el.tBigo.toLowerCase().includes(inputFilter.toLowerCase());
      const useYn = selectFilter.USE_YN === 'A' ? true : el.useYn === selectFilter.USE_YN;
      return (tTitle || tContents || tBigo) && useYn;
    });
    const handler = setTimeout(() => {
      setData(filteredData);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [inputFilter, selectFilter, originData]);

  const updateTabletTemplete = async (el: TabletTalk) => {
    const res = await postTabletTalk(el);
    if (res) {
      refetch();
    }
  };

  const updateUseYn = async (i: number) => {
    data.map((el) =>
      el.tCd === originData[i].tCd //
        ? updateTabletTemplete({ ...el, useYn: 'Y', tGubun })
        : updateTabletTemplete({ ...el, useYn: 'N', tGubun })
    );
  };

  const handleUseYnClick = (el: TabletTalk, index: number) => {
    // 사용유무가 이미 Y인 경우
    if (el.useYn === 'Y') {
      return;
    }
    // 오른쪽 양식지 템플릿에 변경된 내용이 있는 경우
    if (warning) {
      // 양식지 변경 확인 모달 열기
      setOpenModal({ status: true, type: 'confirm', selectedIndex: index });
    } else {
      // 사용유무 업데이트
      updateUseYn(index);
    }
  };

  if (!data || data === undefined || data.length === undefined) {
    return null;
  }

  return (
    <Root>
      <div className='header'>
        <ClickableContainer
          onClick={() => {
            setInputFilter('');
            setSelectFilter(INIT_USE_YN_A); // 검색필터 초기화
          }} //
          disabled={inputFilter === '' && selectFilter.USE_YN === 'A'}
          button={<BtnGhost>{t('초기화')}</BtnGhost>}
        />
        <SelectBox
          options={useYnComCdListWithAll} //
          defaultOption={selectFilter.cdName}
          state={selectFilter}
          setState={setSelectFilter}
          stateKey={COMCD_USE_YN}
          initiateKey={selectFilter[COMCD_USE_YN]}
          textLabel={t('사용유무')}
        />
        <div className='search'>
          <span className='material-symbols-rounded search-icon'>search</span>
          <input
            type='text'
            value={inputFilter} //
            placeholder={t('검색어를 입력해주세요')}
            onChange={(e) => setInputFilter(e.target.value)}
          />
          <ClickableContainer
            button={
              <span
                className={`material-symbols-rounded close-icon ${
                  inputFilter.length === 0 //
                    ? 'hidden'
                    : ''
                }`}
              >
                close
              </span>
            } //
            onClick={() => setInputFilter('')}
          />
        </div>
      </div>
      <InputTable className='margin-left-05 noHorizontalScroll'>
        <div ref={scrollContainerRef}>
          <div className='thead'>
            <div className='tr'>
              <div className='trCol2p5 flex-center shrink0'>No</div>
              <div className='trColGrow flex-center shrink1'>{t('필독 제목 | 비고')}</div>
              <div className='trCol6 flex-center shrink1'>{t('사용유무')}</div>
            </div>
          </div>
          <div className='table'>
            <div className='tbody'>
              {data?.length > 0 ? (
                data.map((el: TabletTalk, i: number) => (
                  <div
                    className={`tr ${selectedIndex === i ? 'selected' : ''}`} //
                    role='button'
                    tabIndex={0}
                    key={`tabletTalk_${el.tCd}`}
                    onClick={() => setSelectedIndex(i)}
                  >
                    <div className='trCol2p5 flex-center shrink0'>{i + 1}</div>
                    <div className='trColGrow flex-start shrink1'>
                      {el.tTitle}
                      {el.tBigo !== '' && <span className='bigo'>{el.tBigo}</span>}
                    </div>
                    <div className='trCol6 flex-center'>
                      <ClickableContainer
                        onClick={() => handleUseYnClick(el, i)} //
                        button={<TableLamp use={el.useYn === 'Y'} />}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <div className='noData'>No data.</div>
              )}
            </div>
          </div>
        </div>
      </InputTable>
      {auth.createAuth && ( //
        <ButtonsWrapper>
          <BtnBlue
            className={selectedIndex === null ? 'disabled' : ''}
            onClick={() => setSelectedIndex(null)} //
          >
            {t('새 알림톡 작성')}
          </BtnBlue>
        </ButtonsWrapper>
      )}
      <Portal openModal={openModal?.status}>
        {openModal.selectedIndex !== null && (
          <YesOrNoConfirm
            mainText={t('사용하는 알림톡을을 변경하시겠습니까?')}
            subText={t('저장되지 않은 내용은 유실됩니다.')}
            onClickClose={() => setOpenModal({ status: false, type: 'confirm', selectedIndex: null })} //
            onClickConfirm={() => {
              updateUseYn(openModal.selectedIndex as number);
              setOpenModal({ status: false, type: 'confirm', selectedIndex: null });
            }}
          />
        )}
      </Portal>
    </Root>
  );
};

export default AllimtalkList;
