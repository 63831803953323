import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import ClickableContainer from '../../s_cctv/S_cctv1/RealtimeCCTV/ClickableContainer';
import ListBoxInModal from './ListBoxInModal';
import FoldableListBoxInModal from './FoldableListBoxInModal';
import ArrowBoxInModal from './ArrowBoxInModal';
import { handleListClick } from '../../../hooks/useJobType';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
  gap: 0.75rem;
  &:last-child {
    border-bottom: none;
  }
  .main-list-container {
    display: flex;
    gap: 0.5rem;
  }
  .list-box-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .sub-list-container {
    border-radius: 0.25rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    gap: 0.25rem;
    outline: 0.25rem solid ${({ theme }: { theme: any }) => theme.tonal};
    margin-left: 5rem;
    margin-bottom: 1rem;
  }
`;

interface Props {
  list: JobtypeFoldableList;
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
  foldList: { fold: boolean }[];
  setFoldList: Dispatch<SetStateAction<{ fold: boolean }[]>>;
  index: number;
}

const JobTypeFoldableList = ({ list, selected, setSelected, foldList, setFoldList, index: listIndex }: Props) => {
  const isFold = foldList[listIndex]?.fold;
  const [count, setCount] = useState(0);

  // 선택된 공종 내 sub-list 항목 개수 세기
  useEffect(() => {
    const selectedList = new Set(selected);
    const selectedListCount = list.subList //
      .filter((item: JobtypeList) => selectedList.has(item.key)).length;

    setCount(selectedListCount);
  }, [selected, list]);

  // sub-list 접고/펼치기 버튼 함수
  const handleArrowClick = () => {
    setFoldList((prev) => {
      const newFoldList = [...prev];
      newFoldList[listIndex].fold = !isFold;
      return newFoldList;
    });
  };

  // main-list 클릭 함수
  const handleMainListClick = () => {
    const selectedList = new Set(selected);
    const selectedListCount = list.subList //
      .filter((item) => selectedList.has(item.key)).length;

    if (selectedListCount === 0) {
      // 전체 추가하기
      const updatedA = [...selected, ...list.subList.map((item) => item.key)];
      setSelected(updatedA);
    } else if (selectedListCount === list.subList.length) {
      // 전체 빼기
      const updatedA = selected //
        .filter((item) => !list.subList.some((subItem) => subItem.key === item));
      setSelected(updatedA);
    } else {
      // 추가되지 않은 항목 추가하기
      const nonDuplicatedKeys = list.subList //
        .filter((item) => !selectedList.has(item.key))
        .map((item) => item.key);
      const updatedA = [...selected, ...nonDuplicatedKeys];
      setSelected(updatedA);
    }

    if (isFold) {
      handleArrowClick();
    }
  };

  if (isFold === undefined) return null;

  return (
    <Root>
      <div className='main-list-container'>
        <ClickableContainer
          button={<ArrowBoxInModal fold={isFold} />} //
          onClick={handleArrowClick}
        />
        <ClickableContainer
          button={
            <FoldableListBoxInModal
              data={list} //
              count={count}
            />
          }
          onClick={handleMainListClick}
          flexGrow
        />
      </div>
      {!isFold && (
        <div className='sub-list-container'>
          {list.subList.map((item: JobtypeList) => (
            <ClickableContainer //
              key={item.key}
              button={
                <ListBoxInModal
                  data={item} //
                  isSelected={selected.includes(item.key)}
                />
              }
              onClick={() => handleListClick(item, setSelected, selected)}
            />
          ))}
        </div>
      )}
    </Root>
  );
};

export default JobTypeFoldableList;
