/*
 * 작성자 : 김광민
 * 날짜 : 2023.08.09
 * 기능 : 서브메뉴 리스트 출력 컴포넌트
 */

import { Dispatch, SetStateAction, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { asideSubState, pageInfoState } from '../atoms';
import { useRecoilValue, useSetRecoilState } from 'recoil';

interface AsideSubMenuProps {
  subMenu: any;
  pageMovement: (sub: string) => void;
  setLinkPath: (mtCd: string, mdCd: string) => string;
  subSelected: string;
  setSubSelected: Dispatch<SetStateAction<string>>;
  setMainFocus: Dispatch<SetStateAction<string>>;
  closeAside: () => void;
}

const Root = styled.div`
  height: 100vh;
  position: relative;
  z-index: 5000;
  background-color: ${({ theme }: { theme: any }) => theme.board};
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    background-color: transparent;
  }
  .aside-top {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0 0.75rem;
    margin: 0.5rem 0;
    @media (min-width: 1280px) {
      display: none;
    }
    .close-menu {
      cursor: pointer;
      position: fixed;
      top: 1rem;
      right: 1rem;
      display: flex;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
      width: 2rem;
      height: 2rem;
      align-items: center;
      justify-content: center;
      border-radius: 1rem;
      user-select: none;
    }
    @media (min-width: 1024px) {
      .hamburger {
        display: flex;
        color: ${({ theme }: { theme: any }) => theme.text_primary};
      }
      .close-menu {
        display: none;
      }
    }
  }
  .arrow_back {
    user-select: none;
    cursor: pointer;
    width: 3.5rem;
    width: fit-content;
    padding-left: 0.5rem;
    padding-right: 1rem;
    height: 3rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    :hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
      /* background-color: rgb(209 213 219 / 0.5); */
    }
  }
  .submenu {
    top: 0;
    /* width: 100vw; */
    width: 17.5rem;
    height: 100%;
    padding: 0 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow-y: auto;
    @media (min-width: 1280px) {
      padding: 0.5rem 0;
    }
    @media (min-width: 640px) {
      width: 16rem;
    }
    .sub-list {
      user-select: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0 1.5rem;
      border-radius: 2.5rem;
      height: 3rem;
      flex-shrink: 0;
      margin: 0 0.5rem;
      background-color: ${({ theme }: { theme: any }) => theme.background};
      .sub-title {
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
        font-weight: ${({ theme }: { theme: any }) => theme.font_semibold};
      }
      @media (min-width: 1024px) {
        :hover {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
        }
      }
    }
    .sub-list.active {
      background-color: rgb(0, 128, 255);
      .sub-title {
        color: white;
        font-weight: 400;
      }
      :hover {
        background-color: rgb(0, 99, 197);
      }
    }
  }
`;

const AsideSubMenu = ({ subMenu, pageMovement, setLinkPath, subSelected, setSubSelected, setMainFocus, closeAside }: AsideSubMenuProps) => {
  const { t } = useTranslation();
  const pageInfo = useRecoilValue(pageInfoState);
  const setOpenSubAside = useSetRecoilState(asideSubState);

  const backToMenu = () => {
    setMainFocus('');
    setOpenSubAside(false);
  };

  useLayoutEffect(() => {
    subMenu.map((el: any) => {
      el.sub2?.map((el2: any) => {
        if (el2.mdCd === pageInfo.mdCd) {
          setSubSelected(el2.mdCd);
        }
      });
    });
  }, []);

  return (
    <Root>
      <div className='aside-top'>
        <div className='icon-menu close-menu' role='presentation' onMouseDown={() => closeAside()}>
          <span className='material-symbols-rounded'>close </span>
        </div>
        <div className='icon-menu arrow_back' role='presentation' onMouseDown={() => backToMenu()}>
          <span className='material-symbols-rounded'>arrow_back </span>
          <span>{t('뒤로가기')}</span>
        </div>
      </div>
      <ul className='submenu'>
        {subMenu && subMenu.length > 1
          ? subMenu.map((sub: any) => (
              <Link
                key={sub.mdCd}
                to={setLinkPath(sub.mtCd, sub.mdCd)}
                className={pageInfo.mtCd === sub.mtCd && sub.mdCd === subSelected ? 'active sub-list' : 'sub-list'}
                onClick={() => pageMovement(sub)}
              >
                <li className='sub-title'>{sub.useYn === 'N' ? <span style={{ color: 'red' }}>{t(sub.mdName)}</span> : t(sub.mdName)}</li>
              </Link>
            ))
          : undefined}
      </ul>
    </Root>
  );
};
export default AsideSubMenu;
