import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import i18n from '../../translation/i18n';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getAgeFromCertainDate } from '../../utils/getAge';
import { PulseLoader } from 'react-spinners';
import { apiGet } from '../../services/_common';

// 종합 현황
export const TotalInfo = ({ name, userInfo }: any) => {
  const { t } = useTranslation();
  const todayjs = dayjs();
  const format = todayjs.format('YYYYMMDD');
  let format2 = todayjs.format(`MM월 DD일 dddd`);
  if (i18n.language === 'en-US') {
    format2 = todayjs.locale('en').format(`MMMM D dddd`);
  } else {
    format2 = todayjs.locale('ko').format(`MM월 DD일 dddd`);
  }
  const workerSearchObj = {
    wWorkstatus: 'I',
    wJobdate1: format,
    wJobdate2: format,
  };
  const attendSearchObj = {
    aDate1: format,
    aDate2: format,
  };
  const oldWorkerWearchObj = {
    wWorkstatus: 'I',
    oldDate1: '',
    oldDate2: String(Number(format) - Number(`${userInfo.elderlyAgeSet}0000`)),
  };

  const [info, setInfo] = useState({ newWorkerCnt: 0, todayAttendCnt: 0, todayOldWorkerAttendCnt: 0, todayNormalWorkerCnt: 0 });

  const newWorkerQuery = useQuery(['TotalInfoNewWorkerGet', userInfo], () => apiGet({ path: '/worker', req: { ...workerSearchObj, hCd: userInfo.hCd, sCd: userInfo.sCd } }), {
    enabled: userInfo.sCd !== '00000',
  });
  const attendQuery = useQuery(['attendCntGet', userInfo], () => apiGet({ path: '/attend/day', req: { ...attendSearchObj, hCd: userInfo.hCd, sCd: userInfo.sCd } }), {
    enabled: userInfo.sCd !== '00000',
  });
  // const oldWorkerQuery = useQuery(['oldWorkerGet', userInfo], () => workerGet(oldWorkerWearchObj, userInfo.hCd, userInfo.sCd));

  useEffect(() => {
    if (newWorkerQuery.isSuccess && newWorkerQuery.data && newWorkerQuery.data.data.statusCode === 200) {
      setInfo((prev) => ({ ...prev, newWorkerCnt: newWorkerQuery.data.data.data.workerList.length }));
    }
  }, [newWorkerQuery.isSuccess, newWorkerQuery.isRefetching]);

  useEffect(() => {
    if (attendQuery.isSuccess && attendQuery.data && attendQuery.data.data.statusCode === 200) {
      const { attendList } = attendQuery.data.data.data;
      // 금일출역 중 고령근로자 카운트
      const newArray = attendList.map((el: any) => {
        if (el.wBdate !== null) {
          const age = getAgeFromCertainDate(el.wBdate, todayjs);
          return { ...el, age };
        }
        return { ...el, age: 0 };
      });
      const oldWorkerArray = newArray.filter((el: any) => Number(userInfo.elderlyAgeSet <= el.age));
      setInfo((prev) => ({ ...prev, todayAttendCnt: attendList.length, todayOldWorkerAttendCnt: oldWorkerArray.length, todayNormalWorkerCnt: attendList.length - oldWorkerArray.length }));
    }
  }, [attendQuery.isSuccess, attendQuery.isRefetching]);

  // useEffect(() => {
  //   if (oldWorkerQuery.isSuccess && oldWorkerQuery.data && oldWorkerQuery.data.data.statusCode === 200) {
  //     const { workerList } = oldWorkerQuery.data.data.data;
  //     const newArray = workerList?.filter((el: any) => el.wBdate !== null);

  //     setInfo((prev) => ({ ...prev, oldWorkerCnt: newArray.length }));
  //   }
  // }, [oldWorkerQuery.isSuccess, oldWorkerQuery.isRefetching]);

  const onClickRefresh = () => {
    attendQuery.refetch();
    newWorkerQuery.refetch();
    // oldWorkerQuery.refetch();
  };

  if (
    !newWorkerQuery.isSuccess ||
    newWorkerQuery.data.status !== 200 ||
    newWorkerQuery.isRefetching ||
    !attendQuery.isSuccess ||
    attendQuery.data.status !== 200 ||
    attendQuery.isRefetching
    // || !oldWorkerQuery.isSuccess ||
    // oldWorkerQuery.data.status !== 200 ||
    // oldWorkerQuery.isRefetching
  ) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <div className='totalInfo'>
      <div className='widget-header'>
        <div className='widget-title flex-between'>
          <span>{t(name)}</span>
          <div className='refresh-btn' onClick={onClickRefresh} role='presentation'>
            <span className='material-symbols-rounded'>refresh</span>
          </div>
        </div>
        <div className='widget-subTitle'>{format2}</div>
      </div>
      <div className='widget-body'>
        <div className='infoBox flex-basic'>
          <div className='red'>
            <span className='material-symbols-rounded'>person_add</span>
          </div>
          <div className='labelAndValue flex-between'>
            <div className='infoBox-label'>{t('신규 근로자')}</div>
            <div className='infoBox-value'>
              <span className='count'>{info.newWorkerCnt}</span>
              {t('명')}
            </div>
          </div>
        </div>
        <div className='infoBox flex-basic'>
          <div className='blue'>
            <span className='material-symbols-rounded'>leaderboard</span>
          </div>
          <div className='labelAndValue flex-between'>
            <div className='infoBox-label'>{t('금일 출역')}</div>
            <div className='infoBox-value'>
              <span className='count'>{info.todayAttendCnt}</span>
              {t('명')}
            </div>
          </div>
        </div>
        <div className='infoBox flex-basic'>
          <div className='orange'>
            <span className='material-symbols-rounded'>groups</span>{' '}
          </div>
          <div className='labelAndValue flex-between'>
            <div className='infoBox-label'>{t('금일 고령 출역')}</div>
            <div className='infoBox-value'>
              <span className='count'>{info.todayOldWorkerAttendCnt}</span>
              {t('명')}
            </div>
          </div>
        </div>
        <div className='infoBox flex-basic'>
          <div className='green'>
            <span className='material-symbols-rounded'>group</span>{' '}
          </div>
          <div className='labelAndValue flex-between'>
            <div className='infoBox-label'>{t('금일 일반 출역')}</div>
            <div className='infoBox-value'>
              <span className='count'>{info.todayNormalWorkerCnt}</span>
              {t('명')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
