import { IModal } from 'customTypes';
import { BtnBlue, BtnGhost, BtnRed, BtnWhite } from '../Button';
import { ModalBackground, Modal } from '../../assets/styles/Modal';
import { useTranslation } from 'react-i18next';

const DeleteModal = ({ openModal, setOpenModal, state }: any) => {
  const { t } = useTranslation();
  // 닫기버튼 클릭
  const onClickClose = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };

  const onClickDelete = () => {
    const updatedArray = [...openModal.state];
    updatedArray.splice(openModal.index, 1);
    openModal.setState(updatedArray);
    openModal.api(openModal.el);
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };

  return (
    <ModalBackground>
      <Modal>
        <div className='modal delete'>
          <div className='inputForm-head'>{t('정말 삭제 하시겠습니까?')}</div>
          <div className='inputForm-body'>
            <div className='modal-footer flex-end'>
              <BtnGhost onClick={onClickClose}>{t('아니요')}</BtnGhost>
              <BtnRed onClick={onClickDelete}>{t('네')}</BtnRed>
            </div>
          </div>
        </div>
      </Modal>
    </ModalBackground>
  );
};

export default DeleteModal;
