import { PASSWORD, PASSWORD_WRONG } from '../_constants';
/**
 * 작성자 : 홍선영
 * 날짜 : 2023.03.29
 * 기능 : 비밀번호와 비밀번호 확인 값을 받아서 빈값여부, 두 비밀번호 일치여부 확인하여
 *        조건충족여부와 알럿메시지값 리턴하는 함수
 */

import { useState, useEffect } from 'react';

export const usePasswordValidationCheck = (password: string, passwordConfirm: string) => {
  const [isPasswordPassed, setIsPasswordPassed] = useState(false);
  const [passwordAlertMessage, setPasswordAlertMessage] = useState('');

  useEffect(() => {
    if (password?.trim() === '' || passwordConfirm?.trim() === '') {
      setPasswordAlertMessage(PASSWORD);
      setIsPasswordPassed(false);
    } else if (password !== passwordConfirm) {
      setPasswordAlertMessage(PASSWORD_WRONG);
      setIsPasswordPassed(false);
    } else setIsPasswordPassed(true);
  }, [password, passwordConfirm]);

  return { isPasswordPassed, passwordAlertMessage };
};
