/**
 * 작성자 : 한영광
 * 날짜 : 2023.05.10
 * 기능 : TOAST UI 라이브러리로 테이블 생성하는 컴포넌트
 */
/**
 * 수정자 : 한영광
 * 날짜 : 2023.09.05
 * 수정내용 : 정적 컴포넌트로 TUI-GRID를 사용하면서 자동 리로드 되는 부분 개선을 위해 동적인 컴포넌트로 수정함
 * 참고자료 : https://github.com/nhn/tui.grid/blob/master/packages/toast-ui.grid/docs/ko/README.md
 */
import { TuiGridStyle } from '../../assets/styles/TuiGridStyle';
import Grid from '@toast-ui/react-grid';
import { useRef, useEffect, useState, Dispatch, SetStateAction, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { asideFoldState } from '../../atoms';
import { useRecoilValue } from 'recoil';

interface Props {
  data: any[];
  filterTableState?: any[];
  setFilterTableState?: Dispatch<SetStateAction<any>>;
  columns: any[];
  onClickRow?: Function;
  perPage?: number; // 페이지 당 보여줄 로우 갯수
  height?: any;
  usePagenation?: boolean; // 페이징 사용유무
  rowKey?: string; // 선택된 로우 index (선택된 로우를 리렌더링 시에도 유지하기위해 사용. onClickRow 함수를 통해 상위컴포넌트로 전달함)
  excelBtn?: boolean;
  setExcelBtn?: Dispatch<SetStateAction<any>>;
  onClickCheckBox?: Function;
  visibleTotalCount?: boolean;
  scrollX?: boolean;
  visiblePages?: number; // pagenation 사용 시 보여줄 페이지 갯수
  rowHeight?: any;
  frozenCount?: number; // 고정시킬 컬럼 갯수(좌측부터 시작)
  dashboard?: boolean; // 대시보드에서 사용하는건지 구분
  eListYn?: string; // 근로자 명 일부 가릴지 여부 ('Y' or 'N')
  eExcelYn?: string; // 엑셀 내보내기 시 근로자 명 일부 가릴지 여부 ('Y' or 'N')
  rowClickCheckBox?: boolean; // 로우 클릭 시 체크박스 체크 처리 할지 여부
  checkedColumn?: { columnName: string | null; checked: boolean }; // tuiGrid 외부의 컬럼 선택값
  modal?: boolean; // 모달창인지
  summary?: any; // 테이블 고정 로우
  fileName?: string;
  setRowKey?: Dispatch<SetStateAction<any>>;
  customCheck?: boolean; // tuigrid 로우 내부 체크박스 여부
  draggable?: boolean; // 드래그가능 여부
  key?: string;
  // width?: number | 'auto';
  /**
   * 작성자 : 한영광
   * 작성날짜 : 2023-09-07
   * summary 타입
    {
      height?: number;
      position?: SummaryPosition;
      defaultContent?: string | SummaryColumnContentMapOnlyFn;
      columnContent?: {
        [propName: string]: string | SummaryColumnContentMapOnlyFn;
      };
    }
   */
}

const TuiGrid = (props: Props) => {
  const gridRef = useRef<Grid | null>(null);
  const {
    key,
    data,
    filterTableState,
    setFilterTableState,
    columns,
    perPage = 15,
    onClickRow,
    height = 600,
    usePagenation = false,
    rowKey,
    excelBtn,
    setExcelBtn,
    onClickCheckBox,
    visibleTotalCount,
    scrollX = true,
    visiblePages = 10,
    summary = {},
    rowHeight = 48,
    frozenCount = 0,
    dashboard,
    rowClickCheckBox,
    checkedColumn,
    eListYn = 'N',
    eExcelYn,
    modal,
    fileName: xlsxName,
    setRowKey,
    customCheck = false,
    draggable = false,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [dataState, setDataState] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(data.length);
  const [pageNum, setPageNum] = useState<any>(1);
  const queryParams = new URLSearchParams(location.search);
  const maxPageNum = Math.ceil(data.length / perPage) <= 0 ? 1 : Math.ceil(data.length / perPage);
  const foldMenu = useRecoilValue(asideFoldState);

  useEffect(() => {
    const newData =
      eListYn === 'Y'
        ? data.map((v: any) => {
            return {
              ...v,
              wName: v.wName ? `${v.wName.substring(0, 1)}*${v.wName.substring(2)}` : '',
            };
          })
        : data;
    setDataState(newData);
    if (setFilterTableState) setFilterTableState(newData);
    setTotalCount(data.length);
    setPageNum(1);
  }, [data, perPage]);

  useEffect(() => {
    data.map((v: any, i: number) => {
      v.rowKey = dataState[i]?.rowKey;
    });

    const grid = gridRef.current?.getInstance();

    if (!modal) {
      grid?.getPagination()?.movePageTo(Number(pageNum));
    }

    grid?.on('beforeFilter', (ev: any) => {
      if (setRowKey) setRowKey('');
    });

    grid?.on('afterFilter', (ev: any) => {
      dataState.map((_: any, i: number) => {
        grid?.removeRowClassName(i, 'selectedRow');
      });
      if (setFilterTableState) {
        setFilterTableState(ev.instance.store.data.filteredRawData);
      }
    });

    grid?.on('afterUnfilter', (ev: any) => {
      dataState.map((_: any, i: number) => {
        grid?.removeRowClassName(i, 'selectedRow');
      });
      if (setFilterTableState) {
        setFilterTableState(ev.instance.store.data.filteredRawData);
      }
    });

    grid?.on('afterSort', (ev: any) => {
      dataState.map((_: any, i: number) => {
        grid?.removeRowClassName(i, 'selectedRow');
      });
      if (setFilterTableState) {
        setFilterTableState(ev.instance.store.data.filteredRawData);
      }
    });

    grid?.on('afterUnsort', (ev: any) => {
      dataState.map((_: any, i: number) => {
        grid?.removeRowClassName(i, 'selectedRow');
      });
      if (setFilterTableState) {
        setFilterTableState(ev.instance.store.data.filteredRawData);
      }
    });

    grid?.on('drop', (ev: any) => {
      dataState.map((_: any, i: number) => {
        grid?.removeRowClassName(i, 'selectedRow');
      });
      if (setFilterTableState) {
        setFilterTableState(ev.instance.store.data.filteredRawData);
      }
    });
  }, [dataState]);

  useEffect(() => {
    const grid = gridRef.current?.getInstance();

    const beforePageMoveListener = (ev: any) => {
      if (!dashboard && !modal) {
        navigate({
          pathname: location.pathname,
          search: `?pageNum=${ev.page}`,
        });
      }
    };

    if (grid && usePagenation) {
      grid.on('beforePageMove', beforePageMoveListener);
    }

    // 언마운트시 이벤트리스너 해제
    return () => {
      if (grid && grid && Object.keys(grid).length > 0 && usePagenation) {
        grid?.off('beforePageMove', beforePageMoveListener);
      }
    };
  }, [dataState]);

  useEffect(() => {
    const gridInstance = gridRef.current?.getInstance();
    if (gridInstance && dataState) {
      try {
        gridInstance.resetData(dataState, { pageState: { page: 1, perPage } });
      } catch (error) {
        console.error('Error resetting data in TUI Grid:', error);
      }
    }
  }, [gridRef, perPage, dataState]);

  useEffect(() => {
    const grid = gridRef.current?.getInstance();
    const handleExport = (ev: any) => {
      ev.stop();
      let wNameIdx: number = -1;

      const newDataList = ev.data.map((v: any, i: number) => {
        if (i === 0) {
          wNameIdx = v.findIndex((v2: any) => v2 === t('근로자명'));
        } else {
          const newData = v;
          newData[0] = newData[0]?.replace('No.', '');
          /**
           * 수정자 : 한영광
           * 수정일자 : 2023.11.09
           * 수정내용 : 암호화 할 근로자명을 포함하지 않는 데이터인 경우 엑셀 내보내기 시 빈 데이터가 나오는 버그가 발생하여
           *           eExcelYn이 null이 아닌것을 암호화 할 근로자명이 있는것으로 예외처리 함
           *           **근로자명 컬럼의 순서를 통일해줘야 하고 순서가 바뀌면 아래 인덱스값도 수정해야함
           *              -> 만약 순서를 통일 할 수 없다면 파라미터로 컬럼순서를 받아서 처리 할 예정
           */
          if (eExcelYn && wNameIdx !== -1) {
            newData[wNameIdx] = eExcelYn === 'Y' ? (data[i - 1]?.wName ? `${data[i - 1]?.wName.substring(0, 1)}*${data[i - 1]?.wName.substring(2)}` : '') : data[i - 1]?.wName;
          }
          return [...newData];
        }
        return [...v];
      });

      // 사진컬럼이 있을 때 엑셀보내기 시 사진컬럼 삭제
      const imgIndex = ev.data[0].indexOf(t('사진'));
      // 첫 번째 하위 배열에 '사진'이 있는 경우
      if (imgIndex !== -1) {
        // 각 하위 배열에서 imgIndex 위치의 값 제거
        for (const subArray of newDataList) {
          subArray.splice(imgIndex, 1);
        }
      }
      ev.exportFn(newDataList);
    };

    if (excelBtn && setExcelBtn) {
      grid?.on('beforeExport', handleExport);
      grid?.export('xlsx', {
        fileName: xlsxName || 'PMS', // 내보낼 파일의 이름을 정의
        onlyFiltered: true,
      });
      setExcelBtn(false);
    }

    return () => {
      if (excelBtn && setExcelBtn) {
        grid?.off('beforeExport', handleExport);
      }
    };
  }, [excelBtn]);

  useEffect(() => {
    const grid = gridRef.current?.getInstance();
    if (onClickCheckBox) {
      grid?.on('checkAll', () => {
        onClickCheckBox(grid.getCheckedRows());
      });
      grid?.on('uncheckAll', () => {
        onClickCheckBox(grid.getCheckedRows());
      });
      grid?.on('check', () => {
        onClickCheckBox(grid.getCheckedRows());
      });
      grid?.on('uncheck', () => {
        onClickCheckBox(grid.getCheckedRows());
      });
    }
  }, [onClickCheckBox]);

  useEffect(() => {
    const grid = gridRef.current?.getInstance();
    dataState.map((_: any, i: number) => {
      grid?.removeRowClassName(i, 'selectedRow');
    });

    if (rowKey && dataState.length > Number(rowKey)) {
      grid?.addRowClassName(rowKey, 'selectedRow');
      grid?.focusAt(Number(rowKey), 0, true);
    }
  }, [rowKey, dataState]);

  useEffect(() => {
    const grid = gridRef.current?.getInstance();
    if (rowKey !== undefined && rowKey !== '' && filterTableState && Number(rowKey) < dataState.length) {
      grid?.focusAt(Number(rowKey), 0, true);

      data.map((_: any, i: number) => {
        grid?.removeRowClassName(i, 'selectedRow');
      });
      grid?.addRowClassName(filterTableState[Number(rowKey)]?.rowKey, 'selectedRow');
    }
    if (filterTableState) {
      setTotalCount(filterTableState.length);
    }
    setPageNum(queryParams.get('pageNum'));
  }, [rowKey, filterTableState]);

  useEffect(() => {
    const grid = gridRef.current?.getInstance();
    grid?.getPagination()?.movePageTo(Number(pageNum));
  }, [pageNum, dataState]);

  useEffect(() => {
    /**
     * 수정자 : 홍선영
     * 수정일자 : 2024.05.14
     * 수정내용 : 근로자 서명관리에서 tuigrid 외부의 상단 컬럼전체 체크박스 클릭시 모든 로우의 컬럼값 업데이트 처리 추가
     */
    if (checkedColumn?.columnName) {
      const grid = gridRef.current?.getInstance();
      grid?.setColumnValues(checkedColumn.columnName, checkedColumn.checked);

      if (grid?.getData()) setDataState(grid?.getData());
      if (onClickCheckBox) onClickCheckBox(grid?.getCheckedRows());
    }
  }, [checkedColumn, gridRef]);

  const onClick = (ev: any) => {
    if (onClickRow) {
      if (
        ev.rowKey !== undefined &&
        ev.columnName !== '_checked' &&
        ev.columnName !== 'wWorkstatus' &&
        ev.columnName !== 'aImgPath' &&
        ev.columnName !== 'button' &&
        ev.columnName !== 'workerInfoCheck' &&
        ev.columnName !== 'cCheck' && // 공통 양식지
        ev.columnName !== 'jCheck' && // 직종별 양식지
        ev.columnName !== 'muscularCheck' &&
        ev.columnName !== 'painCheck' &&
        ev.columnName !== 'stressCheck' &&
        ev.targetType !== 'columnHeader'
      ) {
        const grid = gridRef.current?.getInstance();
        let filterRowKey: any;
        if (filterTableState) {
          filterRowKey = filterTableState?.findIndex((v) => v.rowKey === ev.rowKey);
        } else {
          filterRowKey = ev.rowKey;
        }
        onClickRow(dataState[ev.rowKey], ev.columnName, filterRowKey);

        data.map((_: any, i: number) => {
          grid?.removeRowClassName(i, 'selectedRow');
        });
        grid?.addRowClassName(Number(filterRowKey), 'selectedRow');
        if (rowClickCheckBox && onClickCheckBox) {
          grid?.check(filterRowKey);
          onClickCheckBox(grid?.getCheckedRows());
        }
      } else if (summaryCheck() && ev.targetType === 'dummy') {
        /**
         * 수정자 : 한영광
         * 수정일자 : 2023.11.20
         * 수정내용 : 연출역 현황에서 summary(합계 나타내는 로우)를 사용하는데 summary 클릭 시 이벤트 처리를 위해 추가함
         */
        onClickRow('summary', ev.nativeEvent.srcElement.getAttribute('data-column-name'));
      }
    }

    /**
     * 수정자 : 홍선영
     * 수정일자 : 2024.05.14
     * 수정내용 : 근로자 서명관리에서 로우 내부의 checkbox 클릭시 해당셀 값 업데이트 처리 추가
     */
    if (customCheck) {
      if (
        ev.rowKey !== undefined &&
        ev.columnName !== 'wName' &&
        ev.columnName !== 'sjName' &&
        ev.columnName !== 'wPrejobtypeName' &&
        ev.columnName !== 'wJobtypeName' &&
        ev.columnName !== 'wJobdate' &&
        ev.columnName !== 'wWorkstatusName' &&
        ev.columnName !== 'wHnum' &&
        ev.columnName !== 'stressTotal' &&
        ev.columnName !== 'stressResult' &&
        ev.columnName !== 'wBdate' &&
        ev.columnName !== 'wRetiredate' &&
        ev.targetType !== 'columnHeader'
      ) {
        const grid = gridRef.current?.getInstance();
        // let filterRowKey: any;
        // if (filterTableState) {
        //   filterRowKey = filterTableState?.findIndex((v) => v.rowKey === ev.rowKey);
        // } else {
        //   filterRowKey = ev.rowKey;
        // }

        grid?.setValue(ev.rowKey, ev.columnName, !grid?.getValue(ev.rowKey, ev.columnName));

        // grid?.check(filterRowKey);
        if (onClickCheckBox) onClickCheckBox(grid?.getCheckedRows());
      }
    }
  };
  const summaryCheck = () => {
    if (Object.keys(summary).length !== 0 && data.length !== 0) return true;
    return false;
  };
  const [tuiHeight, setTuiHeight] = useState<any>(0);
  useEffect(() => {
    // pagenation이 있을때
    if (usePagenation === true) {
      setTuiHeight(height - 84);
    }
    // pagenation이 없고 summary가 있고 데이터가 없을때
    if (usePagenation === false && Object.keys(summary).length !== 0 && dataState.length === 0) {
      setTuiHeight(height - 12);
    }
    // pagenation이 없고 summary가 있고 데이터가 있을때
    if (usePagenation === false && Object.keys(summary).length !== 0 && dataState.length !== 0) {
      setTuiHeight(height - 80);
    }
    // pagenation이 없고 summary가 없을때
    if (usePagenation === false && Object.keys(summary).length === 0) {
      setTuiHeight(height - 40);
    }
  }, [height, dataState.length]);

  // side menu가 fold되면 리렌더링
  useEffect(() => {
    const grid = gridRef.current?.getInstance();
    if (grid) {
      grid.refreshLayout();
    }
  }, [gridRef]);

  /**
   * 수정자 : 한영광
   * 수정일자 : 2024.01.29
   * 수정내용 : Summary 데이터가 변경된 경우 TuiGrid 라이브러리에 변경된 데이터가 반영이 안되는 이슈 발생
   *           Summary 데이터 변경 시 TuiGrid 내부함수 setSummaryColumnContent()를 사용하여 변경된 Summary 데이터를 반영하도록 수정
   */
  useEffect(() => {
    if (summaryCheck()) {
      const grid = gridRef.current?.getInstance();
      Object.keys(summary.columnContent).map((v: string) => {
        grid?.setSummaryColumnContent(v, summary.columnContent[v]);
      });
    }
  }, [summary]);

  const handleResize = () => {
    const grid = gridRef.current?.getInstance();
    if (grid) {
      grid?.refreshLayout();
    }
  };

  return (
    <TuiGridStyle onResize={handleResize}>
      {!summaryCheck() ? ( // summary 사용하지 않는경우
        <>
          <Grid
            key={key}
            draggable={draggable}
            data={dataState}
            columns={columns}
            rowHeaders={
              onClickCheckBox
                ? [
                    {
                      type: 'checkbox',
                      header: `
                          <label for="all-checkbox" class="checkbox">
                            <input type="checkbox" id="all-checkbox" class="hidden-input" name="_checked" />
                            <span class="custom-input"></span>
                          </label>
                        `,
                    },
                    { type: 'rowNum', width: 40, align: 'center', renderer: { classNames: ['tui-num'] } },
                  ]
                : [{ type: 'rowNum', width: 40, align: 'center', renderer: { classNames: ['tui-num'] } }]
            } // 맨 앞에 No. 생성 (버그있는 듯)
            bodyHeight={tuiHeight}
            rowHeight={rowHeight !== undefined ? rowHeight : 56}
            usageStatistics={false}
            columnOptions={{ resizable: true, frozenCount, frozenBorderWidth: 0 }}
            pageOptions={
              usePagenation
                ? {
                    useClient: true, // 프론트엔드에서 페이징 사용할지 여부
                    perPage, // 페이징할 row 갯수
                    // page: Number(pageNum) ? (Number(pageNum) > maxPageNum ? maxPageNum : Number(pageNum)) : 1, // 디폴트 페이지번호
                    page: pageNum,
                    visiblePages,
                  }
                : undefined
            }
            scrollX={scrollX}
            scrollY
            width='auto'
            onClick={(ev: any) => onClick(ev)}
            ref={gridRef}
            // oneTimeBindingProps={['data']}
          />
          {visibleTotalCount && (
            <h5 className='gridCount'>
              {t('총')} {totalCount}
              {t('개가 검색되었습니다')}.
            </h5>
          )}
        </>
      ) : (
        // summary 사용 하는경우
        <>
          <div style={{ display: 'none' }}>{`${summary}`}</div>
          <Grid
            key={key}
            draggable={draggable}
            data={dataState}
            columns={columns}
            summary={summary}
            rowHeaders={
              onClickCheckBox
                ? [
                    {
                      type: 'checkbox',
                      header: `
                        <label for="all-checkbox" class="checkbox">
                          <input type="checkbox" id="all-checkbox" class="hidden-input" name="_checked" />
                          <span class="custom-input"></span>
                        </label>
                      `,
                    },
                    { type: 'rowNum', width: 40, align: 'center', renderer: { classNames: ['tui-num'] } },
                  ]
                : [{ type: 'rowNum', width: 40, align: 'center', renderer: { classNames: ['tui-num'] } }]
            }
            // bodyHeight={height - 90}
            bodyHeight={tuiHeight}
            rowHeight={rowHeight !== undefined ? rowHeight : 56}
            usageStatistics={false}
            columnOptions={{ resizable: true, frozenCount, frozenBorderWidth: 0 }}
            pageOptions={
              usePagenation
                ? {
                    useClient: true, // 프론트엔드에서 페이징 사용할지 여부
                    perPage, // 페이징할 row 갯수
                    // page: Number(pageNum) ? (Number(pageNum) > maxPageNum ? maxPageNum : Number(pageNum)) : 1, // 디폴트 페이지번호
                    page: pageNum,
                    visiblePages,
                  }
                : undefined
            }
            scrollX
            scrollY
            onClick={(ev: any) => onClick(ev)}
            ref={gridRef}
            width='auto'
            // oneTimeBindingProps={['data']}
          />
          {visibleTotalCount && (
            <h5 className='gridCount'>
              {t('총')} {totalCount}
              {t('개가 검색되었습니다')}.
            </h5>
          )}
        </>
      )}
    </TuiGridStyle>
  );
};

export default TuiGrid;
