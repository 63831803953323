/**
 * 작성자 : 홍선영
 * 날짜 : 2023.12.08
 * 경로 : 설정관리 > 터널관리 > 작업 내용 목록 & 장비 상태 목록
 */

import { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { PulseLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { userState } from '../../../atoms';
import { IModal } from 'customTypes';
import { COMCD_USE_YN, ERROR, FLAG_CREATE_OR_UPDATE, FLAG_NONE, INIT_USE_YN_A } from '../../../_constants';
import i18n from '../../../translation/i18n';
import { InputTable } from '../../../assets/styles/InputTable';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { BtnBlue, BtnGhost, BtnRed } from '../../../components/Button';
import Input from '../../../components/Input';
import Portal from '../../../components/Portal';
import SelectBox from '../../../components/SelectBox';
import SelectBoxs from '../../../components/SelectBoxs';
import IssueGuide from '../../../components/IssueGuide';
import DeleteModal from '../../../components/Modal/DeleteModal2';
import { ynFilter } from '../../../utils/ynFilter';
import { useSetAuth } from '../../../utils/useSetAuth';
import { applyBorderStyle } from '../../../utils/applyBorderStyle';
import { scrollToNodeBottom } from '../../../utils/scrollToNodeBottom';
import { useQuery } from '@tanstack/react-query';
import { apiDelete, apiGet, apiPost } from '../../../services/_common';
import { toast } from 'react-toastify';
import { trimArray } from '../../../utils/trimArray';
import { useFetchCommonCodeList } from '../../../services/useSetCodeListInSelectBoxForm';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import { logPost } from '../../../services/log';

const Root = styled.div`
  .inputFormsWrapper.smallTab {
    flex-direction: row;
    align-items: center;
    padding: 0 0.5rem;
    font-weight: 500;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 0rem !important;
    }import { applyBorderStyle } from './../../../utils/applyBorderStyle';

    .tab {
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
      cursor: pointer;
      height: 3.5rem;
      border-bottom: 2px solid transparent;
    }
    .activeTab {
      font-weight: 700;
      border-bottom: 2px solid ${({ theme }: { theme: any }) => theme.selected_primary};
      color: ${({ theme }: { theme: any }) => theme.selected_primary};
    }
  }

  .tr > div > div {
    width: 100%;
    li { width: 100% !important; }
  }

  .secondSearchOption {
    width: fit-content;
  }

  .textBtnGroup button {
    padding: 0 0.75rem;
    width: 4.5rem;
    height: 2.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
  }
`;

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 0.5rem;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;

interface ITjcCd {
  tjcCd: string;
  tjcName: string;
  useYn: string;
  editor?: string;
  writer?: string;
}
interface ITesCd {
  tesCd: string;
  tesName: string;
  useYn: string;
  editor?: string;
  writer?: string;
}
interface IData {
  type: string;
  endPoint: string;
  dtoKey: string;
  nameKey: string;
  codeKey: string;
  responseArrayKey: string;
}

interface Props {
  prop: IData;
}

const TAreaInputList = ({ prop }: Props) => {
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const userInfo = useRecoilValue(userState);
  const [searchOption, setSearchOption] = useState({ [prop.nameKey]: '' });
  const [searchOptionUseYn, setSearchOptionUseYn] = useState(INIT_USE_YN_A);
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const scrollContainerRef = useRef<HTMLInputElement>(null);
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const { data: useYnComCdListWithAll } = useFetchCommonCodeList(COMCD_USE_YN, true); // 사용여부 공통코드 목록 (전체포함)
  const { data: useYnComCdList } = useFetchCommonCodeList(COMCD_USE_YN, false); // 사용유무 공통코드 목록
  const {
    data: tunnelContentList,
    isLoading,
    isFetching,
    isError,
  } = useQuery([`tAreaList_${prop.type}`, prop.type], () => fetchData(prop), { enabled: !!userInfo.hCd && !!userInfo.sCd && !!prop, cacheTime: 0 });
  const [tableState, setTableState] = useState<any[]>(tunnelContentList?.map((el: any) => ({ ...el, flag: FLAG_NONE })) || []);
  const [initTableState, setInitTableState] = useState<any[]>(tunnelContentList?.map((el: any) => ({ ...el, flag: FLAG_NONE })) || []);

  const fetchData = async (propParam: IData) => {
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
      const res = await apiGet({ path: propParam.endPoint, req });
      const result = res.data.data[propParam.responseArrayKey];
      setTableState(result.map((el: any) => ({ ...el, flag: FLAG_NONE })));
      setInitTableState(result.map((el: any) => ({ ...el, flag: FLAG_NONE })));
      return result;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  useEffect(() => {
    if (prop.type) {
      logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: prop.type === 'jc' ? '터널 설정 관리 > 작업 내용 목록' : '터널 설정 관리 > 장비 상태 목록',
        action: '조회',
        etc: ``,
      });
    }
  }, [prop.type]);

  useEffect(() => {
    setSearchOptionUseYn(INIT_USE_YN_A);
  }, [i18n.language]);

  useEffect(() => {
    if (tableState.length > 0) scrollToNodeBottom(scrollContainerRef);
  }, [tableState.length]);

  useEffect(() => {
    setSearchOption({ [prop.nameKey]: '' });
  }, [prop.nameKey]);

  useEffect(() => {
    applyFilter(initTableState);
  }, [searchOption[prop.nameKey], searchOptionUseYn[COMCD_USE_YN]]);

  const applyFilter = (array: any[]) => {
    // 검색옵션 변경됐을 때 필터링 처리
    // 필터링된 어레이 리턴, 대소문자구분X
    const titleFilteredArray = array.filter((el: any) => el[prop.nameKey]?.toLowerCase()?.includes(searchOption[prop.nameKey]?.toLowerCase()));
    const result = ynFilter(titleFilteredArray, 'useYn', searchOptionUseYn[COMCD_USE_YN]);
    if (result.length > 0) setTableState(result);
    else setTableState([]);
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    setSearchOptionUseYn({ type: COMCD_USE_YN, [COMCD_USE_YN]: 'A', cdName: t('전체') });
    setSearchOption({ [prop.nameKey]: '' });
  };

  const onClickAddTableRow = () => {
    const jcObj = { tjcCd: '', tjcName: '', useYn: 'Y' };
    const esObj = { tesCd: '', tesName: '', useYn: 'Y' };
    let newObj: ITjcCd | ITesCd;
    if (prop.type === 'jc') newObj = jcObj;
    else newObj = esObj;
    setTableState((prev: ITjcCd[] | ITesCd[]) => [...prev, newObj]);
  };

  const onClickSave = () => {
    setIsSaveClicked(true);
    const required = [prop.nameKey];
    const emptyCheck = tableState.filter((el: any) => {
      const check = required.find((el2: any) => el[el2] === '' && el.flag === FLAG_CREATE_OR_UPDATE);
      return check;
    });
    if (emptyCheck.length > 0) toast.warning(t('필수입력값을 모두 입력하세요'));
    else {
      const updateArray = tableState.filter((el: any) => el.flag === FLAG_CREATE_OR_UPDATE);
      if (updateArray.length > 0) postAPI(updateArray);
    }
  };

  const postAPI = async (array: ITjcCd[] | ITesCd[]) => {
    const newArray = array.map((el: ITjcCd | ITesCd) => ({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      useYn: el.useYn,
      [prop.codeKey]: el[prop.codeKey as keyof (ITjcCd | ITesCd)],
      [prop.nameKey]: el[prop.nameKey as keyof (ITjcCd | ITesCd)],
      editor: userInfo.userId,
      ...(!!el[prop.codeKey as keyof (ITjcCd | ITesCd)] === false && { writer: userInfo.userId }),
    }));
    const req = { [prop.dtoKey]: trimArray(newArray) };
    const res = await apiPost({ path: prop.endPoint, req });
    const { statusCode, message } = res.data;
    if (statusCode === 200) {
      applyFilter(res.data.data[prop.responseArrayKey].map((el: any) => ({ ...el, flag: FLAG_NONE })));
      setInitTableState(res.data.data[prop.responseArrayKey].map((el: any) => ({ ...el, flag: FLAG_NONE })));
      setIsSaveClicked(false);
      toast.success(t(message));
    } else toast.error(t(ERROR));
  };

  const deleteAPI = async (el: any) => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, [prop.codeKey]: el[prop.codeKey], editor: userInfo.userId };
    const res = await apiDelete({ path: prop.endPoint, req });
    const { data, statusCode, message } = res.data;
    if (statusCode === 200) {
      setIsSaveClicked(false);
      toast.success(t(message));
      applyFilter(data[prop.responseArrayKey].map((resEl: any) => ({ ...resEl, flag: FLAG_NONE })));
      setInitTableState(data[prop.responseArrayKey].map((resEl: any) => ({ ...resEl, flag: FLAG_NONE })));
    } else toast.error(t(ERROR));
  };

  const onClickDelete = (el: any, i: number) => {
    if (el[prop.codeKey]) {
      // 기존 데이터 삭제시
      const data = { ...el, editor: userInfo.userId };
      setOpenModal((prev) => ({ ...prev, type: 'delete', status: true, state: tableState, setState: setTableState, api: () => deleteAPI(data), el: data, index: i }));
    } else {
      // 제거 (새로 추가한 데이터 제거시)
      const updatedArray = [...tableState];
      updatedArray.splice(i, 1);
      setTableState(updatedArray);
    }
  };

  if (isError) return <IssueGuide text='요청하신 작업을 완수하지 못했습니다 잠시 후 다시 시도하세요' />;
  if (isLoading || isFetching)
    return (
      <LoadingModalBackground>
        <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' style={{ width: 'inherit', height: 'inherit' }} />
      </LoadingModalBackground>
    );

  return (
    <div className='content-container oneColumn'>
      <Root>
        <SearchOptions align='left'>
          <div className='inputsWrapper'>
            <div className='flex-basic textBtnGroup'>
              <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col withLabelComCf'>
                <label htmlFor='useYn'>{t('사용유무')}</label>
                <SelectBox
                  options={useYnComCdListWithAll}
                  defaultOption={searchOptionUseYn.cdName}
                  state={searchOptionUseYn}
                  setState={setSearchOptionUseYn}
                  stateKey={COMCD_USE_YN}
                  initiateKey={searchOptionUseYn[COMCD_USE_YN]}
                  filterbar='filter-1-left'
                />
              </div>
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col'>
                <Input
                  label=''
                  placeholder={prop.type === 'jc' ? t('작업 내용 항목명') : t('장비 상태 항목명')}
                  type='text'
                  id={prop.nameKey}
                  name={prop.nameKey}
                  state={searchOption}
                  setState={setSearchOption}
                />
              </div>
            </div>
          </div>
        </SearchOptions>
        <InputTable className='margin-left-05' ref={scrollContainerRef}>
          <div className='thead'>
            <div className='tr'>
              <div className='trCol2p5 flex-center tableStickyNo'>No</div>
              <div className='trCol4 flex-center'>{t('코드')}</div>
              <div className='trCol12 flex-center required content-overflow'>{prop.type === 'jc' ? t('작업 내용 항목명') : t('장비 상태 항목명')}</div>
              <div className='trCol8 flex-center'>{t('사용유무')}</div>
              <div className='trCol10 flex-center'>{t('등록일자')}</div>
              <div className='trCol6 flex-center'>{t('등록자')}</div>
              <div className='trCol10 flex-center'>{t('수정일자')}</div>
              <div className='trCol6 flex-center'>{t('수정자')}</div>
              {auth.deleteAuth && <div className='trCol4 flex-center'> </div>}
            </div>
          </div>
          {tableState.length > 0 ? (
            <div className='table'>
              <div className='tbody'>
                {tableState.map((el, index) => (
                  <div className='tr' key={`${index}_${el[prop.codeKey]}`}>
                    <div className='trCol2p5 flex-center tableStickyNo'>{index + 1}</div>
                    <div className='trCol4 flex-center'>{el[prop.codeKey]}</div>
                    <div className='trCol12 flex-center content-overflow content-overflow'>
                      <Input
                        type='text'
                        id={prop.nameKey}
                        name={prop.nameKey}
                        state={tableState}
                        setState={setTableState}
                        stateType={{ type: 'array', index }}
                        getBorderStyle={isSaveClicked ? applyBorderStyle(el[prop.nameKey], 'red', 'name') : undefined}
                        useFlag
                      />
                    </div>
                    <div className='trCol8 flex-center'>
                      <SelectBoxs
                        options={useYnComCdList}
                        defaultOption={el.useYn === 'Y' ? t('사용') : t('미사용')}
                        state={tableState}
                        setState={setTableState}
                        rawData={initTableState}
                        setRawData={setInitTableState}
                        stateKey='useYn'
                        codeKey='cdName'
                        index={index}
                        object={el}
                        primaryKey='useYn'
                        initiateKey='useYn'
                      />
                    </div>
                    <div className='trCol10 flex-center'>{el.wDate}</div>
                    <div className='trCol6 flex-center'>{el.writer}</div>
                    <div className='trCol10 flex-center'>{el.eDate}</div>
                    <div className='trCol6 flex-center'>{el.editor}</div>
                    {auth.deleteAuth && (
                      <div className='trCol4 flex-center'>
                        <BtnRed onClick={() => onClickDelete(el, index)}>{el[prop.codeKey] ? t('삭제') : t('제거')}</BtnRed>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <IssueGuide />
          )}
        </InputTable>
        <ButtonsWrapper>
          {auth.createAuth && <BtnBlue onClick={onClickAddTableRow}>{t('신규항목 추가')}</BtnBlue>}
          {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={onClickSave}>{t('저장')}</BtnBlue>}
        </ButtonsWrapper>
      </Root>
      <Portal openModal={openModal?.status}>{openModal && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
    </div>
  );
};

export default TAreaInputList;
