/**
 * 작성자 : 한영광
 * 날짜 : 2023.07.12
 * 기능 : Line Chart Modal
 */

import { Dispatch, SetStateAction } from 'react';
import { IAuth, IModal } from 'customTypes';
import { ModalBackground, Modal } from '../../assets/styles/Modal';

import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { BtnBlue, BtnGray } from '../Button';
import { useTranslation } from 'react-i18next';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface IProps {
  setOpenModal: Dispatch<SetStateAction<any>>;
  /**
   * chartData 예시
   * [
   [
    {
      dvName: '센서명1',
      dvNo: '1',
      checkTime: '2023-07-07',
      pm10: '20'  // 키값(pm10)은 datasets의 data와 매핑
      pm25: '30'  // 키값(pm25)은 datasets의 data와 매핑
    }
   ].length() === 날짜갯수
  ].length() === 장비갯수
   */
  chartData: any[];
  /**
   * datasets 예시
   * [
    {
      label: 'PM2.5',
      data: 'pm25',
      borderColor: 'rgba(0, 221, 255, 0.5)',
      backgroundColor: 'rgba(0, 221, 255, 0.5)',
    },
    {
      label: 'PM10',
      data: 'pm10',
      borderColor: 'rgba(0, 119, 255, 0.5)',
      backgroundColor: 'rgba(0, 119, 255, 0.5)',
    },
  ]
   */
  datasets: any[];
  title: string; // 차트 제목
}

const LineChartModal = ({ setOpenModal, chartData, datasets, title }: IProps) => {
  const { t } = useTranslation();
  const onClickClose = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };
  const renderChart = chartData.map((v: any, i: number) => (
    <Line
      key={i}
      data={{
        labels: v.map((v2: any) => v2.checkTime),
        datasets: datasets.map((dataset: any) => {
          return {
            ...dataset,
            data: v.map((v2: any) => v2[dataset.data]),
          };
        }),
      }}
      options={{
        plugins: {
          legend: {
            position: 'bottom' as const,
          },
          title: {
            display: true,
            text: `${t('장비명')} : ${v[0].dvName}  ${t('측정일자')} : ${v[0].checkTime} ~ ${v[v.length - 1].checkTime}`,
          },
        },
      }}
    />
  ));
  return (
    <ModalBackground onClick={onClickClose} role='presentation'>
      <Modal minWidth={75}>
        <div
          className='modal height-fit'
          // style={{ height: 'auto', padding: '0 0.5rem' }}
          role='presentation'
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className='inputForm-head'>
            <div className='modalHeaderTitle'>{title}</div>
            <div className='closeBtn' onClick={onClickClose} role='presentation'>
              <span className='material-symbols-rounded'>close</span>
            </div>
          </div>
          <div className='sensor flexColumn flex-between col-w100'>
            <div className='chart' style={{ height: '39.5rem', maxHeight: '80vh' }}>
              {renderChart}
            </div>
            <div className='modal-footer flex-end col-w100'>
              <BtnGray onClick={onClickClose}>{t('닫기')}</BtnGray>
            </div>
          </div>
        </div>
      </Modal>
    </ModalBackground>
  );
};

export default LineChartModal;
