import styled from 'styled-components';

export const InputTable = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll auto;
  width: 100%;
  height: fit-content;
  flex-grow: 1;
  &.overflow-y-scroll {
    overflow: scroll !important;
    &::-webkit-scrollbar {
      width: 0.75rem !important;
    }
  }
  &.mGasTable {
    animation: 30s linear;
    animation-direction: reverse;
    animation-iteration-count: infinite;
    animation-name: mGasTable;
  }
  &.margin-left-05 {
    margin-left: 0.5rem;
    width: calc(100% - 0.5rem) !important;
  }
  &.mGasTable {
    overflow: auto;
  }
  .padding-right-05 {
    padding-right: 0.5rem !important;
  }
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  &:hover::-webkit-scrollbar {
    width: 0.75rem !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }: { theme: any }) => theme.scrollBar};
  }
  &.noHorizontalScroll::-webkit-scrollbar {
    height: 0 !important;
  }
  input[type='checkbox'] {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
  }
  input:not([type='checkbox']) {
    width: 100%;
    font-size: 0.875rem;
    height: 2rem;
  }
  input[type='checkbox'],
  input[type='radio'] {
    margin: 0;
    outline: none;
    height: 1.25rem;
  }
  .content-overflow {
    flex-grow: 1;
    overflow: hidden;
  }
  .content-overflow2x {
    flex-grow: 2;
    overflow: hidden;
  }
  input[type='text']:focus-within,
  input[type='number']:focus-within {
  }
  .selectBox > div {
    width: 100%;
    ul > li {
      width: 100% !important;
      max-width: 100% !important;
      > span {
        width: 100%;
      }
    }
  }
  .flex-col.detailInfo {
    padding: 0 1rem;
    margin: 0.5rem 0;
    flex-grow: 1;
    width: 100%;
    > div {
      display: flex;
      gap: 1rem;
    }
    .userSet-label span {
      font-weight: 500;
      flex-shrink: 0;
      font-size: 0.875rem;
    }
    .userSet-data {
      height: 2.5rem;
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 0.25rem;
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
      font-size: 0.875rem;
    }
  }
  .buttonsWrapper {
    display: flex;
    justify-content: flex-end;
    > button {
      margin-left: 0.5rem;
      font-size: 0.875rem;
    }
  }
  .table {
    border-top: 0;
    border-radius: 0 0 5px 5px;
    /* overflow: auto; */
    &.minHeight {
      min-height: 10rem;
    }
  }
  .table.img {
    position: sticky;
    left: 0;
    flex-grow: 1;
  }
  .horizontalHalf {
    max-height: 28vh;
    min-height: 28vh;
  }
  .full {
    min-height: calc(100vh - 31rem);
    max-height: calc(100vh - 31rem);
  }
  .thead {
    position: relative;
    /* color: rgba(0, 0, 0, 0.8); */
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    font-size: 0.875rem;
    word-break: keep-all;
    z-index: 10;
    position: sticky;
    top: 0;
    width: 100%;
    min-width: fit-content;
    border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    background-color: ${({ theme }: { theme: any }) => theme.board};
    /* background-color: white; */
    .tr {
      > div {
        font-weight: 600;
        height: 3rem;
        flex-shrink: 0;
        background-color: ${({ theme }: { theme: any }) => theme.board};
      }
    }

    .tableStickyNo {
      position: sticky;
      left: 0;
      z-index: 2;
      /* display:block; */
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 2.75rem;
      background-color: ${({ theme }: { theme: any }) => theme.board};
    }
    .tableStickyTitle {
      position: sticky;
      left: 2.5rem;
      z-index: 2;
      /* display: block; */
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 2.75rem;
      text-align: center;
    }
  }
  .tbody {
    width: 100%;
    height: 100%;
    min-width: fit-content;
    .tableStickyNo {
      position: sticky;
      left: 0;
      z-index: 2;
      /* display:block; */
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 2.75rem;
      background-color: ${({ theme }: { theme: any }) => theme.board};
    }
    .tableStickyTitle {
      position: sticky;
      left: 2.5rem;
      z-index: 2;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 2.75rem;
    }
    .overflow-visible {
      overflow: visible !important;
      height: 4rem;
      ul {
        justify-content: flex-start !important;
      }
      .optionBox li {
        height: 2.5rem !important;
      }
    }
    .transBg {
      background-color: transparent;
    }
    .tr:nth-child(n + 2) {
      border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    }
    .tr {
      cursor: pointer;
      > div {
        height: 3rem;
        padding: 0 0.25rem;
        flex-shrink: 0;
        color: ${({ theme }: { theme: any }) => theme.text_primary};
        /**
         * 수정자 : 홍선영
         * 날짜 : 2024.01.11
         * 수정내용 : 클릭한 로우 백그라운드 표시 안되어서 아래 주석 처리
         */
        // background-color: ${({ theme }: { theme: any }) => theme.board};
        > button {
          height: 2rem;
        }
      }
      > div.selector {
        > div {
          width: 100%;
          > div > ul > li {
            width: 100%;
            > span {
              width: 100%;
            }
          }
        }
      }
      .phoneNumber {
        input {
          width: 3rem;
          padding: 0;
          text-align: center;
        }
        span {
          margin: 0.125rem;
        }
      }
      .delete {
        button {
          width: 3rem;
          font-size: 0.875rem;
          padding: 0;
        }
      }
      ul {
        justify-content: center;
        height: 2rem;
        > li {
          height: 2rem !important;
          &.w100 {
            width: 100%;
          }
        }
      }
      > .buttonWrapper {
        display: flex;
      }

      &.selectedRow {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};

        > .tableStickyNo,
        > .tableStickyTitle {
          background-color: inherit;
        }
      }
    }
    .tr.selected > div,
    .tr.point.selected > div {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_blue} !important;
    }
    .tr:hover > div,
    .tr.point:hover > div {
      cursor: pointer;
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
    }
    .tr.point {
      > div {
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
      }
    }
    .active,
    .active > div {
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
    }
  }
  tfoot {
    position: relative;
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    font-size: 0.875rem;
    word-break: keep-all;
    z-index: 10;
    position: sticky;
    bottom: 0;
    width: 100%;
    min-width: fit-content;
    border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    background-color: ${({ theme }: { theme: any }) => theme.board};
    .tr > div {
      height: 3rem;
    }
  }
  span.pointValue {
    padding: 0 0.75rem;
    border-radius: 0.25rem;
    background-color: rgba(0, 0, 0, 0.0375);
    color: #4b5675;
    color: ${({ theme }: { theme: any }) => theme.text_disabled};
    font-weight: 600;
  }
  span.pointValue.under10 {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
    color: #50cd89;
  }
  span.pointValue.over10 {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
    color: #009ef7;
  }
  .subNumber {
    font-size: 0.75rem;
    font-weight: 600;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    border-radius: 1rem;
    margin-left: 0.25rem;
    padding: 0 0.75rem;
  }
  .gridCol {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10rem, auto));
    row-gap: 1rem;
    column-gap: 1rem;
  }
  .tr {
    display: flex;
    width: 100%;
    min-width: fit-content;
    align-items: center;
    font-size: 0.875rem;
    word-break: keep-all;
    line-height: 1;
    button {
      width: 3rem;
      padding: 0;
      font-size: 0.875rem;
    }
  }
  .shrink0 {
    flex-shrink: 0;
  }
  .shrink1 {
    flex-shrink: 1 !important;
  }
  .trColGrow {
    flex-grow: 1;
    /* width: 100%; */
  }
  .trCol2p5 {
    width: 2.5rem;
  }
  .trCol3 {
    width: 3rem;
  }
  .trCol4 {
    width: 4rem;
  }
  .trCol4p5 {
    width: 4.5rem;
  }

  .trCol5 {
    width: 5rem;
  }

  .trCol5p5 {
    width: 5.5rem;
  }
  .trCol6 {
    width: 6rem;
  }
  .trCol6p5 {
    width: 6.5rem;
  }
  .trCol7 {
    width: 7rem;
  }
  .trCol7p5 {
    width: 7.5rem;
  }
  .trCol8 {
    width: 8rem;
  }
  .trCol10 {
    width: 10rem;
  }
  .trCol11 {
    width: 11rem;
  }
  .trCol12 {
    width: 12rem;
  }
  .trCol12p5 {
    width: 12.5rem;
  }
  .trCol13 {
    width: 13rem;
  }

  .trCol15 {
    width: 15rem;
  }
  .trCol16 {
    width: 16rem;
  }

  .trCol17p5 {
    width: 17.5rem;
  }

  .trCol20 {
    width: 20rem;
  }

  .trCol22p5 {
    width: 22.5rem;
  }

  .trCol22 {
    width: 22rem;
  }

  .trCol30 {
    width: 30rem;
  }

  .trCol35 {
    width: 35rem;
  }

  .trCol40 {
    width: 40rem;
  }

  .trCol45 {
    width: 45rem;
  }

  .trCol50 {
    width: 50rem;
  }

  .trCol65 {
    width: 65rem;
  }

  .trCol70 {
    width: 70rem;
  }
  .tableImg {
    padding: 0 !important;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .tableTip {
    position: sticky;
    left: 0;
    right: 0;
    user-select: none;
    display: flex;
    font-size: 0.875rem;
    width: calc(100% - 0.5rem);
    height: 3rem;
    align-items: center;
    padding: 1rem;
    background-color: #fffbeb;
    background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
    color: #d97706;
    gap: 0.5rem;
    .material-symbols-rounded {
      font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
      color: #f59e0b;
    }
  }
  .thead {
    .mGas {
      gap: 0.5rem;
      .sensorName {
        width: 9rem;
      }
      .tatName {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        height: fit-content !important;
        .point {
          flex-grow: 1;
          display: flex;
          gap: 0.5rem;
          padding-bottom: 0.25rem;
        }
      }
    }
  }
`;
