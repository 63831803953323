import { useTranslation } from 'react-i18next';
import RadioContainer from '../../../components/button/RadioContainer';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 24rem;
  .titleName {
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    font-weight: 600;
  }
  .toggle-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

interface Props {
  toggleKey: string;
  toggleData: 'Y' | 'N';
  setState: Dispatch<SetStateAction<any>>;
  labelName: string;
}

const ToggleSettings = ({ toggleKey, toggleData, setState, labelName }: Props) => {
  const { t } = useTranslation();

  return (
    <Root>
      <span className='titleName'>{labelName}</span>
      <div className='toggle-wrapper'>
        <RadioContainer
          name={t('사용')} //
          onClick={() => setState((prev: any) => ({ ...prev, [toggleKey]: 'Y' }))}
          isSelected={toggleData === 'Y'}
        />
        <RadioContainer
          name={t('미사용')} //
          onClick={() => setState((prev: any) => ({ ...prev, [toggleKey]: 'N' }))}
          isSelected={toggleData === 'N'}
        />
      </div>
    </Root>
  );
};

export default ToggleSettings;
