import styled from 'styled-components';

const Root = styled.div`
  margin-right: 0.75rem;
  display: flex;
  align-items: center;
  .seperator {
    color: ${({ theme }: { theme: any }) => theme.outline};
    margin: 0 0.375rem;
  }
  .counts {
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_disabled};
  }
  .selected-counts {
    display: flex;
    align-items: center;
    padding: 0 0.25rem;
    border-radius: 100%;
    height: 1.5rem;
    min-width: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &.gray {
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      color: ${({ theme }: { theme: any }) => theme.text_disabled};
    }
    &.green {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
      color: ${({ theme }: { theme: any }) => theme.filled_green};
    }
    &.blue {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
      color: ${({ theme }: { theme: any }) => theme.filled_blue};
    }
  }
`;

interface Props {
  count: number;
  totalCount: number;
}

const CountBoxInModal = ({ count, totalCount }: Props) => {
  const getColor = () => {
    if (count === 0) return 'gray';
    if (count === totalCount) return 'blue';
    return 'green';
  };
  const countStyle = `selected-counts counts ${getColor() ?? 'gray'}`;

  return (
    <Root className='counts-container'>
      <span className={countStyle}>{count}</span>
      <span className='total-counts counts'>
        <span className='seperator'>/</span>
        {totalCount}
      </span>
    </Root>
  );
};

export default CountBoxInModal;
