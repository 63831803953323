import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { useTranslation } from 'react-i18next';
import { today } from '../../../utils/formatDate';
import { useSetSjcd } from '../../../utils/useSetSjcd';
import useOnKeydownF9 from '../../../utils/useOnKeydownF9';
import useSetJobtype from '../../../utils/useSetJobtype';
import { INIT_WORKER_STATUS, LIST_COUNT, SAFEEDU_EXAM_RESULT, WORK_STATUS_SEARCH } from '../../../_constants';
import { useRecoilValue } from 'recoil';
import { IUser, userState } from '../../../atoms';
import { IComCdList } from 'customTypes';
import useSetListCount from '../../../utils/useSetListCount';
import SelectBox from '../../../components/SelectBox';
import SearchSelectBoxSm from '../../../components/SearchSelectBoxSm';
import RangePicker, { onChangeRangeInput } from '../../../components/RangePicker';
import Input from '../../../components/Input';
import ShortcutButton from '../../../components/button/ShortcutButton';
import { BtnGhost } from '../../../components/Button';
import { toast } from 'react-toastify';
import { setComCdListState } from '../../../utils/setComCdListState';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { apiGet } from '../../../services/_common';
import { useSetAuth } from '../../../utils/useSetAuth';
import { logPost } from '../../../services/log';

interface ISafeeduInquirySearchOption {
  setIsFetching: Dispatch<SetStateAction<boolean>>; // 검색데이터 패칭상태
  setIsError: Dispatch<SetStateAction<boolean>>;
  tableState: any[];
  setTableState: Dispatch<SetStateAction<any[]>>;
  setViewTableState: Dispatch<SetStateAction<any[]>>;
  setRowKey: Dispatch<SetStateAction<string>>;
  listCount: any;
  setListCount: Dispatch<SetStateAction<any>>;
  refetchFlag: boolean;
  setRefetchFlag: Dispatch<SetStateAction<boolean>>;
  setExcelBtn: Dispatch<SetStateAction<boolean>>;
}

const SafeeduExamResultSearchOption = ({
  setIsFetching,
  setIsError,
  tableState,
  setTableState,
  setViewTableState,
  setRowKey,
  listCount,
  setListCount,
  refetchFlag,
  setRefetchFlag,
  setExcelBtn,
}: ISafeeduInquirySearchOption) => {
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const toDay = today();
  const userInfo = useRecoilValue<IUser>(userState);
  const { siteJoinInfoList } = useSetSjcd();
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const [wPrejobtype, setWPrejobtype] = useState({ type: 'wPrejobtype', wPrejobtype: '', cdName: '' });
  const { prejobtypeList, jobtypeList } = useSetJobtype(wPrejobtype.wPrejobtype);
  const [sjCd, setSjCd] = useState({ type: 'sjCd', sjCd: '', cdName: '' });
  const [wWorkstatus, setWWorkstatus] = useState(INIT_WORKER_STATUS);
  const [searchOption, setSearchOption] = useState({ wName: '', examResultFrom: '', examResultEnd: '' });
  const [visibleRangePicker, setVisibleRangePicker] = useState(false);
  const [pickerDateRange, setPickerDateRange] = useState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
  const [userInputDateRange, setUserInputDateRange] = useState({ start: toDay, end: toDay });
  const [initiate, setinitiate] = useState('');
  const [jikJongList, setJikJongList] = useState<any[]>([]);
  const [wJobtype, setWJobtype] = useState({ type: 'wJobtype', wJobtype: '', cdName: '' });
  const [listCountComCdList, setListCountComCdList] = useState<IComCdList[]>([]); // 검색줄수 공통코드
  const { patchUserMenuAPI } = useSetListCount(); // 검색줄수설정 훅
  const wNameRef = useRef<HTMLInputElement>(null);
  const INIT_SAFEEDU_EXAM_RESULT = { type: 'eduexamResult', eduexamResult: 'A', cdName: '전체' };
  const [eduexamResult, setEduexamResult] = useState(INIT_SAFEEDU_EXAM_RESULT);

  const { data, isLoading, isFetching, isError, refetch } = useQuery(['eduexamResult', userInfo.hCd, userInfo.sCd], () => fetchData(), {
    cacheTime: 0,
  });

  useEffect(() => {
    if (refetchFlag) refetch();
  }, [refetchFlag]);

  const applyFilters = (array: any[], filter: any) => {
    return array.filter((item: any) => {
      const examDate = item.examDate ? new Date(item.examDate) : null;
      const startDate = filter.examDateStart ? new Date(filter.examDateStart) : new Date('2024-01-01'); // Set a very early date if start date is not provided
      const endDate = filter.examDateEnd ? new Date(filter.examDateEnd) : new Date(); // Use today's date if end date is not provided
      const examResultFrom = filter.examResultFrom ? parseInt(filter.examResultFrom, 10) : 0; // Default to 0 if not provided
      const examResultEnd = filter.examResultEnd ? parseInt(filter.examResultEnd, 10) : 100; // Default to 100 if not provided

      return (
        // examDate가 시작일과 종료일에 포함되는지 확인
        (!examDate || (examDate >= startDate && examDate <= endDate)) &&
        // texamResult가 지정된 결과 범위 내에 있는지 확인
        item.texamResult >= examResultFrom &&
        item.texamResult <= examResultEnd &&
        // 코드값이 일치하는경우 포함 || 필터가 비어 있는 경우 모두 포함
        (!filter.sjCd || item.sjCd === filter.sjCd) &&
        (!filter.prejobtype || item.prejobtype === filter.prejobtype) &&
        (!filter.jobtype || item.jobtype === filter.jobtype) &&
        ((!filter.wWorkstatus && item.wWorkstatus !== 'T' && item.wWorkstatus !== 'D') || item.wWorkstatus === filter.wWorkstatus) &&
        // 이름이 부분일치하거나 || 이름 필터가 비어 있는 경우 모두 포함
        (!filter.wName || item.wName.toLowerCase().includes(filter.wName.toLowerCase())) &&
        // 합/불합격 문자열이 일치하거나 || 이 필터가 비어있는 경우 모두 포함
        (!filter.texamResultName || item.texamResultName === filter.texamResultName)
      );
    });
  };

  const fetchData = async () => {
    try {
      const path = '/eduexam/result';
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
      const res = await apiGet({ path, req });

      const sortedArray = res.data.data.sort((a: any, b: any) => {
        if (a.examDate > b.examDate) return -1;
        if (a.examDate < b.examDate) return 1;
        return b.wCd.localeCompare(a.wCd);
      });

      const filter = {
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        wName: searchOption.wName,
        wWorkstatus: wWorkstatus.wWorkstatus === 'A' ? '' : wWorkstatus.wWorkstatus,
        sjCd: sjCd.sjCd,
        examDateStart: userInputDateRange.start,
        examDateEnd: userInputDateRange.end,
        prejobtype: wPrejobtype.wPrejobtype,
        jobtype: wJobtype.wJobtype,
        examResultFrom: searchOption.examResultFrom,
        examResultEnd: searchOption.examResultEnd,
        texamResultName: eduexamResult.eduexamResult === 'A' ? '' : eduexamResult.cdName,
      };
      const filteredResult = applyFilters(sortedArray, filter);
      setTableState(filteredResult);

      const addHTML = filteredResult.map((el: any) => ({
        ...el,
        result1: `<span class='${el.result1 !== 'X' ? 'blue' : 'red'}'>${el.result1}</span>`,
        result2: `<span class='${el.result2 !== 'X' ? 'blue' : 'red'}'>${el.result2}</span>`,
        result3: `<span class='${el.result3 !== 'X' ? 'blue' : 'red'}'>${el.result3}</span>`,
        result4: `<span class='${el.result4 !== 'X' ? 'blue' : 'red'}'>${el.result4}</span>`,
        result5: `<span class='${el.result5 !== 'X' ? 'blue' : 'red'}'>${el.result5}</span>`,
        result6: `<span class='${el.result6 !== 'X' ? 'blue' : 'red'}'>${el.result6}</span>`,
        result7: `<span class='${el.result7 !== 'X' ? 'blue' : 'red'}'>${el.result7}</span>`,
        result8: `<span class='${el.result8 !== 'X' ? 'blue' : 'red'}'>${el.result8}</span>`,
        result9: `<span class='${el.result9 !== 'X' ? 'blue' : 'red'}'>${el.result9}</span>`,
        result10: `<span class='${el.result10 !== 'X' ? 'blue' : 'red'}'>${el.result10}</span>`,
        texamResultName: el.texamResultName === '합격' ? `<span class='badge blue'>${el.texamResultName}</span>` : `<span class='badge red'>${el.texamResultName}</span>`,
      }));
      setViewTableState(addHTML);
      return filteredResult;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    } finally {
      setRefetchFlag(false);
    }
  };

  useEffect(() => {
    setComCdListState(LIST_COUNT, setListCountComCdList, false);
  }, []);

  useEffect(() => {
    setIsFetching(isFetching);
  }, [isLoading, isFetching]);

  useEffect(() => {
    setIsError(isError);
  }, [isError]);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    setWJobtype({ type: 'wJobtype', wJobtype: '', cdName: '' });
    if (wPrejobtype.wPrejobtype === '') setJikJongList([]);
  }, [wPrejobtype.wPrejobtype]);

  useEffect(() => {
    setJikJongList(jobtypeList);
  }, [jobtypeList]);

  useEffect(() => {
    if (eduexamResult.eduexamResult !== 'A') {
      if (eduexamResult.eduexamResult === 'pass') setSearchOption((prev) => ({ ...prev, examResultFrom: '60', examResultEnd: '100' }));
      else setSearchOption((prev) => ({ ...prev, examResultFrom: '0', examResultEnd: '50' }));
    } else setSearchOption((prev) => ({ ...prev, examResultFrom: '', examResultEnd: '' }));
  }, [eduexamResult]);

  useEffect(() => {
    if (pickerDateRange[0].startDate) {
      setUserInputDateRange((prev: any) => ({ ...prev, start: dayjs(pickerDateRange[0].startDate).format('YYYY-MM-DD') }));
    }
    if (pickerDateRange[0].endDate) {
      setUserInputDateRange((prev: any) => ({ ...prev, end: dayjs(pickerDateRange[0].endDate).format('YYYY-MM-DD') }));
    }
  }, [pickerDateRange[0].startDate, pickerDateRange[0].endDate]);

  // 검색 버튼 클릭
  const onClickSearch = () => {
    setRowKey('');
    if (Number(searchOption.examResultFrom?.trim()) > Number(searchOption.examResultEnd?.trim())) {
      toast.warning(t('최저 ~ 최고 점수 범위를 확인하세요'));
    } else if (userInputDateRange.end && userInputDateRange.start > userInputDateRange.end) {
      toast.warning(t('날짜를 다시 입력하세요'));
      setSearchOption((prev) => ({ ...prev, end: '' }));
    } else {
      setVisibleRangePicker(false);
      refetch();
      logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '안전 교육 > 안전보건 교육 시험 보고서',
        action: '조회',
        etc: ``,
      });
    }
  };

  const onClickRangeInput = () => {
    if (!visibleRangePicker) setVisibleRangePicker(true);
  };

  const initiateSearchOptions = () => {
    setWWorkstatus(INIT_WORKER_STATUS);
    setSjCd({ type: 'sjCd', sjCd: '', cdName: '' });
    setWPrejobtype({ type: 'wPrejobtype', wPrejobtype: '', cdName: '' });
    setWJobtype({ type: 'wJobtype', wJobtype: '', cdName: '' });
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    setSearchOption({ wName: '', examResultFrom: '', examResultEnd: '' });
    setinitiate(`${Math.random()}`);
    initiateSearchOptions();
    setPickerDateRange([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
    setUserInputDateRange({ start: '', end: '' });
    setEduexamResult(INIT_SAFEEDU_EXAM_RESULT);
  };

  // 근로자명 입력창에서 엔터입력시 검색
  const loginOnEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (wNameRef.current) onClickSearch();
    }
  };

  // 엑셀 저장 버튼 클릭
  const onClickExcelSave = () => {
    if (tableState.length <= 0) return;
    setExcelBtn(true);
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '안전 교육',
      action: '엑셀 저장',
      etc: ``,
    });
  };

  const renderExcelBtn = () => {
    if (auth.excelAuth) {
      return (
        <BtnGhost onClick={onClickExcelSave}>
          <span className='icon-ms-xlsx'>X</span> {t('엑셀')}
        </BtnGhost>
      );
    }
    return null;
  };

  return (
    <SearchOptions>
      <div className='inputsWrapper'>
        <div className='inputForm-col withLabelComCf'>
          <label htmlFor='wWorkstatus'>{t('근로 상태')}</label>
          <SelectBox
            options={WORK_STATUS_SEARCH}
            defaultOption={t('재직')}
            state={wWorkstatus}
            setState={setWWorkstatus}
            stateKey='wWorkstatus'
            initiateKey={wWorkstatus.wWorkstatus}
            filterbar='filter-1-left'
          />
        </div>
        <div className='inputForm-row withLabel'>
          <label htmlFor='sjCd'>{t('협력업체')}</label>
          <div className='inputForm-col'>
            <SearchSelectBoxSm
              options={siteJoinInfoList}
              defaultOption={t('전체')}
              state={sjCd}
              setState={setSjCd}
              stateKey='sjCd'
              codeKey='cdName'
              initiateKey={sjCd.sjCd}
              filterbar='filter-1-left'
              optionHeight='height-md'
            />
          </div>
        </div>
        <div className='inputForm-row'>
          <div className='inputForm-col withLabel'>
            <Input label={t('총점수')} type='number' id='examResultFrom' name='examResultFrom' state={searchOption} setState={setSearchOption} maxLength={2} />
          </div>
        </div>
        <span className='flex-center'>~</span>
        <div className='inputForm-row'>
          <div className='inputForm-col withLabel'>
            <Input type='number' id='examResultEnd' name='examResultEnd' state={searchOption} setState={setSearchOption} maxLength={3} />
          </div>
        </div>
        <div className='inputForm-row withLabel'>
          <label htmlFor='sjCd'>{t('시험 결과')}</label>
          <div className='inputForm-col'>
            <SelectBox
              options={SAFEEDU_EXAM_RESULT}
              defaultOption={t('전체')}
              state={eduexamResult}
              setState={setEduexamResult}
              stateKey='eduexamResult'
              initiateKey={eduexamResult.eduexamResult}
              filterbar='filter-1-center'
              optionHeight='height-md'
            />
          </div>
        </div>
        <div className='inputForm-row-fit'>
          <span className='calendarLabel'>{t('시험일자')}</span>
          <div className='inputForm-col'>
            <div className='flex-basic'>
              <input
                id='startInput'
                type='text'
                value={userInputDateRange.start}
                onMouseDown={onClickRangeInput}
                onChange={(e) => onChangeRangeInput(e, 'start', setPickerDateRange, setUserInputDateRange)}
              />
              <span className='inputDash'> ~ </span>
              <input
                id='endInput'
                type='text'
                value={userInputDateRange.end}
                onMouseDown={onClickRangeInput}
                onChange={(e) => onChangeRangeInput(e, 'end', setPickerDateRange, setUserInputDateRange)}
              />
            </div>
            {visibleRangePicker && (
              <div className='rangePickerWrapper'>
                <RangePicker state={pickerDateRange} setState={setPickerDateRange} setVisible={setVisibleRangePicker} />
              </div>
            )}
          </div>
        </div>
        {userInfo.prejobtypeYn === 'Y' && (
          <div className='inputForm-row'>
            <div className='inputForm-col'>
              <SearchSelectBoxSm
                options={prejobtypeList}
                defaultOption={t('공종 전체')}
                state={wPrejobtype}
                setState={setWPrejobtype}
                stateKey='wPrejobtype'
                codeKey='cdName'
                initiateKey={initiate}
                filterbar='filter-1-right'
                comboWidth='expand-box-sm'
                optionHeight='height-md'
              />
            </div>
          </div>
        )}
        <div className='inputForm-row'>
          <div className='inputForm-col'>
            <SearchSelectBoxSm
              options={jikJongList}
              defaultOption={t('직종 전체')}
              state={wJobtype}
              setState={setWJobtype}
              stateKey='wJobtype'
              codeKey='cdName'
              initiateKey={wJobtype.wJobtype}
              filterbar='filter-1-right'
              comboWidth='expand-box-sm'
              optionHeight='height-md'
            />
          </div>
        </div>
        <div className='inputForm-row'>
          <div className='inputForm-col'>
            <Input placeholder={t('근로자명')} label='' type='text' id='wName' name='wName' state={searchOption} setState={setSearchOption} inputRef={wNameRef} onKeyDown={loginOnEnterKeyDown} />
          </div>
        </div>
      </div>
      <div className='inputsWrapper'>
        <div className='secondSearchOption'>
          <div className='flex-basic textBtnGroup'>
            <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
            <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
            <div className='searchResult'>
              {t('총')}
              <span>{tableState.length}</span>
              {t('개')}
            </div>
            <div className='inputForm-row'>
              <div className='inputForm-col withLabelComCf'>
                <label htmlFor='useYn'>{t('보기 설정')}</label>
                <SelectBox
                  options={listCountComCdList}
                  defaultOption={listCount.cdName}
                  state={listCount}
                  setState={setListCount}
                  stateKey={LIST_COUNT}
                  initiateKey={listCount[LIST_COUNT]}
                  setTableLinesAPI={patchUserMenuAPI}
                  optionHeight='height-sm'
                  rsearch
                />
              </div>
            </div>
          </div>
          <div className='flex-basic iconBtnGroup'>{renderExcelBtn()}</div>
        </div>
      </div>
    </SearchOptions>
  );
};

export default SafeeduExamResultSearchOption;
