/**
 * 작성자 : 홍선영
 * 날짜 : 2023.04.20
 * 기능 : 마스터관리-관리자정보 페이지
 */

import { useState, useEffect, useRef } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { IModal, INumbers } from 'customTypes';
import { pageInfoState, userState } from '../../atoms';
import { trimObject } from '../../utils/trimObject';
import { useDetectScrolledToBottom } from '../../utils/useDetectScrolledToBottom';
import { checkUserInputRequiredValue } from '../../utils/checkUserInputRequiredValue';
import { emailRegex, passwordRegex } from '../../utils/checkRegex';
import ViewTable from '../../components/Table/ViewTable';
import UserInfoInputFormMaster from '../../components/Form/UserInfoInputFormMaster';
import Tab from '../../components/Tab';
import Input from '../../components/Input';
import { BtnGhost } from '../../components/Button';
import { SearchOptions } from '../../assets/styles/SearchOptions';
import { COMCD_LOCK_YN, COMCD_USE_YN, INIT_LOCK_YN_N, INIT_USE_YN_A, INIT_USE_YN_Y } from '../../_constants';
import { logPost } from '../../services/log';
import SelectBox from '../../components/SelectBox';
import { checkPhonNumEmpty } from '../../utils/checkPhonNumEmpty';
import { useTranslation } from 'react-i18next';
import BackButton from '../../components/BackButton';
import Portal from '../../components/Portal';
import DeleteModal from '../../components/Modal/DeleteModal2';
import { apiDelete, apiGet, apiPatch, apiPost } from '../../services/_common';
import { ynFilter } from '../../utils/ynFilter';
import { useFetchCommonCodeList } from '../../services/useSetCodeListInSelectBoxForm';

const Root = styled.div`
  &.hideRoot {
    visibility: hidden;
    position: absolute;
  }
  .formTitle {
    padding: 0 0.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .inputFormsWrapper {
    border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    padding: 0.5rem;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    .table {
      height: fit-content;
    }
  }
  .searchSaveBar {
    display: flex;
    justify-content: flex-start;
  }

  .searchOption {
    align-items: flex-end;
  }

  .buttonsWrapper {
    align-items: flex-end;
    margin: 0px 0.5rem;

    > button {
      margin-left: 0.5rem;
    }
  }

  .searchContent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 2rem;
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 1023px) {
    height: fit-content !important;
  }

  @media screen and (max-width: 767px) {
    .searchSaveBar {
      flex-direction: column;
      height: fit-content;
      > div {
        padding-bottom: 1rem;
      }
    }
    .searchOption {
      width: 100%;
    }
    .buttonsWrapper {
      justify-content: end;
    }
  }

  @media screen and (max-width: 425px) {
  }
`;

const Master1 = () => {
  const { t } = useTranslation();
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  const { pathname } = useLocation();
  const userInfo = useRecoilValue(userState);
  const node = useRef<any>(null);
  const userInfoInputFormRef = useRef<any>(null); // 인풋 폼 ref (테이블 로우를 클릭했을 때 바로 inputForm 으로 스크롤 이동시키기 위한 ref)
  const [searchOptionAdminName, setSearchOptionAdminName] = useState({ adminName: '' });
  const [searchOptionUseYn, setSearchOptionUseYn] = useState(INIT_USE_YN_A);
  const [isNewAdd, setIsNewAdd] = useState(true); // 신규등록 여부
  const [isSettingPassword, setIsSettingPassword] = useState(false); // 패스워드 변경여부
  const [rowState, setRowState] = useState({
    userId: '',
    isDuplicateChecked: false,
    userName: '',
    hpNum: '',
    telNum: '',
    eMail: '',
    ipInfo: '',
    displayPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
    bigo: '',
    delYn: '',
    eDate: '',
    editor: '',
    lockYn: 'N',
    useYn: 'Y',
    userPwd: '',
    wDate: '',
    writer: '',
    loginerrorCount: '',
    gCd: 'MM', // 고정값
    hCd: 'M000', // 고정값
    sCd: '00000', // 고정값
  }); // 서버에서 받아 온 raw data 어레이 중 1줄의 데이터 state
  const [viewData, setViewData] = useState([{}]); // 서버에서 받아 온 raw data 중 viewTable에 뿌려줄 데이터만 담는 state
  const [columnCount, setColumnCount] = useState(0);
  const { isBottom } = useDetectScrolledToBottom(node); // 스크롤이 해당노드의 하단에 근접했는지 여부 (플로팅버튼 띄우기 위함)
  const [masterUserList, setMasterUserList] = useState<any>([]);
  const [orgMasterUserList, setOrgMasterUserList] = useState<any>([]); // 필터링되지않은 유저리스트
  const [lockYn, setLockYn] = useState(INIT_LOCK_YN_N); // 잠금유무 공통코드
  const [useYn, setUseYn] = useState(INIT_USE_YN_Y); // 사용유무 공통코드
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [telNumState, setTelNumState] = useState<INumbers>({ num1: '', num2: '', num3: '' });
  const [hpNumState, setHpNumState] = useState<INumbers>({ num1: '010', num2: '', num3: '' });
  const [ipInfoState, setIpInfoState] = useState<INumbers>({ num1: '', num2: '', num3: '', num4: '' });
  const [viewMainPage, setViewMainPage] = useState(true);
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const { data: useYnComCdListWithAll } = useFetchCommonCodeList(COMCD_USE_YN, true); // 사용여부 공통코드 목록 (검색조건에서 사용, 전체포함)
  const { data: lockYnComCdList } = useFetchCommonCodeList(COMCD_LOCK_YN, false); // 잠금유무 공통코드 목록 (사용자 정보에서 사용)
  const { data: useYnComCdList } = useFetchCommonCodeList(COMCD_USE_YN, false); // 사용유무 공통코드 목록 (사용자 정보에서 사용)

  useEffect(() => {
    getManagerListAPI().then((res: any) => {
      if (res.status === 200) {
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '관리자 정보',
          action: '조회',
          etc: ``,
        });
      }
    });
    setSearchOptionUseYn(INIT_USE_YN_A);
  }, []);

  // 로우데이터에서 뷰테이블에 보여줄 데이터만 뽑아서 배열에 담아 리턴
  const setDataIntoViewTable = (properties: any) => {
    setColumnCount(properties.length);
    const viewDataArray: any = [];
    const allKeys = Object.keys(masterUserList[0]);

    masterUserList?.map((el: any, i: number) => {
      const result = allKeys.reduce((next, key: any) => {
        if (properties.includes(key)) {
          return { ...next, [key]: masterUserList[i][key] };
        }
        return next;
      }, {});
      return viewDataArray.push(result);
    });
    setViewData(viewDataArray);
  };

  useEffect(() => {
    // 도메인에 따라서 뷰테이블에 전달해야하는 키값만 viewTableProperties 배열안에 작성하면 됨.
    if (masterUserList && masterUserList.length > 0) {
      if (pathname === '/master1') {
        const viewTableProperties = ['hCd', 'userId', 'userName', 'lockYn', 'useYn'];
        setDataIntoViewTable(viewTableProperties);
      }
    }
  }, [masterUserList, pathname]);

  // 마스터관리자 신규등록 API
  const insertNewUserManagerAPI = async (resultData: any) => {
    const trimData = trimObject(resultData);
    setRowState((prev) => ({ ...prev, displayPassword: trimData.newPassword }));
    const req = {
      userId: trimData.userId,
      userName: trimData.userName,
      userPwd: trimData.newPassword,
      gCd: trimData.gCd,
      hCd: trimData.hCd,
      sCd: trimData.sCd,
      hpNum: trimData.hpNum,
      telNum: trimData.telNum,
      eMail: trimData.eMail,
      ipInfo: trimData.ipInfo,
      lockYn: trimData.lockYn,
      useYn: trimData.useYn,
      bigo: trimData.bigo,
      approvalYn: 'Y',
      pwdCheckyn: 'N',
      loginerrorCount: 0,
      writer: userInfo.userId,
    };
    const res = await apiPost({ path: '/user/manager', contentType: 'application/json', req });
    const { statusCode, message, data } = res.data;
    const { bigo, delYn, eDate, eMail, editor, gCd, hCd, hpNum, ipInfo, sCd, telNum, userId, userName, userPwd, newPassword, newPasswordConfirm } = trimData;
    if (statusCode === 200) {
      toast.success(t(message));
      setMasterUserList((prev: any) => [
        ...prev,
        {
          bigo,
          delYn,
          eDate: data.eDate,
          eMail,
          editor: data.writer,
          gCd,
          hCd,
          hpNum,
          ipInfo,
          lockYn: data.lockYn,
          sCd,
          telNum,
          useYn: data.useYn,
          userId,
          userName,
          userPwd,
          wDate: data.wDate,
          writer: data.writer,
          isDuplicateChecked: true,
          newPassword,
          newPasswordConfirm,
          displayPassword: trimData.newPassword,
        },
      ]);
      setOrgMasterUserList((prev: any) => [
        ...prev,
        {
          bigo,
          delYn,
          eDate: data.eDate,
          eMail,
          editor: data.writer,
          gCd,
          hCd,
          hpNum,
          ipInfo,
          lockYn: data.lockYn,
          sCd,
          telNum,
          useYn: data.useYn,
          userId,
          userName,
          userPwd,
          wDate: data.wDate,
          writer: data.writer,
          isDuplicateChecked: true,
          newPassword,
          newPasswordConfirm,
          displayPassword: trimData.newPassword,
        },
      ]);
      await logPost({ hCd: userInfo.hCd, sCd: userInfo.sCd, userId: userInfo.userId, menu: '마스터관리-관리자정보', action: '마스터 관리자 신규등록', etc: '' });
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 마스터관리자 수정 API
  const updateUserManagerAPI = async (resultData: any) => {
    const trimData = trimObject(resultData);
    const req = {
      userId: trimData.userId,
      userName: trimData.userName,
      userPwd: trimData.userPwd,
      gCd: trimData.gCd,
      hCd: trimData.hCd,
      sCd: trimData.sCd,
      hpNum: trimData.hpNum,
      telNum: trimData.telNum,
      eMail: trimData.eMail,
      ipInfo: trimData.ipInfo,
      bigo: trimData.bigo,
      lockYn: trimData.lockYn,
      useYn: trimData.useYn,
      editor: userInfo.userId,
    };
    const res = await apiPatch({ path: '/user/manager', contentType: 'application/json', req });

    const { statusCode, message, data } = res.data;
    if (statusCode === 200) {
      const { bigo, delYn, eMail, gCd, hCd, hpNum, ipInfo, sCd, telNum, userId, userName, userPwd, wDate, writer } = rowState;
      const findIndex = masterUserList.findIndex((el: any) => el.userId === trimData.userId);
      const copyArray: any = [...masterUserList];
      if (findIndex !== -1) {
        copyArray[findIndex] = {
          ...copyArray[findIndex],
          bigo,
          delYn,
          eDate: data.eDate,
          eMail,
          editor: data.editor,
          gCd,
          hCd,
          hpNum,
          ipInfo,
          lockYn: data.lockYn,
          sCd,
          telNum,
          useYn: data.useYn,
          userId,
          userName,
          userPwd,
          wDate,
          writer,
        };

        const newReqData = { ...rowState, ...req, editor: data.editor, eDate: data.eDate };
        setRowState(newReqData);
      }
      setMasterUserList(copyArray);
      toast.success(t(message));

      await logPost({ hCd: userInfo.hCd, sCd: userInfo.sCd, userId: userInfo.userId, menu: '마스터관리-관리자정보', action: '마스터 관리자 수정', etc: `${trimData.userId} 수정` });
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 마스터관리자 조회 API
  const getManagerListAPI = async () => {
    const res = await apiGet({ path: '/user/manager' });
    const { status, data } = res;
    if (status === 200) {
      // 삭제되지 않은 유저만 필터링해서 setState
      const activeUsers = data.data.userList.filter((el: any) => el.delYn === 'N');
      setMasterUserList(activeUsers);
      setOrgMasterUserList(activeUsers);
    }
    return res;
  };

  const onClickDelete = async () => {
    setOpenModal((prev) => ({ ...prev, status: true, type: 'delete', api: deleteAPI }));
  };

  // 마스터관리자 삭제 API
  const deleteAPI = async () => {
    const { userId, editor } = rowState;
    const req = { userId, editor };
    const res = await apiDelete({ path: '/user/manager', req });
    const { message, statusCode } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      // 삭제된로우 리스트에서 제거
      const newArray = masterUserList.filter((el: any) => el.userId !== rowState.userId);
      setMasterUserList(newArray);
      await logPost({ hCd: userInfo.hCd, sCd: userInfo.sCd, userId: userInfo.userId, menu: '마스터관리-관리자정보', action: '마스터 관리자 삭제', etc: `${userId} 삭제` });
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 저장버튼 클릭해서 save API 호출하여 데이터전송
  const onClickSave = async () => {
    const mergedHpNumber = `${hpNumState.num1}-${hpNumState.num2}-${hpNumState.num3}`;
    const mergedTelNumber = telNumState.num1 === '' && telNumState.num2 === '' && telNumState.num3 === '' ? '' : `${telNumState.num1}-${telNumState.num2}-${telNumState.num3}`;
    const mergedIpNumber =
      ipInfoState.num1 === '' && ipInfoState.num2 === '' && ipInfoState.num3 === '' && ipInfoState.num4 === '' ? '' : `${ipInfoState.num1}.${ipInfoState.num2}.${ipInfoState.num3}.${ipInfoState.num4}`;

    const { newPassword, newPasswordConfirm, hpNum, eMail } = rowState;
    const { isUserInputRequiredValuePass, userInputRequiredValueMsg } = checkUserInputRequiredValue(rowState);
    const { isPasswordRegexPass, passwordRegexAlertMsg } = passwordRegex(newPassword, newPasswordConfirm);
    const { isEmpty, emptyAlertMessage } = checkPhonNumEmpty(hpNumState);
    const { isEmailRegexPass, emailRegexAlertMsg } = emailRegex(eMail);

    setIsSaveClicked(true);
    if (!isUserInputRequiredValuePass) return toast.warning(t(userInputRequiredValueMsg));
    if (isSettingPassword) return toast.warning(t('비밀번호 변경버튼을 클릭하여 변경을 완료하세요'));
    if (isNewAdd || isSettingPassword) {
      if (!isPasswordRegexPass) return toast.warning(t(passwordRegexAlertMsg));
    }
    if (isEmpty) return toast.warning(t(emptyAlertMessage));
    if (!isEmailRegexPass) return toast.warning(t(emailRegexAlertMsg));

    const resultData = { ...rowState, hpNum: mergedHpNumber, telNum: mergedTelNumber, ipInfo: mergedIpNumber, useYn: useYn[COMCD_USE_YN], lockYn: lockYn[COMCD_LOCK_YN] };

    if (isNewAdd) return insertNewUserManagerAPI(resultData);
    return updateUserManagerAPI(resultData);
  };

  // 신규등록 버튼 클릭해서 새 입력창(iputForm) 노출
  const onClickNewRegistration = () => {
    initiateState();
    setIsNewAdd(true);
    if (size.innerSize.W < 1024) {
      setViewMainPage(false);
    }
  };

  const backToMain = () => {
    setViewMainPage(true);
  };

  // 스테이트 객체의 밸류를 ''로 초기화(고정값인 hCd, gCd, sCd는 제외)
  const initiateState = () => {
    const keys = Object.keys(rowState);
    const filteredKeys = keys.filter((keyEl: string) => keyEl !== 'hCd' && keyEl !== 'gCd' && keyEl !== 'sCd');
    filteredKeys.map((el) => {
      return setRowState((prev: any) => ({ ...prev, [el]: '', isDuplicateChecked: false, useYn: 'Y', lockYn: 'N' }));
    });
    setIsSaveClicked(false);
    setHpNumState({ num1: '010', num2: '', num3: '' });
    setTelNumState({ num1: '', num2: '', num3: '' });
    setIpInfoState({ num1: '', num2: '', num3: '', num4: '' });
    setUseYn(INIT_USE_YN_Y);
    setLockYn(INIT_LOCK_YN_N);
  };

  const onClickInitiateSearchOption = () => {
    setSearchOptionUseYn(INIT_USE_YN_A);
    setUseYn(INIT_USE_YN_Y);
    setLockYn(INIT_LOCK_YN_N);
    setSearchOptionAdminName({ adminName: '' });
    if (size.innerSize.W >= 1024) {
      onClickNewRegistration(); // 우측 인풋창도 초기화
    }
  };

  useEffect(() => {
    // 검색옵션 변경됐을 때 필터링 처리
    // 필터링 기준
    const filterOptions = {
      userName: searchOptionAdminName.adminName,
    };

    // 필터링된 어레이 리턴, 대소문자구분X
    const filteredArray = orgMasterUserList.filter((item: any) => {
      return item.userName?.toLowerCase()?.includes(filterOptions.userName?.toLowerCase());
    });
    const useYnFilter = ynFilter(filteredArray, 'useYn', searchOptionUseYn[COMCD_USE_YN]);
    if (useYnFilter.length > 0) setMasterUserList(useYnFilter);
    else setMasterUserList([]);
  }, [searchOptionAdminName, searchOptionUseYn[COMCD_USE_YN], orgMasterUserList]);

  const onClickTab = (mrCd: string) => {
    setCurrentTabMrCd(mrCd);
  };

  return (
    <div className='contents'>
      <Tab tabList={tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      <div className={`content-container ${size.innerSize.W >= 1024 ? 'twoColumn column-55 max800' : 'oneColumn'}`}>
        <Root className={size.innerSize.W >= 1024 || viewMainPage ? 'showRoot' : 'hideRoot'}>
          <SearchOptions>
            <div className='inputsWrapper'>
              <div className='inputForm-row'>
                <div className='inputForm-col withLabelComCf'>
                  <label htmlFor='useYn'>{t('사용유무')}</label>
                  <SelectBox
                    options={useYnComCdListWithAll}
                    defaultOption={searchOptionUseYn.cdName}
                    state={searchOptionUseYn}
                    setState={setSearchOptionUseYn}
                    stateKey={COMCD_USE_YN}
                    initiateKey={searchOptionUseYn[COMCD_USE_YN]}
                    filterbar='filter-1-left'
                  />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <Input placeholder={t('관리자명')} label='' type='text' id='adminName' name='adminName' state={searchOptionAdminName} setState={setSearchOptionAdminName} />
                </div>
              </div>
            </div>
            <div className='inputsWrapper'>
              <div className='secondSearchOption'>
                <div className='flex-basic textBtnGroup'>
                  <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
                </div>
                <div className='flex-basic iconBtnGroup'>
                  <BtnGhost onClick={onClickNewRegistration}>
                    <span className='material-symbols-rounded'>add</span>
                    {t('등록')}
                  </BtnGhost>
                </div>
              </div>
            </div>
          </SearchOptions>
          <ViewTable
            rawData={masterUserList}
            state={viewData}
            setRowState={setRowState}
            setIsNewAdd={setIsNewAdd}
            setIsSettingPassword={setIsSettingPassword}
            userInfoInputFormRef={userInfoInputFormRef}
            setLockYn={setLockYn}
            setUseYn={setUseYn}
            setTelNumState={setTelNumState}
            setHpNumState={setHpNumState}
            setIpInfoState={setIpInfoState}
            setViewMainPage={() => setViewMainPage(false)}
          />
        </Root>
        {(size.innerSize.W >= 1024 || !viewMainPage) && (
          <Root>
            <div className='inputFormsWrapper flexRowEm'>
              {size.innerSize.W < 1024 && <BackButton func={() => backToMain()} />}
              <div className='formTitle'>{isNewAdd ? t('새로운 사용자') : t('사용자 정보')}</div>
            </div>
            <UserInfoInputFormMaster
              style={{ width: 'calc(50% - 2rem)', flexDirection: 'column' }}
              state={rowState}
              setState={setRowState}
              isNewAdd={isNewAdd}
              userInfoInputFormRef={userInfoInputFormRef}
              isSettingPassword={isSettingPassword}
              setIsSettingPassword={setIsSettingPassword}
              onClickNewAdd={onClickNewRegistration}
              onClickSave={onClickSave}
              onClickDelete={onClickDelete}
              telNumState={telNumState}
              setTelNumState={setTelNumState}
              hpNumState={hpNumState}
              setHpNumState={setHpNumState}
              ipInfoState={ipInfoState}
              setIpInfoState={setIpInfoState}
              lockYn={lockYn}
              setLockYn={setLockYn}
              useYn={useYn}
              setUseYn={setUseYn}
              lockYnComCdList={lockYnComCdList}
              useYnComCdList={useYnComCdList}
              isSaveClicked={isSaveClicked}
            />
          </Root>
        )}
      </div>
      <Portal openModal={openModal?.status}>{openModal && openModal.type === 'delete' && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
    </div>
  );
};

export default Master1;
