export const nvrListOverwrite = (nvrs: any[], settings: any[]) => {
  const res = nvrs.map((nvr: any) => {
    const settingsSubList = settings.find((cam: any) => nvr.nCd === cam.nCd)?.subList;
    return {
      ...nvr,
      subList: nvr.subList.map((sub: any) => {
        const settingCam = settingsSubList?.find((settingsSub: any) => sub.cCd === settingsSub.cCd);
        return {
          ...sub,
          windowIndex: settingCam?.windowIndex,
          isPlaying: settingCam?.isPlaying,
        };
      }),
    };
  });
  return res;
};
