import { useEffect, useState } from 'react';

/**
 * 작성자 : 김광민
 * 날짜 : 2023.09.27
 * 기능 : 다크모드 적용 함수
 */
function getInitialColorMode() {
  const persistedColorPreference = window.localStorage.getItem('color-mode');
  if (persistedColorPreference) {
    return persistedColorPreference;
  }
  const systemPreference = window.matchMedia('(prefers-color-scheme: dark)');
  if (systemPreference.matches) {
    return 'dark';
  }
  return 'light';
}
export default getInitialColorMode;

/**
 * 작성자 : 김광민
 * 날짜 : 2024.03.12
 * 기능 : 테마에 따라 로고 이미지를 설정하는 커스텀 훅
 */
export function useThemeLogo(headInfoQuery: any, theme: string) {
  const [logo, setLogo] = useState<string>('');

  useEffect(() => {
    // headInfoQuery가 성공하지 않았다면 함수를 종료합니다.
    if (!headInfoQuery.isSuccess) return;

    const headInfo = headInfoQuery.data?.data?.data?.headInfo;
    if (!headInfo) return; // headInfo가 없으면 함수를 종료합니다.

    const lightThemeLogo = headInfo.hLogoimage;
    const darkThemeLogo = headInfo.hDLogoimage;
    if (theme === 'light' && lightThemeLogo) setLogo(lightThemeLogo);
    else if (theme === 'light' && !lightThemeLogo && darkThemeLogo) setLogo(darkThemeLogo);
    else if (theme === 'dark' && darkThemeLogo) setLogo(darkThemeLogo);
    else if (theme === 'dark' && !darkThemeLogo && lightThemeLogo) setLogo(lightThemeLogo);
    else setLogo('');
  }, [headInfoQuery, theme]);

  return logo;
}
