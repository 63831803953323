import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GRANT } from '../_constants';
/**
 * 작성자 : 한영광
 * 날짜 : 2023.06.05
 * 기능 : 로그인 한 사용자의 메뉴 권한 체크 함수
 */
interface Iprops {
  mtCd: string;
  mdCd: string;
  mrCd: string;
}
const CheckUserMenuGrant = ({ mtCd, mdCd, mrCd }: Iprops, userMenuList: any, pathname: string, t: any) => {
  // const { cYn, uYn, dYn, eYn, pYn, rSearch } = userMenuList.filter((v: any) => v.mtCd === mtCd && v.mdCd === mdCd && v.mrCd === mrCd)[0];
  const grant = userMenuList.find((v: any) => v.mtCd === mtCd && v.mdCd === mdCd && v.mrCd === mrCd);
  if (!grant) {
    if (pathname !== '/') {
      // toast.success('권한이 없습니다.');
      // alert(t(GRANT));
      window.location.replace('/');
    }
    return '';
  }

  const { cYn, uYn, dYn, eYn, pYn, rSearch } = grant;
  return {
    cYn,
    uYn,
    dYn,
    eYn,
    pYn,
    rSearch,
  };
};

export default CheckUserMenuGrant;
