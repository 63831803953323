import { useRecoilValue } from 'recoil';
import { IUser, userState } from '../../../atoms';
import { apiGet } from '../../../services/_common';
import { useQuery } from '@tanstack/react-query';
import { COMCD_STEMPLET_GUBUN } from '../../../_constants';
import { IComCdList } from 'customTypes';

// 공통코드(공통&직종별 양식지) 조회
export const useTemplateGubunList = (etcKey: string) => {
  const userInfo = useRecoilValue<IUser>(userState);
  const queryKey = [`codeDetail_${etcKey}`];

  const fetchData = async () => {
    try {
      const req = { grCd: COMCD_STEMPLET_GUBUN };
      const res = await apiGet({ path: '/code/detail', req });
      const { comCdList } = res.data.data;
      return comCdList.filter((code: IComCdList) => code.etc1 === etcKey && code.useYn === 'Y').map((item: IComCdList) => ({ key: item.subCd, label: item.cdName }));
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  const { data, isError, isLoading, isFetching, refetch } = useQuery(queryKey, () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
  });

  return { data, isError, isLoading, isFetching, refetch };
};
