/**
 * 작성자 : 홍선영
 * 날짜 : 2023.06.05
 * 경로 : 설정관리-환경설정-건강검진결과탭 (현장관리자)
 */

import { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { IModal } from 'customTypes';
import { userState } from '../../../atoms';
import { FLAG_CREATE_OR_UPDATE } from '../../../_constants';
import { InputTable } from '../../../assets/styles/InputTable';
import { trimArray } from '../../../utils/trimArray';
import Portal from '../../../components/Portal';
import DeleteModal from '../../../components/Modal/DeleteModal2';
import { BtnBlue, BtnRed } from '../../../components/Button';
import { logPost } from '../../../services/log';
import { applyBorderStyle } from '../../../utils/applyBorderStyle';
import { useSetAuth } from '../../../utils/useSetAuth';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { scrollToNodeBottom } from '../../../utils/scrollToNodeBottom';
import { useTranslation } from 'react-i18next';
import { apiDelete, apiGet, apiPost } from '../../../services/_common';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  justify-content: flex-end;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;
const Root = styled.div`
  flex-shrink: 0;
  .inputForm {
    flex-grow: 1;
    position: relative;
    overflow: auto;
    > div {
      width: 100%;
    }
    @media (min-width: 768px) {
      > div {
        position: absolute;
      }
    }
  }
  .thead {
    position: sticky;
    top: 0;
  }
  .tr {
    > div {
      padding: 0 0.5rem;
      button {
        width: 3.5rem;
        padding: 0;
        font-size: 0.875rem;
      }
    }
    > div:nth-child(n + 2) {
      padding: 0;
    }
    input {
      font-size: 0.875rem;
      height: 2rem;
      margin: 0 0.25rem;
    }
  }
`;

interface IHealthSet {
  hCd: string;
  sCd: string;
  hcpCodeOne: string;
  hcpCode?: string;
  hcpName: string;
  writer: string;
  editor?: string;
  flag: string;
}

const MedicalCheckupSetting = () => {
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const userInfo = useRecoilValue(userState);
  const [hcpNTableState, setHcpNTableState] = useState<IHealthSet[]>([]); // 일반 건강검진 목록
  const [hcpSTableState, setHcpSTableState] = useState<IHealthSet[]>([]); // 특수 건강검진 목록
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '', title: '' });
  const hcpNTableRefs = useRef<Array<HTMLInputElement | null>>(Array(hcpNTableState.length).fill(null));
  const hcpSTableRefs = useRef<Array<HTMLInputElement | null>>(Array(hcpSTableState.length).fill(null));
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [isSave2Clicked, setIsSave2Clicked] = useState(false); // 저장버튼2 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const scrollHcpNContainerRef = useRef<HTMLInputElement>(null);
  const scrollHcpSContainerRef = useRef<HTMLInputElement>(null);
  const [isHcpNTableInitialRender, setIsHcpNTableInitialRender] = useState(true); // 컴포넌트의 이니셜렌더 여부
  const [isHcpSTableInitialRender, setIsHcpSTableInitialRender] = useState(true); // 서브테이블 컴포넌트의 이니셜렌더 여부

  useEffect(() => {
    //  로우가 1개 이상이고, 이니셜렌더가 아닌경우 스크롤을 하단으로 이동
    if (hcpNTableState.length > 0 && !isHcpNTableInitialRender) scrollToNodeBottom(scrollHcpNContainerRef);
  }, [hcpNTableState.length]);

  useEffect(() => {
    if (hcpSTableState.length > 0 && !isHcpSTableInitialRender) scrollToNodeBottom(scrollHcpSContainerRef);
  }, [hcpSTableState.length]);

  useEffect(() => {
    getNormalHealthSettingAPI().then((res: any) => {
      if (res.status === 200) {
        getSpecialHealthSettingAPI();
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '환경 설정 > 건강검진 결과',
          action: '조회',
          etc: ``,
        });
      }
    });
  }, []);

  const getNormalHealthSettingAPI = async () => {
    const req = {
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      hcpCodeOne: 'N',
    };
    const res = await apiGet({ path: '/setting/health', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      setHcpNTableState(data.healthSet);
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  const getSpecialHealthSettingAPI = async () => {
    const req = {
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      hcpCodeOne: 'S',
    };
    const res = await apiGet({ path: '/setting/health', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      setHcpSTableState(data.healthSet);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 건강검진 항목 추가
  const onClickAddRow = (type: string) => {
    const data: IHealthSet = {
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      hcpCodeOne: type,
      hcpName: '',
      writer: userInfo.userId,
      editor: userInfo.userId,
      flag: FLAG_CREATE_OR_UPDATE,
    };

    // 일반 건강검진 신규항목 추가
    if (type === 'N') {
      setHcpNTableState((prev: IHealthSet[]) => [...prev, data]);
      setIsHcpNTableInitialRender(false);
    }

    // 특수 건강검진 신규항목 추가
    if (type === 'S') {
      setHcpSTableState((prev: IHealthSet[]) => [...prev, data]);
      setIsHcpSTableInitialRender(false);
    }
  };

  const saveRow = (required: string[], state: IHealthSet[], api: (array: IHealthSet[], type: string) => void, type: string) => {
    const emptyCheck = state.filter((el: any) => {
      const check = required.find((el2: string) => el[el2] === '');
      return check;
    });
    if (emptyCheck.length > 0) {
      const refs = type === 'N' ? hcpNTableRefs : hcpSTableRefs;
      // 첫번째 빈칸에 포커스
      for (const ref of refs.current) {
        if (ref && !ref.value) {
          ref.focus();
          break;
        }
      }

      toast.warning(t('결과값 항목명을 입력하세요'));
    } else {
      // 빈값이 없는 경우 수정,업데이트된 로우들만 서버에 전달
      const reqData = state.filter((el: IHealthSet) => el.flag === FLAG_CREATE_OR_UPDATE);
      api(reqData, type);
    }
  };

  const saveAPI = async (array: IHealthSet[], type: string) => {
    if (array.length > 0) {
      const newArray = array.map((el: IHealthSet) => {
        return { ...el, hCd: userInfo.hCd, sCd: userInfo.sCd, hcpCodeOne: type };
      });
      const req = { healthSetReqDto: trimArray(newArray) };
      const res = await apiPost({ path: '/setting/health', contentType: 'application/json', req });
      const { message, statusCode, data } = res.data;
      if (statusCode === 200) {
        if (type === 'N') {
          setHcpNTableState(data.healthSet);
          await logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: '환경 설정 > 건강검진 결과',
            action: '일반건강검진 결과 저장',
            etc: `신규 ${newArray.filter((v: any) => !v.hcpCode).length}건, 수정 ${newArray.filter((v: any) => v.hcpCode).length}건`,
          });
        }
        if (type === 'S') {
          setHcpSTableState(data.healthSet);
          await logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: '환경 설정 > 건강검진 결과',
            action: '특수건강검진 결과 저장',
            etc: `신규 ${newArray.filter((v: any) => !v.hcpCode).length}건, 수정 ${newArray.filter((v: any) => v.hcpCode).length}건`,
          });
        }
        toast.success(`${type === 'N' ? t('일반') : t('특수')} ${t(message)}`);
      } else {
        // toast.error(t(ERROR));
      }
    } else {
      toast.warning(t('변경된 내용이 없습니다'));
    }
  };

  // 건강검진 항목 추가
  const onClickSave = (type: string) => {
    const required = ['hcpName'];

    if (type === 'N') {
      setIsSaveClicked(true);
      saveRow(required, hcpNTableState, saveAPI, 'N');
    }

    if (type === 'S') {
      setIsSave2Clicked(true);
      saveRow(required, hcpSTableState, saveAPI, 'S');
    }
  };

  // 결과값 항목명 수정
  const onChangeHcpName = (e: React.ChangeEvent<HTMLInputElement>, i: number, code: string | undefined, type: string) => {
    const { name, value } = e.currentTarget;
    // let convertValue = value;

    // // 특수문자 입력 방지
    // const regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=]/gi;
    // if (regExp.test(value)) {
    //   convertValue = value.substring(0, value.length - 1); // 입력한 특수문자 한자리 지움
    // }

    if (type === 'N') {
      const tableArray = [...hcpNTableState];

      // 기존 로우를 수정할 때 (코드값이 있을때)
      if (code !== undefined) {
        const findIndex = tableArray.findIndex((el: IHealthSet) => el.hcpCode === code);
        if (findIndex !== -1) {
          tableArray[findIndex] = { ...tableArray[findIndex], [name]: value, flag: FLAG_CREATE_OR_UPDATE, editor: userInfo.userId };
        }
      } else {
        // 새로 추가한 로우를 수정할 때
        tableArray[i] = {
          ...tableArray[i],
          [name]: value,
          flag: FLAG_CREATE_OR_UPDATE,
          writer: userInfo.userId,
          editor: userInfo.userId,
        };
      }

      setHcpNTableState(tableArray);
    }

    if (type === 'S') {
      const tableArray = [...hcpSTableState];

      // 기존 로우를 수정할 때 (코드값이 있을때)
      if (code !== undefined) {
        const findIndex = tableArray.findIndex((el: IHealthSet) => el.hcpCode === code);
        if (findIndex !== -1) {
          tableArray[findIndex] = { ...tableArray[findIndex], [name]: value, flag: FLAG_CREATE_OR_UPDATE, editor: userInfo.userId };
        }
      } else {
        // 새로 추가한 로우를 수정할 때
        tableArray[i] = {
          ...tableArray[i],
          [name]: value,
          flag: FLAG_CREATE_OR_UPDATE,
          writer: userInfo.userId,
          editor: userInfo.userId,
        };
      }

      setHcpSTableState(tableArray);
    }
  };

  const deleteAPI = async (el: IHealthSet, type: string) => {
    if (el.hcpCode) {
      const req = {
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        hcpCode: el.hcpCode,
        editor: userInfo.userId,
      };

      const res = await apiDelete({ path: '/setting/health', contentType: 'application/json', req });
      const { statusCode } = res.data;

      if (statusCode === 200) {
        setOpenModal((prev) => ({ ...prev, status: false }));
        if (type === 'N') {
          const copyArray = [...hcpNTableState];
          const newArray = copyArray.filter((v: IHealthSet) => v.hcpCode !== el.hcpCode);
          setHcpNTableState(newArray);
          await logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: '환경 설정 > 건강검진 결과',
            action: '일반건강검진 결과 삭제',
            etc: `${el.hcpName}(${el.hcpCode})`,
          });
        }

        if (type === 'S') {
          const copyArray = [...hcpSTableState];
          const newArray = copyArray.filter((v: IHealthSet) => v.hcpCode !== el.hcpCode);
          setHcpSTableState(newArray);
          await logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: '환경 설정 > 건강검진 결과',
            action: '특수건강검진 결과 삭제',
            etc: `${el.hcpName}(${el.hcpCode})`,
          });
        }
      } else {
        // toast.error(t(ERROR));
      }
    }
  };

  const onClickDelete = (el: IHealthSet, i: number, type: string) => {
    if (type === 'N') {
      const tableArray = [...hcpNTableState];

      if (el.hcpCode !== undefined) {
        // 기존 로우를 삭제할 때 (코드값이 있을때)
        // 삭제 시 에디터아이디 추가
        const data = {
          ...el,
          editor: userInfo.userId,
        };
        setOpenModal((prev: any) => ({ ...prev, status: true, type: 'delete', tableArray, setHcpNTableState, api: () => deleteAPI(data, type), el: data, index: i }));
      } else {
        // 새로 추가한 로우를 삭제할 때
        tableArray.splice(i, 1);
        setHcpNTableState(tableArray);
      }
    }

    if (type === 'S') {
      const tableArray = [...hcpSTableState];
      if (el.hcpCode !== undefined) {
        // 기존 로우를 삭제할 때 (코드값이 있을때)
        // 삭제 시 에디터아이디 추가
        const data = {
          ...el,
          editor: userInfo.userId,
        };
        setOpenModal((prev: any) => ({ ...prev, status: true, tableArray, type: 'delete', setHcpSTableState, api: () => deleteAPI(data, type), el: data, index: i }));
      } else {
        // 새로 추가한 로우를 삭제할 때
        tableArray.splice(i, 1);
        setHcpSTableState(tableArray);
      }
    }
  };

  const renderDeleteBtn = (el: any, i: number, type: string) => {
    if (auth.deleteAuth) {
      return (
        <div className='trCol4 flex-center shrink0 padding-right-05'>
          <BtnRed onClick={() => onClickDelete(el, i, type)}>{el.hcpCode !== undefined ? t('삭제') : t('제거')}</BtnRed>
        </div>
      );
    }
    if (el.hcpCode === undefined && (auth.createAuth || auth.updateAuth)) {
      return (
        <div className='trCol4 flex-center shrink0 padding-right-05'>
          <BtnRed onClick={() => onClickDelete(el, i, type)}>{t('제거')}</BtnRed>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <Root>
        <SearchOptions align='left' className='setting-medical'>
          <div className='inputsWrapper'>
            <div className='secondSearchOption'>
              <div>{t('일반 건강검진')}</div>
            </div>
          </div>
        </SearchOptions>
        <InputTable className='margin-left-05 noHorizontalScroll'>
          <div ref={scrollHcpNContainerRef}>
            <div className='thead'>
              <div className='tr'>
                <div className='trCol2p5 flex-center shrink0'>No</div>
                <div className='trCol4 flex-center shrink0'>{t('코드')}</div>
                <div className='trColGrow flex-center shrink1'>{t('결과값 항목명')}</div>
                <div className='trCol4 flex-basic shrink0 padding-right-05'> </div>
              </div>
            </div>
            <div className='table'>
              <div className='tbody'>
                {hcpNTableState?.length > 0 ? (
                  hcpNTableState.map((el: IHealthSet, i: number) => (
                    <div className='tr' role='button' tabIndex={0} key={`NHealthCheckup_${i}`}>
                      <div className='trCol2p5 flex-center shrink0'>{i + 1}</div>
                      <div className='trCol4 flex-center shrink0 padding-right-05'>{el.hcpCode}</div>
                      <div className='trColGrow flex-center shrink1'>
                        <input
                          type='text'
                          id='sHcpName'
                          name='hcpName'
                          value={el.hcpName}
                          onChange={(e) => onChangeHcpName(e, i, el.hcpCode, 'N')}
                          disabled={!auth.createAuth && !auth.updateAuth}
                          ref={(refEl) => {
                            hcpNTableRefs.current[i] = refEl;
                          }}
                          style={isSaveClicked ? applyBorderStyle(el.hcpName, 'red', 'hcpName') : undefined}
                          maxLength={50}
                        />
                      </div>
                      {renderDeleteBtn(el, i, 'N')}
                    </div>
                  ))
                ) : (
                  <div className='noData'>No data.</div>
                )}
              </div>
            </div>
          </div>
        </InputTable>
        <ButtonsWrapper>
          {auth.createAuth && <BtnBlue onClick={() => onClickAddRow('N')}>{t('신규항목 추가')}</BtnBlue>}
          {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={() => onClickSave('N')}>{t('저장')}</BtnBlue>}
        </ButtonsWrapper>
        <Portal openModal={openModal?.status}>{openModal && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
      </Root>
      <Root>
        <SearchOptions align='left' className='setting-medical'>
          <div className='inputsWrapper'>
            <div className='secondSearchOption'>
              <div>{t('특수 건강검진')}</div>
            </div>
          </div>
        </SearchOptions>
        <InputTable className='margin-left-05 noHorizontalScroll'>
          <div className='thead'>
            <div className='tr'>
              <div className='trCol2p5 flex-center shrink0'>No</div>
              <div className='trCol4 flex-center shrink0'>{t('코드')}</div>
              <div className='trColGrow flex-center shrink1'>{t('결과값 항목명')}</div>
              <div className='trCol4 flex-basic shrink0 padding-right-05'> </div>
            </div>
          </div>
          <div className='table'>
            <div className='tbody'>
              {hcpSTableState?.length > 0 ? (
                hcpSTableState.map((el: IHealthSet, i: number) => (
                  <div className='tr' role='button' tabIndex={0} key={`SHealthCheckup_${i}`}>
                    <div className='trCol2p5 flex-center shrink0'>{i + 1}</div>
                    <div className='trCol4 flex-center shrink0 '>{el.hcpCode}</div>
                    <div className='trColGrow flex-center shrink1'>
                      {/* <div className={auth.deleteAuth ? 'trCol6' : 'trCol6'}> */}
                      <input
                        type='text'
                        id='sHcpName'
                        name='hcpName'
                        value={el.hcpName}
                        onChange={(e) => onChangeHcpName(e, i, el.hcpCode, 'S')}
                        disabled={!auth.createAuth && !auth.updateAuth}
                        ref={(refEl) => {
                          hcpSTableRefs.current[i] = refEl;
                        }}
                        style={isSave2Clicked ? applyBorderStyle(el.hcpName, 'red', 'hcpName') : undefined}
                        maxLength={50}
                      />
                    </div>
                    {renderDeleteBtn(el, i, 'S')}
                  </div>
                ))
              ) : (
                <div className='noData'>No data.</div>
              )}
            </div>
          </div>
        </InputTable>
        <ButtonsWrapper>
          {auth.createAuth && <BtnBlue onClick={() => onClickAddRow('S')}>{t('신규항목 추가')}</BtnBlue>}
          {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={() => onClickSave('S')}>{t('저장')}</BtnBlue>}
        </ButtonsWrapper>
        <Portal openModal={openModal?.status}>{openModal && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
      </Root>
    </>
  );
};

export default MedicalCheckupSetting;
