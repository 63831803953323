import { ISideMenuObject, ITabObject } from 'customTypes';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { v1 } from 'uuid';
import getInitialColorMode from './services/getInitialColorMode';

export interface responsiveProps {
  width?: number;
  height?: number;
  deviceW?: number;
  deviceH?: number;
  mode?: string;
}

export interface IUser {
  userId: string;
  userName: string;
  gCd: string;
  sessionTimeout: string;
  hCd: string;
  hName: string;
  sCd: string;
  sName: string;
  prejobtypeYn: string;
  eduPlace: string;
  eduCharge: string;
  eduStartTime: string;
  eduEndTime: string;
  elderlyAgeSet: string;
  eListYn: string;
  eExcelYn: string;
  ePrintYn: string;
  fromLowpress: string;
  toLowpress: string;
  fromHighpress: string;
  toHighpress: string;
  accidentSdate: string;
  accidentEdate: string;
  accidentTday: string;
  userMenuList: any[];
  cctvProtocol: string;
  bScreenMode: string;
}

export interface IPageInfo {
  header: {
    mtName: string;
    mdName: string;
  };
  defaultMrCd: string;
  tabList: ITabObject[];
  mtCd: string;
  mdCd: string;
  mrCd: string;
}

const { persistAtom } = recoilPersist();

export const sizeState = atom<any>({
  key: `size/${v1()}`,
  default: [{ inner: [window.innerWidth, window.innerHeight] }, { screen: [window.screen.availWidth, window.screen.availHeight] }],
});

export const userState = atom<IUser>({
  key: 'userState',
  default: {
    userId: '',
    userName: '',
    gCd: '',
    sessionTimeout: '',
    hCd: '',
    hName: '',
    sCd: '',
    sName: '',
    prejobtypeYn: '',
    eduPlace: '',
    eduCharge: '',
    eduStartTime: '',
    eduEndTime: '',
    elderlyAgeSet: '',
    eListYn: '',
    eExcelYn: '',
    ePrintYn: '',
    fromLowpress: '',
    toLowpress: '',
    fromHighpress: '',
    toHighpress: '',
    accidentSdate: '',
    accidentEdate: '',
    accidentTday: '',
    userMenuList: [],
    cctvProtocol: '',
    bScreenMode: '',
  },
  effects_UNSTABLE: [persistAtom],
});

export const pageInfoState = atom<IPageInfo>({
  key: 'pageInfoState',
  default: { header: { mtName: '', mdName: '' }, defaultMrCd: '', tabList: [], mtCd: '', mdCd: '', mrCd: '' },
  effects_UNSTABLE: [persistAtom],
});

export const sideMenuState = atom<ISideMenuObject[]>({
  key: 'sideMenuState',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const asideState = atom<boolean>({
  key: `asideState`,
  default: false,
});

export const asideSubState = atom<boolean>({
  key: `asideSubState`,
  default: false,
});

export const themeState = atom<string>({
  key: `themeState`,
  default: getInitialColorMode(),
});

export const bScreenMode = atom<string>({
  key: `bScreenMode`,
  default: '1',
});

export const asideFoldState = atom<boolean>({
  key: `asideFoldState`,
  default: false,
});

export const loginState = atom<boolean>({
  key: `loginState`,
  default: false,
});

export const tInfoUpdateState = atom<boolean>({
  // 대시보드에서 터널정보가 업데이트 됐을 때 터널관련 컴포넌트를 모두 업데이트 시키기 위한 스테이트
  key: `tInfoUpdateState`,
  default: false,
});

export const tunnelReverseState = atom<boolean>({
  key: `isTunnelReverse`,
  default: false,
});

export const mouseDownIndexState = atom<number | null>({
  key: `mouseDownIndexState`,
  default: 0,
});

export const camRecordingState = atom<boolean>({
  key: `camRecordingState`,
  default: false,
});

export const msiteUseYnState = atom<'Y' | 'N'>({
  key: `msiteUseYnState`,
  default: 'N',
});

export const msiteListState = atom<any[]>({
  key: `msiteListState`,
  default: [],
});

export const localCctvSettingState = atom<CctvSettings>({
  key: `localCctvSettingState`,
  default: {
    camList: '',
    camList1: '',
    camList2: '',
    camList3: '',
    camList4: '',
    camList5: '',
    camCType: '',
    camSpeed: '',
    camCPath: '',
    camMPath: '',
    camFColor: '',
    camCLocation: '',
  },
});
