/**
 * 작성자 : 홍선영
 * 날짜 : 2023.01.09
 * 기능 : 터널 관리 > 굴진/일반 정보 > 굴진/일반 정보 팝업 > 근로자/장비 위치정보 탭 (현장용)
 */
/*
 * 수정자 : 김광민
 * 수정 날짜 : 2024.02.27
 * 수정 이유 : 가독성 향상과 유지보수를 위한 코드 분리, 변수 변경 및 삭제, 재사용 코드 컴포넌트화, 스타일 수정, 일부 로직 수정
 */

import { useQuery } from '@tanstack/react-query';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { apiGet } from '../services/_common';
import { IUser } from '../atoms';
import { PulseLoader } from 'react-spinners';
import TareaLocationInfoGrid from './TareaLocationInfoTable';

type Props = { userInfo: IUser; tatCd: string; tadCd: String; tatGubunCd: string; perPage: number; isSearchClicked: boolean; setIsSearchClicked: Dispatch<SetStateAction<boolean>> };

const TareaLocationInfoEquipGrid = ({ userInfo, tatCd, tadCd, tatGubunCd, perPage, isSearchClicked, setIsSearchClicked }: Props) => {
  const [initEquipLocationState, setInitEquipLocationState] = useState<any[]>([]);
  const [equipLocationState, setEquipLocationState] = useState<any[]>([]);

  // 선택한 구역의 장비 위치 정보를 가져오는 쿼리
  const tAreaEquipLocationQuery = useQuery(
    ['tAreaDEquipLocationGet', userInfo.hCd, userInfo.sCd, tatCd, tatGubunCd],
    () => apiGet({ path: '/tarea/board/equipment', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, tatCd } }),
    {
      enabled: !!userInfo.hCd && !!userInfo.sCd && !!tatCd,
      onSuccess: (fetchedData) => {
        // 선택한 구역의 장비 위치 정보를 설정
        const { tareaList } = fetchedData.data.data;
        const newArray = tareaList.filter((el: any) => el.tatGubun === tatGubunCd);
        setInitEquipLocationState(newArray);
      },
      onError: (error) => {
        console.error('API 호출 중 오류 발생:', error);
      },
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      if (tAreaEquipLocationQuery.isSuccess && tAreaEquipLocationQuery.data.status === 200) {
        const { tareaList } = tAreaEquipLocationQuery.data.data.data;
        const newArray = tareaList.filter((el: any) => el.tatGubun === tatGubunCd);
        setInitEquipLocationState(newArray);
      }
    };
    fetchData().catch(console.error);
  }, [tAreaEquipLocationQuery.isSuccess, tAreaEquipLocationQuery.isRefetching]);

  // 검색 버튼 클릭시
  useEffect(() => {
    if (isSearchClicked) {
      tAreaEquipLocationQuery.refetch();
      setIsSearchClicked(false);
    }
  }, [isSearchClicked]);

  // 장비 위치 정보를 설정하는 useEffect
  useEffect(() => {
    if (tadCd !== 'A') {
      const newArray = initEquipLocationState
        .filter((el: any) => el.tadCd === tadCd)
        .map((v: any) => {
          const date = v.bDate.split(' ');
          return {
            ...v,
            wNamejobType: v.wJobtypeName ? `${v.wName}<div>${v.wJobtypeName}</div>` : v.wName,
            sjNametadName: `${v.sjName}<div>${v.tadName}</div>`,
            bDate: `${date[0]}<div>${date[1]}</div>`,
          };
        });
      setEquipLocationState(newArray);
    } else {
      const newArray = initEquipLocationState.map((v: any) => {
        const date = v.bDate.split(' ');
        return {
          ...v,
          wNamejobType: v.wJobtypeName ? `${v.wName}<div>${v.wJobtypeName}</div>` : v.wName,
          sjNametadName: `${v.sjName}<div>${v.tadName}</div>`,
          bDate: `${date[0]}<div>${date[1]}</div>`,
        };
      });
      setEquipLocationState(newArray);
    }
  }, [initEquipLocationState, tadCd]);

  if (tAreaEquipLocationQuery.isLoading) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return <TareaLocationInfoGrid perPage={perPage} gridData={equipLocationState} isWorker={false} />;
};

export default TareaLocationInfoEquipGrid;
