/**
 * 작성자 : 홍선영
 * 날짜 : 2024.02.08
 * 경로 : 설정관리-환경설정-터널관리탭 (현장관리자)
 */

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { tunnelReverseState, userState } from '../../../atoms';
import { TUNNEL_DIRECTION } from '../../../_constants';
import { BtnBlue } from '../../../components/Button';
import { useSetAuth } from '../../../utils/useSetAuth';
import { apiPost } from '../../../services/_common';
import SelectBox from '../../../components/SelectBox';
import IssueGuide from '../../../components/IssueGuide';
import { logPost } from '../../../services/log';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  width: 100%;
  justify-content: flex-end;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;

const Root = styled.div`
  align-items: center;
  .inputForm {
    overflow: auto;
    flex-grow: 1;
    position: relative;
    width: calc(100% - 0.5rem);
    margin-left: 0.5rem;

    > div {
      width: 100%;
    }

    .setting-container-group {
      width: 100%;
      max-width: 40rem;
      margin: 0 auto;
      gap: 2rem;
      display: flex;
      flex-direction: column;
      padding-bottom: 4rem;
    }

    .title {
      border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      height: 3rem;
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
    }
    .setting-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      column-gap: 2rem;
      row-gap: 1rem;
      padding: 1rem 0.5rem;
      @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    .setting-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 2.5rem;
      input {
        font-size: 0.875rem;
      }
      label {
        font-weight: 500;
        font-size: 0.875rem;
        width: 8rem;
        flex-shrink: 0;
      }
      > div {
        flex-grow: 1;
        ul li {
          max-width: 100%;
          width: 100%;
          span {
            width: 100%;
          }
        }
      }
    }
  }

  .buttonsWrapper {
    display: flex;
    justify-content: flex-start;
    @media (min-width: 768px) {
      justify-content: flex-end;
    }
  }
`;

const TunnelSetting = () => {
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const userInfo = useRecoilValue(userState);
  const [isReversed, setIsReversed] = useRecoilState(tunnelReverseState);

  useEffect(() => {
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '환경 설정 > 터널 관리',
      action: '조회',
      etc: ``,
    });
  }, []);

  const INIT_TUNNEL_DIRECTION = {
    type: 'tDirection',
    // tDirection: 'F',
    // cdName: t('정방향'),
    tDirection: !isReversed ? 'F' : 'R',
    cdName: !isReversed ? t('정방향') : t('역방향'),
  };
  const [TunnelDirection, setTunnelDirection] = useState(INIT_TUNNEL_DIRECTION);

  const saveTunnelSettingAPI = async () => {
    const { hCd, sCd, userId } = userInfo;
    const requestParams = { hCd, sCd, tDirection: TunnelDirection.tDirection, editor: userId };
    const response = await apiPost({ path: '/setting/tunnel', req: requestParams });
    const { statusCode, message } = response.data || {};
    if (statusCode === 200) {
      toast.success(t(message));
      setIsReversed(!isReversed);
    }
  };

  const onClickSave = () => {
    saveTunnelSettingAPI();
  };

  if (isReversed === null) {
    return <IssueGuide />;
  }

  return (
    <Root>
      <div className='inputForm'>
        <div className='setting-container-group'>
          <div className='setting-container'>
            <div className='title'>{t('수직구 방향 설정')}</div>
            <div className='setting-grid'>
              <div className='setting-row'>
                <label htmlFor='tDirection'>{t('시/종점 방향')}</label>
                <SelectBox
                  options={TUNNEL_DIRECTION}
                  defaultOption={t(`${TunnelDirection.cdName}`)}
                  state={TunnelDirection}
                  setState={setTunnelDirection}
                  stateKey='tDirection'
                  grantChk={userInfo.gCd !== 'MM'}
                  initiateKey={TunnelDirection.tDirection}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ButtonsWrapper>{(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={onClickSave}>{t('저장')}</BtnBlue>}</ButtonsWrapper>
    </Root>
  );
};

export default TunnelSetting;
