import React from 'react';
import styled from 'styled-components';

const CustomDotContainer = styled.div`
  display: flex;
  width: 1rem;
  height: 0.25rem;
  border-radius: 1rem;
  background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
  margin-bottom: 0.375rem;
  margin-left: 0.125rem;
  margin-right: 0.125rem;
  cursor: pointer;
  &.inactive {
    opacity: 0.25;
  }
`;

export const CustomDot = ({ onClick, mergedData, ...rest }: any) => {
  const {
    onMove,
    index,
    active,
    carouselState: { currentSlide, deviceType },
  } = rest;
  const carouselItems = mergedData.map(() => '');
  // onMove means if dragging or swiping in progress.
  // active is provided by this lib for checking if the item is active or not.
  return (
    <CustomDotContainer role='button' tabIndex={0} className={active ? 'active' : 'inactive'} onClick={() => onClick()}>
      {React.Children.toArray(carouselItems)[index]}
    </CustomDotContainer>
  );
};
