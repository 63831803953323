/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.23
 * 경로 : 터널관리 - 구역 현황 관리 > 종합진행현황
 */

import { useQuery } from '@tanstack/react-query';
import { logPost } from '../../services/log';
import { ERROR } from '../../_constants';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import styled from 'styled-components';
import TunnelTdProgressBar from '../TunnelTdProgressbar';
import TotalPointLength from '../tunnel/TotalLength';
import HalfDoughnutChart from '../chart/HalfDoughnutChart';
import { apiGet } from '../../services/_common';
import { tunnelReverseState } from '../../atoms';
import { useRecoilValue } from 'recoil';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  user-select: none;
  > .title {
    height: 2rem;
    padding: 0 1rem;
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    .name {
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      font-weight: ${({ theme }: { theme: any }) => theme.font_semibold};
      font-size: 1.125rem;
      line-height: 1.2;
    }
  }
  > .content {
    width: 100%;
    flex-grow: 1;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    position: relative;
    .doughnut {
      width: 100%;
      height: 5rem;
      height: 75%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.125rem;
      flex-grow: 1;
      position: absolute;
      top: -0.5rem;
      canvas {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -1.5rem;
      }
      .percentage {
        font-weight: 600;
        font-size: 2.5rem;
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        padding-left: 0.5rem;
        .symbol {
          font-size: 1rem;
          font-weight: 400;
          color: ${({ theme }: { theme: any }) => theme.text_disabled};
          margin-left: 0.125rem;
        }
      }
      .tatName {
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        margin-bottom: 1.75rem;
        padding: 0 0.75rem;
        border-radius: 1rem;
        /* padding: 0 0.5rem; */
        /* border-radius: 0.25rem; */
        /* background-color: ${({ theme }: { theme: any }) => theme.tonal}; */
        outline: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      }
    }
    .progressbar {
      display: flex;
      gap: 0.25rem;
      .point {
        flex: 1 0 calc(50% - 0.25rem);
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
      }
    }
  }
`;

type Props = { name: string; userInfo: any; tatCd: string };

const TunnelExcavationDoughnut = ({ name, userInfo, tatCd }: Props) => {
  const { t } = useTranslation();
  const isTunnelReversed = useRecoilValue(tunnelReverseState);

  // Tarea 데이터 요청 함수
  const fetchTareaData = async ({ hCd, sCd }: { hCd: string; sCd: string }) => {
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, tatCd };
      const res = await apiGet({ path: `/tarea/t`, req });
      const { data } = res.data || {};

      return data || [];
    } catch (error) {
      console.error('API 호출 중 오류 발생:', error);
      toast.error(t(ERROR));
      throw new Error('API 호출 중 오류 발생');
    }
  };

  // Tarea 데이터 요청 상태값
  const { data, isError, isLoading, refetch } = useQuery(['TunnelExcavationDoughnut', userInfo.hCd, userInfo.sCd], () => fetchTareaData(userInfo), {
    refetchInterval: 60000, // 60초마다 자동 refetch
  });

  // 전체 길이 및 반경 길이 계산 함수
  const getPercentage = () => {
    const { eRLength, eLength, sRLength, sLength, sUseYn, eUseYn } = data;
    const rlength = ((sUseYn === 'Y' && sRLength) ?? 0) + ((eUseYn === 'Y' && eRLength) ?? 0);
    const length = ((sUseYn === 'Y' && sLength) ?? 0) + ((eUseYn === 'Y' && eLength) ?? 0);

    return length !== 0 ? (rlength / length) * 100 : 0;
  };

  // 시점 및 종점 렌더링 함수
  const renderPoint = (point: 'start' | 'end') => {
    const { eRLength, eLength, sRLength, sLength, sUseYn, eUseYn } = data;
    const isStart = point === 'start';
    const style = isStart ? 'startPoint' : 'endPoint';
    const rlength = isStart ? sRLength : eRLength;
    const length = isStart ? sLength : eLength;
    const useYn = isStart ? sUseYn : eUseYn;
    const isReversed = !isTunnelReversed ? isStart : !isStart;

    if (useYn === 'N') return null;

    return (
      <div className={`point ${style}`}>
        <TunnelTdProgressBar rlength={rlength} length={length} reverse={isReversed} meter={false} point={isStart ? 's' : 'e'} />
        <TotalPointLength rLength={rlength} length={length} nameOnly reverse={isStart} />
      </div>
    );
  };

  // 로딩 중일 때
  if (isLoading) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  if (isError) {
    console.error('API 호출 중 오류 발생:', data);
  }

  return (
    <Root>
      <div className='title'>
        <span className='name'>{t('굴진 진행 현황')}</span>
      </div>
      <div className='content'>
        <div className='doughnut'>
          <HalfDoughnutChart value={100} chartType='half' siteDashboard />
          <HalfDoughnutChart value={getPercentage()} background chartType='half' siteDashboard />
          <div className='percentage'>
            {getPercentage().toFixed(0)}
            <span className='symbol'>%</span>
          </div>
          {/* <div className='tatName'>{data.tatName}</div> */}
        </div>
        <div className='progressbar'>
          {renderPoint('start')}
          {renderPoint('end')}
        </div>
      </div>
    </Root>
  );
};

export default TunnelExcavationDoughnut;
