import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Modal, ModalBackground } from '../../../assets/styles/Modal';
import { useTranslation } from 'react-i18next';
import { BtnBlue } from '../../../components/Button';
import { useReactToPrint } from 'react-to-print';
import WorkerInfoPrintForm from './workerInfoPrintForm';
import { PulseLoader } from 'react-spinners';
import { useSetAuth } from '../../../utils/useSetAuth';
import useOnKeydownPgUp from '../../../utils/useOnKeydownPgUp';
import useOnKeydownPgDn from '../../../utils/useOnKeydownPgDn';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTemplateGubunList } from './services';
import WorkerInfoPrint from './WorkerInfoPrint';

interface IWorkerPrintDateilModal {
  setOpenModal: Dispatch<SetStateAction<{ status: boolean; type: string; data?: any; code?: string; aDate?: string; index?: number }>>;
  data: any;
  index: number | undefined;
  reportArray: any[]; // 보고서목록데이터
  setRowKey: Dispatch<SetStateAction<string>>;
  listCount: any;
}

const WorkerPrintDateilModal = ({ setOpenModal, data, index, reportArray, setRowKey, listCount }: IWorkerPrintDateilModal) => {
  const { data: CtemplateList, isLoading: isLoadingCtemplateList } = useTemplateGubunList('C');
  const { data: JtemplateList, isLoading: isLoadingJtemplateList } = useTemplateGubunList('J');
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const navigate = useNavigate();
  const location = useLocation();
  const componentRef = useRef<HTMLDivElement>(null);
  const [workerImg, setWorkerImg] = useState<string>('workerInfo');
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState('workerInfo');
  const randomString = Math.random().toString(36).substring(7); // 랜덤 문자열을 쿼리스트링으로 추가해서 이미지 캐싱 방지
  const { isPgUpPressed, setIsPgUpPressed } = useOnKeydownPgUp();
  const { isPgDnPressed, setIsPgDnPressed } = useOnKeydownPgDn();
  const [workerData, setWorkerData] = useState(data);
  const [currentWorkerIndex, setCurrentWorkerIndex] = useState(index);

  const createButtons = () => {
    const docs = [
      { key: 'consignImg', label: '개인정보 위탁/처리 동의서' },
      { key: 'safeeduImg', label: '안전교육 완료 서명' },
      ...CtemplateList,
      ...JtemplateList,
      { key: 'muscularImg', label: '근골격계 증상조사표' },
      { key: 'painImg', label: '작업관련 통증 조사표' },
      { key: 'stressImg', label: '직무스트레스 평가표' },
    ];

    return docs.map(
      (doc) =>
        workerData[doc.key] && (
          <div key={doc.key} className={`${selected === doc.key ? 'selected' : ''}`} role='button' tabIndex={0} onClick={() => onClickImgBtn(doc.key)}>
            {t(doc.label)}
          </div>
        )
    );
  };

  useEffect(() => {
    const targetRow = reportArray.find((_, i: number) => i === currentWorkerIndex);
    if (targetRow) {
      setSelected('workerInfo');
      setWorkerImg('workerInfo');

      setWorkerData({
        workerInfo: { ...targetRow },
        consignImg: targetRow.consignImg,
        safeeduImg: targetRow.safeeduImg,
        muscularImg: targetRow.muscularImg,
        painImg: targetRow.painImg,
        stressImg: targetRow.stressImg,
        C01: targetRow.C01,
        C02: targetRow.C02,
        C03: targetRow.C03,
        C04: targetRow.C04,
        C05: targetRow.C05,
        C06: targetRow.C06,
        C07: targetRow.C07,
        C08: targetRow.C08,
        C09: targetRow.C09,
        C10: targetRow.C10,
        J01: targetRow.J01,
        J02: targetRow.J02,
        J03: targetRow.J03,
        J04: targetRow.J04,
        J05: targetRow.J05,
        J06: targetRow.J06,
        J07: targetRow.J07,
        J08: targetRow.J08,
        J09: targetRow.J09,
        J10: targetRow.J10,
      });
    }
  }, [currentWorkerIndex]);

  useEffect(() => {
    if (isLoading) setIsLoading(false);
    if (componentRef.current) {
      componentRef.current.scrollTop = 0; // Reset scroll position to the top
    }
  }, [workerImg, isLoading]);

  const onClickImgBtn = (keyParam: string) => {
    setSelected(keyParam);
    if (keyParam !== 'workerInfo') {
      setWorkerImg(workerData[keyParam]);
      if (workerData[keyParam] !== workerImg) setIsLoading(true);
    } else {
      setWorkerImg('workerInfo');
      if (workerImg !== 'workerInfo') setIsLoading(true);
    }
  };

  const onClickClose = () => {
    setOpenModal((prev) => ({ ...prev, status: false }));
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: workerData.workerInfo.wImg //
      ? ''
      : '@page { size: A4 portrait; margin: 1cm; } @media print { body { -webkit-print-color-adjust: exact; width:100%; } }',
  });

  const onClickPrint = () => {
    handlePrint();
  };

  useEffect(() => {
    if (isPgUpPressed) {
      onClickPrev();
      setIsPgUpPressed(false);
    }
  }, [isPgUpPressed]);

  useEffect(() => {
    if (isPgDnPressed) {
      onClickNext();
      setIsPgDnPressed(false);
    }
  }, [isPgDnPressed]);

  const onClickPrev = () => {
    if (reportArray) {
      if (currentWorkerIndex !== undefined && currentWorkerIndex > 0) {
        const newIndex = currentWorkerIndex - 1;
        if (listCount) {
          navigate({ pathname: location.pathname, search: `?pageNum=${Math.floor(newIndex / listCount) + 1}` });
        }
        if (setRowKey) setRowKey(`${newIndex}`);
        setCurrentWorkerIndex(newIndex);
      } else toast.info(t('이전 근로자가 없습니다'));
    }
  };

  const onClickNext = () => {
    if (reportArray) {
      if (currentWorkerIndex !== undefined && reportArray.length - 1 > currentWorkerIndex) {
        const newIndex = currentWorkerIndex + 1;
        if (listCount) {
          navigate({ pathname: location.pathname, search: `?pageNum=${Math.floor(newIndex / listCount) + 1}` });
        }
        //     if (setReportArray) setReportArray([...reportArray]);
        if (setRowKey) setRowKey(`${newIndex}`);
        setCurrentWorkerIndex(newIndex);
      } else toast.info(t('다음 근로자가 없습니다'));
    }
  };

  if (isLoadingCtemplateList || isLoadingJtemplateList) return null;

  return (
    <ModalBackground onClick={onClickClose} role='presentation'>
      <Modal>
        <div
          className='modal workerSignInfo'
          role='presentation'
          onClick={(event) => {
            event.stopPropagation();
          }}
          style={{ minWidth: '55vw' }}
        >
          <div className='inputForm-head'>
            {/* {`${workerData.workerInfo?.wName} ${t('근로자 서명 내용 보기/출력')}`} */}
            {t('근로자 서명 내용 보기/출력')}
            <div className='closeBtn' onClick={onClickClose} role='presentation'>
              <span className='material-symbols-rounded'>close</span>
            </div>
          </div>
          {!isLoading ? (
            <div className='inputForm-body'>
              <div className='buttonContainer'>
                <div className={`${selected === 'workerInfo' ? 'selected' : ''}`} role='button' tabIndex={0} onClick={() => onClickImgBtn('workerInfo')}>
                  {t('근로자 정보')}
                </div>
                {createButtons()}
              </div>
              <div className='contentContainer flexColumn' ref={componentRef}>
                {workerImg !== 'workerInfo' ? (
                  <div className='scrollableDiv'>
                    <img key={workerData.workerInfo.wCd} src={`${workerImg}?v=${randomString}`} alt='이미지' />
                  </div>
                ) : (
                  <WorkerInfoPrint
                    data={workerData.workerInfo} //
                  />
                )}
              </div>
            </div>
          ) : (
            <div className='centered-content full'>
              <PulseLoader color='rgb(0, 122, 255)' size='10px' />
            </div>
          )}
          <div className='modal-footer flex-between'>
            <div className='flex-basic arrows'>
              <BtnBlue onClick={onClickPrev}>
                <span className='material-symbols-rounded'>arrow_back_ios_new</span>
                <p>{t('이전')}</p>
                <div>
                  <p>Page</p>
                  <p>Up</p>
                </div>
              </BtnBlue>
              <BtnBlue onClick={onClickNext}>
                <div>
                  <p>Page</p>
                  <p>Down</p>
                </div>
                <p>{t('다음')}</p>
                <span className='material-symbols-rounded'>arrow_forward_ios</span>
              </BtnBlue>
            </div>
            <div className='flex-basic'>{auth.printAuth && <BtnBlue onClick={onClickPrint}>{t('인쇄')}</BtnBlue>}</div>
          </div>
        </div>
      </Modal>
    </ModalBackground>
  );
};

export default WorkerPrintDateilModal;
