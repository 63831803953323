/**
 * 작성자 : 한영광
 * 날짜 : 2023.12.27
 * 기능 : 터널 관리 > 굴진/일반 정보 > 굴진/일반 정보 팝업 > CCTV 정보 탭 (현장용)
 */
/**
 * 수정자 : 김광민
 * 수정 날짜 : 2024.02.15
 * 수정 이유 : 가독성 향상과 유지보수를 위한 코드 분리, 변수 변경 및 삭제, 재사용 코드 컴포넌트화, 스타일 수정, 일부 로직 수정
 */

import { IUser } from '../../atoms';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WebSDKspeaker } from '../../utils/webSDKspeakerClass';
import { webSDKFunc } from '../../services/webSDK';
import { PulseLoader } from 'react-spinners';
import * as cctv from '../../utils/cctv';
import WebSdk from '../../pages/s_cctv/WebSdk';
import SelectBox from '../SelectBox';
import MultiVideoPlayer from '../MultipleVideoPlayer3';
import WebSdkModal from './WebSdkModal';
import Portal from '../Portal';
import styled from 'styled-components';
import PlayVideoButton from '../button/PlayVideoButton';
import IssueGuide from '../IssueGuide';
import TunnelPointHorizontalLabel from '../TunnelPointHorisontalLabel';
import VariableButton from '../button/VariableButton';
import VariableMessage from '../VariableMessage';

const Root = styled.div`
  width: calc(50% - 0.25rem);
  flex-grow: 1;
  background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  gap: 1rem;
  .selector {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
    > div:nth-child(2) {
      width: 20rem;
      flex-grow: 1;
      li {
        background-color: ${({ theme }: { theme: any }) => theme.board} !important;
        width: 100% !important;
        max-width: 100% !important;
        &:hover {
          box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.05);
        }
        &.opened:hover {
          box-shadow: none;
        }
      }
    }
  }
  .cctvWrapper {
    border-radius: 0.5rem;
    overflow: hidden;
  }
  .controllerWrapper {
    display: none;
  }
`;

interface ICameraObject {
  wsNum: string;
  nName: string;
  cName: string;
}

type Props = {
  userInfo: IUser;
  pointListData: CctvList[];
  pointPosition: 'S' | 'E';
  isLoading: boolean;
};
const webSDKClosure = webSDKFunc();

const CctvPoint = ({ userInfo, pointListData, pointPosition, isLoading }: Props) => {
  const cctvElement = useRef<HTMLDivElement>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [cctvDivision, setCctvDivision] = useState(1);

  const [isWebSdkPlay, setIsWebSdkPlay] = useState(false);
  const [isDelay, setIsDelay] = useState(false);
  const [isMic, setIsMic] = useState(false);
  const [isPlay, setIsPlay] = useState(true);
  const [selectedCamera, setSelectedCamera] = useState<any>({});
  const [camera, setCamera] = useState<any>({});
  const [speaker, setSpeaker] = useState<WebSDKspeaker>();
  const [bsState, setBsState] = useState<number[]>([]);

  const [openModal, setOpenModal] = useState<Record<string, boolean | string>>({ status: false, type: '', title: '' });
  const [openSubModal, setOpenSubModal] = useState<Record<string, boolean | string>>({ status: false, type: '', isOverlappingModal: true });

  const { t } = useTranslation();
  const { cName } = selectedCamera as CctvList;
  // pointListData 배열의 변화를 감지하여 실행되는 useEffect
  useEffect(() => {
    // pointListData에 데이터가 있을 경우 첫 번째 CCTV를 선택된 카메라로 설정
    if (pointListData.length > 0) {
      setCamera({ ...pointListData[0], subCd: 0, name: pointListData[0]?.cName, cdName: pointListData[0]?.cName });
      setSelectedCamera({ ...pointListData[0], subCd: 0, name: pointListData[0]?.cName, cdName: pointListData[0]?.cName });
    } else {
      // pointListData가 비어있을 경우 선택된 카메라를 초기화
      setCamera({});
      setSelectedCamera({});
    }

    // 부모 컴포넌트에서 rowState 변경 시 실행되는 초기화 함수
    const onChangeRow = () => {
      // CCTV 플러그인 객체가 존재시 destroy
      webSDKClosure.initCctvOBJ();
      if (cctv.getPluginOBJECT()?.oPlugin) {
        cctv.destroy();
      }
      // 웹 SDK 재생 상태, 재생 상태, 마이크 상태, 스피커 상태를 초기화
      setIsWebSdkPlay(false);
      setIsPlay(true);
      setBsState([0]);
      setIsMic(false);
      speaker?.stop();
      speaker?.logout();
    };
    // 초기화 함수 실행
    onChangeRow();
  }, [pointListData]);

  // selectedCamera의 변화를 감지하여 실행되는 useEffect
  useEffect(() => {
    setCamera(pointListData.find((v: any) => v.cCd === selectedCamera?.cCd));
    // 웹 SDK 재생 중이고 CCTV 객체가 존재시 플러그인 destroy
    if (isWebSdkPlay) {
      webSDKClosure.getCctvOBJ(pointPosition)?.destoryPlugin();
    }
    // 방송 상태가 '2'(에러 상태)일 경우 마이크와 방송 상태를 초기화
    if (bsState[0] === 2) {
      setIsMic(false);
      setBsState([0]);
    }
    // 웹 SDK 재생 상태, 재생 상태를 초기화하고 스피커 로그아웃
    setIsWebSdkPlay(false);
    setIsPlay(true);
    speaker?.stop();
    speaker?.logout();
  }, [selectedCamera]);

  const onClickPlay = async () => {
    setIsWebSdkPlay(true);
    setIsPlay(false);

    // 리스너 상태 변경 함수
    const stateChangeListener = (speakerData: any) => {
      const newBsState = bsState;
      newBsState[speakerData.index] = speakerData.status;
      setBsState([...newBsState]);
      setIsDelay(true);
    };

    setTimeout(async () => {
      const { bsCd, bsIp, bsPPort, bsId, bsPwd } = camera as CctvList;
      if (bsCd) {
        const newSpeakerData = {
          ip: bsIp,
          port: bsPPort,
          id: bsId,
          pwd: bsPwd,
        };
        setIsDelay(false);
        const websdkSpeaker = new WebSDKspeaker(setOpenSubModal, 0, stateChangeListener, newSpeakerData);
        await websdkSpeaker.init().then(() => {
          websdkSpeaker.login();
        });
        setSpeaker(websdkSpeaker);
        setBsState([0]);
      } else {
        setSpeaker(undefined);
      }
    }, 1000);
  };

  // 시작 버튼 클릭시
  const onClickStart = async () => {
    if (speaker && bsState) {
      speaker.start();
      setIsMic(true);
    }
  };

  // 중지 버튼 클릭시
  const onClickStop = async () => {
    if (isMic) {
      speaker?.stop();
      setIsMic(false);
    }
  };

  // 새로고침 버튼 클릭시
  const onClickRefresh = async () => {
    if (speaker && bsState) {
      setBsState([0]);
      speaker.stop();
      speaker.connChk();
    }
  };

  // 카메라 정보 객체 생성
  const cameraInfo: ICameraObject[] = pointListData.map((data: any) => ({
    wsNum: data.wsNum,
    nName: data.nName,
    cName: data.cName,
  }));

  // 방송 상태에 따른 버튼 렌더링 함수
  const renderButton = () => {
    const play = <VariableButton icon='play_arrow' text='방송 시작' onClick={onClickStart} />;
    const pause = <VariableButton icon='pause' text='방송 정지' onClick={onClickStop} />;
    const refresh = <VariableButton icon='refresh' text='새로고침' onClick={onClickRefresh} />;
    const loading = <VariableButton icon='wifi_tethering' text='...연결중' />;

    switch (bsState[0]) {
      case 1:
      case 2:
        return !isMic ? play : pause;
      case 3:
        return !isMic && refresh;
      default:
        return loading;
    }
  };

  // 방송 상태에 따른 메시지 호출 함수
  const renderText = () => {
    // 방송 상태별 메세지 객체
    const statusMessages: { [key: number]: string } = {
      0: t('연결중'),
      1: t('방송가능'),
      2: t('방송중'),
    };
    const defaultMessage = t('방송불가');
    const message = statusMessages[bsState[0]] || defaultMessage;
    const color = statusMessages[bsState[0]] ? 'positive' : 'negative';
    return <VariableMessage color={color} message={message} />;
  };

  // 모달 렌더링 함수
  const renderPortal = () => {
    return <Portal openModal={!!openModal?.status}>{openModal && <WebSdkModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>;
  };

  if (isLoading) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  // // 데이터가 없는 경우
  if (pointListData?.length === 0 || !currentPage) {
    const text = '데이터가 존재하지 않습니다.';
    return (
      <Root>
        <IssueGuide text={text} />
      </Root>
    );
  }

  if (userInfo.cctvProtocol === '2') {
    return (
      <Root ref={cctvElement}>
        <div className='selector'>
          <TunnelPointHorizontalLabel text='카메라' isStartPoint={pointPosition === 'S'} />
          <SelectBox options={pointListData} defaultOption={cName} state={selectedCamera} setState={setSelectedCamera} stateKey='cCd' />
        </div>
        <MultiVideoPlayer
          cctvDivisions={cctvDivision}
          setCctvDivisions={setCctvDivision}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          currentCameraInfo={cameraInfo}
          totalCameraInfo={cameraInfo}
          dashboard
        />
        {renderPortal()}
      </Root>
    );
  }

  /**
   * 수정자 : 한영광
   * 수정일자 : 2024.02.28
   * 수정내용 : 터널 정보 모달창 컴포넌트 리팩토링 과정에서 카메라 정보를 담는 상태변수를 콤보박스 컴포넌트에서 같이 사용하게 되면서 카메라 정보가 바뀌는 문제 발생
   *           -> 카메라 정보를 담는 상태변수와 콤보박스에서 사용할 상태변수를 분리하여 문제 해결
   */
  if (userInfo.cctvProtocol !== '2' && isWebSdkPlay) {
    return (
      <Root ref={cctvElement}>
        <div className='selector'>
          <TunnelPointHorizontalLabel text='카메라' isStartPoint={pointPosition === 'S'} />
          <SelectBox
            options={pointListData}
            defaultOption={cName}
            state={selectedCamera}
            setState={setSelectedCamera}
            stateKey='cCd'
            isWebSdk
            webSDKClosure={webSDKClosure}
            pointPosition={pointPosition}
            initiateKey={cName}
          />
        </div>
        <WebSdk
          state={camera}
          divisionCount={1}
          currentPageNumber={currentPage}
          setCurrentPageNumber={setCurrentPage}
          height='25rem'
          divPlugin={`divPlugin${pointPosition}`}
          dashboard
          webSDKClosure={webSDKClosure}
          pointPosition={pointPosition}
        />
        {speaker && (
          <div className='flex-between buttons'>
            {renderText()}
            {isDelay && renderButton()}
          </div>
        )}
        {renderPortal()}
      </Root>
    );
  }

  if (userInfo.cctvProtocol !== '2' && !isWebSdkPlay) {
    return (
      <Root ref={cctvElement}>
        <div className='selector'>
          <TunnelPointHorizontalLabel text='카메라' isStartPoint={pointPosition === 'S'} />
          <SelectBox
            options={pointListData}
            defaultOption={cName}
            state={selectedCamera}
            setState={setSelectedCamera}
            stateKey='cCd'
            isWebSdk
            webSDKClosure={webSDKClosure}
            pointPosition={pointPosition}
            initiateKey={cName}
          />
        </div>
        {isPlay && <PlayVideoButton onClickPlay={onClickPlay} />}
        {renderPortal()}
      </Root>
    );
  }

  return null;
};

export default CctvPoint;
