import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { IUser, asideState, userState } from '../atoms';
import DashboardComponent from '../components/Dashboard';
import { useTranslation, Trans } from 'react-i18next';
import { ERROR } from '../_constants';
import { toast } from 'react-toastify';
import { apiGet } from '../services/_common';

const Root = styled.div`
  color: ${({ theme }: { theme: any }) => theme.text_primary};

  @media screen and (max-width: 1023px) {
    padding-top: 0;
    > :last-child {
      padding-top: 3.5rem;
    }
  }
`;
const DashboardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  align-items: stretch;

  .icon-menu {
    cursor: pointer;
    width: 3rem;
    height: 2.5rem;
    border-radius: 0.25rem;
    padding: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    :hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
    }
  }
  .tab-list {
    position: sticky;
    left: 0.75rem;
    // top: 0.5rem;
    display: flex;
    gap: 0.5rem;
    > .tab {
      border-radius: 0.25rem;
      overflow: hidden;
      padding: 0 1rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      font-weight: ${({ theme }: { theme: any }) => theme.font_semibold};
      background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
      color: ${({ theme }: { theme: any }) => theme.selected_primary};
    }
  }

  @media screen and (min-width: 1024px) {
    display: none;
  }

  @media screen and (max-width: 1023px) {
    padding: 0.5rem;
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: ${({ theme }: { theme: any }) => theme.background};
  }
`;

const Dashboard = () => {
  const { t } = useTranslation();
  const setOpenAside = useSetRecoilState(asideState);
  const [userInfo, setUserInfo] = useRecoilState<IUser>(userState);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const oeditYn = queryParams.get('oeditYn');
  const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    if (oeditYn === 'Y' && userInfo.sCd !== '00000') {
      getSiteInfoAPI(userInfo.hCd, userInfo.sCd);
    }
  }, [oeditYn]);

  const sizeCheck = () => {
    setInnerWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', sizeCheck);
    window.screen.orientation.addEventListener('change', sizeCheck);
    return () => {
      window.removeEventListener('resize', sizeCheck);
      window.screen.orientation.removeEventListener('change', sizeCheck);
    };
  }, []);

  useEffect(() => {
    if (innerWidth >= 1024) setOpenAside(true);
  }, [innerWidth]);

  // 현장 정보 조회 API
  const getSiteInfoAPI = async (hCd: string, sCd: string) => {
    const req = { hCd, sCd };
    const res = await apiGet({ path: '/site/info', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      getSiteSetAPI(hCd, sCd, data.siteInfo);
      // getUserSideMenuAPI();
      // getMenuSiteInfoAPI(hCd, sCd);
    } else {
      // toast.error(t(ERROR));
    }
  };

  /**
   * 수정자 : 홍선영
   * 수정일자 : 2024.05.31
   * 수정내용 : 설정변경으로 리로드 시 메뉴 노출되지 않는 문제로 주석처리함
   */

  // // 현장 메뉴 조회 API
  // const getMenuSiteInfoAPI = async (hCd: string, sCd: string) => {
  //   const res = await apiGet({ path: '/menu/siteInfo', req: { hCd, sCd } });
  //   const { data, statusCode } = res.data;
  //   if (statusCode === 200) {
  //     setUserInfo((prev) => ({
  //       ...prev,
  //       userMenuList: data.filter((v: any) => v.useYn === 'Y'),
  //     }));
  //   } else {
  //     // toast.error(t(ERROR));
  //   }
  // };

  // 현장 설정 정보 조회 API
  const getSiteSetAPI = async (hCd: string, sCd: string, siteInfo: any) => {
    const req = { hCd, sCd };
    const res = await apiGet({ path: '/setting/general', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      setUserInfo((prev) => ({
        ...prev,
        hCd,
        sCd,
        eExcelYn: siteInfo?.eExcelYn,
        eListYn: siteInfo?.eListYn,
        ePrintYn: siteInfo?.ePrintYn,
        prejobtypeYn: data.generalSet.prejobtypeYn,
        elderlyAgeSet: data.generalSet.elderlyAgeSet,
        sessionTimeout: data.generalSet.sessionTimeout,
        eduPlace: data.generalSet.eduPlace,
        eduCharge: data.generalSet.eduCharge,
        eduStartTime: data.generalSet.eduStartTime,
        eduEndTime: data.generalSet.eduEndTime,
        fromHighpress: data.generalSet.fromHighpress,
        fromLowpress: data.generalSet.fromLowpress,
        toHighpress: data.generalSet.toHighpress,
        toLowpress: data.generalSet.toLowpress,
        accidentEdate: data.generalSet.accidentEdate,
        accidentSdate: data.generalSet.accidentSdate,
        accidentTday: data.generalSet.accidentTday,
      }));
    } else {
      // toast.error(t(ERROR));
    }
  };

  // // 사용자 사이드메뉴 리스트 조회 API
  // const getUserSideMenuAPI = async () => {
  //   const res = await userSideMenuGet(userInfo.userId);
  //   const { data, statusCode } = res.data;
  //   const { userMenuList } = data;
  //   if (statusCode === 200) {
  //     setUserInfo((prev) => ({
  //       ...prev,
  //       userMenuList,
  //     }));
  //   } else {
  //     // toast.error(t(ERROR));
  //   }
  // };

  // 스크롤 최상단으로 이동
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return useMemo(
    () => (
      <Root className='contents dashboard'>
        <DashboardHeader>
          <div className='tab-list'>
            <div className='icon-menu hamburger' role='presentation' onMouseDown={() => setOpenAside(true)}>
              <span className='material-symbols-rounded'>menu </span>
            </div>
            <div className='tab'>
              <Trans t={t}>대시보드</Trans>
            </div>
          </div>
        </DashboardHeader>
        <DashboardComponent isViewMode />
        {/* <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} /> */}
      </Root>
    ),
    [t]
  );
};

export default React.memo(Dashboard);
