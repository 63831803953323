import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import CountBoxInModal from './CountBoxInModal';
import { useTranslation } from 'react-i18next';
import { countSelectedAtFoldable, handleListClick } from '../../../hooks/useJobType';
import ClickableContainer from '../../s_cctv/S_cctv1/RealtimeCCTV/ClickableContainer';
import ListBoxInModal from './ListBoxInModal';

const Root = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  overflow-y: scroll;
  padding: 0.5rem;
  background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
  }
`;

interface Props {
  jobTypeLists: any;
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
  onClickSave: () => void;
}

const JobTypeBLists = ({ jobTypeLists, selected, setSelected, onClickSave }: Props) => {
  const { t } = useTranslation();

  console.log(selected, 'selected');
  const count = countSelectedAtFoldable(jobTypeLists, selected);

  return (
    <>
      <Root>
        <div className='grid-container'>
          {jobTypeLists.map((item: any) => (
            <ClickableContainer //
              key={item.key}
              button={
                <ListBoxInModal
                  data={item} //
                  isSelected={selected.includes(item.key)}
                />
              }
              onClick={() => handleListClick(item, setSelected, selected)}
            />
          ))}
        </div>
      </Root>
      <div className='footer'>
        <CountBoxInModal
          count={count} //
          totalCount={jobTypeLists.length}
        />
        <button className='button' type='button' onClick={onClickSave}>
          {t('적용')}
        </button>
      </div>
    </>
  );
};

export default JobTypeBLists;
