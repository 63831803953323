/**
 * 작성자 : 홍선영
 * 날짜 : 2023.05.25
 * 경로 : 설정관리-CCTV관리-CCTV 보기의 CCTV 플레이어 컴포넌트
 */

import React, { useEffect, useRef, useState } from 'react';
import JSMpegPlayer from '@cycjimmy/jsmpeg-player';
import Loading from '../assets/images/loading.svg';
import NoConnected from '../assets/images/noConnected.svg';

interface VideoPlayerProps {
  wsNum: string;
  options?: object;
  setPlayer: boolean;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ wsNum, options, setPlayer }) => {
  const canvasRef = useRef<HTMLDivElement | null>(null);
  let player: any = null;
  const [canPlay, setCanPlay] = useState<null | boolean>(null);
  const [connect, setConnect] = useState(true);
  const [time, setTime] = useState(0);
  const [destroyTimer, setDestroyTimer] = useState(0); // 30초 카운트 후 영상끊고 다시보기버튼 노출하기위한 카운터
  const [destroy, setDestroy] = useState<boolean>(false);

  useEffect(() => {
    const schema = process.env.REACT_APP_SOCKET_SCHEMA;
    const port = schema === 'ws' ? `:${wsNum}` : wsNum;
    const url = `${schema}://${process.env.REACT_APP_SOCKET_IP}${port}`;
    // const url = `ws://${process.env.REACT_APP_SOCKET_IP}${wsNum}`;

    if (setPlayer && canvasRef?.current) {
      player = new JSMpegPlayer.VideoElement(`#video-canvas_${wsNum}`, url, options, {
        onVideoDecode: () => {
          if (player !== null) {
            if (player?.player?.video?.canPlay) {
              setCanPlay(true);
            }
          }
        },
      });

      const cctvWrapperDiv = document?.getElementById(`video-canvas_${wsNum}`);
      if (cctvWrapperDiv) {
        const elementsToRemove = cctvWrapperDiv?.querySelectorAll('.replayBtn');
        if (elementsToRemove && elementsToRemove.length > 0) {
          elementsToRemove.forEach((element) => {
            element.remove();
          });
        }
      }

      const playerDiv = player.els.wrapper;
      if (document.getElementById('noConnectedImage') === null) {
        const disConnectDiv = document.createElement('div');
        disConnectDiv.className = 'noConnectedImage';
        disConnectDiv.style.zIndex = '998';
        disConnectDiv.style.position = 'absolute';
        disConnectDiv.style.color = '#fff';
        disConnectDiv.style.backgroundColor = '#000';
        disConnectDiv.style.borderRadius = '5px';
        disConnectDiv.style.top = '0'; // Position the disConnectDiv over the canvas
        disConnectDiv.style.width = '100%';
        disConnectDiv.style.height = '100%';
        disConnectDiv.style.display = 'flex';
        disConnectDiv.style.justifyContent = 'center';
        disConnectDiv.style.alignItems = 'center';
        disConnectDiv.style.cursor = 'default';
        disConnectDiv.style.fontSize = '2em';
        const iconSize1 = '3em';
        const materialIcon1 = document.createElement('i');
        materialIcon1.className = `material-symbols-rounded`;
        materialIcon1.textContent = 'videocam_off';
        materialIcon1.style.fontSize = iconSize1;
        disConnectDiv.appendChild(materialIcon1);

        const loadingDiv = document.createElement('div');
        loadingDiv.className = 'loadingImage';
        loadingDiv.style.zIndex = '998';
        loadingDiv.style.position = 'absolute';
        loadingDiv.style.color = '#fff';
        loadingDiv.style.backgroundColor = '#000';
        loadingDiv.style.borderRadius = '5px';
        loadingDiv.style.top = '0'; // Position the loadingDiv over the canvas
        loadingDiv.style.width = '100%';
        loadingDiv.style.height = '100%';
        loadingDiv.style.display = 'flex';
        loadingDiv.style.justifyContent = 'center';
        loadingDiv.style.alignItems = 'center';
        loadingDiv.style.cursor = 'default';
        loadingDiv.style.fontSize = '2em';
        const iconSize2 = '3em';
        const materialIcon2 = document.createElement('i');
        materialIcon2.className = `material-symbols-rounded`;
        materialIcon2.textContent = 'pending';
        materialIcon2.style.fontSize = iconSize2;
        loadingDiv.appendChild(materialIcon2);

        playerDiv?.appendChild(disConnectDiv);
        playerDiv?.appendChild(loadingDiv);
      }
    }

    if (destroy) {
      if (player && player.els.wrapper.playerInstance.source.socket !== null) {
        player.destroy();
        player = null;
        setCanPlay(null);
        setConnect(true);
        setTime(0);
      }
    }

    return () => {
      if (player && player.els.wrapper.playerInstance.source.socket !== null) {
        player.destroy();
        player = null;
        setCanPlay(null);
        setConnect(true);
        setTime(0);
      }
    };
  }, [wsNum, options, destroy]);

  useEffect(() => {
    if (destroy) {
      setDestroy(false);
      setDestroyTimer(0);
    }
  }, [wsNum]);

  useEffect(() => {
    if (canPlay) {
      // 백그라운드 svg 삭제
      const myDiv = document.getElementById(`video-canvas_${wsNum}`);

      if (myDiv) {
        const elementToRemove = document.getElementsByClassName('loadingImage');
        const elementToRemove2 = document.getElementsByClassName('noConnectedImage');
        if (elementToRemove !== null && elementToRemove2 !== null) {
          while (elementToRemove.length > 0) {
            elementToRemove[0].parentNode?.removeChild(elementToRemove[0]);
          }

          while (elementToRemove2.length > 0) {
            elementToRemove2[0].parentNode?.removeChild(elementToRemove2[0]);
          }
        }
      }
    }
  }, [canPlay]);

  useEffect(() => {
    if (!connect) {
      // 로딩이미지 svg 삭제
      const myDiv = document.getElementById(`video-canvas_${wsNum}`);
      if (myDiv) {
        const elementToRemove = document.getElementsByClassName('loadingImage');
        if (elementToRemove !== null) {
          while (elementToRemove.length > 0) {
            elementToRemove[0].parentNode?.removeChild(elementToRemove[0]);
          }
        }
      }
    }
  }, [connect]);

  // 시간 경과 체크를 위한 useEffect
  useEffect(() => {
    if (canPlay) {
      setTime(0); // canPlay가 true 로 전환됐으면 time 0으로 리셋
      return undefined;
    }
    if (!canPlay && time >= 15) {
      setConnect(false);
      return undefined;
    }

    const tick = setTimeout(() => {
      setTime(time + 1);
    }, 1000);

    return () => clearTimeout(tick);
  }, [time, canPlay]);

  // 시간 경과 체크를 위한 useEffect2
  useEffect(() => {
    if (destroyTimer >= 30) {
      setDestroy(true);
      return undefined;
    }

    const tick2 = setTimeout(() => {
      setDestroyTimer(destroyTimer + 1);
    }, 1000);

    return () => clearTimeout(tick2);
  }, [destroyTimer]);

  useEffect(() => {
    if (destroy) {
      const myDiv = document?.getElementById(`video-canvas_${wsNum}`);
      if (myDiv) {
        const replayBtnDiv = document.createElement('button');
        myDiv.style.backgroundColor = '#000';
        myDiv.style.borderRadius = '10px';
        replayBtnDiv.className = 'replayBtn';
        replayBtnDiv.style.backgroundColor = '#000';
        replayBtnDiv.style.zIndex = '999';
        replayBtnDiv.style.position = 'relative';
        replayBtnDiv.style.color = 'white';
        replayBtnDiv.style.padding = '0.1rem 0.5rem';
        replayBtnDiv.style.borderRadius = '5px';
        replayBtnDiv.style.top = '0'; // Position the replayBtnDiv over the canvas
        replayBtnDiv.style.width = '100%';
        replayBtnDiv.style.height = '100%';
        replayBtnDiv.style.fontSize = '2em';
        const iconSize = '3em';
        const materialIcon = document.createElement('i');
        materialIcon.className = `material-symbols-rounded`;
        materialIcon.textContent = 'play_circle';
        materialIcon.style.fontSize = iconSize;
        replayBtnDiv.appendChild(materialIcon);
        myDiv?.appendChild(replayBtnDiv);

        replayBtnDiv.addEventListener('click', function () {
          setDestroy(false);
          setDestroyTimer(0);
        });
      }
    }
  }, [destroy]);

  const requestFullscreen = () => {
    const { current } = canvasRef;
    if (current) {
      if (current.requestFullscreen) {
        current.requestFullscreen();
      } else if ((current as any).mozRequestFullScreen) {
        /* Firefox */
        (current as any).mozRequestFullScreen();
      } else if ((current as any).webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        (current as any).webkitRequestFullscreen();
      } else if ((current as any).msRequestFullscreen) {
        /* IE/Edge */
        (current as any).msRequestFullscreen();
      }
    }
  };

  // 전체화면 상태에서 더블클릭시 풀스크린 해제
  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if ((document as any).mozCancelFullScreen) {
      /* Firefox */
      (document as any).mozCancelFullScreen();
    } else if ((document as any).webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      (document as any).webkitExitFullscreen();
    } else if ((document as any).msExitFullscreen) {
      /* IE/Edge */
      (document as any).msExitFullscreen();
    }
  };

  const handleDoubleClick = () => {
    if (!document.fullscreenElement) {
      requestFullscreen();
    } else if (document.fullscreenElement) {
      exitFullscreen();
    }
  };

  if (!setPlayer) return null;
  return (
    <div className='cctvWrapper'>
      <div id={`video-canvas_${wsNum}`} ref={canvasRef} role='presentation' onDoubleClick={handleDoubleClick} />
    </div>
  );
};

export default VideoPlayer;
