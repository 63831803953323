import styled from 'styled-components';

export const TuiGridTwoColumnStyle = styled.div`
  &.hideRoot {
    visibility: hidden;
    position: absolute;
  }
  .cctvWrapper {
    height: 40rem;
  }
  .cctvWrapperHidden {
    height: 0.1rem;
    width: 0.1rem;
  }
  width: 100%;
  border-radius: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .tui-container {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    margin-left: 0.5rem;
  }
  .tableWrapper {
    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
  input[type='checkbox'],
  input[type='radio'] {
    margin: 0;
    outline: none;
  }
  .inputFormsWrapper {
    border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    padding: 0.5rem;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .input8rem {
    input {
      width: 8rem;
    }
  }
  .inputFormsWrapper.smallTab {
    flex-direction: row;
    padding: 0 0.5rem;
    font-weight: 500;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 0rem !important;
    }
    .tab {
      /* width: fit-content; */
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
      cursor: pointer;
      height: 3.5rem;
      border-bottom: 2px solid transparent;
    }
    .activeTab {
      font-weight: 700;
      color: ${({ theme }: { theme: any }) => theme.selected_primary};
      border-bottom: 2px solid ${({ theme }: { theme: any }) => theme.selected_primaty};
    }
  }
  .formTitle {
    padding: 0 0.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .end {
    justify-content: end;
    > button {
      margin-left: 1rem;
    }
  }

  .thead > .tr {
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .spaceBetween {
      justify-content: space-evenly;
    }
  }

  .table {
    height: 64rem;
    padding: 0;

    > .tbody > .tr {
      padding: 0.5rem 1rem;
    }
  }

  .grid {
    display: grid;
    margin-bottom: 1rem;
    align-items: center;
    min-height: 3.4rem;
  }

  .grid100,
  .grid100T,
  .grid60,
  .grid50,
  .grid30 {
    > div > div {
      display: grid;
      grid-template-columns: 9rem 1fr;
      align-items: center;
    }
  }

  .grid100T {
    grid-template-columns: 9rem 1fr;
  }

  .grid50,
  .grid60 {
    grid-template-columns: 1fr 1fr;
  }

  .grid50 {
    grid-gap: 1rem;
  }

  .grid30 {
    grid-template-columns: 1.5fr 0.7fr 0.8fr;
  }

  .plainText {
    grid-template-columns: 9rem 1fr;

    > :last-child {
      display: block;
    }
  }

  .marginBottomNone {
    margin-bottom: 0;
  }

  .rtspCctvViewWrapper {
    flex-grow: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 40rem;
  }
  .webSdkCctvViewWrapper {
    /* background-color: black; */
    flex-grow: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    canvas {
      width: 100%;
      height: auto;
      border-radius: 0.5rem;
      overflow: hidden;
    }
  }

  ul li {
    width: 100% !important;
    max-width: none !important;
  }

  .emptyData {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    padding-bottom: 4rem;
    img {
      width: 16rem;
      -webkit-user-drag: none;
    }
    p {
      color: ${({ theme }: { theme: any }) => theme.text_primary};
    }
  }
`;
