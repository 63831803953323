import { Dispatch, SetStateAction } from 'react';
import { WebSDKcctv, emptyCameraStatus } from '../utils/webSDKcctvClass';

export const webSDKFunc = () => {
  let cctvOBJ: any | WebSDKcctv;
  const cctvObjects = {
    S: cctvOBJ,
    E: cctvOBJ,
  };
  const initCctvOBJ = () => {
    cctvObjects.S = undefined;
    cctvObjects.E = undefined;
  };
  const setCctvOBJ = (obj: WebSDKcctv, pointPosition: string) => {
    isStart(pointPosition) ? (cctvObjects.S = obj) : (cctvObjects.E = obj);
  };
  const getCctvOBJ = (pointPosition: string) => {
    return isStart(pointPosition) ? cctvObjects.S : cctvObjects.E;
  };
  const isStart = (pointPosition: string) => {
    return pointPosition === 'S';
  };

  return {
    setCctvOBJ,
    getCctvOBJ,
    initCctvOBJ,
  };
};

// JSON 문자열인지 아닌지 확인하는 함수
function isJSONString(str: string): boolean {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
}

// 다운로드 경로 JSON 문자열 변환 함수
export const initPath = (path: string | null) => {
  if (path === null) {
    return null;
  }
  return isJSONString(path) ? JSON.parse(path).replace(/""/g, '"') : path.replace(/"/g, '');
};

// CCTV 재생 시, 선택한 카메라를 윈도우에서 제거하고, 메뉴목록 UI를 업데이트 하는 함수
export const removeCameraWindow = async (
  cctvOBJ: WebSDKcctv,
  selectedCameraInfo: SelectedCctvCameraInfo,
  setSelectedCameraInfo: Dispatch<SetStateAction<SelectedCctvCameraInfo>>,
  nvrList: IMainMenu[],
  setNvrList: Dispatch<SetStateAction<IMainMenu[]>>
) => {
  if (selectedCameraInfo.windowIndex !== null && cctvOBJ) {
    const stopRes = await cctvOBJ?.stopWithIndex(selectedCameraInfo?.windowIndex);
    if (stopRes !== undefined) {
      setSelectedCameraInfo(emptyCameraStatus);

      // 메뉴 목록 UI 업데이트
      const updateNvrList = nvrList.map((el: IMainMenu) => {
        const newSubList = el.subList.map((camera: any) =>
          camera.nCd === selectedCameraInfo.nCd && camera.cCd === selectedCameraInfo.cCd //
            ? {
                cCd: camera.cCd,
                cName: camera.cName,
                nCd: camera.nCd,
                stream: camera.stream,
                channelNum: camera.channelNum,
                ip: camera.ip,
                port: camera.port,
                pPort: camera.pPort,
                isPlaying: false,
                isRecording: false,
              }
            : camera
        );
        return { ...el, subList: newSubList };
      });
      setNvrList(updateNvrList);
    }
  }
};

export const initNvrList = (nvrList: IMainMenu[]) => {
  const updateNvrList = nvrList.map((el: IMainMenu) => {
    const newSubList = el.subList.map((camera: ISubMenu) =>
      camera.isPlaying //
        ? { ...camera, isPlaying: false }
        : camera
    );
    return { ...el, subList: newSubList };
  });
  return updateNvrList;
};
