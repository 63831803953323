/**
 * 작성자 : 홍선영
 * 날짜 : 2023.03.29
 * 기능 : 특정 노드로 스크롤을 이동시키는 함수 (예: 테이블의 로우를 클릭했을 때 해당함수를 실행하여 인풋박스(특정노드)로 스크롤이동)
 */

export const scrollToNode = (node: React.RefObject<any>) => {
  if (node.current !== null) {
    node.current.scrollIntoView({ behavior: 'smooth' });
  }
};
