import React, { SetStateAction, useEffect, useState, Dispatch, useRef, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v1 } from 'uuid';
import { SelectBoxSmDropStyle } from '../assets/styles/SelectBoxSmDrop';
import * as cctv from '../utils/cctv';
import { toast } from 'react-toastify';
import LeftedToast from './cctv/LeftedToast';

interface ISelectBox {
  options: any; // 셀렉트박스 배열 [{ subCd: "코드값", cdName:"코드이름" }, ... ]
  defaultOption: string; // 셀렉트 디폴트옵션
  state: any; //  state값
  setState: Dispatch<SetStateAction<any>>; //  useState set액션
  stateKey?: any;
  initiateKey?: any;
  grantChk?: boolean;
  disabled?: boolean;
  getBorderStyle?: any; // 선택값이 없을 때 보더 스타일
  setTableLinesAPI?: (el: any) => void;
  filterbar?: 'filter-1-left' | 'filter-1-leftToCenter' | 'filter-1-center' | 'filter-2-left' | 'filter-2-leftToCenter' | 'filter-2-center';
  optionHeight?: 'height-lg' | 'height-md' | 'height-base' | 'height-sm';
  dropDownWidth?: 'fit-content' | 'expand-content' | 'expand-content-md' | 'expand-content-sm' | 'expand-content-xs';
  align?: 'left' | 'right';
  rsearch?: boolean; // 보기설정으로 사용하는건지 구분
  webSDKClosure?: any;
  pointPosition?: string;
  hideWebSdk?: boolean;
  isWebSdk?: boolean;
  textLabel?: string;
  clickAble?: boolean; // 클릭 시 드롭다운 가능여부 (true: 드롭다운 안됨, false: 드롭다운 됨)
  toastContent?: string; // 드롭다운 안되는 경우 보여줄 토스트 메시지
  toastLocation?: 'center' | 'left';
}

const SelectBox = ({
  options,
  defaultOption = '',
  state,
  setState,
  stateKey,
  initiateKey,
  grantChk,
  disabled,
  getBorderStyle,
  setTableLinesAPI,
  filterbar = 'filter-2-center',
  dropDownWidth = 'fit-content',
  align = 'left',
  optionHeight = 'height-base',
  rsearch,
  webSDKClosure,
  pointPosition,
  hideWebSdk,
  isWebSdk,
  textLabel,
  clickAble,
  toastContent,
  toastLocation = 'center',
}: ISelectBox) => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>({ type: stateKey, [stateKey]: state[stateKey], cdName: defaultOption });
  const [optionIndex, setOptionIndex] = useState(0);
  const [dropdownPosition, setDropdownPosition] = useState<'bottomToTop' | 'topToBottom' | null>(null);

  useEffect(() => {
    if (initiateKey !== undefined && options !== undefined && options.length > 0) {
      // initiate key prop이 변경되었을 때 셀렉트박스 cdName값(선택된옵션)을 다시 세팅
      const findCdName = options.find((el: any) => el[stateKey] === initiateKey || el.cdName === initiateKey);
      if (findCdName !== undefined) {
        setSelectedOption({ type: stateKey, [stateKey]: findCdName[stateKey], cdName: findCdName.cdName });
      } else {
        setSelectedOption({ type: stateKey, [stateKey]: state[stateKey] || '', cdName: t(`${defaultOption}`) || t('미선택') });
      }
    }
  }, [initiateKey, options]);

  useEffect(() => {
    if (rsearch && selectedOption[stateKey]) {
      setState({ type: stateKey, [stateKey]: selectedOption[stateKey], cdName: selectedOption.cdName });
    } else if (isWebSdk && selectedOption[stateKey] !== undefined) {
      setState({ ...selectedOption, type: stateKey, [stateKey]: selectedOption[stateKey], cdName: selectedOption.cdName });
    } else if (!rsearch && !isWebSdk && selectedOption[stateKey] !== undefined) {
      setState({ type: stateKey, [stateKey]: selectedOption[stateKey], cdName: selectedOption.cdName });
    }
  }, [selectedOption]);

  // 콤보박스 바깥클릭 감지
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  // 콤보박스 열린상태에서 바깥 클릭했을 때 창닫기
  const handleClickOutside = (e: any) => {
    if (wrapperRef && !wrapperRef.current?.contains(e.target)) setIsDropdownOpen(false);
    else setIsDropdownOpen(true);
  };

  // 콤보박스 클릭시 옵션리스트 열기
  const onClickDropdown = () => {
    if (clickAble && toastContent) {
      if (toastLocation === 'left') {
        toast(<LeftedToast message={toastContent} />, { position: 'top-left', autoClose: 3000 });
      } else {
        toast.warn(t(toastContent));
      }
      return;
    }
    if (!grantChk) {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  // 콤보박스에서 방향키 위아래로 움직였을 때 옵션이동
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLDivElement>, option: any) => {
    const key = e.key || e.keyCode;
    if ((key === 'ArrowUp' || key === 38) && optionIndex > 0) {
      //  방향키 위
      setOptionIndex((prev) => prev - 1);
    }
    if ((key === 'ArrowDown' || key === 40) && optionIndex < option.length - 1) {
      //  방향키 아래
      setOptionIndex((prev) => prev + 1);
    }
    if (key === 'Enter' || key === 13) {
      //  엔터
      setSelectedOption(option[optionIndex]);
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  //  메뉴배열 노출
  const renderAllDropdownMenu = () => {
    return options?.map((el: any) => (
      <li
        className={stateKey && state[stateKey] === el[stateKey] ? 'selectedOption' : undefined}
        key={`${v1()}`}
        role='presentation'
        onClick={() => {
          setSelectedOption(el);
          if (setTableLinesAPI) {
            setTableLinesAPI(el);
          }
          setIsDropdownOpen(!isDropdownOpen);
        }}
      >
        {t(el.cdName)}
      </li>
    ));
  };

  const emptyData = !options || options?.length === 0;

  const renderCdName = () => {
    if (emptyData) return `${t('목록 없음')}`;
    if (selectedOption.cdName) return t(selectedOption.cdName);
    return t(defaultOption);
  };

  useLayoutEffect(() => {
    const updatePosition = () => {
      if (wrapperRef.current) {
        const rect = wrapperRef.current.getBoundingClientRect();
        if (window.innerHeight - 72 < rect.bottom && rect.top - 72 > rect.height) {
          setDropdownPosition('bottomToTop');
        } else {
          setDropdownPosition('topToBottom');
        }
      }
    };
    updatePosition();
    if (cctv.getPluginOBJECT()?.oPlugin && hideWebSdk) {
      if (isDropdownOpen) {
        cctv.hideWndAll();
      } else {
        cctv.showWndAll();
      }
    }
    if (pointPosition && webSDKClosure?.getCctvOBJ(pointPosition)) {
      const hide = () => webSDKClosure.getCctvOBJ(pointPosition)?.hideWnd();
      const show = () => webSDKClosure.getCctvOBJ(pointPosition)?.showWnd();
      isDropdownOpen ? hide() : show();
    }
  }, [isDropdownOpen]);

  return (
    <SelectBoxSmDropStyle isDropdownOpen={isDropdownOpen} className={textLabel ? 'widthLabel' : ''}>
      {textLabel && <label htmlFor='tatGubun'>{t(textLabel)}</label>}
      <ul>
        <li className={`defaultOption ${emptyData ? 'disabled' : ''} ${isDropdownOpen ? 'opened' : ''}`} role='presentation' onClick={onClickDropdown} style={getBorderStyle}>
          <span>{renderCdName()}</span>
          <div>
            <span className='material-symbols-rounded'>keyboard_arrow_down</span>
            {/* <IoChevronDownSharp /> */}
          </div>
        </li>
        {!disabled ? (
          <div className={`optionBox ${isDropdownOpen && options?.length > 0 ? 'on' : 'off'} ${filterbar} ${optionHeight} ${dropDownWidth} ${dropdownPosition} ${align}`} ref={wrapperRef}>
            {renderAllDropdownMenu()}
          </div>
        ) : null}
      </ul>
      <div className={` ${isDropdownOpen && 'optionBox-filter'}`} />
    </SelectBoxSmDropStyle>
  );
};

export default React.memo(SelectBox);
