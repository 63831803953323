/**
 * 작성자 : 홍선영
 * 날짜 : 2023.05.18
 * 기능 : 공통코드를 조회해서 setState하는 함수.
 *        grCd, setState, isAllIncluded 인자로 받으며, isAllIncluded 는 공통코드항목에 '전체'를 포함하지 여부임.
 *
 * 수정자 : 한영광
 * 날짜 : 2023.05.31
 * 수정내용 : etc1~5로 분류 처리를 위해 etcKey(ex.etc1 or etc2), etcData 인자 추가하고 필터링 추가함.
 */

import { apiGet } from '../services/_common';
import { Dispatch, SetStateAction } from 'react';
import { IComCdList } from 'customTypes';
import { arraySortByAscdOrder } from './arraySortByAscdOrder';
import { t } from 'i18next';

export const setComCdListState = async (comCd: string, setState: Dispatch<SetStateAction<IComCdList[]>>, isAllIncluded: boolean, etcKey?: string, etcData?: string) => {
  const req = { grCd: comCd };
  const res = await apiGet({ path: '/code/detail', req });
  const { statusCode, data } = res.data;
  if (statusCode === 200) {
    let newArr = data.comCdList;
    if (etcKey) newArr = newArr.filter((v: any) => v[etcKey] === etcData);
    const useYList = newArr.filter((el: any) => el.useYn === 'Y');
    const newArray = useYList.map((el: any) => ({ type: el.grCd, [el.grCd]: el.subCd, cdName: el.cdName }));
    newArr = newArray;
    if (isAllIncluded) {
      const defaultObject = { type: comCd, [comCd]: 'A', cdName: t('전체'), cdSort: 0 };
      newArr = [defaultObject, ...newArray];
    }
    setState(arraySortByAscdOrder(newArr, 'cdSort'));
  }
  // else {
  //   // toast.error(t(ERROR));
  // }
};
