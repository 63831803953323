import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { ContentsContainerRoot } from '../../../assets/styles/ContentsContainerRoot';
import TuiGridImage from '../../../components/TuiGridImage';
import WorkerImageSearchOption from './WorkerImageSearchOption';
import useSetListCount from '../../../utils/useSetListCount';
import { logPost } from '../../../services/log';
import { useRecoilValue } from 'recoil';
import { IUser, userState } from '../../../atoms';

const Root = styled(ContentsContainerRoot)``;

const WorkerImage = () => {
  const userInfo = useRecoilValue<IUser>(userState);
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [tableState, setTableState] = useState<any[]>([]);
  const { listCount, setListCount } = useSetListCount('15'); // 검색줄수설정 훅
  const [refetchFlag, setRefetchFlag] = useState(false);
  const loading = isFetching;

  useEffect(() => {
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '태블릿 근로자 관리 > 근로자 관리 > 근로자 사진',
      action: '조회',
      etc: ``,
    });
  }, []);

  return (
    <div className='content-container oneColumn'>
      <Root loading={loading}>
        <WorkerImageSearchOption
          setIsFetching={setIsFetching}
          setIsError={setIsError}
          tableState={tableState}
          setTableState={setTableState}
          listCount={listCount}
          setListCount={setListCount}
          refetchFlag={refetchFlag}
          setRefetchFlag={setRefetchFlag}
        />
        <TuiGridImage data={tableState} listCount={listCount} loading={loading} error={isError} />
      </Root>
    </div>
  );
};

export default WorkerImage;
