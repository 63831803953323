/**
 * 작성자 : 한영광
 * 날짜 : 2023.05.10
 * 경로 : 마스터일반관리 - 현장연결 페이지
 *
 * 작성자 : 홍선영
 * 날짜 : 2023.05.19
 * 경로 : 검색줄수, 운영상태, 사용유무 옵션값을 공통코드 조회한 값으로 변경하고, SelectBox > ComCdSelectBox에 맞추어 수정
 */
import { useEffect, useState, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { IUser, pageInfoState, userState } from '../../atoms';
import { BtnGhost } from '../../components/Button';
import { COMCD_S_STATUS, COMCD_USE_YN, INIT_USE_YN_A, LIST_COUNT } from '../../_constants';
import { SearchOptions } from '../../assets/styles/SearchOptions';
import Tab from '../../components/Tab';
import Input from '../../components/Input';
import SelectBox from '../../components/SelectBox';
import { logPost } from '../../services/log';
import TuiGrid from '../../components/Table/TuiGrid';
import { useTranslation } from 'react-i18next';
import i18n from '../../translation/i18n';
import { apiGet } from '../../services/_common';
import { ynFilter } from '../../utils/ynFilter';
import { useFetchCommonCodeList } from '../../services/useSetCodeListInSelectBoxForm';

const Root = styled.div`
  .tui-container {
    flex-grow: 1;
    overflow: hidden;
    margin-left: 0.5rem;
  }
  .textBtnGroup button {
    padding: 0 0.75rem;
    width: 4.5rem;
    height: 2.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
  }

  .table {
    max-height: 65vh;
  }
  .tr {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .mtName,
    .mdName,
    .mrName,
    .bigo {
      justify-content: left;
    }
  }
`;

interface ITableObject {
  index: number;
  mtCd: string;
  hCd: string;
  hName: string;
  sCd: string;
  sName: string;
  sStatus: string;
  sDate: string;
  fDate: string;
  bigo: string;
  useYn: string;
}

const Mcommon4 = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useRecoilState<IUser>(userState);
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  const [columns, setColumns] = useState<any[]>([]);
  const [searchOption, setSearchOption] = useState({ hName: '', sName: '', bigo: '' });
  const [listCount, setListCount] = useState({ type: LIST_COUNT, [LIST_COUNT]: '15', cdName: '15' });
  const [sStatus, setSStatus] = useState({ type: COMCD_S_STATUS, [COMCD_S_STATUS]: 'A', cdName: t('전체') });
  const [useYn, setUseYn] = useState(INIT_USE_YN_A);
  const [excelBtn, setExcelBtn] = useState(false);
  const [tableState, setTableState] = useState<ITableObject[]>([]);
  const [initTableState, setInitTableState] = useState<ITableObject[]>([]);
  const { data: useYnComCdListWithAll } = useFetchCommonCodeList(COMCD_USE_YN, true); // 사용여부 공통코드 목록 (검색조건에서 사용, 전체포함)
  const { data: sStatusComCdListWithAll } = useFetchCommonCodeList(COMCD_S_STATUS, true); // 운영상태 공통코드 목록 (검색조건에서 사용, 전체포함)
  const { data: listCountComCdList } = useFetchCommonCodeList(LIST_COUNT, false); // 검색줄수 공통코드 목록 (검색조건에서 사용)

  useEffect(() => {
    getSiteAPI().then((res: any) => {
      if (res.status === 200) {
        if (res.status === 200) {
          logPost({
            hCd: userInfo.hCd,
            sCd: userInfo.sCd,
            userId: userInfo.userId,
            menu: '현장 연결',
            action: '조회',
            etc: ``,
          });
        }
      }
    });
    setColumns([
      {
        header: t('본사코드'),
        name: 'hCd',
        align: 'center',
        // rowSpan: true,
        sortable: true,
        width: 120,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('본사명'),
        name: 'hName',
        // rowSpan: true,
        sortable: true,
        renderer: { classNames: ['text_secondary'] },
        minWidth: 120,
      },
      {
        header: t('현장코드'),
        name: 'sCd',
        align: 'center',
        sortable: true,
        minWidth: 80,
      },
      {
        header: t('현장명'),
        name: 'sName',
        sortable: true,
        // filter: 'select',
        renderer: { classNames: ['text_secondary'] },
        minWidth: 160,
      },
      {
        header: t('운영상태'),
        name: 'sStatusCdName',
        align: 'center',
        sortable: true,
        minWidth: 80,
        // filter: 'select'
      },
      {
        header: t('시공일자'),
        name: 'sDate',
        align: 'center',
        sortable: true,
        minWidth: 120,
      },
      {
        header: t('종료일자'),
        name: 'fDate',
        align: 'center',
        sortable: true,
        minWidth: 120,
      },
      {
        header: t('사용유무'),
        name: 'useYnCdName',
        align: 'center',
        sortable: true,
        minWidth: 80,
        // filter: 'select',minWidth: 120,
      },
      {
        header: t('비고'),
        name: 'bigo',
        sortable: true,
        minWidth: 160,
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    // 검색옵션 변경됐을 때 필터링 처리
    // 필터링 기준
    const filterOptions = {
      hName: searchOption.hName,
      sName: searchOption.sName,
      bigo: searchOption.bigo,
    };

    // 필터링된 어레이 리턴, 대소문자구분X
    const filteredArray = initTableState.filter((item: any) => {
      return (
        item.hName?.toLowerCase()?.includes(filterOptions.hName?.toLowerCase()) &&
        item.sName?.toLowerCase()?.includes(filterOptions.sName?.toLowerCase()) &&
        item.bigo?.toLowerCase()?.includes(filterOptions.bigo?.toLowerCase())
      );
    });

    const sStatusFilter = ynFilter(filteredArray, 'sStatus', sStatus[COMCD_S_STATUS]);
    const result = ynFilter(sStatusFilter, 'useYn', useYn[COMCD_USE_YN]);

    if (result.length > 0) {
      setTableState(result);
    } else {
      setTableState([]);
    }
  }, [searchOption, useYn[COMCD_USE_YN], sStatus[COMCD_S_STATUS]]);

  // 현장 정보 리스트 조회 API
  const getSiteAPI = async () => {
    const res = await apiGet({ path: '/site' });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const newTableState: Array<any> = [];
      for (const v of data.siteInfoList) {
        newTableState.push({
          hCd: v.hCd,
          hName: v.hName,
          sCd: v.sCd,
          sName: v.sName,
          sStatus: v.sStatus,
          sStatusCdName: v.sStatus === 'Y' ? t('운영중') : t('종료'),
          sDate: v.sDate,
          fDate: v.fDate,
          bigo: v.bigo,
          useYn: v.useYn,
          useYnCdName: v.useYn === 'Y' ? t('사용') : t('미사용'),
        });
      }
      setTableState(newTableState);
      setInitTableState(newTableState);
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  // 현장 정보 조회 API
  const getSiteInfoAPI = async (hCd: string, sCd: string) => {
    const req = { hCd, sCd };
    const res = await apiGet({ path: '/site/info', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      getSiteSetAPI(hCd, sCd, data.siteInfo);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 현장 설정 정보 조회 API
  const getSiteSetAPI = async (hCd: string, sCd: string, siteInfo: any) => {
    const req = { hCd, sCd };
    const res = await apiGet({ path: '/setting/general', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      setUserInfo((prev) => ({
        ...prev,
        hCd,
        sCd,
        hName: siteInfo.hName,
        sName: siteInfo.sName,
        eExcelYn: siteInfo.eExcelYn,
        eListYn: siteInfo.eListYn,
        ePrintYn: siteInfo.ePrintYn,
        prejobtypeYn: data.generalSet.prejobtypeYn,
        elderlyAgeSet: data.generalSet.elderlyAgeSet,
        sessionTimeout: data.generalSet.sessionTimeout,
        eduPlace: data.generalSet.eduPlace,
        eduCharge: data.generalSet.eduCharge,
        eduStartTime: data.generalSet.eduStartTime,
        eduEndTime: data.generalSet.eduEndTime,
        fromHighpress: data.generalSet.fromHighpress,
        fromLowpress: data.generalSet.fromLowpress,
        toHighpress: data.generalSet.toHighpress,
        toLowpress: data.generalSet.toLowpress,
        accidentEdate: data.generalSet.accidentEdate,
        accidentSdate: data.generalSet.accidentSdate,
        accidentTday: data.generalSet.accidentTday,
        cctvProtocol: data.generalSet.cctvProtocol,
        bScreenMode: data.generalSet.bScreenMode,
      }));
    } else {
      // toast.error(t(ERROR));
    }
  };

  // // 본사 메뉴 조회 API
  // const getMenuHeadInfoAPI = async (hCd: string) => {
  //   const res = await menuHeadInfoId(hCd);
  //   const { data, statusCode } = res.data;
  //   if (statusCode === 200) {
  //     setUserInfo((prev) => ({
  //       ...prev,
  //       userMenuList: data.filter((v: any) => v.useYn === 'Y'),
  //     }));
  //   } else {
  //     // toast.error(t(ERROR));
  //   }
  // };

  // // 현장 메뉴 조회 API
  // const getMenuSiteInfoAPI = async (hCd: string, sCd: string) => {
  //   const res = await menuSiteInfoId(hCd, sCd);
  //   const { data, statusCode } = res.data;
  //   if (statusCode === 200) {
  //     setUserInfo((prev) => ({
  //       ...prev,
  //       userMenuList: data.filter((v: any) => v.useYn === 'Y'),
  //     }));
  //   } else {
  //     // toast.error(t(ERROR));
  //   }
  // };

  const onClickRow = (rowData: any, columnName: string) => {
    /**
     * 수정자 : 한영광
     * 수정일자 : 2023.12.05
     * 수정내용 : 현장 정보 조회, 사이드 메뉴 조회 API 요청은 이제 Aside 컴포넌트에서만 하도록 수정 (userInfo.hCd, userInfo.sCd 상태값이 변경되면 API 요청함)
     */
    if (columnName === 'hCd' || columnName === 'hName') {
      setUserInfo((prev) => ({
        ...prev,
        hCd: rowData.hCd,
        hName: rowData.hName,
        sCd: '00000',
        sName: '',
      }));
      toast.info(`${t('본사')}(${rowData.hCd}) ${t('코드 전역변수에 저장')}`);
    } else {
      setUserInfo((prev) => ({
        ...prev,
        hCd: rowData.hCd,
        hName: rowData.hName,
        sCd: rowData.sCd,
        sName: rowData.sName,
      }));
      toast.info(`${t('본사')}(${rowData.hCd}), ${t('현장')}(${rowData.sCd}) ${t('코드 전역변수에 저장')}`);
    }
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    setSearchOption((prev: any) => ({ ...prev, hName: '', sName: '', bigo: '' }));
    setUseYn(INIT_USE_YN_A);
    setSStatus({ type: COMCD_S_STATUS, [COMCD_S_STATUS]: 'A', cdName: t('전체') });
  };

  const onClickTab = (mrCd: string) => {
    setCurrentTabMrCd(mrCd);
  };
  const componentRef = useRef<HTMLDivElement>(null);
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight, componentRef.current?.offsetWidth]);
  return (
    <div className='contents'>
      <Tab tabList={tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      <div className='content-container oneColumn'>
        <Root>
          <SearchOptions>
            {/* {isBottom && (
              <FloatingButtonToTop>
                <button type='button' onClick={() => scrollToNodeTop(tableRef)}>
                  <IoChevronUpSharp size={20} style={{ stroke: 'white' }} />
                </button>
              </FloatingButtonToTop>
            )} */}
            <div className='inputsWrapper'>
              <div className='flex-basic textBtnGroup'>
                <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col withLabelComCf'>
                  <label htmlFor='useYn'>{t('검색줄수')}</label>
                  <SelectBox
                    options={listCountComCdList}
                    defaultOption={listCount.cdName}
                    stateKey={LIST_COUNT}
                    state={listCount}
                    setState={setListCount}
                    initiateKey={listCount[LIST_COUNT]}
                    filterbar='filter-1-left'
                  />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col withLabelComCf'>
                  <label htmlFor='sStatus'>{t('운영상태')}</label>
                  <SelectBox
                    options={sStatusComCdListWithAll}
                    defaultOption={sStatus.cdName}
                    stateKey={COMCD_S_STATUS}
                    state={sStatus}
                    setState={setSStatus}
                    initiateKey={sStatus[COMCD_S_STATUS]}
                    filterbar='filter-1-leftToCenter'
                  />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col withLabelComCf'>
                  <label htmlFor='useYn'>{t('사용유무')}</label>
                  <SelectBox
                    options={useYnComCdListWithAll}
                    defaultOption={useYn.cdName}
                    stateKey={COMCD_USE_YN}
                    state={useYn}
                    setState={setUseYn}
                    initiateKey={useYn[COMCD_USE_YN]}
                    filterbar='filter-1-center'
                  />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <Input label='' placeholder={t('본사명')} type='text' id='hName' name='hName' state={searchOption} setState={setSearchOption} />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <Input label='' placeholder={t('현장명')} type='text' id='sName' name='sName' state={searchOption} setState={setSearchOption} />
                </div>
              </div>

              <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <Input label='' placeholder={t('비고')} type='text' id='bigo' name='bigo' state={searchOption} setState={setSearchOption} maxLength={200} />
                </div>
              </div>
            </div>
          </SearchOptions>
          <div ref={componentRef} className='tui-container'>
            <TuiGrid
              data={tableState}
              columns={columns}
              perPage={Number(listCount[LIST_COUNT])}
              excelBtn={excelBtn}
              setExcelBtn={setExcelBtn}
              usePagenation
              onClickRow={onClickRow}
              height={tuiHeight}
              frozenCount={1}
            />
          </div>
        </Root>
      </div>
    </div>
  );
};

export default Mcommon4;
