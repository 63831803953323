/**
 * 작성자 : 홍선영
 * 날짜 : 2023.05.25
 * 경로 : 현장 CCTV - CCTV보기
 */

import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { pageInfoState } from '../../atoms';
import Tab from '../../components/Tab';
import SiteCCTV from './S_cctv1/SiteCCTV';

const Scctv1 = () => {
  const navigate = useNavigate();
  const tabInfo = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);
  const size = useOutletContext<any>();

  useEffect(() => {
    if (currentTabMrCd === '002') {
      navigate('/realtime_cctv');
    }
    if (currentTabMrCd === '003') {
      navigate('/play_cctv');
    }
    if (currentTabMrCd === '004') {
      navigate('/realtime_cctv?type=infra');
    }
  }, [currentTabMrCd, navigate]);

  const onClickTab = (mrCd: string) => {
    setCurrentTabMrCd(mrCd);
  };

  const renderContent = () => {
    switch (currentTabMrCd) {
      case '001':
        return <SiteCCTV />;
      case '002':
        return null;
      case '003':
        return null;
      case '004':
        return null;
      default:
        return <SiteCCTV />;
    }
  };

  return (
    <div className='contents'>
      <Tab tabList={tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      {renderContent()}
    </div>
  );
};

export default Scctv1;

// const { userMenuList } = userInfo;

// useEffect(() => {
//   const arr: any[] = [];
//   userMenuList &&
//     userMenuList?.map((v: any) => {
//       const result = tabInfo?.tabList?.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
//       if (result) {
//         arr.push(result);
//         setNewTabList(arr);
//       }
//     });
// }, [tabInfo.tabList]);

// useEffect(() => {
//   // userMenuList에서 현재 탭과 일치하는 탭만 필터링하여 새로운 탭 목록으로 설정합니다.
//   const filteredTabs = tabInfo.tabList.filter((tab: ITabObject) =>
//     userMenuList.some(
//       (menu: any) =>
//         tab.mtCd === menu.mtCd && //
//         tab.mdCd === menu.mdCd &&
//         tab.mrCd === menu.mrCd
//     )
//   );
//   setNewTabList(filteredTabs);
// }, [tabInfo.tabList, userMenuList]);
