/**
 * 작성자 : 홍선영
 * 날짜 : 2024.05.07
 * 기능 : 직종선택 모달 함수
 */

import { IUser } from '../atoms';
import { apiGet } from '../services/_common';

// 공종 사용 여부에 따라 직종 목록을 문자열로 변환하여 반환
export const jobtypeArrayToString = (prejobtypeYn: string, selectedArray: ISelectedJobtype[]) => {
  if (selectedArray.length > 0) {
    if (prejobtypeYn === 'Y') {
      const result = selectedArray.map((item) => item.key).join(',');
      return result;
    }
    const result = selectedArray.map((item) => item.key.substring(3, 7)).join(',');
    return result;
  }
  return null;
};

// 직종 코드 목록을 가져오는 함수
export const getJobypeCodeList = async (userInfo: IUser, jobtypes: string) => {
  const jobtypeCodes = jobtypes.split(',');

  // 공종+직종 사용인 경우
  if (userInfo.prejobtypeYn === 'Y') {
    // 중복된 공종코드 제거하고 배열 생성
    const result: string[] = [];
    jobtypeCodes.forEach((item: string) => {
      const prefix = item.substring(0, 4);
      if (!result.includes(prefix)) {
        result.push(prefix);
      }
    });

    // 각 공종 코드에 해당하는 세부 직종 목록을 비동기적으로 가져옴
    const promises = result.map(async (el: string) => {
      const res = await apiGet({ path: '/code/pmsNormalSub', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, grCd: el } });
      return { wPrejobtype: res.data.data.pmsNormalSubList[0]?.grCd, wJobtype: res.data.data.pmsNormalSubList };
    });
    const promisedResult = await Promise.all(promises);
    return promisedResult;
  }
  {
    // 직종만 사용인 경우, 전체 직종 목록을 동기적으로 가져옴
    const res = await apiGet({ path: '/code/pmsNormalTitle', req: { hCd: userInfo.hCd, sCd: userInfo.sCd, subCd: 'B' } });
    return res.data.data.pmsNormalTitleList;
  }
};

// 문자열 형태의 직종값을 배열로 변환하는 함수
export const jobtypeStringToArray = (userInfo: IUser, codeList: any[], jobtypeIdx: string) => {
  if (userInfo.prejobtypeYn === 'Y') {
    const result = codeList
      .flatMap((data2) => {
        const prefix = data2.wPrejobtype;
        return data2.wJobtype.map((job: any) => {
          const key = `${prefix}${job.subCd}`;
          return { key, name: job.cdName };
        });
      })
      .filter((job) => jobtypeIdx.includes(job.key));
    return result;
  }
  {
    const jobtypeIdxArray = jobtypeIdx.split(',');
    const addPrejobtypeKey = jobtypeIdxArray.map((el: any) => `000${el}`);
    const result = codeList.map((el: any) => ({ key: `${el.grCd}${el.subCd}`, name: el.cdName })).filter((item) => addPrejobtypeKey.includes(item.key));
    return result;
  }
};
