/**
 * 작성자 : 홍선영
 * 날짜 : 2023.07.24
 * 기능 : page down키 엔터 useState hook
 */

import { useEffect, useState } from 'react';

const useOnKeydownPgDn = () => {
  const [isPgDnPressed, setIsPgDnPressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Page Down' || event.keyCode === 34) {
        event.preventDefault();
        setIsPgDnPressed(true);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      setIsPgDnPressed(false);
    };
  }, []);

  return { isPgDnPressed, setIsPgDnPressed };
};
export default useOnKeydownPgDn;
