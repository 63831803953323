/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.24
 * 경로 : 구역별 상황판 > 터널 종합 진행 현황
 */

import styled from 'styled-components';
import TunnelTdProgressBar from '../TunnelTdProgressbar';
import TotalPointLength from '../tunnel/TotalLength';
import TunnelChipView from '../tunnel/TunnelChipView';
import { useEffect, useState } from 'react';
import PointIcon from '../tunnel/PointIcon';
import { useRecoilValue } from 'recoil';
import { tunnelReverseState } from '../../atoms';

const Root = styled.div`
  width: calc(100% - 0.25rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
  border-radius: 0.25rem;
  padding: 0.25rem 0.375rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  pointer-events: none;
  .pointLabel {
    flex-grow: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;
    .name {
      font-size: 0.875rem;
      font-weight: 400;
      padding: 0 0.75rem;
      border-radius: 1rem;
      color: ${({ theme }: { theme: any }) => theme.text_reverse};
      line-height: 1.25rem;
      display: flex;
      align-items: center;
      gap: 0.375rem;
      height: 1.5rem;
      justify-content: center;
      margin-right: 1rem;
    }
    .material-symbols-rounded {
      font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
      font-size: 1.5rem;
    }
  }
  .progressbar > :first-child {
    display: none;
  }
  .chipGroup {
    display: flex;
    gap: 0.25rem;
    width: 100%;
    > div {
      width: calc(50% - 0.125rem);
      flex-shrink: 0;
      .tunnelChip {
        height: 2.5rem;
        .chipValue {
          font-size: 0.875rem;
        }
      }
    }
  }
  .bigo {
    border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    border-radius: 0.25rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 2.5rem;
    background-color: ${({ theme }: { theme: any }) => theme.board};
    .material-symbols-rounded {
      font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
      font-size: 0.875rem;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      width: 1.5rem;
      text-align: center;
      border-right: 1px solid ${({ theme }: { theme: any }) => theme.outline_em};
    }
    .text {
      flex-grow: 1;
      font-weight: 600;
      font-size: 0.875rem;
      padding: 0 0.375rem;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    }
  }
  &.startPoint {
    .name {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
    }
    .pointLabel {
      .name {
        background-color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
      }
      .material-symbols-rounded {
        color: ${({ theme }: { theme: any }) => theme.filled_blue};
      }
    }
  }
  &.endPoint {
    .name {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
    }
    .pointLabel {
      .name {
        background-color: ${({ theme }: { theme: any }) => theme.filled_amber_deep};
      }
      .material-symbols-rounded {
        color: ${({ theme }: { theme: any }) => theme.filled_amber};
      }
    }
  }
`;

const TunnelTotalInfoMiniPoint = ({ point, data }: { point: 'start' | 'end'; data: any }) => {
  const isTunnelReversed = useRecoilValue(tunnelReverseState);
  const { eRLength, eLength, sRLength, sLength, eTjcName, eTesName, sTjcName, sTesName, eDeviceCount, eWorkerCount, sDeviceCount, sWorkerCount } = data;
  const isStart = point === 'start';
  const style = isStart ? 'startPoint' : 'endPoint';
  const rlength = isStart ? sRLength : eRLength;
  const length = isStart ? sLength : eLength;
  const tjcName = isStart ? sTjcName : eTjcName;
  const tesName = isStart ? sTesName : eTesName;
  const deviceCount = isStart ? sDeviceCount : eDeviceCount;
  const workerCount = isStart ? sWorkerCount : eWorkerCount;
  const [bigo, setbigo] = useState(tjcName);
  const isTjcName = bigo === tjcName;

  const isReversed = !isTunnelReversed ? isStart : !isStart;

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (tjcName && tesName) {
      intervalId = setInterval(() => setbigo(isTjcName ? tesName : tjcName), 3000);
    }
    return () => clearInterval(intervalId);
  }, [bigo]);

  return (
    <Root className={`point ${style}`}>
      <div className='pointLabel'>
        {isStart ? <PointIcon isStart /> : <PointIcon isStart={false} />}
        <span className='name'>{isStart ? '시점' : '종점'}</span>
      </div>
      <TunnelTdProgressBar rlength={rlength} length={length} reverse={isReversed} meter={false} grow={false} size='small' />
      <TotalPointLength rLength={rlength} length={length} nameOnly reverse={isStart} />
      <div className={`chipGroup ${style}`}>
        <TunnelChipView value={workerCount} icon='group' disabled={workerCount === '0'} size='small' />
        <TunnelChipView value={deviceCount} icon='front_loader' disabled={deviceCount === '0'} size='small' />
      </div>
      <div className='bigo'>
        <span className='material-symbols-rounded'>{isTjcName ? 'construction' : 'front_loader'}</span>
        <span className='text'>{bigo}</span>
      </div>
    </Root>
  );
};

export default TunnelTotalInfoMiniPoint;
