/**
 * 작성자 : 한영광
 * 날짜 : 2024.01.15
 * 경로 : 터널관리 > 굴진 / 일반 진행 내역 탭
 */
import { useEffect, useState, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PulseLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { IoChevronUpSharp } from 'react-icons/io5';
import dayjs from 'dayjs';
import styled from 'styled-components';

import i18n from '../../../translation/i18n';
import { IComCdList } from 'customTypes';
import { IUser, asideFoldState, userState } from '../../../atoms';
import { INIT_TATCD_A, INIT_TES_A, INIT_TJC_A, LIST_COUNT } from '../../../_constants';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { FloatingButtonToTop } from '../../../assets/styles/FloatingButtonToTop';
import { ContentsContainerRoot } from '../../../assets/styles/ContentsContainerRoot';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import { useSetAuth } from '../../../utils/useSetAuth';
import { todayYYYYMMDD } from '../../../utils/formatDate';
import { scrollToNodeTop } from '../../../utils/scrollToNodeTop';
import { useGetFrozenCount } from '../../../utils/getFrozenCount';
import { setComCdListState } from '../../../utils/setComCdListState';
import { useDetectScrolledToBottom } from '../../../utils/useDetectScrolledToBottom';
import useOnKeydownF9 from '../../../utils/useOnKeydownF9';
import useSetListCount from '../../../utils/useSetListCount';
import { BtnGhost } from '../../../components/Button';
import Input from '../../../components/Input';
import TuiGrid from '../../../components/Table/TuiGrid';
import SelectBox from '../../../components/SelectBox';
import TuiGridWrapper from '../../../components/Table/TuiGridWrapper';
import SearchSelectBoxSm from '../../../components/SearchSelectBoxSm';
import RangePicker, { onChangeRangeInput } from '../../../components/RangePicker';
import { apiGet } from '../../../services/_common';
import { logPost } from '../../../services/log';
import { useFetchTunnelCodeList, useFetchTunnelEsCodeList, useFetchTunnelJcCodeList } from '../../../services/useSetCodeListInSelectBoxForm';
import IssueGuide from '../../../components/IssueGuide';
import ShortcutButton from '../../../components/button/ShortcutButton';

const Root = styled(ContentsContainerRoot)`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .btn-in-cell {
    height: 2rem;
    font-size: 0.875rem;
    padding-left: 0.5rem;
    padding-right: 0.75rem;
    margin: 0 auto;
    font-weight: 600;
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.board};
    }
    .material-symbols-rounded {
      margin-right: 0.25rem;
    }
  }
  .btn-blue {
    background-color: ${({ theme }: { theme: any }) => theme.filled_blue} !important;
    border: none !important;
    /* color: ${({ theme }: { theme: any }) => theme.tonal_blue} !important; */
    color: ${({ theme }: { theme: any }) => theme.color.blue_100} !important;
  }
  .btn-red {
    background-color: ${({ theme }: { theme: any }) => theme.filled_red} !important;
    border: none !important;
    /* color: ${({ theme }: { theme: any }) => theme.tonal_red} !important; */
    color: ${({ theme }: { theme: any }) => theme.color.red_100} !important;
  }
  .btn-blue,
  .btn-red {
    .material-symbols-rounded {
      font-size: 1.375rem;
    }
  }
`;

interface ITunnelHistory {
  bigo: string;
  tatIdx: number;
  tatCd: string;
  tatName: string;
  tatGubun: string;
  rLength: number;
  tjcCd: string;
  tjcName?: string;
  tesCd: string;
  tesName?: string;
  editor: string;
  eDate: string;
}

const DetailInfo = () => {
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const toDay = todayYYYYMMDD();
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const { listCount, setListCount, patchUserMenuAPI } = useSetListCount(); // 검색줄수설정 훅
  const userInfo = useRecoilValue<IUser>(userState);
  const { eExcelYn, eListYn } = userInfo;
  const [columns, setColumns] = useState<any[]>([]);
  const tableRef = useRef<HTMLDivElement>(null);
  const [listCountComCdList, setListCountComCdList] = useState<IComCdList[]>([]); // 검색줄수 공통코드
  const [excelBtn, setExcelBtn] = useState(false);
  const { isBottom } = useDetectScrolledToBottom(tableRef);
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '', code: '', index: 0 });
  const [rowKey, setRowKey] = useState('');
  const foldMenu = useRecoilValue(asideFoldState);
  const [visibleRangePicker, setVisibleRangePicker] = useState(false);
  const [pickerDateRange, setPickerDateRange] = useState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
  const [userInputDateRange, setUserInputDateRange] = useState({ start: toDay, end: toDay });
  const [searchBigo, setSearchBigo] = useState({ bigo: '' });
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  const [tatGubunCdList] = useState<any>([
    { type: 'tatGubun', tatGubun: 'A', cdName: t('전체') },
    { type: 'tatGubun', tatGubun: 's', cdName: t('시점') },
    { type: 'tatGubun', tatGubun: 'e', cdName: t('종점') },
  ]);
  const { data: tatCdList, isError: isErrorTatList, isLoading: isLoadingTatList } = useFetchTunnelCodeList();
  const { data: tjcCdList, isError: isErrorTjcList, isLoading: isLoadingTjcList } = useFetchTunnelJcCodeList();
  const { data: tesCdList, isError: isErrorTesList, isLoading: isLoadingTesList } = useFetchTunnelEsCodeList();
  const [tatGubun, setTatGubun] = useState({ type: 'tatGubun', tatGubun: 'A', cdName: t('전체') });
  const [tatCd, setTatCd] = useState(INIT_TATCD_A);
  const [tjcCd, setTjcCd] = useState(INIT_TJC_A);
  const [tesCd, setTesCd] = useState(INIT_TES_A);
  const {
    data: tunnelHistoryList,
    isLoading,
    isFetching,
    isRefetching,
    isError,
    refetch,
  } = useQuery(['tunnelHistoryList', userInfo.hCd, userInfo.sCd], () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && userInfo.sCd !== '00000',
    cacheTime: 0,
  });
  const [tableState, setTableState] = useState<ITunnelHistory[]>(tunnelHistoryList?.map((el: ITunnelHistory) => ({ ...el, tatGubun: el.tatGubun === 's' ? t('시점') : t('종점') })) || []);
  const [orgTableState, setOrgTableState] = useState<ITunnelHistory[]>(tunnelHistoryList?.map((el: ITunnelHistory) => ({ ...el, tatGubun: el.tatGubun === 's' ? t('시점') : t('종점') })) || []);

  const componentRef = useRef<HTMLDivElement>(null);

  const frozenCount = useGetFrozenCount();

  useEffect(() => {
    setComCdListState(LIST_COUNT, setListCountComCdList, false);
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: `터널 리스트 > 굴진 /일반 진행 내역`,
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    setColumns([
      {
        header: t('터널명'),
        name: 'tatName',
        align: 'center',
        sortable: true,
        minWidth: 80,
        // renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('시/종점'),
        name: 'tatGubun',
        align: 'center',
        sortable: true,
        width: 200,
        // renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('현재 굴진 길이'),
        name: 'rLength',
        align: 'center',
        sortable: true,
        minWidth: 120,
      },
      {
        header: t('작업 내용'),
        name: 'tjcName',
        sortable: true,
        minWidth: 120,
      },
      {
        header: t('장비 상태'),
        name: 'tesName',
        align: 'center',
        sortable: true,
        minWidth: 140,
      },
      {
        header: t('수정자'),
        name: 'editor',
        align: 'center',
        sortable: true,
        minWidth: 120,
        // renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('비고'),
        name: 'bigo',
        sortable: true,
        minWidth: 180,
        // renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('수정일자'),
        name: 'eDate',
        align: 'center',
        sortable: true,
        minWidth: 120,
      },
    ]);
  }, [userInfo.prejobtypeYn, tableState, i18n.language]);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    if (pickerDateRange[0].startDate) {
      setUserInputDateRange((prev: any) => ({ ...prev, start: dayjs(pickerDateRange[0].startDate).format('YYYY-MM-DD') }));
    }
    if (pickerDateRange[0].endDate) {
      setUserInputDateRange((prev: any) => ({ ...prev, end: dayjs(pickerDateRange[0].endDate).format('YYYY-MM-DD') }));
    }
  }, [pickerDateRange[0].startDate, pickerDateRange[0].endDate]);

  const onClickRangeInput = () => {
    if (!visibleRangePicker) setVisibleRangePicker(true);
  };

  // 검색버튼 클릭 이벤트
  const onClickSearch = () => {
    refetch();
  };

  const fetchData = async () => {
    const req = {
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      tatCd: tatCd.tatCd === 'A' ? '' : tatCd.tatCd,
      tatGubun: tatGubun.tatGubun === 'A' ? '' : tatGubun.tatGubun,
      eDate1: userInputDateRange.start.replaceAll('-', ''),
      eDate2: userInputDateRange.end.replaceAll('-', ''),
      tjcCd: tjcCd.tjcCd === 'A' ? '' : tjcCd.tjcCd,
      tesCd: tesCd.tesCd === 'A' ? '' : tesCd.tesCd,
      bigo: searchBigo.bigo,
    };
    const res = await apiGet({ path: '/tarea/history', req });
    const result = res.data.data.tareaHistory?.map((el: ITunnelHistory) => ({ ...el, tatGubun: el.tatGubun === 's' ? t('시점') : t('종점') }));
    setTableState(result);
    setOrgTableState(result);
    return result;
  };

  // 엑셀 저장 버튼 클릭
  const onClickExcelSave = () => {
    if (tableState.length <= 0) return;
    setExcelBtn(true);
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '터널 관리 > 구역 현황 관리 > 굴진 / 일반 진행 내역',
      action: '엑셀 저장',
      etc: ``,
    });
  };

  const renderExcelBtn = () => {
    if (auth.excelAuth) {
      return (
        <BtnGhost onClick={onClickExcelSave}>
          <span className='icon-ms-xlsx'>X</span> {t('엑셀')}
        </BtnGhost>
      );
    }
    return null;
  };

  const initiateSearchOptions = () => {
    setTatGubun({ type: 'tatGubun', tatGubun: 'A', cdName: t('전체') });
    setTatCd(INIT_TATCD_A);
    setTjcCd(INIT_TJC_A);
    setTesCd(INIT_TES_A);
    setUserInputDateRange({ start: toDay, end: toDay });
    setPickerDateRange([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    initiateSearchOptions();
    setTableState(orgTableState);
  };

  // const onClickRow = (rowData: any, columnName: any, filteredIndex: number) => {
  //   console.log({ rowData, columnName, filteredIndex });
  // };

  // 데이터 패치하는 요소 중 하나라도 로딩중인 경우 스피너 노출
  const atLeastOneIsLoading = !columns || isLoading || isLoadingTatList || isLoadingTjcList || isLoadingTesList;
  // 데이터 패치하는 요소 중 하나라도 에러인 경우 스피너 노출
  const atLeastOneIsError = isError || isErrorTatList || isErrorTjcList || isErrorTesList;
  // 데이터 리패치 시 본문만 로딩스피너 노출
  const isLoadingTunnelHistory = isLoading || isRefetching || isFetching;

  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight, foldMenu, atLeastOneIsLoading]);

  if (atLeastOneIsError) return <IssueGuide />;
  if (atLeastOneIsLoading)
    return (
      <LoadingModalBackground>
        <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
      </LoadingModalBackground>
    );

  return (
    <Root loading={isLoadingTunnelHistory}>
      <SearchOptions>
        {isBottom && (
          <FloatingButtonToTop>
            <button type='button' onClick={() => scrollToNodeTop(tableRef)}>
              <IoChevronUpSharp size={20} style={{ stroke: 'white' }} />
            </button>
          </FloatingButtonToTop>
        )}
        <div className='inputsWrapper'>
          <div className='inputForm-row withLabel'>
            <label htmlFor='tatCd'>{t('터널명')}</label>
            <div className='inputForm-col'>
              <SearchSelectBoxSm
                options={tatCdList}
                defaultOption={t('전체')}
                state={tatCd}
                setState={setTatCd}
                stateKey='tatCd'
                codeKey='cdName'
                initiateKey={tatCd.tatCd}
                filterbar='filter-1-left'
                dropDownWidth='fit-content'
                optionHeight='height-md'
              />
            </div>
          </div>
          <div className='inputForm-row withLabel'>
            <label htmlFor='tatGubun'>{t('시/종점')}</label>
            <div className='inputForm-col'>
              <SearchSelectBoxSm
                options={tatGubunCdList}
                defaultOption={t('전체')}
                state={tatGubun}
                setState={setTatGubun}
                stateKey='tatGubun'
                codeKey='cdName'
                initiateKey={tatGubun.tatGubun}
                filterbar='filter-1-left'
                optionHeight='height-md'
              />
            </div>
          </div>
          <div className='inputForm-row-fit'>
            <span className='calendarLabel'>{t('적용일자')}</span>
            <div className='inputForm-col'>
              <div className='flex-basic'>
                <input
                  id='startInput'
                  type='text'
                  value={userInputDateRange.start}
                  onMouseDown={onClickRangeInput}
                  onChange={(e) => onChangeRangeInput(e, 'start', setPickerDateRange, setUserInputDateRange)}
                />
                <span className='inputDash'> ~ </span>
                <input
                  id='endInput'
                  type='text'
                  value={userInputDateRange.end}
                  onMouseDown={onClickRangeInput}
                  onChange={(e) => onChangeRangeInput(e, 'end', setPickerDateRange, setUserInputDateRange)}
                />
              </div>
              {visibleRangePicker && (
                <div className='rangePickerWrapper'>
                  <RangePicker state={pickerDateRange} setState={setPickerDateRange} setVisible={setVisibleRangePicker} />
                </div>
              )}
            </div>
          </div>
          <div className='inputForm-row withLabel'>
            <label htmlFor='tjcCd'>{t('작업 내용')}</label>
            <div className='inputForm-col'>
              <SearchSelectBoxSm
                options={tjcCdList}
                defaultOption={t('전체')}
                state={tjcCd}
                setState={setTjcCd}
                stateKey='tjcCd'
                codeKey='cdName'
                initiateKey={tjcCd.tjcCd}
                filterbar='filter-1-left'
                optionHeight='height-md'
              />
            </div>
          </div>
          <div className='inputForm-row withLabel'>
            <label htmlFor='tesCd'>{t('장비 상태')}</label>
            <div className='inputForm-col'>
              <SearchSelectBoxSm
                options={tesCdList}
                defaultOption={t('전체')}
                state={tesCd}
                setState={setTesCd}
                stateKey='tesCd'
                codeKey='cdName'
                initiateKey={tesCd.tesCd}
                filterbar='filter-1-right'
                comboWidth='expand-box-sm'
                optionHeight='height-md'
              />
            </div>
          </div>
          <div className='inputForm-row'>
            <div className='inputForm-col'>
              <Input placeholder={t('비고')} label='' type='text' id='bigo' name='bigo' state={searchBigo} setState={setSearchBigo} maxLength={200} />
            </div>
          </div>
        </div>
        <div className='inputsWrapper'>
          <div className='secondSearchOption'>
            <div className='flex-basic textBtnGroup'>
              <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
              {/* <BtnGhost onClick={onClickSearch} className='searchBtn'>
                {t('검색')}
                <span className='shortcut-f9'>F9</span>
              </BtnGhost> */}
              <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
              <div className='searchResult'>
                {t('총')}
                <span>{tableState.length}</span>
                {t('개')}
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col withLabelComCf'>
                  <label htmlFor='useYn'>{t('보기 설정')}</label>
                  <SelectBox
                    options={listCountComCdList}
                    defaultOption={listCount[LIST_COUNT]}
                    state={listCount}
                    setState={setListCount}
                    stateKey={LIST_COUNT}
                    initiateKey={listCount[LIST_COUNT]}
                    setTableLinesAPI={patchUserMenuAPI}
                    optionHeight='height-sm'
                    rsearch
                  />
                </div>
              </div>
            </div>
            <div className='flex-basic iconBtnGroup'>{renderExcelBtn()}</div>
          </div>
        </div>
      </SearchOptions>
      {isLoadingTunnelHistory ? (
        <LoadingModalBackground>
          <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
        </LoadingModalBackground>
      ) : (
        <TuiGridWrapper componentRef={componentRef}>
          <TuiGrid
            data={tableState}
            columns={columns}
            perPage={Number(listCount[LIST_COUNT])}
            excelBtn={excelBtn}
            setExcelBtn={setExcelBtn}
            usePagenation
            scrollX
            height={tuiHeight}
            rowKey={rowKey}
            eListYn={eListYn}
            eExcelYn={eExcelYn}
            frozenCount={frozenCount}
          />
        </TuiGridWrapper>
      )}
    </Root>
  );
};

export default DetailInfo;
