/**
 * 작성자 : 김광민
 * 날짜 : 2024.03.14
 * 경로 : 대시보드 & 상황판 > 터널 종합 진행 현황 (일반)
 */

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ERROR } from '../../_constants';
import { useQuery } from '@tanstack/react-query';
import { PulseLoader } from 'react-spinners';
import { useState } from 'react';
import PlayButton from './PlayButton';
import RefreshButton from './RefreshButton';
import { useRecoilValue } from 'recoil';
import { IUser, tInfoUpdateState } from '../../atoms';
import { apiGet } from '../../services/_common';
import TunnelTable from '../tunnel/TunnelTable';

type Props = {
  name: string;
  userInfo: any;
  visibleRefreshBtn?: boolean;
  uniqueKey: string;
};

const TunnelTotalTable = ({ name, userInfo, visibleRefreshBtn = true, uniqueKey }: Props) => {
  const { t } = useTranslation();
  const [autoPlay, setAutoPlay] = useState(visibleRefreshBtn === false);
  const isTunnelInfoUpdated = useRecoilValue(tInfoUpdateState);

  // Tarea 데이터 요청 함수
  const fetchTareaData = async (userInfoData: IUser) => {
    const { hCd, sCd } = userInfoData;
    try {
      const req = { hCd, sCd };
      const res = await apiGet({ path: `/tarea/t/total`, req });
      const { data } = res.data || {};
      return data?.tareaList || [];
    } catch (error) {
      console.error('API 호출 중 오류 발생:', error);
      toast.error(t(ERROR));
      throw new Error('API 호출 중 오류 발생');
    }
  };

  // Tarea 데이터 요청 상태값
  const { data, isError, isLoading, refetch } = useQuery([uniqueKey, userInfo.hCd, userInfo.sCd, isTunnelInfoUpdated], () => fetchTareaData(userInfo), {
    refetchInterval: visibleRefreshBtn ? false : 60000, // 60초마다 자동 refetch
  });

  // 로딩 중일 때
  if (isLoading) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  if (isError) {
    console.error('API 호출 중 오류 발생:', data);
  }

  // 새로고침 버튼 클릭 시
  const onClickRefresh = () => {
    refetch();
  };

  // 데이터가 있는지 확인합니다.
  const isData = data && data.length > 0;

  return (
    <div className='mGas tempEdit'>
      <div className='widget-header margin-top'>
        <div className='widget-title flex-between innerBtn'>
          <span>{t('터널 종합 진행 현황')}</span>
          {visibleRefreshBtn && (
            <>
              <div className='labelSet flex-end'>
                <PlayButton autoPlay={autoPlay} onClick={() => setAutoPlay(!autoPlay)} />
              </div>
              <RefreshButton onClick={onClickRefresh} />
            </>
          )}
        </div>
      </div>
      {isData && <TunnelTable data={data} autoPlay={autoPlay} />}
    </div>
  );
};

export default TunnelTotalTable;
