/**
 * 작성자 : 홍선영
 * 날짜 : 2024.01.22
 * 기능 : 굴진 진행 현황 대시보드 컴포넌트 (구역별 상황판)
 */

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PulseLoader } from 'react-spinners';
import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';

import { EndPoint, StartPoint } from '../TunnelPoint';
import { apiGet } from '../../services/_common';
import TotalPointLength from '../tunnel/TotalLength';
import TunnelTdProgressBar from '../TunnelTdProgressbar';
import useDashboardTimer from '../../utils/useDashboardTimer';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  user-select: none;
  > .title {
    height: 2rem;
    width: 100%;
    padding: 0 1rem;
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > .name {
      color: ${({ theme }: { theme: any }) => theme.text_primary};
      font-weight: ${({ theme }: { theme: any }) => theme.font_semibold};
      font-size: 1.125rem;
      line-height: 1.2;
    }
  }
  > .content {
    width: 100%;
    flex-grow: 1;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.25rem;
    margin-bottom: 0.5rem;
    > .point {
      width: 100%;
      flex-grow: 1;
      max-height: calc(50% - 0.125rem);
      display: flex;
      gap: 0.5rem;
      align-items: center;
      border-radius: 0.5rem;
      padding: 0.375rem 0.5rem;
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      &.startPoint {
        > .body > .material-symbols-rounded {
          color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
        }
      }
      &.endPoint {
        > .body > .material-symbols-rounded {
          color: ${({ theme }: { theme: any }) => theme.filled_amber_deep};
        }
      }
      > .header {
        width: 4rem;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.75rem;
        background-color: ${({ theme }: { theme: any }) => theme.alpha_25};
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
        padding: 0.5rem;
        flex-shrink: 0;
      }
      > .body {
        flex-grow: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 0.25rem;
        border-radius: 0.25rem;
        background-color: ${({ theme }: { theme: any }) => theme.alpha_75};
        gap: 0.125rem;
        > div {
          flex-grow: 0;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .progressbar .point {
    display: none;
  }
`;

interface Iprops {
  name: string;
  userInfo: any;
  tatCd: string;
  refreshTimeCount: number;
}

interface ItareaT {
  bigo: string;
  tatCd: string;
  tatName: string;
  sUseYn: string;
  sLength: number;
  sRLength: number;
  sDvNo: string;
  sTjcCd: string;
  sTesCd: string;
  sBigo: string;
  eUseYn: string;
  eLength: number;
  eRLength: number;
  eDvNo: string;
  eTjcCd: string;
  eTesCd: string;
  eBigo: string;
  tLength: number;
  tatFidCd: string;
  useYn: string;
  writer: string;
  wDate: string;
  editor: string;
  eDate: string;
}

export const TunnelExcavationProcess = ({ name, userInfo, tatCd, refreshTimeCount }: Iprops) => {
  const { t } = useTranslation();
  const { timer, setTimer } = useDashboardTimer(refreshTimeCount);

  useEffect(() => {
    if (timer <= 0) {
      refetch();
      setTimer(refreshTimeCount);
    }
  }, [timer]);

  // 터널 구역 정보 조회
  const { data, isLoading, refetch } = useQuery(['tAreatTGet', userInfo.hCd, userInfo.sCd, tatCd], () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && !!tatCd,
  });

  const fetchData = async () => {
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, tatCd };
      const res = await apiGet({ path: `/tarea/t`, req });
      const tareaT: ItareaT = res.data?.data || {};
      return tareaT;
    } catch (error) {
      console.error(error);
      throw new Error('error /msg');
    }
  };

  // 시점 및 종점 렌더링 함수
  const renderPoint = (point: 'start' | 'end') => {
    if (!data) return null;

    const { eRLength, eLength, sRLength, sLength, sUseYn, eUseYn } = data;
    const style = point === 'start' ? 'startPoint' : 'endPoint';
    const rlength = point === 'start' ? sRLength : eRLength;
    const length = point === 'start' ? sLength : eLength;
    const useYn = point === 'start' ? sUseYn : eUseYn;

    if (useYn === 'N') return null;

    return (
      <div className={`point ${style}`}>
        <div className='header'>{point === 'start' ? <StartPoint vertical /> : <EndPoint vertical />}</div>
        <div className='body'>
          <TunnelTdProgressBar rlength={rlength} length={length} meter={false} />
          <TotalPointLength rLength={rlength} length={length} reverse={false} />
        </div>
      </div>
    );
  };

  if (isLoading || !data) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <Root>
      <div className='title'>
        <span className='name'>{t(name)}</span>
      </div>
      <div className='content'>
        {renderPoint('start')}
        {renderPoint('end')}
      </div>
    </Root>
  );
};
