import { BtnBlue, BtnRed } from './Button';
import { createRoot } from 'react-dom/client';

class RemoveBtnRenderer {
  element;

  constructor(props: any) {
    this.element = document.createElement('div');

    const { onRemoveButtonClicked, auth, t } = props.columnInfo.renderer.options;
    const { rowKey } = props;
    if (auth.createAuth || auth.updateAuth) {
      if (props.formattedValue === 'I') {
        createRoot(this.element).render(
          <BtnRed className='btn-in-cell btn-red' type='button' onClick={() => onRemoveButtonClicked(rowKey, 'R')}>
            <span className='material-symbols-rounded'>close</span> {t('퇴직')}
          </BtnRed>
        );
      } else {
        createRoot(this.element).render(
          <BtnBlue className='btn-in-cell btn-blue' type='button' onClick={() => onRemoveButtonClicked(rowKey, 'I')}>
            <span className='material-symbols-rounded'>check</span> {t('재직')}
          </BtnBlue>
        );
      }
    }
  }

  getElement() {
    return this.element;
  }
}
export default RemoveBtnRenderer;
