import styled from 'styled-components';
import Tab from '../../components/Tab';
import TableRow from './master6/TableRow';
import { useOutletContext } from 'react-router-dom';
import { useSigncode } from '../../hooks/useTablet';
import { useRecoilValue } from 'recoil';
import { IUser, pageInfoState, userState } from '../../atoms';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BtnBlue } from '../../components/Button';
import { InputTable } from '../../assets/styles/InputTable';
import { getDate } from 'date-fns';
import { PulseLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { mergeRes } from '../../hooks/useMSite';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  padding: 0.5rem;
  > div {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
  .countWrapper {
    padding: 0 0.25rem;
  }
  .countName {
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
  }
  .countNumber {
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.filled_blue};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    margin-left: 0.5rem;
  }
  .seperator {
    height: 1rem;
    border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    margin: 0 0.25rem;
  }
`;

const Root = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  overflow: hidden;
  justify-content: flex-start;
  .header {
    padding: 0.75rem;
    height: 3rem;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    .title {
      flex-shrink: 0;
      width: fit-content;
      font-weight: 600;
      margin-right: 0.5rem;
    }
    input {
      width: 12rem;
    }
  }
`;

const Master6 = () => {
  const { t } = useTranslation();
  const size = useOutletContext<any>();
  const tabInfo = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(tabInfo.defaultMrCd);

  const scrollContainerRef = useRef<HTMLInputElement>(null);
  const userInfo = useRecoilValue<IUser>(userState);

  const { signcodeQuery, postSigncode } = useSigncode();
  const { data, isLoading, isError, refetch } = signcodeQuery;

  const [tableState, setTableState] = useState<Signcode[]>(data);
  const [emptyCheck, setEmptyCheck] = useState(false);

  // data 변경시 tableState 업데이트
  useEffect(() => {
    if (data) {
      setTableState(data);
    } else {
      setTableState([]);
    }
  }, [data]);

  // 신규항목 버튼 클릭 실행 함수
  const onClickAddTableRow = () => {
    const tableRowData: Signcode = {
      signName: '',
      signTag: '',
      useYn: 'Y',
    };
    // 1. 비어있는 입력필드 유효성 검사 해제
    setEmptyCheck(false);
    // 2. setState 함수 호출
    setTableState((prev) => {
      const updatedState = [...prev, tableRowData];
      // 2-1. 테이블 스크롤 위치 조정
      setTimeout(() => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current //
            .scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      }, 0);
      // 2-2. tableState 업데이트
      return updatedState;
    });
  };

  // 저장 버튼 클릭 함수
  const onClickSave = () => {
    let emptyCount = 0;
    let response: any = [];

    tableState.map((tableRow, index) => {
      const { signName, signTag, useYn } = tableRow;
      // 1. 서명항목, 서명TAG 공백인 경우 리턴
      if (signName === '' || signTag === '') {
        emptyCount += 1;
        setEmptyCheck(true);
        return;
      }
      // 2. 신규 항목 POST API 요청
      const dataIndex = data[index]; // 기존 항목
      if (!dataIndex) {
        const req = {
          signName,
          signTag,
          useYn,
          delYn: 'N',
          writer: userInfo.userId,
          editor: userInfo.userId,
        };
        postSigncode({ postSigncodeDto: [req] }).then((res) => {
          response.push(res);
        });
        return;
      }
      // 3. 기존 항목에서 수정된 내용이 없는 경우 리턴
      const comparisonName = dataIndex.signName === signName;
      const comparisonTag = dataIndex.signTag === signTag;
      const comparisonUse = dataIndex.useYn === useYn;
      if (comparisonName && comparisonTag && comparisonUse) {
        return;
      }
      // 4. 기존 항목 POST API 요청
      const req = {
        signName,
        signTag,
        useYn,
        delYn: 'N',
        signIndex: tableRow.signIndex,
        writer: tableRow.writer,
        editor: userInfo.userId,
      };
      postSigncode({ postSigncodeDto: [req] }).then((res) => {
        response.push(res);
      });
    });
    // 비어 있는 입력필드 유효성 검사 해제
    if (emptyCount === 0) setEmptyCheck(false);
    // 비어 있는 입력필드 유효성 검사 유지
    if (emptyCount > 0) emptyCount = 0;
    // Query Refetch
    setTimeout(async () => {
      const res = mergeRes(response);
      res.forEach((el) => {
        if (el.statusCode === 200) {
          toast.success(t(el.message));
        }
      });
      await refetch();
    }, 100);
  };

  if (isLoading) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  if (isError) return null;

  return (
    <div className='contents'>
      <Tab tabList={tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={() => {}} size={size} />
      <div className='content-container oneColumn'>
        <Root>
          <InputTable className='margin-left-05 overflow-y-scroll'>
            <div ref={scrollContainerRef}>
              <div className='thead'>
                <div className='tr'>
                  <div className='trCol2p5 flex-center tableStickyNo'>{t('No')}</div>
                  <div className='trCol15 flex-center required tableStickyTitle content-overflow2x'>{t('서명항목')}</div>
                  <div className='trCol10 flex-center content-overflow2x'>{t('서명 TAG')}</div>
                  <div className='trCol5 required flex-center content-overflow'>{t('사용유무')}</div>
                  <div className='trCol10 flex-center content-overflow'>{t('등록일자')}</div>
                  <div className='trCol5 flex-center content-overflow'>{t('등록자')}</div>
                  <div className='trCol10 flex-center content-overflow'>{t('수정일자')}</div>
                  <div className='trCol5 flex-center content-overflow'>{t('수정자')}</div>
                  <div className='trCol5 flex-center'> </div>
                </div>
              </div>
              <div className='tbody'>
                {tableState?.length > 0 ? (
                  tableState.map((el: any, i: number) => (
                    <TableRow //
                      rowData={el}
                      setTableState={setTableState}
                      index={i}
                      key={`table_${i}_${el.signTag}_${getDate}`}
                      emptyCheck={emptyCheck}
                    />
                  ))
                ) : (
                  <div className='noData'>No data.</div>
                )}
              </div>
            </div>
          </InputTable>
          <ButtonsWrapper>
            <div className='countWrapper'>
              {/* <span className='countName'>
                {t('전체')}
                <span className='countNumber'>{tableState.length}</span>
              </span>
              <span className='seperator' /> */}
              <span className='countName'>
                {t('사용 중')}
                <span className='countNumber'>{tableState.filter((el) => el.useYn === 'Y').length}</span>
              </span>
            </div>
            <div>
              <BtnBlue onClick={onClickAddTableRow}>{t('신규항목 추가')}</BtnBlue>
              <BtnBlue onClick={onClickSave}>{t('저장')}</BtnBlue>
            </div>
          </ButtonsWrapper>
        </Root>
      </div>
    </div>
  );
};

export default Master6;
