import { useRef, useState } from 'react';
import styled from 'styled-components';
import { extractDate, extractTime, HHMMSStoTime, timeToHHMM } from '../../../../utils/timeline';

const Root = styled.div`
  position: relative;
  overflow: visible;
  &.fill {
    background-color: ${({ theme }: { theme: any }) => theme.color.blue_900};
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.color.blue_700};
    }
  }
  cursor: default;
  height: 1rem;
  border-left: 1px solid ${({ theme }: { theme: any }) => theme.color.zinc_500};
  border-right: 1px solid ${({ theme }: { theme: any }) => theme.color.zinc_500};
  &:hover {
    background-color: ${({ theme }: { theme: any }) => theme.color.zinc_700};
  }
  &:first-child {
    border-left: none;
  }
  &:last-child {
    border-right: none;
  }
  .timetip {
    z-index: 100;
    position: absolute;
    display: flex;
    justify-content: center;
    color: white;
    top: -2.5rem;
    left: -0.625rem;
    font-size: 0.875rem;
    padding: 0.25em 0.75em;
    background-color: ${({ theme }: { theme: any }) => theme.color.zinc_950};
    color: ${({ theme }: { theme: any }) => theme.color.zinc_300};
    border-radius: 0.5rem;
    text-align: center;
  }
`;

interface Props {
  start: number;
  end: number;
  recordData: Playback[];
}

const TimelineSubInterval = ({ start, end, recordData }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [timeTip, setTimeTip] = useState<string | null>(null);

  // video 재생시간이 start, end 사이에 포함되는 경우 필터링
  const filteredRecordData = recordData.filter((video) => {
    const { szStartTime, szEndTime } = video;

    // video 시작시간 일자와 종료시간 일자가 같은지 비교
    const comparison = extractDate(szStartTime) === extractDate(szEndTime);
    // 일자가 일치하지 않으면 종료시간 일자와 시간을 00:00:00으로 설정
    const conditionTime = comparison ? extractTime(szStartTime) : `00:00:00`;

    // 시간을 분 단위로 변환
    const startTime = HHMMSStoTime(conditionTime);
    const endTime = HHMMSStoTime(extractTime(szEndTime));

    // video 시작 시간이 subinterval start보다 같거나 크고
    // video 종료 시간이 subinterval end보다 같거나 작은 경우
    const comparison1 = start * 60 >= startTime && end * 60 <= endTime;

    // video 시작 시간이 subinterval start보다 작지만 subinterval end보다 작고
    // video 종료 시간이 subinterval end보다 같거나 작은 경우
    const comparison2 = start * 60 < startTime && startTime < end * 60 && end * 60 <= endTime;

    // video 시작 시간이 subinterval start보다 같거나 크고
    // video 종료 시간이 subinterval end보다 작지만 subinterval start보다 큰 경우
    const comparison3 = start * 60 >= startTime && start * 60 < endTime && end * 60 > endTime;

    return comparison1 || comparison2 || comparison3;
    // return comparison1;
  });

  // 마우스 커서 위치의 상대적인 시간을 계산해서 timetip에 적용하는 함수
  // useref의 왼쪽 가장자리를 0, 오른쪽 가장자리를 100으로 설정하여 계산
  const handleMouseHover = (event: React.MouseEvent<HTMLSpanElement>) => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const relativePosition = ((event.clientX - rect.left) / rect.width) * 100;
      const relativeValue = start + (end - start) * (relativePosition / 100);
      const time = timeToHHMM(relativeValue * 60); // 분을 초로 변환
      setTimeTip(time);
    }
  };

  // video 재생시간이 하나 이상 포함되는 경우 fill 클래스 적용
  const RootStyle = () => {
    const fill = filteredRecordData.length > 0 ? 'fill' : '';
    return `sub-interval ${fill}`;
  };

  return (
    <Root //
      className={RootStyle()}
      ref={ref}
      onMouseMove={handleMouseHover}
      onMouseLeave={() => setTimeTip(null)}
    >
      {timeTip !== null && <span className='timetip'>{timeTip}</span>}
    </Root>
  );
};

export default TimelineSubInterval;
