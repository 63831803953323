import styled from 'styled-components';

const Root = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  .face-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    .material-symbols-rounded {
      font-variation-settings: 'FILL' 1;
    }
    &.medium {
      width: 2.5rem;
      height: 2.5rem;
      .face {
        font-size: 1.875rem;
      }
    }
    &.large {
      width: 4.5rem;
      height: 4.5rem;
      .face {
        font-size: 3.875rem;
      }
    }
  }
`;

interface Props {
  size?: 'large' | 'medium';
}

const AvatarIcon = ({ size = 'medium' }: Props) => {
  return (
    <Root>
      <div className={`face-circle ${size}`}>
        <span className='material-symbols-rounded face'>face</span>
      </div>
    </Root>
  );
};

export default AvatarIcon;
