/**
 * 작성자 : 김광민
 * 날짜 : 2024.01.19
 * 경로 : 대시보드 | 터널관리 - 구역 현황 관리 > 구역별 종합진행현황
 */

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import TunnelChipView from './TunnelChipView';
import { PulseLoader } from 'react-spinners';
import { TAREA_INNERTAB_EQUIP, TAREA_INNERTAB_LOCATION } from '../../_constants';
import IssueGuide from '../IssueGuide';

const SingleTunnelPointDetailWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
`;

const SingleTunnelPointDetailContainer = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 0.5rem;
  overflow: auto;
  min-height: 10.25rem;
  &.reverse {
    flex-direction: row-reverse;
  }
  .detailPoint {
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    transition: width ease-in-out 0.025s;
    &.single {
      max-width: 7rem;
    }
    &.focusedArea {
      border-radius: 0.25rem;
      .filtered-title {
        &.start {
          background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
          color: ${({ theme }: { theme: any }) => theme.tonal_blue};
        }
        &.end {
          background-color: ${({ theme }: { theme: any }) => theme.filled_amber};
          color: ${({ theme }: { theme: any }) => theme.tonal_orange};
        }
      }
    }
    .filtered-title {
      border-radius: 0.25rem;
      text-align: center;
      font-size: 0.75rem;
      padding: 0.25rem 0;
      flex-grow: 0;
      &.start {
        color: ${({ theme }: { theme: any }) => theme.filled_blue};
        background-color: ${({ theme }: { theme: any }) => theme.tonal_blue};
      }
      &.end {
        color: ${({ theme }: { theme: any }) => theme.filled_amber_deep};
        background-color: ${({ theme }: { theme: any }) => theme.tonal_orange};
      }
    }
    .filtered-content {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }
  }
  &.dashboard {
    opacity: 1;
    gap: 0.25rem;
    overflow: scroll auto;
    min-height: initial;
    .detailPoint {
      flex-grow: 0;
      width: 3.5rem;
      .filtered-title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0.25rem;
      }
    }
  }
  &.siteDashboard {
    .detailPoint {
      width: 4rem;
      .filtered-title {
        padding: 0.5rem;
        font-size: 0.875rem;
      }
      .filtered-content {
        .tunnelChip {
          height: 2.5rem;
          .chipValue {
            font-size: 0.875rem;
          }
        }
      }
    }
  }
`;

const NoData = styled.div`
  width: 100%;
  flex-grow: 1;
  min-height: 6.5rem;
  font-size: 0.75rem;
  margin-bottom: 0.75rem;
`;

const Loader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  min-height: 6.5rem;
  margin-bottom: 0.75rem;
  &.siteDashboard {
    min-height: 8rem;
  }
`;

type Props = {
  isStart: boolean;
  transition?: boolean;
  filteredData: any;
  masterData: MutateDataType;
  filter: TunnelFilterData[];
  dashboard: boolean;
  autoPlay: boolean;
  selectedTatCd?: string;
  updatePlayNow?: () => void;
  onClick?: any;
  siteDashboard: boolean;
};

const SingleTunnelPointDetail = ({ isStart, transition, filteredData, masterData, filter, dashboard, autoPlay, selectedTatCd, updatePlayNow, onClick, siteDashboard }: Props) => {
  const [focusedIndex, setFocusedIndex] = useState<number>(0);
  const [playNow, setPlayNow] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const focusedRef = useRef<HTMLDivElement | null>(null);
  const pointPosition = isStart ? 'start' : 'end';

  // 필터 결과 가져오기 함수
  const filterResult = ({ text, filterData }: { text: string; filterData: TunnelFilterData[] }) => {
    return filterData.find((obj) => obj.title === text)?.status;
  };

  // autoPlay 상태 변경시 playNow 상태 업데이트
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const handleAutoPlayChange = () => {
      // autoPlay가 true이고 시점이면 0.5초 후에 playNow 상태 변경
      if (autoPlay && isStart) {
        timeoutId = setTimeout(() => setPlayNow(true), 500);
      }
      // autoPlay가 true이고 종점이면 1초 후에 playNow 상태 변경
      if (autoPlay && !isStart) {
        timeoutId = setTimeout(() => setPlayNow(true), 1000);
      }
    };
    // selectedTatCd가 업데이트될 때 focusedIndex 초기화
    if (selectedTatCd) {
      setFocusedIndex(0);
    }
    // selectedTatCd가 존재하면 0.5초 후에 handleAutoPlayChange 실행
    if (selectedTatCd && autoPlay) {
      timeoutId = setTimeout(handleAutoPlayChange, 500);
    }
    // autoPlay가 false면 시점, 종점 구분없이 playNow 상태 즉시 변경
    if (selectedTatCd && !autoPlay) {
      setPlayNow(false);
    }
    return () => clearTimeout(timeoutId);
  }, [autoPlay, selectedTatCd]);

  // playNow가 true일 때 1.5초 간격으로 focusedIndex 업데이트
  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const updateFocusedIndex = () => {
      if (filteredData.length === 0 || filteredData[focusedIndex + 1] === undefined) {
        setFocusedIndex(0);
        setPlayNow(false);
        handleUpdatePlayNow();
      } else {
        setFocusedIndex(focusedIndex + 1);
      }
    };

    if (playNow) {
      intervalId = setInterval(updateFocusedIndex, 1500);
    }

    return () => {
      clearInterval(intervalId);
      if (playNow && filteredData.length === 0) {
        handleUpdatePlayNow();
      }
    };
  }, [playNow, focusedIndex, filteredData.length]);

  // scrollIntoView 실행
  useEffect(() => {
    const element = focusedRef.current;
    if (focusedIndex !== -1 && element) {
      const elementRect = element.getBoundingClientRect();
      const parentRect = element.parentElement?.getBoundingClientRect();
      // 시점인 경우
      if ((isStart && elementRect.left < parentRect!.left) || elementRect.right > parentRect!.right) {
        // 요소가 가로 스크롤 영역 밖에 있으면 수동으로 스크롤 조작
        element.parentElement?.scrollTo({
          left: elementRect.left + element.parentElement!.scrollLeft - parentRect!.left,
          behavior: 'smooth',
        });
      }
      // 종점인 경우
      if ((!isStart && elementRect.left < parentRect!.left) || elementRect.right > parentRect!.right) {
        // 요소가 가로 스크롤 영역 밖에 있으면 수동으로 스크롤 조작
        element.parentElement?.scrollTo({
          left: elementRect.right - parentRect!.right + element.parentElement!.scrollLeft,
          behavior: 'smooth',
        });
      }
    }
  }, [focusedIndex]);

  const handleUpdatePlayNow = () => {
    if (autoPlay) {
      updatePlayNow && updatePlayNow();
    }
  };

  // 로딩 중일때
  if (transition) {
    return (
      <Loader className={siteDashboard ? 'siteDashboard' : ''}>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </Loader>
    );
  }

  // 데이터가 없을때
  if (filteredData.length === 0) {
    return <IssueGuide illustrator={{ visible: false }} />;
  }

  return (
    <SingleTunnelPointDetailWrapper>
      <SingleTunnelPointDetailContainer className={`${isStart ? 'reverse' : ''} ${dashboard ? 'dashboard' : ''} ${playNow ? 'autoPlay' : ''} ${siteDashboard ? 'siteDashboard' : ''}`} ref={wrapperRef}>
        {filteredData.length > 0 &&
          filteredData.map(({ tatName, workerCount, deviceCount, bsCount, tatGubun, tadCd }: any, index: number) => (
            <div
              ref={index === focusedIndex ? focusedRef : null}
              key={index}
              className={`detailPoint single ${pointPosition} ${playNow ? 'autoPlay' : ''} ${playNow && index === focusedIndex ? 'focusedArea' : ''}`}
            >
              <span className={`filtered-title ${pointPosition}`}>{tatName}</span>
              <div className='filtered-content'>
                {/* 근로자 */}
                {workerCount !== undefined && filterResult({ text: 'location', filterData: filter }) && (
                  <div className={workerCount === '0' ? 'opacity' : ''}>
                    <TunnelChipView
                      value={workerCount}
                      icon='group'
                      disabled={workerCount === '0'}
                      size={dashboard ? 'small' : ''}
                      onClick={() => workerCount !== '0' && onClick(TAREA_INNERTAB_LOCATION, tatGubun, tadCd)}
                    />
                  </div>
                )}
                {/* 장비 */}
                {deviceCount !== undefined && filterResult({ text: 'device', filterData: filter }) && (
                  <div className={deviceCount === '0' ? 'opacity' : ''}>
                    <TunnelChipView
                      value={deviceCount}
                      icon='front_loader'
                      disabled={deviceCount === '0'}
                      size={dashboard ? 'small' : ''}
                      onClick={() => deviceCount !== '0' && onClick(TAREA_INNERTAB_LOCATION, tatGubun, tadCd)}
                    />
                  </div>
                )}
                {/* 방송장비 */}
                {bsCount !== undefined && filterResult({ text: 'broadCast', filterData: filter }) && (
                  <div className={bsCount === '0' ? 'opacity' : ''}>
                    <TunnelChipView value={bsCount} icon='volume_up' disabled={bsCount === '0'} onClick={() => bsCount !== '0' && onClick(TAREA_INNERTAB_EQUIP, tatGubun, tadCd)} />
                  </div>
                )}
              </div>
            </div>
          ))}
      </SingleTunnelPointDetailContainer>
    </SingleTunnelPointDetailWrapper>
  );
};

// export default SingleTunnelPointDetail;
SingleTunnelPointDetail.displayName = 'SingleTunnelPointDetail';

export default React.memo(SingleTunnelPointDetail);
