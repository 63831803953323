/**
 * 작성자 : 홍선영
 * 날짜 : 2023.07.10
 * 기능 : 안전교육 등록/수정/뷰 모달
 */

import { useState, useEffect, Dispatch, SetStateAction, useRef } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { userState } from '../../atoms';
import ReactSelect from '../ReactSelect';

import { IAuth, IComCd, IModal } from 'customTypes';
import { InputTable } from '../../assets/styles/InputTable';
import { ModalBackground, Modal } from '../../assets/styles/Modal';
import { BtnBlue, BtnGhost, BtnLightSkyBlue, BtnRed } from '../Button';
import Input from '../Input';
import Portal from '../Portal';
import Textarea from '../Textarea';
import Thumbnail from './Thumbnail';
import DeleteModal from './DeleteModal2';
import DatePicker from '../DatePicker';
import WorkerListModal from './WorkerListModal';
import { trimObject } from '../../utils/trimObject';
import { getFileNameFromURL, isImageFile } from '../../utils/formatFilePath';
import { hourList2, minList2 } from '../../_constants';
import { formatDateYMD, isValidYYYYMMDD, todayYYYYMMDD } from '../../utils/formatDate';
import { logPost } from '../../services/log';
import { useTranslation } from 'react-i18next';
import { apiDelete, apiGet, apiPost } from '../../services/_common';

interface IProps {
  getReportListAPI: () => void;
  setOpenModal: Dispatch<SetStateAction<IModal>>;
  auth: IAuth;
  code: { hCd: string; sCd: string; sSeq: number };
}

interface ISaveEduInfo {
  hCd?: string;
  sCd?: string;
  sSeq?: number;
  wCd?: string;
  sPlace: string;
  sDate: string;
  sTeacher: string;
  sMemo: string;
  writer?: string;
  editor?: string;
  sFile?: string;
  sjCd?: string;
  wName?: string;
  sCode: string;
}

interface ISubModal {
  status: boolean;
  type: string;
  sEduseq: string;
}

const SafeEduModal = ({ getReportListAPI, setOpenModal, auth, code }: IProps) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);
  const initialEduInfo = {
    sDate: '',
    sMemo: '',
    sCode: '',
    sPlace: userInfo.eduPlace,
    sTeacher: userInfo.eduCharge,
  };

  interface ISelectedFile {
    type: string;
    url: string | ArrayBuffer | null;
  }

  const toDay = todayYYYYMMDD();
  const [openSubModal, setOpenSubModal] = useState<any>({ status: false, type: '', sEduseq: '', isOverlappingModal: true });
  const [tableState, setTableState] = useState<any[]>([]); // 교육 근로자 리스트
  const [isViewMode, setIsViewMode] = useState(!auth.createAuth && !auth.updateAuth); // 권한에 따른 뷰 or 수정모드 여부
  const [workerInputSet, setWorkerInputSet] = useState<ISaveEduInfo>(initialEduInfo);
  const [sDate, setSDate] = useState(toDay);
  const eduStartHour = userInfo.eduStartTime.substring(0, 2);
  const eduStartMin = userInfo.eduStartTime.substring(2, 4);
  const eduEndHour = userInfo.eduEndTime.substring(0, 2);
  const eduEndMin = userInfo.eduEndTime.substring(2, 4);
  const [sHour, setSHour] = useState({ label: eduStartHour, value: eduStartHour });
  const [sMin, setSMin] = useState({ label: eduStartMin, value: eduStartMin });
  const [fHour, setFHour] = useState({ label: eduEndHour, value: eduEndHour });
  const [fMin, setFMin] = useState({ label: eduEndMin, value: eduEndMin });
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [filePath, setFilePath] = useState<any>('');
  const [selectedFileUrl, setSelectedFileUrl] = useState<ISelectedFile>({ type: '', url: '' });
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [isNewAdd, setIsNewAdd] = useState(false);
  const [edutypeNormalCdList, setEdutypeNormalCdList] = useState([]);
  const [eduType, setEduType] = useState({ label: '', value: '' });
  const IMAGE = 'image';
  const FILE = 'file';
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [firstApiReq, setFirstApiReq] = useState(false);

  useEffect(() => {
    getNormalCdTitleAPI().then((res: any) => {
      if (res.status === 200) {
        setFirstApiReq(true);
      }
    });
  }, []);

  useEffect(() => {
    if (code.sSeq > 0 && firstApiReq) {
      getSafeEduInfoAPI();
    }
    if (code.sSeq === 0) {
      setIsNewAdd(true);
    }
  }, [code, firstApiReq]);

  useEffect(() => {
    if (sDate !== '') setWorkerInputSet((prev: ISaveEduInfo) => ({ ...prev, sDate: sDate?.replace('-', '') }));
  }, [sDate]);

  useEffect(() => {
    if (edutypeNormalCdList.length > 0) {
      const findsCodeName: any = edutypeNormalCdList.find((el: any) => el.value === eduType.value);
      if (findsCodeName) setEduType((prev: any) => ({ ...prev, label: findsCodeName.label }));
    }
  }, [edutypeNormalCdList, eduType.value]);

  const getSafeEduInfoAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, sSeq: Number(code.sSeq) };
    const res = await apiGet({ path: '/safe/edu', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const { safeEduD, safeEduT } = data;
      setEduType({ label: '', value: safeEduT.sCode });
      setWorkerInputSet((prev) => ({ ...prev, sCode: safeEduT.sCode, sPlace: safeEduT.sPlace, sTeacher: safeEduT.sTeacher, sMemo: safeEduT.sMemo }));
      setSDate(safeEduT.sDate);
      setTableState(safeEduD);
      setFilePath(safeEduT.sFile);
      const isImage = isImageFile(safeEduT.sFile); // 경로 확장자에 따라 이미지인지 아닌지 판별
      setSelectedFileUrl({ url: safeEduT.sFile, type: isImage ? IMAGE : 'file' });

      if (safeEduT.sStime) {
        const { sStime } = safeEduT;
        setSHour({ label: sStime.substring(0, 2), value: sStime.substring(0, 2) });
        setSMin({ label: sStime.substring(2, 4), value: sStime.substring(2, 4) });
      }
      if (safeEduT.sEtime) {
        const { sEtime } = safeEduT;
        setFHour({ label: sEtime.substring(0, 2), value: sEtime.substring(0, 2) });
        setFMin({ label: sEtime.substring(2, 4), value: sEtime.substring(2, 4) });
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 안전교육 조회 API
  const getNormalCdTitleAPI = async () => {
    const req = {
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      subCd: 'F',
    };
    const res = await apiGet({ path: '/code/pmsNormalTitle', req });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const formatSelectBoxFormList = data.pmsNormalTitleList.map((el: IComCd) => {
        return { value: el.subCd, label: el.cdName };
      });
      const addDefaultObject = { value: '', label: t('선택') };
      const newArray: any = [addDefaultObject, ...formatSelectBoxFormList];
      setEdutypeNormalCdList(newArray);
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  // 근로자 검색
  const onClickSearchWorker = async () => {
    setOpenSubModal((prev: any) => ({ ...prev, status: true, type: 'workerList' }));
  };

  const onClickClose = () => {
    if (!openSubModal.status) setOpenModal((prev: IModal) => ({ ...prev, status: false }));
    else setOpenSubModal((prev: IModal) => ({ ...prev, status: false }));
  };

  const safeeduDdeleteAPI = async (worker: any) => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, sSeq: code.sSeq, sEduseq: worker.sEduseq, editor: userInfo.userId };
    const res = await apiDelete({ path: '/safe/eduD', contentType: 'application/json', req });
    const { statusCode, message } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      const newArray = tableState.filter((el) => el.sEduseq !== worker.sEduseq);
      setTableState(newArray);
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '안전 교육 정보(팝업)',
        action: '근로자 삭제',
        etc: `${worker.wName}(${worker.wCd})`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  const onClickDeleteEduWorker = (index: number, el: any) => {
    if (el.sEduseq === undefined) {
      // 새로 선택한 근로자일경우
      const newArray = tableState.filter((_, i) => index !== i);
      setTableState(newArray);
    } else {
      setOpenSubModal((prev: any) => ({ ...prev, status: true, type: 'delete', api: () => safeeduDdeleteAPI(el) }));
    }
  };

  const onClickSaveSafeEdu = () => {
    setIsSaveClicked(true);
    if (eduType.value === '') return toast.warning(t('교육 구분을 선택하세요'));
    if (!sDate) return toast.warning(t('교육 일자를 선택하세요'));
    if (sDate && (sDate.length < 8 || !isValidYYYYMMDD(sDate))) return toast.warning(t('교육 일자를 확인하세요'));
    if (tableState.length <= 0) return toast.warning(t('근로자를 선택하세요'));

    const trimData = trimObject(workerInputSet);
    return saveSafeEduInfoAPI(trimData);
  };

  const saveSafeEduInfoAPI = async (userInput: ISaveEduInfo) => {
    const newArray = tableState.map((el) => {
      return {
        sEduseq: el.sEduseq || 0,
        wCd: el.wCd,
      };
    });

    const safeEduTOjb = {
      file: selectedFile,
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      sSeq: code.sSeq,
      sCode: eduType.value,
      sDate: sDate || '',
      sPlace: userInput.sPlace,
      sTeacher: userInput.sTeacher,
      sStime: `${sHour.value}${sMin.value}`,
      sEtime: `${fHour.value}${fMin.value}`,
      sFile: filePath,
      sMemo: userInput.sMemo,
      writer: userInfo.userId,
      editor: userInfo.userId,
    };
    const req = { ...safeEduTOjb, safeEduWorkerReqDto: newArray };
    const res = await apiPost({ path: '/safe/edu', contentType: 'multipart/form-data', req });

    const { statusCode, data, message } = res.data;
    if (statusCode === 200) {
      // setWorkerInputSet(data);
      toast.success(t(message));
      getReportListAPI();
      setOpenModal((prev) => ({ ...prev, status: false }));

      if (isNewAdd) {
        await logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '안전 교육 정보(팝업)',
          action: '신규 등록',
          etc: `${formatDateYMD(sDate)}`,
        });
      } else {
        await logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '안전 교육 정보(팝업)',
          action: '안전 교육 정보 저장',
          etc: `${formatDateYMD(sDate)}(${eduType.value})`,
        });
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  const onClickDelete = async () => {
    setOpenSubModal((prev: any) => ({ ...prev, status: true, type: 'delete', api: deleteAPI }));
  };

  const deleteAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, sSeq: code.sSeq, editor: userInfo.userId };
    const res = await apiDelete({ path: '/safe/edu', contentType: 'application/json', req });
    const { statusCode, message } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      getReportListAPI();
      setOpenModal((prev) => ({ ...prev, status: false }));
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '안전 교육 정보(팝업)',
        action: '안전 교육 정보 삭제',
        etc: `${formatDateYMD(sDate)}(${eduType.value})`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;

    if (fileList) {
      const file = fileList[0];
      const fileSize = file.size;

      // 5MB in Bytes (5 * 1024 * 1024)
      if (fileSize > 5242880) {
        toast.warning(`${t('파일크기는 5MB를 초과할 수 없습니다')} (${fileSize / 1024 / 1024}/5MB)`);
      } else {
        setSelectedFile(file);
        setFilePath(file.name);

        // 썸네일
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedFileUrl((prev: any) => ({ ...prev, type: IMAGE, url: reader.result as string }));
        };

        if (!file.type.startsWith('image/')) {
          // 파일이 이미지가 아닐때
          setSelectedFileUrl((prev: any) => ({ ...prev, type: FILE, url: reader.result as string }));
        } else {
          reader.readAsDataURL(file);
        }
      }
    }
  };

  const onClickDeleteThumbnail = () => {
    setFilePath('');
    setSelectedFileUrl({ type: '', url: '' });
  };

  const onClickThumbnail = (url: string) => {
    // 썸네일 확대클릭
    setOpenSubModal((prev: any) => ({ ...prev, status: true, type: 'thumbnail', data: { aImgPath: url }, thumbnailOnly: true }));
  };

  const renderFileBtn = () => {
    if (auth.createAuth || (auth.updateAuth && code.sSeq > 0)) {
      return (
        <label htmlFor='filebox' className='searchBox'>
          <div className='fileButton' id='filebox'>
            {t('파일선택')}
          </div>
        </label>
      );
    }
    return null;
  };

  const renderDeleteBtn = (el: any, i: number) => {
    if (auth.deleteAuth || auth.createAuth || auth.updateAuth) {
      return (
        <div className='trCol4 flex-center'>
          <BtnRed onClick={() => onClickDeleteEduWorker(i, el)}>{el.sEduseq !== undefined ? t('삭제') : t('제거')}</BtnRed>
        </div>
      );
    }
    return null;
  };

  return (
    <ModalBackground onClick={onClickClose} role='presentation'>
      {openSubModal.status !== true && (
        <Modal>
          <div
            className='modal'
            role='presentation'
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div className='inputForm-head'>
              <div className='modalHeaderTitle'>
                {t('안전교육')} {isNewAdd ? t('등록') : t('정보')}
              </div>
              <div className='closeBtn' onClick={onClickClose} role='presentation'>
                <span className='material-symbols-rounded'>close</span>
              </div>
            </div>
            <div className='modal-contents'>
              <div className={`filter-option-grid ${isViewMode ? 'view' : undefined}`}>
                <div className='reactSelectrWrapper'>
                  <label htmlFor='eduType' className='w5rem required'>
                    {t('교육 구분')}
                  </label>
                  <ReactSelect
                    defaultValue={eduType}
                    options={edutypeNormalCdList}
                    value={eduType}
                    state={eduType}
                    setState={setEduType}
                    isClearable={false}
                    isSearchable={false}
                    isSaveClicked={isSaveClicked}
                  />
                </div>
                <Input className='w5rem' label='교육 장소' type='text' name='sPlace' state={workerInputSet} setState={setWorkerInputSet} disabled={!auth.updateAuth && !auth.createAuth} />

                <div className='grid plainText marginBottomNone datePickerWrapper'>
                  <label htmlFor='sDate' className='w5rem required'>
                    {t('교육 일자')}
                  </label>
                  <DatePicker startDate={workerInputSet.sDate} setDate={setSDate} popperPlacement='bottom' isSaveClicked={isSaveClicked} />
                </div>
                <Input className='w5rem' label='강사' type='text' name='sTeacher' state={workerInputSet} setState={setWorkerInputSet} disabled={!auth.updateAuth && !auth.createAuth} />

                <div className='span2 selectTimer'>
                  <label htmlFor='pwdLimitCount' className='w5rem'>
                    {t('교육 시간')}
                  </label>
                  <div className='flex-basic'>
                    <div className='flex-basic'>
                      <ReactSelect options={hourList2} value={sHour} defaultValue={sHour} state={sHour} setState={setSHour} isClearable={false} isSearchable={false} />
                      <span>{t('시')}</span>
                    </div>
                    <div className='flex-basic'>
                      <ReactSelect options={minList2} value={sMin} defaultValue={sMin} state={sMin} setState={setSMin} isClearable={false} isSearchable={false} />
                      <span>{t('분')}</span>
                    </div>
                    <span>-</span>
                    <div className='flex-basic'>
                      <ReactSelect options={hourList2} value={fHour} defaultValue={fHour} state={fHour} setState={setFHour} isClearable={false} isSearchable={false} />
                      <span>{t('시')}</span>
                    </div>
                    <div className='flex-basic'>
                      <ReactSelect options={minList2} value={fMin} defaultValue={fMin} state={fMin} setState={setFMin} isClearable={false} isSearchable={false} />
                      <span>{t('분')}</span>
                    </div>
                  </div>
                </div>
                <div className='span2 fileBoxWrapper'>
                  <label htmlFor='file' className='w5rem'>
                    {t('첨부 파일')}
                  </label>
                  <div className='flex-basic'>
                    <input
                      className='hiddenFileBox'
                      style={{ width: '50%', lineHeight: '2' }}
                      type='file'
                      accept='file'
                      id='filebox'
                      name='dIfilename'
                      ref={inputFileRef}
                      onChange={handleFileInputChange}
                    />
                    <input
                      placeholder={t('5MB 까지 첨부 가능')}
                      className='fileBoxInput'
                      style={{ width: '50%', lineHeight: '2' }}
                      type='text'
                      id='filebox'
                      name='dIfilename'
                      value={getFileNameFromURL(filePath)}
                      disabled
                    />
                    <div className='flex-basic'>{renderFileBtn()}</div>
                    {/* <span>5MB 까지 첨부 가능</span> */}
                  </div>
                </div>
                {!isNewAdd && selectedFileUrl.url && (
                  <div className='span2 fileBoxWrapper preview'>
                    <label htmlFor='file' className='w5rem'>
                      {t('미리보기')}
                    </label>
                    <div className='preview'>
                      {/* <div className={selectedFileUrl.type === IMAGE ? 'thumbnailWrapper' : 'fileWrapper flex-start'}> */}
                      {selectedFileUrl.type === IMAGE ? (
                        <img className='thumbnail' src={selectedFileUrl.url || filePath} alt='' onClick={() => onClickThumbnail(selectedFileUrl.url || filePath)} role='presentation' />
                      ) : (
                        <div>{getFileNameFromURL(filePath)}</div>
                      )}
                      <div className='buttonsWrapper'>
                        <BtnGhost>
                          <span className='material-symbols-rounded'>download </span>
                          <a href={filePath}>{t('첨부파일 다운로드')}</a>
                        </BtnGhost>
                        <BtnGhost onClick={onClickDeleteThumbnail}>
                          <span className='material-symbols-rounded'>delete</span>
                          {t('첨부파일 삭제')}
                        </BtnGhost>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                )}
                <div className='span2'>
                  <Textarea
                    className='w5rem'
                    display='flex'
                    flexDirection='row'
                    label={t('교육 내용')}
                    name='sMemo'
                    state={workerInputSet}
                    set={setWorkerInputSet}
                    disabled={!auth.updateAuth && !auth.createAuth}
                  />
                </div>
              </div>
              <div className='flex-between tableTop'>
                <div className='tableTitle required'>{t('교육 근로자')}</div>
                <div className='buttonsWrapper'>
                  {(auth.createAuth || auth.updateAuth) && (
                    <BtnLightSkyBlue className='btn-small' onClick={onClickSearchWorker}>
                      {t('근로자 검색')}
                    </BtnLightSkyBlue>
                  )}
                </div>
              </div>
              <InputTable className='inputTable-overflow margin-left-05'>
                <div className='thead'>
                  <div className='tr'>
                    <div className='trCol2p5 flex-center tableStickyNo'>{t('순번')}</div>
                    <div className='trCol8 flex-center content-overflow'>{t('협력업체')}</div>
                    <div className='trCol8 flex-center content-overflow'>{t('근로자명')}</div>
                    {auth.deleteAuth && <div className='trCol4'> </div>}
                  </div>
                </div>
                <div className='table'>
                  <div className='tbody'>
                    {tableState.map((el: any, i: number) => (
                      <div className='tr' key={i}>
                        <div className='trCol2p5 flex-center tableStickyNo'>{i + 1}</div>
                        <div className='trCol8 flex-basic content-overflow'>{el.sjName}</div>
                        <div className='trCol8 flex-basic content-overflow'>{el.wName}</div>
                        {renderDeleteBtn(el, i)}
                      </div>
                    ))}
                  </div>
                </div>
              </InputTable>
            </div>
            <div className='modal-footer flex-end'>
              {auth.deleteAuth && !isNewAdd && <BtnRed onClick={onClickDelete}>{t('삭제')}</BtnRed>}
              {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={onClickSaveSafeEdu}>{t('저장')}</BtnBlue>}
              {/* <BtnGray onClick={onClickClose}>닫기</BtnGray> */}
            </div>
          </div>
        </Modal>
      )}
      {openSubModal.status === true && openSubModal.type === 'workerList' && (
        <WorkerListModal openModal={openSubModal} setOpenModal={setOpenSubModal} state={tableState} setState={setTableState} isIncludeBlackUser useCheckbox onClickClose={onClickClose} />
      )}
      <Portal openModal={openSubModal?.status}>
        {openSubModal.status === true && openSubModal.type === 'delete' && <DeleteModal openModal={openSubModal} setOpenModal={setOpenSubModal} />}
        {openSubModal.status === true && openSubModal.type === 'thumbnail' && <Thumbnail openModal={openSubModal} setOpenModal={setOpenSubModal} />}
      </Portal>
    </ModalBackground>
  );
};

export default SafeEduModal;
