/**
 * 작성자 : 감광민
 * 날짜 : 2023.11.06
 * 기능 : tuigrid의 height 반응형 레이아웃 대응 wrapper
 */

import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
  componentRef?: any;
  scrollXHidden?: boolean;
  scrollYHidden?: boolean;
};

const Root = styled.div`
  flex-grow: 1;
  overflow: hidden;
  margin-left: 0.5rem;
  width: calc(100% - 0.5rem);
  &.scrollX-hidden {
    .tui-grid-body-area,
    .tui-grid-lside-area .tui-grid-body-area,
    .tui-grid-has-summary-top .tui-grid-body-area {
      overflow-x: hidden !important;
    }
  }
  &.scrollY-hidden {
    .tui-grid-body-area,
    .tui-grid-lside-area .tui-grid-body-area,
    .tui-grid-has-summary-top .tui-grid-body-area {
      overflow-y: hidden !important;
    }
  }
`;
const TuiGridWrapper = ({ children, componentRef, scrollXHidden = false, scrollYHidden = false }: Props) => {
  const size = useOutletContext<any>();

  if (size.innerSize.W > 0) {
    return (
      <Root ref={componentRef} className={`${scrollXHidden ? 'scrollX-hidden' : ''} ${scrollYHidden ? 'scrollY-hidden' : ''}`}>
        {children}
      </Root>
    );
  }
  return null;
};

export default TuiGridWrapper;
