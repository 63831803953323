import { useEffect, useRef, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import i18n from '../../../translation/i18n';
import { LIST_COUNT } from '../../../_constants';
import { IUser, userState } from '../../../atoms';
import { ContentsContainerRoot } from '../../../assets/styles/ContentsContainerRoot';
import { LoadingModalBackground } from '../../../assets/styles/Modal';
import TuiGrid from '../../../components/Table/TuiGrid';
import IssueGuide from '../../../components/IssueGuide';
import TuiGridWrapper from '../../../components/Table/TuiGridWrapper';
import { useGetFrozenCount } from '../../../utils/getFrozenCount';
import useSetListCount from '../../../utils/useSetListCount';
import IncompletedWorkerSearchOption from './IncompletedWorkerSearchOption';
import { logPost } from '../../../services/log';
import CustomRemoveBtnRenderer from '../../../components/CustomRemoveBtnRenderer';
import { useSetAuth } from '../../../utils/useSetAuth';
import { apiDelete } from '../../../services/_common';
import { toast } from 'react-toastify';
import Portal from '../../../components/Portal';
import DeleteModal from '../../../components/Modal/DeleteModal2';
import { IModal } from 'customTypes';

const Root = styled(ContentsContainerRoot)``;

const IncompleteWorker = () => {
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const userInfo = useRecoilValue<IUser>(userState);
  const { eExcelYn, eListYn } = userInfo;
  const { t } = useTranslation();
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [tableState, setTableState] = useState<any[]>([]);
  const [filterTableState, setFilterTableState] = useState<any[]>([]);
  const componentRef = useRef<HTMLDivElement>(null);
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  const [tuiWidth, setTuiWidth] = useState<null | number>(null);
  const [columns, setColumns] = useState<any[]>([]);
  const [excelBtn, setExcelBtn] = useState(false);
  const [rowKey, setRowKey] = useState('');
  const { listCount, setListCount } = useSetListCount('15'); // 검색줄수설정 훅
  const frozenCount = useGetFrozenCount();
  const [refetchFlag, setRefetchFlag] = useState(false);
  const [openModal, setOpenModal] = useState<IModal>({ status: false, type: '' });

  useEffect(() => {
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '태블릿 근로자 관리 > 근로자 관리 > 미완료 근로자',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    if (componentRef.current !== null) setTuiHeight(componentRef.current.offsetHeight);
  }, [componentRef.current?.offsetHeight]);

  useEffect(() => {
    if (componentRef.current !== null && componentRef.current !== undefined) setTuiWidth(componentRef.current?.offsetWidth);
  }, [componentRef.current?.offsetWidth]);

  const onClickTuiCustomButton = (targetRow: any) => {
    if (targetRow) {
      setRowKey(`${targetRow.rowKey}`);
      setOpenModal((prev) => ({ ...prev, status: true, type: 'delete', api: () => deleteAPI(targetRow.wCd) }));
    }
  };

  const deleteAPI = async (wCd: number) => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, wCd, editor: userInfo.userId };
    const res = await apiDelete({ path: '/worker/info', req });
    const { statusCode, message } = res.data;
    if (statusCode === 200) {
      setRowKey('');
      setRefetchFlag(true);
      toast.success(t(message));
    }
  };

  const fixedPrefixColumns = [
    {
      header: t('근로자명'),
      name: 'wName',
      sortable: true,
      minWidth: 120,
      renderer: { classNames: ['text_secondary'] },
    },
    {
      header: t('협력업체'),
      name: 'sjName',
      sortable: true,
      filter: 'select',
      minWidth: 120,
      renderer: { classNames: ['text_secondary'] },
    },
  ];

  const fixedSuffixColumns = [
    {
      header: t('직종'),
      name: 'wJobtypeName',
      sortable: true,
      filter: 'select',
      minWidth: 120,
    },
    {
      header: t('휴대폰번호'),
      name: 'wHnum',
      align: 'center',
      minWidth: 140,
    },
    {
      header: t('외국인 여부'),
      name: 'wForeignynName',
      align: 'center',
      sortable: true,
      filter: 'select',
      minWidth: 120,
    },
    {
      header: t('등록일자'),
      name: 'wDate',
      align: 'center',
      sortable: true,
      minWidth: 120,
    },
    {
      align: 'center',
      renderer: { type: CustomRemoveBtnRenderer, options: { onClickTuiCustomButton, auth, t, style: { text: '삭제', bgColor: '#f43f5e', hoverBgColor: 'rgba(246, 78, 96, 0.6)' } } },
      width: 80,
    },
  ];

  const prejobtypeColumn = [
    {
      header: t('공종'),
      name: 'wPrejobtypeName',
      sortable: true,
      filter: 'select',
      minWidth: 120,
    },
  ];

  useEffect(() => {
    const col = [...fixedPrefixColumns, ...(userInfo.prejobtypeYn === 'Y' ? prejobtypeColumn : []), ...fixedSuffixColumns];
    setColumns(col);
  }, [userInfo.prejobtypeYn, i18n.language]);

  const loading = isFetching;

  if (isError) return <IssueGuide />;

  return (
    <>
      <div className='content-container oneColumn'>
        <Root loading={loading}>
          <IncompletedWorkerSearchOption
            setIsFetching={setIsFetching}
            setIsError={setIsError}
            tableState={tableState}
            setTableState={setTableState}
            setRowKey={setRowKey}
            listCount={listCount}
            setListCount={setListCount}
            setExcelBtn={setExcelBtn}
            refetchFlag={refetchFlag}
            setRefetchFlag={setRefetchFlag}
          />
          <TuiGridWrapper componentRef={componentRef}>
            {tuiWidth !== null && (
              <TuiGrid
                data={tableState}
                filterTableState={filterTableState}
                setFilterTableState={setFilterTableState}
                columns={columns}
                perPage={Number(listCount[LIST_COUNT])}
                excelBtn={excelBtn}
                setExcelBtn={setExcelBtn}
                usePagenation
                scrollX
                height={tuiHeight}
                rowKey={rowKey}
                eListYn={eListYn}
                eExcelYn={eExcelYn}
                rowHeight={48}
                frozenCount={frozenCount}
                setRowKey={setRowKey}
              />
            )}
          </TuiGridWrapper>
        </Root>
        <Portal openModal={openModal?.status}>{openModal.status && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal>
      </div>
      {loading && (
        <LoadingModalBackground>
          <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' />
        </LoadingModalBackground>
      )}
    </>
  );
};

export default IncompleteWorker;
