/**
 * 작성자 : 한영광
 * 날짜 : 2023.07.10
 * 경로 : 센서 관리 - 미세먼지 측정기
 */
import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { PulseLoader } from 'react-spinners';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { pageInfoState, userState } from '../../atoms';
import { BtnGhost } from '../../components/Button';
import { LIST_COUNT, SENSOR_TIME } from '../../_constants';
import { SearchOptions } from '../../assets/styles/SearchOptions';
import { scrollToNodeTop } from '../../utils/scrollToNodeTop';
import { useDetectScrolledToBottom } from '../../utils/useDetectScrolledToBottom';
import { IoChevronUpSharp } from 'react-icons/io5';
import { FloatingButtonToTop } from '../../assets/styles/FloatingButtonToTop';
import Tab from '../../components/Tab';
import { IComCdList, ITabObject } from 'customTypes';
import { setComCdListState } from '../../utils/setComCdListState';
import { todayYYYYMMDD, toMonthYYYMMDD } from '../../utils/formatDate';
import { useReactToPrint } from 'react-to-print';
import Portal from '../../components/Portal';
import RangePicker, { onChangeRangeInput } from '../../components/RangePicker';
import LineChartModal from '../../components/Modal/lineChartModal';
import useOnKeydownF9 from '../../utils/useOnKeydownF9';
import { useSetAuth } from '../../utils/useSetAuth';
import { logPost } from '../../services/log';
import TuiGrid from '../../components/Table/TuiGrid';
import SelectBox from '../../components/SelectBox';
import illustrator from '../../assets/images/illustration/31.svg';
import i18n from '../../translation/i18n';
import { LoadingModalBackground } from '../../assets/styles/Modal';
import TuiGridWrapper from '../../components/Table/TuiGridWrapper';
import { useGetFrozenCount } from '../../utils/getFrozenCount';
import BackButton from '../../components/BackButton';
import useSetListCount from '../../utils/useSetListCount';
import nodataIllustrator from '../../assets/images/illustration/304.svg';
import { apiGet } from '../../services/_common';
import ShortcutButton from '../../components/button/ShortcutButton';

const Root = styled.div`
  width: 100%;
  &.hideRoot {
    visibility: hidden;
    position: absolute;
  }
  .tui-container {
    flex-grow: 1;
    overflow: hidden;
    margin-left: 0.5rem;
  }
  .emptyData {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    padding-bottom: 4rem;
    img {
      width: 16rem;
      /* user-drag: none; */
      -webkit-user-drag: none;
    }
    span {
      color: ${({ theme }: { theme: any }) => theme.filled_violet};
    }
  }
  .inputFormsWrapper {
    background-color: ${({ theme }: { theme: any }) => theme.board};
    border-radius: 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .searchContent {
    display: flex;
    justify-content: space-between;
    .buttonsWrapper {
      display: flex;
      margin-bottom: 0rem;
    }
  }
  .end {
    justify-content: end;
    > button {
      margin-left: 1rem;
    }
  }
  .mgas-responsive {
    flex-wrap: nowrap;
    @media (min-width: 1024px) {
      flex-wrap: wrap;
      overflow: visible !important;
    }
  }
`;
const Ssensor1 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const INIT_F_NAME = { type: 'fName', fName: 'A', cdName: t('전체') };
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const { isF9Pressed, setIsF9Pressed } = useOnKeydownF9(); // f9키 프레스 훅
  const { listCount, setListCount, patchUserMenuAPI } = useSetListCount(); // 검색줄수설정 훅
  const tabInfo = useRecoilValue(pageInfoState);
  const toDay = todayYYYYMMDD();
  const toMonth = toMonthYYYMMDD();
  const date = new Date();
  const userInfo = useRecoilValue(userState);
  const { userMenuList } = userInfo;
  const [rowState, setRowState] = useState<any>({});
  const [viewRowState, setViewRowState] = useState<boolean>(false);
  const [tableState, setTableState] = useState<any[]>([]);
  const [tableState2, setTableState2] = useState<any[]>([]);
  const [orgTableState2, setOrgTableState2] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [columns2, setColumns2] = useState<any[]>([]);
  const size = useOutletContext<any>();
  const { defaultMrCd } = useRecoilValue(pageInfoState);
  const [currentTabMrCd, setCurrentTabMrCd] = useState(defaultMrCd);
  const tableRef = useRef<HTMLDivElement>(null);
  const [visibleRangePicker, setVisibleRangePicker] = useState(false);
  const [rangeState, setRangeState] = useState([{ startDate: new Date(date.getFullYear(), date.getMonth(), 1), endDate: new Date(), key: 'selection' }]);
  const [listCountComCdList, setListCountComCdList] = useState<IComCdList[]>([]); // 검색줄수 공통코드
  const [fNameList, setFnameList] = useState<any[]>([]);
  const [searchOption, setSearchOption] = useState({ start: toMonth, end: toDay });
  const [fName, setFName] = useState(INIT_F_NAME);
  const [sensorTime, setSensorTime] = useState<any>({ type: 'time', time: '1', cdName: t('시간') });
  const [excelBtn, setExcelBtn] = useState(false);
  const [excelBtn2, setExcelBtn2] = useState(false);
  const [chartData, setChartData] = useState<any>([]);
  const [chartData2, setChartData2] = useState<any>([]);
  const [datasets] = useState<any>([
    {
      label: `PM2.5 ${t('평균')}`,
      data: 'avgPm25',
      borderColor: 'rgba(0, 221, 255, 0.5)',
      backgroundColor: 'rgba(0, 221, 255, 0.5)',
    },
    {
      label: `PM2.5 ${t('최소')}`,
      data: 'minPm25',
      borderColor: 'rgba(0, 119, 255, 0.5)',
      backgroundColor: 'rgba(0, 119, 255, 0.5)',
    },
    {
      label: `PM2.5 ${t('최대')}`,
      data: 'maxPm25',
      borderColor: 'rgba(0, 0, 255, 0.5)',
      backgroundColor: 'rgba(0, 0, 255, 0.5)',
    },
    {
      label: `PM10 ${t('평균')}`,
      data: 'avgPm10',
      borderColor: 'rgba(150, 0, 255, 0.5)',
      backgroundColor: 'rgba(150, 0, 255, 0.5)',
    },
    {
      label: `PM10 ${t('최소')}`,
      data: 'minPm10',
      borderColor: 'rgba(255, 0, 255, 0.5)',
      backgroundColor: 'rgba(255, 0, 255, 0.5)',
    },
    {
      label: `PM10 ${t('최대')}`,
      data: 'maxPm10',
      borderColor: 'rgba(255, 0, 110, 0.5)',
      backgroundColor: 'rgba(255, 0, 110, 0.5)',
    },
  ]);
  const [datasets2] = useState<any>([
    {
      label: 'PM2.5',
      data: 'pm25',
      borderColor: 'rgba(0, 221, 255, 0.5)',
      backgroundColor: 'rgba(0, 221, 255, 0.5)',
    },
    {
      label: 'PM10',
      data: 'pm10',
      borderColor: 'rgba(0, 119, 255, 0.5)',
      backgroundColor: 'rgba(0, 119, 255, 0.5)',
    },
  ]);
  const { isBottom } = useDetectScrolledToBottom(tableRef);
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '' });
  const [newTabList, setNewTabList] = useState<ITabObject[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const arr: any[] = [];
    userMenuList?.map((v: any) => {
      const result = tabInfo?.tabList?.find((el: ITabObject) => el.mtCd === v.mtCd && el.mdCd === v.mdCd && el.mrCd === v.mrCd);
      if (result) {
        arr.push(result);
        setNewTabList(arr);
      }
    });
  }, [tabInfo.tabList]);

  useEffect(() => {
    if (isF9Pressed) {
      onClickSearch();
      setIsF9Pressed(false);
    }
  }, [isF9Pressed]);

  useEffect(() => {
    if (!rangeState[0].startDate) {
      return;
    }
    setSearchOption((prev: any) => ({ ...prev, start: dayjs(rangeState[0].startDate).format('YYYY-MM-DD') }));
  }, [rangeState[0].startDate]);

  useEffect(() => {
    if (!rangeState[0].endDate) {
      return;
    }
    setSearchOption((prev: any) => ({ ...prev, end: dayjs(rangeState[0].endDate).format('YYYY-MM-DD') }));
  }, [rangeState[0].endDate]);

  useEffect(() => {
    setColumns([
      {
        header: t('장비명'),
        name: 'dvName',
        align: 'left',
        sortable: true,
        filter: 'select',
        width: 100,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('측정일자'),
        name: 'checkTime',
        align: 'center',
        sortable: true,
        // filter: 'select',
        minWidth: 130,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: `PM2.5${t('평균')}`,
        name: 'avgPm25',
        align: 'center',
        sortable: true,
        filter: 'number',
        minWidth: 130,
      },
      {
        header: `PM2.5${t('최소')}`,
        name: 'minPm25',
        align: 'center',
        sortable: true,
        filter: 'number',
        minWidth: 130,
      },
      {
        header: `PM2.5${t('최대')}`,
        name: 'maxPm25',
        align: 'center',
        sortable: true,
        filter: 'number',
        minWidth: 130,
      },
      {
        header: `PM10${t('평균')}`,
        name: 'avgPm10',
        align: 'center',
        sortable: true,
        filter: 'number',
        minWidth: 130,
      },
      {
        header: `PM10${t('최소')}`,
        name: 'minPm10',
        align: 'center',
        sortable: true,
        filter: 'number',
        minWidth: 130,
      },
      {
        header: `PM10${t('최대')}`,
        name: 'maxPm10',
        align: 'center',
        sortable: true,
        filter: 'number',
        minWidth: 130,
      },
    ]);
    setColumns2([
      {
        header: t('장비명'),
        name: 'dvName',
        align: 'left',
        sortable: true,
        width: 120,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: t('측정일자/시간'),
        name: 'checkTime',
        align: 'center',
        sortable: true,
        minWidth: 140,
        renderer: { classNames: ['text_secondary'] },
      },
      {
        header: 'PM2.5',
        name: 'pm25',
        align: 'center',
        sortable: true,
        filter: 'number',
        minWidth: 120,
      },
      {
        header: 'PM10',
        name: 'pm10',
        align: 'center',
        sortable: true,
        filter: 'number',
        minWidth: 120,
      },
    ]);

    setTableState2(orgTableState2.map((v: any) => ({ ...v, checkTime: `${v.checkTime}${t('시')}` })));
  }, [i18n.language]);

  useEffect(() => {
    setLoading(true);
    getSensorAPI().then((res: any) => {
      if (res.status === 200) {
        getNormalCdAPI();
        setComCdListState(LIST_COUNT, setListCountComCdList, false);
        logPost({
          hCd: userInfo.hCd,
          sCd: userInfo.sCd,
          userId: userInfo.userId,
          menu: '미세먼지 측정기',
          action: '조회',
          etc: ``,
        });
      }
    });
  }, []);

  useEffect(() => {
    if (Object.keys(rowState).length !== 0) getSensorDayAPI(rowState);
  }, [rowState, sensorTime]);

  // 센서 리스트 조회 API
  const getSensorAPI = async () => {
    const { start, end } = searchOption;
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, dvNo: fName.fName === 'A' ? '' : fName.fName, date1: start.replaceAll('-', ''), date2: end.replaceAll('-', '') };
    const res = await apiGet({ path: '/sensor/pm', req });
    const { data, statusCode } = res.data !== undefined && res.data;

    if (statusCode === 200) {
      setLoading(false);
      setTableState(data);
      const dvList = toDvList(data);
      const newData = dvList.map((v: any) => data.filter((v2: any) => v2.dvNo === v.dvNo));
      setChartData(newData);
    } else {
      setLoading(false);
      // toast.error(t(ERROR));
    }
    return res;
  };

  // 장비 리스트 조회 API
  const getNormalCdAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, grCd: '01' };
    const res = await apiGet({ path: '/sensor/detail', req });
    const { data, statusCode } = res.data !== undefined && res.data;
    if (statusCode === 200) {
      const newList = [];
      newList.push(INIT_F_NAME);
      data.sensorList.map((v: any) => newList.push({ type: 'fName', fName: v.subCd, cdName: v.sName }));
      setFnameList(newList);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 센서 일별 조회 API
  const getSensorDayAPI = async (rowData: any) => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, dvNo: rowData.dvNo, date: rowData.checkTime?.replaceAll('-', ''), time: sensorTime.time };
    const res = await apiGet({ path: '/sensor/pmDay', req });
    const { data, statusCode } = res.data !== undefined && res.data;
    if (statusCode === 200) {
      if (sensorTime.time === '1') {
        setOrgTableState2(data);
        setTableState2(data.map((v: any) => ({ ...v, checkTime: `${v.checkTime}${t('시')}` })));
        setChartData2([data]);
      } else {
        setTableState2(data);
        setChartData2([data]);
      }
    } else {
      // toast.error(t(ERROR));
    }
  };

  const toDvList = (data: any[]) => {
    const map = new Map(data.map((m: any) => [m.dvNo, m])).entries();
    return Array.from(map).map((v: any) => {
      return { dvNo: v[1].dvNo, dvName: v[1].dvName };
    });
  };

  // 검색 버튼 클릭
  const onClickSearch = () => {
    if (!openModal.status) {
      if (searchOption.end && searchOption.start > searchOption.end) {
        toast.warning(t('날짜를 다시 입력하세요'));
        setSearchOption((prev: any) => ({ ...prev, end: '' }));
      } else {
        setLoading(true);
        setRowState({});
        setTableState2([]);
        setVisibleRangePicker(false);
        navigate({ pathname: location.pathname, search: '' });
        getSensorAPI();
      }
    }
  };

  // 초기화 버튼 클릭
  const onClickInitiateSearchOption = () => {
    Object.keys(searchOption).map((el: any) => {
      return setSearchOption((prev) => ({ ...prev, [el]: '', start: toMonth, end: toDay }));
    });
    setFName(INIT_F_NAME);
    setRangeState([{ startDate: new Date(date.getFullYear(), date.getMonth(), 1), endDate: new Date(), key: 'selection' }]);
  };

  // 차트 버튼 클릭
  const onClickChart = () => {
    if (tableState.length !== 0) setOpenModal({ status: true, type: 'lineChart' });
    else toast.warning(t('데이터가 없습니다'));
  };

  // 차트2 버튼 클릭
  const onClickChart2 = () => {
    if (tableState2.length !== 0) setOpenModal({ status: true, type: 'lineChart2' });
    else toast.warning(t('데이터가 없습니다'));
  };

  // 엑셀 저장 버튼 클릭
  const onClickExcelSave = () => {
    if (tableState.length <= 0) return;
    setExcelBtn(true);
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '미세먼지 측정기',
      action: '엑셀 저장',
      etc: `${searchOption.start}~${searchOption.end}`,
    });
  };

  // 엑셀 저장 버튼 클릭
  const onClickExcelSave2 = () => {
    if (tableState2.length <= 0) return;
    setExcelBtn2(true);
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '미세먼지 측정기',
      action: '엑셀 저장',
      etc: `${rowState.checkTime}`,
    });
  };

  const componentRef = useRef<HTMLDivElement>(null);
  const componentRef2 = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({ content: () => componentRef.current });
  const handlePrint2 = useReactToPrint({ content: () => componentRef2.current });
  // 인쇄 버튼 클릭
  const onClickPrint = () => {
    handlePrint();
  };
  // 인쇄 버튼 클릭
  const onClickPrint2 = () => {
    handlePrint2();
  };

  // 측정기 Row 클릭
  const onClickRow = async (rowData: any) => {
    if (rowData) {
      setRowState(rowData);
      setViewRowState(true);
    }
  };
  const backToMain = () => {
    setRowState({});
    if (rowState.length === undefined) {
      setViewRowState(false);
    }
  };
  const renderExcelBtn = () => {
    if (auth.excelAuth) {
      return (
        <BtnGhost onClick={onClickExcelSave}>
          <span className='icon-ms-xlsx'>X</span> {t('엑셀')}
        </BtnGhost>
      );
    }
    return null;
  };

  const renderPrintBtn = () => {
    if (auth.printAuth) {
      return (
        <BtnGhost onClick={onClickPrint}>
          <span className='material-symbols-rounded'>print</span>
          {t('인쇄')}
        </BtnGhost>
      );
    }
    return null;
  };

  const renderExcelBtn2 = () => {
    if (auth.excelAuth) {
      return (
        <BtnGhost onClick={onClickExcelSave2}>
          <span className='icon-ms-xlsx'>X</span> {t('엑셀')}
        </BtnGhost>
      );
    }
    return null;
  };

  const renderPrintBtn2 = () => {
    if (auth.printAuth) {
      return (
        <BtnGhost onClick={onClickPrint2}>
          <span className='material-symbols-rounded'>print</span>인쇄
        </BtnGhost>
      );
    }
    return null;
  };

  const onClickRangeInput = () => {
    if (!visibleRangePicker) setVisibleRangePicker(true);
  };

  const onClickTab = (tab: string) => {
    setCurrentTabMrCd(tab);
  };
  const [tuiHeight, setTuiHeight] = useState<null | number>(null);
  useEffect(() => {
    if (componentRef.current !== null) {
      setTuiHeight(componentRef.current.offsetHeight);
    }
  }, [componentRef.current?.offsetHeight, componentRef.current?.offsetWidth]);
  const [tuiHeight2, setTuiHeight2] = useState<null | number>(null);
  useEffect(() => {
    if (componentRef2.current !== null) {
      setTuiHeight2(componentRef2.current.offsetHeight);
    }
  }, [componentRef2.current?.offsetHeight, componentRef2.current?.offsetWidth]);

  const frozenCount = useGetFrozenCount();

  return (
    <div className='contents'>
      <Tab tabList={newTabList || tabInfo.tabList} currentTabMrCd={currentTabMrCd} setCurrentTabMrCd={setCurrentTabMrCd} onClickTab={onClickTab} size={size} />
      <div className={`content-container ${size.innerSize.W >= 1024 ? 'twoColumn column-55 mediaSet768' : 'oneColumn'}`}>
        <Root className={size.innerSize.W >= 1024 || !viewRowState ? 'showRoot' : 'hideRoot'}>
          <SearchOptions>
            {isBottom && (
              <FloatingButtonToTop>
                <button type='button' onClick={() => scrollToNodeTop(tableRef)}>
                  <IoChevronUpSharp size={20} style={{ stroke: 'white' }} />
                </button>
              </FloatingButtonToTop>
            )}
            <div className='inputsWrapper'>
              <div className='inputForm-col withLabelComCf'>
                <label htmlFor='fName'>{t('장비')}</label>
                <SelectBox
                  options={fNameList}
                  defaultOption={t('전체')}
                  state={fName}
                  setState={setFName}
                  stateKey='fName'
                  filterbar='filter-1-left'
                  initiateKey={fName.fName}
                  optionHeight='height-md'
                />
              </div>
              <div className='inputForm-row-fit'>
                <span className='calendarLabel'>{t('조회일자')}</span>
                <div className='inputForm-col'>
                  <div className='flex-basic'>
                    <input id='startInput' type='text' value={searchOption.start} onMouseDown={onClickRangeInput} onChange={(e) => onChangeRangeInput(e, 'start', setRangeState, setSearchOption)} />
                    <span className='inputDash'> ~ </span>
                    <input id='endInput' type='text' value={searchOption.end} onMouseDown={onClickRangeInput} onChange={(e) => onChangeRangeInput(e, 'end', setRangeState, setSearchOption)} />
                  </div>
                  {visibleRangePicker && (
                    <div className='rangePickerWrapper'>
                      <RangePicker state={rangeState} setState={setRangeState} setVisible={setVisibleRangePicker} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='inputsWrapper'>
              <div className='secondSearchOption'>
                <div className='flex-basic textBtnGroup'>
                  <ShortcutButton icon='search' buttonText={t('검색')} shortcut='F9' onClick={onClickSearch} />
                  {/* <BtnGhost onClick={onClickSearch} className='searchBtn'>
                    {t('검색')}
                    <span className='shortcut-f9'>F9</span>
                  </BtnGhost> */}
                  <BtnGhost onClick={onClickInitiateSearchOption}>{t('초기화')}</BtnGhost>
                  <div className='searchResult'>
                    {t('총')}
                    <span>{tableState.length}</span>
                    {t('개')}
                  </div>
                  <div className='inputForm-row'>
                    <div className='inputForm-col withLabelComCf'>
                      <label htmlFor='useYn'>{t('보기 설정')}</label>
                      <SelectBox
                        options={listCountComCdList}
                        defaultOption={listCount.cdName}
                        state={listCount}
                        setState={setListCount}
                        stateKey={LIST_COUNT}
                        initiateKey={listCount[LIST_COUNT]}
                        setTableLinesAPI={patchUserMenuAPI}
                        optionHeight='height-sm'
                        rsearch
                      />
                    </div>
                  </div>
                </div>

                <div className='flex-basic iconBtnGroup'>
                  <BtnGhost onClick={onClickChart}>
                    <span className='material-symbols-rounded'>monitoring</span>
                    {t('차트')}
                  </BtnGhost>
                  {renderExcelBtn()}
                  {/* {renderPrintBtn()} */}
                </div>
              </div>
            </div>
          </SearchOptions>
          {tableState.length > 0 ? (
            <TuiGridWrapper componentRef={componentRef}>
              <TuiGrid
                data={tableState}
                columns={columns}
                perPage={Number(listCount[LIST_COUNT])}
                excelBtn={excelBtn}
                setExcelBtn={setExcelBtn}
                usePagenation
                onClickRow={onClickRow}
                scrollX
                height={tuiHeight}
                frozenCount={frozenCount}
              />
            </TuiGridWrapper>
          ) : (
            <div className='emptyData'>
              <img src={nodataIllustrator} alt='noData' />
              <span>{t('해당 일자의 데이터가 존재하지 않습니다')}</span>
            </div>
          )}
        </Root>
        {(size.innerSize.W >= 1024 || viewRowState) &&
          (viewRowState ? (
            <Root>
              <SearchOptions align='left'>
                <div className='inputsWrapperGroup'>
                  {size.innerSize.W < 1024 && <BackButton func={() => backToMain()} />}
                  <div className='inputsWrapper sensor-results'>
                    <div className='sensor-result-header'>
                      <span>{t('측정일')}</span>
                      <div>{rowState.checkTime}</div>
                    </div>
                    <div className='sensor-result-data'>
                      <span>PM2.5</span>
                      <div>
                        <span>{t('평균')}</span>
                        {rowState.avgPm25}
                      </div>
                      <div>
                        <span>{t('최소')}</span>
                        {rowState.minPm25}
                      </div>
                      <div>
                        <span>{t('최대')}</span>
                        {rowState.maxPm25}
                      </div>
                    </div>
                    <div className='sensor-result-data'>
                      <span>PM10</span>
                      <div>
                        <span>{t('평균')}</span>
                        {rowState.avgPm10}
                      </div>
                      <div>
                        <span>{t('최소')}</span>
                        {rowState.minPm10}
                      </div>
                      <div>
                        <span>{t('최대')}</span>
                        {rowState.maxPm10}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='inputsWrapper sensorOverflow'>
                  <div className='secondSearchOption mgas-responsive'>
                    <div className='flex-basic shrink-0'>
                      <SelectBox options={SENSOR_TIME} defaultOption={t('시간')} state={sensorTime} setState={setSensorTime} stateKey='time' />
                    </div>
                    <div className='flex-basic iconBtnGroup'>
                      <BtnGhost onClick={onClickChart2}>
                        <span className='material-symbols-rounded'>monitoring</span>
                        {t('차트')}
                      </BtnGhost>
                      {renderExcelBtn2()}
                      {/* {renderPrintBtn2()} */}
                    </div>
                  </div>
                </div>
              </SearchOptions>
              <TuiGridWrapper componentRef={componentRef2}>
                <TuiGrid
                  data={tableState2}
                  columns={columns2}
                  perPage={Number(listCount[LIST_COUNT])}
                  excelBtn={excelBtn2}
                  setExcelBtn={setExcelBtn2}
                  usePagenation
                  height={tuiHeight2}
                  frozenCount={frozenCount}
                  scrollX
                />
              </TuiGridWrapper>
            </Root>
          ) : (
            <Root>
              <div className='emptyData'>
                <img src={illustrator} alt='noData' />
                <span>{t('왼쪽 목록에서 센서를 선택해주세요')}</span>
              </div>
            </Root>
          ))}
      </div>
      <Portal openModal={loading}>
        <LoadingModalBackground>
          <PulseLoader className='flex-center' color='rgb(0, 122, 255)' size='1rem' style={{ width: 'inherit', height: 'inherit' }} />
        </LoadingModalBackground>
      </Portal>
      <Portal openModal={openModal?.status}>
        {openModal.status && openModal.type === 'lineChart' && <LineChartModal setOpenModal={setOpenModal} chartData={chartData} datasets={datasets} title={t('미세먼지 측정기')} />}
        {openModal.status && openModal.type === 'lineChart2' && <LineChartModal setOpenModal={setOpenModal} chartData={chartData2} datasets={datasets2} title={t('미세먼지 측정기')} />}
      </Portal>
    </div>
  );
};

export default Ssensor1;
