/**
 * 작성자 : 홍선영
 * 날짜 : 2024.05.20
 * 기능 : 근로자 출입 현황 대시보드 컴포넌트 (다중현장)
 */

import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PulseLoader } from 'react-spinners';
import { useQuery } from '@tanstack/react-query';

import useDashboardTimer from '../../utils/useDashboardTimer';
import { apiGet } from '../../services/_common';
import { PhotoTableListStyle } from '../../assets/styles/PhotoTableList';
import IssueGuide from '../IssueGuide';
import Slider from 'react-slick';
import { sortArrayByTimeDesc } from '../../utils/arraySortByTimeDescOrder';

interface Iprops {
  name: string;
  userInfo: any;
  refreshTimeCount: number;
  uniqueKey: string;
}

interface IworkerAttend {
  aImg?: any;
  wCd: string;
  wName: string;
  sjCd: string;
  sjName: string;
  wPrejobtype: string;
  wPrejobtypeName: string;
  wJobtype: string;
  wJobtypeName: string;
  aDate: string;
  fLocationName: string;
  aTime: string;
  sName: string;
}

export const WorkerAttendMultiSiteExit = ({ name, userInfo, refreshTimeCount, uniqueKey }: Iprops) => {
  const { t } = useTranslation();
  const { timer, setTimer } = useDashboardTimer(refreshTimeCount);
  const tabRefs = useRef<(any | null)[]>([]);
  const [tabList, setTabList] = useState([{ msCd: userInfo.sCd, msName: '당 현장', sliderIndex: 0 }]);
  const [slideTimer, setSlideTimer] = useState(0);
  const [activeSlide, setActiveSlide] = useState<number>(0);

  useEffect(() => {
    if (timer <= 0) {
      refetch();
      refetchMsite();
      setTimer(refreshTimeCount);
    }
  }, [timer]);

  // 다중현장목록 조회
  const {
    data: msite,
    isLoading: isLoadingMsite,
    isError: isErrorMsite,
    isFetched: isFetchedMsite,
    isRefetching: isRefetchingMsite,
    refetch: refetchMsite,
  } = useQuery([`msiteGet_${uniqueKey}`, userInfo.hCd, userInfo.sCd], () => fetchMsiteData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd,
  });

  // 근로자 출입현황 조회
  const { data, isLoading, isError, refetch } = useQuery(['attendMultiexitGet', userInfo.hCd, userInfo.sCd], () => fetchData(), {
    enabled: !!userInfo.hCd && !!userInfo.sCd && !!isFetchedMsite && isRefetchingMsite === false,
  });

  const fetchMsiteData = async () => {
    try {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
      const res = await apiGet({ path: `/site/msite`, req });
      const newArray = res.data.data?.filter((el: any) => el.useYn === 'Y')?.map((el2: any) => ({ msCd: el2.msCd, msName: el2.msName }));

      // 현장코드로 정렬
      newArray.sort((a: any, b: any) => {
        const numA = parseInt(a.msCd.substring(1), 10);
        const numB = parseInt(b.msCd.substring(1), 10);
        return numA - numB;
      });
      const addIndex = newArray.map((el: any, i: number) => ({ ...el, sliderIndex: i + 1 }));
      const addMyMsite = [{ msCd: userInfo.sCd, msName: '당 현장', sliderIndex: 0 }, ...addIndex];
      return addMyMsite;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  const fetchData = async () => {
    try {
      if (isFetchedMsite && !isRefetchingMsite && msite) {
        const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
        const res = await apiGet({ path: `/attend/multiexit`, req });
        console.log('res', res);
        const attendList = res.data.data?.attendList || [];
        const totalWorkers = attendList.length;

        // 출역내역을 기반으로 현장별 근로자목록 생성
        const groupedData = groupBy(attendList, 'msCd');

        const result = Object.keys(groupedData).map((msCd, i) => ({
          msCd,
          worker: sortArrayByTimeDesc(groupedData[msCd]),
          sliderIndex: msite.find((el: any) => el.msCd === msCd)?.sliderIndex,
        }));

        // 현장코드로 정렬
        result.sort((a: any, b: any) => {
          const numA = parseInt(a.msCd.substring(1), 10);
          const numB = parseInt(b.msCd.substring(1), 10);
          return numA - numB;
        });

        // msName에 인원추가하여 탭리스트 setState
        const updatedTabList = msite.map((item: any) => {
          const match = result.find((bItem: any) => bItem.msCd === item.msCd);
          if (match) return { ...item, msName: `${item.msName}`, count: match.worker.length };
          return { ...item, msName: `${item.msName}`, count: 0 };
        });
        setTabList(updatedTabList);
        return { result, totalWorkers };
      }
      return null;
    } catch (error) {
      console.error(error);
      throw new Error('error');
    }
  };

  const groupBy = (array: any, key: any) => {
    return array.reduce((result: any, currentValue: any) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
      return result;
    }, {});
  };

  const settings = {
    // className: 'center',
    // centerMode: true,
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 1000,
    slidesToShow: tabList.length,
    slidesToScroll: 1,
    autoplay: tabList.length >= 2,
    // autoplaySpeed: slideTimer <= 3 ? 5000 : slideTimer * 1400,
    autoplaySpeed: 20 * 1000,
    beforeChange: (current: number, next: number) => {
      if (data) {
        const nextTabLength = data?.result.find((el) => el.sliderIndex === next)?.worker.length;
        if (nextTabLength !== undefined) {
          setSlideTimer(nextTabLength);
        }
      }
      setActiveSlide(next);
    },
  };

  if (isError || isErrorMsite) return <IssueGuide />;

  if (isLoading || isLoadingMsite || !settings) {
    return (
      <div className='centered-content'>
        <PulseLoader color='rgb(0, 122, 255)' size='10px' />
      </div>
    );
  }

  return (
    <PhotoTableListStyle>
      <div className='widget-header safety'>
        <div className='widget-title flex-between'>
          <span>
            {t('근로자 출입현황 (1개 출구용)')}
            <span className='badge'>{data?.totalWorkers || 0}</span>
          </span>
        </div>
      </div>
      <div className='widget-body photoTable'>
        <div className='innerTab nonClick'>
          <Slider key={tabList?.length} {...settings}>
            {tabList?.map((el: any, i: number) => (
              <div key={el.sliderIndex} className={activeSlide === el.sliderIndex ? 'active' : undefined} ref={tabRefs.current[i]}>
                <span className='tabName'>{el.msName}</span> ({el.count})
              </div>
            ))}
          </Slider>
        </div>
        <div className='sliderWrapper'>
          <div className='text-container active'>
            <WorkerList key={activeSlide} workerList={data?.result.find((el) => el.sliderIndex === activeSlide)?.worker || []} />
          </div>
        </div>
      </div>
    </PhotoTableListStyle>
  );
};

const WorkerList = ({ workerList }: { workerList: IworkerAttend[] }) => {
  const lastElementRef = useRef(null); // Create a ref for the last element
  const [animationKey, setAnimationKey] = useState(0);

  useEffect(() => {
    setAnimationKey((prevKey: any) => prevKey + 1);
  }, [workerList]);

  const animationStyle = {
    animation: `slide ${workerList.length * 1.6}s linear infinite`,
    paddingTop: `${workerList.length > 14 ? `2rem` : undefined}`,
  };

  if (workerList.length === 0) return null;
  return (
    <div key={animationKey} style={workerList.length > 14 ? animationStyle : undefined}>
      {workerList?.map((worker: IworkerAttend, index) => (
        <div key={`${worker.wCd}_${index}`} className='rowWrapper flex-basic' ref={index === workerList.length - 1 ? lastElementRef : null}>
          <div className='flex-basic'>
            <div className='imgWrapper'>{worker.aImg ? <img src={worker.aImg} alt='' /> : <span className='material-symbols-rounded'>person </span>}</div>
            <div className='flex-col'>
              <div className='flex-basic flex-between'>
                <div className='ellipsis' style={{ paddingRight: '0.5rem' }}>
                  {worker.wName}
                </div>
                <div className='buttonsWrapper flex-basic'>
                  <button type='button' className='amber'>
                    <span>{worker.fLocationName}</span>
                  </button>
                </div>
              </div>

              <div className='flex-basic flex-between'>
                <div style={{ paddingRight: '0.5rem' }}>{worker.sName}</div>
                <div className='buttonsWrapper flex-basic'>
                  <button type='button' className='gray'>
                    <span>{worker.aTime}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
