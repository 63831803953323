import styled from 'styled-components';

interface IModalStyle {
  minWidth?: number;
  maxWidth?: string;
}

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5010;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 60%);
  backdrop-filter: blur(0.5rem);

  &.leftedModal {
    display: flex;
    align-items: center;
  }

  .cctvWrapperHidden {
    height: 0.1rem;
    width: 0.1rem;
  }

  .icon-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    gap: 0.25rem;
    cursor: pointer;
    border-radius: 5rem;
    padding-right: 1rem;
    padding-left: 0.5rem;
    font-size: 0.875rem;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    height: 100%;
  }
  .icon-btn:hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
  }
  .closeBtn {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5rem;
    cursor: pointer;
    margin: 0 0.5rem;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }
  .closeBtn:hover {
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
  }
  .modal-filter {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 5020;
  }
`;

export const Modal = styled.div<IModalStyle>`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .raw-html {
    &.modal {
      width: 1060px;
      height: 691px;
    }

    .inputForm-body {
      padding: 2rem;
    }

    td {
      padding: 8px !important;
    }

    figure {
      margin: 0 auto;
      text-align: -webkit-center;
    }
  }

  .signup {
    ul > .defaultOption {
      width: inherit !important;
      max-width: inherit !important;
    }
  }

  .tui-grid-lside-area {
    overflow: initial !important;
    .tui-grid-body-area {
      overflow: scroll hidden;
    }
  }
  label.w5rem {
    width: 5rem;
    flex-shrink: 0;
  }
  label.w6rem {
    width: 6rem;
    flex-shrink: 0;
  }
  label.w7rem {
    width: 7rem;
    flex-shrink: 0;
  }
  .pb05 {
    padding-bottom: 0.5rem;
  }
  .inModal {
    z-index: 6000;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 2rem);
    width: 64rem;
    background-color: ${({ theme }: { theme: any }) => theme.board};
    border-radius: 0.5rem;
    overflow: hidden;
    @media screen and (max-width: 1023px) {
      /* width: 80%; */
      width: calc(100vw - 2rem);
    }
    @media (max-width: 767px) {
      width: 100%;
      height: calc(var(--vh, 1vh) * 100);
      position: absolute;
      top: 0;
      padding-bottom: env(safe-area-inset-bottom); //ios 노치 대응
      overflow-y: auto;
      border-radius: 0;
    }
    .innerTabChip {
      overflow: overlay;
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      border-radius: 5rem;
      &::-webkit-scrollbar {
        height: 0rem;
      }
      .tab {
        flex-shrink: 0;
        padding: 0 1.5rem;
        height: 2.5rem;
        display: flex;
        border-radius: 5rem;
        align-items: center;
        cursor: pointer;
        user-select: none;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
      }
      .tab:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
      .activeTab {
        pointer-events: none;
        color: ${({ theme }: { theme: any }) => theme.selected_text};
        background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
      }
      /* .activeTab: */
    }
    .innerTab {
      /* padding-bottom: 2rem; */
      .tab {
        padding: 0 1rem;
        cursor: pointer;
        height: 3rem;
      }

      .activeTab {
        font-weight: bold;
        border-bottom: 2px solid rgb(63, 66, 84);
      }
    }
    > .modal-body {
      flex-grow: 1;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      overflow: auto;
      gap: 1rem;
      @media (min-width: 760px) {
        gap: 2rem;
        flex-direction: row;
      }
      > div,
      > ul {
        flex: 1 1 50%;
      }
      > div {
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem 0;
        @media (min-width: 760px) {
          padding: 0;
          position: sticky;
          top: 0;
        }
        img {
          max-width: 12rem;
          -webkit-user-drag: none;
          @media (min-width: 760px) {
            max-width: 16rem;
          }
        }
        div {
          margin: 0 2rem;
          word-break: keep-all;
          text-align: center;
        }
      }
      > ul {
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
        /* padding: 0 1rem; */
        display: flex;
        flex-direction: column;
        gap: 1rem;
        /* overflow: auto; */
        width: 100%;
        height: fit-content;
        li {
          width: 100%;
          display: flex;
          gap: 1rem;
          overflow: visible;
          > div {
            flex-grow: 1;
            white-space: normal;
            h6 {
              font-weight: 400;
            }
            > div.lists {
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
            }
            p,
            span {
              /* font-size: 0.875rem; */
            }
            p {
              white-space: normal;
              word-break: keep-all;
            }
            div.description {
              display: flex;
              align-items: center;
              margin: 0.25rem 0;
              color: rgba(0, 0, 0, 0.6);
              font-size: 0.875rem;
              padding: 0.25rem;
              color: ${({ theme }: { theme: any }) => theme.text_secondary};
              background-color: ${({ theme }: { theme: any }) => theme.tonal};
              border-radius: 0.125rem;
              span {
                font-size: 1rem;
                color: rgba(0, 0, 0, 0.6);
              }
            }
            a {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 2.5rem;
              border-radius: 0.25rem;
              margin-top: 0.5rem;
              background-color: ${({ theme }: { theme: any }) => theme.tonal};
            }
            a:hover {
              background-color: rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
    }
    > .inputForm-head {
      font-weight: 500;
      flex-shrink: 0;
      padding: 0.5rem 0.5rem;
      border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      > .icon-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        gap: 0.25rem;
        cursor: pointer;
        border-radius: 5rem;
        padding-right: 1rem;
        padding-left: 0.5rem;
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_primary};
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        height: 100%;
      }
      > .icon-btn:hover {
        background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
      }
      > svg {
        width: 24px;
        height: 24px;
      }
      .closeBtn {
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5rem;
        cursor: pointer;
        margin: 0 0.5rem;
      }
      .closeBtn:hover {
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
      }
      .material-symbols-rounded {
        color: ${({ theme }: { theme: any }) => theme.text_primary};
        font-variation-settings: 'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 20;
      }
    }
    .inputForm-body {
      > .searchWrapper {
        padding: 0.5rem 0.75rem;
        @media (max-width: 640px) {
          flex-direction: column;
          align-items: flex-start;
        }
        .flex-basic {
          gap: 0.5rem;
        }
        .flex-end {
          gap: 1rem;
          > .flex-basic {
            > div,
            > span {
              background-color: ${({ theme }: { theme: any }) => theme.tonal};
              padding: 0 0.5rem;
              height: 2.5rem;
              font-size: 0.875rem;
              border-radius: 0.25rem;
              color: ${({ theme }: { theme: any }) => theme.text_primary};
            }
            > span {
              display: flex;
              align-items: center;
            }
          }
        }
      }
      > div label {
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_secondary};
      }
    }
    .inputForm-body.tui-body {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .modal-contents {
        flex-grow: 1;
        padding-bottom: 0.25rem;
        overflow: hidden;
        .tui-container {
          flex-grow: 1;
          height: 100%;
          overflow: hidden;
          margin-left: 1rem;
          .tui-grid-rside-area {
            overflow: auto;
          }
        }
      }
    }
    .fileBoxWrapper {
      /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
      width: 100%;
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      padding: 0.5rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 0.875rem;
      input {
        width: 20rem;
        font-size: 0.875rem;
        line-height: 2.5rem;
        padding: 0 0.5rem;
        /* display: flex;
          align-items: center; */
      }
      label {
        width: 6rem;
        height: 100%;
        display: flex;
        align-items: center;
        div {
          height: 100%;
          font-size: 0.875rem;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .modal {
    z-index: 6000;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 2rem);
    width: calc(100vw - 2rem);
    background-color: ${({ theme }: { theme: any }) => theme.board};
    border-radius: 0.5rem;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      width: 100%;
      height: 100vh;
      height: inherit;
      overflow-y: auto;
      border-radius: 0;

      height: calc(var(--vh, 1vh) * 100);
      position: absolute;
      // top: 0;
      padding-bottom: env(safe-area-inset-bottom); //ios 노치 대응
    }

    &.padding-bottom {
      padding-bottom: 0.5rem;
    }
    @media (min-width: 768px) {
      width: ${(props) => (props.minWidth ? props.minWidth : 40)}rem;
      &.minHeight-609 {
        max-height: 609px;
      }
      &.dailyPersonalAttend {
        width: 80rem;
        margin: 0 1rem;
      }
    }
    .modal-contents {
      overflow: auto;
      flex-grow: 1;
    }
    .flex-grow {
      flex-grow: 1;
    }
    > .inputForm-head {
      position: relative;
      font-weight: 500;
      flex-shrink: 0;
      /* font-size: 1.125rem; */
      /* padding: 0 1rem; */
      padding: 0.5rem;
      height: 3.5rem;
      border-bottom: 1px solid ${({ theme }: { theme: any }) => theme.outline};
      > svg {
        width: 24px;
        height: 24px;
      }
      .modalHeaderTitle {
        padding: 0 0.25rem;
        color: ${({ theme }: { theme: any }) => theme.text_primary};
        display: flex;
        align-items: center;
        &.camera-channel {
          display: flex;
          align-items: center;
        }
        .camera-type {
          margin: 0 0.5rem;
          display: flex;
          gap: 0.25rem;
          > span {
            display: flex;
            gap: 0.25rem;
            flex-direction: row;
            height: 1.75rem;
            align-items: center;
            padding: 0 0.5rem;
            font-size: 0.875rem;
            border-radius: 1rem;
            color: ${({ theme }: { theme: any }) => theme.text_disabled};
            background-color: ${({ theme }: { theme: any }) => theme.tonal};
            .bullet {
              display: flex;
              padding: 0.375rem;
              border-radius: 1rem;
            }
            &.online {
              font-weight: 600;

              background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
              color: ${({ theme }: { theme: any }) => theme.filled_green};
              .bullet {
                background-color: ${({ theme }: { theme: any }) => theme.filled_green};
              }
            }
            &.offline {
              font-weight: 600;

              background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
              color: ${({ theme }: { theme: any }) => theme.filled_red};
              .bullet {
                background-color: ${({ theme }: { theme: any }) => theme.filled_red};
              }
            }
          }
        }
      }
      .modalHeaderCenter {
        width: 100%;
        text-align: center;
        color: ${({ theme }: { theme: any }) => theme.text_primary};
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
      }
    }
    .tuiGridWrapper {
      flex-grow: 1;
      overflow: auto;
      &.overflow-none {
        overflow: hidden;
        .tui-grid-pagination {
          padding-top: 0.45rem;
          padding-bottom: 0.55rem;
        }
      }
    }
    .filter-option-flex {
      padding: 0.5rem;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      .modal-element > div {
        display: flex;
        gap: 0.5rem;
        input {
          width: 8rem;
          font-size: 0.875rem;
        }
        button {
          height: 2.5rem;
          width: 4rem;
          font-size: 0.875rem;
        }
      }
    }
    .filter-option-grid {
      padding: 0.5rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.5rem 2rem;
      > .viewOnly {
        display: flex;
        align-items: center;
        label {
          font-size: 0.875rem;
          padding: 0 0.25rem;
        }
        div {
          flex-grow: 1;
          height: 2.5rem;
          padding: 0 0.75rem;
          display: flex;
          align-items: center;
          font-size: 0.875rem;
          color: ${({ theme }: { theme: any }) => theme.text_disabled};
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          border-radius: 0.125rem;
        }
      }
      > .selectWrapper {
        display: flex;
        align-items: center;
        label {
          font-size: 0.875rem;
          width: 7rem;
          padding: 0 0.25rem;
        }
        > div {
          flex-grow: 1;
          > ul {
            width: 100%;
            > li {
              width: 100%;
              span {
                width: 100%;
              }
            }
          }
          > div {
            width: 100%;
          }
        }
      }

      .datePickerWrapper {
        z-index: 5030;
      }

      > div:nth-child(n + 2) > div,
      .datePickerWrapper,
      .reactSelectrWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0;
        label {
          font-size: 0.875rem;
          padding: 0 0.25rem;
        }
        .editPositionDatePicker {
          width: 100%;
          .react-datepicker__triangle {
            display: none;
          }
          .react-datepicker-popper {
            transform: translate(-50%, -50%) !important;
            top: 50% !important;
            left: calc(50% - 6rem) !important;
          }
        }
        input,
        .react-datepicker-wrapper {
          height: 2.5rem;
          width: 7rem;
          flex-grow: 1;
          font-size: 0.875rem;
          input {
            font-size: 0.875rem;
            width: 100%;
          }
        }
        .select__value-container {
          text-align: left;
          margin-left: 0.5rem;
        }
      }
      .reactSelectrWrapper > div {
        flex-grow: 1;
        font-size: 0.875rem;
      }
      > div.span2 {
        grid-column: span 2;
        label {
          font-size: 0.875rem;
        }
      }
      .fileBoxWrapper {
        justify-content: flex-start;
        label {
          padding: 0px 0.25rem;
        }
        span {
          font-size: 0.875rem;
        }
        > div.flex-basic {
          width: 50%;
          flex-grow: 1;
          display: flex;
          gap: 0.5rem;
          > div {
            width: 5rem;
            > label {
              padding: 0;
              width: 100%;
              > div {
                display: flex;
                height: 2.5rem;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }
      .fileBoxWrapper.preview {
        width: 100%;
        > .preview {
          flex: 1 1 50%;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          img {
            width: 100%;
            background-color: ${({ theme }: { theme: any }) => theme.tonal};
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 0.25rem;
          }
          .buttonsWrapper {
            display: flex;
            justify-content: center;
            gap: 0.5rem;
            button {
              height: 2rem;
              font-size: 0.875rem;
              font-weight: 600;
              padding-left: 0.5rem;
              padding-right: 1rem;
              a {
                font-weight: 600;
              }
              .material-symbols-rounded {
                font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
              }
            }
          }
        }
      }
      .selectTimer {
        display: flex;
        align-items: center;
        label {
          font-size: 0.875rem;
          padding: 0 0.25rem;
        }
        > .flex-basic {
          flex-grow: 1;
          gap: 0.5rem;
          > .flex-basic {
            gap: 0.5rem;
          }
          > div > div {
            width: 4.25rem;
          }
          > div > div > div,
          > div span,
          span {
            font-size: 0.875rem;
            color: ${({ theme }: { theme: any }) => theme.text_tertiary};
          }
        }
      }
      textarea {
        flex-grow: 1;
      }

      &.grid1 {
        grid-template-columns: repeat(1, 1fr) !important;
        width: inherit;
        li {
          max-width: none;
        }
        label {
          font-size: 0.875rem;
          padding: 0 0.25rem;
        }

        .time > div {
          gap: 1rem;
          > div > span {
            padding-left: 0.5rem;
          }
        }
      }
    }
    .filter-option-grid.point {
      margin-top: 2rem;
      > div:nth-child(1) {
        grid-column: span 2;
        > div,
        .datePickerWrapper,
        .reactSelectrWrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0;
          label {
            font-size: 0.875rem;
            padding: 0 0.25rem;
          }
          input,
          .react-datepicker-wrapper {
            height: 2.5rem;
            width: 7rem;
            flex-grow: 1;
            font-size: 0.875rem;
            input {
              font-size: 0.875rem;
              width: 100%;
            }
          }
        }
      }
    }
    .inputTable-overflow {
      overflow: auto;
    }
    .camera-channel-bar {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
      padding: 0 0.5rem;
      height: 3.5rem;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      justify-content: space-between;
      > label {
        display: flex;
        align-items: center;
        user-select: none;
        font-weight: 600;
        font-size: 0.875rem;
        cursor: pointer;
        padding: 0 1rem;
        height: 2.5rem;
        border-radius: 2rem;
        &:hover {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
        }
        input {
          outline: none;
        }
      }
      .guide {
        padding: 0 1rem;
        font-size: 0.875rem;
        color: ${({ theme }: { theme: any }) => theme.text_tertiary};
        > span {
          color: ${({ theme }: { theme: any }) => theme.filled_blue};
          font-weight: 600;
          margin-right: 0.125rem;
        }
      }
    }
    > .inputForm-body {
      flex-shrink: 1;
      background-color: ${({ theme }: { theme: any }) => theme.board};
      color: #121212;
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: auto;
      &.camera-channel ul {
        display: grid;
        gap: 0.5rem 1rem;
        margin: 0.75rem 0.5rem;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 2.5rem;
        > li {
          overflow: visible;
          height: fit-content;
          display: flex;
          align-items: center;
          label {
            background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
            &:hover {
              background-color: ${({ theme }: { theme: any }) => theme.tonal};
            }
            cursor: pointer;
            gap: 0.5rem;
            width: 100%;
            padding-left: 1rem;
            padding-right: 0.5rem;
            height: 2.5rem;
            border-radius: 2rem;
            &.none-select {
              cursor: default;
              pointer-events: none;
              > .camera-name {
                color: ${({ theme }: { theme: any }) => theme.text_disabled};
              }
            }
            input {
              margin: 0;
              flex-shrink: 0;
              outline: none;
            }
            > .camera-name {
              flex-grow: 1;
              user-select: none;
              color: ${({ theme }: { theme: any }) => theme.text_primary};
              font-size: 0.875rem;
            }
            .badge {
              padding-left: 0.5rem;
              padding-right: 0.75rem;
              height: 1.75rem;
              border-radius: 1rem;
              display: flex;
              align-items: center;
              gap: 0.25rem;
              display: flex;
              background-color: ${({ theme }: { theme: any }) => theme.board};
              filter: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));
              &.green {
                background-color: ${({ theme }: { theme: any }) => theme.tonal_green};
                > .bullet {
                  background-color: ${({ theme }: { theme: any }) => theme.filled_green};
                }
                > .bullet-status {
                  color: ${({ theme }: { theme: any }) => theme.filled_green};
                }
              }
              &.red {
                background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
                > .bullet {
                  background-color: ${({ theme }: { theme: any }) => theme.filled_red};
                }
                > .bullet-status {
                  color: ${({ theme }: { theme: any }) => theme.filled_red};
                }
              }
              > .bullet {
                padding: 0.375rem;
                font-size: 0.875rem;
                border-radius: 1rem;
                background-color: gray;
                height: 0.5rem;
              }
              > .bullet-status {
                font-weight: 600;
                font-size: 0.875rem;
                line-height: 1;
                user-select: none;
              }
            }
          }
        }
      }
      &.dailyPersonalAttend,
      &.monthlyAttend {
        padding-bottom: 0.5rem;
        .searchWrapper {
          padding: 0 0.75rem;
          height: 3.5rem;
          flex-shrink: 0;
          > div.view-option {
            gap: 0.5rem;
            label {
              font-size: 0.875rem;
              user-select: none;
            }
          }
        }
        > div.detail-info {
          margin: 0.5rem;
        }
        > div.detail-info,
        > div.searchWrapper > div.detail-info {
          display: flex;
          padding: 0 0.75rem;
          height: 2.5rem;
          border-radius: 0.25rem;
          background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
          > .flex-basic {
            font-size: 0.875rem;
            .seperator {
              border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
              height: 1rem;
              margin: 0 0.5rem;
            }
            &:nth-child(n + 2)::before {
              content: '•';
              margin: 0 0.375rem;
            }
            span.data {
              color: ${({ theme }: { theme: any }) => theme.text_primary};
              font-weight: 500;
            }
            label,
            span.wave {
              color: ${({ theme }: { theme: any }) => theme.text_disabled};
            }
          }
        }
      }
      &.monthlyAttend {
        padding-bottom: 0;
      }
      .modal-contents {
        padding: 1rem;
        flex-grow: 1;
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        .subHead {
          padding: 2rem 0;
        }

        .thumbnailData {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          padding: 1rem 0;

          dt {
            min-width: 5rem;
          }
        }
      }
      .searchWrapper {
        .searchOptionWrapper {
          width: 100%;
          > div > div {
            width: 100%;
            display: flex;
          }
        }
      }
      .datePickerWrapper {
        > :nth-child(3) > :nth-child(2) {
          position: fixed !important;
          inset: 0px auto auto 0px;
        }
        .applyBorder {
          border: 1px solid red;
        }
      }
      .inputFormText {
        line-height: 1.5;
        border-radius: 5px;
        padding: 1rem;
        background-color: rgba(18, 97, 255, 0.1);
        margin-bottom: 2rem;
        letter-spacing: -0.07rem;
      }
      .content {
        padding-top: 1rem;
        background-color: transparent;
      }
      .flexStart {
        justify-content: flex-start;
        > label {
          min-width: 8rem;
        }
      }
      .labelRow {
        > div > div {
          display: flex;
          > label {
            min-width: 8rem;
          }
        }
      }
      .paddingBottom {
        padding-bottom: 2rem;
      }
      label {
        display: flex;
        align-items: center;
        font-weight: 500;
        /* color: rgba(0, 0, 0, 0.6); */
      }
      .modal-row {
        display: flex;
        gap: 1rem;
      }
      .modal-element {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        > div > ul > li:first-child {
          display: flex;
          width: 100%;
          max-width: 100%;
          /* max-width: 17.5rem; */
          span {
            width: 100%;
          }
        }
        .inputWithBtnRow {
          width: 100%;
          display: flex;
          gap: 0.5rem;
          div {
            flex-grow: 1;
            /* width: 100%; */
          }
          input {
            height: 2.5rem;
            border-radius: 0.25rem;
            max-width: 100%;
          }
          Button {
            height: 2.5rem;
            min-width: fit-content;
          }
        }
        input.inputMask {
          height: 2.5rem;
        }
        input.inputMask:focus {
          outline: none;
        }
        /* react-datepicker__month-container */
        .react-datepicker-popper {
          padding: 0;
          left: 50% !important;
          transform: translate(-50%, 0) !important;
          width: 100%;
        }
        .react-datepicker__month-container {
          float: none;
          /* width: 100%; */
        }
        .react-datepicker {
          width: 100%;
        }
        .react-datepicker__triangle {
          display: none;
        }
        .modal-element-time {
          display: flex;
          gap: 2rem;
          > div {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            /* width: 12rem; */
            input {
              height: 2.5rem;
            }
            > span {
              color: ${({ theme }: { theme: any }) => theme.text_secondary};
            }
          }
        }
        .searchAfterText {
          background-color: ${({ theme }: { theme: any }) => theme.tonal};
          height: 2.5rem;
          padding: 0 1rem;
          display: flex;
          align-items: center;
          border-radius: 0.25rem;
        }
      }
    }
    .filter-option-grid.block-detail {
      div:last-child {
        grid-column: span 2;
      }
    }
    .modal-close {
      cursor: pointer;
      width: 3rem;
      height: 3rem;
      user-select: none;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.25rem;
      :hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
  .modal.fitHeight {
    height: fit-content;
  }
  .modal.delete {
    width: 20rem;
    height: fit-content;
    > .inputForm-head {
      justify-content: center;
      height: 5rem;
      display: flex;
      flex-direction: column;
      color: ${({ theme }: { theme: any }) => theme.text_primary};
    }
    .modal-footer {
      border: none;
    }
    button {
      flex: 1 1 50%;
      font-size: 0.875rem;
    }
  }
  .modal.userInfoSetting,
  .modal.height-fit {
    height: fit-content;
    @media (max-width: 768px) {
      height: calc(var(--vh, 1vh) * 100);
      position: absolute;
      top: 0;
      padding-bottom: env(safe-area-inset-bottom); //ios 노치 대응
    }
    .modal-footer {
      .flex-end {
        gap: 0.5rem;
      }
    }
  }
  .modal.width-fit {
    min-width: fit-content;
    max-width: 60vw;
  }
  .modal.tunnelChart {
    height: auto;
    max-height: calc(100vh - 2rem);
    @media (max-width: 768px) {
      height: calc(var(--vh, 1vh) * 100);
      max-height: none;
      position: absolute;
      top: 0;
      padding-bottom: env(safe-area-inset-bottom); //ios 노치 대응
    }
  }
  .modal-footer {
    border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem;
    > div {
      gap: 0.25rem;
      > button {
        height: 2.5rem;
        font-size: 0.875rem;
        width: 6rem;
        padding: 0;
        @media screen and (max-width: 767px) {
          width: fit-content;
          padding: 0 1.5rem;
        }
      }
    }
    > .tipballoon {
      padding-left: 0.5rem;
      padding-right: 1rem;
      border-radius: 2rem;
      height: 2rem;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      font-weight: ${({ theme }: { theme: any }) => theme.font_medium};
      > .material-symbols-rounded {
        color: ${({ theme }: { theme: any }) => theme.badge_primary_text};
        font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
        font-size: 1.25rem;
      }
    }
    > .arrows {
      button {
        gap: 0.25rem;
        width: fit-content;
        gap: 0.5rem;
        background-color: #e5f1ff;
        background-color: ${({ theme }: { theme: any }) => theme.tonal};
        &:hover {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
        }
        &:nth-child(1) {
          padding-left: 0.25rem;
          padding-right: 0.5rem;
          @media screen and (max-width: 767px) {
            padding-right: 1rem;
          }
          > div:nth-child(3) {
            padding-left: 0.5rem;
            border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
            @media screen and (max-width: 767px) {
              display: none;
            }
            p {
              text-align: left;
            }
          }
        }
        &:nth-child(2) {
          padding-left: 0.5rem;
          padding-right: 0.25rem;
          @media screen and (max-width: 767px) {
            padding-left: 1rem;
          }
          > div:nth-child(1) {
            padding-right: 0.5rem;
            border-right: 1px solid ${({ theme }: { theme: any }) => theme.outline};
            @media screen and (max-width: 767px) {
              display: none;
            }
            p {
              text-align: right;
            }
          }
        }
        > p,
        > span {
          color: ${({ theme }: { theme: any }) => theme.selected_primary};
        }
        > div {
          p {
            color: ${({ theme }: { theme: any }) => theme.text_tertiary};
            font-size: 0.625rem;
            line-height: 1.125;
            letter-spacing: 0.025rem;
            font-weight: 600;
          }
        }
      }
    }
    > button {
      height: 2.5rem;
      width: 6rem;
      min-width: fit-content;
      max-width: -webkit-fill-available;
      /* margin-left: 0.25rem; */
    }
  }
  .tableTop {
    padding: 0.5rem;
    .tableTitle {
      padding: 0.25rem;
      font-size: 0.875rem;
      color: ${({ theme }: { theme: any }) => theme.text_secondary};
    }
    button {
      height: 2.5rem;
      font-size: 0.875rem;
    }
  }
  .tui-grid-content-area {
    display: flex;
    > div:first-child {
      position: relative;
    }
    > div:nth-child(2) {
      margin-left: 0 !important;
    }
  }
  .grid {
    display: grid;
    align-items: center;
    /* min-height: 3.5rem; */
  }
  .grid100,
  .grid100T,
  .grid60,
  .grid50,
  .grid30 {
    > div > div {
      display: grid;
      grid-template-columns: 9rem 1fr;
      align-items: center;
    }
  }

  .grid100T {
    grid-template-columns: 9rem 1fr;
  }

  .grid50,
  .grid60 {
    grid-template-columns: 1fr 1fr;
  }

  .grid50 {
    grid-gap: 1rem;
  }

  .grid30 {
    grid-template-columns: 1.5fr 0.7fr 0.8fr;
  }

  .plainText {
    grid-template-columns: 9rem 1fr;

    > :last-child {
      display: block;
    }
  }

  .marginBottomNone {
    margin-bottom: 0;
  }

  > .modal-scrollStyle {
    padding: 30px 12px 30px 30px;

    > .inputForm-body {
      margin-right: 12px;

      > .modal-contents {
        padding-right: 12px;
      }
    }
  }

  .flexColumn {
    display: flex;
    flex-direction: column;
    // padding: 0.5rem 0 1rem 0;
    > small {
      line-height: 1.5;
    }
  }

  .view {
    input {
      background-color: transparent;
      border: none;
      padding-left: 0;
      padding-right: 0;
    }
    .selectWrapper ul {
      border: none;

      li {
        background-color: transparent;
        cursor: default;
        > span {
          display: none;
        }
      }
    }

    .datePickerWrapper {
      input {
        width: 100%;
        background-color: transparent;
        border: none;
      }
      > :nth-child(3) > :nth-child(2) {
        display: none;
      }
    }
  }

  .thumbnailWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      object-fit: contain;
    }

    .thumbnail {
      // width: 30rem;
      // height: 20rem;
      // cursor: pointer;

      width: fit-content;
      height: fit-content;
      cursor: pointer;
      max-width: 80vw;
      max-height: 80vh;
      overflow: auto;
    }

    button {
      height: 2.5rem;
      width: 6rem;
      margin: 0 0.3rem;
    }

    .buttonsWrapper {
      padding: 0.5rem 0;
    }
  }

  // .thumbnailModal {
  //   justify-content: center;
  //   align-items: center;
  //   .thumbnail {
  //     width: fit-content;
  //     height: fit-content;
  //   }
  // }

  .fileWrapper {
    display: flex;
    flex-direction: row;

    button {
      height: 2.5rem;
      width: 6rem;
      margin: 0 0.25rem;
    }

    .buttonsWrapper {
      padding: 0.5rem 0;
    }
  }

  .innerTab {
    padding-bottom: 2rem;
    .tab {
      padding: 0 1rem;
      cursor: pointer;
      height: 3rem;
    }

    .activeTab {
      font-weight: 700;
      border-bottom: 2px solid rgb(63, 66, 84);
    }
  }

  .sensor .chart {
    padding: 0 0.5rem;
    width: 100%;
    overflow-y: auto;
  }

  .inputRowContainer {
    gap: 0.5rem;
    padding: 1rem;

    label {
      min-width: 7rem;
      display: flex;
      align-items: center;
    }

    textarea {
      min-height: 3rem;
      width: 100%;
    }

    .checkContainer {
      gap: 1rem;
    }
  }

  .mqttAlert {
    padding: 1rem;
    background-color: ${({ theme }: { theme: any }) => theme.color.red_deep};
    height: fit-content;
    width: fit-content;
    max-height: 80vh;
    max-width: 85vw;

    > div > .modalHeaderTitle {
      color: white !important;
      font-weight: 600;
    }

    .thead {
      border: none;
      .tr {
        border: none;
        color: #eaeaea;
        > div {
          background-color: ${({ theme }: { theme: any }) => theme.color.red_deep};
        }
      }
    }

    .table .tbody .tr {
      border-top: none;
      height: 3rem;
      > div {
        color: white;
        font-weight: 400;
      }

      .lime {
        background-color: ${({ theme }: { theme: any }) => theme.filled_lime};
        color: ${({ theme }: { theme: any }) => theme.lime};
      }

      .chip {
        border-radius: 25px;
        height: fit-content;
        padding: 2px;
        color: #fff;
      }

      .blue {
        background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
        color: ${({ theme }: { theme: any }) => theme.tonal_blue};
      }

      .red {
        color: ${({ theme }: { theme: any }) => theme.filled_red};
        background-color: ${({ theme }: { theme: any }) => theme.tonal_red};
      }

      .green {
        background-color: ${({ theme }: { theme: any }) => theme.filled_green};
        color: ${({ theme }: { theme: any }) => theme.tonal_green};
      }

      .txt_green {
        color: ${({ theme }: { theme: any }) => theme.filled_green};
      }

      .txt_violet {
        color: ${({ theme }: { theme: any }) => theme.filled_violet};
      }

      .txt_red {
        color: ${({ theme }: { theme: any }) => theme.filled_red};
      }

      .txt_lime {
        color: ${({ theme }: { theme: any }) => theme.filled_lime};
      }

      .txt_pink {
        color: ${({ theme }: { theme: any }) => theme.filled_pink};
      }

      .txt_sky {
        color: ${({ theme }: { theme: any }) => theme.filled_sky};
      }

      .txt_slate {
        color: ${({ theme }: { theme: any }) => theme.filled_slate};
      }
    }

    > div {
      background-color: ${({ theme }: { theme: any }) => theme.color.red_deep} !important;
      border-bottom: none;
    }

    .material-symbols-rounded {
      &.alert {
        padding: 0 0.5rem;
        color: ${({ theme }: { theme: any }) => theme.color.orange};
      }
    }
  }

  .black {
    .namespaceEllipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block !important;
      white-space: nowrap;
      width: 11rem;
      line-height: 2.5rem;
    }
  }

  @media screen and (max-width: 768px) {
    .sensor {
      flex-grow: 1;
    }
    .black {
      flex-grow: 1;
      .selectWrapper ul li {
        min-width: 100%;
      }

      .filter-option-grid {
        grid-template-columns: repeat(1, 1fr) !important;
        width: 100%;
      }
    }
  }

  .workerSignInfo {
    .inputForm-head {
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    }
    .inputForm-body {
      display: flex;
      flex-direction: row;
      align-items: start;
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    }

    .buttonContainer {
      position: sticky;
      top: 0;
      flex-shrink: 0;
      width: 14rem;
      min-width: fit-content;
      display: flex;
      gap: 0.5rem;
      flex-direction: column;
      padding: 0.5rem;
      border-radius: 0.25rem;

      > div {
        border-radius: 5px;
        text-align: center;
        padding: 0.5rem;
        background-color: ${({ theme }: { theme: any }) => theme.badge_primary_background};
        cursor: pointer;
        user-select: none;
        color: ${({ theme }: { theme: any }) => theme.badge_primary_text};
        font-size: 0.875rem;

        &:hover {
          background-color: ${({ theme }: { theme: any }) => theme.tonal_deep_blue} !important;
          box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
        }
        &.selected {
          font-weight: 400;
          background-color: ${({ theme }: { theme: any }) => theme.filled_blue_deep} !important;
          color: ${({ theme }: { theme: any }) => theme.board};
        }
      }
    }

    .contentContainer {
      padding: 0.5rem;
      width: 100%;
      height: fit-content;
      overflow: auto;

      .scrollableDiv {
        > img {
          width: 100%;
        }
      }

      .printWorkerInfo {
        padding: 0.5rem;
      }

      .custom-table {
        width: 100%;
        border-collapse: collapse;
      }

      .custom-table th,
      .custom-table td {
        border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
        padding: 8px;
        text-align: left;
      }

      .custom-table th {
        background-color: #f4f4f4;
        color: #333;
      }

      .custom-table tbody tr:nth-child(odd) {
        // background-color: #f9f9f9;
        background-color: ${({ theme }: { theme: any }) => theme.lightgray_lighter};
      }

      .custom-table td:nth-child(1),
      .custom-table td:nth-child(3) {
        font-weight: bold;
        min-width: 8rem;
        width: 20%;
      }

      .custom-table td:nth-child(2),
      .custom-table td:nth-child(4) {
        width: 30%;
        max-width: 6rem;
        overflow: hidden;
      }
    }

    .modal-footer {
      position: sticky;
      bottom: 0;
    }
  }
`;

export const LoadingModalBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5010;
  width: 100%;
  height: 100%;
  display: flex;

  > span {
    height: inherit;
    width: inherit;
  }
`;
