/**
 * 작성자 : 홍선영
 * 날짜 : 2023.09.12
 * 경로 : 안전 관리/게시판 - 이미지 게시판
 */

import { Dispatch, SetStateAction, useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { readAndCompressImage } from 'browser-image-resizer';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';

import { IUser, userState } from '../../../atoms';
import { IModal } from 'customTypes';
import { COMCD_USE_YN, FLAG_CREATE_OR_UPDATE, FLAG_NONE, INIT_USE_YN_A, USE_YN } from '../../../_constants';
import { InputTable } from '../../../assets/styles/InputTable';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import illustrator from '../../../assets/images/illustration/31.svg';
import { trimArray } from '../../../utils/trimArray';
import { useSetAuth } from '../../../utils/useSetAuth';
import { applyBorderStyle } from '../../../utils/applyBorderStyle';
import { getFileNameFromURL } from '../../../utils/formatFilePath';
import { arraySortByAscdOrder } from '../../../utils/arraySortByAscdOrder';
import { BtnBlue, BtnGreen, BtnRed } from '../../../components/Button';
import Input from '../../../components/Input';
import Portal from '../../../components/Portal';
import SelectBox from '../../../components/SelectBox';
import IssueGuide from '../../../components/IssueGuide';
import SelectBoxs from '../../../components/SelectBoxs';
import DeleteModal from '../../../components/Modal/DeleteModal';
import DashboardViewModalSlider from '../../../components/Modal/DashboardViewModalSlider';
import { apiDelete, apiGet, apiPost } from '../../../services/_common';
import { ynFilter } from '../../../utils/ynFilter';
import { useFetchCommonCodeList } from '../../../services/useSetCodeListInSelectBoxForm';
import { logPost } from '../../../services/log';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  justify-content: flex-end;
  flex-wrap: wrap;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
  .inputNumber {
    display: flex;
    position: relative;
    width: 4.5rem;
    > span {
      user-select: none;
      position: absolute;
      right: 0;
      font-size: 0.875rem;
      top: 50%;
      margin-right: 0.5rem;
      color: rgba(0, 0, 0, 0.6);
      transform: translate(0, -50%);
    }
  }
  .description {
    /* height: 100%; */
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    font-size: 0.875rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    line-height: 1;
    word-break: keep-all;
  }
  .addImgBtn {
    user-select: none;
    .material-symbols-rounded {
      font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 20;
    }
    .fileButton {
      cursor: pointer;
      display: flex;
      align-items: center;
      /* padding: 0 1rem; */
      padding-right: 1rem;
      padding-left: 0.5rem;
      height: 2.5rem;
      border-radius: 0.25rem;
      font-size: 0.875rem;
      gap: 0.5rem;
      background-color: #007aff;
      color: white;
    }
  }
`;

const Root = styled.div`
  &.hideRoot {
    visibility: hidden;
    position: absolute;
  }
  .icon-btn {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    gap: 0.25rem;
    cursor: pointer;
    border-radius: 5rem;
    padding-right: 1rem;
    padding-left: 0.5rem;
    font-size: 0.875rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    color: ${({ theme }: { theme: any }) => theme.text_primary};
    &:hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
    }
    > span {
      color: ${({ theme }: { theme: any }) => theme.text_primary};
    }
    &.arrow-icon span {
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }
  }
  .emptyData {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    padding-bottom: 4rem;
    img {
      width: 16rem;
      /* user-drag: none; */
      -webkit-user-drag: none;
    }
    span {
      color: ${({ theme }: { theme: any }) => theme.filled_violet};
    }
  }
  .inputFormsWrapper {
    padding: 0.5rem;
  }
  .inputFormsWrapper.report {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .closeBtn {
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5rem;
      cursor: pointer;
      margin: 0 0.5rem;
      color: ${({ theme }: { theme: any }) => theme.text_primary};
    }
    .closeBtn:hover {
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
    }
  }
  .formTitle {
    padding: 0 0.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: ${({ theme }: { theme: any }) => theme.text_primary};
  }

  .content-container {
    gap: 2rem;
    > div {
      flex: 0.5;
    }

    .table {
      height: calc(100vh - 27rem);
      overflow-y: scroll;

      .colorButton {
        border: none;
        height: 2rem;
        width: calc(100% - 1rem);
        border-radius: 5px;
        margin: 0 auto;
      }
    }

    .fileButton {
      text-transform: uppercase;
      height: 3.4rem;
      padding: 0px 2rem;
      cursor: pointer;
      border-radius: 4px;
      box-shadow: rgba(76, 87, 125, 0.1) 2px 2px 3px;
      transition: all 0.2s ease 0s;
      background: rgb(63, 66, 84);
      border: 1px solid rgb(72, 216, 158);
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      font-size: 1.3rem;
      white-space: nowrap;
      color: #eaeaea;
      background-color: #007aff;
      border: 1px solid #007aff;

      &:hover {
        color: #eaeaea;
        background-color: rgba(18, 97, 255, 0.6);
      }
    }
  }
`;

// Compression configuration
const config = {
  quality: 0.7,
  maxWidth: 800,
  maxHeight: 600,
  autoRotate: true,
  debug: true,
};

interface ISafeImageBoardT {
  index?: number;
  ibtCd: string;
  ibtName: string;
  ibtDefault: string;
  useYn: string;
  writer: string;
  wDate: string;
  editor: string;
  eDate: string;
  flag?: string;
}

interface ISafeImageBoardD {
  index?: number;
  hCd?: string;
  sCd?: string;
  ibtCd?: string;
  ibdCd: string;
  iFilename: string;
  cdSort: number;
  useYn: string;
  writer: string;
  editor: string;
  flag?: string;
  imgPath?: string;
  imgName?: string;
  imgBase64?: string;
}

type DragItem = {
  type: string;
  index: number;
};

const DRAGGABLE_ITEM_TYPE = 'ROW';

const DraggableRow = ({ el, index, moveRow, boardDtableState, setBoardDtableState, onClickDeleteBoardD, userInfo, auth }: any) => {
  const { t } = useTranslation();
  const trRef = useRef(null);
  const newCdArray = boardDtableState.map((elParam: ISafeImageBoardD) => elParam.cdSort);
  const cdSortArray = newCdArray.sort((a: number, b: number) => a - b);

  const [, drop] = useDrop({
    accept: DRAGGABLE_ITEM_TYPE,
    hover(item: DragItem, monitor) {
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop: (item, monitor) => {
      const copyArray = [...boardDtableState];
      const newArray = copyArray.map((cpItem, cpIndex) => {
        return {
          ...cpItem,
          cdSort: cdSortArray[cpIndex],
          flag: FLAG_CREATE_OR_UPDATE,
          editor: userInfo.userId,
        };
      });

      setBoardDtableState(newArray);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: DRAGGABLE_ITEM_TYPE,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(trRef));

  return (
    <div ref={trRef} style={{ opacity: isDragging ? 0 : 1 }}>
      <div className='tr' role='button' tabIndex={0}>
        <div className='trCol2p5 tableStickyNo flex-center'>{index + 1}</div>
        <div className='trCol6 flex-basic tableImg'>
          <img height={60} src={el.imgPath} alt='' />
        </div>
        <div className='trCol20 flex-basic content-overflow'>{el.imgName}</div>
        {auth.deleteAuth && (
          <div className='trCol4 flex-center'>
            <BtnRed onClick={() => onClickDeleteBoardD(el, index)}>{el.index === undefined ? t('삭제') : t('제거')}</BtnRed>
          </div>
        )}
      </div>
    </div>
  );
};

const ImageBoard = () => {
  const { t } = useTranslation();
  const { auth } = useSetAuth(); // 사용자 권한값 훅
  const size = useOutletContext<any>();
  const userInfo = useRecoilValue<IUser>(userState);
  const { data: useYnComCdListWithAll } = useFetchCommonCodeList(COMCD_USE_YN, true); // 사용여부 공통코드 목록 (전체포함)
  const [searchOption, setSearchOption] = useState({ name: '' });
  const [useYn, setUseYn] = useState(INIT_USE_YN_A);
  const [boardTtableState, setBoardTtableState] = useState<ISafeImageBoardT[]>([]);
  const [boardDtableState, setBoardDtableState] = useState<ISafeImageBoardD[]>([]);
  const [boardTorgTableState, setBoardTOrgTableState] = useState<ISafeImageBoardT[]>([]);
  const [boardDorgTableState, setBoardDOrgTableState] = useState<ISafeImageBoardD[]>([]);
  const [openModal, setOpenModal] = useState<any>({ status: false, type: '', title: '' });
  const [newBoardTRowIndex, setNewBoardTRowIndex] = useState<number>(0); // 신규항목 추가 클릭했을 때 새 로우객체에 인덱스추가
  const [newBoardDRowIndex, setNewBoardDRowIndex] = useState<number>(0); // 신규항목 추가 클릭했을 때 새 로우객체에 인덱스추가
  const [initBoardTableLength, setInitBoardTableLength] = useState<number>(0); // 테이블에 표시할 로우 넘버
  const [initBoardDTableLength, setInitBoardDTableLength] = useState<number>(0); // 테이블에 표시할 로우 넘버
  const [isSaveClicked, setIsSaveClicked] = useState(false); // 저장1버튼 클릭 여부에 따라 필수입력값 보더색상 처리하기 위한 state 값
  const [showSubTable, setShowSubTable] = useState({ status: false, ibtCd: '', ibtName: '' });
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[] | []>([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const scrollContainerRef = useRef<HTMLInputElement>(null);
  const [addRowStatus, setAddRowStatus] = useState(false); // 신규추가 상태값
  const [newFileCount, setNewFileCount] = useState(0); // 신규 추가하는 파일 개수

  useEffect(() => {
    getSafeImageBoardtAPI();
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '안전관리/게시판 > 이미지 게시판',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    applyFilter(boardTorgTableState);
  }, [searchOption, useYn[COMCD_USE_YN]]);

  // 신규추가시 스크롤 마지막으로 이동
  useEffect(() => {
    if (addRowStatus) {
      if (scrollContainerRef.current) scrollContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      setAddRowStatus(false);
    }
  }, [addRowStatus]);

  const getSafeImageBoardtAPI = async () => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
    const res = await apiGet({ path: '/safe/imageboardt', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      const { imageBoardTList } = data;
      const addFlag = imageBoardTList.map((el: ISafeImageBoardT) => ({ ...el, flag: FLAG_NONE, hCd: userInfo.hCd, sCd: userInfo.sCd }));
      setBoardTtableState(addFlag);
      setBoardTOrgTableState(addFlag);
      setNewBoardTRowIndex(imageBoardTList.length);
      setInitBoardTableLength(imageBoardTList.length);
    } else {
      // toast.error(t(ERROR));
    }
    return res;
  };

  const getSafeImageBoarddAPI = async (ibtCdParam: string) => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, ibtCd: ibtCdParam };
    const res = await apiGet({ path: '/safe/imageboardd', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      const { imageBoardDList } = data;
      const addFlag = imageBoardDList.map((el: ISafeImageBoardD) => ({
        ...el,
        flag: FLAG_NONE,
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        ibtCd: ibtCdParam,
        imgBase64: '',
        imgPath: el.iFilename,
        imgName: getFileNameFromURL(el.iFilename),
      }));
      setBoardDtableState(arraySortByAscdOrder(addFlag, 'cdSort'));
      setNewBoardDRowIndex(imageBoardDList.length);
      setInitBoardDTableLength(imageBoardDList.length);
    } else {
      // toast.error(t(ERROR));
    }
  };

  const deleteImageboardtAPI = async (el: ISafeImageBoardT) => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, ibtCd: el.ibtCd, editor: userInfo.userId };
    const res = await apiDelete({ path: '/safe/imageboardt', req });
    const { statusCode } = res.data;
    if (statusCode === 200) {
      setOpenModal((prev: IModal) => ({ ...prev, status: false }));
    } else {
      // toast.error(t(ERROR));
    }
  };

  const deleteImageboarddAPI = async (el: ISafeImageBoardD) => {
    const req = { hCd: userInfo.hCd, sCd: userInfo.sCd, ibtCd: showSubTable.ibtCd, ibdCd: el.ibdCd, editor: userInfo.userId };
    const res = await apiDelete({ path: '/safe/imageboardd', req });
    const { statusCode, data } = res.data;
    if (statusCode === 200) {
      setOpenModal((prev: IModal) => ({ ...prev, status: false }));
      // setNewBoardDRowIndex(data.cameraList.length);
    } else {
      // toast.error(t(ERROR));
    }
  };

  const saveImageBoardTListAPI = async (reqData: ISafeImageBoardT[]) => {
    const req = { imageBoardTReqDto: trimArray(reqData) };
    const res = await apiPost({ path: '/safe/imageboardt', contentType: 'application/json', req });
    const { statusCode, data, message } = res.data;
    if (statusCode === 200) {
      const { imageBoardTList } = data;
      const addFlag = imageBoardTList.map((el: ISafeImageBoardT) => ({ ...el, flag: FLAG_NONE, hCd: userInfo.hCd, sCd: userInfo.sCd }));
      setBoardTtableState(addFlag);
      setBoardTOrgTableState(addFlag);
      setNewBoardTRowIndex(imageBoardTList.length);
      setInitBoardTableLength(imageBoardTList.length);
      setIsSaveClicked(false);
      toast.success(t(message));
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 이미지게시판 상세항목 저장
  const saveImageBoardDListAPI = async (reqData: ISafeImageBoardD[]) => {
    const req = { imageBoardDReqDto: reqData };
    const res = await apiPost({ path: '/safe/imageboardd', contentType: 'application/json', req });
    const { statusCode, data, message } = res.data;
    if (statusCode === 200) {
      const { imageBoardDList } = data;
      const addFlag = imageBoardDList.map((el: ISafeImageBoardD) => ({
        ...el,
        flag: FLAG_NONE,
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        ibtCd: showSubTable.ibtCd,
        imgPath: el.iFilename,
        imgName: getFileNameFromURL(el.iFilename),
      }));
      setBoardDtableState(arraySortByAscdOrder(addFlag, 'cdSort'));
      setNewBoardDRowIndex(imageBoardDList.length);
      setInitBoardDTableLength(imageBoardDList.length);
      setIsSaveClicked(false);
      toast.success(t(message));
      setNewFileCount(0);
    } else {
      // toast.error(t(ERROR));
    }
  };

  const onChangeBoardTtableState = (e: React.ChangeEvent<HTMLInputElement>, i: number, el: ISafeImageBoardT) => {
    const { name, value } = e.currentTarget;
    const viewDataArray = [...boardTtableState];
    const rawDataArray = [...boardTorgTableState];

    let findIndex;
    if (el.index === undefined) {
      // 새로운 로우가 아닌 기존 로우를 수정했을 때
      findIndex = boardTtableState.findIndex((boardTEl: ISafeImageBoardT) => boardTEl.ibtCd === el.ibtCd);
    } else {
      findIndex = i;
    }

    viewDataArray[i] = { ...viewDataArray[i], [name]: value, flag: FLAG_CREATE_OR_UPDATE }; // 뷰테이블 로우 업데이트
    if (findIndex !== -1) {
      // 데이터테이블 로우 업데이트
      rawDataArray[findIndex] = { ...rawDataArray[findIndex], [name]: value, editor: userInfo.userId, flag: FLAG_CREATE_OR_UPDATE };
    }
    setBoardTtableState(viewDataArray);
    setBoardTOrgTableState(rawDataArray);
  };

  const onChangeBoardDtableState = (e: React.ChangeEvent<HTMLInputElement>, i: number, el: ISafeImageBoardD) => {
    const { name, value } = e.currentTarget;
    const viewDataArray = [...boardDtableState];
    const rawDataArray = [...boardDorgTableState];

    let findIndex;
    if (el.index === undefined) {
      // 새로운 로우가 아닌 기존 로우를 수정했을 때
      findIndex = boardDtableState.findIndex((boardDEl: ISafeImageBoardD) => boardDEl.ibdCd === el.ibdCd);
    } else {
      findIndex = i;
    }

    viewDataArray[i] = { ...viewDataArray[i], [name]: value, flag: FLAG_CREATE_OR_UPDATE }; // 뷰테이블 로우 업데이트
    if (findIndex !== -1) {
      // 데이터테이블 로우 업데이트
      rawDataArray[findIndex] = { ...rawDataArray[findIndex], [name]: value, editor: userInfo.userId, flag: FLAG_CREATE_OR_UPDATE };
    }
    setBoardDtableState(viewDataArray);
    setBoardDOrgTableState(rawDataArray);
  };

  const onChangeRadioOption = (e: React.ChangeEvent<HTMLInputElement>, i: number, el: ISafeImageBoardT) => {
    const viewDataArray = [...boardTtableState]; // 뷰테이블 로우 어레이

    // 수정한 로우 & 원래 디폴트값이었던 로우의 적용여부,플래그 값 업데이트
    const findPrevDefaultIndex = viewDataArray.findIndex((viewEl: ISafeImageBoardT) => viewEl.ibtDefault === 'Y');
    if (findPrevDefaultIndex !== -1) {
      viewDataArray[findPrevDefaultIndex] = {
        ...viewDataArray[findPrevDefaultIndex],
        ibtDefault: 'N',
        flag: FLAG_CREATE_OR_UPDATE,
      };
    }

    const findNewDefaultIndex = viewDataArray.findIndex((_, viewIndex) => viewIndex === i);
    if (findNewDefaultIndex !== -1) {
      viewDataArray[findNewDefaultIndex] = {
        ...viewDataArray[findNewDefaultIndex],
        ibtDefault: 'Y',
        flag: FLAG_CREATE_OR_UPDATE,
      };
    }

    setBoardTtableState(viewDataArray);
  };

  // 안전중점사항 항목 추가 클릭
  const onClickAddNewBoardT = () => {
    setNewBoardTRowIndex((prev) => prev + 1);
    const newAdd = {
      index: newBoardTRowIndex,
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      ibtCd: '',
      ibtName: '',
      ibtDefault: 'N',
      useYn: 'Y',
      writer: '',
      editor: '',
      flag: FLAG_CREATE_OR_UPDATE,
    };

    setBoardTtableState((prev: any[]) => [...prev, newAdd]);
    setAddRowStatus(true);
  };

  const onClickSaveImageBoardT = async () => {
    setIsSaveClicked(true);
    const findDefault = boardTtableState.find((el) => el.ibtDefault === 'Y');
    if (findDefault !== undefined) {
      // 기본값이 Y이면서 동시에 사용유무가 N 인 경우
      const defaultYn = boardTtableState.find((el) => el.ibtDefault === 'Y' && el.useYn === 'N');
      if (defaultYn !== undefined) {
        return toast.warning(t('적용 항목의 경우 미사용할 수 없습니다'));
      }
      const requiredFieldArray = ['ibtName'];
      const emptyCheck = boardTtableState.filter((el: any) => {
        const check = requiredFieldArray.find((el2: string) => el[el2] === '');
        return check;
      });
      if (emptyCheck.length > 0) {
        toast.warning(t('필수입력값을 모두 입력하세요'));
      } else {
        const addWriterIntoNewArray = boardTtableState.map((el) => (el.index !== undefined ? { ...el, writer: userInfo.userId } : { ...el }));
        const newArray = addWriterIntoNewArray.map(({ index, no, wDate, eDate, ...rest }: any) => rest);
        const filteredArray = newArray.filter((el: ISafeImageBoardT) => el.flag === FLAG_CREATE_OR_UPDATE);
        const addUserInfoArray = filteredArray.map((el: ISafeImageBoardT) => ({ ...el, editor: userInfo.userId }));
        if (addUserInfoArray.length > 0) saveImageBoardTListAPI(addUserInfoArray);
        else toast.warning(t('변경사항이 없습니다.'));
      }
    } else toast.warning(t('기본 적용항목을 선택하세요'));
    return undefined;
  };

  const onClickSaveImageBoardD = async () => {
    const newArray = boardDtableState.filter((el: ISafeImageBoardD) => el.flag === FLAG_CREATE_OR_UPDATE);
    if (newArray.length > 0) {
      // compressAndUploadImages();
      const resultArray = newArray.map(({ index, imgPath, imgName, ...rest }: any) => rest);
      // 서버 dto 순서에 맞추지 않으면 에러나므로 순서대로 정렬

      /**
       * 수정자 : 홍선영
       * 수정일자 : 2023.11.02
       * 수정내용 : 이미지를 추가할때, 추가한 객체배열만 전송하는것이 아니라, cdSort값 전체 재정렬해서 모든객체 전송하여 cdSort값 꼬이는 에러 수정함
       */
      const sortArray = resultArray.map((el, index) => {
        return {
          hCd: el.hCd,
          sCd: el.sCd,
          ibtCd: el.ibtCd,
          ibdCd: el.ibdCd,
          iFilename: el.iFilename,
          cdSort: index,
          useYn: el.useYn,
          writer: el.writer,
          editor: el.editor,
          flag: 'Y',
          ...(el.imgBase64 ? { imgBase64: el.imgBase64 } : { imgBase64: '' }),
        };
      });
      saveImageBoardDListAPI(sortArray);
    } else {
      toast.warning(t('선택된 파일이 없습니다'));
    }
  };

  const decreaseArrayNo = (array: any[], dataLength: number, setState: Dispatch<SetStateAction<any[]>>) => {
    let increase = 0;

    const newArray = array.map((el, i) => {
      if (el.index !== undefined) {
        increase += 1;
        const copyArray = [...array];
        copyArray[i] = {
          ...copyArray[i],
          no: dataLength + increase,
        };
        return copyArray[i];
      }
      return el;
    });
    setState(newArray);
  };

  const onClickDeleteBoardT = (el: ISafeImageBoardT, i: number) => {
    if (el.ibtDefault === 'N') {
      if (el.index !== undefined) {
        // 새로 추가한 로우 제거 클릭 시
        const updatedArray = [...boardTtableState];
        updatedArray.splice(i, 1);
        decreaseArrayNo(updatedArray, initBoardTableLength, setBoardTtableState);
      } else {
        // 삭제 시 에디터아이디 추가
        const data = {
          ...el,
          editor: userInfo.userId,
        };
        setOpenModal((prev: any) => ({ ...prev, status: true, type: 'array', title: 'delete', state: boardTtableState, setState: setBoardTtableState, api: deleteImageboardtAPI, el: data, index: i }));
      }
    } else toast.warning(t('적용중인 항목은 삭제하실 수 없습니다'));
  };

  const onClickDeleteBoardD = (el: ISafeImageBoardD, i: number) => {
    if (el.index !== undefined) {
      // 새로 추가한 로우 제거 클릭 시
      const updatedArray = [...boardDtableState];
      updatedArray.splice(i, 1);
      decreaseArrayNo(updatedArray, initBoardDTableLength, setBoardDtableState);
      setNewFileCount((prev) => prev - 1);
    } else {
      // 삭제 시 에디터아이디 추가
      const data = {
        ...el,
        editor: userInfo.userId,
      };
      setOpenModal((prev: any) => ({ ...prev, status: true, type: 'array', title: 'delete', state: boardDtableState, setState: setBoardDtableState, api: deleteImageboarddAPI, el: data, index: i }));
    }
  };

  const onClickTableState = (i: number, el: any) => {
    setSelectedRowIndex(i);
    // 기존 로우 클릭시 상세항목 목록 노출
    if (el.index === undefined) {
      // 기존 선택한 로우와 클릭한 로우가 같지 않을때만 상세항목 API 요청
      if (showSubTable.ibtCd !== el.ibtCd) {
        getSafeImageBoarddAPI(el.ibtCd);
        setShowSubTable({ status: true, ibtCd: el.ibtCd, ibtName: el.ibtName });
      }
    } else {
      setShowSubTable({ status: false, ibtCd: '', ibtName: '' });
    }
  };

  const getBackgroundStyle = (i: number) => {
    if (i === selectedRowIndex) {
      return { backgroundColor: 'rgb(243, 246, 249)' };
    }
    return undefined;
  };

  const onClickInitiateSearchOption = () => {
    setSearchOption({ name: '' });
    setUseYn(INIT_USE_YN_A);
    setShowSubTable({ status: false, ibtCd: '', ibtName: '' });
  };

  const applyFilter = (array: any[]) => {
    // 검색옵션 변경됐을 때 필터링 처리
    // 필터링 기준
    const filterOptions = {
      name: searchOption.name,
    };
    // 필터링된 어레이 리턴, 대소문자구분X
    const titleFilteredArray = array.filter((item: any) => {
      return item.ibtName?.toLowerCase()?.includes(filterOptions.name?.toLowerCase());
    });
    const newFilter = titleFilteredArray;

    const result = ynFilter(newFilter, 'useYn', useYn[COMCD_USE_YN]);
    if (result.length > 0) {
      setBoardTtableState(result);
    } else {
      setBoardTtableState([]);
    }
  };

  const moveRow = (fromIndex: number, toIndex: number) => {
    const updatedData = [...boardDtableState];
    const [movedItem] = updatedData.splice(fromIndex, 1);
    updatedData.splice(toIndex, 0, movedItem);
    setBoardDtableState(updatedData);
  };

  const onClickPreview = () => {
    if (showSubTable.status) {
      if (boardDtableState && boardDtableState.length === 0) toast.warning(t('게시판 이미지가 없습니다'));
      else {
        setOpenModal((prev: any) => ({ ...prev, status: true, type: 'dashboardView', data: boardDtableState }));
      }
    } else toast.warning(t('게시판 항목을 선택하세요'));
  };

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // 한번에 최대 10개의 파일까지만 첨부할수 있도록 제한
    if (newFileCount < 10) {
      const fileList = event.target.files;

      if (fileList) {
        const MAX_FILE_SIZE_MB = 5;
        const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // convert to bytes

        const fileListArray = Array.from(Object.values(fileList));
        const smallFiles = fileListArray.filter((file) => file.size <= MAX_FILE_SIZE_BYTES);

        if (fileListArray.length !== smallFiles.length) {
          toast.warning(t(`파일크기는 5MB를 초과할 수 없습니다`));
        }
        const combinedArray = [...selectedFiles, ...smallFiles];
        compressAndUploadImages(combinedArray);
        setNewFileCount((prev) => prev + 1);
      }
    } else toast.warning(t('최대 10개 이미지까지 첨부 가능합니다'));
    event.target.value = '';
  };

  // 이미지 압축
  const compressAndUploadImages = async (selectedFilesParam: File[]) => {
    try {
      const compressedImages = await Promise.all(selectedFilesParam.map((file) => readAndCompressImage(file, config)));
      // 압축한 파일을 base64로 변환
      const promises = compressedImages.map((file, i) => {
        return new Promise((resolve) => {
          const fileReader = new FileReader();

          fileReader.onload = function (e) {
            const base64String = e.target!.result as string;
            resolve({
              index: newBoardDRowIndex + (i + 1),
              hCd: userInfo.hCd,
              sCd: userInfo.sCd,
              ibtCd: showSubTable.ibtCd,
              ibdCd: '',
              // iFilename: selectedFilesParam[i].name,
              iFilename: '',
              cdSort: boardDtableState.length + i + 1,
              useYn: 'Y',
              writer: userInfo.userId,
              editor: userInfo.userId,
              flag: FLAG_CREATE_OR_UPDATE,
              imgBase64: base64String.split(',')[1],
              imgPath: base64String,
              imgName: selectedFilesParam[i].name,
            });
          };
          fileReader.readAsDataURL(file);
        });
      });

      const resultArray: any = await Promise.all(promises);
      setBoardDtableState((prev: any[]) => [...prev, ...resultArray]);

      // postSiteImagesAPI(resultArray);
    } catch (error) {
      toast.error(t('이미지 압축 중 에러 발생 다시 시도하세요'));
      console.error(error);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={`content-container ${size.innerSize.W >= 1024 ? 'twoColumn column-55 max800' : 'oneColumn'}`}>
        <Root className={size.innerSize.W >= 1024 || (size.innerSize.W < 1024 && !showSubTable.ibtName) ? 'showRoot' : 'hideRoot'}>
          <SearchOptions align='left'>
            <div className='inputsWrapper'>
              <div className='inputForm-row'>
                <div className='inputForm-col withLabelComCf'>
                  <label htmlFor='useYn'>{t('사용유무')}</label>
                  <SelectBox
                    options={useYnComCdListWithAll}
                    defaultOption={useYn.cdName}
                    state={useYn}
                    setState={setUseYn}
                    stateKey={COMCD_USE_YN}
                    initiateKey={useYn[COMCD_USE_YN]}
                    filterbar='filter-1-left'
                  />
                </div>
              </div>
              <div className='inputForm-row'>
                <div className='inputForm-col'>
                  <Input label='' placeholder={t('게시판 명')} type='text' id='name' name='name' state={searchOption} setState={setSearchOption} />
                </div>
              </div>
            </div>
            {/* <BtnGreen onClick={onClickInitiateSearchOption}>초기화</BtnGreen> */}
          </SearchOptions>
          <InputTable className='margin-left-05'>
            <div className='thead'>
              <div className='tr'>
                <div className='trCol2p5 flex-center tableStickyNo'>No</div>
                <div className='trCol4 flex-center'>{t('코드')}</div>
                <div className='trCol10 flex-center required tableStickyTitle'>{t('게시판 명')}</div>
                <div className='trCol3 flex-center'>{t('적용')}</div>
                <div className='trCol6 flex-center'>{t('사용유무')}</div>
                <div className='trCol10 flex-center'>{t('등록일자')}</div>
                <div className='trCol6 flex-center'>{t('등록자')}</div>
                <div className='trCol10 flex-center'>{t('수정일자')}</div>
                <div className='trCol6 flex-center'>{t('수정자')}</div>
                {auth.deleteAuth && <div className='trCol4 flex-center'> </div>}
              </div>
            </div>
            <div className='table' ref={scrollContainerRef}>
              <div className='tbody'>
                {boardTtableState.map((el, i) => (
                  <div className='tr' role='button' tabIndex={0} key={i} onClick={() => onClickTableState(i, el)} style={getBackgroundStyle(i)}>
                    <div className='trCol2p5 flex-center tableStickyNo text_disabled'>{i + 1}</div>
                    <div className='trCol4 flex-center'>{el.ibtCd}</div>
                    <div className='trCol10 flex-center tableStickyTitle'>
                      <input
                        type='text'
                        id='ibtName'
                        name='ibtName'
                        value={el.ibtName}
                        onClick={(event) => {
                          if (size?.innerSize.W !== undefined && size.innerSize.W < 1024) {
                            event.stopPropagation();
                          }
                        }}
                        onChange={(e) => onChangeBoardTtableState(e, i, el)}
                        style={isSaveClicked ? applyBorderStyle(el.ibtName, 'red', 'ibtName') : undefined}
                        maxLength={50}
                      />
                    </div>
                    <div className='trCol3 flex-center'>
                      <input
                        type='radio'
                        id='ibtDefault'
                        name='ibtDefault'
                        onClick={(event) => {
                          if (size?.innerSize.W !== undefined && size.innerSize.W < 1024) {
                            event.stopPropagation();
                          }
                        }}
                        checked={el.ibtDefault === 'Y'}
                        onChange={(e) => onChangeRadioOption(e, i, el)}
                        disabled={el.useYn === 'N'}
                      />
                    </div>
                    <div className='trCol6 flex-center selector'>
                      <SelectBoxs
                        options={USE_YN}
                        defaultOption={el.useYn === 'Y' ? t('사용') : t('미사용')}
                        state={boardTtableState}
                        setState={setBoardTtableState}
                        rawData={boardTorgTableState}
                        setRawData={setBoardTOrgTableState}
                        stateKey='useYn'
                        codeKey='cdName'
                        index={i}
                        object={el}
                        primaryKey='ibtCd'
                      />
                    </div>
                    <div className='trCol10 flex-center text_tertiary'>{el.wDate}</div>
                    <div className='trCol6 flex-center text_tertiary'>{el.writer}</div>
                    <div className='trCol10 flex-center text_primary'>{el.eDate}</div>
                    <div className='trCol6 flex-center text_primary'>{el.editor}</div>
                    {auth.deleteAuth && (
                      <div className='trCol4 flex-center'>
                        <BtnRed
                          onClick={(event) => {
                            event.stopPropagation();
                            onClickDeleteBoardT(el, i);
                          }}
                        >
                          {el.index === undefined ? t('삭제') : t('제거')}
                        </BtnRed>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </InputTable>
          <ButtonsWrapper>
            {auth.createAuth && <BtnBlue onClick={onClickAddNewBoardT}>{t('신규항목 추가')}</BtnBlue>}
            {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={onClickSaveImageBoardT}>{t('저장')}</BtnBlue>}
          </ButtonsWrapper>
        </Root>
        {showSubTable.ibtName ? (
          <Root>
            <div className='inputFormsWrapper report'>
              {!(size.innerSize.W >= 1024) && (
                <div
                  className='icon-btn arrow-icon'
                  role='presentation'
                  onMouseDown={() => {
                    setShowSubTable({ status: false, ibtCd: '', ibtName: '' });
                  }}
                >
                  <span className='material-symbols-rounded'>arrow_back</span>
                  {t('뒤로가기')}
                </div>
              )}
              <div className='formTitle'>{showSubTable.status && `${showSubTable.ibtName}(${showSubTable.ibtCd})`}</div>
            </div>
            <InputTable className='margin-left-05'>
              <div className='tableTip'>
                <span className='material-symbols-rounded'>lightbulb </span>
                <span>{t('드래그 앤 드롭을 지원합니다. 행 순서를 위,아래로 변경하세요')}</span>
              </div>
              <div className='thead'>
                <div className='tr'>
                  <div className='trCol2p5 flex-center tableStickyNo'>No</div>
                  <div className='trCol6 flex-center'>{t('썸네일')}</div>
                  <div className='trCol20 required flex-center content-overflow'>{t('파일')}</div>
                  {auth.deleteAuth && <div className='trCol4 flex-center'> </div>}
                </div>
              </div>

              <div className='table img'>
                <div className='tbody'>
                  {showSubTable.status && boardDtableState?.length > 0 ? (
                    boardDtableState.map((el, index) => (
                      <DraggableRow
                        key={`${el.ibdCd}_${index}`}
                        index={index}
                        el={el}
                        moveRow={moveRow}
                        boardDtableState={boardDtableState}
                        setBoardDtableState={setBoardDtableState}
                        boardDorgTableState={boardDorgTableState}
                        setBoardDOrgTableState={setBoardDOrgTableState}
                        onClickDeleteBoardD={onClickDeleteBoardD}
                        onChangeBoardDtableState={onChangeBoardDtableState}
                        userInfo={userInfo}
                        auth={auth}
                      />
                    ))
                  ) : (
                    <IssueGuide />
                  )}
                </div>
              </div>
            </InputTable>
            <ButtonsWrapper>
              <div className='description'>{t('개별 이미지 당 5MB 까지 첨부 가능')}</div>
              <div className='addImgBtn'>
                <input
                  className='hiddenFileBox'
                  style={{ width: '50%', lineHeight: '2' }}
                  type='file'
                  multiple
                  accept='image/jpg, image/png, image/jpeg'
                  id='filebox'
                  name='dIfilename'
                  ref={inputFileRef}
                  onChange={handleFileInputChange}
                />
                {auth.createAuth && (
                  <label htmlFor='filebox'>
                    <div className='fileButton' id='filebox'>
                      <span className='material-symbols-rounded'>image </span>
                      <span>{t('이미지 추가')}</span>
                    </div>
                  </label>
                )}
              </div>
              {boardDtableState?.length > 0 && <BtnGreen onClick={onClickPreview}>{t('미리보기')}</BtnGreen>}
              {(auth.createAuth || auth.updateAuth) && <BtnBlue onClick={onClickSaveImageBoardD}>{t('저장')}</BtnBlue>}
            </ButtonsWrapper>
          </Root>
        ) : (
          size.innerSize.W >= 1024 && (
            <Root>
              <div className='emptyData'>
                <img src={illustrator} alt='noData' />
                <span>{t('왼쪽 목록에서 메뉴 타이틀을 선택해주세요')}</span>
              </div>
            </Root>
          )
        )}
        <Portal openModal={openModal?.status}>
          {openModal && openModal.title === 'delete' && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}
          {openModal && openModal.type === 'dashboardView' && <DashboardViewModalSlider openModal={openModal} setOpenModal={setOpenModal} />}
        </Portal>
      </div>
    </DndProvider>
  );
};

export default ImageBoard;
