import { userState } from '../atoms';
import { useRecoilValue } from 'recoil';
import { useQuery } from '@tanstack/react-query';
import { apiGet, apiPost } from '../services/_common';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const useMSite = (hCd: string, sCd: string) => {
  const mSiteQueryKey = '/site/msite';
  const mSiteQuery = useQuery(
    [mSiteQueryKey, hCd, sCd], // dependencies
    () => getMSiteList(mSiteQueryKey, { hCd, sCd }), // fetch
    { enabled: !!hCd && !!sCd } // option
  );

  const mSiteUseNQuery = useQuery(
    [`${mSiteQueryKey}/useN`, hCd, sCd], // dependencies
    () => getMSiteFilteredList(mSiteQueryKey, { hCd, sCd }), // fetch
    { enabled: !!hCd && !!sCd } // option
  );

  const { t } = useTranslation();
  const userInfo = useRecoilValue(userState);

  // 1. 삭제 버튼을 클릭하면 signIndex 유무에 따라
  // 2. setState 함수를 사용하거나 delete api를 호출하여
  // 3. tableState 상태를 업데이트하는 함수
  const deleteMSite = async (rowData: Msite) => {
    // signIndex가 있는 경우
    if (rowData.sSeq) {
      // const { wDate, eDate, ...rest } = rowData;
      const { wDate, eDate, mhName, msName, ...rest } = rowData;
      const req = {
        postMsiteSetDto: [
          {
            ...rest,
            useYn: 'N',
            wregYn: 'N',
            delYn: 'Y',
            editor: userInfo.userId,
          },
        ],
      };
      const res = await apiPost({
        path: '/site/msite', //
        contentType: 'application/json',
        req,
      });
      const { message, statusCode } = res.data;
      if (statusCode === 200) {
        toast.success(t(message));
        mSiteQuery.refetch();
      }
    }
  };

  return { mSiteQuery, mSiteUseNQuery, deleteMSite };
};

export const getMSiteList = async (path: string, user: { hCd: string; sCd: string }) => {
  try {
    const res = await apiGet({ path, req: user });
    const { data } = res.data;
    const filteredData = data.filter((el: any) => el.delYn !== 'Y');
    return filteredData;
  } catch (error) {
    console.error('error', error);
    throw new Error('error');
  }
};

export const getMSiteFilteredList = async (path: string, user: { hCd: string; sCd: string }) => {
  try {
    const res = await apiGet({ path, req: user });
    const { data } = res.data;
    const filteredData = data.filter((el: any) => el.delYn !== 'Y' || el.useYn !== 'Y');
    return filteredData;
  } catch (error) {
    console.error('error', error);
    throw new Error('error');
  }
};

export const getHcdList = async () => {
  try {
    const res = await apiGet({ path: '/menu/headInfo' });
    const { data } = res.data;
    const newArray = data.map(
      (el: any) => ({ type: 'hCd', hCd: el.hCd, cdName: el.hName }) //
    );
    return newArray;
  } catch (error) {
    console.error('error', error);
    throw new Error('error');
  }
};

export const getMsCdList = async (hCd: string) => {
  try {
    const res = await apiGet({ path: '/menu/siteInfo', req: { hCd } });
    const { data } = res.data;
    const newArray = data.map(
      (el: any) => ({ type: 'msCd', msCd: el.sCd, cdName: el.sName }) //
    );
    return newArray;
  } catch (error) {
    console.error('error', error);
    throw new Error('error');
  }
};

export const postMSite = async (req: any) => {
  try {
    const res = await apiPost({
      path: '/site/msite', //
      contentType: 'application/json',
      req,
    });
    const { message, statusCode } = res.data;
    if (statusCode !== 200) {
      throw new Error(message);
    }
    return res.data;
  } catch (error) {
    console.error('error', error);
    throw new Error('error');
  }
};

export const mergeRes = (resData: ResponseData[]): ResponseData[] => {
  const mergedData: { [key: string]: ResponseData } = {};
  resData.forEach((res) => {
    if (mergedData[res.message]) {
      mergedData[res.message].data.push(...res.data);
    } else {
      mergedData[res.message] = {
        data: [...res.data],
        message: res.message,
        statusCode: res.statusCode,
      };
    }
  });
  return Object.values(mergedData);
};

export default useMSite;
