/**
 * 작성자 : 한영광
 * 날짜 : 2023.04.19
 * 기능 : 마스터일반관리 -> 메뉴관리 -> 본사별 메뉴 관리 페이지
 */
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { InputTable } from '../../../assets/styles/InputTable';
import { userState } from '../../../atoms';
import { BtnBlue, BtnGhost } from '../../../components/Button';
import SelectBoxs from '../../../components/SelectBoxs';
import { SearchOptions } from '../../../assets/styles/SearchOptions';
import { scrollToNodeTop } from '../../../utils/scrollToNodeTop';
import { useDetectScrolledToBottom } from '../../../utils/useDetectScrolledToBottom';
import { IoChevronUpSharp } from 'react-icons/io5';
import { FloatingButtonToTop } from '../../../assets/styles/FloatingButtonToTop';
import { ERROR } from '../../../_constants';
import { logPost } from '../../../services/log';
import SearchSelectBoxSm from '../../../components/SearchSelectBoxSm';
// import illustrator from '../../assets/images/illustration/61.svg';
import illustrator from '../../../assets/images/illustration/304.svg';
import { useTranslation } from 'react-i18next';
import { apiGet, apiPost } from '../../../services/_common';

const EmptyData = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  height: 100%;
  width: 100%;
  padding-top: 6rem !important;
  /* padding-bottom: 4rem !important; */
  img {
    width: 16rem;
    /* user-drag: none; */
    -webkit-user-drag: none;
  }
  p {
    color: ${({ theme }: { theme: any }) => theme.filled_violet};
    word-break: keep-all;
    text-align: center;
  }
  p:nth-child(2) {
    padding: 1rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    border-radius: 0.5rem;
    margin: 1rem;
  }
  p:nth-child(3) {
    color: ${({ theme }: { theme: any }) => theme.text_tertiary};
    margin: 0 2rem;
  }
`;
const ButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 0.5rem;
  button {
    height: 2.5rem;
    font-size: 0.875rem;
  }
`;
const Root = styled.div`
  .textBtnGroup button {
    padding: 0 0.75rem;
    width: 4.5rem;
    height: 2.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    border: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
  }
  > div:nth-child(2) {
    overflow: auto;
  }
  .table.img {
    position: sticky;
    left: 0;
  }
`;

const CheckBox = styled.div`
  display: flex;
  align-items: center;
  > label {
    font-size: 1.25rem;
    font-weight: initial;
  }
`;

interface ITableObject {
  index: number;
  mtCd: string;
  mtName: string;
  depth: string;
  mdCd: string;
  mdName: string;
  useYn: string;
  mrCd: string;
  mrName: string;
  mrList: string;
  bigo: string;
  rSort: string;
}

const Head = () => {
  const { t } = useTranslation();
  const tableRef = useRef<HTMLDivElement>(null);
  const userInfo = useRecoilValue(userState);
  const [searchOption, setSearchOption] = useState({
    hCd: '',
  });
  const [initate, setInitate] = useState('');
  const [tableState, setTableState] = useState<ITableObject[]>([]);
  const [headList, setHeadList] = useState<any[]>([]);
  const [listCount, setListCount] = useState<any[]>([]);
  const [checkboxes, setCheckboxes] = useState<{ idx: number; isChecked: boolean }[]>([]);
  const [rawData, setRawData] = useState<any[]>([]);
  const [allCheckBoxs, setAllCheckBoxs] = useState(false);
  const { isBottom } = useDetectScrolledToBottom(tableRef);

  useEffect(() => {
    getHeadInfoAPI();
    logPost({
      hCd: userInfo.hCd,
      sCd: userInfo.sCd,
      userId: userInfo.userId,
      menu: '본사별 메뉴 관리',
      action: '조회',
      etc: ``,
    });
  }, []);

  useEffect(() => {
    const { hCd } = searchOption;
    if (hCd !== '') {
      getHeadInfoIdAPI(hCd);
      getListCountAPI();
      scrollToNodeTop(tableRef); // 검색조건 변경시 스크롤 상단으로 이동
      setAllCheckBoxs(false);
    }
  }, [searchOption]);

  // 본사 리스트 조회 API
  const getHeadInfoAPI = async () => {
    const res = await apiGet({ path: '/menu/headInfo' });
    const { data, statusCode } = res.data;
    if (statusCode === 200) {
      const newArray = data.map((el: any) => ({ type: 'hCd', hCd: el.hCd, cdName: el.hName }));
      setHeadList(newArray);
    }
    return res;
  };

  // 본사 메뉴 조회 API
  const getHeadInfoIdAPI = async (hCd: string) => {
    const res = await apiGet({ path: `/menu/headInfo/${hCd}` });
    const { data, message, statusCode } = res.data;
    if (statusCode === 200) {
      const newTableState = data
        .filter((v: any) => v.depth !== '0')
        .filter((v: any) => v.mrName !== null)
        .map((el: any, i: number) => {
          return { index: i + 1, ...el };
        });
      setTableState(newTableState);
      setRawData(data);
      const checkList = [];
      for (let i = 0; i < data.length; i += 1) {
        checkList.push({
          idx: i,
          isChecked: data[i].useYn === 'Y',
        });
      }
      setCheckboxes(checkList);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 공통코드(검색 줄수) 조회 API
  const getListCountAPI = async () => {
    const res = await apiGet({ path: '/menu/ListCount' });
    const { data, message, statusCode } = res.data;
    if (statusCode === 200) {
      const newListCount = data.map((el: any, i: number) => {
        return {
          idx: i,
          type: 'mrList',
          mrList: el,
        };
      });
      setListCount(newListCount);
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 일반 메뉴 저장 API
  const saveAPI = async (reqData: any[]) => {
    const req = { menuHeadInfoSaveReqDto: reqData };
    const res = await apiPost({ path: '/menu/headInfo', contentType: 'application/json', req });
    const { data, message, statusCode } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      setAllCheckBoxs(false);
      const newTableState = data
        .filter((v: any) => v.depth !== '0')
        .filter((v: any) => v.mrName !== null)
        .map((el: any, i: number) => {
          return { index: i + 1, ...el };
        });
      setTableState(newTableState);
      setAllCheckBoxs(false);
      await logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '마스터일반관리-메뉴관리',
        action: '본사별 메뉴 저장',
        etc: `본사코드 : ${reqData[0].hCd}`,
      });
    } else {
      // toast.error(t(ERROR));
    }
  };

  // 초기화 버튼 클릭
  const onClickSearch = () => {
    setTableState([]);
    setSearchOption({
      hCd: '',
    });
    setInitate(`${Math.random()}`);
    // const { hCd } = searchOption;
    // if (hCd !== '') {
    //   getHeadInfoIdAPI(hCd);
    //   getListCountAPI();
    //   scrollToNodeTop(tableRef);
    // } else {
    //   toast.warning('본사명을 선택하세요');
    // }
  };

  // 체크박스 클릭
  const handleCheckboxChangeAll = () => {
    setAllCheckBoxs(!allCheckBoxs);
    const newTableState = [...tableState];
    for (const v of newTableState) {
      v.useYn = !allCheckBoxs ? 'Y' : 'N';
    }
  };

  // 체크박스 클릭
  const handleCheckboxChange = (i: number, el: ITableObject) => {
    const newTableState = [...tableState];
    if (el.depth === '1') {
      if (newTableState[i].useYn === 'Y') {
        for (const v of newTableState) {
          if (el.mtCd === v.mtCd && el.mdCd === v.mdCd && v.depth !== '0') {
            v.useYn = 'N';
          }
        }
      } else {
        for (const v of newTableState) {
          if (el.mtCd === v.mtCd && el.mdCd === v.mdCd && v.depth !== '0') {
            v.useYn = 'Y';
          }
        }
      }
    } else if (el.depth === '2') {
      if (newTableState[i].useYn === 'Y') {
        newTableState[i].useYn = 'N';
        // 사용유무 depth가 2인 로우의 사용유무가 모두 N 이면 depth가 1인 로우 사용유무도 N 으로 처리
        let depth2UseYn = 'N';
        for (const v of newTableState) {
          if (el.mtCd === v.mtCd && el.mdCd === v.mdCd && v.depth === '2' && v.useYn === 'Y') {
            depth2UseYn = 'Y';
          }
        }
        for (const v of newTableState) {
          if (el.mtCd === v.mtCd && el.mdCd === v.mdCd && v.depth === '1') {
            v.useYn = depth2UseYn;
          }
        }
      } else {
        newTableState[i].useYn = 'Y';
        for (const v of newTableState) {
          if (el.mtCd === v.mtCd && el.mdCd === v.mdCd && v.depth === '1') {
            v.useYn = 'Y';
          }
        }
      }
    }
    setTableState(newTableState);
  };

  // 저장 버튼 클릭
  const onClickSave = () => {
    const { hCd } = searchOption;
    const reqArr = tableState
      .filter((v) => v.useYn === 'Y')
      .map((v) => {
        return {
          hCd,
          mtCd: v.mtCd,
          mdCd: v.mdCd,
          mrCd: v.mrCd,
          rSearch: Number(v.mrList),
          depth: v.depth,
          writer: userInfo.userId,
          cdSort: Number(v.rSort),
        };
      });
    if (reqArr.length === 0) {
      toast.warning(`${t('저장할 항목이 없습니다')}\r${t('검색 후 저장하세요')}`);
    } else {
      saveAPI(reqArr);
    }
  };

  const onChangeRSort = (e: React.ChangeEvent<HTMLInputElement>, i: number, el: ITableObject) => {
    const { name, value } = e.currentTarget;
    const newTableState = [...tableState];
    newTableState[i].rSort = value;
    setTableState(newTableState);
  };

  const tableRaw = (el: any, i: number) => {
    return (
      <>
        <div className='trCol2p5 flex-center tableStickyNo'>{el.index}</div>
        <div className='trCol5 flex-center content-overflow'>{el.depth === '1' ? el.mtCd : null}</div>
        <div className='trCol10 mtName flex-basic content-overflow'>{el.depth === '1' ? t(el.mtName) : null}</div>
        <div className='trCol5 flex-center content-overflow'>{el.depth === '1' ? el.mdCd : null}</div>
        <div className='trCol13 mdName flex-basic content-overflow'>{t(el.mdName)}</div>
        <div className='trCol5 flex-center '>
          {el.depth !== '0' ? (
            <CheckBox>
              <input type='checkbox' checked={el.useYn === 'Y'} onChange={() => handleCheckboxChange(i, el)} />
            </CheckBox>
          ) : null}
        </div>
        <div className='trCol15 mrName flex-basic content-overflow tableStickyTitle'>{el.depth === '2' ? `[${el.mrCd}] ${t(el.mrName)}` : null}</div>
        <div className='trCol5 flex-center '>
          {el.depth === '2' ? <input maxLength={3} type='text' value={el.rSort} id='rSort' name='rSort' inputMode='decimal' onChange={(e) => onChangeRSort(e, i, el)} /> : null}
        </div>
        <div className='trCol5 flex-center '>
          {el.depth === '2' ? (
            <SelectBoxs
              options={listCount}
              defaultOption={el.mrList}
              state={tableState}
              setState={setTableState}
              rawData={rawData}
              setRawData={setRawData}
              stateKey='mrList'
              codeKey='mrList'
              index={i}
              object={el}
              primaryKey='type'
              initiateKey={searchOption}
            />
          ) : null}
        </div>
        <div className='trCol10 bigo flex-center '>{el.depth === '2' ? el.bigo : null}</div>
      </>
    );
  };

  return (
    <Root>
      <SearchOptions>
        {isBottom && (
          <FloatingButtonToTop>
            <button type='button' onClick={() => scrollToNodeTop(tableRef)}>
              <IoChevronUpSharp size={20} style={{ stroke: 'white' }} />
            </button>
          </FloatingButtonToTop>
        )}
        <div className='inputsWrapper'>
          <div className='flex-basic textBtnGroup'>
            <BtnGhost onClick={onClickSearch}>{t('초기화')}</BtnGhost>
          </div>
          <div className='inputForm-row'>
            <div className='inputForm-col'>
              <SearchSelectBoxSm
                options={headList}
                defaultOption={t('본사명 선택')}
                state={searchOption}
                setState={setSearchOption}
                stateKey='hCd'
                codeKey='cdName'
                initiateKey={initate}
                filterbar='filter-1-left'
                dropDownWidth='expand-content-sm'
              />
            </div>
          </div>
        </div>
        {/* <BtnGreen onClick={onClickSearch}>초기화</BtnGreen> */}
      </SearchOptions>
      <InputTable className='margin-left-05'>
        <div className='thead'>
          <div className='tr'>
            <div className='trCol2p5 flex-center tableStickyNo'>{t('No')}</div>
            <div className='trCol5 flex-center content-overflow'>{t('코드')}</div>
            <div className='trCol10 flex-center content-overflow'>{t('타이틀 명')}</div>
            <div className='trCol5 flex-center content-overflow'>{t('메뉴 코드')}</div>
            <div className='trCol13 flex-center content-overflow'>{t('메뉴 명')}</div>
            <div className='trCol5 flex-center'>
              <CheckBox>
                <input type='checkbox' checked={allCheckBoxs} onChange={() => handleCheckboxChangeAll()} />
              </CheckBox>
              &nbsp;{t('사용유무')}
            </div>
            <div className='trCol15 flex-center content-overflow tableStickyTitle'>{t('보고서명')}</div>
            <div className='trCol5 flex-center '>{t('정렬')}</div>
            <div className='trCol5 flex-center '>{t('검색줄수')}</div>
            <div className='trCol10 flex-center '>{t('비고')}</div>
          </div>
        </div>
        <div className={`${tableState?.length > 0 ? 'table' : 'table img'}`} ref={tableRef}>
          <div className='tbody'>
            {tableState?.length > 0 ? (
              tableState?.map((el: any, i: number) =>
                el.depth === '0' ? null : el.depth === '1' ? (
                  <div className='tr' key={i} role='button' tabIndex={0} style={{ backgroundColor: '#F5F5F5' }}>
                    {tableRaw(el, i)}
                  </div>
                ) : (
                  <div className='tr' key={i} role='button' tabIndex={0}>
                    {tableRaw(el, i)}
                  </div>
                )
              )
            ) : (
              <EmptyData>
                <img src={illustrator} alt='noData' />
                <p>{t('필터를 적용해 주세요')}</p>
                <p>{t('데이터가 존재하지 않습니다')}</p>
              </EmptyData>
              // <div className='noData'>No data.</div>
            )}
          </div>
        </div>
      </InputTable>
      {/* <Portal openModal={openModal?.status}>{openModal && <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />}</Portal> */}
      {tableState?.length > 0 && (
        <ButtonsWrapper>
          <BtnBlue
            onClick={() => {
              onClickSave();
            }}
          >
            {t('저장')}
          </BtnBlue>
        </ButtonsWrapper>
      )}
    </Root>
  );
};

export default Head;
