import { useEffect, useState } from 'react';
import { SEX } from '../../../_constants';
import SelectBox from '../../SelectBox';
import { useTranslation } from 'react-i18next';

interface Props {
  propertyName: string;
  cdName: string;
  isViewMode: boolean;
  workerInputSet: any;
  setWorkerInputSet: any;
  isNewAdd: boolean;
}

const Sex = ({ propertyName, cdName, isViewMode, workerInputSet, setWorkerInputSet, isNewAdd }: Props) => {
  const { t } = useTranslation();
  const [sex, setSex] = useState({ type: 'sex', value: workerInputSet.sex, cdName: t('선택') }); // 성별
  const [editKey, setEditKey] = useState(false);

  useEffect(() => {
    // 이전/다음 으로 근로자 이동 시 edit 키 업데이트
    if (editKey) setEditKey(false);
  }, [workerInputSet.wCd]);

  useEffect(() => {
    if (workerInputSet.sex !== sex.value) setEditKey(true);
  }, [sex.value]);

  useEffect(() => {
    if (isNewAdd || (!isNewAdd && editKey)) {
      setWorkerInputSet((prev: any) => ({ ...prev, [propertyName]: sex.value }));
    }
  }, [sex.value, isNewAdd, editKey]);

  return (
    <div className='control selector'>
      <label htmlFor={propertyName}>{t(cdName)}</label>
      <SelectBox
        options={SEX} //
        defaultOption={sex.value === '' ? t('선택') : sex.cdName}
        state={sex}
        setState={setSex}
        stateKey='value'
        disabled={isViewMode}
        initiateKey={workerInputSet[propertyName]}
      />
    </div>
  );
};

export default Sex;
