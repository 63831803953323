import { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import Cookies from 'js-cookie';

import { IModal, ILogin } from 'customTypes';
import { IUser, userState } from '../../atoms';
import { BtnBlue, BtnWhite } from '../Button';
import Input from '../Input';
import { ModalBackground, Modal } from '../../assets/styles/Modal';
import { passwordOnlyRegex, passwordRegex } from '../../utils/checkRegex';
import { registerPwd } from '../../services/register';
import { ERROR } from '../../_constants';

interface IPasswordState {
  password: string;
  passwordConfirm: string;
}

const PasswordCheckYnModal = ({ openModal, setOpenModal, state, setState, resetState }: ILogin) => {
  const { t } = useTranslation();
  const setUserInfo = useSetRecoilState<IUser>(userState);
  const focusRef = useRef<HTMLInputElement>(null);
  const [pwAlert, setPwAlert] = useState(false);
  const [newPassword, setNewPassword] = useState<IPasswordState>({
    password: '',
    passwordConfirm: '',
  });

  // 모달창닫기
  const onClickClose = () => {
    setOpenModal((prev: IModal) => ({ ...prev, status: false }));
  };

  // 패스워드 input에서 포커스 아웃되었을 때 비밀번호정규식 확인문구
  const onBlur = () => {
    const { isPasswordRegexPass } = passwordOnlyRegex(newPassword?.password);
    if (!isPasswordRegexPass) setPwAlert(true);
    else setPwAlert(false);
  };

  // 새 비밀번호 DB insert API 호출
  const saveNewPasswordAPI = async () => {
    const res = await registerPwd(state?.id, newPassword?.password, state.accessToken);
    const { statusCode, message } = res.data;
    if (statusCode === 200) {
      toast.success(t(message));
      const { userId, userName, gCd, sessionTimeout, hCd, hName, sCd, sName, pwdchangeDate, prejobtypeYn, eduPlace, eduCharge, eduStartTime, eduEndTime, elderlyAgeSet } = state.userInfo;
      Cookies.set('prosafeToken', state.accessToken);
      Cookies.set('refreshToken', state.refreshToken);
      setUserInfo((prev) => ({
        ...prev,
        userId,
        userName,
        gCd,
        sessionTimeout,
        hCd,
        hName,
        sCd,
        sName,
        pwdchangeDate,
        prejobtypeYn,
        eduPlace,
        eduCharge,
        eduStartTime,
        eduEndTime,
        elderlyAgeSet,
      }));
      window.location.replace('/');
    } else {
      toast.error(t(message));
    }
  };

  // 새 비밀번호 저장버튼 클릭시
  // 1. 비밀번호 빈값,두 비밀번호 일치확인. 불일치시 알럿메시지
  // 2. 1번 통과시 정규식체크하고 통과시 새 비밀번호 저장 API 호출. 정규식 미충족시 비밀번호 인풋으로 포커스이동
  const onClickSaveNewPassword = () => {
    const { password, passwordConfirm } = newPassword;
    const { isPasswordRegexPass, passwordRegexAlertMsg } = passwordRegex(password, passwordConfirm);

    if (isPasswordRegexPass) {
      saveNewPasswordAPI();
    } else {
      toast.warning(t(passwordRegexAlertMsg));
      focusRef.current?.focus();
    }
  };

  return (
    <ModalBackground>
      <Modal>
        <div className='modal fitHeight'>
          <div className='inputForm-head'>
            <h5 className='bold'>{openModal?.title}</h5>
          </div>
          <div className='inputForm-body'>
            <div className='modal-contents'>
              <div className='inputForm-col'>
                <div className='inputForm-col'>
                  <Input
                    name='password'
                    label={t('신규 비밀번호')}
                    type='password'
                    state={newPassword}
                    setState={setNewPassword}
                    placeholder={t('영어대문자, 소문자, 숫자, 특수문자 중 3종류 포함')}
                    blur={onBlur}
                    maxLength={20}
                    inputRef={focusRef}
                  />
                </div>
                <div className='flexColumn'>
                  <small className='red text-left' style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
                    {t('현재 입력 글자수')} : {newPassword?.password?.length}
                  </small>
                  <small className='red text-left' style={{ paddingBottom: '0.5rem' }}>
                    {pwAlert && t('※ 최소 8자리 이상 : 영어 대문자, 소문자, 숫자, 특수문자 중 3종류 조합')}
                  </small>
                </div>
                <div className='inputForm-col'>
                  <Input
                    name='passwordConfirm'
                    label={t('신규 비밀번호 재입력')}
                    type='password'
                    state={newPassword}
                    setState={setNewPassword}
                    placeholder={t('영어대문자, 소문자, 숫자, 특수문자 중 3종류 포함')}
                    maxLength={20}
                  />
                </div>
              </div>
            </div>
            <div className='modal-footer flex-end'>
              <BtnBlue onClick={onClickSaveNewPassword}>{t('저장')}</BtnBlue>
              <BtnWhite onClick={onClickClose}>{t('닫기')}</BtnWhite>
            </div>
          </div>
        </div>
      </Modal>
    </ModalBackground>
  );
};

export default PasswordCheckYnModal;
