import styled from 'styled-components';

const Root = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  justify-content: center;
  border-radius: 0.25rem;
  color: ${({ theme }: { theme: any }) => theme.text_tertiary};
  background-color: ${({ theme }: { theme: any }) => theme.tonal};
  cursor: pointer;
  flex-shrink: 0;
  height: 2.5rem;
  padding: 1rem;
  &.minWidth {
    min-width: 2.25rem;
  }
  &.width40 {
    min-width: 4rem;
  }
  &.width60 {
    min-width: 6rem;
  }
  &.width80 {
    min-width: 8rem;
  }
  .material-symbols-rounded {
    font-size: 1.5rem;
  }
  &.filledIcon {
    .material-symbols-rounded {
      font-variation-settings: 'FILL' 1;
    }
  }
  .name {
    letter-spacing: normal;
    font-size: 0.875rem;
    width: fit-content;
    word-break: keep-all;
    white-space: nowrap;
  }
  &.play_arrow_reverse {
    .material-symbols-rounded {
      transform: rotate(180deg);
    }
  }
  &.selected,
  &:hover {
    color: ${({ theme }: { theme: any }) => theme.text_reverse};
    background-color: ${({ theme }: { theme: any }) => theme.filled_blue};
    .name {
      font-weight: 400;
    }
    &:active {
      color: ${({ theme }: { theme: any }) => theme.text_reverse};
      background-color: ${({ theme }: { theme: any }) => theme.filled_blue_deep};
    }
  }
  &.unselected:hover {
    color: ${({ theme }: { theme: any }) => theme.text_secondary};
    background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
  }
  &.darkModeOnly {
    color: ${({ theme }: { theme: any }) => theme.color.zinc_200};
    background-color: ${({ theme }: { theme: any }) => theme.color.zinc_800};
    .name {
      font-weight: 400;
    }
    &.selected,
    &:hover {
      color: ${({ theme }: { theme: any }) => theme.color.blue_100};
      background-color: ${({ theme }: { theme: any }) => theme.color.blue_700};
      &:active {
        color: ${({ theme }: { theme: any }) => theme.color.blue_100};
        background-color: ${({ theme }: { theme: any }) => theme.color.blue_600};
      }
    }
    &.unselected:hover {
      color: ${({ theme }: { theme: any }) => theme.color.zinc_100};
      background-color: ${({ theme }: { theme: any }) => theme.color.zinc_600};
    }
    &.negative {
      color: ${({ theme }: { theme: any }) => theme.color.red_500};
      &:hover {
        color: ${({ theme }: { theme: any }) => theme.color.red_100};
        background-color: ${({ theme }: { theme: any }) => theme.color.red_700};
      }
      &:active {
        color: ${({ theme }: { theme: any }) => theme.color.red_100};
        background-color: ${({ theme }: { theme: any }) => theme.color.red_600};
      }
    }
  }
  &.disabled {
    &:hover {
      color: ${({ theme }: { theme: any }) => theme.text_tertiary};
      background-color: ${({ theme }: { theme: any }) => theme.tonal};
      cursor: default;
    }
  }
`;

type Props = {
  className?: string;
  icon?: string;
  name?: string;
  selected?: boolean;
  onClick?: () => void;
  minWidth?: boolean;
  darkModeOnly?: boolean;
  iconWeight?: 400 | 300;
  filledIcon?: boolean;
  width?: 'width40' | 'width60' | 'width80';
  disabled?: boolean;
};

const BigButtonContainer = ({ className, icon, name, selected, minWidth, darkModeOnly, onClick, iconWeight, filledIcon = true, width, disabled = false }: Props) => {
  const setClassName = className ?? '';
  const setMinWidth = minWidth ? 'minWidth' : '';
  const setDarkModeOnly = darkModeOnly ? 'darkModeOnly' : '';
  const setIconWeight = iconWeight ? `iconWeight_${iconWeight}` : '';
  const setFilledIcon = filledIcon ? 'filledIcon' : '';
  const setWidth = width ?? '';
  const setDisabled = disabled ? 'disabled' : '';
  const setSelected = () => {
    if (selected === true) return 'selected';
    if (selected === false) return 'unselected';
    return '';
  };

  const setStyle = () => {
    return `${setClassName} ${setSelected()} ${setMinWidth} ${setDarkModeOnly} ${setIconWeight} ${setFilledIcon} ${setWidth} ${setDisabled}`;
  };

  return (
    <Root className={setStyle()} role='button' onClick={onClick}>
      {icon && <span className='material-symbols-rounded'>{icon}</span>}
      {name && <span className='name'>{name}</span>}
    </Root>
  );
};

export default BigButtonContainer;
