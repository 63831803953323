import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Root = styled.button`
  height: 2.25rem;
  background-color: ${({ theme }: { theme: any }) => theme.board};
  outline: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  border-radius: 0.25rem;
  color: ${({ theme }: { theme: any }) => theme.text_tertiary};
  padding: 0.375rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;
  font-size: 0.875rem;
  user-select: none;
  .material-symbols-rounded {
    font-size: 1.25rem;
  }
  .seperator {
    height: 1.25rem;
    border-left: 1px solid ${({ theme }: { theme: any }) => theme.outline};
    margin: 0 0.125rem;
  }
  .shortcut-f9 {
    font-size: 0.75rem;
    padding: 0.25rem;
    background-color: ${({ theme }: { theme: any }) => theme.tonal_light};
    color: ${({ theme }: { theme: any }) => theme.text_tonal_deep};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.125rem;
    outline: 1px solid ${({ theme }: { theme: any }) => theme.outline};
  }
  &:hover {
    background-color: ${({ theme }: { theme: any }) => theme.board};
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  }
  &:active {
    background-color: ${({ theme }: { theme: any }) => theme.tonal};
    .shortcut-f9 {
      background-color: ${({ theme }: { theme: any }) => theme.tonal_deep};
    }
  }
`;

type Props = {
  onClickRefresh: () => void;
};

const RefreshButton = ({ onClickRefresh }: Props) => {
  const { t } = useTranslation();
  return (
    <Root type='button' className='refreshButton' onClick={onClickRefresh}>
      <span className='material-symbols-rounded'>refresh</span>
      {t('새로고침')}
      <span className='seperator' />
      <span className='shortcut-f9'>F9</span>
    </Root>
  );
};

export default RefreshButton;
