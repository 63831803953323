/**
 * 작성자 : 홍선영
 * 날짜 : 2023.12.19
 * 기능 : 터널 관리 > 굴진/일반 정보 > 굴진/일반 정보 팝업 > 굴진/일반정보 탭 (현장용)
 */

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiGet, apiPost } from '../services/_common';
import { trimObject } from '../utils/trimObject';
import { toast } from 'react-toastify';
import { IUser, tInfoUpdateState } from '../atoms';
import { BtnBlue } from './Button';
import { logPost } from '../services/log';
import { useSetRecoilState } from 'recoil';
import TareaNormalInfoContent from './TareaNormalInfoContent';

interface ITareaNormalInfo {
  rowState: TareaData;
  userInfo: IUser;
  onClickPrev: () => void;
  onClickNext: () => void;
  auth: { createAuth: boolean; updateAuth: boolean };
  isSaveClicked: boolean;
  setDataList?: Dispatch<SetStateAction<TareaData[]>>;
  showPrevNextBtn: boolean;
}

interface IRequestData {
  hCd: string;
  sCd: string;
  tatCd: string;
  sRLength: string;
  sTjcCd: string;
  sTesCd: string;
  sBigo: string;
  eRLength: string;
  eTjcCd: string;
  eTesCd: string;
  eBigo: string;
  editor: string;
}

export const TareaNormalInfo = ({ rowState, userInfo, onClickPrev, onClickNext, auth, isSaveClicked, setDataList, showPrevNextBtn }: ITareaNormalInfo) => {
  const { t } = useTranslation();
  const [data, setData] = useState<any>(rowState);
  const setIsTunnelInfoUpdate = useSetRecoilState(tInfoUpdateState);

  // rowState 업데이트 시 data 업데이트
  useEffect(() => {
    setData(rowState);
  }, [rowState]);

  // 데이터 업데이트 후 터널 구역 정보 리스트 조회
  const getTareaAPI = async () => {
    if (setDataList) {
      const req = { hCd: userInfo.hCd, sCd: userInfo.sCd };
      const res = await apiGet({ path: '/tarea', req });
      const {
        statusCode,
        data: { tareaList },
      } = res.data || {};
      if (statusCode === 200) setDataList(tareaList);
    }
  };

  // 굴진/일반 정보 데이터 업데이트
  const postAPI = async (reqData: IRequestData) => {
    const res = await apiPost({ path: '/tarea/t/general', req: trimObject(reqData) });
    const { statusCode, message } = res.data || {};
    if (statusCode === 200) {
      toast.success(`${t(message)} 저장 성공`);
      // 저장 성공 시 구역정보 다시 조회해서 list set
      if (setDataList) getTareaAPI();
      // 터널정보 업데이트상태 전역저장
      // (대시보드에서 관련 컴포넌트를 모두 업데이트 시킬때 사용)
      setIsTunnelInfoUpdate((prev) => !prev);
      logPost({
        hCd: userInfo.hCd,
        sCd: userInfo.sCd,
        userId: userInfo.userId,
        menu: '터널관리 굴진/일반정보',
        action: '저장',
        etc: ``,
      });
    }
  };

  const createReqData = (): IRequestData => ({
    hCd: userInfo.hCd,
    sCd: userInfo.sCd,
    tatCd: data.tatCd,
    sRLength: data.sRLength?.toString()?.replace(/[^\d]/g, ''),
    sTjcCd: data.sTjcCd,
    sTesCd: data.sTesCd,
    sBigo: data.sBigo,
    eRLength: data.eRLength?.toString()?.replace(/[^\d]/g, ''),
    eTjcCd: data.eTjcCd,
    eTesCd: data.eTesCd,
    eBigo: data.eBigo,
    editor: userInfo.userId,
  });

  // 저장 버튼 클릭
  const onClickSave = () => {
    const reqData = createReqData();
    postAPI(reqData);
  };

  return (
    <>
      <TareaNormalInfoContent data={data} setData={setData} userInfo={userInfo} isSaveClicked={isSaveClicked} />
      <div className={`modal-footer ${showPrevNextBtn ? 'flex-between' : 'flex-end'}`}>
        {showPrevNextBtn ? (
          <div className='flex-basic arrows'>
            <BtnBlue onClick={onClickPrev}>
              <span className='material-symbols-rounded'>arrow_back_ios_new</span>
              <p>{t('이전')}</p>
              <div>
                <p>Page</p>
                <p>Up</p>
              </div>
            </BtnBlue>
            <BtnBlue onClick={onClickNext}>
              <div>
                <p>Page</p>
                <p>Down</p>
              </div>
              <p>{t('다음')}</p>
              <span className='material-symbols-rounded'>arrow_forward_ios</span>
            </BtnBlue>
          </div>
        ) : undefined}
        <div className='flex-basic'>{(auth.createAuth || auth.updateAuth) && (data.sUseYn === 'Y' || data.eUseYn === 'Y') && <BtnBlue onClick={onClickSave}>{t('저장')}</BtnBlue>}</div>
      </div>
    </>
  );
};
